/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const mtechnavi = $root.mtechnavi = (() => {

    /**
     * Namespace mtechnavi.
     * @exports mtechnavi
     * @namespace
     */
    const mtechnavi = {};

    mtechnavi.api = (function() {

        /**
         * Namespace api.
         * @memberof mtechnavi
         * @namespace
         */
        const api = {};

        api.admin = (function() {

            /**
             * Namespace admin.
             * @memberof mtechnavi.api
             * @namespace
             */
            const admin = {};

            admin.TenantManagementService = (function() {

                /**
                 * Constructs a new TenantManagementService service.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a TenantManagementService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function TenantManagementService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (TenantManagementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TenantManagementService;

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#listTenantManagements}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef ListTenantManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.ListTenantManagementsResponse} [response] ListTenantManagementsResponse
                 */

                /**
                 * Calls ListTenantManagements.
                 * @function listTenantManagements
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IListTenantManagementsRequest} request ListTenantManagementsRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.ListTenantManagementsCallback} callback Node-style callback called with the error, if any, and ListTenantManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.listTenantManagements = function listTenantManagements(request, callback) {
                    return this.rpcCall(listTenantManagements, $root.mtechnavi.api.admin.ListTenantManagementsRequest, $root.mtechnavi.api.admin.ListTenantManagementsResponse, request, callback);
                }, "name", { value: "ListTenantManagements" });

                /**
                 * Calls ListTenantManagements.
                 * @function listTenantManagements
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IListTenantManagementsRequest} request ListTenantManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.ListTenantManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#getTenantManagementContent}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef GetTenantManagementContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.TenantManagementContent} [response] TenantManagementContent
                 */

                /**
                 * Calls GetTenantManagementContent.
                 * @function getTenantManagementContent
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IGetTenantManagementContentRequest} request GetTenantManagementContentRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.GetTenantManagementContentCallback} callback Node-style callback called with the error, if any, and TenantManagementContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.getTenantManagementContent = function getTenantManagementContent(request, callback) {
                    return this.rpcCall(getTenantManagementContent, $root.mtechnavi.api.admin.GetTenantManagementContentRequest, $root.mtechnavi.api.admin.TenantManagementContent, request, callback);
                }, "name", { value: "GetTenantManagementContent" });

                /**
                 * Calls GetTenantManagementContent.
                 * @function getTenantManagementContent
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IGetTenantManagementContentRequest} request GetTenantManagementContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.TenantManagementContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#saveTenantManagementWithRelatedItems}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef SaveTenantManagementWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.TenantManagementContent} [response] TenantManagementContent
                 */

                /**
                 * Calls SaveTenantManagementWithRelatedItems.
                 * @function saveTenantManagementWithRelatedItems
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISaveTenantManagementWithRelatedItemsRequest} request SaveTenantManagementWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.SaveTenantManagementWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and TenantManagementContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.saveTenantManagementWithRelatedItems = function saveTenantManagementWithRelatedItems(request, callback) {
                    return this.rpcCall(saveTenantManagementWithRelatedItems, $root.mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest, $root.mtechnavi.api.admin.TenantManagementContent, request, callback);
                }, "name", { value: "SaveTenantManagementWithRelatedItems" });

                /**
                 * Calls SaveTenantManagementWithRelatedItems.
                 * @function saveTenantManagementWithRelatedItems
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISaveTenantManagementWithRelatedItemsRequest} request SaveTenantManagementWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.TenantManagementContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#deleteTenantManagementWithRelatedItems}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef DeleteTenantManagementWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteTenantManagementWithRelatedItems.
                 * @function deleteTenantManagementWithRelatedItems
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteTenantManagementWithRelatedItemsRequest} request DeleteTenantManagementWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.DeleteTenantManagementWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.deleteTenantManagementWithRelatedItems = function deleteTenantManagementWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteTenantManagementWithRelatedItems, $root.mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteTenantManagementWithRelatedItems" });

                /**
                 * Calls DeleteTenantManagementWithRelatedItems.
                 * @function deleteTenantManagementWithRelatedItems
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteTenantManagementWithRelatedItemsRequest} request DeleteTenantManagementWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#reserveDeleteTenantManagement}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef ReserveDeleteTenantManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.ReserveDeleteTenantManagementResponse} [response] ReserveDeleteTenantManagementResponse
                 */

                /**
                 * Calls ReserveDeleteTenantManagement.
                 * @function reserveDeleteTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IReserveDeleteTenantManagementRequest} request ReserveDeleteTenantManagementRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.ReserveDeleteTenantManagementCallback} callback Node-style callback called with the error, if any, and ReserveDeleteTenantManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.reserveDeleteTenantManagement = function reserveDeleteTenantManagement(request, callback) {
                    return this.rpcCall(reserveDeleteTenantManagement, $root.mtechnavi.api.admin.ReserveDeleteTenantManagementRequest, $root.mtechnavi.api.admin.ReserveDeleteTenantManagementResponse, request, callback);
                }, "name", { value: "ReserveDeleteTenantManagement" });

                /**
                 * Calls ReserveDeleteTenantManagement.
                 * @function reserveDeleteTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IReserveDeleteTenantManagementRequest} request ReserveDeleteTenantManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.ReserveDeleteTenantManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#cancelReserveDeleteTenantManagement}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef CancelReserveDeleteTenantManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse} [response] CancelReserveDeleteTenantManagementResponse
                 */

                /**
                 * Calls CancelReserveDeleteTenantManagement.
                 * @function cancelReserveDeleteTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICancelReserveDeleteTenantManagementRequest} request CancelReserveDeleteTenantManagementRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.CancelReserveDeleteTenantManagementCallback} callback Node-style callback called with the error, if any, and CancelReserveDeleteTenantManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.cancelReserveDeleteTenantManagement = function cancelReserveDeleteTenantManagement(request, callback) {
                    return this.rpcCall(cancelReserveDeleteTenantManagement, $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest, $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse, request, callback);
                }, "name", { value: "CancelReserveDeleteTenantManagement" });

                /**
                 * Calls CancelReserveDeleteTenantManagement.
                 * @function cancelReserveDeleteTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICancelReserveDeleteTenantManagementRequest} request CancelReserveDeleteTenantManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#disableTenantManagement}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef DisableTenantManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.DisableTenantManagementResponse} [response] DisableTenantManagementResponse
                 */

                /**
                 * Calls DisableTenantManagement.
                 * @function disableTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDisableTenantManagementRequest} request DisableTenantManagementRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.DisableTenantManagementCallback} callback Node-style callback called with the error, if any, and DisableTenantManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.disableTenantManagement = function disableTenantManagement(request, callback) {
                    return this.rpcCall(disableTenantManagement, $root.mtechnavi.api.admin.DisableTenantManagementRequest, $root.mtechnavi.api.admin.DisableTenantManagementResponse, request, callback);
                }, "name", { value: "DisableTenantManagement" });

                /**
                 * Calls DisableTenantManagement.
                 * @function disableTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDisableTenantManagementRequest} request DisableTenantManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.DisableTenantManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#enableTenantManagement}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef EnableTenantManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.EnableTenantManagementResponse} [response] EnableTenantManagementResponse
                 */

                /**
                 * Calls EnableTenantManagement.
                 * @function enableTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IEnableTenantManagementRequest} request EnableTenantManagementRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.EnableTenantManagementCallback} callback Node-style callback called with the error, if any, and EnableTenantManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.enableTenantManagement = function enableTenantManagement(request, callback) {
                    return this.rpcCall(enableTenantManagement, $root.mtechnavi.api.admin.EnableTenantManagementRequest, $root.mtechnavi.api.admin.EnableTenantManagementResponse, request, callback);
                }, "name", { value: "EnableTenantManagement" });

                /**
                 * Calls EnableTenantManagement.
                 * @function enableTenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IEnableTenantManagementRequest} request EnableTenantManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.EnableTenantManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#sendEmailTenantInvitation}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef SendEmailTenantInvitationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.SendEmailTenantInvitationResponse} [response] SendEmailTenantInvitationResponse
                 */

                /**
                 * Calls SendEmailTenantInvitation.
                 * @function sendEmailTenantInvitation
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISendEmailTenantInvitationRequest} request SendEmailTenantInvitationRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.SendEmailTenantInvitationCallback} callback Node-style callback called with the error, if any, and SendEmailTenantInvitationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.sendEmailTenantInvitation = function sendEmailTenantInvitation(request, callback) {
                    return this.rpcCall(sendEmailTenantInvitation, $root.mtechnavi.api.admin.SendEmailTenantInvitationRequest, $root.mtechnavi.api.admin.SendEmailTenantInvitationResponse, request, callback);
                }, "name", { value: "SendEmailTenantInvitation" });

                /**
                 * Calls SendEmailTenantInvitation.
                 * @function sendEmailTenantInvitation
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISendEmailTenantInvitationRequest} request SendEmailTenantInvitationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.SendEmailTenantInvitationResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#createLicense}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef CreateLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.License} [response] License
                 */

                /**
                 * Calls CreateLicense.
                 * @function createLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateLicenseRequest} request CreateLicenseRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.CreateLicenseCallback} callback Node-style callback called with the error, if any, and License
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.createLicense = function createLicense(request, callback) {
                    return this.rpcCall(createLicense, $root.mtechnavi.api.admin.CreateLicenseRequest, $root.mtechnavi.api.license.License, request, callback);
                }, "name", { value: "CreateLicense" });

                /**
                 * Calls CreateLicense.
                 * @function createLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateLicenseRequest} request CreateLicenseRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.License>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#updateLicense}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef UpdateLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.License} [response] License
                 */

                /**
                 * Calls UpdateLicense.
                 * @function updateLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IUpdateLicenseRequest} request UpdateLicenseRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.UpdateLicenseCallback} callback Node-style callback called with the error, if any, and License
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.updateLicense = function updateLicense(request, callback) {
                    return this.rpcCall(updateLicense, $root.mtechnavi.api.admin.UpdateLicenseRequest, $root.mtechnavi.api.license.License, request, callback);
                }, "name", { value: "UpdateLicense" });

                /**
                 * Calls UpdateLicense.
                 * @function updateLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IUpdateLicenseRequest} request UpdateLicenseRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.License>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#deleteLicense}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef DeleteLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteLicense.
                 * @function deleteLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteLicenseRequest} request DeleteLicenseRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.DeleteLicenseCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.deleteLicense = function deleteLicense(request, callback) {
                    return this.rpcCall(deleteLicense, $root.mtechnavi.api.admin.DeleteLicenseRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteLicense" });

                /**
                 * Calls DeleteLicense.
                 * @function deleteLicense
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteLicenseRequest} request DeleteLicenseRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#bulkWriteLicenses}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef BulkWriteLicensesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.BulkWriteLicensesResponse} [response] BulkWriteLicensesResponse
                 */

                /**
                 * Calls BulkWriteLicenses.
                 * @function bulkWriteLicenses
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IBulkWriteLicensesRequest} request BulkWriteLicensesRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.BulkWriteLicensesCallback} callback Node-style callback called with the error, if any, and BulkWriteLicensesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.bulkWriteLicenses = function bulkWriteLicenses(request, callback) {
                    return this.rpcCall(bulkWriteLicenses, $root.mtechnavi.api.admin.BulkWriteLicensesRequest, $root.mtechnavi.api.admin.BulkWriteLicensesResponse, request, callback);
                }, "name", { value: "BulkWriteLicenses" });

                /**
                 * Calls BulkWriteLicenses.
                 * @function bulkWriteLicenses
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IBulkWriteLicensesRequest} request BulkWriteLicensesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.BulkWriteLicensesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#listTenantAdmins}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef ListTenantAdminsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.ListTenantAdminsResponse} [response] ListTenantAdminsResponse
                 */

                /**
                 * Calls ListTenantAdmins.
                 * @function listTenantAdmins
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IListTenantAdminsRequest} request ListTenantAdminsRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.ListTenantAdminsCallback} callback Node-style callback called with the error, if any, and ListTenantAdminsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.listTenantAdmins = function listTenantAdmins(request, callback) {
                    return this.rpcCall(listTenantAdmins, $root.mtechnavi.api.admin.ListTenantAdminsRequest, $root.mtechnavi.api.admin.ListTenantAdminsResponse, request, callback);
                }, "name", { value: "ListTenantAdmins" });

                /**
                 * Calls ListTenantAdmins.
                 * @function listTenantAdmins
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IListTenantAdminsRequest} request ListTenantAdminsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.ListTenantAdminsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#createTenantAdmin}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef CreateTenantAdminCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {sharelib.UserReference} [response] UserReference
                 */

                /**
                 * Calls CreateTenantAdmin.
                 * @function createTenantAdmin
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateTenantAdminRequest} request CreateTenantAdminRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.CreateTenantAdminCallback} callback Node-style callback called with the error, if any, and UserReference
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.createTenantAdmin = function createTenantAdmin(request, callback) {
                    return this.rpcCall(createTenantAdmin, $root.mtechnavi.api.admin.CreateTenantAdminRequest, $root.sharelib.UserReference, request, callback);
                }, "name", { value: "CreateTenantAdmin" });

                /**
                 * Calls CreateTenantAdmin.
                 * @function createTenantAdmin
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateTenantAdminRequest} request CreateTenantAdminRequest message or plain object
                 * @returns {Promise<sharelib.UserReference>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#sendEmailAccountCreated}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef SendEmailAccountCreatedCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.SendEmailAccountCreatedResponse} [response] SendEmailAccountCreatedResponse
                 */

                /**
                 * Calls SendEmailAccountCreated.
                 * @function sendEmailAccountCreated
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISendEmailAccountCreatedRequest} request SendEmailAccountCreatedRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.SendEmailAccountCreatedCallback} callback Node-style callback called with the error, if any, and SendEmailAccountCreatedResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.sendEmailAccountCreated = function sendEmailAccountCreated(request, callback) {
                    return this.rpcCall(sendEmailAccountCreated, $root.mtechnavi.api.admin.SendEmailAccountCreatedRequest, $root.mtechnavi.api.admin.SendEmailAccountCreatedResponse, request, callback);
                }, "name", { value: "SendEmailAccountCreated" });

                /**
                 * Calls SendEmailAccountCreated.
                 * @function sendEmailAccountCreated
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.ISendEmailAccountCreatedRequest} request SendEmailAccountCreatedRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.SendEmailAccountCreatedResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.TenantManagementService#manualTenantProvisioning}.
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @typedef ManualTenantProvisioningCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls ManualTenantProvisioning.
                 * @function manualTenantProvisioning
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IManualTenantProvisioningRequest} request ManualTenantProvisioningRequest message or plain object
                 * @param {mtechnavi.api.admin.TenantManagementService.ManualTenantProvisioningCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantManagementService.prototype.manualTenantProvisioning = function manualTenantProvisioning(request, callback) {
                    return this.rpcCall(manualTenantProvisioning, $root.mtechnavi.api.admin.ManualTenantProvisioningRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "ManualTenantProvisioning" });

                /**
                 * Calls ManualTenantProvisioning.
                 * @function manualTenantProvisioning
                 * @memberof mtechnavi.api.admin.TenantManagementService
                 * @instance
                 * @param {mtechnavi.api.admin.IManualTenantProvisioningRequest} request ManualTenantProvisioningRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return TenantManagementService;
            })();

            admin.InformationService = (function() {

                /**
                 * Constructs a new InformationService service.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an InformationService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function InformationService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (InformationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = InformationService;

                /**
                 * Callback as used by {@link mtechnavi.api.admin.InformationService#listInformationContents}.
                 * @memberof mtechnavi.api.admin.InformationService
                 * @typedef ListInformationContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.ListInformationContentsResponse} [response] ListInformationContentsResponse
                 */

                /**
                 * Calls ListInformationContents.
                 * @function listInformationContents
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IListInformationContentsRequest} request ListInformationContentsRequest message or plain object
                 * @param {mtechnavi.api.admin.InformationService.ListInformationContentsCallback} callback Node-style callback called with the error, if any, and ListInformationContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(InformationService.prototype.listInformationContents = function listInformationContents(request, callback) {
                    return this.rpcCall(listInformationContents, $root.mtechnavi.api.admin.ListInformationContentsRequest, $root.mtechnavi.api.admin.ListInformationContentsResponse, request, callback);
                }, "name", { value: "ListInformationContents" });

                /**
                 * Calls ListInformationContents.
                 * @function listInformationContents
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IListInformationContentsRequest} request ListInformationContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.ListInformationContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.InformationService#createInformation}.
                 * @memberof mtechnavi.api.admin.InformationService
                 * @typedef CreateInformationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.Information} [response] Information
                 */

                /**
                 * Calls CreateInformation.
                 * @function createInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateInformationRequest} request CreateInformationRequest message or plain object
                 * @param {mtechnavi.api.admin.InformationService.CreateInformationCallback} callback Node-style callback called with the error, if any, and Information
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(InformationService.prototype.createInformation = function createInformation(request, callback) {
                    return this.rpcCall(createInformation, $root.mtechnavi.api.admin.CreateInformationRequest, $root.mtechnavi.api.admin.Information, request, callback);
                }, "name", { value: "CreateInformation" });

                /**
                 * Calls CreateInformation.
                 * @function createInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.ICreateInformationRequest} request CreateInformationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.Information>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.InformationService#updateInformation}.
                 * @memberof mtechnavi.api.admin.InformationService
                 * @typedef UpdateInformationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.admin.Information} [response] Information
                 */

                /**
                 * Calls UpdateInformation.
                 * @function updateInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IUpdateInformationRequest} request UpdateInformationRequest message or plain object
                 * @param {mtechnavi.api.admin.InformationService.UpdateInformationCallback} callback Node-style callback called with the error, if any, and Information
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(InformationService.prototype.updateInformation = function updateInformation(request, callback) {
                    return this.rpcCall(updateInformation, $root.mtechnavi.api.admin.UpdateInformationRequest, $root.mtechnavi.api.admin.Information, request, callback);
                }, "name", { value: "UpdateInformation" });

                /**
                 * Calls UpdateInformation.
                 * @function updateInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IUpdateInformationRequest} request UpdateInformationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.admin.Information>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.admin.InformationService#deleteInformation}.
                 * @memberof mtechnavi.api.admin.InformationService
                 * @typedef DeleteInformationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteInformation.
                 * @function deleteInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteInformationRequest} request DeleteInformationRequest message or plain object
                 * @param {mtechnavi.api.admin.InformationService.DeleteInformationCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(InformationService.prototype.deleteInformation = function deleteInformation(request, callback) {
                    return this.rpcCall(deleteInformation, $root.mtechnavi.api.admin.DeleteInformationRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteInformation" });

                /**
                 * Calls DeleteInformation.
                 * @function deleteInformation
                 * @memberof mtechnavi.api.admin.InformationService
                 * @instance
                 * @param {mtechnavi.api.admin.IDeleteInformationRequest} request DeleteInformationRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return InformationService;
            })();

            admin.ListTenantManagementsRequest = (function() {

                /**
                 * Properties of a ListTenantManagementsRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IListTenantManagementsRequest
                 * @property {string|null} [pageToken] ListTenantManagementsRequest pageToken
                 * @property {Array.<string>|null} [tenantManagementIds] ListTenantManagementsRequest tenantManagementIds
                 */

                /**
                 * Constructs a new ListTenantManagementsRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListTenantManagementsRequest.
                 * @implements IListTenantManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IListTenantManagementsRequest=} [properties] Properties to set
                 */
                function ListTenantManagementsRequest(properties) {
                    this.tenantManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTenantManagementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @instance
                 */
                ListTenantManagementsRequest.prototype.pageToken = "";

                /**
                 * ListTenantManagementsRequest tenantManagementIds.
                 * @member {Array.<string>} tenantManagementIds
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @instance
                 */
                ListTenantManagementsRequest.prototype.tenantManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListTenantManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.tenantManagementIds != null && message.hasOwnProperty("tenantManagementIds")) {
                        if (!Array.isArray(message.tenantManagementIds))
                            return "tenantManagementIds: array expected";
                        for (let i = 0; i < message.tenantManagementIds.length; ++i)
                            if (!$util.isString(message.tenantManagementIds[i]))
                                return "tenantManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListTenantManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListTenantManagementsRequest} ListTenantManagementsRequest
                 */
                ListTenantManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListTenantManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListTenantManagementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.tenantManagementIds) {
                        if (!Array.isArray(object.tenantManagementIds))
                            throw TypeError(".mtechnavi.api.admin.ListTenantManagementsRequest.tenantManagementIds: array expected");
                        message.tenantManagementIds = [];
                        for (let i = 0; i < object.tenantManagementIds.length; ++i)
                            message.tenantManagementIds[i] = String(object.tenantManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListTenantManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @static
                 * @param {mtechnavi.api.admin.ListTenantManagementsRequest} message ListTenantManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.tenantManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.tenantManagementIds && message.tenantManagementIds.length) {
                        object.tenantManagementIds = [];
                        for (let j = 0; j < message.tenantManagementIds.length; ++j)
                            object.tenantManagementIds[j] = message.tenantManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListTenantManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListTenantManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantManagementsRequest;
            })();

            admin.ListTenantManagementsResponse = (function() {

                /**
                 * Properties of a ListTenantManagementsResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IListTenantManagementsResponse
                 * @property {Long|null} [total] ListTenantManagementsResponse total
                 * @property {Array.<mtechnavi.api.admin.ITenantManagement>|null} [items] ListTenantManagementsResponse items
                 * @property {string|null} [pageToken] ListTenantManagementsResponse pageToken
                 */

                /**
                 * Constructs a new ListTenantManagementsResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListTenantManagementsResponse.
                 * @implements IListTenantManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IListTenantManagementsResponse=} [properties] Properties to set
                 */
                function ListTenantManagementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTenantManagementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @instance
                 */
                ListTenantManagementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListTenantManagementsResponse items.
                 * @member {Array.<mtechnavi.api.admin.ITenantManagement>} items
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @instance
                 */
                ListTenantManagementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListTenantManagementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @instance
                 */
                ListTenantManagementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListTenantManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.admin.TenantManagement.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListTenantManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListTenantManagementsResponse} ListTenantManagementsResponse
                 */
                ListTenantManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListTenantManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListTenantManagementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.admin.ListTenantManagementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.ListTenantManagementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.admin.TenantManagement.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListTenantManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @static
                 * @param {mtechnavi.api.admin.ListTenantManagementsResponse} message ListTenantManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.admin.TenantManagement.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListTenantManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListTenantManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantManagementsResponse;
            })();

            admin.GetTenantManagementContentRequest = (function() {

                /**
                 * Properties of a GetTenantManagementContentRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IGetTenantManagementContentRequest
                 * @property {string|null} [tenantManagementId] GetTenantManagementContentRequest tenantManagementId
                 */

                /**
                 * Constructs a new GetTenantManagementContentRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a GetTenantManagementContentRequest.
                 * @implements IGetTenantManagementContentRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IGetTenantManagementContentRequest=} [properties] Properties to set
                 */
                function GetTenantManagementContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTenantManagementContentRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.GetTenantManagementContentRequest
                 * @instance
                 */
                GetTenantManagementContentRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies a GetTenantManagementContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.GetTenantManagementContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTenantManagementContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a GetTenantManagementContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.GetTenantManagementContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.GetTenantManagementContentRequest} GetTenantManagementContentRequest
                 */
                GetTenantManagementContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.GetTenantManagementContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.GetTenantManagementContentRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTenantManagementContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.GetTenantManagementContentRequest
                 * @static
                 * @param {mtechnavi.api.admin.GetTenantManagementContentRequest} message GetTenantManagementContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTenantManagementContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementId = "";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this GetTenantManagementContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.GetTenantManagementContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTenantManagementContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTenantManagementContentRequest;
            })();

            admin.SaveTenantManagementWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a SaveTenantManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ISaveTenantManagementWithRelatedItemsRequest
                 * @property {mtechnavi.api.admin.ITenantManagement|null} [tenantManagement] SaveTenantManagementWithRelatedItemsRequest tenantManagement
                 * @property {mtechnavi.api.company.IBillingCompany|null} [billingCompany] SaveTenantManagementWithRelatedItemsRequest billingCompany
                 */

                /**
                 * Constructs a new SaveTenantManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a SaveTenantManagementWithRelatedItemsRequest.
                 * @implements ISaveTenantManagementWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ISaveTenantManagementWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function SaveTenantManagementWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveTenantManagementWithRelatedItemsRequest tenantManagement.
                 * @member {mtechnavi.api.admin.ITenantManagement|null|undefined} tenantManagement
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveTenantManagementWithRelatedItemsRequest.prototype.tenantManagement = null;

                /**
                 * SaveTenantManagementWithRelatedItemsRequest billingCompany.
                 * @member {mtechnavi.api.company.IBillingCompany|null|undefined} billingCompany
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveTenantManagementWithRelatedItemsRequest.prototype.billingCompany = null;

                /**
                 * Verifies a SaveTenantManagementWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveTenantManagementWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement")) {
                        let error = $root.mtechnavi.api.admin.TenantManagement.verify(message.tenantManagement);
                        if (error)
                            return "tenantManagement." + error;
                    }
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany")) {
                        let error = $root.mtechnavi.api.company.BillingCompany.verify(message.billingCompany);
                        if (error)
                            return "billingCompany." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveTenantManagementWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest} SaveTenantManagementWithRelatedItemsRequest
                 */
                SaveTenantManagementWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest();
                    if (object.tenantManagement != null) {
                        if (typeof object.tenantManagement !== "object")
                            throw TypeError(".mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest.tenantManagement: object expected");
                        message.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.fromObject(object.tenantManagement);
                    }
                    if (object.billingCompany != null) {
                        if (typeof object.billingCompany !== "object")
                            throw TypeError(".mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest.billingCompany: object expected");
                        message.billingCompany = $root.mtechnavi.api.company.BillingCompany.fromObject(object.billingCompany);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveTenantManagementWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest} message SaveTenantManagementWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveTenantManagementWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagement = null;
                        object.billingCompany = null;
                    }
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement"))
                        object.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.toObject(message.tenantManagement, options);
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany"))
                        object.billingCompany = $root.mtechnavi.api.company.BillingCompany.toObject(message.billingCompany, options);
                    return object;
                };

                /**
                 * Converts this SaveTenantManagementWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.SaveTenantManagementWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveTenantManagementWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveTenantManagementWithRelatedItemsRequest;
            })();

            admin.DeleteTenantManagementWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteTenantManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IDeleteTenantManagementWithRelatedItemsRequest
                 * @property {string|null} [tenantManagementId] DeleteTenantManagementWithRelatedItemsRequest tenantManagementId
                 */

                /**
                 * Constructs a new DeleteTenantManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a DeleteTenantManagementWithRelatedItemsRequest.
                 * @implements IDeleteTenantManagementWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IDeleteTenantManagementWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteTenantManagementWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteTenantManagementWithRelatedItemsRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest
                 * @instance
                 */
                DeleteTenantManagementWithRelatedItemsRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies a DeleteTenantManagementWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTenantManagementWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteTenantManagementWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest} DeleteTenantManagementWithRelatedItemsRequest
                 */
                DeleteTenantManagementWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteTenantManagementWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest} message DeleteTenantManagementWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTenantManagementWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementId = "";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this DeleteTenantManagementWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.DeleteTenantManagementWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTenantManagementWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteTenantManagementWithRelatedItemsRequest;
            })();

            admin.ReserveDeleteTenantManagementRequest = (function() {

                /**
                 * Properties of a ReserveDeleteTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IReserveDeleteTenantManagementRequest
                 * @property {mtechnavi.api.admin.ITenantManagement|null} [tenantManagement] ReserveDeleteTenantManagementRequest tenantManagement
                 */

                /**
                 * Constructs a new ReserveDeleteTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ReserveDeleteTenantManagementRequest.
                 * @implements IReserveDeleteTenantManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IReserveDeleteTenantManagementRequest=} [properties] Properties to set
                 */
                function ReserveDeleteTenantManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReserveDeleteTenantManagementRequest tenantManagement.
                 * @member {mtechnavi.api.admin.ITenantManagement|null|undefined} tenantManagement
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementRequest
                 * @instance
                 */
                ReserveDeleteTenantManagementRequest.prototype.tenantManagement = null;

                /**
                 * Verifies a ReserveDeleteTenantManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReserveDeleteTenantManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement")) {
                        let error = $root.mtechnavi.api.admin.TenantManagement.verify(message.tenantManagement);
                        if (error)
                            return "tenantManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReserveDeleteTenantManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ReserveDeleteTenantManagementRequest} ReserveDeleteTenantManagementRequest
                 */
                ReserveDeleteTenantManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ReserveDeleteTenantManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ReserveDeleteTenantManagementRequest();
                    if (object.tenantManagement != null) {
                        if (typeof object.tenantManagement !== "object")
                            throw TypeError(".mtechnavi.api.admin.ReserveDeleteTenantManagementRequest.tenantManagement: object expected");
                        message.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.fromObject(object.tenantManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReserveDeleteTenantManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementRequest
                 * @static
                 * @param {mtechnavi.api.admin.ReserveDeleteTenantManagementRequest} message ReserveDeleteTenantManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReserveDeleteTenantManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagement = null;
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement"))
                        object.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.toObject(message.tenantManagement, options);
                    return object;
                };

                /**
                 * Converts this ReserveDeleteTenantManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReserveDeleteTenantManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReserveDeleteTenantManagementRequest;
            })();

            admin.ReserveDeleteTenantManagementResponse = (function() {

                /**
                 * Properties of a ReserveDeleteTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IReserveDeleteTenantManagementResponse
                 * @property {mtechnavi.api.admin.ITenantManagementContent|null} [tenantManagementContent] ReserveDeleteTenantManagementResponse tenantManagementContent
                 */

                /**
                 * Constructs a new ReserveDeleteTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ReserveDeleteTenantManagementResponse.
                 * @implements IReserveDeleteTenantManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IReserveDeleteTenantManagementResponse=} [properties] Properties to set
                 */
                function ReserveDeleteTenantManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReserveDeleteTenantManagementResponse tenantManagementContent.
                 * @member {mtechnavi.api.admin.ITenantManagementContent|null|undefined} tenantManagementContent
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementResponse
                 * @instance
                 */
                ReserveDeleteTenantManagementResponse.prototype.tenantManagementContent = null;

                /**
                 * Verifies a ReserveDeleteTenantManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReserveDeleteTenantManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent")) {
                        let error = $root.mtechnavi.api.admin.TenantManagementContent.verify(message.tenantManagementContent);
                        if (error)
                            return "tenantManagementContent." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReserveDeleteTenantManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ReserveDeleteTenantManagementResponse} ReserveDeleteTenantManagementResponse
                 */
                ReserveDeleteTenantManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ReserveDeleteTenantManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ReserveDeleteTenantManagementResponse();
                    if (object.tenantManagementContent != null) {
                        if (typeof object.tenantManagementContent !== "object")
                            throw TypeError(".mtechnavi.api.admin.ReserveDeleteTenantManagementResponse.tenantManagementContent: object expected");
                        message.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.fromObject(object.tenantManagementContent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReserveDeleteTenantManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementResponse
                 * @static
                 * @param {mtechnavi.api.admin.ReserveDeleteTenantManagementResponse} message ReserveDeleteTenantManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReserveDeleteTenantManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementContent = null;
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent"))
                        object.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.toObject(message.tenantManagementContent, options);
                    return object;
                };

                /**
                 * Converts this ReserveDeleteTenantManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ReserveDeleteTenantManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReserveDeleteTenantManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReserveDeleteTenantManagementResponse;
            })();

            admin.CancelReserveDeleteTenantManagementRequest = (function() {

                /**
                 * Properties of a CancelReserveDeleteTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ICancelReserveDeleteTenantManagementRequest
                 * @property {mtechnavi.api.admin.ITenantManagement|null} [tenantManagement] CancelReserveDeleteTenantManagementRequest tenantManagement
                 */

                /**
                 * Constructs a new CancelReserveDeleteTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a CancelReserveDeleteTenantManagementRequest.
                 * @implements ICancelReserveDeleteTenantManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ICancelReserveDeleteTenantManagementRequest=} [properties] Properties to set
                 */
                function CancelReserveDeleteTenantManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CancelReserveDeleteTenantManagementRequest tenantManagement.
                 * @member {mtechnavi.api.admin.ITenantManagement|null|undefined} tenantManagement
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest
                 * @instance
                 */
                CancelReserveDeleteTenantManagementRequest.prototype.tenantManagement = null;

                /**
                 * Verifies a CancelReserveDeleteTenantManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelReserveDeleteTenantManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement")) {
                        let error = $root.mtechnavi.api.admin.TenantManagement.verify(message.tenantManagement);
                        if (error)
                            return "tenantManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CancelReserveDeleteTenantManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest} CancelReserveDeleteTenantManagementRequest
                 */
                CancelReserveDeleteTenantManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest();
                    if (object.tenantManagement != null) {
                        if (typeof object.tenantManagement !== "object")
                            throw TypeError(".mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest.tenantManagement: object expected");
                        message.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.fromObject(object.tenantManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CancelReserveDeleteTenantManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest
                 * @static
                 * @param {mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest} message CancelReserveDeleteTenantManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelReserveDeleteTenantManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagement = null;
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement"))
                        object.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.toObject(message.tenantManagement, options);
                    return object;
                };

                /**
                 * Converts this CancelReserveDeleteTenantManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelReserveDeleteTenantManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CancelReserveDeleteTenantManagementRequest;
            })();

            admin.CancelReserveDeleteTenantManagementResponse = (function() {

                /**
                 * Properties of a CancelReserveDeleteTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface ICancelReserveDeleteTenantManagementResponse
                 * @property {mtechnavi.api.admin.ITenantManagementContent|null} [tenantManagementContent] CancelReserveDeleteTenantManagementResponse tenantManagementContent
                 */

                /**
                 * Constructs a new CancelReserveDeleteTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a CancelReserveDeleteTenantManagementResponse.
                 * @implements ICancelReserveDeleteTenantManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.ICancelReserveDeleteTenantManagementResponse=} [properties] Properties to set
                 */
                function CancelReserveDeleteTenantManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CancelReserveDeleteTenantManagementResponse tenantManagementContent.
                 * @member {mtechnavi.api.admin.ITenantManagementContent|null|undefined} tenantManagementContent
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse
                 * @instance
                 */
                CancelReserveDeleteTenantManagementResponse.prototype.tenantManagementContent = null;

                /**
                 * Verifies a CancelReserveDeleteTenantManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelReserveDeleteTenantManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent")) {
                        let error = $root.mtechnavi.api.admin.TenantManagementContent.verify(message.tenantManagementContent);
                        if (error)
                            return "tenantManagementContent." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CancelReserveDeleteTenantManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse} CancelReserveDeleteTenantManagementResponse
                 */
                CancelReserveDeleteTenantManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse();
                    if (object.tenantManagementContent != null) {
                        if (typeof object.tenantManagementContent !== "object")
                            throw TypeError(".mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse.tenantManagementContent: object expected");
                        message.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.fromObject(object.tenantManagementContent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CancelReserveDeleteTenantManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse
                 * @static
                 * @param {mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse} message CancelReserveDeleteTenantManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelReserveDeleteTenantManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementContent = null;
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent"))
                        object.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.toObject(message.tenantManagementContent, options);
                    return object;
                };

                /**
                 * Converts this CancelReserveDeleteTenantManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.CancelReserveDeleteTenantManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelReserveDeleteTenantManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CancelReserveDeleteTenantManagementResponse;
            })();

            admin.DisableTenantManagementRequest = (function() {

                /**
                 * Properties of a DisableTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IDisableTenantManagementRequest
                 * @property {string|null} [tenantManagementId] DisableTenantManagementRequest tenantManagementId
                 */

                /**
                 * Constructs a new DisableTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a DisableTenantManagementRequest.
                 * @implements IDisableTenantManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IDisableTenantManagementRequest=} [properties] Properties to set
                 */
                function DisableTenantManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisableTenantManagementRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.DisableTenantManagementRequest
                 * @instance
                 */
                DisableTenantManagementRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies a DisableTenantManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.DisableTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableTenantManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a DisableTenantManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.DisableTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.DisableTenantManagementRequest} DisableTenantManagementRequest
                 */
                DisableTenantManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.DisableTenantManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.DisableTenantManagementRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a DisableTenantManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.DisableTenantManagementRequest
                 * @static
                 * @param {mtechnavi.api.admin.DisableTenantManagementRequest} message DisableTenantManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableTenantManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementId = "";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this DisableTenantManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.DisableTenantManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableTenantManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisableTenantManagementRequest;
            })();

            admin.DisableTenantManagementResponse = (function() {

                /**
                 * Properties of a DisableTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IDisableTenantManagementResponse
                 * @property {mtechnavi.api.admin.ITenantManagementContent|null} [tenantManagementContent] DisableTenantManagementResponse tenantManagementContent
                 */

                /**
                 * Constructs a new DisableTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a DisableTenantManagementResponse.
                 * @implements IDisableTenantManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IDisableTenantManagementResponse=} [properties] Properties to set
                 */
                function DisableTenantManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisableTenantManagementResponse tenantManagementContent.
                 * @member {mtechnavi.api.admin.ITenantManagementContent|null|undefined} tenantManagementContent
                 * @memberof mtechnavi.api.admin.DisableTenantManagementResponse
                 * @instance
                 */
                DisableTenantManagementResponse.prototype.tenantManagementContent = null;

                /**
                 * Verifies a DisableTenantManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.DisableTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableTenantManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent")) {
                        let error = $root.mtechnavi.api.admin.TenantManagementContent.verify(message.tenantManagementContent);
                        if (error)
                            return "tenantManagementContent." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DisableTenantManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.DisableTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.DisableTenantManagementResponse} DisableTenantManagementResponse
                 */
                DisableTenantManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.DisableTenantManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.DisableTenantManagementResponse();
                    if (object.tenantManagementContent != null) {
                        if (typeof object.tenantManagementContent !== "object")
                            throw TypeError(".mtechnavi.api.admin.DisableTenantManagementResponse.tenantManagementContent: object expected");
                        message.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.fromObject(object.tenantManagementContent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DisableTenantManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.DisableTenantManagementResponse
                 * @static
                 * @param {mtechnavi.api.admin.DisableTenantManagementResponse} message DisableTenantManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableTenantManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementContent = null;
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent"))
                        object.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.toObject(message.tenantManagementContent, options);
                    return object;
                };

                /**
                 * Converts this DisableTenantManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.DisableTenantManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableTenantManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisableTenantManagementResponse;
            })();

            admin.EnableTenantManagementRequest = (function() {

                /**
                 * Properties of an EnableTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IEnableTenantManagementRequest
                 * @property {string|null} [tenantManagementId] EnableTenantManagementRequest tenantManagementId
                 */

                /**
                 * Constructs a new EnableTenantManagementRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an EnableTenantManagementRequest.
                 * @implements IEnableTenantManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IEnableTenantManagementRequest=} [properties] Properties to set
                 */
                function EnableTenantManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnableTenantManagementRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.EnableTenantManagementRequest
                 * @instance
                 */
                EnableTenantManagementRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies an EnableTenantManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.EnableTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableTenantManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates an EnableTenantManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.EnableTenantManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.EnableTenantManagementRequest} EnableTenantManagementRequest
                 */
                EnableTenantManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.EnableTenantManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.EnableTenantManagementRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from an EnableTenantManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.EnableTenantManagementRequest
                 * @static
                 * @param {mtechnavi.api.admin.EnableTenantManagementRequest} message EnableTenantManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableTenantManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementId = "";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this EnableTenantManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.EnableTenantManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableTenantManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnableTenantManagementRequest;
            })();

            admin.EnableTenantManagementResponse = (function() {

                /**
                 * Properties of an EnableTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IEnableTenantManagementResponse
                 * @property {mtechnavi.api.admin.ITenantManagementContent|null} [tenantManagementContent] EnableTenantManagementResponse tenantManagementContent
                 */

                /**
                 * Constructs a new EnableTenantManagementResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an EnableTenantManagementResponse.
                 * @implements IEnableTenantManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IEnableTenantManagementResponse=} [properties] Properties to set
                 */
                function EnableTenantManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnableTenantManagementResponse tenantManagementContent.
                 * @member {mtechnavi.api.admin.ITenantManagementContent|null|undefined} tenantManagementContent
                 * @memberof mtechnavi.api.admin.EnableTenantManagementResponse
                 * @instance
                 */
                EnableTenantManagementResponse.prototype.tenantManagementContent = null;

                /**
                 * Verifies an EnableTenantManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.EnableTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableTenantManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent")) {
                        let error = $root.mtechnavi.api.admin.TenantManagementContent.verify(message.tenantManagementContent);
                        if (error)
                            return "tenantManagementContent." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EnableTenantManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.EnableTenantManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.EnableTenantManagementResponse} EnableTenantManagementResponse
                 */
                EnableTenantManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.EnableTenantManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.EnableTenantManagementResponse();
                    if (object.tenantManagementContent != null) {
                        if (typeof object.tenantManagementContent !== "object")
                            throw TypeError(".mtechnavi.api.admin.EnableTenantManagementResponse.tenantManagementContent: object expected");
                        message.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.fromObject(object.tenantManagementContent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EnableTenantManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.EnableTenantManagementResponse
                 * @static
                 * @param {mtechnavi.api.admin.EnableTenantManagementResponse} message EnableTenantManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableTenantManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementContent = null;
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent"))
                        object.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.toObject(message.tenantManagementContent, options);
                    return object;
                };

                /**
                 * Converts this EnableTenantManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.EnableTenantManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableTenantManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnableTenantManagementResponse;
            })();

            admin.SendEmailTenantInvitationRequest = (function() {

                /**
                 * Properties of a SendEmailTenantInvitationRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ISendEmailTenantInvitationRequest
                 * @property {string|null} [tenantManagementId] SendEmailTenantInvitationRequest tenantManagementId
                 * @property {string|null} [individualMessage] SendEmailTenantInvitationRequest individualMessage
                 * @property {string|null} [contactInformationMessage] SendEmailTenantInvitationRequest contactInformationMessage
                 */

                /**
                 * Constructs a new SendEmailTenantInvitationRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a SendEmailTenantInvitationRequest.
                 * @implements ISendEmailTenantInvitationRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ISendEmailTenantInvitationRequest=} [properties] Properties to set
                 */
                function SendEmailTenantInvitationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendEmailTenantInvitationRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.tenantManagementId = "";

                /**
                 * SendEmailTenantInvitationRequest individualMessage.
                 * @member {string} individualMessage
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.individualMessage = "";

                /**
                 * SendEmailTenantInvitationRequest contactInformationMessage.
                 * @member {string} contactInformationMessage
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.contactInformationMessage = "";

                /**
                 * Verifies a SendEmailTenantInvitationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailTenantInvitationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        if (!$util.isString(message.individualMessage))
                            return "individualMessage: string expected";
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        if (!$util.isString(message.contactInformationMessage))
                            return "contactInformationMessage: string expected";
                    return null;
                };

                /**
                 * Creates a SendEmailTenantInvitationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.SendEmailTenantInvitationRequest} SendEmailTenantInvitationRequest
                 */
                SendEmailTenantInvitationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.SendEmailTenantInvitationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.SendEmailTenantInvitationRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.individualMessage != null)
                        message.individualMessage = String(object.individualMessage);
                    if (object.contactInformationMessage != null)
                        message.contactInformationMessage = String(object.contactInformationMessage);
                    return message;
                };

                /**
                 * Creates a plain object from a SendEmailTenantInvitationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @static
                 * @param {mtechnavi.api.admin.SendEmailTenantInvitationRequest} message SendEmailTenantInvitationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailTenantInvitationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.individualMessage = "";
                        object.contactInformationMessage = "";
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        object.individualMessage = message.individualMessage;
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        object.contactInformationMessage = message.contactInformationMessage;
                    return object;
                };

                /**
                 * Converts this SendEmailTenantInvitationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailTenantInvitationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailTenantInvitationRequest;
            })();

            admin.SendEmailTenantInvitationResponse = (function() {

                /**
                 * Properties of a SendEmailTenantInvitationResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface ISendEmailTenantInvitationResponse
                 * @property {mtechnavi.api.admin.ITenantManagementContent|null} [tenantManagementContent] SendEmailTenantInvitationResponse tenantManagementContent
                 */

                /**
                 * Constructs a new SendEmailTenantInvitationResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a SendEmailTenantInvitationResponse.
                 * @implements ISendEmailTenantInvitationResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.ISendEmailTenantInvitationResponse=} [properties] Properties to set
                 */
                function SendEmailTenantInvitationResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendEmailTenantInvitationResponse tenantManagementContent.
                 * @member {mtechnavi.api.admin.ITenantManagementContent|null|undefined} tenantManagementContent
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationResponse
                 * @instance
                 */
                SendEmailTenantInvitationResponse.prototype.tenantManagementContent = null;

                /**
                 * Verifies a SendEmailTenantInvitationResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailTenantInvitationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent")) {
                        let error = $root.mtechnavi.api.admin.TenantManagementContent.verify(message.tenantManagementContent);
                        if (error)
                            return "tenantManagementContent." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SendEmailTenantInvitationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.SendEmailTenantInvitationResponse} SendEmailTenantInvitationResponse
                 */
                SendEmailTenantInvitationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.SendEmailTenantInvitationResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.SendEmailTenantInvitationResponse();
                    if (object.tenantManagementContent != null) {
                        if (typeof object.tenantManagementContent !== "object")
                            throw TypeError(".mtechnavi.api.admin.SendEmailTenantInvitationResponse.tenantManagementContent: object expected");
                        message.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.fromObject(object.tenantManagementContent);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SendEmailTenantInvitationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationResponse
                 * @static
                 * @param {mtechnavi.api.admin.SendEmailTenantInvitationResponse} message SendEmailTenantInvitationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailTenantInvitationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantManagementContent = null;
                    if (message.tenantManagementContent != null && message.hasOwnProperty("tenantManagementContent"))
                        object.tenantManagementContent = $root.mtechnavi.api.admin.TenantManagementContent.toObject(message.tenantManagementContent, options);
                    return object;
                };

                /**
                 * Converts this SendEmailTenantInvitationResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.SendEmailTenantInvitationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailTenantInvitationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailTenantInvitationResponse;
            })();

            admin.CreateLicenseRequest = (function() {

                /**
                 * Properties of a CreateLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ICreateLicenseRequest
                 * @property {string|null} [tenantManagementId] CreateLicenseRequest tenantManagementId
                 * @property {mtechnavi.api.license.ILicense|null} [license] CreateLicenseRequest license
                 */

                /**
                 * Constructs a new CreateLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a CreateLicenseRequest.
                 * @implements ICreateLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ICreateLicenseRequest=} [properties] Properties to set
                 */
                function CreateLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateLicenseRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @instance
                 */
                CreateLicenseRequest.prototype.tenantManagementId = "";

                /**
                 * CreateLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @instance
                 */
                CreateLicenseRequest.prototype.license = null;

                /**
                 * Verifies a CreateLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.CreateLicenseRequest} CreateLicenseRequest
                 */
                CreateLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.CreateLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.CreateLicenseRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.admin.CreateLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @static
                 * @param {mtechnavi.api.admin.CreateLicenseRequest} message CreateLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.license = null;
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this CreateLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.CreateLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateLicenseRequest;
            })();

            admin.UpdateLicenseRequest = (function() {

                /**
                 * Properties of an UpdateLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IUpdateLicenseRequest
                 * @property {string|null} [tenantManagementId] UpdateLicenseRequest tenantManagementId
                 * @property {mtechnavi.api.license.ILicense|null} [license] UpdateLicenseRequest license
                 */

                /**
                 * Constructs a new UpdateLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an UpdateLicenseRequest.
                 * @implements IUpdateLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IUpdateLicenseRequest=} [properties] Properties to set
                 */
                function UpdateLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateLicenseRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @instance
                 */
                UpdateLicenseRequest.prototype.tenantManagementId = "";

                /**
                 * UpdateLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @instance
                 */
                UpdateLicenseRequest.prototype.license = null;

                /**
                 * Verifies an UpdateLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.UpdateLicenseRequest} UpdateLicenseRequest
                 */
                UpdateLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.UpdateLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.UpdateLicenseRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.admin.UpdateLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @static
                 * @param {mtechnavi.api.admin.UpdateLicenseRequest} message UpdateLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.license = null;
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this UpdateLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.UpdateLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateLicenseRequest;
            })();

            admin.DeleteLicenseRequest = (function() {

                /**
                 * Properties of a DeleteLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IDeleteLicenseRequest
                 * @property {string|null} [tenantManagementId] DeleteLicenseRequest tenantManagementId
                 * @property {mtechnavi.api.license.ILicense|null} [license] DeleteLicenseRequest license
                 */

                /**
                 * Constructs a new DeleteLicenseRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a DeleteLicenseRequest.
                 * @implements IDeleteLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IDeleteLicenseRequest=} [properties] Properties to set
                 */
                function DeleteLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteLicenseRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @instance
                 */
                DeleteLicenseRequest.prototype.tenantManagementId = "";

                /**
                 * DeleteLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @instance
                 */
                DeleteLicenseRequest.prototype.license = null;

                /**
                 * Verifies a DeleteLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.DeleteLicenseRequest} DeleteLicenseRequest
                 */
                DeleteLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.DeleteLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.DeleteLicenseRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.admin.DeleteLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @static
                 * @param {mtechnavi.api.admin.DeleteLicenseRequest} message DeleteLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.license = null;
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this DeleteLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.DeleteLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteLicenseRequest;
            })();

            admin.BulkWriteLicensesRequest = (function() {

                /**
                 * Properties of a BulkWriteLicensesRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IBulkWriteLicensesRequest
                 * @property {string|null} [tenantManagementId] BulkWriteLicensesRequest tenantManagementId
                 * @property {Array.<mtechnavi.api.license.ILicense>|null} [items] BulkWriteLicensesRequest items
                 */

                /**
                 * Constructs a new BulkWriteLicensesRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a BulkWriteLicensesRequest.
                 * @implements IBulkWriteLicensesRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IBulkWriteLicensesRequest=} [properties] Properties to set
                 */
                function BulkWriteLicensesRequest(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteLicensesRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @instance
                 */
                BulkWriteLicensesRequest.prototype.tenantManagementId = "";

                /**
                 * BulkWriteLicensesRequest items.
                 * @member {Array.<mtechnavi.api.license.ILicense>} items
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @instance
                 */
                BulkWriteLicensesRequest.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteLicensesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteLicensesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.license.License.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteLicensesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.BulkWriteLicensesRequest} BulkWriteLicensesRequest
                 */
                BulkWriteLicensesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.BulkWriteLicensesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.BulkWriteLicensesRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.admin.BulkWriteLicensesRequest.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.BulkWriteLicensesRequest.items: object expected");
                            message.items[i] = $root.mtechnavi.api.license.License.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteLicensesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @static
                 * @param {mtechnavi.api.admin.BulkWriteLicensesRequest} message BulkWriteLicensesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteLicensesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        object.tenantManagementId = "";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.license.License.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteLicensesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteLicensesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteLicensesRequest;
            })();

            admin.BulkWriteLicensesResponse = (function() {

                /**
                 * Properties of a BulkWriteLicensesResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IBulkWriteLicensesResponse
                 * @property {Array.<mtechnavi.api.license.ILicense>|null} [items] BulkWriteLicensesResponse items
                 */

                /**
                 * Constructs a new BulkWriteLicensesResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a BulkWriteLicensesResponse.
                 * @implements IBulkWriteLicensesResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IBulkWriteLicensesResponse=} [properties] Properties to set
                 */
                function BulkWriteLicensesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteLicensesResponse items.
                 * @member {Array.<mtechnavi.api.license.ILicense>} items
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesResponse
                 * @instance
                 */
                BulkWriteLicensesResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteLicensesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteLicensesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.license.License.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteLicensesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.BulkWriteLicensesResponse} BulkWriteLicensesResponse
                 */
                BulkWriteLicensesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.BulkWriteLicensesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.BulkWriteLicensesResponse();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.admin.BulkWriteLicensesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.BulkWriteLicensesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.license.License.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteLicensesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesResponse
                 * @static
                 * @param {mtechnavi.api.admin.BulkWriteLicensesResponse} message BulkWriteLicensesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteLicensesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.license.License.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteLicensesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.BulkWriteLicensesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteLicensesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteLicensesResponse;
            })();

            admin.ListTenantAdminsRequest = (function() {

                /**
                 * Properties of a ListTenantAdminsRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IListTenantAdminsRequest
                 * @property {string|null} [pageToken] ListTenantAdminsRequest pageToken
                 * @property {Array.<string>|null} [tenantManagementIds] ListTenantAdminsRequest tenantManagementIds
                 */

                /**
                 * Constructs a new ListTenantAdminsRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListTenantAdminsRequest.
                 * @implements IListTenantAdminsRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IListTenantAdminsRequest=} [properties] Properties to set
                 */
                function ListTenantAdminsRequest(properties) {
                    this.tenantManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTenantAdminsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @instance
                 */
                ListTenantAdminsRequest.prototype.pageToken = "";

                /**
                 * ListTenantAdminsRequest tenantManagementIds.
                 * @member {Array.<string>} tenantManagementIds
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @instance
                 */
                ListTenantAdminsRequest.prototype.tenantManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListTenantAdminsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantAdminsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.tenantManagementIds != null && message.hasOwnProperty("tenantManagementIds")) {
                        if (!Array.isArray(message.tenantManagementIds))
                            return "tenantManagementIds: array expected";
                        for (let i = 0; i < message.tenantManagementIds.length; ++i)
                            if (!$util.isString(message.tenantManagementIds[i]))
                                return "tenantManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListTenantAdminsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListTenantAdminsRequest} ListTenantAdminsRequest
                 */
                ListTenantAdminsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListTenantAdminsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListTenantAdminsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.tenantManagementIds) {
                        if (!Array.isArray(object.tenantManagementIds))
                            throw TypeError(".mtechnavi.api.admin.ListTenantAdminsRequest.tenantManagementIds: array expected");
                        message.tenantManagementIds = [];
                        for (let i = 0; i < object.tenantManagementIds.length; ++i)
                            message.tenantManagementIds[i] = String(object.tenantManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListTenantAdminsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @static
                 * @param {mtechnavi.api.admin.ListTenantAdminsRequest} message ListTenantAdminsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantAdminsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.tenantManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.tenantManagementIds && message.tenantManagementIds.length) {
                        object.tenantManagementIds = [];
                        for (let j = 0; j < message.tenantManagementIds.length; ++j)
                            object.tenantManagementIds[j] = message.tenantManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListTenantAdminsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListTenantAdminsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantAdminsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantAdminsRequest;
            })();

            admin.ListTenantAdminsResponse = (function() {

                /**
                 * Properties of a ListTenantAdminsResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IListTenantAdminsResponse
                 * @property {Long|null} [total] ListTenantAdminsResponse total
                 * @property {Array.<sharelib.IUserReference>|null} [items] ListTenantAdminsResponse items
                 * @property {string|null} [pageToken] ListTenantAdminsResponse pageToken
                 */

                /**
                 * Constructs a new ListTenantAdminsResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListTenantAdminsResponse.
                 * @implements IListTenantAdminsResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IListTenantAdminsResponse=} [properties] Properties to set
                 */
                function ListTenantAdminsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTenantAdminsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @instance
                 */
                ListTenantAdminsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListTenantAdminsResponse items.
                 * @member {Array.<sharelib.IUserReference>} items
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @instance
                 */
                ListTenantAdminsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListTenantAdminsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @instance
                 */
                ListTenantAdminsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListTenantAdminsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantAdminsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListTenantAdminsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListTenantAdminsResponse} ListTenantAdminsResponse
                 */
                ListTenantAdminsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListTenantAdminsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListTenantAdminsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.admin.ListTenantAdminsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.ListTenantAdminsResponse.items: object expected");
                            message.items[i] = $root.sharelib.UserReference.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListTenantAdminsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @static
                 * @param {mtechnavi.api.admin.ListTenantAdminsResponse} message ListTenantAdminsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantAdminsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.UserReference.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListTenantAdminsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListTenantAdminsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantAdminsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantAdminsResponse;
            })();

            admin.CreateTenantAdminRequest = (function() {

                /**
                 * Properties of a CreateTenantAdminRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ICreateTenantAdminRequest
                 * @property {string|null} [tenantManagementId] CreateTenantAdminRequest tenantManagementId
                 * @property {sharelib.IUserReference|null} [tenantAdmin] CreateTenantAdminRequest tenantAdmin
                 */

                /**
                 * Constructs a new CreateTenantAdminRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a CreateTenantAdminRequest.
                 * @implements ICreateTenantAdminRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ICreateTenantAdminRequest=} [properties] Properties to set
                 */
                function CreateTenantAdminRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTenantAdminRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @instance
                 */
                CreateTenantAdminRequest.prototype.tenantManagementId = "";

                /**
                 * CreateTenantAdminRequest tenantAdmin.
                 * @member {sharelib.IUserReference|null|undefined} tenantAdmin
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @instance
                 */
                CreateTenantAdminRequest.prototype.tenantAdmin = null;

                /**
                 * Verifies a CreateTenantAdminRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTenantAdminRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.tenantAdmin != null && message.hasOwnProperty("tenantAdmin")) {
                        let error = $root.sharelib.UserReference.verify(message.tenantAdmin);
                        if (error)
                            return "tenantAdmin." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateTenantAdminRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.CreateTenantAdminRequest} CreateTenantAdminRequest
                 */
                CreateTenantAdminRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.CreateTenantAdminRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.CreateTenantAdminRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.tenantAdmin != null) {
                        if (typeof object.tenantAdmin !== "object")
                            throw TypeError(".mtechnavi.api.admin.CreateTenantAdminRequest.tenantAdmin: object expected");
                        message.tenantAdmin = $root.sharelib.UserReference.fromObject(object.tenantAdmin);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTenantAdminRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @static
                 * @param {mtechnavi.api.admin.CreateTenantAdminRequest} message CreateTenantAdminRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTenantAdminRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.tenantAdmin = null;
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.tenantAdmin != null && message.hasOwnProperty("tenantAdmin"))
                        object.tenantAdmin = $root.sharelib.UserReference.toObject(message.tenantAdmin, options);
                    return object;
                };

                /**
                 * Converts this CreateTenantAdminRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.CreateTenantAdminRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTenantAdminRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTenantAdminRequest;
            })();

            admin.SendEmailAccountCreatedRequest = (function() {

                /**
                 * Properties of a SendEmailAccountCreatedRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ISendEmailAccountCreatedRequest
                 * @property {string|null} [tenantManagementId] SendEmailAccountCreatedRequest tenantManagementId
                 * @property {string|null} [userId] SendEmailAccountCreatedRequest userId
                 */

                /**
                 * Constructs a new SendEmailAccountCreatedRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a SendEmailAccountCreatedRequest.
                 * @implements ISendEmailAccountCreatedRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ISendEmailAccountCreatedRequest=} [properties] Properties to set
                 */
                function SendEmailAccountCreatedRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendEmailAccountCreatedRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @instance
                 */
                SendEmailAccountCreatedRequest.prototype.tenantManagementId = "";

                /**
                 * SendEmailAccountCreatedRequest userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @instance
                 */
                SendEmailAccountCreatedRequest.prototype.userId = "";

                /**
                 * Verifies a SendEmailAccountCreatedRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailAccountCreatedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a SendEmailAccountCreatedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.SendEmailAccountCreatedRequest} SendEmailAccountCreatedRequest
                 */
                SendEmailAccountCreatedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.SendEmailAccountCreatedRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.SendEmailAccountCreatedRequest();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a SendEmailAccountCreatedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @static
                 * @param {mtechnavi.api.admin.SendEmailAccountCreatedRequest} message SendEmailAccountCreatedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailAccountCreatedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.userId = "";
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this SendEmailAccountCreatedRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailAccountCreatedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailAccountCreatedRequest;
            })();

            admin.SendEmailAccountCreatedResponse = (function() {

                /**
                 * Properties of a SendEmailAccountCreatedResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface ISendEmailAccountCreatedResponse
                 */

                /**
                 * Constructs a new SendEmailAccountCreatedResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a SendEmailAccountCreatedResponse.
                 * @implements ISendEmailAccountCreatedResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.ISendEmailAccountCreatedResponse=} [properties] Properties to set
                 */
                function SendEmailAccountCreatedResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SendEmailAccountCreatedResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailAccountCreatedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SendEmailAccountCreatedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.SendEmailAccountCreatedResponse} SendEmailAccountCreatedResponse
                 */
                SendEmailAccountCreatedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.SendEmailAccountCreatedResponse)
                        return object;
                    return new $root.mtechnavi.api.admin.SendEmailAccountCreatedResponse();
                };

                /**
                 * Creates a plain object from a SendEmailAccountCreatedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedResponse
                 * @static
                 * @param {mtechnavi.api.admin.SendEmailAccountCreatedResponse} message SendEmailAccountCreatedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailAccountCreatedResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SendEmailAccountCreatedResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.SendEmailAccountCreatedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailAccountCreatedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailAccountCreatedResponse;
            })();

            admin.ListInformationContentsRequest = (function() {

                /**
                 * Properties of a ListInformationContentsRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IListInformationContentsRequest
                 * @property {string|null} [pageToken] ListInformationContentsRequest pageToken
                 * @property {Array.<string>|null} [informationIds] ListInformationContentsRequest informationIds
                 */

                /**
                 * Constructs a new ListInformationContentsRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListInformationContentsRequest.
                 * @implements IListInformationContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IListInformationContentsRequest=} [properties] Properties to set
                 */
                function ListInformationContentsRequest(properties) {
                    this.informationIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListInformationContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @instance
                 */
                ListInformationContentsRequest.prototype.pageToken = "";

                /**
                 * ListInformationContentsRequest informationIds.
                 * @member {Array.<string>} informationIds
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @instance
                 */
                ListInformationContentsRequest.prototype.informationIds = $util.emptyArray;

                /**
                 * Verifies a ListInformationContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListInformationContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.informationIds != null && message.hasOwnProperty("informationIds")) {
                        if (!Array.isArray(message.informationIds))
                            return "informationIds: array expected";
                        for (let i = 0; i < message.informationIds.length; ++i)
                            if (!$util.isString(message.informationIds[i]))
                                return "informationIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListInformationContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListInformationContentsRequest} ListInformationContentsRequest
                 */
                ListInformationContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListInformationContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListInformationContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.informationIds) {
                        if (!Array.isArray(object.informationIds))
                            throw TypeError(".mtechnavi.api.admin.ListInformationContentsRequest.informationIds: array expected");
                        message.informationIds = [];
                        for (let i = 0; i < object.informationIds.length; ++i)
                            message.informationIds[i] = String(object.informationIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListInformationContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @static
                 * @param {mtechnavi.api.admin.ListInformationContentsRequest} message ListInformationContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListInformationContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.informationIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.informationIds && message.informationIds.length) {
                        object.informationIds = [];
                        for (let j = 0; j < message.informationIds.length; ++j)
                            object.informationIds[j] = message.informationIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListInformationContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListInformationContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListInformationContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListInformationContentsRequest;
            })();

            admin.ListInformationContentsResponse = (function() {

                /**
                 * Properties of a ListInformationContentsResponse.
                 * @memberof mtechnavi.api.admin
                 * @interface IListInformationContentsResponse
                 * @property {Long|null} [total] ListInformationContentsResponse total
                 * @property {Array.<mtechnavi.api.admin.IInformationContent>|null} [items] ListInformationContentsResponse items
                 * @property {string|null} [pageToken] ListInformationContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListInformationContentsResponse.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ListInformationContentsResponse.
                 * @implements IListInformationContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.admin.IListInformationContentsResponse=} [properties] Properties to set
                 */
                function ListInformationContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListInformationContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @instance
                 */
                ListInformationContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListInformationContentsResponse items.
                 * @member {Array.<mtechnavi.api.admin.IInformationContent>} items
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @instance
                 */
                ListInformationContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListInformationContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @instance
                 */
                ListInformationContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListInformationContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListInformationContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.admin.InformationContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListInformationContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ListInformationContentsResponse} ListInformationContentsResponse
                 */
                ListInformationContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ListInformationContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ListInformationContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.admin.ListInformationContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.ListInformationContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.admin.InformationContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListInformationContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @static
                 * @param {mtechnavi.api.admin.ListInformationContentsResponse} message ListInformationContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListInformationContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.admin.InformationContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListInformationContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ListInformationContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListInformationContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListInformationContentsResponse;
            })();

            admin.CreateInformationRequest = (function() {

                /**
                 * Properties of a CreateInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface ICreateInformationRequest
                 * @property {mtechnavi.api.admin.IInformation|null} [information] CreateInformationRequest information
                 */

                /**
                 * Constructs a new CreateInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a CreateInformationRequest.
                 * @implements ICreateInformationRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.ICreateInformationRequest=} [properties] Properties to set
                 */
                function CreateInformationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateInformationRequest information.
                 * @member {mtechnavi.api.admin.IInformation|null|undefined} information
                 * @memberof mtechnavi.api.admin.CreateInformationRequest
                 * @instance
                 */
                CreateInformationRequest.prototype.information = null;

                /**
                 * Verifies a CreateInformationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.CreateInformationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateInformationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.information != null && message.hasOwnProperty("information")) {
                        let error = $root.mtechnavi.api.admin.Information.verify(message.information);
                        if (error)
                            return "information." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateInformationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.CreateInformationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.CreateInformationRequest} CreateInformationRequest
                 */
                CreateInformationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.CreateInformationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.CreateInformationRequest();
                    if (object.information != null) {
                        if (typeof object.information !== "object")
                            throw TypeError(".mtechnavi.api.admin.CreateInformationRequest.information: object expected");
                        message.information = $root.mtechnavi.api.admin.Information.fromObject(object.information);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateInformationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.CreateInformationRequest
                 * @static
                 * @param {mtechnavi.api.admin.CreateInformationRequest} message CreateInformationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateInformationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.information = null;
                    if (message.information != null && message.hasOwnProperty("information"))
                        object.information = $root.mtechnavi.api.admin.Information.toObject(message.information, options);
                    return object;
                };

                /**
                 * Converts this CreateInformationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.CreateInformationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateInformationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateInformationRequest;
            })();

            admin.UpdateInformationRequest = (function() {

                /**
                 * Properties of an UpdateInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IUpdateInformationRequest
                 * @property {mtechnavi.api.admin.IInformation|null} [information] UpdateInformationRequest information
                 */

                /**
                 * Constructs a new UpdateInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an UpdateInformationRequest.
                 * @implements IUpdateInformationRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IUpdateInformationRequest=} [properties] Properties to set
                 */
                function UpdateInformationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateInformationRequest information.
                 * @member {mtechnavi.api.admin.IInformation|null|undefined} information
                 * @memberof mtechnavi.api.admin.UpdateInformationRequest
                 * @instance
                 */
                UpdateInformationRequest.prototype.information = null;

                /**
                 * Verifies an UpdateInformationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.UpdateInformationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateInformationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.information != null && message.hasOwnProperty("information")) {
                        let error = $root.mtechnavi.api.admin.Information.verify(message.information);
                        if (error)
                            return "information." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateInformationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.UpdateInformationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.UpdateInformationRequest} UpdateInformationRequest
                 */
                UpdateInformationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.UpdateInformationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.UpdateInformationRequest();
                    if (object.information != null) {
                        if (typeof object.information !== "object")
                            throw TypeError(".mtechnavi.api.admin.UpdateInformationRequest.information: object expected");
                        message.information = $root.mtechnavi.api.admin.Information.fromObject(object.information);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateInformationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.UpdateInformationRequest
                 * @static
                 * @param {mtechnavi.api.admin.UpdateInformationRequest} message UpdateInformationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateInformationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.information = null;
                    if (message.information != null && message.hasOwnProperty("information"))
                        object.information = $root.mtechnavi.api.admin.Information.toObject(message.information, options);
                    return object;
                };

                /**
                 * Converts this UpdateInformationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.UpdateInformationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateInformationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateInformationRequest;
            })();

            admin.DeleteInformationRequest = (function() {

                /**
                 * Properties of a DeleteInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IDeleteInformationRequest
                 * @property {mtechnavi.api.admin.IInformation|null} [information] DeleteInformationRequest information
                 */

                /**
                 * Constructs a new DeleteInformationRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a DeleteInformationRequest.
                 * @implements IDeleteInformationRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IDeleteInformationRequest=} [properties] Properties to set
                 */
                function DeleteInformationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteInformationRequest information.
                 * @member {mtechnavi.api.admin.IInformation|null|undefined} information
                 * @memberof mtechnavi.api.admin.DeleteInformationRequest
                 * @instance
                 */
                DeleteInformationRequest.prototype.information = null;

                /**
                 * Verifies a DeleteInformationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.DeleteInformationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteInformationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.information != null && message.hasOwnProperty("information")) {
                        let error = $root.mtechnavi.api.admin.Information.verify(message.information);
                        if (error)
                            return "information." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteInformationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.DeleteInformationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.DeleteInformationRequest} DeleteInformationRequest
                 */
                DeleteInformationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.DeleteInformationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.DeleteInformationRequest();
                    if (object.information != null) {
                        if (typeof object.information !== "object")
                            throw TypeError(".mtechnavi.api.admin.DeleteInformationRequest.information: object expected");
                        message.information = $root.mtechnavi.api.admin.Information.fromObject(object.information);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteInformationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.DeleteInformationRequest
                 * @static
                 * @param {mtechnavi.api.admin.DeleteInformationRequest} message DeleteInformationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteInformationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.information = null;
                    if (message.information != null && message.hasOwnProperty("information"))
                        object.information = $root.mtechnavi.api.admin.Information.toObject(message.information, options);
                    return object;
                };

                /**
                 * Converts this DeleteInformationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.DeleteInformationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteInformationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteInformationRequest;
            })();

            admin.ManualTenantProvisioningRequest = (function() {

                /**
                 * Properties of a ManualTenantProvisioningRequest.
                 * @memberof mtechnavi.api.admin
                 * @interface IManualTenantProvisioningRequest
                 * @property {Array.<string>|null} [companyIds] ManualTenantProvisioningRequest companyIds
                 */

                /**
                 * Constructs a new ManualTenantProvisioningRequest.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a ManualTenantProvisioningRequest.
                 * @implements IManualTenantProvisioningRequest
                 * @constructor
                 * @param {mtechnavi.api.admin.IManualTenantProvisioningRequest=} [properties] Properties to set
                 */
                function ManualTenantProvisioningRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ManualTenantProvisioningRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.admin.ManualTenantProvisioningRequest
                 * @instance
                 */
                ManualTenantProvisioningRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ManualTenantProvisioningRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.ManualTenantProvisioningRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ManualTenantProvisioningRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ManualTenantProvisioningRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.ManualTenantProvisioningRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.ManualTenantProvisioningRequest} ManualTenantProvisioningRequest
                 */
                ManualTenantProvisioningRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.ManualTenantProvisioningRequest)
                        return object;
                    let message = new $root.mtechnavi.api.admin.ManualTenantProvisioningRequest();
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.admin.ManualTenantProvisioningRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ManualTenantProvisioningRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.ManualTenantProvisioningRequest
                 * @static
                 * @param {mtechnavi.api.admin.ManualTenantProvisioningRequest} message ManualTenantProvisioningRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ManualTenantProvisioningRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ManualTenantProvisioningRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.ManualTenantProvisioningRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ManualTenantProvisioningRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ManualTenantProvisioningRequest;
            })();

            admin.TenantManagementContent = (function() {

                /**
                 * Properties of a TenantManagementContent.
                 * @memberof mtechnavi.api.admin
                 * @interface ITenantManagementContent
                 * @property {mtechnavi.api.admin.ITenantManagement|null} [tenantManagement] TenantManagementContent tenantManagement
                 * @property {mtechnavi.api.company.IBillingCompany|null} [billingCompany] TenantManagementContent billingCompany
                 * @property {Array.<mtechnavi.api.license.ILicense>|null} [licenses] TenantManagementContent licenses
                 */

                /**
                 * Constructs a new TenantManagementContent.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a TenantManagementContent.
                 * @implements ITenantManagementContent
                 * @constructor
                 * @param {mtechnavi.api.admin.ITenantManagementContent=} [properties] Properties to set
                 */
                function TenantManagementContent(properties) {
                    this.licenses = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantManagementContent tenantManagement.
                 * @member {mtechnavi.api.admin.ITenantManagement|null|undefined} tenantManagement
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @instance
                 */
                TenantManagementContent.prototype.tenantManagement = null;

                /**
                 * TenantManagementContent billingCompany.
                 * @member {mtechnavi.api.company.IBillingCompany|null|undefined} billingCompany
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @instance
                 */
                TenantManagementContent.prototype.billingCompany = null;

                /**
                 * TenantManagementContent licenses.
                 * @member {Array.<mtechnavi.api.license.ILicense>} licenses
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @instance
                 */
                TenantManagementContent.prototype.licenses = $util.emptyArray;

                /**
                 * Verifies a TenantManagementContent message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantManagementContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement")) {
                        let error = $root.mtechnavi.api.admin.TenantManagement.verify(message.tenantManagement);
                        if (error)
                            return "tenantManagement." + error;
                    }
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany")) {
                        let error = $root.mtechnavi.api.company.BillingCompany.verify(message.billingCompany);
                        if (error)
                            return "billingCompany." + error;
                    }
                    if (message.licenses != null && message.hasOwnProperty("licenses")) {
                        if (!Array.isArray(message.licenses))
                            return "licenses: array expected";
                        for (let i = 0; i < message.licenses.length; ++i) {
                            let error = $root.mtechnavi.api.license.License.verify(message.licenses[i]);
                            if (error)
                                return "licenses." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a TenantManagementContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.TenantManagementContent} TenantManagementContent
                 */
                TenantManagementContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.TenantManagementContent)
                        return object;
                    let message = new $root.mtechnavi.api.admin.TenantManagementContent();
                    if (object.tenantManagement != null) {
                        if (typeof object.tenantManagement !== "object")
                            throw TypeError(".mtechnavi.api.admin.TenantManagementContent.tenantManagement: object expected");
                        message.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.fromObject(object.tenantManagement);
                    }
                    if (object.billingCompany != null) {
                        if (typeof object.billingCompany !== "object")
                            throw TypeError(".mtechnavi.api.admin.TenantManagementContent.billingCompany: object expected");
                        message.billingCompany = $root.mtechnavi.api.company.BillingCompany.fromObject(object.billingCompany);
                    }
                    if (object.licenses) {
                        if (!Array.isArray(object.licenses))
                            throw TypeError(".mtechnavi.api.admin.TenantManagementContent.licenses: array expected");
                        message.licenses = [];
                        for (let i = 0; i < object.licenses.length; ++i) {
                            if (typeof object.licenses[i] !== "object")
                                throw TypeError(".mtechnavi.api.admin.TenantManagementContent.licenses: object expected");
                            message.licenses[i] = $root.mtechnavi.api.license.License.fromObject(object.licenses[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TenantManagementContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @static
                 * @param {mtechnavi.api.admin.TenantManagementContent} message TenantManagementContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantManagementContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenses = [];
                    if (options.defaults) {
                        object.tenantManagement = null;
                        object.billingCompany = null;
                    }
                    if (message.tenantManagement != null && message.hasOwnProperty("tenantManagement"))
                        object.tenantManagement = $root.mtechnavi.api.admin.TenantManagement.toObject(message.tenantManagement, options);
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany"))
                        object.billingCompany = $root.mtechnavi.api.company.BillingCompany.toObject(message.billingCompany, options);
                    if (message.licenses && message.licenses.length) {
                        object.licenses = [];
                        for (let j = 0; j < message.licenses.length; ++j)
                            object.licenses[j] = $root.mtechnavi.api.license.License.toObject(message.licenses[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this TenantManagementContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.TenantManagementContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantManagementContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantManagementContent;
            })();

            admin.InformationContent = (function() {

                /**
                 * Properties of an InformationContent.
                 * @memberof mtechnavi.api.admin
                 * @interface IInformationContent
                 * @property {mtechnavi.api.admin.IInformation|null} [information] InformationContent information
                 * @property {sharelib.INameOption|null} [status] InformationContent status
                 */

                /**
                 * Constructs a new InformationContent.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an InformationContent.
                 * @implements IInformationContent
                 * @constructor
                 * @param {mtechnavi.api.admin.IInformationContent=} [properties] Properties to set
                 */
                function InformationContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InformationContent information.
                 * @member {mtechnavi.api.admin.IInformation|null|undefined} information
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @instance
                 */
                InformationContent.prototype.information = null;

                /**
                 * InformationContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @instance
                 */
                InformationContent.prototype.status = null;

                /**
                 * Verifies an InformationContent message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InformationContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.information != null && message.hasOwnProperty("information")) {
                        let error = $root.mtechnavi.api.admin.Information.verify(message.information);
                        if (error)
                            return "information." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    return null;
                };

                /**
                 * Creates an InformationContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.InformationContent} InformationContent
                 */
                InformationContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.InformationContent)
                        return object;
                    let message = new $root.mtechnavi.api.admin.InformationContent();
                    if (object.information != null) {
                        if (typeof object.information !== "object")
                            throw TypeError(".mtechnavi.api.admin.InformationContent.information: object expected");
                        message.information = $root.mtechnavi.api.admin.Information.fromObject(object.information);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.admin.InformationContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InformationContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @static
                 * @param {mtechnavi.api.admin.InformationContent} message InformationContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InformationContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.information = null;
                        object.status = null;
                    }
                    if (message.information != null && message.hasOwnProperty("information"))
                        object.information = $root.mtechnavi.api.admin.Information.toObject(message.information, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    return object;
                };

                /**
                 * Converts this InformationContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.InformationContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InformationContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return InformationContent;
            })();

            admin.TenantManagement = (function() {

                /**
                 * Properties of a TenantManagement.
                 * @memberof mtechnavi.api.admin
                 * @interface ITenantManagement
                 * @property {string|null} [tenantManagementId] TenantManagement tenantManagementId
                 * @property {string|null} [tenantId] TenantManagement tenantId
                 * @property {string|null} [tenantCode] TenantManagement tenantCode
                 * @property {string|null} [domain] TenantManagement domain
                 * @property {string|null} [tenantDisplayName] TenantManagement tenantDisplayName
                 * @property {string|null} [billingCompanyId] TenantManagement billingCompanyId
                 * @property {Array.<string>|null} [licenseIds] TenantManagement licenseIds
                 * @property {string|null} [statusSystemName] TenantManagement statusSystemName
                 * @property {sharelib.INameOption|null} [status] TenantManagement status
                 * @property {Long|null} [invitedAt] TenantManagement invitedAt
                 * @property {Long|null} [tenantCreatedAt] TenantManagement tenantCreatedAt
                 * @property {Long|null} [deleteReservedAt] TenantManagement deleteReservedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] TenantManagement createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] TenantManagement updatedProperties
                 * @property {Long|null} [createdAt] TenantManagement createdAt
                 * @property {Long|null} [updatedAt] TenantManagement updatedAt
                 * @property {Long|null} [deletedAt] TenantManagement deletedAt
                 */

                /**
                 * Constructs a new TenantManagement.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents a TenantManagement.
                 * @implements ITenantManagement
                 * @constructor
                 * @param {mtechnavi.api.admin.ITenantManagement=} [properties] Properties to set
                 */
                function TenantManagement(properties) {
                    this.licenseIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantManagement tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.tenantManagementId = "";

                /**
                 * TenantManagement tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.tenantId = "";

                /**
                 * TenantManagement tenantCode.
                 * @member {string} tenantCode
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.tenantCode = "";

                /**
                 * TenantManagement domain.
                 * @member {string} domain
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.domain = "";

                /**
                 * TenantManagement tenantDisplayName.
                 * @member {string} tenantDisplayName
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.tenantDisplayName = "";

                /**
                 * TenantManagement billingCompanyId.
                 * @member {string} billingCompanyId
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.billingCompanyId = "";

                /**
                 * TenantManagement licenseIds.
                 * @member {Array.<string>} licenseIds
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.licenseIds = $util.emptyArray;

                /**
                 * TenantManagement statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.statusSystemName = "";

                /**
                 * TenantManagement status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.status = null;

                /**
                 * TenantManagement invitedAt.
                 * @member {Long} invitedAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.invitedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TenantManagement tenantCreatedAt.
                 * @member {Long} tenantCreatedAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.tenantCreatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TenantManagement deleteReservedAt.
                 * @member {Long} deleteReservedAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.deleteReservedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TenantManagement createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.createdProperties = null;

                /**
                 * TenantManagement updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.updatedProperties = null;

                /**
                 * TenantManagement createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TenantManagement updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TenantManagement deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 */
                TenantManagement.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a TenantManagement message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantManagement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.tenantCode != null && message.hasOwnProperty("tenantCode"))
                        if (!$util.isString(message.tenantCode))
                            return "tenantCode: string expected";
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        if (!$util.isString(message.domain))
                            return "domain: string expected";
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        if (!$util.isString(message.tenantDisplayName))
                            return "tenantDisplayName: string expected";
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        if (!$util.isString(message.billingCompanyId))
                            return "billingCompanyId: string expected";
                    if (message.licenseIds != null && message.hasOwnProperty("licenseIds")) {
                        if (!Array.isArray(message.licenseIds))
                            return "licenseIds: array expected";
                        for (let i = 0; i < message.licenseIds.length; ++i)
                            if (!$util.isString(message.licenseIds[i]))
                                return "licenseIds: string[] expected";
                    }
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.invitedAt != null && message.hasOwnProperty("invitedAt"))
                        if (!$util.isInteger(message.invitedAt) && !(message.invitedAt && $util.isInteger(message.invitedAt.low) && $util.isInteger(message.invitedAt.high)))
                            return "invitedAt: integer|Long expected";
                    if (message.tenantCreatedAt != null && message.hasOwnProperty("tenantCreatedAt"))
                        if (!$util.isInteger(message.tenantCreatedAt) && !(message.tenantCreatedAt && $util.isInteger(message.tenantCreatedAt.low) && $util.isInteger(message.tenantCreatedAt.high)))
                            return "tenantCreatedAt: integer|Long expected";
                    if (message.deleteReservedAt != null && message.hasOwnProperty("deleteReservedAt"))
                        if (!$util.isInteger(message.deleteReservedAt) && !(message.deleteReservedAt && $util.isInteger(message.deleteReservedAt.low) && $util.isInteger(message.deleteReservedAt.high)))
                            return "deleteReservedAt: integer|Long expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TenantManagement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.TenantManagement} TenantManagement
                 */
                TenantManagement.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.TenantManagement)
                        return object;
                    let message = new $root.mtechnavi.api.admin.TenantManagement();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.tenantCode != null)
                        message.tenantCode = String(object.tenantCode);
                    if (object.domain != null)
                        message.domain = String(object.domain);
                    if (object.tenantDisplayName != null)
                        message.tenantDisplayName = String(object.tenantDisplayName);
                    if (object.billingCompanyId != null)
                        message.billingCompanyId = String(object.billingCompanyId);
                    if (object.licenseIds) {
                        if (!Array.isArray(object.licenseIds))
                            throw TypeError(".mtechnavi.api.admin.TenantManagement.licenseIds: array expected");
                        message.licenseIds = [];
                        for (let i = 0; i < object.licenseIds.length; ++i)
                            message.licenseIds[i] = String(object.licenseIds[i]);
                    }
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.admin.TenantManagement.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.invitedAt != null)
                        if ($util.Long)
                            (message.invitedAt = $util.Long.fromValue(object.invitedAt)).unsigned = false;
                        else if (typeof object.invitedAt === "string")
                            message.invitedAt = parseInt(object.invitedAt, 10);
                        else if (typeof object.invitedAt === "number")
                            message.invitedAt = object.invitedAt;
                        else if (typeof object.invitedAt === "object")
                            message.invitedAt = new $util.LongBits(object.invitedAt.low >>> 0, object.invitedAt.high >>> 0).toNumber();
                    if (object.tenantCreatedAt != null)
                        if ($util.Long)
                            (message.tenantCreatedAt = $util.Long.fromValue(object.tenantCreatedAt)).unsigned = false;
                        else if (typeof object.tenantCreatedAt === "string")
                            message.tenantCreatedAt = parseInt(object.tenantCreatedAt, 10);
                        else if (typeof object.tenantCreatedAt === "number")
                            message.tenantCreatedAt = object.tenantCreatedAt;
                        else if (typeof object.tenantCreatedAt === "object")
                            message.tenantCreatedAt = new $util.LongBits(object.tenantCreatedAt.low >>> 0, object.tenantCreatedAt.high >>> 0).toNumber();
                    if (object.deleteReservedAt != null)
                        if ($util.Long)
                            (message.deleteReservedAt = $util.Long.fromValue(object.deleteReservedAt)).unsigned = false;
                        else if (typeof object.deleteReservedAt === "string")
                            message.deleteReservedAt = parseInt(object.deleteReservedAt, 10);
                        else if (typeof object.deleteReservedAt === "number")
                            message.deleteReservedAt = object.deleteReservedAt;
                        else if (typeof object.deleteReservedAt === "object")
                            message.deleteReservedAt = new $util.LongBits(object.deleteReservedAt.low >>> 0, object.deleteReservedAt.high >>> 0).toNumber();
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.admin.TenantManagement.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.admin.TenantManagement.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a TenantManagement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @static
                 * @param {mtechnavi.api.admin.TenantManagement} message TenantManagement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantManagement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseIds = [];
                    if (options.defaults) {
                        object.tenantManagementId = "";
                        object.tenantId = "";
                        object.tenantCode = "";
                        object.domain = "";
                        object.tenantDisplayName = "";
                        object.billingCompanyId = "";
                        object.statusSystemName = "";
                        object.status = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.invitedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.invitedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.tenantCreatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.tenantCreatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deleteReservedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deleteReservedAt = options.longs === String ? "0" : 0;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.tenantCode != null && message.hasOwnProperty("tenantCode"))
                        object.tenantCode = message.tenantCode;
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        object.domain = message.domain;
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        object.tenantDisplayName = message.tenantDisplayName;
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        object.billingCompanyId = message.billingCompanyId;
                    if (message.licenseIds && message.licenseIds.length) {
                        object.licenseIds = [];
                        for (let j = 0; j < message.licenseIds.length; ++j)
                            object.licenseIds[j] = message.licenseIds[j];
                    }
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.invitedAt != null && message.hasOwnProperty("invitedAt"))
                        if (typeof message.invitedAt === "number")
                            object.invitedAt = options.longs === String ? String(message.invitedAt) : message.invitedAt;
                        else
                            object.invitedAt = options.longs === String ? $util.Long.prototype.toString.call(message.invitedAt) : options.longs === Number ? new $util.LongBits(message.invitedAt.low >>> 0, message.invitedAt.high >>> 0).toNumber() : message.invitedAt;
                    if (message.tenantCreatedAt != null && message.hasOwnProperty("tenantCreatedAt"))
                        if (typeof message.tenantCreatedAt === "number")
                            object.tenantCreatedAt = options.longs === String ? String(message.tenantCreatedAt) : message.tenantCreatedAt;
                        else
                            object.tenantCreatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.tenantCreatedAt) : options.longs === Number ? new $util.LongBits(message.tenantCreatedAt.low >>> 0, message.tenantCreatedAt.high >>> 0).toNumber() : message.tenantCreatedAt;
                    if (message.deleteReservedAt != null && message.hasOwnProperty("deleteReservedAt"))
                        if (typeof message.deleteReservedAt === "number")
                            object.deleteReservedAt = options.longs === String ? String(message.deleteReservedAt) : message.deleteReservedAt;
                        else
                            object.deleteReservedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deleteReservedAt) : options.longs === Number ? new $util.LongBits(message.deleteReservedAt.low >>> 0, message.deleteReservedAt.high >>> 0).toNumber() : message.deleteReservedAt;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this TenantManagement to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.TenantManagement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantManagement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantManagement;
            })();

            admin.Information = (function() {

                /**
                 * Properties of an Information.
                 * @memberof mtechnavi.api.admin
                 * @interface IInformation
                 * @property {string|null} [informationId] Information informationId
                 * @property {string|null} [subject] Information subject
                 * @property {string|null} [categorySystemName] Information categorySystemName
                 * @property {sharelib.INameOption|null} [category] Information category
                 * @property {string|null} [content] Information content
                 * @property {Long|null} [startAt] Information startAt
                 * @property {Long|null} [endAt] Information endAt
                 * @property {boolean|null} [requiredRead] Information requiredRead
                 * @property {Long|null} [publishedAt] Information publishedAt
                 * @property {Long|null} [latestUpdatedAt] Information latestUpdatedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] Information createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] Information updatedProperties
                 * @property {Long|null} [createdAt] Information createdAt
                 * @property {Long|null} [updatedAt] Information updatedAt
                 * @property {Long|null} [deletedAt] Information deletedAt
                 */

                /**
                 * Constructs a new Information.
                 * @memberof mtechnavi.api.admin
                 * @classdesc Represents an Information.
                 * @implements IInformation
                 * @constructor
                 * @param {mtechnavi.api.admin.IInformation=} [properties] Properties to set
                 */
                function Information(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Information informationId.
                 * @member {string} informationId
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.informationId = "";

                /**
                 * Information subject.
                 * @member {string} subject
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.subject = "";

                /**
                 * Information categorySystemName.
                 * @member {string} categorySystemName
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.categorySystemName = "";

                /**
                 * Information category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.category = null;

                /**
                 * Information content.
                 * @member {string} content
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.content = "";

                /**
                 * Information startAt.
                 * @member {Long} startAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.startAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information endAt.
                 * @member {Long} endAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.endAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information requiredRead.
                 * @member {boolean} requiredRead
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.requiredRead = false;

                /**
                 * Information publishedAt.
                 * @member {Long} publishedAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.publishedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information latestUpdatedAt.
                 * @member {Long} latestUpdatedAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.latestUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.createdProperties = null;

                /**
                 * Information updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.updatedProperties = null;

                /**
                 * Information createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Information deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 */
                Information.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Information message.
                 * @function verify
                 * @memberof mtechnavi.api.admin.Information
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Information.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.informationId != null && message.hasOwnProperty("informationId"))
                        if (!$util.isString(message.informationId))
                            return "informationId: string expected";
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        if (!$util.isString(message.subject))
                            return "subject: string expected";
                    if (message.categorySystemName != null && message.hasOwnProperty("categorySystemName"))
                        if (!$util.isString(message.categorySystemName))
                            return "categorySystemName: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        if (!$util.isInteger(message.startAt) && !(message.startAt && $util.isInteger(message.startAt.low) && $util.isInteger(message.startAt.high)))
                            return "startAt: integer|Long expected";
                    if (message.endAt != null && message.hasOwnProperty("endAt"))
                        if (!$util.isInteger(message.endAt) && !(message.endAt && $util.isInteger(message.endAt.low) && $util.isInteger(message.endAt.high)))
                            return "endAt: integer|Long expected";
                    if (message.requiredRead != null && message.hasOwnProperty("requiredRead"))
                        if (typeof message.requiredRead !== "boolean")
                            return "requiredRead: boolean expected";
                    if (message.publishedAt != null && message.hasOwnProperty("publishedAt"))
                        if (!$util.isInteger(message.publishedAt) && !(message.publishedAt && $util.isInteger(message.publishedAt.low) && $util.isInteger(message.publishedAt.high)))
                            return "publishedAt: integer|Long expected";
                    if (message.latestUpdatedAt != null && message.hasOwnProperty("latestUpdatedAt"))
                        if (!$util.isInteger(message.latestUpdatedAt) && !(message.latestUpdatedAt && $util.isInteger(message.latestUpdatedAt.low) && $util.isInteger(message.latestUpdatedAt.high)))
                            return "latestUpdatedAt: integer|Long expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Information message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.admin.Information
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.admin.Information} Information
                 */
                Information.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.admin.Information)
                        return object;
                    let message = new $root.mtechnavi.api.admin.Information();
                    if (object.informationId != null)
                        message.informationId = String(object.informationId);
                    if (object.subject != null)
                        message.subject = String(object.subject);
                    if (object.categorySystemName != null)
                        message.categorySystemName = String(object.categorySystemName);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.admin.Information.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.startAt != null)
                        if ($util.Long)
                            (message.startAt = $util.Long.fromValue(object.startAt)).unsigned = false;
                        else if (typeof object.startAt === "string")
                            message.startAt = parseInt(object.startAt, 10);
                        else if (typeof object.startAt === "number")
                            message.startAt = object.startAt;
                        else if (typeof object.startAt === "object")
                            message.startAt = new $util.LongBits(object.startAt.low >>> 0, object.startAt.high >>> 0).toNumber();
                    if (object.endAt != null)
                        if ($util.Long)
                            (message.endAt = $util.Long.fromValue(object.endAt)).unsigned = false;
                        else if (typeof object.endAt === "string")
                            message.endAt = parseInt(object.endAt, 10);
                        else if (typeof object.endAt === "number")
                            message.endAt = object.endAt;
                        else if (typeof object.endAt === "object")
                            message.endAt = new $util.LongBits(object.endAt.low >>> 0, object.endAt.high >>> 0).toNumber();
                    if (object.requiredRead != null)
                        message.requiredRead = Boolean(object.requiredRead);
                    if (object.publishedAt != null)
                        if ($util.Long)
                            (message.publishedAt = $util.Long.fromValue(object.publishedAt)).unsigned = false;
                        else if (typeof object.publishedAt === "string")
                            message.publishedAt = parseInt(object.publishedAt, 10);
                        else if (typeof object.publishedAt === "number")
                            message.publishedAt = object.publishedAt;
                        else if (typeof object.publishedAt === "object")
                            message.publishedAt = new $util.LongBits(object.publishedAt.low >>> 0, object.publishedAt.high >>> 0).toNumber();
                    if (object.latestUpdatedAt != null)
                        if ($util.Long)
                            (message.latestUpdatedAt = $util.Long.fromValue(object.latestUpdatedAt)).unsigned = false;
                        else if (typeof object.latestUpdatedAt === "string")
                            message.latestUpdatedAt = parseInt(object.latestUpdatedAt, 10);
                        else if (typeof object.latestUpdatedAt === "number")
                            message.latestUpdatedAt = object.latestUpdatedAt;
                        else if (typeof object.latestUpdatedAt === "object")
                            message.latestUpdatedAt = new $util.LongBits(object.latestUpdatedAt.low >>> 0, object.latestUpdatedAt.high >>> 0).toNumber();
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.admin.Information.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.admin.Information.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Information message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.admin.Information
                 * @static
                 * @param {mtechnavi.api.admin.Information} message Information
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Information.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.informationId = "";
                        object.subject = "";
                        object.categorySystemName = "";
                        object.category = null;
                        object.content = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.startAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.endAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endAt = options.longs === String ? "0" : 0;
                        object.requiredRead = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.publishedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.publishedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.latestUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.latestUpdatedAt = options.longs === String ? "0" : 0;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.informationId != null && message.hasOwnProperty("informationId"))
                        object.informationId = message.informationId;
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        object.subject = message.subject;
                    if (message.categorySystemName != null && message.hasOwnProperty("categorySystemName"))
                        object.categorySystemName = message.categorySystemName;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        if (typeof message.startAt === "number")
                            object.startAt = options.longs === String ? String(message.startAt) : message.startAt;
                        else
                            object.startAt = options.longs === String ? $util.Long.prototype.toString.call(message.startAt) : options.longs === Number ? new $util.LongBits(message.startAt.low >>> 0, message.startAt.high >>> 0).toNumber() : message.startAt;
                    if (message.endAt != null && message.hasOwnProperty("endAt"))
                        if (typeof message.endAt === "number")
                            object.endAt = options.longs === String ? String(message.endAt) : message.endAt;
                        else
                            object.endAt = options.longs === String ? $util.Long.prototype.toString.call(message.endAt) : options.longs === Number ? new $util.LongBits(message.endAt.low >>> 0, message.endAt.high >>> 0).toNumber() : message.endAt;
                    if (message.requiredRead != null && message.hasOwnProperty("requiredRead"))
                        object.requiredRead = message.requiredRead;
                    if (message.publishedAt != null && message.hasOwnProperty("publishedAt"))
                        if (typeof message.publishedAt === "number")
                            object.publishedAt = options.longs === String ? String(message.publishedAt) : message.publishedAt;
                        else
                            object.publishedAt = options.longs === String ? $util.Long.prototype.toString.call(message.publishedAt) : options.longs === Number ? new $util.LongBits(message.publishedAt.low >>> 0, message.publishedAt.high >>> 0).toNumber() : message.publishedAt;
                    if (message.latestUpdatedAt != null && message.hasOwnProperty("latestUpdatedAt"))
                        if (typeof message.latestUpdatedAt === "number")
                            object.latestUpdatedAt = options.longs === String ? String(message.latestUpdatedAt) : message.latestUpdatedAt;
                        else
                            object.latestUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.latestUpdatedAt) : options.longs === Number ? new $util.LongBits(message.latestUpdatedAt.low >>> 0, message.latestUpdatedAt.high >>> 0).toNumber() : message.latestUpdatedAt;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Information to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.admin.Information
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Information.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Information;
            })();

            return admin;
        })();

        api.analysis = (function() {

            /**
             * Namespace analysis.
             * @memberof mtechnavi.api
             * @namespace
             */
            const analysis = {};

            analysis.AnalysisService = (function() {

                /**
                 * Constructs a new AnalysisService service.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an AnalysisService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AnalysisService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AnalysisService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AnalysisService;

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderPlacements}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderPlacementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse} [response] ListAnalysisOrderPlacementsResponse
                 */

                /**
                 * Calls ListAnalysisOrderPlacements.
                 * @function listAnalysisOrderPlacements
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementsRequest} request ListAnalysisOrderPlacementsRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderPlacementsCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderPlacementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderPlacements = function listAnalysisOrderPlacements(request, callback) {
                    return this.rpcCall(listAnalysisOrderPlacements, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderPlacements" });

                /**
                 * Calls ListAnalysisOrderPlacements.
                 * @function listAnalysisOrderPlacements
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementsRequest} request ListAnalysisOrderPlacementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderPlacementCategorys}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderPlacementCategorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse} [response] ListAnalysisOrderPlacementCategorysResponse
                 */

                /**
                 * Calls ListAnalysisOrderPlacementCategorys.
                 * @function listAnalysisOrderPlacementCategorys
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementCategorysRequest} request ListAnalysisOrderPlacementCategorysRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderPlacementCategorysCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderPlacementCategorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderPlacementCategorys = function listAnalysisOrderPlacementCategorys(request, callback) {
                    return this.rpcCall(listAnalysisOrderPlacementCategorys, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderPlacementCategorys" });

                /**
                 * Calls ListAnalysisOrderPlacementCategorys.
                 * @function listAnalysisOrderPlacementCategorys
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementCategorysRequest} request ListAnalysisOrderPlacementCategorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderPlacementDetails}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderPlacementDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse} [response] ListAnalysisOrderPlacementDetailsResponse
                 */

                /**
                 * Calls ListAnalysisOrderPlacementDetails.
                 * @function listAnalysisOrderPlacementDetails
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementDetailsRequest} request ListAnalysisOrderPlacementDetailsRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderPlacementDetailsCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderPlacementDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderPlacementDetails = function listAnalysisOrderPlacementDetails(request, callback) {
                    return this.rpcCall(listAnalysisOrderPlacementDetails, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderPlacementDetails" });

                /**
                 * Calls ListAnalysisOrderPlacementDetails.
                 * @function listAnalysisOrderPlacementDetails
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementDetailsRequest} request ListAnalysisOrderPlacementDetailsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderReceipts}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderReceiptsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse} [response] ListAnalysisOrderReceiptsResponse
                 */

                /**
                 * Calls ListAnalysisOrderReceipts.
                 * @function listAnalysisOrderReceipts
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptsRequest} request ListAnalysisOrderReceiptsRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderReceiptsCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderReceiptsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderReceipts = function listAnalysisOrderReceipts(request, callback) {
                    return this.rpcCall(listAnalysisOrderReceipts, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderReceipts" });

                /**
                 * Calls ListAnalysisOrderReceipts.
                 * @function listAnalysisOrderReceipts
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptsRequest} request ListAnalysisOrderReceiptsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderReceiptCategorys}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderReceiptCategorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse} [response] ListAnalysisOrderReceiptCategorysResponse
                 */

                /**
                 * Calls ListAnalysisOrderReceiptCategorys.
                 * @function listAnalysisOrderReceiptCategorys
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptCategorysRequest} request ListAnalysisOrderReceiptCategorysRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderReceiptCategorysCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderReceiptCategorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderReceiptCategorys = function listAnalysisOrderReceiptCategorys(request, callback) {
                    return this.rpcCall(listAnalysisOrderReceiptCategorys, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderReceiptCategorys" });

                /**
                 * Calls ListAnalysisOrderReceiptCategorys.
                 * @function listAnalysisOrderReceiptCategorys
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptCategorysRequest} request ListAnalysisOrderReceiptCategorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrderReceiptDetails}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrderReceiptDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse} [response] ListAnalysisOrderReceiptDetailsResponse
                 */

                /**
                 * Calls ListAnalysisOrderReceiptDetails.
                 * @function listAnalysisOrderReceiptDetails
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptDetailsRequest} request ListAnalysisOrderReceiptDetailsRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrderReceiptDetailsCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrderReceiptDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrderReceiptDetails = function listAnalysisOrderReceiptDetails(request, callback) {
                    return this.rpcCall(listAnalysisOrderReceiptDetails, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest, $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse, request, callback);
                }, "name", { value: "ListAnalysisOrderReceiptDetails" });

                /**
                 * Calls ListAnalysisOrderReceiptDetails.
                 * @function listAnalysisOrderReceiptDetails
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptDetailsRequest} request ListAnalysisOrderReceiptDetailsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisOrders}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisOrdersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisOrdersResponse} [response] ListAnalysisOrdersResponse
                 */

                /**
                 * Calls ListAnalysisOrders.
                 * @function listAnalysisOrders
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrdersRequest} request ListAnalysisOrdersRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisOrdersCallback} callback Node-style callback called with the error, if any, and ListAnalysisOrdersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisOrders = function listAnalysisOrders(request, callback) {
                    return this.rpcCall(listAnalysisOrders, $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest, $root.mtechnavi.api.analysis.ListAnalysisOrdersResponse, request, callback);
                }, "name", { value: "ListAnalysisOrders" });

                /**
                 * Calls ListAnalysisOrders.
                 * @function listAnalysisOrders
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisOrdersRequest} request ListAnalysisOrdersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisOrdersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#listAnalysisSettingItems}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ListAnalysisSettingItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListAnalysisSettingItemsResponse} [response] ListAnalysisSettingItemsResponse
                 */

                /**
                 * Calls ListAnalysisSettingItems.
                 * @function listAnalysisSettingItems
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisSettingItemsRequest} request ListAnalysisSettingItemsRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ListAnalysisSettingItemsCallback} callback Node-style callback called with the error, if any, and ListAnalysisSettingItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.listAnalysisSettingItems = function listAnalysisSettingItems(request, callback) {
                    return this.rpcCall(listAnalysisSettingItems, $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest, $root.mtechnavi.api.analysis.ListAnalysisSettingItemsResponse, request, callback);
                }, "name", { value: "ListAnalysisSettingItems" });

                /**
                 * Calls ListAnalysisSettingItems.
                 * @function listAnalysisSettingItems
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListAnalysisSettingItemsRequest} request ListAnalysisSettingItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListAnalysisSettingItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.AnalysisService#exportAnalysisOrders}.
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @typedef ExportAnalysisOrdersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ExportAnalysisOrdersResponse} [response] ExportAnalysisOrdersResponse
                 */

                /**
                 * Calls ExportAnalysisOrders.
                 * @function exportAnalysisOrders
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IExportAnalysisOrdersRequest} request ExportAnalysisOrdersRequest message or plain object
                 * @param {mtechnavi.api.analysis.AnalysisService.ExportAnalysisOrdersCallback} callback Node-style callback called with the error, if any, and ExportAnalysisOrdersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AnalysisService.prototype.exportAnalysisOrders = function exportAnalysisOrders(request, callback) {
                    return this.rpcCall(exportAnalysisOrders, $root.mtechnavi.api.analysis.ExportAnalysisOrdersRequest, $root.mtechnavi.api.analysis.ExportAnalysisOrdersResponse, request, callback);
                }, "name", { value: "ExportAnalysisOrders" });

                /**
                 * Calls ExportAnalysisOrders.
                 * @function exportAnalysisOrders
                 * @memberof mtechnavi.api.analysis.AnalysisService
                 * @instance
                 * @param {mtechnavi.api.analysis.IExportAnalysisOrdersRequest} request ExportAnalysisOrdersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ExportAnalysisOrdersResponse>} Promise
                 * @variation 2
                 */

                return AnalysisService;
            })();

            analysis.SearchCompanyService = (function() {

                /**
                 * Constructs a new SearchCompanyService service.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a SearchCompanyService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SearchCompanyService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SearchCompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SearchCompanyService;

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.SearchCompanyService#listCompanys}.
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @typedef ListCompanysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListCompanysResponse} [response] ListCompanysResponse
                 */

                /**
                 * Calls ListCompanys.
                 * @function listCompanys
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListCompanysRequest} request ListCompanysRequest message or plain object
                 * @param {mtechnavi.api.analysis.SearchCompanyService.ListCompanysCallback} callback Node-style callback called with the error, if any, and ListCompanysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SearchCompanyService.prototype.listCompanys = function listCompanys(request, callback) {
                    return this.rpcCall(listCompanys, $root.mtechnavi.api.analysis.ListCompanysRequest, $root.mtechnavi.api.analysis.ListCompanysResponse, request, callback);
                }, "name", { value: "ListCompanys" });

                /**
                 * Calls ListCompanys.
                 * @function listCompanys
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListCompanysRequest} request ListCompanysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListCompanysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.analysis.SearchCompanyService#listCompanyContents}.
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @typedef ListCompanyContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.analysis.ListCompanyContentsResponse} [response] ListCompanyContentsResponse
                 */

                /**
                 * Calls ListCompanyContents.
                 * @function listCompanyContents
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListCompanyContentsRequest} request ListCompanyContentsRequest message or plain object
                 * @param {mtechnavi.api.analysis.SearchCompanyService.ListCompanyContentsCallback} callback Node-style callback called with the error, if any, and ListCompanyContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SearchCompanyService.prototype.listCompanyContents = function listCompanyContents(request, callback) {
                    return this.rpcCall(listCompanyContents, $root.mtechnavi.api.analysis.ListCompanyContentsRequest, $root.mtechnavi.api.analysis.ListCompanyContentsResponse, request, callback);
                }, "name", { value: "ListCompanyContents" });

                /**
                 * Calls ListCompanyContents.
                 * @function listCompanyContents
                 * @memberof mtechnavi.api.analysis.SearchCompanyService
                 * @instance
                 * @param {mtechnavi.api.analysis.IListCompanyContentsRequest} request ListCompanyContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.analysis.ListCompanyContentsResponse>} Promise
                 * @variation 2
                 */

                return SearchCompanyService;
            })();

            analysis.ListAnalysisOrderPlacementsRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementsRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementsRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderPlacementsRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementsRequest.
                 * @implements IListAnalysisOrderPlacementsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementsRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementsRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderPlacementsRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderPlacementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest} ListAnalysisOrderPlacementsRequest
                 */
                ListAnalysisOrderPlacementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest} message ListAnalysisOrderPlacementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderPlacementsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderPlacementsRequest;
            })();

            analysis.ListAnalysisOrderPlacementsResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementsResponse
                 * @property {Long|null} [total] ListAnalysisOrderPlacementsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderItem>|null} [items] ListAnalysisOrderPlacementsResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementsResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementsResponse.
                 * @implements IListAnalysisOrderPlacementsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementsResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderPlacementsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderPlacementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderPlacementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse} ListAnalysisOrderPlacementsResponse
                 */
                ListAnalysisOrderPlacementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse} message ListAnalysisOrderPlacementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderPlacementsResponse;
            })();

            analysis.ListAnalysisOrderPlacementCategorysRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementCategorysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementCategorysRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementCategorysRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderPlacementCategorysRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementCategorysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementCategorysRequest.
                 * @implements IListAnalysisOrderPlacementCategorysRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementCategorysRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementCategorysRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementCategorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementCategorysRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderPlacementCategorysRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementCategorysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderPlacementCategorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementCategorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementCategorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest} ListAnalysisOrderPlacementCategorysRequest
                 */
                ListAnalysisOrderPlacementCategorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementCategorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest} message ListAnalysisOrderPlacementCategorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementCategorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementCategorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementCategorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderPlacementCategorysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     * @property {string|null} [analysisSettingItemId] DataFilter analysisSettingItemId
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * DataFilter analysisSettingItemId.
                     * @member {string} analysisSettingItemId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisSettingItemId = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            if (!$util.isString(message.analysisSettingItemId))
                                return "analysisSettingItemId: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.analysisSettingItemId != null)
                            message.analysisSettingItemId = String(object.analysisSettingItemId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.analysisSettingItemId = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            object.analysisSettingItemId = message.analysisSettingItemId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderPlacementCategorysRequest;
            })();

            analysis.ListAnalysisOrderPlacementCategorysResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementCategorysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementCategorysResponse
                 * @property {Long|null} [total] ListAnalysisOrderPlacementCategorysResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderCategoryItem>|null} [items] ListAnalysisOrderPlacementCategorysResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementCategorysResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementCategorysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementCategorysResponse.
                 * @implements IListAnalysisOrderPlacementCategorysResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementCategorysResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementCategorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementCategorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementCategorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderPlacementCategorysResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderCategoryItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementCategorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderPlacementCategorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementCategorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderPlacementCategorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementCategorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderCategoryItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementCategorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse} ListAnalysisOrderPlacementCategorysResponse
                 */
                ListAnalysisOrderPlacementCategorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderCategoryItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementCategorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse} message ListAnalysisOrderPlacementCategorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementCategorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderCategoryItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementCategorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementCategorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementCategorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderPlacementCategorysResponse;
            })();

            analysis.ListAnalysisOrderPlacementDetailsRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementDetailsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementDetailsRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementDetailsRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderPlacementDetailsRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementDetailsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementDetailsRequest.
                 * @implements IListAnalysisOrderPlacementDetailsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementDetailsRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementDetailsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementDetailsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementDetailsRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderPlacementDetailsRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @instance
                 */
                ListAnalysisOrderPlacementDetailsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderPlacementDetailsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest} ListAnalysisOrderPlacementDetailsRequest
                 */
                ListAnalysisOrderPlacementDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest} message ListAnalysisOrderPlacementDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderPlacementDetailsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     * @property {string|null} [analysisSettingItemId] DataFilter analysisSettingItemId
                     * @property {string|null} [analysisItem] DataFilter analysisItem
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * DataFilter analysisSettingItemId.
                     * @member {string} analysisSettingItemId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisSettingItemId = "";

                    /**
                     * DataFilter analysisItem.
                     * @member {string} analysisItem
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisItem = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            if (!$util.isString(message.analysisSettingItemId))
                                return "analysisSettingItemId: string expected";
                        if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                            if (!$util.isString(message.analysisItem))
                                return "analysisItem: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.analysisSettingItemId != null)
                            message.analysisSettingItemId = String(object.analysisSettingItemId);
                        if (object.analysisItem != null)
                            message.analysisItem = String(object.analysisItem);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.analysisSettingItemId = "";
                            object.analysisItem = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            object.analysisSettingItemId = message.analysisSettingItemId;
                        if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                            object.analysisItem = message.analysisItem;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderPlacementDetailsRequest;
            })();

            analysis.ListAnalysisOrderPlacementDetailsResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderPlacementDetailsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderPlacementDetailsResponse
                 * @property {Long|null} [total] ListAnalysisOrderPlacementDetailsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderDetailItem>|null} [items] ListAnalysisOrderPlacementDetailsResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderPlacementDetailsResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderPlacementDetailsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderPlacementDetailsResponse.
                 * @implements IListAnalysisOrderPlacementDetailsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderPlacementDetailsResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderPlacementDetailsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderPlacementDetailsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementDetailsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderPlacementDetailsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderDetailItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementDetailsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderPlacementDetailsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderPlacementDetailsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderPlacementDetailsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderPlacementDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderDetailItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderPlacementDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse} ListAnalysisOrderPlacementDetailsResponse
                 */
                ListAnalysisOrderPlacementDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderDetailItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderPlacementDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse} message ListAnalysisOrderPlacementDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderPlacementDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderDetailItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderPlacementDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderPlacementDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderPlacementDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderPlacementDetailsResponse;
            })();

            analysis.ListAnalysisOrderReceiptsRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptsRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptsRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderReceiptsRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptsRequest.
                 * @implements IListAnalysisOrderReceiptsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptsRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptsRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderReceiptsRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderReceiptsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest} ListAnalysisOrderReceiptsRequest
                 */
                ListAnalysisOrderReceiptsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest} message ListAnalysisOrderReceiptsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderReceiptsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderReceiptsRequest;
            })();

            analysis.ListAnalysisOrderReceiptsResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptsResponse
                 * @property {Long|null} [total] ListAnalysisOrderReceiptsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderItem>|null} [items] ListAnalysisOrderReceiptsResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptsResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptsResponse.
                 * @implements IListAnalysisOrderReceiptsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptsResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderReceiptsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderReceiptsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderReceiptsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse} ListAnalysisOrderReceiptsResponse
                 */
                ListAnalysisOrderReceiptsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse} message ListAnalysisOrderReceiptsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderReceiptsResponse;
            })();

            analysis.ListAnalysisOrderReceiptCategorysRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptCategorysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptCategorysRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptCategorysRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderReceiptCategorysRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptCategorysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptCategorysRequest.
                 * @implements IListAnalysisOrderReceiptCategorysRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptCategorysRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptCategorysRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptCategorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptCategorysRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderReceiptCategorysRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptCategorysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderReceiptCategorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptCategorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptCategorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest} ListAnalysisOrderReceiptCategorysRequest
                 */
                ListAnalysisOrderReceiptCategorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptCategorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest} message ListAnalysisOrderReceiptCategorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptCategorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptCategorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptCategorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderReceiptCategorysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     * @property {string|null} [analysisSettingItemId] DataFilter analysisSettingItemId
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * DataFilter analysisSettingItemId.
                     * @member {string} analysisSettingItemId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisSettingItemId = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            if (!$util.isString(message.analysisSettingItemId))
                                return "analysisSettingItemId: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.analysisSettingItemId != null)
                            message.analysisSettingItemId = String(object.analysisSettingItemId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.analysisSettingItemId = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            object.analysisSettingItemId = message.analysisSettingItemId;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderReceiptCategorysRequest;
            })();

            analysis.ListAnalysisOrderReceiptCategorysResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptCategorysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptCategorysResponse
                 * @property {Long|null} [total] ListAnalysisOrderReceiptCategorysResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderCategoryItem>|null} [items] ListAnalysisOrderReceiptCategorysResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptCategorysResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptCategorysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptCategorysResponse.
                 * @implements IListAnalysisOrderReceiptCategorysResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptCategorysResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptCategorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptCategorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptCategorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderReceiptCategorysResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderCategoryItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptCategorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderReceiptCategorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptCategorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderReceiptCategorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptCategorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderCategoryItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptCategorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse} ListAnalysisOrderReceiptCategorysResponse
                 */
                ListAnalysisOrderReceiptCategorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderCategoryItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptCategorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse} message ListAnalysisOrderReceiptCategorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptCategorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderCategoryItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptCategorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptCategorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptCategorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderReceiptCategorysResponse;
            })();

            analysis.ListAnalysisOrderReceiptDetailsRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptDetailsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptDetailsRequest
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptDetailsRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.IDataFilter|null} [dataFilter] ListAnalysisOrderReceiptDetailsRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptDetailsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptDetailsRequest.
                 * @implements IListAnalysisOrderReceiptDetailsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptDetailsRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptDetailsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptDetailsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptDetailsRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrderReceiptDetailsRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @instance
                 */
                ListAnalysisOrderReceiptDetailsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrderReceiptDetailsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest} ListAnalysisOrderReceiptDetailsRequest
                 */
                ListAnalysisOrderReceiptDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest} message ListAnalysisOrderReceiptDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrderReceiptDetailsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     * @property {string|null} [analysisSettingItemId] DataFilter analysisSettingItemId
                     * @property {string|null} [analysisItem] DataFilter analysisItem
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * DataFilter analysisSettingItemId.
                     * @member {string} analysisSettingItemId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisSettingItemId = "";

                    /**
                     * DataFilter analysisItem.
                     * @member {string} analysisItem
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisItem = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            if (!$util.isString(message.analysisSettingItemId))
                                return "analysisSettingItemId: string expected";
                        if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                            if (!$util.isString(message.analysisItem))
                                return "analysisItem: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.analysisSettingItemId != null)
                            message.analysisSettingItemId = String(object.analysisSettingItemId);
                        if (object.analysisItem != null)
                            message.analysisItem = String(object.analysisItem);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.baseDateType = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.analysisSettingItemId = "";
                            object.analysisItem = "";
                            object.companyId = "";
                        }
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            object.analysisSettingItemId = message.analysisSettingItemId;
                        if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                            object.analysisItem = message.analysisItem;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrderReceiptDetailsRequest;
            })();

            analysis.ListAnalysisOrderReceiptDetailsResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrderReceiptDetailsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrderReceiptDetailsResponse
                 * @property {Long|null} [total] ListAnalysisOrderReceiptDetailsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderDetailItem>|null} [items] ListAnalysisOrderReceiptDetailsResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrderReceiptDetailsResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrderReceiptDetailsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrderReceiptDetailsResponse.
                 * @implements IListAnalysisOrderReceiptDetailsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrderReceiptDetailsResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrderReceiptDetailsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrderReceiptDetailsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptDetailsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrderReceiptDetailsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderDetailItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptDetailsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrderReceiptDetailsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @instance
                 */
                ListAnalysisOrderReceiptDetailsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrderReceiptDetailsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrderReceiptDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderDetailItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrderReceiptDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse} ListAnalysisOrderReceiptDetailsResponse
                 */
                ListAnalysisOrderReceiptDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderDetailItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrderReceiptDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse} message ListAnalysisOrderReceiptDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrderReceiptDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderDetailItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrderReceiptDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrderReceiptDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrderReceiptDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrderReceiptDetailsResponse;
            })();

            analysis.ListAnalysisOrdersRequest = (function() {

                /**
                 * Properties of a ListAnalysisOrdersRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrdersRequest
                 * @property {string|null} [pageToken] ListAnalysisOrdersRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisOrdersRequest.IDataFilter|null} [dataFilter] ListAnalysisOrdersRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisOrdersRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrdersRequest.
                 * @implements IListAnalysisOrdersRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrdersRequest=} [properties] Properties to set
                 */
                function ListAnalysisOrdersRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrdersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @instance
                 */
                ListAnalysisOrdersRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisOrdersRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisOrdersRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @instance
                 */
                ListAnalysisOrdersRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisOrdersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrdersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrdersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrdersRequest} ListAnalysisOrdersRequest
                 */
                ListAnalysisOrdersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrdersRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrdersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrdersRequest} message ListAnalysisOrdersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrdersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrdersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrdersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisOrdersRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     * @property {string|null} [dataType] DataFilter dataType
                     * @property {string|null} [baseDateType] DataFilter baseDateType
                     * @property {string|null} [analysisSettingItemId] DataFilter analysisSettingItemId
                     * @property {string|null} [startDate] DataFilter startDate
                     * @property {string|null} [endDate] DataFilter endDate
                     * @property {string|null} [aggregationUnit] DataFilter aggregationUnit
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisOrdersRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * DataFilter dataType.
                     * @member {string} dataType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.dataType = "";

                    /**
                     * DataFilter baseDateType.
                     * @member {string} baseDateType
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.baseDateType = "";

                    /**
                     * DataFilter analysisSettingItemId.
                     * @member {string} analysisSettingItemId
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.analysisSettingItemId = "";

                    /**
                     * DataFilter startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.startDate = "";

                    /**
                     * DataFilter endDate.
                     * @member {string} endDate
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.endDate = "";

                    /**
                     * DataFilter aggregationUnit.
                     * @member {string} aggregationUnit
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.aggregationUnit = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        if (message.dataType != null && message.hasOwnProperty("dataType"))
                            if (!$util.isString(message.dataType))
                                return "dataType: string expected";
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            if (!$util.isString(message.baseDateType))
                                return "baseDateType: string expected";
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            if (!$util.isString(message.analysisSettingItemId))
                                return "analysisSettingItemId: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            if (!$util.isString(message.endDate))
                                return "endDate: string expected";
                        if (message.aggregationUnit != null && message.hasOwnProperty("aggregationUnit"))
                            if (!$util.isString(message.aggregationUnit))
                                return "aggregationUnit: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        if (object.dataType != null)
                            message.dataType = String(object.dataType);
                        if (object.baseDateType != null)
                            message.baseDateType = String(object.baseDateType);
                        if (object.analysisSettingItemId != null)
                            message.analysisSettingItemId = String(object.analysisSettingItemId);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.endDate != null)
                            message.endDate = String(object.endDate);
                        if (object.aggregationUnit != null)
                            message.aggregationUnit = String(object.aggregationUnit);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.dataType = "";
                            object.baseDateType = "";
                            object.analysisSettingItemId = "";
                            object.startDate = "";
                            object.endDate = "";
                            object.aggregationUnit = "";
                            object.companyId = "";
                        }
                        if (message.dataType != null && message.hasOwnProperty("dataType"))
                            object.dataType = message.dataType;
                        if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                            object.baseDateType = message.baseDateType;
                        if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                            object.analysisSettingItemId = message.analysisSettingItemId;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = message.endDate;
                        if (message.aggregationUnit != null && message.hasOwnProperty("aggregationUnit"))
                            object.aggregationUnit = message.aggregationUnit;
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisOrdersRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisOrdersRequest;
            })();

            analysis.ListAnalysisOrdersResponse = (function() {

                /**
                 * Properties of a ListAnalysisOrdersResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisOrdersResponse
                 * @property {Long|null} [total] ListAnalysisOrdersResponse total
                 * @property {Array.<mtechnavi.api.analysis.IOrderAggregatedItem>|null} [items] ListAnalysisOrdersResponse items
                 * @property {string|null} [pageToken] ListAnalysisOrdersResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisOrdersResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisOrdersResponse.
                 * @implements IListAnalysisOrdersResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisOrdersResponse=} [properties] Properties to set
                 */
                function ListAnalysisOrdersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisOrdersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @instance
                 */
                ListAnalysisOrdersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisOrdersResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IOrderAggregatedItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @instance
                 */
                ListAnalysisOrdersResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisOrdersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @instance
                 */
                ListAnalysisOrdersResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisOrdersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisOrdersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.OrderAggregatedItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisOrdersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisOrdersResponse} ListAnalysisOrdersResponse
                 */
                ListAnalysisOrdersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisOrdersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisOrdersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrdersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisOrdersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.OrderAggregatedItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisOrdersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisOrdersResponse} message ListAnalysisOrdersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisOrdersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.OrderAggregatedItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisOrdersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisOrdersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisOrdersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisOrdersResponse;
            })();

            analysis.ListAnalysisSettingItemsRequest = (function() {

                /**
                 * Properties of a ListAnalysisSettingItemsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisSettingItemsRequest
                 * @property {string|null} [pageToken] ListAnalysisSettingItemsRequest pageToken
                 * @property {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.IDataFilter|null} [dataFilter] ListAnalysisSettingItemsRequest dataFilter
                 */

                /**
                 * Constructs a new ListAnalysisSettingItemsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisSettingItemsRequest.
                 * @implements IListAnalysisSettingItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisSettingItemsRequest=} [properties] Properties to set
                 */
                function ListAnalysisSettingItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisSettingItemsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @instance
                 */
                ListAnalysisSettingItemsRequest.prototype.pageToken = "";

                /**
                 * ListAnalysisSettingItemsRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @instance
                 */
                ListAnalysisSettingItemsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListAnalysisSettingItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisSettingItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListAnalysisSettingItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest} ListAnalysisSettingItemsRequest
                 */
                ListAnalysisSettingItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisSettingItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest} message ListAnalysisSettingItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisSettingItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListAnalysisSettingItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisSettingItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListAnalysisSettingItemsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                     * @interface IDataFilter
                     * @property {string|null} [companyId] DataFilter companyId
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter companyId.
                     * @member {string} companyId
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.companyId = "";

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            if (!$util.isString(message.companyId))
                                return "companyId: string expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter();
                        if (object.companyId != null)
                            message.companyId = String(object.companyId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.companyId = "";
                        if (message.companyId != null && message.hasOwnProperty("companyId"))
                            object.companyId = message.companyId;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListAnalysisSettingItemsRequest;
            })();

            analysis.ListAnalysisSettingItemsResponse = (function() {

                /**
                 * Properties of a ListAnalysisSettingItemsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListAnalysisSettingItemsResponse
                 * @property {Long|null} [total] ListAnalysisSettingItemsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IAnalysisSettingItem>|null} [items] ListAnalysisSettingItemsResponse items
                 * @property {string|null} [pageToken] ListAnalysisSettingItemsResponse pageToken
                 */

                /**
                 * Constructs a new ListAnalysisSettingItemsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListAnalysisSettingItemsResponse.
                 * @implements IListAnalysisSettingItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListAnalysisSettingItemsResponse=} [properties] Properties to set
                 */
                function ListAnalysisSettingItemsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAnalysisSettingItemsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @instance
                 */
                ListAnalysisSettingItemsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAnalysisSettingItemsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IAnalysisSettingItem>} items
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @instance
                 */
                ListAnalysisSettingItemsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAnalysisSettingItemsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @instance
                 */
                ListAnalysisSettingItemsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAnalysisSettingItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAnalysisSettingItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.AnalysisSettingItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAnalysisSettingItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListAnalysisSettingItemsResponse} ListAnalysisSettingItemsResponse
                 */
                ListAnalysisSettingItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListAnalysisSettingItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListAnalysisSettingItemsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListAnalysisSettingItemsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListAnalysisSettingItemsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.AnalysisSettingItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAnalysisSettingItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListAnalysisSettingItemsResponse} message ListAnalysisSettingItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAnalysisSettingItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.AnalysisSettingItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAnalysisSettingItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListAnalysisSettingItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAnalysisSettingItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAnalysisSettingItemsResponse;
            })();

            analysis.ExportAnalysisOrdersRequest = (function() {

                /**
                 * Properties of an ExportAnalysisOrdersRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IExportAnalysisOrdersRequest
                 * @property {string|null} [companyId] ExportAnalysisOrdersRequest companyId
                 * @property {string|null} [dataType] ExportAnalysisOrdersRequest dataType
                 * @property {string|null} [baseDateType] ExportAnalysisOrdersRequest baseDateType
                 * @property {string|null} [analysisSettingItemId] ExportAnalysisOrdersRequest analysisSettingItemId
                 * @property {string|null} [startDate] ExportAnalysisOrdersRequest startDate
                 * @property {string|null} [endDate] ExportAnalysisOrdersRequest endDate
                 * @property {string|null} [aggregationUnit] ExportAnalysisOrdersRequest aggregationUnit
                 */

                /**
                 * Constructs a new ExportAnalysisOrdersRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an ExportAnalysisOrdersRequest.
                 * @implements IExportAnalysisOrdersRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IExportAnalysisOrdersRequest=} [properties] Properties to set
                 */
                function ExportAnalysisOrdersRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAnalysisOrdersRequest companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.companyId = "";

                /**
                 * ExportAnalysisOrdersRequest dataType.
                 * @member {string} dataType
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.dataType = "";

                /**
                 * ExportAnalysisOrdersRequest baseDateType.
                 * @member {string} baseDateType
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.baseDateType = "";

                /**
                 * ExportAnalysisOrdersRequest analysisSettingItemId.
                 * @member {string} analysisSettingItemId
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.analysisSettingItemId = "";

                /**
                 * ExportAnalysisOrdersRequest startDate.
                 * @member {string} startDate
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.startDate = "";

                /**
                 * ExportAnalysisOrdersRequest endDate.
                 * @member {string} endDate
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.endDate = "";

                /**
                 * ExportAnalysisOrdersRequest aggregationUnit.
                 * @member {string} aggregationUnit
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 */
                ExportAnalysisOrdersRequest.prototype.aggregationUnit = "";

                /**
                 * Verifies an ExportAnalysisOrdersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAnalysisOrdersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.dataType != null && message.hasOwnProperty("dataType"))
                        if (!$util.isString(message.dataType))
                            return "dataType: string expected";
                    if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                        if (!$util.isString(message.baseDateType))
                            return "baseDateType: string expected";
                    if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                        if (!$util.isString(message.analysisSettingItemId))
                            return "analysisSettingItemId: string expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        if (!$util.isString(message.startDate))
                            return "startDate: string expected";
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        if (!$util.isString(message.endDate))
                            return "endDate: string expected";
                    if (message.aggregationUnit != null && message.hasOwnProperty("aggregationUnit"))
                        if (!$util.isString(message.aggregationUnit))
                            return "aggregationUnit: string expected";
                    return null;
                };

                /**
                 * Creates an ExportAnalysisOrdersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ExportAnalysisOrdersRequest} ExportAnalysisOrdersRequest
                 */
                ExportAnalysisOrdersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ExportAnalysisOrdersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ExportAnalysisOrdersRequest();
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.dataType != null)
                        message.dataType = String(object.dataType);
                    if (object.baseDateType != null)
                        message.baseDateType = String(object.baseDateType);
                    if (object.analysisSettingItemId != null)
                        message.analysisSettingItemId = String(object.analysisSettingItemId);
                    if (object.startDate != null)
                        message.startDate = String(object.startDate);
                    if (object.endDate != null)
                        message.endDate = String(object.endDate);
                    if (object.aggregationUnit != null)
                        message.aggregationUnit = String(object.aggregationUnit);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAnalysisOrdersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ExportAnalysisOrdersRequest} message ExportAnalysisOrdersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAnalysisOrdersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.companyId = "";
                        object.dataType = "";
                        object.baseDateType = "";
                        object.analysisSettingItemId = "";
                        object.startDate = "";
                        object.endDate = "";
                        object.aggregationUnit = "";
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.dataType != null && message.hasOwnProperty("dataType"))
                        object.dataType = message.dataType;
                    if (message.baseDateType != null && message.hasOwnProperty("baseDateType"))
                        object.baseDateType = message.baseDateType;
                    if (message.analysisSettingItemId != null && message.hasOwnProperty("analysisSettingItemId"))
                        object.analysisSettingItemId = message.analysisSettingItemId;
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = message.startDate;
                    if (message.endDate != null && message.hasOwnProperty("endDate"))
                        object.endDate = message.endDate;
                    if (message.aggregationUnit != null && message.hasOwnProperty("aggregationUnit"))
                        object.aggregationUnit = message.aggregationUnit;
                    return object;
                };

                /**
                 * Converts this ExportAnalysisOrdersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAnalysisOrdersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAnalysisOrdersRequest;
            })();

            analysis.ExportAnalysisOrdersResponse = (function() {

                /**
                 * Properties of an ExportAnalysisOrdersResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IExportAnalysisOrdersResponse
                 * @property {string|null} [assetId] ExportAnalysisOrdersResponse assetId
                 * @property {string|null} [ext] ExportAnalysisOrdersResponse ext
                 */

                /**
                 * Constructs a new ExportAnalysisOrdersResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an ExportAnalysisOrdersResponse.
                 * @implements IExportAnalysisOrdersResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IExportAnalysisOrdersResponse=} [properties] Properties to set
                 */
                function ExportAnalysisOrdersResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAnalysisOrdersResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @instance
                 */
                ExportAnalysisOrdersResponse.prototype.assetId = "";

                /**
                 * ExportAnalysisOrdersResponse ext.
                 * @member {string} ext
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @instance
                 */
                ExportAnalysisOrdersResponse.prototype.ext = "";

                /**
                 * Verifies an ExportAnalysisOrdersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAnalysisOrdersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.ext != null && message.hasOwnProperty("ext"))
                        if (!$util.isString(message.ext))
                            return "ext: string expected";
                    return null;
                };

                /**
                 * Creates an ExportAnalysisOrdersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ExportAnalysisOrdersResponse} ExportAnalysisOrdersResponse
                 */
                ExportAnalysisOrdersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ExportAnalysisOrdersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ExportAnalysisOrdersResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.ext != null)
                        message.ext = String(object.ext);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAnalysisOrdersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ExportAnalysisOrdersResponse} message ExportAnalysisOrdersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAnalysisOrdersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.assetId = "";
                        object.ext = "";
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    if (message.ext != null && message.hasOwnProperty("ext"))
                        object.ext = message.ext;
                    return object;
                };

                /**
                 * Converts this ExportAnalysisOrdersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ExportAnalysisOrdersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAnalysisOrdersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAnalysisOrdersResponse;
            })();

            analysis.ListCompanysRequest = (function() {

                /**
                 * Properties of a ListCompanysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListCompanysRequest
                 * @property {Long|null} [limit] ListCompanysRequest limit
                 * @property {string|null} [pageToken] ListCompanysRequest pageToken
                 * @property {mtechnavi.api.analysis.ListCompanysRequest.IDataFilter|null} [dataFilter] ListCompanysRequest dataFilter
                 */

                /**
                 * Constructs a new ListCompanysRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListCompanysRequest.
                 * @implements IListCompanysRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListCompanysRequest=} [properties] Properties to set
                 */
                function ListCompanysRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCompanysRequest limit.
                 * @member {Long} limit
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @instance
                 */
                ListCompanysRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @instance
                 */
                ListCompanysRequest.prototype.pageToken = "";

                /**
                 * ListCompanysRequest dataFilter.
                 * @member {mtechnavi.api.analysis.ListCompanysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @instance
                 */
                ListCompanysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListCompanysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCompanysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListCompanysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListCompanysRequest} ListCompanysRequest
                 */
                ListCompanysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListCompanysRequest();
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListCompanysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListCompanysRequest} message ListCompanysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCompanysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListCompanysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListCompanysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCompanysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListCompanysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest
                     * @interface IDataFilter
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IRegionFilter>|null} [regionFilters] DataFilter regionFilters
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ISkillFilter>|null} [skillFilters] DataFilter skillFilters
                     * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesFilter|null} [facilitiesFilter] DataFilter facilitiesFilter
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesCategoryFilter>|null} [facilitiesCategoryFilters] DataFilter facilitiesCategoryFilters
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IMaterialFilter>|null} [materialFilters] DataFilter materialFilters
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ICertificateFilter>|null} [certificateFilters] DataFilter certificateFilters
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IStrengthFilter>|null} [strengthFilters] DataFilter strengthFilters
                     * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.INetSalesFilter|null} [netSalesFilter] DataFilter netSalesFilter
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IIndustryFilter>|null} [industryFilters] DataFilter industryFilters
                     * @property {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IKeywordFilter>|null} [keywordFilters] DataFilter keywordFilters
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.analysis.ListCompanysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.regionFilters = [];
                        this.skillFilters = [];
                        this.facilitiesCategoryFilters = [];
                        this.materialFilters = [];
                        this.certificateFilters = [];
                        this.strengthFilters = [];
                        this.industryFilters = [];
                        this.keywordFilters = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter regionFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IRegionFilter>} regionFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.regionFilters = $util.emptyArray;

                    /**
                     * DataFilter skillFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ISkillFilter>} skillFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.skillFilters = $util.emptyArray;

                    /**
                     * DataFilter facilitiesFilter.
                     * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesFilter|null|undefined} facilitiesFilter
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.facilitiesFilter = null;

                    /**
                     * DataFilter facilitiesCategoryFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesCategoryFilter>} facilitiesCategoryFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.facilitiesCategoryFilters = $util.emptyArray;

                    /**
                     * DataFilter materialFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IMaterialFilter>} materialFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.materialFilters = $util.emptyArray;

                    /**
                     * DataFilter certificateFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ICertificateFilter>} certificateFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.certificateFilters = $util.emptyArray;

                    /**
                     * DataFilter strengthFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IStrengthFilter>} strengthFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.strengthFilters = $util.emptyArray;

                    /**
                     * DataFilter netSalesFilter.
                     * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.INetSalesFilter|null|undefined} netSalesFilter
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.netSalesFilter = null;

                    /**
                     * DataFilter industryFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IIndustryFilter>} industryFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.industryFilters = $util.emptyArray;

                    /**
                     * DataFilter keywordFilters.
                     * @member {Array.<mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IKeywordFilter>} keywordFilters
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.keywordFilters = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.regionFilters != null && message.hasOwnProperty("regionFilters")) {
                            if (!Array.isArray(message.regionFilters))
                                return "regionFilters: array expected";
                            for (let i = 0; i < message.regionFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter.verify(message.regionFilters[i]);
                                if (error)
                                    return "regionFilters." + error;
                            }
                        }
                        if (message.skillFilters != null && message.hasOwnProperty("skillFilters")) {
                            if (!Array.isArray(message.skillFilters))
                                return "skillFilters: array expected";
                            for (let i = 0; i < message.skillFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter.verify(message.skillFilters[i]);
                                if (error)
                                    return "skillFilters." + error;
                            }
                        }
                        if (message.facilitiesFilter != null && message.hasOwnProperty("facilitiesFilter")) {
                            let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter.verify(message.facilitiesFilter);
                            if (error)
                                return "facilitiesFilter." + error;
                        }
                        if (message.facilitiesCategoryFilters != null && message.hasOwnProperty("facilitiesCategoryFilters")) {
                            if (!Array.isArray(message.facilitiesCategoryFilters))
                                return "facilitiesCategoryFilters: array expected";
                            for (let i = 0; i < message.facilitiesCategoryFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter.verify(message.facilitiesCategoryFilters[i]);
                                if (error)
                                    return "facilitiesCategoryFilters." + error;
                            }
                        }
                        if (message.materialFilters != null && message.hasOwnProperty("materialFilters")) {
                            if (!Array.isArray(message.materialFilters))
                                return "materialFilters: array expected";
                            for (let i = 0; i < message.materialFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter.verify(message.materialFilters[i]);
                                if (error)
                                    return "materialFilters." + error;
                            }
                        }
                        if (message.certificateFilters != null && message.hasOwnProperty("certificateFilters")) {
                            if (!Array.isArray(message.certificateFilters))
                                return "certificateFilters: array expected";
                            for (let i = 0; i < message.certificateFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter.verify(message.certificateFilters[i]);
                                if (error)
                                    return "certificateFilters." + error;
                            }
                        }
                        if (message.strengthFilters != null && message.hasOwnProperty("strengthFilters")) {
                            if (!Array.isArray(message.strengthFilters))
                                return "strengthFilters: array expected";
                            for (let i = 0; i < message.strengthFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter.verify(message.strengthFilters[i]);
                                if (error)
                                    return "strengthFilters." + error;
                            }
                        }
                        if (message.netSalesFilter != null && message.hasOwnProperty("netSalesFilter")) {
                            let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter.verify(message.netSalesFilter);
                            if (error)
                                return "netSalesFilter." + error;
                        }
                        if (message.industryFilters != null && message.hasOwnProperty("industryFilters")) {
                            if (!Array.isArray(message.industryFilters))
                                return "industryFilters: array expected";
                            for (let i = 0; i < message.industryFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter.verify(message.industryFilters[i]);
                                if (error)
                                    return "industryFilters." + error;
                            }
                        }
                        if (message.keywordFilters != null && message.hasOwnProperty("keywordFilters")) {
                            if (!Array.isArray(message.keywordFilters))
                                return "keywordFilters: array expected";
                            for (let i = 0; i < message.keywordFilters.length; ++i) {
                                let error = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter.verify(message.keywordFilters[i]);
                                if (error)
                                    return "keywordFilters." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter();
                        if (object.regionFilters) {
                            if (!Array.isArray(object.regionFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.regionFilters: array expected");
                            message.regionFilters = [];
                            for (let i = 0; i < object.regionFilters.length; ++i) {
                                if (typeof object.regionFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.regionFilters: object expected");
                                message.regionFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter.fromObject(object.regionFilters[i]);
                            }
                        }
                        if (object.skillFilters) {
                            if (!Array.isArray(object.skillFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.skillFilters: array expected");
                            message.skillFilters = [];
                            for (let i = 0; i < object.skillFilters.length; ++i) {
                                if (typeof object.skillFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.skillFilters: object expected");
                                message.skillFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter.fromObject(object.skillFilters[i]);
                            }
                        }
                        if (object.facilitiesFilter != null) {
                            if (typeof object.facilitiesFilter !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.facilitiesFilter: object expected");
                            message.facilitiesFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter.fromObject(object.facilitiesFilter);
                        }
                        if (object.facilitiesCategoryFilters) {
                            if (!Array.isArray(object.facilitiesCategoryFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.facilitiesCategoryFilters: array expected");
                            message.facilitiesCategoryFilters = [];
                            for (let i = 0; i < object.facilitiesCategoryFilters.length; ++i) {
                                if (typeof object.facilitiesCategoryFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.facilitiesCategoryFilters: object expected");
                                message.facilitiesCategoryFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter.fromObject(object.facilitiesCategoryFilters[i]);
                            }
                        }
                        if (object.materialFilters) {
                            if (!Array.isArray(object.materialFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.materialFilters: array expected");
                            message.materialFilters = [];
                            for (let i = 0; i < object.materialFilters.length; ++i) {
                                if (typeof object.materialFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.materialFilters: object expected");
                                message.materialFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter.fromObject(object.materialFilters[i]);
                            }
                        }
                        if (object.certificateFilters) {
                            if (!Array.isArray(object.certificateFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.certificateFilters: array expected");
                            message.certificateFilters = [];
                            for (let i = 0; i < object.certificateFilters.length; ++i) {
                                if (typeof object.certificateFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.certificateFilters: object expected");
                                message.certificateFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter.fromObject(object.certificateFilters[i]);
                            }
                        }
                        if (object.strengthFilters) {
                            if (!Array.isArray(object.strengthFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.strengthFilters: array expected");
                            message.strengthFilters = [];
                            for (let i = 0; i < object.strengthFilters.length; ++i) {
                                if (typeof object.strengthFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.strengthFilters: object expected");
                                message.strengthFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter.fromObject(object.strengthFilters[i]);
                            }
                        }
                        if (object.netSalesFilter != null) {
                            if (typeof object.netSalesFilter !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.netSalesFilter: object expected");
                            message.netSalesFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter.fromObject(object.netSalesFilter);
                        }
                        if (object.industryFilters) {
                            if (!Array.isArray(object.industryFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.industryFilters: array expected");
                            message.industryFilters = [];
                            for (let i = 0; i < object.industryFilters.length; ++i) {
                                if (typeof object.industryFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.industryFilters: object expected");
                                message.industryFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter.fromObject(object.industryFilters[i]);
                            }
                        }
                        if (object.keywordFilters) {
                            if (!Array.isArray(object.keywordFilters))
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.keywordFilters: array expected");
                            message.keywordFilters = [];
                            for (let i = 0; i < object.keywordFilters.length; ++i) {
                                if (typeof object.keywordFilters[i] !== "object")
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.keywordFilters: object expected");
                                message.keywordFilters[i] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter.fromObject(object.keywordFilters[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.regionFilters = [];
                            object.skillFilters = [];
                            object.facilitiesCategoryFilters = [];
                            object.materialFilters = [];
                            object.certificateFilters = [];
                            object.strengthFilters = [];
                            object.industryFilters = [];
                            object.keywordFilters = [];
                        }
                        if (options.defaults) {
                            object.facilitiesFilter = null;
                            object.netSalesFilter = null;
                        }
                        if (message.regionFilters && message.regionFilters.length) {
                            object.regionFilters = [];
                            for (let j = 0; j < message.regionFilters.length; ++j)
                                object.regionFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter.toObject(message.regionFilters[j], options);
                        }
                        if (message.skillFilters && message.skillFilters.length) {
                            object.skillFilters = [];
                            for (let j = 0; j < message.skillFilters.length; ++j)
                                object.skillFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter.toObject(message.skillFilters[j], options);
                        }
                        if (message.facilitiesFilter != null && message.hasOwnProperty("facilitiesFilter"))
                            object.facilitiesFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter.toObject(message.facilitiesFilter, options);
                        if (message.facilitiesCategoryFilters && message.facilitiesCategoryFilters.length) {
                            object.facilitiesCategoryFilters = [];
                            for (let j = 0; j < message.facilitiesCategoryFilters.length; ++j)
                                object.facilitiesCategoryFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter.toObject(message.facilitiesCategoryFilters[j], options);
                        }
                        if (message.materialFilters && message.materialFilters.length) {
                            object.materialFilters = [];
                            for (let j = 0; j < message.materialFilters.length; ++j)
                                object.materialFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter.toObject(message.materialFilters[j], options);
                        }
                        if (message.certificateFilters && message.certificateFilters.length) {
                            object.certificateFilters = [];
                            for (let j = 0; j < message.certificateFilters.length; ++j)
                                object.certificateFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter.toObject(message.certificateFilters[j], options);
                        }
                        if (message.strengthFilters && message.strengthFilters.length) {
                            object.strengthFilters = [];
                            for (let j = 0; j < message.strengthFilters.length; ++j)
                                object.strengthFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter.toObject(message.strengthFilters[j], options);
                        }
                        if (message.netSalesFilter != null && message.hasOwnProperty("netSalesFilter"))
                            object.netSalesFilter = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter.toObject(message.netSalesFilter, options);
                        if (message.industryFilters && message.industryFilters.length) {
                            object.industryFilters = [];
                            for (let j = 0; j < message.industryFilters.length; ++j)
                                object.industryFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter.toObject(message.industryFilters[j], options);
                        }
                        if (message.keywordFilters && message.keywordFilters.length) {
                            object.keywordFilters = [];
                            for (let j = 0; j < message.keywordFilters.length; ++j)
                                object.keywordFilters[j] = $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter.toObject(message.keywordFilters[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * FilterType enum.
                     * @name mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType
                     * @enum {number}
                     * @property {number} AND=0 AND value
                     * @property {number} OR=1 OR value
                     */
                    DataFilter.FilterType = (function() {
                        const valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "AND"] = 0;
                        values[valuesById[1] = "OR"] = 1;
                        return values;
                    })();

                    DataFilter.RegionFilter = (function() {

                        /**
                         * Properties of a RegionFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IRegionFilter
                         * @property {Array.<string>|null} [names] RegionFilter names
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] RegionFilter type
                         */

                        /**
                         * Constructs a new RegionFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a RegionFilter.
                         * @implements IRegionFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IRegionFilter=} [properties] Properties to set
                         */
                        function RegionFilter(properties) {
                            this.names = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RegionFilter names.
                         * @member {Array.<string>} names
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @instance
                         */
                        RegionFilter.prototype.names = $util.emptyArray;

                        /**
                         * RegionFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @instance
                         */
                        RegionFilter.prototype.type = 0;

                        /**
                         * Verifies a RegionFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        RegionFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (let i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a RegionFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter} RegionFilter
                         */
                        RegionFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter.names: array expected");
                                message.names = [];
                                for (let i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a RegionFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter} message RegionFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        RegionFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (let j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this RegionFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.RegionFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        RegionFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return RegionFilter;
                    })();

                    DataFilter.SkillFilter = (function() {

                        /**
                         * Properties of a SkillFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface ISkillFilter
                         * @property {Array.<string>|null} [skillIds] SkillFilter skillIds
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] SkillFilter type
                         */

                        /**
                         * Constructs a new SkillFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a SkillFilter.
                         * @implements ISkillFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ISkillFilter=} [properties] Properties to set
                         */
                        function SkillFilter(properties) {
                            this.skillIds = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SkillFilter skillIds.
                         * @member {Array.<string>} skillIds
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @instance
                         */
                        SkillFilter.prototype.skillIds = $util.emptyArray;

                        /**
                         * SkillFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @instance
                         */
                        SkillFilter.prototype.type = 0;

                        /**
                         * Verifies a SkillFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SkillFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.skillIds != null && message.hasOwnProperty("skillIds")) {
                                if (!Array.isArray(message.skillIds))
                                    return "skillIds: array expected";
                                for (let i = 0; i < message.skillIds.length; ++i)
                                    if (!$util.isString(message.skillIds[i]))
                                        return "skillIds: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a SkillFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter} SkillFilter
                         */
                        SkillFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter();
                            if (object.skillIds) {
                                if (!Array.isArray(object.skillIds))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter.skillIds: array expected");
                                message.skillIds = [];
                                for (let i = 0; i < object.skillIds.length; ++i)
                                    message.skillIds[i] = String(object.skillIds[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a SkillFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter} message SkillFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SkillFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.skillIds = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.skillIds && message.skillIds.length) {
                                object.skillIds = [];
                                for (let j = 0; j < message.skillIds.length; ++j)
                                    object.skillIds[j] = message.skillIds[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this SkillFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.SkillFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SkillFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return SkillFilter;
                    })();

                    DataFilter.FacilitiesFilter = (function() {

                        /**
                         * Properties of a FacilitiesFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IFacilitiesFilter
                         * @property {string|null} [modelNumber] FacilitiesFilter modelNumber
                         * @property {Long|null} [sizeX] FacilitiesFilter sizeX
                         * @property {Long|null} [sizeY] FacilitiesFilter sizeY
                         * @property {Long|null} [sizeZ] FacilitiesFilter sizeZ
                         * @property {Long|null} [sizeT] FacilitiesFilter sizeT
                         * @property {Long|null} [diameter] FacilitiesFilter diameter
                         */

                        /**
                         * Constructs a new FacilitiesFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a FacilitiesFilter.
                         * @implements IFacilitiesFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesFilter=} [properties] Properties to set
                         */
                        function FacilitiesFilter(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FacilitiesFilter modelNumber.
                         * @member {string} modelNumber
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.modelNumber = "";

                        /**
                         * FacilitiesFilter sizeX.
                         * @member {Long} sizeX
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.sizeX = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * FacilitiesFilter sizeY.
                         * @member {Long} sizeY
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.sizeY = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * FacilitiesFilter sizeZ.
                         * @member {Long} sizeZ
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.sizeZ = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * FacilitiesFilter sizeT.
                         * @member {Long} sizeT
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.sizeT = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * FacilitiesFilter diameter.
                         * @member {Long} diameter
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         */
                        FacilitiesFilter.prototype.diameter = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Verifies a FacilitiesFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FacilitiesFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                                if (!$util.isString(message.modelNumber))
                                    return "modelNumber: string expected";
                            if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                                if (!$util.isInteger(message.sizeX) && !(message.sizeX && $util.isInteger(message.sizeX.low) && $util.isInteger(message.sizeX.high)))
                                    return "sizeX: integer|Long expected";
                            if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                                if (!$util.isInteger(message.sizeY) && !(message.sizeY && $util.isInteger(message.sizeY.low) && $util.isInteger(message.sizeY.high)))
                                    return "sizeY: integer|Long expected";
                            if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                                if (!$util.isInteger(message.sizeZ) && !(message.sizeZ && $util.isInteger(message.sizeZ.low) && $util.isInteger(message.sizeZ.high)))
                                    return "sizeZ: integer|Long expected";
                            if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                                if (!$util.isInteger(message.sizeT) && !(message.sizeT && $util.isInteger(message.sizeT.low) && $util.isInteger(message.sizeT.high)))
                                    return "sizeT: integer|Long expected";
                            if (message.diameter != null && message.hasOwnProperty("diameter"))
                                if (!$util.isInteger(message.diameter) && !(message.diameter && $util.isInteger(message.diameter.low) && $util.isInteger(message.diameter.high)))
                                    return "diameter: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a FacilitiesFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter} FacilitiesFilter
                         */
                        FacilitiesFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter();
                            if (object.modelNumber != null)
                                message.modelNumber = String(object.modelNumber);
                            if (object.sizeX != null)
                                if ($util.Long)
                                    (message.sizeX = $util.Long.fromValue(object.sizeX)).unsigned = false;
                                else if (typeof object.sizeX === "string")
                                    message.sizeX = parseInt(object.sizeX, 10);
                                else if (typeof object.sizeX === "number")
                                    message.sizeX = object.sizeX;
                                else if (typeof object.sizeX === "object")
                                    message.sizeX = new $util.LongBits(object.sizeX.low >>> 0, object.sizeX.high >>> 0).toNumber();
                            if (object.sizeY != null)
                                if ($util.Long)
                                    (message.sizeY = $util.Long.fromValue(object.sizeY)).unsigned = false;
                                else if (typeof object.sizeY === "string")
                                    message.sizeY = parseInt(object.sizeY, 10);
                                else if (typeof object.sizeY === "number")
                                    message.sizeY = object.sizeY;
                                else if (typeof object.sizeY === "object")
                                    message.sizeY = new $util.LongBits(object.sizeY.low >>> 0, object.sizeY.high >>> 0).toNumber();
                            if (object.sizeZ != null)
                                if ($util.Long)
                                    (message.sizeZ = $util.Long.fromValue(object.sizeZ)).unsigned = false;
                                else if (typeof object.sizeZ === "string")
                                    message.sizeZ = parseInt(object.sizeZ, 10);
                                else if (typeof object.sizeZ === "number")
                                    message.sizeZ = object.sizeZ;
                                else if (typeof object.sizeZ === "object")
                                    message.sizeZ = new $util.LongBits(object.sizeZ.low >>> 0, object.sizeZ.high >>> 0).toNumber();
                            if (object.sizeT != null)
                                if ($util.Long)
                                    (message.sizeT = $util.Long.fromValue(object.sizeT)).unsigned = false;
                                else if (typeof object.sizeT === "string")
                                    message.sizeT = parseInt(object.sizeT, 10);
                                else if (typeof object.sizeT === "number")
                                    message.sizeT = object.sizeT;
                                else if (typeof object.sizeT === "object")
                                    message.sizeT = new $util.LongBits(object.sizeT.low >>> 0, object.sizeT.high >>> 0).toNumber();
                            if (object.diameter != null)
                                if ($util.Long)
                                    (message.diameter = $util.Long.fromValue(object.diameter)).unsigned = false;
                                else if (typeof object.diameter === "string")
                                    message.diameter = parseInt(object.diameter, 10);
                                else if (typeof object.diameter === "number")
                                    message.diameter = object.diameter;
                                else if (typeof object.diameter === "object")
                                    message.diameter = new $util.LongBits(object.diameter.low >>> 0, object.diameter.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a FacilitiesFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter} message FacilitiesFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FacilitiesFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults) {
                                object.modelNumber = "";
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.sizeX = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sizeX = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.sizeY = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sizeY = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.sizeZ = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sizeZ = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.sizeT = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.sizeT = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.diameter = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.diameter = options.longs === String ? "0" : 0;
                            }
                            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                                object.modelNumber = message.modelNumber;
                            if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                                if (typeof message.sizeX === "number")
                                    object.sizeX = options.longs === String ? String(message.sizeX) : message.sizeX;
                                else
                                    object.sizeX = options.longs === String ? $util.Long.prototype.toString.call(message.sizeX) : options.longs === Number ? new $util.LongBits(message.sizeX.low >>> 0, message.sizeX.high >>> 0).toNumber() : message.sizeX;
                            if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                                if (typeof message.sizeY === "number")
                                    object.sizeY = options.longs === String ? String(message.sizeY) : message.sizeY;
                                else
                                    object.sizeY = options.longs === String ? $util.Long.prototype.toString.call(message.sizeY) : options.longs === Number ? new $util.LongBits(message.sizeY.low >>> 0, message.sizeY.high >>> 0).toNumber() : message.sizeY;
                            if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                                if (typeof message.sizeZ === "number")
                                    object.sizeZ = options.longs === String ? String(message.sizeZ) : message.sizeZ;
                                else
                                    object.sizeZ = options.longs === String ? $util.Long.prototype.toString.call(message.sizeZ) : options.longs === Number ? new $util.LongBits(message.sizeZ.low >>> 0, message.sizeZ.high >>> 0).toNumber() : message.sizeZ;
                            if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                                if (typeof message.sizeT === "number")
                                    object.sizeT = options.longs === String ? String(message.sizeT) : message.sizeT;
                                else
                                    object.sizeT = options.longs === String ? $util.Long.prototype.toString.call(message.sizeT) : options.longs === Number ? new $util.LongBits(message.sizeT.low >>> 0, message.sizeT.high >>> 0).toNumber() : message.sizeT;
                            if (message.diameter != null && message.hasOwnProperty("diameter"))
                                if (typeof message.diameter === "number")
                                    object.diameter = options.longs === String ? String(message.diameter) : message.diameter;
                                else
                                    object.diameter = options.longs === String ? $util.Long.prototype.toString.call(message.diameter) : options.longs === Number ? new $util.LongBits(message.diameter.low >>> 0, message.diameter.high >>> 0).toNumber() : message.diameter;
                            return object;
                        };

                        /**
                         * Converts this FacilitiesFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FacilitiesFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FacilitiesFilter;
                    })();

                    DataFilter.FacilitiesCategoryFilter = (function() {

                        /**
                         * Properties of a FacilitiesCategoryFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IFacilitiesCategoryFilter
                         * @property {Array.<string>|null} [names] FacilitiesCategoryFilter names
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] FacilitiesCategoryFilter type
                         */

                        /**
                         * Constructs a new FacilitiesCategoryFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a FacilitiesCategoryFilter.
                         * @implements IFacilitiesCategoryFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IFacilitiesCategoryFilter=} [properties] Properties to set
                         */
                        function FacilitiesCategoryFilter(properties) {
                            this.names = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FacilitiesCategoryFilter names.
                         * @member {Array.<string>} names
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @instance
                         */
                        FacilitiesCategoryFilter.prototype.names = $util.emptyArray;

                        /**
                         * FacilitiesCategoryFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @instance
                         */
                        FacilitiesCategoryFilter.prototype.type = 0;

                        /**
                         * Verifies a FacilitiesCategoryFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FacilitiesCategoryFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (let i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a FacilitiesCategoryFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter} FacilitiesCategoryFilter
                         */
                        FacilitiesCategoryFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter.names: array expected");
                                message.names = [];
                                for (let i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FacilitiesCategoryFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter} message FacilitiesCategoryFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FacilitiesCategoryFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (let j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this FacilitiesCategoryFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FacilitiesCategoryFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FacilitiesCategoryFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return FacilitiesCategoryFilter;
                    })();

                    DataFilter.MaterialFilter = (function() {

                        /**
                         * Properties of a MaterialFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IMaterialFilter
                         * @property {Array.<string>|null} [materialIds] MaterialFilter materialIds
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] MaterialFilter type
                         */

                        /**
                         * Constructs a new MaterialFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a MaterialFilter.
                         * @implements IMaterialFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IMaterialFilter=} [properties] Properties to set
                         */
                        function MaterialFilter(properties) {
                            this.materialIds = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * MaterialFilter materialIds.
                         * @member {Array.<string>} materialIds
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @instance
                         */
                        MaterialFilter.prototype.materialIds = $util.emptyArray;

                        /**
                         * MaterialFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @instance
                         */
                        MaterialFilter.prototype.type = 0;

                        /**
                         * Verifies a MaterialFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        MaterialFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.materialIds != null && message.hasOwnProperty("materialIds")) {
                                if (!Array.isArray(message.materialIds))
                                    return "materialIds: array expected";
                                for (let i = 0; i < message.materialIds.length; ++i)
                                    if (!$util.isString(message.materialIds[i]))
                                        return "materialIds: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a MaterialFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter} MaterialFilter
                         */
                        MaterialFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter();
                            if (object.materialIds) {
                                if (!Array.isArray(object.materialIds))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter.materialIds: array expected");
                                message.materialIds = [];
                                for (let i = 0; i < object.materialIds.length; ++i)
                                    message.materialIds[i] = String(object.materialIds[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a MaterialFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter} message MaterialFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        MaterialFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.materialIds = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.materialIds && message.materialIds.length) {
                                object.materialIds = [];
                                for (let j = 0; j < message.materialIds.length; ++j)
                                    object.materialIds[j] = message.materialIds[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this MaterialFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.MaterialFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        MaterialFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return MaterialFilter;
                    })();

                    DataFilter.CertificateFilter = (function() {

                        /**
                         * Properties of a CertificateFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface ICertificateFilter
                         * @property {Array.<string>|null} [names] CertificateFilter names
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] CertificateFilter type
                         */

                        /**
                         * Constructs a new CertificateFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a CertificateFilter.
                         * @implements ICertificateFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.ICertificateFilter=} [properties] Properties to set
                         */
                        function CertificateFilter(properties) {
                            this.names = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CertificateFilter names.
                         * @member {Array.<string>} names
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @instance
                         */
                        CertificateFilter.prototype.names = $util.emptyArray;

                        /**
                         * CertificateFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @instance
                         */
                        CertificateFilter.prototype.type = 0;

                        /**
                         * Verifies a CertificateFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        CertificateFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (let i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a CertificateFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter} CertificateFilter
                         */
                        CertificateFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter.names: array expected");
                                message.names = [];
                                for (let i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a CertificateFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter} message CertificateFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        CertificateFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (let j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this CertificateFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.CertificateFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        CertificateFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return CertificateFilter;
                    })();

                    DataFilter.StrengthFilter = (function() {

                        /**
                         * Properties of a StrengthFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IStrengthFilter
                         * @property {Array.<string>|null} [names] StrengthFilter names
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] StrengthFilter type
                         */

                        /**
                         * Constructs a new StrengthFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a StrengthFilter.
                         * @implements IStrengthFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IStrengthFilter=} [properties] Properties to set
                         */
                        function StrengthFilter(properties) {
                            this.names = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StrengthFilter names.
                         * @member {Array.<string>} names
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @instance
                         */
                        StrengthFilter.prototype.names = $util.emptyArray;

                        /**
                         * StrengthFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @instance
                         */
                        StrengthFilter.prototype.type = 0;

                        /**
                         * Verifies a StrengthFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StrengthFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (let i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a StrengthFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter} StrengthFilter
                         */
                        StrengthFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter.names: array expected");
                                message.names = [];
                                for (let i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StrengthFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter} message StrengthFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StrengthFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (let j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this StrengthFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.StrengthFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StrengthFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StrengthFilter;
                    })();

                    DataFilter.NetSalesFilter = (function() {

                        /**
                         * Properties of a NetSalesFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface INetSalesFilter
                         * @property {Long|null} [netSales] NetSalesFilter netSales
                         */

                        /**
                         * Constructs a new NetSalesFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a NetSalesFilter.
                         * @implements INetSalesFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.INetSalesFilter=} [properties] Properties to set
                         */
                        function NetSalesFilter(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NetSalesFilter netSales.
                         * @member {Long} netSales
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter
                         * @instance
                         */
                        NetSalesFilter.prototype.netSales = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Verifies a NetSalesFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NetSalesFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.netSales != null && message.hasOwnProperty("netSales"))
                                if (!$util.isInteger(message.netSales) && !(message.netSales && $util.isInteger(message.netSales.low) && $util.isInteger(message.netSales.high)))
                                    return "netSales: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a NetSalesFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter} NetSalesFilter
                         */
                        NetSalesFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter();
                            if (object.netSales != null)
                                if ($util.Long)
                                    (message.netSales = $util.Long.fromValue(object.netSales)).unsigned = false;
                                else if (typeof object.netSales === "string")
                                    message.netSales = parseInt(object.netSales, 10);
                                else if (typeof object.netSales === "number")
                                    message.netSales = object.netSales;
                                else if (typeof object.netSales === "object")
                                    message.netSales = new $util.LongBits(object.netSales.low >>> 0, object.netSales.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from a NetSalesFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter} message NetSalesFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NetSalesFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults)
                                if ($util.Long) {
                                    let long = new $util.Long(0, 0, false);
                                    object.netSales = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.netSales = options.longs === String ? "0" : 0;
                            if (message.netSales != null && message.hasOwnProperty("netSales"))
                                if (typeof message.netSales === "number")
                                    object.netSales = options.longs === String ? String(message.netSales) : message.netSales;
                                else
                                    object.netSales = options.longs === String ? $util.Long.prototype.toString.call(message.netSales) : options.longs === Number ? new $util.LongBits(message.netSales.low >>> 0, message.netSales.high >>> 0).toNumber() : message.netSales;
                            return object;
                        };

                        /**
                         * Converts this NetSalesFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.NetSalesFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NetSalesFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NetSalesFilter;
                    })();

                    DataFilter.IndustryFilter = (function() {

                        /**
                         * Properties of an IndustryFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IIndustryFilter
                         * @property {Array.<string>|null} [industryIds] IndustryFilter industryIds
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] IndustryFilter type
                         */

                        /**
                         * Constructs a new IndustryFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents an IndustryFilter.
                         * @implements IIndustryFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IIndustryFilter=} [properties] Properties to set
                         */
                        function IndustryFilter(properties) {
                            this.industryIds = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * IndustryFilter industryIds.
                         * @member {Array.<string>} industryIds
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @instance
                         */
                        IndustryFilter.prototype.industryIds = $util.emptyArray;

                        /**
                         * IndustryFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @instance
                         */
                        IndustryFilter.prototype.type = 0;

                        /**
                         * Verifies an IndustryFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        IndustryFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.industryIds != null && message.hasOwnProperty("industryIds")) {
                                if (!Array.isArray(message.industryIds))
                                    return "industryIds: array expected";
                                for (let i = 0; i < message.industryIds.length; ++i)
                                    if (!$util.isString(message.industryIds[i]))
                                        return "industryIds: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates an IndustryFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter} IndustryFilter
                         */
                        IndustryFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter();
                            if (object.industryIds) {
                                if (!Array.isArray(object.industryIds))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter.industryIds: array expected");
                                message.industryIds = [];
                                for (let i = 0; i < object.industryIds.length; ++i)
                                    message.industryIds[i] = String(object.industryIds[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an IndustryFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter} message IndustryFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        IndustryFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.industryIds = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.industryIds && message.industryIds.length) {
                                object.industryIds = [];
                                for (let j = 0; j < message.industryIds.length; ++j)
                                    object.industryIds[j] = message.industryIds[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this IndustryFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IndustryFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        IndustryFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return IndustryFilter;
                    })();

                    DataFilter.KeywordFilter = (function() {

                        /**
                         * Properties of a KeywordFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @interface IKeywordFilter
                         * @property {Array.<string>|null} [names] KeywordFilter names
                         * @property {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType|null} [type] KeywordFilter type
                         */

                        /**
                         * Constructs a new KeywordFilter.
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter
                         * @classdesc Represents a KeywordFilter.
                         * @implements IKeywordFilter
                         * @constructor
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.IKeywordFilter=} [properties] Properties to set
                         */
                        function KeywordFilter(properties) {
                            this.names = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * KeywordFilter names.
                         * @member {Array.<string>} names
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @instance
                         */
                        KeywordFilter.prototype.names = $util.emptyArray;

                        /**
                         * KeywordFilter type.
                         * @member {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType} type
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @instance
                         */
                        KeywordFilter.prototype.type = 0;

                        /**
                         * Verifies a KeywordFilter message.
                         * @function verify
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        KeywordFilter.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.names != null && message.hasOwnProperty("names")) {
                                if (!Array.isArray(message.names))
                                    return "names: array expected";
                                for (let i = 0; i < message.names.length; ++i)
                                    if (!$util.isString(message.names[i]))
                                        return "names: string[] expected";
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                switch (message.type) {
                                default:
                                    return "type: enum value expected";
                                case 0:
                                case 1:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a KeywordFilter message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter} KeywordFilter
                         */
                        KeywordFilter.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter)
                                return object;
                            let message = new $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter();
                            if (object.names) {
                                if (!Array.isArray(object.names))
                                    throw TypeError(".mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter.names: array expected");
                                message.names = [];
                                for (let i = 0; i < object.names.length; ++i)
                                    message.names[i] = String(object.names[i]);
                            }
                            switch (object.type) {
                            case "AND":
                            case 0:
                                message.type = 0;
                                break;
                            case "OR":
                            case 1:
                                message.type = 1;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a KeywordFilter message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @static
                         * @param {mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter} message KeywordFilter
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        KeywordFilter.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.names = [];
                            if (options.defaults)
                                object.type = options.enums === String ? "AND" : 0;
                            if (message.names && message.names.length) {
                                object.names = [];
                                for (let j = 0; j < message.names.length; ++j)
                                    object.names[j] = message.names[j];
                            }
                            if (message.type != null && message.hasOwnProperty("type"))
                                object.type = options.enums === String ? $root.mtechnavi.api.analysis.ListCompanysRequest.DataFilter.FilterType[message.type] : message.type;
                            return object;
                        };

                        /**
                         * Converts this KeywordFilter to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.analysis.ListCompanysRequest.DataFilter.KeywordFilter
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        KeywordFilter.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return KeywordFilter;
                    })();

                    return DataFilter;
                })();

                return ListCompanysRequest;
            })();

            analysis.ListCompanysResponse = (function() {

                /**
                 * Properties of a ListCompanysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListCompanysResponse
                 * @property {Long|null} [count] ListCompanysResponse count
                 * @property {Long|null} [total] ListCompanysResponse total
                 * @property {Array.<mtechnavi.api.analysis.IViewCompany>|null} [items] ListCompanysResponse items
                 * @property {string|null} [pageToken] ListCompanysResponse pageToken
                 */

                /**
                 * Constructs a new ListCompanysResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListCompanysResponse.
                 * @implements IListCompanysResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListCompanysResponse=} [properties] Properties to set
                 */
                function ListCompanysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCompanysResponse count.
                 * @member {Long} count
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @instance
                 */
                ListCompanysResponse.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @instance
                 */
                ListCompanysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanysResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IViewCompany>} items
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @instance
                 */
                ListCompanysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListCompanysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @instance
                 */
                ListCompanysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListCompanysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCompanysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.count != null && message.hasOwnProperty("count"))
                        if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                            return "count: integer|Long expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.ViewCompany.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListCompanysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListCompanysResponse} ListCompanysResponse
                 */
                ListCompanysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListCompanysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListCompanysResponse();
                    if (object.count != null)
                        if ($util.Long)
                            (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                        else if (typeof object.count === "string")
                            message.count = parseInt(object.count, 10);
                        else if (typeof object.count === "number")
                            message.count = object.count;
                        else if (typeof object.count === "object")
                            message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListCompanysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListCompanysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.ViewCompany.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListCompanysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListCompanysResponse} message ListCompanysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCompanysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.count = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.count != null && message.hasOwnProperty("count"))
                        if (typeof message.count === "number")
                            object.count = options.longs === String ? String(message.count) : message.count;
                        else
                            object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.ViewCompany.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListCompanysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListCompanysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCompanysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListCompanysResponse;
            })();

            analysis.ListCompanyContentsRequest = (function() {

                /**
                 * Properties of a ListCompanyContentsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListCompanyContentsRequest
                 * @property {Long|null} [limit] ListCompanyContentsRequest limit
                 * @property {string|null} [pageToken] ListCompanyContentsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListCompanyContentsRequest companyIds
                 */

                /**
                 * Constructs a new ListCompanyContentsRequest.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListCompanyContentsRequest.
                 * @implements IListCompanyContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListCompanyContentsRequest=} [properties] Properties to set
                 */
                function ListCompanyContentsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCompanyContentsRequest limit.
                 * @member {Long} limit
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @instance
                 */
                ListCompanyContentsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanyContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @instance
                 */
                ListCompanyContentsRequest.prototype.pageToken = "";

                /**
                 * ListCompanyContentsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @instance
                 */
                ListCompanyContentsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListCompanyContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCompanyContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListCompanyContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListCompanyContentsRequest} ListCompanyContentsRequest
                 */
                ListCompanyContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListCompanyContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListCompanyContentsRequest();
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.analysis.ListCompanyContentsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListCompanyContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @static
                 * @param {mtechnavi.api.analysis.ListCompanyContentsRequest} message ListCompanyContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCompanyContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListCompanyContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCompanyContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListCompanyContentsRequest;
            })();

            analysis.ListCompanyContentsResponse = (function() {

                /**
                 * Properties of a ListCompanyContentsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @interface IListCompanyContentsResponse
                 * @property {Long|null} [count] ListCompanyContentsResponse count
                 * @property {Long|null} [total] ListCompanyContentsResponse total
                 * @property {Array.<mtechnavi.api.analysis.IViewCompany>|null} [items] ListCompanyContentsResponse items
                 * @property {string|null} [pageToken] ListCompanyContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListCompanyContentsResponse.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ListCompanyContentsResponse.
                 * @implements IListCompanyContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.analysis.IListCompanyContentsResponse=} [properties] Properties to set
                 */
                function ListCompanyContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCompanyContentsResponse count.
                 * @member {Long} count
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @instance
                 */
                ListCompanyContentsResponse.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanyContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @instance
                 */
                ListCompanyContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCompanyContentsResponse items.
                 * @member {Array.<mtechnavi.api.analysis.IViewCompany>} items
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @instance
                 */
                ListCompanyContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListCompanyContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @instance
                 */
                ListCompanyContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListCompanyContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCompanyContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.count != null && message.hasOwnProperty("count"))
                        if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                            return "count: integer|Long expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.ViewCompany.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListCompanyContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ListCompanyContentsResponse} ListCompanyContentsResponse
                 */
                ListCompanyContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ListCompanyContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ListCompanyContentsResponse();
                    if (object.count != null)
                        if ($util.Long)
                            (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                        else if (typeof object.count === "string")
                            message.count = parseInt(object.count, 10);
                        else if (typeof object.count === "number")
                            message.count = object.count;
                        else if (typeof object.count === "object")
                            message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.analysis.ListCompanyContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ListCompanyContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.analysis.ViewCompany.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListCompanyContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @static
                 * @param {mtechnavi.api.analysis.ListCompanyContentsResponse} message ListCompanyContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCompanyContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.count = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.count != null && message.hasOwnProperty("count"))
                        if (typeof message.count === "number")
                            object.count = options.longs === String ? String(message.count) : message.count;
                        else
                            object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.analysis.ViewCompany.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListCompanyContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ListCompanyContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCompanyContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListCompanyContentsResponse;
            })();

            analysis.OrderItem = (function() {

                /**
                 * Properties of an OrderItem.
                 * @memberof mtechnavi.api.analysis
                 * @interface IOrderItem
                 * @property {string|null} [baseDate] OrderItem baseDate
                 * @property {string|null} [amountNum] OrderItem amountNum
                 */

                /**
                 * Constructs a new OrderItem.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an OrderItem.
                 * @implements IOrderItem
                 * @constructor
                 * @param {mtechnavi.api.analysis.IOrderItem=} [properties] Properties to set
                 */
                function OrderItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderItem baseDate.
                 * @member {string} baseDate
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @instance
                 */
                OrderItem.prototype.baseDate = "";

                /**
                 * OrderItem amountNum.
                 * @member {string} amountNum
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @instance
                 */
                OrderItem.prototype.amountNum = "";

                /**
                 * Verifies an OrderItem message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        if (!$util.isString(message.baseDate))
                            return "baseDate: string expected";
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        if (!$util.isString(message.amountNum))
                            return "amountNum: string expected";
                    return null;
                };

                /**
                 * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.OrderItem} OrderItem
                 */
                OrderItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.OrderItem)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.OrderItem();
                    if (object.baseDate != null)
                        message.baseDate = String(object.baseDate);
                    if (object.amountNum != null)
                        message.amountNum = String(object.amountNum);
                    return message;
                };

                /**
                 * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @static
                 * @param {mtechnavi.api.analysis.OrderItem} message OrderItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.baseDate = "";
                        object.amountNum = "";
                    }
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        object.baseDate = message.baseDate;
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        object.amountNum = message.amountNum;
                    return object;
                };

                /**
                 * Converts this OrderItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.OrderItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrderItem;
            })();

            analysis.OrderCategoryItem = (function() {

                /**
                 * Properties of an OrderCategoryItem.
                 * @memberof mtechnavi.api.analysis
                 * @interface IOrderCategoryItem
                 * @property {string|null} [analysisItem] OrderCategoryItem analysisItem
                 * @property {string|null} [amountNum] OrderCategoryItem amountNum
                 */

                /**
                 * Constructs a new OrderCategoryItem.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an OrderCategoryItem.
                 * @implements IOrderCategoryItem
                 * @constructor
                 * @param {mtechnavi.api.analysis.IOrderCategoryItem=} [properties] Properties to set
                 */
                function OrderCategoryItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderCategoryItem analysisItem.
                 * @member {string} analysisItem
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @instance
                 */
                OrderCategoryItem.prototype.analysisItem = "";

                /**
                 * OrderCategoryItem amountNum.
                 * @member {string} amountNum
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @instance
                 */
                OrderCategoryItem.prototype.amountNum = "";

                /**
                 * Verifies an OrderCategoryItem message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderCategoryItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                        if (!$util.isString(message.analysisItem))
                            return "analysisItem: string expected";
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        if (!$util.isString(message.amountNum))
                            return "amountNum: string expected";
                    return null;
                };

                /**
                 * Creates an OrderCategoryItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.OrderCategoryItem} OrderCategoryItem
                 */
                OrderCategoryItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.OrderCategoryItem)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.OrderCategoryItem();
                    if (object.analysisItem != null)
                        message.analysisItem = String(object.analysisItem);
                    if (object.amountNum != null)
                        message.amountNum = String(object.amountNum);
                    return message;
                };

                /**
                 * Creates a plain object from an OrderCategoryItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @static
                 * @param {mtechnavi.api.analysis.OrderCategoryItem} message OrderCategoryItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderCategoryItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.analysisItem = "";
                        object.amountNum = "";
                    }
                    if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                        object.analysisItem = message.analysisItem;
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        object.amountNum = message.amountNum;
                    return object;
                };

                /**
                 * Converts this OrderCategoryItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.OrderCategoryItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderCategoryItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrderCategoryItem;
            })();

            analysis.OrderDetailItem = (function() {

                /**
                 * Properties of an OrderDetailItem.
                 * @memberof mtechnavi.api.analysis
                 * @interface IOrderDetailItem
                 * @property {string|null} [baseDate] OrderDetailItem baseDate
                 * @property {string|null} [unitPriceNum] OrderDetailItem unitPriceNum
                 * @property {string|null} [quantityNum] OrderDetailItem quantityNum
                 * @property {string|null} [amountNum] OrderDetailItem amountNum
                 * @property {string|null} [inspectionQuantityNum] OrderDetailItem inspectionQuantityNum
                 * @property {string|null} [inspectionAmountNum] OrderDetailItem inspectionAmountNum
                 * @property {string|null} [defectiveRate] OrderDetailItem defectiveRate
                 */

                /**
                 * Constructs a new OrderDetailItem.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an OrderDetailItem.
                 * @implements IOrderDetailItem
                 * @constructor
                 * @param {mtechnavi.api.analysis.IOrderDetailItem=} [properties] Properties to set
                 */
                function OrderDetailItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderDetailItem baseDate.
                 * @member {string} baseDate
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.baseDate = "";

                /**
                 * OrderDetailItem unitPriceNum.
                 * @member {string} unitPriceNum
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.unitPriceNum = "";

                /**
                 * OrderDetailItem quantityNum.
                 * @member {string} quantityNum
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.quantityNum = "";

                /**
                 * OrderDetailItem amountNum.
                 * @member {string} amountNum
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.amountNum = "";

                /**
                 * OrderDetailItem inspectionQuantityNum.
                 * @member {string} inspectionQuantityNum
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.inspectionQuantityNum = "";

                /**
                 * OrderDetailItem inspectionAmountNum.
                 * @member {string} inspectionAmountNum
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.inspectionAmountNum = "";

                /**
                 * OrderDetailItem defectiveRate.
                 * @member {string} defectiveRate
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 */
                OrderDetailItem.prototype.defectiveRate = "";

                /**
                 * Verifies an OrderDetailItem message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderDetailItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        if (!$util.isString(message.baseDate))
                            return "baseDate: string expected";
                    if (message.unitPriceNum != null && message.hasOwnProperty("unitPriceNum"))
                        if (!$util.isString(message.unitPriceNum))
                            return "unitPriceNum: string expected";
                    if (message.quantityNum != null && message.hasOwnProperty("quantityNum"))
                        if (!$util.isString(message.quantityNum))
                            return "quantityNum: string expected";
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        if (!$util.isString(message.amountNum))
                            return "amountNum: string expected";
                    if (message.inspectionQuantityNum != null && message.hasOwnProperty("inspectionQuantityNum"))
                        if (!$util.isString(message.inspectionQuantityNum))
                            return "inspectionQuantityNum: string expected";
                    if (message.inspectionAmountNum != null && message.hasOwnProperty("inspectionAmountNum"))
                        if (!$util.isString(message.inspectionAmountNum))
                            return "inspectionAmountNum: string expected";
                    if (message.defectiveRate != null && message.hasOwnProperty("defectiveRate"))
                        if (!$util.isString(message.defectiveRate))
                            return "defectiveRate: string expected";
                    return null;
                };

                /**
                 * Creates an OrderDetailItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.OrderDetailItem} OrderDetailItem
                 */
                OrderDetailItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.OrderDetailItem)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.OrderDetailItem();
                    if (object.baseDate != null)
                        message.baseDate = String(object.baseDate);
                    if (object.unitPriceNum != null)
                        message.unitPriceNum = String(object.unitPriceNum);
                    if (object.quantityNum != null)
                        message.quantityNum = String(object.quantityNum);
                    if (object.amountNum != null)
                        message.amountNum = String(object.amountNum);
                    if (object.inspectionQuantityNum != null)
                        message.inspectionQuantityNum = String(object.inspectionQuantityNum);
                    if (object.inspectionAmountNum != null)
                        message.inspectionAmountNum = String(object.inspectionAmountNum);
                    if (object.defectiveRate != null)
                        message.defectiveRate = String(object.defectiveRate);
                    return message;
                };

                /**
                 * Creates a plain object from an OrderDetailItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @static
                 * @param {mtechnavi.api.analysis.OrderDetailItem} message OrderDetailItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderDetailItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.baseDate = "";
                        object.unitPriceNum = "";
                        object.quantityNum = "";
                        object.amountNum = "";
                        object.inspectionQuantityNum = "";
                        object.inspectionAmountNum = "";
                        object.defectiveRate = "";
                    }
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        object.baseDate = message.baseDate;
                    if (message.unitPriceNum != null && message.hasOwnProperty("unitPriceNum"))
                        object.unitPriceNum = message.unitPriceNum;
                    if (message.quantityNum != null && message.hasOwnProperty("quantityNum"))
                        object.quantityNum = message.quantityNum;
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        object.amountNum = message.amountNum;
                    if (message.inspectionQuantityNum != null && message.hasOwnProperty("inspectionQuantityNum"))
                        object.inspectionQuantityNum = message.inspectionQuantityNum;
                    if (message.inspectionAmountNum != null && message.hasOwnProperty("inspectionAmountNum"))
                        object.inspectionAmountNum = message.inspectionAmountNum;
                    if (message.defectiveRate != null && message.hasOwnProperty("defectiveRate"))
                        object.defectiveRate = message.defectiveRate;
                    return object;
                };

                /**
                 * Converts this OrderDetailItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.OrderDetailItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderDetailItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrderDetailItem;
            })();

            analysis.OrderAggregatedItem = (function() {

                /**
                 * Properties of an OrderAggregatedItem.
                 * @memberof mtechnavi.api.analysis
                 * @interface IOrderAggregatedItem
                 * @property {string|null} [baseDate] OrderAggregatedItem baseDate
                 * @property {string|null} [analysisItem] OrderAggregatedItem analysisItem
                 * @property {string|null} [amountNum] OrderAggregatedItem amountNum
                 */

                /**
                 * Constructs a new OrderAggregatedItem.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an OrderAggregatedItem.
                 * @implements IOrderAggregatedItem
                 * @constructor
                 * @param {mtechnavi.api.analysis.IOrderAggregatedItem=} [properties] Properties to set
                 */
                function OrderAggregatedItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderAggregatedItem baseDate.
                 * @member {string} baseDate
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @instance
                 */
                OrderAggregatedItem.prototype.baseDate = "";

                /**
                 * OrderAggregatedItem analysisItem.
                 * @member {string} analysisItem
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @instance
                 */
                OrderAggregatedItem.prototype.analysisItem = "";

                /**
                 * OrderAggregatedItem amountNum.
                 * @member {string} amountNum
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @instance
                 */
                OrderAggregatedItem.prototype.amountNum = "";

                /**
                 * Verifies an OrderAggregatedItem message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderAggregatedItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        if (!$util.isString(message.baseDate))
                            return "baseDate: string expected";
                    if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                        if (!$util.isString(message.analysisItem))
                            return "analysisItem: string expected";
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        if (!$util.isString(message.amountNum))
                            return "amountNum: string expected";
                    return null;
                };

                /**
                 * Creates an OrderAggregatedItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.OrderAggregatedItem} OrderAggregatedItem
                 */
                OrderAggregatedItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.OrderAggregatedItem)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.OrderAggregatedItem();
                    if (object.baseDate != null)
                        message.baseDate = String(object.baseDate);
                    if (object.analysisItem != null)
                        message.analysisItem = String(object.analysisItem);
                    if (object.amountNum != null)
                        message.amountNum = String(object.amountNum);
                    return message;
                };

                /**
                 * Creates a plain object from an OrderAggregatedItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @static
                 * @param {mtechnavi.api.analysis.OrderAggregatedItem} message OrderAggregatedItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderAggregatedItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.baseDate = "";
                        object.analysisItem = "";
                        object.amountNum = "";
                    }
                    if (message.baseDate != null && message.hasOwnProperty("baseDate"))
                        object.baseDate = message.baseDate;
                    if (message.analysisItem != null && message.hasOwnProperty("analysisItem"))
                        object.analysisItem = message.analysisItem;
                    if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                        object.amountNum = message.amountNum;
                    return object;
                };

                /**
                 * Converts this OrderAggregatedItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.OrderAggregatedItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderAggregatedItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrderAggregatedItem;
            })();

            analysis.AnalysisSettingItem = (function() {

                /**
                 * Properties of an AnalysisSettingItem.
                 * @memberof mtechnavi.api.analysis
                 * @interface IAnalysisSettingItem
                 * @property {string|null} [dataType] AnalysisSettingItem dataType
                 * @property {string|null} [id] AnalysisSettingItem id
                 * @property {string|null} [displayName] AnalysisSettingItem displayName
                 */

                /**
                 * Constructs a new AnalysisSettingItem.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents an AnalysisSettingItem.
                 * @implements IAnalysisSettingItem
                 * @constructor
                 * @param {mtechnavi.api.analysis.IAnalysisSettingItem=} [properties] Properties to set
                 */
                function AnalysisSettingItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AnalysisSettingItem dataType.
                 * @member {string} dataType
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @instance
                 */
                AnalysisSettingItem.prototype.dataType = "";

                /**
                 * AnalysisSettingItem id.
                 * @member {string} id
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @instance
                 */
                AnalysisSettingItem.prototype.id = "";

                /**
                 * AnalysisSettingItem displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @instance
                 */
                AnalysisSettingItem.prototype.displayName = "";

                /**
                 * Verifies an AnalysisSettingItem message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AnalysisSettingItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.dataType != null && message.hasOwnProperty("dataType"))
                        if (!$util.isString(message.dataType))
                            return "dataType: string expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates an AnalysisSettingItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.AnalysisSettingItem} AnalysisSettingItem
                 */
                AnalysisSettingItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.AnalysisSettingItem)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.AnalysisSettingItem();
                    if (object.dataType != null)
                        message.dataType = String(object.dataType);
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from an AnalysisSettingItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @static
                 * @param {mtechnavi.api.analysis.AnalysisSettingItem} message AnalysisSettingItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AnalysisSettingItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.dataType = "";
                        object.id = "";
                        object.displayName = "";
                    }
                    if (message.dataType != null && message.hasOwnProperty("dataType"))
                        object.dataType = message.dataType;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this AnalysisSettingItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.AnalysisSettingItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AnalysisSettingItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AnalysisSettingItem;
            })();

            analysis.NameOption = (function() {

                /**
                 * Properties of a NameOption.
                 * @memberof mtechnavi.api.analysis
                 * @interface INameOption
                 * @property {string|null} [categoryName] NameOption categoryName
                 * @property {string|null} [systemName] NameOption systemName
                 * @property {string|null} [displayNameLangJa] NameOption displayNameLangJa
                 */

                /**
                 * Constructs a new NameOption.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a NameOption.
                 * @implements INameOption
                 * @constructor
                 * @param {mtechnavi.api.analysis.INameOption=} [properties] Properties to set
                 */
                function NameOption(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NameOption categoryName.
                 * @member {string} categoryName
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @instance
                 */
                NameOption.prototype.categoryName = "";

                /**
                 * NameOption systemName.
                 * @member {string} systemName
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @instance
                 */
                NameOption.prototype.systemName = "";

                /**
                 * NameOption displayNameLangJa.
                 * @member {string} displayNameLangJa
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @instance
                 */
                NameOption.prototype.displayNameLangJa = "";

                /**
                 * Verifies a NameOption message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NameOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                        if (!$util.isString(message.categoryName))
                            return "categoryName: string expected";
                    if (message.systemName != null && message.hasOwnProperty("systemName"))
                        if (!$util.isString(message.systemName))
                            return "systemName: string expected";
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                        if (!$util.isString(message.displayNameLangJa))
                            return "displayNameLangJa: string expected";
                    return null;
                };

                /**
                 * Creates a NameOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.NameOption} NameOption
                 */
                NameOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.NameOption)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.NameOption();
                    if (object.categoryName != null)
                        message.categoryName = String(object.categoryName);
                    if (object.systemName != null)
                        message.systemName = String(object.systemName);
                    if (object.displayNameLangJa != null)
                        message.displayNameLangJa = String(object.displayNameLangJa);
                    return message;
                };

                /**
                 * Creates a plain object from a NameOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @static
                 * @param {mtechnavi.api.analysis.NameOption} message NameOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NameOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.categoryName = "";
                        object.systemName = "";
                        object.displayNameLangJa = "";
                    }
                    if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                        object.categoryName = message.categoryName;
                    if (message.systemName != null && message.hasOwnProperty("systemName"))
                        object.systemName = message.systemName;
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                        object.displayNameLangJa = message.displayNameLangJa;
                    return object;
                };

                /**
                 * Converts this NameOption to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.NameOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NameOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NameOption;
            })();

            analysis.Datetime = (function() {

                /**
                 * Properties of a Datetime.
                 * @memberof mtechnavi.api.analysis
                 * @interface IDatetime
                 * @property {string|null} [timezoneName] Datetime timezoneName
                 * @property {Long|null} [timestamp] Datetime timestamp
                 * @property {Long|null} [accuracy] Datetime accuracy
                 */

                /**
                 * Constructs a new Datetime.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Datetime.
                 * @implements IDatetime
                 * @constructor
                 * @param {mtechnavi.api.analysis.IDatetime=} [properties] Properties to set
                 */
                function Datetime(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Datetime timezoneName.
                 * @member {string} timezoneName
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @instance
                 */
                Datetime.prototype.timezoneName = "";

                /**
                 * Datetime timestamp.
                 * @member {Long} timestamp
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @instance
                 */
                Datetime.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Datetime accuracy.
                 * @member {Long} accuracy
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @instance
                 */
                Datetime.prototype.accuracy = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Datetime message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Datetime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                        if (!$util.isString(message.timezoneName))
                            return "timezoneName: string expected";
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                            return "timestamp: integer|Long expected";
                    if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                        if (!$util.isInteger(message.accuracy) && !(message.accuracy && $util.isInteger(message.accuracy.low) && $util.isInteger(message.accuracy.high)))
                            return "accuracy: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Datetime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Datetime} Datetime
                 */
                Datetime.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Datetime)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Datetime();
                    if (object.timezoneName != null)
                        message.timezoneName = String(object.timezoneName);
                    if (object.timestamp != null)
                        if ($util.Long)
                            (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                        else if (typeof object.timestamp === "string")
                            message.timestamp = parseInt(object.timestamp, 10);
                        else if (typeof object.timestamp === "number")
                            message.timestamp = object.timestamp;
                        else if (typeof object.timestamp === "object")
                            message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                    if (object.accuracy != null)
                        if ($util.Long)
                            (message.accuracy = $util.Long.fromValue(object.accuracy)).unsigned = false;
                        else if (typeof object.accuracy === "string")
                            message.accuracy = parseInt(object.accuracy, 10);
                        else if (typeof object.accuracy === "number")
                            message.accuracy = object.accuracy;
                        else if (typeof object.accuracy === "object")
                            message.accuracy = new $util.LongBits(object.accuracy.low >>> 0, object.accuracy.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Datetime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @static
                 * @param {mtechnavi.api.analysis.Datetime} message Datetime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Datetime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.timezoneName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestamp = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.accuracy = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.accuracy = options.longs === String ? "0" : 0;
                    }
                    if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                        object.timezoneName = message.timezoneName;
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        if (typeof message.timestamp === "number")
                            object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                        else
                            object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                    if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                        if (typeof message.accuracy === "number")
                            object.accuracy = options.longs === String ? String(message.accuracy) : message.accuracy;
                        else
                            object.accuracy = options.longs === String ? $util.Long.prototype.toString.call(message.accuracy) : options.longs === Number ? new $util.LongBits(message.accuracy.low >>> 0, message.accuracy.high >>> 0).toNumber() : message.accuracy;
                    return object;
                };

                /**
                 * Converts this Datetime to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Datetime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Datetime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Datetime;
            })();

            analysis.ViewCompany = (function() {

                /**
                 * Properties of a ViewCompany.
                 * @memberof mtechnavi.api.analysis
                 * @interface IViewCompany
                 * @property {string|null} [companyId] ViewCompany companyId
                 * @property {mtechnavi.api.analysis.IProfile|null} [profile] ViewCompany profile
                 * @property {Array.<mtechnavi.api.analysis.IBranch>|null} [branchs] ViewCompany branchs
                 * @property {Array.<mtechnavi.api.analysis.IFacilities>|null} [facilities] ViewCompany facilities
                 * @property {Array.<mtechnavi.api.analysis.ICertificate>|null} [certificates] ViewCompany certificates
                 * @property {Array.<mtechnavi.api.analysis.IFinancial>|null} [financials] ViewCompany financials
                 * @property {Array.<mtechnavi.api.analysis.ISkill>|null} [skills] ViewCompany skills
                 * @property {Array.<mtechnavi.api.analysis.IStrength>|null} [strengths] ViewCompany strengths
                 */

                /**
                 * Constructs a new ViewCompany.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a ViewCompany.
                 * @implements IViewCompany
                 * @constructor
                 * @param {mtechnavi.api.analysis.IViewCompany=} [properties] Properties to set
                 */
                function ViewCompany(properties) {
                    this.branchs = [];
                    this.facilities = [];
                    this.certificates = [];
                    this.financials = [];
                    this.skills = [];
                    this.strengths = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ViewCompany companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.companyId = "";

                /**
                 * ViewCompany profile.
                 * @member {mtechnavi.api.analysis.IProfile|null|undefined} profile
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.profile = null;

                /**
                 * ViewCompany branchs.
                 * @member {Array.<mtechnavi.api.analysis.IBranch>} branchs
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.branchs = $util.emptyArray;

                /**
                 * ViewCompany facilities.
                 * @member {Array.<mtechnavi.api.analysis.IFacilities>} facilities
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.facilities = $util.emptyArray;

                /**
                 * ViewCompany certificates.
                 * @member {Array.<mtechnavi.api.analysis.ICertificate>} certificates
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.certificates = $util.emptyArray;

                /**
                 * ViewCompany financials.
                 * @member {Array.<mtechnavi.api.analysis.IFinancial>} financials
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.financials = $util.emptyArray;

                /**
                 * ViewCompany skills.
                 * @member {Array.<mtechnavi.api.analysis.ISkill>} skills
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.skills = $util.emptyArray;

                /**
                 * ViewCompany strengths.
                 * @member {Array.<mtechnavi.api.analysis.IStrength>} strengths
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 */
                ViewCompany.prototype.strengths = $util.emptyArray;

                /**
                 * Verifies a ViewCompany message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ViewCompany.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        let error = $root.mtechnavi.api.analysis.Profile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    if (message.branchs != null && message.hasOwnProperty("branchs")) {
                        if (!Array.isArray(message.branchs))
                            return "branchs: array expected";
                        for (let i = 0; i < message.branchs.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Branch.verify(message.branchs[i]);
                            if (error)
                                return "branchs." + error;
                        }
                    }
                    if (message.facilities != null && message.hasOwnProperty("facilities")) {
                        if (!Array.isArray(message.facilities))
                            return "facilities: array expected";
                        for (let i = 0; i < message.facilities.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Facilities.verify(message.facilities[i]);
                            if (error)
                                return "facilities." + error;
                        }
                    }
                    if (message.certificates != null && message.hasOwnProperty("certificates")) {
                        if (!Array.isArray(message.certificates))
                            return "certificates: array expected";
                        for (let i = 0; i < message.certificates.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Certificate.verify(message.certificates[i]);
                            if (error)
                                return "certificates." + error;
                        }
                    }
                    if (message.financials != null && message.hasOwnProperty("financials")) {
                        if (!Array.isArray(message.financials))
                            return "financials: array expected";
                        for (let i = 0; i < message.financials.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Financial.verify(message.financials[i]);
                            if (error)
                                return "financials." + error;
                        }
                    }
                    if (message.skills != null && message.hasOwnProperty("skills")) {
                        if (!Array.isArray(message.skills))
                            return "skills: array expected";
                        for (let i = 0; i < message.skills.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Skill.verify(message.skills[i]);
                            if (error)
                                return "skills." + error;
                        }
                    }
                    if (message.strengths != null && message.hasOwnProperty("strengths")) {
                        if (!Array.isArray(message.strengths))
                            return "strengths: array expected";
                        for (let i = 0; i < message.strengths.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.Strength.verify(message.strengths[i]);
                            if (error)
                                return "strengths." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ViewCompany message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.ViewCompany} ViewCompany
                 */
                ViewCompany.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.ViewCompany)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.ViewCompany();
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.profile: object expected");
                        message.profile = $root.mtechnavi.api.analysis.Profile.fromObject(object.profile);
                    }
                    if (object.branchs) {
                        if (!Array.isArray(object.branchs))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.branchs: array expected");
                        message.branchs = [];
                        for (let i = 0; i < object.branchs.length; ++i) {
                            if (typeof object.branchs[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.branchs: object expected");
                            message.branchs[i] = $root.mtechnavi.api.analysis.Branch.fromObject(object.branchs[i]);
                        }
                    }
                    if (object.facilities) {
                        if (!Array.isArray(object.facilities))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.facilities: array expected");
                        message.facilities = [];
                        for (let i = 0; i < object.facilities.length; ++i) {
                            if (typeof object.facilities[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.facilities: object expected");
                            message.facilities[i] = $root.mtechnavi.api.analysis.Facilities.fromObject(object.facilities[i]);
                        }
                    }
                    if (object.certificates) {
                        if (!Array.isArray(object.certificates))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.certificates: array expected");
                        message.certificates = [];
                        for (let i = 0; i < object.certificates.length; ++i) {
                            if (typeof object.certificates[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.certificates: object expected");
                            message.certificates[i] = $root.mtechnavi.api.analysis.Certificate.fromObject(object.certificates[i]);
                        }
                    }
                    if (object.financials) {
                        if (!Array.isArray(object.financials))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.financials: array expected");
                        message.financials = [];
                        for (let i = 0; i < object.financials.length; ++i) {
                            if (typeof object.financials[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.financials: object expected");
                            message.financials[i] = $root.mtechnavi.api.analysis.Financial.fromObject(object.financials[i]);
                        }
                    }
                    if (object.skills) {
                        if (!Array.isArray(object.skills))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.skills: array expected");
                        message.skills = [];
                        for (let i = 0; i < object.skills.length; ++i) {
                            if (typeof object.skills[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.skills: object expected");
                            message.skills[i] = $root.mtechnavi.api.analysis.Skill.fromObject(object.skills[i]);
                        }
                    }
                    if (object.strengths) {
                        if (!Array.isArray(object.strengths))
                            throw TypeError(".mtechnavi.api.analysis.ViewCompany.strengths: array expected");
                        message.strengths = [];
                        for (let i = 0; i < object.strengths.length; ++i) {
                            if (typeof object.strengths[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.ViewCompany.strengths: object expected");
                            message.strengths[i] = $root.mtechnavi.api.analysis.Strength.fromObject(object.strengths[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ViewCompany message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @static
                 * @param {mtechnavi.api.analysis.ViewCompany} message ViewCompany
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ViewCompany.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.branchs = [];
                        object.facilities = [];
                        object.certificates = [];
                        object.financials = [];
                        object.skills = [];
                        object.strengths = [];
                    }
                    if (options.defaults) {
                        object.companyId = "";
                        object.profile = null;
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.mtechnavi.api.analysis.Profile.toObject(message.profile, options);
                    if (message.branchs && message.branchs.length) {
                        object.branchs = [];
                        for (let j = 0; j < message.branchs.length; ++j)
                            object.branchs[j] = $root.mtechnavi.api.analysis.Branch.toObject(message.branchs[j], options);
                    }
                    if (message.facilities && message.facilities.length) {
                        object.facilities = [];
                        for (let j = 0; j < message.facilities.length; ++j)
                            object.facilities[j] = $root.mtechnavi.api.analysis.Facilities.toObject(message.facilities[j], options);
                    }
                    if (message.certificates && message.certificates.length) {
                        object.certificates = [];
                        for (let j = 0; j < message.certificates.length; ++j)
                            object.certificates[j] = $root.mtechnavi.api.analysis.Certificate.toObject(message.certificates[j], options);
                    }
                    if (message.financials && message.financials.length) {
                        object.financials = [];
                        for (let j = 0; j < message.financials.length; ++j)
                            object.financials[j] = $root.mtechnavi.api.analysis.Financial.toObject(message.financials[j], options);
                    }
                    if (message.skills && message.skills.length) {
                        object.skills = [];
                        for (let j = 0; j < message.skills.length; ++j)
                            object.skills[j] = $root.mtechnavi.api.analysis.Skill.toObject(message.skills[j], options);
                    }
                    if (message.strengths && message.strengths.length) {
                        object.strengths = [];
                        for (let j = 0; j < message.strengths.length; ++j)
                            object.strengths[j] = $root.mtechnavi.api.analysis.Strength.toObject(message.strengths[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ViewCompany to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.ViewCompany
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ViewCompany.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ViewCompany;
            })();

            analysis.Profile = (function() {

                /**
                 * Properties of a Profile.
                 * @memberof mtechnavi.api.analysis
                 * @interface IProfile
                 * @property {string|null} [displayNameLangJa] Profile displayNameLangJa
                 * @property {string|null} [displayNameLangEn] Profile displayNameLangEn
                 * @property {string|null} [displayNameLangJaKana] Profile displayNameLangJaKana
                 * @property {string|null} [websiteUrl] Profile websiteUrl
                 * @property {string|null} [mainBusiness] Profile mainBusiness
                 * @property {sharelib.IMonetaryAmount|null} [capitalStock] Profile capitalStock
                 * @property {mtechnavi.api.analysis.Profile.IEmployees|null} [employees] Profile employees
                 * @property {string|null} [mainShareholder] Profile mainShareholder
                 * @property {string|null} [mainBank] Profile mainBank
                 * @property {string|null} [mainSupplier] Profile mainSupplier
                 * @property {string|null} [mainCustomer] Profile mainCustomer
                 * @property {mtechnavi.api.company.ICompanyLegalProperties|null} [companyLegalProperties] Profile companyLegalProperties
                 * @property {mtechnavi.api.analysis.Profile.IRepresentative|null} [representative] Profile representative
                 * @property {string|null} [phoneNumber] Profile phoneNumber
                 * @property {mtechnavi.api.analysis.Profile.IAddress|null} [address] Profile address
                 */

                /**
                 * Constructs a new Profile.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Profile.
                 * @implements IProfile
                 * @constructor
                 * @param {mtechnavi.api.analysis.IProfile=} [properties] Properties to set
                 */
                function Profile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Profile displayNameLangJa.
                 * @member {string} displayNameLangJa
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.displayNameLangJa = "";

                /**
                 * Profile displayNameLangEn.
                 * @member {string} displayNameLangEn
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.displayNameLangEn = "";

                /**
                 * Profile displayNameLangJaKana.
                 * @member {string} displayNameLangJaKana
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.displayNameLangJaKana = "";

                /**
                 * Profile websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.websiteUrl = "";

                /**
                 * Profile mainBusiness.
                 * @member {string} mainBusiness
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.mainBusiness = "";

                /**
                 * Profile capitalStock.
                 * @member {sharelib.IMonetaryAmount|null|undefined} capitalStock
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.capitalStock = null;

                /**
                 * Profile employees.
                 * @member {mtechnavi.api.analysis.Profile.IEmployees|null|undefined} employees
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.employees = null;

                /**
                 * Profile mainShareholder.
                 * @member {string} mainShareholder
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.mainShareholder = "";

                /**
                 * Profile mainBank.
                 * @member {string} mainBank
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.mainBank = "";

                /**
                 * Profile mainSupplier.
                 * @member {string} mainSupplier
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.mainSupplier = "";

                /**
                 * Profile mainCustomer.
                 * @member {string} mainCustomer
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.mainCustomer = "";

                /**
                 * Profile companyLegalProperties.
                 * @member {mtechnavi.api.company.ICompanyLegalProperties|null|undefined} companyLegalProperties
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.companyLegalProperties = null;

                /**
                 * Profile representative.
                 * @member {mtechnavi.api.analysis.Profile.IRepresentative|null|undefined} representative
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.representative = null;

                /**
                 * Profile phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.phoneNumber = "";

                /**
                 * Profile address.
                 * @member {mtechnavi.api.analysis.Profile.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 */
                Profile.prototype.address = null;

                /**
                 * Verifies a Profile message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Profile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Profile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                        if (!$util.isString(message.displayNameLangJa))
                            return "displayNameLangJa: string expected";
                    if (message.displayNameLangEn != null && message.hasOwnProperty("displayNameLangEn"))
                        if (!$util.isString(message.displayNameLangEn))
                            return "displayNameLangEn: string expected";
                    if (message.displayNameLangJaKana != null && message.hasOwnProperty("displayNameLangJaKana"))
                        if (!$util.isString(message.displayNameLangJaKana))
                            return "displayNameLangJaKana: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        if (!$util.isString(message.mainBusiness))
                            return "mainBusiness: string expected";
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.capitalStock);
                        if (error)
                            return "capitalStock." + error;
                    }
                    if (message.employees != null && message.hasOwnProperty("employees")) {
                        let error = $root.mtechnavi.api.analysis.Profile.Employees.verify(message.employees);
                        if (error)
                            return "employees." + error;
                    }
                    if (message.mainShareholder != null && message.hasOwnProperty("mainShareholder"))
                        if (!$util.isString(message.mainShareholder))
                            return "mainShareholder: string expected";
                    if (message.mainBank != null && message.hasOwnProperty("mainBank"))
                        if (!$util.isString(message.mainBank))
                            return "mainBank: string expected";
                    if (message.mainSupplier != null && message.hasOwnProperty("mainSupplier"))
                        if (!$util.isString(message.mainSupplier))
                            return "mainSupplier: string expected";
                    if (message.mainCustomer != null && message.hasOwnProperty("mainCustomer"))
                        if (!$util.isString(message.mainCustomer))
                            return "mainCustomer: string expected";
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties")) {
                        let error = $root.mtechnavi.api.company.CompanyLegalProperties.verify(message.companyLegalProperties);
                        if (error)
                            return "companyLegalProperties." + error;
                    }
                    if (message.representative != null && message.hasOwnProperty("representative")) {
                        let error = $root.mtechnavi.api.analysis.Profile.Representative.verify(message.representative);
                        if (error)
                            return "representative." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.analysis.Profile.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Profile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Profile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Profile} Profile
                 */
                Profile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Profile)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Profile();
                    if (object.displayNameLangJa != null)
                        message.displayNameLangJa = String(object.displayNameLangJa);
                    if (object.displayNameLangEn != null)
                        message.displayNameLangEn = String(object.displayNameLangEn);
                    if (object.displayNameLangJaKana != null)
                        message.displayNameLangJaKana = String(object.displayNameLangJaKana);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.mainBusiness != null)
                        message.mainBusiness = String(object.mainBusiness);
                    if (object.capitalStock != null) {
                        if (typeof object.capitalStock !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Profile.capitalStock: object expected");
                        message.capitalStock = $root.sharelib.MonetaryAmount.fromObject(object.capitalStock);
                    }
                    if (object.employees != null) {
                        if (typeof object.employees !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Profile.employees: object expected");
                        message.employees = $root.mtechnavi.api.analysis.Profile.Employees.fromObject(object.employees);
                    }
                    if (object.mainShareholder != null)
                        message.mainShareholder = String(object.mainShareholder);
                    if (object.mainBank != null)
                        message.mainBank = String(object.mainBank);
                    if (object.mainSupplier != null)
                        message.mainSupplier = String(object.mainSupplier);
                    if (object.mainCustomer != null)
                        message.mainCustomer = String(object.mainCustomer);
                    if (object.companyLegalProperties != null) {
                        if (typeof object.companyLegalProperties !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Profile.companyLegalProperties: object expected");
                        message.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.fromObject(object.companyLegalProperties);
                    }
                    if (object.representative != null) {
                        if (typeof object.representative !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Profile.representative: object expected");
                        message.representative = $root.mtechnavi.api.analysis.Profile.Representative.fromObject(object.representative);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Profile.address: object expected");
                        message.address = $root.mtechnavi.api.analysis.Profile.Address.fromObject(object.address);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Profile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Profile
                 * @static
                 * @param {mtechnavi.api.analysis.Profile} message Profile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Profile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.displayNameLangJa = "";
                        object.displayNameLangEn = "";
                        object.displayNameLangJaKana = "";
                        object.websiteUrl = "";
                        object.mainBusiness = "";
                        object.capitalStock = null;
                        object.employees = null;
                        object.mainShareholder = "";
                        object.mainBank = "";
                        object.mainSupplier = "";
                        object.mainCustomer = "";
                        object.companyLegalProperties = null;
                        object.representative = null;
                        object.phoneNumber = "";
                        object.address = null;
                    }
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                        object.displayNameLangJa = message.displayNameLangJa;
                    if (message.displayNameLangEn != null && message.hasOwnProperty("displayNameLangEn"))
                        object.displayNameLangEn = message.displayNameLangEn;
                    if (message.displayNameLangJaKana != null && message.hasOwnProperty("displayNameLangJaKana"))
                        object.displayNameLangJaKana = message.displayNameLangJaKana;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        object.mainBusiness = message.mainBusiness;
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock"))
                        object.capitalStock = $root.sharelib.MonetaryAmount.toObject(message.capitalStock, options);
                    if (message.employees != null && message.hasOwnProperty("employees"))
                        object.employees = $root.mtechnavi.api.analysis.Profile.Employees.toObject(message.employees, options);
                    if (message.mainShareholder != null && message.hasOwnProperty("mainShareholder"))
                        object.mainShareholder = message.mainShareholder;
                    if (message.mainBank != null && message.hasOwnProperty("mainBank"))
                        object.mainBank = message.mainBank;
                    if (message.mainSupplier != null && message.hasOwnProperty("mainSupplier"))
                        object.mainSupplier = message.mainSupplier;
                    if (message.mainCustomer != null && message.hasOwnProperty("mainCustomer"))
                        object.mainCustomer = message.mainCustomer;
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties"))
                        object.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.toObject(message.companyLegalProperties, options);
                    if (message.representative != null && message.hasOwnProperty("representative"))
                        object.representative = $root.mtechnavi.api.analysis.Profile.Representative.toObject(message.representative, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.analysis.Profile.Address.toObject(message.address, options);
                    return object;
                };

                /**
                 * Converts this Profile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Profile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Profile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Profile.Employees = (function() {

                    /**
                     * Properties of an Employees.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @interface IEmployees
                     * @property {Long|null} [size] Employees size
                     * @property {sharelib.IAmount|null} [averageAge] Employees averageAge
                     */

                    /**
                     * Constructs a new Employees.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @classdesc Represents an Employees.
                     * @implements IEmployees
                     * @constructor
                     * @param {mtechnavi.api.analysis.Profile.IEmployees=} [properties] Properties to set
                     */
                    function Employees(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Employees size.
                     * @member {Long} size
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @instance
                     */
                    Employees.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Employees averageAge.
                     * @member {sharelib.IAmount|null|undefined} averageAge
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @instance
                     */
                    Employees.prototype.averageAge = null;

                    /**
                     * Verifies an Employees message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Employees.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                                return "size: integer|Long expected";
                        if (message.averageAge != null && message.hasOwnProperty("averageAge")) {
                            let error = $root.sharelib.Amount.verify(message.averageAge);
                            if (error)
                                return "averageAge." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an Employees message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.Profile.Employees} Employees
                     */
                    Employees.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.Profile.Employees)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.Profile.Employees();
                        if (object.size != null)
                            if ($util.Long)
                                (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                            else if (typeof object.size === "string")
                                message.size = parseInt(object.size, 10);
                            else if (typeof object.size === "number")
                                message.size = object.size;
                            else if (typeof object.size === "object")
                                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
                        if (object.averageAge != null) {
                            if (typeof object.averageAge !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Profile.Employees.averageAge: object expected");
                            message.averageAge = $root.sharelib.Amount.fromObject(object.averageAge);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Employees message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @static
                     * @param {mtechnavi.api.analysis.Profile.Employees} message Employees
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Employees.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.size = options.longs === String ? "0" : 0;
                            object.averageAge = null;
                        }
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (typeof message.size === "number")
                                object.size = options.longs === String ? String(message.size) : message.size;
                            else
                                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
                        if (message.averageAge != null && message.hasOwnProperty("averageAge"))
                            object.averageAge = $root.sharelib.Amount.toObject(message.averageAge, options);
                        return object;
                    };

                    /**
                     * Converts this Employees to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.Profile.Employees
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Employees.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Employees;
                })();

                Profile.Representative = (function() {

                    /**
                     * Properties of a Representative.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @interface IRepresentative
                     * @property {string|null} [displayNameLangJa] Representative displayNameLangJa
                     * @property {string|null} [displayNameLangJaKana] Representative displayNameLangJaKana
                     * @property {string|null} [position] Representative position
                     * @property {sharelib.IDate|null} [birthDate] Representative birthDate
                     */

                    /**
                     * Constructs a new Representative.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @classdesc Represents a Representative.
                     * @implements IRepresentative
                     * @constructor
                     * @param {mtechnavi.api.analysis.Profile.IRepresentative=} [properties] Properties to set
                     */
                    function Representative(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Representative displayNameLangJa.
                     * @member {string} displayNameLangJa
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.displayNameLangJa = "";

                    /**
                     * Representative displayNameLangJaKana.
                     * @member {string} displayNameLangJaKana
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.displayNameLangJaKana = "";

                    /**
                     * Representative position.
                     * @member {string} position
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.position = "";

                    /**
                     * Representative birthDate.
                     * @member {sharelib.IDate|null|undefined} birthDate
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.birthDate = null;

                    /**
                     * Verifies a Representative message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Representative.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                            if (!$util.isString(message.displayNameLangJa))
                                return "displayNameLangJa: string expected";
                        if (message.displayNameLangJaKana != null && message.hasOwnProperty("displayNameLangJaKana"))
                            if (!$util.isString(message.displayNameLangJaKana))
                                return "displayNameLangJaKana: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        if (message.birthDate != null && message.hasOwnProperty("birthDate")) {
                            let error = $root.sharelib.Date.verify(message.birthDate);
                            if (error)
                                return "birthDate." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Representative message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.Profile.Representative} Representative
                     */
                    Representative.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.Profile.Representative)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.Profile.Representative();
                        if (object.displayNameLangJa != null)
                            message.displayNameLangJa = String(object.displayNameLangJa);
                        if (object.displayNameLangJaKana != null)
                            message.displayNameLangJaKana = String(object.displayNameLangJaKana);
                        if (object.position != null)
                            message.position = String(object.position);
                        if (object.birthDate != null) {
                            if (typeof object.birthDate !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Profile.Representative.birthDate: object expected");
                            message.birthDate = $root.sharelib.Date.fromObject(object.birthDate);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Representative message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @static
                     * @param {mtechnavi.api.analysis.Profile.Representative} message Representative
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Representative.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.displayNameLangJa = "";
                            object.displayNameLangJaKana = "";
                            object.position = "";
                            object.birthDate = null;
                        }
                        if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                            object.displayNameLangJa = message.displayNameLangJa;
                        if (message.displayNameLangJaKana != null && message.hasOwnProperty("displayNameLangJaKana"))
                            object.displayNameLangJaKana = message.displayNameLangJaKana;
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        if (message.birthDate != null && message.hasOwnProperty("birthDate"))
                            object.birthDate = $root.sharelib.Date.toObject(message.birthDate, options);
                        return object;
                    };

                    /**
                     * Converts this Representative to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.Profile.Representative
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Representative.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Representative;
                })();

                Profile.Address = (function() {

                    /**
                     * Properties of an Address.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @interface IAddress
                     * @property {mtechnavi.api.analysis.INameOption|null} [region] Address region
                     * @property {string|null} [postalCode] Address postalCode
                     * @property {string|null} [addressLines1] Address addressLines1
                     */

                    /**
                     * Constructs a new Address.
                     * @memberof mtechnavi.api.analysis.Profile
                     * @classdesc Represents an Address.
                     * @implements IAddress
                     * @constructor
                     * @param {mtechnavi.api.analysis.Profile.IAddress=} [properties] Properties to set
                     */
                    function Address(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Address region.
                     * @member {mtechnavi.api.analysis.INameOption|null|undefined} region
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @instance
                     */
                    Address.prototype.region = null;

                    /**
                     * Address postalCode.
                     * @member {string} postalCode
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @instance
                     */
                    Address.prototype.postalCode = "";

                    /**
                     * Address addressLines1.
                     * @member {string} addressLines1
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @instance
                     */
                    Address.prototype.addressLines1 = "";

                    /**
                     * Verifies an Address message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Address.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.region != null && message.hasOwnProperty("region")) {
                            let error = $root.mtechnavi.api.analysis.NameOption.verify(message.region);
                            if (error)
                                return "region." + error;
                        }
                        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                            if (!$util.isString(message.postalCode))
                                return "postalCode: string expected";
                        if (message.addressLines1 != null && message.hasOwnProperty("addressLines1"))
                            if (!$util.isString(message.addressLines1))
                                return "addressLines1: string expected";
                        return null;
                    };

                    /**
                     * Creates an Address message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.Profile.Address} Address
                     */
                    Address.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.Profile.Address)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.Profile.Address();
                        if (object.region != null) {
                            if (typeof object.region !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Profile.Address.region: object expected");
                            message.region = $root.mtechnavi.api.analysis.NameOption.fromObject(object.region);
                        }
                        if (object.postalCode != null)
                            message.postalCode = String(object.postalCode);
                        if (object.addressLines1 != null)
                            message.addressLines1 = String(object.addressLines1);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Address message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @static
                     * @param {mtechnavi.api.analysis.Profile.Address} message Address
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Address.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.region = null;
                            object.postalCode = "";
                            object.addressLines1 = "";
                        }
                        if (message.region != null && message.hasOwnProperty("region"))
                            object.region = $root.mtechnavi.api.analysis.NameOption.toObject(message.region, options);
                        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                            object.postalCode = message.postalCode;
                        if (message.addressLines1 != null && message.hasOwnProperty("addressLines1"))
                            object.addressLines1 = message.addressLines1;
                        return object;
                    };

                    /**
                     * Converts this Address to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.Profile.Address
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Address.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Address;
                })();

                return Profile;
            })();

            analysis.Branch = (function() {

                /**
                 * Properties of a Branch.
                 * @memberof mtechnavi.api.analysis
                 * @interface IBranch
                 * @property {string|null} [recordId] Branch recordId
                 * @property {string|null} [companyId] Branch companyId
                 * @property {string|null} [displayName] Branch displayName
                 * @property {mtechnavi.api.analysis.Branch.IAddress|null} [address] Branch address
                 * @property {string|null} [overview] Branch overview
                 * @property {string|null} [phoneNumber] Branch phoneNumber
                 * @property {string|null} [faxNumber] Branch faxNumber
                 */

                /**
                 * Constructs a new Branch.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Branch.
                 * @implements IBranch
                 * @constructor
                 * @param {mtechnavi.api.analysis.IBranch=} [properties] Properties to set
                 */
                function Branch(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Branch recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.recordId = "";

                /**
                 * Branch companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.companyId = "";

                /**
                 * Branch displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.displayName = "";

                /**
                 * Branch address.
                 * @member {mtechnavi.api.analysis.Branch.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.address = null;

                /**
                 * Branch overview.
                 * @member {string} overview
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.overview = "";

                /**
                 * Branch phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.phoneNumber = "";

                /**
                 * Branch faxNumber.
                 * @member {string} faxNumber
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 */
                Branch.prototype.faxNumber = "";

                /**
                 * Verifies a Branch message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Branch
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Branch.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.analysis.Branch.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.overview != null && message.hasOwnProperty("overview"))
                        if (!$util.isString(message.overview))
                            return "overview: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        if (!$util.isString(message.faxNumber))
                            return "faxNumber: string expected";
                    return null;
                };

                /**
                 * Creates a Branch message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Branch
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Branch} Branch
                 */
                Branch.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Branch)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Branch();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Branch.address: object expected");
                        message.address = $root.mtechnavi.api.analysis.Branch.Address.fromObject(object.address);
                    }
                    if (object.overview != null)
                        message.overview = String(object.overview);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.faxNumber != null)
                        message.faxNumber = String(object.faxNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a Branch message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Branch
                 * @static
                 * @param {mtechnavi.api.analysis.Branch} message Branch
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Branch.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                        object.displayName = "";
                        object.address = null;
                        object.overview = "";
                        object.phoneNumber = "";
                        object.faxNumber = "";
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.analysis.Branch.Address.toObject(message.address, options);
                    if (message.overview != null && message.hasOwnProperty("overview"))
                        object.overview = message.overview;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = message.faxNumber;
                    return object;
                };

                /**
                 * Converts this Branch to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Branch
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Branch.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Branch.Address = (function() {

                    /**
                     * Properties of an Address.
                     * @memberof mtechnavi.api.analysis.Branch
                     * @interface IAddress
                     * @property {mtechnavi.api.analysis.INameOption|null} [country] Address country
                     * @property {mtechnavi.api.analysis.INameOption|null} [region] Address region
                     * @property {string|null} [postalCode] Address postalCode
                     * @property {string|null} [addressLines1] Address addressLines1
                     */

                    /**
                     * Constructs a new Address.
                     * @memberof mtechnavi.api.analysis.Branch
                     * @classdesc Represents an Address.
                     * @implements IAddress
                     * @constructor
                     * @param {mtechnavi.api.analysis.Branch.IAddress=} [properties] Properties to set
                     */
                    function Address(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Address country.
                     * @member {mtechnavi.api.analysis.INameOption|null|undefined} country
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @instance
                     */
                    Address.prototype.country = null;

                    /**
                     * Address region.
                     * @member {mtechnavi.api.analysis.INameOption|null|undefined} region
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @instance
                     */
                    Address.prototype.region = null;

                    /**
                     * Address postalCode.
                     * @member {string} postalCode
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @instance
                     */
                    Address.prototype.postalCode = "";

                    /**
                     * Address addressLines1.
                     * @member {string} addressLines1
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @instance
                     */
                    Address.prototype.addressLines1 = "";

                    /**
                     * Verifies an Address message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Address.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.country != null && message.hasOwnProperty("country")) {
                            let error = $root.mtechnavi.api.analysis.NameOption.verify(message.country);
                            if (error)
                                return "country." + error;
                        }
                        if (message.region != null && message.hasOwnProperty("region")) {
                            let error = $root.mtechnavi.api.analysis.NameOption.verify(message.region);
                            if (error)
                                return "region." + error;
                        }
                        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                            if (!$util.isString(message.postalCode))
                                return "postalCode: string expected";
                        if (message.addressLines1 != null && message.hasOwnProperty("addressLines1"))
                            if (!$util.isString(message.addressLines1))
                                return "addressLines1: string expected";
                        return null;
                    };

                    /**
                     * Creates an Address message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.Branch.Address} Address
                     */
                    Address.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.Branch.Address)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.Branch.Address();
                        if (object.country != null) {
                            if (typeof object.country !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Branch.Address.country: object expected");
                            message.country = $root.mtechnavi.api.analysis.NameOption.fromObject(object.country);
                        }
                        if (object.region != null) {
                            if (typeof object.region !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Branch.Address.region: object expected");
                            message.region = $root.mtechnavi.api.analysis.NameOption.fromObject(object.region);
                        }
                        if (object.postalCode != null)
                            message.postalCode = String(object.postalCode);
                        if (object.addressLines1 != null)
                            message.addressLines1 = String(object.addressLines1);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Address message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @static
                     * @param {mtechnavi.api.analysis.Branch.Address} message Address
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Address.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.country = null;
                            object.region = null;
                            object.postalCode = "";
                            object.addressLines1 = "";
                        }
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = $root.mtechnavi.api.analysis.NameOption.toObject(message.country, options);
                        if (message.region != null && message.hasOwnProperty("region"))
                            object.region = $root.mtechnavi.api.analysis.NameOption.toObject(message.region, options);
                        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                            object.postalCode = message.postalCode;
                        if (message.addressLines1 != null && message.hasOwnProperty("addressLines1"))
                            object.addressLines1 = message.addressLines1;
                        return object;
                    };

                    /**
                     * Converts this Address to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.Branch.Address
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Address.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Address;
                })();

                return Branch;
            })();

            analysis.Facilities = (function() {

                /**
                 * Properties of a Facilities.
                 * @memberof mtechnavi.api.analysis
                 * @interface IFacilities
                 * @property {string|null} [recordId] Facilities recordId
                 * @property {string|null} [companyId] Facilities companyId
                 * @property {string|null} [branchId] Facilities branchId
                 * @property {Array.<mtechnavi.api.analysis.INameOption>|null} [categorys] Facilities categorys
                 * @property {string|null} [manufacture] Facilities manufacture
                 * @property {string|null} [modelNumber] Facilities modelNumber
                 * @property {mtechnavi.api.analysis.INameOption|null} [accuracy] Facilities accuracy
                 * @property {Long|null} [assetsAmount] Facilities assetsAmount
                 * @property {Long|null} [processableTon] Facilities processableTon
                 * @property {Long|null} [sizeX] Facilities sizeX
                 * @property {Long|null} [sizeY] Facilities sizeY
                 * @property {Long|null} [sizeZ] Facilities sizeZ
                 * @property {Long|null} [sizeT] Facilities sizeT
                 * @property {Long|null} [diameter] Facilities diameter
                 */

                /**
                 * Constructs a new Facilities.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Facilities.
                 * @implements IFacilities
                 * @constructor
                 * @param {mtechnavi.api.analysis.IFacilities=} [properties] Properties to set
                 */
                function Facilities(properties) {
                    this.categorys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Facilities recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.recordId = "";

                /**
                 * Facilities companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.companyId = "";

                /**
                 * Facilities branchId.
                 * @member {string} branchId
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.branchId = "";

                /**
                 * Facilities categorys.
                 * @member {Array.<mtechnavi.api.analysis.INameOption>} categorys
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.categorys = $util.emptyArray;

                /**
                 * Facilities manufacture.
                 * @member {string} manufacture
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.manufacture = "";

                /**
                 * Facilities modelNumber.
                 * @member {string} modelNumber
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.modelNumber = "";

                /**
                 * Facilities accuracy.
                 * @member {mtechnavi.api.analysis.INameOption|null|undefined} accuracy
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.accuracy = null;

                /**
                 * Facilities assetsAmount.
                 * @member {Long} assetsAmount
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.assetsAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities processableTon.
                 * @member {Long} processableTon
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.processableTon = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities sizeX.
                 * @member {Long} sizeX
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.sizeX = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities sizeY.
                 * @member {Long} sizeY
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.sizeY = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities sizeZ.
                 * @member {Long} sizeZ
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.sizeZ = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities sizeT.
                 * @member {Long} sizeT
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.sizeT = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Facilities diameter.
                 * @member {Long} diameter
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 */
                Facilities.prototype.diameter = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Facilities message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Facilities.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.branchId != null && message.hasOwnProperty("branchId"))
                        if (!$util.isString(message.branchId))
                            return "branchId: string expected";
                    if (message.categorys != null && message.hasOwnProperty("categorys")) {
                        if (!Array.isArray(message.categorys))
                            return "categorys: array expected";
                        for (let i = 0; i < message.categorys.length; ++i) {
                            let error = $root.mtechnavi.api.analysis.NameOption.verify(message.categorys[i]);
                            if (error)
                                return "categorys." + error;
                        }
                    }
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        if (!$util.isString(message.manufacture))
                            return "manufacture: string expected";
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        if (!$util.isString(message.modelNumber))
                            return "modelNumber: string expected";
                    if (message.accuracy != null && message.hasOwnProperty("accuracy")) {
                        let error = $root.mtechnavi.api.analysis.NameOption.verify(message.accuracy);
                        if (error)
                            return "accuracy." + error;
                    }
                    if (message.assetsAmount != null && message.hasOwnProperty("assetsAmount"))
                        if (!$util.isInteger(message.assetsAmount) && !(message.assetsAmount && $util.isInteger(message.assetsAmount.low) && $util.isInteger(message.assetsAmount.high)))
                            return "assetsAmount: integer|Long expected";
                    if (message.processableTon != null && message.hasOwnProperty("processableTon"))
                        if (!$util.isInteger(message.processableTon) && !(message.processableTon && $util.isInteger(message.processableTon.low) && $util.isInteger(message.processableTon.high)))
                            return "processableTon: integer|Long expected";
                    if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                        if (!$util.isInteger(message.sizeX) && !(message.sizeX && $util.isInteger(message.sizeX.low) && $util.isInteger(message.sizeX.high)))
                            return "sizeX: integer|Long expected";
                    if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                        if (!$util.isInteger(message.sizeY) && !(message.sizeY && $util.isInteger(message.sizeY.low) && $util.isInteger(message.sizeY.high)))
                            return "sizeY: integer|Long expected";
                    if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                        if (!$util.isInteger(message.sizeZ) && !(message.sizeZ && $util.isInteger(message.sizeZ.low) && $util.isInteger(message.sizeZ.high)))
                            return "sizeZ: integer|Long expected";
                    if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                        if (!$util.isInteger(message.sizeT) && !(message.sizeT && $util.isInteger(message.sizeT.low) && $util.isInteger(message.sizeT.high)))
                            return "sizeT: integer|Long expected";
                    if (message.diameter != null && message.hasOwnProperty("diameter"))
                        if (!$util.isInteger(message.diameter) && !(message.diameter && $util.isInteger(message.diameter.low) && $util.isInteger(message.diameter.high)))
                            return "diameter: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Facilities message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Facilities} Facilities
                 */
                Facilities.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Facilities)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Facilities();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.branchId != null)
                        message.branchId = String(object.branchId);
                    if (object.categorys) {
                        if (!Array.isArray(object.categorys))
                            throw TypeError(".mtechnavi.api.analysis.Facilities.categorys: array expected");
                        message.categorys = [];
                        for (let i = 0; i < object.categorys.length; ++i) {
                            if (typeof object.categorys[i] !== "object")
                                throw TypeError(".mtechnavi.api.analysis.Facilities.categorys: object expected");
                            message.categorys[i] = $root.mtechnavi.api.analysis.NameOption.fromObject(object.categorys[i]);
                        }
                    }
                    if (object.manufacture != null)
                        message.manufacture = String(object.manufacture);
                    if (object.modelNumber != null)
                        message.modelNumber = String(object.modelNumber);
                    if (object.accuracy != null) {
                        if (typeof object.accuracy !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Facilities.accuracy: object expected");
                        message.accuracy = $root.mtechnavi.api.analysis.NameOption.fromObject(object.accuracy);
                    }
                    if (object.assetsAmount != null)
                        if ($util.Long)
                            (message.assetsAmount = $util.Long.fromValue(object.assetsAmount)).unsigned = false;
                        else if (typeof object.assetsAmount === "string")
                            message.assetsAmount = parseInt(object.assetsAmount, 10);
                        else if (typeof object.assetsAmount === "number")
                            message.assetsAmount = object.assetsAmount;
                        else if (typeof object.assetsAmount === "object")
                            message.assetsAmount = new $util.LongBits(object.assetsAmount.low >>> 0, object.assetsAmount.high >>> 0).toNumber();
                    if (object.processableTon != null)
                        if ($util.Long)
                            (message.processableTon = $util.Long.fromValue(object.processableTon)).unsigned = false;
                        else if (typeof object.processableTon === "string")
                            message.processableTon = parseInt(object.processableTon, 10);
                        else if (typeof object.processableTon === "number")
                            message.processableTon = object.processableTon;
                        else if (typeof object.processableTon === "object")
                            message.processableTon = new $util.LongBits(object.processableTon.low >>> 0, object.processableTon.high >>> 0).toNumber();
                    if (object.sizeX != null)
                        if ($util.Long)
                            (message.sizeX = $util.Long.fromValue(object.sizeX)).unsigned = false;
                        else if (typeof object.sizeX === "string")
                            message.sizeX = parseInt(object.sizeX, 10);
                        else if (typeof object.sizeX === "number")
                            message.sizeX = object.sizeX;
                        else if (typeof object.sizeX === "object")
                            message.sizeX = new $util.LongBits(object.sizeX.low >>> 0, object.sizeX.high >>> 0).toNumber();
                    if (object.sizeY != null)
                        if ($util.Long)
                            (message.sizeY = $util.Long.fromValue(object.sizeY)).unsigned = false;
                        else if (typeof object.sizeY === "string")
                            message.sizeY = parseInt(object.sizeY, 10);
                        else if (typeof object.sizeY === "number")
                            message.sizeY = object.sizeY;
                        else if (typeof object.sizeY === "object")
                            message.sizeY = new $util.LongBits(object.sizeY.low >>> 0, object.sizeY.high >>> 0).toNumber();
                    if (object.sizeZ != null)
                        if ($util.Long)
                            (message.sizeZ = $util.Long.fromValue(object.sizeZ)).unsigned = false;
                        else if (typeof object.sizeZ === "string")
                            message.sizeZ = parseInt(object.sizeZ, 10);
                        else if (typeof object.sizeZ === "number")
                            message.sizeZ = object.sizeZ;
                        else if (typeof object.sizeZ === "object")
                            message.sizeZ = new $util.LongBits(object.sizeZ.low >>> 0, object.sizeZ.high >>> 0).toNumber();
                    if (object.sizeT != null)
                        if ($util.Long)
                            (message.sizeT = $util.Long.fromValue(object.sizeT)).unsigned = false;
                        else if (typeof object.sizeT === "string")
                            message.sizeT = parseInt(object.sizeT, 10);
                        else if (typeof object.sizeT === "number")
                            message.sizeT = object.sizeT;
                        else if (typeof object.sizeT === "object")
                            message.sizeT = new $util.LongBits(object.sizeT.low >>> 0, object.sizeT.high >>> 0).toNumber();
                    if (object.diameter != null)
                        if ($util.Long)
                            (message.diameter = $util.Long.fromValue(object.diameter)).unsigned = false;
                        else if (typeof object.diameter === "string")
                            message.diameter = parseInt(object.diameter, 10);
                        else if (typeof object.diameter === "number")
                            message.diameter = object.diameter;
                        else if (typeof object.diameter === "object")
                            message.diameter = new $util.LongBits(object.diameter.low >>> 0, object.diameter.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Facilities message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @static
                 * @param {mtechnavi.api.analysis.Facilities} message Facilities
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Facilities.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.categorys = [];
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                        object.branchId = "";
                        object.manufacture = "";
                        object.modelNumber = "";
                        object.accuracy = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.assetsAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.assetsAmount = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.processableTon = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processableTon = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeX = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeX = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeY = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeY = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeZ = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeZ = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeT = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeT = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.diameter = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.diameter = options.longs === String ? "0" : 0;
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.branchId != null && message.hasOwnProperty("branchId"))
                        object.branchId = message.branchId;
                    if (message.categorys && message.categorys.length) {
                        object.categorys = [];
                        for (let j = 0; j < message.categorys.length; ++j)
                            object.categorys[j] = $root.mtechnavi.api.analysis.NameOption.toObject(message.categorys[j], options);
                    }
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        object.manufacture = message.manufacture;
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        object.modelNumber = message.modelNumber;
                    if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                        object.accuracy = $root.mtechnavi.api.analysis.NameOption.toObject(message.accuracy, options);
                    if (message.assetsAmount != null && message.hasOwnProperty("assetsAmount"))
                        if (typeof message.assetsAmount === "number")
                            object.assetsAmount = options.longs === String ? String(message.assetsAmount) : message.assetsAmount;
                        else
                            object.assetsAmount = options.longs === String ? $util.Long.prototype.toString.call(message.assetsAmount) : options.longs === Number ? new $util.LongBits(message.assetsAmount.low >>> 0, message.assetsAmount.high >>> 0).toNumber() : message.assetsAmount;
                    if (message.processableTon != null && message.hasOwnProperty("processableTon"))
                        if (typeof message.processableTon === "number")
                            object.processableTon = options.longs === String ? String(message.processableTon) : message.processableTon;
                        else
                            object.processableTon = options.longs === String ? $util.Long.prototype.toString.call(message.processableTon) : options.longs === Number ? new $util.LongBits(message.processableTon.low >>> 0, message.processableTon.high >>> 0).toNumber() : message.processableTon;
                    if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                        if (typeof message.sizeX === "number")
                            object.sizeX = options.longs === String ? String(message.sizeX) : message.sizeX;
                        else
                            object.sizeX = options.longs === String ? $util.Long.prototype.toString.call(message.sizeX) : options.longs === Number ? new $util.LongBits(message.sizeX.low >>> 0, message.sizeX.high >>> 0).toNumber() : message.sizeX;
                    if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                        if (typeof message.sizeY === "number")
                            object.sizeY = options.longs === String ? String(message.sizeY) : message.sizeY;
                        else
                            object.sizeY = options.longs === String ? $util.Long.prototype.toString.call(message.sizeY) : options.longs === Number ? new $util.LongBits(message.sizeY.low >>> 0, message.sizeY.high >>> 0).toNumber() : message.sizeY;
                    if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                        if (typeof message.sizeZ === "number")
                            object.sizeZ = options.longs === String ? String(message.sizeZ) : message.sizeZ;
                        else
                            object.sizeZ = options.longs === String ? $util.Long.prototype.toString.call(message.sizeZ) : options.longs === Number ? new $util.LongBits(message.sizeZ.low >>> 0, message.sizeZ.high >>> 0).toNumber() : message.sizeZ;
                    if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                        if (typeof message.sizeT === "number")
                            object.sizeT = options.longs === String ? String(message.sizeT) : message.sizeT;
                        else
                            object.sizeT = options.longs === String ? $util.Long.prototype.toString.call(message.sizeT) : options.longs === Number ? new $util.LongBits(message.sizeT.low >>> 0, message.sizeT.high >>> 0).toNumber() : message.sizeT;
                    if (message.diameter != null && message.hasOwnProperty("diameter"))
                        if (typeof message.diameter === "number")
                            object.diameter = options.longs === String ? String(message.diameter) : message.diameter;
                        else
                            object.diameter = options.longs === String ? $util.Long.prototype.toString.call(message.diameter) : options.longs === Number ? new $util.LongBits(message.diameter.low >>> 0, message.diameter.high >>> 0).toNumber() : message.diameter;
                    return object;
                };

                /**
                 * Converts this Facilities to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Facilities
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Facilities.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Facilities;
            })();

            analysis.Certificate = (function() {

                /**
                 * Properties of a Certificate.
                 * @memberof mtechnavi.api.analysis
                 * @interface ICertificate
                 * @property {string|null} [recordId] Certificate recordId
                 * @property {string|null} [companyId] Certificate companyId
                 * @property {mtechnavi.api.analysis.INameOption|null} [certificate] Certificate certificate
                 * @property {string|null} [displayName] Certificate displayName
                 * @property {string|null} [certificateNumber] Certificate certificateNumber
                 * @property {boolean|null} [obtained] Certificate obtained
                 * @property {boolean|null} [obtainPlan] Certificate obtainPlan
                 * @property {mtechnavi.api.analysis.IDatetime|null} [obtainedDt] Certificate obtainedDt
                 * @property {mtechnavi.api.analysis.IDatetime|null} [obtainPlanDt] Certificate obtainPlanDt
                 * @property {mtechnavi.api.analysis.IDatetime|null} [expirationDt] Certificate expirationDt
                 * @property {string|null} [sharedBy] Certificate sharedBy
                 */

                /**
                 * Constructs a new Certificate.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Certificate.
                 * @implements ICertificate
                 * @constructor
                 * @param {mtechnavi.api.analysis.ICertificate=} [properties] Properties to set
                 */
                function Certificate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Certificate recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.recordId = "";

                /**
                 * Certificate companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.companyId = "";

                /**
                 * Certificate certificate.
                 * @member {mtechnavi.api.analysis.INameOption|null|undefined} certificate
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.certificate = null;

                /**
                 * Certificate displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.displayName = "";

                /**
                 * Certificate certificateNumber.
                 * @member {string} certificateNumber
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.certificateNumber = "";

                /**
                 * Certificate obtained.
                 * @member {boolean} obtained
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.obtained = false;

                /**
                 * Certificate obtainPlan.
                 * @member {boolean} obtainPlan
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.obtainPlan = false;

                /**
                 * Certificate obtainedDt.
                 * @member {mtechnavi.api.analysis.IDatetime|null|undefined} obtainedDt
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.obtainedDt = null;

                /**
                 * Certificate obtainPlanDt.
                 * @member {mtechnavi.api.analysis.IDatetime|null|undefined} obtainPlanDt
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.obtainPlanDt = null;

                /**
                 * Certificate expirationDt.
                 * @member {mtechnavi.api.analysis.IDatetime|null|undefined} expirationDt
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.expirationDt = null;

                /**
                 * Certificate sharedBy.
                 * @member {string} sharedBy
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 */
                Certificate.prototype.sharedBy = "";

                /**
                 * Verifies a Certificate message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Certificate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.certificate != null && message.hasOwnProperty("certificate")) {
                        let error = $root.mtechnavi.api.analysis.NameOption.verify(message.certificate);
                        if (error)
                            return "certificate." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber"))
                        if (!$util.isString(message.certificateNumber))
                            return "certificateNumber: string expected";
                    if (message.obtained != null && message.hasOwnProperty("obtained"))
                        if (typeof message.obtained !== "boolean")
                            return "obtained: boolean expected";
                    if (message.obtainPlan != null && message.hasOwnProperty("obtainPlan"))
                        if (typeof message.obtainPlan !== "boolean")
                            return "obtainPlan: boolean expected";
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt")) {
                        let error = $root.mtechnavi.api.analysis.Datetime.verify(message.obtainedDt);
                        if (error)
                            return "obtainedDt." + error;
                    }
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt")) {
                        let error = $root.mtechnavi.api.analysis.Datetime.verify(message.obtainPlanDt);
                        if (error)
                            return "obtainPlanDt." + error;
                    }
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt")) {
                        let error = $root.mtechnavi.api.analysis.Datetime.verify(message.expirationDt);
                        if (error)
                            return "expirationDt." + error;
                    }
                    if (message.sharedBy != null && message.hasOwnProperty("sharedBy"))
                        if (!$util.isString(message.sharedBy))
                            return "sharedBy: string expected";
                    return null;
                };

                /**
                 * Creates a Certificate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Certificate} Certificate
                 */
                Certificate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Certificate)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Certificate();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.certificate != null) {
                        if (typeof object.certificate !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Certificate.certificate: object expected");
                        message.certificate = $root.mtechnavi.api.analysis.NameOption.fromObject(object.certificate);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.certificateNumber != null)
                        message.certificateNumber = String(object.certificateNumber);
                    if (object.obtained != null)
                        message.obtained = Boolean(object.obtained);
                    if (object.obtainPlan != null)
                        message.obtainPlan = Boolean(object.obtainPlan);
                    if (object.obtainedDt != null) {
                        if (typeof object.obtainedDt !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Certificate.obtainedDt: object expected");
                        message.obtainedDt = $root.mtechnavi.api.analysis.Datetime.fromObject(object.obtainedDt);
                    }
                    if (object.obtainPlanDt != null) {
                        if (typeof object.obtainPlanDt !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Certificate.obtainPlanDt: object expected");
                        message.obtainPlanDt = $root.mtechnavi.api.analysis.Datetime.fromObject(object.obtainPlanDt);
                    }
                    if (object.expirationDt != null) {
                        if (typeof object.expirationDt !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Certificate.expirationDt: object expected");
                        message.expirationDt = $root.mtechnavi.api.analysis.Datetime.fromObject(object.expirationDt);
                    }
                    if (object.sharedBy != null)
                        message.sharedBy = String(object.sharedBy);
                    return message;
                };

                /**
                 * Creates a plain object from a Certificate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @static
                 * @param {mtechnavi.api.analysis.Certificate} message Certificate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Certificate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                        object.certificate = null;
                        object.displayName = "";
                        object.certificateNumber = "";
                        object.obtained = false;
                        object.obtainPlan = false;
                        object.obtainedDt = null;
                        object.obtainPlanDt = null;
                        object.expirationDt = null;
                        object.sharedBy = "";
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.certificate != null && message.hasOwnProperty("certificate"))
                        object.certificate = $root.mtechnavi.api.analysis.NameOption.toObject(message.certificate, options);
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber"))
                        object.certificateNumber = message.certificateNumber;
                    if (message.obtained != null && message.hasOwnProperty("obtained"))
                        object.obtained = message.obtained;
                    if (message.obtainPlan != null && message.hasOwnProperty("obtainPlan"))
                        object.obtainPlan = message.obtainPlan;
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt"))
                        object.obtainedDt = $root.mtechnavi.api.analysis.Datetime.toObject(message.obtainedDt, options);
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt"))
                        object.obtainPlanDt = $root.mtechnavi.api.analysis.Datetime.toObject(message.obtainPlanDt, options);
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt"))
                        object.expirationDt = $root.mtechnavi.api.analysis.Datetime.toObject(message.expirationDt, options);
                    if (message.sharedBy != null && message.hasOwnProperty("sharedBy"))
                        object.sharedBy = message.sharedBy;
                    return object;
                };

                /**
                 * Converts this Certificate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Certificate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Certificate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Certificate;
            })();

            analysis.Financial = (function() {

                /**
                 * Properties of a Financial.
                 * @memberof mtechnavi.api.analysis
                 * @interface IFinancial
                 * @property {string|null} [recordId] Financial recordId
                 * @property {string|null} [companyId] Financial companyId
                 * @property {sharelib.IMonetaryAmount|null} [netSales] Financial netSales
                 * @property {sharelib.IMonetaryAmount|null} [operatingProfit] Financial operatingProfit
                 * @property {sharelib.IMonetaryAmount|null} [ordinaryIncome] Financial ordinaryIncome
                 * @property {mtechnavi.api.analysis.IDatetime|null} [periodStartDt] Financial periodStartDt
                 * @property {mtechnavi.api.analysis.IDatetime|null} [periodEndDt] Financial periodEndDt
                 */

                /**
                 * Constructs a new Financial.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Financial.
                 * @implements IFinancial
                 * @constructor
                 * @param {mtechnavi.api.analysis.IFinancial=} [properties] Properties to set
                 */
                function Financial(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Financial recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.recordId = "";

                /**
                 * Financial companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.companyId = "";

                /**
                 * Financial netSales.
                 * @member {sharelib.IMonetaryAmount|null|undefined} netSales
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.netSales = null;

                /**
                 * Financial operatingProfit.
                 * @member {sharelib.IMonetaryAmount|null|undefined} operatingProfit
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.operatingProfit = null;

                /**
                 * Financial ordinaryIncome.
                 * @member {sharelib.IMonetaryAmount|null|undefined} ordinaryIncome
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.ordinaryIncome = null;

                /**
                 * Financial periodStartDt.
                 * @member {mtechnavi.api.analysis.IDatetime|null|undefined} periodStartDt
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.periodStartDt = null;

                /**
                 * Financial periodEndDt.
                 * @member {mtechnavi.api.analysis.IDatetime|null|undefined} periodEndDt
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 */
                Financial.prototype.periodEndDt = null;

                /**
                 * Verifies a Financial message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Financial
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Financial.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.netSales != null && message.hasOwnProperty("netSales")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.netSales);
                        if (error)
                            return "netSales." + error;
                    }
                    if (message.operatingProfit != null && message.hasOwnProperty("operatingProfit")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.operatingProfit);
                        if (error)
                            return "operatingProfit." + error;
                    }
                    if (message.ordinaryIncome != null && message.hasOwnProperty("ordinaryIncome")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.ordinaryIncome);
                        if (error)
                            return "ordinaryIncome." + error;
                    }
                    if (message.periodStartDt != null && message.hasOwnProperty("periodStartDt")) {
                        let error = $root.mtechnavi.api.analysis.Datetime.verify(message.periodStartDt);
                        if (error)
                            return "periodStartDt." + error;
                    }
                    if (message.periodEndDt != null && message.hasOwnProperty("periodEndDt")) {
                        let error = $root.mtechnavi.api.analysis.Datetime.verify(message.periodEndDt);
                        if (error)
                            return "periodEndDt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Financial message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Financial
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Financial} Financial
                 */
                Financial.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Financial)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Financial();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.netSales != null) {
                        if (typeof object.netSales !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Financial.netSales: object expected");
                        message.netSales = $root.sharelib.MonetaryAmount.fromObject(object.netSales);
                    }
                    if (object.operatingProfit != null) {
                        if (typeof object.operatingProfit !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Financial.operatingProfit: object expected");
                        message.operatingProfit = $root.sharelib.MonetaryAmount.fromObject(object.operatingProfit);
                    }
                    if (object.ordinaryIncome != null) {
                        if (typeof object.ordinaryIncome !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Financial.ordinaryIncome: object expected");
                        message.ordinaryIncome = $root.sharelib.MonetaryAmount.fromObject(object.ordinaryIncome);
                    }
                    if (object.periodStartDt != null) {
                        if (typeof object.periodStartDt !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Financial.periodStartDt: object expected");
                        message.periodStartDt = $root.mtechnavi.api.analysis.Datetime.fromObject(object.periodStartDt);
                    }
                    if (object.periodEndDt != null) {
                        if (typeof object.periodEndDt !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Financial.periodEndDt: object expected");
                        message.periodEndDt = $root.mtechnavi.api.analysis.Datetime.fromObject(object.periodEndDt);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Financial message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Financial
                 * @static
                 * @param {mtechnavi.api.analysis.Financial} message Financial
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Financial.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                        object.netSales = null;
                        object.operatingProfit = null;
                        object.ordinaryIncome = null;
                        object.periodStartDt = null;
                        object.periodEndDt = null;
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.netSales != null && message.hasOwnProperty("netSales"))
                        object.netSales = $root.sharelib.MonetaryAmount.toObject(message.netSales, options);
                    if (message.operatingProfit != null && message.hasOwnProperty("operatingProfit"))
                        object.operatingProfit = $root.sharelib.MonetaryAmount.toObject(message.operatingProfit, options);
                    if (message.ordinaryIncome != null && message.hasOwnProperty("ordinaryIncome"))
                        object.ordinaryIncome = $root.sharelib.MonetaryAmount.toObject(message.ordinaryIncome, options);
                    if (message.periodStartDt != null && message.hasOwnProperty("periodStartDt"))
                        object.periodStartDt = $root.mtechnavi.api.analysis.Datetime.toObject(message.periodStartDt, options);
                    if (message.periodEndDt != null && message.hasOwnProperty("periodEndDt"))
                        object.periodEndDt = $root.mtechnavi.api.analysis.Datetime.toObject(message.periodEndDt, options);
                    return object;
                };

                /**
                 * Converts this Financial to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Financial
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Financial.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Financial;
            })();

            analysis.Skill = (function() {

                /**
                 * Properties of a Skill.
                 * @memberof mtechnavi.api.analysis
                 * @interface ISkill
                 * @property {string|null} [recordId] Skill recordId
                 * @property {string|null} [companyId] Skill companyId
                 * @property {Array.<string>|null} [skillItemIds] Skill skillItemIds
                 */

                /**
                 * Constructs a new Skill.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Skill.
                 * @implements ISkill
                 * @constructor
                 * @param {mtechnavi.api.analysis.ISkill=} [properties] Properties to set
                 */
                function Skill(properties) {
                    this.skillItemIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Skill recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Skill
                 * @instance
                 */
                Skill.prototype.recordId = "";

                /**
                 * Skill companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Skill
                 * @instance
                 */
                Skill.prototype.companyId = "";

                /**
                 * Skill skillItemIds.
                 * @member {Array.<string>} skillItemIds
                 * @memberof mtechnavi.api.analysis.Skill
                 * @instance
                 */
                Skill.prototype.skillItemIds = $util.emptyArray;

                /**
                 * Verifies a Skill message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Skill
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Skill.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.skillItemIds != null && message.hasOwnProperty("skillItemIds")) {
                        if (!Array.isArray(message.skillItemIds))
                            return "skillItemIds: array expected";
                        for (let i = 0; i < message.skillItemIds.length; ++i)
                            if (!$util.isString(message.skillItemIds[i]))
                                return "skillItemIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Skill message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Skill
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Skill} Skill
                 */
                Skill.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Skill)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Skill();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.skillItemIds) {
                        if (!Array.isArray(object.skillItemIds))
                            throw TypeError(".mtechnavi.api.analysis.Skill.skillItemIds: array expected");
                        message.skillItemIds = [];
                        for (let i = 0; i < object.skillItemIds.length; ++i)
                            message.skillItemIds[i] = String(object.skillItemIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Skill message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Skill
                 * @static
                 * @param {mtechnavi.api.analysis.Skill} message Skill
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Skill.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.skillItemIds = [];
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.skillItemIds && message.skillItemIds.length) {
                        object.skillItemIds = [];
                        for (let j = 0; j < message.skillItemIds.length; ++j)
                            object.skillItemIds[j] = message.skillItemIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this Skill to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Skill
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Skill.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Skill;
            })();

            analysis.Strength = (function() {

                /**
                 * Properties of a Strength.
                 * @memberof mtechnavi.api.analysis
                 * @interface IStrength
                 * @property {string|null} [recordId] Strength recordId
                 * @property {string|null} [companyId] Strength companyId
                 * @property {mtechnavi.api.analysis.INameOption|null} [category] Strength category
                 * @property {string|null} [content] Strength content
                 * @property {mtechnavi.api.analysis.Strength.ILinkProperties|null} [linkProperties] Strength linkProperties
                 */

                /**
                 * Constructs a new Strength.
                 * @memberof mtechnavi.api.analysis
                 * @classdesc Represents a Strength.
                 * @implements IStrength
                 * @constructor
                 * @param {mtechnavi.api.analysis.IStrength=} [properties] Properties to set
                 */
                function Strength(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Strength recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 */
                Strength.prototype.recordId = "";

                /**
                 * Strength companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 */
                Strength.prototype.companyId = "";

                /**
                 * Strength category.
                 * @member {mtechnavi.api.analysis.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 */
                Strength.prototype.category = null;

                /**
                 * Strength content.
                 * @member {string} content
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 */
                Strength.prototype.content = "";

                /**
                 * Strength linkProperties.
                 * @member {mtechnavi.api.analysis.Strength.ILinkProperties|null|undefined} linkProperties
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 */
                Strength.prototype.linkProperties = null;

                /**
                 * Verifies a Strength message.
                 * @function verify
                 * @memberof mtechnavi.api.analysis.Strength
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Strength.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.mtechnavi.api.analysis.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.linkProperties != null && message.hasOwnProperty("linkProperties")) {
                        let error = $root.mtechnavi.api.analysis.Strength.LinkProperties.verify(message.linkProperties);
                        if (error)
                            return "linkProperties." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Strength message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.analysis.Strength
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.analysis.Strength} Strength
                 */
                Strength.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.analysis.Strength)
                        return object;
                    let message = new $root.mtechnavi.api.analysis.Strength();
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Strength.category: object expected");
                        message.category = $root.mtechnavi.api.analysis.NameOption.fromObject(object.category);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.linkProperties != null) {
                        if (typeof object.linkProperties !== "object")
                            throw TypeError(".mtechnavi.api.analysis.Strength.linkProperties: object expected");
                        message.linkProperties = $root.mtechnavi.api.analysis.Strength.LinkProperties.fromObject(object.linkProperties);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Strength message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.analysis.Strength
                 * @static
                 * @param {mtechnavi.api.analysis.Strength} message Strength
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Strength.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.recordId = "";
                        object.companyId = "";
                        object.category = null;
                        object.content = "";
                        object.linkProperties = null;
                    }
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.mtechnavi.api.analysis.NameOption.toObject(message.category, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.linkProperties != null && message.hasOwnProperty("linkProperties"))
                        object.linkProperties = $root.mtechnavi.api.analysis.Strength.LinkProperties.toObject(message.linkProperties, options);
                    return object;
                };

                /**
                 * Converts this Strength to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.analysis.Strength
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Strength.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Strength.LinkProperties = (function() {

                    /**
                     * Properties of a LinkProperties.
                     * @memberof mtechnavi.api.analysis.Strength
                     * @interface ILinkProperties
                     * @property {string|null} [branchId] LinkProperties branchId
                     * @property {string|null} [facilitiesId] LinkProperties facilitiesId
                     */

                    /**
                     * Constructs a new LinkProperties.
                     * @memberof mtechnavi.api.analysis.Strength
                     * @classdesc Represents a LinkProperties.
                     * @implements ILinkProperties
                     * @constructor
                     * @param {mtechnavi.api.analysis.Strength.ILinkProperties=} [properties] Properties to set
                     */
                    function LinkProperties(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LinkProperties branchId.
                     * @member {string} branchId
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @instance
                     */
                    LinkProperties.prototype.branchId = "";

                    /**
                     * LinkProperties facilitiesId.
                     * @member {string} facilitiesId
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @instance
                     */
                    LinkProperties.prototype.facilitiesId = "";

                    /**
                     * Verifies a LinkProperties message.
                     * @function verify
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LinkProperties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.branchId != null && message.hasOwnProperty("branchId"))
                            if (!$util.isString(message.branchId))
                                return "branchId: string expected";
                        if (message.facilitiesId != null && message.hasOwnProperty("facilitiesId"))
                            if (!$util.isString(message.facilitiesId))
                                return "facilitiesId: string expected";
                        return null;
                    };

                    /**
                     * Creates a LinkProperties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.analysis.Strength.LinkProperties} LinkProperties
                     */
                    LinkProperties.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.analysis.Strength.LinkProperties)
                            return object;
                        let message = new $root.mtechnavi.api.analysis.Strength.LinkProperties();
                        if (object.branchId != null)
                            message.branchId = String(object.branchId);
                        if (object.facilitiesId != null)
                            message.facilitiesId = String(object.facilitiesId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LinkProperties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @static
                     * @param {mtechnavi.api.analysis.Strength.LinkProperties} message LinkProperties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LinkProperties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.branchId = "";
                            object.facilitiesId = "";
                        }
                        if (message.branchId != null && message.hasOwnProperty("branchId"))
                            object.branchId = message.branchId;
                        if (message.facilitiesId != null && message.hasOwnProperty("facilitiesId"))
                            object.facilitiesId = message.facilitiesId;
                        return object;
                    };

                    /**
                     * Converts this LinkProperties to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.analysis.Strength.LinkProperties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LinkProperties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LinkProperties;
                })();

                return Strength;
            })();

            return analysis;
        })();

        api.assetinventory = (function() {

            /**
             * Namespace assetinventory.
             * @memberof mtechnavi.api
             * @namespace
             */
            const assetinventory = {};

            assetinventory.AssetInventory = (function() {

                /**
                 * Constructs a new AssetInventory service.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents an AssetInventory
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AssetInventory(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AssetInventory.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AssetInventory;

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#generateWritableSignedUrl}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef GenerateWritableSignedUrlCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.SignedUrl} [response] SignedUrl
                 */

                /**
                 * Calls GenerateWritableSignedUrl.
                 * @function generateWritableSignedUrl
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGenerateWritableSignedUrlRequest} request GenerateWritableSignedUrlRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.GenerateWritableSignedUrlCallback} callback Node-style callback called with the error, if any, and SignedUrl
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.generateWritableSignedUrl = function generateWritableSignedUrl(request, callback) {
                    return this.rpcCall(generateWritableSignedUrl, $root.mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest, $root.mtechnavi.api.assetinventory.SignedUrl, request, callback);
                }, "name", { value: "GenerateWritableSignedUrl" });

                /**
                 * Calls GenerateWritableSignedUrl.
                 * @function generateWritableSignedUrl
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGenerateWritableSignedUrlRequest} request GenerateWritableSignedUrlRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.SignedUrl>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#generateReadableSignedUrl}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef GenerateReadableSignedUrlCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.SignedUrl} [response] SignedUrl
                 */

                /**
                 * Calls GenerateReadableSignedUrl.
                 * @function generateReadableSignedUrl
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGenerateReadableSignedUrlRequest} request GenerateReadableSignedUrlRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.GenerateReadableSignedUrlCallback} callback Node-style callback called with the error, if any, and SignedUrl
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.generateReadableSignedUrl = function generateReadableSignedUrl(request, callback) {
                    return this.rpcCall(generateReadableSignedUrl, $root.mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest, $root.mtechnavi.api.assetinventory.SignedUrl, request, callback);
                }, "name", { value: "GenerateReadableSignedUrl" });

                /**
                 * Calls GenerateReadableSignedUrl.
                 * @function generateReadableSignedUrl
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGenerateReadableSignedUrlRequest} request GenerateReadableSignedUrlRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.SignedUrl>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#createAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef CreateAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.Asset} [response] Asset
                 */

                /**
                 * Calls CreateAsset.
                 * @function createAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateAssetRequest} request CreateAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.CreateAssetCallback} callback Node-style callback called with the error, if any, and Asset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.createAsset = function createAsset(request, callback) {
                    return this.rpcCall(createAsset, $root.mtechnavi.api.assetinventory.CreateAssetRequest, $root.mtechnavi.api.assetinventory.Asset, request, callback);
                }, "name", { value: "CreateAsset" });

                /**
                 * Calls CreateAsset.
                 * @function createAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateAssetRequest} request CreateAssetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.Asset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#getAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef GetAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.Asset} [response] Asset
                 */

                /**
                 * Calls GetAsset.
                 * @function getAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGetAssetRequest} request GetAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.GetAssetCallback} callback Node-style callback called with the error, if any, and Asset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.getAsset = function getAsset(request, callback) {
                    return this.rpcCall(getAsset, $root.mtechnavi.api.assetinventory.GetAssetRequest, $root.mtechnavi.api.assetinventory.Asset, request, callback);
                }, "name", { value: "GetAsset" });

                /**
                 * Calls GetAsset.
                 * @function getAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IGetAssetRequest} request GetAssetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.Asset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#listAssets}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef ListAssetsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.ListAssetsResponse} [response] ListAssetsResponse
                 */

                /**
                 * Calls ListAssets.
                 * @function listAssets
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IListAssetsRequest} request ListAssetsRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.ListAssetsCallback} callback Node-style callback called with the error, if any, and ListAssetsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.listAssets = function listAssets(request, callback) {
                    return this.rpcCall(listAssets, $root.mtechnavi.api.assetinventory.ListAssetsRequest, $root.mtechnavi.api.assetinventory.ListAssetsResponse, request, callback);
                }, "name", { value: "ListAssets" });

                /**
                 * Calls ListAssets.
                 * @function listAssets
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IListAssetsRequest} request ListAssetsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.ListAssetsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#updateAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef UpdateAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.Asset} [response] Asset
                 */

                /**
                 * Calls UpdateAsset.
                 * @function updateAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IUpdateAssetRequest} request UpdateAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.UpdateAssetCallback} callback Node-style callback called with the error, if any, and Asset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.updateAsset = function updateAsset(request, callback) {
                    return this.rpcCall(updateAsset, $root.mtechnavi.api.assetinventory.UpdateAssetRequest, $root.mtechnavi.api.assetinventory.Asset, request, callback);
                }, "name", { value: "UpdateAsset" });

                /**
                 * Calls UpdateAsset.
                 * @function updateAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IUpdateAssetRequest} request UpdateAssetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.Asset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#deleteAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef DeleteAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteAsset.
                 * @function deleteAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IDeleteAssetRequest} request DeleteAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.DeleteAssetCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.deleteAsset = function deleteAsset(request, callback) {
                    return this.rpcCall(deleteAsset, $root.mtechnavi.api.assetinventory.DeleteAssetRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteAsset" });

                /**
                 * Calls DeleteAsset.
                 * @function deleteAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.IDeleteAssetRequest} request DeleteAssetRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#createZippedAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef CreateZippedAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.CreateZippedAssetResponse} [response] CreateZippedAssetResponse
                 */

                /**
                 * Calls CreateZippedAsset.
                 * @function createZippedAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateZippedAssetRequest} request CreateZippedAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.CreateZippedAssetCallback} callback Node-style callback called with the error, if any, and CreateZippedAssetResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.createZippedAsset = function createZippedAsset(request, callback) {
                    return this.rpcCall(createZippedAsset, $root.mtechnavi.api.assetinventory.CreateZippedAssetRequest, $root.mtechnavi.api.assetinventory.CreateZippedAssetResponse, request, callback);
                }, "name", { value: "CreateZippedAsset" });

                /**
                 * Calls CreateZippedAsset.
                 * @function createZippedAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateZippedAssetRequest} request CreateZippedAssetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.CreateZippedAssetResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.AssetInventory#createTemporaryAsset}.
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @typedef CreateTemporaryAssetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryAssetResponse} [response] CreateTemporaryAssetResponse
                 */

                /**
                 * Calls CreateTemporaryAsset.
                 * @function createTemporaryAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryAssetRequest} request CreateTemporaryAssetRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.AssetInventory.CreateTemporaryAssetCallback} callback Node-style callback called with the error, if any, and CreateTemporaryAssetResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AssetInventory.prototype.createTemporaryAsset = function createTemporaryAsset(request, callback) {
                    return this.rpcCall(createTemporaryAsset, $root.mtechnavi.api.assetinventory.CreateTemporaryAssetRequest, $root.mtechnavi.api.assetinventory.CreateTemporaryAssetResponse, request, callback);
                }, "name", { value: "CreateTemporaryAsset" });

                /**
                 * Calls CreateTemporaryAsset.
                 * @function createTemporaryAsset
                 * @memberof mtechnavi.api.assetinventory.AssetInventory
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryAssetRequest} request CreateTemporaryAssetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.CreateTemporaryAssetResponse>} Promise
                 * @variation 2
                 */

                return AssetInventory;
            })();

            assetinventory.ImageService = (function() {

                /**
                 * Constructs a new ImageService service.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents an ImageService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ImageService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ImageService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ImageService;

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.ImageService#createAssetImages}.
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @typedef CreateAssetImagesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.CreateAssetImagesResponse} [response] CreateAssetImagesResponse
                 */

                /**
                 * Calls CreateAssetImages.
                 * @function createAssetImages
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateAssetImagesRequest} request CreateAssetImagesRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.ImageService.CreateAssetImagesCallback} callback Node-style callback called with the error, if any, and CreateAssetImagesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ImageService.prototype.createAssetImages = function createAssetImages(request, callback) {
                    return this.rpcCall(createAssetImages, $root.mtechnavi.api.assetinventory.CreateAssetImagesRequest, $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse, request, callback);
                }, "name", { value: "CreateAssetImages" });

                /**
                 * Calls CreateAssetImages.
                 * @function createAssetImages
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateAssetImagesRequest} request CreateAssetImagesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.CreateAssetImagesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.assetinventory.ImageService#createTemporaryImages}.
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @typedef CreateTemporaryImagesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse} [response] CreateTemporaryImagesResponse
                 */

                /**
                 * Calls CreateTemporaryImages.
                 * @function createTemporaryImages
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest} request CreateTemporaryImagesRequest message or plain object
                 * @param {mtechnavi.api.assetinventory.ImageService.CreateTemporaryImagesCallback} callback Node-style callback called with the error, if any, and CreateTemporaryImagesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ImageService.prototype.createTemporaryImages = function createTemporaryImages(request, callback) {
                    return this.rpcCall(createTemporaryImages, $root.mtechnavi.api.assetinventory.CreateTemporaryImagesRequest, $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse, request, callback);
                }, "name", { value: "CreateTemporaryImages" });

                /**
                 * Calls CreateTemporaryImages.
                 * @function createTemporaryImages
                 * @memberof mtechnavi.api.assetinventory.ImageService
                 * @instance
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest} request CreateTemporaryImagesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.assetinventory.CreateTemporaryImagesResponse>} Promise
                 * @variation 2
                 */

                return ImageService;
            })();

            assetinventory.GenerateWritableSignedUrlRequest = (function() {

                /**
                 * Properties of a GenerateWritableSignedUrlRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IGenerateWritableSignedUrlRequest
                 * @property {Long|null} [expires] GenerateWritableSignedUrlRequest expires
                 * @property {string|null} [contentType] GenerateWritableSignedUrlRequest contentType
                 */

                /**
                 * Constructs a new GenerateWritableSignedUrlRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a GenerateWritableSignedUrlRequest.
                 * @implements IGenerateWritableSignedUrlRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IGenerateWritableSignedUrlRequest=} [properties] Properties to set
                 */
                function GenerateWritableSignedUrlRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GenerateWritableSignedUrlRequest expires.
                 * @member {Long} expires
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @instance
                 */
                GenerateWritableSignedUrlRequest.prototype.expires = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GenerateWritableSignedUrlRequest contentType.
                 * @member {string} contentType
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @instance
                 */
                GenerateWritableSignedUrlRequest.prototype.contentType = "";

                /**
                 * Verifies a GenerateWritableSignedUrlRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateWritableSignedUrlRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (!$util.isInteger(message.expires) && !(message.expires && $util.isInteger(message.expires.low) && $util.isInteger(message.expires.high)))
                            return "expires: integer|Long expected";
                    if (message.contentType != null && message.hasOwnProperty("contentType"))
                        if (!$util.isString(message.contentType))
                            return "contentType: string expected";
                    return null;
                };

                /**
                 * Creates a GenerateWritableSignedUrlRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest} GenerateWritableSignedUrlRequest
                 */
                GenerateWritableSignedUrlRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest();
                    if (object.expires != null)
                        if ($util.Long)
                            (message.expires = $util.Long.fromValue(object.expires)).unsigned = false;
                        else if (typeof object.expires === "string")
                            message.expires = parseInt(object.expires, 10);
                        else if (typeof object.expires === "number")
                            message.expires = object.expires;
                        else if (typeof object.expires === "object")
                            message.expires = new $util.LongBits(object.expires.low >>> 0, object.expires.high >>> 0).toNumber();
                    if (object.contentType != null)
                        message.contentType = String(object.contentType);
                    return message;
                };

                /**
                 * Creates a plain object from a GenerateWritableSignedUrlRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest} message GenerateWritableSignedUrlRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateWritableSignedUrlRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expires = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expires = options.longs === String ? "0" : 0;
                        object.contentType = "";
                    }
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (typeof message.expires === "number")
                            object.expires = options.longs === String ? String(message.expires) : message.expires;
                        else
                            object.expires = options.longs === String ? $util.Long.prototype.toString.call(message.expires) : options.longs === Number ? new $util.LongBits(message.expires.low >>> 0, message.expires.high >>> 0).toNumber() : message.expires;
                    if (message.contentType != null && message.hasOwnProperty("contentType"))
                        object.contentType = message.contentType;
                    return object;
                };

                /**
                 * Converts this GenerateWritableSignedUrlRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.GenerateWritableSignedUrlRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateWritableSignedUrlRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GenerateWritableSignedUrlRequest;
            })();

            assetinventory.GenerateReadableSignedUrlRequest = (function() {

                /**
                 * Properties of a GenerateReadableSignedUrlRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IGenerateReadableSignedUrlRequest
                 * @property {Long|null} [expires] GenerateReadableSignedUrlRequest expires
                 * @property {string|null} [signedUrl] GenerateReadableSignedUrlRequest signedUrl
                 */

                /**
                 * Constructs a new GenerateReadableSignedUrlRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a GenerateReadableSignedUrlRequest.
                 * @implements IGenerateReadableSignedUrlRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IGenerateReadableSignedUrlRequest=} [properties] Properties to set
                 */
                function GenerateReadableSignedUrlRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GenerateReadableSignedUrlRequest expires.
                 * @member {Long} expires
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @instance
                 */
                GenerateReadableSignedUrlRequest.prototype.expires = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * GenerateReadableSignedUrlRequest signedUrl.
                 * @member {string} signedUrl
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @instance
                 */
                GenerateReadableSignedUrlRequest.prototype.signedUrl = "";

                /**
                 * Verifies a GenerateReadableSignedUrlRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateReadableSignedUrlRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (!$util.isInteger(message.expires) && !(message.expires && $util.isInteger(message.expires.low) && $util.isInteger(message.expires.high)))
                            return "expires: integer|Long expected";
                    if (message.signedUrl != null && message.hasOwnProperty("signedUrl"))
                        if (!$util.isString(message.signedUrl))
                            return "signedUrl: string expected";
                    return null;
                };

                /**
                 * Creates a GenerateReadableSignedUrlRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest} GenerateReadableSignedUrlRequest
                 */
                GenerateReadableSignedUrlRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest();
                    if (object.expires != null)
                        if ($util.Long)
                            (message.expires = $util.Long.fromValue(object.expires)).unsigned = false;
                        else if (typeof object.expires === "string")
                            message.expires = parseInt(object.expires, 10);
                        else if (typeof object.expires === "number")
                            message.expires = object.expires;
                        else if (typeof object.expires === "object")
                            message.expires = new $util.LongBits(object.expires.low >>> 0, object.expires.high >>> 0).toNumber();
                    if (object.signedUrl != null)
                        message.signedUrl = String(object.signedUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a GenerateReadableSignedUrlRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest} message GenerateReadableSignedUrlRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateReadableSignedUrlRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expires = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expires = options.longs === String ? "0" : 0;
                        object.signedUrl = "";
                    }
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (typeof message.expires === "number")
                            object.expires = options.longs === String ? String(message.expires) : message.expires;
                        else
                            object.expires = options.longs === String ? $util.Long.prototype.toString.call(message.expires) : options.longs === Number ? new $util.LongBits(message.expires.low >>> 0, message.expires.high >>> 0).toNumber() : message.expires;
                    if (message.signedUrl != null && message.hasOwnProperty("signedUrl"))
                        object.signedUrl = message.signedUrl;
                    return object;
                };

                /**
                 * Converts this GenerateReadableSignedUrlRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.GenerateReadableSignedUrlRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateReadableSignedUrlRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GenerateReadableSignedUrlRequest;
            })();

            assetinventory.CreateAssetRequest = (function() {

                /**
                 * Properties of a CreateAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateAssetRequest
                 * @property {mtechnavi.api.assetinventory.IAsset|null} [asset] CreateAssetRequest asset
                 * @property {string|null} [sourceUrl] CreateAssetRequest sourceUrl
                 */

                /**
                 * Constructs a new CreateAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateAssetRequest.
                 * @implements ICreateAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateAssetRequest=} [properties] Properties to set
                 */
                function CreateAssetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateAssetRequest asset.
                 * @member {mtechnavi.api.assetinventory.IAsset|null|undefined} asset
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @instance
                 */
                CreateAssetRequest.prototype.asset = null;

                /**
                 * CreateAssetRequest sourceUrl.
                 * @member {string} sourceUrl
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @instance
                 */
                CreateAssetRequest.prototype.sourceUrl = "";

                /**
                 * Verifies a CreateAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asset != null && message.hasOwnProperty("asset")) {
                        let error = $root.mtechnavi.api.assetinventory.Asset.verify(message.asset);
                        if (error)
                            return "asset." + error;
                    }
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        if (!$util.isString(message.sourceUrl))
                            return "sourceUrl: string expected";
                    return null;
                };

                /**
                 * Creates a CreateAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateAssetRequest} CreateAssetRequest
                 */
                CreateAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateAssetRequest();
                    if (object.asset != null) {
                        if (typeof object.asset !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.CreateAssetRequest.asset: object expected");
                        message.asset = $root.mtechnavi.api.assetinventory.Asset.fromObject(object.asset);
                    }
                    if (object.sourceUrl != null)
                        message.sourceUrl = String(object.sourceUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateAssetRequest} message CreateAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.asset = null;
                        object.sourceUrl = "";
                    }
                    if (message.asset != null && message.hasOwnProperty("asset"))
                        object.asset = $root.mtechnavi.api.assetinventory.Asset.toObject(message.asset, options);
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        object.sourceUrl = message.sourceUrl;
                    return object;
                };

                /**
                 * Converts this CreateAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAssetRequest;
            })();

            assetinventory.GetAssetRequest = (function() {

                /**
                 * Properties of a GetAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IGetAssetRequest
                 * @property {string|null} [assetId] GetAssetRequest assetId
                 */

                /**
                 * Constructs a new GetAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a GetAssetRequest.
                 * @implements IGetAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IGetAssetRequest=} [properties] Properties to set
                 */
                function GetAssetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetAssetRequest assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.assetinventory.GetAssetRequest
                 * @instance
                 */
                GetAssetRequest.prototype.assetId = "";

                /**
                 * Verifies a GetAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.GetAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates a GetAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.GetAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.GetAssetRequest} GetAssetRequest
                 */
                GetAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.GetAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.GetAssetRequest();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.GetAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.GetAssetRequest} message GetAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this GetAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.GetAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetAssetRequest;
            })();

            assetinventory.ListAssetsRequest = (function() {

                /**
                 * Properties of a ListAssetsRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IListAssetsRequest
                 * @property {Array.<string>|null} [ids] ListAssetsRequest ids
                 */

                /**
                 * Constructs a new ListAssetsRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a ListAssetsRequest.
                 * @implements IListAssetsRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IListAssetsRequest=} [properties] Properties to set
                 */
                function ListAssetsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAssetsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.assetinventory.ListAssetsRequest
                 * @instance
                 */
                ListAssetsRequest.prototype.ids = $util.emptyArray;

                /**
                 * Verifies a ListAssetsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.ListAssetsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAssetsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListAssetsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.ListAssetsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.ListAssetsRequest} ListAssetsRequest
                 */
                ListAssetsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.ListAssetsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.ListAssetsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.assetinventory.ListAssetsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAssetsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.ListAssetsRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.ListAssetsRequest} message ListAssetsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAssetsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListAssetsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.ListAssetsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAssetsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAssetsRequest;
            })();

            assetinventory.ListAssetsResponse = (function() {

                /**
                 * Properties of a ListAssetsResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IListAssetsResponse
                 * @property {Long|null} [total] ListAssetsResponse total
                 * @property {Array.<mtechnavi.api.assetinventory.IAsset>|null} [items] ListAssetsResponse items
                 */

                /**
                 * Constructs a new ListAssetsResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a ListAssetsResponse.
                 * @implements IListAssetsResponse
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IListAssetsResponse=} [properties] Properties to set
                 */
                function ListAssetsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAssetsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @instance
                 */
                ListAssetsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAssetsResponse items.
                 * @member {Array.<mtechnavi.api.assetinventory.IAsset>} items
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @instance
                 */
                ListAssetsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListAssetsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAssetsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.assetinventory.Asset.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListAssetsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.ListAssetsResponse} ListAssetsResponse
                 */
                ListAssetsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.ListAssetsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.ListAssetsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.assetinventory.ListAssetsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.ListAssetsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.assetinventory.Asset.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAssetsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @static
                 * @param {mtechnavi.api.assetinventory.ListAssetsResponse} message ListAssetsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAssetsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.assetinventory.Asset.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListAssetsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.ListAssetsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAssetsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAssetsResponse;
            })();

            assetinventory.UpdateAssetRequest = (function() {

                /**
                 * Properties of an UpdateAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IUpdateAssetRequest
                 * @property {mtechnavi.api.assetinventory.IAsset|null} [asset] UpdateAssetRequest asset
                 * @property {string|null} [sourceUrl] UpdateAssetRequest sourceUrl
                 */

                /**
                 * Constructs a new UpdateAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents an UpdateAssetRequest.
                 * @implements IUpdateAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IUpdateAssetRequest=} [properties] Properties to set
                 */
                function UpdateAssetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateAssetRequest asset.
                 * @member {mtechnavi.api.assetinventory.IAsset|null|undefined} asset
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @instance
                 */
                UpdateAssetRequest.prototype.asset = null;

                /**
                 * UpdateAssetRequest sourceUrl.
                 * @member {string} sourceUrl
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @instance
                 */
                UpdateAssetRequest.prototype.sourceUrl = "";

                /**
                 * Verifies an UpdateAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asset != null && message.hasOwnProperty("asset")) {
                        let error = $root.mtechnavi.api.assetinventory.Asset.verify(message.asset);
                        if (error)
                            return "asset." + error;
                    }
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        if (!$util.isString(message.sourceUrl))
                            return "sourceUrl: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.UpdateAssetRequest} UpdateAssetRequest
                 */
                UpdateAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.UpdateAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.UpdateAssetRequest();
                    if (object.asset != null) {
                        if (typeof object.asset !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.UpdateAssetRequest.asset: object expected");
                        message.asset = $root.mtechnavi.api.assetinventory.Asset.fromObject(object.asset);
                    }
                    if (object.sourceUrl != null)
                        message.sourceUrl = String(object.sourceUrl);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.UpdateAssetRequest} message UpdateAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.asset = null;
                        object.sourceUrl = "";
                    }
                    if (message.asset != null && message.hasOwnProperty("asset"))
                        object.asset = $root.mtechnavi.api.assetinventory.Asset.toObject(message.asset, options);
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        object.sourceUrl = message.sourceUrl;
                    return object;
                };

                /**
                 * Converts this UpdateAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.UpdateAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateAssetRequest;
            })();

            assetinventory.DeleteAssetRequest = (function() {

                /**
                 * Properties of a DeleteAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IDeleteAssetRequest
                 * @property {mtechnavi.api.assetinventory.IAsset|null} [asset] DeleteAssetRequest asset
                 */

                /**
                 * Constructs a new DeleteAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a DeleteAssetRequest.
                 * @implements IDeleteAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IDeleteAssetRequest=} [properties] Properties to set
                 */
                function DeleteAssetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteAssetRequest asset.
                 * @member {mtechnavi.api.assetinventory.IAsset|null|undefined} asset
                 * @memberof mtechnavi.api.assetinventory.DeleteAssetRequest
                 * @instance
                 */
                DeleteAssetRequest.prototype.asset = null;

                /**
                 * Verifies a DeleteAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.DeleteAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asset != null && message.hasOwnProperty("asset")) {
                        let error = $root.mtechnavi.api.assetinventory.Asset.verify(message.asset);
                        if (error)
                            return "asset." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.DeleteAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.DeleteAssetRequest} DeleteAssetRequest
                 */
                DeleteAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.DeleteAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.DeleteAssetRequest();
                    if (object.asset != null) {
                        if (typeof object.asset !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.DeleteAssetRequest.asset: object expected");
                        message.asset = $root.mtechnavi.api.assetinventory.Asset.fromObject(object.asset);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.DeleteAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.DeleteAssetRequest} message DeleteAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.asset = null;
                    if (message.asset != null && message.hasOwnProperty("asset"))
                        object.asset = $root.mtechnavi.api.assetinventory.Asset.toObject(message.asset, options);
                    return object;
                };

                /**
                 * Converts this DeleteAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.DeleteAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteAssetRequest;
            })();

            assetinventory.CreateZippedAssetRequest = (function() {

                /**
                 * Properties of a CreateZippedAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateZippedAssetRequest
                 * @property {Array.<string>|null} [assetIds] CreateZippedAssetRequest assetIds
                 * @property {Long|null} [expires] CreateZippedAssetRequest expires
                 */

                /**
                 * Constructs a new CreateZippedAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateZippedAssetRequest.
                 * @implements ICreateZippedAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateZippedAssetRequest=} [properties] Properties to set
                 */
                function CreateZippedAssetRequest(properties) {
                    this.assetIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateZippedAssetRequest assetIds.
                 * @member {Array.<string>} assetIds
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @instance
                 */
                CreateZippedAssetRequest.prototype.assetIds = $util.emptyArray;

                /**
                 * CreateZippedAssetRequest expires.
                 * @member {Long} expires
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @instance
                 */
                CreateZippedAssetRequest.prototype.expires = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a CreateZippedAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateZippedAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetIds != null && message.hasOwnProperty("assetIds")) {
                        if (!Array.isArray(message.assetIds))
                            return "assetIds: array expected";
                        for (let i = 0; i < message.assetIds.length; ++i)
                            if (!$util.isString(message.assetIds[i]))
                                return "assetIds: string[] expected";
                    }
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (!$util.isInteger(message.expires) && !(message.expires && $util.isInteger(message.expires.low) && $util.isInteger(message.expires.high)))
                            return "expires: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a CreateZippedAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateZippedAssetRequest} CreateZippedAssetRequest
                 */
                CreateZippedAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateZippedAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateZippedAssetRequest();
                    if (object.assetIds) {
                        if (!Array.isArray(object.assetIds))
                            throw TypeError(".mtechnavi.api.assetinventory.CreateZippedAssetRequest.assetIds: array expected");
                        message.assetIds = [];
                        for (let i = 0; i < object.assetIds.length; ++i)
                            message.assetIds[i] = String(object.assetIds[i]);
                    }
                    if (object.expires != null)
                        if ($util.Long)
                            (message.expires = $util.Long.fromValue(object.expires)).unsigned = false;
                        else if (typeof object.expires === "string")
                            message.expires = parseInt(object.expires, 10);
                        else if (typeof object.expires === "number")
                            message.expires = object.expires;
                        else if (typeof object.expires === "object")
                            message.expires = new $util.LongBits(object.expires.low >>> 0, object.expires.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a CreateZippedAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateZippedAssetRequest} message CreateZippedAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateZippedAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.assetIds = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expires = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expires = options.longs === String ? "0" : 0;
                    if (message.assetIds && message.assetIds.length) {
                        object.assetIds = [];
                        for (let j = 0; j < message.assetIds.length; ++j)
                            object.assetIds[j] = message.assetIds[j];
                    }
                    if (message.expires != null && message.hasOwnProperty("expires"))
                        if (typeof message.expires === "number")
                            object.expires = options.longs === String ? String(message.expires) : message.expires;
                        else
                            object.expires = options.longs === String ? $util.Long.prototype.toString.call(message.expires) : options.longs === Number ? new $util.LongBits(message.expires.low >>> 0, message.expires.high >>> 0).toNumber() : message.expires;
                    return object;
                };

                /**
                 * Converts this CreateZippedAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateZippedAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateZippedAssetRequest;
            })();

            assetinventory.CreateZippedAssetResponse = (function() {

                /**
                 * Properties of a CreateZippedAssetResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateZippedAssetResponse
                 * @property {string|null} [assetId] CreateZippedAssetResponse assetId
                 */

                /**
                 * Constructs a new CreateZippedAssetResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateZippedAssetResponse.
                 * @implements ICreateZippedAssetResponse
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateZippedAssetResponse=} [properties] Properties to set
                 */
                function CreateZippedAssetResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateZippedAssetResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetResponse
                 * @instance
                 */
                CreateZippedAssetResponse.prototype.assetId = "";

                /**
                 * Verifies a CreateZippedAssetResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateZippedAssetResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates a CreateZippedAssetResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateZippedAssetResponse} CreateZippedAssetResponse
                 */
                CreateZippedAssetResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateZippedAssetResponse)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateZippedAssetResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateZippedAssetResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetResponse
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateZippedAssetResponse} message CreateZippedAssetResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateZippedAssetResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this CreateZippedAssetResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateZippedAssetResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateZippedAssetResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateZippedAssetResponse;
            })();

            assetinventory.CreateTemporaryAssetRequest = (function() {

                /**
                 * Properties of a CreateTemporaryAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateTemporaryAssetRequest
                 * @property {string|null} [srcBucketName] CreateTemporaryAssetRequest srcBucketName
                 * @property {string|null} [srcObjectPrefix] CreateTemporaryAssetRequest srcObjectPrefix
                 * @property {mtn.FileEncoding|null} [encoding] CreateTemporaryAssetRequest encoding
                 */

                /**
                 * Constructs a new CreateTemporaryAssetRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateTemporaryAssetRequest.
                 * @implements ICreateTemporaryAssetRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryAssetRequest=} [properties] Properties to set
                 */
                function CreateTemporaryAssetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTemporaryAssetRequest srcBucketName.
                 * @member {string} srcBucketName
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @instance
                 */
                CreateTemporaryAssetRequest.prototype.srcBucketName = "";

                /**
                 * CreateTemporaryAssetRequest srcObjectPrefix.
                 * @member {string} srcObjectPrefix
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @instance
                 */
                CreateTemporaryAssetRequest.prototype.srcObjectPrefix = "";

                /**
                 * CreateTemporaryAssetRequest encoding.
                 * @member {mtn.FileEncoding} encoding
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @instance
                 */
                CreateTemporaryAssetRequest.prototype.encoding = 0;

                /**
                 * Verifies a CreateTemporaryAssetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTemporaryAssetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.srcBucketName != null && message.hasOwnProperty("srcBucketName"))
                        if (!$util.isString(message.srcBucketName))
                            return "srcBucketName: string expected";
                    if (message.srcObjectPrefix != null && message.hasOwnProperty("srcObjectPrefix"))
                        if (!$util.isString(message.srcObjectPrefix))
                            return "srcObjectPrefix: string expected";
                    if (message.encoding != null && message.hasOwnProperty("encoding"))
                        switch (message.encoding) {
                        default:
                            return "encoding: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a CreateTemporaryAssetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateTemporaryAssetRequest} CreateTemporaryAssetRequest
                 */
                CreateTemporaryAssetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateTemporaryAssetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateTemporaryAssetRequest();
                    if (object.srcBucketName != null)
                        message.srcBucketName = String(object.srcBucketName);
                    if (object.srcObjectPrefix != null)
                        message.srcObjectPrefix = String(object.srcObjectPrefix);
                    switch (object.encoding) {
                    case "UTF_8":
                    case 0:
                        message.encoding = 0;
                        break;
                    case "SHIFT_JIS":
                    case 1:
                        message.encoding = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTemporaryAssetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryAssetRequest} message CreateTemporaryAssetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTemporaryAssetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.srcBucketName = "";
                        object.srcObjectPrefix = "";
                        object.encoding = options.enums === String ? "UTF_8" : 0;
                    }
                    if (message.srcBucketName != null && message.hasOwnProperty("srcBucketName"))
                        object.srcBucketName = message.srcBucketName;
                    if (message.srcObjectPrefix != null && message.hasOwnProperty("srcObjectPrefix"))
                        object.srcObjectPrefix = message.srcObjectPrefix;
                    if (message.encoding != null && message.hasOwnProperty("encoding"))
                        object.encoding = options.enums === String ? $root.mtn.FileEncoding[message.encoding] : message.encoding;
                    return object;
                };

                /**
                 * Converts this CreateTemporaryAssetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTemporaryAssetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTemporaryAssetRequest;
            })();

            assetinventory.CreateTemporaryAssetResponse = (function() {

                /**
                 * Properties of a CreateTemporaryAssetResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateTemporaryAssetResponse
                 * @property {string|null} [assetId] CreateTemporaryAssetResponse assetId
                 * @property {string|null} [ext] CreateTemporaryAssetResponse ext
                 */

                /**
                 * Constructs a new CreateTemporaryAssetResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateTemporaryAssetResponse.
                 * @implements ICreateTemporaryAssetResponse
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryAssetResponse=} [properties] Properties to set
                 */
                function CreateTemporaryAssetResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTemporaryAssetResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @instance
                 */
                CreateTemporaryAssetResponse.prototype.assetId = "";

                /**
                 * CreateTemporaryAssetResponse ext.
                 * @member {string} ext
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @instance
                 */
                CreateTemporaryAssetResponse.prototype.ext = "";

                /**
                 * Verifies a CreateTemporaryAssetResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTemporaryAssetResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.ext != null && message.hasOwnProperty("ext"))
                        if (!$util.isString(message.ext))
                            return "ext: string expected";
                    return null;
                };

                /**
                 * Creates a CreateTemporaryAssetResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateTemporaryAssetResponse} CreateTemporaryAssetResponse
                 */
                CreateTemporaryAssetResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateTemporaryAssetResponse)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateTemporaryAssetResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.ext != null)
                        message.ext = String(object.ext);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTemporaryAssetResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryAssetResponse} message CreateTemporaryAssetResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTemporaryAssetResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.assetId = "";
                        object.ext = "";
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    if (message.ext != null && message.hasOwnProperty("ext"))
                        object.ext = message.ext;
                    return object;
                };

                /**
                 * Converts this CreateTemporaryAssetResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryAssetResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTemporaryAssetResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTemporaryAssetResponse;
            })();

            assetinventory.CreateAssetImagesRequest = (function() {

                /**
                 * Properties of a CreateAssetImagesRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateAssetImagesRequest
                 * @property {string|null} [assetId] CreateAssetImagesRequest assetId
                 * @property {Array.<mtechnavi.api.assetinventory.IImageProperty>|null} [imagePropertys] CreateAssetImagesRequest imagePropertys
                 */

                /**
                 * Constructs a new CreateAssetImagesRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateAssetImagesRequest.
                 * @implements ICreateAssetImagesRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateAssetImagesRequest=} [properties] Properties to set
                 */
                function CreateAssetImagesRequest(properties) {
                    this.imagePropertys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateAssetImagesRequest assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @instance
                 */
                CreateAssetImagesRequest.prototype.assetId = "";

                /**
                 * CreateAssetImagesRequest imagePropertys.
                 * @member {Array.<mtechnavi.api.assetinventory.IImageProperty>} imagePropertys
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @instance
                 */
                CreateAssetImagesRequest.prototype.imagePropertys = $util.emptyArray;

                /**
                 * Verifies a CreateAssetImagesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAssetImagesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.imagePropertys != null && message.hasOwnProperty("imagePropertys")) {
                        if (!Array.isArray(message.imagePropertys))
                            return "imagePropertys: array expected";
                        for (let i = 0; i < message.imagePropertys.length; ++i) {
                            let error = $root.mtechnavi.api.assetinventory.ImageProperty.verify(message.imagePropertys[i]);
                            if (error)
                                return "imagePropertys." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateAssetImagesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateAssetImagesRequest} CreateAssetImagesRequest
                 */
                CreateAssetImagesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateAssetImagesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateAssetImagesRequest();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.imagePropertys) {
                        if (!Array.isArray(object.imagePropertys))
                            throw TypeError(".mtechnavi.api.assetinventory.CreateAssetImagesRequest.imagePropertys: array expected");
                        message.imagePropertys = [];
                        for (let i = 0; i < object.imagePropertys.length; ++i) {
                            if (typeof object.imagePropertys[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.CreateAssetImagesRequest.imagePropertys: object expected");
                            message.imagePropertys[i] = $root.mtechnavi.api.assetinventory.ImageProperty.fromObject(object.imagePropertys[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateAssetImagesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateAssetImagesRequest} message CreateAssetImagesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAssetImagesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.imagePropertys = [];
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    if (message.imagePropertys && message.imagePropertys.length) {
                        object.imagePropertys = [];
                        for (let j = 0; j < message.imagePropertys.length; ++j)
                            object.imagePropertys[j] = $root.mtechnavi.api.assetinventory.ImageProperty.toObject(message.imagePropertys[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateAssetImagesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAssetImagesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAssetImagesRequest;
            })();

            assetinventory.CreateAssetImagesResponse = (function() {

                /**
                 * Properties of a CreateAssetImagesResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateAssetImagesResponse
                 * @property {Array.<mtechnavi.api.assetinventory.CreateAssetImagesResponse.ICreatedImage>|null} [createdImages] CreateAssetImagesResponse createdImages
                 */

                /**
                 * Constructs a new CreateAssetImagesResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateAssetImagesResponse.
                 * @implements ICreateAssetImagesResponse
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateAssetImagesResponse=} [properties] Properties to set
                 */
                function CreateAssetImagesResponse(properties) {
                    this.createdImages = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateAssetImagesResponse createdImages.
                 * @member {Array.<mtechnavi.api.assetinventory.CreateAssetImagesResponse.ICreatedImage>} createdImages
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                 * @instance
                 */
                CreateAssetImagesResponse.prototype.createdImages = $util.emptyArray;

                /**
                 * Verifies a CreateAssetImagesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAssetImagesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.createdImages != null && message.hasOwnProperty("createdImages")) {
                        if (!Array.isArray(message.createdImages))
                            return "createdImages: array expected";
                        for (let i = 0; i < message.createdImages.length; ++i) {
                            let error = $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage.verify(message.createdImages[i]);
                            if (error)
                                return "createdImages." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateAssetImagesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateAssetImagesResponse} CreateAssetImagesResponse
                 */
                CreateAssetImagesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse();
                    if (object.createdImages) {
                        if (!Array.isArray(object.createdImages))
                            throw TypeError(".mtechnavi.api.assetinventory.CreateAssetImagesResponse.createdImages: array expected");
                        message.createdImages = [];
                        for (let i = 0; i < object.createdImages.length; ++i) {
                            if (typeof object.createdImages[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.CreateAssetImagesResponse.createdImages: object expected");
                            message.createdImages[i] = $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage.fromObject(object.createdImages[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateAssetImagesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateAssetImagesResponse} message CreateAssetImagesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAssetImagesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.createdImages = [];
                    if (message.createdImages && message.createdImages.length) {
                        object.createdImages = [];
                        for (let j = 0; j < message.createdImages.length; ++j)
                            object.createdImages[j] = $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage.toObject(message.createdImages[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateAssetImagesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAssetImagesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateAssetImagesResponse.CreatedImage = (function() {

                    /**
                     * Properties of a CreatedImage.
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                     * @interface ICreatedImage
                     * @property {string|null} [name] CreatedImage name
                     * @property {string|null} [assetId] CreatedImage assetId
                     * @property {Long|null} [width] CreatedImage width
                     * @property {Long|null} [height] CreatedImage height
                     */

                    /**
                     * Constructs a new CreatedImage.
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse
                     * @classdesc Represents a CreatedImage.
                     * @implements ICreatedImage
                     * @constructor
                     * @param {mtechnavi.api.assetinventory.CreateAssetImagesResponse.ICreatedImage=} [properties] Properties to set
                     */
                    function CreatedImage(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreatedImage name.
                     * @member {string} name
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.name = "";

                    /**
                     * CreatedImage assetId.
                     * @member {string} assetId
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.assetId = "";

                    /**
                     * CreatedImage width.
                     * @member {Long} width
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CreatedImage height.
                     * @member {Long} height
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a CreatedImage message.
                     * @function verify
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreatedImage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.assetId != null && message.hasOwnProperty("assetId"))
                            if (!$util.isString(message.assetId))
                                return "assetId: string expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                                return "width: integer|Long expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                                return "height: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a CreatedImage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage} CreatedImage
                     */
                    CreatedImage.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage)
                            return object;
                        let message = new $root.mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.assetId != null)
                            message.assetId = String(object.assetId);
                        if (object.width != null)
                            if ($util.Long)
                                (message.width = $util.Long.fromValue(object.width)).unsigned = false;
                            else if (typeof object.width === "string")
                                message.width = parseInt(object.width, 10);
                            else if (typeof object.width === "number")
                                message.width = object.width;
                            else if (typeof object.width === "object")
                                message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber();
                        if (object.height != null)
                            if ($util.Long)
                                (message.height = $util.Long.fromValue(object.height)).unsigned = false;
                            else if (typeof object.height === "string")
                                message.height = parseInt(object.height, 10);
                            else if (typeof object.height === "number")
                                message.height = object.height;
                            else if (typeof object.height === "object")
                                message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreatedImage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @static
                     * @param {mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage} message CreatedImage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreatedImage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.assetId = "";
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.width = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.height = options.longs === String ? "0" : 0;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.assetId != null && message.hasOwnProperty("assetId"))
                            object.assetId = message.assetId;
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (typeof message.width === "number")
                                object.width = options.longs === String ? String(message.width) : message.width;
                            else
                                object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber() : message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (typeof message.height === "number")
                                object.height = options.longs === String ? String(message.height) : message.height;
                            else
                                object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber() : message.height;
                        return object;
                    };

                    /**
                     * Converts this CreatedImage to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.assetinventory.CreateAssetImagesResponse.CreatedImage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreatedImage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreatedImage;
                })();

                return CreateAssetImagesResponse;
            })();

            assetinventory.CreateTemporaryImagesRequest = (function() {

                /**
                 * Properties of a CreateTemporaryImagesRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateTemporaryImagesRequest
                 * @property {string|null} [sourceUrl] CreateTemporaryImagesRequest sourceUrl
                 * @property {Array.<mtechnavi.api.assetinventory.IImageProperty>|null} [imagePropertys] CreateTemporaryImagesRequest imagePropertys
                 */

                /**
                 * Constructs a new CreateTemporaryImagesRequest.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateTemporaryImagesRequest.
                 * @implements ICreateTemporaryImagesRequest
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryImagesRequest=} [properties] Properties to set
                 */
                function CreateTemporaryImagesRequest(properties) {
                    this.imagePropertys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTemporaryImagesRequest sourceUrl.
                 * @member {string} sourceUrl
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @instance
                 */
                CreateTemporaryImagesRequest.prototype.sourceUrl = "";

                /**
                 * CreateTemporaryImagesRequest imagePropertys.
                 * @member {Array.<mtechnavi.api.assetinventory.IImageProperty>} imagePropertys
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @instance
                 */
                CreateTemporaryImagesRequest.prototype.imagePropertys = $util.emptyArray;

                /**
                 * Verifies a CreateTemporaryImagesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTemporaryImagesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        if (!$util.isString(message.sourceUrl))
                            return "sourceUrl: string expected";
                    if (message.imagePropertys != null && message.hasOwnProperty("imagePropertys")) {
                        if (!Array.isArray(message.imagePropertys))
                            return "imagePropertys: array expected";
                        for (let i = 0; i < message.imagePropertys.length; ++i) {
                            let error = $root.mtechnavi.api.assetinventory.ImageProperty.verify(message.imagePropertys[i]);
                            if (error)
                                return "imagePropertys." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateTemporaryImagesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateTemporaryImagesRequest} CreateTemporaryImagesRequest
                 */
                CreateTemporaryImagesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateTemporaryImagesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateTemporaryImagesRequest();
                    if (object.sourceUrl != null)
                        message.sourceUrl = String(object.sourceUrl);
                    if (object.imagePropertys) {
                        if (!Array.isArray(object.imagePropertys))
                            throw TypeError(".mtechnavi.api.assetinventory.CreateTemporaryImagesRequest.imagePropertys: array expected");
                        message.imagePropertys = [];
                        for (let i = 0; i < object.imagePropertys.length; ++i) {
                            if (typeof object.imagePropertys[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.CreateTemporaryImagesRequest.imagePropertys: object expected");
                            message.imagePropertys[i] = $root.mtechnavi.api.assetinventory.ImageProperty.fromObject(object.imagePropertys[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTemporaryImagesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryImagesRequest} message CreateTemporaryImagesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTemporaryImagesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.imagePropertys = [];
                    if (options.defaults)
                        object.sourceUrl = "";
                    if (message.sourceUrl != null && message.hasOwnProperty("sourceUrl"))
                        object.sourceUrl = message.sourceUrl;
                    if (message.imagePropertys && message.imagePropertys.length) {
                        object.imagePropertys = [];
                        for (let j = 0; j < message.imagePropertys.length; ++j)
                            object.imagePropertys[j] = $root.mtechnavi.api.assetinventory.ImageProperty.toObject(message.imagePropertys[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateTemporaryImagesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTemporaryImagesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTemporaryImagesRequest;
            })();

            assetinventory.CreateTemporaryImagesResponse = (function() {

                /**
                 * Properties of a CreateTemporaryImagesResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ICreateTemporaryImagesResponse
                 * @property {Array.<mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.ICreatedImage>|null} [createdImages] CreateTemporaryImagesResponse createdImages
                 */

                /**
                 * Constructs a new CreateTemporaryImagesResponse.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a CreateTemporaryImagesResponse.
                 * @implements ICreateTemporaryImagesResponse
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ICreateTemporaryImagesResponse=} [properties] Properties to set
                 */
                function CreateTemporaryImagesResponse(properties) {
                    this.createdImages = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTemporaryImagesResponse createdImages.
                 * @member {Array.<mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.ICreatedImage>} createdImages
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                 * @instance
                 */
                CreateTemporaryImagesResponse.prototype.createdImages = $util.emptyArray;

                /**
                 * Verifies a CreateTemporaryImagesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTemporaryImagesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.createdImages != null && message.hasOwnProperty("createdImages")) {
                        if (!Array.isArray(message.createdImages))
                            return "createdImages: array expected";
                        for (let i = 0; i < message.createdImages.length; ++i) {
                            let error = $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage.verify(message.createdImages[i]);
                            if (error)
                                return "createdImages." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateTemporaryImagesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse} CreateTemporaryImagesResponse
                 */
                CreateTemporaryImagesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse();
                    if (object.createdImages) {
                        if (!Array.isArray(object.createdImages))
                            throw TypeError(".mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.createdImages: array expected");
                        message.createdImages = [];
                        for (let i = 0; i < object.createdImages.length; ++i) {
                            if (typeof object.createdImages[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.createdImages: object expected");
                            message.createdImages[i] = $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage.fromObject(object.createdImages[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTemporaryImagesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                 * @static
                 * @param {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse} message CreateTemporaryImagesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTemporaryImagesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.createdImages = [];
                    if (message.createdImages && message.createdImages.length) {
                        object.createdImages = [];
                        for (let j = 0; j < message.createdImages.length; ++j)
                            object.createdImages[j] = $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage.toObject(message.createdImages[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateTemporaryImagesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTemporaryImagesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateTemporaryImagesResponse.CreatedImage = (function() {

                    /**
                     * Properties of a CreatedImage.
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                     * @interface ICreatedImage
                     * @property {string|null} [name] CreatedImage name
                     * @property {string|null} [signedUrl] CreatedImage signedUrl
                     * @property {Long|null} [width] CreatedImage width
                     * @property {Long|null} [height] CreatedImage height
                     * @property {string|null} [assetId] CreatedImage assetId
                     */

                    /**
                     * Constructs a new CreatedImage.
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse
                     * @classdesc Represents a CreatedImage.
                     * @implements ICreatedImage
                     * @constructor
                     * @param {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.ICreatedImage=} [properties] Properties to set
                     */
                    function CreatedImage(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreatedImage name.
                     * @member {string} name
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.name = "";

                    /**
                     * CreatedImage signedUrl.
                     * @member {string} signedUrl
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.signedUrl = "";

                    /**
                     * CreatedImage width.
                     * @member {Long} width
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CreatedImage height.
                     * @member {Long} height
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CreatedImage assetId.
                     * @member {string} assetId
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     */
                    CreatedImage.prototype.assetId = "";

                    /**
                     * Verifies a CreatedImage message.
                     * @function verify
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreatedImage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.signedUrl != null && message.hasOwnProperty("signedUrl"))
                            if (!$util.isString(message.signedUrl))
                                return "signedUrl: string expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                                return "width: integer|Long expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                                return "height: integer|Long expected";
                        if (message.assetId != null && message.hasOwnProperty("assetId"))
                            if (!$util.isString(message.assetId))
                                return "assetId: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreatedImage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage} CreatedImage
                     */
                    CreatedImage.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage)
                            return object;
                        let message = new $root.mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.signedUrl != null)
                            message.signedUrl = String(object.signedUrl);
                        if (object.width != null)
                            if ($util.Long)
                                (message.width = $util.Long.fromValue(object.width)).unsigned = false;
                            else if (typeof object.width === "string")
                                message.width = parseInt(object.width, 10);
                            else if (typeof object.width === "number")
                                message.width = object.width;
                            else if (typeof object.width === "object")
                                message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber();
                        if (object.height != null)
                            if ($util.Long)
                                (message.height = $util.Long.fromValue(object.height)).unsigned = false;
                            else if (typeof object.height === "string")
                                message.height = parseInt(object.height, 10);
                            else if (typeof object.height === "number")
                                message.height = object.height;
                            else if (typeof object.height === "object")
                                message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber();
                        if (object.assetId != null)
                            message.assetId = String(object.assetId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreatedImage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @static
                     * @param {mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage} message CreatedImage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreatedImage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.signedUrl = "";
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.width = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.height = options.longs === String ? "0" : 0;
                            object.assetId = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.signedUrl != null && message.hasOwnProperty("signedUrl"))
                            object.signedUrl = message.signedUrl;
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (typeof message.width === "number")
                                object.width = options.longs === String ? String(message.width) : message.width;
                            else
                                object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber() : message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (typeof message.height === "number")
                                object.height = options.longs === String ? String(message.height) : message.height;
                            else
                                object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber() : message.height;
                        if (message.assetId != null && message.hasOwnProperty("assetId"))
                            object.assetId = message.assetId;
                        return object;
                    };

                    /**
                     * Converts this CreatedImage to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.assetinventory.CreateTemporaryImagesResponse.CreatedImage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreatedImage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreatedImage;
                })();

                return CreateTemporaryImagesResponse;
            })();

            assetinventory.Asset = (function() {

                /**
                 * Properties of an Asset.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IAsset
                 * @property {string|null} [assetId] Asset assetId
                 * @property {string|null} [filename] Asset filename
                 * @property {string|null} [mimeType] Asset mimeType
                 * @property {string|null} [displayName] Asset displayName
                 * @property {string|null} [assetDataId] Asset assetDataId
                 * @property {Array.<sharelib.INameOption>|null} [purposeUnits] Asset purposeUnits
                 * @property {Object.<string,string>|null} [purposeUnitProperties] Asset purposeUnitProperties
                 * @property {sharelib.IEmbeddedUser|null} [createdBy] Asset createdBy
                 * @property {sharelib.IEmbeddedUser|null} [updatedBy] Asset updatedBy
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Asset sharedProperties
                 * @property {Long|null} [deletedAt] Asset deletedAt
                 * @property {Long|null} [createdAt] Asset createdAt
                 * @property {Long|null} [updatedAt] Asset updatedAt
                 */

                /**
                 * Constructs a new Asset.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents an Asset.
                 * @implements IAsset
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IAsset=} [properties] Properties to set
                 */
                function Asset(properties) {
                    this.purposeUnits = [];
                    this.purposeUnitProperties = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Asset assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.assetId = "";

                /**
                 * Asset filename.
                 * @member {string} filename
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.filename = "";

                /**
                 * Asset mimeType.
                 * @member {string} mimeType
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.mimeType = "";

                /**
                 * Asset displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.displayName = "";

                /**
                 * Asset assetDataId.
                 * @member {string} assetDataId
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.assetDataId = "";

                /**
                 * Asset purposeUnits.
                 * @member {Array.<sharelib.INameOption>} purposeUnits
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.purposeUnits = $util.emptyArray;

                /**
                 * Asset purposeUnitProperties.
                 * @member {Object.<string,string>} purposeUnitProperties
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.purposeUnitProperties = $util.emptyObject;

                /**
                 * Asset createdBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} createdBy
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.createdBy = null;

                /**
                 * Asset updatedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} updatedBy
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.updatedBy = null;

                /**
                 * Asset sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.sharedProperties = null;

                /**
                 * Asset deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Asset createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Asset updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 */
                Asset.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Asset message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Asset.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        if (!$util.isString(message.filename))
                            return "filename: string expected";
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        if (!$util.isString(message.mimeType))
                            return "mimeType: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.assetDataId != null && message.hasOwnProperty("assetDataId"))
                        if (!$util.isString(message.assetDataId))
                            return "assetDataId: string expected";
                    if (message.purposeUnits != null && message.hasOwnProperty("purposeUnits")) {
                        if (!Array.isArray(message.purposeUnits))
                            return "purposeUnits: array expected";
                        for (let i = 0; i < message.purposeUnits.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.purposeUnits[i]);
                            if (error)
                                return "purposeUnits." + error;
                        }
                    }
                    if (message.purposeUnitProperties != null && message.hasOwnProperty("purposeUnitProperties")) {
                        if (!$util.isObject(message.purposeUnitProperties))
                            return "purposeUnitProperties: object expected";
                        let key = Object.keys(message.purposeUnitProperties);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.purposeUnitProperties[key[i]]))
                                return "purposeUnitProperties: string{k:string} expected";
                    }
                    if (message.createdBy != null && message.hasOwnProperty("createdBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.createdBy);
                        if (error)
                            return "createdBy." + error;
                    }
                    if (message.updatedBy != null && message.hasOwnProperty("updatedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.updatedBy);
                        if (error)
                            return "updatedBy." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Asset message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.Asset} Asset
                 */
                Asset.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.Asset)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.Asset();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.filename != null)
                        message.filename = String(object.filename);
                    if (object.mimeType != null)
                        message.mimeType = String(object.mimeType);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.assetDataId != null)
                        message.assetDataId = String(object.assetDataId);
                    if (object.purposeUnits) {
                        if (!Array.isArray(object.purposeUnits))
                            throw TypeError(".mtechnavi.api.assetinventory.Asset.purposeUnits: array expected");
                        message.purposeUnits = [];
                        for (let i = 0; i < object.purposeUnits.length; ++i) {
                            if (typeof object.purposeUnits[i] !== "object")
                                throw TypeError(".mtechnavi.api.assetinventory.Asset.purposeUnits: object expected");
                            message.purposeUnits[i] = $root.sharelib.NameOption.fromObject(object.purposeUnits[i]);
                        }
                    }
                    if (object.purposeUnitProperties) {
                        if (typeof object.purposeUnitProperties !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.Asset.purposeUnitProperties: object expected");
                        message.purposeUnitProperties = {};
                        for (let keys = Object.keys(object.purposeUnitProperties), i = 0; i < keys.length; ++i)
                            message.purposeUnitProperties[keys[i]] = String(object.purposeUnitProperties[keys[i]]);
                    }
                    if (object.createdBy != null) {
                        if (typeof object.createdBy !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.Asset.createdBy: object expected");
                        message.createdBy = $root.sharelib.EmbeddedUser.fromObject(object.createdBy);
                    }
                    if (object.updatedBy != null) {
                        if (typeof object.updatedBy !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.Asset.updatedBy: object expected");
                        message.updatedBy = $root.sharelib.EmbeddedUser.fromObject(object.updatedBy);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.Asset.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Asset message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @static
                 * @param {mtechnavi.api.assetinventory.Asset} message Asset
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Asset.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.purposeUnits = [];
                    if (options.objects || options.defaults)
                        object.purposeUnitProperties = {};
                    if (options.defaults) {
                        object.assetId = "";
                        object.displayName = "";
                        object.assetDataId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.filename = "";
                        object.mimeType = "";
                        object.createdBy = null;
                        object.updatedBy = null;
                        object.sharedProperties = null;
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.assetDataId != null && message.hasOwnProperty("assetDataId"))
                        object.assetDataId = message.assetDataId;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        object.filename = message.filename;
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        object.mimeType = message.mimeType;
                    if (message.purposeUnits && message.purposeUnits.length) {
                        object.purposeUnits = [];
                        for (let j = 0; j < message.purposeUnits.length; ++j)
                            object.purposeUnits[j] = $root.sharelib.NameOption.toObject(message.purposeUnits[j], options);
                    }
                    let keys2;
                    if (message.purposeUnitProperties && (keys2 = Object.keys(message.purposeUnitProperties)).length) {
                        object.purposeUnitProperties = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.purposeUnitProperties[keys2[j]] = message.purposeUnitProperties[keys2[j]];
                    }
                    if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                        object.createdBy = $root.sharelib.EmbeddedUser.toObject(message.createdBy, options);
                    if (message.updatedBy != null && message.hasOwnProperty("updatedBy"))
                        object.updatedBy = $root.sharelib.EmbeddedUser.toObject(message.updatedBy, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    return object;
                };

                /**
                 * Converts this Asset to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.Asset
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Asset.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Asset;
            })();

            assetinventory.SignedUrl = (function() {

                /**
                 * Properties of a SignedUrl.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface ISignedUrl
                 * @property {string|null} [url] SignedUrl url
                 */

                /**
                 * Constructs a new SignedUrl.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents a SignedUrl.
                 * @implements ISignedUrl
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.ISignedUrl=} [properties] Properties to set
                 */
                function SignedUrl(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SignedUrl url.
                 * @member {string} url
                 * @memberof mtechnavi.api.assetinventory.SignedUrl
                 * @instance
                 */
                SignedUrl.prototype.url = "";

                /**
                 * Verifies a SignedUrl message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.SignedUrl
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignedUrl.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };

                /**
                 * Creates a SignedUrl message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.SignedUrl
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.SignedUrl} SignedUrl
                 */
                SignedUrl.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.SignedUrl)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.SignedUrl();
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };

                /**
                 * Creates a plain object from a SignedUrl message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.SignedUrl
                 * @static
                 * @param {mtechnavi.api.assetinventory.SignedUrl} message SignedUrl
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignedUrl.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };

                /**
                 * Converts this SignedUrl to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.SignedUrl
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignedUrl.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignedUrl;
            })();

            /**
             * ImageFormat enum.
             * @name mtechnavi.api.assetinventory.ImageFormat
             * @enum {number}
             * @property {number} JPEG=0 JPEG value
             * @property {number} PNG=1 PNG value
             */
            assetinventory.ImageFormat = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JPEG"] = 0;
                values[valuesById[1] = "PNG"] = 1;
                return values;
            })();

            assetinventory.ImageProperty = (function() {

                /**
                 * Properties of an ImageProperty.
                 * @memberof mtechnavi.api.assetinventory
                 * @interface IImageProperty
                 * @property {string|null} [name] ImageProperty name
                 * @property {mtechnavi.api.assetinventory.ImageFormat|null} [format] ImageProperty format
                 * @property {mtechnavi.api.assetinventory.ImageProperty.IOption|null} [option] ImageProperty option
                 */

                /**
                 * Constructs a new ImageProperty.
                 * @memberof mtechnavi.api.assetinventory
                 * @classdesc Represents an ImageProperty.
                 * @implements IImageProperty
                 * @constructor
                 * @param {mtechnavi.api.assetinventory.IImageProperty=} [properties] Properties to set
                 */
                function ImageProperty(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImageProperty name.
                 * @member {string} name
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.name = "";

                /**
                 * ImageProperty format.
                 * @member {mtechnavi.api.assetinventory.ImageFormat} format
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.format = 0;

                /**
                 * ImageProperty option.
                 * @member {mtechnavi.api.assetinventory.ImageProperty.IOption|null|undefined} option
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.option = null;

                /**
                 * Verifies an ImageProperty message.
                 * @function verify
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImageProperty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.format != null && message.hasOwnProperty("format"))
                        switch (message.format) {
                        default:
                            return "format: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.option != null && message.hasOwnProperty("option")) {
                        let error = $root.mtechnavi.api.assetinventory.ImageProperty.Option.verify(message.option);
                        if (error)
                            return "option." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ImageProperty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.assetinventory.ImageProperty} ImageProperty
                 */
                ImageProperty.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.assetinventory.ImageProperty)
                        return object;
                    let message = new $root.mtechnavi.api.assetinventory.ImageProperty();
                    if (object.name != null)
                        message.name = String(object.name);
                    switch (object.format) {
                    case "JPEG":
                    case 0:
                        message.format = 0;
                        break;
                    case "PNG":
                    case 1:
                        message.format = 1;
                        break;
                    }
                    if (object.option != null) {
                        if (typeof object.option !== "object")
                            throw TypeError(".mtechnavi.api.assetinventory.ImageProperty.option: object expected");
                        message.option = $root.mtechnavi.api.assetinventory.ImageProperty.Option.fromObject(object.option);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ImageProperty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @static
                 * @param {mtechnavi.api.assetinventory.ImageProperty} message ImageProperty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageProperty.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.format = options.enums === String ? "JPEG" : 0;
                        object.option = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.format != null && message.hasOwnProperty("format"))
                        object.format = options.enums === String ? $root.mtechnavi.api.assetinventory.ImageFormat[message.format] : message.format;
                    if (message.option != null && message.hasOwnProperty("option"))
                        object.option = $root.mtechnavi.api.assetinventory.ImageProperty.Option.toObject(message.option, options);
                    return object;
                };

                /**
                 * Converts this ImageProperty to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.assetinventory.ImageProperty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageProperty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ImageProperty.Option = (function() {

                    /**
                     * Properties of an Option.
                     * @memberof mtechnavi.api.assetinventory.ImageProperty
                     * @interface IOption
                     * @property {number|null} [density] Option density
                     * @property {number|null} [page] Option page
                     * @property {number|null} [numPages] Option numPages
                     * @property {boolean|null} [thumbnail] Option thumbnail
                     * @property {boolean|null} [crop] Option crop
                     * @property {Long|null} [width] Option width
                     * @property {Long|null} [height] Option height
                     * @property {Long|null} [top] Option top
                     * @property {Long|null} [left] Option left
                     * @property {number|null} [quality] Option quality
                     * @property {number|null} [compression] Option compression
                     */

                    /**
                     * Constructs a new Option.
                     * @memberof mtechnavi.api.assetinventory.ImageProperty
                     * @classdesc Represents an Option.
                     * @implements IOption
                     * @constructor
                     * @param {mtechnavi.api.assetinventory.ImageProperty.IOption=} [properties] Properties to set
                     */
                    function Option(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Option density.
                     * @member {number} density
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.density = 0;

                    /**
                     * Option page.
                     * @member {number} page
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.page = 0;

                    /**
                     * Option numPages.
                     * @member {number} numPages
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.numPages = 0;

                    /**
                     * Option thumbnail.
                     * @member {boolean} thumbnail
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.thumbnail = false;

                    /**
                     * Option crop.
                     * @member {boolean} crop
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.crop = false;

                    /**
                     * Option width.
                     * @member {Long} width
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Option height.
                     * @member {Long} height
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Option top.
                     * @member {Long} top
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.top = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Option left.
                     * @member {Long} left
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.left = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Option quality.
                     * @member {number} quality
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.quality = 0;

                    /**
                     * Option compression.
                     * @member {number} compression
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     */
                    Option.prototype.compression = 0;

                    /**
                     * Verifies an Option message.
                     * @function verify
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Option.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.density != null && message.hasOwnProperty("density"))
                            if (!$util.isInteger(message.density))
                                return "density: integer expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page))
                                return "page: integer expected";
                        if (message.numPages != null && message.hasOwnProperty("numPages"))
                            if (!$util.isInteger(message.numPages))
                                return "numPages: integer expected";
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                            if (typeof message.thumbnail !== "boolean")
                                return "thumbnail: boolean expected";
                        if (message.crop != null && message.hasOwnProperty("crop"))
                            if (typeof message.crop !== "boolean")
                                return "crop: boolean expected";
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                                return "width: integer|Long expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                                return "height: integer|Long expected";
                        if (message.top != null && message.hasOwnProperty("top"))
                            if (!$util.isInteger(message.top) && !(message.top && $util.isInteger(message.top.low) && $util.isInteger(message.top.high)))
                                return "top: integer|Long expected";
                        if (message.left != null && message.hasOwnProperty("left"))
                            if (!$util.isInteger(message.left) && !(message.left && $util.isInteger(message.left.low) && $util.isInteger(message.left.high)))
                                return "left: integer|Long expected";
                        if (message.quality != null && message.hasOwnProperty("quality"))
                            if (!$util.isInteger(message.quality))
                                return "quality: integer expected";
                        if (message.compression != null && message.hasOwnProperty("compression"))
                            if (!$util.isInteger(message.compression))
                                return "compression: integer expected";
                        return null;
                    };

                    /**
                     * Creates an Option message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.assetinventory.ImageProperty.Option} Option
                     */
                    Option.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.assetinventory.ImageProperty.Option)
                            return object;
                        let message = new $root.mtechnavi.api.assetinventory.ImageProperty.Option();
                        if (object.density != null)
                            message.density = object.density | 0;
                        if (object.page != null)
                            message.page = object.page | 0;
                        if (object.numPages != null)
                            message.numPages = object.numPages | 0;
                        if (object.thumbnail != null)
                            message.thumbnail = Boolean(object.thumbnail);
                        if (object.crop != null)
                            message.crop = Boolean(object.crop);
                        if (object.width != null)
                            if ($util.Long)
                                (message.width = $util.Long.fromValue(object.width)).unsigned = false;
                            else if (typeof object.width === "string")
                                message.width = parseInt(object.width, 10);
                            else if (typeof object.width === "number")
                                message.width = object.width;
                            else if (typeof object.width === "object")
                                message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber();
                        if (object.height != null)
                            if ($util.Long)
                                (message.height = $util.Long.fromValue(object.height)).unsigned = false;
                            else if (typeof object.height === "string")
                                message.height = parseInt(object.height, 10);
                            else if (typeof object.height === "number")
                                message.height = object.height;
                            else if (typeof object.height === "object")
                                message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber();
                        if (object.top != null)
                            if ($util.Long)
                                (message.top = $util.Long.fromValue(object.top)).unsigned = false;
                            else if (typeof object.top === "string")
                                message.top = parseInt(object.top, 10);
                            else if (typeof object.top === "number")
                                message.top = object.top;
                            else if (typeof object.top === "object")
                                message.top = new $util.LongBits(object.top.low >>> 0, object.top.high >>> 0).toNumber();
                        if (object.left != null)
                            if ($util.Long)
                                (message.left = $util.Long.fromValue(object.left)).unsigned = false;
                            else if (typeof object.left === "string")
                                message.left = parseInt(object.left, 10);
                            else if (typeof object.left === "number")
                                message.left = object.left;
                            else if (typeof object.left === "object")
                                message.left = new $util.LongBits(object.left.low >>> 0, object.left.high >>> 0).toNumber();
                        if (object.quality != null)
                            message.quality = object.quality | 0;
                        if (object.compression != null)
                            message.compression = object.compression | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from an Option message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @static
                     * @param {mtechnavi.api.assetinventory.ImageProperty.Option} message Option
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Option.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.density = 0;
                            object.page = 0;
                            object.numPages = 0;
                            object.thumbnail = false;
                            object.crop = false;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.width = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.height = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.top = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.top = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.left = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.left = options.longs === String ? "0" : 0;
                            object.quality = 0;
                            object.compression = 0;
                        }
                        if (message.density != null && message.hasOwnProperty("density"))
                            object.density = message.density;
                        if (message.page != null && message.hasOwnProperty("page"))
                            object.page = message.page;
                        if (message.numPages != null && message.hasOwnProperty("numPages"))
                            object.numPages = message.numPages;
                        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                            object.thumbnail = message.thumbnail;
                        if (message.crop != null && message.hasOwnProperty("crop"))
                            object.crop = message.crop;
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (typeof message.width === "number")
                                object.width = options.longs === String ? String(message.width) : message.width;
                            else
                                object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber() : message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (typeof message.height === "number")
                                object.height = options.longs === String ? String(message.height) : message.height;
                            else
                                object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber() : message.height;
                        if (message.top != null && message.hasOwnProperty("top"))
                            if (typeof message.top === "number")
                                object.top = options.longs === String ? String(message.top) : message.top;
                            else
                                object.top = options.longs === String ? $util.Long.prototype.toString.call(message.top) : options.longs === Number ? new $util.LongBits(message.top.low >>> 0, message.top.high >>> 0).toNumber() : message.top;
                        if (message.left != null && message.hasOwnProperty("left"))
                            if (typeof message.left === "number")
                                object.left = options.longs === String ? String(message.left) : message.left;
                            else
                                object.left = options.longs === String ? $util.Long.prototype.toString.call(message.left) : options.longs === Number ? new $util.LongBits(message.left.low >>> 0, message.left.high >>> 0).toNumber() : message.left;
                        if (message.quality != null && message.hasOwnProperty("quality"))
                            object.quality = message.quality;
                        if (message.compression != null && message.hasOwnProperty("compression"))
                            object.compression = message.compression;
                        return object;
                    };

                    /**
                     * Converts this Option to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.assetinventory.ImageProperty.Option
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Option.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Option;
                })();

                return ImageProperty;
            })();

            return assetinventory;
        })();

        api.bcp = (function() {

            /**
             * Namespace bcp.
             * @memberof mtechnavi.api
             * @namespace
             */
            const bcp = {};

            bcp.BcpService = (function() {

                /**
                 * Constructs a new BcpService service.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a BcpService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BcpService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BcpService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BcpService;

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#listDisasterSystemNotifications}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef ListDisasterSystemNotificationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse} [response] ListDisasterSystemNotificationsResponse
                 */

                /**
                 * Calls ListDisasterSystemNotifications.
                 * @function listDisasterSystemNotifications
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisasterSystemNotificationsRequest} request ListDisasterSystemNotificationsRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.ListDisasterSystemNotificationsCallback} callback Node-style callback called with the error, if any, and ListDisasterSystemNotificationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.listDisasterSystemNotifications = function listDisasterSystemNotifications(request, callback) {
                    return this.rpcCall(listDisasterSystemNotifications, $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest, $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse, request, callback);
                }, "name", { value: "ListDisasterSystemNotifications" });

                /**
                 * Calls ListDisasterSystemNotifications.
                 * @function listDisasterSystemNotifications
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisasterSystemNotificationsRequest} request ListDisasterSystemNotificationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#createDisasterSystemNotification}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef CreateDisasterSystemNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.DisasterSystemNotification} [response] DisasterSystemNotification
                 */

                /**
                 * Calls CreateDisasterSystemNotification.
                 * @function createDisasterSystemNotification
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICreateDisasterSystemNotificationRequest} request CreateDisasterSystemNotificationRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.CreateDisasterSystemNotificationCallback} callback Node-style callback called with the error, if any, and DisasterSystemNotification
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.createDisasterSystemNotification = function createDisasterSystemNotification(request, callback) {
                    return this.rpcCall(createDisasterSystemNotification, $root.mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest, $root.mtechnavi.api.bcp.DisasterSystemNotification, request, callback);
                }, "name", { value: "CreateDisasterSystemNotification" });

                /**
                 * Calls CreateDisasterSystemNotification.
                 * @function createDisasterSystemNotification
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICreateDisasterSystemNotificationRequest} request CreateDisasterSystemNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.DisasterSystemNotification>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#updateDisasterSystemNotification}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef UpdateDisasterSystemNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.DisasterSystemNotification} [response] DisasterSystemNotification
                 */

                /**
                 * Calls UpdateDisasterSystemNotification.
                 * @function updateDisasterSystemNotification
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSystemNotificationRequest} request UpdateDisasterSystemNotificationRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.UpdateDisasterSystemNotificationCallback} callback Node-style callback called with the error, if any, and DisasterSystemNotification
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.updateDisasterSystemNotification = function updateDisasterSystemNotification(request, callback) {
                    return this.rpcCall(updateDisasterSystemNotification, $root.mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest, $root.mtechnavi.api.bcp.DisasterSystemNotification, request, callback);
                }, "name", { value: "UpdateDisasterSystemNotification" });

                /**
                 * Calls UpdateDisasterSystemNotification.
                 * @function updateDisasterSystemNotification
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSystemNotificationRequest} request UpdateDisasterSystemNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.DisasterSystemNotification>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#listDisasters}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef ListDisastersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.ListDisastersResponse} [response] ListDisastersResponse
                 */

                /**
                 * Calls ListDisasters.
                 * @function listDisasters
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisastersRequest} request ListDisastersRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.ListDisastersCallback} callback Node-style callback called with the error, if any, and ListDisastersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.listDisasters = function listDisasters(request, callback) {
                    return this.rpcCall(listDisasters, $root.mtechnavi.api.bcp.ListDisastersRequest, $root.mtechnavi.api.bcp.ListDisastersResponse, request, callback);
                }, "name", { value: "ListDisasters" });

                /**
                 * Calls ListDisasters.
                 * @function listDisasters
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisastersRequest} request ListDisastersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.ListDisastersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#createDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef CreateDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.DisasterSurvey} [response] DisasterSurvey
                 */

                /**
                 * Calls CreateDisasterSurvey.
                 * @function createDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICreateDisasterSurveyRequest} request CreateDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.CreateDisasterSurveyCallback} callback Node-style callback called with the error, if any, and DisasterSurvey
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.createDisasterSurvey = function createDisasterSurvey(request, callback) {
                    return this.rpcCall(createDisasterSurvey, $root.mtechnavi.api.bcp.CreateDisasterSurveyRequest, $root.mtechnavi.api.bcp.DisasterSurvey, request, callback);
                }, "name", { value: "CreateDisasterSurvey" });

                /**
                 * Calls CreateDisasterSurvey.
                 * @function createDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICreateDisasterSurveyRequest} request CreateDisasterSurveyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.DisasterSurvey>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#updateDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef UpdateDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.DisasterSurvey} [response] DisasterSurvey
                 */

                /**
                 * Calls UpdateDisasterSurvey.
                 * @function updateDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSurveyRequest} request UpdateDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.UpdateDisasterSurveyCallback} callback Node-style callback called with the error, if any, and DisasterSurvey
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.updateDisasterSurvey = function updateDisasterSurvey(request, callback) {
                    return this.rpcCall(updateDisasterSurvey, $root.mtechnavi.api.bcp.UpdateDisasterSurveyRequest, $root.mtechnavi.api.bcp.DisasterSurvey, request, callback);
                }, "name", { value: "UpdateDisasterSurvey" });

                /**
                 * Calls UpdateDisasterSurvey.
                 * @function updateDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSurveyRequest} request UpdateDisasterSurveyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.DisasterSurvey>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#deleteDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef DeleteDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteDisasterSurvey.
                 * @function deleteDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IDeleteDisasterSurveyRequest} request DeleteDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.DeleteDisasterSurveyCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.deleteDisasterSurvey = function deleteDisasterSurvey(request, callback) {
                    return this.rpcCall(deleteDisasterSurvey, $root.mtechnavi.api.bcp.DeleteDisasterSurveyRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteDisasterSurvey" });

                /**
                 * Calls DeleteDisasterSurvey.
                 * @function deleteDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IDeleteDisasterSurveyRequest} request DeleteDisasterSurveyRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#listDisasterSurveys}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef ListDisasterSurveysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.bcp.ListDisasterSurveysResponse} [response] ListDisasterSurveysResponse
                 */

                /**
                 * Calls ListDisasterSurveys.
                 * @function listDisasterSurveys
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisasterSurveysRequest} request ListDisasterSurveysRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.ListDisasterSurveysCallback} callback Node-style callback called with the error, if any, and ListDisasterSurveysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.listDisasterSurveys = function listDisasterSurveys(request, callback) {
                    return this.rpcCall(listDisasterSurveys, $root.mtechnavi.api.bcp.ListDisasterSurveysRequest, $root.mtechnavi.api.bcp.ListDisasterSurveysResponse, request, callback);
                }, "name", { value: "ListDisasterSurveys" });

                /**
                 * Calls ListDisasterSurveys.
                 * @function listDisasterSurveys
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IListDisasterSurveysRequest} request ListDisasterSurveysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.bcp.ListDisasterSurveysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#completeDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef CompleteDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls CompleteDisasterSurvey.
                 * @function completeDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICompleteDisasterSurveyRequest} request CompleteDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.CompleteDisasterSurveyCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.completeDisasterSurvey = function completeDisasterSurvey(request, callback) {
                    return this.rpcCall(completeDisasterSurvey, $root.mtechnavi.api.bcp.CompleteDisasterSurveyRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "CompleteDisasterSurvey" });

                /**
                 * Calls CompleteDisasterSurvey.
                 * @function completeDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.ICompleteDisasterSurveyRequest} request CompleteDisasterSurveyRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#uncompleteDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef UncompleteDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls UncompleteDisasterSurvey.
                 * @function uncompleteDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest} request UncompleteDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.UncompleteDisasterSurveyCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.uncompleteDisasterSurvey = function uncompleteDisasterSurvey(request, callback) {
                    return this.rpcCall(uncompleteDisasterSurvey, $root.mtechnavi.api.bcp.UncompleteDisasterSurveyRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "UncompleteDisasterSurvey" });

                /**
                 * Calls UncompleteDisasterSurvey.
                 * @function uncompleteDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest} request UncompleteDisasterSurveyRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.bcp.BcpService#addSuveyBaseRequestIdInDisasterSurvey}.
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @typedef AddSuveyBaseRequestIdInDisasterSurveyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls AddSuveyBaseRequestIdInDisasterSurvey.
                 * @function addSuveyBaseRequestIdInDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest} request AddSuveyBaseRequestIdInDisasterSurveyRequest message or plain object
                 * @param {mtechnavi.api.bcp.BcpService.AddSuveyBaseRequestIdInDisasterSurveyCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BcpService.prototype.addSuveyBaseRequestIdInDisasterSurvey = function addSuveyBaseRequestIdInDisasterSurvey(request, callback) {
                    return this.rpcCall(addSuveyBaseRequestIdInDisasterSurvey, $root.mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "AddSuveyBaseRequestIdInDisasterSurvey" });

                /**
                 * Calls AddSuveyBaseRequestIdInDisasterSurvey.
                 * @function addSuveyBaseRequestIdInDisasterSurvey
                 * @memberof mtechnavi.api.bcp.BcpService
                 * @instance
                 * @param {mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest} request AddSuveyBaseRequestIdInDisasterSurveyRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return BcpService;
            })();

            bcp.ListDisasterSystemNotificationsRequest = (function() {

                /**
                 * Properties of a ListDisasterSystemNotificationsRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisasterSystemNotificationsRequest
                 * @property {string|null} [pageToken] ListDisasterSystemNotificationsRequest pageToken
                 */

                /**
                 * Constructs a new ListDisasterSystemNotificationsRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisasterSystemNotificationsRequest.
                 * @implements IListDisasterSystemNotificationsRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisasterSystemNotificationsRequest=} [properties] Properties to set
                 */
                function ListDisasterSystemNotificationsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisasterSystemNotificationsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest
                 * @instance
                 */
                ListDisasterSystemNotificationsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListDisasterSystemNotificationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisasterSystemNotificationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListDisasterSystemNotificationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest} ListDisasterSystemNotificationsRequest
                 */
                ListDisasterSystemNotificationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisasterSystemNotificationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest} message ListDisasterSystemNotificationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisasterSystemNotificationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListDisasterSystemNotificationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisasterSystemNotificationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisasterSystemNotificationsRequest;
            })();

            bcp.ListDisasterSystemNotificationsResponse = (function() {

                /**
                 * Properties of a ListDisasterSystemNotificationsResponse.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisasterSystemNotificationsResponse
                 * @property {Long|null} [total] ListDisasterSystemNotificationsResponse total
                 * @property {Array.<mtechnavi.api.bcp.IDisasterSystemNotification>|null} [items] ListDisasterSystemNotificationsResponse items
                 * @property {string|null} [pageToken] ListDisasterSystemNotificationsResponse pageToken
                 */

                /**
                 * Constructs a new ListDisasterSystemNotificationsResponse.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisasterSystemNotificationsResponse.
                 * @implements IListDisasterSystemNotificationsResponse
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisasterSystemNotificationsResponse=} [properties] Properties to set
                 */
                function ListDisasterSystemNotificationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisasterSystemNotificationsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @instance
                 */
                ListDisasterSystemNotificationsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListDisasterSystemNotificationsResponse items.
                 * @member {Array.<mtechnavi.api.bcp.IDisasterSystemNotification>} items
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @instance
                 */
                ListDisasterSystemNotificationsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListDisasterSystemNotificationsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @instance
                 */
                ListDisasterSystemNotificationsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListDisasterSystemNotificationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisasterSystemNotificationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.bcp.DisasterSystemNotification.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListDisasterSystemNotificationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse} ListDisasterSystemNotificationsResponse
                 */
                ListDisasterSystemNotificationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.bcp.DisasterSystemNotification.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisasterSystemNotificationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse} message ListDisasterSystemNotificationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisasterSystemNotificationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.bcp.DisasterSystemNotification.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListDisasterSystemNotificationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisasterSystemNotificationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisasterSystemNotificationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisasterSystemNotificationsResponse;
            })();

            bcp.CreateDisasterSystemNotificationRequest = (function() {

                /**
                 * Properties of a CreateDisasterSystemNotificationRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface ICreateDisasterSystemNotificationRequest
                 * @property {mtechnavi.api.bcp.IDisasterSystemNotification|null} [disasterSystemNotification] CreateDisasterSystemNotificationRequest disasterSystemNotification
                 */

                /**
                 * Constructs a new CreateDisasterSystemNotificationRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a CreateDisasterSystemNotificationRequest.
                 * @implements ICreateDisasterSystemNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.ICreateDisasterSystemNotificationRequest=} [properties] Properties to set
                 */
                function CreateDisasterSystemNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateDisasterSystemNotificationRequest disasterSystemNotification.
                 * @member {mtechnavi.api.bcp.IDisasterSystemNotification|null|undefined} disasterSystemNotification
                 * @memberof mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest
                 * @instance
                 */
                CreateDisasterSystemNotificationRequest.prototype.disasterSystemNotification = null;

                /**
                 * Verifies a CreateDisasterSystemNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDisasterSystemNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSystemNotification != null && message.hasOwnProperty("disasterSystemNotification")) {
                        let error = $root.mtechnavi.api.bcp.DisasterSystemNotification.verify(message.disasterSystemNotification);
                        if (error)
                            return "disasterSystemNotification." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateDisasterSystemNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest} CreateDisasterSystemNotificationRequest
                 */
                CreateDisasterSystemNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest();
                    if (object.disasterSystemNotification != null) {
                        if (typeof object.disasterSystemNotification !== "object")
                            throw TypeError(".mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest.disasterSystemNotification: object expected");
                        message.disasterSystemNotification = $root.mtechnavi.api.bcp.DisasterSystemNotification.fromObject(object.disasterSystemNotification);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateDisasterSystemNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest
                 * @static
                 * @param {mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest} message CreateDisasterSystemNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDisasterSystemNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSystemNotification = null;
                    if (message.disasterSystemNotification != null && message.hasOwnProperty("disasterSystemNotification"))
                        object.disasterSystemNotification = $root.mtechnavi.api.bcp.DisasterSystemNotification.toObject(message.disasterSystemNotification, options);
                    return object;
                };

                /**
                 * Converts this CreateDisasterSystemNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.CreateDisasterSystemNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDisasterSystemNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateDisasterSystemNotificationRequest;
            })();

            bcp.UpdateDisasterSystemNotificationRequest = (function() {

                /**
                 * Properties of an UpdateDisasterSystemNotificationRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IUpdateDisasterSystemNotificationRequest
                 * @property {mtechnavi.api.bcp.IDisasterSystemNotification|null} [disasterSystemNotification] UpdateDisasterSystemNotificationRequest disasterSystemNotification
                 */

                /**
                 * Constructs a new UpdateDisasterSystemNotificationRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents an UpdateDisasterSystemNotificationRequest.
                 * @implements IUpdateDisasterSystemNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSystemNotificationRequest=} [properties] Properties to set
                 */
                function UpdateDisasterSystemNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateDisasterSystemNotificationRequest disasterSystemNotification.
                 * @member {mtechnavi.api.bcp.IDisasterSystemNotification|null|undefined} disasterSystemNotification
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest
                 * @instance
                 */
                UpdateDisasterSystemNotificationRequest.prototype.disasterSystemNotification = null;

                /**
                 * Verifies an UpdateDisasterSystemNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDisasterSystemNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSystemNotification != null && message.hasOwnProperty("disasterSystemNotification")) {
                        let error = $root.mtechnavi.api.bcp.DisasterSystemNotification.verify(message.disasterSystemNotification);
                        if (error)
                            return "disasterSystemNotification." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateDisasterSystemNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest} UpdateDisasterSystemNotificationRequest
                 */
                UpdateDisasterSystemNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest();
                    if (object.disasterSystemNotification != null) {
                        if (typeof object.disasterSystemNotification !== "object")
                            throw TypeError(".mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest.disasterSystemNotification: object expected");
                        message.disasterSystemNotification = $root.mtechnavi.api.bcp.DisasterSystemNotification.fromObject(object.disasterSystemNotification);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateDisasterSystemNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest
                 * @static
                 * @param {mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest} message UpdateDisasterSystemNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDisasterSystemNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSystemNotification = null;
                    if (message.disasterSystemNotification != null && message.hasOwnProperty("disasterSystemNotification"))
                        object.disasterSystemNotification = $root.mtechnavi.api.bcp.DisasterSystemNotification.toObject(message.disasterSystemNotification, options);
                    return object;
                };

                /**
                 * Converts this UpdateDisasterSystemNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSystemNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDisasterSystemNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateDisasterSystemNotificationRequest;
            })();

            bcp.ListDisastersRequest = (function() {

                /**
                 * Properties of a ListDisastersRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisastersRequest
                 * @property {string|null} [pageToken] ListDisastersRequest pageToken
                 * @property {Array.<string>|null} [disasterIds] ListDisastersRequest disasterIds
                 */

                /**
                 * Constructs a new ListDisastersRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisastersRequest.
                 * @implements IListDisastersRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisastersRequest=} [properties] Properties to set
                 */
                function ListDisastersRequest(properties) {
                    this.disasterIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisastersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @instance
                 */
                ListDisastersRequest.prototype.pageToken = "";

                /**
                 * ListDisastersRequest disasterIds.
                 * @member {Array.<string>} disasterIds
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @instance
                 */
                ListDisastersRequest.prototype.disasterIds = $util.emptyArray;

                /**
                 * Verifies a ListDisastersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisastersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.disasterIds != null && message.hasOwnProperty("disasterIds")) {
                        if (!Array.isArray(message.disasterIds))
                            return "disasterIds: array expected";
                        for (let i = 0; i < message.disasterIds.length; ++i)
                            if (!$util.isString(message.disasterIds[i]))
                                return "disasterIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListDisastersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisastersRequest} ListDisastersRequest
                 */
                ListDisastersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisastersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisastersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.disasterIds) {
                        if (!Array.isArray(object.disasterIds))
                            throw TypeError(".mtechnavi.api.bcp.ListDisastersRequest.disasterIds: array expected");
                        message.disasterIds = [];
                        for (let i = 0; i < object.disasterIds.length; ++i)
                            message.disasterIds[i] = String(object.disasterIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisastersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisastersRequest} message ListDisastersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisastersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.disasterIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.disasterIds && message.disasterIds.length) {
                        object.disasterIds = [];
                        for (let j = 0; j < message.disasterIds.length; ++j)
                            object.disasterIds[j] = message.disasterIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListDisastersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisastersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisastersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisastersRequest;
            })();

            bcp.ListDisastersResponse = (function() {

                /**
                 * Properties of a ListDisastersResponse.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisastersResponse
                 * @property {Long|null} [total] ListDisastersResponse total
                 * @property {Array.<mtechnavi.api.bcp.IDisaster>|null} [items] ListDisastersResponse items
                 * @property {string|null} [pageToken] ListDisastersResponse pageToken
                 */

                /**
                 * Constructs a new ListDisastersResponse.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisastersResponse.
                 * @implements IListDisastersResponse
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisastersResponse=} [properties] Properties to set
                 */
                function ListDisastersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisastersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @instance
                 */
                ListDisastersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListDisastersResponse items.
                 * @member {Array.<mtechnavi.api.bcp.IDisaster>} items
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @instance
                 */
                ListDisastersResponse.prototype.items = $util.emptyArray;

                /**
                 * ListDisastersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @instance
                 */
                ListDisastersResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListDisastersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisastersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.bcp.Disaster.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListDisastersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisastersResponse} ListDisastersResponse
                 */
                ListDisastersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisastersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisastersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.bcp.ListDisastersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.ListDisastersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.bcp.Disaster.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisastersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisastersResponse} message ListDisastersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisastersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.bcp.Disaster.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListDisastersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisastersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisastersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisastersResponse;
            })();

            bcp.CreateDisasterSurveyRequest = (function() {

                /**
                 * Properties of a CreateDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface ICreateDisasterSurveyRequest
                 * @property {mtechnavi.api.bcp.IDisasterSurvey|null} [disasterSurvey] CreateDisasterSurveyRequest disasterSurvey
                 */

                /**
                 * Constructs a new CreateDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a CreateDisasterSurveyRequest.
                 * @implements ICreateDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.ICreateDisasterSurveyRequest=} [properties] Properties to set
                 */
                function CreateDisasterSurveyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateDisasterSurveyRequest disasterSurvey.
                 * @member {mtechnavi.api.bcp.IDisasterSurvey|null|undefined} disasterSurvey
                 * @memberof mtechnavi.api.bcp.CreateDisasterSurveyRequest
                 * @instance
                 */
                CreateDisasterSurveyRequest.prototype.disasterSurvey = null;

                /**
                 * Verifies a CreateDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.CreateDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurvey != null && message.hasOwnProperty("disasterSurvey")) {
                        let error = $root.mtechnavi.api.bcp.DisasterSurvey.verify(message.disasterSurvey);
                        if (error)
                            return "disasterSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.CreateDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.CreateDisasterSurveyRequest} CreateDisasterSurveyRequest
                 */
                CreateDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.CreateDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.CreateDisasterSurveyRequest();
                    if (object.disasterSurvey != null) {
                        if (typeof object.disasterSurvey !== "object")
                            throw TypeError(".mtechnavi.api.bcp.CreateDisasterSurveyRequest.disasterSurvey: object expected");
                        message.disasterSurvey = $root.mtechnavi.api.bcp.DisasterSurvey.fromObject(object.disasterSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.CreateDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.CreateDisasterSurveyRequest} message CreateDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSurvey = null;
                    if (message.disasterSurvey != null && message.hasOwnProperty("disasterSurvey"))
                        object.disasterSurvey = $root.mtechnavi.api.bcp.DisasterSurvey.toObject(message.disasterSurvey, options);
                    return object;
                };

                /**
                 * Converts this CreateDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.CreateDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateDisasterSurveyRequest;
            })();

            bcp.UpdateDisasterSurveyRequest = (function() {

                /**
                 * Properties of an UpdateDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IUpdateDisasterSurveyRequest
                 * @property {mtechnavi.api.bcp.IDisasterSurvey|null} [disasterSurvey] UpdateDisasterSurveyRequest disasterSurvey
                 */

                /**
                 * Constructs a new UpdateDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents an UpdateDisasterSurveyRequest.
                 * @implements IUpdateDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IUpdateDisasterSurveyRequest=} [properties] Properties to set
                 */
                function UpdateDisasterSurveyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateDisasterSurveyRequest disasterSurvey.
                 * @member {mtechnavi.api.bcp.IDisasterSurvey|null|undefined} disasterSurvey
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSurveyRequest
                 * @instance
                 */
                UpdateDisasterSurveyRequest.prototype.disasterSurvey = null;

                /**
                 * Verifies an UpdateDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurvey != null && message.hasOwnProperty("disasterSurvey")) {
                        let error = $root.mtechnavi.api.bcp.DisasterSurvey.verify(message.disasterSurvey);
                        if (error)
                            return "disasterSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.UpdateDisasterSurveyRequest} UpdateDisasterSurveyRequest
                 */
                UpdateDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.UpdateDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.UpdateDisasterSurveyRequest();
                    if (object.disasterSurvey != null) {
                        if (typeof object.disasterSurvey !== "object")
                            throw TypeError(".mtechnavi.api.bcp.UpdateDisasterSurveyRequest.disasterSurvey: object expected");
                        message.disasterSurvey = $root.mtechnavi.api.bcp.DisasterSurvey.fromObject(object.disasterSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.UpdateDisasterSurveyRequest} message UpdateDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSurvey = null;
                    if (message.disasterSurvey != null && message.hasOwnProperty("disasterSurvey"))
                        object.disasterSurvey = $root.mtechnavi.api.bcp.DisasterSurvey.toObject(message.disasterSurvey, options);
                    return object;
                };

                /**
                 * Converts this UpdateDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.UpdateDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateDisasterSurveyRequest;
            })();

            bcp.DeleteDisasterSurveyRequest = (function() {

                /**
                 * Properties of a DeleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IDeleteDisasterSurveyRequest
                 * @property {string|null} [disasterSurveyId] DeleteDisasterSurveyRequest disasterSurveyId
                 */

                /**
                 * Constructs a new DeleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a DeleteDisasterSurveyRequest.
                 * @implements IDeleteDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IDeleteDisasterSurveyRequest=} [properties] Properties to set
                 */
                function DeleteDisasterSurveyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteDisasterSurveyRequest disasterSurveyId.
                 * @member {string} disasterSurveyId
                 * @memberof mtechnavi.api.bcp.DeleteDisasterSurveyRequest
                 * @instance
                 */
                DeleteDisasterSurveyRequest.prototype.disasterSurveyId = "";

                /**
                 * Verifies a DeleteDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.DeleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        if (!$util.isString(message.disasterSurveyId))
                            return "disasterSurveyId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.DeleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.DeleteDisasterSurveyRequest} DeleteDisasterSurveyRequest
                 */
                DeleteDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.DeleteDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.DeleteDisasterSurveyRequest();
                    if (object.disasterSurveyId != null)
                        message.disasterSurveyId = String(object.disasterSurveyId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.DeleteDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.DeleteDisasterSurveyRequest} message DeleteDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSurveyId = "";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        object.disasterSurveyId = message.disasterSurveyId;
                    return object;
                };

                /**
                 * Converts this DeleteDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.DeleteDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteDisasterSurveyRequest;
            })();

            bcp.ListDisasterSurveysRequest = (function() {

                /**
                 * Properties of a ListDisasterSurveysRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisasterSurveysRequest
                 * @property {string|null} [pageToken] ListDisasterSurveysRequest pageToken
                 * @property {Array.<string>|null} [disasterSurveyIds] ListDisasterSurveysRequest disasterSurveyIds
                 */

                /**
                 * Constructs a new ListDisasterSurveysRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisasterSurveysRequest.
                 * @implements IListDisasterSurveysRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisasterSurveysRequest=} [properties] Properties to set
                 */
                function ListDisasterSurveysRequest(properties) {
                    this.disasterSurveyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisasterSurveysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @instance
                 */
                ListDisasterSurveysRequest.prototype.pageToken = "";

                /**
                 * ListDisasterSurveysRequest disasterSurveyIds.
                 * @member {Array.<string>} disasterSurveyIds
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @instance
                 */
                ListDisasterSurveysRequest.prototype.disasterSurveyIds = $util.emptyArray;

                /**
                 * Verifies a ListDisasterSurveysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisasterSurveysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.disasterSurveyIds != null && message.hasOwnProperty("disasterSurveyIds")) {
                        if (!Array.isArray(message.disasterSurveyIds))
                            return "disasterSurveyIds: array expected";
                        for (let i = 0; i < message.disasterSurveyIds.length; ++i)
                            if (!$util.isString(message.disasterSurveyIds[i]))
                                return "disasterSurveyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListDisasterSurveysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisasterSurveysRequest} ListDisasterSurveysRequest
                 */
                ListDisasterSurveysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisasterSurveysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisasterSurveysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.disasterSurveyIds) {
                        if (!Array.isArray(object.disasterSurveyIds))
                            throw TypeError(".mtechnavi.api.bcp.ListDisasterSurveysRequest.disasterSurveyIds: array expected");
                        message.disasterSurveyIds = [];
                        for (let i = 0; i < object.disasterSurveyIds.length; ++i)
                            message.disasterSurveyIds[i] = String(object.disasterSurveyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisasterSurveysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisasterSurveysRequest} message ListDisasterSurveysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisasterSurveysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.disasterSurveyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.disasterSurveyIds && message.disasterSurveyIds.length) {
                        object.disasterSurveyIds = [];
                        for (let j = 0; j < message.disasterSurveyIds.length; ++j)
                            object.disasterSurveyIds[j] = message.disasterSurveyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListDisasterSurveysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisasterSurveysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisasterSurveysRequest;
            })();

            bcp.ListDisasterSurveysResponse = (function() {

                /**
                 * Properties of a ListDisasterSurveysResponse.
                 * @memberof mtechnavi.api.bcp
                 * @interface IListDisasterSurveysResponse
                 * @property {Long|null} [total] ListDisasterSurveysResponse total
                 * @property {Array.<mtechnavi.api.bcp.IDisasterSurvey>|null} [items] ListDisasterSurveysResponse items
                 * @property {string|null} [pageToken] ListDisasterSurveysResponse pageToken
                 */

                /**
                 * Constructs a new ListDisasterSurveysResponse.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a ListDisasterSurveysResponse.
                 * @implements IListDisasterSurveysResponse
                 * @constructor
                 * @param {mtechnavi.api.bcp.IListDisasterSurveysResponse=} [properties] Properties to set
                 */
                function ListDisasterSurveysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListDisasterSurveysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @instance
                 */
                ListDisasterSurveysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListDisasterSurveysResponse items.
                 * @member {Array.<mtechnavi.api.bcp.IDisasterSurvey>} items
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @instance
                 */
                ListDisasterSurveysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListDisasterSurveysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @instance
                 */
                ListDisasterSurveysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListDisasterSurveysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListDisasterSurveysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.bcp.DisasterSurvey.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListDisasterSurveysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.ListDisasterSurveysResponse} ListDisasterSurveysResponse
                 */
                ListDisasterSurveysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.ListDisasterSurveysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.ListDisasterSurveysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.bcp.ListDisasterSurveysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.ListDisasterSurveysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.bcp.DisasterSurvey.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListDisasterSurveysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @static
                 * @param {mtechnavi.api.bcp.ListDisasterSurveysResponse} message ListDisasterSurveysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListDisasterSurveysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.bcp.DisasterSurvey.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListDisasterSurveysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.ListDisasterSurveysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListDisasterSurveysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListDisasterSurveysResponse;
            })();

            bcp.CompleteDisasterSurveyRequest = (function() {

                /**
                 * Properties of a CompleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface ICompleteDisasterSurveyRequest
                 * @property {string|null} [disasterSurveyId] CompleteDisasterSurveyRequest disasterSurveyId
                 */

                /**
                 * Constructs a new CompleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a CompleteDisasterSurveyRequest.
                 * @implements ICompleteDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.ICompleteDisasterSurveyRequest=} [properties] Properties to set
                 */
                function CompleteDisasterSurveyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDisasterSurveyRequest disasterSurveyId.
                 * @member {string} disasterSurveyId
                 * @memberof mtechnavi.api.bcp.CompleteDisasterSurveyRequest
                 * @instance
                 */
                CompleteDisasterSurveyRequest.prototype.disasterSurveyId = "";

                /**
                 * Verifies a CompleteDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.CompleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        if (!$util.isString(message.disasterSurveyId))
                            return "disasterSurveyId: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.CompleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.CompleteDisasterSurveyRequest} CompleteDisasterSurveyRequest
                 */
                CompleteDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.CompleteDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.CompleteDisasterSurveyRequest();
                    if (object.disasterSurveyId != null)
                        message.disasterSurveyId = String(object.disasterSurveyId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.CompleteDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.CompleteDisasterSurveyRequest} message CompleteDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSurveyId = "";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        object.disasterSurveyId = message.disasterSurveyId;
                    return object;
                };

                /**
                 * Converts this CompleteDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.CompleteDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDisasterSurveyRequest;
            })();

            bcp.UncompleteDisasterSurveyRequest = (function() {

                /**
                 * Properties of an UncompleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IUncompleteDisasterSurveyRequest
                 * @property {string|null} [disasterSurveyId] UncompleteDisasterSurveyRequest disasterSurveyId
                 */

                /**
                 * Constructs a new UncompleteDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents an UncompleteDisasterSurveyRequest.
                 * @implements IUncompleteDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IUncompleteDisasterSurveyRequest=} [properties] Properties to set
                 */
                function UncompleteDisasterSurveyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompleteDisasterSurveyRequest disasterSurveyId.
                 * @member {string} disasterSurveyId
                 * @memberof mtechnavi.api.bcp.UncompleteDisasterSurveyRequest
                 * @instance
                 */
                UncompleteDisasterSurveyRequest.prototype.disasterSurveyId = "";

                /**
                 * Verifies an UncompleteDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.UncompleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        if (!$util.isString(message.disasterSurveyId))
                            return "disasterSurveyId: string expected";
                    return null;
                };

                /**
                 * Creates an UncompleteDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.UncompleteDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.UncompleteDisasterSurveyRequest} UncompleteDisasterSurveyRequest
                 */
                UncompleteDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.UncompleteDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.UncompleteDisasterSurveyRequest();
                    if (object.disasterSurveyId != null)
                        message.disasterSurveyId = String(object.disasterSurveyId);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompleteDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.UncompleteDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.UncompleteDisasterSurveyRequest} message UncompleteDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.disasterSurveyId = "";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        object.disasterSurveyId = message.disasterSurveyId;
                    return object;
                };

                /**
                 * Converts this UncompleteDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.UncompleteDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteDisasterSurveyRequest;
            })();

            bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest = (function() {

                /**
                 * Properties of an AddSuveyBaseRequestIdInDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @interface IAddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @property {string|null} [disasterSurveyId] AddSuveyBaseRequestIdInDisasterSurveyRequest disasterSurveyId
                 * @property {Array.<string>|null} [suveyBaseRequestIds] AddSuveyBaseRequestIdInDisasterSurveyRequest suveyBaseRequestIds
                 */

                /**
                 * Constructs a new AddSuveyBaseRequestIdInDisasterSurveyRequest.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents an AddSuveyBaseRequestIdInDisasterSurveyRequest.
                 * @implements IAddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.bcp.IAddSuveyBaseRequestIdInDisasterSurveyRequest=} [properties] Properties to set
                 */
                function AddSuveyBaseRequestIdInDisasterSurveyRequest(properties) {
                    this.suveyBaseRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddSuveyBaseRequestIdInDisasterSurveyRequest disasterSurveyId.
                 * @member {string} disasterSurveyId
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @instance
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.prototype.disasterSurveyId = "";

                /**
                 * AddSuveyBaseRequestIdInDisasterSurveyRequest suveyBaseRequestIds.
                 * @member {Array.<string>} suveyBaseRequestIds
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @instance
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.prototype.suveyBaseRequestIds = $util.emptyArray;

                /**
                 * Verifies an AddSuveyBaseRequestIdInDisasterSurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        if (!$util.isString(message.disasterSurveyId))
                            return "disasterSurveyId: string expected";
                    if (message.suveyBaseRequestIds != null && message.hasOwnProperty("suveyBaseRequestIds")) {
                        if (!Array.isArray(message.suveyBaseRequestIds))
                            return "suveyBaseRequestIds: array expected";
                        for (let i = 0; i < message.suveyBaseRequestIds.length; ++i)
                            if (!$util.isString(message.suveyBaseRequestIds[i]))
                                return "suveyBaseRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates an AddSuveyBaseRequestIdInDisasterSurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest} AddSuveyBaseRequestIdInDisasterSurveyRequest
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest();
                    if (object.disasterSurveyId != null)
                        message.disasterSurveyId = String(object.disasterSurveyId);
                    if (object.suveyBaseRequestIds) {
                        if (!Array.isArray(object.suveyBaseRequestIds))
                            throw TypeError(".mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest.suveyBaseRequestIds: array expected");
                        message.suveyBaseRequestIds = [];
                        for (let i = 0; i < object.suveyBaseRequestIds.length; ++i)
                            message.suveyBaseRequestIds[i] = String(object.suveyBaseRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AddSuveyBaseRequestIdInDisasterSurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @static
                 * @param {mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest} message AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.suveyBaseRequestIds = [];
                    if (options.defaults)
                        object.disasterSurveyId = "";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        object.disasterSurveyId = message.disasterSurveyId;
                    if (message.suveyBaseRequestIds && message.suveyBaseRequestIds.length) {
                        object.suveyBaseRequestIds = [];
                        for (let j = 0; j < message.suveyBaseRequestIds.length; ++j)
                            object.suveyBaseRequestIds[j] = message.suveyBaseRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this AddSuveyBaseRequestIdInDisasterSurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.AddSuveyBaseRequestIdInDisasterSurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddSuveyBaseRequestIdInDisasterSurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AddSuveyBaseRequestIdInDisasterSurveyRequest;
            })();

            bcp.DisasterSystemNotification = (function() {

                /**
                 * Properties of a DisasterSystemNotification.
                 * @memberof mtechnavi.api.bcp
                 * @interface IDisasterSystemNotification
                 * @property {string|null} [disasterSystemNotificationId] DisasterSystemNotification disasterSystemNotificationId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] DisasterSystemNotification systemNotificationUsers
                 * @property {Long|null} [createdAt] DisasterSystemNotification createdAt
                 * @property {Long|null} [updatedAt] DisasterSystemNotification updatedAt
                 * @property {Long|null} [deletedAt] DisasterSystemNotification deletedAt
                 */

                /**
                 * Constructs a new DisasterSystemNotification.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a DisasterSystemNotification.
                 * @implements IDisasterSystemNotification
                 * @constructor
                 * @param {mtechnavi.api.bcp.IDisasterSystemNotification=} [properties] Properties to set
                 */
                function DisasterSystemNotification(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisasterSystemNotification disasterSystemNotificationId.
                 * @member {string} disasterSystemNotificationId
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 */
                DisasterSystemNotification.prototype.disasterSystemNotificationId = "";

                /**
                 * DisasterSystemNotification systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 */
                DisasterSystemNotification.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * DisasterSystemNotification createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 */
                DisasterSystemNotification.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSystemNotification updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 */
                DisasterSystemNotification.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSystemNotification deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 */
                DisasterSystemNotification.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a DisasterSystemNotification message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisasterSystemNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSystemNotificationId != null && message.hasOwnProperty("disasterSystemNotificationId"))
                        if (!$util.isString(message.disasterSystemNotificationId))
                            return "disasterSystemNotificationId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a DisasterSystemNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.DisasterSystemNotification} DisasterSystemNotification
                 */
                DisasterSystemNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.DisasterSystemNotification)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.DisasterSystemNotification();
                    if (object.disasterSystemNotificationId != null)
                        message.disasterSystemNotificationId = String(object.disasterSystemNotificationId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.bcp.DisasterSystemNotification.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.DisasterSystemNotification.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a DisasterSystemNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @static
                 * @param {mtechnavi.api.bcp.DisasterSystemNotification} message DisasterSystemNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisasterSystemNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.disasterSystemNotificationId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.disasterSystemNotificationId != null && message.hasOwnProperty("disasterSystemNotificationId"))
                        object.disasterSystemNotificationId = message.disasterSystemNotificationId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this DisasterSystemNotification to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.DisasterSystemNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisasterSystemNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisasterSystemNotification;
            })();

            bcp.Disaster = (function() {

                /**
                 * Properties of a Disaster.
                 * @memberof mtechnavi.api.bcp
                 * @interface IDisaster
                 * @property {string|null} [disasterId] Disaster disasterId
                 * @property {boolean|null} [withSurvey] Disaster withSurvey
                 * @property {Long|null} [occurredAt] Disaster occurredAt
                 * @property {sharelib.INameOption|null} [category] Disaster category
                 * @property {string|null} [content] Disaster content
                 * @property {Array.<sharelib.INameOption>|null} [regions] Disaster regions
                 * @property {string|null} [externalSourceOrigin] Disaster externalSourceOrigin
                 * @property {string|null} [externalSourceOriginDetail] Disaster externalSourceOriginDetail
                 * @property {Long|null} [reportedAt] Disaster reportedAt
                 * @property {Long|null} [createdAt] Disaster createdAt
                 * @property {Long|null} [updatedAt] Disaster updatedAt
                 * @property {Long|null} [deletedAt] Disaster deletedAt
                 */

                /**
                 * Constructs a new Disaster.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a Disaster.
                 * @implements IDisaster
                 * @constructor
                 * @param {mtechnavi.api.bcp.IDisaster=} [properties] Properties to set
                 */
                function Disaster(properties) {
                    this.regions = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Disaster disasterId.
                 * @member {string} disasterId
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.disasterId = "";

                /**
                 * Disaster withSurvey.
                 * @member {boolean} withSurvey
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.withSurvey = false;

                /**
                 * Disaster occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Disaster category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.category = null;

                /**
                 * Disaster content.
                 * @member {string} content
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.content = "";

                /**
                 * Disaster regions.
                 * @member {Array.<sharelib.INameOption>} regions
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.regions = $util.emptyArray;

                /**
                 * Disaster externalSourceOrigin.
                 * @member {string} externalSourceOrigin
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.externalSourceOrigin = "";

                /**
                 * Disaster externalSourceOriginDetail.
                 * @member {string} externalSourceOriginDetail
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.externalSourceOriginDetail = "";

                /**
                 * Disaster reportedAt.
                 * @member {Long} reportedAt
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.reportedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Disaster createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Disaster updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Disaster deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 */
                Disaster.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Disaster message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Disaster.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterId != null && message.hasOwnProperty("disasterId"))
                        if (!$util.isString(message.disasterId))
                            return "disasterId: string expected";
                    if (message.withSurvey != null && message.hasOwnProperty("withSurvey"))
                        if (typeof message.withSurvey !== "boolean")
                            return "withSurvey: boolean expected";
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.regions != null && message.hasOwnProperty("regions")) {
                        if (!Array.isArray(message.regions))
                            return "regions: array expected";
                        for (let i = 0; i < message.regions.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.regions[i]);
                            if (error)
                                return "regions." + error;
                        }
                    }
                    if (message.externalSourceOrigin != null && message.hasOwnProperty("externalSourceOrigin"))
                        if (!$util.isString(message.externalSourceOrigin))
                            return "externalSourceOrigin: string expected";
                    if (message.externalSourceOriginDetail != null && message.hasOwnProperty("externalSourceOriginDetail"))
                        if (!$util.isString(message.externalSourceOriginDetail))
                            return "externalSourceOriginDetail: string expected";
                    if (message.reportedAt != null && message.hasOwnProperty("reportedAt"))
                        if (!$util.isInteger(message.reportedAt) && !(message.reportedAt && $util.isInteger(message.reportedAt.low) && $util.isInteger(message.reportedAt.high)))
                            return "reportedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Disaster message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.Disaster} Disaster
                 */
                Disaster.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.Disaster)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.Disaster();
                    if (object.disasterId != null)
                        message.disasterId = String(object.disasterId);
                    if (object.withSurvey != null)
                        message.withSurvey = Boolean(object.withSurvey);
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.bcp.Disaster.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.regions) {
                        if (!Array.isArray(object.regions))
                            throw TypeError(".mtechnavi.api.bcp.Disaster.regions: array expected");
                        message.regions = [];
                        for (let i = 0; i < object.regions.length; ++i) {
                            if (typeof object.regions[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.Disaster.regions: object expected");
                            message.regions[i] = $root.sharelib.NameOption.fromObject(object.regions[i]);
                        }
                    }
                    if (object.externalSourceOrigin != null)
                        message.externalSourceOrigin = String(object.externalSourceOrigin);
                    if (object.externalSourceOriginDetail != null)
                        message.externalSourceOriginDetail = String(object.externalSourceOriginDetail);
                    if (object.reportedAt != null)
                        if ($util.Long)
                            (message.reportedAt = $util.Long.fromValue(object.reportedAt)).unsigned = false;
                        else if (typeof object.reportedAt === "string")
                            message.reportedAt = parseInt(object.reportedAt, 10);
                        else if (typeof object.reportedAt === "number")
                            message.reportedAt = object.reportedAt;
                        else if (typeof object.reportedAt === "object")
                            message.reportedAt = new $util.LongBits(object.reportedAt.low >>> 0, object.reportedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Disaster message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @static
                 * @param {mtechnavi.api.bcp.Disaster} message Disaster
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Disaster.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.regions = [];
                    if (options.defaults) {
                        object.disasterId = "";
                        object.withSurvey = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        object.category = null;
                        object.content = "";
                        object.externalSourceOrigin = "";
                        object.externalSourceOriginDetail = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.reportedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reportedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.disasterId != null && message.hasOwnProperty("disasterId"))
                        object.disasterId = message.disasterId;
                    if (message.withSurvey != null && message.hasOwnProperty("withSurvey"))
                        object.withSurvey = message.withSurvey;
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.regions && message.regions.length) {
                        object.regions = [];
                        for (let j = 0; j < message.regions.length; ++j)
                            object.regions[j] = $root.sharelib.NameOption.toObject(message.regions[j], options);
                    }
                    if (message.externalSourceOrigin != null && message.hasOwnProperty("externalSourceOrigin"))
                        object.externalSourceOrigin = message.externalSourceOrigin;
                    if (message.externalSourceOriginDetail != null && message.hasOwnProperty("externalSourceOriginDetail"))
                        object.externalSourceOriginDetail = message.externalSourceOriginDetail;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.reportedAt != null && message.hasOwnProperty("reportedAt"))
                        if (typeof message.reportedAt === "number")
                            object.reportedAt = options.longs === String ? String(message.reportedAt) : message.reportedAt;
                        else
                            object.reportedAt = options.longs === String ? $util.Long.prototype.toString.call(message.reportedAt) : options.longs === Number ? new $util.LongBits(message.reportedAt.low >>> 0, message.reportedAt.high >>> 0).toNumber() : message.reportedAt;
                    return object;
                };

                /**
                 * Converts this Disaster to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.Disaster
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Disaster.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Disaster;
            })();

            bcp.DisasterSurvey = (function() {

                /**
                 * Properties of a DisasterSurvey.
                 * @memberof mtechnavi.api.bcp
                 * @interface IDisasterSurvey
                 * @property {string|null} [disasterSurveyId] DisasterSurvey disasterSurveyId
                 * @property {string|null} [disasterId] DisasterSurvey disasterId
                 * @property {Long|null} [disasterSurveyAutoName] DisasterSurvey disasterSurveyAutoName
                 * @property {sharelib.INameOption|null} [status] DisasterSurvey status
                 * @property {sharelib.INameOption|null} [category] DisasterSurvey category
                 * @property {Long|null} [occurredAt] DisasterSurvey occurredAt
                 * @property {string|null} [disasterDisplayName] DisasterSurvey disasterDisplayName
                 * @property {Array.<sharelib.INameOption>|null} [regions] DisasterSurvey regions
                 * @property {string|null} [externalSourceCategory] DisasterSurvey externalSourceCategory
                 * @property {string|null} [externalSourceName] DisasterSurvey externalSourceName
                 * @property {string|null} [remarks] DisasterSurvey remarks
                 * @property {string|null} [surveyRemarks] DisasterSurvey surveyRemarks
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] DisasterSurvey updatedProperties
                 * @property {Array.<string>|null} [suveyBaseRequestIds] DisasterSurvey suveyBaseRequestIds
                 * @property {boolean|null} [completed] DisasterSurvey completed
                 * @property {Long|null} [createdAt] DisasterSurvey createdAt
                 * @property {Long|null} [updatedAt] DisasterSurvey updatedAt
                 * @property {Long|null} [deletedAt] DisasterSurvey deletedAt
                 */

                /**
                 * Constructs a new DisasterSurvey.
                 * @memberof mtechnavi.api.bcp
                 * @classdesc Represents a DisasterSurvey.
                 * @implements IDisasterSurvey
                 * @constructor
                 * @param {mtechnavi.api.bcp.IDisasterSurvey=} [properties] Properties to set
                 */
                function DisasterSurvey(properties) {
                    this.regions = [];
                    this.suveyBaseRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisasterSurvey disasterSurveyId.
                 * @member {string} disasterSurveyId
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.disasterSurveyId = "";

                /**
                 * DisasterSurvey disasterId.
                 * @member {string} disasterId
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.disasterId = "";

                /**
                 * DisasterSurvey disasterSurveyAutoName.
                 * @member {Long} disasterSurveyAutoName
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.disasterSurveyAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSurvey status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.status = null;

                /**
                 * DisasterSurvey category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.category = null;

                /**
                 * DisasterSurvey occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSurvey disasterDisplayName.
                 * @member {string} disasterDisplayName
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.disasterDisplayName = "";

                /**
                 * DisasterSurvey regions.
                 * @member {Array.<sharelib.INameOption>} regions
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.regions = $util.emptyArray;

                /**
                 * DisasterSurvey externalSourceCategory.
                 * @member {string} externalSourceCategory
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.externalSourceCategory = "";

                /**
                 * DisasterSurvey externalSourceName.
                 * @member {string} externalSourceName
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.externalSourceName = "";

                /**
                 * DisasterSurvey remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.remarks = "";

                /**
                 * DisasterSurvey surveyRemarks.
                 * @member {string} surveyRemarks
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.surveyRemarks = "";

                /**
                 * DisasterSurvey updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.updatedProperties = null;

                /**
                 * DisasterSurvey suveyBaseRequestIds.
                 * @member {Array.<string>} suveyBaseRequestIds
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.suveyBaseRequestIds = $util.emptyArray;

                /**
                 * DisasterSurvey completed.
                 * @member {boolean} completed
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.completed = false;

                /**
                 * DisasterSurvey createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSurvey updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * DisasterSurvey deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 */
                DisasterSurvey.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a DisasterSurvey message.
                 * @function verify
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisasterSurvey.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        if (!$util.isString(message.disasterSurveyId))
                            return "disasterSurveyId: string expected";
                    if (message.disasterId != null && message.hasOwnProperty("disasterId"))
                        if (!$util.isString(message.disasterId))
                            return "disasterId: string expected";
                    if (message.disasterSurveyAutoName != null && message.hasOwnProperty("disasterSurveyAutoName"))
                        if (!$util.isInteger(message.disasterSurveyAutoName) && !(message.disasterSurveyAutoName && $util.isInteger(message.disasterSurveyAutoName.low) && $util.isInteger(message.disasterSurveyAutoName.high)))
                            return "disasterSurveyAutoName: integer|Long expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.disasterDisplayName != null && message.hasOwnProperty("disasterDisplayName"))
                        if (!$util.isString(message.disasterDisplayName))
                            return "disasterDisplayName: string expected";
                    if (message.regions != null && message.hasOwnProperty("regions")) {
                        if (!Array.isArray(message.regions))
                            return "regions: array expected";
                        for (let i = 0; i < message.regions.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.regions[i]);
                            if (error)
                                return "regions." + error;
                        }
                    }
                    if (message.externalSourceCategory != null && message.hasOwnProperty("externalSourceCategory"))
                        if (!$util.isString(message.externalSourceCategory))
                            return "externalSourceCategory: string expected";
                    if (message.externalSourceName != null && message.hasOwnProperty("externalSourceName"))
                        if (!$util.isString(message.externalSourceName))
                            return "externalSourceName: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.surveyRemarks != null && message.hasOwnProperty("surveyRemarks"))
                        if (!$util.isString(message.surveyRemarks))
                            return "surveyRemarks: string expected";
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.suveyBaseRequestIds != null && message.hasOwnProperty("suveyBaseRequestIds")) {
                        if (!Array.isArray(message.suveyBaseRequestIds))
                            return "suveyBaseRequestIds: array expected";
                        for (let i = 0; i < message.suveyBaseRequestIds.length; ++i)
                            if (!$util.isString(message.suveyBaseRequestIds[i]))
                                return "suveyBaseRequestIds: string[] expected";
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        if (typeof message.completed !== "boolean")
                            return "completed: boolean expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a DisasterSurvey message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.bcp.DisasterSurvey} DisasterSurvey
                 */
                DisasterSurvey.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.bcp.DisasterSurvey)
                        return object;
                    let message = new $root.mtechnavi.api.bcp.DisasterSurvey();
                    if (object.disasterSurveyId != null)
                        message.disasterSurveyId = String(object.disasterSurveyId);
                    if (object.disasterId != null)
                        message.disasterId = String(object.disasterId);
                    if (object.disasterSurveyAutoName != null)
                        if ($util.Long)
                            (message.disasterSurveyAutoName = $util.Long.fromValue(object.disasterSurveyAutoName)).unsigned = false;
                        else if (typeof object.disasterSurveyAutoName === "string")
                            message.disasterSurveyAutoName = parseInt(object.disasterSurveyAutoName, 10);
                        else if (typeof object.disasterSurveyAutoName === "number")
                            message.disasterSurveyAutoName = object.disasterSurveyAutoName;
                        else if (typeof object.disasterSurveyAutoName === "object")
                            message.disasterSurveyAutoName = new $util.LongBits(object.disasterSurveyAutoName.low >>> 0, object.disasterSurveyAutoName.high >>> 0).toNumber();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.disasterDisplayName != null)
                        message.disasterDisplayName = String(object.disasterDisplayName);
                    if (object.regions) {
                        if (!Array.isArray(object.regions))
                            throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.regions: array expected");
                        message.regions = [];
                        for (let i = 0; i < object.regions.length; ++i) {
                            if (typeof object.regions[i] !== "object")
                                throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.regions: object expected");
                            message.regions[i] = $root.sharelib.NameOption.fromObject(object.regions[i]);
                        }
                    }
                    if (object.externalSourceCategory != null)
                        message.externalSourceCategory = String(object.externalSourceCategory);
                    if (object.externalSourceName != null)
                        message.externalSourceName = String(object.externalSourceName);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.surveyRemarks != null)
                        message.surveyRemarks = String(object.surveyRemarks);
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.suveyBaseRequestIds) {
                        if (!Array.isArray(object.suveyBaseRequestIds))
                            throw TypeError(".mtechnavi.api.bcp.DisasterSurvey.suveyBaseRequestIds: array expected");
                        message.suveyBaseRequestIds = [];
                        for (let i = 0; i < object.suveyBaseRequestIds.length; ++i)
                            message.suveyBaseRequestIds[i] = String(object.suveyBaseRequestIds[i]);
                    }
                    if (object.completed != null)
                        message.completed = Boolean(object.completed);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a DisasterSurvey message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @static
                 * @param {mtechnavi.api.bcp.DisasterSurvey} message DisasterSurvey
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisasterSurvey.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.regions = [];
                        object.suveyBaseRequestIds = [];
                    }
                    if (options.defaults) {
                        object.disasterSurveyId = "";
                        object.disasterId = "";
                        object.status = null;
                        object.category = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        object.disasterDisplayName = "";
                        object.externalSourceCategory = "";
                        object.externalSourceName = "";
                        object.remarks = "";
                        object.surveyRemarks = "";
                        object.updatedProperties = null;
                        object.completed = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.disasterSurveyAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.disasterSurveyAutoName = options.longs === String ? "0" : 0;
                    }
                    if (message.disasterSurveyId != null && message.hasOwnProperty("disasterSurveyId"))
                        object.disasterSurveyId = message.disasterSurveyId;
                    if (message.disasterId != null && message.hasOwnProperty("disasterId"))
                        object.disasterId = message.disasterId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.disasterDisplayName != null && message.hasOwnProperty("disasterDisplayName"))
                        object.disasterDisplayName = message.disasterDisplayName;
                    if (message.regions && message.regions.length) {
                        object.regions = [];
                        for (let j = 0; j < message.regions.length; ++j)
                            object.regions[j] = $root.sharelib.NameOption.toObject(message.regions[j], options);
                    }
                    if (message.externalSourceCategory != null && message.hasOwnProperty("externalSourceCategory"))
                        object.externalSourceCategory = message.externalSourceCategory;
                    if (message.externalSourceName != null && message.hasOwnProperty("externalSourceName"))
                        object.externalSourceName = message.externalSourceName;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.surveyRemarks != null && message.hasOwnProperty("surveyRemarks"))
                        object.surveyRemarks = message.surveyRemarks;
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.suveyBaseRequestIds && message.suveyBaseRequestIds.length) {
                        object.suveyBaseRequestIds = [];
                        for (let j = 0; j < message.suveyBaseRequestIds.length; ++j)
                            object.suveyBaseRequestIds[j] = message.suveyBaseRequestIds[j];
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = message.completed;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.disasterSurveyAutoName != null && message.hasOwnProperty("disasterSurveyAutoName"))
                        if (typeof message.disasterSurveyAutoName === "number")
                            object.disasterSurveyAutoName = options.longs === String ? String(message.disasterSurveyAutoName) : message.disasterSurveyAutoName;
                        else
                            object.disasterSurveyAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.disasterSurveyAutoName) : options.longs === Number ? new $util.LongBits(message.disasterSurveyAutoName.low >>> 0, message.disasterSurveyAutoName.high >>> 0).toNumber() : message.disasterSurveyAutoName;
                    return object;
                };

                /**
                 * Converts this DisasterSurvey to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.bcp.DisasterSurvey
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisasterSurvey.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisasterSurvey;
            })();

            return bcp;
        })();

        api.blueprint = (function() {

            /**
             * Namespace blueprint.
             * @memberof mtechnavi.api
             * @namespace
             */
            const blueprint = {};

            blueprint.TransactionUnitService = (function() {

                /**
                 * Constructs a new TransactionUnitService service.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a TransactionUnitService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function TransactionUnitService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (TransactionUnitService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TransactionUnitService;

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#createTransactionUnit}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef CreateTransactionUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.TransactionUnit} [response] TransactionUnit
                 */

                /**
                 * Calls CreateTransactionUnit.
                 * @function createTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateTransactionUnitRequest} request CreateTransactionUnitRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.CreateTransactionUnitCallback} callback Node-style callback called with the error, if any, and TransactionUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.createTransactionUnit = function createTransactionUnit(request, callback) {
                    return this.rpcCall(createTransactionUnit, $root.mtechnavi.api.blueprint.CreateTransactionUnitRequest, $root.mtechnavi.api.blueprint.TransactionUnit, request, callback);
                }, "name", { value: "CreateTransactionUnit" });

                /**
                 * Calls CreateTransactionUnit.
                 * @function createTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateTransactionUnitRequest} request CreateTransactionUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.TransactionUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#listTransactionUnits}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef ListTransactionUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitsResponse} [response] ListTransactionUnitsResponse
                 */

                /**
                 * Calls ListTransactionUnits.
                 * @function listTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitsRequest} request ListTransactionUnitsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.ListTransactionUnitsCallback} callback Node-style callback called with the error, if any, and ListTransactionUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.listTransactionUnits = function listTransactionUnits(request, callback) {
                    return this.rpcCall(listTransactionUnits, $root.mtechnavi.api.blueprint.ListTransactionUnitsRequest, $root.mtechnavi.api.blueprint.ListTransactionUnitsResponse, request, callback);
                }, "name", { value: "ListTransactionUnits" });

                /**
                 * Calls ListTransactionUnits.
                 * @function listTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitsRequest} request ListTransactionUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListTransactionUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#getTransactionUnit}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef GetTransactionUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.TransactionUnit} [response] TransactionUnit
                 */

                /**
                 * Calls GetTransactionUnit.
                 * @function getTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IGetTransactionUnitRequest} request GetTransactionUnitRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.GetTransactionUnitCallback} callback Node-style callback called with the error, if any, and TransactionUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.getTransactionUnit = function getTransactionUnit(request, callback) {
                    return this.rpcCall(getTransactionUnit, $root.mtechnavi.api.blueprint.GetTransactionUnitRequest, $root.mtechnavi.api.blueprint.TransactionUnit, request, callback);
                }, "name", { value: "GetTransactionUnit" });

                /**
                 * Calls GetTransactionUnit.
                 * @function getTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IGetTransactionUnitRequest} request GetTransactionUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.TransactionUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#updateTransactionUnit}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef UpdateTransactionUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.TransactionUnit} [response] TransactionUnit
                 */

                /**
                 * Calls UpdateTransactionUnit.
                 * @function updateTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateTransactionUnitRequest} request UpdateTransactionUnitRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.UpdateTransactionUnitCallback} callback Node-style callback called with the error, if any, and TransactionUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.updateTransactionUnit = function updateTransactionUnit(request, callback) {
                    return this.rpcCall(updateTransactionUnit, $root.mtechnavi.api.blueprint.UpdateTransactionUnitRequest, $root.mtechnavi.api.blueprint.TransactionUnit, request, callback);
                }, "name", { value: "UpdateTransactionUnit" });

                /**
                 * Calls UpdateTransactionUnit.
                 * @function updateTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateTransactionUnitRequest} request UpdateTransactionUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.TransactionUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#deleteTransactionUnit}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef DeleteTransactionUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteTransactionUnit.
                 * @function deleteTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteTransactionUnitRequest} request DeleteTransactionUnitRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.DeleteTransactionUnitCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.deleteTransactionUnit = function deleteTransactionUnit(request, callback) {
                    return this.rpcCall(deleteTransactionUnit, $root.mtechnavi.api.blueprint.DeleteTransactionUnitRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteTransactionUnit" });

                /**
                 * Calls DeleteTransactionUnit.
                 * @function deleteTransactionUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteTransactionUnitRequest} request DeleteTransactionUnitRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#importTransactionUnits}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef ImportTransactionUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ImportTransactionUnitsResponse} [response] ImportTransactionUnitsResponse
                 */

                /**
                 * Calls ImportTransactionUnits.
                 * @function importTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IImportTransactionUnitsRequest} request ImportTransactionUnitsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.ImportTransactionUnitsCallback} callback Node-style callback called with the error, if any, and ImportTransactionUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.importTransactionUnits = function importTransactionUnits(request, callback) {
                    return this.rpcCall(importTransactionUnits, $root.mtechnavi.api.blueprint.ImportTransactionUnitsRequest, $root.mtechnavi.api.blueprint.ImportTransactionUnitsResponse, request, callback);
                }, "name", { value: "ImportTransactionUnits" });

                /**
                 * Calls ImportTransactionUnits.
                 * @function importTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IImportTransactionUnitsRequest} request ImportTransactionUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ImportTransactionUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#exportTransactionUnits}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef ExportTransactionUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExportTransactionUnitsResponse} [response] ExportTransactionUnitsResponse
                 */

                /**
                 * Calls ExportTransactionUnits.
                 * @function exportTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportTransactionUnitsRequest} request ExportTransactionUnitsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.ExportTransactionUnitsCallback} callback Node-style callback called with the error, if any, and ExportTransactionUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.exportTransactionUnits = function exportTransactionUnits(request, callback) {
                    return this.rpcCall(exportTransactionUnits, $root.mtechnavi.api.blueprint.ExportTransactionUnitsRequest, $root.mtechnavi.api.blueprint.ExportTransactionUnitsResponse, request, callback);
                }, "name", { value: "ExportTransactionUnits" });

                /**
                 * Calls ExportTransactionUnits.
                 * @function exportTransactionUnits
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportTransactionUnitsRequest} request ExportTransactionUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExportTransactionUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.TransactionUnitService#listTransactionUnitFormats}.
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @typedef ListTransactionUnitFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse} [response] ListTransactionUnitFormatsResponse
                 */

                /**
                 * Calls ListTransactionUnitFormats.
                 * @function listTransactionUnitFormats
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitFormatsRequest} request ListTransactionUnitFormatsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.TransactionUnitService.ListTransactionUnitFormatsCallback} callback Node-style callback called with the error, if any, and ListTransactionUnitFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TransactionUnitService.prototype.listTransactionUnitFormats = function listTransactionUnitFormats(request, callback) {
                    return this.rpcCall(listTransactionUnitFormats, $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest, $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse, request, callback);
                }, "name", { value: "ListTransactionUnitFormats" });

                /**
                 * Calls ListTransactionUnitFormats.
                 * @function listTransactionUnitFormats
                 * @memberof mtechnavi.api.blueprint.TransactionUnitService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitFormatsRequest} request ListTransactionUnitFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse>} Promise
                 * @variation 2
                 */

                return TransactionUnitService;
            })();

            blueprint.BlueprintService = (function() {

                /**
                 * Constructs a new BlueprintService service.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BlueprintService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BlueprintService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BlueprintService;

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#searchBlueprints}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef SearchBlueprintsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.SearchBlueprintsResponse} [response] SearchBlueprintsResponse
                 */

                /**
                 * Calls SearchBlueprints.
                 * @function searchBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ISearchBlueprintsRequest} request SearchBlueprintsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.SearchBlueprintsCallback} callback Node-style callback called with the error, if any, and SearchBlueprintsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.searchBlueprints = function searchBlueprints(request, callback) {
                    return this.rpcCall(searchBlueprints, $root.mtechnavi.api.blueprint.SearchBlueprintsRequest, $root.mtechnavi.api.blueprint.SearchBlueprintsResponse, request, callback);
                }, "name", { value: "SearchBlueprints" });

                /**
                 * Calls SearchBlueprints.
                 * @function searchBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ISearchBlueprintsRequest} request SearchBlueprintsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.SearchBlueprintsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#extractBlueprintImageSegment}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ExtractBlueprintImageSegmentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse} [response] ExtractBlueprintImageSegmentResponse
                 */

                /**
                 * Calls ExtractBlueprintImageSegment.
                 * @function extractBlueprintImageSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExtractBlueprintImageSegmentRequest} request ExtractBlueprintImageSegmentRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ExtractBlueprintImageSegmentCallback} callback Node-style callback called with the error, if any, and ExtractBlueprintImageSegmentResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.extractBlueprintImageSegment = function extractBlueprintImageSegment(request, callback) {
                    return this.rpcCall(extractBlueprintImageSegment, $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest, $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse, request, callback);
                }, "name", { value: "ExtractBlueprintImageSegment" });

                /**
                 * Calls ExtractBlueprintImageSegment.
                 * @function extractBlueprintImageSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExtractBlueprintImageSegmentRequest} request ExtractBlueprintImageSegmentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintDisplayAttributes}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintDisplayAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse} [response] ListBlueprintDisplayAttributesResponse
                 */

                /**
                 * Calls ListBlueprintDisplayAttributes.
                 * @function listBlueprintDisplayAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintDisplayAttributesRequest} request ListBlueprintDisplayAttributesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintDisplayAttributesCallback} callback Node-style callback called with the error, if any, and ListBlueprintDisplayAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintDisplayAttributes = function listBlueprintDisplayAttributes(request, callback) {
                    return this.rpcCall(listBlueprintDisplayAttributes, $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest, $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse, request, callback);
                }, "name", { value: "ListBlueprintDisplayAttributes" });

                /**
                 * Calls ListBlueprintDisplayAttributes.
                 * @function listBlueprintDisplayAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintDisplayAttributesRequest} request ListBlueprintDisplayAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#createBlueprintAndBlueprintRevision}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef CreateBlueprintAndBlueprintRevisionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintContent} [response] BlueprintContent
                 */

                /**
                 * Calls CreateBlueprintAndBlueprintRevision.
                 * @function createBlueprintAndBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest} request CreateBlueprintAndBlueprintRevisionRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.CreateBlueprintAndBlueprintRevisionCallback} callback Node-style callback called with the error, if any, and BlueprintContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.createBlueprintAndBlueprintRevision = function createBlueprintAndBlueprintRevision(request, callback) {
                    return this.rpcCall(createBlueprintAndBlueprintRevision, $root.mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest, $root.mtechnavi.api.blueprint.BlueprintContent, request, callback);
                }, "name", { value: "CreateBlueprintAndBlueprintRevision" });

                /**
                 * Calls CreateBlueprintAndBlueprintRevision.
                 * @function createBlueprintAndBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest} request CreateBlueprintAndBlueprintRevisionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprints}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintsResponse} [response] ListBlueprintsResponse
                 */

                /**
                 * Calls ListBlueprints.
                 * @function listBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintsRequest} request ListBlueprintsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintsCallback} callback Node-style callback called with the error, if any, and ListBlueprintsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprints = function listBlueprints(request, callback) {
                    return this.rpcCall(listBlueprints, $root.mtechnavi.api.blueprint.ListBlueprintsRequest, $root.mtechnavi.api.blueprint.ListBlueprintsResponse, request, callback);
                }, "name", { value: "ListBlueprints" });

                /**
                 * Calls ListBlueprints.
                 * @function listBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintsRequest} request ListBlueprintsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#updateBlueprint}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef UpdateBlueprintCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintContent} [response] BlueprintContent
                 */

                /**
                 * Calls UpdateBlueprint.
                 * @function updateBlueprint
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRequest} request UpdateBlueprintRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.UpdateBlueprintCallback} callback Node-style callback called with the error, if any, and BlueprintContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.updateBlueprint = function updateBlueprint(request, callback) {
                    return this.rpcCall(updateBlueprint, $root.mtechnavi.api.blueprint.UpdateBlueprintRequest, $root.mtechnavi.api.blueprint.BlueprintContent, request, callback);
                }, "name", { value: "UpdateBlueprint" });

                /**
                 * Calls UpdateBlueprint.
                 * @function updateBlueprint
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRequest} request UpdateBlueprintRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#deleteBlueprintWithRelatedItems}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef DeleteBlueprintWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBlueprintWithRelatedItems.
                 * @function deleteBlueprintWithRelatedItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintWithRelatedItemsRequest} request DeleteBlueprintWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.DeleteBlueprintWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.deleteBlueprintWithRelatedItems = function deleteBlueprintWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteBlueprintWithRelatedItems, $root.mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBlueprintWithRelatedItems" });

                /**
                 * Calls DeleteBlueprintWithRelatedItems.
                 * @function deleteBlueprintWithRelatedItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintWithRelatedItemsRequest} request DeleteBlueprintWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#getBlueprintContent}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef GetBlueprintContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintContent} [response] BlueprintContent
                 */

                /**
                 * Calls GetBlueprintContent.
                 * @function getBlueprintContent
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IGetBlueprintContentRequest} request GetBlueprintContentRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.GetBlueprintContentCallback} callback Node-style callback called with the error, if any, and BlueprintContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.getBlueprintContent = function getBlueprintContent(request, callback) {
                    return this.rpcCall(getBlueprintContent, $root.mtechnavi.api.blueprint.GetBlueprintContentRequest, $root.mtechnavi.api.blueprint.BlueprintContent, request, callback);
                }, "name", { value: "GetBlueprintContent" });

                /**
                 * Calls GetBlueprintContent.
                 * @function getBlueprintContent
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IGetBlueprintContentRequest} request GetBlueprintContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#createBlueprintRevision}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef CreateBlueprintRevisionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintContent} [response] BlueprintContent
                 */

                /**
                 * Calls CreateBlueprintRevision.
                 * @function createBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintRevisionRequest} request CreateBlueprintRevisionRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.CreateBlueprintRevisionCallback} callback Node-style callback called with the error, if any, and BlueprintContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.createBlueprintRevision = function createBlueprintRevision(request, callback) {
                    return this.rpcCall(createBlueprintRevision, $root.mtechnavi.api.blueprint.CreateBlueprintRevisionRequest, $root.mtechnavi.api.blueprint.BlueprintContent, request, callback);
                }, "name", { value: "CreateBlueprintRevision" });

                /**
                 * Calls CreateBlueprintRevision.
                 * @function createBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintRevisionRequest} request CreateBlueprintRevisionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintRevisions}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintRevisionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionsResponse} [response] ListBlueprintRevisionsResponse
                 */

                /**
                 * Calls ListBlueprintRevisions.
                 * @function listBlueprintRevisions
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionsRequest} request ListBlueprintRevisionsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintRevisionsCallback} callback Node-style callback called with the error, if any, and ListBlueprintRevisionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintRevisions = function listBlueprintRevisions(request, callback) {
                    return this.rpcCall(listBlueprintRevisions, $root.mtechnavi.api.blueprint.ListBlueprintRevisionsRequest, $root.mtechnavi.api.blueprint.ListBlueprintRevisionsResponse, request, callback);
                }, "name", { value: "ListBlueprintRevisions" });

                /**
                 * Calls ListBlueprintRevisions.
                 * @function listBlueprintRevisions
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionsRequest} request ListBlueprintRevisionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintRevisionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#updateBlueprintRevision}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef UpdateBlueprintRevisionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintContent} [response] BlueprintContent
                 */

                /**
                 * Calls UpdateBlueprintRevision.
                 * @function updateBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRevisionRequest} request UpdateBlueprintRevisionRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.UpdateBlueprintRevisionCallback} callback Node-style callback called with the error, if any, and BlueprintContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.updateBlueprintRevision = function updateBlueprintRevision(request, callback) {
                    return this.rpcCall(updateBlueprintRevision, $root.mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest, $root.mtechnavi.api.blueprint.BlueprintContent, request, callback);
                }, "name", { value: "UpdateBlueprintRevision" });

                /**
                 * Calls UpdateBlueprintRevision.
                 * @function updateBlueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRevisionRequest} request UpdateBlueprintRevisionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#deleteBlueprintRevisionWithRelatedItems}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef DeleteBlueprintRevisionWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBlueprintRevisionWithRelatedItems.
                 * @function deleteBlueprintRevisionWithRelatedItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintRevisionWithRelatedItemsRequest} request DeleteBlueprintRevisionWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.DeleteBlueprintRevisionWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.deleteBlueprintRevisionWithRelatedItems = function deleteBlueprintRevisionWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteBlueprintRevisionWithRelatedItems, $root.mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBlueprintRevisionWithRelatedItems" });

                /**
                 * Calls DeleteBlueprintRevisionWithRelatedItems.
                 * @function deleteBlueprintRevisionWithRelatedItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintRevisionWithRelatedItemsRequest} request DeleteBlueprintRevisionWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintRevisionContents}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintRevisionContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse} [response] ListBlueprintRevisionContentsResponse
                 */

                /**
                 * Calls ListBlueprintRevisionContents.
                 * @function listBlueprintRevisionContents
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionContentsRequest} request ListBlueprintRevisionContentsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintRevisionContentsCallback} callback Node-style callback called with the error, if any, and ListBlueprintRevisionContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintRevisionContents = function listBlueprintRevisionContents(request, callback) {
                    return this.rpcCall(listBlueprintRevisionContents, $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest, $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse, request, callback);
                }, "name", { value: "ListBlueprintRevisionContents" });

                /**
                 * Calls ListBlueprintRevisionContents.
                 * @function listBlueprintRevisionContents
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionContentsRequest} request ListBlueprintRevisionContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#createBlueprintSegment}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef CreateBlueprintSegmentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintSegment} [response] BlueprintSegment
                 */

                /**
                 * Calls CreateBlueprintSegment.
                 * @function createBlueprintSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintSegmentRequest} request CreateBlueprintSegmentRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.CreateBlueprintSegmentCallback} callback Node-style callback called with the error, if any, and BlueprintSegment
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.createBlueprintSegment = function createBlueprintSegment(request, callback) {
                    return this.rpcCall(createBlueprintSegment, $root.mtechnavi.api.blueprint.CreateBlueprintSegmentRequest, $root.mtechnavi.api.blueprint.BlueprintSegment, request, callback);
                }, "name", { value: "CreateBlueprintSegment" });

                /**
                 * Calls CreateBlueprintSegment.
                 * @function createBlueprintSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintSegmentRequest} request CreateBlueprintSegmentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintSegment>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintSegments}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintSegmentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintSegmentsResponse} [response] ListBlueprintSegmentsResponse
                 */

                /**
                 * Calls ListBlueprintSegments.
                 * @function listBlueprintSegments
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintSegmentsRequest} request ListBlueprintSegmentsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintSegmentsCallback} callback Node-style callback called with the error, if any, and ListBlueprintSegmentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintSegments = function listBlueprintSegments(request, callback) {
                    return this.rpcCall(listBlueprintSegments, $root.mtechnavi.api.blueprint.ListBlueprintSegmentsRequest, $root.mtechnavi.api.blueprint.ListBlueprintSegmentsResponse, request, callback);
                }, "name", { value: "ListBlueprintSegments" });

                /**
                 * Calls ListBlueprintSegments.
                 * @function listBlueprintSegments
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintSegmentsRequest} request ListBlueprintSegmentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintSegmentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#updateBlueprintSegment}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef UpdateBlueprintSegmentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintSegment} [response] BlueprintSegment
                 */

                /**
                 * Calls UpdateBlueprintSegment.
                 * @function updateBlueprintSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintSegmentRequest} request UpdateBlueprintSegmentRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.UpdateBlueprintSegmentCallback} callback Node-style callback called with the error, if any, and BlueprintSegment
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.updateBlueprintSegment = function updateBlueprintSegment(request, callback) {
                    return this.rpcCall(updateBlueprintSegment, $root.mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest, $root.mtechnavi.api.blueprint.BlueprintSegment, request, callback);
                }, "name", { value: "UpdateBlueprintSegment" });

                /**
                 * Calls UpdateBlueprintSegment.
                 * @function updateBlueprintSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintSegmentRequest} request UpdateBlueprintSegmentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintSegment>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#createBlueprintAttribute}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef CreateBlueprintAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintAttribute} [response] BlueprintAttribute
                 */

                /**
                 * Calls CreateBlueprintAttribute.
                 * @function createBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAttributeRequest} request CreateBlueprintAttributeRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.CreateBlueprintAttributeCallback} callback Node-style callback called with the error, if any, and BlueprintAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.createBlueprintAttribute = function createBlueprintAttribute(request, callback) {
                    return this.rpcCall(createBlueprintAttribute, $root.mtechnavi.api.blueprint.CreateBlueprintAttributeRequest, $root.mtechnavi.api.blueprint.BlueprintAttribute, request, callback);
                }, "name", { value: "CreateBlueprintAttribute" });

                /**
                 * Calls CreateBlueprintAttribute.
                 * @function createBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAttributeRequest} request CreateBlueprintAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintAttributes}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributesResponse} [response] ListBlueprintAttributesResponse
                 */

                /**
                 * Calls ListBlueprintAttributes.
                 * @function listBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributesRequest} request ListBlueprintAttributesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintAttributesCallback} callback Node-style callback called with the error, if any, and ListBlueprintAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintAttributes = function listBlueprintAttributes(request, callback) {
                    return this.rpcCall(listBlueprintAttributes, $root.mtechnavi.api.blueprint.ListBlueprintAttributesRequest, $root.mtechnavi.api.blueprint.ListBlueprintAttributesResponse, request, callback);
                }, "name", { value: "ListBlueprintAttributes" });

                /**
                 * Calls ListBlueprintAttributes.
                 * @function listBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributesRequest} request ListBlueprintAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#updateBlueprintAttribute}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef UpdateBlueprintAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BlueprintAttribute} [response] BlueprintAttribute
                 */

                /**
                 * Calls UpdateBlueprintAttribute.
                 * @function updateBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintAttributeRequest} request UpdateBlueprintAttributeRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.UpdateBlueprintAttributeCallback} callback Node-style callback called with the error, if any, and BlueprintAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.updateBlueprintAttribute = function updateBlueprintAttribute(request, callback) {
                    return this.rpcCall(updateBlueprintAttribute, $root.mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest, $root.mtechnavi.api.blueprint.BlueprintAttribute, request, callback);
                }, "name", { value: "UpdateBlueprintAttribute" });

                /**
                 * Calls UpdateBlueprintAttribute.
                 * @function updateBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintAttributeRequest} request UpdateBlueprintAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BlueprintAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#bulkWriteBlueprintAttributes}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef BulkWriteBlueprintAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse} [response] BulkWriteBlueprintAttributesResponse
                 */

                /**
                 * Calls BulkWriteBlueprintAttributes.
                 * @function bulkWriteBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IBulkWriteBlueprintAttributesRequest} request BulkWriteBlueprintAttributesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.BulkWriteBlueprintAttributesCallback} callback Node-style callback called with the error, if any, and BulkWriteBlueprintAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.bulkWriteBlueprintAttributes = function bulkWriteBlueprintAttributes(request, callback) {
                    return this.rpcCall(bulkWriteBlueprintAttributes, $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest, $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse, request, callback);
                }, "name", { value: "BulkWriteBlueprintAttributes" });

                /**
                 * Calls BulkWriteBlueprintAttributes.
                 * @function bulkWriteBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IBulkWriteBlueprintAttributesRequest} request BulkWriteBlueprintAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#deleteBlueprintAttribute}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef DeleteBlueprintAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBlueprintAttribute.
                 * @function deleteBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintAttributeRequest} request DeleteBlueprintAttributeRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.DeleteBlueprintAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.deleteBlueprintAttribute = function deleteBlueprintAttribute(request, callback) {
                    return this.rpcCall(deleteBlueprintAttribute, $root.mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBlueprintAttribute" });

                /**
                 * Calls DeleteBlueprintAttribute.
                 * @function deleteBlueprintAttribute
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintAttributeRequest} request DeleteBlueprintAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintLinkItems}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintLinkItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse} [response] ListBlueprintLinkItemsResponse
                 */

                /**
                 * Calls ListBlueprintLinkItems.
                 * @function listBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemsRequest} request ListBlueprintLinkItemsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintLinkItemsCallback} callback Node-style callback called with the error, if any, and ListBlueprintLinkItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintLinkItems = function listBlueprintLinkItems(request, callback) {
                    return this.rpcCall(listBlueprintLinkItems, $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest, $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse, request, callback);
                }, "name", { value: "ListBlueprintLinkItems" });

                /**
                 * Calls ListBlueprintLinkItems.
                 * @function listBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemsRequest} request ListBlueprintLinkItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listUserDefinedViews}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListUserDefinedViewsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListUserDefinedViewsResponse} [response] ListUserDefinedViewsResponse
                 */

                /**
                 * Calls ListUserDefinedViews.
                 * @function listUserDefinedViews
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListUserDefinedViewsRequest} request ListUserDefinedViewsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListUserDefinedViewsCallback} callback Node-style callback called with the error, if any, and ListUserDefinedViewsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listUserDefinedViews = function listUserDefinedViews(request, callback) {
                    return this.rpcCall(listUserDefinedViews, $root.mtechnavi.api.blueprint.ListUserDefinedViewsRequest, $root.mtechnavi.api.blueprint.ListUserDefinedViewsResponse, request, callback);
                }, "name", { value: "ListUserDefinedViews" });

                /**
                 * Calls ListUserDefinedViews.
                 * @function listUserDefinedViews
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListUserDefinedViewsRequest} request ListUserDefinedViewsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListUserDefinedViewsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listUserDefinedDatas}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListUserDefinedDatasCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListUserDefinedDatasResponse} [response] ListUserDefinedDatasResponse
                 */

                /**
                 * Calls ListUserDefinedDatas.
                 * @function listUserDefinedDatas
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListUserDefinedDatasRequest} request ListUserDefinedDatasRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListUserDefinedDatasCallback} callback Node-style callback called with the error, if any, and ListUserDefinedDatasResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listUserDefinedDatas = function listUserDefinedDatas(request, callback) {
                    return this.rpcCall(listUserDefinedDatas, $root.mtechnavi.api.blueprint.ListUserDefinedDatasRequest, $root.mtechnavi.api.blueprint.ListUserDefinedDatasResponse, request, callback);
                }, "name", { value: "ListUserDefinedDatas" });

                /**
                 * Calls ListUserDefinedDatas.
                 * @function listUserDefinedDatas
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListUserDefinedDatasRequest} request ListUserDefinedDatasRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListUserDefinedDatasResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#existsBlueprintNumber}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ExistsBlueprintNumberCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse} [response] ExistsBlueprintNumberRequestResponse
                 */

                /**
                 * Calls ExistsBlueprintNumber.
                 * @function existsBlueprintNumber
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExistsBlueprintNumberRequest} request ExistsBlueprintNumberRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ExistsBlueprintNumberCallback} callback Node-style callback called with the error, if any, and ExistsBlueprintNumberRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.existsBlueprintNumber = function existsBlueprintNumber(request, callback) {
                    return this.rpcCall(existsBlueprintNumber, $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequest, $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse, request, callback);
                }, "name", { value: "ExistsBlueprintNumber" });

                /**
                 * Calls ExistsBlueprintNumber.
                 * @function existsBlueprintNumber
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExistsBlueprintNumberRequest} request ExistsBlueprintNumberRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#asyncImportBlueprintsWithBlueprintFiles}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef AsyncImportBlueprintsWithBlueprintFilesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls AsyncImportBlueprintsWithBlueprintFiles.
                 * @function asyncImportBlueprintsWithBlueprintFiles
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsWithBlueprintFilesRequest} request AsyncImportBlueprintsWithBlueprintFilesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.AsyncImportBlueprintsWithBlueprintFilesCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.asyncImportBlueprintsWithBlueprintFiles = function asyncImportBlueprintsWithBlueprintFiles(request, callback) {
                    return this.rpcCall(asyncImportBlueprintsWithBlueprintFiles, $root.mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "AsyncImportBlueprintsWithBlueprintFiles" });

                /**
                 * Calls AsyncImportBlueprintsWithBlueprintFiles.
                 * @function asyncImportBlueprintsWithBlueprintFiles
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsWithBlueprintFilesRequest} request AsyncImportBlueprintsWithBlueprintFilesRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#asyncImportBlueprints}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef AsyncImportBlueprintsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls AsyncImportBlueprints.
                 * @function asyncImportBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsRequest} request AsyncImportBlueprintsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.AsyncImportBlueprintsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.asyncImportBlueprints = function asyncImportBlueprints(request, callback) {
                    return this.rpcCall(asyncImportBlueprints, $root.mtechnavi.api.blueprint.AsyncImportBlueprintsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "AsyncImportBlueprints" });

                /**
                 * Calls AsyncImportBlueprints.
                 * @function asyncImportBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsRequest} request AsyncImportBlueprintsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#exportBlueprints}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ExportBlueprintsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExportBlueprintsResponse} [response] ExportBlueprintsResponse
                 */

                /**
                 * Calls ExportBlueprints.
                 * @function exportBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintsRequest} request ExportBlueprintsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ExportBlueprintsCallback} callback Node-style callback called with the error, if any, and ExportBlueprintsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.exportBlueprints = function exportBlueprints(request, callback) {
                    return this.rpcCall(exportBlueprints, $root.mtechnavi.api.blueprint.ExportBlueprintsRequest, $root.mtechnavi.api.blueprint.ExportBlueprintsResponse, request, callback);
                }, "name", { value: "ExportBlueprints" });

                /**
                 * Calls ExportBlueprints.
                 * @function exportBlueprints
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintsRequest} request ExportBlueprintsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExportBlueprintsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintFormats}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintFormatsResponse} [response] ListBlueprintFormatsResponse
                 */

                /**
                 * Calls ListBlueprintFormats.
                 * @function listBlueprintFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintFormatsRequest} request ListBlueprintFormatsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintFormatsCallback} callback Node-style callback called with the error, if any, and ListBlueprintFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintFormats = function listBlueprintFormats(request, callback) {
                    return this.rpcCall(listBlueprintFormats, $root.mtechnavi.api.blueprint.ListBlueprintFormatsRequest, $root.mtechnavi.api.blueprint.ListBlueprintFormatsResponse, request, callback);
                }, "name", { value: "ListBlueprintFormats" });

                /**
                 * Calls ListBlueprintFormats.
                 * @function listBlueprintFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintFormatsRequest} request ListBlueprintFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#asyncImportBlueprintAttributes}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef AsyncImportBlueprintAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls AsyncImportBlueprintAttributes.
                 * @function asyncImportBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintAttributesRequest} request AsyncImportBlueprintAttributesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.AsyncImportBlueprintAttributesCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.asyncImportBlueprintAttributes = function asyncImportBlueprintAttributes(request, callback) {
                    return this.rpcCall(asyncImportBlueprintAttributes, $root.mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "AsyncImportBlueprintAttributes" });

                /**
                 * Calls AsyncImportBlueprintAttributes.
                 * @function asyncImportBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintAttributesRequest} request AsyncImportBlueprintAttributesRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#exportBlueprintAttributes}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ExportBlueprintAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExportBlueprintAttributesResponse} [response] ExportBlueprintAttributesResponse
                 */

                /**
                 * Calls ExportBlueprintAttributes.
                 * @function exportBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintAttributesRequest} request ExportBlueprintAttributesRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ExportBlueprintAttributesCallback} callback Node-style callback called with the error, if any, and ExportBlueprintAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.exportBlueprintAttributes = function exportBlueprintAttributes(request, callback) {
                    return this.rpcCall(exportBlueprintAttributes, $root.mtechnavi.api.blueprint.ExportBlueprintAttributesRequest, $root.mtechnavi.api.blueprint.ExportBlueprintAttributesResponse, request, callback);
                }, "name", { value: "ExportBlueprintAttributes" });

                /**
                 * Calls ExportBlueprintAttributes.
                 * @function exportBlueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintAttributesRequest} request ExportBlueprintAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExportBlueprintAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintAttributeFormats}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintAttributeFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse} [response] ListBlueprintAttributeFormatsResponse
                 */

                /**
                 * Calls ListBlueprintAttributeFormats.
                 * @function listBlueprintAttributeFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributeFormatsRequest} request ListBlueprintAttributeFormatsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintAttributeFormatsCallback} callback Node-style callback called with the error, if any, and ListBlueprintAttributeFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintAttributeFormats = function listBlueprintAttributeFormats(request, callback) {
                    return this.rpcCall(listBlueprintAttributeFormats, $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest, $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse, request, callback);
                }, "name", { value: "ListBlueprintAttributeFormats" });

                /**
                 * Calls ListBlueprintAttributeFormats.
                 * @function listBlueprintAttributeFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributeFormatsRequest} request ListBlueprintAttributeFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#asyncImportBlueprintLinkItems}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef AsyncImportBlueprintLinkItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls AsyncImportBlueprintLinkItems.
                 * @function asyncImportBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintLinkItemsRequest} request AsyncImportBlueprintLinkItemsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.AsyncImportBlueprintLinkItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.asyncImportBlueprintLinkItems = function asyncImportBlueprintLinkItems(request, callback) {
                    return this.rpcCall(asyncImportBlueprintLinkItems, $root.mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "AsyncImportBlueprintLinkItems" });

                /**
                 * Calls AsyncImportBlueprintLinkItems.
                 * @function asyncImportBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintLinkItemsRequest} request AsyncImportBlueprintLinkItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#exportBlueprintLinkItems}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ExportBlueprintLinkItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse} [response] ExportBlueprintLinkItemsResponse
                 */

                /**
                 * Calls ExportBlueprintLinkItems.
                 * @function exportBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintLinkItemsRequest} request ExportBlueprintLinkItemsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ExportBlueprintLinkItemsCallback} callback Node-style callback called with the error, if any, and ExportBlueprintLinkItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.exportBlueprintLinkItems = function exportBlueprintLinkItems(request, callback) {
                    return this.rpcCall(exportBlueprintLinkItems, $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest, $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse, request, callback);
                }, "name", { value: "ExportBlueprintLinkItems" });

                /**
                 * Calls ExportBlueprintLinkItems.
                 * @function exportBlueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IExportBlueprintLinkItemsRequest} request ExportBlueprintLinkItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintService#listBlueprintLinkItemFormats}.
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @typedef ListBlueprintLinkItemFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse} [response] ListBlueprintLinkItemFormatsResponse
                 */

                /**
                 * Calls ListBlueprintLinkItemFormats.
                 * @function listBlueprintLinkItemFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemFormatsRequest} request ListBlueprintLinkItemFormatsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintService.ListBlueprintLinkItemFormatsCallback} callback Node-style callback called with the error, if any, and ListBlueprintLinkItemFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintService.prototype.listBlueprintLinkItemFormats = function listBlueprintLinkItemFormats(request, callback) {
                    return this.rpcCall(listBlueprintLinkItemFormats, $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest, $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse, request, callback);
                }, "name", { value: "ListBlueprintLinkItemFormats" });

                /**
                 * Calls ListBlueprintLinkItemFormats.
                 * @function listBlueprintLinkItemFormats
                 * @memberof mtechnavi.api.blueprint.BlueprintService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemFormatsRequest} request ListBlueprintLinkItemFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse>} Promise
                 * @variation 2
                 */

                return BlueprintService;
            })();

            blueprint.BlueprintTaskListService = (function() {

                /**
                 * Constructs a new BlueprintTaskListService service.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintTaskListService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BlueprintTaskListService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BlueprintTaskListService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BlueprintTaskListService;

                /**
                 * Callback as used by {@link mtechnavi.api.blueprint.BlueprintTaskListService#uncompletedListThreads}.
                 * @memberof mtechnavi.api.blueprint.BlueprintTaskListService
                 * @typedef UncompletedListThreadsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.blueprint.UncompletedListThreadsResponse} [response] UncompletedListThreadsResponse
                 */

                /**
                 * Calls UncompletedListThreads.
                 * @function uncompletedListThreads
                 * @memberof mtechnavi.api.blueprint.BlueprintTaskListService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUncompletedListThreadsRequest} request UncompletedListThreadsRequest message or plain object
                 * @param {mtechnavi.api.blueprint.BlueprintTaskListService.UncompletedListThreadsCallback} callback Node-style callback called with the error, if any, and UncompletedListThreadsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BlueprintTaskListService.prototype.uncompletedListThreads = function uncompletedListThreads(request, callback) {
                    return this.rpcCall(uncompletedListThreads, $root.mtechnavi.api.blueprint.UncompletedListThreadsRequest, $root.mtechnavi.api.blueprint.UncompletedListThreadsResponse, request, callback);
                }, "name", { value: "UncompletedListThreads" });

                /**
                 * Calls UncompletedListThreads.
                 * @function uncompletedListThreads
                 * @memberof mtechnavi.api.blueprint.BlueprintTaskListService
                 * @instance
                 * @param {mtechnavi.api.blueprint.IUncompletedListThreadsRequest} request UncompletedListThreadsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.blueprint.UncompletedListThreadsResponse>} Promise
                 * @variation 2
                 */

                return BlueprintTaskListService;
            })();

            blueprint.CreateTransactionUnitRequest = (function() {

                /**
                 * Properties of a CreateTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICreateTransactionUnitRequest
                 * @property {mtechnavi.api.blueprint.ITransactionUnit|null} [transactionUnit] CreateTransactionUnitRequest transactionUnit
                 */

                /**
                 * Constructs a new CreateTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CreateTransactionUnitRequest.
                 * @implements ICreateTransactionUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICreateTransactionUnitRequest=} [properties] Properties to set
                 */
                function CreateTransactionUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTransactionUnitRequest transactionUnit.
                 * @member {mtechnavi.api.blueprint.ITransactionUnit|null|undefined} transactionUnit
                 * @memberof mtechnavi.api.blueprint.CreateTransactionUnitRequest
                 * @instance
                 */
                CreateTransactionUnitRequest.prototype.transactionUnit = null;

                /**
                 * Verifies a CreateTransactionUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CreateTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTransactionUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit")) {
                        let error = $root.mtechnavi.api.blueprint.TransactionUnit.verify(message.transactionUnit);
                        if (error)
                            return "transactionUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateTransactionUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CreateTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CreateTransactionUnitRequest} CreateTransactionUnitRequest
                 */
                CreateTransactionUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CreateTransactionUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CreateTransactionUnitRequest();
                    if (object.transactionUnit != null) {
                        if (typeof object.transactionUnit !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateTransactionUnitRequest.transactionUnit: object expected");
                        message.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.fromObject(object.transactionUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTransactionUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CreateTransactionUnitRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.CreateTransactionUnitRequest} message CreateTransactionUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTransactionUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.transactionUnit = null;
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit"))
                        object.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.toObject(message.transactionUnit, options);
                    return object;
                };

                /**
                 * Converts this CreateTransactionUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CreateTransactionUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTransactionUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTransactionUnitRequest;
            })();

            blueprint.ListTransactionUnitsRequest = (function() {

                /**
                 * Properties of a ListTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListTransactionUnitsRequest
                 * @property {string|null} [pageToken] ListTransactionUnitsRequest pageToken
                 * @property {Array.<string>|null} [transactionUnitIds] ListTransactionUnitsRequest transactionUnitIds
                 */

                /**
                 * Constructs a new ListTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListTransactionUnitsRequest.
                 * @implements IListTransactionUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitsRequest=} [properties] Properties to set
                 */
                function ListTransactionUnitsRequest(properties) {
                    this.transactionUnitIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTransactionUnitsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @instance
                 */
                ListTransactionUnitsRequest.prototype.pageToken = "";

                /**
                 * ListTransactionUnitsRequest transactionUnitIds.
                 * @member {Array.<string>} transactionUnitIds
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @instance
                 */
                ListTransactionUnitsRequest.prototype.transactionUnitIds = $util.emptyArray;

                /**
                 * Verifies a ListTransactionUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTransactionUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.transactionUnitIds != null && message.hasOwnProperty("transactionUnitIds")) {
                        if (!Array.isArray(message.transactionUnitIds))
                            return "transactionUnitIds: array expected";
                        for (let i = 0; i < message.transactionUnitIds.length; ++i)
                            if (!$util.isString(message.transactionUnitIds[i]))
                                return "transactionUnitIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListTransactionUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListTransactionUnitsRequest} ListTransactionUnitsRequest
                 */
                ListTransactionUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListTransactionUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListTransactionUnitsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.transactionUnitIds) {
                        if (!Array.isArray(object.transactionUnitIds))
                            throw TypeError(".mtechnavi.api.blueprint.ListTransactionUnitsRequest.transactionUnitIds: array expected");
                        message.transactionUnitIds = [];
                        for (let i = 0; i < object.transactionUnitIds.length; ++i)
                            message.transactionUnitIds[i] = String(object.transactionUnitIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListTransactionUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitsRequest} message ListTransactionUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTransactionUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.transactionUnitIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.transactionUnitIds && message.transactionUnitIds.length) {
                        object.transactionUnitIds = [];
                        for (let j = 0; j < message.transactionUnitIds.length; ++j)
                            object.transactionUnitIds[j] = message.transactionUnitIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListTransactionUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTransactionUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTransactionUnitsRequest;
            })();

            blueprint.ListTransactionUnitsResponse = (function() {

                /**
                 * Properties of a ListTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListTransactionUnitsResponse
                 * @property {Long|null} [total] ListTransactionUnitsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.ITransactionUnit>|null} [items] ListTransactionUnitsResponse items
                 * @property {string|null} [pageToken] ListTransactionUnitsResponse pageToken
                 */

                /**
                 * Constructs a new ListTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListTransactionUnitsResponse.
                 * @implements IListTransactionUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitsResponse=} [properties] Properties to set
                 */
                function ListTransactionUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTransactionUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @instance
                 */
                ListTransactionUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListTransactionUnitsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.ITransactionUnit>} items
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @instance
                 */
                ListTransactionUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListTransactionUnitsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @instance
                 */
                ListTransactionUnitsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListTransactionUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTransactionUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.TransactionUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListTransactionUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListTransactionUnitsResponse} ListTransactionUnitsResponse
                 */
                ListTransactionUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListTransactionUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListTransactionUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListTransactionUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListTransactionUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.TransactionUnit.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListTransactionUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitsResponse} message ListTransactionUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTransactionUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.TransactionUnit.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListTransactionUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTransactionUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTransactionUnitsResponse;
            })();

            blueprint.GetTransactionUnitRequest = (function() {

                /**
                 * Properties of a GetTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IGetTransactionUnitRequest
                 * @property {string|null} [transactionUnitId] GetTransactionUnitRequest transactionUnitId
                 */

                /**
                 * Constructs a new GetTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a GetTransactionUnitRequest.
                 * @implements IGetTransactionUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IGetTransactionUnitRequest=} [properties] Properties to set
                 */
                function GetTransactionUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTransactionUnitRequest transactionUnitId.
                 * @member {string} transactionUnitId
                 * @memberof mtechnavi.api.blueprint.GetTransactionUnitRequest
                 * @instance
                 */
                GetTransactionUnitRequest.prototype.transactionUnitId = "";

                /**
                 * Verifies a GetTransactionUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.GetTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTransactionUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        if (!$util.isString(message.transactionUnitId))
                            return "transactionUnitId: string expected";
                    return null;
                };

                /**
                 * Creates a GetTransactionUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.GetTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.GetTransactionUnitRequest} GetTransactionUnitRequest
                 */
                GetTransactionUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.GetTransactionUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.GetTransactionUnitRequest();
                    if (object.transactionUnitId != null)
                        message.transactionUnitId = String(object.transactionUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTransactionUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.GetTransactionUnitRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.GetTransactionUnitRequest} message GetTransactionUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTransactionUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.transactionUnitId = "";
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        object.transactionUnitId = message.transactionUnitId;
                    return object;
                };

                /**
                 * Converts this GetTransactionUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.GetTransactionUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTransactionUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTransactionUnitRequest;
            })();

            blueprint.UpdateTransactionUnitRequest = (function() {

                /**
                 * Properties of an UpdateTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUpdateTransactionUnitRequest
                 * @property {mtechnavi.api.blueprint.ITransactionUnit|null} [transactionUnit] UpdateTransactionUnitRequest transactionUnit
                 */

                /**
                 * Constructs a new UpdateTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UpdateTransactionUnitRequest.
                 * @implements IUpdateTransactionUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUpdateTransactionUnitRequest=} [properties] Properties to set
                 */
                function UpdateTransactionUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateTransactionUnitRequest transactionUnit.
                 * @member {mtechnavi.api.blueprint.ITransactionUnit|null|undefined} transactionUnit
                 * @memberof mtechnavi.api.blueprint.UpdateTransactionUnitRequest
                 * @instance
                 */
                UpdateTransactionUnitRequest.prototype.transactionUnit = null;

                /**
                 * Verifies an UpdateTransactionUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UpdateTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTransactionUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit")) {
                        let error = $root.mtechnavi.api.blueprint.TransactionUnit.verify(message.transactionUnit);
                        if (error)
                            return "transactionUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateTransactionUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UpdateTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UpdateTransactionUnitRequest} UpdateTransactionUnitRequest
                 */
                UpdateTransactionUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UpdateTransactionUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UpdateTransactionUnitRequest();
                    if (object.transactionUnit != null) {
                        if (typeof object.transactionUnit !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.UpdateTransactionUnitRequest.transactionUnit: object expected");
                        message.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.fromObject(object.transactionUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateTransactionUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UpdateTransactionUnitRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UpdateTransactionUnitRequest} message UpdateTransactionUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTransactionUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.transactionUnit = null;
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit"))
                        object.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.toObject(message.transactionUnit, options);
                    return object;
                };

                /**
                 * Converts this UpdateTransactionUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UpdateTransactionUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTransactionUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateTransactionUnitRequest;
            })();

            blueprint.DeleteTransactionUnitRequest = (function() {

                /**
                 * Properties of a DeleteTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IDeleteTransactionUnitRequest
                 * @property {mtechnavi.api.blueprint.ITransactionUnit|null} [transactionUnit] DeleteTransactionUnitRequest transactionUnit
                 */

                /**
                 * Constructs a new DeleteTransactionUnitRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a DeleteTransactionUnitRequest.
                 * @implements IDeleteTransactionUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IDeleteTransactionUnitRequest=} [properties] Properties to set
                 */
                function DeleteTransactionUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteTransactionUnitRequest transactionUnit.
                 * @member {mtechnavi.api.blueprint.ITransactionUnit|null|undefined} transactionUnit
                 * @memberof mtechnavi.api.blueprint.DeleteTransactionUnitRequest
                 * @instance
                 */
                DeleteTransactionUnitRequest.prototype.transactionUnit = null;

                /**
                 * Verifies a DeleteTransactionUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.DeleteTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTransactionUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit")) {
                        let error = $root.mtechnavi.api.blueprint.TransactionUnit.verify(message.transactionUnit);
                        if (error)
                            return "transactionUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteTransactionUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.DeleteTransactionUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.DeleteTransactionUnitRequest} DeleteTransactionUnitRequest
                 */
                DeleteTransactionUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.DeleteTransactionUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.DeleteTransactionUnitRequest();
                    if (object.transactionUnit != null) {
                        if (typeof object.transactionUnit !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.DeleteTransactionUnitRequest.transactionUnit: object expected");
                        message.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.fromObject(object.transactionUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteTransactionUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.DeleteTransactionUnitRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.DeleteTransactionUnitRequest} message DeleteTransactionUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTransactionUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.transactionUnit = null;
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit"))
                        object.transactionUnit = $root.mtechnavi.api.blueprint.TransactionUnit.toObject(message.transactionUnit, options);
                    return object;
                };

                /**
                 * Converts this DeleteTransactionUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.DeleteTransactionUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTransactionUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteTransactionUnitRequest;
            })();

            blueprint.ImportTransactionUnitsRequest = (function() {

                /**
                 * Properties of an ImportTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IImportTransactionUnitsRequest
                 * @property {string|null} [url] ImportTransactionUnitsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportTransactionUnitsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportTransactionUnitsRequest dryRun
                 * @property {boolean|null} [exportError] ImportTransactionUnitsRequest exportError
                 */

                /**
                 * Constructs a new ImportTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ImportTransactionUnitsRequest.
                 * @implements IImportTransactionUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IImportTransactionUnitsRequest=} [properties] Properties to set
                 */
                function ImportTransactionUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportTransactionUnitsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @instance
                 */
                ImportTransactionUnitsRequest.prototype.url = "";

                /**
                 * ImportTransactionUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @instance
                 */
                ImportTransactionUnitsRequest.prototype.fileFormat = null;

                /**
                 * ImportTransactionUnitsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @instance
                 */
                ImportTransactionUnitsRequest.prototype.dryRun = false;

                /**
                 * ImportTransactionUnitsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @instance
                 */
                ImportTransactionUnitsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportTransactionUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportTransactionUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportTransactionUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ImportTransactionUnitsRequest} ImportTransactionUnitsRequest
                 */
                ImportTransactionUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ImportTransactionUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ImportTransactionUnitsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ImportTransactionUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportTransactionUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ImportTransactionUnitsRequest} message ImportTransactionUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportTransactionUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportTransactionUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportTransactionUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportTransactionUnitsRequest;
            })();

            blueprint.ImportTransactionUnitsResponse = (function() {

                /**
                 * Properties of an ImportTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IImportTransactionUnitsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportTransactionUnitsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportTransactionUnitsResponse errors
                 * @property {string|null} [assetId] ImportTransactionUnitsResponse assetId
                 */

                /**
                 * Constructs a new ImportTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ImportTransactionUnitsResponse.
                 * @implements IImportTransactionUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IImportTransactionUnitsResponse=} [properties] Properties to set
                 */
                function ImportTransactionUnitsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportTransactionUnitsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @instance
                 */
                ImportTransactionUnitsResponse.prototype.summary = null;

                /**
                 * ImportTransactionUnitsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @instance
                 */
                ImportTransactionUnitsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportTransactionUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @instance
                 */
                ImportTransactionUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportTransactionUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportTransactionUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportTransactionUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ImportTransactionUnitsResponse} ImportTransactionUnitsResponse
                 */
                ImportTransactionUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ImportTransactionUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ImportTransactionUnitsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ImportTransactionUnitsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.blueprint.ImportTransactionUnitsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ImportTransactionUnitsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportTransactionUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ImportTransactionUnitsResponse} message ImportTransactionUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportTransactionUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportTransactionUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ImportTransactionUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportTransactionUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportTransactionUnitsResponse;
            })();

            blueprint.ExportTransactionUnitsRequest = (function() {

                /**
                 * Properties of an ExportTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportTransactionUnitsRequest
                 * @property {Array.<string>|null} [ids] ExportTransactionUnitsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportTransactionUnitsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportTransactionUnitsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportTransactionUnitsRequest.
                 * @implements IExportTransactionUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportTransactionUnitsRequest=} [properties] Properties to set
                 */
                function ExportTransactionUnitsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportTransactionUnitsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @instance
                 */
                ExportTransactionUnitsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportTransactionUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @instance
                 */
                ExportTransactionUnitsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportTransactionUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportTransactionUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportTransactionUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportTransactionUnitsRequest} ExportTransactionUnitsRequest
                 */
                ExportTransactionUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportTransactionUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportTransactionUnitsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.blueprint.ExportTransactionUnitsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ExportTransactionUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportTransactionUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportTransactionUnitsRequest} message ExportTransactionUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportTransactionUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportTransactionUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportTransactionUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportTransactionUnitsRequest;
            })();

            blueprint.ExportTransactionUnitsResponse = (function() {

                /**
                 * Properties of an ExportTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportTransactionUnitsResponse
                 * @property {string|null} [assetId] ExportTransactionUnitsResponse assetId
                 */

                /**
                 * Constructs a new ExportTransactionUnitsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportTransactionUnitsResponse.
                 * @implements IExportTransactionUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportTransactionUnitsResponse=} [properties] Properties to set
                 */
                function ExportTransactionUnitsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportTransactionUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsResponse
                 * @instance
                 */
                ExportTransactionUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportTransactionUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportTransactionUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportTransactionUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportTransactionUnitsResponse} ExportTransactionUnitsResponse
                 */
                ExportTransactionUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportTransactionUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportTransactionUnitsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportTransactionUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportTransactionUnitsResponse} message ExportTransactionUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportTransactionUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportTransactionUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportTransactionUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportTransactionUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportTransactionUnitsResponse;
            })();

            blueprint.ListTransactionUnitFormatsRequest = (function() {

                /**
                 * Properties of a ListTransactionUnitFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListTransactionUnitFormatsRequest
                 */

                /**
                 * Constructs a new ListTransactionUnitFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListTransactionUnitFormatsRequest.
                 * @implements IListTransactionUnitFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitFormatsRequest=} [properties] Properties to set
                 */
                function ListTransactionUnitFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListTransactionUnitFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTransactionUnitFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListTransactionUnitFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest} ListTransactionUnitFormatsRequest
                 */
                ListTransactionUnitFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListTransactionUnitFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest} message ListTransactionUnitFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTransactionUnitFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListTransactionUnitFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTransactionUnitFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTransactionUnitFormatsRequest;
            })();

            blueprint.ListTransactionUnitFormatsResponse = (function() {

                /**
                 * Properties of a ListTransactionUnitFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListTransactionUnitFormatsResponse
                 * @property {Long|null} [total] ListTransactionUnitFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListTransactionUnitFormatsResponse items
                 */

                /**
                 * Constructs a new ListTransactionUnitFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListTransactionUnitFormatsResponse.
                 * @implements IListTransactionUnitFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListTransactionUnitFormatsResponse=} [properties] Properties to set
                 */
                function ListTransactionUnitFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTransactionUnitFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @instance
                 */
                ListTransactionUnitFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListTransactionUnitFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @instance
                 */
                ListTransactionUnitFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListTransactionUnitFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTransactionUnitFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListTransactionUnitFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse} ListTransactionUnitFormatsResponse
                 */
                ListTransactionUnitFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListTransactionUnitFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse} message ListTransactionUnitFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTransactionUnitFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListTransactionUnitFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListTransactionUnitFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTransactionUnitFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTransactionUnitFormatsResponse;
            })();

            blueprint.SearchBlueprintsRequest = (function() {

                /**
                 * Properties of a SearchBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ISearchBlueprintsRequest
                 * @property {Long|null} [limit] SearchBlueprintsRequest limit
                 * @property {string|null} [pageToken] SearchBlueprintsRequest pageToken
                 * @property {mtechnavi.api.blueprint.SearchBlueprintsRequest.IDataFilter|null} [dataFilter] SearchBlueprintsRequest dataFilter
                 * @property {Array.<string>|null} [displayAttributes] SearchBlueprintsRequest displayAttributes
                 */

                /**
                 * Constructs a new SearchBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a SearchBlueprintsRequest.
                 * @implements ISearchBlueprintsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ISearchBlueprintsRequest=} [properties] Properties to set
                 */
                function SearchBlueprintsRequest(properties) {
                    this.displayAttributes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SearchBlueprintsRequest limit.
                 * @member {Long} limit
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @instance
                 */
                SearchBlueprintsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SearchBlueprintsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @instance
                 */
                SearchBlueprintsRequest.prototype.pageToken = "";

                /**
                 * SearchBlueprintsRequest dataFilter.
                 * @member {mtechnavi.api.blueprint.SearchBlueprintsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @instance
                 */
                SearchBlueprintsRequest.prototype.dataFilter = null;

                /**
                 * SearchBlueprintsRequest displayAttributes.
                 * @member {Array.<string>} displayAttributes
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @instance
                 */
                SearchBlueprintsRequest.prototype.displayAttributes = $util.emptyArray;

                /**
                 * Verifies a SearchBlueprintsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SearchBlueprintsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    if (message.displayAttributes != null && message.hasOwnProperty("displayAttributes")) {
                        if (!Array.isArray(message.displayAttributes))
                            return "displayAttributes: array expected";
                        for (let i = 0; i < message.displayAttributes.length; ++i)
                            if (!$util.isString(message.displayAttributes[i]))
                                return "displayAttributes: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SearchBlueprintsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.SearchBlueprintsRequest} SearchBlueprintsRequest
                 */
                SearchBlueprintsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.SearchBlueprintsRequest();
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    if (object.displayAttributes) {
                        if (!Array.isArray(object.displayAttributes))
                            throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.displayAttributes: array expected");
                        message.displayAttributes = [];
                        for (let i = 0; i < object.displayAttributes.length; ++i)
                            message.displayAttributes[i] = String(object.displayAttributes[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SearchBlueprintsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.SearchBlueprintsRequest} message SearchBlueprintsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SearchBlueprintsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.displayAttributes = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.toObject(message.dataFilter, options);
                    if (message.displayAttributes && message.displayAttributes.length) {
                        object.displayAttributes = [];
                        for (let j = 0; j < message.displayAttributes.length; ++j)
                            object.displayAttributes[j] = message.displayAttributes[j];
                    }
                    return object;
                };

                /**
                 * Converts this SearchBlueprintsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SearchBlueprintsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                SearchBlueprintsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                     * @interface IDataFilter
                     * @property {Long|null} [classTopX] DataFilter classTopX
                     * @property {Array.<string>|null} [pClassNames] DataFilter pClassNames
                     * @property {Array.<string>|null} [characterTargetAttributes] DataFilter characterTargetAttributes
                     * @property {boolean|null} [characterAndSearch] DataFilter characterAndSearch
                     * @property {Array.<string>|null} [characterKeywords] DataFilter characterKeywords
                     * @property {Array.<mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition>|null} [numericRangeConditions] DataFilter numericRangeConditions
                     * @property {Array.<mtechnavi.api.blueprint.ITemporarySegmentProperty>|null} [temporarySegmentPropertys] DataFilter temporarySegmentPropertys
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.blueprint.SearchBlueprintsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.pClassNames = [];
                        this.characterTargetAttributes = [];
                        this.characterKeywords = [];
                        this.numericRangeConditions = [];
                        this.temporarySegmentPropertys = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter classTopX.
                     * @member {Long} classTopX
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.classTopX = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter pClassNames.
                     * @member {Array.<string>} pClassNames
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.pClassNames = $util.emptyArray;

                    /**
                     * DataFilter characterTargetAttributes.
                     * @member {Array.<string>} characterTargetAttributes
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.characterTargetAttributes = $util.emptyArray;

                    /**
                     * DataFilter characterAndSearch.
                     * @member {boolean} characterAndSearch
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.characterAndSearch = false;

                    /**
                     * DataFilter characterKeywords.
                     * @member {Array.<string>} characterKeywords
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.characterKeywords = $util.emptyArray;

                    /**
                     * DataFilter numericRangeConditions.
                     * @member {Array.<mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition>} numericRangeConditions
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.numericRangeConditions = $util.emptyArray;

                    /**
                     * DataFilter temporarySegmentPropertys.
                     * @member {Array.<mtechnavi.api.blueprint.ITemporarySegmentProperty>} temporarySegmentPropertys
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.temporarySegmentPropertys = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.classTopX != null && message.hasOwnProperty("classTopX"))
                            if (!$util.isInteger(message.classTopX) && !(message.classTopX && $util.isInteger(message.classTopX.low) && $util.isInteger(message.classTopX.high)))
                                return "classTopX: integer|Long expected";
                        if (message.pClassNames != null && message.hasOwnProperty("pClassNames")) {
                            if (!Array.isArray(message.pClassNames))
                                return "pClassNames: array expected";
                            for (let i = 0; i < message.pClassNames.length; ++i)
                                if (!$util.isString(message.pClassNames[i]))
                                    return "pClassNames: string[] expected";
                        }
                        if (message.characterTargetAttributes != null && message.hasOwnProperty("characterTargetAttributes")) {
                            if (!Array.isArray(message.characterTargetAttributes))
                                return "characterTargetAttributes: array expected";
                            for (let i = 0; i < message.characterTargetAttributes.length; ++i)
                                if (!$util.isString(message.characterTargetAttributes[i]))
                                    return "characterTargetAttributes: string[] expected";
                        }
                        if (message.characterAndSearch != null && message.hasOwnProperty("characterAndSearch"))
                            if (typeof message.characterAndSearch !== "boolean")
                                return "characterAndSearch: boolean expected";
                        if (message.characterKeywords != null && message.hasOwnProperty("characterKeywords")) {
                            if (!Array.isArray(message.characterKeywords))
                                return "characterKeywords: array expected";
                            for (let i = 0; i < message.characterKeywords.length; ++i)
                                if (!$util.isString(message.characterKeywords[i]))
                                    return "characterKeywords: string[] expected";
                        }
                        if (message.numericRangeConditions != null && message.hasOwnProperty("numericRangeConditions")) {
                            if (!Array.isArray(message.numericRangeConditions))
                                return "numericRangeConditions: array expected";
                            for (let i = 0; i < message.numericRangeConditions.length; ++i) {
                                let error = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition.verify(message.numericRangeConditions[i]);
                                if (error)
                                    return "numericRangeConditions." + error;
                            }
                        }
                        if (message.temporarySegmentPropertys != null && message.hasOwnProperty("temporarySegmentPropertys")) {
                            if (!Array.isArray(message.temporarySegmentPropertys))
                                return "temporarySegmentPropertys: array expected";
                            for (let i = 0; i < message.temporarySegmentPropertys.length; ++i) {
                                let error = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.verify(message.temporarySegmentPropertys[i]);
                                if (error)
                                    return "temporarySegmentPropertys." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter();
                        if (object.classTopX != null)
                            if ($util.Long)
                                (message.classTopX = $util.Long.fromValue(object.classTopX)).unsigned = false;
                            else if (typeof object.classTopX === "string")
                                message.classTopX = parseInt(object.classTopX, 10);
                            else if (typeof object.classTopX === "number")
                                message.classTopX = object.classTopX;
                            else if (typeof object.classTopX === "object")
                                message.classTopX = new $util.LongBits(object.classTopX.low >>> 0, object.classTopX.high >>> 0).toNumber();
                        if (object.pClassNames) {
                            if (!Array.isArray(object.pClassNames))
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.pClassNames: array expected");
                            message.pClassNames = [];
                            for (let i = 0; i < object.pClassNames.length; ++i)
                                message.pClassNames[i] = String(object.pClassNames[i]);
                        }
                        if (object.characterTargetAttributes) {
                            if (!Array.isArray(object.characterTargetAttributes))
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.characterTargetAttributes: array expected");
                            message.characterTargetAttributes = [];
                            for (let i = 0; i < object.characterTargetAttributes.length; ++i)
                                message.characterTargetAttributes[i] = String(object.characterTargetAttributes[i]);
                        }
                        if (object.characterAndSearch != null)
                            message.characterAndSearch = Boolean(object.characterAndSearch);
                        if (object.characterKeywords) {
                            if (!Array.isArray(object.characterKeywords))
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.characterKeywords: array expected");
                            message.characterKeywords = [];
                            for (let i = 0; i < object.characterKeywords.length; ++i)
                                message.characterKeywords[i] = String(object.characterKeywords[i]);
                        }
                        if (object.numericRangeConditions) {
                            if (!Array.isArray(object.numericRangeConditions))
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.numericRangeConditions: array expected");
                            message.numericRangeConditions = [];
                            for (let i = 0; i < object.numericRangeConditions.length; ++i) {
                                if (typeof object.numericRangeConditions[i] !== "object")
                                    throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.numericRangeConditions: object expected");
                                message.numericRangeConditions[i] = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition.fromObject(object.numericRangeConditions[i]);
                            }
                        }
                        if (object.temporarySegmentPropertys) {
                            if (!Array.isArray(object.temporarySegmentPropertys))
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.temporarySegmentPropertys: array expected");
                            message.temporarySegmentPropertys = [];
                            for (let i = 0; i < object.temporarySegmentPropertys.length; ++i) {
                                if (typeof object.temporarySegmentPropertys[i] !== "object")
                                    throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter.temporarySegmentPropertys: object expected");
                                message.temporarySegmentPropertys[i] = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.fromObject(object.temporarySegmentPropertys[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.pClassNames = [];
                            object.characterTargetAttributes = [];
                            object.characterKeywords = [];
                            object.numericRangeConditions = [];
                            object.temporarySegmentPropertys = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.classTopX = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.classTopX = options.longs === String ? "0" : 0;
                            object.characterAndSearch = false;
                        }
                        if (message.classTopX != null && message.hasOwnProperty("classTopX"))
                            if (typeof message.classTopX === "number")
                                object.classTopX = options.longs === String ? String(message.classTopX) : message.classTopX;
                            else
                                object.classTopX = options.longs === String ? $util.Long.prototype.toString.call(message.classTopX) : options.longs === Number ? new $util.LongBits(message.classTopX.low >>> 0, message.classTopX.high >>> 0).toNumber() : message.classTopX;
                        if (message.pClassNames && message.pClassNames.length) {
                            object.pClassNames = [];
                            for (let j = 0; j < message.pClassNames.length; ++j)
                                object.pClassNames[j] = message.pClassNames[j];
                        }
                        if (message.characterTargetAttributes && message.characterTargetAttributes.length) {
                            object.characterTargetAttributes = [];
                            for (let j = 0; j < message.characterTargetAttributes.length; ++j)
                                object.characterTargetAttributes[j] = message.characterTargetAttributes[j];
                        }
                        if (message.characterAndSearch != null && message.hasOwnProperty("characterAndSearch"))
                            object.characterAndSearch = message.characterAndSearch;
                        if (message.characterKeywords && message.characterKeywords.length) {
                            object.characterKeywords = [];
                            for (let j = 0; j < message.characterKeywords.length; ++j)
                                object.characterKeywords[j] = message.characterKeywords[j];
                        }
                        if (message.numericRangeConditions && message.numericRangeConditions.length) {
                            object.numericRangeConditions = [];
                            for (let j = 0; j < message.numericRangeConditions.length; ++j)
                                object.numericRangeConditions[j] = $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition.toObject(message.numericRangeConditions[j], options);
                        }
                        if (message.temporarySegmentPropertys && message.temporarySegmentPropertys.length) {
                            object.temporarySegmentPropertys = [];
                            for (let j = 0; j < message.temporarySegmentPropertys.length; ++j)
                                object.temporarySegmentPropertys[j] = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.toObject(message.temporarySegmentPropertys[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                SearchBlueprintsRequest.NumericRangeCondition = (function() {

                    /**
                     * Properties of a NumericRangeCondition.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                     * @interface INumericRangeCondition
                     * @property {string|null} [attribute] NumericRangeCondition attribute
                     * @property {string|null} [from] NumericRangeCondition from
                     * @property {string|null} [to] NumericRangeCondition to
                     */

                    /**
                     * Constructs a new NumericRangeCondition.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest
                     * @classdesc Represents a NumericRangeCondition.
                     * @implements INumericRangeCondition
                     * @constructor
                     * @param {mtechnavi.api.blueprint.SearchBlueprintsRequest.INumericRangeCondition=} [properties] Properties to set
                     */
                    function NumericRangeCondition(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NumericRangeCondition attribute.
                     * @member {string} attribute
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @instance
                     */
                    NumericRangeCondition.prototype.attribute = "";

                    /**
                     * NumericRangeCondition from.
                     * @member {string} from
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @instance
                     */
                    NumericRangeCondition.prototype.from = "";

                    /**
                     * NumericRangeCondition to.
                     * @member {string} to
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @instance
                     */
                    NumericRangeCondition.prototype.to = "";

                    /**
                     * Verifies a NumericRangeCondition message.
                     * @function verify
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NumericRangeCondition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.attribute != null && message.hasOwnProperty("attribute"))
                            if (!$util.isString(message.attribute))
                                return "attribute: string expected";
                        if (message.from != null && message.hasOwnProperty("from"))
                            if (!$util.isString(message.from))
                                return "from: string expected";
                        if (message.to != null && message.hasOwnProperty("to"))
                            if (!$util.isString(message.to))
                                return "to: string expected";
                        return null;
                    };

                    /**
                     * Creates a NumericRangeCondition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition} NumericRangeCondition
                     */
                    NumericRangeCondition.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition)
                            return object;
                        let message = new $root.mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition();
                        if (object.attribute != null)
                            message.attribute = String(object.attribute);
                        if (object.from != null)
                            message.from = String(object.from);
                        if (object.to != null)
                            message.to = String(object.to);
                        return message;
                    };

                    /**
                     * Creates a plain object from a NumericRangeCondition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @static
                     * @param {mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition} message NumericRangeCondition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NumericRangeCondition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.attribute = "";
                            object.from = "";
                            object.to = "";
                        }
                        if (message.attribute != null && message.hasOwnProperty("attribute"))
                            object.attribute = message.attribute;
                        if (message.from != null && message.hasOwnProperty("from"))
                            object.from = message.from;
                        if (message.to != null && message.hasOwnProperty("to"))
                            object.to = message.to;
                        return object;
                    };

                    /**
                     * Converts this NumericRangeCondition to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintsRequest.NumericRangeCondition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NumericRangeCondition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NumericRangeCondition;
                })();

                return SearchBlueprintsRequest;
            })();

            blueprint.SearchBlueprintsResponse = (function() {

                /**
                 * Properties of a SearchBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ISearchBlueprintsResponse
                 * @property {Long|null} [total] SearchBlueprintsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.ISearchBlueprintContent>|null} [items] SearchBlueprintsResponse items
                 * @property {string|null} [pageToken] SearchBlueprintsResponse pageToken
                 */

                /**
                 * Constructs a new SearchBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a SearchBlueprintsResponse.
                 * @implements ISearchBlueprintsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ISearchBlueprintsResponse=} [properties] Properties to set
                 */
                function SearchBlueprintsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SearchBlueprintsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @instance
                 */
                SearchBlueprintsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SearchBlueprintsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.ISearchBlueprintContent>} items
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @instance
                 */
                SearchBlueprintsResponse.prototype.items = $util.emptyArray;

                /**
                 * SearchBlueprintsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @instance
                 */
                SearchBlueprintsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SearchBlueprintsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SearchBlueprintsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.SearchBlueprintContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SearchBlueprintsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.SearchBlueprintsResponse} SearchBlueprintsResponse
                 */
                SearchBlueprintsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.SearchBlueprintsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.SearchBlueprintContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SearchBlueprintsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.SearchBlueprintsResponse} message SearchBlueprintsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SearchBlueprintsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.SearchBlueprintContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SearchBlueprintsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SearchBlueprintsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SearchBlueprintsResponse;
            })();

            blueprint.ExtractBlueprintImageSegmentRequest = (function() {

                /**
                 * Properties of an ExtractBlueprintImageSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExtractBlueprintImageSegmentRequest
                 * @property {string|null} [url] ExtractBlueprintImageSegmentRequest url
                 */

                /**
                 * Constructs a new ExtractBlueprintImageSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExtractBlueprintImageSegmentRequest.
                 * @implements IExtractBlueprintImageSegmentRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExtractBlueprintImageSegmentRequest=} [properties] Properties to set
                 */
                function ExtractBlueprintImageSegmentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtractBlueprintImageSegmentRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest
                 * @instance
                 */
                ExtractBlueprintImageSegmentRequest.prototype.url = "";

                /**
                 * Verifies an ExtractBlueprintImageSegmentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtractBlueprintImageSegmentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };

                /**
                 * Creates an ExtractBlueprintImageSegmentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest} ExtractBlueprintImageSegmentRequest
                 */
                ExtractBlueprintImageSegmentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };

                /**
                 * Creates a plain object from an ExtractBlueprintImageSegmentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest} message ExtractBlueprintImageSegmentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtractBlueprintImageSegmentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };

                /**
                 * Converts this ExtractBlueprintImageSegmentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtractBlueprintImageSegmentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtractBlueprintImageSegmentRequest;
            })();

            blueprint.ExtractBlueprintImageSegmentResponse = (function() {

                /**
                 * Properties of an ExtractBlueprintImageSegmentResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExtractBlueprintImageSegmentResponse
                 * @property {Array.<mtechnavi.api.blueprint.ITemporarySegmentProperty>|null} [temporarySegmentPropertys] ExtractBlueprintImageSegmentResponse temporarySegmentPropertys
                 */

                /**
                 * Constructs a new ExtractBlueprintImageSegmentResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExtractBlueprintImageSegmentResponse.
                 * @implements IExtractBlueprintImageSegmentResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExtractBlueprintImageSegmentResponse=} [properties] Properties to set
                 */
                function ExtractBlueprintImageSegmentResponse(properties) {
                    this.temporarySegmentPropertys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtractBlueprintImageSegmentResponse temporarySegmentPropertys.
                 * @member {Array.<mtechnavi.api.blueprint.ITemporarySegmentProperty>} temporarySegmentPropertys
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse
                 * @instance
                 */
                ExtractBlueprintImageSegmentResponse.prototype.temporarySegmentPropertys = $util.emptyArray;

                /**
                 * Verifies an ExtractBlueprintImageSegmentResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtractBlueprintImageSegmentResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.temporarySegmentPropertys != null && message.hasOwnProperty("temporarySegmentPropertys")) {
                        if (!Array.isArray(message.temporarySegmentPropertys))
                            return "temporarySegmentPropertys: array expected";
                        for (let i = 0; i < message.temporarySegmentPropertys.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.verify(message.temporarySegmentPropertys[i]);
                            if (error)
                                return "temporarySegmentPropertys." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an ExtractBlueprintImageSegmentResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse} ExtractBlueprintImageSegmentResponse
                 */
                ExtractBlueprintImageSegmentResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse();
                    if (object.temporarySegmentPropertys) {
                        if (!Array.isArray(object.temporarySegmentPropertys))
                            throw TypeError(".mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse.temporarySegmentPropertys: array expected");
                        message.temporarySegmentPropertys = [];
                        for (let i = 0; i < object.temporarySegmentPropertys.length; ++i) {
                            if (typeof object.temporarySegmentPropertys[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse.temporarySegmentPropertys: object expected");
                            message.temporarySegmentPropertys[i] = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.fromObject(object.temporarySegmentPropertys[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExtractBlueprintImageSegmentResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse} message ExtractBlueprintImageSegmentResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtractBlueprintImageSegmentResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.temporarySegmentPropertys = [];
                    if (message.temporarySegmentPropertys && message.temporarySegmentPropertys.length) {
                        object.temporarySegmentPropertys = [];
                        for (let j = 0; j < message.temporarySegmentPropertys.length; ++j)
                            object.temporarySegmentPropertys[j] = $root.mtechnavi.api.blueprint.TemporarySegmentProperty.toObject(message.temporarySegmentPropertys[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ExtractBlueprintImageSegmentResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExtractBlueprintImageSegmentResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtractBlueprintImageSegmentResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtractBlueprintImageSegmentResponse;
            })();

            blueprint.ListBlueprintDisplayAttributesRequest = (function() {

                /**
                 * Properties of a ListBlueprintDisplayAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintDisplayAttributesRequest
                 */

                /**
                 * Constructs a new ListBlueprintDisplayAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintDisplayAttributesRequest.
                 * @implements IListBlueprintDisplayAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintDisplayAttributesRequest=} [properties] Properties to set
                 */
                function ListBlueprintDisplayAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBlueprintDisplayAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintDisplayAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintDisplayAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest} ListBlueprintDisplayAttributesRequest
                 */
                ListBlueprintDisplayAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest();
                };

                /**
                 * Creates a plain object from a ListBlueprintDisplayAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest} message ListBlueprintDisplayAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintDisplayAttributesRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBlueprintDisplayAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintDisplayAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintDisplayAttributesRequest;
            })();

            blueprint.ListBlueprintDisplayAttributesResponse = (function() {

                /**
                 * Properties of a ListBlueprintDisplayAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintDisplayAttributesResponse
                 * @property {Array.<mtechnavi.api.blueprint.IDisplayAttribute>|null} [displayAttributes] ListBlueprintDisplayAttributesResponse displayAttributes
                 */

                /**
                 * Constructs a new ListBlueprintDisplayAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintDisplayAttributesResponse.
                 * @implements IListBlueprintDisplayAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintDisplayAttributesResponse=} [properties] Properties to set
                 */
                function ListBlueprintDisplayAttributesResponse(properties) {
                    this.displayAttributes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintDisplayAttributesResponse displayAttributes.
                 * @member {Array.<mtechnavi.api.blueprint.IDisplayAttribute>} displayAttributes
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse
                 * @instance
                 */
                ListBlueprintDisplayAttributesResponse.prototype.displayAttributes = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintDisplayAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintDisplayAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayAttributes != null && message.hasOwnProperty("displayAttributes")) {
                        if (!Array.isArray(message.displayAttributes))
                            return "displayAttributes: array expected";
                        for (let i = 0; i < message.displayAttributes.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.DisplayAttribute.verify(message.displayAttributes[i]);
                            if (error)
                                return "displayAttributes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintDisplayAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse} ListBlueprintDisplayAttributesResponse
                 */
                ListBlueprintDisplayAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse();
                    if (object.displayAttributes) {
                        if (!Array.isArray(object.displayAttributes))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse.displayAttributes: array expected");
                        message.displayAttributes = [];
                        for (let i = 0; i < object.displayAttributes.length; ++i) {
                            if (typeof object.displayAttributes[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse.displayAttributes: object expected");
                            message.displayAttributes[i] = $root.mtechnavi.api.blueprint.DisplayAttribute.fromObject(object.displayAttributes[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintDisplayAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse} message ListBlueprintDisplayAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintDisplayAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.displayAttributes = [];
                    if (message.displayAttributes && message.displayAttributes.length) {
                        object.displayAttributes = [];
                        for (let j = 0; j < message.displayAttributes.length; ++j)
                            object.displayAttributes[j] = $root.mtechnavi.api.blueprint.DisplayAttribute.toObject(message.displayAttributes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintDisplayAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintDisplayAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintDisplayAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintDisplayAttributesResponse;
            })();

            blueprint.CreateBlueprintAndBlueprintRevisionRequest = (function() {

                /**
                 * Properties of a CreateBlueprintAndBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICreateBlueprintAndBlueprintRevisionRequest
                 * @property {mtechnavi.api.blueprint.IBlueprint|null} [blueprint] CreateBlueprintAndBlueprintRevisionRequest blueprint
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] CreateBlueprintAndBlueprintRevisionRequest blueprintRevision
                 */

                /**
                 * Constructs a new CreateBlueprintAndBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CreateBlueprintAndBlueprintRevisionRequest.
                 * @implements ICreateBlueprintAndBlueprintRevisionRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAndBlueprintRevisionRequest=} [properties] Properties to set
                 */
                function CreateBlueprintAndBlueprintRevisionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBlueprintAndBlueprintRevisionRequest blueprint.
                 * @member {mtechnavi.api.blueprint.IBlueprint|null|undefined} blueprint
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @instance
                 */
                CreateBlueprintAndBlueprintRevisionRequest.prototype.blueprint = null;

                /**
                 * CreateBlueprintAndBlueprintRevisionRequest blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @instance
                 */
                CreateBlueprintAndBlueprintRevisionRequest.prototype.blueprintRevision = null;

                /**
                 * Verifies a CreateBlueprintAndBlueprintRevisionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBlueprintAndBlueprintRevisionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBlueprintAndBlueprintRevisionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest} CreateBlueprintAndBlueprintRevisionRequest
                 */
                CreateBlueprintAndBlueprintRevisionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest();
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.blueprint);
                    }
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBlueprintAndBlueprintRevisionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest} message CreateBlueprintAndBlueprintRevisionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBlueprintAndBlueprintRevisionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprint = null;
                        object.blueprintRevision = null;
                    }
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.blueprint, options);
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    return object;
                };

                /**
                 * Converts this CreateBlueprintAndBlueprintRevisionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAndBlueprintRevisionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBlueprintAndBlueprintRevisionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBlueprintAndBlueprintRevisionRequest;
            })();

            blueprint.ListBlueprintsRequest = (function() {

                /**
                 * Properties of a ListBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintsRequest
                 * @property {string|null} [pageToken] ListBlueprintsRequest pageToken
                 * @property {Array.<string>|null} [blueprintIds] ListBlueprintsRequest blueprintIds
                 */

                /**
                 * Constructs a new ListBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintsRequest.
                 * @implements IListBlueprintsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintsRequest=} [properties] Properties to set
                 */
                function ListBlueprintsRequest(properties) {
                    this.blueprintIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @instance
                 */
                ListBlueprintsRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintsRequest blueprintIds.
                 * @member {Array.<string>} blueprintIds
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @instance
                 */
                ListBlueprintsRequest.prototype.blueprintIds = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintIds != null && message.hasOwnProperty("blueprintIds")) {
                        if (!Array.isArray(message.blueprintIds))
                            return "blueprintIds: array expected";
                        for (let i = 0; i < message.blueprintIds.length; ++i)
                            if (!$util.isString(message.blueprintIds[i]))
                                return "blueprintIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintsRequest} ListBlueprintsRequest
                 */
                ListBlueprintsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintIds) {
                        if (!Array.isArray(object.blueprintIds))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintsRequest.blueprintIds: array expected");
                        message.blueprintIds = [];
                        for (let i = 0; i < object.blueprintIds.length; ++i)
                            message.blueprintIds[i] = String(object.blueprintIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintsRequest} message ListBlueprintsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.blueprintIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintIds && message.blueprintIds.length) {
                        object.blueprintIds = [];
                        for (let j = 0; j < message.blueprintIds.length; ++j)
                            object.blueprintIds[j] = message.blueprintIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintsRequest;
            })();

            blueprint.ListBlueprintsResponse = (function() {

                /**
                 * Properties of a ListBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintsResponse
                 * @property {Long|null} [total] ListBlueprintsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprint>|null} [items] ListBlueprintsResponse items
                 * @property {string|null} [pageToken] ListBlueprintsResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintsResponse.
                 * @implements IListBlueprintsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintsResponse=} [properties] Properties to set
                 */
                function ListBlueprintsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @instance
                 */
                ListBlueprintsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprint>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @instance
                 */
                ListBlueprintsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @instance
                 */
                ListBlueprintsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintsResponse} ListBlueprintsResponse
                 */
                ListBlueprintsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintsResponse} message ListBlueprintsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintsResponse;
            })();

            blueprint.UpdateBlueprintRequest = (function() {

                /**
                 * Properties of an UpdateBlueprintRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUpdateBlueprintRequest
                 * @property {mtechnavi.api.blueprint.IBlueprint|null} [blueprint] UpdateBlueprintRequest blueprint
                 */

                /**
                 * Constructs a new UpdateBlueprintRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UpdateBlueprintRequest.
                 * @implements IUpdateBlueprintRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRequest=} [properties] Properties to set
                 */
                function UpdateBlueprintRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBlueprintRequest blueprint.
                 * @member {mtechnavi.api.blueprint.IBlueprint|null|undefined} blueprint
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRequest
                 * @instance
                 */
                UpdateBlueprintRequest.prototype.blueprint = null;

                /**
                 * Verifies an UpdateBlueprintRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBlueprintRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBlueprintRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UpdateBlueprintRequest} UpdateBlueprintRequest
                 */
                UpdateBlueprintRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UpdateBlueprintRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UpdateBlueprintRequest();
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.UpdateBlueprintRequest.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.blueprint);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBlueprintRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UpdateBlueprintRequest} message UpdateBlueprintRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBlueprintRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprint = null;
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.blueprint, options);
                    return object;
                };

                /**
                 * Converts this UpdateBlueprintRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBlueprintRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBlueprintRequest;
            })();

            blueprint.DeleteBlueprintWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteBlueprintWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IDeleteBlueprintWithRelatedItemsRequest
                 * @property {mtechnavi.api.blueprint.IBlueprint|null} [blueprint] DeleteBlueprintWithRelatedItemsRequest blueprint
                 */

                /**
                 * Constructs a new DeleteBlueprintWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a DeleteBlueprintWithRelatedItemsRequest.
                 * @implements IDeleteBlueprintWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteBlueprintWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBlueprintWithRelatedItemsRequest blueprint.
                 * @member {mtechnavi.api.blueprint.IBlueprint|null|undefined} blueprint
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest
                 * @instance
                 */
                DeleteBlueprintWithRelatedItemsRequest.prototype.blueprint = null;

                /**
                 * Verifies a DeleteBlueprintWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBlueprintWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBlueprintWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest} DeleteBlueprintWithRelatedItemsRequest
                 */
                DeleteBlueprintWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest();
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.blueprint);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBlueprintWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest} message DeleteBlueprintWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBlueprintWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprint = null;
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.blueprint, options);
                    return object;
                };

                /**
                 * Converts this DeleteBlueprintWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBlueprintWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBlueprintWithRelatedItemsRequest;
            })();

            blueprint.GetBlueprintContentRequest = (function() {

                /**
                 * Properties of a GetBlueprintContentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IGetBlueprintContentRequest
                 * @property {string|null} [blueprintId] GetBlueprintContentRequest blueprintId
                 * @property {number|null} [revision] GetBlueprintContentRequest revision
                 */

                /**
                 * Constructs a new GetBlueprintContentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a GetBlueprintContentRequest.
                 * @implements IGetBlueprintContentRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IGetBlueprintContentRequest=} [properties] Properties to set
                 */
                function GetBlueprintContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBlueprintContentRequest blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @instance
                 */
                GetBlueprintContentRequest.prototype.blueprintId = "";

                /**
                 * GetBlueprintContentRequest revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @instance
                 */
                GetBlueprintContentRequest.prototype.revision = 0;

                /**
                 * Verifies a GetBlueprintContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBlueprintContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    return null;
                };

                /**
                 * Creates a GetBlueprintContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.GetBlueprintContentRequest} GetBlueprintContentRequest
                 */
                GetBlueprintContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.GetBlueprintContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.GetBlueprintContentRequest();
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a GetBlueprintContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.GetBlueprintContentRequest} message GetBlueprintContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBlueprintContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintId = "";
                        object.revision = 0;
                    }
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    return object;
                };

                /**
                 * Converts this GetBlueprintContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.GetBlueprintContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBlueprintContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBlueprintContentRequest;
            })();

            blueprint.CreateBlueprintRevisionRequest = (function() {

                /**
                 * Properties of a CreateBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICreateBlueprintRevisionRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] CreateBlueprintRevisionRequest blueprintRevision
                 */

                /**
                 * Constructs a new CreateBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CreateBlueprintRevisionRequest.
                 * @implements ICreateBlueprintRevisionRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintRevisionRequest=} [properties] Properties to set
                 */
                function CreateBlueprintRevisionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBlueprintRevisionRequest blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintRevisionRequest
                 * @instance
                 */
                CreateBlueprintRevisionRequest.prototype.blueprintRevision = null;

                /**
                 * Verifies a CreateBlueprintRevisionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBlueprintRevisionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBlueprintRevisionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CreateBlueprintRevisionRequest} CreateBlueprintRevisionRequest
                 */
                CreateBlueprintRevisionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CreateBlueprintRevisionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CreateBlueprintRevisionRequest();
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateBlueprintRevisionRequest.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBlueprintRevisionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintRevisionRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.CreateBlueprintRevisionRequest} message CreateBlueprintRevisionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBlueprintRevisionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintRevision = null;
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    return object;
                };

                /**
                 * Converts this CreateBlueprintRevisionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintRevisionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBlueprintRevisionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBlueprintRevisionRequest;
            })();

            blueprint.ListBlueprintRevisionsRequest = (function() {

                /**
                 * Properties of a ListBlueprintRevisionsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintRevisionsRequest
                 * @property {string|null} [pageToken] ListBlueprintRevisionsRequest pageToken
                 * @property {string|null} [blueprintId] ListBlueprintRevisionsRequest blueprintId
                 * @property {number|null} [revision] ListBlueprintRevisionsRequest revision
                 */

                /**
                 * Constructs a new ListBlueprintRevisionsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintRevisionsRequest.
                 * @implements IListBlueprintRevisionsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionsRequest=} [properties] Properties to set
                 */
                function ListBlueprintRevisionsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintRevisionsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @instance
                 */
                ListBlueprintRevisionsRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintRevisionsRequest blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @instance
                 */
                ListBlueprintRevisionsRequest.prototype.blueprintId = "";

                /**
                 * ListBlueprintRevisionsRequest revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @instance
                 */
                ListBlueprintRevisionsRequest.prototype.revision = 0;

                /**
                 * Verifies a ListBlueprintRevisionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintRevisionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintRevisionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintRevisionsRequest} ListBlueprintRevisionsRequest
                 */
                ListBlueprintRevisionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintRevisionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintRevisionsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintRevisionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionsRequest} message ListBlueprintRevisionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintRevisionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.blueprintId = "";
                        object.revision = 0;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    return object;
                };

                /**
                 * Converts this ListBlueprintRevisionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintRevisionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintRevisionsRequest;
            })();

            blueprint.ListBlueprintRevisionsResponse = (function() {

                /**
                 * Properties of a ListBlueprintRevisionsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintRevisionsResponse
                 * @property {Long|null} [total] ListBlueprintRevisionsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintRevision>|null} [items] ListBlueprintRevisionsResponse items
                 * @property {string|null} [pageToken] ListBlueprintRevisionsResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintRevisionsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintRevisionsResponse.
                 * @implements IListBlueprintRevisionsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionsResponse=} [properties] Properties to set
                 */
                function ListBlueprintRevisionsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintRevisionsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @instance
                 */
                ListBlueprintRevisionsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintRevisionsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintRevision>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @instance
                 */
                ListBlueprintRevisionsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintRevisionsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @instance
                 */
                ListBlueprintRevisionsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintRevisionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintRevisionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintRevisionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintRevisionsResponse} ListBlueprintRevisionsResponse
                 */
                ListBlueprintRevisionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintRevisionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintRevisionsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintRevisionsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintRevisionsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintRevisionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionsResponse} message ListBlueprintRevisionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintRevisionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintRevisionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintRevisionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintRevisionsResponse;
            })();

            blueprint.UpdateBlueprintRevisionRequest = (function() {

                /**
                 * Properties of an UpdateBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUpdateBlueprintRevisionRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] UpdateBlueprintRevisionRequest blueprintRevision
                 */

                /**
                 * Constructs a new UpdateBlueprintRevisionRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UpdateBlueprintRevisionRequest.
                 * @implements IUpdateBlueprintRevisionRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintRevisionRequest=} [properties] Properties to set
                 */
                function UpdateBlueprintRevisionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBlueprintRevisionRequest blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest
                 * @instance
                 */
                UpdateBlueprintRevisionRequest.prototype.blueprintRevision = null;

                /**
                 * Verifies an UpdateBlueprintRevisionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBlueprintRevisionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBlueprintRevisionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest} UpdateBlueprintRevisionRequest
                 */
                UpdateBlueprintRevisionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest();
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBlueprintRevisionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest} message UpdateBlueprintRevisionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBlueprintRevisionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintRevision = null;
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    return object;
                };

                /**
                 * Converts this UpdateBlueprintRevisionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintRevisionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBlueprintRevisionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBlueprintRevisionRequest;
            })();

            blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteBlueprintRevisionWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IDeleteBlueprintRevisionWithRelatedItemsRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] DeleteBlueprintRevisionWithRelatedItemsRequest blueprintRevision
                 */

                /**
                 * Constructs a new DeleteBlueprintRevisionWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a DeleteBlueprintRevisionWithRelatedItemsRequest.
                 * @implements IDeleteBlueprintRevisionWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintRevisionWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteBlueprintRevisionWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBlueprintRevisionWithRelatedItemsRequest blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @instance
                 */
                DeleteBlueprintRevisionWithRelatedItemsRequest.prototype.blueprintRevision = null;

                /**
                 * Verifies a DeleteBlueprintRevisionWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBlueprintRevisionWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBlueprintRevisionWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest} DeleteBlueprintRevisionWithRelatedItemsRequest
                 */
                DeleteBlueprintRevisionWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest();
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBlueprintRevisionWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest} message DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBlueprintRevisionWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintRevision = null;
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    return object;
                };

                /**
                 * Converts this DeleteBlueprintRevisionWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintRevisionWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBlueprintRevisionWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBlueprintRevisionWithRelatedItemsRequest;
            })();

            blueprint.ListBlueprintRevisionContentsRequest = (function() {

                /**
                 * Properties of a ListBlueprintRevisionContentsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintRevisionContentsRequest
                 * @property {string|null} [pageToken] ListBlueprintRevisionContentsRequest pageToken
                 * @property {Array.<string>|null} [blueprintNumbers] ListBlueprintRevisionContentsRequest blueprintNumbers
                 */

                /**
                 * Constructs a new ListBlueprintRevisionContentsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintRevisionContentsRequest.
                 * @implements IListBlueprintRevisionContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionContentsRequest=} [properties] Properties to set
                 */
                function ListBlueprintRevisionContentsRequest(properties) {
                    this.blueprintNumbers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintRevisionContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @instance
                 */
                ListBlueprintRevisionContentsRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintRevisionContentsRequest blueprintNumbers.
                 * @member {Array.<string>} blueprintNumbers
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @instance
                 */
                ListBlueprintRevisionContentsRequest.prototype.blueprintNumbers = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintRevisionContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintRevisionContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintNumbers != null && message.hasOwnProperty("blueprintNumbers")) {
                        if (!Array.isArray(message.blueprintNumbers))
                            return "blueprintNumbers: array expected";
                        for (let i = 0; i < message.blueprintNumbers.length; ++i)
                            if (!$util.isString(message.blueprintNumbers[i]))
                                return "blueprintNumbers: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintRevisionContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest} ListBlueprintRevisionContentsRequest
                 */
                ListBlueprintRevisionContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintNumbers) {
                        if (!Array.isArray(object.blueprintNumbers))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest.blueprintNumbers: array expected");
                        message.blueprintNumbers = [];
                        for (let i = 0; i < object.blueprintNumbers.length; ++i)
                            message.blueprintNumbers[i] = String(object.blueprintNumbers[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintRevisionContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest} message ListBlueprintRevisionContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintRevisionContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.blueprintNumbers = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintNumbers && message.blueprintNumbers.length) {
                        object.blueprintNumbers = [];
                        for (let j = 0; j < message.blueprintNumbers.length; ++j)
                            object.blueprintNumbers[j] = message.blueprintNumbers[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintRevisionContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintRevisionContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintRevisionContentsRequest;
            })();

            blueprint.ListBlueprintRevisionContentsResponse = (function() {

                /**
                 * Properties of a ListBlueprintRevisionContentsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintRevisionContentsResponse
                 * @property {Long|null} [total] ListBlueprintRevisionContentsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintRevisionContent>|null} [items] ListBlueprintRevisionContentsResponse items
                 * @property {string|null} [pageToken] ListBlueprintRevisionContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintRevisionContentsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintRevisionContentsResponse.
                 * @implements IListBlueprintRevisionContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintRevisionContentsResponse=} [properties] Properties to set
                 */
                function ListBlueprintRevisionContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintRevisionContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @instance
                 */
                ListBlueprintRevisionContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintRevisionContentsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintRevisionContent>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @instance
                 */
                ListBlueprintRevisionContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintRevisionContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @instance
                 */
                ListBlueprintRevisionContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintRevisionContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintRevisionContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintRevisionContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintRevisionContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse} ListBlueprintRevisionContentsResponse
                 */
                ListBlueprintRevisionContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintRevisionContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintRevisionContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse} message ListBlueprintRevisionContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintRevisionContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintRevisionContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintRevisionContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintRevisionContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintRevisionContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintRevisionContentsResponse;
            })();

            blueprint.CreateBlueprintSegmentRequest = (function() {

                /**
                 * Properties of a CreateBlueprintSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICreateBlueprintSegmentRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintSegment|null} [blueprintSegment] CreateBlueprintSegmentRequest blueprintSegment
                 */

                /**
                 * Constructs a new CreateBlueprintSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CreateBlueprintSegmentRequest.
                 * @implements ICreateBlueprintSegmentRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintSegmentRequest=} [properties] Properties to set
                 */
                function CreateBlueprintSegmentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBlueprintSegmentRequest blueprintSegment.
                 * @member {mtechnavi.api.blueprint.IBlueprintSegment|null|undefined} blueprintSegment
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintSegmentRequest
                 * @instance
                 */
                CreateBlueprintSegmentRequest.prototype.blueprintSegment = null;

                /**
                 * Verifies a CreateBlueprintSegmentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintSegmentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBlueprintSegmentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintSegment.verify(message.blueprintSegment);
                        if (error)
                            return "blueprintSegment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBlueprintSegmentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintSegmentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CreateBlueprintSegmentRequest} CreateBlueprintSegmentRequest
                 */
                CreateBlueprintSegmentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CreateBlueprintSegmentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CreateBlueprintSegmentRequest();
                    if (object.blueprintSegment != null) {
                        if (typeof object.blueprintSegment !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateBlueprintSegmentRequest.blueprintSegment: object expected");
                        message.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.fromObject(object.blueprintSegment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBlueprintSegmentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintSegmentRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.CreateBlueprintSegmentRequest} message CreateBlueprintSegmentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBlueprintSegmentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintSegment = null;
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment"))
                        object.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.toObject(message.blueprintSegment, options);
                    return object;
                };

                /**
                 * Converts this CreateBlueprintSegmentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintSegmentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBlueprintSegmentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBlueprintSegmentRequest;
            })();

            blueprint.ListBlueprintSegmentsRequest = (function() {

                /**
                 * Properties of a ListBlueprintSegmentsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintSegmentsRequest
                 * @property {string|null} [pageToken] ListBlueprintSegmentsRequest pageToken
                 * @property {string|null} [blueprintRevisionId] ListBlueprintSegmentsRequest blueprintRevisionId
                 */

                /**
                 * Constructs a new ListBlueprintSegmentsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintSegmentsRequest.
                 * @implements IListBlueprintSegmentsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintSegmentsRequest=} [properties] Properties to set
                 */
                function ListBlueprintSegmentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintSegmentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @instance
                 */
                ListBlueprintSegmentsRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintSegmentsRequest blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @instance
                 */
                ListBlueprintSegmentsRequest.prototype.blueprintRevisionId = "";

                /**
                 * Verifies a ListBlueprintSegmentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintSegmentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintSegmentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintSegmentsRequest} ListBlueprintSegmentsRequest
                 */
                ListBlueprintSegmentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintSegmentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintSegmentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintSegmentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintSegmentsRequest} message ListBlueprintSegmentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintSegmentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.blueprintRevisionId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    return object;
                };

                /**
                 * Converts this ListBlueprintSegmentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintSegmentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintSegmentsRequest;
            })();

            blueprint.ListBlueprintSegmentsResponse = (function() {

                /**
                 * Properties of a ListBlueprintSegmentsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintSegmentsResponse
                 * @property {Long|null} [total] ListBlueprintSegmentsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintSegment>|null} [items] ListBlueprintSegmentsResponse items
                 * @property {string|null} [pageToken] ListBlueprintSegmentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintSegmentsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintSegmentsResponse.
                 * @implements IListBlueprintSegmentsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintSegmentsResponse=} [properties] Properties to set
                 */
                function ListBlueprintSegmentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintSegmentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @instance
                 */
                ListBlueprintSegmentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintSegmentsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintSegment>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @instance
                 */
                ListBlueprintSegmentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintSegmentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @instance
                 */
                ListBlueprintSegmentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintSegmentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintSegmentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintSegment.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintSegmentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintSegmentsResponse} ListBlueprintSegmentsResponse
                 */
                ListBlueprintSegmentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintSegmentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintSegmentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintSegmentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintSegmentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintSegment.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintSegmentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintSegmentsResponse} message ListBlueprintSegmentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintSegmentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintSegment.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintSegmentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintSegmentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintSegmentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintSegmentsResponse;
            })();

            blueprint.UpdateBlueprintSegmentRequest = (function() {

                /**
                 * Properties of an UpdateBlueprintSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUpdateBlueprintSegmentRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintSegment|null} [blueprintSegment] UpdateBlueprintSegmentRequest blueprintSegment
                 */

                /**
                 * Constructs a new UpdateBlueprintSegmentRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UpdateBlueprintSegmentRequest.
                 * @implements IUpdateBlueprintSegmentRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintSegmentRequest=} [properties] Properties to set
                 */
                function UpdateBlueprintSegmentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBlueprintSegmentRequest blueprintSegment.
                 * @member {mtechnavi.api.blueprint.IBlueprintSegment|null|undefined} blueprintSegment
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest
                 * @instance
                 */
                UpdateBlueprintSegmentRequest.prototype.blueprintSegment = null;

                /**
                 * Verifies an UpdateBlueprintSegmentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBlueprintSegmentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintSegment.verify(message.blueprintSegment);
                        if (error)
                            return "blueprintSegment." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBlueprintSegmentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest} UpdateBlueprintSegmentRequest
                 */
                UpdateBlueprintSegmentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest();
                    if (object.blueprintSegment != null) {
                        if (typeof object.blueprintSegment !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest.blueprintSegment: object expected");
                        message.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.fromObject(object.blueprintSegment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBlueprintSegmentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest} message UpdateBlueprintSegmentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBlueprintSegmentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintSegment = null;
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment"))
                        object.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.toObject(message.blueprintSegment, options);
                    return object;
                };

                /**
                 * Converts this UpdateBlueprintSegmentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintSegmentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBlueprintSegmentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBlueprintSegmentRequest;
            })();

            blueprint.CreateBlueprintAttributeRequest = (function() {

                /**
                 * Properties of a CreateBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICreateBlueprintAttributeRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintAttribute|null} [blueprintAttribute] CreateBlueprintAttributeRequest blueprintAttribute
                 */

                /**
                 * Constructs a new CreateBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CreateBlueprintAttributeRequest.
                 * @implements ICreateBlueprintAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICreateBlueprintAttributeRequest=} [properties] Properties to set
                 */
                function CreateBlueprintAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBlueprintAttributeRequest blueprintAttribute.
                 * @member {mtechnavi.api.blueprint.IBlueprintAttribute|null|undefined} blueprintAttribute
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAttributeRequest
                 * @instance
                 */
                CreateBlueprintAttributeRequest.prototype.blueprintAttribute = null;

                /**
                 * Verifies a CreateBlueprintAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBlueprintAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttribute);
                        if (error)
                            return "blueprintAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBlueprintAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CreateBlueprintAttributeRequest} CreateBlueprintAttributeRequest
                 */
                CreateBlueprintAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CreateBlueprintAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CreateBlueprintAttributeRequest();
                    if (object.blueprintAttribute != null) {
                        if (typeof object.blueprintAttribute !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.CreateBlueprintAttributeRequest.blueprintAttribute: object expected");
                        message.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBlueprintAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAttributeRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.CreateBlueprintAttributeRequest} message CreateBlueprintAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBlueprintAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintAttribute = null;
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute"))
                        object.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateBlueprintAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CreateBlueprintAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBlueprintAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBlueprintAttributeRequest;
            })();

            blueprint.ListBlueprintAttributesRequest = (function() {

                /**
                 * Properties of a ListBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintAttributesRequest
                 * @property {string|null} [pageToken] ListBlueprintAttributesRequest pageToken
                 * @property {string|null} [blueprintRevisionId] ListBlueprintAttributesRequest blueprintRevisionId
                 */

                /**
                 * Constructs a new ListBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintAttributesRequest.
                 * @implements IListBlueprintAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributesRequest=} [properties] Properties to set
                 */
                function ListBlueprintAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @instance
                 */
                ListBlueprintAttributesRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintAttributesRequest blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @instance
                 */
                ListBlueprintAttributesRequest.prototype.blueprintRevisionId = "";

                /**
                 * Verifies a ListBlueprintAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintAttributesRequest} ListBlueprintAttributesRequest
                 */
                ListBlueprintAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributesRequest} message ListBlueprintAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.blueprintRevisionId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    return object;
                };

                /**
                 * Converts this ListBlueprintAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintAttributesRequest;
            })();

            blueprint.ListBlueprintAttributesResponse = (function() {

                /**
                 * Properties of a ListBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintAttributesResponse
                 * @property {Long|null} [total] ListBlueprintAttributesResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>|null} [items] ListBlueprintAttributesResponse items
                 * @property {string|null} [pageToken] ListBlueprintAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintAttributesResponse.
                 * @implements IListBlueprintAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributesResponse=} [properties] Properties to set
                 */
                function ListBlueprintAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @instance
                 */
                ListBlueprintAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintAttributesResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @instance
                 */
                ListBlueprintAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @instance
                 */
                ListBlueprintAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintAttributesResponse} ListBlueprintAttributesResponse
                 */
                ListBlueprintAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributesResponse} message ListBlueprintAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintAttributesResponse;
            })();

            blueprint.UpdateBlueprintAttributeRequest = (function() {

                /**
                 * Properties of an UpdateBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUpdateBlueprintAttributeRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintAttribute|null} [blueprintAttribute] UpdateBlueprintAttributeRequest blueprintAttribute
                 */

                /**
                 * Constructs a new UpdateBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UpdateBlueprintAttributeRequest.
                 * @implements IUpdateBlueprintAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUpdateBlueprintAttributeRequest=} [properties] Properties to set
                 */
                function UpdateBlueprintAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBlueprintAttributeRequest blueprintAttribute.
                 * @member {mtechnavi.api.blueprint.IBlueprintAttribute|null|undefined} blueprintAttribute
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest
                 * @instance
                 */
                UpdateBlueprintAttributeRequest.prototype.blueprintAttribute = null;

                /**
                 * Verifies an UpdateBlueprintAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBlueprintAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttribute);
                        if (error)
                            return "blueprintAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBlueprintAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest} UpdateBlueprintAttributeRequest
                 */
                UpdateBlueprintAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest();
                    if (object.blueprintAttribute != null) {
                        if (typeof object.blueprintAttribute !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest.blueprintAttribute: object expected");
                        message.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBlueprintAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest} message UpdateBlueprintAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBlueprintAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintAttribute = null;
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute"))
                        object.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateBlueprintAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UpdateBlueprintAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBlueprintAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBlueprintAttributeRequest;
            })();

            blueprint.BulkWriteBlueprintAttributesRequest = (function() {

                /**
                 * Properties of a BulkWriteBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBulkWriteBlueprintAttributesRequest
                 * @property {string|null} [blueprintRevisionId] BulkWriteBlueprintAttributesRequest blueprintRevisionId
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>|null} [blueprintAttributes] BulkWriteBlueprintAttributesRequest blueprintAttributes
                 */

                /**
                 * Constructs a new BulkWriteBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BulkWriteBlueprintAttributesRequest.
                 * @implements IBulkWriteBlueprintAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBulkWriteBlueprintAttributesRequest=} [properties] Properties to set
                 */
                function BulkWriteBlueprintAttributesRequest(properties) {
                    this.blueprintAttributes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteBlueprintAttributesRequest blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @instance
                 */
                BulkWriteBlueprintAttributesRequest.prototype.blueprintRevisionId = "";

                /**
                 * BulkWriteBlueprintAttributesRequest blueprintAttributes.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>} blueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @instance
                 */
                BulkWriteBlueprintAttributesRequest.prototype.blueprintAttributes = $util.emptyArray;

                /**
                 * Verifies a BulkWriteBlueprintAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteBlueprintAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    if (message.blueprintAttributes != null && message.hasOwnProperty("blueprintAttributes")) {
                        if (!Array.isArray(message.blueprintAttributes))
                            return "blueprintAttributes: array expected";
                        for (let i = 0; i < message.blueprintAttributes.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttributes[i]);
                            if (error)
                                return "blueprintAttributes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteBlueprintAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest} BulkWriteBlueprintAttributesRequest
                 */
                BulkWriteBlueprintAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest();
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    if (object.blueprintAttributes) {
                        if (!Array.isArray(object.blueprintAttributes))
                            throw TypeError(".mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest.blueprintAttributes: array expected");
                        message.blueprintAttributes = [];
                        for (let i = 0; i < object.blueprintAttributes.length; ++i) {
                            if (typeof object.blueprintAttributes[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest.blueprintAttributes: object expected");
                            message.blueprintAttributes[i] = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttributes[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteBlueprintAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest} message BulkWriteBlueprintAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteBlueprintAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.blueprintAttributes = [];
                    if (options.defaults)
                        object.blueprintRevisionId = "";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    if (message.blueprintAttributes && message.blueprintAttributes.length) {
                        object.blueprintAttributes = [];
                        for (let j = 0; j < message.blueprintAttributes.length; ++j)
                            object.blueprintAttributes[j] = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttributes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteBlueprintAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteBlueprintAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteBlueprintAttributesRequest;
            })();

            blueprint.BulkWriteBlueprintAttributesResponse = (function() {

                /**
                 * Properties of a BulkWriteBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBulkWriteBlueprintAttributesResponse
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>|null} [blueprintAttributes] BulkWriteBlueprintAttributesResponse blueprintAttributes
                 */

                /**
                 * Constructs a new BulkWriteBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BulkWriteBlueprintAttributesResponse.
                 * @implements IBulkWriteBlueprintAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBulkWriteBlueprintAttributesResponse=} [properties] Properties to set
                 */
                function BulkWriteBlueprintAttributesResponse(properties) {
                    this.blueprintAttributes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteBlueprintAttributesResponse blueprintAttributes.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>} blueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse
                 * @instance
                 */
                BulkWriteBlueprintAttributesResponse.prototype.blueprintAttributes = $util.emptyArray;

                /**
                 * Verifies a BulkWriteBlueprintAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteBlueprintAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintAttributes != null && message.hasOwnProperty("blueprintAttributes")) {
                        if (!Array.isArray(message.blueprintAttributes))
                            return "blueprintAttributes: array expected";
                        for (let i = 0; i < message.blueprintAttributes.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttributes[i]);
                            if (error)
                                return "blueprintAttributes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteBlueprintAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse} BulkWriteBlueprintAttributesResponse
                 */
                BulkWriteBlueprintAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse();
                    if (object.blueprintAttributes) {
                        if (!Array.isArray(object.blueprintAttributes))
                            throw TypeError(".mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse.blueprintAttributes: array expected");
                        message.blueprintAttributes = [];
                        for (let i = 0; i < object.blueprintAttributes.length; ++i) {
                            if (typeof object.blueprintAttributes[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse.blueprintAttributes: object expected");
                            message.blueprintAttributes[i] = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttributes[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteBlueprintAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse} message BulkWriteBlueprintAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteBlueprintAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.blueprintAttributes = [];
                    if (message.blueprintAttributes && message.blueprintAttributes.length) {
                        object.blueprintAttributes = [];
                        for (let j = 0; j < message.blueprintAttributes.length; ++j)
                            object.blueprintAttributes[j] = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttributes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteBlueprintAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BulkWriteBlueprintAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteBlueprintAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteBlueprintAttributesResponse;
            })();

            blueprint.DeleteBlueprintAttributeRequest = (function() {

                /**
                 * Properties of a DeleteBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IDeleteBlueprintAttributeRequest
                 * @property {mtechnavi.api.blueprint.IBlueprintAttribute|null} [blueprintAttribute] DeleteBlueprintAttributeRequest blueprintAttribute
                 */

                /**
                 * Constructs a new DeleteBlueprintAttributeRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a DeleteBlueprintAttributeRequest.
                 * @implements IDeleteBlueprintAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IDeleteBlueprintAttributeRequest=} [properties] Properties to set
                 */
                function DeleteBlueprintAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBlueprintAttributeRequest blueprintAttribute.
                 * @member {mtechnavi.api.blueprint.IBlueprintAttribute|null|undefined} blueprintAttribute
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest
                 * @instance
                 */
                DeleteBlueprintAttributeRequest.prototype.blueprintAttribute = null;

                /**
                 * Verifies a DeleteBlueprintAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBlueprintAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttribute);
                        if (error)
                            return "blueprintAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBlueprintAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest} DeleteBlueprintAttributeRequest
                 */
                DeleteBlueprintAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest();
                    if (object.blueprintAttribute != null) {
                        if (typeof object.blueprintAttribute !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest.blueprintAttribute: object expected");
                        message.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBlueprintAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest} message DeleteBlueprintAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBlueprintAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintAttribute = null;
                    if (message.blueprintAttribute != null && message.hasOwnProperty("blueprintAttribute"))
                        object.blueprintAttribute = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteBlueprintAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.DeleteBlueprintAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBlueprintAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBlueprintAttributeRequest;
            })();

            blueprint.ListBlueprintLinkItemsRequest = (function() {

                /**
                 * Properties of a ListBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintLinkItemsRequest
                 * @property {string|null} [pageToken] ListBlueprintLinkItemsRequest pageToken
                 * @property {string|null} [blueprintId] ListBlueprintLinkItemsRequest blueprintId
                 */

                /**
                 * Constructs a new ListBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintLinkItemsRequest.
                 * @implements IListBlueprintLinkItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemsRequest=} [properties] Properties to set
                 */
                function ListBlueprintLinkItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintLinkItemsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @instance
                 */
                ListBlueprintLinkItemsRequest.prototype.pageToken = "";

                /**
                 * ListBlueprintLinkItemsRequest blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @instance
                 */
                ListBlueprintLinkItemsRequest.prototype.blueprintId = "";

                /**
                 * Verifies a ListBlueprintLinkItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintLinkItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintLinkItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest} ListBlueprintLinkItemsRequest
                 */
                ListBlueprintLinkItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintLinkItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest} message ListBlueprintLinkItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintLinkItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.blueprintId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    return object;
                };

                /**
                 * Converts this ListBlueprintLinkItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintLinkItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintLinkItemsRequest;
            })();

            blueprint.ListBlueprintLinkItemsResponse = (function() {

                /**
                 * Properties of a ListBlueprintLinkItemsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintLinkItemsResponse
                 * @property {Long|null} [total] ListBlueprintLinkItemsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintLinkItem>|null} [items] ListBlueprintLinkItemsResponse items
                 * @property {string|null} [pageToken] ListBlueprintLinkItemsResponse pageToken
                 */

                /**
                 * Constructs a new ListBlueprintLinkItemsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintLinkItemsResponse.
                 * @implements IListBlueprintLinkItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemsResponse=} [properties] Properties to set
                 */
                function ListBlueprintLinkItemsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintLinkItemsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @instance
                 */
                ListBlueprintLinkItemsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintLinkItemsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintLinkItem>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @instance
                 */
                ListBlueprintLinkItemsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBlueprintLinkItemsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @instance
                 */
                ListBlueprintLinkItemsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBlueprintLinkItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintLinkItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintLinkItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintLinkItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse} ListBlueprintLinkItemsResponse
                 */
                ListBlueprintLinkItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintLinkItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintLinkItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse} message ListBlueprintLinkItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintLinkItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintLinkItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBlueprintLinkItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintLinkItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintLinkItemsResponse;
            })();

            blueprint.ListUserDefinedViewsRequest = (function() {

                /**
                 * Properties of a ListUserDefinedViewsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListUserDefinedViewsRequest
                 */

                /**
                 * Constructs a new ListUserDefinedViewsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListUserDefinedViewsRequest.
                 * @implements IListUserDefinedViewsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListUserDefinedViewsRequest=} [properties] Properties to set
                 */
                function ListUserDefinedViewsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserDefinedViewsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserDefinedViewsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserDefinedViewsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListUserDefinedViewsRequest} ListUserDefinedViewsRequest
                 */
                ListUserDefinedViewsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListUserDefinedViewsRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListUserDefinedViewsRequest();
                };

                /**
                 * Creates a plain object from a ListUserDefinedViewsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListUserDefinedViewsRequest} message ListUserDefinedViewsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserDefinedViewsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserDefinedViewsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserDefinedViewsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserDefinedViewsRequest;
            })();

            blueprint.ListUserDefinedViewsResponse = (function() {

                /**
                 * Properties of a ListUserDefinedViewsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListUserDefinedViewsResponse
                 * @property {Array.<mtechnavi.api.blueprint.IUserDefinedView>|null} [userDefinedViews] ListUserDefinedViewsResponse userDefinedViews
                 */

                /**
                 * Constructs a new ListUserDefinedViewsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListUserDefinedViewsResponse.
                 * @implements IListUserDefinedViewsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListUserDefinedViewsResponse=} [properties] Properties to set
                 */
                function ListUserDefinedViewsResponse(properties) {
                    this.userDefinedViews = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserDefinedViewsResponse userDefinedViews.
                 * @member {Array.<mtechnavi.api.blueprint.IUserDefinedView>} userDefinedViews
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsResponse
                 * @instance
                 */
                ListUserDefinedViewsResponse.prototype.userDefinedViews = $util.emptyArray;

                /**
                 * Verifies a ListUserDefinedViewsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserDefinedViewsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userDefinedViews != null && message.hasOwnProperty("userDefinedViews")) {
                        if (!Array.isArray(message.userDefinedViews))
                            return "userDefinedViews: array expected";
                        for (let i = 0; i < message.userDefinedViews.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.UserDefinedView.verify(message.userDefinedViews[i]);
                            if (error)
                                return "userDefinedViews." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserDefinedViewsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListUserDefinedViewsResponse} ListUserDefinedViewsResponse
                 */
                ListUserDefinedViewsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListUserDefinedViewsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListUserDefinedViewsResponse();
                    if (object.userDefinedViews) {
                        if (!Array.isArray(object.userDefinedViews))
                            throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedViewsResponse.userDefinedViews: array expected");
                        message.userDefinedViews = [];
                        for (let i = 0; i < object.userDefinedViews.length; ++i) {
                            if (typeof object.userDefinedViews[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedViewsResponse.userDefinedViews: object expected");
                            message.userDefinedViews[i] = $root.mtechnavi.api.blueprint.UserDefinedView.fromObject(object.userDefinedViews[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserDefinedViewsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListUserDefinedViewsResponse} message ListUserDefinedViewsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserDefinedViewsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.userDefinedViews = [];
                    if (message.userDefinedViews && message.userDefinedViews.length) {
                        object.userDefinedViews = [];
                        for (let j = 0; j < message.userDefinedViews.length; ++j)
                            object.userDefinedViews[j] = $root.mtechnavi.api.blueprint.UserDefinedView.toObject(message.userDefinedViews[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserDefinedViewsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedViewsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserDefinedViewsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserDefinedViewsResponse;
            })();

            blueprint.ListUserDefinedDatasRequest = (function() {

                /**
                 * Properties of a ListUserDefinedDatasRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListUserDefinedDatasRequest
                 * @property {string|null} [viewName] ListUserDefinedDatasRequest viewName
                 * @property {string|null} [blueprintNumber] ListUserDefinedDatasRequest blueprintNumber
                 */

                /**
                 * Constructs a new ListUserDefinedDatasRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListUserDefinedDatasRequest.
                 * @implements IListUserDefinedDatasRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListUserDefinedDatasRequest=} [properties] Properties to set
                 */
                function ListUserDefinedDatasRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserDefinedDatasRequest viewName.
                 * @member {string} viewName
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @instance
                 */
                ListUserDefinedDatasRequest.prototype.viewName = "";

                /**
                 * ListUserDefinedDatasRequest blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @instance
                 */
                ListUserDefinedDatasRequest.prototype.blueprintNumber = "";

                /**
                 * Verifies a ListUserDefinedDatasRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserDefinedDatasRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.viewName != null && message.hasOwnProperty("viewName"))
                        if (!$util.isString(message.viewName))
                            return "viewName: string expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserDefinedDatasRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListUserDefinedDatasRequest} ListUserDefinedDatasRequest
                 */
                ListUserDefinedDatasRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListUserDefinedDatasRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListUserDefinedDatasRequest();
                    if (object.viewName != null)
                        message.viewName = String(object.viewName);
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserDefinedDatasRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListUserDefinedDatasRequest} message ListUserDefinedDatasRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserDefinedDatasRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.viewName = "";
                        object.blueprintNumber = "";
                    }
                    if (message.viewName != null && message.hasOwnProperty("viewName"))
                        object.viewName = message.viewName;
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    return object;
                };

                /**
                 * Converts this ListUserDefinedDatasRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserDefinedDatasRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserDefinedDatasRequest;
            })();

            blueprint.ListUserDefinedDatasResponse = (function() {

                /**
                 * Properties of a ListUserDefinedDatasResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListUserDefinedDatasResponse
                 * @property {Array.<mtechnavi.api.blueprint.IUserDefinedHeader>|null} [headers] ListUserDefinedDatasResponse headers
                 * @property {Array.<mtechnavi.api.blueprint.IUserDefinedRecord>|null} [datas] ListUserDefinedDatasResponse datas
                 */

                /**
                 * Constructs a new ListUserDefinedDatasResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListUserDefinedDatasResponse.
                 * @implements IListUserDefinedDatasResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListUserDefinedDatasResponse=} [properties] Properties to set
                 */
                function ListUserDefinedDatasResponse(properties) {
                    this.headers = [];
                    this.datas = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserDefinedDatasResponse headers.
                 * @member {Array.<mtechnavi.api.blueprint.IUserDefinedHeader>} headers
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @instance
                 */
                ListUserDefinedDatasResponse.prototype.headers = $util.emptyArray;

                /**
                 * ListUserDefinedDatasResponse datas.
                 * @member {Array.<mtechnavi.api.blueprint.IUserDefinedRecord>} datas
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @instance
                 */
                ListUserDefinedDatasResponse.prototype.datas = $util.emptyArray;

                /**
                 * Verifies a ListUserDefinedDatasResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserDefinedDatasResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.headers != null && message.hasOwnProperty("headers")) {
                        if (!Array.isArray(message.headers))
                            return "headers: array expected";
                        for (let i = 0; i < message.headers.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.UserDefinedHeader.verify(message.headers[i]);
                            if (error)
                                return "headers." + error;
                        }
                    }
                    if (message.datas != null && message.hasOwnProperty("datas")) {
                        if (!Array.isArray(message.datas))
                            return "datas: array expected";
                        for (let i = 0; i < message.datas.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.UserDefinedRecord.verify(message.datas[i]);
                            if (error)
                                return "datas." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserDefinedDatasResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListUserDefinedDatasResponse} ListUserDefinedDatasResponse
                 */
                ListUserDefinedDatasResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListUserDefinedDatasResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListUserDefinedDatasResponse();
                    if (object.headers) {
                        if (!Array.isArray(object.headers))
                            throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedDatasResponse.headers: array expected");
                        message.headers = [];
                        for (let i = 0; i < object.headers.length; ++i) {
                            if (typeof object.headers[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedDatasResponse.headers: object expected");
                            message.headers[i] = $root.mtechnavi.api.blueprint.UserDefinedHeader.fromObject(object.headers[i]);
                        }
                    }
                    if (object.datas) {
                        if (!Array.isArray(object.datas))
                            throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedDatasResponse.datas: array expected");
                        message.datas = [];
                        for (let i = 0; i < object.datas.length; ++i) {
                            if (typeof object.datas[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListUserDefinedDatasResponse.datas: object expected");
                            message.datas[i] = $root.mtechnavi.api.blueprint.UserDefinedRecord.fromObject(object.datas[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserDefinedDatasResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListUserDefinedDatasResponse} message ListUserDefinedDatasResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserDefinedDatasResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.headers = [];
                        object.datas = [];
                    }
                    if (message.headers && message.headers.length) {
                        object.headers = [];
                        for (let j = 0; j < message.headers.length; ++j)
                            object.headers[j] = $root.mtechnavi.api.blueprint.UserDefinedHeader.toObject(message.headers[j], options);
                    }
                    if (message.datas && message.datas.length) {
                        object.datas = [];
                        for (let j = 0; j < message.datas.length; ++j)
                            object.datas[j] = $root.mtechnavi.api.blueprint.UserDefinedRecord.toObject(message.datas[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserDefinedDatasResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListUserDefinedDatasResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserDefinedDatasResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserDefinedDatasResponse;
            })();

            blueprint.ExistsBlueprintNumberRequest = (function() {

                /**
                 * Properties of an ExistsBlueprintNumberRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExistsBlueprintNumberRequest
                 * @property {string|null} [blueprintNumber] ExistsBlueprintNumberRequest blueprintNumber
                 */

                /**
                 * Constructs a new ExistsBlueprintNumberRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExistsBlueprintNumberRequest.
                 * @implements IExistsBlueprintNumberRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExistsBlueprintNumberRequest=} [properties] Properties to set
                 */
                function ExistsBlueprintNumberRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExistsBlueprintNumberRequest blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequest
                 * @instance
                 */
                ExistsBlueprintNumberRequest.prototype.blueprintNumber = "";

                /**
                 * Verifies an ExistsBlueprintNumberRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExistsBlueprintNumberRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    return null;
                };

                /**
                 * Creates an ExistsBlueprintNumberRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExistsBlueprintNumberRequest} ExistsBlueprintNumberRequest
                 */
                ExistsBlueprintNumberRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequest();
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    return message;
                };

                /**
                 * Creates a plain object from an ExistsBlueprintNumberRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExistsBlueprintNumberRequest} message ExistsBlueprintNumberRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExistsBlueprintNumberRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.blueprintNumber = "";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    return object;
                };

                /**
                 * Converts this ExistsBlueprintNumberRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExistsBlueprintNumberRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExistsBlueprintNumberRequest;
            })();

            blueprint.ExistsBlueprintNumberRequestResponse = (function() {

                /**
                 * Properties of an ExistsBlueprintNumberRequestResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExistsBlueprintNumberRequestResponse
                 * @property {boolean|null} [exists] ExistsBlueprintNumberRequestResponse exists
                 */

                /**
                 * Constructs a new ExistsBlueprintNumberRequestResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExistsBlueprintNumberRequestResponse.
                 * @implements IExistsBlueprintNumberRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExistsBlueprintNumberRequestResponse=} [properties] Properties to set
                 */
                function ExistsBlueprintNumberRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExistsBlueprintNumberRequestResponse exists.
                 * @member {boolean} exists
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse
                 * @instance
                 */
                ExistsBlueprintNumberRequestResponse.prototype.exists = false;

                /**
                 * Verifies an ExistsBlueprintNumberRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExistsBlueprintNumberRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.exists != null && message.hasOwnProperty("exists"))
                        if (typeof message.exists !== "boolean")
                            return "exists: boolean expected";
                    return null;
                };

                /**
                 * Creates an ExistsBlueprintNumberRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse} ExistsBlueprintNumberRequestResponse
                 */
                ExistsBlueprintNumberRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse();
                    if (object.exists != null)
                        message.exists = Boolean(object.exists);
                    return message;
                };

                /**
                 * Creates a plain object from an ExistsBlueprintNumberRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse} message ExistsBlueprintNumberRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExistsBlueprintNumberRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.exists = false;
                    if (message.exists != null && message.hasOwnProperty("exists"))
                        object.exists = message.exists;
                    return object;
                };

                /**
                 * Converts this ExistsBlueprintNumberRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExistsBlueprintNumberRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExistsBlueprintNumberRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExistsBlueprintNumberRequestResponse;
            })();

            /**
             * ImportBlueprintsWithBlueprintFilesMode enum.
             * @name mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode
             * @enum {number}
             * @property {number} Revision=0 Revision value
             * @property {number} Update=1 Update value
             */
            blueprint.ImportBlueprintsWithBlueprintFilesMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Revision"] = 0;
                values[valuesById[1] = "Update"] = 1;
                return values;
            })();

            blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest = (function() {

                /**
                 * Properties of an AsyncImportBlueprintsWithBlueprintFilesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IAsyncImportBlueprintsWithBlueprintFilesRequest
                 * @property {Array.<sharelib.IAttachment>|null} [attachments] AsyncImportBlueprintsWithBlueprintFilesRequest attachments
                 * @property {mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode|null} [mode] AsyncImportBlueprintsWithBlueprintFilesRequest mode
                 */

                /**
                 * Constructs a new AsyncImportBlueprintsWithBlueprintFilesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an AsyncImportBlueprintsWithBlueprintFilesRequest.
                 * @implements IAsyncImportBlueprintsWithBlueprintFilesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsWithBlueprintFilesRequest=} [properties] Properties to set
                 */
                function AsyncImportBlueprintsWithBlueprintFilesRequest(properties) {
                    this.attachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AsyncImportBlueprintsWithBlueprintFilesRequest attachments.
                 * @member {Array.<sharelib.IAttachment>} attachments
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @instance
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.prototype.attachments = $util.emptyArray;

                /**
                 * AsyncImportBlueprintsWithBlueprintFilesRequest mode.
                 * @member {mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode} mode
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @instance
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.prototype.mode = 0;

                /**
                 * Verifies an AsyncImportBlueprintsWithBlueprintFilesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attachments != null && message.hasOwnProperty("attachments")) {
                        if (!Array.isArray(message.attachments))
                            return "attachments: array expected";
                        for (let i = 0; i < message.attachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.attachments[i]);
                            if (error)
                                return "attachments." + error;
                        }
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        switch (message.mode) {
                        default:
                            return "mode: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates an AsyncImportBlueprintsWithBlueprintFilesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest} AsyncImportBlueprintsWithBlueprintFilesRequest
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest();
                    if (object.attachments) {
                        if (!Array.isArray(object.attachments))
                            throw TypeError(".mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest.attachments: array expected");
                        message.attachments = [];
                        for (let i = 0; i < object.attachments.length; ++i) {
                            if (typeof object.attachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest.attachments: object expected");
                            message.attachments[i] = $root.sharelib.Attachment.fromObject(object.attachments[i]);
                        }
                    }
                    switch (object.mode) {
                    case "Revision":
                    case 0:
                        message.mode = 0;
                        break;
                    case "Update":
                    case 1:
                        message.mode = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AsyncImportBlueprintsWithBlueprintFilesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest} message AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attachments = [];
                    if (options.defaults)
                        object.mode = options.enums === String ? "Revision" : 0;
                    if (message.attachments && message.attachments.length) {
                        object.attachments = [];
                        for (let j = 0; j < message.attachments.length; ++j)
                            object.attachments[j] = $root.sharelib.Attachment.toObject(message.attachments[j], options);
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        object.mode = options.enums === String ? $root.mtechnavi.api.blueprint.ImportBlueprintsWithBlueprintFilesMode[message.mode] : message.mode;
                    return object;
                };

                /**
                 * Converts this AsyncImportBlueprintsWithBlueprintFilesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsWithBlueprintFilesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AsyncImportBlueprintsWithBlueprintFilesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AsyncImportBlueprintsWithBlueprintFilesRequest;
            })();

            blueprint.AsyncImportBlueprintsRequest = (function() {

                /**
                 * Properties of an AsyncImportBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IAsyncImportBlueprintsRequest
                 * @property {string|null} [url] AsyncImportBlueprintsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] AsyncImportBlueprintsRequest fileFormat
                 * @property {boolean|null} [dryRun] AsyncImportBlueprintsRequest dryRun
                 */

                /**
                 * Constructs a new AsyncImportBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an AsyncImportBlueprintsRequest.
                 * @implements IAsyncImportBlueprintsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintsRequest=} [properties] Properties to set
                 */
                function AsyncImportBlueprintsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AsyncImportBlueprintsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @instance
                 */
                AsyncImportBlueprintsRequest.prototype.url = "";

                /**
                 * AsyncImportBlueprintsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @instance
                 */
                AsyncImportBlueprintsRequest.prototype.fileFormat = null;

                /**
                 * AsyncImportBlueprintsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @instance
                 */
                AsyncImportBlueprintsRequest.prototype.dryRun = false;

                /**
                 * Verifies an AsyncImportBlueprintsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AsyncImportBlueprintsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    return null;
                };

                /**
                 * Creates an AsyncImportBlueprintsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.AsyncImportBlueprintsRequest} AsyncImportBlueprintsRequest
                 */
                AsyncImportBlueprintsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.AsyncImportBlueprintsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.AsyncImportBlueprintsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.AsyncImportBlueprintsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    return message;
                };

                /**
                 * Creates a plain object from an AsyncImportBlueprintsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.AsyncImportBlueprintsRequest} message AsyncImportBlueprintsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AsyncImportBlueprintsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    return object;
                };

                /**
                 * Converts this AsyncImportBlueprintsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AsyncImportBlueprintsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AsyncImportBlueprintsRequest;
            })();

            blueprint.ExportBlueprintsRequest = (function() {

                /**
                 * Properties of an ExportBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintsRequest
                 * @property {Array.<string>|null} [ids] ExportBlueprintsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBlueprintsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBlueprintsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintsRequest.
                 * @implements IExportBlueprintsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintsRequest=} [properties] Properties to set
                 */
                function ExportBlueprintsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @instance
                 */
                ExportBlueprintsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBlueprintsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @instance
                 */
                ExportBlueprintsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBlueprintsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBlueprintsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintsRequest} ExportBlueprintsRequest
                 */
                ExportBlueprintsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintsRequest} message ExportBlueprintsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBlueprintsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintsRequest;
            })();

            blueprint.ExportBlueprintsResponse = (function() {

                /**
                 * Properties of an ExportBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintsResponse
                 * @property {string|null} [assetId] ExportBlueprintsResponse assetId
                 */

                /**
                 * Constructs a new ExportBlueprintsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintsResponse.
                 * @implements IExportBlueprintsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintsResponse=} [properties] Properties to set
                 */
                function ExportBlueprintsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsResponse
                 * @instance
                 */
                ExportBlueprintsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBlueprintsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBlueprintsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintsResponse} ExportBlueprintsResponse
                 */
                ExportBlueprintsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintsResponse} message ExportBlueprintsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBlueprintsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintsResponse;
            })();

            blueprint.ListBlueprintFormatsRequest = (function() {

                /**
                 * Properties of a ListBlueprintFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintFormatsRequest
                 */

                /**
                 * Constructs a new ListBlueprintFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintFormatsRequest.
                 * @implements IListBlueprintFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintFormatsRequest=} [properties] Properties to set
                 */
                function ListBlueprintFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBlueprintFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintFormatsRequest} ListBlueprintFormatsRequest
                 */
                ListBlueprintFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListBlueprintFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBlueprintFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintFormatsRequest} message ListBlueprintFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBlueprintFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintFormatsRequest;
            })();

            blueprint.ListBlueprintFormatsResponse = (function() {

                /**
                 * Properties of a ListBlueprintFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintFormatsResponse
                 * @property {Long|null} [total] ListBlueprintFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBlueprintFormatsResponse items
                 */

                /**
                 * Constructs a new ListBlueprintFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintFormatsResponse.
                 * @implements IListBlueprintFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintFormatsResponse=} [properties] Properties to set
                 */
                function ListBlueprintFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @instance
                 */
                ListBlueprintFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @instance
                 */
                ListBlueprintFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintFormatsResponse} ListBlueprintFormatsResponse
                 */
                ListBlueprintFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintFormatsResponse} message ListBlueprintFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintFormatsResponse;
            })();

            blueprint.AsyncImportBlueprintAttributesRequest = (function() {

                /**
                 * Properties of an AsyncImportBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IAsyncImportBlueprintAttributesRequest
                 * @property {string|null} [url] AsyncImportBlueprintAttributesRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] AsyncImportBlueprintAttributesRequest fileFormat
                 * @property {boolean|null} [dryRun] AsyncImportBlueprintAttributesRequest dryRun
                 */

                /**
                 * Constructs a new AsyncImportBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an AsyncImportBlueprintAttributesRequest.
                 * @implements IAsyncImportBlueprintAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintAttributesRequest=} [properties] Properties to set
                 */
                function AsyncImportBlueprintAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AsyncImportBlueprintAttributesRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @instance
                 */
                AsyncImportBlueprintAttributesRequest.prototype.url = "";

                /**
                 * AsyncImportBlueprintAttributesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @instance
                 */
                AsyncImportBlueprintAttributesRequest.prototype.fileFormat = null;

                /**
                 * AsyncImportBlueprintAttributesRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @instance
                 */
                AsyncImportBlueprintAttributesRequest.prototype.dryRun = false;

                /**
                 * Verifies an AsyncImportBlueprintAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AsyncImportBlueprintAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    return null;
                };

                /**
                 * Creates an AsyncImportBlueprintAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest} AsyncImportBlueprintAttributesRequest
                 */
                AsyncImportBlueprintAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    return message;
                };

                /**
                 * Creates a plain object from an AsyncImportBlueprintAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest} message AsyncImportBlueprintAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AsyncImportBlueprintAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    return object;
                };

                /**
                 * Converts this AsyncImportBlueprintAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AsyncImportBlueprintAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AsyncImportBlueprintAttributesRequest;
            })();

            blueprint.ExportBlueprintAttributesRequest = (function() {

                /**
                 * Properties of an ExportBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintAttributesRequest
                 * @property {Array.<string>|null} [ids] ExportBlueprintAttributesRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBlueprintAttributesRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBlueprintAttributesRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintAttributesRequest.
                 * @implements IExportBlueprintAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintAttributesRequest=} [properties] Properties to set
                 */
                function ExportBlueprintAttributesRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintAttributesRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @instance
                 */
                ExportBlueprintAttributesRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBlueprintAttributesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @instance
                 */
                ExportBlueprintAttributesRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBlueprintAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBlueprintAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintAttributesRequest} ExportBlueprintAttributesRequest
                 */
                ExportBlueprintAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintAttributesRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintAttributesRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintAttributesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintAttributesRequest} message ExportBlueprintAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBlueprintAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintAttributesRequest;
            })();

            blueprint.ExportBlueprintAttributesResponse = (function() {

                /**
                 * Properties of an ExportBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintAttributesResponse
                 * @property {string|null} [assetId] ExportBlueprintAttributesResponse assetId
                 */

                /**
                 * Constructs a new ExportBlueprintAttributesResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintAttributesResponse.
                 * @implements IExportBlueprintAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintAttributesResponse=} [properties] Properties to set
                 */
                function ExportBlueprintAttributesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintAttributesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesResponse
                 * @instance
                 */
                ExportBlueprintAttributesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBlueprintAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBlueprintAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintAttributesResponse} ExportBlueprintAttributesResponse
                 */
                ExportBlueprintAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintAttributesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintAttributesResponse} message ExportBlueprintAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBlueprintAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintAttributesResponse;
            })();

            blueprint.ListBlueprintAttributeFormatsRequest = (function() {

                /**
                 * Properties of a ListBlueprintAttributeFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintAttributeFormatsRequest
                 */

                /**
                 * Constructs a new ListBlueprintAttributeFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintAttributeFormatsRequest.
                 * @implements IListBlueprintAttributeFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributeFormatsRequest=} [properties] Properties to set
                 */
                function ListBlueprintAttributeFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBlueprintAttributeFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintAttributeFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintAttributeFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest} ListBlueprintAttributeFormatsRequest
                 */
                ListBlueprintAttributeFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBlueprintAttributeFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest} message ListBlueprintAttributeFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintAttributeFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBlueprintAttributeFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintAttributeFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintAttributeFormatsRequest;
            })();

            blueprint.ListBlueprintAttributeFormatsResponse = (function() {

                /**
                 * Properties of a ListBlueprintAttributeFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintAttributeFormatsResponse
                 * @property {Long|null} [total] ListBlueprintAttributeFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBlueprintAttributeFormatsResponse items
                 */

                /**
                 * Constructs a new ListBlueprintAttributeFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintAttributeFormatsResponse.
                 * @implements IListBlueprintAttributeFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintAttributeFormatsResponse=} [properties] Properties to set
                 */
                function ListBlueprintAttributeFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintAttributeFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @instance
                 */
                ListBlueprintAttributeFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintAttributeFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @instance
                 */
                ListBlueprintAttributeFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintAttributeFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintAttributeFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintAttributeFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse} ListBlueprintAttributeFormatsResponse
                 */
                ListBlueprintAttributeFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintAttributeFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse} message ListBlueprintAttributeFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintAttributeFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintAttributeFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintAttributeFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintAttributeFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintAttributeFormatsResponse;
            })();

            blueprint.AsyncImportBlueprintLinkItemsRequest = (function() {

                /**
                 * Properties of an AsyncImportBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IAsyncImportBlueprintLinkItemsRequest
                 * @property {string|null} [url] AsyncImportBlueprintLinkItemsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] AsyncImportBlueprintLinkItemsRequest fileFormat
                 * @property {boolean|null} [dryRun] AsyncImportBlueprintLinkItemsRequest dryRun
                 */

                /**
                 * Constructs a new AsyncImportBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an AsyncImportBlueprintLinkItemsRequest.
                 * @implements IAsyncImportBlueprintLinkItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IAsyncImportBlueprintLinkItemsRequest=} [properties] Properties to set
                 */
                function AsyncImportBlueprintLinkItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AsyncImportBlueprintLinkItemsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @instance
                 */
                AsyncImportBlueprintLinkItemsRequest.prototype.url = "";

                /**
                 * AsyncImportBlueprintLinkItemsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @instance
                 */
                AsyncImportBlueprintLinkItemsRequest.prototype.fileFormat = null;

                /**
                 * AsyncImportBlueprintLinkItemsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @instance
                 */
                AsyncImportBlueprintLinkItemsRequest.prototype.dryRun = false;

                /**
                 * Verifies an AsyncImportBlueprintLinkItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AsyncImportBlueprintLinkItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    return null;
                };

                /**
                 * Creates an AsyncImportBlueprintLinkItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest} AsyncImportBlueprintLinkItemsRequest
                 */
                AsyncImportBlueprintLinkItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    return message;
                };

                /**
                 * Creates a plain object from an AsyncImportBlueprintLinkItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest} message AsyncImportBlueprintLinkItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AsyncImportBlueprintLinkItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    return object;
                };

                /**
                 * Converts this AsyncImportBlueprintLinkItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.AsyncImportBlueprintLinkItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AsyncImportBlueprintLinkItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AsyncImportBlueprintLinkItemsRequest;
            })();

            blueprint.ExportBlueprintLinkItemsRequest = (function() {

                /**
                 * Properties of an ExportBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintLinkItemsRequest
                 * @property {Array.<string>|null} [ids] ExportBlueprintLinkItemsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBlueprintLinkItemsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBlueprintLinkItemsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintLinkItemsRequest.
                 * @implements IExportBlueprintLinkItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintLinkItemsRequest=} [properties] Properties to set
                 */
                function ExportBlueprintLinkItemsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintLinkItemsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @instance
                 */
                ExportBlueprintLinkItemsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBlueprintLinkItemsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @instance
                 */
                ExportBlueprintLinkItemsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBlueprintLinkItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintLinkItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBlueprintLinkItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest} ExportBlueprintLinkItemsRequest
                 */
                ExportBlueprintLinkItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintLinkItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest} message ExportBlueprintLinkItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintLinkItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBlueprintLinkItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintLinkItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintLinkItemsRequest;
            })();

            blueprint.ExportBlueprintLinkItemsResponse = (function() {

                /**
                 * Properties of an ExportBlueprintLinkItemsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IExportBlueprintLinkItemsResponse
                 * @property {string|null} [assetId] ExportBlueprintLinkItemsResponse assetId
                 */

                /**
                 * Constructs a new ExportBlueprintLinkItemsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ExportBlueprintLinkItemsResponse.
                 * @implements IExportBlueprintLinkItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IExportBlueprintLinkItemsResponse=} [properties] Properties to set
                 */
                function ExportBlueprintLinkItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBlueprintLinkItemsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse
                 * @instance
                 */
                ExportBlueprintLinkItemsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBlueprintLinkItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBlueprintLinkItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBlueprintLinkItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse} ExportBlueprintLinkItemsResponse
                 */
                ExportBlueprintLinkItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBlueprintLinkItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse} message ExportBlueprintLinkItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBlueprintLinkItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBlueprintLinkItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ExportBlueprintLinkItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBlueprintLinkItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBlueprintLinkItemsResponse;
            })();

            blueprint.ListBlueprintLinkItemFormatsRequest = (function() {

                /**
                 * Properties of a ListBlueprintLinkItemFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintLinkItemFormatsRequest
                 */

                /**
                 * Constructs a new ListBlueprintLinkItemFormatsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintLinkItemFormatsRequest.
                 * @implements IListBlueprintLinkItemFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemFormatsRequest=} [properties] Properties to set
                 */
                function ListBlueprintLinkItemFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBlueprintLinkItemFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintLinkItemFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBlueprintLinkItemFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest} ListBlueprintLinkItemFormatsRequest
                 */
                ListBlueprintLinkItemFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBlueprintLinkItemFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest} message ListBlueprintLinkItemFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintLinkItemFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBlueprintLinkItemFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintLinkItemFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintLinkItemFormatsRequest;
            })();

            blueprint.ListBlueprintLinkItemFormatsResponse = (function() {

                /**
                 * Properties of a ListBlueprintLinkItemFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IListBlueprintLinkItemFormatsResponse
                 * @property {Long|null} [total] ListBlueprintLinkItemFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBlueprintLinkItemFormatsResponse items
                 */

                /**
                 * Constructs a new ListBlueprintLinkItemFormatsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a ListBlueprintLinkItemFormatsResponse.
                 * @implements IListBlueprintLinkItemFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IListBlueprintLinkItemFormatsResponse=} [properties] Properties to set
                 */
                function ListBlueprintLinkItemFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBlueprintLinkItemFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @instance
                 */
                ListBlueprintLinkItemFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBlueprintLinkItemFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @instance
                 */
                ListBlueprintLinkItemFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBlueprintLinkItemFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBlueprintLinkItemFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBlueprintLinkItemFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse} ListBlueprintLinkItemFormatsResponse
                 */
                ListBlueprintLinkItemFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBlueprintLinkItemFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse} message ListBlueprintLinkItemFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBlueprintLinkItemFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBlueprintLinkItemFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ListBlueprintLinkItemFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBlueprintLinkItemFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBlueprintLinkItemFormatsResponse;
            })();

            blueprint.UncompletedListThreadsRequest = (function() {

                /**
                 * Properties of an UncompletedListThreadsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUncompletedListThreadsRequest
                 * @property {string|null} [pageToken] UncompletedListThreadsRequest pageToken
                 */

                /**
                 * Constructs a new UncompletedListThreadsRequest.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UncompletedListThreadsRequest.
                 * @implements IUncompletedListThreadsRequest
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUncompletedListThreadsRequest=} [properties] Properties to set
                 */
                function UncompletedListThreadsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListThreadsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsRequest
                 * @instance
                 */
                UncompletedListThreadsRequest.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListThreadsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListThreadsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListThreadsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UncompletedListThreadsRequest} UncompletedListThreadsRequest
                 */
                UncompletedListThreadsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UncompletedListThreadsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UncompletedListThreadsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListThreadsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsRequest
                 * @static
                 * @param {mtechnavi.api.blueprint.UncompletedListThreadsRequest} message UncompletedListThreadsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListThreadsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListThreadsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListThreadsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListThreadsRequest;
            })();

            blueprint.UncompletedListThreadsResponse = (function() {

                /**
                 * Properties of an UncompletedListThreadsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUncompletedListThreadsResponse
                 * @property {Long|null} [total] UncompletedListThreadsResponse total
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintRevision>|null} [items] UncompletedListThreadsResponse items
                 * @property {string|null} [pageToken] UncompletedListThreadsResponse pageToken
                 */

                /**
                 * Constructs a new UncompletedListThreadsResponse.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an UncompletedListThreadsResponse.
                 * @implements IUncompletedListThreadsResponse
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUncompletedListThreadsResponse=} [properties] Properties to set
                 */
                function UncompletedListThreadsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListThreadsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @instance
                 */
                UncompletedListThreadsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UncompletedListThreadsResponse items.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintRevision>} items
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @instance
                 */
                UncompletedListThreadsResponse.prototype.items = $util.emptyArray;

                /**
                 * UncompletedListThreadsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @instance
                 */
                UncompletedListThreadsResponse.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListThreadsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListThreadsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListThreadsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UncompletedListThreadsResponse} UncompletedListThreadsResponse
                 */
                UncompletedListThreadsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UncompletedListThreadsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UncompletedListThreadsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.blueprint.UncompletedListThreadsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.UncompletedListThreadsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListThreadsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @static
                 * @param {mtechnavi.api.blueprint.UncompletedListThreadsResponse} message UncompletedListThreadsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListThreadsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListThreadsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UncompletedListThreadsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListThreadsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListThreadsResponse;
            })();

            blueprint.TransactionUnit = (function() {

                /**
                 * Properties of a TransactionUnit.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ITransactionUnit
                 * @property {string|null} [transactionUnitId] TransactionUnit transactionUnitId
                 * @property {string|null} [transactionUnitCode] TransactionUnit transactionUnitCode
                 * @property {Object.<string,string>|null} [displayNameLang] TransactionUnit displayNameLang
                 * @property {mtechnavi.api.blueprint.IBlueprintReference|null} [blueprintReference] TransactionUnit blueprintReference
                 * @property {mtechnavi.api.blueprint.ICatalogReference|null} [catalogReference] TransactionUnit catalogReference
                 * @property {sharelib.INameOption|null} [quantityUnit] TransactionUnit quantityUnit
                 * @property {Array.<sharelib.INameOption>|null} [processs] TransactionUnit processs
                 * @property {sharelib.INameOption|null} [category1] TransactionUnit category1
                 * @property {sharelib.INameOption|null} [category2] TransactionUnit category2
                 * @property {sharelib.INameOption|null} [category3] TransactionUnit category3
                 * @property {string|null} [contactMatter] TransactionUnit contactMatter
                 * @property {sharelib.INameOption|null} [usable] TransactionUnit usable
                 * @property {Array.<string>|null} [tags] TransactionUnit tags
                 * @property {string|null} [transactionUnitNumber] TransactionUnit transactionUnitNumber
                 * @property {Long|null} [deletedAt] TransactionUnit deletedAt
                 * @property {Long|null} [createdAt] TransactionUnit createdAt
                 * @property {Long|null} [updatedAt] TransactionUnit updatedAt
                 */

                /**
                 * Constructs a new TransactionUnit.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a TransactionUnit.
                 * @implements ITransactionUnit
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ITransactionUnit=} [properties] Properties to set
                 */
                function TransactionUnit(properties) {
                    this.displayNameLang = {};
                    this.processs = [];
                    this.tags = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TransactionUnit transactionUnitId.
                 * @member {string} transactionUnitId
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.transactionUnitId = "";

                /**
                 * TransactionUnit transactionUnitCode.
                 * @member {string} transactionUnitCode
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.transactionUnitCode = "";

                /**
                 * TransactionUnit displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.displayNameLang = $util.emptyObject;

                /**
                 * TransactionUnit blueprintReference.
                 * @member {mtechnavi.api.blueprint.IBlueprintReference|null|undefined} blueprintReference
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.blueprintReference = null;

                /**
                 * TransactionUnit catalogReference.
                 * @member {mtechnavi.api.blueprint.ICatalogReference|null|undefined} catalogReference
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.catalogReference = null;

                /**
                 * TransactionUnit quantityUnit.
                 * @member {sharelib.INameOption|null|undefined} quantityUnit
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.quantityUnit = null;

                /**
                 * TransactionUnit processs.
                 * @member {Array.<sharelib.INameOption>} processs
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.processs = $util.emptyArray;

                /**
                 * TransactionUnit category1.
                 * @member {sharelib.INameOption|null|undefined} category1
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.category1 = null;

                /**
                 * TransactionUnit category2.
                 * @member {sharelib.INameOption|null|undefined} category2
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.category2 = null;

                /**
                 * TransactionUnit category3.
                 * @member {sharelib.INameOption|null|undefined} category3
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.category3 = null;

                /**
                 * TransactionUnit contactMatter.
                 * @member {string} contactMatter
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.contactMatter = "";

                /**
                 * TransactionUnit usable.
                 * @member {sharelib.INameOption|null|undefined} usable
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.usable = null;

                /**
                 * TransactionUnit tags.
                 * @member {Array.<string>} tags
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.tags = $util.emptyArray;

                /**
                 * TransactionUnit transactionUnitNumber.
                 * @member {string} transactionUnitNumber
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.transactionUnitNumber = "";

                /**
                 * TransactionUnit deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TransactionUnit createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TransactionUnit updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 */
                TransactionUnit.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a TransactionUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TransactionUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        if (!$util.isString(message.transactionUnitId))
                            return "transactionUnitId: string expected";
                    if (message.transactionUnitCode != null && message.hasOwnProperty("transactionUnitCode"))
                        if (!$util.isString(message.transactionUnitCode))
                            return "transactionUnitCode: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.blueprintReference != null && message.hasOwnProperty("blueprintReference")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintReference.verify(message.blueprintReference);
                        if (error)
                            return "blueprintReference." + error;
                    }
                    if (message.catalogReference != null && message.hasOwnProperty("catalogReference")) {
                        let error = $root.mtechnavi.api.blueprint.CatalogReference.verify(message.catalogReference);
                        if (error)
                            return "catalogReference." + error;
                    }
                    if (message.quantityUnit != null && message.hasOwnProperty("quantityUnit")) {
                        let error = $root.sharelib.NameOption.verify(message.quantityUnit);
                        if (error)
                            return "quantityUnit." + error;
                    }
                    if (message.processs != null && message.hasOwnProperty("processs")) {
                        if (!Array.isArray(message.processs))
                            return "processs: array expected";
                        for (let i = 0; i < message.processs.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.processs[i]);
                            if (error)
                                return "processs." + error;
                        }
                    }
                    if (message.category1 != null && message.hasOwnProperty("category1")) {
                        let error = $root.sharelib.NameOption.verify(message.category1);
                        if (error)
                            return "category1." + error;
                    }
                    if (message.category2 != null && message.hasOwnProperty("category2")) {
                        let error = $root.sharelib.NameOption.verify(message.category2);
                        if (error)
                            return "category2." + error;
                    }
                    if (message.category3 != null && message.hasOwnProperty("category3")) {
                        let error = $root.sharelib.NameOption.verify(message.category3);
                        if (error)
                            return "category3." + error;
                    }
                    if (message.contactMatter != null && message.hasOwnProperty("contactMatter"))
                        if (!$util.isString(message.contactMatter))
                            return "contactMatter: string expected";
                    if (message.usable != null && message.hasOwnProperty("usable")) {
                        let error = $root.sharelib.NameOption.verify(message.usable);
                        if (error)
                            return "usable." + error;
                    }
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (let i = 0; i < message.tags.length; ++i)
                            if (!$util.isString(message.tags[i]))
                                return "tags: string[] expected";
                    }
                    if (message.transactionUnitNumber != null && message.hasOwnProperty("transactionUnitNumber"))
                        if (!$util.isString(message.transactionUnitNumber))
                            return "transactionUnitNumber: string expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TransactionUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.TransactionUnit} TransactionUnit
                 */
                TransactionUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.TransactionUnit)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.TransactionUnit();
                    if (object.transactionUnitId != null)
                        message.transactionUnitId = String(object.transactionUnitId);
                    if (object.transactionUnitCode != null)
                        message.transactionUnitCode = String(object.transactionUnitCode);
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.blueprintReference != null) {
                        if (typeof object.blueprintReference !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.blueprintReference: object expected");
                        message.blueprintReference = $root.mtechnavi.api.blueprint.BlueprintReference.fromObject(object.blueprintReference);
                    }
                    if (object.catalogReference != null) {
                        if (typeof object.catalogReference !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.catalogReference: object expected");
                        message.catalogReference = $root.mtechnavi.api.blueprint.CatalogReference.fromObject(object.catalogReference);
                    }
                    if (object.quantityUnit != null) {
                        if (typeof object.quantityUnit !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.quantityUnit: object expected");
                        message.quantityUnit = $root.sharelib.NameOption.fromObject(object.quantityUnit);
                    }
                    if (object.processs) {
                        if (!Array.isArray(object.processs))
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.processs: array expected");
                        message.processs = [];
                        for (let i = 0; i < object.processs.length; ++i) {
                            if (typeof object.processs[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.processs: object expected");
                            message.processs[i] = $root.sharelib.NameOption.fromObject(object.processs[i]);
                        }
                    }
                    if (object.category1 != null) {
                        if (typeof object.category1 !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.category1: object expected");
                        message.category1 = $root.sharelib.NameOption.fromObject(object.category1);
                    }
                    if (object.category2 != null) {
                        if (typeof object.category2 !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.category2: object expected");
                        message.category2 = $root.sharelib.NameOption.fromObject(object.category2);
                    }
                    if (object.category3 != null) {
                        if (typeof object.category3 !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.category3: object expected");
                        message.category3 = $root.sharelib.NameOption.fromObject(object.category3);
                    }
                    if (object.contactMatter != null)
                        message.contactMatter = String(object.contactMatter);
                    if (object.usable != null) {
                        if (typeof object.usable !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.usable: object expected");
                        message.usable = $root.sharelib.NameOption.fromObject(object.usable);
                    }
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".mtechnavi.api.blueprint.TransactionUnit.tags: array expected");
                        message.tags = [];
                        for (let i = 0; i < object.tags.length; ++i)
                            message.tags[i] = String(object.tags[i]);
                    }
                    if (object.transactionUnitNumber != null)
                        message.transactionUnitNumber = String(object.transactionUnitNumber);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a TransactionUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @static
                 * @param {mtechnavi.api.blueprint.TransactionUnit} message TransactionUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TransactionUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.processs = [];
                        object.tags = [];
                    }
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.transactionUnitId = "";
                        object.transactionUnitCode = "";
                        object.blueprintReference = null;
                        object.catalogReference = null;
                        object.quantityUnit = null;
                        object.category1 = null;
                        object.category2 = null;
                        object.category3 = null;
                        object.contactMatter = "";
                        object.usable = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.transactionUnitNumber = "";
                    }
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        object.transactionUnitId = message.transactionUnitId;
                    if (message.transactionUnitCode != null && message.hasOwnProperty("transactionUnitCode"))
                        object.transactionUnitCode = message.transactionUnitCode;
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.blueprintReference != null && message.hasOwnProperty("blueprintReference"))
                        object.blueprintReference = $root.mtechnavi.api.blueprint.BlueprintReference.toObject(message.blueprintReference, options);
                    if (message.catalogReference != null && message.hasOwnProperty("catalogReference"))
                        object.catalogReference = $root.mtechnavi.api.blueprint.CatalogReference.toObject(message.catalogReference, options);
                    if (message.quantityUnit != null && message.hasOwnProperty("quantityUnit"))
                        object.quantityUnit = $root.sharelib.NameOption.toObject(message.quantityUnit, options);
                    if (message.processs && message.processs.length) {
                        object.processs = [];
                        for (let j = 0; j < message.processs.length; ++j)
                            object.processs[j] = $root.sharelib.NameOption.toObject(message.processs[j], options);
                    }
                    if (message.category1 != null && message.hasOwnProperty("category1"))
                        object.category1 = $root.sharelib.NameOption.toObject(message.category1, options);
                    if (message.category2 != null && message.hasOwnProperty("category2"))
                        object.category2 = $root.sharelib.NameOption.toObject(message.category2, options);
                    if (message.category3 != null && message.hasOwnProperty("category3"))
                        object.category3 = $root.sharelib.NameOption.toObject(message.category3, options);
                    if (message.contactMatter != null && message.hasOwnProperty("contactMatter"))
                        object.contactMatter = message.contactMatter;
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = $root.sharelib.NameOption.toObject(message.usable, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (let j = 0; j < message.tags.length; ++j)
                            object.tags[j] = message.tags[j];
                    }
                    if (message.transactionUnitNumber != null && message.hasOwnProperty("transactionUnitNumber"))
                        object.transactionUnitNumber = message.transactionUnitNumber;
                    return object;
                };

                /**
                 * Converts this TransactionUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.TransactionUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TransactionUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TransactionUnit;
            })();

            blueprint.Blueprint = (function() {

                /**
                 * Properties of a Blueprint.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprint
                 * @property {string|null} [blueprintId] Blueprint blueprintId
                 * @property {string|null} [blueprintNumber] Blueprint blueprintNumber
                 * @property {number|null} [revision] Blueprint revision
                 * @property {string|null} [displayName] Blueprint displayName
                 * @property {Array.<string>|null} [analysisSettingItems] Blueprint analysisSettingItems
                 * @property {sharelib.INameOption|null} [blueprintContentType] Blueprint blueprintContentType
                 * @property {sharelib.INameOption|null} [category] Blueprint category
                 * @property {boolean|null} [usable] Blueprint usable
                 * @property {mtechnavi.api.blueprint.ExtractStatus|null} [extractStatus] Blueprint extractStatus
                 * @property {Array.<string>|null} [usedSystems] Blueprint usedSystems
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] Blueprint managementOrganization
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] Blueprint createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] Blueprint updatedProperties
                 * @property {Long|null} [createdAt] Blueprint createdAt
                 * @property {Long|null} [updatedAt] Blueprint updatedAt
                 * @property {Long|null} [deletedAt] Blueprint deletedAt
                 */

                /**
                 * Constructs a new Blueprint.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a Blueprint.
                 * @implements IBlueprint
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprint=} [properties] Properties to set
                 */
                function Blueprint(properties) {
                    this.analysisSettingItems = [];
                    this.usedSystems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Blueprint blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.blueprintId = "";

                /**
                 * Blueprint blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.blueprintNumber = "";

                /**
                 * Blueprint revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.revision = 0;

                /**
                 * Blueprint displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.displayName = "";

                /**
                 * Blueprint analysisSettingItems.
                 * @member {Array.<string>} analysisSettingItems
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.analysisSettingItems = $util.emptyArray;

                /**
                 * Blueprint blueprintContentType.
                 * @member {sharelib.INameOption|null|undefined} blueprintContentType
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.blueprintContentType = null;

                /**
                 * Blueprint category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.category = null;

                /**
                 * Blueprint usable.
                 * @member {boolean} usable
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.usable = false;

                /**
                 * Blueprint extractStatus.
                 * @member {mtechnavi.api.blueprint.ExtractStatus} extractStatus
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.extractStatus = 0;

                /**
                 * Blueprint usedSystems.
                 * @member {Array.<string>} usedSystems
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.usedSystems = $util.emptyArray;

                /**
                 * Blueprint managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.managementOrganization = null;

                /**
                 * Blueprint createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.createdProperties = null;

                /**
                 * Blueprint updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.updatedProperties = null;

                /**
                 * Blueprint createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Blueprint updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Blueprint deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 */
                Blueprint.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Blueprint message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Blueprint.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.analysisSettingItems != null && message.hasOwnProperty("analysisSettingItems")) {
                        if (!Array.isArray(message.analysisSettingItems))
                            return "analysisSettingItems: array expected";
                        for (let i = 0; i < message.analysisSettingItems.length; ++i)
                            if (!$util.isString(message.analysisSettingItems[i]))
                                return "analysisSettingItems: string[] expected";
                    }
                    if (message.blueprintContentType != null && message.hasOwnProperty("blueprintContentType")) {
                        let error = $root.sharelib.NameOption.verify(message.blueprintContentType);
                        if (error)
                            return "blueprintContentType." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        if (typeof message.usable !== "boolean")
                            return "usable: boolean expected";
                    if (message.extractStatus != null && message.hasOwnProperty("extractStatus"))
                        switch (message.extractStatus) {
                        default:
                            return "extractStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.usedSystems != null && message.hasOwnProperty("usedSystems")) {
                        if (!Array.isArray(message.usedSystems))
                            return "usedSystems: array expected";
                        for (let i = 0; i < message.usedSystems.length; ++i)
                            if (!$util.isString(message.usedSystems[i]))
                                return "usedSystems: string[] expected";
                    }
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Blueprint message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.Blueprint} Blueprint
                 */
                Blueprint.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.Blueprint)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.Blueprint();
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.analysisSettingItems) {
                        if (!Array.isArray(object.analysisSettingItems))
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.analysisSettingItems: array expected");
                        message.analysisSettingItems = [];
                        for (let i = 0; i < object.analysisSettingItems.length; ++i)
                            message.analysisSettingItems[i] = String(object.analysisSettingItems[i]);
                    }
                    if (object.blueprintContentType != null) {
                        if (typeof object.blueprintContentType !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.blueprintContentType: object expected");
                        message.blueprintContentType = $root.sharelib.NameOption.fromObject(object.blueprintContentType);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.usable != null)
                        message.usable = Boolean(object.usable);
                    switch (object.extractStatus) {
                    case "ExtractStatusNone":
                    case 0:
                        message.extractStatus = 0;
                        break;
                    case "ExtractStatusProcessing":
                    case 1:
                        message.extractStatus = 1;
                        break;
                    case "ExtractStatusProcessed":
                    case 2:
                        message.extractStatus = 2;
                        break;
                    case "ExtractStatusProcessError":
                    case 3:
                        message.extractStatus = 3;
                        break;
                    }
                    if (object.usedSystems) {
                        if (!Array.isArray(object.usedSystems))
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.usedSystems: array expected");
                        message.usedSystems = [];
                        for (let i = 0; i < object.usedSystems.length; ++i)
                            message.usedSystems[i] = String(object.usedSystems[i]);
                    }
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.Blueprint.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Blueprint message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @static
                 * @param {mtechnavi.api.blueprint.Blueprint} message Blueprint
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Blueprint.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.analysisSettingItems = [];
                        object.usedSystems = [];
                    }
                    if (options.defaults) {
                        object.blueprintId = "";
                        object.blueprintNumber = "";
                        object.revision = 0;
                        object.displayName = "";
                        object.blueprintContentType = null;
                        object.category = null;
                        object.usable = false;
                        object.extractStatus = options.enums === String ? "ExtractStatusNone" : 0;
                        object.managementOrganization = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.analysisSettingItems && message.analysisSettingItems.length) {
                        object.analysisSettingItems = [];
                        for (let j = 0; j < message.analysisSettingItems.length; ++j)
                            object.analysisSettingItems[j] = message.analysisSettingItems[j];
                    }
                    if (message.blueprintContentType != null && message.hasOwnProperty("blueprintContentType"))
                        object.blueprintContentType = $root.sharelib.NameOption.toObject(message.blueprintContentType, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = message.usable;
                    if (message.extractStatus != null && message.hasOwnProperty("extractStatus"))
                        object.extractStatus = options.enums === String ? $root.mtechnavi.api.blueprint.ExtractStatus[message.extractStatus] : message.extractStatus;
                    if (message.usedSystems && message.usedSystems.length) {
                        object.usedSystems = [];
                        for (let j = 0; j < message.usedSystems.length; ++j)
                            object.usedSystems[j] = message.usedSystems[j];
                    }
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Blueprint to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.Blueprint
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Blueprint.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Blueprint;
            })();

            blueprint.BlueprintRevision = (function() {

                /**
                 * Properties of a BlueprintRevision.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintRevision
                 * @property {string|null} [blueprintRevisionId] BlueprintRevision blueprintRevisionId
                 * @property {string|null} [blueprintId] BlueprintRevision blueprintId
                 * @property {number|null} [revision] BlueprintRevision revision
                 * @property {string|null} [tag] BlueprintRevision tag
                 * @property {mtechnavi.api.blueprint.IBlueprintFile|null} [blueprintFile] BlueprintRevision blueprintFile
                 * @property {sharelib.IDatetime|null} [revisedDt] BlueprintRevision revisedDt
                 * @property {string|null} [comment] BlueprintRevision comment
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] BlueprintRevision createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BlueprintRevision updatedProperties
                 * @property {Long|null} [createdAt] BlueprintRevision createdAt
                 * @property {Long|null} [updatedAt] BlueprintRevision updatedAt
                 * @property {Long|null} [deletedAt] BlueprintRevision deletedAt
                 */

                /**
                 * Constructs a new BlueprintRevision.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintRevision.
                 * @implements IBlueprintRevision
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintRevision=} [properties] Properties to set
                 */
                function BlueprintRevision(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintRevision blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.blueprintRevisionId = "";

                /**
                 * BlueprintRevision blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.blueprintId = "";

                /**
                 * BlueprintRevision revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.revision = 0;

                /**
                 * BlueprintRevision tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.tag = "";

                /**
                 * BlueprintRevision blueprintFile.
                 * @member {mtechnavi.api.blueprint.IBlueprintFile|null|undefined} blueprintFile
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.blueprintFile = null;

                /**
                 * BlueprintRevision revisedDt.
                 * @member {sharelib.IDatetime|null|undefined} revisedDt
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.revisedDt = null;

                /**
                 * BlueprintRevision comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.comment = "";

                /**
                 * BlueprintRevision createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.createdProperties = null;

                /**
                 * BlueprintRevision updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.updatedProperties = null;

                /**
                 * BlueprintRevision createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintRevision updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintRevision deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 */
                BlueprintRevision.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BlueprintRevision message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintRevision.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.blueprintFile != null && message.hasOwnProperty("blueprintFile")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintFile.verify(message.blueprintFile);
                        if (error)
                            return "blueprintFile." + error;
                    }
                    if (message.revisedDt != null && message.hasOwnProperty("revisedDt")) {
                        let error = $root.sharelib.Datetime.verify(message.revisedDt);
                        if (error)
                            return "revisedDt." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BlueprintRevision message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintRevision} BlueprintRevision
                 */
                BlueprintRevision.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintRevision)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintRevision();
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    if (object.blueprintFile != null) {
                        if (typeof object.blueprintFile !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevision.blueprintFile: object expected");
                        message.blueprintFile = $root.mtechnavi.api.blueprint.BlueprintFile.fromObject(object.blueprintFile);
                    }
                    if (object.revisedDt != null) {
                        if (typeof object.revisedDt !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevision.revisedDt: object expected");
                        message.revisedDt = $root.sharelib.Datetime.fromObject(object.revisedDt);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevision.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevision.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintRevision message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintRevision} message BlueprintRevision
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintRevision.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintRevisionId = "";
                        object.blueprintId = "";
                        object.revision = 0;
                        object.tag = "";
                        object.blueprintFile = null;
                        object.revisedDt = null;
                        object.comment = "";
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.blueprintFile != null && message.hasOwnProperty("blueprintFile"))
                        object.blueprintFile = $root.mtechnavi.api.blueprint.BlueprintFile.toObject(message.blueprintFile, options);
                    if (message.revisedDt != null && message.hasOwnProperty("revisedDt"))
                        object.revisedDt = $root.sharelib.Datetime.toObject(message.revisedDt, options);
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BlueprintRevision to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintRevision
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintRevision.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintRevision;
            })();

            blueprint.BlueprintAttribute = (function() {

                /**
                 * Properties of a BlueprintAttribute.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintAttribute
                 * @property {string|null} [blueprintAttributeId] BlueprintAttribute blueprintAttributeId
                 * @property {string|null} [blueprintRevisionId] BlueprintAttribute blueprintRevisionId
                 * @property {string|null} [tag] BlueprintAttribute tag
                 * @property {mtechnavi.api.blueprint.IAttributeProperty|null} [attributeProperty] BlueprintAttribute attributeProperty
                 * @property {boolean|null} [extracted] BlueprintAttribute extracted
                 * @property {boolean|null} [inFile] BlueprintAttribute inFile
                 * @property {boolean|null} [fixed] BlueprintAttribute fixed
                 * @property {Long|null} [order] BlueprintAttribute order
                 * @property {Long|null} [createdAt] BlueprintAttribute createdAt
                 * @property {Long|null} [updatedAt] BlueprintAttribute updatedAt
                 * @property {Long|null} [deletedAt] BlueprintAttribute deletedAt
                 */

                /**
                 * Constructs a new BlueprintAttribute.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintAttribute.
                 * @implements IBlueprintAttribute
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintAttribute=} [properties] Properties to set
                 */
                function BlueprintAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintAttribute blueprintAttributeId.
                 * @member {string} blueprintAttributeId
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.blueprintAttributeId = "";

                /**
                 * BlueprintAttribute blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.blueprintRevisionId = "";

                /**
                 * BlueprintAttribute tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.tag = "";

                /**
                 * BlueprintAttribute attributeProperty.
                 * @member {mtechnavi.api.blueprint.IAttributeProperty|null|undefined} attributeProperty
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.attributeProperty = null;

                /**
                 * BlueprintAttribute extracted.
                 * @member {boolean} extracted
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.extracted = false;

                /**
                 * BlueprintAttribute inFile.
                 * @member {boolean} inFile
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.inFile = false;

                /**
                 * BlueprintAttribute fixed.
                 * @member {boolean} fixed
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.fixed = false;

                /**
                 * BlueprintAttribute order.
                 * @member {Long} order
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintAttribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintAttribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintAttribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 */
                BlueprintAttribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BlueprintAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintAttributeId != null && message.hasOwnProperty("blueprintAttributeId"))
                        if (!$util.isString(message.blueprintAttributeId))
                            return "blueprintAttributeId: string expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.attributeProperty != null && message.hasOwnProperty("attributeProperty")) {
                        let error = $root.mtechnavi.api.blueprint.AttributeProperty.verify(message.attributeProperty);
                        if (error)
                            return "attributeProperty." + error;
                    }
                    if (message.extracted != null && message.hasOwnProperty("extracted"))
                        if (typeof message.extracted !== "boolean")
                            return "extracted: boolean expected";
                    if (message.inFile != null && message.hasOwnProperty("inFile"))
                        if (typeof message.inFile !== "boolean")
                            return "inFile: boolean expected";
                    if (message.fixed != null && message.hasOwnProperty("fixed"))
                        if (typeof message.fixed !== "boolean")
                            return "fixed: boolean expected";
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                            return "order: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BlueprintAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintAttribute} BlueprintAttribute
                 */
                BlueprintAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintAttribute();
                    if (object.blueprintAttributeId != null)
                        message.blueprintAttributeId = String(object.blueprintAttributeId);
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    if (object.attributeProperty != null) {
                        if (typeof object.attributeProperty !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintAttribute.attributeProperty: object expected");
                        message.attributeProperty = $root.mtechnavi.api.blueprint.AttributeProperty.fromObject(object.attributeProperty);
                    }
                    if (object.extracted != null)
                        message.extracted = Boolean(object.extracted);
                    if (object.inFile != null)
                        message.inFile = Boolean(object.inFile);
                    if (object.fixed != null)
                        message.fixed = Boolean(object.fixed);
                    if (object.order != null)
                        if ($util.Long)
                            (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                        else if (typeof object.order === "string")
                            message.order = parseInt(object.order, 10);
                        else if (typeof object.order === "number")
                            message.order = object.order;
                        else if (typeof object.order === "object")
                            message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintAttribute} message BlueprintAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintAttributeId = "";
                        object.blueprintRevisionId = "";
                        object.tag = "";
                        object.attributeProperty = null;
                        object.extracted = false;
                        object.inFile = false;
                        object.fixed = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.order = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.blueprintAttributeId != null && message.hasOwnProperty("blueprintAttributeId"))
                        object.blueprintAttributeId = message.blueprintAttributeId;
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.attributeProperty != null && message.hasOwnProperty("attributeProperty"))
                        object.attributeProperty = $root.mtechnavi.api.blueprint.AttributeProperty.toObject(message.attributeProperty, options);
                    if (message.extracted != null && message.hasOwnProperty("extracted"))
                        object.extracted = message.extracted;
                    if (message.inFile != null && message.hasOwnProperty("inFile"))
                        object.inFile = message.inFile;
                    if (message.fixed != null && message.hasOwnProperty("fixed"))
                        object.fixed = message.fixed;
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (typeof message.order === "number")
                            object.order = options.longs === String ? String(message.order) : message.order;
                        else
                            object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BlueprintAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintAttribute;
            })();

            blueprint.BlueprintSegment = (function() {

                /**
                 * Properties of a BlueprintSegment.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintSegment
                 * @property {string|null} [blueprintSegmentId] BlueprintSegment blueprintSegmentId
                 * @property {string|null} [blueprintRevisionId] BlueprintSegment blueprintRevisionId
                 * @property {string|null} [tag] BlueprintSegment tag
                 * @property {mtechnavi.api.blueprint.IImageProperty|null} [imageProperty] BlueprintSegment imageProperty
                 * @property {Array.<mtechnavi.api.blueprint.ISegmentProperty>|null} [segmentPropertys] BlueprintSegment segmentPropertys
                 * @property {Long|null} [createdAt] BlueprintSegment createdAt
                 * @property {Long|null} [updatedAt] BlueprintSegment updatedAt
                 * @property {Long|null} [deletedAt] BlueprintSegment deletedAt
                 */

                /**
                 * Constructs a new BlueprintSegment.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintSegment.
                 * @implements IBlueprintSegment
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintSegment=} [properties] Properties to set
                 */
                function BlueprintSegment(properties) {
                    this.segmentPropertys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintSegment blueprintSegmentId.
                 * @member {string} blueprintSegmentId
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.blueprintSegmentId = "";

                /**
                 * BlueprintSegment blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.blueprintRevisionId = "";

                /**
                 * BlueprintSegment tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.tag = "";

                /**
                 * BlueprintSegment imageProperty.
                 * @member {mtechnavi.api.blueprint.IImageProperty|null|undefined} imageProperty
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.imageProperty = null;

                /**
                 * BlueprintSegment segmentPropertys.
                 * @member {Array.<mtechnavi.api.blueprint.ISegmentProperty>} segmentPropertys
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.segmentPropertys = $util.emptyArray;

                /**
                 * BlueprintSegment createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintSegment updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintSegment deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 */
                BlueprintSegment.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BlueprintSegment message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintSegment.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintSegmentId != null && message.hasOwnProperty("blueprintSegmentId"))
                        if (!$util.isString(message.blueprintSegmentId))
                            return "blueprintSegmentId: string expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.imageProperty != null && message.hasOwnProperty("imageProperty")) {
                        let error = $root.mtechnavi.api.blueprint.ImageProperty.verify(message.imageProperty);
                        if (error)
                            return "imageProperty." + error;
                    }
                    if (message.segmentPropertys != null && message.hasOwnProperty("segmentPropertys")) {
                        if (!Array.isArray(message.segmentPropertys))
                            return "segmentPropertys: array expected";
                        for (let i = 0; i < message.segmentPropertys.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.SegmentProperty.verify(message.segmentPropertys[i]);
                            if (error)
                                return "segmentPropertys." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BlueprintSegment message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintSegment} BlueprintSegment
                 */
                BlueprintSegment.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintSegment)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintSegment();
                    if (object.blueprintSegmentId != null)
                        message.blueprintSegmentId = String(object.blueprintSegmentId);
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    if (object.imageProperty != null) {
                        if (typeof object.imageProperty !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintSegment.imageProperty: object expected");
                        message.imageProperty = $root.mtechnavi.api.blueprint.ImageProperty.fromObject(object.imageProperty);
                    }
                    if (object.segmentPropertys) {
                        if (!Array.isArray(object.segmentPropertys))
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintSegment.segmentPropertys: array expected");
                        message.segmentPropertys = [];
                        for (let i = 0; i < object.segmentPropertys.length; ++i) {
                            if (typeof object.segmentPropertys[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.BlueprintSegment.segmentPropertys: object expected");
                            message.segmentPropertys[i] = $root.mtechnavi.api.blueprint.SegmentProperty.fromObject(object.segmentPropertys[i]);
                        }
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintSegment message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintSegment} message BlueprintSegment
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintSegment.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.segmentPropertys = [];
                    if (options.defaults) {
                        object.blueprintSegmentId = "";
                        object.blueprintRevisionId = "";
                        object.tag = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.imageProperty = null;
                    }
                    if (message.blueprintSegmentId != null && message.hasOwnProperty("blueprintSegmentId"))
                        object.blueprintSegmentId = message.blueprintSegmentId;
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.segmentPropertys && message.segmentPropertys.length) {
                        object.segmentPropertys = [];
                        for (let j = 0; j < message.segmentPropertys.length; ++j)
                            object.segmentPropertys[j] = $root.mtechnavi.api.blueprint.SegmentProperty.toObject(message.segmentPropertys[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.imageProperty != null && message.hasOwnProperty("imageProperty"))
                        object.imageProperty = $root.mtechnavi.api.blueprint.ImageProperty.toObject(message.imageProperty, options);
                    return object;
                };

                /**
                 * Converts this BlueprintSegment to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintSegment
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintSegment.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintSegment;
            })();

            blueprint.BlueprintLinkItem = (function() {

                /**
                 * Properties of a BlueprintLinkItem.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintLinkItem
                 * @property {string|null} [blueprintLinkItemId] BlueprintLinkItem blueprintLinkItemId
                 * @property {string|null} [blueprintId] BlueprintLinkItem blueprintId
                 * @property {string|null} [category] BlueprintLinkItem category
                 * @property {string|null} [displayName] BlueprintLinkItem displayName
                 * @property {string|null} [linkUrl] BlueprintLinkItem linkUrl
                 * @property {Long|null} [order] BlueprintLinkItem order
                 * @property {Long|null} [createdAt] BlueprintLinkItem createdAt
                 * @property {Long|null} [updatedAt] BlueprintLinkItem updatedAt
                 * @property {Long|null} [deletedAt] BlueprintLinkItem deletedAt
                 */

                /**
                 * Constructs a new BlueprintLinkItem.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintLinkItem.
                 * @implements IBlueprintLinkItem
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintLinkItem=} [properties] Properties to set
                 */
                function BlueprintLinkItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintLinkItem blueprintLinkItemId.
                 * @member {string} blueprintLinkItemId
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.blueprintLinkItemId = "";

                /**
                 * BlueprintLinkItem blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.blueprintId = "";

                /**
                 * BlueprintLinkItem category.
                 * @member {string} category
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.category = "";

                /**
                 * BlueprintLinkItem displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.displayName = "";

                /**
                 * BlueprintLinkItem linkUrl.
                 * @member {string} linkUrl
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.linkUrl = "";

                /**
                 * BlueprintLinkItem order.
                 * @member {Long} order
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintLinkItem createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintLinkItem updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BlueprintLinkItem deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 */
                BlueprintLinkItem.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BlueprintLinkItem message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintLinkItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintLinkItemId != null && message.hasOwnProperty("blueprintLinkItemId"))
                        if (!$util.isString(message.blueprintLinkItemId))
                            return "blueprintLinkItemId: string expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                        if (!$util.isString(message.linkUrl))
                            return "linkUrl: string expected";
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                            return "order: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BlueprintLinkItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintLinkItem} BlueprintLinkItem
                 */
                BlueprintLinkItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintLinkItem)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintLinkItem();
                    if (object.blueprintLinkItemId != null)
                        message.blueprintLinkItemId = String(object.blueprintLinkItemId);
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.linkUrl != null)
                        message.linkUrl = String(object.linkUrl);
                    if (object.order != null)
                        if ($util.Long)
                            (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                        else if (typeof object.order === "string")
                            message.order = parseInt(object.order, 10);
                        else if (typeof object.order === "number")
                            message.order = object.order;
                        else if (typeof object.order === "object")
                            message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintLinkItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintLinkItem} message BlueprintLinkItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintLinkItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintLinkItemId = "";
                        object.blueprintId = "";
                        object.category = "";
                        object.displayName = "";
                        object.linkUrl = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.order = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.blueprintLinkItemId != null && message.hasOwnProperty("blueprintLinkItemId"))
                        object.blueprintLinkItemId = message.blueprintLinkItemId;
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                        object.linkUrl = message.linkUrl;
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (typeof message.order === "number")
                            object.order = options.longs === String ? String(message.order) : message.order;
                        else
                            object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BlueprintLinkItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintLinkItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintLinkItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintLinkItem;
            })();

            blueprint.BlueprintReference = (function() {

                /**
                 * Properties of a BlueprintReference.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintReference
                 * @property {string|null} [blueprintNumber] BlueprintReference blueprintNumber
                 * @property {string|null} [branchNumber] BlueprintReference branchNumber
                 * @property {number|null} [revision] BlueprintReference revision
                 */

                /**
                 * Constructs a new BlueprintReference.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintReference.
                 * @implements IBlueprintReference
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintReference=} [properties] Properties to set
                 */
                function BlueprintReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintReference blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.blueprintNumber = "";

                /**
                 * BlueprintReference branchNumber.
                 * @member {string} branchNumber
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.branchNumber = "";

                /**
                 * BlueprintReference revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.revision = 0;

                /**
                 * Verifies a BlueprintReference message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    if (message.branchNumber != null && message.hasOwnProperty("branchNumber"))
                        if (!$util.isString(message.branchNumber))
                            return "branchNumber: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    return null;
                };

                /**
                 * Creates a BlueprintReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintReference} BlueprintReference
                 */
                BlueprintReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintReference)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintReference();
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    if (object.branchNumber != null)
                        message.branchNumber = String(object.branchNumber);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintReference} message BlueprintReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintNumber = "";
                        object.branchNumber = "";
                        object.revision = 0;
                    }
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    if (message.branchNumber != null && message.hasOwnProperty("branchNumber"))
                        object.branchNumber = message.branchNumber;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    return object;
                };

                /**
                 * Converts this BlueprintReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintReference;
            })();

            blueprint.CatalogReference = (function() {

                /**
                 * Properties of a CatalogReference.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ICatalogReference
                 * @property {string|null} [modelNumber] CatalogReference modelNumber
                 * @property {string|null} [manufacture] CatalogReference manufacture
                 */

                /**
                 * Constructs a new CatalogReference.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a CatalogReference.
                 * @implements ICatalogReference
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ICatalogReference=} [properties] Properties to set
                 */
                function CatalogReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CatalogReference modelNumber.
                 * @member {string} modelNumber
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @instance
                 */
                CatalogReference.prototype.modelNumber = "";

                /**
                 * CatalogReference manufacture.
                 * @member {string} manufacture
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @instance
                 */
                CatalogReference.prototype.manufacture = "";

                /**
                 * Verifies a CatalogReference message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CatalogReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        if (!$util.isString(message.modelNumber))
                            return "modelNumber: string expected";
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        if (!$util.isString(message.manufacture))
                            return "manufacture: string expected";
                    return null;
                };

                /**
                 * Creates a CatalogReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.CatalogReference} CatalogReference
                 */
                CatalogReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.CatalogReference)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.CatalogReference();
                    if (object.modelNumber != null)
                        message.modelNumber = String(object.modelNumber);
                    if (object.manufacture != null)
                        message.manufacture = String(object.manufacture);
                    return message;
                };

                /**
                 * Creates a plain object from a CatalogReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @static
                 * @param {mtechnavi.api.blueprint.CatalogReference} message CatalogReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CatalogReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.modelNumber = "";
                        object.manufacture = "";
                    }
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        object.modelNumber = message.modelNumber;
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        object.manufacture = message.manufacture;
                    return object;
                };

                /**
                 * Converts this CatalogReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.CatalogReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CatalogReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CatalogReference;
            })();

            /**
             * ExtractStatus enum.
             * @name mtechnavi.api.blueprint.ExtractStatus
             * @enum {number}
             * @property {number} ExtractStatusNone=0 ExtractStatusNone value
             * @property {number} ExtractStatusProcessing=1 ExtractStatusProcessing value
             * @property {number} ExtractStatusProcessed=2 ExtractStatusProcessed value
             * @property {number} ExtractStatusProcessError=3 ExtractStatusProcessError value
             */
            blueprint.ExtractStatus = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ExtractStatusNone"] = 0;
                values[valuesById[1] = "ExtractStatusProcessing"] = 1;
                values[valuesById[2] = "ExtractStatusProcessed"] = 2;
                values[valuesById[3] = "ExtractStatusProcessError"] = 3;
                return values;
            })();

            blueprint.BlueprintFile = (function() {

                /**
                 * Properties of a BlueprintFile.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintFile
                 * @property {sharelib.IAttachment|null} [attachment] BlueprintFile attachment
                 * @property {Long|null} [extractedAt] BlueprintFile extractedAt
                 */

                /**
                 * Constructs a new BlueprintFile.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintFile.
                 * @implements IBlueprintFile
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintFile=} [properties] Properties to set
                 */
                function BlueprintFile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintFile attachment.
                 * @member {sharelib.IAttachment|null|undefined} attachment
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @instance
                 */
                BlueprintFile.prototype.attachment = null;

                /**
                 * BlueprintFile extractedAt.
                 * @member {Long} extractedAt
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @instance
                 */
                BlueprintFile.prototype.extractedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BlueprintFile message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintFile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.sharelib.Attachment.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.extractedAt != null && message.hasOwnProperty("extractedAt"))
                        if (!$util.isInteger(message.extractedAt) && !(message.extractedAt && $util.isInteger(message.extractedAt.low) && $util.isInteger(message.extractedAt.high)))
                            return "extractedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BlueprintFile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintFile} BlueprintFile
                 */
                BlueprintFile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintFile)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintFile();
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintFile.attachment: object expected");
                        message.attachment = $root.sharelib.Attachment.fromObject(object.attachment);
                    }
                    if (object.extractedAt != null)
                        if ($util.Long)
                            (message.extractedAt = $util.Long.fromValue(object.extractedAt)).unsigned = false;
                        else if (typeof object.extractedAt === "string")
                            message.extractedAt = parseInt(object.extractedAt, 10);
                        else if (typeof object.extractedAt === "number")
                            message.extractedAt = object.extractedAt;
                        else if (typeof object.extractedAt === "object")
                            message.extractedAt = new $util.LongBits(object.extractedAt.low >>> 0, object.extractedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintFile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintFile} message BlueprintFile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintFile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.attachment = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.extractedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.extractedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.sharelib.Attachment.toObject(message.attachment, options);
                    if (message.extractedAt != null && message.hasOwnProperty("extractedAt"))
                        if (typeof message.extractedAt === "number")
                            object.extractedAt = options.longs === String ? String(message.extractedAt) : message.extractedAt;
                        else
                            object.extractedAt = options.longs === String ? $util.Long.prototype.toString.call(message.extractedAt) : options.longs === Number ? new $util.LongBits(message.extractedAt.low >>> 0, message.extractedAt.high >>> 0).toNumber() : message.extractedAt;
                    return object;
                };

                /**
                 * Converts this BlueprintFile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintFile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintFile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintFile;
            })();

            blueprint.AttributeProperty = (function() {

                /**
                 * Properties of an AttributeProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IAttributeProperty
                 * @property {string|null} [title] AttributeProperty title
                 * @property {string|null} [value] AttributeProperty value
                 * @property {string|null} [attributeName] AttributeProperty attributeName
                 * @property {string|null} [attributeValue] AttributeProperty attributeValue
                 */

                /**
                 * Constructs a new AttributeProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an AttributeProperty.
                 * @implements IAttributeProperty
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IAttributeProperty=} [properties] Properties to set
                 */
                function AttributeProperty(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AttributeProperty title.
                 * @member {string} title
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @instance
                 */
                AttributeProperty.prototype.title = "";

                /**
                 * AttributeProperty value.
                 * @member {string} value
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @instance
                 */
                AttributeProperty.prototype.value = "";

                /**
                 * AttributeProperty attributeName.
                 * @member {string} attributeName
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @instance
                 */
                AttributeProperty.prototype.attributeName = "";

                /**
                 * AttributeProperty attributeValue.
                 * @member {string} attributeValue
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @instance
                 */
                AttributeProperty.prototype.attributeValue = "";

                /**
                 * Verifies an AttributeProperty message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AttributeProperty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.attributeName != null && message.hasOwnProperty("attributeName"))
                        if (!$util.isString(message.attributeName))
                            return "attributeName: string expected";
                    if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                        if (!$util.isString(message.attributeValue))
                            return "attributeValue: string expected";
                    return null;
                };

                /**
                 * Creates an AttributeProperty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.AttributeProperty} AttributeProperty
                 */
                AttributeProperty.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.AttributeProperty)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.AttributeProperty();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.attributeName != null)
                        message.attributeName = String(object.attributeName);
                    if (object.attributeValue != null)
                        message.attributeValue = String(object.attributeValue);
                    return message;
                };

                /**
                 * Creates a plain object from an AttributeProperty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @static
                 * @param {mtechnavi.api.blueprint.AttributeProperty} message AttributeProperty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AttributeProperty.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.title = "";
                        object.value = "";
                        object.attributeName = "";
                        object.attributeValue = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.attributeName != null && message.hasOwnProperty("attributeName"))
                        object.attributeName = message.attributeName;
                    if (message.attributeValue != null && message.hasOwnProperty("attributeValue"))
                        object.attributeValue = message.attributeValue;
                    return object;
                };

                /**
                 * Converts this AttributeProperty to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.AttributeProperty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AttributeProperty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AttributeProperty;
            })();

            blueprint.TemporarySegmentProperty = (function() {

                /**
                 * Properties of a TemporarySegmentProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ITemporarySegmentProperty
                 * @property {string|null} [url] TemporarySegmentProperty url
                 * @property {mtechnavi.api.blueprint.ISegmentProperty|null} [segmentProperty] TemporarySegmentProperty segmentProperty
                 */

                /**
                 * Constructs a new TemporarySegmentProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a TemporarySegmentProperty.
                 * @implements ITemporarySegmentProperty
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ITemporarySegmentProperty=} [properties] Properties to set
                 */
                function TemporarySegmentProperty(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TemporarySegmentProperty url.
                 * @member {string} url
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @instance
                 */
                TemporarySegmentProperty.prototype.url = "";

                /**
                 * TemporarySegmentProperty segmentProperty.
                 * @member {mtechnavi.api.blueprint.ISegmentProperty|null|undefined} segmentProperty
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @instance
                 */
                TemporarySegmentProperty.prototype.segmentProperty = null;

                /**
                 * Verifies a TemporarySegmentProperty message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TemporarySegmentProperty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.segmentProperty != null && message.hasOwnProperty("segmentProperty")) {
                        let error = $root.mtechnavi.api.blueprint.SegmentProperty.verify(message.segmentProperty);
                        if (error)
                            return "segmentProperty." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TemporarySegmentProperty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.TemporarySegmentProperty} TemporarySegmentProperty
                 */
                TemporarySegmentProperty.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.TemporarySegmentProperty)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.TemporarySegmentProperty();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.segmentProperty != null) {
                        if (typeof object.segmentProperty !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.TemporarySegmentProperty.segmentProperty: object expected");
                        message.segmentProperty = $root.mtechnavi.api.blueprint.SegmentProperty.fromObject(object.segmentProperty);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TemporarySegmentProperty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @static
                 * @param {mtechnavi.api.blueprint.TemporarySegmentProperty} message TemporarySegmentProperty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TemporarySegmentProperty.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.segmentProperty = null;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.segmentProperty != null && message.hasOwnProperty("segmentProperty"))
                        object.segmentProperty = $root.mtechnavi.api.blueprint.SegmentProperty.toObject(message.segmentProperty, options);
                    return object;
                };

                /**
                 * Converts this TemporarySegmentProperty to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.TemporarySegmentProperty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TemporarySegmentProperty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TemporarySegmentProperty;
            })();

            blueprint.SegmentProperty = (function() {

                /**
                 * Properties of a SegmentProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ISegmentProperty
                 * @property {string|null} [segmentName] SegmentProperty segmentName
                 * @property {Long|null} [left] SegmentProperty left
                 * @property {Long|null} [top] SegmentProperty top
                 * @property {Long|null} [width] SegmentProperty width
                 * @property {Long|null} [height] SegmentProperty height
                 * @property {boolean|null} [fixed] SegmentProperty fixed
                 * @property {string|null} [assetId] SegmentProperty assetId
                 */

                /**
                 * Constructs a new SegmentProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a SegmentProperty.
                 * @implements ISegmentProperty
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ISegmentProperty=} [properties] Properties to set
                 */
                function SegmentProperty(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SegmentProperty segmentName.
                 * @member {string} segmentName
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.segmentName = "";

                /**
                 * SegmentProperty left.
                 * @member {Long} left
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.left = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SegmentProperty top.
                 * @member {Long} top
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.top = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SegmentProperty width.
                 * @member {Long} width
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SegmentProperty height.
                 * @member {Long} height
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SegmentProperty fixed.
                 * @member {boolean} fixed
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.fixed = false;

                /**
                 * SegmentProperty assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 */
                SegmentProperty.prototype.assetId = "";

                /**
                 * Verifies a SegmentProperty message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SegmentProperty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.segmentName != null && message.hasOwnProperty("segmentName"))
                        if (!$util.isString(message.segmentName))
                            return "segmentName: string expected";
                    if (message.left != null && message.hasOwnProperty("left"))
                        if (!$util.isInteger(message.left) && !(message.left && $util.isInteger(message.left.low) && $util.isInteger(message.left.high)))
                            return "left: integer|Long expected";
                    if (message.top != null && message.hasOwnProperty("top"))
                        if (!$util.isInteger(message.top) && !(message.top && $util.isInteger(message.top.low) && $util.isInteger(message.top.high)))
                            return "top: integer|Long expected";
                    if (message.width != null && message.hasOwnProperty("width"))
                        if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                            return "width: integer|Long expected";
                    if (message.height != null && message.hasOwnProperty("height"))
                        if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                            return "height: integer|Long expected";
                    if (message.fixed != null && message.hasOwnProperty("fixed"))
                        if (typeof message.fixed !== "boolean")
                            return "fixed: boolean expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates a SegmentProperty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.SegmentProperty} SegmentProperty
                 */
                SegmentProperty.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.SegmentProperty)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.SegmentProperty();
                    if (object.segmentName != null)
                        message.segmentName = String(object.segmentName);
                    if (object.left != null)
                        if ($util.Long)
                            (message.left = $util.Long.fromValue(object.left)).unsigned = false;
                        else if (typeof object.left === "string")
                            message.left = parseInt(object.left, 10);
                        else if (typeof object.left === "number")
                            message.left = object.left;
                        else if (typeof object.left === "object")
                            message.left = new $util.LongBits(object.left.low >>> 0, object.left.high >>> 0).toNumber();
                    if (object.top != null)
                        if ($util.Long)
                            (message.top = $util.Long.fromValue(object.top)).unsigned = false;
                        else if (typeof object.top === "string")
                            message.top = parseInt(object.top, 10);
                        else if (typeof object.top === "number")
                            message.top = object.top;
                        else if (typeof object.top === "object")
                            message.top = new $util.LongBits(object.top.low >>> 0, object.top.high >>> 0).toNumber();
                    if (object.width != null)
                        if ($util.Long)
                            (message.width = $util.Long.fromValue(object.width)).unsigned = false;
                        else if (typeof object.width === "string")
                            message.width = parseInt(object.width, 10);
                        else if (typeof object.width === "number")
                            message.width = object.width;
                        else if (typeof object.width === "object")
                            message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber();
                    if (object.height != null)
                        if ($util.Long)
                            (message.height = $util.Long.fromValue(object.height)).unsigned = false;
                        else if (typeof object.height === "string")
                            message.height = parseInt(object.height, 10);
                        else if (typeof object.height === "number")
                            message.height = object.height;
                        else if (typeof object.height === "object")
                            message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber();
                    if (object.fixed != null)
                        message.fixed = Boolean(object.fixed);
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from a SegmentProperty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @static
                 * @param {mtechnavi.api.blueprint.SegmentProperty} message SegmentProperty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SegmentProperty.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.segmentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.left = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.left = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.top = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.top = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.width = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.height = options.longs === String ? "0" : 0;
                        object.fixed = false;
                        object.assetId = "";
                    }
                    if (message.segmentName != null && message.hasOwnProperty("segmentName"))
                        object.segmentName = message.segmentName;
                    if (message.left != null && message.hasOwnProperty("left"))
                        if (typeof message.left === "number")
                            object.left = options.longs === String ? String(message.left) : message.left;
                        else
                            object.left = options.longs === String ? $util.Long.prototype.toString.call(message.left) : options.longs === Number ? new $util.LongBits(message.left.low >>> 0, message.left.high >>> 0).toNumber() : message.left;
                    if (message.top != null && message.hasOwnProperty("top"))
                        if (typeof message.top === "number")
                            object.top = options.longs === String ? String(message.top) : message.top;
                        else
                            object.top = options.longs === String ? $util.Long.prototype.toString.call(message.top) : options.longs === Number ? new $util.LongBits(message.top.low >>> 0, message.top.high >>> 0).toNumber() : message.top;
                    if (message.width != null && message.hasOwnProperty("width"))
                        if (typeof message.width === "number")
                            object.width = options.longs === String ? String(message.width) : message.width;
                        else
                            object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber() : message.width;
                    if (message.height != null && message.hasOwnProperty("height"))
                        if (typeof message.height === "number")
                            object.height = options.longs === String ? String(message.height) : message.height;
                        else
                            object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber() : message.height;
                    if (message.fixed != null && message.hasOwnProperty("fixed"))
                        object.fixed = message.fixed;
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this SegmentProperty to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.SegmentProperty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SegmentProperty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SegmentProperty;
            })();

            blueprint.ImageProperty = (function() {

                /**
                 * Properties of an ImageProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IImageProperty
                 * @property {string|null} [imageAssetId] ImageProperty imageAssetId
                 * @property {Long|null} [imageWidth] ImageProperty imageWidth
                 * @property {Long|null} [imageHeight] ImageProperty imageHeight
                 * @property {string|null} [thumbnailAssetId] ImageProperty thumbnailAssetId
                 */

                /**
                 * Constructs a new ImageProperty.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents an ImageProperty.
                 * @implements IImageProperty
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IImageProperty=} [properties] Properties to set
                 */
                function ImageProperty(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImageProperty imageAssetId.
                 * @member {string} imageAssetId
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.imageAssetId = "";

                /**
                 * ImageProperty imageWidth.
                 * @member {Long} imageWidth
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.imageWidth = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ImageProperty imageHeight.
                 * @member {Long} imageHeight
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.imageHeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ImageProperty thumbnailAssetId.
                 * @member {string} thumbnailAssetId
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @instance
                 */
                ImageProperty.prototype.thumbnailAssetId = "";

                /**
                 * Verifies an ImageProperty message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImageProperty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.imageAssetId != null && message.hasOwnProperty("imageAssetId"))
                        if (!$util.isString(message.imageAssetId))
                            return "imageAssetId: string expected";
                    if (message.imageWidth != null && message.hasOwnProperty("imageWidth"))
                        if (!$util.isInteger(message.imageWidth) && !(message.imageWidth && $util.isInteger(message.imageWidth.low) && $util.isInteger(message.imageWidth.high)))
                            return "imageWidth: integer|Long expected";
                    if (message.imageHeight != null && message.hasOwnProperty("imageHeight"))
                        if (!$util.isInteger(message.imageHeight) && !(message.imageHeight && $util.isInteger(message.imageHeight.low) && $util.isInteger(message.imageHeight.high)))
                            return "imageHeight: integer|Long expected";
                    if (message.thumbnailAssetId != null && message.hasOwnProperty("thumbnailAssetId"))
                        if (!$util.isString(message.thumbnailAssetId))
                            return "thumbnailAssetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImageProperty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.ImageProperty} ImageProperty
                 */
                ImageProperty.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.ImageProperty)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.ImageProperty();
                    if (object.imageAssetId != null)
                        message.imageAssetId = String(object.imageAssetId);
                    if (object.imageWidth != null)
                        if ($util.Long)
                            (message.imageWidth = $util.Long.fromValue(object.imageWidth)).unsigned = false;
                        else if (typeof object.imageWidth === "string")
                            message.imageWidth = parseInt(object.imageWidth, 10);
                        else if (typeof object.imageWidth === "number")
                            message.imageWidth = object.imageWidth;
                        else if (typeof object.imageWidth === "object")
                            message.imageWidth = new $util.LongBits(object.imageWidth.low >>> 0, object.imageWidth.high >>> 0).toNumber();
                    if (object.imageHeight != null)
                        if ($util.Long)
                            (message.imageHeight = $util.Long.fromValue(object.imageHeight)).unsigned = false;
                        else if (typeof object.imageHeight === "string")
                            message.imageHeight = parseInt(object.imageHeight, 10);
                        else if (typeof object.imageHeight === "number")
                            message.imageHeight = object.imageHeight;
                        else if (typeof object.imageHeight === "object")
                            message.imageHeight = new $util.LongBits(object.imageHeight.low >>> 0, object.imageHeight.high >>> 0).toNumber();
                    if (object.thumbnailAssetId != null)
                        message.thumbnailAssetId = String(object.thumbnailAssetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImageProperty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @static
                 * @param {mtechnavi.api.blueprint.ImageProperty} message ImageProperty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageProperty.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.imageAssetId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.imageWidth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.imageWidth = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.imageHeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.imageHeight = options.longs === String ? "0" : 0;
                        object.thumbnailAssetId = "";
                    }
                    if (message.imageAssetId != null && message.hasOwnProperty("imageAssetId"))
                        object.imageAssetId = message.imageAssetId;
                    if (message.imageWidth != null && message.hasOwnProperty("imageWidth"))
                        if (typeof message.imageWidth === "number")
                            object.imageWidth = options.longs === String ? String(message.imageWidth) : message.imageWidth;
                        else
                            object.imageWidth = options.longs === String ? $util.Long.prototype.toString.call(message.imageWidth) : options.longs === Number ? new $util.LongBits(message.imageWidth.low >>> 0, message.imageWidth.high >>> 0).toNumber() : message.imageWidth;
                    if (message.imageHeight != null && message.hasOwnProperty("imageHeight"))
                        if (typeof message.imageHeight === "number")
                            object.imageHeight = options.longs === String ? String(message.imageHeight) : message.imageHeight;
                        else
                            object.imageHeight = options.longs === String ? $util.Long.prototype.toString.call(message.imageHeight) : options.longs === Number ? new $util.LongBits(message.imageHeight.low >>> 0, message.imageHeight.high >>> 0).toNumber() : message.imageHeight;
                    if (message.thumbnailAssetId != null && message.hasOwnProperty("thumbnailAssetId"))
                        object.thumbnailAssetId = message.thumbnailAssetId;
                    return object;
                };

                /**
                 * Converts this ImageProperty to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.ImageProperty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageProperty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImageProperty;
            })();

            blueprint.UserDefinedView = (function() {

                /**
                 * Properties of a UserDefinedView.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUserDefinedView
                 * @property {string|null} [viewName] UserDefinedView viewName
                 * @property {string|null} [displayName] UserDefinedView displayName
                 */

                /**
                 * Constructs a new UserDefinedView.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a UserDefinedView.
                 * @implements IUserDefinedView
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUserDefinedView=} [properties] Properties to set
                 */
                function UserDefinedView(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserDefinedView viewName.
                 * @member {string} viewName
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @instance
                 */
                UserDefinedView.prototype.viewName = "";

                /**
                 * UserDefinedView displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @instance
                 */
                UserDefinedView.prototype.displayName = "";

                /**
                 * Verifies a UserDefinedView message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserDefinedView.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.viewName != null && message.hasOwnProperty("viewName"))
                        if (!$util.isString(message.viewName))
                            return "viewName: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a UserDefinedView message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UserDefinedView} UserDefinedView
                 */
                UserDefinedView.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UserDefinedView)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UserDefinedView();
                    if (object.viewName != null)
                        message.viewName = String(object.viewName);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a UserDefinedView message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @static
                 * @param {mtechnavi.api.blueprint.UserDefinedView} message UserDefinedView
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserDefinedView.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.viewName = "";
                        object.displayName = "";
                    }
                    if (message.viewName != null && message.hasOwnProperty("viewName"))
                        object.viewName = message.viewName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this UserDefinedView to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UserDefinedView
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserDefinedView.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserDefinedView;
            })();

            blueprint.UserDefinedHeader = (function() {

                /**
                 * Properties of a UserDefinedHeader.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUserDefinedHeader
                 * @property {string|null} [key] UserDefinedHeader key
                 * @property {string|null} [value] UserDefinedHeader value
                 */

                /**
                 * Constructs a new UserDefinedHeader.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a UserDefinedHeader.
                 * @implements IUserDefinedHeader
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUserDefinedHeader=} [properties] Properties to set
                 */
                function UserDefinedHeader(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserDefinedHeader key.
                 * @member {string} key
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @instance
                 */
                UserDefinedHeader.prototype.key = "";

                /**
                 * UserDefinedHeader value.
                 * @member {string} value
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @instance
                 */
                UserDefinedHeader.prototype.value = "";

                /**
                 * Verifies a UserDefinedHeader message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserDefinedHeader.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };

                /**
                 * Creates a UserDefinedHeader message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UserDefinedHeader} UserDefinedHeader
                 */
                UserDefinedHeader.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UserDefinedHeader)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UserDefinedHeader();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a UserDefinedHeader message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @static
                 * @param {mtechnavi.api.blueprint.UserDefinedHeader} message UserDefinedHeader
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserDefinedHeader.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.value = "";
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this UserDefinedHeader to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UserDefinedHeader
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserDefinedHeader.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserDefinedHeader;
            })();

            blueprint.UserDefinedItem = (function() {

                /**
                 * Properties of a UserDefinedItem.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUserDefinedItem
                 * @property {string|null} [key] UserDefinedItem key
                 * @property {string|null} [value] UserDefinedItem value
                 */

                /**
                 * Constructs a new UserDefinedItem.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a UserDefinedItem.
                 * @implements IUserDefinedItem
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUserDefinedItem=} [properties] Properties to set
                 */
                function UserDefinedItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserDefinedItem key.
                 * @member {string} key
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @instance
                 */
                UserDefinedItem.prototype.key = "";

                /**
                 * UserDefinedItem value.
                 * @member {string} value
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @instance
                 */
                UserDefinedItem.prototype.value = "";

                /**
                 * Verifies a UserDefinedItem message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserDefinedItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };

                /**
                 * Creates a UserDefinedItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UserDefinedItem} UserDefinedItem
                 */
                UserDefinedItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UserDefinedItem)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UserDefinedItem();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a UserDefinedItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @static
                 * @param {mtechnavi.api.blueprint.UserDefinedItem} message UserDefinedItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserDefinedItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.value = "";
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this UserDefinedItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UserDefinedItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserDefinedItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserDefinedItem;
            })();

            blueprint.UserDefinedRecord = (function() {

                /**
                 * Properties of a UserDefinedRecord.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IUserDefinedRecord
                 * @property {Array.<mtechnavi.api.blueprint.IUserDefinedItem>|null} [record] UserDefinedRecord record
                 */

                /**
                 * Constructs a new UserDefinedRecord.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a UserDefinedRecord.
                 * @implements IUserDefinedRecord
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IUserDefinedRecord=} [properties] Properties to set
                 */
                function UserDefinedRecord(properties) {
                    this.record = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserDefinedRecord record.
                 * @member {Array.<mtechnavi.api.blueprint.IUserDefinedItem>} record
                 * @memberof mtechnavi.api.blueprint.UserDefinedRecord
                 * @instance
                 */
                UserDefinedRecord.prototype.record = $util.emptyArray;

                /**
                 * Verifies a UserDefinedRecord message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.UserDefinedRecord
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserDefinedRecord.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.record != null && message.hasOwnProperty("record")) {
                        if (!Array.isArray(message.record))
                            return "record: array expected";
                        for (let i = 0; i < message.record.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.UserDefinedItem.verify(message.record[i]);
                            if (error)
                                return "record." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a UserDefinedRecord message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedRecord
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.UserDefinedRecord} UserDefinedRecord
                 */
                UserDefinedRecord.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.UserDefinedRecord)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.UserDefinedRecord();
                    if (object.record) {
                        if (!Array.isArray(object.record))
                            throw TypeError(".mtechnavi.api.blueprint.UserDefinedRecord.record: array expected");
                        message.record = [];
                        for (let i = 0; i < object.record.length; ++i) {
                            if (typeof object.record[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.UserDefinedRecord.record: object expected");
                            message.record[i] = $root.mtechnavi.api.blueprint.UserDefinedItem.fromObject(object.record[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserDefinedRecord message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.UserDefinedRecord
                 * @static
                 * @param {mtechnavi.api.blueprint.UserDefinedRecord} message UserDefinedRecord
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserDefinedRecord.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.record = [];
                    if (message.record && message.record.length) {
                        object.record = [];
                        for (let j = 0; j < message.record.length; ++j)
                            object.record[j] = $root.mtechnavi.api.blueprint.UserDefinedItem.toObject(message.record[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this UserDefinedRecord to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.UserDefinedRecord
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserDefinedRecord.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserDefinedRecord;
            })();

            blueprint.DisplayAttribute = (function() {

                /**
                 * Properties of a DisplayAttribute.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IDisplayAttribute
                 * @property {string|null} [attributeName] DisplayAttribute attributeName
                 * @property {Long|null} [order] DisplayAttribute order
                 */

                /**
                 * Constructs a new DisplayAttribute.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a DisplayAttribute.
                 * @implements IDisplayAttribute
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IDisplayAttribute=} [properties] Properties to set
                 */
                function DisplayAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisplayAttribute attributeName.
                 * @member {string} attributeName
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @instance
                 */
                DisplayAttribute.prototype.attributeName = "";

                /**
                 * DisplayAttribute order.
                 * @member {Long} order
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @instance
                 */
                DisplayAttribute.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a DisplayAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisplayAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attributeName != null && message.hasOwnProperty("attributeName"))
                        if (!$util.isString(message.attributeName))
                            return "attributeName: string expected";
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                            return "order: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a DisplayAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.DisplayAttribute} DisplayAttribute
                 */
                DisplayAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.DisplayAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.DisplayAttribute();
                    if (object.attributeName != null)
                        message.attributeName = String(object.attributeName);
                    if (object.order != null)
                        if ($util.Long)
                            (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                        else if (typeof object.order === "string")
                            message.order = parseInt(object.order, 10);
                        else if (typeof object.order === "number")
                            message.order = object.order;
                        else if (typeof object.order === "object")
                            message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a DisplayAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @static
                 * @param {mtechnavi.api.blueprint.DisplayAttribute} message DisplayAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisplayAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.attributeName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.order = options.longs === String ? "0" : 0;
                    }
                    if (message.attributeName != null && message.hasOwnProperty("attributeName"))
                        object.attributeName = message.attributeName;
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (typeof message.order === "number")
                            object.order = options.longs === String ? String(message.order) : message.order;
                        else
                            object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
                    return object;
                };

                /**
                 * Converts this DisplayAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.DisplayAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisplayAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisplayAttribute;
            })();

            blueprint.SearchBlueprintContent = (function() {

                /**
                 * Properties of a SearchBlueprintContent.
                 * @memberof mtechnavi.api.blueprint
                 * @interface ISearchBlueprintContent
                 * @property {string|null} [blueprintId] SearchBlueprintContent blueprintId
                 * @property {string|null} [blueprintRevisionId] SearchBlueprintContent blueprintRevisionId
                 * @property {string|null} [blueprintNumber] SearchBlueprintContent blueprintNumber
                 * @property {number|null} [revision] SearchBlueprintContent revision
                 * @property {string|null} [thumbnailAssetId] SearchBlueprintContent thumbnailAssetId
                 * @property {number|null} [distanceFront] SearchBlueprintContent distanceFront
                 * @property {number|null} [distanceRight] SearchBlueprintContent distanceRight
                 * @property {number|null} [distanceBottom] SearchBlueprintContent distanceBottom
                 * @property {number|null} [distanceBack] SearchBlueprintContent distanceBack
                 * @property {number|null} [distanceAverage] SearchBlueprintContent distanceAverage
                 * @property {Array.<mtechnavi.api.blueprint.SearchBlueprintContent.IAttributeValue>|null} [attributes] SearchBlueprintContent attributes
                 */

                /**
                 * Constructs a new SearchBlueprintContent.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a SearchBlueprintContent.
                 * @implements ISearchBlueprintContent
                 * @constructor
                 * @param {mtechnavi.api.blueprint.ISearchBlueprintContent=} [properties] Properties to set
                 */
                function SearchBlueprintContent(properties) {
                    this.attributes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SearchBlueprintContent blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.blueprintId = "";

                /**
                 * SearchBlueprintContent blueprintRevisionId.
                 * @member {string} blueprintRevisionId
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.blueprintRevisionId = "";

                /**
                 * SearchBlueprintContent blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.blueprintNumber = "";

                /**
                 * SearchBlueprintContent revision.
                 * @member {number} revision
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.revision = 0;

                /**
                 * SearchBlueprintContent thumbnailAssetId.
                 * @member {string} thumbnailAssetId
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.thumbnailAssetId = "";

                /**
                 * SearchBlueprintContent distanceFront.
                 * @member {number} distanceFront
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.distanceFront = 0;

                /**
                 * SearchBlueprintContent distanceRight.
                 * @member {number} distanceRight
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.distanceRight = 0;

                /**
                 * SearchBlueprintContent distanceBottom.
                 * @member {number} distanceBottom
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.distanceBottom = 0;

                /**
                 * SearchBlueprintContent distanceBack.
                 * @member {number} distanceBack
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.distanceBack = 0;

                /**
                 * SearchBlueprintContent distanceAverage.
                 * @member {number} distanceAverage
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.distanceAverage = 0;

                /**
                 * SearchBlueprintContent attributes.
                 * @member {Array.<mtechnavi.api.blueprint.SearchBlueprintContent.IAttributeValue>} attributes
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 */
                SearchBlueprintContent.prototype.attributes = $util.emptyArray;

                /**
                 * Verifies a SearchBlueprintContent message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SearchBlueprintContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        if (!$util.isString(message.blueprintRevisionId))
                            return "blueprintRevisionId: string expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    if (message.thumbnailAssetId != null && message.hasOwnProperty("thumbnailAssetId"))
                        if (!$util.isString(message.thumbnailAssetId))
                            return "thumbnailAssetId: string expected";
                    if (message.distanceFront != null && message.hasOwnProperty("distanceFront"))
                        if (typeof message.distanceFront !== "number")
                            return "distanceFront: number expected";
                    if (message.distanceRight != null && message.hasOwnProperty("distanceRight"))
                        if (typeof message.distanceRight !== "number")
                            return "distanceRight: number expected";
                    if (message.distanceBottom != null && message.hasOwnProperty("distanceBottom"))
                        if (typeof message.distanceBottom !== "number")
                            return "distanceBottom: number expected";
                    if (message.distanceBack != null && message.hasOwnProperty("distanceBack"))
                        if (typeof message.distanceBack !== "number")
                            return "distanceBack: number expected";
                    if (message.distanceAverage != null && message.hasOwnProperty("distanceAverage"))
                        if (typeof message.distanceAverage !== "number")
                            return "distanceAverage: number expected";
                    if (message.attributes != null && message.hasOwnProperty("attributes")) {
                        if (!Array.isArray(message.attributes))
                            return "attributes: array expected";
                        for (let i = 0; i < message.attributes.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue.verify(message.attributes[i]);
                            if (error)
                                return "attributes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SearchBlueprintContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.SearchBlueprintContent} SearchBlueprintContent
                 */
                SearchBlueprintContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintContent)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.SearchBlueprintContent();
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.blueprintRevisionId != null)
                        message.blueprintRevisionId = String(object.blueprintRevisionId);
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    if (object.thumbnailAssetId != null)
                        message.thumbnailAssetId = String(object.thumbnailAssetId);
                    if (object.distanceFront != null)
                        message.distanceFront = Number(object.distanceFront);
                    if (object.distanceRight != null)
                        message.distanceRight = Number(object.distanceRight);
                    if (object.distanceBottom != null)
                        message.distanceBottom = Number(object.distanceBottom);
                    if (object.distanceBack != null)
                        message.distanceBack = Number(object.distanceBack);
                    if (object.distanceAverage != null)
                        message.distanceAverage = Number(object.distanceAverage);
                    if (object.attributes) {
                        if (!Array.isArray(object.attributes))
                            throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintContent.attributes: array expected");
                        message.attributes = [];
                        for (let i = 0; i < object.attributes.length; ++i) {
                            if (typeof object.attributes[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.SearchBlueprintContent.attributes: object expected");
                            message.attributes[i] = $root.mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue.fromObject(object.attributes[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SearchBlueprintContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @static
                 * @param {mtechnavi.api.blueprint.SearchBlueprintContent} message SearchBlueprintContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SearchBlueprintContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attributes = [];
                    if (options.defaults) {
                        object.blueprintId = "";
                        object.blueprintRevisionId = "";
                        object.blueprintNumber = "";
                        object.revision = 0;
                        object.thumbnailAssetId = "";
                        object.distanceFront = 0;
                        object.distanceRight = 0;
                        object.distanceBottom = 0;
                        object.distanceBack = 0;
                        object.distanceAverage = 0;
                    }
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.blueprintRevisionId != null && message.hasOwnProperty("blueprintRevisionId"))
                        object.blueprintRevisionId = message.blueprintRevisionId;
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    if (message.thumbnailAssetId != null && message.hasOwnProperty("thumbnailAssetId"))
                        object.thumbnailAssetId = message.thumbnailAssetId;
                    if (message.distanceFront != null && message.hasOwnProperty("distanceFront"))
                        object.distanceFront = options.json && !isFinite(message.distanceFront) ? String(message.distanceFront) : message.distanceFront;
                    if (message.distanceRight != null && message.hasOwnProperty("distanceRight"))
                        object.distanceRight = options.json && !isFinite(message.distanceRight) ? String(message.distanceRight) : message.distanceRight;
                    if (message.distanceBottom != null && message.hasOwnProperty("distanceBottom"))
                        object.distanceBottom = options.json && !isFinite(message.distanceBottom) ? String(message.distanceBottom) : message.distanceBottom;
                    if (message.distanceBack != null && message.hasOwnProperty("distanceBack"))
                        object.distanceBack = options.json && !isFinite(message.distanceBack) ? String(message.distanceBack) : message.distanceBack;
                    if (message.distanceAverage != null && message.hasOwnProperty("distanceAverage"))
                        object.distanceAverage = options.json && !isFinite(message.distanceAverage) ? String(message.distanceAverage) : message.distanceAverage;
                    if (message.attributes && message.attributes.length) {
                        object.attributes = [];
                        for (let j = 0; j < message.attributes.length; ++j)
                            object.attributes[j] = $root.mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue.toObject(message.attributes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this SearchBlueprintContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SearchBlueprintContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                SearchBlueprintContent.AttributeValue = (function() {

                    /**
                     * Properties of an AttributeValue.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                     * @interface IAttributeValue
                     * @property {string|null} [name] AttributeValue name
                     * @property {string|null} [value] AttributeValue value
                     */

                    /**
                     * Constructs a new AttributeValue.
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent
                     * @classdesc Represents an AttributeValue.
                     * @implements IAttributeValue
                     * @constructor
                     * @param {mtechnavi.api.blueprint.SearchBlueprintContent.IAttributeValue=} [properties] Properties to set
                     */
                    function AttributeValue(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AttributeValue name.
                     * @member {string} name
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @instance
                     */
                    AttributeValue.prototype.name = "";

                    /**
                     * AttributeValue value.
                     * @member {string} value
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @instance
                     */
                    AttributeValue.prototype.value = "";

                    /**
                     * Verifies an AttributeValue message.
                     * @function verify
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AttributeValue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        return null;
                    };

                    /**
                     * Creates an AttributeValue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue} AttributeValue
                     */
                    AttributeValue.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue)
                            return object;
                        let message = new $root.mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.value != null)
                            message.value = String(object.value);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AttributeValue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @static
                     * @param {mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue} message AttributeValue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AttributeValue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.value = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };

                    /**
                     * Converts this AttributeValue to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.blueprint.SearchBlueprintContent.AttributeValue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AttributeValue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AttributeValue;
                })();

                return SearchBlueprintContent;
            })();

            blueprint.BlueprintContent = (function() {

                /**
                 * Properties of a BlueprintContent.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintContent
                 * @property {mtechnavi.api.blueprint.IBlueprint|null} [blueprint] BlueprintContent blueprint
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] BlueprintContent blueprintRevision
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>|null} [blueprintAttributes] BlueprintContent blueprintAttributes
                 * @property {mtechnavi.api.blueprint.IBlueprintSegment|null} [blueprintSegment] BlueprintContent blueprintSegment
                 * @property {Array.<mtechnavi.api.blueprint.IBlueprintLinkItem>|null} [blueprintLinkItems] BlueprintContent blueprintLinkItems
                 */

                /**
                 * Constructs a new BlueprintContent.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintContent.
                 * @implements IBlueprintContent
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintContent=} [properties] Properties to set
                 */
                function BlueprintContent(properties) {
                    this.blueprintAttributes = [];
                    this.blueprintLinkItems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintContent blueprint.
                 * @member {mtechnavi.api.blueprint.IBlueprint|null|undefined} blueprint
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 */
                BlueprintContent.prototype.blueprint = null;

                /**
                 * BlueprintContent blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 */
                BlueprintContent.prototype.blueprintRevision = null;

                /**
                 * BlueprintContent blueprintAttributes.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintAttribute>} blueprintAttributes
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 */
                BlueprintContent.prototype.blueprintAttributes = $util.emptyArray;

                /**
                 * BlueprintContent blueprintSegment.
                 * @member {mtechnavi.api.blueprint.IBlueprintSegment|null|undefined} blueprintSegment
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 */
                BlueprintContent.prototype.blueprintSegment = null;

                /**
                 * BlueprintContent blueprintLinkItems.
                 * @member {Array.<mtechnavi.api.blueprint.IBlueprintLinkItem>} blueprintLinkItems
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 */
                BlueprintContent.prototype.blueprintLinkItems = $util.emptyArray;

                /**
                 * Verifies a BlueprintContent message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    if (message.blueprintAttributes != null && message.hasOwnProperty("blueprintAttributes")) {
                        if (!Array.isArray(message.blueprintAttributes))
                            return "blueprintAttributes: array expected";
                        for (let i = 0; i < message.blueprintAttributes.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintAttribute.verify(message.blueprintAttributes[i]);
                            if (error)
                                return "blueprintAttributes." + error;
                        }
                    }
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintSegment.verify(message.blueprintSegment);
                        if (error)
                            return "blueprintSegment." + error;
                    }
                    if (message.blueprintLinkItems != null && message.hasOwnProperty("blueprintLinkItems")) {
                        if (!Array.isArray(message.blueprintLinkItems))
                            return "blueprintLinkItems: array expected";
                        for (let i = 0; i < message.blueprintLinkItems.length; ++i) {
                            let error = $root.mtechnavi.api.blueprint.BlueprintLinkItem.verify(message.blueprintLinkItems[i]);
                            if (error)
                                return "blueprintLinkItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BlueprintContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintContent} BlueprintContent
                 */
                BlueprintContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintContent)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintContent();
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.blueprint);
                    }
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    if (object.blueprintAttributes) {
                        if (!Array.isArray(object.blueprintAttributes))
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintAttributes: array expected");
                        message.blueprintAttributes = [];
                        for (let i = 0; i < object.blueprintAttributes.length; ++i) {
                            if (typeof object.blueprintAttributes[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintAttributes: object expected");
                            message.blueprintAttributes[i] = $root.mtechnavi.api.blueprint.BlueprintAttribute.fromObject(object.blueprintAttributes[i]);
                        }
                    }
                    if (object.blueprintSegment != null) {
                        if (typeof object.blueprintSegment !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintSegment: object expected");
                        message.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.fromObject(object.blueprintSegment);
                    }
                    if (object.blueprintLinkItems) {
                        if (!Array.isArray(object.blueprintLinkItems))
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintLinkItems: array expected");
                        message.blueprintLinkItems = [];
                        for (let i = 0; i < object.blueprintLinkItems.length; ++i) {
                            if (typeof object.blueprintLinkItems[i] !== "object")
                                throw TypeError(".mtechnavi.api.blueprint.BlueprintContent.blueprintLinkItems: object expected");
                            message.blueprintLinkItems[i] = $root.mtechnavi.api.blueprint.BlueprintLinkItem.fromObject(object.blueprintLinkItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintContent} message BlueprintContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.blueprintAttributes = [];
                        object.blueprintLinkItems = [];
                    }
                    if (options.defaults) {
                        object.blueprint = null;
                        object.blueprintRevision = null;
                        object.blueprintSegment = null;
                    }
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.blueprint, options);
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    if (message.blueprintAttributes && message.blueprintAttributes.length) {
                        object.blueprintAttributes = [];
                        for (let j = 0; j < message.blueprintAttributes.length; ++j)
                            object.blueprintAttributes[j] = $root.mtechnavi.api.blueprint.BlueprintAttribute.toObject(message.blueprintAttributes[j], options);
                    }
                    if (message.blueprintSegment != null && message.hasOwnProperty("blueprintSegment"))
                        object.blueprintSegment = $root.mtechnavi.api.blueprint.BlueprintSegment.toObject(message.blueprintSegment, options);
                    if (message.blueprintLinkItems && message.blueprintLinkItems.length) {
                        object.blueprintLinkItems = [];
                        for (let j = 0; j < message.blueprintLinkItems.length; ++j)
                            object.blueprintLinkItems[j] = $root.mtechnavi.api.blueprint.BlueprintLinkItem.toObject(message.blueprintLinkItems[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BlueprintContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintContent;
            })();

            blueprint.BlueprintRevisionContent = (function() {

                /**
                 * Properties of a BlueprintRevisionContent.
                 * @memberof mtechnavi.api.blueprint
                 * @interface IBlueprintRevisionContent
                 * @property {mtechnavi.api.blueprint.IBlueprint|null} [blueprint] BlueprintRevisionContent blueprint
                 * @property {mtechnavi.api.blueprint.IBlueprintRevision|null} [blueprintRevision] BlueprintRevisionContent blueprintRevision
                 */

                /**
                 * Constructs a new BlueprintRevisionContent.
                 * @memberof mtechnavi.api.blueprint
                 * @classdesc Represents a BlueprintRevisionContent.
                 * @implements IBlueprintRevisionContent
                 * @constructor
                 * @param {mtechnavi.api.blueprint.IBlueprintRevisionContent=} [properties] Properties to set
                 */
                function BlueprintRevisionContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintRevisionContent blueprint.
                 * @member {mtechnavi.api.blueprint.IBlueprint|null|undefined} blueprint
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @instance
                 */
                BlueprintRevisionContent.prototype.blueprint = null;

                /**
                 * BlueprintRevisionContent blueprintRevision.
                 * @member {mtechnavi.api.blueprint.IBlueprintRevision|null|undefined} blueprintRevision
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @instance
                 */
                BlueprintRevisionContent.prototype.blueprintRevision = null;

                /**
                 * Verifies a BlueprintRevisionContent message.
                 * @function verify
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintRevisionContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.blueprint.Blueprint.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision")) {
                        let error = $root.mtechnavi.api.blueprint.BlueprintRevision.verify(message.blueprintRevision);
                        if (error)
                            return "blueprintRevision." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BlueprintRevisionContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.blueprint.BlueprintRevisionContent} BlueprintRevisionContent
                 */
                BlueprintRevisionContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.blueprint.BlueprintRevisionContent)
                        return object;
                    let message = new $root.mtechnavi.api.blueprint.BlueprintRevisionContent();
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevisionContent.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.blueprint.Blueprint.fromObject(object.blueprint);
                    }
                    if (object.blueprintRevision != null) {
                        if (typeof object.blueprintRevision !== "object")
                            throw TypeError(".mtechnavi.api.blueprint.BlueprintRevisionContent.blueprintRevision: object expected");
                        message.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.fromObject(object.blueprintRevision);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintRevisionContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @static
                 * @param {mtechnavi.api.blueprint.BlueprintRevisionContent} message BlueprintRevisionContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintRevisionContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprint = null;
                        object.blueprintRevision = null;
                    }
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.blueprint.Blueprint.toObject(message.blueprint, options);
                    if (message.blueprintRevision != null && message.hasOwnProperty("blueprintRevision"))
                        object.blueprintRevision = $root.mtechnavi.api.blueprint.BlueprintRevision.toObject(message.blueprintRevision, options);
                    return object;
                };

                /**
                 * Converts this BlueprintRevisionContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.blueprint.BlueprintRevisionContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintRevisionContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintRevisionContent;
            })();

            return blueprint;
        })();

        api.company = (function() {

            /**
             * Namespace company.
             * @memberof mtechnavi.api
             * @namespace
             */
            const company = {};

            company.CompanyService = (function() {

                /**
                 * Constructs a new CompanyService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function CompanyService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (CompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CompanyService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyService#getCompany}.
                 * @memberof mtechnavi.api.company.CompanyService
                 * @typedef GetCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.Company} [response] Company
                 */

                /**
                 * Calls GetCompany.
                 * @function getCompany
                 * @memberof mtechnavi.api.company.CompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetCompanyRequest} request GetCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyService.GetCompanyCallback} callback Node-style callback called with the error, if any, and Company
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyService.prototype.getCompany = function getCompany(request, callback) {
                    return this.rpcCall(getCompany, $root.mtechnavi.api.company.GetCompanyRequest, $root.mtechnavi.api.company.Company, request, callback);
                }, "name", { value: "GetCompany" });

                /**
                 * Calls GetCompany.
                 * @function getCompany
                 * @memberof mtechnavi.api.company.CompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetCompanyRequest} request GetCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.Company>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyService#updateCompany}.
                 * @memberof mtechnavi.api.company.CompanyService
                 * @typedef UpdateCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.Company} [response] Company
                 */

                /**
                 * Calls UpdateCompany.
                 * @function updateCompany
                 * @memberof mtechnavi.api.company.CompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateCompanyRequest} request UpdateCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyService.UpdateCompanyCallback} callback Node-style callback called with the error, if any, and Company
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyService.prototype.updateCompany = function updateCompany(request, callback) {
                    return this.rpcCall(updateCompany, $root.mtechnavi.api.company.UpdateCompanyRequest, $root.mtechnavi.api.company.Company, request, callback);
                }, "name", { value: "UpdateCompany" });

                /**
                 * Calls UpdateCompany.
                 * @function updateCompany
                 * @memberof mtechnavi.api.company.CompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateCompanyRequest} request UpdateCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.Company>} Promise
                 * @variation 2
                 */

                return CompanyService;
            })();

            company.ComponentUnitService = (function() {

                /**
                 * Constructs a new ComponentUnitService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ComponentUnitService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ComponentUnitService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ComponentUnitService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ComponentUnitService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#createComponentUnit}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef CreateComponentUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ComponentUnit} [response] ComponentUnit
                 */

                /**
                 * Calls CreateComponentUnit.
                 * @function createComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateComponentUnitRequest} request CreateComponentUnitRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.CreateComponentUnitCallback} callback Node-style callback called with the error, if any, and ComponentUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.createComponentUnit = function createComponentUnit(request, callback) {
                    return this.rpcCall(createComponentUnit, $root.mtechnavi.api.company.CreateComponentUnitRequest, $root.mtechnavi.api.company.ComponentUnit, request, callback);
                }, "name", { value: "CreateComponentUnit" });

                /**
                 * Calls CreateComponentUnit.
                 * @function createComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateComponentUnitRequest} request CreateComponentUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ComponentUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#listComponentUnits}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef ListComponentUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListComponentUnitsResponse} [response] ListComponentUnitsResponse
                 */

                /**
                 * Calls ListComponentUnits.
                 * @function listComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListComponentUnitsRequest} request ListComponentUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.ListComponentUnitsCallback} callback Node-style callback called with the error, if any, and ListComponentUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.listComponentUnits = function listComponentUnits(request, callback) {
                    return this.rpcCall(listComponentUnits, $root.mtechnavi.api.company.ListComponentUnitsRequest, $root.mtechnavi.api.company.ListComponentUnitsResponse, request, callback);
                }, "name", { value: "ListComponentUnits" });

                /**
                 * Calls ListComponentUnits.
                 * @function listComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListComponentUnitsRequest} request ListComponentUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListComponentUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#getComponentUnit}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef GetComponentUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ComponentUnit} [response] ComponentUnit
                 */

                /**
                 * Calls GetComponentUnit.
                 * @function getComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IGetComponentUnitRequest} request GetComponentUnitRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.GetComponentUnitCallback} callback Node-style callback called with the error, if any, and ComponentUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.getComponentUnit = function getComponentUnit(request, callback) {
                    return this.rpcCall(getComponentUnit, $root.mtechnavi.api.company.GetComponentUnitRequest, $root.mtechnavi.api.company.ComponentUnit, request, callback);
                }, "name", { value: "GetComponentUnit" });

                /**
                 * Calls GetComponentUnit.
                 * @function getComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IGetComponentUnitRequest} request GetComponentUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ComponentUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#updateComponentUnit}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef UpdateComponentUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ComponentUnit} [response] ComponentUnit
                 */

                /**
                 * Calls UpdateComponentUnit.
                 * @function updateComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateComponentUnitRequest} request UpdateComponentUnitRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.UpdateComponentUnitCallback} callback Node-style callback called with the error, if any, and ComponentUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.updateComponentUnit = function updateComponentUnit(request, callback) {
                    return this.rpcCall(updateComponentUnit, $root.mtechnavi.api.company.UpdateComponentUnitRequest, $root.mtechnavi.api.company.ComponentUnit, request, callback);
                }, "name", { value: "UpdateComponentUnit" });

                /**
                 * Calls UpdateComponentUnit.
                 * @function updateComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateComponentUnitRequest} request UpdateComponentUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ComponentUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#deleteComponentUnit}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef DeleteComponentUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteComponentUnit.
                 * @function deleteComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteComponentUnitRequest} request DeleteComponentUnitRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.DeleteComponentUnitCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.deleteComponentUnit = function deleteComponentUnit(request, callback) {
                    return this.rpcCall(deleteComponentUnit, $root.mtechnavi.api.company.DeleteComponentUnitRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteComponentUnit" });

                /**
                 * Calls DeleteComponentUnit.
                 * @function deleteComponentUnit
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteComponentUnitRequest} request DeleteComponentUnitRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#importComponentUnits}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef ImportComponentUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportComponentUnitsResponse} [response] ImportComponentUnitsResponse
                 */

                /**
                 * Calls ImportComponentUnits.
                 * @function importComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IImportComponentUnitsRequest} request ImportComponentUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.ImportComponentUnitsCallback} callback Node-style callback called with the error, if any, and ImportComponentUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.importComponentUnits = function importComponentUnits(request, callback) {
                    return this.rpcCall(importComponentUnits, $root.mtechnavi.api.company.ImportComponentUnitsRequest, $root.mtechnavi.api.company.ImportComponentUnitsResponse, request, callback);
                }, "name", { value: "ImportComponentUnits" });

                /**
                 * Calls ImportComponentUnits.
                 * @function importComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IImportComponentUnitsRequest} request ImportComponentUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportComponentUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#exportComponentUnits}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef ExportComponentUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportComponentUnitsResponse} [response] ExportComponentUnitsResponse
                 */

                /**
                 * Calls ExportComponentUnits.
                 * @function exportComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IExportComponentUnitsRequest} request ExportComponentUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.ExportComponentUnitsCallback} callback Node-style callback called with the error, if any, and ExportComponentUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.exportComponentUnits = function exportComponentUnits(request, callback) {
                    return this.rpcCall(exportComponentUnits, $root.mtechnavi.api.company.ExportComponentUnitsRequest, $root.mtechnavi.api.company.ExportComponentUnitsResponse, request, callback);
                }, "name", { value: "ExportComponentUnits" });

                /**
                 * Calls ExportComponentUnits.
                 * @function exportComponentUnits
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IExportComponentUnitsRequest} request ExportComponentUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportComponentUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.ComponentUnitService#listComponentUnitFormats}.
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @typedef ListComponentUnitFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListComponentUnitFormatsResponse} [response] ListComponentUnitFormatsResponse
                 */

                /**
                 * Calls ListComponentUnitFormats.
                 * @function listComponentUnitFormats
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListComponentUnitFormatsRequest} request ListComponentUnitFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.ComponentUnitService.ListComponentUnitFormatsCallback} callback Node-style callback called with the error, if any, and ListComponentUnitFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ComponentUnitService.prototype.listComponentUnitFormats = function listComponentUnitFormats(request, callback) {
                    return this.rpcCall(listComponentUnitFormats, $root.mtechnavi.api.company.ListComponentUnitFormatsRequest, $root.mtechnavi.api.company.ListComponentUnitFormatsResponse, request, callback);
                }, "name", { value: "ListComponentUnitFormats" });

                /**
                 * Calls ListComponentUnitFormats.
                 * @function listComponentUnitFormats
                 * @memberof mtechnavi.api.company.ComponentUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListComponentUnitFormatsRequest} request ListComponentUnitFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListComponentUnitFormatsResponse>} Promise
                 * @variation 2
                 */

                return ComponentUnitService;
            })();

            company.BusinessUnitService = (function() {

                /**
                 * Constructs a new BusinessUnitService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BusinessUnitService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BusinessUnitService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BusinessUnitService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#listBusinessUnits}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef ListBusinessUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitsResponse} [response] ListBusinessUnitsResponse
                 */

                /**
                 * Calls ListBusinessUnits.
                 * @function listBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitsRequest} request ListBusinessUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.ListBusinessUnitsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.listBusinessUnits = function listBusinessUnits(request, callback) {
                    return this.rpcCall(listBusinessUnits, $root.mtechnavi.api.company.ListBusinessUnitsRequest, $root.mtechnavi.api.company.ListBusinessUnitsResponse, request, callback);
                }, "name", { value: "ListBusinessUnits" });

                /**
                 * Calls ListBusinessUnits.
                 * @function listBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitsRequest} request ListBusinessUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#getBusinessUnit}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef GetBusinessUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnit} [response] BusinessUnit
                 */

                /**
                 * Calls GetBusinessUnit.
                 * @function getBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequest} request GetBusinessUnitRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.GetBusinessUnitCallback} callback Node-style callback called with the error, if any, and BusinessUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.getBusinessUnit = function getBusinessUnit(request, callback) {
                    return this.rpcCall(getBusinessUnit, $root.mtechnavi.api.company.GetBusinessUnitRequest, $root.mtechnavi.api.company.BusinessUnit, request, callback);
                }, "name", { value: "GetBusinessUnit" });

                /**
                 * Calls GetBusinessUnit.
                 * @function getBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequest} request GetBusinessUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#updateBusinessUnit}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef UpdateBusinessUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnit} [response] BusinessUnit
                 */

                /**
                 * Calls UpdateBusinessUnit.
                 * @function updateBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequest} request UpdateBusinessUnitRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.UpdateBusinessUnitCallback} callback Node-style callback called with the error, if any, and BusinessUnit
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.updateBusinessUnit = function updateBusinessUnit(request, callback) {
                    return this.rpcCall(updateBusinessUnit, $root.mtechnavi.api.company.UpdateBusinessUnitRequest, $root.mtechnavi.api.company.BusinessUnit, request, callback);
                }, "name", { value: "UpdateBusinessUnit" });

                /**
                 * Calls UpdateBusinessUnit.
                 * @function updateBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequest} request UpdateBusinessUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnit>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#deleteBusinessUnit}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef DeleteBusinessUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnit.
                 * @function deleteBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequest} request DeleteBusinessUnitRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.DeleteBusinessUnitCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.deleteBusinessUnit = function deleteBusinessUnit(request, callback) {
                    return this.rpcCall(deleteBusinessUnit, $root.mtechnavi.api.company.DeleteBusinessUnitRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnit" });

                /**
                 * Calls DeleteBusinessUnit.
                 * @function deleteBusinessUnit
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequest} request DeleteBusinessUnitRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#importBusinessUnits}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef ImportBusinessUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitsResponse} [response] ImportBusinessUnitsResponse
                 */

                /**
                 * Calls ImportBusinessUnits.
                 * @function importBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitsRequest} request ImportBusinessUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.ImportBusinessUnitsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.importBusinessUnits = function importBusinessUnits(request, callback) {
                    return this.rpcCall(importBusinessUnits, $root.mtechnavi.api.company.ImportBusinessUnitsRequest, $root.mtechnavi.api.company.ImportBusinessUnitsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnits" });

                /**
                 * Calls ImportBusinessUnits.
                 * @function importBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitsRequest} request ImportBusinessUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#exportBusinessUnits}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef ExportBusinessUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitsResponse} [response] ExportBusinessUnitsResponse
                 */

                /**
                 * Calls ExportBusinessUnits.
                 * @function exportBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitsRequest} request ExportBusinessUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.ExportBusinessUnitsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.exportBusinessUnits = function exportBusinessUnits(request, callback) {
                    return this.rpcCall(exportBusinessUnits, $root.mtechnavi.api.company.ExportBusinessUnitsRequest, $root.mtechnavi.api.company.ExportBusinessUnitsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnits" });

                /**
                 * Calls ExportBusinessUnits.
                 * @function exportBusinessUnits
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitsRequest} request ExportBusinessUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitService#listBusinessUnitFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @typedef ListBusinessUnitFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitFormatsResponse} [response] ListBusinessUnitFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitFormats.
                 * @function listBusinessUnitFormats
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFormatsRequest} request ListBusinessUnitFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitService.ListBusinessUnitFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitService.prototype.listBusinessUnitFormats = function listBusinessUnitFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitFormats, $root.mtechnavi.api.company.ListBusinessUnitFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitFormats" });

                /**
                 * Calls ListBusinessUnitFormats.
                 * @function listBusinessUnitFormats
                 * @memberof mtechnavi.api.company.BusinessUnitService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFormatsRequest} request ListBusinessUnitFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitFormatsResponse>} Promise
                 * @variation 2
                 */

                return BusinessUnitService;
            })();

            company.StaffService = (function() {

                /**
                 * Constructs a new StaffService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StaffService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function StaffService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (StaffService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StaffService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#listStaffs}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef ListStaffsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListStaffsResponse} [response] ListStaffsResponse
                 */

                /**
                 * Calls ListStaffs.
                 * @function listStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffsRequest} request ListStaffsRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.ListStaffsCallback} callback Node-style callback called with the error, if any, and ListStaffsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.listStaffs = function listStaffs(request, callback) {
                    return this.rpcCall(listStaffs, $root.mtechnavi.api.company.ListStaffsRequest, $root.mtechnavi.api.company.ListStaffsResponse, request, callback);
                }, "name", { value: "ListStaffs" });

                /**
                 * Calls ListStaffs.
                 * @function listStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffsRequest} request ListStaffsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListStaffsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#getStaff}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef GetStaffCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.Staff} [response] Staff
                 */

                /**
                 * Calls GetStaff.
                 * @function getStaff
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IGetStaffRequest} request GetStaffRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.GetStaffCallback} callback Node-style callback called with the error, if any, and Staff
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.getStaff = function getStaff(request, callback) {
                    return this.rpcCall(getStaff, $root.mtechnavi.api.company.GetStaffRequest, $root.mtechnavi.api.company.Staff, request, callback);
                }, "name", { value: "GetStaff" });

                /**
                 * Calls GetStaff.
                 * @function getStaff
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IGetStaffRequest} request GetStaffRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.Staff>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#listStaffAndComponentUnits}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef ListStaffAndComponentUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListStaffAndComponentUnitsResponse} [response] ListStaffAndComponentUnitsResponse
                 */

                /**
                 * Calls ListStaffAndComponentUnits.
                 * @function listStaffAndComponentUnits
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffAndComponentUnitsRequest} request ListStaffAndComponentUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.ListStaffAndComponentUnitsCallback} callback Node-style callback called with the error, if any, and ListStaffAndComponentUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.listStaffAndComponentUnits = function listStaffAndComponentUnits(request, callback) {
                    return this.rpcCall(listStaffAndComponentUnits, $root.mtechnavi.api.company.ListStaffAndComponentUnitsRequest, $root.mtechnavi.api.company.ListStaffAndComponentUnitsResponse, request, callback);
                }, "name", { value: "ListStaffAndComponentUnits" });

                /**
                 * Calls ListStaffAndComponentUnits.
                 * @function listStaffAndComponentUnits
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffAndComponentUnitsRequest} request ListStaffAndComponentUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListStaffAndComponentUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#importStaffs}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef ImportStaffsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportStaffsResponse} [response] ImportStaffsResponse
                 */

                /**
                 * Calls ImportStaffs.
                 * @function importStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IImportStaffsRequest} request ImportStaffsRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.ImportStaffsCallback} callback Node-style callback called with the error, if any, and ImportStaffsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.importStaffs = function importStaffs(request, callback) {
                    return this.rpcCall(importStaffs, $root.mtechnavi.api.company.ImportStaffsRequest, $root.mtechnavi.api.company.ImportStaffsResponse, request, callback);
                }, "name", { value: "ImportStaffs" });

                /**
                 * Calls ImportStaffs.
                 * @function importStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IImportStaffsRequest} request ImportStaffsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportStaffsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#exportStaffs}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef ExportStaffsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportStaffsResponse} [response] ExportStaffsResponse
                 */

                /**
                 * Calls ExportStaffs.
                 * @function exportStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IExportStaffsRequest} request ExportStaffsRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.ExportStaffsCallback} callback Node-style callback called with the error, if any, and ExportStaffsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.exportStaffs = function exportStaffs(request, callback) {
                    return this.rpcCall(exportStaffs, $root.mtechnavi.api.company.ExportStaffsRequest, $root.mtechnavi.api.company.ExportStaffsResponse, request, callback);
                }, "name", { value: "ExportStaffs" });

                /**
                 * Calls ExportStaffs.
                 * @function exportStaffs
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IExportStaffsRequest} request ExportStaffsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportStaffsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.StaffService#listStaffFormats}.
                 * @memberof mtechnavi.api.company.StaffService
                 * @typedef ListStaffFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListStaffFormatsResponse} [response] ListStaffFormatsResponse
                 */

                /**
                 * Calls ListStaffFormats.
                 * @function listStaffFormats
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffFormatsRequest} request ListStaffFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.StaffService.ListStaffFormatsCallback} callback Node-style callback called with the error, if any, and ListStaffFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(StaffService.prototype.listStaffFormats = function listStaffFormats(request, callback) {
                    return this.rpcCall(listStaffFormats, $root.mtechnavi.api.company.ListStaffFormatsRequest, $root.mtechnavi.api.company.ListStaffFormatsResponse, request, callback);
                }, "name", { value: "ListStaffFormats" });

                /**
                 * Calls ListStaffFormats.
                 * @function listStaffFormats
                 * @memberof mtechnavi.api.company.StaffService
                 * @instance
                 * @param {mtechnavi.api.company.IListStaffFormatsRequest} request ListStaffFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListStaffFormatsResponse>} Promise
                 * @variation 2
                 */

                return StaffService;
            })();

            company.PublicCompanyService = (function() {

                /**
                 * Constructs a new PublicCompanyService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicCompanyService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function PublicCompanyService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (PublicCompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PublicCompanyService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicListCompanys}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicListCompanysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.PublicListCompanysResponse} [response] PublicListCompanysResponse
                 */

                /**
                 * Calls PublicListCompanys.
                 * @function publicListCompanys
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanysRequest} request PublicListCompanysRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicListCompanysCallback} callback Node-style callback called with the error, if any, and PublicListCompanysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicListCompanys = function publicListCompanys(request, callback) {
                    return this.rpcCall(publicListCompanys, $root.mtechnavi.api.company.PublicListCompanysRequest, $root.mtechnavi.api.company.PublicListCompanysResponse, request, callback);
                }, "name", { value: "PublicListCompanys" });

                /**
                 * Calls PublicListCompanys.
                 * @function publicListCompanys
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanysRequest} request PublicListCompanysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.PublicListCompanysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicGetCompany}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicGetCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.Company} [response] Company
                 */

                /**
                 * Calls PublicGetCompany.
                 * @function publicGetCompany
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicGetCompanyRequest} request PublicGetCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicGetCompanyCallback} callback Node-style callback called with the error, if any, and Company
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicGetCompany = function publicGetCompany(request, callback) {
                    return this.rpcCall(publicGetCompany, $root.mtechnavi.api.company.PublicGetCompanyRequest, $root.mtechnavi.api.company.Company, request, callback);
                }, "name", { value: "PublicGetCompany" });

                /**
                 * Calls PublicGetCompany.
                 * @function publicGetCompany
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicGetCompanyRequest} request PublicGetCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.Company>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicListCompanyAndBusinessUnits}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicListCompanyAndBusinessUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse} [response] PublicListCompanyAndBusinessUnitsResponse
                 */

                /**
                 * Calls PublicListCompanyAndBusinessUnits.
                 * @function publicListCompanyAndBusinessUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanyAndBusinessUnitsRequest} request PublicListCompanyAndBusinessUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicListCompanyAndBusinessUnitsCallback} callback Node-style callback called with the error, if any, and PublicListCompanyAndBusinessUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicListCompanyAndBusinessUnits = function publicListCompanyAndBusinessUnits(request, callback) {
                    return this.rpcCall(publicListCompanyAndBusinessUnits, $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest, $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse, request, callback);
                }, "name", { value: "PublicListCompanyAndBusinessUnits" });

                /**
                 * Calls PublicListCompanyAndBusinessUnits.
                 * @function publicListCompanyAndBusinessUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanyAndBusinessUnitsRequest} request PublicListCompanyAndBusinessUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicListComponentUnits}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicListComponentUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.PublicListComponentUnitsResponse} [response] PublicListComponentUnitsResponse
                 */

                /**
                 * Calls PublicListComponentUnits.
                 * @function publicListComponentUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListComponentUnitsRequest} request PublicListComponentUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicListComponentUnitsCallback} callback Node-style callback called with the error, if any, and PublicListComponentUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicListComponentUnits = function publicListComponentUnits(request, callback) {
                    return this.rpcCall(publicListComponentUnits, $root.mtechnavi.api.company.PublicListComponentUnitsRequest, $root.mtechnavi.api.company.PublicListComponentUnitsResponse, request, callback);
                }, "name", { value: "PublicListComponentUnits" });

                /**
                 * Calls PublicListComponentUnits.
                 * @function publicListComponentUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListComponentUnitsRequest} request PublicListComponentUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.PublicListComponentUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicListCompanyAndComponentUnitAndBusinessUnits}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicListCompanyAndComponentUnitAndBusinessUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse} [response] PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 */

                /**
                 * Calls PublicListCompanyAndComponentUnitAndBusinessUnits.
                 * @function publicListCompanyAndComponentUnitAndBusinessUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanyAndComponentUnitAndBusinessUnitsRequest} request PublicListCompanyAndComponentUnitAndBusinessUnitsRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicListCompanyAndComponentUnitAndBusinessUnitsCallback} callback Node-style callback called with the error, if any, and PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicListCompanyAndComponentUnitAndBusinessUnits = function publicListCompanyAndComponentUnitAndBusinessUnits(request, callback) {
                    return this.rpcCall(publicListCompanyAndComponentUnitAndBusinessUnits, $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest, $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse, request, callback);
                }, "name", { value: "PublicListCompanyAndComponentUnitAndBusinessUnits" });

                /**
                 * Calls PublicListCompanyAndComponentUnitAndBusinessUnits.
                 * @function publicListCompanyAndComponentUnitAndBusinessUnits
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListCompanyAndComponentUnitAndBusinessUnitsRequest} request PublicListCompanyAndComponentUnitAndBusinessUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse} [response] PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 */

                /**
                 * Calls PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit.
                 * @function publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest} request PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitCallback} callback Node-style callback called with the error, if any, and PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit = function publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit(request, callback) {
                    return this.rpcCall(publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit, $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest, $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse, request, callback);
                }, "name", { value: "PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit" });

                /**
                 * Calls PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit.
                 * @function publicListPostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest} request PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#createBusinessUnitRequest}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef CreateBusinessUnitRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequest} [response] BusinessUnitRequest
                 */

                /**
                 * Calls CreateBusinessUnitRequest.
                 * @function createBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestRequest} request CreateBusinessUnitRequestRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.CreateBusinessUnitRequestCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.createBusinessUnitRequest = function createBusinessUnitRequest(request, callback) {
                    return this.rpcCall(createBusinessUnitRequest, $root.mtechnavi.api.company.CreateBusinessUnitRequestRequest, $root.mtechnavi.api.company.BusinessUnitRequest, request, callback);
                }, "name", { value: "CreateBusinessUnitRequest" });

                /**
                 * Calls CreateBusinessUnitRequest.
                 * @function createBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestRequest} request CreateBusinessUnitRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#listBusinessUnitRequests}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef ListBusinessUnitRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestsResponse} [response] ListBusinessUnitRequestsResponse
                 */

                /**
                 * Calls ListBusinessUnitRequests.
                 * @function listBusinessUnitRequests
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestsRequest} request ListBusinessUnitRequestsRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.ListBusinessUnitRequestsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.listBusinessUnitRequests = function listBusinessUnitRequests(request, callback) {
                    return this.rpcCall(listBusinessUnitRequests, $root.mtechnavi.api.company.ListBusinessUnitRequestsRequest, $root.mtechnavi.api.company.ListBusinessUnitRequestsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRequests" });

                /**
                 * Calls ListBusinessUnitRequests.
                 * @function listBusinessUnitRequests
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestsRequest} request ListBusinessUnitRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#getBusinessUnitRequest}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef GetBusinessUnitRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequest} [response] BusinessUnitRequest
                 */

                /**
                 * Calls GetBusinessUnitRequest.
                 * @function getBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestRequest} request GetBusinessUnitRequestRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.GetBusinessUnitRequestCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.getBusinessUnitRequest = function getBusinessUnitRequest(request, callback) {
                    return this.rpcCall(getBusinessUnitRequest, $root.mtechnavi.api.company.GetBusinessUnitRequestRequest, $root.mtechnavi.api.company.BusinessUnitRequest, request, callback);
                }, "name", { value: "GetBusinessUnitRequest" });

                /**
                 * Calls GetBusinessUnitRequest.
                 * @function getBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestRequest} request GetBusinessUnitRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#updateBusinessUnitRequest}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef UpdateBusinessUnitRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequest} [response] BusinessUnitRequest
                 */

                /**
                 * Calls UpdateBusinessUnitRequest.
                 * @function updateBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestRequest} request UpdateBusinessUnitRequestRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.UpdateBusinessUnitRequestCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.updateBusinessUnitRequest = function updateBusinessUnitRequest(request, callback) {
                    return this.rpcCall(updateBusinessUnitRequest, $root.mtechnavi.api.company.UpdateBusinessUnitRequestRequest, $root.mtechnavi.api.company.BusinessUnitRequest, request, callback);
                }, "name", { value: "UpdateBusinessUnitRequest" });

                /**
                 * Calls UpdateBusinessUnitRequest.
                 * @function updateBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestRequest} request UpdateBusinessUnitRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#deleteBusinessUnitRequest}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef DeleteBusinessUnitRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitRequest.
                 * @function deleteBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestRequest} request DeleteBusinessUnitRequestRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.DeleteBusinessUnitRequestCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.deleteBusinessUnitRequest = function deleteBusinessUnitRequest(request, callback) {
                    return this.rpcCall(deleteBusinessUnitRequest, $root.mtechnavi.api.company.DeleteBusinessUnitRequestRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitRequest" });

                /**
                 * Calls DeleteBusinessUnitRequest.
                 * @function deleteBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestRequest} request DeleteBusinessUnitRequestRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#sharetoBusinessUnitRequest}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef SharetoBusinessUnitRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestResponse} [response] SharetoBusinessUnitRequestResponse
                 */

                /**
                 * Calls SharetoBusinessUnitRequest.
                 * @function sharetoBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestRequest} request SharetoBusinessUnitRequestRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.SharetoBusinessUnitRequestCallback} callback Node-style callback called with the error, if any, and SharetoBusinessUnitRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.sharetoBusinessUnitRequest = function sharetoBusinessUnitRequest(request, callback) {
                    return this.rpcCall(sharetoBusinessUnitRequest, $root.mtechnavi.api.company.SharetoBusinessUnitRequestRequest, $root.mtechnavi.api.company.SharetoBusinessUnitRequestResponse, request, callback);
                }, "name", { value: "SharetoBusinessUnitRequest" });

                /**
                 * Calls SharetoBusinessUnitRequest.
                 * @function sharetoBusinessUnitRequest
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestRequest} request SharetoBusinessUnitRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharetoBusinessUnitRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#createBusinessUnitRequestResult}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef CreateBusinessUnitRequestResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequestResult} [response] BusinessUnitRequestResult
                 */

                /**
                 * Calls CreateBusinessUnitRequestResult.
                 * @function createBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestResultRequest} request CreateBusinessUnitRequestResultRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.CreateBusinessUnitRequestResultCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequestResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.createBusinessUnitRequestResult = function createBusinessUnitRequestResult(request, callback) {
                    return this.rpcCall(createBusinessUnitRequestResult, $root.mtechnavi.api.company.CreateBusinessUnitRequestResultRequest, $root.mtechnavi.api.company.BusinessUnitRequestResult, request, callback);
                }, "name", { value: "CreateBusinessUnitRequestResult" });

                /**
                 * Calls CreateBusinessUnitRequestResult.
                 * @function createBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestResultRequest} request CreateBusinessUnitRequestResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequestResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#listBusinessUnitRequestResults}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef ListBusinessUnitRequestResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestResultsResponse} [response] ListBusinessUnitRequestResultsResponse
                 */

                /**
                 * Calls ListBusinessUnitRequestResults.
                 * @function listBusinessUnitRequestResults
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestResultsRequest} request ListBusinessUnitRequestResultsRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.ListBusinessUnitRequestResultsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRequestResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.listBusinessUnitRequestResults = function listBusinessUnitRequestResults(request, callback) {
                    return this.rpcCall(listBusinessUnitRequestResults, $root.mtechnavi.api.company.ListBusinessUnitRequestResultsRequest, $root.mtechnavi.api.company.ListBusinessUnitRequestResultsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRequestResults" });

                /**
                 * Calls ListBusinessUnitRequestResults.
                 * @function listBusinessUnitRequestResults
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestResultsRequest} request ListBusinessUnitRequestResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRequestResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#getBusinessUnitRequestResult}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef GetBusinessUnitRequestResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequestResult} [response] BusinessUnitRequestResult
                 */

                /**
                 * Calls GetBusinessUnitRequestResult.
                 * @function getBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestResultRequest} request GetBusinessUnitRequestResultRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.GetBusinessUnitRequestResultCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequestResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.getBusinessUnitRequestResult = function getBusinessUnitRequestResult(request, callback) {
                    return this.rpcCall(getBusinessUnitRequestResult, $root.mtechnavi.api.company.GetBusinessUnitRequestResultRequest, $root.mtechnavi.api.company.BusinessUnitRequestResult, request, callback);
                }, "name", { value: "GetBusinessUnitRequestResult" });

                /**
                 * Calls GetBusinessUnitRequestResult.
                 * @function getBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestResultRequest} request GetBusinessUnitRequestResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequestResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#updateBusinessUnitRequestResult}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef UpdateBusinessUnitRequestResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRequestResult} [response] BusinessUnitRequestResult
                 */

                /**
                 * Calls UpdateBusinessUnitRequestResult.
                 * @function updateBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestResultRequest} request UpdateBusinessUnitRequestResultRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.UpdateBusinessUnitRequestResultCallback} callback Node-style callback called with the error, if any, and BusinessUnitRequestResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.updateBusinessUnitRequestResult = function updateBusinessUnitRequestResult(request, callback) {
                    return this.rpcCall(updateBusinessUnitRequestResult, $root.mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest, $root.mtechnavi.api.company.BusinessUnitRequestResult, request, callback);
                }, "name", { value: "UpdateBusinessUnitRequestResult" });

                /**
                 * Calls UpdateBusinessUnitRequestResult.
                 * @function updateBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestResultRequest} request UpdateBusinessUnitRequestResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRequestResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#deleteBusinessUnitRequestResult}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef DeleteBusinessUnitRequestResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitRequestResult.
                 * @function deleteBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestResultRequest} request DeleteBusinessUnitRequestResultRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.DeleteBusinessUnitRequestResultCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.deleteBusinessUnitRequestResult = function deleteBusinessUnitRequestResult(request, callback) {
                    return this.rpcCall(deleteBusinessUnitRequestResult, $root.mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitRequestResult" });

                /**
                 * Calls DeleteBusinessUnitRequestResult.
                 * @function deleteBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestResultRequest} request DeleteBusinessUnitRequestResultRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.PublicCompanyService#sharetoBusinessUnitRequestResult}.
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @typedef SharetoBusinessUnitRequestResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse} [response] SharetoBusinessUnitRequestResultResponse
                 */

                /**
                 * Calls SharetoBusinessUnitRequestResult.
                 * @function sharetoBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestResultRequest} request SharetoBusinessUnitRequestResultRequest message or plain object
                 * @param {mtechnavi.api.company.PublicCompanyService.SharetoBusinessUnitRequestResultCallback} callback Node-style callback called with the error, if any, and SharetoBusinessUnitRequestResultResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicCompanyService.prototype.sharetoBusinessUnitRequestResult = function sharetoBusinessUnitRequestResult(request, callback) {
                    return this.rpcCall(sharetoBusinessUnitRequestResult, $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest, $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse, request, callback);
                }, "name", { value: "SharetoBusinessUnitRequestResult" });

                /**
                 * Calls SharetoBusinessUnitRequestResult.
                 * @function sharetoBusinessUnitRequestResult
                 * @memberof mtechnavi.api.company.PublicCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestResultRequest} request SharetoBusinessUnitRequestResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse>} Promise
                 * @variation 2
                 */

                return PublicCompanyService;
            })();

            company.BusinessUnitManagementService = (function() {

                /**
                 * Constructs a new BusinessUnitManagementService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitManagementService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BusinessUnitManagementService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BusinessUnitManagementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BusinessUnitManagementService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitManagements}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementsResponse} [response] ListBusinessUnitManagementsResponse
                 */

                /**
                 * Calls ListBusinessUnitManagements.
                 * @function listBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementsRequest} request ListBusinessUnitManagementsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitManagementsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitManagements = function listBusinessUnitManagements(request, callback) {
                    return this.rpcCall(listBusinessUnitManagements, $root.mtechnavi.api.company.ListBusinessUnitManagementsRequest, $root.mtechnavi.api.company.ListBusinessUnitManagementsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitManagements" });

                /**
                 * Calls ListBusinessUnitManagements.
                 * @function listBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementsRequest} request ListBusinessUnitManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitManagement}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitManagement} [response] BusinessUnitManagement
                 */

                /**
                 * Calls GetBusinessUnitManagement.
                 * @function getBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementRequest} request GetBusinessUnitManagementRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitManagementCallback} callback Node-style callback called with the error, if any, and BusinessUnitManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitManagement = function getBusinessUnitManagement(request, callback) {
                    return this.rpcCall(getBusinessUnitManagement, $root.mtechnavi.api.company.GetBusinessUnitManagementRequest, $root.mtechnavi.api.company.BusinessUnitManagement, request, callback);
                }, "name", { value: "GetBusinessUnitManagement" });

                /**
                 * Calls GetBusinessUnitManagement.
                 * @function getBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementRequest} request GetBusinessUnitManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitManagement}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitManagement} [response] BusinessUnitManagement
                 */

                /**
                 * Calls CreateBusinessUnitManagement.
                 * @function createBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitManagementRequest} request CreateBusinessUnitManagementRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitManagementCallback} callback Node-style callback called with the error, if any, and BusinessUnitManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitManagement = function createBusinessUnitManagement(request, callback) {
                    return this.rpcCall(createBusinessUnitManagement, $root.mtechnavi.api.company.CreateBusinessUnitManagementRequest, $root.mtechnavi.api.company.BusinessUnitManagement, request, callback);
                }, "name", { value: "CreateBusinessUnitManagement" });

                /**
                 * Calls CreateBusinessUnitManagement.
                 * @function createBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitManagementRequest} request CreateBusinessUnitManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitManagement}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitManagement} [response] BusinessUnitManagement
                 */

                /**
                 * Calls UpdateBusinessUnitManagement.
                 * @function updateBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitManagementRequest} request UpdateBusinessUnitManagementRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitManagementCallback} callback Node-style callback called with the error, if any, and BusinessUnitManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitManagement = function updateBusinessUnitManagement(request, callback) {
                    return this.rpcCall(updateBusinessUnitManagement, $root.mtechnavi.api.company.UpdateBusinessUnitManagementRequest, $root.mtechnavi.api.company.BusinessUnitManagement, request, callback);
                }, "name", { value: "UpdateBusinessUnitManagement" });

                /**
                 * Calls UpdateBusinessUnitManagement.
                 * @function updateBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitManagementRequest} request UpdateBusinessUnitManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitManagement}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitManagement.
                 * @function deleteBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitManagementRequest} request DeleteBusinessUnitManagementRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitManagementCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitManagement = function deleteBusinessUnitManagement(request, callback) {
                    return this.rpcCall(deleteBusinessUnitManagement, $root.mtechnavi.api.company.DeleteBusinessUnitManagementRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitManagement" });

                /**
                 * Calls DeleteBusinessUnitManagement.
                 * @function deleteBusinessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitManagementRequest} request DeleteBusinessUnitManagementRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importOldBusinessUnitManagements}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportOldBusinessUnitManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse} [response] ImportOldBusinessUnitManagementsResponse
                 */

                /**
                 * Calls ImportOldBusinessUnitManagements.
                 * @function importOldBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportOldBusinessUnitManagementsRequest} request ImportOldBusinessUnitManagementsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportOldBusinessUnitManagementsCallback} callback Node-style callback called with the error, if any, and ImportOldBusinessUnitManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importOldBusinessUnitManagements = function importOldBusinessUnitManagements(request, callback) {
                    return this.rpcCall(importOldBusinessUnitManagements, $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest, $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse, request, callback);
                }, "name", { value: "ImportOldBusinessUnitManagements" });

                /**
                 * Calls ImportOldBusinessUnitManagements.
                 * @function importOldBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportOldBusinessUnitManagementsRequest} request ImportOldBusinessUnitManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportOldBusinessUnitManagements}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportOldBusinessUnitManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse} [response] ExportOldBusinessUnitManagementsResponse
                 */

                /**
                 * Calls ExportOldBusinessUnitManagements.
                 * @function exportOldBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportOldBusinessUnitManagementsRequest} request ExportOldBusinessUnitManagementsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportOldBusinessUnitManagementsCallback} callback Node-style callback called with the error, if any, and ExportOldBusinessUnitManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportOldBusinessUnitManagements = function exportOldBusinessUnitManagements(request, callback) {
                    return this.rpcCall(exportOldBusinessUnitManagements, $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest, $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse, request, callback);
                }, "name", { value: "ExportOldBusinessUnitManagements" });

                /**
                 * Calls ExportOldBusinessUnitManagements.
                 * @function exportOldBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportOldBusinessUnitManagementsRequest} request ExportOldBusinessUnitManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listOldBusinessUnitManagementFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListOldBusinessUnitManagementFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse} [response] ListOldBusinessUnitManagementFormatsResponse
                 */

                /**
                 * Calls ListOldBusinessUnitManagementFormats.
                 * @function listOldBusinessUnitManagementFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListOldBusinessUnitManagementFormatsRequest} request ListOldBusinessUnitManagementFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListOldBusinessUnitManagementFormatsCallback} callback Node-style callback called with the error, if any, and ListOldBusinessUnitManagementFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listOldBusinessUnitManagementFormats = function listOldBusinessUnitManagementFormats(request, callback) {
                    return this.rpcCall(listOldBusinessUnitManagementFormats, $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest, $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse, request, callback);
                }, "name", { value: "ListOldBusinessUnitManagementFormats" });

                /**
                 * Calls ListOldBusinessUnitManagementFormats.
                 * @function listOldBusinessUnitManagementFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListOldBusinessUnitManagementFormatsRequest} request ListOldBusinessUnitManagementFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitManagementContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitManagementContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementContentsResponse} [response] ListBusinessUnitManagementContentsResponse
                 */

                /**
                 * Calls ListBusinessUnitManagementContents.
                 * @function listBusinessUnitManagementContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementContentsRequest} request ListBusinessUnitManagementContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitManagementContentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitManagementContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitManagementContents = function listBusinessUnitManagementContents(request, callback) {
                    return this.rpcCall(listBusinessUnitManagementContents, $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest, $root.mtechnavi.api.company.ListBusinessUnitManagementContentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitManagementContents" });

                /**
                 * Calls ListBusinessUnitManagementContents.
                 * @function listBusinessUnitManagementContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementContentsRequest} request ListBusinessUnitManagementContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitManagementContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitManagementContent}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitManagementContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitManagementContent} [response] BusinessUnitManagementContent
                 */

                /**
                 * Calls GetBusinessUnitManagementContent.
                 * @function getBusinessUnitManagementContent
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementContentRequest} request GetBusinessUnitManagementContentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitManagementContentCallback} callback Node-style callback called with the error, if any, and BusinessUnitManagementContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitManagementContent = function getBusinessUnitManagementContent(request, callback) {
                    return this.rpcCall(getBusinessUnitManagementContent, $root.mtechnavi.api.company.GetBusinessUnitManagementContentRequest, $root.mtechnavi.api.company.BusinessUnitManagementContent, request, callback);
                }, "name", { value: "GetBusinessUnitManagementContent" });

                /**
                 * Calls GetBusinessUnitManagementContent.
                 * @function getBusinessUnitManagementContent
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementContentRequest} request GetBusinessUnitManagementContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitManagementContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listHistorys}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListHistorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListHistorysResponse} [response] ListHistorysResponse
                 */

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListHistorysCallback} callback Node-style callback called with the error, if any, and ListHistorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listHistorys = function listHistorys(request, callback) {
                    return this.rpcCall(listHistorys, $root.mtechnavi.api.company.ListHistorysRequest, $root.mtechnavi.api.company.ListHistorysResponse, request, callback);
                }, "name", { value: "ListHistorys" });

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListHistorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sendEmailTenantInvitation}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SendEmailTenantInvitationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SendEmailTenantInvitationResponse} [response] SendEmailTenantInvitationResponse
                 */

                /**
                 * Calls SendEmailTenantInvitation.
                 * @function sendEmailTenantInvitation
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISendEmailTenantInvitationRequest} request SendEmailTenantInvitationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SendEmailTenantInvitationCallback} callback Node-style callback called with the error, if any, and SendEmailTenantInvitationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sendEmailTenantInvitation = function sendEmailTenantInvitation(request, callback) {
                    return this.rpcCall(sendEmailTenantInvitation, $root.mtechnavi.api.company.SendEmailTenantInvitationRequest, $root.mtechnavi.api.company.SendEmailTenantInvitationResponse, request, callback);
                }, "name", { value: "SendEmailTenantInvitation" });

                /**
                 * Calls SendEmailTenantInvitation.
                 * @function sendEmailTenantInvitation
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISendEmailTenantInvitationRequest} request SendEmailTenantInvitationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SendEmailTenantInvitationResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitProfiles}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitProfilesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitProfilesResponse} [response] ListBusinessUnitProfilesResponse
                 */

                /**
                 * Calls ListBusinessUnitProfiles.
                 * @function listBusinessUnitProfiles
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitProfilesRequest} request ListBusinessUnitProfilesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitProfilesCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitProfilesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitProfiles = function listBusinessUnitProfiles(request, callback) {
                    return this.rpcCall(listBusinessUnitProfiles, $root.mtechnavi.api.company.ListBusinessUnitProfilesRequest, $root.mtechnavi.api.company.ListBusinessUnitProfilesResponse, request, callback);
                }, "name", { value: "ListBusinessUnitProfiles" });

                /**
                 * Calls ListBusinessUnitProfiles.
                 * @function listBusinessUnitProfiles
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitProfilesRequest} request ListBusinessUnitProfilesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitProfilesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitProfile} [response] BusinessUnitProfile
                 */

                /**
                 * Calls CreateBusinessUnitProfile.
                 * @function createBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitProfileRequest} request CreateBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and BusinessUnitProfile
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitProfile = function createBusinessUnitProfile(request, callback) {
                    return this.rpcCall(createBusinessUnitProfile, $root.mtechnavi.api.company.CreateBusinessUnitProfileRequest, $root.mtechnavi.api.company.BusinessUnitProfile, request, callback);
                }, "name", { value: "CreateBusinessUnitProfile" });

                /**
                 * Calls CreateBusinessUnitProfile.
                 * @function createBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitProfileRequest} request CreateBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitProfile>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitProfile} [response] BusinessUnitProfile
                 */

                /**
                 * Calls GetBusinessUnitProfile.
                 * @function getBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitProfileRequest} request GetBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and BusinessUnitProfile
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitProfile = function getBusinessUnitProfile(request, callback) {
                    return this.rpcCall(getBusinessUnitProfile, $root.mtechnavi.api.company.GetBusinessUnitProfileRequest, $root.mtechnavi.api.company.BusinessUnitProfile, request, callback);
                }, "name", { value: "GetBusinessUnitProfile" });

                /**
                 * Calls GetBusinessUnitProfile.
                 * @function getBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitProfileRequest} request GetBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitProfile>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitProfile} [response] BusinessUnitProfile
                 */

                /**
                 * Calls UpdateBusinessUnitProfile.
                 * @function updateBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitProfileRequest} request UpdateBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and BusinessUnitProfile
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitProfile = function updateBusinessUnitProfile(request, callback) {
                    return this.rpcCall(updateBusinessUnitProfile, $root.mtechnavi.api.company.UpdateBusinessUnitProfileRequest, $root.mtechnavi.api.company.BusinessUnitProfile, request, callback);
                }, "name", { value: "UpdateBusinessUnitProfile" });

                /**
                 * Calls UpdateBusinessUnitProfile.
                 * @function updateBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitProfileRequest} request UpdateBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitProfile>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitProfile.
                 * @function deleteBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitProfileRequest} request DeleteBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitProfile = function deleteBusinessUnitProfile(request, callback) {
                    return this.rpcCall(deleteBusinessUnitProfile, $root.mtechnavi.api.company.DeleteBusinessUnitProfileRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitProfile" });

                /**
                 * Calls DeleteBusinessUnitProfile.
                 * @function deleteBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitProfileRequest} request DeleteBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateSystemNotificationUsersInBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateSystemNotificationUsersInBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitProfile} [response] BusinessUnitProfile
                 */

                /**
                 * Calls UpdateSystemNotificationUsersInBusinessUnitProfile.
                 * @function updateSystemNotificationUsersInBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateSystemNotificationUsersInBusinessUnitProfileRequest} request UpdateSystemNotificationUsersInBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateSystemNotificationUsersInBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and BusinessUnitProfile
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateSystemNotificationUsersInBusinessUnitProfile = function updateSystemNotificationUsersInBusinessUnitProfile(request, callback) {
                    return this.rpcCall(updateSystemNotificationUsersInBusinessUnitProfile, $root.mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest, $root.mtechnavi.api.company.BusinessUnitProfile, request, callback);
                }, "name", { value: "UpdateSystemNotificationUsersInBusinessUnitProfile" });

                /**
                 * Calls UpdateSystemNotificationUsersInBusinessUnitProfile.
                 * @function updateSystemNotificationUsersInBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateSystemNotificationUsersInBusinessUnitProfileRequest} request UpdateSystemNotificationUsersInBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitProfile>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitFinancials}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitFinancialsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialsResponse} [response] ListBusinessUnitFinancialsResponse
                 */

                /**
                 * Calls ListBusinessUnitFinancials.
                 * @function listBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialsRequest} request ListBusinessUnitFinancialsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitFinancialsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitFinancialsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitFinancials = function listBusinessUnitFinancials(request, callback) {
                    return this.rpcCall(listBusinessUnitFinancials, $root.mtechnavi.api.company.ListBusinessUnitFinancialsRequest, $root.mtechnavi.api.company.ListBusinessUnitFinancialsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitFinancials" });

                /**
                 * Calls ListBusinessUnitFinancials.
                 * @function listBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialsRequest} request ListBusinessUnitFinancialsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitFinancialsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitFinancial}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitFinancialCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFinancial} [response] BusinessUnitFinancial
                 */

                /**
                 * Calls CreateBusinessUnitFinancial.
                 * @function createBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFinancialRequest} request CreateBusinessUnitFinancialRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitFinancialCallback} callback Node-style callback called with the error, if any, and BusinessUnitFinancial
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitFinancial = function createBusinessUnitFinancial(request, callback) {
                    return this.rpcCall(createBusinessUnitFinancial, $root.mtechnavi.api.company.CreateBusinessUnitFinancialRequest, $root.mtechnavi.api.company.BusinessUnitFinancial, request, callback);
                }, "name", { value: "CreateBusinessUnitFinancial" });

                /**
                 * Calls CreateBusinessUnitFinancial.
                 * @function createBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFinancialRequest} request CreateBusinessUnitFinancialRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFinancial>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitFinancial}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitFinancialCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFinancial} [response] BusinessUnitFinancial
                 */

                /**
                 * Calls GetBusinessUnitFinancial.
                 * @function getBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitFinancialRequest} request GetBusinessUnitFinancialRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitFinancialCallback} callback Node-style callback called with the error, if any, and BusinessUnitFinancial
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitFinancial = function getBusinessUnitFinancial(request, callback) {
                    return this.rpcCall(getBusinessUnitFinancial, $root.mtechnavi.api.company.GetBusinessUnitFinancialRequest, $root.mtechnavi.api.company.BusinessUnitFinancial, request, callback);
                }, "name", { value: "GetBusinessUnitFinancial" });

                /**
                 * Calls GetBusinessUnitFinancial.
                 * @function getBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitFinancialRequest} request GetBusinessUnitFinancialRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFinancial>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitFinancial}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitFinancialCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFinancial} [response] BusinessUnitFinancial
                 */

                /**
                 * Calls UpdateBusinessUnitFinancial.
                 * @function updateBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFinancialRequest} request UpdateBusinessUnitFinancialRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitFinancialCallback} callback Node-style callback called with the error, if any, and BusinessUnitFinancial
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitFinancial = function updateBusinessUnitFinancial(request, callback) {
                    return this.rpcCall(updateBusinessUnitFinancial, $root.mtechnavi.api.company.UpdateBusinessUnitFinancialRequest, $root.mtechnavi.api.company.BusinessUnitFinancial, request, callback);
                }, "name", { value: "UpdateBusinessUnitFinancial" });

                /**
                 * Calls UpdateBusinessUnitFinancial.
                 * @function updateBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFinancialRequest} request UpdateBusinessUnitFinancialRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFinancial>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitFinancial}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitFinancialCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitFinancial.
                 * @function deleteBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFinancialRequest} request DeleteBusinessUnitFinancialRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitFinancialCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitFinancial = function deleteBusinessUnitFinancial(request, callback) {
                    return this.rpcCall(deleteBusinessUnitFinancial, $root.mtechnavi.api.company.DeleteBusinessUnitFinancialRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitFinancial" });

                /**
                 * Calls DeleteBusinessUnitFinancial.
                 * @function deleteBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFinancialRequest} request DeleteBusinessUnitFinancialRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitBranchs}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitBranchsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitBranchsResponse} [response] ListBusinessUnitBranchsResponse
                 */

                /**
                 * Calls ListBusinessUnitBranchs.
                 * @function listBusinessUnitBranchs
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitBranchsRequest} request ListBusinessUnitBranchsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitBranchsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitBranchsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitBranchs = function listBusinessUnitBranchs(request, callback) {
                    return this.rpcCall(listBusinessUnitBranchs, $root.mtechnavi.api.company.ListBusinessUnitBranchsRequest, $root.mtechnavi.api.company.ListBusinessUnitBranchsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitBranchs" });

                /**
                 * Calls ListBusinessUnitBranchs.
                 * @function listBusinessUnitBranchs
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitBranchsRequest} request ListBusinessUnitBranchsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitBranchsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitBranch}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitBranchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitBranch} [response] BusinessUnitBranch
                 */

                /**
                 * Calls CreateBusinessUnitBranch.
                 * @function createBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitBranchRequest} request CreateBusinessUnitBranchRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitBranchCallback} callback Node-style callback called with the error, if any, and BusinessUnitBranch
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitBranch = function createBusinessUnitBranch(request, callback) {
                    return this.rpcCall(createBusinessUnitBranch, $root.mtechnavi.api.company.CreateBusinessUnitBranchRequest, $root.mtechnavi.api.company.BusinessUnitBranch, request, callback);
                }, "name", { value: "CreateBusinessUnitBranch" });

                /**
                 * Calls CreateBusinessUnitBranch.
                 * @function createBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitBranchRequest} request CreateBusinessUnitBranchRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitBranch>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitBranch}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitBranchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitBranch} [response] BusinessUnitBranch
                 */

                /**
                 * Calls GetBusinessUnitBranch.
                 * @function getBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitBranchRequest} request GetBusinessUnitBranchRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitBranchCallback} callback Node-style callback called with the error, if any, and BusinessUnitBranch
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitBranch = function getBusinessUnitBranch(request, callback) {
                    return this.rpcCall(getBusinessUnitBranch, $root.mtechnavi.api.company.GetBusinessUnitBranchRequest, $root.mtechnavi.api.company.BusinessUnitBranch, request, callback);
                }, "name", { value: "GetBusinessUnitBranch" });

                /**
                 * Calls GetBusinessUnitBranch.
                 * @function getBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitBranchRequest} request GetBusinessUnitBranchRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitBranch>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitBranch}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitBranchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitBranch} [response] BusinessUnitBranch
                 */

                /**
                 * Calls UpdateBusinessUnitBranch.
                 * @function updateBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitBranchRequest} request UpdateBusinessUnitBranchRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitBranchCallback} callback Node-style callback called with the error, if any, and BusinessUnitBranch
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitBranch = function updateBusinessUnitBranch(request, callback) {
                    return this.rpcCall(updateBusinessUnitBranch, $root.mtechnavi.api.company.UpdateBusinessUnitBranchRequest, $root.mtechnavi.api.company.BusinessUnitBranch, request, callback);
                }, "name", { value: "UpdateBusinessUnitBranch" });

                /**
                 * Calls UpdateBusinessUnitBranch.
                 * @function updateBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitBranchRequest} request UpdateBusinessUnitBranchRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitBranch>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitBranch}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitBranchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitBranch.
                 * @function deleteBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitBranchRequest} request DeleteBusinessUnitBranchRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitBranchCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitBranch = function deleteBusinessUnitBranch(request, callback) {
                    return this.rpcCall(deleteBusinessUnitBranch, $root.mtechnavi.api.company.DeleteBusinessUnitBranchRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitBranch" });

                /**
                 * Calls DeleteBusinessUnitBranch.
                 * @function deleteBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitBranchRequest} request DeleteBusinessUnitBranchRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitFacilitiess}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitFacilitiessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiessResponse} [response] ListBusinessUnitFacilitiessResponse
                 */

                /**
                 * Calls ListBusinessUnitFacilitiess.
                 * @function listBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiessRequest} request ListBusinessUnitFacilitiessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitFacilitiessCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitFacilitiessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitFacilitiess = function listBusinessUnitFacilitiess(request, callback) {
                    return this.rpcCall(listBusinessUnitFacilitiess, $root.mtechnavi.api.company.ListBusinessUnitFacilitiessRequest, $root.mtechnavi.api.company.ListBusinessUnitFacilitiessResponse, request, callback);
                }, "name", { value: "ListBusinessUnitFacilitiess" });

                /**
                 * Calls ListBusinessUnitFacilitiess.
                 * @function listBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiessRequest} request ListBusinessUnitFacilitiessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitFacilitiessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitFacilities}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitFacilitiesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFacilities} [response] BusinessUnitFacilities
                 */

                /**
                 * Calls CreateBusinessUnitFacilities.
                 * @function createBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFacilitiesRequest} request CreateBusinessUnitFacilitiesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitFacilitiesCallback} callback Node-style callback called with the error, if any, and BusinessUnitFacilities
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitFacilities = function createBusinessUnitFacilities(request, callback) {
                    return this.rpcCall(createBusinessUnitFacilities, $root.mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest, $root.mtechnavi.api.company.BusinessUnitFacilities, request, callback);
                }, "name", { value: "CreateBusinessUnitFacilities" });

                /**
                 * Calls CreateBusinessUnitFacilities.
                 * @function createBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFacilitiesRequest} request CreateBusinessUnitFacilitiesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFacilities>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitFacilities}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitFacilitiesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFacilities} [response] BusinessUnitFacilities
                 */

                /**
                 * Calls GetBusinessUnitFacilities.
                 * @function getBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitFacilitiesRequest} request GetBusinessUnitFacilitiesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitFacilitiesCallback} callback Node-style callback called with the error, if any, and BusinessUnitFacilities
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitFacilities = function getBusinessUnitFacilities(request, callback) {
                    return this.rpcCall(getBusinessUnitFacilities, $root.mtechnavi.api.company.GetBusinessUnitFacilitiesRequest, $root.mtechnavi.api.company.BusinessUnitFacilities, request, callback);
                }, "name", { value: "GetBusinessUnitFacilities" });

                /**
                 * Calls GetBusinessUnitFacilities.
                 * @function getBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitFacilitiesRequest} request GetBusinessUnitFacilitiesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFacilities>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitFacilities}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitFacilitiesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFacilities} [response] BusinessUnitFacilities
                 */

                /**
                 * Calls UpdateBusinessUnitFacilities.
                 * @function updateBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFacilitiesRequest} request UpdateBusinessUnitFacilitiesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitFacilitiesCallback} callback Node-style callback called with the error, if any, and BusinessUnitFacilities
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitFacilities = function updateBusinessUnitFacilities(request, callback) {
                    return this.rpcCall(updateBusinessUnitFacilities, $root.mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest, $root.mtechnavi.api.company.BusinessUnitFacilities, request, callback);
                }, "name", { value: "UpdateBusinessUnitFacilities" });

                /**
                 * Calls UpdateBusinessUnitFacilities.
                 * @function updateBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFacilitiesRequest} request UpdateBusinessUnitFacilitiesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFacilities>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitFacilities}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitFacilitiesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitFacilities.
                 * @function deleteBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFacilitiesRequest} request DeleteBusinessUnitFacilitiesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitFacilitiesCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitFacilities = function deleteBusinessUnitFacilities(request, callback) {
                    return this.rpcCall(deleteBusinessUnitFacilities, $root.mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitFacilities" });

                /**
                 * Calls DeleteBusinessUnitFacilities.
                 * @function deleteBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFacilitiesRequest} request DeleteBusinessUnitFacilitiesRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitCertificates}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitCertificatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificatesResponse} [response] ListBusinessUnitCertificatesResponse
                 */

                /**
                 * Calls ListBusinessUnitCertificates.
                 * @function listBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificatesRequest} request ListBusinessUnitCertificatesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitCertificatesCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitCertificatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitCertificates = function listBusinessUnitCertificates(request, callback) {
                    return this.rpcCall(listBusinessUnitCertificates, $root.mtechnavi.api.company.ListBusinessUnitCertificatesRequest, $root.mtechnavi.api.company.ListBusinessUnitCertificatesResponse, request, callback);
                }, "name", { value: "ListBusinessUnitCertificates" });

                /**
                 * Calls ListBusinessUnitCertificates.
                 * @function listBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificatesRequest} request ListBusinessUnitCertificatesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitCertificatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitCertificate}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitCertificateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitCertificate} [response] BusinessUnitCertificate
                 */

                /**
                 * Calls CreateBusinessUnitCertificate.
                 * @function createBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitCertificateRequest} request CreateBusinessUnitCertificateRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitCertificateCallback} callback Node-style callback called with the error, if any, and BusinessUnitCertificate
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitCertificate = function createBusinessUnitCertificate(request, callback) {
                    return this.rpcCall(createBusinessUnitCertificate, $root.mtechnavi.api.company.CreateBusinessUnitCertificateRequest, $root.mtechnavi.api.company.BusinessUnitCertificate, request, callback);
                }, "name", { value: "CreateBusinessUnitCertificate" });

                /**
                 * Calls CreateBusinessUnitCertificate.
                 * @function createBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitCertificateRequest} request CreateBusinessUnitCertificateRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitCertificate>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitCertificate}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitCertificateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitCertificate} [response] BusinessUnitCertificate
                 */

                /**
                 * Calls GetBusinessUnitCertificate.
                 * @function getBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitCertificateRequest} request GetBusinessUnitCertificateRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitCertificateCallback} callback Node-style callback called with the error, if any, and BusinessUnitCertificate
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitCertificate = function getBusinessUnitCertificate(request, callback) {
                    return this.rpcCall(getBusinessUnitCertificate, $root.mtechnavi.api.company.GetBusinessUnitCertificateRequest, $root.mtechnavi.api.company.BusinessUnitCertificate, request, callback);
                }, "name", { value: "GetBusinessUnitCertificate" });

                /**
                 * Calls GetBusinessUnitCertificate.
                 * @function getBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitCertificateRequest} request GetBusinessUnitCertificateRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitCertificate>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitCertificate}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitCertificateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitCertificate} [response] BusinessUnitCertificate
                 */

                /**
                 * Calls UpdateBusinessUnitCertificate.
                 * @function updateBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitCertificateRequest} request UpdateBusinessUnitCertificateRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitCertificateCallback} callback Node-style callback called with the error, if any, and BusinessUnitCertificate
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitCertificate = function updateBusinessUnitCertificate(request, callback) {
                    return this.rpcCall(updateBusinessUnitCertificate, $root.mtechnavi.api.company.UpdateBusinessUnitCertificateRequest, $root.mtechnavi.api.company.BusinessUnitCertificate, request, callback);
                }, "name", { value: "UpdateBusinessUnitCertificate" });

                /**
                 * Calls UpdateBusinessUnitCertificate.
                 * @function updateBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitCertificateRequest} request UpdateBusinessUnitCertificateRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitCertificate>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitCertificate}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitCertificateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitCertificate.
                 * @function deleteBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitCertificateRequest} request DeleteBusinessUnitCertificateRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitCertificateCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitCertificate = function deleteBusinessUnitCertificate(request, callback) {
                    return this.rpcCall(deleteBusinessUnitCertificate, $root.mtechnavi.api.company.DeleteBusinessUnitCertificateRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitCertificate" });

                /**
                 * Calls DeleteBusinessUnitCertificate.
                 * @function deleteBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitCertificateRequest} request DeleteBusinessUnitCertificateRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitStrengths}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitStrengthsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitStrengthsResponse} [response] ListBusinessUnitStrengthsResponse
                 */

                /**
                 * Calls ListBusinessUnitStrengths.
                 * @function listBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitStrengthsRequest} request ListBusinessUnitStrengthsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitStrengthsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitStrengthsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitStrengths = function listBusinessUnitStrengths(request, callback) {
                    return this.rpcCall(listBusinessUnitStrengths, $root.mtechnavi.api.company.ListBusinessUnitStrengthsRequest, $root.mtechnavi.api.company.ListBusinessUnitStrengthsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitStrengths" });

                /**
                 * Calls ListBusinessUnitStrengths.
                 * @function listBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitStrengthsRequest} request ListBusinessUnitStrengthsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitStrengthsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitStrength}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitStrengthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitStrength} [response] BusinessUnitStrength
                 */

                /**
                 * Calls CreateBusinessUnitStrength.
                 * @function createBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitStrengthRequest} request CreateBusinessUnitStrengthRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitStrengthCallback} callback Node-style callback called with the error, if any, and BusinessUnitStrength
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitStrength = function createBusinessUnitStrength(request, callback) {
                    return this.rpcCall(createBusinessUnitStrength, $root.mtechnavi.api.company.CreateBusinessUnitStrengthRequest, $root.mtechnavi.api.company.BusinessUnitStrength, request, callback);
                }, "name", { value: "CreateBusinessUnitStrength" });

                /**
                 * Calls CreateBusinessUnitStrength.
                 * @function createBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitStrengthRequest} request CreateBusinessUnitStrengthRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitStrength>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitStrength}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitStrengthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitStrength} [response] BusinessUnitStrength
                 */

                /**
                 * Calls GetBusinessUnitStrength.
                 * @function getBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitStrengthRequest} request GetBusinessUnitStrengthRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitStrengthCallback} callback Node-style callback called with the error, if any, and BusinessUnitStrength
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitStrength = function getBusinessUnitStrength(request, callback) {
                    return this.rpcCall(getBusinessUnitStrength, $root.mtechnavi.api.company.GetBusinessUnitStrengthRequest, $root.mtechnavi.api.company.BusinessUnitStrength, request, callback);
                }, "name", { value: "GetBusinessUnitStrength" });

                /**
                 * Calls GetBusinessUnitStrength.
                 * @function getBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitStrengthRequest} request GetBusinessUnitStrengthRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitStrength>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitStrength}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitStrengthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitStrength} [response] BusinessUnitStrength
                 */

                /**
                 * Calls UpdateBusinessUnitStrength.
                 * @function updateBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitStrengthRequest} request UpdateBusinessUnitStrengthRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitStrengthCallback} callback Node-style callback called with the error, if any, and BusinessUnitStrength
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitStrength = function updateBusinessUnitStrength(request, callback) {
                    return this.rpcCall(updateBusinessUnitStrength, $root.mtechnavi.api.company.UpdateBusinessUnitStrengthRequest, $root.mtechnavi.api.company.BusinessUnitStrength, request, callback);
                }, "name", { value: "UpdateBusinessUnitStrength" });

                /**
                 * Calls UpdateBusinessUnitStrength.
                 * @function updateBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitStrengthRequest} request UpdateBusinessUnitStrengthRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitStrength>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitStrength}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitStrengthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitStrength.
                 * @function deleteBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitStrengthRequest} request DeleteBusinessUnitStrengthRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitStrengthCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitStrength = function deleteBusinessUnitStrength(request, callback) {
                    return this.rpcCall(deleteBusinessUnitStrength, $root.mtechnavi.api.company.DeleteBusinessUnitStrengthRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitStrength" });

                /**
                 * Calls DeleteBusinessUnitStrength.
                 * @function deleteBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitStrengthRequest} request DeleteBusinessUnitStrengthRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitSkills}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitSkillsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillsResponse} [response] ListBusinessUnitSkillsResponse
                 */

                /**
                 * Calls ListBusinessUnitSkills.
                 * @function listBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillsRequest} request ListBusinessUnitSkillsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitSkillsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitSkillsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitSkills = function listBusinessUnitSkills(request, callback) {
                    return this.rpcCall(listBusinessUnitSkills, $root.mtechnavi.api.company.ListBusinessUnitSkillsRequest, $root.mtechnavi.api.company.ListBusinessUnitSkillsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitSkills" });

                /**
                 * Calls ListBusinessUnitSkills.
                 * @function listBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillsRequest} request ListBusinessUnitSkillsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitSkillsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitSkill}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitSkillCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSkill} [response] BusinessUnitSkill
                 */

                /**
                 * Calls CreateBusinessUnitSkill.
                 * @function createBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSkillRequest} request CreateBusinessUnitSkillRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitSkillCallback} callback Node-style callback called with the error, if any, and BusinessUnitSkill
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitSkill = function createBusinessUnitSkill(request, callback) {
                    return this.rpcCall(createBusinessUnitSkill, $root.mtechnavi.api.company.CreateBusinessUnitSkillRequest, $root.mtechnavi.api.company.BusinessUnitSkill, request, callback);
                }, "name", { value: "CreateBusinessUnitSkill" });

                /**
                 * Calls CreateBusinessUnitSkill.
                 * @function createBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSkillRequest} request CreateBusinessUnitSkillRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSkill>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitSkill}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitSkillCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSkill} [response] BusinessUnitSkill
                 */

                /**
                 * Calls GetBusinessUnitSkill.
                 * @function getBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillRequest} request GetBusinessUnitSkillRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitSkillCallback} callback Node-style callback called with the error, if any, and BusinessUnitSkill
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitSkill = function getBusinessUnitSkill(request, callback) {
                    return this.rpcCall(getBusinessUnitSkill, $root.mtechnavi.api.company.GetBusinessUnitSkillRequest, $root.mtechnavi.api.company.BusinessUnitSkill, request, callback);
                }, "name", { value: "GetBusinessUnitSkill" });

                /**
                 * Calls GetBusinessUnitSkill.
                 * @function getBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillRequest} request GetBusinessUnitSkillRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSkill>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitSkill}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitSkillCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSkill} [response] BusinessUnitSkill
                 */

                /**
                 * Calls UpdateBusinessUnitSkill.
                 * @function updateBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSkillRequest} request UpdateBusinessUnitSkillRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitSkillCallback} callback Node-style callback called with the error, if any, and BusinessUnitSkill
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitSkill = function updateBusinessUnitSkill(request, callback) {
                    return this.rpcCall(updateBusinessUnitSkill, $root.mtechnavi.api.company.UpdateBusinessUnitSkillRequest, $root.mtechnavi.api.company.BusinessUnitSkill, request, callback);
                }, "name", { value: "UpdateBusinessUnitSkill" });

                /**
                 * Calls UpdateBusinessUnitSkill.
                 * @function updateBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSkillRequest} request UpdateBusinessUnitSkillRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSkill>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitSkill}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitSkillCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitSkill.
                 * @function deleteBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSkillRequest} request DeleteBusinessUnitSkillRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitSkillCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitSkill = function deleteBusinessUnitSkill(request, callback) {
                    return this.rpcCall(deleteBusinessUnitSkill, $root.mtechnavi.api.company.DeleteBusinessUnitSkillRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitSkill" });

                /**
                 * Calls DeleteBusinessUnitSkill.
                 * @function deleteBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSkillRequest} request DeleteBusinessUnitSkillRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitSkillTree}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitSkillTreeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSkillTree} [response] BusinessUnitSkillTree
                 */

                /**
                 * Calls GetBusinessUnitSkillTree.
                 * @function getBusinessUnitSkillTree
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillTreeRequest} request GetBusinessUnitSkillTreeRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitSkillTreeCallback} callback Node-style callback called with the error, if any, and BusinessUnitSkillTree
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitSkillTree = function getBusinessUnitSkillTree(request, callback) {
                    return this.rpcCall(getBusinessUnitSkillTree, $root.mtechnavi.api.company.GetBusinessUnitSkillTreeRequest, $root.mtechnavi.api.company.BusinessUnitSkillTree, request, callback);
                }, "name", { value: "GetBusinessUnitSkillTree" });

                /**
                 * Calls GetBusinessUnitSkillTree.
                 * @function getBusinessUnitSkillTree
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillTreeRequest} request GetBusinessUnitSkillTreeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSkillTree>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitContactHeaders}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitContactHeadersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitContactHeadersResponse} [response] ListBusinessUnitContactHeadersResponse
                 */

                /**
                 * Calls ListBusinessUnitContactHeaders.
                 * @function listBusinessUnitContactHeaders
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactHeadersRequest} request ListBusinessUnitContactHeadersRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitContactHeadersCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitContactHeadersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitContactHeaders = function listBusinessUnitContactHeaders(request, callback) {
                    return this.rpcCall(listBusinessUnitContactHeaders, $root.mtechnavi.api.company.ListBusinessUnitContactHeadersRequest, $root.mtechnavi.api.company.ListBusinessUnitContactHeadersResponse, request, callback);
                }, "name", { value: "ListBusinessUnitContactHeaders" });

                /**
                 * Calls ListBusinessUnitContactHeaders.
                 * @function listBusinessUnitContactHeaders
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactHeadersRequest} request ListBusinessUnitContactHeadersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitContactHeadersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitContactHeader}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitContactHeaderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactHeader} [response] BusinessUnitContactHeader
                 */

                /**
                 * Calls CreateBusinessUnitContactHeader.
                 * @function createBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactHeaderRequest} request CreateBusinessUnitContactHeaderRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitContactHeaderCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactHeader
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitContactHeader = function createBusinessUnitContactHeader(request, callback) {
                    return this.rpcCall(createBusinessUnitContactHeader, $root.mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest, $root.mtechnavi.api.company.BusinessUnitContactHeader, request, callback);
                }, "name", { value: "CreateBusinessUnitContactHeader" });

                /**
                 * Calls CreateBusinessUnitContactHeader.
                 * @function createBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactHeaderRequest} request CreateBusinessUnitContactHeaderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactHeader>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitContactHeader}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitContactHeaderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactHeader} [response] BusinessUnitContactHeader
                 */

                /**
                 * Calls GetBusinessUnitContactHeader.
                 * @function getBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactHeaderRequest} request GetBusinessUnitContactHeaderRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitContactHeaderCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactHeader
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitContactHeader = function getBusinessUnitContactHeader(request, callback) {
                    return this.rpcCall(getBusinessUnitContactHeader, $root.mtechnavi.api.company.GetBusinessUnitContactHeaderRequest, $root.mtechnavi.api.company.BusinessUnitContactHeader, request, callback);
                }, "name", { value: "GetBusinessUnitContactHeader" });

                /**
                 * Calls GetBusinessUnitContactHeader.
                 * @function getBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactHeaderRequest} request GetBusinessUnitContactHeaderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactHeader>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitContactHeader}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitContactHeaderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactHeader} [response] BusinessUnitContactHeader
                 */

                /**
                 * Calls UpdateBusinessUnitContactHeader.
                 * @function updateBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactHeaderRequest} request UpdateBusinessUnitContactHeaderRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitContactHeaderCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactHeader
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitContactHeader = function updateBusinessUnitContactHeader(request, callback) {
                    return this.rpcCall(updateBusinessUnitContactHeader, $root.mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest, $root.mtechnavi.api.company.BusinessUnitContactHeader, request, callback);
                }, "name", { value: "UpdateBusinessUnitContactHeader" });

                /**
                 * Calls UpdateBusinessUnitContactHeader.
                 * @function updateBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactHeaderRequest} request UpdateBusinessUnitContactHeaderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactHeader>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitContactHeader}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitContactHeaderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitContactHeader.
                 * @function deleteBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactHeaderRequest} request DeleteBusinessUnitContactHeaderRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitContactHeaderCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitContactHeader = function deleteBusinessUnitContactHeader(request, callback) {
                    return this.rpcCall(deleteBusinessUnitContactHeader, $root.mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitContactHeader" });

                /**
                 * Calls DeleteBusinessUnitContactHeader.
                 * @function deleteBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactHeaderRequest} request DeleteBusinessUnitContactHeaderRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitContacts}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitContactsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitContactsResponse} [response] ListBusinessUnitContactsResponse
                 */

                /**
                 * Calls ListBusinessUnitContacts.
                 * @function listBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactsRequest} request ListBusinessUnitContactsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitContactsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitContactsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitContacts = function listBusinessUnitContacts(request, callback) {
                    return this.rpcCall(listBusinessUnitContacts, $root.mtechnavi.api.company.ListBusinessUnitContactsRequest, $root.mtechnavi.api.company.ListBusinessUnitContactsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitContacts" });

                /**
                 * Calls ListBusinessUnitContacts.
                 * @function listBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactsRequest} request ListBusinessUnitContactsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitContactsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContact} [response] BusinessUnitContact
                 */

                /**
                 * Calls CreateBusinessUnitContact.
                 * @function createBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactRequest} request CreateBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and BusinessUnitContact
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitContact = function createBusinessUnitContact(request, callback) {
                    return this.rpcCall(createBusinessUnitContact, $root.mtechnavi.api.company.CreateBusinessUnitContactRequest, $root.mtechnavi.api.company.BusinessUnitContact, request, callback);
                }, "name", { value: "CreateBusinessUnitContact" });

                /**
                 * Calls CreateBusinessUnitContact.
                 * @function createBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactRequest} request CreateBusinessUnitContactRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContact>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContact} [response] BusinessUnitContact
                 */

                /**
                 * Calls GetBusinessUnitContact.
                 * @function getBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactRequest} request GetBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and BusinessUnitContact
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitContact = function getBusinessUnitContact(request, callback) {
                    return this.rpcCall(getBusinessUnitContact, $root.mtechnavi.api.company.GetBusinessUnitContactRequest, $root.mtechnavi.api.company.BusinessUnitContact, request, callback);
                }, "name", { value: "GetBusinessUnitContact" });

                /**
                 * Calls GetBusinessUnitContact.
                 * @function getBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactRequest} request GetBusinessUnitContactRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContact>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContact} [response] BusinessUnitContact
                 */

                /**
                 * Calls UpdateBusinessUnitContact.
                 * @function updateBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactRequest} request UpdateBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and BusinessUnitContact
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitContact = function updateBusinessUnitContact(request, callback) {
                    return this.rpcCall(updateBusinessUnitContact, $root.mtechnavi.api.company.UpdateBusinessUnitContactRequest, $root.mtechnavi.api.company.BusinessUnitContact, request, callback);
                }, "name", { value: "UpdateBusinessUnitContact" });

                /**
                 * Calls UpdateBusinessUnitContact.
                 * @function updateBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactRequest} request UpdateBusinessUnitContactRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContact>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitContact.
                 * @function deleteBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactRequest} request DeleteBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitContact = function deleteBusinessUnitContact(request, callback) {
                    return this.rpcCall(deleteBusinessUnitContact, $root.mtechnavi.api.company.DeleteBusinessUnitContactRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitContact" });

                /**
                 * Calls DeleteBusinessUnitContact.
                 * @function deleteBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactRequest} request DeleteBusinessUnitContactRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#activateMainContactInBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ActivateMainContactInBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls ActivateMainContactInBusinessUnitContact.
                 * @function activateMainContactInBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IActivateMainContactInBusinessUnitContactRequest} request ActivateMainContactInBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ActivateMainContactInBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.activateMainContactInBusinessUnitContact = function activateMainContactInBusinessUnitContact(request, callback) {
                    return this.rpcCall(activateMainContactInBusinessUnitContact, $root.mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "ActivateMainContactInBusinessUnitContact" });

                /**
                 * Calls ActivateMainContactInBusinessUnitContact.
                 * @function activateMainContactInBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IActivateMainContactInBusinessUnitContactRequest} request ActivateMainContactInBusinessUnitContactRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitContactAttributes}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitContactAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitContactAttributesResponse} [response] ListBusinessUnitContactAttributesResponse
                 */

                /**
                 * Calls ListBusinessUnitContactAttributes.
                 * @function listBusinessUnitContactAttributes
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactAttributesRequest} request ListBusinessUnitContactAttributesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitContactAttributesCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitContactAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitContactAttributes = function listBusinessUnitContactAttributes(request, callback) {
                    return this.rpcCall(listBusinessUnitContactAttributes, $root.mtechnavi.api.company.ListBusinessUnitContactAttributesRequest, $root.mtechnavi.api.company.ListBusinessUnitContactAttributesResponse, request, callback);
                }, "name", { value: "ListBusinessUnitContactAttributes" });

                /**
                 * Calls ListBusinessUnitContactAttributes.
                 * @function listBusinessUnitContactAttributes
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactAttributesRequest} request ListBusinessUnitContactAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitContactAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitContactAttribute}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitContactAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactAttribute} [response] BusinessUnitContactAttribute
                 */

                /**
                 * Calls CreateBusinessUnitContactAttribute.
                 * @function createBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactAttributeRequest} request CreateBusinessUnitContactAttributeRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitContactAttributeCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitContactAttribute = function createBusinessUnitContactAttribute(request, callback) {
                    return this.rpcCall(createBusinessUnitContactAttribute, $root.mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest, $root.mtechnavi.api.company.BusinessUnitContactAttribute, request, callback);
                }, "name", { value: "CreateBusinessUnitContactAttribute" });

                /**
                 * Calls CreateBusinessUnitContactAttribute.
                 * @function createBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactAttributeRequest} request CreateBusinessUnitContactAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitContactAttribute}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitContactAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactAttribute} [response] BusinessUnitContactAttribute
                 */

                /**
                 * Calls GetBusinessUnitContactAttribute.
                 * @function getBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactAttributeRequest} request GetBusinessUnitContactAttributeRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitContactAttributeCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitContactAttribute = function getBusinessUnitContactAttribute(request, callback) {
                    return this.rpcCall(getBusinessUnitContactAttribute, $root.mtechnavi.api.company.GetBusinessUnitContactAttributeRequest, $root.mtechnavi.api.company.BusinessUnitContactAttribute, request, callback);
                }, "name", { value: "GetBusinessUnitContactAttribute" });

                /**
                 * Calls GetBusinessUnitContactAttribute.
                 * @function getBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactAttributeRequest} request GetBusinessUnitContactAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitContactAttribute}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitContactAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactAttribute} [response] BusinessUnitContactAttribute
                 */

                /**
                 * Calls UpdateBusinessUnitContactAttribute.
                 * @function updateBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactAttributeRequest} request UpdateBusinessUnitContactAttributeRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitContactAttributeCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitContactAttribute = function updateBusinessUnitContactAttribute(request, callback) {
                    return this.rpcCall(updateBusinessUnitContactAttribute, $root.mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest, $root.mtechnavi.api.company.BusinessUnitContactAttribute, request, callback);
                }, "name", { value: "UpdateBusinessUnitContactAttribute" });

                /**
                 * Calls UpdateBusinessUnitContactAttribute.
                 * @function updateBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactAttributeRequest} request UpdateBusinessUnitContactAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitContactAttribute}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitContactAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitContactAttribute.
                 * @function deleteBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactAttributeRequest} request DeleteBusinessUnitContactAttributeRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitContactAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitContactAttribute = function deleteBusinessUnitContactAttribute(request, callback) {
                    return this.rpcCall(deleteBusinessUnitContactAttribute, $root.mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitContactAttribute" });

                /**
                 * Calls DeleteBusinessUnitContactAttribute.
                 * @function deleteBusinessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactAttributeRequest} request DeleteBusinessUnitContactAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitDocuments}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitDocumentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitDocumentsResponse} [response] ListBusinessUnitDocumentsResponse
                 */

                /**
                 * Calls ListBusinessUnitDocuments.
                 * @function listBusinessUnitDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitDocumentsRequest} request ListBusinessUnitDocumentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitDocumentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitDocumentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitDocuments = function listBusinessUnitDocuments(request, callback) {
                    return this.rpcCall(listBusinessUnitDocuments, $root.mtechnavi.api.company.ListBusinessUnitDocumentsRequest, $root.mtechnavi.api.company.ListBusinessUnitDocumentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitDocuments" });

                /**
                 * Calls ListBusinessUnitDocuments.
                 * @function listBusinessUnitDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitDocumentsRequest} request ListBusinessUnitDocumentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitDocumentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitDocument} [response] BusinessUnitDocument
                 */

                /**
                 * Calls CreateBusinessUnitDocument.
                 * @function createBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitDocumentRequest} request CreateBusinessUnitDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitDocumentCallback} callback Node-style callback called with the error, if any, and BusinessUnitDocument
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitDocument = function createBusinessUnitDocument(request, callback) {
                    return this.rpcCall(createBusinessUnitDocument, $root.mtechnavi.api.company.CreateBusinessUnitDocumentRequest, $root.mtechnavi.api.company.BusinessUnitDocument, request, callback);
                }, "name", { value: "CreateBusinessUnitDocument" });

                /**
                 * Calls CreateBusinessUnitDocument.
                 * @function createBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitDocumentRequest} request CreateBusinessUnitDocumentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitDocument>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitDocument} [response] BusinessUnitDocument
                 */

                /**
                 * Calls GetBusinessUnitDocument.
                 * @function getBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitDocumentRequest} request GetBusinessUnitDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitDocumentCallback} callback Node-style callback called with the error, if any, and BusinessUnitDocument
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitDocument = function getBusinessUnitDocument(request, callback) {
                    return this.rpcCall(getBusinessUnitDocument, $root.mtechnavi.api.company.GetBusinessUnitDocumentRequest, $root.mtechnavi.api.company.BusinessUnitDocument, request, callback);
                }, "name", { value: "GetBusinessUnitDocument" });

                /**
                 * Calls GetBusinessUnitDocument.
                 * @function getBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitDocumentRequest} request GetBusinessUnitDocumentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitDocument>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitDocument} [response] BusinessUnitDocument
                 */

                /**
                 * Calls UpdateBusinessUnitDocument.
                 * @function updateBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitDocumentRequest} request UpdateBusinessUnitDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitDocumentCallback} callback Node-style callback called with the error, if any, and BusinessUnitDocument
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitDocument = function updateBusinessUnitDocument(request, callback) {
                    return this.rpcCall(updateBusinessUnitDocument, $root.mtechnavi.api.company.UpdateBusinessUnitDocumentRequest, $root.mtechnavi.api.company.BusinessUnitDocument, request, callback);
                }, "name", { value: "UpdateBusinessUnitDocument" });

                /**
                 * Calls UpdateBusinessUnitDocument.
                 * @function updateBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitDocumentRequest} request UpdateBusinessUnitDocumentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitDocument>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitDocument.
                 * @function deleteBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitDocumentRequest} request DeleteBusinessUnitDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitDocumentCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitDocument = function deleteBusinessUnitDocument(request, callback) {
                    return this.rpcCall(deleteBusinessUnitDocument, $root.mtechnavi.api.company.DeleteBusinessUnitDocumentRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitDocument" });

                /**
                 * Calls DeleteBusinessUnitDocument.
                 * @function deleteBusinessUnitDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitDocumentRequest} request DeleteBusinessUnitDocumentRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitSnapshots}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitSnapshotsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitSnapshotsResponse} [response] ListBusinessUnitSnapshotsResponse
                 */

                /**
                 * Calls ListBusinessUnitSnapshots.
                 * @function listBusinessUnitSnapshots
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSnapshotsRequest} request ListBusinessUnitSnapshotsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitSnapshotsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitSnapshotsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitSnapshots = function listBusinessUnitSnapshots(request, callback) {
                    return this.rpcCall(listBusinessUnitSnapshots, $root.mtechnavi.api.company.ListBusinessUnitSnapshotsRequest, $root.mtechnavi.api.company.ListBusinessUnitSnapshotsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitSnapshots" });

                /**
                 * Calls ListBusinessUnitSnapshots.
                 * @function listBusinessUnitSnapshots
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSnapshotsRequest} request ListBusinessUnitSnapshotsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitSnapshotsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitSnapshot}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitSnapshotCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSnapshot} [response] BusinessUnitSnapshot
                 */

                /**
                 * Calls CreateBusinessUnitSnapshot.
                 * @function createBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSnapshotRequest} request CreateBusinessUnitSnapshotRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitSnapshotCallback} callback Node-style callback called with the error, if any, and BusinessUnitSnapshot
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitSnapshot = function createBusinessUnitSnapshot(request, callback) {
                    return this.rpcCall(createBusinessUnitSnapshot, $root.mtechnavi.api.company.CreateBusinessUnitSnapshotRequest, $root.mtechnavi.api.company.BusinessUnitSnapshot, request, callback);
                }, "name", { value: "CreateBusinessUnitSnapshot" });

                /**
                 * Calls CreateBusinessUnitSnapshot.
                 * @function createBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSnapshotRequest} request CreateBusinessUnitSnapshotRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSnapshot>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitSnapshot}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitSnapshotCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSnapshot} [response] BusinessUnitSnapshot
                 */

                /**
                 * Calls GetBusinessUnitSnapshot.
                 * @function getBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSnapshotRequest} request GetBusinessUnitSnapshotRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitSnapshotCallback} callback Node-style callback called with the error, if any, and BusinessUnitSnapshot
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitSnapshot = function getBusinessUnitSnapshot(request, callback) {
                    return this.rpcCall(getBusinessUnitSnapshot, $root.mtechnavi.api.company.GetBusinessUnitSnapshotRequest, $root.mtechnavi.api.company.BusinessUnitSnapshot, request, callback);
                }, "name", { value: "GetBusinessUnitSnapshot" });

                /**
                 * Calls GetBusinessUnitSnapshot.
                 * @function getBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitSnapshotRequest} request GetBusinessUnitSnapshotRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSnapshot>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitSnapshot}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitSnapshotCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSnapshot} [response] BusinessUnitSnapshot
                 */

                /**
                 * Calls UpdateBusinessUnitSnapshot.
                 * @function updateBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSnapshotRequest} request UpdateBusinessUnitSnapshotRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitSnapshotCallback} callback Node-style callback called with the error, if any, and BusinessUnitSnapshot
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitSnapshot = function updateBusinessUnitSnapshot(request, callback) {
                    return this.rpcCall(updateBusinessUnitSnapshot, $root.mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest, $root.mtechnavi.api.company.BusinessUnitSnapshot, request, callback);
                }, "name", { value: "UpdateBusinessUnitSnapshot" });

                /**
                 * Calls UpdateBusinessUnitSnapshot.
                 * @function updateBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSnapshotRequest} request UpdateBusinessUnitSnapshotRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSnapshot>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitSnapshot}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitSnapshotCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitSnapshot.
                 * @function deleteBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSnapshotRequest} request DeleteBusinessUnitSnapshotRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitSnapshotCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitSnapshot = function deleteBusinessUnitSnapshot(request, callback) {
                    return this.rpcCall(deleteBusinessUnitSnapshot, $root.mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitSnapshot" });

                /**
                 * Calls DeleteBusinessUnitSnapshot.
                 * @function deleteBusinessUnitSnapshot
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSnapshotRequest} request DeleteBusinessUnitSnapshotRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitChangeNotifications}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitChangeNotificationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse} [response] ListBusinessUnitChangeNotificationsResponse
                 */

                /**
                 * Calls ListBusinessUnitChangeNotifications.
                 * @function listBusinessUnitChangeNotifications
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitChangeNotificationsRequest} request ListBusinessUnitChangeNotificationsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitChangeNotificationsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitChangeNotificationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitChangeNotifications = function listBusinessUnitChangeNotifications(request, callback) {
                    return this.rpcCall(listBusinessUnitChangeNotifications, $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest, $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitChangeNotifications" });

                /**
                 * Calls ListBusinessUnitChangeNotifications.
                 * @function listBusinessUnitChangeNotifications
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitChangeNotificationsRequest} request ListBusinessUnitChangeNotificationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitChangeNotification}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitChangeNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitChangeNotification} [response] BusinessUnitChangeNotification
                 */

                /**
                 * Calls CreateBusinessUnitChangeNotification.
                 * @function createBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitChangeNotificationRequest} request CreateBusinessUnitChangeNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitChangeNotificationCallback} callback Node-style callback called with the error, if any, and BusinessUnitChangeNotification
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitChangeNotification = function createBusinessUnitChangeNotification(request, callback) {
                    return this.rpcCall(createBusinessUnitChangeNotification, $root.mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest, $root.mtechnavi.api.company.BusinessUnitChangeNotification, request, callback);
                }, "name", { value: "CreateBusinessUnitChangeNotification" });

                /**
                 * Calls CreateBusinessUnitChangeNotification.
                 * @function createBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitChangeNotificationRequest} request CreateBusinessUnitChangeNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitChangeNotification>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitChangeNotification}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitChangeNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitChangeNotification} [response] BusinessUnitChangeNotification
                 */

                /**
                 * Calls GetBusinessUnitChangeNotification.
                 * @function getBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitChangeNotificationRequest} request GetBusinessUnitChangeNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitChangeNotificationCallback} callback Node-style callback called with the error, if any, and BusinessUnitChangeNotification
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitChangeNotification = function getBusinessUnitChangeNotification(request, callback) {
                    return this.rpcCall(getBusinessUnitChangeNotification, $root.mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest, $root.mtechnavi.api.company.BusinessUnitChangeNotification, request, callback);
                }, "name", { value: "GetBusinessUnitChangeNotification" });

                /**
                 * Calls GetBusinessUnitChangeNotification.
                 * @function getBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitChangeNotificationRequest} request GetBusinessUnitChangeNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitChangeNotification>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitChangeNotification}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitChangeNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitChangeNotification} [response] BusinessUnitChangeNotification
                 */

                /**
                 * Calls UpdateBusinessUnitChangeNotification.
                 * @function updateBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitChangeNotificationRequest} request UpdateBusinessUnitChangeNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitChangeNotificationCallback} callback Node-style callback called with the error, if any, and BusinessUnitChangeNotification
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitChangeNotification = function updateBusinessUnitChangeNotification(request, callback) {
                    return this.rpcCall(updateBusinessUnitChangeNotification, $root.mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest, $root.mtechnavi.api.company.BusinessUnitChangeNotification, request, callback);
                }, "name", { value: "UpdateBusinessUnitChangeNotification" });

                /**
                 * Calls UpdateBusinessUnitChangeNotification.
                 * @function updateBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitChangeNotificationRequest} request UpdateBusinessUnitChangeNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitChangeNotification>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitChangeNotification}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitChangeNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitChangeNotification.
                 * @function deleteBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitChangeNotificationRequest} request DeleteBusinessUnitChangeNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitChangeNotificationCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitChangeNotification = function deleteBusinessUnitChangeNotification(request, callback) {
                    return this.rpcCall(deleteBusinessUnitChangeNotification, $root.mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitChangeNotification" });

                /**
                 * Calls DeleteBusinessUnitChangeNotification.
                 * @function deleteBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitChangeNotificationRequest} request DeleteBusinessUnitChangeNotificationRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitContactContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitContactContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitContactContentsResponse} [response] ListBusinessUnitContactContentsResponse
                 */

                /**
                 * Calls ListBusinessUnitContactContents.
                 * @function listBusinessUnitContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactContentsRequest} request ListBusinessUnitContactContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitContactContentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitContactContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitContactContents = function listBusinessUnitContactContents(request, callback) {
                    return this.rpcCall(listBusinessUnitContactContents, $root.mtechnavi.api.company.ListBusinessUnitContactContentsRequest, $root.mtechnavi.api.company.ListBusinessUnitContactContentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitContactContents" });

                /**
                 * Calls ListBusinessUnitContactContents.
                 * @function listBusinessUnitContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactContentsRequest} request ListBusinessUnitContactContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitContactContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#getBusinessUnitContactContent}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef GetBusinessUnitContactContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitContactContent} [response] BusinessUnitContactContent
                 */

                /**
                 * Calls GetBusinessUnitContactContent.
                 * @function getBusinessUnitContactContent
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactContentRequest} request GetBusinessUnitContactContentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.GetBusinessUnitContactContentCallback} callback Node-style callback called with the error, if any, and BusinessUnitContactContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.getBusinessUnitContactContent = function getBusinessUnitContactContent(request, callback) {
                    return this.rpcCall(getBusinessUnitContactContent, $root.mtechnavi.api.company.GetBusinessUnitContactContentRequest, $root.mtechnavi.api.company.BusinessUnitContactContent, request, callback);
                }, "name", { value: "GetBusinessUnitContactContent" });

                /**
                 * Calls GetBusinessUnitContactContent.
                 * @function getBusinessUnitContactContent
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactContentRequest} request GetBusinessUnitContactContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitContactContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitProfiles}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitProfilesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitProfilesResponse} [response] SharedListBusinessUnitProfilesResponse
                 */

                /**
                 * Calls SharedListBusinessUnitProfiles.
                 * @function sharedListBusinessUnitProfiles
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitProfilesRequest} request SharedListBusinessUnitProfilesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitProfilesCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitProfilesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitProfiles = function sharedListBusinessUnitProfiles(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitProfiles, $root.mtechnavi.api.company.SharedListBusinessUnitProfilesRequest, $root.mtechnavi.api.company.SharedListBusinessUnitProfilesResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitProfiles" });

                /**
                 * Calls SharedListBusinessUnitProfiles.
                 * @function sharedListBusinessUnitProfiles
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitProfilesRequest} request SharedListBusinessUnitProfilesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitProfilesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitProfile} [response] BusinessUnitProfile
                 */

                /**
                 * Calls SharedGetBusinessUnitProfile.
                 * @function sharedGetBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitProfileRequest} request SharedGetBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and BusinessUnitProfile
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitProfile = function sharedGetBusinessUnitProfile(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitProfile, $root.mtechnavi.api.company.SharedGetBusinessUnitProfileRequest, $root.mtechnavi.api.company.BusinessUnitProfile, request, callback);
                }, "name", { value: "SharedGetBusinessUnitProfile" });

                /**
                 * Calls SharedGetBusinessUnitProfile.
                 * @function sharedGetBusinessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitProfileRequest} request SharedGetBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitProfile>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitFinancials}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitFinancialsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse} [response] SharedListBusinessUnitFinancialsResponse
                 */

                /**
                 * Calls SharedListBusinessUnitFinancials.
                 * @function sharedListBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFinancialsRequest} request SharedListBusinessUnitFinancialsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitFinancialsCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitFinancialsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitFinancials = function sharedListBusinessUnitFinancials(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitFinancials, $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest, $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitFinancials" });

                /**
                 * Calls SharedListBusinessUnitFinancials.
                 * @function sharedListBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFinancialsRequest} request SharedListBusinessUnitFinancialsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitFinancial}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitFinancialCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFinancial} [response] BusinessUnitFinancial
                 */

                /**
                 * Calls SharedGetBusinessUnitFinancial.
                 * @function sharedGetBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFinancialRequest} request SharedGetBusinessUnitFinancialRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitFinancialCallback} callback Node-style callback called with the error, if any, and BusinessUnitFinancial
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitFinancial = function sharedGetBusinessUnitFinancial(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitFinancial, $root.mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest, $root.mtechnavi.api.company.BusinessUnitFinancial, request, callback);
                }, "name", { value: "SharedGetBusinessUnitFinancial" });

                /**
                 * Calls SharedGetBusinessUnitFinancial.
                 * @function sharedGetBusinessUnitFinancial
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFinancialRequest} request SharedGetBusinessUnitFinancialRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFinancial>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitBranchs}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitBranchsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitBranchsResponse} [response] SharedListBusinessUnitBranchsResponse
                 */

                /**
                 * Calls SharedListBusinessUnitBranchs.
                 * @function sharedListBusinessUnitBranchs
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitBranchsRequest} request SharedListBusinessUnitBranchsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitBranchsCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitBranchsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitBranchs = function sharedListBusinessUnitBranchs(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitBranchs, $root.mtechnavi.api.company.SharedListBusinessUnitBranchsRequest, $root.mtechnavi.api.company.SharedListBusinessUnitBranchsResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitBranchs" });

                /**
                 * Calls SharedListBusinessUnitBranchs.
                 * @function sharedListBusinessUnitBranchs
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitBranchsRequest} request SharedListBusinessUnitBranchsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitBranchsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitBranch}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitBranchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitBranch} [response] BusinessUnitBranch
                 */

                /**
                 * Calls SharedGetBusinessUnitBranch.
                 * @function sharedGetBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitBranchRequest} request SharedGetBusinessUnitBranchRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitBranchCallback} callback Node-style callback called with the error, if any, and BusinessUnitBranch
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitBranch = function sharedGetBusinessUnitBranch(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitBranch, $root.mtechnavi.api.company.SharedGetBusinessUnitBranchRequest, $root.mtechnavi.api.company.BusinessUnitBranch, request, callback);
                }, "name", { value: "SharedGetBusinessUnitBranch" });

                /**
                 * Calls SharedGetBusinessUnitBranch.
                 * @function sharedGetBusinessUnitBranch
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitBranchRequest} request SharedGetBusinessUnitBranchRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitBranch>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitFacilitiess}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitFacilitiessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse} [response] SharedListBusinessUnitFacilitiessResponse
                 */

                /**
                 * Calls SharedListBusinessUnitFacilitiess.
                 * @function sharedListBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFacilitiessRequest} request SharedListBusinessUnitFacilitiessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitFacilitiessCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitFacilitiessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitFacilitiess = function sharedListBusinessUnitFacilitiess(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitFacilitiess, $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest, $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitFacilitiess" });

                /**
                 * Calls SharedListBusinessUnitFacilitiess.
                 * @function sharedListBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFacilitiessRequest} request SharedListBusinessUnitFacilitiessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitFacilities}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitFacilitiesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitFacilities} [response] BusinessUnitFacilities
                 */

                /**
                 * Calls SharedGetBusinessUnitFacilities.
                 * @function sharedGetBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFacilitiesRequest} request SharedGetBusinessUnitFacilitiesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitFacilitiesCallback} callback Node-style callback called with the error, if any, and BusinessUnitFacilities
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitFacilities = function sharedGetBusinessUnitFacilities(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitFacilities, $root.mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest, $root.mtechnavi.api.company.BusinessUnitFacilities, request, callback);
                }, "name", { value: "SharedGetBusinessUnitFacilities" });

                /**
                 * Calls SharedGetBusinessUnitFacilities.
                 * @function sharedGetBusinessUnitFacilities
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFacilitiesRequest} request SharedGetBusinessUnitFacilitiesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitFacilities>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitCertificates}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitCertificatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse} [response] SharedListBusinessUnitCertificatesResponse
                 */

                /**
                 * Calls SharedListBusinessUnitCertificates.
                 * @function sharedListBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitCertificatesRequest} request SharedListBusinessUnitCertificatesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitCertificatesCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitCertificatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitCertificates = function sharedListBusinessUnitCertificates(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitCertificates, $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest, $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitCertificates" });

                /**
                 * Calls SharedListBusinessUnitCertificates.
                 * @function sharedListBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitCertificatesRequest} request SharedListBusinessUnitCertificatesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitCertificate}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitCertificateCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitCertificate} [response] BusinessUnitCertificate
                 */

                /**
                 * Calls SharedGetBusinessUnitCertificate.
                 * @function sharedGetBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitCertificateRequest} request SharedGetBusinessUnitCertificateRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitCertificateCallback} callback Node-style callback called with the error, if any, and BusinessUnitCertificate
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitCertificate = function sharedGetBusinessUnitCertificate(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitCertificate, $root.mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest, $root.mtechnavi.api.company.BusinessUnitCertificate, request, callback);
                }, "name", { value: "SharedGetBusinessUnitCertificate" });

                /**
                 * Calls SharedGetBusinessUnitCertificate.
                 * @function sharedGetBusinessUnitCertificate
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitCertificateRequest} request SharedGetBusinessUnitCertificateRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitCertificate>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitStrengths}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitStrengthsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse} [response] SharedListBusinessUnitStrengthsResponse
                 */

                /**
                 * Calls SharedListBusinessUnitStrengths.
                 * @function sharedListBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitStrengthsRequest} request SharedListBusinessUnitStrengthsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitStrengthsCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitStrengthsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitStrengths = function sharedListBusinessUnitStrengths(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitStrengths, $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest, $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitStrengths" });

                /**
                 * Calls SharedListBusinessUnitStrengths.
                 * @function sharedListBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitStrengthsRequest} request SharedListBusinessUnitStrengthsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitStrength}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitStrengthCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitStrength} [response] BusinessUnitStrength
                 */

                /**
                 * Calls SharedGetBusinessUnitStrength.
                 * @function sharedGetBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitStrengthRequest} request SharedGetBusinessUnitStrengthRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitStrengthCallback} callback Node-style callback called with the error, if any, and BusinessUnitStrength
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitStrength = function sharedGetBusinessUnitStrength(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitStrength, $root.mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest, $root.mtechnavi.api.company.BusinessUnitStrength, request, callback);
                }, "name", { value: "SharedGetBusinessUnitStrength" });

                /**
                 * Calls SharedGetBusinessUnitStrength.
                 * @function sharedGetBusinessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitStrengthRequest} request SharedGetBusinessUnitStrengthRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitStrength>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitSkills}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitSkillsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitSkillsResponse} [response] SharedListBusinessUnitSkillsResponse
                 */

                /**
                 * Calls SharedListBusinessUnitSkills.
                 * @function sharedListBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitSkillsRequest} request SharedListBusinessUnitSkillsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitSkillsCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitSkillsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitSkills = function sharedListBusinessUnitSkills(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitSkills, $root.mtechnavi.api.company.SharedListBusinessUnitSkillsRequest, $root.mtechnavi.api.company.SharedListBusinessUnitSkillsResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitSkills" });

                /**
                 * Calls SharedListBusinessUnitSkills.
                 * @function sharedListBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitSkillsRequest} request SharedListBusinessUnitSkillsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitSkillsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedGetBusinessUnitSkill}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedGetBusinessUnitSkillCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitSkill} [response] BusinessUnitSkill
                 */

                /**
                 * Calls SharedGetBusinessUnitSkill.
                 * @function sharedGetBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitSkillRequest} request SharedGetBusinessUnitSkillRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedGetBusinessUnitSkillCallback} callback Node-style callback called with the error, if any, and BusinessUnitSkill
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedGetBusinessUnitSkill = function sharedGetBusinessUnitSkill(request, callback) {
                    return this.rpcCall(sharedGetBusinessUnitSkill, $root.mtechnavi.api.company.SharedGetBusinessUnitSkillRequest, $root.mtechnavi.api.company.BusinessUnitSkill, request, callback);
                }, "name", { value: "SharedGetBusinessUnitSkill" });

                /**
                 * Calls SharedGetBusinessUnitSkill.
                 * @function sharedGetBusinessUnitSkill
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitSkillRequest} request SharedGetBusinessUnitSkillRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitSkill>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharetoBusinessUnitContactHeader}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharetoBusinessUnitContactHeaderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse} [response] SharetoBusinessUnitContactHeaderResponse
                 */

                /**
                 * Calls SharetoBusinessUnitContactHeader.
                 * @function sharetoBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactHeaderRequest} request SharetoBusinessUnitContactHeaderRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharetoBusinessUnitContactHeaderCallback} callback Node-style callback called with the error, if any, and SharetoBusinessUnitContactHeaderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharetoBusinessUnitContactHeader = function sharetoBusinessUnitContactHeader(request, callback) {
                    return this.rpcCall(sharetoBusinessUnitContactHeader, $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest, $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse, request, callback);
                }, "name", { value: "SharetoBusinessUnitContactHeader" });

                /**
                 * Calls SharetoBusinessUnitContactHeader.
                 * @function sharetoBusinessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactHeaderRequest} request SharetoBusinessUnitContactHeaderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharetoBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharetoBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactResponse} [response] SharetoBusinessUnitContactResponse
                 */

                /**
                 * Calls SharetoBusinessUnitContact.
                 * @function sharetoBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactRequest} request SharetoBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharetoBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and SharetoBusinessUnitContactResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharetoBusinessUnitContact = function sharetoBusinessUnitContact(request, callback) {
                    return this.rpcCall(sharetoBusinessUnitContact, $root.mtechnavi.api.company.SharetoBusinessUnitContactRequest, $root.mtechnavi.api.company.SharetoBusinessUnitContactResponse, request, callback);
                }, "name", { value: "SharetoBusinessUnitContact" });

                /**
                 * Calls SharetoBusinessUnitContact.
                 * @function sharetoBusinessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactRequest} request SharetoBusinessUnitContactRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharetoBusinessUnitContactResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#startBusiness}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef StartBusinessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.StartBusinessResponse} [response] StartBusinessResponse
                 */

                /**
                 * Calls StartBusiness.
                 * @function startBusiness
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IStartBusinessRequest} request StartBusinessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.StartBusinessCallback} callback Node-style callback called with the error, if any, and StartBusinessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.startBusiness = function startBusiness(request, callback) {
                    return this.rpcCall(startBusiness, $root.mtechnavi.api.company.StartBusinessRequest, $root.mtechnavi.api.company.StartBusinessResponse, request, callback);
                }, "name", { value: "StartBusiness" });

                /**
                 * Calls StartBusiness.
                 * @function startBusiness
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IStartBusinessRequest} request StartBusinessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.StartBusinessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#stopBusiness}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef StopBusinessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.StopBusinessResponse} [response] StopBusinessResponse
                 */

                /**
                 * Calls StopBusiness.
                 * @function stopBusiness
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IStopBusinessRequest} request StopBusinessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.StopBusinessCallback} callback Node-style callback called with the error, if any, and StopBusinessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.stopBusiness = function stopBusiness(request, callback) {
                    return this.rpcCall(stopBusiness, $root.mtechnavi.api.company.StopBusinessRequest, $root.mtechnavi.api.company.StopBusinessResponse, request, callback);
                }, "name", { value: "StopBusiness" });

                /**
                 * Calls StopBusiness.
                 * @function stopBusiness
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IStopBusinessRequest} request StopBusinessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.StopBusinessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#confirmBusinessUnitChangeNotification}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ConfirmBusinessUnitChangeNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse} [response] ConfirmBusinessUnitChangeNotificationResponse
                 */

                /**
                 * Calls ConfirmBusinessUnitChangeNotification.
                 * @function confirmBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest} request ConfirmBusinessUnitChangeNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ConfirmBusinessUnitChangeNotificationCallback} callback Node-style callback called with the error, if any, and ConfirmBusinessUnitChangeNotificationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.confirmBusinessUnitChangeNotification = function confirmBusinessUnitChangeNotification(request, callback) {
                    return this.rpcCall(confirmBusinessUnitChangeNotification, $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest, $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse, request, callback);
                }, "name", { value: "ConfirmBusinessUnitChangeNotification" });

                /**
                 * Calls ConfirmBusinessUnitChangeNotification.
                 * @function confirmBusinessUnitChangeNotification
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest} request ConfirmBusinessUnitChangeNotificationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitManagements}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementsResponse} [response] ExportBusinessUnitManagementsResponse
                 */

                /**
                 * Calls ExportBusinessUnitManagements.
                 * @function exportBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementsRequest} request ExportBusinessUnitManagementsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitManagementsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitManagements = function exportBusinessUnitManagements(request, callback) {
                    return this.rpcCall(exportBusinessUnitManagements, $root.mtechnavi.api.company.ExportBusinessUnitManagementsRequest, $root.mtechnavi.api.company.ExportBusinessUnitManagementsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitManagements" });

                /**
                 * Calls ExportBusinessUnitManagements.
                 * @function exportBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementsRequest} request ExportBusinessUnitManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitManagements}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementsResponse} [response] ImportBusinessUnitManagementsResponse
                 */

                /**
                 * Calls ImportBusinessUnitManagements.
                 * @function importBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementsRequest} request ImportBusinessUnitManagementsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitManagementsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitManagements = function importBusinessUnitManagements(request, callback) {
                    return this.rpcCall(importBusinessUnitManagements, $root.mtechnavi.api.company.ImportBusinessUnitManagementsRequest, $root.mtechnavi.api.company.ImportBusinessUnitManagementsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitManagements" });

                /**
                 * Calls ImportBusinessUnitManagements.
                 * @function importBusinessUnitManagements
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementsRequest} request ImportBusinessUnitManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitManagementFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitManagementFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse} [response] ListBusinessUnitManagementFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitManagementFormats.
                 * @function listBusinessUnitManagementFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementFormatsRequest} request ListBusinessUnitManagementFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitManagementFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitManagementFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitManagementFormats = function listBusinessUnitManagementFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitManagementFormats, $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitManagementFormats" });

                /**
                 * Calls ListBusinessUnitManagementFormats.
                 * @function listBusinessUnitManagementFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementFormatsRequest} request ListBusinessUnitManagementFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitScores}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitScoresCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitScoresResponse} [response] ExportBusinessUnitScoresResponse
                 */

                /**
                 * Calls ExportBusinessUnitScores.
                 * @function exportBusinessUnitScores
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitScoresRequest} request ExportBusinessUnitScoresRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitScoresCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitScoresResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitScores = function exportBusinessUnitScores(request, callback) {
                    return this.rpcCall(exportBusinessUnitScores, $root.mtechnavi.api.company.ExportBusinessUnitScoresRequest, $root.mtechnavi.api.company.ExportBusinessUnitScoresResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitScores" });

                /**
                 * Calls ExportBusinessUnitScores.
                 * @function exportBusinessUnitScores
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitScoresRequest} request ExportBusinessUnitScoresRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitScoresResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitScores}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitScoresCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitScoresResponse} [response] ImportBusinessUnitScoresResponse
                 */

                /**
                 * Calls ImportBusinessUnitScores.
                 * @function importBusinessUnitScores
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitScoresRequest} request ImportBusinessUnitScoresRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitScoresCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitScoresResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitScores = function importBusinessUnitScores(request, callback) {
                    return this.rpcCall(importBusinessUnitScores, $root.mtechnavi.api.company.ImportBusinessUnitScoresRequest, $root.mtechnavi.api.company.ImportBusinessUnitScoresResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitScores" });

                /**
                 * Calls ImportBusinessUnitScores.
                 * @function importBusinessUnitScores
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitScoresRequest} request ImportBusinessUnitScoresRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitScoresResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitScoreFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitScoreFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse} [response] ListBusinessUnitScoreFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitScoreFormats.
                 * @function listBusinessUnitScoreFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitScoreFormatsRequest} request ListBusinessUnitScoreFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitScoreFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitScoreFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitScoreFormats = function listBusinessUnitScoreFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitScoreFormats, $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitScoreFormats" });

                /**
                 * Calls ListBusinessUnitScoreFormats.
                 * @function listBusinessUnitScoreFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitScoreFormatsRequest} request ListBusinessUnitScoreFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitManagementItemValues}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitManagementItemValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse} [response] ExportBusinessUnitManagementItemValuesResponse
                 */

                /**
                 * Calls ExportBusinessUnitManagementItemValues.
                 * @function exportBusinessUnitManagementItemValues
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementItemValuesRequest} request ExportBusinessUnitManagementItemValuesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitManagementItemValuesCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitManagementItemValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitManagementItemValues = function exportBusinessUnitManagementItemValues(request, callback) {
                    return this.rpcCall(exportBusinessUnitManagementItemValues, $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest, $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitManagementItemValues" });

                /**
                 * Calls ExportBusinessUnitManagementItemValues.
                 * @function exportBusinessUnitManagementItemValues
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementItemValuesRequest} request ExportBusinessUnitManagementItemValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitManagementItemValues}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitManagementItemValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse} [response] ImportBusinessUnitManagementItemValuesResponse
                 */

                /**
                 * Calls ImportBusinessUnitManagementItemValues.
                 * @function importBusinessUnitManagementItemValues
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementItemValuesRequest} request ImportBusinessUnitManagementItemValuesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitManagementItemValuesCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitManagementItemValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitManagementItemValues = function importBusinessUnitManagementItemValues(request, callback) {
                    return this.rpcCall(importBusinessUnitManagementItemValues, $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest, $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitManagementItemValues" });

                /**
                 * Calls ImportBusinessUnitManagementItemValues.
                 * @function importBusinessUnitManagementItemValues
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementItemValuesRequest} request ImportBusinessUnitManagementItemValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitManagementItemValueFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitManagementItemValueFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse} [response] ListBusinessUnitManagementItemValueFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitManagementItemValueFormats.
                 * @function listBusinessUnitManagementItemValueFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementItemValueFormatsRequest} request ListBusinessUnitManagementItemValueFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitManagementItemValueFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitManagementItemValueFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitManagementItemValueFormats = function listBusinessUnitManagementItemValueFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitManagementItemValueFormats, $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitManagementItemValueFormats" });

                /**
                 * Calls ListBusinessUnitManagementItemValueFormats.
                 * @function listBusinessUnitManagementItemValueFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementItemValueFormatsRequest} request ListBusinessUnitManagementItemValueFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listSharedContactContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListSharedContactContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListSharedContactContentsResponse} [response] ListSharedContactContentsResponse
                 */

                /**
                 * Calls ListSharedContactContents.
                 * @function listSharedContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListSharedContactContentsRequest} request ListSharedContactContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListSharedContactContentsCallback} callback Node-style callback called with the error, if any, and ListSharedContactContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listSharedContactContents = function listSharedContactContents(request, callback) {
                    return this.rpcCall(listSharedContactContents, $root.mtechnavi.api.company.ListSharedContactContentsRequest, $root.mtechnavi.api.company.ListSharedContactContentsResponse, request, callback);
                }, "name", { value: "ListSharedContactContents" });

                /**
                 * Calls ListSharedContactContents.
                 * @function listSharedContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListSharedContactContentsRequest} request ListSharedContactContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListSharedContactContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitRankReception}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitRankReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRankReception} [response] BusinessUnitRankReception
                 */

                /**
                 * Calls CreateBusinessUnitRankReception.
                 * @function createBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRankReceptionRequest} request CreateBusinessUnitRankReceptionRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitRankReceptionCallback} callback Node-style callback called with the error, if any, and BusinessUnitRankReception
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitRankReception = function createBusinessUnitRankReception(request, callback) {
                    return this.rpcCall(createBusinessUnitRankReception, $root.mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest, $root.mtechnavi.api.company.BusinessUnitRankReception, request, callback);
                }, "name", { value: "CreateBusinessUnitRankReception" });

                /**
                 * Calls CreateBusinessUnitRankReception.
                 * @function createBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRankReceptionRequest} request CreateBusinessUnitRankReceptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRankReception>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitRankReception}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitRankReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitRankReception} [response] BusinessUnitRankReception
                 */

                /**
                 * Calls UpdateBusinessUnitRankReception.
                 * @function updateBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRankReceptionRequest} request UpdateBusinessUnitRankReceptionRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitRankReceptionCallback} callback Node-style callback called with the error, if any, and BusinessUnitRankReception
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitRankReception = function updateBusinessUnitRankReception(request, callback) {
                    return this.rpcCall(updateBusinessUnitRankReception, $root.mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest, $root.mtechnavi.api.company.BusinessUnitRankReception, request, callback);
                }, "name", { value: "UpdateBusinessUnitRankReception" });

                /**
                 * Calls UpdateBusinessUnitRankReception.
                 * @function updateBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRankReceptionRequest} request UpdateBusinessUnitRankReceptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitRankReception>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitRankReception}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitRankReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitRankReception.
                 * @function deleteBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRankReceptionRequest} request DeleteBusinessUnitRankReceptionRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitRankReceptionCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitRankReception = function deleteBusinessUnitRankReception(request, callback) {
                    return this.rpcCall(deleteBusinessUnitRankReception, $root.mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitRankReception" });

                /**
                 * Calls DeleteBusinessUnitRankReception.
                 * @function deleteBusinessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRankReceptionRequest} request DeleteBusinessUnitRankReceptionRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitRankRequestContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitRankRequestContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse} [response] ListBusinessUnitRankRequestContentsResponse
                 */

                /**
                 * Calls ListBusinessUnitRankRequestContents.
                 * @function listBusinessUnitRankRequestContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestContentsRequest} request ListBusinessUnitRankRequestContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitRankRequestContentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRankRequestContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitRankRequestContents = function listBusinessUnitRankRequestContents(request, callback) {
                    return this.rpcCall(listBusinessUnitRankRequestContents, $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest, $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRankRequestContents" });

                /**
                 * Calls ListBusinessUnitRankRequestContents.
                 * @function listBusinessUnitRankRequestContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestContentsRequest} request ListBusinessUnitRankRequestContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitRankReceptionContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitRankReceptionContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse} [response] ListBusinessUnitRankReceptionContentsResponse
                 */

                /**
                 * Calls ListBusinessUnitRankReceptionContents.
                 * @function listBusinessUnitRankReceptionContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionContentsRequest} request ListBusinessUnitRankReceptionContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitRankReceptionContentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRankReceptionContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitRankReceptionContents = function listBusinessUnitRankReceptionContents(request, callback) {
                    return this.rpcCall(listBusinessUnitRankReceptionContents, $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest, $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRankReceptionContents" });

                /**
                 * Calls ListBusinessUnitRankReceptionContents.
                 * @function listBusinessUnitRankReceptionContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionContentsRequest} request ListBusinessUnitRankReceptionContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitRankRequests}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitRankRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse} [response] ExportBusinessUnitRankRequestsResponse
                 */

                /**
                 * Calls ExportBusinessUnitRankRequests.
                 * @function exportBusinessUnitRankRequests
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankRequestsRequest} request ExportBusinessUnitRankRequestsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitRankRequestsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitRankRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitRankRequests = function exportBusinessUnitRankRequests(request, callback) {
                    return this.rpcCall(exportBusinessUnitRankRequests, $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest, $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitRankRequests" });

                /**
                 * Calls ExportBusinessUnitRankRequests.
                 * @function exportBusinessUnitRankRequests
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankRequestsRequest} request ExportBusinessUnitRankRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitRankRequests}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitRankRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse} [response] ImportBusinessUnitRankRequestsResponse
                 */

                /**
                 * Calls ImportBusinessUnitRankRequests.
                 * @function importBusinessUnitRankRequests
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankRequestsRequest} request ImportBusinessUnitRankRequestsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitRankRequestsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitRankRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitRankRequests = function importBusinessUnitRankRequests(request, callback) {
                    return this.rpcCall(importBusinessUnitRankRequests, $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest, $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitRankRequests" });

                /**
                 * Calls ImportBusinessUnitRankRequests.
                 * @function importBusinessUnitRankRequests
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankRequestsRequest} request ImportBusinessUnitRankRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitRankRequestFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitRankRequestFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse} [response] ListBusinessUnitRankRequestFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitRankRequestFormats.
                 * @function listBusinessUnitRankRequestFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestFormatsRequest} request ListBusinessUnitRankRequestFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitRankRequestFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRankRequestFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitRankRequestFormats = function listBusinessUnitRankRequestFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitRankRequestFormats, $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRankRequestFormats" });

                /**
                 * Calls ListBusinessUnitRankRequestFormats.
                 * @function listBusinessUnitRankRequestFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestFormatsRequest} request ListBusinessUnitRankRequestFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitFinancials}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitFinancialsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse} [response] ListLocalBusinessUnitFinancialsResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitFinancials.
                 * @function listLocalBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFinancialsRequest} request ListLocalBusinessUnitFinancialsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitFinancialsCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitFinancialsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitFinancials = function listLocalBusinessUnitFinancials(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitFinancials, $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitFinancials" });

                /**
                 * Calls ListLocalBusinessUnitFinancials.
                 * @function listLocalBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFinancialsRequest} request ListLocalBusinessUnitFinancialsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitFacilitiess}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitFacilitiessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse} [response] ListLocalBusinessUnitFacilitiessResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitFacilitiess.
                 * @function listLocalBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFacilitiessRequest} request ListLocalBusinessUnitFacilitiessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitFacilitiessCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitFacilitiessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitFacilitiess = function listLocalBusinessUnitFacilitiess(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitFacilitiess, $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitFacilitiess" });

                /**
                 * Calls ListLocalBusinessUnitFacilitiess.
                 * @function listLocalBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFacilitiessRequest} request ListLocalBusinessUnitFacilitiessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitCertificates}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitCertificatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse} [response] ListLocalBusinessUnitCertificatesResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitCertificates.
                 * @function listLocalBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitCertificatesRequest} request ListLocalBusinessUnitCertificatesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitCertificatesCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitCertificatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitCertificates = function listLocalBusinessUnitCertificates(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitCertificates, $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitCertificates" });

                /**
                 * Calls ListLocalBusinessUnitCertificates.
                 * @function listLocalBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitCertificatesRequest} request ListLocalBusinessUnitCertificatesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitStrengths}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitStrengthsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse} [response] ListLocalBusinessUnitStrengthsResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitStrengths.
                 * @function listLocalBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitStrengthsRequest} request ListLocalBusinessUnitStrengthsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitStrengthsCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitStrengthsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitStrengths = function listLocalBusinessUnitStrengths(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitStrengths, $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitStrengths" });

                /**
                 * Calls ListLocalBusinessUnitStrengths.
                 * @function listLocalBusinessUnitStrengths
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitStrengthsRequest} request ListLocalBusinessUnitStrengthsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitSkills}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitSkillsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse} [response] ListLocalBusinessUnitSkillsResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitSkills.
                 * @function listLocalBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitSkillsRequest} request ListLocalBusinessUnitSkillsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitSkillsCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitSkillsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitSkills = function listLocalBusinessUnitSkills(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitSkills, $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitSkills" });

                /**
                 * Calls ListLocalBusinessUnitSkills.
                 * @function listLocalBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitSkillsRequest} request ListLocalBusinessUnitSkillsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitContactContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitContactContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse} [response] ListLocalBusinessUnitContactContentsResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitContactContents.
                 * @function listLocalBusinessUnitContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitContactContentsRequest} request ListLocalBusinessUnitContactContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitContactContentsCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitContactContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitContactContents = function listLocalBusinessUnitContactContents(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitContactContents, $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitContactContents" });

                /**
                 * Calls ListLocalBusinessUnitContactContents.
                 * @function listLocalBusinessUnitContactContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitContactContentsRequest} request ListLocalBusinessUnitContactContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listLocalBusinessUnitRankRequestContents}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListLocalBusinessUnitRankRequestContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse} [response] ListLocalBusinessUnitRankRequestContentsResponse
                 */

                /**
                 * Calls ListLocalBusinessUnitRankRequestContents.
                 * @function listLocalBusinessUnitRankRequestContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsRequest} request ListLocalBusinessUnitRankRequestContentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListLocalBusinessUnitRankRequestContentsCallback} callback Node-style callback called with the error, if any, and ListLocalBusinessUnitRankRequestContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listLocalBusinessUnitRankRequestContents = function listLocalBusinessUnitRankRequestContents(request, callback) {
                    return this.rpcCall(listLocalBusinessUnitRankRequestContents, $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest, $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse, request, callback);
                }, "name", { value: "ListLocalBusinessUnitRankRequestContents" });

                /**
                 * Calls ListLocalBusinessUnitRankRequestContents.
                 * @function listLocalBusinessUnitRankRequestContents
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsRequest} request ListLocalBusinessUnitRankRequestContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitContacts}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitContactsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitContactsResponse} [response] ExportBusinessUnitContactsResponse
                 */

                /**
                 * Calls ExportBusinessUnitContacts.
                 * @function exportBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitContactsRequest} request ExportBusinessUnitContactsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitContactsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitContactsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitContacts = function exportBusinessUnitContacts(request, callback) {
                    return this.rpcCall(exportBusinessUnitContacts, $root.mtechnavi.api.company.ExportBusinessUnitContactsRequest, $root.mtechnavi.api.company.ExportBusinessUnitContactsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitContacts" });

                /**
                 * Calls ExportBusinessUnitContacts.
                 * @function exportBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitContactsRequest} request ExportBusinessUnitContactsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitContactsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitContacts}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitContactsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitContactsResponse} [response] ImportBusinessUnitContactsResponse
                 */

                /**
                 * Calls ImportBusinessUnitContacts.
                 * @function importBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitContactsRequest} request ImportBusinessUnitContactsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitContactsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitContactsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitContacts = function importBusinessUnitContacts(request, callback) {
                    return this.rpcCall(importBusinessUnitContacts, $root.mtechnavi.api.company.ImportBusinessUnitContactsRequest, $root.mtechnavi.api.company.ImportBusinessUnitContactsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitContacts" });

                /**
                 * Calls ImportBusinessUnitContacts.
                 * @function importBusinessUnitContacts
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitContactsRequest} request ImportBusinessUnitContactsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitContactsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitContactFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitContactFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitContactFormatsResponse} [response] ListBusinessUnitContactFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitContactFormats.
                 * @function listBusinessUnitContactFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactFormatsRequest} request ListBusinessUnitContactFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitContactFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitContactFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitContactFormats = function listBusinessUnitContactFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitContactFormats, $root.mtechnavi.api.company.ListBusinessUnitContactFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitContactFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitContactFormats" });

                /**
                 * Calls ListBusinessUnitContactFormats.
                 * @function listBusinessUnitContactFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitContactFormatsRequest} request ListBusinessUnitContactFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitContactFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitFinancials}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitFinancialsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitFinancialsResponse} [response] ExportBusinessUnitFinancialsResponse
                 */

                /**
                 * Calls ExportBusinessUnitFinancials.
                 * @function exportBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitFinancialsRequest} request ExportBusinessUnitFinancialsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitFinancialsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitFinancialsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitFinancials = function exportBusinessUnitFinancials(request, callback) {
                    return this.rpcCall(exportBusinessUnitFinancials, $root.mtechnavi.api.company.ExportBusinessUnitFinancialsRequest, $root.mtechnavi.api.company.ExportBusinessUnitFinancialsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitFinancials" });

                /**
                 * Calls ExportBusinessUnitFinancials.
                 * @function exportBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitFinancialsRequest} request ExportBusinessUnitFinancialsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitFinancialsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitFinancials}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitFinancialsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitFinancialsResponse} [response] ImportBusinessUnitFinancialsResponse
                 */

                /**
                 * Calls ImportBusinessUnitFinancials.
                 * @function importBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitFinancialsRequest} request ImportBusinessUnitFinancialsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitFinancialsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitFinancialsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitFinancials = function importBusinessUnitFinancials(request, callback) {
                    return this.rpcCall(importBusinessUnitFinancials, $root.mtechnavi.api.company.ImportBusinessUnitFinancialsRequest, $root.mtechnavi.api.company.ImportBusinessUnitFinancialsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitFinancials" });

                /**
                 * Calls ImportBusinessUnitFinancials.
                 * @function importBusinessUnitFinancials
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitFinancialsRequest} request ImportBusinessUnitFinancialsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitFinancialsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitFinancialFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitFinancialFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse} [response] ListBusinessUnitFinancialFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitFinancialFormats.
                 * @function listBusinessUnitFinancialFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialFormatsRequest} request ListBusinessUnitFinancialFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitFinancialFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitFinancialFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitFinancialFormats = function listBusinessUnitFinancialFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitFinancialFormats, $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitFinancialFormats" });

                /**
                 * Calls ListBusinessUnitFinancialFormats.
                 * @function listBusinessUnitFinancialFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialFormatsRequest} request ListBusinessUnitFinancialFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitCertificates}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitCertificatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitCertificatesResponse} [response] ExportBusinessUnitCertificatesResponse
                 */

                /**
                 * Calls ExportBusinessUnitCertificates.
                 * @function exportBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitCertificatesRequest} request ExportBusinessUnitCertificatesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitCertificatesCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitCertificatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitCertificates = function exportBusinessUnitCertificates(request, callback) {
                    return this.rpcCall(exportBusinessUnitCertificates, $root.mtechnavi.api.company.ExportBusinessUnitCertificatesRequest, $root.mtechnavi.api.company.ExportBusinessUnitCertificatesResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitCertificates" });

                /**
                 * Calls ExportBusinessUnitCertificates.
                 * @function exportBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitCertificatesRequest} request ExportBusinessUnitCertificatesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitCertificatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitCertificates}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitCertificatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitCertificatesResponse} [response] ImportBusinessUnitCertificatesResponse
                 */

                /**
                 * Calls ImportBusinessUnitCertificates.
                 * @function importBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitCertificatesRequest} request ImportBusinessUnitCertificatesRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitCertificatesCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitCertificatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitCertificates = function importBusinessUnitCertificates(request, callback) {
                    return this.rpcCall(importBusinessUnitCertificates, $root.mtechnavi.api.company.ImportBusinessUnitCertificatesRequest, $root.mtechnavi.api.company.ImportBusinessUnitCertificatesResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitCertificates" });

                /**
                 * Calls ImportBusinessUnitCertificates.
                 * @function importBusinessUnitCertificates
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitCertificatesRequest} request ImportBusinessUnitCertificatesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitCertificatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitCertificateFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitCertificateFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse} [response] ListBusinessUnitCertificateFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitCertificateFormats.
                 * @function listBusinessUnitCertificateFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificateFormatsRequest} request ListBusinessUnitCertificateFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitCertificateFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitCertificateFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitCertificateFormats = function listBusinessUnitCertificateFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitCertificateFormats, $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitCertificateFormats" });

                /**
                 * Calls ListBusinessUnitCertificateFormats.
                 * @function listBusinessUnitCertificateFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificateFormatsRequest} request ListBusinessUnitCertificateFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitFacilitiess}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitFacilitiessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse} [response] ExportBusinessUnitFacilitiessResponse
                 */

                /**
                 * Calls ExportBusinessUnitFacilitiess.
                 * @function exportBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitFacilitiessRequest} request ExportBusinessUnitFacilitiessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitFacilitiessCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitFacilitiessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitFacilitiess = function exportBusinessUnitFacilitiess(request, callback) {
                    return this.rpcCall(exportBusinessUnitFacilitiess, $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest, $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitFacilitiess" });

                /**
                 * Calls ExportBusinessUnitFacilitiess.
                 * @function exportBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitFacilitiessRequest} request ExportBusinessUnitFacilitiessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitFacilitiess}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitFacilitiessCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse} [response] ImportBusinessUnitFacilitiessResponse
                 */

                /**
                 * Calls ImportBusinessUnitFacilitiess.
                 * @function importBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitFacilitiessRequest} request ImportBusinessUnitFacilitiessRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitFacilitiessCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitFacilitiessResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitFacilitiess = function importBusinessUnitFacilitiess(request, callback) {
                    return this.rpcCall(importBusinessUnitFacilitiess, $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest, $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitFacilitiess" });

                /**
                 * Calls ImportBusinessUnitFacilitiess.
                 * @function importBusinessUnitFacilitiess
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitFacilitiessRequest} request ImportBusinessUnitFacilitiessRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitFacilitiesFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitFacilitiesFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse} [response] ListBusinessUnitFacilitiesFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitFacilitiesFormats.
                 * @function listBusinessUnitFacilitiesFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiesFormatsRequest} request ListBusinessUnitFacilitiesFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitFacilitiesFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitFacilitiesFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitFacilitiesFormats = function listBusinessUnitFacilitiesFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitFacilitiesFormats, $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitFacilitiesFormats" });

                /**
                 * Calls ListBusinessUnitFacilitiesFormats.
                 * @function listBusinessUnitFacilitiesFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiesFormatsRequest} request ListBusinessUnitFacilitiesFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitSkills}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitSkillsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitSkillsResponse} [response] ExportBusinessUnitSkillsResponse
                 */

                /**
                 * Calls ExportBusinessUnitSkills.
                 * @function exportBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitSkillsRequest} request ExportBusinessUnitSkillsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitSkillsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitSkillsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitSkills = function exportBusinessUnitSkills(request, callback) {
                    return this.rpcCall(exportBusinessUnitSkills, $root.mtechnavi.api.company.ExportBusinessUnitSkillsRequest, $root.mtechnavi.api.company.ExportBusinessUnitSkillsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitSkills" });

                /**
                 * Calls ExportBusinessUnitSkills.
                 * @function exportBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitSkillsRequest} request ExportBusinessUnitSkillsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitSkillsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitSkills}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitSkillsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitSkillsResponse} [response] ImportBusinessUnitSkillsResponse
                 */

                /**
                 * Calls ImportBusinessUnitSkills.
                 * @function importBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitSkillsRequest} request ImportBusinessUnitSkillsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitSkillsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitSkillsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitSkills = function importBusinessUnitSkills(request, callback) {
                    return this.rpcCall(importBusinessUnitSkills, $root.mtechnavi.api.company.ImportBusinessUnitSkillsRequest, $root.mtechnavi.api.company.ImportBusinessUnitSkillsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitSkills" });

                /**
                 * Calls ImportBusinessUnitSkills.
                 * @function importBusinessUnitSkills
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitSkillsRequest} request ImportBusinessUnitSkillsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitSkillsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitSkillFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitSkillFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse} [response] ListBusinessUnitSkillFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitSkillFormats.
                 * @function listBusinessUnitSkillFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillFormatsRequest} request ListBusinessUnitSkillFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitSkillFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitSkillFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitSkillFormats = function listBusinessUnitSkillFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitSkillFormats, $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitSkillFormats" });

                /**
                 * Calls ListBusinessUnitSkillFormats.
                 * @function listBusinessUnitSkillFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillFormatsRequest} request ListBusinessUnitSkillFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#exportBusinessUnitRankReceptions}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ExportBusinessUnitRankReceptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse} [response] ExportBusinessUnitRankReceptionsResponse
                 */

                /**
                 * Calls ExportBusinessUnitRankReceptions.
                 * @function exportBusinessUnitRankReceptions
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankReceptionsRequest} request ExportBusinessUnitRankReceptionsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ExportBusinessUnitRankReceptionsCallback} callback Node-style callback called with the error, if any, and ExportBusinessUnitRankReceptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.exportBusinessUnitRankReceptions = function exportBusinessUnitRankReceptions(request, callback) {
                    return this.rpcCall(exportBusinessUnitRankReceptions, $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest, $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse, request, callback);
                }, "name", { value: "ExportBusinessUnitRankReceptions" });

                /**
                 * Calls ExportBusinessUnitRankReceptions.
                 * @function exportBusinessUnitRankReceptions
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankReceptionsRequest} request ExportBusinessUnitRankReceptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#importBusinessUnitRankReceptions}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ImportBusinessUnitRankReceptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse} [response] ImportBusinessUnitRankReceptionsResponse
                 */

                /**
                 * Calls ImportBusinessUnitRankReceptions.
                 * @function importBusinessUnitRankReceptions
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankReceptionsRequest} request ImportBusinessUnitRankReceptionsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ImportBusinessUnitRankReceptionsCallback} callback Node-style callback called with the error, if any, and ImportBusinessUnitRankReceptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.importBusinessUnitRankReceptions = function importBusinessUnitRankReceptions(request, callback) {
                    return this.rpcCall(importBusinessUnitRankReceptions, $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest, $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse, request, callback);
                }, "name", { value: "ImportBusinessUnitRankReceptions" });

                /**
                 * Calls ImportBusinessUnitRankReceptions.
                 * @function importBusinessUnitRankReceptions
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankReceptionsRequest} request ImportBusinessUnitRankReceptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitRankReceptionFormats}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitRankReceptionFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse} [response] ListBusinessUnitRankReceptionFormatsResponse
                 */

                /**
                 * Calls ListBusinessUnitRankReceptionFormats.
                 * @function listBusinessUnitRankReceptionFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionFormatsRequest} request ListBusinessUnitRankReceptionFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitRankReceptionFormatsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitRankReceptionFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitRankReceptionFormats = function listBusinessUnitRankReceptionFormats(request, callback) {
                    return this.rpcCall(listBusinessUnitRankReceptionFormats, $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest, $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitRankReceptionFormats" });

                /**
                 * Calls ListBusinessUnitRankReceptionFormats.
                 * @function listBusinessUnitRankReceptionFormats
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionFormatsRequest} request ListBusinessUnitRankReceptionFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#listBusinessUnitPublicDocuments}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef ListBusinessUnitPublicDocumentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse} [response] ListBusinessUnitPublicDocumentsResponse
                 */

                /**
                 * Calls ListBusinessUnitPublicDocuments.
                 * @function listBusinessUnitPublicDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitPublicDocumentsRequest} request ListBusinessUnitPublicDocumentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.ListBusinessUnitPublicDocumentsCallback} callback Node-style callback called with the error, if any, and ListBusinessUnitPublicDocumentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.listBusinessUnitPublicDocuments = function listBusinessUnitPublicDocuments(request, callback) {
                    return this.rpcCall(listBusinessUnitPublicDocuments, $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest, $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse, request, callback);
                }, "name", { value: "ListBusinessUnitPublicDocuments" });

                /**
                 * Calls ListBusinessUnitPublicDocuments.
                 * @function listBusinessUnitPublicDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IListBusinessUnitPublicDocumentsRequest} request ListBusinessUnitPublicDocumentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#createBusinessUnitPublicDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef CreateBusinessUnitPublicDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitPublicDocument} [response] BusinessUnitPublicDocument
                 */

                /**
                 * Calls CreateBusinessUnitPublicDocument.
                 * @function createBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitPublicDocumentRequest} request CreateBusinessUnitPublicDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.CreateBusinessUnitPublicDocumentCallback} callback Node-style callback called with the error, if any, and BusinessUnitPublicDocument
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.createBusinessUnitPublicDocument = function createBusinessUnitPublicDocument(request, callback) {
                    return this.rpcCall(createBusinessUnitPublicDocument, $root.mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest, $root.mtechnavi.api.company.BusinessUnitPublicDocument, request, callback);
                }, "name", { value: "CreateBusinessUnitPublicDocument" });

                /**
                 * Calls CreateBusinessUnitPublicDocument.
                 * @function createBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBusinessUnitPublicDocumentRequest} request CreateBusinessUnitPublicDocumentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitPublicDocument>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#updateBusinessUnitPublicDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef UpdateBusinessUnitPublicDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BusinessUnitPublicDocument} [response] BusinessUnitPublicDocument
                 */

                /**
                 * Calls UpdateBusinessUnitPublicDocument.
                 * @function updateBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitPublicDocumentRequest} request UpdateBusinessUnitPublicDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.UpdateBusinessUnitPublicDocumentCallback} callback Node-style callback called with the error, if any, and BusinessUnitPublicDocument
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.updateBusinessUnitPublicDocument = function updateBusinessUnitPublicDocument(request, callback) {
                    return this.rpcCall(updateBusinessUnitPublicDocument, $root.mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest, $root.mtechnavi.api.company.BusinessUnitPublicDocument, request, callback);
                }, "name", { value: "UpdateBusinessUnitPublicDocument" });

                /**
                 * Calls UpdateBusinessUnitPublicDocument.
                 * @function updateBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitPublicDocumentRequest} request UpdateBusinessUnitPublicDocumentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BusinessUnitPublicDocument>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#deleteBusinessUnitPublicDocument}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef DeleteBusinessUnitPublicDocumentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBusinessUnitPublicDocument.
                 * @function deleteBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitPublicDocumentRequest} request DeleteBusinessUnitPublicDocumentRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.DeleteBusinessUnitPublicDocumentCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.deleteBusinessUnitPublicDocument = function deleteBusinessUnitPublicDocument(request, callback) {
                    return this.rpcCall(deleteBusinessUnitPublicDocument, $root.mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBusinessUnitPublicDocument" });

                /**
                 * Calls DeleteBusinessUnitPublicDocument.
                 * @function deleteBusinessUnitPublicDocument
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitPublicDocumentRequest} request DeleteBusinessUnitPublicDocumentRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BusinessUnitManagementService#sharedListBusinessUnitPublicDocuments}.
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @typedef SharedListBusinessUnitPublicDocumentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse} [response] SharedListBusinessUnitPublicDocumentsResponse
                 */

                /**
                 * Calls SharedListBusinessUnitPublicDocuments.
                 * @function sharedListBusinessUnitPublicDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitPublicDocumentsRequest} request SharedListBusinessUnitPublicDocumentsRequest message or plain object
                 * @param {mtechnavi.api.company.BusinessUnitManagementService.SharedListBusinessUnitPublicDocumentsCallback} callback Node-style callback called with the error, if any, and SharedListBusinessUnitPublicDocumentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BusinessUnitManagementService.prototype.sharedListBusinessUnitPublicDocuments = function sharedListBusinessUnitPublicDocuments(request, callback) {
                    return this.rpcCall(sharedListBusinessUnitPublicDocuments, $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest, $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse, request, callback);
                }, "name", { value: "SharedListBusinessUnitPublicDocuments" });

                /**
                 * Calls SharedListBusinessUnitPublicDocuments.
                 * @function sharedListBusinessUnitPublicDocuments
                 * @memberof mtechnavi.api.company.BusinessUnitManagementService
                 * @instance
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitPublicDocumentsRequest} request SharedListBusinessUnitPublicDocumentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse>} Promise
                 * @variation 2
                 */

                return BusinessUnitManagementService;
            })();

            company.OrganizationService = (function() {

                /**
                 * Constructs a new OrganizationService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an OrganizationService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function OrganizationService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (OrganizationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrganizationService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#listOrganizations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef ListOrganizationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListOrganizationsResponse} [response] ListOrganizationsResponse
                 */

                /**
                 * Calls ListOrganizations.
                 * @function listOrganizations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationsRequest} request ListOrganizationsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.ListOrganizationsCallback} callback Node-style callback called with the error, if any, and ListOrganizationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.listOrganizations = function listOrganizations(request, callback) {
                    return this.rpcCall(listOrganizations, $root.mtechnavi.api.company.ListOrganizationsRequest, $root.mtechnavi.api.company.ListOrganizationsResponse, request, callback);
                }, "name", { value: "ListOrganizations" });

                /**
                 * Calls ListOrganizations.
                 * @function listOrganizations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationsRequest} request ListOrganizationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListOrganizationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#saveOrganizations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef SaveOrganizationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.Organization} [response] Organization
                 */

                /**
                 * Calls SaveOrganizations.
                 * @function saveOrganizations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.ISaveOrganizationRequest} request SaveOrganizationRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.SaveOrganizationsCallback} callback Node-style callback called with the error, if any, and Organization
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.saveOrganizations = function saveOrganizations(request, callback) {
                    return this.rpcCall(saveOrganizations, $root.mtechnavi.api.company.SaveOrganizationRequest, $root.mtechnavi.api.company.Organization, request, callback);
                }, "name", { value: "SaveOrganizations" });

                /**
                 * Calls SaveOrganizations.
                 * @function saveOrganizations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.ISaveOrganizationRequest} request SaveOrganizationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.Organization>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#listOrganizationRelations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef ListOrganizationRelationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListOrganizationRelationsResponse} [response] ListOrganizationRelationsResponse
                 */

                /**
                 * Calls ListOrganizationRelations.
                 * @function listOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationRelationsRequest} request ListOrganizationRelationsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.ListOrganizationRelationsCallback} callback Node-style callback called with the error, if any, and ListOrganizationRelationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.listOrganizationRelations = function listOrganizationRelations(request, callback) {
                    return this.rpcCall(listOrganizationRelations, $root.mtechnavi.api.company.ListOrganizationRelationsRequest, $root.mtechnavi.api.company.ListOrganizationRelationsResponse, request, callback);
                }, "name", { value: "ListOrganizationRelations" });

                /**
                 * Calls ListOrganizationRelations.
                 * @function listOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationRelationsRequest} request ListOrganizationRelationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListOrganizationRelationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#bulkWriteOrganizationRelations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef BulkWriteOrganizationRelationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BulkWriteOrganizationRelationsResponse} [response] BulkWriteOrganizationRelationsResponse
                 */

                /**
                 * Calls BulkWriteOrganizationRelations.
                 * @function bulkWriteOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest} request BulkWriteOrganizationRelationsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.BulkWriteOrganizationRelationsCallback} callback Node-style callback called with the error, if any, and BulkWriteOrganizationRelationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.bulkWriteOrganizationRelations = function bulkWriteOrganizationRelations(request, callback) {
                    return this.rpcCall(bulkWriteOrganizationRelations, $root.mtechnavi.api.company.BulkWriteOrganizationRelationsRequest, $root.mtechnavi.api.company.BulkWriteOrganizationRelationsResponse, request, callback);
                }, "name", { value: "BulkWriteOrganizationRelations" });

                /**
                 * Calls BulkWriteOrganizationRelations.
                 * @function bulkWriteOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest} request BulkWriteOrganizationRelationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BulkWriteOrganizationRelationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#deleteOrganizationWithRelatedItems}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef DeleteOrganizationWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteOrganizationWithRelatedItems.
                 * @function deleteOrganizationWithRelatedItems
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest} request DeleteOrganizationWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.DeleteOrganizationWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.deleteOrganizationWithRelatedItems = function deleteOrganizationWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteOrganizationWithRelatedItems, $root.mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteOrganizationWithRelatedItems" });

                /**
                 * Calls DeleteOrganizationWithRelatedItems.
                 * @function deleteOrganizationWithRelatedItems
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest} request DeleteOrganizationWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#importOrganizationRelations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef ImportOrganizationRelationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ImportOrganizationRelationsResponse} [response] ImportOrganizationRelationsResponse
                 */

                /**
                 * Calls ImportOrganizationRelations.
                 * @function importOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IImportOrganizationRelationsRequest} request ImportOrganizationRelationsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.ImportOrganizationRelationsCallback} callback Node-style callback called with the error, if any, and ImportOrganizationRelationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.importOrganizationRelations = function importOrganizationRelations(request, callback) {
                    return this.rpcCall(importOrganizationRelations, $root.mtechnavi.api.company.ImportOrganizationRelationsRequest, $root.mtechnavi.api.company.ImportOrganizationRelationsResponse, request, callback);
                }, "name", { value: "ImportOrganizationRelations" });

                /**
                 * Calls ImportOrganizationRelations.
                 * @function importOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IImportOrganizationRelationsRequest} request ImportOrganizationRelationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ImportOrganizationRelationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#exportOrganizationRelations}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef ExportOrganizationRelationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ExportOrganizationRelationsResponse} [response] ExportOrganizationRelationsResponse
                 */

                /**
                 * Calls ExportOrganizationRelations.
                 * @function exportOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IExportOrganizationRelationsRequest} request ExportOrganizationRelationsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.ExportOrganizationRelationsCallback} callback Node-style callback called with the error, if any, and ExportOrganizationRelationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.exportOrganizationRelations = function exportOrganizationRelations(request, callback) {
                    return this.rpcCall(exportOrganizationRelations, $root.mtechnavi.api.company.ExportOrganizationRelationsRequest, $root.mtechnavi.api.company.ExportOrganizationRelationsResponse, request, callback);
                }, "name", { value: "ExportOrganizationRelations" });

                /**
                 * Calls ExportOrganizationRelations.
                 * @function exportOrganizationRelations
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IExportOrganizationRelationsRequest} request ExportOrganizationRelationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ExportOrganizationRelationsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.OrganizationService#listOrganizationRelationFormats}.
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @typedef ListOrganizationRelationFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListOrganizationRelationFormatsResponse} [response] ListOrganizationRelationFormatsResponse
                 */

                /**
                 * Calls ListOrganizationRelationFormats.
                 * @function listOrganizationRelationFormats
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationRelationFormatsRequest} request ListOrganizationRelationFormatsRequest message or plain object
                 * @param {mtechnavi.api.company.OrganizationService.ListOrganizationRelationFormatsCallback} callback Node-style callback called with the error, if any, and ListOrganizationRelationFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(OrganizationService.prototype.listOrganizationRelationFormats = function listOrganizationRelationFormats(request, callback) {
                    return this.rpcCall(listOrganizationRelationFormats, $root.mtechnavi.api.company.ListOrganizationRelationFormatsRequest, $root.mtechnavi.api.company.ListOrganizationRelationFormatsResponse, request, callback);
                }, "name", { value: "ListOrganizationRelationFormats" });

                /**
                 * Calls ListOrganizationRelationFormats.
                 * @function listOrganizationRelationFormats
                 * @memberof mtechnavi.api.company.OrganizationService
                 * @instance
                 * @param {mtechnavi.api.company.IListOrganizationRelationFormatsRequest} request ListOrganizationRelationFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListOrganizationRelationFormatsResponse>} Promise
                 * @variation 2
                 */

                return OrganizationService;
            })();

            company.BillingCompanyService = (function() {

                /**
                 * Constructs a new BillingCompanyService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BillingCompanyService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BillingCompanyService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BillingCompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BillingCompanyService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.BillingCompanyService#listBillingCompanys}.
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @typedef ListBillingCompanysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.ListBillingCompanysResponse} [response] ListBillingCompanysResponse
                 */

                /**
                 * Calls ListBillingCompanys.
                 * @function listBillingCompanys
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBillingCompanysRequest} request ListBillingCompanysRequest message or plain object
                 * @param {mtechnavi.api.company.BillingCompanyService.ListBillingCompanysCallback} callback Node-style callback called with the error, if any, and ListBillingCompanysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BillingCompanyService.prototype.listBillingCompanys = function listBillingCompanys(request, callback) {
                    return this.rpcCall(listBillingCompanys, $root.mtechnavi.api.company.ListBillingCompanysRequest, $root.mtechnavi.api.company.ListBillingCompanysResponse, request, callback);
                }, "name", { value: "ListBillingCompanys" });

                /**
                 * Calls ListBillingCompanys.
                 * @function listBillingCompanys
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IListBillingCompanysRequest} request ListBillingCompanysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.ListBillingCompanysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BillingCompanyService#getBillingCompany}.
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @typedef GetBillingCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BillingCompany} [response] BillingCompany
                 */

                /**
                 * Calls GetBillingCompany.
                 * @function getBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBillingCompanyRequest} request GetBillingCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.BillingCompanyService.GetBillingCompanyCallback} callback Node-style callback called with the error, if any, and BillingCompany
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BillingCompanyService.prototype.getBillingCompany = function getBillingCompany(request, callback) {
                    return this.rpcCall(getBillingCompany, $root.mtechnavi.api.company.GetBillingCompanyRequest, $root.mtechnavi.api.company.BillingCompany, request, callback);
                }, "name", { value: "GetBillingCompany" });

                /**
                 * Calls GetBillingCompany.
                 * @function getBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IGetBillingCompanyRequest} request GetBillingCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BillingCompany>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BillingCompanyService#createBillingCompany}.
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @typedef CreateBillingCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BillingCompany} [response] BillingCompany
                 */

                /**
                 * Calls CreateBillingCompany.
                 * @function createBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBillingCompanyRequest} request CreateBillingCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.BillingCompanyService.CreateBillingCompanyCallback} callback Node-style callback called with the error, if any, and BillingCompany
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BillingCompanyService.prototype.createBillingCompany = function createBillingCompany(request, callback) {
                    return this.rpcCall(createBillingCompany, $root.mtechnavi.api.company.CreateBillingCompanyRequest, $root.mtechnavi.api.company.BillingCompany, request, callback);
                }, "name", { value: "CreateBillingCompany" });

                /**
                 * Calls CreateBillingCompany.
                 * @function createBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.ICreateBillingCompanyRequest} request CreateBillingCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BillingCompany>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BillingCompanyService#updateBillingCompany}.
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @typedef UpdateBillingCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.company.BillingCompany} [response] BillingCompany
                 */

                /**
                 * Calls UpdateBillingCompany.
                 * @function updateBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBillingCompanyRequest} request UpdateBillingCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.BillingCompanyService.UpdateBillingCompanyCallback} callback Node-style callback called with the error, if any, and BillingCompany
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BillingCompanyService.prototype.updateBillingCompany = function updateBillingCompany(request, callback) {
                    return this.rpcCall(updateBillingCompany, $root.mtechnavi.api.company.UpdateBillingCompanyRequest, $root.mtechnavi.api.company.BillingCompany, request, callback);
                }, "name", { value: "UpdateBillingCompany" });

                /**
                 * Calls UpdateBillingCompany.
                 * @function updateBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IUpdateBillingCompanyRequest} request UpdateBillingCompanyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.company.BillingCompany>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.BillingCompanyService#deleteBillingCompany}.
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @typedef DeleteBillingCompanyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBillingCompany.
                 * @function deleteBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBillingCompanyRequest} request DeleteBillingCompanyRequest message or plain object
                 * @param {mtechnavi.api.company.BillingCompanyService.DeleteBillingCompanyCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BillingCompanyService.prototype.deleteBillingCompany = function deleteBillingCompany(request, callback) {
                    return this.rpcCall(deleteBillingCompany, $root.mtechnavi.api.company.DeleteBillingCompanyRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBillingCompany" });

                /**
                 * Calls DeleteBillingCompany.
                 * @function deleteBillingCompany
                 * @memberof mtechnavi.api.company.BillingCompanyService
                 * @instance
                 * @param {mtechnavi.api.company.IDeleteBillingCompanyRequest} request DeleteBillingCompanyRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return BillingCompanyService;
            })();

            company.GetCompanyRequest = (function() {

                /**
                 * Properties of a GetCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetCompanyRequest
                 */

                /**
                 * Constructs a new GetCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetCompanyRequest.
                 * @implements IGetCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetCompanyRequest=} [properties] Properties to set
                 */
                function GetCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a GetCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetCompanyRequest} GetCompanyRequest
                 */
                GetCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetCompanyRequest)
                        return object;
                    return new $root.mtechnavi.api.company.GetCompanyRequest();
                };

                /**
                 * Creates a plain object from a GetCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.GetCompanyRequest} message GetCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCompanyRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCompanyRequest;
            })();

            company.UpdateCompanyRequest = (function() {

                /**
                 * Properties of an UpdateCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateCompanyRequest
                 * @property {mtechnavi.api.company.ICompany|null} [company] UpdateCompanyRequest company
                 */

                /**
                 * Constructs a new UpdateCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateCompanyRequest.
                 * @implements IUpdateCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateCompanyRequest=} [properties] Properties to set
                 */
                function UpdateCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateCompanyRequest company.
                 * @member {mtechnavi.api.company.ICompany|null|undefined} company
                 * @memberof mtechnavi.api.company.UpdateCompanyRequest
                 * @instance
                 */
                UpdateCompanyRequest.prototype.company = null;

                /**
                 * Verifies an UpdateCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.company != null && message.hasOwnProperty("company")) {
                        let error = $root.mtechnavi.api.company.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateCompanyRequest} UpdateCompanyRequest
                 */
                UpdateCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateCompanyRequest();
                    if (object.company != null) {
                        if (typeof object.company !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateCompanyRequest.company: object expected");
                        message.company = $root.mtechnavi.api.company.Company.fromObject(object.company);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateCompanyRequest} message UpdateCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.company = null;
                    if (message.company != null && message.hasOwnProperty("company"))
                        object.company = $root.mtechnavi.api.company.Company.toObject(message.company, options);
                    return object;
                };

                /**
                 * Converts this UpdateCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateCompanyRequest;
            })();

            company.CreateComponentUnitRequest = (function() {

                /**
                 * Properties of a CreateComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateComponentUnitRequest
                 * @property {mtechnavi.api.company.IComponentUnit|null} [componentUnit] CreateComponentUnitRequest componentUnit
                 */

                /**
                 * Constructs a new CreateComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateComponentUnitRequest.
                 * @implements ICreateComponentUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateComponentUnitRequest=} [properties] Properties to set
                 */
                function CreateComponentUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateComponentUnitRequest componentUnit.
                 * @member {mtechnavi.api.company.IComponentUnit|null|undefined} componentUnit
                 * @memberof mtechnavi.api.company.CreateComponentUnitRequest
                 * @instance
                 */
                CreateComponentUnitRequest.prototype.componentUnit = null;

                /**
                 * Verifies a CreateComponentUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateComponentUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnit);
                        if (error)
                            return "componentUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateComponentUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateComponentUnitRequest} CreateComponentUnitRequest
                 */
                CreateComponentUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateComponentUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateComponentUnitRequest();
                    if (object.componentUnit != null) {
                        if (typeof object.componentUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateComponentUnitRequest.componentUnit: object expected");
                        message.componentUnit = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateComponentUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateComponentUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateComponentUnitRequest} message CreateComponentUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateComponentUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.componentUnit = null;
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit"))
                        object.componentUnit = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnit, options);
                    return object;
                };

                /**
                 * Converts this CreateComponentUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateComponentUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateComponentUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateComponentUnitRequest;
            })();

            company.ListComponentUnitsRequest = (function() {

                /**
                 * Properties of a ListComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListComponentUnitsRequest
                 */

                /**
                 * Constructs a new ListComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListComponentUnitsRequest.
                 * @implements IListComponentUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListComponentUnitsRequest=} [properties] Properties to set
                 */
                function ListComponentUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListComponentUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListComponentUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListComponentUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListComponentUnitsRequest} ListComponentUnitsRequest
                 */
                ListComponentUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListComponentUnitsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListComponentUnitsRequest();
                };

                /**
                 * Creates a plain object from a ListComponentUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListComponentUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListComponentUnitsRequest} message ListComponentUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListComponentUnitsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListComponentUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListComponentUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListComponentUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListComponentUnitsRequest;
            })();

            company.ListComponentUnitsResponse = (function() {

                /**
                 * Properties of a ListComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListComponentUnitsResponse
                 * @property {Long|null} [total] ListComponentUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.IComponentUnit>|null} [items] ListComponentUnitsResponse items
                 */

                /**
                 * Constructs a new ListComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListComponentUnitsResponse.
                 * @implements IListComponentUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListComponentUnitsResponse=} [properties] Properties to set
                 */
                function ListComponentUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListComponentUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @instance
                 */
                ListComponentUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListComponentUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.IComponentUnit>} items
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @instance
                 */
                ListComponentUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListComponentUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListComponentUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListComponentUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListComponentUnitsResponse} ListComponentUnitsResponse
                 */
                ListComponentUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListComponentUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListComponentUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListComponentUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListComponentUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListComponentUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListComponentUnitsResponse} message ListComponentUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListComponentUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.ComponentUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListComponentUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListComponentUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListComponentUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListComponentUnitsResponse;
            })();

            company.PublicListComponentUnitsRequest = (function() {

                /**
                 * Properties of a PublicListComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListComponentUnitsRequest
                 */

                /**
                 * Constructs a new PublicListComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListComponentUnitsRequest.
                 * @implements IPublicListComponentUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListComponentUnitsRequest=} [properties] Properties to set
                 */
                function PublicListComponentUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a PublicListComponentUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListComponentUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PublicListComponentUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListComponentUnitsRequest} PublicListComponentUnitsRequest
                 */
                PublicListComponentUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListComponentUnitsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.PublicListComponentUnitsRequest();
                };

                /**
                 * Creates a plain object from a PublicListComponentUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicListComponentUnitsRequest} message PublicListComponentUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListComponentUnitsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PublicListComponentUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListComponentUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListComponentUnitsRequest;
            })();

            company.PublicListComponentUnitsResponse = (function() {

                /**
                 * Properties of a PublicListComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListComponentUnitsResponse
                 * @property {Long|null} [total] PublicListComponentUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.IComponentUnit>|null} [items] PublicListComponentUnitsResponse items
                 */

                /**
                 * Constructs a new PublicListComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListComponentUnitsResponse.
                 * @implements IPublicListComponentUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListComponentUnitsResponse=} [properties] Properties to set
                 */
                function PublicListComponentUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListComponentUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @instance
                 */
                PublicListComponentUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListComponentUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.IComponentUnit>} items
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @instance
                 */
                PublicListComponentUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a PublicListComponentUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListComponentUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PublicListComponentUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListComponentUnitsResponse} PublicListComponentUnitsResponse
                 */
                PublicListComponentUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListComponentUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListComponentUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.PublicListComponentUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PublicListComponentUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListComponentUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.PublicListComponentUnitsResponse} message PublicListComponentUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListComponentUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.ComponentUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PublicListComponentUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListComponentUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListComponentUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListComponentUnitsResponse;
            })();

            company.GetComponentUnitRequest = (function() {

                /**
                 * Properties of a GetComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetComponentUnitRequest
                 * @property {string|null} [componentUnitId] GetComponentUnitRequest componentUnitId
                 */

                /**
                 * Constructs a new GetComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetComponentUnitRequest.
                 * @implements IGetComponentUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetComponentUnitRequest=} [properties] Properties to set
                 */
                function GetComponentUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetComponentUnitRequest componentUnitId.
                 * @member {string} componentUnitId
                 * @memberof mtechnavi.api.company.GetComponentUnitRequest
                 * @instance
                 */
                GetComponentUnitRequest.prototype.componentUnitId = "";

                /**
                 * Verifies a GetComponentUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetComponentUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        if (!$util.isString(message.componentUnitId))
                            return "componentUnitId: string expected";
                    return null;
                };

                /**
                 * Creates a GetComponentUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetComponentUnitRequest} GetComponentUnitRequest
                 */
                GetComponentUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetComponentUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetComponentUnitRequest();
                    if (object.componentUnitId != null)
                        message.componentUnitId = String(object.componentUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetComponentUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetComponentUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.GetComponentUnitRequest} message GetComponentUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetComponentUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.componentUnitId = "";
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        object.componentUnitId = message.componentUnitId;
                    return object;
                };

                /**
                 * Converts this GetComponentUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetComponentUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetComponentUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetComponentUnitRequest;
            })();

            company.UpdateComponentUnitRequest = (function() {

                /**
                 * Properties of an UpdateComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateComponentUnitRequest
                 * @property {mtechnavi.api.company.IComponentUnit|null} [componentUnit] UpdateComponentUnitRequest componentUnit
                 */

                /**
                 * Constructs a new UpdateComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateComponentUnitRequest.
                 * @implements IUpdateComponentUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateComponentUnitRequest=} [properties] Properties to set
                 */
                function UpdateComponentUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateComponentUnitRequest componentUnit.
                 * @member {mtechnavi.api.company.IComponentUnit|null|undefined} componentUnit
                 * @memberof mtechnavi.api.company.UpdateComponentUnitRequest
                 * @instance
                 */
                UpdateComponentUnitRequest.prototype.componentUnit = null;

                /**
                 * Verifies an UpdateComponentUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateComponentUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnit);
                        if (error)
                            return "componentUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateComponentUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateComponentUnitRequest} UpdateComponentUnitRequest
                 */
                UpdateComponentUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateComponentUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateComponentUnitRequest();
                    if (object.componentUnit != null) {
                        if (typeof object.componentUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateComponentUnitRequest.componentUnit: object expected");
                        message.componentUnit = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateComponentUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateComponentUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateComponentUnitRequest} message UpdateComponentUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateComponentUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.componentUnit = null;
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit"))
                        object.componentUnit = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnit, options);
                    return object;
                };

                /**
                 * Converts this UpdateComponentUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateComponentUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateComponentUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateComponentUnitRequest;
            })();

            company.DeleteComponentUnitRequest = (function() {

                /**
                 * Properties of a DeleteComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteComponentUnitRequest
                 * @property {mtechnavi.api.company.IComponentUnit|null} [componentUnit] DeleteComponentUnitRequest componentUnit
                 */

                /**
                 * Constructs a new DeleteComponentUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteComponentUnitRequest.
                 * @implements IDeleteComponentUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteComponentUnitRequest=} [properties] Properties to set
                 */
                function DeleteComponentUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteComponentUnitRequest componentUnit.
                 * @member {mtechnavi.api.company.IComponentUnit|null|undefined} componentUnit
                 * @memberof mtechnavi.api.company.DeleteComponentUnitRequest
                 * @instance
                 */
                DeleteComponentUnitRequest.prototype.componentUnit = null;

                /**
                 * Verifies a DeleteComponentUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteComponentUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnit);
                        if (error)
                            return "componentUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteComponentUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteComponentUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteComponentUnitRequest} DeleteComponentUnitRequest
                 */
                DeleteComponentUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteComponentUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteComponentUnitRequest();
                    if (object.componentUnit != null) {
                        if (typeof object.componentUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteComponentUnitRequest.componentUnit: object expected");
                        message.componentUnit = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteComponentUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteComponentUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteComponentUnitRequest} message DeleteComponentUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteComponentUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.componentUnit = null;
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit"))
                        object.componentUnit = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnit, options);
                    return object;
                };

                /**
                 * Converts this DeleteComponentUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteComponentUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteComponentUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteComponentUnitRequest;
            })();

            company.CreateBusinessUnitRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitRequest
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] CreateBusinessUnitRequest businessUnit
                 */

                /**
                 * Constructs a new CreateBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitRequest.
                 * @implements ICreateBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitRequest businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequest
                 * @instance
                 */
                CreateBusinessUnitRequest.prototype.businessUnit = null;

                /**
                 * Verifies a CreateBusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitRequest} CreateBusinessUnitRequest
                 */
                CreateBusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitRequest();
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitRequest.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitRequest} message CreateBusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnit = null;
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitRequest;
            })();

            company.ListBusinessUnitsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitsRequest
                 * @property {Array.<string>|null} [ids] ListBusinessUnitsRequest ids
                 */

                /**
                 * Constructs a new ListBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitsRequest.
                 * @implements IListBusinessUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ListBusinessUnitsRequest
                 * @instance
                 */
                ListBusinessUnitsRequest.prototype.ids = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitsRequest} ListBusinessUnitsRequest
                 */
                ListBusinessUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitsRequest} message ListBusinessUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitsRequest;
            })();

            company.ListBusinessUnitsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitsResponse
                 * @property {Long|null} [total] ListBusinessUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnit>|null} [items] ListBusinessUnitsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitsResponse.
                 * @implements IListBusinessUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @instance
                 */
                ListBusinessUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnit>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @instance
                 */
                ListBusinessUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitsResponse} ListBusinessUnitsResponse
                 */
                ListBusinessUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitsResponse} message ListBusinessUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitsResponse;
            })();

            company.GetBusinessUnitRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitRequest
                 * @property {string|null} [businessUnitId] GetBusinessUnitRequest businessUnitId
                 */

                /**
                 * Constructs a new GetBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitRequest.
                 * @implements IGetBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitRequest businessUnitId.
                 * @member {string} businessUnitId
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequest
                 * @instance
                 */
                GetBusinessUnitRequest.prototype.businessUnitId = "";

                /**
                 * Verifies a GetBusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitId != null && message.hasOwnProperty("businessUnitId"))
                        if (!$util.isString(message.businessUnitId))
                            return "businessUnitId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitRequest} GetBusinessUnitRequest
                 */
                GetBusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitRequest();
                    if (object.businessUnitId != null)
                        message.businessUnitId = String(object.businessUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitRequest} message GetBusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitId = "";
                    if (message.businessUnitId != null && message.hasOwnProperty("businessUnitId"))
                        object.businessUnitId = message.businessUnitId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitRequest;
            })();

            company.UpdateBusinessUnitRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitRequest
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] UpdateBusinessUnitRequest businessUnit
                 */

                /**
                 * Constructs a new UpdateBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitRequest.
                 * @implements IUpdateBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitRequest businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequest
                 * @instance
                 */
                UpdateBusinessUnitRequest.prototype.businessUnit = null;

                /**
                 * Verifies an UpdateBusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitRequest} UpdateBusinessUnitRequest
                 */
                UpdateBusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitRequest();
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitRequest.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitRequest} message UpdateBusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnit = null;
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitRequest;
            })();

            company.DeleteBusinessUnitRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitRequest
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] DeleteBusinessUnitRequest businessUnit
                 */

                /**
                 * Constructs a new DeleteBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitRequest.
                 * @implements IDeleteBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitRequest businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequest
                 * @instance
                 */
                DeleteBusinessUnitRequest.prototype.businessUnit = null;

                /**
                 * Verifies a DeleteBusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitRequest} DeleteBusinessUnitRequest
                 */
                DeleteBusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitRequest();
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitRequest.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitRequest} message DeleteBusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnit = null;
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitRequest;
            })();

            company.PublicListCompanyAndBusinessUnitsRequest = (function() {

                /**
                 * Properties of a PublicListCompanyAndBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanyAndBusinessUnitsRequest
                 */

                /**
                 * Constructs a new PublicListCompanyAndBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanyAndBusinessUnitsRequest.
                 * @implements IPublicListCompanyAndBusinessUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanyAndBusinessUnitsRequest=} [properties] Properties to set
                 */
                function PublicListCompanyAndBusinessUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a PublicListCompanyAndBusinessUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanyAndBusinessUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PublicListCompanyAndBusinessUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest} PublicListCompanyAndBusinessUnitsRequest
                 */
                PublicListCompanyAndBusinessUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest();
                };

                /**
                 * Creates a plain object from a PublicListCompanyAndBusinessUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest} message PublicListCompanyAndBusinessUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanyAndBusinessUnitsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PublicListCompanyAndBusinessUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanyAndBusinessUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanyAndBusinessUnitsRequest;
            })();

            company.PublicListCompanyAndBusinessUnitsResponse = (function() {

                /**
                 * Properties of a PublicListCompanyAndBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanyAndBusinessUnitsResponse
                 * @property {Long|null} [total] PublicListCompanyAndBusinessUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.ICompanyAndBusinessUnit>|null} [items] PublicListCompanyAndBusinessUnitsResponse items
                 */

                /**
                 * Constructs a new PublicListCompanyAndBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanyAndBusinessUnitsResponse.
                 * @implements IPublicListCompanyAndBusinessUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanyAndBusinessUnitsResponse=} [properties] Properties to set
                 */
                function PublicListCompanyAndBusinessUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListCompanyAndBusinessUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @instance
                 */
                PublicListCompanyAndBusinessUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListCompanyAndBusinessUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.ICompanyAndBusinessUnit>} items
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @instance
                 */
                PublicListCompanyAndBusinessUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a PublicListCompanyAndBusinessUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanyAndBusinessUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.CompanyAndBusinessUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PublicListCompanyAndBusinessUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse} PublicListCompanyAndBusinessUnitsResponse
                 */
                PublicListCompanyAndBusinessUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.CompanyAndBusinessUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListCompanyAndBusinessUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse} message PublicListCompanyAndBusinessUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanyAndBusinessUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.CompanyAndBusinessUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PublicListCompanyAndBusinessUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanyAndBusinessUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanyAndBusinessUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanyAndBusinessUnitsResponse;
            })();

            company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest = (function() {

                /**
                 * Properties of a PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 */

                /**
                 * Constructs a new PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.
                 * @implements IPublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanyAndComponentUnitAndBusinessUnitsRequest=} [properties] Properties to set
                 */
                function PublicListCompanyAndComponentUnitAndBusinessUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a PublicListCompanyAndComponentUnitAndBusinessUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PublicListCompanyAndComponentUnitAndBusinessUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest} PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest();
                };

                /**
                 * Creates a plain object from a PublicListCompanyAndComponentUnitAndBusinessUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest} message PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PublicListCompanyAndComponentUnitAndBusinessUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanyAndComponentUnitAndBusinessUnitsRequest;
            })();

            company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse = (function() {

                /**
                 * Properties of a PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @property {Long|null} [total] PublicListCompanyAndComponentUnitAndBusinessUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.ICompanyAndComponentUnitAndBusinessUnit>|null} [items] PublicListCompanyAndComponentUnitAndBusinessUnitsResponse items
                 */

                /**
                 * Constructs a new PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.
                 * @implements IPublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanyAndComponentUnitAndBusinessUnitsResponse=} [properties] Properties to set
                 */
                function PublicListCompanyAndComponentUnitAndBusinessUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListCompanyAndComponentUnitAndBusinessUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @instance
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListCompanyAndComponentUnitAndBusinessUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.ICompanyAndComponentUnitAndBusinessUnit>} items
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @instance
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a PublicListCompanyAndComponentUnitAndBusinessUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PublicListCompanyAndComponentUnitAndBusinessUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse} PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListCompanyAndComponentUnitAndBusinessUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse} message PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PublicListCompanyAndComponentUnitAndBusinessUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanyAndComponentUnitAndBusinessUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanyAndComponentUnitAndBusinessUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanyAndComponentUnitAndBusinessUnitsResponse;
            })();

            company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest = (function() {

                /**
                 * Properties of a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 */

                /**
                 * Constructs a new PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.
                 * @implements IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest=} [properties] Properties to set
                 */
                function PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest} PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest)
                        return object;
                    return new $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest();
                };

                /**
                 * Creates a plain object from a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest} message PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitRequest;
            })();

            company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse = (function() {

                /**
                 * Properties of a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @property {Long|null} [total] PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse total
                 * @property {Array.<mtechnavi.api.company.IPostUnitAndCompanyAndComponentUnitAndBusinessUnit>|null} [items] PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse items
                 */

                /**
                 * Constructs a new PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.
                 * @implements IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse=} [properties] Properties to set
                 */
                function PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @instance
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse items.
                 * @member {Array.<mtechnavi.api.company.IPostUnitAndCompanyAndComponentUnitAndBusinessUnit>} items
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @instance
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse} PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @static
                 * @param {mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse} message PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListPostUnitAndCompanyAndComponentUnitAndBusinessUnitResponse;
            })();

            company.CreateBusinessUnitRequestRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitRequestRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequest|null} [businessUnitRequest] CreateBusinessUnitRequestRequest businessUnitRequest
                 */

                /**
                 * Constructs a new CreateBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitRequestRequest.
                 * @implements ICreateBusinessUnitRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitRequestRequest businessUnitRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRequest|null|undefined} businessUnitRequest
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestRequest
                 * @instance
                 */
                CreateBusinessUnitRequestRequest.prototype.businessUnitRequest = null;

                /**
                 * Verifies a CreateBusinessUnitRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.businessUnitRequest);
                        if (error)
                            return "businessUnitRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitRequestRequest} CreateBusinessUnitRequestRequest
                 */
                CreateBusinessUnitRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitRequestRequest();
                    if (object.businessUnitRequest != null) {
                        if (typeof object.businessUnitRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitRequestRequest.businessUnitRequest: object expected");
                        message.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.businessUnitRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitRequestRequest} message CreateBusinessUnitRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequest = null;
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest"))
                        object.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.businessUnitRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitRequestRequest;
            })();

            company.ListBusinessUnitRequestsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRequestsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRequestsRequest.
                 * @implements IListBusinessUnitRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRequestsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRequestsRequest} ListBusinessUnitRequestsRequest
                 */
                ListBusinessUnitRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRequestsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitRequestsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestsRequest} message ListBusinessUnitRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRequestsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRequestsRequest;
            })();

            company.ListBusinessUnitRequestsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRequestsResponse
                 * @property {Long|null} [total] ListBusinessUnitRequestsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitRequest>|null} [items] ListBusinessUnitRequestsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRequestsResponse.
                 * @implements IListBusinessUnitRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @instance
                 */
                ListBusinessUnitRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRequestsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitRequest>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @instance
                 */
                ListBusinessUnitRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRequestsResponse} ListBusinessUnitRequestsResponse
                 */
                ListBusinessUnitRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestsResponse} message ListBusinessUnitRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRequestsResponse;
            })();

            company.GetBusinessUnitRequestRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitRequestRequest
                 * @property {string|null} [businessUnitRequestId] GetBusinessUnitRequestRequest businessUnitRequestId
                 */

                /**
                 * Constructs a new GetBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitRequestRequest.
                 * @implements IGetBusinessUnitRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitRequestRequest businessUnitRequestId.
                 * @member {string} businessUnitRequestId
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestRequest
                 * @instance
                 */
                GetBusinessUnitRequestRequest.prototype.businessUnitRequestId = "";

                /**
                 * Verifies a GetBusinessUnitRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestId != null && message.hasOwnProperty("businessUnitRequestId"))
                        if (!$util.isString(message.businessUnitRequestId))
                            return "businessUnitRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitRequestRequest} GetBusinessUnitRequestRequest
                 */
                GetBusinessUnitRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitRequestRequest();
                    if (object.businessUnitRequestId != null)
                        message.businessUnitRequestId = String(object.businessUnitRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitRequestRequest} message GetBusinessUnitRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequestId = "";
                    if (message.businessUnitRequestId != null && message.hasOwnProperty("businessUnitRequestId"))
                        object.businessUnitRequestId = message.businessUnitRequestId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitRequestRequest;
            })();

            company.UpdateBusinessUnitRequestRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitRequestRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequest|null} [businessUnitRequest] UpdateBusinessUnitRequestRequest businessUnitRequest
                 */

                /**
                 * Constructs a new UpdateBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitRequestRequest.
                 * @implements IUpdateBusinessUnitRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitRequestRequest businessUnitRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRequest|null|undefined} businessUnitRequest
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestRequest
                 * @instance
                 */
                UpdateBusinessUnitRequestRequest.prototype.businessUnitRequest = null;

                /**
                 * Verifies an UpdateBusinessUnitRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.businessUnitRequest);
                        if (error)
                            return "businessUnitRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitRequestRequest} UpdateBusinessUnitRequestRequest
                 */
                UpdateBusinessUnitRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitRequestRequest();
                    if (object.businessUnitRequest != null) {
                        if (typeof object.businessUnitRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitRequestRequest.businessUnitRequest: object expected");
                        message.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.businessUnitRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitRequestRequest} message UpdateBusinessUnitRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequest = null;
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest"))
                        object.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.businessUnitRequest, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitRequestRequest;
            })();

            company.DeleteBusinessUnitRequestRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitRequestRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequest|null} [businessUnitRequest] DeleteBusinessUnitRequestRequest businessUnitRequest
                 */

                /**
                 * Constructs a new DeleteBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitRequestRequest.
                 * @implements IDeleteBusinessUnitRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitRequestRequest businessUnitRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRequest|null|undefined} businessUnitRequest
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestRequest
                 * @instance
                 */
                DeleteBusinessUnitRequestRequest.prototype.businessUnitRequest = null;

                /**
                 * Verifies a DeleteBusinessUnitRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.businessUnitRequest);
                        if (error)
                            return "businessUnitRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitRequestRequest} DeleteBusinessUnitRequestRequest
                 */
                DeleteBusinessUnitRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitRequestRequest();
                    if (object.businessUnitRequest != null) {
                        if (typeof object.businessUnitRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitRequestRequest.businessUnitRequest: object expected");
                        message.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.businessUnitRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitRequestRequest} message DeleteBusinessUnitRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequest = null;
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest"))
                        object.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.businessUnitRequest, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitRequestRequest;
            })();

            company.CreateBusinessUnitRequestResultRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitRequestResultRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequestResult|null} [businessUnitRequestResult] CreateBusinessUnitRequestResultRequest businessUnitRequestResult
                 * @property {mtechnavi.api.company.IBusinessUnitRequest|null} [businessUnitRequest] CreateBusinessUnitRequestResultRequest businessUnitRequest
                 */

                /**
                 * Constructs a new CreateBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitRequestResultRequest.
                 * @implements ICreateBusinessUnitRequestResultRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRequestResultRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitRequestResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitRequestResultRequest businessUnitRequestResult.
                 * @member {mtechnavi.api.company.IBusinessUnitRequestResult|null|undefined} businessUnitRequestResult
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @instance
                 */
                CreateBusinessUnitRequestResultRequest.prototype.businessUnitRequestResult = null;

                /**
                 * CreateBusinessUnitRequestResultRequest businessUnitRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRequest|null|undefined} businessUnitRequest
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @instance
                 */
                CreateBusinessUnitRequestResultRequest.prototype.businessUnitRequest = null;

                /**
                 * Verifies a CreateBusinessUnitRequestResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitRequestResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequestResult.verify(message.businessUnitRequestResult);
                        if (error)
                            return "businessUnitRequestResult." + error;
                    }
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.businessUnitRequest);
                        if (error)
                            return "businessUnitRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitRequestResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitRequestResultRequest} CreateBusinessUnitRequestResultRequest
                 */
                CreateBusinessUnitRequestResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitRequestResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitRequestResultRequest();
                    if (object.businessUnitRequestResult != null) {
                        if (typeof object.businessUnitRequestResult !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitRequestResultRequest.businessUnitRequestResult: object expected");
                        message.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.fromObject(object.businessUnitRequestResult);
                    }
                    if (object.businessUnitRequest != null) {
                        if (typeof object.businessUnitRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitRequestResultRequest.businessUnitRequest: object expected");
                        message.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.businessUnitRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitRequestResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitRequestResultRequest} message CreateBusinessUnitRequestResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitRequestResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRequestResult = null;
                        object.businessUnitRequest = null;
                    }
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult"))
                        object.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.toObject(message.businessUnitRequestResult, options);
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest"))
                        object.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.businessUnitRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitRequestResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRequestResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitRequestResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitRequestResultRequest;
            })();

            company.ListBusinessUnitRequestResultsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRequestResultsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRequestResultsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitRequestResultsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRequestResultsRequest.
                 * @implements IListBusinessUnitRequestResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestResultsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRequestResultsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitRequestResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRequestResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRequestResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRequestResultsRequest} ListBusinessUnitRequestResultsRequest
                 */
                ListBusinessUnitRequestResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRequestResultsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitRequestResultsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRequestResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestResultsRequest} message ListBusinessUnitRequestResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRequestResultsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitRequestResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRequestResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRequestResultsRequest;
            })();

            company.ListBusinessUnitRequestResultsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRequestResultsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRequestResultsResponse
                 * @property {Long|null} [total] ListBusinessUnitRequestResultsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitRequestResult>|null} [items] ListBusinessUnitRequestResultsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitRequestResultsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRequestResultsResponse.
                 * @implements IListBusinessUnitRequestResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRequestResultsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRequestResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRequestResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @instance
                 */
                ListBusinessUnitRequestResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRequestResultsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitRequestResult>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @instance
                 */
                ListBusinessUnitRequestResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRequestResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRequestResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitRequestResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRequestResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRequestResultsResponse} ListBusinessUnitRequestResultsResponse
                 */
                ListBusinessUnitRequestResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRequestResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRequestResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRequestResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRequestResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitRequestResult.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRequestResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRequestResultsResponse} message ListBusinessUnitRequestResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRequestResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitRequestResult.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRequestResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRequestResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRequestResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRequestResultsResponse;
            })();

            company.GetBusinessUnitRequestResultRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitRequestResultRequest
                 * @property {string|null} [businessUnitRequestResultId] GetBusinessUnitRequestResultRequest businessUnitRequestResultId
                 */

                /**
                 * Constructs a new GetBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitRequestResultRequest.
                 * @implements IGetBusinessUnitRequestResultRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitRequestResultRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitRequestResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitRequestResultRequest businessUnitRequestResultId.
                 * @member {string} businessUnitRequestResultId
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestResultRequest
                 * @instance
                 */
                GetBusinessUnitRequestResultRequest.prototype.businessUnitRequestResultId = "";

                /**
                 * Verifies a GetBusinessUnitRequestResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitRequestResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestResultId != null && message.hasOwnProperty("businessUnitRequestResultId"))
                        if (!$util.isString(message.businessUnitRequestResultId))
                            return "businessUnitRequestResultId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitRequestResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitRequestResultRequest} GetBusinessUnitRequestResultRequest
                 */
                GetBusinessUnitRequestResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitRequestResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitRequestResultRequest();
                    if (object.businessUnitRequestResultId != null)
                        message.businessUnitRequestResultId = String(object.businessUnitRequestResultId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitRequestResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestResultRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitRequestResultRequest} message GetBusinessUnitRequestResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitRequestResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequestResultId = "";
                    if (message.businessUnitRequestResultId != null && message.hasOwnProperty("businessUnitRequestResultId"))
                        object.businessUnitRequestResultId = message.businessUnitRequestResultId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitRequestResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitRequestResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitRequestResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitRequestResultRequest;
            })();

            company.UpdateBusinessUnitRequestResultRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitRequestResultRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequestResult|null} [businessUnitRequestResult] UpdateBusinessUnitRequestResultRequest businessUnitRequestResult
                 */

                /**
                 * Constructs a new UpdateBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitRequestResultRequest.
                 * @implements IUpdateBusinessUnitRequestResultRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRequestResultRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitRequestResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitRequestResultRequest businessUnitRequestResult.
                 * @member {mtechnavi.api.company.IBusinessUnitRequestResult|null|undefined} businessUnitRequestResult
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest
                 * @instance
                 */
                UpdateBusinessUnitRequestResultRequest.prototype.businessUnitRequestResult = null;

                /**
                 * Verifies an UpdateBusinessUnitRequestResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitRequestResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequestResult.verify(message.businessUnitRequestResult);
                        if (error)
                            return "businessUnitRequestResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitRequestResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest} UpdateBusinessUnitRequestResultRequest
                 */
                UpdateBusinessUnitRequestResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest();
                    if (object.businessUnitRequestResult != null) {
                        if (typeof object.businessUnitRequestResult !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest.businessUnitRequestResult: object expected");
                        message.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.fromObject(object.businessUnitRequestResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitRequestResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest} message UpdateBusinessUnitRequestResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitRequestResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequestResult = null;
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult"))
                        object.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.toObject(message.businessUnitRequestResult, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitRequestResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRequestResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitRequestResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitRequestResultRequest;
            })();

            company.DeleteBusinessUnitRequestResultRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitRequestResultRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRequestResult|null} [businessUnitRequestResult] DeleteBusinessUnitRequestResultRequest businessUnitRequestResult
                 */

                /**
                 * Constructs a new DeleteBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitRequestResultRequest.
                 * @implements IDeleteBusinessUnitRequestResultRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRequestResultRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitRequestResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitRequestResultRequest businessUnitRequestResult.
                 * @member {mtechnavi.api.company.IBusinessUnitRequestResult|null|undefined} businessUnitRequestResult
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest
                 * @instance
                 */
                DeleteBusinessUnitRequestResultRequest.prototype.businessUnitRequestResult = null;

                /**
                 * Verifies a DeleteBusinessUnitRequestResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitRequestResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequestResult.verify(message.businessUnitRequestResult);
                        if (error)
                            return "businessUnitRequestResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitRequestResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest} DeleteBusinessUnitRequestResultRequest
                 */
                DeleteBusinessUnitRequestResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest();
                    if (object.businessUnitRequestResult != null) {
                        if (typeof object.businessUnitRequestResult !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest.businessUnitRequestResult: object expected");
                        message.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.fromObject(object.businessUnitRequestResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitRequestResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest} message DeleteBusinessUnitRequestResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitRequestResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRequestResult = null;
                    if (message.businessUnitRequestResult != null && message.hasOwnProperty("businessUnitRequestResult"))
                        object.businessUnitRequestResult = $root.mtechnavi.api.company.BusinessUnitRequestResult.toObject(message.businessUnitRequestResult, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitRequestResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRequestResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitRequestResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitRequestResultRequest;
            })();

            company.SharetoBusinessUnitRequestRequest = (function() {

                /**
                 * Properties of a SharetoBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitRequestRequest
                 * @property {string|null} [id] SharetoBusinessUnitRequestRequest id
                 */

                /**
                 * Constructs a new SharetoBusinessUnitRequestRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitRequestRequest.
                 * @implements ISharetoBusinessUnitRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestRequest=} [properties] Properties to set
                 */
                function SharetoBusinessUnitRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoBusinessUnitRequestRequest id.
                 * @member {string} id
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestRequest
                 * @instance
                 */
                SharetoBusinessUnitRequestRequest.prototype.id = "";

                /**
                 * Verifies a SharetoBusinessUnitRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitRequestRequest} SharetoBusinessUnitRequestRequest
                 */
                SharetoBusinessUnitRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharetoBusinessUnitRequestRequest();
                    if (object.id != null)
                        message.id = String(object.id);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestRequest
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestRequest} message SharetoBusinessUnitRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.id = "";
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this SharetoBusinessUnitRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitRequestRequest;
            })();

            company.SharetoBusinessUnitRequestResponse = (function() {

                /**
                 * Properties of a SharetoBusinessUnitRequestResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitRequestResponse
                 */

                /**
                 * Constructs a new SharetoBusinessUnitRequestResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitRequestResponse.
                 * @implements ISharetoBusinessUnitRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestResponse=} [properties] Properties to set
                 */
                function SharetoBusinessUnitRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoBusinessUnitRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitRequestResponse} SharetoBusinessUnitRequestResponse
                 */
                SharetoBusinessUnitRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.company.SharetoBusinessUnitRequestResponse();
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResponse
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestResponse} message SharetoBusinessUnitRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoBusinessUnitRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitRequestResponse;
            })();

            company.SharetoBusinessUnitRequestResultRequest = (function() {

                /**
                 * Properties of a SharetoBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitRequestResultRequest
                 * @property {string|null} [id] SharetoBusinessUnitRequestResultRequest id
                 */

                /**
                 * Constructs a new SharetoBusinessUnitRequestResultRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitRequestResultRequest.
                 * @implements ISharetoBusinessUnitRequestResultRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestResultRequest=} [properties] Properties to set
                 */
                function SharetoBusinessUnitRequestResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoBusinessUnitRequestResultRequest id.
                 * @member {string} id
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest
                 * @instance
                 */
                SharetoBusinessUnitRequestResultRequest.prototype.id = "";

                /**
                 * Verifies a SharetoBusinessUnitRequestResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitRequestResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitRequestResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest} SharetoBusinessUnitRequestResultRequest
                 */
                SharetoBusinessUnitRequestResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest();
                    if (object.id != null)
                        message.id = String(object.id);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitRequestResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest} message SharetoBusinessUnitRequestResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitRequestResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.id = "";
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this SharetoBusinessUnitRequestResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitRequestResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitRequestResultRequest;
            })();

            company.SharetoBusinessUnitRequestResultResponse = (function() {

                /**
                 * Properties of a SharetoBusinessUnitRequestResultResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitRequestResultResponse
                 */

                /**
                 * Constructs a new SharetoBusinessUnitRequestResultResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitRequestResultResponse.
                 * @implements ISharetoBusinessUnitRequestResultResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitRequestResultResponse=} [properties] Properties to set
                 */
                function SharetoBusinessUnitRequestResultResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoBusinessUnitRequestResultResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitRequestResultResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitRequestResultResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse} SharetoBusinessUnitRequestResultResponse
                 */
                SharetoBusinessUnitRequestResultResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse)
                        return object;
                    return new $root.mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse();
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitRequestResultResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse} message SharetoBusinessUnitRequestResultResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitRequestResultResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoBusinessUnitRequestResultResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitRequestResultResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitRequestResultResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitRequestResultResponse;
            })();

            company.ListStaffsRequest = (function() {

                /**
                 * Properties of a ListStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffsRequest
                 * @property {string|null} [email] ListStaffsRequest email
                 */

                /**
                 * Constructs a new ListStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffsRequest.
                 * @implements IListStaffsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffsRequest=} [properties] Properties to set
                 */
                function ListStaffsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListStaffsRequest email.
                 * @member {string} email
                 * @memberof mtechnavi.api.company.ListStaffsRequest
                 * @instance
                 */
                ListStaffsRequest.prototype.email = "";

                /**
                 * Verifies a ListStaffsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a ListStaffsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffsRequest} ListStaffsRequest
                 */
                ListStaffsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListStaffsRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a ListStaffsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListStaffsRequest} message ListStaffsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this ListStaffsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffsRequest;
            })();

            company.ListStaffsResponse = (function() {

                /**
                 * Properties of a ListStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffsResponse
                 * @property {Long|null} [total] ListStaffsResponse total
                 * @property {Array.<mtechnavi.api.company.IStaff>|null} [items] ListStaffsResponse items
                 */

                /**
                 * Constructs a new ListStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffsResponse.
                 * @implements IListStaffsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffsResponse=} [properties] Properties to set
                 */
                function ListStaffsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListStaffsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @instance
                 */
                ListStaffsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListStaffsResponse items.
                 * @member {Array.<mtechnavi.api.company.IStaff>} items
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @instance
                 */
                ListStaffsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListStaffsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.Staff.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListStaffsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffsResponse} ListStaffsResponse
                 */
                ListStaffsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListStaffsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListStaffsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListStaffsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.Staff.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListStaffsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListStaffsResponse} message ListStaffsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.Staff.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListStaffsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffsResponse;
            })();

            company.GetStaffRequest = (function() {

                /**
                 * Properties of a GetStaffRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetStaffRequest
                 * @property {string|null} [staffId] GetStaffRequest staffId
                 */

                /**
                 * Constructs a new GetStaffRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetStaffRequest.
                 * @implements IGetStaffRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetStaffRequest=} [properties] Properties to set
                 */
                function GetStaffRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetStaffRequest staffId.
                 * @member {string} staffId
                 * @memberof mtechnavi.api.company.GetStaffRequest
                 * @instance
                 */
                GetStaffRequest.prototype.staffId = "";

                /**
                 * Verifies a GetStaffRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetStaffRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStaffRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        if (!$util.isString(message.staffId))
                            return "staffId: string expected";
                    return null;
                };

                /**
                 * Creates a GetStaffRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetStaffRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetStaffRequest} GetStaffRequest
                 */
                GetStaffRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetStaffRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetStaffRequest();
                    if (object.staffId != null)
                        message.staffId = String(object.staffId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetStaffRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetStaffRequest
                 * @static
                 * @param {mtechnavi.api.company.GetStaffRequest} message GetStaffRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStaffRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.staffId = "";
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        object.staffId = message.staffId;
                    return object;
                };

                /**
                 * Converts this GetStaffRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetStaffRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStaffRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetStaffRequest;
            })();

            company.ListStaffAndComponentUnitsRequest = (function() {

                /**
                 * Properties of a ListStaffAndComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffAndComponentUnitsRequest
                 * @property {string|null} [email] ListStaffAndComponentUnitsRequest email
                 */

                /**
                 * Constructs a new ListStaffAndComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffAndComponentUnitsRequest.
                 * @implements IListStaffAndComponentUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffAndComponentUnitsRequest=} [properties] Properties to set
                 */
                function ListStaffAndComponentUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListStaffAndComponentUnitsRequest email.
                 * @member {string} email
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsRequest
                 * @instance
                 */
                ListStaffAndComponentUnitsRequest.prototype.email = "";

                /**
                 * Verifies a ListStaffAndComponentUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffAndComponentUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a ListStaffAndComponentUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffAndComponentUnitsRequest} ListStaffAndComponentUnitsRequest
                 */
                ListStaffAndComponentUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffAndComponentUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListStaffAndComponentUnitsRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a ListStaffAndComponentUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListStaffAndComponentUnitsRequest} message ListStaffAndComponentUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffAndComponentUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this ListStaffAndComponentUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffAndComponentUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffAndComponentUnitsRequest;
            })();

            company.ListStaffAndComponentUnitsResponse = (function() {

                /**
                 * Properties of a ListStaffAndComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffAndComponentUnitsResponse
                 * @property {Long|null} [total] ListStaffAndComponentUnitsResponse total
                 * @property {Array.<mtechnavi.api.company.IStaffAndComponentUnit>|null} [items] ListStaffAndComponentUnitsResponse items
                 */

                /**
                 * Constructs a new ListStaffAndComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffAndComponentUnitsResponse.
                 * @implements IListStaffAndComponentUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffAndComponentUnitsResponse=} [properties] Properties to set
                 */
                function ListStaffAndComponentUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListStaffAndComponentUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @instance
                 */
                ListStaffAndComponentUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListStaffAndComponentUnitsResponse items.
                 * @member {Array.<mtechnavi.api.company.IStaffAndComponentUnit>} items
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @instance
                 */
                ListStaffAndComponentUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListStaffAndComponentUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffAndComponentUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.StaffAndComponentUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListStaffAndComponentUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffAndComponentUnitsResponse} ListStaffAndComponentUnitsResponse
                 */
                ListStaffAndComponentUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffAndComponentUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListStaffAndComponentUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListStaffAndComponentUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListStaffAndComponentUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.StaffAndComponentUnit.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListStaffAndComponentUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListStaffAndComponentUnitsResponse} message ListStaffAndComponentUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffAndComponentUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.StaffAndComponentUnit.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListStaffAndComponentUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffAndComponentUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffAndComponentUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffAndComponentUnitsResponse;
            })();

            company.ImportStaffsRequest = (function() {

                /**
                 * Properties of an ImportStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportStaffsRequest
                 * @property {string|null} [url] ImportStaffsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportStaffsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportStaffsRequest dryRun
                 * @property {boolean|null} [exportError] ImportStaffsRequest exportError
                 */

                /**
                 * Constructs a new ImportStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportStaffsRequest.
                 * @implements IImportStaffsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportStaffsRequest=} [properties] Properties to set
                 */
                function ImportStaffsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportStaffsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @instance
                 */
                ImportStaffsRequest.prototype.url = "";

                /**
                 * ImportStaffsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @instance
                 */
                ImportStaffsRequest.prototype.fileFormat = null;

                /**
                 * ImportStaffsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @instance
                 */
                ImportStaffsRequest.prototype.dryRun = false;

                /**
                 * ImportStaffsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @instance
                 */
                ImportStaffsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportStaffsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportStaffsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportStaffsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportStaffsRequest} ImportStaffsRequest
                 */
                ImportStaffsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportStaffsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportStaffsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportStaffsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportStaffsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportStaffsRequest} message ImportStaffsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportStaffsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportStaffsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportStaffsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportStaffsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportStaffsRequest;
            })();

            company.ImportStaffsResponse = (function() {

                /**
                 * Properties of an ImportStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportStaffsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportStaffsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportStaffsResponse errors
                 * @property {string|null} [assetId] ImportStaffsResponse assetId
                 */

                /**
                 * Constructs a new ImportStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportStaffsResponse.
                 * @implements IImportStaffsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportStaffsResponse=} [properties] Properties to set
                 */
                function ImportStaffsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportStaffsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @instance
                 */
                ImportStaffsResponse.prototype.summary = null;

                /**
                 * ImportStaffsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @instance
                 */
                ImportStaffsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportStaffsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @instance
                 */
                ImportStaffsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportStaffsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportStaffsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportStaffsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportStaffsResponse} ImportStaffsResponse
                 */
                ImportStaffsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportStaffsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportStaffsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportStaffsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportStaffsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportStaffsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportStaffsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportStaffsResponse} message ImportStaffsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportStaffsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportStaffsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportStaffsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportStaffsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportStaffsResponse;
            })();

            company.ExportStaffsRequest = (function() {

                /**
                 * Properties of an ExportStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportStaffsRequest
                 * @property {Array.<string>|null} [ids] ExportStaffsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportStaffsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportStaffsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportStaffsRequest.
                 * @implements IExportStaffsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportStaffsRequest=} [properties] Properties to set
                 */
                function ExportStaffsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportStaffsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @instance
                 */
                ExportStaffsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportStaffsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @instance
                 */
                ExportStaffsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportStaffsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportStaffsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportStaffsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportStaffsRequest} ExportStaffsRequest
                 */
                ExportStaffsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportStaffsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportStaffsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportStaffsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportStaffsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportStaffsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportStaffsRequest} message ExportStaffsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportStaffsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportStaffsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportStaffsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportStaffsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportStaffsRequest;
            })();

            company.ExportStaffsResponse = (function() {

                /**
                 * Properties of an ExportStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportStaffsResponse
                 * @property {string|null} [assetId] ExportStaffsResponse assetId
                 */

                /**
                 * Constructs a new ExportStaffsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportStaffsResponse.
                 * @implements IExportStaffsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportStaffsResponse=} [properties] Properties to set
                 */
                function ExportStaffsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportStaffsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportStaffsResponse
                 * @instance
                 */
                ExportStaffsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportStaffsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportStaffsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportStaffsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportStaffsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportStaffsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportStaffsResponse} ExportStaffsResponse
                 */
                ExportStaffsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportStaffsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportStaffsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportStaffsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportStaffsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportStaffsResponse} message ExportStaffsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportStaffsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportStaffsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportStaffsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportStaffsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportStaffsResponse;
            })();

            company.ImportComponentUnitsRequest = (function() {

                /**
                 * Properties of an ImportComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportComponentUnitsRequest
                 * @property {string|null} [url] ImportComponentUnitsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportComponentUnitsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportComponentUnitsRequest dryRun
                 * @property {boolean|null} [exportError] ImportComponentUnitsRequest exportError
                 */

                /**
                 * Constructs a new ImportComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportComponentUnitsRequest.
                 * @implements IImportComponentUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportComponentUnitsRequest=} [properties] Properties to set
                 */
                function ImportComponentUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportComponentUnitsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @instance
                 */
                ImportComponentUnitsRequest.prototype.url = "";

                /**
                 * ImportComponentUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @instance
                 */
                ImportComponentUnitsRequest.prototype.fileFormat = null;

                /**
                 * ImportComponentUnitsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @instance
                 */
                ImportComponentUnitsRequest.prototype.dryRun = false;

                /**
                 * ImportComponentUnitsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @instance
                 */
                ImportComponentUnitsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportComponentUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportComponentUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportComponentUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportComponentUnitsRequest} ImportComponentUnitsRequest
                 */
                ImportComponentUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportComponentUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportComponentUnitsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportComponentUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportComponentUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportComponentUnitsRequest} message ImportComponentUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportComponentUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportComponentUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportComponentUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportComponentUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportComponentUnitsRequest;
            })();

            company.ImportComponentUnitsResponse = (function() {

                /**
                 * Properties of an ImportComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportComponentUnitsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportComponentUnitsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportComponentUnitsResponse errors
                 * @property {string|null} [assetId] ImportComponentUnitsResponse assetId
                 */

                /**
                 * Constructs a new ImportComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportComponentUnitsResponse.
                 * @implements IImportComponentUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportComponentUnitsResponse=} [properties] Properties to set
                 */
                function ImportComponentUnitsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportComponentUnitsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @instance
                 */
                ImportComponentUnitsResponse.prototype.summary = null;

                /**
                 * ImportComponentUnitsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @instance
                 */
                ImportComponentUnitsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportComponentUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @instance
                 */
                ImportComponentUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportComponentUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportComponentUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportComponentUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportComponentUnitsResponse} ImportComponentUnitsResponse
                 */
                ImportComponentUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportComponentUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportComponentUnitsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportComponentUnitsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportComponentUnitsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportComponentUnitsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportComponentUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportComponentUnitsResponse} message ImportComponentUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportComponentUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportComponentUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportComponentUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportComponentUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportComponentUnitsResponse;
            })();

            company.ExportComponentUnitsRequest = (function() {

                /**
                 * Properties of an ExportComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportComponentUnitsRequest
                 * @property {Array.<string>|null} [ids] ExportComponentUnitsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportComponentUnitsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportComponentUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportComponentUnitsRequest.
                 * @implements IExportComponentUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportComponentUnitsRequest=} [properties] Properties to set
                 */
                function ExportComponentUnitsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportComponentUnitsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @instance
                 */
                ExportComponentUnitsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportComponentUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @instance
                 */
                ExportComponentUnitsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportComponentUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportComponentUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportComponentUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportComponentUnitsRequest} ExportComponentUnitsRequest
                 */
                ExportComponentUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportComponentUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportComponentUnitsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportComponentUnitsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportComponentUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportComponentUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportComponentUnitsRequest} message ExportComponentUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportComponentUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportComponentUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportComponentUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportComponentUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportComponentUnitsRequest;
            })();

            company.ExportComponentUnitsResponse = (function() {

                /**
                 * Properties of an ExportComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportComponentUnitsResponse
                 * @property {string|null} [assetId] ExportComponentUnitsResponse assetId
                 */

                /**
                 * Constructs a new ExportComponentUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportComponentUnitsResponse.
                 * @implements IExportComponentUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportComponentUnitsResponse=} [properties] Properties to set
                 */
                function ExportComponentUnitsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportComponentUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportComponentUnitsResponse
                 * @instance
                 */
                ExportComponentUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportComponentUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportComponentUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportComponentUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportComponentUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportComponentUnitsResponse} ExportComponentUnitsResponse
                 */
                ExportComponentUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportComponentUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportComponentUnitsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportComponentUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportComponentUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportComponentUnitsResponse} message ExportComponentUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportComponentUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportComponentUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportComponentUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportComponentUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportComponentUnitsResponse;
            })();

            company.ImportBusinessUnitsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitsRequest
                 * @property {string|null} [url] ImportBusinessUnitsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitsRequest.
                 * @implements IImportBusinessUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @instance
                 */
                ImportBusinessUnitsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @instance
                 */
                ImportBusinessUnitsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @instance
                 */
                ImportBusinessUnitsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @instance
                 */
                ImportBusinessUnitsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitsRequest} ImportBusinessUnitsRequest
                 */
                ImportBusinessUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitsRequest} message ImportBusinessUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitsRequest;
            })();

            company.ImportBusinessUnitsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitsResponse.
                 * @implements IImportBusinessUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @instance
                 */
                ImportBusinessUnitsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @instance
                 */
                ImportBusinessUnitsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @instance
                 */
                ImportBusinessUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitsResponse} ImportBusinessUnitsResponse
                 */
                ImportBusinessUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitsResponse} message ImportBusinessUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitsResponse;
            })();

            company.ExportBusinessUnitsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitsRequest.
                 * @implements IExportBusinessUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @instance
                 */
                ExportBusinessUnitsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @instance
                 */
                ExportBusinessUnitsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitsRequest} ExportBusinessUnitsRequest
                 */
                ExportBusinessUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitsRequest} message ExportBusinessUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitsRequest;
            })();

            company.ExportBusinessUnitsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitsResponse.
                 * @implements IExportBusinessUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsResponse
                 * @instance
                 */
                ExportBusinessUnitsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitsResponse} ExportBusinessUnitsResponse
                 */
                ExportBusinessUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitsResponse} message ExportBusinessUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitsResponse;
            })();

            company.ListComponentUnitFormatsRequest = (function() {

                /**
                 * Properties of a ListComponentUnitFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListComponentUnitFormatsRequest
                 */

                /**
                 * Constructs a new ListComponentUnitFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListComponentUnitFormatsRequest.
                 * @implements IListComponentUnitFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListComponentUnitFormatsRequest=} [properties] Properties to set
                 */
                function ListComponentUnitFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListComponentUnitFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListComponentUnitFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListComponentUnitFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListComponentUnitFormatsRequest} ListComponentUnitFormatsRequest
                 */
                ListComponentUnitFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListComponentUnitFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListComponentUnitFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListComponentUnitFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListComponentUnitFormatsRequest} message ListComponentUnitFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListComponentUnitFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListComponentUnitFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListComponentUnitFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListComponentUnitFormatsRequest;
            })();

            company.ListComponentUnitFormatsResponse = (function() {

                /**
                 * Properties of a ListComponentUnitFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListComponentUnitFormatsResponse
                 * @property {Long|null} [total] ListComponentUnitFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListComponentUnitFormatsResponse items
                 */

                /**
                 * Constructs a new ListComponentUnitFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListComponentUnitFormatsResponse.
                 * @implements IListComponentUnitFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListComponentUnitFormatsResponse=} [properties] Properties to set
                 */
                function ListComponentUnitFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListComponentUnitFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @instance
                 */
                ListComponentUnitFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListComponentUnitFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @instance
                 */
                ListComponentUnitFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListComponentUnitFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListComponentUnitFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListComponentUnitFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListComponentUnitFormatsResponse} ListComponentUnitFormatsResponse
                 */
                ListComponentUnitFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListComponentUnitFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListComponentUnitFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListComponentUnitFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListComponentUnitFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListComponentUnitFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListComponentUnitFormatsResponse} message ListComponentUnitFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListComponentUnitFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListComponentUnitFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListComponentUnitFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListComponentUnitFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListComponentUnitFormatsResponse;
            })();

            company.ListBusinessUnitFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFormatsRequest.
                 * @implements IListBusinessUnitFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFormatsRequest} ListBusinessUnitFormatsRequest
                 */
                ListBusinessUnitFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFormatsRequest} message ListBusinessUnitFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFormatsRequest;
            })();

            company.ListBusinessUnitFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFormatsResponse.
                 * @implements IListBusinessUnitFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFormatsResponse} ListBusinessUnitFormatsResponse
                 */
                ListBusinessUnitFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFormatsResponse} message ListBusinessUnitFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFormatsResponse;
            })();

            company.ListStaffFormatsRequest = (function() {

                /**
                 * Properties of a ListStaffFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffFormatsRequest
                 */

                /**
                 * Constructs a new ListStaffFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffFormatsRequest.
                 * @implements IListStaffFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffFormatsRequest=} [properties] Properties to set
                 */
                function ListStaffFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListStaffFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListStaffFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffFormatsRequest} ListStaffFormatsRequest
                 */
                ListStaffFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListStaffFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListStaffFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListStaffFormatsRequest} message ListStaffFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListStaffFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffFormatsRequest;
            })();

            company.ListStaffFormatsResponse = (function() {

                /**
                 * Properties of a ListStaffFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListStaffFormatsResponse
                 * @property {Long|null} [total] ListStaffFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListStaffFormatsResponse items
                 */

                /**
                 * Constructs a new ListStaffFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListStaffFormatsResponse.
                 * @implements IListStaffFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListStaffFormatsResponse=} [properties] Properties to set
                 */
                function ListStaffFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListStaffFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @instance
                 */
                ListStaffFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListStaffFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @instance
                 */
                ListStaffFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListStaffFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListStaffFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListStaffFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListStaffFormatsResponse} ListStaffFormatsResponse
                 */
                ListStaffFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListStaffFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListStaffFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListStaffFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListStaffFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListStaffFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListStaffFormatsResponse} message ListStaffFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListStaffFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListStaffFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListStaffFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListStaffFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListStaffFormatsResponse;
            })();

            company.PublicGetCompanyRequest = (function() {

                /**
                 * Properties of a PublicGetCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicGetCompanyRequest
                 * @property {string|null} [companyId] PublicGetCompanyRequest companyId
                 */

                /**
                 * Constructs a new PublicGetCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicGetCompanyRequest.
                 * @implements IPublicGetCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicGetCompanyRequest=} [properties] Properties to set
                 */
                function PublicGetCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicGetCompanyRequest companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.PublicGetCompanyRequest
                 * @instance
                 */
                PublicGetCompanyRequest.prototype.companyId = "";

                /**
                 * Verifies a PublicGetCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicGetCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicGetCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    return null;
                };

                /**
                 * Creates a PublicGetCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicGetCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicGetCompanyRequest} PublicGetCompanyRequest
                 */
                PublicGetCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicGetCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicGetCompanyRequest();
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    return message;
                };

                /**
                 * Creates a plain object from a PublicGetCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicGetCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicGetCompanyRequest} message PublicGetCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicGetCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.companyId = "";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    return object;
                };

                /**
                 * Converts this PublicGetCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicGetCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicGetCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicGetCompanyRequest;
            })();

            company.PublicListCompanysRequest = (function() {

                /**
                 * Properties of a PublicListCompanysRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanysRequest
                 * @property {string|null} [pageToken] PublicListCompanysRequest pageToken
                 */

                /**
                 * Constructs a new PublicListCompanysRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanysRequest.
                 * @implements IPublicListCompanysRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanysRequest=} [properties] Properties to set
                 */
                function PublicListCompanysRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListCompanysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.PublicListCompanysRequest
                 * @instance
                 */
                PublicListCompanysRequest.prototype.pageToken = "";

                /**
                 * Verifies a PublicListCompanysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a PublicListCompanysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanysRequest} PublicListCompanysRequest
                 */
                PublicListCompanysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListCompanysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListCompanysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanysRequest
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanysRequest} message PublicListCompanysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this PublicListCompanysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanysRequest;
            })();

            company.PublicListCompanysResponse = (function() {

                /**
                 * Properties of a PublicListCompanysResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IPublicListCompanysResponse
                 * @property {Long|null} [total] PublicListCompanysResponse total
                 * @property {Array.<mtechnavi.api.company.ICompany>|null} [items] PublicListCompanysResponse items
                 * @property {string|null} [pageToken] PublicListCompanysResponse pageToken
                 */

                /**
                 * Constructs a new PublicListCompanysResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PublicListCompanysResponse.
                 * @implements IPublicListCompanysResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IPublicListCompanysResponse=} [properties] Properties to set
                 */
                function PublicListCompanysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListCompanysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @instance
                 */
                PublicListCompanysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListCompanysResponse items.
                 * @member {Array.<mtechnavi.api.company.ICompany>} items
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @instance
                 */
                PublicListCompanysResponse.prototype.items = $util.emptyArray;

                /**
                 * PublicListCompanysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @instance
                 */
                PublicListCompanysResponse.prototype.pageToken = "";

                /**
                 * Verifies a PublicListCompanysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListCompanysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.Company.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a PublicListCompanysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PublicListCompanysResponse} PublicListCompanysResponse
                 */
                PublicListCompanysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PublicListCompanysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.PublicListCompanysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.PublicListCompanysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PublicListCompanysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.Company.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListCompanysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @static
                 * @param {mtechnavi.api.company.PublicListCompanysResponse} message PublicListCompanysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListCompanysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.Company.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this PublicListCompanysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PublicListCompanysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListCompanysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListCompanysResponse;
            })();

            company.ListBusinessUnitManagementsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitManagementsRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitManagementsRequest businessUnitManagementIds
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitManagementsRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementsRequest.
                 * @implements IListBusinessUnitManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementsRequest(properties) {
                    this.businessUnitManagementIds = [];
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @instance
                 */
                ListBusinessUnitManagementsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitManagementsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @instance
                 */
                ListBusinessUnitManagementsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * ListBusinessUnitManagementsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @instance
                 */
                ListBusinessUnitManagementsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementsRequest} ListBusinessUnitManagementsRequest
                 */
                ListBusinessUnitManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementsRequest} message ListBusinessUnitManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.businessUnitManagementIds = [];
                        object.companyIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementsRequest;
            })();

            company.ListBusinessUnitManagementsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementsResponse
                 * @property {Long|null} [total] ListBusinessUnitManagementsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitManagement>|null} [items] ListBusinessUnitManagementsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitManagementsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementsResponse.
                 * @implements IListBusinessUnitManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @instance
                 */
                ListBusinessUnitManagementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitManagementsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitManagement>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @instance
                 */
                ListBusinessUnitManagementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitManagementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @instance
                 */
                ListBusinessUnitManagementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementsResponse} ListBusinessUnitManagementsResponse
                 */
                ListBusinessUnitManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementsResponse} message ListBusinessUnitManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementsResponse;
            })();

            company.GetBusinessUnitManagementRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitManagementRequest
                 * @property {string|null} [businessUnitManagementId] GetBusinessUnitManagementRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new GetBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitManagementRequest.
                 * @implements IGetBusinessUnitManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitManagementRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementRequest
                 * @instance
                 */
                GetBusinessUnitManagementRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a GetBusinessUnitManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitManagementRequest} GetBusinessUnitManagementRequest
                 */
                GetBusinessUnitManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitManagementRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitManagementRequest} message GetBusinessUnitManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagementId = "";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitManagementRequest;
            })();

            company.ListOldBusinessUnitManagementFormatsRequest = (function() {

                /**
                 * Properties of a ListOldBusinessUnitManagementFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListOldBusinessUnitManagementFormatsRequest
                 * @property {string|null} [pageToken] ListOldBusinessUnitManagementFormatsRequest pageToken
                 */

                /**
                 * Constructs a new ListOldBusinessUnitManagementFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOldBusinessUnitManagementFormatsRequest.
                 * @implements IListOldBusinessUnitManagementFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListOldBusinessUnitManagementFormatsRequest=} [properties] Properties to set
                 */
                function ListOldBusinessUnitManagementFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOldBusinessUnitManagementFormatsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest
                 * @instance
                 */
                ListOldBusinessUnitManagementFormatsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListOldBusinessUnitManagementFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOldBusinessUnitManagementFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListOldBusinessUnitManagementFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest} ListOldBusinessUnitManagementFormatsRequest
                 */
                ListOldBusinessUnitManagementFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListOldBusinessUnitManagementFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest} message ListOldBusinessUnitManagementFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOldBusinessUnitManagementFormatsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListOldBusinessUnitManagementFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOldBusinessUnitManagementFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOldBusinessUnitManagementFormatsRequest;
            })();

            company.ListOldBusinessUnitManagementFormatsResponse = (function() {

                /**
                 * Properties of a ListOldBusinessUnitManagementFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListOldBusinessUnitManagementFormatsResponse
                 * @property {Long|null} [total] ListOldBusinessUnitManagementFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListOldBusinessUnitManagementFormatsResponse items
                 * @property {string|null} [pageToken] ListOldBusinessUnitManagementFormatsResponse pageToken
                 */

                /**
                 * Constructs a new ListOldBusinessUnitManagementFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOldBusinessUnitManagementFormatsResponse.
                 * @implements IListOldBusinessUnitManagementFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListOldBusinessUnitManagementFormatsResponse=} [properties] Properties to set
                 */
                function ListOldBusinessUnitManagementFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOldBusinessUnitManagementFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @instance
                 */
                ListOldBusinessUnitManagementFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListOldBusinessUnitManagementFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @instance
                 */
                ListOldBusinessUnitManagementFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListOldBusinessUnitManagementFormatsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @instance
                 */
                ListOldBusinessUnitManagementFormatsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListOldBusinessUnitManagementFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOldBusinessUnitManagementFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListOldBusinessUnitManagementFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse} ListOldBusinessUnitManagementFormatsResponse
                 */
                ListOldBusinessUnitManagementFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListOldBusinessUnitManagementFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse} message ListOldBusinessUnitManagementFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOldBusinessUnitManagementFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListOldBusinessUnitManagementFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOldBusinessUnitManagementFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOldBusinessUnitManagementFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOldBusinessUnitManagementFormatsResponse;
            })();

            company.CreateBusinessUnitManagementRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitManagementRequest
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] CreateBusinessUnitManagementRequest businessUnitManagement
                 */

                /**
                 * Constructs a new CreateBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitManagementRequest.
                 * @implements ICreateBusinessUnitManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitManagementRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitManagementRequest businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.CreateBusinessUnitManagementRequest
                 * @instance
                 */
                CreateBusinessUnitManagementRequest.prototype.businessUnitManagement = null;

                /**
                 * Verifies a CreateBusinessUnitManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitManagementRequest} CreateBusinessUnitManagementRequest
                 */
                CreateBusinessUnitManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitManagementRequest();
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitManagementRequest.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitManagementRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitManagementRequest} message CreateBusinessUnitManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagement = null;
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitManagementRequest;
            })();

            company.UpdateBusinessUnitManagementRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitManagementRequest
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] UpdateBusinessUnitManagementRequest businessUnitManagement
                 */

                /**
                 * Constructs a new UpdateBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitManagementRequest.
                 * @implements IUpdateBusinessUnitManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitManagementRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitManagementRequest businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitManagementRequest
                 * @instance
                 */
                UpdateBusinessUnitManagementRequest.prototype.businessUnitManagement = null;

                /**
                 * Verifies an UpdateBusinessUnitManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitManagementRequest} UpdateBusinessUnitManagementRequest
                 */
                UpdateBusinessUnitManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitManagementRequest();
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitManagementRequest.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitManagementRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitManagementRequest} message UpdateBusinessUnitManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagement = null;
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitManagementRequest;
            })();

            company.DeleteBusinessUnitManagementRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitManagementRequest
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] DeleteBusinessUnitManagementRequest businessUnitManagement
                 */

                /**
                 * Constructs a new DeleteBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitManagementRequest.
                 * @implements IDeleteBusinessUnitManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitManagementRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitManagementRequest businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitManagementRequest
                 * @instance
                 */
                DeleteBusinessUnitManagementRequest.prototype.businessUnitManagement = null;

                /**
                 * Verifies a DeleteBusinessUnitManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitManagementRequest} DeleteBusinessUnitManagementRequest
                 */
                DeleteBusinessUnitManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitManagementRequest();
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitManagementRequest.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitManagementRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitManagementRequest} message DeleteBusinessUnitManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagement = null;
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitManagementRequest;
            })();

            company.ImportOldBusinessUnitManagementsRequest = (function() {

                /**
                 * Properties of an ImportOldBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportOldBusinessUnitManagementsRequest
                 * @property {string|null} [url] ImportOldBusinessUnitManagementsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportOldBusinessUnitManagementsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportOldBusinessUnitManagementsRequest dryRun
                 * @property {boolean|null} [exportError] ImportOldBusinessUnitManagementsRequest exportError
                 */

                /**
                 * Constructs a new ImportOldBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportOldBusinessUnitManagementsRequest.
                 * @implements IImportOldBusinessUnitManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportOldBusinessUnitManagementsRequest=} [properties] Properties to set
                 */
                function ImportOldBusinessUnitManagementsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportOldBusinessUnitManagementsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportOldBusinessUnitManagementsRequest.prototype.url = "";

                /**
                 * ImportOldBusinessUnitManagementsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportOldBusinessUnitManagementsRequest.prototype.fileFormat = null;

                /**
                 * ImportOldBusinessUnitManagementsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportOldBusinessUnitManagementsRequest.prototype.dryRun = false;

                /**
                 * ImportOldBusinessUnitManagementsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportOldBusinessUnitManagementsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportOldBusinessUnitManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportOldBusinessUnitManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportOldBusinessUnitManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest} ImportOldBusinessUnitManagementsRequest
                 */
                ImportOldBusinessUnitManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportOldBusinessUnitManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest} message ImportOldBusinessUnitManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportOldBusinessUnitManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportOldBusinessUnitManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportOldBusinessUnitManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportOldBusinessUnitManagementsRequest;
            })();

            company.ImportOldBusinessUnitManagementsResponse = (function() {

                /**
                 * Properties of an ImportOldBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportOldBusinessUnitManagementsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportOldBusinessUnitManagementsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportOldBusinessUnitManagementsResponse errors
                 * @property {string|null} [assetId] ImportOldBusinessUnitManagementsResponse assetId
                 */

                /**
                 * Constructs a new ImportOldBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportOldBusinessUnitManagementsResponse.
                 * @implements IImportOldBusinessUnitManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportOldBusinessUnitManagementsResponse=} [properties] Properties to set
                 */
                function ImportOldBusinessUnitManagementsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportOldBusinessUnitManagementsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportOldBusinessUnitManagementsResponse.prototype.summary = null;

                /**
                 * ImportOldBusinessUnitManagementsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportOldBusinessUnitManagementsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportOldBusinessUnitManagementsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportOldBusinessUnitManagementsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportOldBusinessUnitManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportOldBusinessUnitManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportOldBusinessUnitManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse} ImportOldBusinessUnitManagementsResponse
                 */
                ImportOldBusinessUnitManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportOldBusinessUnitManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse} message ImportOldBusinessUnitManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportOldBusinessUnitManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportOldBusinessUnitManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportOldBusinessUnitManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportOldBusinessUnitManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportOldBusinessUnitManagementsResponse;
            })();

            company.ListBusinessUnitManagementContentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementContentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitManagementContentsRequest pageToken
                 * @property {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.IDataFilter|null} [dataFilter] ListBusinessUnitManagementContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementContentsRequest.
                 * @implements IListBusinessUnitManagementContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementContentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @instance
                 */
                ListBusinessUnitManagementContentsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitManagementContentsRequest dataFilter.
                 * @member {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @instance
                 */
                ListBusinessUnitManagementContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListBusinessUnitManagementContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest} ListBusinessUnitManagementContentsRequest
                 */
                ListBusinessUnitManagementContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest} message ListBusinessUnitManagementContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListBusinessUnitManagementContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [businessUnitManagementIds] DataFilter businessUnitManagementIds
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.businessUnitManagementIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter businessUnitManagementIds.
                     * @member {Array.<string>} businessUnitManagementIds
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.businessUnitManagementIds = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                            if (!Array.isArray(message.businessUnitManagementIds))
                                return "businessUnitManagementIds: array expected";
                            for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                                if (!$util.isString(message.businessUnitManagementIds[i]))
                                    return "businessUnitManagementIds: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter();
                        if (object.businessUnitManagementIds) {
                            if (!Array.isArray(object.businessUnitManagementIds))
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter.businessUnitManagementIds: array expected");
                            message.businessUnitManagementIds = [];
                            for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                                message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.businessUnitManagementIds = [];
                        if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                            object.businessUnitManagementIds = [];
                            for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                                object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListBusinessUnitManagementContentsRequest;
            })();

            company.ListBusinessUnitManagementContentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementContentsResponse
                 * @property {Long|null} [total] ListBusinessUnitManagementContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitManagementContent>|null} [items] ListBusinessUnitManagementContentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitManagementContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementContentsResponse.
                 * @implements IListBusinessUnitManagementContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementContentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @instance
                 */
                ListBusinessUnitManagementContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitManagementContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitManagementContent>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @instance
                 */
                ListBusinessUnitManagementContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitManagementContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @instance
                 */
                ListBusinessUnitManagementContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitManagementContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitManagementContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementContentsResponse} ListBusinessUnitManagementContentsResponse
                 */
                ListBusinessUnitManagementContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitManagementContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementContentsResponse} message ListBusinessUnitManagementContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitManagementContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementContentsResponse;
            })();

            company.GetBusinessUnitManagementContentRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitManagementContentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitManagementContentRequest
                 * @property {string|null} [businessUnitManagementId] GetBusinessUnitManagementContentRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new GetBusinessUnitManagementContentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitManagementContentRequest.
                 * @implements IGetBusinessUnitManagementContentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitManagementContentRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitManagementContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitManagementContentRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementContentRequest
                 * @instance
                 */
                GetBusinessUnitManagementContentRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a GetBusinessUnitManagementContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitManagementContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitManagementContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitManagementContentRequest} GetBusinessUnitManagementContentRequest
                 */
                GetBusinessUnitManagementContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitManagementContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitManagementContentRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitManagementContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementContentRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitManagementContentRequest} message GetBusinessUnitManagementContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitManagementContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagementId = "";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitManagementContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitManagementContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitManagementContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitManagementContentRequest;
            })();

            company.ExportOldBusinessUnitManagementsRequest = (function() {

                /**
                 * Properties of an ExportOldBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportOldBusinessUnitManagementsRequest
                 * @property {Array.<string>|null} [ids] ExportOldBusinessUnitManagementsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportOldBusinessUnitManagementsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportOldBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportOldBusinessUnitManagementsRequest.
                 * @implements IExportOldBusinessUnitManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportOldBusinessUnitManagementsRequest=} [properties] Properties to set
                 */
                function ExportOldBusinessUnitManagementsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOldBusinessUnitManagementsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ExportOldBusinessUnitManagementsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportOldBusinessUnitManagementsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @instance
                 */
                ExportOldBusinessUnitManagementsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportOldBusinessUnitManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOldBusinessUnitManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportOldBusinessUnitManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest} ExportOldBusinessUnitManagementsRequest
                 */
                ExportOldBusinessUnitManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOldBusinessUnitManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest} message ExportOldBusinessUnitManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOldBusinessUnitManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportOldBusinessUnitManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOldBusinessUnitManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOldBusinessUnitManagementsRequest;
            })();

            company.ExportOldBusinessUnitManagementsResponse = (function() {

                /**
                 * Properties of an ExportOldBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportOldBusinessUnitManagementsResponse
                 * @property {string|null} [assetId] ExportOldBusinessUnitManagementsResponse assetId
                 */

                /**
                 * Constructs a new ExportOldBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportOldBusinessUnitManagementsResponse.
                 * @implements IExportOldBusinessUnitManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportOldBusinessUnitManagementsResponse=} [properties] Properties to set
                 */
                function ExportOldBusinessUnitManagementsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOldBusinessUnitManagementsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse
                 * @instance
                 */
                ExportOldBusinessUnitManagementsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportOldBusinessUnitManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOldBusinessUnitManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportOldBusinessUnitManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse} ExportOldBusinessUnitManagementsResponse
                 */
                ExportOldBusinessUnitManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOldBusinessUnitManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse} message ExportOldBusinessUnitManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOldBusinessUnitManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportOldBusinessUnitManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportOldBusinessUnitManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOldBusinessUnitManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOldBusinessUnitManagementsResponse;
            })();

            company.ListHistorysRequest = (function() {

                /**
                 * Properties of a ListHistorysRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListHistorysRequest
                 * @property {string|null} [pageToken] ListHistorysRequest pageToken
                 * @property {string|null} [typeName] ListHistorysRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListHistorysRequest recordIds
                 */

                /**
                 * Constructs a new ListHistorysRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListHistorysRequest.
                 * @implements IListHistorysRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListHistorysRequest=} [properties] Properties to set
                 */
                function ListHistorysRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.pageToken = "";

                /**
                 * ListHistorysRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.typeName = "";

                /**
                 * ListHistorysRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListHistorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListHistorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListHistorysRequest} ListHistorysRequest
                 */
                ListHistorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListHistorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListHistorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.company.ListHistorysRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @static
                 * @param {mtechnavi.api.company.ListHistorysRequest} message ListHistorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListHistorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListHistorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysRequest;
            })();

            company.ListHistorysResponse = (function() {

                /**
                 * Properties of a ListHistorysResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListHistorysResponse
                 * @property {Long|null} [total] ListHistorysResponse total
                 * @property {Array.<mtechnavi.api.company.IHistory>|null} [items] ListHistorysResponse items
                 * @property {string|null} [pageToken] ListHistorysResponse pageToken
                 */

                /**
                 * Constructs a new ListHistorysResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListHistorysResponse.
                 * @implements IListHistorysResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListHistorysResponse=} [properties] Properties to set
                 */
                function ListHistorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListHistorysResponse items.
                 * @member {Array.<mtechnavi.api.company.IHistory>} items
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListHistorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListHistorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.History.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListHistorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListHistorysResponse} ListHistorysResponse
                 */
                ListHistorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListHistorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListHistorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListHistorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListHistorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.History.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @static
                 * @param {mtechnavi.api.company.ListHistorysResponse} message ListHistorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.History.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListHistorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListHistorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysResponse;
            })();

            company.SendEmailTenantInvitationRequest = (function() {

                /**
                 * Properties of a SendEmailTenantInvitationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISendEmailTenantInvitationRequest
                 * @property {string|null} [businessUnitManagementId] SendEmailTenantInvitationRequest businessUnitManagementId
                 * @property {string|null} [individualMessage] SendEmailTenantInvitationRequest individualMessage
                 * @property {string|null} [contactInformationMessage] SendEmailTenantInvitationRequest contactInformationMessage
                 */

                /**
                 * Constructs a new SendEmailTenantInvitationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SendEmailTenantInvitationRequest.
                 * @implements ISendEmailTenantInvitationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISendEmailTenantInvitationRequest=} [properties] Properties to set
                 */
                function SendEmailTenantInvitationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendEmailTenantInvitationRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.businessUnitManagementId = "";

                /**
                 * SendEmailTenantInvitationRequest individualMessage.
                 * @member {string} individualMessage
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.individualMessage = "";

                /**
                 * SendEmailTenantInvitationRequest contactInformationMessage.
                 * @member {string} contactInformationMessage
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @instance
                 */
                SendEmailTenantInvitationRequest.prototype.contactInformationMessage = "";

                /**
                 * Verifies a SendEmailTenantInvitationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailTenantInvitationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        if (!$util.isString(message.individualMessage))
                            return "individualMessage: string expected";
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        if (!$util.isString(message.contactInformationMessage))
                            return "contactInformationMessage: string expected";
                    return null;
                };

                /**
                 * Creates a SendEmailTenantInvitationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SendEmailTenantInvitationRequest} SendEmailTenantInvitationRequest
                 */
                SendEmailTenantInvitationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SendEmailTenantInvitationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SendEmailTenantInvitationRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.individualMessage != null)
                        message.individualMessage = String(object.individualMessage);
                    if (object.contactInformationMessage != null)
                        message.contactInformationMessage = String(object.contactInformationMessage);
                    return message;
                };

                /**
                 * Creates a plain object from a SendEmailTenantInvitationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @static
                 * @param {mtechnavi.api.company.SendEmailTenantInvitationRequest} message SendEmailTenantInvitationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailTenantInvitationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitManagementId = "";
                        object.individualMessage = "";
                        object.contactInformationMessage = "";
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        object.individualMessage = message.individualMessage;
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        object.contactInformationMessage = message.contactInformationMessage;
                    return object;
                };

                /**
                 * Converts this SendEmailTenantInvitationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailTenantInvitationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailTenantInvitationRequest;
            })();

            company.SendEmailTenantInvitationResponse = (function() {

                /**
                 * Properties of a SendEmailTenantInvitationResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISendEmailTenantInvitationResponse
                 */

                /**
                 * Constructs a new SendEmailTenantInvitationResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SendEmailTenantInvitationResponse.
                 * @implements ISendEmailTenantInvitationResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISendEmailTenantInvitationResponse=} [properties] Properties to set
                 */
                function SendEmailTenantInvitationResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SendEmailTenantInvitationResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailTenantInvitationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SendEmailTenantInvitationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SendEmailTenantInvitationResponse} SendEmailTenantInvitationResponse
                 */
                SendEmailTenantInvitationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SendEmailTenantInvitationResponse)
                        return object;
                    return new $root.mtechnavi.api.company.SendEmailTenantInvitationResponse();
                };

                /**
                 * Creates a plain object from a SendEmailTenantInvitationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationResponse
                 * @static
                 * @param {mtechnavi.api.company.SendEmailTenantInvitationResponse} message SendEmailTenantInvitationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailTenantInvitationResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SendEmailTenantInvitationResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SendEmailTenantInvitationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailTenantInvitationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailTenantInvitationResponse;
            })();

            company.ListBusinessUnitProfilesRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitProfilesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitProfilesRequest
                 * @property {string|null} [pageToken] ListBusinessUnitProfilesRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitProfilesRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitProfilesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitProfilesRequest.
                 * @implements IListBusinessUnitProfilesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitProfilesRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitProfilesRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitProfilesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @instance
                 */
                ListBusinessUnitProfilesRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitProfilesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @instance
                 */
                ListBusinessUnitProfilesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitProfilesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitProfilesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitProfilesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitProfilesRequest} ListBusinessUnitProfilesRequest
                 */
                ListBusinessUnitProfilesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitProfilesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitProfilesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitProfilesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitProfilesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitProfilesRequest} message ListBusinessUnitProfilesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitProfilesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitProfilesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitProfilesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitProfilesRequest;
            })();

            company.ListBusinessUnitProfilesResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitProfilesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitProfilesResponse
                 * @property {Long|null} [total] ListBusinessUnitProfilesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitProfile>|null} [items] ListBusinessUnitProfilesResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitProfilesResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitProfilesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitProfilesResponse.
                 * @implements IListBusinessUnitProfilesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitProfilesResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitProfilesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitProfilesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @instance
                 */
                ListBusinessUnitProfilesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitProfilesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitProfile>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @instance
                 */
                ListBusinessUnitProfilesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitProfilesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @instance
                 */
                ListBusinessUnitProfilesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitProfilesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitProfilesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitProfilesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitProfilesResponse} ListBusinessUnitProfilesResponse
                 */
                ListBusinessUnitProfilesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitProfilesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitProfilesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitProfilesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitProfilesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitProfilesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitProfilesResponse} message ListBusinessUnitProfilesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitProfilesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitProfilesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitProfilesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitProfilesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitProfilesResponse;
            })();

            company.CreateBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitProfileRequest
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [businessUnitProfile] CreateBusinessUnitProfileRequest businessUnitProfile
                 */

                /**
                 * Constructs a new CreateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitProfileRequest.
                 * @implements ICreateBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitProfileRequest businessUnitProfile.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} businessUnitProfile
                 * @memberof mtechnavi.api.company.CreateBusinessUnitProfileRequest
                 * @instance
                 */
                CreateBusinessUnitProfileRequest.prototype.businessUnitProfile = null;

                /**
                 * Verifies a CreateBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.businessUnitProfile);
                        if (error)
                            return "businessUnitProfile." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitProfileRequest} CreateBusinessUnitProfileRequest
                 */
                CreateBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitProfileRequest();
                    if (object.businessUnitProfile != null) {
                        if (typeof object.businessUnitProfile !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitProfileRequest.businessUnitProfile: object expected");
                        message.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.businessUnitProfile);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitProfileRequest} message CreateBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitProfile = null;
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile"))
                        object.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.businessUnitProfile, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitProfileRequest;
            })();

            company.GetBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitProfileRequest
                 * @property {string|null} [businessUnitProfileId] GetBusinessUnitProfileRequest businessUnitProfileId
                 */

                /**
                 * Constructs a new GetBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitProfileRequest.
                 * @implements IGetBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitProfileRequest businessUnitProfileId.
                 * @member {string} businessUnitProfileId
                 * @memberof mtechnavi.api.company.GetBusinessUnitProfileRequest
                 * @instance
                 */
                GetBusinessUnitProfileRequest.prototype.businessUnitProfileId = "";

                /**
                 * Verifies a GetBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        if (!$util.isString(message.businessUnitProfileId))
                            return "businessUnitProfileId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitProfileRequest} GetBusinessUnitProfileRequest
                 */
                GetBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitProfileRequest();
                    if (object.businessUnitProfileId != null)
                        message.businessUnitProfileId = String(object.businessUnitProfileId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitProfileRequest} message GetBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitProfileId = "";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        object.businessUnitProfileId = message.businessUnitProfileId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitProfileRequest;
            })();

            company.UpdateBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitProfileRequest
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [businessUnitProfile] UpdateBusinessUnitProfileRequest businessUnitProfile
                 */

                /**
                 * Constructs a new UpdateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitProfileRequest.
                 * @implements IUpdateBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitProfileRequest businessUnitProfile.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} businessUnitProfile
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitProfileRequest
                 * @instance
                 */
                UpdateBusinessUnitProfileRequest.prototype.businessUnitProfile = null;

                /**
                 * Verifies an UpdateBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.businessUnitProfile);
                        if (error)
                            return "businessUnitProfile." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitProfileRequest} UpdateBusinessUnitProfileRequest
                 */
                UpdateBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitProfileRequest();
                    if (object.businessUnitProfile != null) {
                        if (typeof object.businessUnitProfile !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitProfileRequest.businessUnitProfile: object expected");
                        message.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.businessUnitProfile);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitProfileRequest} message UpdateBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitProfile = null;
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile"))
                        object.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.businessUnitProfile, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitProfileRequest;
            })();

            company.DeleteBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitProfileRequest
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [businessUnitProfile] DeleteBusinessUnitProfileRequest businessUnitProfile
                 */

                /**
                 * Constructs a new DeleteBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitProfileRequest.
                 * @implements IDeleteBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitProfileRequest businessUnitProfile.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} businessUnitProfile
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitProfileRequest
                 * @instance
                 */
                DeleteBusinessUnitProfileRequest.prototype.businessUnitProfile = null;

                /**
                 * Verifies a DeleteBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.businessUnitProfile);
                        if (error)
                            return "businessUnitProfile." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitProfileRequest} DeleteBusinessUnitProfileRequest
                 */
                DeleteBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitProfileRequest();
                    if (object.businessUnitProfile != null) {
                        if (typeof object.businessUnitProfile !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitProfileRequest.businessUnitProfile: object expected");
                        message.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.businessUnitProfile);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitProfileRequest} message DeleteBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitProfile = null;
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile"))
                        object.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.businessUnitProfile, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitProfileRequest;
            })();

            company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of an UpdateSystemNotificationUsersInBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @property {string|null} [businessUnitProfileId] UpdateSystemNotificationUsersInBusinessUnitProfileRequest businessUnitProfileId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] UpdateSystemNotificationUsersInBusinessUnitProfileRequest systemNotificationUsers
                 * @property {Long|null} [updatedAt] UpdateSystemNotificationUsersInBusinessUnitProfileRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateSystemNotificationUsersInBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateSystemNotificationUsersInBusinessUnitProfileRequest.
                 * @implements IUpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateSystemNotificationUsersInBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function UpdateSystemNotificationUsersInBusinessUnitProfileRequest(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSystemNotificationUsersInBusinessUnitProfileRequest businessUnitProfileId.
                 * @member {string} businessUnitProfileId
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.prototype.businessUnitProfileId = "";

                /**
                 * UpdateSystemNotificationUsersInBusinessUnitProfileRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * UpdateSystemNotificationUsersInBusinessUnitProfileRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateSystemNotificationUsersInBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        if (!$util.isString(message.businessUnitProfileId))
                            return "businessUnitProfileId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateSystemNotificationUsersInBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest} UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest();
                    if (object.businessUnitProfileId != null)
                        message.businessUnitProfileId = String(object.businessUnitProfileId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSystemNotificationUsersInBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest} message UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.businessUnitProfileId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        object.businessUnitProfileId = message.businessUnitProfileId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateSystemNotificationUsersInBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateSystemNotificationUsersInBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSystemNotificationUsersInBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSystemNotificationUsersInBusinessUnitProfileRequest;
            })();

            company.ListBusinessUnitFinancialsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFinancialsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitFinancialsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitFinancialsRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFinancialsRequest.
                 * @implements IListBusinessUnitFinancialsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitFinancialsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFinancialsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @instance
                 */
                ListBusinessUnitFinancialsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitFinancialsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @instance
                 */
                ListBusinessUnitFinancialsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitFinancialsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFinancialsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFinancialsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFinancialsRequest} ListBusinessUnitFinancialsRequest
                 */
                ListBusinessUnitFinancialsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFinancialsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFinancialsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFinancialsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFinancialsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialsRequest} message ListBusinessUnitFinancialsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFinancialsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFinancialsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFinancialsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFinancialsRequest;
            })();

            company.ListBusinessUnitFinancialsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFinancialsResponse
                 * @property {Long|null} [total] ListBusinessUnitFinancialsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFinancial>|null} [items] ListBusinessUnitFinancialsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitFinancialsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFinancialsResponse.
                 * @implements IListBusinessUnitFinancialsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitFinancialsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFinancialsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListBusinessUnitFinancialsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitFinancialsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFinancial>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListBusinessUnitFinancialsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitFinancialsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListBusinessUnitFinancialsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitFinancialsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFinancialsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFinancialsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFinancialsResponse} ListBusinessUnitFinancialsResponse
                 */
                ListBusinessUnitFinancialsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFinancialsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFinancialsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFinancialsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitFinancialsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFinancialsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialsResponse} message ListBusinessUnitFinancialsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFinancialsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFinancialsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFinancialsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFinancialsResponse;
            })();

            company.CreateBusinessUnitFinancialRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitFinancialRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFinancial|null} [businessUnitFinancial] CreateBusinessUnitFinancialRequest businessUnitFinancial
                 */

                /**
                 * Constructs a new CreateBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitFinancialRequest.
                 * @implements ICreateBusinessUnitFinancialRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFinancialRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitFinancialRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitFinancialRequest businessUnitFinancial.
                 * @member {mtechnavi.api.company.IBusinessUnitFinancial|null|undefined} businessUnitFinancial
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFinancialRequest
                 * @instance
                 */
                CreateBusinessUnitFinancialRequest.prototype.businessUnitFinancial = null;

                /**
                 * Verifies a CreateBusinessUnitFinancialRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitFinancialRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.businessUnitFinancial);
                        if (error)
                            return "businessUnitFinancial." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitFinancialRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitFinancialRequest} CreateBusinessUnitFinancialRequest
                 */
                CreateBusinessUnitFinancialRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitFinancialRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitFinancialRequest();
                    if (object.businessUnitFinancial != null) {
                        if (typeof object.businessUnitFinancial !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitFinancialRequest.businessUnitFinancial: object expected");
                        message.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.businessUnitFinancial);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitFinancialRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFinancialRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitFinancialRequest} message CreateBusinessUnitFinancialRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitFinancialRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFinancial = null;
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial"))
                        object.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.businessUnitFinancial, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitFinancialRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFinancialRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitFinancialRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitFinancialRequest;
            })();

            company.GetBusinessUnitFinancialRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitFinancialRequest
                 * @property {string|null} [businessUnitFinancialId] GetBusinessUnitFinancialRequest businessUnitFinancialId
                 */

                /**
                 * Constructs a new GetBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitFinancialRequest.
                 * @implements IGetBusinessUnitFinancialRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitFinancialRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitFinancialRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitFinancialRequest businessUnitFinancialId.
                 * @member {string} businessUnitFinancialId
                 * @memberof mtechnavi.api.company.GetBusinessUnitFinancialRequest
                 * @instance
                 */
                GetBusinessUnitFinancialRequest.prototype.businessUnitFinancialId = "";

                /**
                 * Verifies a GetBusinessUnitFinancialRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitFinancialRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        if (!$util.isString(message.businessUnitFinancialId))
                            return "businessUnitFinancialId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitFinancialRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitFinancialRequest} GetBusinessUnitFinancialRequest
                 */
                GetBusinessUnitFinancialRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitFinancialRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitFinancialRequest();
                    if (object.businessUnitFinancialId != null)
                        message.businessUnitFinancialId = String(object.businessUnitFinancialId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitFinancialRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitFinancialRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitFinancialRequest} message GetBusinessUnitFinancialRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitFinancialRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFinancialId = "";
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        object.businessUnitFinancialId = message.businessUnitFinancialId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitFinancialRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitFinancialRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitFinancialRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitFinancialRequest;
            })();

            company.UpdateBusinessUnitFinancialRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitFinancialRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFinancial|null} [businessUnitFinancial] UpdateBusinessUnitFinancialRequest businessUnitFinancial
                 */

                /**
                 * Constructs a new UpdateBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitFinancialRequest.
                 * @implements IUpdateBusinessUnitFinancialRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFinancialRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitFinancialRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitFinancialRequest businessUnitFinancial.
                 * @member {mtechnavi.api.company.IBusinessUnitFinancial|null|undefined} businessUnitFinancial
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFinancialRequest
                 * @instance
                 */
                UpdateBusinessUnitFinancialRequest.prototype.businessUnitFinancial = null;

                /**
                 * Verifies an UpdateBusinessUnitFinancialRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitFinancialRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.businessUnitFinancial);
                        if (error)
                            return "businessUnitFinancial." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitFinancialRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitFinancialRequest} UpdateBusinessUnitFinancialRequest
                 */
                UpdateBusinessUnitFinancialRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitFinancialRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitFinancialRequest();
                    if (object.businessUnitFinancial != null) {
                        if (typeof object.businessUnitFinancial !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitFinancialRequest.businessUnitFinancial: object expected");
                        message.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.businessUnitFinancial);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitFinancialRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFinancialRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitFinancialRequest} message UpdateBusinessUnitFinancialRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitFinancialRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFinancial = null;
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial"))
                        object.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.businessUnitFinancial, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitFinancialRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFinancialRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitFinancialRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitFinancialRequest;
            })();

            company.DeleteBusinessUnitFinancialRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitFinancialRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFinancial|null} [businessUnitFinancial] DeleteBusinessUnitFinancialRequest businessUnitFinancial
                 */

                /**
                 * Constructs a new DeleteBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitFinancialRequest.
                 * @implements IDeleteBusinessUnitFinancialRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFinancialRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitFinancialRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitFinancialRequest businessUnitFinancial.
                 * @member {mtechnavi.api.company.IBusinessUnitFinancial|null|undefined} businessUnitFinancial
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFinancialRequest
                 * @instance
                 */
                DeleteBusinessUnitFinancialRequest.prototype.businessUnitFinancial = null;

                /**
                 * Verifies a DeleteBusinessUnitFinancialRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitFinancialRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.businessUnitFinancial);
                        if (error)
                            return "businessUnitFinancial." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitFinancialRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitFinancialRequest} DeleteBusinessUnitFinancialRequest
                 */
                DeleteBusinessUnitFinancialRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitFinancialRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitFinancialRequest();
                    if (object.businessUnitFinancial != null) {
                        if (typeof object.businessUnitFinancial !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitFinancialRequest.businessUnitFinancial: object expected");
                        message.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.businessUnitFinancial);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitFinancialRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFinancialRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitFinancialRequest} message DeleteBusinessUnitFinancialRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitFinancialRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFinancial = null;
                    if (message.businessUnitFinancial != null && message.hasOwnProperty("businessUnitFinancial"))
                        object.businessUnitFinancial = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.businessUnitFinancial, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitFinancialRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFinancialRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitFinancialRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitFinancialRequest;
            })();

            company.ListBusinessUnitBranchsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitBranchsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitBranchsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitBranchsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitBranchsRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitBranchsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitBranchsRequest.
                 * @implements IListBusinessUnitBranchsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitBranchsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitBranchsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitBranchsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @instance
                 */
                ListBusinessUnitBranchsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitBranchsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @instance
                 */
                ListBusinessUnitBranchsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitBranchsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitBranchsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitBranchsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitBranchsRequest} ListBusinessUnitBranchsRequest
                 */
                ListBusinessUnitBranchsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitBranchsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitBranchsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitBranchsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitBranchsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitBranchsRequest} message ListBusinessUnitBranchsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitBranchsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitBranchsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitBranchsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitBranchsRequest;
            })();

            company.ListBusinessUnitBranchsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitBranchsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitBranchsResponse
                 * @property {Long|null} [total] ListBusinessUnitBranchsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitBranch>|null} [items] ListBusinessUnitBranchsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitBranchsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitBranchsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitBranchsResponse.
                 * @implements IListBusinessUnitBranchsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitBranchsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitBranchsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitBranchsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @instance
                 */
                ListBusinessUnitBranchsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitBranchsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitBranch>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @instance
                 */
                ListBusinessUnitBranchsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitBranchsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @instance
                 */
                ListBusinessUnitBranchsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitBranchsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitBranchsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitBranch.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitBranchsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitBranchsResponse} ListBusinessUnitBranchsResponse
                 */
                ListBusinessUnitBranchsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitBranchsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitBranchsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitBranchsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitBranchsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitBranch.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitBranchsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitBranchsResponse} message ListBusinessUnitBranchsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitBranchsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitBranch.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitBranchsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitBranchsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitBranchsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitBranchsResponse;
            })();

            company.CreateBusinessUnitBranchRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitBranchRequest
                 * @property {mtechnavi.api.company.IBusinessUnitBranch|null} [businessUnitBranch] CreateBusinessUnitBranchRequest businessUnitBranch
                 */

                /**
                 * Constructs a new CreateBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitBranchRequest.
                 * @implements ICreateBusinessUnitBranchRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitBranchRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitBranchRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitBranchRequest businessUnitBranch.
                 * @member {mtechnavi.api.company.IBusinessUnitBranch|null|undefined} businessUnitBranch
                 * @memberof mtechnavi.api.company.CreateBusinessUnitBranchRequest
                 * @instance
                 */
                CreateBusinessUnitBranchRequest.prototype.businessUnitBranch = null;

                /**
                 * Verifies a CreateBusinessUnitBranchRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitBranchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitBranch.verify(message.businessUnitBranch);
                        if (error)
                            return "businessUnitBranch." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitBranchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitBranchRequest} CreateBusinessUnitBranchRequest
                 */
                CreateBusinessUnitBranchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitBranchRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitBranchRequest();
                    if (object.businessUnitBranch != null) {
                        if (typeof object.businessUnitBranch !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitBranchRequest.businessUnitBranch: object expected");
                        message.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.fromObject(object.businessUnitBranch);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitBranchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitBranchRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitBranchRequest} message CreateBusinessUnitBranchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitBranchRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitBranch = null;
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch"))
                        object.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.toObject(message.businessUnitBranch, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitBranchRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitBranchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitBranchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitBranchRequest;
            })();

            company.GetBusinessUnitBranchRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitBranchRequest
                 * @property {string|null} [businessUnitBranchId] GetBusinessUnitBranchRequest businessUnitBranchId
                 */

                /**
                 * Constructs a new GetBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitBranchRequest.
                 * @implements IGetBusinessUnitBranchRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitBranchRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitBranchRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitBranchRequest businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.company.GetBusinessUnitBranchRequest
                 * @instance
                 */
                GetBusinessUnitBranchRequest.prototype.businessUnitBranchId = "";

                /**
                 * Verifies a GetBusinessUnitBranchRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitBranchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitBranchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitBranchRequest} GetBusinessUnitBranchRequest
                 */
                GetBusinessUnitBranchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitBranchRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitBranchRequest();
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitBranchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitBranchRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitBranchRequest} message GetBusinessUnitBranchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitBranchRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitBranchId = "";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitBranchRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitBranchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitBranchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitBranchRequest;
            })();

            company.UpdateBusinessUnitBranchRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitBranchRequest
                 * @property {mtechnavi.api.company.IBusinessUnitBranch|null} [businessUnitBranch] UpdateBusinessUnitBranchRequest businessUnitBranch
                 */

                /**
                 * Constructs a new UpdateBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitBranchRequest.
                 * @implements IUpdateBusinessUnitBranchRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitBranchRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitBranchRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitBranchRequest businessUnitBranch.
                 * @member {mtechnavi.api.company.IBusinessUnitBranch|null|undefined} businessUnitBranch
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitBranchRequest
                 * @instance
                 */
                UpdateBusinessUnitBranchRequest.prototype.businessUnitBranch = null;

                /**
                 * Verifies an UpdateBusinessUnitBranchRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitBranchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitBranch.verify(message.businessUnitBranch);
                        if (error)
                            return "businessUnitBranch." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitBranchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitBranchRequest} UpdateBusinessUnitBranchRequest
                 */
                UpdateBusinessUnitBranchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitBranchRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitBranchRequest();
                    if (object.businessUnitBranch != null) {
                        if (typeof object.businessUnitBranch !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitBranchRequest.businessUnitBranch: object expected");
                        message.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.fromObject(object.businessUnitBranch);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitBranchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitBranchRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitBranchRequest} message UpdateBusinessUnitBranchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitBranchRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitBranch = null;
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch"))
                        object.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.toObject(message.businessUnitBranch, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitBranchRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitBranchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitBranchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitBranchRequest;
            })();

            company.DeleteBusinessUnitBranchRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitBranchRequest
                 * @property {mtechnavi.api.company.IBusinessUnitBranch|null} [businessUnitBranch] DeleteBusinessUnitBranchRequest businessUnitBranch
                 */

                /**
                 * Constructs a new DeleteBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitBranchRequest.
                 * @implements IDeleteBusinessUnitBranchRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitBranchRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitBranchRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitBranchRequest businessUnitBranch.
                 * @member {mtechnavi.api.company.IBusinessUnitBranch|null|undefined} businessUnitBranch
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitBranchRequest
                 * @instance
                 */
                DeleteBusinessUnitBranchRequest.prototype.businessUnitBranch = null;

                /**
                 * Verifies a DeleteBusinessUnitBranchRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitBranchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitBranch.verify(message.businessUnitBranch);
                        if (error)
                            return "businessUnitBranch." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitBranchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitBranchRequest} DeleteBusinessUnitBranchRequest
                 */
                DeleteBusinessUnitBranchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitBranchRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitBranchRequest();
                    if (object.businessUnitBranch != null) {
                        if (typeof object.businessUnitBranch !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitBranchRequest.businessUnitBranch: object expected");
                        message.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.fromObject(object.businessUnitBranch);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitBranchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitBranchRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitBranchRequest} message DeleteBusinessUnitBranchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitBranchRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitBranch = null;
                    if (message.businessUnitBranch != null && message.hasOwnProperty("businessUnitBranch"))
                        object.businessUnitBranch = $root.mtechnavi.api.company.BusinessUnitBranch.toObject(message.businessUnitBranch, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitBranchRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitBranchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitBranchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitBranchRequest;
            })();

            company.ListBusinessUnitFacilitiessRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFacilitiessRequest
                 * @property {string|null} [pageToken] ListBusinessUnitFacilitiessRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitFacilitiessRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFacilitiessRequest.
                 * @implements IListBusinessUnitFacilitiessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiessRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitFacilitiessRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFacilitiessRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ListBusinessUnitFacilitiessRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitFacilitiessRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ListBusinessUnitFacilitiessRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitFacilitiessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFacilitiessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFacilitiessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFacilitiessRequest} ListBusinessUnitFacilitiessRequest
                 */
                ListBusinessUnitFacilitiessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFacilitiessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFacilitiessRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFacilitiessRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFacilitiessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiessRequest} message ListBusinessUnitFacilitiessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFacilitiessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFacilitiessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFacilitiessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFacilitiessRequest;
            })();

            company.ListBusinessUnitFacilitiessResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFacilitiessResponse
                 * @property {Long|null} [total] ListBusinessUnitFacilitiessResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFacilities>|null} [items] ListBusinessUnitFacilitiessResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitFacilitiessResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFacilitiessResponse.
                 * @implements IListBusinessUnitFacilitiessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiessResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitFacilitiessResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFacilitiessResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListBusinessUnitFacilitiessResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitFacilitiessResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFacilities>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListBusinessUnitFacilitiessResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitFacilitiessResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListBusinessUnitFacilitiessResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitFacilitiessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFacilitiessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFacilitiessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFacilitiessResponse} ListBusinessUnitFacilitiessResponse
                 */
                ListBusinessUnitFacilitiessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFacilitiessResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFacilitiessResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFacilitiessResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitFacilitiessResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFacilitiessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiessResponse} message ListBusinessUnitFacilitiessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFacilitiessResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFacilitiessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFacilitiessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFacilitiessResponse;
            })();

            company.CreateBusinessUnitFacilitiesRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitFacilitiesRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFacilities|null} [businessUnitFacilities] CreateBusinessUnitFacilitiesRequest businessUnitFacilities
                 */

                /**
                 * Constructs a new CreateBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitFacilitiesRequest.
                 * @implements ICreateBusinessUnitFacilitiesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitFacilitiesRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitFacilitiesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitFacilitiesRequest businessUnitFacilities.
                 * @member {mtechnavi.api.company.IBusinessUnitFacilities|null|undefined} businessUnitFacilities
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest
                 * @instance
                 */
                CreateBusinessUnitFacilitiesRequest.prototype.businessUnitFacilities = null;

                /**
                 * Verifies a CreateBusinessUnitFacilitiesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitFacilitiesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.businessUnitFacilities);
                        if (error)
                            return "businessUnitFacilities." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitFacilitiesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest} CreateBusinessUnitFacilitiesRequest
                 */
                CreateBusinessUnitFacilitiesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest();
                    if (object.businessUnitFacilities != null) {
                        if (typeof object.businessUnitFacilities !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest.businessUnitFacilities: object expected");
                        message.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.businessUnitFacilities);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitFacilitiesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest} message CreateBusinessUnitFacilitiesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitFacilitiesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFacilities = null;
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities"))
                        object.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.businessUnitFacilities, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitFacilitiesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitFacilitiesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitFacilitiesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitFacilitiesRequest;
            })();

            company.GetBusinessUnitFacilitiesRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitFacilitiesRequest
                 * @property {string|null} [businessUnitFacilitiesId] GetBusinessUnitFacilitiesRequest businessUnitFacilitiesId
                 */

                /**
                 * Constructs a new GetBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitFacilitiesRequest.
                 * @implements IGetBusinessUnitFacilitiesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitFacilitiesRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitFacilitiesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitFacilitiesRequest businessUnitFacilitiesId.
                 * @member {string} businessUnitFacilitiesId
                 * @memberof mtechnavi.api.company.GetBusinessUnitFacilitiesRequest
                 * @instance
                 */
                GetBusinessUnitFacilitiesRequest.prototype.businessUnitFacilitiesId = "";

                /**
                 * Verifies a GetBusinessUnitFacilitiesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitFacilitiesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        if (!$util.isString(message.businessUnitFacilitiesId))
                            return "businessUnitFacilitiesId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitFacilitiesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitFacilitiesRequest} GetBusinessUnitFacilitiesRequest
                 */
                GetBusinessUnitFacilitiesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitFacilitiesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitFacilitiesRequest();
                    if (object.businessUnitFacilitiesId != null)
                        message.businessUnitFacilitiesId = String(object.businessUnitFacilitiesId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitFacilitiesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitFacilitiesRequest} message GetBusinessUnitFacilitiesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitFacilitiesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFacilitiesId = "";
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        object.businessUnitFacilitiesId = message.businessUnitFacilitiesId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitFacilitiesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitFacilitiesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitFacilitiesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitFacilitiesRequest;
            })();

            company.UpdateBusinessUnitFacilitiesRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitFacilitiesRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFacilities|null} [businessUnitFacilities] UpdateBusinessUnitFacilitiesRequest businessUnitFacilities
                 */

                /**
                 * Constructs a new UpdateBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitFacilitiesRequest.
                 * @implements IUpdateBusinessUnitFacilitiesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitFacilitiesRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitFacilitiesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitFacilitiesRequest businessUnitFacilities.
                 * @member {mtechnavi.api.company.IBusinessUnitFacilities|null|undefined} businessUnitFacilities
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest
                 * @instance
                 */
                UpdateBusinessUnitFacilitiesRequest.prototype.businessUnitFacilities = null;

                /**
                 * Verifies an UpdateBusinessUnitFacilitiesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitFacilitiesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.businessUnitFacilities);
                        if (error)
                            return "businessUnitFacilities." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitFacilitiesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest} UpdateBusinessUnitFacilitiesRequest
                 */
                UpdateBusinessUnitFacilitiesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest();
                    if (object.businessUnitFacilities != null) {
                        if (typeof object.businessUnitFacilities !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest.businessUnitFacilities: object expected");
                        message.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.businessUnitFacilities);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitFacilitiesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest} message UpdateBusinessUnitFacilitiesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitFacilitiesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFacilities = null;
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities"))
                        object.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.businessUnitFacilities, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitFacilitiesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitFacilitiesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitFacilitiesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitFacilitiesRequest;
            })();

            company.DeleteBusinessUnitFacilitiesRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitFacilitiesRequest
                 * @property {mtechnavi.api.company.IBusinessUnitFacilities|null} [businessUnitFacilities] DeleteBusinessUnitFacilitiesRequest businessUnitFacilities
                 */

                /**
                 * Constructs a new DeleteBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitFacilitiesRequest.
                 * @implements IDeleteBusinessUnitFacilitiesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitFacilitiesRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitFacilitiesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitFacilitiesRequest businessUnitFacilities.
                 * @member {mtechnavi.api.company.IBusinessUnitFacilities|null|undefined} businessUnitFacilities
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest
                 * @instance
                 */
                DeleteBusinessUnitFacilitiesRequest.prototype.businessUnitFacilities = null;

                /**
                 * Verifies a DeleteBusinessUnitFacilitiesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitFacilitiesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.businessUnitFacilities);
                        if (error)
                            return "businessUnitFacilities." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitFacilitiesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest} DeleteBusinessUnitFacilitiesRequest
                 */
                DeleteBusinessUnitFacilitiesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest();
                    if (object.businessUnitFacilities != null) {
                        if (typeof object.businessUnitFacilities !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest.businessUnitFacilities: object expected");
                        message.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.businessUnitFacilities);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitFacilitiesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest} message DeleteBusinessUnitFacilitiesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitFacilitiesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFacilities = null;
                    if (message.businessUnitFacilities != null && message.hasOwnProperty("businessUnitFacilities"))
                        object.businessUnitFacilities = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.businessUnitFacilities, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitFacilitiesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitFacilitiesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitFacilitiesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitFacilitiesRequest;
            })();

            company.ListBusinessUnitCertificatesRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitCertificatesRequest
                 * @property {string|null} [pageToken] ListBusinessUnitCertificatesRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitCertificatesRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitCertificatesRequest.
                 * @implements IListBusinessUnitCertificatesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificatesRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitCertificatesRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitCertificatesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @instance
                 */
                ListBusinessUnitCertificatesRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitCertificatesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @instance
                 */
                ListBusinessUnitCertificatesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitCertificatesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitCertificatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitCertificatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitCertificatesRequest} ListBusinessUnitCertificatesRequest
                 */
                ListBusinessUnitCertificatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitCertificatesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitCertificatesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitCertificatesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitCertificatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificatesRequest} message ListBusinessUnitCertificatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitCertificatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitCertificatesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitCertificatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitCertificatesRequest;
            })();

            company.ListBusinessUnitCertificatesResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitCertificatesResponse
                 * @property {Long|null} [total] ListBusinessUnitCertificatesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitCertificate>|null} [items] ListBusinessUnitCertificatesResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitCertificatesResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitCertificatesResponse.
                 * @implements IListBusinessUnitCertificatesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificatesResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitCertificatesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitCertificatesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListBusinessUnitCertificatesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitCertificatesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitCertificate>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListBusinessUnitCertificatesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitCertificatesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListBusinessUnitCertificatesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitCertificatesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitCertificatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitCertificatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitCertificatesResponse} ListBusinessUnitCertificatesResponse
                 */
                ListBusinessUnitCertificatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitCertificatesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitCertificatesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitCertificatesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitCertificatesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitCertificatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificatesResponse} message ListBusinessUnitCertificatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitCertificatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitCertificatesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitCertificatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitCertificatesResponse;
            })();

            company.CreateBusinessUnitCertificateRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitCertificateRequest
                 * @property {mtechnavi.api.company.IBusinessUnitCertificate|null} [businessUnitCertificate] CreateBusinessUnitCertificateRequest businessUnitCertificate
                 */

                /**
                 * Constructs a new CreateBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitCertificateRequest.
                 * @implements ICreateBusinessUnitCertificateRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitCertificateRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitCertificateRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitCertificateRequest businessUnitCertificate.
                 * @member {mtechnavi.api.company.IBusinessUnitCertificate|null|undefined} businessUnitCertificate
                 * @memberof mtechnavi.api.company.CreateBusinessUnitCertificateRequest
                 * @instance
                 */
                CreateBusinessUnitCertificateRequest.prototype.businessUnitCertificate = null;

                /**
                 * Verifies a CreateBusinessUnitCertificateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitCertificateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.businessUnitCertificate);
                        if (error)
                            return "businessUnitCertificate." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitCertificateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitCertificateRequest} CreateBusinessUnitCertificateRequest
                 */
                CreateBusinessUnitCertificateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitCertificateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitCertificateRequest();
                    if (object.businessUnitCertificate != null) {
                        if (typeof object.businessUnitCertificate !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitCertificateRequest.businessUnitCertificate: object expected");
                        message.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.businessUnitCertificate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitCertificateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitCertificateRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitCertificateRequest} message CreateBusinessUnitCertificateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitCertificateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitCertificate = null;
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate"))
                        object.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.businessUnitCertificate, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitCertificateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitCertificateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitCertificateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitCertificateRequest;
            })();

            company.GetBusinessUnitCertificateRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitCertificateRequest
                 * @property {string|null} [businessUnitCertificateId] GetBusinessUnitCertificateRequest businessUnitCertificateId
                 */

                /**
                 * Constructs a new GetBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitCertificateRequest.
                 * @implements IGetBusinessUnitCertificateRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitCertificateRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitCertificateRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitCertificateRequest businessUnitCertificateId.
                 * @member {string} businessUnitCertificateId
                 * @memberof mtechnavi.api.company.GetBusinessUnitCertificateRequest
                 * @instance
                 */
                GetBusinessUnitCertificateRequest.prototype.businessUnitCertificateId = "";

                /**
                 * Verifies a GetBusinessUnitCertificateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitCertificateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        if (!$util.isString(message.businessUnitCertificateId))
                            return "businessUnitCertificateId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitCertificateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitCertificateRequest} GetBusinessUnitCertificateRequest
                 */
                GetBusinessUnitCertificateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitCertificateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitCertificateRequest();
                    if (object.businessUnitCertificateId != null)
                        message.businessUnitCertificateId = String(object.businessUnitCertificateId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitCertificateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitCertificateRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitCertificateRequest} message GetBusinessUnitCertificateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitCertificateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitCertificateId = "";
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        object.businessUnitCertificateId = message.businessUnitCertificateId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitCertificateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitCertificateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitCertificateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitCertificateRequest;
            })();

            company.UpdateBusinessUnitCertificateRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitCertificateRequest
                 * @property {mtechnavi.api.company.IBusinessUnitCertificate|null} [businessUnitCertificate] UpdateBusinessUnitCertificateRequest businessUnitCertificate
                 */

                /**
                 * Constructs a new UpdateBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitCertificateRequest.
                 * @implements IUpdateBusinessUnitCertificateRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitCertificateRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitCertificateRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitCertificateRequest businessUnitCertificate.
                 * @member {mtechnavi.api.company.IBusinessUnitCertificate|null|undefined} businessUnitCertificate
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitCertificateRequest
                 * @instance
                 */
                UpdateBusinessUnitCertificateRequest.prototype.businessUnitCertificate = null;

                /**
                 * Verifies an UpdateBusinessUnitCertificateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitCertificateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.businessUnitCertificate);
                        if (error)
                            return "businessUnitCertificate." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitCertificateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitCertificateRequest} UpdateBusinessUnitCertificateRequest
                 */
                UpdateBusinessUnitCertificateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitCertificateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitCertificateRequest();
                    if (object.businessUnitCertificate != null) {
                        if (typeof object.businessUnitCertificate !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitCertificateRequest.businessUnitCertificate: object expected");
                        message.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.businessUnitCertificate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitCertificateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitCertificateRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitCertificateRequest} message UpdateBusinessUnitCertificateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitCertificateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitCertificate = null;
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate"))
                        object.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.businessUnitCertificate, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitCertificateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitCertificateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitCertificateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitCertificateRequest;
            })();

            company.DeleteBusinessUnitCertificateRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitCertificateRequest
                 * @property {mtechnavi.api.company.IBusinessUnitCertificate|null} [businessUnitCertificate] DeleteBusinessUnitCertificateRequest businessUnitCertificate
                 */

                /**
                 * Constructs a new DeleteBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitCertificateRequest.
                 * @implements IDeleteBusinessUnitCertificateRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitCertificateRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitCertificateRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitCertificateRequest businessUnitCertificate.
                 * @member {mtechnavi.api.company.IBusinessUnitCertificate|null|undefined} businessUnitCertificate
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitCertificateRequest
                 * @instance
                 */
                DeleteBusinessUnitCertificateRequest.prototype.businessUnitCertificate = null;

                /**
                 * Verifies a DeleteBusinessUnitCertificateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitCertificateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.businessUnitCertificate);
                        if (error)
                            return "businessUnitCertificate." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitCertificateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitCertificateRequest} DeleteBusinessUnitCertificateRequest
                 */
                DeleteBusinessUnitCertificateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitCertificateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitCertificateRequest();
                    if (object.businessUnitCertificate != null) {
                        if (typeof object.businessUnitCertificate !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitCertificateRequest.businessUnitCertificate: object expected");
                        message.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.businessUnitCertificate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitCertificateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitCertificateRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitCertificateRequest} message DeleteBusinessUnitCertificateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitCertificateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitCertificate = null;
                    if (message.businessUnitCertificate != null && message.hasOwnProperty("businessUnitCertificate"))
                        object.businessUnitCertificate = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.businessUnitCertificate, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitCertificateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitCertificateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitCertificateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitCertificateRequest;
            })();

            company.ListBusinessUnitStrengthsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitStrengthsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitStrengthsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitStrengthsRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitStrengthsRequest.
                 * @implements IListBusinessUnitStrengthsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitStrengthsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitStrengthsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitStrengthsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @instance
                 */
                ListBusinessUnitStrengthsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitStrengthsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @instance
                 */
                ListBusinessUnitStrengthsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitStrengthsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitStrengthsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitStrengthsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitStrengthsRequest} ListBusinessUnitStrengthsRequest
                 */
                ListBusinessUnitStrengthsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitStrengthsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitStrengthsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitStrengthsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitStrengthsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitStrengthsRequest} message ListBusinessUnitStrengthsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitStrengthsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitStrengthsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitStrengthsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitStrengthsRequest;
            })();

            company.ListBusinessUnitStrengthsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitStrengthsResponse
                 * @property {Long|null} [total] ListBusinessUnitStrengthsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitStrength>|null} [items] ListBusinessUnitStrengthsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitStrengthsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitStrengthsResponse.
                 * @implements IListBusinessUnitStrengthsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitStrengthsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitStrengthsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitStrengthsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListBusinessUnitStrengthsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitStrengthsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitStrength>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListBusinessUnitStrengthsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitStrengthsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListBusinessUnitStrengthsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitStrengthsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitStrengthsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitStrengthsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitStrengthsResponse} ListBusinessUnitStrengthsResponse
                 */
                ListBusinessUnitStrengthsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitStrengthsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitStrengthsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitStrengthsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitStrengthsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitStrengthsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitStrengthsResponse} message ListBusinessUnitStrengthsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitStrengthsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitStrengthsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitStrengthsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitStrengthsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitStrengthsResponse;
            })();

            company.CreateBusinessUnitStrengthRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitStrengthRequest
                 * @property {mtechnavi.api.company.IBusinessUnitStrength|null} [businessUnitStrength] CreateBusinessUnitStrengthRequest businessUnitStrength
                 */

                /**
                 * Constructs a new CreateBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitStrengthRequest.
                 * @implements ICreateBusinessUnitStrengthRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitStrengthRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitStrengthRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitStrengthRequest businessUnitStrength.
                 * @member {mtechnavi.api.company.IBusinessUnitStrength|null|undefined} businessUnitStrength
                 * @memberof mtechnavi.api.company.CreateBusinessUnitStrengthRequest
                 * @instance
                 */
                CreateBusinessUnitStrengthRequest.prototype.businessUnitStrength = null;

                /**
                 * Verifies a CreateBusinessUnitStrengthRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitStrengthRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.businessUnitStrength);
                        if (error)
                            return "businessUnitStrength." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitStrengthRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitStrengthRequest} CreateBusinessUnitStrengthRequest
                 */
                CreateBusinessUnitStrengthRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitStrengthRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitStrengthRequest();
                    if (object.businessUnitStrength != null) {
                        if (typeof object.businessUnitStrength !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitStrengthRequest.businessUnitStrength: object expected");
                        message.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.businessUnitStrength);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitStrengthRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitStrengthRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitStrengthRequest} message CreateBusinessUnitStrengthRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitStrengthRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitStrength = null;
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength"))
                        object.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.businessUnitStrength, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitStrengthRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitStrengthRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitStrengthRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitStrengthRequest;
            })();

            company.GetBusinessUnitStrengthRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitStrengthRequest
                 * @property {string|null} [businessUnitStrengthId] GetBusinessUnitStrengthRequest businessUnitStrengthId
                 */

                /**
                 * Constructs a new GetBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitStrengthRequest.
                 * @implements IGetBusinessUnitStrengthRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitStrengthRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitStrengthRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitStrengthRequest businessUnitStrengthId.
                 * @member {string} businessUnitStrengthId
                 * @memberof mtechnavi.api.company.GetBusinessUnitStrengthRequest
                 * @instance
                 */
                GetBusinessUnitStrengthRequest.prototype.businessUnitStrengthId = "";

                /**
                 * Verifies a GetBusinessUnitStrengthRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitStrengthRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        if (!$util.isString(message.businessUnitStrengthId))
                            return "businessUnitStrengthId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitStrengthRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitStrengthRequest} GetBusinessUnitStrengthRequest
                 */
                GetBusinessUnitStrengthRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitStrengthRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitStrengthRequest();
                    if (object.businessUnitStrengthId != null)
                        message.businessUnitStrengthId = String(object.businessUnitStrengthId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitStrengthRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitStrengthRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitStrengthRequest} message GetBusinessUnitStrengthRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitStrengthRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitStrengthId = "";
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        object.businessUnitStrengthId = message.businessUnitStrengthId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitStrengthRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitStrengthRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitStrengthRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitStrengthRequest;
            })();

            company.UpdateBusinessUnitStrengthRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitStrengthRequest
                 * @property {mtechnavi.api.company.IBusinessUnitStrength|null} [businessUnitStrength] UpdateBusinessUnitStrengthRequest businessUnitStrength
                 */

                /**
                 * Constructs a new UpdateBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitStrengthRequest.
                 * @implements IUpdateBusinessUnitStrengthRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitStrengthRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitStrengthRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitStrengthRequest businessUnitStrength.
                 * @member {mtechnavi.api.company.IBusinessUnitStrength|null|undefined} businessUnitStrength
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitStrengthRequest
                 * @instance
                 */
                UpdateBusinessUnitStrengthRequest.prototype.businessUnitStrength = null;

                /**
                 * Verifies an UpdateBusinessUnitStrengthRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitStrengthRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.businessUnitStrength);
                        if (error)
                            return "businessUnitStrength." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitStrengthRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitStrengthRequest} UpdateBusinessUnitStrengthRequest
                 */
                UpdateBusinessUnitStrengthRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitStrengthRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitStrengthRequest();
                    if (object.businessUnitStrength != null) {
                        if (typeof object.businessUnitStrength !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitStrengthRequest.businessUnitStrength: object expected");
                        message.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.businessUnitStrength);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitStrengthRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitStrengthRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitStrengthRequest} message UpdateBusinessUnitStrengthRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitStrengthRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitStrength = null;
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength"))
                        object.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.businessUnitStrength, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitStrengthRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitStrengthRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitStrengthRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitStrengthRequest;
            })();

            company.DeleteBusinessUnitStrengthRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitStrengthRequest
                 * @property {mtechnavi.api.company.IBusinessUnitStrength|null} [businessUnitStrength] DeleteBusinessUnitStrengthRequest businessUnitStrength
                 */

                /**
                 * Constructs a new DeleteBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitStrengthRequest.
                 * @implements IDeleteBusinessUnitStrengthRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitStrengthRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitStrengthRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitStrengthRequest businessUnitStrength.
                 * @member {mtechnavi.api.company.IBusinessUnitStrength|null|undefined} businessUnitStrength
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitStrengthRequest
                 * @instance
                 */
                DeleteBusinessUnitStrengthRequest.prototype.businessUnitStrength = null;

                /**
                 * Verifies a DeleteBusinessUnitStrengthRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitStrengthRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.businessUnitStrength);
                        if (error)
                            return "businessUnitStrength." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitStrengthRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitStrengthRequest} DeleteBusinessUnitStrengthRequest
                 */
                DeleteBusinessUnitStrengthRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitStrengthRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitStrengthRequest();
                    if (object.businessUnitStrength != null) {
                        if (typeof object.businessUnitStrength !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitStrengthRequest.businessUnitStrength: object expected");
                        message.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.businessUnitStrength);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitStrengthRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitStrengthRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitStrengthRequest} message DeleteBusinessUnitStrengthRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitStrengthRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitStrength = null;
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength"))
                        object.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.businessUnitStrength, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitStrengthRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitStrengthRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitStrengthRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitStrengthRequest;
            })();

            company.ListBusinessUnitSkillsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSkillsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitSkillsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitSkillsRequest companyIds
                 */

                /**
                 * Constructs a new ListBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSkillsRequest.
                 * @implements IListBusinessUnitSkillsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitSkillsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitSkillsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @instance
                 */
                ListBusinessUnitSkillsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitSkillsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @instance
                 */
                ListBusinessUnitSkillsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitSkillsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSkillsRequest} ListBusinessUnitSkillsRequest
                 */
                ListBusinessUnitSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSkillsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitSkillsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitSkillsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillsRequest} message ListBusinessUnitSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSkillsRequest;
            })();

            company.ListBusinessUnitSkillsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSkillsResponse
                 * @property {Long|null} [total] ListBusinessUnitSkillsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSkill>|null} [items] ListBusinessUnitSkillsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitSkillsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSkillsResponse.
                 * @implements IListBusinessUnitSkillsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitSkillsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitSkillsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @instance
                 */
                ListBusinessUnitSkillsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitSkillsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSkill>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @instance
                 */
                ListBusinessUnitSkillsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitSkillsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @instance
                 */
                ListBusinessUnitSkillsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitSkillsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSkillsResponse} ListBusinessUnitSkillsResponse
                 */
                ListBusinessUnitSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSkillsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitSkillsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitSkillsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitSkillsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillsResponse} message ListBusinessUnitSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSkillsResponse;
            })();

            company.CreateBusinessUnitSkillRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitSkillRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSkill|null} [businessUnitSkill] CreateBusinessUnitSkillRequest businessUnitSkill
                 */

                /**
                 * Constructs a new CreateBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitSkillRequest.
                 * @implements ICreateBusinessUnitSkillRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSkillRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitSkillRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitSkillRequest businessUnitSkill.
                 * @member {mtechnavi.api.company.IBusinessUnitSkill|null|undefined} businessUnitSkill
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSkillRequest
                 * @instance
                 */
                CreateBusinessUnitSkillRequest.prototype.businessUnitSkill = null;

                /**
                 * Verifies a CreateBusinessUnitSkillRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.businessUnitSkill);
                        if (error)
                            return "businessUnitSkill." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitSkillRequest} CreateBusinessUnitSkillRequest
                 */
                CreateBusinessUnitSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitSkillRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitSkillRequest();
                    if (object.businessUnitSkill != null) {
                        if (typeof object.businessUnitSkill !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitSkillRequest.businessUnitSkill: object expected");
                        message.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.businessUnitSkill);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSkillRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitSkillRequest} message CreateBusinessUnitSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSkill = null;
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill"))
                        object.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.businessUnitSkill, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitSkillRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitSkillRequest;
            })();

            company.GetBusinessUnitSkillRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitSkillRequest
                 * @property {string|null} [businessUnitSkillId] GetBusinessUnitSkillRequest businessUnitSkillId
                 */

                /**
                 * Constructs a new GetBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitSkillRequest.
                 * @implements IGetBusinessUnitSkillRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitSkillRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitSkillRequest businessUnitSkillId.
                 * @member {string} businessUnitSkillId
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillRequest
                 * @instance
                 */
                GetBusinessUnitSkillRequest.prototype.businessUnitSkillId = "";

                /**
                 * Verifies a GetBusinessUnitSkillRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        if (!$util.isString(message.businessUnitSkillId))
                            return "businessUnitSkillId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitSkillRequest} GetBusinessUnitSkillRequest
                 */
                GetBusinessUnitSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitSkillRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitSkillRequest();
                    if (object.businessUnitSkillId != null)
                        message.businessUnitSkillId = String(object.businessUnitSkillId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitSkillRequest} message GetBusinessUnitSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSkillId = "";
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        object.businessUnitSkillId = message.businessUnitSkillId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitSkillRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitSkillRequest;
            })();

            company.UpdateBusinessUnitSkillRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitSkillRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSkill|null} [businessUnitSkill] UpdateBusinessUnitSkillRequest businessUnitSkill
                 */

                /**
                 * Constructs a new UpdateBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitSkillRequest.
                 * @implements IUpdateBusinessUnitSkillRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSkillRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitSkillRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitSkillRequest businessUnitSkill.
                 * @member {mtechnavi.api.company.IBusinessUnitSkill|null|undefined} businessUnitSkill
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSkillRequest
                 * @instance
                 */
                UpdateBusinessUnitSkillRequest.prototype.businessUnitSkill = null;

                /**
                 * Verifies an UpdateBusinessUnitSkillRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.businessUnitSkill);
                        if (error)
                            return "businessUnitSkill." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitSkillRequest} UpdateBusinessUnitSkillRequest
                 */
                UpdateBusinessUnitSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitSkillRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitSkillRequest();
                    if (object.businessUnitSkill != null) {
                        if (typeof object.businessUnitSkill !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitSkillRequest.businessUnitSkill: object expected");
                        message.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.businessUnitSkill);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSkillRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitSkillRequest} message UpdateBusinessUnitSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSkill = null;
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill"))
                        object.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.businessUnitSkill, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitSkillRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitSkillRequest;
            })();

            company.DeleteBusinessUnitSkillRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitSkillRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSkill|null} [businessUnitSkill] DeleteBusinessUnitSkillRequest businessUnitSkill
                 */

                /**
                 * Constructs a new DeleteBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitSkillRequest.
                 * @implements IDeleteBusinessUnitSkillRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSkillRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitSkillRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitSkillRequest businessUnitSkill.
                 * @member {mtechnavi.api.company.IBusinessUnitSkill|null|undefined} businessUnitSkill
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSkillRequest
                 * @instance
                 */
                DeleteBusinessUnitSkillRequest.prototype.businessUnitSkill = null;

                /**
                 * Verifies a DeleteBusinessUnitSkillRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.businessUnitSkill);
                        if (error)
                            return "businessUnitSkill." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitSkillRequest} DeleteBusinessUnitSkillRequest
                 */
                DeleteBusinessUnitSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitSkillRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitSkillRequest();
                    if (object.businessUnitSkill != null) {
                        if (typeof object.businessUnitSkill !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitSkillRequest.businessUnitSkill: object expected");
                        message.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.businessUnitSkill);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSkillRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitSkillRequest} message DeleteBusinessUnitSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSkill = null;
                    if (message.businessUnitSkill != null && message.hasOwnProperty("businessUnitSkill"))
                        object.businessUnitSkill = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.businessUnitSkill, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitSkillRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitSkillRequest;
            })();

            company.ListBusinessUnitContactHeadersRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitContactHeadersRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactHeadersRequest
                 * @property {string|null} [pageToken] ListBusinessUnitContactHeadersRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitContactHeadersRequest companyIds
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitContactHeadersRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitContactHeadersRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactHeadersRequest.
                 * @implements IListBusinessUnitContactHeadersRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactHeadersRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitContactHeadersRequest(properties) {
                    this.companyIds = [];
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactHeadersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @instance
                 */
                ListBusinessUnitContactHeadersRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitContactHeadersRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @instance
                 */
                ListBusinessUnitContactHeadersRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * ListBusinessUnitContactHeadersRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @instance
                 */
                ListBusinessUnitContactHeadersRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitContactHeadersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactHeadersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactHeadersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactHeadersRequest} ListBusinessUnitContactHeadersRequest
                 */
                ListBusinessUnitContactHeadersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactHeadersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactHeadersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactHeadersRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactHeadersRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactHeadersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactHeadersRequest} message ListBusinessUnitContactHeadersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactHeadersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.companyIds = [];
                        object.businessUnitManagementIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactHeadersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactHeadersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactHeadersRequest;
            })();

            company.ListBusinessUnitContactHeadersResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitContactHeadersResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactHeadersResponse
                 * @property {Long|null} [total] ListBusinessUnitContactHeadersResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitContactHeader>|null} [items] ListBusinessUnitContactHeadersResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitContactHeadersResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitContactHeadersResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactHeadersResponse.
                 * @implements IListBusinessUnitContactHeadersResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactHeadersResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitContactHeadersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactHeadersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @instance
                 */
                ListBusinessUnitContactHeadersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitContactHeadersResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitContactHeader>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @instance
                 */
                ListBusinessUnitContactHeadersResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitContactHeadersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @instance
                 */
                ListBusinessUnitContactHeadersResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitContactHeadersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactHeadersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactHeadersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactHeadersResponse} ListBusinessUnitContactHeadersResponse
                 */
                ListBusinessUnitContactHeadersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactHeadersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactHeadersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactHeadersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactHeadersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactHeadersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactHeadersResponse} message ListBusinessUnitContactHeadersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactHeadersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactHeadersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactHeadersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactHeadersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactHeadersResponse;
            })();

            company.CreateBusinessUnitContactHeaderRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitContactHeaderRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactHeader|null} [businessUnitContactHeader] CreateBusinessUnitContactHeaderRequest businessUnitContactHeader
                 * @property {string|null} [businessUnitContactId] CreateBusinessUnitContactHeaderRequest businessUnitContactId
                 */

                /**
                 * Constructs a new CreateBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitContactHeaderRequest.
                 * @implements ICreateBusinessUnitContactHeaderRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactHeaderRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitContactHeaderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitContactHeaderRequest businessUnitContactHeader.
                 * @member {mtechnavi.api.company.IBusinessUnitContactHeader|null|undefined} businessUnitContactHeader
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @instance
                 */
                CreateBusinessUnitContactHeaderRequest.prototype.businessUnitContactHeader = null;

                /**
                 * CreateBusinessUnitContactHeaderRequest businessUnitContactId.
                 * @member {string} businessUnitContactId
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @instance
                 */
                CreateBusinessUnitContactHeaderRequest.prototype.businessUnitContactId = "";

                /**
                 * Verifies a CreateBusinessUnitContactHeaderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitContactHeaderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.businessUnitContactHeader);
                        if (error)
                            return "businessUnitContactHeader." + error;
                    }
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        if (!$util.isString(message.businessUnitContactId))
                            return "businessUnitContactId: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitContactHeaderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest} CreateBusinessUnitContactHeaderRequest
                 */
                CreateBusinessUnitContactHeaderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest();
                    if (object.businessUnitContactHeader != null) {
                        if (typeof object.businessUnitContactHeader !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest.businessUnitContactHeader: object expected");
                        message.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.businessUnitContactHeader);
                    }
                    if (object.businessUnitContactId != null)
                        message.businessUnitContactId = String(object.businessUnitContactId);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitContactHeaderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest} message CreateBusinessUnitContactHeaderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitContactHeaderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContactHeader = null;
                        object.businessUnitContactId = "";
                    }
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader"))
                        object.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.businessUnitContactHeader, options);
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        object.businessUnitContactId = message.businessUnitContactId;
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitContactHeaderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactHeaderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitContactHeaderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitContactHeaderRequest;
            })();

            company.GetBusinessUnitContactHeaderRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitContactHeaderRequest
                 * @property {string|null} [businessUnitContactHeaderId] GetBusinessUnitContactHeaderRequest businessUnitContactHeaderId
                 */

                /**
                 * Constructs a new GetBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitContactHeaderRequest.
                 * @implements IGetBusinessUnitContactHeaderRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactHeaderRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitContactHeaderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitContactHeaderRequest businessUnitContactHeaderId.
                 * @member {string} businessUnitContactHeaderId
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactHeaderRequest
                 * @instance
                 */
                GetBusinessUnitContactHeaderRequest.prototype.businessUnitContactHeaderId = "";

                /**
                 * Verifies a GetBusinessUnitContactHeaderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitContactHeaderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        if (!$util.isString(message.businessUnitContactHeaderId))
                            return "businessUnitContactHeaderId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitContactHeaderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitContactHeaderRequest} GetBusinessUnitContactHeaderRequest
                 */
                GetBusinessUnitContactHeaderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitContactHeaderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitContactHeaderRequest();
                    if (object.businessUnitContactHeaderId != null)
                        message.businessUnitContactHeaderId = String(object.businessUnitContactHeaderId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitContactHeaderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactHeaderRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitContactHeaderRequest} message GetBusinessUnitContactHeaderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitContactHeaderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactHeaderId = "";
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        object.businessUnitContactHeaderId = message.businessUnitContactHeaderId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitContactHeaderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactHeaderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitContactHeaderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitContactHeaderRequest;
            })();

            company.UpdateBusinessUnitContactHeaderRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitContactHeaderRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactHeader|null} [businessUnitContactHeader] UpdateBusinessUnitContactHeaderRequest businessUnitContactHeader
                 */

                /**
                 * Constructs a new UpdateBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitContactHeaderRequest.
                 * @implements IUpdateBusinessUnitContactHeaderRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactHeaderRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitContactHeaderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitContactHeaderRequest businessUnitContactHeader.
                 * @member {mtechnavi.api.company.IBusinessUnitContactHeader|null|undefined} businessUnitContactHeader
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest
                 * @instance
                 */
                UpdateBusinessUnitContactHeaderRequest.prototype.businessUnitContactHeader = null;

                /**
                 * Verifies an UpdateBusinessUnitContactHeaderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitContactHeaderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.businessUnitContactHeader);
                        if (error)
                            return "businessUnitContactHeader." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitContactHeaderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest} UpdateBusinessUnitContactHeaderRequest
                 */
                UpdateBusinessUnitContactHeaderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest();
                    if (object.businessUnitContactHeader != null) {
                        if (typeof object.businessUnitContactHeader !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest.businessUnitContactHeader: object expected");
                        message.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.businessUnitContactHeader);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitContactHeaderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest} message UpdateBusinessUnitContactHeaderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitContactHeaderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactHeader = null;
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader"))
                        object.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.businessUnitContactHeader, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitContactHeaderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactHeaderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitContactHeaderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitContactHeaderRequest;
            })();

            company.DeleteBusinessUnitContactHeaderRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitContactHeaderRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactHeader|null} [businessUnitContactHeader] DeleteBusinessUnitContactHeaderRequest businessUnitContactHeader
                 */

                /**
                 * Constructs a new DeleteBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitContactHeaderRequest.
                 * @implements IDeleteBusinessUnitContactHeaderRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactHeaderRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitContactHeaderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitContactHeaderRequest businessUnitContactHeader.
                 * @member {mtechnavi.api.company.IBusinessUnitContactHeader|null|undefined} businessUnitContactHeader
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest
                 * @instance
                 */
                DeleteBusinessUnitContactHeaderRequest.prototype.businessUnitContactHeader = null;

                /**
                 * Verifies a DeleteBusinessUnitContactHeaderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitContactHeaderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.businessUnitContactHeader);
                        if (error)
                            return "businessUnitContactHeader." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitContactHeaderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest} DeleteBusinessUnitContactHeaderRequest
                 */
                DeleteBusinessUnitContactHeaderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest();
                    if (object.businessUnitContactHeader != null) {
                        if (typeof object.businessUnitContactHeader !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest.businessUnitContactHeader: object expected");
                        message.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.businessUnitContactHeader);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitContactHeaderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest} message DeleteBusinessUnitContactHeaderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitContactHeaderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactHeader = null;
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader"))
                        object.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.businessUnitContactHeader, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitContactHeaderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactHeaderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitContactHeaderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitContactHeaderRequest;
            })();

            company.ListBusinessUnitContactsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitContactsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] ListBusinessUnitContactsRequest companyIds
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitContactsRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactsRequest.
                 * @implements IListBusinessUnitContactsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitContactsRequest(properties) {
                    this.companyIds = [];
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @instance
                 */
                ListBusinessUnitContactsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitContactsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @instance
                 */
                ListBusinessUnitContactsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * ListBusinessUnitContactsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @instance
                 */
                ListBusinessUnitContactsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitContactsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactsRequest} ListBusinessUnitContactsRequest
                 */
                ListBusinessUnitContactsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactsRequest} message ListBusinessUnitContactsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.companyIds = [];
                        object.businessUnitManagementIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactsRequest;
            })();

            company.ListBusinessUnitContactsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactsResponse
                 * @property {Long|null} [total] ListBusinessUnitContactsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitContact>|null} [items] ListBusinessUnitContactsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitContactsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactsResponse.
                 * @implements IListBusinessUnitContactsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitContactsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @instance
                 */
                ListBusinessUnitContactsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitContactsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitContact>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @instance
                 */
                ListBusinessUnitContactsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitContactsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @instance
                 */
                ListBusinessUnitContactsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitContactsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactsResponse} ListBusinessUnitContactsResponse
                 */
                ListBusinessUnitContactsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactsResponse} message ListBusinessUnitContactsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactsResponse;
            })();

            company.CreateBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitContactRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] CreateBusinessUnitContactRequest businessUnitContact
                 * @property {string|null} [businessUnitContactHeaderId] CreateBusinessUnitContactRequest businessUnitContactHeaderId
                 */

                /**
                 * Constructs a new CreateBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitContactRequest.
                 * @implements ICreateBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitContactRequest businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @instance
                 */
                CreateBusinessUnitContactRequest.prototype.businessUnitContact = null;

                /**
                 * CreateBusinessUnitContactRequest businessUnitContactHeaderId.
                 * @member {string} businessUnitContactHeaderId
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @instance
                 */
                CreateBusinessUnitContactRequest.prototype.businessUnitContactHeaderId = "";

                /**
                 * Verifies a CreateBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        if (!$util.isString(message.businessUnitContactHeaderId))
                            return "businessUnitContactHeaderId: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitContactRequest} CreateBusinessUnitContactRequest
                 */
                CreateBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitContactRequest();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitContactRequest.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    if (object.businessUnitContactHeaderId != null)
                        message.businessUnitContactHeaderId = String(object.businessUnitContactHeaderId);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitContactRequest} message CreateBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContact = null;
                        object.businessUnitContactHeaderId = "";
                    }
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        object.businessUnitContactHeaderId = message.businessUnitContactHeaderId;
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitContactRequest;
            })();

            company.GetBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitContactRequest
                 * @property {string|null} [businessUnitContactId] GetBusinessUnitContactRequest businessUnitContactId
                 */

                /**
                 * Constructs a new GetBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitContactRequest.
                 * @implements IGetBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitContactRequest businessUnitContactId.
                 * @member {string} businessUnitContactId
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactRequest
                 * @instance
                 */
                GetBusinessUnitContactRequest.prototype.businessUnitContactId = "";

                /**
                 * Verifies a GetBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        if (!$util.isString(message.businessUnitContactId))
                            return "businessUnitContactId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitContactRequest} GetBusinessUnitContactRequest
                 */
                GetBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitContactRequest();
                    if (object.businessUnitContactId != null)
                        message.businessUnitContactId = String(object.businessUnitContactId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitContactRequest} message GetBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactId = "";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        object.businessUnitContactId = message.businessUnitContactId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitContactRequest;
            })();

            company.UpdateBusinessUnitContactRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitContactRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] UpdateBusinessUnitContactRequest businessUnitContact
                 */

                /**
                 * Constructs a new UpdateBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitContactRequest.
                 * @implements IUpdateBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitContactRequest businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactRequest
                 * @instance
                 */
                UpdateBusinessUnitContactRequest.prototype.businessUnitContact = null;

                /**
                 * Verifies an UpdateBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitContactRequest} UpdateBusinessUnitContactRequest
                 */
                UpdateBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitContactRequest();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitContactRequest.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitContactRequest} message UpdateBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContact = null;
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitContactRequest;
            })();

            company.DeleteBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitContactRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] DeleteBusinessUnitContactRequest businessUnitContact
                 */

                /**
                 * Constructs a new DeleteBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitContactRequest.
                 * @implements IDeleteBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitContactRequest businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactRequest
                 * @instance
                 */
                DeleteBusinessUnitContactRequest.prototype.businessUnitContact = null;

                /**
                 * Verifies a DeleteBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitContactRequest} DeleteBusinessUnitContactRequest
                 */
                DeleteBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitContactRequest();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitContactRequest.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitContactRequest} message DeleteBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContact = null;
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitContactRequest;
            })();

            company.ActivateMainContactInBusinessUnitContactRequest = (function() {

                /**
                 * Properties of an ActivateMainContactInBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IActivateMainContactInBusinessUnitContactRequest
                 * @property {string|null} [businessUnitContactId] ActivateMainContactInBusinessUnitContactRequest businessUnitContactId
                 */

                /**
                 * Constructs a new ActivateMainContactInBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ActivateMainContactInBusinessUnitContactRequest.
                 * @implements IActivateMainContactInBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IActivateMainContactInBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function ActivateMainContactInBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ActivateMainContactInBusinessUnitContactRequest businessUnitContactId.
                 * @member {string} businessUnitContactId
                 * @memberof mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest
                 * @instance
                 */
                ActivateMainContactInBusinessUnitContactRequest.prototype.businessUnitContactId = "";

                /**
                 * Verifies an ActivateMainContactInBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActivateMainContactInBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        if (!$util.isString(message.businessUnitContactId))
                            return "businessUnitContactId: string expected";
                    return null;
                };

                /**
                 * Creates an ActivateMainContactInBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest} ActivateMainContactInBusinessUnitContactRequest
                 */
                ActivateMainContactInBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest();
                    if (object.businessUnitContactId != null)
                        message.businessUnitContactId = String(object.businessUnitContactId);
                    return message;
                };

                /**
                 * Creates a plain object from an ActivateMainContactInBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest} message ActivateMainContactInBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActivateMainContactInBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactId = "";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        object.businessUnitContactId = message.businessUnitContactId;
                    return object;
                };

                /**
                 * Converts this ActivateMainContactInBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ActivateMainContactInBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActivateMainContactInBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ActivateMainContactInBusinessUnitContactRequest;
            })();

            company.ListBusinessUnitContactAttributesRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitContactAttributesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactAttributesRequest
                 * @property {string|null} [pageToken] ListBusinessUnitContactAttributesRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitContactAttributesRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitContactAttributesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactAttributesRequest.
                 * @implements IListBusinessUnitContactAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactAttributesRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitContactAttributesRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @instance
                 */
                ListBusinessUnitContactAttributesRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitContactAttributesRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @instance
                 */
                ListBusinessUnitContactAttributesRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitContactAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactAttributesRequest} ListBusinessUnitContactAttributesRequest
                 */
                ListBusinessUnitContactAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactAttributesRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactAttributesRequest} message ListBusinessUnitContactAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactAttributesRequest;
            })();

            company.ListBusinessUnitContactAttributesResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitContactAttributesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactAttributesResponse
                 * @property {Long|null} [total] ListBusinessUnitContactAttributesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitContactAttribute>|null} [items] ListBusinessUnitContactAttributesResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitContactAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitContactAttributesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactAttributesResponse.
                 * @implements IListBusinessUnitContactAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactAttributesResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitContactAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @instance
                 */
                ListBusinessUnitContactAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitContactAttributesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitContactAttribute>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @instance
                 */
                ListBusinessUnitContactAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitContactAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @instance
                 */
                ListBusinessUnitContactAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitContactAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactAttributesResponse} ListBusinessUnitContactAttributesResponse
                 */
                ListBusinessUnitContactAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactAttributesResponse} message ListBusinessUnitContactAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactAttributesResponse;
            })();

            company.CreateBusinessUnitContactAttributeRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitContactAttributeRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] CreateBusinessUnitContactAttributeRequest businessUnitContactAttribute
                 */

                /**
                 * Constructs a new CreateBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitContactAttributeRequest.
                 * @implements ICreateBusinessUnitContactAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitContactAttributeRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitContactAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitContactAttributeRequest businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest
                 * @instance
                 */
                CreateBusinessUnitContactAttributeRequest.prototype.businessUnitContactAttribute = null;

                /**
                 * Verifies a CreateBusinessUnitContactAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitContactAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitContactAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest} CreateBusinessUnitContactAttributeRequest
                 */
                CreateBusinessUnitContactAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest();
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitContactAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest} message CreateBusinessUnitContactAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitContactAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactAttribute = null;
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitContactAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitContactAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitContactAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitContactAttributeRequest;
            })();

            company.GetBusinessUnitContactAttributeRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitContactAttributeRequest
                 * @property {string|null} [businessUnitContactAttributeId] GetBusinessUnitContactAttributeRequest businessUnitContactAttributeId
                 */

                /**
                 * Constructs a new GetBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitContactAttributeRequest.
                 * @implements IGetBusinessUnitContactAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactAttributeRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitContactAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitContactAttributeRequest businessUnitContactAttributeId.
                 * @member {string} businessUnitContactAttributeId
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactAttributeRequest
                 * @instance
                 */
                GetBusinessUnitContactAttributeRequest.prototype.businessUnitContactAttributeId = "";

                /**
                 * Verifies a GetBusinessUnitContactAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitContactAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactAttributeId != null && message.hasOwnProperty("businessUnitContactAttributeId"))
                        if (!$util.isString(message.businessUnitContactAttributeId))
                            return "businessUnitContactAttributeId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitContactAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitContactAttributeRequest} GetBusinessUnitContactAttributeRequest
                 */
                GetBusinessUnitContactAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitContactAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitContactAttributeRequest();
                    if (object.businessUnitContactAttributeId != null)
                        message.businessUnitContactAttributeId = String(object.businessUnitContactAttributeId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitContactAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactAttributeRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitContactAttributeRequest} message GetBusinessUnitContactAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitContactAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactAttributeId = "";
                    if (message.businessUnitContactAttributeId != null && message.hasOwnProperty("businessUnitContactAttributeId"))
                        object.businessUnitContactAttributeId = message.businessUnitContactAttributeId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitContactAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitContactAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitContactAttributeRequest;
            })();

            company.UpdateBusinessUnitContactAttributeRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitContactAttributeRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] UpdateBusinessUnitContactAttributeRequest businessUnitContactAttribute
                 */

                /**
                 * Constructs a new UpdateBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitContactAttributeRequest.
                 * @implements IUpdateBusinessUnitContactAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitContactAttributeRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitContactAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitContactAttributeRequest businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest
                 * @instance
                 */
                UpdateBusinessUnitContactAttributeRequest.prototype.businessUnitContactAttribute = null;

                /**
                 * Verifies an UpdateBusinessUnitContactAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitContactAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitContactAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest} UpdateBusinessUnitContactAttributeRequest
                 */
                UpdateBusinessUnitContactAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest();
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitContactAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest} message UpdateBusinessUnitContactAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitContactAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactAttribute = null;
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitContactAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitContactAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitContactAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitContactAttributeRequest;
            })();

            company.DeleteBusinessUnitContactAttributeRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitContactAttributeRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] DeleteBusinessUnitContactAttributeRequest businessUnitContactAttribute
                 */

                /**
                 * Constructs a new DeleteBusinessUnitContactAttributeRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitContactAttributeRequest.
                 * @implements IDeleteBusinessUnitContactAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitContactAttributeRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitContactAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitContactAttributeRequest businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest
                 * @instance
                 */
                DeleteBusinessUnitContactAttributeRequest.prototype.businessUnitContactAttribute = null;

                /**
                 * Verifies a DeleteBusinessUnitContactAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitContactAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitContactAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest} DeleteBusinessUnitContactAttributeRequest
                 */
                DeleteBusinessUnitContactAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest();
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitContactAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest} message DeleteBusinessUnitContactAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitContactAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactAttribute = null;
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitContactAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitContactAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitContactAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitContactAttributeRequest;
            })();

            company.ListBusinessUnitDocumentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitDocumentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitDocumentsRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitDocumentsRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitDocumentsRequest.
                 * @implements IListBusinessUnitDocumentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitDocumentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitDocumentsRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitDocumentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @instance
                 */
                ListBusinessUnitDocumentsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitDocumentsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @instance
                 */
                ListBusinessUnitDocumentsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitDocumentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitDocumentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitDocumentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitDocumentsRequest} ListBusinessUnitDocumentsRequest
                 */
                ListBusinessUnitDocumentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitDocumentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitDocumentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitDocumentsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitDocumentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitDocumentsRequest} message ListBusinessUnitDocumentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitDocumentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitDocumentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitDocumentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitDocumentsRequest;
            })();

            company.ListBusinessUnitDocumentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitDocumentsResponse
                 * @property {Long|null} [total] ListBusinessUnitDocumentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitDocument>|null} [items] ListBusinessUnitDocumentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitDocumentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitDocumentsResponse.
                 * @implements IListBusinessUnitDocumentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitDocumentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitDocumentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitDocumentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitDocumentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitDocumentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitDocument>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitDocumentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitDocumentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitDocumentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitDocumentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitDocumentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitDocument.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitDocumentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitDocumentsResponse} ListBusinessUnitDocumentsResponse
                 */
                ListBusinessUnitDocumentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitDocumentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitDocumentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitDocumentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitDocumentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitDocument.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitDocumentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitDocumentsResponse} message ListBusinessUnitDocumentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitDocumentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitDocument.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitDocumentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitDocumentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitDocumentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitDocumentsResponse;
            })();

            company.CreateBusinessUnitDocumentRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitDocument|null} [businessUnitDocument] CreateBusinessUnitDocumentRequest businessUnitDocument
                 */

                /**
                 * Constructs a new CreateBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitDocumentRequest.
                 * @implements ICreateBusinessUnitDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitDocumentRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitDocumentRequest businessUnitDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitDocument|null|undefined} businessUnitDocument
                 * @memberof mtechnavi.api.company.CreateBusinessUnitDocumentRequest
                 * @instance
                 */
                CreateBusinessUnitDocumentRequest.prototype.businessUnitDocument = null;

                /**
                 * Verifies a CreateBusinessUnitDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitDocument.verify(message.businessUnitDocument);
                        if (error)
                            return "businessUnitDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitDocumentRequest} CreateBusinessUnitDocumentRequest
                 */
                CreateBusinessUnitDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitDocumentRequest();
                    if (object.businessUnitDocument != null) {
                        if (typeof object.businessUnitDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitDocumentRequest.businessUnitDocument: object expected");
                        message.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.fromObject(object.businessUnitDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitDocumentRequest} message CreateBusinessUnitDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitDocument = null;
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument"))
                        object.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.toObject(message.businessUnitDocument, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitDocumentRequest;
            })();

            company.GetBusinessUnitDocumentRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitDocumentRequest
                 * @property {string|null} [businessUnitDocumentId] GetBusinessUnitDocumentRequest businessUnitDocumentId
                 */

                /**
                 * Constructs a new GetBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitDocumentRequest.
                 * @implements IGetBusinessUnitDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitDocumentRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitDocumentRequest businessUnitDocumentId.
                 * @member {string} businessUnitDocumentId
                 * @memberof mtechnavi.api.company.GetBusinessUnitDocumentRequest
                 * @instance
                 */
                GetBusinessUnitDocumentRequest.prototype.businessUnitDocumentId = "";

                /**
                 * Verifies a GetBusinessUnitDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitDocumentId != null && message.hasOwnProperty("businessUnitDocumentId"))
                        if (!$util.isString(message.businessUnitDocumentId))
                            return "businessUnitDocumentId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitDocumentRequest} GetBusinessUnitDocumentRequest
                 */
                GetBusinessUnitDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitDocumentRequest();
                    if (object.businessUnitDocumentId != null)
                        message.businessUnitDocumentId = String(object.businessUnitDocumentId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitDocumentRequest} message GetBusinessUnitDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitDocumentId = "";
                    if (message.businessUnitDocumentId != null && message.hasOwnProperty("businessUnitDocumentId"))
                        object.businessUnitDocumentId = message.businessUnitDocumentId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitDocumentRequest;
            })();

            company.UpdateBusinessUnitDocumentRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitDocument|null} [businessUnitDocument] UpdateBusinessUnitDocumentRequest businessUnitDocument
                 */

                /**
                 * Constructs a new UpdateBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitDocumentRequest.
                 * @implements IUpdateBusinessUnitDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitDocumentRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitDocumentRequest businessUnitDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitDocument|null|undefined} businessUnitDocument
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitDocumentRequest
                 * @instance
                 */
                UpdateBusinessUnitDocumentRequest.prototype.businessUnitDocument = null;

                /**
                 * Verifies an UpdateBusinessUnitDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitDocument.verify(message.businessUnitDocument);
                        if (error)
                            return "businessUnitDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitDocumentRequest} UpdateBusinessUnitDocumentRequest
                 */
                UpdateBusinessUnitDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitDocumentRequest();
                    if (object.businessUnitDocument != null) {
                        if (typeof object.businessUnitDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitDocumentRequest.businessUnitDocument: object expected");
                        message.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.fromObject(object.businessUnitDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitDocumentRequest} message UpdateBusinessUnitDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitDocument = null;
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument"))
                        object.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.toObject(message.businessUnitDocument, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitDocumentRequest;
            })();

            company.DeleteBusinessUnitDocumentRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitDocument|null} [businessUnitDocument] DeleteBusinessUnitDocumentRequest businessUnitDocument
                 */

                /**
                 * Constructs a new DeleteBusinessUnitDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitDocumentRequest.
                 * @implements IDeleteBusinessUnitDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitDocumentRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitDocumentRequest businessUnitDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitDocument|null|undefined} businessUnitDocument
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitDocumentRequest
                 * @instance
                 */
                DeleteBusinessUnitDocumentRequest.prototype.businessUnitDocument = null;

                /**
                 * Verifies a DeleteBusinessUnitDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitDocument.verify(message.businessUnitDocument);
                        if (error)
                            return "businessUnitDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitDocumentRequest} DeleteBusinessUnitDocumentRequest
                 */
                DeleteBusinessUnitDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitDocumentRequest();
                    if (object.businessUnitDocument != null) {
                        if (typeof object.businessUnitDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitDocumentRequest.businessUnitDocument: object expected");
                        message.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.fromObject(object.businessUnitDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitDocumentRequest} message DeleteBusinessUnitDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitDocument = null;
                    if (message.businessUnitDocument != null && message.hasOwnProperty("businessUnitDocument"))
                        object.businessUnitDocument = $root.mtechnavi.api.company.BusinessUnitDocument.toObject(message.businessUnitDocument, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitDocumentRequest;
            })();

            company.ListBusinessUnitSnapshotsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitSnapshotsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSnapshotsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitSnapshotsRequest pageToken
                 * @property {string|null} [typeName] ListBusinessUnitSnapshotsRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListBusinessUnitSnapshotsRequest recordIds
                 */

                /**
                 * Constructs a new ListBusinessUnitSnapshotsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSnapshotsRequest.
                 * @implements IListBusinessUnitSnapshotsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSnapshotsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitSnapshotsRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitSnapshotsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @instance
                 */
                ListBusinessUnitSnapshotsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitSnapshotsRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @instance
                 */
                ListBusinessUnitSnapshotsRequest.prototype.typeName = "";

                /**
                 * ListBusinessUnitSnapshotsRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @instance
                 */
                ListBusinessUnitSnapshotsRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitSnapshotsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSnapshotsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSnapshotsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSnapshotsRequest} ListBusinessUnitSnapshotsRequest
                 */
                ListBusinessUnitSnapshotsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSnapshotsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitSnapshotsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitSnapshotsRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSnapshotsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSnapshotsRequest} message ListBusinessUnitSnapshotsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSnapshotsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitSnapshotsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSnapshotsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSnapshotsRequest;
            })();

            company.ListBusinessUnitSnapshotsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitSnapshotsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSnapshotsResponse
                 * @property {Long|null} [total] ListBusinessUnitSnapshotsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSnapshot>|null} [items] ListBusinessUnitSnapshotsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitSnapshotsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitSnapshotsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSnapshotsResponse.
                 * @implements IListBusinessUnitSnapshotsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSnapshotsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitSnapshotsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitSnapshotsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @instance
                 */
                ListBusinessUnitSnapshotsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitSnapshotsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSnapshot>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @instance
                 */
                ListBusinessUnitSnapshotsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitSnapshotsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @instance
                 */
                ListBusinessUnitSnapshotsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitSnapshotsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSnapshotsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSnapshot.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSnapshotsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSnapshotsResponse} ListBusinessUnitSnapshotsResponse
                 */
                ListBusinessUnitSnapshotsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSnapshotsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitSnapshotsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitSnapshotsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitSnapshotsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitSnapshot.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSnapshotsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSnapshotsResponse} message ListBusinessUnitSnapshotsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSnapshotsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitSnapshot.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitSnapshotsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSnapshotsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSnapshotsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSnapshotsResponse;
            })();

            company.CreateBusinessUnitSnapshotRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitSnapshotRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSnapshot|null} [businessUnitSnapshot] CreateBusinessUnitSnapshotRequest businessUnitSnapshot
                 */

                /**
                 * Constructs a new CreateBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitSnapshotRequest.
                 * @implements ICreateBusinessUnitSnapshotRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitSnapshotRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitSnapshotRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitSnapshotRequest businessUnitSnapshot.
                 * @member {mtechnavi.api.company.IBusinessUnitSnapshot|null|undefined} businessUnitSnapshot
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSnapshotRequest
                 * @instance
                 */
                CreateBusinessUnitSnapshotRequest.prototype.businessUnitSnapshot = null;

                /**
                 * Verifies a CreateBusinessUnitSnapshotRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitSnapshotRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSnapshot.verify(message.businessUnitSnapshot);
                        if (error)
                            return "businessUnitSnapshot." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitSnapshotRequest} CreateBusinessUnitSnapshotRequest
                 */
                CreateBusinessUnitSnapshotRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitSnapshotRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitSnapshotRequest();
                    if (object.businessUnitSnapshot != null) {
                        if (typeof object.businessUnitSnapshot !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitSnapshotRequest.businessUnitSnapshot: object expected");
                        message.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.fromObject(object.businessUnitSnapshot);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitSnapshotRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSnapshotRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitSnapshotRequest} message CreateBusinessUnitSnapshotRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitSnapshotRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSnapshot = null;
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot"))
                        object.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.toObject(message.businessUnitSnapshot, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitSnapshotRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitSnapshotRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitSnapshotRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitSnapshotRequest;
            })();

            company.GetBusinessUnitSnapshotRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitSnapshotRequest
                 * @property {string|null} [businessUnitSnapshotId] GetBusinessUnitSnapshotRequest businessUnitSnapshotId
                 */

                /**
                 * Constructs a new GetBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitSnapshotRequest.
                 * @implements IGetBusinessUnitSnapshotRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitSnapshotRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitSnapshotRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitSnapshotRequest businessUnitSnapshotId.
                 * @member {string} businessUnitSnapshotId
                 * @memberof mtechnavi.api.company.GetBusinessUnitSnapshotRequest
                 * @instance
                 */
                GetBusinessUnitSnapshotRequest.prototype.businessUnitSnapshotId = "";

                /**
                 * Verifies a GetBusinessUnitSnapshotRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitSnapshotRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSnapshotId != null && message.hasOwnProperty("businessUnitSnapshotId"))
                        if (!$util.isString(message.businessUnitSnapshotId))
                            return "businessUnitSnapshotId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitSnapshotRequest} GetBusinessUnitSnapshotRequest
                 */
                GetBusinessUnitSnapshotRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitSnapshotRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitSnapshotRequest();
                    if (object.businessUnitSnapshotId != null)
                        message.businessUnitSnapshotId = String(object.businessUnitSnapshotId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitSnapshotRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSnapshotRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitSnapshotRequest} message GetBusinessUnitSnapshotRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitSnapshotRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSnapshotId = "";
                    if (message.businessUnitSnapshotId != null && message.hasOwnProperty("businessUnitSnapshotId"))
                        object.businessUnitSnapshotId = message.businessUnitSnapshotId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitSnapshotRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitSnapshotRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitSnapshotRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitSnapshotRequest;
            })();

            company.UpdateBusinessUnitSnapshotRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitSnapshotRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSnapshot|null} [businessUnitSnapshot] UpdateBusinessUnitSnapshotRequest businessUnitSnapshot
                 */

                /**
                 * Constructs a new UpdateBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitSnapshotRequest.
                 * @implements IUpdateBusinessUnitSnapshotRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitSnapshotRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitSnapshotRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitSnapshotRequest businessUnitSnapshot.
                 * @member {mtechnavi.api.company.IBusinessUnitSnapshot|null|undefined} businessUnitSnapshot
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest
                 * @instance
                 */
                UpdateBusinessUnitSnapshotRequest.prototype.businessUnitSnapshot = null;

                /**
                 * Verifies an UpdateBusinessUnitSnapshotRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitSnapshotRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSnapshot.verify(message.businessUnitSnapshot);
                        if (error)
                            return "businessUnitSnapshot." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest} UpdateBusinessUnitSnapshotRequest
                 */
                UpdateBusinessUnitSnapshotRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest();
                    if (object.businessUnitSnapshot != null) {
                        if (typeof object.businessUnitSnapshot !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest.businessUnitSnapshot: object expected");
                        message.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.fromObject(object.businessUnitSnapshot);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitSnapshotRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest} message UpdateBusinessUnitSnapshotRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitSnapshotRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSnapshot = null;
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot"))
                        object.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.toObject(message.businessUnitSnapshot, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitSnapshotRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitSnapshotRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitSnapshotRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitSnapshotRequest;
            })();

            company.DeleteBusinessUnitSnapshotRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitSnapshotRequest
                 * @property {mtechnavi.api.company.IBusinessUnitSnapshot|null} [businessUnitSnapshot] DeleteBusinessUnitSnapshotRequest businessUnitSnapshot
                 */

                /**
                 * Constructs a new DeleteBusinessUnitSnapshotRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitSnapshotRequest.
                 * @implements IDeleteBusinessUnitSnapshotRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitSnapshotRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitSnapshotRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitSnapshotRequest businessUnitSnapshot.
                 * @member {mtechnavi.api.company.IBusinessUnitSnapshot|null|undefined} businessUnitSnapshot
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest
                 * @instance
                 */
                DeleteBusinessUnitSnapshotRequest.prototype.businessUnitSnapshot = null;

                /**
                 * Verifies a DeleteBusinessUnitSnapshotRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitSnapshotRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSnapshot.verify(message.businessUnitSnapshot);
                        if (error)
                            return "businessUnitSnapshot." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitSnapshotRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest} DeleteBusinessUnitSnapshotRequest
                 */
                DeleteBusinessUnitSnapshotRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest();
                    if (object.businessUnitSnapshot != null) {
                        if (typeof object.businessUnitSnapshot !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest.businessUnitSnapshot: object expected");
                        message.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.fromObject(object.businessUnitSnapshot);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitSnapshotRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest} message DeleteBusinessUnitSnapshotRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitSnapshotRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSnapshot = null;
                    if (message.businessUnitSnapshot != null && message.hasOwnProperty("businessUnitSnapshot"))
                        object.businessUnitSnapshot = $root.mtechnavi.api.company.BusinessUnitSnapshot.toObject(message.businessUnitSnapshot, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitSnapshotRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitSnapshotRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitSnapshotRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitSnapshotRequest;
            })();

            company.ListBusinessUnitChangeNotificationsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitChangeNotificationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitChangeNotificationsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitChangeNotificationsRequest pageToken
                 * @property {string|null} [baseTypeName] ListBusinessUnitChangeNotificationsRequest baseTypeName
                 * @property {Array.<string>|null} [baseRecordIds] ListBusinessUnitChangeNotificationsRequest baseRecordIds
                 * @property {string|null} [typeName] ListBusinessUnitChangeNotificationsRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListBusinessUnitChangeNotificationsRequest recordIds
                 */

                /**
                 * Constructs a new ListBusinessUnitChangeNotificationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitChangeNotificationsRequest.
                 * @implements IListBusinessUnitChangeNotificationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitChangeNotificationsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitChangeNotificationsRequest(properties) {
                    this.baseRecordIds = [];
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitChangeNotificationsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitChangeNotificationsRequest baseTypeName.
                 * @member {string} baseTypeName
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.baseTypeName = "";

                /**
                 * ListBusinessUnitChangeNotificationsRequest baseRecordIds.
                 * @member {Array.<string>} baseRecordIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.baseRecordIds = $util.emptyArray;

                /**
                 * ListBusinessUnitChangeNotificationsRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.typeName = "";

                /**
                 * ListBusinessUnitChangeNotificationsRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitChangeNotificationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitChangeNotificationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseTypeName != null && message.hasOwnProperty("baseTypeName"))
                        if (!$util.isString(message.baseTypeName))
                            return "baseTypeName: string expected";
                    if (message.baseRecordIds != null && message.hasOwnProperty("baseRecordIds")) {
                        if (!Array.isArray(message.baseRecordIds))
                            return "baseRecordIds: array expected";
                        for (let i = 0; i < message.baseRecordIds.length; ++i)
                            if (!$util.isString(message.baseRecordIds[i]))
                                return "baseRecordIds: string[] expected";
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitChangeNotificationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest} ListBusinessUnitChangeNotificationsRequest
                 */
                ListBusinessUnitChangeNotificationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseTypeName != null)
                        message.baseTypeName = String(object.baseTypeName);
                    if (object.baseRecordIds) {
                        if (!Array.isArray(object.baseRecordIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest.baseRecordIds: array expected");
                        message.baseRecordIds = [];
                        for (let i = 0; i < object.baseRecordIds.length; ++i)
                            message.baseRecordIds[i] = String(object.baseRecordIds[i]);
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitChangeNotificationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest} message ListBusinessUnitChangeNotificationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitChangeNotificationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.baseRecordIds = [];
                        object.recordIds = [];
                    }
                    if (options.defaults) {
                        object.pageToken = "";
                        object.baseTypeName = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseTypeName != null && message.hasOwnProperty("baseTypeName"))
                        object.baseTypeName = message.baseTypeName;
                    if (message.baseRecordIds && message.baseRecordIds.length) {
                        object.baseRecordIds = [];
                        for (let j = 0; j < message.baseRecordIds.length; ++j)
                            object.baseRecordIds[j] = message.baseRecordIds[j];
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitChangeNotificationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitChangeNotificationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitChangeNotificationsRequest;
            })();

            company.ListBusinessUnitChangeNotificationsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitChangeNotificationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitChangeNotificationsResponse
                 * @property {Long|null} [total] ListBusinessUnitChangeNotificationsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitChangeNotification>|null} [items] ListBusinessUnitChangeNotificationsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitChangeNotificationsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitChangeNotificationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitChangeNotificationsResponse.
                 * @implements IListBusinessUnitChangeNotificationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitChangeNotificationsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitChangeNotificationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitChangeNotificationsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitChangeNotificationsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitChangeNotification>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitChangeNotificationsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @instance
                 */
                ListBusinessUnitChangeNotificationsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitChangeNotificationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitChangeNotificationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitChangeNotification.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitChangeNotificationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse} ListBusinessUnitChangeNotificationsResponse
                 */
                ListBusinessUnitChangeNotificationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitChangeNotification.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitChangeNotificationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse} message ListBusinessUnitChangeNotificationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitChangeNotificationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitChangeNotification.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitChangeNotificationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitChangeNotificationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitChangeNotificationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitChangeNotificationsResponse;
            })();

            company.CreateBusinessUnitChangeNotificationRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitChangeNotificationRequest
                 * @property {mtechnavi.api.company.IBusinessUnitChangeNotification|null} [businessUnitChangeNotification] CreateBusinessUnitChangeNotificationRequest businessUnitChangeNotification
                 */

                /**
                 * Constructs a new CreateBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitChangeNotificationRequest.
                 * @implements ICreateBusinessUnitChangeNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitChangeNotificationRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitChangeNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitChangeNotificationRequest businessUnitChangeNotification.
                 * @member {mtechnavi.api.company.IBusinessUnitChangeNotification|null|undefined} businessUnitChangeNotification
                 * @memberof mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                CreateBusinessUnitChangeNotificationRequest.prototype.businessUnitChangeNotification = null;

                /**
                 * Verifies a CreateBusinessUnitChangeNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitChangeNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitChangeNotification.verify(message.businessUnitChangeNotification);
                        if (error)
                            return "businessUnitChangeNotification." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitChangeNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest} CreateBusinessUnitChangeNotificationRequest
                 */
                CreateBusinessUnitChangeNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest();
                    if (object.businessUnitChangeNotification != null) {
                        if (typeof object.businessUnitChangeNotification !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest.businessUnitChangeNotification: object expected");
                        message.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.fromObject(object.businessUnitChangeNotification);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitChangeNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest} message CreateBusinessUnitChangeNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitChangeNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitChangeNotification = null;
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification"))
                        object.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.toObject(message.businessUnitChangeNotification, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitChangeNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitChangeNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitChangeNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitChangeNotificationRequest;
            })();

            company.GetBusinessUnitChangeNotificationRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitChangeNotificationRequest
                 * @property {string|null} [businessUnitChangeNotificationId] GetBusinessUnitChangeNotificationRequest businessUnitChangeNotificationId
                 */

                /**
                 * Constructs a new GetBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitChangeNotificationRequest.
                 * @implements IGetBusinessUnitChangeNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitChangeNotificationRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitChangeNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitChangeNotificationRequest businessUnitChangeNotificationId.
                 * @member {string} businessUnitChangeNotificationId
                 * @memberof mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                GetBusinessUnitChangeNotificationRequest.prototype.businessUnitChangeNotificationId = "";

                /**
                 * Verifies a GetBusinessUnitChangeNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitChangeNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitChangeNotificationId != null && message.hasOwnProperty("businessUnitChangeNotificationId"))
                        if (!$util.isString(message.businessUnitChangeNotificationId))
                            return "businessUnitChangeNotificationId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitChangeNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest} GetBusinessUnitChangeNotificationRequest
                 */
                GetBusinessUnitChangeNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest();
                    if (object.businessUnitChangeNotificationId != null)
                        message.businessUnitChangeNotificationId = String(object.businessUnitChangeNotificationId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitChangeNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest} message GetBusinessUnitChangeNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitChangeNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitChangeNotificationId = "";
                    if (message.businessUnitChangeNotificationId != null && message.hasOwnProperty("businessUnitChangeNotificationId"))
                        object.businessUnitChangeNotificationId = message.businessUnitChangeNotificationId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitChangeNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitChangeNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitChangeNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitChangeNotificationRequest;
            })();

            company.UpdateBusinessUnitChangeNotificationRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitChangeNotificationRequest
                 * @property {mtechnavi.api.company.IBusinessUnitChangeNotification|null} [businessUnitChangeNotification] UpdateBusinessUnitChangeNotificationRequest businessUnitChangeNotification
                 */

                /**
                 * Constructs a new UpdateBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitChangeNotificationRequest.
                 * @implements IUpdateBusinessUnitChangeNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitChangeNotificationRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitChangeNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitChangeNotificationRequest businessUnitChangeNotification.
                 * @member {mtechnavi.api.company.IBusinessUnitChangeNotification|null|undefined} businessUnitChangeNotification
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                UpdateBusinessUnitChangeNotificationRequest.prototype.businessUnitChangeNotification = null;

                /**
                 * Verifies an UpdateBusinessUnitChangeNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitChangeNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitChangeNotification.verify(message.businessUnitChangeNotification);
                        if (error)
                            return "businessUnitChangeNotification." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitChangeNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest} UpdateBusinessUnitChangeNotificationRequest
                 */
                UpdateBusinessUnitChangeNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest();
                    if (object.businessUnitChangeNotification != null) {
                        if (typeof object.businessUnitChangeNotification !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest.businessUnitChangeNotification: object expected");
                        message.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.fromObject(object.businessUnitChangeNotification);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitChangeNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest} message UpdateBusinessUnitChangeNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitChangeNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitChangeNotification = null;
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification"))
                        object.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.toObject(message.businessUnitChangeNotification, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitChangeNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitChangeNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitChangeNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitChangeNotificationRequest;
            })();

            company.DeleteBusinessUnitChangeNotificationRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitChangeNotificationRequest
                 * @property {mtechnavi.api.company.IBusinessUnitChangeNotification|null} [businessUnitChangeNotification] DeleteBusinessUnitChangeNotificationRequest businessUnitChangeNotification
                 */

                /**
                 * Constructs a new DeleteBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitChangeNotificationRequest.
                 * @implements IDeleteBusinessUnitChangeNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitChangeNotificationRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitChangeNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitChangeNotificationRequest businessUnitChangeNotification.
                 * @member {mtechnavi.api.company.IBusinessUnitChangeNotification|null|undefined} businessUnitChangeNotification
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                DeleteBusinessUnitChangeNotificationRequest.prototype.businessUnitChangeNotification = null;

                /**
                 * Verifies a DeleteBusinessUnitChangeNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitChangeNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitChangeNotification.verify(message.businessUnitChangeNotification);
                        if (error)
                            return "businessUnitChangeNotification." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitChangeNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest} DeleteBusinessUnitChangeNotificationRequest
                 */
                DeleteBusinessUnitChangeNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest();
                    if (object.businessUnitChangeNotification != null) {
                        if (typeof object.businessUnitChangeNotification !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest.businessUnitChangeNotification: object expected");
                        message.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.fromObject(object.businessUnitChangeNotification);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitChangeNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest} message DeleteBusinessUnitChangeNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitChangeNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitChangeNotification = null;
                    if (message.businessUnitChangeNotification != null && message.hasOwnProperty("businessUnitChangeNotification"))
                        object.businessUnitChangeNotification = $root.mtechnavi.api.company.BusinessUnitChangeNotification.toObject(message.businessUnitChangeNotification, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitChangeNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitChangeNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitChangeNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitChangeNotificationRequest;
            })();

            company.ListBusinessUnitContactContentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactContentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitContactContentsRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitContactContentsRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactContentsRequest.
                 * @implements IListBusinessUnitContactContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactContentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitContactContentsRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @instance
                 */
                ListBusinessUnitContactContentsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitContactContentsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @instance
                 */
                ListBusinessUnitContactContentsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitContactContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactContentsRequest} ListBusinessUnitContactContentsRequest
                 */
                ListBusinessUnitContactContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactContentsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactContentsRequest} message ListBusinessUnitContactContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactContentsRequest;
            })();

            company.ListBusinessUnitContactContentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactContentsResponse
                 * @property {Long|null} [total] ListBusinessUnitContactContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitContactContent>|null} [items] ListBusinessUnitContactContentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitContactContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactContentsResponse.
                 * @implements IListBusinessUnitContactContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactContentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitContactContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListBusinessUnitContactContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitContactContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitContactContent>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListBusinessUnitContactContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitContactContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListBusinessUnitContactContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitContactContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContactContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactContentsResponse} ListBusinessUnitContactContentsResponse
                 */
                ListBusinessUnitContactContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitContactContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactContentsResponse} message ListBusinessUnitContactContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitContactContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactContentsResponse;
            })();

            company.GetBusinessUnitContactContentRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitContactContentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitContactContentRequest
                 * @property {string|null} [businessUnitContactId] GetBusinessUnitContactContentRequest businessUnitContactId
                 */

                /**
                 * Constructs a new GetBusinessUnitContactContentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitContactContentRequest.
                 * @implements IGetBusinessUnitContactContentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitContactContentRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitContactContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitContactContentRequest businessUnitContactId.
                 * @member {string} businessUnitContactId
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactContentRequest
                 * @instance
                 */
                GetBusinessUnitContactContentRequest.prototype.businessUnitContactId = "";

                /**
                 * Verifies a GetBusinessUnitContactContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitContactContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        if (!$util.isString(message.businessUnitContactId))
                            return "businessUnitContactId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitContactContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitContactContentRequest} GetBusinessUnitContactContentRequest
                 */
                GetBusinessUnitContactContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitContactContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitContactContentRequest();
                    if (object.businessUnitContactId != null)
                        message.businessUnitContactId = String(object.businessUnitContactId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitContactContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactContentRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitContactContentRequest} message GetBusinessUnitContactContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitContactContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactId = "";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        object.businessUnitContactId = message.businessUnitContactId;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitContactContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitContactContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitContactContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitContactContentRequest;
            })();

            company.GetBusinessUnitScoreContentRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitScoreContentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitScoreContentRequest
                 * @property {string|null} [code] GetBusinessUnitScoreContentRequest code
                 * @property {string|null} [category] GetBusinessUnitScoreContentRequest category
                 */

                /**
                 * Constructs a new GetBusinessUnitScoreContentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitScoreContentRequest.
                 * @implements IGetBusinessUnitScoreContentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitScoreContentRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitScoreContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBusinessUnitScoreContentRequest code.
                 * @member {string} code
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @instance
                 */
                GetBusinessUnitScoreContentRequest.prototype.code = "";

                /**
                 * GetBusinessUnitScoreContentRequest category.
                 * @member {string} category
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @instance
                 */
                GetBusinessUnitScoreContentRequest.prototype.category = "";

                /**
                 * Verifies a GetBusinessUnitScoreContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitScoreContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitScoreContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitScoreContentRequest} GetBusinessUnitScoreContentRequest
                 */
                GetBusinessUnitScoreContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitScoreContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBusinessUnitScoreContentRequest();
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBusinessUnitScoreContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitScoreContentRequest} message GetBusinessUnitScoreContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitScoreContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.code = "";
                        object.category = "";
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this GetBusinessUnitScoreContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitScoreContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitScoreContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitScoreContentRequest;
            })();

            company.SharedListBusinessUnitProfilesRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitProfilesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitProfilesRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitProfilesRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitProfilesRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitProfilesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitProfilesRequest.
                 * @implements ISharedListBusinessUnitProfilesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitProfilesRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitProfilesRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitProfilesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @instance
                 */
                SharedListBusinessUnitProfilesRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitProfilesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @instance
                 */
                SharedListBusinessUnitProfilesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitProfilesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitProfilesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitProfilesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitProfilesRequest} SharedListBusinessUnitProfilesRequest
                 */
                SharedListBusinessUnitProfilesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitProfilesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitProfilesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitProfilesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitProfilesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitProfilesRequest} message SharedListBusinessUnitProfilesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitProfilesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitProfilesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitProfilesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitProfilesRequest;
            })();

            company.SharedListBusinessUnitProfilesResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitProfilesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitProfilesResponse
                 * @property {Long|null} [total] SharedListBusinessUnitProfilesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitProfile>|null} [items] SharedListBusinessUnitProfilesResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitProfilesResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitProfilesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitProfilesResponse.
                 * @implements ISharedListBusinessUnitProfilesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitProfilesResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitProfilesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitProfilesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @instance
                 */
                SharedListBusinessUnitProfilesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitProfilesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitProfile>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @instance
                 */
                SharedListBusinessUnitProfilesResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitProfilesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @instance
                 */
                SharedListBusinessUnitProfilesResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitProfilesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitProfilesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitProfilesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitProfilesResponse} SharedListBusinessUnitProfilesResponse
                 */
                SharedListBusinessUnitProfilesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitProfilesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitProfilesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitProfilesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitProfilesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitProfilesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitProfilesResponse} message SharedListBusinessUnitProfilesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitProfilesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitProfilesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitProfilesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitProfilesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitProfilesResponse;
            })();

            company.SharedGetBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitProfileRequest
                 * @property {string|null} [businessUnitProfileId] SharedGetBusinessUnitProfileRequest businessUnitProfileId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitProfileRequest.
                 * @implements ISharedGetBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitProfileRequest businessUnitProfileId.
                 * @member {string} businessUnitProfileId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitProfileRequest
                 * @instance
                 */
                SharedGetBusinessUnitProfileRequest.prototype.businessUnitProfileId = "";

                /**
                 * Verifies a SharedGetBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        if (!$util.isString(message.businessUnitProfileId))
                            return "businessUnitProfileId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitProfileRequest} SharedGetBusinessUnitProfileRequest
                 */
                SharedGetBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitProfileRequest();
                    if (object.businessUnitProfileId != null)
                        message.businessUnitProfileId = String(object.businessUnitProfileId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitProfileRequest} message SharedGetBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitProfileId = "";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        object.businessUnitProfileId = message.businessUnitProfileId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitProfileRequest;
            })();

            company.SharedListBusinessUnitFinancialsRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitFinancialsRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitFinancialsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitFinancialsRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitFinancialsRequest.
                 * @implements ISharedListBusinessUnitFinancialsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFinancialsRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitFinancialsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitFinancialsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @instance
                 */
                SharedListBusinessUnitFinancialsRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitFinancialsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @instance
                 */
                SharedListBusinessUnitFinancialsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitFinancialsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitFinancialsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitFinancialsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest} SharedListBusinessUnitFinancialsRequest
                 */
                SharedListBusinessUnitFinancialsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitFinancialsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest} message SharedListBusinessUnitFinancialsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitFinancialsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitFinancialsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitFinancialsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitFinancialsRequest;
            })();

            company.SharedListBusinessUnitFinancialsResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitFinancialsResponse
                 * @property {Long|null} [total] SharedListBusinessUnitFinancialsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFinancial>|null} [items] SharedListBusinessUnitFinancialsResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitFinancialsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitFinancialsResponse.
                 * @implements ISharedListBusinessUnitFinancialsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFinancialsResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitFinancialsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitFinancialsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @instance
                 */
                SharedListBusinessUnitFinancialsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitFinancialsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFinancial>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @instance
                 */
                SharedListBusinessUnitFinancialsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitFinancialsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @instance
                 */
                SharedListBusinessUnitFinancialsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitFinancialsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitFinancialsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitFinancialsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse} SharedListBusinessUnitFinancialsResponse
                 */
                SharedListBusinessUnitFinancialsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitFinancialsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse} message SharedListBusinessUnitFinancialsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitFinancialsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitFinancialsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFinancialsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitFinancialsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitFinancialsResponse;
            })();

            company.SharedGetBusinessUnitFinancialRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitFinancialRequest
                 * @property {string|null} [businessUnitFinancialId] SharedGetBusinessUnitFinancialRequest businessUnitFinancialId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitFinancialRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitFinancialRequest.
                 * @implements ISharedGetBusinessUnitFinancialRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFinancialRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitFinancialRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitFinancialRequest businessUnitFinancialId.
                 * @member {string} businessUnitFinancialId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest
                 * @instance
                 */
                SharedGetBusinessUnitFinancialRequest.prototype.businessUnitFinancialId = "";

                /**
                 * Verifies a SharedGetBusinessUnitFinancialRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitFinancialRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        if (!$util.isString(message.businessUnitFinancialId))
                            return "businessUnitFinancialId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitFinancialRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest} SharedGetBusinessUnitFinancialRequest
                 */
                SharedGetBusinessUnitFinancialRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest();
                    if (object.businessUnitFinancialId != null)
                        message.businessUnitFinancialId = String(object.businessUnitFinancialId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitFinancialRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest} message SharedGetBusinessUnitFinancialRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitFinancialRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFinancialId = "";
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        object.businessUnitFinancialId = message.businessUnitFinancialId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitFinancialRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFinancialRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitFinancialRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitFinancialRequest;
            })();

            company.SharedListBusinessUnitBranchsRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitBranchsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitBranchsRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitBranchsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitBranchsRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitBranchsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitBranchsRequest.
                 * @implements ISharedListBusinessUnitBranchsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitBranchsRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitBranchsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitBranchsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @instance
                 */
                SharedListBusinessUnitBranchsRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitBranchsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @instance
                 */
                SharedListBusinessUnitBranchsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitBranchsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitBranchsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitBranchsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitBranchsRequest} SharedListBusinessUnitBranchsRequest
                 */
                SharedListBusinessUnitBranchsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitBranchsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitBranchsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitBranchsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitBranchsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitBranchsRequest} message SharedListBusinessUnitBranchsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitBranchsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitBranchsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitBranchsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitBranchsRequest;
            })();

            company.SharedListBusinessUnitBranchsResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitBranchsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitBranchsResponse
                 * @property {Long|null} [total] SharedListBusinessUnitBranchsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitBranch>|null} [items] SharedListBusinessUnitBranchsResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitBranchsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitBranchsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitBranchsResponse.
                 * @implements ISharedListBusinessUnitBranchsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitBranchsResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitBranchsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitBranchsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @instance
                 */
                SharedListBusinessUnitBranchsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitBranchsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitBranch>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @instance
                 */
                SharedListBusinessUnitBranchsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitBranchsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @instance
                 */
                SharedListBusinessUnitBranchsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitBranchsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitBranchsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitBranch.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitBranchsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitBranchsResponse} SharedListBusinessUnitBranchsResponse
                 */
                SharedListBusinessUnitBranchsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitBranchsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitBranchsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitBranchsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitBranchsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitBranch.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitBranchsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitBranchsResponse} message SharedListBusinessUnitBranchsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitBranchsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitBranch.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitBranchsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitBranchsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitBranchsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitBranchsResponse;
            })();

            company.SharedGetBusinessUnitBranchRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitBranchRequest
                 * @property {string|null} [businessUnitBranchId] SharedGetBusinessUnitBranchRequest businessUnitBranchId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitBranchRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitBranchRequest.
                 * @implements ISharedGetBusinessUnitBranchRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitBranchRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitBranchRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitBranchRequest businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitBranchRequest
                 * @instance
                 */
                SharedGetBusinessUnitBranchRequest.prototype.businessUnitBranchId = "";

                /**
                 * Verifies a SharedGetBusinessUnitBranchRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitBranchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitBranchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitBranchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitBranchRequest} SharedGetBusinessUnitBranchRequest
                 */
                SharedGetBusinessUnitBranchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitBranchRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitBranchRequest();
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitBranchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitBranchRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitBranchRequest} message SharedGetBusinessUnitBranchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitBranchRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitBranchId = "";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitBranchRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitBranchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitBranchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitBranchRequest;
            })();

            company.SharedListBusinessUnitFacilitiessRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitFacilitiessRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitFacilitiessRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitFacilitiessRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitFacilitiessRequest.
                 * @implements ISharedListBusinessUnitFacilitiessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFacilitiessRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitFacilitiessRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitFacilitiessRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @instance
                 */
                SharedListBusinessUnitFacilitiessRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitFacilitiessRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @instance
                 */
                SharedListBusinessUnitFacilitiessRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitFacilitiessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitFacilitiessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitFacilitiessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest} SharedListBusinessUnitFacilitiessRequest
                 */
                SharedListBusinessUnitFacilitiessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitFacilitiessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest} message SharedListBusinessUnitFacilitiessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitFacilitiessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitFacilitiessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitFacilitiessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitFacilitiessRequest;
            })();

            company.SharedListBusinessUnitFacilitiessResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitFacilitiessResponse
                 * @property {Long|null} [total] SharedListBusinessUnitFacilitiessResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFacilities>|null} [items] SharedListBusinessUnitFacilitiessResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitFacilitiessResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitFacilitiessResponse.
                 * @implements ISharedListBusinessUnitFacilitiessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitFacilitiessResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitFacilitiessResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitFacilitiessResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                SharedListBusinessUnitFacilitiessResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitFacilitiessResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFacilities>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                SharedListBusinessUnitFacilitiessResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitFacilitiessResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @instance
                 */
                SharedListBusinessUnitFacilitiessResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitFacilitiessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitFacilitiessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitFacilitiessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse} SharedListBusinessUnitFacilitiessResponse
                 */
                SharedListBusinessUnitFacilitiessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitFacilitiessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse} message SharedListBusinessUnitFacilitiessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitFacilitiessResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitFacilitiessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitFacilitiessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitFacilitiessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitFacilitiessResponse;
            })();

            company.SharedGetBusinessUnitFacilitiesRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitFacilitiesRequest
                 * @property {string|null} [businessUnitFacilitiesId] SharedGetBusinessUnitFacilitiesRequest businessUnitFacilitiesId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitFacilitiesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitFacilitiesRequest.
                 * @implements ISharedGetBusinessUnitFacilitiesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitFacilitiesRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitFacilitiesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitFacilitiesRequest businessUnitFacilitiesId.
                 * @member {string} businessUnitFacilitiesId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest
                 * @instance
                 */
                SharedGetBusinessUnitFacilitiesRequest.prototype.businessUnitFacilitiesId = "";

                /**
                 * Verifies a SharedGetBusinessUnitFacilitiesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitFacilitiesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        if (!$util.isString(message.businessUnitFacilitiesId))
                            return "businessUnitFacilitiesId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitFacilitiesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest} SharedGetBusinessUnitFacilitiesRequest
                 */
                SharedGetBusinessUnitFacilitiesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest();
                    if (object.businessUnitFacilitiesId != null)
                        message.businessUnitFacilitiesId = String(object.businessUnitFacilitiesId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitFacilitiesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest} message SharedGetBusinessUnitFacilitiesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitFacilitiesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitFacilitiesId = "";
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        object.businessUnitFacilitiesId = message.businessUnitFacilitiesId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitFacilitiesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitFacilitiesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitFacilitiesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitFacilitiesRequest;
            })();

            company.SharedListBusinessUnitCertificatesRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitCertificatesRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitCertificatesRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitCertificatesRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitCertificatesRequest.
                 * @implements ISharedListBusinessUnitCertificatesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitCertificatesRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitCertificatesRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitCertificatesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @instance
                 */
                SharedListBusinessUnitCertificatesRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitCertificatesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @instance
                 */
                SharedListBusinessUnitCertificatesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitCertificatesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitCertificatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitCertificatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest} SharedListBusinessUnitCertificatesRequest
                 */
                SharedListBusinessUnitCertificatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitCertificatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest} message SharedListBusinessUnitCertificatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitCertificatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitCertificatesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitCertificatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitCertificatesRequest;
            })();

            company.SharedListBusinessUnitCertificatesResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitCertificatesResponse
                 * @property {Long|null} [total] SharedListBusinessUnitCertificatesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitCertificate>|null} [items] SharedListBusinessUnitCertificatesResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitCertificatesResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitCertificatesResponse.
                 * @implements ISharedListBusinessUnitCertificatesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitCertificatesResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitCertificatesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitCertificatesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @instance
                 */
                SharedListBusinessUnitCertificatesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitCertificatesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitCertificate>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @instance
                 */
                SharedListBusinessUnitCertificatesResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitCertificatesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @instance
                 */
                SharedListBusinessUnitCertificatesResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitCertificatesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitCertificatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitCertificatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse} SharedListBusinessUnitCertificatesResponse
                 */
                SharedListBusinessUnitCertificatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitCertificatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse} message SharedListBusinessUnitCertificatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitCertificatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitCertificatesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitCertificatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitCertificatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitCertificatesResponse;
            })();

            company.SharedGetBusinessUnitCertificateRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitCertificateRequest
                 * @property {string|null} [businessUnitCertificateId] SharedGetBusinessUnitCertificateRequest businessUnitCertificateId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitCertificateRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitCertificateRequest.
                 * @implements ISharedGetBusinessUnitCertificateRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitCertificateRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitCertificateRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitCertificateRequest businessUnitCertificateId.
                 * @member {string} businessUnitCertificateId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest
                 * @instance
                 */
                SharedGetBusinessUnitCertificateRequest.prototype.businessUnitCertificateId = "";

                /**
                 * Verifies a SharedGetBusinessUnitCertificateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitCertificateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        if (!$util.isString(message.businessUnitCertificateId))
                            return "businessUnitCertificateId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitCertificateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest} SharedGetBusinessUnitCertificateRequest
                 */
                SharedGetBusinessUnitCertificateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest();
                    if (object.businessUnitCertificateId != null)
                        message.businessUnitCertificateId = String(object.businessUnitCertificateId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitCertificateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest} message SharedGetBusinessUnitCertificateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitCertificateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitCertificateId = "";
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        object.businessUnitCertificateId = message.businessUnitCertificateId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitCertificateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitCertificateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitCertificateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitCertificateRequest;
            })();

            company.SharedListBusinessUnitStrengthsRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitStrengthsRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitStrengthsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitStrengthsRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitStrengthsRequest.
                 * @implements ISharedListBusinessUnitStrengthsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitStrengthsRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitStrengthsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitStrengthsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @instance
                 */
                SharedListBusinessUnitStrengthsRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitStrengthsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @instance
                 */
                SharedListBusinessUnitStrengthsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitStrengthsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitStrengthsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitStrengthsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest} SharedListBusinessUnitStrengthsRequest
                 */
                SharedListBusinessUnitStrengthsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitStrengthsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest} message SharedListBusinessUnitStrengthsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitStrengthsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitStrengthsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitStrengthsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitStrengthsRequest;
            })();

            company.SharedListBusinessUnitStrengthsResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitStrengthsResponse
                 * @property {Long|null} [total] SharedListBusinessUnitStrengthsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitStrength>|null} [items] SharedListBusinessUnitStrengthsResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitStrengthsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitStrengthsResponse.
                 * @implements ISharedListBusinessUnitStrengthsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitStrengthsResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitStrengthsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitStrengthsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @instance
                 */
                SharedListBusinessUnitStrengthsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitStrengthsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitStrength>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @instance
                 */
                SharedListBusinessUnitStrengthsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitStrengthsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @instance
                 */
                SharedListBusinessUnitStrengthsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitStrengthsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitStrengthsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitStrengthsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse} SharedListBusinessUnitStrengthsResponse
                 */
                SharedListBusinessUnitStrengthsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitStrengthsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse} message SharedListBusinessUnitStrengthsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitStrengthsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitStrengthsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitStrengthsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitStrengthsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitStrengthsResponse;
            })();

            company.SharedGetBusinessUnitStrengthRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitStrengthRequest
                 * @property {string|null} [businessUnitStrengthId] SharedGetBusinessUnitStrengthRequest businessUnitStrengthId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitStrengthRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitStrengthRequest.
                 * @implements ISharedGetBusinessUnitStrengthRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitStrengthRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitStrengthRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitStrengthRequest businessUnitStrengthId.
                 * @member {string} businessUnitStrengthId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest
                 * @instance
                 */
                SharedGetBusinessUnitStrengthRequest.prototype.businessUnitStrengthId = "";

                /**
                 * Verifies a SharedGetBusinessUnitStrengthRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitStrengthRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        if (!$util.isString(message.businessUnitStrengthId))
                            return "businessUnitStrengthId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitStrengthRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest} SharedGetBusinessUnitStrengthRequest
                 */
                SharedGetBusinessUnitStrengthRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest();
                    if (object.businessUnitStrengthId != null)
                        message.businessUnitStrengthId = String(object.businessUnitStrengthId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitStrengthRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest} message SharedGetBusinessUnitStrengthRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitStrengthRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitStrengthId = "";
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        object.businessUnitStrengthId = message.businessUnitStrengthId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitStrengthRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitStrengthRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitStrengthRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitStrengthRequest;
            })();

            company.SharedListBusinessUnitSkillsRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitSkillsRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitSkillsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitSkillsRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitSkillsRequest.
                 * @implements ISharedListBusinessUnitSkillsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitSkillsRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitSkillsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitSkillsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @instance
                 */
                SharedListBusinessUnitSkillsRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitSkillsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @instance
                 */
                SharedListBusinessUnitSkillsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitSkillsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitSkillsRequest} SharedListBusinessUnitSkillsRequest
                 */
                SharedListBusinessUnitSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitSkillsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitSkillsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitSkillsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitSkillsRequest} message SharedListBusinessUnitSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitSkillsRequest;
            })();

            company.SharedListBusinessUnitSkillsResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitSkillsResponse
                 * @property {Long|null} [total] SharedListBusinessUnitSkillsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSkill>|null} [items] SharedListBusinessUnitSkillsResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitSkillsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitSkillsResponse.
                 * @implements ISharedListBusinessUnitSkillsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitSkillsResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitSkillsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitSkillsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @instance
                 */
                SharedListBusinessUnitSkillsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitSkillsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSkill>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @instance
                 */
                SharedListBusinessUnitSkillsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitSkillsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @instance
                 */
                SharedListBusinessUnitSkillsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitSkillsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitSkillsResponse} SharedListBusinessUnitSkillsResponse
                 */
                SharedListBusinessUnitSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitSkillsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitSkillsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitSkillsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitSkillsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitSkillsResponse} message SharedListBusinessUnitSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitSkillsResponse;
            })();

            company.SharedGetBusinessUnitSkillRequest = (function() {

                /**
                 * Properties of a SharedGetBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedGetBusinessUnitSkillRequest
                 * @property {string|null} [businessUnitSkillId] SharedGetBusinessUnitSkillRequest businessUnitSkillId
                 */

                /**
                 * Constructs a new SharedGetBusinessUnitSkillRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedGetBusinessUnitSkillRequest.
                 * @implements ISharedGetBusinessUnitSkillRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedGetBusinessUnitSkillRequest=} [properties] Properties to set
                 */
                function SharedGetBusinessUnitSkillRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetBusinessUnitSkillRequest businessUnitSkillId.
                 * @member {string} businessUnitSkillId
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitSkillRequest
                 * @instance
                 */
                SharedGetBusinessUnitSkillRequest.prototype.businessUnitSkillId = "";

                /**
                 * Verifies a SharedGetBusinessUnitSkillRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetBusinessUnitSkillRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        if (!$util.isString(message.businessUnitSkillId))
                            return "businessUnitSkillId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetBusinessUnitSkillRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitSkillRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedGetBusinessUnitSkillRequest} SharedGetBusinessUnitSkillRequest
                 */
                SharedGetBusinessUnitSkillRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedGetBusinessUnitSkillRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedGetBusinessUnitSkillRequest();
                    if (object.businessUnitSkillId != null)
                        message.businessUnitSkillId = String(object.businessUnitSkillId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetBusinessUnitSkillRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitSkillRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedGetBusinessUnitSkillRequest} message SharedGetBusinessUnitSkillRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetBusinessUnitSkillRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitSkillId = "";
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        object.businessUnitSkillId = message.businessUnitSkillId;
                    return object;
                };

                /**
                 * Converts this SharedGetBusinessUnitSkillRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedGetBusinessUnitSkillRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetBusinessUnitSkillRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetBusinessUnitSkillRequest;
            })();

            company.SharetoBusinessUnitContactHeaderRequest = (function() {

                /**
                 * Properties of a SharetoBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitContactHeaderRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContactHeader|null} [businessUnitContactHeader] SharetoBusinessUnitContactHeaderRequest businessUnitContactHeader
                 */

                /**
                 * Constructs a new SharetoBusinessUnitContactHeaderRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitContactHeaderRequest.
                 * @implements ISharetoBusinessUnitContactHeaderRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactHeaderRequest=} [properties] Properties to set
                 */
                function SharetoBusinessUnitContactHeaderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoBusinessUnitContactHeaderRequest businessUnitContactHeader.
                 * @member {mtechnavi.api.company.IBusinessUnitContactHeader|null|undefined} businessUnitContactHeader
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest
                 * @instance
                 */
                SharetoBusinessUnitContactHeaderRequest.prototype.businessUnitContactHeader = null;

                /**
                 * Verifies a SharetoBusinessUnitContactHeaderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitContactHeaderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.businessUnitContactHeader);
                        if (error)
                            return "businessUnitContactHeader." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitContactHeaderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest} SharetoBusinessUnitContactHeaderRequest
                 */
                SharetoBusinessUnitContactHeaderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest();
                    if (object.businessUnitContactHeader != null) {
                        if (typeof object.businessUnitContactHeader !== "object")
                            throw TypeError(".mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest.businessUnitContactHeader: object expected");
                        message.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.businessUnitContactHeader);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitContactHeaderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest} message SharetoBusinessUnitContactHeaderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitContactHeaderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContactHeader = null;
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader"))
                        object.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.businessUnitContactHeader, options);
                    return object;
                };

                /**
                 * Converts this SharetoBusinessUnitContactHeaderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitContactHeaderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitContactHeaderRequest;
            })();

            company.SharetoBusinessUnitContactHeaderResponse = (function() {

                /**
                 * Properties of a SharetoBusinessUnitContactHeaderResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitContactHeaderResponse
                 */

                /**
                 * Constructs a new SharetoBusinessUnitContactHeaderResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitContactHeaderResponse.
                 * @implements ISharetoBusinessUnitContactHeaderResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactHeaderResponse=} [properties] Properties to set
                 */
                function SharetoBusinessUnitContactHeaderResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoBusinessUnitContactHeaderResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitContactHeaderResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitContactHeaderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse} SharetoBusinessUnitContactHeaderResponse
                 */
                SharetoBusinessUnitContactHeaderResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse)
                        return object;
                    return new $root.mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse();
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitContactHeaderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse} message SharetoBusinessUnitContactHeaderResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitContactHeaderResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoBusinessUnitContactHeaderResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactHeaderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitContactHeaderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitContactHeaderResponse;
            })();

            company.SharetoBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a SharetoBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitContactRequest
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] SharetoBusinessUnitContactRequest businessUnitContact
                 */

                /**
                 * Constructs a new SharetoBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitContactRequest.
                 * @implements ISharetoBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function SharetoBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoBusinessUnitContactRequest businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactRequest
                 * @instance
                 */
                SharetoBusinessUnitContactRequest.prototype.businessUnitContact = null;

                /**
                 * Verifies a SharetoBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitContactRequest} SharetoBusinessUnitContactRequest
                 */
                SharetoBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharetoBusinessUnitContactRequest();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.SharetoBusinessUnitContactRequest.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactRequest} message SharetoBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitContact = null;
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    return object;
                };

                /**
                 * Converts this SharetoBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitContactRequest;
            })();

            company.SharetoBusinessUnitContactResponse = (function() {

                /**
                 * Properties of a SharetoBusinessUnitContactResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharetoBusinessUnitContactResponse
                 */

                /**
                 * Constructs a new SharetoBusinessUnitContactResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharetoBusinessUnitContactResponse.
                 * @implements ISharetoBusinessUnitContactResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharetoBusinessUnitContactResponse=} [properties] Properties to set
                 */
                function SharetoBusinessUnitContactResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoBusinessUnitContactResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoBusinessUnitContactResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoBusinessUnitContactResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharetoBusinessUnitContactResponse} SharetoBusinessUnitContactResponse
                 */
                SharetoBusinessUnitContactResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharetoBusinessUnitContactResponse)
                        return object;
                    return new $root.mtechnavi.api.company.SharetoBusinessUnitContactResponse();
                };

                /**
                 * Creates a plain object from a SharetoBusinessUnitContactResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactResponse
                 * @static
                 * @param {mtechnavi.api.company.SharetoBusinessUnitContactResponse} message SharetoBusinessUnitContactResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoBusinessUnitContactResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoBusinessUnitContactResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharetoBusinessUnitContactResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoBusinessUnitContactResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoBusinessUnitContactResponse;
            })();

            company.StartBusinessRequest = (function() {

                /**
                 * Properties of a StartBusinessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IStartBusinessRequest
                 * @property {string|null} [businessUnitManagementId] StartBusinessRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new StartBusinessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StartBusinessRequest.
                 * @implements IStartBusinessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IStartBusinessRequest=} [properties] Properties to set
                 */
                function StartBusinessRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StartBusinessRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.StartBusinessRequest
                 * @instance
                 */
                StartBusinessRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a StartBusinessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.StartBusinessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartBusinessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a StartBusinessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.StartBusinessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.StartBusinessRequest} StartBusinessRequest
                 */
                StartBusinessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.StartBusinessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.StartBusinessRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a StartBusinessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.StartBusinessRequest
                 * @static
                 * @param {mtechnavi.api.company.StartBusinessRequest} message StartBusinessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartBusinessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagementId = "";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this StartBusinessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.StartBusinessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartBusinessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartBusinessRequest;
            })();

            company.StartBusinessResponse = (function() {

                /**
                 * Properties of a StartBusinessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IStartBusinessResponse
                 */

                /**
                 * Constructs a new StartBusinessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StartBusinessResponse.
                 * @implements IStartBusinessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IStartBusinessResponse=} [properties] Properties to set
                 */
                function StartBusinessResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a StartBusinessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.StartBusinessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartBusinessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StartBusinessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.StartBusinessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.StartBusinessResponse} StartBusinessResponse
                 */
                StartBusinessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.StartBusinessResponse)
                        return object;
                    return new $root.mtechnavi.api.company.StartBusinessResponse();
                };

                /**
                 * Creates a plain object from a StartBusinessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.StartBusinessResponse
                 * @static
                 * @param {mtechnavi.api.company.StartBusinessResponse} message StartBusinessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartBusinessResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StartBusinessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.StartBusinessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartBusinessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartBusinessResponse;
            })();

            company.StopBusinessRequest = (function() {

                /**
                 * Properties of a StopBusinessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IStopBusinessRequest
                 * @property {string|null} [businessUnitManagementId] StopBusinessRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new StopBusinessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StopBusinessRequest.
                 * @implements IStopBusinessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IStopBusinessRequest=} [properties] Properties to set
                 */
                function StopBusinessRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StopBusinessRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.StopBusinessRequest
                 * @instance
                 */
                StopBusinessRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a StopBusinessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.StopBusinessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StopBusinessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a StopBusinessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.StopBusinessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.StopBusinessRequest} StopBusinessRequest
                 */
                StopBusinessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.StopBusinessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.StopBusinessRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a StopBusinessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.StopBusinessRequest
                 * @static
                 * @param {mtechnavi.api.company.StopBusinessRequest} message StopBusinessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StopBusinessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitManagementId = "";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this StopBusinessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.StopBusinessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StopBusinessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StopBusinessRequest;
            })();

            company.StopBusinessResponse = (function() {

                /**
                 * Properties of a StopBusinessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IStopBusinessResponse
                 */

                /**
                 * Constructs a new StopBusinessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StopBusinessResponse.
                 * @implements IStopBusinessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IStopBusinessResponse=} [properties] Properties to set
                 */
                function StopBusinessResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a StopBusinessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.StopBusinessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StopBusinessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StopBusinessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.StopBusinessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.StopBusinessResponse} StopBusinessResponse
                 */
                StopBusinessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.StopBusinessResponse)
                        return object;
                    return new $root.mtechnavi.api.company.StopBusinessResponse();
                };

                /**
                 * Creates a plain object from a StopBusinessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.StopBusinessResponse
                 * @static
                 * @param {mtechnavi.api.company.StopBusinessResponse} message StopBusinessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StopBusinessResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StopBusinessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.StopBusinessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StopBusinessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StopBusinessResponse;
            })();

            company.ConfirmBusinessUnitChangeNotificationRequest = (function() {

                /**
                 * Properties of a ConfirmBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IConfirmBusinessUnitChangeNotificationRequest
                 * @property {string|null} [typeName] ConfirmBusinessUnitChangeNotificationRequest typeName
                 * @property {Array.<string>|null} [recordIds] ConfirmBusinessUnitChangeNotificationRequest recordIds
                 */

                /**
                 * Constructs a new ConfirmBusinessUnitChangeNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ConfirmBusinessUnitChangeNotificationRequest.
                 * @implements IConfirmBusinessUnitChangeNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationRequest=} [properties] Properties to set
                 */
                function ConfirmBusinessUnitChangeNotificationRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ConfirmBusinessUnitChangeNotificationRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                ConfirmBusinessUnitChangeNotificationRequest.prototype.typeName = "";

                /**
                 * ConfirmBusinessUnitChangeNotificationRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @instance
                 */
                ConfirmBusinessUnitChangeNotificationRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ConfirmBusinessUnitChangeNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmBusinessUnitChangeNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ConfirmBusinessUnitChangeNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest} ConfirmBusinessUnitChangeNotificationRequest
                 */
                ConfirmBusinessUnitChangeNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest();
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ConfirmBusinessUnitChangeNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest} message ConfirmBusinessUnitChangeNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmBusinessUnitChangeNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults)
                        object.typeName = "";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ConfirmBusinessUnitChangeNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmBusinessUnitChangeNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ConfirmBusinessUnitChangeNotificationRequest;
            })();

            company.ConfirmBusinessUnitChangeNotificationResponse = (function() {

                /**
                 * Properties of a ConfirmBusinessUnitChangeNotificationResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IConfirmBusinessUnitChangeNotificationResponse
                 */

                /**
                 * Constructs a new ConfirmBusinessUnitChangeNotificationResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ConfirmBusinessUnitChangeNotificationResponse.
                 * @implements IConfirmBusinessUnitChangeNotificationResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IConfirmBusinessUnitChangeNotificationResponse=} [properties] Properties to set
                 */
                function ConfirmBusinessUnitChangeNotificationResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ConfirmBusinessUnitChangeNotificationResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmBusinessUnitChangeNotificationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ConfirmBusinessUnitChangeNotificationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse} ConfirmBusinessUnitChangeNotificationResponse
                 */
                ConfirmBusinessUnitChangeNotificationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse)
                        return object;
                    return new $root.mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse();
                };

                /**
                 * Creates a plain object from a ConfirmBusinessUnitChangeNotificationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse
                 * @static
                 * @param {mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse} message ConfirmBusinessUnitChangeNotificationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmBusinessUnitChangeNotificationResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ConfirmBusinessUnitChangeNotificationResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ConfirmBusinessUnitChangeNotificationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmBusinessUnitChangeNotificationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ConfirmBusinessUnitChangeNotificationResponse;
            })();

            company.ExportBusinessUnitManagementsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitManagementsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitManagementsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitManagementsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitManagementsRequest.
                 * @implements IExportBusinessUnitManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitManagementsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitManagementsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @instance
                 */
                ExportBusinessUnitManagementsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitManagementsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @instance
                 */
                ExportBusinessUnitManagementsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitManagementsRequest} ExportBusinessUnitManagementsRequest
                 */
                ExportBusinessUnitManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitManagementsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitManagementsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitManagementsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementsRequest} message ExportBusinessUnitManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitManagementsRequest;
            })();

            company.ExportBusinessUnitManagementsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitManagementsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitManagementsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitManagementsResponse.
                 * @implements IExportBusinessUnitManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitManagementsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitManagementsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsResponse
                 * @instance
                 */
                ExportBusinessUnitManagementsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitManagementsResponse} ExportBusinessUnitManagementsResponse
                 */
                ExportBusinessUnitManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitManagementsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementsResponse} message ExportBusinessUnitManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitManagementsResponse;
            })();

            company.ImportBusinessUnitManagementsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitManagementsRequest
                 * @property {string|null} [url] ImportBusinessUnitManagementsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitManagementsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitManagementsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitManagementsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitManagementsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitManagementsRequest.
                 * @implements IImportBusinessUnitManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitManagementsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitManagementsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportBusinessUnitManagementsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitManagementsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportBusinessUnitManagementsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitManagementsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportBusinessUnitManagementsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitManagementsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @instance
                 */
                ImportBusinessUnitManagementsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitManagementsRequest} ImportBusinessUnitManagementsRequest
                 */
                ImportBusinessUnitManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitManagementsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementsRequest} message ImportBusinessUnitManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitManagementsRequest;
            })();

            company.ImportBusinessUnitManagementsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitManagementsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitManagementsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitManagementsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitManagementsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitManagementsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitManagementsResponse.
                 * @implements IImportBusinessUnitManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitManagementsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitManagementsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportBusinessUnitManagementsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitManagementsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportBusinessUnitManagementsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitManagementsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @instance
                 */
                ImportBusinessUnitManagementsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitManagementsResponse} ImportBusinessUnitManagementsResponse
                 */
                ImportBusinessUnitManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitManagementsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementsResponse} message ImportBusinessUnitManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitManagementsResponse;
            })();

            company.ListBusinessUnitManagementFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementFormatsRequest.
                 * @implements IListBusinessUnitManagementFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitManagementFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest} ListBusinessUnitManagementFormatsRequest
                 */
                ListBusinessUnitManagementFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest} message ListBusinessUnitManagementFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitManagementFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementFormatsRequest;
            })();

            company.ListBusinessUnitManagementFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitManagementFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitManagementFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementFormatsResponse.
                 * @implements IListBusinessUnitManagementFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @instance
                 */
                ListBusinessUnitManagementFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitManagementFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @instance
                 */
                ListBusinessUnitManagementFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitManagementFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse} ListBusinessUnitManagementFormatsResponse
                 */
                ListBusinessUnitManagementFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse} message ListBusinessUnitManagementFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementFormatsResponse;
            })();

            company.ExportBusinessUnitScoresRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitScoresRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitScoresRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitScoresRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitScoresRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitScoresRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitScoresRequest.
                 * @implements IExportBusinessUnitScoresRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitScoresRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitScoresRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitScoresRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @instance
                 */
                ExportBusinessUnitScoresRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitScoresRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @instance
                 */
                ExportBusinessUnitScoresRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitScoresRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitScoresRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitScoresRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitScoresRequest} ExportBusinessUnitScoresRequest
                 */
                ExportBusinessUnitScoresRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitScoresRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitScoresRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitScoresRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitScoresRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitScoresRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitScoresRequest} message ExportBusinessUnitScoresRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitScoresRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitScoresRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitScoresRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitScoresRequest;
            })();

            company.ExportBusinessUnitScoresResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitScoresResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitScoresResponse
                 * @property {string|null} [assetId] ExportBusinessUnitScoresResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitScoresResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitScoresResponse.
                 * @implements IExportBusinessUnitScoresResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitScoresResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitScoresResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitScoresResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresResponse
                 * @instance
                 */
                ExportBusinessUnitScoresResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitScoresResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitScoresResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitScoresResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitScoresResponse} ExportBusinessUnitScoresResponse
                 */
                ExportBusinessUnitScoresResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitScoresResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitScoresResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitScoresResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitScoresResponse} message ExportBusinessUnitScoresResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitScoresResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitScoresResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitScoresResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitScoresResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitScoresResponse;
            })();

            company.ImportBusinessUnitScoresRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitScoresRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitScoresRequest
                 * @property {string|null} [url] ImportBusinessUnitScoresRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitScoresRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitScoresRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitScoresRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitScoresRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitScoresRequest.
                 * @implements IImportBusinessUnitScoresRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitScoresRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitScoresRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitScoresRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @instance
                 */
                ImportBusinessUnitScoresRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitScoresRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @instance
                 */
                ImportBusinessUnitScoresRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitScoresRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @instance
                 */
                ImportBusinessUnitScoresRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitScoresRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @instance
                 */
                ImportBusinessUnitScoresRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitScoresRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitScoresRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitScoresRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitScoresRequest} ImportBusinessUnitScoresRequest
                 */
                ImportBusinessUnitScoresRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitScoresRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitScoresRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitScoresRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitScoresRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitScoresRequest} message ImportBusinessUnitScoresRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitScoresRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitScoresRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitScoresRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitScoresRequest;
            })();

            company.ImportBusinessUnitScoresResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitScoresResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitScoresResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitScoresResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitScoresResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitScoresResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitScoresResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitScoresResponse.
                 * @implements IImportBusinessUnitScoresResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitScoresResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitScoresResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitScoresResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @instance
                 */
                ImportBusinessUnitScoresResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitScoresResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @instance
                 */
                ImportBusinessUnitScoresResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitScoresResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @instance
                 */
                ImportBusinessUnitScoresResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitScoresResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitScoresResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitScoresResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitScoresResponse} ImportBusinessUnitScoresResponse
                 */
                ImportBusinessUnitScoresResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitScoresResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitScoresResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitScoresResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitScoresResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitScoresResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitScoresResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitScoresResponse} message ImportBusinessUnitScoresResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitScoresResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitScoresResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitScoresResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitScoresResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitScoresResponse;
            })();

            company.ListBusinessUnitScoreFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitScoreFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitScoreFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitScoreFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitScoreFormatsRequest.
                 * @implements IListBusinessUnitScoreFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitScoreFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitScoreFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitScoreFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitScoreFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitScoreFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest} ListBusinessUnitScoreFormatsRequest
                 */
                ListBusinessUnitScoreFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitScoreFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest} message ListBusinessUnitScoreFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitScoreFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitScoreFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitScoreFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitScoreFormatsRequest;
            })();

            company.ListBusinessUnitScoreFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitScoreFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitScoreFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitScoreFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitScoreFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitScoreFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitScoreFormatsResponse.
                 * @implements IListBusinessUnitScoreFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitScoreFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitScoreFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitScoreFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @instance
                 */
                ListBusinessUnitScoreFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitScoreFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @instance
                 */
                ListBusinessUnitScoreFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitScoreFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitScoreFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitScoreFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse} ListBusinessUnitScoreFormatsResponse
                 */
                ListBusinessUnitScoreFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitScoreFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse} message ListBusinessUnitScoreFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitScoreFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitScoreFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitScoreFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitScoreFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitScoreFormatsResponse;
            })();

            company.ExportBusinessUnitManagementItemValuesRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitManagementItemValuesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitManagementItemValuesRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitManagementItemValuesRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitManagementItemValuesRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitManagementItemValuesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitManagementItemValuesRequest.
                 * @implements IExportBusinessUnitManagementItemValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementItemValuesRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitManagementItemValuesRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitManagementItemValuesRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ExportBusinessUnitManagementItemValuesRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitManagementItemValuesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ExportBusinessUnitManagementItemValuesRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitManagementItemValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitManagementItemValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitManagementItemValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest} ExportBusinessUnitManagementItemValuesRequest
                 */
                ExportBusinessUnitManagementItemValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitManagementItemValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest} message ExportBusinessUnitManagementItemValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitManagementItemValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitManagementItemValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitManagementItemValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitManagementItemValuesRequest;
            })();

            company.ExportBusinessUnitManagementItemValuesResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitManagementItemValuesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitManagementItemValuesResponse
                 * @property {string|null} [assetId] ExportBusinessUnitManagementItemValuesResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitManagementItemValuesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitManagementItemValuesResponse.
                 * @implements IExportBusinessUnitManagementItemValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitManagementItemValuesResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitManagementItemValuesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitManagementItemValuesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse
                 * @instance
                 */
                ExportBusinessUnitManagementItemValuesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitManagementItemValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitManagementItemValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitManagementItemValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse} ExportBusinessUnitManagementItemValuesResponse
                 */
                ExportBusinessUnitManagementItemValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitManagementItemValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse} message ExportBusinessUnitManagementItemValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitManagementItemValuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitManagementItemValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitManagementItemValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitManagementItemValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitManagementItemValuesResponse;
            })();

            company.ImportBusinessUnitManagementItemValuesRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitManagementItemValuesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitManagementItemValuesRequest
                 * @property {string|null} [url] ImportBusinessUnitManagementItemValuesRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitManagementItemValuesRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitManagementItemValuesRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitManagementItemValuesRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitManagementItemValuesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitManagementItemValuesRequest.
                 * @implements IImportBusinessUnitManagementItemValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementItemValuesRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitManagementItemValuesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitManagementItemValuesRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitManagementItemValuesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitManagementItemValuesRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitManagementItemValuesRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitManagementItemValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitManagementItemValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitManagementItemValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest} ImportBusinessUnitManagementItemValuesRequest
                 */
                ImportBusinessUnitManagementItemValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitManagementItemValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest} message ImportBusinessUnitManagementItemValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitManagementItemValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitManagementItemValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitManagementItemValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitManagementItemValuesRequest;
            })();

            company.ImportBusinessUnitManagementItemValuesResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitManagementItemValuesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitManagementItemValuesResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitManagementItemValuesResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitManagementItemValuesResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitManagementItemValuesResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitManagementItemValuesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitManagementItemValuesResponse.
                 * @implements IImportBusinessUnitManagementItemValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitManagementItemValuesResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitManagementItemValuesResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitManagementItemValuesResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitManagementItemValuesResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitManagementItemValuesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @instance
                 */
                ImportBusinessUnitManagementItemValuesResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitManagementItemValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitManagementItemValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitManagementItemValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse} ImportBusinessUnitManagementItemValuesResponse
                 */
                ImportBusinessUnitManagementItemValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitManagementItemValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse} message ImportBusinessUnitManagementItemValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitManagementItemValuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitManagementItemValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitManagementItemValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitManagementItemValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitManagementItemValuesResponse;
            })();

            company.ListBusinessUnitManagementItemValueFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementItemValueFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementItemValueFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementItemValueFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementItemValueFormatsRequest.
                 * @implements IListBusinessUnitManagementItemValueFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementItemValueFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementItemValueFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitManagementItemValueFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementItemValueFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementItemValueFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest} ListBusinessUnitManagementItemValueFormatsRequest
                 */
                ListBusinessUnitManagementItemValueFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementItemValueFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest} message ListBusinessUnitManagementItemValueFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementItemValueFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitManagementItemValueFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementItemValueFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementItemValueFormatsRequest;
            })();

            company.ListBusinessUnitManagementItemValueFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitManagementItemValueFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitManagementItemValueFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitManagementItemValueFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitManagementItemValueFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitManagementItemValueFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitManagementItemValueFormatsResponse.
                 * @implements IListBusinessUnitManagementItemValueFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitManagementItemValueFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitManagementItemValueFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitManagementItemValueFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @instance
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitManagementItemValueFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @instance
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitManagementItemValueFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitManagementItemValueFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse} ListBusinessUnitManagementItemValueFormatsResponse
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitManagementItemValueFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse} message ListBusinessUnitManagementItemValueFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitManagementItemValueFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitManagementItemValueFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitManagementItemValueFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitManagementItemValueFormatsResponse;
            })();

            company.GetBusinessUnitSkillTreeRequest = (function() {

                /**
                 * Properties of a GetBusinessUnitSkillTreeRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBusinessUnitSkillTreeRequest
                 */

                /**
                 * Constructs a new GetBusinessUnitSkillTreeRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBusinessUnitSkillTreeRequest.
                 * @implements IGetBusinessUnitSkillTreeRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBusinessUnitSkillTreeRequest=} [properties] Properties to set
                 */
                function GetBusinessUnitSkillTreeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a GetBusinessUnitSkillTreeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillTreeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBusinessUnitSkillTreeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetBusinessUnitSkillTreeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillTreeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBusinessUnitSkillTreeRequest} GetBusinessUnitSkillTreeRequest
                 */
                GetBusinessUnitSkillTreeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBusinessUnitSkillTreeRequest)
                        return object;
                    return new $root.mtechnavi.api.company.GetBusinessUnitSkillTreeRequest();
                };

                /**
                 * Creates a plain object from a GetBusinessUnitSkillTreeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillTreeRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBusinessUnitSkillTreeRequest} message GetBusinessUnitSkillTreeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBusinessUnitSkillTreeRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetBusinessUnitSkillTreeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBusinessUnitSkillTreeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBusinessUnitSkillTreeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBusinessUnitSkillTreeRequest;
            })();

            company.UnshareRecordRequest = (function() {

                /**
                 * Properties of an UnshareRecordRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUnshareRecordRequest
                 * @property {string|null} [tenantId] UnshareRecordRequest tenantId
                 * @property {string|null} [typeName] UnshareRecordRequest typeName
                 * @property {string|null} [recordId] UnshareRecordRequest recordId
                 */

                /**
                 * Constructs a new UnshareRecordRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UnshareRecordRequest.
                 * @implements IUnshareRecordRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUnshareRecordRequest=} [properties] Properties to set
                 */
                function UnshareRecordRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnshareRecordRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @instance
                 */
                UnshareRecordRequest.prototype.tenantId = "";

                /**
                 * UnshareRecordRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @instance
                 */
                UnshareRecordRequest.prototype.typeName = "";

                /**
                 * UnshareRecordRequest recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @instance
                 */
                UnshareRecordRequest.prototype.recordId = "";

                /**
                 * Verifies an UnshareRecordRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnshareRecordRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    return null;
                };

                /**
                 * Creates an UnshareRecordRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UnshareRecordRequest} UnshareRecordRequest
                 */
                UnshareRecordRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UnshareRecordRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UnshareRecordRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    return message;
                };

                /**
                 * Creates a plain object from an UnshareRecordRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @static
                 * @param {mtechnavi.api.company.UnshareRecordRequest} message UnshareRecordRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnshareRecordRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    return object;
                };

                /**
                 * Converts this UnshareRecordRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UnshareRecordRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnshareRecordRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnshareRecordRequest;
            })();

            company.ListSharedContactContentsRequest = (function() {

                /**
                 * Properties of a ListSharedContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListSharedContactContentsRequest
                 * @property {string|null} [pageToken] ListSharedContactContentsRequest pageToken
                 */

                /**
                 * Constructs a new ListSharedContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListSharedContactContentsRequest.
                 * @implements IListSharedContactContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListSharedContactContentsRequest=} [properties] Properties to set
                 */
                function ListSharedContactContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSharedContactContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListSharedContactContentsRequest
                 * @instance
                 */
                ListSharedContactContentsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListSharedContactContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListSharedContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSharedContactContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSharedContactContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListSharedContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListSharedContactContentsRequest} ListSharedContactContentsRequest
                 */
                ListSharedContactContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListSharedContactContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListSharedContactContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSharedContactContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListSharedContactContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListSharedContactContentsRequest} message ListSharedContactContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSharedContactContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSharedContactContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListSharedContactContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSharedContactContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSharedContactContentsRequest;
            })();

            company.ListSharedContactContentsResponse = (function() {

                /**
                 * Properties of a ListSharedContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListSharedContactContentsResponse
                 * @property {Long|null} [total] ListSharedContactContentsResponse total
                 * @property {Array.<mtechnavi.api.company.ISharedContactContent>|null} [items] ListSharedContactContentsResponse items
                 * @property {string|null} [pageToken] ListSharedContactContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListSharedContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListSharedContactContentsResponse.
                 * @implements IListSharedContactContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListSharedContactContentsResponse=} [properties] Properties to set
                 */
                function ListSharedContactContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSharedContactContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @instance
                 */
                ListSharedContactContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSharedContactContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.ISharedContactContent>} items
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @instance
                 */
                ListSharedContactContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSharedContactContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @instance
                 */
                ListSharedContactContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSharedContactContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSharedContactContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.SharedContactContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSharedContactContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListSharedContactContentsResponse} ListSharedContactContentsResponse
                 */
                ListSharedContactContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListSharedContactContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListSharedContactContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListSharedContactContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListSharedContactContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.SharedContactContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSharedContactContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListSharedContactContentsResponse} message ListSharedContactContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSharedContactContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.SharedContactContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSharedContactContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListSharedContactContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSharedContactContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSharedContactContentsResponse;
            })();

            company.CreateBusinessUnitRankReceptionRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitRankReceptionRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRankReception|null} [businessUnitRankReception] CreateBusinessUnitRankReceptionRequest businessUnitRankReception
                 */

                /**
                 * Constructs a new CreateBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitRankReceptionRequest.
                 * @implements ICreateBusinessUnitRankReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitRankReceptionRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitRankReceptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitRankReceptionRequest businessUnitRankReception.
                 * @member {mtechnavi.api.company.IBusinessUnitRankReception|null|undefined} businessUnitRankReception
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest
                 * @instance
                 */
                CreateBusinessUnitRankReceptionRequest.prototype.businessUnitRankReception = null;

                /**
                 * Verifies a CreateBusinessUnitRankReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitRankReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.verify(message.businessUnitRankReception);
                        if (error)
                            return "businessUnitRankReception." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitRankReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest} CreateBusinessUnitRankReceptionRequest
                 */
                CreateBusinessUnitRankReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest();
                    if (object.businessUnitRankReception != null) {
                        if (typeof object.businessUnitRankReception !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest.businessUnitRankReception: object expected");
                        message.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.fromObject(object.businessUnitRankReception);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitRankReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest} message CreateBusinessUnitRankReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitRankReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRankReception = null;
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception"))
                        object.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.toObject(message.businessUnitRankReception, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitRankReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitRankReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitRankReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitRankReceptionRequest;
            })();

            company.UpdateBusinessUnitRankReceptionRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitRankReceptionRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRankReception|null} [businessUnitRankReception] UpdateBusinessUnitRankReceptionRequest businessUnitRankReception
                 */

                /**
                 * Constructs a new UpdateBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitRankReceptionRequest.
                 * @implements IUpdateBusinessUnitRankReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitRankReceptionRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitRankReceptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitRankReceptionRequest businessUnitRankReception.
                 * @member {mtechnavi.api.company.IBusinessUnitRankReception|null|undefined} businessUnitRankReception
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest
                 * @instance
                 */
                UpdateBusinessUnitRankReceptionRequest.prototype.businessUnitRankReception = null;

                /**
                 * Verifies an UpdateBusinessUnitRankReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitRankReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.verify(message.businessUnitRankReception);
                        if (error)
                            return "businessUnitRankReception." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitRankReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest} UpdateBusinessUnitRankReceptionRequest
                 */
                UpdateBusinessUnitRankReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest();
                    if (object.businessUnitRankReception != null) {
                        if (typeof object.businessUnitRankReception !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest.businessUnitRankReception: object expected");
                        message.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.fromObject(object.businessUnitRankReception);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitRankReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest} message UpdateBusinessUnitRankReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitRankReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRankReception = null;
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception"))
                        object.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.toObject(message.businessUnitRankReception, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitRankReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitRankReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitRankReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitRankReceptionRequest;
            })();

            company.DeleteBusinessUnitRankReceptionRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitRankReceptionRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRankReception|null} [businessUnitRankReception] DeleteBusinessUnitRankReceptionRequest businessUnitRankReception
                 */

                /**
                 * Constructs a new DeleteBusinessUnitRankReceptionRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitRankReceptionRequest.
                 * @implements IDeleteBusinessUnitRankReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitRankReceptionRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitRankReceptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitRankReceptionRequest businessUnitRankReception.
                 * @member {mtechnavi.api.company.IBusinessUnitRankReception|null|undefined} businessUnitRankReception
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest
                 * @instance
                 */
                DeleteBusinessUnitRankReceptionRequest.prototype.businessUnitRankReception = null;

                /**
                 * Verifies a DeleteBusinessUnitRankReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitRankReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.verify(message.businessUnitRankReception);
                        if (error)
                            return "businessUnitRankReception." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitRankReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest} DeleteBusinessUnitRankReceptionRequest
                 */
                DeleteBusinessUnitRankReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest();
                    if (object.businessUnitRankReception != null) {
                        if (typeof object.businessUnitRankReception !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest.businessUnitRankReception: object expected");
                        message.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.fromObject(object.businessUnitRankReception);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitRankReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest} message DeleteBusinessUnitRankReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitRankReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitRankReception = null;
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception"))
                        object.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.toObject(message.businessUnitRankReception, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitRankReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitRankReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitRankReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitRankReceptionRequest;
            })();

            company.ListBusinessUnitRankRequestContentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRankRequestContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankRequestContentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitRankRequestContentsRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitRankRequestContentsRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitRankRequestContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankRequestContentsRequest.
                 * @implements IListBusinessUnitRankRequestContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestContentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRankRequestContentsRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankRequestContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @instance
                 */
                ListBusinessUnitRankRequestContentsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitRankRequestContentsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @instance
                 */
                ListBusinessUnitRankRequestContentsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRankRequestContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankRequestContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankRequestContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest} ListBusinessUnitRankRequestContentsRequest
                 */
                ListBusinessUnitRankRequestContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankRequestContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest} message ListBusinessUnitRankRequestContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankRequestContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankRequestContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankRequestContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankRequestContentsRequest;
            })();

            company.ListBusinessUnitRankRequestContentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRankRequestContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankRequestContentsResponse
                 * @property {Long|null} [total] ListBusinessUnitRankRequestContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitRankContent>|null} [items] ListBusinessUnitRankRequestContentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitRankRequestContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitRankRequestContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankRequestContentsResponse.
                 * @implements IListBusinessUnitRankRequestContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestContentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRankRequestContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankRequestContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankRequestContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRankRequestContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitRankContent>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankRequestContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitRankRequestContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankRequestContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitRankRequestContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankRequestContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitRankContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankRequestContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse} ListBusinessUnitRankRequestContentsResponse
                 */
                ListBusinessUnitRankRequestContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitRankContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankRequestContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse} message ListBusinessUnitRankRequestContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankRequestContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitRankContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankRequestContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankRequestContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankRequestContentsResponse;
            })();

            company.ListBusinessUnitRankReceptionContentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRankReceptionContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankReceptionContentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitRankReceptionContentsRequest pageToken
                 * @property {Array.<string>|null} [businessUnitManagementIds] ListBusinessUnitRankReceptionContentsRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new ListBusinessUnitRankReceptionContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankReceptionContentsRequest.
                 * @implements IListBusinessUnitRankReceptionContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionContentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRankReceptionContentsRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankReceptionContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @instance
                 */
                ListBusinessUnitRankReceptionContentsRequest.prototype.pageToken = "";

                /**
                 * ListBusinessUnitRankReceptionContentsRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @instance
                 */
                ListBusinessUnitRankReceptionContentsRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRankReceptionContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankReceptionContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankReceptionContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest} ListBusinessUnitRankReceptionContentsRequest
                 */
                ListBusinessUnitRankReceptionContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankReceptionContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest} message ListBusinessUnitRankReceptionContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankReceptionContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankReceptionContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankReceptionContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankReceptionContentsRequest;
            })();

            company.ListBusinessUnitRankReceptionContentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRankReceptionContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankReceptionContentsResponse
                 * @property {Long|null} [total] ListBusinessUnitRankReceptionContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitRankContent>|null} [items] ListBusinessUnitRankReceptionContentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitRankReceptionContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitRankReceptionContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankReceptionContentsResponse.
                 * @implements IListBusinessUnitRankReceptionContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionContentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRankReceptionContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankReceptionContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankReceptionContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRankReceptionContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitRankContent>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankReceptionContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitRankReceptionContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @instance
                 */
                ListBusinessUnitRankReceptionContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitRankReceptionContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankReceptionContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitRankContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankReceptionContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse} ListBusinessUnitRankReceptionContentsResponse
                 */
                ListBusinessUnitRankReceptionContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitRankContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankReceptionContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse} message ListBusinessUnitRankReceptionContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankReceptionContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitRankContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankReceptionContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankReceptionContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankReceptionContentsResponse;
            })();

            company.ExportBusinessUnitRankRequestsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitRankRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitRankRequestsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitRankRequestsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitRankRequestsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitRankRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitRankRequestsRequest.
                 * @implements IExportBusinessUnitRankRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankRequestsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitRankRequestsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitRankRequestsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ExportBusinessUnitRankRequestsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitRankRequestsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ExportBusinessUnitRankRequestsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitRankRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitRankRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitRankRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest} ExportBusinessUnitRankRequestsRequest
                 */
                ExportBusinessUnitRankRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitRankRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest} message ExportBusinessUnitRankRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitRankRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitRankRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitRankRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitRankRequestsRequest;
            })();

            company.ExportBusinessUnitRankRequestsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitRankRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitRankRequestsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitRankRequestsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitRankRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitRankRequestsResponse.
                 * @implements IExportBusinessUnitRankRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankRequestsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitRankRequestsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitRankRequestsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse
                 * @instance
                 */
                ExportBusinessUnitRankRequestsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitRankRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitRankRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitRankRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse} ExportBusinessUnitRankRequestsResponse
                 */
                ExportBusinessUnitRankRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitRankRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse} message ExportBusinessUnitRankRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitRankRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitRankRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitRankRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitRankRequestsResponse;
            })();

            company.ImportBusinessUnitRankRequestsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitRankRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitRankRequestsRequest
                 * @property {string|null} [url] ImportBusinessUnitRankRequestsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitRankRequestsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitRankRequestsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitRankRequestsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitRankRequestsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitRankRequestsRequest.
                 * @implements IImportBusinessUnitRankRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankRequestsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitRankRequestsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitRankRequestsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ImportBusinessUnitRankRequestsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitRankRequestsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ImportBusinessUnitRankRequestsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitRankRequestsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ImportBusinessUnitRankRequestsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitRankRequestsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @instance
                 */
                ImportBusinessUnitRankRequestsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitRankRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitRankRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitRankRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest} ImportBusinessUnitRankRequestsRequest
                 */
                ImportBusinessUnitRankRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitRankRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest} message ImportBusinessUnitRankRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitRankRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitRankRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitRankRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitRankRequestsRequest;
            })();

            company.ImportBusinessUnitRankRequestsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitRankRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitRankRequestsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitRankRequestsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitRankRequestsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitRankRequestsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitRankRequestsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitRankRequestsResponse.
                 * @implements IImportBusinessUnitRankRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankRequestsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitRankRequestsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitRankRequestsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @instance
                 */
                ImportBusinessUnitRankRequestsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitRankRequestsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @instance
                 */
                ImportBusinessUnitRankRequestsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitRankRequestsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @instance
                 */
                ImportBusinessUnitRankRequestsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitRankRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitRankRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitRankRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse} ImportBusinessUnitRankRequestsResponse
                 */
                ImportBusinessUnitRankRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitRankRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse} message ImportBusinessUnitRankRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitRankRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitRankRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitRankRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitRankRequestsResponse;
            })();

            company.ListBusinessUnitRankRequestFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRankRequestFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankRequestFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitRankRequestFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankRequestFormatsRequest.
                 * @implements IListBusinessUnitRankRequestFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRankRequestFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitRankRequestFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankRequestFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankRequestFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest} ListBusinessUnitRankRequestFormatsRequest
                 */
                ListBusinessUnitRankRequestFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankRequestFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest} message ListBusinessUnitRankRequestFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankRequestFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitRankRequestFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankRequestFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankRequestFormatsRequest;
            })();

            company.ListBusinessUnitRankRequestFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRankRequestFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankRequestFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitRankRequestFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitRankRequestFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitRankRequestFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankRequestFormatsResponse.
                 * @implements IListBusinessUnitRankRequestFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankRequestFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRankRequestFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankRequestFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @instance
                 */
                ListBusinessUnitRankRequestFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRankRequestFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @instance
                 */
                ListBusinessUnitRankRequestFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRankRequestFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankRequestFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankRequestFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse} ListBusinessUnitRankRequestFormatsResponse
                 */
                ListBusinessUnitRankRequestFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankRequestFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse} message ListBusinessUnitRankRequestFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankRequestFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankRequestFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankRequestFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankRequestFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankRequestFormatsResponse;
            })();

            company.ListLocalBusinessUnitFinancialsRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitFinancialsRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitFinancialsRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitFinancialsRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitFinancialsRequest.
                 * @implements IListLocalBusinessUnitFinancialsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFinancialsRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitFinancialsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitFinancialsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @instance
                 */
                ListLocalBusinessUnitFinancialsRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitFinancialsRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @instance
                 */
                ListLocalBusinessUnitFinancialsRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitFinancialsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitFinancialsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitFinancialsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest} ListLocalBusinessUnitFinancialsRequest
                 */
                ListLocalBusinessUnitFinancialsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitFinancialsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest} message ListLocalBusinessUnitFinancialsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitFinancialsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitFinancialsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitFinancialsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitFinancialsRequest;
            })();

            company.ListLocalBusinessUnitFinancialsResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitFinancialsResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitFinancialsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFinancial>|null} [items] ListLocalBusinessUnitFinancialsResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitFinancialsResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitFinancialsResponse.
                 * @implements IListLocalBusinessUnitFinancialsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFinancialsResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitFinancialsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitFinancialsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListLocalBusinessUnitFinancialsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitFinancialsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFinancial>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListLocalBusinessUnitFinancialsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitFinancialsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @instance
                 */
                ListLocalBusinessUnitFinancialsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitFinancialsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitFinancialsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitFinancialsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse} ListLocalBusinessUnitFinancialsResponse
                 */
                ListLocalBusinessUnitFinancialsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitFinancialsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse} message ListLocalBusinessUnitFinancialsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitFinancialsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitFinancialsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFinancialsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitFinancialsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitFinancialsResponse;
            })();

            company.ListLocalBusinessUnitFacilitiessRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitFacilitiessRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitFacilitiessRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitFacilitiessRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitFacilitiessRequest.
                 * @implements IListLocalBusinessUnitFacilitiessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFacilitiessRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitFacilitiessRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitFacilitiessRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ListLocalBusinessUnitFacilitiessRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitFacilitiessRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ListLocalBusinessUnitFacilitiessRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitFacilitiessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitFacilitiessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitFacilitiessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest} ListLocalBusinessUnitFacilitiessRequest
                 */
                ListLocalBusinessUnitFacilitiessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitFacilitiessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest} message ListLocalBusinessUnitFacilitiessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitFacilitiessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitFacilitiessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitFacilitiessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitFacilitiessRequest;
            })();

            company.ListLocalBusinessUnitFacilitiessResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitFacilitiessResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitFacilitiessResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitFacilities>|null} [items] ListLocalBusinessUnitFacilitiessResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitFacilitiessResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitFacilitiessResponse.
                 * @implements IListLocalBusinessUnitFacilitiessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitFacilitiessResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitFacilitiessResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitFacilitiessResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListLocalBusinessUnitFacilitiessResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitFacilitiessResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitFacilities>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListLocalBusinessUnitFacilitiessResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitFacilitiessResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ListLocalBusinessUnitFacilitiessResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitFacilitiessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitFacilitiessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitFacilitiessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse} ListLocalBusinessUnitFacilitiessResponse
                 */
                ListLocalBusinessUnitFacilitiessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitFacilitiessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse} message ListLocalBusinessUnitFacilitiessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitFacilitiessResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitFacilitiessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitFacilitiessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitFacilitiessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitFacilitiessResponse;
            })();

            company.ListLocalBusinessUnitCertificatesRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitCertificatesRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitCertificatesRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitCertificatesRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitCertificatesRequest.
                 * @implements IListLocalBusinessUnitCertificatesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitCertificatesRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitCertificatesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitCertificatesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @instance
                 */
                ListLocalBusinessUnitCertificatesRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitCertificatesRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @instance
                 */
                ListLocalBusinessUnitCertificatesRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitCertificatesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitCertificatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitCertificatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest} ListLocalBusinessUnitCertificatesRequest
                 */
                ListLocalBusinessUnitCertificatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitCertificatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest} message ListLocalBusinessUnitCertificatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitCertificatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitCertificatesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitCertificatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitCertificatesRequest;
            })();

            company.ListLocalBusinessUnitCertificatesResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitCertificatesResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitCertificatesResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitCertificate>|null} [items] ListLocalBusinessUnitCertificatesResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitCertificatesResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitCertificatesResponse.
                 * @implements IListLocalBusinessUnitCertificatesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitCertificatesResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitCertificatesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitCertificatesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListLocalBusinessUnitCertificatesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitCertificatesResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitCertificate>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListLocalBusinessUnitCertificatesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitCertificatesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @instance
                 */
                ListLocalBusinessUnitCertificatesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitCertificatesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitCertificatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitCertificatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse} ListLocalBusinessUnitCertificatesResponse
                 */
                ListLocalBusinessUnitCertificatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitCertificatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse} message ListLocalBusinessUnitCertificatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitCertificatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitCertificatesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitCertificatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitCertificatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitCertificatesResponse;
            })();

            company.ListLocalBusinessUnitStrengthsRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitStrengthsRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitStrengthsRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitStrengthsRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitStrengthsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitStrengthsRequest.
                 * @implements IListLocalBusinessUnitStrengthsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitStrengthsRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitStrengthsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitStrengthsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @instance
                 */
                ListLocalBusinessUnitStrengthsRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitStrengthsRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @instance
                 */
                ListLocalBusinessUnitStrengthsRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitStrengthsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitStrengthsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitStrengthsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest} ListLocalBusinessUnitStrengthsRequest
                 */
                ListLocalBusinessUnitStrengthsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitStrengthsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest} message ListLocalBusinessUnitStrengthsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitStrengthsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitStrengthsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitStrengthsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitStrengthsRequest;
            })();

            company.ListLocalBusinessUnitStrengthsResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitStrengthsResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitStrengthsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitStrength>|null} [items] ListLocalBusinessUnitStrengthsResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitStrengthsResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitStrengthsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitStrengthsResponse.
                 * @implements IListLocalBusinessUnitStrengthsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitStrengthsResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitStrengthsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitStrengthsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListLocalBusinessUnitStrengthsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitStrengthsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitStrength>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListLocalBusinessUnitStrengthsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitStrengthsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @instance
                 */
                ListLocalBusinessUnitStrengthsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitStrengthsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitStrengthsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitStrengthsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse} ListLocalBusinessUnitStrengthsResponse
                 */
                ListLocalBusinessUnitStrengthsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitStrengthsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse} message ListLocalBusinessUnitStrengthsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitStrengthsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitStrengthsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitStrengthsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitStrengthsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitStrengthsResponse;
            })();

            company.ListLocalBusinessUnitSkillsRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitSkillsRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitSkillsRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitSkillsRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitSkillsRequest.
                 * @implements IListLocalBusinessUnitSkillsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitSkillsRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitSkillsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitSkillsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @instance
                 */
                ListLocalBusinessUnitSkillsRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitSkillsRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @instance
                 */
                ListLocalBusinessUnitSkillsRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitSkillsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest} ListLocalBusinessUnitSkillsRequest
                 */
                ListLocalBusinessUnitSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest} message ListLocalBusinessUnitSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitSkillsRequest;
            })();

            company.ListLocalBusinessUnitSkillsResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitSkillsResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitSkillsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSkill>|null} [items] ListLocalBusinessUnitSkillsResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitSkillsResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitSkillsResponse.
                 * @implements IListLocalBusinessUnitSkillsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitSkillsResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitSkillsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitSkillsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @instance
                 */
                ListLocalBusinessUnitSkillsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitSkillsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSkill>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @instance
                 */
                ListLocalBusinessUnitSkillsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitSkillsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @instance
                 */
                ListLocalBusinessUnitSkillsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitSkillsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse} ListLocalBusinessUnitSkillsResponse
                 */
                ListLocalBusinessUnitSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse} message ListLocalBusinessUnitSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitSkillsResponse;
            })();

            company.ListLocalBusinessUnitContactContentsRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitContactContentsRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitContactContentsRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitContactContentsRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitContactContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitContactContentsRequest.
                 * @implements IListLocalBusinessUnitContactContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitContactContentsRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitContactContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitContactContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @instance
                 */
                ListLocalBusinessUnitContactContentsRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitContactContentsRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @instance
                 */
                ListLocalBusinessUnitContactContentsRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitContactContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitContactContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitContactContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest} ListLocalBusinessUnitContactContentsRequest
                 */
                ListLocalBusinessUnitContactContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitContactContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest} message ListLocalBusinessUnitContactContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitContactContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitContactContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitContactContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitContactContentsRequest;
            })();

            company.ListLocalBusinessUnitContactContentsResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitContactContentsResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitContactContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitContactContent>|null} [items] ListLocalBusinessUnitContactContentsResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitContactContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitContactContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitContactContentsResponse.
                 * @implements IListLocalBusinessUnitContactContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitContactContentsResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitContactContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitContactContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitContactContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitContactContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitContactContent>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitContactContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitContactContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitContactContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitContactContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitContactContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContactContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitContactContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse} ListLocalBusinessUnitContactContentsResponse
                 */
                ListLocalBusinessUnitContactContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitContactContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitContactContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse} message ListLocalBusinessUnitContactContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitContactContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitContactContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitContactContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitContactContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitContactContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitContactContentsResponse;
            })();

            company.ListLocalBusinessUnitRankRequestContentsRequest = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitRankRequestContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitRankRequestContentsRequest
                 * @property {string|null} [pageToken] ListLocalBusinessUnitRankRequestContentsRequest pageToken
                 * @property {string|null} [businessUnitManagementId] ListLocalBusinessUnitRankRequestContentsRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitRankRequestContentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitRankRequestContentsRequest.
                 * @implements IListLocalBusinessUnitRankRequestContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsRequest=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitRankRequestContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitRankRequestContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @instance
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.prototype.pageToken = "";

                /**
                 * ListLocalBusinessUnitRankRequestContentsRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @instance
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies a ListLocalBusinessUnitRankRequestContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitRankRequestContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest} ListLocalBusinessUnitRankRequestContentsRequest
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitRankRequestContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest} message ListLocalBusinessUnitRankRequestContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitRankRequestContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitRankRequestContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitRankRequestContentsRequest;
            })();

            company.ListLocalBusinessUnitRankRequestContentsResponse = (function() {

                /**
                 * Properties of a ListLocalBusinessUnitRankRequestContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListLocalBusinessUnitRankRequestContentsResponse
                 * @property {Long|null} [total] ListLocalBusinessUnitRankRequestContentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitRankContent>|null} [items] ListLocalBusinessUnitRankRequestContentsResponse items
                 * @property {string|null} [pageToken] ListLocalBusinessUnitRankRequestContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListLocalBusinessUnitRankRequestContentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListLocalBusinessUnitRankRequestContentsResponse.
                 * @implements IListLocalBusinessUnitRankRequestContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListLocalBusinessUnitRankRequestContentsResponse=} [properties] Properties to set
                 */
                function ListLocalBusinessUnitRankRequestContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLocalBusinessUnitRankRequestContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLocalBusinessUnitRankRequestContentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitRankContent>} items
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLocalBusinessUnitRankRequestContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @instance
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLocalBusinessUnitRankRequestContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitRankContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLocalBusinessUnitRankRequestContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse} ListLocalBusinessUnitRankRequestContentsResponse
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitRankContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLocalBusinessUnitRankRequestContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse} message ListLocalBusinessUnitRankRequestContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitRankContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLocalBusinessUnitRankRequestContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListLocalBusinessUnitRankRequestContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLocalBusinessUnitRankRequestContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLocalBusinessUnitRankRequestContentsResponse;
            })();

            company.ExportBusinessUnitContactsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitContactsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitContactsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitContactsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitContactsRequest.
                 * @implements IExportBusinessUnitContactsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitContactsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitContactsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitContactsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @instance
                 */
                ExportBusinessUnitContactsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitContactsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @instance
                 */
                ExportBusinessUnitContactsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitContactsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitContactsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitContactsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitContactsRequest} ExportBusinessUnitContactsRequest
                 */
                ExportBusinessUnitContactsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitContactsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitContactsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitContactsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitContactsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitContactsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitContactsRequest} message ExportBusinessUnitContactsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitContactsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitContactsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitContactsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitContactsRequest;
            })();

            company.ExportBusinessUnitContactsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitContactsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitContactsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitContactsResponse.
                 * @implements IExportBusinessUnitContactsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitContactsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitContactsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitContactsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsResponse
                 * @instance
                 */
                ExportBusinessUnitContactsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitContactsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitContactsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitContactsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitContactsResponse} ExportBusinessUnitContactsResponse
                 */
                ExportBusinessUnitContactsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitContactsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitContactsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitContactsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitContactsResponse} message ExportBusinessUnitContactsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitContactsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitContactsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitContactsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitContactsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitContactsResponse;
            })();

            company.ImportBusinessUnitContactsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitContactsRequest
                 * @property {string|null} [url] ImportBusinessUnitContactsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitContactsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitContactsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitContactsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitContactsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitContactsRequest.
                 * @implements IImportBusinessUnitContactsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitContactsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitContactsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitContactsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @instance
                 */
                ImportBusinessUnitContactsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitContactsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @instance
                 */
                ImportBusinessUnitContactsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitContactsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @instance
                 */
                ImportBusinessUnitContactsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitContactsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @instance
                 */
                ImportBusinessUnitContactsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitContactsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitContactsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitContactsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitContactsRequest} ImportBusinessUnitContactsRequest
                 */
                ImportBusinessUnitContactsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitContactsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitContactsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitContactsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitContactsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitContactsRequest} message ImportBusinessUnitContactsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitContactsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitContactsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitContactsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitContactsRequest;
            })();

            company.ImportBusinessUnitContactsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitContactsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitContactsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitContactsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitContactsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitContactsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitContactsResponse.
                 * @implements IImportBusinessUnitContactsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitContactsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitContactsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitContactsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @instance
                 */
                ImportBusinessUnitContactsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitContactsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @instance
                 */
                ImportBusinessUnitContactsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitContactsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @instance
                 */
                ImportBusinessUnitContactsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitContactsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitContactsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitContactsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitContactsResponse} ImportBusinessUnitContactsResponse
                 */
                ImportBusinessUnitContactsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitContactsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitContactsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitContactsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitContactsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitContactsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitContactsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitContactsResponse} message ImportBusinessUnitContactsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitContactsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitContactsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitContactsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitContactsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitContactsResponse;
            })();

            company.ListBusinessUnitContactFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitContactFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitContactFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactFormatsRequest.
                 * @implements IListBusinessUnitContactFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitContactFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitContactFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactFormatsRequest} ListBusinessUnitContactFormatsRequest
                 */
                ListBusinessUnitContactFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitContactFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactFormatsRequest} message ListBusinessUnitContactFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitContactFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactFormatsRequest;
            })();

            company.ListBusinessUnitContactFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitContactFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitContactFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitContactFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitContactFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitContactFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitContactFormatsResponse.
                 * @implements IListBusinessUnitContactFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitContactFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitContactFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitContactFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @instance
                 */
                ListBusinessUnitContactFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitContactFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @instance
                 */
                ListBusinessUnitContactFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitContactFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitContactFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitContactFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitContactFormatsResponse} ListBusinessUnitContactFormatsResponse
                 */
                ListBusinessUnitContactFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitContactFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitContactFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitContactFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitContactFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitContactFormatsResponse} message ListBusinessUnitContactFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitContactFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitContactFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitContactFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitContactFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitContactFormatsResponse;
            })();

            company.ExportBusinessUnitFinancialsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitFinancialsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitFinancialsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitFinancialsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitFinancialsRequest.
                 * @implements IExportBusinessUnitFinancialsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitFinancialsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitFinancialsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitFinancialsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ExportBusinessUnitFinancialsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitFinancialsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ExportBusinessUnitFinancialsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitFinancialsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitFinancialsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitFinancialsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitFinancialsRequest} ExportBusinessUnitFinancialsRequest
                 */
                ExportBusinessUnitFinancialsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitFinancialsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitFinancialsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitFinancialsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitFinancialsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitFinancialsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitFinancialsRequest} message ExportBusinessUnitFinancialsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitFinancialsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitFinancialsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitFinancialsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitFinancialsRequest;
            })();

            company.ExportBusinessUnitFinancialsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitFinancialsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitFinancialsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitFinancialsResponse.
                 * @implements IExportBusinessUnitFinancialsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitFinancialsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitFinancialsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitFinancialsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsResponse
                 * @instance
                 */
                ExportBusinessUnitFinancialsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitFinancialsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitFinancialsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitFinancialsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitFinancialsResponse} ExportBusinessUnitFinancialsResponse
                 */
                ExportBusinessUnitFinancialsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitFinancialsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitFinancialsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitFinancialsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitFinancialsResponse} message ExportBusinessUnitFinancialsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitFinancialsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitFinancialsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFinancialsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitFinancialsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitFinancialsResponse;
            })();

            company.ImportBusinessUnitFinancialsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitFinancialsRequest
                 * @property {string|null} [url] ImportBusinessUnitFinancialsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitFinancialsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitFinancialsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitFinancialsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitFinancialsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitFinancialsRequest.
                 * @implements IImportBusinessUnitFinancialsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitFinancialsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitFinancialsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitFinancialsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ImportBusinessUnitFinancialsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitFinancialsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ImportBusinessUnitFinancialsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitFinancialsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ImportBusinessUnitFinancialsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitFinancialsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @instance
                 */
                ImportBusinessUnitFinancialsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitFinancialsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitFinancialsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitFinancialsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitFinancialsRequest} ImportBusinessUnitFinancialsRequest
                 */
                ImportBusinessUnitFinancialsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitFinancialsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitFinancialsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFinancialsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitFinancialsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitFinancialsRequest} message ImportBusinessUnitFinancialsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitFinancialsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitFinancialsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitFinancialsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitFinancialsRequest;
            })();

            company.ImportBusinessUnitFinancialsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitFinancialsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitFinancialsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitFinancialsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitFinancialsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitFinancialsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitFinancialsResponse.
                 * @implements IImportBusinessUnitFinancialsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitFinancialsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitFinancialsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitFinancialsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @instance
                 */
                ImportBusinessUnitFinancialsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitFinancialsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @instance
                 */
                ImportBusinessUnitFinancialsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitFinancialsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @instance
                 */
                ImportBusinessUnitFinancialsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitFinancialsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitFinancialsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitFinancialsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitFinancialsResponse} ImportBusinessUnitFinancialsResponse
                 */
                ImportBusinessUnitFinancialsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitFinancialsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitFinancialsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFinancialsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFinancialsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFinancialsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitFinancialsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitFinancialsResponse} message ImportBusinessUnitFinancialsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitFinancialsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitFinancialsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFinancialsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitFinancialsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitFinancialsResponse;
            })();

            company.ListBusinessUnitFinancialFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitFinancialFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFinancialFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitFinancialFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFinancialFormatsRequest.
                 * @implements IListBusinessUnitFinancialFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitFinancialFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitFinancialFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFinancialFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFinancialFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest} ListBusinessUnitFinancialFormatsRequest
                 */
                ListBusinessUnitFinancialFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFinancialFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest} message ListBusinessUnitFinancialFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFinancialFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitFinancialFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFinancialFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFinancialFormatsRequest;
            })();

            company.ListBusinessUnitFinancialFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitFinancialFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFinancialFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitFinancialFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitFinancialFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitFinancialFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFinancialFormatsResponse.
                 * @implements IListBusinessUnitFinancialFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFinancialFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitFinancialFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFinancialFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFinancialFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitFinancialFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFinancialFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitFinancialFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFinancialFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFinancialFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse} ListBusinessUnitFinancialFormatsResponse
                 */
                ListBusinessUnitFinancialFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFinancialFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse} message ListBusinessUnitFinancialFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFinancialFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFinancialFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFinancialFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFinancialFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFinancialFormatsResponse;
            })();

            company.ExportBusinessUnitCertificatesRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitCertificatesRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitCertificatesRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitCertificatesRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitCertificatesRequest.
                 * @implements IExportBusinessUnitCertificatesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitCertificatesRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitCertificatesRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitCertificatesRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ExportBusinessUnitCertificatesRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitCertificatesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ExportBusinessUnitCertificatesRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitCertificatesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitCertificatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitCertificatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitCertificatesRequest} ExportBusinessUnitCertificatesRequest
                 */
                ExportBusinessUnitCertificatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitCertificatesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitCertificatesRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitCertificatesRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitCertificatesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitCertificatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitCertificatesRequest} message ExportBusinessUnitCertificatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitCertificatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitCertificatesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitCertificatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitCertificatesRequest;
            })();

            company.ExportBusinessUnitCertificatesResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitCertificatesResponse
                 * @property {string|null} [assetId] ExportBusinessUnitCertificatesResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitCertificatesResponse.
                 * @implements IExportBusinessUnitCertificatesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitCertificatesResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitCertificatesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitCertificatesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesResponse
                 * @instance
                 */
                ExportBusinessUnitCertificatesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitCertificatesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitCertificatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitCertificatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitCertificatesResponse} ExportBusinessUnitCertificatesResponse
                 */
                ExportBusinessUnitCertificatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitCertificatesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitCertificatesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitCertificatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitCertificatesResponse} message ExportBusinessUnitCertificatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitCertificatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitCertificatesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitCertificatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitCertificatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitCertificatesResponse;
            })();

            company.ImportBusinessUnitCertificatesRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitCertificatesRequest
                 * @property {string|null} [url] ImportBusinessUnitCertificatesRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitCertificatesRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitCertificatesRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitCertificatesRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitCertificatesRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitCertificatesRequest.
                 * @implements IImportBusinessUnitCertificatesRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitCertificatesRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitCertificatesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitCertificatesRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ImportBusinessUnitCertificatesRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitCertificatesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ImportBusinessUnitCertificatesRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitCertificatesRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ImportBusinessUnitCertificatesRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitCertificatesRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @instance
                 */
                ImportBusinessUnitCertificatesRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitCertificatesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitCertificatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitCertificatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitCertificatesRequest} ImportBusinessUnitCertificatesRequest
                 */
                ImportBusinessUnitCertificatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitCertificatesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitCertificatesRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitCertificatesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitCertificatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitCertificatesRequest} message ImportBusinessUnitCertificatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitCertificatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitCertificatesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitCertificatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitCertificatesRequest;
            })();

            company.ImportBusinessUnitCertificatesResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitCertificatesResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitCertificatesResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitCertificatesResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitCertificatesResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitCertificatesResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitCertificatesResponse.
                 * @implements IImportBusinessUnitCertificatesResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitCertificatesResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitCertificatesResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitCertificatesResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @instance
                 */
                ImportBusinessUnitCertificatesResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitCertificatesResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @instance
                 */
                ImportBusinessUnitCertificatesResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitCertificatesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @instance
                 */
                ImportBusinessUnitCertificatesResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitCertificatesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitCertificatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitCertificatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitCertificatesResponse} ImportBusinessUnitCertificatesResponse
                 */
                ImportBusinessUnitCertificatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitCertificatesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitCertificatesResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitCertificatesResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitCertificatesResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitCertificatesResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitCertificatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitCertificatesResponse} message ImportBusinessUnitCertificatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitCertificatesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitCertificatesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitCertificatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitCertificatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitCertificatesResponse;
            })();

            company.ListBusinessUnitCertificateFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitCertificateFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitCertificateFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitCertificateFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitCertificateFormatsRequest.
                 * @implements IListBusinessUnitCertificateFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificateFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitCertificateFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitCertificateFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitCertificateFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitCertificateFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest} ListBusinessUnitCertificateFormatsRequest
                 */
                ListBusinessUnitCertificateFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitCertificateFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest} message ListBusinessUnitCertificateFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitCertificateFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitCertificateFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitCertificateFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitCertificateFormatsRequest;
            })();

            company.ListBusinessUnitCertificateFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitCertificateFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitCertificateFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitCertificateFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitCertificateFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitCertificateFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitCertificateFormatsResponse.
                 * @implements IListBusinessUnitCertificateFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitCertificateFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitCertificateFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitCertificateFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @instance
                 */
                ListBusinessUnitCertificateFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitCertificateFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @instance
                 */
                ListBusinessUnitCertificateFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitCertificateFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitCertificateFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitCertificateFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse} ListBusinessUnitCertificateFormatsResponse
                 */
                ListBusinessUnitCertificateFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitCertificateFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse} message ListBusinessUnitCertificateFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitCertificateFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitCertificateFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitCertificateFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitCertificateFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitCertificateFormatsResponse;
            })();

            company.ExportBusinessUnitFacilitiessRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitFacilitiessRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitFacilitiessRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitFacilitiessRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitFacilitiessRequest.
                 * @implements IExportBusinessUnitFacilitiessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitFacilitiessRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitFacilitiessRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitFacilitiessRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ExportBusinessUnitFacilitiessRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitFacilitiessRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ExportBusinessUnitFacilitiessRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitFacilitiessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitFacilitiessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitFacilitiessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest} ExportBusinessUnitFacilitiessRequest
                 */
                ExportBusinessUnitFacilitiessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitFacilitiessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest} message ExportBusinessUnitFacilitiessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitFacilitiessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitFacilitiessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitFacilitiessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitFacilitiessRequest;
            })();

            company.ExportBusinessUnitFacilitiessResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitFacilitiessResponse
                 * @property {string|null} [assetId] ExportBusinessUnitFacilitiessResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitFacilitiessResponse.
                 * @implements IExportBusinessUnitFacilitiessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitFacilitiessResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitFacilitiessResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitFacilitiessResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ExportBusinessUnitFacilitiessResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitFacilitiessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitFacilitiessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitFacilitiessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse} ExportBusinessUnitFacilitiessResponse
                 */
                ExportBusinessUnitFacilitiessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitFacilitiessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse} message ExportBusinessUnitFacilitiessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitFacilitiessResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitFacilitiessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitFacilitiessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitFacilitiessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitFacilitiessResponse;
            })();

            company.ImportBusinessUnitFacilitiessRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitFacilitiessRequest
                 * @property {string|null} [url] ImportBusinessUnitFacilitiessRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitFacilitiessRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitFacilitiessRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitFacilitiessRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitFacilitiessRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitFacilitiessRequest.
                 * @implements IImportBusinessUnitFacilitiessRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitFacilitiessRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitFacilitiessRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitFacilitiessRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ImportBusinessUnitFacilitiessRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitFacilitiessRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ImportBusinessUnitFacilitiessRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitFacilitiessRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ImportBusinessUnitFacilitiessRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitFacilitiessRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @instance
                 */
                ImportBusinessUnitFacilitiessRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitFacilitiessRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitFacilitiessRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitFacilitiessRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest} ImportBusinessUnitFacilitiessRequest
                 */
                ImportBusinessUnitFacilitiessRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitFacilitiessRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest} message ImportBusinessUnitFacilitiessRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitFacilitiessRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitFacilitiessRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitFacilitiessRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitFacilitiessRequest;
            })();

            company.ImportBusinessUnitFacilitiessResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitFacilitiessResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitFacilitiessResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitFacilitiessResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitFacilitiessResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitFacilitiessResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitFacilitiessResponse.
                 * @implements IImportBusinessUnitFacilitiessResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitFacilitiessResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitFacilitiessResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitFacilitiessResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ImportBusinessUnitFacilitiessResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitFacilitiessResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ImportBusinessUnitFacilitiessResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitFacilitiessResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @instance
                 */
                ImportBusinessUnitFacilitiessResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitFacilitiessResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitFacilitiessResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitFacilitiessResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse} ImportBusinessUnitFacilitiessResponse
                 */
                ImportBusinessUnitFacilitiessResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitFacilitiessResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse} message ImportBusinessUnitFacilitiessResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitFacilitiessResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitFacilitiessResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitFacilitiessResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitFacilitiessResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitFacilitiessResponse;
            })();

            company.ListBusinessUnitFacilitiesFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitFacilitiesFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFacilitiesFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitFacilitiesFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFacilitiesFormatsRequest.
                 * @implements IListBusinessUnitFacilitiesFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiesFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitFacilitiesFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitFacilitiesFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFacilitiesFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFacilitiesFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest} ListBusinessUnitFacilitiesFormatsRequest
                 */
                ListBusinessUnitFacilitiesFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFacilitiesFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest} message ListBusinessUnitFacilitiesFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFacilitiesFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitFacilitiesFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFacilitiesFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFacilitiesFormatsRequest;
            })();

            company.ListBusinessUnitFacilitiesFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitFacilitiesFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitFacilitiesFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitFacilitiesFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitFacilitiesFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitFacilitiesFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitFacilitiesFormatsResponse.
                 * @implements IListBusinessUnitFacilitiesFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitFacilitiesFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitFacilitiesFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitFacilitiesFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFacilitiesFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitFacilitiesFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @instance
                 */
                ListBusinessUnitFacilitiesFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitFacilitiesFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitFacilitiesFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitFacilitiesFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse} ListBusinessUnitFacilitiesFormatsResponse
                 */
                ListBusinessUnitFacilitiesFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitFacilitiesFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse} message ListBusinessUnitFacilitiesFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitFacilitiesFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitFacilitiesFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitFacilitiesFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitFacilitiesFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitFacilitiesFormatsResponse;
            })();

            company.ExportBusinessUnitSkillsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitSkillsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitSkillsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitSkillsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitSkillsRequest.
                 * @implements IExportBusinessUnitSkillsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitSkillsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitSkillsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitSkillsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @instance
                 */
                ExportBusinessUnitSkillsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitSkillsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @instance
                 */
                ExportBusinessUnitSkillsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitSkillsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitSkillsRequest} ExportBusinessUnitSkillsRequest
                 */
                ExportBusinessUnitSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitSkillsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitSkillsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitSkillsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitSkillsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitSkillsRequest} message ExportBusinessUnitSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitSkillsRequest;
            })();

            company.ExportBusinessUnitSkillsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitSkillsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitSkillsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitSkillsResponse.
                 * @implements IExportBusinessUnitSkillsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitSkillsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitSkillsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitSkillsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsResponse
                 * @instance
                 */
                ExportBusinessUnitSkillsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitSkillsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitSkillsResponse} ExportBusinessUnitSkillsResponse
                 */
                ExportBusinessUnitSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitSkillsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitSkillsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitSkillsResponse} message ExportBusinessUnitSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitSkillsResponse;
            })();

            company.ImportBusinessUnitSkillsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitSkillsRequest
                 * @property {string|null} [url] ImportBusinessUnitSkillsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitSkillsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitSkillsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitSkillsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitSkillsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitSkillsRequest.
                 * @implements IImportBusinessUnitSkillsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitSkillsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitSkillsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitSkillsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @instance
                 */
                ImportBusinessUnitSkillsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitSkillsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @instance
                 */
                ImportBusinessUnitSkillsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitSkillsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @instance
                 */
                ImportBusinessUnitSkillsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitSkillsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @instance
                 */
                ImportBusinessUnitSkillsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitSkillsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitSkillsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitSkillsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitSkillsRequest} ImportBusinessUnitSkillsRequest
                 */
                ImportBusinessUnitSkillsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitSkillsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitSkillsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitSkillsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitSkillsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitSkillsRequest} message ImportBusinessUnitSkillsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitSkillsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitSkillsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitSkillsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitSkillsRequest;
            })();

            company.ImportBusinessUnitSkillsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitSkillsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitSkillsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitSkillsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitSkillsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitSkillsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitSkillsResponse.
                 * @implements IImportBusinessUnitSkillsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitSkillsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitSkillsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitSkillsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @instance
                 */
                ImportBusinessUnitSkillsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitSkillsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @instance
                 */
                ImportBusinessUnitSkillsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitSkillsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @instance
                 */
                ImportBusinessUnitSkillsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitSkillsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitSkillsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitSkillsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitSkillsResponse} ImportBusinessUnitSkillsResponse
                 */
                ImportBusinessUnitSkillsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitSkillsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitSkillsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitSkillsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitSkillsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitSkillsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitSkillsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitSkillsResponse} message ImportBusinessUnitSkillsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitSkillsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitSkillsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitSkillsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitSkillsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitSkillsResponse;
            })();

            company.ListBusinessUnitSkillFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitSkillFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSkillFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitSkillFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSkillFormatsRequest.
                 * @implements IListBusinessUnitSkillFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitSkillFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitSkillFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSkillFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSkillFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest} ListBusinessUnitSkillFormatsRequest
                 */
                ListBusinessUnitSkillFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSkillFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest} message ListBusinessUnitSkillFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSkillFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitSkillFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSkillFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSkillFormatsRequest;
            })();

            company.ListBusinessUnitSkillFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitSkillFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitSkillFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitSkillFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitSkillFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitSkillFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitSkillFormatsResponse.
                 * @implements IListBusinessUnitSkillFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitSkillFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitSkillFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitSkillFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @instance
                 */
                ListBusinessUnitSkillFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitSkillFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @instance
                 */
                ListBusinessUnitSkillFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitSkillFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitSkillFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitSkillFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse} ListBusinessUnitSkillFormatsResponse
                 */
                ListBusinessUnitSkillFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitSkillFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse} message ListBusinessUnitSkillFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitSkillFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitSkillFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitSkillFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitSkillFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitSkillFormatsResponse;
            })();

            company.ExportBusinessUnitRankReceptionsRequest = (function() {

                /**
                 * Properties of an ExportBusinessUnitRankReceptionsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitRankReceptionsRequest
                 * @property {Array.<string>|null} [ids] ExportBusinessUnitRankReceptionsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportBusinessUnitRankReceptionsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportBusinessUnitRankReceptionsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitRankReceptionsRequest.
                 * @implements IExportBusinessUnitRankReceptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankReceptionsRequest=} [properties] Properties to set
                 */
                function ExportBusinessUnitRankReceptionsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitRankReceptionsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ExportBusinessUnitRankReceptionsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportBusinessUnitRankReceptionsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ExportBusinessUnitRankReceptionsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportBusinessUnitRankReceptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitRankReceptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitRankReceptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest} ExportBusinessUnitRankReceptionsRequest
                 */
                ExportBusinessUnitRankReceptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitRankReceptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest} message ExportBusinessUnitRankReceptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitRankReceptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitRankReceptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitRankReceptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitRankReceptionsRequest;
            })();

            company.ExportBusinessUnitRankReceptionsResponse = (function() {

                /**
                 * Properties of an ExportBusinessUnitRankReceptionsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportBusinessUnitRankReceptionsResponse
                 * @property {string|null} [assetId] ExportBusinessUnitRankReceptionsResponse assetId
                 */

                /**
                 * Constructs a new ExportBusinessUnitRankReceptionsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportBusinessUnitRankReceptionsResponse.
                 * @implements IExportBusinessUnitRankReceptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportBusinessUnitRankReceptionsResponse=} [properties] Properties to set
                 */
                function ExportBusinessUnitRankReceptionsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportBusinessUnitRankReceptionsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse
                 * @instance
                 */
                ExportBusinessUnitRankReceptionsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportBusinessUnitRankReceptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportBusinessUnitRankReceptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportBusinessUnitRankReceptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse} ExportBusinessUnitRankReceptionsResponse
                 */
                ExportBusinessUnitRankReceptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportBusinessUnitRankReceptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse} message ExportBusinessUnitRankReceptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportBusinessUnitRankReceptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportBusinessUnitRankReceptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportBusinessUnitRankReceptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportBusinessUnitRankReceptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportBusinessUnitRankReceptionsResponse;
            })();

            company.ImportBusinessUnitRankReceptionsRequest = (function() {

                /**
                 * Properties of an ImportBusinessUnitRankReceptionsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitRankReceptionsRequest
                 * @property {string|null} [url] ImportBusinessUnitRankReceptionsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportBusinessUnitRankReceptionsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportBusinessUnitRankReceptionsRequest dryRun
                 * @property {boolean|null} [exportError] ImportBusinessUnitRankReceptionsRequest exportError
                 */

                /**
                 * Constructs a new ImportBusinessUnitRankReceptionsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitRankReceptionsRequest.
                 * @implements IImportBusinessUnitRankReceptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankReceptionsRequest=} [properties] Properties to set
                 */
                function ImportBusinessUnitRankReceptionsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitRankReceptionsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsRequest.prototype.url = "";

                /**
                 * ImportBusinessUnitRankReceptionsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsRequest.prototype.fileFormat = null;

                /**
                 * ImportBusinessUnitRankReceptionsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsRequest.prototype.dryRun = false;

                /**
                 * ImportBusinessUnitRankReceptionsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportBusinessUnitRankReceptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitRankReceptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitRankReceptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest} ImportBusinessUnitRankReceptionsRequest
                 */
                ImportBusinessUnitRankReceptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitRankReceptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest} message ImportBusinessUnitRankReceptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitRankReceptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitRankReceptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitRankReceptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitRankReceptionsRequest;
            })();

            company.ImportBusinessUnitRankReceptionsResponse = (function() {

                /**
                 * Properties of an ImportBusinessUnitRankReceptionsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportBusinessUnitRankReceptionsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportBusinessUnitRankReceptionsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportBusinessUnitRankReceptionsResponse errors
                 * @property {string|null} [assetId] ImportBusinessUnitRankReceptionsResponse assetId
                 */

                /**
                 * Constructs a new ImportBusinessUnitRankReceptionsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportBusinessUnitRankReceptionsResponse.
                 * @implements IImportBusinessUnitRankReceptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportBusinessUnitRankReceptionsResponse=} [properties] Properties to set
                 */
                function ImportBusinessUnitRankReceptionsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportBusinessUnitRankReceptionsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsResponse.prototype.summary = null;

                /**
                 * ImportBusinessUnitRankReceptionsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportBusinessUnitRankReceptionsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @instance
                 */
                ImportBusinessUnitRankReceptionsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportBusinessUnitRankReceptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportBusinessUnitRankReceptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportBusinessUnitRankReceptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse} ImportBusinessUnitRankReceptionsResponse
                 */
                ImportBusinessUnitRankReceptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportBusinessUnitRankReceptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse} message ImportBusinessUnitRankReceptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportBusinessUnitRankReceptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportBusinessUnitRankReceptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportBusinessUnitRankReceptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportBusinessUnitRankReceptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportBusinessUnitRankReceptionsResponse;
            })();

            company.ListBusinessUnitRankReceptionFormatsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitRankReceptionFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankReceptionFormatsRequest
                 */

                /**
                 * Constructs a new ListBusinessUnitRankReceptionFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankReceptionFormatsRequest.
                 * @implements IListBusinessUnitRankReceptionFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionFormatsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitRankReceptionFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListBusinessUnitRankReceptionFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankReceptionFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankReceptionFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest} ListBusinessUnitRankReceptionFormatsRequest
                 */
                ListBusinessUnitRankReceptionFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankReceptionFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest} message ListBusinessUnitRankReceptionFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankReceptionFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListBusinessUnitRankReceptionFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankReceptionFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankReceptionFormatsRequest;
            })();

            company.ListBusinessUnitRankReceptionFormatsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitRankReceptionFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitRankReceptionFormatsResponse
                 * @property {Long|null} [total] ListBusinessUnitRankReceptionFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListBusinessUnitRankReceptionFormatsResponse items
                 */

                /**
                 * Constructs a new ListBusinessUnitRankReceptionFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitRankReceptionFormatsResponse.
                 * @implements IListBusinessUnitRankReceptionFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitRankReceptionFormatsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitRankReceptionFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitRankReceptionFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @instance
                 */
                ListBusinessUnitRankReceptionFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitRankReceptionFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @instance
                 */
                ListBusinessUnitRankReceptionFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListBusinessUnitRankReceptionFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitRankReceptionFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitRankReceptionFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse} ListBusinessUnitRankReceptionFormatsResponse
                 */
                ListBusinessUnitRankReceptionFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitRankReceptionFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse} message ListBusinessUnitRankReceptionFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitRankReceptionFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitRankReceptionFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitRankReceptionFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitRankReceptionFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitRankReceptionFormatsResponse;
            })();

            company.ListBusinessUnitPublicDocumentsRequest = (function() {

                /**
                 * Properties of a ListBusinessUnitPublicDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitPublicDocumentsRequest
                 * @property {string|null} [pageToken] ListBusinessUnitPublicDocumentsRequest pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitPublicDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitPublicDocumentsRequest.
                 * @implements IListBusinessUnitPublicDocumentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitPublicDocumentsRequest=} [properties] Properties to set
                 */
                function ListBusinessUnitPublicDocumentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitPublicDocumentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest
                 * @instance
                 */
                ListBusinessUnitPublicDocumentsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitPublicDocumentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitPublicDocumentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitPublicDocumentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest} ListBusinessUnitPublicDocumentsRequest
                 */
                ListBusinessUnitPublicDocumentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitPublicDocumentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest} message ListBusinessUnitPublicDocumentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitPublicDocumentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitPublicDocumentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitPublicDocumentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitPublicDocumentsRequest;
            })();

            company.ListBusinessUnitPublicDocumentsResponse = (function() {

                /**
                 * Properties of a ListBusinessUnitPublicDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBusinessUnitPublicDocumentsResponse
                 * @property {Long|null} [total] ListBusinessUnitPublicDocumentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitPublicDocument>|null} [items] ListBusinessUnitPublicDocumentsResponse items
                 * @property {string|null} [pageToken] ListBusinessUnitPublicDocumentsResponse pageToken
                 */

                /**
                 * Constructs a new ListBusinessUnitPublicDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBusinessUnitPublicDocumentsResponse.
                 * @implements IListBusinessUnitPublicDocumentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBusinessUnitPublicDocumentsResponse=} [properties] Properties to set
                 */
                function ListBusinessUnitPublicDocumentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBusinessUnitPublicDocumentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitPublicDocumentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBusinessUnitPublicDocumentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitPublicDocument>} items
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitPublicDocumentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBusinessUnitPublicDocumentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                ListBusinessUnitPublicDocumentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBusinessUnitPublicDocumentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBusinessUnitPublicDocumentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitPublicDocument.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBusinessUnitPublicDocumentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse} ListBusinessUnitPublicDocumentsResponse
                 */
                ListBusinessUnitPublicDocumentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitPublicDocument.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBusinessUnitPublicDocumentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse} message ListBusinessUnitPublicDocumentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBusinessUnitPublicDocumentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitPublicDocument.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBusinessUnitPublicDocumentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBusinessUnitPublicDocumentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBusinessUnitPublicDocumentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBusinessUnitPublicDocumentsResponse;
            })();

            company.CreateBusinessUnitPublicDocumentRequest = (function() {

                /**
                 * Properties of a CreateBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBusinessUnitPublicDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitPublicDocument|null} [businessUnitPublicDocument] CreateBusinessUnitPublicDocumentRequest businessUnitPublicDocument
                 */

                /**
                 * Constructs a new CreateBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBusinessUnitPublicDocumentRequest.
                 * @implements ICreateBusinessUnitPublicDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBusinessUnitPublicDocumentRequest=} [properties] Properties to set
                 */
                function CreateBusinessUnitPublicDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBusinessUnitPublicDocumentRequest businessUnitPublicDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitPublicDocument|null|undefined} businessUnitPublicDocument
                 * @memberof mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest
                 * @instance
                 */
                CreateBusinessUnitPublicDocumentRequest.prototype.businessUnitPublicDocument = null;

                /**
                 * Verifies a CreateBusinessUnitPublicDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBusinessUnitPublicDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitPublicDocument.verify(message.businessUnitPublicDocument);
                        if (error)
                            return "businessUnitPublicDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBusinessUnitPublicDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest} CreateBusinessUnitPublicDocumentRequest
                 */
                CreateBusinessUnitPublicDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest();
                    if (object.businessUnitPublicDocument != null) {
                        if (typeof object.businessUnitPublicDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest.businessUnitPublicDocument: object expected");
                        message.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.fromObject(object.businessUnitPublicDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBusinessUnitPublicDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest} message CreateBusinessUnitPublicDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBusinessUnitPublicDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitPublicDocument = null;
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument"))
                        object.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.toObject(message.businessUnitPublicDocument, options);
                    return object;
                };

                /**
                 * Converts this CreateBusinessUnitPublicDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBusinessUnitPublicDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBusinessUnitPublicDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBusinessUnitPublicDocumentRequest;
            })();

            company.UpdateBusinessUnitPublicDocumentRequest = (function() {

                /**
                 * Properties of an UpdateBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBusinessUnitPublicDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitPublicDocument|null} [businessUnitPublicDocument] UpdateBusinessUnitPublicDocumentRequest businessUnitPublicDocument
                 */

                /**
                 * Constructs a new UpdateBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBusinessUnitPublicDocumentRequest.
                 * @implements IUpdateBusinessUnitPublicDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBusinessUnitPublicDocumentRequest=} [properties] Properties to set
                 */
                function UpdateBusinessUnitPublicDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBusinessUnitPublicDocumentRequest businessUnitPublicDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitPublicDocument|null|undefined} businessUnitPublicDocument
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest
                 * @instance
                 */
                UpdateBusinessUnitPublicDocumentRequest.prototype.businessUnitPublicDocument = null;

                /**
                 * Verifies an UpdateBusinessUnitPublicDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBusinessUnitPublicDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitPublicDocument.verify(message.businessUnitPublicDocument);
                        if (error)
                            return "businessUnitPublicDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBusinessUnitPublicDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest} UpdateBusinessUnitPublicDocumentRequest
                 */
                UpdateBusinessUnitPublicDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest();
                    if (object.businessUnitPublicDocument != null) {
                        if (typeof object.businessUnitPublicDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest.businessUnitPublicDocument: object expected");
                        message.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.fromObject(object.businessUnitPublicDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBusinessUnitPublicDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest} message UpdateBusinessUnitPublicDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBusinessUnitPublicDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitPublicDocument = null;
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument"))
                        object.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.toObject(message.businessUnitPublicDocument, options);
                    return object;
                };

                /**
                 * Converts this UpdateBusinessUnitPublicDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBusinessUnitPublicDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBusinessUnitPublicDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBusinessUnitPublicDocumentRequest;
            })();

            company.DeleteBusinessUnitPublicDocumentRequest = (function() {

                /**
                 * Properties of a DeleteBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBusinessUnitPublicDocumentRequest
                 * @property {mtechnavi.api.company.IBusinessUnitPublicDocument|null} [businessUnitPublicDocument] DeleteBusinessUnitPublicDocumentRequest businessUnitPublicDocument
                 */

                /**
                 * Constructs a new DeleteBusinessUnitPublicDocumentRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBusinessUnitPublicDocumentRequest.
                 * @implements IDeleteBusinessUnitPublicDocumentRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBusinessUnitPublicDocumentRequest=} [properties] Properties to set
                 */
                function DeleteBusinessUnitPublicDocumentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBusinessUnitPublicDocumentRequest businessUnitPublicDocument.
                 * @member {mtechnavi.api.company.IBusinessUnitPublicDocument|null|undefined} businessUnitPublicDocument
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest
                 * @instance
                 */
                DeleteBusinessUnitPublicDocumentRequest.prototype.businessUnitPublicDocument = null;

                /**
                 * Verifies a DeleteBusinessUnitPublicDocumentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBusinessUnitPublicDocumentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitPublicDocument.verify(message.businessUnitPublicDocument);
                        if (error)
                            return "businessUnitPublicDocument." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBusinessUnitPublicDocumentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest} DeleteBusinessUnitPublicDocumentRequest
                 */
                DeleteBusinessUnitPublicDocumentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest();
                    if (object.businessUnitPublicDocument != null) {
                        if (typeof object.businessUnitPublicDocument !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest.businessUnitPublicDocument: object expected");
                        message.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.fromObject(object.businessUnitPublicDocument);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBusinessUnitPublicDocumentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest} message DeleteBusinessUnitPublicDocumentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBusinessUnitPublicDocumentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.businessUnitPublicDocument = null;
                    if (message.businessUnitPublicDocument != null && message.hasOwnProperty("businessUnitPublicDocument"))
                        object.businessUnitPublicDocument = $root.mtechnavi.api.company.BusinessUnitPublicDocument.toObject(message.businessUnitPublicDocument, options);
                    return object;
                };

                /**
                 * Converts this DeleteBusinessUnitPublicDocumentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBusinessUnitPublicDocumentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBusinessUnitPublicDocumentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBusinessUnitPublicDocumentRequest;
            })();

            company.SharedListBusinessUnitPublicDocumentsRequest = (function() {

                /**
                 * Properties of a SharedListBusinessUnitPublicDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitPublicDocumentsRequest
                 * @property {string|null} [pageToken] SharedListBusinessUnitPublicDocumentsRequest pageToken
                 * @property {Array.<string>|null} [companyIds] SharedListBusinessUnitPublicDocumentsRequest companyIds
                 */

                /**
                 * Constructs a new SharedListBusinessUnitPublicDocumentsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitPublicDocumentsRequest.
                 * @implements ISharedListBusinessUnitPublicDocumentsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitPublicDocumentsRequest=} [properties] Properties to set
                 */
                function SharedListBusinessUnitPublicDocumentsRequest(properties) {
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitPublicDocumentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @instance
                 */
                SharedListBusinessUnitPublicDocumentsRequest.prototype.pageToken = "";

                /**
                 * SharedListBusinessUnitPublicDocumentsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @instance
                 */
                SharedListBusinessUnitPublicDocumentsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharedListBusinessUnitPublicDocumentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitPublicDocumentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitPublicDocumentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest} SharedListBusinessUnitPublicDocumentsRequest
                 */
                SharedListBusinessUnitPublicDocumentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitPublicDocumentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest} message SharedListBusinessUnitPublicDocumentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitPublicDocumentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.companyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitPublicDocumentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitPublicDocumentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitPublicDocumentsRequest;
            })();

            company.SharedListBusinessUnitPublicDocumentsResponse = (function() {

                /**
                 * Properties of a SharedListBusinessUnitPublicDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedListBusinessUnitPublicDocumentsResponse
                 * @property {Long|null} [total] SharedListBusinessUnitPublicDocumentsResponse total
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitPublicDocument>|null} [items] SharedListBusinessUnitPublicDocumentsResponse items
                 * @property {string|null} [pageToken] SharedListBusinessUnitPublicDocumentsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListBusinessUnitPublicDocumentsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedListBusinessUnitPublicDocumentsResponse.
                 * @implements ISharedListBusinessUnitPublicDocumentsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedListBusinessUnitPublicDocumentsResponse=} [properties] Properties to set
                 */
                function SharedListBusinessUnitPublicDocumentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListBusinessUnitPublicDocumentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                SharedListBusinessUnitPublicDocumentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListBusinessUnitPublicDocumentsResponse items.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitPublicDocument>} items
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                SharedListBusinessUnitPublicDocumentsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListBusinessUnitPublicDocumentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @instance
                 */
                SharedListBusinessUnitPublicDocumentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListBusinessUnitPublicDocumentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListBusinessUnitPublicDocumentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitPublicDocument.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListBusinessUnitPublicDocumentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse} SharedListBusinessUnitPublicDocumentsResponse
                 */
                SharedListBusinessUnitPublicDocumentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BusinessUnitPublicDocument.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListBusinessUnitPublicDocumentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @static
                 * @param {mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse} message SharedListBusinessUnitPublicDocumentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListBusinessUnitPublicDocumentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BusinessUnitPublicDocument.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListBusinessUnitPublicDocumentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedListBusinessUnitPublicDocumentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListBusinessUnitPublicDocumentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListBusinessUnitPublicDocumentsResponse;
            })();

            company.ListOrganizationsRequest = (function() {

                /**
                 * Properties of a ListOrganizationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationsRequest
                 * @property {string|null} [pageToken] ListOrganizationsRequest pageToken
                 * @property {Array.<string>|null} [organizationIds] ListOrganizationsRequest organizationIds
                 */

                /**
                 * Constructs a new ListOrganizationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationsRequest.
                 * @implements IListOrganizationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationsRequest=} [properties] Properties to set
                 */
                function ListOrganizationsRequest(properties) {
                    this.organizationIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrganizationsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @instance
                 */
                ListOrganizationsRequest.prototype.pageToken = "";

                /**
                 * ListOrganizationsRequest organizationIds.
                 * @member {Array.<string>} organizationIds
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @instance
                 */
                ListOrganizationsRequest.prototype.organizationIds = $util.emptyArray;

                /**
                 * Verifies a ListOrganizationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.organizationIds != null && message.hasOwnProperty("organizationIds")) {
                        if (!Array.isArray(message.organizationIds))
                            return "organizationIds: array expected";
                        for (let i = 0; i < message.organizationIds.length; ++i)
                            if (!$util.isString(message.organizationIds[i]))
                                return "organizationIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListOrganizationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationsRequest} ListOrganizationsRequest
                 */
                ListOrganizationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOrganizationsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.organizationIds) {
                        if (!Array.isArray(object.organizationIds))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationsRequest.organizationIds: array expected");
                        message.organizationIds = [];
                        for (let i = 0; i < object.organizationIds.length; ++i)
                            message.organizationIds[i] = String(object.organizationIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrganizationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationsRequest} message ListOrganizationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.organizationIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.organizationIds && message.organizationIds.length) {
                        object.organizationIds = [];
                        for (let j = 0; j < message.organizationIds.length; ++j)
                            object.organizationIds[j] = message.organizationIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListOrganizationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationsRequest;
            })();

            company.ListOrganizationsResponse = (function() {

                /**
                 * Properties of a ListOrganizationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationsResponse
                 * @property {Long|null} [total] ListOrganizationsResponse total
                 * @property {Array.<mtechnavi.api.company.IOrganization>|null} [items] ListOrganizationsResponse items
                 * @property {string|null} [pageToken] ListOrganizationsResponse pageToken
                 */

                /**
                 * Constructs a new ListOrganizationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationsResponse.
                 * @implements IListOrganizationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationsResponse=} [properties] Properties to set
                 */
                function ListOrganizationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrganizationsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @instance
                 */
                ListOrganizationsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListOrganizationsResponse items.
                 * @member {Array.<mtechnavi.api.company.IOrganization>} items
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @instance
                 */
                ListOrganizationsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListOrganizationsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @instance
                 */
                ListOrganizationsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListOrganizationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.Organization.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListOrganizationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationsResponse} ListOrganizationsResponse
                 */
                ListOrganizationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOrganizationsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListOrganizationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.Organization.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrganizationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationsResponse} message ListOrganizationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.Organization.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListOrganizationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationsResponse;
            })();

            company.SaveOrganizationRequest = (function() {

                /**
                 * Properties of a SaveOrganizationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISaveOrganizationRequest
                 * @property {mtechnavi.api.company.IOrganization|null} [organization] SaveOrganizationRequest organization
                 */

                /**
                 * Constructs a new SaveOrganizationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SaveOrganizationRequest.
                 * @implements ISaveOrganizationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISaveOrganizationRequest=} [properties] Properties to set
                 */
                function SaveOrganizationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveOrganizationRequest organization.
                 * @member {mtechnavi.api.company.IOrganization|null|undefined} organization
                 * @memberof mtechnavi.api.company.SaveOrganizationRequest
                 * @instance
                 */
                SaveOrganizationRequest.prototype.organization = null;

                /**
                 * Verifies a SaveOrganizationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SaveOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOrganizationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organization != null && message.hasOwnProperty("organization")) {
                        let error = $root.mtechnavi.api.company.Organization.verify(message.organization);
                        if (error)
                            return "organization." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveOrganizationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SaveOrganizationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SaveOrganizationRequest} SaveOrganizationRequest
                 */
                SaveOrganizationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SaveOrganizationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SaveOrganizationRequest();
                    if (object.organization != null) {
                        if (typeof object.organization !== "object")
                            throw TypeError(".mtechnavi.api.company.SaveOrganizationRequest.organization: object expected");
                        message.organization = $root.mtechnavi.api.company.Organization.fromObject(object.organization);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveOrganizationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SaveOrganizationRequest
                 * @static
                 * @param {mtechnavi.api.company.SaveOrganizationRequest} message SaveOrganizationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOrganizationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.organization = null;
                    if (message.organization != null && message.hasOwnProperty("organization"))
                        object.organization = $root.mtechnavi.api.company.Organization.toObject(message.organization, options);
                    return object;
                };

                /**
                 * Converts this SaveOrganizationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SaveOrganizationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOrganizationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveOrganizationRequest;
            })();

            company.ListOrganizationRelationsRequest = (function() {

                /**
                 * Properties of a ListOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationRelationsRequest
                 * @property {string|null} [pageToken] ListOrganizationRelationsRequest pageToken
                 * @property {Array.<string>|null} [organizationIds] ListOrganizationRelationsRequest organizationIds
                 * @property {Array.<string>|null} [userIds] ListOrganizationRelationsRequest userIds
                 */

                /**
                 * Constructs a new ListOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationRelationsRequest.
                 * @implements IListOrganizationRelationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationRelationsRequest=} [properties] Properties to set
                 */
                function ListOrganizationRelationsRequest(properties) {
                    this.organizationIds = [];
                    this.userIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrganizationRelationsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @instance
                 */
                ListOrganizationRelationsRequest.prototype.pageToken = "";

                /**
                 * ListOrganizationRelationsRequest organizationIds.
                 * @member {Array.<string>} organizationIds
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @instance
                 */
                ListOrganizationRelationsRequest.prototype.organizationIds = $util.emptyArray;

                /**
                 * ListOrganizationRelationsRequest userIds.
                 * @member {Array.<string>} userIds
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @instance
                 */
                ListOrganizationRelationsRequest.prototype.userIds = $util.emptyArray;

                /**
                 * Verifies a ListOrganizationRelationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationRelationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.organizationIds != null && message.hasOwnProperty("organizationIds")) {
                        if (!Array.isArray(message.organizationIds))
                            return "organizationIds: array expected";
                        for (let i = 0; i < message.organizationIds.length; ++i)
                            if (!$util.isString(message.organizationIds[i]))
                                return "organizationIds: string[] expected";
                    }
                    if (message.userIds != null && message.hasOwnProperty("userIds")) {
                        if (!Array.isArray(message.userIds))
                            return "userIds: array expected";
                        for (let i = 0; i < message.userIds.length; ++i)
                            if (!$util.isString(message.userIds[i]))
                                return "userIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListOrganizationRelationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationRelationsRequest} ListOrganizationRelationsRequest
                 */
                ListOrganizationRelationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationRelationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOrganizationRelationsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.organizationIds) {
                        if (!Array.isArray(object.organizationIds))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationRelationsRequest.organizationIds: array expected");
                        message.organizationIds = [];
                        for (let i = 0; i < object.organizationIds.length; ++i)
                            message.organizationIds[i] = String(object.organizationIds[i]);
                    }
                    if (object.userIds) {
                        if (!Array.isArray(object.userIds))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationRelationsRequest.userIds: array expected");
                        message.userIds = [];
                        for (let i = 0; i < object.userIds.length; ++i)
                            message.userIds[i] = String(object.userIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrganizationRelationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationRelationsRequest} message ListOrganizationRelationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationRelationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.organizationIds = [];
                        object.userIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.organizationIds && message.organizationIds.length) {
                        object.organizationIds = [];
                        for (let j = 0; j < message.organizationIds.length; ++j)
                            object.organizationIds[j] = message.organizationIds[j];
                    }
                    if (message.userIds && message.userIds.length) {
                        object.userIds = [];
                        for (let j = 0; j < message.userIds.length; ++j)
                            object.userIds[j] = message.userIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListOrganizationRelationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationRelationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationRelationsRequest;
            })();

            company.ListOrganizationRelationsResponse = (function() {

                /**
                 * Properties of a ListOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationRelationsResponse
                 * @property {Long|null} [total] ListOrganizationRelationsResponse total
                 * @property {Array.<mtechnavi.api.company.IOrganizationRelation>|null} [items] ListOrganizationRelationsResponse items
                 * @property {string|null} [pageToken] ListOrganizationRelationsResponse pageToken
                 */

                /**
                 * Constructs a new ListOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationRelationsResponse.
                 * @implements IListOrganizationRelationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationRelationsResponse=} [properties] Properties to set
                 */
                function ListOrganizationRelationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrganizationRelationsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @instance
                 */
                ListOrganizationRelationsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListOrganizationRelationsResponse items.
                 * @member {Array.<mtechnavi.api.company.IOrganizationRelation>} items
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @instance
                 */
                ListOrganizationRelationsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListOrganizationRelationsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @instance
                 */
                ListOrganizationRelationsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListOrganizationRelationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationRelationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.OrganizationRelation.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListOrganizationRelationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationRelationsResponse} ListOrganizationRelationsResponse
                 */
                ListOrganizationRelationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationRelationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOrganizationRelationsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationRelationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListOrganizationRelationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.OrganizationRelation.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrganizationRelationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationRelationsResponse} message ListOrganizationRelationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationRelationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.OrganizationRelation.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListOrganizationRelationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationRelationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationRelationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationRelationsResponse;
            })();

            company.SaveOrganizationRelationRequest = (function() {

                /**
                 * Properties of a SaveOrganizationRelationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ISaveOrganizationRelationRequest
                 * @property {mtechnavi.api.company.IOrganizationRelation|null} [organizationRelation] SaveOrganizationRelationRequest organizationRelation
                 */

                /**
                 * Constructs a new SaveOrganizationRelationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SaveOrganizationRelationRequest.
                 * @implements ISaveOrganizationRelationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ISaveOrganizationRelationRequest=} [properties] Properties to set
                 */
                function SaveOrganizationRelationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveOrganizationRelationRequest organizationRelation.
                 * @member {mtechnavi.api.company.IOrganizationRelation|null|undefined} organizationRelation
                 * @memberof mtechnavi.api.company.SaveOrganizationRelationRequest
                 * @instance
                 */
                SaveOrganizationRelationRequest.prototype.organizationRelation = null;

                /**
                 * Verifies a SaveOrganizationRelationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SaveOrganizationRelationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveOrganizationRelationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organizationRelation != null && message.hasOwnProperty("organizationRelation")) {
                        let error = $root.mtechnavi.api.company.OrganizationRelation.verify(message.organizationRelation);
                        if (error)
                            return "organizationRelation." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveOrganizationRelationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SaveOrganizationRelationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SaveOrganizationRelationRequest} SaveOrganizationRelationRequest
                 */
                SaveOrganizationRelationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SaveOrganizationRelationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.SaveOrganizationRelationRequest();
                    if (object.organizationRelation != null) {
                        if (typeof object.organizationRelation !== "object")
                            throw TypeError(".mtechnavi.api.company.SaveOrganizationRelationRequest.organizationRelation: object expected");
                        message.organizationRelation = $root.mtechnavi.api.company.OrganizationRelation.fromObject(object.organizationRelation);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveOrganizationRelationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SaveOrganizationRelationRequest
                 * @static
                 * @param {mtechnavi.api.company.SaveOrganizationRelationRequest} message SaveOrganizationRelationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveOrganizationRelationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.organizationRelation = null;
                    if (message.organizationRelation != null && message.hasOwnProperty("organizationRelation"))
                        object.organizationRelation = $root.mtechnavi.api.company.OrganizationRelation.toObject(message.organizationRelation, options);
                    return object;
                };

                /**
                 * Converts this SaveOrganizationRelationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SaveOrganizationRelationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveOrganizationRelationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveOrganizationRelationRequest;
            })();

            company.BulkWriteOrganizationRelationsRequest = (function() {

                /**
                 * Properties of a BulkWriteOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IBulkWriteOrganizationRelationsRequest
                 * @property {string|null} [organizationId] BulkWriteOrganizationRelationsRequest organizationId
                 * @property {Array.<mtechnavi.api.company.IOrganizationRelation>|null} [items] BulkWriteOrganizationRelationsRequest items
                 */

                /**
                 * Constructs a new BulkWriteOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BulkWriteOrganizationRelationsRequest.
                 * @implements IBulkWriteOrganizationRelationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IBulkWriteOrganizationRelationsRequest=} [properties] Properties to set
                 */
                function BulkWriteOrganizationRelationsRequest(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteOrganizationRelationsRequest organizationId.
                 * @member {string} organizationId
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @instance
                 */
                BulkWriteOrganizationRelationsRequest.prototype.organizationId = "";

                /**
                 * BulkWriteOrganizationRelationsRequest items.
                 * @member {Array.<mtechnavi.api.company.IOrganizationRelation>} items
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @instance
                 */
                BulkWriteOrganizationRelationsRequest.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteOrganizationRelationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteOrganizationRelationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        if (!$util.isString(message.organizationId))
                            return "organizationId: string expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.OrganizationRelation.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteOrganizationRelationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BulkWriteOrganizationRelationsRequest} BulkWriteOrganizationRelationsRequest
                 */
                BulkWriteOrganizationRelationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BulkWriteOrganizationRelationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.BulkWriteOrganizationRelationsRequest();
                    if (object.organizationId != null)
                        message.organizationId = String(object.organizationId);
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.BulkWriteOrganizationRelationsRequest.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BulkWriteOrganizationRelationsRequest.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.OrganizationRelation.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteOrganizationRelationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @static
                 * @param {mtechnavi.api.company.BulkWriteOrganizationRelationsRequest} message BulkWriteOrganizationRelationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteOrganizationRelationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        object.organizationId = "";
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.OrganizationRelation.toObject(message.items[j], options);
                    }
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        object.organizationId = message.organizationId;
                    return object;
                };

                /**
                 * Converts this BulkWriteOrganizationRelationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteOrganizationRelationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteOrganizationRelationsRequest;
            })();

            company.BulkWriteOrganizationRelationsResponse = (function() {

                /**
                 * Properties of a BulkWriteOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IBulkWriteOrganizationRelationsResponse
                 * @property {Array.<mtechnavi.api.company.IOrganizationRelation>|null} [items] BulkWriteOrganizationRelationsResponse items
                 */

                /**
                 * Constructs a new BulkWriteOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BulkWriteOrganizationRelationsResponse.
                 * @implements IBulkWriteOrganizationRelationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IBulkWriteOrganizationRelationsResponse=} [properties] Properties to set
                 */
                function BulkWriteOrganizationRelationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteOrganizationRelationsResponse items.
                 * @member {Array.<mtechnavi.api.company.IOrganizationRelation>} items
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsResponse
                 * @instance
                 */
                BulkWriteOrganizationRelationsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteOrganizationRelationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteOrganizationRelationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.OrganizationRelation.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteOrganizationRelationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BulkWriteOrganizationRelationsResponse} BulkWriteOrganizationRelationsResponse
                 */
                BulkWriteOrganizationRelationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BulkWriteOrganizationRelationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.BulkWriteOrganizationRelationsResponse();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.BulkWriteOrganizationRelationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BulkWriteOrganizationRelationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.OrganizationRelation.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteOrganizationRelationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsResponse
                 * @static
                 * @param {mtechnavi.api.company.BulkWriteOrganizationRelationsResponse} message BulkWriteOrganizationRelationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteOrganizationRelationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.OrganizationRelation.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteOrganizationRelationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BulkWriteOrganizationRelationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteOrganizationRelationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteOrganizationRelationsResponse;
            })();

            company.DeleteOrganizationWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteOrganizationWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteOrganizationWithRelatedItemsRequest
                 * @property {string|null} [organizationId] DeleteOrganizationWithRelatedItemsRequest organizationId
                 */

                /**
                 * Constructs a new DeleteOrganizationWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteOrganizationWithRelatedItemsRequest.
                 * @implements IDeleteOrganizationWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteOrganizationWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteOrganizationWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteOrganizationWithRelatedItemsRequest organizationId.
                 * @member {string} organizationId
                 * @memberof mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest
                 * @instance
                 */
                DeleteOrganizationWithRelatedItemsRequest.prototype.organizationId = "";

                /**
                 * Verifies a DeleteOrganizationWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteOrganizationWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        if (!$util.isString(message.organizationId))
                            return "organizationId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteOrganizationWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest} DeleteOrganizationWithRelatedItemsRequest
                 */
                DeleteOrganizationWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest();
                    if (object.organizationId != null)
                        message.organizationId = String(object.organizationId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteOrganizationWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest} message DeleteOrganizationWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteOrganizationWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.organizationId = "";
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        object.organizationId = message.organizationId;
                    return object;
                };

                /**
                 * Converts this DeleteOrganizationWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteOrganizationWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteOrganizationWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteOrganizationWithRelatedItemsRequest;
            })();

            company.ImportOrganizationRelationsRequest = (function() {

                /**
                 * Properties of an ImportOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IImportOrganizationRelationsRequest
                 * @property {string|null} [url] ImportOrganizationRelationsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportOrganizationRelationsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportOrganizationRelationsRequest dryRun
                 * @property {boolean|null} [exportError] ImportOrganizationRelationsRequest exportError
                 */

                /**
                 * Constructs a new ImportOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportOrganizationRelationsRequest.
                 * @implements IImportOrganizationRelationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IImportOrganizationRelationsRequest=} [properties] Properties to set
                 */
                function ImportOrganizationRelationsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportOrganizationRelationsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @instance
                 */
                ImportOrganizationRelationsRequest.prototype.url = "";

                /**
                 * ImportOrganizationRelationsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @instance
                 */
                ImportOrganizationRelationsRequest.prototype.fileFormat = null;

                /**
                 * ImportOrganizationRelationsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @instance
                 */
                ImportOrganizationRelationsRequest.prototype.dryRun = false;

                /**
                 * ImportOrganizationRelationsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @instance
                 */
                ImportOrganizationRelationsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportOrganizationRelationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportOrganizationRelationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportOrganizationRelationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportOrganizationRelationsRequest} ImportOrganizationRelationsRequest
                 */
                ImportOrganizationRelationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportOrganizationRelationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportOrganizationRelationsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportOrganizationRelationsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportOrganizationRelationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @static
                 * @param {mtechnavi.api.company.ImportOrganizationRelationsRequest} message ImportOrganizationRelationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportOrganizationRelationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportOrganizationRelationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportOrganizationRelationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportOrganizationRelationsRequest;
            })();

            company.ImportOrganizationRelationsResponse = (function() {

                /**
                 * Properties of an ImportOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IImportOrganizationRelationsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportOrganizationRelationsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportOrganizationRelationsResponse errors
                 * @property {string|null} [assetId] ImportOrganizationRelationsResponse assetId
                 */

                /**
                 * Constructs a new ImportOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImportOrganizationRelationsResponse.
                 * @implements IImportOrganizationRelationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IImportOrganizationRelationsResponse=} [properties] Properties to set
                 */
                function ImportOrganizationRelationsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportOrganizationRelationsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @instance
                 */
                ImportOrganizationRelationsResponse.prototype.summary = null;

                /**
                 * ImportOrganizationRelationsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @instance
                 */
                ImportOrganizationRelationsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportOrganizationRelationsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @instance
                 */
                ImportOrganizationRelationsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportOrganizationRelationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportOrganizationRelationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportOrganizationRelationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImportOrganizationRelationsResponse} ImportOrganizationRelationsResponse
                 */
                ImportOrganizationRelationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImportOrganizationRelationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImportOrganizationRelationsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.company.ImportOrganizationRelationsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.company.ImportOrganizationRelationsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ImportOrganizationRelationsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportOrganizationRelationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @static
                 * @param {mtechnavi.api.company.ImportOrganizationRelationsResponse} message ImportOrganizationRelationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportOrganizationRelationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportOrganizationRelationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImportOrganizationRelationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportOrganizationRelationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportOrganizationRelationsResponse;
            })();

            company.ExportOrganizationRelationsRequest = (function() {

                /**
                 * Properties of an ExportOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IExportOrganizationRelationsRequest
                 * @property {Array.<string>|null} [ids] ExportOrganizationRelationsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportOrganizationRelationsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportOrganizationRelationsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportOrganizationRelationsRequest.
                 * @implements IExportOrganizationRelationsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IExportOrganizationRelationsRequest=} [properties] Properties to set
                 */
                function ExportOrganizationRelationsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOrganizationRelationsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @instance
                 */
                ExportOrganizationRelationsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportOrganizationRelationsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @instance
                 */
                ExportOrganizationRelationsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportOrganizationRelationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOrganizationRelationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportOrganizationRelationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportOrganizationRelationsRequest} ExportOrganizationRelationsRequest
                 */
                ExportOrganizationRelationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportOrganizationRelationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportOrganizationRelationsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.company.ExportOrganizationRelationsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.company.ExportOrganizationRelationsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOrganizationRelationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @static
                 * @param {mtechnavi.api.company.ExportOrganizationRelationsRequest} message ExportOrganizationRelationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOrganizationRelationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportOrganizationRelationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOrganizationRelationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOrganizationRelationsRequest;
            })();

            company.ExportOrganizationRelationsResponse = (function() {

                /**
                 * Properties of an ExportOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IExportOrganizationRelationsResponse
                 * @property {string|null} [assetId] ExportOrganizationRelationsResponse assetId
                 */

                /**
                 * Constructs a new ExportOrganizationRelationsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ExportOrganizationRelationsResponse.
                 * @implements IExportOrganizationRelationsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IExportOrganizationRelationsResponse=} [properties] Properties to set
                 */
                function ExportOrganizationRelationsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOrganizationRelationsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsResponse
                 * @instance
                 */
                ExportOrganizationRelationsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportOrganizationRelationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOrganizationRelationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportOrganizationRelationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ExportOrganizationRelationsResponse} ExportOrganizationRelationsResponse
                 */
                ExportOrganizationRelationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ExportOrganizationRelationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ExportOrganizationRelationsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOrganizationRelationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsResponse
                 * @static
                 * @param {mtechnavi.api.company.ExportOrganizationRelationsResponse} message ExportOrganizationRelationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOrganizationRelationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportOrganizationRelationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ExportOrganizationRelationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOrganizationRelationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOrganizationRelationsResponse;
            })();

            company.ListOrganizationRelationFormatsRequest = (function() {

                /**
                 * Properties of a ListOrganizationRelationFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationRelationFormatsRequest
                 */

                /**
                 * Constructs a new ListOrganizationRelationFormatsRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationRelationFormatsRequest.
                 * @implements IListOrganizationRelationFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationRelationFormatsRequest=} [properties] Properties to set
                 */
                function ListOrganizationRelationFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListOrganizationRelationFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationRelationFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListOrganizationRelationFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationRelationFormatsRequest} ListOrganizationRelationFormatsRequest
                 */
                ListOrganizationRelationFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationRelationFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.company.ListOrganizationRelationFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListOrganizationRelationFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsRequest
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationRelationFormatsRequest} message ListOrganizationRelationFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationRelationFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListOrganizationRelationFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationRelationFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationRelationFormatsRequest;
            })();

            company.ListOrganizationRelationFormatsResponse = (function() {

                /**
                 * Properties of a ListOrganizationRelationFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListOrganizationRelationFormatsResponse
                 * @property {Long|null} [total] ListOrganizationRelationFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListOrganizationRelationFormatsResponse items
                 */

                /**
                 * Constructs a new ListOrganizationRelationFormatsResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListOrganizationRelationFormatsResponse.
                 * @implements IListOrganizationRelationFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListOrganizationRelationFormatsResponse=} [properties] Properties to set
                 */
                function ListOrganizationRelationFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrganizationRelationFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @instance
                 */
                ListOrganizationRelationFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListOrganizationRelationFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @instance
                 */
                ListOrganizationRelationFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListOrganizationRelationFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrganizationRelationFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListOrganizationRelationFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListOrganizationRelationFormatsResponse} ListOrganizationRelationFormatsResponse
                 */
                ListOrganizationRelationFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListOrganizationRelationFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListOrganizationRelationFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListOrganizationRelationFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListOrganizationRelationFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrganizationRelationFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @static
                 * @param {mtechnavi.api.company.ListOrganizationRelationFormatsResponse} message ListOrganizationRelationFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrganizationRelationFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListOrganizationRelationFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListOrganizationRelationFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrganizationRelationFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrganizationRelationFormatsResponse;
            })();

            company.ListBillingCompanysRequest = (function() {

                /**
                 * Properties of a ListBillingCompanysRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IListBillingCompanysRequest
                 * @property {string|null} [pageToken] ListBillingCompanysRequest pageToken
                 * @property {Array.<string>|null} [billingCompanyIds] ListBillingCompanysRequest billingCompanyIds
                 */

                /**
                 * Constructs a new ListBillingCompanysRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBillingCompanysRequest.
                 * @implements IListBillingCompanysRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IListBillingCompanysRequest=} [properties] Properties to set
                 */
                function ListBillingCompanysRequest(properties) {
                    this.billingCompanyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBillingCompanysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @instance
                 */
                ListBillingCompanysRequest.prototype.pageToken = "";

                /**
                 * ListBillingCompanysRequest billingCompanyIds.
                 * @member {Array.<string>} billingCompanyIds
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @instance
                 */
                ListBillingCompanysRequest.prototype.billingCompanyIds = $util.emptyArray;

                /**
                 * Verifies a ListBillingCompanysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBillingCompanysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.billingCompanyIds != null && message.hasOwnProperty("billingCompanyIds")) {
                        if (!Array.isArray(message.billingCompanyIds))
                            return "billingCompanyIds: array expected";
                        for (let i = 0; i < message.billingCompanyIds.length; ++i)
                            if (!$util.isString(message.billingCompanyIds[i]))
                                return "billingCompanyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBillingCompanysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBillingCompanysRequest} ListBillingCompanysRequest
                 */
                ListBillingCompanysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBillingCompanysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBillingCompanysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.billingCompanyIds) {
                        if (!Array.isArray(object.billingCompanyIds))
                            throw TypeError(".mtechnavi.api.company.ListBillingCompanysRequest.billingCompanyIds: array expected");
                        message.billingCompanyIds = [];
                        for (let i = 0; i < object.billingCompanyIds.length; ++i)
                            message.billingCompanyIds[i] = String(object.billingCompanyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBillingCompanysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @static
                 * @param {mtechnavi.api.company.ListBillingCompanysRequest} message ListBillingCompanysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBillingCompanysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.billingCompanyIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.billingCompanyIds && message.billingCompanyIds.length) {
                        object.billingCompanyIds = [];
                        for (let j = 0; j < message.billingCompanyIds.length; ++j)
                            object.billingCompanyIds[j] = message.billingCompanyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBillingCompanysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBillingCompanysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBillingCompanysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBillingCompanysRequest;
            })();

            company.ListBillingCompanysResponse = (function() {

                /**
                 * Properties of a ListBillingCompanysResponse.
                 * @memberof mtechnavi.api.company
                 * @interface IListBillingCompanysResponse
                 * @property {Long|null} [total] ListBillingCompanysResponse total
                 * @property {Array.<mtechnavi.api.company.IBillingCompany>|null} [items] ListBillingCompanysResponse items
                 * @property {string|null} [pageToken] ListBillingCompanysResponse pageToken
                 */

                /**
                 * Constructs a new ListBillingCompanysResponse.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ListBillingCompanysResponse.
                 * @implements IListBillingCompanysResponse
                 * @constructor
                 * @param {mtechnavi.api.company.IListBillingCompanysResponse=} [properties] Properties to set
                 */
                function ListBillingCompanysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBillingCompanysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @instance
                 */
                ListBillingCompanysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBillingCompanysResponse items.
                 * @member {Array.<mtechnavi.api.company.IBillingCompany>} items
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @instance
                 */
                ListBillingCompanysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBillingCompanysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @instance
                 */
                ListBillingCompanysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBillingCompanysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBillingCompanysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.company.BillingCompany.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBillingCompanysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ListBillingCompanysResponse} ListBillingCompanysResponse
                 */
                ListBillingCompanysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ListBillingCompanysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.company.ListBillingCompanysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.company.ListBillingCompanysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.ListBillingCompanysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.company.BillingCompany.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBillingCompanysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @static
                 * @param {mtechnavi.api.company.ListBillingCompanysResponse} message ListBillingCompanysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBillingCompanysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.company.BillingCompany.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBillingCompanysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ListBillingCompanysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBillingCompanysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBillingCompanysResponse;
            })();

            company.GetBillingCompanyRequest = (function() {

                /**
                 * Properties of a GetBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IGetBillingCompanyRequest
                 * @property {string|null} [billingCompanyId] GetBillingCompanyRequest billingCompanyId
                 */

                /**
                 * Constructs a new GetBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a GetBillingCompanyRequest.
                 * @implements IGetBillingCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IGetBillingCompanyRequest=} [properties] Properties to set
                 */
                function GetBillingCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBillingCompanyRequest billingCompanyId.
                 * @member {string} billingCompanyId
                 * @memberof mtechnavi.api.company.GetBillingCompanyRequest
                 * @instance
                 */
                GetBillingCompanyRequest.prototype.billingCompanyId = "";

                /**
                 * Verifies a GetBillingCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.GetBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBillingCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        if (!$util.isString(message.billingCompanyId))
                            return "billingCompanyId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBillingCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.GetBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.GetBillingCompanyRequest} GetBillingCompanyRequest
                 */
                GetBillingCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.GetBillingCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.GetBillingCompanyRequest();
                    if (object.billingCompanyId != null)
                        message.billingCompanyId = String(object.billingCompanyId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBillingCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.GetBillingCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.GetBillingCompanyRequest} message GetBillingCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBillingCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.billingCompanyId = "";
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        object.billingCompanyId = message.billingCompanyId;
                    return object;
                };

                /**
                 * Converts this GetBillingCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.GetBillingCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBillingCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBillingCompanyRequest;
            })();

            company.CreateBillingCompanyRequest = (function() {

                /**
                 * Properties of a CreateBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ICreateBillingCompanyRequest
                 * @property {mtechnavi.api.company.IBillingCompany|null} [billingCompany] CreateBillingCompanyRequest billingCompany
                 */

                /**
                 * Constructs a new CreateBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CreateBillingCompanyRequest.
                 * @implements ICreateBillingCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ICreateBillingCompanyRequest=} [properties] Properties to set
                 */
                function CreateBillingCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBillingCompanyRequest billingCompany.
                 * @member {mtechnavi.api.company.IBillingCompany|null|undefined} billingCompany
                 * @memberof mtechnavi.api.company.CreateBillingCompanyRequest
                 * @instance
                 */
                CreateBillingCompanyRequest.prototype.billingCompany = null;

                /**
                 * Verifies a CreateBillingCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CreateBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBillingCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany")) {
                        let error = $root.mtechnavi.api.company.BillingCompany.verify(message.billingCompany);
                        if (error)
                            return "billingCompany." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBillingCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CreateBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CreateBillingCompanyRequest} CreateBillingCompanyRequest
                 */
                CreateBillingCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CreateBillingCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.CreateBillingCompanyRequest();
                    if (object.billingCompany != null) {
                        if (typeof object.billingCompany !== "object")
                            throw TypeError(".mtechnavi.api.company.CreateBillingCompanyRequest.billingCompany: object expected");
                        message.billingCompany = $root.mtechnavi.api.company.BillingCompany.fromObject(object.billingCompany);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBillingCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CreateBillingCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.CreateBillingCompanyRequest} message CreateBillingCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBillingCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.billingCompany = null;
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany"))
                        object.billingCompany = $root.mtechnavi.api.company.BillingCompany.toObject(message.billingCompany, options);
                    return object;
                };

                /**
                 * Converts this CreateBillingCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CreateBillingCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBillingCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBillingCompanyRequest;
            })();

            company.UpdateBillingCompanyRequest = (function() {

                /**
                 * Properties of an UpdateBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IUpdateBillingCompanyRequest
                 * @property {mtechnavi.api.company.IBillingCompany|null} [billingCompany] UpdateBillingCompanyRequest billingCompany
                 */

                /**
                 * Constructs a new UpdateBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an UpdateBillingCompanyRequest.
                 * @implements IUpdateBillingCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IUpdateBillingCompanyRequest=} [properties] Properties to set
                 */
                function UpdateBillingCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBillingCompanyRequest billingCompany.
                 * @member {mtechnavi.api.company.IBillingCompany|null|undefined} billingCompany
                 * @memberof mtechnavi.api.company.UpdateBillingCompanyRequest
                 * @instance
                 */
                UpdateBillingCompanyRequest.prototype.billingCompany = null;

                /**
                 * Verifies an UpdateBillingCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.UpdateBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBillingCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany")) {
                        let error = $root.mtechnavi.api.company.BillingCompany.verify(message.billingCompany);
                        if (error)
                            return "billingCompany." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBillingCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.UpdateBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.UpdateBillingCompanyRequest} UpdateBillingCompanyRequest
                 */
                UpdateBillingCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.UpdateBillingCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.UpdateBillingCompanyRequest();
                    if (object.billingCompany != null) {
                        if (typeof object.billingCompany !== "object")
                            throw TypeError(".mtechnavi.api.company.UpdateBillingCompanyRequest.billingCompany: object expected");
                        message.billingCompany = $root.mtechnavi.api.company.BillingCompany.fromObject(object.billingCompany);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBillingCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.UpdateBillingCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.UpdateBillingCompanyRequest} message UpdateBillingCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBillingCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.billingCompany = null;
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany"))
                        object.billingCompany = $root.mtechnavi.api.company.BillingCompany.toObject(message.billingCompany, options);
                    return object;
                };

                /**
                 * Converts this UpdateBillingCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.UpdateBillingCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBillingCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBillingCompanyRequest;
            })();

            company.DeleteBillingCompanyRequest = (function() {

                /**
                 * Properties of a DeleteBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IDeleteBillingCompanyRequest
                 * @property {mtechnavi.api.company.IBillingCompany|null} [billingCompany] DeleteBillingCompanyRequest billingCompany
                 */

                /**
                 * Constructs a new DeleteBillingCompanyRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeleteBillingCompanyRequest.
                 * @implements IDeleteBillingCompanyRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IDeleteBillingCompanyRequest=} [properties] Properties to set
                 */
                function DeleteBillingCompanyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBillingCompanyRequest billingCompany.
                 * @member {mtechnavi.api.company.IBillingCompany|null|undefined} billingCompany
                 * @memberof mtechnavi.api.company.DeleteBillingCompanyRequest
                 * @instance
                 */
                DeleteBillingCompanyRequest.prototype.billingCompany = null;

                /**
                 * Verifies a DeleteBillingCompanyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeleteBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBillingCompanyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany")) {
                        let error = $root.mtechnavi.api.company.BillingCompany.verify(message.billingCompany);
                        if (error)
                            return "billingCompany." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBillingCompanyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeleteBillingCompanyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeleteBillingCompanyRequest} DeleteBillingCompanyRequest
                 */
                DeleteBillingCompanyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeleteBillingCompanyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.DeleteBillingCompanyRequest();
                    if (object.billingCompany != null) {
                        if (typeof object.billingCompany !== "object")
                            throw TypeError(".mtechnavi.api.company.DeleteBillingCompanyRequest.billingCompany: object expected");
                        message.billingCompany = $root.mtechnavi.api.company.BillingCompany.fromObject(object.billingCompany);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBillingCompanyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeleteBillingCompanyRequest
                 * @static
                 * @param {mtechnavi.api.company.DeleteBillingCompanyRequest} message DeleteBillingCompanyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBillingCompanyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.billingCompany = null;
                    if (message.billingCompany != null && message.hasOwnProperty("billingCompany"))
                        object.billingCompany = $root.mtechnavi.api.company.BillingCompany.toObject(message.billingCompany, options);
                    return object;
                };

                /**
                 * Converts this DeleteBillingCompanyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeleteBillingCompanyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBillingCompanyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBillingCompanyRequest;
            })();

            company.Company = (function() {

                /**
                 * Properties of a Company.
                 * @memberof mtechnavi.api.company
                 * @interface ICompany
                 * @property {string|null} [companyId] Company companyId
                 * @property {Object.<string,string>|null} [displayNameLang] Company displayNameLang
                 * @property {mtechnavi.api.company.ICompanyLegalProperties|null} [companyLegalProperties] Company companyLegalProperties
                 * @property {mtechnavi.api.company.IProfile|null} [profile] Company profile
                 * @property {mtechnavi.api.company.ICompanyStatus|null} [companyStatus] Company companyStatus
                 * @property {string|null} [timezoneName] Company timezoneName
                 * @property {boolean|null} [published] Company published
                 * @property {Long|null} [deletedAt] Company deletedAt
                 * @property {Long|null} [createdAt] Company createdAt
                 * @property {Long|null} [updatedAt] Company updatedAt
                 */

                /**
                 * Constructs a new Company.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a Company.
                 * @implements ICompany
                 * @constructor
                 * @param {mtechnavi.api.company.ICompany=} [properties] Properties to set
                 */
                function Company(properties) {
                    this.displayNameLang = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Company companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.companyId = "";

                /**
                 * Company displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.displayNameLang = $util.emptyObject;

                /**
                 * Company companyLegalProperties.
                 * @member {mtechnavi.api.company.ICompanyLegalProperties|null|undefined} companyLegalProperties
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.companyLegalProperties = null;

                /**
                 * Company profile.
                 * @member {mtechnavi.api.company.IProfile|null|undefined} profile
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.profile = null;

                /**
                 * Company companyStatus.
                 * @member {mtechnavi.api.company.ICompanyStatus|null|undefined} companyStatus
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.companyStatus = null;

                /**
                 * Company timezoneName.
                 * @member {string} timezoneName
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.timezoneName = "";

                /**
                 * Company published.
                 * @member {boolean} published
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.published = false;

                /**
                 * Company deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Company createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Company updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 */
                Company.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Company message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Company
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Company.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties")) {
                        let error = $root.mtechnavi.api.company.CompanyLegalProperties.verify(message.companyLegalProperties);
                        if (error)
                            return "companyLegalProperties." + error;
                    }
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        let error = $root.mtechnavi.api.company.Profile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    if (message.companyStatus != null && message.hasOwnProperty("companyStatus")) {
                        let error = $root.mtechnavi.api.company.CompanyStatus.verify(message.companyStatus);
                        if (error)
                            return "companyStatus." + error;
                    }
                    if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                        if (!$util.isString(message.timezoneName))
                            return "timezoneName: string expected";
                    if (message.published != null && message.hasOwnProperty("published"))
                        if (typeof message.published !== "boolean")
                            return "published: boolean expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Company message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Company
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Company} Company
                 */
                Company.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Company)
                        return object;
                    let message = new $root.mtechnavi.api.company.Company();
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.company.Company.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.companyLegalProperties != null) {
                        if (typeof object.companyLegalProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.Company.companyLegalProperties: object expected");
                        message.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.fromObject(object.companyLegalProperties);
                    }
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".mtechnavi.api.company.Company.profile: object expected");
                        message.profile = $root.mtechnavi.api.company.Profile.fromObject(object.profile);
                    }
                    if (object.companyStatus != null) {
                        if (typeof object.companyStatus !== "object")
                            throw TypeError(".mtechnavi.api.company.Company.companyStatus: object expected");
                        message.companyStatus = $root.mtechnavi.api.company.CompanyStatus.fromObject(object.companyStatus);
                    }
                    if (object.timezoneName != null)
                        message.timezoneName = String(object.timezoneName);
                    if (object.published != null)
                        message.published = Boolean(object.published);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Company message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Company
                 * @static
                 * @param {mtechnavi.api.company.Company} message Company
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Company.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.companyId = "";
                        object.companyLegalProperties = null;
                        object.profile = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.companyStatus = null;
                        object.timezoneName = "";
                        object.published = false;
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties"))
                        object.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.toObject(message.companyLegalProperties, options);
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.mtechnavi.api.company.Profile.toObject(message.profile, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.companyStatus != null && message.hasOwnProperty("companyStatus"))
                        object.companyStatus = $root.mtechnavi.api.company.CompanyStatus.toObject(message.companyStatus, options);
                    if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                        object.timezoneName = message.timezoneName;
                    if (message.published != null && message.hasOwnProperty("published"))
                        object.published = message.published;
                    return object;
                };

                /**
                 * Converts this Company to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Company
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Company.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Company;
            })();

            company.ComponentUnit = (function() {

                /**
                 * Properties of a ComponentUnit.
                 * @memberof mtechnavi.api.company
                 * @interface IComponentUnit
                 * @property {string|null} [componentUnitId] ComponentUnit componentUnitId
                 * @property {string|null} [companyId] ComponentUnit companyId
                 * @property {string|null} [componentUnitCode] ComponentUnit componentUnitCode
                 * @property {Object.<string,string>|null} [displayNameLang] ComponentUnit displayNameLang
                 * @property {string|null} [phoneNumber] ComponentUnit phoneNumber
                 * @property {sharelib.IAddress|null} [address] ComponentUnit address
                 * @property {mtechnavi.api.company.ComponentUnit.IPlaceProperties|null} [placeProperties] ComponentUnit placeProperties
                 * @property {sharelib.INameOption|null} [postUnit] ComponentUnit postUnit
                 * @property {mtechnavi.api.company.IPostUnitProperties|null} [postUnitProperties] ComponentUnit postUnitProperties
                 * @property {sharelib.INameOption|null} [organizationUnit] ComponentUnit organizationUnit
                 * @property {mtechnavi.api.company.IOrganizationUnitProperties|null} [organizationUnitProperties] ComponentUnit organizationUnitProperties
                 * @property {sharelib.INameOption|null} [deliveryPoint] ComponentUnit deliveryPoint
                 * @property {mtechnavi.api.company.IDeliveryPointProperties|null} [deliveryPointProperties] ComponentUnit deliveryPointProperties
                 * @property {sharelib.INameOption|null} [office] ComponentUnit office
                 * @property {mtechnavi.api.company.IOfficeProperties|null} [officeProperties] ComponentUnit officeProperties
                 * @property {Array.<string>|null} [linkageItem] ComponentUnit linkageItem
                 * @property {sharelib.INameOption|null} [usable] ComponentUnit usable
                 * @property {Array.<string>|null} [tags] ComponentUnit tags
                 * @property {Long|null} [deletedAt] ComponentUnit deletedAt
                 * @property {Long|null} [createdAt] ComponentUnit createdAt
                 * @property {Long|null} [updatedAt] ComponentUnit updatedAt
                 */

                /**
                 * Constructs a new ComponentUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ComponentUnit.
                 * @implements IComponentUnit
                 * @constructor
                 * @param {mtechnavi.api.company.IComponentUnit=} [properties] Properties to set
                 */
                function ComponentUnit(properties) {
                    this.displayNameLang = {};
                    this.linkageItem = [];
                    this.tags = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComponentUnit componentUnitId.
                 * @member {string} componentUnitId
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.componentUnitId = "";

                /**
                 * ComponentUnit companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.companyId = "";

                /**
                 * ComponentUnit componentUnitCode.
                 * @member {string} componentUnitCode
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.componentUnitCode = "";

                /**
                 * ComponentUnit displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.displayNameLang = $util.emptyObject;

                /**
                 * ComponentUnit phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.phoneNumber = "";

                /**
                 * ComponentUnit address.
                 * @member {sharelib.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.address = null;

                /**
                 * ComponentUnit placeProperties.
                 * @member {mtechnavi.api.company.ComponentUnit.IPlaceProperties|null|undefined} placeProperties
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.placeProperties = null;

                /**
                 * ComponentUnit postUnit.
                 * @member {sharelib.INameOption|null|undefined} postUnit
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.postUnit = null;

                /**
                 * ComponentUnit postUnitProperties.
                 * @member {mtechnavi.api.company.IPostUnitProperties|null|undefined} postUnitProperties
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.postUnitProperties = null;

                /**
                 * ComponentUnit organizationUnit.
                 * @member {sharelib.INameOption|null|undefined} organizationUnit
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.organizationUnit = null;

                /**
                 * ComponentUnit organizationUnitProperties.
                 * @member {mtechnavi.api.company.IOrganizationUnitProperties|null|undefined} organizationUnitProperties
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.organizationUnitProperties = null;

                /**
                 * ComponentUnit deliveryPoint.
                 * @member {sharelib.INameOption|null|undefined} deliveryPoint
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.deliveryPoint = null;

                /**
                 * ComponentUnit deliveryPointProperties.
                 * @member {mtechnavi.api.company.IDeliveryPointProperties|null|undefined} deliveryPointProperties
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.deliveryPointProperties = null;

                /**
                 * ComponentUnit office.
                 * @member {sharelib.INameOption|null|undefined} office
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.office = null;

                /**
                 * ComponentUnit officeProperties.
                 * @member {mtechnavi.api.company.IOfficeProperties|null|undefined} officeProperties
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.officeProperties = null;

                /**
                 * ComponentUnit linkageItem.
                 * @member {Array.<string>} linkageItem
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.linkageItem = $util.emptyArray;

                /**
                 * ComponentUnit usable.
                 * @member {sharelib.INameOption|null|undefined} usable
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.usable = null;

                /**
                 * ComponentUnit tags.
                 * @member {Array.<string>} tags
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.tags = $util.emptyArray;

                /**
                 * ComponentUnit deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ComponentUnit createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ComponentUnit updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 */
                ComponentUnit.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a ComponentUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComponentUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        if (!$util.isString(message.componentUnitId))
                            return "componentUnitId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.componentUnitCode != null && message.hasOwnProperty("componentUnitCode"))
                        if (!$util.isString(message.componentUnitCode))
                            return "componentUnitCode: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.sharelib.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.placeProperties != null && message.hasOwnProperty("placeProperties")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.PlaceProperties.verify(message.placeProperties);
                        if (error)
                            return "placeProperties." + error;
                    }
                    if (message.postUnit != null && message.hasOwnProperty("postUnit")) {
                        let error = $root.sharelib.NameOption.verify(message.postUnit);
                        if (error)
                            return "postUnit." + error;
                    }
                    if (message.postUnitProperties != null && message.hasOwnProperty("postUnitProperties")) {
                        let error = $root.mtechnavi.api.company.PostUnitProperties.verify(message.postUnitProperties);
                        if (error)
                            return "postUnitProperties." + error;
                    }
                    if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit")) {
                        let error = $root.sharelib.NameOption.verify(message.organizationUnit);
                        if (error)
                            return "organizationUnit." + error;
                    }
                    if (message.organizationUnitProperties != null && message.hasOwnProperty("organizationUnitProperties")) {
                        let error = $root.mtechnavi.api.company.OrganizationUnitProperties.verify(message.organizationUnitProperties);
                        if (error)
                            return "organizationUnitProperties." + error;
                    }
                    if (message.deliveryPoint != null && message.hasOwnProperty("deliveryPoint")) {
                        let error = $root.sharelib.NameOption.verify(message.deliveryPoint);
                        if (error)
                            return "deliveryPoint." + error;
                    }
                    if (message.deliveryPointProperties != null && message.hasOwnProperty("deliveryPointProperties")) {
                        let error = $root.mtechnavi.api.company.DeliveryPointProperties.verify(message.deliveryPointProperties);
                        if (error)
                            return "deliveryPointProperties." + error;
                    }
                    if (message.office != null && message.hasOwnProperty("office")) {
                        let error = $root.sharelib.NameOption.verify(message.office);
                        if (error)
                            return "office." + error;
                    }
                    if (message.officeProperties != null && message.hasOwnProperty("officeProperties")) {
                        let error = $root.mtechnavi.api.company.OfficeProperties.verify(message.officeProperties);
                        if (error)
                            return "officeProperties." + error;
                    }
                    if (message.linkageItem != null && message.hasOwnProperty("linkageItem")) {
                        if (!Array.isArray(message.linkageItem))
                            return "linkageItem: array expected";
                        for (let i = 0; i < message.linkageItem.length; ++i)
                            if (!$util.isString(message.linkageItem[i]))
                                return "linkageItem: string[] expected";
                    }
                    if (message.usable != null && message.hasOwnProperty("usable")) {
                        let error = $root.sharelib.NameOption.verify(message.usable);
                        if (error)
                            return "usable." + error;
                    }
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (let i = 0; i < message.tags.length; ++i)
                            if (!$util.isString(message.tags[i]))
                                return "tags: string[] expected";
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ComponentUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ComponentUnit} ComponentUnit
                 */
                ComponentUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ComponentUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.ComponentUnit();
                    if (object.componentUnitId != null)
                        message.componentUnitId = String(object.componentUnitId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.componentUnitCode != null)
                        message.componentUnitCode = String(object.componentUnitCode);
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.address: object expected");
                        message.address = $root.sharelib.Address.fromObject(object.address);
                    }
                    if (object.placeProperties != null) {
                        if (typeof object.placeProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.placeProperties: object expected");
                        message.placeProperties = $root.mtechnavi.api.company.ComponentUnit.PlaceProperties.fromObject(object.placeProperties);
                    }
                    if (object.postUnit != null) {
                        if (typeof object.postUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.postUnit: object expected");
                        message.postUnit = $root.sharelib.NameOption.fromObject(object.postUnit);
                    }
                    if (object.postUnitProperties != null) {
                        if (typeof object.postUnitProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.postUnitProperties: object expected");
                        message.postUnitProperties = $root.mtechnavi.api.company.PostUnitProperties.fromObject(object.postUnitProperties);
                    }
                    if (object.organizationUnit != null) {
                        if (typeof object.organizationUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.organizationUnit: object expected");
                        message.organizationUnit = $root.sharelib.NameOption.fromObject(object.organizationUnit);
                    }
                    if (object.organizationUnitProperties != null) {
                        if (typeof object.organizationUnitProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.organizationUnitProperties: object expected");
                        message.organizationUnitProperties = $root.mtechnavi.api.company.OrganizationUnitProperties.fromObject(object.organizationUnitProperties);
                    }
                    if (object.deliveryPoint != null) {
                        if (typeof object.deliveryPoint !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.deliveryPoint: object expected");
                        message.deliveryPoint = $root.sharelib.NameOption.fromObject(object.deliveryPoint);
                    }
                    if (object.deliveryPointProperties != null) {
                        if (typeof object.deliveryPointProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.deliveryPointProperties: object expected");
                        message.deliveryPointProperties = $root.mtechnavi.api.company.DeliveryPointProperties.fromObject(object.deliveryPointProperties);
                    }
                    if (object.office != null) {
                        if (typeof object.office !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.office: object expected");
                        message.office = $root.sharelib.NameOption.fromObject(object.office);
                    }
                    if (object.officeProperties != null) {
                        if (typeof object.officeProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.officeProperties: object expected");
                        message.officeProperties = $root.mtechnavi.api.company.OfficeProperties.fromObject(object.officeProperties);
                    }
                    if (object.linkageItem) {
                        if (!Array.isArray(object.linkageItem))
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.linkageItem: array expected");
                        message.linkageItem = [];
                        for (let i = 0; i < object.linkageItem.length; ++i)
                            message.linkageItem[i] = String(object.linkageItem[i]);
                    }
                    if (object.usable != null) {
                        if (typeof object.usable !== "object")
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.usable: object expected");
                        message.usable = $root.sharelib.NameOption.fromObject(object.usable);
                    }
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".mtechnavi.api.company.ComponentUnit.tags: array expected");
                        message.tags = [];
                        for (let i = 0; i < object.tags.length; ++i)
                            message.tags[i] = String(object.tags[i]);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ComponentUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @static
                 * @param {mtechnavi.api.company.ComponentUnit} message ComponentUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComponentUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.linkageItem = [];
                        object.tags = [];
                    }
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.componentUnitId = "";
                        object.companyId = "";
                        object.componentUnitCode = "";
                        object.phoneNumber = "";
                        object.address = null;
                        object.postUnit = null;
                        object.postUnitProperties = null;
                        object.organizationUnit = null;
                        object.organizationUnitProperties = null;
                        object.deliveryPoint = null;
                        object.deliveryPointProperties = null;
                        object.office = null;
                        object.officeProperties = null;
                        object.usable = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.placeProperties = null;
                    }
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        object.componentUnitId = message.componentUnitId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.componentUnitCode != null && message.hasOwnProperty("componentUnitCode"))
                        object.componentUnitCode = message.componentUnitCode;
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.sharelib.Address.toObject(message.address, options);
                    if (message.postUnit != null && message.hasOwnProperty("postUnit"))
                        object.postUnit = $root.sharelib.NameOption.toObject(message.postUnit, options);
                    if (message.postUnitProperties != null && message.hasOwnProperty("postUnitProperties"))
                        object.postUnitProperties = $root.mtechnavi.api.company.PostUnitProperties.toObject(message.postUnitProperties, options);
                    if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit"))
                        object.organizationUnit = $root.sharelib.NameOption.toObject(message.organizationUnit, options);
                    if (message.organizationUnitProperties != null && message.hasOwnProperty("organizationUnitProperties"))
                        object.organizationUnitProperties = $root.mtechnavi.api.company.OrganizationUnitProperties.toObject(message.organizationUnitProperties, options);
                    if (message.deliveryPoint != null && message.hasOwnProperty("deliveryPoint"))
                        object.deliveryPoint = $root.sharelib.NameOption.toObject(message.deliveryPoint, options);
                    if (message.deliveryPointProperties != null && message.hasOwnProperty("deliveryPointProperties"))
                        object.deliveryPointProperties = $root.mtechnavi.api.company.DeliveryPointProperties.toObject(message.deliveryPointProperties, options);
                    if (message.office != null && message.hasOwnProperty("office"))
                        object.office = $root.sharelib.NameOption.toObject(message.office, options);
                    if (message.officeProperties != null && message.hasOwnProperty("officeProperties"))
                        object.officeProperties = $root.mtechnavi.api.company.OfficeProperties.toObject(message.officeProperties, options);
                    if (message.linkageItem && message.linkageItem.length) {
                        object.linkageItem = [];
                        for (let j = 0; j < message.linkageItem.length; ++j)
                            object.linkageItem[j] = message.linkageItem[j];
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = $root.sharelib.NameOption.toObject(message.usable, options);
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (let j = 0; j < message.tags.length; ++j)
                            object.tags[j] = message.tags[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.placeProperties != null && message.hasOwnProperty("placeProperties"))
                        object.placeProperties = $root.mtechnavi.api.company.ComponentUnit.PlaceProperties.toObject(message.placeProperties, options);
                    return object;
                };

                /**
                 * Converts this ComponentUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ComponentUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComponentUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ComponentUnit.PlaceProperties = (function() {

                    /**
                     * Properties of a PlaceProperties.
                     * @memberof mtechnavi.api.company.ComponentUnit
                     * @interface IPlaceProperties
                     * @property {Object.<string,string>|null} [displayNameLang] PlaceProperties displayNameLang
                     * @property {mtechnavi.api.company.IImageFile|null} [image] PlaceProperties image
                     */

                    /**
                     * Constructs a new PlaceProperties.
                     * @memberof mtechnavi.api.company.ComponentUnit
                     * @classdesc Represents a PlaceProperties.
                     * @implements IPlaceProperties
                     * @constructor
                     * @param {mtechnavi.api.company.ComponentUnit.IPlaceProperties=} [properties] Properties to set
                     */
                    function PlaceProperties(properties) {
                        this.displayNameLang = {};
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PlaceProperties displayNameLang.
                     * @member {Object.<string,string>} displayNameLang
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @instance
                     */
                    PlaceProperties.prototype.displayNameLang = $util.emptyObject;

                    /**
                     * PlaceProperties image.
                     * @member {mtechnavi.api.company.IImageFile|null|undefined} image
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @instance
                     */
                    PlaceProperties.prototype.image = null;

                    /**
                     * Verifies a PlaceProperties message.
                     * @function verify
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PlaceProperties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                            if (!$util.isObject(message.displayNameLang))
                                return "displayNameLang: object expected";
                            let key = Object.keys(message.displayNameLang);
                            for (let i = 0; i < key.length; ++i)
                                if (!$util.isString(message.displayNameLang[key[i]]))
                                    return "displayNameLang: string{k:string} expected";
                        }
                        if (message.image != null && message.hasOwnProperty("image")) {
                            let error = $root.mtechnavi.api.company.ImageFile.verify(message.image);
                            if (error)
                                return "image." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PlaceProperties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.ComponentUnit.PlaceProperties} PlaceProperties
                     */
                    PlaceProperties.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.ComponentUnit.PlaceProperties)
                            return object;
                        let message = new $root.mtechnavi.api.company.ComponentUnit.PlaceProperties();
                        if (object.displayNameLang) {
                            if (typeof object.displayNameLang !== "object")
                                throw TypeError(".mtechnavi.api.company.ComponentUnit.PlaceProperties.displayNameLang: object expected");
                            message.displayNameLang = {};
                            for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                        }
                        if (object.image != null) {
                            if (typeof object.image !== "object")
                                throw TypeError(".mtechnavi.api.company.ComponentUnit.PlaceProperties.image: object expected");
                            message.image = $root.mtechnavi.api.company.ImageFile.fromObject(object.image);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PlaceProperties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @static
                     * @param {mtechnavi.api.company.ComponentUnit.PlaceProperties} message PlaceProperties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PlaceProperties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.objects || options.defaults)
                            object.displayNameLang = {};
                        if (options.defaults)
                            object.image = null;
                        let keys2;
                        if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                            object.displayNameLang = {};
                            for (let j = 0; j < keys2.length; ++j)
                                object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                        }
                        if (message.image != null && message.hasOwnProperty("image"))
                            object.image = $root.mtechnavi.api.company.ImageFile.toObject(message.image, options);
                        return object;
                    };

                    /**
                     * Converts this PlaceProperties to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.ComponentUnit.PlaceProperties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PlaceProperties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PlaceProperties;
                })();

                return ComponentUnit;
            })();

            company.Staff = (function() {

                /**
                 * Properties of a Staff.
                 * @memberof mtechnavi.api.company
                 * @interface IStaff
                 * @property {string|null} [staffId] Staff staffId
                 * @property {string|null} [staffCode] Staff staffCode
                 * @property {string|null} [displayName] Staff displayName
                 * @property {string|null} [componentUnitId] Staff componentUnitId
                 * @property {string|null} [phoneNumber] Staff phoneNumber
                 * @property {string|null} [contactEmail] Staff contactEmail
                 * @property {string|null} [email] Staff email
                 * @property {sharelib.INameOption|null} [usable] Staff usable
                 * @property {Array.<string>|null} [tags] Staff tags
                 * @property {Long|null} [deletedAt] Staff deletedAt
                 * @property {Long|null} [createdAt] Staff createdAt
                 * @property {Long|null} [updatedAt] Staff updatedAt
                 */

                /**
                 * Constructs a new Staff.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a Staff.
                 * @implements IStaff
                 * @constructor
                 * @param {mtechnavi.api.company.IStaff=} [properties] Properties to set
                 */
                function Staff(properties) {
                    this.tags = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Staff staffId.
                 * @member {string} staffId
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.staffId = "";

                /**
                 * Staff staffCode.
                 * @member {string} staffCode
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.staffCode = "";

                /**
                 * Staff displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.displayName = "";

                /**
                 * Staff componentUnitId.
                 * @member {string} componentUnitId
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.componentUnitId = "";

                /**
                 * Staff phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.phoneNumber = "";

                /**
                 * Staff contactEmail.
                 * @member {string} contactEmail
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.contactEmail = "";

                /**
                 * Staff email.
                 * @member {string} email
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.email = "";

                /**
                 * Staff usable.
                 * @member {sharelib.INameOption|null|undefined} usable
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.usable = null;

                /**
                 * Staff tags.
                 * @member {Array.<string>} tags
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.tags = $util.emptyArray;

                /**
                 * Staff deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Staff createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Staff updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 */
                Staff.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Staff message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Staff
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Staff.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        if (!$util.isString(message.staffId))
                            return "staffId: string expected";
                    if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                        if (!$util.isString(message.staffCode))
                            return "staffCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        if (!$util.isString(message.componentUnitId))
                            return "componentUnitId: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.contactEmail != null && message.hasOwnProperty("contactEmail"))
                        if (!$util.isString(message.contactEmail))
                            return "contactEmail: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.usable != null && message.hasOwnProperty("usable")) {
                        let error = $root.sharelib.NameOption.verify(message.usable);
                        if (error)
                            return "usable." + error;
                    }
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (let i = 0; i < message.tags.length; ++i)
                            if (!$util.isString(message.tags[i]))
                                return "tags: string[] expected";
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Staff message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Staff
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Staff} Staff
                 */
                Staff.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Staff)
                        return object;
                    let message = new $root.mtechnavi.api.company.Staff();
                    if (object.staffId != null)
                        message.staffId = String(object.staffId);
                    if (object.staffCode != null)
                        message.staffCode = String(object.staffCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.componentUnitId != null)
                        message.componentUnitId = String(object.componentUnitId);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.contactEmail != null)
                        message.contactEmail = String(object.contactEmail);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.usable != null) {
                        if (typeof object.usable !== "object")
                            throw TypeError(".mtechnavi.api.company.Staff.usable: object expected");
                        message.usable = $root.sharelib.NameOption.fromObject(object.usable);
                    }
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".mtechnavi.api.company.Staff.tags: array expected");
                        message.tags = [];
                        for (let i = 0; i < object.tags.length; ++i)
                            message.tags[i] = String(object.tags[i]);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Staff message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Staff
                 * @static
                 * @param {mtechnavi.api.company.Staff} message Staff
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Staff.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.tags = [];
                    if (options.defaults) {
                        object.staffId = "";
                        object.staffCode = "";
                        object.displayName = "";
                        object.componentUnitId = "";
                        object.contactEmail = "";
                        object.email = "";
                        object.usable = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.phoneNumber = "";
                    }
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        object.staffId = message.staffId;
                    if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                        object.staffCode = message.staffCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        object.componentUnitId = message.componentUnitId;
                    if (message.contactEmail != null && message.hasOwnProperty("contactEmail"))
                        object.contactEmail = message.contactEmail;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = $root.sharelib.NameOption.toObject(message.usable, options);
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (let j = 0; j < message.tags.length; ++j)
                            object.tags[j] = message.tags[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    return object;
                };

                /**
                 * Converts this Staff to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Staff
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Staff.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Staff;
            })();

            company.BusinessUnit = (function() {

                /**
                 * Properties of a BusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnit
                 * @property {string|null} [businessUnitId] BusinessUnit businessUnitId
                 * @property {string|null} [companyId] BusinessUnit companyId
                 * @property {string|null} [businessUnitCode] BusinessUnit businessUnitCode
                 * @property {string|null} [displayName] BusinessUnit displayName
                 * @property {string|null} [businessUnitCategory] BusinessUnit businessUnitCategory
                 * @property {string|null} [remarks] BusinessUnit remarks
                 * @property {Array.<mtechnavi.api.company.IPostUnit>|null} [postUnits] BusinessUnit postUnits
                 * @property {Array.<string>|null} [tags] BusinessUnit tags
                 * @property {Array.<sharelib.INameOption>|null} [aggregatedPostUnitTypes] BusinessUnit aggregatedPostUnitTypes
                 * @property {Long|null} [deletedAt] BusinessUnit deletedAt
                 * @property {Long|null} [createdAt] BusinessUnit createdAt
                 * @property {Long|null} [updatedAt] BusinessUnit updatedAt
                 */

                /**
                 * Constructs a new BusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnit.
                 * @implements IBusinessUnit
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnit=} [properties] Properties to set
                 */
                function BusinessUnit(properties) {
                    this.postUnits = [];
                    this.tags = [];
                    this.aggregatedPostUnitTypes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnit businessUnitId.
                 * @member {string} businessUnitId
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.businessUnitId = "";

                /**
                 * BusinessUnit companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.companyId = "";

                /**
                 * BusinessUnit businessUnitCode.
                 * @member {string} businessUnitCode
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.businessUnitCode = "";

                /**
                 * BusinessUnit displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.displayName = "";

                /**
                 * BusinessUnit businessUnitCategory.
                 * @member {string} businessUnitCategory
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.businessUnitCategory = "";

                /**
                 * BusinessUnit remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.remarks = "";

                /**
                 * BusinessUnit postUnits.
                 * @member {Array.<mtechnavi.api.company.IPostUnit>} postUnits
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.postUnits = $util.emptyArray;

                /**
                 * BusinessUnit tags.
                 * @member {Array.<string>} tags
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.tags = $util.emptyArray;

                /**
                 * BusinessUnit aggregatedPostUnitTypes.
                 * @member {Array.<sharelib.INameOption>} aggregatedPostUnitTypes
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.aggregatedPostUnitTypes = $util.emptyArray;

                /**
                 * BusinessUnit deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnit createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnit updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 */
                BusinessUnit.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitId != null && message.hasOwnProperty("businessUnitId"))
                        if (!$util.isString(message.businessUnitId))
                            return "businessUnitId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.businessUnitCode != null && message.hasOwnProperty("businessUnitCode"))
                        if (!$util.isString(message.businessUnitCode))
                            return "businessUnitCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.businessUnitCategory != null && message.hasOwnProperty("businessUnitCategory"))
                        if (!$util.isString(message.businessUnitCategory))
                            return "businessUnitCategory: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.postUnits != null && message.hasOwnProperty("postUnits")) {
                        if (!Array.isArray(message.postUnits))
                            return "postUnits: array expected";
                        for (let i = 0; i < message.postUnits.length; ++i) {
                            let error = $root.mtechnavi.api.company.PostUnit.verify(message.postUnits[i]);
                            if (error)
                                return "postUnits." + error;
                        }
                    }
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (let i = 0; i < message.tags.length; ++i)
                            if (!$util.isString(message.tags[i]))
                                return "tags: string[] expected";
                    }
                    if (message.aggregatedPostUnitTypes != null && message.hasOwnProperty("aggregatedPostUnitTypes")) {
                        if (!Array.isArray(message.aggregatedPostUnitTypes))
                            return "aggregatedPostUnitTypes: array expected";
                        for (let i = 0; i < message.aggregatedPostUnitTypes.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.aggregatedPostUnitTypes[i]);
                            if (error)
                                return "aggregatedPostUnitTypes." + error;
                        }
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnit} BusinessUnit
                 */
                BusinessUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnit();
                    if (object.businessUnitId != null)
                        message.businessUnitId = String(object.businessUnitId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.businessUnitCode != null)
                        message.businessUnitCode = String(object.businessUnitCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.businessUnitCategory != null)
                        message.businessUnitCategory = String(object.businessUnitCategory);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.postUnits) {
                        if (!Array.isArray(object.postUnits))
                            throw TypeError(".mtechnavi.api.company.BusinessUnit.postUnits: array expected");
                        message.postUnits = [];
                        for (let i = 0; i < object.postUnits.length; ++i) {
                            if (typeof object.postUnits[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnit.postUnits: object expected");
                            message.postUnits[i] = $root.mtechnavi.api.company.PostUnit.fromObject(object.postUnits[i]);
                        }
                    }
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".mtechnavi.api.company.BusinessUnit.tags: array expected");
                        message.tags = [];
                        for (let i = 0; i < object.tags.length; ++i)
                            message.tags[i] = String(object.tags[i]);
                    }
                    if (object.aggregatedPostUnitTypes) {
                        if (!Array.isArray(object.aggregatedPostUnitTypes))
                            throw TypeError(".mtechnavi.api.company.BusinessUnit.aggregatedPostUnitTypes: array expected");
                        message.aggregatedPostUnitTypes = [];
                        for (let i = 0; i < object.aggregatedPostUnitTypes.length; ++i) {
                            if (typeof object.aggregatedPostUnitTypes[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnit.aggregatedPostUnitTypes: object expected");
                            message.aggregatedPostUnitTypes[i] = $root.sharelib.NameOption.fromObject(object.aggregatedPostUnitTypes[i]);
                        }
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnit} message BusinessUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.postUnits = [];
                        object.tags = [];
                        object.aggregatedPostUnitTypes = [];
                    }
                    if (options.defaults) {
                        object.businessUnitId = "";
                        object.companyId = "";
                        object.businessUnitCode = "";
                        object.displayName = "";
                        object.businessUnitCategory = "";
                        object.remarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitId != null && message.hasOwnProperty("businessUnitId"))
                        object.businessUnitId = message.businessUnitId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.businessUnitCode != null && message.hasOwnProperty("businessUnitCode"))
                        object.businessUnitCode = message.businessUnitCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.businessUnitCategory != null && message.hasOwnProperty("businessUnitCategory"))
                        object.businessUnitCategory = message.businessUnitCategory;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.postUnits && message.postUnits.length) {
                        object.postUnits = [];
                        for (let j = 0; j < message.postUnits.length; ++j)
                            object.postUnits[j] = $root.mtechnavi.api.company.PostUnit.toObject(message.postUnits[j], options);
                    }
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (let j = 0; j < message.tags.length; ++j)
                            object.tags[j] = message.tags[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.aggregatedPostUnitTypes && message.aggregatedPostUnitTypes.length) {
                        object.aggregatedPostUnitTypes = [];
                        for (let j = 0; j < message.aggregatedPostUnitTypes.length; ++j)
                            object.aggregatedPostUnitTypes[j] = $root.sharelib.NameOption.toObject(message.aggregatedPostUnitTypes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnit;
            })();

            company.BusinessUnitRequest = (function() {

                /**
                 * Properties of a BusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitRequest
                 * @property {string|null} [businessUnitRequestId] BusinessUnitRequest businessUnitRequestId
                 * @property {string|null} [companyId] BusinessUnitRequest companyId
                 * @property {string|null} [sourceCompanyId] BusinessUnitRequest sourceCompanyId
                 * @property {Long|null} [deletedAt] BusinessUnitRequest deletedAt
                 * @property {Long|null} [createdAt] BusinessUnitRequest createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitRequest updatedAt
                 */

                /**
                 * Constructs a new BusinessUnitRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitRequest.
                 * @implements IBusinessUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitRequest=} [properties] Properties to set
                 */
                function BusinessUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitRequest businessUnitRequestId.
                 * @member {string} businessUnitRequestId
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.businessUnitRequestId = "";

                /**
                 * BusinessUnitRequest companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.companyId = "";

                /**
                 * BusinessUnitRequest sourceCompanyId.
                 * @member {string} sourceCompanyId
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.sourceCompanyId = "";

                /**
                 * BusinessUnitRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 */
                BusinessUnitRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestId != null && message.hasOwnProperty("businessUnitRequestId"))
                        if (!$util.isString(message.businessUnitRequestId))
                            return "businessUnitRequestId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.sourceCompanyId != null && message.hasOwnProperty("sourceCompanyId"))
                        if (!$util.isString(message.sourceCompanyId))
                            return "sourceCompanyId: string expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitRequest} BusinessUnitRequest
                 */
                BusinessUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitRequest();
                    if (object.businessUnitRequestId != null)
                        message.businessUnitRequestId = String(object.businessUnitRequestId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.sourceCompanyId != null)
                        message.sourceCompanyId = String(object.sourceCompanyId);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitRequest} message BusinessUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRequestId = "";
                        object.companyId = "";
                        object.sourceCompanyId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitRequestId != null && message.hasOwnProperty("businessUnitRequestId"))
                        object.businessUnitRequestId = message.businessUnitRequestId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.sourceCompanyId != null && message.hasOwnProperty("sourceCompanyId"))
                        object.sourceCompanyId = message.sourceCompanyId;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitRequest;
            })();

            company.BusinessUnitRequestResult = (function() {

                /**
                 * Properties of a BusinessUnitRequestResult.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitRequestResult
                 * @property {string|null} [businessUnitRequestResultId] BusinessUnitRequestResult businessUnitRequestResultId
                 * @property {mtechnavi.api.company.IBusinessUnitRequest|null} [businessUnitRequest] BusinessUnitRequestResult businessUnitRequest
                 * @property {Long|null} [deletedAt] BusinessUnitRequestResult deletedAt
                 * @property {Long|null} [createdAt] BusinessUnitRequestResult createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitRequestResult updatedAt
                 */

                /**
                 * Constructs a new BusinessUnitRequestResult.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitRequestResult.
                 * @implements IBusinessUnitRequestResult
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitRequestResult=} [properties] Properties to set
                 */
                function BusinessUnitRequestResult(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitRequestResult businessUnitRequestResultId.
                 * @member {string} businessUnitRequestResultId
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 */
                BusinessUnitRequestResult.prototype.businessUnitRequestResultId = "";

                /**
                 * BusinessUnitRequestResult businessUnitRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRequest|null|undefined} businessUnitRequest
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 */
                BusinessUnitRequestResult.prototype.businessUnitRequest = null;

                /**
                 * BusinessUnitRequestResult deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 */
                BusinessUnitRequestResult.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRequestResult createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 */
                BusinessUnitRequestResult.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRequestResult updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 */
                BusinessUnitRequestResult.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitRequestResult message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitRequestResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRequestResultId != null && message.hasOwnProperty("businessUnitRequestResultId"))
                        if (!$util.isString(message.businessUnitRequestResultId))
                            return "businessUnitRequestResultId: string expected";
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRequest.verify(message.businessUnitRequest);
                        if (error)
                            return "businessUnitRequest." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitRequestResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitRequestResult} BusinessUnitRequestResult
                 */
                BusinessUnitRequestResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitRequestResult)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitRequestResult();
                    if (object.businessUnitRequestResultId != null)
                        message.businessUnitRequestResultId = String(object.businessUnitRequestResultId);
                    if (object.businessUnitRequest != null) {
                        if (typeof object.businessUnitRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRequestResult.businessUnitRequest: object expected");
                        message.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.fromObject(object.businessUnitRequest);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitRequestResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitRequestResult} message BusinessUnitRequestResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitRequestResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRequestResultId = "";
                        object.businessUnitRequest = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitRequestResultId != null && message.hasOwnProperty("businessUnitRequestResultId"))
                        object.businessUnitRequestResultId = message.businessUnitRequestResultId;
                    if (message.businessUnitRequest != null && message.hasOwnProperty("businessUnitRequest"))
                        object.businessUnitRequest = $root.mtechnavi.api.company.BusinessUnitRequest.toObject(message.businessUnitRequest, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitRequestResult to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitRequestResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitRequestResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitRequestResult;
            })();

            company.BusinessUnitManagement = (function() {

                /**
                 * Properties of a BusinessUnitManagement.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitManagement
                 * @property {string|null} [businessUnitManagementId] BusinessUnitManagement businessUnitManagementId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] BusinessUnitManagement systemNotificationUsers
                 * @property {string|null} [companyId] BusinessUnitManagement companyId
                 * @property {mtechnavi.api.company.IProcess|null} [tenantLinkProcess] BusinessUnitManagement tenantLinkProcess
                 * @property {mtechnavi.api.company.IProcess|null} [tenantInviteProcess] BusinessUnitManagement tenantInviteProcess
                 * @property {string|null} [code] BusinessUnitManagement code
                 * @property {string|null} [displayName] BusinessUnitManagement displayName
                 * @property {Array.<sharelib.IUserReference>|null} [mainContact] BusinessUnitManagement mainContact
                 * @property {Array.<sharelib.IUserReference>|null} [mainContactAssistant] BusinessUnitManagement mainContactAssistant
                 * @property {Array.<sharelib.IUserReference>|null} [staff] BusinessUnitManagement staff
                 * @property {Array.<sharelib.IUserReference>|null} [staffAssistant] BusinessUnitManagement staffAssistant
                 * @property {mtechnavi.api.company.ICompanyProfile|null} [profile] BusinessUnitManagement profile
                 * @property {Array.<mtechnavi.api.company.IItemValue>|null} [itemValues] BusinessUnitManagement itemValues
                 * @property {sharelib.INameOption|null} [status] BusinessUnitManagement status
                 * @property {Array.<sharelib.IUserReference>|null} [invitees] BusinessUnitManagement invitees
                 * @property {boolean|null} [notificationDisabled] BusinessUnitManagement notificationDisabled
                 * @property {Long|null} [deletedAt] BusinessUnitManagement deletedAt
                 * @property {Long|null} [createdAt] BusinessUnitManagement createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitManagement updatedAt
                 */

                /**
                 * Constructs a new BusinessUnitManagement.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitManagement.
                 * @implements IBusinessUnitManagement
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitManagement=} [properties] Properties to set
                 */
                function BusinessUnitManagement(properties) {
                    this.systemNotificationUsers = [];
                    this.mainContact = [];
                    this.mainContactAssistant = [];
                    this.staff = [];
                    this.staffAssistant = [];
                    this.itemValues = [];
                    this.invitees = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitManagement businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitManagement systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * BusinessUnitManagement companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.companyId = "";

                /**
                 * BusinessUnitManagement tenantLinkProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} tenantLinkProcess
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.tenantLinkProcess = null;

                /**
                 * BusinessUnitManagement tenantInviteProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} tenantInviteProcess
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.tenantInviteProcess = null;

                /**
                 * BusinessUnitManagement code.
                 * @member {string} code
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.code = "";

                /**
                 * BusinessUnitManagement displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.displayName = "";

                /**
                 * BusinessUnitManagement mainContact.
                 * @member {Array.<sharelib.IUserReference>} mainContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.mainContact = $util.emptyArray;

                /**
                 * BusinessUnitManagement mainContactAssistant.
                 * @member {Array.<sharelib.IUserReference>} mainContactAssistant
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.mainContactAssistant = $util.emptyArray;

                /**
                 * BusinessUnitManagement staff.
                 * @member {Array.<sharelib.IUserReference>} staff
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.staff = $util.emptyArray;

                /**
                 * BusinessUnitManagement staffAssistant.
                 * @member {Array.<sharelib.IUserReference>} staffAssistant
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.staffAssistant = $util.emptyArray;

                /**
                 * BusinessUnitManagement profile.
                 * @member {mtechnavi.api.company.ICompanyProfile|null|undefined} profile
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.profile = null;

                /**
                 * BusinessUnitManagement itemValues.
                 * @member {Array.<mtechnavi.api.company.IItemValue>} itemValues
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.itemValues = $util.emptyArray;

                /**
                 * BusinessUnitManagement status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.status = null;

                /**
                 * BusinessUnitManagement invitees.
                 * @member {Array.<sharelib.IUserReference>} invitees
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.invitees = $util.emptyArray;

                /**
                 * BusinessUnitManagement notificationDisabled.
                 * @member {boolean} notificationDisabled
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.notificationDisabled = false;

                /**
                 * BusinessUnitManagement deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitManagement createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitManagement updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 */
                BusinessUnitManagement.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitManagement message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitManagement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.tenantLinkProcess != null && message.hasOwnProperty("tenantLinkProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.tenantLinkProcess);
                        if (error)
                            return "tenantLinkProcess." + error;
                    }
                    if (message.tenantInviteProcess != null && message.hasOwnProperty("tenantInviteProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.tenantInviteProcess);
                        if (error)
                            return "tenantInviteProcess." + error;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.mainContact != null && message.hasOwnProperty("mainContact")) {
                        if (!Array.isArray(message.mainContact))
                            return "mainContact: array expected";
                        for (let i = 0; i < message.mainContact.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContact[i]);
                            if (error)
                                return "mainContact." + error;
                        }
                    }
                    if (message.mainContactAssistant != null && message.hasOwnProperty("mainContactAssistant")) {
                        if (!Array.isArray(message.mainContactAssistant))
                            return "mainContactAssistant: array expected";
                        for (let i = 0; i < message.mainContactAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContactAssistant[i]);
                            if (error)
                                return "mainContactAssistant." + error;
                        }
                    }
                    if (message.staff != null && message.hasOwnProperty("staff")) {
                        if (!Array.isArray(message.staff))
                            return "staff: array expected";
                        for (let i = 0; i < message.staff.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staff[i]);
                            if (error)
                                return "staff." + error;
                        }
                    }
                    if (message.staffAssistant != null && message.hasOwnProperty("staffAssistant")) {
                        if (!Array.isArray(message.staffAssistant))
                            return "staffAssistant: array expected";
                        for (let i = 0; i < message.staffAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staffAssistant[i]);
                            if (error)
                                return "staffAssistant." + error;
                        }
                    }
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        let error = $root.mtechnavi.api.company.CompanyProfile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    if (message.itemValues != null && message.hasOwnProperty("itemValues")) {
                        if (!Array.isArray(message.itemValues))
                            return "itemValues: array expected";
                        for (let i = 0; i < message.itemValues.length; ++i) {
                            let error = $root.mtechnavi.api.company.ItemValue.verify(message.itemValues[i]);
                            if (error)
                                return "itemValues." + error;
                        }
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.invitees != null && message.hasOwnProperty("invitees")) {
                        if (!Array.isArray(message.invitees))
                            return "invitees: array expected";
                        for (let i = 0; i < message.invitees.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.invitees[i]);
                            if (error)
                                return "invitees." + error;
                        }
                    }
                    if (message.notificationDisabled != null && message.hasOwnProperty("notificationDisabled"))
                        if (typeof message.notificationDisabled !== "boolean")
                            return "notificationDisabled: boolean expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitManagement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitManagement} BusinessUnitManagement
                 */
                BusinessUnitManagement.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitManagement)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitManagement();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.tenantLinkProcess != null) {
                        if (typeof object.tenantLinkProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.tenantLinkProcess: object expected");
                        message.tenantLinkProcess = $root.mtechnavi.api.company.Process.fromObject(object.tenantLinkProcess);
                    }
                    if (object.tenantInviteProcess != null) {
                        if (typeof object.tenantInviteProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.tenantInviteProcess: object expected");
                        message.tenantInviteProcess = $root.mtechnavi.api.company.Process.fromObject(object.tenantInviteProcess);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.mainContact) {
                        if (!Array.isArray(object.mainContact))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.mainContact: array expected");
                        message.mainContact = [];
                        for (let i = 0; i < object.mainContact.length; ++i) {
                            if (typeof object.mainContact[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.mainContact: object expected");
                            message.mainContact[i] = $root.sharelib.UserReference.fromObject(object.mainContact[i]);
                        }
                    }
                    if (object.mainContactAssistant) {
                        if (!Array.isArray(object.mainContactAssistant))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.mainContactAssistant: array expected");
                        message.mainContactAssistant = [];
                        for (let i = 0; i < object.mainContactAssistant.length; ++i) {
                            if (typeof object.mainContactAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.mainContactAssistant: object expected");
                            message.mainContactAssistant[i] = $root.sharelib.UserReference.fromObject(object.mainContactAssistant[i]);
                        }
                    }
                    if (object.staff) {
                        if (!Array.isArray(object.staff))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.staff: array expected");
                        message.staff = [];
                        for (let i = 0; i < object.staff.length; ++i) {
                            if (typeof object.staff[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.staff: object expected");
                            message.staff[i] = $root.sharelib.UserReference.fromObject(object.staff[i]);
                        }
                    }
                    if (object.staffAssistant) {
                        if (!Array.isArray(object.staffAssistant))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.staffAssistant: array expected");
                        message.staffAssistant = [];
                        for (let i = 0; i < object.staffAssistant.length; ++i) {
                            if (typeof object.staffAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.staffAssistant: object expected");
                            message.staffAssistant[i] = $root.sharelib.UserReference.fromObject(object.staffAssistant[i]);
                        }
                    }
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.profile: object expected");
                        message.profile = $root.mtechnavi.api.company.CompanyProfile.fromObject(object.profile);
                    }
                    if (object.itemValues) {
                        if (!Array.isArray(object.itemValues))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.itemValues: array expected");
                        message.itemValues = [];
                        for (let i = 0; i < object.itemValues.length; ++i) {
                            if (typeof object.itemValues[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.itemValues: object expected");
                            message.itemValues[i] = $root.mtechnavi.api.company.ItemValue.fromObject(object.itemValues[i]);
                        }
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.invitees) {
                        if (!Array.isArray(object.invitees))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.invitees: array expected");
                        message.invitees = [];
                        for (let i = 0; i < object.invitees.length; ++i) {
                            if (typeof object.invitees[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagement.invitees: object expected");
                            message.invitees[i] = $root.sharelib.UserReference.fromObject(object.invitees[i]);
                        }
                    }
                    if (object.notificationDisabled != null)
                        message.notificationDisabled = Boolean(object.notificationDisabled);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitManagement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitManagement} message BusinessUnitManagement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitManagement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.mainContact = [];
                        object.mainContactAssistant = [];
                        object.staff = [];
                        object.staffAssistant = [];
                        object.systemNotificationUsers = [];
                        object.itemValues = [];
                        object.invitees = [];
                    }
                    if (options.defaults) {
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        object.code = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.tenantLinkProcess = null;
                        object.tenantInviteProcess = null;
                        object.profile = null;
                        object.status = null;
                        object.notificationDisabled = false;
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.mainContact && message.mainContact.length) {
                        object.mainContact = [];
                        for (let j = 0; j < message.mainContact.length; ++j)
                            object.mainContact[j] = $root.sharelib.UserReference.toObject(message.mainContact[j], options);
                    }
                    if (message.mainContactAssistant && message.mainContactAssistant.length) {
                        object.mainContactAssistant = [];
                        for (let j = 0; j < message.mainContactAssistant.length; ++j)
                            object.mainContactAssistant[j] = $root.sharelib.UserReference.toObject(message.mainContactAssistant[j], options);
                    }
                    if (message.staff && message.staff.length) {
                        object.staff = [];
                        for (let j = 0; j < message.staff.length; ++j)
                            object.staff[j] = $root.sharelib.UserReference.toObject(message.staff[j], options);
                    }
                    if (message.staffAssistant && message.staffAssistant.length) {
                        object.staffAssistant = [];
                        for (let j = 0; j < message.staffAssistant.length; ++j)
                            object.staffAssistant[j] = $root.sharelib.UserReference.toObject(message.staffAssistant[j], options);
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.tenantLinkProcess != null && message.hasOwnProperty("tenantLinkProcess"))
                        object.tenantLinkProcess = $root.mtechnavi.api.company.Process.toObject(message.tenantLinkProcess, options);
                    if (message.tenantInviteProcess != null && message.hasOwnProperty("tenantInviteProcess"))
                        object.tenantInviteProcess = $root.mtechnavi.api.company.Process.toObject(message.tenantInviteProcess, options);
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.mtechnavi.api.company.CompanyProfile.toObject(message.profile, options);
                    if (message.itemValues && message.itemValues.length) {
                        object.itemValues = [];
                        for (let j = 0; j < message.itemValues.length; ++j)
                            object.itemValues[j] = $root.mtechnavi.api.company.ItemValue.toObject(message.itemValues[j], options);
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.invitees && message.invitees.length) {
                        object.invitees = [];
                        for (let j = 0; j < message.invitees.length; ++j)
                            object.invitees[j] = $root.sharelib.UserReference.toObject(message.invitees[j], options);
                    }
                    if (message.notificationDisabled != null && message.hasOwnProperty("notificationDisabled"))
                        object.notificationDisabled = message.notificationDisabled;
                    return object;
                };

                /**
                 * Converts this BusinessUnitManagement to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitManagement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitManagement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitManagement;
            })();

            company.BusinessUnitProfile = (function() {

                /**
                 * Properties of a BusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitProfile
                 * @property {string|null} [businessUnitProfileId] BusinessUnitProfile businessUnitProfileId
                 * @property {string|null} [companyId] BusinessUnitProfile companyId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] BusinessUnitProfile systemNotificationUsers
                 * @property {mtechnavi.api.company.ICompanyProfile|null} [profile] BusinessUnitProfile profile
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitProfile sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitProfile updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitProfile createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitProfile updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitProfile deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitProfile.
                 * @implements IBusinessUnitProfile
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitProfile=} [properties] Properties to set
                 */
                function BusinessUnitProfile(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitProfile businessUnitProfileId.
                 * @member {string} businessUnitProfileId
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.businessUnitProfileId = "";

                /**
                 * BusinessUnitProfile companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.companyId = "";

                /**
                 * BusinessUnitProfile systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * BusinessUnitProfile profile.
                 * @member {mtechnavi.api.company.ICompanyProfile|null|undefined} profile
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.profile = null;

                /**
                 * BusinessUnitProfile sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.sharedProperties = null;

                /**
                 * BusinessUnitProfile updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.updatedProperties = null;

                /**
                 * BusinessUnitProfile createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitProfile updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitProfile deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 */
                BusinessUnitProfile.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitProfile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitProfile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        if (!$util.isString(message.businessUnitProfileId))
                            return "businessUnitProfileId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        let error = $root.mtechnavi.api.company.CompanyProfile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitProfile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitProfile} BusinessUnitProfile
                 */
                BusinessUnitProfile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitProfile)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitProfile();
                    if (object.businessUnitProfileId != null)
                        message.businessUnitProfileId = String(object.businessUnitProfileId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitProfile.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitProfile.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitProfile.profile: object expected");
                        message.profile = $root.mtechnavi.api.company.CompanyProfile.fromObject(object.profile);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitProfile.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitProfile.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitProfile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitProfile} message BusinessUnitProfile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitProfile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.businessUnitProfileId = "";
                        object.companyId = "";
                        object.profile = null;
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitProfileId != null && message.hasOwnProperty("businessUnitProfileId"))
                        object.businessUnitProfileId = message.businessUnitProfileId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.mtechnavi.api.company.CompanyProfile.toObject(message.profile, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnitProfile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitProfile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitProfile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitProfile;
            })();

            company.BusinessUnitFinancial = (function() {

                /**
                 * Properties of a BusinessUnitFinancial.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitFinancial
                 * @property {string|null} [businessUnitFinancialId] BusinessUnitFinancial businessUnitFinancialId
                 * @property {string|null} [companyId] BusinessUnitFinancial companyId
                 * @property {sharelib.IDatetime|null} [periodStartDt] BusinessUnitFinancial periodStartDt
                 * @property {sharelib.IDatetime|null} [periodEndDt] BusinessUnitFinancial periodEndDt
                 * @property {sharelib.IMonetaryAmount|null} [netSales] BusinessUnitFinancial netSales
                 * @property {sharelib.IMonetaryAmount|null} [operatingProfit] BusinessUnitFinancial operatingProfit
                 * @property {sharelib.IMonetaryAmount|null} [ordinaryIncome] BusinessUnitFinancial ordinaryIncome
                 * @property {boolean|null} [nondisclosure] BusinessUnitFinancial nondisclosure
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitFinancial sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitFinancial updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitFinancial createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitFinancial updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitFinancial deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitFinancial.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitFinancial.
                 * @implements IBusinessUnitFinancial
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitFinancial=} [properties] Properties to set
                 */
                function BusinessUnitFinancial(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitFinancial businessUnitFinancialId.
                 * @member {string} businessUnitFinancialId
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.businessUnitFinancialId = "";

                /**
                 * BusinessUnitFinancial companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.companyId = "";

                /**
                 * BusinessUnitFinancial periodStartDt.
                 * @member {sharelib.IDatetime|null|undefined} periodStartDt
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.periodStartDt = null;

                /**
                 * BusinessUnitFinancial periodEndDt.
                 * @member {sharelib.IDatetime|null|undefined} periodEndDt
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.periodEndDt = null;

                /**
                 * BusinessUnitFinancial netSales.
                 * @member {sharelib.IMonetaryAmount|null|undefined} netSales
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.netSales = null;

                /**
                 * BusinessUnitFinancial operatingProfit.
                 * @member {sharelib.IMonetaryAmount|null|undefined} operatingProfit
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.operatingProfit = null;

                /**
                 * BusinessUnitFinancial ordinaryIncome.
                 * @member {sharelib.IMonetaryAmount|null|undefined} ordinaryIncome
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.ordinaryIncome = null;

                /**
                 * BusinessUnitFinancial nondisclosure.
                 * @member {boolean} nondisclosure
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.nondisclosure = false;

                /**
                 * BusinessUnitFinancial sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.sharedProperties = null;

                /**
                 * BusinessUnitFinancial updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.updatedProperties = null;

                /**
                 * BusinessUnitFinancial createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFinancial updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFinancial deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 */
                BusinessUnitFinancial.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitFinancial message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitFinancial.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        if (!$util.isString(message.businessUnitFinancialId))
                            return "businessUnitFinancialId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.periodStartDt != null && message.hasOwnProperty("periodStartDt")) {
                        let error = $root.sharelib.Datetime.verify(message.periodStartDt);
                        if (error)
                            return "periodStartDt." + error;
                    }
                    if (message.periodEndDt != null && message.hasOwnProperty("periodEndDt")) {
                        let error = $root.sharelib.Datetime.verify(message.periodEndDt);
                        if (error)
                            return "periodEndDt." + error;
                    }
                    if (message.netSales != null && message.hasOwnProperty("netSales")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.netSales);
                        if (error)
                            return "netSales." + error;
                    }
                    if (message.operatingProfit != null && message.hasOwnProperty("operatingProfit")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.operatingProfit);
                        if (error)
                            return "operatingProfit." + error;
                    }
                    if (message.ordinaryIncome != null && message.hasOwnProperty("ordinaryIncome")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.ordinaryIncome);
                        if (error)
                            return "ordinaryIncome." + error;
                    }
                    if (message.nondisclosure != null && message.hasOwnProperty("nondisclosure"))
                        if (typeof message.nondisclosure !== "boolean")
                            return "nondisclosure: boolean expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitFinancial message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitFinancial} BusinessUnitFinancial
                 */
                BusinessUnitFinancial.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitFinancial)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitFinancial();
                    if (object.businessUnitFinancialId != null)
                        message.businessUnitFinancialId = String(object.businessUnitFinancialId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.periodStartDt != null) {
                        if (typeof object.periodStartDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.periodStartDt: object expected");
                        message.periodStartDt = $root.sharelib.Datetime.fromObject(object.periodStartDt);
                    }
                    if (object.periodEndDt != null) {
                        if (typeof object.periodEndDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.periodEndDt: object expected");
                        message.periodEndDt = $root.sharelib.Datetime.fromObject(object.periodEndDt);
                    }
                    if (object.netSales != null) {
                        if (typeof object.netSales !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.netSales: object expected");
                        message.netSales = $root.sharelib.MonetaryAmount.fromObject(object.netSales);
                    }
                    if (object.operatingProfit != null) {
                        if (typeof object.operatingProfit !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.operatingProfit: object expected");
                        message.operatingProfit = $root.sharelib.MonetaryAmount.fromObject(object.operatingProfit);
                    }
                    if (object.ordinaryIncome != null) {
                        if (typeof object.ordinaryIncome !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.ordinaryIncome: object expected");
                        message.ordinaryIncome = $root.sharelib.MonetaryAmount.fromObject(object.ordinaryIncome);
                    }
                    if (object.nondisclosure != null)
                        message.nondisclosure = Boolean(object.nondisclosure);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFinancial.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitFinancial message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitFinancial} message BusinessUnitFinancial
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitFinancial.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitFinancialId = "";
                        object.companyId = "";
                        object.periodStartDt = null;
                        object.periodEndDt = null;
                        object.netSales = null;
                        object.operatingProfit = null;
                        object.ordinaryIncome = null;
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.nondisclosure = false;
                    }
                    if (message.businessUnitFinancialId != null && message.hasOwnProperty("businessUnitFinancialId"))
                        object.businessUnitFinancialId = message.businessUnitFinancialId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.periodStartDt != null && message.hasOwnProperty("periodStartDt"))
                        object.periodStartDt = $root.sharelib.Datetime.toObject(message.periodStartDt, options);
                    if (message.periodEndDt != null && message.hasOwnProperty("periodEndDt"))
                        object.periodEndDt = $root.sharelib.Datetime.toObject(message.periodEndDt, options);
                    if (message.netSales != null && message.hasOwnProperty("netSales"))
                        object.netSales = $root.sharelib.MonetaryAmount.toObject(message.netSales, options);
                    if (message.operatingProfit != null && message.hasOwnProperty("operatingProfit"))
                        object.operatingProfit = $root.sharelib.MonetaryAmount.toObject(message.operatingProfit, options);
                    if (message.ordinaryIncome != null && message.hasOwnProperty("ordinaryIncome"))
                        object.ordinaryIncome = $root.sharelib.MonetaryAmount.toObject(message.ordinaryIncome, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.nondisclosure != null && message.hasOwnProperty("nondisclosure"))
                        object.nondisclosure = message.nondisclosure;
                    return object;
                };

                /**
                 * Converts this BusinessUnitFinancial to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitFinancial
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitFinancial.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitFinancial;
            })();

            company.BusinessUnitBranch = (function() {

                /**
                 * Properties of a BusinessUnitBranch.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitBranch
                 * @property {string|null} [businessUnitBranchId] BusinessUnitBranch businessUnitBranchId
                 * @property {string|null} [companyId] BusinessUnitBranch companyId
                 * @property {string|null} [displayName] BusinessUnitBranch displayName
                 * @property {mtechnavi.api.company.IAddress|null} [address] BusinessUnitBranch address
                 * @property {string|null} [phoneNumber] BusinessUnitBranch phoneNumber
                 * @property {string|null} [faxNumber] BusinessUnitBranch faxNumber
                 * @property {string|null} [overview] BusinessUnitBranch overview
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitBranch sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitBranch updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitBranch createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitBranch updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitBranch deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitBranch.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitBranch.
                 * @implements IBusinessUnitBranch
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitBranch=} [properties] Properties to set
                 */
                function BusinessUnitBranch(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitBranch businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.businessUnitBranchId = "";

                /**
                 * BusinessUnitBranch companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.companyId = "";

                /**
                 * BusinessUnitBranch displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.displayName = "";

                /**
                 * BusinessUnitBranch address.
                 * @member {mtechnavi.api.company.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.address = null;

                /**
                 * BusinessUnitBranch phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.phoneNumber = "";

                /**
                 * BusinessUnitBranch faxNumber.
                 * @member {string} faxNumber
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.faxNumber = "";

                /**
                 * BusinessUnitBranch overview.
                 * @member {string} overview
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.overview = "";

                /**
                 * BusinessUnitBranch sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.sharedProperties = null;

                /**
                 * BusinessUnitBranch updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.updatedProperties = null;

                /**
                 * BusinessUnitBranch createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitBranch updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitBranch deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 */
                BusinessUnitBranch.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitBranch message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitBranch.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        if (!$util.isString(message.faxNumber))
                            return "faxNumber: string expected";
                    if (message.overview != null && message.hasOwnProperty("overview"))
                        if (!$util.isString(message.overview))
                            return "overview: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitBranch message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitBranch} BusinessUnitBranch
                 */
                BusinessUnitBranch.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitBranch)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitBranch();
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitBranch.address: object expected");
                        message.address = $root.mtechnavi.api.company.Address.fromObject(object.address);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.faxNumber != null)
                        message.faxNumber = String(object.faxNumber);
                    if (object.overview != null)
                        message.overview = String(object.overview);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitBranch.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitBranch.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitBranch message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitBranch} message BusinessUnitBranch
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitBranch.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitBranchId = "";
                        object.companyId = "";
                        object.displayName = "";
                        object.address = null;
                        object.phoneNumber = "";
                        object.faxNumber = "";
                        object.overview = "";
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.Address.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = message.faxNumber;
                    if (message.overview != null && message.hasOwnProperty("overview"))
                        object.overview = message.overview;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitBranch to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitBranch
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitBranch.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitBranch;
            })();

            company.BusinessUnitFacilities = (function() {

                /**
                 * Properties of a BusinessUnitFacilities.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitFacilities
                 * @property {string|null} [businessUnitFacilitiesId] BusinessUnitFacilities businessUnitFacilitiesId
                 * @property {string|null} [companyId] BusinessUnitFacilities companyId
                 * @property {string|null} [businessUnitBranchId] BusinessUnitFacilities businessUnitBranchId
                 * @property {Array.<sharelib.INameOption>|null} [categorys] BusinessUnitFacilities categorys
                 * @property {string|null} [manufacture] BusinessUnitFacilities manufacture
                 * @property {string|null} [modelNumber] BusinessUnitFacilities modelNumber
                 * @property {Long|null} [assetsAmount] BusinessUnitFacilities assetsAmount
                 * @property {sharelib.INameOption|null} [accuracy] BusinessUnitFacilities accuracy
                 * @property {Long|null} [processableTon] BusinessUnitFacilities processableTon
                 * @property {Long|null} [sizeX] BusinessUnitFacilities sizeX
                 * @property {Long|null} [sizeY] BusinessUnitFacilities sizeY
                 * @property {Long|null} [sizeZ] BusinessUnitFacilities sizeZ
                 * @property {Long|null} [sizeT] BusinessUnitFacilities sizeT
                 * @property {Long|null} [diameter] BusinessUnitFacilities diameter
                 * @property {sharelib.INameOption|null} [processableMaterial] BusinessUnitFacilities processableMaterial
                 * @property {string|null} [remarks] BusinessUnitFacilities remarks
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitFacilities sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitFacilities updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitFacilities createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitFacilities updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitFacilities deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitFacilities.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitFacilities.
                 * @implements IBusinessUnitFacilities
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitFacilities=} [properties] Properties to set
                 */
                function BusinessUnitFacilities(properties) {
                    this.categorys = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitFacilities businessUnitFacilitiesId.
                 * @member {string} businessUnitFacilitiesId
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.businessUnitFacilitiesId = "";

                /**
                 * BusinessUnitFacilities companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.companyId = "";

                /**
                 * BusinessUnitFacilities businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.businessUnitBranchId = "";

                /**
                 * BusinessUnitFacilities categorys.
                 * @member {Array.<sharelib.INameOption>} categorys
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.categorys = $util.emptyArray;

                /**
                 * BusinessUnitFacilities manufacture.
                 * @member {string} manufacture
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.manufacture = "";

                /**
                 * BusinessUnitFacilities modelNumber.
                 * @member {string} modelNumber
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.modelNumber = "";

                /**
                 * BusinessUnitFacilities assetsAmount.
                 * @member {Long} assetsAmount
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.assetsAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities accuracy.
                 * @member {sharelib.INameOption|null|undefined} accuracy
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.accuracy = null;

                /**
                 * BusinessUnitFacilities processableTon.
                 * @member {Long} processableTon
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.processableTon = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities sizeX.
                 * @member {Long} sizeX
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.sizeX = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities sizeY.
                 * @member {Long} sizeY
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.sizeY = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities sizeZ.
                 * @member {Long} sizeZ
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.sizeZ = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities sizeT.
                 * @member {Long} sizeT
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.sizeT = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities diameter.
                 * @member {Long} diameter
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.diameter = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities processableMaterial.
                 * @member {sharelib.INameOption|null|undefined} processableMaterial
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.processableMaterial = null;

                /**
                 * BusinessUnitFacilities remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.remarks = "";

                /**
                 * BusinessUnitFacilities sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.sharedProperties = null;

                /**
                 * BusinessUnitFacilities updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.updatedProperties = null;

                /**
                 * BusinessUnitFacilities createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitFacilities deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 */
                BusinessUnitFacilities.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitFacilities message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitFacilities.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        if (!$util.isString(message.businessUnitFacilitiesId))
                            return "businessUnitFacilitiesId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    if (message.categorys != null && message.hasOwnProperty("categorys")) {
                        if (!Array.isArray(message.categorys))
                            return "categorys: array expected";
                        for (let i = 0; i < message.categorys.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.categorys[i]);
                            if (error)
                                return "categorys." + error;
                        }
                    }
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        if (!$util.isString(message.manufacture))
                            return "manufacture: string expected";
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        if (!$util.isString(message.modelNumber))
                            return "modelNumber: string expected";
                    if (message.assetsAmount != null && message.hasOwnProperty("assetsAmount"))
                        if (!$util.isInteger(message.assetsAmount) && !(message.assetsAmount && $util.isInteger(message.assetsAmount.low) && $util.isInteger(message.assetsAmount.high)))
                            return "assetsAmount: integer|Long expected";
                    if (message.accuracy != null && message.hasOwnProperty("accuracy")) {
                        let error = $root.sharelib.NameOption.verify(message.accuracy);
                        if (error)
                            return "accuracy." + error;
                    }
                    if (message.processableTon != null && message.hasOwnProperty("processableTon"))
                        if (!$util.isInteger(message.processableTon) && !(message.processableTon && $util.isInteger(message.processableTon.low) && $util.isInteger(message.processableTon.high)))
                            return "processableTon: integer|Long expected";
                    if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                        if (!$util.isInteger(message.sizeX) && !(message.sizeX && $util.isInteger(message.sizeX.low) && $util.isInteger(message.sizeX.high)))
                            return "sizeX: integer|Long expected";
                    if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                        if (!$util.isInteger(message.sizeY) && !(message.sizeY && $util.isInteger(message.sizeY.low) && $util.isInteger(message.sizeY.high)))
                            return "sizeY: integer|Long expected";
                    if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                        if (!$util.isInteger(message.sizeZ) && !(message.sizeZ && $util.isInteger(message.sizeZ.low) && $util.isInteger(message.sizeZ.high)))
                            return "sizeZ: integer|Long expected";
                    if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                        if (!$util.isInteger(message.sizeT) && !(message.sizeT && $util.isInteger(message.sizeT.low) && $util.isInteger(message.sizeT.high)))
                            return "sizeT: integer|Long expected";
                    if (message.diameter != null && message.hasOwnProperty("diameter"))
                        if (!$util.isInteger(message.diameter) && !(message.diameter && $util.isInteger(message.diameter.low) && $util.isInteger(message.diameter.high)))
                            return "diameter: integer|Long expected";
                    if (message.processableMaterial != null && message.hasOwnProperty("processableMaterial")) {
                        let error = $root.sharelib.NameOption.verify(message.processableMaterial);
                        if (error)
                            return "processableMaterial." + error;
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitFacilities message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitFacilities} BusinessUnitFacilities
                 */
                BusinessUnitFacilities.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitFacilities)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitFacilities();
                    if (object.businessUnitFacilitiesId != null)
                        message.businessUnitFacilitiesId = String(object.businessUnitFacilitiesId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    if (object.categorys) {
                        if (!Array.isArray(object.categorys))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.categorys: array expected");
                        message.categorys = [];
                        for (let i = 0; i < object.categorys.length; ++i) {
                            if (typeof object.categorys[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.categorys: object expected");
                            message.categorys[i] = $root.sharelib.NameOption.fromObject(object.categorys[i]);
                        }
                    }
                    if (object.manufacture != null)
                        message.manufacture = String(object.manufacture);
                    if (object.modelNumber != null)
                        message.modelNumber = String(object.modelNumber);
                    if (object.assetsAmount != null)
                        if ($util.Long)
                            (message.assetsAmount = $util.Long.fromValue(object.assetsAmount)).unsigned = false;
                        else if (typeof object.assetsAmount === "string")
                            message.assetsAmount = parseInt(object.assetsAmount, 10);
                        else if (typeof object.assetsAmount === "number")
                            message.assetsAmount = object.assetsAmount;
                        else if (typeof object.assetsAmount === "object")
                            message.assetsAmount = new $util.LongBits(object.assetsAmount.low >>> 0, object.assetsAmount.high >>> 0).toNumber();
                    if (object.accuracy != null) {
                        if (typeof object.accuracy !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.accuracy: object expected");
                        message.accuracy = $root.sharelib.NameOption.fromObject(object.accuracy);
                    }
                    if (object.processableTon != null)
                        if ($util.Long)
                            (message.processableTon = $util.Long.fromValue(object.processableTon)).unsigned = false;
                        else if (typeof object.processableTon === "string")
                            message.processableTon = parseInt(object.processableTon, 10);
                        else if (typeof object.processableTon === "number")
                            message.processableTon = object.processableTon;
                        else if (typeof object.processableTon === "object")
                            message.processableTon = new $util.LongBits(object.processableTon.low >>> 0, object.processableTon.high >>> 0).toNumber();
                    if (object.sizeX != null)
                        if ($util.Long)
                            (message.sizeX = $util.Long.fromValue(object.sizeX)).unsigned = false;
                        else if (typeof object.sizeX === "string")
                            message.sizeX = parseInt(object.sizeX, 10);
                        else if (typeof object.sizeX === "number")
                            message.sizeX = object.sizeX;
                        else if (typeof object.sizeX === "object")
                            message.sizeX = new $util.LongBits(object.sizeX.low >>> 0, object.sizeX.high >>> 0).toNumber();
                    if (object.sizeY != null)
                        if ($util.Long)
                            (message.sizeY = $util.Long.fromValue(object.sizeY)).unsigned = false;
                        else if (typeof object.sizeY === "string")
                            message.sizeY = parseInt(object.sizeY, 10);
                        else if (typeof object.sizeY === "number")
                            message.sizeY = object.sizeY;
                        else if (typeof object.sizeY === "object")
                            message.sizeY = new $util.LongBits(object.sizeY.low >>> 0, object.sizeY.high >>> 0).toNumber();
                    if (object.sizeZ != null)
                        if ($util.Long)
                            (message.sizeZ = $util.Long.fromValue(object.sizeZ)).unsigned = false;
                        else if (typeof object.sizeZ === "string")
                            message.sizeZ = parseInt(object.sizeZ, 10);
                        else if (typeof object.sizeZ === "number")
                            message.sizeZ = object.sizeZ;
                        else if (typeof object.sizeZ === "object")
                            message.sizeZ = new $util.LongBits(object.sizeZ.low >>> 0, object.sizeZ.high >>> 0).toNumber();
                    if (object.sizeT != null)
                        if ($util.Long)
                            (message.sizeT = $util.Long.fromValue(object.sizeT)).unsigned = false;
                        else if (typeof object.sizeT === "string")
                            message.sizeT = parseInt(object.sizeT, 10);
                        else if (typeof object.sizeT === "number")
                            message.sizeT = object.sizeT;
                        else if (typeof object.sizeT === "object")
                            message.sizeT = new $util.LongBits(object.sizeT.low >>> 0, object.sizeT.high >>> 0).toNumber();
                    if (object.diameter != null)
                        if ($util.Long)
                            (message.diameter = $util.Long.fromValue(object.diameter)).unsigned = false;
                        else if (typeof object.diameter === "string")
                            message.diameter = parseInt(object.diameter, 10);
                        else if (typeof object.diameter === "number")
                            message.diameter = object.diameter;
                        else if (typeof object.diameter === "object")
                            message.diameter = new $util.LongBits(object.diameter.low >>> 0, object.diameter.high >>> 0).toNumber();
                    if (object.processableMaterial != null) {
                        if (typeof object.processableMaterial !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.processableMaterial: object expected");
                        message.processableMaterial = $root.sharelib.NameOption.fromObject(object.processableMaterial);
                    }
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitFacilities.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitFacilities message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitFacilities} message BusinessUnitFacilities
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitFacilities.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.categorys = [];
                    if (options.defaults) {
                        object.businessUnitFacilitiesId = "";
                        object.companyId = "";
                        object.businessUnitBranchId = "";
                        object.manufacture = "";
                        object.modelNumber = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.assetsAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.assetsAmount = options.longs === String ? "0" : 0;
                        object.accuracy = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.processableTon = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processableTon = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeX = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeX = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeY = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeY = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeZ = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeZ = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sizeT = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sizeT = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.diameter = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.diameter = options.longs === String ? "0" : 0;
                        object.processableMaterial = null;
                        object.remarks = "";
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                        object.businessUnitFacilitiesId = message.businessUnitFacilitiesId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        object.manufacture = message.manufacture;
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        object.modelNumber = message.modelNumber;
                    if (message.assetsAmount != null && message.hasOwnProperty("assetsAmount"))
                        if (typeof message.assetsAmount === "number")
                            object.assetsAmount = options.longs === String ? String(message.assetsAmount) : message.assetsAmount;
                        else
                            object.assetsAmount = options.longs === String ? $util.Long.prototype.toString.call(message.assetsAmount) : options.longs === Number ? new $util.LongBits(message.assetsAmount.low >>> 0, message.assetsAmount.high >>> 0).toNumber() : message.assetsAmount;
                    if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                        object.accuracy = $root.sharelib.NameOption.toObject(message.accuracy, options);
                    if (message.processableTon != null && message.hasOwnProperty("processableTon"))
                        if (typeof message.processableTon === "number")
                            object.processableTon = options.longs === String ? String(message.processableTon) : message.processableTon;
                        else
                            object.processableTon = options.longs === String ? $util.Long.prototype.toString.call(message.processableTon) : options.longs === Number ? new $util.LongBits(message.processableTon.low >>> 0, message.processableTon.high >>> 0).toNumber() : message.processableTon;
                    if (message.sizeX != null && message.hasOwnProperty("sizeX"))
                        if (typeof message.sizeX === "number")
                            object.sizeX = options.longs === String ? String(message.sizeX) : message.sizeX;
                        else
                            object.sizeX = options.longs === String ? $util.Long.prototype.toString.call(message.sizeX) : options.longs === Number ? new $util.LongBits(message.sizeX.low >>> 0, message.sizeX.high >>> 0).toNumber() : message.sizeX;
                    if (message.sizeY != null && message.hasOwnProperty("sizeY"))
                        if (typeof message.sizeY === "number")
                            object.sizeY = options.longs === String ? String(message.sizeY) : message.sizeY;
                        else
                            object.sizeY = options.longs === String ? $util.Long.prototype.toString.call(message.sizeY) : options.longs === Number ? new $util.LongBits(message.sizeY.low >>> 0, message.sizeY.high >>> 0).toNumber() : message.sizeY;
                    if (message.sizeZ != null && message.hasOwnProperty("sizeZ"))
                        if (typeof message.sizeZ === "number")
                            object.sizeZ = options.longs === String ? String(message.sizeZ) : message.sizeZ;
                        else
                            object.sizeZ = options.longs === String ? $util.Long.prototype.toString.call(message.sizeZ) : options.longs === Number ? new $util.LongBits(message.sizeZ.low >>> 0, message.sizeZ.high >>> 0).toNumber() : message.sizeZ;
                    if (message.sizeT != null && message.hasOwnProperty("sizeT"))
                        if (typeof message.sizeT === "number")
                            object.sizeT = options.longs === String ? String(message.sizeT) : message.sizeT;
                        else
                            object.sizeT = options.longs === String ? $util.Long.prototype.toString.call(message.sizeT) : options.longs === Number ? new $util.LongBits(message.sizeT.low >>> 0, message.sizeT.high >>> 0).toNumber() : message.sizeT;
                    if (message.diameter != null && message.hasOwnProperty("diameter"))
                        if (typeof message.diameter === "number")
                            object.diameter = options.longs === String ? String(message.diameter) : message.diameter;
                        else
                            object.diameter = options.longs === String ? $util.Long.prototype.toString.call(message.diameter) : options.longs === Number ? new $util.LongBits(message.diameter.low >>> 0, message.diameter.high >>> 0).toNumber() : message.diameter;
                    if (message.processableMaterial != null && message.hasOwnProperty("processableMaterial"))
                        object.processableMaterial = $root.sharelib.NameOption.toObject(message.processableMaterial, options);
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.categorys && message.categorys.length) {
                        object.categorys = [];
                        for (let j = 0; j < message.categorys.length; ++j)
                            object.categorys[j] = $root.sharelib.NameOption.toObject(message.categorys[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnitFacilities to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitFacilities
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitFacilities.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitFacilities;
            })();

            company.BusinessUnitCertificate = (function() {

                /**
                 * Properties of a BusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitCertificate
                 * @property {string|null} [businessUnitCertificateId] BusinessUnitCertificate businessUnitCertificateId
                 * @property {string|null} [companyId] BusinessUnitCertificate companyId
                 * @property {sharelib.INameOption|null} [certificate] BusinessUnitCertificate certificate
                 * @property {boolean|null} [obtained] BusinessUnitCertificate obtained
                 * @property {string|null} [displayName] BusinessUnitCertificate displayName
                 * @property {string|null} [certificateNumber] BusinessUnitCertificate certificateNumber
                 * @property {sharelib.IAttachment|null} [attachment] BusinessUnitCertificate attachment
                 * @property {sharelib.IDatetime|null} [obtainedDt] BusinessUnitCertificate obtainedDt
                 * @property {sharelib.IDatetime|null} [expirationDt] BusinessUnitCertificate expirationDt
                 * @property {sharelib.IDatetime|null} [obtainPlanDt] BusinessUnitCertificate obtainPlanDt
                 * @property {string|null} [scopeProduct] BusinessUnitCertificate scopeProduct
                 * @property {string|null} [scopeBranch] BusinessUnitCertificate scopeBranch
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitCertificate sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitCertificate updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitCertificate createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitCertificate updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitCertificate deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitCertificate.
                 * @implements IBusinessUnitCertificate
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitCertificate=} [properties] Properties to set
                 */
                function BusinessUnitCertificate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitCertificate businessUnitCertificateId.
                 * @member {string} businessUnitCertificateId
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.businessUnitCertificateId = "";

                /**
                 * BusinessUnitCertificate companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.companyId = "";

                /**
                 * BusinessUnitCertificate certificate.
                 * @member {sharelib.INameOption|null|undefined} certificate
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.certificate = null;

                /**
                 * BusinessUnitCertificate obtained.
                 * @member {boolean} obtained
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.obtained = false;

                /**
                 * BusinessUnitCertificate displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.displayName = "";

                /**
                 * BusinessUnitCertificate certificateNumber.
                 * @member {string} certificateNumber
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.certificateNumber = "";

                /**
                 * BusinessUnitCertificate attachment.
                 * @member {sharelib.IAttachment|null|undefined} attachment
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.attachment = null;

                /**
                 * BusinessUnitCertificate obtainedDt.
                 * @member {sharelib.IDatetime|null|undefined} obtainedDt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.obtainedDt = null;

                /**
                 * BusinessUnitCertificate expirationDt.
                 * @member {sharelib.IDatetime|null|undefined} expirationDt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.expirationDt = null;

                /**
                 * BusinessUnitCertificate obtainPlanDt.
                 * @member {sharelib.IDatetime|null|undefined} obtainPlanDt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.obtainPlanDt = null;

                /**
                 * BusinessUnitCertificate scopeProduct.
                 * @member {string} scopeProduct
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.scopeProduct = "";

                /**
                 * BusinessUnitCertificate scopeBranch.
                 * @member {string} scopeBranch
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.scopeBranch = "";

                /**
                 * BusinessUnitCertificate sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.sharedProperties = null;

                /**
                 * BusinessUnitCertificate updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.updatedProperties = null;

                /**
                 * BusinessUnitCertificate createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitCertificate updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitCertificate deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 */
                BusinessUnitCertificate.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitCertificate message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitCertificate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        if (!$util.isString(message.businessUnitCertificateId))
                            return "businessUnitCertificateId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.certificate != null && message.hasOwnProperty("certificate")) {
                        let error = $root.sharelib.NameOption.verify(message.certificate);
                        if (error)
                            return "certificate." + error;
                    }
                    if (message.obtained != null && message.hasOwnProperty("obtained"))
                        if (typeof message.obtained !== "boolean")
                            return "obtained: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber"))
                        if (!$util.isString(message.certificateNumber))
                            return "certificateNumber: string expected";
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.sharelib.Attachment.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt")) {
                        let error = $root.sharelib.Datetime.verify(message.obtainedDt);
                        if (error)
                            return "obtainedDt." + error;
                    }
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt")) {
                        let error = $root.sharelib.Datetime.verify(message.expirationDt);
                        if (error)
                            return "expirationDt." + error;
                    }
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt")) {
                        let error = $root.sharelib.Datetime.verify(message.obtainPlanDt);
                        if (error)
                            return "obtainPlanDt." + error;
                    }
                    if (message.scopeProduct != null && message.hasOwnProperty("scopeProduct"))
                        if (!$util.isString(message.scopeProduct))
                            return "scopeProduct: string expected";
                    if (message.scopeBranch != null && message.hasOwnProperty("scopeBranch"))
                        if (!$util.isString(message.scopeBranch))
                            return "scopeBranch: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitCertificate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitCertificate} BusinessUnitCertificate
                 */
                BusinessUnitCertificate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitCertificate)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitCertificate();
                    if (object.businessUnitCertificateId != null)
                        message.businessUnitCertificateId = String(object.businessUnitCertificateId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.certificate != null) {
                        if (typeof object.certificate !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.certificate: object expected");
                        message.certificate = $root.sharelib.NameOption.fromObject(object.certificate);
                    }
                    if (object.obtained != null)
                        message.obtained = Boolean(object.obtained);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.certificateNumber != null)
                        message.certificateNumber = String(object.certificateNumber);
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.attachment: object expected");
                        message.attachment = $root.sharelib.Attachment.fromObject(object.attachment);
                    }
                    if (object.obtainedDt != null) {
                        if (typeof object.obtainedDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.obtainedDt: object expected");
                        message.obtainedDt = $root.sharelib.Datetime.fromObject(object.obtainedDt);
                    }
                    if (object.expirationDt != null) {
                        if (typeof object.expirationDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.expirationDt: object expected");
                        message.expirationDt = $root.sharelib.Datetime.fromObject(object.expirationDt);
                    }
                    if (object.obtainPlanDt != null) {
                        if (typeof object.obtainPlanDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.obtainPlanDt: object expected");
                        message.obtainPlanDt = $root.sharelib.Datetime.fromObject(object.obtainPlanDt);
                    }
                    if (object.scopeProduct != null)
                        message.scopeProduct = String(object.scopeProduct);
                    if (object.scopeBranch != null)
                        message.scopeBranch = String(object.scopeBranch);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitCertificate.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitCertificate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitCertificate} message BusinessUnitCertificate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitCertificate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitCertificateId = "";
                        object.companyId = "";
                        object.certificate = null;
                        object.obtained = false;
                        object.displayName = "";
                        object.certificateNumber = "";
                        object.attachment = null;
                        object.obtainedDt = null;
                        object.expirationDt = null;
                        object.obtainPlanDt = null;
                        object.scopeProduct = "";
                        object.scopeBranch = "";
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitCertificateId != null && message.hasOwnProperty("businessUnitCertificateId"))
                        object.businessUnitCertificateId = message.businessUnitCertificateId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.certificate != null && message.hasOwnProperty("certificate"))
                        object.certificate = $root.sharelib.NameOption.toObject(message.certificate, options);
                    if (message.obtained != null && message.hasOwnProperty("obtained"))
                        object.obtained = message.obtained;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber"))
                        object.certificateNumber = message.certificateNumber;
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.sharelib.Attachment.toObject(message.attachment, options);
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt"))
                        object.obtainedDt = $root.sharelib.Datetime.toObject(message.obtainedDt, options);
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt"))
                        object.expirationDt = $root.sharelib.Datetime.toObject(message.expirationDt, options);
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt"))
                        object.obtainPlanDt = $root.sharelib.Datetime.toObject(message.obtainPlanDt, options);
                    if (message.scopeProduct != null && message.hasOwnProperty("scopeProduct"))
                        object.scopeProduct = message.scopeProduct;
                    if (message.scopeBranch != null && message.hasOwnProperty("scopeBranch"))
                        object.scopeBranch = message.scopeBranch;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitCertificate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitCertificate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitCertificate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitCertificate;
            })();

            company.BusinessUnitStrength = (function() {

                /**
                 * Properties of a BusinessUnitStrength.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitStrength
                 * @property {string|null} [businessUnitStrengthId] BusinessUnitStrength businessUnitStrengthId
                 * @property {string|null} [companyId] BusinessUnitStrength companyId
                 * @property {sharelib.INameOption|null} [category] BusinessUnitStrength category
                 * @property {string|null} [content] BusinessUnitStrength content
                 * @property {mtechnavi.api.company.BusinessUnitStrength.ILinkProperties|null} [linkProperties] BusinessUnitStrength linkProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitStrength sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitStrength updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitStrength createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitStrength updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitStrength deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitStrength.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitStrength.
                 * @implements IBusinessUnitStrength
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitStrength=} [properties] Properties to set
                 */
                function BusinessUnitStrength(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitStrength businessUnitStrengthId.
                 * @member {string} businessUnitStrengthId
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.businessUnitStrengthId = "";

                /**
                 * BusinessUnitStrength companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.companyId = "";

                /**
                 * BusinessUnitStrength category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.category = null;

                /**
                 * BusinessUnitStrength content.
                 * @member {string} content
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.content = "";

                /**
                 * BusinessUnitStrength linkProperties.
                 * @member {mtechnavi.api.company.BusinessUnitStrength.ILinkProperties|null|undefined} linkProperties
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.linkProperties = null;

                /**
                 * BusinessUnitStrength sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.sharedProperties = null;

                /**
                 * BusinessUnitStrength updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.updatedProperties = null;

                /**
                 * BusinessUnitStrength createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitStrength updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitStrength deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 */
                BusinessUnitStrength.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitStrength message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitStrength.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        if (!$util.isString(message.businessUnitStrengthId))
                            return "businessUnitStrengthId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.linkProperties != null && message.hasOwnProperty("linkProperties")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.LinkProperties.verify(message.linkProperties);
                        if (error)
                            return "linkProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitStrength message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitStrength} BusinessUnitStrength
                 */
                BusinessUnitStrength.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitStrength)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitStrength();
                    if (object.businessUnitStrengthId != null)
                        message.businessUnitStrengthId = String(object.businessUnitStrengthId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitStrength.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.linkProperties != null) {
                        if (typeof object.linkProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitStrength.linkProperties: object expected");
                        message.linkProperties = $root.mtechnavi.api.company.BusinessUnitStrength.LinkProperties.fromObject(object.linkProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitStrength.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitStrength.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitStrength message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitStrength} message BusinessUnitStrength
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitStrength.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitStrengthId = "";
                        object.companyId = "";
                        object.category = null;
                        object.content = "";
                        object.linkProperties = null;
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitStrengthId != null && message.hasOwnProperty("businessUnitStrengthId"))
                        object.businessUnitStrengthId = message.businessUnitStrengthId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.linkProperties != null && message.hasOwnProperty("linkProperties"))
                        object.linkProperties = $root.mtechnavi.api.company.BusinessUnitStrength.LinkProperties.toObject(message.linkProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitStrength to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitStrength
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitStrength.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                BusinessUnitStrength.LinkProperties = (function() {

                    /**
                     * Properties of a LinkProperties.
                     * @memberof mtechnavi.api.company.BusinessUnitStrength
                     * @interface ILinkProperties
                     * @property {string|null} [businessUnitBranchId] LinkProperties businessUnitBranchId
                     * @property {string|null} [businessUnitFacilitiesId] LinkProperties businessUnitFacilitiesId
                     */

                    /**
                     * Constructs a new LinkProperties.
                     * @memberof mtechnavi.api.company.BusinessUnitStrength
                     * @classdesc Represents a LinkProperties.
                     * @implements ILinkProperties
                     * @constructor
                     * @param {mtechnavi.api.company.BusinessUnitStrength.ILinkProperties=} [properties] Properties to set
                     */
                    function LinkProperties(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LinkProperties businessUnitBranchId.
                     * @member {string} businessUnitBranchId
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @instance
                     */
                    LinkProperties.prototype.businessUnitBranchId = "";

                    /**
                     * LinkProperties businessUnitFacilitiesId.
                     * @member {string} businessUnitFacilitiesId
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @instance
                     */
                    LinkProperties.prototype.businessUnitFacilitiesId = "";

                    /**
                     * Verifies a LinkProperties message.
                     * @function verify
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LinkProperties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                            if (!$util.isString(message.businessUnitBranchId))
                                return "businessUnitBranchId: string expected";
                        if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                            if (!$util.isString(message.businessUnitFacilitiesId))
                                return "businessUnitFacilitiesId: string expected";
                        return null;
                    };

                    /**
                     * Creates a LinkProperties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.BusinessUnitStrength.LinkProperties} LinkProperties
                     */
                    LinkProperties.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.BusinessUnitStrength.LinkProperties)
                            return object;
                        let message = new $root.mtechnavi.api.company.BusinessUnitStrength.LinkProperties();
                        if (object.businessUnitBranchId != null)
                            message.businessUnitBranchId = String(object.businessUnitBranchId);
                        if (object.businessUnitFacilitiesId != null)
                            message.businessUnitFacilitiesId = String(object.businessUnitFacilitiesId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LinkProperties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @static
                     * @param {mtechnavi.api.company.BusinessUnitStrength.LinkProperties} message LinkProperties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LinkProperties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.businessUnitBranchId = "";
                            object.businessUnitFacilitiesId = "";
                        }
                        if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                            object.businessUnitBranchId = message.businessUnitBranchId;
                        if (message.businessUnitFacilitiesId != null && message.hasOwnProperty("businessUnitFacilitiesId"))
                            object.businessUnitFacilitiesId = message.businessUnitFacilitiesId;
                        return object;
                    };

                    /**
                     * Converts this LinkProperties to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.BusinessUnitStrength.LinkProperties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LinkProperties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LinkProperties;
                })();

                return BusinessUnitStrength;
            })();

            company.BusinessUnitSkill = (function() {

                /**
                 * Properties of a BusinessUnitSkill.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitSkill
                 * @property {string|null} [businessUnitSkillId] BusinessUnitSkill businessUnitSkillId
                 * @property {string|null} [companyId] BusinessUnitSkill companyId
                 * @property {Array.<string>|null} [businessUnitSkillItemIds] BusinessUnitSkill businessUnitSkillItemIds
                 * @property {string|null} [otherSkill] BusinessUnitSkill otherSkill
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitSkill sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitSkill updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitSkill createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitSkill updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitSkill deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitSkill.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitSkill.
                 * @implements IBusinessUnitSkill
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitSkill=} [properties] Properties to set
                 */
                function BusinessUnitSkill(properties) {
                    this.businessUnitSkillItemIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitSkill businessUnitSkillId.
                 * @member {string} businessUnitSkillId
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.businessUnitSkillId = "";

                /**
                 * BusinessUnitSkill companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.companyId = "";

                /**
                 * BusinessUnitSkill businessUnitSkillItemIds.
                 * @member {Array.<string>} businessUnitSkillItemIds
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.businessUnitSkillItemIds = $util.emptyArray;

                /**
                 * BusinessUnitSkill otherSkill.
                 * @member {string} otherSkill
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.otherSkill = "";

                /**
                 * BusinessUnitSkill sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.sharedProperties = null;

                /**
                 * BusinessUnitSkill updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.updatedProperties = null;

                /**
                 * BusinessUnitSkill createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitSkill updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitSkill deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 */
                BusinessUnitSkill.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitSkill message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitSkill.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        if (!$util.isString(message.businessUnitSkillId))
                            return "businessUnitSkillId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.businessUnitSkillItemIds != null && message.hasOwnProperty("businessUnitSkillItemIds")) {
                        if (!Array.isArray(message.businessUnitSkillItemIds))
                            return "businessUnitSkillItemIds: array expected";
                        for (let i = 0; i < message.businessUnitSkillItemIds.length; ++i)
                            if (!$util.isString(message.businessUnitSkillItemIds[i]))
                                return "businessUnitSkillItemIds: string[] expected";
                    }
                    if (message.otherSkill != null && message.hasOwnProperty("otherSkill"))
                        if (!$util.isString(message.otherSkill))
                            return "otherSkill: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitSkill message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitSkill} BusinessUnitSkill
                 */
                BusinessUnitSkill.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitSkill)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitSkill();
                    if (object.businessUnitSkillId != null)
                        message.businessUnitSkillId = String(object.businessUnitSkillId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.businessUnitSkillItemIds) {
                        if (!Array.isArray(object.businessUnitSkillItemIds))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkill.businessUnitSkillItemIds: array expected");
                        message.businessUnitSkillItemIds = [];
                        for (let i = 0; i < object.businessUnitSkillItemIds.length; ++i)
                            message.businessUnitSkillItemIds[i] = String(object.businessUnitSkillItemIds[i]);
                    }
                    if (object.otherSkill != null)
                        message.otherSkill = String(object.otherSkill);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkill.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkill.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitSkill message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitSkill} message BusinessUnitSkill
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitSkill.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitSkillItemIds = [];
                    if (options.defaults) {
                        object.businessUnitSkillId = "";
                        object.companyId = "";
                        object.sharedProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.otherSkill = "";
                    }
                    if (message.businessUnitSkillId != null && message.hasOwnProperty("businessUnitSkillId"))
                        object.businessUnitSkillId = message.businessUnitSkillId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.businessUnitSkillItemIds && message.businessUnitSkillItemIds.length) {
                        object.businessUnitSkillItemIds = [];
                        for (let j = 0; j < message.businessUnitSkillItemIds.length; ++j)
                            object.businessUnitSkillItemIds[j] = message.businessUnitSkillItemIds[j];
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.otherSkill != null && message.hasOwnProperty("otherSkill"))
                        object.otherSkill = message.otherSkill;
                    return object;
                };

                /**
                 * Converts this BusinessUnitSkill to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitSkill
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitSkill.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitSkill;
            })();

            company.BusinessUnitContactHeader = (function() {

                /**
                 * Properties of a BusinessUnitContactHeader.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitContactHeader
                 * @property {string|null} [businessUnitContactHeaderId] BusinessUnitContactHeader businessUnitContactHeaderId
                 * @property {string|null} [businessUnitManagementId] BusinessUnitContactHeader businessUnitManagementId
                 * @property {string|null} [companyId] BusinessUnitContactHeader companyId
                 * @property {string|null} [displayName] BusinessUnitContactHeader displayName
                 * @property {boolean|null} [documentRecipientAddress] BusinessUnitContactHeader documentRecipientAddress
                 * @property {boolean|null} [notificationUser] BusinessUnitContactHeader notificationUser
                 * @property {mtechnavi.api.company.IProcess|null} [requestProcess] BusinessUnitContactHeader requestProcess
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitContactHeader sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitContactHeader createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitContactHeader updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitContactHeader deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitContactHeader.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitContactHeader.
                 * @implements IBusinessUnitContactHeader
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitContactHeader=} [properties] Properties to set
                 */
                function BusinessUnitContactHeader(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitContactHeader businessUnitContactHeaderId.
                 * @member {string} businessUnitContactHeaderId
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.businessUnitContactHeaderId = "";

                /**
                 * BusinessUnitContactHeader businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitContactHeader companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.companyId = "";

                /**
                 * BusinessUnitContactHeader displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.displayName = "";

                /**
                 * BusinessUnitContactHeader documentRecipientAddress.
                 * @member {boolean} documentRecipientAddress
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.documentRecipientAddress = false;

                /**
                 * BusinessUnitContactHeader notificationUser.
                 * @member {boolean} notificationUser
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.notificationUser = false;

                /**
                 * BusinessUnitContactHeader requestProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} requestProcess
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.requestProcess = null;

                /**
                 * BusinessUnitContactHeader sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.sharedProperties = null;

                /**
                 * BusinessUnitContactHeader createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContactHeader updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContactHeader deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 */
                BusinessUnitContactHeader.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitContactHeader message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitContactHeader.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        if (!$util.isString(message.businessUnitContactHeaderId))
                            return "businessUnitContactHeaderId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.documentRecipientAddress != null && message.hasOwnProperty("documentRecipientAddress"))
                        if (typeof message.documentRecipientAddress !== "boolean")
                            return "documentRecipientAddress: boolean expected";
                    if (message.notificationUser != null && message.hasOwnProperty("notificationUser"))
                        if (typeof message.notificationUser !== "boolean")
                            return "notificationUser: boolean expected";
                    if (message.requestProcess != null && message.hasOwnProperty("requestProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.requestProcess);
                        if (error)
                            return "requestProcess." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitContactHeader message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitContactHeader} BusinessUnitContactHeader
                 */
                BusinessUnitContactHeader.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitContactHeader)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitContactHeader();
                    if (object.businessUnitContactHeaderId != null)
                        message.businessUnitContactHeaderId = String(object.businessUnitContactHeaderId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.documentRecipientAddress != null)
                        message.documentRecipientAddress = Boolean(object.documentRecipientAddress);
                    if (object.notificationUser != null)
                        message.notificationUser = Boolean(object.notificationUser);
                    if (object.requestProcess != null) {
                        if (typeof object.requestProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactHeader.requestProcess: object expected");
                        message.requestProcess = $root.mtechnavi.api.company.Process.fromObject(object.requestProcess);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactHeader.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitContactHeader message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitContactHeader} message BusinessUnitContactHeader
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitContactHeader.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContactHeaderId = "";
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        object.displayName = "";
                        object.documentRecipientAddress = false;
                        object.notificationUser = false;
                        object.requestProcess = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitContactHeaderId != null && message.hasOwnProperty("businessUnitContactHeaderId"))
                        object.businessUnitContactHeaderId = message.businessUnitContactHeaderId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.documentRecipientAddress != null && message.hasOwnProperty("documentRecipientAddress"))
                        object.documentRecipientAddress = message.documentRecipientAddress;
                    if (message.notificationUser != null && message.hasOwnProperty("notificationUser"))
                        object.notificationUser = message.notificationUser;
                    if (message.requestProcess != null && message.hasOwnProperty("requestProcess"))
                        object.requestProcess = $root.mtechnavi.api.company.Process.toObject(message.requestProcess, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitContactHeader to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitContactHeader
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitContactHeader.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitContactHeader;
            })();

            company.BusinessUnitContact = (function() {

                /**
                 * Properties of a BusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitContact
                 * @property {string|null} [businessUnitContactId] BusinessUnitContact businessUnitContactId
                 * @property {string|null} [businessUnitManagementId] BusinessUnitContact businessUnitManagementId
                 * @property {string|null} [companyId] BusinessUnitContact companyId
                 * @property {string|null} [displayName] BusinessUnitContact displayName
                 * @property {mtechnavi.api.company.IBusinessUnitBranchProperties|null} [businessUnitBranchProperties] BusinessUnitContact businessUnitBranchProperties
                 * @property {string|null} [alias] BusinessUnitContact alias
                 * @property {Array.<mtechnavi.api.company.BusinessUnitContact.INotificationUser>|null} [notificationUsers] BusinessUnitContact notificationUsers
                 * @property {boolean|null} [mainContact] BusinessUnitContact mainContact
                 * @property {mtechnavi.api.company.IProcess|null} [sendProcess] BusinessUnitContact sendProcess
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitContact sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitContact createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitContact updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitContact deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitContact.
                 * @implements IBusinessUnitContact
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitContact=} [properties] Properties to set
                 */
                function BusinessUnitContact(properties) {
                    this.notificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitContact businessUnitContactId.
                 * @member {string} businessUnitContactId
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.businessUnitContactId = "";

                /**
                 * BusinessUnitContact businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitContact companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.companyId = "";

                /**
                 * BusinessUnitContact displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.displayName = "";

                /**
                 * BusinessUnitContact businessUnitBranchProperties.
                 * @member {mtechnavi.api.company.IBusinessUnitBranchProperties|null|undefined} businessUnitBranchProperties
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.businessUnitBranchProperties = null;

                /**
                 * BusinessUnitContact alias.
                 * @member {string} alias
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.alias = "";

                /**
                 * BusinessUnitContact notificationUsers.
                 * @member {Array.<mtechnavi.api.company.BusinessUnitContact.INotificationUser>} notificationUsers
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.notificationUsers = $util.emptyArray;

                /**
                 * BusinessUnitContact mainContact.
                 * @member {boolean} mainContact
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.mainContact = false;

                /**
                 * BusinessUnitContact sendProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} sendProcess
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.sendProcess = null;

                /**
                 * BusinessUnitContact sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.sharedProperties = null;

                /**
                 * BusinessUnitContact createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContact updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContact deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 */
                BusinessUnitContact.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitContact message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitContact.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        if (!$util.isString(message.businessUnitContactId))
                            return "businessUnitContactId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.businessUnitBranchProperties != null && message.hasOwnProperty("businessUnitBranchProperties")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitBranchProperties.verify(message.businessUnitBranchProperties);
                        if (error)
                            return "businessUnitBranchProperties." + error;
                    }
                    if (message.alias != null && message.hasOwnProperty("alias"))
                        if (!$util.isString(message.alias))
                            return "alias: string expected";
                    if (message.notificationUsers != null && message.hasOwnProperty("notificationUsers")) {
                        if (!Array.isArray(message.notificationUsers))
                            return "notificationUsers: array expected";
                        for (let i = 0; i < message.notificationUsers.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitContact.NotificationUser.verify(message.notificationUsers[i]);
                            if (error)
                                return "notificationUsers." + error;
                        }
                    }
                    if (message.mainContact != null && message.hasOwnProperty("mainContact"))
                        if (typeof message.mainContact !== "boolean")
                            return "mainContact: boolean expected";
                    if (message.sendProcess != null && message.hasOwnProperty("sendProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.sendProcess);
                        if (error)
                            return "sendProcess." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitContact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitContact} BusinessUnitContact
                 */
                BusinessUnitContact.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitContact)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitContact();
                    if (object.businessUnitContactId != null)
                        message.businessUnitContactId = String(object.businessUnitContactId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.businessUnitBranchProperties != null) {
                        if (typeof object.businessUnitBranchProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContact.businessUnitBranchProperties: object expected");
                        message.businessUnitBranchProperties = $root.mtechnavi.api.company.BusinessUnitBranchProperties.fromObject(object.businessUnitBranchProperties);
                    }
                    if (object.alias != null)
                        message.alias = String(object.alias);
                    if (object.notificationUsers) {
                        if (!Array.isArray(object.notificationUsers))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContact.notificationUsers: array expected");
                        message.notificationUsers = [];
                        for (let i = 0; i < object.notificationUsers.length; ++i) {
                            if (typeof object.notificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitContact.notificationUsers: object expected");
                            message.notificationUsers[i] = $root.mtechnavi.api.company.BusinessUnitContact.NotificationUser.fromObject(object.notificationUsers[i]);
                        }
                    }
                    if (object.mainContact != null)
                        message.mainContact = Boolean(object.mainContact);
                    if (object.sendProcess != null) {
                        if (typeof object.sendProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContact.sendProcess: object expected");
                        message.sendProcess = $root.mtechnavi.api.company.Process.fromObject(object.sendProcess);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContact.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitContact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitContact} message BusinessUnitContact
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitContact.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.notificationUsers = [];
                    if (options.defaults) {
                        object.businessUnitContactId = "";
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        object.displayName = "";
                        object.businessUnitBranchProperties = null;
                        object.alias = "";
                        object.mainContact = false;
                        object.sendProcess = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitContactId != null && message.hasOwnProperty("businessUnitContactId"))
                        object.businessUnitContactId = message.businessUnitContactId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.businessUnitBranchProperties != null && message.hasOwnProperty("businessUnitBranchProperties"))
                        object.businessUnitBranchProperties = $root.mtechnavi.api.company.BusinessUnitBranchProperties.toObject(message.businessUnitBranchProperties, options);
                    if (message.alias != null && message.hasOwnProperty("alias"))
                        object.alias = message.alias;
                    if (message.notificationUsers && message.notificationUsers.length) {
                        object.notificationUsers = [];
                        for (let j = 0; j < message.notificationUsers.length; ++j)
                            object.notificationUsers[j] = $root.mtechnavi.api.company.BusinessUnitContact.NotificationUser.toObject(message.notificationUsers[j], options);
                    }
                    if (message.mainContact != null && message.hasOwnProperty("mainContact"))
                        object.mainContact = message.mainContact;
                    if (message.sendProcess != null && message.hasOwnProperty("sendProcess"))
                        object.sendProcess = $root.mtechnavi.api.company.Process.toObject(message.sendProcess, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitContact to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitContact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitContact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                BusinessUnitContact.NotificationUser = (function() {

                    /**
                     * Properties of a NotificationUser.
                     * @memberof mtechnavi.api.company.BusinessUnitContact
                     * @interface INotificationUser
                     * @property {sharelib.INameOption|null} [category] NotificationUser category
                     * @property {sharelib.IUserReference|null} [user] NotificationUser user
                     */

                    /**
                     * Constructs a new NotificationUser.
                     * @memberof mtechnavi.api.company.BusinessUnitContact
                     * @classdesc Represents a NotificationUser.
                     * @implements INotificationUser
                     * @constructor
                     * @param {mtechnavi.api.company.BusinessUnitContact.INotificationUser=} [properties] Properties to set
                     */
                    function NotificationUser(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NotificationUser category.
                     * @member {sharelib.INameOption|null|undefined} category
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @instance
                     */
                    NotificationUser.prototype.category = null;

                    /**
                     * NotificationUser user.
                     * @member {sharelib.IUserReference|null|undefined} user
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @instance
                     */
                    NotificationUser.prototype.user = null;

                    /**
                     * Verifies a NotificationUser message.
                     * @function verify
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NotificationUser.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.category != null && message.hasOwnProperty("category")) {
                            let error = $root.sharelib.NameOption.verify(message.category);
                            if (error)
                                return "category." + error;
                        }
                        if (message.user != null && message.hasOwnProperty("user")) {
                            let error = $root.sharelib.UserReference.verify(message.user);
                            if (error)
                                return "user." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a NotificationUser message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.BusinessUnitContact.NotificationUser} NotificationUser
                     */
                    NotificationUser.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.BusinessUnitContact.NotificationUser)
                            return object;
                        let message = new $root.mtechnavi.api.company.BusinessUnitContact.NotificationUser();
                        if (object.category != null) {
                            if (typeof object.category !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitContact.NotificationUser.category: object expected");
                            message.category = $root.sharelib.NameOption.fromObject(object.category);
                        }
                        if (object.user != null) {
                            if (typeof object.user !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitContact.NotificationUser.user: object expected");
                            message.user = $root.sharelib.UserReference.fromObject(object.user);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a NotificationUser message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @static
                     * @param {mtechnavi.api.company.BusinessUnitContact.NotificationUser} message NotificationUser
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NotificationUser.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.category = null;
                            object.user = null;
                        }
                        if (message.category != null && message.hasOwnProperty("category"))
                            object.category = $root.sharelib.NameOption.toObject(message.category, options);
                        if (message.user != null && message.hasOwnProperty("user"))
                            object.user = $root.sharelib.UserReference.toObject(message.user, options);
                        return object;
                    };

                    /**
                     * Converts this NotificationUser to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.BusinessUnitContact.NotificationUser
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NotificationUser.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NotificationUser;
                })();

                return BusinessUnitContact;
            })();

            company.BusinessUnitContactAttribute = (function() {

                /**
                 * Properties of a BusinessUnitContactAttribute.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitContactAttribute
                 * @property {string|null} [businessUnitContactAttributeId] BusinessUnitContactAttribute businessUnitContactAttributeId
                 * @property {string|null} [businessUnitManagementId] BusinessUnitContactAttribute businessUnitManagementId
                 * @property {string|null} [urn] BusinessUnitContactAttribute urn
                 * @property {string|null} [typeName] BusinessUnitContactAttribute typeName
                 * @property {string|null} [recordId] BusinessUnitContactAttribute recordId
                 * @property {string|null} [code] BusinessUnitContactAttribute code
                 * @property {string|null} [displayName] BusinessUnitContactAttribute displayName
                 * @property {string|null} [remarks] BusinessUnitContactAttribute remarks
                 * @property {Long|null} [createdAt] BusinessUnitContactAttribute createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitContactAttribute updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitContactAttribute deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitContactAttribute.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitContactAttribute.
                 * @implements IBusinessUnitContactAttribute
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitContactAttribute=} [properties] Properties to set
                 */
                function BusinessUnitContactAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitContactAttribute businessUnitContactAttributeId.
                 * @member {string} businessUnitContactAttributeId
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.businessUnitContactAttributeId = "";

                /**
                 * BusinessUnitContactAttribute businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitContactAttribute urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.urn = "";

                /**
                 * BusinessUnitContactAttribute typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.typeName = "";

                /**
                 * BusinessUnitContactAttribute recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.recordId = "";

                /**
                 * BusinessUnitContactAttribute code.
                 * @member {string} code
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.code = "";

                /**
                 * BusinessUnitContactAttribute displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.displayName = "";

                /**
                 * BusinessUnitContactAttribute remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.remarks = "";

                /**
                 * BusinessUnitContactAttribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContactAttribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitContactAttribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 */
                BusinessUnitContactAttribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitContactAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitContactAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContactAttributeId != null && message.hasOwnProperty("businessUnitContactAttributeId"))
                        if (!$util.isString(message.businessUnitContactAttributeId))
                            return "businessUnitContactAttributeId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitContactAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitContactAttribute} BusinessUnitContactAttribute
                 */
                BusinessUnitContactAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitContactAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitContactAttribute();
                    if (object.businessUnitContactAttributeId != null)
                        message.businessUnitContactAttributeId = String(object.businessUnitContactAttributeId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitContactAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitContactAttribute} message BusinessUnitContactAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitContactAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContactAttributeId = "";
                        object.businessUnitManagementId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.code = "";
                        object.displayName = "";
                        object.remarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitContactAttributeId != null && message.hasOwnProperty("businessUnitContactAttributeId"))
                        object.businessUnitContactAttributeId = message.businessUnitContactAttributeId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitContactAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitContactAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitContactAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitContactAttribute;
            })();

            company.BusinessUnitDocument = (function() {

                /**
                 * Properties of a BusinessUnitDocument.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitDocument
                 * @property {string|null} [businessUnitDocumentId] BusinessUnitDocument businessUnitDocumentId
                 * @property {string|null} [businessUnitManagementId] BusinessUnitDocument businessUnitManagementId
                 * @property {sharelib.INameOption|null} [category] BusinessUnitDocument category
                 * @property {sharelib.IAttachment|null} [attachment] BusinessUnitDocument attachment
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitDocument updatedProperties
                 * @property {Long|null} [createdAt] BusinessUnitDocument createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitDocument updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitDocument deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitDocument.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitDocument.
                 * @implements IBusinessUnitDocument
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitDocument=} [properties] Properties to set
                 */
                function BusinessUnitDocument(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitDocument businessUnitDocumentId.
                 * @member {string} businessUnitDocumentId
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.businessUnitDocumentId = "";

                /**
                 * BusinessUnitDocument businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitDocument category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.category = null;

                /**
                 * BusinessUnitDocument attachment.
                 * @member {sharelib.IAttachment|null|undefined} attachment
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.attachment = null;

                /**
                 * BusinessUnitDocument updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.updatedProperties = null;

                /**
                 * BusinessUnitDocument createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitDocument updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitDocument deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 */
                BusinessUnitDocument.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitDocument message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitDocument.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitDocumentId != null && message.hasOwnProperty("businessUnitDocumentId"))
                        if (!$util.isString(message.businessUnitDocumentId))
                            return "businessUnitDocumentId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.sharelib.Attachment.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitDocument message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitDocument} BusinessUnitDocument
                 */
                BusinessUnitDocument.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitDocument)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitDocument();
                    if (object.businessUnitDocumentId != null)
                        message.businessUnitDocumentId = String(object.businessUnitDocumentId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitDocument.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitDocument.attachment: object expected");
                        message.attachment = $root.sharelib.Attachment.fromObject(object.attachment);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitDocument.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitDocument message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitDocument} message BusinessUnitDocument
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitDocument.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitDocumentId = "";
                        object.businessUnitManagementId = "";
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.category = null;
                        object.attachment = null;
                    }
                    if (message.businessUnitDocumentId != null && message.hasOwnProperty("businessUnitDocumentId"))
                        object.businessUnitDocumentId = message.businessUnitDocumentId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.sharelib.Attachment.toObject(message.attachment, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitDocument to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitDocument
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitDocument.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitDocument;
            })();

            company.BusinessUnitActiveControl = (function() {

                /**
                 * Properties of a BusinessUnitActiveControl.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitActiveControl
                 * @property {string|null} [businessUnitActiveControlId] BusinessUnitActiveControl businessUnitActiveControlId
                 * @property {string|null} [companyId] BusinessUnitActiveControl companyId
                 * @property {boolean|null} [active] BusinessUnitActiveControl active
                 * @property {mtechnavi.api.company.IProcess|null} [process] BusinessUnitActiveControl process
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitActiveControl sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitActiveControl createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitActiveControl updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitActiveControl deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitActiveControl.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitActiveControl.
                 * @implements IBusinessUnitActiveControl
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitActiveControl=} [properties] Properties to set
                 */
                function BusinessUnitActiveControl(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitActiveControl businessUnitActiveControlId.
                 * @member {string} businessUnitActiveControlId
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.businessUnitActiveControlId = "";

                /**
                 * BusinessUnitActiveControl companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.companyId = "";

                /**
                 * BusinessUnitActiveControl active.
                 * @member {boolean} active
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.active = false;

                /**
                 * BusinessUnitActiveControl process.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} process
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.process = null;

                /**
                 * BusinessUnitActiveControl sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.sharedProperties = null;

                /**
                 * BusinessUnitActiveControl createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitActiveControl updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitActiveControl deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 */
                BusinessUnitActiveControl.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitActiveControl message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitActiveControl.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitActiveControlId != null && message.hasOwnProperty("businessUnitActiveControlId"))
                        if (!$util.isString(message.businessUnitActiveControlId))
                            return "businessUnitActiveControlId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.active != null && message.hasOwnProperty("active"))
                        if (typeof message.active !== "boolean")
                            return "active: boolean expected";
                    if (message.process != null && message.hasOwnProperty("process")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.process);
                        if (error)
                            return "process." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitActiveControl message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitActiveControl} BusinessUnitActiveControl
                 */
                BusinessUnitActiveControl.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitActiveControl)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitActiveControl();
                    if (object.businessUnitActiveControlId != null)
                        message.businessUnitActiveControlId = String(object.businessUnitActiveControlId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.active != null)
                        message.active = Boolean(object.active);
                    if (object.process != null) {
                        if (typeof object.process !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitActiveControl.process: object expected");
                        message.process = $root.mtechnavi.api.company.Process.fromObject(object.process);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitActiveControl.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitActiveControl message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitActiveControl} message BusinessUnitActiveControl
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitActiveControl.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitActiveControlId = "";
                        object.companyId = "";
                        object.active = false;
                        object.process = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitActiveControlId != null && message.hasOwnProperty("businessUnitActiveControlId"))
                        object.businessUnitActiveControlId = message.businessUnitActiveControlId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.active != null && message.hasOwnProperty("active"))
                        object.active = message.active;
                    if (message.process != null && message.hasOwnProperty("process"))
                        object.process = $root.mtechnavi.api.company.Process.toObject(message.process, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitActiveControl to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitActiveControl
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitActiveControl.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitActiveControl;
            })();

            company.BusinessUnitSnapshot = (function() {

                /**
                 * Properties of a BusinessUnitSnapshot.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitSnapshot
                 * @property {string|null} [businessUnitSnapshotId] BusinessUnitSnapshot businessUnitSnapshotId
                 * @property {string|null} [urn] BusinessUnitSnapshot urn
                 * @property {string|null} [typeName] BusinessUnitSnapshot typeName
                 * @property {string|null} [recordId] BusinessUnitSnapshot recordId
                 * @property {string|null} [data] BusinessUnitSnapshot data
                 * @property {Long|null} [createdAt] BusinessUnitSnapshot createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitSnapshot updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitSnapshot deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitSnapshot.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitSnapshot.
                 * @implements IBusinessUnitSnapshot
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitSnapshot=} [properties] Properties to set
                 */
                function BusinessUnitSnapshot(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitSnapshot businessUnitSnapshotId.
                 * @member {string} businessUnitSnapshotId
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.businessUnitSnapshotId = "";

                /**
                 * BusinessUnitSnapshot urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.urn = "";

                /**
                 * BusinessUnitSnapshot typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.typeName = "";

                /**
                 * BusinessUnitSnapshot recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.recordId = "";

                /**
                 * BusinessUnitSnapshot data.
                 * @member {string} data
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.data = "";

                /**
                 * BusinessUnitSnapshot createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitSnapshot updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitSnapshot deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 */
                BusinessUnitSnapshot.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitSnapshot message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitSnapshot.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSnapshotId != null && message.hasOwnProperty("businessUnitSnapshotId"))
                        if (!$util.isString(message.businessUnitSnapshotId))
                            return "businessUnitSnapshotId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.data != null && message.hasOwnProperty("data"))
                        if (!$util.isString(message.data))
                            return "data: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitSnapshot message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitSnapshot} BusinessUnitSnapshot
                 */
                BusinessUnitSnapshot.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitSnapshot)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitSnapshot();
                    if (object.businessUnitSnapshotId != null)
                        message.businessUnitSnapshotId = String(object.businessUnitSnapshotId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.data != null)
                        message.data = String(object.data);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitSnapshot message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitSnapshot} message BusinessUnitSnapshot
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitSnapshot.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitSnapshotId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.data = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitSnapshotId != null && message.hasOwnProperty("businessUnitSnapshotId"))
                        object.businessUnitSnapshotId = message.businessUnitSnapshotId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = message.data;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitSnapshot to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitSnapshot
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitSnapshot.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitSnapshot;
            })();

            company.BusinessUnitChangeNotification = (function() {

                /**
                 * Properties of a BusinessUnitChangeNotification.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitChangeNotification
                 * @property {string|null} [businessUnitChangeNotificationId] BusinessUnitChangeNotification businessUnitChangeNotificationId
                 * @property {string|null} [baseUrn] BusinessUnitChangeNotification baseUrn
                 * @property {string|null} [urn] BusinessUnitChangeNotification urn
                 * @property {string|null} [typeName] BusinessUnitChangeNotification typeName
                 * @property {string|null} [recordId] BusinessUnitChangeNotification recordId
                 * @property {string|null} [targetKey] BusinessUnitChangeNotification targetKey
                 * @property {string|null} [targetFieldPath] BusinessUnitChangeNotification targetFieldPath
                 * @property {string|null} [oldValue] BusinessUnitChangeNotification oldValue
                 * @property {string|null} [newValue] BusinessUnitChangeNotification newValue
                 * @property {Array.<string>|null} [remarksNames] BusinessUnitChangeNotification remarksNames
                 * @property {Long|null} [notifiedAt] BusinessUnitChangeNotification notifiedAt
                 * @property {Long|null} [createdAt] BusinessUnitChangeNotification createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitChangeNotification updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitChangeNotification deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitChangeNotification.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitChangeNotification.
                 * @implements IBusinessUnitChangeNotification
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitChangeNotification=} [properties] Properties to set
                 */
                function BusinessUnitChangeNotification(properties) {
                    this.remarksNames = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitChangeNotification businessUnitChangeNotificationId.
                 * @member {string} businessUnitChangeNotificationId
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.businessUnitChangeNotificationId = "";

                /**
                 * BusinessUnitChangeNotification baseUrn.
                 * @member {string} baseUrn
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.baseUrn = "";

                /**
                 * BusinessUnitChangeNotification urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.urn = "";

                /**
                 * BusinessUnitChangeNotification typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.typeName = "";

                /**
                 * BusinessUnitChangeNotification recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.recordId = "";

                /**
                 * BusinessUnitChangeNotification targetKey.
                 * @member {string} targetKey
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.targetKey = "";

                /**
                 * BusinessUnitChangeNotification targetFieldPath.
                 * @member {string} targetFieldPath
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.targetFieldPath = "";

                /**
                 * BusinessUnitChangeNotification oldValue.
                 * @member {string} oldValue
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.oldValue = "";

                /**
                 * BusinessUnitChangeNotification newValue.
                 * @member {string} newValue
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.newValue = "";

                /**
                 * BusinessUnitChangeNotification remarksNames.
                 * @member {Array.<string>} remarksNames
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.remarksNames = $util.emptyArray;

                /**
                 * BusinessUnitChangeNotification notifiedAt.
                 * @member {Long} notifiedAt
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.notifiedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitChangeNotification createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitChangeNotification updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitChangeNotification deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 */
                BusinessUnitChangeNotification.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitChangeNotification message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitChangeNotification.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitChangeNotificationId != null && message.hasOwnProperty("businessUnitChangeNotificationId"))
                        if (!$util.isString(message.businessUnitChangeNotificationId))
                            return "businessUnitChangeNotificationId: string expected";
                    if (message.baseUrn != null && message.hasOwnProperty("baseUrn"))
                        if (!$util.isString(message.baseUrn))
                            return "baseUrn: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.targetKey != null && message.hasOwnProperty("targetKey"))
                        if (!$util.isString(message.targetKey))
                            return "targetKey: string expected";
                    if (message.targetFieldPath != null && message.hasOwnProperty("targetFieldPath"))
                        if (!$util.isString(message.targetFieldPath))
                            return "targetFieldPath: string expected";
                    if (message.oldValue != null && message.hasOwnProperty("oldValue"))
                        if (!$util.isString(message.oldValue))
                            return "oldValue: string expected";
                    if (message.newValue != null && message.hasOwnProperty("newValue"))
                        if (!$util.isString(message.newValue))
                            return "newValue: string expected";
                    if (message.remarksNames != null && message.hasOwnProperty("remarksNames")) {
                        if (!Array.isArray(message.remarksNames))
                            return "remarksNames: array expected";
                        for (let i = 0; i < message.remarksNames.length; ++i)
                            if (!$util.isString(message.remarksNames[i]))
                                return "remarksNames: string[] expected";
                    }
                    if (message.notifiedAt != null && message.hasOwnProperty("notifiedAt"))
                        if (!$util.isInteger(message.notifiedAt) && !(message.notifiedAt && $util.isInteger(message.notifiedAt.low) && $util.isInteger(message.notifiedAt.high)))
                            return "notifiedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitChangeNotification message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitChangeNotification} BusinessUnitChangeNotification
                 */
                BusinessUnitChangeNotification.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitChangeNotification)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitChangeNotification();
                    if (object.businessUnitChangeNotificationId != null)
                        message.businessUnitChangeNotificationId = String(object.businessUnitChangeNotificationId);
                    if (object.baseUrn != null)
                        message.baseUrn = String(object.baseUrn);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.targetKey != null)
                        message.targetKey = String(object.targetKey);
                    if (object.targetFieldPath != null)
                        message.targetFieldPath = String(object.targetFieldPath);
                    if (object.oldValue != null)
                        message.oldValue = String(object.oldValue);
                    if (object.newValue != null)
                        message.newValue = String(object.newValue);
                    if (object.remarksNames) {
                        if (!Array.isArray(object.remarksNames))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitChangeNotification.remarksNames: array expected");
                        message.remarksNames = [];
                        for (let i = 0; i < object.remarksNames.length; ++i)
                            message.remarksNames[i] = String(object.remarksNames[i]);
                    }
                    if (object.notifiedAt != null)
                        if ($util.Long)
                            (message.notifiedAt = $util.Long.fromValue(object.notifiedAt)).unsigned = false;
                        else if (typeof object.notifiedAt === "string")
                            message.notifiedAt = parseInt(object.notifiedAt, 10);
                        else if (typeof object.notifiedAt === "number")
                            message.notifiedAt = object.notifiedAt;
                        else if (typeof object.notifiedAt === "object")
                            message.notifiedAt = new $util.LongBits(object.notifiedAt.low >>> 0, object.notifiedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitChangeNotification message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitChangeNotification} message BusinessUnitChangeNotification
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitChangeNotification.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.remarksNames = [];
                    if (options.defaults) {
                        object.businessUnitChangeNotificationId = "";
                        object.baseUrn = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.targetKey = "";
                        object.targetFieldPath = "";
                        object.oldValue = "";
                        object.newValue = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.notifiedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.notifiedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitChangeNotificationId != null && message.hasOwnProperty("businessUnitChangeNotificationId"))
                        object.businessUnitChangeNotificationId = message.businessUnitChangeNotificationId;
                    if (message.baseUrn != null && message.hasOwnProperty("baseUrn"))
                        object.baseUrn = message.baseUrn;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.targetKey != null && message.hasOwnProperty("targetKey"))
                        object.targetKey = message.targetKey;
                    if (message.targetFieldPath != null && message.hasOwnProperty("targetFieldPath"))
                        object.targetFieldPath = message.targetFieldPath;
                    if (message.oldValue != null && message.hasOwnProperty("oldValue"))
                        object.oldValue = message.oldValue;
                    if (message.newValue != null && message.hasOwnProperty("newValue"))
                        object.newValue = message.newValue;
                    if (message.remarksNames && message.remarksNames.length) {
                        object.remarksNames = [];
                        for (let j = 0; j < message.remarksNames.length; ++j)
                            object.remarksNames[j] = message.remarksNames[j];
                    }
                    if (message.notifiedAt != null && message.hasOwnProperty("notifiedAt"))
                        if (typeof message.notifiedAt === "number")
                            object.notifiedAt = options.longs === String ? String(message.notifiedAt) : message.notifiedAt;
                        else
                            object.notifiedAt = options.longs === String ? $util.Long.prototype.toString.call(message.notifiedAt) : options.longs === Number ? new $util.LongBits(message.notifiedAt.low >>> 0, message.notifiedAt.high >>> 0).toNumber() : message.notifiedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitChangeNotification to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitChangeNotification
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitChangeNotification.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitChangeNotification;
            })();

            company.BusinessUnitPublicDocument = (function() {

                /**
                 * Properties of a BusinessUnitPublicDocument.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitPublicDocument
                 * @property {string|null} [businessUnitPublicDocumentId] BusinessUnitPublicDocument businessUnitPublicDocumentId
                 * @property {string|null} [companyId] BusinessUnitPublicDocument companyId
                 * @property {sharelib.INameOption|null} [status] BusinessUnitPublicDocument status
                 * @property {string|null} [statusSystemName] BusinessUnitPublicDocument statusSystemName
                 * @property {string|null} [displayName] BusinessUnitPublicDocument displayName
                 * @property {string|null} [contents] BusinessUnitPublicDocument contents
                 * @property {Array.<sharelib.IAttachment>|null} [attachments] BusinessUnitPublicDocument attachments
                 * @property {sharelib.IDatetime|null} [releaseStartDt] BusinessUnitPublicDocument releaseStartDt
                 * @property {sharelib.IDatetime|null} [releaseEndDt] BusinessUnitPublicDocument releaseEndDt
                 * @property {Long|null} [latestUpdatedAt] BusinessUnitPublicDocument latestUpdatedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitPublicDocument updatedProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitPublicDocument sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitPublicDocument createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitPublicDocument updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitPublicDocument deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitPublicDocument.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitPublicDocument.
                 * @implements IBusinessUnitPublicDocument
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitPublicDocument=} [properties] Properties to set
                 */
                function BusinessUnitPublicDocument(properties) {
                    this.attachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitPublicDocument businessUnitPublicDocumentId.
                 * @member {string} businessUnitPublicDocumentId
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.businessUnitPublicDocumentId = "";

                /**
                 * BusinessUnitPublicDocument companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.companyId = "";

                /**
                 * BusinessUnitPublicDocument status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.status = null;

                /**
                 * BusinessUnitPublicDocument statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.statusSystemName = "";

                /**
                 * BusinessUnitPublicDocument displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.displayName = "";

                /**
                 * BusinessUnitPublicDocument contents.
                 * @member {string} contents
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.contents = "";

                /**
                 * BusinessUnitPublicDocument attachments.
                 * @member {Array.<sharelib.IAttachment>} attachments
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.attachments = $util.emptyArray;

                /**
                 * BusinessUnitPublicDocument releaseStartDt.
                 * @member {sharelib.IDatetime|null|undefined} releaseStartDt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.releaseStartDt = null;

                /**
                 * BusinessUnitPublicDocument releaseEndDt.
                 * @member {sharelib.IDatetime|null|undefined} releaseEndDt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.releaseEndDt = null;

                /**
                 * BusinessUnitPublicDocument latestUpdatedAt.
                 * @member {Long} latestUpdatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.latestUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitPublicDocument updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.updatedProperties = null;

                /**
                 * BusinessUnitPublicDocument sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.sharedProperties = null;

                /**
                 * BusinessUnitPublicDocument createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitPublicDocument updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitPublicDocument deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 */
                BusinessUnitPublicDocument.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitPublicDocument message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitPublicDocument.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitPublicDocumentId != null && message.hasOwnProperty("businessUnitPublicDocumentId"))
                        if (!$util.isString(message.businessUnitPublicDocumentId))
                            return "businessUnitPublicDocumentId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        if (!$util.isString(message.contents))
                            return "contents: string expected";
                    if (message.attachments != null && message.hasOwnProperty("attachments")) {
                        if (!Array.isArray(message.attachments))
                            return "attachments: array expected";
                        for (let i = 0; i < message.attachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.attachments[i]);
                            if (error)
                                return "attachments." + error;
                        }
                    }
                    if (message.releaseStartDt != null && message.hasOwnProperty("releaseStartDt")) {
                        let error = $root.sharelib.Datetime.verify(message.releaseStartDt);
                        if (error)
                            return "releaseStartDt." + error;
                    }
                    if (message.releaseEndDt != null && message.hasOwnProperty("releaseEndDt")) {
                        let error = $root.sharelib.Datetime.verify(message.releaseEndDt);
                        if (error)
                            return "releaseEndDt." + error;
                    }
                    if (message.latestUpdatedAt != null && message.hasOwnProperty("latestUpdatedAt"))
                        if (!$util.isInteger(message.latestUpdatedAt) && !(message.latestUpdatedAt && $util.isInteger(message.latestUpdatedAt.low) && $util.isInteger(message.latestUpdatedAt.high)))
                            return "latestUpdatedAt: integer|Long expected";
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitPublicDocument message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitPublicDocument} BusinessUnitPublicDocument
                 */
                BusinessUnitPublicDocument.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitPublicDocument)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitPublicDocument();
                    if (object.businessUnitPublicDocumentId != null)
                        message.businessUnitPublicDocumentId = String(object.businessUnitPublicDocumentId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.contents != null)
                        message.contents = String(object.contents);
                    if (object.attachments) {
                        if (!Array.isArray(object.attachments))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.attachments: array expected");
                        message.attachments = [];
                        for (let i = 0; i < object.attachments.length; ++i) {
                            if (typeof object.attachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.attachments: object expected");
                            message.attachments[i] = $root.sharelib.Attachment.fromObject(object.attachments[i]);
                        }
                    }
                    if (object.releaseStartDt != null) {
                        if (typeof object.releaseStartDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.releaseStartDt: object expected");
                        message.releaseStartDt = $root.sharelib.Datetime.fromObject(object.releaseStartDt);
                    }
                    if (object.releaseEndDt != null) {
                        if (typeof object.releaseEndDt !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.releaseEndDt: object expected");
                        message.releaseEndDt = $root.sharelib.Datetime.fromObject(object.releaseEndDt);
                    }
                    if (object.latestUpdatedAt != null)
                        if ($util.Long)
                            (message.latestUpdatedAt = $util.Long.fromValue(object.latestUpdatedAt)).unsigned = false;
                        else if (typeof object.latestUpdatedAt === "string")
                            message.latestUpdatedAt = parseInt(object.latestUpdatedAt, 10);
                        else if (typeof object.latestUpdatedAt === "number")
                            message.latestUpdatedAt = object.latestUpdatedAt;
                        else if (typeof object.latestUpdatedAt === "object")
                            message.latestUpdatedAt = new $util.LongBits(object.latestUpdatedAt.low >>> 0, object.latestUpdatedAt.high >>> 0).toNumber();
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitPublicDocument.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitPublicDocument message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitPublicDocument} message BusinessUnitPublicDocument
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitPublicDocument.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attachments = [];
                    if (options.defaults) {
                        object.businessUnitPublicDocumentId = "";
                        object.status = null;
                        object.statusSystemName = "";
                        object.displayName = "";
                        object.contents = "";
                        object.releaseStartDt = null;
                        object.releaseEndDt = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.companyId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.latestUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.latestUpdatedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                    }
                    if (message.businessUnitPublicDocumentId != null && message.hasOwnProperty("businessUnitPublicDocumentId"))
                        object.businessUnitPublicDocumentId = message.businessUnitPublicDocumentId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        object.contents = message.contents;
                    if (message.attachments && message.attachments.length) {
                        object.attachments = [];
                        for (let j = 0; j < message.attachments.length; ++j)
                            object.attachments[j] = $root.sharelib.Attachment.toObject(message.attachments[j], options);
                    }
                    if (message.releaseStartDt != null && message.hasOwnProperty("releaseStartDt"))
                        object.releaseStartDt = $root.sharelib.Datetime.toObject(message.releaseStartDt, options);
                    if (message.releaseEndDt != null && message.hasOwnProperty("releaseEndDt"))
                        object.releaseEndDt = $root.sharelib.Datetime.toObject(message.releaseEndDt, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.latestUpdatedAt != null && message.hasOwnProperty("latestUpdatedAt"))
                        if (typeof message.latestUpdatedAt === "number")
                            object.latestUpdatedAt = options.longs === String ? String(message.latestUpdatedAt) : message.latestUpdatedAt;
                        else
                            object.latestUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.latestUpdatedAt) : options.longs === Number ? new $util.LongBits(message.latestUpdatedAt.low >>> 0, message.latestUpdatedAt.high >>> 0).toNumber() : message.latestUpdatedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitPublicDocument to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitPublicDocument
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitPublicDocument.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitPublicDocument;
            })();

            company.History = (function() {

                /**
                 * Properties of a History.
                 * @memberof mtechnavi.api.company
                 * @interface IHistory
                 * @property {string|null} [historyId] History historyId
                 * @property {string|null} [urn] History urn
                 * @property {string|null} [typeName] History typeName
                 * @property {string|null} [recordId] History recordId
                 * @property {string|null} [historyCategoryName] History historyCategoryName
                 * @property {string|null} [contentName] History contentName
                 * @property {Array.<string>|null} [contentParamaters] History contentParamaters
                 * @property {Long|null} [occurredAt] History occurredAt
                 * @property {Long|null} [level] History level
                 * @property {Long|null} [createdAt] History createdAt
                 * @property {Long|null} [updatedAt] History updatedAt
                 * @property {Long|null} [deletedAt] History deletedAt
                 */

                /**
                 * Constructs a new History.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a History.
                 * @implements IHistory
                 * @constructor
                 * @param {mtechnavi.api.company.IHistory=} [properties] Properties to set
                 */
                function History(properties) {
                    this.contentParamaters = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * History historyId.
                 * @member {string} historyId
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.historyId = "";

                /**
                 * History urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.urn = "";

                /**
                 * History typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.typeName = "";

                /**
                 * History recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.recordId = "";

                /**
                 * History historyCategoryName.
                 * @member {string} historyCategoryName
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.historyCategoryName = "";

                /**
                 * History contentName.
                 * @member {string} contentName
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.contentName = "";

                /**
                 * History contentParamaters.
                 * @member {Array.<string>} contentParamaters
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.contentParamaters = $util.emptyArray;

                /**
                 * History occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 */
                History.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a History message.
                 * @function verify
                 * @memberof mtechnavi.api.company.History
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                History.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        if (!$util.isString(message.historyId))
                            return "historyId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        if (!$util.isString(message.historyCategoryName))
                            return "historyCategoryName: string expected";
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        if (!$util.isString(message.contentName))
                            return "contentName: string expected";
                    if (message.contentParamaters != null && message.hasOwnProperty("contentParamaters")) {
                        if (!Array.isArray(message.contentParamaters))
                            return "contentParamaters: array expected";
                        for (let i = 0; i < message.contentParamaters.length; ++i)
                            if (!$util.isString(message.contentParamaters[i]))
                                return "contentParamaters: string[] expected";
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a History message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.History
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.History} History
                 */
                History.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.History)
                        return object;
                    let message = new $root.mtechnavi.api.company.History();
                    if (object.historyId != null)
                        message.historyId = String(object.historyId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.historyCategoryName != null)
                        message.historyCategoryName = String(object.historyCategoryName);
                    if (object.contentName != null)
                        message.contentName = String(object.contentName);
                    if (object.contentParamaters) {
                        if (!Array.isArray(object.contentParamaters))
                            throw TypeError(".mtechnavi.api.company.History.contentParamaters: array expected");
                        message.contentParamaters = [];
                        for (let i = 0; i < object.contentParamaters.length; ++i)
                            message.contentParamaters[i] = String(object.contentParamaters[i]);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a History message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.History
                 * @static
                 * @param {mtechnavi.api.company.History} message History
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                History.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.contentParamaters = [];
                    if (options.defaults) {
                        object.historyId = "";
                        object.urn = "";
                        object.historyCategoryName = "";
                        object.contentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.typeName = "";
                        object.recordId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                    }
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        object.historyId = message.historyId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        object.historyCategoryName = message.historyCategoryName;
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        object.contentName = message.contentName;
                    if (message.contentParamaters && message.contentParamaters.length) {
                        object.contentParamaters = [];
                        for (let j = 0; j < message.contentParamaters.length; ++j)
                            object.contentParamaters[j] = message.contentParamaters[j];
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    return object;
                };

                /**
                 * Converts this History to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.History
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                History.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return History;
            })();

            company.BusinessUnitRankRequest = (function() {

                /**
                 * Properties of a BusinessUnitRankRequest.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitRankRequest
                 * @property {string|null} [businessUnitRankRequestId] BusinessUnitRankRequest businessUnitRankRequestId
                 * @property {string|null} [businessUnitManagementId] BusinessUnitRankRequest businessUnitManagementId
                 * @property {string|null} [companyId] BusinessUnitRankRequest companyId
                 * @property {string|null} [displayName] BusinessUnitRankRequest displayName
                 * @property {sharelib.INameOption|null} [rank] BusinessUnitRankRequest rank
                 * @property {mtechnavi.api.company.IProcess|null} [requestProcess] BusinessUnitRankRequest requestProcess
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitRankRequest updatedProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitRankRequest sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitRankRequest createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitRankRequest updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitRankRequest deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitRankRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitRankRequest.
                 * @implements IBusinessUnitRankRequest
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitRankRequest=} [properties] Properties to set
                 */
                function BusinessUnitRankRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitRankRequest businessUnitRankRequestId.
                 * @member {string} businessUnitRankRequestId
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.businessUnitRankRequestId = "";

                /**
                 * BusinessUnitRankRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitRankRequest companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.companyId = "";

                /**
                 * BusinessUnitRankRequest displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.displayName = "";

                /**
                 * BusinessUnitRankRequest rank.
                 * @member {sharelib.INameOption|null|undefined} rank
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.rank = null;

                /**
                 * BusinessUnitRankRequest requestProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} requestProcess
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.requestProcess = null;

                /**
                 * BusinessUnitRankRequest updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.updatedProperties = null;

                /**
                 * BusinessUnitRankRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.sharedProperties = null;

                /**
                 * BusinessUnitRankRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRankRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRankRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 */
                BusinessUnitRankRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitRankRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitRankRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankRequestId != null && message.hasOwnProperty("businessUnitRankRequestId"))
                        if (!$util.isString(message.businessUnitRankRequestId))
                            return "businessUnitRankRequestId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.rank != null && message.hasOwnProperty("rank")) {
                        let error = $root.sharelib.NameOption.verify(message.rank);
                        if (error)
                            return "rank." + error;
                    }
                    if (message.requestProcess != null && message.hasOwnProperty("requestProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.requestProcess);
                        if (error)
                            return "requestProcess." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitRankRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitRankRequest} BusinessUnitRankRequest
                 */
                BusinessUnitRankRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitRankRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitRankRequest();
                    if (object.businessUnitRankRequestId != null)
                        message.businessUnitRankRequestId = String(object.businessUnitRankRequestId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.rank != null) {
                        if (typeof object.rank !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankRequest.rank: object expected");
                        message.rank = $root.sharelib.NameOption.fromObject(object.rank);
                    }
                    if (object.requestProcess != null) {
                        if (typeof object.requestProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankRequest.requestProcess: object expected");
                        message.requestProcess = $root.mtechnavi.api.company.Process.fromObject(object.requestProcess);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankRequest.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitRankRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitRankRequest} message BusinessUnitRankRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitRankRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRankRequestId = "";
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        object.displayName = "";
                        object.rank = null;
                        object.requestProcess = null;
                        object.updatedProperties = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitRankRequestId != null && message.hasOwnProperty("businessUnitRankRequestId"))
                        object.businessUnitRankRequestId = message.businessUnitRankRequestId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.rank != null && message.hasOwnProperty("rank"))
                        object.rank = $root.sharelib.NameOption.toObject(message.rank, options);
                    if (message.requestProcess != null && message.hasOwnProperty("requestProcess"))
                        object.requestProcess = $root.mtechnavi.api.company.Process.toObject(message.requestProcess, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BusinessUnitRankRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitRankRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitRankRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitRankRequest;
            })();

            company.BusinessUnitRankReception = (function() {

                /**
                 * Properties of a BusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitRankReception
                 * @property {string|null} [businessUnitRankReceptionId] BusinessUnitRankReception businessUnitRankReceptionId
                 * @property {string|null} [businessUnitRankRequestId] BusinessUnitRankReception businessUnitRankRequestId
                 * @property {string|null} [applyKind] BusinessUnitRankReception applyKind
                 * @property {mtechnavi.api.company.IBusinessUnitBranchProperties|null} [businessUnitBranchProperties] BusinessUnitRankReception businessUnitBranchProperties
                 * @property {sharelib.INameOption|null} [category] BusinessUnitRankReception category
                 * @property {mtechnavi.api.company.BusinessUnitRankReception.IQualityManager|null} [qualityManager] BusinessUnitRankReception qualityManager
                 * @property {string|null} [comment] BusinessUnitRankReception comment
                 * @property {mtechnavi.api.company.IProcess|null} [sendProcess] BusinessUnitRankReception sendProcess
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BusinessUnitRankReception updatedProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BusinessUnitRankReception sharedProperties
                 * @property {Long|null} [createdAt] BusinessUnitRankReception createdAt
                 * @property {Long|null} [updatedAt] BusinessUnitRankReception updatedAt
                 * @property {Long|null} [deletedAt] BusinessUnitRankReception deletedAt
                 */

                /**
                 * Constructs a new BusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitRankReception.
                 * @implements IBusinessUnitRankReception
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitRankReception=} [properties] Properties to set
                 */
                function BusinessUnitRankReception(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitRankReception businessUnitRankReceptionId.
                 * @member {string} businessUnitRankReceptionId
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.businessUnitRankReceptionId = "";

                /**
                 * BusinessUnitRankReception businessUnitRankRequestId.
                 * @member {string} businessUnitRankRequestId
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.businessUnitRankRequestId = "";

                /**
                 * BusinessUnitRankReception applyKind.
                 * @member {string} applyKind
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.applyKind = "";

                /**
                 * BusinessUnitRankReception businessUnitBranchProperties.
                 * @member {mtechnavi.api.company.IBusinessUnitBranchProperties|null|undefined} businessUnitBranchProperties
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.businessUnitBranchProperties = null;

                /**
                 * BusinessUnitRankReception category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.category = null;

                /**
                 * BusinessUnitRankReception qualityManager.
                 * @member {mtechnavi.api.company.BusinessUnitRankReception.IQualityManager|null|undefined} qualityManager
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.qualityManager = null;

                /**
                 * BusinessUnitRankReception comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.comment = "";

                /**
                 * BusinessUnitRankReception sendProcess.
                 * @member {mtechnavi.api.company.IProcess|null|undefined} sendProcess
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.sendProcess = null;

                /**
                 * BusinessUnitRankReception updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.updatedProperties = null;

                /**
                 * BusinessUnitRankReception sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.sharedProperties = null;

                /**
                 * BusinessUnitRankReception createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRankReception updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BusinessUnitRankReception deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 */
                BusinessUnitRankReception.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BusinessUnitRankReception message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitRankReception.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankReceptionId != null && message.hasOwnProperty("businessUnitRankReceptionId"))
                        if (!$util.isString(message.businessUnitRankReceptionId))
                            return "businessUnitRankReceptionId: string expected";
                    if (message.businessUnitRankRequestId != null && message.hasOwnProperty("businessUnitRankRequestId"))
                        if (!$util.isString(message.businessUnitRankRequestId))
                            return "businessUnitRankRequestId: string expected";
                    if (message.applyKind != null && message.hasOwnProperty("applyKind"))
                        if (!$util.isString(message.applyKind))
                            return "applyKind: string expected";
                    if (message.businessUnitBranchProperties != null && message.hasOwnProperty("businessUnitBranchProperties")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitBranchProperties.verify(message.businessUnitBranchProperties);
                        if (error)
                            return "businessUnitBranchProperties." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.qualityManager != null && message.hasOwnProperty("qualityManager")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.QualityManager.verify(message.qualityManager);
                        if (error)
                            return "qualityManager." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.sendProcess != null && message.hasOwnProperty("sendProcess")) {
                        let error = $root.mtechnavi.api.company.Process.verify(message.sendProcess);
                        if (error)
                            return "sendProcess." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitRankReception message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitRankReception} BusinessUnitRankReception
                 */
                BusinessUnitRankReception.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitRankReception)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitRankReception();
                    if (object.businessUnitRankReceptionId != null)
                        message.businessUnitRankReceptionId = String(object.businessUnitRankReceptionId);
                    if (object.businessUnitRankRequestId != null)
                        message.businessUnitRankRequestId = String(object.businessUnitRankRequestId);
                    if (object.applyKind != null)
                        message.applyKind = String(object.applyKind);
                    if (object.businessUnitBranchProperties != null) {
                        if (typeof object.businessUnitBranchProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.businessUnitBranchProperties: object expected");
                        message.businessUnitBranchProperties = $root.mtechnavi.api.company.BusinessUnitBranchProperties.fromObject(object.businessUnitBranchProperties);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.qualityManager != null) {
                        if (typeof object.qualityManager !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.qualityManager: object expected");
                        message.qualityManager = $root.mtechnavi.api.company.BusinessUnitRankReception.QualityManager.fromObject(object.qualityManager);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.sendProcess != null) {
                        if (typeof object.sendProcess !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.sendProcess: object expected");
                        message.sendProcess = $root.mtechnavi.api.company.Process.fromObject(object.sendProcess);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankReception.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitRankReception message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitRankReception} message BusinessUnitRankReception
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitRankReception.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRankReceptionId = "";
                        object.businessUnitRankRequestId = "";
                        object.businessUnitBranchProperties = null;
                        object.category = null;
                        object.qualityManager = null;
                        object.sendProcess = null;
                        object.updatedProperties = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.applyKind = "";
                        object.comment = "";
                    }
                    if (message.businessUnitRankReceptionId != null && message.hasOwnProperty("businessUnitRankReceptionId"))
                        object.businessUnitRankReceptionId = message.businessUnitRankReceptionId;
                    if (message.businessUnitRankRequestId != null && message.hasOwnProperty("businessUnitRankRequestId"))
                        object.businessUnitRankRequestId = message.businessUnitRankRequestId;
                    if (message.businessUnitBranchProperties != null && message.hasOwnProperty("businessUnitBranchProperties"))
                        object.businessUnitBranchProperties = $root.mtechnavi.api.company.BusinessUnitBranchProperties.toObject(message.businessUnitBranchProperties, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.qualityManager != null && message.hasOwnProperty("qualityManager"))
                        object.qualityManager = $root.mtechnavi.api.company.BusinessUnitRankReception.QualityManager.toObject(message.qualityManager, options);
                    if (message.sendProcess != null && message.hasOwnProperty("sendProcess"))
                        object.sendProcess = $root.mtechnavi.api.company.Process.toObject(message.sendProcess, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.applyKind != null && message.hasOwnProperty("applyKind"))
                        object.applyKind = message.applyKind;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this BusinessUnitRankReception to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitRankReception
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitRankReception.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                BusinessUnitRankReception.QualityManager = (function() {

                    /**
                     * Properties of a QualityManager.
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception
                     * @interface IQualityManager
                     * @property {string|null} [displayName] QualityManager displayName
                     * @property {string|null} [position] QualityManager position
                     * @property {string|null} [email] QualityManager email
                     */

                    /**
                     * Constructs a new QualityManager.
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception
                     * @classdesc Represents a QualityManager.
                     * @implements IQualityManager
                     * @constructor
                     * @param {mtechnavi.api.company.BusinessUnitRankReception.IQualityManager=} [properties] Properties to set
                     */
                    function QualityManager(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * QualityManager displayName.
                     * @member {string} displayName
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @instance
                     */
                    QualityManager.prototype.displayName = "";

                    /**
                     * QualityManager position.
                     * @member {string} position
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @instance
                     */
                    QualityManager.prototype.position = "";

                    /**
                     * QualityManager email.
                     * @member {string} email
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @instance
                     */
                    QualityManager.prototype.email = "";

                    /**
                     * Verifies a QualityManager message.
                     * @function verify
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QualityManager.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        return null;
                    };

                    /**
                     * Creates a QualityManager message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.BusinessUnitRankReception.QualityManager} QualityManager
                     */
                    QualityManager.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.BusinessUnitRankReception.QualityManager)
                            return object;
                        let message = new $root.mtechnavi.api.company.BusinessUnitRankReception.QualityManager();
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.position != null)
                            message.position = String(object.position);
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };

                    /**
                     * Creates a plain object from a QualityManager message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @static
                     * @param {mtechnavi.api.company.BusinessUnitRankReception.QualityManager} message QualityManager
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QualityManager.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.displayName = "";
                            object.position = "";
                            object.email = "";
                        }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        return object;
                    };

                    /**
                     * Converts this QualityManager to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.BusinessUnitRankReception.QualityManager
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QualityManager.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return QualityManager;
                })();

                return BusinessUnitRankReception;
            })();

            company.LocalBusinessUnitProfile = (function() {

                /**
                 * Properties of a LocalBusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitProfile
                 * @property {string|null} [localBusinessUnitProfileId] LocalBusinessUnitProfile localBusinessUnitProfileId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitProfile businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [item] LocalBusinessUnitProfile item
                 * @property {Long|null} [createdAt] LocalBusinessUnitProfile createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitProfile updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitProfile deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitProfile.
                 * @implements ILocalBusinessUnitProfile
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitProfile=} [properties] Properties to set
                 */
                function LocalBusinessUnitProfile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitProfile localBusinessUnitProfileId.
                 * @member {string} localBusinessUnitProfileId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.localBusinessUnitProfileId = "";

                /**
                 * LocalBusinessUnitProfile businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitProfile item.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.item = null;

                /**
                 * LocalBusinessUnitProfile createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitProfile updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitProfile deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 */
                LocalBusinessUnitProfile.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitProfile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitProfile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitProfileId != null && message.hasOwnProperty("localBusinessUnitProfileId"))
                        if (!$util.isString(message.localBusinessUnitProfileId))
                            return "localBusinessUnitProfileId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitProfile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitProfile} LocalBusinessUnitProfile
                 */
                LocalBusinessUnitProfile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitProfile)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitProfile();
                    if (object.localBusinessUnitProfileId != null)
                        message.localBusinessUnitProfileId = String(object.localBusinessUnitProfileId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitProfile.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitProfile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitProfile} message LocalBusinessUnitProfile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitProfile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitProfileId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitProfileId != null && message.hasOwnProperty("localBusinessUnitProfileId"))
                        object.localBusinessUnitProfileId = message.localBusinessUnitProfileId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitProfile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitProfile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitProfile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitProfile;
            })();

            company.LocalBusinessUnitFinancial = (function() {

                /**
                 * Properties of a LocalBusinessUnitFinancial.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitFinancial
                 * @property {string|null} [localBusinessUnitFinancialId] LocalBusinessUnitFinancial localBusinessUnitFinancialId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitFinancial businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitFinancial|null} [item] LocalBusinessUnitFinancial item
                 * @property {Long|null} [createdAt] LocalBusinessUnitFinancial createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitFinancial updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitFinancial deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitFinancial.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitFinancial.
                 * @implements ILocalBusinessUnitFinancial
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitFinancial=} [properties] Properties to set
                 */
                function LocalBusinessUnitFinancial(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitFinancial localBusinessUnitFinancialId.
                 * @member {string} localBusinessUnitFinancialId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.localBusinessUnitFinancialId = "";

                /**
                 * LocalBusinessUnitFinancial businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitFinancial item.
                 * @member {mtechnavi.api.company.IBusinessUnitFinancial|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.item = null;

                /**
                 * LocalBusinessUnitFinancial createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitFinancial updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitFinancial deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 */
                LocalBusinessUnitFinancial.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitFinancial message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitFinancial.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitFinancialId != null && message.hasOwnProperty("localBusinessUnitFinancialId"))
                        if (!$util.isString(message.localBusinessUnitFinancialId))
                            return "localBusinessUnitFinancialId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFinancial.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitFinancial message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitFinancial} LocalBusinessUnitFinancial
                 */
                LocalBusinessUnitFinancial.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitFinancial)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitFinancial();
                    if (object.localBusinessUnitFinancialId != null)
                        message.localBusinessUnitFinancialId = String(object.localBusinessUnitFinancialId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitFinancial.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitFinancial.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitFinancial message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitFinancial} message LocalBusinessUnitFinancial
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitFinancial.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitFinancialId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitFinancialId != null && message.hasOwnProperty("localBusinessUnitFinancialId"))
                        object.localBusinessUnitFinancialId = message.localBusinessUnitFinancialId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitFinancial.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitFinancial to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFinancial
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitFinancial.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitFinancial;
            })();

            company.LocalBusinessUnitFacilities = (function() {

                /**
                 * Properties of a LocalBusinessUnitFacilities.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitFacilities
                 * @property {string|null} [localBusinessUnitFacilitiesId] LocalBusinessUnitFacilities localBusinessUnitFacilitiesId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitFacilities businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitFacilities|null} [item] LocalBusinessUnitFacilities item
                 * @property {Long|null} [createdAt] LocalBusinessUnitFacilities createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitFacilities updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitFacilities deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitFacilities.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitFacilities.
                 * @implements ILocalBusinessUnitFacilities
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitFacilities=} [properties] Properties to set
                 */
                function LocalBusinessUnitFacilities(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitFacilities localBusinessUnitFacilitiesId.
                 * @member {string} localBusinessUnitFacilitiesId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.localBusinessUnitFacilitiesId = "";

                /**
                 * LocalBusinessUnitFacilities businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitFacilities item.
                 * @member {mtechnavi.api.company.IBusinessUnitFacilities|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.item = null;

                /**
                 * LocalBusinessUnitFacilities createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitFacilities updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitFacilities deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 */
                LocalBusinessUnitFacilities.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitFacilities message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitFacilities.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitFacilitiesId != null && message.hasOwnProperty("localBusinessUnitFacilitiesId"))
                        if (!$util.isString(message.localBusinessUnitFacilitiesId))
                            return "localBusinessUnitFacilitiesId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitFacilities.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitFacilities message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitFacilities} LocalBusinessUnitFacilities
                 */
                LocalBusinessUnitFacilities.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitFacilities)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitFacilities();
                    if (object.localBusinessUnitFacilitiesId != null)
                        message.localBusinessUnitFacilitiesId = String(object.localBusinessUnitFacilitiesId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitFacilities.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitFacilities.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitFacilities message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitFacilities} message LocalBusinessUnitFacilities
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitFacilities.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitFacilitiesId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitFacilitiesId != null && message.hasOwnProperty("localBusinessUnitFacilitiesId"))
                        object.localBusinessUnitFacilitiesId = message.localBusinessUnitFacilitiesId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitFacilities.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitFacilities to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitFacilities
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitFacilities.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitFacilities;
            })();

            company.LocalBusinessUnitCertificate = (function() {

                /**
                 * Properties of a LocalBusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitCertificate
                 * @property {string|null} [localBusinessUnitCertificateId] LocalBusinessUnitCertificate localBusinessUnitCertificateId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitCertificate businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitCertificate|null} [item] LocalBusinessUnitCertificate item
                 * @property {Long|null} [createdAt] LocalBusinessUnitCertificate createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitCertificate updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitCertificate deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitCertificate.
                 * @implements ILocalBusinessUnitCertificate
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitCertificate=} [properties] Properties to set
                 */
                function LocalBusinessUnitCertificate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitCertificate localBusinessUnitCertificateId.
                 * @member {string} localBusinessUnitCertificateId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.localBusinessUnitCertificateId = "";

                /**
                 * LocalBusinessUnitCertificate businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitCertificate item.
                 * @member {mtechnavi.api.company.IBusinessUnitCertificate|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.item = null;

                /**
                 * LocalBusinessUnitCertificate createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitCertificate updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitCertificate deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 */
                LocalBusinessUnitCertificate.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitCertificate message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitCertificate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitCertificateId != null && message.hasOwnProperty("localBusinessUnitCertificateId"))
                        if (!$util.isString(message.localBusinessUnitCertificateId))
                            return "localBusinessUnitCertificateId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitCertificate.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitCertificate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitCertificate} LocalBusinessUnitCertificate
                 */
                LocalBusinessUnitCertificate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitCertificate)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitCertificate();
                    if (object.localBusinessUnitCertificateId != null)
                        message.localBusinessUnitCertificateId = String(object.localBusinessUnitCertificateId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitCertificate.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitCertificate.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitCertificate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitCertificate} message LocalBusinessUnitCertificate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitCertificate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitCertificateId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitCertificateId != null && message.hasOwnProperty("localBusinessUnitCertificateId"))
                        object.localBusinessUnitCertificateId = message.localBusinessUnitCertificateId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitCertificate.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitCertificate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitCertificate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitCertificate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitCertificate;
            })();

            company.LocalBusinessUnitStrength = (function() {

                /**
                 * Properties of a LocalBusinessUnitStrength.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitStrength
                 * @property {string|null} [localBusinessUnitStrengthId] LocalBusinessUnitStrength localBusinessUnitStrengthId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitStrength businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitStrength|null} [item] LocalBusinessUnitStrength item
                 * @property {Long|null} [createdAt] LocalBusinessUnitStrength createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitStrength updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitStrength deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitStrength.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitStrength.
                 * @implements ILocalBusinessUnitStrength
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitStrength=} [properties] Properties to set
                 */
                function LocalBusinessUnitStrength(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitStrength localBusinessUnitStrengthId.
                 * @member {string} localBusinessUnitStrengthId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.localBusinessUnitStrengthId = "";

                /**
                 * LocalBusinessUnitStrength businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitStrength item.
                 * @member {mtechnavi.api.company.IBusinessUnitStrength|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.item = null;

                /**
                 * LocalBusinessUnitStrength createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitStrength updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitStrength deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 */
                LocalBusinessUnitStrength.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitStrength message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitStrength.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitStrengthId != null && message.hasOwnProperty("localBusinessUnitStrengthId"))
                        if (!$util.isString(message.localBusinessUnitStrengthId))
                            return "localBusinessUnitStrengthId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitStrength message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitStrength} LocalBusinessUnitStrength
                 */
                LocalBusinessUnitStrength.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitStrength)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitStrength();
                    if (object.localBusinessUnitStrengthId != null)
                        message.localBusinessUnitStrengthId = String(object.localBusinessUnitStrengthId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitStrength.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitStrength message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitStrength} message LocalBusinessUnitStrength
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitStrength.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitStrengthId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitStrengthId != null && message.hasOwnProperty("localBusinessUnitStrengthId"))
                        object.localBusinessUnitStrengthId = message.localBusinessUnitStrengthId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitStrength to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitStrength
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitStrength.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitStrength;
            })();

            company.LocalBusinessUnitSkill = (function() {

                /**
                 * Properties of a LocalBusinessUnitSkill.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitSkill
                 * @property {string|null} [localBusinessUnitSkillId] LocalBusinessUnitSkill localBusinessUnitSkillId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitSkill businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitSkill|null} [item] LocalBusinessUnitSkill item
                 * @property {Long|null} [createdAt] LocalBusinessUnitSkill createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitSkill updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitSkill deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitSkill.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitSkill.
                 * @implements ILocalBusinessUnitSkill
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitSkill=} [properties] Properties to set
                 */
                function LocalBusinessUnitSkill(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitSkill localBusinessUnitSkillId.
                 * @member {string} localBusinessUnitSkillId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.localBusinessUnitSkillId = "";

                /**
                 * LocalBusinessUnitSkill businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitSkill item.
                 * @member {mtechnavi.api.company.IBusinessUnitSkill|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.item = null;

                /**
                 * LocalBusinessUnitSkill createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitSkill updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitSkill deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 */
                LocalBusinessUnitSkill.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitSkill message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitSkill.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitSkillId != null && message.hasOwnProperty("localBusinessUnitSkillId"))
                        if (!$util.isString(message.localBusinessUnitSkillId))
                            return "localBusinessUnitSkillId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitSkill.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitSkill message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitSkill} LocalBusinessUnitSkill
                 */
                LocalBusinessUnitSkill.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitSkill)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitSkill();
                    if (object.localBusinessUnitSkillId != null)
                        message.localBusinessUnitSkillId = String(object.localBusinessUnitSkillId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitSkill.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitSkill.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitSkill message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitSkill} message LocalBusinessUnitSkill
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitSkill.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitSkillId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitSkillId != null && message.hasOwnProperty("localBusinessUnitSkillId"))
                        object.localBusinessUnitSkillId = message.localBusinessUnitSkillId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitSkill.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitSkill to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitSkill
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitSkill.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitSkill;
            })();

            company.LocalBusinessUnitContact = (function() {

                /**
                 * Properties of a LocalBusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitContact
                 * @property {string|null} [localBusinessUnitContactId] LocalBusinessUnitContact localBusinessUnitContactId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitContact businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [item] LocalBusinessUnitContact item
                 * @property {Long|null} [createdAt] LocalBusinessUnitContact createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitContact updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitContact deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitContact.
                 * @implements ILocalBusinessUnitContact
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitContact=} [properties] Properties to set
                 */
                function LocalBusinessUnitContact(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitContact localBusinessUnitContactId.
                 * @member {string} localBusinessUnitContactId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.localBusinessUnitContactId = "";

                /**
                 * LocalBusinessUnitContact businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitContact item.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.item = null;

                /**
                 * LocalBusinessUnitContact createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitContact updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitContact deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 */
                LocalBusinessUnitContact.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitContact message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitContact.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitContactId != null && message.hasOwnProperty("localBusinessUnitContactId"))
                        if (!$util.isString(message.localBusinessUnitContactId))
                            return "localBusinessUnitContactId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitContact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitContact} LocalBusinessUnitContact
                 */
                LocalBusinessUnitContact.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitContact)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitContact();
                    if (object.localBusinessUnitContactId != null)
                        message.localBusinessUnitContactId = String(object.localBusinessUnitContactId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitContact.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitContact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitContact} message LocalBusinessUnitContact
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitContact.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitContactId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitContactId != null && message.hasOwnProperty("localBusinessUnitContactId"))
                        object.localBusinessUnitContactId = message.localBusinessUnitContactId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitContact to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitContact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitContact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitContact;
            })();

            company.LocalBusinessUnitRankReception = (function() {

                /**
                 * Properties of a LocalBusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @interface ILocalBusinessUnitRankReception
                 * @property {string|null} [localBusinessUnitRankReceptionId] LocalBusinessUnitRankReception localBusinessUnitRankReceptionId
                 * @property {string|null} [businessUnitManagementId] LocalBusinessUnitRankReception businessUnitManagementId
                 * @property {mtechnavi.api.company.IBusinessUnitRankReception|null} [item] LocalBusinessUnitRankReception item
                 * @property {Long|null} [createdAt] LocalBusinessUnitRankReception createdAt
                 * @property {Long|null} [updatedAt] LocalBusinessUnitRankReception updatedAt
                 * @property {Long|null} [deletedAt] LocalBusinessUnitRankReception deletedAt
                 */

                /**
                 * Constructs a new LocalBusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a LocalBusinessUnitRankReception.
                 * @implements ILocalBusinessUnitRankReception
                 * @constructor
                 * @param {mtechnavi.api.company.ILocalBusinessUnitRankReception=} [properties] Properties to set
                 */
                function LocalBusinessUnitRankReception(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalBusinessUnitRankReception localBusinessUnitRankReceptionId.
                 * @member {string} localBusinessUnitRankReceptionId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.localBusinessUnitRankReceptionId = "";

                /**
                 * LocalBusinessUnitRankReception businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.businessUnitManagementId = "";

                /**
                 * LocalBusinessUnitRankReception item.
                 * @member {mtechnavi.api.company.IBusinessUnitRankReception|null|undefined} item
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.item = null;

                /**
                 * LocalBusinessUnitRankReception createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitRankReception updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LocalBusinessUnitRankReception deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 */
                LocalBusinessUnitRankReception.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LocalBusinessUnitRankReception message.
                 * @function verify
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalBusinessUnitRankReception.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localBusinessUnitRankReceptionId != null && message.hasOwnProperty("localBusinessUnitRankReceptionId"))
                        if (!$util.isString(message.localBusinessUnitRankReceptionId))
                            return "localBusinessUnitRankReceptionId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocalBusinessUnitRankReception message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.LocalBusinessUnitRankReception} LocalBusinessUnitRankReception
                 */
                LocalBusinessUnitRankReception.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.LocalBusinessUnitRankReception)
                        return object;
                    let message = new $root.mtechnavi.api.company.LocalBusinessUnitRankReception();
                    if (object.localBusinessUnitRankReceptionId != null)
                        message.localBusinessUnitRankReceptionId = String(object.localBusinessUnitRankReceptionId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.company.LocalBusinessUnitRankReception.item: object expected");
                        message.item = $root.mtechnavi.api.company.BusinessUnitRankReception.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocalBusinessUnitRankReception message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @static
                 * @param {mtechnavi.api.company.LocalBusinessUnitRankReception} message LocalBusinessUnitRankReception
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalBusinessUnitRankReception.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.localBusinessUnitRankReceptionId = "";
                        object.businessUnitManagementId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.localBusinessUnitRankReceptionId != null && message.hasOwnProperty("localBusinessUnitRankReceptionId"))
                        object.localBusinessUnitRankReceptionId = message.localBusinessUnitRankReceptionId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.company.BusinessUnitRankReception.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LocalBusinessUnitRankReception to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.LocalBusinessUnitRankReception
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalBusinessUnitRankReception.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LocalBusinessUnitRankReception;
            })();

            company.Organization = (function() {

                /**
                 * Properties of an Organization.
                 * @memberof mtechnavi.api.company
                 * @interface IOrganization
                 * @property {string|null} [organizationId] Organization organizationId
                 * @property {string|null} [code] Organization code
                 * @property {string|null} [displayName] Organization displayName
                 * @property {string|null} [displayNameShort] Organization displayNameShort
                 * @property {sharelib.IOrganizationReference|null} [parentOrganization] Organization parentOrganization
                 * @property {boolean|null} [usable] Organization usable
                 * @property {boolean|null} [procurementOption] Organization procurementOption
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] Organization createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] Organization updatedProperties
                 * @property {Long|null} [createdAt] Organization createdAt
                 * @property {Long|null} [updatedAt] Organization updatedAt
                 * @property {Long|null} [deletedAt] Organization deletedAt
                 */

                /**
                 * Constructs a new Organization.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an Organization.
                 * @implements IOrganization
                 * @constructor
                 * @param {mtechnavi.api.company.IOrganization=} [properties] Properties to set
                 */
                function Organization(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Organization organizationId.
                 * @member {string} organizationId
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.organizationId = "";

                /**
                 * Organization code.
                 * @member {string} code
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.code = "";

                /**
                 * Organization displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.displayName = "";

                /**
                 * Organization displayNameShort.
                 * @member {string} displayNameShort
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.displayNameShort = "";

                /**
                 * Organization parentOrganization.
                 * @member {sharelib.IOrganizationReference|null|undefined} parentOrganization
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.parentOrganization = null;

                /**
                 * Organization usable.
                 * @member {boolean} usable
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.usable = false;

                /**
                 * Organization procurementOption.
                 * @member {boolean} procurementOption
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.procurementOption = false;

                /**
                 * Organization createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.createdProperties = null;

                /**
                 * Organization updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.updatedProperties = null;

                /**
                 * Organization createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Organization updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Organization deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 */
                Organization.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Organization message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Organization
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Organization.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        if (!$util.isString(message.organizationId))
                            return "organizationId: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                        if (!$util.isString(message.displayNameShort))
                            return "displayNameShort: string expected";
                    if (message.parentOrganization != null && message.hasOwnProperty("parentOrganization")) {
                        let error = $root.sharelib.OrganizationReference.verify(message.parentOrganization);
                        if (error)
                            return "parentOrganization." + error;
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        if (typeof message.usable !== "boolean")
                            return "usable: boolean expected";
                    if (message.procurementOption != null && message.hasOwnProperty("procurementOption"))
                        if (typeof message.procurementOption !== "boolean")
                            return "procurementOption: boolean expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Organization message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Organization
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Organization} Organization
                 */
                Organization.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Organization)
                        return object;
                    let message = new $root.mtechnavi.api.company.Organization();
                    if (object.organizationId != null)
                        message.organizationId = String(object.organizationId);
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.displayNameShort != null)
                        message.displayNameShort = String(object.displayNameShort);
                    if (object.parentOrganization != null) {
                        if (typeof object.parentOrganization !== "object")
                            throw TypeError(".mtechnavi.api.company.Organization.parentOrganization: object expected");
                        message.parentOrganization = $root.sharelib.OrganizationReference.fromObject(object.parentOrganization);
                    }
                    if (object.usable != null)
                        message.usable = Boolean(object.usable);
                    if (object.procurementOption != null)
                        message.procurementOption = Boolean(object.procurementOption);
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.Organization.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.Organization.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Organization message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Organization
                 * @static
                 * @param {mtechnavi.api.company.Organization} message Organization
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Organization.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.organizationId = "";
                        object.code = "";
                        object.displayName = "";
                        object.displayNameShort = "";
                        object.parentOrganization = null;
                        object.usable = false;
                        object.procurementOption = false;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        object.organizationId = message.organizationId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                        object.displayNameShort = message.displayNameShort;
                    if (message.parentOrganization != null && message.hasOwnProperty("parentOrganization"))
                        object.parentOrganization = $root.sharelib.OrganizationReference.toObject(message.parentOrganization, options);
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = message.usable;
                    if (message.procurementOption != null && message.hasOwnProperty("procurementOption"))
                        object.procurementOption = message.procurementOption;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Organization to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Organization
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Organization.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Organization;
            })();

            company.OrganizationRelation = (function() {

                /**
                 * Properties of an OrganizationRelation.
                 * @memberof mtechnavi.api.company
                 * @interface IOrganizationRelation
                 * @property {string|null} [organizationRelationId] OrganizationRelation organizationRelationId
                 * @property {string|null} [organizationId] OrganizationRelation organizationId
                 * @property {string|null} [userId] OrganizationRelation userId
                 * @property {Long|null} [level] OrganizationRelation level
                 * @property {Long|null} [createdAt] OrganizationRelation createdAt
                 * @property {Long|null} [updatedAt] OrganizationRelation updatedAt
                 * @property {Long|null} [deletedAt] OrganizationRelation deletedAt
                 */

                /**
                 * Constructs a new OrganizationRelation.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an OrganizationRelation.
                 * @implements IOrganizationRelation
                 * @constructor
                 * @param {mtechnavi.api.company.IOrganizationRelation=} [properties] Properties to set
                 */
                function OrganizationRelation(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrganizationRelation organizationRelationId.
                 * @member {string} organizationRelationId
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.organizationRelationId = "";

                /**
                 * OrganizationRelation organizationId.
                 * @member {string} organizationId
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.organizationId = "";

                /**
                 * OrganizationRelation userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.userId = "";

                /**
                 * OrganizationRelation level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * OrganizationRelation createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * OrganizationRelation updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * OrganizationRelation deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 */
                OrganizationRelation.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an OrganizationRelation message.
                 * @function verify
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrganizationRelation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.organizationRelationId != null && message.hasOwnProperty("organizationRelationId"))
                        if (!$util.isString(message.organizationRelationId))
                            return "organizationRelationId: string expected";
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        if (!$util.isString(message.organizationId))
                            return "organizationId: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an OrganizationRelation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.OrganizationRelation} OrganizationRelation
                 */
                OrganizationRelation.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.OrganizationRelation)
                        return object;
                    let message = new $root.mtechnavi.api.company.OrganizationRelation();
                    if (object.organizationRelationId != null)
                        message.organizationRelationId = String(object.organizationRelationId);
                    if (object.organizationId != null)
                        message.organizationId = String(object.organizationId);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an OrganizationRelation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @static
                 * @param {mtechnavi.api.company.OrganizationRelation} message OrganizationRelation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrganizationRelation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.organizationRelationId = "";
                        object.organizationId = "";
                        object.userId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.organizationRelationId != null && message.hasOwnProperty("organizationRelationId"))
                        object.organizationRelationId = message.organizationRelationId;
                    if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                        object.organizationId = message.organizationId;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this OrganizationRelation to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.OrganizationRelation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrganizationRelation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrganizationRelation;
            })();

            company.BillingCompany = (function() {

                /**
                 * Properties of a BillingCompany.
                 * @memberof mtechnavi.api.company
                 * @interface IBillingCompany
                 * @property {string|null} [billingCompanyId] BillingCompany billingCompanyId
                 * @property {string|null} [displayName] BillingCompany displayName
                 * @property {string|null} [adminDisplayName] BillingCompany adminDisplayName
                 * @property {string|null} [adminEmail] BillingCompany adminEmail
                 * @property {sharelib.INameOption|null} [country] BillingCompany country
                 * @property {sharelib.INameOption|null} [region] BillingCompany region
                 * @property {Array.<string>|null} [addressLines] BillingCompany addressLines
                 * @property {string|null} [phoneNumber] BillingCompany phoneNumber
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] BillingCompany createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] BillingCompany updatedProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BillingCompany sharedProperties
                 * @property {Long|null} [createdAt] BillingCompany createdAt
                 * @property {Long|null} [updatedAt] BillingCompany updatedAt
                 * @property {Long|null} [deletedAt] BillingCompany deletedAt
                 */

                /**
                 * Constructs a new BillingCompany.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BillingCompany.
                 * @implements IBillingCompany
                 * @constructor
                 * @param {mtechnavi.api.company.IBillingCompany=} [properties] Properties to set
                 */
                function BillingCompany(properties) {
                    this.addressLines = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BillingCompany billingCompanyId.
                 * @member {string} billingCompanyId
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.billingCompanyId = "";

                /**
                 * BillingCompany displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.displayName = "";

                /**
                 * BillingCompany adminDisplayName.
                 * @member {string} adminDisplayName
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.adminDisplayName = "";

                /**
                 * BillingCompany adminEmail.
                 * @member {string} adminEmail
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.adminEmail = "";

                /**
                 * BillingCompany country.
                 * @member {sharelib.INameOption|null|undefined} country
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.country = null;

                /**
                 * BillingCompany region.
                 * @member {sharelib.INameOption|null|undefined} region
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.region = null;

                /**
                 * BillingCompany addressLines.
                 * @member {Array.<string>} addressLines
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.addressLines = $util.emptyArray;

                /**
                 * BillingCompany phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.phoneNumber = "";

                /**
                 * BillingCompany createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.createdProperties = null;

                /**
                 * BillingCompany updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.updatedProperties = null;

                /**
                 * BillingCompany sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.sharedProperties = null;

                /**
                 * BillingCompany createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BillingCompany updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BillingCompany deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 */
                BillingCompany.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BillingCompany message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BillingCompany.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        if (!$util.isString(message.billingCompanyId))
                            return "billingCompanyId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        if (!$util.isString(message.adminDisplayName))
                            return "adminDisplayName: string expected";
                    if (message.adminEmail != null && message.hasOwnProperty("adminEmail"))
                        if (!$util.isString(message.adminEmail))
                            return "adminEmail: string expected";
                    if (message.country != null && message.hasOwnProperty("country")) {
                        let error = $root.sharelib.NameOption.verify(message.country);
                        if (error)
                            return "country." + error;
                    }
                    if (message.region != null && message.hasOwnProperty("region")) {
                        let error = $root.sharelib.NameOption.verify(message.region);
                        if (error)
                            return "region." + error;
                    }
                    if (message.addressLines != null && message.hasOwnProperty("addressLines")) {
                        if (!Array.isArray(message.addressLines))
                            return "addressLines: array expected";
                        for (let i = 0; i < message.addressLines.length; ++i)
                            if (!$util.isString(message.addressLines[i]))
                                return "addressLines: string[] expected";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BillingCompany message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BillingCompany} BillingCompany
                 */
                BillingCompany.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BillingCompany)
                        return object;
                    let message = new $root.mtechnavi.api.company.BillingCompany();
                    if (object.billingCompanyId != null)
                        message.billingCompanyId = String(object.billingCompanyId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.adminDisplayName != null)
                        message.adminDisplayName = String(object.adminDisplayName);
                    if (object.adminEmail != null)
                        message.adminEmail = String(object.adminEmail);
                    if (object.country != null) {
                        if (typeof object.country !== "object")
                            throw TypeError(".mtechnavi.api.company.BillingCompany.country: object expected");
                        message.country = $root.sharelib.NameOption.fromObject(object.country);
                    }
                    if (object.region != null) {
                        if (typeof object.region !== "object")
                            throw TypeError(".mtechnavi.api.company.BillingCompany.region: object expected");
                        message.region = $root.sharelib.NameOption.fromObject(object.region);
                    }
                    if (object.addressLines) {
                        if (!Array.isArray(object.addressLines))
                            throw TypeError(".mtechnavi.api.company.BillingCompany.addressLines: array expected");
                        message.addressLines = [];
                        for (let i = 0; i < object.addressLines.length; ++i)
                            message.addressLines[i] = String(object.addressLines[i]);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BillingCompany.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BillingCompany.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.BillingCompany.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BillingCompany message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @static
                 * @param {mtechnavi.api.company.BillingCompany} message BillingCompany
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BillingCompany.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.addressLines = [];
                    if (options.defaults) {
                        object.billingCompanyId = "";
                        object.displayName = "";
                        object.country = null;
                        object.region = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.adminDisplayName = "";
                        object.adminEmail = "";
                        object.phoneNumber = "";
                    }
                    if (message.billingCompanyId != null && message.hasOwnProperty("billingCompanyId"))
                        object.billingCompanyId = message.billingCompanyId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = $root.sharelib.NameOption.toObject(message.country, options);
                    if (message.region != null && message.hasOwnProperty("region"))
                        object.region = $root.sharelib.NameOption.toObject(message.region, options);
                    if (message.addressLines && message.addressLines.length) {
                        object.addressLines = [];
                        for (let j = 0; j < message.addressLines.length; ++j)
                            object.addressLines[j] = message.addressLines[j];
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        object.adminDisplayName = message.adminDisplayName;
                    if (message.adminEmail != null && message.hasOwnProperty("adminEmail"))
                        object.adminEmail = message.adminEmail;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    return object;
                };

                /**
                 * Converts this BillingCompany to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BillingCompany
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BillingCompany.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BillingCompany;
            })();

            company.CompanyStatus = (function() {

                /**
                 * Properties of a CompanyStatus.
                 * @memberof mtechnavi.api.company
                 * @interface ICompanyStatus
                 * @property {sharelib.INameOption|null} [status] CompanyStatus status
                 * @property {sharelib.INameOption|null} [category] CompanyStatus category
                 * @property {sharelib.INameOption|null} [detail] CompanyStatus detail
                 */

                /**
                 * Constructs a new CompanyStatus.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyStatus.
                 * @implements ICompanyStatus
                 * @constructor
                 * @param {mtechnavi.api.company.ICompanyStatus=} [properties] Properties to set
                 */
                function CompanyStatus(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompanyStatus status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @instance
                 */
                CompanyStatus.prototype.status = null;

                /**
                 * CompanyStatus category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @instance
                 */
                CompanyStatus.prototype.category = null;

                /**
                 * CompanyStatus detail.
                 * @member {sharelib.INameOption|null|undefined} detail
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @instance
                 */
                CompanyStatus.prototype.detail = null;

                /**
                 * Verifies a CompanyStatus message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompanyStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.detail != null && message.hasOwnProperty("detail")) {
                        let error = $root.sharelib.NameOption.verify(message.detail);
                        if (error)
                            return "detail." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompanyStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CompanyStatus} CompanyStatus
                 */
                CompanyStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CompanyStatus)
                        return object;
                    let message = new $root.mtechnavi.api.company.CompanyStatus();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyStatus.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyStatus.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.detail != null) {
                        if (typeof object.detail !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyStatus.detail: object expected");
                        message.detail = $root.sharelib.NameOption.fromObject(object.detail);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompanyStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @static
                 * @param {mtechnavi.api.company.CompanyStatus} message CompanyStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.status = null;
                        object.category = null;
                        object.detail = null;
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.detail != null && message.hasOwnProperty("detail"))
                        object.detail = $root.sharelib.NameOption.toObject(message.detail, options);
                    return object;
                };

                /**
                 * Converts this CompanyStatus to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CompanyStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompanyStatus;
            })();

            company.Profile = (function() {

                /**
                 * Properties of a Profile.
                 * @memberof mtechnavi.api.company
                 * @interface IProfile
                 * @property {string|null} [websiteUrl] Profile websiteUrl
                 * @property {string|null} [phoneNumber] Profile phoneNumber
                 * @property {sharelib.IAddress|null} [address] Profile address
                 * @property {mtechnavi.api.company.Profile.IRepresentative|null} [representative] Profile representative
                 * @property {sharelib.IMonetaryAmount|null} [capitalStock] Profile capitalStock
                 * @property {string|null} [industry] Profile industry
                 * @property {string|null} [mainBusiness] Profile mainBusiness
                 * @property {string|null} [publicComment] Profile publicComment
                 */

                /**
                 * Constructs a new Profile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a Profile.
                 * @implements IProfile
                 * @constructor
                 * @param {mtechnavi.api.company.IProfile=} [properties] Properties to set
                 */
                function Profile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Profile websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.websiteUrl = "";

                /**
                 * Profile phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.phoneNumber = "";

                /**
                 * Profile address.
                 * @member {sharelib.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.address = null;

                /**
                 * Profile representative.
                 * @member {mtechnavi.api.company.Profile.IRepresentative|null|undefined} representative
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.representative = null;

                /**
                 * Profile capitalStock.
                 * @member {sharelib.IMonetaryAmount|null|undefined} capitalStock
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.capitalStock = null;

                /**
                 * Profile industry.
                 * @member {string} industry
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.industry = "";

                /**
                 * Profile mainBusiness.
                 * @member {string} mainBusiness
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.mainBusiness = "";

                /**
                 * Profile publicComment.
                 * @member {string} publicComment
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 */
                Profile.prototype.publicComment = "";

                /**
                 * Verifies a Profile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Profile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Profile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.sharelib.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.representative != null && message.hasOwnProperty("representative")) {
                        let error = $root.mtechnavi.api.company.Profile.Representative.verify(message.representative);
                        if (error)
                            return "representative." + error;
                    }
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.capitalStock);
                        if (error)
                            return "capitalStock." + error;
                    }
                    if (message.industry != null && message.hasOwnProperty("industry"))
                        if (!$util.isString(message.industry))
                            return "industry: string expected";
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        if (!$util.isString(message.mainBusiness))
                            return "mainBusiness: string expected";
                    if (message.publicComment != null && message.hasOwnProperty("publicComment"))
                        if (!$util.isString(message.publicComment))
                            return "publicComment: string expected";
                    return null;
                };

                /**
                 * Creates a Profile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Profile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Profile} Profile
                 */
                Profile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Profile)
                        return object;
                    let message = new $root.mtechnavi.api.company.Profile();
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.Profile.address: object expected");
                        message.address = $root.sharelib.Address.fromObject(object.address);
                    }
                    if (object.representative != null) {
                        if (typeof object.representative !== "object")
                            throw TypeError(".mtechnavi.api.company.Profile.representative: object expected");
                        message.representative = $root.mtechnavi.api.company.Profile.Representative.fromObject(object.representative);
                    }
                    if (object.capitalStock != null) {
                        if (typeof object.capitalStock !== "object")
                            throw TypeError(".mtechnavi.api.company.Profile.capitalStock: object expected");
                        message.capitalStock = $root.sharelib.MonetaryAmount.fromObject(object.capitalStock);
                    }
                    if (object.industry != null)
                        message.industry = String(object.industry);
                    if (object.mainBusiness != null)
                        message.mainBusiness = String(object.mainBusiness);
                    if (object.publicComment != null)
                        message.publicComment = String(object.publicComment);
                    return message;
                };

                /**
                 * Creates a plain object from a Profile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Profile
                 * @static
                 * @param {mtechnavi.api.company.Profile} message Profile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Profile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.address = null;
                        object.representative = null;
                        object.capitalStock = null;
                        object.industry = "";
                        object.mainBusiness = "";
                        object.publicComment = "";
                    }
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.sharelib.Address.toObject(message.address, options);
                    if (message.representative != null && message.hasOwnProperty("representative"))
                        object.representative = $root.mtechnavi.api.company.Profile.Representative.toObject(message.representative, options);
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock"))
                        object.capitalStock = $root.sharelib.MonetaryAmount.toObject(message.capitalStock, options);
                    if (message.industry != null && message.hasOwnProperty("industry"))
                        object.industry = message.industry;
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        object.mainBusiness = message.mainBusiness;
                    if (message.publicComment != null && message.hasOwnProperty("publicComment"))
                        object.publicComment = message.publicComment;
                    return object;
                };

                /**
                 * Converts this Profile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Profile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Profile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Profile.Representative = (function() {

                    /**
                     * Properties of a Representative.
                     * @memberof mtechnavi.api.company.Profile
                     * @interface IRepresentative
                     * @property {Object.<string,string>|null} [displayNameLang] Representative displayNameLang
                     * @property {string|null} [position] Representative position
                     */

                    /**
                     * Constructs a new Representative.
                     * @memberof mtechnavi.api.company.Profile
                     * @classdesc Represents a Representative.
                     * @implements IRepresentative
                     * @constructor
                     * @param {mtechnavi.api.company.Profile.IRepresentative=} [properties] Properties to set
                     */
                    function Representative(properties) {
                        this.displayNameLang = {};
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Representative displayNameLang.
                     * @member {Object.<string,string>} displayNameLang
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.displayNameLang = $util.emptyObject;

                    /**
                     * Representative position.
                     * @member {string} position
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @instance
                     */
                    Representative.prototype.position = "";

                    /**
                     * Verifies a Representative message.
                     * @function verify
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Representative.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                            if (!$util.isObject(message.displayNameLang))
                                return "displayNameLang: object expected";
                            let key = Object.keys(message.displayNameLang);
                            for (let i = 0; i < key.length; ++i)
                                if (!$util.isString(message.displayNameLang[key[i]]))
                                    return "displayNameLang: string{k:string} expected";
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        return null;
                    };

                    /**
                     * Creates a Representative message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.Profile.Representative} Representative
                     */
                    Representative.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.Profile.Representative)
                            return object;
                        let message = new $root.mtechnavi.api.company.Profile.Representative();
                        if (object.displayNameLang) {
                            if (typeof object.displayNameLang !== "object")
                                throw TypeError(".mtechnavi.api.company.Profile.Representative.displayNameLang: object expected");
                            message.displayNameLang = {};
                            for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                        }
                        if (object.position != null)
                            message.position = String(object.position);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Representative message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @static
                     * @param {mtechnavi.api.company.Profile.Representative} message Representative
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Representative.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.objects || options.defaults)
                            object.displayNameLang = {};
                        if (options.defaults)
                            object.position = "";
                        let keys2;
                        if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                            object.displayNameLang = {};
                            for (let j = 0; j < keys2.length; ++j)
                                object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        return object;
                    };

                    /**
                     * Converts this Representative to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.Profile.Representative
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Representative.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Representative;
                })();

                return Profile;
            })();

            company.CompanyLegalProperties = (function() {

                /**
                 * Properties of a CompanyLegalProperties.
                 * @memberof mtechnavi.api.company
                 * @interface ICompanyLegalProperties
                 * @property {string|null} [companyNumber] CompanyLegalProperties companyNumber
                 * @property {string|null} [eligibleInvoiceAuthorizationNumber] CompanyLegalProperties eligibleInvoiceAuthorizationNumber
                 */

                /**
                 * Constructs a new CompanyLegalProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyLegalProperties.
                 * @implements ICompanyLegalProperties
                 * @constructor
                 * @param {mtechnavi.api.company.ICompanyLegalProperties=} [properties] Properties to set
                 */
                function CompanyLegalProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompanyLegalProperties companyNumber.
                 * @member {string} companyNumber
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @instance
                 */
                CompanyLegalProperties.prototype.companyNumber = "";

                /**
                 * CompanyLegalProperties eligibleInvoiceAuthorizationNumber.
                 * @member {string} eligibleInvoiceAuthorizationNumber
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @instance
                 */
                CompanyLegalProperties.prototype.eligibleInvoiceAuthorizationNumber = "";

                /**
                 * Verifies a CompanyLegalProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompanyLegalProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyNumber != null && message.hasOwnProperty("companyNumber"))
                        if (!$util.isString(message.companyNumber))
                            return "companyNumber: string expected";
                    if (message.eligibleInvoiceAuthorizationNumber != null && message.hasOwnProperty("eligibleInvoiceAuthorizationNumber"))
                        if (!$util.isString(message.eligibleInvoiceAuthorizationNumber))
                            return "eligibleInvoiceAuthorizationNumber: string expected";
                    return null;
                };

                /**
                 * Creates a CompanyLegalProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CompanyLegalProperties} CompanyLegalProperties
                 */
                CompanyLegalProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CompanyLegalProperties)
                        return object;
                    let message = new $root.mtechnavi.api.company.CompanyLegalProperties();
                    if (object.companyNumber != null)
                        message.companyNumber = String(object.companyNumber);
                    if (object.eligibleInvoiceAuthorizationNumber != null)
                        message.eligibleInvoiceAuthorizationNumber = String(object.eligibleInvoiceAuthorizationNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a CompanyLegalProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @static
                 * @param {mtechnavi.api.company.CompanyLegalProperties} message CompanyLegalProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyLegalProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.companyNumber = "";
                        object.eligibleInvoiceAuthorizationNumber = "";
                    }
                    if (message.companyNumber != null && message.hasOwnProperty("companyNumber"))
                        object.companyNumber = message.companyNumber;
                    if (message.eligibleInvoiceAuthorizationNumber != null && message.hasOwnProperty("eligibleInvoiceAuthorizationNumber"))
                        object.eligibleInvoiceAuthorizationNumber = message.eligibleInvoiceAuthorizationNumber;
                    return object;
                };

                /**
                 * Converts this CompanyLegalProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CompanyLegalProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyLegalProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompanyLegalProperties;
            })();

            company.PostUnitProperties = (function() {

                /**
                 * Properties of a PostUnitProperties.
                 * @memberof mtechnavi.api.company
                 * @interface IPostUnitProperties
                 */

                /**
                 * Constructs a new PostUnitProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PostUnitProperties.
                 * @implements IPostUnitProperties
                 * @constructor
                 * @param {mtechnavi.api.company.IPostUnitProperties=} [properties] Properties to set
                 */
                function PostUnitProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a PostUnitProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PostUnitProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostUnitProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PostUnitProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PostUnitProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PostUnitProperties} PostUnitProperties
                 */
                PostUnitProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PostUnitProperties)
                        return object;
                    return new $root.mtechnavi.api.company.PostUnitProperties();
                };

                /**
                 * Creates a plain object from a PostUnitProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PostUnitProperties
                 * @static
                 * @param {mtechnavi.api.company.PostUnitProperties} message PostUnitProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostUnitProperties.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PostUnitProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PostUnitProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostUnitProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PostUnitProperties;
            })();

            company.OrganizationUnitProperties = (function() {

                /**
                 * Properties of an OrganizationUnitProperties.
                 * @memberof mtechnavi.api.company
                 * @interface IOrganizationUnitProperties
                 */

                /**
                 * Constructs a new OrganizationUnitProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an OrganizationUnitProperties.
                 * @implements IOrganizationUnitProperties
                 * @constructor
                 * @param {mtechnavi.api.company.IOrganizationUnitProperties=} [properties] Properties to set
                 */
                function OrganizationUnitProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an OrganizationUnitProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.OrganizationUnitProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrganizationUnitProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an OrganizationUnitProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.OrganizationUnitProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.OrganizationUnitProperties} OrganizationUnitProperties
                 */
                OrganizationUnitProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.OrganizationUnitProperties)
                        return object;
                    return new $root.mtechnavi.api.company.OrganizationUnitProperties();
                };

                /**
                 * Creates a plain object from an OrganizationUnitProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.OrganizationUnitProperties
                 * @static
                 * @param {mtechnavi.api.company.OrganizationUnitProperties} message OrganizationUnitProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrganizationUnitProperties.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this OrganizationUnitProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.OrganizationUnitProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrganizationUnitProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OrganizationUnitProperties;
            })();

            company.DeliveryPointProperties = (function() {

                /**
                 * Properties of a DeliveryPointProperties.
                 * @memberof mtechnavi.api.company
                 * @interface IDeliveryPointProperties
                 */

                /**
                 * Constructs a new DeliveryPointProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DeliveryPointProperties.
                 * @implements IDeliveryPointProperties
                 * @constructor
                 * @param {mtechnavi.api.company.IDeliveryPointProperties=} [properties] Properties to set
                 */
                function DeliveryPointProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a DeliveryPointProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DeliveryPointProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeliveryPointProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a DeliveryPointProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DeliveryPointProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DeliveryPointProperties} DeliveryPointProperties
                 */
                DeliveryPointProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DeliveryPointProperties)
                        return object;
                    return new $root.mtechnavi.api.company.DeliveryPointProperties();
                };

                /**
                 * Creates a plain object from a DeliveryPointProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DeliveryPointProperties
                 * @static
                 * @param {mtechnavi.api.company.DeliveryPointProperties} message DeliveryPointProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeliveryPointProperties.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DeliveryPointProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DeliveryPointProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeliveryPointProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeliveryPointProperties;
            })();

            company.OfficeProperties = (function() {

                /**
                 * Properties of an OfficeProperties.
                 * @memberof mtechnavi.api.company
                 * @interface IOfficeProperties
                 * @property {sharelib.INameOption|null} [officeRank] OfficeProperties officeRank
                 */

                /**
                 * Constructs a new OfficeProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an OfficeProperties.
                 * @implements IOfficeProperties
                 * @constructor
                 * @param {mtechnavi.api.company.IOfficeProperties=} [properties] Properties to set
                 */
                function OfficeProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OfficeProperties officeRank.
                 * @member {sharelib.INameOption|null|undefined} officeRank
                 * @memberof mtechnavi.api.company.OfficeProperties
                 * @instance
                 */
                OfficeProperties.prototype.officeRank = null;

                /**
                 * Verifies an OfficeProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.OfficeProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OfficeProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.officeRank != null && message.hasOwnProperty("officeRank")) {
                        let error = $root.sharelib.NameOption.verify(message.officeRank);
                        if (error)
                            return "officeRank." + error;
                    }
                    return null;
                };

                /**
                 * Creates an OfficeProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.OfficeProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.OfficeProperties} OfficeProperties
                 */
                OfficeProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.OfficeProperties)
                        return object;
                    let message = new $root.mtechnavi.api.company.OfficeProperties();
                    if (object.officeRank != null) {
                        if (typeof object.officeRank !== "object")
                            throw TypeError(".mtechnavi.api.company.OfficeProperties.officeRank: object expected");
                        message.officeRank = $root.sharelib.NameOption.fromObject(object.officeRank);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an OfficeProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.OfficeProperties
                 * @static
                 * @param {mtechnavi.api.company.OfficeProperties} message OfficeProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OfficeProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.officeRank = null;
                    if (message.officeRank != null && message.hasOwnProperty("officeRank"))
                        object.officeRank = $root.sharelib.NameOption.toObject(message.officeRank, options);
                    return object;
                };

                /**
                 * Converts this OfficeProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.OfficeProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OfficeProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OfficeProperties;
            })();

            company.ImageFile = (function() {

                /**
                 * Properties of an ImageFile.
                 * @memberof mtechnavi.api.company
                 * @interface IImageFile
                 * @property {string|null} [assetId] ImageFile assetId
                 * @property {Object.<string,string>|null} [metadata] ImageFile metadata
                 */

                /**
                 * Constructs a new ImageFile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ImageFile.
                 * @implements IImageFile
                 * @constructor
                 * @param {mtechnavi.api.company.IImageFile=} [properties] Properties to set
                 */
                function ImageFile(properties) {
                    this.metadata = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImageFile assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.company.ImageFile
                 * @instance
                 */
                ImageFile.prototype.assetId = "";

                /**
                 * ImageFile metadata.
                 * @member {Object.<string,string>} metadata
                 * @memberof mtechnavi.api.company.ImageFile
                 * @instance
                 */
                ImageFile.prototype.metadata = $util.emptyObject;

                /**
                 * Verifies an ImageFile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ImageFile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImageFile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.metadata != null && message.hasOwnProperty("metadata")) {
                        if (!$util.isObject(message.metadata))
                            return "metadata: object expected";
                        let key = Object.keys(message.metadata);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.metadata[key[i]]))
                                return "metadata: string{k:string} expected";
                    }
                    return null;
                };

                /**
                 * Creates an ImageFile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ImageFile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ImageFile} ImageFile
                 */
                ImageFile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ImageFile)
                        return object;
                    let message = new $root.mtechnavi.api.company.ImageFile();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.metadata) {
                        if (typeof object.metadata !== "object")
                            throw TypeError(".mtechnavi.api.company.ImageFile.metadata: object expected");
                        message.metadata = {};
                        for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                            message.metadata[keys[i]] = String(object.metadata[keys[i]]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ImageFile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ImageFile
                 * @static
                 * @param {mtechnavi.api.company.ImageFile} message ImageFile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageFile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.metadata = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    let keys2;
                    if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                        object.metadata = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.metadata[keys2[j]] = message.metadata[keys2[j]];
                    }
                    return object;
                };

                /**
                 * Converts this ImageFile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ImageFile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageFile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImageFile;
            })();

            company.PostUnit = (function() {

                /**
                 * Properties of a PostUnit.
                 * @memberof mtechnavi.api.company
                 * @interface IPostUnit
                 * @property {string|null} [componentUnitId] PostUnit componentUnitId
                 * @property {string|null} [postUnitCode] PostUnit postUnitCode
                 * @property {string|null} [displayName] PostUnit displayName
                 * @property {Array.<sharelib.INameOption>|null} [postUnitType] PostUnit postUnitType
                 * @property {string|null} [remarks] PostUnit remarks
                 * @property {string|null} [componentUnitCategory] PostUnit componentUnitCategory
                 * @property {Array.<string>|null} [linkageItem] PostUnit linkageItem
                 * @property {sharelib.INameOption|null} [usable] PostUnit usable
                 */

                /**
                 * Constructs a new PostUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PostUnit.
                 * @implements IPostUnit
                 * @constructor
                 * @param {mtechnavi.api.company.IPostUnit=} [properties] Properties to set
                 */
                function PostUnit(properties) {
                    this.postUnitType = [];
                    this.linkageItem = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PostUnit componentUnitId.
                 * @member {string} componentUnitId
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.componentUnitId = "";

                /**
                 * PostUnit postUnitCode.
                 * @member {string} postUnitCode
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.postUnitCode = "";

                /**
                 * PostUnit displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.displayName = "";

                /**
                 * PostUnit postUnitType.
                 * @member {Array.<sharelib.INameOption>} postUnitType
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.postUnitType = $util.emptyArray;

                /**
                 * PostUnit remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.remarks = "";

                /**
                 * PostUnit componentUnitCategory.
                 * @member {string} componentUnitCategory
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.componentUnitCategory = "";

                /**
                 * PostUnit linkageItem.
                 * @member {Array.<string>} linkageItem
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.linkageItem = $util.emptyArray;

                /**
                 * PostUnit usable.
                 * @member {sharelib.INameOption|null|undefined} usable
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 */
                PostUnit.prototype.usable = null;

                /**
                 * Verifies a PostUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PostUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        if (!$util.isString(message.componentUnitId))
                            return "componentUnitId: string expected";
                    if (message.postUnitCode != null && message.hasOwnProperty("postUnitCode"))
                        if (!$util.isString(message.postUnitCode))
                            return "postUnitCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.postUnitType != null && message.hasOwnProperty("postUnitType")) {
                        if (!Array.isArray(message.postUnitType))
                            return "postUnitType: array expected";
                        for (let i = 0; i < message.postUnitType.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.postUnitType[i]);
                            if (error)
                                return "postUnitType." + error;
                        }
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.componentUnitCategory != null && message.hasOwnProperty("componentUnitCategory"))
                        if (!$util.isString(message.componentUnitCategory))
                            return "componentUnitCategory: string expected";
                    if (message.linkageItem != null && message.hasOwnProperty("linkageItem")) {
                        if (!Array.isArray(message.linkageItem))
                            return "linkageItem: array expected";
                        for (let i = 0; i < message.linkageItem.length; ++i)
                            if (!$util.isString(message.linkageItem[i]))
                                return "linkageItem: string[] expected";
                    }
                    if (message.usable != null && message.hasOwnProperty("usable")) {
                        let error = $root.sharelib.NameOption.verify(message.usable);
                        if (error)
                            return "usable." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PostUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PostUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PostUnit} PostUnit
                 */
                PostUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PostUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.PostUnit();
                    if (object.componentUnitId != null)
                        message.componentUnitId = String(object.componentUnitId);
                    if (object.postUnitCode != null)
                        message.postUnitCode = String(object.postUnitCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.postUnitType) {
                        if (!Array.isArray(object.postUnitType))
                            throw TypeError(".mtechnavi.api.company.PostUnit.postUnitType: array expected");
                        message.postUnitType = [];
                        for (let i = 0; i < object.postUnitType.length; ++i) {
                            if (typeof object.postUnitType[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.PostUnit.postUnitType: object expected");
                            message.postUnitType[i] = $root.sharelib.NameOption.fromObject(object.postUnitType[i]);
                        }
                    }
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.componentUnitCategory != null)
                        message.componentUnitCategory = String(object.componentUnitCategory);
                    if (object.linkageItem) {
                        if (!Array.isArray(object.linkageItem))
                            throw TypeError(".mtechnavi.api.company.PostUnit.linkageItem: array expected");
                        message.linkageItem = [];
                        for (let i = 0; i < object.linkageItem.length; ++i)
                            message.linkageItem[i] = String(object.linkageItem[i]);
                    }
                    if (object.usable != null) {
                        if (typeof object.usable !== "object")
                            throw TypeError(".mtechnavi.api.company.PostUnit.usable: object expected");
                        message.usable = $root.sharelib.NameOption.fromObject(object.usable);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PostUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PostUnit
                 * @static
                 * @param {mtechnavi.api.company.PostUnit} message PostUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.postUnitType = [];
                        object.linkageItem = [];
                    }
                    if (options.defaults) {
                        object.componentUnitId = "";
                        object.postUnitCode = "";
                        object.displayName = "";
                        object.remarks = "";
                        object.componentUnitCategory = "";
                        object.usable = null;
                    }
                    if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                        object.componentUnitId = message.componentUnitId;
                    if (message.postUnitCode != null && message.hasOwnProperty("postUnitCode"))
                        object.postUnitCode = message.postUnitCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.postUnitType && message.postUnitType.length) {
                        object.postUnitType = [];
                        for (let j = 0; j < message.postUnitType.length; ++j)
                            object.postUnitType[j] = $root.sharelib.NameOption.toObject(message.postUnitType[j], options);
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.componentUnitCategory != null && message.hasOwnProperty("componentUnitCategory"))
                        object.componentUnitCategory = message.componentUnitCategory;
                    if (message.linkageItem && message.linkageItem.length) {
                        object.linkageItem = [];
                        for (let j = 0; j < message.linkageItem.length; ++j)
                            object.linkageItem[j] = message.linkageItem[j];
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = $root.sharelib.NameOption.toObject(message.usable, options);
                    return object;
                };

                /**
                 * Converts this PostUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PostUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PostUnit;
            })();

            company.Process = (function() {

                /**
                 * Properties of a Process.
                 * @memberof mtechnavi.api.company
                 * @interface IProcess
                 * @property {Long|null} [processedAt] Process processedAt
                 * @property {sharelib.IEmbeddedUser|null} [processedBy] Process processedBy
                 * @property {string|null} [processedComment] Process processedComment
                 */

                /**
                 * Constructs a new Process.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a Process.
                 * @implements IProcess
                 * @constructor
                 * @param {mtechnavi.api.company.IProcess=} [properties] Properties to set
                 */
                function Process(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Process processedAt.
                 * @member {Long} processedAt
                 * @memberof mtechnavi.api.company.Process
                 * @instance
                 */
                Process.prototype.processedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Process processedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} processedBy
                 * @memberof mtechnavi.api.company.Process
                 * @instance
                 */
                Process.prototype.processedBy = null;

                /**
                 * Process processedComment.
                 * @member {string} processedComment
                 * @memberof mtechnavi.api.company.Process
                 * @instance
                 */
                Process.prototype.processedComment = "";

                /**
                 * Verifies a Process message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Process
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Process.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (!$util.isInteger(message.processedAt) && !(message.processedAt && $util.isInteger(message.processedAt.low) && $util.isInteger(message.processedAt.high)))
                            return "processedAt: integer|Long expected";
                    if (message.processedBy != null && message.hasOwnProperty("processedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.processedBy);
                        if (error)
                            return "processedBy." + error;
                    }
                    if (message.processedComment != null && message.hasOwnProperty("processedComment"))
                        if (!$util.isString(message.processedComment))
                            return "processedComment: string expected";
                    return null;
                };

                /**
                 * Creates a Process message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Process
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Process} Process
                 */
                Process.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Process)
                        return object;
                    let message = new $root.mtechnavi.api.company.Process();
                    if (object.processedAt != null)
                        if ($util.Long)
                            (message.processedAt = $util.Long.fromValue(object.processedAt)).unsigned = false;
                        else if (typeof object.processedAt === "string")
                            message.processedAt = parseInt(object.processedAt, 10);
                        else if (typeof object.processedAt === "number")
                            message.processedAt = object.processedAt;
                        else if (typeof object.processedAt === "object")
                            message.processedAt = new $util.LongBits(object.processedAt.low >>> 0, object.processedAt.high >>> 0).toNumber();
                    if (object.processedBy != null) {
                        if (typeof object.processedBy !== "object")
                            throw TypeError(".mtechnavi.api.company.Process.processedBy: object expected");
                        message.processedBy = $root.sharelib.EmbeddedUser.fromObject(object.processedBy);
                    }
                    if (object.processedComment != null)
                        message.processedComment = String(object.processedComment);
                    return message;
                };

                /**
                 * Creates a plain object from a Process message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Process
                 * @static
                 * @param {mtechnavi.api.company.Process} message Process
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Process.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.processedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processedAt = options.longs === String ? "0" : 0;
                        object.processedBy = null;
                        object.processedComment = "";
                    }
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (typeof message.processedAt === "number")
                            object.processedAt = options.longs === String ? String(message.processedAt) : message.processedAt;
                        else
                            object.processedAt = options.longs === String ? $util.Long.prototype.toString.call(message.processedAt) : options.longs === Number ? new $util.LongBits(message.processedAt.low >>> 0, message.processedAt.high >>> 0).toNumber() : message.processedAt;
                    if (message.processedBy != null && message.hasOwnProperty("processedBy"))
                        object.processedBy = $root.sharelib.EmbeddedUser.toObject(message.processedBy, options);
                    if (message.processedComment != null && message.hasOwnProperty("processedComment"))
                        object.processedComment = message.processedComment;
                    return object;
                };

                /**
                 * Converts this Process to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Process
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Process.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Process;
            })();

            company.Address = (function() {

                /**
                 * Properties of an Address.
                 * @memberof mtechnavi.api.company
                 * @interface IAddress
                 * @property {sharelib.INameOption|null} [country] Address country
                 * @property {string|null} [postalCode] Address postalCode
                 * @property {sharelib.INameOption|null} [region] Address region
                 * @property {Array.<string>|null} [addressLines] Address addressLines
                 */

                /**
                 * Constructs a new Address.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an Address.
                 * @implements IAddress
                 * @constructor
                 * @param {mtechnavi.api.company.IAddress=} [properties] Properties to set
                 */
                function Address(properties) {
                    this.addressLines = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Address country.
                 * @member {sharelib.INameOption|null|undefined} country
                 * @memberof mtechnavi.api.company.Address
                 * @instance
                 */
                Address.prototype.country = null;

                /**
                 * Address postalCode.
                 * @member {string} postalCode
                 * @memberof mtechnavi.api.company.Address
                 * @instance
                 */
                Address.prototype.postalCode = "";

                /**
                 * Address region.
                 * @member {sharelib.INameOption|null|undefined} region
                 * @memberof mtechnavi.api.company.Address
                 * @instance
                 */
                Address.prototype.region = null;

                /**
                 * Address addressLines.
                 * @member {Array.<string>} addressLines
                 * @memberof mtechnavi.api.company.Address
                 * @instance
                 */
                Address.prototype.addressLines = $util.emptyArray;

                /**
                 * Verifies an Address message.
                 * @function verify
                 * @memberof mtechnavi.api.company.Address
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Address.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.country != null && message.hasOwnProperty("country")) {
                        let error = $root.sharelib.NameOption.verify(message.country);
                        if (error)
                            return "country." + error;
                    }
                    if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                        if (!$util.isString(message.postalCode))
                            return "postalCode: string expected";
                    if (message.region != null && message.hasOwnProperty("region")) {
                        let error = $root.sharelib.NameOption.verify(message.region);
                        if (error)
                            return "region." + error;
                    }
                    if (message.addressLines != null && message.hasOwnProperty("addressLines")) {
                        if (!Array.isArray(message.addressLines))
                            return "addressLines: array expected";
                        for (let i = 0; i < message.addressLines.length; ++i)
                            if (!$util.isString(message.addressLines[i]))
                                return "addressLines: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates an Address message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.Address
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.Address} Address
                 */
                Address.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.Address)
                        return object;
                    let message = new $root.mtechnavi.api.company.Address();
                    if (object.country != null) {
                        if (typeof object.country !== "object")
                            throw TypeError(".mtechnavi.api.company.Address.country: object expected");
                        message.country = $root.sharelib.NameOption.fromObject(object.country);
                    }
                    if (object.postalCode != null)
                        message.postalCode = String(object.postalCode);
                    if (object.region != null) {
                        if (typeof object.region !== "object")
                            throw TypeError(".mtechnavi.api.company.Address.region: object expected");
                        message.region = $root.sharelib.NameOption.fromObject(object.region);
                    }
                    if (object.addressLines) {
                        if (!Array.isArray(object.addressLines))
                            throw TypeError(".mtechnavi.api.company.Address.addressLines: array expected");
                        message.addressLines = [];
                        for (let i = 0; i < object.addressLines.length; ++i)
                            message.addressLines[i] = String(object.addressLines[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Address message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.Address
                 * @static
                 * @param {mtechnavi.api.company.Address} message Address
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Address.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.addressLines = [];
                    if (options.defaults) {
                        object.country = null;
                        object.postalCode = "";
                        object.region = null;
                    }
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = $root.sharelib.NameOption.toObject(message.country, options);
                    if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                        object.postalCode = message.postalCode;
                    if (message.region != null && message.hasOwnProperty("region"))
                        object.region = $root.sharelib.NameOption.toObject(message.region, options);
                    if (message.addressLines && message.addressLines.length) {
                        object.addressLines = [];
                        for (let j = 0; j < message.addressLines.length; ++j)
                            object.addressLines[j] = message.addressLines[j];
                    }
                    return object;
                };

                /**
                 * Converts this Address to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.Address
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Address.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Address;
            })();

            company.CompanyProfile = (function() {

                /**
                 * Properties of a CompanyProfile.
                 * @memberof mtechnavi.api.company
                 * @interface ICompanyProfile
                 * @property {Object.<string,string>|null} [displayNameLang] CompanyProfile displayNameLang
                 * @property {mtechnavi.api.company.ICompanyLegalProperties|null} [companyLegalProperties] CompanyProfile companyLegalProperties
                 * @property {string|null} [websiteUrl] CompanyProfile websiteUrl
                 * @property {mtechnavi.api.company.CompanyProfile.IRepresentative|null} [representative] CompanyProfile representative
                 * @property {mtechnavi.api.company.IAddress|null} [address] CompanyProfile address
                 * @property {string|null} [phoneNumber] CompanyProfile phoneNumber
                 * @property {mtechnavi.api.company.CompanyProfile.IEmployees|null} [employees] CompanyProfile employees
                 * @property {sharelib.IMonetaryAmount|null} [capitalStock] CompanyProfile capitalStock
                 * @property {string|null} [mainShareholder] CompanyProfile mainShareholder
                 * @property {string|null} [mainBank] CompanyProfile mainBank
                 * @property {string|null} [mainSupplier] CompanyProfile mainSupplier
                 * @property {string|null} [mainCustomer] CompanyProfile mainCustomer
                 * @property {string|null} [mainBusiness] CompanyProfile mainBusiness
                 */

                /**
                 * Constructs a new CompanyProfile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyProfile.
                 * @implements ICompanyProfile
                 * @constructor
                 * @param {mtechnavi.api.company.ICompanyProfile=} [properties] Properties to set
                 */
                function CompanyProfile(properties) {
                    this.displayNameLang = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompanyProfile displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.displayNameLang = $util.emptyObject;

                /**
                 * CompanyProfile companyLegalProperties.
                 * @member {mtechnavi.api.company.ICompanyLegalProperties|null|undefined} companyLegalProperties
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.companyLegalProperties = null;

                /**
                 * CompanyProfile websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.websiteUrl = "";

                /**
                 * CompanyProfile representative.
                 * @member {mtechnavi.api.company.CompanyProfile.IRepresentative|null|undefined} representative
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.representative = null;

                /**
                 * CompanyProfile address.
                 * @member {mtechnavi.api.company.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.address = null;

                /**
                 * CompanyProfile phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.phoneNumber = "";

                /**
                 * CompanyProfile employees.
                 * @member {mtechnavi.api.company.CompanyProfile.IEmployees|null|undefined} employees
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.employees = null;

                /**
                 * CompanyProfile capitalStock.
                 * @member {sharelib.IMonetaryAmount|null|undefined} capitalStock
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.capitalStock = null;

                /**
                 * CompanyProfile mainShareholder.
                 * @member {string} mainShareholder
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.mainShareholder = "";

                /**
                 * CompanyProfile mainBank.
                 * @member {string} mainBank
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.mainBank = "";

                /**
                 * CompanyProfile mainSupplier.
                 * @member {string} mainSupplier
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.mainSupplier = "";

                /**
                 * CompanyProfile mainCustomer.
                 * @member {string} mainCustomer
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.mainCustomer = "";

                /**
                 * CompanyProfile mainBusiness.
                 * @member {string} mainBusiness
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 */
                CompanyProfile.prototype.mainBusiness = "";

                /**
                 * Verifies a CompanyProfile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompanyProfile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties")) {
                        let error = $root.mtechnavi.api.company.CompanyLegalProperties.verify(message.companyLegalProperties);
                        if (error)
                            return "companyLegalProperties." + error;
                    }
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.representative != null && message.hasOwnProperty("representative")) {
                        let error = $root.mtechnavi.api.company.CompanyProfile.Representative.verify(message.representative);
                        if (error)
                            return "representative." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.employees != null && message.hasOwnProperty("employees")) {
                        let error = $root.mtechnavi.api.company.CompanyProfile.Employees.verify(message.employees);
                        if (error)
                            return "employees." + error;
                    }
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock")) {
                        let error = $root.sharelib.MonetaryAmount.verify(message.capitalStock);
                        if (error)
                            return "capitalStock." + error;
                    }
                    if (message.mainShareholder != null && message.hasOwnProperty("mainShareholder"))
                        if (!$util.isString(message.mainShareholder))
                            return "mainShareholder: string expected";
                    if (message.mainBank != null && message.hasOwnProperty("mainBank"))
                        if (!$util.isString(message.mainBank))
                            return "mainBank: string expected";
                    if (message.mainSupplier != null && message.hasOwnProperty("mainSupplier"))
                        if (!$util.isString(message.mainSupplier))
                            return "mainSupplier: string expected";
                    if (message.mainCustomer != null && message.hasOwnProperty("mainCustomer"))
                        if (!$util.isString(message.mainCustomer))
                            return "mainCustomer: string expected";
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        if (!$util.isString(message.mainBusiness))
                            return "mainBusiness: string expected";
                    return null;
                };

                /**
                 * Creates a CompanyProfile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CompanyProfile} CompanyProfile
                 */
                CompanyProfile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CompanyProfile)
                        return object;
                    let message = new $root.mtechnavi.api.company.CompanyProfile();
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.companyLegalProperties != null) {
                        if (typeof object.companyLegalProperties !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.companyLegalProperties: object expected");
                        message.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.fromObject(object.companyLegalProperties);
                    }
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.representative != null) {
                        if (typeof object.representative !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.representative: object expected");
                        message.representative = $root.mtechnavi.api.company.CompanyProfile.Representative.fromObject(object.representative);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.address: object expected");
                        message.address = $root.mtechnavi.api.company.Address.fromObject(object.address);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.employees != null) {
                        if (typeof object.employees !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.employees: object expected");
                        message.employees = $root.mtechnavi.api.company.CompanyProfile.Employees.fromObject(object.employees);
                    }
                    if (object.capitalStock != null) {
                        if (typeof object.capitalStock !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyProfile.capitalStock: object expected");
                        message.capitalStock = $root.sharelib.MonetaryAmount.fromObject(object.capitalStock);
                    }
                    if (object.mainShareholder != null)
                        message.mainShareholder = String(object.mainShareholder);
                    if (object.mainBank != null)
                        message.mainBank = String(object.mainBank);
                    if (object.mainSupplier != null)
                        message.mainSupplier = String(object.mainSupplier);
                    if (object.mainCustomer != null)
                        message.mainCustomer = String(object.mainCustomer);
                    if (object.mainBusiness != null)
                        message.mainBusiness = String(object.mainBusiness);
                    return message;
                };

                /**
                 * Creates a plain object from a CompanyProfile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @static
                 * @param {mtechnavi.api.company.CompanyProfile} message CompanyProfile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyProfile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.companyLegalProperties = null;
                        object.websiteUrl = "";
                        object.representative = null;
                        object.address = null;
                        object.phoneNumber = "";
                        object.employees = null;
                        object.capitalStock = null;
                        object.mainShareholder = "";
                        object.mainBank = "";
                        object.mainSupplier = "";
                        object.mainCustomer = "";
                        object.mainBusiness = "";
                    }
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.companyLegalProperties != null && message.hasOwnProperty("companyLegalProperties"))
                        object.companyLegalProperties = $root.mtechnavi.api.company.CompanyLegalProperties.toObject(message.companyLegalProperties, options);
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.representative != null && message.hasOwnProperty("representative"))
                        object.representative = $root.mtechnavi.api.company.CompanyProfile.Representative.toObject(message.representative, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.Address.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.employees != null && message.hasOwnProperty("employees"))
                        object.employees = $root.mtechnavi.api.company.CompanyProfile.Employees.toObject(message.employees, options);
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock"))
                        object.capitalStock = $root.sharelib.MonetaryAmount.toObject(message.capitalStock, options);
                    if (message.mainShareholder != null && message.hasOwnProperty("mainShareholder"))
                        object.mainShareholder = message.mainShareholder;
                    if (message.mainBank != null && message.hasOwnProperty("mainBank"))
                        object.mainBank = message.mainBank;
                    if (message.mainSupplier != null && message.hasOwnProperty("mainSupplier"))
                        object.mainSupplier = message.mainSupplier;
                    if (message.mainCustomer != null && message.hasOwnProperty("mainCustomer"))
                        object.mainCustomer = message.mainCustomer;
                    if (message.mainBusiness != null && message.hasOwnProperty("mainBusiness"))
                        object.mainBusiness = message.mainBusiness;
                    return object;
                };

                /**
                 * Converts this CompanyProfile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CompanyProfile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyProfile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CompanyProfile.Representative = (function() {

                    /**
                     * Properties of a Representative.
                     * @memberof mtechnavi.api.company.CompanyProfile
                     * @interface IRepresentative
                     * @property {Object.<string,string>|null} [displayNameLang] Representative displayNameLang
                     * @property {string|null} [position] Representative position
                     * @property {sharelib.IDate|null} [birthDate] Representative birthDate
                     */

                    /**
                     * Constructs a new Representative.
                     * @memberof mtechnavi.api.company.CompanyProfile
                     * @classdesc Represents a Representative.
                     * @implements IRepresentative
                     * @constructor
                     * @param {mtechnavi.api.company.CompanyProfile.IRepresentative=} [properties] Properties to set
                     */
                    function Representative(properties) {
                        this.displayNameLang = {};
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Representative displayNameLang.
                     * @member {Object.<string,string>} displayNameLang
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @instance
                     */
                    Representative.prototype.displayNameLang = $util.emptyObject;

                    /**
                     * Representative position.
                     * @member {string} position
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @instance
                     */
                    Representative.prototype.position = "";

                    /**
                     * Representative birthDate.
                     * @member {sharelib.IDate|null|undefined} birthDate
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @instance
                     */
                    Representative.prototype.birthDate = null;

                    /**
                     * Verifies a Representative message.
                     * @function verify
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Representative.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                            if (!$util.isObject(message.displayNameLang))
                                return "displayNameLang: object expected";
                            let key = Object.keys(message.displayNameLang);
                            for (let i = 0; i < key.length; ++i)
                                if (!$util.isString(message.displayNameLang[key[i]]))
                                    return "displayNameLang: string{k:string} expected";
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        if (message.birthDate != null && message.hasOwnProperty("birthDate")) {
                            let error = $root.sharelib.Date.verify(message.birthDate);
                            if (error)
                                return "birthDate." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Representative message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.CompanyProfile.Representative} Representative
                     */
                    Representative.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.CompanyProfile.Representative)
                            return object;
                        let message = new $root.mtechnavi.api.company.CompanyProfile.Representative();
                        if (object.displayNameLang) {
                            if (typeof object.displayNameLang !== "object")
                                throw TypeError(".mtechnavi.api.company.CompanyProfile.Representative.displayNameLang: object expected");
                            message.displayNameLang = {};
                            for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                        }
                        if (object.position != null)
                            message.position = String(object.position);
                        if (object.birthDate != null) {
                            if (typeof object.birthDate !== "object")
                                throw TypeError(".mtechnavi.api.company.CompanyProfile.Representative.birthDate: object expected");
                            message.birthDate = $root.sharelib.Date.fromObject(object.birthDate);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Representative message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @static
                     * @param {mtechnavi.api.company.CompanyProfile.Representative} message Representative
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Representative.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.objects || options.defaults)
                            object.displayNameLang = {};
                        if (options.defaults) {
                            object.position = "";
                            object.birthDate = null;
                        }
                        let keys2;
                        if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                            object.displayNameLang = {};
                            for (let j = 0; j < keys2.length; ++j)
                                object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        if (message.birthDate != null && message.hasOwnProperty("birthDate"))
                            object.birthDate = $root.sharelib.Date.toObject(message.birthDate, options);
                        return object;
                    };

                    /**
                     * Converts this Representative to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.CompanyProfile.Representative
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Representative.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Representative;
                })();

                CompanyProfile.Employees = (function() {

                    /**
                     * Properties of an Employees.
                     * @memberof mtechnavi.api.company.CompanyProfile
                     * @interface IEmployees
                     * @property {Long|null} [size] Employees size
                     * @property {sharelib.IAmount|null} [averageAge] Employees averageAge
                     */

                    /**
                     * Constructs a new Employees.
                     * @memberof mtechnavi.api.company.CompanyProfile
                     * @classdesc Represents an Employees.
                     * @implements IEmployees
                     * @constructor
                     * @param {mtechnavi.api.company.CompanyProfile.IEmployees=} [properties] Properties to set
                     */
                    function Employees(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Employees size.
                     * @member {Long} size
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @instance
                     */
                    Employees.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Employees averageAge.
                     * @member {sharelib.IAmount|null|undefined} averageAge
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @instance
                     */
                    Employees.prototype.averageAge = null;

                    /**
                     * Verifies an Employees message.
                     * @function verify
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Employees.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                                return "size: integer|Long expected";
                        if (message.averageAge != null && message.hasOwnProperty("averageAge")) {
                            let error = $root.sharelib.Amount.verify(message.averageAge);
                            if (error)
                                return "averageAge." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an Employees message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.company.CompanyProfile.Employees} Employees
                     */
                    Employees.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.company.CompanyProfile.Employees)
                            return object;
                        let message = new $root.mtechnavi.api.company.CompanyProfile.Employees();
                        if (object.size != null)
                            if ($util.Long)
                                (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                            else if (typeof object.size === "string")
                                message.size = parseInt(object.size, 10);
                            else if (typeof object.size === "number")
                                message.size = object.size;
                            else if (typeof object.size === "object")
                                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
                        if (object.averageAge != null) {
                            if (typeof object.averageAge !== "object")
                                throw TypeError(".mtechnavi.api.company.CompanyProfile.Employees.averageAge: object expected");
                            message.averageAge = $root.sharelib.Amount.fromObject(object.averageAge);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Employees message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @static
                     * @param {mtechnavi.api.company.CompanyProfile.Employees} message Employees
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Employees.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.size = options.longs === String ? "0" : 0;
                            object.averageAge = null;
                        }
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (typeof message.size === "number")
                                object.size = options.longs === String ? String(message.size) : message.size;
                            else
                                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
                        if (message.averageAge != null && message.hasOwnProperty("averageAge"))
                            object.averageAge = $root.sharelib.Amount.toObject(message.averageAge, options);
                        return object;
                    };

                    /**
                     * Converts this Employees to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.company.CompanyProfile.Employees
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Employees.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Employees;
                })();

                return CompanyProfile;
            })();

            company.ItemValue = (function() {

                /**
                 * Properties of an ItemValue.
                 * @memberof mtechnavi.api.company
                 * @interface IItemValue
                 * @property {string|null} [itemKey] ItemValue itemKey
                 * @property {string|null} [itemValue] ItemValue itemValue
                 * @property {string|null} [code] ItemValue code
                 */

                /**
                 * Constructs a new ItemValue.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents an ItemValue.
                 * @implements IItemValue
                 * @constructor
                 * @param {mtechnavi.api.company.IItemValue=} [properties] Properties to set
                 */
                function ItemValue(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemValue itemKey.
                 * @member {string} itemKey
                 * @memberof mtechnavi.api.company.ItemValue
                 * @instance
                 */
                ItemValue.prototype.itemKey = "";

                /**
                 * ItemValue itemValue.
                 * @member {string} itemValue
                 * @memberof mtechnavi.api.company.ItemValue
                 * @instance
                 */
                ItemValue.prototype.itemValue = "";

                /**
                 * ItemValue code.
                 * @member {string} code
                 * @memberof mtechnavi.api.company.ItemValue
                 * @instance
                 */
                ItemValue.prototype.code = "";

                /**
                 * Verifies an ItemValue message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ItemValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.itemKey != null && message.hasOwnProperty("itemKey"))
                        if (!$util.isString(message.itemKey))
                            return "itemKey: string expected";
                    if (message.itemValue != null && message.hasOwnProperty("itemValue"))
                        if (!$util.isString(message.itemValue))
                            return "itemValue: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    return null;
                };

                /**
                 * Creates an ItemValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ItemValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ItemValue} ItemValue
                 */
                ItemValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ItemValue)
                        return object;
                    let message = new $root.mtechnavi.api.company.ItemValue();
                    if (object.itemKey != null)
                        message.itemKey = String(object.itemKey);
                    if (object.itemValue != null)
                        message.itemValue = String(object.itemValue);
                    if (object.code != null)
                        message.code = String(object.code);
                    return message;
                };

                /**
                 * Creates a plain object from an ItemValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ItemValue
                 * @static
                 * @param {mtechnavi.api.company.ItemValue} message ItemValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.itemKey = "";
                        object.itemValue = "";
                        object.code = "";
                    }
                    if (message.itemKey != null && message.hasOwnProperty("itemKey"))
                        object.itemKey = message.itemKey;
                    if (message.itemValue != null && message.hasOwnProperty("itemValue"))
                        object.itemValue = message.itemValue;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    return object;
                };

                /**
                 * Converts this ItemValue to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ItemValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ItemValue;
            })();

            company.ComparisonItem = (function() {

                /**
                 * Properties of a ComparisonItem.
                 * @memberof mtechnavi.api.company
                 * @interface IComparisonItem
                 * @property {string|null} [value] ComparisonItem value
                 * @property {string|null} [displayName] ComparisonItem displayName
                 */

                /**
                 * Constructs a new ComparisonItem.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a ComparisonItem.
                 * @implements IComparisonItem
                 * @constructor
                 * @param {mtechnavi.api.company.IComparisonItem=} [properties] Properties to set
                 */
                function ComparisonItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComparisonItem value.
                 * @member {string} value
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @instance
                 */
                ComparisonItem.prototype.value = "";

                /**
                 * ComparisonItem displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @instance
                 */
                ComparisonItem.prototype.displayName = "";

                /**
                 * Verifies a ComparisonItem message.
                 * @function verify
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComparisonItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a ComparisonItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.ComparisonItem} ComparisonItem
                 */
                ComparisonItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.ComparisonItem)
                        return object;
                    let message = new $root.mtechnavi.api.company.ComparisonItem();
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a ComparisonItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @static
                 * @param {mtechnavi.api.company.ComparisonItem} message ComparisonItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComparisonItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.value = "";
                        object.displayName = "";
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this ComparisonItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.ComparisonItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComparisonItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ComparisonItem;
            })();

            company.DiffItemBusinessUnitProfile = (function() {

                /**
                 * Properties of a DiffItemBusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @interface IDiffItemBusinessUnitProfile
                 * @property {string|null} [keyValue] DiffItemBusinessUnitProfile keyValue
                 * @property {mtechnavi.api.company.IComparisonItem|null} [displayNameLangJa] DiffItemBusinessUnitProfile displayNameLangJa
                 * @property {mtechnavi.api.company.IComparisonItem|null} [companyNumber] DiffItemBusinessUnitProfile companyNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [representativeDisplayNameLangJa] DiffItemBusinessUnitProfile representativeDisplayNameLangJa
                 * @property {mtechnavi.api.company.IComparisonItem|null} [postalCode] DiffItemBusinessUnitProfile postalCode
                 * @property {mtechnavi.api.company.IComparisonItem|null} [address] DiffItemBusinessUnitProfile address
                 * @property {mtechnavi.api.company.IComparisonItem|null} [capitalStock] DiffItemBusinessUnitProfile capitalStock
                 */

                /**
                 * Constructs a new DiffItemBusinessUnitProfile.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DiffItemBusinessUnitProfile.
                 * @implements IDiffItemBusinessUnitProfile
                 * @constructor
                 * @param {mtechnavi.api.company.IDiffItemBusinessUnitProfile=} [properties] Properties to set
                 */
                function DiffItemBusinessUnitProfile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiffItemBusinessUnitProfile keyValue.
                 * @member {string} keyValue
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.keyValue = "";

                /**
                 * DiffItemBusinessUnitProfile displayNameLangJa.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} displayNameLangJa
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.displayNameLangJa = null;

                /**
                 * DiffItemBusinessUnitProfile companyNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} companyNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.companyNumber = null;

                /**
                 * DiffItemBusinessUnitProfile representativeDisplayNameLangJa.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} representativeDisplayNameLangJa
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.representativeDisplayNameLangJa = null;

                /**
                 * DiffItemBusinessUnitProfile postalCode.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} postalCode
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.postalCode = null;

                /**
                 * DiffItemBusinessUnitProfile address.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} address
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.address = null;

                /**
                 * DiffItemBusinessUnitProfile capitalStock.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} capitalStock
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 */
                DiffItemBusinessUnitProfile.prototype.capitalStock = null;

                /**
                 * Verifies a DiffItemBusinessUnitProfile message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiffItemBusinessUnitProfile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        if (!$util.isString(message.keyValue))
                            return "keyValue: string expected";
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.displayNameLangJa);
                        if (error)
                            return "displayNameLangJa." + error;
                    }
                    if (message.companyNumber != null && message.hasOwnProperty("companyNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.companyNumber);
                        if (error)
                            return "companyNumber." + error;
                    }
                    if (message.representativeDisplayNameLangJa != null && message.hasOwnProperty("representativeDisplayNameLangJa")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.representativeDisplayNameLangJa);
                        if (error)
                            return "representativeDisplayNameLangJa." + error;
                    }
                    if (message.postalCode != null && message.hasOwnProperty("postalCode")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.postalCode);
                        if (error)
                            return "postalCode." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.capitalStock);
                        if (error)
                            return "capitalStock." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiffItemBusinessUnitProfile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DiffItemBusinessUnitProfile} DiffItemBusinessUnitProfile
                 */
                DiffItemBusinessUnitProfile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DiffItemBusinessUnitProfile)
                        return object;
                    let message = new $root.mtechnavi.api.company.DiffItemBusinessUnitProfile();
                    if (object.keyValue != null)
                        message.keyValue = String(object.keyValue);
                    if (object.displayNameLangJa != null) {
                        if (typeof object.displayNameLangJa !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.displayNameLangJa: object expected");
                        message.displayNameLangJa = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.displayNameLangJa);
                    }
                    if (object.companyNumber != null) {
                        if (typeof object.companyNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.companyNumber: object expected");
                        message.companyNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.companyNumber);
                    }
                    if (object.representativeDisplayNameLangJa != null) {
                        if (typeof object.representativeDisplayNameLangJa !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.representativeDisplayNameLangJa: object expected");
                        message.representativeDisplayNameLangJa = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.representativeDisplayNameLangJa);
                    }
                    if (object.postalCode != null) {
                        if (typeof object.postalCode !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.postalCode: object expected");
                        message.postalCode = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.postalCode);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.address: object expected");
                        message.address = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.address);
                    }
                    if (object.capitalStock != null) {
                        if (typeof object.capitalStock !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitProfile.capitalStock: object expected");
                        message.capitalStock = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.capitalStock);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiffItemBusinessUnitProfile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @static
                 * @param {mtechnavi.api.company.DiffItemBusinessUnitProfile} message DiffItemBusinessUnitProfile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiffItemBusinessUnitProfile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.keyValue = "";
                        object.displayNameLangJa = null;
                        object.companyNumber = null;
                        object.representativeDisplayNameLangJa = null;
                        object.postalCode = null;
                        object.address = null;
                        object.capitalStock = null;
                    }
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        object.keyValue = message.keyValue;
                    if (message.displayNameLangJa != null && message.hasOwnProperty("displayNameLangJa"))
                        object.displayNameLangJa = $root.mtechnavi.api.company.ComparisonItem.toObject(message.displayNameLangJa, options);
                    if (message.companyNumber != null && message.hasOwnProperty("companyNumber"))
                        object.companyNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.companyNumber, options);
                    if (message.representativeDisplayNameLangJa != null && message.hasOwnProperty("representativeDisplayNameLangJa"))
                        object.representativeDisplayNameLangJa = $root.mtechnavi.api.company.ComparisonItem.toObject(message.representativeDisplayNameLangJa, options);
                    if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                        object.postalCode = $root.mtechnavi.api.company.ComparisonItem.toObject(message.postalCode, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.ComparisonItem.toObject(message.address, options);
                    if (message.capitalStock != null && message.hasOwnProperty("capitalStock"))
                        object.capitalStock = $root.mtechnavi.api.company.ComparisonItem.toObject(message.capitalStock, options);
                    return object;
                };

                /**
                 * Converts this DiffItemBusinessUnitProfile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitProfile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiffItemBusinessUnitProfile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiffItemBusinessUnitProfile;
            })();

            company.DiffItemBusinessUnitBranch = (function() {

                /**
                 * Properties of a DiffItemBusinessUnitBranch.
                 * @memberof mtechnavi.api.company
                 * @interface IDiffItemBusinessUnitBranch
                 * @property {string|null} [keyValue] DiffItemBusinessUnitBranch keyValue
                 * @property {mtechnavi.api.company.IComparisonItem|null} [address] DiffItemBusinessUnitBranch address
                 * @property {mtechnavi.api.company.IComparisonItem|null} [phoneNumber] DiffItemBusinessUnitBranch phoneNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [faxNumber] DiffItemBusinessUnitBranch faxNumber
                 */

                /**
                 * Constructs a new DiffItemBusinessUnitBranch.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DiffItemBusinessUnitBranch.
                 * @implements IDiffItemBusinessUnitBranch
                 * @constructor
                 * @param {mtechnavi.api.company.IDiffItemBusinessUnitBranch=} [properties] Properties to set
                 */
                function DiffItemBusinessUnitBranch(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiffItemBusinessUnitBranch keyValue.
                 * @member {string} keyValue
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @instance
                 */
                DiffItemBusinessUnitBranch.prototype.keyValue = "";

                /**
                 * DiffItemBusinessUnitBranch address.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} address
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @instance
                 */
                DiffItemBusinessUnitBranch.prototype.address = null;

                /**
                 * DiffItemBusinessUnitBranch phoneNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} phoneNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @instance
                 */
                DiffItemBusinessUnitBranch.prototype.phoneNumber = null;

                /**
                 * DiffItemBusinessUnitBranch faxNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} faxNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @instance
                 */
                DiffItemBusinessUnitBranch.prototype.faxNumber = null;

                /**
                 * Verifies a DiffItemBusinessUnitBranch message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiffItemBusinessUnitBranch.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        if (!$util.isString(message.keyValue))
                            return "keyValue: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.faxNumber);
                        if (error)
                            return "faxNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiffItemBusinessUnitBranch message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DiffItemBusinessUnitBranch} DiffItemBusinessUnitBranch
                 */
                DiffItemBusinessUnitBranch.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DiffItemBusinessUnitBranch)
                        return object;
                    let message = new $root.mtechnavi.api.company.DiffItemBusinessUnitBranch();
                    if (object.keyValue != null)
                        message.keyValue = String(object.keyValue);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitBranch.address: object expected");
                        message.address = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.address);
                    }
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitBranch.phoneNumber: object expected");
                        message.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.phoneNumber);
                    }
                    if (object.faxNumber != null) {
                        if (typeof object.faxNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitBranch.faxNumber: object expected");
                        message.faxNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.faxNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiffItemBusinessUnitBranch message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @static
                 * @param {mtechnavi.api.company.DiffItemBusinessUnitBranch} message DiffItemBusinessUnitBranch
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiffItemBusinessUnitBranch.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.keyValue = "";
                        object.address = null;
                        object.phoneNumber = null;
                        object.faxNumber = null;
                    }
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        object.keyValue = message.keyValue;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.ComparisonItem.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.phoneNumber, options);
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.faxNumber, options);
                    return object;
                };

                /**
                 * Converts this DiffItemBusinessUnitBranch to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitBranch
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiffItemBusinessUnitBranch.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiffItemBusinessUnitBranch;
            })();

            company.DiffItemBusinessUnitCertificate = (function() {

                /**
                 * Properties of a DiffItemBusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @interface IDiffItemBusinessUnitCertificate
                 * @property {string|null} [keyValue] DiffItemBusinessUnitCertificate keyValue
                 * @property {mtechnavi.api.company.IComparisonItem|null} [obtained] DiffItemBusinessUnitCertificate obtained
                 * @property {mtechnavi.api.company.IComparisonItem|null} [certificateNumber] DiffItemBusinessUnitCertificate certificateNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [attachment] DiffItemBusinessUnitCertificate attachment
                 * @property {mtechnavi.api.company.IComparisonItem|null} [obtainedDt] DiffItemBusinessUnitCertificate obtainedDt
                 * @property {mtechnavi.api.company.IComparisonItem|null} [expirationDt] DiffItemBusinessUnitCertificate expirationDt
                 * @property {mtechnavi.api.company.IComparisonItem|null} [obtainPlanDt] DiffItemBusinessUnitCertificate obtainPlanDt
                 * @property {mtechnavi.api.company.IComparisonItem|null} [scopeProduct] DiffItemBusinessUnitCertificate scopeProduct
                 * @property {mtechnavi.api.company.IComparisonItem|null} [scopeBranch] DiffItemBusinessUnitCertificate scopeBranch
                 */

                /**
                 * Constructs a new DiffItemBusinessUnitCertificate.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DiffItemBusinessUnitCertificate.
                 * @implements IDiffItemBusinessUnitCertificate
                 * @constructor
                 * @param {mtechnavi.api.company.IDiffItemBusinessUnitCertificate=} [properties] Properties to set
                 */
                function DiffItemBusinessUnitCertificate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiffItemBusinessUnitCertificate keyValue.
                 * @member {string} keyValue
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.keyValue = "";

                /**
                 * DiffItemBusinessUnitCertificate obtained.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} obtained
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.obtained = null;

                /**
                 * DiffItemBusinessUnitCertificate certificateNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} certificateNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.certificateNumber = null;

                /**
                 * DiffItemBusinessUnitCertificate attachment.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} attachment
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.attachment = null;

                /**
                 * DiffItemBusinessUnitCertificate obtainedDt.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} obtainedDt
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.obtainedDt = null;

                /**
                 * DiffItemBusinessUnitCertificate expirationDt.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} expirationDt
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.expirationDt = null;

                /**
                 * DiffItemBusinessUnitCertificate obtainPlanDt.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} obtainPlanDt
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.obtainPlanDt = null;

                /**
                 * DiffItemBusinessUnitCertificate scopeProduct.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} scopeProduct
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.scopeProduct = null;

                /**
                 * DiffItemBusinessUnitCertificate scopeBranch.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} scopeBranch
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 */
                DiffItemBusinessUnitCertificate.prototype.scopeBranch = null;

                /**
                 * Verifies a DiffItemBusinessUnitCertificate message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiffItemBusinessUnitCertificate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        if (!$util.isString(message.keyValue))
                            return "keyValue: string expected";
                    if (message.obtained != null && message.hasOwnProperty("obtained")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.obtained);
                        if (error)
                            return "obtained." + error;
                    }
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.certificateNumber);
                        if (error)
                            return "certificateNumber." + error;
                    }
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.obtainedDt);
                        if (error)
                            return "obtainedDt." + error;
                    }
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.expirationDt);
                        if (error)
                            return "expirationDt." + error;
                    }
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.obtainPlanDt);
                        if (error)
                            return "obtainPlanDt." + error;
                    }
                    if (message.scopeProduct != null && message.hasOwnProperty("scopeProduct")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.scopeProduct);
                        if (error)
                            return "scopeProduct." + error;
                    }
                    if (message.scopeBranch != null && message.hasOwnProperty("scopeBranch")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.scopeBranch);
                        if (error)
                            return "scopeBranch." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiffItemBusinessUnitCertificate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DiffItemBusinessUnitCertificate} DiffItemBusinessUnitCertificate
                 */
                DiffItemBusinessUnitCertificate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DiffItemBusinessUnitCertificate)
                        return object;
                    let message = new $root.mtechnavi.api.company.DiffItemBusinessUnitCertificate();
                    if (object.keyValue != null)
                        message.keyValue = String(object.keyValue);
                    if (object.obtained != null) {
                        if (typeof object.obtained !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.obtained: object expected");
                        message.obtained = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.obtained);
                    }
                    if (object.certificateNumber != null) {
                        if (typeof object.certificateNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.certificateNumber: object expected");
                        message.certificateNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.certificateNumber);
                    }
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.attachment: object expected");
                        message.attachment = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.attachment);
                    }
                    if (object.obtainedDt != null) {
                        if (typeof object.obtainedDt !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.obtainedDt: object expected");
                        message.obtainedDt = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.obtainedDt);
                    }
                    if (object.expirationDt != null) {
                        if (typeof object.expirationDt !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.expirationDt: object expected");
                        message.expirationDt = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.expirationDt);
                    }
                    if (object.obtainPlanDt != null) {
                        if (typeof object.obtainPlanDt !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.obtainPlanDt: object expected");
                        message.obtainPlanDt = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.obtainPlanDt);
                    }
                    if (object.scopeProduct != null) {
                        if (typeof object.scopeProduct !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.scopeProduct: object expected");
                        message.scopeProduct = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.scopeProduct);
                    }
                    if (object.scopeBranch != null) {
                        if (typeof object.scopeBranch !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitCertificate.scopeBranch: object expected");
                        message.scopeBranch = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.scopeBranch);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiffItemBusinessUnitCertificate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @static
                 * @param {mtechnavi.api.company.DiffItemBusinessUnitCertificate} message DiffItemBusinessUnitCertificate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiffItemBusinessUnitCertificate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.keyValue = "";
                        object.obtained = null;
                        object.certificateNumber = null;
                        object.attachment = null;
                        object.obtainedDt = null;
                        object.expirationDt = null;
                        object.obtainPlanDt = null;
                        object.scopeProduct = null;
                        object.scopeBranch = null;
                    }
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        object.keyValue = message.keyValue;
                    if (message.obtained != null && message.hasOwnProperty("obtained"))
                        object.obtained = $root.mtechnavi.api.company.ComparisonItem.toObject(message.obtained, options);
                    if (message.certificateNumber != null && message.hasOwnProperty("certificateNumber"))
                        object.certificateNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.certificateNumber, options);
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.mtechnavi.api.company.ComparisonItem.toObject(message.attachment, options);
                    if (message.obtainedDt != null && message.hasOwnProperty("obtainedDt"))
                        object.obtainedDt = $root.mtechnavi.api.company.ComparisonItem.toObject(message.obtainedDt, options);
                    if (message.expirationDt != null && message.hasOwnProperty("expirationDt"))
                        object.expirationDt = $root.mtechnavi.api.company.ComparisonItem.toObject(message.expirationDt, options);
                    if (message.obtainPlanDt != null && message.hasOwnProperty("obtainPlanDt"))
                        object.obtainPlanDt = $root.mtechnavi.api.company.ComparisonItem.toObject(message.obtainPlanDt, options);
                    if (message.scopeProduct != null && message.hasOwnProperty("scopeProduct"))
                        object.scopeProduct = $root.mtechnavi.api.company.ComparisonItem.toObject(message.scopeProduct, options);
                    if (message.scopeBranch != null && message.hasOwnProperty("scopeBranch"))
                        object.scopeBranch = $root.mtechnavi.api.company.ComparisonItem.toObject(message.scopeBranch, options);
                    return object;
                };

                /**
                 * Converts this DiffItemBusinessUnitCertificate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitCertificate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiffItemBusinessUnitCertificate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiffItemBusinessUnitCertificate;
            })();

            company.DiffItemBusinessUnitContact = (function() {

                /**
                 * Properties of a DiffItemBusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @interface IDiffItemBusinessUnitContact
                 * @property {string|null} [keyValue] DiffItemBusinessUnitContact keyValue
                 * @property {mtechnavi.api.company.IComparisonItem|null} [businessUnitBranchDisplayName] DiffItemBusinessUnitContact businessUnitBranchDisplayName
                 * @property {mtechnavi.api.company.IComparisonItem|null} [address] DiffItemBusinessUnitContact address
                 * @property {mtechnavi.api.company.IComparisonItem|null} [phoneNumber] DiffItemBusinessUnitContact phoneNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [faxNumber] DiffItemBusinessUnitContact faxNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [alias] DiffItemBusinessUnitContact alias
                 * @property {mtechnavi.api.company.IComparisonItem|null} [notificationUsers] DiffItemBusinessUnitContact notificationUsers
                 */

                /**
                 * Constructs a new DiffItemBusinessUnitContact.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DiffItemBusinessUnitContact.
                 * @implements IDiffItemBusinessUnitContact
                 * @constructor
                 * @param {mtechnavi.api.company.IDiffItemBusinessUnitContact=} [properties] Properties to set
                 */
                function DiffItemBusinessUnitContact(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiffItemBusinessUnitContact keyValue.
                 * @member {string} keyValue
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.keyValue = "";

                /**
                 * DiffItemBusinessUnitContact businessUnitBranchDisplayName.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} businessUnitBranchDisplayName
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.businessUnitBranchDisplayName = null;

                /**
                 * DiffItemBusinessUnitContact address.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} address
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.address = null;

                /**
                 * DiffItemBusinessUnitContact phoneNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} phoneNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.phoneNumber = null;

                /**
                 * DiffItemBusinessUnitContact faxNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} faxNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.faxNumber = null;

                /**
                 * DiffItemBusinessUnitContact alias.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} alias
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.alias = null;

                /**
                 * DiffItemBusinessUnitContact notificationUsers.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} notificationUsers
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 */
                DiffItemBusinessUnitContact.prototype.notificationUsers = null;

                /**
                 * Verifies a DiffItemBusinessUnitContact message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiffItemBusinessUnitContact.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        if (!$util.isString(message.keyValue))
                            return "keyValue: string expected";
                    if (message.businessUnitBranchDisplayName != null && message.hasOwnProperty("businessUnitBranchDisplayName")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.businessUnitBranchDisplayName);
                        if (error)
                            return "businessUnitBranchDisplayName." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.faxNumber);
                        if (error)
                            return "faxNumber." + error;
                    }
                    if (message.alias != null && message.hasOwnProperty("alias")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.alias);
                        if (error)
                            return "alias." + error;
                    }
                    if (message.notificationUsers != null && message.hasOwnProperty("notificationUsers")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.notificationUsers);
                        if (error)
                            return "notificationUsers." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiffItemBusinessUnitContact message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DiffItemBusinessUnitContact} DiffItemBusinessUnitContact
                 */
                DiffItemBusinessUnitContact.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DiffItemBusinessUnitContact)
                        return object;
                    let message = new $root.mtechnavi.api.company.DiffItemBusinessUnitContact();
                    if (object.keyValue != null)
                        message.keyValue = String(object.keyValue);
                    if (object.businessUnitBranchDisplayName != null) {
                        if (typeof object.businessUnitBranchDisplayName !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.businessUnitBranchDisplayName: object expected");
                        message.businessUnitBranchDisplayName = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.businessUnitBranchDisplayName);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.address: object expected");
                        message.address = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.address);
                    }
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.phoneNumber: object expected");
                        message.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.phoneNumber);
                    }
                    if (object.faxNumber != null) {
                        if (typeof object.faxNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.faxNumber: object expected");
                        message.faxNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.faxNumber);
                    }
                    if (object.alias != null) {
                        if (typeof object.alias !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.alias: object expected");
                        message.alias = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.alias);
                    }
                    if (object.notificationUsers != null) {
                        if (typeof object.notificationUsers !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitContact.notificationUsers: object expected");
                        message.notificationUsers = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.notificationUsers);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiffItemBusinessUnitContact message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @static
                 * @param {mtechnavi.api.company.DiffItemBusinessUnitContact} message DiffItemBusinessUnitContact
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiffItemBusinessUnitContact.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.keyValue = "";
                        object.businessUnitBranchDisplayName = null;
                        object.address = null;
                        object.phoneNumber = null;
                        object.faxNumber = null;
                        object.alias = null;
                        object.notificationUsers = null;
                    }
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        object.keyValue = message.keyValue;
                    if (message.businessUnitBranchDisplayName != null && message.hasOwnProperty("businessUnitBranchDisplayName"))
                        object.businessUnitBranchDisplayName = $root.mtechnavi.api.company.ComparisonItem.toObject(message.businessUnitBranchDisplayName, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.ComparisonItem.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.phoneNumber, options);
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.faxNumber, options);
                    if (message.alias != null && message.hasOwnProperty("alias"))
                        object.alias = $root.mtechnavi.api.company.ComparisonItem.toObject(message.alias, options);
                    if (message.notificationUsers != null && message.hasOwnProperty("notificationUsers"))
                        object.notificationUsers = $root.mtechnavi.api.company.ComparisonItem.toObject(message.notificationUsers, options);
                    return object;
                };

                /**
                 * Converts this DiffItemBusinessUnitContact to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitContact
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiffItemBusinessUnitContact.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiffItemBusinessUnitContact;
            })();

            company.DiffItemBusinessUnitRankReception = (function() {

                /**
                 * Properties of a DiffItemBusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @interface IDiffItemBusinessUnitRankReception
                 * @property {string|null} [keyValue] DiffItemBusinessUnitRankReception keyValue
                 * @property {mtechnavi.api.company.IComparisonItem|null} [ownCompany] DiffItemBusinessUnitRankReception ownCompany
                 * @property {mtechnavi.api.company.IComparisonItem|null} [companyDisplayName] DiffItemBusinessUnitRankReception companyDisplayName
                 * @property {mtechnavi.api.company.IComparisonItem|null} [businessUnitBranchDisplayName] DiffItemBusinessUnitRankReception businessUnitBranchDisplayName
                 * @property {mtechnavi.api.company.IComparisonItem|null} [address] DiffItemBusinessUnitRankReception address
                 * @property {mtechnavi.api.company.IComparisonItem|null} [phoneNumber] DiffItemBusinessUnitRankReception phoneNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [faxNumber] DiffItemBusinessUnitRankReception faxNumber
                 * @property {mtechnavi.api.company.IComparisonItem|null} [category] DiffItemBusinessUnitRankReception category
                 * @property {mtechnavi.api.company.IComparisonItem|null} [qualityManagerDisplayName] DiffItemBusinessUnitRankReception qualityManagerDisplayName
                 * @property {mtechnavi.api.company.IComparisonItem|null} [qualityManagerPosition] DiffItemBusinessUnitRankReception qualityManagerPosition
                 * @property {mtechnavi.api.company.IComparisonItem|null} [qualityManagerEmail] DiffItemBusinessUnitRankReception qualityManagerEmail
                 */

                /**
                 * Constructs a new DiffItemBusinessUnitRankReception.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a DiffItemBusinessUnitRankReception.
                 * @implements IDiffItemBusinessUnitRankReception
                 * @constructor
                 * @param {mtechnavi.api.company.IDiffItemBusinessUnitRankReception=} [properties] Properties to set
                 */
                function DiffItemBusinessUnitRankReception(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiffItemBusinessUnitRankReception keyValue.
                 * @member {string} keyValue
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.keyValue = "";

                /**
                 * DiffItemBusinessUnitRankReception ownCompany.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} ownCompany
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.ownCompany = null;

                /**
                 * DiffItemBusinessUnitRankReception companyDisplayName.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} companyDisplayName
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.companyDisplayName = null;

                /**
                 * DiffItemBusinessUnitRankReception businessUnitBranchDisplayName.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} businessUnitBranchDisplayName
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.businessUnitBranchDisplayName = null;

                /**
                 * DiffItemBusinessUnitRankReception address.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} address
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.address = null;

                /**
                 * DiffItemBusinessUnitRankReception phoneNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} phoneNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.phoneNumber = null;

                /**
                 * DiffItemBusinessUnitRankReception faxNumber.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} faxNumber
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.faxNumber = null;

                /**
                 * DiffItemBusinessUnitRankReception category.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} category
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.category = null;

                /**
                 * DiffItemBusinessUnitRankReception qualityManagerDisplayName.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} qualityManagerDisplayName
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.qualityManagerDisplayName = null;

                /**
                 * DiffItemBusinessUnitRankReception qualityManagerPosition.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} qualityManagerPosition
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.qualityManagerPosition = null;

                /**
                 * DiffItemBusinessUnitRankReception qualityManagerEmail.
                 * @member {mtechnavi.api.company.IComparisonItem|null|undefined} qualityManagerEmail
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 */
                DiffItemBusinessUnitRankReception.prototype.qualityManagerEmail = null;

                /**
                 * Verifies a DiffItemBusinessUnitRankReception message.
                 * @function verify
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiffItemBusinessUnitRankReception.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        if (!$util.isString(message.keyValue))
                            return "keyValue: string expected";
                    if (message.ownCompany != null && message.hasOwnProperty("ownCompany")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.ownCompany);
                        if (error)
                            return "ownCompany." + error;
                    }
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.companyDisplayName);
                        if (error)
                            return "companyDisplayName." + error;
                    }
                    if (message.businessUnitBranchDisplayName != null && message.hasOwnProperty("businessUnitBranchDisplayName")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.businessUnitBranchDisplayName);
                        if (error)
                            return "businessUnitBranchDisplayName." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.faxNumber);
                        if (error)
                            return "faxNumber." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.qualityManagerDisplayName != null && message.hasOwnProperty("qualityManagerDisplayName")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.qualityManagerDisplayName);
                        if (error)
                            return "qualityManagerDisplayName." + error;
                    }
                    if (message.qualityManagerPosition != null && message.hasOwnProperty("qualityManagerPosition")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.qualityManagerPosition);
                        if (error)
                            return "qualityManagerPosition." + error;
                    }
                    if (message.qualityManagerEmail != null && message.hasOwnProperty("qualityManagerEmail")) {
                        let error = $root.mtechnavi.api.company.ComparisonItem.verify(message.qualityManagerEmail);
                        if (error)
                            return "qualityManagerEmail." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiffItemBusinessUnitRankReception message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.DiffItemBusinessUnitRankReception} DiffItemBusinessUnitRankReception
                 */
                DiffItemBusinessUnitRankReception.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.DiffItemBusinessUnitRankReception)
                        return object;
                    let message = new $root.mtechnavi.api.company.DiffItemBusinessUnitRankReception();
                    if (object.keyValue != null)
                        message.keyValue = String(object.keyValue);
                    if (object.ownCompany != null) {
                        if (typeof object.ownCompany !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.ownCompany: object expected");
                        message.ownCompany = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.ownCompany);
                    }
                    if (object.companyDisplayName != null) {
                        if (typeof object.companyDisplayName !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.companyDisplayName: object expected");
                        message.companyDisplayName = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.companyDisplayName);
                    }
                    if (object.businessUnitBranchDisplayName != null) {
                        if (typeof object.businessUnitBranchDisplayName !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.businessUnitBranchDisplayName: object expected");
                        message.businessUnitBranchDisplayName = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.businessUnitBranchDisplayName);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.address: object expected");
                        message.address = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.address);
                    }
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.phoneNumber: object expected");
                        message.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.phoneNumber);
                    }
                    if (object.faxNumber != null) {
                        if (typeof object.faxNumber !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.faxNumber: object expected");
                        message.faxNumber = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.faxNumber);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.category: object expected");
                        message.category = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.category);
                    }
                    if (object.qualityManagerDisplayName != null) {
                        if (typeof object.qualityManagerDisplayName !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.qualityManagerDisplayName: object expected");
                        message.qualityManagerDisplayName = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.qualityManagerDisplayName);
                    }
                    if (object.qualityManagerPosition != null) {
                        if (typeof object.qualityManagerPosition !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.qualityManagerPosition: object expected");
                        message.qualityManagerPosition = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.qualityManagerPosition);
                    }
                    if (object.qualityManagerEmail != null) {
                        if (typeof object.qualityManagerEmail !== "object")
                            throw TypeError(".mtechnavi.api.company.DiffItemBusinessUnitRankReception.qualityManagerEmail: object expected");
                        message.qualityManagerEmail = $root.mtechnavi.api.company.ComparisonItem.fromObject(object.qualityManagerEmail);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiffItemBusinessUnitRankReception message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @static
                 * @param {mtechnavi.api.company.DiffItemBusinessUnitRankReception} message DiffItemBusinessUnitRankReception
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiffItemBusinessUnitRankReception.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.keyValue = "";
                        object.ownCompany = null;
                        object.companyDisplayName = null;
                        object.businessUnitBranchDisplayName = null;
                        object.address = null;
                        object.phoneNumber = null;
                        object.faxNumber = null;
                        object.category = null;
                        object.qualityManagerDisplayName = null;
                        object.qualityManagerPosition = null;
                        object.qualityManagerEmail = null;
                    }
                    if (message.keyValue != null && message.hasOwnProperty("keyValue"))
                        object.keyValue = message.keyValue;
                    if (message.ownCompany != null && message.hasOwnProperty("ownCompany"))
                        object.ownCompany = $root.mtechnavi.api.company.ComparisonItem.toObject(message.ownCompany, options);
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName"))
                        object.companyDisplayName = $root.mtechnavi.api.company.ComparisonItem.toObject(message.companyDisplayName, options);
                    if (message.businessUnitBranchDisplayName != null && message.hasOwnProperty("businessUnitBranchDisplayName"))
                        object.businessUnitBranchDisplayName = $root.mtechnavi.api.company.ComparisonItem.toObject(message.businessUnitBranchDisplayName, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.ComparisonItem.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.phoneNumber, options);
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = $root.mtechnavi.api.company.ComparisonItem.toObject(message.faxNumber, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.mtechnavi.api.company.ComparisonItem.toObject(message.category, options);
                    if (message.qualityManagerDisplayName != null && message.hasOwnProperty("qualityManagerDisplayName"))
                        object.qualityManagerDisplayName = $root.mtechnavi.api.company.ComparisonItem.toObject(message.qualityManagerDisplayName, options);
                    if (message.qualityManagerPosition != null && message.hasOwnProperty("qualityManagerPosition"))
                        object.qualityManagerPosition = $root.mtechnavi.api.company.ComparisonItem.toObject(message.qualityManagerPosition, options);
                    if (message.qualityManagerEmail != null && message.hasOwnProperty("qualityManagerEmail"))
                        object.qualityManagerEmail = $root.mtechnavi.api.company.ComparisonItem.toObject(message.qualityManagerEmail, options);
                    return object;
                };

                /**
                 * Converts this DiffItemBusinessUnitRankReception to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.DiffItemBusinessUnitRankReception
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiffItemBusinessUnitRankReception.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiffItemBusinessUnitRankReception;
            })();

            company.BusinessUnitBranchProperties = (function() {

                /**
                 * Properties of a BusinessUnitBranchProperties.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitBranchProperties
                 * @property {string|null} [businessUnitBranchId] BusinessUnitBranchProperties businessUnitBranchId
                 * @property {string|null} [displayName] BusinessUnitBranchProperties displayName
                 * @property {mtechnavi.api.company.IAddress|null} [address] BusinessUnitBranchProperties address
                 * @property {string|null} [phoneNumber] BusinessUnitBranchProperties phoneNumber
                 * @property {string|null} [faxNumber] BusinessUnitBranchProperties faxNumber
                 */

                /**
                 * Constructs a new BusinessUnitBranchProperties.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitBranchProperties.
                 * @implements IBusinessUnitBranchProperties
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitBranchProperties=} [properties] Properties to set
                 */
                function BusinessUnitBranchProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitBranchProperties businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 */
                BusinessUnitBranchProperties.prototype.businessUnitBranchId = "";

                /**
                 * BusinessUnitBranchProperties displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 */
                BusinessUnitBranchProperties.prototype.displayName = "";

                /**
                 * BusinessUnitBranchProperties address.
                 * @member {mtechnavi.api.company.IAddress|null|undefined} address
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 */
                BusinessUnitBranchProperties.prototype.address = null;

                /**
                 * BusinessUnitBranchProperties phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 */
                BusinessUnitBranchProperties.prototype.phoneNumber = "";

                /**
                 * BusinessUnitBranchProperties faxNumber.
                 * @member {string} faxNumber
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 */
                BusinessUnitBranchProperties.prototype.faxNumber = "";

                /**
                 * Verifies a BusinessUnitBranchProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitBranchProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        let error = $root.mtechnavi.api.company.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        if (!$util.isString(message.faxNumber))
                            return "faxNumber: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitBranchProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitBranchProperties} BusinessUnitBranchProperties
                 */
                BusinessUnitBranchProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitBranchProperties)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitBranchProperties();
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitBranchProperties.address: object expected");
                        message.address = $root.mtechnavi.api.company.Address.fromObject(object.address);
                    }
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.faxNumber != null)
                        message.faxNumber = String(object.faxNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitBranchProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitBranchProperties} message BusinessUnitBranchProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitBranchProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.displayName = "";
                        object.address = null;
                        object.phoneNumber = "";
                        object.faxNumber = "";
                        object.businessUnitBranchId = "";
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.mtechnavi.api.company.Address.toObject(message.address, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.faxNumber != null && message.hasOwnProperty("faxNumber"))
                        object.faxNumber = message.faxNumber;
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    return object;
                };

                /**
                 * Converts this BusinessUnitBranchProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitBranchProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitBranchProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitBranchProperties;
            })();

            company.BusinessUnitManagementContent = (function() {

                /**
                 * Properties of a BusinessUnitManagementContent.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitManagementContent
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] BusinessUnitManagementContent businessUnitManagement
                 * @property {sharelib.INameOption|null} [tenantStatus] BusinessUnitManagementContent tenantStatus
                 * @property {Array.<sharelib.IUserReference>|null} [aggregatedNotificationUsers] BusinessUnitManagementContent aggregatedNotificationUsers
                 * @property {sharelib.INameOption|null} [notificationStatus] BusinessUnitManagementContent notificationStatus
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [businessUnitProfile] BusinessUnitManagementContent businessUnitProfile
                 * @property {mtechnavi.api.company.ICompanyProfile|null} [profile] BusinessUnitManagementContent profile
                 */

                /**
                 * Constructs a new BusinessUnitManagementContent.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitManagementContent.
                 * @implements IBusinessUnitManagementContent
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitManagementContent=} [properties] Properties to set
                 */
                function BusinessUnitManagementContent(properties) {
                    this.aggregatedNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitManagementContent businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.businessUnitManagement = null;

                /**
                 * BusinessUnitManagementContent tenantStatus.
                 * @member {sharelib.INameOption|null|undefined} tenantStatus
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.tenantStatus = null;

                /**
                 * BusinessUnitManagementContent aggregatedNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} aggregatedNotificationUsers
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.aggregatedNotificationUsers = $util.emptyArray;

                /**
                 * BusinessUnitManagementContent notificationStatus.
                 * @member {sharelib.INameOption|null|undefined} notificationStatus
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.notificationStatus = null;

                /**
                 * BusinessUnitManagementContent businessUnitProfile.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} businessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.businessUnitProfile = null;

                /**
                 * BusinessUnitManagementContent profile.
                 * @member {mtechnavi.api.company.ICompanyProfile|null|undefined} profile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 */
                BusinessUnitManagementContent.prototype.profile = null;

                /**
                 * Verifies a BusinessUnitManagementContent message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitManagementContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    if (message.tenantStatus != null && message.hasOwnProperty("tenantStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.tenantStatus);
                        if (error)
                            return "tenantStatus." + error;
                    }
                    if (message.aggregatedNotificationUsers != null && message.hasOwnProperty("aggregatedNotificationUsers")) {
                        if (!Array.isArray(message.aggregatedNotificationUsers))
                            return "aggregatedNotificationUsers: array expected";
                        for (let i = 0; i < message.aggregatedNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.aggregatedNotificationUsers[i]);
                            if (error)
                                return "aggregatedNotificationUsers." + error;
                        }
                    }
                    if (message.notificationStatus != null && message.hasOwnProperty("notificationStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.notificationStatus);
                        if (error)
                            return "notificationStatus." + error;
                    }
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.businessUnitProfile);
                        if (error)
                            return "businessUnitProfile." + error;
                    }
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        let error = $root.mtechnavi.api.company.CompanyProfile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitManagementContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitManagementContent} BusinessUnitManagementContent
                 */
                BusinessUnitManagementContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitManagementContent)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitManagementContent();
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    if (object.tenantStatus != null) {
                        if (typeof object.tenantStatus !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.tenantStatus: object expected");
                        message.tenantStatus = $root.sharelib.NameOption.fromObject(object.tenantStatus);
                    }
                    if (object.aggregatedNotificationUsers) {
                        if (!Array.isArray(object.aggregatedNotificationUsers))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.aggregatedNotificationUsers: array expected");
                        message.aggregatedNotificationUsers = [];
                        for (let i = 0; i < object.aggregatedNotificationUsers.length; ++i) {
                            if (typeof object.aggregatedNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.aggregatedNotificationUsers: object expected");
                            message.aggregatedNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.aggregatedNotificationUsers[i]);
                        }
                    }
                    if (object.notificationStatus != null) {
                        if (typeof object.notificationStatus !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.notificationStatus: object expected");
                        message.notificationStatus = $root.sharelib.NameOption.fromObject(object.notificationStatus);
                    }
                    if (object.businessUnitProfile != null) {
                        if (typeof object.businessUnitProfile !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.businessUnitProfile: object expected");
                        message.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.businessUnitProfile);
                    }
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementContent.profile: object expected");
                        message.profile = $root.mtechnavi.api.company.CompanyProfile.fromObject(object.profile);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitManagementContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitManagementContent} message BusinessUnitManagementContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitManagementContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.aggregatedNotificationUsers = [];
                    if (options.defaults) {
                        object.businessUnitManagement = null;
                        object.tenantStatus = null;
                        object.businessUnitProfile = null;
                        object.profile = null;
                        object.notificationStatus = null;
                    }
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    if (message.tenantStatus != null && message.hasOwnProperty("tenantStatus"))
                        object.tenantStatus = $root.sharelib.NameOption.toObject(message.tenantStatus, options);
                    if (message.aggregatedNotificationUsers && message.aggregatedNotificationUsers.length) {
                        object.aggregatedNotificationUsers = [];
                        for (let j = 0; j < message.aggregatedNotificationUsers.length; ++j)
                            object.aggregatedNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.aggregatedNotificationUsers[j], options);
                    }
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile"))
                        object.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.businessUnitProfile, options);
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.mtechnavi.api.company.CompanyProfile.toObject(message.profile, options);
                    if (message.notificationStatus != null && message.hasOwnProperty("notificationStatus"))
                        object.notificationStatus = $root.sharelib.NameOption.toObject(message.notificationStatus, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitManagementContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitManagementContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitManagementContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitManagementContent;
            })();

            company.BusinessUnitManagementFormatContent = (function() {

                /**
                 * Properties of a BusinessUnitManagementFormatContent.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitManagementFormatContent
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] BusinessUnitManagementFormatContent businessUnitManagement
                 * @property {sharelib.INameOption|null} [tenantStatus] BusinessUnitManagementFormatContent tenantStatus
                 * @property {mtechnavi.api.company.IBusinessUnitProfile|null} [businessUnitProfile] BusinessUnitManagementFormatContent businessUnitProfile
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitMainContact] BusinessUnitManagementFormatContent businessUnitMainContact
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] BusinessUnitManagementFormatContent businessUnitContactAttribute
                 * @property {mtechnavi.api.company.IBusinessUnitStrength|null} [businessUnitStrength] BusinessUnitManagementFormatContent businessUnitStrength
                 */

                /**
                 * Constructs a new BusinessUnitManagementFormatContent.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitManagementFormatContent.
                 * @implements IBusinessUnitManagementFormatContent
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitManagementFormatContent=} [properties] Properties to set
                 */
                function BusinessUnitManagementFormatContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitManagementFormatContent businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.businessUnitManagement = null;

                /**
                 * BusinessUnitManagementFormatContent tenantStatus.
                 * @member {sharelib.INameOption|null|undefined} tenantStatus
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.tenantStatus = null;

                /**
                 * BusinessUnitManagementFormatContent businessUnitProfile.
                 * @member {mtechnavi.api.company.IBusinessUnitProfile|null|undefined} businessUnitProfile
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.businessUnitProfile = null;

                /**
                 * BusinessUnitManagementFormatContent businessUnitMainContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitMainContact
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.businessUnitMainContact = null;

                /**
                 * BusinessUnitManagementFormatContent businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.businessUnitContactAttribute = null;

                /**
                 * BusinessUnitManagementFormatContent businessUnitStrength.
                 * @member {mtechnavi.api.company.IBusinessUnitStrength|null|undefined} businessUnitStrength
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 */
                BusinessUnitManagementFormatContent.prototype.businessUnitStrength = null;

                /**
                 * Verifies a BusinessUnitManagementFormatContent message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitManagementFormatContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    if (message.tenantStatus != null && message.hasOwnProperty("tenantStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.tenantStatus);
                        if (error)
                            return "tenantStatus." + error;
                    }
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitProfile.verify(message.businessUnitProfile);
                        if (error)
                            return "businessUnitProfile." + error;
                    }
                    if (message.businessUnitMainContact != null && message.hasOwnProperty("businessUnitMainContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitMainContact);
                        if (error)
                            return "businessUnitMainContact." + error;
                    }
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitStrength.verify(message.businessUnitStrength);
                        if (error)
                            return "businessUnitStrength." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitManagementFormatContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitManagementFormatContent} BusinessUnitManagementFormatContent
                 */
                BusinessUnitManagementFormatContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitManagementFormatContent)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitManagementFormatContent();
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    if (object.tenantStatus != null) {
                        if (typeof object.tenantStatus !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.tenantStatus: object expected");
                        message.tenantStatus = $root.sharelib.NameOption.fromObject(object.tenantStatus);
                    }
                    if (object.businessUnitProfile != null) {
                        if (typeof object.businessUnitProfile !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.businessUnitProfile: object expected");
                        message.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.fromObject(object.businessUnitProfile);
                    }
                    if (object.businessUnitMainContact != null) {
                        if (typeof object.businessUnitMainContact !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.businessUnitMainContact: object expected");
                        message.businessUnitMainContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitMainContact);
                    }
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    if (object.businessUnitStrength != null) {
                        if (typeof object.businessUnitStrength !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitManagementFormatContent.businessUnitStrength: object expected");
                        message.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.fromObject(object.businessUnitStrength);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitManagementFormatContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitManagementFormatContent} message BusinessUnitManagementFormatContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitManagementFormatContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitManagement = null;
                        object.tenantStatus = null;
                        object.businessUnitProfile = null;
                        object.businessUnitContactAttribute = null;
                        object.businessUnitMainContact = null;
                        object.businessUnitStrength = null;
                    }
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    if (message.tenantStatus != null && message.hasOwnProperty("tenantStatus"))
                        object.tenantStatus = $root.sharelib.NameOption.toObject(message.tenantStatus, options);
                    if (message.businessUnitProfile != null && message.hasOwnProperty("businessUnitProfile"))
                        object.businessUnitProfile = $root.mtechnavi.api.company.BusinessUnitProfile.toObject(message.businessUnitProfile, options);
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    if (message.businessUnitMainContact != null && message.hasOwnProperty("businessUnitMainContact"))
                        object.businessUnitMainContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitMainContact, options);
                    if (message.businessUnitStrength != null && message.hasOwnProperty("businessUnitStrength"))
                        object.businessUnitStrength = $root.mtechnavi.api.company.BusinessUnitStrength.toObject(message.businessUnitStrength, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitManagementFormatContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitManagementFormatContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitManagementFormatContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitManagementFormatContent;
            })();

            company.BusinessUnitContactContent = (function() {

                /**
                 * Properties of a BusinessUnitContactContent.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitContactContent
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] BusinessUnitContactContent businessUnitContact
                 * @property {mtechnavi.api.company.IBusinessUnitContactHeader|null} [businessUnitContactHeader] BusinessUnitContactContent businessUnitContactHeader
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] BusinessUnitContactContent businessUnitContactAttribute
                 * @property {sharelib.INameOption|null} [status] BusinessUnitContactContent status
                 */

                /**
                 * Constructs a new BusinessUnitContactContent.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitContactContent.
                 * @implements IBusinessUnitContactContent
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitContactContent=} [properties] Properties to set
                 */
                function BusinessUnitContactContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitContactContent businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @instance
                 */
                BusinessUnitContactContent.prototype.businessUnitContact = null;

                /**
                 * BusinessUnitContactContent businessUnitContactHeader.
                 * @member {mtechnavi.api.company.IBusinessUnitContactHeader|null|undefined} businessUnitContactHeader
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @instance
                 */
                BusinessUnitContactContent.prototype.businessUnitContactHeader = null;

                /**
                 * BusinessUnitContactContent businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @instance
                 */
                BusinessUnitContactContent.prototype.businessUnitContactAttribute = null;

                /**
                 * BusinessUnitContactContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @instance
                 */
                BusinessUnitContactContent.prototype.status = null;

                /**
                 * Verifies a BusinessUnitContactContent message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitContactContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactHeader.verify(message.businessUnitContactHeader);
                        if (error)
                            return "businessUnitContactHeader." + error;
                    }
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitContactContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitContactContent} BusinessUnitContactContent
                 */
                BusinessUnitContactContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitContactContent)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitContactContent();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactContent.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    if (object.businessUnitContactHeader != null) {
                        if (typeof object.businessUnitContactHeader !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactContent.businessUnitContactHeader: object expected");
                        message.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.fromObject(object.businessUnitContactHeader);
                    }
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactContent.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitContactContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitContactContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitContactContent} message BusinessUnitContactContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitContactContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContact = null;
                        object.businessUnitContactHeader = null;
                        object.businessUnitContactAttribute = null;
                        object.status = null;
                    }
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    if (message.businessUnitContactHeader != null && message.hasOwnProperty("businessUnitContactHeader"))
                        object.businessUnitContactHeader = $root.mtechnavi.api.company.BusinessUnitContactHeader.toObject(message.businessUnitContactHeader, options);
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitContactContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitContactContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitContactContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitContactContent;
            })();

            company.StaffAndComponentUnit = (function() {

                /**
                 * Properties of a StaffAndComponentUnit.
                 * @memberof mtechnavi.api.company
                 * @interface IStaffAndComponentUnit
                 * @property {mtechnavi.api.company.IStaff|null} [staff] StaffAndComponentUnit staff
                 * @property {mtechnavi.api.company.IComponentUnit|null} [componentUnit] StaffAndComponentUnit componentUnit
                 */

                /**
                 * Constructs a new StaffAndComponentUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a StaffAndComponentUnit.
                 * @implements IStaffAndComponentUnit
                 * @constructor
                 * @param {mtechnavi.api.company.IStaffAndComponentUnit=} [properties] Properties to set
                 */
                function StaffAndComponentUnit(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StaffAndComponentUnit staff.
                 * @member {mtechnavi.api.company.IStaff|null|undefined} staff
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @instance
                 */
                StaffAndComponentUnit.prototype.staff = null;

                /**
                 * StaffAndComponentUnit componentUnit.
                 * @member {mtechnavi.api.company.IComponentUnit|null|undefined} componentUnit
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @instance
                 */
                StaffAndComponentUnit.prototype.componentUnit = null;

                /**
                 * Verifies a StaffAndComponentUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StaffAndComponentUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.staff != null && message.hasOwnProperty("staff")) {
                        let error = $root.mtechnavi.api.company.Staff.verify(message.staff);
                        if (error)
                            return "staff." + error;
                    }
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnit);
                        if (error)
                            return "componentUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a StaffAndComponentUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.StaffAndComponentUnit} StaffAndComponentUnit
                 */
                StaffAndComponentUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.StaffAndComponentUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.StaffAndComponentUnit();
                    if (object.staff != null) {
                        if (typeof object.staff !== "object")
                            throw TypeError(".mtechnavi.api.company.StaffAndComponentUnit.staff: object expected");
                        message.staff = $root.mtechnavi.api.company.Staff.fromObject(object.staff);
                    }
                    if (object.componentUnit != null) {
                        if (typeof object.componentUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.StaffAndComponentUnit.componentUnit: object expected");
                        message.componentUnit = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a StaffAndComponentUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @static
                 * @param {mtechnavi.api.company.StaffAndComponentUnit} message StaffAndComponentUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StaffAndComponentUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.staff = null;
                        object.componentUnit = null;
                    }
                    if (message.staff != null && message.hasOwnProperty("staff"))
                        object.staff = $root.mtechnavi.api.company.Staff.toObject(message.staff, options);
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit"))
                        object.componentUnit = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnit, options);
                    return object;
                };

                /**
                 * Converts this StaffAndComponentUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.StaffAndComponentUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StaffAndComponentUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StaffAndComponentUnit;
            })();

            company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit = (function() {

                /**
                 * Properties of a PostUnitAndCompanyAndComponentUnitAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @interface IPostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @property {mtechnavi.api.company.IPostUnit|null} [postUnit] PostUnitAndCompanyAndComponentUnitAndBusinessUnit postUnit
                 * @property {mtechnavi.api.company.IComponentUnit|null} [componentUnit] PostUnitAndCompanyAndComponentUnitAndBusinessUnit componentUnit
                 * @property {mtechnavi.api.company.ICompany|null} [company] PostUnitAndCompanyAndComponentUnitAndBusinessUnit company
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] PostUnitAndCompanyAndComponentUnitAndBusinessUnit businessUnit
                 */

                /**
                 * Constructs a new PostUnitAndCompanyAndComponentUnitAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a PostUnitAndCompanyAndComponentUnitAndBusinessUnit.
                 * @implements IPostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @constructor
                 * @param {mtechnavi.api.company.IPostUnitAndCompanyAndComponentUnitAndBusinessUnit=} [properties] Properties to set
                 */
                function PostUnitAndCompanyAndComponentUnitAndBusinessUnit(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PostUnitAndCompanyAndComponentUnitAndBusinessUnit postUnit.
                 * @member {mtechnavi.api.company.IPostUnit|null|undefined} postUnit
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.prototype.postUnit = null;

                /**
                 * PostUnitAndCompanyAndComponentUnitAndBusinessUnit componentUnit.
                 * @member {mtechnavi.api.company.IComponentUnit|null|undefined} componentUnit
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.prototype.componentUnit = null;

                /**
                 * PostUnitAndCompanyAndComponentUnitAndBusinessUnit company.
                 * @member {mtechnavi.api.company.ICompany|null|undefined} company
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.prototype.company = null;

                /**
                 * PostUnitAndCompanyAndComponentUnitAndBusinessUnit businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.prototype.businessUnit = null;

                /**
                 * Verifies a PostUnitAndCompanyAndComponentUnitAndBusinessUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.postUnit != null && message.hasOwnProperty("postUnit")) {
                        let error = $root.mtechnavi.api.company.PostUnit.verify(message.postUnit);
                        if (error)
                            return "postUnit." + error;
                    }
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit")) {
                        let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnit);
                        if (error)
                            return "componentUnit." + error;
                    }
                    if (message.company != null && message.hasOwnProperty("company")) {
                        let error = $root.mtechnavi.api.company.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PostUnitAndCompanyAndComponentUnitAndBusinessUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit} PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit();
                    if (object.postUnit != null) {
                        if (typeof object.postUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.postUnit: object expected");
                        message.postUnit = $root.mtechnavi.api.company.PostUnit.fromObject(object.postUnit);
                    }
                    if (object.componentUnit != null) {
                        if (typeof object.componentUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.componentUnit: object expected");
                        message.componentUnit = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnit);
                    }
                    if (object.company != null) {
                        if (typeof object.company !== "object")
                            throw TypeError(".mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.company: object expected");
                        message.company = $root.mtechnavi.api.company.Company.fromObject(object.company);
                    }
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PostUnitAndCompanyAndComponentUnitAndBusinessUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit} message PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.postUnit = null;
                        object.componentUnit = null;
                        object.company = null;
                        object.businessUnit = null;
                    }
                    if (message.postUnit != null && message.hasOwnProperty("postUnit"))
                        object.postUnit = $root.mtechnavi.api.company.PostUnit.toObject(message.postUnit, options);
                    if (message.componentUnit != null && message.hasOwnProperty("componentUnit"))
                        object.componentUnit = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnit, options);
                    if (message.company != null && message.hasOwnProperty("company"))
                        object.company = $root.mtechnavi.api.company.Company.toObject(message.company, options);
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this PostUnitAndCompanyAndComponentUnitAndBusinessUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.PostUnitAndCompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PostUnitAndCompanyAndComponentUnitAndBusinessUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PostUnitAndCompanyAndComponentUnitAndBusinessUnit;
            })();

            company.CompanyAndComponentUnitAndBusinessUnit = (function() {

                /**
                 * Properties of a CompanyAndComponentUnitAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @interface ICompanyAndComponentUnitAndBusinessUnit
                 * @property {mtechnavi.api.company.ICompany|null} [company] CompanyAndComponentUnitAndBusinessUnit company
                 * @property {Array.<mtechnavi.api.company.IComponentUnit>|null} [componentUnits] CompanyAndComponentUnitAndBusinessUnit componentUnits
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] CompanyAndComponentUnitAndBusinessUnit businessUnit
                 */

                /**
                 * Constructs a new CompanyAndComponentUnitAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyAndComponentUnitAndBusinessUnit.
                 * @implements ICompanyAndComponentUnitAndBusinessUnit
                 * @constructor
                 * @param {mtechnavi.api.company.ICompanyAndComponentUnitAndBusinessUnit=} [properties] Properties to set
                 */
                function CompanyAndComponentUnitAndBusinessUnit(properties) {
                    this.componentUnits = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompanyAndComponentUnitAndBusinessUnit company.
                 * @member {mtechnavi.api.company.ICompany|null|undefined} company
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                CompanyAndComponentUnitAndBusinessUnit.prototype.company = null;

                /**
                 * CompanyAndComponentUnitAndBusinessUnit componentUnits.
                 * @member {Array.<mtechnavi.api.company.IComponentUnit>} componentUnits
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                CompanyAndComponentUnitAndBusinessUnit.prototype.componentUnits = $util.emptyArray;

                /**
                 * CompanyAndComponentUnitAndBusinessUnit businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 */
                CompanyAndComponentUnitAndBusinessUnit.prototype.businessUnit = null;

                /**
                 * Verifies a CompanyAndComponentUnitAndBusinessUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompanyAndComponentUnitAndBusinessUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.company != null && message.hasOwnProperty("company")) {
                        let error = $root.mtechnavi.api.company.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                    if (message.componentUnits != null && message.hasOwnProperty("componentUnits")) {
                        if (!Array.isArray(message.componentUnits))
                            return "componentUnits: array expected";
                        for (let i = 0; i < message.componentUnits.length; ++i) {
                            let error = $root.mtechnavi.api.company.ComponentUnit.verify(message.componentUnits[i]);
                            if (error)
                                return "componentUnits." + error;
                        }
                    }
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompanyAndComponentUnitAndBusinessUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit} CompanyAndComponentUnitAndBusinessUnit
                 */
                CompanyAndComponentUnitAndBusinessUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit();
                    if (object.company != null) {
                        if (typeof object.company !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.company: object expected");
                        message.company = $root.mtechnavi.api.company.Company.fromObject(object.company);
                    }
                    if (object.componentUnits) {
                        if (!Array.isArray(object.componentUnits))
                            throw TypeError(".mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.componentUnits: array expected");
                        message.componentUnits = [];
                        for (let i = 0; i < object.componentUnits.length; ++i) {
                            if (typeof object.componentUnits[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.componentUnits: object expected");
                            message.componentUnits[i] = $root.mtechnavi.api.company.ComponentUnit.fromObject(object.componentUnits[i]);
                        }
                    }
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompanyAndComponentUnitAndBusinessUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @static
                 * @param {mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit} message CompanyAndComponentUnitAndBusinessUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyAndComponentUnitAndBusinessUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.componentUnits = [];
                    if (options.defaults) {
                        object.company = null;
                        object.businessUnit = null;
                    }
                    if (message.company != null && message.hasOwnProperty("company"))
                        object.company = $root.mtechnavi.api.company.Company.toObject(message.company, options);
                    if (message.componentUnits && message.componentUnits.length) {
                        object.componentUnits = [];
                        for (let j = 0; j < message.componentUnits.length; ++j)
                            object.componentUnits[j] = $root.mtechnavi.api.company.ComponentUnit.toObject(message.componentUnits[j], options);
                    }
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this CompanyAndComponentUnitAndBusinessUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CompanyAndComponentUnitAndBusinessUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyAndComponentUnitAndBusinessUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompanyAndComponentUnitAndBusinessUnit;
            })();

            company.CompanyAndBusinessUnit = (function() {

                /**
                 * Properties of a CompanyAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @interface ICompanyAndBusinessUnit
                 * @property {mtechnavi.api.company.ICompany|null} [company] CompanyAndBusinessUnit company
                 * @property {mtechnavi.api.company.IBusinessUnit|null} [businessUnit] CompanyAndBusinessUnit businessUnit
                 */

                /**
                 * Constructs a new CompanyAndBusinessUnit.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyAndBusinessUnit.
                 * @implements ICompanyAndBusinessUnit
                 * @constructor
                 * @param {mtechnavi.api.company.ICompanyAndBusinessUnit=} [properties] Properties to set
                 */
                function CompanyAndBusinessUnit(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompanyAndBusinessUnit company.
                 * @member {mtechnavi.api.company.ICompany|null|undefined} company
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @instance
                 */
                CompanyAndBusinessUnit.prototype.company = null;

                /**
                 * CompanyAndBusinessUnit businessUnit.
                 * @member {mtechnavi.api.company.IBusinessUnit|null|undefined} businessUnit
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @instance
                 */
                CompanyAndBusinessUnit.prototype.businessUnit = null;

                /**
                 * Verifies a CompanyAndBusinessUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompanyAndBusinessUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.company != null && message.hasOwnProperty("company")) {
                        let error = $root.mtechnavi.api.company.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit")) {
                        let error = $root.mtechnavi.api.company.BusinessUnit.verify(message.businessUnit);
                        if (error)
                            return "businessUnit." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompanyAndBusinessUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.CompanyAndBusinessUnit} CompanyAndBusinessUnit
                 */
                CompanyAndBusinessUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.CompanyAndBusinessUnit)
                        return object;
                    let message = new $root.mtechnavi.api.company.CompanyAndBusinessUnit();
                    if (object.company != null) {
                        if (typeof object.company !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyAndBusinessUnit.company: object expected");
                        message.company = $root.mtechnavi.api.company.Company.fromObject(object.company);
                    }
                    if (object.businessUnit != null) {
                        if (typeof object.businessUnit !== "object")
                            throw TypeError(".mtechnavi.api.company.CompanyAndBusinessUnit.businessUnit: object expected");
                        message.businessUnit = $root.mtechnavi.api.company.BusinessUnit.fromObject(object.businessUnit);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompanyAndBusinessUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @static
                 * @param {mtechnavi.api.company.CompanyAndBusinessUnit} message CompanyAndBusinessUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompanyAndBusinessUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.company = null;
                        object.businessUnit = null;
                    }
                    if (message.company != null && message.hasOwnProperty("company"))
                        object.company = $root.mtechnavi.api.company.Company.toObject(message.company, options);
                    if (message.businessUnit != null && message.hasOwnProperty("businessUnit"))
                        object.businessUnit = $root.mtechnavi.api.company.BusinessUnit.toObject(message.businessUnit, options);
                    return object;
                };

                /**
                 * Converts this CompanyAndBusinessUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.CompanyAndBusinessUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompanyAndBusinessUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompanyAndBusinessUnit;
            })();

            company.BusinessUnitSkillTree = (function() {

                /**
                 * Properties of a BusinessUnitSkillTree.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitSkillTree
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSkillItem>|null} [skillItems] BusinessUnitSkillTree skillItems
                 */

                /**
                 * Constructs a new BusinessUnitSkillTree.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitSkillTree.
                 * @implements IBusinessUnitSkillTree
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitSkillTree=} [properties] Properties to set
                 */
                function BusinessUnitSkillTree(properties) {
                    this.skillItems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitSkillTree skillItems.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSkillItem>} skillItems
                 * @memberof mtechnavi.api.company.BusinessUnitSkillTree
                 * @instance
                 */
                BusinessUnitSkillTree.prototype.skillItems = $util.emptyArray;

                /**
                 * Verifies a BusinessUnitSkillTree message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitSkillTree
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitSkillTree.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.skillItems != null && message.hasOwnProperty("skillItems")) {
                        if (!Array.isArray(message.skillItems))
                            return "skillItems: array expected";
                        for (let i = 0; i < message.skillItems.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSkillItem.verify(message.skillItems[i]);
                            if (error)
                                return "skillItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitSkillTree message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkillTree
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitSkillTree} BusinessUnitSkillTree
                 */
                BusinessUnitSkillTree.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitSkillTree)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitSkillTree();
                    if (object.skillItems) {
                        if (!Array.isArray(object.skillItems))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkillTree.skillItems: array expected");
                        message.skillItems = [];
                        for (let i = 0; i < object.skillItems.length; ++i) {
                            if (typeof object.skillItems[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitSkillTree.skillItems: object expected");
                            message.skillItems[i] = $root.mtechnavi.api.company.BusinessUnitSkillItem.fromObject(object.skillItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitSkillTree message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkillTree
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitSkillTree} message BusinessUnitSkillTree
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitSkillTree.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.skillItems = [];
                    if (message.skillItems && message.skillItems.length) {
                        object.skillItems = [];
                        for (let j = 0; j < message.skillItems.length; ++j)
                            object.skillItems[j] = $root.mtechnavi.api.company.BusinessUnitSkillItem.toObject(message.skillItems[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnitSkillTree to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitSkillTree
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitSkillTree.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitSkillTree;
            })();

            company.BusinessUnitSkillItem = (function() {

                /**
                 * Properties of a BusinessUnitSkillItem.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitSkillItem
                 * @property {string|null} [businessUnitSkillItemId] BusinessUnitSkillItem businessUnitSkillItemId
                 * @property {Object.<string,string>|null} [displayNameLang] BusinessUnitSkillItem displayNameLang
                 * @property {Array.<mtechnavi.api.company.IBusinessUnitSkillItem>|null} [children] BusinessUnitSkillItem children
                 */

                /**
                 * Constructs a new BusinessUnitSkillItem.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitSkillItem.
                 * @implements IBusinessUnitSkillItem
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitSkillItem=} [properties] Properties to set
                 */
                function BusinessUnitSkillItem(properties) {
                    this.displayNameLang = {};
                    this.children = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitSkillItem businessUnitSkillItemId.
                 * @member {string} businessUnitSkillItemId
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @instance
                 */
                BusinessUnitSkillItem.prototype.businessUnitSkillItemId = "";

                /**
                 * BusinessUnitSkillItem displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @instance
                 */
                BusinessUnitSkillItem.prototype.displayNameLang = $util.emptyObject;

                /**
                 * BusinessUnitSkillItem children.
                 * @member {Array.<mtechnavi.api.company.IBusinessUnitSkillItem>} children
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @instance
                 */
                BusinessUnitSkillItem.prototype.children = $util.emptyArray;

                /**
                 * Verifies a BusinessUnitSkillItem message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitSkillItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitSkillItemId != null && message.hasOwnProperty("businessUnitSkillItemId"))
                        if (!$util.isString(message.businessUnitSkillItemId))
                            return "businessUnitSkillItemId: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.children != null && message.hasOwnProperty("children")) {
                        if (!Array.isArray(message.children))
                            return "children: array expected";
                        for (let i = 0; i < message.children.length; ++i) {
                            let error = $root.mtechnavi.api.company.BusinessUnitSkillItem.verify(message.children[i]);
                            if (error)
                                return "children." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitSkillItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitSkillItem} BusinessUnitSkillItem
                 */
                BusinessUnitSkillItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitSkillItem)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitSkillItem();
                    if (object.businessUnitSkillItemId != null)
                        message.businessUnitSkillItemId = String(object.businessUnitSkillItemId);
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkillItem.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.children) {
                        if (!Array.isArray(object.children))
                            throw TypeError(".mtechnavi.api.company.BusinessUnitSkillItem.children: array expected");
                        message.children = [];
                        for (let i = 0; i < object.children.length; ++i) {
                            if (typeof object.children[i] !== "object")
                                throw TypeError(".mtechnavi.api.company.BusinessUnitSkillItem.children: object expected");
                            message.children[i] = $root.mtechnavi.api.company.BusinessUnitSkillItem.fromObject(object.children[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitSkillItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitSkillItem} message BusinessUnitSkillItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitSkillItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.children = [];
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults)
                        object.businessUnitSkillItemId = "";
                    if (message.businessUnitSkillItemId != null && message.hasOwnProperty("businessUnitSkillItemId"))
                        object.businessUnitSkillItemId = message.businessUnitSkillItemId;
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.children && message.children.length) {
                        object.children = [];
                        for (let j = 0; j < message.children.length; ++j)
                            object.children[j] = $root.mtechnavi.api.company.BusinessUnitSkillItem.toObject(message.children[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnitSkillItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitSkillItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitSkillItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitSkillItem;
            })();

            company.SharedContactContent = (function() {

                /**
                 * Properties of a SharedContactContent.
                 * @memberof mtechnavi.api.company
                 * @interface ISharedContactContent
                 * @property {mtechnavi.api.company.IBusinessUnitContact|null} [businessUnitContact] SharedContactContent businessUnitContact
                 * @property {mtechnavi.api.company.IBusinessUnitManagement|null} [businessUnitManagement] SharedContactContent businessUnitManagement
                 * @property {mtechnavi.api.company.IBusinessUnitContactAttribute|null} [businessUnitContactAttribute] SharedContactContent businessUnitContactAttribute
                 * @property {string|null} [displayName] SharedContactContent displayName
                 */

                /**
                 * Constructs a new SharedContactContent.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a SharedContactContent.
                 * @implements ISharedContactContent
                 * @constructor
                 * @param {mtechnavi.api.company.ISharedContactContent=} [properties] Properties to set
                 */
                function SharedContactContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedContactContent businessUnitContact.
                 * @member {mtechnavi.api.company.IBusinessUnitContact|null|undefined} businessUnitContact
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @instance
                 */
                SharedContactContent.prototype.businessUnitContact = null;

                /**
                 * SharedContactContent businessUnitManagement.
                 * @member {mtechnavi.api.company.IBusinessUnitManagement|null|undefined} businessUnitManagement
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @instance
                 */
                SharedContactContent.prototype.businessUnitManagement = null;

                /**
                 * SharedContactContent businessUnitContactAttribute.
                 * @member {mtechnavi.api.company.IBusinessUnitContactAttribute|null|undefined} businessUnitContactAttribute
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @instance
                 */
                SharedContactContent.prototype.businessUnitContactAttribute = null;

                /**
                 * SharedContactContent displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @instance
                 */
                SharedContactContent.prototype.displayName = "";

                /**
                 * Verifies a SharedContactContent message.
                 * @function verify
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedContactContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContact.verify(message.businessUnitContact);
                        if (error)
                            return "businessUnitContact." + error;
                    }
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitManagement.verify(message.businessUnitManagement);
                        if (error)
                            return "businessUnitManagement." + error;
                    }
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitContactAttribute.verify(message.businessUnitContactAttribute);
                        if (error)
                            return "businessUnitContactAttribute." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a SharedContactContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.SharedContactContent} SharedContactContent
                 */
                SharedContactContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.SharedContactContent)
                        return object;
                    let message = new $root.mtechnavi.api.company.SharedContactContent();
                    if (object.businessUnitContact != null) {
                        if (typeof object.businessUnitContact !== "object")
                            throw TypeError(".mtechnavi.api.company.SharedContactContent.businessUnitContact: object expected");
                        message.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.fromObject(object.businessUnitContact);
                    }
                    if (object.businessUnitManagement != null) {
                        if (typeof object.businessUnitManagement !== "object")
                            throw TypeError(".mtechnavi.api.company.SharedContactContent.businessUnitManagement: object expected");
                        message.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.fromObject(object.businessUnitManagement);
                    }
                    if (object.businessUnitContactAttribute != null) {
                        if (typeof object.businessUnitContactAttribute !== "object")
                            throw TypeError(".mtechnavi.api.company.SharedContactContent.businessUnitContactAttribute: object expected");
                        message.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.fromObject(object.businessUnitContactAttribute);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedContactContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @static
                 * @param {mtechnavi.api.company.SharedContactContent} message SharedContactContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedContactContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitContact = null;
                        object.businessUnitManagement = null;
                        object.businessUnitContactAttribute = null;
                        object.displayName = "";
                    }
                    if (message.businessUnitContact != null && message.hasOwnProperty("businessUnitContact"))
                        object.businessUnitContact = $root.mtechnavi.api.company.BusinessUnitContact.toObject(message.businessUnitContact, options);
                    if (message.businessUnitManagement != null && message.hasOwnProperty("businessUnitManagement"))
                        object.businessUnitManagement = $root.mtechnavi.api.company.BusinessUnitManagement.toObject(message.businessUnitManagement, options);
                    if (message.businessUnitContactAttribute != null && message.hasOwnProperty("businessUnitContactAttribute"))
                        object.businessUnitContactAttribute = $root.mtechnavi.api.company.BusinessUnitContactAttribute.toObject(message.businessUnitContactAttribute, options);
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this SharedContactContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.SharedContactContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedContactContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedContactContent;
            })();

            company.BusinessUnitRankContent = (function() {

                /**
                 * Properties of a BusinessUnitRankContent.
                 * @memberof mtechnavi.api.company
                 * @interface IBusinessUnitRankContent
                 * @property {mtechnavi.api.company.IBusinessUnitRankRequest|null} [businessUnitRankRequest] BusinessUnitRankContent businessUnitRankRequest
                 * @property {mtechnavi.api.company.IBusinessUnitRankReception|null} [businessUnitRankReception] BusinessUnitRankContent businessUnitRankReception
                 */

                /**
                 * Constructs a new BusinessUnitRankContent.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a BusinessUnitRankContent.
                 * @implements IBusinessUnitRankContent
                 * @constructor
                 * @param {mtechnavi.api.company.IBusinessUnitRankContent=} [properties] Properties to set
                 */
                function BusinessUnitRankContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitRankContent businessUnitRankRequest.
                 * @member {mtechnavi.api.company.IBusinessUnitRankRequest|null|undefined} businessUnitRankRequest
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @instance
                 */
                BusinessUnitRankContent.prototype.businessUnitRankRequest = null;

                /**
                 * BusinessUnitRankContent businessUnitRankReception.
                 * @member {mtechnavi.api.company.IBusinessUnitRankReception|null|undefined} businessUnitRankReception
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @instance
                 */
                BusinessUnitRankContent.prototype.businessUnitRankReception = null;

                /**
                 * Verifies a BusinessUnitRankContent message.
                 * @function verify
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitRankContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitRankRequest != null && message.hasOwnProperty("businessUnitRankRequest")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankRequest.verify(message.businessUnitRankRequest);
                        if (error)
                            return "businessUnitRankRequest." + error;
                    }
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception")) {
                        let error = $root.mtechnavi.api.company.BusinessUnitRankReception.verify(message.businessUnitRankReception);
                        if (error)
                            return "businessUnitRankReception." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BusinessUnitRankContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.BusinessUnitRankContent} BusinessUnitRankContent
                 */
                BusinessUnitRankContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.BusinessUnitRankContent)
                        return object;
                    let message = new $root.mtechnavi.api.company.BusinessUnitRankContent();
                    if (object.businessUnitRankRequest != null) {
                        if (typeof object.businessUnitRankRequest !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankContent.businessUnitRankRequest: object expected");
                        message.businessUnitRankRequest = $root.mtechnavi.api.company.BusinessUnitRankRequest.fromObject(object.businessUnitRankRequest);
                    }
                    if (object.businessUnitRankReception != null) {
                        if (typeof object.businessUnitRankReception !== "object")
                            throw TypeError(".mtechnavi.api.company.BusinessUnitRankContent.businessUnitRankReception: object expected");
                        message.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.fromObject(object.businessUnitRankReception);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitRankContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @static
                 * @param {mtechnavi.api.company.BusinessUnitRankContent} message BusinessUnitRankContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitRankContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitRankRequest = null;
                        object.businessUnitRankReception = null;
                    }
                    if (message.businessUnitRankRequest != null && message.hasOwnProperty("businessUnitRankRequest"))
                        object.businessUnitRankRequest = $root.mtechnavi.api.company.BusinessUnitRankRequest.toObject(message.businessUnitRankRequest, options);
                    if (message.businessUnitRankReception != null && message.hasOwnProperty("businessUnitRankReception"))
                        object.businessUnitRankReception = $root.mtechnavi.api.company.BusinessUnitRankReception.toObject(message.businessUnitRankReception, options);
                    return object;
                };

                /**
                 * Converts this BusinessUnitRankContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.BusinessUnitRankContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitRankContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitRankContent;
            })();

            company.CompanyBatchService = (function() {

                /**
                 * Constructs a new CompanyBatchService service.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a CompanyBatchService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function CompanyBatchService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (CompanyBatchService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CompanyBatchService;

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskLinkBusinessUnitManagement}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskLinkBusinessUnitManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskLinkBusinessUnitManagement.
                 * @function taskLinkBusinessUnitManagement
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskLinkBusinessUnitManagementRequest} request TaskLinkBusinessUnitManagementRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskLinkBusinessUnitManagementCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskLinkBusinessUnitManagement = function taskLinkBusinessUnitManagement(request, callback) {
                    return this.rpcCall(taskLinkBusinessUnitManagement, $root.mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskLinkBusinessUnitManagement" });

                /**
                 * Calls TaskLinkBusinessUnitManagement.
                 * @function taskLinkBusinessUnitManagement
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskLinkBusinessUnitManagementRequest} request TaskLinkBusinessUnitManagementRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskCreateHistory}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskCreateHistoryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskCreateHistory.
                 * @function taskCreateHistory
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateHistoryRequest} request TaskCreateHistoryRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskCreateHistoryCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskCreateHistory = function taskCreateHistory(request, callback) {
                    return this.rpcCall(taskCreateHistory, $root.mtechnavi.api.company.TaskCreateHistoryRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskCreateHistory" });

                /**
                 * Calls TaskCreateHistory.
                 * @function taskCreateHistory
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateHistoryRequest} request TaskCreateHistoryRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskNotification}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskNotificationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskNotification.
                 * @function taskNotification
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskNotificationRequest} request TaskNotificationRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskNotificationCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskNotification = function taskNotification(request, callback) {
                    return this.rpcCall(taskNotification, $root.mtechnavi.api.company.TaskNotificationRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskNotification" });

                /**
                 * Calls TaskNotification.
                 * @function taskNotification
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskNotificationRequest} request TaskNotificationRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskCreateMainContact}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskCreateMainContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskCreateMainContact.
                 * @function taskCreateMainContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateMainContactRequest} request TaskCreateMainContactRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskCreateMainContactCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskCreateMainContact = function taskCreateMainContact(request, callback) {
                    return this.rpcCall(taskCreateMainContact, $root.mtechnavi.api.company.TaskCreateMainContactRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskCreateMainContact" });

                /**
                 * Calls TaskCreateMainContact.
                 * @function taskCreateMainContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateMainContactRequest} request TaskCreateMainContactRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskSharetoBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskSharetoBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskSharetoBusinessUnitContact.
                 * @function taskSharetoBusinessUnitContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskSharetoBusinessUnitContactRequest} request TaskSharetoBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskSharetoBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskSharetoBusinessUnitContact = function taskSharetoBusinessUnitContact(request, callback) {
                    return this.rpcCall(taskSharetoBusinessUnitContact, $root.mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskSharetoBusinessUnitContact" });

                /**
                 * Calls TaskSharetoBusinessUnitContact.
                 * @function taskSharetoBusinessUnitContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskSharetoBusinessUnitContactRequest} request TaskSharetoBusinessUnitContactRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskCreateBusinessUnitProfile}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskCreateBusinessUnitProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskCreateBusinessUnitProfile.
                 * @function taskCreateBusinessUnitProfile
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateBusinessUnitProfileRequest} request TaskCreateBusinessUnitProfileRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskCreateBusinessUnitProfileCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskCreateBusinessUnitProfile = function taskCreateBusinessUnitProfile(request, callback) {
                    return this.rpcCall(taskCreateBusinessUnitProfile, $root.mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskCreateBusinessUnitProfile" });

                /**
                 * Calls TaskCreateBusinessUnitProfile.
                 * @function taskCreateBusinessUnitProfile
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskCreateBusinessUnitProfileRequest} request TaskCreateBusinessUnitProfileRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.company.CompanyBatchService#taskSharetoImportedBusinessUnitContact}.
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @typedef TaskSharetoImportedBusinessUnitContactCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls TaskSharetoImportedBusinessUnitContact.
                 * @function taskSharetoImportedBusinessUnitContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskSharetoImportedBusinessUnitContactRequest} request TaskSharetoImportedBusinessUnitContactRequest message or plain object
                 * @param {mtechnavi.api.company.CompanyBatchService.TaskSharetoImportedBusinessUnitContactCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(CompanyBatchService.prototype.taskSharetoImportedBusinessUnitContact = function taskSharetoImportedBusinessUnitContact(request, callback) {
                    return this.rpcCall(taskSharetoImportedBusinessUnitContact, $root.mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "TaskSharetoImportedBusinessUnitContact" });

                /**
                 * Calls TaskSharetoImportedBusinessUnitContact.
                 * @function taskSharetoImportedBusinessUnitContact
                 * @memberof mtechnavi.api.company.CompanyBatchService
                 * @instance
                 * @param {mtechnavi.api.company.ITaskSharetoImportedBusinessUnitContactRequest} request TaskSharetoImportedBusinessUnitContactRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return CompanyBatchService;
            })();

            company.TaskLinkBusinessUnitManagementRequest = (function() {

                /**
                 * Properties of a TaskLinkBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskLinkBusinessUnitManagementRequest
                 * @property {string|null} [businessUnitManagementId] TaskLinkBusinessUnitManagementRequest businessUnitManagementId
                 * @property {string|null} [linkTenantId] TaskLinkBusinessUnitManagementRequest linkTenantId
                 * @property {string|null} [companyDisplayName] TaskLinkBusinessUnitManagementRequest companyDisplayName
                 * @property {string|null} [inviteBy] TaskLinkBusinessUnitManagementRequest inviteBy
                 * @property {sharelib.IUserReference|null} [adminUser] TaskLinkBusinessUnitManagementRequest adminUser
                 * @property {Long|null} [linkedAt] TaskLinkBusinessUnitManagementRequest linkedAt
                 */

                /**
                 * Constructs a new TaskLinkBusinessUnitManagementRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskLinkBusinessUnitManagementRequest.
                 * @implements ITaskLinkBusinessUnitManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskLinkBusinessUnitManagementRequest=} [properties] Properties to set
                 */
                function TaskLinkBusinessUnitManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskLinkBusinessUnitManagementRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.businessUnitManagementId = "";

                /**
                 * TaskLinkBusinessUnitManagementRequest linkTenantId.
                 * @member {string} linkTenantId
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.linkTenantId = "";

                /**
                 * TaskLinkBusinessUnitManagementRequest companyDisplayName.
                 * @member {string} companyDisplayName
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.companyDisplayName = "";

                /**
                 * TaskLinkBusinessUnitManagementRequest inviteBy.
                 * @member {string} inviteBy
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.inviteBy = "";

                /**
                 * TaskLinkBusinessUnitManagementRequest adminUser.
                 * @member {sharelib.IUserReference|null|undefined} adminUser
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.adminUser = null;

                /**
                 * TaskLinkBusinessUnitManagementRequest linkedAt.
                 * @member {Long} linkedAt
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.linkedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a TaskLinkBusinessUnitManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskLinkBusinessUnitManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.linkTenantId != null && message.hasOwnProperty("linkTenantId"))
                        if (!$util.isString(message.linkTenantId))
                            return "linkTenantId: string expected";
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName"))
                        if (!$util.isString(message.companyDisplayName))
                            return "companyDisplayName: string expected";
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        if (!$util.isString(message.inviteBy))
                            return "inviteBy: string expected";
                    if (message.adminUser != null && message.hasOwnProperty("adminUser")) {
                        let error = $root.sharelib.UserReference.verify(message.adminUser);
                        if (error)
                            return "adminUser." + error;
                    }
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (!$util.isInteger(message.linkedAt) && !(message.linkedAt && $util.isInteger(message.linkedAt.low) && $util.isInteger(message.linkedAt.high)))
                            return "linkedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TaskLinkBusinessUnitManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest} TaskLinkBusinessUnitManagementRequest
                 */
                TaskLinkBusinessUnitManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.linkTenantId != null)
                        message.linkTenantId = String(object.linkTenantId);
                    if (object.companyDisplayName != null)
                        message.companyDisplayName = String(object.companyDisplayName);
                    if (object.inviteBy != null)
                        message.inviteBy = String(object.inviteBy);
                    if (object.adminUser != null) {
                        if (typeof object.adminUser !== "object")
                            throw TypeError(".mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest.adminUser: object expected");
                        message.adminUser = $root.sharelib.UserReference.fromObject(object.adminUser);
                    }
                    if (object.linkedAt != null)
                        if ($util.Long)
                            (message.linkedAt = $util.Long.fromValue(object.linkedAt)).unsigned = false;
                        else if (typeof object.linkedAt === "string")
                            message.linkedAt = parseInt(object.linkedAt, 10);
                        else if (typeof object.linkedAt === "number")
                            message.linkedAt = object.linkedAt;
                        else if (typeof object.linkedAt === "object")
                            message.linkedAt = new $util.LongBits(object.linkedAt.low >>> 0, object.linkedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a TaskLinkBusinessUnitManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest} message TaskLinkBusinessUnitManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskLinkBusinessUnitManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitManagementId = "";
                        object.linkTenantId = "";
                        object.inviteBy = "";
                        object.companyDisplayName = "";
                        object.adminUser = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.linkedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.linkedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.linkTenantId != null && message.hasOwnProperty("linkTenantId"))
                        object.linkTenantId = message.linkTenantId;
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        object.inviteBy = message.inviteBy;
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName"))
                        object.companyDisplayName = message.companyDisplayName;
                    if (message.adminUser != null && message.hasOwnProperty("adminUser"))
                        object.adminUser = $root.sharelib.UserReference.toObject(message.adminUser, options);
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (typeof message.linkedAt === "number")
                            object.linkedAt = options.longs === String ? String(message.linkedAt) : message.linkedAt;
                        else
                            object.linkedAt = options.longs === String ? $util.Long.prototype.toString.call(message.linkedAt) : options.longs === Number ? new $util.LongBits(message.linkedAt.low >>> 0, message.linkedAt.high >>> 0).toNumber() : message.linkedAt;
                    return object;
                };

                /**
                 * Converts this TaskLinkBusinessUnitManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskLinkBusinessUnitManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskLinkBusinessUnitManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskLinkBusinessUnitManagementRequest;
            })();

            company.TaskCreateHistoryRequest = (function() {

                /**
                 * Properties of a TaskCreateHistoryRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskCreateHistoryRequest
                 * @property {string|null} [tenantId] TaskCreateHistoryRequest tenantId
                 * @property {string|null} [historyCategoryName] TaskCreateHistoryRequest historyCategoryName
                 * @property {string|null} [contentName] TaskCreateHistoryRequest contentName
                 * @property {Array.<string>|null} [contentParamaters] TaskCreateHistoryRequest contentParamaters
                 * @property {Long|null} [occurredAt] TaskCreateHistoryRequest occurredAt
                 * @property {Long|null} [level] TaskCreateHistoryRequest level
                 * @property {string|null} [typeName] TaskCreateHistoryRequest typeName
                 * @property {string|null} [recordId] TaskCreateHistoryRequest recordId
                 */

                /**
                 * Constructs a new TaskCreateHistoryRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskCreateHistoryRequest.
                 * @implements ITaskCreateHistoryRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskCreateHistoryRequest=} [properties] Properties to set
                 */
                function TaskCreateHistoryRequest(properties) {
                    this.contentParamaters = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskCreateHistoryRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.tenantId = "";

                /**
                 * TaskCreateHistoryRequest historyCategoryName.
                 * @member {string} historyCategoryName
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.historyCategoryName = "";

                /**
                 * TaskCreateHistoryRequest contentName.
                 * @member {string} contentName
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.contentName = "";

                /**
                 * TaskCreateHistoryRequest contentParamaters.
                 * @member {Array.<string>} contentParamaters
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.contentParamaters = $util.emptyArray;

                /**
                 * TaskCreateHistoryRequest occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TaskCreateHistoryRequest level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TaskCreateHistoryRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.typeName = "";

                /**
                 * TaskCreateHistoryRequest recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 */
                TaskCreateHistoryRequest.prototype.recordId = "";

                /**
                 * Verifies a TaskCreateHistoryRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskCreateHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        if (!$util.isString(message.historyCategoryName))
                            return "historyCategoryName: string expected";
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        if (!$util.isString(message.contentName))
                            return "contentName: string expected";
                    if (message.contentParamaters != null && message.hasOwnProperty("contentParamaters")) {
                        if (!Array.isArray(message.contentParamaters))
                            return "contentParamaters: array expected";
                        for (let i = 0; i < message.contentParamaters.length; ++i)
                            if (!$util.isString(message.contentParamaters[i]))
                                return "contentParamaters: string[] expected";
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    return null;
                };

                /**
                 * Creates a TaskCreateHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskCreateHistoryRequest} TaskCreateHistoryRequest
                 */
                TaskCreateHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskCreateHistoryRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskCreateHistoryRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.historyCategoryName != null)
                        message.historyCategoryName = String(object.historyCategoryName);
                    if (object.contentName != null)
                        message.contentName = String(object.contentName);
                    if (object.contentParamaters) {
                        if (!Array.isArray(object.contentParamaters))
                            throw TypeError(".mtechnavi.api.company.TaskCreateHistoryRequest.contentParamaters: array expected");
                        message.contentParamaters = [];
                        for (let i = 0; i < object.contentParamaters.length; ++i)
                            message.contentParamaters[i] = String(object.contentParamaters[i]);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    return message;
                };

                /**
                 * Creates a plain object from a TaskCreateHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskCreateHistoryRequest} message TaskCreateHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskCreateHistoryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.contentParamaters = [];
                    if (options.defaults) {
                        object.tenantId = "";
                        object.historyCategoryName = "";
                        object.contentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        object.historyCategoryName = message.historyCategoryName;
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        object.contentName = message.contentName;
                    if (message.contentParamaters && message.contentParamaters.length) {
                        object.contentParamaters = [];
                        for (let j = 0; j < message.contentParamaters.length; ++j)
                            object.contentParamaters[j] = message.contentParamaters[j];
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    return object;
                };

                /**
                 * Converts this TaskCreateHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskCreateHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskCreateHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskCreateHistoryRequest;
            })();

            company.TaskNotificationRequest = (function() {

                /**
                 * Properties of a TaskNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskNotificationRequest
                 * @property {string|null} [tenantId] TaskNotificationRequest tenantId
                 * @property {string|null} [notificationId] TaskNotificationRequest notificationId
                 * @property {string|null} [notificationCompanyName] TaskNotificationRequest notificationCompanyName
                 * @property {sharelib.IUserReference|null} [notificationUser] TaskNotificationRequest notificationUser
                 * @property {sharelib.INotificationSetting|null} [notificationSetting] TaskNotificationRequest notificationSetting
                 */

                /**
                 * Constructs a new TaskNotificationRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskNotificationRequest.
                 * @implements ITaskNotificationRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskNotificationRequest=} [properties] Properties to set
                 */
                function TaskNotificationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskNotificationRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 */
                TaskNotificationRequest.prototype.tenantId = "";

                /**
                 * TaskNotificationRequest notificationId.
                 * @member {string} notificationId
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 */
                TaskNotificationRequest.prototype.notificationId = "";

                /**
                 * TaskNotificationRequest notificationCompanyName.
                 * @member {string} notificationCompanyName
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 */
                TaskNotificationRequest.prototype.notificationCompanyName = "";

                /**
                 * TaskNotificationRequest notificationUser.
                 * @member {sharelib.IUserReference|null|undefined} notificationUser
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 */
                TaskNotificationRequest.prototype.notificationUser = null;

                /**
                 * TaskNotificationRequest notificationSetting.
                 * @member {sharelib.INotificationSetting|null|undefined} notificationSetting
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 */
                TaskNotificationRequest.prototype.notificationSetting = null;

                /**
                 * Verifies a TaskNotificationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskNotificationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                        if (!$util.isString(message.notificationId))
                            return "notificationId: string expected";
                    if (message.notificationCompanyName != null && message.hasOwnProperty("notificationCompanyName"))
                        if (!$util.isString(message.notificationCompanyName))
                            return "notificationCompanyName: string expected";
                    if (message.notificationUser != null && message.hasOwnProperty("notificationUser")) {
                        let error = $root.sharelib.UserReference.verify(message.notificationUser);
                        if (error)
                            return "notificationUser." + error;
                    }
                    if (message.notificationSetting != null && message.hasOwnProperty("notificationSetting")) {
                        let error = $root.sharelib.NotificationSetting.verify(message.notificationSetting);
                        if (error)
                            return "notificationSetting." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TaskNotificationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskNotificationRequest} TaskNotificationRequest
                 */
                TaskNotificationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskNotificationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskNotificationRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.notificationId != null)
                        message.notificationId = String(object.notificationId);
                    if (object.notificationCompanyName != null)
                        message.notificationCompanyName = String(object.notificationCompanyName);
                    if (object.notificationUser != null) {
                        if (typeof object.notificationUser !== "object")
                            throw TypeError(".mtechnavi.api.company.TaskNotificationRequest.notificationUser: object expected");
                        message.notificationUser = $root.sharelib.UserReference.fromObject(object.notificationUser);
                    }
                    if (object.notificationSetting != null) {
                        if (typeof object.notificationSetting !== "object")
                            throw TypeError(".mtechnavi.api.company.TaskNotificationRequest.notificationSetting: object expected");
                        message.notificationSetting = $root.sharelib.NotificationSetting.fromObject(object.notificationSetting);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TaskNotificationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskNotificationRequest} message TaskNotificationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskNotificationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.notificationId = "";
                        object.notificationCompanyName = "";
                        object.notificationUser = null;
                        object.notificationSetting = null;
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                        object.notificationId = message.notificationId;
                    if (message.notificationCompanyName != null && message.hasOwnProperty("notificationCompanyName"))
                        object.notificationCompanyName = message.notificationCompanyName;
                    if (message.notificationUser != null && message.hasOwnProperty("notificationUser"))
                        object.notificationUser = $root.sharelib.UserReference.toObject(message.notificationUser, options);
                    if (message.notificationSetting != null && message.hasOwnProperty("notificationSetting"))
                        object.notificationSetting = $root.sharelib.NotificationSetting.toObject(message.notificationSetting, options);
                    return object;
                };

                /**
                 * Converts this TaskNotificationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskNotificationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskNotificationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskNotificationRequest;
            })();

            company.TaskCreateMainContactRequest = (function() {

                /**
                 * Properties of a TaskCreateMainContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskCreateMainContactRequest
                 * @property {string|null} [tenantId] TaskCreateMainContactRequest tenantId
                 * @property {string|null} [businessUnitManagementId] TaskCreateMainContactRequest businessUnitManagementId
                 * @property {sharelib.IUserReference|null} [mainContact] TaskCreateMainContactRequest mainContact
                 */

                /**
                 * Constructs a new TaskCreateMainContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskCreateMainContactRequest.
                 * @implements ITaskCreateMainContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskCreateMainContactRequest=} [properties] Properties to set
                 */
                function TaskCreateMainContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskCreateMainContactRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @instance
                 */
                TaskCreateMainContactRequest.prototype.tenantId = "";

                /**
                 * TaskCreateMainContactRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @instance
                 */
                TaskCreateMainContactRequest.prototype.businessUnitManagementId = "";

                /**
                 * TaskCreateMainContactRequest mainContact.
                 * @member {sharelib.IUserReference|null|undefined} mainContact
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @instance
                 */
                TaskCreateMainContactRequest.prototype.mainContact = null;

                /**
                 * Verifies a TaskCreateMainContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskCreateMainContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.mainContact != null && message.hasOwnProperty("mainContact")) {
                        let error = $root.sharelib.UserReference.verify(message.mainContact);
                        if (error)
                            return "mainContact." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TaskCreateMainContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskCreateMainContactRequest} TaskCreateMainContactRequest
                 */
                TaskCreateMainContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskCreateMainContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskCreateMainContactRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.mainContact != null) {
                        if (typeof object.mainContact !== "object")
                            throw TypeError(".mtechnavi.api.company.TaskCreateMainContactRequest.mainContact: object expected");
                        message.mainContact = $root.sharelib.UserReference.fromObject(object.mainContact);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TaskCreateMainContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskCreateMainContactRequest} message TaskCreateMainContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskCreateMainContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.businessUnitManagementId = "";
                        object.mainContact = null;
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.mainContact != null && message.hasOwnProperty("mainContact"))
                        object.mainContact = $root.sharelib.UserReference.toObject(message.mainContact, options);
                    return object;
                };

                /**
                 * Converts this TaskCreateMainContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskCreateMainContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskCreateMainContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskCreateMainContactRequest;
            })();

            company.TaskSharetoBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a TaskSharetoBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskSharetoBusinessUnitContactRequest
                 * @property {string|null} [tenantId] TaskSharetoBusinessUnitContactRequest tenantId
                 * @property {string|null} [businessUnitManagementId] TaskSharetoBusinessUnitContactRequest businessUnitManagementId
                 * @property {Long|null} [linkedAt] TaskSharetoBusinessUnitContactRequest linkedAt
                 */

                /**
                 * Constructs a new TaskSharetoBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskSharetoBusinessUnitContactRequest.
                 * @implements ITaskSharetoBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskSharetoBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function TaskSharetoBusinessUnitContactRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskSharetoBusinessUnitContactRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @instance
                 */
                TaskSharetoBusinessUnitContactRequest.prototype.tenantId = "";

                /**
                 * TaskSharetoBusinessUnitContactRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @instance
                 */
                TaskSharetoBusinessUnitContactRequest.prototype.businessUnitManagementId = "";

                /**
                 * TaskSharetoBusinessUnitContactRequest linkedAt.
                 * @member {Long} linkedAt
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @instance
                 */
                TaskSharetoBusinessUnitContactRequest.prototype.linkedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a TaskSharetoBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskSharetoBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (!$util.isInteger(message.linkedAt) && !(message.linkedAt && $util.isInteger(message.linkedAt.low) && $util.isInteger(message.linkedAt.high)))
                            return "linkedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TaskSharetoBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest} TaskSharetoBusinessUnitContactRequest
                 */
                TaskSharetoBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.linkedAt != null)
                        if ($util.Long)
                            (message.linkedAt = $util.Long.fromValue(object.linkedAt)).unsigned = false;
                        else if (typeof object.linkedAt === "string")
                            message.linkedAt = parseInt(object.linkedAt, 10);
                        else if (typeof object.linkedAt === "number")
                            message.linkedAt = object.linkedAt;
                        else if (typeof object.linkedAt === "object")
                            message.linkedAt = new $util.LongBits(object.linkedAt.low >>> 0, object.linkedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a TaskSharetoBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest} message TaskSharetoBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskSharetoBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.businessUnitManagementId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.linkedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.linkedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (typeof message.linkedAt === "number")
                            object.linkedAt = options.longs === String ? String(message.linkedAt) : message.linkedAt;
                        else
                            object.linkedAt = options.longs === String ? $util.Long.prototype.toString.call(message.linkedAt) : options.longs === Number ? new $util.LongBits(message.linkedAt.low >>> 0, message.linkedAt.high >>> 0).toNumber() : message.linkedAt;
                    return object;
                };

                /**
                 * Converts this TaskSharetoBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskSharetoBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskSharetoBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskSharetoBusinessUnitContactRequest;
            })();

            company.TaskCreateBusinessUnitProfileRequest = (function() {

                /**
                 * Properties of a TaskCreateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskCreateBusinessUnitProfileRequest
                 * @property {string|null} [tenantId] TaskCreateBusinessUnitProfileRequest tenantId
                 * @property {string|null} [companyDisplayName] TaskCreateBusinessUnitProfileRequest companyDisplayName
                 */

                /**
                 * Constructs a new TaskCreateBusinessUnitProfileRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskCreateBusinessUnitProfileRequest.
                 * @implements ITaskCreateBusinessUnitProfileRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskCreateBusinessUnitProfileRequest=} [properties] Properties to set
                 */
                function TaskCreateBusinessUnitProfileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskCreateBusinessUnitProfileRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @instance
                 */
                TaskCreateBusinessUnitProfileRequest.prototype.tenantId = "";

                /**
                 * TaskCreateBusinessUnitProfileRequest companyDisplayName.
                 * @member {string} companyDisplayName
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @instance
                 */
                TaskCreateBusinessUnitProfileRequest.prototype.companyDisplayName = "";

                /**
                 * Verifies a TaskCreateBusinessUnitProfileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskCreateBusinessUnitProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName"))
                        if (!$util.isString(message.companyDisplayName))
                            return "companyDisplayName: string expected";
                    return null;
                };

                /**
                 * Creates a TaskCreateBusinessUnitProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest} TaskCreateBusinessUnitProfileRequest
                 */
                TaskCreateBusinessUnitProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.companyDisplayName != null)
                        message.companyDisplayName = String(object.companyDisplayName);
                    return message;
                };

                /**
                 * Creates a plain object from a TaskCreateBusinessUnitProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest} message TaskCreateBusinessUnitProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskCreateBusinessUnitProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.companyDisplayName = "";
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.companyDisplayName != null && message.hasOwnProperty("companyDisplayName"))
                        object.companyDisplayName = message.companyDisplayName;
                    return object;
                };

                /**
                 * Converts this TaskCreateBusinessUnitProfileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskCreateBusinessUnitProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskCreateBusinessUnitProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskCreateBusinessUnitProfileRequest;
            })();

            company.TaskSharetoImportedBusinessUnitContactRequest = (function() {

                /**
                 * Properties of a TaskSharetoImportedBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @interface ITaskSharetoImportedBusinessUnitContactRequest
                 * @property {string|null} [tenantId] TaskSharetoImportedBusinessUnitContactRequest tenantId
                 * @property {Array.<string>|null} [businessUnitManagementIds] TaskSharetoImportedBusinessUnitContactRequest businessUnitManagementIds
                 */

                /**
                 * Constructs a new TaskSharetoImportedBusinessUnitContactRequest.
                 * @memberof mtechnavi.api.company
                 * @classdesc Represents a TaskSharetoImportedBusinessUnitContactRequest.
                 * @implements ITaskSharetoImportedBusinessUnitContactRequest
                 * @constructor
                 * @param {mtechnavi.api.company.ITaskSharetoImportedBusinessUnitContactRequest=} [properties] Properties to set
                 */
                function TaskSharetoImportedBusinessUnitContactRequest(properties) {
                    this.businessUnitManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskSharetoImportedBusinessUnitContactRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @instance
                 */
                TaskSharetoImportedBusinessUnitContactRequest.prototype.tenantId = "";

                /**
                 * TaskSharetoImportedBusinessUnitContactRequest businessUnitManagementIds.
                 * @member {Array.<string>} businessUnitManagementIds
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @instance
                 */
                TaskSharetoImportedBusinessUnitContactRequest.prototype.businessUnitManagementIds = $util.emptyArray;

                /**
                 * Verifies a TaskSharetoImportedBusinessUnitContactRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskSharetoImportedBusinessUnitContactRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.businessUnitManagementIds != null && message.hasOwnProperty("businessUnitManagementIds")) {
                        if (!Array.isArray(message.businessUnitManagementIds))
                            return "businessUnitManagementIds: array expected";
                        for (let i = 0; i < message.businessUnitManagementIds.length; ++i)
                            if (!$util.isString(message.businessUnitManagementIds[i]))
                                return "businessUnitManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a TaskSharetoImportedBusinessUnitContactRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest} TaskSharetoImportedBusinessUnitContactRequest
                 */
                TaskSharetoImportedBusinessUnitContactRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest)
                        return object;
                    let message = new $root.mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.businessUnitManagementIds) {
                        if (!Array.isArray(object.businessUnitManagementIds))
                            throw TypeError(".mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest.businessUnitManagementIds: array expected");
                        message.businessUnitManagementIds = [];
                        for (let i = 0; i < object.businessUnitManagementIds.length; ++i)
                            message.businessUnitManagementIds[i] = String(object.businessUnitManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TaskSharetoImportedBusinessUnitContactRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @static
                 * @param {mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest} message TaskSharetoImportedBusinessUnitContactRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskSharetoImportedBusinessUnitContactRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.businessUnitManagementIds = [];
                    if (options.defaults)
                        object.tenantId = "";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.businessUnitManagementIds && message.businessUnitManagementIds.length) {
                        object.businessUnitManagementIds = [];
                        for (let j = 0; j < message.businessUnitManagementIds.length; ++j)
                            object.businessUnitManagementIds[j] = message.businessUnitManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this TaskSharetoImportedBusinessUnitContactRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.company.TaskSharetoImportedBusinessUnitContactRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskSharetoImportedBusinessUnitContactRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TaskSharetoImportedBusinessUnitContactRequest;
            })();

            return company;
        })();

        api.dlock = (function() {

            /**
             * Namespace dlock.
             * @memberof mtechnavi.api
             * @namespace
             */
            const dlock = {};

            dlock.Lock = (function() {

                /**
                 * Properties of a Lock.
                 * @memberof mtechnavi.api.dlock
                 * @interface ILock
                 * @property {string|null} [key] Lock key
                 * @property {string|null} [lockId] Lock lockId
                 * @property {string|null} [lockedBy] Lock lockedBy
                 * @property {Long|null} [expiredAt] Lock expiredAt
                 */

                /**
                 * Constructs a new Lock.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents a Lock.
                 * @implements ILock
                 * @constructor
                 * @param {mtechnavi.api.dlock.ILock=} [properties] Properties to set
                 */
                function Lock(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Lock key.
                 * @member {string} key
                 * @memberof mtechnavi.api.dlock.Lock
                 * @instance
                 */
                Lock.prototype.key = "";

                /**
                 * Lock lockId.
                 * @member {string} lockId
                 * @memberof mtechnavi.api.dlock.Lock
                 * @instance
                 */
                Lock.prototype.lockId = "";

                /**
                 * Lock lockedBy.
                 * @member {string} lockedBy
                 * @memberof mtechnavi.api.dlock.Lock
                 * @instance
                 */
                Lock.prototype.lockedBy = "";

                /**
                 * Lock expiredAt.
                 * @member {Long} expiredAt
                 * @memberof mtechnavi.api.dlock.Lock
                 * @instance
                 */
                Lock.prototype.expiredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Lock message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.Lock
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Lock.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        if (!$util.isString(message.lockId))
                            return "lockId: string expected";
                    if (message.lockedBy != null && message.hasOwnProperty("lockedBy"))
                        if (!$util.isString(message.lockedBy))
                            return "lockedBy: string expected";
                    if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                        if (!$util.isInteger(message.expiredAt) && !(message.expiredAt && $util.isInteger(message.expiredAt.low) && $util.isInteger(message.expiredAt.high)))
                            return "expiredAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Lock message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.Lock
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.Lock} Lock
                 */
                Lock.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.Lock)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.Lock();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.lockId != null)
                        message.lockId = String(object.lockId);
                    if (object.lockedBy != null)
                        message.lockedBy = String(object.lockedBy);
                    if (object.expiredAt != null)
                        if ($util.Long)
                            (message.expiredAt = $util.Long.fromValue(object.expiredAt)).unsigned = false;
                        else if (typeof object.expiredAt === "string")
                            message.expiredAt = parseInt(object.expiredAt, 10);
                        else if (typeof object.expiredAt === "number")
                            message.expiredAt = object.expiredAt;
                        else if (typeof object.expiredAt === "object")
                            message.expiredAt = new $util.LongBits(object.expiredAt.low >>> 0, object.expiredAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Lock message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.Lock
                 * @static
                 * @param {mtechnavi.api.dlock.Lock} message Lock
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Lock.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.lockId = "";
                        object.lockedBy = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expiredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expiredAt = options.longs === String ? "0" : 0;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        object.lockId = message.lockId;
                    if (message.lockedBy != null && message.hasOwnProperty("lockedBy"))
                        object.lockedBy = message.lockedBy;
                    if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                        if (typeof message.expiredAt === "number")
                            object.expiredAt = options.longs === String ? String(message.expiredAt) : message.expiredAt;
                        else
                            object.expiredAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiredAt) : options.longs === Number ? new $util.LongBits(message.expiredAt.low >>> 0, message.expiredAt.high >>> 0).toNumber() : message.expiredAt;
                    return object;
                };

                /**
                 * Converts this Lock to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.Lock
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Lock.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Lock;
            })();

            /**
             * LockStatus enum.
             * @name mtechnavi.api.dlock.LockStatus
             * @enum {number}
             * @property {number} AVAILABLE=0 AVAILABLE value
             * @property {number} LOCKED_BY_ME=1 LOCKED_BY_ME value
             * @property {number} LOCKED_BY_OTHER=2 LOCKED_BY_OTHER value
             */
            dlock.LockStatus = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "AVAILABLE"] = 0;
                values[valuesById[1] = "LOCKED_BY_ME"] = 1;
                values[valuesById[2] = "LOCKED_BY_OTHER"] = 2;
                return values;
            })();

            dlock.AcquireLockRequest = (function() {

                /**
                 * Properties of an AcquireLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @interface IAcquireLockRequest
                 * @property {string|null} [key] AcquireLockRequest key
                 * @property {Long|null} [expire] AcquireLockRequest expire
                 */

                /**
                 * Constructs a new AcquireLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents an AcquireLockRequest.
                 * @implements IAcquireLockRequest
                 * @constructor
                 * @param {mtechnavi.api.dlock.IAcquireLockRequest=} [properties] Properties to set
                 */
                function AcquireLockRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AcquireLockRequest key.
                 * @member {string} key
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @instance
                 */
                AcquireLockRequest.prototype.key = "";

                /**
                 * AcquireLockRequest expire.
                 * @member {Long} expire
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @instance
                 */
                AcquireLockRequest.prototype.expire = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an AcquireLockRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcquireLockRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.expire != null && message.hasOwnProperty("expire"))
                        if (!$util.isInteger(message.expire) && !(message.expire && $util.isInteger(message.expire.low) && $util.isInteger(message.expire.high)))
                            return "expire: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an AcquireLockRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.AcquireLockRequest} AcquireLockRequest
                 */
                AcquireLockRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.AcquireLockRequest)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.AcquireLockRequest();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.expire != null)
                        if ($util.Long)
                            (message.expire = $util.Long.fromValue(object.expire)).unsigned = false;
                        else if (typeof object.expire === "string")
                            message.expire = parseInt(object.expire, 10);
                        else if (typeof object.expire === "number")
                            message.expire = object.expire;
                        else if (typeof object.expire === "object")
                            message.expire = new $util.LongBits(object.expire.low >>> 0, object.expire.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an AcquireLockRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @static
                 * @param {mtechnavi.api.dlock.AcquireLockRequest} message AcquireLockRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcquireLockRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expire = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expire = options.longs === String ? "0" : 0;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.expire != null && message.hasOwnProperty("expire"))
                        if (typeof message.expire === "number")
                            object.expire = options.longs === String ? String(message.expire) : message.expire;
                        else
                            object.expire = options.longs === String ? $util.Long.prototype.toString.call(message.expire) : options.longs === Number ? new $util.LongBits(message.expire.low >>> 0, message.expire.high >>> 0).toNumber() : message.expire;
                    return object;
                };

                /**
                 * Converts this AcquireLockRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.AcquireLockRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcquireLockRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AcquireLockRequest;
            })();

            dlock.GetLockStatusRequest = (function() {

                /**
                 * Properties of a GetLockStatusRequest.
                 * @memberof mtechnavi.api.dlock
                 * @interface IGetLockStatusRequest
                 * @property {string|null} [key] GetLockStatusRequest key
                 */

                /**
                 * Constructs a new GetLockStatusRequest.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents a GetLockStatusRequest.
                 * @implements IGetLockStatusRequest
                 * @constructor
                 * @param {mtechnavi.api.dlock.IGetLockStatusRequest=} [properties] Properties to set
                 */
                function GetLockStatusRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetLockStatusRequest key.
                 * @member {string} key
                 * @memberof mtechnavi.api.dlock.GetLockStatusRequest
                 * @instance
                 */
                GetLockStatusRequest.prototype.key = "";

                /**
                 * Verifies a GetLockStatusRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.GetLockStatusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetLockStatusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    return null;
                };

                /**
                 * Creates a GetLockStatusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.GetLockStatusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.GetLockStatusRequest} GetLockStatusRequest
                 */
                GetLockStatusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.GetLockStatusRequest)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.GetLockStatusRequest();
                    if (object.key != null)
                        message.key = String(object.key);
                    return message;
                };

                /**
                 * Creates a plain object from a GetLockStatusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.GetLockStatusRequest
                 * @static
                 * @param {mtechnavi.api.dlock.GetLockStatusRequest} message GetLockStatusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetLockStatusRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.key = "";
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    return object;
                };

                /**
                 * Converts this GetLockStatusRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.GetLockStatusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetLockStatusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetLockStatusRequest;
            })();

            dlock.GetLockStatusResponse = (function() {

                /**
                 * Properties of a GetLockStatusResponse.
                 * @memberof mtechnavi.api.dlock
                 * @interface IGetLockStatusResponse
                 * @property {mtechnavi.api.dlock.LockStatus|null} [status] GetLockStatusResponse status
                 */

                /**
                 * Constructs a new GetLockStatusResponse.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents a GetLockStatusResponse.
                 * @implements IGetLockStatusResponse
                 * @constructor
                 * @param {mtechnavi.api.dlock.IGetLockStatusResponse=} [properties] Properties to set
                 */
                function GetLockStatusResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetLockStatusResponse status.
                 * @member {mtechnavi.api.dlock.LockStatus} status
                 * @memberof mtechnavi.api.dlock.GetLockStatusResponse
                 * @instance
                 */
                GetLockStatusResponse.prototype.status = 0;

                /**
                 * Verifies a GetLockStatusResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.GetLockStatusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetLockStatusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetLockStatusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.GetLockStatusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.GetLockStatusResponse} GetLockStatusResponse
                 */
                GetLockStatusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.GetLockStatusResponse)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.GetLockStatusResponse();
                    switch (object.status) {
                    case "AVAILABLE":
                    case 0:
                        message.status = 0;
                        break;
                    case "LOCKED_BY_ME":
                    case 1:
                        message.status = 1;
                        break;
                    case "LOCKED_BY_OTHER":
                    case 2:
                        message.status = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetLockStatusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.GetLockStatusResponse
                 * @static
                 * @param {mtechnavi.api.dlock.GetLockStatusResponse} message GetLockStatusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetLockStatusResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.status = options.enums === String ? "AVAILABLE" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.mtechnavi.api.dlock.LockStatus[message.status] : message.status;
                    return object;
                };

                /**
                 * Converts this GetLockStatusResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.GetLockStatusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetLockStatusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetLockStatusResponse;
            })();

            dlock.ExtendLockRequest = (function() {

                /**
                 * Properties of an ExtendLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @interface IExtendLockRequest
                 * @property {string|null} [key] ExtendLockRequest key
                 * @property {string|null} [lockId] ExtendLockRequest lockId
                 * @property {Long|null} [expire] ExtendLockRequest expire
                 */

                /**
                 * Constructs a new ExtendLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents an ExtendLockRequest.
                 * @implements IExtendLockRequest
                 * @constructor
                 * @param {mtechnavi.api.dlock.IExtendLockRequest=} [properties] Properties to set
                 */
                function ExtendLockRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtendLockRequest key.
                 * @member {string} key
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @instance
                 */
                ExtendLockRequest.prototype.key = "";

                /**
                 * ExtendLockRequest lockId.
                 * @member {string} lockId
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @instance
                 */
                ExtendLockRequest.prototype.lockId = "";

                /**
                 * ExtendLockRequest expire.
                 * @member {Long} expire
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @instance
                 */
                ExtendLockRequest.prototype.expire = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an ExtendLockRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtendLockRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        if (!$util.isString(message.lockId))
                            return "lockId: string expected";
                    if (message.expire != null && message.hasOwnProperty("expire"))
                        if (!$util.isInteger(message.expire) && !(message.expire && $util.isInteger(message.expire.low) && $util.isInteger(message.expire.high)))
                            return "expire: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an ExtendLockRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.ExtendLockRequest} ExtendLockRequest
                 */
                ExtendLockRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.ExtendLockRequest)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.ExtendLockRequest();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.lockId != null)
                        message.lockId = String(object.lockId);
                    if (object.expire != null)
                        if ($util.Long)
                            (message.expire = $util.Long.fromValue(object.expire)).unsigned = false;
                        else if (typeof object.expire === "string")
                            message.expire = parseInt(object.expire, 10);
                        else if (typeof object.expire === "number")
                            message.expire = object.expire;
                        else if (typeof object.expire === "object")
                            message.expire = new $util.LongBits(object.expire.low >>> 0, object.expire.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an ExtendLockRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @static
                 * @param {mtechnavi.api.dlock.ExtendLockRequest} message ExtendLockRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtendLockRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.lockId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.expire = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expire = options.longs === String ? "0" : 0;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        object.lockId = message.lockId;
                    if (message.expire != null && message.hasOwnProperty("expire"))
                        if (typeof message.expire === "number")
                            object.expire = options.longs === String ? String(message.expire) : message.expire;
                        else
                            object.expire = options.longs === String ? $util.Long.prototype.toString.call(message.expire) : options.longs === Number ? new $util.LongBits(message.expire.low >>> 0, message.expire.high >>> 0).toNumber() : message.expire;
                    return object;
                };

                /**
                 * Converts this ExtendLockRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.ExtendLockRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtendLockRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtendLockRequest;
            })();

            dlock.ExtendLockResponse = (function() {

                /**
                 * Properties of an ExtendLockResponse.
                 * @memberof mtechnavi.api.dlock
                 * @interface IExtendLockResponse
                 * @property {mtechnavi.api.dlock.ILock|null} [lock] ExtendLockResponse lock
                 */

                /**
                 * Constructs a new ExtendLockResponse.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents an ExtendLockResponse.
                 * @implements IExtendLockResponse
                 * @constructor
                 * @param {mtechnavi.api.dlock.IExtendLockResponse=} [properties] Properties to set
                 */
                function ExtendLockResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtendLockResponse lock.
                 * @member {mtechnavi.api.dlock.ILock|null|undefined} lock
                 * @memberof mtechnavi.api.dlock.ExtendLockResponse
                 * @instance
                 */
                ExtendLockResponse.prototype.lock = null;

                /**
                 * Verifies an ExtendLockResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.ExtendLockResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtendLockResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lock != null && message.hasOwnProperty("lock")) {
                        let error = $root.mtechnavi.api.dlock.Lock.verify(message.lock);
                        if (error)
                            return "lock." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExtendLockResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.ExtendLockResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.ExtendLockResponse} ExtendLockResponse
                 */
                ExtendLockResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.ExtendLockResponse)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.ExtendLockResponse();
                    if (object.lock != null) {
                        if (typeof object.lock !== "object")
                            throw TypeError(".mtechnavi.api.dlock.ExtendLockResponse.lock: object expected");
                        message.lock = $root.mtechnavi.api.dlock.Lock.fromObject(object.lock);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExtendLockResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.ExtendLockResponse
                 * @static
                 * @param {mtechnavi.api.dlock.ExtendLockResponse} message ExtendLockResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtendLockResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.lock = null;
                    if (message.lock != null && message.hasOwnProperty("lock"))
                        object.lock = $root.mtechnavi.api.dlock.Lock.toObject(message.lock, options);
                    return object;
                };

                /**
                 * Converts this ExtendLockResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.ExtendLockResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtendLockResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtendLockResponse;
            })();

            dlock.ReleaseLockRequest = (function() {

                /**
                 * Properties of a ReleaseLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @interface IReleaseLockRequest
                 * @property {string|null} [key] ReleaseLockRequest key
                 * @property {string|null} [lockId] ReleaseLockRequest lockId
                 */

                /**
                 * Constructs a new ReleaseLockRequest.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents a ReleaseLockRequest.
                 * @implements IReleaseLockRequest
                 * @constructor
                 * @param {mtechnavi.api.dlock.IReleaseLockRequest=} [properties] Properties to set
                 */
                function ReleaseLockRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReleaseLockRequest key.
                 * @member {string} key
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @instance
                 */
                ReleaseLockRequest.prototype.key = "";

                /**
                 * ReleaseLockRequest lockId.
                 * @member {string} lockId
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @instance
                 */
                ReleaseLockRequest.prototype.lockId = "";

                /**
                 * Verifies a ReleaseLockRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReleaseLockRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        if (!$util.isString(message.lockId))
                            return "lockId: string expected";
                    return null;
                };

                /**
                 * Creates a ReleaseLockRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.dlock.ReleaseLockRequest} ReleaseLockRequest
                 */
                ReleaseLockRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.dlock.ReleaseLockRequest)
                        return object;
                    let message = new $root.mtechnavi.api.dlock.ReleaseLockRequest();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.lockId != null)
                        message.lockId = String(object.lockId);
                    return message;
                };

                /**
                 * Creates a plain object from a ReleaseLockRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @static
                 * @param {mtechnavi.api.dlock.ReleaseLockRequest} message ReleaseLockRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReleaseLockRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.lockId = "";
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.lockId != null && message.hasOwnProperty("lockId"))
                        object.lockId = message.lockId;
                    return object;
                };

                /**
                 * Converts this ReleaseLockRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.dlock.ReleaseLockRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReleaseLockRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReleaseLockRequest;
            })();

            dlock.Locker = (function() {

                /**
                 * Constructs a new Locker service.
                 * @memberof mtechnavi.api.dlock
                 * @classdesc Represents a Locker
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Locker(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Locker.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Locker;

                /**
                 * Callback as used by {@link mtechnavi.api.dlock.Locker#acquireLock}.
                 * @memberof mtechnavi.api.dlock.Locker
                 * @typedef AcquireLockCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.dlock.Lock} [response] Lock
                 */

                /**
                 * Calls AcquireLock.
                 * @function acquireLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IAcquireLockRequest} request AcquireLockRequest message or plain object
                 * @param {mtechnavi.api.dlock.Locker.AcquireLockCallback} callback Node-style callback called with the error, if any, and Lock
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Locker.prototype.acquireLock = function acquireLock(request, callback) {
                    return this.rpcCall(acquireLock, $root.mtechnavi.api.dlock.AcquireLockRequest, $root.mtechnavi.api.dlock.Lock, request, callback);
                }, "name", { value: "AcquireLock" });

                /**
                 * Calls AcquireLock.
                 * @function acquireLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IAcquireLockRequest} request AcquireLockRequest message or plain object
                 * @returns {Promise<mtechnavi.api.dlock.Lock>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.dlock.Locker#getLockStatus}.
                 * @memberof mtechnavi.api.dlock.Locker
                 * @typedef GetLockStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.dlock.GetLockStatusResponse} [response] GetLockStatusResponse
                 */

                /**
                 * Calls GetLockStatus.
                 * @function getLockStatus
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IGetLockStatusRequest} request GetLockStatusRequest message or plain object
                 * @param {mtechnavi.api.dlock.Locker.GetLockStatusCallback} callback Node-style callback called with the error, if any, and GetLockStatusResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Locker.prototype.getLockStatus = function getLockStatus(request, callback) {
                    return this.rpcCall(getLockStatus, $root.mtechnavi.api.dlock.GetLockStatusRequest, $root.mtechnavi.api.dlock.GetLockStatusResponse, request, callback);
                }, "name", { value: "GetLockStatus" });

                /**
                 * Calls GetLockStatus.
                 * @function getLockStatus
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IGetLockStatusRequest} request GetLockStatusRequest message or plain object
                 * @returns {Promise<mtechnavi.api.dlock.GetLockStatusResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.dlock.Locker#extendLock}.
                 * @memberof mtechnavi.api.dlock.Locker
                 * @typedef ExtendLockCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.dlock.Lock} [response] Lock
                 */

                /**
                 * Calls ExtendLock.
                 * @function extendLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IExtendLockRequest} request ExtendLockRequest message or plain object
                 * @param {mtechnavi.api.dlock.Locker.ExtendLockCallback} callback Node-style callback called with the error, if any, and Lock
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Locker.prototype.extendLock = function extendLock(request, callback) {
                    return this.rpcCall(extendLock, $root.mtechnavi.api.dlock.ExtendLockRequest, $root.mtechnavi.api.dlock.Lock, request, callback);
                }, "name", { value: "ExtendLock" });

                /**
                 * Calls ExtendLock.
                 * @function extendLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IExtendLockRequest} request ExtendLockRequest message or plain object
                 * @returns {Promise<mtechnavi.api.dlock.Lock>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.dlock.Locker#releaseLock}.
                 * @memberof mtechnavi.api.dlock.Locker
                 * @typedef ReleaseLockCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls ReleaseLock.
                 * @function releaseLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IReleaseLockRequest} request ReleaseLockRequest message or plain object
                 * @param {mtechnavi.api.dlock.Locker.ReleaseLockCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Locker.prototype.releaseLock = function releaseLock(request, callback) {
                    return this.rpcCall(releaseLock, $root.mtechnavi.api.dlock.ReleaseLockRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "ReleaseLock" });

                /**
                 * Calls ReleaseLock.
                 * @function releaseLock
                 * @memberof mtechnavi.api.dlock.Locker
                 * @instance
                 * @param {mtechnavi.api.dlock.IReleaseLockRequest} request ReleaseLockRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return Locker;
            })();

            return dlock;
        })();

        api.estimation = (function() {

            /**
             * Namespace estimation.
             * @memberof mtechnavi.api
             * @namespace
             */
            const estimation = {};

            estimation.EstimationService = (function() {

                /**
                 * Constructs a new EstimationService service.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimationService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function EstimationService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (EstimationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EstimationService;

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimationService#listHistorys}.
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @typedef ListHistorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListHistorysResponse} [response] ListHistorysResponse
                 */

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimationService.ListHistorysCallback} callback Node-style callback called with the error, if any, and ListHistorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimationService.prototype.listHistorys = function listHistorys(request, callback) {
                    return this.rpcCall(listHistorys, $root.mtechnavi.api.estimation.ListHistorysRequest, $root.mtechnavi.api.estimation.ListHistorysResponse, request, callback);
                }, "name", { value: "ListHistorys" });

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListHistorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimationService#sharetoDiscard}.
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @typedef SharetoDiscardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoDiscardResponse} [response] SharetoDiscardResponse
                 */

                /**
                 * Calls SharetoDiscard.
                 * @function sharetoDiscard
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoDiscardRequest} request SharetoDiscardRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimationService.SharetoDiscardCallback} callback Node-style callback called with the error, if any, and SharetoDiscardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimationService.prototype.sharetoDiscard = function sharetoDiscard(request, callback) {
                    return this.rpcCall(sharetoDiscard, $root.mtechnavi.api.estimation.SharetoDiscardRequest, $root.mtechnavi.api.estimation.SharetoDiscardResponse, request, callback);
                }, "name", { value: "SharetoDiscard" });

                /**
                 * Calls SharetoDiscard.
                 * @function sharetoDiscard
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoDiscardRequest} request SharetoDiscardRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoDiscardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimationService#sharetoOpened}.
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @typedef SharetoOpenedCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoOpenedResponse} [response] SharetoOpenedResponse
                 */

                /**
                 * Calls SharetoOpened.
                 * @function sharetoOpened
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoOpenedRequest} request SharetoOpenedRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimationService.SharetoOpenedCallback} callback Node-style callback called with the error, if any, and SharetoOpenedResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimationService.prototype.sharetoOpened = function sharetoOpened(request, callback) {
                    return this.rpcCall(sharetoOpened, $root.mtechnavi.api.estimation.SharetoOpenedRequest, $root.mtechnavi.api.estimation.SharetoOpenedResponse, request, callback);
                }, "name", { value: "SharetoOpened" });

                /**
                 * Calls SharetoOpened.
                 * @function sharetoOpened
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoOpenedRequest} request SharetoOpenedRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoOpenedResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimationService#sharetoComplete}.
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @typedef SharetoCompleteCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoCompleteResponse} [response] SharetoCompleteResponse
                 */

                /**
                 * Calls SharetoComplete.
                 * @function sharetoComplete
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoCompleteRequest} request SharetoCompleteRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimationService.SharetoCompleteCallback} callback Node-style callback called with the error, if any, and SharetoCompleteResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimationService.prototype.sharetoComplete = function sharetoComplete(request, callback) {
                    return this.rpcCall(sharetoComplete, $root.mtechnavi.api.estimation.SharetoCompleteRequest, $root.mtechnavi.api.estimation.SharetoCompleteResponse, request, callback);
                }, "name", { value: "SharetoComplete" });

                /**
                 * Calls SharetoComplete.
                 * @function sharetoComplete
                 * @memberof mtechnavi.api.estimation.EstimationService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoCompleteRequest} request SharetoCompleteRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoCompleteResponse>} Promise
                 * @variation 2
                 */

                return EstimationService;
            })();

            estimation.EstimateSenderService = (function() {

                /**
                 * Constructs a new EstimateSenderService service.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateSenderService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function EstimateSenderService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (EstimateSenderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EstimateSenderService;

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequestPlans}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestPlansCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlansResponse} [response] ListEstimateRequestPlansResponse
                 */

                /**
                 * Calls ListEstimateRequestPlans.
                 * @function listEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlansRequest} request ListEstimateRequestPlansRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestPlansCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestPlansResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequestPlans = function listEstimateRequestPlans(request, callback) {
                    return this.rpcCall(listEstimateRequestPlans, $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest, $root.mtechnavi.api.estimation.ListEstimateRequestPlansResponse, request, callback);
                }, "name", { value: "ListEstimateRequestPlans" });

                /**
                 * Calls ListEstimateRequestPlans.
                 * @function listEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlansRequest} request ListEstimateRequestPlansRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestPlansResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#deleteEstimateRequestPlan}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef DeleteEstimateRequestPlanCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteEstimateRequestPlan.
                 * @function deleteEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDeleteEstimateRequestPlanRequest} request DeleteEstimateRequestPlanRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.DeleteEstimateRequestPlanCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.deleteEstimateRequestPlan = function deleteEstimateRequestPlan(request, callback) {
                    return this.rpcCall(deleteEstimateRequestPlan, $root.mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteEstimateRequestPlan" });

                /**
                 * Calls DeleteEstimateRequestPlan.
                 * @function deleteEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDeleteEstimateRequestPlanRequest} request DeleteEstimateRequestPlanRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#importEstimateRequestPlans}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ImportEstimateRequestPlansCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ImportEstimateRequestPlansResponse} [response] ImportEstimateRequestPlansResponse
                 */

                /**
                 * Calls ImportEstimateRequestPlans.
                 * @function importEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IImportEstimateRequestPlansRequest} request ImportEstimateRequestPlansRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ImportEstimateRequestPlansCallback} callback Node-style callback called with the error, if any, and ImportEstimateRequestPlansResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.importEstimateRequestPlans = function importEstimateRequestPlans(request, callback) {
                    return this.rpcCall(importEstimateRequestPlans, $root.mtechnavi.api.estimation.ImportEstimateRequestPlansRequest, $root.mtechnavi.api.estimation.ImportEstimateRequestPlansResponse, request, callback);
                }, "name", { value: "ImportEstimateRequestPlans" });

                /**
                 * Calls ImportEstimateRequestPlans.
                 * @function importEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IImportEstimateRequestPlansRequest} request ImportEstimateRequestPlansRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ImportEstimateRequestPlansResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequestPlanFormats}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestPlanFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse} [response] ListEstimateRequestPlanFormatsResponse
                 */

                /**
                 * Calls ListEstimateRequestPlanFormats.
                 * @function listEstimateRequestPlanFormats
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlanFormatsRequest} request ListEstimateRequestPlanFormatsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestPlanFormatsCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestPlanFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequestPlanFormats = function listEstimateRequestPlanFormats(request, callback) {
                    return this.rpcCall(listEstimateRequestPlanFormats, $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest, $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse, request, callback);
                }, "name", { value: "ListEstimateRequestPlanFormats" });

                /**
                 * Calls ListEstimateRequestPlanFormats.
                 * @function listEstimateRequestPlanFormats
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlanFormatsRequest} request ListEstimateRequestPlanFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#exportEstimateRequestPlans}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ExportEstimateRequestPlansCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ExportEstimateRequestPlansResponse} [response] ExportEstimateRequestPlansResponse
                 */

                /**
                 * Calls ExportEstimateRequestPlans.
                 * @function exportEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IExportEstimateRequestPlansRequest} request ExportEstimateRequestPlansRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ExportEstimateRequestPlansCallback} callback Node-style callback called with the error, if any, and ExportEstimateRequestPlansResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.exportEstimateRequestPlans = function exportEstimateRequestPlans(request, callback) {
                    return this.rpcCall(exportEstimateRequestPlans, $root.mtechnavi.api.estimation.ExportEstimateRequestPlansRequest, $root.mtechnavi.api.estimation.ExportEstimateRequestPlansResponse, request, callback);
                }, "name", { value: "ExportEstimateRequestPlans" });

                /**
                 * Calls ExportEstimateRequestPlans.
                 * @function exportEstimateRequestPlans
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IExportEstimateRequestPlansRequest} request ExportEstimateRequestPlansRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ExportEstimateRequestPlansResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#completeEstimateRequestPlan}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef CompleteEstimateRequestPlanCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse} [response] CompleteEstimateRequestPlanResponse
                 */

                /**
                 * Calls CompleteEstimateRequestPlan.
                 * @function completeEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest} request CompleteEstimateRequestPlanRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.CompleteEstimateRequestPlanCallback} callback Node-style callback called with the error, if any, and CompleteEstimateRequestPlanResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.completeEstimateRequestPlan = function completeEstimateRequestPlan(request, callback) {
                    return this.rpcCall(completeEstimateRequestPlan, $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest, $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse, request, callback);
                }, "name", { value: "CompleteEstimateRequestPlan" });

                /**
                 * Calls CompleteEstimateRequestPlan.
                 * @function completeEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest} request CompleteEstimateRequestPlanRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#uncompleteEstimateRequestPlan}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef UncompleteEstimateRequestPlanCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse} [response] UncompleteEstimateRequestPlanResponse
                 */

                /**
                 * Calls UncompleteEstimateRequestPlan.
                 * @function uncompleteEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUncompleteEstimateRequestPlanRequest} request UncompleteEstimateRequestPlanRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.UncompleteEstimateRequestPlanCallback} callback Node-style callback called with the error, if any, and UncompleteEstimateRequestPlanResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.uncompleteEstimateRequestPlan = function uncompleteEstimateRequestPlan(request, callback) {
                    return this.rpcCall(uncompleteEstimateRequestPlan, $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest, $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse, request, callback);
                }, "name", { value: "UncompleteEstimateRequestPlan" });

                /**
                 * Calls UncompleteEstimateRequestPlan.
                 * @function uncompleteEstimateRequestPlan
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUncompleteEstimateRequestPlanRequest} request UncompleteEstimateRequestPlanRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#saveEstimateManagementWithRelatedItems}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SaveEstimateManagementWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse} [response] SaveEstimateManagementWithRelatedItemsResponse
                 */

                /**
                 * Calls SaveEstimateManagementWithRelatedItems.
                 * @function saveEstimateManagementWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsRequest} request SaveEstimateManagementWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SaveEstimateManagementWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and SaveEstimateManagementWithRelatedItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.saveEstimateManagementWithRelatedItems = function saveEstimateManagementWithRelatedItems(request, callback) {
                    return this.rpcCall(saveEstimateManagementWithRelatedItems, $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest, $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse, request, callback);
                }, "name", { value: "SaveEstimateManagementWithRelatedItems" });

                /**
                 * Calls SaveEstimateManagementWithRelatedItems.
                 * @function saveEstimateManagementWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsRequest} request SaveEstimateManagementWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#deleteEstimateManagementWithRelatedItems}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef DeleteEstimateManagementWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteEstimateManagementWithRelatedItems.
                 * @function deleteEstimateManagementWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDeleteEstimateManagementWithRelatedItemsRequest} request DeleteEstimateManagementWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.DeleteEstimateManagementWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.deleteEstimateManagementWithRelatedItems = function deleteEstimateManagementWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteEstimateManagementWithRelatedItems, $root.mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteEstimateManagementWithRelatedItems" });

                /**
                 * Calls DeleteEstimateManagementWithRelatedItems.
                 * @function deleteEstimateManagementWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDeleteEstimateManagementWithRelatedItemsRequest} request DeleteEstimateManagementWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateManagements}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateManagementsResponse} [response] ListEstimateManagementsResponse
                 */

                /**
                 * Calls ListEstimateManagements.
                 * @function listEstimateManagements
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateManagementsRequest} request ListEstimateManagementsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateManagementsCallback} callback Node-style callback called with the error, if any, and ListEstimateManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateManagements = function listEstimateManagements(request, callback) {
                    return this.rpcCall(listEstimateManagements, $root.mtechnavi.api.estimation.ListEstimateManagementsRequest, $root.mtechnavi.api.estimation.ListEstimateManagementsResponse, request, callback);
                }, "name", { value: "ListEstimateManagements" });

                /**
                 * Calls ListEstimateManagements.
                 * @function listEstimateManagements
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateManagementsRequest} request ListEstimateManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#updateEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef UpdateEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateManagement} [response] EstimateManagement
                 */

                /**
                 * Calls UpdateEstimateManagement.
                 * @function updateEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateManagementRequest} request UpdateEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.UpdateEstimateManagementCallback} callback Node-style callback called with the error, if any, and EstimateManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.updateEstimateManagement = function updateEstimateManagement(request, callback) {
                    return this.rpcCall(updateEstimateManagement, $root.mtechnavi.api.estimation.UpdateEstimateManagementRequest, $root.mtechnavi.api.estimation.EstimateManagement, request, callback);
                }, "name", { value: "UpdateEstimateManagement" });

                /**
                 * Calls UpdateEstimateManagement.
                 * @function updateEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateManagementRequest} request UpdateEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#approvalRequestEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ApprovalRequestEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse} [response] ApprovalRequestEstimateManagementResponse
                 */

                /**
                 * Calls ApprovalRequestEstimateManagement.
                 * @function approvalRequestEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateManagementRequest} request ApprovalRequestEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ApprovalRequestEstimateManagementCallback} callback Node-style callback called with the error, if any, and ApprovalRequestEstimateManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.approvalRequestEstimateManagement = function approvalRequestEstimateManagement(request, callback) {
                    return this.rpcCall(approvalRequestEstimateManagement, $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest, $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse, request, callback);
                }, "name", { value: "ApprovalRequestEstimateManagement" });

                /**
                 * Calls ApprovalRequestEstimateManagement.
                 * @function approvalRequestEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateManagementRequest} request ApprovalRequestEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#rejectEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef RejectEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.RejectEstimateManagementResponse} [response] RejectEstimateManagementResponse
                 */

                /**
                 * Calls RejectEstimateManagement.
                 * @function rejectEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IRejectEstimateManagementRequest} request RejectEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.RejectEstimateManagementCallback} callback Node-style callback called with the error, if any, and RejectEstimateManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.rejectEstimateManagement = function rejectEstimateManagement(request, callback) {
                    return this.rpcCall(rejectEstimateManagement, $root.mtechnavi.api.estimation.RejectEstimateManagementRequest, $root.mtechnavi.api.estimation.RejectEstimateManagementResponse, request, callback);
                }, "name", { value: "RejectEstimateManagement" });

                /**
                 * Calls RejectEstimateManagement.
                 * @function rejectEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IRejectEstimateManagementRequest} request RejectEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.RejectEstimateManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#approveEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ApproveEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ApproveEstimateManagementResponse} [response] ApproveEstimateManagementResponse
                 */

                /**
                 * Calls ApproveEstimateManagement.
                 * @function approveEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApproveEstimateManagementRequest} request ApproveEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ApproveEstimateManagementCallback} callback Node-style callback called with the error, if any, and ApproveEstimateManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.approveEstimateManagement = function approveEstimateManagement(request, callback) {
                    return this.rpcCall(approveEstimateManagement, $root.mtechnavi.api.estimation.ApproveEstimateManagementRequest, $root.mtechnavi.api.estimation.ApproveEstimateManagementResponse, request, callback);
                }, "name", { value: "ApproveEstimateManagement" });

                /**
                 * Calls ApproveEstimateManagement.
                 * @function approveEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApproveEstimateManagementRequest} request ApproveEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ApproveEstimateManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#discardEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef DiscardEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.DiscardEstimateManagementResponse} [response] DiscardEstimateManagementResponse
                 */

                /**
                 * Calls DiscardEstimateManagement.
                 * @function discardEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDiscardEstimateManagementRequest} request DiscardEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.DiscardEstimateManagementCallback} callback Node-style callback called with the error, if any, and DiscardEstimateManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.discardEstimateManagement = function discardEstimateManagement(request, callback) {
                    return this.rpcCall(discardEstimateManagement, $root.mtechnavi.api.estimation.DiscardEstimateManagementRequest, $root.mtechnavi.api.estimation.DiscardEstimateManagementResponse, request, callback);
                }, "name", { value: "DiscardEstimateManagement" });

                /**
                 * Calls DiscardEstimateManagement.
                 * @function discardEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IDiscardEstimateManagementRequest} request DiscardEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.DiscardEstimateManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#completeEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef CompleteEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.CompleteEstimateManagementResponse} [response] CompleteEstimateManagementResponse
                 */

                /**
                 * Calls CompleteEstimateManagement.
                 * @function completeEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateManagementRequest} request CompleteEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.CompleteEstimateManagementCallback} callback Node-style callback called with the error, if any, and CompleteEstimateManagementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.completeEstimateManagement = function completeEstimateManagement(request, callback) {
                    return this.rpcCall(completeEstimateManagement, $root.mtechnavi.api.estimation.CompleteEstimateManagementRequest, $root.mtechnavi.api.estimation.CompleteEstimateManagementResponse, request, callback);
                }, "name", { value: "CompleteEstimateManagement" });

                /**
                 * Calls CompleteEstimateManagement.
                 * @function completeEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateManagementRequest} request CompleteEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.CompleteEstimateManagementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#updateSystemNotificationUsersInEstimateManagement}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef UpdateSystemNotificationUsersInEstimateManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateManagement} [response] EstimateManagement
                 */

                /**
                 * Calls UpdateSystemNotificationUsersInEstimateManagement.
                 * @function updateSystemNotificationUsersInEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateManagementRequest} request UpdateSystemNotificationUsersInEstimateManagementRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.UpdateSystemNotificationUsersInEstimateManagementCallback} callback Node-style callback called with the error, if any, and EstimateManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.updateSystemNotificationUsersInEstimateManagement = function updateSystemNotificationUsersInEstimateManagement(request, callback) {
                    return this.rpcCall(updateSystemNotificationUsersInEstimateManagement, $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest, $root.mtechnavi.api.estimation.EstimateManagement, request, callback);
                }, "name", { value: "UpdateSystemNotificationUsersInEstimateManagement" });

                /**
                 * Calls UpdateSystemNotificationUsersInEstimateManagement.
                 * @function updateSystemNotificationUsersInEstimateManagement
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateManagementRequest} request UpdateSystemNotificationUsersInEstimateManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#sharetoEstimateRequestWithRelatedItems}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SharetoEstimateRequestWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse} [response] SharetoEstimateRequestWithRelatedItemsResponse
                 */

                /**
                 * Calls SharetoEstimateRequestWithRelatedItems.
                 * @function sharetoEstimateRequestWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsRequest} request SharetoEstimateRequestWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SharetoEstimateRequestWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and SharetoEstimateRequestWithRelatedItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.sharetoEstimateRequestWithRelatedItems = function sharetoEstimateRequestWithRelatedItems(request, callback) {
                    return this.rpcCall(sharetoEstimateRequestWithRelatedItems, $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest, $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse, request, callback);
                }, "name", { value: "SharetoEstimateRequestWithRelatedItems" });

                /**
                 * Calls SharetoEstimateRequestWithRelatedItems.
                 * @function sharetoEstimateRequestWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsRequest} request SharetoEstimateRequestWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequests}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestsResponse} [response] ListEstimateRequestsResponse
                 */

                /**
                 * Calls ListEstimateRequests.
                 * @function listEstimateRequests
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestsRequest} request ListEstimateRequestsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestsCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequests = function listEstimateRequests(request, callback) {
                    return this.rpcCall(listEstimateRequests, $root.mtechnavi.api.estimation.ListEstimateRequestsRequest, $root.mtechnavi.api.estimation.ListEstimateRequestsResponse, request, callback);
                }, "name", { value: "ListEstimateRequests" });

                /**
                 * Calls ListEstimateRequests.
                 * @function listEstimateRequests
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestsRequest} request ListEstimateRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequestDetails}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestDetailsResponse} [response] ListEstimateRequestDetailsResponse
                 */

                /**
                 * Calls ListEstimateRequestDetails.
                 * @function listEstimateRequestDetails
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestDetailsRequest} request ListEstimateRequestDetailsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestDetailsCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequestDetails = function listEstimateRequestDetails(request, callback) {
                    return this.rpcCall(listEstimateRequestDetails, $root.mtechnavi.api.estimation.ListEstimateRequestDetailsRequest, $root.mtechnavi.api.estimation.ListEstimateRequestDetailsResponse, request, callback);
                }, "name", { value: "ListEstimateRequestDetails" });

                /**
                 * Calls ListEstimateRequestDetails.
                 * @function listEstimateRequestDetails
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestDetailsRequest} request ListEstimateRequestDetailsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestDetailsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequestUnits}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestUnitsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitsResponse} [response] ListEstimateRequestUnitsResponse
                 */

                /**
                 * Calls ListEstimateRequestUnits.
                 * @function listEstimateRequestUnits
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitsRequest} request ListEstimateRequestUnitsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestUnitsCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestUnitsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequestUnits = function listEstimateRequestUnits(request, callback) {
                    return this.rpcCall(listEstimateRequestUnits, $root.mtechnavi.api.estimation.ListEstimateRequestUnitsRequest, $root.mtechnavi.api.estimation.ListEstimateRequestUnitsResponse, request, callback);
                }, "name", { value: "ListEstimateRequestUnits" });

                /**
                 * Calls ListEstimateRequestUnits.
                 * @function listEstimateRequestUnits
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitsRequest} request ListEstimateRequestUnitsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestUnitsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateManagementSummarys}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateManagementSummarysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateManagementSummarysResponse} [response] ListEstimateManagementSummarysResponse
                 */

                /**
                 * Calls ListEstimateManagementSummarys.
                 * @function listEstimateManagementSummarys
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateManagementSummarysRequest} request ListEstimateManagementSummarysRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateManagementSummarysCallback} callback Node-style callback called with the error, if any, and ListEstimateManagementSummarysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateManagementSummarys = function listEstimateManagementSummarys(request, callback) {
                    return this.rpcCall(listEstimateManagementSummarys, $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest, $root.mtechnavi.api.estimation.ListEstimateManagementSummarysResponse, request, callback);
                }, "name", { value: "ListEstimateManagementSummarys" });

                /**
                 * Calls ListEstimateManagementSummarys.
                 * @function listEstimateManagementSummarys
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateManagementSummarysRequest} request ListEstimateManagementSummarysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateManagementSummarysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateRequestUnitSummarys}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateRequestUnitSummarysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse} [response] ListEstimateRequestUnitSummarysResponse
                 */

                /**
                 * Calls ListEstimateRequestUnitSummarys.
                 * @function listEstimateRequestUnitSummarys
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitSummarysRequest} request ListEstimateRequestUnitSummarysRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateRequestUnitSummarysCallback} callback Node-style callback called with the error, if any, and ListEstimateRequestUnitSummarysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateRequestUnitSummarys = function listEstimateRequestUnitSummarys(request, callback) {
                    return this.rpcCall(listEstimateRequestUnitSummarys, $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest, $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse, request, callback);
                }, "name", { value: "ListEstimateRequestUnitSummarys" });

                /**
                 * Calls ListEstimateRequestUnitSummarys.
                 * @function listEstimateRequestUnitSummarys
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitSummarysRequest} request ListEstimateRequestUnitSummarysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#saveEstimateSelection}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SaveEstimateSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateSelection} [response] EstimateSelection
                 */

                /**
                 * Calls SaveEstimateSelection.
                 * @function saveEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISaveEstimateSelectionRequest} request SaveEstimateSelectionRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SaveEstimateSelectionCallback} callback Node-style callback called with the error, if any, and EstimateSelection
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.saveEstimateSelection = function saveEstimateSelection(request, callback) {
                    return this.rpcCall(saveEstimateSelection, $root.mtechnavi.api.estimation.SaveEstimateSelectionRequest, $root.mtechnavi.api.estimation.EstimateSelection, request, callback);
                }, "name", { value: "SaveEstimateSelection" });

                /**
                 * Calls SaveEstimateSelection.
                 * @function saveEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISaveEstimateSelectionRequest} request SaveEstimateSelectionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateSelection>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listEstimateSelections}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListEstimateSelectionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateSelectionsResponse} [response] ListEstimateSelectionsResponse
                 */

                /**
                 * Calls ListEstimateSelections.
                 * @function listEstimateSelections
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateSelectionsRequest} request ListEstimateSelectionsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListEstimateSelectionsCallback} callback Node-style callback called with the error, if any, and ListEstimateSelectionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listEstimateSelections = function listEstimateSelections(request, callback) {
                    return this.rpcCall(listEstimateSelections, $root.mtechnavi.api.estimation.ListEstimateSelectionsRequest, $root.mtechnavi.api.estimation.ListEstimateSelectionsResponse, request, callback);
                }, "name", { value: "ListEstimateSelections" });

                /**
                 * Calls ListEstimateSelections.
                 * @function listEstimateSelections
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateSelectionsRequest} request ListEstimateSelectionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateSelectionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#approvalRequestEstimateSelection}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ApprovalRequestEstimateSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse} [response] ApprovalRequestEstimateSelectionResponse
                 */

                /**
                 * Calls ApprovalRequestEstimateSelection.
                 * @function approvalRequestEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest} request ApprovalRequestEstimateSelectionRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ApprovalRequestEstimateSelectionCallback} callback Node-style callback called with the error, if any, and ApprovalRequestEstimateSelectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.approvalRequestEstimateSelection = function approvalRequestEstimateSelection(request, callback) {
                    return this.rpcCall(approvalRequestEstimateSelection, $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest, $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse, request, callback);
                }, "name", { value: "ApprovalRequestEstimateSelection" });

                /**
                 * Calls ApprovalRequestEstimateSelection.
                 * @function approvalRequestEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest} request ApprovalRequestEstimateSelectionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#rejectEstimateSelection}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef RejectEstimateSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.RejectEstimateSelectionResponse} [response] RejectEstimateSelectionResponse
                 */

                /**
                 * Calls RejectEstimateSelection.
                 * @function rejectEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IRejectEstimateSelectionRequest} request RejectEstimateSelectionRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.RejectEstimateSelectionCallback} callback Node-style callback called with the error, if any, and RejectEstimateSelectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.rejectEstimateSelection = function rejectEstimateSelection(request, callback) {
                    return this.rpcCall(rejectEstimateSelection, $root.mtechnavi.api.estimation.RejectEstimateSelectionRequest, $root.mtechnavi.api.estimation.RejectEstimateSelectionResponse, request, callback);
                }, "name", { value: "RejectEstimateSelection" });

                /**
                 * Calls RejectEstimateSelection.
                 * @function rejectEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IRejectEstimateSelectionRequest} request RejectEstimateSelectionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.RejectEstimateSelectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#approveEstimateSelection}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ApproveEstimateSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ApproveEstimateSelectionResponse} [response] ApproveEstimateSelectionResponse
                 */

                /**
                 * Calls ApproveEstimateSelection.
                 * @function approveEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApproveEstimateSelectionRequest} request ApproveEstimateSelectionRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ApproveEstimateSelectionCallback} callback Node-style callback called with the error, if any, and ApproveEstimateSelectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.approveEstimateSelection = function approveEstimateSelection(request, callback) {
                    return this.rpcCall(approveEstimateSelection, $root.mtechnavi.api.estimation.ApproveEstimateSelectionRequest, $root.mtechnavi.api.estimation.ApproveEstimateSelectionResponse, request, callback);
                }, "name", { value: "ApproveEstimateSelection" });

                /**
                 * Calls ApproveEstimateSelection.
                 * @function approveEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IApproveEstimateSelectionRequest} request ApproveEstimateSelectionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ApproveEstimateSelectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#sharetoEstimateSelection}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SharetoEstimateSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoEstimateSelectionResponse} [response] SharetoEstimateSelectionResponse
                 */

                /**
                 * Calls SharetoEstimateSelection.
                 * @function sharetoEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateSelectionRequest} request SharetoEstimateSelectionRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SharetoEstimateSelectionCallback} callback Node-style callback called with the error, if any, and SharetoEstimateSelectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.sharetoEstimateSelection = function sharetoEstimateSelection(request, callback) {
                    return this.rpcCall(sharetoEstimateSelection, $root.mtechnavi.api.estimation.SharetoEstimateSelectionRequest, $root.mtechnavi.api.estimation.SharetoEstimateSelectionResponse, request, callback);
                }, "name", { value: "SharetoEstimateSelection" });

                /**
                 * Calls SharetoEstimateSelection.
                 * @function sharetoEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateSelectionRequest} request SharetoEstimateSelectionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoEstimateSelectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#listOrderFormats}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ListOrderFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListOrderFormatsResponse} [response] ListOrderFormatsResponse
                 */

                /**
                 * Calls ListOrderFormats.
                 * @function listOrderFormats
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListOrderFormatsRequest} request ListOrderFormatsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ListOrderFormatsCallback} callback Node-style callback called with the error, if any, and ListOrderFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.listOrderFormats = function listOrderFormats(request, callback) {
                    return this.rpcCall(listOrderFormats, $root.mtechnavi.api.estimation.ListOrderFormatsRequest, $root.mtechnavi.api.estimation.ListOrderFormatsResponse, request, callback);
                }, "name", { value: "ListOrderFormats" });

                /**
                 * Calls ListOrderFormats.
                 * @function listOrderFormats
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListOrderFormatsRequest} request ListOrderFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListOrderFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#exportOrders}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef ExportOrdersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ExportOrdersResponse} [response] ExportOrdersResponse
                 */

                /**
                 * Calls ExportOrders.
                 * @function exportOrders
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IExportOrdersRequest} request ExportOrdersRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.ExportOrdersCallback} callback Node-style callback called with the error, if any, and ExportOrdersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.exportOrders = function exportOrders(request, callback) {
                    return this.rpcCall(exportOrders, $root.mtechnavi.api.estimation.ExportOrdersRequest, $root.mtechnavi.api.estimation.ExportOrdersResponse, request, callback);
                }, "name", { value: "ExportOrders" });

                /**
                 * Calls ExportOrders.
                 * @function exportOrders
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.IExportOrdersRequest} request ExportOrdersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ExportOrdersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#createEstimateResultChangeRequest}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef CreateEstimateResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateResultChangeRequest} [response] EstimateResultChangeRequest
                 */

                /**
                 * Calls CreateEstimateResultChangeRequest.
                 * @function createEstimateResultChangeRequest
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICreateEstimateResultChangeRequestRequest} request CreateEstimateResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.CreateEstimateResultChangeRequestCallback} callback Node-style callback called with the error, if any, and EstimateResultChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.createEstimateResultChangeRequest = function createEstimateResultChangeRequest(request, callback) {
                    return this.rpcCall(createEstimateResultChangeRequest, $root.mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest, $root.mtechnavi.api.estimation.EstimateResultChangeRequest, request, callback);
                }, "name", { value: "CreateEstimateResultChangeRequest" });

                /**
                 * Calls CreateEstimateResultChangeRequest.
                 * @function createEstimateResultChangeRequest
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICreateEstimateResultChangeRequestRequest} request CreateEstimateResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateResultChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#sharetoEstimateResultChangeRequest}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SharetoEstimateResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse} [response] SharetoEstimateResultChangeRequestResponse
                 */

                /**
                 * Calls SharetoEstimateResultChangeRequest.
                 * @function sharetoEstimateResultChangeRequest
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultChangeRequestRequest} request SharetoEstimateResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SharetoEstimateResultChangeRequestCallback} callback Node-style callback called with the error, if any, and SharetoEstimateResultChangeRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.sharetoEstimateResultChangeRequest = function sharetoEstimateResultChangeRequest(request, callback) {
                    return this.rpcCall(sharetoEstimateResultChangeRequest, $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest, $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse, request, callback);
                }, "name", { value: "SharetoEstimateResultChangeRequest" });

                /**
                 * Calls SharetoEstimateResultChangeRequest.
                 * @function sharetoEstimateResultChangeRequest
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultChangeRequestRequest} request SharetoEstimateResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#sharedListEstimateResults}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SharedListEstimateResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultsResponse} [response] SharedListEstimateResultsResponse
                 */

                /**
                 * Calls SharedListEstimateResults.
                 * @function sharedListEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultsRequest} request SharedListEstimateResultsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SharedListEstimateResultsCallback} callback Node-style callback called with the error, if any, and SharedListEstimateResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.sharedListEstimateResults = function sharedListEstimateResults(request, callback) {
                    return this.rpcCall(sharedListEstimateResults, $root.mtechnavi.api.estimation.SharedListEstimateResultsRequest, $root.mtechnavi.api.estimation.SharedListEstimateResultsResponse, request, callback);
                }, "name", { value: "SharedListEstimateResults" });

                /**
                 * Calls SharedListEstimateResults.
                 * @function sharedListEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultsRequest} request SharedListEstimateResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharedListEstimateResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateSenderService#sharedListEstimateResultDetails}.
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @typedef SharedListEstimateResultDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse} [response] SharedListEstimateResultDetailsResponse
                 */

                /**
                 * Calls SharedListEstimateResultDetails.
                 * @function sharedListEstimateResultDetails
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultDetailsRequest} request SharedListEstimateResultDetailsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateSenderService.SharedListEstimateResultDetailsCallback} callback Node-style callback called with the error, if any, and SharedListEstimateResultDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateSenderService.prototype.sharedListEstimateResultDetails = function sharedListEstimateResultDetails(request, callback) {
                    return this.rpcCall(sharedListEstimateResultDetails, $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest, $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse, request, callback);
                }, "name", { value: "SharedListEstimateResultDetails" });

                /**
                 * Calls SharedListEstimateResultDetails.
                 * @function sharedListEstimateResultDetails
                 * @memberof mtechnavi.api.estimation.EstimateSenderService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultDetailsRequest} request SharedListEstimateResultDetailsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse>} Promise
                 * @variation 2
                 */

                return EstimateSenderService;
            })();

            estimation.EstimateReceiverService = (function() {

                /**
                 * Constructs a new EstimateReceiverService service.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateReceiverService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function EstimateReceiverService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (EstimateReceiverService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EstimateReceiverService;

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#listEstimateResults}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef ListEstimateResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateResultsResponse} [response] ListEstimateResultsResponse
                 */

                /**
                 * Calls ListEstimateResults.
                 * @function listEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultsRequest} request ListEstimateResultsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.ListEstimateResultsCallback} callback Node-style callback called with the error, if any, and ListEstimateResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.listEstimateResults = function listEstimateResults(request, callback) {
                    return this.rpcCall(listEstimateResults, $root.mtechnavi.api.estimation.ListEstimateResultsRequest, $root.mtechnavi.api.estimation.ListEstimateResultsResponse, request, callback);
                }, "name", { value: "ListEstimateResults" });

                /**
                 * Calls ListEstimateResults.
                 * @function listEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultsRequest} request ListEstimateResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#updateEstimateResult}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef UpdateEstimateResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateResult} [response] EstimateResult
                 */

                /**
                 * Calls UpdateEstimateResult.
                 * @function updateEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultRequest} request UpdateEstimateResultRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.UpdateEstimateResultCallback} callback Node-style callback called with the error, if any, and EstimateResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.updateEstimateResult = function updateEstimateResult(request, callback) {
                    return this.rpcCall(updateEstimateResult, $root.mtechnavi.api.estimation.UpdateEstimateResultRequest, $root.mtechnavi.api.estimation.EstimateResult, request, callback);
                }, "name", { value: "UpdateEstimateResult" });

                /**
                 * Calls UpdateEstimateResult.
                 * @function updateEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultRequest} request UpdateEstimateResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#completeEstimateResult}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef CompleteEstimateResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.CompleteEstimateResultResponse} [response] CompleteEstimateResultResponse
                 */

                /**
                 * Calls CompleteEstimateResult.
                 * @function completeEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateResultRequest} request CompleteEstimateResultRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.CompleteEstimateResultCallback} callback Node-style callback called with the error, if any, and CompleteEstimateResultResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.completeEstimateResult = function completeEstimateResult(request, callback) {
                    return this.rpcCall(completeEstimateResult, $root.mtechnavi.api.estimation.CompleteEstimateResultRequest, $root.mtechnavi.api.estimation.CompleteEstimateResultResponse, request, callback);
                }, "name", { value: "CompleteEstimateResult" });

                /**
                 * Calls CompleteEstimateResult.
                 * @function completeEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ICompleteEstimateResultRequest} request CompleteEstimateResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.CompleteEstimateResultResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#updateSystemNotificationUsersInEstimateResult}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef UpdateSystemNotificationUsersInEstimateResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateResult} [response] EstimateResult
                 */

                /**
                 * Calls UpdateSystemNotificationUsersInEstimateResult.
                 * @function updateSystemNotificationUsersInEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateResultRequest} request UpdateSystemNotificationUsersInEstimateResultRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.UpdateSystemNotificationUsersInEstimateResultCallback} callback Node-style callback called with the error, if any, and EstimateResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.updateSystemNotificationUsersInEstimateResult = function updateSystemNotificationUsersInEstimateResult(request, callback) {
                    return this.rpcCall(updateSystemNotificationUsersInEstimateResult, $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest, $root.mtechnavi.api.estimation.EstimateResult, request, callback);
                }, "name", { value: "UpdateSystemNotificationUsersInEstimateResult" });

                /**
                 * Calls UpdateSystemNotificationUsersInEstimateResult.
                 * @function updateSystemNotificationUsersInEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateResultRequest} request UpdateSystemNotificationUsersInEstimateResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#updateRemarksInEstimateResult}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef UpdateRemarksInEstimateResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateResult} [response] EstimateResult
                 */

                /**
                 * Calls UpdateRemarksInEstimateResult.
                 * @function updateRemarksInEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateRemarksInEstimateResultRequest} request UpdateRemarksInEstimateResultRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.UpdateRemarksInEstimateResultCallback} callback Node-style callback called with the error, if any, and EstimateResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.updateRemarksInEstimateResult = function updateRemarksInEstimateResult(request, callback) {
                    return this.rpcCall(updateRemarksInEstimateResult, $root.mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest, $root.mtechnavi.api.estimation.EstimateResult, request, callback);
                }, "name", { value: "UpdateRemarksInEstimateResult" });

                /**
                 * Calls UpdateRemarksInEstimateResult.
                 * @function updateRemarksInEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateRemarksInEstimateResultRequest} request UpdateRemarksInEstimateResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#sharetoEstimateResultWithRelatedItems}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef SharetoEstimateResultWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse} [response] SharetoEstimateResultWithRelatedItemsResponse
                 */

                /**
                 * Calls SharetoEstimateResultWithRelatedItems.
                 * @function sharetoEstimateResultWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest} request SharetoEstimateResultWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.SharetoEstimateResultWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and SharetoEstimateResultWithRelatedItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.sharetoEstimateResultWithRelatedItems = function sharetoEstimateResultWithRelatedItems(request, callback) {
                    return this.rpcCall(sharetoEstimateResultWithRelatedItems, $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest, $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse, request, callback);
                }, "name", { value: "SharetoEstimateResultWithRelatedItems" });

                /**
                 * Calls SharetoEstimateResultWithRelatedItems.
                 * @function sharetoEstimateResultWithRelatedItems
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest} request SharetoEstimateResultWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#listEstimateResultDetails}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef ListEstimateResultDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateResultDetailsResponse} [response] ListEstimateResultDetailsResponse
                 */

                /**
                 * Calls ListEstimateResultDetails.
                 * @function listEstimateResultDetails
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultDetailsRequest} request ListEstimateResultDetailsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.ListEstimateResultDetailsCallback} callback Node-style callback called with the error, if any, and ListEstimateResultDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.listEstimateResultDetails = function listEstimateResultDetails(request, callback) {
                    return this.rpcCall(listEstimateResultDetails, $root.mtechnavi.api.estimation.ListEstimateResultDetailsRequest, $root.mtechnavi.api.estimation.ListEstimateResultDetailsResponse, request, callback);
                }, "name", { value: "ListEstimateResultDetails" });

                /**
                 * Calls ListEstimateResultDetails.
                 * @function listEstimateResultDetails
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultDetailsRequest} request ListEstimateResultDetailsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateResultDetailsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#updateEstimateResultDetail}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef UpdateEstimateResultDetailCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.EstimateResultDetail} [response] EstimateResultDetail
                 */

                /**
                 * Calls UpdateEstimateResultDetail.
                 * @function updateEstimateResultDetail
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultDetailRequest} request UpdateEstimateResultDetailRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.UpdateEstimateResultDetailCallback} callback Node-style callback called with the error, if any, and EstimateResultDetail
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.updateEstimateResultDetail = function updateEstimateResultDetail(request, callback) {
                    return this.rpcCall(updateEstimateResultDetail, $root.mtechnavi.api.estimation.UpdateEstimateResultDetailRequest, $root.mtechnavi.api.estimation.EstimateResultDetail, request, callback);
                }, "name", { value: "UpdateEstimateResultDetail" });

                /**
                 * Calls UpdateEstimateResultDetail.
                 * @function updateEstimateResultDetail
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultDetailRequest} request UpdateEstimateResultDetailRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.EstimateResultDetail>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#listEstimateResultSummarys}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef ListEstimateResultSummarysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.ListEstimateResultSummarysResponse} [response] ListEstimateResultSummarysResponse
                 */

                /**
                 * Calls ListEstimateResultSummarys.
                 * @function listEstimateResultSummarys
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultSummarysRequest} request ListEstimateResultSummarysRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.ListEstimateResultSummarysCallback} callback Node-style callback called with the error, if any, and ListEstimateResultSummarysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.listEstimateResultSummarys = function listEstimateResultSummarys(request, callback) {
                    return this.rpcCall(listEstimateResultSummarys, $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest, $root.mtechnavi.api.estimation.ListEstimateResultSummarysResponse, request, callback);
                }, "name", { value: "ListEstimateResultSummarys" });

                /**
                 * Calls ListEstimateResultSummarys.
                 * @function listEstimateResultSummarys
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IListEstimateResultSummarysRequest} request ListEstimateResultSummarysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.ListEstimateResultSummarysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#openEstimateRequestUnit}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef OpenEstimateRequestUnitCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.OpenEstimateRequestUnitResponse} [response] OpenEstimateRequestUnitResponse
                 */

                /**
                 * Calls OpenEstimateRequestUnit.
                 * @function openEstimateRequestUnit
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest} request OpenEstimateRequestUnitRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.OpenEstimateRequestUnitCallback} callback Node-style callback called with the error, if any, and OpenEstimateRequestUnitResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.openEstimateRequestUnit = function openEstimateRequestUnit(request, callback) {
                    return this.rpcCall(openEstimateRequestUnit, $root.mtechnavi.api.estimation.OpenEstimateRequestUnitRequest, $root.mtechnavi.api.estimation.OpenEstimateRequestUnitResponse, request, callback);
                }, "name", { value: "OpenEstimateRequestUnit" });

                /**
                 * Calls OpenEstimateRequestUnit.
                 * @function openEstimateRequestUnit
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest} request OpenEstimateRequestUnitRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.OpenEstimateRequestUnitResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimateReceiverService#sharedListEstimateSelections}.
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @typedef SharedListEstimateSelectionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.SharedListEstimateSelectionsResponse} [response] SharedListEstimateSelectionsResponse
                 */

                /**
                 * Calls SharedListEstimateSelections.
                 * @function sharedListEstimateSelections
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateSelectionsRequest} request SharedListEstimateSelectionsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimateReceiverService.SharedListEstimateSelectionsCallback} callback Node-style callback called with the error, if any, and SharedListEstimateSelectionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimateReceiverService.prototype.sharedListEstimateSelections = function sharedListEstimateSelections(request, callback) {
                    return this.rpcCall(sharedListEstimateSelections, $root.mtechnavi.api.estimation.SharedListEstimateSelectionsRequest, $root.mtechnavi.api.estimation.SharedListEstimateSelectionsResponse, request, callback);
                }, "name", { value: "SharedListEstimateSelections" });

                /**
                 * Calls SharedListEstimateSelections.
                 * @function sharedListEstimateSelections
                 * @memberof mtechnavi.api.estimation.EstimateReceiverService
                 * @instance
                 * @param {mtechnavi.api.estimation.ISharedListEstimateSelectionsRequest} request SharedListEstimateSelectionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.SharedListEstimateSelectionsResponse>} Promise
                 * @variation 2
                 */

                return EstimateReceiverService;
            })();

            estimation.EstimationTaskListService = (function() {

                /**
                 * Constructs a new EstimationTaskListService service.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimationTaskListService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function EstimationTaskListService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (EstimationTaskListService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EstimationTaskListService;

                /**
                 * Callback as used by {@link mtechnavi.api.estimation.EstimationTaskListService#uncompletedListEstimateResults}.
                 * @memberof mtechnavi.api.estimation.EstimationTaskListService
                 * @typedef UncompletedListEstimateResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.estimation.UncompletedListEstimateResultsResponse} [response] UncompletedListEstimateResultsResponse
                 */

                /**
                 * Calls UncompletedListEstimateResults.
                 * @function uncompletedListEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimationTaskListService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUncompletedListEstimateResultsRequest} request UncompletedListEstimateResultsRequest message or plain object
                 * @param {mtechnavi.api.estimation.EstimationTaskListService.UncompletedListEstimateResultsCallback} callback Node-style callback called with the error, if any, and UncompletedListEstimateResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(EstimationTaskListService.prototype.uncompletedListEstimateResults = function uncompletedListEstimateResults(request, callback) {
                    return this.rpcCall(uncompletedListEstimateResults, $root.mtechnavi.api.estimation.UncompletedListEstimateResultsRequest, $root.mtechnavi.api.estimation.UncompletedListEstimateResultsResponse, request, callback);
                }, "name", { value: "UncompletedListEstimateResults" });

                /**
                 * Calls UncompletedListEstimateResults.
                 * @function uncompletedListEstimateResults
                 * @memberof mtechnavi.api.estimation.EstimationTaskListService
                 * @instance
                 * @param {mtechnavi.api.estimation.IUncompletedListEstimateResultsRequest} request UncompletedListEstimateResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.estimation.UncompletedListEstimateResultsResponse>} Promise
                 * @variation 2
                 */

                return EstimationTaskListService;
            })();

            estimation.ListHistorysRequest = (function() {

                /**
                 * Properties of a ListHistorysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListHistorysRequest
                 * @property {string|null} [pageToken] ListHistorysRequest pageToken
                 * @property {string|null} [typeName] ListHistorysRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListHistorysRequest recordIds
                 */

                /**
                 * Constructs a new ListHistorysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListHistorysRequest.
                 * @implements IListHistorysRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListHistorysRequest=} [properties] Properties to set
                 */
                function ListHistorysRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.pageToken = "";

                /**
                 * ListHistorysRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.typeName = "";

                /**
                 * ListHistorysRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListHistorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListHistorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListHistorysRequest} ListHistorysRequest
                 */
                ListHistorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListHistorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListHistorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.estimation.ListHistorysRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListHistorysRequest} message ListHistorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListHistorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListHistorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysRequest;
            })();

            estimation.ListHistorysResponse = (function() {

                /**
                 * Properties of a ListHistorysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListHistorysResponse
                 * @property {Long|null} [total] ListHistorysResponse total
                 * @property {Array.<mtechnavi.api.estimation.IHistory>|null} [items] ListHistorysResponse items
                 * @property {string|null} [pageToken] ListHistorysResponse pageToken
                 */

                /**
                 * Constructs a new ListHistorysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListHistorysResponse.
                 * @implements IListHistorysResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListHistorysResponse=} [properties] Properties to set
                 */
                function ListHistorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListHistorysResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IHistory>} items
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListHistorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListHistorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.History.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListHistorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListHistorysResponse} ListHistorysResponse
                 */
                ListHistorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListHistorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListHistorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListHistorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListHistorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.History.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListHistorysResponse} message ListHistorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.History.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListHistorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListHistorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysResponse;
            })();

            estimation.SharetoDiscardRequest = (function() {

                /**
                 * Properties of a SharetoDiscardRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoDiscardRequest
                 * @property {mtechnavi.api.estimation.IDiscard|null} [discard] SharetoDiscardRequest discard
                 */

                /**
                 * Constructs a new SharetoDiscardRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoDiscardRequest.
                 * @implements ISharetoDiscardRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoDiscardRequest=} [properties] Properties to set
                 */
                function SharetoDiscardRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoDiscardRequest discard.
                 * @member {mtechnavi.api.estimation.IDiscard|null|undefined} discard
                 * @memberof mtechnavi.api.estimation.SharetoDiscardRequest
                 * @instance
                 */
                SharetoDiscardRequest.prototype.discard = null;

                /**
                 * Verifies a SharetoDiscardRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoDiscardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoDiscardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discard != null && message.hasOwnProperty("discard")) {
                        let error = $root.mtechnavi.api.estimation.Discard.verify(message.discard);
                        if (error)
                            return "discard." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoDiscardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoDiscardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoDiscardRequest} SharetoDiscardRequest
                 */
                SharetoDiscardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoDiscardRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoDiscardRequest();
                    if (object.discard != null) {
                        if (typeof object.discard !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SharetoDiscardRequest.discard: object expected");
                        message.discard = $root.mtechnavi.api.estimation.Discard.fromObject(object.discard);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoDiscardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoDiscardRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoDiscardRequest} message SharetoDiscardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoDiscardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.discard = null;
                    if (message.discard != null && message.hasOwnProperty("discard"))
                        object.discard = $root.mtechnavi.api.estimation.Discard.toObject(message.discard, options);
                    return object;
                };

                /**
                 * Converts this SharetoDiscardRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoDiscardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoDiscardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoDiscardRequest;
            })();

            estimation.SharetoDiscardResponse = (function() {

                /**
                 * Properties of a SharetoDiscardResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoDiscardResponse
                 */

                /**
                 * Constructs a new SharetoDiscardResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoDiscardResponse.
                 * @implements ISharetoDiscardResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoDiscardResponse=} [properties] Properties to set
                 */
                function SharetoDiscardResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoDiscardResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoDiscardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoDiscardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoDiscardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoDiscardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoDiscardResponse} SharetoDiscardResponse
                 */
                SharetoDiscardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoDiscardResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoDiscardResponse();
                };

                /**
                 * Creates a plain object from a SharetoDiscardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoDiscardResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoDiscardResponse} message SharetoDiscardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoDiscardResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoDiscardResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoDiscardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoDiscardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoDiscardResponse;
            })();

            estimation.SharetoOpenedRequest = (function() {

                /**
                 * Properties of a SharetoOpenedRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoOpenedRequest
                 * @property {mtechnavi.api.estimation.IOpened|null} [opened] SharetoOpenedRequest opened
                 */

                /**
                 * Constructs a new SharetoOpenedRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoOpenedRequest.
                 * @implements ISharetoOpenedRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoOpenedRequest=} [properties] Properties to set
                 */
                function SharetoOpenedRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoOpenedRequest opened.
                 * @member {mtechnavi.api.estimation.IOpened|null|undefined} opened
                 * @memberof mtechnavi.api.estimation.SharetoOpenedRequest
                 * @instance
                 */
                SharetoOpenedRequest.prototype.opened = null;

                /**
                 * Verifies a SharetoOpenedRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoOpenedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoOpenedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.opened != null && message.hasOwnProperty("opened")) {
                        let error = $root.mtechnavi.api.estimation.Opened.verify(message.opened);
                        if (error)
                            return "opened." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoOpenedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoOpenedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoOpenedRequest} SharetoOpenedRequest
                 */
                SharetoOpenedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoOpenedRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoOpenedRequest();
                    if (object.opened != null) {
                        if (typeof object.opened !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SharetoOpenedRequest.opened: object expected");
                        message.opened = $root.mtechnavi.api.estimation.Opened.fromObject(object.opened);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoOpenedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoOpenedRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoOpenedRequest} message SharetoOpenedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoOpenedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.opened = null;
                    if (message.opened != null && message.hasOwnProperty("opened"))
                        object.opened = $root.mtechnavi.api.estimation.Opened.toObject(message.opened, options);
                    return object;
                };

                /**
                 * Converts this SharetoOpenedRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoOpenedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoOpenedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoOpenedRequest;
            })();

            estimation.SharetoOpenedResponse = (function() {

                /**
                 * Properties of a SharetoOpenedResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoOpenedResponse
                 */

                /**
                 * Constructs a new SharetoOpenedResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoOpenedResponse.
                 * @implements ISharetoOpenedResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoOpenedResponse=} [properties] Properties to set
                 */
                function SharetoOpenedResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoOpenedResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoOpenedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoOpenedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoOpenedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoOpenedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoOpenedResponse} SharetoOpenedResponse
                 */
                SharetoOpenedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoOpenedResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoOpenedResponse();
                };

                /**
                 * Creates a plain object from a SharetoOpenedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoOpenedResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoOpenedResponse} message SharetoOpenedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoOpenedResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoOpenedResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoOpenedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoOpenedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoOpenedResponse;
            })();

            estimation.SharetoCompleteRequest = (function() {

                /**
                 * Properties of a SharetoCompleteRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoCompleteRequest
                 * @property {mtechnavi.api.estimation.IComplete|null} [complete] SharetoCompleteRequest complete
                 */

                /**
                 * Constructs a new SharetoCompleteRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoCompleteRequest.
                 * @implements ISharetoCompleteRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoCompleteRequest=} [properties] Properties to set
                 */
                function SharetoCompleteRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoCompleteRequest complete.
                 * @member {mtechnavi.api.estimation.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.estimation.SharetoCompleteRequest
                 * @instance
                 */
                SharetoCompleteRequest.prototype.complete = null;

                /**
                 * Verifies a SharetoCompleteRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoCompleteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoCompleteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.estimation.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoCompleteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoCompleteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoCompleteRequest} SharetoCompleteRequest
                 */
                SharetoCompleteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoCompleteRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoCompleteRequest();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SharetoCompleteRequest.complete: object expected");
                        message.complete = $root.mtechnavi.api.estimation.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoCompleteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoCompleteRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoCompleteRequest} message SharetoCompleteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoCompleteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.estimation.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this SharetoCompleteRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoCompleteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoCompleteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoCompleteRequest;
            })();

            estimation.SharetoCompleteResponse = (function() {

                /**
                 * Properties of a SharetoCompleteResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoCompleteResponse
                 */

                /**
                 * Constructs a new SharetoCompleteResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoCompleteResponse.
                 * @implements ISharetoCompleteResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoCompleteResponse=} [properties] Properties to set
                 */
                function SharetoCompleteResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoCompleteResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoCompleteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoCompleteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoCompleteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoCompleteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoCompleteResponse} SharetoCompleteResponse
                 */
                SharetoCompleteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoCompleteResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoCompleteResponse();
                };

                /**
                 * Creates a plain object from a SharetoCompleteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoCompleteResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoCompleteResponse} message SharetoCompleteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoCompleteResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoCompleteResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoCompleteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoCompleteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoCompleteResponse;
            })();

            estimation.ListEstimateRequestPlansRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestPlansRequest
                 * @property {string|null} [pageToken] ListEstimateRequestPlansRequest pageToken
                 * @property {Array.<string>|null} [estimateRequestPlanIds] ListEstimateRequestPlansRequest estimateRequestPlanIds
                 * @property {mtechnavi.api.estimation.ListEstimateRequestPlansRequest.IDataFilter|null} [dataFilter] ListEstimateRequestPlansRequest dataFilter
                 */

                /**
                 * Constructs a new ListEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestPlansRequest.
                 * @implements IListEstimateRequestPlansRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlansRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestPlansRequest(properties) {
                    this.estimateRequestPlanIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestPlansRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @instance
                 */
                ListEstimateRequestPlansRequest.prototype.pageToken = "";

                /**
                 * ListEstimateRequestPlansRequest estimateRequestPlanIds.
                 * @member {Array.<string>} estimateRequestPlanIds
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @instance
                 */
                ListEstimateRequestPlansRequest.prototype.estimateRequestPlanIds = $util.emptyArray;

                /**
                 * ListEstimateRequestPlansRequest dataFilter.
                 * @member {mtechnavi.api.estimation.ListEstimateRequestPlansRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @instance
                 */
                ListEstimateRequestPlansRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListEstimateRequestPlansRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestPlansRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateRequestPlanIds != null && message.hasOwnProperty("estimateRequestPlanIds")) {
                        if (!Array.isArray(message.estimateRequestPlanIds))
                            return "estimateRequestPlanIds: array expected";
                        for (let i = 0; i < message.estimateRequestPlanIds.length; ++i)
                            if (!$util.isString(message.estimateRequestPlanIds[i]))
                                return "estimateRequestPlanIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestPlansRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestPlansRequest} ListEstimateRequestPlansRequest
                 */
                ListEstimateRequestPlansRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateRequestPlanIds) {
                        if (!Array.isArray(object.estimateRequestPlanIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansRequest.estimateRequestPlanIds: array expected");
                        message.estimateRequestPlanIds = [];
                        for (let i = 0; i < object.estimateRequestPlanIds.length; ++i)
                            message.estimateRequestPlanIds[i] = String(object.estimateRequestPlanIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestPlansRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlansRequest} message ListEstimateRequestPlansRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestPlansRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateRequestPlanIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateRequestPlanIds && message.estimateRequestPlanIds.length) {
                        object.estimateRequestPlanIds = [];
                        for (let j = 0; j < message.estimateRequestPlanIds.length; ++j)
                            object.estimateRequestPlanIds[j] = message.estimateRequestPlanIds[j];
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestPlansRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestPlansRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListEstimateRequestPlansRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                     * @interface IDataFilter
                     * @property {Array.<sharelib.INameOption>|null} [statuss] DataFilter statuss
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.estimation.ListEstimateRequestPlansRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statuss = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statuss.
                     * @member {Array.<sharelib.INameOption>} statuss
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statuss = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statuss != null && message.hasOwnProperty("statuss")) {
                            if (!Array.isArray(message.statuss))
                                return "statuss: array expected";
                            for (let i = 0; i < message.statuss.length; ++i) {
                                let error = $root.sharelib.NameOption.verify(message.statuss[i]);
                                if (error)
                                    return "statuss." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter();
                        if (object.statuss) {
                            if (!Array.isArray(object.statuss))
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter.statuss: array expected");
                            message.statuss = [];
                            for (let i = 0; i < object.statuss.length; ++i) {
                                if (typeof object.statuss[i] !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter.statuss: object expected");
                                message.statuss[i] = $root.sharelib.NameOption.fromObject(object.statuss[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.statuss = [];
                        if (message.statuss && message.statuss.length) {
                            object.statuss = [];
                            for (let j = 0; j < message.statuss.length; ++j)
                                object.statuss[j] = $root.sharelib.NameOption.toObject(message.statuss[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListEstimateRequestPlansRequest;
            })();

            estimation.ListEstimateRequestPlansResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestPlansResponse
                 * @property {Long|null} [total] ListEstimateRequestPlansResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestPlan>|null} [items] ListEstimateRequestPlansResponse items
                 * @property {string|null} [pageToken] ListEstimateRequestPlansResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestPlansResponse.
                 * @implements IListEstimateRequestPlansResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlansResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestPlansResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestPlansResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @instance
                 */
                ListEstimateRequestPlansResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestPlansResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestPlan>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @instance
                 */
                ListEstimateRequestPlansResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateRequestPlansResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @instance
                 */
                ListEstimateRequestPlansResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateRequestPlansResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestPlansResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestPlansResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestPlansResponse} ListEstimateRequestPlansResponse
                 */
                ListEstimateRequestPlansResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestPlansResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestPlansResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlansResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateRequestPlan.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestPlansResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlansResponse} message ListEstimateRequestPlansResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestPlansResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateRequestPlan.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestPlansResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlansResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestPlansResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestPlansResponse;
            })();

            estimation.DeleteEstimateRequestPlanRequest = (function() {

                /**
                 * Properties of a DeleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IDeleteEstimateRequestPlanRequest
                 * @property {mtechnavi.api.estimation.IEstimateRequestPlan|null} [estimateRequestPlan] DeleteEstimateRequestPlanRequest estimateRequestPlan
                 */

                /**
                 * Constructs a new DeleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a DeleteEstimateRequestPlanRequest.
                 * @implements IDeleteEstimateRequestPlanRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IDeleteEstimateRequestPlanRequest=} [properties] Properties to set
                 */
                function DeleteEstimateRequestPlanRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteEstimateRequestPlanRequest estimateRequestPlan.
                 * @member {mtechnavi.api.estimation.IEstimateRequestPlan|null|undefined} estimateRequestPlan
                 * @memberof mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest
                 * @instance
                 */
                DeleteEstimateRequestPlanRequest.prototype.estimateRequestPlan = null;

                /**
                 * Verifies a DeleteEstimateRequestPlanRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteEstimateRequestPlanRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestPlan != null && message.hasOwnProperty("estimateRequestPlan")) {
                        let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.verify(message.estimateRequestPlan);
                        if (error)
                            return "estimateRequestPlan." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteEstimateRequestPlanRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest} DeleteEstimateRequestPlanRequest
                 */
                DeleteEstimateRequestPlanRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest();
                    if (object.estimateRequestPlan != null) {
                        if (typeof object.estimateRequestPlan !== "object")
                            throw TypeError(".mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest.estimateRequestPlan: object expected");
                        message.estimateRequestPlan = $root.mtechnavi.api.estimation.EstimateRequestPlan.fromObject(object.estimateRequestPlan);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteEstimateRequestPlanRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest
                 * @static
                 * @param {mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest} message DeleteEstimateRequestPlanRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteEstimateRequestPlanRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateRequestPlan = null;
                    if (message.estimateRequestPlan != null && message.hasOwnProperty("estimateRequestPlan"))
                        object.estimateRequestPlan = $root.mtechnavi.api.estimation.EstimateRequestPlan.toObject(message.estimateRequestPlan, options);
                    return object;
                };

                /**
                 * Converts this DeleteEstimateRequestPlanRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.DeleteEstimateRequestPlanRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteEstimateRequestPlanRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteEstimateRequestPlanRequest;
            })();

            estimation.ImportEstimateRequestPlansRequest = (function() {

                /**
                 * Properties of an ImportEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IImportEstimateRequestPlansRequest
                 * @property {string|null} [url] ImportEstimateRequestPlansRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportEstimateRequestPlansRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportEstimateRequestPlansRequest dryRun
                 * @property {boolean|null} [exportError] ImportEstimateRequestPlansRequest exportError
                 */

                /**
                 * Constructs a new ImportEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ImportEstimateRequestPlansRequest.
                 * @implements IImportEstimateRequestPlansRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IImportEstimateRequestPlansRequest=} [properties] Properties to set
                 */
                function ImportEstimateRequestPlansRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportEstimateRequestPlansRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @instance
                 */
                ImportEstimateRequestPlansRequest.prototype.url = "";

                /**
                 * ImportEstimateRequestPlansRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @instance
                 */
                ImportEstimateRequestPlansRequest.prototype.fileFormat = null;

                /**
                 * ImportEstimateRequestPlansRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @instance
                 */
                ImportEstimateRequestPlansRequest.prototype.dryRun = false;

                /**
                 * ImportEstimateRequestPlansRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @instance
                 */
                ImportEstimateRequestPlansRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportEstimateRequestPlansRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportEstimateRequestPlansRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportEstimateRequestPlansRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ImportEstimateRequestPlansRequest} ImportEstimateRequestPlansRequest
                 */
                ImportEstimateRequestPlansRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ImportEstimateRequestPlansRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ImportEstimateRequestPlansRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ImportEstimateRequestPlansRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportEstimateRequestPlansRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ImportEstimateRequestPlansRequest} message ImportEstimateRequestPlansRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportEstimateRequestPlansRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportEstimateRequestPlansRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportEstimateRequestPlansRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportEstimateRequestPlansRequest;
            })();

            estimation.ImportEstimateRequestPlansResponse = (function() {

                /**
                 * Properties of an ImportEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IImportEstimateRequestPlansResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportEstimateRequestPlansResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportEstimateRequestPlansResponse errors
                 * @property {string|null} [assetId] ImportEstimateRequestPlansResponse assetId
                 */

                /**
                 * Constructs a new ImportEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ImportEstimateRequestPlansResponse.
                 * @implements IImportEstimateRequestPlansResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IImportEstimateRequestPlansResponse=} [properties] Properties to set
                 */
                function ImportEstimateRequestPlansResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportEstimateRequestPlansResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @instance
                 */
                ImportEstimateRequestPlansResponse.prototype.summary = null;

                /**
                 * ImportEstimateRequestPlansResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @instance
                 */
                ImportEstimateRequestPlansResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportEstimateRequestPlansResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @instance
                 */
                ImportEstimateRequestPlansResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportEstimateRequestPlansResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportEstimateRequestPlansResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportEstimateRequestPlansResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ImportEstimateRequestPlansResponse} ImportEstimateRequestPlansResponse
                 */
                ImportEstimateRequestPlansResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ImportEstimateRequestPlansResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ImportEstimateRequestPlansResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ImportEstimateRequestPlansResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.estimation.ImportEstimateRequestPlansResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ImportEstimateRequestPlansResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportEstimateRequestPlansResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ImportEstimateRequestPlansResponse} message ImportEstimateRequestPlansResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportEstimateRequestPlansResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportEstimateRequestPlansResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ImportEstimateRequestPlansResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportEstimateRequestPlansResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportEstimateRequestPlansResponse;
            })();

            estimation.ListEstimateRequestPlanFormatsRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestPlanFormatsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestPlanFormatsRequest
                 */

                /**
                 * Constructs a new ListEstimateRequestPlanFormatsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestPlanFormatsRequest.
                 * @implements IListEstimateRequestPlanFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlanFormatsRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestPlanFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListEstimateRequestPlanFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestPlanFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestPlanFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest} ListEstimateRequestPlanFormatsRequest
                 */
                ListEstimateRequestPlanFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListEstimateRequestPlanFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest} message ListEstimateRequestPlanFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestPlanFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListEstimateRequestPlanFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestPlanFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestPlanFormatsRequest;
            })();

            estimation.ListEstimateRequestPlanFormatsResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestPlanFormatsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestPlanFormatsResponse
                 * @property {Long|null} [total] ListEstimateRequestPlanFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListEstimateRequestPlanFormatsResponse items
                 */

                /**
                 * Constructs a new ListEstimateRequestPlanFormatsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestPlanFormatsResponse.
                 * @implements IListEstimateRequestPlanFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestPlanFormatsResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestPlanFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestPlanFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @instance
                 */
                ListEstimateRequestPlanFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestPlanFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @instance
                 */
                ListEstimateRequestPlanFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListEstimateRequestPlanFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestPlanFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestPlanFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse} ListEstimateRequestPlanFormatsResponse
                 */
                ListEstimateRequestPlanFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestPlanFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse} message ListEstimateRequestPlanFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestPlanFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestPlanFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestPlanFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestPlanFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestPlanFormatsResponse;
            })();

            estimation.ExportEstimateRequestPlansRequest = (function() {

                /**
                 * Properties of an ExportEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IExportEstimateRequestPlansRequest
                 * @property {Array.<string>|null} [ids] ExportEstimateRequestPlansRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportEstimateRequestPlansRequest fileFormat
                 */

                /**
                 * Constructs a new ExportEstimateRequestPlansRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ExportEstimateRequestPlansRequest.
                 * @implements IExportEstimateRequestPlansRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IExportEstimateRequestPlansRequest=} [properties] Properties to set
                 */
                function ExportEstimateRequestPlansRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportEstimateRequestPlansRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @instance
                 */
                ExportEstimateRequestPlansRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportEstimateRequestPlansRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @instance
                 */
                ExportEstimateRequestPlansRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportEstimateRequestPlansRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportEstimateRequestPlansRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportEstimateRequestPlansRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ExportEstimateRequestPlansRequest} ExportEstimateRequestPlansRequest
                 */
                ExportEstimateRequestPlansRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ExportEstimateRequestPlansRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ExportEstimateRequestPlansRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.estimation.ExportEstimateRequestPlansRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ExportEstimateRequestPlansRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportEstimateRequestPlansRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ExportEstimateRequestPlansRequest} message ExportEstimateRequestPlansRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportEstimateRequestPlansRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportEstimateRequestPlansRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportEstimateRequestPlansRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportEstimateRequestPlansRequest;
            })();

            estimation.ExportEstimateRequestPlansResponse = (function() {

                /**
                 * Properties of an ExportEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IExportEstimateRequestPlansResponse
                 * @property {string|null} [assetId] ExportEstimateRequestPlansResponse assetId
                 */

                /**
                 * Constructs a new ExportEstimateRequestPlansResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ExportEstimateRequestPlansResponse.
                 * @implements IExportEstimateRequestPlansResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IExportEstimateRequestPlansResponse=} [properties] Properties to set
                 */
                function ExportEstimateRequestPlansResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportEstimateRequestPlansResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansResponse
                 * @instance
                 */
                ExportEstimateRequestPlansResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportEstimateRequestPlansResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportEstimateRequestPlansResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportEstimateRequestPlansResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ExportEstimateRequestPlansResponse} ExportEstimateRequestPlansResponse
                 */
                ExportEstimateRequestPlansResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ExportEstimateRequestPlansResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ExportEstimateRequestPlansResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportEstimateRequestPlansResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ExportEstimateRequestPlansResponse} message ExportEstimateRequestPlansResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportEstimateRequestPlansResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportEstimateRequestPlansResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ExportEstimateRequestPlansResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportEstimateRequestPlansResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportEstimateRequestPlansResponse;
            })();

            estimation.CompleteEstimateRequestPlanRequest = (function() {

                /**
                 * Properties of a CompleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateRequestPlanRequest
                 * @property {string|null} [estimateRequestPlanId] CompleteEstimateRequestPlanRequest estimateRequestPlanId
                 */

                /**
                 * Constructs a new CompleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateRequestPlanRequest.
                 * @implements ICompleteEstimateRequestPlanRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateRequestPlanRequest=} [properties] Properties to set
                 */
                function CompleteEstimateRequestPlanRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateRequestPlanRequest estimateRequestPlanId.
                 * @member {string} estimateRequestPlanId
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest
                 * @instance
                 */
                CompleteEstimateRequestPlanRequest.prototype.estimateRequestPlanId = "";

                /**
                 * Verifies a CompleteEstimateRequestPlanRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateRequestPlanRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        if (!$util.isString(message.estimateRequestPlanId))
                            return "estimateRequestPlanId: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteEstimateRequestPlanRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest} CompleteEstimateRequestPlanRequest
                 */
                CompleteEstimateRequestPlanRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest();
                    if (object.estimateRequestPlanId != null)
                        message.estimateRequestPlanId = String(object.estimateRequestPlanId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateRequestPlanRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest} message CompleteEstimateRequestPlanRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateRequestPlanRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateRequestPlanId = "";
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        object.estimateRequestPlanId = message.estimateRequestPlanId;
                    return object;
                };

                /**
                 * Converts this CompleteEstimateRequestPlanRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateRequestPlanRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateRequestPlanRequest;
            })();

            estimation.CompleteEstimateRequestPlanResponse = (function() {

                /**
                 * Properties of a CompleteEstimateRequestPlanResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateRequestPlanResponse
                 * @property {mtechnavi.api.estimation.IComplete|null} [complete] CompleteEstimateRequestPlanResponse complete
                 */

                /**
                 * Constructs a new CompleteEstimateRequestPlanResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateRequestPlanResponse.
                 * @implements ICompleteEstimateRequestPlanResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateRequestPlanResponse=} [properties] Properties to set
                 */
                function CompleteEstimateRequestPlanResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateRequestPlanResponse complete.
                 * @member {mtechnavi.api.estimation.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse
                 * @instance
                 */
                CompleteEstimateRequestPlanResponse.prototype.complete = null;

                /**
                 * Verifies a CompleteEstimateRequestPlanResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateRequestPlanResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.estimation.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteEstimateRequestPlanResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse} CompleteEstimateRequestPlanResponse
                 */
                CompleteEstimateRequestPlanResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse.complete: object expected");
                        message.complete = $root.mtechnavi.api.estimation.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateRequestPlanResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse} message CompleteEstimateRequestPlanResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateRequestPlanResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.estimation.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this CompleteEstimateRequestPlanResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateRequestPlanResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateRequestPlanResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateRequestPlanResponse;
            })();

            estimation.UncompleteEstimateRequestPlanRequest = (function() {

                /**
                 * Properties of an UncompleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUncompleteEstimateRequestPlanRequest
                 * @property {string|null} [estimateRequestPlanId] UncompleteEstimateRequestPlanRequest estimateRequestPlanId
                 */

                /**
                 * Constructs a new UncompleteEstimateRequestPlanRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UncompleteEstimateRequestPlanRequest.
                 * @implements IUncompleteEstimateRequestPlanRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUncompleteEstimateRequestPlanRequest=} [properties] Properties to set
                 */
                function UncompleteEstimateRequestPlanRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompleteEstimateRequestPlanRequest estimateRequestPlanId.
                 * @member {string} estimateRequestPlanId
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest
                 * @instance
                 */
                UncompleteEstimateRequestPlanRequest.prototype.estimateRequestPlanId = "";

                /**
                 * Verifies an UncompleteEstimateRequestPlanRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteEstimateRequestPlanRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        if (!$util.isString(message.estimateRequestPlanId))
                            return "estimateRequestPlanId: string expected";
                    return null;
                };

                /**
                 * Creates an UncompleteEstimateRequestPlanRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest} UncompleteEstimateRequestPlanRequest
                 */
                UncompleteEstimateRequestPlanRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest();
                    if (object.estimateRequestPlanId != null)
                        message.estimateRequestPlanId = String(object.estimateRequestPlanId);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompleteEstimateRequestPlanRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest} message UncompleteEstimateRequestPlanRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteEstimateRequestPlanRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateRequestPlanId = "";
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        object.estimateRequestPlanId = message.estimateRequestPlanId;
                    return object;
                };

                /**
                 * Converts this UncompleteEstimateRequestPlanRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteEstimateRequestPlanRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteEstimateRequestPlanRequest;
            })();

            estimation.UncompleteEstimateRequestPlanResponse = (function() {

                /**
                 * Properties of an UncompleteEstimateRequestPlanResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUncompleteEstimateRequestPlanResponse
                 */

                /**
                 * Constructs a new UncompleteEstimateRequestPlanResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UncompleteEstimateRequestPlanResponse.
                 * @implements IUncompleteEstimateRequestPlanResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUncompleteEstimateRequestPlanResponse=} [properties] Properties to set
                 */
                function UncompleteEstimateRequestPlanResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an UncompleteEstimateRequestPlanResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteEstimateRequestPlanResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UncompleteEstimateRequestPlanResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse} UncompleteEstimateRequestPlanResponse
                 */
                UncompleteEstimateRequestPlanResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse();
                };

                /**
                 * Creates a plain object from an UncompleteEstimateRequestPlanResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse
                 * @static
                 * @param {mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse} message UncompleteEstimateRequestPlanResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteEstimateRequestPlanResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UncompleteEstimateRequestPlanResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UncompleteEstimateRequestPlanResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteEstimateRequestPlanResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteEstimateRequestPlanResponse;
            })();

            estimation.SaveEstimateManagementWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a SaveEstimateManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISaveEstimateManagementWithRelatedItemsRequest
                 * @property {mtechnavi.api.estimation.IEstimateManagement|null} [estimateManagement] SaveEstimateManagementWithRelatedItemsRequest estimateManagement
                 * @property {mtechnavi.api.estimation.IEstimateRequest|null} [estimateRequest] SaveEstimateManagementWithRelatedItemsRequest estimateRequest
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestDetail>|null} [estimateRequestDetails] SaveEstimateManagementWithRelatedItemsRequest estimateRequestDetails
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestUnit>|null} [estimateRequestUnits] SaveEstimateManagementWithRelatedItemsRequest estimateRequestUnits
                 */

                /**
                 * Constructs a new SaveEstimateManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SaveEstimateManagementWithRelatedItemsRequest.
                 * @implements ISaveEstimateManagementWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function SaveEstimateManagementWithRelatedItemsRequest(properties) {
                    this.estimateRequestDetails = [];
                    this.estimateRequestUnits = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveEstimateManagementWithRelatedItemsRequest estimateManagement.
                 * @member {mtechnavi.api.estimation.IEstimateManagement|null|undefined} estimateManagement
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveEstimateManagementWithRelatedItemsRequest.prototype.estimateManagement = null;

                /**
                 * SaveEstimateManagementWithRelatedItemsRequest estimateRequest.
                 * @member {mtechnavi.api.estimation.IEstimateRequest|null|undefined} estimateRequest
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveEstimateManagementWithRelatedItemsRequest.prototype.estimateRequest = null;

                /**
                 * SaveEstimateManagementWithRelatedItemsRequest estimateRequestDetails.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestDetail>} estimateRequestDetails
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveEstimateManagementWithRelatedItemsRequest.prototype.estimateRequestDetails = $util.emptyArray;

                /**
                 * SaveEstimateManagementWithRelatedItemsRequest estimateRequestUnits.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestUnit>} estimateRequestUnits
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @instance
                 */
                SaveEstimateManagementWithRelatedItemsRequest.prototype.estimateRequestUnits = $util.emptyArray;

                /**
                 * Verifies a SaveEstimateManagementWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveEstimateManagementWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement")) {
                        let error = $root.mtechnavi.api.estimation.EstimateManagement.verify(message.estimateManagement);
                        if (error)
                            return "estimateManagement." + error;
                    }
                    if (message.estimateRequest != null && message.hasOwnProperty("estimateRequest")) {
                        let error = $root.mtechnavi.api.estimation.EstimateRequest.verify(message.estimateRequest);
                        if (error)
                            return "estimateRequest." + error;
                    }
                    if (message.estimateRequestDetails != null && message.hasOwnProperty("estimateRequestDetails")) {
                        if (!Array.isArray(message.estimateRequestDetails))
                            return "estimateRequestDetails: array expected";
                        for (let i = 0; i < message.estimateRequestDetails.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestDetail.verify(message.estimateRequestDetails[i]);
                            if (error)
                                return "estimateRequestDetails." + error;
                        }
                    }
                    if (message.estimateRequestUnits != null && message.hasOwnProperty("estimateRequestUnits")) {
                        if (!Array.isArray(message.estimateRequestUnits))
                            return "estimateRequestUnits: array expected";
                        for (let i = 0; i < message.estimateRequestUnits.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestUnit.verify(message.estimateRequestUnits[i]);
                            if (error)
                                return "estimateRequestUnits." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SaveEstimateManagementWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest} SaveEstimateManagementWithRelatedItemsRequest
                 */
                SaveEstimateManagementWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest();
                    if (object.estimateManagement != null) {
                        if (typeof object.estimateManagement !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateManagement: object expected");
                        message.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.fromObject(object.estimateManagement);
                    }
                    if (object.estimateRequest != null) {
                        if (typeof object.estimateRequest !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateRequest: object expected");
                        message.estimateRequest = $root.mtechnavi.api.estimation.EstimateRequest.fromObject(object.estimateRequest);
                    }
                    if (object.estimateRequestDetails) {
                        if (!Array.isArray(object.estimateRequestDetails))
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateRequestDetails: array expected");
                        message.estimateRequestDetails = [];
                        for (let i = 0; i < object.estimateRequestDetails.length; ++i) {
                            if (typeof object.estimateRequestDetails[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateRequestDetails: object expected");
                            message.estimateRequestDetails[i] = $root.mtechnavi.api.estimation.EstimateRequestDetail.fromObject(object.estimateRequestDetails[i]);
                        }
                    }
                    if (object.estimateRequestUnits) {
                        if (!Array.isArray(object.estimateRequestUnits))
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateRequestUnits: array expected");
                        message.estimateRequestUnits = [];
                        for (let i = 0; i < object.estimateRequestUnits.length; ++i) {
                            if (typeof object.estimateRequestUnits[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest.estimateRequestUnits: object expected");
                            message.estimateRequestUnits[i] = $root.mtechnavi.api.estimation.EstimateRequestUnit.fromObject(object.estimateRequestUnits[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveEstimateManagementWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest} message SaveEstimateManagementWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveEstimateManagementWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.estimateRequestDetails = [];
                        object.estimateRequestUnits = [];
                    }
                    if (options.defaults) {
                        object.estimateManagement = null;
                        object.estimateRequest = null;
                    }
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement"))
                        object.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.toObject(message.estimateManagement, options);
                    if (message.estimateRequest != null && message.hasOwnProperty("estimateRequest"))
                        object.estimateRequest = $root.mtechnavi.api.estimation.EstimateRequest.toObject(message.estimateRequest, options);
                    if (message.estimateRequestDetails && message.estimateRequestDetails.length) {
                        object.estimateRequestDetails = [];
                        for (let j = 0; j < message.estimateRequestDetails.length; ++j)
                            object.estimateRequestDetails[j] = $root.mtechnavi.api.estimation.EstimateRequestDetail.toObject(message.estimateRequestDetails[j], options);
                    }
                    if (message.estimateRequestUnits && message.estimateRequestUnits.length) {
                        object.estimateRequestUnits = [];
                        for (let j = 0; j < message.estimateRequestUnits.length; ++j)
                            object.estimateRequestUnits[j] = $root.mtechnavi.api.estimation.EstimateRequestUnit.toObject(message.estimateRequestUnits[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this SaveEstimateManagementWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveEstimateManagementWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveEstimateManagementWithRelatedItemsRequest;
            })();

            estimation.SaveEstimateManagementWithRelatedItemsResponse = (function() {

                /**
                 * Properties of a SaveEstimateManagementWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISaveEstimateManagementWithRelatedItemsResponse
                 * @property {mtechnavi.api.estimation.IEstimateManagement|null} [estimateManagement] SaveEstimateManagementWithRelatedItemsResponse estimateManagement
                 */

                /**
                 * Constructs a new SaveEstimateManagementWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SaveEstimateManagementWithRelatedItemsResponse.
                 * @implements ISaveEstimateManagementWithRelatedItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISaveEstimateManagementWithRelatedItemsResponse=} [properties] Properties to set
                 */
                function SaveEstimateManagementWithRelatedItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveEstimateManagementWithRelatedItemsResponse estimateManagement.
                 * @member {mtechnavi.api.estimation.IEstimateManagement|null|undefined} estimateManagement
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse
                 * @instance
                 */
                SaveEstimateManagementWithRelatedItemsResponse.prototype.estimateManagement = null;

                /**
                 * Verifies a SaveEstimateManagementWithRelatedItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveEstimateManagementWithRelatedItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement")) {
                        let error = $root.mtechnavi.api.estimation.EstimateManagement.verify(message.estimateManagement);
                        if (error)
                            return "estimateManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveEstimateManagementWithRelatedItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse} SaveEstimateManagementWithRelatedItemsResponse
                 */
                SaveEstimateManagementWithRelatedItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse();
                    if (object.estimateManagement != null) {
                        if (typeof object.estimateManagement !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse.estimateManagement: object expected");
                        message.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.fromObject(object.estimateManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveEstimateManagementWithRelatedItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse} message SaveEstimateManagementWithRelatedItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveEstimateManagementWithRelatedItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateManagement = null;
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement"))
                        object.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.toObject(message.estimateManagement, options);
                    return object;
                };

                /**
                 * Converts this SaveEstimateManagementWithRelatedItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SaveEstimateManagementWithRelatedItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveEstimateManagementWithRelatedItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveEstimateManagementWithRelatedItemsResponse;
            })();

            estimation.DeleteEstimateManagementWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteEstimateManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IDeleteEstimateManagementWithRelatedItemsRequest
                 * @property {string|null} [estimateManagementId] DeleteEstimateManagementWithRelatedItemsRequest estimateManagementId
                 */

                /**
                 * Constructs a new DeleteEstimateManagementWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a DeleteEstimateManagementWithRelatedItemsRequest.
                 * @implements IDeleteEstimateManagementWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IDeleteEstimateManagementWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteEstimateManagementWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteEstimateManagementWithRelatedItemsRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest
                 * @instance
                 */
                DeleteEstimateManagementWithRelatedItemsRequest.prototype.estimateManagementId = "";

                /**
                 * Verifies a DeleteEstimateManagementWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteEstimateManagementWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteEstimateManagementWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest} DeleteEstimateManagementWithRelatedItemsRequest
                 */
                DeleteEstimateManagementWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteEstimateManagementWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest} message DeleteEstimateManagementWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteEstimateManagementWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateManagementId = "";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    return object;
                };

                /**
                 * Converts this DeleteEstimateManagementWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.DeleteEstimateManagementWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteEstimateManagementWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteEstimateManagementWithRelatedItemsRequest;
            })();

            estimation.ListEstimateManagementsRequest = (function() {

                /**
                 * Properties of a ListEstimateManagementsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateManagementsRequest
                 * @property {string|null} [pageToken] ListEstimateManagementsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] ListEstimateManagementsRequest estimateManagementIds
                 */

                /**
                 * Constructs a new ListEstimateManagementsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateManagementsRequest.
                 * @implements IListEstimateManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateManagementsRequest=} [properties] Properties to set
                 */
                function ListEstimateManagementsRequest(properties) {
                    this.estimateManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateManagementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @instance
                 */
                ListEstimateManagementsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateManagementsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @instance
                 */
                ListEstimateManagementsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateManagementsRequest} ListEstimateManagementsRequest
                 */
                ListEstimateManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateManagementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateManagementsRequest} message ListEstimateManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateManagementsRequest;
            })();

            estimation.ListEstimateManagementsResponse = (function() {

                /**
                 * Properties of a ListEstimateManagementsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateManagementsResponse
                 * @property {Long|null} [total] ListEstimateManagementsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateManagement>|null} [items] ListEstimateManagementsResponse items
                 * @property {string|null} [pageToken] ListEstimateManagementsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateManagementsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateManagementsResponse.
                 * @implements IListEstimateManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateManagementsResponse=} [properties] Properties to set
                 */
                function ListEstimateManagementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateManagementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @instance
                 */
                ListEstimateManagementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateManagementsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateManagement>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @instance
                 */
                ListEstimateManagementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateManagementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @instance
                 */
                ListEstimateManagementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateManagement.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateManagementsResponse} ListEstimateManagementsResponse
                 */
                ListEstimateManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateManagementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateManagement.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateManagementsResponse} message ListEstimateManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateManagement.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateManagementsResponse;
            })();

            estimation.UpdateEstimateManagementRequest = (function() {

                /**
                 * Properties of an UpdateEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateEstimateManagementRequest
                 * @property {mtechnavi.api.estimation.IEstimateManagement|null} [estimateManagement] UpdateEstimateManagementRequest estimateManagement
                 */

                /**
                 * Constructs a new UpdateEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateEstimateManagementRequest.
                 * @implements IUpdateEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateEstimateManagementRequest=} [properties] Properties to set
                 */
                function UpdateEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateEstimateManagementRequest estimateManagement.
                 * @member {mtechnavi.api.estimation.IEstimateManagement|null|undefined} estimateManagement
                 * @memberof mtechnavi.api.estimation.UpdateEstimateManagementRequest
                 * @instance
                 */
                UpdateEstimateManagementRequest.prototype.estimateManagement = null;

                /**
                 * Verifies an UpdateEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement")) {
                        let error = $root.mtechnavi.api.estimation.EstimateManagement.verify(message.estimateManagement);
                        if (error)
                            return "estimateManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateEstimateManagementRequest} UpdateEstimateManagementRequest
                 */
                UpdateEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateEstimateManagementRequest();
                    if (object.estimateManagement != null) {
                        if (typeof object.estimateManagement !== "object")
                            throw TypeError(".mtechnavi.api.estimation.UpdateEstimateManagementRequest.estimateManagement: object expected");
                        message.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.fromObject(object.estimateManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateEstimateManagementRequest} message UpdateEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateManagement = null;
                    if (message.estimateManagement != null && message.hasOwnProperty("estimateManagement"))
                        object.estimateManagement = $root.mtechnavi.api.estimation.EstimateManagement.toObject(message.estimateManagement, options);
                    return object;
                };

                /**
                 * Converts this UpdateEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateEstimateManagementRequest;
            })();

            estimation.ApprovalRequestEstimateManagementRequest = (function() {

                /**
                 * Properties of an ApprovalRequestEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApprovalRequestEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] ApprovalRequestEstimateManagementRequest estimateManagementId
                 * @property {sharelib.IUserReference|null} [approvalPlanStaff] ApprovalRequestEstimateManagementRequest approvalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] ApprovalRequestEstimateManagementRequest approvalPlanOrganization
                 * @property {string|null} [comment] ApprovalRequestEstimateManagementRequest comment
                 */

                /**
                 * Constructs a new ApprovalRequestEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApprovalRequestEstimateManagementRequest.
                 * @implements IApprovalRequestEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateManagementRequest=} [properties] Properties to set
                 */
                function ApprovalRequestEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApprovalRequestEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @instance
                 */
                ApprovalRequestEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * ApprovalRequestEstimateManagementRequest approvalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @instance
                 */
                ApprovalRequestEstimateManagementRequest.prototype.approvalPlanStaff = null;

                /**
                 * ApprovalRequestEstimateManagementRequest approvalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @instance
                 */
                ApprovalRequestEstimateManagementRequest.prototype.approvalPlanOrganization = null;

                /**
                 * ApprovalRequestEstimateManagementRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @instance
                 */
                ApprovalRequestEstimateManagementRequest.prototype.comment = "";

                /**
                 * Verifies an ApprovalRequestEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                        if (error)
                            return "approvalPlanStaff." + error;
                    }
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                        if (error)
                            return "approvalPlanOrganization." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest} ApprovalRequestEstimateManagementRequest
                 */
                ApprovalRequestEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.approvalPlanStaff != null) {
                        if (typeof object.approvalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest.approvalPlanStaff: object expected");
                        message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
                    }
                    if (object.approvalPlanOrganization != null) {
                        if (typeof object.approvalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest.approvalPlanOrganization: object expected");
                        message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApprovalRequestEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest} message ApprovalRequestEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.comment = "";
                        object.approvalPlanStaff = null;
                        object.approvalPlanOrganization = null;
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                        object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                        object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this ApprovalRequestEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestEstimateManagementRequest;
            })();

            estimation.ApprovalRequestEstimateManagementResponse = (function() {

                /**
                 * Properties of an ApprovalRequestEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApprovalRequestEstimateManagementResponse
                 */

                /**
                 * Constructs a new ApprovalRequestEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApprovalRequestEstimateManagementResponse.
                 * @implements IApprovalRequestEstimateManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateManagementResponse=} [properties] Properties to set
                 */
                function ApprovalRequestEstimateManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApprovalRequestEstimateManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestEstimateManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestEstimateManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse} ApprovalRequestEstimateManagementResponse
                 */
                ApprovalRequestEstimateManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse();
                };

                /**
                 * Creates a plain object from an ApprovalRequestEstimateManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse} message ApprovalRequestEstimateManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestEstimateManagementResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApprovalRequestEstimateManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestEstimateManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestEstimateManagementResponse;
            })();

            estimation.RejectEstimateManagementRequest = (function() {

                /**
                 * Properties of a RejectEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IRejectEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] RejectEstimateManagementRequest estimateManagementId
                 * @property {string|null} [comment] RejectEstimateManagementRequest comment
                 */

                /**
                 * Constructs a new RejectEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a RejectEstimateManagementRequest.
                 * @implements IRejectEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IRejectEstimateManagementRequest=} [properties] Properties to set
                 */
                function RejectEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RejectEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @instance
                 */
                RejectEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * RejectEstimateManagementRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @instance
                 */
                RejectEstimateManagementRequest.prototype.comment = "";

                /**
                 * Verifies a RejectEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a RejectEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.RejectEstimateManagementRequest} RejectEstimateManagementRequest
                 */
                RejectEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.RejectEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.RejectEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a RejectEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.RejectEstimateManagementRequest} message RejectEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.comment = "";
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this RejectEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectEstimateManagementRequest;
            })();

            estimation.RejectEstimateManagementResponse = (function() {

                /**
                 * Properties of a RejectEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IRejectEstimateManagementResponse
                 */

                /**
                 * Constructs a new RejectEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a RejectEstimateManagementResponse.
                 * @implements IRejectEstimateManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IRejectEstimateManagementResponse=} [properties] Properties to set
                 */
                function RejectEstimateManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a RejectEstimateManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectEstimateManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a RejectEstimateManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.RejectEstimateManagementResponse} RejectEstimateManagementResponse
                 */
                RejectEstimateManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.RejectEstimateManagementResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.RejectEstimateManagementResponse();
                };

                /**
                 * Creates a plain object from a RejectEstimateManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementResponse
                 * @static
                 * @param {mtechnavi.api.estimation.RejectEstimateManagementResponse} message RejectEstimateManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectEstimateManagementResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this RejectEstimateManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.RejectEstimateManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectEstimateManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectEstimateManagementResponse;
            })();

            estimation.ApproveEstimateManagementRequest = (function() {

                /**
                 * Properties of an ApproveEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApproveEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] ApproveEstimateManagementRequest estimateManagementId
                 * @property {string|null} [comment] ApproveEstimateManagementRequest comment
                 */

                /**
                 * Constructs a new ApproveEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApproveEstimateManagementRequest.
                 * @implements IApproveEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApproveEstimateManagementRequest=} [properties] Properties to set
                 */
                function ApproveEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApproveEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @instance
                 */
                ApproveEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * ApproveEstimateManagementRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @instance
                 */
                ApproveEstimateManagementRequest.prototype.comment = "";

                /**
                 * Verifies an ApproveEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApproveEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApproveEstimateManagementRequest} ApproveEstimateManagementRequest
                 */
                ApproveEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApproveEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ApproveEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApproveEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ApproveEstimateManagementRequest} message ApproveEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.comment = "";
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this ApproveEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveEstimateManagementRequest;
            })();

            estimation.ApproveEstimateManagementResponse = (function() {

                /**
                 * Properties of an ApproveEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApproveEstimateManagementResponse
                 */

                /**
                 * Constructs a new ApproveEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApproveEstimateManagementResponse.
                 * @implements IApproveEstimateManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApproveEstimateManagementResponse=} [properties] Properties to set
                 */
                function ApproveEstimateManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApproveEstimateManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveEstimateManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApproveEstimateManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApproveEstimateManagementResponse} ApproveEstimateManagementResponse
                 */
                ApproveEstimateManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApproveEstimateManagementResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.ApproveEstimateManagementResponse();
                };

                /**
                 * Creates a plain object from an ApproveEstimateManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ApproveEstimateManagementResponse} message ApproveEstimateManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveEstimateManagementResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApproveEstimateManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApproveEstimateManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveEstimateManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveEstimateManagementResponse;
            })();

            estimation.DiscardEstimateManagementRequest = (function() {

                /**
                 * Properties of a DiscardEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IDiscardEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] DiscardEstimateManagementRequest estimateManagementId
                 * @property {string|null} [comment] DiscardEstimateManagementRequest comment
                 */

                /**
                 * Constructs a new DiscardEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a DiscardEstimateManagementRequest.
                 * @implements IDiscardEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IDiscardEstimateManagementRequest=} [properties] Properties to set
                 */
                function DiscardEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscardEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @instance
                 */
                DiscardEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * DiscardEstimateManagementRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @instance
                 */
                DiscardEstimateManagementRequest.prototype.comment = "";

                /**
                 * Verifies a DiscardEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a DiscardEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.DiscardEstimateManagementRequest} DiscardEstimateManagementRequest
                 */
                DiscardEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.DiscardEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.DiscardEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a DiscardEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.DiscardEstimateManagementRequest} message DiscardEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.comment = "";
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this DiscardEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardEstimateManagementRequest;
            })();

            estimation.DiscardEstimateManagementResponse = (function() {

                /**
                 * Properties of a DiscardEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IDiscardEstimateManagementResponse
                 * @property {mtechnavi.api.estimation.IDiscard|null} [discard] DiscardEstimateManagementResponse discard
                 */

                /**
                 * Constructs a new DiscardEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a DiscardEstimateManagementResponse.
                 * @implements IDiscardEstimateManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IDiscardEstimateManagementResponse=} [properties] Properties to set
                 */
                function DiscardEstimateManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscardEstimateManagementResponse discard.
                 * @member {mtechnavi.api.estimation.IDiscard|null|undefined} discard
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementResponse
                 * @instance
                 */
                DiscardEstimateManagementResponse.prototype.discard = null;

                /**
                 * Verifies a DiscardEstimateManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardEstimateManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discard != null && message.hasOwnProperty("discard")) {
                        let error = $root.mtechnavi.api.estimation.Discard.verify(message.discard);
                        if (error)
                            return "discard." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiscardEstimateManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.DiscardEstimateManagementResponse} DiscardEstimateManagementResponse
                 */
                DiscardEstimateManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.DiscardEstimateManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.DiscardEstimateManagementResponse();
                    if (object.discard != null) {
                        if (typeof object.discard !== "object")
                            throw TypeError(".mtechnavi.api.estimation.DiscardEstimateManagementResponse.discard: object expected");
                        message.discard = $root.mtechnavi.api.estimation.Discard.fromObject(object.discard);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiscardEstimateManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementResponse
                 * @static
                 * @param {mtechnavi.api.estimation.DiscardEstimateManagementResponse} message DiscardEstimateManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardEstimateManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.discard = null;
                    if (message.discard != null && message.hasOwnProperty("discard"))
                        object.discard = $root.mtechnavi.api.estimation.Discard.toObject(message.discard, options);
                    return object;
                };

                /**
                 * Converts this DiscardEstimateManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.DiscardEstimateManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardEstimateManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardEstimateManagementResponse;
            })();

            estimation.CompleteEstimateManagementRequest = (function() {

                /**
                 * Properties of a CompleteEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] CompleteEstimateManagementRequest estimateManagementId
                 * @property {string|null} [comment] CompleteEstimateManagementRequest comment
                 */

                /**
                 * Constructs a new CompleteEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateManagementRequest.
                 * @implements ICompleteEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateManagementRequest=} [properties] Properties to set
                 */
                function CompleteEstimateManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @instance
                 */
                CompleteEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * CompleteEstimateManagementRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @instance
                 */
                CompleteEstimateManagementRequest.prototype.comment = "";

                /**
                 * Verifies a CompleteEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateManagementRequest} CompleteEstimateManagementRequest
                 */
                CompleteEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateManagementRequest} message CompleteEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.comment = "";
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this CompleteEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateManagementRequest;
            })();

            estimation.CompleteEstimateManagementResponse = (function() {

                /**
                 * Properties of a CompleteEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateManagementResponse
                 * @property {mtechnavi.api.estimation.IComplete|null} [complete] CompleteEstimateManagementResponse complete
                 */

                /**
                 * Constructs a new CompleteEstimateManagementResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateManagementResponse.
                 * @implements ICompleteEstimateManagementResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateManagementResponse=} [properties] Properties to set
                 */
                function CompleteEstimateManagementResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateManagementResponse complete.
                 * @member {mtechnavi.api.estimation.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementResponse
                 * @instance
                 */
                CompleteEstimateManagementResponse.prototype.complete = null;

                /**
                 * Verifies a CompleteEstimateManagementResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateManagementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.estimation.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteEstimateManagementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateManagementResponse} CompleteEstimateManagementResponse
                 */
                CompleteEstimateManagementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateManagementResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateManagementResponse();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.estimation.CompleteEstimateManagementResponse.complete: object expected");
                        message.complete = $root.mtechnavi.api.estimation.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateManagementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementResponse
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateManagementResponse} message CompleteEstimateManagementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateManagementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.estimation.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this CompleteEstimateManagementResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateManagementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateManagementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateManagementResponse;
            })();

            estimation.UpdateSystemNotificationUsersInEstimateManagementRequest = (function() {

                /**
                 * Properties of an UpdateSystemNotificationUsersInEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateSystemNotificationUsersInEstimateManagementRequest
                 * @property {string|null} [estimateManagementId] UpdateSystemNotificationUsersInEstimateManagementRequest estimateManagementId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] UpdateSystemNotificationUsersInEstimateManagementRequest systemNotificationUsers
                 * @property {Long|null} [updatedAt] UpdateSystemNotificationUsersInEstimateManagementRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateSystemNotificationUsersInEstimateManagementRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateSystemNotificationUsersInEstimateManagementRequest.
                 * @implements IUpdateSystemNotificationUsersInEstimateManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateManagementRequest=} [properties] Properties to set
                 */
                function UpdateSystemNotificationUsersInEstimateManagementRequest(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSystemNotificationUsersInEstimateManagementRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.prototype.estimateManagementId = "";

                /**
                 * UpdateSystemNotificationUsersInEstimateManagementRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * UpdateSystemNotificationUsersInEstimateManagementRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateSystemNotificationUsersInEstimateManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateSystemNotificationUsersInEstimateManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest} UpdateSystemNotificationUsersInEstimateManagementRequest
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSystemNotificationUsersInEstimateManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest} message UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateSystemNotificationUsersInEstimateManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSystemNotificationUsersInEstimateManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSystemNotificationUsersInEstimateManagementRequest;
            })();

            estimation.SharetoEstimateRequestWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a SharetoEstimateRequestWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateRequestWithRelatedItemsRequest
                 * @property {string|null} [estimateManagementId] SharetoEstimateRequestWithRelatedItemsRequest estimateManagementId
                 */

                /**
                 * Constructs a new SharetoEstimateRequestWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateRequestWithRelatedItemsRequest.
                 * @implements ISharetoEstimateRequestWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function SharetoEstimateRequestWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoEstimateRequestWithRelatedItemsRequest estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest
                 * @instance
                 */
                SharetoEstimateRequestWithRelatedItemsRequest.prototype.estimateManagementId = "";

                /**
                 * Verifies a SharetoEstimateRequestWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateRequestWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateRequestWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest} SharetoEstimateRequestWithRelatedItemsRequest
                 */
                SharetoEstimateRequestWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoEstimateRequestWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest} message SharetoEstimateRequestWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateRequestWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateManagementId = "";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    return object;
                };

                /**
                 * Converts this SharetoEstimateRequestWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateRequestWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateRequestWithRelatedItemsRequest;
            })();

            estimation.SharetoEstimateRequestWithRelatedItemsResponse = (function() {

                /**
                 * Properties of a SharetoEstimateRequestWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateRequestWithRelatedItemsResponse
                 */

                /**
                 * Constructs a new SharetoEstimateRequestWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateRequestWithRelatedItemsResponse.
                 * @implements ISharetoEstimateRequestWithRelatedItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateRequestWithRelatedItemsResponse=} [properties] Properties to set
                 */
                function SharetoEstimateRequestWithRelatedItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoEstimateRequestWithRelatedItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateRequestWithRelatedItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateRequestWithRelatedItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse} SharetoEstimateRequestWithRelatedItemsResponse
                 */
                SharetoEstimateRequestWithRelatedItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse();
                };

                /**
                 * Creates a plain object from a SharetoEstimateRequestWithRelatedItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse} message SharetoEstimateRequestWithRelatedItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateRequestWithRelatedItemsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoEstimateRequestWithRelatedItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateRequestWithRelatedItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateRequestWithRelatedItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateRequestWithRelatedItemsResponse;
            })();

            estimation.ListEstimateRequestsRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestsRequest
                 * @property {string|null} [pageToken] ListEstimateRequestsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] ListEstimateRequestsRequest estimateManagementIds
                 */

                /**
                 * Constructs a new ListEstimateRequestsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestsRequest.
                 * @implements IListEstimateRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestsRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestsRequest(properties) {
                    this.estimateManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @instance
                 */
                ListEstimateRequestsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateRequestsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @instance
                 */
                ListEstimateRequestsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestsRequest} ListEstimateRequestsRequest
                 */
                ListEstimateRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestsRequest} message ListEstimateRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestsRequest;
            })();

            estimation.ListEstimateRequestsResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestsResponse
                 * @property {Long|null} [total] ListEstimateRequestsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequest>|null} [items] ListEstimateRequestsResponse items
                 * @property {string|null} [pageToken] ListEstimateRequestsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateRequestsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestsResponse.
                 * @implements IListEstimateRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestsResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @instance
                 */
                ListEstimateRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequest>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @instance
                 */
                ListEstimateRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateRequestsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @instance
                 */
                ListEstimateRequestsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestsResponse} ListEstimateRequestsResponse
                 */
                ListEstimateRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateRequest.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestsResponse} message ListEstimateRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateRequest.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestsResponse;
            })();

            estimation.ListEstimateRequestDetailsRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestDetailsRequest
                 * @property {string|null} [pageToken] ListEstimateRequestDetailsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] ListEstimateRequestDetailsRequest estimateManagementIds
                 */

                /**
                 * Constructs a new ListEstimateRequestDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestDetailsRequest.
                 * @implements IListEstimateRequestDetailsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestDetailsRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestDetailsRequest(properties) {
                    this.estimateManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestDetailsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @instance
                 */
                ListEstimateRequestDetailsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateRequestDetailsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @instance
                 */
                ListEstimateRequestDetailsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateRequestDetailsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestDetailsRequest} ListEstimateRequestDetailsRequest
                 */
                ListEstimateRequestDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestDetailsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestDetailsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestDetailsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestDetailsRequest} message ListEstimateRequestDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestDetailsRequest;
            })();

            estimation.ListEstimateRequestDetailsResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestDetailsResponse
                 * @property {Long|null} [total] ListEstimateRequestDetailsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestDetail>|null} [items] ListEstimateRequestDetailsResponse items
                 * @property {string|null} [pageToken] ListEstimateRequestDetailsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateRequestDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestDetailsResponse.
                 * @implements IListEstimateRequestDetailsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestDetailsResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestDetailsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestDetailsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @instance
                 */
                ListEstimateRequestDetailsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestDetailsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestDetail>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @instance
                 */
                ListEstimateRequestDetailsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateRequestDetailsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @instance
                 */
                ListEstimateRequestDetailsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateRequestDetailsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestDetail.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestDetailsResponse} ListEstimateRequestDetailsResponse
                 */
                ListEstimateRequestDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestDetailsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestDetailsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestDetailsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestDetailsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateRequestDetail.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestDetailsResponse} message ListEstimateRequestDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateRequestDetail.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestDetailsResponse;
            })();

            estimation.ListEstimateRequestUnitsRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestUnitsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestUnitsRequest
                 * @property {string|null} [pageToken] ListEstimateRequestUnitsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] ListEstimateRequestUnitsRequest estimateManagementIds
                 */

                /**
                 * Constructs a new ListEstimateRequestUnitsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestUnitsRequest.
                 * @implements IListEstimateRequestUnitsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitsRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestUnitsRequest(properties) {
                    this.estimateManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestUnitsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @instance
                 */
                ListEstimateRequestUnitsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateRequestUnitsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @instance
                 */
                ListEstimateRequestUnitsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateRequestUnitsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestUnitsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestUnitsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestUnitsRequest} ListEstimateRequestUnitsRequest
                 */
                ListEstimateRequestUnitsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestUnitsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestUnitsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestUnitsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitsRequest} message ListEstimateRequestUnitsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestUnitsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateManagementIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestUnitsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestUnitsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestUnitsRequest;
            })();

            estimation.ListEstimateRequestUnitsResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestUnitsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestUnitsResponse
                 * @property {Long|null} [total] ListEstimateRequestUnitsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestUnit>|null} [items] ListEstimateRequestUnitsResponse items
                 * @property {string|null} [pageToken] ListEstimateRequestUnitsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateRequestUnitsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestUnitsResponse.
                 * @implements IListEstimateRequestUnitsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitsResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestUnitsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestUnitsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @instance
                 */
                ListEstimateRequestUnitsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestUnitsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestUnit>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @instance
                 */
                ListEstimateRequestUnitsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateRequestUnitsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @instance
                 */
                ListEstimateRequestUnitsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateRequestUnitsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestUnitsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestUnit.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestUnitsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestUnitsResponse} ListEstimateRequestUnitsResponse
                 */
                ListEstimateRequestUnitsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestUnitsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestUnitsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateRequestUnit.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestUnitsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitsResponse} message ListEstimateRequestUnitsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestUnitsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateRequestUnit.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestUnitsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestUnitsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestUnitsResponse;
            })();

            estimation.ListEstimateManagementSummarysRequest = (function() {

                /**
                 * Properties of a ListEstimateManagementSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateManagementSummarysRequest
                 * @property {string|null} [pageToken] ListEstimateManagementSummarysRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] ListEstimateManagementSummarysRequest estimateManagementIds
                 * @property {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.IDataFilter|null} [dataFilter] ListEstimateManagementSummarysRequest dataFilter
                 */

                /**
                 * Constructs a new ListEstimateManagementSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateManagementSummarysRequest.
                 * @implements IListEstimateManagementSummarysRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateManagementSummarysRequest=} [properties] Properties to set
                 */
                function ListEstimateManagementSummarysRequest(properties) {
                    this.estimateManagementIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateManagementSummarysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @instance
                 */
                ListEstimateManagementSummarysRequest.prototype.pageToken = "";

                /**
                 * ListEstimateManagementSummarysRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @instance
                 */
                ListEstimateManagementSummarysRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * ListEstimateManagementSummarysRequest dataFilter.
                 * @member {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @instance
                 */
                ListEstimateManagementSummarysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListEstimateManagementSummarysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateManagementSummarysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateManagementSummarysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest} ListEstimateManagementSummarysRequest
                 */
                ListEstimateManagementSummarysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateManagementSummarysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest} message ListEstimateManagementSummarysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateManagementSummarysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateManagementIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListEstimateManagementSummarysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateManagementSummarysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListEstimateManagementSummarysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                     * @interface IDataFilter
                     * @property {Array.<sharelib.INameOption>|null} [statuss] DataFilter statuss
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statuss = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statuss.
                     * @member {Array.<sharelib.INameOption>} statuss
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statuss = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statuss != null && message.hasOwnProperty("statuss")) {
                            if (!Array.isArray(message.statuss))
                                return "statuss: array expected";
                            for (let i = 0; i < message.statuss.length; ++i) {
                                let error = $root.sharelib.NameOption.verify(message.statuss[i]);
                                if (error)
                                    return "statuss." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter();
                        if (object.statuss) {
                            if (!Array.isArray(object.statuss))
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter.statuss: array expected");
                            message.statuss = [];
                            for (let i = 0; i < object.statuss.length; ++i) {
                                if (typeof object.statuss[i] !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter.statuss: object expected");
                                message.statuss[i] = $root.sharelib.NameOption.fromObject(object.statuss[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.statuss = [];
                        if (message.statuss && message.statuss.length) {
                            object.statuss = [];
                            for (let j = 0; j < message.statuss.length; ++j)
                                object.statuss[j] = $root.sharelib.NameOption.toObject(message.statuss[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListEstimateManagementSummarysRequest;
            })();

            estimation.ListEstimateManagementSummarysResponse = (function() {

                /**
                 * Properties of a ListEstimateManagementSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateManagementSummarysResponse
                 * @property {Long|null} [total] ListEstimateManagementSummarysResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateManagementSummary>|null} [items] ListEstimateManagementSummarysResponse items
                 * @property {string|null} [pageToken] ListEstimateManagementSummarysResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateManagementSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateManagementSummarysResponse.
                 * @implements IListEstimateManagementSummarysResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateManagementSummarysResponse=} [properties] Properties to set
                 */
                function ListEstimateManagementSummarysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateManagementSummarysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @instance
                 */
                ListEstimateManagementSummarysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateManagementSummarysResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateManagementSummary>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @instance
                 */
                ListEstimateManagementSummarysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateManagementSummarysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @instance
                 */
                ListEstimateManagementSummarysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateManagementSummarysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateManagementSummarysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateManagementSummary.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateManagementSummarysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateManagementSummarysResponse} ListEstimateManagementSummarysResponse
                 */
                ListEstimateManagementSummarysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateManagementSummarysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateManagementSummarysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateManagementSummarysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateManagementSummary.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateManagementSummarysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateManagementSummarysResponse} message ListEstimateManagementSummarysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateManagementSummarysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateManagementSummary.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateManagementSummarysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateManagementSummarysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateManagementSummarysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateManagementSummarysResponse;
            })();

            estimation.ListEstimateRequestUnitSummarysRequest = (function() {

                /**
                 * Properties of a ListEstimateRequestUnitSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestUnitSummarysRequest
                 * @property {string|null} [pageToken] ListEstimateRequestUnitSummarysRequest pageToken
                 * @property {Array.<string>|null} [estimateRequestUnitIds] ListEstimateRequestUnitSummarysRequest estimateRequestUnitIds
                 * @property {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.IDataFilter|null} [dataFilter] ListEstimateRequestUnitSummarysRequest dataFilter
                 */

                /**
                 * Constructs a new ListEstimateRequestUnitSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestUnitSummarysRequest.
                 * @implements IListEstimateRequestUnitSummarysRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitSummarysRequest=} [properties] Properties to set
                 */
                function ListEstimateRequestUnitSummarysRequest(properties) {
                    this.estimateRequestUnitIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestUnitSummarysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @instance
                 */
                ListEstimateRequestUnitSummarysRequest.prototype.pageToken = "";

                /**
                 * ListEstimateRequestUnitSummarysRequest estimateRequestUnitIds.
                 * @member {Array.<string>} estimateRequestUnitIds
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @instance
                 */
                ListEstimateRequestUnitSummarysRequest.prototype.estimateRequestUnitIds = $util.emptyArray;

                /**
                 * ListEstimateRequestUnitSummarysRequest dataFilter.
                 * @member {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @instance
                 */
                ListEstimateRequestUnitSummarysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListEstimateRequestUnitSummarysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestUnitSummarysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateRequestUnitIds != null && message.hasOwnProperty("estimateRequestUnitIds")) {
                        if (!Array.isArray(message.estimateRequestUnitIds))
                            return "estimateRequestUnitIds: array expected";
                        for (let i = 0; i < message.estimateRequestUnitIds.length; ++i)
                            if (!$util.isString(message.estimateRequestUnitIds[i]))
                                return "estimateRequestUnitIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestUnitSummarysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest} ListEstimateRequestUnitSummarysRequest
                 */
                ListEstimateRequestUnitSummarysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateRequestUnitIds) {
                        if (!Array.isArray(object.estimateRequestUnitIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.estimateRequestUnitIds: array expected");
                        message.estimateRequestUnitIds = [];
                        for (let i = 0; i < object.estimateRequestUnitIds.length; ++i)
                            message.estimateRequestUnitIds[i] = String(object.estimateRequestUnitIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestUnitSummarysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest} message ListEstimateRequestUnitSummarysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestUnitSummarysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateRequestUnitIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateRequestUnitIds && message.estimateRequestUnitIds.length) {
                        object.estimateRequestUnitIds = [];
                        for (let j = 0; j < message.estimateRequestUnitIds.length; ++j)
                            object.estimateRequestUnitIds[j] = message.estimateRequestUnitIds[j];
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestUnitSummarysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestUnitSummarysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListEstimateRequestUnitSummarysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                     * @interface IDataFilter
                     * @property {Array.<sharelib.INameOption>|null} [statuss] DataFilter statuss
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statuss = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statuss.
                     * @member {Array.<sharelib.INameOption>} statuss
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statuss = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statuss != null && message.hasOwnProperty("statuss")) {
                            if (!Array.isArray(message.statuss))
                                return "statuss: array expected";
                            for (let i = 0; i < message.statuss.length; ++i) {
                                let error = $root.sharelib.NameOption.verify(message.statuss[i]);
                                if (error)
                                    return "statuss." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter();
                        if (object.statuss) {
                            if (!Array.isArray(object.statuss))
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter.statuss: array expected");
                            message.statuss = [];
                            for (let i = 0; i < object.statuss.length; ++i) {
                                if (typeof object.statuss[i] !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter.statuss: object expected");
                                message.statuss[i] = $root.sharelib.NameOption.fromObject(object.statuss[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.statuss = [];
                        if (message.statuss && message.statuss.length) {
                            object.statuss = [];
                            for (let j = 0; j < message.statuss.length; ++j)
                                object.statuss[j] = $root.sharelib.NameOption.toObject(message.statuss[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListEstimateRequestUnitSummarysRequest;
            })();

            estimation.ListEstimateRequestUnitSummarysResponse = (function() {

                /**
                 * Properties of a ListEstimateRequestUnitSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateRequestUnitSummarysResponse
                 * @property {Long|null} [total] ListEstimateRequestUnitSummarysResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateRequestUnitSummary>|null} [items] ListEstimateRequestUnitSummarysResponse items
                 * @property {string|null} [pageToken] ListEstimateRequestUnitSummarysResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateRequestUnitSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateRequestUnitSummarysResponse.
                 * @implements IListEstimateRequestUnitSummarysResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateRequestUnitSummarysResponse=} [properties] Properties to set
                 */
                function ListEstimateRequestUnitSummarysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateRequestUnitSummarysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @instance
                 */
                ListEstimateRequestUnitSummarysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateRequestUnitSummarysResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateRequestUnitSummary>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @instance
                 */
                ListEstimateRequestUnitSummarysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateRequestUnitSummarysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @instance
                 */
                ListEstimateRequestUnitSummarysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateRequestUnitSummarysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateRequestUnitSummarysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestUnitSummary.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateRequestUnitSummarysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse} ListEstimateRequestUnitSummarysResponse
                 */
                ListEstimateRequestUnitSummarysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateRequestUnitSummary.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateRequestUnitSummarysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse} message ListEstimateRequestUnitSummarysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateRequestUnitSummarysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateRequestUnitSummary.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateRequestUnitSummarysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateRequestUnitSummarysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateRequestUnitSummarysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateRequestUnitSummarysResponse;
            })();

            estimation.SaveEstimateSelectionRequest = (function() {

                /**
                 * Properties of a SaveEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISaveEstimateSelectionRequest
                 * @property {mtechnavi.api.estimation.IEstimateSelection|null} [estimateSelection] SaveEstimateSelectionRequest estimateSelection
                 * @property {string|null} [estimateRequestUnitId] SaveEstimateSelectionRequest estimateRequestUnitId
                 */

                /**
                 * Constructs a new SaveEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SaveEstimateSelectionRequest.
                 * @implements ISaveEstimateSelectionRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISaveEstimateSelectionRequest=} [properties] Properties to set
                 */
                function SaveEstimateSelectionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveEstimateSelectionRequest estimateSelection.
                 * @member {mtechnavi.api.estimation.IEstimateSelection|null|undefined} estimateSelection
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @instance
                 */
                SaveEstimateSelectionRequest.prototype.estimateSelection = null;

                /**
                 * SaveEstimateSelectionRequest estimateRequestUnitId.
                 * @member {string} estimateRequestUnitId
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @instance
                 */
                SaveEstimateSelectionRequest.prototype.estimateRequestUnitId = "";

                /**
                 * Verifies a SaveEstimateSelectionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveEstimateSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateSelection != null && message.hasOwnProperty("estimateSelection")) {
                        let error = $root.mtechnavi.api.estimation.EstimateSelection.verify(message.estimateSelection);
                        if (error)
                            return "estimateSelection." + error;
                    }
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        if (!$util.isString(message.estimateRequestUnitId))
                            return "estimateRequestUnitId: string expected";
                    return null;
                };

                /**
                 * Creates a SaveEstimateSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SaveEstimateSelectionRequest} SaveEstimateSelectionRequest
                 */
                SaveEstimateSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SaveEstimateSelectionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SaveEstimateSelectionRequest();
                    if (object.estimateSelection != null) {
                        if (typeof object.estimateSelection !== "object")
                            throw TypeError(".mtechnavi.api.estimation.SaveEstimateSelectionRequest.estimateSelection: object expected");
                        message.estimateSelection = $root.mtechnavi.api.estimation.EstimateSelection.fromObject(object.estimateSelection);
                    }
                    if (object.estimateRequestUnitId != null)
                        message.estimateRequestUnitId = String(object.estimateRequestUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from a SaveEstimateSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SaveEstimateSelectionRequest} message SaveEstimateSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveEstimateSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateSelection = null;
                        object.estimateRequestUnitId = "";
                    }
                    if (message.estimateSelection != null && message.hasOwnProperty("estimateSelection"))
                        object.estimateSelection = $root.mtechnavi.api.estimation.EstimateSelection.toObject(message.estimateSelection, options);
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        object.estimateRequestUnitId = message.estimateRequestUnitId;
                    return object;
                };

                /**
                 * Converts this SaveEstimateSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SaveEstimateSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveEstimateSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveEstimateSelectionRequest;
            })();

            estimation.ListEstimateSelectionsRequest = (function() {

                /**
                 * Properties of a ListEstimateSelectionsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateSelectionsRequest
                 * @property {string|null} [pageToken] ListEstimateSelectionsRequest pageToken
                 * @property {Array.<string>|null} [estimateRequestUnitIds] ListEstimateSelectionsRequest estimateRequestUnitIds
                 */

                /**
                 * Constructs a new ListEstimateSelectionsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateSelectionsRequest.
                 * @implements IListEstimateSelectionsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateSelectionsRequest=} [properties] Properties to set
                 */
                function ListEstimateSelectionsRequest(properties) {
                    this.estimateRequestUnitIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateSelectionsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @instance
                 */
                ListEstimateSelectionsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateSelectionsRequest estimateRequestUnitIds.
                 * @member {Array.<string>} estimateRequestUnitIds
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @instance
                 */
                ListEstimateSelectionsRequest.prototype.estimateRequestUnitIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateSelectionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateSelectionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateRequestUnitIds != null && message.hasOwnProperty("estimateRequestUnitIds")) {
                        if (!Array.isArray(message.estimateRequestUnitIds))
                            return "estimateRequestUnitIds: array expected";
                        for (let i = 0; i < message.estimateRequestUnitIds.length; ++i)
                            if (!$util.isString(message.estimateRequestUnitIds[i]))
                                return "estimateRequestUnitIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateSelectionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateSelectionsRequest} ListEstimateSelectionsRequest
                 */
                ListEstimateSelectionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateSelectionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateSelectionsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateRequestUnitIds) {
                        if (!Array.isArray(object.estimateRequestUnitIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateSelectionsRequest.estimateRequestUnitIds: array expected");
                        message.estimateRequestUnitIds = [];
                        for (let i = 0; i < object.estimateRequestUnitIds.length; ++i)
                            message.estimateRequestUnitIds[i] = String(object.estimateRequestUnitIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateSelectionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateSelectionsRequest} message ListEstimateSelectionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateSelectionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateRequestUnitIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateRequestUnitIds && message.estimateRequestUnitIds.length) {
                        object.estimateRequestUnitIds = [];
                        for (let j = 0; j < message.estimateRequestUnitIds.length; ++j)
                            object.estimateRequestUnitIds[j] = message.estimateRequestUnitIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateSelectionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateSelectionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateSelectionsRequest;
            })();

            estimation.ListEstimateSelectionsResponse = (function() {

                /**
                 * Properties of a ListEstimateSelectionsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateSelectionsResponse
                 * @property {Long|null} [total] ListEstimateSelectionsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateSelection>|null} [items] ListEstimateSelectionsResponse items
                 * @property {string|null} [pageToken] ListEstimateSelectionsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateSelectionsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateSelectionsResponse.
                 * @implements IListEstimateSelectionsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateSelectionsResponse=} [properties] Properties to set
                 */
                function ListEstimateSelectionsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateSelectionsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @instance
                 */
                ListEstimateSelectionsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateSelectionsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateSelection>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @instance
                 */
                ListEstimateSelectionsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateSelectionsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @instance
                 */
                ListEstimateSelectionsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateSelectionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateSelectionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateSelection.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateSelectionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateSelectionsResponse} ListEstimateSelectionsResponse
                 */
                ListEstimateSelectionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateSelectionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateSelectionsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateSelectionsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateSelectionsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateSelection.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateSelectionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateSelectionsResponse} message ListEstimateSelectionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateSelectionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateSelection.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateSelectionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateSelectionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateSelectionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateSelectionsResponse;
            })();

            estimation.ApprovalRequestEstimateSelectionRequest = (function() {

                /**
                 * Properties of an ApprovalRequestEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApprovalRequestEstimateSelectionRequest
                 * @property {string|null} [estimateSelectionId] ApprovalRequestEstimateSelectionRequest estimateSelectionId
                 * @property {sharelib.IUserReference|null} [approvalPlanStaff] ApprovalRequestEstimateSelectionRequest approvalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] ApprovalRequestEstimateSelectionRequest approvalPlanOrganization
                 * @property {string|null} [comment] ApprovalRequestEstimateSelectionRequest comment
                 */

                /**
                 * Constructs a new ApprovalRequestEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApprovalRequestEstimateSelectionRequest.
                 * @implements IApprovalRequestEstimateSelectionRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateSelectionRequest=} [properties] Properties to set
                 */
                function ApprovalRequestEstimateSelectionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApprovalRequestEstimateSelectionRequest estimateSelectionId.
                 * @member {string} estimateSelectionId
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @instance
                 */
                ApprovalRequestEstimateSelectionRequest.prototype.estimateSelectionId = "";

                /**
                 * ApprovalRequestEstimateSelectionRequest approvalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @instance
                 */
                ApprovalRequestEstimateSelectionRequest.prototype.approvalPlanStaff = null;

                /**
                 * ApprovalRequestEstimateSelectionRequest approvalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @instance
                 */
                ApprovalRequestEstimateSelectionRequest.prototype.approvalPlanOrganization = null;

                /**
                 * ApprovalRequestEstimateSelectionRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @instance
                 */
                ApprovalRequestEstimateSelectionRequest.prototype.comment = "";

                /**
                 * Verifies an ApprovalRequestEstimateSelectionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestEstimateSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        if (!$util.isString(message.estimateSelectionId))
                            return "estimateSelectionId: string expected";
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                        if (error)
                            return "approvalPlanStaff." + error;
                    }
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                        if (error)
                            return "approvalPlanOrganization." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestEstimateSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest} ApprovalRequestEstimateSelectionRequest
                 */
                ApprovalRequestEstimateSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest();
                    if (object.estimateSelectionId != null)
                        message.estimateSelectionId = String(object.estimateSelectionId);
                    if (object.approvalPlanStaff != null) {
                        if (typeof object.approvalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest.approvalPlanStaff: object expected");
                        message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
                    }
                    if (object.approvalPlanOrganization != null) {
                        if (typeof object.approvalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest.approvalPlanOrganization: object expected");
                        message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApprovalRequestEstimateSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest} message ApprovalRequestEstimateSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestEstimateSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateSelectionId = "";
                        object.comment = "";
                        object.approvalPlanStaff = null;
                        object.approvalPlanOrganization = null;
                    }
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        object.estimateSelectionId = message.estimateSelectionId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                        object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                        object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this ApprovalRequestEstimateSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestEstimateSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestEstimateSelectionRequest;
            })();

            estimation.ApprovalRequestEstimateSelectionResponse = (function() {

                /**
                 * Properties of an ApprovalRequestEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApprovalRequestEstimateSelectionResponse
                 */

                /**
                 * Constructs a new ApprovalRequestEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApprovalRequestEstimateSelectionResponse.
                 * @implements IApprovalRequestEstimateSelectionResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApprovalRequestEstimateSelectionResponse=} [properties] Properties to set
                 */
                function ApprovalRequestEstimateSelectionResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApprovalRequestEstimateSelectionResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestEstimateSelectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestEstimateSelectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse} ApprovalRequestEstimateSelectionResponse
                 */
                ApprovalRequestEstimateSelectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse();
                };

                /**
                 * Creates a plain object from an ApprovalRequestEstimateSelectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse} message ApprovalRequestEstimateSelectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestEstimateSelectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApprovalRequestEstimateSelectionResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApprovalRequestEstimateSelectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestEstimateSelectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestEstimateSelectionResponse;
            })();

            estimation.RejectEstimateSelectionRequest = (function() {

                /**
                 * Properties of a RejectEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IRejectEstimateSelectionRequest
                 * @property {string|null} [estimateSelectionId] RejectEstimateSelectionRequest estimateSelectionId
                 * @property {string|null} [comment] RejectEstimateSelectionRequest comment
                 */

                /**
                 * Constructs a new RejectEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a RejectEstimateSelectionRequest.
                 * @implements IRejectEstimateSelectionRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IRejectEstimateSelectionRequest=} [properties] Properties to set
                 */
                function RejectEstimateSelectionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RejectEstimateSelectionRequest estimateSelectionId.
                 * @member {string} estimateSelectionId
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @instance
                 */
                RejectEstimateSelectionRequest.prototype.estimateSelectionId = "";

                /**
                 * RejectEstimateSelectionRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @instance
                 */
                RejectEstimateSelectionRequest.prototype.comment = "";

                /**
                 * Verifies a RejectEstimateSelectionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectEstimateSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        if (!$util.isString(message.estimateSelectionId))
                            return "estimateSelectionId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a RejectEstimateSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.RejectEstimateSelectionRequest} RejectEstimateSelectionRequest
                 */
                RejectEstimateSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.RejectEstimateSelectionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.RejectEstimateSelectionRequest();
                    if (object.estimateSelectionId != null)
                        message.estimateSelectionId = String(object.estimateSelectionId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a RejectEstimateSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @static
                 * @param {mtechnavi.api.estimation.RejectEstimateSelectionRequest} message RejectEstimateSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectEstimateSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateSelectionId = "";
                        object.comment = "";
                    }
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        object.estimateSelectionId = message.estimateSelectionId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this RejectEstimateSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectEstimateSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectEstimateSelectionRequest;
            })();

            estimation.RejectEstimateSelectionResponse = (function() {

                /**
                 * Properties of a RejectEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IRejectEstimateSelectionResponse
                 */

                /**
                 * Constructs a new RejectEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a RejectEstimateSelectionResponse.
                 * @implements IRejectEstimateSelectionResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IRejectEstimateSelectionResponse=} [properties] Properties to set
                 */
                function RejectEstimateSelectionResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a RejectEstimateSelectionResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectEstimateSelectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a RejectEstimateSelectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.RejectEstimateSelectionResponse} RejectEstimateSelectionResponse
                 */
                RejectEstimateSelectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.RejectEstimateSelectionResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.RejectEstimateSelectionResponse();
                };

                /**
                 * Creates a plain object from a RejectEstimateSelectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionResponse
                 * @static
                 * @param {mtechnavi.api.estimation.RejectEstimateSelectionResponse} message RejectEstimateSelectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectEstimateSelectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this RejectEstimateSelectionResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.RejectEstimateSelectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectEstimateSelectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectEstimateSelectionResponse;
            })();

            estimation.ApproveEstimateSelectionRequest = (function() {

                /**
                 * Properties of an ApproveEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApproveEstimateSelectionRequest
                 * @property {string|null} [estimateSelectionId] ApproveEstimateSelectionRequest estimateSelectionId
                 * @property {string|null} [comment] ApproveEstimateSelectionRequest comment
                 */

                /**
                 * Constructs a new ApproveEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApproveEstimateSelectionRequest.
                 * @implements IApproveEstimateSelectionRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApproveEstimateSelectionRequest=} [properties] Properties to set
                 */
                function ApproveEstimateSelectionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApproveEstimateSelectionRequest estimateSelectionId.
                 * @member {string} estimateSelectionId
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @instance
                 */
                ApproveEstimateSelectionRequest.prototype.estimateSelectionId = "";

                /**
                 * ApproveEstimateSelectionRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @instance
                 */
                ApproveEstimateSelectionRequest.prototype.comment = "";

                /**
                 * Verifies an ApproveEstimateSelectionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveEstimateSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        if (!$util.isString(message.estimateSelectionId))
                            return "estimateSelectionId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApproveEstimateSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApproveEstimateSelectionRequest} ApproveEstimateSelectionRequest
                 */
                ApproveEstimateSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApproveEstimateSelectionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ApproveEstimateSelectionRequest();
                    if (object.estimateSelectionId != null)
                        message.estimateSelectionId = String(object.estimateSelectionId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApproveEstimateSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ApproveEstimateSelectionRequest} message ApproveEstimateSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveEstimateSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateSelectionId = "";
                        object.comment = "";
                    }
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        object.estimateSelectionId = message.estimateSelectionId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this ApproveEstimateSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveEstimateSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveEstimateSelectionRequest;
            })();

            estimation.ApproveEstimateSelectionResponse = (function() {

                /**
                 * Properties of an ApproveEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IApproveEstimateSelectionResponse
                 */

                /**
                 * Constructs a new ApproveEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ApproveEstimateSelectionResponse.
                 * @implements IApproveEstimateSelectionResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IApproveEstimateSelectionResponse=} [properties] Properties to set
                 */
                function ApproveEstimateSelectionResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApproveEstimateSelectionResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveEstimateSelectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApproveEstimateSelectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ApproveEstimateSelectionResponse} ApproveEstimateSelectionResponse
                 */
                ApproveEstimateSelectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ApproveEstimateSelectionResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.ApproveEstimateSelectionResponse();
                };

                /**
                 * Creates a plain object from an ApproveEstimateSelectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ApproveEstimateSelectionResponse} message ApproveEstimateSelectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveEstimateSelectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApproveEstimateSelectionResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ApproveEstimateSelectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveEstimateSelectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveEstimateSelectionResponse;
            })();

            estimation.SharetoEstimateSelectionRequest = (function() {

                /**
                 * Properties of a SharetoEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateSelectionRequest
                 * @property {string|null} [estimateRequestUnitId] SharetoEstimateSelectionRequest estimateRequestUnitId
                 */

                /**
                 * Constructs a new SharetoEstimateSelectionRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateSelectionRequest.
                 * @implements ISharetoEstimateSelectionRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateSelectionRequest=} [properties] Properties to set
                 */
                function SharetoEstimateSelectionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoEstimateSelectionRequest estimateRequestUnitId.
                 * @member {string} estimateRequestUnitId
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionRequest
                 * @instance
                 */
                SharetoEstimateSelectionRequest.prototype.estimateRequestUnitId = "";

                /**
                 * Verifies a SharetoEstimateSelectionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        if (!$util.isString(message.estimateRequestUnitId))
                            return "estimateRequestUnitId: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateSelectionRequest} SharetoEstimateSelectionRequest
                 */
                SharetoEstimateSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateSelectionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoEstimateSelectionRequest();
                    if (object.estimateRequestUnitId != null)
                        message.estimateRequestUnitId = String(object.estimateRequestUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoEstimateSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateSelectionRequest} message SharetoEstimateSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateRequestUnitId = "";
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        object.estimateRequestUnitId = message.estimateRequestUnitId;
                    return object;
                };

                /**
                 * Converts this SharetoEstimateSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateSelectionRequest;
            })();

            estimation.SharetoEstimateSelectionResponse = (function() {

                /**
                 * Properties of a SharetoEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateSelectionResponse
                 */

                /**
                 * Constructs a new SharetoEstimateSelectionResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateSelectionResponse.
                 * @implements ISharetoEstimateSelectionResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateSelectionResponse=} [properties] Properties to set
                 */
                function SharetoEstimateSelectionResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoEstimateSelectionResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateSelectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateSelectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateSelectionResponse} SharetoEstimateSelectionResponse
                 */
                SharetoEstimateSelectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateSelectionResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoEstimateSelectionResponse();
                };

                /**
                 * Creates a plain object from a SharetoEstimateSelectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateSelectionResponse} message SharetoEstimateSelectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateSelectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoEstimateSelectionResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateSelectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateSelectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateSelectionResponse;
            })();

            estimation.ListOrderFormatsRequest = (function() {

                /**
                 * Properties of a ListOrderFormatsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListOrderFormatsRequest
                 */

                /**
                 * Constructs a new ListOrderFormatsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListOrderFormatsRequest.
                 * @implements IListOrderFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListOrderFormatsRequest=} [properties] Properties to set
                 */
                function ListOrderFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListOrderFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrderFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListOrderFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListOrderFormatsRequest} ListOrderFormatsRequest
                 */
                ListOrderFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListOrderFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.estimation.ListOrderFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListOrderFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListOrderFormatsRequest} message ListOrderFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrderFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListOrderFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrderFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrderFormatsRequest;
            })();

            estimation.ListOrderFormatsResponse = (function() {

                /**
                 * Properties of a ListOrderFormatsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListOrderFormatsResponse
                 * @property {Long|null} [total] ListOrderFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListOrderFormatsResponse items
                 */

                /**
                 * Constructs a new ListOrderFormatsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListOrderFormatsResponse.
                 * @implements IListOrderFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListOrderFormatsResponse=} [properties] Properties to set
                 */
                function ListOrderFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListOrderFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @instance
                 */
                ListOrderFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListOrderFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @instance
                 */
                ListOrderFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListOrderFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListOrderFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListOrderFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListOrderFormatsResponse} ListOrderFormatsResponse
                 */
                ListOrderFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListOrderFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListOrderFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListOrderFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListOrderFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListOrderFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListOrderFormatsResponse} message ListOrderFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListOrderFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListOrderFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListOrderFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListOrderFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListOrderFormatsResponse;
            })();

            estimation.ExportOrdersRequest = (function() {

                /**
                 * Properties of an ExportOrdersRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IExportOrdersRequest
                 * @property {Array.<string>|null} [ids] ExportOrdersRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportOrdersRequest fileFormat
                 */

                /**
                 * Constructs a new ExportOrdersRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ExportOrdersRequest.
                 * @implements IExportOrdersRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IExportOrdersRequest=} [properties] Properties to set
                 */
                function ExportOrdersRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOrdersRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @instance
                 */
                ExportOrdersRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportOrdersRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @instance
                 */
                ExportOrdersRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportOrdersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOrdersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportOrdersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ExportOrdersRequest} ExportOrdersRequest
                 */
                ExportOrdersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ExportOrdersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ExportOrdersRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.estimation.ExportOrdersRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ExportOrdersRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOrdersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ExportOrdersRequest} message ExportOrdersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOrdersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportOrdersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ExportOrdersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOrdersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOrdersRequest;
            })();

            estimation.ExportOrdersResponse = (function() {

                /**
                 * Properties of an ExportOrdersResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IExportOrdersResponse
                 * @property {string|null} [assetId] ExportOrdersResponse assetId
                 */

                /**
                 * Constructs a new ExportOrdersResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ExportOrdersResponse.
                 * @implements IExportOrdersResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IExportOrdersResponse=} [properties] Properties to set
                 */
                function ExportOrdersResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportOrdersResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.estimation.ExportOrdersResponse
                 * @instance
                 */
                ExportOrdersResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportOrdersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ExportOrdersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportOrdersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportOrdersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ExportOrdersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ExportOrdersResponse} ExportOrdersResponse
                 */
                ExportOrdersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ExportOrdersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ExportOrdersResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportOrdersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ExportOrdersResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ExportOrdersResponse} message ExportOrdersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportOrdersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportOrdersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ExportOrdersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportOrdersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportOrdersResponse;
            })();

            estimation.CreateEstimateResultChangeRequestRequest = (function() {

                /**
                 * Properties of a CreateEstimateResultChangeRequestRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICreateEstimateResultChangeRequestRequest
                 * @property {mtechnavi.api.estimation.IEstimateResultChangeRequest|null} [estimateResultChangeRequest] CreateEstimateResultChangeRequestRequest estimateResultChangeRequest
                 */

                /**
                 * Constructs a new CreateEstimateResultChangeRequestRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CreateEstimateResultChangeRequestRequest.
                 * @implements ICreateEstimateResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICreateEstimateResultChangeRequestRequest=} [properties] Properties to set
                 */
                function CreateEstimateResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateEstimateResultChangeRequestRequest estimateResultChangeRequest.
                 * @member {mtechnavi.api.estimation.IEstimateResultChangeRequest|null|undefined} estimateResultChangeRequest
                 * @memberof mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest
                 * @instance
                 */
                CreateEstimateResultChangeRequestRequest.prototype.estimateResultChangeRequest = null;

                /**
                 * Verifies a CreateEstimateResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateEstimateResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultChangeRequest != null && message.hasOwnProperty("estimateResultChangeRequest")) {
                        let error = $root.mtechnavi.api.estimation.EstimateResultChangeRequest.verify(message.estimateResultChangeRequest);
                        if (error)
                            return "estimateResultChangeRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateEstimateResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest} CreateEstimateResultChangeRequestRequest
                 */
                CreateEstimateResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest();
                    if (object.estimateResultChangeRequest != null) {
                        if (typeof object.estimateResultChangeRequest !== "object")
                            throw TypeError(".mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest.estimateResultChangeRequest: object expected");
                        message.estimateResultChangeRequest = $root.mtechnavi.api.estimation.EstimateResultChangeRequest.fromObject(object.estimateResultChangeRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateEstimateResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest} message CreateEstimateResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateEstimateResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateResultChangeRequest = null;
                    if (message.estimateResultChangeRequest != null && message.hasOwnProperty("estimateResultChangeRequest"))
                        object.estimateResultChangeRequest = $root.mtechnavi.api.estimation.EstimateResultChangeRequest.toObject(message.estimateResultChangeRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateEstimateResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CreateEstimateResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateEstimateResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateEstimateResultChangeRequestRequest;
            })();

            estimation.SharetoEstimateResultChangeRequestRequest = (function() {

                /**
                 * Properties of a SharetoEstimateResultChangeRequestRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateResultChangeRequestRequest
                 * @property {string|null} [estimateResultChangeRequestId] SharetoEstimateResultChangeRequestRequest estimateResultChangeRequestId
                 */

                /**
                 * Constructs a new SharetoEstimateResultChangeRequestRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateResultChangeRequestRequest.
                 * @implements ISharetoEstimateResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultChangeRequestRequest=} [properties] Properties to set
                 */
                function SharetoEstimateResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoEstimateResultChangeRequestRequest estimateResultChangeRequestId.
                 * @member {string} estimateResultChangeRequestId
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest
                 * @instance
                 */
                SharetoEstimateResultChangeRequestRequest.prototype.estimateResultChangeRequestId = "";

                /**
                 * Verifies a SharetoEstimateResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultChangeRequestId != null && message.hasOwnProperty("estimateResultChangeRequestId"))
                        if (!$util.isString(message.estimateResultChangeRequestId))
                            return "estimateResultChangeRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest} SharetoEstimateResultChangeRequestRequest
                 */
                SharetoEstimateResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest();
                    if (object.estimateResultChangeRequestId != null)
                        message.estimateResultChangeRequestId = String(object.estimateResultChangeRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoEstimateResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest} message SharetoEstimateResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateResultChangeRequestId = "";
                    if (message.estimateResultChangeRequestId != null && message.hasOwnProperty("estimateResultChangeRequestId"))
                        object.estimateResultChangeRequestId = message.estimateResultChangeRequestId;
                    return object;
                };

                /**
                 * Converts this SharetoEstimateResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateResultChangeRequestRequest;
            })();

            estimation.SharetoEstimateResultChangeRequestResponse = (function() {

                /**
                 * Properties of a SharetoEstimateResultChangeRequestResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateResultChangeRequestResponse
                 */

                /**
                 * Constructs a new SharetoEstimateResultChangeRequestResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateResultChangeRequestResponse.
                 * @implements ISharetoEstimateResultChangeRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultChangeRequestResponse=} [properties] Properties to set
                 */
                function SharetoEstimateResultChangeRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoEstimateResultChangeRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateResultChangeRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateResultChangeRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse} SharetoEstimateResultChangeRequestResponse
                 */
                SharetoEstimateResultChangeRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse();
                };

                /**
                 * Creates a plain object from a SharetoEstimateResultChangeRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse} message SharetoEstimateResultChangeRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateResultChangeRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoEstimateResultChangeRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultChangeRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateResultChangeRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateResultChangeRequestResponse;
            })();

            estimation.SharedListEstimateResultsRequest = (function() {

                /**
                 * Properties of a SharedListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateResultsRequest
                 * @property {string|null} [pageToken] SharedListEstimateResultsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] SharedListEstimateResultsRequest estimateManagementIds
                 * @property {Array.<string>|null} [estimateRequestUnitIds] SharedListEstimateResultsRequest estimateRequestUnitIds
                 */

                /**
                 * Constructs a new SharedListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateResultsRequest.
                 * @implements ISharedListEstimateResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultsRequest=} [properties] Properties to set
                 */
                function SharedListEstimateResultsRequest(properties) {
                    this.estimateManagementIds = [];
                    this.estimateRequestUnitIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @instance
                 */
                SharedListEstimateResultsRequest.prototype.pageToken = "";

                /**
                 * SharedListEstimateResultsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @instance
                 */
                SharedListEstimateResultsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * SharedListEstimateResultsRequest estimateRequestUnitIds.
                 * @member {Array.<string>} estimateRequestUnitIds
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @instance
                 */
                SharedListEstimateResultsRequest.prototype.estimateRequestUnitIds = $util.emptyArray;

                /**
                 * Verifies a SharedListEstimateResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    if (message.estimateRequestUnitIds != null && message.hasOwnProperty("estimateRequestUnitIds")) {
                        if (!Array.isArray(message.estimateRequestUnitIds))
                            return "estimateRequestUnitIds: array expected";
                        for (let i = 0; i < message.estimateRequestUnitIds.length; ++i)
                            if (!$util.isString(message.estimateRequestUnitIds[i]))
                                return "estimateRequestUnitIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListEstimateResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateResultsRequest} SharedListEstimateResultsRequest
                 */
                SharedListEstimateResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    if (object.estimateRequestUnitIds) {
                        if (!Array.isArray(object.estimateRequestUnitIds))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultsRequest.estimateRequestUnitIds: array expected");
                        message.estimateRequestUnitIds = [];
                        for (let i = 0; i < object.estimateRequestUnitIds.length; ++i)
                            message.estimateRequestUnitIds[i] = String(object.estimateRequestUnitIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultsRequest} message SharedListEstimateResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.estimateManagementIds = [];
                        object.estimateRequestUnitIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    if (message.estimateRequestUnitIds && message.estimateRequestUnitIds.length) {
                        object.estimateRequestUnitIds = [];
                        for (let j = 0; j < message.estimateRequestUnitIds.length; ++j)
                            object.estimateRequestUnitIds[j] = message.estimateRequestUnitIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListEstimateResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateResultsRequest;
            })();

            estimation.SharedListEstimateResultsResponse = (function() {

                /**
                 * Properties of a SharedListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateResultsResponse
                 * @property {Long|null} [total] SharedListEstimateResultsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResult>|null} [items] SharedListEstimateResultsResponse items
                 * @property {string|null} [pageToken] SharedListEstimateResultsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateResultsResponse.
                 * @implements ISharedListEstimateResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultsResponse=} [properties] Properties to set
                 */
                function SharedListEstimateResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @instance
                 */
                SharedListEstimateResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListEstimateResultsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResult>} items
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @instance
                 */
                SharedListEstimateResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListEstimateResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @instance
                 */
                SharedListEstimateResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListEstimateResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListEstimateResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateResultsResponse} SharedListEstimateResultsResponse
                 */
                SharedListEstimateResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultsResponse} message SharedListEstimateResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListEstimateResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateResultsResponse;
            })();

            estimation.SharedListEstimateResultDetailsRequest = (function() {

                /**
                 * Properties of a SharedListEstimateResultDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateResultDetailsRequest
                 * @property {string|null} [pageToken] SharedListEstimateResultDetailsRequest pageToken
                 * @property {Array.<string>|null} [estimateManagementIds] SharedListEstimateResultDetailsRequest estimateManagementIds
                 * @property {Array.<string>|null} [estimateRequestUnitIds] SharedListEstimateResultDetailsRequest estimateRequestUnitIds
                 */

                /**
                 * Constructs a new SharedListEstimateResultDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateResultDetailsRequest.
                 * @implements ISharedListEstimateResultDetailsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultDetailsRequest=} [properties] Properties to set
                 */
                function SharedListEstimateResultDetailsRequest(properties) {
                    this.estimateManagementIds = [];
                    this.estimateRequestUnitIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateResultDetailsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @instance
                 */
                SharedListEstimateResultDetailsRequest.prototype.pageToken = "";

                /**
                 * SharedListEstimateResultDetailsRequest estimateManagementIds.
                 * @member {Array.<string>} estimateManagementIds
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @instance
                 */
                SharedListEstimateResultDetailsRequest.prototype.estimateManagementIds = $util.emptyArray;

                /**
                 * SharedListEstimateResultDetailsRequest estimateRequestUnitIds.
                 * @member {Array.<string>} estimateRequestUnitIds
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @instance
                 */
                SharedListEstimateResultDetailsRequest.prototype.estimateRequestUnitIds = $util.emptyArray;

                /**
                 * Verifies a SharedListEstimateResultDetailsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateResultDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateManagementIds != null && message.hasOwnProperty("estimateManagementIds")) {
                        if (!Array.isArray(message.estimateManagementIds))
                            return "estimateManagementIds: array expected";
                        for (let i = 0; i < message.estimateManagementIds.length; ++i)
                            if (!$util.isString(message.estimateManagementIds[i]))
                                return "estimateManagementIds: string[] expected";
                    }
                    if (message.estimateRequestUnitIds != null && message.hasOwnProperty("estimateRequestUnitIds")) {
                        if (!Array.isArray(message.estimateRequestUnitIds))
                            return "estimateRequestUnitIds: array expected";
                        for (let i = 0; i < message.estimateRequestUnitIds.length; ++i)
                            if (!$util.isString(message.estimateRequestUnitIds[i]))
                                return "estimateRequestUnitIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListEstimateResultDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest} SharedListEstimateResultDetailsRequest
                 */
                SharedListEstimateResultDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateManagementIds) {
                        if (!Array.isArray(object.estimateManagementIds))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest.estimateManagementIds: array expected");
                        message.estimateManagementIds = [];
                        for (let i = 0; i < object.estimateManagementIds.length; ++i)
                            message.estimateManagementIds[i] = String(object.estimateManagementIds[i]);
                    }
                    if (object.estimateRequestUnitIds) {
                        if (!Array.isArray(object.estimateRequestUnitIds))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest.estimateRequestUnitIds: array expected");
                        message.estimateRequestUnitIds = [];
                        for (let i = 0; i < object.estimateRequestUnitIds.length; ++i)
                            message.estimateRequestUnitIds[i] = String(object.estimateRequestUnitIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateResultDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest} message SharedListEstimateResultDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateResultDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.estimateManagementIds = [];
                        object.estimateRequestUnitIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateManagementIds && message.estimateManagementIds.length) {
                        object.estimateManagementIds = [];
                        for (let j = 0; j < message.estimateManagementIds.length; ++j)
                            object.estimateManagementIds[j] = message.estimateManagementIds[j];
                    }
                    if (message.estimateRequestUnitIds && message.estimateRequestUnitIds.length) {
                        object.estimateRequestUnitIds = [];
                        for (let j = 0; j < message.estimateRequestUnitIds.length; ++j)
                            object.estimateRequestUnitIds[j] = message.estimateRequestUnitIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListEstimateResultDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateResultDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateResultDetailsRequest;
            })();

            estimation.SharedListEstimateResultDetailsResponse = (function() {

                /**
                 * Properties of a SharedListEstimateResultDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateResultDetailsResponse
                 * @property {Long|null} [total] SharedListEstimateResultDetailsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResultDetail>|null} [items] SharedListEstimateResultDetailsResponse items
                 * @property {string|null} [pageToken] SharedListEstimateResultDetailsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListEstimateResultDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateResultDetailsResponse.
                 * @implements ISharedListEstimateResultDetailsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateResultDetailsResponse=} [properties] Properties to set
                 */
                function SharedListEstimateResultDetailsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateResultDetailsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @instance
                 */
                SharedListEstimateResultDetailsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListEstimateResultDetailsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResultDetail>} items
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @instance
                 */
                SharedListEstimateResultDetailsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListEstimateResultDetailsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @instance
                 */
                SharedListEstimateResultDetailsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListEstimateResultDetailsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateResultDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResultDetail.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListEstimateResultDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse} SharedListEstimateResultDetailsResponse
                 */
                SharedListEstimateResultDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResultDetail.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateResultDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse} message SharedListEstimateResultDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateResultDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResultDetail.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListEstimateResultDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateResultDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateResultDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateResultDetailsResponse;
            })();

            estimation.ListEstimateResultsRequest = (function() {

                /**
                 * Properties of a ListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultsRequest
                 * @property {string|null} [pageToken] ListEstimateResultsRequest pageToken
                 * @property {Array.<string>|null} [estimateResultIds] ListEstimateResultsRequest estimateResultIds
                 */

                /**
                 * Constructs a new ListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultsRequest.
                 * @implements IListEstimateResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultsRequest=} [properties] Properties to set
                 */
                function ListEstimateResultsRequest(properties) {
                    this.estimateResultIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @instance
                 */
                ListEstimateResultsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateResultsRequest estimateResultIds.
                 * @member {Array.<string>} estimateResultIds
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @instance
                 */
                ListEstimateResultsRequest.prototype.estimateResultIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateResultIds != null && message.hasOwnProperty("estimateResultIds")) {
                        if (!Array.isArray(message.estimateResultIds))
                            return "estimateResultIds: array expected";
                        for (let i = 0; i < message.estimateResultIds.length; ++i)
                            if (!$util.isString(message.estimateResultIds[i]))
                                return "estimateResultIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultsRequest} ListEstimateResultsRequest
                 */
                ListEstimateResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateResultIds) {
                        if (!Array.isArray(object.estimateResultIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultsRequest.estimateResultIds: array expected");
                        message.estimateResultIds = [];
                        for (let i = 0; i < object.estimateResultIds.length; ++i)
                            message.estimateResultIds[i] = String(object.estimateResultIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultsRequest} message ListEstimateResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateResultIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateResultIds && message.estimateResultIds.length) {
                        object.estimateResultIds = [];
                        for (let j = 0; j < message.estimateResultIds.length; ++j)
                            object.estimateResultIds[j] = message.estimateResultIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateResultsRequest;
            })();

            estimation.ListEstimateResultsResponse = (function() {

                /**
                 * Properties of a ListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultsResponse
                 * @property {Long|null} [total] ListEstimateResultsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResult>|null} [items] ListEstimateResultsResponse items
                 * @property {string|null} [pageToken] ListEstimateResultsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultsResponse.
                 * @implements IListEstimateResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultsResponse=} [properties] Properties to set
                 */
                function ListEstimateResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @instance
                 */
                ListEstimateResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateResultsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResult>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @instance
                 */
                ListEstimateResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @instance
                 */
                ListEstimateResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultsResponse} ListEstimateResultsResponse
                 */
                ListEstimateResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultsResponse} message ListEstimateResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateResultsResponse;
            })();

            estimation.UpdateEstimateResultRequest = (function() {

                /**
                 * Properties of an UpdateEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateEstimateResultRequest
                 * @property {mtechnavi.api.estimation.IEstimateResult|null} [estimateResult] UpdateEstimateResultRequest estimateResult
                 */

                /**
                 * Constructs a new UpdateEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateEstimateResultRequest.
                 * @implements IUpdateEstimateResultRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultRequest=} [properties] Properties to set
                 */
                function UpdateEstimateResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateEstimateResultRequest estimateResult.
                 * @member {mtechnavi.api.estimation.IEstimateResult|null|undefined} estimateResult
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultRequest
                 * @instance
                 */
                UpdateEstimateResultRequest.prototype.estimateResult = null;

                /**
                 * Verifies an UpdateEstimateResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateEstimateResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResult != null && message.hasOwnProperty("estimateResult")) {
                        let error = $root.mtechnavi.api.estimation.EstimateResult.verify(message.estimateResult);
                        if (error)
                            return "estimateResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateEstimateResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateEstimateResultRequest} UpdateEstimateResultRequest
                 */
                UpdateEstimateResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateEstimateResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateEstimateResultRequest();
                    if (object.estimateResult != null) {
                        if (typeof object.estimateResult !== "object")
                            throw TypeError(".mtechnavi.api.estimation.UpdateEstimateResultRequest.estimateResult: object expected");
                        message.estimateResult = $root.mtechnavi.api.estimation.EstimateResult.fromObject(object.estimateResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateEstimateResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateEstimateResultRequest} message UpdateEstimateResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateEstimateResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateResult = null;
                    if (message.estimateResult != null && message.hasOwnProperty("estimateResult"))
                        object.estimateResult = $root.mtechnavi.api.estimation.EstimateResult.toObject(message.estimateResult, options);
                    return object;
                };

                /**
                 * Converts this UpdateEstimateResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateEstimateResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateEstimateResultRequest;
            })();

            estimation.CompleteEstimateResultRequest = (function() {

                /**
                 * Properties of a CompleteEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateResultRequest
                 * @property {string|null} [estimateResultId] CompleteEstimateResultRequest estimateResultId
                 */

                /**
                 * Constructs a new CompleteEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateResultRequest.
                 * @implements ICompleteEstimateResultRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateResultRequest=} [properties] Properties to set
                 */
                function CompleteEstimateResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateResultRequest estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultRequest
                 * @instance
                 */
                CompleteEstimateResultRequest.prototype.estimateResultId = "";

                /**
                 * Verifies a CompleteEstimateResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteEstimateResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateResultRequest} CompleteEstimateResultRequest
                 */
                CompleteEstimateResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateResultRequest();
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultRequest
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateResultRequest} message CompleteEstimateResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateResultId = "";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    return object;
                };

                /**
                 * Converts this CompleteEstimateResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateResultRequest;
            })();

            estimation.CompleteEstimateResultResponse = (function() {

                /**
                 * Properties of a CompleteEstimateResultResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ICompleteEstimateResultResponse
                 * @property {mtechnavi.api.estimation.IComplete|null} [complete] CompleteEstimateResultResponse complete
                 */

                /**
                 * Constructs a new CompleteEstimateResultResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a CompleteEstimateResultResponse.
                 * @implements ICompleteEstimateResultResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ICompleteEstimateResultResponse=} [properties] Properties to set
                 */
                function CompleteEstimateResultResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEstimateResultResponse complete.
                 * @member {mtechnavi.api.estimation.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultResponse
                 * @instance
                 */
                CompleteEstimateResultResponse.prototype.complete = null;

                /**
                 * Verifies a CompleteEstimateResultResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEstimateResultResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.estimation.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteEstimateResultResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.CompleteEstimateResultResponse} CompleteEstimateResultResponse
                 */
                CompleteEstimateResultResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.CompleteEstimateResultResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.CompleteEstimateResultResponse();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.estimation.CompleteEstimateResultResponse.complete: object expected");
                        message.complete = $root.mtechnavi.api.estimation.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEstimateResultResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultResponse
                 * @static
                 * @param {mtechnavi.api.estimation.CompleteEstimateResultResponse} message CompleteEstimateResultResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEstimateResultResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.estimation.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this CompleteEstimateResultResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.CompleteEstimateResultResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEstimateResultResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEstimateResultResponse;
            })();

            estimation.UpdateSystemNotificationUsersInEstimateResultRequest = (function() {

                /**
                 * Properties of an UpdateSystemNotificationUsersInEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateSystemNotificationUsersInEstimateResultRequest
                 * @property {string|null} [estimateResultId] UpdateSystemNotificationUsersInEstimateResultRequest estimateResultId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] UpdateSystemNotificationUsersInEstimateResultRequest systemNotificationUsers
                 * @property {Long|null} [updatedAt] UpdateSystemNotificationUsersInEstimateResultRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateSystemNotificationUsersInEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateSystemNotificationUsersInEstimateResultRequest.
                 * @implements IUpdateSystemNotificationUsersInEstimateResultRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateSystemNotificationUsersInEstimateResultRequest=} [properties] Properties to set
                 */
                function UpdateSystemNotificationUsersInEstimateResultRequest(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSystemNotificationUsersInEstimateResultRequest estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.prototype.estimateResultId = "";

                /**
                 * UpdateSystemNotificationUsersInEstimateResultRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * UpdateSystemNotificationUsersInEstimateResultRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateSystemNotificationUsersInEstimateResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateSystemNotificationUsersInEstimateResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest} UpdateSystemNotificationUsersInEstimateResultRequest
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest();
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSystemNotificationUsersInEstimateResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest} message UpdateSystemNotificationUsersInEstimateResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.estimateResultId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateSystemNotificationUsersInEstimateResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateSystemNotificationUsersInEstimateResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSystemNotificationUsersInEstimateResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSystemNotificationUsersInEstimateResultRequest;
            })();

            estimation.UpdateRemarksInEstimateResultRequest = (function() {

                /**
                 * Properties of an UpdateRemarksInEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateRemarksInEstimateResultRequest
                 * @property {string|null} [estimateResultId] UpdateRemarksInEstimateResultRequest estimateResultId
                 * @property {string|null} [remarks] UpdateRemarksInEstimateResultRequest remarks
                 * @property {Long|null} [updatedAt] UpdateRemarksInEstimateResultRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateRemarksInEstimateResultRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateRemarksInEstimateResultRequest.
                 * @implements IUpdateRemarksInEstimateResultRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateRemarksInEstimateResultRequest=} [properties] Properties to set
                 */
                function UpdateRemarksInEstimateResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateRemarksInEstimateResultRequest estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @instance
                 */
                UpdateRemarksInEstimateResultRequest.prototype.estimateResultId = "";

                /**
                 * UpdateRemarksInEstimateResultRequest remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @instance
                 */
                UpdateRemarksInEstimateResultRequest.prototype.remarks = "";

                /**
                 * UpdateRemarksInEstimateResultRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @instance
                 */
                UpdateRemarksInEstimateResultRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateRemarksInEstimateResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateRemarksInEstimateResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateRemarksInEstimateResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest} UpdateRemarksInEstimateResultRequest
                 */
                UpdateRemarksInEstimateResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest();
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateRemarksInEstimateResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest} message UpdateRemarksInEstimateResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateRemarksInEstimateResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateResultId = "";
                        object.remarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateRemarksInEstimateResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateRemarksInEstimateResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateRemarksInEstimateResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateRemarksInEstimateResultRequest;
            })();

            estimation.SharetoEstimateResultWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a SharetoEstimateResultWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateResultWithRelatedItemsRequest
                 * @property {string|null} [estimateResultId] SharetoEstimateResultWithRelatedItemsRequest estimateResultId
                 * @property {boolean|null} [declined] SharetoEstimateResultWithRelatedItemsRequest declined
                 * @property {string|null} [comment] SharetoEstimateResultWithRelatedItemsRequest comment
                 */

                /**
                 * Constructs a new SharetoEstimateResultWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateResultWithRelatedItemsRequest.
                 * @implements ISharetoEstimateResultWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function SharetoEstimateResultWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoEstimateResultWithRelatedItemsRequest estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @instance
                 */
                SharetoEstimateResultWithRelatedItemsRequest.prototype.estimateResultId = "";

                /**
                 * SharetoEstimateResultWithRelatedItemsRequest declined.
                 * @member {boolean} declined
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @instance
                 */
                SharetoEstimateResultWithRelatedItemsRequest.prototype.declined = false;

                /**
                 * SharetoEstimateResultWithRelatedItemsRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @instance
                 */
                SharetoEstimateResultWithRelatedItemsRequest.prototype.comment = "";

                /**
                 * Verifies a SharetoEstimateResultWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateResultWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        if (typeof message.declined !== "boolean")
                            return "declined: boolean expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateResultWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest} SharetoEstimateResultWithRelatedItemsRequest
                 */
                SharetoEstimateResultWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest();
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.declined != null)
                        message.declined = Boolean(object.declined);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoEstimateResultWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest} message SharetoEstimateResultWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateResultWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateResultId = "";
                        object.declined = false;
                        object.comment = "";
                    }
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        object.declined = message.declined;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this SharetoEstimateResultWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateResultWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateResultWithRelatedItemsRequest;
            })();

            estimation.SharetoEstimateResultWithRelatedItemsResponse = (function() {

                /**
                 * Properties of a SharetoEstimateResultWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharetoEstimateResultWithRelatedItemsResponse
                 */

                /**
                 * Constructs a new SharetoEstimateResultWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharetoEstimateResultWithRelatedItemsResponse.
                 * @implements ISharetoEstimateResultWithRelatedItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharetoEstimateResultWithRelatedItemsResponse=} [properties] Properties to set
                 */
                function SharetoEstimateResultWithRelatedItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoEstimateResultWithRelatedItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoEstimateResultWithRelatedItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoEstimateResultWithRelatedItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse} SharetoEstimateResultWithRelatedItemsResponse
                 */
                SharetoEstimateResultWithRelatedItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse)
                        return object;
                    return new $root.mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse();
                };

                /**
                 * Creates a plain object from a SharetoEstimateResultWithRelatedItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse} message SharetoEstimateResultWithRelatedItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoEstimateResultWithRelatedItemsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoEstimateResultWithRelatedItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharetoEstimateResultWithRelatedItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoEstimateResultWithRelatedItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoEstimateResultWithRelatedItemsResponse;
            })();

            estimation.ListEstimateResultDetailsRequest = (function() {

                /**
                 * Properties of a ListEstimateResultDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultDetailsRequest
                 * @property {string|null} [pageToken] ListEstimateResultDetailsRequest pageToken
                 * @property {Array.<string>|null} [estimateResultIds] ListEstimateResultDetailsRequest estimateResultIds
                 */

                /**
                 * Constructs a new ListEstimateResultDetailsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultDetailsRequest.
                 * @implements IListEstimateResultDetailsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultDetailsRequest=} [properties] Properties to set
                 */
                function ListEstimateResultDetailsRequest(properties) {
                    this.estimateResultIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultDetailsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @instance
                 */
                ListEstimateResultDetailsRequest.prototype.pageToken = "";

                /**
                 * ListEstimateResultDetailsRequest estimateResultIds.
                 * @member {Array.<string>} estimateResultIds
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @instance
                 */
                ListEstimateResultDetailsRequest.prototype.estimateResultIds = $util.emptyArray;

                /**
                 * Verifies a ListEstimateResultDetailsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateResultIds != null && message.hasOwnProperty("estimateResultIds")) {
                        if (!Array.isArray(message.estimateResultIds))
                            return "estimateResultIds: array expected";
                        for (let i = 0; i < message.estimateResultIds.length; ++i)
                            if (!$util.isString(message.estimateResultIds[i]))
                                return "estimateResultIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateResultDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultDetailsRequest} ListEstimateResultDetailsRequest
                 */
                ListEstimateResultDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultDetailsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultDetailsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateResultIds) {
                        if (!Array.isArray(object.estimateResultIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultDetailsRequest.estimateResultIds: array expected");
                        message.estimateResultIds = [];
                        for (let i = 0; i < object.estimateResultIds.length; ++i)
                            message.estimateResultIds[i] = String(object.estimateResultIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultDetailsRequest} message ListEstimateResultDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateResultIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateResultIds && message.estimateResultIds.length) {
                        object.estimateResultIds = [];
                        for (let j = 0; j < message.estimateResultIds.length; ++j)
                            object.estimateResultIds[j] = message.estimateResultIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateResultDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateResultDetailsRequest;
            })();

            estimation.ListEstimateResultDetailsResponse = (function() {

                /**
                 * Properties of a ListEstimateResultDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultDetailsResponse
                 * @property {Long|null} [total] ListEstimateResultDetailsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResultDetail>|null} [items] ListEstimateResultDetailsResponse items
                 * @property {string|null} [pageToken] ListEstimateResultDetailsResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateResultDetailsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultDetailsResponse.
                 * @implements IListEstimateResultDetailsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultDetailsResponse=} [properties] Properties to set
                 */
                function ListEstimateResultDetailsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultDetailsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @instance
                 */
                ListEstimateResultDetailsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateResultDetailsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResultDetail>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @instance
                 */
                ListEstimateResultDetailsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateResultDetailsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @instance
                 */
                ListEstimateResultDetailsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateResultDetailsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResultDetail.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateResultDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultDetailsResponse} ListEstimateResultDetailsResponse
                 */
                ListEstimateResultDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultDetailsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultDetailsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultDetailsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateResultDetailsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResultDetail.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultDetailsResponse} message ListEstimateResultDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResultDetail.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateResultDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateResultDetailsResponse;
            })();

            estimation.UpdateEstimateResultDetailRequest = (function() {

                /**
                 * Properties of an UpdateEstimateResultDetailRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUpdateEstimateResultDetailRequest
                 * @property {mtechnavi.api.estimation.IEstimateResultDetail|null} [estimateResultDetail] UpdateEstimateResultDetailRequest estimateResultDetail
                 */

                /**
                 * Constructs a new UpdateEstimateResultDetailRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UpdateEstimateResultDetailRequest.
                 * @implements IUpdateEstimateResultDetailRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUpdateEstimateResultDetailRequest=} [properties] Properties to set
                 */
                function UpdateEstimateResultDetailRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateEstimateResultDetailRequest estimateResultDetail.
                 * @member {mtechnavi.api.estimation.IEstimateResultDetail|null|undefined} estimateResultDetail
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultDetailRequest
                 * @instance
                 */
                UpdateEstimateResultDetailRequest.prototype.estimateResultDetail = null;

                /**
                 * Verifies an UpdateEstimateResultDetailRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultDetailRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateEstimateResultDetailRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultDetail != null && message.hasOwnProperty("estimateResultDetail")) {
                        let error = $root.mtechnavi.api.estimation.EstimateResultDetail.verify(message.estimateResultDetail);
                        if (error)
                            return "estimateResultDetail." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateEstimateResultDetailRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultDetailRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UpdateEstimateResultDetailRequest} UpdateEstimateResultDetailRequest
                 */
                UpdateEstimateResultDetailRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UpdateEstimateResultDetailRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UpdateEstimateResultDetailRequest();
                    if (object.estimateResultDetail != null) {
                        if (typeof object.estimateResultDetail !== "object")
                            throw TypeError(".mtechnavi.api.estimation.UpdateEstimateResultDetailRequest.estimateResultDetail: object expected");
                        message.estimateResultDetail = $root.mtechnavi.api.estimation.EstimateResultDetail.fromObject(object.estimateResultDetail);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateEstimateResultDetailRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultDetailRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UpdateEstimateResultDetailRequest} message UpdateEstimateResultDetailRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateEstimateResultDetailRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateResultDetail = null;
                    if (message.estimateResultDetail != null && message.hasOwnProperty("estimateResultDetail"))
                        object.estimateResultDetail = $root.mtechnavi.api.estimation.EstimateResultDetail.toObject(message.estimateResultDetail, options);
                    return object;
                };

                /**
                 * Converts this UpdateEstimateResultDetailRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UpdateEstimateResultDetailRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateEstimateResultDetailRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateEstimateResultDetailRequest;
            })();

            estimation.ListEstimateResultSummarysRequest = (function() {

                /**
                 * Properties of a ListEstimateResultSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultSummarysRequest
                 * @property {string|null} [pageToken] ListEstimateResultSummarysRequest pageToken
                 * @property {Array.<string>|null} [estimateResultIds] ListEstimateResultSummarysRequest estimateResultIds
                 * @property {Array.<string>|null} [estimateRequestIds] ListEstimateResultSummarysRequest estimateRequestIds
                 * @property {mtechnavi.api.estimation.ListEstimateResultSummarysRequest.IDataFilter|null} [dataFilter] ListEstimateResultSummarysRequest dataFilter
                 */

                /**
                 * Constructs a new ListEstimateResultSummarysRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultSummarysRequest.
                 * @implements IListEstimateResultSummarysRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultSummarysRequest=} [properties] Properties to set
                 */
                function ListEstimateResultSummarysRequest(properties) {
                    this.estimateResultIds = [];
                    this.estimateRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultSummarysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @instance
                 */
                ListEstimateResultSummarysRequest.prototype.pageToken = "";

                /**
                 * ListEstimateResultSummarysRequest estimateResultIds.
                 * @member {Array.<string>} estimateResultIds
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @instance
                 */
                ListEstimateResultSummarysRequest.prototype.estimateResultIds = $util.emptyArray;

                /**
                 * ListEstimateResultSummarysRequest estimateRequestIds.
                 * @member {Array.<string>} estimateRequestIds
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @instance
                 */
                ListEstimateResultSummarysRequest.prototype.estimateRequestIds = $util.emptyArray;

                /**
                 * ListEstimateResultSummarysRequest dataFilter.
                 * @member {mtechnavi.api.estimation.ListEstimateResultSummarysRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @instance
                 */
                ListEstimateResultSummarysRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListEstimateResultSummarysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultSummarysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateResultIds != null && message.hasOwnProperty("estimateResultIds")) {
                        if (!Array.isArray(message.estimateResultIds))
                            return "estimateResultIds: array expected";
                        for (let i = 0; i < message.estimateResultIds.length; ++i)
                            if (!$util.isString(message.estimateResultIds[i]))
                                return "estimateResultIds: string[] expected";
                    }
                    if (message.estimateRequestIds != null && message.hasOwnProperty("estimateRequestIds")) {
                        if (!Array.isArray(message.estimateRequestIds))
                            return "estimateRequestIds: array expected";
                        for (let i = 0; i < message.estimateRequestIds.length; ++i)
                            if (!$util.isString(message.estimateRequestIds[i]))
                                return "estimateRequestIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListEstimateResultSummarysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultSummarysRequest} ListEstimateResultSummarysRequest
                 */
                ListEstimateResultSummarysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateResultIds) {
                        if (!Array.isArray(object.estimateResultIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysRequest.estimateResultIds: array expected");
                        message.estimateResultIds = [];
                        for (let i = 0; i < object.estimateResultIds.length; ++i)
                            message.estimateResultIds[i] = String(object.estimateResultIds[i]);
                    }
                    if (object.estimateRequestIds) {
                        if (!Array.isArray(object.estimateRequestIds))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysRequest.estimateRequestIds: array expected");
                        message.estimateRequestIds = [];
                        for (let i = 0; i < object.estimateRequestIds.length; ++i)
                            message.estimateRequestIds[i] = String(object.estimateRequestIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultSummarysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultSummarysRequest} message ListEstimateResultSummarysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultSummarysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.estimateResultIds = [];
                        object.estimateRequestIds = [];
                    }
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateResultIds && message.estimateResultIds.length) {
                        object.estimateResultIds = [];
                        for (let j = 0; j < message.estimateResultIds.length; ++j)
                            object.estimateResultIds[j] = message.estimateResultIds[j];
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter.toObject(message.dataFilter, options);
                    if (message.estimateRequestIds && message.estimateRequestIds.length) {
                        object.estimateRequestIds = [];
                        for (let j = 0; j < message.estimateRequestIds.length; ++j)
                            object.estimateRequestIds[j] = message.estimateRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListEstimateResultSummarysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultSummarysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListEstimateResultSummarysRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                     * @interface IDataFilter
                     * @property {Array.<sharelib.INameOption>|null} [statuss] DataFilter statuss
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.estimation.ListEstimateResultSummarysRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statuss = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statuss.
                     * @member {Array.<sharelib.INameOption>} statuss
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statuss = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statuss != null && message.hasOwnProperty("statuss")) {
                            if (!Array.isArray(message.statuss))
                                return "statuss: array expected";
                            for (let i = 0; i < message.statuss.length; ++i) {
                                let error = $root.sharelib.NameOption.verify(message.statuss[i]);
                                if (error)
                                    return "statuss." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter();
                        if (object.statuss) {
                            if (!Array.isArray(object.statuss))
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter.statuss: array expected");
                            message.statuss = [];
                            for (let i = 0; i < object.statuss.length; ++i) {
                                if (typeof object.statuss[i] !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter.statuss: object expected");
                                message.statuss[i] = $root.sharelib.NameOption.fromObject(object.statuss[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.statuss = [];
                        if (message.statuss && message.statuss.length) {
                            object.statuss = [];
                            for (let j = 0; j < message.statuss.length; ++j)
                                object.statuss[j] = $root.sharelib.NameOption.toObject(message.statuss[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListEstimateResultSummarysRequest;
            })();

            estimation.ListEstimateResultSummarysResponse = (function() {

                /**
                 * Properties of a ListEstimateResultSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IListEstimateResultSummarysResponse
                 * @property {Long|null} [total] ListEstimateResultSummarysResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResultSummary>|null} [items] ListEstimateResultSummarysResponse items
                 * @property {string|null} [pageToken] ListEstimateResultSummarysResponse pageToken
                 */

                /**
                 * Constructs a new ListEstimateResultSummarysResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a ListEstimateResultSummarysResponse.
                 * @implements IListEstimateResultSummarysResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IListEstimateResultSummarysResponse=} [properties] Properties to set
                 */
                function ListEstimateResultSummarysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListEstimateResultSummarysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @instance
                 */
                ListEstimateResultSummarysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListEstimateResultSummarysResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResultSummary>} items
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @instance
                 */
                ListEstimateResultSummarysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListEstimateResultSummarysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @instance
                 */
                ListEstimateResultSummarysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListEstimateResultSummarysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListEstimateResultSummarysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResultSummary.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListEstimateResultSummarysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ListEstimateResultSummarysResponse} ListEstimateResultSummarysResponse
                 */
                ListEstimateResultSummarysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ListEstimateResultSummarysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ListEstimateResultSummarysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.ListEstimateResultSummarysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResultSummary.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListEstimateResultSummarysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @static
                 * @param {mtechnavi.api.estimation.ListEstimateResultSummarysResponse} message ListEstimateResultSummarysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListEstimateResultSummarysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResultSummary.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListEstimateResultSummarysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ListEstimateResultSummarysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListEstimateResultSummarysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListEstimateResultSummarysResponse;
            })();

            estimation.OpenEstimateRequestUnitRequest = (function() {

                /**
                 * Properties of an OpenEstimateRequestUnitRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IOpenEstimateRequestUnitRequest
                 * @property {string|null} [estimateRequestUnitId] OpenEstimateRequestUnitRequest estimateRequestUnitId
                 */

                /**
                 * Constructs a new OpenEstimateRequestUnitRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an OpenEstimateRequestUnitRequest.
                 * @implements IOpenEstimateRequestUnitRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IOpenEstimateRequestUnitRequest=} [properties] Properties to set
                 */
                function OpenEstimateRequestUnitRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OpenEstimateRequestUnitRequest estimateRequestUnitId.
                 * @member {string} estimateRequestUnitId
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitRequest
                 * @instance
                 */
                OpenEstimateRequestUnitRequest.prototype.estimateRequestUnitId = "";

                /**
                 * Verifies an OpenEstimateRequestUnitRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OpenEstimateRequestUnitRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        if (!$util.isString(message.estimateRequestUnitId))
                            return "estimateRequestUnitId: string expected";
                    return null;
                };

                /**
                 * Creates an OpenEstimateRequestUnitRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.OpenEstimateRequestUnitRequest} OpenEstimateRequestUnitRequest
                 */
                OpenEstimateRequestUnitRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.OpenEstimateRequestUnitRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.OpenEstimateRequestUnitRequest();
                    if (object.estimateRequestUnitId != null)
                        message.estimateRequestUnitId = String(object.estimateRequestUnitId);
                    return message;
                };

                /**
                 * Creates a plain object from an OpenEstimateRequestUnitRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitRequest
                 * @static
                 * @param {mtechnavi.api.estimation.OpenEstimateRequestUnitRequest} message OpenEstimateRequestUnitRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenEstimateRequestUnitRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.estimateRequestUnitId = "";
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        object.estimateRequestUnitId = message.estimateRequestUnitId;
                    return object;
                };

                /**
                 * Converts this OpenEstimateRequestUnitRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenEstimateRequestUnitRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenEstimateRequestUnitRequest;
            })();

            estimation.OpenEstimateRequestUnitResponse = (function() {

                /**
                 * Properties of an OpenEstimateRequestUnitResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IOpenEstimateRequestUnitResponse
                 * @property {mtechnavi.api.estimation.IOpened|null} [opened] OpenEstimateRequestUnitResponse opened
                 */

                /**
                 * Constructs a new OpenEstimateRequestUnitResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an OpenEstimateRequestUnitResponse.
                 * @implements IOpenEstimateRequestUnitResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IOpenEstimateRequestUnitResponse=} [properties] Properties to set
                 */
                function OpenEstimateRequestUnitResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OpenEstimateRequestUnitResponse opened.
                 * @member {mtechnavi.api.estimation.IOpened|null|undefined} opened
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitResponse
                 * @instance
                 */
                OpenEstimateRequestUnitResponse.prototype.opened = null;

                /**
                 * Verifies an OpenEstimateRequestUnitResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OpenEstimateRequestUnitResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.opened != null && message.hasOwnProperty("opened")) {
                        let error = $root.mtechnavi.api.estimation.Opened.verify(message.opened);
                        if (error)
                            return "opened." + error;
                    }
                    return null;
                };

                /**
                 * Creates an OpenEstimateRequestUnitResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.OpenEstimateRequestUnitResponse} OpenEstimateRequestUnitResponse
                 */
                OpenEstimateRequestUnitResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.OpenEstimateRequestUnitResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.OpenEstimateRequestUnitResponse();
                    if (object.opened != null) {
                        if (typeof object.opened !== "object")
                            throw TypeError(".mtechnavi.api.estimation.OpenEstimateRequestUnitResponse.opened: object expected");
                        message.opened = $root.mtechnavi.api.estimation.Opened.fromObject(object.opened);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an OpenEstimateRequestUnitResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitResponse
                 * @static
                 * @param {mtechnavi.api.estimation.OpenEstimateRequestUnitResponse} message OpenEstimateRequestUnitResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenEstimateRequestUnitResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.opened = null;
                    if (message.opened != null && message.hasOwnProperty("opened"))
                        object.opened = $root.mtechnavi.api.estimation.Opened.toObject(message.opened, options);
                    return object;
                };

                /**
                 * Converts this OpenEstimateRequestUnitResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.OpenEstimateRequestUnitResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenEstimateRequestUnitResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenEstimateRequestUnitResponse;
            })();

            estimation.SharedListEstimateSelectionsRequest = (function() {

                /**
                 * Properties of a SharedListEstimateSelectionsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateSelectionsRequest
                 * @property {string|null} [pageToken] SharedListEstimateSelectionsRequest pageToken
                 * @property {Array.<string>|null} [estimateResultIds] SharedListEstimateSelectionsRequest estimateResultIds
                 */

                /**
                 * Constructs a new SharedListEstimateSelectionsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateSelectionsRequest.
                 * @implements ISharedListEstimateSelectionsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateSelectionsRequest=} [properties] Properties to set
                 */
                function SharedListEstimateSelectionsRequest(properties) {
                    this.estimateResultIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateSelectionsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @instance
                 */
                SharedListEstimateSelectionsRequest.prototype.pageToken = "";

                /**
                 * SharedListEstimateSelectionsRequest estimateResultIds.
                 * @member {Array.<string>} estimateResultIds
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @instance
                 */
                SharedListEstimateSelectionsRequest.prototype.estimateResultIds = $util.emptyArray;

                /**
                 * Verifies a SharedListEstimateSelectionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateSelectionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.estimateResultIds != null && message.hasOwnProperty("estimateResultIds")) {
                        if (!Array.isArray(message.estimateResultIds))
                            return "estimateResultIds: array expected";
                        for (let i = 0; i < message.estimateResultIds.length; ++i)
                            if (!$util.isString(message.estimateResultIds[i]))
                                return "estimateResultIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListEstimateSelectionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateSelectionsRequest} SharedListEstimateSelectionsRequest
                 */
                SharedListEstimateSelectionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateSelectionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateSelectionsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.estimateResultIds) {
                        if (!Array.isArray(object.estimateResultIds))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateSelectionsRequest.estimateResultIds: array expected");
                        message.estimateResultIds = [];
                        for (let i = 0; i < object.estimateResultIds.length; ++i)
                            message.estimateResultIds[i] = String(object.estimateResultIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateSelectionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateSelectionsRequest} message SharedListEstimateSelectionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateSelectionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.estimateResultIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.estimateResultIds && message.estimateResultIds.length) {
                        object.estimateResultIds = [];
                        for (let j = 0; j < message.estimateResultIds.length; ++j)
                            object.estimateResultIds[j] = message.estimateResultIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListEstimateSelectionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateSelectionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateSelectionsRequest;
            })();

            estimation.SharedListEstimateSelectionsResponse = (function() {

                /**
                 * Properties of a SharedListEstimateSelectionsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface ISharedListEstimateSelectionsResponse
                 * @property {Long|null} [total] SharedListEstimateSelectionsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateSelection>|null} [items] SharedListEstimateSelectionsResponse items
                 * @property {string|null} [pageToken] SharedListEstimateSelectionsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListEstimateSelectionsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a SharedListEstimateSelectionsResponse.
                 * @implements ISharedListEstimateSelectionsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.ISharedListEstimateSelectionsResponse=} [properties] Properties to set
                 */
                function SharedListEstimateSelectionsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListEstimateSelectionsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @instance
                 */
                SharedListEstimateSelectionsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListEstimateSelectionsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateSelection>} items
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @instance
                 */
                SharedListEstimateSelectionsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListEstimateSelectionsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @instance
                 */
                SharedListEstimateSelectionsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListEstimateSelectionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListEstimateSelectionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateSelection.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListEstimateSelectionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.SharedListEstimateSelectionsResponse} SharedListEstimateSelectionsResponse
                 */
                SharedListEstimateSelectionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.SharedListEstimateSelectionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.SharedListEstimateSelectionsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.SharedListEstimateSelectionsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.SharedListEstimateSelectionsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateSelection.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListEstimateSelectionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.SharedListEstimateSelectionsResponse} message SharedListEstimateSelectionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListEstimateSelectionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateSelection.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListEstimateSelectionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.SharedListEstimateSelectionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListEstimateSelectionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListEstimateSelectionsResponse;
            })();

            estimation.UncompletedListEstimateResultsRequest = (function() {

                /**
                 * Properties of an UncompletedListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUncompletedListEstimateResultsRequest
                 * @property {string|null} [pageToken] UncompletedListEstimateResultsRequest pageToken
                 */

                /**
                 * Constructs a new UncompletedListEstimateResultsRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UncompletedListEstimateResultsRequest.
                 * @implements IUncompletedListEstimateResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUncompletedListEstimateResultsRequest=} [properties] Properties to set
                 */
                function UncompletedListEstimateResultsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListEstimateResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsRequest
                 * @instance
                 */
                UncompletedListEstimateResultsRequest.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListEstimateResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListEstimateResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListEstimateResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UncompletedListEstimateResultsRequest} UncompletedListEstimateResultsRequest
                 */
                UncompletedListEstimateResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UncompletedListEstimateResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UncompletedListEstimateResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListEstimateResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsRequest
                 * @static
                 * @param {mtechnavi.api.estimation.UncompletedListEstimateResultsRequest} message UncompletedListEstimateResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListEstimateResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListEstimateResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListEstimateResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListEstimateResultsRequest;
            })();

            estimation.UncompletedListEstimateResultsResponse = (function() {

                /**
                 * Properties of an UncompletedListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @interface IUncompletedListEstimateResultsResponse
                 * @property {Long|null} [total] UncompletedListEstimateResultsResponse total
                 * @property {Array.<mtechnavi.api.estimation.IEstimateResult>|null} [items] UncompletedListEstimateResultsResponse items
                 * @property {string|null} [pageToken] UncompletedListEstimateResultsResponse pageToken
                 */

                /**
                 * Constructs a new UncompletedListEstimateResultsResponse.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an UncompletedListEstimateResultsResponse.
                 * @implements IUncompletedListEstimateResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.estimation.IUncompletedListEstimateResultsResponse=} [properties] Properties to set
                 */
                function UncompletedListEstimateResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListEstimateResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @instance
                 */
                UncompletedListEstimateResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UncompletedListEstimateResultsResponse items.
                 * @member {Array.<mtechnavi.api.estimation.IEstimateResult>} items
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @instance
                 */
                UncompletedListEstimateResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * UncompletedListEstimateResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @instance
                 */
                UncompletedListEstimateResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListEstimateResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListEstimateResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.estimation.EstimateResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListEstimateResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.UncompletedListEstimateResultsResponse} UncompletedListEstimateResultsResponse
                 */
                UncompletedListEstimateResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.UncompletedListEstimateResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.UncompletedListEstimateResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.estimation.UncompletedListEstimateResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.UncompletedListEstimateResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.estimation.EstimateResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListEstimateResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @static
                 * @param {mtechnavi.api.estimation.UncompletedListEstimateResultsResponse} message UncompletedListEstimateResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListEstimateResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.estimation.EstimateResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListEstimateResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.UncompletedListEstimateResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListEstimateResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListEstimateResultsResponse;
            })();

            estimation.EstimateRequestPlan = (function() {

                /**
                 * Properties of an EstimateRequestPlan.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateRequestPlan
                 * @property {string|null} [estimateRequestPlanId] EstimateRequestPlan estimateRequestPlanId
                 * @property {string|null} [statusSystemName] EstimateRequestPlan statusSystemName
                 * @property {sharelib.INameOption|null} [status] EstimateRequestPlan status
                 * @property {Object.<string,string>|null} [displayNameLang] EstimateRequestPlan displayNameLang
                 * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPWorkOrder|null} [sapWorkOrder] EstimateRequestPlan sapWorkOrder
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] EstimateRequestPlan createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] EstimateRequestPlan updatedProperties
                 * @property {Long|null} [deletedAt] EstimateRequestPlan deletedAt
                 * @property {Long|null} [createdAt] EstimateRequestPlan createdAt
                 * @property {Long|null} [updatedAt] EstimateRequestPlan updatedAt
                 */

                /**
                 * Constructs a new EstimateRequestPlan.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateRequestPlan.
                 * @implements IEstimateRequestPlan
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateRequestPlan=} [properties] Properties to set
                 */
                function EstimateRequestPlan(properties) {
                    this.displayNameLang = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateRequestPlan estimateRequestPlanId.
                 * @member {string} estimateRequestPlanId
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.estimateRequestPlanId = "";

                /**
                 * EstimateRequestPlan statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.statusSystemName = "";

                /**
                 * EstimateRequestPlan status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.status = null;

                /**
                 * EstimateRequestPlan displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.displayNameLang = $util.emptyObject;

                /**
                 * EstimateRequestPlan sapWorkOrder.
                 * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPWorkOrder|null|undefined} sapWorkOrder
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.sapWorkOrder = null;

                /**
                 * EstimateRequestPlan createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.createdProperties = null;

                /**
                 * EstimateRequestPlan updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.updatedProperties = null;

                /**
                 * EstimateRequestPlan deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestPlan createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestPlan updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                EstimateRequestPlan.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * EstimateRequestPlan linkageContent.
                 * @member {"sapWorkOrder"|undefined} linkageContent
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 */
                Object.defineProperty(EstimateRequestPlan.prototype, "linkageContent", {
                    get: $util.oneOfGetter($oneOfFields = ["sapWorkOrder"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Verifies an EstimateRequestPlan message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateRequestPlan.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        if (!$util.isString(message.estimateRequestPlanId))
                            return "estimateRequestPlanId: string expected";
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.sapWorkOrder != null && message.hasOwnProperty("sapWorkOrder")) {
                        properties.linkageContent = 1;
                        {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.verify(message.sapWorkOrder);
                            if (error)
                                return "sapWorkOrder." + error;
                        }
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateRequestPlan message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateRequestPlan} EstimateRequestPlan
                 */
                EstimateRequestPlan.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan();
                    if (object.estimateRequestPlanId != null)
                        message.estimateRequestPlanId = String(object.estimateRequestPlanId);
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.sapWorkOrder != null) {
                        if (typeof object.sapWorkOrder !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.sapWorkOrder: object expected");
                        message.sapWorkOrder = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.fromObject(object.sapWorkOrder);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateRequestPlan message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateRequestPlan} message EstimateRequestPlan
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateRequestPlan.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.estimateRequestPlanId = "";
                        object.statusSystemName = "";
                        object.status = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateRequestPlanId != null && message.hasOwnProperty("estimateRequestPlanId"))
                        object.estimateRequestPlanId = message.estimateRequestPlanId;
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.sapWorkOrder != null && message.hasOwnProperty("sapWorkOrder")) {
                        object.sapWorkOrder = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.toObject(message.sapWorkOrder, options);
                        if (options.oneofs)
                            object.linkageContent = "sapWorkOrder";
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this EstimateRequestPlan to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateRequestPlan.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                EstimateRequestPlan.SAPWorkOrder = (function() {

                    /**
                     * Properties of a SAPWorkOrder.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPWorkOrder
                     * @property {string|null} [trackingCode] SAPWorkOrder trackingCode
                     * @property {string|null} [orderType] SAPWorkOrder orderType
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ICostCenter|null} [costCenter] SAPWorkOrder costCenter
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [company] SAPWorkOrder company
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPReceiverProperties|null} [receiverProperties] SAPWorkOrder receiverProperties
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.IRequesterProperties|null} [requesterProperties] SAPWorkOrder requesterProperties
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [workCategory] SAPWorkOrder workCategory
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [workUsage] SAPWorkOrder workUsage
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [place] SAPWorkOrder place
                     * @property {string|null} [amount] SAPWorkOrder amount
                     * @property {string|null} [budget] SAPWorkOrder budget
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [settlementAccount] SAPWorkOrder settlementAccount
                     * @property {string|null} [workCompletePlanDate] SAPWorkOrder workCompletePlanDate
                     * @property {string|null} [workCompletedDate] SAPWorkOrder workCompletedDate
                     * @property {string|null} [workStopedDate] SAPWorkOrder workStopedDate
                     * @property {string|null} [workDeletedDate] SAPWorkOrder workDeletedDate
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IMonthlyProperties|null} [monthlyProperties] SAPWorkOrder monthlyProperties
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IAccumulatedProperties|null} [accumulatedProperties] SAPWorkOrder accumulatedProperties
                     * @property {string|null} [actualUnitPrice] SAPWorkOrder actualUnitPrice
                     * @property {string|null} [designCostRatio] SAPWorkOrder designCostRatio
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPUnitAmount|null} [quantity] SAPWorkOrder quantity
                     * @property {string|null} [unitPrice] SAPWorkOrder unitPrice
                     * @property {string|null} [sellingPrice] SAPWorkOrder sellingPrice
                     * @property {string|null} [salesTaxCode] SAPWorkOrder salesTaxCode
                     * @property {string|null} [requestDate] SAPWorkOrder requestDate
                     * @property {string|null} [approvedDate] SAPWorkOrder approvedDate
                     * @property {sharelib.IDatetime|null} [desiredReceiveDt] SAPWorkOrder desiredReceiveDt
                     * @property {string|null} [receivedCode] SAPWorkOrder receivedCode
                     * @property {string|null} [requestedCode] SAPWorkOrder requestedCode
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [project] SAPWorkOrder project
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [customer] SAPWorkOrder customer
                     * @property {string|null} [orderCode] SAPWorkOrder orderCode
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPTypeCodeItem|null} [material] SAPWorkOrder material
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [suppliesCompany] SAPWorkOrder suppliesCompany
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPAssociatedCompany|null} [associatedCompany] SAPWorkOrder associatedCompany
                     * @property {boolean|null} [attachment] SAPWorkOrder attachment
                     * @property {string|null} [blueprintContent] SAPWorkOrder blueprintContent
                     * @property {string|null} [freeItem] SAPWorkOrder freeItem
                     * @property {string|null} [receivedFreeItem] SAPWorkOrder receivedFreeItem
                     * @property {string|null} [assetCode1] SAPWorkOrder assetCode1
                     * @property {string|null} [assetCode2] SAPWorkOrder assetCode2
                     * @property {string|null} [assetCode3] SAPWorkOrder assetCode3
                     * @property {string|null} [assetCode4] SAPWorkOrder assetCode4
                     * @property {string|null} [assetCode5] SAPWorkOrder assetCode5
                     * @property {string|null} [assetCode6] SAPWorkOrder assetCode6
                     * @property {string|null} [assetCode7] SAPWorkOrder assetCode7
                     * @property {string|null} [assetCode8] SAPWorkOrder assetCode8
                     * @property {string|null} [assetCode9] SAPWorkOrder assetCode9
                     * @property {string|null} [assetCode10] SAPWorkOrder assetCode10
                     * @property {string|null} [settlementCode] SAPWorkOrder settlementCode
                     * @property {string|null} [purchaseOrderDate] SAPWorkOrder purchaseOrderDate
                     * @property {string|null} [approvalDate] SAPWorkOrder approvalDate
                     * @property {string|null} [approvalQuantity] SAPWorkOrder approvalQuantity
                     * @property {string|null} [approvalAmount] SAPWorkOrder approvalAmount
                     * @property {string|null} [startDate] SAPWorkOrder startDate
                     * @property {string|null} [costCategory1] SAPWorkOrder costCategory1
                     * @property {string|null} [environmentInvestmentAmount1] SAPWorkOrder environmentInvestmentAmount1
                     * @property {string|null} [environmentReturnAmount1] SAPWorkOrder environmentReturnAmount1
                     */

                    /**
                     * Constructs a new SAPWorkOrder.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPWorkOrder.
                     * @implements ISAPWorkOrder
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPWorkOrder=} [properties] Properties to set
                     */
                    function SAPWorkOrder(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPWorkOrder trackingCode.
                     * @member {string} trackingCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.trackingCode = "";

                    /**
                     * SAPWorkOrder orderType.
                     * @member {string} orderType
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.orderType = "";

                    /**
                     * SAPWorkOrder costCenter.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ICostCenter|null|undefined} costCenter
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.costCenter = null;

                    /**
                     * SAPWorkOrder company.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} company
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.company = null;

                    /**
                     * SAPWorkOrder receiverProperties.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPReceiverProperties|null|undefined} receiverProperties
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.receiverProperties = null;

                    /**
                     * SAPWorkOrder requesterProperties.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.IRequesterProperties|null|undefined} requesterProperties
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.requesterProperties = null;

                    /**
                     * SAPWorkOrder workCategory.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} workCategory
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workCategory = null;

                    /**
                     * SAPWorkOrder workUsage.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} workUsage
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workUsage = null;

                    /**
                     * SAPWorkOrder place.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} place
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.place = null;

                    /**
                     * SAPWorkOrder amount.
                     * @member {string} amount
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.amount = "";

                    /**
                     * SAPWorkOrder budget.
                     * @member {string} budget
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.budget = "";

                    /**
                     * SAPWorkOrder settlementAccount.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} settlementAccount
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.settlementAccount = null;

                    /**
                     * SAPWorkOrder workCompletePlanDate.
                     * @member {string} workCompletePlanDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workCompletePlanDate = "";

                    /**
                     * SAPWorkOrder workCompletedDate.
                     * @member {string} workCompletedDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workCompletedDate = "";

                    /**
                     * SAPWorkOrder workStopedDate.
                     * @member {string} workStopedDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workStopedDate = "";

                    /**
                     * SAPWorkOrder workDeletedDate.
                     * @member {string} workDeletedDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.workDeletedDate = "";

                    /**
                     * SAPWorkOrder monthlyProperties.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IMonthlyProperties|null|undefined} monthlyProperties
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.monthlyProperties = null;

                    /**
                     * SAPWorkOrder accumulatedProperties.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IAccumulatedProperties|null|undefined} accumulatedProperties
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.accumulatedProperties = null;

                    /**
                     * SAPWorkOrder actualUnitPrice.
                     * @member {string} actualUnitPrice
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.actualUnitPrice = "";

                    /**
                     * SAPWorkOrder designCostRatio.
                     * @member {string} designCostRatio
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.designCostRatio = "";

                    /**
                     * SAPWorkOrder quantity.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPUnitAmount|null|undefined} quantity
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.quantity = null;

                    /**
                     * SAPWorkOrder unitPrice.
                     * @member {string} unitPrice
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.unitPrice = "";

                    /**
                     * SAPWorkOrder sellingPrice.
                     * @member {string} sellingPrice
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.sellingPrice = "";

                    /**
                     * SAPWorkOrder salesTaxCode.
                     * @member {string} salesTaxCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.salesTaxCode = "";

                    /**
                     * SAPWorkOrder requestDate.
                     * @member {string} requestDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.requestDate = "";

                    /**
                     * SAPWorkOrder approvedDate.
                     * @member {string} approvedDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.approvedDate = "";

                    /**
                     * SAPWorkOrder desiredReceiveDt.
                     * @member {sharelib.IDatetime|null|undefined} desiredReceiveDt
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.desiredReceiveDt = null;

                    /**
                     * SAPWorkOrder receivedCode.
                     * @member {string} receivedCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.receivedCode = "";

                    /**
                     * SAPWorkOrder requestedCode.
                     * @member {string} requestedCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.requestedCode = "";

                    /**
                     * SAPWorkOrder project.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} project
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.project = null;

                    /**
                     * SAPWorkOrder customer.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} customer
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.customer = null;

                    /**
                     * SAPWorkOrder orderCode.
                     * @member {string} orderCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.orderCode = "";

                    /**
                     * SAPWorkOrder material.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPTypeCodeItem|null|undefined} material
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.material = null;

                    /**
                     * SAPWorkOrder suppliesCompany.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} suppliesCompany
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.suppliesCompany = null;

                    /**
                     * SAPWorkOrder associatedCompany.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPAssociatedCompany|null|undefined} associatedCompany
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.associatedCompany = null;

                    /**
                     * SAPWorkOrder attachment.
                     * @member {boolean} attachment
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.attachment = false;

                    /**
                     * SAPWorkOrder blueprintContent.
                     * @member {string} blueprintContent
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.blueprintContent = "";

                    /**
                     * SAPWorkOrder freeItem.
                     * @member {string} freeItem
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.freeItem = "";

                    /**
                     * SAPWorkOrder receivedFreeItem.
                     * @member {string} receivedFreeItem
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.receivedFreeItem = "";

                    /**
                     * SAPWorkOrder assetCode1.
                     * @member {string} assetCode1
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode1 = "";

                    /**
                     * SAPWorkOrder assetCode2.
                     * @member {string} assetCode2
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode2 = "";

                    /**
                     * SAPWorkOrder assetCode3.
                     * @member {string} assetCode3
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode3 = "";

                    /**
                     * SAPWorkOrder assetCode4.
                     * @member {string} assetCode4
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode4 = "";

                    /**
                     * SAPWorkOrder assetCode5.
                     * @member {string} assetCode5
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode5 = "";

                    /**
                     * SAPWorkOrder assetCode6.
                     * @member {string} assetCode6
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode6 = "";

                    /**
                     * SAPWorkOrder assetCode7.
                     * @member {string} assetCode7
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode7 = "";

                    /**
                     * SAPWorkOrder assetCode8.
                     * @member {string} assetCode8
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode8 = "";

                    /**
                     * SAPWorkOrder assetCode9.
                     * @member {string} assetCode9
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode9 = "";

                    /**
                     * SAPWorkOrder assetCode10.
                     * @member {string} assetCode10
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.assetCode10 = "";

                    /**
                     * SAPWorkOrder settlementCode.
                     * @member {string} settlementCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.settlementCode = "";

                    /**
                     * SAPWorkOrder purchaseOrderDate.
                     * @member {string} purchaseOrderDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.purchaseOrderDate = "";

                    /**
                     * SAPWorkOrder approvalDate.
                     * @member {string} approvalDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.approvalDate = "";

                    /**
                     * SAPWorkOrder approvalQuantity.
                     * @member {string} approvalQuantity
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.approvalQuantity = "";

                    /**
                     * SAPWorkOrder approvalAmount.
                     * @member {string} approvalAmount
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.approvalAmount = "";

                    /**
                     * SAPWorkOrder startDate.
                     * @member {string} startDate
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.startDate = "";

                    /**
                     * SAPWorkOrder costCategory1.
                     * @member {string} costCategory1
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.costCategory1 = "";

                    /**
                     * SAPWorkOrder environmentInvestmentAmount1.
                     * @member {string} environmentInvestmentAmount1
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.environmentInvestmentAmount1 = "";

                    /**
                     * SAPWorkOrder environmentReturnAmount1.
                     * @member {string} environmentReturnAmount1
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     */
                    SAPWorkOrder.prototype.environmentReturnAmount1 = "";

                    /**
                     * Verifies a SAPWorkOrder message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPWorkOrder.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.trackingCode != null && message.hasOwnProperty("trackingCode"))
                            if (!$util.isString(message.trackingCode))
                                return "trackingCode: string expected";
                        if (message.orderType != null && message.hasOwnProperty("orderType"))
                            if (!$util.isString(message.orderType))
                                return "orderType: string expected";
                        if (message.costCenter != null && message.hasOwnProperty("costCenter")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.CostCenter.verify(message.costCenter);
                            if (error)
                                return "costCenter." + error;
                        }
                        if (message.company != null && message.hasOwnProperty("company")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.company);
                            if (error)
                                return "company." + error;
                        }
                        if (message.receiverProperties != null && message.hasOwnProperty("receiverProperties")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.verify(message.receiverProperties);
                            if (error)
                                return "receiverProperties." + error;
                        }
                        if (message.requesterProperties != null && message.hasOwnProperty("requesterProperties")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.verify(message.requesterProperties);
                            if (error)
                                return "requesterProperties." + error;
                        }
                        if (message.workCategory != null && message.hasOwnProperty("workCategory")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.workCategory);
                            if (error)
                                return "workCategory." + error;
                        }
                        if (message.workUsage != null && message.hasOwnProperty("workUsage")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.workUsage);
                            if (error)
                                return "workUsage." + error;
                        }
                        if (message.place != null && message.hasOwnProperty("place")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.place);
                            if (error)
                                return "place." + error;
                        }
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (!$util.isString(message.amount))
                                return "amount: string expected";
                        if (message.budget != null && message.hasOwnProperty("budget"))
                            if (!$util.isString(message.budget))
                                return "budget: string expected";
                        if (message.settlementAccount != null && message.hasOwnProperty("settlementAccount")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.settlementAccount);
                            if (error)
                                return "settlementAccount." + error;
                        }
                        if (message.workCompletePlanDate != null && message.hasOwnProperty("workCompletePlanDate"))
                            if (!$util.isString(message.workCompletePlanDate))
                                return "workCompletePlanDate: string expected";
                        if (message.workCompletedDate != null && message.hasOwnProperty("workCompletedDate"))
                            if (!$util.isString(message.workCompletedDate))
                                return "workCompletedDate: string expected";
                        if (message.workStopedDate != null && message.hasOwnProperty("workStopedDate"))
                            if (!$util.isString(message.workStopedDate))
                                return "workStopedDate: string expected";
                        if (message.workDeletedDate != null && message.hasOwnProperty("workDeletedDate"))
                            if (!$util.isString(message.workDeletedDate))
                                return "workDeletedDate: string expected";
                        if (message.monthlyProperties != null && message.hasOwnProperty("monthlyProperties")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties.verify(message.monthlyProperties);
                            if (error)
                                return "monthlyProperties." + error;
                        }
                        if (message.accumulatedProperties != null && message.hasOwnProperty("accumulatedProperties")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties.verify(message.accumulatedProperties);
                            if (error)
                                return "accumulatedProperties." + error;
                        }
                        if (message.actualUnitPrice != null && message.hasOwnProperty("actualUnitPrice"))
                            if (!$util.isString(message.actualUnitPrice))
                                return "actualUnitPrice: string expected";
                        if (message.designCostRatio != null && message.hasOwnProperty("designCostRatio"))
                            if (!$util.isString(message.designCostRatio))
                                return "designCostRatio: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount.verify(message.quantity);
                            if (error)
                                return "quantity." + error;
                        }
                        if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                            if (!$util.isString(message.unitPrice))
                                return "unitPrice: string expected";
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (!$util.isString(message.sellingPrice))
                                return "sellingPrice: string expected";
                        if (message.salesTaxCode != null && message.hasOwnProperty("salesTaxCode"))
                            if (!$util.isString(message.salesTaxCode))
                                return "salesTaxCode: string expected";
                        if (message.requestDate != null && message.hasOwnProperty("requestDate"))
                            if (!$util.isString(message.requestDate))
                                return "requestDate: string expected";
                        if (message.approvedDate != null && message.hasOwnProperty("approvedDate"))
                            if (!$util.isString(message.approvedDate))
                                return "approvedDate: string expected";
                        if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt")) {
                            let error = $root.sharelib.Datetime.verify(message.desiredReceiveDt);
                            if (error)
                                return "desiredReceiveDt." + error;
                        }
                        if (message.receivedCode != null && message.hasOwnProperty("receivedCode"))
                            if (!$util.isString(message.receivedCode))
                                return "receivedCode: string expected";
                        if (message.requestedCode != null && message.hasOwnProperty("requestedCode"))
                            if (!$util.isString(message.requestedCode))
                                return "requestedCode: string expected";
                        if (message.project != null && message.hasOwnProperty("project")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.project);
                            if (error)
                                return "project." + error;
                        }
                        if (message.customer != null && message.hasOwnProperty("customer")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.customer);
                            if (error)
                                return "customer." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.material != null && message.hasOwnProperty("material")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem.verify(message.material);
                            if (error)
                                return "material." + error;
                        }
                        if (message.suppliesCompany != null && message.hasOwnProperty("suppliesCompany")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.suppliesCompany);
                            if (error)
                                return "suppliesCompany." + error;
                        }
                        if (message.associatedCompany != null && message.hasOwnProperty("associatedCompany")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany.verify(message.associatedCompany);
                            if (error)
                                return "associatedCompany." + error;
                        }
                        if (message.attachment != null && message.hasOwnProperty("attachment"))
                            if (typeof message.attachment !== "boolean")
                                return "attachment: boolean expected";
                        if (message.blueprintContent != null && message.hasOwnProperty("blueprintContent"))
                            if (!$util.isString(message.blueprintContent))
                                return "blueprintContent: string expected";
                        if (message.freeItem != null && message.hasOwnProperty("freeItem"))
                            if (!$util.isString(message.freeItem))
                                return "freeItem: string expected";
                        if (message.receivedFreeItem != null && message.hasOwnProperty("receivedFreeItem"))
                            if (!$util.isString(message.receivedFreeItem))
                                return "receivedFreeItem: string expected";
                        if (message.assetCode1 != null && message.hasOwnProperty("assetCode1"))
                            if (!$util.isString(message.assetCode1))
                                return "assetCode1: string expected";
                        if (message.assetCode2 != null && message.hasOwnProperty("assetCode2"))
                            if (!$util.isString(message.assetCode2))
                                return "assetCode2: string expected";
                        if (message.assetCode3 != null && message.hasOwnProperty("assetCode3"))
                            if (!$util.isString(message.assetCode3))
                                return "assetCode3: string expected";
                        if (message.assetCode4 != null && message.hasOwnProperty("assetCode4"))
                            if (!$util.isString(message.assetCode4))
                                return "assetCode4: string expected";
                        if (message.assetCode5 != null && message.hasOwnProperty("assetCode5"))
                            if (!$util.isString(message.assetCode5))
                                return "assetCode5: string expected";
                        if (message.assetCode6 != null && message.hasOwnProperty("assetCode6"))
                            if (!$util.isString(message.assetCode6))
                                return "assetCode6: string expected";
                        if (message.assetCode7 != null && message.hasOwnProperty("assetCode7"))
                            if (!$util.isString(message.assetCode7))
                                return "assetCode7: string expected";
                        if (message.assetCode8 != null && message.hasOwnProperty("assetCode8"))
                            if (!$util.isString(message.assetCode8))
                                return "assetCode8: string expected";
                        if (message.assetCode9 != null && message.hasOwnProperty("assetCode9"))
                            if (!$util.isString(message.assetCode9))
                                return "assetCode9: string expected";
                        if (message.assetCode10 != null && message.hasOwnProperty("assetCode10"))
                            if (!$util.isString(message.assetCode10))
                                return "assetCode10: string expected";
                        if (message.settlementCode != null && message.hasOwnProperty("settlementCode"))
                            if (!$util.isString(message.settlementCode))
                                return "settlementCode: string expected";
                        if (message.purchaseOrderDate != null && message.hasOwnProperty("purchaseOrderDate"))
                            if (!$util.isString(message.purchaseOrderDate))
                                return "purchaseOrderDate: string expected";
                        if (message.approvalDate != null && message.hasOwnProperty("approvalDate"))
                            if (!$util.isString(message.approvalDate))
                                return "approvalDate: string expected";
                        if (message.approvalQuantity != null && message.hasOwnProperty("approvalQuantity"))
                            if (!$util.isString(message.approvalQuantity))
                                return "approvalQuantity: string expected";
                        if (message.approvalAmount != null && message.hasOwnProperty("approvalAmount"))
                            if (!$util.isString(message.approvalAmount))
                                return "approvalAmount: string expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            if (!$util.isString(message.startDate))
                                return "startDate: string expected";
                        if (message.costCategory1 != null && message.hasOwnProperty("costCategory1"))
                            if (!$util.isString(message.costCategory1))
                                return "costCategory1: string expected";
                        if (message.environmentInvestmentAmount1 != null && message.hasOwnProperty("environmentInvestmentAmount1"))
                            if (!$util.isString(message.environmentInvestmentAmount1))
                                return "environmentInvestmentAmount1: string expected";
                        if (message.environmentReturnAmount1 != null && message.hasOwnProperty("environmentReturnAmount1"))
                            if (!$util.isString(message.environmentReturnAmount1))
                                return "environmentReturnAmount1: string expected";
                        return null;
                    };

                    /**
                     * Creates a SAPWorkOrder message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder} SAPWorkOrder
                     */
                    SAPWorkOrder.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder();
                        if (object.trackingCode != null)
                            message.trackingCode = String(object.trackingCode);
                        if (object.orderType != null)
                            message.orderType = String(object.orderType);
                        if (object.costCenter != null) {
                            if (typeof object.costCenter !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.costCenter: object expected");
                            message.costCenter = $root.mtechnavi.api.estimation.EstimateRequestPlan.CostCenter.fromObject(object.costCenter);
                        }
                        if (object.company != null) {
                            if (typeof object.company !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.company: object expected");
                            message.company = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.company);
                        }
                        if (object.receiverProperties != null) {
                            if (typeof object.receiverProperties !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.receiverProperties: object expected");
                            message.receiverProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.fromObject(object.receiverProperties);
                        }
                        if (object.requesterProperties != null) {
                            if (typeof object.requesterProperties !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.requesterProperties: object expected");
                            message.requesterProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.fromObject(object.requesterProperties);
                        }
                        if (object.workCategory != null) {
                            if (typeof object.workCategory !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.workCategory: object expected");
                            message.workCategory = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.workCategory);
                        }
                        if (object.workUsage != null) {
                            if (typeof object.workUsage !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.workUsage: object expected");
                            message.workUsage = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.workUsage);
                        }
                        if (object.place != null) {
                            if (typeof object.place !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.place: object expected");
                            message.place = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.place);
                        }
                        if (object.amount != null)
                            message.amount = String(object.amount);
                        if (object.budget != null)
                            message.budget = String(object.budget);
                        if (object.settlementAccount != null) {
                            if (typeof object.settlementAccount !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.settlementAccount: object expected");
                            message.settlementAccount = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.settlementAccount);
                        }
                        if (object.workCompletePlanDate != null)
                            message.workCompletePlanDate = String(object.workCompletePlanDate);
                        if (object.workCompletedDate != null)
                            message.workCompletedDate = String(object.workCompletedDate);
                        if (object.workStopedDate != null)
                            message.workStopedDate = String(object.workStopedDate);
                        if (object.workDeletedDate != null)
                            message.workDeletedDate = String(object.workDeletedDate);
                        if (object.monthlyProperties != null) {
                            if (typeof object.monthlyProperties !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.monthlyProperties: object expected");
                            message.monthlyProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties.fromObject(object.monthlyProperties);
                        }
                        if (object.accumulatedProperties != null) {
                            if (typeof object.accumulatedProperties !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.accumulatedProperties: object expected");
                            message.accumulatedProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties.fromObject(object.accumulatedProperties);
                        }
                        if (object.actualUnitPrice != null)
                            message.actualUnitPrice = String(object.actualUnitPrice);
                        if (object.designCostRatio != null)
                            message.designCostRatio = String(object.designCostRatio);
                        if (object.quantity != null) {
                            if (typeof object.quantity !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.quantity: object expected");
                            message.quantity = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount.fromObject(object.quantity);
                        }
                        if (object.unitPrice != null)
                            message.unitPrice = String(object.unitPrice);
                        if (object.sellingPrice != null)
                            message.sellingPrice = String(object.sellingPrice);
                        if (object.salesTaxCode != null)
                            message.salesTaxCode = String(object.salesTaxCode);
                        if (object.requestDate != null)
                            message.requestDate = String(object.requestDate);
                        if (object.approvedDate != null)
                            message.approvedDate = String(object.approvedDate);
                        if (object.desiredReceiveDt != null) {
                            if (typeof object.desiredReceiveDt !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.desiredReceiveDt: object expected");
                            message.desiredReceiveDt = $root.sharelib.Datetime.fromObject(object.desiredReceiveDt);
                        }
                        if (object.receivedCode != null)
                            message.receivedCode = String(object.receivedCode);
                        if (object.requestedCode != null)
                            message.requestedCode = String(object.requestedCode);
                        if (object.project != null) {
                            if (typeof object.project !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.project: object expected");
                            message.project = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.project);
                        }
                        if (object.customer != null) {
                            if (typeof object.customer !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.customer: object expected");
                            message.customer = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.customer);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.material != null) {
                            if (typeof object.material !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.material: object expected");
                            message.material = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem.fromObject(object.material);
                        }
                        if (object.suppliesCompany != null) {
                            if (typeof object.suppliesCompany !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.suppliesCompany: object expected");
                            message.suppliesCompany = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.suppliesCompany);
                        }
                        if (object.associatedCompany != null) {
                            if (typeof object.associatedCompany !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.associatedCompany: object expected");
                            message.associatedCompany = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany.fromObject(object.associatedCompany);
                        }
                        if (object.attachment != null)
                            message.attachment = Boolean(object.attachment);
                        if (object.blueprintContent != null)
                            message.blueprintContent = String(object.blueprintContent);
                        if (object.freeItem != null)
                            message.freeItem = String(object.freeItem);
                        if (object.receivedFreeItem != null)
                            message.receivedFreeItem = String(object.receivedFreeItem);
                        if (object.assetCode1 != null)
                            message.assetCode1 = String(object.assetCode1);
                        if (object.assetCode2 != null)
                            message.assetCode2 = String(object.assetCode2);
                        if (object.assetCode3 != null)
                            message.assetCode3 = String(object.assetCode3);
                        if (object.assetCode4 != null)
                            message.assetCode4 = String(object.assetCode4);
                        if (object.assetCode5 != null)
                            message.assetCode5 = String(object.assetCode5);
                        if (object.assetCode6 != null)
                            message.assetCode6 = String(object.assetCode6);
                        if (object.assetCode7 != null)
                            message.assetCode7 = String(object.assetCode7);
                        if (object.assetCode8 != null)
                            message.assetCode8 = String(object.assetCode8);
                        if (object.assetCode9 != null)
                            message.assetCode9 = String(object.assetCode9);
                        if (object.assetCode10 != null)
                            message.assetCode10 = String(object.assetCode10);
                        if (object.settlementCode != null)
                            message.settlementCode = String(object.settlementCode);
                        if (object.purchaseOrderDate != null)
                            message.purchaseOrderDate = String(object.purchaseOrderDate);
                        if (object.approvalDate != null)
                            message.approvalDate = String(object.approvalDate);
                        if (object.approvalQuantity != null)
                            message.approvalQuantity = String(object.approvalQuantity);
                        if (object.approvalAmount != null)
                            message.approvalAmount = String(object.approvalAmount);
                        if (object.startDate != null)
                            message.startDate = String(object.startDate);
                        if (object.costCategory1 != null)
                            message.costCategory1 = String(object.costCategory1);
                        if (object.environmentInvestmentAmount1 != null)
                            message.environmentInvestmentAmount1 = String(object.environmentInvestmentAmount1);
                        if (object.environmentReturnAmount1 != null)
                            message.environmentReturnAmount1 = String(object.environmentReturnAmount1);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPWorkOrder message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder} message SAPWorkOrder
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPWorkOrder.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.trackingCode = "";
                            object.orderType = "";
                            object.costCenter = null;
                            object.company = null;
                            object.receiverProperties = null;
                            object.requesterProperties = null;
                            object.workCategory = null;
                            object.workUsage = null;
                            object.place = null;
                            object.amount = "";
                            object.budget = "";
                            object.settlementAccount = null;
                            object.workCompletePlanDate = "";
                            object.workCompletedDate = "";
                            object.workStopedDate = "";
                            object.workDeletedDate = "";
                            object.monthlyProperties = null;
                            object.accumulatedProperties = null;
                            object.actualUnitPrice = "";
                            object.designCostRatio = "";
                            object.quantity = null;
                            object.unitPrice = "";
                            object.sellingPrice = "";
                            object.salesTaxCode = "";
                            object.requestDate = "";
                            object.approvedDate = "";
                            object.receivedCode = "";
                            object.requestedCode = "";
                            object.project = null;
                            object.customer = null;
                            object.orderCode = "";
                            object.material = null;
                            object.suppliesCompany = null;
                            object.associatedCompany = null;
                            object.attachment = false;
                            object.blueprintContent = "";
                            object.freeItem = "";
                            object.receivedFreeItem = "";
                            object.assetCode1 = "";
                            object.assetCode2 = "";
                            object.assetCode3 = "";
                            object.assetCode4 = "";
                            object.assetCode5 = "";
                            object.assetCode6 = "";
                            object.assetCode7 = "";
                            object.assetCode8 = "";
                            object.assetCode9 = "";
                            object.assetCode10 = "";
                            object.settlementCode = "";
                            object.purchaseOrderDate = "";
                            object.approvalDate = "";
                            object.approvalQuantity = "";
                            object.approvalAmount = "";
                            object.startDate = "";
                            object.costCategory1 = "";
                            object.environmentInvestmentAmount1 = "";
                            object.environmentReturnAmount1 = "";
                            object.desiredReceiveDt = null;
                        }
                        if (message.trackingCode != null && message.hasOwnProperty("trackingCode"))
                            object.trackingCode = message.trackingCode;
                        if (message.orderType != null && message.hasOwnProperty("orderType"))
                            object.orderType = message.orderType;
                        if (message.costCenter != null && message.hasOwnProperty("costCenter"))
                            object.costCenter = $root.mtechnavi.api.estimation.EstimateRequestPlan.CostCenter.toObject(message.costCenter, options);
                        if (message.company != null && message.hasOwnProperty("company"))
                            object.company = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.company, options);
                        if (message.receiverProperties != null && message.hasOwnProperty("receiverProperties"))
                            object.receiverProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.toObject(message.receiverProperties, options);
                        if (message.requesterProperties != null && message.hasOwnProperty("requesterProperties"))
                            object.requesterProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.toObject(message.requesterProperties, options);
                        if (message.workCategory != null && message.hasOwnProperty("workCategory"))
                            object.workCategory = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.workCategory, options);
                        if (message.workUsage != null && message.hasOwnProperty("workUsage"))
                            object.workUsage = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.workUsage, options);
                        if (message.place != null && message.hasOwnProperty("place"))
                            object.place = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.place, options);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = message.amount;
                        if (message.budget != null && message.hasOwnProperty("budget"))
                            object.budget = message.budget;
                        if (message.settlementAccount != null && message.hasOwnProperty("settlementAccount"))
                            object.settlementAccount = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.settlementAccount, options);
                        if (message.workCompletePlanDate != null && message.hasOwnProperty("workCompletePlanDate"))
                            object.workCompletePlanDate = message.workCompletePlanDate;
                        if (message.workCompletedDate != null && message.hasOwnProperty("workCompletedDate"))
                            object.workCompletedDate = message.workCompletedDate;
                        if (message.workStopedDate != null && message.hasOwnProperty("workStopedDate"))
                            object.workStopedDate = message.workStopedDate;
                        if (message.workDeletedDate != null && message.hasOwnProperty("workDeletedDate"))
                            object.workDeletedDate = message.workDeletedDate;
                        if (message.monthlyProperties != null && message.hasOwnProperty("monthlyProperties"))
                            object.monthlyProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties.toObject(message.monthlyProperties, options);
                        if (message.accumulatedProperties != null && message.hasOwnProperty("accumulatedProperties"))
                            object.accumulatedProperties = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties.toObject(message.accumulatedProperties, options);
                        if (message.actualUnitPrice != null && message.hasOwnProperty("actualUnitPrice"))
                            object.actualUnitPrice = message.actualUnitPrice;
                        if (message.designCostRatio != null && message.hasOwnProperty("designCostRatio"))
                            object.designCostRatio = message.designCostRatio;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            object.quantity = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount.toObject(message.quantity, options);
                        if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                            object.unitPrice = message.unitPrice;
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            object.sellingPrice = message.sellingPrice;
                        if (message.salesTaxCode != null && message.hasOwnProperty("salesTaxCode"))
                            object.salesTaxCode = message.salesTaxCode;
                        if (message.requestDate != null && message.hasOwnProperty("requestDate"))
                            object.requestDate = message.requestDate;
                        if (message.approvedDate != null && message.hasOwnProperty("approvedDate"))
                            object.approvedDate = message.approvedDate;
                        if (message.receivedCode != null && message.hasOwnProperty("receivedCode"))
                            object.receivedCode = message.receivedCode;
                        if (message.requestedCode != null && message.hasOwnProperty("requestedCode"))
                            object.requestedCode = message.requestedCode;
                        if (message.project != null && message.hasOwnProperty("project"))
                            object.project = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.project, options);
                        if (message.customer != null && message.hasOwnProperty("customer"))
                            object.customer = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.customer, options);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.material != null && message.hasOwnProperty("material"))
                            object.material = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem.toObject(message.material, options);
                        if (message.suppliesCompany != null && message.hasOwnProperty("suppliesCompany"))
                            object.suppliesCompany = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.suppliesCompany, options);
                        if (message.associatedCompany != null && message.hasOwnProperty("associatedCompany"))
                            object.associatedCompany = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany.toObject(message.associatedCompany, options);
                        if (message.attachment != null && message.hasOwnProperty("attachment"))
                            object.attachment = message.attachment;
                        if (message.blueprintContent != null && message.hasOwnProperty("blueprintContent"))
                            object.blueprintContent = message.blueprintContent;
                        if (message.freeItem != null && message.hasOwnProperty("freeItem"))
                            object.freeItem = message.freeItem;
                        if (message.receivedFreeItem != null && message.hasOwnProperty("receivedFreeItem"))
                            object.receivedFreeItem = message.receivedFreeItem;
                        if (message.assetCode1 != null && message.hasOwnProperty("assetCode1"))
                            object.assetCode1 = message.assetCode1;
                        if (message.assetCode2 != null && message.hasOwnProperty("assetCode2"))
                            object.assetCode2 = message.assetCode2;
                        if (message.assetCode3 != null && message.hasOwnProperty("assetCode3"))
                            object.assetCode3 = message.assetCode3;
                        if (message.assetCode4 != null && message.hasOwnProperty("assetCode4"))
                            object.assetCode4 = message.assetCode4;
                        if (message.assetCode5 != null && message.hasOwnProperty("assetCode5"))
                            object.assetCode5 = message.assetCode5;
                        if (message.assetCode6 != null && message.hasOwnProperty("assetCode6"))
                            object.assetCode6 = message.assetCode6;
                        if (message.assetCode7 != null && message.hasOwnProperty("assetCode7"))
                            object.assetCode7 = message.assetCode7;
                        if (message.assetCode8 != null && message.hasOwnProperty("assetCode8"))
                            object.assetCode8 = message.assetCode8;
                        if (message.assetCode9 != null && message.hasOwnProperty("assetCode9"))
                            object.assetCode9 = message.assetCode9;
                        if (message.assetCode10 != null && message.hasOwnProperty("assetCode10"))
                            object.assetCode10 = message.assetCode10;
                        if (message.settlementCode != null && message.hasOwnProperty("settlementCode"))
                            object.settlementCode = message.settlementCode;
                        if (message.purchaseOrderDate != null && message.hasOwnProperty("purchaseOrderDate"))
                            object.purchaseOrderDate = message.purchaseOrderDate;
                        if (message.approvalDate != null && message.hasOwnProperty("approvalDate"))
                            object.approvalDate = message.approvalDate;
                        if (message.approvalQuantity != null && message.hasOwnProperty("approvalQuantity"))
                            object.approvalQuantity = message.approvalQuantity;
                        if (message.approvalAmount != null && message.hasOwnProperty("approvalAmount"))
                            object.approvalAmount = message.approvalAmount;
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = message.startDate;
                        if (message.costCategory1 != null && message.hasOwnProperty("costCategory1"))
                            object.costCategory1 = message.costCategory1;
                        if (message.environmentInvestmentAmount1 != null && message.hasOwnProperty("environmentInvestmentAmount1"))
                            object.environmentInvestmentAmount1 = message.environmentInvestmentAmount1;
                        if (message.environmentReturnAmount1 != null && message.hasOwnProperty("environmentReturnAmount1"))
                            object.environmentReturnAmount1 = message.environmentReturnAmount1;
                        if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt"))
                            object.desiredReceiveDt = $root.sharelib.Datetime.toObject(message.desiredReceiveDt, options);
                        return object;
                    };

                    /**
                     * Converts this SAPWorkOrder to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPWorkOrder.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    SAPWorkOrder.MonthlyProperties = (function() {

                        /**
                         * Properties of a MonthlyProperties.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                         * @interface IMonthlyProperties
                         * @property {string|null} [workTime] MonthlyProperties workTime
                         * @property {string|null} [designTime] MonthlyProperties designTime
                         * @property {string|null} [receivedAssessmentAmount] MonthlyProperties receivedAssessmentAmount
                         * @property {string|null} [purchaseAssessmentAmount] MonthlyProperties purchaseAssessmentAmount
                         * @property {string|null} [workCost] MonthlyProperties workCost
                         * @property {string|null} [designCost] MonthlyProperties designCost
                         * @property {string|null} [receivedAmount] MonthlyProperties receivedAmount
                         * @property {string|null} [orderAmount] MonthlyProperties orderAmount
                         * @property {string|null} [directDeliveryAmount] MonthlyProperties directDeliveryAmount
                         * @property {string|null} [deliveryAmount] MonthlyProperties deliveryAmount
                         * @property {string|null} [reclassifyAmount] MonthlyProperties reclassifyAmount
                         * @property {string|null} [totalAmount] MonthlyProperties totalAmount
                         */

                        /**
                         * Constructs a new MonthlyProperties.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                         * @classdesc Represents a MonthlyProperties.
                         * @implements IMonthlyProperties
                         * @constructor
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IMonthlyProperties=} [properties] Properties to set
                         */
                        function MonthlyProperties(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * MonthlyProperties workTime.
                         * @member {string} workTime
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.workTime = "";

                        /**
                         * MonthlyProperties designTime.
                         * @member {string} designTime
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.designTime = "";

                        /**
                         * MonthlyProperties receivedAssessmentAmount.
                         * @member {string} receivedAssessmentAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.receivedAssessmentAmount = "";

                        /**
                         * MonthlyProperties purchaseAssessmentAmount.
                         * @member {string} purchaseAssessmentAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.purchaseAssessmentAmount = "";

                        /**
                         * MonthlyProperties workCost.
                         * @member {string} workCost
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.workCost = "";

                        /**
                         * MonthlyProperties designCost.
                         * @member {string} designCost
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.designCost = "";

                        /**
                         * MonthlyProperties receivedAmount.
                         * @member {string} receivedAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.receivedAmount = "";

                        /**
                         * MonthlyProperties orderAmount.
                         * @member {string} orderAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.orderAmount = "";

                        /**
                         * MonthlyProperties directDeliveryAmount.
                         * @member {string} directDeliveryAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.directDeliveryAmount = "";

                        /**
                         * MonthlyProperties deliveryAmount.
                         * @member {string} deliveryAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.deliveryAmount = "";

                        /**
                         * MonthlyProperties reclassifyAmount.
                         * @member {string} reclassifyAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.reclassifyAmount = "";

                        /**
                         * MonthlyProperties totalAmount.
                         * @member {string} totalAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         */
                        MonthlyProperties.prototype.totalAmount = "";

                        /**
                         * Verifies a MonthlyProperties message.
                         * @function verify
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        MonthlyProperties.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workTime != null && message.hasOwnProperty("workTime"))
                                if (!$util.isString(message.workTime))
                                    return "workTime: string expected";
                            if (message.designTime != null && message.hasOwnProperty("designTime"))
                                if (!$util.isString(message.designTime))
                                    return "designTime: string expected";
                            if (message.receivedAssessmentAmount != null && message.hasOwnProperty("receivedAssessmentAmount"))
                                if (!$util.isString(message.receivedAssessmentAmount))
                                    return "receivedAssessmentAmount: string expected";
                            if (message.purchaseAssessmentAmount != null && message.hasOwnProperty("purchaseAssessmentAmount"))
                                if (!$util.isString(message.purchaseAssessmentAmount))
                                    return "purchaseAssessmentAmount: string expected";
                            if (message.workCost != null && message.hasOwnProperty("workCost"))
                                if (!$util.isString(message.workCost))
                                    return "workCost: string expected";
                            if (message.designCost != null && message.hasOwnProperty("designCost"))
                                if (!$util.isString(message.designCost))
                                    return "designCost: string expected";
                            if (message.receivedAmount != null && message.hasOwnProperty("receivedAmount"))
                                if (!$util.isString(message.receivedAmount))
                                    return "receivedAmount: string expected";
                            if (message.orderAmount != null && message.hasOwnProperty("orderAmount"))
                                if (!$util.isString(message.orderAmount))
                                    return "orderAmount: string expected";
                            if (message.directDeliveryAmount != null && message.hasOwnProperty("directDeliveryAmount"))
                                if (!$util.isString(message.directDeliveryAmount))
                                    return "directDeliveryAmount: string expected";
                            if (message.deliveryAmount != null && message.hasOwnProperty("deliveryAmount"))
                                if (!$util.isString(message.deliveryAmount))
                                    return "deliveryAmount: string expected";
                            if (message.reclassifyAmount != null && message.hasOwnProperty("reclassifyAmount"))
                                if (!$util.isString(message.reclassifyAmount))
                                    return "reclassifyAmount: string expected";
                            if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                                if (!$util.isString(message.totalAmount))
                                    return "totalAmount: string expected";
                            return null;
                        };

                        /**
                         * Creates a MonthlyProperties message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties} MonthlyProperties
                         */
                        MonthlyProperties.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties)
                                return object;
                            let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties();
                            if (object.workTime != null)
                                message.workTime = String(object.workTime);
                            if (object.designTime != null)
                                message.designTime = String(object.designTime);
                            if (object.receivedAssessmentAmount != null)
                                message.receivedAssessmentAmount = String(object.receivedAssessmentAmount);
                            if (object.purchaseAssessmentAmount != null)
                                message.purchaseAssessmentAmount = String(object.purchaseAssessmentAmount);
                            if (object.workCost != null)
                                message.workCost = String(object.workCost);
                            if (object.designCost != null)
                                message.designCost = String(object.designCost);
                            if (object.receivedAmount != null)
                                message.receivedAmount = String(object.receivedAmount);
                            if (object.orderAmount != null)
                                message.orderAmount = String(object.orderAmount);
                            if (object.directDeliveryAmount != null)
                                message.directDeliveryAmount = String(object.directDeliveryAmount);
                            if (object.deliveryAmount != null)
                                message.deliveryAmount = String(object.deliveryAmount);
                            if (object.reclassifyAmount != null)
                                message.reclassifyAmount = String(object.reclassifyAmount);
                            if (object.totalAmount != null)
                                message.totalAmount = String(object.totalAmount);
                            return message;
                        };

                        /**
                         * Creates a plain object from a MonthlyProperties message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @static
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties} message MonthlyProperties
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        MonthlyProperties.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults) {
                                object.workTime = "";
                                object.designTime = "";
                                object.receivedAssessmentAmount = "";
                                object.purchaseAssessmentAmount = "";
                                object.workCost = "";
                                object.designCost = "";
                                object.receivedAmount = "";
                                object.orderAmount = "";
                                object.directDeliveryAmount = "";
                                object.deliveryAmount = "";
                                object.reclassifyAmount = "";
                                object.totalAmount = "";
                            }
                            if (message.workTime != null && message.hasOwnProperty("workTime"))
                                object.workTime = message.workTime;
                            if (message.designTime != null && message.hasOwnProperty("designTime"))
                                object.designTime = message.designTime;
                            if (message.receivedAssessmentAmount != null && message.hasOwnProperty("receivedAssessmentAmount"))
                                object.receivedAssessmentAmount = message.receivedAssessmentAmount;
                            if (message.purchaseAssessmentAmount != null && message.hasOwnProperty("purchaseAssessmentAmount"))
                                object.purchaseAssessmentAmount = message.purchaseAssessmentAmount;
                            if (message.workCost != null && message.hasOwnProperty("workCost"))
                                object.workCost = message.workCost;
                            if (message.designCost != null && message.hasOwnProperty("designCost"))
                                object.designCost = message.designCost;
                            if (message.receivedAmount != null && message.hasOwnProperty("receivedAmount"))
                                object.receivedAmount = message.receivedAmount;
                            if (message.orderAmount != null && message.hasOwnProperty("orderAmount"))
                                object.orderAmount = message.orderAmount;
                            if (message.directDeliveryAmount != null && message.hasOwnProperty("directDeliveryAmount"))
                                object.directDeliveryAmount = message.directDeliveryAmount;
                            if (message.deliveryAmount != null && message.hasOwnProperty("deliveryAmount"))
                                object.deliveryAmount = message.deliveryAmount;
                            if (message.reclassifyAmount != null && message.hasOwnProperty("reclassifyAmount"))
                                object.reclassifyAmount = message.reclassifyAmount;
                            if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                                object.totalAmount = message.totalAmount;
                            return object;
                        };

                        /**
                         * Converts this MonthlyProperties to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.MonthlyProperties
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        MonthlyProperties.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return MonthlyProperties;
                    })();

                    SAPWorkOrder.AccumulatedProperties = (function() {

                        /**
                         * Properties of an AccumulatedProperties.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                         * @interface IAccumulatedProperties
                         * @property {string|null} [workTime] AccumulatedProperties workTime
                         * @property {string|null} [designTime] AccumulatedProperties designTime
                         * @property {string|null} [receivedDocumentAmount] AccumulatedProperties receivedDocumentAmount
                         * @property {string|null} [orderDocumentAmount] AccumulatedProperties orderDocumentAmount
                         * @property {string|null} [workCost] AccumulatedProperties workCost
                         * @property {string|null} [designCost] AccumulatedProperties designCost
                         * @property {string|null} [receivedAmount] AccumulatedProperties receivedAmount
                         * @property {string|null} [orderAmount] AccumulatedProperties orderAmount
                         * @property {string|null} [directDeliveryAmount] AccumulatedProperties directDeliveryAmount
                         * @property {string|null} [deliveryAmount] AccumulatedProperties deliveryAmount
                         * @property {string|null} [reclassifyAmount] AccumulatedProperties reclassifyAmount
                         * @property {string|null} [totalAmount] AccumulatedProperties totalAmount
                         */

                        /**
                         * Constructs a new AccumulatedProperties.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder
                         * @classdesc Represents an AccumulatedProperties.
                         * @implements IAccumulatedProperties
                         * @constructor
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.IAccumulatedProperties=} [properties] Properties to set
                         */
                        function AccumulatedProperties(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AccumulatedProperties workTime.
                         * @member {string} workTime
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.workTime = "";

                        /**
                         * AccumulatedProperties designTime.
                         * @member {string} designTime
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.designTime = "";

                        /**
                         * AccumulatedProperties receivedDocumentAmount.
                         * @member {string} receivedDocumentAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.receivedDocumentAmount = "";

                        /**
                         * AccumulatedProperties orderDocumentAmount.
                         * @member {string} orderDocumentAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.orderDocumentAmount = "";

                        /**
                         * AccumulatedProperties workCost.
                         * @member {string} workCost
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.workCost = "";

                        /**
                         * AccumulatedProperties designCost.
                         * @member {string} designCost
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.designCost = "";

                        /**
                         * AccumulatedProperties receivedAmount.
                         * @member {string} receivedAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.receivedAmount = "";

                        /**
                         * AccumulatedProperties orderAmount.
                         * @member {string} orderAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.orderAmount = "";

                        /**
                         * AccumulatedProperties directDeliveryAmount.
                         * @member {string} directDeliveryAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.directDeliveryAmount = "";

                        /**
                         * AccumulatedProperties deliveryAmount.
                         * @member {string} deliveryAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.deliveryAmount = "";

                        /**
                         * AccumulatedProperties reclassifyAmount.
                         * @member {string} reclassifyAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.reclassifyAmount = "";

                        /**
                         * AccumulatedProperties totalAmount.
                         * @member {string} totalAmount
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         */
                        AccumulatedProperties.prototype.totalAmount = "";

                        /**
                         * Verifies an AccumulatedProperties message.
                         * @function verify
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AccumulatedProperties.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.workTime != null && message.hasOwnProperty("workTime"))
                                if (!$util.isString(message.workTime))
                                    return "workTime: string expected";
                            if (message.designTime != null && message.hasOwnProperty("designTime"))
                                if (!$util.isString(message.designTime))
                                    return "designTime: string expected";
                            if (message.receivedDocumentAmount != null && message.hasOwnProperty("receivedDocumentAmount"))
                                if (!$util.isString(message.receivedDocumentAmount))
                                    return "receivedDocumentAmount: string expected";
                            if (message.orderDocumentAmount != null && message.hasOwnProperty("orderDocumentAmount"))
                                if (!$util.isString(message.orderDocumentAmount))
                                    return "orderDocumentAmount: string expected";
                            if (message.workCost != null && message.hasOwnProperty("workCost"))
                                if (!$util.isString(message.workCost))
                                    return "workCost: string expected";
                            if (message.designCost != null && message.hasOwnProperty("designCost"))
                                if (!$util.isString(message.designCost))
                                    return "designCost: string expected";
                            if (message.receivedAmount != null && message.hasOwnProperty("receivedAmount"))
                                if (!$util.isString(message.receivedAmount))
                                    return "receivedAmount: string expected";
                            if (message.orderAmount != null && message.hasOwnProperty("orderAmount"))
                                if (!$util.isString(message.orderAmount))
                                    return "orderAmount: string expected";
                            if (message.directDeliveryAmount != null && message.hasOwnProperty("directDeliveryAmount"))
                                if (!$util.isString(message.directDeliveryAmount))
                                    return "directDeliveryAmount: string expected";
                            if (message.deliveryAmount != null && message.hasOwnProperty("deliveryAmount"))
                                if (!$util.isString(message.deliveryAmount))
                                    return "deliveryAmount: string expected";
                            if (message.reclassifyAmount != null && message.hasOwnProperty("reclassifyAmount"))
                                if (!$util.isString(message.reclassifyAmount))
                                    return "reclassifyAmount: string expected";
                            if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                                if (!$util.isString(message.totalAmount))
                                    return "totalAmount: string expected";
                            return null;
                        };

                        /**
                         * Creates an AccumulatedProperties message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties} AccumulatedProperties
                         */
                        AccumulatedProperties.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties)
                                return object;
                            let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties();
                            if (object.workTime != null)
                                message.workTime = String(object.workTime);
                            if (object.designTime != null)
                                message.designTime = String(object.designTime);
                            if (object.receivedDocumentAmount != null)
                                message.receivedDocumentAmount = String(object.receivedDocumentAmount);
                            if (object.orderDocumentAmount != null)
                                message.orderDocumentAmount = String(object.orderDocumentAmount);
                            if (object.workCost != null)
                                message.workCost = String(object.workCost);
                            if (object.designCost != null)
                                message.designCost = String(object.designCost);
                            if (object.receivedAmount != null)
                                message.receivedAmount = String(object.receivedAmount);
                            if (object.orderAmount != null)
                                message.orderAmount = String(object.orderAmount);
                            if (object.directDeliveryAmount != null)
                                message.directDeliveryAmount = String(object.directDeliveryAmount);
                            if (object.deliveryAmount != null)
                                message.deliveryAmount = String(object.deliveryAmount);
                            if (object.reclassifyAmount != null)
                                message.reclassifyAmount = String(object.reclassifyAmount);
                            if (object.totalAmount != null)
                                message.totalAmount = String(object.totalAmount);
                            return message;
                        };

                        /**
                         * Creates a plain object from an AccumulatedProperties message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @static
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties} message AccumulatedProperties
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AccumulatedProperties.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults) {
                                object.workTime = "";
                                object.designTime = "";
                                object.receivedDocumentAmount = "";
                                object.orderDocumentAmount = "";
                                object.workCost = "";
                                object.designCost = "";
                                object.receivedAmount = "";
                                object.orderAmount = "";
                                object.directDeliveryAmount = "";
                                object.deliveryAmount = "";
                                object.reclassifyAmount = "";
                                object.totalAmount = "";
                            }
                            if (message.workTime != null && message.hasOwnProperty("workTime"))
                                object.workTime = message.workTime;
                            if (message.designTime != null && message.hasOwnProperty("designTime"))
                                object.designTime = message.designTime;
                            if (message.receivedDocumentAmount != null && message.hasOwnProperty("receivedDocumentAmount"))
                                object.receivedDocumentAmount = message.receivedDocumentAmount;
                            if (message.orderDocumentAmount != null && message.hasOwnProperty("orderDocumentAmount"))
                                object.orderDocumentAmount = message.orderDocumentAmount;
                            if (message.workCost != null && message.hasOwnProperty("workCost"))
                                object.workCost = message.workCost;
                            if (message.designCost != null && message.hasOwnProperty("designCost"))
                                object.designCost = message.designCost;
                            if (message.receivedAmount != null && message.hasOwnProperty("receivedAmount"))
                                object.receivedAmount = message.receivedAmount;
                            if (message.orderAmount != null && message.hasOwnProperty("orderAmount"))
                                object.orderAmount = message.orderAmount;
                            if (message.directDeliveryAmount != null && message.hasOwnProperty("directDeliveryAmount"))
                                object.directDeliveryAmount = message.directDeliveryAmount;
                            if (message.deliveryAmount != null && message.hasOwnProperty("deliveryAmount"))
                                object.deliveryAmount = message.deliveryAmount;
                            if (message.reclassifyAmount != null && message.hasOwnProperty("reclassifyAmount"))
                                object.reclassifyAmount = message.reclassifyAmount;
                            if (message.totalAmount != null && message.hasOwnProperty("totalAmount"))
                                object.totalAmount = message.totalAmount;
                            return object;
                        };

                        /**
                         * Converts this AccumulatedProperties to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPWorkOrder.AccumulatedProperties
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AccumulatedProperties.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AccumulatedProperties;
                    })();

                    return SAPWorkOrder;
                })();

                EstimateRequestPlan.RequesterProperties = (function() {

                    /**
                     * Properties of a RequesterProperties.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface IRequesterProperties
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IComponentUnitReference|null} [organizationUnit] RequesterProperties organizationUnit
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IStaffReference|null} [staff] RequesterProperties staff
                     * @property {string|null} [phoneNumber] RequesterProperties phoneNumber
                     */

                    /**
                     * Constructs a new RequesterProperties.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a RequesterProperties.
                     * @implements IRequesterProperties
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.IRequesterProperties=} [properties] Properties to set
                     */
                    function RequesterProperties(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RequesterProperties organizationUnit.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IComponentUnitReference|null|undefined} organizationUnit
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @instance
                     */
                    RequesterProperties.prototype.organizationUnit = null;

                    /**
                     * RequesterProperties staff.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IStaffReference|null|undefined} staff
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @instance
                     */
                    RequesterProperties.prototype.staff = null;

                    /**
                     * RequesterProperties phoneNumber.
                     * @member {string} phoneNumber
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @instance
                     */
                    RequesterProperties.prototype.phoneNumber = "";

                    /**
                     * Verifies a RequesterProperties message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RequesterProperties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference.verify(message.organizationUnit);
                            if (error)
                                return "organizationUnit." + error;
                        }
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                            if (!$util.isString(message.phoneNumber))
                                return "phoneNumber: string expected";
                        return null;
                    };

                    /**
                     * Creates a RequesterProperties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties} RequesterProperties
                     */
                    RequesterProperties.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties();
                        if (object.organizationUnit != null) {
                            if (typeof object.organizationUnit !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.organizationUnit: object expected");
                            message.organizationUnit = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference.fromObject(object.organizationUnit);
                        }
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.staff: object expected");
                            message.staff = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference.fromObject(object.staff);
                        }
                        if (object.phoneNumber != null)
                            message.phoneNumber = String(object.phoneNumber);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RequesterProperties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties} message RequesterProperties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RequesterProperties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.phoneNumber = "";
                            object.organizationUnit = null;
                            object.staff = null;
                        }
                        if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                            object.phoneNumber = message.phoneNumber;
                        if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit"))
                            object.organizationUnit = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference.toObject(message.organizationUnit, options);
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference.toObject(message.staff, options);
                        return object;
                    };

                    /**
                     * Converts this RequesterProperties to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RequesterProperties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    RequesterProperties.ComponentUnitReference = (function() {

                        /**
                         * Properties of a ComponentUnitReference.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                         * @interface IComponentUnitReference
                         * @property {Object.<string,string>|null} [displayNameLang] ComponentUnitReference displayNameLang
                         */

                        /**
                         * Constructs a new ComponentUnitReference.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                         * @classdesc Represents a ComponentUnitReference.
                         * @implements IComponentUnitReference
                         * @constructor
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IComponentUnitReference=} [properties] Properties to set
                         */
                        function ComponentUnitReference(properties) {
                            this.displayNameLang = {};
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ComponentUnitReference displayNameLang.
                         * @member {Object.<string,string>} displayNameLang
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference
                         * @instance
                         */
                        ComponentUnitReference.prototype.displayNameLang = $util.emptyObject;

                        /**
                         * Verifies a ComponentUnitReference message.
                         * @function verify
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ComponentUnitReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                                if (!$util.isObject(message.displayNameLang))
                                    return "displayNameLang: object expected";
                                let key = Object.keys(message.displayNameLang);
                                for (let i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.displayNameLang[key[i]]))
                                        return "displayNameLang: string{k:string} expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a ComponentUnitReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference} ComponentUnitReference
                         */
                        ComponentUnitReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference)
                                return object;
                            let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference();
                            if (object.displayNameLang) {
                                if (typeof object.displayNameLang !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference.displayNameLang: object expected");
                                message.displayNameLang = {};
                                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a ComponentUnitReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference
                         * @static
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference} message ComponentUnitReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ComponentUnitReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.objects || options.defaults)
                                object.displayNameLang = {};
                            let keys2;
                            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                                object.displayNameLang = {};
                                for (let j = 0; j < keys2.length; ++j)
                                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                            }
                            return object;
                        };

                        /**
                         * Converts this ComponentUnitReference to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.ComponentUnitReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ComponentUnitReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ComponentUnitReference;
                    })();

                    RequesterProperties.StaffReference = (function() {

                        /**
                         * Properties of a StaffReference.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                         * @interface IStaffReference
                         * @property {Object.<string,string>|null} [displayNameLang] StaffReference displayNameLang
                         */

                        /**
                         * Constructs a new StaffReference.
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties
                         * @classdesc Represents a StaffReference.
                         * @implements IStaffReference
                         * @constructor
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.IStaffReference=} [properties] Properties to set
                         */
                        function StaffReference(properties) {
                            this.displayNameLang = {};
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StaffReference displayNameLang.
                         * @member {Object.<string,string>} displayNameLang
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference
                         * @instance
                         */
                        StaffReference.prototype.displayNameLang = $util.emptyObject;

                        /**
                         * Verifies a StaffReference message.
                         * @function verify
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StaffReference.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                                if (!$util.isObject(message.displayNameLang))
                                    return "displayNameLang: object expected";
                                let key = Object.keys(message.displayNameLang);
                                for (let i = 0; i < key.length; ++i)
                                    if (!$util.isString(message.displayNameLang[key[i]]))
                                        return "displayNameLang: string{k:string} expected";
                            }
                            return null;
                        };

                        /**
                         * Creates a StaffReference message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference} StaffReference
                         */
                        StaffReference.fromObject = function fromObject(object) {
                            if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference)
                                return object;
                            let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference();
                            if (object.displayNameLang) {
                                if (typeof object.displayNameLang !== "object")
                                    throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference.displayNameLang: object expected");
                                message.displayNameLang = {};
                                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StaffReference message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference
                         * @static
                         * @param {mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference} message StaffReference
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StaffReference.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.objects || options.defaults)
                                object.displayNameLang = {};
                            let keys2;
                            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                                object.displayNameLang = {};
                                for (let j = 0; j < keys2.length; ++j)
                                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                            }
                            return object;
                        };

                        /**
                         * Converts this StaffReference to JSON.
                         * @function toJSON
                         * @memberof mtechnavi.api.estimation.EstimateRequestPlan.RequesterProperties.StaffReference
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StaffReference.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return StaffReference;
                    })();

                    return RequesterProperties;
                })();

                EstimateRequestPlan.CostCenter = (function() {

                    /**
                     * Properties of a CostCenter.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ICostCenter
                     * @property {string|null} [costCenterCode] CostCenter costCenterCode
                     * @property {string|null} [displayName] CostCenter displayName
                     */

                    /**
                     * Constructs a new CostCenter.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a CostCenter.
                     * @implements ICostCenter
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ICostCenter=} [properties] Properties to set
                     */
                    function CostCenter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CostCenter costCenterCode.
                     * @member {string} costCenterCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @instance
                     */
                    CostCenter.prototype.costCenterCode = "";

                    /**
                     * CostCenter displayName.
                     * @member {string} displayName
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @instance
                     */
                    CostCenter.prototype.displayName = "";

                    /**
                     * Verifies a CostCenter message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CostCenter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.costCenterCode != null && message.hasOwnProperty("costCenterCode"))
                            if (!$util.isString(message.costCenterCode))
                                return "costCenterCode: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        return null;
                    };

                    /**
                     * Creates a CostCenter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.CostCenter} CostCenter
                     */
                    CostCenter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.CostCenter)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.CostCenter();
                        if (object.costCenterCode != null)
                            message.costCenterCode = String(object.costCenterCode);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CostCenter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.CostCenter} message CostCenter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CostCenter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.costCenterCode = "";
                            object.displayName = "";
                        }
                        if (message.costCenterCode != null && message.hasOwnProperty("costCenterCode"))
                            object.costCenterCode = message.costCenterCode;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        return object;
                    };

                    /**
                     * Converts this CostCenter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.CostCenter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CostCenter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CostCenter;
                })();

                EstimateRequestPlan.SAPCodeItem = (function() {

                    /**
                     * Properties of a SAPCodeItem.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPCodeItem
                     * @property {string|null} [itemCode] SAPCodeItem itemCode
                     * @property {string|null} [displayName] SAPCodeItem displayName
                     */

                    /**
                     * Constructs a new SAPCodeItem.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPCodeItem.
                     * @implements ISAPCodeItem
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem=} [properties] Properties to set
                     */
                    function SAPCodeItem(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPCodeItem itemCode.
                     * @member {string} itemCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @instance
                     */
                    SAPCodeItem.prototype.itemCode = "";

                    /**
                     * SAPCodeItem displayName.
                     * @member {string} displayName
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @instance
                     */
                    SAPCodeItem.prototype.displayName = "";

                    /**
                     * Verifies a SAPCodeItem message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPCodeItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.itemCode != null && message.hasOwnProperty("itemCode"))
                            if (!$util.isString(message.itemCode))
                                return "itemCode: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        return null;
                    };

                    /**
                     * Creates a SAPCodeItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem} SAPCodeItem
                     */
                    SAPCodeItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem();
                        if (object.itemCode != null)
                            message.itemCode = String(object.itemCode);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPCodeItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem} message SAPCodeItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPCodeItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.itemCode = "";
                            object.displayName = "";
                        }
                        if (message.itemCode != null && message.hasOwnProperty("itemCode"))
                            object.itemCode = message.itemCode;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        return object;
                    };

                    /**
                     * Converts this SAPCodeItem to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPCodeItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SAPCodeItem;
                })();

                EstimateRequestPlan.SAPUnitAmount = (function() {

                    /**
                     * Properties of a SAPUnitAmount.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPUnitAmount
                     * @property {string|null} [unit] SAPUnitAmount unit
                     * @property {string|null} [amount] SAPUnitAmount amount
                     */

                    /**
                     * Constructs a new SAPUnitAmount.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPUnitAmount.
                     * @implements ISAPUnitAmount
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPUnitAmount=} [properties] Properties to set
                     */
                    function SAPUnitAmount(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPUnitAmount unit.
                     * @member {string} unit
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @instance
                     */
                    SAPUnitAmount.prototype.unit = "";

                    /**
                     * SAPUnitAmount amount.
                     * @member {string} amount
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @instance
                     */
                    SAPUnitAmount.prototype.amount = "";

                    /**
                     * Verifies a SAPUnitAmount message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPUnitAmount.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.unit != null && message.hasOwnProperty("unit"))
                            if (!$util.isString(message.unit))
                                return "unit: string expected";
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (!$util.isString(message.amount))
                                return "amount: string expected";
                        return null;
                    };

                    /**
                     * Creates a SAPUnitAmount message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount} SAPUnitAmount
                     */
                    SAPUnitAmount.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount();
                        if (object.unit != null)
                            message.unit = String(object.unit);
                        if (object.amount != null)
                            message.amount = String(object.amount);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPUnitAmount message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount} message SAPUnitAmount
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPUnitAmount.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.unit = "";
                            object.amount = "";
                        }
                        if (message.unit != null && message.hasOwnProperty("unit"))
                            object.unit = message.unit;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = message.amount;
                        return object;
                    };

                    /**
                     * Converts this SAPUnitAmount to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPUnitAmount
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPUnitAmount.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SAPUnitAmount;
                })();

                EstimateRequestPlan.SAPTypeCodeItem = (function() {

                    /**
                     * Properties of a SAPTypeCodeItem.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPTypeCodeItem
                     * @property {string|null} [itemType] SAPTypeCodeItem itemType
                     * @property {string|null} [itemCode] SAPTypeCodeItem itemCode
                     * @property {string|null} [displayName] SAPTypeCodeItem displayName
                     */

                    /**
                     * Constructs a new SAPTypeCodeItem.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPTypeCodeItem.
                     * @implements ISAPTypeCodeItem
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPTypeCodeItem=} [properties] Properties to set
                     */
                    function SAPTypeCodeItem(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPTypeCodeItem itemType.
                     * @member {string} itemType
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @instance
                     */
                    SAPTypeCodeItem.prototype.itemType = "";

                    /**
                     * SAPTypeCodeItem itemCode.
                     * @member {string} itemCode
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @instance
                     */
                    SAPTypeCodeItem.prototype.itemCode = "";

                    /**
                     * SAPTypeCodeItem displayName.
                     * @member {string} displayName
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @instance
                     */
                    SAPTypeCodeItem.prototype.displayName = "";

                    /**
                     * Verifies a SAPTypeCodeItem message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPTypeCodeItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            if (!$util.isString(message.itemType))
                                return "itemType: string expected";
                        if (message.itemCode != null && message.hasOwnProperty("itemCode"))
                            if (!$util.isString(message.itemCode))
                                return "itemCode: string expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        return null;
                    };

                    /**
                     * Creates a SAPTypeCodeItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem} SAPTypeCodeItem
                     */
                    SAPTypeCodeItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem();
                        if (object.itemType != null)
                            message.itemType = String(object.itemType);
                        if (object.itemCode != null)
                            message.itemCode = String(object.itemCode);
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPTypeCodeItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem} message SAPTypeCodeItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPTypeCodeItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.itemType = "";
                            object.itemCode = "";
                            object.displayName = "";
                        }
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            object.itemType = message.itemType;
                        if (message.itemCode != null && message.hasOwnProperty("itemCode"))
                            object.itemCode = message.itemCode;
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        return object;
                    };

                    /**
                     * Converts this SAPTypeCodeItem to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPTypeCodeItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPTypeCodeItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SAPTypeCodeItem;
                })();

                EstimateRequestPlan.SAPAssociatedCompany = (function() {

                    /**
                     * Properties of a SAPAssociatedCompany.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPAssociatedCompany
                     * @property {string|null} [displayName] SAPAssociatedCompany displayName
                     * @property {string|null} [costCenter] SAPAssociatedCompany costCenter
                     * @property {string|null} [profitCenter] SAPAssociatedCompany profitCenter
                     */

                    /**
                     * Constructs a new SAPAssociatedCompany.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPAssociatedCompany.
                     * @implements ISAPAssociatedCompany
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPAssociatedCompany=} [properties] Properties to set
                     */
                    function SAPAssociatedCompany(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPAssociatedCompany displayName.
                     * @member {string} displayName
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @instance
                     */
                    SAPAssociatedCompany.prototype.displayName = "";

                    /**
                     * SAPAssociatedCompany costCenter.
                     * @member {string} costCenter
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @instance
                     */
                    SAPAssociatedCompany.prototype.costCenter = "";

                    /**
                     * SAPAssociatedCompany profitCenter.
                     * @member {string} profitCenter
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @instance
                     */
                    SAPAssociatedCompany.prototype.profitCenter = "";

                    /**
                     * Verifies a SAPAssociatedCompany message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPAssociatedCompany.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            if (!$util.isString(message.displayName))
                                return "displayName: string expected";
                        if (message.costCenter != null && message.hasOwnProperty("costCenter"))
                            if (!$util.isString(message.costCenter))
                                return "costCenter: string expected";
                        if (message.profitCenter != null && message.hasOwnProperty("profitCenter"))
                            if (!$util.isString(message.profitCenter))
                                return "profitCenter: string expected";
                        return null;
                    };

                    /**
                     * Creates a SAPAssociatedCompany message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany} SAPAssociatedCompany
                     */
                    SAPAssociatedCompany.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany();
                        if (object.displayName != null)
                            message.displayName = String(object.displayName);
                        if (object.costCenter != null)
                            message.costCenter = String(object.costCenter);
                        if (object.profitCenter != null)
                            message.profitCenter = String(object.profitCenter);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPAssociatedCompany message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany} message SAPAssociatedCompany
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPAssociatedCompany.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.displayName = "";
                            object.costCenter = "";
                            object.profitCenter = "";
                        }
                        if (message.displayName != null && message.hasOwnProperty("displayName"))
                            object.displayName = message.displayName;
                        if (message.costCenter != null && message.hasOwnProperty("costCenter"))
                            object.costCenter = message.costCenter;
                        if (message.profitCenter != null && message.hasOwnProperty("profitCenter"))
                            object.profitCenter = message.profitCenter;
                        return object;
                    };

                    /**
                     * Converts this SAPAssociatedCompany to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPAssociatedCompany
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPAssociatedCompany.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SAPAssociatedCompany;
                })();

                EstimateRequestPlan.SAPReceiverProperties = (function() {

                    /**
                     * Properties of a SAPReceiverProperties.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @interface ISAPReceiverProperties
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [organizationUnit] SAPReceiverProperties organizationUnit
                     * @property {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null} [staff] SAPReceiverProperties staff
                     * @property {sharelib.IDatetime|null} [receivedDt] SAPReceiverProperties receivedDt
                     */

                    /**
                     * Constructs a new SAPReceiverProperties.
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan
                     * @classdesc Represents a SAPReceiverProperties.
                     * @implements ISAPReceiverProperties
                     * @constructor
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.ISAPReceiverProperties=} [properties] Properties to set
                     */
                    function SAPReceiverProperties(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SAPReceiverProperties organizationUnit.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} organizationUnit
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @instance
                     */
                    SAPReceiverProperties.prototype.organizationUnit = null;

                    /**
                     * SAPReceiverProperties staff.
                     * @member {mtechnavi.api.estimation.EstimateRequestPlan.ISAPCodeItem|null|undefined} staff
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @instance
                     */
                    SAPReceiverProperties.prototype.staff = null;

                    /**
                     * SAPReceiverProperties receivedDt.
                     * @member {sharelib.IDatetime|null|undefined} receivedDt
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @instance
                     */
                    SAPReceiverProperties.prototype.receivedDt = null;

                    /**
                     * Verifies a SAPReceiverProperties message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SAPReceiverProperties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.organizationUnit);
                            if (error)
                                return "organizationUnit." + error;
                        }
                        if (message.staff != null && message.hasOwnProperty("staff")) {
                            let error = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.verify(message.staff);
                            if (error)
                                return "staff." + error;
                        }
                        if (message.receivedDt != null && message.hasOwnProperty("receivedDt")) {
                            let error = $root.sharelib.Datetime.verify(message.receivedDt);
                            if (error)
                                return "receivedDt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SAPReceiverProperties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties} SAPReceiverProperties
                     */
                    SAPReceiverProperties.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties();
                        if (object.organizationUnit != null) {
                            if (typeof object.organizationUnit !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.organizationUnit: object expected");
                            message.organizationUnit = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.organizationUnit);
                        }
                        if (object.staff != null) {
                            if (typeof object.staff !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.staff: object expected");
                            message.staff = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.fromObject(object.staff);
                        }
                        if (object.receivedDt != null) {
                            if (typeof object.receivedDt !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties.receivedDt: object expected");
                            message.receivedDt = $root.sharelib.Datetime.fromObject(object.receivedDt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SAPReceiverProperties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @static
                     * @param {mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties} message SAPReceiverProperties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SAPReceiverProperties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.organizationUnit = null;
                            object.staff = null;
                            object.receivedDt = null;
                        }
                        if (message.organizationUnit != null && message.hasOwnProperty("organizationUnit"))
                            object.organizationUnit = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.organizationUnit, options);
                        if (message.staff != null && message.hasOwnProperty("staff"))
                            object.staff = $root.mtechnavi.api.estimation.EstimateRequestPlan.SAPCodeItem.toObject(message.staff, options);
                        if (message.receivedDt != null && message.hasOwnProperty("receivedDt"))
                            object.receivedDt = $root.sharelib.Datetime.toObject(message.receivedDt, options);
                        return object;
                    };

                    /**
                     * Converts this SAPReceiverProperties to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.EstimateRequestPlan.SAPReceiverProperties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SAPReceiverProperties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SAPReceiverProperties;
                })();

                return EstimateRequestPlan;
            })();

            estimation.EstimateManagement = (function() {

                /**
                 * Properties of an EstimateManagement.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateManagement
                 * @property {string|null} [estimateManagementId] EstimateManagement estimateManagementId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] EstimateManagement systemNotificationUsers
                 * @property {string|null} [remarks] EstimateManagement remarks
                 * @property {sharelib.INameOption|null} [status] EstimateManagement status
                 * @property {sharelib.INameOption|null} [approvalStatus] EstimateManagement approvalStatus
                 * @property {sharelib.IApprovalRequest|null} [approvalRequest] EstimateManagement approvalRequest
                 * @property {sharelib.IApprovalResult|null} [approvalResult] EstimateManagement approvalResult
                 * @property {sharelib.IEmbeddedUser|null} [issuer] EstimateManagement issuer
                 * @property {Long|null} [createdAt] EstimateManagement createdAt
                 * @property {Long|null} [updatedAt] EstimateManagement updatedAt
                 * @property {Long|null} [deletedAt] EstimateManagement deletedAt
                 */

                /**
                 * Constructs a new EstimateManagement.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateManagement.
                 * @implements IEstimateManagement
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateManagement=} [properties] Properties to set
                 */
                function EstimateManagement(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateManagement estimateManagementId.
                 * @member {string} estimateManagementId
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.estimateManagementId = "";

                /**
                 * EstimateManagement systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * EstimateManagement remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.remarks = "";

                /**
                 * EstimateManagement status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.status = null;

                /**
                 * EstimateManagement approvalStatus.
                 * @member {sharelib.INameOption|null|undefined} approvalStatus
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.approvalStatus = null;

                /**
                 * EstimateManagement approvalRequest.
                 * @member {sharelib.IApprovalRequest|null|undefined} approvalRequest
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.approvalRequest = null;

                /**
                 * EstimateManagement approvalResult.
                 * @member {sharelib.IApprovalResult|null|undefined} approvalResult
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.approvalResult = null;

                /**
                 * EstimateManagement issuer.
                 * @member {sharelib.IEmbeddedUser|null|undefined} issuer
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.issuer = null;

                /**
                 * EstimateManagement createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagement updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagement deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 */
                EstimateManagement.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateManagement message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateManagement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        if (!$util.isString(message.estimateManagementId))
                            return "estimateManagementId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.approvalStatus);
                        if (error)
                            return "approvalStatus." + error;
                    }
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest")) {
                        let error = $root.sharelib.ApprovalRequest.verify(message.approvalRequest);
                        if (error)
                            return "approvalRequest." + error;
                    }
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult")) {
                        let error = $root.sharelib.ApprovalResult.verify(message.approvalResult);
                        if (error)
                            return "approvalResult." + error;
                    }
                    if (message.issuer != null && message.hasOwnProperty("issuer")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.issuer);
                        if (error)
                            return "issuer." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateManagement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateManagement} EstimateManagement
                 */
                EstimateManagement.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateManagement)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateManagement();
                    if (object.estimateManagementId != null)
                        message.estimateManagementId = String(object.estimateManagementId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateManagement.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.approvalStatus != null) {
                        if (typeof object.approvalStatus !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.approvalStatus: object expected");
                        message.approvalStatus = $root.sharelib.NameOption.fromObject(object.approvalStatus);
                    }
                    if (object.approvalRequest != null) {
                        if (typeof object.approvalRequest !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.approvalRequest: object expected");
                        message.approvalRequest = $root.sharelib.ApprovalRequest.fromObject(object.approvalRequest);
                    }
                    if (object.approvalResult != null) {
                        if (typeof object.approvalResult !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.approvalResult: object expected");
                        message.approvalResult = $root.sharelib.ApprovalResult.fromObject(object.approvalResult);
                    }
                    if (object.issuer != null) {
                        if (typeof object.issuer !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagement.issuer: object expected");
                        message.issuer = $root.sharelib.EmbeddedUser.fromObject(object.issuer);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateManagement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateManagement} message EstimateManagement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateManagement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.estimateManagementId = "";
                        object.remarks = "";
                        object.status = null;
                        object.approvalStatus = null;
                        object.approvalRequest = null;
                        object.approvalResult = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.issuer = null;
                    }
                    if (message.estimateManagementId != null && message.hasOwnProperty("estimateManagementId"))
                        object.estimateManagementId = message.estimateManagementId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        object.approvalStatus = $root.sharelib.NameOption.toObject(message.approvalStatus, options);
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest"))
                        object.approvalRequest = $root.sharelib.ApprovalRequest.toObject(message.approvalRequest, options);
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult"))
                        object.approvalResult = $root.sharelib.ApprovalResult.toObject(message.approvalResult, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.issuer != null && message.hasOwnProperty("issuer"))
                        object.issuer = $root.sharelib.EmbeddedUser.toObject(message.issuer, options);
                    return object;
                };

                /**
                 * Converts this EstimateManagement to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateManagement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateManagement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateManagement;
            })();

            estimation.EstimateRequest = (function() {

                /**
                 * Properties of an EstimateRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateRequest
                 * @property {string|null} [estimateRequestId] EstimateRequest estimateRequestId
                 * @property {Long|null} [estimateRequestAutoName] EstimateRequest estimateRequestAutoName
                 * @property {string|null} [trackingCode] EstimateRequest trackingCode
                 * @property {string|null} [displayName] EstimateRequest displayName
                 * @property {sharelib.IDatetime|null} [estimateDeadlineDt] EstimateRequest estimateDeadlineDt
                 * @property {sharelib.INameOption|null} [desiredReceiveType] EstimateRequest desiredReceiveType
                 * @property {sharelib.IDatetime|null} [desiredReceiveDt] EstimateRequest desiredReceiveDt
                 * @property {string|null} [desiredReceiveRemarks] EstimateRequest desiredReceiveRemarks
                 * @property {sharelib.IDatetime|null} [orderPlanDt] EstimateRequest orderPlanDt
                 * @property {string|null} [orderPlanRemarks] EstimateRequest orderPlanRemarks
                 * @property {sharelib.INameOption|null} [topPriorityContent] EstimateRequest topPriorityContent
                 * @property {sharelib.INameOption|null} [purpose] EstimateRequest purpose
                 * @property {string|null} [notice] EstimateRequest notice
                 * @property {sharelib.INameOption|null} [deliveryPointType] EstimateRequest deliveryPointType
                 * @property {mtechnavi.api.estimation.IBusinessUnitBranchReference|null} [deliveryPoint] EstimateRequest deliveryPoint
                 * @property {sharelib.IAddress|null} [deliveryAddress] EstimateRequest deliveryAddress
                 * @property {string|null} [deliveryPhoneNumber] EstimateRequest deliveryPhoneNumber
                 * @property {string|null} [deliveryPlace] EstimateRequest deliveryPlace
                 * @property {mtechnavi.api.estimation.IImageFile|null} [deliveryImage] EstimateRequest deliveryImage
                 * @property {sharelib.IAttachment|null} [deliveryAttachment] EstimateRequest deliveryAttachment
                 * @property {sharelib.IOrganizationStructureReference|null} [contactOrganization] EstimateRequest contactOrganization
                 * @property {sharelib.IUserReference|null} [contactStaff] EstimateRequest contactStaff
                 * @property {string|null} [requesterDepartment] EstimateRequest requesterDepartment
                 * @property {string|null} [requesterResponsible] EstimateRequest requesterResponsible
                 * @property {Array.<sharelib.IAttachment>|null} [attachments] EstimateRequest attachments
                 * @property {sharelib.IEmbeddedCompany|null} [requestingCompany] EstimateRequest requestingCompany
                 * @property {Long|null} [sendedAt] EstimateRequest sendedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateRequest sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] EstimateRequest createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] EstimateRequest updatedProperties
                 * @property {Long|null} [createdAt] EstimateRequest createdAt
                 * @property {Long|null} [updatedAt] EstimateRequest updatedAt
                 * @property {Long|null} [deletedAt] EstimateRequest deletedAt
                 */

                /**
                 * Constructs a new EstimateRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateRequest.
                 * @implements IEstimateRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateRequest=} [properties] Properties to set
                 */
                function EstimateRequest(properties) {
                    this.attachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateRequest estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.estimateRequestId = "";

                /**
                 * EstimateRequest estimateRequestAutoName.
                 * @member {Long} estimateRequestAutoName
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.estimateRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequest trackingCode.
                 * @member {string} trackingCode
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.trackingCode = "";

                /**
                 * EstimateRequest displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.displayName = "";

                /**
                 * EstimateRequest estimateDeadlineDt.
                 * @member {sharelib.IDatetime|null|undefined} estimateDeadlineDt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.estimateDeadlineDt = null;

                /**
                 * EstimateRequest desiredReceiveType.
                 * @member {sharelib.INameOption|null|undefined} desiredReceiveType
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.desiredReceiveType = null;

                /**
                 * EstimateRequest desiredReceiveDt.
                 * @member {sharelib.IDatetime|null|undefined} desiredReceiveDt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.desiredReceiveDt = null;

                /**
                 * EstimateRequest desiredReceiveRemarks.
                 * @member {string} desiredReceiveRemarks
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.desiredReceiveRemarks = "";

                /**
                 * EstimateRequest orderPlanDt.
                 * @member {sharelib.IDatetime|null|undefined} orderPlanDt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.orderPlanDt = null;

                /**
                 * EstimateRequest orderPlanRemarks.
                 * @member {string} orderPlanRemarks
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.orderPlanRemarks = "";

                /**
                 * EstimateRequest topPriorityContent.
                 * @member {sharelib.INameOption|null|undefined} topPriorityContent
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.topPriorityContent = null;

                /**
                 * EstimateRequest purpose.
                 * @member {sharelib.INameOption|null|undefined} purpose
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.purpose = null;

                /**
                 * EstimateRequest notice.
                 * @member {string} notice
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.notice = "";

                /**
                 * EstimateRequest deliveryPointType.
                 * @member {sharelib.INameOption|null|undefined} deliveryPointType
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryPointType = null;

                /**
                 * EstimateRequest deliveryPoint.
                 * @member {mtechnavi.api.estimation.IBusinessUnitBranchReference|null|undefined} deliveryPoint
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryPoint = null;

                /**
                 * EstimateRequest deliveryAddress.
                 * @member {sharelib.IAddress|null|undefined} deliveryAddress
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryAddress = null;

                /**
                 * EstimateRequest deliveryPhoneNumber.
                 * @member {string} deliveryPhoneNumber
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryPhoneNumber = "";

                /**
                 * EstimateRequest deliveryPlace.
                 * @member {string} deliveryPlace
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryPlace = "";

                /**
                 * EstimateRequest deliveryImage.
                 * @member {mtechnavi.api.estimation.IImageFile|null|undefined} deliveryImage
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryImage = null;

                /**
                 * EstimateRequest deliveryAttachment.
                 * @member {sharelib.IAttachment|null|undefined} deliveryAttachment
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deliveryAttachment = null;

                /**
                 * EstimateRequest contactOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} contactOrganization
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.contactOrganization = null;

                /**
                 * EstimateRequest contactStaff.
                 * @member {sharelib.IUserReference|null|undefined} contactStaff
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.contactStaff = null;

                /**
                 * EstimateRequest requesterDepartment.
                 * @member {string} requesterDepartment
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.requesterDepartment = "";

                /**
                 * EstimateRequest requesterResponsible.
                 * @member {string} requesterResponsible
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.requesterResponsible = "";

                /**
                 * EstimateRequest attachments.
                 * @member {Array.<sharelib.IAttachment>} attachments
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.attachments = $util.emptyArray;

                /**
                 * EstimateRequest requestingCompany.
                 * @member {sharelib.IEmbeddedCompany|null|undefined} requestingCompany
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.requestingCompany = null;

                /**
                 * EstimateRequest sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.sharedProperties = null;

                /**
                 * EstimateRequest createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.createdProperties = null;

                /**
                 * EstimateRequest updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.updatedProperties = null;

                /**
                 * EstimateRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 */
                EstimateRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (!$util.isInteger(message.estimateRequestAutoName) && !(message.estimateRequestAutoName && $util.isInteger(message.estimateRequestAutoName.low) && $util.isInteger(message.estimateRequestAutoName.high)))
                            return "estimateRequestAutoName: integer|Long expected";
                    if (message.trackingCode != null && message.hasOwnProperty("trackingCode"))
                        if (!$util.isString(message.trackingCode))
                            return "trackingCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt")) {
                        let error = $root.sharelib.Datetime.verify(message.estimateDeadlineDt);
                        if (error)
                            return "estimateDeadlineDt." + error;
                    }
                    if (message.desiredReceiveType != null && message.hasOwnProperty("desiredReceiveType")) {
                        let error = $root.sharelib.NameOption.verify(message.desiredReceiveType);
                        if (error)
                            return "desiredReceiveType." + error;
                    }
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt")) {
                        let error = $root.sharelib.Datetime.verify(message.desiredReceiveDt);
                        if (error)
                            return "desiredReceiveDt." + error;
                    }
                    if (message.desiredReceiveRemarks != null && message.hasOwnProperty("desiredReceiveRemarks"))
                        if (!$util.isString(message.desiredReceiveRemarks))
                            return "desiredReceiveRemarks: string expected";
                    if (message.orderPlanDt != null && message.hasOwnProperty("orderPlanDt")) {
                        let error = $root.sharelib.Datetime.verify(message.orderPlanDt);
                        if (error)
                            return "orderPlanDt." + error;
                    }
                    if (message.orderPlanRemarks != null && message.hasOwnProperty("orderPlanRemarks"))
                        if (!$util.isString(message.orderPlanRemarks))
                            return "orderPlanRemarks: string expected";
                    if (message.topPriorityContent != null && message.hasOwnProperty("topPriorityContent")) {
                        let error = $root.sharelib.NameOption.verify(message.topPriorityContent);
                        if (error)
                            return "topPriorityContent." + error;
                    }
                    if (message.purpose != null && message.hasOwnProperty("purpose")) {
                        let error = $root.sharelib.NameOption.verify(message.purpose);
                        if (error)
                            return "purpose." + error;
                    }
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        if (!$util.isString(message.notice))
                            return "notice: string expected";
                    if (message.deliveryPointType != null && message.hasOwnProperty("deliveryPointType")) {
                        let error = $root.sharelib.NameOption.verify(message.deliveryPointType);
                        if (error)
                            return "deliveryPointType." + error;
                    }
                    if (message.deliveryPoint != null && message.hasOwnProperty("deliveryPoint")) {
                        let error = $root.mtechnavi.api.estimation.BusinessUnitBranchReference.verify(message.deliveryPoint);
                        if (error)
                            return "deliveryPoint." + error;
                    }
                    if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress")) {
                        let error = $root.sharelib.Address.verify(message.deliveryAddress);
                        if (error)
                            return "deliveryAddress." + error;
                    }
                    if (message.deliveryPhoneNumber != null && message.hasOwnProperty("deliveryPhoneNumber"))
                        if (!$util.isString(message.deliveryPhoneNumber))
                            return "deliveryPhoneNumber: string expected";
                    if (message.deliveryPlace != null && message.hasOwnProperty("deliveryPlace"))
                        if (!$util.isString(message.deliveryPlace))
                            return "deliveryPlace: string expected";
                    if (message.deliveryImage != null && message.hasOwnProperty("deliveryImage")) {
                        let error = $root.mtechnavi.api.estimation.ImageFile.verify(message.deliveryImage);
                        if (error)
                            return "deliveryImage." + error;
                    }
                    if (message.deliveryAttachment != null && message.hasOwnProperty("deliveryAttachment")) {
                        let error = $root.sharelib.Attachment.verify(message.deliveryAttachment);
                        if (error)
                            return "deliveryAttachment." + error;
                    }
                    if (message.contactOrganization != null && message.hasOwnProperty("contactOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.contactOrganization);
                        if (error)
                            return "contactOrganization." + error;
                    }
                    if (message.contactStaff != null && message.hasOwnProperty("contactStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.contactStaff);
                        if (error)
                            return "contactStaff." + error;
                    }
                    if (message.requesterDepartment != null && message.hasOwnProperty("requesterDepartment"))
                        if (!$util.isString(message.requesterDepartment))
                            return "requesterDepartment: string expected";
                    if (message.requesterResponsible != null && message.hasOwnProperty("requesterResponsible"))
                        if (!$util.isString(message.requesterResponsible))
                            return "requesterResponsible: string expected";
                    if (message.attachments != null && message.hasOwnProperty("attachments")) {
                        if (!Array.isArray(message.attachments))
                            return "attachments: array expected";
                        for (let i = 0; i < message.attachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.attachments[i]);
                            if (error)
                                return "attachments." + error;
                        }
                    }
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany")) {
                        let error = $root.sharelib.EmbeddedCompany.verify(message.requestingCompany);
                        if (error)
                            return "requestingCompany." + error;
                    }
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateRequest} EstimateRequest
                 */
                EstimateRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateRequest();
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.estimateRequestAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestAutoName = $util.Long.fromValue(object.estimateRequestAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestAutoName === "string")
                            message.estimateRequestAutoName = parseInt(object.estimateRequestAutoName, 10);
                        else if (typeof object.estimateRequestAutoName === "number")
                            message.estimateRequestAutoName = object.estimateRequestAutoName;
                        else if (typeof object.estimateRequestAutoName === "object")
                            message.estimateRequestAutoName = new $util.LongBits(object.estimateRequestAutoName.low >>> 0, object.estimateRequestAutoName.high >>> 0).toNumber();
                    if (object.trackingCode != null)
                        message.trackingCode = String(object.trackingCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.estimateDeadlineDt != null) {
                        if (typeof object.estimateDeadlineDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.estimateDeadlineDt: object expected");
                        message.estimateDeadlineDt = $root.sharelib.Datetime.fromObject(object.estimateDeadlineDt);
                    }
                    if (object.desiredReceiveType != null) {
                        if (typeof object.desiredReceiveType !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.desiredReceiveType: object expected");
                        message.desiredReceiveType = $root.sharelib.NameOption.fromObject(object.desiredReceiveType);
                    }
                    if (object.desiredReceiveDt != null) {
                        if (typeof object.desiredReceiveDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.desiredReceiveDt: object expected");
                        message.desiredReceiveDt = $root.sharelib.Datetime.fromObject(object.desiredReceiveDt);
                    }
                    if (object.desiredReceiveRemarks != null)
                        message.desiredReceiveRemarks = String(object.desiredReceiveRemarks);
                    if (object.orderPlanDt != null) {
                        if (typeof object.orderPlanDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.orderPlanDt: object expected");
                        message.orderPlanDt = $root.sharelib.Datetime.fromObject(object.orderPlanDt);
                    }
                    if (object.orderPlanRemarks != null)
                        message.orderPlanRemarks = String(object.orderPlanRemarks);
                    if (object.topPriorityContent != null) {
                        if (typeof object.topPriorityContent !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.topPriorityContent: object expected");
                        message.topPriorityContent = $root.sharelib.NameOption.fromObject(object.topPriorityContent);
                    }
                    if (object.purpose != null) {
                        if (typeof object.purpose !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.purpose: object expected");
                        message.purpose = $root.sharelib.NameOption.fromObject(object.purpose);
                    }
                    if (object.notice != null)
                        message.notice = String(object.notice);
                    if (object.deliveryPointType != null) {
                        if (typeof object.deliveryPointType !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.deliveryPointType: object expected");
                        message.deliveryPointType = $root.sharelib.NameOption.fromObject(object.deliveryPointType);
                    }
                    if (object.deliveryPoint != null) {
                        if (typeof object.deliveryPoint !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.deliveryPoint: object expected");
                        message.deliveryPoint = $root.mtechnavi.api.estimation.BusinessUnitBranchReference.fromObject(object.deliveryPoint);
                    }
                    if (object.deliveryAddress != null) {
                        if (typeof object.deliveryAddress !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.deliveryAddress: object expected");
                        message.deliveryAddress = $root.sharelib.Address.fromObject(object.deliveryAddress);
                    }
                    if (object.deliveryPhoneNumber != null)
                        message.deliveryPhoneNumber = String(object.deliveryPhoneNumber);
                    if (object.deliveryPlace != null)
                        message.deliveryPlace = String(object.deliveryPlace);
                    if (object.deliveryImage != null) {
                        if (typeof object.deliveryImage !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.deliveryImage: object expected");
                        message.deliveryImage = $root.mtechnavi.api.estimation.ImageFile.fromObject(object.deliveryImage);
                    }
                    if (object.deliveryAttachment != null) {
                        if (typeof object.deliveryAttachment !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.deliveryAttachment: object expected");
                        message.deliveryAttachment = $root.sharelib.Attachment.fromObject(object.deliveryAttachment);
                    }
                    if (object.contactOrganization != null) {
                        if (typeof object.contactOrganization !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.contactOrganization: object expected");
                        message.contactOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.contactOrganization);
                    }
                    if (object.contactStaff != null) {
                        if (typeof object.contactStaff !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.contactStaff: object expected");
                        message.contactStaff = $root.sharelib.UserReference.fromObject(object.contactStaff);
                    }
                    if (object.requesterDepartment != null)
                        message.requesterDepartment = String(object.requesterDepartment);
                    if (object.requesterResponsible != null)
                        message.requesterResponsible = String(object.requesterResponsible);
                    if (object.attachments) {
                        if (!Array.isArray(object.attachments))
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.attachments: array expected");
                        message.attachments = [];
                        for (let i = 0; i < object.attachments.length; ++i) {
                            if (typeof object.attachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequest.attachments: object expected");
                            message.attachments[i] = $root.sharelib.Attachment.fromObject(object.attachments[i]);
                        }
                    }
                    if (object.requestingCompany != null) {
                        if (typeof object.requestingCompany !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.requestingCompany: object expected");
                        message.requestingCompany = $root.sharelib.EmbeddedCompany.fromObject(object.requestingCompany);
                    }
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequest.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateRequest} message EstimateRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attachments = [];
                    if (options.defaults) {
                        object.estimateRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestAutoName = options.longs === String ? "0" : 0;
                        object.trackingCode = "";
                        object.displayName = "";
                        object.estimateDeadlineDt = null;
                        object.orderPlanDt = null;
                        object.topPriorityContent = null;
                        object.purpose = null;
                        object.notice = "";
                        object.deliveryPointType = null;
                        object.deliveryAddress = null;
                        object.deliveryPhoneNumber = "";
                        object.deliveryPlace = "";
                        object.deliveryImage = null;
                        object.deliveryAttachment = null;
                        object.requesterDepartment = "";
                        object.requesterResponsible = "";
                        object.requestingCompany = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.orderPlanRemarks = "";
                        object.desiredReceiveType = null;
                        object.desiredReceiveDt = null;
                        object.desiredReceiveRemarks = "";
                        object.deliveryPoint = null;
                        object.contactOrganization = null;
                        object.contactStaff = null;
                    }
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (typeof message.estimateRequestAutoName === "number")
                            object.estimateRequestAutoName = options.longs === String ? String(message.estimateRequestAutoName) : message.estimateRequestAutoName;
                        else
                            object.estimateRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestAutoName.low >>> 0, message.estimateRequestAutoName.high >>> 0).toNumber() : message.estimateRequestAutoName;
                    if (message.trackingCode != null && message.hasOwnProperty("trackingCode"))
                        object.trackingCode = message.trackingCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt"))
                        object.estimateDeadlineDt = $root.sharelib.Datetime.toObject(message.estimateDeadlineDt, options);
                    if (message.orderPlanDt != null && message.hasOwnProperty("orderPlanDt"))
                        object.orderPlanDt = $root.sharelib.Datetime.toObject(message.orderPlanDt, options);
                    if (message.topPriorityContent != null && message.hasOwnProperty("topPriorityContent"))
                        object.topPriorityContent = $root.sharelib.NameOption.toObject(message.topPriorityContent, options);
                    if (message.purpose != null && message.hasOwnProperty("purpose"))
                        object.purpose = $root.sharelib.NameOption.toObject(message.purpose, options);
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        object.notice = message.notice;
                    if (message.deliveryPointType != null && message.hasOwnProperty("deliveryPointType"))
                        object.deliveryPointType = $root.sharelib.NameOption.toObject(message.deliveryPointType, options);
                    if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                        object.deliveryAddress = $root.sharelib.Address.toObject(message.deliveryAddress, options);
                    if (message.deliveryPhoneNumber != null && message.hasOwnProperty("deliveryPhoneNumber"))
                        object.deliveryPhoneNumber = message.deliveryPhoneNumber;
                    if (message.deliveryPlace != null && message.hasOwnProperty("deliveryPlace"))
                        object.deliveryPlace = message.deliveryPlace;
                    if (message.deliveryImage != null && message.hasOwnProperty("deliveryImage"))
                        object.deliveryImage = $root.mtechnavi.api.estimation.ImageFile.toObject(message.deliveryImage, options);
                    if (message.deliveryAttachment != null && message.hasOwnProperty("deliveryAttachment"))
                        object.deliveryAttachment = $root.sharelib.Attachment.toObject(message.deliveryAttachment, options);
                    if (message.requesterDepartment != null && message.hasOwnProperty("requesterDepartment"))
                        object.requesterDepartment = message.requesterDepartment;
                    if (message.requesterResponsible != null && message.hasOwnProperty("requesterResponsible"))
                        object.requesterResponsible = message.requesterResponsible;
                    if (message.attachments && message.attachments.length) {
                        object.attachments = [];
                        for (let j = 0; j < message.attachments.length; ++j)
                            object.attachments[j] = $root.sharelib.Attachment.toObject(message.attachments[j], options);
                    }
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany"))
                        object.requestingCompany = $root.sharelib.EmbeddedCompany.toObject(message.requestingCompany, options);
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.orderPlanRemarks != null && message.hasOwnProperty("orderPlanRemarks"))
                        object.orderPlanRemarks = message.orderPlanRemarks;
                    if (message.desiredReceiveType != null && message.hasOwnProperty("desiredReceiveType"))
                        object.desiredReceiveType = $root.sharelib.NameOption.toObject(message.desiredReceiveType, options);
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt"))
                        object.desiredReceiveDt = $root.sharelib.Datetime.toObject(message.desiredReceiveDt, options);
                    if (message.desiredReceiveRemarks != null && message.hasOwnProperty("desiredReceiveRemarks"))
                        object.desiredReceiveRemarks = message.desiredReceiveRemarks;
                    if (message.deliveryPoint != null && message.hasOwnProperty("deliveryPoint"))
                        object.deliveryPoint = $root.mtechnavi.api.estimation.BusinessUnitBranchReference.toObject(message.deliveryPoint, options);
                    if (message.contactOrganization != null && message.hasOwnProperty("contactOrganization"))
                        object.contactOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.contactOrganization, options);
                    if (message.contactStaff != null && message.hasOwnProperty("contactStaff"))
                        object.contactStaff = $root.sharelib.UserReference.toObject(message.contactStaff, options);
                    return object;
                };

                /**
                 * Converts this EstimateRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateRequest;
            })();

            estimation.EstimateRequestDetail = (function() {

                /**
                 * Properties of an EstimateRequestDetail.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateRequestDetail
                 * @property {string|null} [estimateRequestDetailId] EstimateRequestDetail estimateRequestDetailId
                 * @property {string|null} [estimateRequestId] EstimateRequestDetail estimateRequestId
                 * @property {Long|null} [detailAutoName] EstimateRequestDetail detailAutoName
                 * @property {mtechnavi.api.estimation.ITransactionUnitReference|null} [transactionUnit] EstimateRequestDetail transactionUnit
                 * @property {string|null} [displayName] EstimateRequestDetail displayName
                 * @property {sharelib.INullableQuantityAmount|null} [quantity] EstimateRequestDetail quantity
                 * @property {boolean|null} [inspection] EstimateRequestDetail inspection
                 * @property {mtechnavi.api.estimation.IBlueprintReference|null} [blueprint] EstimateRequestDetail blueprint
                 * @property {sharelib.IAttachment|null} [attachment] EstimateRequestDetail attachment
                 * @property {Array.<sharelib.INameOption>|null} [processs] EstimateRequestDetail processs
                 * @property {string|null} [modelNumber] EstimateRequestDetail modelNumber
                 * @property {string|null} [manufacture] EstimateRequestDetail manufacture
                 * @property {string|null} [contactMatter] EstimateRequestDetail contactMatter
                 * @property {boolean|null} [suppliedParts] EstimateRequestDetail suppliedParts
                 * @property {sharelib.IDatetime|null} [suppliesDt] EstimateRequestDetail suppliesDt
                 * @property {string|null} [suppliesRemarks] EstimateRequestDetail suppliesRemarks
                 * @property {boolean|null} [requiredMaterialCost] EstimateRequestDetail requiredMaterialCost
                 * @property {boolean|null} [requiredProcessingCost] EstimateRequestDetail requiredProcessingCost
                 * @property {boolean|null} [requiredAdministrativeCost] EstimateRequestDetail requiredAdministrativeCost
                 * @property {boolean|null} [requiredFreightCost] EstimateRequestDetail requiredFreightCost
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateRequestDetail sharedProperties
                 * @property {Long|null} [createdAt] EstimateRequestDetail createdAt
                 * @property {Long|null} [updatedAt] EstimateRequestDetail updatedAt
                 * @property {Long|null} [deletedAt] EstimateRequestDetail deletedAt
                 */

                /**
                 * Constructs a new EstimateRequestDetail.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateRequestDetail.
                 * @implements IEstimateRequestDetail
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateRequestDetail=} [properties] Properties to set
                 */
                function EstimateRequestDetail(properties) {
                    this.processs = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateRequestDetail estimateRequestDetailId.
                 * @member {string} estimateRequestDetailId
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.estimateRequestDetailId = "";

                /**
                 * EstimateRequestDetail estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.estimateRequestId = "";

                /**
                 * EstimateRequestDetail detailAutoName.
                 * @member {Long} detailAutoName
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.detailAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestDetail transactionUnit.
                 * @member {mtechnavi.api.estimation.ITransactionUnitReference|null|undefined} transactionUnit
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.transactionUnit = null;

                /**
                 * EstimateRequestDetail displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.displayName = "";

                /**
                 * EstimateRequestDetail quantity.
                 * @member {sharelib.INullableQuantityAmount|null|undefined} quantity
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.quantity = null;

                /**
                 * EstimateRequestDetail inspection.
                 * @member {boolean} inspection
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.inspection = false;

                /**
                 * EstimateRequestDetail blueprint.
                 * @member {mtechnavi.api.estimation.IBlueprintReference|null|undefined} blueprint
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.blueprint = null;

                /**
                 * EstimateRequestDetail attachment.
                 * @member {sharelib.IAttachment|null|undefined} attachment
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.attachment = null;

                /**
                 * EstimateRequestDetail processs.
                 * @member {Array.<sharelib.INameOption>} processs
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.processs = $util.emptyArray;

                /**
                 * EstimateRequestDetail modelNumber.
                 * @member {string} modelNumber
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.modelNumber = "";

                /**
                 * EstimateRequestDetail manufacture.
                 * @member {string} manufacture
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.manufacture = "";

                /**
                 * EstimateRequestDetail contactMatter.
                 * @member {string} contactMatter
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.contactMatter = "";

                /**
                 * EstimateRequestDetail suppliedParts.
                 * @member {boolean} suppliedParts
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.suppliedParts = false;

                /**
                 * EstimateRequestDetail suppliesDt.
                 * @member {sharelib.IDatetime|null|undefined} suppliesDt
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.suppliesDt = null;

                /**
                 * EstimateRequestDetail suppliesRemarks.
                 * @member {string} suppliesRemarks
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.suppliesRemarks = "";

                /**
                 * EstimateRequestDetail requiredMaterialCost.
                 * @member {boolean} requiredMaterialCost
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.requiredMaterialCost = false;

                /**
                 * EstimateRequestDetail requiredProcessingCost.
                 * @member {boolean} requiredProcessingCost
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.requiredProcessingCost = false;

                /**
                 * EstimateRequestDetail requiredAdministrativeCost.
                 * @member {boolean} requiredAdministrativeCost
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.requiredAdministrativeCost = false;

                /**
                 * EstimateRequestDetail requiredFreightCost.
                 * @member {boolean} requiredFreightCost
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.requiredFreightCost = false;

                /**
                 * EstimateRequestDetail sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.sharedProperties = null;

                /**
                 * EstimateRequestDetail createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestDetail updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestDetail deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 */
                EstimateRequestDetail.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateRequestDetail message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateRequestDetail.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestDetailId != null && message.hasOwnProperty("estimateRequestDetailId"))
                        if (!$util.isString(message.estimateRequestDetailId))
                            return "estimateRequestDetailId: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.detailAutoName != null && message.hasOwnProperty("detailAutoName"))
                        if (!$util.isInteger(message.detailAutoName) && !(message.detailAutoName && $util.isInteger(message.detailAutoName.low) && $util.isInteger(message.detailAutoName.high)))
                            return "detailAutoName: integer|Long expected";
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit")) {
                        let error = $root.mtechnavi.api.estimation.TransactionUnitReference.verify(message.transactionUnit);
                        if (error)
                            return "transactionUnit." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.quantity != null && message.hasOwnProperty("quantity")) {
                        let error = $root.sharelib.NullableQuantityAmount.verify(message.quantity);
                        if (error)
                            return "quantity." + error;
                    }
                    if (message.inspection != null && message.hasOwnProperty("inspection"))
                        if (typeof message.inspection !== "boolean")
                            return "inspection: boolean expected";
                    if (message.blueprint != null && message.hasOwnProperty("blueprint")) {
                        let error = $root.mtechnavi.api.estimation.BlueprintReference.verify(message.blueprint);
                        if (error)
                            return "blueprint." + error;
                    }
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.sharelib.Attachment.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.processs != null && message.hasOwnProperty("processs")) {
                        if (!Array.isArray(message.processs))
                            return "processs: array expected";
                        for (let i = 0; i < message.processs.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.processs[i]);
                            if (error)
                                return "processs." + error;
                        }
                    }
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        if (!$util.isString(message.modelNumber))
                            return "modelNumber: string expected";
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        if (!$util.isString(message.manufacture))
                            return "manufacture: string expected";
                    if (message.contactMatter != null && message.hasOwnProperty("contactMatter"))
                        if (!$util.isString(message.contactMatter))
                            return "contactMatter: string expected";
                    if (message.suppliedParts != null && message.hasOwnProperty("suppliedParts"))
                        if (typeof message.suppliedParts !== "boolean")
                            return "suppliedParts: boolean expected";
                    if (message.suppliesDt != null && message.hasOwnProperty("suppliesDt")) {
                        let error = $root.sharelib.Datetime.verify(message.suppliesDt);
                        if (error)
                            return "suppliesDt." + error;
                    }
                    if (message.suppliesRemarks != null && message.hasOwnProperty("suppliesRemarks"))
                        if (!$util.isString(message.suppliesRemarks))
                            return "suppliesRemarks: string expected";
                    if (message.requiredMaterialCost != null && message.hasOwnProperty("requiredMaterialCost"))
                        if (typeof message.requiredMaterialCost !== "boolean")
                            return "requiredMaterialCost: boolean expected";
                    if (message.requiredProcessingCost != null && message.hasOwnProperty("requiredProcessingCost"))
                        if (typeof message.requiredProcessingCost !== "boolean")
                            return "requiredProcessingCost: boolean expected";
                    if (message.requiredAdministrativeCost != null && message.hasOwnProperty("requiredAdministrativeCost"))
                        if (typeof message.requiredAdministrativeCost !== "boolean")
                            return "requiredAdministrativeCost: boolean expected";
                    if (message.requiredFreightCost != null && message.hasOwnProperty("requiredFreightCost"))
                        if (typeof message.requiredFreightCost !== "boolean")
                            return "requiredFreightCost: boolean expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateRequestDetail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateRequestDetail} EstimateRequestDetail
                 */
                EstimateRequestDetail.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestDetail)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateRequestDetail();
                    if (object.estimateRequestDetailId != null)
                        message.estimateRequestDetailId = String(object.estimateRequestDetailId);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.detailAutoName != null)
                        if ($util.Long)
                            (message.detailAutoName = $util.Long.fromValue(object.detailAutoName)).unsigned = false;
                        else if (typeof object.detailAutoName === "string")
                            message.detailAutoName = parseInt(object.detailAutoName, 10);
                        else if (typeof object.detailAutoName === "number")
                            message.detailAutoName = object.detailAutoName;
                        else if (typeof object.detailAutoName === "object")
                            message.detailAutoName = new $util.LongBits(object.detailAutoName.low >>> 0, object.detailAutoName.high >>> 0).toNumber();
                    if (object.transactionUnit != null) {
                        if (typeof object.transactionUnit !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.transactionUnit: object expected");
                        message.transactionUnit = $root.mtechnavi.api.estimation.TransactionUnitReference.fromObject(object.transactionUnit);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.quantity != null) {
                        if (typeof object.quantity !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.quantity: object expected");
                        message.quantity = $root.sharelib.NullableQuantityAmount.fromObject(object.quantity);
                    }
                    if (object.inspection != null)
                        message.inspection = Boolean(object.inspection);
                    if (object.blueprint != null) {
                        if (typeof object.blueprint !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.blueprint: object expected");
                        message.blueprint = $root.mtechnavi.api.estimation.BlueprintReference.fromObject(object.blueprint);
                    }
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.attachment: object expected");
                        message.attachment = $root.sharelib.Attachment.fromObject(object.attachment);
                    }
                    if (object.processs) {
                        if (!Array.isArray(object.processs))
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.processs: array expected");
                        message.processs = [];
                        for (let i = 0; i < object.processs.length; ++i) {
                            if (typeof object.processs[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.processs: object expected");
                            message.processs[i] = $root.sharelib.NameOption.fromObject(object.processs[i]);
                        }
                    }
                    if (object.modelNumber != null)
                        message.modelNumber = String(object.modelNumber);
                    if (object.manufacture != null)
                        message.manufacture = String(object.manufacture);
                    if (object.contactMatter != null)
                        message.contactMatter = String(object.contactMatter);
                    if (object.suppliedParts != null)
                        message.suppliedParts = Boolean(object.suppliedParts);
                    if (object.suppliesDt != null) {
                        if (typeof object.suppliesDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.suppliesDt: object expected");
                        message.suppliesDt = $root.sharelib.Datetime.fromObject(object.suppliesDt);
                    }
                    if (object.suppliesRemarks != null)
                        message.suppliesRemarks = String(object.suppliesRemarks);
                    if (object.requiredMaterialCost != null)
                        message.requiredMaterialCost = Boolean(object.requiredMaterialCost);
                    if (object.requiredProcessingCost != null)
                        message.requiredProcessingCost = Boolean(object.requiredProcessingCost);
                    if (object.requiredAdministrativeCost != null)
                        message.requiredAdministrativeCost = Boolean(object.requiredAdministrativeCost);
                    if (object.requiredFreightCost != null)
                        message.requiredFreightCost = Boolean(object.requiredFreightCost);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestDetail.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateRequestDetail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateRequestDetail} message EstimateRequestDetail
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateRequestDetail.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.processs = [];
                    if (options.defaults) {
                        object.estimateRequestDetailId = "";
                        object.estimateRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.detailAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.detailAutoName = options.longs === String ? "0" : 0;
                        object.transactionUnit = null;
                        object.displayName = "";
                        object.quantity = null;
                        object.inspection = false;
                        object.blueprint = null;
                        object.attachment = null;
                        object.modelNumber = "";
                        object.manufacture = "";
                        object.contactMatter = "";
                        object.suppliedParts = false;
                        object.suppliesDt = null;
                        object.suppliesRemarks = "";
                        object.requiredMaterialCost = false;
                        object.requiredProcessingCost = false;
                        object.requiredAdministrativeCost = false;
                        object.requiredFreightCost = false;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateRequestDetailId != null && message.hasOwnProperty("estimateRequestDetailId"))
                        object.estimateRequestDetailId = message.estimateRequestDetailId;
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.detailAutoName != null && message.hasOwnProperty("detailAutoName"))
                        if (typeof message.detailAutoName === "number")
                            object.detailAutoName = options.longs === String ? String(message.detailAutoName) : message.detailAutoName;
                        else
                            object.detailAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.detailAutoName) : options.longs === Number ? new $util.LongBits(message.detailAutoName.low >>> 0, message.detailAutoName.high >>> 0).toNumber() : message.detailAutoName;
                    if (message.transactionUnit != null && message.hasOwnProperty("transactionUnit"))
                        object.transactionUnit = $root.mtechnavi.api.estimation.TransactionUnitReference.toObject(message.transactionUnit, options);
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        object.quantity = $root.sharelib.NullableQuantityAmount.toObject(message.quantity, options);
                    if (message.inspection != null && message.hasOwnProperty("inspection"))
                        object.inspection = message.inspection;
                    if (message.blueprint != null && message.hasOwnProperty("blueprint"))
                        object.blueprint = $root.mtechnavi.api.estimation.BlueprintReference.toObject(message.blueprint, options);
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.sharelib.Attachment.toObject(message.attachment, options);
                    if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                        object.modelNumber = message.modelNumber;
                    if (message.manufacture != null && message.hasOwnProperty("manufacture"))
                        object.manufacture = message.manufacture;
                    if (message.contactMatter != null && message.hasOwnProperty("contactMatter"))
                        object.contactMatter = message.contactMatter;
                    if (message.suppliedParts != null && message.hasOwnProperty("suppliedParts"))
                        object.suppliedParts = message.suppliedParts;
                    if (message.suppliesDt != null && message.hasOwnProperty("suppliesDt"))
                        object.suppliesDt = $root.sharelib.Datetime.toObject(message.suppliesDt, options);
                    if (message.suppliesRemarks != null && message.hasOwnProperty("suppliesRemarks"))
                        object.suppliesRemarks = message.suppliesRemarks;
                    if (message.requiredMaterialCost != null && message.hasOwnProperty("requiredMaterialCost"))
                        object.requiredMaterialCost = message.requiredMaterialCost;
                    if (message.requiredProcessingCost != null && message.hasOwnProperty("requiredProcessingCost"))
                        object.requiredProcessingCost = message.requiredProcessingCost;
                    if (message.requiredAdministrativeCost != null && message.hasOwnProperty("requiredAdministrativeCost"))
                        object.requiredAdministrativeCost = message.requiredAdministrativeCost;
                    if (message.requiredFreightCost != null && message.hasOwnProperty("requiredFreightCost"))
                        object.requiredFreightCost = message.requiredFreightCost;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.processs && message.processs.length) {
                        object.processs = [];
                        for (let j = 0; j < message.processs.length; ++j)
                            object.processs[j] = $root.sharelib.NameOption.toObject(message.processs[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this EstimateRequestDetail to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateRequestDetail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateRequestDetail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateRequestDetail;
            })();

            estimation.EstimateRequestUnit = (function() {

                /**
                 * Properties of an EstimateRequestUnit.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateRequestUnit
                 * @property {string|null} [estimateRequestUnitId] EstimateRequestUnit estimateRequestUnitId
                 * @property {string|null} [estimateRequestId] EstimateRequestUnit estimateRequestId
                 * @property {string|null} [businessUnitManagementId] EstimateRequestUnit businessUnitManagementId
                 * @property {Array.<string>|null} [businessUnitContactIds] EstimateRequestUnit businessUnitContactIds
                 * @property {string|null} [companyId] EstimateRequestUnit companyId
                 * @property {Long|null} [estimateRequestUnitAutoName] EstimateRequestUnit estimateRequestUnitAutoName
                 * @property {sharelib.INameOption|null} [status] EstimateRequestUnit status
                 * @property {string|null} [code] EstimateRequestUnit code
                 * @property {string|null} [displayName] EstimateRequestUnit displayName
                 * @property {Array.<sharelib.IUserReference>|null} [contactUsers] EstimateRequestUnit contactUsers
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateRequestUnit sharedProperties
                 * @property {Long|null} [createdAt] EstimateRequestUnit createdAt
                 * @property {Long|null} [updatedAt] EstimateRequestUnit updatedAt
                 * @property {Long|null} [deletedAt] EstimateRequestUnit deletedAt
                 */

                /**
                 * Constructs a new EstimateRequestUnit.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateRequestUnit.
                 * @implements IEstimateRequestUnit
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateRequestUnit=} [properties] Properties to set
                 */
                function EstimateRequestUnit(properties) {
                    this.businessUnitContactIds = [];
                    this.contactUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateRequestUnit estimateRequestUnitId.
                 * @member {string} estimateRequestUnitId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.estimateRequestUnitId = "";

                /**
                 * EstimateRequestUnit estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.estimateRequestId = "";

                /**
                 * EstimateRequestUnit businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.businessUnitManagementId = "";

                /**
                 * EstimateRequestUnit businessUnitContactIds.
                 * @member {Array.<string>} businessUnitContactIds
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.businessUnitContactIds = $util.emptyArray;

                /**
                 * EstimateRequestUnit companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.companyId = "";

                /**
                 * EstimateRequestUnit estimateRequestUnitAutoName.
                 * @member {Long} estimateRequestUnitAutoName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.estimateRequestUnitAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnit status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.status = null;

                /**
                 * EstimateRequestUnit code.
                 * @member {string} code
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.code = "";

                /**
                 * EstimateRequestUnit displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.displayName = "";

                /**
                 * EstimateRequestUnit contactUsers.
                 * @member {Array.<sharelib.IUserReference>} contactUsers
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.contactUsers = $util.emptyArray;

                /**
                 * EstimateRequestUnit sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.sharedProperties = null;

                /**
                 * EstimateRequestUnit createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnit updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnit deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 */
                EstimateRequestUnit.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateRequestUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateRequestUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        if (!$util.isString(message.estimateRequestUnitId))
                            return "estimateRequestUnitId: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.businessUnitContactIds != null && message.hasOwnProperty("businessUnitContactIds")) {
                        if (!Array.isArray(message.businessUnitContactIds))
                            return "businessUnitContactIds: array expected";
                        for (let i = 0; i < message.businessUnitContactIds.length; ++i)
                            if (!$util.isString(message.businessUnitContactIds[i]))
                                return "businessUnitContactIds: string[] expected";
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (!$util.isInteger(message.estimateRequestUnitAutoName) && !(message.estimateRequestUnitAutoName && $util.isInteger(message.estimateRequestUnitAutoName.low) && $util.isInteger(message.estimateRequestUnitAutoName.high)))
                            return "estimateRequestUnitAutoName: integer|Long expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.contactUsers != null && message.hasOwnProperty("contactUsers")) {
                        if (!Array.isArray(message.contactUsers))
                            return "contactUsers: array expected";
                        for (let i = 0; i < message.contactUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.contactUsers[i]);
                            if (error)
                                return "contactUsers." + error;
                        }
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateRequestUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateRequestUnit} EstimateRequestUnit
                 */
                EstimateRequestUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestUnit)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateRequestUnit();
                    if (object.estimateRequestUnitId != null)
                        message.estimateRequestUnitId = String(object.estimateRequestUnitId);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.businessUnitContactIds) {
                        if (!Array.isArray(object.businessUnitContactIds))
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnit.businessUnitContactIds: array expected");
                        message.businessUnitContactIds = [];
                        for (let i = 0; i < object.businessUnitContactIds.length; ++i)
                            message.businessUnitContactIds[i] = String(object.businessUnitContactIds[i]);
                    }
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.estimateRequestUnitAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestUnitAutoName = $util.Long.fromValue(object.estimateRequestUnitAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestUnitAutoName === "string")
                            message.estimateRequestUnitAutoName = parseInt(object.estimateRequestUnitAutoName, 10);
                        else if (typeof object.estimateRequestUnitAutoName === "number")
                            message.estimateRequestUnitAutoName = object.estimateRequestUnitAutoName;
                        else if (typeof object.estimateRequestUnitAutoName === "object")
                            message.estimateRequestUnitAutoName = new $util.LongBits(object.estimateRequestUnitAutoName.low >>> 0, object.estimateRequestUnitAutoName.high >>> 0).toNumber();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnit.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.contactUsers) {
                        if (!Array.isArray(object.contactUsers))
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnit.contactUsers: array expected");
                        message.contactUsers = [];
                        for (let i = 0; i < object.contactUsers.length; ++i) {
                            if (typeof object.contactUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnit.contactUsers: object expected");
                            message.contactUsers[i] = $root.sharelib.UserReference.fromObject(object.contactUsers[i]);
                        }
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnit.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateRequestUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateRequestUnit} message EstimateRequestUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateRequestUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.businessUnitContactIds = [];
                        object.contactUsers = [];
                    }
                    if (options.defaults) {
                        object.estimateRequestUnitId = "";
                        object.estimateRequestId = "";
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestUnitAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestUnitAutoName = options.longs === String ? "0" : 0;
                        object.status = null;
                        object.code = "";
                        object.displayName = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateRequestUnitId != null && message.hasOwnProperty("estimateRequestUnitId"))
                        object.estimateRequestUnitId = message.estimateRequestUnitId;
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.businessUnitContactIds && message.businessUnitContactIds.length) {
                        object.businessUnitContactIds = [];
                        for (let j = 0; j < message.businessUnitContactIds.length; ++j)
                            object.businessUnitContactIds[j] = message.businessUnitContactIds[j];
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (typeof message.estimateRequestUnitAutoName === "number")
                            object.estimateRequestUnitAutoName = options.longs === String ? String(message.estimateRequestUnitAutoName) : message.estimateRequestUnitAutoName;
                        else
                            object.estimateRequestUnitAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestUnitAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestUnitAutoName.low >>> 0, message.estimateRequestUnitAutoName.high >>> 0).toNumber() : message.estimateRequestUnitAutoName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.contactUsers && message.contactUsers.length) {
                        object.contactUsers = [];
                        for (let j = 0; j < message.contactUsers.length; ++j)
                            object.contactUsers[j] = $root.sharelib.UserReference.toObject(message.contactUsers[j], options);
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this EstimateRequestUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateRequestUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateRequestUnit;
            })();

            estimation.EstimateManagementSummary = (function() {

                /**
                 * Properties of an EstimateManagementSummary.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateManagementSummary
                 * @property {string|null} [estimateManagementSummaryId] EstimateManagementSummary estimateManagementSummaryId
                 * @property {string|null} [statusSystemName] EstimateManagementSummary statusSystemName
                 * @property {Array.<string>|null} [estimateRequestUnitIds] EstimateManagementSummary estimateRequestUnitIds
                 * @property {sharelib.INameOption|null} [status] EstimateManagementSummary status
                 * @property {Long|null} [estimateRequestAutoName] EstimateManagementSummary estimateRequestAutoName
                 * @property {string|null} [displayName] EstimateManagementSummary displayName
                 * @property {Long|null} [countEstimateRequest] EstimateManagementSummary countEstimateRequest
                 * @property {Long|null} [countEstimateResult] EstimateManagementSummary countEstimateResult
                 * @property {Long|null} [countEstimateResultDecline] EstimateManagementSummary countEstimateResultDecline
                 * @property {Long|null} [countEstimateSelection] EstimateManagementSummary countEstimateSelection
                 * @property {sharelib.IDatetime|null} [estimateDeadlineDt] EstimateManagementSummary estimateDeadlineDt
                 * @property {sharelib.IDatetime|null} [desiredReceiveDt] EstimateManagementSummary desiredReceiveDt
                 * @property {Array.<string>|null} [estimateRequestUnits] EstimateManagementSummary estimateRequestUnits
                 * @property {sharelib.IEmbeddedUser|null} [issuer] EstimateManagementSummary issuer
                 * @property {sharelib.IUserReference|null} [approvalPlanStaff] EstimateManagementSummary approvalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] EstimateManagementSummary approvalPlanOrganization
                 * @property {Long|null} [createdAt] EstimateManagementSummary createdAt
                 * @property {Long|null} [updatedAt] EstimateManagementSummary updatedAt
                 * @property {Long|null} [deletedAt] EstimateManagementSummary deletedAt
                 */

                /**
                 * Constructs a new EstimateManagementSummary.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateManagementSummary.
                 * @implements IEstimateManagementSummary
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateManagementSummary=} [properties] Properties to set
                 */
                function EstimateManagementSummary(properties) {
                    this.estimateRequestUnitIds = [];
                    this.estimateRequestUnits = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateManagementSummary estimateManagementSummaryId.
                 * @member {string} estimateManagementSummaryId
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.estimateManagementSummaryId = "";

                /**
                 * EstimateManagementSummary statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.statusSystemName = "";

                /**
                 * EstimateManagementSummary estimateRequestUnitIds.
                 * @member {Array.<string>} estimateRequestUnitIds
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.estimateRequestUnitIds = $util.emptyArray;

                /**
                 * EstimateManagementSummary status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.status = null;

                /**
                 * EstimateManagementSummary estimateRequestAutoName.
                 * @member {Long} estimateRequestAutoName
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.estimateRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.displayName = "";

                /**
                 * EstimateManagementSummary countEstimateRequest.
                 * @member {Long} countEstimateRequest
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.countEstimateRequest = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary countEstimateResult.
                 * @member {Long} countEstimateResult
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.countEstimateResult = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary countEstimateResultDecline.
                 * @member {Long} countEstimateResultDecline
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.countEstimateResultDecline = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary countEstimateSelection.
                 * @member {Long} countEstimateSelection
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.countEstimateSelection = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary estimateDeadlineDt.
                 * @member {sharelib.IDatetime|null|undefined} estimateDeadlineDt
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.estimateDeadlineDt = null;

                /**
                 * EstimateManagementSummary desiredReceiveDt.
                 * @member {sharelib.IDatetime|null|undefined} desiredReceiveDt
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.desiredReceiveDt = null;

                /**
                 * EstimateManagementSummary estimateRequestUnits.
                 * @member {Array.<string>} estimateRequestUnits
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.estimateRequestUnits = $util.emptyArray;

                /**
                 * EstimateManagementSummary issuer.
                 * @member {sharelib.IEmbeddedUser|null|undefined} issuer
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.issuer = null;

                /**
                 * EstimateManagementSummary approvalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.approvalPlanStaff = null;

                /**
                 * EstimateManagementSummary approvalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.approvalPlanOrganization = null;

                /**
                 * EstimateManagementSummary createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateManagementSummary deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 */
                EstimateManagementSummary.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateManagementSummary message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateManagementSummary.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateManagementSummaryId != null && message.hasOwnProperty("estimateManagementSummaryId"))
                        if (!$util.isString(message.estimateManagementSummaryId))
                            return "estimateManagementSummaryId: string expected";
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.estimateRequestUnitIds != null && message.hasOwnProperty("estimateRequestUnitIds")) {
                        if (!Array.isArray(message.estimateRequestUnitIds))
                            return "estimateRequestUnitIds: array expected";
                        for (let i = 0; i < message.estimateRequestUnitIds.length; ++i)
                            if (!$util.isString(message.estimateRequestUnitIds[i]))
                                return "estimateRequestUnitIds: string[] expected";
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (!$util.isInteger(message.estimateRequestAutoName) && !(message.estimateRequestAutoName && $util.isInteger(message.estimateRequestAutoName.low) && $util.isInteger(message.estimateRequestAutoName.high)))
                            return "estimateRequestAutoName: integer|Long expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.countEstimateRequest != null && message.hasOwnProperty("countEstimateRequest"))
                        if (!$util.isInteger(message.countEstimateRequest) && !(message.countEstimateRequest && $util.isInteger(message.countEstimateRequest.low) && $util.isInteger(message.countEstimateRequest.high)))
                            return "countEstimateRequest: integer|Long expected";
                    if (message.countEstimateResult != null && message.hasOwnProperty("countEstimateResult"))
                        if (!$util.isInteger(message.countEstimateResult) && !(message.countEstimateResult && $util.isInteger(message.countEstimateResult.low) && $util.isInteger(message.countEstimateResult.high)))
                            return "countEstimateResult: integer|Long expected";
                    if (message.countEstimateResultDecline != null && message.hasOwnProperty("countEstimateResultDecline"))
                        if (!$util.isInteger(message.countEstimateResultDecline) && !(message.countEstimateResultDecline && $util.isInteger(message.countEstimateResultDecline.low) && $util.isInteger(message.countEstimateResultDecline.high)))
                            return "countEstimateResultDecline: integer|Long expected";
                    if (message.countEstimateSelection != null && message.hasOwnProperty("countEstimateSelection"))
                        if (!$util.isInteger(message.countEstimateSelection) && !(message.countEstimateSelection && $util.isInteger(message.countEstimateSelection.low) && $util.isInteger(message.countEstimateSelection.high)))
                            return "countEstimateSelection: integer|Long expected";
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt")) {
                        let error = $root.sharelib.Datetime.verify(message.estimateDeadlineDt);
                        if (error)
                            return "estimateDeadlineDt." + error;
                    }
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt")) {
                        let error = $root.sharelib.Datetime.verify(message.desiredReceiveDt);
                        if (error)
                            return "desiredReceiveDt." + error;
                    }
                    if (message.estimateRequestUnits != null && message.hasOwnProperty("estimateRequestUnits")) {
                        if (!Array.isArray(message.estimateRequestUnits))
                            return "estimateRequestUnits: array expected";
                        for (let i = 0; i < message.estimateRequestUnits.length; ++i)
                            if (!$util.isString(message.estimateRequestUnits[i]))
                                return "estimateRequestUnits: string[] expected";
                    }
                    if (message.issuer != null && message.hasOwnProperty("issuer")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.issuer);
                        if (error)
                            return "issuer." + error;
                    }
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                        if (error)
                            return "approvalPlanStaff." + error;
                    }
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                        if (error)
                            return "approvalPlanOrganization." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateManagementSummary message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateManagementSummary} EstimateManagementSummary
                 */
                EstimateManagementSummary.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateManagementSummary)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateManagementSummary();
                    if (object.estimateManagementSummaryId != null)
                        message.estimateManagementSummaryId = String(object.estimateManagementSummaryId);
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.estimateRequestUnitIds) {
                        if (!Array.isArray(object.estimateRequestUnitIds))
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.estimateRequestUnitIds: array expected");
                        message.estimateRequestUnitIds = [];
                        for (let i = 0; i < object.estimateRequestUnitIds.length; ++i)
                            message.estimateRequestUnitIds[i] = String(object.estimateRequestUnitIds[i]);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.estimateRequestAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestAutoName = $util.Long.fromValue(object.estimateRequestAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestAutoName === "string")
                            message.estimateRequestAutoName = parseInt(object.estimateRequestAutoName, 10);
                        else if (typeof object.estimateRequestAutoName === "number")
                            message.estimateRequestAutoName = object.estimateRequestAutoName;
                        else if (typeof object.estimateRequestAutoName === "object")
                            message.estimateRequestAutoName = new $util.LongBits(object.estimateRequestAutoName.low >>> 0, object.estimateRequestAutoName.high >>> 0).toNumber();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.countEstimateRequest != null)
                        if ($util.Long)
                            (message.countEstimateRequest = $util.Long.fromValue(object.countEstimateRequest)).unsigned = false;
                        else if (typeof object.countEstimateRequest === "string")
                            message.countEstimateRequest = parseInt(object.countEstimateRequest, 10);
                        else if (typeof object.countEstimateRequest === "number")
                            message.countEstimateRequest = object.countEstimateRequest;
                        else if (typeof object.countEstimateRequest === "object")
                            message.countEstimateRequest = new $util.LongBits(object.countEstimateRequest.low >>> 0, object.countEstimateRequest.high >>> 0).toNumber();
                    if (object.countEstimateResult != null)
                        if ($util.Long)
                            (message.countEstimateResult = $util.Long.fromValue(object.countEstimateResult)).unsigned = false;
                        else if (typeof object.countEstimateResult === "string")
                            message.countEstimateResult = parseInt(object.countEstimateResult, 10);
                        else if (typeof object.countEstimateResult === "number")
                            message.countEstimateResult = object.countEstimateResult;
                        else if (typeof object.countEstimateResult === "object")
                            message.countEstimateResult = new $util.LongBits(object.countEstimateResult.low >>> 0, object.countEstimateResult.high >>> 0).toNumber();
                    if (object.countEstimateResultDecline != null)
                        if ($util.Long)
                            (message.countEstimateResultDecline = $util.Long.fromValue(object.countEstimateResultDecline)).unsigned = false;
                        else if (typeof object.countEstimateResultDecline === "string")
                            message.countEstimateResultDecline = parseInt(object.countEstimateResultDecline, 10);
                        else if (typeof object.countEstimateResultDecline === "number")
                            message.countEstimateResultDecline = object.countEstimateResultDecline;
                        else if (typeof object.countEstimateResultDecline === "object")
                            message.countEstimateResultDecline = new $util.LongBits(object.countEstimateResultDecline.low >>> 0, object.countEstimateResultDecline.high >>> 0).toNumber();
                    if (object.countEstimateSelection != null)
                        if ($util.Long)
                            (message.countEstimateSelection = $util.Long.fromValue(object.countEstimateSelection)).unsigned = false;
                        else if (typeof object.countEstimateSelection === "string")
                            message.countEstimateSelection = parseInt(object.countEstimateSelection, 10);
                        else if (typeof object.countEstimateSelection === "number")
                            message.countEstimateSelection = object.countEstimateSelection;
                        else if (typeof object.countEstimateSelection === "object")
                            message.countEstimateSelection = new $util.LongBits(object.countEstimateSelection.low >>> 0, object.countEstimateSelection.high >>> 0).toNumber();
                    if (object.estimateDeadlineDt != null) {
                        if (typeof object.estimateDeadlineDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.estimateDeadlineDt: object expected");
                        message.estimateDeadlineDt = $root.sharelib.Datetime.fromObject(object.estimateDeadlineDt);
                    }
                    if (object.desiredReceiveDt != null) {
                        if (typeof object.desiredReceiveDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.desiredReceiveDt: object expected");
                        message.desiredReceiveDt = $root.sharelib.Datetime.fromObject(object.desiredReceiveDt);
                    }
                    if (object.estimateRequestUnits) {
                        if (!Array.isArray(object.estimateRequestUnits))
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.estimateRequestUnits: array expected");
                        message.estimateRequestUnits = [];
                        for (let i = 0; i < object.estimateRequestUnits.length; ++i)
                            message.estimateRequestUnits[i] = String(object.estimateRequestUnits[i]);
                    }
                    if (object.issuer != null) {
                        if (typeof object.issuer !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.issuer: object expected");
                        message.issuer = $root.sharelib.EmbeddedUser.fromObject(object.issuer);
                    }
                    if (object.approvalPlanStaff != null) {
                        if (typeof object.approvalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.approvalPlanStaff: object expected");
                        message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
                    }
                    if (object.approvalPlanOrganization != null) {
                        if (typeof object.approvalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateManagementSummary.approvalPlanOrganization: object expected");
                        message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateManagementSummary message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateManagementSummary} message EstimateManagementSummary
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateManagementSummary.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.estimateRequestUnitIds = [];
                        object.estimateRequestUnits = [];
                    }
                    if (options.defaults) {
                        object.estimateManagementSummaryId = "";
                        object.statusSystemName = "";
                        object.status = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestAutoName = options.longs === String ? "0" : 0;
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.countEstimateRequest = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.countEstimateRequest = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.countEstimateResult = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.countEstimateResult = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.countEstimateResultDecline = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.countEstimateResultDecline = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.countEstimateSelection = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.countEstimateSelection = options.longs === String ? "0" : 0;
                        object.estimateDeadlineDt = null;
                        object.issuer = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.desiredReceiveDt = null;
                        object.approvalPlanStaff = null;
                        object.approvalPlanOrganization = null;
                    }
                    if (message.estimateManagementSummaryId != null && message.hasOwnProperty("estimateManagementSummaryId"))
                        object.estimateManagementSummaryId = message.estimateManagementSummaryId;
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.estimateRequestUnitIds && message.estimateRequestUnitIds.length) {
                        object.estimateRequestUnitIds = [];
                        for (let j = 0; j < message.estimateRequestUnitIds.length; ++j)
                            object.estimateRequestUnitIds[j] = message.estimateRequestUnitIds[j];
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (typeof message.estimateRequestAutoName === "number")
                            object.estimateRequestAutoName = options.longs === String ? String(message.estimateRequestAutoName) : message.estimateRequestAutoName;
                        else
                            object.estimateRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestAutoName.low >>> 0, message.estimateRequestAutoName.high >>> 0).toNumber() : message.estimateRequestAutoName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.countEstimateRequest != null && message.hasOwnProperty("countEstimateRequest"))
                        if (typeof message.countEstimateRequest === "number")
                            object.countEstimateRequest = options.longs === String ? String(message.countEstimateRequest) : message.countEstimateRequest;
                        else
                            object.countEstimateRequest = options.longs === String ? $util.Long.prototype.toString.call(message.countEstimateRequest) : options.longs === Number ? new $util.LongBits(message.countEstimateRequest.low >>> 0, message.countEstimateRequest.high >>> 0).toNumber() : message.countEstimateRequest;
                    if (message.countEstimateResult != null && message.hasOwnProperty("countEstimateResult"))
                        if (typeof message.countEstimateResult === "number")
                            object.countEstimateResult = options.longs === String ? String(message.countEstimateResult) : message.countEstimateResult;
                        else
                            object.countEstimateResult = options.longs === String ? $util.Long.prototype.toString.call(message.countEstimateResult) : options.longs === Number ? new $util.LongBits(message.countEstimateResult.low >>> 0, message.countEstimateResult.high >>> 0).toNumber() : message.countEstimateResult;
                    if (message.countEstimateResultDecline != null && message.hasOwnProperty("countEstimateResultDecline"))
                        if (typeof message.countEstimateResultDecline === "number")
                            object.countEstimateResultDecline = options.longs === String ? String(message.countEstimateResultDecline) : message.countEstimateResultDecline;
                        else
                            object.countEstimateResultDecline = options.longs === String ? $util.Long.prototype.toString.call(message.countEstimateResultDecline) : options.longs === Number ? new $util.LongBits(message.countEstimateResultDecline.low >>> 0, message.countEstimateResultDecline.high >>> 0).toNumber() : message.countEstimateResultDecline;
                    if (message.countEstimateSelection != null && message.hasOwnProperty("countEstimateSelection"))
                        if (typeof message.countEstimateSelection === "number")
                            object.countEstimateSelection = options.longs === String ? String(message.countEstimateSelection) : message.countEstimateSelection;
                        else
                            object.countEstimateSelection = options.longs === String ? $util.Long.prototype.toString.call(message.countEstimateSelection) : options.longs === Number ? new $util.LongBits(message.countEstimateSelection.low >>> 0, message.countEstimateSelection.high >>> 0).toNumber() : message.countEstimateSelection;
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt"))
                        object.estimateDeadlineDt = $root.sharelib.Datetime.toObject(message.estimateDeadlineDt, options);
                    if (message.estimateRequestUnits && message.estimateRequestUnits.length) {
                        object.estimateRequestUnits = [];
                        for (let j = 0; j < message.estimateRequestUnits.length; ++j)
                            object.estimateRequestUnits[j] = message.estimateRequestUnits[j];
                    }
                    if (message.issuer != null && message.hasOwnProperty("issuer"))
                        object.issuer = $root.sharelib.EmbeddedUser.toObject(message.issuer, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt"))
                        object.desiredReceiveDt = $root.sharelib.Datetime.toObject(message.desiredReceiveDt, options);
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                        object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                        object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this EstimateManagementSummary to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateManagementSummary
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateManagementSummary.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateManagementSummary;
            })();

            estimation.EstimateRequestUnitSummary = (function() {

                /**
                 * Properties of an EstimateRequestUnitSummary.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateRequestUnitSummary
                 * @property {string|null} [estimateRequestUnitSummaryId] EstimateRequestUnitSummary estimateRequestUnitSummaryId
                 * @property {string|null} [statusSystemName] EstimateRequestUnitSummary statusSystemName
                 * @property {string|null} [estimateRequestId] EstimateRequestUnitSummary estimateRequestId
                 * @property {Long|null} [estimateRequestAutoName] EstimateRequestUnitSummary estimateRequestAutoName
                 * @property {Long|null} [estimateRequestUnitAutoName] EstimateRequestUnitSummary estimateRequestUnitAutoName
                 * @property {sharelib.INameOption|null} [status] EstimateRequestUnitSummary status
                 * @property {boolean|null} [adoption] EstimateRequestUnitSummary adoption
                 * @property {string|null} [displayName] EstimateRequestUnitSummary displayName
                 * @property {string|null} [estimateRequestUnit] EstimateRequestUnitSummary estimateRequestUnit
                 * @property {sharelib.IDatetime|null} [estimateDeadlineDt] EstimateRequestUnitSummary estimateDeadlineDt
                 * @property {sharelib.INullableMonetaryAmount|null} [amount] EstimateRequestUnitSummary amount
                 * @property {sharelib.IDatetime|null} [deliveryTimeDt] EstimateRequestUnitSummary deliveryTimeDt
                 * @property {Long|null} [replyedAt] EstimateRequestUnitSummary replyedAt
                 * @property {boolean|null} [orderExported] EstimateRequestUnitSummary orderExported
                 * @property {sharelib.IEmbeddedUser|null} [issuer] EstimateRequestUnitSummary issuer
                 * @property {sharelib.INameOption|null} [feedbackCategory] EstimateRequestUnitSummary feedbackCategory
                 * @property {string|null} [feedbackComment] EstimateRequestUnitSummary feedbackComment
                 * @property {sharelib.IUserReference|null} [feedbackApprovalPlanStaff] EstimateRequestUnitSummary feedbackApprovalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [feedbackApprovalPlanOrganization] EstimateRequestUnitSummary feedbackApprovalPlanOrganization
                 * @property {Long|null} [createdAt] EstimateRequestUnitSummary createdAt
                 * @property {Long|null} [updatedAt] EstimateRequestUnitSummary updatedAt
                 * @property {Long|null} [deletedAt] EstimateRequestUnitSummary deletedAt
                 */

                /**
                 * Constructs a new EstimateRequestUnitSummary.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateRequestUnitSummary.
                 * @implements IEstimateRequestUnitSummary
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateRequestUnitSummary=} [properties] Properties to set
                 */
                function EstimateRequestUnitSummary(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateRequestUnitSummary estimateRequestUnitSummaryId.
                 * @member {string} estimateRequestUnitSummaryId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateRequestUnitSummaryId = "";

                /**
                 * EstimateRequestUnitSummary statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.statusSystemName = "";

                /**
                 * EstimateRequestUnitSummary estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateRequestId = "";

                /**
                 * EstimateRequestUnitSummary estimateRequestAutoName.
                 * @member {Long} estimateRequestAutoName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnitSummary estimateRequestUnitAutoName.
                 * @member {Long} estimateRequestUnitAutoName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateRequestUnitAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnitSummary status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.status = null;

                /**
                 * EstimateRequestUnitSummary adoption.
                 * @member {boolean} adoption
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.adoption = false;

                /**
                 * EstimateRequestUnitSummary displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.displayName = "";

                /**
                 * EstimateRequestUnitSummary estimateRequestUnit.
                 * @member {string} estimateRequestUnit
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateRequestUnit = "";

                /**
                 * EstimateRequestUnitSummary estimateDeadlineDt.
                 * @member {sharelib.IDatetime|null|undefined} estimateDeadlineDt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.estimateDeadlineDt = null;

                /**
                 * EstimateRequestUnitSummary amount.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} amount
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.amount = null;

                /**
                 * EstimateRequestUnitSummary deliveryTimeDt.
                 * @member {sharelib.IDatetime|null|undefined} deliveryTimeDt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.deliveryTimeDt = null;

                /**
                 * EstimateRequestUnitSummary replyedAt.
                 * @member {Long} replyedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.replyedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnitSummary orderExported.
                 * @member {boolean} orderExported
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.orderExported = false;

                /**
                 * EstimateRequestUnitSummary issuer.
                 * @member {sharelib.IEmbeddedUser|null|undefined} issuer
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.issuer = null;

                /**
                 * EstimateRequestUnitSummary feedbackCategory.
                 * @member {sharelib.INameOption|null|undefined} feedbackCategory
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.feedbackCategory = null;

                /**
                 * EstimateRequestUnitSummary feedbackComment.
                 * @member {string} feedbackComment
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.feedbackComment = "";

                /**
                 * EstimateRequestUnitSummary feedbackApprovalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} feedbackApprovalPlanStaff
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.feedbackApprovalPlanStaff = null;

                /**
                 * EstimateRequestUnitSummary feedbackApprovalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} feedbackApprovalPlanOrganization
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.feedbackApprovalPlanOrganization = null;

                /**
                 * EstimateRequestUnitSummary createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnitSummary updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateRequestUnitSummary deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 */
                EstimateRequestUnitSummary.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateRequestUnitSummary message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateRequestUnitSummary.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateRequestUnitSummaryId != null && message.hasOwnProperty("estimateRequestUnitSummaryId"))
                        if (!$util.isString(message.estimateRequestUnitSummaryId))
                            return "estimateRequestUnitSummaryId: string expected";
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (!$util.isInteger(message.estimateRequestAutoName) && !(message.estimateRequestAutoName && $util.isInteger(message.estimateRequestAutoName.low) && $util.isInteger(message.estimateRequestAutoName.high)))
                            return "estimateRequestAutoName: integer|Long expected";
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (!$util.isInteger(message.estimateRequestUnitAutoName) && !(message.estimateRequestUnitAutoName && $util.isInteger(message.estimateRequestUnitAutoName.low) && $util.isInteger(message.estimateRequestUnitAutoName.high)))
                            return "estimateRequestUnitAutoName: integer|Long expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        if (typeof message.adoption !== "boolean")
                            return "adoption: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.estimateRequestUnit != null && message.hasOwnProperty("estimateRequestUnit"))
                        if (!$util.isString(message.estimateRequestUnit))
                            return "estimateRequestUnit: string expected";
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt")) {
                        let error = $root.sharelib.Datetime.verify(message.estimateDeadlineDt);
                        if (error)
                            return "estimateDeadlineDt." + error;
                    }
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt")) {
                        let error = $root.sharelib.Datetime.verify(message.deliveryTimeDt);
                        if (error)
                            return "deliveryTimeDt." + error;
                    }
                    if (message.replyedAt != null && message.hasOwnProperty("replyedAt"))
                        if (!$util.isInteger(message.replyedAt) && !(message.replyedAt && $util.isInteger(message.replyedAt.low) && $util.isInteger(message.replyedAt.high)))
                            return "replyedAt: integer|Long expected";
                    if (message.orderExported != null && message.hasOwnProperty("orderExported"))
                        if (typeof message.orderExported !== "boolean")
                            return "orderExported: boolean expected";
                    if (message.issuer != null && message.hasOwnProperty("issuer")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.issuer);
                        if (error)
                            return "issuer." + error;
                    }
                    if (message.feedbackCategory != null && message.hasOwnProperty("feedbackCategory")) {
                        let error = $root.sharelib.NameOption.verify(message.feedbackCategory);
                        if (error)
                            return "feedbackCategory." + error;
                    }
                    if (message.feedbackComment != null && message.hasOwnProperty("feedbackComment"))
                        if (!$util.isString(message.feedbackComment))
                            return "feedbackComment: string expected";
                    if (message.feedbackApprovalPlanStaff != null && message.hasOwnProperty("feedbackApprovalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.feedbackApprovalPlanStaff);
                        if (error)
                            return "feedbackApprovalPlanStaff." + error;
                    }
                    if (message.feedbackApprovalPlanOrganization != null && message.hasOwnProperty("feedbackApprovalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.feedbackApprovalPlanOrganization);
                        if (error)
                            return "feedbackApprovalPlanOrganization." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateRequestUnitSummary message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateRequestUnitSummary} EstimateRequestUnitSummary
                 */
                EstimateRequestUnitSummary.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateRequestUnitSummary)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateRequestUnitSummary();
                    if (object.estimateRequestUnitSummaryId != null)
                        message.estimateRequestUnitSummaryId = String(object.estimateRequestUnitSummaryId);
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.estimateRequestAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestAutoName = $util.Long.fromValue(object.estimateRequestAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestAutoName === "string")
                            message.estimateRequestAutoName = parseInt(object.estimateRequestAutoName, 10);
                        else if (typeof object.estimateRequestAutoName === "number")
                            message.estimateRequestAutoName = object.estimateRequestAutoName;
                        else if (typeof object.estimateRequestAutoName === "object")
                            message.estimateRequestAutoName = new $util.LongBits(object.estimateRequestAutoName.low >>> 0, object.estimateRequestAutoName.high >>> 0).toNumber();
                    if (object.estimateRequestUnitAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestUnitAutoName = $util.Long.fromValue(object.estimateRequestUnitAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestUnitAutoName === "string")
                            message.estimateRequestUnitAutoName = parseInt(object.estimateRequestUnitAutoName, 10);
                        else if (typeof object.estimateRequestUnitAutoName === "number")
                            message.estimateRequestUnitAutoName = object.estimateRequestUnitAutoName;
                        else if (typeof object.estimateRequestUnitAutoName === "object")
                            message.estimateRequestUnitAutoName = new $util.LongBits(object.estimateRequestUnitAutoName.low >>> 0, object.estimateRequestUnitAutoName.high >>> 0).toNumber();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.adoption != null)
                        message.adoption = Boolean(object.adoption);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.estimateRequestUnit != null)
                        message.estimateRequestUnit = String(object.estimateRequestUnit);
                    if (object.estimateDeadlineDt != null) {
                        if (typeof object.estimateDeadlineDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.estimateDeadlineDt: object expected");
                        message.estimateDeadlineDt = $root.sharelib.Datetime.fromObject(object.estimateDeadlineDt);
                    }
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.amount: object expected");
                        message.amount = $root.sharelib.NullableMonetaryAmount.fromObject(object.amount);
                    }
                    if (object.deliveryTimeDt != null) {
                        if (typeof object.deliveryTimeDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.deliveryTimeDt: object expected");
                        message.deliveryTimeDt = $root.sharelib.Datetime.fromObject(object.deliveryTimeDt);
                    }
                    if (object.replyedAt != null)
                        if ($util.Long)
                            (message.replyedAt = $util.Long.fromValue(object.replyedAt)).unsigned = false;
                        else if (typeof object.replyedAt === "string")
                            message.replyedAt = parseInt(object.replyedAt, 10);
                        else if (typeof object.replyedAt === "number")
                            message.replyedAt = object.replyedAt;
                        else if (typeof object.replyedAt === "object")
                            message.replyedAt = new $util.LongBits(object.replyedAt.low >>> 0, object.replyedAt.high >>> 0).toNumber();
                    if (object.orderExported != null)
                        message.orderExported = Boolean(object.orderExported);
                    if (object.issuer != null) {
                        if (typeof object.issuer !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.issuer: object expected");
                        message.issuer = $root.sharelib.EmbeddedUser.fromObject(object.issuer);
                    }
                    if (object.feedbackCategory != null) {
                        if (typeof object.feedbackCategory !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.feedbackCategory: object expected");
                        message.feedbackCategory = $root.sharelib.NameOption.fromObject(object.feedbackCategory);
                    }
                    if (object.feedbackComment != null)
                        message.feedbackComment = String(object.feedbackComment);
                    if (object.feedbackApprovalPlanStaff != null) {
                        if (typeof object.feedbackApprovalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.feedbackApprovalPlanStaff: object expected");
                        message.feedbackApprovalPlanStaff = $root.sharelib.UserReference.fromObject(object.feedbackApprovalPlanStaff);
                    }
                    if (object.feedbackApprovalPlanOrganization != null) {
                        if (typeof object.feedbackApprovalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateRequestUnitSummary.feedbackApprovalPlanOrganization: object expected");
                        message.feedbackApprovalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.feedbackApprovalPlanOrganization);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateRequestUnitSummary message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateRequestUnitSummary} message EstimateRequestUnitSummary
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateRequestUnitSummary.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateRequestUnitSummaryId = "";
                        object.statusSystemName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestUnitAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestUnitAutoName = options.longs === String ? "0" : 0;
                        object.status = null;
                        object.adoption = false;
                        object.displayName = "";
                        object.estimateRequestUnit = "";
                        object.estimateDeadlineDt = null;
                        object.amount = null;
                        object.deliveryTimeDt = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.replyedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.replyedAt = options.longs === String ? "0" : 0;
                        object.orderExported = false;
                        object.issuer = null;
                        object.feedbackCategory = null;
                        object.feedbackComment = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.estimateRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestAutoName = options.longs === String ? "0" : 0;
                        object.feedbackApprovalPlanStaff = null;
                        object.feedbackApprovalPlanOrganization = null;
                    }
                    if (message.estimateRequestUnitSummaryId != null && message.hasOwnProperty("estimateRequestUnitSummaryId"))
                        object.estimateRequestUnitSummaryId = message.estimateRequestUnitSummaryId;
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (typeof message.estimateRequestUnitAutoName === "number")
                            object.estimateRequestUnitAutoName = options.longs === String ? String(message.estimateRequestUnitAutoName) : message.estimateRequestUnitAutoName;
                        else
                            object.estimateRequestUnitAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestUnitAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestUnitAutoName.low >>> 0, message.estimateRequestUnitAutoName.high >>> 0).toNumber() : message.estimateRequestUnitAutoName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        object.adoption = message.adoption;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.estimateRequestUnit != null && message.hasOwnProperty("estimateRequestUnit"))
                        object.estimateRequestUnit = message.estimateRequestUnit;
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt"))
                        object.estimateDeadlineDt = $root.sharelib.Datetime.toObject(message.estimateDeadlineDt, options);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.sharelib.NullableMonetaryAmount.toObject(message.amount, options);
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt"))
                        object.deliveryTimeDt = $root.sharelib.Datetime.toObject(message.deliveryTimeDt, options);
                    if (message.replyedAt != null && message.hasOwnProperty("replyedAt"))
                        if (typeof message.replyedAt === "number")
                            object.replyedAt = options.longs === String ? String(message.replyedAt) : message.replyedAt;
                        else
                            object.replyedAt = options.longs === String ? $util.Long.prototype.toString.call(message.replyedAt) : options.longs === Number ? new $util.LongBits(message.replyedAt.low >>> 0, message.replyedAt.high >>> 0).toNumber() : message.replyedAt;
                    if (message.orderExported != null && message.hasOwnProperty("orderExported"))
                        object.orderExported = message.orderExported;
                    if (message.issuer != null && message.hasOwnProperty("issuer"))
                        object.issuer = $root.sharelib.EmbeddedUser.toObject(message.issuer, options);
                    if (message.feedbackCategory != null && message.hasOwnProperty("feedbackCategory"))
                        object.feedbackCategory = $root.sharelib.NameOption.toObject(message.feedbackCategory, options);
                    if (message.feedbackComment != null && message.hasOwnProperty("feedbackComment"))
                        object.feedbackComment = message.feedbackComment;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.estimateRequestAutoName != null && message.hasOwnProperty("estimateRequestAutoName"))
                        if (typeof message.estimateRequestAutoName === "number")
                            object.estimateRequestAutoName = options.longs === String ? String(message.estimateRequestAutoName) : message.estimateRequestAutoName;
                        else
                            object.estimateRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestAutoName.low >>> 0, message.estimateRequestAutoName.high >>> 0).toNumber() : message.estimateRequestAutoName;
                    if (message.feedbackApprovalPlanStaff != null && message.hasOwnProperty("feedbackApprovalPlanStaff"))
                        object.feedbackApprovalPlanStaff = $root.sharelib.UserReference.toObject(message.feedbackApprovalPlanStaff, options);
                    if (message.feedbackApprovalPlanOrganization != null && message.hasOwnProperty("feedbackApprovalPlanOrganization"))
                        object.feedbackApprovalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.feedbackApprovalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this EstimateRequestUnitSummary to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateRequestUnitSummary
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateRequestUnitSummary.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateRequestUnitSummary;
            })();

            estimation.EstimateSelection = (function() {

                /**
                 * Properties of an EstimateSelection.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateSelection
                 * @property {string|null} [estimateSelectionId] EstimateSelection estimateSelectionId
                 * @property {string|null} [estimateRequestId] EstimateSelection estimateRequestId
                 * @property {boolean|null} [adoption] EstimateSelection adoption
                 * @property {string|null} [remarks] EstimateSelection remarks
                 * @property {boolean|null} [notification] EstimateSelection notification
                 * @property {Long|null} [notificationAt] EstimateSelection notificationAt
                 * @property {sharelib.INameOption|null} [feedbackCategory] EstimateSelection feedbackCategory
                 * @property {string|null} [feedbackComment] EstimateSelection feedbackComment
                 * @property {boolean|null} [orderExported] EstimateSelection orderExported
                 * @property {Long|null} [orderExportedAt] EstimateSelection orderExportedAt
                 * @property {sharelib.INameOption|null} [approvalStatus] EstimateSelection approvalStatus
                 * @property {sharelib.IApprovalRequest|null} [approvalRequest] EstimateSelection approvalRequest
                 * @property {sharelib.IApprovalResult|null} [approvalResult] EstimateSelection approvalResult
                 * @property {Long|null} [sendedAt] EstimateSelection sendedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateSelection sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] EstimateSelection createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] EstimateSelection updatedProperties
                 * @property {Long|null} [createdAt] EstimateSelection createdAt
                 * @property {Long|null} [updatedAt] EstimateSelection updatedAt
                 * @property {Long|null} [deletedAt] EstimateSelection deletedAt
                 */

                /**
                 * Constructs a new EstimateSelection.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateSelection.
                 * @implements IEstimateSelection
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateSelection=} [properties] Properties to set
                 */
                function EstimateSelection(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateSelection estimateSelectionId.
                 * @member {string} estimateSelectionId
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.estimateSelectionId = "";

                /**
                 * EstimateSelection estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.estimateRequestId = "";

                /**
                 * EstimateSelection adoption.
                 * @member {boolean} adoption
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.adoption = false;

                /**
                 * EstimateSelection remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.remarks = "";

                /**
                 * EstimateSelection notification.
                 * @member {boolean} notification
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.notification = false;

                /**
                 * EstimateSelection notificationAt.
                 * @member {Long} notificationAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.notificationAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateSelection feedbackCategory.
                 * @member {sharelib.INameOption|null|undefined} feedbackCategory
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.feedbackCategory = null;

                /**
                 * EstimateSelection feedbackComment.
                 * @member {string} feedbackComment
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.feedbackComment = "";

                /**
                 * EstimateSelection orderExported.
                 * @member {boolean} orderExported
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.orderExported = false;

                /**
                 * EstimateSelection orderExportedAt.
                 * @member {Long} orderExportedAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.orderExportedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateSelection approvalStatus.
                 * @member {sharelib.INameOption|null|undefined} approvalStatus
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.approvalStatus = null;

                /**
                 * EstimateSelection approvalRequest.
                 * @member {sharelib.IApprovalRequest|null|undefined} approvalRequest
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.approvalRequest = null;

                /**
                 * EstimateSelection approvalResult.
                 * @member {sharelib.IApprovalResult|null|undefined} approvalResult
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.approvalResult = null;

                /**
                 * EstimateSelection sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateSelection sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.sharedProperties = null;

                /**
                 * EstimateSelection createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.createdProperties = null;

                /**
                 * EstimateSelection updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.updatedProperties = null;

                /**
                 * EstimateSelection createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateSelection updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateSelection deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 */
                EstimateSelection.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateSelection message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateSelection.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        if (!$util.isString(message.estimateSelectionId))
                            return "estimateSelectionId: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        if (typeof message.adoption !== "boolean")
                            return "adoption: boolean expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.notification != null && message.hasOwnProperty("notification"))
                        if (typeof message.notification !== "boolean")
                            return "notification: boolean expected";
                    if (message.notificationAt != null && message.hasOwnProperty("notificationAt"))
                        if (!$util.isInteger(message.notificationAt) && !(message.notificationAt && $util.isInteger(message.notificationAt.low) && $util.isInteger(message.notificationAt.high)))
                            return "notificationAt: integer|Long expected";
                    if (message.feedbackCategory != null && message.hasOwnProperty("feedbackCategory")) {
                        let error = $root.sharelib.NameOption.verify(message.feedbackCategory);
                        if (error)
                            return "feedbackCategory." + error;
                    }
                    if (message.feedbackComment != null && message.hasOwnProperty("feedbackComment"))
                        if (!$util.isString(message.feedbackComment))
                            return "feedbackComment: string expected";
                    if (message.orderExported != null && message.hasOwnProperty("orderExported"))
                        if (typeof message.orderExported !== "boolean")
                            return "orderExported: boolean expected";
                    if (message.orderExportedAt != null && message.hasOwnProperty("orderExportedAt"))
                        if (!$util.isInteger(message.orderExportedAt) && !(message.orderExportedAt && $util.isInteger(message.orderExportedAt.low) && $util.isInteger(message.orderExportedAt.high)))
                            return "orderExportedAt: integer|Long expected";
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.approvalStatus);
                        if (error)
                            return "approvalStatus." + error;
                    }
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest")) {
                        let error = $root.sharelib.ApprovalRequest.verify(message.approvalRequest);
                        if (error)
                            return "approvalRequest." + error;
                    }
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult")) {
                        let error = $root.sharelib.ApprovalResult.verify(message.approvalResult);
                        if (error)
                            return "approvalResult." + error;
                    }
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateSelection message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateSelection} EstimateSelection
                 */
                EstimateSelection.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateSelection)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateSelection();
                    if (object.estimateSelectionId != null)
                        message.estimateSelectionId = String(object.estimateSelectionId);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.adoption != null)
                        message.adoption = Boolean(object.adoption);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.notification != null)
                        message.notification = Boolean(object.notification);
                    if (object.notificationAt != null)
                        if ($util.Long)
                            (message.notificationAt = $util.Long.fromValue(object.notificationAt)).unsigned = false;
                        else if (typeof object.notificationAt === "string")
                            message.notificationAt = parseInt(object.notificationAt, 10);
                        else if (typeof object.notificationAt === "number")
                            message.notificationAt = object.notificationAt;
                        else if (typeof object.notificationAt === "object")
                            message.notificationAt = new $util.LongBits(object.notificationAt.low >>> 0, object.notificationAt.high >>> 0).toNumber();
                    if (object.feedbackCategory != null) {
                        if (typeof object.feedbackCategory !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.feedbackCategory: object expected");
                        message.feedbackCategory = $root.sharelib.NameOption.fromObject(object.feedbackCategory);
                    }
                    if (object.feedbackComment != null)
                        message.feedbackComment = String(object.feedbackComment);
                    if (object.orderExported != null)
                        message.orderExported = Boolean(object.orderExported);
                    if (object.orderExportedAt != null)
                        if ($util.Long)
                            (message.orderExportedAt = $util.Long.fromValue(object.orderExportedAt)).unsigned = false;
                        else if (typeof object.orderExportedAt === "string")
                            message.orderExportedAt = parseInt(object.orderExportedAt, 10);
                        else if (typeof object.orderExportedAt === "number")
                            message.orderExportedAt = object.orderExportedAt;
                        else if (typeof object.orderExportedAt === "object")
                            message.orderExportedAt = new $util.LongBits(object.orderExportedAt.low >>> 0, object.orderExportedAt.high >>> 0).toNumber();
                    if (object.approvalStatus != null) {
                        if (typeof object.approvalStatus !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.approvalStatus: object expected");
                        message.approvalStatus = $root.sharelib.NameOption.fromObject(object.approvalStatus);
                    }
                    if (object.approvalRequest != null) {
                        if (typeof object.approvalRequest !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.approvalRequest: object expected");
                        message.approvalRequest = $root.sharelib.ApprovalRequest.fromObject(object.approvalRequest);
                    }
                    if (object.approvalResult != null) {
                        if (typeof object.approvalResult !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.approvalResult: object expected");
                        message.approvalResult = $root.sharelib.ApprovalResult.fromObject(object.approvalResult);
                    }
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateSelection.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateSelection message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateSelection} message EstimateSelection
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateSelection.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateSelectionId = "";
                        object.estimateRequestId = "";
                        object.adoption = false;
                        object.remarks = "";
                        object.notification = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.notificationAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.notificationAt = options.longs === String ? "0" : 0;
                        object.feedbackCategory = null;
                        object.feedbackComment = "";
                        object.orderExported = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.orderExportedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.orderExportedAt = options.longs === String ? "0" : 0;
                        object.approvalStatus = null;
                        object.approvalRequest = null;
                        object.approvalResult = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateSelectionId != null && message.hasOwnProperty("estimateSelectionId"))
                        object.estimateSelectionId = message.estimateSelectionId;
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        object.adoption = message.adoption;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.notification != null && message.hasOwnProperty("notification"))
                        object.notification = message.notification;
                    if (message.notificationAt != null && message.hasOwnProperty("notificationAt"))
                        if (typeof message.notificationAt === "number")
                            object.notificationAt = options.longs === String ? String(message.notificationAt) : message.notificationAt;
                        else
                            object.notificationAt = options.longs === String ? $util.Long.prototype.toString.call(message.notificationAt) : options.longs === Number ? new $util.LongBits(message.notificationAt.low >>> 0, message.notificationAt.high >>> 0).toNumber() : message.notificationAt;
                    if (message.feedbackCategory != null && message.hasOwnProperty("feedbackCategory"))
                        object.feedbackCategory = $root.sharelib.NameOption.toObject(message.feedbackCategory, options);
                    if (message.feedbackComment != null && message.hasOwnProperty("feedbackComment"))
                        object.feedbackComment = message.feedbackComment;
                    if (message.orderExported != null && message.hasOwnProperty("orderExported"))
                        object.orderExported = message.orderExported;
                    if (message.orderExportedAt != null && message.hasOwnProperty("orderExportedAt"))
                        if (typeof message.orderExportedAt === "number")
                            object.orderExportedAt = options.longs === String ? String(message.orderExportedAt) : message.orderExportedAt;
                        else
                            object.orderExportedAt = options.longs === String ? $util.Long.prototype.toString.call(message.orderExportedAt) : options.longs === Number ? new $util.LongBits(message.orderExportedAt.low >>> 0, message.orderExportedAt.high >>> 0).toNumber() : message.orderExportedAt;
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        object.approvalStatus = $root.sharelib.NameOption.toObject(message.approvalStatus, options);
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest"))
                        object.approvalRequest = $root.sharelib.ApprovalRequest.toObject(message.approvalRequest, options);
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult"))
                        object.approvalResult = $root.sharelib.ApprovalResult.toObject(message.approvalResult, options);
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this EstimateSelection to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateSelection
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateSelection.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateSelection;
            })();

            estimation.EstimateResultChangeRequest = (function() {

                /**
                 * Properties of an EstimateResultChangeRequest.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateResultChangeRequest
                 * @property {string|null} [estimateResultChangeRequestId] EstimateResultChangeRequest estimateResultChangeRequestId
                 * @property {string|null} [estimateResultId] EstimateResultChangeRequest estimateResultId
                 * @property {Long|null} [requestedAt] EstimateResultChangeRequest requestedAt
                 * @property {string|null} [comment] EstimateResultChangeRequest comment
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateResultChangeRequest sharedProperties
                 * @property {Long|null} [createdAt] EstimateResultChangeRequest createdAt
                 * @property {Long|null} [updatedAt] EstimateResultChangeRequest updatedAt
                 * @property {Long|null} [deletedAt] EstimateResultChangeRequest deletedAt
                 */

                /**
                 * Constructs a new EstimateResultChangeRequest.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateResultChangeRequest.
                 * @implements IEstimateResultChangeRequest
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateResultChangeRequest=} [properties] Properties to set
                 */
                function EstimateResultChangeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateResultChangeRequest estimateResultChangeRequestId.
                 * @member {string} estimateResultChangeRequestId
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.estimateResultChangeRequestId = "";

                /**
                 * EstimateResultChangeRequest estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.estimateResultId = "";

                /**
                 * EstimateResultChangeRequest requestedAt.
                 * @member {Long} requestedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.requestedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultChangeRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.comment = "";

                /**
                 * EstimateResultChangeRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.sharedProperties = null;

                /**
                 * EstimateResultChangeRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultChangeRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultChangeRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 */
                EstimateResultChangeRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateResultChangeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateResultChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultChangeRequestId != null && message.hasOwnProperty("estimateResultChangeRequestId"))
                        if (!$util.isString(message.estimateResultChangeRequestId))
                            return "estimateResultChangeRequestId: string expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (!$util.isInteger(message.requestedAt) && !(message.requestedAt && $util.isInteger(message.requestedAt.low) && $util.isInteger(message.requestedAt.high)))
                            return "requestedAt: integer|Long expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateResultChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateResultChangeRequest} EstimateResultChangeRequest
                 */
                EstimateResultChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateResultChangeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateResultChangeRequest();
                    if (object.estimateResultChangeRequestId != null)
                        message.estimateResultChangeRequestId = String(object.estimateResultChangeRequestId);
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.requestedAt != null)
                        if ($util.Long)
                            (message.requestedAt = $util.Long.fromValue(object.requestedAt)).unsigned = false;
                        else if (typeof object.requestedAt === "string")
                            message.requestedAt = parseInt(object.requestedAt, 10);
                        else if (typeof object.requestedAt === "number")
                            message.requestedAt = object.requestedAt;
                        else if (typeof object.requestedAt === "object")
                            message.requestedAt = new $util.LongBits(object.requestedAt.low >>> 0, object.requestedAt.high >>> 0).toNumber();
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultChangeRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateResultChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateResultChangeRequest} message EstimateResultChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateResultChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateResultChangeRequestId = "";
                        object.estimateResultId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.requestedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestedAt = options.longs === String ? "0" : 0;
                        object.comment = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateResultChangeRequestId != null && message.hasOwnProperty("estimateResultChangeRequestId"))
                        object.estimateResultChangeRequestId = message.estimateResultChangeRequestId;
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (typeof message.requestedAt === "number")
                            object.requestedAt = options.longs === String ? String(message.requestedAt) : message.requestedAt;
                        else
                            object.requestedAt = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAt) : options.longs === Number ? new $util.LongBits(message.requestedAt.low >>> 0, message.requestedAt.high >>> 0).toNumber() : message.requestedAt;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this EstimateResultChangeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateResultChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateResultChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateResultChangeRequest;
            })();

            estimation.EstimateResult = (function() {

                /**
                 * Properties of an EstimateResult.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateResult
                 * @property {string|null} [estimateResultId] EstimateResult estimateResultId
                 * @property {string|null} [estimateRequestId] EstimateResult estimateRequestId
                 * @property {mtechnavi.api.estimation.IEstimateRequest|null} [estimateRequest] EstimateResult estimateRequest
                 * @property {mtechnavi.api.estimation.IEstimateRequestUnit|null} [estimateRequestUnit] EstimateResult estimateRequestUnit
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] EstimateResult systemNotificationUsers
                 * @property {sharelib.INameOption|null} [status] EstimateResult status
                 * @property {sharelib.IDatetime|null} [deliveryTimeDt] EstimateResult deliveryTimeDt
                 * @property {string|null} [deliveryTimeRemarks] EstimateResult deliveryTimeRemarks
                 * @property {string|null} [leadTimeNum] EstimateResult leadTimeNum
                 * @property {sharelib.INameOption|null} [leadTimeUnit] EstimateResult leadTimeUnit
                 * @property {sharelib.IDatetime|null} [estimateExpireDt] EstimateResult estimateExpireDt
                 * @property {string|null} [estimateRemarks] EstimateResult estimateRemarks
                 * @property {string|null} [remarks] EstimateResult remarks
                 * @property {boolean|null} [declined] EstimateResult declined
                 * @property {string|null} [comment] EstimateResult comment
                 * @property {Long|null} [sendedAt] EstimateResult sendedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateResult sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] EstimateResult createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] EstimateResult updatedProperties
                 * @property {Long|null} [createdAt] EstimateResult createdAt
                 * @property {Long|null} [updatedAt] EstimateResult updatedAt
                 * @property {Long|null} [deletedAt] EstimateResult deletedAt
                 */

                /**
                 * Constructs a new EstimateResult.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateResult.
                 * @implements IEstimateResult
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateResult=} [properties] Properties to set
                 */
                function EstimateResult(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateResult estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateResultId = "";

                /**
                 * EstimateResult estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateRequestId = "";

                /**
                 * EstimateResult estimateRequest.
                 * @member {mtechnavi.api.estimation.IEstimateRequest|null|undefined} estimateRequest
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateRequest = null;

                /**
                 * EstimateResult estimateRequestUnit.
                 * @member {mtechnavi.api.estimation.IEstimateRequestUnit|null|undefined} estimateRequestUnit
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateRequestUnit = null;

                /**
                 * EstimateResult systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * EstimateResult status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.status = null;

                /**
                 * EstimateResult deliveryTimeDt.
                 * @member {sharelib.IDatetime|null|undefined} deliveryTimeDt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.deliveryTimeDt = null;

                /**
                 * EstimateResult deliveryTimeRemarks.
                 * @member {string} deliveryTimeRemarks
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.deliveryTimeRemarks = "";

                /**
                 * EstimateResult leadTimeNum.
                 * @member {string} leadTimeNum
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.leadTimeNum = "";

                /**
                 * EstimateResult leadTimeUnit.
                 * @member {sharelib.INameOption|null|undefined} leadTimeUnit
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.leadTimeUnit = null;

                /**
                 * EstimateResult estimateExpireDt.
                 * @member {sharelib.IDatetime|null|undefined} estimateExpireDt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateExpireDt = null;

                /**
                 * EstimateResult estimateRemarks.
                 * @member {string} estimateRemarks
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.estimateRemarks = "";

                /**
                 * EstimateResult remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.remarks = "";

                /**
                 * EstimateResult declined.
                 * @member {boolean} declined
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.declined = false;

                /**
                 * EstimateResult comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.comment = "";

                /**
                 * EstimateResult sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResult sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.sharedProperties = null;

                /**
                 * EstimateResult createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.createdProperties = null;

                /**
                 * EstimateResult updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.updatedProperties = null;

                /**
                 * EstimateResult createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResult updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResult deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 */
                EstimateResult.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateResult message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.estimateRequest != null && message.hasOwnProperty("estimateRequest")) {
                        let error = $root.mtechnavi.api.estimation.EstimateRequest.verify(message.estimateRequest);
                        if (error)
                            return "estimateRequest." + error;
                    }
                    if (message.estimateRequestUnit != null && message.hasOwnProperty("estimateRequestUnit")) {
                        let error = $root.mtechnavi.api.estimation.EstimateRequestUnit.verify(message.estimateRequestUnit);
                        if (error)
                            return "estimateRequestUnit." + error;
                    }
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt")) {
                        let error = $root.sharelib.Datetime.verify(message.deliveryTimeDt);
                        if (error)
                            return "deliveryTimeDt." + error;
                    }
                    if (message.deliveryTimeRemarks != null && message.hasOwnProperty("deliveryTimeRemarks"))
                        if (!$util.isString(message.deliveryTimeRemarks))
                            return "deliveryTimeRemarks: string expected";
                    if (message.leadTimeNum != null && message.hasOwnProperty("leadTimeNum"))
                        if (!$util.isString(message.leadTimeNum))
                            return "leadTimeNum: string expected";
                    if (message.leadTimeUnit != null && message.hasOwnProperty("leadTimeUnit")) {
                        let error = $root.sharelib.NameOption.verify(message.leadTimeUnit);
                        if (error)
                            return "leadTimeUnit." + error;
                    }
                    if (message.estimateExpireDt != null && message.hasOwnProperty("estimateExpireDt")) {
                        let error = $root.sharelib.Datetime.verify(message.estimateExpireDt);
                        if (error)
                            return "estimateExpireDt." + error;
                    }
                    if (message.estimateRemarks != null && message.hasOwnProperty("estimateRemarks"))
                        if (!$util.isString(message.estimateRemarks))
                            return "estimateRemarks: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        if (typeof message.declined !== "boolean")
                            return "declined: boolean expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateResult} EstimateResult
                 */
                EstimateResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateResult)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateResult();
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.estimateRequest != null) {
                        if (typeof object.estimateRequest !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.estimateRequest: object expected");
                        message.estimateRequest = $root.mtechnavi.api.estimation.EstimateRequest.fromObject(object.estimateRequest);
                    }
                    if (object.estimateRequestUnit != null) {
                        if (typeof object.estimateRequestUnit !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.estimateRequestUnit: object expected");
                        message.estimateRequestUnit = $root.mtechnavi.api.estimation.EstimateRequestUnit.fromObject(object.estimateRequestUnit);
                    }
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.estimation.EstimateResult.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.deliveryTimeDt != null) {
                        if (typeof object.deliveryTimeDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.deliveryTimeDt: object expected");
                        message.deliveryTimeDt = $root.sharelib.Datetime.fromObject(object.deliveryTimeDt);
                    }
                    if (object.deliveryTimeRemarks != null)
                        message.deliveryTimeRemarks = String(object.deliveryTimeRemarks);
                    if (object.leadTimeNum != null)
                        message.leadTimeNum = String(object.leadTimeNum);
                    if (object.leadTimeUnit != null) {
                        if (typeof object.leadTimeUnit !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.leadTimeUnit: object expected");
                        message.leadTimeUnit = $root.sharelib.NameOption.fromObject(object.leadTimeUnit);
                    }
                    if (object.estimateExpireDt != null) {
                        if (typeof object.estimateExpireDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.estimateExpireDt: object expected");
                        message.estimateExpireDt = $root.sharelib.Datetime.fromObject(object.estimateExpireDt);
                    }
                    if (object.estimateRemarks != null)
                        message.estimateRemarks = String(object.estimateRemarks);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.declined != null)
                        message.declined = Boolean(object.declined);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResult.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateResult} message EstimateResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.estimateResultId = "";
                        object.estimateRequest = null;
                        object.status = null;
                        object.deliveryTimeDt = null;
                        object.deliveryTimeRemarks = "";
                        object.leadTimeNum = "";
                        object.leadTimeUnit = null;
                        object.estimateExpireDt = null;
                        object.estimateRemarks = "";
                        object.remarks = "";
                        object.declined = false;
                        object.comment = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.estimateRequestId = "";
                        object.estimateRequestUnit = null;
                    }
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.estimateRequest != null && message.hasOwnProperty("estimateRequest"))
                        object.estimateRequest = $root.mtechnavi.api.estimation.EstimateRequest.toObject(message.estimateRequest, options);
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt"))
                        object.deliveryTimeDt = $root.sharelib.Datetime.toObject(message.deliveryTimeDt, options);
                    if (message.deliveryTimeRemarks != null && message.hasOwnProperty("deliveryTimeRemarks"))
                        object.deliveryTimeRemarks = message.deliveryTimeRemarks;
                    if (message.leadTimeNum != null && message.hasOwnProperty("leadTimeNum"))
                        object.leadTimeNum = message.leadTimeNum;
                    if (message.leadTimeUnit != null && message.hasOwnProperty("leadTimeUnit"))
                        object.leadTimeUnit = $root.sharelib.NameOption.toObject(message.leadTimeUnit, options);
                    if (message.estimateExpireDt != null && message.hasOwnProperty("estimateExpireDt"))
                        object.estimateExpireDt = $root.sharelib.Datetime.toObject(message.estimateExpireDt, options);
                    if (message.estimateRemarks != null && message.hasOwnProperty("estimateRemarks"))
                        object.estimateRemarks = message.estimateRemarks;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        object.declined = message.declined;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    if (message.estimateRequestUnit != null && message.hasOwnProperty("estimateRequestUnit"))
                        object.estimateRequestUnit = $root.mtechnavi.api.estimation.EstimateRequestUnit.toObject(message.estimateRequestUnit, options);
                    return object;
                };

                /**
                 * Converts this EstimateResult to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateResult;
            })();

            estimation.EstimateResultDetail = (function() {

                /**
                 * Properties of an EstimateResultDetail.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateResultDetail
                 * @property {string|null} [estimateResultDetailId] EstimateResultDetail estimateResultDetailId
                 * @property {string|null} [estimateResultId] EstimateResultDetail estimateResultId
                 * @property {string|null} [estimateRequestDetailId] EstimateResultDetail estimateRequestDetailId
                 * @property {mtechnavi.api.estimation.IEstimateRequestDetail|null} [estimateRequestDetail] EstimateResultDetail estimateRequestDetail
                 * @property {sharelib.INullableMonetaryAmount|null} [amount] EstimateResultDetail amount
                 * @property {sharelib.INullableMonetaryAmount|null} [unitPrice] EstimateResultDetail unitPrice
                 * @property {sharelib.INullableMonetaryAmount|null} [materialCost] EstimateResultDetail materialCost
                 * @property {sharelib.INullableMonetaryAmount|null} [processingCost] EstimateResultDetail processingCost
                 * @property {sharelib.INullableMonetaryAmount|null} [administrativeCost] EstimateResultDetail administrativeCost
                 * @property {sharelib.INullableMonetaryAmount|null} [freightCost] EstimateResultDetail freightCost
                 * @property {sharelib.INullableMonetaryAmount|null} [otherCost] EstimateResultDetail otherCost
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] EstimateResultDetail sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] EstimateResultDetail createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] EstimateResultDetail updatedProperties
                 * @property {Long|null} [createdAt] EstimateResultDetail createdAt
                 * @property {Long|null} [updatedAt] EstimateResultDetail updatedAt
                 * @property {Long|null} [deletedAt] EstimateResultDetail deletedAt
                 */

                /**
                 * Constructs a new EstimateResultDetail.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateResultDetail.
                 * @implements IEstimateResultDetail
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateResultDetail=} [properties] Properties to set
                 */
                function EstimateResultDetail(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateResultDetail estimateResultDetailId.
                 * @member {string} estimateResultDetailId
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.estimateResultDetailId = "";

                /**
                 * EstimateResultDetail estimateResultId.
                 * @member {string} estimateResultId
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.estimateResultId = "";

                /**
                 * EstimateResultDetail estimateRequestDetailId.
                 * @member {string} estimateRequestDetailId
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.estimateRequestDetailId = "";

                /**
                 * EstimateResultDetail estimateRequestDetail.
                 * @member {mtechnavi.api.estimation.IEstimateRequestDetail|null|undefined} estimateRequestDetail
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.estimateRequestDetail = null;

                /**
                 * EstimateResultDetail amount.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} amount
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.amount = null;

                /**
                 * EstimateResultDetail unitPrice.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} unitPrice
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.unitPrice = null;

                /**
                 * EstimateResultDetail materialCost.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} materialCost
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.materialCost = null;

                /**
                 * EstimateResultDetail processingCost.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} processingCost
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.processingCost = null;

                /**
                 * EstimateResultDetail administrativeCost.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} administrativeCost
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.administrativeCost = null;

                /**
                 * EstimateResultDetail freightCost.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} freightCost
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.freightCost = null;

                /**
                 * EstimateResultDetail otherCost.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} otherCost
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.otherCost = null;

                /**
                 * EstimateResultDetail sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.sharedProperties = null;

                /**
                 * EstimateResultDetail createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.createdProperties = null;

                /**
                 * EstimateResultDetail updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.updatedProperties = null;

                /**
                 * EstimateResultDetail createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultDetail updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultDetail deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 */
                EstimateResultDetail.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateResultDetail message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateResultDetail.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultDetailId != null && message.hasOwnProperty("estimateResultDetailId"))
                        if (!$util.isString(message.estimateResultDetailId))
                            return "estimateResultDetailId: string expected";
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        if (!$util.isString(message.estimateResultId))
                            return "estimateResultId: string expected";
                    if (message.estimateRequestDetailId != null && message.hasOwnProperty("estimateRequestDetailId"))
                        if (!$util.isString(message.estimateRequestDetailId))
                            return "estimateRequestDetailId: string expected";
                    if (message.estimateRequestDetail != null && message.hasOwnProperty("estimateRequestDetail")) {
                        let error = $root.mtechnavi.api.estimation.EstimateRequestDetail.verify(message.estimateRequestDetail);
                        if (error)
                            return "estimateRequestDetail." + error;
                    }
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.unitPrice != null && message.hasOwnProperty("unitPrice")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.unitPrice);
                        if (error)
                            return "unitPrice." + error;
                    }
                    if (message.materialCost != null && message.hasOwnProperty("materialCost")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.materialCost);
                        if (error)
                            return "materialCost." + error;
                    }
                    if (message.processingCost != null && message.hasOwnProperty("processingCost")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.processingCost);
                        if (error)
                            return "processingCost." + error;
                    }
                    if (message.administrativeCost != null && message.hasOwnProperty("administrativeCost")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.administrativeCost);
                        if (error)
                            return "administrativeCost." + error;
                    }
                    if (message.freightCost != null && message.hasOwnProperty("freightCost")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.freightCost);
                        if (error)
                            return "freightCost." + error;
                    }
                    if (message.otherCost != null && message.hasOwnProperty("otherCost")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.otherCost);
                        if (error)
                            return "otherCost." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateResultDetail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateResultDetail} EstimateResultDetail
                 */
                EstimateResultDetail.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateResultDetail)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateResultDetail();
                    if (object.estimateResultDetailId != null)
                        message.estimateResultDetailId = String(object.estimateResultDetailId);
                    if (object.estimateResultId != null)
                        message.estimateResultId = String(object.estimateResultId);
                    if (object.estimateRequestDetailId != null)
                        message.estimateRequestDetailId = String(object.estimateRequestDetailId);
                    if (object.estimateRequestDetail != null) {
                        if (typeof object.estimateRequestDetail !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.estimateRequestDetail: object expected");
                        message.estimateRequestDetail = $root.mtechnavi.api.estimation.EstimateRequestDetail.fromObject(object.estimateRequestDetail);
                    }
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.amount: object expected");
                        message.amount = $root.sharelib.NullableMonetaryAmount.fromObject(object.amount);
                    }
                    if (object.unitPrice != null) {
                        if (typeof object.unitPrice !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.unitPrice: object expected");
                        message.unitPrice = $root.sharelib.NullableMonetaryAmount.fromObject(object.unitPrice);
                    }
                    if (object.materialCost != null) {
                        if (typeof object.materialCost !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.materialCost: object expected");
                        message.materialCost = $root.sharelib.NullableMonetaryAmount.fromObject(object.materialCost);
                    }
                    if (object.processingCost != null) {
                        if (typeof object.processingCost !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.processingCost: object expected");
                        message.processingCost = $root.sharelib.NullableMonetaryAmount.fromObject(object.processingCost);
                    }
                    if (object.administrativeCost != null) {
                        if (typeof object.administrativeCost !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.administrativeCost: object expected");
                        message.administrativeCost = $root.sharelib.NullableMonetaryAmount.fromObject(object.administrativeCost);
                    }
                    if (object.freightCost != null) {
                        if (typeof object.freightCost !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.freightCost: object expected");
                        message.freightCost = $root.sharelib.NullableMonetaryAmount.fromObject(object.freightCost);
                    }
                    if (object.otherCost != null) {
                        if (typeof object.otherCost !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.otherCost: object expected");
                        message.otherCost = $root.sharelib.NullableMonetaryAmount.fromObject(object.otherCost);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultDetail.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateResultDetail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateResultDetail} message EstimateResultDetail
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateResultDetail.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateResultDetailId = "";
                        object.estimateResultId = "";
                        object.estimateRequestDetailId = "";
                        object.estimateRequestDetail = null;
                        object.amount = null;
                        object.unitPrice = null;
                        object.materialCost = null;
                        object.processingCost = null;
                        object.administrativeCost = null;
                        object.freightCost = null;
                        object.otherCost = null;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.estimateResultDetailId != null && message.hasOwnProperty("estimateResultDetailId"))
                        object.estimateResultDetailId = message.estimateResultDetailId;
                    if (message.estimateResultId != null && message.hasOwnProperty("estimateResultId"))
                        object.estimateResultId = message.estimateResultId;
                    if (message.estimateRequestDetailId != null && message.hasOwnProperty("estimateRequestDetailId"))
                        object.estimateRequestDetailId = message.estimateRequestDetailId;
                    if (message.estimateRequestDetail != null && message.hasOwnProperty("estimateRequestDetail"))
                        object.estimateRequestDetail = $root.mtechnavi.api.estimation.EstimateRequestDetail.toObject(message.estimateRequestDetail, options);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.sharelib.NullableMonetaryAmount.toObject(message.amount, options);
                    if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                        object.unitPrice = $root.sharelib.NullableMonetaryAmount.toObject(message.unitPrice, options);
                    if (message.materialCost != null && message.hasOwnProperty("materialCost"))
                        object.materialCost = $root.sharelib.NullableMonetaryAmount.toObject(message.materialCost, options);
                    if (message.processingCost != null && message.hasOwnProperty("processingCost"))
                        object.processingCost = $root.sharelib.NullableMonetaryAmount.toObject(message.processingCost, options);
                    if (message.administrativeCost != null && message.hasOwnProperty("administrativeCost"))
                        object.administrativeCost = $root.sharelib.NullableMonetaryAmount.toObject(message.administrativeCost, options);
                    if (message.freightCost != null && message.hasOwnProperty("freightCost"))
                        object.freightCost = $root.sharelib.NullableMonetaryAmount.toObject(message.freightCost, options);
                    if (message.otherCost != null && message.hasOwnProperty("otherCost"))
                        object.otherCost = $root.sharelib.NullableMonetaryAmount.toObject(message.otherCost, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this EstimateResultDetail to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateResultDetail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateResultDetail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateResultDetail;
            })();

            estimation.EstimateResultSummary = (function() {

                /**
                 * Properties of an EstimateResultSummary.
                 * @memberof mtechnavi.api.estimation
                 * @interface IEstimateResultSummary
                 * @property {string|null} [estimateResultSummaryId] EstimateResultSummary estimateResultSummaryId
                 * @property {string|null} [estimateRequestId] EstimateResultSummary estimateRequestId
                 * @property {string|null} [statusSystemName] EstimateResultSummary statusSystemName
                 * @property {Long|null} [estimateRequestUnitAutoName] EstimateResultSummary estimateRequestUnitAutoName
                 * @property {sharelib.INameOption|null} [status] EstimateResultSummary status
                 * @property {boolean|null} [adoption] EstimateResultSummary adoption
                 * @property {sharelib.IDatetime|null} [estimateDeadlineDt] EstimateResultSummary estimateDeadlineDt
                 * @property {string|null} [displayName] EstimateResultSummary displayName
                 * @property {sharelib.IDatetime|null} [desiredReceiveDt] EstimateResultSummary desiredReceiveDt
                 * @property {sharelib.IDatetime|null} [deliveryTimeDt] EstimateResultSummary deliveryTimeDt
                 * @property {sharelib.IEmbeddedCompany|null} [requestingCompany] EstimateResultSummary requestingCompany
                 * @property {sharelib.INullableMonetaryAmount|null} [amount] EstimateResultSummary amount
                 * @property {Long|null} [createdAt] EstimateResultSummary createdAt
                 * @property {Long|null} [updatedAt] EstimateResultSummary updatedAt
                 * @property {Long|null} [deletedAt] EstimateResultSummary deletedAt
                 */

                /**
                 * Constructs a new EstimateResultSummary.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an EstimateResultSummary.
                 * @implements IEstimateResultSummary
                 * @constructor
                 * @param {mtechnavi.api.estimation.IEstimateResultSummary=} [properties] Properties to set
                 */
                function EstimateResultSummary(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EstimateResultSummary estimateResultSummaryId.
                 * @member {string} estimateResultSummaryId
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.estimateResultSummaryId = "";

                /**
                 * EstimateResultSummary estimateRequestId.
                 * @member {string} estimateRequestId
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.estimateRequestId = "";

                /**
                 * EstimateResultSummary statusSystemName.
                 * @member {string} statusSystemName
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.statusSystemName = "";

                /**
                 * EstimateResultSummary estimateRequestUnitAutoName.
                 * @member {Long} estimateRequestUnitAutoName
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.estimateRequestUnitAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultSummary status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.status = null;

                /**
                 * EstimateResultSummary adoption.
                 * @member {boolean} adoption
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.adoption = false;

                /**
                 * EstimateResultSummary estimateDeadlineDt.
                 * @member {sharelib.IDatetime|null|undefined} estimateDeadlineDt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.estimateDeadlineDt = null;

                /**
                 * EstimateResultSummary displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.displayName = "";

                /**
                 * EstimateResultSummary desiredReceiveDt.
                 * @member {sharelib.IDatetime|null|undefined} desiredReceiveDt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.desiredReceiveDt = null;

                /**
                 * EstimateResultSummary deliveryTimeDt.
                 * @member {sharelib.IDatetime|null|undefined} deliveryTimeDt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.deliveryTimeDt = null;

                /**
                 * EstimateResultSummary requestingCompany.
                 * @member {sharelib.IEmbeddedCompany|null|undefined} requestingCompany
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.requestingCompany = null;

                /**
                 * EstimateResultSummary amount.
                 * @member {sharelib.INullableMonetaryAmount|null|undefined} amount
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.amount = null;

                /**
                 * EstimateResultSummary createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultSummary updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * EstimateResultSummary deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 */
                EstimateResultSummary.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an EstimateResultSummary message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EstimateResultSummary.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.estimateResultSummaryId != null && message.hasOwnProperty("estimateResultSummaryId"))
                        if (!$util.isString(message.estimateResultSummaryId))
                            return "estimateResultSummaryId: string expected";
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        if (!$util.isString(message.estimateRequestId))
                            return "estimateRequestId: string expected";
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        if (!$util.isString(message.statusSystemName))
                            return "statusSystemName: string expected";
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (!$util.isInteger(message.estimateRequestUnitAutoName) && !(message.estimateRequestUnitAutoName && $util.isInteger(message.estimateRequestUnitAutoName.low) && $util.isInteger(message.estimateRequestUnitAutoName.high)))
                            return "estimateRequestUnitAutoName: integer|Long expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        if (typeof message.adoption !== "boolean")
                            return "adoption: boolean expected";
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt")) {
                        let error = $root.sharelib.Datetime.verify(message.estimateDeadlineDt);
                        if (error)
                            return "estimateDeadlineDt." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt")) {
                        let error = $root.sharelib.Datetime.verify(message.desiredReceiveDt);
                        if (error)
                            return "desiredReceiveDt." + error;
                    }
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt")) {
                        let error = $root.sharelib.Datetime.verify(message.deliveryTimeDt);
                        if (error)
                            return "deliveryTimeDt." + error;
                    }
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany")) {
                        let error = $root.sharelib.EmbeddedCompany.verify(message.requestingCompany);
                        if (error)
                            return "requestingCompany." + error;
                    }
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        let error = $root.sharelib.NullableMonetaryAmount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an EstimateResultSummary message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.EstimateResultSummary} EstimateResultSummary
                 */
                EstimateResultSummary.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.EstimateResultSummary)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.EstimateResultSummary();
                    if (object.estimateResultSummaryId != null)
                        message.estimateResultSummaryId = String(object.estimateResultSummaryId);
                    if (object.estimateRequestId != null)
                        message.estimateRequestId = String(object.estimateRequestId);
                    if (object.statusSystemName != null)
                        message.statusSystemName = String(object.statusSystemName);
                    if (object.estimateRequestUnitAutoName != null)
                        if ($util.Long)
                            (message.estimateRequestUnitAutoName = $util.Long.fromValue(object.estimateRequestUnitAutoName)).unsigned = false;
                        else if (typeof object.estimateRequestUnitAutoName === "string")
                            message.estimateRequestUnitAutoName = parseInt(object.estimateRequestUnitAutoName, 10);
                        else if (typeof object.estimateRequestUnitAutoName === "number")
                            message.estimateRequestUnitAutoName = object.estimateRequestUnitAutoName;
                        else if (typeof object.estimateRequestUnitAutoName === "object")
                            message.estimateRequestUnitAutoName = new $util.LongBits(object.estimateRequestUnitAutoName.low >>> 0, object.estimateRequestUnitAutoName.high >>> 0).toNumber();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.adoption != null)
                        message.adoption = Boolean(object.adoption);
                    if (object.estimateDeadlineDt != null) {
                        if (typeof object.estimateDeadlineDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.estimateDeadlineDt: object expected");
                        message.estimateDeadlineDt = $root.sharelib.Datetime.fromObject(object.estimateDeadlineDt);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.desiredReceiveDt != null) {
                        if (typeof object.desiredReceiveDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.desiredReceiveDt: object expected");
                        message.desiredReceiveDt = $root.sharelib.Datetime.fromObject(object.desiredReceiveDt);
                    }
                    if (object.deliveryTimeDt != null) {
                        if (typeof object.deliveryTimeDt !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.deliveryTimeDt: object expected");
                        message.deliveryTimeDt = $root.sharelib.Datetime.fromObject(object.deliveryTimeDt);
                    }
                    if (object.requestingCompany != null) {
                        if (typeof object.requestingCompany !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.requestingCompany: object expected");
                        message.requestingCompany = $root.sharelib.EmbeddedCompany.fromObject(object.requestingCompany);
                    }
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".mtechnavi.api.estimation.EstimateResultSummary.amount: object expected");
                        message.amount = $root.sharelib.NullableMonetaryAmount.fromObject(object.amount);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an EstimateResultSummary message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @static
                 * @param {mtechnavi.api.estimation.EstimateResultSummary} message EstimateResultSummary
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EstimateResultSummary.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.estimateResultSummaryId = "";
                        object.statusSystemName = "";
                        object.status = null;
                        object.adoption = false;
                        object.estimateDeadlineDt = null;
                        object.displayName = "";
                        object.deliveryTimeDt = null;
                        object.requestingCompany = null;
                        object.amount = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.estimateRequestUnitAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.estimateRequestUnitAutoName = options.longs === String ? "0" : 0;
                        object.desiredReceiveDt = null;
                        object.estimateRequestId = "";
                    }
                    if (message.estimateResultSummaryId != null && message.hasOwnProperty("estimateResultSummaryId"))
                        object.estimateResultSummaryId = message.estimateResultSummaryId;
                    if (message.statusSystemName != null && message.hasOwnProperty("statusSystemName"))
                        object.statusSystemName = message.statusSystemName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.adoption != null && message.hasOwnProperty("adoption"))
                        object.adoption = message.adoption;
                    if (message.estimateDeadlineDt != null && message.hasOwnProperty("estimateDeadlineDt"))
                        object.estimateDeadlineDt = $root.sharelib.Datetime.toObject(message.estimateDeadlineDt, options);
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.deliveryTimeDt != null && message.hasOwnProperty("deliveryTimeDt"))
                        object.deliveryTimeDt = $root.sharelib.Datetime.toObject(message.deliveryTimeDt, options);
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany"))
                        object.requestingCompany = $root.sharelib.EmbeddedCompany.toObject(message.requestingCompany, options);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.sharelib.NullableMonetaryAmount.toObject(message.amount, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.estimateRequestUnitAutoName != null && message.hasOwnProperty("estimateRequestUnitAutoName"))
                        if (typeof message.estimateRequestUnitAutoName === "number")
                            object.estimateRequestUnitAutoName = options.longs === String ? String(message.estimateRequestUnitAutoName) : message.estimateRequestUnitAutoName;
                        else
                            object.estimateRequestUnitAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.estimateRequestUnitAutoName) : options.longs === Number ? new $util.LongBits(message.estimateRequestUnitAutoName.low >>> 0, message.estimateRequestUnitAutoName.high >>> 0).toNumber() : message.estimateRequestUnitAutoName;
                    if (message.desiredReceiveDt != null && message.hasOwnProperty("desiredReceiveDt"))
                        object.desiredReceiveDt = $root.sharelib.Datetime.toObject(message.desiredReceiveDt, options);
                    if (message.estimateRequestId != null && message.hasOwnProperty("estimateRequestId"))
                        object.estimateRequestId = message.estimateRequestId;
                    return object;
                };

                /**
                 * Converts this EstimateResultSummary to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.EstimateResultSummary
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EstimateResultSummary.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EstimateResultSummary;
            })();

            estimation.History = (function() {

                /**
                 * Properties of a History.
                 * @memberof mtechnavi.api.estimation
                 * @interface IHistory
                 * @property {string|null} [historyId] History historyId
                 * @property {string|null} [urn] History urn
                 * @property {string|null} [typeName] History typeName
                 * @property {string|null} [recordId] History recordId
                 * @property {string|null} [historyCategoryName] History historyCategoryName
                 * @property {string|null} [contentName] History contentName
                 * @property {Array.<string>|null} [contentParamaters] History contentParamaters
                 * @property {Long|null} [occurredAt] History occurredAt
                 * @property {Long|null} [level] History level
                 * @property {Long|null} [createdAt] History createdAt
                 * @property {Long|null} [updatedAt] History updatedAt
                 * @property {Long|null} [deletedAt] History deletedAt
                 */

                /**
                 * Constructs a new History.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a History.
                 * @implements IHistory
                 * @constructor
                 * @param {mtechnavi.api.estimation.IHistory=} [properties] Properties to set
                 */
                function History(properties) {
                    this.contentParamaters = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * History historyId.
                 * @member {string} historyId
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.historyId = "";

                /**
                 * History urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.urn = "";

                /**
                 * History typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.typeName = "";

                /**
                 * History recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.recordId = "";

                /**
                 * History historyCategoryName.
                 * @member {string} historyCategoryName
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.historyCategoryName = "";

                /**
                 * History contentName.
                 * @member {string} contentName
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.contentName = "";

                /**
                 * History contentParamaters.
                 * @member {Array.<string>} contentParamaters
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.contentParamaters = $util.emptyArray;

                /**
                 * History occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 */
                History.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a History message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.History
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                History.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        if (!$util.isString(message.historyId))
                            return "historyId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        if (!$util.isString(message.historyCategoryName))
                            return "historyCategoryName: string expected";
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        if (!$util.isString(message.contentName))
                            return "contentName: string expected";
                    if (message.contentParamaters != null && message.hasOwnProperty("contentParamaters")) {
                        if (!Array.isArray(message.contentParamaters))
                            return "contentParamaters: array expected";
                        for (let i = 0; i < message.contentParamaters.length; ++i)
                            if (!$util.isString(message.contentParamaters[i]))
                                return "contentParamaters: string[] expected";
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a History message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.History
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.History} History
                 */
                History.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.History)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.History();
                    if (object.historyId != null)
                        message.historyId = String(object.historyId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.historyCategoryName != null)
                        message.historyCategoryName = String(object.historyCategoryName);
                    if (object.contentName != null)
                        message.contentName = String(object.contentName);
                    if (object.contentParamaters) {
                        if (!Array.isArray(object.contentParamaters))
                            throw TypeError(".mtechnavi.api.estimation.History.contentParamaters: array expected");
                        message.contentParamaters = [];
                        for (let i = 0; i < object.contentParamaters.length; ++i)
                            message.contentParamaters[i] = String(object.contentParamaters[i]);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a History message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.History
                 * @static
                 * @param {mtechnavi.api.estimation.History} message History
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                History.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.contentParamaters = [];
                    if (options.defaults) {
                        object.historyId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.historyCategoryName = "";
                        object.contentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        object.historyId = message.historyId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        object.historyCategoryName = message.historyCategoryName;
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        object.contentName = message.contentName;
                    if (message.contentParamaters && message.contentParamaters.length) {
                        object.contentParamaters = [];
                        for (let j = 0; j < message.contentParamaters.length; ++j)
                            object.contentParamaters[j] = message.contentParamaters[j];
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this History to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.History
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                History.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return History;
            })();

            estimation.Discard = (function() {

                /**
                 * Properties of a Discard.
                 * @memberof mtechnavi.api.estimation
                 * @interface IDiscard
                 * @property {string|null} [discardId] Discard discardId
                 * @property {string|null} [urn] Discard urn
                 * @property {string|null} [typeName] Discard typeName
                 * @property {string|null} [recordId] Discard recordId
                 * @property {string|null} [comment] Discard comment
                 * @property {Long|null} [discardedAt] Discard discardedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Discard sharedProperties
                 * @property {Long|null} [createdAt] Discard createdAt
                 * @property {Long|null} [updatedAt] Discard updatedAt
                 * @property {Long|null} [deletedAt] Discard deletedAt
                 */

                /**
                 * Constructs a new Discard.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a Discard.
                 * @implements IDiscard
                 * @constructor
                 * @param {mtechnavi.api.estimation.IDiscard=} [properties] Properties to set
                 */
                function Discard(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Discard discardId.
                 * @member {string} discardId
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.discardId = "";

                /**
                 * Discard urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.urn = "";

                /**
                 * Discard typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.typeName = "";

                /**
                 * Discard recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.recordId = "";

                /**
                 * Discard comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.comment = "";

                /**
                 * Discard discardedAt.
                 * @member {Long} discardedAt
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.discardedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.sharedProperties = null;

                /**
                 * Discard createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 */
                Discard.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Discard message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.Discard
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Discard.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discardId != null && message.hasOwnProperty("discardId"))
                        if (!$util.isString(message.discardId))
                            return "discardId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.discardedAt != null && message.hasOwnProperty("discardedAt"))
                        if (!$util.isInteger(message.discardedAt) && !(message.discardedAt && $util.isInteger(message.discardedAt.low) && $util.isInteger(message.discardedAt.high)))
                            return "discardedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Discard message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.Discard
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.Discard} Discard
                 */
                Discard.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.Discard)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.Discard();
                    if (object.discardId != null)
                        message.discardId = String(object.discardId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.discardedAt != null)
                        if ($util.Long)
                            (message.discardedAt = $util.Long.fromValue(object.discardedAt)).unsigned = false;
                        else if (typeof object.discardedAt === "string")
                            message.discardedAt = parseInt(object.discardedAt, 10);
                        else if (typeof object.discardedAt === "number")
                            message.discardedAt = object.discardedAt;
                        else if (typeof object.discardedAt === "object")
                            message.discardedAt = new $util.LongBits(object.discardedAt.low >>> 0, object.discardedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.Discard.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Discard message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.Discard
                 * @static
                 * @param {mtechnavi.api.estimation.Discard} message Discard
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Discard.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.discardId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.comment = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.discardedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.discardedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.discardId != null && message.hasOwnProperty("discardId"))
                        object.discardId = message.discardId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.discardedAt != null && message.hasOwnProperty("discardedAt"))
                        if (typeof message.discardedAt === "number")
                            object.discardedAt = options.longs === String ? String(message.discardedAt) : message.discardedAt;
                        else
                            object.discardedAt = options.longs === String ? $util.Long.prototype.toString.call(message.discardedAt) : options.longs === Number ? new $util.LongBits(message.discardedAt.low >>> 0, message.discardedAt.high >>> 0).toNumber() : message.discardedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Discard to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.Discard
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Discard.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Discard;
            })();

            estimation.Complete = (function() {

                /**
                 * Properties of a Complete.
                 * @memberof mtechnavi.api.estimation
                 * @interface IComplete
                 * @property {string|null} [completeId] Complete completeId
                 * @property {string|null} [urn] Complete urn
                 * @property {string|null} [typeName] Complete typeName
                 * @property {string|null} [recordId] Complete recordId
                 * @property {string|null} [comment] Complete comment
                 * @property {Long|null} [completedAt] Complete completedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Complete sharedProperties
                 * @property {Long|null} [createdAt] Complete createdAt
                 * @property {Long|null} [updatedAt] Complete updatedAt
                 * @property {Long|null} [deletedAt] Complete deletedAt
                 */

                /**
                 * Constructs a new Complete.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a Complete.
                 * @implements IComplete
                 * @constructor
                 * @param {mtechnavi.api.estimation.IComplete=} [properties] Properties to set
                 */
                function Complete(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Complete completeId.
                 * @member {string} completeId
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.completeId = "";

                /**
                 * Complete urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.urn = "";

                /**
                 * Complete typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.typeName = "";

                /**
                 * Complete recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.recordId = "";

                /**
                 * Complete comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.comment = "";

                /**
                 * Complete completedAt.
                 * @member {Long} completedAt
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.completedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.sharedProperties = null;

                /**
                 * Complete createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 */
                Complete.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Complete message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.Complete
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Complete.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.completeId != null && message.hasOwnProperty("completeId"))
                        if (!$util.isString(message.completeId))
                            return "completeId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (!$util.isInteger(message.completedAt) && !(message.completedAt && $util.isInteger(message.completedAt.low) && $util.isInteger(message.completedAt.high)))
                            return "completedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Complete message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.Complete
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.Complete} Complete
                 */
                Complete.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.Complete)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.Complete();
                    if (object.completeId != null)
                        message.completeId = String(object.completeId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.completedAt != null)
                        if ($util.Long)
                            (message.completedAt = $util.Long.fromValue(object.completedAt)).unsigned = false;
                        else if (typeof object.completedAt === "string")
                            message.completedAt = parseInt(object.completedAt, 10);
                        else if (typeof object.completedAt === "number")
                            message.completedAt = object.completedAt;
                        else if (typeof object.completedAt === "object")
                            message.completedAt = new $util.LongBits(object.completedAt.low >>> 0, object.completedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.Complete.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Complete message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.Complete
                 * @static
                 * @param {mtechnavi.api.estimation.Complete} message Complete
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Complete.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.completeId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.completedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.completedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.comment = "";
                    }
                    if (message.completeId != null && message.hasOwnProperty("completeId"))
                        object.completeId = message.completeId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (typeof message.completedAt === "number")
                            object.completedAt = options.longs === String ? String(message.completedAt) : message.completedAt;
                        else
                            object.completedAt = options.longs === String ? $util.Long.prototype.toString.call(message.completedAt) : options.longs === Number ? new $util.LongBits(message.completedAt.low >>> 0, message.completedAt.high >>> 0).toNumber() : message.completedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this Complete to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.Complete
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Complete.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Complete;
            })();

            estimation.Opened = (function() {

                /**
                 * Properties of an Opened.
                 * @memberof mtechnavi.api.estimation
                 * @interface IOpened
                 * @property {string|null} [openedId] Opened openedId
                 * @property {string|null} [urn] Opened urn
                 * @property {string|null} [typeName] Opened typeName
                 * @property {string|null} [recordId] Opened recordId
                 * @property {mtechnavi.api.estimation.Opened.IProcess|null} [openUserProcess] Opened openUserProcess
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Opened sharedProperties
                 * @property {Long|null} [createdAt] Opened createdAt
                 * @property {Long|null} [updatedAt] Opened updatedAt
                 * @property {Long|null} [deletedAt] Opened deletedAt
                 */

                /**
                 * Constructs a new Opened.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an Opened.
                 * @implements IOpened
                 * @constructor
                 * @param {mtechnavi.api.estimation.IOpened=} [properties] Properties to set
                 */
                function Opened(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Opened openedId.
                 * @member {string} openedId
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.openedId = "";

                /**
                 * Opened urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.urn = "";

                /**
                 * Opened typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.typeName = "";

                /**
                 * Opened recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.recordId = "";

                /**
                 * Opened openUserProcess.
                 * @member {mtechnavi.api.estimation.Opened.IProcess|null|undefined} openUserProcess
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.openUserProcess = null;

                /**
                 * Opened sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.sharedProperties = null;

                /**
                 * Opened createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Opened updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Opened deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 */
                Opened.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Opened message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.Opened
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Opened.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.openedId != null && message.hasOwnProperty("openedId"))
                        if (!$util.isString(message.openedId))
                            return "openedId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.openUserProcess != null && message.hasOwnProperty("openUserProcess")) {
                        let error = $root.mtechnavi.api.estimation.Opened.Process.verify(message.openUserProcess);
                        if (error)
                            return "openUserProcess." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Opened message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.Opened
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.Opened} Opened
                 */
                Opened.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.Opened)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.Opened();
                    if (object.openedId != null)
                        message.openedId = String(object.openedId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.openUserProcess != null) {
                        if (typeof object.openUserProcess !== "object")
                            throw TypeError(".mtechnavi.api.estimation.Opened.openUserProcess: object expected");
                        message.openUserProcess = $root.mtechnavi.api.estimation.Opened.Process.fromObject(object.openUserProcess);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.estimation.Opened.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Opened message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.Opened
                 * @static
                 * @param {mtechnavi.api.estimation.Opened} message Opened
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Opened.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.openedId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.openUserProcess = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.openedId != null && message.hasOwnProperty("openedId"))
                        object.openedId = message.openedId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.openUserProcess != null && message.hasOwnProperty("openUserProcess"))
                        object.openUserProcess = $root.mtechnavi.api.estimation.Opened.Process.toObject(message.openUserProcess, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Opened to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.Opened
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Opened.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Opened.Process = (function() {

                    /**
                     * Properties of a Process.
                     * @memberof mtechnavi.api.estimation.Opened
                     * @interface IProcess
                     * @property {Long|null} [processedAt] Process processedAt
                     * @property {sharelib.IEmbeddedUser|null} [processedBy] Process processedBy
                     */

                    /**
                     * Constructs a new Process.
                     * @memberof mtechnavi.api.estimation.Opened
                     * @classdesc Represents a Process.
                     * @implements IProcess
                     * @constructor
                     * @param {mtechnavi.api.estimation.Opened.IProcess=} [properties] Properties to set
                     */
                    function Process(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Process processedAt.
                     * @member {Long} processedAt
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @instance
                     */
                    Process.prototype.processedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Process processedBy.
                     * @member {sharelib.IEmbeddedUser|null|undefined} processedBy
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @instance
                     */
                    Process.prototype.processedBy = null;

                    /**
                     * Verifies a Process message.
                     * @function verify
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Process.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                            if (!$util.isInteger(message.processedAt) && !(message.processedAt && $util.isInteger(message.processedAt.low) && $util.isInteger(message.processedAt.high)))
                                return "processedAt: integer|Long expected";
                        if (message.processedBy != null && message.hasOwnProperty("processedBy")) {
                            let error = $root.sharelib.EmbeddedUser.verify(message.processedBy);
                            if (error)
                                return "processedBy." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Process message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.estimation.Opened.Process} Process
                     */
                    Process.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.estimation.Opened.Process)
                            return object;
                        let message = new $root.mtechnavi.api.estimation.Opened.Process();
                        if (object.processedAt != null)
                            if ($util.Long)
                                (message.processedAt = $util.Long.fromValue(object.processedAt)).unsigned = false;
                            else if (typeof object.processedAt === "string")
                                message.processedAt = parseInt(object.processedAt, 10);
                            else if (typeof object.processedAt === "number")
                                message.processedAt = object.processedAt;
                            else if (typeof object.processedAt === "object")
                                message.processedAt = new $util.LongBits(object.processedAt.low >>> 0, object.processedAt.high >>> 0).toNumber();
                        if (object.processedBy != null) {
                            if (typeof object.processedBy !== "object")
                                throw TypeError(".mtechnavi.api.estimation.Opened.Process.processedBy: object expected");
                            message.processedBy = $root.sharelib.EmbeddedUser.fromObject(object.processedBy);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Process message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @static
                     * @param {mtechnavi.api.estimation.Opened.Process} message Process
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Process.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.processedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.processedAt = options.longs === String ? "0" : 0;
                            object.processedBy = null;
                        }
                        if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                            if (typeof message.processedAt === "number")
                                object.processedAt = options.longs === String ? String(message.processedAt) : message.processedAt;
                            else
                                object.processedAt = options.longs === String ? $util.Long.prototype.toString.call(message.processedAt) : options.longs === Number ? new $util.LongBits(message.processedAt.low >>> 0, message.processedAt.high >>> 0).toNumber() : message.processedAt;
                        if (message.processedBy != null && message.hasOwnProperty("processedBy"))
                            object.processedBy = $root.sharelib.EmbeddedUser.toObject(message.processedBy, options);
                        return object;
                    };

                    /**
                     * Converts this Process to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.estimation.Opened.Process
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Process.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Process;
                })();

                return Opened;
            })();

            estimation.ImageFile = (function() {

                /**
                 * Properties of an ImageFile.
                 * @memberof mtechnavi.api.estimation
                 * @interface IImageFile
                 * @property {string|null} [assetId] ImageFile assetId
                 */

                /**
                 * Constructs a new ImageFile.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents an ImageFile.
                 * @implements IImageFile
                 * @constructor
                 * @param {mtechnavi.api.estimation.IImageFile=} [properties] Properties to set
                 */
                function ImageFile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImageFile assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.estimation.ImageFile
                 * @instance
                 */
                ImageFile.prototype.assetId = "";

                /**
                 * Verifies an ImageFile message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.ImageFile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImageFile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImageFile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.ImageFile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.ImageFile} ImageFile
                 */
                ImageFile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.ImageFile)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.ImageFile();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImageFile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.ImageFile
                 * @static
                 * @param {mtechnavi.api.estimation.ImageFile} message ImageFile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageFile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImageFile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.ImageFile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageFile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImageFile;
            })();

            estimation.TransactionUnitReference = (function() {

                /**
                 * Properties of a TransactionUnitReference.
                 * @memberof mtechnavi.api.estimation
                 * @interface ITransactionUnitReference
                 * @property {string|null} [transactionUnitId] TransactionUnitReference transactionUnitId
                 * @property {string|null} [displayNameLang] TransactionUnitReference displayNameLang
                 * @property {string|null} [transactionUnitCode] TransactionUnitReference transactionUnitCode
                 */

                /**
                 * Constructs a new TransactionUnitReference.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a TransactionUnitReference.
                 * @implements ITransactionUnitReference
                 * @constructor
                 * @param {mtechnavi.api.estimation.ITransactionUnitReference=} [properties] Properties to set
                 */
                function TransactionUnitReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TransactionUnitReference transactionUnitId.
                 * @member {string} transactionUnitId
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @instance
                 */
                TransactionUnitReference.prototype.transactionUnitId = "";

                /**
                 * TransactionUnitReference displayNameLang.
                 * @member {string} displayNameLang
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @instance
                 */
                TransactionUnitReference.prototype.displayNameLang = "";

                /**
                 * TransactionUnitReference transactionUnitCode.
                 * @member {string} transactionUnitCode
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @instance
                 */
                TransactionUnitReference.prototype.transactionUnitCode = "";

                /**
                 * Verifies a TransactionUnitReference message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TransactionUnitReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        if (!$util.isString(message.transactionUnitId))
                            return "transactionUnitId: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang"))
                        if (!$util.isString(message.displayNameLang))
                            return "displayNameLang: string expected";
                    if (message.transactionUnitCode != null && message.hasOwnProperty("transactionUnitCode"))
                        if (!$util.isString(message.transactionUnitCode))
                            return "transactionUnitCode: string expected";
                    return null;
                };

                /**
                 * Creates a TransactionUnitReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.TransactionUnitReference} TransactionUnitReference
                 */
                TransactionUnitReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.TransactionUnitReference)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.TransactionUnitReference();
                    if (object.transactionUnitId != null)
                        message.transactionUnitId = String(object.transactionUnitId);
                    if (object.displayNameLang != null)
                        message.displayNameLang = String(object.displayNameLang);
                    if (object.transactionUnitCode != null)
                        message.transactionUnitCode = String(object.transactionUnitCode);
                    return message;
                };

                /**
                 * Creates a plain object from a TransactionUnitReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @static
                 * @param {mtechnavi.api.estimation.TransactionUnitReference} message TransactionUnitReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TransactionUnitReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.transactionUnitId = "";
                        object.displayNameLang = "";
                        object.transactionUnitCode = "";
                    }
                    if (message.transactionUnitId != null && message.hasOwnProperty("transactionUnitId"))
                        object.transactionUnitId = message.transactionUnitId;
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang"))
                        object.displayNameLang = message.displayNameLang;
                    if (message.transactionUnitCode != null && message.hasOwnProperty("transactionUnitCode"))
                        object.transactionUnitCode = message.transactionUnitCode;
                    return object;
                };

                /**
                 * Converts this TransactionUnitReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.TransactionUnitReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TransactionUnitReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TransactionUnitReference;
            })();

            estimation.BlueprintReference = (function() {

                /**
                 * Properties of a BlueprintReference.
                 * @memberof mtechnavi.api.estimation
                 * @interface IBlueprintReference
                 * @property {string|null} [blueprintId] BlueprintReference blueprintId
                 * @property {string|null} [blueprintNumber] BlueprintReference blueprintNumber
                 * @property {string|null} [branchNumber] BlueprintReference branchNumber
                 * @property {string|null} [revision] BlueprintReference revision
                 */

                /**
                 * Constructs a new BlueprintReference.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a BlueprintReference.
                 * @implements IBlueprintReference
                 * @constructor
                 * @param {mtechnavi.api.estimation.IBlueprintReference=} [properties] Properties to set
                 */
                function BlueprintReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintReference blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.blueprintId = "";

                /**
                 * BlueprintReference blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.blueprintNumber = "";

                /**
                 * BlueprintReference branchNumber.
                 * @member {string} branchNumber
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.branchNumber = "";

                /**
                 * BlueprintReference revision.
                 * @member {string} revision
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @instance
                 */
                BlueprintReference.prototype.revision = "";

                /**
                 * Verifies a BlueprintReference message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    if (message.branchNumber != null && message.hasOwnProperty("branchNumber"))
                        if (!$util.isString(message.branchNumber))
                            return "branchNumber: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isString(message.revision))
                            return "revision: string expected";
                    return null;
                };

                /**
                 * Creates a BlueprintReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.BlueprintReference} BlueprintReference
                 */
                BlueprintReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.BlueprintReference)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.BlueprintReference();
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    if (object.branchNumber != null)
                        message.branchNumber = String(object.branchNumber);
                    if (object.revision != null)
                        message.revision = String(object.revision);
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @static
                 * @param {mtechnavi.api.estimation.BlueprintReference} message BlueprintReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.blueprintId = "";
                        object.blueprintNumber = "";
                        object.branchNumber = "";
                        object.revision = "";
                    }
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    if (message.branchNumber != null && message.hasOwnProperty("branchNumber"))
                        object.branchNumber = message.branchNumber;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    return object;
                };

                /**
                 * Converts this BlueprintReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.BlueprintReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintReference;
            })();

            estimation.BusinessUnitBranchReference = (function() {

                /**
                 * Properties of a BusinessUnitBranchReference.
                 * @memberof mtechnavi.api.estimation
                 * @interface IBusinessUnitBranchReference
                 * @property {string|null} [businessUnitBranchId] BusinessUnitBranchReference businessUnitBranchId
                 * @property {string|null} [displayName] BusinessUnitBranchReference displayName
                 */

                /**
                 * Constructs a new BusinessUnitBranchReference.
                 * @memberof mtechnavi.api.estimation
                 * @classdesc Represents a BusinessUnitBranchReference.
                 * @implements IBusinessUnitBranchReference
                 * @constructor
                 * @param {mtechnavi.api.estimation.IBusinessUnitBranchReference=} [properties] Properties to set
                 */
                function BusinessUnitBranchReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitBranchReference businessUnitBranchId.
                 * @member {string} businessUnitBranchId
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @instance
                 */
                BusinessUnitBranchReference.prototype.businessUnitBranchId = "";

                /**
                 * BusinessUnitBranchReference displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @instance
                 */
                BusinessUnitBranchReference.prototype.displayName = "";

                /**
                 * Verifies a BusinessUnitBranchReference message.
                 * @function verify
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitBranchReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        if (!$util.isString(message.businessUnitBranchId))
                            return "businessUnitBranchId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitBranchReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.estimation.BusinessUnitBranchReference} BusinessUnitBranchReference
                 */
                BusinessUnitBranchReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.estimation.BusinessUnitBranchReference)
                        return object;
                    let message = new $root.mtechnavi.api.estimation.BusinessUnitBranchReference();
                    if (object.businessUnitBranchId != null)
                        message.businessUnitBranchId = String(object.businessUnitBranchId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitBranchReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @static
                 * @param {mtechnavi.api.estimation.BusinessUnitBranchReference} message BusinessUnitBranchReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitBranchReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.businessUnitBranchId = "";
                        object.displayName = "";
                    }
                    if (message.businessUnitBranchId != null && message.hasOwnProperty("businessUnitBranchId"))
                        object.businessUnitBranchId = message.businessUnitBranchId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this BusinessUnitBranchReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.estimation.BusinessUnitBranchReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitBranchReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitBranchReference;
            })();

            return estimation;
        })();

        api.form = (function() {

            /**
             * Namespace form.
             * @memberof mtechnavi.api
             * @namespace
             */
            const form = {};

            form.BaseFormService = (function() {

                /**
                 * Constructs a new BaseFormService service.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BaseFormService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function BaseFormService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (BaseFormService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BaseFormService;

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#listBaseForms}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef ListBaseFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListBaseFormsResponse} [response] ListBaseFormsResponse
                 */

                /**
                 * Calls ListBaseForms.
                 * @function listBaseForms
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormsRequest} request ListBaseFormsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.ListBaseFormsCallback} callback Node-style callback called with the error, if any, and ListBaseFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.listBaseForms = function listBaseForms(request, callback) {
                    return this.rpcCall(listBaseForms, $root.mtechnavi.api.form.ListBaseFormsRequest, $root.mtechnavi.api.form.ListBaseFormsResponse, request, callback);
                }, "name", { value: "ListBaseForms" });

                /**
                 * Calls ListBaseForms.
                 * @function listBaseForms
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormsRequest} request ListBaseFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListBaseFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#createBaseForm}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef CreateBaseFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.BaseForm} [response] BaseForm
                 */

                /**
                 * Calls CreateBaseForm.
                 * @function createBaseForm
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateBaseFormRequest} request CreateBaseFormRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.CreateBaseFormCallback} callback Node-style callback called with the error, if any, and BaseForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.createBaseForm = function createBaseForm(request, callback) {
                    return this.rpcCall(createBaseForm, $root.mtechnavi.api.form.CreateBaseFormRequest, $root.mtechnavi.api.form.BaseForm, request, callback);
                }, "name", { value: "CreateBaseForm" });

                /**
                 * Calls CreateBaseForm.
                 * @function createBaseForm
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateBaseFormRequest} request CreateBaseFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.BaseForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#createOrUpdateBaseFormWithRelatedItems}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef CreateOrUpdateBaseFormWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse} [response] CreateOrUpdateBaseFormWithRelatedItemsResponse
                 */

                /**
                 * Calls CreateOrUpdateBaseFormWithRelatedItems.
                 * @function createOrUpdateBaseFormWithRelatedItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsRequest} request CreateOrUpdateBaseFormWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.CreateOrUpdateBaseFormWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.createOrUpdateBaseFormWithRelatedItems = function createOrUpdateBaseFormWithRelatedItems(request, callback) {
                    return this.rpcCall(createOrUpdateBaseFormWithRelatedItems, $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest, $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse, request, callback);
                }, "name", { value: "CreateOrUpdateBaseFormWithRelatedItems" });

                /**
                 * Calls CreateOrUpdateBaseFormWithRelatedItems.
                 * @function createOrUpdateBaseFormWithRelatedItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsRequest} request CreateOrUpdateBaseFormWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#deleteBaseFormWithRelatedItems}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef DeleteBaseFormWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBaseFormWithRelatedItems.
                 * @function deleteBaseFormWithRelatedItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteBaseFormWithRelatedItemsRequest} request DeleteBaseFormWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.DeleteBaseFormWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.deleteBaseFormWithRelatedItems = function deleteBaseFormWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteBaseFormWithRelatedItems, $root.mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBaseFormWithRelatedItems" });

                /**
                 * Calls DeleteBaseFormWithRelatedItems.
                 * @function deleteBaseFormWithRelatedItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteBaseFormWithRelatedItemsRequest} request DeleteBaseFormWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#listBaseFormSettings}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef ListBaseFormSettingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListBaseFormSettingsResponse} [response] ListBaseFormSettingsResponse
                 */

                /**
                 * Calls ListBaseFormSettings.
                 * @function listBaseFormSettings
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormSettingsRequest} request ListBaseFormSettingsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.ListBaseFormSettingsCallback} callback Node-style callback called with the error, if any, and ListBaseFormSettingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.listBaseFormSettings = function listBaseFormSettings(request, callback) {
                    return this.rpcCall(listBaseFormSettings, $root.mtechnavi.api.form.ListBaseFormSettingsRequest, $root.mtechnavi.api.form.ListBaseFormSettingsResponse, request, callback);
                }, "name", { value: "ListBaseFormSettings" });

                /**
                 * Calls ListBaseFormSettings.
                 * @function listBaseFormSettings
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormSettingsRequest} request ListBaseFormSettingsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListBaseFormSettingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#listBaseFormSettingItems}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef ListBaseFormSettingItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListBaseFormSettingItemsResponse} [response] ListBaseFormSettingItemsResponse
                 */

                /**
                 * Calls ListBaseFormSettingItems.
                 * @function listBaseFormSettingItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormSettingItemsRequest} request ListBaseFormSettingItemsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.ListBaseFormSettingItemsCallback} callback Node-style callback called with the error, if any, and ListBaseFormSettingItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.listBaseFormSettingItems = function listBaseFormSettingItems(request, callback) {
                    return this.rpcCall(listBaseFormSettingItems, $root.mtechnavi.api.form.ListBaseFormSettingItemsRequest, $root.mtechnavi.api.form.ListBaseFormSettingItemsResponse, request, callback);
                }, "name", { value: "ListBaseFormSettingItems" });

                /**
                 * Calls ListBaseFormSettingItems.
                 * @function listBaseFormSettingItems
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListBaseFormSettingItemsRequest} request ListBaseFormSettingItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListBaseFormSettingItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.BaseFormService#listItemAutoNameManagements}.
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @typedef ListItemAutoNameManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListItemAutoNameManagementsResponse} [response] ListItemAutoNameManagementsResponse
                 */

                /**
                 * Calls ListItemAutoNameManagements.
                 * @function listItemAutoNameManagements
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListItemAutoNameManagementsRequest} request ListItemAutoNameManagementsRequest message or plain object
                 * @param {mtechnavi.api.form.BaseFormService.ListItemAutoNameManagementsCallback} callback Node-style callback called with the error, if any, and ListItemAutoNameManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(BaseFormService.prototype.listItemAutoNameManagements = function listItemAutoNameManagements(request, callback) {
                    return this.rpcCall(listItemAutoNameManagements, $root.mtechnavi.api.form.ListItemAutoNameManagementsRequest, $root.mtechnavi.api.form.ListItemAutoNameManagementsResponse, request, callback);
                }, "name", { value: "ListItemAutoNameManagements" });

                /**
                 * Calls ListItemAutoNameManagements.
                 * @function listItemAutoNameManagements
                 * @memberof mtechnavi.api.form.BaseFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListItemAutoNameManagementsRequest} request ListItemAutoNameManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListItemAutoNameManagementsResponse>} Promise
                 * @variation 2
                 */

                return BaseFormService;
            })();

            form.FormService = (function() {

                /**
                 * Constructs a new FormService service.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function FormService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (FormService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FormService;

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#listForms}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef ListFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListFormsResponse} [response] ListFormsResponse
                 */

                /**
                 * Calls ListForms.
                 * @function listForms
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormsRequest} request ListFormsRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.ListFormsCallback} callback Node-style callback called with the error, if any, and ListFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.listForms = function listForms(request, callback) {
                    return this.rpcCall(listForms, $root.mtechnavi.api.form.ListFormsRequest, $root.mtechnavi.api.form.ListFormsResponse, request, callback);
                }, "name", { value: "ListForms" });

                /**
                 * Calls ListForms.
                 * @function listForms
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormsRequest} request ListFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#generateForm}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef GenerateFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.GenerateFormResponse} [response] GenerateFormResponse
                 */

                /**
                 * Calls GenerateForm.
                 * @function generateForm
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IGenerateFormRequest} request GenerateFormRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.GenerateFormCallback} callback Node-style callback called with the error, if any, and GenerateFormResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.generateForm = function generateForm(request, callback) {
                    return this.rpcCall(generateForm, $root.mtechnavi.api.form.GenerateFormRequest, $root.mtechnavi.api.form.GenerateFormResponse, request, callback);
                }, "name", { value: "GenerateForm" });

                /**
                 * Calls GenerateForm.
                 * @function generateForm
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IGenerateFormRequest} request GenerateFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.GenerateFormResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#deleteFormWithRelatedItems}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef DeleteFormWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteFormWithRelatedItems.
                 * @function deleteFormWithRelatedItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteFormWithRelatedItemsRequest} request DeleteFormWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.DeleteFormWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.deleteFormWithRelatedItems = function deleteFormWithRelatedItems(request, callback) {
                    return this.rpcCall(deleteFormWithRelatedItems, $root.mtechnavi.api.form.DeleteFormWithRelatedItemsRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteFormWithRelatedItems" });

                /**
                 * Calls DeleteFormWithRelatedItems.
                 * @function deleteFormWithRelatedItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteFormWithRelatedItemsRequest} request DeleteFormWithRelatedItemsRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#sharetoFormWithRelatedItems}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef SharetoFormWithRelatedItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.SharetoFormWithRelatedItemsResponse} [response] SharetoFormWithRelatedItemsResponse
                 */

                /**
                 * Calls SharetoFormWithRelatedItems.
                 * @function sharetoFormWithRelatedItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.ISharetoFormWithRelatedItemsRequest} request SharetoFormWithRelatedItemsRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.SharetoFormWithRelatedItemsCallback} callback Node-style callback called with the error, if any, and SharetoFormWithRelatedItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.sharetoFormWithRelatedItems = function sharetoFormWithRelatedItems(request, callback) {
                    return this.rpcCall(sharetoFormWithRelatedItems, $root.mtechnavi.api.form.SharetoFormWithRelatedItemsRequest, $root.mtechnavi.api.form.SharetoFormWithRelatedItemsResponse, request, callback);
                }, "name", { value: "SharetoFormWithRelatedItems" });

                /**
                 * Calls SharetoFormWithRelatedItems.
                 * @function sharetoFormWithRelatedItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.ISharetoFormWithRelatedItemsRequest} request SharetoFormWithRelatedItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.SharetoFormWithRelatedItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#listFormSettings}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef ListFormSettingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListFormSettingsResponse} [response] ListFormSettingsResponse
                 */

                /**
                 * Calls ListFormSettings.
                 * @function listFormSettings
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormSettingsRequest} request ListFormSettingsRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.ListFormSettingsCallback} callback Node-style callback called with the error, if any, and ListFormSettingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.listFormSettings = function listFormSettings(request, callback) {
                    return this.rpcCall(listFormSettings, $root.mtechnavi.api.form.ListFormSettingsRequest, $root.mtechnavi.api.form.ListFormSettingsResponse, request, callback);
                }, "name", { value: "ListFormSettings" });

                /**
                 * Calls ListFormSettings.
                 * @function listFormSettings
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormSettingsRequest} request ListFormSettingsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListFormSettingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#listFormSettingItems}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef ListFormSettingItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListFormSettingItemsResponse} [response] ListFormSettingItemsResponse
                 */

                /**
                 * Calls ListFormSettingItems.
                 * @function listFormSettingItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormSettingItemsRequest} request ListFormSettingItemsRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.ListFormSettingItemsCallback} callback Node-style callback called with the error, if any, and ListFormSettingItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.listFormSettingItems = function listFormSettingItems(request, callback) {
                    return this.rpcCall(listFormSettingItems, $root.mtechnavi.api.form.ListFormSettingItemsRequest, $root.mtechnavi.api.form.ListFormSettingItemsResponse, request, callback);
                }, "name", { value: "ListFormSettingItems" });

                /**
                 * Calls ListFormSettingItems.
                 * @function listFormSettingItems
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormSettingItemsRequest} request ListFormSettingItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListFormSettingItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#listFormValues}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef ListFormValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListFormValuesResponse} [response] ListFormValuesResponse
                 */

                /**
                 * Calls ListFormValues.
                 * @function listFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormValuesRequest} request ListFormValuesRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.ListFormValuesCallback} callback Node-style callback called with the error, if any, and ListFormValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.listFormValues = function listFormValues(request, callback) {
                    return this.rpcCall(listFormValues, $root.mtechnavi.api.form.ListFormValuesRequest, $root.mtechnavi.api.form.ListFormValuesResponse, request, callback);
                }, "name", { value: "ListFormValues" });

                /**
                 * Calls ListFormValues.
                 * @function listFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IListFormValuesRequest} request ListFormValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListFormValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#bulkWriteFormValues}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef BulkWriteFormValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.BulkWriteFormValuesResponse} [response] BulkWriteFormValuesResponse
                 */

                /**
                 * Calls BulkWriteFormValues.
                 * @function bulkWriteFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IBulkWriteFormValuesRequest} request BulkWriteFormValuesRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.BulkWriteFormValuesCallback} callback Node-style callback called with the error, if any, and BulkWriteFormValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.bulkWriteFormValues = function bulkWriteFormValues(request, callback) {
                    return this.rpcCall(bulkWriteFormValues, $root.mtechnavi.api.form.BulkWriteFormValuesRequest, $root.mtechnavi.api.form.BulkWriteFormValuesResponse, request, callback);
                }, "name", { value: "BulkWriteFormValues" });

                /**
                 * Calls BulkWriteFormValues.
                 * @function bulkWriteFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IBulkWriteFormValuesRequest} request BulkWriteFormValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.BulkWriteFormValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#sharetoFormValues}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef SharetoFormValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.SharetoFormValuesResponse} [response] SharetoFormValuesResponse
                 */

                /**
                 * Calls SharetoFormValues.
                 * @function sharetoFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.ISharetoFormValuesRequest} request SharetoFormValuesRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.SharetoFormValuesCallback} callback Node-style callback called with the error, if any, and SharetoFormValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.sharetoFormValues = function sharetoFormValues(request, callback) {
                    return this.rpcCall(sharetoFormValues, $root.mtechnavi.api.form.SharetoFormValuesRequest, $root.mtechnavi.api.form.SharetoFormValuesResponse, request, callback);
                }, "name", { value: "SharetoFormValues" });

                /**
                 * Calls SharetoFormValues.
                 * @function sharetoFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.ISharetoFormValuesRequest} request SharetoFormValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.SharetoFormValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#importFormValues}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef ImportFormValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ImportFormValuesResponse} [response] ImportFormValuesResponse
                 */

                /**
                 * Calls ImportFormValues.
                 * @function importFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IImportFormValuesRequest} request ImportFormValuesRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.ImportFormValuesCallback} callback Node-style callback called with the error, if any, and ImportFormValuesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.importFormValues = function importFormValues(request, callback) {
                    return this.rpcCall(importFormValues, $root.mtechnavi.api.form.ImportFormValuesRequest, $root.mtechnavi.api.form.ImportFormValuesResponse, request, callback);
                }, "name", { value: "ImportFormValues" });

                /**
                 * Calls ImportFormValues.
                 * @function importFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IImportFormValuesRequest} request ImportFormValuesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ImportFormValuesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.FormService#bulkDeleteFormValues}.
                 * @memberof mtechnavi.api.form.FormService
                 * @typedef BulkDeleteFormValuesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls BulkDeleteFormValues.
                 * @function bulkDeleteFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IBulkDeleteFormValuesRequest} request BulkDeleteFormValuesRequest message or plain object
                 * @param {mtechnavi.api.form.FormService.BulkDeleteFormValuesCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(FormService.prototype.bulkDeleteFormValues = function bulkDeleteFormValues(request, callback) {
                    return this.rpcCall(bulkDeleteFormValues, $root.mtechnavi.api.form.BulkDeleteFormValuesRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "BulkDeleteFormValues" });

                /**
                 * Calls BulkDeleteFormValues.
                 * @function bulkDeleteFormValues
                 * @memberof mtechnavi.api.form.FormService
                 * @instance
                 * @param {mtechnavi.api.form.IBulkDeleteFormValuesRequest} request BulkDeleteFormValuesRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return FormService;
            })();

            form.SampleWorkFormService = (function() {

                /**
                 * Constructs a new SampleWorkFormService service.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SampleWorkFormService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SampleWorkFormService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SampleWorkFormService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SampleWorkFormService;

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#listSampleWorkForms}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef ListSampleWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ListSampleWorkFormsResponse} [response] ListSampleWorkFormsResponse
                 */

                /**
                 * Calls ListSampleWorkForms.
                 * @function listSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListSampleWorkFormsRequest} request ListSampleWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.ListSampleWorkFormsCallback} callback Node-style callback called with the error, if any, and ListSampleWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.listSampleWorkForms = function listSampleWorkForms(request, callback) {
                    return this.rpcCall(listSampleWorkForms, $root.mtechnavi.api.form.ListSampleWorkFormsRequest, $root.mtechnavi.api.form.ListSampleWorkFormsResponse, request, callback);
                }, "name", { value: "ListSampleWorkForms" });

                /**
                 * Calls ListSampleWorkForms.
                 * @function listSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IListSampleWorkFormsRequest} request ListSampleWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ListSampleWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#createSampleWorkForm}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef CreateSampleWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.SampleWorkForm} [response] SampleWorkForm
                 */

                /**
                 * Calls CreateSampleWorkForm.
                 * @function createSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateSampleWorkFormRequest} request CreateSampleWorkFormRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.CreateSampleWorkFormCallback} callback Node-style callback called with the error, if any, and SampleWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.createSampleWorkForm = function createSampleWorkForm(request, callback) {
                    return this.rpcCall(createSampleWorkForm, $root.mtechnavi.api.form.CreateSampleWorkFormRequest, $root.mtechnavi.api.form.SampleWorkForm, request, callback);
                }, "name", { value: "CreateSampleWorkForm" });

                /**
                 * Calls CreateSampleWorkForm.
                 * @function createSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.ICreateSampleWorkFormRequest} request CreateSampleWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.SampleWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#updateSampleWorkForm}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef UpdateSampleWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.SampleWorkForm} [response] SampleWorkForm
                 */

                /**
                 * Calls UpdateSampleWorkForm.
                 * @function updateSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IUpdateSampleWorkFormRequest} request UpdateSampleWorkFormRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.UpdateSampleWorkFormCallback} callback Node-style callback called with the error, if any, and SampleWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.updateSampleWorkForm = function updateSampleWorkForm(request, callback) {
                    return this.rpcCall(updateSampleWorkForm, $root.mtechnavi.api.form.UpdateSampleWorkFormRequest, $root.mtechnavi.api.form.SampleWorkForm, request, callback);
                }, "name", { value: "UpdateSampleWorkForm" });

                /**
                 * Calls UpdateSampleWorkForm.
                 * @function updateSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IUpdateSampleWorkFormRequest} request UpdateSampleWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.SampleWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#deleteSampleWorkForm}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef DeleteSampleWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteSampleWorkForm.
                 * @function deleteSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteSampleWorkFormRequest} request DeleteSampleWorkFormRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.DeleteSampleWorkFormCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.deleteSampleWorkForm = function deleteSampleWorkForm(request, callback) {
                    return this.rpcCall(deleteSampleWorkForm, $root.mtechnavi.api.form.DeleteSampleWorkFormRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteSampleWorkForm" });

                /**
                 * Calls DeleteSampleWorkForm.
                 * @function deleteSampleWorkForm
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IDeleteSampleWorkFormRequest} request DeleteSampleWorkFormRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#importSampleWorkForms}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef ImportSampleWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ImportSampleWorkFormsResponse} [response] ImportSampleWorkFormsResponse
                 */

                /**
                 * Calls ImportSampleWorkForms.
                 * @function importSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IImportSampleWorkFormsRequest} request ImportSampleWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.ImportSampleWorkFormsCallback} callback Node-style callback called with the error, if any, and ImportSampleWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.importSampleWorkForms = function importSampleWorkForms(request, callback) {
                    return this.rpcCall(importSampleWorkForms, $root.mtechnavi.api.form.ImportSampleWorkFormsRequest, $root.mtechnavi.api.form.ImportSampleWorkFormsResponse, request, callback);
                }, "name", { value: "ImportSampleWorkForms" });

                /**
                 * Calls ImportSampleWorkForms.
                 * @function importSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IImportSampleWorkFormsRequest} request ImportSampleWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ImportSampleWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.form.SampleWorkFormService#exportSampleWorkForms}.
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @typedef ExportSampleWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.form.ExportSampleWorkFormsResponse} [response] ExportSampleWorkFormsResponse
                 */

                /**
                 * Calls ExportSampleWorkForms.
                 * @function exportSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IExportSampleWorkFormsRequest} request ExportSampleWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.form.SampleWorkFormService.ExportSampleWorkFormsCallback} callback Node-style callback called with the error, if any, and ExportSampleWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SampleWorkFormService.prototype.exportSampleWorkForms = function exportSampleWorkForms(request, callback) {
                    return this.rpcCall(exportSampleWorkForms, $root.mtechnavi.api.form.ExportSampleWorkFormsRequest, $root.mtechnavi.api.form.ExportSampleWorkFormsResponse, request, callback);
                }, "name", { value: "ExportSampleWorkForms" });

                /**
                 * Calls ExportSampleWorkForms.
                 * @function exportSampleWorkForms
                 * @memberof mtechnavi.api.form.SampleWorkFormService
                 * @instance
                 * @param {mtechnavi.api.form.IExportSampleWorkFormsRequest} request ExportSampleWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.form.ExportSampleWorkFormsResponse>} Promise
                 * @variation 2
                 */

                return SampleWorkFormService;
            })();

            form.ListBaseFormsRequest = (function() {

                /**
                 * Properties of a ListBaseFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormsRequest
                 * @property {string|null} [pageToken] ListBaseFormsRequest pageToken
                 * @property {Array.<string>|null} [baseFormIds] ListBaseFormsRequest baseFormIds
                 * @property {Array.<sharelib.INameOption>|null} [purposes] ListBaseFormsRequest purposes
                 */

                /**
                 * Constructs a new ListBaseFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormsRequest.
                 * @implements IListBaseFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormsRequest=} [properties] Properties to set
                 */
                function ListBaseFormsRequest(properties) {
                    this.baseFormIds = [];
                    this.purposes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @instance
                 */
                ListBaseFormsRequest.prototype.pageToken = "";

                /**
                 * ListBaseFormsRequest baseFormIds.
                 * @member {Array.<string>} baseFormIds
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @instance
                 */
                ListBaseFormsRequest.prototype.baseFormIds = $util.emptyArray;

                /**
                 * ListBaseFormsRequest purposes.
                 * @member {Array.<sharelib.INameOption>} purposes
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @instance
                 */
                ListBaseFormsRequest.prototype.purposes = $util.emptyArray;

                /**
                 * Verifies a ListBaseFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseFormIds != null && message.hasOwnProperty("baseFormIds")) {
                        if (!Array.isArray(message.baseFormIds))
                            return "baseFormIds: array expected";
                        for (let i = 0; i < message.baseFormIds.length; ++i)
                            if (!$util.isString(message.baseFormIds[i]))
                                return "baseFormIds: string[] expected";
                    }
                    if (message.purposes != null && message.hasOwnProperty("purposes")) {
                        if (!Array.isArray(message.purposes))
                            return "purposes: array expected";
                        for (let i = 0; i < message.purposes.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.purposes[i]);
                            if (error)
                                return "purposes." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBaseFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormsRequest} ListBaseFormsRequest
                 */
                ListBaseFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseFormIds) {
                        if (!Array.isArray(object.baseFormIds))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormsRequest.baseFormIds: array expected");
                        message.baseFormIds = [];
                        for (let i = 0; i < object.baseFormIds.length; ++i)
                            message.baseFormIds[i] = String(object.baseFormIds[i]);
                    }
                    if (object.purposes) {
                        if (!Array.isArray(object.purposes))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormsRequest.purposes: array expected");
                        message.purposes = [];
                        for (let i = 0; i < object.purposes.length; ++i) {
                            if (typeof object.purposes[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListBaseFormsRequest.purposes: object expected");
                            message.purposes[i] = $root.sharelib.NameOption.fromObject(object.purposes[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormsRequest} message ListBaseFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.baseFormIds = [];
                        object.purposes = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseFormIds && message.baseFormIds.length) {
                        object.baseFormIds = [];
                        for (let j = 0; j < message.baseFormIds.length; ++j)
                            object.baseFormIds[j] = message.baseFormIds[j];
                    }
                    if (message.purposes && message.purposes.length) {
                        object.purposes = [];
                        for (let j = 0; j < message.purposes.length; ++j)
                            object.purposes[j] = $root.sharelib.NameOption.toObject(message.purposes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBaseFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormsRequest;
            })();

            form.ListBaseFormsResponse = (function() {

                /**
                 * Properties of a ListBaseFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormsResponse
                 * @property {Long|null} [total] ListBaseFormsResponse total
                 * @property {Array.<mtechnavi.api.form.IBaseForm>|null} [items] ListBaseFormsResponse items
                 * @property {string|null} [pageToken] ListBaseFormsResponse pageToken
                 */

                /**
                 * Constructs a new ListBaseFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormsResponse.
                 * @implements IListBaseFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormsResponse=} [properties] Properties to set
                 */
                function ListBaseFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @instance
                 */
                ListBaseFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBaseFormsResponse items.
                 * @member {Array.<mtechnavi.api.form.IBaseForm>} items
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @instance
                 */
                ListBaseFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBaseFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @instance
                 */
                ListBaseFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBaseFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.BaseForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBaseFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormsResponse} ListBaseFormsResponse
                 */
                ListBaseFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListBaseFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.BaseForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormsResponse} message ListBaseFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.BaseForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBaseFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormsResponse;
            })();

            form.CreateBaseFormRequest = (function() {

                /**
                 * Properties of a CreateBaseFormRequest.
                 * @memberof mtechnavi.api.form
                 * @interface ICreateBaseFormRequest
                 * @property {mtechnavi.api.form.IBaseForm|null} [baseForm] CreateBaseFormRequest baseForm
                 */

                /**
                 * Constructs a new CreateBaseFormRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a CreateBaseFormRequest.
                 * @implements ICreateBaseFormRequest
                 * @constructor
                 * @param {mtechnavi.api.form.ICreateBaseFormRequest=} [properties] Properties to set
                 */
                function CreateBaseFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBaseFormRequest baseForm.
                 * @member {mtechnavi.api.form.IBaseForm|null|undefined} baseForm
                 * @memberof mtechnavi.api.form.CreateBaseFormRequest
                 * @instance
                 */
                CreateBaseFormRequest.prototype.baseForm = null;

                /**
                 * Verifies a CreateBaseFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.CreateBaseFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBaseFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseForm != null && message.hasOwnProperty("baseForm")) {
                        let error = $root.mtechnavi.api.form.BaseForm.verify(message.baseForm);
                        if (error)
                            return "baseForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBaseFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.CreateBaseFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.CreateBaseFormRequest} CreateBaseFormRequest
                 */
                CreateBaseFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.CreateBaseFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.CreateBaseFormRequest();
                    if (object.baseForm != null) {
                        if (typeof object.baseForm !== "object")
                            throw TypeError(".mtechnavi.api.form.CreateBaseFormRequest.baseForm: object expected");
                        message.baseForm = $root.mtechnavi.api.form.BaseForm.fromObject(object.baseForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBaseFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.CreateBaseFormRequest
                 * @static
                 * @param {mtechnavi.api.form.CreateBaseFormRequest} message CreateBaseFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBaseFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseForm = null;
                    if (message.baseForm != null && message.hasOwnProperty("baseForm"))
                        object.baseForm = $root.mtechnavi.api.form.BaseForm.toObject(message.baseForm, options);
                    return object;
                };

                /**
                 * Converts this CreateBaseFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.CreateBaseFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBaseFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBaseFormRequest;
            })();

            form.CreateOrUpdateBaseFormWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a CreateOrUpdateBaseFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface ICreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @property {mtechnavi.api.form.IBaseForm|null} [baseForm] CreateOrUpdateBaseFormWithRelatedItemsRequest baseForm
                 * @property {mtechnavi.api.form.IBaseFormSetting|null} [baseFormSetting] CreateOrUpdateBaseFormWithRelatedItemsRequest baseFormSetting
                 * @property {Array.<mtechnavi.api.form.IBaseFormSettingItem>|null} [baseFormSettingItems] CreateOrUpdateBaseFormWithRelatedItemsRequest baseFormSettingItems
                 */

                /**
                 * Constructs a new CreateOrUpdateBaseFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a CreateOrUpdateBaseFormWithRelatedItemsRequest.
                 * @implements ICreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function CreateOrUpdateBaseFormWithRelatedItemsRequest(properties) {
                    this.baseFormSettingItems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateOrUpdateBaseFormWithRelatedItemsRequest baseForm.
                 * @member {mtechnavi.api.form.IBaseForm|null|undefined} baseForm
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @instance
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.prototype.baseForm = null;

                /**
                 * CreateOrUpdateBaseFormWithRelatedItemsRequest baseFormSetting.
                 * @member {mtechnavi.api.form.IBaseFormSetting|null|undefined} baseFormSetting
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @instance
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.prototype.baseFormSetting = null;

                /**
                 * CreateOrUpdateBaseFormWithRelatedItemsRequest baseFormSettingItems.
                 * @member {Array.<mtechnavi.api.form.IBaseFormSettingItem>} baseFormSettingItems
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @instance
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.prototype.baseFormSettingItems = $util.emptyArray;

                /**
                 * Verifies a CreateOrUpdateBaseFormWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseForm != null && message.hasOwnProperty("baseForm")) {
                        let error = $root.mtechnavi.api.form.BaseForm.verify(message.baseForm);
                        if (error)
                            return "baseForm." + error;
                    }
                    if (message.baseFormSetting != null && message.hasOwnProperty("baseFormSetting")) {
                        let error = $root.mtechnavi.api.form.BaseFormSetting.verify(message.baseFormSetting);
                        if (error)
                            return "baseFormSetting." + error;
                    }
                    if (message.baseFormSettingItems != null && message.hasOwnProperty("baseFormSettingItems")) {
                        if (!Array.isArray(message.baseFormSettingItems))
                            return "baseFormSettingItems: array expected";
                        for (let i = 0; i < message.baseFormSettingItems.length; ++i) {
                            let error = $root.mtechnavi.api.form.BaseFormSettingItem.verify(message.baseFormSettingItems[i]);
                            if (error)
                                return "baseFormSettingItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateOrUpdateBaseFormWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest} CreateOrUpdateBaseFormWithRelatedItemsRequest
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest();
                    if (object.baseForm != null) {
                        if (typeof object.baseForm !== "object")
                            throw TypeError(".mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest.baseForm: object expected");
                        message.baseForm = $root.mtechnavi.api.form.BaseForm.fromObject(object.baseForm);
                    }
                    if (object.baseFormSetting != null) {
                        if (typeof object.baseFormSetting !== "object")
                            throw TypeError(".mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest.baseFormSetting: object expected");
                        message.baseFormSetting = $root.mtechnavi.api.form.BaseFormSetting.fromObject(object.baseFormSetting);
                    }
                    if (object.baseFormSettingItems) {
                        if (!Array.isArray(object.baseFormSettingItems))
                            throw TypeError(".mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest.baseFormSettingItems: array expected");
                        message.baseFormSettingItems = [];
                        for (let i = 0; i < object.baseFormSettingItems.length; ++i) {
                            if (typeof object.baseFormSettingItems[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest.baseFormSettingItems: object expected");
                            message.baseFormSettingItems[i] = $root.mtechnavi.api.form.BaseFormSettingItem.fromObject(object.baseFormSettingItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateOrUpdateBaseFormWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest} message CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.baseFormSettingItems = [];
                    if (options.defaults) {
                        object.baseForm = null;
                        object.baseFormSetting = null;
                    }
                    if (message.baseForm != null && message.hasOwnProperty("baseForm"))
                        object.baseForm = $root.mtechnavi.api.form.BaseForm.toObject(message.baseForm, options);
                    if (message.baseFormSetting != null && message.hasOwnProperty("baseFormSetting"))
                        object.baseFormSetting = $root.mtechnavi.api.form.BaseFormSetting.toObject(message.baseFormSetting, options);
                    if (message.baseFormSettingItems && message.baseFormSettingItems.length) {
                        object.baseFormSettingItems = [];
                        for (let j = 0; j < message.baseFormSettingItems.length; ++j)
                            object.baseFormSettingItems[j] = $root.mtechnavi.api.form.BaseFormSettingItem.toObject(message.baseFormSettingItems[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CreateOrUpdateBaseFormWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateOrUpdateBaseFormWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateOrUpdateBaseFormWithRelatedItemsRequest;
            })();

            form.CreateOrUpdateBaseFormWithRelatedItemsResponse = (function() {

                /**
                 * Properties of a CreateOrUpdateBaseFormWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface ICreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @property {mtechnavi.api.form.IBaseForm|null} [baseForm] CreateOrUpdateBaseFormWithRelatedItemsResponse baseForm
                 */

                /**
                 * Constructs a new CreateOrUpdateBaseFormWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a CreateOrUpdateBaseFormWithRelatedItemsResponse.
                 * @implements ICreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.ICreateOrUpdateBaseFormWithRelatedItemsResponse=} [properties] Properties to set
                 */
                function CreateOrUpdateBaseFormWithRelatedItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateOrUpdateBaseFormWithRelatedItemsResponse baseForm.
                 * @member {mtechnavi.api.form.IBaseForm|null|undefined} baseForm
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @instance
                 */
                CreateOrUpdateBaseFormWithRelatedItemsResponse.prototype.baseForm = null;

                /**
                 * Verifies a CreateOrUpdateBaseFormWithRelatedItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateOrUpdateBaseFormWithRelatedItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseForm != null && message.hasOwnProperty("baseForm")) {
                        let error = $root.mtechnavi.api.form.BaseForm.verify(message.baseForm);
                        if (error)
                            return "baseForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateOrUpdateBaseFormWithRelatedItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse} CreateOrUpdateBaseFormWithRelatedItemsResponse
                 */
                CreateOrUpdateBaseFormWithRelatedItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse();
                    if (object.baseForm != null) {
                        if (typeof object.baseForm !== "object")
                            throw TypeError(".mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse.baseForm: object expected");
                        message.baseForm = $root.mtechnavi.api.form.BaseForm.fromObject(object.baseForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateOrUpdateBaseFormWithRelatedItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @static
                 * @param {mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse} message CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateOrUpdateBaseFormWithRelatedItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseForm = null;
                    if (message.baseForm != null && message.hasOwnProperty("baseForm"))
                        object.baseForm = $root.mtechnavi.api.form.BaseForm.toObject(message.baseForm, options);
                    return object;
                };

                /**
                 * Converts this CreateOrUpdateBaseFormWithRelatedItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.CreateOrUpdateBaseFormWithRelatedItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateOrUpdateBaseFormWithRelatedItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateOrUpdateBaseFormWithRelatedItemsResponse;
            })();

            form.DeleteBaseFormWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteBaseFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IDeleteBaseFormWithRelatedItemsRequest
                 * @property {string|null} [baseFormId] DeleteBaseFormWithRelatedItemsRequest baseFormId
                 */

                /**
                 * Constructs a new DeleteBaseFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a DeleteBaseFormWithRelatedItemsRequest.
                 * @implements IDeleteBaseFormWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IDeleteBaseFormWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteBaseFormWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBaseFormWithRelatedItemsRequest baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest
                 * @instance
                 */
                DeleteBaseFormWithRelatedItemsRequest.prototype.baseFormId = "";

                /**
                 * Verifies a DeleteBaseFormWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBaseFormWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteBaseFormWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest} DeleteBaseFormWithRelatedItemsRequest
                 */
                DeleteBaseFormWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest();
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBaseFormWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest} message DeleteBaseFormWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBaseFormWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseFormId = "";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    return object;
                };

                /**
                 * Converts this DeleteBaseFormWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.DeleteBaseFormWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBaseFormWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBaseFormWithRelatedItemsRequest;
            })();

            form.ListBaseFormSettingsRequest = (function() {

                /**
                 * Properties of a ListBaseFormSettingsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormSettingsRequest
                 * @property {string|null} [pageToken] ListBaseFormSettingsRequest pageToken
                 * @property {Array.<string>|null} [baseFormIds] ListBaseFormSettingsRequest baseFormIds
                 */

                /**
                 * Constructs a new ListBaseFormSettingsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormSettingsRequest.
                 * @implements IListBaseFormSettingsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormSettingsRequest=} [properties] Properties to set
                 */
                function ListBaseFormSettingsRequest(properties) {
                    this.baseFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormSettingsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @instance
                 */
                ListBaseFormSettingsRequest.prototype.pageToken = "";

                /**
                 * ListBaseFormSettingsRequest baseFormIds.
                 * @member {Array.<string>} baseFormIds
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @instance
                 */
                ListBaseFormSettingsRequest.prototype.baseFormIds = $util.emptyArray;

                /**
                 * Verifies a ListBaseFormSettingsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormSettingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseFormIds != null && message.hasOwnProperty("baseFormIds")) {
                        if (!Array.isArray(message.baseFormIds))
                            return "baseFormIds: array expected";
                        for (let i = 0; i < message.baseFormIds.length; ++i)
                            if (!$util.isString(message.baseFormIds[i]))
                                return "baseFormIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBaseFormSettingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormSettingsRequest} ListBaseFormSettingsRequest
                 */
                ListBaseFormSettingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormSettingsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormSettingsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseFormIds) {
                        if (!Array.isArray(object.baseFormIds))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormSettingsRequest.baseFormIds: array expected");
                        message.baseFormIds = [];
                        for (let i = 0; i < object.baseFormIds.length; ++i)
                            message.baseFormIds[i] = String(object.baseFormIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormSettingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormSettingsRequest} message ListBaseFormSettingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormSettingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.baseFormIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseFormIds && message.baseFormIds.length) {
                        object.baseFormIds = [];
                        for (let j = 0; j < message.baseFormIds.length; ++j)
                            object.baseFormIds[j] = message.baseFormIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBaseFormSettingsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormSettingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormSettingsRequest;
            })();

            form.ListBaseFormSettingsResponse = (function() {

                /**
                 * Properties of a ListBaseFormSettingsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormSettingsResponse
                 * @property {Long|null} [total] ListBaseFormSettingsResponse total
                 * @property {Array.<mtechnavi.api.form.IBaseFormSetting>|null} [items] ListBaseFormSettingsResponse items
                 * @property {string|null} [pageToken] ListBaseFormSettingsResponse pageToken
                 */

                /**
                 * Constructs a new ListBaseFormSettingsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormSettingsResponse.
                 * @implements IListBaseFormSettingsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormSettingsResponse=} [properties] Properties to set
                 */
                function ListBaseFormSettingsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormSettingsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @instance
                 */
                ListBaseFormSettingsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBaseFormSettingsResponse items.
                 * @member {Array.<mtechnavi.api.form.IBaseFormSetting>} items
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @instance
                 */
                ListBaseFormSettingsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBaseFormSettingsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @instance
                 */
                ListBaseFormSettingsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBaseFormSettingsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormSettingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.BaseFormSetting.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBaseFormSettingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormSettingsResponse} ListBaseFormSettingsResponse
                 */
                ListBaseFormSettingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormSettingsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormSettingsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormSettingsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListBaseFormSettingsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.BaseFormSetting.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormSettingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormSettingsResponse} message ListBaseFormSettingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormSettingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.BaseFormSetting.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBaseFormSettingsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormSettingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormSettingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormSettingsResponse;
            })();

            form.ListBaseFormSettingItemsRequest = (function() {

                /**
                 * Properties of a ListBaseFormSettingItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormSettingItemsRequest
                 * @property {string|null} [pageToken] ListBaseFormSettingItemsRequest pageToken
                 * @property {Array.<string>|null} [baseFormIds] ListBaseFormSettingItemsRequest baseFormIds
                 */

                /**
                 * Constructs a new ListBaseFormSettingItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormSettingItemsRequest.
                 * @implements IListBaseFormSettingItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormSettingItemsRequest=} [properties] Properties to set
                 */
                function ListBaseFormSettingItemsRequest(properties) {
                    this.baseFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormSettingItemsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @instance
                 */
                ListBaseFormSettingItemsRequest.prototype.pageToken = "";

                /**
                 * ListBaseFormSettingItemsRequest baseFormIds.
                 * @member {Array.<string>} baseFormIds
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @instance
                 */
                ListBaseFormSettingItemsRequest.prototype.baseFormIds = $util.emptyArray;

                /**
                 * Verifies a ListBaseFormSettingItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormSettingItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseFormIds != null && message.hasOwnProperty("baseFormIds")) {
                        if (!Array.isArray(message.baseFormIds))
                            return "baseFormIds: array expected";
                        for (let i = 0; i < message.baseFormIds.length; ++i)
                            if (!$util.isString(message.baseFormIds[i]))
                                return "baseFormIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListBaseFormSettingItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormSettingItemsRequest} ListBaseFormSettingItemsRequest
                 */
                ListBaseFormSettingItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormSettingItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormSettingItemsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseFormIds) {
                        if (!Array.isArray(object.baseFormIds))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormSettingItemsRequest.baseFormIds: array expected");
                        message.baseFormIds = [];
                        for (let i = 0; i < object.baseFormIds.length; ++i)
                            message.baseFormIds[i] = String(object.baseFormIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormSettingItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormSettingItemsRequest} message ListBaseFormSettingItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormSettingItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.baseFormIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseFormIds && message.baseFormIds.length) {
                        object.baseFormIds = [];
                        for (let j = 0; j < message.baseFormIds.length; ++j)
                            object.baseFormIds[j] = message.baseFormIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListBaseFormSettingItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormSettingItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormSettingItemsRequest;
            })();

            form.ListBaseFormSettingItemsResponse = (function() {

                /**
                 * Properties of a ListBaseFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListBaseFormSettingItemsResponse
                 * @property {Long|null} [total] ListBaseFormSettingItemsResponse total
                 * @property {Array.<mtechnavi.api.form.IBaseFormSettingItem>|null} [items] ListBaseFormSettingItemsResponse items
                 * @property {string|null} [pageToken] ListBaseFormSettingItemsResponse pageToken
                 */

                /**
                 * Constructs a new ListBaseFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListBaseFormSettingItemsResponse.
                 * @implements IListBaseFormSettingItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListBaseFormSettingItemsResponse=} [properties] Properties to set
                 */
                function ListBaseFormSettingItemsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseFormSettingItemsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @instance
                 */
                ListBaseFormSettingItemsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBaseFormSettingItemsResponse items.
                 * @member {Array.<mtechnavi.api.form.IBaseFormSettingItem>} items
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @instance
                 */
                ListBaseFormSettingItemsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBaseFormSettingItemsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @instance
                 */
                ListBaseFormSettingItemsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBaseFormSettingItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseFormSettingItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.BaseFormSettingItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBaseFormSettingItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListBaseFormSettingItemsResponse} ListBaseFormSettingItemsResponse
                 */
                ListBaseFormSettingItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListBaseFormSettingItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListBaseFormSettingItemsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListBaseFormSettingItemsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListBaseFormSettingItemsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.BaseFormSettingItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseFormSettingItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListBaseFormSettingItemsResponse} message ListBaseFormSettingItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseFormSettingItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.BaseFormSettingItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBaseFormSettingItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListBaseFormSettingItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseFormSettingItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseFormSettingItemsResponse;
            })();

            form.BulkWriteBaseFormSettingItemsResponse = (function() {

                /**
                 * Properties of a BulkWriteBaseFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IBulkWriteBaseFormSettingItemsResponse
                 * @property {Array.<mtechnavi.api.form.IBaseFormSettingItem>|null} [items] BulkWriteBaseFormSettingItemsResponse items
                 */

                /**
                 * Constructs a new BulkWriteBaseFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BulkWriteBaseFormSettingItemsResponse.
                 * @implements IBulkWriteBaseFormSettingItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IBulkWriteBaseFormSettingItemsResponse=} [properties] Properties to set
                 */
                function BulkWriteBaseFormSettingItemsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteBaseFormSettingItemsResponse items.
                 * @member {Array.<mtechnavi.api.form.IBaseFormSettingItem>} items
                 * @memberof mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse
                 * @instance
                 */
                BulkWriteBaseFormSettingItemsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteBaseFormSettingItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteBaseFormSettingItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.BaseFormSettingItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteBaseFormSettingItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse} BulkWriteBaseFormSettingItemsResponse
                 */
                BulkWriteBaseFormSettingItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.BaseFormSettingItem.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteBaseFormSettingItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse
                 * @static
                 * @param {mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse} message BulkWriteBaseFormSettingItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteBaseFormSettingItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.BaseFormSettingItem.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteBaseFormSettingItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BulkWriteBaseFormSettingItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteBaseFormSettingItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteBaseFormSettingItemsResponse;
            })();

            form.ListItemAutoNameManagementsRequest = (function() {

                /**
                 * Properties of a ListItemAutoNameManagementsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListItemAutoNameManagementsRequest
                 * @property {string|null} [pageToken] ListItemAutoNameManagementsRequest pageToken
                 * @property {string|null} [baseFormId] ListItemAutoNameManagementsRequest baseFormId
                 * @property {string|null} [itemAutoName] ListItemAutoNameManagementsRequest itemAutoName
                 */

                /**
                 * Constructs a new ListItemAutoNameManagementsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListItemAutoNameManagementsRequest.
                 * @implements IListItemAutoNameManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListItemAutoNameManagementsRequest=} [properties] Properties to set
                 */
                function ListItemAutoNameManagementsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListItemAutoNameManagementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @instance
                 */
                ListItemAutoNameManagementsRequest.prototype.pageToken = "";

                /**
                 * ListItemAutoNameManagementsRequest baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @instance
                 */
                ListItemAutoNameManagementsRequest.prototype.baseFormId = "";

                /**
                 * ListItemAutoNameManagementsRequest itemAutoName.
                 * @member {string} itemAutoName
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @instance
                 */
                ListItemAutoNameManagementsRequest.prototype.itemAutoName = "";

                /**
                 * Verifies a ListItemAutoNameManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListItemAutoNameManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        if (!$util.isString(message.itemAutoName))
                            return "itemAutoName: string expected";
                    return null;
                };

                /**
                 * Creates a ListItemAutoNameManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListItemAutoNameManagementsRequest} ListItemAutoNameManagementsRequest
                 */
                ListItemAutoNameManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListItemAutoNameManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListItemAutoNameManagementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.itemAutoName != null)
                        message.itemAutoName = String(object.itemAutoName);
                    return message;
                };

                /**
                 * Creates a plain object from a ListItemAutoNameManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListItemAutoNameManagementsRequest} message ListItemAutoNameManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListItemAutoNameManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.baseFormId = "";
                        object.itemAutoName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        object.itemAutoName = message.itemAutoName;
                    return object;
                };

                /**
                 * Converts this ListItemAutoNameManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListItemAutoNameManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListItemAutoNameManagementsRequest;
            })();

            form.ListItemAutoNameManagementsResponse = (function() {

                /**
                 * Properties of a ListItemAutoNameManagementsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListItemAutoNameManagementsResponse
                 * @property {Long|null} [total] ListItemAutoNameManagementsResponse total
                 * @property {Array.<mtechnavi.api.form.IItemAutoNameManagement>|null} [items] ListItemAutoNameManagementsResponse items
                 * @property {string|null} [pageToken] ListItemAutoNameManagementsResponse pageToken
                 */

                /**
                 * Constructs a new ListItemAutoNameManagementsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListItemAutoNameManagementsResponse.
                 * @implements IListItemAutoNameManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListItemAutoNameManagementsResponse=} [properties] Properties to set
                 */
                function ListItemAutoNameManagementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListItemAutoNameManagementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @instance
                 */
                ListItemAutoNameManagementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListItemAutoNameManagementsResponse items.
                 * @member {Array.<mtechnavi.api.form.IItemAutoNameManagement>} items
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @instance
                 */
                ListItemAutoNameManagementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListItemAutoNameManagementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @instance
                 */
                ListItemAutoNameManagementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListItemAutoNameManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListItemAutoNameManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.ItemAutoNameManagement.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListItemAutoNameManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListItemAutoNameManagementsResponse} ListItemAutoNameManagementsResponse
                 */
                ListItemAutoNameManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListItemAutoNameManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListItemAutoNameManagementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListItemAutoNameManagementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListItemAutoNameManagementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.ItemAutoNameManagement.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListItemAutoNameManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListItemAutoNameManagementsResponse} message ListItemAutoNameManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListItemAutoNameManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.ItemAutoNameManagement.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListItemAutoNameManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListItemAutoNameManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListItemAutoNameManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListItemAutoNameManagementsResponse;
            })();

            form.ListFormsRequest = (function() {

                /**
                 * Properties of a ListFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormsRequest
                 * @property {string|null} [pageToken] ListFormsRequest pageToken
                 * @property {Array.<string>|null} [baseFormIds] ListFormsRequest baseFormIds
                 * @property {Array.<string>|null} [formIds] ListFormsRequest formIds
                 */

                /**
                 * Constructs a new ListFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormsRequest.
                 * @implements IListFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormsRequest=} [properties] Properties to set
                 */
                function ListFormsRequest(properties) {
                    this.baseFormIds = [];
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @instance
                 */
                ListFormsRequest.prototype.pageToken = "";

                /**
                 * ListFormsRequest baseFormIds.
                 * @member {Array.<string>} baseFormIds
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @instance
                 */
                ListFormsRequest.prototype.baseFormIds = $util.emptyArray;

                /**
                 * ListFormsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @instance
                 */
                ListFormsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a ListFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseFormIds != null && message.hasOwnProperty("baseFormIds")) {
                        if (!Array.isArray(message.baseFormIds))
                            return "baseFormIds: array expected";
                        for (let i = 0; i < message.baseFormIds.length; ++i)
                            if (!$util.isString(message.baseFormIds[i]))
                                return "baseFormIds: string[] expected";
                    }
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormsRequest} ListFormsRequest
                 */
                ListFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseFormIds) {
                        if (!Array.isArray(object.baseFormIds))
                            throw TypeError(".mtechnavi.api.form.ListFormsRequest.baseFormIds: array expected");
                        message.baseFormIds = [];
                        for (let i = 0; i < object.baseFormIds.length; ++i)
                            message.baseFormIds[i] = String(object.baseFormIds[i]);
                    }
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.form.ListFormsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListFormsRequest} message ListFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.baseFormIds = [];
                        object.formIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseFormIds && message.baseFormIds.length) {
                        object.baseFormIds = [];
                        for (let j = 0; j < message.baseFormIds.length; ++j)
                            object.baseFormIds[j] = message.baseFormIds[j];
                    }
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormsRequest;
            })();

            form.ListFormsResponse = (function() {

                /**
                 * Properties of a ListFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormsResponse
                 * @property {Long|null} [total] ListFormsResponse total
                 * @property {Array.<mtechnavi.api.form.IForm>|null} [items] ListFormsResponse items
                 * @property {string|null} [pageToken] ListFormsResponse pageToken
                 */

                /**
                 * Constructs a new ListFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormsResponse.
                 * @implements IListFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormsResponse=} [properties] Properties to set
                 */
                function ListFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @instance
                 */
                ListFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListFormsResponse items.
                 * @member {Array.<mtechnavi.api.form.IForm>} items
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @instance
                 */
                ListFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @instance
                 */
                ListFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.Form.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormsResponse} ListFormsResponse
                 */
                ListFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.Form.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListFormsResponse} message ListFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.Form.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormsResponse;
            })();

            form.GenerateFormRequest = (function() {

                /**
                 * Properties of a GenerateFormRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IGenerateFormRequest
                 * @property {string|null} [baseFormId] GenerateFormRequest baseFormId
                 * @property {Array.<string>|null} [baseFormIds] GenerateFormRequest baseFormIds
                 */

                /**
                 * Constructs a new GenerateFormRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a GenerateFormRequest.
                 * @implements IGenerateFormRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IGenerateFormRequest=} [properties] Properties to set
                 */
                function GenerateFormRequest(properties) {
                    this.baseFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GenerateFormRequest baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @instance
                 */
                GenerateFormRequest.prototype.baseFormId = "";

                /**
                 * GenerateFormRequest baseFormIds.
                 * @member {Array.<string>} baseFormIds
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @instance
                 */
                GenerateFormRequest.prototype.baseFormIds = $util.emptyArray;

                /**
                 * Verifies a GenerateFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.baseFormIds != null && message.hasOwnProperty("baseFormIds")) {
                        if (!Array.isArray(message.baseFormIds))
                            return "baseFormIds: array expected";
                        for (let i = 0; i < message.baseFormIds.length; ++i)
                            if (!$util.isString(message.baseFormIds[i]))
                                return "baseFormIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a GenerateFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.GenerateFormRequest} GenerateFormRequest
                 */
                GenerateFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.GenerateFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.GenerateFormRequest();
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.baseFormIds) {
                        if (!Array.isArray(object.baseFormIds))
                            throw TypeError(".mtechnavi.api.form.GenerateFormRequest.baseFormIds: array expected");
                        message.baseFormIds = [];
                        for (let i = 0; i < object.baseFormIds.length; ++i)
                            message.baseFormIds[i] = String(object.baseFormIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GenerateFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @static
                 * @param {mtechnavi.api.form.GenerateFormRequest} message GenerateFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.baseFormIds = [];
                    if (options.defaults)
                        object.baseFormId = "";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.baseFormIds && message.baseFormIds.length) {
                        object.baseFormIds = [];
                        for (let j = 0; j < message.baseFormIds.length; ++j)
                            object.baseFormIds[j] = message.baseFormIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this GenerateFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.GenerateFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GenerateFormRequest;
            })();

            form.GenerateFormResponse = (function() {

                /**
                 * Properties of a GenerateFormResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IGenerateFormResponse
                 * @property {mtechnavi.api.form.IForm|null} [form] GenerateFormResponse form
                 * @property {Array.<mtechnavi.api.form.IForm>|null} [forms] GenerateFormResponse forms
                 */

                /**
                 * Constructs a new GenerateFormResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a GenerateFormResponse.
                 * @implements IGenerateFormResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IGenerateFormResponse=} [properties] Properties to set
                 */
                function GenerateFormResponse(properties) {
                    this.forms = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GenerateFormResponse form.
                 * @member {mtechnavi.api.form.IForm|null|undefined} form
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @instance
                 */
                GenerateFormResponse.prototype.form = null;

                /**
                 * GenerateFormResponse forms.
                 * @member {Array.<mtechnavi.api.form.IForm>} forms
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @instance
                 */
                GenerateFormResponse.prototype.forms = $util.emptyArray;

                /**
                 * Verifies a GenerateFormResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GenerateFormResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.form != null && message.hasOwnProperty("form")) {
                        let error = $root.mtechnavi.api.form.Form.verify(message.form);
                        if (error)
                            return "form." + error;
                    }
                    if (message.forms != null && message.hasOwnProperty("forms")) {
                        if (!Array.isArray(message.forms))
                            return "forms: array expected";
                        for (let i = 0; i < message.forms.length; ++i) {
                            let error = $root.mtechnavi.api.form.Form.verify(message.forms[i]);
                            if (error)
                                return "forms." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GenerateFormResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.GenerateFormResponse} GenerateFormResponse
                 */
                GenerateFormResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.GenerateFormResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.GenerateFormResponse();
                    if (object.form != null) {
                        if (typeof object.form !== "object")
                            throw TypeError(".mtechnavi.api.form.GenerateFormResponse.form: object expected");
                        message.form = $root.mtechnavi.api.form.Form.fromObject(object.form);
                    }
                    if (object.forms) {
                        if (!Array.isArray(object.forms))
                            throw TypeError(".mtechnavi.api.form.GenerateFormResponse.forms: array expected");
                        message.forms = [];
                        for (let i = 0; i < object.forms.length; ++i) {
                            if (typeof object.forms[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.GenerateFormResponse.forms: object expected");
                            message.forms[i] = $root.mtechnavi.api.form.Form.fromObject(object.forms[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GenerateFormResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @static
                 * @param {mtechnavi.api.form.GenerateFormResponse} message GenerateFormResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GenerateFormResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.forms = [];
                    if (options.defaults)
                        object.form = null;
                    if (message.form != null && message.hasOwnProperty("form"))
                        object.form = $root.mtechnavi.api.form.Form.toObject(message.form, options);
                    if (message.forms && message.forms.length) {
                        object.forms = [];
                        for (let j = 0; j < message.forms.length; ++j)
                            object.forms[j] = $root.mtechnavi.api.form.Form.toObject(message.forms[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GenerateFormResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.GenerateFormResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GenerateFormResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GenerateFormResponse;
            })();

            form.DeleteFormWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a DeleteFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IDeleteFormWithRelatedItemsRequest
                 * @property {string|null} [formId] DeleteFormWithRelatedItemsRequest formId
                 */

                /**
                 * Constructs a new DeleteFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a DeleteFormWithRelatedItemsRequest.
                 * @implements IDeleteFormWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IDeleteFormWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function DeleteFormWithRelatedItemsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteFormWithRelatedItemsRequest formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.DeleteFormWithRelatedItemsRequest
                 * @instance
                 */
                DeleteFormWithRelatedItemsRequest.prototype.formId = "";

                /**
                 * Verifies a DeleteFormWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.DeleteFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteFormWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    return null;
                };

                /**
                 * Creates a DeleteFormWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.DeleteFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.DeleteFormWithRelatedItemsRequest} DeleteFormWithRelatedItemsRequest
                 */
                DeleteFormWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.DeleteFormWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.DeleteFormWithRelatedItemsRequest();
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteFormWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.DeleteFormWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.DeleteFormWithRelatedItemsRequest} message DeleteFormWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteFormWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.formId = "";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    return object;
                };

                /**
                 * Converts this DeleteFormWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.DeleteFormWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteFormWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteFormWithRelatedItemsRequest;
            })();

            form.SharetoFormWithRelatedItemsRequest = (function() {

                /**
                 * Properties of a SharetoFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface ISharetoFormWithRelatedItemsRequest
                 * @property {Array.<string>|null} [formIds] SharetoFormWithRelatedItemsRequest formIds
                 * @property {Array.<string>|null} [companyIds] SharetoFormWithRelatedItemsRequest companyIds
                 */

                /**
                 * Constructs a new SharetoFormWithRelatedItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SharetoFormWithRelatedItemsRequest.
                 * @implements ISharetoFormWithRelatedItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.ISharetoFormWithRelatedItemsRequest=} [properties] Properties to set
                 */
                function SharetoFormWithRelatedItemsRequest(properties) {
                    this.formIds = [];
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoFormWithRelatedItemsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @instance
                 */
                SharetoFormWithRelatedItemsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * SharetoFormWithRelatedItemsRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @instance
                 */
                SharetoFormWithRelatedItemsRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharetoFormWithRelatedItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoFormWithRelatedItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharetoFormWithRelatedItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.SharetoFormWithRelatedItemsRequest} SharetoFormWithRelatedItemsRequest
                 */
                SharetoFormWithRelatedItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.SharetoFormWithRelatedItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.SharetoFormWithRelatedItemsRequest();
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.form.SharetoFormWithRelatedItemsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.form.SharetoFormWithRelatedItemsRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoFormWithRelatedItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.SharetoFormWithRelatedItemsRequest} message SharetoFormWithRelatedItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoFormWithRelatedItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.formIds = [];
                        object.companyIds = [];
                    }
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharetoFormWithRelatedItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoFormWithRelatedItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoFormWithRelatedItemsRequest;
            })();

            form.SharetoFormWithRelatedItemsResponse = (function() {

                /**
                 * Properties of a SharetoFormWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface ISharetoFormWithRelatedItemsResponse
                 */

                /**
                 * Constructs a new SharetoFormWithRelatedItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SharetoFormWithRelatedItemsResponse.
                 * @implements ISharetoFormWithRelatedItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.ISharetoFormWithRelatedItemsResponse=} [properties] Properties to set
                 */
                function SharetoFormWithRelatedItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoFormWithRelatedItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoFormWithRelatedItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoFormWithRelatedItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.SharetoFormWithRelatedItemsResponse} SharetoFormWithRelatedItemsResponse
                 */
                SharetoFormWithRelatedItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.SharetoFormWithRelatedItemsResponse)
                        return object;
                    return new $root.mtechnavi.api.form.SharetoFormWithRelatedItemsResponse();
                };

                /**
                 * Creates a plain object from a SharetoFormWithRelatedItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsResponse
                 * @static
                 * @param {mtechnavi.api.form.SharetoFormWithRelatedItemsResponse} message SharetoFormWithRelatedItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoFormWithRelatedItemsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoFormWithRelatedItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.SharetoFormWithRelatedItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoFormWithRelatedItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoFormWithRelatedItemsResponse;
            })();

            form.ListFormSettingsRequest = (function() {

                /**
                 * Properties of a ListFormSettingsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormSettingsRequest
                 * @property {string|null} [pageToken] ListFormSettingsRequest pageToken
                 * @property {Array.<string>|null} [formIds] ListFormSettingsRequest formIds
                 */

                /**
                 * Constructs a new ListFormSettingsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormSettingsRequest.
                 * @implements IListFormSettingsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormSettingsRequest=} [properties] Properties to set
                 */
                function ListFormSettingsRequest(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormSettingsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @instance
                 */
                ListFormSettingsRequest.prototype.pageToken = "";

                /**
                 * ListFormSettingsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @instance
                 */
                ListFormSettingsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a ListFormSettingsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormSettingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListFormSettingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormSettingsRequest} ListFormSettingsRequest
                 */
                ListFormSettingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormSettingsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormSettingsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.form.ListFormSettingsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormSettingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListFormSettingsRequest} message ListFormSettingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormSettingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListFormSettingsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormSettingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormSettingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormSettingsRequest;
            })();

            form.ListFormSettingsResponse = (function() {

                /**
                 * Properties of a ListFormSettingsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormSettingsResponse
                 * @property {Long|null} [total] ListFormSettingsResponse total
                 * @property {Array.<mtechnavi.api.form.IFormSetting>|null} [items] ListFormSettingsResponse items
                 * @property {string|null} [pageToken] ListFormSettingsResponse pageToken
                 */

                /**
                 * Constructs a new ListFormSettingsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormSettingsResponse.
                 * @implements IListFormSettingsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormSettingsResponse=} [properties] Properties to set
                 */
                function ListFormSettingsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormSettingsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @instance
                 */
                ListFormSettingsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListFormSettingsResponse items.
                 * @member {Array.<mtechnavi.api.form.IFormSetting>} items
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @instance
                 */
                ListFormSettingsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListFormSettingsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @instance
                 */
                ListFormSettingsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListFormSettingsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormSettingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormSetting.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListFormSettingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormSettingsResponse} ListFormSettingsResponse
                 */
                ListFormSettingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormSettingsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormSettingsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListFormSettingsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListFormSettingsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormSetting.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormSettingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListFormSettingsResponse} message ListFormSettingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormSettingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormSetting.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListFormSettingsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormSettingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormSettingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormSettingsResponse;
            })();

            form.ListFormSettingItemsRequest = (function() {

                /**
                 * Properties of a ListFormSettingItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormSettingItemsRequest
                 * @property {string|null} [pageToken] ListFormSettingItemsRequest pageToken
                 * @property {Array.<string>|null} [formIds] ListFormSettingItemsRequest formIds
                 */

                /**
                 * Constructs a new ListFormSettingItemsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormSettingItemsRequest.
                 * @implements IListFormSettingItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormSettingItemsRequest=} [properties] Properties to set
                 */
                function ListFormSettingItemsRequest(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormSettingItemsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @instance
                 */
                ListFormSettingItemsRequest.prototype.pageToken = "";

                /**
                 * ListFormSettingItemsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @instance
                 */
                ListFormSettingItemsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a ListFormSettingItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormSettingItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListFormSettingItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormSettingItemsRequest} ListFormSettingItemsRequest
                 */
                ListFormSettingItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormSettingItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormSettingItemsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.form.ListFormSettingItemsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormSettingItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListFormSettingItemsRequest} message ListFormSettingItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormSettingItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListFormSettingItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormSettingItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormSettingItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormSettingItemsRequest;
            })();

            form.ListFormSettingItemsResponse = (function() {

                /**
                 * Properties of a ListFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormSettingItemsResponse
                 * @property {Long|null} [total] ListFormSettingItemsResponse total
                 * @property {Array.<mtechnavi.api.form.IFormSettingItem>|null} [items] ListFormSettingItemsResponse items
                 * @property {string|null} [pageToken] ListFormSettingItemsResponse pageToken
                 */

                /**
                 * Constructs a new ListFormSettingItemsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormSettingItemsResponse.
                 * @implements IListFormSettingItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormSettingItemsResponse=} [properties] Properties to set
                 */
                function ListFormSettingItemsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormSettingItemsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @instance
                 */
                ListFormSettingItemsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListFormSettingItemsResponse items.
                 * @member {Array.<mtechnavi.api.form.IFormSettingItem>} items
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @instance
                 */
                ListFormSettingItemsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListFormSettingItemsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @instance
                 */
                ListFormSettingItemsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListFormSettingItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormSettingItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormSettingItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListFormSettingItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormSettingItemsResponse} ListFormSettingItemsResponse
                 */
                ListFormSettingItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormSettingItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormSettingItemsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListFormSettingItemsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListFormSettingItemsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormSettingItem.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormSettingItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListFormSettingItemsResponse} message ListFormSettingItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormSettingItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormSettingItem.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListFormSettingItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormSettingItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormSettingItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormSettingItemsResponse;
            })();

            form.ListFormValuesRequest = (function() {

                /**
                 * Properties of a ListFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormValuesRequest
                 * @property {string|null} [pageToken] ListFormValuesRequest pageToken
                 * @property {string|null} [typeName] ListFormValuesRequest typeName
                 * @property {string|null} [recordId] ListFormValuesRequest recordId
                 * @property {Array.<string>|null} [formIds] ListFormValuesRequest formIds
                 * @property {Array.<string>|null} [tags] ListFormValuesRequest tags
                 */

                /**
                 * Constructs a new ListFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormValuesRequest.
                 * @implements IListFormValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormValuesRequest=} [properties] Properties to set
                 */
                function ListFormValuesRequest(properties) {
                    this.formIds = [];
                    this.tags = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormValuesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 */
                ListFormValuesRequest.prototype.pageToken = "";

                /**
                 * ListFormValuesRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 */
                ListFormValuesRequest.prototype.typeName = "";

                /**
                 * ListFormValuesRequest recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 */
                ListFormValuesRequest.prototype.recordId = "";

                /**
                 * ListFormValuesRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 */
                ListFormValuesRequest.prototype.formIds = $util.emptyArray;

                /**
                 * ListFormValuesRequest tags.
                 * @member {Array.<string>} tags
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 */
                ListFormValuesRequest.prototype.tags = $util.emptyArray;

                /**
                 * Verifies a ListFormValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    if (message.tags != null && message.hasOwnProperty("tags")) {
                        if (!Array.isArray(message.tags))
                            return "tags: array expected";
                        for (let i = 0; i < message.tags.length; ++i)
                            if (!$util.isString(message.tags[i]))
                                return "tags: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListFormValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormValuesRequest} ListFormValuesRequest
                 */
                ListFormValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormValuesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.form.ListFormValuesRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    if (object.tags) {
                        if (!Array.isArray(object.tags))
                            throw TypeError(".mtechnavi.api.form.ListFormValuesRequest.tags: array expected");
                        message.tags = [];
                        for (let i = 0; i < object.tags.length; ++i)
                            message.tags[i] = String(object.tags[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @static
                 * @param {mtechnavi.api.form.ListFormValuesRequest} message ListFormValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.formIds = [];
                        object.tags = [];
                    }
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    if (message.tags && message.tags.length) {
                        object.tags = [];
                        for (let j = 0; j < message.tags.length; ++j)
                            object.tags[j] = message.tags[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListFormValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormValuesRequest;
            })();

            form.ListFormValuesResponse = (function() {

                /**
                 * Properties of a ListFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListFormValuesResponse
                 * @property {Long|null} [total] ListFormValuesResponse total
                 * @property {Array.<mtechnavi.api.form.IFormValue>|null} [items] ListFormValuesResponse items
                 * @property {string|null} [pageToken] ListFormValuesResponse pageToken
                 */

                /**
                 * Constructs a new ListFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListFormValuesResponse.
                 * @implements IListFormValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListFormValuesResponse=} [properties] Properties to set
                 */
                function ListFormValuesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListFormValuesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @instance
                 */
                ListFormValuesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListFormValuesResponse items.
                 * @member {Array.<mtechnavi.api.form.IFormValue>} items
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @instance
                 */
                ListFormValuesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListFormValuesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @instance
                 */
                ListFormValuesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListFormValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListFormValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormValue.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListFormValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListFormValuesResponse} ListFormValuesResponse
                 */
                ListFormValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListFormValuesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListFormValuesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListFormValuesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListFormValuesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormValue.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListFormValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @static
                 * @param {mtechnavi.api.form.ListFormValuesResponse} message ListFormValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListFormValuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormValue.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListFormValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListFormValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListFormValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListFormValuesResponse;
            })();

            form.BulkWriteFormValuesRequest = (function() {

                /**
                 * Properties of a BulkWriteFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IBulkWriteFormValuesRequest
                 * @property {Array.<mtechnavi.api.form.IFormValue>|null} [items] BulkWriteFormValuesRequest items
                 * @property {string|null} [tag] BulkWriteFormValuesRequest tag
                 */

                /**
                 * Constructs a new BulkWriteFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BulkWriteFormValuesRequest.
                 * @implements IBulkWriteFormValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IBulkWriteFormValuesRequest=} [properties] Properties to set
                 */
                function BulkWriteFormValuesRequest(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteFormValuesRequest items.
                 * @member {Array.<mtechnavi.api.form.IFormValue>} items
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @instance
                 */
                BulkWriteFormValuesRequest.prototype.items = $util.emptyArray;

                /**
                 * BulkWriteFormValuesRequest tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @instance
                 */
                BulkWriteFormValuesRequest.prototype.tag = "";

                /**
                 * Verifies a BulkWriteFormValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteFormValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormValue.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    return null;
                };

                /**
                 * Creates a BulkWriteFormValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BulkWriteFormValuesRequest} BulkWriteFormValuesRequest
                 */
                BulkWriteFormValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BulkWriteFormValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.BulkWriteFormValuesRequest();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.BulkWriteFormValuesRequest.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.BulkWriteFormValuesRequest.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormValue.fromObject(object.items[i]);
                        }
                    }
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteFormValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @static
                 * @param {mtechnavi.api.form.BulkWriteFormValuesRequest} message BulkWriteFormValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteFormValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        object.tag = "";
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormValue.toObject(message.items[j], options);
                    }
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    return object;
                };

                /**
                 * Converts this BulkWriteFormValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteFormValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteFormValuesRequest;
            })();

            form.BulkWriteFormValuesResponse = (function() {

                /**
                 * Properties of a BulkWriteFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IBulkWriteFormValuesResponse
                 * @property {Array.<mtechnavi.api.form.IFormValue>|null} [items] BulkWriteFormValuesResponse items
                 */

                /**
                 * Constructs a new BulkWriteFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BulkWriteFormValuesResponse.
                 * @implements IBulkWriteFormValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IBulkWriteFormValuesResponse=} [properties] Properties to set
                 */
                function BulkWriteFormValuesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkWriteFormValuesResponse items.
                 * @member {Array.<mtechnavi.api.form.IFormValue>} items
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesResponse
                 * @instance
                 */
                BulkWriteFormValuesResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a BulkWriteFormValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkWriteFormValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormValue.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a BulkWriteFormValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BulkWriteFormValuesResponse} BulkWriteFormValuesResponse
                 */
                BulkWriteFormValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BulkWriteFormValuesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.BulkWriteFormValuesResponse();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.BulkWriteFormValuesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.BulkWriteFormValuesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormValue.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BulkWriteFormValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesResponse
                 * @static
                 * @param {mtechnavi.api.form.BulkWriteFormValuesResponse} message BulkWriteFormValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkWriteFormValuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormValue.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BulkWriteFormValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BulkWriteFormValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkWriteFormValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkWriteFormValuesResponse;
            })();

            form.SharetoFormValuesRequest = (function() {

                /**
                 * Properties of a SharetoFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @interface ISharetoFormValuesRequest
                 * @property {Array.<string>|null} [formValueIds] SharetoFormValuesRequest formValueIds
                 * @property {Array.<string>|null} [companyIds] SharetoFormValuesRequest companyIds
                 */

                /**
                 * Constructs a new SharetoFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SharetoFormValuesRequest.
                 * @implements ISharetoFormValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.form.ISharetoFormValuesRequest=} [properties] Properties to set
                 */
                function SharetoFormValuesRequest(properties) {
                    this.formValueIds = [];
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoFormValuesRequest formValueIds.
                 * @member {Array.<string>} formValueIds
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @instance
                 */
                SharetoFormValuesRequest.prototype.formValueIds = $util.emptyArray;

                /**
                 * SharetoFormValuesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @instance
                 */
                SharetoFormValuesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a SharetoFormValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoFormValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formValueIds != null && message.hasOwnProperty("formValueIds")) {
                        if (!Array.isArray(message.formValueIds))
                            return "formValueIds: array expected";
                        for (let i = 0; i < message.formValueIds.length; ++i)
                            if (!$util.isString(message.formValueIds[i]))
                                return "formValueIds: string[] expected";
                    }
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharetoFormValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.SharetoFormValuesRequest} SharetoFormValuesRequest
                 */
                SharetoFormValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.SharetoFormValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.SharetoFormValuesRequest();
                    if (object.formValueIds) {
                        if (!Array.isArray(object.formValueIds))
                            throw TypeError(".mtechnavi.api.form.SharetoFormValuesRequest.formValueIds: array expected");
                        message.formValueIds = [];
                        for (let i = 0; i < object.formValueIds.length; ++i)
                            message.formValueIds[i] = String(object.formValueIds[i]);
                    }
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.form.SharetoFormValuesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoFormValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @static
                 * @param {mtechnavi.api.form.SharetoFormValuesRequest} message SharetoFormValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoFormValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.formValueIds = [];
                        object.companyIds = [];
                    }
                    if (message.formValueIds && message.formValueIds.length) {
                        object.formValueIds = [];
                        for (let j = 0; j < message.formValueIds.length; ++j)
                            object.formValueIds[j] = message.formValueIds[j];
                    }
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharetoFormValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.SharetoFormValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoFormValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoFormValuesRequest;
            })();

            form.SharetoFormValuesResponse = (function() {

                /**
                 * Properties of a SharetoFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @interface ISharetoFormValuesResponse
                 */

                /**
                 * Constructs a new SharetoFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SharetoFormValuesResponse.
                 * @implements ISharetoFormValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.form.ISharetoFormValuesResponse=} [properties] Properties to set
                 */
                function SharetoFormValuesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoFormValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.SharetoFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoFormValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoFormValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.SharetoFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.SharetoFormValuesResponse} SharetoFormValuesResponse
                 */
                SharetoFormValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.SharetoFormValuesResponse)
                        return object;
                    return new $root.mtechnavi.api.form.SharetoFormValuesResponse();
                };

                /**
                 * Creates a plain object from a SharetoFormValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.SharetoFormValuesResponse
                 * @static
                 * @param {mtechnavi.api.form.SharetoFormValuesResponse} message SharetoFormValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoFormValuesResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoFormValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.SharetoFormValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoFormValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoFormValuesResponse;
            })();

            form.ImportFormValuesRequest = (function() {

                /**
                 * Properties of an ImportFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IImportFormValuesRequest
                 * @property {Array.<mtechnavi.api.form.IFormValue>|null} [items] ImportFormValuesRequest items
                 * @property {string|null} [tag] ImportFormValuesRequest tag
                 */

                /**
                 * Constructs a new ImportFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ImportFormValuesRequest.
                 * @implements IImportFormValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IImportFormValuesRequest=} [properties] Properties to set
                 */
                function ImportFormValuesRequest(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportFormValuesRequest items.
                 * @member {Array.<mtechnavi.api.form.IFormValue>} items
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @instance
                 */
                ImportFormValuesRequest.prototype.items = $util.emptyArray;

                /**
                 * ImportFormValuesRequest tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @instance
                 */
                ImportFormValuesRequest.prototype.tag = "";

                /**
                 * Verifies an ImportFormValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportFormValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormValue.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    return null;
                };

                /**
                 * Creates an ImportFormValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ImportFormValuesRequest} ImportFormValuesRequest
                 */
                ImportFormValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ImportFormValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ImportFormValuesRequest();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ImportFormValuesRequest.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ImportFormValuesRequest.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormValue.fromObject(object.items[i]);
                        }
                    }
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportFormValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @static
                 * @param {mtechnavi.api.form.ImportFormValuesRequest} message ImportFormValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportFormValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        object.tag = "";
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormValue.toObject(message.items[j], options);
                    }
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    return object;
                };

                /**
                 * Converts this ImportFormValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ImportFormValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportFormValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportFormValuesRequest;
            })();

            form.ImportFormValuesResponse = (function() {

                /**
                 * Properties of an ImportFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IImportFormValuesResponse
                 * @property {Array.<mtechnavi.api.form.IImportFormValueValidationError>|null} [errors] ImportFormValuesResponse errors
                 */

                /**
                 * Constructs a new ImportFormValuesResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ImportFormValuesResponse.
                 * @implements IImportFormValuesResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IImportFormValuesResponse=} [properties] Properties to set
                 */
                function ImportFormValuesResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportFormValuesResponse errors.
                 * @member {Array.<mtechnavi.api.form.IImportFormValueValidationError>} errors
                 * @memberof mtechnavi.api.form.ImportFormValuesResponse
                 * @instance
                 */
                ImportFormValuesResponse.prototype.errors = $util.emptyArray;

                /**
                 * Verifies an ImportFormValuesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ImportFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportFormValuesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.mtechnavi.api.form.ImportFormValueValidationError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an ImportFormValuesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ImportFormValuesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ImportFormValuesResponse} ImportFormValuesResponse
                 */
                ImportFormValuesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ImportFormValuesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ImportFormValuesResponse();
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.form.ImportFormValuesResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ImportFormValuesResponse.errors: object expected");
                            message.errors[i] = $root.mtechnavi.api.form.ImportFormValueValidationError.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ImportFormValuesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ImportFormValuesResponse
                 * @static
                 * @param {mtechnavi.api.form.ImportFormValuesResponse} message ImportFormValuesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportFormValuesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.mtechnavi.api.form.ImportFormValueValidationError.toObject(message.errors[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ImportFormValuesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ImportFormValuesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportFormValuesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportFormValuesResponse;
            })();

            form.ListSampleWorkFormsRequest = (function() {

                /**
                 * Properties of a ListSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IListSampleWorkFormsRequest
                 * @property {string|null} [pageToken] ListSampleWorkFormsRequest pageToken
                 */

                /**
                 * Constructs a new ListSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListSampleWorkFormsRequest.
                 * @implements IListSampleWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IListSampleWorkFormsRequest=} [properties] Properties to set
                 */
                function ListSampleWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSampleWorkFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsRequest
                 * @instance
                 */
                ListSampleWorkFormsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListSampleWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSampleWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSampleWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListSampleWorkFormsRequest} ListSampleWorkFormsRequest
                 */
                ListSampleWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListSampleWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListSampleWorkFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSampleWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.form.ListSampleWorkFormsRequest} message ListSampleWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSampleWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSampleWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSampleWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSampleWorkFormsRequest;
            })();

            form.ListSampleWorkFormsResponse = (function() {

                /**
                 * Properties of a ListSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IListSampleWorkFormsResponse
                 * @property {Long|null} [total] ListSampleWorkFormsResponse total
                 * @property {Array.<mtechnavi.api.form.ISampleWorkForm>|null} [items] ListSampleWorkFormsResponse items
                 * @property {string|null} [pageToken] ListSampleWorkFormsResponse pageToken
                 */

                /**
                 * Constructs a new ListSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a ListSampleWorkFormsResponse.
                 * @implements IListSampleWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IListSampleWorkFormsResponse=} [properties] Properties to set
                 */
                function ListSampleWorkFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSampleWorkFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @instance
                 */
                ListSampleWorkFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSampleWorkFormsResponse items.
                 * @member {Array.<mtechnavi.api.form.ISampleWorkForm>} items
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @instance
                 */
                ListSampleWorkFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSampleWorkFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @instance
                 */
                ListSampleWorkFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSampleWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSampleWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.SampleWorkForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSampleWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ListSampleWorkFormsResponse} ListSampleWorkFormsResponse
                 */
                ListSampleWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ListSampleWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ListSampleWorkFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.ListSampleWorkFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.ListSampleWorkFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.SampleWorkForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSampleWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.form.ListSampleWorkFormsResponse} message ListSampleWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSampleWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.SampleWorkForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSampleWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ListSampleWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSampleWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSampleWorkFormsResponse;
            })();

            form.CreateSampleWorkFormRequest = (function() {

                /**
                 * Properties of a CreateSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @interface ICreateSampleWorkFormRequest
                 * @property {mtechnavi.api.form.ISampleWorkForm|null} [sampleWorkForm] CreateSampleWorkFormRequest sampleWorkForm
                 */

                /**
                 * Constructs a new CreateSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a CreateSampleWorkFormRequest.
                 * @implements ICreateSampleWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.form.ICreateSampleWorkFormRequest=} [properties] Properties to set
                 */
                function CreateSampleWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSampleWorkFormRequest sampleWorkForm.
                 * @member {mtechnavi.api.form.ISampleWorkForm|null|undefined} sampleWorkForm
                 * @memberof mtechnavi.api.form.CreateSampleWorkFormRequest
                 * @instance
                 */
                CreateSampleWorkFormRequest.prototype.sampleWorkForm = null;

                /**
                 * Verifies a CreateSampleWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.CreateSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSampleWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm")) {
                        let error = $root.mtechnavi.api.form.SampleWorkForm.verify(message.sampleWorkForm);
                        if (error)
                            return "sampleWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSampleWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.CreateSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.CreateSampleWorkFormRequest} CreateSampleWorkFormRequest
                 */
                CreateSampleWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.CreateSampleWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.CreateSampleWorkFormRequest();
                    if (object.sampleWorkForm != null) {
                        if (typeof object.sampleWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.form.CreateSampleWorkFormRequest.sampleWorkForm: object expected");
                        message.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.fromObject(object.sampleWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSampleWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.CreateSampleWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.form.CreateSampleWorkFormRequest} message CreateSampleWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSampleWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.sampleWorkForm = null;
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm"))
                        object.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.toObject(message.sampleWorkForm, options);
                    return object;
                };

                /**
                 * Converts this CreateSampleWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.CreateSampleWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSampleWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSampleWorkFormRequest;
            })();

            form.UpdateSampleWorkFormRequest = (function() {

                /**
                 * Properties of an UpdateSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IUpdateSampleWorkFormRequest
                 * @property {mtechnavi.api.form.ISampleWorkForm|null} [sampleWorkForm] UpdateSampleWorkFormRequest sampleWorkForm
                 */

                /**
                 * Constructs a new UpdateSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an UpdateSampleWorkFormRequest.
                 * @implements IUpdateSampleWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IUpdateSampleWorkFormRequest=} [properties] Properties to set
                 */
                function UpdateSampleWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSampleWorkFormRequest sampleWorkForm.
                 * @member {mtechnavi.api.form.ISampleWorkForm|null|undefined} sampleWorkForm
                 * @memberof mtechnavi.api.form.UpdateSampleWorkFormRequest
                 * @instance
                 */
                UpdateSampleWorkFormRequest.prototype.sampleWorkForm = null;

                /**
                 * Verifies an UpdateSampleWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.UpdateSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSampleWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm")) {
                        let error = $root.mtechnavi.api.form.SampleWorkForm.verify(message.sampleWorkForm);
                        if (error)
                            return "sampleWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSampleWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.UpdateSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.UpdateSampleWorkFormRequest} UpdateSampleWorkFormRequest
                 */
                UpdateSampleWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.UpdateSampleWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.UpdateSampleWorkFormRequest();
                    if (object.sampleWorkForm != null) {
                        if (typeof object.sampleWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.form.UpdateSampleWorkFormRequest.sampleWorkForm: object expected");
                        message.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.fromObject(object.sampleWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSampleWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.UpdateSampleWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.form.UpdateSampleWorkFormRequest} message UpdateSampleWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSampleWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.sampleWorkForm = null;
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm"))
                        object.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.toObject(message.sampleWorkForm, options);
                    return object;
                };

                /**
                 * Converts this UpdateSampleWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.UpdateSampleWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSampleWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSampleWorkFormRequest;
            })();

            form.DeleteSampleWorkFormRequest = (function() {

                /**
                 * Properties of a DeleteSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IDeleteSampleWorkFormRequest
                 * @property {mtechnavi.api.form.ISampleWorkForm|null} [sampleWorkForm] DeleteSampleWorkFormRequest sampleWorkForm
                 */

                /**
                 * Constructs a new DeleteSampleWorkFormRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a DeleteSampleWorkFormRequest.
                 * @implements IDeleteSampleWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IDeleteSampleWorkFormRequest=} [properties] Properties to set
                 */
                function DeleteSampleWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteSampleWorkFormRequest sampleWorkForm.
                 * @member {mtechnavi.api.form.ISampleWorkForm|null|undefined} sampleWorkForm
                 * @memberof mtechnavi.api.form.DeleteSampleWorkFormRequest
                 * @instance
                 */
                DeleteSampleWorkFormRequest.prototype.sampleWorkForm = null;

                /**
                 * Verifies a DeleteSampleWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.DeleteSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSampleWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm")) {
                        let error = $root.mtechnavi.api.form.SampleWorkForm.verify(message.sampleWorkForm);
                        if (error)
                            return "sampleWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteSampleWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.DeleteSampleWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.DeleteSampleWorkFormRequest} DeleteSampleWorkFormRequest
                 */
                DeleteSampleWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.DeleteSampleWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.DeleteSampleWorkFormRequest();
                    if (object.sampleWorkForm != null) {
                        if (typeof object.sampleWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.form.DeleteSampleWorkFormRequest.sampleWorkForm: object expected");
                        message.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.fromObject(object.sampleWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteSampleWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.DeleteSampleWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.form.DeleteSampleWorkFormRequest} message DeleteSampleWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSampleWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.sampleWorkForm = null;
                    if (message.sampleWorkForm != null && message.hasOwnProperty("sampleWorkForm"))
                        object.sampleWorkForm = $root.mtechnavi.api.form.SampleWorkForm.toObject(message.sampleWorkForm, options);
                    return object;
                };

                /**
                 * Converts this DeleteSampleWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.DeleteSampleWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSampleWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteSampleWorkFormRequest;
            })();

            form.ImportSampleWorkFormsRequest = (function() {

                /**
                 * Properties of an ImportSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IImportSampleWorkFormsRequest
                 * @property {string|null} [url] ImportSampleWorkFormsRequest url
                 */

                /**
                 * Constructs a new ImportSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ImportSampleWorkFormsRequest.
                 * @implements IImportSampleWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IImportSampleWorkFormsRequest=} [properties] Properties to set
                 */
                function ImportSampleWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportSampleWorkFormsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsRequest
                 * @instance
                 */
                ImportSampleWorkFormsRequest.prototype.url = "";

                /**
                 * Verifies an ImportSampleWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportSampleWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    return null;
                };

                /**
                 * Creates an ImportSampleWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ImportSampleWorkFormsRequest} ImportSampleWorkFormsRequest
                 */
                ImportSampleWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ImportSampleWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ImportSampleWorkFormsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportSampleWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.form.ImportSampleWorkFormsRequest} message ImportSampleWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportSampleWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.url = "";
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    return object;
                };

                /**
                 * Converts this ImportSampleWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportSampleWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportSampleWorkFormsRequest;
            })();

            form.ImportSampleWorkFormsResponse = (function() {

                /**
                 * Properties of an ImportSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IImportSampleWorkFormsResponse
                 * @property {string|null} [assetId] ImportSampleWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ImportSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ImportSampleWorkFormsResponse.
                 * @implements IImportSampleWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IImportSampleWorkFormsResponse=} [properties] Properties to set
                 */
                function ImportSampleWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportSampleWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsResponse
                 * @instance
                 */
                ImportSampleWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportSampleWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportSampleWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportSampleWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ImportSampleWorkFormsResponse} ImportSampleWorkFormsResponse
                 */
                ImportSampleWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ImportSampleWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ImportSampleWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportSampleWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.form.ImportSampleWorkFormsResponse} message ImportSampleWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportSampleWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportSampleWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ImportSampleWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportSampleWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportSampleWorkFormsResponse;
            })();

            form.ExportSampleWorkFormsRequest = (function() {

                /**
                 * Properties of an ExportSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IExportSampleWorkFormsRequest
                 * @property {string|null} [sampleWorkFormId] ExportSampleWorkFormsRequest sampleWorkFormId
                 */

                /**
                 * Constructs a new ExportSampleWorkFormsRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ExportSampleWorkFormsRequest.
                 * @implements IExportSampleWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IExportSampleWorkFormsRequest=} [properties] Properties to set
                 */
                function ExportSampleWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSampleWorkFormsRequest sampleWorkFormId.
                 * @member {string} sampleWorkFormId
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsRequest
                 * @instance
                 */
                ExportSampleWorkFormsRequest.prototype.sampleWorkFormId = "";

                /**
                 * Verifies an ExportSampleWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSampleWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sampleWorkFormId != null && message.hasOwnProperty("sampleWorkFormId"))
                        if (!$util.isString(message.sampleWorkFormId))
                            return "sampleWorkFormId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSampleWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ExportSampleWorkFormsRequest} ExportSampleWorkFormsRequest
                 */
                ExportSampleWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ExportSampleWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.ExportSampleWorkFormsRequest();
                    if (object.sampleWorkFormId != null)
                        message.sampleWorkFormId = String(object.sampleWorkFormId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSampleWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.form.ExportSampleWorkFormsRequest} message ExportSampleWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSampleWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.sampleWorkFormId = "";
                    if (message.sampleWorkFormId != null && message.hasOwnProperty("sampleWorkFormId"))
                        object.sampleWorkFormId = message.sampleWorkFormId;
                    return object;
                };

                /**
                 * Converts this ExportSampleWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSampleWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSampleWorkFormsRequest;
            })();

            form.ExportSampleWorkFormsResponse = (function() {

                /**
                 * Properties of an ExportSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @interface IExportSampleWorkFormsResponse
                 * @property {string|null} [assetId] ExportSampleWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ExportSampleWorkFormsResponse.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ExportSampleWorkFormsResponse.
                 * @implements IExportSampleWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.form.IExportSampleWorkFormsResponse=} [properties] Properties to set
                 */
                function ExportSampleWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSampleWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsResponse
                 * @instance
                 */
                ExportSampleWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportSampleWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSampleWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSampleWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ExportSampleWorkFormsResponse} ExportSampleWorkFormsResponse
                 */
                ExportSampleWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ExportSampleWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.form.ExportSampleWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSampleWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.form.ExportSampleWorkFormsResponse} message ExportSampleWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSampleWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportSampleWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ExportSampleWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSampleWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSampleWorkFormsResponse;
            })();

            form.BulkDeleteFormValuesRequest = (function() {

                /**
                 * Properties of a BulkDeleteFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @interface IBulkDeleteFormValuesRequest
                 * @property {Array.<string>|null} [formValueIds] BulkDeleteFormValuesRequest formValueIds
                 * @property {string|null} [formId] BulkDeleteFormValuesRequest formId
                 * @property {string|null} [tag] BulkDeleteFormValuesRequest tag
                 */

                /**
                 * Constructs a new BulkDeleteFormValuesRequest.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BulkDeleteFormValuesRequest.
                 * @implements IBulkDeleteFormValuesRequest
                 * @constructor
                 * @param {mtechnavi.api.form.IBulkDeleteFormValuesRequest=} [properties] Properties to set
                 */
                function BulkDeleteFormValuesRequest(properties) {
                    this.formValueIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BulkDeleteFormValuesRequest formValueIds.
                 * @member {Array.<string>} formValueIds
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @instance
                 */
                BulkDeleteFormValuesRequest.prototype.formValueIds = $util.emptyArray;

                /**
                 * BulkDeleteFormValuesRequest formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @instance
                 */
                BulkDeleteFormValuesRequest.prototype.formId = "";

                /**
                 * BulkDeleteFormValuesRequest tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @instance
                 */
                BulkDeleteFormValuesRequest.prototype.tag = "";

                /**
                 * Verifies a BulkDeleteFormValuesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BulkDeleteFormValuesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formValueIds != null && message.hasOwnProperty("formValueIds")) {
                        if (!Array.isArray(message.formValueIds))
                            return "formValueIds: array expected";
                        for (let i = 0; i < message.formValueIds.length; ++i)
                            if (!$util.isString(message.formValueIds[i]))
                                return "formValueIds: string[] expected";
                    }
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    return null;
                };

                /**
                 * Creates a BulkDeleteFormValuesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BulkDeleteFormValuesRequest} BulkDeleteFormValuesRequest
                 */
                BulkDeleteFormValuesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BulkDeleteFormValuesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.form.BulkDeleteFormValuesRequest();
                    if (object.formValueIds) {
                        if (!Array.isArray(object.formValueIds))
                            throw TypeError(".mtechnavi.api.form.BulkDeleteFormValuesRequest.formValueIds: array expected");
                        message.formValueIds = [];
                        for (let i = 0; i < object.formValueIds.length; ++i)
                            message.formValueIds[i] = String(object.formValueIds[i]);
                    }
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    return message;
                };

                /**
                 * Creates a plain object from a BulkDeleteFormValuesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @static
                 * @param {mtechnavi.api.form.BulkDeleteFormValuesRequest} message BulkDeleteFormValuesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BulkDeleteFormValuesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formValueIds = [];
                    if (options.defaults) {
                        object.formId = "";
                        object.tag = "";
                    }
                    if (message.formValueIds && message.formValueIds.length) {
                        object.formValueIds = [];
                        for (let j = 0; j < message.formValueIds.length; ++j)
                            object.formValueIds[j] = message.formValueIds[j];
                    }
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    return object;
                };

                /**
                 * Converts this BulkDeleteFormValuesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BulkDeleteFormValuesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BulkDeleteFormValuesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BulkDeleteFormValuesRequest;
            })();

            form.BaseForm = (function() {

                /**
                 * Properties of a BaseForm.
                 * @memberof mtechnavi.api.form
                 * @interface IBaseForm
                 * @property {string|null} [baseFormId] BaseForm baseFormId
                 * @property {mtechnavi.api.form.IFormProperties|null} [formProperties] BaseForm formProperties
                 * @property {boolean|null} [usable] BaseForm usable
                 * @property {Long|null} [createdAt] BaseForm createdAt
                 * @property {Long|null} [updatedAt] BaseForm updatedAt
                 * @property {Long|null} [deletedAt] BaseForm deletedAt
                 */

                /**
                 * Constructs a new BaseForm.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BaseForm.
                 * @implements IBaseForm
                 * @constructor
                 * @param {mtechnavi.api.form.IBaseForm=} [properties] Properties to set
                 */
                function BaseForm(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BaseForm baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.baseFormId = "";

                /**
                 * BaseForm formProperties.
                 * @member {mtechnavi.api.form.IFormProperties|null|undefined} formProperties
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.formProperties = null;

                /**
                 * BaseForm usable.
                 * @member {boolean} usable
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.usable = false;

                /**
                 * BaseForm createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseForm updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseForm deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 */
                BaseForm.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BaseForm message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BaseForm
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BaseForm.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.formProperties != null && message.hasOwnProperty("formProperties")) {
                        let error = $root.mtechnavi.api.form.FormProperties.verify(message.formProperties);
                        if (error)
                            return "formProperties." + error;
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        if (typeof message.usable !== "boolean")
                            return "usable: boolean expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BaseForm message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BaseForm
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BaseForm} BaseForm
                 */
                BaseForm.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BaseForm)
                        return object;
                    let message = new $root.mtechnavi.api.form.BaseForm();
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.formProperties != null) {
                        if (typeof object.formProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.BaseForm.formProperties: object expected");
                        message.formProperties = $root.mtechnavi.api.form.FormProperties.fromObject(object.formProperties);
                    }
                    if (object.usable != null)
                        message.usable = Boolean(object.usable);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BaseForm message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BaseForm
                 * @static
                 * @param {mtechnavi.api.form.BaseForm} message BaseForm
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BaseForm.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.baseFormId = "";
                        object.formProperties = null;
                        object.usable = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.formProperties != null && message.hasOwnProperty("formProperties"))
                        object.formProperties = $root.mtechnavi.api.form.FormProperties.toObject(message.formProperties, options);
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = message.usable;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BaseForm to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BaseForm
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BaseForm.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BaseForm;
            })();

            form.BaseFormSetting = (function() {

                /**
                 * Properties of a BaseFormSetting.
                 * @memberof mtechnavi.api.form
                 * @interface IBaseFormSetting
                 * @property {string|null} [baseFormSettingId] BaseFormSetting baseFormSettingId
                 * @property {string|null} [baseFormId] BaseFormSetting baseFormId
                 * @property {Array.<mtechnavi.api.form.IFormPage>|null} [formPages] BaseFormSetting formPages
                 * @property {Long|null} [latestItemAutoNameSequenceNumber] BaseFormSetting latestItemAutoNameSequenceNumber
                 * @property {Long|null} [createdAt] BaseFormSetting createdAt
                 * @property {Long|null} [updatedAt] BaseFormSetting updatedAt
                 * @property {Long|null} [deletedAt] BaseFormSetting deletedAt
                 */

                /**
                 * Constructs a new BaseFormSetting.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BaseFormSetting.
                 * @implements IBaseFormSetting
                 * @constructor
                 * @param {mtechnavi.api.form.IBaseFormSetting=} [properties] Properties to set
                 */
                function BaseFormSetting(properties) {
                    this.formPages = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BaseFormSetting baseFormSettingId.
                 * @member {string} baseFormSettingId
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.baseFormSettingId = "";

                /**
                 * BaseFormSetting baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.baseFormId = "";

                /**
                 * BaseFormSetting formPages.
                 * @member {Array.<mtechnavi.api.form.IFormPage>} formPages
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.formPages = $util.emptyArray;

                /**
                 * BaseFormSetting latestItemAutoNameSequenceNumber.
                 * @member {Long} latestItemAutoNameSequenceNumber
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.latestItemAutoNameSequenceNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseFormSetting createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseFormSetting updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseFormSetting deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 */
                BaseFormSetting.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BaseFormSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BaseFormSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseFormSettingId != null && message.hasOwnProperty("baseFormSettingId"))
                        if (!$util.isString(message.baseFormSettingId))
                            return "baseFormSettingId: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.formPages != null && message.hasOwnProperty("formPages")) {
                        if (!Array.isArray(message.formPages))
                            return "formPages: array expected";
                        for (let i = 0; i < message.formPages.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormPage.verify(message.formPages[i]);
                            if (error)
                                return "formPages." + error;
                        }
                    }
                    if (message.latestItemAutoNameSequenceNumber != null && message.hasOwnProperty("latestItemAutoNameSequenceNumber"))
                        if (!$util.isInteger(message.latestItemAutoNameSequenceNumber) && !(message.latestItemAutoNameSequenceNumber && $util.isInteger(message.latestItemAutoNameSequenceNumber.low) && $util.isInteger(message.latestItemAutoNameSequenceNumber.high)))
                            return "latestItemAutoNameSequenceNumber: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BaseFormSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BaseFormSetting} BaseFormSetting
                 */
                BaseFormSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BaseFormSetting)
                        return object;
                    let message = new $root.mtechnavi.api.form.BaseFormSetting();
                    if (object.baseFormSettingId != null)
                        message.baseFormSettingId = String(object.baseFormSettingId);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.formPages) {
                        if (!Array.isArray(object.formPages))
                            throw TypeError(".mtechnavi.api.form.BaseFormSetting.formPages: array expected");
                        message.formPages = [];
                        for (let i = 0; i < object.formPages.length; ++i) {
                            if (typeof object.formPages[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.BaseFormSetting.formPages: object expected");
                            message.formPages[i] = $root.mtechnavi.api.form.FormPage.fromObject(object.formPages[i]);
                        }
                    }
                    if (object.latestItemAutoNameSequenceNumber != null)
                        if ($util.Long)
                            (message.latestItemAutoNameSequenceNumber = $util.Long.fromValue(object.latestItemAutoNameSequenceNumber)).unsigned = false;
                        else if (typeof object.latestItemAutoNameSequenceNumber === "string")
                            message.latestItemAutoNameSequenceNumber = parseInt(object.latestItemAutoNameSequenceNumber, 10);
                        else if (typeof object.latestItemAutoNameSequenceNumber === "number")
                            message.latestItemAutoNameSequenceNumber = object.latestItemAutoNameSequenceNumber;
                        else if (typeof object.latestItemAutoNameSequenceNumber === "object")
                            message.latestItemAutoNameSequenceNumber = new $util.LongBits(object.latestItemAutoNameSequenceNumber.low >>> 0, object.latestItemAutoNameSequenceNumber.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BaseFormSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @static
                 * @param {mtechnavi.api.form.BaseFormSetting} message BaseFormSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BaseFormSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formPages = [];
                    if (options.defaults) {
                        object.baseFormSettingId = "";
                        object.baseFormId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.latestItemAutoNameSequenceNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.latestItemAutoNameSequenceNumber = options.longs === String ? "0" : 0;
                    }
                    if (message.baseFormSettingId != null && message.hasOwnProperty("baseFormSettingId"))
                        object.baseFormSettingId = message.baseFormSettingId;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.formPages && message.formPages.length) {
                        object.formPages = [];
                        for (let j = 0; j < message.formPages.length; ++j)
                            object.formPages[j] = $root.mtechnavi.api.form.FormPage.toObject(message.formPages[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.latestItemAutoNameSequenceNumber != null && message.hasOwnProperty("latestItemAutoNameSequenceNumber"))
                        if (typeof message.latestItemAutoNameSequenceNumber === "number")
                            object.latestItemAutoNameSequenceNumber = options.longs === String ? String(message.latestItemAutoNameSequenceNumber) : message.latestItemAutoNameSequenceNumber;
                        else
                            object.latestItemAutoNameSequenceNumber = options.longs === String ? $util.Long.prototype.toString.call(message.latestItemAutoNameSequenceNumber) : options.longs === Number ? new $util.LongBits(message.latestItemAutoNameSequenceNumber.low >>> 0, message.latestItemAutoNameSequenceNumber.high >>> 0).toNumber() : message.latestItemAutoNameSequenceNumber;
                    return object;
                };

                /**
                 * Converts this BaseFormSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BaseFormSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BaseFormSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BaseFormSetting;
            })();

            form.BaseFormSettingItem = (function() {

                /**
                 * Properties of a BaseFormSettingItem.
                 * @memberof mtechnavi.api.form
                 * @interface IBaseFormSettingItem
                 * @property {string|null} [baseFormSettingItemId] BaseFormSettingItem baseFormSettingItemId
                 * @property {string|null} [baseFormId] BaseFormSettingItem baseFormId
                 * @property {string|null} [baseFormSettingId] BaseFormSettingItem baseFormSettingId
                 * @property {string|null} [formPageId] BaseFormSettingItem formPageId
                 * @property {mtechnavi.api.form.IFormItem|null} [item] BaseFormSettingItem item
                 * @property {Long|null} [createdAt] BaseFormSettingItem createdAt
                 * @property {Long|null} [updatedAt] BaseFormSettingItem updatedAt
                 * @property {Long|null} [deletedAt] BaseFormSettingItem deletedAt
                 */

                /**
                 * Constructs a new BaseFormSettingItem.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a BaseFormSettingItem.
                 * @implements IBaseFormSettingItem
                 * @constructor
                 * @param {mtechnavi.api.form.IBaseFormSettingItem=} [properties] Properties to set
                 */
                function BaseFormSettingItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BaseFormSettingItem baseFormSettingItemId.
                 * @member {string} baseFormSettingItemId
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.baseFormSettingItemId = "";

                /**
                 * BaseFormSettingItem baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.baseFormId = "";

                /**
                 * BaseFormSettingItem baseFormSettingId.
                 * @member {string} baseFormSettingId
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.baseFormSettingId = "";

                /**
                 * BaseFormSettingItem formPageId.
                 * @member {string} formPageId
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.formPageId = "";

                /**
                 * BaseFormSettingItem item.
                 * @member {mtechnavi.api.form.IFormItem|null|undefined} item
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.item = null;

                /**
                 * BaseFormSettingItem createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseFormSettingItem updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseFormSettingItem deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 */
                BaseFormSettingItem.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BaseFormSettingItem message.
                 * @function verify
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BaseFormSettingItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseFormSettingItemId != null && message.hasOwnProperty("baseFormSettingItemId"))
                        if (!$util.isString(message.baseFormSettingItemId))
                            return "baseFormSettingItemId: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.baseFormSettingId != null && message.hasOwnProperty("baseFormSettingId"))
                        if (!$util.isString(message.baseFormSettingId))
                            return "baseFormSettingId: string expected";
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        if (!$util.isString(message.formPageId))
                            return "formPageId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.form.FormItem.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BaseFormSettingItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.BaseFormSettingItem} BaseFormSettingItem
                 */
                BaseFormSettingItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.BaseFormSettingItem)
                        return object;
                    let message = new $root.mtechnavi.api.form.BaseFormSettingItem();
                    if (object.baseFormSettingItemId != null)
                        message.baseFormSettingItemId = String(object.baseFormSettingItemId);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.baseFormSettingId != null)
                        message.baseFormSettingId = String(object.baseFormSettingId);
                    if (object.formPageId != null)
                        message.formPageId = String(object.formPageId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.form.BaseFormSettingItem.item: object expected");
                        message.item = $root.mtechnavi.api.form.FormItem.fromObject(object.item);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BaseFormSettingItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @static
                 * @param {mtechnavi.api.form.BaseFormSettingItem} message BaseFormSettingItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BaseFormSettingItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.baseFormSettingItemId = "";
                        object.baseFormId = "";
                        object.baseFormSettingId = "";
                        object.formPageId = "";
                        object.item = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.baseFormSettingItemId != null && message.hasOwnProperty("baseFormSettingItemId"))
                        object.baseFormSettingItemId = message.baseFormSettingItemId;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.baseFormSettingId != null && message.hasOwnProperty("baseFormSettingId"))
                        object.baseFormSettingId = message.baseFormSettingId;
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        object.formPageId = message.formPageId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.form.FormItem.toObject(message.item, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this BaseFormSettingItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.BaseFormSettingItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BaseFormSettingItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BaseFormSettingItem;
            })();

            form.Form = (function() {

                /**
                 * Properties of a Form.
                 * @memberof mtechnavi.api.form
                 * @interface IForm
                 * @property {string|null} [formId] Form formId
                 * @property {string|null} [baseFormId] Form baseFormId
                 * @property {mtechnavi.api.form.IFormProperties|null} [formProperties] Form formProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Form sharedProperties
                 * @property {Long|null} [createdAt] Form createdAt
                 * @property {Long|null} [updatedAt] Form updatedAt
                 * @property {Long|null} [deletedAt] Form deletedAt
                 */

                /**
                 * Constructs a new Form.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a Form.
                 * @implements IForm
                 * @constructor
                 * @param {mtechnavi.api.form.IForm=} [properties] Properties to set
                 */
                function Form(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Form formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.formId = "";

                /**
                 * Form baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.baseFormId = "";

                /**
                 * Form formProperties.
                 * @member {mtechnavi.api.form.IFormProperties|null|undefined} formProperties
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.formProperties = null;

                /**
                 * Form sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.sharedProperties = null;

                /**
                 * Form createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Form updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Form deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 */
                Form.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Form message.
                 * @function verify
                 * @memberof mtechnavi.api.form.Form
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Form.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.formProperties != null && message.hasOwnProperty("formProperties")) {
                        let error = $root.mtechnavi.api.form.FormProperties.verify(message.formProperties);
                        if (error)
                            return "formProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Form message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.Form
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.Form} Form
                 */
                Form.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.Form)
                        return object;
                    let message = new $root.mtechnavi.api.form.Form();
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.formProperties != null) {
                        if (typeof object.formProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.Form.formProperties: object expected");
                        message.formProperties = $root.mtechnavi.api.form.FormProperties.fromObject(object.formProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.Form.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Form message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.Form
                 * @static
                 * @param {mtechnavi.api.form.Form} message Form
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Form.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.formId = "";
                        object.baseFormId = "";
                        object.formProperties = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.formProperties != null && message.hasOwnProperty("formProperties"))
                        object.formProperties = $root.mtechnavi.api.form.FormProperties.toObject(message.formProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Form to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.Form
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Form.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Form;
            })();

            form.FormSetting = (function() {

                /**
                 * Properties of a FormSetting.
                 * @memberof mtechnavi.api.form
                 * @interface IFormSetting
                 * @property {string|null} [formSettingId] FormSetting formSettingId
                 * @property {string|null} [formId] FormSetting formId
                 * @property {Array.<mtechnavi.api.form.IFormPage>|null} [formPages] FormSetting formPages
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] FormSetting sharedProperties
                 * @property {Long|null} [createdAt] FormSetting createdAt
                 * @property {Long|null} [updatedAt] FormSetting updatedAt
                 * @property {Long|null} [deletedAt] FormSetting deletedAt
                 */

                /**
                 * Constructs a new FormSetting.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormSetting.
                 * @implements IFormSetting
                 * @constructor
                 * @param {mtechnavi.api.form.IFormSetting=} [properties] Properties to set
                 */
                function FormSetting(properties) {
                    this.formPages = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormSetting formSettingId.
                 * @member {string} formSettingId
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.formSettingId = "";

                /**
                 * FormSetting formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.formId = "";

                /**
                 * FormSetting formPages.
                 * @member {Array.<mtechnavi.api.form.IFormPage>} formPages
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.formPages = $util.emptyArray;

                /**
                 * FormSetting sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.sharedProperties = null;

                /**
                 * FormSetting createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormSetting updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormSetting deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 */
                FormSetting.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a FormSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formSettingId != null && message.hasOwnProperty("formSettingId"))
                        if (!$util.isString(message.formSettingId))
                            return "formSettingId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.formPages != null && message.hasOwnProperty("formPages")) {
                        if (!Array.isArray(message.formPages))
                            return "formPages: array expected";
                        for (let i = 0; i < message.formPages.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormPage.verify(message.formPages[i]);
                            if (error)
                                return "formPages." + error;
                        }
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FormSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormSetting} FormSetting
                 */
                FormSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormSetting)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormSetting();
                    if (object.formSettingId != null)
                        message.formSettingId = String(object.formSettingId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.formPages) {
                        if (!Array.isArray(object.formPages))
                            throw TypeError(".mtechnavi.api.form.FormSetting.formPages: array expected");
                        message.formPages = [];
                        for (let i = 0; i < object.formPages.length; ++i) {
                            if (typeof object.formPages[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.FormSetting.formPages: object expected");
                            message.formPages[i] = $root.mtechnavi.api.form.FormPage.fromObject(object.formPages[i]);
                        }
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.FormSetting.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a FormSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormSetting
                 * @static
                 * @param {mtechnavi.api.form.FormSetting} message FormSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formPages = [];
                    if (options.defaults) {
                        object.formSettingId = "";
                        object.formId = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.formSettingId != null && message.hasOwnProperty("formSettingId"))
                        object.formSettingId = message.formSettingId;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.formPages && message.formPages.length) {
                        object.formPages = [];
                        for (let j = 0; j < message.formPages.length; ++j)
                            object.formPages[j] = $root.mtechnavi.api.form.FormPage.toObject(message.formPages[j], options);
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this FormSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormSetting;
            })();

            form.FormSettingItem = (function() {

                /**
                 * Properties of a FormSettingItem.
                 * @memberof mtechnavi.api.form
                 * @interface IFormSettingItem
                 * @property {string|null} [formSettingItemId] FormSettingItem formSettingItemId
                 * @property {string|null} [formId] FormSettingItem formId
                 * @property {string|null} [formSettingId] FormSettingItem formSettingId
                 * @property {string|null} [formPageId] FormSettingItem formPageId
                 * @property {mtechnavi.api.form.IFormItem|null} [item] FormSettingItem item
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] FormSettingItem sharedProperties
                 * @property {Long|null} [createdAt] FormSettingItem createdAt
                 * @property {Long|null} [updatedAt] FormSettingItem updatedAt
                 * @property {Long|null} [deletedAt] FormSettingItem deletedAt
                 */

                /**
                 * Constructs a new FormSettingItem.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormSettingItem.
                 * @implements IFormSettingItem
                 * @constructor
                 * @param {mtechnavi.api.form.IFormSettingItem=} [properties] Properties to set
                 */
                function FormSettingItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormSettingItem formSettingItemId.
                 * @member {string} formSettingItemId
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.formSettingItemId = "";

                /**
                 * FormSettingItem formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.formId = "";

                /**
                 * FormSettingItem formSettingId.
                 * @member {string} formSettingId
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.formSettingId = "";

                /**
                 * FormSettingItem formPageId.
                 * @member {string} formPageId
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.formPageId = "";

                /**
                 * FormSettingItem item.
                 * @member {mtechnavi.api.form.IFormItem|null|undefined} item
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.item = null;

                /**
                 * FormSettingItem sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.sharedProperties = null;

                /**
                 * FormSettingItem createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormSettingItem updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormSettingItem deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 */
                FormSettingItem.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a FormSettingItem message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormSettingItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        if (!$util.isString(message.formSettingItemId))
                            return "formSettingItemId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.formSettingId != null && message.hasOwnProperty("formSettingId"))
                        if (!$util.isString(message.formSettingId))
                            return "formSettingId: string expected";
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        if (!$util.isString(message.formPageId))
                            return "formPageId: string expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.form.FormItem.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FormSettingItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormSettingItem} FormSettingItem
                 */
                FormSettingItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormSettingItem)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormSettingItem();
                    if (object.formSettingItemId != null)
                        message.formSettingItemId = String(object.formSettingItemId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.formSettingId != null)
                        message.formSettingId = String(object.formSettingId);
                    if (object.formPageId != null)
                        message.formPageId = String(object.formPageId);
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.form.FormSettingItem.item: object expected");
                        message.item = $root.mtechnavi.api.form.FormItem.fromObject(object.item);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.FormSettingItem.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a FormSettingItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @static
                 * @param {mtechnavi.api.form.FormSettingItem} message FormSettingItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormSettingItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.formSettingItemId = "";
                        object.formId = "";
                        object.formSettingId = "";
                        object.formPageId = "";
                        object.item = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        object.formSettingItemId = message.formSettingItemId;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.formSettingId != null && message.hasOwnProperty("formSettingId"))
                        object.formSettingId = message.formSettingId;
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        object.formPageId = message.formPageId;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.form.FormItem.toObject(message.item, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this FormSettingItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormSettingItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormSettingItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormSettingItem;
            })();

            form.FormValue = (function() {

                /**
                 * Properties of a FormValue.
                 * @memberof mtechnavi.api.form
                 * @interface IFormValue
                 * @property {string|null} [formValueId] FormValue formValueId
                 * @property {string|null} [formId] FormValue formId
                 * @property {string|null} [formSettingItemId] FormValue formSettingItemId
                 * @property {string|null} [itemAutoName] FormValue itemAutoName
                 * @property {string|null} [urn] FormValue urn
                 * @property {string|null} [typeName] FormValue typeName
                 * @property {string|null} [recordId] FormValue recordId
                 * @property {string|null} [tag] FormValue tag
                 * @property {mtechnavi.api.form.IItemValue|null} [input] FormValue input
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] FormValue sharedProperties
                 * @property {Long|null} [createdAt] FormValue createdAt
                 * @property {Long|null} [updatedAt] FormValue updatedAt
                 * @property {Long|null} [deletedAt] FormValue deletedAt
                 */

                /**
                 * Constructs a new FormValue.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormValue.
                 * @implements IFormValue
                 * @constructor
                 * @param {mtechnavi.api.form.IFormValue=} [properties] Properties to set
                 */
                function FormValue(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormValue formValueId.
                 * @member {string} formValueId
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.formValueId = "";

                /**
                 * FormValue formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.formId = "";

                /**
                 * FormValue formSettingItemId.
                 * @member {string} formSettingItemId
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.formSettingItemId = "";

                /**
                 * FormValue itemAutoName.
                 * @member {string} itemAutoName
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.itemAutoName = "";

                /**
                 * FormValue urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.urn = "";

                /**
                 * FormValue typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.typeName = "";

                /**
                 * FormValue recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.recordId = "";

                /**
                 * FormValue tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.tag = "";

                /**
                 * FormValue input.
                 * @member {mtechnavi.api.form.IItemValue|null|undefined} input
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.input = null;

                /**
                 * FormValue sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.sharedProperties = null;

                /**
                 * FormValue createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormValue updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormValue deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 */
                FormValue.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a FormValue message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formValueId != null && message.hasOwnProperty("formValueId"))
                        if (!$util.isString(message.formValueId))
                            return "formValueId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        if (!$util.isString(message.formSettingItemId))
                            return "formSettingItemId: string expected";
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        if (!$util.isString(message.itemAutoName))
                            return "itemAutoName: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.input != null && message.hasOwnProperty("input")) {
                        let error = $root.mtechnavi.api.form.ItemValue.verify(message.input);
                        if (error)
                            return "input." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FormValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormValue} FormValue
                 */
                FormValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormValue)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormValue();
                    if (object.formValueId != null)
                        message.formValueId = String(object.formValueId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.formSettingItemId != null)
                        message.formSettingItemId = String(object.formSettingItemId);
                    if (object.itemAutoName != null)
                        message.itemAutoName = String(object.itemAutoName);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    if (object.input != null) {
                        if (typeof object.input !== "object")
                            throw TypeError(".mtechnavi.api.form.FormValue.input: object expected");
                        message.input = $root.mtechnavi.api.form.ItemValue.fromObject(object.input);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.FormValue.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a FormValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormValue
                 * @static
                 * @param {mtechnavi.api.form.FormValue} message FormValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.formValueId = "";
                        object.formId = "";
                        object.formSettingItemId = "";
                        object.input = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.tag = "";
                        object.itemAutoName = "";
                    }
                    if (message.formValueId != null && message.hasOwnProperty("formValueId"))
                        object.formValueId = message.formValueId;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        object.formSettingItemId = message.formSettingItemId;
                    if (message.input != null && message.hasOwnProperty("input"))
                        object.input = $root.mtechnavi.api.form.ItemValue.toObject(message.input, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        object.itemAutoName = message.itemAutoName;
                    return object;
                };

                /**
                 * Converts this FormValue to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormValue;
            })();

            form.SampleWorkForm = (function() {

                /**
                 * Properties of a SampleWorkForm.
                 * @memberof mtechnavi.api.form
                 * @interface ISampleWorkForm
                 * @property {string|null} [sampleWorkFormId] SampleWorkForm sampleWorkFormId
                 * @property {string|null} [formId] SampleWorkForm formId
                 * @property {string|null} [baseDisplayName] SampleWorkForm baseDisplayName
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] SampleWorkForm managementOrganization
                 * @property {string|null} [displayName] SampleWorkForm displayName
                 * @property {sharelib.INameOption|null} [formType1] SampleWorkForm formType1
                 * @property {sharelib.INameOption|null} [formType2] SampleWorkForm formType2
                 * @property {sharelib.INameOption|null} [formType3] SampleWorkForm formType3
                 * @property {string|null} [attribute1] SampleWorkForm attribute1
                 * @property {string|null} [attribute2] SampleWorkForm attribute2
                 * @property {string|null} [attribute3] SampleWorkForm attribute3
                 * @property {string|null} [notice] SampleWorkForm notice
                 * @property {boolean|null} [required] SampleWorkForm required
                 * @property {sharelib.INameOption|null} [status] SampleWorkForm status
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SampleWorkForm sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SampleWorkForm createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SampleWorkForm updatedProperties
                 * @property {Long|null} [createdAt] SampleWorkForm createdAt
                 * @property {Long|null} [updatedAt] SampleWorkForm updatedAt
                 * @property {Long|null} [deletedAt] SampleWorkForm deletedAt
                 */

                /**
                 * Constructs a new SampleWorkForm.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a SampleWorkForm.
                 * @implements ISampleWorkForm
                 * @constructor
                 * @param {mtechnavi.api.form.ISampleWorkForm=} [properties] Properties to set
                 */
                function SampleWorkForm(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SampleWorkForm sampleWorkFormId.
                 * @member {string} sampleWorkFormId
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.sampleWorkFormId = "";

                /**
                 * SampleWorkForm formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.formId = "";

                /**
                 * SampleWorkForm baseDisplayName.
                 * @member {string} baseDisplayName
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.baseDisplayName = "";

                /**
                 * SampleWorkForm managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.managementOrganization = null;

                /**
                 * SampleWorkForm displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.displayName = "";

                /**
                 * SampleWorkForm formType1.
                 * @member {sharelib.INameOption|null|undefined} formType1
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.formType1 = null;

                /**
                 * SampleWorkForm formType2.
                 * @member {sharelib.INameOption|null|undefined} formType2
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.formType2 = null;

                /**
                 * SampleWorkForm formType3.
                 * @member {sharelib.INameOption|null|undefined} formType3
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.formType3 = null;

                /**
                 * SampleWorkForm attribute1.
                 * @member {string} attribute1
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.attribute1 = "";

                /**
                 * SampleWorkForm attribute2.
                 * @member {string} attribute2
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.attribute2 = "";

                /**
                 * SampleWorkForm attribute3.
                 * @member {string} attribute3
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.attribute3 = "";

                /**
                 * SampleWorkForm notice.
                 * @member {string} notice
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.notice = "";

                /**
                 * SampleWorkForm required.
                 * @member {boolean} required
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.required = false;

                /**
                 * SampleWorkForm status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.status = null;

                /**
                 * SampleWorkForm sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.sharedProperties = null;

                /**
                 * SampleWorkForm createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.createdProperties = null;

                /**
                 * SampleWorkForm updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.updatedProperties = null;

                /**
                 * SampleWorkForm createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SampleWorkForm updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SampleWorkForm deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 */
                SampleWorkForm.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SampleWorkForm message.
                 * @function verify
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SampleWorkForm.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sampleWorkFormId != null && message.hasOwnProperty("sampleWorkFormId"))
                        if (!$util.isString(message.sampleWorkFormId))
                            return "sampleWorkFormId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.baseDisplayName != null && message.hasOwnProperty("baseDisplayName"))
                        if (!$util.isString(message.baseDisplayName))
                            return "baseDisplayName: string expected";
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.formType1 != null && message.hasOwnProperty("formType1")) {
                        let error = $root.sharelib.NameOption.verify(message.formType1);
                        if (error)
                            return "formType1." + error;
                    }
                    if (message.formType2 != null && message.hasOwnProperty("formType2")) {
                        let error = $root.sharelib.NameOption.verify(message.formType2);
                        if (error)
                            return "formType2." + error;
                    }
                    if (message.formType3 != null && message.hasOwnProperty("formType3")) {
                        let error = $root.sharelib.NameOption.verify(message.formType3);
                        if (error)
                            return "formType3." + error;
                    }
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        if (!$util.isString(message.attribute1))
                            return "attribute1: string expected";
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        if (!$util.isString(message.attribute2))
                            return "attribute2: string expected";
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        if (!$util.isString(message.attribute3))
                            return "attribute3: string expected";
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        if (!$util.isString(message.notice))
                            return "notice: string expected";
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SampleWorkForm message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.SampleWorkForm} SampleWorkForm
                 */
                SampleWorkForm.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.SampleWorkForm)
                        return object;
                    let message = new $root.mtechnavi.api.form.SampleWorkForm();
                    if (object.sampleWorkFormId != null)
                        message.sampleWorkFormId = String(object.sampleWorkFormId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.baseDisplayName != null)
                        message.baseDisplayName = String(object.baseDisplayName);
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.formType1 != null) {
                        if (typeof object.formType1 !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.formType1: object expected");
                        message.formType1 = $root.sharelib.NameOption.fromObject(object.formType1);
                    }
                    if (object.formType2 != null) {
                        if (typeof object.formType2 !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.formType2: object expected");
                        message.formType2 = $root.sharelib.NameOption.fromObject(object.formType2);
                    }
                    if (object.formType3 != null) {
                        if (typeof object.formType3 !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.formType3: object expected");
                        message.formType3 = $root.sharelib.NameOption.fromObject(object.formType3);
                    }
                    if (object.attribute1 != null)
                        message.attribute1 = String(object.attribute1);
                    if (object.attribute2 != null)
                        message.attribute2 = String(object.attribute2);
                    if (object.attribute3 != null)
                        message.attribute3 = String(object.attribute3);
                    if (object.notice != null)
                        message.notice = String(object.notice);
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.SampleWorkForm.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SampleWorkForm message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @static
                 * @param {mtechnavi.api.form.SampleWorkForm} message SampleWorkForm
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SampleWorkForm.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.sampleWorkFormId = "";
                        object.formId = "";
                        object.baseDisplayName = "";
                        object.displayName = "";
                        object.notice = "";
                        object.required = false;
                        object.status = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.formType1 = null;
                        object.formType2 = null;
                        object.formType3 = null;
                        object.attribute1 = "";
                        object.attribute2 = "";
                        object.attribute3 = "";
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        object.managementOrganization = null;
                    }
                    if (message.sampleWorkFormId != null && message.hasOwnProperty("sampleWorkFormId"))
                        object.sampleWorkFormId = message.sampleWorkFormId;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.baseDisplayName != null && message.hasOwnProperty("baseDisplayName"))
                        object.baseDisplayName = message.baseDisplayName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        object.notice = message.notice;
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.formType1 != null && message.hasOwnProperty("formType1"))
                        object.formType1 = $root.sharelib.NameOption.toObject(message.formType1, options);
                    if (message.formType2 != null && message.hasOwnProperty("formType2"))
                        object.formType2 = $root.sharelib.NameOption.toObject(message.formType2, options);
                    if (message.formType3 != null && message.hasOwnProperty("formType3"))
                        object.formType3 = $root.sharelib.NameOption.toObject(message.formType3, options);
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        object.attribute1 = message.attribute1;
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        object.attribute2 = message.attribute2;
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        object.attribute3 = message.attribute3;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    return object;
                };

                /**
                 * Converts this SampleWorkForm to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.SampleWorkForm
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SampleWorkForm.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SampleWorkForm;
            })();

            form.ItemAutoNameManagement = (function() {

                /**
                 * Properties of an ItemAutoNameManagement.
                 * @memberof mtechnavi.api.form
                 * @interface IItemAutoNameManagement
                 * @property {string|null} [itemAutoNameManagementId] ItemAutoNameManagement itemAutoNameManagementId
                 * @property {string|null} [baseFormId] ItemAutoNameManagement baseFormId
                 * @property {string|null} [itemAutoName] ItemAutoNameManagement itemAutoName
                 * @property {string|null} [inputType] ItemAutoNameManagement inputType
                 * @property {string|null} [itemAutoNameSearchKey] ItemAutoNameManagement itemAutoNameSearchKey
                 * @property {Long|null} [createdAt] ItemAutoNameManagement createdAt
                 * @property {Long|null} [updatedAt] ItemAutoNameManagement updatedAt
                 * @property {Long|null} [deletedAt] ItemAutoNameManagement deletedAt
                 */

                /**
                 * Constructs a new ItemAutoNameManagement.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ItemAutoNameManagement.
                 * @implements IItemAutoNameManagement
                 * @constructor
                 * @param {mtechnavi.api.form.IItemAutoNameManagement=} [properties] Properties to set
                 */
                function ItemAutoNameManagement(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemAutoNameManagement itemAutoNameManagementId.
                 * @member {string} itemAutoNameManagementId
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.itemAutoNameManagementId = "";

                /**
                 * ItemAutoNameManagement baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.baseFormId = "";

                /**
                 * ItemAutoNameManagement itemAutoName.
                 * @member {string} itemAutoName
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.itemAutoName = "";

                /**
                 * ItemAutoNameManagement inputType.
                 * @member {string} inputType
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.inputType = "";

                /**
                 * ItemAutoNameManagement itemAutoNameSearchKey.
                 * @member {string} itemAutoNameSearchKey
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.itemAutoNameSearchKey = "";

                /**
                 * ItemAutoNameManagement createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ItemAutoNameManagement updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ItemAutoNameManagement deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 */
                ItemAutoNameManagement.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an ItemAutoNameManagement message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemAutoNameManagement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.itemAutoNameManagementId != null && message.hasOwnProperty("itemAutoNameManagementId"))
                        if (!$util.isString(message.itemAutoNameManagementId))
                            return "itemAutoNameManagementId: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        if (!$util.isString(message.itemAutoName))
                            return "itemAutoName: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.itemAutoNameSearchKey != null && message.hasOwnProperty("itemAutoNameSearchKey"))
                        if (!$util.isString(message.itemAutoNameSearchKey))
                            return "itemAutoNameSearchKey: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an ItemAutoNameManagement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ItemAutoNameManagement} ItemAutoNameManagement
                 */
                ItemAutoNameManagement.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ItemAutoNameManagement)
                        return object;
                    let message = new $root.mtechnavi.api.form.ItemAutoNameManagement();
                    if (object.itemAutoNameManagementId != null)
                        message.itemAutoNameManagementId = String(object.itemAutoNameManagementId);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.itemAutoName != null)
                        message.itemAutoName = String(object.itemAutoName);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.itemAutoNameSearchKey != null)
                        message.itemAutoNameSearchKey = String(object.itemAutoNameSearchKey);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an ItemAutoNameManagement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @static
                 * @param {mtechnavi.api.form.ItemAutoNameManagement} message ItemAutoNameManagement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemAutoNameManagement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.itemAutoNameManagementId = "";
                        object.baseFormId = "";
                        object.itemAutoName = "";
                        object.inputType = "";
                        object.itemAutoNameSearchKey = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.itemAutoNameManagementId != null && message.hasOwnProperty("itemAutoNameManagementId"))
                        object.itemAutoNameManagementId = message.itemAutoNameManagementId;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        object.itemAutoName = message.itemAutoName;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.itemAutoNameSearchKey != null && message.hasOwnProperty("itemAutoNameSearchKey"))
                        object.itemAutoNameSearchKey = message.itemAutoNameSearchKey;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this ItemAutoNameManagement to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ItemAutoNameManagement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemAutoNameManagement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ItemAutoNameManagement;
            })();

            form.FormProperties = (function() {

                /**
                 * Properties of a FormProperties.
                 * @memberof mtechnavi.api.form
                 * @interface IFormProperties
                 * @property {boolean|null} [systemForm] FormProperties systemForm
                 * @property {Array.<sharelib.INameOption>|null} [purposes] FormProperties purposes
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] FormProperties managementOrganization
                 * @property {string|null} [displayName] FormProperties displayName
                 * @property {sharelib.INameOption|null} [formType1] FormProperties formType1
                 * @property {sharelib.INameOption|null} [formType2] FormProperties formType2
                 * @property {sharelib.INameOption|null} [formType3] FormProperties formType3
                 * @property {string|null} [description] FormProperties description
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] FormProperties createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] FormProperties updatedProperties
                 */

                /**
                 * Constructs a new FormProperties.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormProperties.
                 * @implements IFormProperties
                 * @constructor
                 * @param {mtechnavi.api.form.IFormProperties=} [properties] Properties to set
                 */
                function FormProperties(properties) {
                    this.purposes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormProperties systemForm.
                 * @member {boolean} systemForm
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.systemForm = false;

                /**
                 * FormProperties purposes.
                 * @member {Array.<sharelib.INameOption>} purposes
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.purposes = $util.emptyArray;

                /**
                 * FormProperties managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.managementOrganization = null;

                /**
                 * FormProperties displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.displayName = "";

                /**
                 * FormProperties formType1.
                 * @member {sharelib.INameOption|null|undefined} formType1
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.formType1 = null;

                /**
                 * FormProperties formType2.
                 * @member {sharelib.INameOption|null|undefined} formType2
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.formType2 = null;

                /**
                 * FormProperties formType3.
                 * @member {sharelib.INameOption|null|undefined} formType3
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.formType3 = null;

                /**
                 * FormProperties description.
                 * @member {string} description
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.description = "";

                /**
                 * FormProperties createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.createdProperties = null;

                /**
                 * FormProperties updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 */
                FormProperties.prototype.updatedProperties = null;

                /**
                 * Verifies a FormProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.systemForm != null && message.hasOwnProperty("systemForm"))
                        if (typeof message.systemForm !== "boolean")
                            return "systemForm: boolean expected";
                    if (message.purposes != null && message.hasOwnProperty("purposes")) {
                        if (!Array.isArray(message.purposes))
                            return "purposes: array expected";
                        for (let i = 0; i < message.purposes.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.purposes[i]);
                            if (error)
                                return "purposes." + error;
                        }
                    }
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.formType1 != null && message.hasOwnProperty("formType1")) {
                        let error = $root.sharelib.NameOption.verify(message.formType1);
                        if (error)
                            return "formType1." + error;
                    }
                    if (message.formType2 != null && message.hasOwnProperty("formType2")) {
                        let error = $root.sharelib.NameOption.verify(message.formType2);
                        if (error)
                            return "formType2." + error;
                    }
                    if (message.formType3 != null && message.hasOwnProperty("formType3")) {
                        let error = $root.sharelib.NameOption.verify(message.formType3);
                        if (error)
                            return "formType3." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    return null;
                };

                /**
                 * Creates a FormProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormProperties} FormProperties
                 */
                FormProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormProperties)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormProperties();
                    if (object.systemForm != null)
                        message.systemForm = Boolean(object.systemForm);
                    if (object.purposes) {
                        if (!Array.isArray(object.purposes))
                            throw TypeError(".mtechnavi.api.form.FormProperties.purposes: array expected");
                        message.purposes = [];
                        for (let i = 0; i < object.purposes.length; ++i) {
                            if (typeof object.purposes[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.FormProperties.purposes: object expected");
                            message.purposes[i] = $root.sharelib.NameOption.fromObject(object.purposes[i]);
                        }
                    }
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.formType1 != null) {
                        if (typeof object.formType1 !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.formType1: object expected");
                        message.formType1 = $root.sharelib.NameOption.fromObject(object.formType1);
                    }
                    if (object.formType2 != null) {
                        if (typeof object.formType2 !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.formType2: object expected");
                        message.formType2 = $root.sharelib.NameOption.fromObject(object.formType2);
                    }
                    if (object.formType3 != null) {
                        if (typeof object.formType3 !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.formType3: object expected");
                        message.formType3 = $root.sharelib.NameOption.fromObject(object.formType3);
                    }
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.form.FormProperties.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FormProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormProperties
                 * @static
                 * @param {mtechnavi.api.form.FormProperties} message FormProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.purposes = [];
                    if (options.defaults) {
                        object.displayName = "";
                        object.description = "";
                        object.systemForm = false;
                        object.formType1 = null;
                        object.formType2 = null;
                        object.formType3 = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        object.managementOrganization = null;
                    }
                    if (message.purposes && message.purposes.length) {
                        object.purposes = [];
                        for (let j = 0; j < message.purposes.length; ++j)
                            object.purposes[j] = $root.sharelib.NameOption.toObject(message.purposes[j], options);
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.systemForm != null && message.hasOwnProperty("systemForm"))
                        object.systemForm = message.systemForm;
                    if (message.formType1 != null && message.hasOwnProperty("formType1"))
                        object.formType1 = $root.sharelib.NameOption.toObject(message.formType1, options);
                    if (message.formType2 != null && message.hasOwnProperty("formType2"))
                        object.formType2 = $root.sharelib.NameOption.toObject(message.formType2, options);
                    if (message.formType3 != null && message.hasOwnProperty("formType3"))
                        object.formType3 = $root.sharelib.NameOption.toObject(message.formType3, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    return object;
                };

                /**
                 * Converts this FormProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormProperties;
            })();

            form.FormItem = (function() {

                /**
                 * Properties of a FormItem.
                 * @memberof mtechnavi.api.form
                 * @interface IFormItem
                 * @property {string|null} [itemAutoName] FormItem itemAutoName
                 * @property {Long|null} [itemSize] FormItem itemSize
                 * @property {string|null} [inputType] FormItem inputType
                 * @property {boolean|null} [required] FormItem required
                 * @property {string|null} [displayName] FormItem displayName
                 * @property {string|null} [description] FormItem description
                 * @property {Array.<string>|null} [selectItems] FormItem selectItems
                 * @property {Array.<string>|null} [fileTypes] FormItem fileTypes
                 * @property {string|null} [minNum] FormItem minNum
                 * @property {string|null} [maxNum] FormItem maxNum
                 * @property {string|null} [precisionNum] FormItem precisionNum
                 * @property {Long|null} [sort] FormItem sort
                 */

                /**
                 * Constructs a new FormItem.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormItem.
                 * @implements IFormItem
                 * @constructor
                 * @param {mtechnavi.api.form.IFormItem=} [properties] Properties to set
                 */
                function FormItem(properties) {
                    this.selectItems = [];
                    this.fileTypes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormItem itemAutoName.
                 * @member {string} itemAutoName
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.itemAutoName = "";

                /**
                 * FormItem itemSize.
                 * @member {Long} itemSize
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.itemSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * FormItem inputType.
                 * @member {string} inputType
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.inputType = "";

                /**
                 * FormItem required.
                 * @member {boolean} required
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.required = false;

                /**
                 * FormItem displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.displayName = "";

                /**
                 * FormItem description.
                 * @member {string} description
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.description = "";

                /**
                 * FormItem selectItems.
                 * @member {Array.<string>} selectItems
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.selectItems = $util.emptyArray;

                /**
                 * FormItem fileTypes.
                 * @member {Array.<string>} fileTypes
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.fileTypes = $util.emptyArray;

                /**
                 * FormItem minNum.
                 * @member {string} minNum
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.minNum = "";

                /**
                 * FormItem maxNum.
                 * @member {string} maxNum
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.maxNum = "";

                /**
                 * FormItem precisionNum.
                 * @member {string} precisionNum
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.precisionNum = "";

                /**
                 * FormItem sort.
                 * @member {Long} sort
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 */
                FormItem.prototype.sort = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a FormItem message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        if (!$util.isString(message.itemAutoName))
                            return "itemAutoName: string expected";
                    if (message.itemSize != null && message.hasOwnProperty("itemSize"))
                        if (!$util.isInteger(message.itemSize) && !(message.itemSize && $util.isInteger(message.itemSize.low) && $util.isInteger(message.itemSize.high)))
                            return "itemSize: integer|Long expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.selectItems != null && message.hasOwnProperty("selectItems")) {
                        if (!Array.isArray(message.selectItems))
                            return "selectItems: array expected";
                        for (let i = 0; i < message.selectItems.length; ++i)
                            if (!$util.isString(message.selectItems[i]))
                                return "selectItems: string[] expected";
                    }
                    if (message.fileTypes != null && message.hasOwnProperty("fileTypes")) {
                        if (!Array.isArray(message.fileTypes))
                            return "fileTypes: array expected";
                        for (let i = 0; i < message.fileTypes.length; ++i)
                            if (!$util.isString(message.fileTypes[i]))
                                return "fileTypes: string[] expected";
                    }
                    if (message.minNum != null && message.hasOwnProperty("minNum"))
                        if (!$util.isString(message.minNum))
                            return "minNum: string expected";
                    if (message.maxNum != null && message.hasOwnProperty("maxNum"))
                        if (!$util.isString(message.maxNum))
                            return "maxNum: string expected";
                    if (message.precisionNum != null && message.hasOwnProperty("precisionNum"))
                        if (!$util.isString(message.precisionNum))
                            return "precisionNum: string expected";
                    if (message.sort != null && message.hasOwnProperty("sort"))
                        if (!$util.isInteger(message.sort) && !(message.sort && $util.isInteger(message.sort.low) && $util.isInteger(message.sort.high)))
                            return "sort: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FormItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormItem} FormItem
                 */
                FormItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormItem)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormItem();
                    if (object.itemAutoName != null)
                        message.itemAutoName = String(object.itemAutoName);
                    if (object.itemSize != null)
                        if ($util.Long)
                            (message.itemSize = $util.Long.fromValue(object.itemSize)).unsigned = false;
                        else if (typeof object.itemSize === "string")
                            message.itemSize = parseInt(object.itemSize, 10);
                        else if (typeof object.itemSize === "number")
                            message.itemSize = object.itemSize;
                        else if (typeof object.itemSize === "object")
                            message.itemSize = new $util.LongBits(object.itemSize.low >>> 0, object.itemSize.high >>> 0).toNumber();
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.selectItems) {
                        if (!Array.isArray(object.selectItems))
                            throw TypeError(".mtechnavi.api.form.FormItem.selectItems: array expected");
                        message.selectItems = [];
                        for (let i = 0; i < object.selectItems.length; ++i)
                            message.selectItems[i] = String(object.selectItems[i]);
                    }
                    if (object.fileTypes) {
                        if (!Array.isArray(object.fileTypes))
                            throw TypeError(".mtechnavi.api.form.FormItem.fileTypes: array expected");
                        message.fileTypes = [];
                        for (let i = 0; i < object.fileTypes.length; ++i)
                            message.fileTypes[i] = String(object.fileTypes[i]);
                    }
                    if (object.minNum != null)
                        message.minNum = String(object.minNum);
                    if (object.maxNum != null)
                        message.maxNum = String(object.maxNum);
                    if (object.precisionNum != null)
                        message.precisionNum = String(object.precisionNum);
                    if (object.sort != null)
                        if ($util.Long)
                            (message.sort = $util.Long.fromValue(object.sort)).unsigned = false;
                        else if (typeof object.sort === "string")
                            message.sort = parseInt(object.sort, 10);
                        else if (typeof object.sort === "number")
                            message.sort = object.sort;
                        else if (typeof object.sort === "object")
                            message.sort = new $util.LongBits(object.sort.low >>> 0, object.sort.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a FormItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormItem
                 * @static
                 * @param {mtechnavi.api.form.FormItem} message FormItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.selectItems = [];
                        object.fileTypes = [];
                    }
                    if (options.defaults) {
                        object.itemAutoName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.itemSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.itemSize = options.longs === String ? "0" : 0;
                        object.inputType = "";
                        object.required = false;
                        object.displayName = "";
                        object.description = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sort = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sort = options.longs === String ? "0" : 0;
                        object.minNum = "";
                        object.maxNum = "";
                        object.precisionNum = "";
                    }
                    if (message.itemAutoName != null && message.hasOwnProperty("itemAutoName"))
                        object.itemAutoName = message.itemAutoName;
                    if (message.itemSize != null && message.hasOwnProperty("itemSize"))
                        if (typeof message.itemSize === "number")
                            object.itemSize = options.longs === String ? String(message.itemSize) : message.itemSize;
                        else
                            object.itemSize = options.longs === String ? $util.Long.prototype.toString.call(message.itemSize) : options.longs === Number ? new $util.LongBits(message.itemSize.low >>> 0, message.itemSize.high >>> 0).toNumber() : message.itemSize;
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.selectItems && message.selectItems.length) {
                        object.selectItems = [];
                        for (let j = 0; j < message.selectItems.length; ++j)
                            object.selectItems[j] = message.selectItems[j];
                    }
                    if (message.fileTypes && message.fileTypes.length) {
                        object.fileTypes = [];
                        for (let j = 0; j < message.fileTypes.length; ++j)
                            object.fileTypes[j] = message.fileTypes[j];
                    }
                    if (message.sort != null && message.hasOwnProperty("sort"))
                        if (typeof message.sort === "number")
                            object.sort = options.longs === String ? String(message.sort) : message.sort;
                        else
                            object.sort = options.longs === String ? $util.Long.prototype.toString.call(message.sort) : options.longs === Number ? new $util.LongBits(message.sort.low >>> 0, message.sort.high >>> 0).toNumber() : message.sort;
                    if (message.minNum != null && message.hasOwnProperty("minNum"))
                        object.minNum = message.minNum;
                    if (message.maxNum != null && message.hasOwnProperty("maxNum"))
                        object.maxNum = message.maxNum;
                    if (message.precisionNum != null && message.hasOwnProperty("precisionNum"))
                        object.precisionNum = message.precisionNum;
                    return object;
                };

                /**
                 * Converts this FormItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormItem;
            })();

            form.FormPage = (function() {

                /**
                 * Properties of a FormPage.
                 * @memberof mtechnavi.api.form
                 * @interface IFormPage
                 * @property {string|null} [formPageId] FormPage formPageId
                 * @property {string|null} [displayName] FormPage displayName
                 * @property {Long|null} [sort] FormPage sort
                 */

                /**
                 * Constructs a new FormPage.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormPage.
                 * @implements IFormPage
                 * @constructor
                 * @param {mtechnavi.api.form.IFormPage=} [properties] Properties to set
                 */
                function FormPage(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormPage formPageId.
                 * @member {string} formPageId
                 * @memberof mtechnavi.api.form.FormPage
                 * @instance
                 */
                FormPage.prototype.formPageId = "";

                /**
                 * FormPage displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormPage
                 * @instance
                 */
                FormPage.prototype.displayName = "";

                /**
                 * FormPage sort.
                 * @member {Long} sort
                 * @memberof mtechnavi.api.form.FormPage
                 * @instance
                 */
                FormPage.prototype.sort = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a FormPage message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormPage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormPage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        if (!$util.isString(message.formPageId))
                            return "formPageId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.sort != null && message.hasOwnProperty("sort"))
                        if (!$util.isInteger(message.sort) && !(message.sort && $util.isInteger(message.sort.low) && $util.isInteger(message.sort.high)))
                            return "sort: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a FormPage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormPage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormPage} FormPage
                 */
                FormPage.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormPage)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormPage();
                    if (object.formPageId != null)
                        message.formPageId = String(object.formPageId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.sort != null)
                        if ($util.Long)
                            (message.sort = $util.Long.fromValue(object.sort)).unsigned = false;
                        else if (typeof object.sort === "string")
                            message.sort = parseInt(object.sort, 10);
                        else if (typeof object.sort === "number")
                            message.sort = object.sort;
                        else if (typeof object.sort === "object")
                            message.sort = new $util.LongBits(object.sort.low >>> 0, object.sort.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a FormPage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormPage
                 * @static
                 * @param {mtechnavi.api.form.FormPage} message FormPage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormPage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.formPageId = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sort = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sort = options.longs === String ? "0" : 0;
                    }
                    if (message.formPageId != null && message.hasOwnProperty("formPageId"))
                        object.formPageId = message.formPageId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.sort != null && message.hasOwnProperty("sort"))
                        if (typeof message.sort === "number")
                            object.sort = options.longs === String ? String(message.sort) : message.sort;
                        else
                            object.sort = options.longs === String ? $util.Long.prototype.toString.call(message.sort) : options.longs === Number ? new $util.LongBits(message.sort.low >>> 0, message.sort.high >>> 0).toNumber() : message.sort;
                    return object;
                };

                /**
                 * Converts this FormPage to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormPage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormPage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormPage;
            })();

            form.ItemValue = (function() {

                /**
                 * Properties of an ItemValue.
                 * @memberof mtechnavi.api.form
                 * @interface IItemValue
                 * @property {string|null} [value] ItemValue value
                 * @property {string|null} [inputType] ItemValue inputType
                 * @property {string|null} [controlValue] ItemValue controlValue
                 */

                /**
                 * Constructs a new ItemValue.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ItemValue.
                 * @implements IItemValue
                 * @constructor
                 * @param {mtechnavi.api.form.IItemValue=} [properties] Properties to set
                 */
                function ItemValue(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemValue value.
                 * @member {string} value
                 * @memberof mtechnavi.api.form.ItemValue
                 * @instance
                 */
                ItemValue.prototype.value = "";

                /**
                 * ItemValue inputType.
                 * @member {string} inputType
                 * @memberof mtechnavi.api.form.ItemValue
                 * @instance
                 */
                ItemValue.prototype.inputType = "";

                /**
                 * ItemValue controlValue.
                 * @member {string} controlValue
                 * @memberof mtechnavi.api.form.ItemValue
                 * @instance
                 */
                ItemValue.prototype.controlValue = "";

                /**
                 * Verifies an ItemValue message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ItemValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        if (!$util.isString(message.inputType))
                            return "inputType: string expected";
                    if (message.controlValue != null && message.hasOwnProperty("controlValue"))
                        if (!$util.isString(message.controlValue))
                            return "controlValue: string expected";
                    return null;
                };

                /**
                 * Creates an ItemValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ItemValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ItemValue} ItemValue
                 */
                ItemValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ItemValue)
                        return object;
                    let message = new $root.mtechnavi.api.form.ItemValue();
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.inputType != null)
                        message.inputType = String(object.inputType);
                    if (object.controlValue != null)
                        message.controlValue = String(object.controlValue);
                    return message;
                };

                /**
                 * Creates a plain object from an ItemValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ItemValue
                 * @static
                 * @param {mtechnavi.api.form.ItemValue} message ItemValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.inputType = "";
                        object.controlValue = "";
                        object.value = "";
                    }
                    if (message.inputType != null && message.hasOwnProperty("inputType"))
                        object.inputType = message.inputType;
                    if (message.controlValue != null && message.hasOwnProperty("controlValue"))
                        object.controlValue = message.controlValue;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this ItemValue to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ItemValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ItemValue;
            })();

            form.FormControlText = (function() {

                /**
                 * Properties of a FormControlText.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlText
                 * @property {string|null} [displayName] FormControlText displayName
                 */

                /**
                 * Constructs a new FormControlText.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlText.
                 * @implements IFormControlText
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlText=} [properties] Properties to set
                 */
                function FormControlText(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlText displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormControlText
                 * @instance
                 */
                FormControlText.prototype.displayName = "";

                /**
                 * Verifies a FormControlText message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlText
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlText.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlText message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlText
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlText} FormControlText
                 */
                FormControlText.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlText)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlText();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlText message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlText
                 * @static
                 * @param {mtechnavi.api.form.FormControlText} message FormControlText
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlText.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.displayName = "";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this FormControlText to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlText
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlText.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlText;
            })();

            form.FormControlTextArea = (function() {

                /**
                 * Properties of a FormControlTextArea.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlTextArea
                 * @property {string|null} [displayName] FormControlTextArea displayName
                 */

                /**
                 * Constructs a new FormControlTextArea.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlTextArea.
                 * @implements IFormControlTextArea
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlTextArea=} [properties] Properties to set
                 */
                function FormControlTextArea(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlTextArea displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormControlTextArea
                 * @instance
                 */
                FormControlTextArea.prototype.displayName = "";

                /**
                 * Verifies a FormControlTextArea message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlTextArea
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlTextArea.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlTextArea message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlTextArea
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlTextArea} FormControlTextArea
                 */
                FormControlTextArea.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlTextArea)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlTextArea();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlTextArea message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlTextArea
                 * @static
                 * @param {mtechnavi.api.form.FormControlTextArea} message FormControlTextArea
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlTextArea.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.displayName = "";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this FormControlTextArea to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlTextArea
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlTextArea.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlTextArea;
            })();

            form.FormControlNumber = (function() {

                /**
                 * Properties of a FormControlNumber.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlNumber
                 * @property {string|null} [integralAmount] FormControlNumber integralAmount
                 * @property {string|null} [fractionalAmount] FormControlNumber fractionalAmount
                 */

                /**
                 * Constructs a new FormControlNumber.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlNumber.
                 * @implements IFormControlNumber
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlNumber=} [properties] Properties to set
                 */
                function FormControlNumber(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlNumber integralAmount.
                 * @member {string} integralAmount
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @instance
                 */
                FormControlNumber.prototype.integralAmount = "";

                /**
                 * FormControlNumber fractionalAmount.
                 * @member {string} fractionalAmount
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @instance
                 */
                FormControlNumber.prototype.fractionalAmount = "";

                /**
                 * Verifies a FormControlNumber message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlNumber.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                        if (!$util.isString(message.integralAmount))
                            return "integralAmount: string expected";
                    if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                        if (!$util.isString(message.fractionalAmount))
                            return "fractionalAmount: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlNumber message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlNumber} FormControlNumber
                 */
                FormControlNumber.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlNumber)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlNumber();
                    if (object.integralAmount != null)
                        message.integralAmount = String(object.integralAmount);
                    if (object.fractionalAmount != null)
                        message.fractionalAmount = String(object.fractionalAmount);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlNumber message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @static
                 * @param {mtechnavi.api.form.FormControlNumber} message FormControlNumber
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlNumber.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.integralAmount = "";
                        object.fractionalAmount = "";
                    }
                    if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                        object.integralAmount = message.integralAmount;
                    if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                        object.fractionalAmount = message.fractionalAmount;
                    return object;
                };

                /**
                 * Converts this FormControlNumber to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlNumber
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlNumber.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlNumber;
            })();

            form.FormControlDate = (function() {

                /**
                 * Properties of a FormControlDate.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlDate
                 * @property {string|null} [date] FormControlDate date
                 */

                /**
                 * Constructs a new FormControlDate.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlDate.
                 * @implements IFormControlDate
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlDate=} [properties] Properties to set
                 */
                function FormControlDate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlDate date.
                 * @member {string} date
                 * @memberof mtechnavi.api.form.FormControlDate
                 * @instance
                 */
                FormControlDate.prototype.date = "";

                /**
                 * Verifies a FormControlDate message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlDate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlDate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isString(message.date))
                            return "date: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlDate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlDate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlDate} FormControlDate
                 */
                FormControlDate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlDate)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlDate();
                    if (object.date != null)
                        message.date = String(object.date);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlDate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlDate
                 * @static
                 * @param {mtechnavi.api.form.FormControlDate} message FormControlDate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlDate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.date = "";
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this FormControlDate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlDate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlDate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlDate;
            })();

            form.FormControlToggle = (function() {

                /**
                 * Properties of a FormControlToggle.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlToggle
                 * @property {boolean|null} [on] FormControlToggle on
                 */

                /**
                 * Constructs a new FormControlToggle.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlToggle.
                 * @implements IFormControlToggle
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlToggle=} [properties] Properties to set
                 */
                function FormControlToggle(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlToggle on.
                 * @member {boolean} on
                 * @memberof mtechnavi.api.form.FormControlToggle
                 * @instance
                 */
                FormControlToggle.prototype.on = false;

                /**
                 * Verifies a FormControlToggle message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlToggle
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlToggle.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.on != null && message.hasOwnProperty("on"))
                        if (typeof message.on !== "boolean")
                            return "on: boolean expected";
                    return null;
                };

                /**
                 * Creates a FormControlToggle message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlToggle
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlToggle} FormControlToggle
                 */
                FormControlToggle.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlToggle)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlToggle();
                    if (object.on != null)
                        message.on = Boolean(object.on);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlToggle message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlToggle
                 * @static
                 * @param {mtechnavi.api.form.FormControlToggle} message FormControlToggle
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlToggle.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.on = false;
                    if (message.on != null && message.hasOwnProperty("on"))
                        object.on = message.on;
                    return object;
                };

                /**
                 * Converts this FormControlToggle to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlToggle
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlToggle.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlToggle;
            })();

            form.FormControlSelectItem = (function() {

                /**
                 * Properties of a FormControlSelectItem.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlSelectItem
                 * @property {string|null} [value] FormControlSelectItem value
                 * @property {string|null} [displayName] FormControlSelectItem displayName
                 */

                /**
                 * Constructs a new FormControlSelectItem.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlSelectItem.
                 * @implements IFormControlSelectItem
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlSelectItem=} [properties] Properties to set
                 */
                function FormControlSelectItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlSelectItem value.
                 * @member {string} value
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @instance
                 */
                FormControlSelectItem.prototype.value = "";

                /**
                 * FormControlSelectItem displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @instance
                 */
                FormControlSelectItem.prototype.displayName = "";

                /**
                 * Verifies a FormControlSelectItem message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlSelectItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlSelectItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlSelectItem} FormControlSelectItem
                 */
                FormControlSelectItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlSelectItem)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlSelectItem();
                    if (object.value != null)
                        message.value = String(object.value);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlSelectItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @static
                 * @param {mtechnavi.api.form.FormControlSelectItem} message FormControlSelectItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlSelectItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.value = "";
                        object.displayName = "";
                    }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this FormControlSelectItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlSelectItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlSelectItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlSelectItem;
            })();

            form.FormControlSelect = (function() {

                /**
                 * Properties of a FormControlSelect.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlSelect
                 * @property {mtechnavi.api.form.IFormControlSelectItem|null} [item] FormControlSelect item
                 */

                /**
                 * Constructs a new FormControlSelect.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlSelect.
                 * @implements IFormControlSelect
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlSelect=} [properties] Properties to set
                 */
                function FormControlSelect(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlSelect item.
                 * @member {mtechnavi.api.form.IFormControlSelectItem|null|undefined} item
                 * @memberof mtechnavi.api.form.FormControlSelect
                 * @instance
                 */
                FormControlSelect.prototype.item = null;

                /**
                 * Verifies a FormControlSelect message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlSelect
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlSelect.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.item != null && message.hasOwnProperty("item")) {
                        let error = $root.mtechnavi.api.form.FormControlSelectItem.verify(message.item);
                        if (error)
                            return "item." + error;
                    }
                    return null;
                };

                /**
                 * Creates a FormControlSelect message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlSelect
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlSelect} FormControlSelect
                 */
                FormControlSelect.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlSelect)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlSelect();
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.form.FormControlSelect.item: object expected");
                        message.item = $root.mtechnavi.api.form.FormControlSelectItem.fromObject(object.item);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlSelect message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlSelect
                 * @static
                 * @param {mtechnavi.api.form.FormControlSelect} message FormControlSelect
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlSelect.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.item = null;
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = $root.mtechnavi.api.form.FormControlSelectItem.toObject(message.item, options);
                    return object;
                };

                /**
                 * Converts this FormControlSelect to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlSelect
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlSelect.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlSelect;
            })();

            form.FormControlSelectMultiple = (function() {

                /**
                 * Properties of a FormControlSelectMultiple.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlSelectMultiple
                 * @property {Array.<mtechnavi.api.form.IFormControlSelectItem>|null} [items] FormControlSelectMultiple items
                 */

                /**
                 * Constructs a new FormControlSelectMultiple.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlSelectMultiple.
                 * @implements IFormControlSelectMultiple
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlSelectMultiple=} [properties] Properties to set
                 */
                function FormControlSelectMultiple(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlSelectMultiple items.
                 * @member {Array.<mtechnavi.api.form.IFormControlSelectItem>} items
                 * @memberof mtechnavi.api.form.FormControlSelectMultiple
                 * @instance
                 */
                FormControlSelectMultiple.prototype.items = $util.emptyArray;

                /**
                 * Verifies a FormControlSelectMultiple message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlSelectMultiple
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlSelectMultiple.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormControlSelectItem.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a FormControlSelectMultiple message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlSelectMultiple
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlSelectMultiple} FormControlSelectMultiple
                 */
                FormControlSelectMultiple.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlSelectMultiple)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlSelectMultiple();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.form.FormControlSelectMultiple.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.FormControlSelectMultiple.items: object expected");
                            message.items[i] = $root.mtechnavi.api.form.FormControlSelectItem.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlSelectMultiple message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlSelectMultiple
                 * @static
                 * @param {mtechnavi.api.form.FormControlSelectMultiple} message FormControlSelectMultiple
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlSelectMultiple.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.form.FormControlSelectItem.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this FormControlSelectMultiple to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlSelectMultiple
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlSelectMultiple.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlSelectMultiple;
            })();

            form.FormControlFile = (function() {

                /**
                 * Properties of a FormControlFile.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlFile
                 * @property {string|null} [assetId] FormControlFile assetId
                 * @property {string|null} [mimeType] FormControlFile mimeType
                 * @property {string|null} [fileName] FormControlFile fileName
                 */

                /**
                 * Constructs a new FormControlFile.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlFile.
                 * @implements IFormControlFile
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlFile=} [properties] Properties to set
                 */
                function FormControlFile(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlFile assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @instance
                 */
                FormControlFile.prototype.assetId = "";

                /**
                 * FormControlFile mimeType.
                 * @member {string} mimeType
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @instance
                 */
                FormControlFile.prototype.mimeType = "";

                /**
                 * FormControlFile fileName.
                 * @member {string} fileName
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @instance
                 */
                FormControlFile.prototype.fileName = "";

                /**
                 * Verifies a FormControlFile message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlFile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        if (!$util.isString(message.mimeType))
                            return "mimeType: string expected";
                    if (message.fileName != null && message.hasOwnProperty("fileName"))
                        if (!$util.isString(message.fileName))
                            return "fileName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlFile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlFile} FormControlFile
                 */
                FormControlFile.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlFile)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlFile();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    if (object.mimeType != null)
                        message.mimeType = String(object.mimeType);
                    if (object.fileName != null)
                        message.fileName = String(object.fileName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlFile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @static
                 * @param {mtechnavi.api.form.FormControlFile} message FormControlFile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlFile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.assetId = "";
                        object.mimeType = "";
                        object.fileName = "";
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        object.mimeType = message.mimeType;
                    if (message.fileName != null && message.hasOwnProperty("fileName"))
                        object.fileName = message.fileName;
                    return object;
                };

                /**
                 * Converts this FormControlFile to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlFile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlFile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlFile;
            })();

            form.FormControlLabel = (function() {

                /**
                 * Properties of a FormControlLabel.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlLabel
                 * @property {string|null} [displayName] FormControlLabel displayName
                 */

                /**
                 * Constructs a new FormControlLabel.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlLabel.
                 * @implements IFormControlLabel
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlLabel=} [properties] Properties to set
                 */
                function FormControlLabel(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlLabel displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormControlLabel
                 * @instance
                 */
                FormControlLabel.prototype.displayName = "";

                /**
                 * Verifies a FormControlLabel message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlLabel
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlLabel.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlLabel message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlLabel
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlLabel} FormControlLabel
                 */
                FormControlLabel.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlLabel)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlLabel();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlLabel message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlLabel
                 * @static
                 * @param {mtechnavi.api.form.FormControlLabel} message FormControlLabel
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlLabel.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.displayName = "";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this FormControlLabel to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlLabel
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlLabel.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlLabel;
            })();

            form.FormControlLabelMultiLine = (function() {

                /**
                 * Properties of a FormControlLabelMultiLine.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlLabelMultiLine
                 * @property {string|null} [displayName] FormControlLabelMultiLine displayName
                 */

                /**
                 * Constructs a new FormControlLabelMultiLine.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlLabelMultiLine.
                 * @implements IFormControlLabelMultiLine
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlLabelMultiLine=} [properties] Properties to set
                 */
                function FormControlLabelMultiLine(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormControlLabelMultiLine displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.form.FormControlLabelMultiLine
                 * @instance
                 */
                FormControlLabelMultiLine.prototype.displayName = "";

                /**
                 * Verifies a FormControlLabelMultiLine message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlLabelMultiLine
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlLabelMultiLine.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates a FormControlLabelMultiLine message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlLabelMultiLine
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlLabelMultiLine} FormControlLabelMultiLine
                 */
                FormControlLabelMultiLine.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlLabelMultiLine)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormControlLabelMultiLine();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from a FormControlLabelMultiLine message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlLabelMultiLine
                 * @static
                 * @param {mtechnavi.api.form.FormControlLabelMultiLine} message FormControlLabelMultiLine
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlLabelMultiLine.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.displayName = "";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this FormControlLabelMultiLine to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlLabelMultiLine
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlLabelMultiLine.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlLabelMultiLine;
            })();

            form.FormControlSpacer = (function() {

                /**
                 * Properties of a FormControlSpacer.
                 * @memberof mtechnavi.api.form
                 * @interface IFormControlSpacer
                 */

                /**
                 * Constructs a new FormControlSpacer.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormControlSpacer.
                 * @implements IFormControlSpacer
                 * @constructor
                 * @param {mtechnavi.api.form.IFormControlSpacer=} [properties] Properties to set
                 */
                function FormControlSpacer(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a FormControlSpacer message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormControlSpacer
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormControlSpacer.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FormControlSpacer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormControlSpacer
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormControlSpacer} FormControlSpacer
                 */
                FormControlSpacer.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormControlSpacer)
                        return object;
                    return new $root.mtechnavi.api.form.FormControlSpacer();
                };

                /**
                 * Creates a plain object from a FormControlSpacer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormControlSpacer
                 * @static
                 * @param {mtechnavi.api.form.FormControlSpacer} message FormControlSpacer
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormControlSpacer.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FormControlSpacer to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormControlSpacer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormControlSpacer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormControlSpacer;
            })();

            form.ImportFormValueValidationError = (function() {

                /**
                 * Properties of an ImportFormValueValidationError.
                 * @memberof mtechnavi.api.form
                 * @interface IImportFormValueValidationError
                 * @property {string|null} [formSettingItemId] ImportFormValueValidationError formSettingItemId
                 * @property {string|null} [value] ImportFormValueValidationError value
                 * @property {sharelib.ErrorLevel|null} [errorLevel] ImportFormValueValidationError errorLevel
                 * @property {string|null} [messageName] ImportFormValueValidationError messageName
                 * @property {Array.<string>|null} [messageArgs] ImportFormValueValidationError messageArgs
                 */

                /**
                 * Constructs a new ImportFormValueValidationError.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents an ImportFormValueValidationError.
                 * @implements IImportFormValueValidationError
                 * @constructor
                 * @param {mtechnavi.api.form.IImportFormValueValidationError=} [properties] Properties to set
                 */
                function ImportFormValueValidationError(properties) {
                    this.messageArgs = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportFormValueValidationError formSettingItemId.
                 * @member {string} formSettingItemId
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 */
                ImportFormValueValidationError.prototype.formSettingItemId = "";

                /**
                 * ImportFormValueValidationError value.
                 * @member {string} value
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 */
                ImportFormValueValidationError.prototype.value = "";

                /**
                 * ImportFormValueValidationError errorLevel.
                 * @member {sharelib.ErrorLevel} errorLevel
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 */
                ImportFormValueValidationError.prototype.errorLevel = 0;

                /**
                 * ImportFormValueValidationError messageName.
                 * @member {string} messageName
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 */
                ImportFormValueValidationError.prototype.messageName = "";

                /**
                 * ImportFormValueValidationError messageArgs.
                 * @member {Array.<string>} messageArgs
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 */
                ImportFormValueValidationError.prototype.messageArgs = $util.emptyArray;

                /**
                 * Verifies an ImportFormValueValidationError message.
                 * @function verify
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportFormValueValidationError.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        if (!$util.isString(message.formSettingItemId))
                            return "formSettingItemId: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                        switch (message.errorLevel) {
                        default:
                            return "errorLevel: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.messageName != null && message.hasOwnProperty("messageName"))
                        if (!$util.isString(message.messageName))
                            return "messageName: string expected";
                    if (message.messageArgs != null && message.hasOwnProperty("messageArgs")) {
                        if (!Array.isArray(message.messageArgs))
                            return "messageArgs: array expected";
                        for (let i = 0; i < message.messageArgs.length; ++i)
                            if (!$util.isString(message.messageArgs[i]))
                                return "messageArgs: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates an ImportFormValueValidationError message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.ImportFormValueValidationError} ImportFormValueValidationError
                 */
                ImportFormValueValidationError.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.ImportFormValueValidationError)
                        return object;
                    let message = new $root.mtechnavi.api.form.ImportFormValueValidationError();
                    if (object.formSettingItemId != null)
                        message.formSettingItemId = String(object.formSettingItemId);
                    if (object.value != null)
                        message.value = String(object.value);
                    switch (object.errorLevel) {
                    case "NONE":
                    case 0:
                        message.errorLevel = 0;
                        break;
                    case "ERROR":
                    case 1:
                        message.errorLevel = 1;
                        break;
                    case "WARNING":
                    case 2:
                        message.errorLevel = 2;
                        break;
                    }
                    if (object.messageName != null)
                        message.messageName = String(object.messageName);
                    if (object.messageArgs) {
                        if (!Array.isArray(object.messageArgs))
                            throw TypeError(".mtechnavi.api.form.ImportFormValueValidationError.messageArgs: array expected");
                        message.messageArgs = [];
                        for (let i = 0; i < object.messageArgs.length; ++i)
                            message.messageArgs[i] = String(object.messageArgs[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ImportFormValueValidationError message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @static
                 * @param {mtechnavi.api.form.ImportFormValueValidationError} message ImportFormValueValidationError
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportFormValueValidationError.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.messageArgs = [];
                    if (options.defaults) {
                        object.formSettingItemId = "";
                        object.value = "";
                        object.errorLevel = options.enums === String ? "NONE" : 0;
                        object.messageName = "";
                    }
                    if (message.formSettingItemId != null && message.hasOwnProperty("formSettingItemId"))
                        object.formSettingItemId = message.formSettingItemId;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                        object.errorLevel = options.enums === String ? $root.sharelib.ErrorLevel[message.errorLevel] : message.errorLevel;
                    if (message.messageName != null && message.hasOwnProperty("messageName"))
                        object.messageName = message.messageName;
                    if (message.messageArgs && message.messageArgs.length) {
                        object.messageArgs = [];
                        for (let j = 0; j < message.messageArgs.length; ++j)
                            object.messageArgs[j] = message.messageArgs[j];
                    }
                    return object;
                };

                /**
                 * Converts this ImportFormValueValidationError to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.ImportFormValueValidationError
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportFormValueValidationError.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportFormValueValidationError;
            })();

            form.FormContent = (function() {

                /**
                 * Properties of a FormContent.
                 * @memberof mtechnavi.api.form
                 * @interface IFormContent
                 * @property {mtechnavi.api.form.IForm|null} [form] FormContent form
                 * @property {mtechnavi.api.form.IFormSetting|null} [formSetting] FormContent formSetting
                 * @property {Array.<mtechnavi.api.form.IFormSettingItem>|null} [formSettingItems] FormContent formSettingItems
                 */

                /**
                 * Constructs a new FormContent.
                 * @memberof mtechnavi.api.form
                 * @classdesc Represents a FormContent.
                 * @implements IFormContent
                 * @constructor
                 * @param {mtechnavi.api.form.IFormContent=} [properties] Properties to set
                 */
                function FormContent(properties) {
                    this.formSettingItems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FormContent form.
                 * @member {mtechnavi.api.form.IForm|null|undefined} form
                 * @memberof mtechnavi.api.form.FormContent
                 * @instance
                 */
                FormContent.prototype.form = null;

                /**
                 * FormContent formSetting.
                 * @member {mtechnavi.api.form.IFormSetting|null|undefined} formSetting
                 * @memberof mtechnavi.api.form.FormContent
                 * @instance
                 */
                FormContent.prototype.formSetting = null;

                /**
                 * FormContent formSettingItems.
                 * @member {Array.<mtechnavi.api.form.IFormSettingItem>} formSettingItems
                 * @memberof mtechnavi.api.form.FormContent
                 * @instance
                 */
                FormContent.prototype.formSettingItems = $util.emptyArray;

                /**
                 * Verifies a FormContent message.
                 * @function verify
                 * @memberof mtechnavi.api.form.FormContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FormContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.form != null && message.hasOwnProperty("form")) {
                        let error = $root.mtechnavi.api.form.Form.verify(message.form);
                        if (error)
                            return "form." + error;
                    }
                    if (message.formSetting != null && message.hasOwnProperty("formSetting")) {
                        let error = $root.mtechnavi.api.form.FormSetting.verify(message.formSetting);
                        if (error)
                            return "formSetting." + error;
                    }
                    if (message.formSettingItems != null && message.hasOwnProperty("formSettingItems")) {
                        if (!Array.isArray(message.formSettingItems))
                            return "formSettingItems: array expected";
                        for (let i = 0; i < message.formSettingItems.length; ++i) {
                            let error = $root.mtechnavi.api.form.FormSettingItem.verify(message.formSettingItems[i]);
                            if (error)
                                return "formSettingItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a FormContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.form.FormContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.form.FormContent} FormContent
                 */
                FormContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.form.FormContent)
                        return object;
                    let message = new $root.mtechnavi.api.form.FormContent();
                    if (object.form != null) {
                        if (typeof object.form !== "object")
                            throw TypeError(".mtechnavi.api.form.FormContent.form: object expected");
                        message.form = $root.mtechnavi.api.form.Form.fromObject(object.form);
                    }
                    if (object.formSetting != null) {
                        if (typeof object.formSetting !== "object")
                            throw TypeError(".mtechnavi.api.form.FormContent.formSetting: object expected");
                        message.formSetting = $root.mtechnavi.api.form.FormSetting.fromObject(object.formSetting);
                    }
                    if (object.formSettingItems) {
                        if (!Array.isArray(object.formSettingItems))
                            throw TypeError(".mtechnavi.api.form.FormContent.formSettingItems: array expected");
                        message.formSettingItems = [];
                        for (let i = 0; i < object.formSettingItems.length; ++i) {
                            if (typeof object.formSettingItems[i] !== "object")
                                throw TypeError(".mtechnavi.api.form.FormContent.formSettingItems: object expected");
                            message.formSettingItems[i] = $root.mtechnavi.api.form.FormSettingItem.fromObject(object.formSettingItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FormContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.form.FormContent
                 * @static
                 * @param {mtechnavi.api.form.FormContent} message FormContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FormContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formSettingItems = [];
                    if (options.defaults) {
                        object.form = null;
                        object.formSetting = null;
                    }
                    if (message.form != null && message.hasOwnProperty("form"))
                        object.form = $root.mtechnavi.api.form.Form.toObject(message.form, options);
                    if (message.formSetting != null && message.hasOwnProperty("formSetting"))
                        object.formSetting = $root.mtechnavi.api.form.FormSetting.toObject(message.formSetting, options);
                    if (message.formSettingItems && message.formSettingItems.length) {
                        object.formSettingItems = [];
                        for (let j = 0; j < message.formSettingItems.length; ++j)
                            object.formSettingItems[j] = $root.mtechnavi.api.form.FormSettingItem.toObject(message.formSettingItems[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this FormContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.form.FormContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FormContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FormContent;
            })();

            return form;
        })();

        api.forum = (function() {

            /**
             * Namespace forum.
             * @memberof mtechnavi.api
             * @namespace
             */
            const forum = {};

            forum.ForumService = (function() {

                /**
                 * Constructs a new ForumService service.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ForumService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ForumService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ForumService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ForumService;

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#createBaseThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef CreateBaseThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.BaseThread} [response] BaseThread
                 */

                /**
                 * Calls CreateBaseThread.
                 * @function createBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateBaseThreadRequest} request CreateBaseThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.CreateBaseThreadCallback} callback Node-style callback called with the error, if any, and BaseThread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.createBaseThread = function createBaseThread(request, callback) {
                    return this.rpcCall(createBaseThread, $root.mtechnavi.api.forum.CreateBaseThreadRequest, $root.mtechnavi.api.forum.BaseThread, request, callback);
                }, "name", { value: "CreateBaseThread" });

                /**
                 * Calls CreateBaseThread.
                 * @function createBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateBaseThreadRequest} request CreateBaseThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.BaseThread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#listBaseThreads}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef ListBaseThreadsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.ListBaseThreadsResponse} [response] ListBaseThreadsResponse
                 */

                /**
                 * Calls ListBaseThreads.
                 * @function listBaseThreads
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListBaseThreadsRequest} request ListBaseThreadsRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.ListBaseThreadsCallback} callback Node-style callback called with the error, if any, and ListBaseThreadsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.listBaseThreads = function listBaseThreads(request, callback) {
                    return this.rpcCall(listBaseThreads, $root.mtechnavi.api.forum.ListBaseThreadsRequest, $root.mtechnavi.api.forum.ListBaseThreadsResponse, request, callback);
                }, "name", { value: "ListBaseThreads" });

                /**
                 * Calls ListBaseThreads.
                 * @function listBaseThreads
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListBaseThreadsRequest} request ListBaseThreadsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.ListBaseThreadsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#getBaseThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef GetBaseThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.BaseThread} [response] BaseThread
                 */

                /**
                 * Calls GetBaseThread.
                 * @function getBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetBaseThreadRequest} request GetBaseThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.GetBaseThreadCallback} callback Node-style callback called with the error, if any, and BaseThread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.getBaseThread = function getBaseThread(request, callback) {
                    return this.rpcCall(getBaseThread, $root.mtechnavi.api.forum.GetBaseThreadRequest, $root.mtechnavi.api.forum.BaseThread, request, callback);
                }, "name", { value: "GetBaseThread" });

                /**
                 * Calls GetBaseThread.
                 * @function getBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetBaseThreadRequest} request GetBaseThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.BaseThread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#updateBaseThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef UpdateBaseThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.BaseThread} [response] BaseThread
                 */

                /**
                 * Calls UpdateBaseThread.
                 * @function updateBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateBaseThreadRequest} request UpdateBaseThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.UpdateBaseThreadCallback} callback Node-style callback called with the error, if any, and BaseThread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.updateBaseThread = function updateBaseThread(request, callback) {
                    return this.rpcCall(updateBaseThread, $root.mtechnavi.api.forum.UpdateBaseThreadRequest, $root.mtechnavi.api.forum.BaseThread, request, callback);
                }, "name", { value: "UpdateBaseThread" });

                /**
                 * Calls UpdateBaseThread.
                 * @function updateBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateBaseThreadRequest} request UpdateBaseThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.BaseThread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#deleteBaseThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef DeleteBaseThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBaseThread.
                 * @function deleteBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteBaseThreadRequest} request DeleteBaseThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.DeleteBaseThreadCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.deleteBaseThread = function deleteBaseThread(request, callback) {
                    return this.rpcCall(deleteBaseThread, $root.mtechnavi.api.forum.DeleteBaseThreadRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBaseThread" });

                /**
                 * Calls DeleteBaseThread.
                 * @function deleteBaseThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteBaseThreadRequest} request DeleteBaseThreadRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#checkUsableForum}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef CheckUsableForumCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.CheckUsableForumResponse} [response] CheckUsableForumResponse
                 */

                /**
                 * Calls CheckUsableForum.
                 * @function checkUsableForum
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICheckUsableForumRequest} request CheckUsableForumRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.CheckUsableForumCallback} callback Node-style callback called with the error, if any, and CheckUsableForumResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.checkUsableForum = function checkUsableForum(request, callback) {
                    return this.rpcCall(checkUsableForum, $root.mtechnavi.api.forum.CheckUsableForumRequest, $root.mtechnavi.api.forum.CheckUsableForumResponse, request, callback);
                }, "name", { value: "CheckUsableForum" });

                /**
                 * Calls CheckUsableForum.
                 * @function checkUsableForum
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICheckUsableForumRequest} request CheckUsableForumRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.CheckUsableForumResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#createThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef CreateThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Thread} [response] Thread
                 */

                /**
                 * Calls CreateThread.
                 * @function createThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateThreadRequest} request CreateThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.CreateThreadCallback} callback Node-style callback called with the error, if any, and Thread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.createThread = function createThread(request, callback) {
                    return this.rpcCall(createThread, $root.mtechnavi.api.forum.CreateThreadRequest, $root.mtechnavi.api.forum.Thread, request, callback);
                }, "name", { value: "CreateThread" });

                /**
                 * Calls CreateThread.
                 * @function createThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateThreadRequest} request CreateThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Thread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#listThreads}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef ListThreadsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.ListThreadsResponse} [response] ListThreadsResponse
                 */

                /**
                 * Calls ListThreads.
                 * @function listThreads
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListThreadsRequest} request ListThreadsRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.ListThreadsCallback} callback Node-style callback called with the error, if any, and ListThreadsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.listThreads = function listThreads(request, callback) {
                    return this.rpcCall(listThreads, $root.mtechnavi.api.forum.ListThreadsRequest, $root.mtechnavi.api.forum.ListThreadsResponse, request, callback);
                }, "name", { value: "ListThreads" });

                /**
                 * Calls ListThreads.
                 * @function listThreads
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListThreadsRequest} request ListThreadsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.ListThreadsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#getThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef GetThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Thread} [response] Thread
                 */

                /**
                 * Calls GetThread.
                 * @function getThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetThreadRequest} request GetThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.GetThreadCallback} callback Node-style callback called with the error, if any, and Thread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.getThread = function getThread(request, callback) {
                    return this.rpcCall(getThread, $root.mtechnavi.api.forum.GetThreadRequest, $root.mtechnavi.api.forum.Thread, request, callback);
                }, "name", { value: "GetThread" });

                /**
                 * Calls GetThread.
                 * @function getThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetThreadRequest} request GetThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Thread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#updateThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef UpdateThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Thread} [response] Thread
                 */

                /**
                 * Calls UpdateThread.
                 * @function updateThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateThreadRequest} request UpdateThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.UpdateThreadCallback} callback Node-style callback called with the error, if any, and Thread
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.updateThread = function updateThread(request, callback) {
                    return this.rpcCall(updateThread, $root.mtechnavi.api.forum.UpdateThreadRequest, $root.mtechnavi.api.forum.Thread, request, callback);
                }, "name", { value: "UpdateThread" });

                /**
                 * Calls UpdateThread.
                 * @function updateThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateThreadRequest} request UpdateThreadRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Thread>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#deleteThread}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef DeleteThreadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteThread.
                 * @function deleteThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteThreadRequest} request DeleteThreadRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.DeleteThreadCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.deleteThread = function deleteThread(request, callback) {
                    return this.rpcCall(deleteThread, $root.mtechnavi.api.forum.DeleteThreadRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteThread" });

                /**
                 * Calls DeleteThread.
                 * @function deleteThread
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteThreadRequest} request DeleteThreadRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#createComment}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef CreateCommentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Comment} [response] Comment
                 */

                /**
                 * Calls CreateComment.
                 * @function createComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateCommentRequest} request CreateCommentRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.CreateCommentCallback} callback Node-style callback called with the error, if any, and Comment
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.createComment = function createComment(request, callback) {
                    return this.rpcCall(createComment, $root.mtechnavi.api.forum.CreateCommentRequest, $root.mtechnavi.api.forum.Comment, request, callback);
                }, "name", { value: "CreateComment" });

                /**
                 * Calls CreateComment.
                 * @function createComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ICreateCommentRequest} request CreateCommentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Comment>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#listComments}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef ListCommentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.ListCommentsResponse} [response] ListCommentsResponse
                 */

                /**
                 * Calls ListComments.
                 * @function listComments
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListCommentsRequest} request ListCommentsRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.ListCommentsCallback} callback Node-style callback called with the error, if any, and ListCommentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.listComments = function listComments(request, callback) {
                    return this.rpcCall(listComments, $root.mtechnavi.api.forum.ListCommentsRequest, $root.mtechnavi.api.forum.ListCommentsResponse, request, callback);
                }, "name", { value: "ListComments" });

                /**
                 * Calls ListComments.
                 * @function listComments
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListCommentsRequest} request ListCommentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.ListCommentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#getComment}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef GetCommentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Comment} [response] Comment
                 */

                /**
                 * Calls GetComment.
                 * @function getComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetCommentRequest} request GetCommentRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.GetCommentCallback} callback Node-style callback called with the error, if any, and Comment
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.getComment = function getComment(request, callback) {
                    return this.rpcCall(getComment, $root.mtechnavi.api.forum.GetCommentRequest, $root.mtechnavi.api.forum.Comment, request, callback);
                }, "name", { value: "GetComment" });

                /**
                 * Calls GetComment.
                 * @function getComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IGetCommentRequest} request GetCommentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Comment>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#updateComment}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef UpdateCommentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.Comment} [response] Comment
                 */

                /**
                 * Calls UpdateComment.
                 * @function updateComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateCommentRequest} request UpdateCommentRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.UpdateCommentCallback} callback Node-style callback called with the error, if any, and Comment
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.updateComment = function updateComment(request, callback) {
                    return this.rpcCall(updateComment, $root.mtechnavi.api.forum.UpdateCommentRequest, $root.mtechnavi.api.forum.Comment, request, callback);
                }, "name", { value: "UpdateComment" });

                /**
                 * Calls UpdateComment.
                 * @function updateComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUpdateCommentRequest} request UpdateCommentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.Comment>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#deleteComment}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef DeleteCommentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteComment.
                 * @function deleteComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteCommentRequest} request DeleteCommentRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.DeleteCommentCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.deleteComment = function deleteComment(request, callback) {
                    return this.rpcCall(deleteComment, $root.mtechnavi.api.forum.DeleteCommentRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteComment" });

                /**
                 * Calls DeleteComment.
                 * @function deleteComment
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IDeleteCommentRequest} request DeleteCommentRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#setMarker}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef SetMarkerCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.SetMarkerResponse} [response] SetMarkerResponse
                 */

                /**
                 * Calls SetMarker.
                 * @function setMarker
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ISetMarkerRequest} request SetMarkerRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.SetMarkerCallback} callback Node-style callback called with the error, if any, and SetMarkerResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.setMarker = function setMarker(request, callback) {
                    return this.rpcCall(setMarker, $root.mtechnavi.api.forum.SetMarkerRequest, $root.mtechnavi.api.forum.SetMarkerResponse, request, callback);
                }, "name", { value: "SetMarker" });

                /**
                 * Calls SetMarker.
                 * @function setMarker
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.ISetMarkerRequest} request SetMarkerRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.SetMarkerResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#unsetMarker}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef UnsetMarkerCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.UnsetMarkerResponse} [response] UnsetMarkerResponse
                 */

                /**
                 * Calls UnsetMarker.
                 * @function unsetMarker
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUnsetMarkerRequest} request UnsetMarkerRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.UnsetMarkerCallback} callback Node-style callback called with the error, if any, and UnsetMarkerResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.unsetMarker = function unsetMarker(request, callback) {
                    return this.rpcCall(unsetMarker, $root.mtechnavi.api.forum.UnsetMarkerRequest, $root.mtechnavi.api.forum.UnsetMarkerResponse, request, callback);
                }, "name", { value: "UnsetMarker" });

                /**
                 * Calls UnsetMarker.
                 * @function unsetMarker
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IUnsetMarkerRequest} request UnsetMarkerRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.UnsetMarkerResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumService#listMarkers}.
                 * @memberof mtechnavi.api.forum.ForumService
                 * @typedef ListMarkersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.ListMarkersResponse} [response] ListMarkersResponse
                 */

                /**
                 * Calls ListMarkers.
                 * @function listMarkers
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListMarkersRequest} request ListMarkersRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumService.ListMarkersCallback} callback Node-style callback called with the error, if any, and ListMarkersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumService.prototype.listMarkers = function listMarkers(request, callback) {
                    return this.rpcCall(listMarkers, $root.mtechnavi.api.forum.ListMarkersRequest, $root.mtechnavi.api.forum.ListMarkersResponse, request, callback);
                }, "name", { value: "ListMarkers" });

                /**
                 * Calls ListMarkers.
                 * @function listMarkers
                 * @memberof mtechnavi.api.forum.ForumService
                 * @instance
                 * @param {mtechnavi.api.forum.IListMarkersRequest} request ListMarkersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.ListMarkersResponse>} Promise
                 * @variation 2
                 */

                return ForumService;
            })();

            forum.ForumTaskListService = (function() {

                /**
                 * Constructs a new ForumTaskListService service.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ForumTaskListService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ForumTaskListService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ForumTaskListService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ForumTaskListService;

                /**
                 * Callback as used by {@link mtechnavi.api.forum.ForumTaskListService#unreadListResources}.
                 * @memberof mtechnavi.api.forum.ForumTaskListService
                 * @typedef UnreadListResourcesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.forum.UnreadListResourcesResponse} [response] UnreadListResourcesResponse
                 */

                /**
                 * Calls UnreadListResources.
                 * @function unreadListResources
                 * @memberof mtechnavi.api.forum.ForumTaskListService
                 * @instance
                 * @param {mtechnavi.api.forum.IUnreadListResourcesRequest} request UnreadListResourcesRequest message or plain object
                 * @param {mtechnavi.api.forum.ForumTaskListService.UnreadListResourcesCallback} callback Node-style callback called with the error, if any, and UnreadListResourcesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ForumTaskListService.prototype.unreadListResources = function unreadListResources(request, callback) {
                    return this.rpcCall(unreadListResources, $root.mtechnavi.api.forum.UnreadListResourcesRequest, $root.mtechnavi.api.forum.UnreadListResourcesResponse, request, callback);
                }, "name", { value: "UnreadListResources" });

                /**
                 * Calls UnreadListResources.
                 * @function unreadListResources
                 * @memberof mtechnavi.api.forum.ForumTaskListService
                 * @instance
                 * @param {mtechnavi.api.forum.IUnreadListResourcesRequest} request UnreadListResourcesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.forum.UnreadListResourcesResponse>} Promise
                 * @variation 2
                 */

                return ForumTaskListService;
            })();

            forum.CreateBaseThreadRequest = (function() {

                /**
                 * Properties of a CreateBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface ICreateBaseThreadRequest
                 * @property {mtechnavi.api.forum.IBaseThread|null} [baseThread] CreateBaseThreadRequest baseThread
                 */

                /**
                 * Constructs a new CreateBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a CreateBaseThreadRequest.
                 * @implements ICreateBaseThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.ICreateBaseThreadRequest=} [properties] Properties to set
                 */
                function CreateBaseThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBaseThreadRequest baseThread.
                 * @member {mtechnavi.api.forum.IBaseThread|null|undefined} baseThread
                 * @memberof mtechnavi.api.forum.CreateBaseThreadRequest
                 * @instance
                 */
                CreateBaseThreadRequest.prototype.baseThread = null;

                /**
                 * Verifies a CreateBaseThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.CreateBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBaseThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThread != null && message.hasOwnProperty("baseThread")) {
                        let error = $root.mtechnavi.api.forum.BaseThread.verify(message.baseThread);
                        if (error)
                            return "baseThread." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBaseThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.CreateBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.CreateBaseThreadRequest} CreateBaseThreadRequest
                 */
                CreateBaseThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.CreateBaseThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.CreateBaseThreadRequest();
                    if (object.baseThread != null) {
                        if (typeof object.baseThread !== "object")
                            throw TypeError(".mtechnavi.api.forum.CreateBaseThreadRequest.baseThread: object expected");
                        message.baseThread = $root.mtechnavi.api.forum.BaseThread.fromObject(object.baseThread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBaseThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.CreateBaseThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.CreateBaseThreadRequest} message CreateBaseThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBaseThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseThread = null;
                    if (message.baseThread != null && message.hasOwnProperty("baseThread"))
                        object.baseThread = $root.mtechnavi.api.forum.BaseThread.toObject(message.baseThread, options);
                    return object;
                };

                /**
                 * Converts this CreateBaseThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.CreateBaseThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBaseThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBaseThreadRequest;
            })();

            forum.ListBaseThreadsRequest = (function() {

                /**
                 * Properties of a ListBaseThreadsRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IListBaseThreadsRequest
                 * @property {string|null} [pageToken] ListBaseThreadsRequest pageToken
                 * @property {mtechnavi.api.forum.IResourceReference|null} [resourceReference] ListBaseThreadsRequest resourceReference
                 * @property {string|null} [typeName] ListBaseThreadsRequest typeName
                 * @property {string|null} [resourceId] ListBaseThreadsRequest resourceId
                 */

                /**
                 * Constructs a new ListBaseThreadsRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListBaseThreadsRequest.
                 * @implements IListBaseThreadsRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IListBaseThreadsRequest=} [properties] Properties to set
                 */
                function ListBaseThreadsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseThreadsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @instance
                 */
                ListBaseThreadsRequest.prototype.pageToken = "";

                /**
                 * ListBaseThreadsRequest resourceReference.
                 * @member {mtechnavi.api.forum.IResourceReference|null|undefined} resourceReference
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @instance
                 */
                ListBaseThreadsRequest.prototype.resourceReference = null;

                /**
                 * ListBaseThreadsRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @instance
                 */
                ListBaseThreadsRequest.prototype.typeName = "";

                /**
                 * ListBaseThreadsRequest resourceId.
                 * @member {string} resourceId
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @instance
                 */
                ListBaseThreadsRequest.prototype.resourceId = "";

                /**
                 * Verifies a ListBaseThreadsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseThreadsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference")) {
                        let error = $root.mtechnavi.api.forum.ResourceReference.verify(message.resourceReference);
                        if (error)
                            return "resourceReference." + error;
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        if (!$util.isString(message.resourceId))
                            return "resourceId: string expected";
                    return null;
                };

                /**
                 * Creates a ListBaseThreadsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListBaseThreadsRequest} ListBaseThreadsRequest
                 */
                ListBaseThreadsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListBaseThreadsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListBaseThreadsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.resourceReference != null) {
                        if (typeof object.resourceReference !== "object")
                            throw TypeError(".mtechnavi.api.forum.ListBaseThreadsRequest.resourceReference: object expected");
                        message.resourceReference = $root.mtechnavi.api.forum.ResourceReference.fromObject(object.resourceReference);
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.resourceId != null)
                        message.resourceId = String(object.resourceId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseThreadsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @static
                 * @param {mtechnavi.api.forum.ListBaseThreadsRequest} message ListBaseThreadsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseThreadsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.resourceReference = null;
                        object.typeName = "";
                        object.resourceId = "";
                        object.pageToken = "";
                    }
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference"))
                        object.resourceReference = $root.mtechnavi.api.forum.ResourceReference.toObject(message.resourceReference, options);
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        object.resourceId = message.resourceId;
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBaseThreadsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListBaseThreadsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseThreadsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseThreadsRequest;
            })();

            forum.ListBaseThreadsResponse = (function() {

                /**
                 * Properties of a ListBaseThreadsResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IListBaseThreadsResponse
                 * @property {Long|null} [total] ListBaseThreadsResponse total
                 * @property {Array.<mtechnavi.api.forum.IBaseThread>|null} [items] ListBaseThreadsResponse items
                 * @property {string|null} [pageToken] ListBaseThreadsResponse pageToken
                 */

                /**
                 * Constructs a new ListBaseThreadsResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListBaseThreadsResponse.
                 * @implements IListBaseThreadsResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IListBaseThreadsResponse=} [properties] Properties to set
                 */
                function ListBaseThreadsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBaseThreadsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @instance
                 */
                ListBaseThreadsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListBaseThreadsResponse items.
                 * @member {Array.<mtechnavi.api.forum.IBaseThread>} items
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @instance
                 */
                ListBaseThreadsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListBaseThreadsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @instance
                 */
                ListBaseThreadsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListBaseThreadsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBaseThreadsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.forum.BaseThread.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListBaseThreadsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListBaseThreadsResponse} ListBaseThreadsResponse
                 */
                ListBaseThreadsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListBaseThreadsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListBaseThreadsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.forum.ListBaseThreadsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.ListBaseThreadsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.forum.BaseThread.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListBaseThreadsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @static
                 * @param {mtechnavi.api.forum.ListBaseThreadsResponse} message ListBaseThreadsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBaseThreadsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.forum.BaseThread.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListBaseThreadsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListBaseThreadsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBaseThreadsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBaseThreadsResponse;
            })();

            forum.GetBaseThreadRequest = (function() {

                /**
                 * Properties of a GetBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IGetBaseThreadRequest
                 * @property {string|null} [baseThreadId] GetBaseThreadRequest baseThreadId
                 */

                /**
                 * Constructs a new GetBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a GetBaseThreadRequest.
                 * @implements IGetBaseThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IGetBaseThreadRequest=} [properties] Properties to set
                 */
                function GetBaseThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBaseThreadRequest baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.GetBaseThreadRequest
                 * @instance
                 */
                GetBaseThreadRequest.prototype.baseThreadId = "";

                /**
                 * Verifies a GetBaseThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.GetBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBaseThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    return null;
                };

                /**
                 * Creates a GetBaseThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.GetBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.GetBaseThreadRequest} GetBaseThreadRequest
                 */
                GetBaseThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.GetBaseThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.GetBaseThreadRequest();
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBaseThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.GetBaseThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.GetBaseThreadRequest} message GetBaseThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBaseThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseThreadId = "";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    return object;
                };

                /**
                 * Converts this GetBaseThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.GetBaseThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBaseThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBaseThreadRequest;
            })();

            forum.UpdateBaseThreadRequest = (function() {

                /**
                 * Properties of an UpdateBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IUpdateBaseThreadRequest
                 * @property {mtechnavi.api.forum.IBaseThread|null} [baseThread] UpdateBaseThreadRequest baseThread
                 */

                /**
                 * Constructs a new UpdateBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UpdateBaseThreadRequest.
                 * @implements IUpdateBaseThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IUpdateBaseThreadRequest=} [properties] Properties to set
                 */
                function UpdateBaseThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBaseThreadRequest baseThread.
                 * @member {mtechnavi.api.forum.IBaseThread|null|undefined} baseThread
                 * @memberof mtechnavi.api.forum.UpdateBaseThreadRequest
                 * @instance
                 */
                UpdateBaseThreadRequest.prototype.baseThread = null;

                /**
                 * Verifies an UpdateBaseThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UpdateBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBaseThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThread != null && message.hasOwnProperty("baseThread")) {
                        let error = $root.mtechnavi.api.forum.BaseThread.verify(message.baseThread);
                        if (error)
                            return "baseThread." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBaseThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UpdateBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UpdateBaseThreadRequest} UpdateBaseThreadRequest
                 */
                UpdateBaseThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UpdateBaseThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UpdateBaseThreadRequest();
                    if (object.baseThread != null) {
                        if (typeof object.baseThread !== "object")
                            throw TypeError(".mtechnavi.api.forum.UpdateBaseThreadRequest.baseThread: object expected");
                        message.baseThread = $root.mtechnavi.api.forum.BaseThread.fromObject(object.baseThread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBaseThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UpdateBaseThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.UpdateBaseThreadRequest} message UpdateBaseThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBaseThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseThread = null;
                    if (message.baseThread != null && message.hasOwnProperty("baseThread"))
                        object.baseThread = $root.mtechnavi.api.forum.BaseThread.toObject(message.baseThread, options);
                    return object;
                };

                /**
                 * Converts this UpdateBaseThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UpdateBaseThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBaseThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBaseThreadRequest;
            })();

            forum.DeleteBaseThreadRequest = (function() {

                /**
                 * Properties of a DeleteBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IDeleteBaseThreadRequest
                 * @property {mtechnavi.api.forum.IBaseThread|null} [baseThread] DeleteBaseThreadRequest baseThread
                 */

                /**
                 * Constructs a new DeleteBaseThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a DeleteBaseThreadRequest.
                 * @implements IDeleteBaseThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IDeleteBaseThreadRequest=} [properties] Properties to set
                 */
                function DeleteBaseThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteBaseThreadRequest baseThread.
                 * @member {mtechnavi.api.forum.IBaseThread|null|undefined} baseThread
                 * @memberof mtechnavi.api.forum.DeleteBaseThreadRequest
                 * @instance
                 */
                DeleteBaseThreadRequest.prototype.baseThread = null;

                /**
                 * Verifies a DeleteBaseThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.DeleteBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteBaseThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThread != null && message.hasOwnProperty("baseThread")) {
                        let error = $root.mtechnavi.api.forum.BaseThread.verify(message.baseThread);
                        if (error)
                            return "baseThread." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteBaseThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.DeleteBaseThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.DeleteBaseThreadRequest} DeleteBaseThreadRequest
                 */
                DeleteBaseThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.DeleteBaseThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.DeleteBaseThreadRequest();
                    if (object.baseThread != null) {
                        if (typeof object.baseThread !== "object")
                            throw TypeError(".mtechnavi.api.forum.DeleteBaseThreadRequest.baseThread: object expected");
                        message.baseThread = $root.mtechnavi.api.forum.BaseThread.fromObject(object.baseThread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteBaseThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.DeleteBaseThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.DeleteBaseThreadRequest} message DeleteBaseThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteBaseThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseThread = null;
                    if (message.baseThread != null && message.hasOwnProperty("baseThread"))
                        object.baseThread = $root.mtechnavi.api.forum.BaseThread.toObject(message.baseThread, options);
                    return object;
                };

                /**
                 * Converts this DeleteBaseThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.DeleteBaseThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteBaseThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteBaseThreadRequest;
            })();

            forum.CheckUsableForumRequest = (function() {

                /**
                 * Properties of a CheckUsableForumRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface ICheckUsableForumRequest
                 * @property {string|null} [typeName] CheckUsableForumRequest typeName
                 * @property {string|null} [resourceId] CheckUsableForumRequest resourceId
                 */

                /**
                 * Constructs a new CheckUsableForumRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a CheckUsableForumRequest.
                 * @implements ICheckUsableForumRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.ICheckUsableForumRequest=} [properties] Properties to set
                 */
                function CheckUsableForumRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CheckUsableForumRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @instance
                 */
                CheckUsableForumRequest.prototype.typeName = "";

                /**
                 * CheckUsableForumRequest resourceId.
                 * @member {string} resourceId
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @instance
                 */
                CheckUsableForumRequest.prototype.resourceId = "";

                /**
                 * Verifies a CheckUsableForumRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CheckUsableForumRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        if (!$util.isString(message.resourceId))
                            return "resourceId: string expected";
                    return null;
                };

                /**
                 * Creates a CheckUsableForumRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.CheckUsableForumRequest} CheckUsableForumRequest
                 */
                CheckUsableForumRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.CheckUsableForumRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.CheckUsableForumRequest();
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.resourceId != null)
                        message.resourceId = String(object.resourceId);
                    return message;
                };

                /**
                 * Creates a plain object from a CheckUsableForumRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @static
                 * @param {mtechnavi.api.forum.CheckUsableForumRequest} message CheckUsableForumRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CheckUsableForumRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.typeName = "";
                        object.resourceId = "";
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        object.resourceId = message.resourceId;
                    return object;
                };

                /**
                 * Converts this CheckUsableForumRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.CheckUsableForumRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CheckUsableForumRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CheckUsableForumRequest;
            })();

            forum.CheckUsableForumResponse = (function() {

                /**
                 * Properties of a CheckUsableForumResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface ICheckUsableForumResponse
                 * @property {string|null} [baseThreadId] CheckUsableForumResponse baseThreadId
                 */

                /**
                 * Constructs a new CheckUsableForumResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a CheckUsableForumResponse.
                 * @implements ICheckUsableForumResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.ICheckUsableForumResponse=} [properties] Properties to set
                 */
                function CheckUsableForumResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CheckUsableForumResponse baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.CheckUsableForumResponse
                 * @instance
                 */
                CheckUsableForumResponse.prototype.baseThreadId = "";

                /**
                 * Verifies a CheckUsableForumResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.CheckUsableForumResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CheckUsableForumResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    return null;
                };

                /**
                 * Creates a CheckUsableForumResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.CheckUsableForumResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.CheckUsableForumResponse} CheckUsableForumResponse
                 */
                CheckUsableForumResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.CheckUsableForumResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.CheckUsableForumResponse();
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    return message;
                };

                /**
                 * Creates a plain object from a CheckUsableForumResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.CheckUsableForumResponse
                 * @static
                 * @param {mtechnavi.api.forum.CheckUsableForumResponse} message CheckUsableForumResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CheckUsableForumResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.baseThreadId = "";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    return object;
                };

                /**
                 * Converts this CheckUsableForumResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.CheckUsableForumResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CheckUsableForumResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CheckUsableForumResponse;
            })();

            forum.CreateThreadRequest = (function() {

                /**
                 * Properties of a CreateThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface ICreateThreadRequest
                 * @property {mtechnavi.api.forum.IThread|null} [thread] CreateThreadRequest thread
                 */

                /**
                 * Constructs a new CreateThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a CreateThreadRequest.
                 * @implements ICreateThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.ICreateThreadRequest=} [properties] Properties to set
                 */
                function CreateThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateThreadRequest thread.
                 * @member {mtechnavi.api.forum.IThread|null|undefined} thread
                 * @memberof mtechnavi.api.forum.CreateThreadRequest
                 * @instance
                 */
                CreateThreadRequest.prototype.thread = null;

                /**
                 * Verifies a CreateThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.CreateThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.thread != null && message.hasOwnProperty("thread")) {
                        let error = $root.mtechnavi.api.forum.Thread.verify(message.thread);
                        if (error)
                            return "thread." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.CreateThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.CreateThreadRequest} CreateThreadRequest
                 */
                CreateThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.CreateThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.CreateThreadRequest();
                    if (object.thread != null) {
                        if (typeof object.thread !== "object")
                            throw TypeError(".mtechnavi.api.forum.CreateThreadRequest.thread: object expected");
                        message.thread = $root.mtechnavi.api.forum.Thread.fromObject(object.thread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.CreateThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.CreateThreadRequest} message CreateThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.thread = null;
                    if (message.thread != null && message.hasOwnProperty("thread"))
                        object.thread = $root.mtechnavi.api.forum.Thread.toObject(message.thread, options);
                    return object;
                };

                /**
                 * Converts this CreateThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.CreateThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateThreadRequest;
            })();

            forum.ListThreadsRequest = (function() {

                /**
                 * Properties of a ListThreadsRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IListThreadsRequest
                 * @property {string|null} [pageToken] ListThreadsRequest pageToken
                 * @property {mtechnavi.api.forum.IResourceReference|null} [resourceReference] ListThreadsRequest resourceReference
                 * @property {string|null} [typeName] ListThreadsRequest typeName
                 * @property {string|null} [resourceId] ListThreadsRequest resourceId
                 * @property {Array.<string>|null} [associations] ListThreadsRequest associations
                 * @property {string|null} [baseThreadId] ListThreadsRequest baseThreadId
                 */

                /**
                 * Constructs a new ListThreadsRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListThreadsRequest.
                 * @implements IListThreadsRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IListThreadsRequest=} [properties] Properties to set
                 */
                function ListThreadsRequest(properties) {
                    this.associations = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListThreadsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.pageToken = "";

                /**
                 * ListThreadsRequest resourceReference.
                 * @member {mtechnavi.api.forum.IResourceReference|null|undefined} resourceReference
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.resourceReference = null;

                /**
                 * ListThreadsRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.typeName = "";

                /**
                 * ListThreadsRequest resourceId.
                 * @member {string} resourceId
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.resourceId = "";

                /**
                 * ListThreadsRequest associations.
                 * @member {Array.<string>} associations
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.associations = $util.emptyArray;

                /**
                 * ListThreadsRequest baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 */
                ListThreadsRequest.prototype.baseThreadId = "";

                /**
                 * Verifies a ListThreadsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListThreadsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference")) {
                        let error = $root.mtechnavi.api.forum.ResourceReference.verify(message.resourceReference);
                        if (error)
                            return "resourceReference." + error;
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        if (!$util.isString(message.resourceId))
                            return "resourceId: string expected";
                    if (message.associations != null && message.hasOwnProperty("associations")) {
                        if (!Array.isArray(message.associations))
                            return "associations: array expected";
                        for (let i = 0; i < message.associations.length; ++i)
                            if (!$util.isString(message.associations[i]))
                                return "associations: string[] expected";
                    }
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    return null;
                };

                /**
                 * Creates a ListThreadsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListThreadsRequest} ListThreadsRequest
                 */
                ListThreadsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListThreadsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListThreadsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.resourceReference != null) {
                        if (typeof object.resourceReference !== "object")
                            throw TypeError(".mtechnavi.api.forum.ListThreadsRequest.resourceReference: object expected");
                        message.resourceReference = $root.mtechnavi.api.forum.ResourceReference.fromObject(object.resourceReference);
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.resourceId != null)
                        message.resourceId = String(object.resourceId);
                    if (object.associations) {
                        if (!Array.isArray(object.associations))
                            throw TypeError(".mtechnavi.api.forum.ListThreadsRequest.associations: array expected");
                        message.associations = [];
                        for (let i = 0; i < object.associations.length; ++i)
                            message.associations[i] = String(object.associations[i]);
                    }
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListThreadsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @static
                 * @param {mtechnavi.api.forum.ListThreadsRequest} message ListThreadsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListThreadsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.associations = [];
                    if (options.defaults) {
                        object.resourceReference = null;
                        object.typeName = "";
                        object.resourceId = "";
                        object.pageToken = "";
                        object.baseThreadId = "";
                    }
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference"))
                        object.resourceReference = $root.mtechnavi.api.forum.ResourceReference.toObject(message.resourceReference, options);
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        object.resourceId = message.resourceId;
                    if (message.associations && message.associations.length) {
                        object.associations = [];
                        for (let j = 0; j < message.associations.length; ++j)
                            object.associations[j] = message.associations[j];
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    return object;
                };

                /**
                 * Converts this ListThreadsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListThreadsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListThreadsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListThreadsRequest;
            })();

            forum.ListThreadsResponse = (function() {

                /**
                 * Properties of a ListThreadsResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IListThreadsResponse
                 * @property {Long|null} [total] ListThreadsResponse total
                 * @property {Array.<mtechnavi.api.forum.IThread>|null} [items] ListThreadsResponse items
                 * @property {string|null} [pageToken] ListThreadsResponse pageToken
                 */

                /**
                 * Constructs a new ListThreadsResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListThreadsResponse.
                 * @implements IListThreadsResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IListThreadsResponse=} [properties] Properties to set
                 */
                function ListThreadsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListThreadsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @instance
                 */
                ListThreadsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListThreadsResponse items.
                 * @member {Array.<mtechnavi.api.forum.IThread>} items
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @instance
                 */
                ListThreadsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListThreadsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @instance
                 */
                ListThreadsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListThreadsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListThreadsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.forum.Thread.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListThreadsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListThreadsResponse} ListThreadsResponse
                 */
                ListThreadsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListThreadsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListThreadsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.forum.ListThreadsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.ListThreadsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.forum.Thread.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListThreadsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @static
                 * @param {mtechnavi.api.forum.ListThreadsResponse} message ListThreadsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListThreadsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.forum.Thread.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListThreadsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListThreadsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListThreadsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListThreadsResponse;
            })();

            forum.GetThreadRequest = (function() {

                /**
                 * Properties of a GetThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IGetThreadRequest
                 * @property {string|null} [threadId] GetThreadRequest threadId
                 */

                /**
                 * Constructs a new GetThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a GetThreadRequest.
                 * @implements IGetThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IGetThreadRequest=} [properties] Properties to set
                 */
                function GetThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetThreadRequest threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.GetThreadRequest
                 * @instance
                 */
                GetThreadRequest.prototype.threadId = "";

                /**
                 * Verifies a GetThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.GetThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    return null;
                };

                /**
                 * Creates a GetThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.GetThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.GetThreadRequest} GetThreadRequest
                 */
                GetThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.GetThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.GetThreadRequest();
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.GetThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.GetThreadRequest} message GetThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.threadId = "";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    return object;
                };

                /**
                 * Converts this GetThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.GetThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetThreadRequest;
            })();

            forum.UpdateThreadRequest = (function() {

                /**
                 * Properties of an UpdateThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IUpdateThreadRequest
                 * @property {mtechnavi.api.forum.IThread|null} [thread] UpdateThreadRequest thread
                 */

                /**
                 * Constructs a new UpdateThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UpdateThreadRequest.
                 * @implements IUpdateThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IUpdateThreadRequest=} [properties] Properties to set
                 */
                function UpdateThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateThreadRequest thread.
                 * @member {mtechnavi.api.forum.IThread|null|undefined} thread
                 * @memberof mtechnavi.api.forum.UpdateThreadRequest
                 * @instance
                 */
                UpdateThreadRequest.prototype.thread = null;

                /**
                 * Verifies an UpdateThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UpdateThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.thread != null && message.hasOwnProperty("thread")) {
                        let error = $root.mtechnavi.api.forum.Thread.verify(message.thread);
                        if (error)
                            return "thread." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UpdateThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UpdateThreadRequest} UpdateThreadRequest
                 */
                UpdateThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UpdateThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UpdateThreadRequest();
                    if (object.thread != null) {
                        if (typeof object.thread !== "object")
                            throw TypeError(".mtechnavi.api.forum.UpdateThreadRequest.thread: object expected");
                        message.thread = $root.mtechnavi.api.forum.Thread.fromObject(object.thread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UpdateThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.UpdateThreadRequest} message UpdateThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.thread = null;
                    if (message.thread != null && message.hasOwnProperty("thread"))
                        object.thread = $root.mtechnavi.api.forum.Thread.toObject(message.thread, options);
                    return object;
                };

                /**
                 * Converts this UpdateThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UpdateThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateThreadRequest;
            })();

            forum.DeleteThreadRequest = (function() {

                /**
                 * Properties of a DeleteThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IDeleteThreadRequest
                 * @property {mtechnavi.api.forum.IThread|null} [thread] DeleteThreadRequest thread
                 */

                /**
                 * Constructs a new DeleteThreadRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a DeleteThreadRequest.
                 * @implements IDeleteThreadRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IDeleteThreadRequest=} [properties] Properties to set
                 */
                function DeleteThreadRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteThreadRequest thread.
                 * @member {mtechnavi.api.forum.IThread|null|undefined} thread
                 * @memberof mtechnavi.api.forum.DeleteThreadRequest
                 * @instance
                 */
                DeleteThreadRequest.prototype.thread = null;

                /**
                 * Verifies a DeleteThreadRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.DeleteThreadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteThreadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.thread != null && message.hasOwnProperty("thread")) {
                        let error = $root.mtechnavi.api.forum.Thread.verify(message.thread);
                        if (error)
                            return "thread." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteThreadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.DeleteThreadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.DeleteThreadRequest} DeleteThreadRequest
                 */
                DeleteThreadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.DeleteThreadRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.DeleteThreadRequest();
                    if (object.thread != null) {
                        if (typeof object.thread !== "object")
                            throw TypeError(".mtechnavi.api.forum.DeleteThreadRequest.thread: object expected");
                        message.thread = $root.mtechnavi.api.forum.Thread.fromObject(object.thread);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteThreadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.DeleteThreadRequest
                 * @static
                 * @param {mtechnavi.api.forum.DeleteThreadRequest} message DeleteThreadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteThreadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.thread = null;
                    if (message.thread != null && message.hasOwnProperty("thread"))
                        object.thread = $root.mtechnavi.api.forum.Thread.toObject(message.thread, options);
                    return object;
                };

                /**
                 * Converts this DeleteThreadRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.DeleteThreadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteThreadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteThreadRequest;
            })();

            forum.CreateCommentRequest = (function() {

                /**
                 * Properties of a CreateCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface ICreateCommentRequest
                 * @property {mtechnavi.api.forum.IComment|null} [comment] CreateCommentRequest comment
                 */

                /**
                 * Constructs a new CreateCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a CreateCommentRequest.
                 * @implements ICreateCommentRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.ICreateCommentRequest=} [properties] Properties to set
                 */
                function CreateCommentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateCommentRequest comment.
                 * @member {mtechnavi.api.forum.IComment|null|undefined} comment
                 * @memberof mtechnavi.api.forum.CreateCommentRequest
                 * @instance
                 */
                CreateCommentRequest.prototype.comment = null;

                /**
                 * Verifies a CreateCommentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.CreateCommentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCommentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.comment != null && message.hasOwnProperty("comment")) {
                        let error = $root.mtechnavi.api.forum.Comment.verify(message.comment);
                        if (error)
                            return "comment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateCommentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.CreateCommentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.CreateCommentRequest} CreateCommentRequest
                 */
                CreateCommentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.CreateCommentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.CreateCommentRequest();
                    if (object.comment != null) {
                        if (typeof object.comment !== "object")
                            throw TypeError(".mtechnavi.api.forum.CreateCommentRequest.comment: object expected");
                        message.comment = $root.mtechnavi.api.forum.Comment.fromObject(object.comment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateCommentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.CreateCommentRequest
                 * @static
                 * @param {mtechnavi.api.forum.CreateCommentRequest} message CreateCommentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCommentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.comment = null;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = $root.mtechnavi.api.forum.Comment.toObject(message.comment, options);
                    return object;
                };

                /**
                 * Converts this CreateCommentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.CreateCommentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCommentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateCommentRequest;
            })();

            forum.ListCommentsRequest = (function() {

                /**
                 * Properties of a ListCommentsRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IListCommentsRequest
                 * @property {string|null} [pageToken] ListCommentsRequest pageToken
                 * @property {string|null} [baseThreadId] ListCommentsRequest baseThreadId
                 * @property {string|null} [threadId] ListCommentsRequest threadId
                 */

                /**
                 * Constructs a new ListCommentsRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListCommentsRequest.
                 * @implements IListCommentsRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IListCommentsRequest=} [properties] Properties to set
                 */
                function ListCommentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCommentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @instance
                 */
                ListCommentsRequest.prototype.pageToken = "";

                /**
                 * ListCommentsRequest baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @instance
                 */
                ListCommentsRequest.prototype.baseThreadId = "";

                /**
                 * ListCommentsRequest threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @instance
                 */
                ListCommentsRequest.prototype.threadId = "";

                /**
                 * Verifies a ListCommentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCommentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    return null;
                };

                /**
                 * Creates a ListCommentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListCommentsRequest} ListCommentsRequest
                 */
                ListCommentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListCommentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListCommentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListCommentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @static
                 * @param {mtechnavi.api.forum.ListCommentsRequest} message ListCommentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCommentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.threadId = "";
                        object.pageToken = "";
                        object.baseThreadId = "";
                    }
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    return object;
                };

                /**
                 * Converts this ListCommentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListCommentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCommentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListCommentsRequest;
            })();

            forum.ListCommentsResponse = (function() {

                /**
                 * Properties of a ListCommentsResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IListCommentsResponse
                 * @property {Long|null} [total] ListCommentsResponse total
                 * @property {Array.<mtechnavi.api.forum.IComment>|null} [items] ListCommentsResponse items
                 * @property {string|null} [pageToken] ListCommentsResponse pageToken
                 */

                /**
                 * Constructs a new ListCommentsResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListCommentsResponse.
                 * @implements IListCommentsResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IListCommentsResponse=} [properties] Properties to set
                 */
                function ListCommentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListCommentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @instance
                 */
                ListCommentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListCommentsResponse items.
                 * @member {Array.<mtechnavi.api.forum.IComment>} items
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @instance
                 */
                ListCommentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListCommentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @instance
                 */
                ListCommentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListCommentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListCommentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.forum.Comment.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListCommentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListCommentsResponse} ListCommentsResponse
                 */
                ListCommentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListCommentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListCommentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.forum.ListCommentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.ListCommentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.forum.Comment.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListCommentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @static
                 * @param {mtechnavi.api.forum.ListCommentsResponse} message ListCommentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListCommentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.forum.Comment.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListCommentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListCommentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListCommentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListCommentsResponse;
            })();

            forum.GetCommentRequest = (function() {

                /**
                 * Properties of a GetCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IGetCommentRequest
                 * @property {string|null} [commentId] GetCommentRequest commentId
                 */

                /**
                 * Constructs a new GetCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a GetCommentRequest.
                 * @implements IGetCommentRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IGetCommentRequest=} [properties] Properties to set
                 */
                function GetCommentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCommentRequest commentId.
                 * @member {string} commentId
                 * @memberof mtechnavi.api.forum.GetCommentRequest
                 * @instance
                 */
                GetCommentRequest.prototype.commentId = "";

                /**
                 * Verifies a GetCommentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.GetCommentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCommentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.commentId != null && message.hasOwnProperty("commentId"))
                        if (!$util.isString(message.commentId))
                            return "commentId: string expected";
                    return null;
                };

                /**
                 * Creates a GetCommentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.GetCommentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.GetCommentRequest} GetCommentRequest
                 */
                GetCommentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.GetCommentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.GetCommentRequest();
                    if (object.commentId != null)
                        message.commentId = String(object.commentId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCommentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.GetCommentRequest
                 * @static
                 * @param {mtechnavi.api.forum.GetCommentRequest} message GetCommentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCommentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.commentId = "";
                    if (message.commentId != null && message.hasOwnProperty("commentId"))
                        object.commentId = message.commentId;
                    return object;
                };

                /**
                 * Converts this GetCommentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.GetCommentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCommentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCommentRequest;
            })();

            forum.UpdateCommentRequest = (function() {

                /**
                 * Properties of an UpdateCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IUpdateCommentRequest
                 * @property {mtechnavi.api.forum.IComment|null} [comment] UpdateCommentRequest comment
                 */

                /**
                 * Constructs a new UpdateCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UpdateCommentRequest.
                 * @implements IUpdateCommentRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IUpdateCommentRequest=} [properties] Properties to set
                 */
                function UpdateCommentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateCommentRequest comment.
                 * @member {mtechnavi.api.forum.IComment|null|undefined} comment
                 * @memberof mtechnavi.api.forum.UpdateCommentRequest
                 * @instance
                 */
                UpdateCommentRequest.prototype.comment = null;

                /**
                 * Verifies an UpdateCommentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UpdateCommentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCommentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.comment != null && message.hasOwnProperty("comment")) {
                        let error = $root.mtechnavi.api.forum.Comment.verify(message.comment);
                        if (error)
                            return "comment." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateCommentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UpdateCommentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UpdateCommentRequest} UpdateCommentRequest
                 */
                UpdateCommentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UpdateCommentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UpdateCommentRequest();
                    if (object.comment != null) {
                        if (typeof object.comment !== "object")
                            throw TypeError(".mtechnavi.api.forum.UpdateCommentRequest.comment: object expected");
                        message.comment = $root.mtechnavi.api.forum.Comment.fromObject(object.comment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateCommentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UpdateCommentRequest
                 * @static
                 * @param {mtechnavi.api.forum.UpdateCommentRequest} message UpdateCommentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCommentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.comment = null;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = $root.mtechnavi.api.forum.Comment.toObject(message.comment, options);
                    return object;
                };

                /**
                 * Converts this UpdateCommentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UpdateCommentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCommentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateCommentRequest;
            })();

            forum.DeleteCommentRequest = (function() {

                /**
                 * Properties of a DeleteCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IDeleteCommentRequest
                 * @property {mtechnavi.api.forum.IComment|null} [comment] DeleteCommentRequest comment
                 */

                /**
                 * Constructs a new DeleteCommentRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a DeleteCommentRequest.
                 * @implements IDeleteCommentRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IDeleteCommentRequest=} [properties] Properties to set
                 */
                function DeleteCommentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteCommentRequest comment.
                 * @member {mtechnavi.api.forum.IComment|null|undefined} comment
                 * @memberof mtechnavi.api.forum.DeleteCommentRequest
                 * @instance
                 */
                DeleteCommentRequest.prototype.comment = null;

                /**
                 * Verifies a DeleteCommentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.DeleteCommentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteCommentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.comment != null && message.hasOwnProperty("comment")) {
                        let error = $root.mtechnavi.api.forum.Comment.verify(message.comment);
                        if (error)
                            return "comment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteCommentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.DeleteCommentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.DeleteCommentRequest} DeleteCommentRequest
                 */
                DeleteCommentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.DeleteCommentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.DeleteCommentRequest();
                    if (object.comment != null) {
                        if (typeof object.comment !== "object")
                            throw TypeError(".mtechnavi.api.forum.DeleteCommentRequest.comment: object expected");
                        message.comment = $root.mtechnavi.api.forum.Comment.fromObject(object.comment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteCommentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.DeleteCommentRequest
                 * @static
                 * @param {mtechnavi.api.forum.DeleteCommentRequest} message DeleteCommentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteCommentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.comment = null;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = $root.mtechnavi.api.forum.Comment.toObject(message.comment, options);
                    return object;
                };

                /**
                 * Converts this DeleteCommentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.DeleteCommentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteCommentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteCommentRequest;
            })();

            forum.SetMarkerRequest = (function() {

                /**
                 * Properties of a SetMarkerRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface ISetMarkerRequest
                 * @property {mtechnavi.api.forum.MarkerRequestMode|null} [mode] SetMarkerRequest mode
                 * @property {string|null} [typeName] SetMarkerRequest typeName
                 * @property {string|null} [recordId] SetMarkerRequest recordId
                 * @property {Array.<sharelib.INameOption>|null} [markers] SetMarkerRequest markers
                 * @property {string|null} [userId] SetMarkerRequest userId
                 */

                /**
                 * Constructs a new SetMarkerRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a SetMarkerRequest.
                 * @implements ISetMarkerRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.ISetMarkerRequest=} [properties] Properties to set
                 */
                function SetMarkerRequest(properties) {
                    this.markers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetMarkerRequest mode.
                 * @member {mtechnavi.api.forum.MarkerRequestMode} mode
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 */
                SetMarkerRequest.prototype.mode = 0;

                /**
                 * SetMarkerRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 */
                SetMarkerRequest.prototype.typeName = "";

                /**
                 * SetMarkerRequest recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 */
                SetMarkerRequest.prototype.recordId = "";

                /**
                 * SetMarkerRequest markers.
                 * @member {Array.<sharelib.INameOption>} markers
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 */
                SetMarkerRequest.prototype.markers = $util.emptyArray;

                /**
                 * SetMarkerRequest userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 */
                SetMarkerRequest.prototype.userId = "";

                /**
                 * Verifies a SetMarkerRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetMarkerRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        switch (message.mode) {
                        default:
                            return "mode: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.markers != null && message.hasOwnProperty("markers")) {
                        if (!Array.isArray(message.markers))
                            return "markers: array expected";
                        for (let i = 0; i < message.markers.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.markers[i]);
                            if (error)
                                return "markers." + error;
                        }
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a SetMarkerRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.SetMarkerRequest} SetMarkerRequest
                 */
                SetMarkerRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.SetMarkerRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.SetMarkerRequest();
                    switch (object.mode) {
                    case "COMPLETE":
                    case 0:
                        message.mode = 0;
                        break;
                    case "INCREMENTAL":
                    case 1:
                        message.mode = 1;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.markers) {
                        if (!Array.isArray(object.markers))
                            throw TypeError(".mtechnavi.api.forum.SetMarkerRequest.markers: array expected");
                        message.markers = [];
                        for (let i = 0; i < object.markers.length; ++i) {
                            if (typeof object.markers[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.SetMarkerRequest.markers: object expected");
                            message.markers[i] = $root.sharelib.NameOption.fromObject(object.markers[i]);
                        }
                    }
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a SetMarkerRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @static
                 * @param {mtechnavi.api.forum.SetMarkerRequest} message SetMarkerRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetMarkerRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.markers = [];
                    if (options.defaults) {
                        object.mode = options.enums === String ? "COMPLETE" : 0;
                        object.typeName = "";
                        object.recordId = "";
                        object.userId = "";
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        object.mode = options.enums === String ? $root.mtechnavi.api.forum.MarkerRequestMode[message.mode] : message.mode;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.markers && message.markers.length) {
                        object.markers = [];
                        for (let j = 0; j < message.markers.length; ++j)
                            object.markers[j] = $root.sharelib.NameOption.toObject(message.markers[j], options);
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this SetMarkerRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.SetMarkerRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetMarkerRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetMarkerRequest;
            })();

            forum.SetMarkerResponse = (function() {

                /**
                 * Properties of a SetMarkerResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface ISetMarkerResponse
                 * @property {mtechnavi.api.forum.IMarker|null} [marker] SetMarkerResponse marker
                 */

                /**
                 * Constructs a new SetMarkerResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a SetMarkerResponse.
                 * @implements ISetMarkerResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.ISetMarkerResponse=} [properties] Properties to set
                 */
                function SetMarkerResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetMarkerResponse marker.
                 * @member {mtechnavi.api.forum.IMarker|null|undefined} marker
                 * @memberof mtechnavi.api.forum.SetMarkerResponse
                 * @instance
                 */
                SetMarkerResponse.prototype.marker = null;

                /**
                 * Verifies a SetMarkerResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.SetMarkerResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetMarkerResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.marker != null && message.hasOwnProperty("marker")) {
                        let error = $root.mtechnavi.api.forum.Marker.verify(message.marker);
                        if (error)
                            return "marker." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SetMarkerResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.SetMarkerResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.SetMarkerResponse} SetMarkerResponse
                 */
                SetMarkerResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.SetMarkerResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.SetMarkerResponse();
                    if (object.marker != null) {
                        if (typeof object.marker !== "object")
                            throw TypeError(".mtechnavi.api.forum.SetMarkerResponse.marker: object expected");
                        message.marker = $root.mtechnavi.api.forum.Marker.fromObject(object.marker);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SetMarkerResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.SetMarkerResponse
                 * @static
                 * @param {mtechnavi.api.forum.SetMarkerResponse} message SetMarkerResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetMarkerResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.marker = null;
                    if (message.marker != null && message.hasOwnProperty("marker"))
                        object.marker = $root.mtechnavi.api.forum.Marker.toObject(message.marker, options);
                    return object;
                };

                /**
                 * Converts this SetMarkerResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.SetMarkerResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetMarkerResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetMarkerResponse;
            })();

            forum.UnsetMarkerRequest = (function() {

                /**
                 * Properties of an UnsetMarkerRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IUnsetMarkerRequest
                 * @property {mtechnavi.api.forum.MarkerRequestMode|null} [mode] UnsetMarkerRequest mode
                 * @property {string|null} [typeName] UnsetMarkerRequest typeName
                 * @property {string|null} [recordId] UnsetMarkerRequest recordId
                 * @property {Array.<sharelib.INameOption>|null} [markers] UnsetMarkerRequest markers
                 */

                /**
                 * Constructs a new UnsetMarkerRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UnsetMarkerRequest.
                 * @implements IUnsetMarkerRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IUnsetMarkerRequest=} [properties] Properties to set
                 */
                function UnsetMarkerRequest(properties) {
                    this.markers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnsetMarkerRequest mode.
                 * @member {mtechnavi.api.forum.MarkerRequestMode} mode
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @instance
                 */
                UnsetMarkerRequest.prototype.mode = 0;

                /**
                 * UnsetMarkerRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @instance
                 */
                UnsetMarkerRequest.prototype.typeName = "";

                /**
                 * UnsetMarkerRequest recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @instance
                 */
                UnsetMarkerRequest.prototype.recordId = "";

                /**
                 * UnsetMarkerRequest markers.
                 * @member {Array.<sharelib.INameOption>} markers
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @instance
                 */
                UnsetMarkerRequest.prototype.markers = $util.emptyArray;

                /**
                 * Verifies an UnsetMarkerRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnsetMarkerRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        switch (message.mode) {
                        default:
                            return "mode: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.markers != null && message.hasOwnProperty("markers")) {
                        if (!Array.isArray(message.markers))
                            return "markers: array expected";
                        for (let i = 0; i < message.markers.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.markers[i]);
                            if (error)
                                return "markers." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an UnsetMarkerRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UnsetMarkerRequest} UnsetMarkerRequest
                 */
                UnsetMarkerRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UnsetMarkerRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UnsetMarkerRequest();
                    switch (object.mode) {
                    case "COMPLETE":
                    case 0:
                        message.mode = 0;
                        break;
                    case "INCREMENTAL":
                    case 1:
                        message.mode = 1;
                        break;
                    }
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.markers) {
                        if (!Array.isArray(object.markers))
                            throw TypeError(".mtechnavi.api.forum.UnsetMarkerRequest.markers: array expected");
                        message.markers = [];
                        for (let i = 0; i < object.markers.length; ++i) {
                            if (typeof object.markers[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.UnsetMarkerRequest.markers: object expected");
                            message.markers[i] = $root.sharelib.NameOption.fromObject(object.markers[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UnsetMarkerRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @static
                 * @param {mtechnavi.api.forum.UnsetMarkerRequest} message UnsetMarkerRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnsetMarkerRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.markers = [];
                    if (options.defaults) {
                        object.mode = options.enums === String ? "COMPLETE" : 0;
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        object.mode = options.enums === String ? $root.mtechnavi.api.forum.MarkerRequestMode[message.mode] : message.mode;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.markers && message.markers.length) {
                        object.markers = [];
                        for (let j = 0; j < message.markers.length; ++j)
                            object.markers[j] = $root.sharelib.NameOption.toObject(message.markers[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this UnsetMarkerRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UnsetMarkerRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnsetMarkerRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnsetMarkerRequest;
            })();

            forum.UnsetMarkerResponse = (function() {

                /**
                 * Properties of an UnsetMarkerResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IUnsetMarkerResponse
                 * @property {mtechnavi.api.forum.IMarker|null} [marker] UnsetMarkerResponse marker
                 */

                /**
                 * Constructs a new UnsetMarkerResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UnsetMarkerResponse.
                 * @implements IUnsetMarkerResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IUnsetMarkerResponse=} [properties] Properties to set
                 */
                function UnsetMarkerResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnsetMarkerResponse marker.
                 * @member {mtechnavi.api.forum.IMarker|null|undefined} marker
                 * @memberof mtechnavi.api.forum.UnsetMarkerResponse
                 * @instance
                 */
                UnsetMarkerResponse.prototype.marker = null;

                /**
                 * Verifies an UnsetMarkerResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UnsetMarkerResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnsetMarkerResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.marker != null && message.hasOwnProperty("marker")) {
                        let error = $root.mtechnavi.api.forum.Marker.verify(message.marker);
                        if (error)
                            return "marker." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UnsetMarkerResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UnsetMarkerResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UnsetMarkerResponse} UnsetMarkerResponse
                 */
                UnsetMarkerResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UnsetMarkerResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UnsetMarkerResponse();
                    if (object.marker != null) {
                        if (typeof object.marker !== "object")
                            throw TypeError(".mtechnavi.api.forum.UnsetMarkerResponse.marker: object expected");
                        message.marker = $root.mtechnavi.api.forum.Marker.fromObject(object.marker);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UnsetMarkerResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UnsetMarkerResponse
                 * @static
                 * @param {mtechnavi.api.forum.UnsetMarkerResponse} message UnsetMarkerResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnsetMarkerResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.marker = null;
                    if (message.marker != null && message.hasOwnProperty("marker"))
                        object.marker = $root.mtechnavi.api.forum.Marker.toObject(message.marker, options);
                    return object;
                };

                /**
                 * Converts this UnsetMarkerResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UnsetMarkerResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnsetMarkerResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnsetMarkerResponse;
            })();

            forum.ListMarkersRequest = (function() {

                /**
                 * Properties of a ListMarkersRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IListMarkersRequest
                 * @property {string|null} [pageToken] ListMarkersRequest pageToken
                 * @property {string|null} [baseThreadId] ListMarkersRequest baseThreadId
                 * @property {string|null} [threadId] ListMarkersRequest threadId
                 */

                /**
                 * Constructs a new ListMarkersRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListMarkersRequest.
                 * @implements IListMarkersRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IListMarkersRequest=} [properties] Properties to set
                 */
                function ListMarkersRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListMarkersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @instance
                 */
                ListMarkersRequest.prototype.pageToken = "";

                /**
                 * ListMarkersRequest baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @instance
                 */
                ListMarkersRequest.prototype.baseThreadId = "";

                /**
                 * ListMarkersRequest threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @instance
                 */
                ListMarkersRequest.prototype.threadId = "";

                /**
                 * Verifies a ListMarkersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListMarkersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    return null;
                };

                /**
                 * Creates a ListMarkersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListMarkersRequest} ListMarkersRequest
                 */
                ListMarkersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListMarkersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListMarkersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    return message;
                };

                /**
                 * Creates a plain object from a ListMarkersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @static
                 * @param {mtechnavi.api.forum.ListMarkersRequest} message ListMarkersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListMarkersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.baseThreadId = "";
                        object.threadId = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    return object;
                };

                /**
                 * Converts this ListMarkersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListMarkersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListMarkersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListMarkersRequest;
            })();

            forum.ListMarkersResponse = (function() {

                /**
                 * Properties of a ListMarkersResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IListMarkersResponse
                 * @property {Long|null} [total] ListMarkersResponse total
                 * @property {Array.<mtechnavi.api.forum.IMarker>|null} [items] ListMarkersResponse items
                 * @property {string|null} [pageToken] ListMarkersResponse pageToken
                 */

                /**
                 * Constructs a new ListMarkersResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ListMarkersResponse.
                 * @implements IListMarkersResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IListMarkersResponse=} [properties] Properties to set
                 */
                function ListMarkersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListMarkersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @instance
                 */
                ListMarkersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListMarkersResponse items.
                 * @member {Array.<mtechnavi.api.forum.IMarker>} items
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @instance
                 */
                ListMarkersResponse.prototype.items = $util.emptyArray;

                /**
                 * ListMarkersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @instance
                 */
                ListMarkersResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListMarkersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListMarkersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.forum.Marker.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListMarkersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ListMarkersResponse} ListMarkersResponse
                 */
                ListMarkersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ListMarkersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ListMarkersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.forum.ListMarkersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.ListMarkersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.forum.Marker.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListMarkersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @static
                 * @param {mtechnavi.api.forum.ListMarkersResponse} message ListMarkersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListMarkersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.forum.Marker.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListMarkersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ListMarkersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListMarkersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListMarkersResponse;
            })();

            forum.UnreadListResourcesRequest = (function() {

                /**
                 * Properties of an UnreadListResourcesRequest.
                 * @memberof mtechnavi.api.forum
                 * @interface IUnreadListResourcesRequest
                 * @property {Array.<string>|null} [typeName] UnreadListResourcesRequest typeName
                 * @property {string|null} [referenceMode] UnreadListResourcesRequest referenceMode
                 * @property {string|null} [pageToken] UnreadListResourcesRequest pageToken
                 */

                /**
                 * Constructs a new UnreadListResourcesRequest.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UnreadListResourcesRequest.
                 * @implements IUnreadListResourcesRequest
                 * @constructor
                 * @param {mtechnavi.api.forum.IUnreadListResourcesRequest=} [properties] Properties to set
                 */
                function UnreadListResourcesRequest(properties) {
                    this.typeName = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnreadListResourcesRequest typeName.
                 * @member {Array.<string>} typeName
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @instance
                 */
                UnreadListResourcesRequest.prototype.typeName = $util.emptyArray;

                /**
                 * UnreadListResourcesRequest referenceMode.
                 * @member {string} referenceMode
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @instance
                 */
                UnreadListResourcesRequest.prototype.referenceMode = "";

                /**
                 * UnreadListResourcesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @instance
                 */
                UnreadListResourcesRequest.prototype.pageToken = "";

                /**
                 * Verifies an UnreadListResourcesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnreadListResourcesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName")) {
                        if (!Array.isArray(message.typeName))
                            return "typeName: array expected";
                        for (let i = 0; i < message.typeName.length; ++i)
                            if (!$util.isString(message.typeName[i]))
                                return "typeName: string[] expected";
                    }
                    if (message.referenceMode != null && message.hasOwnProperty("referenceMode"))
                        if (!$util.isString(message.referenceMode))
                            return "referenceMode: string expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UnreadListResourcesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UnreadListResourcesRequest} UnreadListResourcesRequest
                 */
                UnreadListResourcesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UnreadListResourcesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UnreadListResourcesRequest();
                    if (object.typeName) {
                        if (!Array.isArray(object.typeName))
                            throw TypeError(".mtechnavi.api.forum.UnreadListResourcesRequest.typeName: array expected");
                        message.typeName = [];
                        for (let i = 0; i < object.typeName.length; ++i)
                            message.typeName[i] = String(object.typeName[i]);
                    }
                    if (object.referenceMode != null)
                        message.referenceMode = String(object.referenceMode);
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UnreadListResourcesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @static
                 * @param {mtechnavi.api.forum.UnreadListResourcesRequest} message UnreadListResourcesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnreadListResourcesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.typeName = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.referenceMode = "";
                    }
                    if (message.typeName && message.typeName.length) {
                        object.typeName = [];
                        for (let j = 0; j < message.typeName.length; ++j)
                            object.typeName[j] = message.typeName[j];
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.referenceMode != null && message.hasOwnProperty("referenceMode"))
                        object.referenceMode = message.referenceMode;
                    return object;
                };

                /**
                 * Converts this UnreadListResourcesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UnreadListResourcesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnreadListResourcesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnreadListResourcesRequest;
            })();

            forum.UnreadListResourcesResponse = (function() {

                /**
                 * Properties of an UnreadListResourcesResponse.
                 * @memberof mtechnavi.api.forum
                 * @interface IUnreadListResourcesResponse
                 * @property {Long|null} [total] UnreadListResourcesResponse total
                 * @property {Array.<mtechnavi.api.forum.UnreadListResourcesResponse.IResource>|null} [items] UnreadListResourcesResponse items
                 * @property {string|null} [pageToken] UnreadListResourcesResponse pageToken
                 */

                /**
                 * Constructs a new UnreadListResourcesResponse.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents an UnreadListResourcesResponse.
                 * @implements IUnreadListResourcesResponse
                 * @constructor
                 * @param {mtechnavi.api.forum.IUnreadListResourcesResponse=} [properties] Properties to set
                 */
                function UnreadListResourcesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnreadListResourcesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @instance
                 */
                UnreadListResourcesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UnreadListResourcesResponse items.
                 * @member {Array.<mtechnavi.api.forum.UnreadListResourcesResponse.IResource>} items
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @instance
                 */
                UnreadListResourcesResponse.prototype.items = $util.emptyArray;

                /**
                 * UnreadListResourcesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @instance
                 */
                UnreadListResourcesResponse.prototype.pageToken = "";

                /**
                 * Verifies an UnreadListResourcesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnreadListResourcesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.forum.UnreadListResourcesResponse.Resource.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UnreadListResourcesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.UnreadListResourcesResponse} UnreadListResourcesResponse
                 */
                UnreadListResourcesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.UnreadListResourcesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.forum.UnreadListResourcesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.forum.UnreadListResourcesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.UnreadListResourcesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.forum.UnreadListResourcesResponse.Resource.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UnreadListResourcesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @static
                 * @param {mtechnavi.api.forum.UnreadListResourcesResponse} message UnreadListResourcesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnreadListResourcesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.forum.UnreadListResourcesResponse.Resource.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UnreadListResourcesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnreadListResourcesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UnreadListResourcesResponse.Resource = (function() {

                    /**
                     * Properties of a Resource.
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                     * @interface IResource
                     * @property {string|null} [typeName] Resource typeName
                     * @property {string|null} [recordId] Resource recordId
                     * @property {mtechnavi.api.forum.IBaseThread|null} [baseThread] Resource baseThread
                     */

                    /**
                     * Constructs a new Resource.
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse
                     * @classdesc Represents a Resource.
                     * @implements IResource
                     * @constructor
                     * @param {mtechnavi.api.forum.UnreadListResourcesResponse.IResource=} [properties] Properties to set
                     */
                    function Resource(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Resource typeName.
                     * @member {string} typeName
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @instance
                     */
                    Resource.prototype.typeName = "";

                    /**
                     * Resource recordId.
                     * @member {string} recordId
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @instance
                     */
                    Resource.prototype.recordId = "";

                    /**
                     * Resource baseThread.
                     * @member {mtechnavi.api.forum.IBaseThread|null|undefined} baseThread
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @instance
                     */
                    Resource.prototype.baseThread = null;

                    /**
                     * Verifies a Resource message.
                     * @function verify
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Resource.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.typeName != null && message.hasOwnProperty("typeName"))
                            if (!$util.isString(message.typeName))
                                return "typeName: string expected";
                        if (message.recordId != null && message.hasOwnProperty("recordId"))
                            if (!$util.isString(message.recordId))
                                return "recordId: string expected";
                        if (message.baseThread != null && message.hasOwnProperty("baseThread")) {
                            let error = $root.mtechnavi.api.forum.BaseThread.verify(message.baseThread);
                            if (error)
                                return "baseThread." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Resource message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.forum.UnreadListResourcesResponse.Resource} Resource
                     */
                    Resource.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.forum.UnreadListResourcesResponse.Resource)
                            return object;
                        let message = new $root.mtechnavi.api.forum.UnreadListResourcesResponse.Resource();
                        if (object.typeName != null)
                            message.typeName = String(object.typeName);
                        if (object.recordId != null)
                            message.recordId = String(object.recordId);
                        if (object.baseThread != null) {
                            if (typeof object.baseThread !== "object")
                                throw TypeError(".mtechnavi.api.forum.UnreadListResourcesResponse.Resource.baseThread: object expected");
                            message.baseThread = $root.mtechnavi.api.forum.BaseThread.fromObject(object.baseThread);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Resource message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @static
                     * @param {mtechnavi.api.forum.UnreadListResourcesResponse.Resource} message Resource
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Resource.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.typeName = "";
                            object.recordId = "";
                            object.baseThread = null;
                        }
                        if (message.typeName != null && message.hasOwnProperty("typeName"))
                            object.typeName = message.typeName;
                        if (message.recordId != null && message.hasOwnProperty("recordId"))
                            object.recordId = message.recordId;
                        if (message.baseThread != null && message.hasOwnProperty("baseThread"))
                            object.baseThread = $root.mtechnavi.api.forum.BaseThread.toObject(message.baseThread, options);
                        return object;
                    };

                    /**
                     * Converts this Resource to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.forum.UnreadListResourcesResponse.Resource
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Resource.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Resource;
                })();

                return UnreadListResourcesResponse;
            })();

            forum.BaseThread = (function() {

                /**
                 * Properties of a BaseThread.
                 * @memberof mtechnavi.api.forum
                 * @interface IBaseThread
                 * @property {string|null} [baseThreadId] BaseThread baseThreadId
                 * @property {string|null} [owner] BaseThread owner
                 * @property {mtechnavi.api.forum.IResourceReference|null} [resourceReference] BaseThread resourceReference
                 * @property {string|null} [urn] BaseThread urn
                 * @property {Array.<string>|null} [companyIds] BaseThread companyIds
                 * @property {Array.<sharelib.INameOption>|null} [selectableThreadTypes] BaseThread selectableThreadTypes
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] BaseThread sharedProperties
                 * @property {Long|null} [deletedAt] BaseThread deletedAt
                 * @property {Long|null} [createdAt] BaseThread createdAt
                 * @property {Long|null} [updatedAt] BaseThread updatedAt
                 */

                /**
                 * Constructs a new BaseThread.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a BaseThread.
                 * @implements IBaseThread
                 * @constructor
                 * @param {mtechnavi.api.forum.IBaseThread=} [properties] Properties to set
                 */
                function BaseThread(properties) {
                    this.companyIds = [];
                    this.selectableThreadTypes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BaseThread baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.baseThreadId = "";

                /**
                 * BaseThread owner.
                 * @member {string} owner
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.owner = "";

                /**
                 * BaseThread resourceReference.
                 * @member {mtechnavi.api.forum.IResourceReference|null|undefined} resourceReference
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.resourceReference = null;

                /**
                 * BaseThread urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.urn = "";

                /**
                 * BaseThread companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.companyIds = $util.emptyArray;

                /**
                 * BaseThread selectableThreadTypes.
                 * @member {Array.<sharelib.INameOption>} selectableThreadTypes
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.selectableThreadTypes = $util.emptyArray;

                /**
                 * BaseThread sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.sharedProperties = null;

                /**
                 * BaseThread deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseThread createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * BaseThread updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 */
                BaseThread.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a BaseThread message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BaseThread.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    if (message.owner != null && message.hasOwnProperty("owner"))
                        if (!$util.isString(message.owner))
                            return "owner: string expected";
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference")) {
                        let error = $root.mtechnavi.api.forum.ResourceReference.verify(message.resourceReference);
                        if (error)
                            return "resourceReference." + error;
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    if (message.selectableThreadTypes != null && message.hasOwnProperty("selectableThreadTypes")) {
                        if (!Array.isArray(message.selectableThreadTypes))
                            return "selectableThreadTypes: array expected";
                        for (let i = 0; i < message.selectableThreadTypes.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.selectableThreadTypes[i]);
                            if (error)
                                return "selectableThreadTypes." + error;
                        }
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a BaseThread message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.BaseThread} BaseThread
                 */
                BaseThread.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.BaseThread)
                        return object;
                    let message = new $root.mtechnavi.api.forum.BaseThread();
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    if (object.owner != null)
                        message.owner = String(object.owner);
                    if (object.resourceReference != null) {
                        if (typeof object.resourceReference !== "object")
                            throw TypeError(".mtechnavi.api.forum.BaseThread.resourceReference: object expected");
                        message.resourceReference = $root.mtechnavi.api.forum.ResourceReference.fromObject(object.resourceReference);
                    }
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.forum.BaseThread.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    if (object.selectableThreadTypes) {
                        if (!Array.isArray(object.selectableThreadTypes))
                            throw TypeError(".mtechnavi.api.forum.BaseThread.selectableThreadTypes: array expected");
                        message.selectableThreadTypes = [];
                        for (let i = 0; i < object.selectableThreadTypes.length; ++i) {
                            if (typeof object.selectableThreadTypes[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.BaseThread.selectableThreadTypes: object expected");
                            message.selectableThreadTypes[i] = $root.sharelib.NameOption.fromObject(object.selectableThreadTypes[i]);
                        }
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.forum.BaseThread.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a BaseThread message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @static
                 * @param {mtechnavi.api.forum.BaseThread} message BaseThread
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BaseThread.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.companyIds = [];
                        object.selectableThreadTypes = [];
                    }
                    if (options.defaults) {
                        object.baseThreadId = "";
                        object.owner = "";
                        object.resourceReference = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        object.urn = "";
                    }
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    if (message.owner != null && message.hasOwnProperty("owner"))
                        object.owner = message.owner;
                    if (message.resourceReference != null && message.hasOwnProperty("resourceReference"))
                        object.resourceReference = $root.mtechnavi.api.forum.ResourceReference.toObject(message.resourceReference, options);
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.selectableThreadTypes && message.selectableThreadTypes.length) {
                        object.selectableThreadTypes = [];
                        for (let j = 0; j < message.selectableThreadTypes.length; ++j)
                            object.selectableThreadTypes[j] = $root.sharelib.NameOption.toObject(message.selectableThreadTypes[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this BaseThread to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.BaseThread
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BaseThread.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BaseThread;
            })();

            forum.Thread = (function() {

                /**
                 * Properties of a Thread.
                 * @memberof mtechnavi.api.forum
                 * @interface IThread
                 * @property {string|null} [threadId] Thread threadId
                 * @property {string|null} [baseThreadId] Thread baseThreadId
                 * @property {string|null} [baseThreadOwner] Thread baseThreadOwner
                 * @property {string|null} [displayName] Thread displayName
                 * @property {Array.<string>|null} [companyIds] Thread companyIds
                 * @property {Array.<sharelib.ICompanyReference>|null} [companys] Thread companys
                 * @property {sharelib.INameOption|null} [threadType] Thread threadType
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Thread sharedProperties
                 * @property {mtechnavi.api.forum.Thread.IAttachedPoint|null} [attachedPoint] Thread attachedPoint
                 * @property {Array.<string>|null} [associations] Thread associations
                 * @property {mtechnavi.api.forum.IContributor|null} [contributor] Thread contributor
                 * @property {boolean|null} [completed] Thread completed
                 * @property {Long|null} [deletedAt] Thread deletedAt
                 * @property {Long|null} [createdAt] Thread createdAt
                 * @property {Long|null} [updatedAt] Thread updatedAt
                 */

                /**
                 * Constructs a new Thread.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a Thread.
                 * @implements IThread
                 * @constructor
                 * @param {mtechnavi.api.forum.IThread=} [properties] Properties to set
                 */
                function Thread(properties) {
                    this.companyIds = [];
                    this.companys = [];
                    this.associations = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Thread threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.threadId = "";

                /**
                 * Thread baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.baseThreadId = "";

                /**
                 * Thread baseThreadOwner.
                 * @member {string} baseThreadOwner
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.baseThreadOwner = "";

                /**
                 * Thread displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.displayName = "";

                /**
                 * Thread companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.companyIds = $util.emptyArray;

                /**
                 * Thread companys.
                 * @member {Array.<sharelib.ICompanyReference>} companys
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.companys = $util.emptyArray;

                /**
                 * Thread threadType.
                 * @member {sharelib.INameOption|null|undefined} threadType
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.threadType = null;

                /**
                 * Thread sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.sharedProperties = null;

                /**
                 * Thread attachedPoint.
                 * @member {mtechnavi.api.forum.Thread.IAttachedPoint|null|undefined} attachedPoint
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.attachedPoint = null;

                /**
                 * Thread associations.
                 * @member {Array.<string>} associations
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.associations = $util.emptyArray;

                /**
                 * Thread contributor.
                 * @member {mtechnavi.api.forum.IContributor|null|undefined} contributor
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.contributor = null;

                /**
                 * Thread completed.
                 * @member {boolean} completed
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.completed = false;

                /**
                 * Thread deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Thread createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Thread updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 */
                Thread.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Thread message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.Thread
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Thread.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    if (message.baseThreadOwner != null && message.hasOwnProperty("baseThreadOwner"))
                        if (!$util.isString(message.baseThreadOwner))
                            return "baseThreadOwner: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    if (message.companys != null && message.hasOwnProperty("companys")) {
                        if (!Array.isArray(message.companys))
                            return "companys: array expected";
                        for (let i = 0; i < message.companys.length; ++i) {
                            let error = $root.sharelib.CompanyReference.verify(message.companys[i]);
                            if (error)
                                return "companys." + error;
                        }
                    }
                    if (message.threadType != null && message.hasOwnProperty("threadType")) {
                        let error = $root.sharelib.NameOption.verify(message.threadType);
                        if (error)
                            return "threadType." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.attachedPoint != null && message.hasOwnProperty("attachedPoint")) {
                        let error = $root.mtechnavi.api.forum.Thread.AttachedPoint.verify(message.attachedPoint);
                        if (error)
                            return "attachedPoint." + error;
                    }
                    if (message.associations != null && message.hasOwnProperty("associations")) {
                        if (!Array.isArray(message.associations))
                            return "associations: array expected";
                        for (let i = 0; i < message.associations.length; ++i)
                            if (!$util.isString(message.associations[i]))
                                return "associations: string[] expected";
                    }
                    if (message.contributor != null && message.hasOwnProperty("contributor")) {
                        let error = $root.mtechnavi.api.forum.Contributor.verify(message.contributor);
                        if (error)
                            return "contributor." + error;
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        if (typeof message.completed !== "boolean")
                            return "completed: boolean expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Thread message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.Thread
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.Thread} Thread
                 */
                Thread.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.Thread)
                        return object;
                    let message = new $root.mtechnavi.api.forum.Thread();
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    if (object.baseThreadOwner != null)
                        message.baseThreadOwner = String(object.baseThreadOwner);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.forum.Thread.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    if (object.companys) {
                        if (!Array.isArray(object.companys))
                            throw TypeError(".mtechnavi.api.forum.Thread.companys: array expected");
                        message.companys = [];
                        for (let i = 0; i < object.companys.length; ++i) {
                            if (typeof object.companys[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.Thread.companys: object expected");
                            message.companys[i] = $root.sharelib.CompanyReference.fromObject(object.companys[i]);
                        }
                    }
                    if (object.threadType != null) {
                        if (typeof object.threadType !== "object")
                            throw TypeError(".mtechnavi.api.forum.Thread.threadType: object expected");
                        message.threadType = $root.sharelib.NameOption.fromObject(object.threadType);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.forum.Thread.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.attachedPoint != null) {
                        if (typeof object.attachedPoint !== "object")
                            throw TypeError(".mtechnavi.api.forum.Thread.attachedPoint: object expected");
                        message.attachedPoint = $root.mtechnavi.api.forum.Thread.AttachedPoint.fromObject(object.attachedPoint);
                    }
                    if (object.associations) {
                        if (!Array.isArray(object.associations))
                            throw TypeError(".mtechnavi.api.forum.Thread.associations: array expected");
                        message.associations = [];
                        for (let i = 0; i < object.associations.length; ++i)
                            message.associations[i] = String(object.associations[i]);
                    }
                    if (object.contributor != null) {
                        if (typeof object.contributor !== "object")
                            throw TypeError(".mtechnavi.api.forum.Thread.contributor: object expected");
                        message.contributor = $root.mtechnavi.api.forum.Contributor.fromObject(object.contributor);
                    }
                    if (object.completed != null)
                        message.completed = Boolean(object.completed);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Thread message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.Thread
                 * @static
                 * @param {mtechnavi.api.forum.Thread} message Thread
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Thread.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.companyIds = [];
                        object.associations = [];
                        object.companys = [];
                    }
                    if (options.defaults) {
                        object.threadId = "";
                        object.baseThreadId = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        object.attachedPoint = null;
                        object.threadType = null;
                        object.baseThreadOwner = "";
                        object.completed = false;
                        object.contributor = null;
                    }
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    if (message.attachedPoint != null && message.hasOwnProperty("attachedPoint"))
                        object.attachedPoint = $root.mtechnavi.api.forum.Thread.AttachedPoint.toObject(message.attachedPoint, options);
                    if (message.associations && message.associations.length) {
                        object.associations = [];
                        for (let j = 0; j < message.associations.length; ++j)
                            object.associations[j] = message.associations[j];
                    }
                    if (message.threadType != null && message.hasOwnProperty("threadType"))
                        object.threadType = $root.sharelib.NameOption.toObject(message.threadType, options);
                    if (message.baseThreadOwner != null && message.hasOwnProperty("baseThreadOwner"))
                        object.baseThreadOwner = message.baseThreadOwner;
                    if (message.companys && message.companys.length) {
                        object.companys = [];
                        for (let j = 0; j < message.companys.length; ++j)
                            object.companys[j] = $root.sharelib.CompanyReference.toObject(message.companys[j], options);
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = message.completed;
                    if (message.contributor != null && message.hasOwnProperty("contributor"))
                        object.contributor = $root.mtechnavi.api.forum.Contributor.toObject(message.contributor, options);
                    return object;
                };

                /**
                 * Converts this Thread to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.Thread
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Thread.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Thread.AttachedPoint = (function() {

                    /**
                     * Properties of an AttachedPoint.
                     * @memberof mtechnavi.api.forum.Thread
                     * @interface IAttachedPoint
                     * @property {number|null} [x1] AttachedPoint x1
                     * @property {number|null} [y1] AttachedPoint y1
                     * @property {number|null} [x2] AttachedPoint x2
                     * @property {number|null} [y2] AttachedPoint y2
                     * @property {Object.<string,string>|null} [attributes] AttachedPoint attributes
                     */

                    /**
                     * Constructs a new AttachedPoint.
                     * @memberof mtechnavi.api.forum.Thread
                     * @classdesc Represents an AttachedPoint.
                     * @implements IAttachedPoint
                     * @constructor
                     * @param {mtechnavi.api.forum.Thread.IAttachedPoint=} [properties] Properties to set
                     */
                    function AttachedPoint(properties) {
                        this.attributes = {};
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AttachedPoint x1.
                     * @member {number} x1
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     */
                    AttachedPoint.prototype.x1 = 0;

                    /**
                     * AttachedPoint y1.
                     * @member {number} y1
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     */
                    AttachedPoint.prototype.y1 = 0;

                    /**
                     * AttachedPoint x2.
                     * @member {number} x2
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     */
                    AttachedPoint.prototype.x2 = 0;

                    /**
                     * AttachedPoint y2.
                     * @member {number} y2
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     */
                    AttachedPoint.prototype.y2 = 0;

                    /**
                     * AttachedPoint attributes.
                     * @member {Object.<string,string>} attributes
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     */
                    AttachedPoint.prototype.attributes = $util.emptyObject;

                    /**
                     * Verifies an AttachedPoint message.
                     * @function verify
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AttachedPoint.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.x1 != null && message.hasOwnProperty("x1"))
                            if (!$util.isInteger(message.x1))
                                return "x1: integer expected";
                        if (message.y1 != null && message.hasOwnProperty("y1"))
                            if (!$util.isInteger(message.y1))
                                return "y1: integer expected";
                        if (message.x2 != null && message.hasOwnProperty("x2"))
                            if (!$util.isInteger(message.x2))
                                return "x2: integer expected";
                        if (message.y2 != null && message.hasOwnProperty("y2"))
                            if (!$util.isInteger(message.y2))
                                return "y2: integer expected";
                        if (message.attributes != null && message.hasOwnProperty("attributes")) {
                            if (!$util.isObject(message.attributes))
                                return "attributes: object expected";
                            let key = Object.keys(message.attributes);
                            for (let i = 0; i < key.length; ++i)
                                if (!$util.isString(message.attributes[key[i]]))
                                    return "attributes: string{k:string} expected";
                        }
                        return null;
                    };

                    /**
                     * Creates an AttachedPoint message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.forum.Thread.AttachedPoint} AttachedPoint
                     */
                    AttachedPoint.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.forum.Thread.AttachedPoint)
                            return object;
                        let message = new $root.mtechnavi.api.forum.Thread.AttachedPoint();
                        if (object.x1 != null)
                            message.x1 = object.x1 | 0;
                        if (object.y1 != null)
                            message.y1 = object.y1 | 0;
                        if (object.x2 != null)
                            message.x2 = object.x2 | 0;
                        if (object.y2 != null)
                            message.y2 = object.y2 | 0;
                        if (object.attributes) {
                            if (typeof object.attributes !== "object")
                                throw TypeError(".mtechnavi.api.forum.Thread.AttachedPoint.attributes: object expected");
                            message.attributes = {};
                            for (let keys = Object.keys(object.attributes), i = 0; i < keys.length; ++i)
                                message.attributes[keys[i]] = String(object.attributes[keys[i]]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AttachedPoint message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @static
                     * @param {mtechnavi.api.forum.Thread.AttachedPoint} message AttachedPoint
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AttachedPoint.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.objects || options.defaults)
                            object.attributes = {};
                        if (options.defaults) {
                            object.x1 = 0;
                            object.y1 = 0;
                            object.x2 = 0;
                            object.y2 = 0;
                        }
                        if (message.x1 != null && message.hasOwnProperty("x1"))
                            object.x1 = message.x1;
                        if (message.y1 != null && message.hasOwnProperty("y1"))
                            object.y1 = message.y1;
                        if (message.x2 != null && message.hasOwnProperty("x2"))
                            object.x2 = message.x2;
                        if (message.y2 != null && message.hasOwnProperty("y2"))
                            object.y2 = message.y2;
                        let keys2;
                        if (message.attributes && (keys2 = Object.keys(message.attributes)).length) {
                            object.attributes = {};
                            for (let j = 0; j < keys2.length; ++j)
                                object.attributes[keys2[j]] = message.attributes[keys2[j]];
                        }
                        return object;
                    };

                    /**
                     * Converts this AttachedPoint to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.forum.Thread.AttachedPoint
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AttachedPoint.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AttachedPoint;
                })();

                return Thread;
            })();

            forum.Comment = (function() {

                /**
                 * Properties of a Comment.
                 * @memberof mtechnavi.api.forum
                 * @interface IComment
                 * @property {string|null} [commentId] Comment commentId
                 * @property {string|null} [threadId] Comment threadId
                 * @property {string|null} [text] Comment text
                 * @property {Long|null} [contributedAt] Comment contributedAt
                 * @property {string|null} [replyCommentId] Comment replyCommentId
                 * @property {mtechnavi.api.forum.IContributor|null} [contributor] Comment contributor
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Comment sharedProperties
                 * @property {Long|null} [deletedAt] Comment deletedAt
                 * @property {Long|null} [createdAt] Comment createdAt
                 * @property {Long|null} [updatedAt] Comment updatedAt
                 */

                /**
                 * Constructs a new Comment.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a Comment.
                 * @implements IComment
                 * @constructor
                 * @param {mtechnavi.api.forum.IComment=} [properties] Properties to set
                 */
                function Comment(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Comment commentId.
                 * @member {string} commentId
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.commentId = "";

                /**
                 * Comment threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.threadId = "";

                /**
                 * Comment text.
                 * @member {string} text
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.text = "";

                /**
                 * Comment contributedAt.
                 * @member {Long} contributedAt
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.contributedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Comment replyCommentId.
                 * @member {string} replyCommentId
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.replyCommentId = "";

                /**
                 * Comment contributor.
                 * @member {mtechnavi.api.forum.IContributor|null|undefined} contributor
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.contributor = null;

                /**
                 * Comment sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.sharedProperties = null;

                /**
                 * Comment deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Comment createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Comment updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 */
                Comment.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Comment message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.Comment
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Comment.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.commentId != null && message.hasOwnProperty("commentId"))
                        if (!$util.isString(message.commentId))
                            return "commentId: string expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.contributedAt != null && message.hasOwnProperty("contributedAt"))
                        if (!$util.isInteger(message.contributedAt) && !(message.contributedAt && $util.isInteger(message.contributedAt.low) && $util.isInteger(message.contributedAt.high)))
                            return "contributedAt: integer|Long expected";
                    if (message.replyCommentId != null && message.hasOwnProperty("replyCommentId"))
                        if (!$util.isString(message.replyCommentId))
                            return "replyCommentId: string expected";
                    if (message.contributor != null && message.hasOwnProperty("contributor")) {
                        let error = $root.mtechnavi.api.forum.Contributor.verify(message.contributor);
                        if (error)
                            return "contributor." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Comment message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.Comment
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.Comment} Comment
                 */
                Comment.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.Comment)
                        return object;
                    let message = new $root.mtechnavi.api.forum.Comment();
                    if (object.commentId != null)
                        message.commentId = String(object.commentId);
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    if (object.text != null)
                        message.text = String(object.text);
                    if (object.contributedAt != null)
                        if ($util.Long)
                            (message.contributedAt = $util.Long.fromValue(object.contributedAt)).unsigned = false;
                        else if (typeof object.contributedAt === "string")
                            message.contributedAt = parseInt(object.contributedAt, 10);
                        else if (typeof object.contributedAt === "number")
                            message.contributedAt = object.contributedAt;
                        else if (typeof object.contributedAt === "object")
                            message.contributedAt = new $util.LongBits(object.contributedAt.low >>> 0, object.contributedAt.high >>> 0).toNumber();
                    if (object.replyCommentId != null)
                        message.replyCommentId = String(object.replyCommentId);
                    if (object.contributor != null) {
                        if (typeof object.contributor !== "object")
                            throw TypeError(".mtechnavi.api.forum.Comment.contributor: object expected");
                        message.contributor = $root.mtechnavi.api.forum.Contributor.fromObject(object.contributor);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.forum.Comment.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Comment message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.Comment
                 * @static
                 * @param {mtechnavi.api.forum.Comment} message Comment
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Comment.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.commentId = "";
                        object.threadId = "";
                        object.text = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.contributedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.contributedAt = options.longs === String ? "0" : 0;
                        object.replyCommentId = "";
                        object.contributor = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.commentId != null && message.hasOwnProperty("commentId"))
                        object.commentId = message.commentId;
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.contributedAt != null && message.hasOwnProperty("contributedAt"))
                        if (typeof message.contributedAt === "number")
                            object.contributedAt = options.longs === String ? String(message.contributedAt) : message.contributedAt;
                        else
                            object.contributedAt = options.longs === String ? $util.Long.prototype.toString.call(message.contributedAt) : options.longs === Number ? new $util.LongBits(message.contributedAt.low >>> 0, message.contributedAt.high >>> 0).toNumber() : message.contributedAt;
                    if (message.replyCommentId != null && message.hasOwnProperty("replyCommentId"))
                        object.replyCommentId = message.replyCommentId;
                    if (message.contributor != null && message.hasOwnProperty("contributor"))
                        object.contributor = $root.mtechnavi.api.forum.Contributor.toObject(message.contributor, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this Comment to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.Comment
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Comment.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Comment;
            })();

            forum.Marker = (function() {

                /**
                 * Properties of a Marker.
                 * @memberof mtechnavi.api.forum
                 * @interface IMarker
                 * @property {string|null} [markerId] Marker markerId
                 * @property {Array.<sharelib.INameOption>|null} [markers] Marker markers
                 * @property {string|null} [urn] Marker urn
                 * @property {string|null} [baseThreadId] Marker baseThreadId
                 * @property {string|null} [threadId] Marker threadId
                 * @property {string|null} [userId] Marker userId
                 * @property {Long|null} [deletedAt] Marker deletedAt
                 * @property {Long|null} [createdAt] Marker createdAt
                 * @property {Long|null} [updatedAt] Marker updatedAt
                 */

                /**
                 * Constructs a new Marker.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a Marker.
                 * @implements IMarker
                 * @constructor
                 * @param {mtechnavi.api.forum.IMarker=} [properties] Properties to set
                 */
                function Marker(properties) {
                    this.markers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Marker markerId.
                 * @member {string} markerId
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.markerId = "";

                /**
                 * Marker markers.
                 * @member {Array.<sharelib.INameOption>} markers
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.markers = $util.emptyArray;

                /**
                 * Marker urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.urn = "";

                /**
                 * Marker baseThreadId.
                 * @member {string} baseThreadId
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.baseThreadId = "";

                /**
                 * Marker threadId.
                 * @member {string} threadId
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.threadId = "";

                /**
                 * Marker userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.userId = "";

                /**
                 * Marker deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Marker createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Marker updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 */
                Marker.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Marker message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.Marker
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Marker.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.markerId != null && message.hasOwnProperty("markerId"))
                        if (!$util.isString(message.markerId))
                            return "markerId: string expected";
                    if (message.markers != null && message.hasOwnProperty("markers")) {
                        if (!Array.isArray(message.markers))
                            return "markers: array expected";
                        for (let i = 0; i < message.markers.length; ++i) {
                            let error = $root.sharelib.NameOption.verify(message.markers[i]);
                            if (error)
                                return "markers." + error;
                        }
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        if (!$util.isString(message.baseThreadId))
                            return "baseThreadId: string expected";
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        if (!$util.isString(message.threadId))
                            return "threadId: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Marker message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.Marker
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.Marker} Marker
                 */
                Marker.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.Marker)
                        return object;
                    let message = new $root.mtechnavi.api.forum.Marker();
                    if (object.markerId != null)
                        message.markerId = String(object.markerId);
                    if (object.markers) {
                        if (!Array.isArray(object.markers))
                            throw TypeError(".mtechnavi.api.forum.Marker.markers: array expected");
                        message.markers = [];
                        for (let i = 0; i < object.markers.length; ++i) {
                            if (typeof object.markers[i] !== "object")
                                throw TypeError(".mtechnavi.api.forum.Marker.markers: object expected");
                            message.markers[i] = $root.sharelib.NameOption.fromObject(object.markers[i]);
                        }
                    }
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.baseThreadId != null)
                        message.baseThreadId = String(object.baseThreadId);
                    if (object.threadId != null)
                        message.threadId = String(object.threadId);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Marker message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.Marker
                 * @static
                 * @param {mtechnavi.api.forum.Marker} message Marker
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Marker.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.markers = [];
                    if (options.defaults) {
                        object.markerId = "";
                        object.urn = "";
                        object.baseThreadId = "";
                        object.threadId = "";
                        object.userId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.markerId != null && message.hasOwnProperty("markerId"))
                        object.markerId = message.markerId;
                    if (message.markers && message.markers.length) {
                        object.markers = [];
                        for (let j = 0; j < message.markers.length; ++j)
                            object.markers[j] = $root.sharelib.NameOption.toObject(message.markers[j], options);
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.baseThreadId != null && message.hasOwnProperty("baseThreadId"))
                        object.baseThreadId = message.baseThreadId;
                    if (message.threadId != null && message.hasOwnProperty("threadId"))
                        object.threadId = message.threadId;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this Marker to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.Marker
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Marker.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Marker;
            })();

            forum.ResourceReference = (function() {

                /**
                 * Properties of a ResourceReference.
                 * @memberof mtechnavi.api.forum
                 * @interface IResourceReference
                 * @property {string|null} [typeName] ResourceReference typeName
                 * @property {string|null} [resourceId] ResourceReference resourceId
                 */

                /**
                 * Constructs a new ResourceReference.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a ResourceReference.
                 * @implements IResourceReference
                 * @constructor
                 * @param {mtechnavi.api.forum.IResourceReference=} [properties] Properties to set
                 */
                function ResourceReference(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResourceReference typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @instance
                 */
                ResourceReference.prototype.typeName = "";

                /**
                 * ResourceReference resourceId.
                 * @member {string} resourceId
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @instance
                 */
                ResourceReference.prototype.resourceId = "";

                /**
                 * Verifies a ResourceReference message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResourceReference.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        if (!$util.isString(message.resourceId))
                            return "resourceId: string expected";
                    return null;
                };

                /**
                 * Creates a ResourceReference message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.ResourceReference} ResourceReference
                 */
                ResourceReference.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.ResourceReference)
                        return object;
                    let message = new $root.mtechnavi.api.forum.ResourceReference();
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.resourceId != null)
                        message.resourceId = String(object.resourceId);
                    return message;
                };

                /**
                 * Creates a plain object from a ResourceReference message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @static
                 * @param {mtechnavi.api.forum.ResourceReference} message ResourceReference
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResourceReference.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.typeName = "";
                        object.resourceId = "";
                    }
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.resourceId != null && message.hasOwnProperty("resourceId"))
                        object.resourceId = message.resourceId;
                    return object;
                };

                /**
                 * Converts this ResourceReference to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.ResourceReference
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResourceReference.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResourceReference;
            })();

            forum.Contributor = (function() {

                /**
                 * Properties of a Contributor.
                 * @memberof mtechnavi.api.forum
                 * @interface IContributor
                 * @property {sharelib.IEmbeddedCompany|null} [company] Contributor company
                 * @property {sharelib.IEmbeddedUser|null} [user] Contributor user
                 */

                /**
                 * Constructs a new Contributor.
                 * @memberof mtechnavi.api.forum
                 * @classdesc Represents a Contributor.
                 * @implements IContributor
                 * @constructor
                 * @param {mtechnavi.api.forum.IContributor=} [properties] Properties to set
                 */
                function Contributor(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Contributor company.
                 * @member {sharelib.IEmbeddedCompany|null|undefined} company
                 * @memberof mtechnavi.api.forum.Contributor
                 * @instance
                 */
                Contributor.prototype.company = null;

                /**
                 * Contributor user.
                 * @member {sharelib.IEmbeddedUser|null|undefined} user
                 * @memberof mtechnavi.api.forum.Contributor
                 * @instance
                 */
                Contributor.prototype.user = null;

                /**
                 * Verifies a Contributor message.
                 * @function verify
                 * @memberof mtechnavi.api.forum.Contributor
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Contributor.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.company != null && message.hasOwnProperty("company")) {
                        let error = $root.sharelib.EmbeddedCompany.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Contributor message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.forum.Contributor
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.forum.Contributor} Contributor
                 */
                Contributor.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.forum.Contributor)
                        return object;
                    let message = new $root.mtechnavi.api.forum.Contributor();
                    if (object.company != null) {
                        if (typeof object.company !== "object")
                            throw TypeError(".mtechnavi.api.forum.Contributor.company: object expected");
                        message.company = $root.sharelib.EmbeddedCompany.fromObject(object.company);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.forum.Contributor.user: object expected");
                        message.user = $root.sharelib.EmbeddedUser.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Contributor message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.forum.Contributor
                 * @static
                 * @param {mtechnavi.api.forum.Contributor} message Contributor
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Contributor.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.company = null;
                        object.user = null;
                    }
                    if (message.company != null && message.hasOwnProperty("company"))
                        object.company = $root.sharelib.EmbeddedCompany.toObject(message.company, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.sharelib.EmbeddedUser.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this Contributor to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.forum.Contributor
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Contributor.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Contributor;
            })();

            /**
             * MarkerRequestMode enum.
             * @name mtechnavi.api.forum.MarkerRequestMode
             * @enum {number}
             * @property {number} COMPLETE=0 COMPLETE value
             * @property {number} INCREMENTAL=1 INCREMENTAL value
             */
            forum.MarkerRequestMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "COMPLETE"] = 0;
                values[valuesById[1] = "INCREMENTAL"] = 1;
                return values;
            })();

            return forum;
        })();

        api.idp = (function() {

            /**
             * Namespace idp.
             * @memberof mtechnavi.api
             * @namespace
             */
            const idp = {};

            idp.CreateBrowserSessionRequest = (function() {

                /**
                 * Properties of a CreateBrowserSessionRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface ICreateBrowserSessionRequest
                 * @property {string|null} [idToken] CreateBrowserSessionRequest idToken
                 */

                /**
                 * Constructs a new CreateBrowserSessionRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a CreateBrowserSessionRequest.
                 * @implements ICreateBrowserSessionRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.ICreateBrowserSessionRequest=} [properties] Properties to set
                 */
                function CreateBrowserSessionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBrowserSessionRequest idToken.
                 * @member {string} idToken
                 * @memberof mtechnavi.api.idp.CreateBrowserSessionRequest
                 * @instance
                 */
                CreateBrowserSessionRequest.prototype.idToken = "";

                /**
                 * Verifies a CreateBrowserSessionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.CreateBrowserSessionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBrowserSessionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.idToken != null && message.hasOwnProperty("idToken"))
                        if (!$util.isString(message.idToken))
                            return "idToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBrowserSessionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.CreateBrowserSessionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.CreateBrowserSessionRequest} CreateBrowserSessionRequest
                 */
                CreateBrowserSessionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.CreateBrowserSessionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.CreateBrowserSessionRequest();
                    if (object.idToken != null)
                        message.idToken = String(object.idToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBrowserSessionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.CreateBrowserSessionRequest
                 * @static
                 * @param {mtechnavi.api.idp.CreateBrowserSessionRequest} message CreateBrowserSessionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBrowserSessionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.idToken = "";
                    if (message.idToken != null && message.hasOwnProperty("idToken"))
                        object.idToken = message.idToken;
                    return object;
                };

                /**
                 * Converts this CreateBrowserSessionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.CreateBrowserSessionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBrowserSessionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBrowserSessionRequest;
            })();

            idp.GetTenantConfigRequest = (function() {

                /**
                 * Properties of a GetTenantConfigRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetTenantConfigRequest
                 * @property {string|null} [domain] GetTenantConfigRequest domain
                 * @property {string|null} [internalTenantId] GetTenantConfigRequest internalTenantId
                 */

                /**
                 * Constructs a new GetTenantConfigRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetTenantConfigRequest.
                 * @implements IGetTenantConfigRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetTenantConfigRequest=} [properties] Properties to set
                 */
                function GetTenantConfigRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTenantConfigRequest domain.
                 * @member {string} domain
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @instance
                 */
                GetTenantConfigRequest.prototype.domain = "";

                /**
                 * GetTenantConfigRequest internalTenantId.
                 * @member {string} internalTenantId
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @instance
                 */
                GetTenantConfigRequest.prototype.internalTenantId = "";

                /**
                 * Verifies a GetTenantConfigRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTenantConfigRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        if (!$util.isString(message.domain))
                            return "domain: string expected";
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        if (!$util.isString(message.internalTenantId))
                            return "internalTenantId: string expected";
                    return null;
                };

                /**
                 * Creates a GetTenantConfigRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetTenantConfigRequest} GetTenantConfigRequest
                 */
                GetTenantConfigRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetTenantConfigRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetTenantConfigRequest();
                    if (object.domain != null)
                        message.domain = String(object.domain);
                    if (object.internalTenantId != null)
                        message.internalTenantId = String(object.internalTenantId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTenantConfigRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetTenantConfigRequest} message GetTenantConfigRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTenantConfigRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.domain = "";
                        object.internalTenantId = "";
                    }
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        object.domain = message.domain;
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        object.internalTenantId = message.internalTenantId;
                    return object;
                };

                /**
                 * Converts this GetTenantConfigRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetTenantConfigRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTenantConfigRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTenantConfigRequest;
            })();

            idp.SingleSignOnConfig = (function() {

                /**
                 * Properties of a SingleSignOnConfig.
                 * @memberof mtechnavi.api.idp
                 * @interface ISingleSignOnConfig
                 * @property {boolean|null} [enabled] SingleSignOnConfig enabled
                 * @property {string|null} [allowedDomain] SingleSignOnConfig allowedDomain
                 */

                /**
                 * Constructs a new SingleSignOnConfig.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a SingleSignOnConfig.
                 * @implements ISingleSignOnConfig
                 * @constructor
                 * @param {mtechnavi.api.idp.ISingleSignOnConfig=} [properties] Properties to set
                 */
                function SingleSignOnConfig(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SingleSignOnConfig enabled.
                 * @member {boolean} enabled
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @instance
                 */
                SingleSignOnConfig.prototype.enabled = false;

                /**
                 * SingleSignOnConfig allowedDomain.
                 * @member {string} allowedDomain
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @instance
                 */
                SingleSignOnConfig.prototype.allowedDomain = "";

                /**
                 * Verifies a SingleSignOnConfig message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SingleSignOnConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.allowedDomain != null && message.hasOwnProperty("allowedDomain"))
                        if (!$util.isString(message.allowedDomain))
                            return "allowedDomain: string expected";
                    return null;
                };

                /**
                 * Creates a SingleSignOnConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.SingleSignOnConfig} SingleSignOnConfig
                 */
                SingleSignOnConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.SingleSignOnConfig)
                        return object;
                    let message = new $root.mtechnavi.api.idp.SingleSignOnConfig();
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.allowedDomain != null)
                        message.allowedDomain = String(object.allowedDomain);
                    return message;
                };

                /**
                 * Creates a plain object from a SingleSignOnConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @static
                 * @param {mtechnavi.api.idp.SingleSignOnConfig} message SingleSignOnConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SingleSignOnConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.enabled = false;
                        object.allowedDomain = "";
                    }
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.allowedDomain != null && message.hasOwnProperty("allowedDomain"))
                        object.allowedDomain = message.allowedDomain;
                    return object;
                };

                /**
                 * Converts this SingleSignOnConfig to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.SingleSignOnConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SingleSignOnConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SingleSignOnConfig;
            })();

            idp.TenantConfig = (function() {

                /**
                 * Properties of a TenantConfig.
                 * @memberof mtechnavi.api.idp
                 * @interface ITenantConfig
                 * @property {string|null} [domain] TenantConfig domain
                 * @property {mtechnavi.api.idp.ISingleSignOnConfig|null} [singleSignOn] TenantConfig singleSignOn
                 * @property {mtechnavi.api.idp.ISamlProviderSetting|null} [samlProviderSetting] TenantConfig samlProviderSetting
                 * @property {string|null} [internalApiKey] TenantConfig internalApiKey
                 * @property {string|null} [internalAuthDomain] TenantConfig internalAuthDomain
                 * @property {string|null} [internalTenantId] TenantConfig internalTenantId
                 */

                /**
                 * Constructs a new TenantConfig.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a TenantConfig.
                 * @implements ITenantConfig
                 * @constructor
                 * @param {mtechnavi.api.idp.ITenantConfig=} [properties] Properties to set
                 */
                function TenantConfig(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantConfig domain.
                 * @member {string} domain
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.domain = "";

                /**
                 * TenantConfig singleSignOn.
                 * @member {mtechnavi.api.idp.ISingleSignOnConfig|null|undefined} singleSignOn
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.singleSignOn = null;

                /**
                 * TenantConfig samlProviderSetting.
                 * @member {mtechnavi.api.idp.ISamlProviderSetting|null|undefined} samlProviderSetting
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.samlProviderSetting = null;

                /**
                 * TenantConfig internalApiKey.
                 * @member {string} internalApiKey
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.internalApiKey = "";

                /**
                 * TenantConfig internalAuthDomain.
                 * @member {string} internalAuthDomain
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.internalAuthDomain = "";

                /**
                 * TenantConfig internalTenantId.
                 * @member {string} internalTenantId
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 */
                TenantConfig.prototype.internalTenantId = "";

                /**
                 * Verifies a TenantConfig message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantConfig.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        if (!$util.isString(message.domain))
                            return "domain: string expected";
                    if (message.singleSignOn != null && message.hasOwnProperty("singleSignOn")) {
                        let error = $root.mtechnavi.api.idp.SingleSignOnConfig.verify(message.singleSignOn);
                        if (error)
                            return "singleSignOn." + error;
                    }
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting")) {
                        let error = $root.mtechnavi.api.idp.SamlProviderSetting.verify(message.samlProviderSetting);
                        if (error)
                            return "samlProviderSetting." + error;
                    }
                    if (message.internalApiKey != null && message.hasOwnProperty("internalApiKey"))
                        if (!$util.isString(message.internalApiKey))
                            return "internalApiKey: string expected";
                    if (message.internalAuthDomain != null && message.hasOwnProperty("internalAuthDomain"))
                        if (!$util.isString(message.internalAuthDomain))
                            return "internalAuthDomain: string expected";
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        if (!$util.isString(message.internalTenantId))
                            return "internalTenantId: string expected";
                    return null;
                };

                /**
                 * Creates a TenantConfig message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.TenantConfig} TenantConfig
                 */
                TenantConfig.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.TenantConfig)
                        return object;
                    let message = new $root.mtechnavi.api.idp.TenantConfig();
                    if (object.domain != null)
                        message.domain = String(object.domain);
                    if (object.singleSignOn != null) {
                        if (typeof object.singleSignOn !== "object")
                            throw TypeError(".mtechnavi.api.idp.TenantConfig.singleSignOn: object expected");
                        message.singleSignOn = $root.mtechnavi.api.idp.SingleSignOnConfig.fromObject(object.singleSignOn);
                    }
                    if (object.samlProviderSetting != null) {
                        if (typeof object.samlProviderSetting !== "object")
                            throw TypeError(".mtechnavi.api.idp.TenantConfig.samlProviderSetting: object expected");
                        message.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.fromObject(object.samlProviderSetting);
                    }
                    if (object.internalApiKey != null)
                        message.internalApiKey = String(object.internalApiKey);
                    if (object.internalAuthDomain != null)
                        message.internalAuthDomain = String(object.internalAuthDomain);
                    if (object.internalTenantId != null)
                        message.internalTenantId = String(object.internalTenantId);
                    return message;
                };

                /**
                 * Creates a plain object from a TenantConfig message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @static
                 * @param {mtechnavi.api.idp.TenantConfig} message TenantConfig
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantConfig.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.domain = "";
                        object.internalApiKey = "";
                        object.internalAuthDomain = "";
                        object.internalTenantId = "";
                        object.singleSignOn = null;
                        object.samlProviderSetting = null;
                    }
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        object.domain = message.domain;
                    if (message.internalApiKey != null && message.hasOwnProperty("internalApiKey"))
                        object.internalApiKey = message.internalApiKey;
                    if (message.internalAuthDomain != null && message.hasOwnProperty("internalAuthDomain"))
                        object.internalAuthDomain = message.internalAuthDomain;
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        object.internalTenantId = message.internalTenantId;
                    if (message.singleSignOn != null && message.hasOwnProperty("singleSignOn"))
                        object.singleSignOn = $root.mtechnavi.api.idp.SingleSignOnConfig.toObject(message.singleSignOn, options);
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting"))
                        object.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.toObject(message.samlProviderSetting, options);
                    return object;
                };

                /**
                 * Converts this TenantConfig to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.TenantConfig
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantConfig.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantConfig;
            })();

            idp.VerifyUserResponse = (function() {

                /**
                 * Properties of a VerifyUserResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IVerifyUserResponse
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] VerifyUserResponse tenant
                 * @property {mtechnavi.api.idp.IUser|null} [user] VerifyUserResponse user
                 * @property {Array.<mtechnavi.api.idp.IUserGroup>|null} [groups] VerifyUserResponse groups
                 * @property {Array.<mtechnavi.api.idp.IRole>|null} [roles] VerifyUserResponse roles
                 */

                /**
                 * Constructs a new VerifyUserResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a VerifyUserResponse.
                 * @implements IVerifyUserResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IVerifyUserResponse=} [properties] Properties to set
                 */
                function VerifyUserResponse(properties) {
                    this.groups = [];
                    this.roles = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyUserResponse tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @instance
                 */
                VerifyUserResponse.prototype.tenant = null;

                /**
                 * VerifyUserResponse user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @instance
                 */
                VerifyUserResponse.prototype.user = null;

                /**
                 * VerifyUserResponse groups.
                 * @member {Array.<mtechnavi.api.idp.IUserGroup>} groups
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @instance
                 */
                VerifyUserResponse.prototype.groups = $util.emptyArray;

                /**
                 * VerifyUserResponse roles.
                 * @member {Array.<mtechnavi.api.idp.IRole>} roles
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @instance
                 */
                VerifyUserResponse.prototype.roles = $util.emptyArray;

                /**
                 * Verifies a VerifyUserResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.groups != null && message.hasOwnProperty("groups")) {
                        if (!Array.isArray(message.groups))
                            return "groups: array expected";
                        for (let i = 0; i < message.groups.length; ++i) {
                            let error = $root.mtechnavi.api.idp.UserGroup.verify(message.groups[i]);
                            if (error)
                                return "groups." + error;
                        }
                    }
                    if (message.roles != null && message.hasOwnProperty("roles")) {
                        if (!Array.isArray(message.roles))
                            return "roles: array expected";
                        for (let i = 0; i < message.roles.length; ++i) {
                            let error = $root.mtechnavi.api.idp.Role.verify(message.roles[i]);
                            if (error)
                                return "roles." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a VerifyUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.VerifyUserResponse} VerifyUserResponse
                 */
                VerifyUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.VerifyUserResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.VerifyUserResponse();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    if (object.groups) {
                        if (!Array.isArray(object.groups))
                            throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.groups: array expected");
                        message.groups = [];
                        for (let i = 0; i < object.groups.length; ++i) {
                            if (typeof object.groups[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.groups: object expected");
                            message.groups[i] = $root.mtechnavi.api.idp.UserGroup.fromObject(object.groups[i]);
                        }
                    }
                    if (object.roles) {
                        if (!Array.isArray(object.roles))
                            throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.roles: array expected");
                        message.roles = [];
                        for (let i = 0; i < object.roles.length; ++i) {
                            if (typeof object.roles[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.VerifyUserResponse.roles: object expected");
                            message.roles[i] = $root.mtechnavi.api.idp.Role.fromObject(object.roles[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @static
                 * @param {mtechnavi.api.idp.VerifyUserResponse} message VerifyUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.groups = [];
                        object.roles = [];
                    }
                    if (options.defaults) {
                        object.tenant = null;
                        object.user = null;
                    }
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    if (message.groups && message.groups.length) {
                        object.groups = [];
                        for (let j = 0; j < message.groups.length; ++j)
                            object.groups[j] = $root.mtechnavi.api.idp.UserGroup.toObject(message.groups[j], options);
                    }
                    if (message.roles && message.roles.length) {
                        object.roles = [];
                        for (let j = 0; j < message.roles.length; ++j)
                            object.roles[j] = $root.mtechnavi.api.idp.Role.toObject(message.roles[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this VerifyUserResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.VerifyUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyUserResponse;
            })();

            idp.User = (function() {

                /**
                 * Properties of a User.
                 * @memberof mtechnavi.api.idp
                 * @interface IUser
                 * @property {string|null} [userId] User userId
                 * @property {string|null} [email] User email
                 * @property {boolean|null} [emailVerified] User emailVerified
                 * @property {string|null} [password] User password
                 * @property {string|null} [displayName] User displayName
                 * @property {Long|null} [lastLoginAt] User lastLoginAt
                 * @property {Array.<string>|null} [userGroupIds] User userGroupIds
                 * @property {boolean|null} [disabled] User disabled
                 * @property {Long|null} [deletedAt] User deletedAt
                 * @property {Long|null} [createdAt] User createdAt
                 * @property {Long|null} [updatedAt] User updatedAt
                 */

                /**
                 * Constructs a new User.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a User.
                 * @implements IUser
                 * @constructor
                 * @param {mtechnavi.api.idp.IUser=} [properties] Properties to set
                 */
                function User(properties) {
                    this.userGroupIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * User userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.userId = "";

                /**
                 * User email.
                 * @member {string} email
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.email = "";

                /**
                 * User emailVerified.
                 * @member {boolean} emailVerified
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.emailVerified = false;

                /**
                 * User password.
                 * @member {string} password
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.password = "";

                /**
                 * User displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.displayName = "";

                /**
                 * User lastLoginAt.
                 * @member {Long} lastLoginAt
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.lastLoginAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * User userGroupIds.
                 * @member {Array.<string>} userGroupIds
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.userGroupIds = $util.emptyArray;

                /**
                 * User disabled.
                 * @member {boolean} disabled
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.disabled = false;

                /**
                 * User deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * User createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * User updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 */
                User.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a User message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.User
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                User.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.emailVerified != null && message.hasOwnProperty("emailVerified"))
                        if (typeof message.emailVerified !== "boolean")
                            return "emailVerified: boolean expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.lastLoginAt != null && message.hasOwnProperty("lastLoginAt"))
                        if (!$util.isInteger(message.lastLoginAt) && !(message.lastLoginAt && $util.isInteger(message.lastLoginAt.low) && $util.isInteger(message.lastLoginAt.high)))
                            return "lastLoginAt: integer|Long expected";
                    if (message.userGroupIds != null && message.hasOwnProperty("userGroupIds")) {
                        if (!Array.isArray(message.userGroupIds))
                            return "userGroupIds: array expected";
                        for (let i = 0; i < message.userGroupIds.length; ++i)
                            if (!$util.isString(message.userGroupIds[i]))
                                return "userGroupIds: string[] expected";
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a User message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.User
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.User} User
                 */
                User.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.User)
                        return object;
                    let message = new $root.mtechnavi.api.idp.User();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.emailVerified != null)
                        message.emailVerified = Boolean(object.emailVerified);
                    if (object.password != null)
                        message.password = String(object.password);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.lastLoginAt != null)
                        if ($util.Long)
                            (message.lastLoginAt = $util.Long.fromValue(object.lastLoginAt)).unsigned = false;
                        else if (typeof object.lastLoginAt === "string")
                            message.lastLoginAt = parseInt(object.lastLoginAt, 10);
                        else if (typeof object.lastLoginAt === "number")
                            message.lastLoginAt = object.lastLoginAt;
                        else if (typeof object.lastLoginAt === "object")
                            message.lastLoginAt = new $util.LongBits(object.lastLoginAt.low >>> 0, object.lastLoginAt.high >>> 0).toNumber();
                    if (object.userGroupIds) {
                        if (!Array.isArray(object.userGroupIds))
                            throw TypeError(".mtechnavi.api.idp.User.userGroupIds: array expected");
                        message.userGroupIds = [];
                        for (let i = 0; i < object.userGroupIds.length; ++i)
                            message.userGroupIds[i] = String(object.userGroupIds[i]);
                    }
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a User message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.User
                 * @static
                 * @param {mtechnavi.api.idp.User} message User
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                User.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.userGroupIds = [];
                    if (options.defaults) {
                        object.userId = "";
                        object.email = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.password = "";
                        object.emailVerified = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.disabled = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.lastLoginAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.lastLoginAt = options.longs === String ? "0" : 0;
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.userGroupIds && message.userGroupIds.length) {
                        object.userGroupIds = [];
                        for (let j = 0; j < message.userGroupIds.length; ++j)
                            object.userGroupIds[j] = message.userGroupIds[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    if (message.emailVerified != null && message.hasOwnProperty("emailVerified"))
                        object.emailVerified = message.emailVerified;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.lastLoginAt != null && message.hasOwnProperty("lastLoginAt"))
                        if (typeof message.lastLoginAt === "number")
                            object.lastLoginAt = options.longs === String ? String(message.lastLoginAt) : message.lastLoginAt;
                        else
                            object.lastLoginAt = options.longs === String ? $util.Long.prototype.toString.call(message.lastLoginAt) : options.longs === Number ? new $util.LongBits(message.lastLoginAt.low >>> 0, message.lastLoginAt.high >>> 0).toNumber() : message.lastLoginAt;
                    return object;
                };

                /**
                 * Converts this User to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.User
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                User.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return User;
            })();

            idp.UserGroup = (function() {

                /**
                 * Properties of a UserGroup.
                 * @memberof mtechnavi.api.idp
                 * @interface IUserGroup
                 * @property {string|null} [userGroupId] UserGroup userGroupId
                 * @property {string|null} [userGroupCode] UserGroup userGroupCode
                 * @property {string|null} [displayName] UserGroup displayName
                 * @property {Array.<string>|null} [roleNames] UserGroup roleNames
                 * @property {string|null} [schemaAccessControlName] UserGroup schemaAccessControlName
                 * @property {Long|null} [deletedAt] UserGroup deletedAt
                 * @property {Long|null} [createdAt] UserGroup createdAt
                 * @property {Long|null} [updatedAt] UserGroup updatedAt
                 */

                /**
                 * Constructs a new UserGroup.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a UserGroup.
                 * @implements IUserGroup
                 * @constructor
                 * @param {mtechnavi.api.idp.IUserGroup=} [properties] Properties to set
                 */
                function UserGroup(properties) {
                    this.roleNames = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserGroup userGroupId.
                 * @member {string} userGroupId
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.userGroupId = "";

                /**
                 * UserGroup userGroupCode.
                 * @member {string} userGroupCode
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.userGroupCode = "";

                /**
                 * UserGroup displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.displayName = "";

                /**
                 * UserGroup roleNames.
                 * @member {Array.<string>} roleNames
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.roleNames = $util.emptyArray;

                /**
                 * UserGroup schemaAccessControlName.
                 * @member {string} schemaAccessControlName
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.schemaAccessControlName = "";

                /**
                 * UserGroup deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserGroup createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserGroup updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 */
                UserGroup.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a UserGroup message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserGroup.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupId != null && message.hasOwnProperty("userGroupId"))
                        if (!$util.isString(message.userGroupId))
                            return "userGroupId: string expected";
                    if (message.userGroupCode != null && message.hasOwnProperty("userGroupCode"))
                        if (!$util.isString(message.userGroupCode))
                            return "userGroupCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.roleNames != null && message.hasOwnProperty("roleNames")) {
                        if (!Array.isArray(message.roleNames))
                            return "roleNames: array expected";
                        for (let i = 0; i < message.roleNames.length; ++i)
                            if (!$util.isString(message.roleNames[i]))
                                return "roleNames: string[] expected";
                    }
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        if (!$util.isString(message.schemaAccessControlName))
                            return "schemaAccessControlName: string expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a UserGroup message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.UserGroup} UserGroup
                 */
                UserGroup.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.UserGroup)
                        return object;
                    let message = new $root.mtechnavi.api.idp.UserGroup();
                    if (object.userGroupId != null)
                        message.userGroupId = String(object.userGroupId);
                    if (object.userGroupCode != null)
                        message.userGroupCode = String(object.userGroupCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.roleNames) {
                        if (!Array.isArray(object.roleNames))
                            throw TypeError(".mtechnavi.api.idp.UserGroup.roleNames: array expected");
                        message.roleNames = [];
                        for (let i = 0; i < object.roleNames.length; ++i)
                            message.roleNames[i] = String(object.roleNames[i]);
                    }
                    if (object.schemaAccessControlName != null)
                        message.schemaAccessControlName = String(object.schemaAccessControlName);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a UserGroup message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @static
                 * @param {mtechnavi.api.idp.UserGroup} message UserGroup
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserGroup.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.roleNames = [];
                    if (options.defaults) {
                        object.userGroupId = "";
                        object.userGroupCode = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.schemaAccessControlName = "";
                    }
                    if (message.userGroupId != null && message.hasOwnProperty("userGroupId"))
                        object.userGroupId = message.userGroupId;
                    if (message.userGroupCode != null && message.hasOwnProperty("userGroupCode"))
                        object.userGroupCode = message.userGroupCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.roleNames && message.roleNames.length) {
                        object.roleNames = [];
                        for (let j = 0; j < message.roleNames.length; ++j)
                            object.roleNames[j] = message.roleNames[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        object.schemaAccessControlName = message.schemaAccessControlName;
                    return object;
                };

                /**
                 * Converts this UserGroup to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.UserGroup
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserGroup.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserGroup;
            })();

            idp.Role = (function() {

                /**
                 * Properties of a Role.
                 * @memberof mtechnavi.api.idp
                 * @interface IRole
                 * @property {string|null} [roleName] Role roleName
                 * @property {string|null} [displayName] Role displayName
                 * @property {Array.<string>|null} [permissions] Role permissions
                 */

                /**
                 * Constructs a new Role.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a Role.
                 * @implements IRole
                 * @constructor
                 * @param {mtechnavi.api.idp.IRole=} [properties] Properties to set
                 */
                function Role(properties) {
                    this.permissions = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Role roleName.
                 * @member {string} roleName
                 * @memberof mtechnavi.api.idp.Role
                 * @instance
                 */
                Role.prototype.roleName = "";

                /**
                 * Role displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.idp.Role
                 * @instance
                 */
                Role.prototype.displayName = "";

                /**
                 * Role permissions.
                 * @member {Array.<string>} permissions
                 * @memberof mtechnavi.api.idp.Role
                 * @instance
                 */
                Role.prototype.permissions = $util.emptyArray;

                /**
                 * Verifies a Role message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.Role
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Role.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        if (!$util.isString(message.roleName))
                            return "roleName: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.permissions != null && message.hasOwnProperty("permissions")) {
                        if (!Array.isArray(message.permissions))
                            return "permissions: array expected";
                        for (let i = 0; i < message.permissions.length; ++i)
                            if (!$util.isString(message.permissions[i]))
                                return "permissions: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Role message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.Role
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.Role} Role
                 */
                Role.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.Role)
                        return object;
                    let message = new $root.mtechnavi.api.idp.Role();
                    if (object.roleName != null)
                        message.roleName = String(object.roleName);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.permissions) {
                        if (!Array.isArray(object.permissions))
                            throw TypeError(".mtechnavi.api.idp.Role.permissions: array expected");
                        message.permissions = [];
                        for (let i = 0; i < object.permissions.length; ++i)
                            message.permissions[i] = String(object.permissions[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Role message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.Role
                 * @static
                 * @param {mtechnavi.api.idp.Role} message Role
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Role.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.permissions = [];
                    if (options.defaults) {
                        object.roleName = "";
                        object.displayName = "";
                    }
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        object.roleName = message.roleName;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.permissions && message.permissions.length) {
                        object.permissions = [];
                        for (let j = 0; j < message.permissions.length; ++j)
                            object.permissions[j] = message.permissions[j];
                    }
                    return object;
                };

                /**
                 * Converts this Role to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.Role
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Role.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Role;
            })();

            idp.SchemaAccessControl = (function() {

                /**
                 * Properties of a SchemaAccessControl.
                 * @memberof mtechnavi.api.idp
                 * @interface ISchemaAccessControl
                 * @property {string|null} [schemaAccessControlName] SchemaAccessControl schemaAccessControlName
                 * @property {Object.<string,mtechnavi.api.idp.IPropertyAccessControl>|null} [accessControls] SchemaAccessControl accessControls
                 */

                /**
                 * Constructs a new SchemaAccessControl.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a SchemaAccessControl.
                 * @implements ISchemaAccessControl
                 * @constructor
                 * @param {mtechnavi.api.idp.ISchemaAccessControl=} [properties] Properties to set
                 */
                function SchemaAccessControl(properties) {
                    this.accessControls = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SchemaAccessControl schemaAccessControlName.
                 * @member {string} schemaAccessControlName
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @instance
                 */
                SchemaAccessControl.prototype.schemaAccessControlName = "";

                /**
                 * SchemaAccessControl accessControls.
                 * @member {Object.<string,mtechnavi.api.idp.IPropertyAccessControl>} accessControls
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @instance
                 */
                SchemaAccessControl.prototype.accessControls = $util.emptyObject;

                /**
                 * Verifies a SchemaAccessControl message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SchemaAccessControl.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        if (!$util.isString(message.schemaAccessControlName))
                            return "schemaAccessControlName: string expected";
                    if (message.accessControls != null && message.hasOwnProperty("accessControls")) {
                        if (!$util.isObject(message.accessControls))
                            return "accessControls: object expected";
                        let key = Object.keys(message.accessControls);
                        for (let i = 0; i < key.length; ++i) {
                            let error = $root.mtechnavi.api.idp.PropertyAccessControl.verify(message.accessControls[key[i]]);
                            if (error)
                                return "accessControls." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SchemaAccessControl message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.SchemaAccessControl} SchemaAccessControl
                 */
                SchemaAccessControl.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.SchemaAccessControl)
                        return object;
                    let message = new $root.mtechnavi.api.idp.SchemaAccessControl();
                    if (object.schemaAccessControlName != null)
                        message.schemaAccessControlName = String(object.schemaAccessControlName);
                    if (object.accessControls) {
                        if (typeof object.accessControls !== "object")
                            throw TypeError(".mtechnavi.api.idp.SchemaAccessControl.accessControls: object expected");
                        message.accessControls = {};
                        for (let keys = Object.keys(object.accessControls), i = 0; i < keys.length; ++i) {
                            if (typeof object.accessControls[keys[i]] !== "object")
                                throw TypeError(".mtechnavi.api.idp.SchemaAccessControl.accessControls: object expected");
                            message.accessControls[keys[i]] = $root.mtechnavi.api.idp.PropertyAccessControl.fromObject(object.accessControls[keys[i]]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SchemaAccessControl message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @static
                 * @param {mtechnavi.api.idp.SchemaAccessControl} message SchemaAccessControl
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SchemaAccessControl.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.accessControls = {};
                    if (options.defaults)
                        object.schemaAccessControlName = "";
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        object.schemaAccessControlName = message.schemaAccessControlName;
                    let keys2;
                    if (message.accessControls && (keys2 = Object.keys(message.accessControls)).length) {
                        object.accessControls = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.accessControls[keys2[j]] = $root.mtechnavi.api.idp.PropertyAccessControl.toObject(message.accessControls[keys2[j]], options);
                    }
                    return object;
                };

                /**
                 * Converts this SchemaAccessControl to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.SchemaAccessControl
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SchemaAccessControl.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SchemaAccessControl;
            })();

            idp.PropertyAccessControl = (function() {

                /**
                 * Properties of a PropertyAccessControl.
                 * @memberof mtechnavi.api.idp
                 * @interface IPropertyAccessControl
                 * @property {Array.<string>|null} [allow] PropertyAccessControl allow
                 * @property {Array.<string>|null} [deny] PropertyAccessControl deny
                 */

                /**
                 * Constructs a new PropertyAccessControl.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a PropertyAccessControl.
                 * @implements IPropertyAccessControl
                 * @constructor
                 * @param {mtechnavi.api.idp.IPropertyAccessControl=} [properties] Properties to set
                 */
                function PropertyAccessControl(properties) {
                    this.allow = [];
                    this.deny = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PropertyAccessControl allow.
                 * @member {Array.<string>} allow
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @instance
                 */
                PropertyAccessControl.prototype.allow = $util.emptyArray;

                /**
                 * PropertyAccessControl deny.
                 * @member {Array.<string>} deny
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @instance
                 */
                PropertyAccessControl.prototype.deny = $util.emptyArray;

                /**
                 * Verifies a PropertyAccessControl message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PropertyAccessControl.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.allow != null && message.hasOwnProperty("allow")) {
                        if (!Array.isArray(message.allow))
                            return "allow: array expected";
                        for (let i = 0; i < message.allow.length; ++i)
                            if (!$util.isString(message.allow[i]))
                                return "allow: string[] expected";
                    }
                    if (message.deny != null && message.hasOwnProperty("deny")) {
                        if (!Array.isArray(message.deny))
                            return "deny: array expected";
                        for (let i = 0; i < message.deny.length; ++i)
                            if (!$util.isString(message.deny[i]))
                                return "deny: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a PropertyAccessControl message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.PropertyAccessControl} PropertyAccessControl
                 */
                PropertyAccessControl.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.PropertyAccessControl)
                        return object;
                    let message = new $root.mtechnavi.api.idp.PropertyAccessControl();
                    if (object.allow) {
                        if (!Array.isArray(object.allow))
                            throw TypeError(".mtechnavi.api.idp.PropertyAccessControl.allow: array expected");
                        message.allow = [];
                        for (let i = 0; i < object.allow.length; ++i)
                            message.allow[i] = String(object.allow[i]);
                    }
                    if (object.deny) {
                        if (!Array.isArray(object.deny))
                            throw TypeError(".mtechnavi.api.idp.PropertyAccessControl.deny: array expected");
                        message.deny = [];
                        for (let i = 0; i < object.deny.length; ++i)
                            message.deny[i] = String(object.deny[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PropertyAccessControl message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @static
                 * @param {mtechnavi.api.idp.PropertyAccessControl} message PropertyAccessControl
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PropertyAccessControl.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.allow = [];
                        object.deny = [];
                    }
                    if (message.allow && message.allow.length) {
                        object.allow = [];
                        for (let j = 0; j < message.allow.length; ++j)
                            object.allow[j] = message.allow[j];
                    }
                    if (message.deny && message.deny.length) {
                        object.deny = [];
                        for (let j = 0; j < message.deny.length; ++j)
                            object.deny[j] = message.deny[j];
                    }
                    return object;
                };

                /**
                 * Converts this PropertyAccessControl to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.PropertyAccessControl
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PropertyAccessControl.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PropertyAccessControl;
            })();

            idp.Tenant = (function() {

                /**
                 * Properties of a Tenant.
                 * @memberof mtechnavi.api.idp
                 * @interface ITenant
                 * @property {string|null} [tenantId] Tenant tenantId
                 * @property {string|null} [tenantCode] Tenant tenantCode
                 * @property {string|null} [domain] Tenant domain
                 * @property {string|null} [displayName] Tenant displayName
                 * @property {mtechnavi.api.idp.ISingleSignOnConfig|null} [singleSignOn] Tenant singleSignOn
                 * @property {mtechnavi.api.idp.ISamlProviderSetting|null} [samlProviderSetting] Tenant samlProviderSetting
                 * @property {string|null} [internalTenantId] Tenant internalTenantId
                 * @property {boolean|null} [disabled] Tenant disabled
                 * @property {Long|null} [createdAt] Tenant createdAt
                 * @property {Long|null} [updatedAt] Tenant updatedAt
                 * @property {Long|null} [deletedAt] Tenant deletedAt
                 */

                /**
                 * Constructs a new Tenant.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a Tenant.
                 * @implements ITenant
                 * @constructor
                 * @param {mtechnavi.api.idp.ITenant=} [properties] Properties to set
                 */
                function Tenant(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Tenant tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.tenantId = "";

                /**
                 * Tenant tenantCode.
                 * @member {string} tenantCode
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.tenantCode = "";

                /**
                 * Tenant domain.
                 * @member {string} domain
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.domain = "";

                /**
                 * Tenant displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.displayName = "";

                /**
                 * Tenant singleSignOn.
                 * @member {mtechnavi.api.idp.ISingleSignOnConfig|null|undefined} singleSignOn
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.singleSignOn = null;

                /**
                 * Tenant samlProviderSetting.
                 * @member {mtechnavi.api.idp.ISamlProviderSetting|null|undefined} samlProviderSetting
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.samlProviderSetting = null;

                /**
                 * Tenant internalTenantId.
                 * @member {string} internalTenantId
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.internalTenantId = "";

                /**
                 * Tenant disabled.
                 * @member {boolean} disabled
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.disabled = false;

                /**
                 * Tenant createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Tenant updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Tenant deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 */
                Tenant.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Tenant message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.Tenant
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Tenant.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.tenantCode != null && message.hasOwnProperty("tenantCode"))
                        if (!$util.isString(message.tenantCode))
                            return "tenantCode: string expected";
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        if (!$util.isString(message.domain))
                            return "domain: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.singleSignOn != null && message.hasOwnProperty("singleSignOn")) {
                        let error = $root.mtechnavi.api.idp.SingleSignOnConfig.verify(message.singleSignOn);
                        if (error)
                            return "singleSignOn." + error;
                    }
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting")) {
                        let error = $root.mtechnavi.api.idp.SamlProviderSetting.verify(message.samlProviderSetting);
                        if (error)
                            return "samlProviderSetting." + error;
                    }
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        if (!$util.isString(message.internalTenantId))
                            return "internalTenantId: string expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Tenant message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.Tenant
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.Tenant} Tenant
                 */
                Tenant.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.Tenant)
                        return object;
                    let message = new $root.mtechnavi.api.idp.Tenant();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.tenantCode != null)
                        message.tenantCode = String(object.tenantCode);
                    if (object.domain != null)
                        message.domain = String(object.domain);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.singleSignOn != null) {
                        if (typeof object.singleSignOn !== "object")
                            throw TypeError(".mtechnavi.api.idp.Tenant.singleSignOn: object expected");
                        message.singleSignOn = $root.mtechnavi.api.idp.SingleSignOnConfig.fromObject(object.singleSignOn);
                    }
                    if (object.samlProviderSetting != null) {
                        if (typeof object.samlProviderSetting !== "object")
                            throw TypeError(".mtechnavi.api.idp.Tenant.samlProviderSetting: object expected");
                        message.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.fromObject(object.samlProviderSetting);
                    }
                    if (object.internalTenantId != null)
                        message.internalTenantId = String(object.internalTenantId);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Tenant message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.Tenant
                 * @static
                 * @param {mtechnavi.api.idp.Tenant} message Tenant
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Tenant.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantId = "";
                        object.tenantCode = "";
                        object.domain = "";
                        object.displayName = "";
                        object.internalTenantId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.singleSignOn = null;
                        object.disabled = false;
                        object.samlProviderSetting = null;
                    }
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.tenantCode != null && message.hasOwnProperty("tenantCode"))
                        object.tenantCode = message.tenantCode;
                    if (message.domain != null && message.hasOwnProperty("domain"))
                        object.domain = message.domain;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.internalTenantId != null && message.hasOwnProperty("internalTenantId"))
                        object.internalTenantId = message.internalTenantId;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.singleSignOn != null && message.hasOwnProperty("singleSignOn"))
                        object.singleSignOn = $root.mtechnavi.api.idp.SingleSignOnConfig.toObject(message.singleSignOn, options);
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting"))
                        object.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.toObject(message.samlProviderSetting, options);
                    return object;
                };

                /**
                 * Converts this Tenant to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.Tenant
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Tenant.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Tenant;
            })();

            idp.CreateTenantRequest = (function() {

                /**
                 * Properties of a CreateTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface ICreateTenantRequest
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] CreateTenantRequest tenant
                 * @property {mtechnavi.api.idp.IUser|null} [user] CreateTenantRequest user
                 */

                /**
                 * Constructs a new CreateTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a CreateTenantRequest.
                 * @implements ICreateTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.ICreateTenantRequest=} [properties] Properties to set
                 */
                function CreateTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateTenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @instance
                 */
                CreateTenantRequest.prototype.tenant = null;

                /**
                 * CreateTenantRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @instance
                 */
                CreateTenantRequest.prototype.user = null;

                /**
                 * Verifies a CreateTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.CreateTenantRequest} CreateTenantRequest
                 */
                CreateTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.CreateTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.CreateTenantRequest();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.CreateTenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.idp.CreateTenantRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.CreateTenantRequest} message CreateTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenant = null;
                        object.user = null;
                    }
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this CreateTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.CreateTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateTenantRequest;
            })();

            idp.GetTenantRequest = (function() {

                /**
                 * Properties of a GetTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetTenantRequest
                 * @property {string|null} [tenantId] GetTenantRequest tenantId
                 */

                /**
                 * Constructs a new GetTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetTenantRequest.
                 * @implements IGetTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetTenantRequest=} [properties] Properties to set
                 */
                function GetTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTenantRequest tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.idp.GetTenantRequest
                 * @instance
                 */
                GetTenantRequest.prototype.tenantId = "";

                /**
                 * Verifies a GetTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    return null;
                };

                /**
                 * Creates a GetTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetTenantRequest} GetTenantRequest
                 */
                GetTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetTenantRequest();
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetTenantRequest} message GetTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantId = "";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    return object;
                };

                /**
                 * Converts this GetTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTenantRequest;
            })();

            idp.ListTenantsRequest = (function() {

                /**
                 * Properties of a ListTenantsRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListTenantsRequest
                 */

                /**
                 * Constructs a new ListTenantsRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListTenantsRequest.
                 * @implements IListTenantsRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListTenantsRequest=} [properties] Properties to set
                 */
                function ListTenantsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListTenantsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListTenantsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListTenantsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListTenantsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListTenantsRequest} ListTenantsRequest
                 */
                ListTenantsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListTenantsRequest)
                        return object;
                    return new $root.mtechnavi.api.idp.ListTenantsRequest();
                };

                /**
                 * Creates a plain object from a ListTenantsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListTenantsRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListTenantsRequest} message ListTenantsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListTenantsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListTenantsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantsRequest;
            })();

            idp.ListTenantsResponse = (function() {

                /**
                 * Properties of a ListTenantsResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListTenantsResponse
                 * @property {Long|null} [total] ListTenantsResponse total
                 * @property {Array.<mtechnavi.api.idp.ITenant>|null} [items] ListTenantsResponse items
                 */

                /**
                 * Constructs a new ListTenantsResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListTenantsResponse.
                 * @implements IListTenantsResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListTenantsResponse=} [properties] Properties to set
                 */
                function ListTenantsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListTenantsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @instance
                 */
                ListTenantsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListTenantsResponse items.
                 * @member {Array.<mtechnavi.api.idp.ITenant>} items
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @instance
                 */
                ListTenantsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListTenantsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListTenantsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.Tenant.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListTenantsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListTenantsResponse} ListTenantsResponse
                 */
                ListTenantsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListTenantsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListTenantsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListTenantsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListTenantsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.Tenant.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListTenantsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListTenantsResponse} message ListTenantsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListTenantsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.Tenant.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListTenantsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListTenantsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListTenantsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListTenantsResponse;
            })();

            idp.UpdateTenantRequest = (function() {

                /**
                 * Properties of an UpdateTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IUpdateTenantRequest
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] UpdateTenantRequest tenant
                 */

                /**
                 * Constructs a new UpdateTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents an UpdateTenantRequest.
                 * @implements IUpdateTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IUpdateTenantRequest=} [properties] Properties to set
                 */
                function UpdateTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateTenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.UpdateTenantRequest
                 * @instance
                 */
                UpdateTenantRequest.prototype.tenant = null;

                /**
                 * Verifies an UpdateTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.UpdateTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.UpdateTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.UpdateTenantRequest} UpdateTenantRequest
                 */
                UpdateTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.UpdateTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.UpdateTenantRequest();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.UpdateTenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.UpdateTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.UpdateTenantRequest} message UpdateTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenant = null;
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    return object;
                };

                /**
                 * Converts this UpdateTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.UpdateTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateTenantRequest;
            })();

            idp.DeleteTenantRequest = (function() {

                /**
                 * Properties of a DeleteTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IDeleteTenantRequest
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] DeleteTenantRequest tenant
                 */

                /**
                 * Constructs a new DeleteTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a DeleteTenantRequest.
                 * @implements IDeleteTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IDeleteTenantRequest=} [properties] Properties to set
                 */
                function DeleteTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteTenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.DeleteTenantRequest
                 * @instance
                 */
                DeleteTenantRequest.prototype.tenant = null;

                /**
                 * Verifies a DeleteTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.DeleteTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.DeleteTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.DeleteTenantRequest} DeleteTenantRequest
                 */
                DeleteTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.DeleteTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.DeleteTenantRequest();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.DeleteTenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.DeleteTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.DeleteTenantRequest} message DeleteTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenant = null;
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    return object;
                };

                /**
                 * Converts this DeleteTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.DeleteTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteTenantRequest;
            })();

            idp.DisableTenantRequest = (function() {

                /**
                 * Properties of a DisableTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IDisableTenantRequest
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] DisableTenantRequest tenant
                 */

                /**
                 * Constructs a new DisableTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a DisableTenantRequest.
                 * @implements IDisableTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IDisableTenantRequest=} [properties] Properties to set
                 */
                function DisableTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisableTenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.DisableTenantRequest
                 * @instance
                 */
                DisableTenantRequest.prototype.tenant = null;

                /**
                 * Verifies a DisableTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.DisableTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DisableTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.DisableTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.DisableTenantRequest} DisableTenantRequest
                 */
                DisableTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.DisableTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.DisableTenantRequest();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.DisableTenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DisableTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.DisableTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.DisableTenantRequest} message DisableTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenant = null;
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    return object;
                };

                /**
                 * Converts this DisableTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.DisableTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisableTenantRequest;
            })();

            idp.EnableTenantRequest = (function() {

                /**
                 * Properties of an EnableTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IEnableTenantRequest
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] EnableTenantRequest tenant
                 */

                /**
                 * Constructs a new EnableTenantRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents an EnableTenantRequest.
                 * @implements IEnableTenantRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IEnableTenantRequest=} [properties] Properties to set
                 */
                function EnableTenantRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnableTenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.idp.EnableTenantRequest
                 * @instance
                 */
                EnableTenantRequest.prototype.tenant = null;

                /**
                 * Verifies an EnableTenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.EnableTenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableTenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EnableTenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.EnableTenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.EnableTenantRequest} EnableTenantRequest
                 */
                EnableTenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.EnableTenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.EnableTenantRequest();
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.idp.EnableTenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EnableTenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.EnableTenantRequest
                 * @static
                 * @param {mtechnavi.api.idp.EnableTenantRequest} message EnableTenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableTenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenant = null;
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    return object;
                };

                /**
                 * Converts this EnableTenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.EnableTenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableTenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnableTenantRequest;
            })();

            idp.CreateUserGroupRequest = (function() {

                /**
                 * Properties of a CreateUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface ICreateUserGroupRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] CreateUserGroupRequest userGroup
                 */

                /**
                 * Constructs a new CreateUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a CreateUserGroupRequest.
                 * @implements ICreateUserGroupRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.ICreateUserGroupRequest=} [properties] Properties to set
                 */
                function CreateUserGroupRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserGroupRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.idp.CreateUserGroupRequest
                 * @instance
                 */
                CreateUserGroupRequest.prototype.userGroup = null;

                /**
                 * Verifies a CreateUserGroupRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.CreateUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserGroupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserGroupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.CreateUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.CreateUserGroupRequest} CreateUserGroupRequest
                 */
                CreateUserGroupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.CreateUserGroupRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.CreateUserGroupRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.idp.CreateUserGroupRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserGroupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.CreateUserGroupRequest
                 * @static
                 * @param {mtechnavi.api.idp.CreateUserGroupRequest} message CreateUserGroupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserGroupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroup = null;
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    return object;
                };

                /**
                 * Converts this CreateUserGroupRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.CreateUserGroupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserGroupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserGroupRequest;
            })();

            idp.ListUserGroupsRequest = (function() {

                /**
                 * Properties of a ListUserGroupsRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListUserGroupsRequest
                 */

                /**
                 * Constructs a new ListUserGroupsRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListUserGroupsRequest.
                 * @implements IListUserGroupsRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListUserGroupsRequest=} [properties] Properties to set
                 */
                function ListUserGroupsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserGroupsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListUserGroupsRequest} ListUserGroupsRequest
                 */
                ListUserGroupsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListUserGroupsRequest)
                        return object;
                    return new $root.mtechnavi.api.idp.ListUserGroupsRequest();
                };

                /**
                 * Creates a plain object from a ListUserGroupsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListUserGroupsRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListUserGroupsRequest} message ListUserGroupsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserGroupsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListUserGroupsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupsRequest;
            })();

            idp.ListUserGroupsResponse = (function() {

                /**
                 * Properties of a ListUserGroupsResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListUserGroupsResponse
                 * @property {Long|null} [total] ListUserGroupsResponse total
                 * @property {Array.<mtechnavi.api.idp.IUserGroup>|null} [items] ListUserGroupsResponse items
                 */

                /**
                 * Constructs a new ListUserGroupsResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListUserGroupsResponse.
                 * @implements IListUserGroupsResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListUserGroupsResponse=} [properties] Properties to set
                 */
                function ListUserGroupsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @instance
                 */
                ListUserGroupsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserGroupsResponse items.
                 * @member {Array.<mtechnavi.api.idp.IUserGroup>} items
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @instance
                 */
                ListUserGroupsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUserGroupsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.UserGroup.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserGroupsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListUserGroupsResponse} ListUserGroupsResponse
                 */
                ListUserGroupsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListUserGroupsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListUserGroupsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListUserGroupsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListUserGroupsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.UserGroup.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListUserGroupsResponse} message ListUserGroupsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.UserGroup.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserGroupsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListUserGroupsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupsResponse;
            })();

            idp.GetUserGroupRequest = (function() {

                /**
                 * Properties of a GetUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetUserGroupRequest
                 * @property {string|null} [userGroupId] GetUserGroupRequest userGroupId
                 */

                /**
                 * Constructs a new GetUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetUserGroupRequest.
                 * @implements IGetUserGroupRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetUserGroupRequest=} [properties] Properties to set
                 */
                function GetUserGroupRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetUserGroupRequest userGroupId.
                 * @member {string} userGroupId
                 * @memberof mtechnavi.api.idp.GetUserGroupRequest
                 * @instance
                 */
                GetUserGroupRequest.prototype.userGroupId = "";

                /**
                 * Verifies a GetUserGroupRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserGroupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupId != null && message.hasOwnProperty("userGroupId"))
                        if (!$util.isString(message.userGroupId))
                            return "userGroupId: string expected";
                    return null;
                };

                /**
                 * Creates a GetUserGroupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetUserGroupRequest} GetUserGroupRequest
                 */
                GetUserGroupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetUserGroupRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetUserGroupRequest();
                    if (object.userGroupId != null)
                        message.userGroupId = String(object.userGroupId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetUserGroupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetUserGroupRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetUserGroupRequest} message GetUserGroupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserGroupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroupId = "";
                    if (message.userGroupId != null && message.hasOwnProperty("userGroupId"))
                        object.userGroupId = message.userGroupId;
                    return object;
                };

                /**
                 * Converts this GetUserGroupRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetUserGroupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserGroupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetUserGroupRequest;
            })();

            idp.UpdateUserGroupRequest = (function() {

                /**
                 * Properties of an UpdateUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IUpdateUserGroupRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] UpdateUserGroupRequest userGroup
                 */

                /**
                 * Constructs a new UpdateUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents an UpdateUserGroupRequest.
                 * @implements IUpdateUserGroupRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IUpdateUserGroupRequest=} [properties] Properties to set
                 */
                function UpdateUserGroupRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserGroupRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.idp.UpdateUserGroupRequest
                 * @instance
                 */
                UpdateUserGroupRequest.prototype.userGroup = null;

                /**
                 * Verifies an UpdateUserGroupRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.UpdateUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserGroupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserGroupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.UpdateUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.UpdateUserGroupRequest} UpdateUserGroupRequest
                 */
                UpdateUserGroupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.UpdateUserGroupRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.UpdateUserGroupRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.idp.UpdateUserGroupRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserGroupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.UpdateUserGroupRequest
                 * @static
                 * @param {mtechnavi.api.idp.UpdateUserGroupRequest} message UpdateUserGroupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserGroupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroup = null;
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserGroupRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.UpdateUserGroupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserGroupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserGroupRequest;
            })();

            idp.DeleteUserGroupRequest = (function() {

                /**
                 * Properties of a DeleteUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IDeleteUserGroupRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] DeleteUserGroupRequest userGroup
                 */

                /**
                 * Constructs a new DeleteUserGroupRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a DeleteUserGroupRequest.
                 * @implements IDeleteUserGroupRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IDeleteUserGroupRequest=} [properties] Properties to set
                 */
                function DeleteUserGroupRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserGroupRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.idp.DeleteUserGroupRequest
                 * @instance
                 */
                DeleteUserGroupRequest.prototype.userGroup = null;

                /**
                 * Verifies a DeleteUserGroupRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.DeleteUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserGroupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserGroupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.DeleteUserGroupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.DeleteUserGroupRequest} DeleteUserGroupRequest
                 */
                DeleteUserGroupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.DeleteUserGroupRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.DeleteUserGroupRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.idp.DeleteUserGroupRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserGroupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.DeleteUserGroupRequest
                 * @static
                 * @param {mtechnavi.api.idp.DeleteUserGroupRequest} message DeleteUserGroupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserGroupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroup = null;
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserGroupRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.DeleteUserGroupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserGroupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserGroupRequest;
            })();

            idp.CreateUserRequest = (function() {

                /**
                 * Properties of a CreateUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface ICreateUserRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] CreateUserRequest user
                 */

                /**
                 * Constructs a new CreateUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a CreateUserRequest.
                 * @implements ICreateUserRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.ICreateUserRequest=} [properties] Properties to set
                 */
                function CreateUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.idp.CreateUserRequest
                 * @instance
                 */
                CreateUserRequest.prototype.user = null;

                /**
                 * Verifies a CreateUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.CreateUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.CreateUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.CreateUserRequest} CreateUserRequest
                 */
                CreateUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.CreateUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.CreateUserRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.idp.CreateUserRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.CreateUserRequest
                 * @static
                 * @param {mtechnavi.api.idp.CreateUserRequest} message CreateUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this CreateUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.CreateUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserRequest;
            })();

            idp.ListUsersRequest = (function() {

                /**
                 * Properties of a ListUsersRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListUsersRequest
                 */

                /**
                 * Constructs a new ListUsersRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListUsersRequest.
                 * @implements IListUsersRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListUsersRequest=} [properties] Properties to set
                 */
                function ListUsersRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUsersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListUsersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUsersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUsersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListUsersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListUsersRequest} ListUsersRequest
                 */
                ListUsersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListUsersRequest)
                        return object;
                    return new $root.mtechnavi.api.idp.ListUsersRequest();
                };

                /**
                 * Creates a plain object from a ListUsersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListUsersRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListUsersRequest} message ListUsersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUsersRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUsersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListUsersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUsersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUsersRequest;
            })();

            idp.ListUsersResponse = (function() {

                /**
                 * Properties of a ListUsersResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListUsersResponse
                 * @property {Long|null} [total] ListUsersResponse total
                 * @property {Array.<mtechnavi.api.idp.IUser>|null} [items] ListUsersResponse items
                 */

                /**
                 * Constructs a new ListUsersResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListUsersResponse.
                 * @implements IListUsersResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListUsersResponse=} [properties] Properties to set
                 */
                function ListUsersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUsersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @instance
                 */
                ListUsersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUsersResponse items.
                 * @member {Array.<mtechnavi.api.idp.IUser>} items
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @instance
                 */
                ListUsersResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUsersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUsersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.User.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUsersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListUsersResponse} ListUsersResponse
                 */
                ListUsersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListUsersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListUsersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListUsersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListUsersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.User.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUsersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListUsersResponse} message ListUsersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUsersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.User.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUsersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListUsersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUsersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUsersResponse;
            })();

            idp.GetUserRequest = (function() {

                /**
                 * Properties of a GetUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetUserRequest
                 * @property {string|null} [userId] GetUserRequest userId
                 */

                /**
                 * Constructs a new GetUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetUserRequest.
                 * @implements IGetUserRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetUserRequest=} [properties] Properties to set
                 */
                function GetUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetUserRequest userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.idp.GetUserRequest
                 * @instance
                 */
                GetUserRequest.prototype.userId = "";

                /**
                 * Verifies a GetUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetUserRequest} GetUserRequest
                 */
                GetUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetUserRequest();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetUserRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetUserRequest} message GetUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userId = "";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this GetUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetUserRequest;
            })();

            idp.UpdateUserRequest = (function() {

                /**
                 * Properties of an UpdateUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IUpdateUserRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] UpdateUserRequest user
                 */

                /**
                 * Constructs a new UpdateUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents an UpdateUserRequest.
                 * @implements IUpdateUserRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IUpdateUserRequest=} [properties] Properties to set
                 */
                function UpdateUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.idp.UpdateUserRequest
                 * @instance
                 */
                UpdateUserRequest.prototype.user = null;

                /**
                 * Verifies an UpdateUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.UpdateUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.UpdateUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.UpdateUserRequest} UpdateUserRequest
                 */
                UpdateUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.UpdateUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.UpdateUserRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.idp.UpdateUserRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.UpdateUserRequest
                 * @static
                 * @param {mtechnavi.api.idp.UpdateUserRequest} message UpdateUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.UpdateUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserRequest;
            })();

            idp.DeleteUserRequest = (function() {

                /**
                 * Properties of a DeleteUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IDeleteUserRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] DeleteUserRequest user
                 */

                /**
                 * Constructs a new DeleteUserRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a DeleteUserRequest.
                 * @implements IDeleteUserRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IDeleteUserRequest=} [properties] Properties to set
                 */
                function DeleteUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.idp.DeleteUserRequest
                 * @instance
                 */
                DeleteUserRequest.prototype.user = null;

                /**
                 * Verifies a DeleteUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.DeleteUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.DeleteUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.DeleteUserRequest} DeleteUserRequest
                 */
                DeleteUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.DeleteUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.DeleteUserRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.idp.DeleteUserRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.DeleteUserRequest
                 * @static
                 * @param {mtechnavi.api.idp.DeleteUserRequest} message DeleteUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.DeleteUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserRequest;
            })();

            idp.ListRolesRequest = (function() {

                /**
                 * Properties of a ListRolesRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListRolesRequest
                 */

                /**
                 * Constructs a new ListRolesRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListRolesRequest.
                 * @implements IListRolesRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListRolesRequest=} [properties] Properties to set
                 */
                function ListRolesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListRolesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListRolesRequest} ListRolesRequest
                 */
                ListRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListRolesRequest)
                        return object;
                    return new $root.mtechnavi.api.idp.ListRolesRequest();
                };

                /**
                 * Creates a plain object from a ListRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListRolesRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListRolesRequest} message ListRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListRolesRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListRolesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListRolesRequest;
            })();

            idp.ListRolesResponse = (function() {

                /**
                 * Properties of a ListRolesResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListRolesResponse
                 * @property {Long|null} [total] ListRolesResponse total
                 * @property {Array.<mtechnavi.api.idp.IRole>|null} [items] ListRolesResponse items
                 */

                /**
                 * Constructs a new ListRolesResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListRolesResponse.
                 * @implements IListRolesResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListRolesResponse=} [properties] Properties to set
                 */
                function ListRolesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListRolesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @instance
                 */
                ListRolesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListRolesResponse items.
                 * @member {Array.<mtechnavi.api.idp.IRole>} items
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @instance
                 */
                ListRolesResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListRolesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.Role.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListRolesResponse} ListRolesResponse
                 */
                ListRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListRolesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListRolesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListRolesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListRolesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.Role.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListRolesResponse} message ListRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.Role.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListRolesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListRolesResponse;
            })();

            idp.GetRoleRequest = (function() {

                /**
                 * Properties of a GetRoleRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetRoleRequest
                 * @property {string|null} [roleName] GetRoleRequest roleName
                 */

                /**
                 * Constructs a new GetRoleRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetRoleRequest.
                 * @implements IGetRoleRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetRoleRequest=} [properties] Properties to set
                 */
                function GetRoleRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetRoleRequest roleName.
                 * @member {string} roleName
                 * @memberof mtechnavi.api.idp.GetRoleRequest
                 * @instance
                 */
                GetRoleRequest.prototype.roleName = "";

                /**
                 * Verifies a GetRoleRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetRoleRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        if (!$util.isString(message.roleName))
                            return "roleName: string expected";
                    return null;
                };

                /**
                 * Creates a GetRoleRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetRoleRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetRoleRequest} GetRoleRequest
                 */
                GetRoleRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetRoleRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetRoleRequest();
                    if (object.roleName != null)
                        message.roleName = String(object.roleName);
                    return message;
                };

                /**
                 * Creates a plain object from a GetRoleRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetRoleRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetRoleRequest} message GetRoleRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.roleName = "";
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        object.roleName = message.roleName;
                    return object;
                };

                /**
                 * Converts this GetRoleRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetRoleRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetRoleRequest;
            })();

            idp.ListSchemaAccessControlsRequest = (function() {

                /**
                 * Properties of a ListSchemaAccessControlsRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListSchemaAccessControlsRequest
                 */

                /**
                 * Constructs a new ListSchemaAccessControlsRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListSchemaAccessControlsRequest.
                 * @implements IListSchemaAccessControlsRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListSchemaAccessControlsRequest=} [properties] Properties to set
                 */
                function ListSchemaAccessControlsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListSchemaAccessControlsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSchemaAccessControlsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListSchemaAccessControlsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListSchemaAccessControlsRequest} ListSchemaAccessControlsRequest
                 */
                ListSchemaAccessControlsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListSchemaAccessControlsRequest)
                        return object;
                    return new $root.mtechnavi.api.idp.ListSchemaAccessControlsRequest();
                };

                /**
                 * Creates a plain object from a ListSchemaAccessControlsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListSchemaAccessControlsRequest} message ListSchemaAccessControlsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSchemaAccessControlsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListSchemaAccessControlsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSchemaAccessControlsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSchemaAccessControlsRequest;
            })();

            idp.ListSchemaAccessControlsResponse = (function() {

                /**
                 * Properties of a ListSchemaAccessControlsResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListSchemaAccessControlsResponse
                 * @property {Long|null} [total] ListSchemaAccessControlsResponse total
                 * @property {Array.<mtechnavi.api.idp.ISchemaAccessControl>|null} [items] ListSchemaAccessControlsResponse items
                 */

                /**
                 * Constructs a new ListSchemaAccessControlsResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListSchemaAccessControlsResponse.
                 * @implements IListSchemaAccessControlsResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListSchemaAccessControlsResponse=} [properties] Properties to set
                 */
                function ListSchemaAccessControlsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSchemaAccessControlsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @instance
                 */
                ListSchemaAccessControlsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSchemaAccessControlsResponse items.
                 * @member {Array.<mtechnavi.api.idp.ISchemaAccessControl>} items
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @instance
                 */
                ListSchemaAccessControlsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListSchemaAccessControlsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSchemaAccessControlsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.SchemaAccessControl.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListSchemaAccessControlsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListSchemaAccessControlsResponse} ListSchemaAccessControlsResponse
                 */
                ListSchemaAccessControlsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListSchemaAccessControlsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListSchemaAccessControlsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListSchemaAccessControlsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListSchemaAccessControlsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.SchemaAccessControl.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSchemaAccessControlsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListSchemaAccessControlsResponse} message ListSchemaAccessControlsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSchemaAccessControlsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.SchemaAccessControl.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListSchemaAccessControlsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListSchemaAccessControlsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSchemaAccessControlsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSchemaAccessControlsResponse;
            })();

            idp.GetSchemaAccessControlRequest = (function() {

                /**
                 * Properties of a GetSchemaAccessControlRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IGetSchemaAccessControlRequest
                 * @property {string|null} [schemaAccessControlName] GetSchemaAccessControlRequest schemaAccessControlName
                 */

                /**
                 * Constructs a new GetSchemaAccessControlRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a GetSchemaAccessControlRequest.
                 * @implements IGetSchemaAccessControlRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IGetSchemaAccessControlRequest=} [properties] Properties to set
                 */
                function GetSchemaAccessControlRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSchemaAccessControlRequest schemaAccessControlName.
                 * @member {string} schemaAccessControlName
                 * @memberof mtechnavi.api.idp.GetSchemaAccessControlRequest
                 * @instance
                 */
                GetSchemaAccessControlRequest.prototype.schemaAccessControlName = "";

                /**
                 * Verifies a GetSchemaAccessControlRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.GetSchemaAccessControlRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSchemaAccessControlRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        if (!$util.isString(message.schemaAccessControlName))
                            return "schemaAccessControlName: string expected";
                    return null;
                };

                /**
                 * Creates a GetSchemaAccessControlRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.GetSchemaAccessControlRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.GetSchemaAccessControlRequest} GetSchemaAccessControlRequest
                 */
                GetSchemaAccessControlRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.GetSchemaAccessControlRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.GetSchemaAccessControlRequest();
                    if (object.schemaAccessControlName != null)
                        message.schemaAccessControlName = String(object.schemaAccessControlName);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSchemaAccessControlRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.GetSchemaAccessControlRequest
                 * @static
                 * @param {mtechnavi.api.idp.GetSchemaAccessControlRequest} message GetSchemaAccessControlRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSchemaAccessControlRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.schemaAccessControlName = "";
                    if (message.schemaAccessControlName != null && message.hasOwnProperty("schemaAccessControlName"))
                        object.schemaAccessControlName = message.schemaAccessControlName;
                    return object;
                };

                /**
                 * Converts this GetSchemaAccessControlRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.GetSchemaAccessControlRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSchemaAccessControlRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSchemaAccessControlRequest;
            })();

            idp.ListSamlProviderSettingsRequest = (function() {

                /**
                 * Properties of a ListSamlProviderSettingsRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface IListSamlProviderSettingsRequest
                 * @property {string|null} [pageToken] ListSamlProviderSettingsRequest pageToken
                 */

                /**
                 * Constructs a new ListSamlProviderSettingsRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListSamlProviderSettingsRequest.
                 * @implements IListSamlProviderSettingsRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.IListSamlProviderSettingsRequest=} [properties] Properties to set
                 */
                function ListSamlProviderSettingsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSamlProviderSettingsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsRequest
                 * @instance
                 */
                ListSamlProviderSettingsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListSamlProviderSettingsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSamlProviderSettingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSamlProviderSettingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListSamlProviderSettingsRequest} ListSamlProviderSettingsRequest
                 */
                ListSamlProviderSettingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListSamlProviderSettingsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListSamlProviderSettingsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSamlProviderSettingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsRequest
                 * @static
                 * @param {mtechnavi.api.idp.ListSamlProviderSettingsRequest} message ListSamlProviderSettingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSamlProviderSettingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSamlProviderSettingsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSamlProviderSettingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSamlProviderSettingsRequest;
            })();

            idp.ListSamlProviderSettingsResponse = (function() {

                /**
                 * Properties of a ListSamlProviderSettingsResponse.
                 * @memberof mtechnavi.api.idp
                 * @interface IListSamlProviderSettingsResponse
                 * @property {Long|null} [total] ListSamlProviderSettingsResponse total
                 * @property {Array.<mtechnavi.api.idp.ISamlProviderSetting>|null} [items] ListSamlProviderSettingsResponse items
                 * @property {string|null} [pageToken] ListSamlProviderSettingsResponse pageToken
                 */

                /**
                 * Constructs a new ListSamlProviderSettingsResponse.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a ListSamlProviderSettingsResponse.
                 * @implements IListSamlProviderSettingsResponse
                 * @constructor
                 * @param {mtechnavi.api.idp.IListSamlProviderSettingsResponse=} [properties] Properties to set
                 */
                function ListSamlProviderSettingsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSamlProviderSettingsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @instance
                 */
                ListSamlProviderSettingsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSamlProviderSettingsResponse items.
                 * @member {Array.<mtechnavi.api.idp.ISamlProviderSetting>} items
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @instance
                 */
                ListSamlProviderSettingsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSamlProviderSettingsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @instance
                 */
                ListSamlProviderSettingsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSamlProviderSettingsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSamlProviderSettingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.idp.SamlProviderSetting.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSamlProviderSettingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.ListSamlProviderSettingsResponse} ListSamlProviderSettingsResponse
                 */
                ListSamlProviderSettingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.ListSamlProviderSettingsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.idp.ListSamlProviderSettingsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.idp.ListSamlProviderSettingsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.idp.ListSamlProviderSettingsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.idp.SamlProviderSetting.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSamlProviderSettingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @static
                 * @param {mtechnavi.api.idp.ListSamlProviderSettingsResponse} message ListSamlProviderSettingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSamlProviderSettingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.idp.SamlProviderSetting.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSamlProviderSettingsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.ListSamlProviderSettingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSamlProviderSettingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSamlProviderSettingsResponse;
            })();

            idp.SaveSamlProviderSettingRequest = (function() {

                /**
                 * Properties of a SaveSamlProviderSettingRequest.
                 * @memberof mtechnavi.api.idp
                 * @interface ISaveSamlProviderSettingRequest
                 * @property {mtechnavi.api.idp.ISamlProviderSetting|null} [samlProviderSetting] SaveSamlProviderSettingRequest samlProviderSetting
                 */

                /**
                 * Constructs a new SaveSamlProviderSettingRequest.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a SaveSamlProviderSettingRequest.
                 * @implements ISaveSamlProviderSettingRequest
                 * @constructor
                 * @param {mtechnavi.api.idp.ISaveSamlProviderSettingRequest=} [properties] Properties to set
                 */
                function SaveSamlProviderSettingRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveSamlProviderSettingRequest samlProviderSetting.
                 * @member {mtechnavi.api.idp.ISamlProviderSetting|null|undefined} samlProviderSetting
                 * @memberof mtechnavi.api.idp.SaveSamlProviderSettingRequest
                 * @instance
                 */
                SaveSamlProviderSettingRequest.prototype.samlProviderSetting = null;

                /**
                 * Verifies a SaveSamlProviderSettingRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.SaveSamlProviderSettingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveSamlProviderSettingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting")) {
                        let error = $root.mtechnavi.api.idp.SamlProviderSetting.verify(message.samlProviderSetting);
                        if (error)
                            return "samlProviderSetting." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveSamlProviderSettingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.SaveSamlProviderSettingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.SaveSamlProviderSettingRequest} SaveSamlProviderSettingRequest
                 */
                SaveSamlProviderSettingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.SaveSamlProviderSettingRequest)
                        return object;
                    let message = new $root.mtechnavi.api.idp.SaveSamlProviderSettingRequest();
                    if (object.samlProviderSetting != null) {
                        if (typeof object.samlProviderSetting !== "object")
                            throw TypeError(".mtechnavi.api.idp.SaveSamlProviderSettingRequest.samlProviderSetting: object expected");
                        message.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.fromObject(object.samlProviderSetting);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveSamlProviderSettingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.SaveSamlProviderSettingRequest
                 * @static
                 * @param {mtechnavi.api.idp.SaveSamlProviderSettingRequest} message SaveSamlProviderSettingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveSamlProviderSettingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.samlProviderSetting = null;
                    if (message.samlProviderSetting != null && message.hasOwnProperty("samlProviderSetting"))
                        object.samlProviderSetting = $root.mtechnavi.api.idp.SamlProviderSetting.toObject(message.samlProviderSetting, options);
                    return object;
                };

                /**
                 * Converts this SaveSamlProviderSettingRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.SaveSamlProviderSettingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveSamlProviderSettingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveSamlProviderSettingRequest;
            })();

            idp.Identity = (function() {

                /**
                 * Constructs a new Identity service.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents an Identity
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Identity(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Identity.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Identity;

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#createBrowserSession}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef CreateBrowserSessionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls CreateBrowserSession.
                 * @function createBrowserSession
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateBrowserSessionRequest} request CreateBrowserSessionRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.CreateBrowserSessionCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.createBrowserSession = function createBrowserSession(request, callback) {
                    return this.rpcCall(createBrowserSession, $root.mtechnavi.api.idp.CreateBrowserSessionRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "CreateBrowserSession" });

                /**
                 * Calls CreateBrowserSession.
                 * @function createBrowserSession
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateBrowserSessionRequest} request CreateBrowserSessionRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#deleteBrowserSession}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef DeleteBrowserSessionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteBrowserSession.
                 * @function deleteBrowserSession
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {mtechnavi.api.idp.Identity.DeleteBrowserSessionCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.deleteBrowserSession = function deleteBrowserSession(request, callback) {
                    return this.rpcCall(deleteBrowserSession, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteBrowserSession" });

                /**
                 * Calls DeleteBrowserSession.
                 * @function deleteBrowserSession
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getTenantConfig}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetTenantConfigCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.TenantConfig} [response] TenantConfig
                 */

                /**
                 * Calls GetTenantConfig.
                 * @function getTenantConfig
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetTenantConfigRequest} request GetTenantConfigRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetTenantConfigCallback} callback Node-style callback called with the error, if any, and TenantConfig
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getTenantConfig = function getTenantConfig(request, callback) {
                    return this.rpcCall(getTenantConfig, $root.mtechnavi.api.idp.GetTenantConfigRequest, $root.mtechnavi.api.idp.TenantConfig, request, callback);
                }, "name", { value: "GetTenantConfig" });

                /**
                 * Calls GetTenantConfig.
                 * @function getTenantConfig
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetTenantConfigRequest} request GetTenantConfigRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.TenantConfig>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#verifyUser}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef VerifyUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.VerifyUserResponse} [response] VerifyUserResponse
                 */

                /**
                 * Calls VerifyUser.
                 * @function verifyUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {mtechnavi.api.idp.Identity.VerifyUserCallback} callback Node-style callback called with the error, if any, and VerifyUserResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.verifyUser = function verifyUser(request, callback) {
                    return this.rpcCall(verifyUser, $root.google.protobuf.Empty, $root.mtechnavi.api.idp.VerifyUserResponse, request, callback);
                }, "name", { value: "VerifyUser" });

                /**
                 * Calls VerifyUser.
                 * @function verifyUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<mtechnavi.api.idp.VerifyUserResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#createTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef CreateTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Tenant} [response] Tenant
                 */

                /**
                 * Calls CreateTenant.
                 * @function createTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateTenantRequest} request CreateTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.CreateTenantCallback} callback Node-style callback called with the error, if any, and Tenant
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.createTenant = function createTenant(request, callback) {
                    return this.rpcCall(createTenant, $root.mtechnavi.api.idp.CreateTenantRequest, $root.mtechnavi.api.idp.Tenant, request, callback);
                }, "name", { value: "CreateTenant" });

                /**
                 * Calls CreateTenant.
                 * @function createTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateTenantRequest} request CreateTenantRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Tenant>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listTenants}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListTenantsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListTenantsResponse} [response] ListTenantsResponse
                 */

                /**
                 * Calls ListTenants.
                 * @function listTenants
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListTenantsRequest} request ListTenantsRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListTenantsCallback} callback Node-style callback called with the error, if any, and ListTenantsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listTenants = function listTenants(request, callback) {
                    return this.rpcCall(listTenants, $root.mtechnavi.api.idp.ListTenantsRequest, $root.mtechnavi.api.idp.ListTenantsResponse, request, callback);
                }, "name", { value: "ListTenants" });

                /**
                 * Calls ListTenants.
                 * @function listTenants
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListTenantsRequest} request ListTenantsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListTenantsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Tenant} [response] Tenant
                 */

                /**
                 * Calls GetTenant.
                 * @function getTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetTenantRequest} request GetTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetTenantCallback} callback Node-style callback called with the error, if any, and Tenant
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getTenant = function getTenant(request, callback) {
                    return this.rpcCall(getTenant, $root.mtechnavi.api.idp.GetTenantRequest, $root.mtechnavi.api.idp.Tenant, request, callback);
                }, "name", { value: "GetTenant" });

                /**
                 * Calls GetTenant.
                 * @function getTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetTenantRequest} request GetTenantRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Tenant>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#updateTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef UpdateTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Tenant} [response] Tenant
                 */

                /**
                 * Calls UpdateTenant.
                 * @function updateTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateTenantRequest} request UpdateTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.UpdateTenantCallback} callback Node-style callback called with the error, if any, and Tenant
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.updateTenant = function updateTenant(request, callback) {
                    return this.rpcCall(updateTenant, $root.mtechnavi.api.idp.UpdateTenantRequest, $root.mtechnavi.api.idp.Tenant, request, callback);
                }, "name", { value: "UpdateTenant" });

                /**
                 * Calls UpdateTenant.
                 * @function updateTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateTenantRequest} request UpdateTenantRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Tenant>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#deleteTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef DeleteTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteTenant.
                 * @function deleteTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteTenantRequest} request DeleteTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.DeleteTenantCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.deleteTenant = function deleteTenant(request, callback) {
                    return this.rpcCall(deleteTenant, $root.mtechnavi.api.idp.DeleteTenantRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteTenant" });

                /**
                 * Calls DeleteTenant.
                 * @function deleteTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteTenantRequest} request DeleteTenantRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#disableTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef DisableTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Tenant} [response] Tenant
                 */

                /**
                 * Calls DisableTenant.
                 * @function disableTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDisableTenantRequest} request DisableTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.DisableTenantCallback} callback Node-style callback called with the error, if any, and Tenant
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.disableTenant = function disableTenant(request, callback) {
                    return this.rpcCall(disableTenant, $root.mtechnavi.api.idp.DisableTenantRequest, $root.mtechnavi.api.idp.Tenant, request, callback);
                }, "name", { value: "DisableTenant" });

                /**
                 * Calls DisableTenant.
                 * @function disableTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDisableTenantRequest} request DisableTenantRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Tenant>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#enableTenant}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef EnableTenantCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Tenant} [response] Tenant
                 */

                /**
                 * Calls EnableTenant.
                 * @function enableTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IEnableTenantRequest} request EnableTenantRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.EnableTenantCallback} callback Node-style callback called with the error, if any, and Tenant
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.enableTenant = function enableTenant(request, callback) {
                    return this.rpcCall(enableTenant, $root.mtechnavi.api.idp.EnableTenantRequest, $root.mtechnavi.api.idp.Tenant, request, callback);
                }, "name", { value: "EnableTenant" });

                /**
                 * Calls EnableTenant.
                 * @function enableTenant
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IEnableTenantRequest} request EnableTenantRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Tenant>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#createUserGroup}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef CreateUserGroupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.UserGroup} [response] UserGroup
                 */

                /**
                 * Calls CreateUserGroup.
                 * @function createUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateUserGroupRequest} request CreateUserGroupRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.CreateUserGroupCallback} callback Node-style callback called with the error, if any, and UserGroup
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.createUserGroup = function createUserGroup(request, callback) {
                    return this.rpcCall(createUserGroup, $root.mtechnavi.api.idp.CreateUserGroupRequest, $root.mtechnavi.api.idp.UserGroup, request, callback);
                }, "name", { value: "CreateUserGroup" });

                /**
                 * Calls CreateUserGroup.
                 * @function createUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateUserGroupRequest} request CreateUserGroupRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.UserGroup>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listUserGroups}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListUserGroupsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListUserGroupsResponse} [response] ListUserGroupsResponse
                 */

                /**
                 * Calls ListUserGroups.
                 * @function listUserGroups
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListUserGroupsRequest} request ListUserGroupsRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListUserGroupsCallback} callback Node-style callback called with the error, if any, and ListUserGroupsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listUserGroups = function listUserGroups(request, callback) {
                    return this.rpcCall(listUserGroups, $root.mtechnavi.api.idp.ListUserGroupsRequest, $root.mtechnavi.api.idp.ListUserGroupsResponse, request, callback);
                }, "name", { value: "ListUserGroups" });

                /**
                 * Calls ListUserGroups.
                 * @function listUserGroups
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListUserGroupsRequest} request ListUserGroupsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListUserGroupsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getUserGroup}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetUserGroupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.UserGroup} [response] UserGroup
                 */

                /**
                 * Calls GetUserGroup.
                 * @function getUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetUserGroupRequest} request GetUserGroupRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetUserGroupCallback} callback Node-style callback called with the error, if any, and UserGroup
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getUserGroup = function getUserGroup(request, callback) {
                    return this.rpcCall(getUserGroup, $root.mtechnavi.api.idp.GetUserGroupRequest, $root.mtechnavi.api.idp.UserGroup, request, callback);
                }, "name", { value: "GetUserGroup" });

                /**
                 * Calls GetUserGroup.
                 * @function getUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetUserGroupRequest} request GetUserGroupRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.UserGroup>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#updateUserGroup}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef UpdateUserGroupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.UserGroup} [response] UserGroup
                 */

                /**
                 * Calls UpdateUserGroup.
                 * @function updateUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateUserGroupRequest} request UpdateUserGroupRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.UpdateUserGroupCallback} callback Node-style callback called with the error, if any, and UserGroup
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.updateUserGroup = function updateUserGroup(request, callback) {
                    return this.rpcCall(updateUserGroup, $root.mtechnavi.api.idp.UpdateUserGroupRequest, $root.mtechnavi.api.idp.UserGroup, request, callback);
                }, "name", { value: "UpdateUserGroup" });

                /**
                 * Calls UpdateUserGroup.
                 * @function updateUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateUserGroupRequest} request UpdateUserGroupRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.UserGroup>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#deleteUserGroup}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef DeleteUserGroupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserGroup.
                 * @function deleteUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteUserGroupRequest} request DeleteUserGroupRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.DeleteUserGroupCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.deleteUserGroup = function deleteUserGroup(request, callback) {
                    return this.rpcCall(deleteUserGroup, $root.mtechnavi.api.idp.DeleteUserGroupRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserGroup" });

                /**
                 * Calls DeleteUserGroup.
                 * @function deleteUserGroup
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteUserGroupRequest} request DeleteUserGroupRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#createUser}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef CreateUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.User} [response] User
                 */

                /**
                 * Calls CreateUser.
                 * @function createUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateUserRequest} request CreateUserRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.CreateUserCallback} callback Node-style callback called with the error, if any, and User
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.createUser = function createUser(request, callback) {
                    return this.rpcCall(createUser, $root.mtechnavi.api.idp.CreateUserRequest, $root.mtechnavi.api.idp.User, request, callback);
                }, "name", { value: "CreateUser" });

                /**
                 * Calls CreateUser.
                 * @function createUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ICreateUserRequest} request CreateUserRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.User>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listUsers}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListUsersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListUsersResponse} [response] ListUsersResponse
                 */

                /**
                 * Calls ListUsers.
                 * @function listUsers
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListUsersRequest} request ListUsersRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListUsersCallback} callback Node-style callback called with the error, if any, and ListUsersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listUsers = function listUsers(request, callback) {
                    return this.rpcCall(listUsers, $root.mtechnavi.api.idp.ListUsersRequest, $root.mtechnavi.api.idp.ListUsersResponse, request, callback);
                }, "name", { value: "ListUsers" });

                /**
                 * Calls ListUsers.
                 * @function listUsers
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListUsersRequest} request ListUsersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListUsersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getUser}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.User} [response] User
                 */

                /**
                 * Calls GetUser.
                 * @function getUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetUserRequest} request GetUserRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetUserCallback} callback Node-style callback called with the error, if any, and User
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getUser = function getUser(request, callback) {
                    return this.rpcCall(getUser, $root.mtechnavi.api.idp.GetUserRequest, $root.mtechnavi.api.idp.User, request, callback);
                }, "name", { value: "GetUser" });

                /**
                 * Calls GetUser.
                 * @function getUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetUserRequest} request GetUserRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.User>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#updateUser}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef UpdateUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.User} [response] User
                 */

                /**
                 * Calls UpdateUser.
                 * @function updateUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateUserRequest} request UpdateUserRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.UpdateUserCallback} callback Node-style callback called with the error, if any, and User
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.updateUser = function updateUser(request, callback) {
                    return this.rpcCall(updateUser, $root.mtechnavi.api.idp.UpdateUserRequest, $root.mtechnavi.api.idp.User, request, callback);
                }, "name", { value: "UpdateUser" });

                /**
                 * Calls UpdateUser.
                 * @function updateUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IUpdateUserRequest} request UpdateUserRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.User>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#deleteUser}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef DeleteUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUser.
                 * @function deleteUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteUserRequest} request DeleteUserRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.DeleteUserCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.deleteUser = function deleteUser(request, callback) {
                    return this.rpcCall(deleteUser, $root.mtechnavi.api.idp.DeleteUserRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUser" });

                /**
                 * Calls DeleteUser.
                 * @function deleteUser
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IDeleteUserRequest} request DeleteUserRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listRoles}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListRolesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListRolesResponse} [response] ListRolesResponse
                 */

                /**
                 * Calls ListRoles.
                 * @function listRoles
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListRolesRequest} request ListRolesRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListRolesCallback} callback Node-style callback called with the error, if any, and ListRolesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listRoles = function listRoles(request, callback) {
                    return this.rpcCall(listRoles, $root.mtechnavi.api.idp.ListRolesRequest, $root.mtechnavi.api.idp.ListRolesResponse, request, callback);
                }, "name", { value: "ListRoles" });

                /**
                 * Calls ListRoles.
                 * @function listRoles
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListRolesRequest} request ListRolesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListRolesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getRole}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetRoleCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.Role} [response] Role
                 */

                /**
                 * Calls GetRole.
                 * @function getRole
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetRoleRequest} request GetRoleRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetRoleCallback} callback Node-style callback called with the error, if any, and Role
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getRole = function getRole(request, callback) {
                    return this.rpcCall(getRole, $root.mtechnavi.api.idp.GetRoleRequest, $root.mtechnavi.api.idp.Role, request, callback);
                }, "name", { value: "GetRole" });

                /**
                 * Calls GetRole.
                 * @function getRole
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetRoleRequest} request GetRoleRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.Role>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listSchemaAccessControls}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListSchemaAccessControlsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListSchemaAccessControlsResponse} [response] ListSchemaAccessControlsResponse
                 */

                /**
                 * Calls ListSchemaAccessControls.
                 * @function listSchemaAccessControls
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListSchemaAccessControlsRequest} request ListSchemaAccessControlsRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListSchemaAccessControlsCallback} callback Node-style callback called with the error, if any, and ListSchemaAccessControlsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listSchemaAccessControls = function listSchemaAccessControls(request, callback) {
                    return this.rpcCall(listSchemaAccessControls, $root.mtechnavi.api.idp.ListSchemaAccessControlsRequest, $root.mtechnavi.api.idp.ListSchemaAccessControlsResponse, request, callback);
                }, "name", { value: "ListSchemaAccessControls" });

                /**
                 * Calls ListSchemaAccessControls.
                 * @function listSchemaAccessControls
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListSchemaAccessControlsRequest} request ListSchemaAccessControlsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListSchemaAccessControlsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#getSchemaAccessControl}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef GetSchemaAccessControlCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.SchemaAccessControl} [response] SchemaAccessControl
                 */

                /**
                 * Calls GetSchemaAccessControl.
                 * @function getSchemaAccessControl
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetSchemaAccessControlRequest} request GetSchemaAccessControlRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.GetSchemaAccessControlCallback} callback Node-style callback called with the error, if any, and SchemaAccessControl
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.getSchemaAccessControl = function getSchemaAccessControl(request, callback) {
                    return this.rpcCall(getSchemaAccessControl, $root.mtechnavi.api.idp.GetSchemaAccessControlRequest, $root.mtechnavi.api.idp.SchemaAccessControl, request, callback);
                }, "name", { value: "GetSchemaAccessControl" });

                /**
                 * Calls GetSchemaAccessControl.
                 * @function getSchemaAccessControl
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IGetSchemaAccessControlRequest} request GetSchemaAccessControlRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.SchemaAccessControl>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#listSamlProviderSettings}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef ListSamlProviderSettingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.ListSamlProviderSettingsResponse} [response] ListSamlProviderSettingsResponse
                 */

                /**
                 * Calls ListSamlProviderSettings.
                 * @function listSamlProviderSettings
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListSamlProviderSettingsRequest} request ListSamlProviderSettingsRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.ListSamlProviderSettingsCallback} callback Node-style callback called with the error, if any, and ListSamlProviderSettingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.listSamlProviderSettings = function listSamlProviderSettings(request, callback) {
                    return this.rpcCall(listSamlProviderSettings, $root.mtechnavi.api.idp.ListSamlProviderSettingsRequest, $root.mtechnavi.api.idp.ListSamlProviderSettingsResponse, request, callback);
                }, "name", { value: "ListSamlProviderSettings" });

                /**
                 * Calls ListSamlProviderSettings.
                 * @function listSamlProviderSettings
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.IListSamlProviderSettingsRequest} request ListSamlProviderSettingsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.ListSamlProviderSettingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.idp.Identity#saveSamlProviderSetting}.
                 * @memberof mtechnavi.api.idp.Identity
                 * @typedef SaveSamlProviderSettingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.idp.SamlProviderSetting} [response] SamlProviderSetting
                 */

                /**
                 * Calls SaveSamlProviderSetting.
                 * @function saveSamlProviderSetting
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ISaveSamlProviderSettingRequest} request SaveSamlProviderSettingRequest message or plain object
                 * @param {mtechnavi.api.idp.Identity.SaveSamlProviderSettingCallback} callback Node-style callback called with the error, if any, and SamlProviderSetting
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Identity.prototype.saveSamlProviderSetting = function saveSamlProviderSetting(request, callback) {
                    return this.rpcCall(saveSamlProviderSetting, $root.mtechnavi.api.idp.SaveSamlProviderSettingRequest, $root.mtechnavi.api.idp.SamlProviderSetting, request, callback);
                }, "name", { value: "SaveSamlProviderSetting" });

                /**
                 * Calls SaveSamlProviderSetting.
                 * @function saveSamlProviderSetting
                 * @memberof mtechnavi.api.idp.Identity
                 * @instance
                 * @param {mtechnavi.api.idp.ISaveSamlProviderSettingRequest} request SaveSamlProviderSettingRequest message or plain object
                 * @returns {Promise<mtechnavi.api.idp.SamlProviderSetting>} Promise
                 * @variation 2
                 */

                return Identity;
            })();

            idp.SamlProviderSetting = (function() {

                /**
                 * Properties of a SamlProviderSetting.
                 * @memberof mtechnavi.api.idp
                 * @interface ISamlProviderSetting
                 * @property {string|null} [samlProviderId] SamlProviderSetting samlProviderId
                 * @property {string|null} [displayName] SamlProviderSetting displayName
                 * @property {boolean|null} [enabled] SamlProviderSetting enabled
                 * @property {string|null} [entityId] SamlProviderSetting entityId
                 * @property {string|null} [ssoUrl] SamlProviderSetting ssoUrl
                 * @property {Array.<string>|null} [certificates] SamlProviderSetting certificates
                 */

                /**
                 * Constructs a new SamlProviderSetting.
                 * @memberof mtechnavi.api.idp
                 * @classdesc Represents a SamlProviderSetting.
                 * @implements ISamlProviderSetting
                 * @constructor
                 * @param {mtechnavi.api.idp.ISamlProviderSetting=} [properties] Properties to set
                 */
                function SamlProviderSetting(properties) {
                    this.certificates = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SamlProviderSetting samlProviderId.
                 * @member {string} samlProviderId
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.samlProviderId = "";

                /**
                 * SamlProviderSetting displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.displayName = "";

                /**
                 * SamlProviderSetting enabled.
                 * @member {boolean} enabled
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.enabled = false;

                /**
                 * SamlProviderSetting entityId.
                 * @member {string} entityId
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.entityId = "";

                /**
                 * SamlProviderSetting ssoUrl.
                 * @member {string} ssoUrl
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.ssoUrl = "";

                /**
                 * SamlProviderSetting certificates.
                 * @member {Array.<string>} certificates
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 */
                SamlProviderSetting.prototype.certificates = $util.emptyArray;

                /**
                 * Verifies a SamlProviderSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SamlProviderSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.samlProviderId != null && message.hasOwnProperty("samlProviderId"))
                        if (!$util.isString(message.samlProviderId))
                            return "samlProviderId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        if (typeof message.enabled !== "boolean")
                            return "enabled: boolean expected";
                    if (message.entityId != null && message.hasOwnProperty("entityId"))
                        if (!$util.isString(message.entityId))
                            return "entityId: string expected";
                    if (message.ssoUrl != null && message.hasOwnProperty("ssoUrl"))
                        if (!$util.isString(message.ssoUrl))
                            return "ssoUrl: string expected";
                    if (message.certificates != null && message.hasOwnProperty("certificates")) {
                        if (!Array.isArray(message.certificates))
                            return "certificates: array expected";
                        for (let i = 0; i < message.certificates.length; ++i)
                            if (!$util.isString(message.certificates[i]))
                                return "certificates: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SamlProviderSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.idp.SamlProviderSetting} SamlProviderSetting
                 */
                SamlProviderSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.idp.SamlProviderSetting)
                        return object;
                    let message = new $root.mtechnavi.api.idp.SamlProviderSetting();
                    if (object.samlProviderId != null)
                        message.samlProviderId = String(object.samlProviderId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.enabled != null)
                        message.enabled = Boolean(object.enabled);
                    if (object.entityId != null)
                        message.entityId = String(object.entityId);
                    if (object.ssoUrl != null)
                        message.ssoUrl = String(object.ssoUrl);
                    if (object.certificates) {
                        if (!Array.isArray(object.certificates))
                            throw TypeError(".mtechnavi.api.idp.SamlProviderSetting.certificates: array expected");
                        message.certificates = [];
                        for (let i = 0; i < object.certificates.length; ++i)
                            message.certificates[i] = String(object.certificates[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SamlProviderSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @static
                 * @param {mtechnavi.api.idp.SamlProviderSetting} message SamlProviderSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SamlProviderSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.certificates = [];
                    if (options.defaults) {
                        object.samlProviderId = "";
                        object.enabled = false;
                        object.entityId = "";
                        object.ssoUrl = "";
                        object.displayName = "";
                    }
                    if (message.samlProviderId != null && message.hasOwnProperty("samlProviderId"))
                        object.samlProviderId = message.samlProviderId;
                    if (message.enabled != null && message.hasOwnProperty("enabled"))
                        object.enabled = message.enabled;
                    if (message.entityId != null && message.hasOwnProperty("entityId"))
                        object.entityId = message.entityId;
                    if (message.ssoUrl != null && message.hasOwnProperty("ssoUrl"))
                        object.ssoUrl = message.ssoUrl;
                    if (message.certificates && message.certificates.length) {
                        object.certificates = [];
                        for (let j = 0; j < message.certificates.length; ++j)
                            object.certificates[j] = message.certificates[j];
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this SamlProviderSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.idp.SamlProviderSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SamlProviderSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SamlProviderSetting;
            })();

            return idp;
        })();

        api.license = (function() {

            /**
             * Namespace license.
             * @memberof mtechnavi.api
             * @namespace
             */
            const license = {};

            license.AdminLicenseService = (function() {

                /**
                 * Constructs a new AdminLicenseService service.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents an AdminLicenseService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AdminLicenseService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AdminLicenseService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AdminLicenseService;

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#listLicenseCatalogs}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef ListLicenseCatalogsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.ListLicenseCatalogsResponse} [response] ListLicenseCatalogsResponse
                 */

                /**
                 * Calls ListLicenseCatalogs.
                 * @function listLicenseCatalogs
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicenseCatalogsRequest} request ListLicenseCatalogsRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.ListLicenseCatalogsCallback} callback Node-style callback called with the error, if any, and ListLicenseCatalogsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.listLicenseCatalogs = function listLicenseCatalogs(request, callback) {
                    return this.rpcCall(listLicenseCatalogs, $root.mtechnavi.api.license.ListLicenseCatalogsRequest, $root.mtechnavi.api.license.ListLicenseCatalogsResponse, request, callback);
                }, "name", { value: "ListLicenseCatalogs" });

                /**
                 * Calls ListLicenseCatalogs.
                 * @function listLicenseCatalogs
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicenseCatalogsRequest} request ListLicenseCatalogsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.ListLicenseCatalogsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#createLicenseCatalog}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef CreateLicenseCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.LicenseCatalog} [response] LicenseCatalog
                 */

                /**
                 * Calls CreateLicenseCatalog.
                 * @function createLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ICreateLicenseCatalogRequest} request CreateLicenseCatalogRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.CreateLicenseCatalogCallback} callback Node-style callback called with the error, if any, and LicenseCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.createLicenseCatalog = function createLicenseCatalog(request, callback) {
                    return this.rpcCall(createLicenseCatalog, $root.mtechnavi.api.license.CreateLicenseCatalogRequest, $root.mtechnavi.api.license.LicenseCatalog, request, callback);
                }, "name", { value: "CreateLicenseCatalog" });

                /**
                 * Calls CreateLicenseCatalog.
                 * @function createLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ICreateLicenseCatalogRequest} request CreateLicenseCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.LicenseCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#updateLicenseCatalog}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef UpdateLicenseCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.LicenseCatalog} [response] LicenseCatalog
                 */

                /**
                 * Calls UpdateLicenseCatalog.
                 * @function updateLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IUpdateLicenseCatalogRequest} request UpdateLicenseCatalogRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.UpdateLicenseCatalogCallback} callback Node-style callback called with the error, if any, and LicenseCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.updateLicenseCatalog = function updateLicenseCatalog(request, callback) {
                    return this.rpcCall(updateLicenseCatalog, $root.mtechnavi.api.license.UpdateLicenseCatalogRequest, $root.mtechnavi.api.license.LicenseCatalog, request, callback);
                }, "name", { value: "UpdateLicenseCatalog" });

                /**
                 * Calls UpdateLicenseCatalog.
                 * @function updateLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IUpdateLicenseCatalogRequest} request UpdateLicenseCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.LicenseCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#deleteLicenseCatalog}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef DeleteLicenseCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteLicenseCatalog.
                 * @function deleteLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IDeleteLicenseCatalogRequest} request DeleteLicenseCatalogRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.DeleteLicenseCatalogCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.deleteLicenseCatalog = function deleteLicenseCatalog(request, callback) {
                    return this.rpcCall(deleteLicenseCatalog, $root.mtechnavi.api.license.DeleteLicenseCatalogRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteLicenseCatalog" });

                /**
                 * Calls DeleteLicenseCatalog.
                 * @function deleteLicenseCatalog
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IDeleteLicenseCatalogRequest} request DeleteLicenseCatalogRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#listLicenses}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef ListLicensesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.ListLicensesResponse} [response] ListLicensesResponse
                 */

                /**
                 * Calls ListLicenses.
                 * @function listLicenses
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicensesRequest} request ListLicensesRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.ListLicensesCallback} callback Node-style callback called with the error, if any, and ListLicensesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.listLicenses = function listLicenses(request, callback) {
                    return this.rpcCall(listLicenses, $root.mtechnavi.api.license.ListLicensesRequest, $root.mtechnavi.api.license.ListLicensesResponse, request, callback);
                }, "name", { value: "ListLicenses" });

                /**
                 * Calls ListLicenses.
                 * @function listLicenses
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicensesRequest} request ListLicensesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.ListLicensesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#createLicense}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef CreateLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.License} [response] License
                 */

                /**
                 * Calls CreateLicense.
                 * @function createLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ICreateLicenseRequest} request CreateLicenseRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.CreateLicenseCallback} callback Node-style callback called with the error, if any, and License
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.createLicense = function createLicense(request, callback) {
                    return this.rpcCall(createLicense, $root.mtechnavi.api.license.CreateLicenseRequest, $root.mtechnavi.api.license.License, request, callback);
                }, "name", { value: "CreateLicense" });

                /**
                 * Calls CreateLicense.
                 * @function createLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ICreateLicenseRequest} request CreateLicenseRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.License>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#updateLicense}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef UpdateLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.License} [response] License
                 */

                /**
                 * Calls UpdateLicense.
                 * @function updateLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IUpdateLicenseRequest} request UpdateLicenseRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.UpdateLicenseCallback} callback Node-style callback called with the error, if any, and License
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.updateLicense = function updateLicense(request, callback) {
                    return this.rpcCall(updateLicense, $root.mtechnavi.api.license.UpdateLicenseRequest, $root.mtechnavi.api.license.License, request, callback);
                }, "name", { value: "UpdateLicense" });

                /**
                 * Calls UpdateLicense.
                 * @function updateLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IUpdateLicenseRequest} request UpdateLicenseRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.License>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.AdminLicenseService#deleteLicense}.
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @typedef DeleteLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteLicense.
                 * @function deleteLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IDeleteLicenseRequest} request DeleteLicenseRequest message or plain object
                 * @param {mtechnavi.api.license.AdminLicenseService.DeleteLicenseCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AdminLicenseService.prototype.deleteLicense = function deleteLicense(request, callback) {
                    return this.rpcCall(deleteLicense, $root.mtechnavi.api.license.DeleteLicenseRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteLicense" });

                /**
                 * Calls DeleteLicense.
                 * @function deleteLicense
                 * @memberof mtechnavi.api.license.AdminLicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IDeleteLicenseRequest} request DeleteLicenseRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return AdminLicenseService;
            })();

            license.LicenseService = (function() {

                /**
                 * Constructs a new LicenseService service.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a LicenseService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function LicenseService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (LicenseService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LicenseService;

                /**
                 * Callback as used by {@link mtechnavi.api.license.LicenseService#verifyLicense}.
                 * @memberof mtechnavi.api.license.LicenseService
                 * @typedef VerifyLicenseCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.VerifyLicenseResponse} [response] VerifyLicenseResponse
                 */

                /**
                 * Calls VerifyLicense.
                 * @function verifyLicense
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {mtechnavi.api.license.LicenseService.VerifyLicenseCallback} callback Node-style callback called with the error, if any, and VerifyLicenseResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(LicenseService.prototype.verifyLicense = function verifyLicense(request, callback) {
                    return this.rpcCall(verifyLicense, $root.google.protobuf.Empty, $root.mtechnavi.api.license.VerifyLicenseResponse, request, callback);
                }, "name", { value: "VerifyLicense" });

                /**
                 * Calls VerifyLicense.
                 * @function verifyLicense
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<mtechnavi.api.license.VerifyLicenseResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.LicenseService#listLicenseCatalogs}.
                 * @memberof mtechnavi.api.license.LicenseService
                 * @typedef ListLicenseCatalogsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.ListLicenseCatalogsResponse} [response] ListLicenseCatalogsResponse
                 */

                /**
                 * Calls ListLicenseCatalogs.
                 * @function listLicenseCatalogs
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicenseCatalogsRequest} request ListLicenseCatalogsRequest message or plain object
                 * @param {mtechnavi.api.license.LicenseService.ListLicenseCatalogsCallback} callback Node-style callback called with the error, if any, and ListLicenseCatalogsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(LicenseService.prototype.listLicenseCatalogs = function listLicenseCatalogs(request, callback) {
                    return this.rpcCall(listLicenseCatalogs, $root.mtechnavi.api.license.ListLicenseCatalogsRequest, $root.mtechnavi.api.license.ListLicenseCatalogsResponse, request, callback);
                }, "name", { value: "ListLicenseCatalogs" });

                /**
                 * Calls ListLicenseCatalogs.
                 * @function listLicenseCatalogs
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {mtechnavi.api.license.IListLicenseCatalogsRequest} request ListLicenseCatalogsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.ListLicenseCatalogsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.license.LicenseService#sharedListLicenses}.
                 * @memberof mtechnavi.api.license.LicenseService
                 * @typedef SharedListLicensesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.license.SharedListLicensesResponse} [response] SharedListLicensesResponse
                 */

                /**
                 * Calls SharedListLicenses.
                 * @function sharedListLicenses
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ISharedListLicensesRequest} request SharedListLicensesRequest message or plain object
                 * @param {mtechnavi.api.license.LicenseService.SharedListLicensesCallback} callback Node-style callback called with the error, if any, and SharedListLicensesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(LicenseService.prototype.sharedListLicenses = function sharedListLicenses(request, callback) {
                    return this.rpcCall(sharedListLicenses, $root.mtechnavi.api.license.SharedListLicensesRequest, $root.mtechnavi.api.license.SharedListLicensesResponse, request, callback);
                }, "name", { value: "SharedListLicenses" });

                /**
                 * Calls SharedListLicenses.
                 * @function sharedListLicenses
                 * @memberof mtechnavi.api.license.LicenseService
                 * @instance
                 * @param {mtechnavi.api.license.ISharedListLicensesRequest} request SharedListLicensesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.license.SharedListLicensesResponse>} Promise
                 * @variation 2
                 */

                return LicenseService;
            })();

            license.ListLicenseCatalogsRequest = (function() {

                /**
                 * Properties of a ListLicenseCatalogsRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IListLicenseCatalogsRequest
                 * @property {string|null} [pageToken] ListLicenseCatalogsRequest pageToken
                 * @property {Array.<string>|null} [licenseCodes] ListLicenseCatalogsRequest licenseCodes
                 */

                /**
                 * Constructs a new ListLicenseCatalogsRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a ListLicenseCatalogsRequest.
                 * @implements IListLicenseCatalogsRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IListLicenseCatalogsRequest=} [properties] Properties to set
                 */
                function ListLicenseCatalogsRequest(properties) {
                    this.licenseCodes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLicenseCatalogsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @instance
                 */
                ListLicenseCatalogsRequest.prototype.pageToken = "";

                /**
                 * ListLicenseCatalogsRequest licenseCodes.
                 * @member {Array.<string>} licenseCodes
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @instance
                 */
                ListLicenseCatalogsRequest.prototype.licenseCodes = $util.emptyArray;

                /**
                 * Verifies a ListLicenseCatalogsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLicenseCatalogsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.licenseCodes != null && message.hasOwnProperty("licenseCodes")) {
                        if (!Array.isArray(message.licenseCodes))
                            return "licenseCodes: array expected";
                        for (let i = 0; i < message.licenseCodes.length; ++i)
                            if (!$util.isString(message.licenseCodes[i]))
                                return "licenseCodes: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListLicenseCatalogsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.ListLicenseCatalogsRequest} ListLicenseCatalogsRequest
                 */
                ListLicenseCatalogsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.ListLicenseCatalogsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.ListLicenseCatalogsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.licenseCodes) {
                        if (!Array.isArray(object.licenseCodes))
                            throw TypeError(".mtechnavi.api.license.ListLicenseCatalogsRequest.licenseCodes: array expected");
                        message.licenseCodes = [];
                        for (let i = 0; i < object.licenseCodes.length; ++i)
                            message.licenseCodes[i] = String(object.licenseCodes[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListLicenseCatalogsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @static
                 * @param {mtechnavi.api.license.ListLicenseCatalogsRequest} message ListLicenseCatalogsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLicenseCatalogsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseCodes = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.licenseCodes && message.licenseCodes.length) {
                        object.licenseCodes = [];
                        for (let j = 0; j < message.licenseCodes.length; ++j)
                            object.licenseCodes[j] = message.licenseCodes[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListLicenseCatalogsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLicenseCatalogsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLicenseCatalogsRequest;
            })();

            license.ListLicenseCatalogsResponse = (function() {

                /**
                 * Properties of a ListLicenseCatalogsResponse.
                 * @memberof mtechnavi.api.license
                 * @interface IListLicenseCatalogsResponse
                 * @property {Long|null} [total] ListLicenseCatalogsResponse total
                 * @property {Array.<mtechnavi.api.license.ILicenseCatalog>|null} [items] ListLicenseCatalogsResponse items
                 * @property {string|null} [pageToken] ListLicenseCatalogsResponse pageToken
                 */

                /**
                 * Constructs a new ListLicenseCatalogsResponse.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a ListLicenseCatalogsResponse.
                 * @implements IListLicenseCatalogsResponse
                 * @constructor
                 * @param {mtechnavi.api.license.IListLicenseCatalogsResponse=} [properties] Properties to set
                 */
                function ListLicenseCatalogsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLicenseCatalogsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @instance
                 */
                ListLicenseCatalogsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLicenseCatalogsResponse items.
                 * @member {Array.<mtechnavi.api.license.ILicenseCatalog>} items
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @instance
                 */
                ListLicenseCatalogsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLicenseCatalogsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @instance
                 */
                ListLicenseCatalogsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLicenseCatalogsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLicenseCatalogsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.license.LicenseCatalog.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLicenseCatalogsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.ListLicenseCatalogsResponse} ListLicenseCatalogsResponse
                 */
                ListLicenseCatalogsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.ListLicenseCatalogsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.license.ListLicenseCatalogsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.license.ListLicenseCatalogsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.license.ListLicenseCatalogsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.license.LicenseCatalog.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLicenseCatalogsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @static
                 * @param {mtechnavi.api.license.ListLicenseCatalogsResponse} message ListLicenseCatalogsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLicenseCatalogsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.license.LicenseCatalog.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLicenseCatalogsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.ListLicenseCatalogsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLicenseCatalogsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLicenseCatalogsResponse;
            })();

            license.CreateLicenseCatalogRequest = (function() {

                /**
                 * Properties of a CreateLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @interface ICreateLicenseCatalogRequest
                 * @property {mtechnavi.api.license.ILicenseCatalog|null} [licenseCatalog] CreateLicenseCatalogRequest licenseCatalog
                 */

                /**
                 * Constructs a new CreateLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a CreateLicenseCatalogRequest.
                 * @implements ICreateLicenseCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.license.ICreateLicenseCatalogRequest=} [properties] Properties to set
                 */
                function CreateLicenseCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateLicenseCatalogRequest licenseCatalog.
                 * @member {mtechnavi.api.license.ILicenseCatalog|null|undefined} licenseCatalog
                 * @memberof mtechnavi.api.license.CreateLicenseCatalogRequest
                 * @instance
                 */
                CreateLicenseCatalogRequest.prototype.licenseCatalog = null;

                /**
                 * Verifies a CreateLicenseCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.CreateLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLicenseCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog")) {
                        let error = $root.mtechnavi.api.license.LicenseCatalog.verify(message.licenseCatalog);
                        if (error)
                            return "licenseCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateLicenseCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.CreateLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.CreateLicenseCatalogRequest} CreateLicenseCatalogRequest
                 */
                CreateLicenseCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.CreateLicenseCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.CreateLicenseCatalogRequest();
                    if (object.licenseCatalog != null) {
                        if (typeof object.licenseCatalog !== "object")
                            throw TypeError(".mtechnavi.api.license.CreateLicenseCatalogRequest.licenseCatalog: object expected");
                        message.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.fromObject(object.licenseCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateLicenseCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.CreateLicenseCatalogRequest
                 * @static
                 * @param {mtechnavi.api.license.CreateLicenseCatalogRequest} message CreateLicenseCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLicenseCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.licenseCatalog = null;
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog"))
                        object.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.toObject(message.licenseCatalog, options);
                    return object;
                };

                /**
                 * Converts this CreateLicenseCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.CreateLicenseCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLicenseCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateLicenseCatalogRequest;
            })();

            license.UpdateLicenseCatalogRequest = (function() {

                /**
                 * Properties of an UpdateLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IUpdateLicenseCatalogRequest
                 * @property {mtechnavi.api.license.ILicenseCatalog|null} [licenseCatalog] UpdateLicenseCatalogRequest licenseCatalog
                 */

                /**
                 * Constructs a new UpdateLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents an UpdateLicenseCatalogRequest.
                 * @implements IUpdateLicenseCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IUpdateLicenseCatalogRequest=} [properties] Properties to set
                 */
                function UpdateLicenseCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateLicenseCatalogRequest licenseCatalog.
                 * @member {mtechnavi.api.license.ILicenseCatalog|null|undefined} licenseCatalog
                 * @memberof mtechnavi.api.license.UpdateLicenseCatalogRequest
                 * @instance
                 */
                UpdateLicenseCatalogRequest.prototype.licenseCatalog = null;

                /**
                 * Verifies an UpdateLicenseCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.UpdateLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateLicenseCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog")) {
                        let error = $root.mtechnavi.api.license.LicenseCatalog.verify(message.licenseCatalog);
                        if (error)
                            return "licenseCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateLicenseCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.UpdateLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.UpdateLicenseCatalogRequest} UpdateLicenseCatalogRequest
                 */
                UpdateLicenseCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.UpdateLicenseCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.UpdateLicenseCatalogRequest();
                    if (object.licenseCatalog != null) {
                        if (typeof object.licenseCatalog !== "object")
                            throw TypeError(".mtechnavi.api.license.UpdateLicenseCatalogRequest.licenseCatalog: object expected");
                        message.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.fromObject(object.licenseCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateLicenseCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.UpdateLicenseCatalogRequest
                 * @static
                 * @param {mtechnavi.api.license.UpdateLicenseCatalogRequest} message UpdateLicenseCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateLicenseCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.licenseCatalog = null;
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog"))
                        object.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.toObject(message.licenseCatalog, options);
                    return object;
                };

                /**
                 * Converts this UpdateLicenseCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.UpdateLicenseCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateLicenseCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateLicenseCatalogRequest;
            })();

            license.DeleteLicenseCatalogRequest = (function() {

                /**
                 * Properties of a DeleteLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IDeleteLicenseCatalogRequest
                 * @property {mtechnavi.api.license.ILicenseCatalog|null} [licenseCatalog] DeleteLicenseCatalogRequest licenseCatalog
                 */

                /**
                 * Constructs a new DeleteLicenseCatalogRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a DeleteLicenseCatalogRequest.
                 * @implements IDeleteLicenseCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IDeleteLicenseCatalogRequest=} [properties] Properties to set
                 */
                function DeleteLicenseCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteLicenseCatalogRequest licenseCatalog.
                 * @member {mtechnavi.api.license.ILicenseCatalog|null|undefined} licenseCatalog
                 * @memberof mtechnavi.api.license.DeleteLicenseCatalogRequest
                 * @instance
                 */
                DeleteLicenseCatalogRequest.prototype.licenseCatalog = null;

                /**
                 * Verifies a DeleteLicenseCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.DeleteLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteLicenseCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog")) {
                        let error = $root.mtechnavi.api.license.LicenseCatalog.verify(message.licenseCatalog);
                        if (error)
                            return "licenseCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteLicenseCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.DeleteLicenseCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.DeleteLicenseCatalogRequest} DeleteLicenseCatalogRequest
                 */
                DeleteLicenseCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.DeleteLicenseCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.DeleteLicenseCatalogRequest();
                    if (object.licenseCatalog != null) {
                        if (typeof object.licenseCatalog !== "object")
                            throw TypeError(".mtechnavi.api.license.DeleteLicenseCatalogRequest.licenseCatalog: object expected");
                        message.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.fromObject(object.licenseCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteLicenseCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.DeleteLicenseCatalogRequest
                 * @static
                 * @param {mtechnavi.api.license.DeleteLicenseCatalogRequest} message DeleteLicenseCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteLicenseCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.licenseCatalog = null;
                    if (message.licenseCatalog != null && message.hasOwnProperty("licenseCatalog"))
                        object.licenseCatalog = $root.mtechnavi.api.license.LicenseCatalog.toObject(message.licenseCatalog, options);
                    return object;
                };

                /**
                 * Converts this DeleteLicenseCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.DeleteLicenseCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteLicenseCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteLicenseCatalogRequest;
            })();

            license.ListLicensesRequest = (function() {

                /**
                 * Properties of a ListLicensesRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IListLicensesRequest
                 * @property {string|null} [pageToken] ListLicensesRequest pageToken
                 * @property {Array.<string>|null} [licenseIds] ListLicensesRequest licenseIds
                 * @property {Array.<string>|null} [companyIds] ListLicensesRequest companyIds
                 */

                /**
                 * Constructs a new ListLicensesRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a ListLicensesRequest.
                 * @implements IListLicensesRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IListLicensesRequest=} [properties] Properties to set
                 */
                function ListLicensesRequest(properties) {
                    this.licenseIds = [];
                    this.companyIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLicensesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @instance
                 */
                ListLicensesRequest.prototype.pageToken = "";

                /**
                 * ListLicensesRequest licenseIds.
                 * @member {Array.<string>} licenseIds
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @instance
                 */
                ListLicensesRequest.prototype.licenseIds = $util.emptyArray;

                /**
                 * ListLicensesRequest companyIds.
                 * @member {Array.<string>} companyIds
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @instance
                 */
                ListLicensesRequest.prototype.companyIds = $util.emptyArray;

                /**
                 * Verifies a ListLicensesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLicensesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.licenseIds != null && message.hasOwnProperty("licenseIds")) {
                        if (!Array.isArray(message.licenseIds))
                            return "licenseIds: array expected";
                        for (let i = 0; i < message.licenseIds.length; ++i)
                            if (!$util.isString(message.licenseIds[i]))
                                return "licenseIds: string[] expected";
                    }
                    if (message.companyIds != null && message.hasOwnProperty("companyIds")) {
                        if (!Array.isArray(message.companyIds))
                            return "companyIds: array expected";
                        for (let i = 0; i < message.companyIds.length; ++i)
                            if (!$util.isString(message.companyIds[i]))
                                return "companyIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListLicensesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.ListLicensesRequest} ListLicensesRequest
                 */
                ListLicensesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.ListLicensesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.ListLicensesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.licenseIds) {
                        if (!Array.isArray(object.licenseIds))
                            throw TypeError(".mtechnavi.api.license.ListLicensesRequest.licenseIds: array expected");
                        message.licenseIds = [];
                        for (let i = 0; i < object.licenseIds.length; ++i)
                            message.licenseIds[i] = String(object.licenseIds[i]);
                    }
                    if (object.companyIds) {
                        if (!Array.isArray(object.companyIds))
                            throw TypeError(".mtechnavi.api.license.ListLicensesRequest.companyIds: array expected");
                        message.companyIds = [];
                        for (let i = 0; i < object.companyIds.length; ++i)
                            message.companyIds[i] = String(object.companyIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListLicensesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @static
                 * @param {mtechnavi.api.license.ListLicensesRequest} message ListLicensesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLicensesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.licenseIds = [];
                        object.companyIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.licenseIds && message.licenseIds.length) {
                        object.licenseIds = [];
                        for (let j = 0; j < message.licenseIds.length; ++j)
                            object.licenseIds[j] = message.licenseIds[j];
                    }
                    if (message.companyIds && message.companyIds.length) {
                        object.companyIds = [];
                        for (let j = 0; j < message.companyIds.length; ++j)
                            object.companyIds[j] = message.companyIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListLicensesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.ListLicensesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLicensesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLicensesRequest;
            })();

            license.ListLicensesResponse = (function() {

                /**
                 * Properties of a ListLicensesResponse.
                 * @memberof mtechnavi.api.license
                 * @interface IListLicensesResponse
                 * @property {Long|null} [total] ListLicensesResponse total
                 * @property {Array.<mtechnavi.api.license.ILicense>|null} [items] ListLicensesResponse items
                 * @property {string|null} [pageToken] ListLicensesResponse pageToken
                 */

                /**
                 * Constructs a new ListLicensesResponse.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a ListLicensesResponse.
                 * @implements IListLicensesResponse
                 * @constructor
                 * @param {mtechnavi.api.license.IListLicensesResponse=} [properties] Properties to set
                 */
                function ListLicensesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListLicensesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @instance
                 */
                ListLicensesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListLicensesResponse items.
                 * @member {Array.<mtechnavi.api.license.ILicense>} items
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @instance
                 */
                ListLicensesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListLicensesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @instance
                 */
                ListLicensesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListLicensesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListLicensesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.license.License.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListLicensesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.ListLicensesResponse} ListLicensesResponse
                 */
                ListLicensesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.ListLicensesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.license.ListLicensesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.license.ListLicensesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.license.ListLicensesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.license.License.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListLicensesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @static
                 * @param {mtechnavi.api.license.ListLicensesResponse} message ListLicensesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListLicensesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.license.License.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListLicensesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.ListLicensesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListLicensesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListLicensesResponse;
            })();

            license.CreateLicenseRequest = (function() {

                /**
                 * Properties of a CreateLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @interface ICreateLicenseRequest
                 * @property {mtechnavi.api.license.ILicense|null} [license] CreateLicenseRequest license
                 */

                /**
                 * Constructs a new CreateLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a CreateLicenseRequest.
                 * @implements ICreateLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.license.ICreateLicenseRequest=} [properties] Properties to set
                 */
                function CreateLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.license.CreateLicenseRequest
                 * @instance
                 */
                CreateLicenseRequest.prototype.license = null;

                /**
                 * Verifies a CreateLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.CreateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.CreateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.CreateLicenseRequest} CreateLicenseRequest
                 */
                CreateLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.CreateLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.CreateLicenseRequest();
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.license.CreateLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.CreateLicenseRequest
                 * @static
                 * @param {mtechnavi.api.license.CreateLicenseRequest} message CreateLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.license = null;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this CreateLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.CreateLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateLicenseRequest;
            })();

            license.UpdateLicenseRequest = (function() {

                /**
                 * Properties of an UpdateLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IUpdateLicenseRequest
                 * @property {mtechnavi.api.license.ILicense|null} [license] UpdateLicenseRequest license
                 */

                /**
                 * Constructs a new UpdateLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents an UpdateLicenseRequest.
                 * @implements IUpdateLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IUpdateLicenseRequest=} [properties] Properties to set
                 */
                function UpdateLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.license.UpdateLicenseRequest
                 * @instance
                 */
                UpdateLicenseRequest.prototype.license = null;

                /**
                 * Verifies an UpdateLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.UpdateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.UpdateLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.UpdateLicenseRequest} UpdateLicenseRequest
                 */
                UpdateLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.UpdateLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.UpdateLicenseRequest();
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.license.UpdateLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.UpdateLicenseRequest
                 * @static
                 * @param {mtechnavi.api.license.UpdateLicenseRequest} message UpdateLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.license = null;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this UpdateLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.UpdateLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateLicenseRequest;
            })();

            license.DeleteLicenseRequest = (function() {

                /**
                 * Properties of a DeleteLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @interface IDeleteLicenseRequest
                 * @property {mtechnavi.api.license.ILicense|null} [license] DeleteLicenseRequest license
                 */

                /**
                 * Constructs a new DeleteLicenseRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a DeleteLicenseRequest.
                 * @implements IDeleteLicenseRequest
                 * @constructor
                 * @param {mtechnavi.api.license.IDeleteLicenseRequest=} [properties] Properties to set
                 */
                function DeleteLicenseRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteLicenseRequest license.
                 * @member {mtechnavi.api.license.ILicense|null|undefined} license
                 * @memberof mtechnavi.api.license.DeleteLicenseRequest
                 * @instance
                 */
                DeleteLicenseRequest.prototype.license = null;

                /**
                 * Verifies a DeleteLicenseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.DeleteLicenseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteLicenseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.license != null && message.hasOwnProperty("license")) {
                        let error = $root.mtechnavi.api.license.License.verify(message.license);
                        if (error)
                            return "license." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteLicenseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.DeleteLicenseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.DeleteLicenseRequest} DeleteLicenseRequest
                 */
                DeleteLicenseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.DeleteLicenseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.DeleteLicenseRequest();
                    if (object.license != null) {
                        if (typeof object.license !== "object")
                            throw TypeError(".mtechnavi.api.license.DeleteLicenseRequest.license: object expected");
                        message.license = $root.mtechnavi.api.license.License.fromObject(object.license);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteLicenseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.DeleteLicenseRequest
                 * @static
                 * @param {mtechnavi.api.license.DeleteLicenseRequest} message DeleteLicenseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteLicenseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.license = null;
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = $root.mtechnavi.api.license.License.toObject(message.license, options);
                    return object;
                };

                /**
                 * Converts this DeleteLicenseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.DeleteLicenseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteLicenseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteLicenseRequest;
            })();

            license.SharedListLicensesRequest = (function() {

                /**
                 * Properties of a SharedListLicensesRequest.
                 * @memberof mtechnavi.api.license
                 * @interface ISharedListLicensesRequest
                 * @property {string|null} [pageToken] SharedListLicensesRequest pageToken
                 */

                /**
                 * Constructs a new SharedListLicensesRequest.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a SharedListLicensesRequest.
                 * @implements ISharedListLicensesRequest
                 * @constructor
                 * @param {mtechnavi.api.license.ISharedListLicensesRequest=} [properties] Properties to set
                 */
                function SharedListLicensesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListLicensesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.SharedListLicensesRequest
                 * @instance
                 */
                SharedListLicensesRequest.prototype.pageToken = "";

                /**
                 * Verifies a SharedListLicensesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.license.SharedListLicensesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListLicensesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListLicensesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.SharedListLicensesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.SharedListLicensesRequest} SharedListLicensesRequest
                 */
                SharedListLicensesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.SharedListLicensesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.license.SharedListLicensesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListLicensesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.SharedListLicensesRequest
                 * @static
                 * @param {mtechnavi.api.license.SharedListLicensesRequest} message SharedListLicensesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListLicensesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListLicensesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.SharedListLicensesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListLicensesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListLicensesRequest;
            })();

            license.SharedListLicensesResponse = (function() {

                /**
                 * Properties of a SharedListLicensesResponse.
                 * @memberof mtechnavi.api.license
                 * @interface ISharedListLicensesResponse
                 * @property {Long|null} [total] SharedListLicensesResponse total
                 * @property {Array.<mtechnavi.api.license.ILicense>|null} [items] SharedListLicensesResponse items
                 * @property {string|null} [pageToken] SharedListLicensesResponse pageToken
                 */

                /**
                 * Constructs a new SharedListLicensesResponse.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a SharedListLicensesResponse.
                 * @implements ISharedListLicensesResponse
                 * @constructor
                 * @param {mtechnavi.api.license.ISharedListLicensesResponse=} [properties] Properties to set
                 */
                function SharedListLicensesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListLicensesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @instance
                 */
                SharedListLicensesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListLicensesResponse items.
                 * @member {Array.<mtechnavi.api.license.ILicense>} items
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @instance
                 */
                SharedListLicensesResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListLicensesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @instance
                 */
                SharedListLicensesResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListLicensesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListLicensesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.license.License.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListLicensesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.SharedListLicensesResponse} SharedListLicensesResponse
                 */
                SharedListLicensesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.SharedListLicensesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.license.SharedListLicensesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.license.SharedListLicensesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.license.SharedListLicensesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.license.License.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListLicensesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @static
                 * @param {mtechnavi.api.license.SharedListLicensesResponse} message SharedListLicensesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListLicensesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.license.License.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListLicensesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.SharedListLicensesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListLicensesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListLicensesResponse;
            })();

            license.VerifyLicenseResponse = (function() {

                /**
                 * Properties of a VerifyLicenseResponse.
                 * @memberof mtechnavi.api.license
                 * @interface IVerifyLicenseResponse
                 * @property {sharelib.ILicenseLabelPermission|null} [licenseLabelPermission] VerifyLicenseResponse licenseLabelPermission
                 * @property {Array.<string>|null} [licenseCodes] VerifyLicenseResponse licenseCodes
                 */

                /**
                 * Constructs a new VerifyLicenseResponse.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a VerifyLicenseResponse.
                 * @implements IVerifyLicenseResponse
                 * @constructor
                 * @param {mtechnavi.api.license.IVerifyLicenseResponse=} [properties] Properties to set
                 */
                function VerifyLicenseResponse(properties) {
                    this.licenseCodes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyLicenseResponse licenseLabelPermission.
                 * @member {sharelib.ILicenseLabelPermission|null|undefined} licenseLabelPermission
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @instance
                 */
                VerifyLicenseResponse.prototype.licenseLabelPermission = null;

                /**
                 * VerifyLicenseResponse licenseCodes.
                 * @member {Array.<string>} licenseCodes
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @instance
                 */
                VerifyLicenseResponse.prototype.licenseCodes = $util.emptyArray;

                /**
                 * Verifies a VerifyLicenseResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyLicenseResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseLabelPermission != null && message.hasOwnProperty("licenseLabelPermission")) {
                        let error = $root.sharelib.LicenseLabelPermission.verify(message.licenseLabelPermission);
                        if (error)
                            return "licenseLabelPermission." + error;
                    }
                    if (message.licenseCodes != null && message.hasOwnProperty("licenseCodes")) {
                        if (!Array.isArray(message.licenseCodes))
                            return "licenseCodes: array expected";
                        for (let i = 0; i < message.licenseCodes.length; ++i)
                            if (!$util.isString(message.licenseCodes[i]))
                                return "licenseCodes: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a VerifyLicenseResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.VerifyLicenseResponse} VerifyLicenseResponse
                 */
                VerifyLicenseResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.VerifyLicenseResponse)
                        return object;
                    let message = new $root.mtechnavi.api.license.VerifyLicenseResponse();
                    if (object.licenseLabelPermission != null) {
                        if (typeof object.licenseLabelPermission !== "object")
                            throw TypeError(".mtechnavi.api.license.VerifyLicenseResponse.licenseLabelPermission: object expected");
                        message.licenseLabelPermission = $root.sharelib.LicenseLabelPermission.fromObject(object.licenseLabelPermission);
                    }
                    if (object.licenseCodes) {
                        if (!Array.isArray(object.licenseCodes))
                            throw TypeError(".mtechnavi.api.license.VerifyLicenseResponse.licenseCodes: array expected");
                        message.licenseCodes = [];
                        for (let i = 0; i < object.licenseCodes.length; ++i)
                            message.licenseCodes[i] = String(object.licenseCodes[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyLicenseResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @static
                 * @param {mtechnavi.api.license.VerifyLicenseResponse} message VerifyLicenseResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyLicenseResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseCodes = [];
                    if (options.defaults)
                        object.licenseLabelPermission = null;
                    if (message.licenseLabelPermission != null && message.hasOwnProperty("licenseLabelPermission"))
                        object.licenseLabelPermission = $root.sharelib.LicenseLabelPermission.toObject(message.licenseLabelPermission, options);
                    if (message.licenseCodes && message.licenseCodes.length) {
                        object.licenseCodes = [];
                        for (let j = 0; j < message.licenseCodes.length; ++j)
                            object.licenseCodes[j] = message.licenseCodes[j];
                    }
                    return object;
                };

                /**
                 * Converts this VerifyLicenseResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.VerifyLicenseResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyLicenseResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyLicenseResponse;
            })();

            license.LicenseCatalog = (function() {

                /**
                 * Properties of a LicenseCatalog.
                 * @memberof mtechnavi.api.license
                 * @interface ILicenseCatalog
                 * @property {string|null} [licenseCatalogId] LicenseCatalog licenseCatalogId
                 * @property {string|null} [licenseCode] LicenseCatalog licenseCode
                 * @property {string|null} [displayName] LicenseCatalog displayName
                 * @property {Array.<string>|null} [licenseLabels] LicenseCatalog licenseLabels
                 * @property {Long|null} [validityPeriod] LicenseCatalog validityPeriod
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] LicenseCatalog updatedProperties
                 * @property {Long|null} [createdAt] LicenseCatalog createdAt
                 * @property {Long|null} [updatedAt] LicenseCatalog updatedAt
                 * @property {Long|null} [deletedAt] LicenseCatalog deletedAt
                 */

                /**
                 * Constructs a new LicenseCatalog.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a LicenseCatalog.
                 * @implements ILicenseCatalog
                 * @constructor
                 * @param {mtechnavi.api.license.ILicenseCatalog=} [properties] Properties to set
                 */
                function LicenseCatalog(properties) {
                    this.licenseLabels = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LicenseCatalog licenseCatalogId.
                 * @member {string} licenseCatalogId
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.licenseCatalogId = "";

                /**
                 * LicenseCatalog licenseCode.
                 * @member {string} licenseCode
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.licenseCode = "";

                /**
                 * LicenseCatalog displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.displayName = "";

                /**
                 * LicenseCatalog licenseLabels.
                 * @member {Array.<string>} licenseLabels
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.licenseLabels = $util.emptyArray;

                /**
                 * LicenseCatalog validityPeriod.
                 * @member {Long} validityPeriod
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.validityPeriod = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LicenseCatalog updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.updatedProperties = null;

                /**
                 * LicenseCatalog createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LicenseCatalog updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * LicenseCatalog deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 */
                LicenseCatalog.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a LicenseCatalog message.
                 * @function verify
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LicenseCatalog.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseCatalogId != null && message.hasOwnProperty("licenseCatalogId"))
                        if (!$util.isString(message.licenseCatalogId))
                            return "licenseCatalogId: string expected";
                    if (message.licenseCode != null && message.hasOwnProperty("licenseCode"))
                        if (!$util.isString(message.licenseCode))
                            return "licenseCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.licenseLabels != null && message.hasOwnProperty("licenseLabels")) {
                        if (!Array.isArray(message.licenseLabels))
                            return "licenseLabels: array expected";
                        for (let i = 0; i < message.licenseLabels.length; ++i)
                            if (!$util.isString(message.licenseLabels[i]))
                                return "licenseLabels: string[] expected";
                    }
                    if (message.validityPeriod != null && message.hasOwnProperty("validityPeriod"))
                        if (!$util.isInteger(message.validityPeriod) && !(message.validityPeriod && $util.isInteger(message.validityPeriod.low) && $util.isInteger(message.validityPeriod.high)))
                            return "validityPeriod: integer|Long expected";
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LicenseCatalog message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.LicenseCatalog} LicenseCatalog
                 */
                LicenseCatalog.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.LicenseCatalog)
                        return object;
                    let message = new $root.mtechnavi.api.license.LicenseCatalog();
                    if (object.licenseCatalogId != null)
                        message.licenseCatalogId = String(object.licenseCatalogId);
                    if (object.licenseCode != null)
                        message.licenseCode = String(object.licenseCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.licenseLabels) {
                        if (!Array.isArray(object.licenseLabels))
                            throw TypeError(".mtechnavi.api.license.LicenseCatalog.licenseLabels: array expected");
                        message.licenseLabels = [];
                        for (let i = 0; i < object.licenseLabels.length; ++i)
                            message.licenseLabels[i] = String(object.licenseLabels[i]);
                    }
                    if (object.validityPeriod != null)
                        if ($util.Long)
                            (message.validityPeriod = $util.Long.fromValue(object.validityPeriod)).unsigned = false;
                        else if (typeof object.validityPeriod === "string")
                            message.validityPeriod = parseInt(object.validityPeriod, 10);
                        else if (typeof object.validityPeriod === "number")
                            message.validityPeriod = object.validityPeriod;
                        else if (typeof object.validityPeriod === "object")
                            message.validityPeriod = new $util.LongBits(object.validityPeriod.low >>> 0, object.validityPeriod.high >>> 0).toNumber();
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.license.LicenseCatalog.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LicenseCatalog message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @static
                 * @param {mtechnavi.api.license.LicenseCatalog} message LicenseCatalog
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LicenseCatalog.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseLabels = [];
                    if (options.defaults) {
                        object.licenseCatalogId = "";
                        object.licenseCode = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.validityPeriod = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.validityPeriod = options.longs === String ? "0" : 0;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.licenseCatalogId != null && message.hasOwnProperty("licenseCatalogId"))
                        object.licenseCatalogId = message.licenseCatalogId;
                    if (message.licenseCode != null && message.hasOwnProperty("licenseCode"))
                        object.licenseCode = message.licenseCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.licenseLabels && message.licenseLabels.length) {
                        object.licenseLabels = [];
                        for (let j = 0; j < message.licenseLabels.length; ++j)
                            object.licenseLabels[j] = message.licenseLabels[j];
                    }
                    if (message.validityPeriod != null && message.hasOwnProperty("validityPeriod"))
                        if (typeof message.validityPeriod === "number")
                            object.validityPeriod = options.longs === String ? String(message.validityPeriod) : message.validityPeriod;
                        else
                            object.validityPeriod = options.longs === String ? $util.Long.prototype.toString.call(message.validityPeriod) : options.longs === Number ? new $util.LongBits(message.validityPeriod.low >>> 0, message.validityPeriod.high >>> 0).toNumber() : message.validityPeriod;
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this LicenseCatalog to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.LicenseCatalog
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LicenseCatalog.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LicenseCatalog;
            })();

            license.License = (function() {

                /**
                 * Properties of a License.
                 * @memberof mtechnavi.api.license
                 * @interface ILicense
                 * @property {string|null} [licenseId] License licenseId
                 * @property {string|null} [licenseCatalogId] License licenseCatalogId
                 * @property {string|null} [licenseCode] License licenseCode
                 * @property {string|null} [displayName] License displayName
                 * @property {string|null} [companyId] License companyId
                 * @property {Long|null} [startAt] License startAt
                 * @property {Long|null} [endAt] License endAt
                 * @property {boolean|null} [withoutEnd] License withoutEnd
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] License updatedProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] License sharedProperties
                 * @property {Long|null} [createdAt] License createdAt
                 * @property {Long|null} [updatedAt] License updatedAt
                 * @property {Long|null} [deletedAt] License deletedAt
                 */

                /**
                 * Constructs a new License.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a License.
                 * @implements ILicense
                 * @constructor
                 * @param {mtechnavi.api.license.ILicense=} [properties] Properties to set
                 */
                function License(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * License licenseId.
                 * @member {string} licenseId
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.licenseId = "";

                /**
                 * License licenseCatalogId.
                 * @member {string} licenseCatalogId
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.licenseCatalogId = "";

                /**
                 * License licenseCode.
                 * @member {string} licenseCode
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.licenseCode = "";

                /**
                 * License displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.displayName = "";

                /**
                 * License companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.companyId = "";

                /**
                 * License startAt.
                 * @member {Long} startAt
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.startAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * License endAt.
                 * @member {Long} endAt
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.endAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * License withoutEnd.
                 * @member {boolean} withoutEnd
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.withoutEnd = false;

                /**
                 * License updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.updatedProperties = null;

                /**
                 * License sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.sharedProperties = null;

                /**
                 * License createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * License updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * License deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 */
                License.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a License message.
                 * @function verify
                 * @memberof mtechnavi.api.license.License
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                License.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseId != null && message.hasOwnProperty("licenseId"))
                        if (!$util.isString(message.licenseId))
                            return "licenseId: string expected";
                    if (message.licenseCatalogId != null && message.hasOwnProperty("licenseCatalogId"))
                        if (!$util.isString(message.licenseCatalogId))
                            return "licenseCatalogId: string expected";
                    if (message.licenseCode != null && message.hasOwnProperty("licenseCode"))
                        if (!$util.isString(message.licenseCode))
                            return "licenseCode: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        if (!$util.isInteger(message.startAt) && !(message.startAt && $util.isInteger(message.startAt.low) && $util.isInteger(message.startAt.high)))
                            return "startAt: integer|Long expected";
                    if (message.endAt != null && message.hasOwnProperty("endAt"))
                        if (!$util.isInteger(message.endAt) && !(message.endAt && $util.isInteger(message.endAt.low) && $util.isInteger(message.endAt.high)))
                            return "endAt: integer|Long expected";
                    if (message.withoutEnd != null && message.hasOwnProperty("withoutEnd"))
                        if (typeof message.withoutEnd !== "boolean")
                            return "withoutEnd: boolean expected";
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a License message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.License
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.License} License
                 */
                License.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.License)
                        return object;
                    let message = new $root.mtechnavi.api.license.License();
                    if (object.licenseId != null)
                        message.licenseId = String(object.licenseId);
                    if (object.licenseCatalogId != null)
                        message.licenseCatalogId = String(object.licenseCatalogId);
                    if (object.licenseCode != null)
                        message.licenseCode = String(object.licenseCode);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.startAt != null)
                        if ($util.Long)
                            (message.startAt = $util.Long.fromValue(object.startAt)).unsigned = false;
                        else if (typeof object.startAt === "string")
                            message.startAt = parseInt(object.startAt, 10);
                        else if (typeof object.startAt === "number")
                            message.startAt = object.startAt;
                        else if (typeof object.startAt === "object")
                            message.startAt = new $util.LongBits(object.startAt.low >>> 0, object.startAt.high >>> 0).toNumber();
                    if (object.endAt != null)
                        if ($util.Long)
                            (message.endAt = $util.Long.fromValue(object.endAt)).unsigned = false;
                        else if (typeof object.endAt === "string")
                            message.endAt = parseInt(object.endAt, 10);
                        else if (typeof object.endAt === "number")
                            message.endAt = object.endAt;
                        else if (typeof object.endAt === "object")
                            message.endAt = new $util.LongBits(object.endAt.low >>> 0, object.endAt.high >>> 0).toNumber();
                    if (object.withoutEnd != null)
                        message.withoutEnd = Boolean(object.withoutEnd);
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.license.License.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.license.License.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a License message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.License
                 * @static
                 * @param {mtechnavi.api.license.License} message License
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                License.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.licenseId = "";
                        object.licenseCatalogId = "";
                        object.licenseCode = "";
                        object.displayName = "";
                        object.companyId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.startAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.endAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endAt = options.longs === String ? "0" : 0;
                        object.withoutEnd = false;
                        object.updatedProperties = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.licenseId != null && message.hasOwnProperty("licenseId"))
                        object.licenseId = message.licenseId;
                    if (message.licenseCatalogId != null && message.hasOwnProperty("licenseCatalogId"))
                        object.licenseCatalogId = message.licenseCatalogId;
                    if (message.licenseCode != null && message.hasOwnProperty("licenseCode"))
                        object.licenseCode = message.licenseCode;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        if (typeof message.startAt === "number")
                            object.startAt = options.longs === String ? String(message.startAt) : message.startAt;
                        else
                            object.startAt = options.longs === String ? $util.Long.prototype.toString.call(message.startAt) : options.longs === Number ? new $util.LongBits(message.startAt.low >>> 0, message.startAt.high >>> 0).toNumber() : message.startAt;
                    if (message.endAt != null && message.hasOwnProperty("endAt"))
                        if (typeof message.endAt === "number")
                            object.endAt = options.longs === String ? String(message.endAt) : message.endAt;
                        else
                            object.endAt = options.longs === String ? $util.Long.prototype.toString.call(message.endAt) : options.longs === Number ? new $util.LongBits(message.endAt.low >>> 0, message.endAt.high >>> 0).toNumber() : message.endAt;
                    if (message.withoutEnd != null && message.hasOwnProperty("withoutEnd"))
                        object.withoutEnd = message.withoutEnd;
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this License to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.License
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                License.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return License;
            })();

            license.LicenseLabel = (function() {

                /**
                 * Properties of a LicenseLabel.
                 * @memberof mtechnavi.api.license
                 * @interface ILicenseLabel
                 * @property {string|null} [name] LicenseLabel name
                 * @property {sharelib.ILicenseLabelPermission|null} [whitelist] LicenseLabel whitelist
                 * @property {sharelib.ILicenseLabelPermission|null} [blacklist] LicenseLabel blacklist
                 */

                /**
                 * Constructs a new LicenseLabel.
                 * @memberof mtechnavi.api.license
                 * @classdesc Represents a LicenseLabel.
                 * @implements ILicenseLabel
                 * @constructor
                 * @param {mtechnavi.api.license.ILicenseLabel=} [properties] Properties to set
                 */
                function LicenseLabel(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LicenseLabel name.
                 * @member {string} name
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @instance
                 */
                LicenseLabel.prototype.name = "";

                /**
                 * LicenseLabel whitelist.
                 * @member {sharelib.ILicenseLabelPermission|null|undefined} whitelist
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @instance
                 */
                LicenseLabel.prototype.whitelist = null;

                /**
                 * LicenseLabel blacklist.
                 * @member {sharelib.ILicenseLabelPermission|null|undefined} blacklist
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @instance
                 */
                LicenseLabel.prototype.blacklist = null;

                /**
                 * Verifies a LicenseLabel message.
                 * @function verify
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LicenseLabel.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.whitelist != null && message.hasOwnProperty("whitelist")) {
                        let error = $root.sharelib.LicenseLabelPermission.verify(message.whitelist);
                        if (error)
                            return "whitelist." + error;
                    }
                    if (message.blacklist != null && message.hasOwnProperty("blacklist")) {
                        let error = $root.sharelib.LicenseLabelPermission.verify(message.blacklist);
                        if (error)
                            return "blacklist." + error;
                    }
                    return null;
                };

                /**
                 * Creates a LicenseLabel message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.license.LicenseLabel} LicenseLabel
                 */
                LicenseLabel.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.license.LicenseLabel)
                        return object;
                    let message = new $root.mtechnavi.api.license.LicenseLabel();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.whitelist != null) {
                        if (typeof object.whitelist !== "object")
                            throw TypeError(".mtechnavi.api.license.LicenseLabel.whitelist: object expected");
                        message.whitelist = $root.sharelib.LicenseLabelPermission.fromObject(object.whitelist);
                    }
                    if (object.blacklist != null) {
                        if (typeof object.blacklist !== "object")
                            throw TypeError(".mtechnavi.api.license.LicenseLabel.blacklist: object expected");
                        message.blacklist = $root.sharelib.LicenseLabelPermission.fromObject(object.blacklist);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LicenseLabel message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @static
                 * @param {mtechnavi.api.license.LicenseLabel} message LicenseLabel
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LicenseLabel.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.whitelist = null;
                        object.blacklist = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.whitelist != null && message.hasOwnProperty("whitelist"))
                        object.whitelist = $root.sharelib.LicenseLabelPermission.toObject(message.whitelist, options);
                    if (message.blacklist != null && message.hasOwnProperty("blacklist"))
                        object.blacklist = $root.sharelib.LicenseLabelPermission.toObject(message.blacklist, options);
                    return object;
                };

                /**
                 * Converts this LicenseLabel to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.license.LicenseLabel
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LicenseLabel.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LicenseLabel;
            })();

            return license;
        })();

        api.notification = (function() {

            /**
             * Namespace notification.
             * @memberof mtechnavi.api
             * @namespace
             */
            const notification = {};

            notification.Notification = (function() {

                /**
                 * Constructs a new Notification service.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a Notification
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function Notification(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (Notification.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Notification;

                /**
                 * Callback as used by {@link mtechnavi.api.notification.Notification#notify}.
                 * @memberof mtechnavi.api.notification.Notification
                 * @typedef NotifyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.notification.NotifyResponse} [response] NotifyResponse
                 */

                /**
                 * Calls Notify.
                 * @function notify
                 * @memberof mtechnavi.api.notification.Notification
                 * @instance
                 * @param {mtechnavi.api.notification.INotifyRequest} request NotifyRequest message or plain object
                 * @param {mtechnavi.api.notification.Notification.NotifyCallback} callback Node-style callback called with the error, if any, and NotifyResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Notification.prototype.notify = function notify(request, callback) {
                    return this.rpcCall(notify, $root.mtechnavi.api.notification.NotifyRequest, $root.mtechnavi.api.notification.NotifyResponse, request, callback);
                }, "name", { value: "Notify" });

                /**
                 * Calls Notify.
                 * @function notify
                 * @memberof mtechnavi.api.notification.Notification
                 * @instance
                 * @param {mtechnavi.api.notification.INotifyRequest} request NotifyRequest message or plain object
                 * @returns {Promise<mtechnavi.api.notification.NotifyResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.notification.Notification#listenChannel}.
                 * @memberof mtechnavi.api.notification.Notification
                 * @typedef ListenChannelCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.notification.ListenChannelResponse} [response] ListenChannelResponse
                 */

                /**
                 * Calls ListenChannel.
                 * @function listenChannel
                 * @memberof mtechnavi.api.notification.Notification
                 * @instance
                 * @param {mtechnavi.api.notification.IListenChannelRequest} request ListenChannelRequest message or plain object
                 * @param {mtechnavi.api.notification.Notification.ListenChannelCallback} callback Node-style callback called with the error, if any, and ListenChannelResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(Notification.prototype.listenChannel = function listenChannel(request, callback) {
                    return this.rpcCall(listenChannel, $root.mtechnavi.api.notification.ListenChannelRequest, $root.mtechnavi.api.notification.ListenChannelResponse, request, callback);
                }, "name", { value: "ListenChannel" });

                /**
                 * Calls ListenChannel.
                 * @function listenChannel
                 * @memberof mtechnavi.api.notification.Notification
                 * @instance
                 * @param {mtechnavi.api.notification.IListenChannelRequest} request ListenChannelRequest message or plain object
                 * @returns {Promise<mtechnavi.api.notification.ListenChannelResponse>} Promise
                 * @variation 2
                 */

                return Notification;
            })();

            notification.NotifyRequest = (function() {

                /**
                 * Properties of a NotifyRequest.
                 * @memberof mtechnavi.api.notification
                 * @interface INotifyRequest
                 * @property {mtechnavi.api.notification.INotifyEmailRequest|null} [email] NotifyRequest email
                 * @property {mtechnavi.api.notification.INotifyChannelRequest|null} [channel] NotifyRequest channel
                 */

                /**
                 * Constructs a new NotifyRequest.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a NotifyRequest.
                 * @implements INotifyRequest
                 * @constructor
                 * @param {mtechnavi.api.notification.INotifyRequest=} [properties] Properties to set
                 */
                function NotifyRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NotifyRequest email.
                 * @member {mtechnavi.api.notification.INotifyEmailRequest|null|undefined} email
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @instance
                 */
                NotifyRequest.prototype.email = null;

                /**
                 * NotifyRequest channel.
                 * @member {mtechnavi.api.notification.INotifyChannelRequest|null|undefined} channel
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @instance
                 */
                NotifyRequest.prototype.channel = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * NotifyRequest request.
                 * @member {"email"|"channel"|undefined} request
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @instance
                 */
                Object.defineProperty(NotifyRequest.prototype, "request", {
                    get: $util.oneOfGetter($oneOfFields = ["email", "channel"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Verifies a NotifyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotifyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.email != null && message.hasOwnProperty("email")) {
                        properties.request = 1;
                        {
                            let error = $root.mtechnavi.api.notification.NotifyEmailRequest.verify(message.email);
                            if (error)
                                return "email." + error;
                        }
                    }
                    if (message.channel != null && message.hasOwnProperty("channel")) {
                        if (properties.request === 1)
                            return "request: multiple values";
                        properties.request = 1;
                        {
                            let error = $root.mtechnavi.api.notification.NotifyChannelRequest.verify(message.channel);
                            if (error)
                                return "channel." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a NotifyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.NotifyRequest} NotifyRequest
                 */
                NotifyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.NotifyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.notification.NotifyRequest();
                    if (object.email != null) {
                        if (typeof object.email !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyRequest.email: object expected");
                        message.email = $root.mtechnavi.api.notification.NotifyEmailRequest.fromObject(object.email);
                    }
                    if (object.channel != null) {
                        if (typeof object.channel !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyRequest.channel: object expected");
                        message.channel = $root.mtechnavi.api.notification.NotifyChannelRequest.fromObject(object.channel);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a NotifyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @static
                 * @param {mtechnavi.api.notification.NotifyRequest} message NotifyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotifyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (message.email != null && message.hasOwnProperty("email")) {
                        object.email = $root.mtechnavi.api.notification.NotifyEmailRequest.toObject(message.email, options);
                        if (options.oneofs)
                            object.request = "email";
                    }
                    if (message.channel != null && message.hasOwnProperty("channel")) {
                        object.channel = $root.mtechnavi.api.notification.NotifyChannelRequest.toObject(message.channel, options);
                        if (options.oneofs)
                            object.request = "channel";
                    }
                    return object;
                };

                /**
                 * Converts this NotifyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.NotifyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotifyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NotifyRequest;
            })();

            notification.PasswordResetParameter = (function() {

                /**
                 * Properties of a PasswordResetParameter.
                 * @memberof mtechnavi.api.notification
                 * @interface IPasswordResetParameter
                 * @property {string|null} [resetUrl] PasswordResetParameter resetUrl
                 * @property {string|null} [resetUrlExpiredAt] PasswordResetParameter resetUrlExpiredAt
                 */

                /**
                 * Constructs a new PasswordResetParameter.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a PasswordResetParameter.
                 * @implements IPasswordResetParameter
                 * @constructor
                 * @param {mtechnavi.api.notification.IPasswordResetParameter=} [properties] Properties to set
                 */
                function PasswordResetParameter(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PasswordResetParameter resetUrl.
                 * @member {string} resetUrl
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @instance
                 */
                PasswordResetParameter.prototype.resetUrl = "";

                /**
                 * PasswordResetParameter resetUrlExpiredAt.
                 * @member {string} resetUrlExpiredAt
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @instance
                 */
                PasswordResetParameter.prototype.resetUrlExpiredAt = "";

                /**
                 * Verifies a PasswordResetParameter message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PasswordResetParameter.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.resetUrl != null && message.hasOwnProperty("resetUrl"))
                        if (!$util.isString(message.resetUrl))
                            return "resetUrl: string expected";
                    if (message.resetUrlExpiredAt != null && message.hasOwnProperty("resetUrlExpiredAt"))
                        if (!$util.isString(message.resetUrlExpiredAt))
                            return "resetUrlExpiredAt: string expected";
                    return null;
                };

                /**
                 * Creates a PasswordResetParameter message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.PasswordResetParameter} PasswordResetParameter
                 */
                PasswordResetParameter.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.PasswordResetParameter)
                        return object;
                    let message = new $root.mtechnavi.api.notification.PasswordResetParameter();
                    if (object.resetUrl != null)
                        message.resetUrl = String(object.resetUrl);
                    if (object.resetUrlExpiredAt != null)
                        message.resetUrlExpiredAt = String(object.resetUrlExpiredAt);
                    return message;
                };

                /**
                 * Creates a plain object from a PasswordResetParameter message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @static
                 * @param {mtechnavi.api.notification.PasswordResetParameter} message PasswordResetParameter
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PasswordResetParameter.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.resetUrl = "";
                        object.resetUrlExpiredAt = "";
                    }
                    if (message.resetUrl != null && message.hasOwnProperty("resetUrl"))
                        object.resetUrl = message.resetUrl;
                    if (message.resetUrlExpiredAt != null && message.hasOwnProperty("resetUrlExpiredAt"))
                        object.resetUrlExpiredAt = message.resetUrlExpiredAt;
                    return object;
                };

                /**
                 * Converts this PasswordResetParameter to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.PasswordResetParameter
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PasswordResetParameter.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PasswordResetParameter;
            })();

            notification.SendHeader = (function() {

                /**
                 * Properties of a SendHeader.
                 * @memberof mtechnavi.api.notification
                 * @interface ISendHeader
                 * @property {Array.<string>|null} [to] SendHeader to
                 * @property {Array.<string>|null} [cc] SendHeader cc
                 * @property {Array.<string>|null} [bcc] SendHeader bcc
                 * @property {Long|null} [sendAt] SendHeader sendAt
                 */

                /**
                 * Constructs a new SendHeader.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a SendHeader.
                 * @implements ISendHeader
                 * @constructor
                 * @param {mtechnavi.api.notification.ISendHeader=} [properties] Properties to set
                 */
                function SendHeader(properties) {
                    this.to = [];
                    this.cc = [];
                    this.bcc = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendHeader to.
                 * @member {Array.<string>} to
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @instance
                 */
                SendHeader.prototype.to = $util.emptyArray;

                /**
                 * SendHeader cc.
                 * @member {Array.<string>} cc
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @instance
                 */
                SendHeader.prototype.cc = $util.emptyArray;

                /**
                 * SendHeader bcc.
                 * @member {Array.<string>} bcc
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @instance
                 */
                SendHeader.prototype.bcc = $util.emptyArray;

                /**
                 * SendHeader sendAt.
                 * @member {Long} sendAt
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @instance
                 */
                SendHeader.prototype.sendAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SendHeader message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendHeader.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.to != null && message.hasOwnProperty("to")) {
                        if (!Array.isArray(message.to))
                            return "to: array expected";
                        for (let i = 0; i < message.to.length; ++i)
                            if (!$util.isString(message.to[i]))
                                return "to: string[] expected";
                    }
                    if (message.cc != null && message.hasOwnProperty("cc")) {
                        if (!Array.isArray(message.cc))
                            return "cc: array expected";
                        for (let i = 0; i < message.cc.length; ++i)
                            if (!$util.isString(message.cc[i]))
                                return "cc: string[] expected";
                    }
                    if (message.bcc != null && message.hasOwnProperty("bcc")) {
                        if (!Array.isArray(message.bcc))
                            return "bcc: array expected";
                        for (let i = 0; i < message.bcc.length; ++i)
                            if (!$util.isString(message.bcc[i]))
                                return "bcc: string[] expected";
                    }
                    if (message.sendAt != null && message.hasOwnProperty("sendAt"))
                        if (!$util.isInteger(message.sendAt) && !(message.sendAt && $util.isInteger(message.sendAt.low) && $util.isInteger(message.sendAt.high)))
                            return "sendAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SendHeader message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.SendHeader} SendHeader
                 */
                SendHeader.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.SendHeader)
                        return object;
                    let message = new $root.mtechnavi.api.notification.SendHeader();
                    if (object.to) {
                        if (!Array.isArray(object.to))
                            throw TypeError(".mtechnavi.api.notification.SendHeader.to: array expected");
                        message.to = [];
                        for (let i = 0; i < object.to.length; ++i)
                            message.to[i] = String(object.to[i]);
                    }
                    if (object.cc) {
                        if (!Array.isArray(object.cc))
                            throw TypeError(".mtechnavi.api.notification.SendHeader.cc: array expected");
                        message.cc = [];
                        for (let i = 0; i < object.cc.length; ++i)
                            message.cc[i] = String(object.cc[i]);
                    }
                    if (object.bcc) {
                        if (!Array.isArray(object.bcc))
                            throw TypeError(".mtechnavi.api.notification.SendHeader.bcc: array expected");
                        message.bcc = [];
                        for (let i = 0; i < object.bcc.length; ++i)
                            message.bcc[i] = String(object.bcc[i]);
                    }
                    if (object.sendAt != null)
                        if ($util.Long)
                            (message.sendAt = $util.Long.fromValue(object.sendAt)).unsigned = false;
                        else if (typeof object.sendAt === "string")
                            message.sendAt = parseInt(object.sendAt, 10);
                        else if (typeof object.sendAt === "number")
                            message.sendAt = object.sendAt;
                        else if (typeof object.sendAt === "object")
                            message.sendAt = new $util.LongBits(object.sendAt.low >>> 0, object.sendAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SendHeader message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @static
                 * @param {mtechnavi.api.notification.SendHeader} message SendHeader
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendHeader.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.to = [];
                        object.cc = [];
                        object.bcc = [];
                    }
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendAt = options.longs === String ? "0" : 0;
                    if (message.to && message.to.length) {
                        object.to = [];
                        for (let j = 0; j < message.to.length; ++j)
                            object.to[j] = message.to[j];
                    }
                    if (message.cc && message.cc.length) {
                        object.cc = [];
                        for (let j = 0; j < message.cc.length; ++j)
                            object.cc[j] = message.cc[j];
                    }
                    if (message.bcc && message.bcc.length) {
                        object.bcc = [];
                        for (let j = 0; j < message.bcc.length; ++j)
                            object.bcc[j] = message.bcc[j];
                    }
                    if (message.sendAt != null && message.hasOwnProperty("sendAt"))
                        if (typeof message.sendAt === "number")
                            object.sendAt = options.longs === String ? String(message.sendAt) : message.sendAt;
                        else
                            object.sendAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendAt) : options.longs === Number ? new $util.LongBits(message.sendAt.low >>> 0, message.sendAt.high >>> 0).toNumber() : message.sendAt;
                    return object;
                };

                /**
                 * Converts this SendHeader to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.SendHeader
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendHeader.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendHeader;
            })();

            notification.NotifyEmailRequest = (function() {

                /**
                 * Properties of a NotifyEmailRequest.
                 * @memberof mtechnavi.api.notification
                 * @interface INotifyEmailRequest
                 * @property {string|null} [emailId] NotifyEmailRequest emailId
                 * @property {mtechnavi.api.notification.ISendHeader|null} [header] NotifyEmailRequest header
                 * @property {mtechnavi.api.notification.ITenantCreatedEmailTemplate|null} [tenantCreatedEmail] NotifyEmailRequest tenantCreatedEmail
                 * @property {mtechnavi.api.notification.IAccountCreatedEmailTemplate|null} [accountCreatedEmail] NotifyEmailRequest accountCreatedEmail
                 * @property {mtechnavi.api.notification.IPasswordResetEmailTemplate|null} [passwordResetEmail] NotifyEmailRequest passwordResetEmail
                 * @property {mtechnavi.api.notification.IAddressChangeProhibitionEmailTemplate|null} [addressChangeProhibitionEmail] NotifyEmailRequest addressChangeProhibitionEmail
                 * @property {mtechnavi.api.notification.IFreeStyleEmailTemplate|null} [freeStyleEmail] NotifyEmailRequest freeStyleEmail
                 * @property {mtechnavi.api.notification.IVerifyTenantRequestEmailTemplate|null} [verifyTenantRequestEmail] NotifyEmailRequest verifyTenantRequestEmail
                 * @property {mtechnavi.api.notification.ITenantInvitationEmailTemplate|null} [tenantInvitationEmail] NotifyEmailRequest tenantInvitationEmail
                 * @property {mtechnavi.api.notification.IAsyncProcessEmailTemplate|null} [asyncProcessEmail] NotifyEmailRequest asyncProcessEmail
                 */

                /**
                 * Constructs a new NotifyEmailRequest.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a NotifyEmailRequest.
                 * @implements INotifyEmailRequest
                 * @constructor
                 * @param {mtechnavi.api.notification.INotifyEmailRequest=} [properties] Properties to set
                 */
                function NotifyEmailRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NotifyEmailRequest emailId.
                 * @member {string} emailId
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.emailId = "";

                /**
                 * NotifyEmailRequest header.
                 * @member {mtechnavi.api.notification.ISendHeader|null|undefined} header
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.header = null;

                /**
                 * NotifyEmailRequest tenantCreatedEmail.
                 * @member {mtechnavi.api.notification.ITenantCreatedEmailTemplate|null|undefined} tenantCreatedEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.tenantCreatedEmail = null;

                /**
                 * NotifyEmailRequest accountCreatedEmail.
                 * @member {mtechnavi.api.notification.IAccountCreatedEmailTemplate|null|undefined} accountCreatedEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.accountCreatedEmail = null;

                /**
                 * NotifyEmailRequest passwordResetEmail.
                 * @member {mtechnavi.api.notification.IPasswordResetEmailTemplate|null|undefined} passwordResetEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.passwordResetEmail = null;

                /**
                 * NotifyEmailRequest addressChangeProhibitionEmail.
                 * @member {mtechnavi.api.notification.IAddressChangeProhibitionEmailTemplate|null|undefined} addressChangeProhibitionEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.addressChangeProhibitionEmail = null;

                /**
                 * NotifyEmailRequest freeStyleEmail.
                 * @member {mtechnavi.api.notification.IFreeStyleEmailTemplate|null|undefined} freeStyleEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.freeStyleEmail = null;

                /**
                 * NotifyEmailRequest verifyTenantRequestEmail.
                 * @member {mtechnavi.api.notification.IVerifyTenantRequestEmailTemplate|null|undefined} verifyTenantRequestEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.verifyTenantRequestEmail = null;

                /**
                 * NotifyEmailRequest tenantInvitationEmail.
                 * @member {mtechnavi.api.notification.ITenantInvitationEmailTemplate|null|undefined} tenantInvitationEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.tenantInvitationEmail = null;

                /**
                 * NotifyEmailRequest asyncProcessEmail.
                 * @member {mtechnavi.api.notification.IAsyncProcessEmailTemplate|null|undefined} asyncProcessEmail
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                NotifyEmailRequest.prototype.asyncProcessEmail = null;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * NotifyEmailRequest template.
                 * @member {"tenantCreatedEmail"|"accountCreatedEmail"|"passwordResetEmail"|"addressChangeProhibitionEmail"|"freeStyleEmail"|"verifyTenantRequestEmail"|"tenantInvitationEmail"|"asyncProcessEmail"|undefined} template
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 */
                Object.defineProperty(NotifyEmailRequest.prototype, "template", {
                    get: $util.oneOfGetter($oneOfFields = ["tenantCreatedEmail", "accountCreatedEmail", "passwordResetEmail", "addressChangeProhibitionEmail", "freeStyleEmail", "verifyTenantRequestEmail", "tenantInvitationEmail", "asyncProcessEmail"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Verifies a NotifyEmailRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotifyEmailRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.emailId != null && message.hasOwnProperty("emailId"))
                        if (!$util.isString(message.emailId))
                            return "emailId: string expected";
                    if (message.header != null && message.hasOwnProperty("header")) {
                        let error = $root.mtechnavi.api.notification.SendHeader.verify(message.header);
                        if (error)
                            return "header." + error;
                    }
                    if (message.tenantCreatedEmail != null && message.hasOwnProperty("tenantCreatedEmail")) {
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.TenantCreatedEmailTemplate.verify(message.tenantCreatedEmail);
                            if (error)
                                return "tenantCreatedEmail." + error;
                        }
                    }
                    if (message.accountCreatedEmail != null && message.hasOwnProperty("accountCreatedEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.AccountCreatedEmailTemplate.verify(message.accountCreatedEmail);
                            if (error)
                                return "accountCreatedEmail." + error;
                        }
                    }
                    if (message.passwordResetEmail != null && message.hasOwnProperty("passwordResetEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.PasswordResetEmailTemplate.verify(message.passwordResetEmail);
                            if (error)
                                return "passwordResetEmail." + error;
                        }
                    }
                    if (message.addressChangeProhibitionEmail != null && message.hasOwnProperty("addressChangeProhibitionEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate.verify(message.addressChangeProhibitionEmail);
                            if (error)
                                return "addressChangeProhibitionEmail." + error;
                        }
                    }
                    if (message.freeStyleEmail != null && message.hasOwnProperty("freeStyleEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.FreeStyleEmailTemplate.verify(message.freeStyleEmail);
                            if (error)
                                return "freeStyleEmail." + error;
                        }
                    }
                    if (message.verifyTenantRequestEmail != null && message.hasOwnProperty("verifyTenantRequestEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.VerifyTenantRequestEmailTemplate.verify(message.verifyTenantRequestEmail);
                            if (error)
                                return "verifyTenantRequestEmail." + error;
                        }
                    }
                    if (message.tenantInvitationEmail != null && message.hasOwnProperty("tenantInvitationEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.TenantInvitationEmailTemplate.verify(message.tenantInvitationEmail);
                            if (error)
                                return "tenantInvitationEmail." + error;
                        }
                    }
                    if (message.asyncProcessEmail != null && message.hasOwnProperty("asyncProcessEmail")) {
                        if (properties.template === 1)
                            return "template: multiple values";
                        properties.template = 1;
                        {
                            let error = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.verify(message.asyncProcessEmail);
                            if (error)
                                return "asyncProcessEmail." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a NotifyEmailRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.NotifyEmailRequest} NotifyEmailRequest
                 */
                NotifyEmailRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.NotifyEmailRequest)
                        return object;
                    let message = new $root.mtechnavi.api.notification.NotifyEmailRequest();
                    if (object.emailId != null)
                        message.emailId = String(object.emailId);
                    if (object.header != null) {
                        if (typeof object.header !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.header: object expected");
                        message.header = $root.mtechnavi.api.notification.SendHeader.fromObject(object.header);
                    }
                    if (object.tenantCreatedEmail != null) {
                        if (typeof object.tenantCreatedEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.tenantCreatedEmail: object expected");
                        message.tenantCreatedEmail = $root.mtechnavi.api.notification.TenantCreatedEmailTemplate.fromObject(object.tenantCreatedEmail);
                    }
                    if (object.accountCreatedEmail != null) {
                        if (typeof object.accountCreatedEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.accountCreatedEmail: object expected");
                        message.accountCreatedEmail = $root.mtechnavi.api.notification.AccountCreatedEmailTemplate.fromObject(object.accountCreatedEmail);
                    }
                    if (object.passwordResetEmail != null) {
                        if (typeof object.passwordResetEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.passwordResetEmail: object expected");
                        message.passwordResetEmail = $root.mtechnavi.api.notification.PasswordResetEmailTemplate.fromObject(object.passwordResetEmail);
                    }
                    if (object.addressChangeProhibitionEmail != null) {
                        if (typeof object.addressChangeProhibitionEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.addressChangeProhibitionEmail: object expected");
                        message.addressChangeProhibitionEmail = $root.mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate.fromObject(object.addressChangeProhibitionEmail);
                    }
                    if (object.freeStyleEmail != null) {
                        if (typeof object.freeStyleEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.freeStyleEmail: object expected");
                        message.freeStyleEmail = $root.mtechnavi.api.notification.FreeStyleEmailTemplate.fromObject(object.freeStyleEmail);
                    }
                    if (object.verifyTenantRequestEmail != null) {
                        if (typeof object.verifyTenantRequestEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.verifyTenantRequestEmail: object expected");
                        message.verifyTenantRequestEmail = $root.mtechnavi.api.notification.VerifyTenantRequestEmailTemplate.fromObject(object.verifyTenantRequestEmail);
                    }
                    if (object.tenantInvitationEmail != null) {
                        if (typeof object.tenantInvitationEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.tenantInvitationEmail: object expected");
                        message.tenantInvitationEmail = $root.mtechnavi.api.notification.TenantInvitationEmailTemplate.fromObject(object.tenantInvitationEmail);
                    }
                    if (object.asyncProcessEmail != null) {
                        if (typeof object.asyncProcessEmail !== "object")
                            throw TypeError(".mtechnavi.api.notification.NotifyEmailRequest.asyncProcessEmail: object expected");
                        message.asyncProcessEmail = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.fromObject(object.asyncProcessEmail);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a NotifyEmailRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @static
                 * @param {mtechnavi.api.notification.NotifyEmailRequest} message NotifyEmailRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotifyEmailRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.header = null;
                        object.emailId = "";
                    }
                    if (message.header != null && message.hasOwnProperty("header"))
                        object.header = $root.mtechnavi.api.notification.SendHeader.toObject(message.header, options);
                    if (message.emailId != null && message.hasOwnProperty("emailId"))
                        object.emailId = message.emailId;
                    if (message.tenantCreatedEmail != null && message.hasOwnProperty("tenantCreatedEmail")) {
                        object.tenantCreatedEmail = $root.mtechnavi.api.notification.TenantCreatedEmailTemplate.toObject(message.tenantCreatedEmail, options);
                        if (options.oneofs)
                            object.template = "tenantCreatedEmail";
                    }
                    if (message.accountCreatedEmail != null && message.hasOwnProperty("accountCreatedEmail")) {
                        object.accountCreatedEmail = $root.mtechnavi.api.notification.AccountCreatedEmailTemplate.toObject(message.accountCreatedEmail, options);
                        if (options.oneofs)
                            object.template = "accountCreatedEmail";
                    }
                    if (message.passwordResetEmail != null && message.hasOwnProperty("passwordResetEmail")) {
                        object.passwordResetEmail = $root.mtechnavi.api.notification.PasswordResetEmailTemplate.toObject(message.passwordResetEmail, options);
                        if (options.oneofs)
                            object.template = "passwordResetEmail";
                    }
                    if (message.addressChangeProhibitionEmail != null && message.hasOwnProperty("addressChangeProhibitionEmail")) {
                        object.addressChangeProhibitionEmail = $root.mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate.toObject(message.addressChangeProhibitionEmail, options);
                        if (options.oneofs)
                            object.template = "addressChangeProhibitionEmail";
                    }
                    if (message.freeStyleEmail != null && message.hasOwnProperty("freeStyleEmail")) {
                        object.freeStyleEmail = $root.mtechnavi.api.notification.FreeStyleEmailTemplate.toObject(message.freeStyleEmail, options);
                        if (options.oneofs)
                            object.template = "freeStyleEmail";
                    }
                    if (message.verifyTenantRequestEmail != null && message.hasOwnProperty("verifyTenantRequestEmail")) {
                        object.verifyTenantRequestEmail = $root.mtechnavi.api.notification.VerifyTenantRequestEmailTemplate.toObject(message.verifyTenantRequestEmail, options);
                        if (options.oneofs)
                            object.template = "verifyTenantRequestEmail";
                    }
                    if (message.tenantInvitationEmail != null && message.hasOwnProperty("tenantInvitationEmail")) {
                        object.tenantInvitationEmail = $root.mtechnavi.api.notification.TenantInvitationEmailTemplate.toObject(message.tenantInvitationEmail, options);
                        if (options.oneofs)
                            object.template = "tenantInvitationEmail";
                    }
                    if (message.asyncProcessEmail != null && message.hasOwnProperty("asyncProcessEmail")) {
                        object.asyncProcessEmail = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.toObject(message.asyncProcessEmail, options);
                        if (options.oneofs)
                            object.template = "asyncProcessEmail";
                    }
                    return object;
                };

                /**
                 * Converts this NotifyEmailRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.NotifyEmailRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotifyEmailRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NotifyEmailRequest;
            })();

            notification.TenantCreatedEmailTemplate = (function() {

                /**
                 * Properties of a TenantCreatedEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface ITenantCreatedEmailTemplate
                 * @property {string|null} [tenantDisplayName] TenantCreatedEmailTemplate tenantDisplayName
                 * @property {string|null} [adminDisplayName] TenantCreatedEmailTemplate adminDisplayName
                 * @property {string|null} [tenantSpaceUrl] TenantCreatedEmailTemplate tenantSpaceUrl
                 * @property {string|null} [password] TenantCreatedEmailTemplate password
                 */

                /**
                 * Constructs a new TenantCreatedEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a TenantCreatedEmailTemplate.
                 * @implements ITenantCreatedEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.ITenantCreatedEmailTemplate=} [properties] Properties to set
                 */
                function TenantCreatedEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantCreatedEmailTemplate tenantDisplayName.
                 * @member {string} tenantDisplayName
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @instance
                 */
                TenantCreatedEmailTemplate.prototype.tenantDisplayName = "";

                /**
                 * TenantCreatedEmailTemplate adminDisplayName.
                 * @member {string} adminDisplayName
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @instance
                 */
                TenantCreatedEmailTemplate.prototype.adminDisplayName = "";

                /**
                 * TenantCreatedEmailTemplate tenantSpaceUrl.
                 * @member {string} tenantSpaceUrl
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @instance
                 */
                TenantCreatedEmailTemplate.prototype.tenantSpaceUrl = "";

                /**
                 * TenantCreatedEmailTemplate password.
                 * @member {string} password
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @instance
                 */
                TenantCreatedEmailTemplate.prototype.password = "";

                /**
                 * Verifies a TenantCreatedEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantCreatedEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        if (!$util.isString(message.tenantDisplayName))
                            return "tenantDisplayName: string expected";
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        if (!$util.isString(message.adminDisplayName))
                            return "adminDisplayName: string expected";
                    if (message.tenantSpaceUrl != null && message.hasOwnProperty("tenantSpaceUrl"))
                        if (!$util.isString(message.tenantSpaceUrl))
                            return "tenantSpaceUrl: string expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    return null;
                };

                /**
                 * Creates a TenantCreatedEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.TenantCreatedEmailTemplate} TenantCreatedEmailTemplate
                 */
                TenantCreatedEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.TenantCreatedEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.TenantCreatedEmailTemplate();
                    if (object.tenantDisplayName != null)
                        message.tenantDisplayName = String(object.tenantDisplayName);
                    if (object.adminDisplayName != null)
                        message.adminDisplayName = String(object.adminDisplayName);
                    if (object.tenantSpaceUrl != null)
                        message.tenantSpaceUrl = String(object.tenantSpaceUrl);
                    if (object.password != null)
                        message.password = String(object.password);
                    return message;
                };

                /**
                 * Creates a plain object from a TenantCreatedEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.TenantCreatedEmailTemplate} message TenantCreatedEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantCreatedEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantSpaceUrl = "";
                        object.tenantDisplayName = "";
                        object.adminDisplayName = "";
                        object.password = "";
                    }
                    if (message.tenantSpaceUrl != null && message.hasOwnProperty("tenantSpaceUrl"))
                        object.tenantSpaceUrl = message.tenantSpaceUrl;
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        object.tenantDisplayName = message.tenantDisplayName;
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        object.adminDisplayName = message.adminDisplayName;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    return object;
                };

                /**
                 * Converts this TenantCreatedEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.TenantCreatedEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantCreatedEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantCreatedEmailTemplate;
            })();

            notification.AccountCreatedEmailTemplate = (function() {

                /**
                 * Properties of an AccountCreatedEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IAccountCreatedEmailTemplate
                 * @property {string|null} [tenantSpaceUrl] AccountCreatedEmailTemplate tenantSpaceUrl
                 * @property {string|null} [password] AccountCreatedEmailTemplate password
                 */

                /**
                 * Constructs a new AccountCreatedEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents an AccountCreatedEmailTemplate.
                 * @implements IAccountCreatedEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IAccountCreatedEmailTemplate=} [properties] Properties to set
                 */
                function AccountCreatedEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AccountCreatedEmailTemplate tenantSpaceUrl.
                 * @member {string} tenantSpaceUrl
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @instance
                 */
                AccountCreatedEmailTemplate.prototype.tenantSpaceUrl = "";

                /**
                 * AccountCreatedEmailTemplate password.
                 * @member {string} password
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @instance
                 */
                AccountCreatedEmailTemplate.prototype.password = "";

                /**
                 * Verifies an AccountCreatedEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AccountCreatedEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantSpaceUrl != null && message.hasOwnProperty("tenantSpaceUrl"))
                        if (!$util.isString(message.tenantSpaceUrl))
                            return "tenantSpaceUrl: string expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    return null;
                };

                /**
                 * Creates an AccountCreatedEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.AccountCreatedEmailTemplate} AccountCreatedEmailTemplate
                 */
                AccountCreatedEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.AccountCreatedEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.AccountCreatedEmailTemplate();
                    if (object.tenantSpaceUrl != null)
                        message.tenantSpaceUrl = String(object.tenantSpaceUrl);
                    if (object.password != null)
                        message.password = String(object.password);
                    return message;
                };

                /**
                 * Creates a plain object from an AccountCreatedEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.AccountCreatedEmailTemplate} message AccountCreatedEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AccountCreatedEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantSpaceUrl = "";
                        object.password = "";
                    }
                    if (message.tenantSpaceUrl != null && message.hasOwnProperty("tenantSpaceUrl"))
                        object.tenantSpaceUrl = message.tenantSpaceUrl;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    return object;
                };

                /**
                 * Converts this AccountCreatedEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.AccountCreatedEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AccountCreatedEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AccountCreatedEmailTemplate;
            })();

            notification.PasswordResetEmailTemplate = (function() {

                /**
                 * Properties of a PasswordResetEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IPasswordResetEmailTemplate
                 * @property {string|null} [tenantName] PasswordResetEmailTemplate tenantName
                 * @property {mtechnavi.api.notification.IPasswordResetParameter|null} [passwordResetParam] PasswordResetEmailTemplate passwordResetParam
                 */

                /**
                 * Constructs a new PasswordResetEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a PasswordResetEmailTemplate.
                 * @implements IPasswordResetEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IPasswordResetEmailTemplate=} [properties] Properties to set
                 */
                function PasswordResetEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PasswordResetEmailTemplate tenantName.
                 * @member {string} tenantName
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @instance
                 */
                PasswordResetEmailTemplate.prototype.tenantName = "";

                /**
                 * PasswordResetEmailTemplate passwordResetParam.
                 * @member {mtechnavi.api.notification.IPasswordResetParameter|null|undefined} passwordResetParam
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @instance
                 */
                PasswordResetEmailTemplate.prototype.passwordResetParam = null;

                /**
                 * Verifies a PasswordResetEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PasswordResetEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantName != null && message.hasOwnProperty("tenantName"))
                        if (!$util.isString(message.tenantName))
                            return "tenantName: string expected";
                    if (message.passwordResetParam != null && message.hasOwnProperty("passwordResetParam")) {
                        let error = $root.mtechnavi.api.notification.PasswordResetParameter.verify(message.passwordResetParam);
                        if (error)
                            return "passwordResetParam." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PasswordResetEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.PasswordResetEmailTemplate} PasswordResetEmailTemplate
                 */
                PasswordResetEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.PasswordResetEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.PasswordResetEmailTemplate();
                    if (object.tenantName != null)
                        message.tenantName = String(object.tenantName);
                    if (object.passwordResetParam != null) {
                        if (typeof object.passwordResetParam !== "object")
                            throw TypeError(".mtechnavi.api.notification.PasswordResetEmailTemplate.passwordResetParam: object expected");
                        message.passwordResetParam = $root.mtechnavi.api.notification.PasswordResetParameter.fromObject(object.passwordResetParam);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PasswordResetEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.PasswordResetEmailTemplate} message PasswordResetEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PasswordResetEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantName = "";
                        object.passwordResetParam = null;
                    }
                    if (message.tenantName != null && message.hasOwnProperty("tenantName"))
                        object.tenantName = message.tenantName;
                    if (message.passwordResetParam != null && message.hasOwnProperty("passwordResetParam"))
                        object.passwordResetParam = $root.mtechnavi.api.notification.PasswordResetParameter.toObject(message.passwordResetParam, options);
                    return object;
                };

                /**
                 * Converts this PasswordResetEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.PasswordResetEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PasswordResetEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PasswordResetEmailTemplate;
            })();

            notification.AddressChangeProhibitionEmailTemplate = (function() {

                /**
                 * Properties of an AddressChangeProhibitionEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IAddressChangeProhibitionEmailTemplate
                 */

                /**
                 * Constructs a new AddressChangeProhibitionEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents an AddressChangeProhibitionEmailTemplate.
                 * @implements IAddressChangeProhibitionEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IAddressChangeProhibitionEmailTemplate=} [properties] Properties to set
                 */
                function AddressChangeProhibitionEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an AddressChangeProhibitionEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressChangeProhibitionEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AddressChangeProhibitionEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate} AddressChangeProhibitionEmailTemplate
                 */
                AddressChangeProhibitionEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate)
                        return object;
                    return new $root.mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate();
                };

                /**
                 * Creates a plain object from an AddressChangeProhibitionEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate} message AddressChangeProhibitionEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressChangeProhibitionEmailTemplate.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AddressChangeProhibitionEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.AddressChangeProhibitionEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressChangeProhibitionEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AddressChangeProhibitionEmailTemplate;
            })();

            notification.FreeStyleEmailTemplate = (function() {

                /**
                 * Properties of a FreeStyleEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IFreeStyleEmailTemplate
                 * @property {string|null} [title] FreeStyleEmailTemplate title
                 * @property {string|null} [contentHeader] FreeStyleEmailTemplate contentHeader
                 * @property {string|null} [contentBody] FreeStyleEmailTemplate contentBody
                 * @property {string|null} [contentFooter] FreeStyleEmailTemplate contentFooter
                 */

                /**
                 * Constructs a new FreeStyleEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a FreeStyleEmailTemplate.
                 * @implements IFreeStyleEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IFreeStyleEmailTemplate=} [properties] Properties to set
                 */
                function FreeStyleEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FreeStyleEmailTemplate title.
                 * @member {string} title
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @instance
                 */
                FreeStyleEmailTemplate.prototype.title = "";

                /**
                 * FreeStyleEmailTemplate contentHeader.
                 * @member {string} contentHeader
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @instance
                 */
                FreeStyleEmailTemplate.prototype.contentHeader = "";

                /**
                 * FreeStyleEmailTemplate contentBody.
                 * @member {string} contentBody
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @instance
                 */
                FreeStyleEmailTemplate.prototype.contentBody = "";

                /**
                 * FreeStyleEmailTemplate contentFooter.
                 * @member {string} contentFooter
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @instance
                 */
                FreeStyleEmailTemplate.prototype.contentFooter = "";

                /**
                 * Verifies a FreeStyleEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FreeStyleEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.contentHeader != null && message.hasOwnProperty("contentHeader"))
                        if (!$util.isString(message.contentHeader))
                            return "contentHeader: string expected";
                    if (message.contentBody != null && message.hasOwnProperty("contentBody"))
                        if (!$util.isString(message.contentBody))
                            return "contentBody: string expected";
                    if (message.contentFooter != null && message.hasOwnProperty("contentFooter"))
                        if (!$util.isString(message.contentFooter))
                            return "contentFooter: string expected";
                    return null;
                };

                /**
                 * Creates a FreeStyleEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.FreeStyleEmailTemplate} FreeStyleEmailTemplate
                 */
                FreeStyleEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.FreeStyleEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.FreeStyleEmailTemplate();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.contentHeader != null)
                        message.contentHeader = String(object.contentHeader);
                    if (object.contentBody != null)
                        message.contentBody = String(object.contentBody);
                    if (object.contentFooter != null)
                        message.contentFooter = String(object.contentFooter);
                    return message;
                };

                /**
                 * Creates a plain object from a FreeStyleEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.FreeStyleEmailTemplate} message FreeStyleEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FreeStyleEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.title = "";
                        object.contentHeader = "";
                        object.contentBody = "";
                        object.contentFooter = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.contentHeader != null && message.hasOwnProperty("contentHeader"))
                        object.contentHeader = message.contentHeader;
                    if (message.contentBody != null && message.hasOwnProperty("contentBody"))
                        object.contentBody = message.contentBody;
                    if (message.contentFooter != null && message.hasOwnProperty("contentFooter"))
                        object.contentFooter = message.contentFooter;
                    return object;
                };

                /**
                 * Converts this FreeStyleEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.FreeStyleEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FreeStyleEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FreeStyleEmailTemplate;
            })();

            notification.VerifyTenantRequestEmailTemplate = (function() {

                /**
                 * Properties of a VerifyTenantRequestEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IVerifyTenantRequestEmailTemplate
                 * @property {string|null} [tenantDisplayName] VerifyTenantRequestEmailTemplate tenantDisplayName
                 * @property {string|null} [adminDisplayName] VerifyTenantRequestEmailTemplate adminDisplayName
                 * @property {string|null} [requestVerifiedAt] VerifyTenantRequestEmailTemplate requestVerifiedAt
                 * @property {string|null} [applyTenantRequestUrl] VerifyTenantRequestEmailTemplate applyTenantRequestUrl
                 */

                /**
                 * Constructs a new VerifyTenantRequestEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a VerifyTenantRequestEmailTemplate.
                 * @implements IVerifyTenantRequestEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IVerifyTenantRequestEmailTemplate=} [properties] Properties to set
                 */
                function VerifyTenantRequestEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyTenantRequestEmailTemplate tenantDisplayName.
                 * @member {string} tenantDisplayName
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @instance
                 */
                VerifyTenantRequestEmailTemplate.prototype.tenantDisplayName = "";

                /**
                 * VerifyTenantRequestEmailTemplate adminDisplayName.
                 * @member {string} adminDisplayName
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @instance
                 */
                VerifyTenantRequestEmailTemplate.prototype.adminDisplayName = "";

                /**
                 * VerifyTenantRequestEmailTemplate requestVerifiedAt.
                 * @member {string} requestVerifiedAt
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @instance
                 */
                VerifyTenantRequestEmailTemplate.prototype.requestVerifiedAt = "";

                /**
                 * VerifyTenantRequestEmailTemplate applyTenantRequestUrl.
                 * @member {string} applyTenantRequestUrl
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @instance
                 */
                VerifyTenantRequestEmailTemplate.prototype.applyTenantRequestUrl = "";

                /**
                 * Verifies a VerifyTenantRequestEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyTenantRequestEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        if (!$util.isString(message.tenantDisplayName))
                            return "tenantDisplayName: string expected";
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        if (!$util.isString(message.adminDisplayName))
                            return "adminDisplayName: string expected";
                    if (message.requestVerifiedAt != null && message.hasOwnProperty("requestVerifiedAt"))
                        if (!$util.isString(message.requestVerifiedAt))
                            return "requestVerifiedAt: string expected";
                    if (message.applyTenantRequestUrl != null && message.hasOwnProperty("applyTenantRequestUrl"))
                        if (!$util.isString(message.applyTenantRequestUrl))
                            return "applyTenantRequestUrl: string expected";
                    return null;
                };

                /**
                 * Creates a VerifyTenantRequestEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.VerifyTenantRequestEmailTemplate} VerifyTenantRequestEmailTemplate
                 */
                VerifyTenantRequestEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.VerifyTenantRequestEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.VerifyTenantRequestEmailTemplate();
                    if (object.tenantDisplayName != null)
                        message.tenantDisplayName = String(object.tenantDisplayName);
                    if (object.adminDisplayName != null)
                        message.adminDisplayName = String(object.adminDisplayName);
                    if (object.requestVerifiedAt != null)
                        message.requestVerifiedAt = String(object.requestVerifiedAt);
                    if (object.applyTenantRequestUrl != null)
                        message.applyTenantRequestUrl = String(object.applyTenantRequestUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyTenantRequestEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.VerifyTenantRequestEmailTemplate} message VerifyTenantRequestEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyTenantRequestEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantDisplayName = "";
                        object.adminDisplayName = "";
                        object.requestVerifiedAt = "";
                        object.applyTenantRequestUrl = "";
                    }
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        object.tenantDisplayName = message.tenantDisplayName;
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        object.adminDisplayName = message.adminDisplayName;
                    if (message.requestVerifiedAt != null && message.hasOwnProperty("requestVerifiedAt"))
                        object.requestVerifiedAt = message.requestVerifiedAt;
                    if (message.applyTenantRequestUrl != null && message.hasOwnProperty("applyTenantRequestUrl"))
                        object.applyTenantRequestUrl = message.applyTenantRequestUrl;
                    return object;
                };

                /**
                 * Converts this VerifyTenantRequestEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.VerifyTenantRequestEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyTenantRequestEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyTenantRequestEmailTemplate;
            })();

            notification.TenantInvitationEmailTemplate = (function() {

                /**
                 * Properties of a TenantInvitationEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface ITenantInvitationEmailTemplate
                 * @property {string|null} [tenantDisplayName] TenantInvitationEmailTemplate tenantDisplayName
                 * @property {string|null} [adminDisplayName] TenantInvitationEmailTemplate adminDisplayName
                 * @property {string|null} [individualMessage] TenantInvitationEmailTemplate individualMessage
                 * @property {string|null} [tenantRequestUrl] TenantInvitationEmailTemplate tenantRequestUrl
                 * @property {string|null} [contactInformationMessage] TenantInvitationEmailTemplate contactInformationMessage
                 */

                /**
                 * Constructs a new TenantInvitationEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a TenantInvitationEmailTemplate.
                 * @implements ITenantInvitationEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.ITenantInvitationEmailTemplate=} [properties] Properties to set
                 */
                function TenantInvitationEmailTemplate(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantInvitationEmailTemplate tenantDisplayName.
                 * @member {string} tenantDisplayName
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 */
                TenantInvitationEmailTemplate.prototype.tenantDisplayName = "";

                /**
                 * TenantInvitationEmailTemplate adminDisplayName.
                 * @member {string} adminDisplayName
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 */
                TenantInvitationEmailTemplate.prototype.adminDisplayName = "";

                /**
                 * TenantInvitationEmailTemplate individualMessage.
                 * @member {string} individualMessage
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 */
                TenantInvitationEmailTemplate.prototype.individualMessage = "";

                /**
                 * TenantInvitationEmailTemplate tenantRequestUrl.
                 * @member {string} tenantRequestUrl
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 */
                TenantInvitationEmailTemplate.prototype.tenantRequestUrl = "";

                /**
                 * TenantInvitationEmailTemplate contactInformationMessage.
                 * @member {string} contactInformationMessage
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 */
                TenantInvitationEmailTemplate.prototype.contactInformationMessage = "";

                /**
                 * Verifies a TenantInvitationEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantInvitationEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        if (!$util.isString(message.tenantDisplayName))
                            return "tenantDisplayName: string expected";
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        if (!$util.isString(message.adminDisplayName))
                            return "adminDisplayName: string expected";
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        if (!$util.isString(message.individualMessage))
                            return "individualMessage: string expected";
                    if (message.tenantRequestUrl != null && message.hasOwnProperty("tenantRequestUrl"))
                        if (!$util.isString(message.tenantRequestUrl))
                            return "tenantRequestUrl: string expected";
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        if (!$util.isString(message.contactInformationMessage))
                            return "contactInformationMessage: string expected";
                    return null;
                };

                /**
                 * Creates a TenantInvitationEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.TenantInvitationEmailTemplate} TenantInvitationEmailTemplate
                 */
                TenantInvitationEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.TenantInvitationEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.TenantInvitationEmailTemplate();
                    if (object.tenantDisplayName != null)
                        message.tenantDisplayName = String(object.tenantDisplayName);
                    if (object.adminDisplayName != null)
                        message.adminDisplayName = String(object.adminDisplayName);
                    if (object.individualMessage != null)
                        message.individualMessage = String(object.individualMessage);
                    if (object.tenantRequestUrl != null)
                        message.tenantRequestUrl = String(object.tenantRequestUrl);
                    if (object.contactInformationMessage != null)
                        message.contactInformationMessage = String(object.contactInformationMessage);
                    return message;
                };

                /**
                 * Creates a plain object from a TenantInvitationEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.TenantInvitationEmailTemplate} message TenantInvitationEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantInvitationEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantDisplayName = "";
                        object.adminDisplayName = "";
                        object.individualMessage = "";
                        object.tenantRequestUrl = "";
                        object.contactInformationMessage = "";
                    }
                    if (message.tenantDisplayName != null && message.hasOwnProperty("tenantDisplayName"))
                        object.tenantDisplayName = message.tenantDisplayName;
                    if (message.adminDisplayName != null && message.hasOwnProperty("adminDisplayName"))
                        object.adminDisplayName = message.adminDisplayName;
                    if (message.individualMessage != null && message.hasOwnProperty("individualMessage"))
                        object.individualMessage = message.individualMessage;
                    if (message.tenantRequestUrl != null && message.hasOwnProperty("tenantRequestUrl"))
                        object.tenantRequestUrl = message.tenantRequestUrl;
                    if (message.contactInformationMessage != null && message.hasOwnProperty("contactInformationMessage"))
                        object.contactInformationMessage = message.contactInformationMessage;
                    return object;
                };

                /**
                 * Converts this TenantInvitationEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.TenantInvitationEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantInvitationEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantInvitationEmailTemplate;
            })();

            notification.AsyncProcessEmailTemplate = (function() {

                /**
                 * Properties of an AsyncProcessEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @interface IAsyncProcessEmailTemplate
                 * @property {string|null} [systemCategory] AsyncProcessEmailTemplate systemCategory
                 * @property {string|null} [processResult] AsyncProcessEmailTemplate processResult
                 * @property {string|null} [companyName] AsyncProcessEmailTemplate companyName
                 * @property {string|null} [userName] AsyncProcessEmailTemplate userName
                 * @property {string|null} [processMessage] AsyncProcessEmailTemplate processMessage
                 * @property {string|null} [processName] AsyncProcessEmailTemplate processName
                 * @property {string|null} [startAt] AsyncProcessEmailTemplate startAt
                 * @property {mtechnavi.api.notification.AsyncProcessEmailTemplate.IProcess|null} [process] AsyncProcessEmailTemplate process
                 * @property {Array.<mtechnavi.api.notification.AsyncProcessEmailTemplate.IErrorItem>|null} [errorItems] AsyncProcessEmailTemplate errorItems
                 * @property {string|null} [errorCsvFileUrl] AsyncProcessEmailTemplate errorCsvFileUrl
                 */

                /**
                 * Constructs a new AsyncProcessEmailTemplate.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents an AsyncProcessEmailTemplate.
                 * @implements IAsyncProcessEmailTemplate
                 * @constructor
                 * @param {mtechnavi.api.notification.IAsyncProcessEmailTemplate=} [properties] Properties to set
                 */
                function AsyncProcessEmailTemplate(properties) {
                    this.errorItems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AsyncProcessEmailTemplate systemCategory.
                 * @member {string} systemCategory
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.systemCategory = "";

                /**
                 * AsyncProcessEmailTemplate processResult.
                 * @member {string} processResult
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.processResult = "";

                /**
                 * AsyncProcessEmailTemplate companyName.
                 * @member {string} companyName
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.companyName = "";

                /**
                 * AsyncProcessEmailTemplate userName.
                 * @member {string} userName
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.userName = "";

                /**
                 * AsyncProcessEmailTemplate processMessage.
                 * @member {string} processMessage
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.processMessage = "";

                /**
                 * AsyncProcessEmailTemplate processName.
                 * @member {string} processName
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.processName = "";

                /**
                 * AsyncProcessEmailTemplate startAt.
                 * @member {string} startAt
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.startAt = "";

                /**
                 * AsyncProcessEmailTemplate process.
                 * @member {mtechnavi.api.notification.AsyncProcessEmailTemplate.IProcess|null|undefined} process
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.process = null;

                /**
                 * AsyncProcessEmailTemplate errorItems.
                 * @member {Array.<mtechnavi.api.notification.AsyncProcessEmailTemplate.IErrorItem>} errorItems
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.errorItems = $util.emptyArray;

                /**
                 * AsyncProcessEmailTemplate errorCsvFileUrl.
                 * @member {string} errorCsvFileUrl
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 */
                AsyncProcessEmailTemplate.prototype.errorCsvFileUrl = "";

                /**
                 * Verifies an AsyncProcessEmailTemplate message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AsyncProcessEmailTemplate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.systemCategory != null && message.hasOwnProperty("systemCategory"))
                        if (!$util.isString(message.systemCategory))
                            return "systemCategory: string expected";
                    if (message.processResult != null && message.hasOwnProperty("processResult"))
                        if (!$util.isString(message.processResult))
                            return "processResult: string expected";
                    if (message.companyName != null && message.hasOwnProperty("companyName"))
                        if (!$util.isString(message.companyName))
                            return "companyName: string expected";
                    if (message.userName != null && message.hasOwnProperty("userName"))
                        if (!$util.isString(message.userName))
                            return "userName: string expected";
                    if (message.processMessage != null && message.hasOwnProperty("processMessage"))
                        if (!$util.isString(message.processMessage))
                            return "processMessage: string expected";
                    if (message.processName != null && message.hasOwnProperty("processName"))
                        if (!$util.isString(message.processName))
                            return "processName: string expected";
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        if (!$util.isString(message.startAt))
                            return "startAt: string expected";
                    if (message.process != null && message.hasOwnProperty("process")) {
                        let error = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.Process.verify(message.process);
                        if (error)
                            return "process." + error;
                    }
                    if (message.errorItems != null && message.hasOwnProperty("errorItems")) {
                        if (!Array.isArray(message.errorItems))
                            return "errorItems: array expected";
                        for (let i = 0; i < message.errorItems.length; ++i) {
                            let error = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem.verify(message.errorItems[i]);
                            if (error)
                                return "errorItems." + error;
                        }
                    }
                    if (message.errorCsvFileUrl != null && message.hasOwnProperty("errorCsvFileUrl"))
                        if (!$util.isString(message.errorCsvFileUrl))
                            return "errorCsvFileUrl: string expected";
                    return null;
                };

                /**
                 * Creates an AsyncProcessEmailTemplate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.AsyncProcessEmailTemplate} AsyncProcessEmailTemplate
                 */
                AsyncProcessEmailTemplate.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.AsyncProcessEmailTemplate)
                        return object;
                    let message = new $root.mtechnavi.api.notification.AsyncProcessEmailTemplate();
                    if (object.systemCategory != null)
                        message.systemCategory = String(object.systemCategory);
                    if (object.processResult != null)
                        message.processResult = String(object.processResult);
                    if (object.companyName != null)
                        message.companyName = String(object.companyName);
                    if (object.userName != null)
                        message.userName = String(object.userName);
                    if (object.processMessage != null)
                        message.processMessage = String(object.processMessage);
                    if (object.processName != null)
                        message.processName = String(object.processName);
                    if (object.startAt != null)
                        message.startAt = String(object.startAt);
                    if (object.process != null) {
                        if (typeof object.process !== "object")
                            throw TypeError(".mtechnavi.api.notification.AsyncProcessEmailTemplate.process: object expected");
                        message.process = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.Process.fromObject(object.process);
                    }
                    if (object.errorItems) {
                        if (!Array.isArray(object.errorItems))
                            throw TypeError(".mtechnavi.api.notification.AsyncProcessEmailTemplate.errorItems: array expected");
                        message.errorItems = [];
                        for (let i = 0; i < object.errorItems.length; ++i) {
                            if (typeof object.errorItems[i] !== "object")
                                throw TypeError(".mtechnavi.api.notification.AsyncProcessEmailTemplate.errorItems: object expected");
                            message.errorItems[i] = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem.fromObject(object.errorItems[i]);
                        }
                    }
                    if (object.errorCsvFileUrl != null)
                        message.errorCsvFileUrl = String(object.errorCsvFileUrl);
                    return message;
                };

                /**
                 * Creates a plain object from an AsyncProcessEmailTemplate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @static
                 * @param {mtechnavi.api.notification.AsyncProcessEmailTemplate} message AsyncProcessEmailTemplate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AsyncProcessEmailTemplate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errorItems = [];
                    if (options.defaults) {
                        object.systemCategory = "";
                        object.processResult = "";
                        object.companyName = "";
                        object.userName = "";
                        object.processMessage = "";
                        object.processName = "";
                        object.startAt = "";
                        object.process = null;
                        object.errorCsvFileUrl = "";
                    }
                    if (message.systemCategory != null && message.hasOwnProperty("systemCategory"))
                        object.systemCategory = message.systemCategory;
                    if (message.processResult != null && message.hasOwnProperty("processResult"))
                        object.processResult = message.processResult;
                    if (message.companyName != null && message.hasOwnProperty("companyName"))
                        object.companyName = message.companyName;
                    if (message.userName != null && message.hasOwnProperty("userName"))
                        object.userName = message.userName;
                    if (message.processMessage != null && message.hasOwnProperty("processMessage"))
                        object.processMessage = message.processMessage;
                    if (message.processName != null && message.hasOwnProperty("processName"))
                        object.processName = message.processName;
                    if (message.startAt != null && message.hasOwnProperty("startAt"))
                        object.startAt = message.startAt;
                    if (message.process != null && message.hasOwnProperty("process"))
                        object.process = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.Process.toObject(message.process, options);
                    if (message.errorItems && message.errorItems.length) {
                        object.errorItems = [];
                        for (let j = 0; j < message.errorItems.length; ++j)
                            object.errorItems[j] = $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem.toObject(message.errorItems[j], options);
                    }
                    if (message.errorCsvFileUrl != null && message.hasOwnProperty("errorCsvFileUrl"))
                        object.errorCsvFileUrl = message.errorCsvFileUrl;
                    return object;
                };

                /**
                 * Converts this AsyncProcessEmailTemplate to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AsyncProcessEmailTemplate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                AsyncProcessEmailTemplate.Process = (function() {

                    /**
                     * Properties of a Process.
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                     * @interface IProcess
                     * @property {Long|null} [totalCount] Process totalCount
                     * @property {Long|null} [successCount] Process successCount
                     * @property {Long|null} [errorCount] Process errorCount
                     */

                    /**
                     * Constructs a new Process.
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                     * @classdesc Represents a Process.
                     * @implements IProcess
                     * @constructor
                     * @param {mtechnavi.api.notification.AsyncProcessEmailTemplate.IProcess=} [properties] Properties to set
                     */
                    function Process(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Process totalCount.
                     * @member {Long} totalCount
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @instance
                     */
                    Process.prototype.totalCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Process successCount.
                     * @member {Long} successCount
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @instance
                     */
                    Process.prototype.successCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Process errorCount.
                     * @member {Long} errorCount
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @instance
                     */
                    Process.prototype.errorCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a Process message.
                     * @function verify
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Process.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                            if (!$util.isInteger(message.totalCount) && !(message.totalCount && $util.isInteger(message.totalCount.low) && $util.isInteger(message.totalCount.high)))
                                return "totalCount: integer|Long expected";
                        if (message.successCount != null && message.hasOwnProperty("successCount"))
                            if (!$util.isInteger(message.successCount) && !(message.successCount && $util.isInteger(message.successCount.low) && $util.isInteger(message.successCount.high)))
                                return "successCount: integer|Long expected";
                        if (message.errorCount != null && message.hasOwnProperty("errorCount"))
                            if (!$util.isInteger(message.errorCount) && !(message.errorCount && $util.isInteger(message.errorCount.low) && $util.isInteger(message.errorCount.high)))
                                return "errorCount: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a Process message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.notification.AsyncProcessEmailTemplate.Process} Process
                     */
                    Process.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.Process)
                            return object;
                        let message = new $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.Process();
                        if (object.totalCount != null)
                            if ($util.Long)
                                (message.totalCount = $util.Long.fromValue(object.totalCount)).unsigned = false;
                            else if (typeof object.totalCount === "string")
                                message.totalCount = parseInt(object.totalCount, 10);
                            else if (typeof object.totalCount === "number")
                                message.totalCount = object.totalCount;
                            else if (typeof object.totalCount === "object")
                                message.totalCount = new $util.LongBits(object.totalCount.low >>> 0, object.totalCount.high >>> 0).toNumber();
                        if (object.successCount != null)
                            if ($util.Long)
                                (message.successCount = $util.Long.fromValue(object.successCount)).unsigned = false;
                            else if (typeof object.successCount === "string")
                                message.successCount = parseInt(object.successCount, 10);
                            else if (typeof object.successCount === "number")
                                message.successCount = object.successCount;
                            else if (typeof object.successCount === "object")
                                message.successCount = new $util.LongBits(object.successCount.low >>> 0, object.successCount.high >>> 0).toNumber();
                        if (object.errorCount != null)
                            if ($util.Long)
                                (message.errorCount = $util.Long.fromValue(object.errorCount)).unsigned = false;
                            else if (typeof object.errorCount === "string")
                                message.errorCount = parseInt(object.errorCount, 10);
                            else if (typeof object.errorCount === "number")
                                message.errorCount = object.errorCount;
                            else if (typeof object.errorCount === "object")
                                message.errorCount = new $util.LongBits(object.errorCount.low >>> 0, object.errorCount.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a Process message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @static
                     * @param {mtechnavi.api.notification.AsyncProcessEmailTemplate.Process} message Process
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Process.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.totalCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.totalCount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.successCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.successCount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.errorCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.errorCount = options.longs === String ? "0" : 0;
                        }
                        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                            if (typeof message.totalCount === "number")
                                object.totalCount = options.longs === String ? String(message.totalCount) : message.totalCount;
                            else
                                object.totalCount = options.longs === String ? $util.Long.prototype.toString.call(message.totalCount) : options.longs === Number ? new $util.LongBits(message.totalCount.low >>> 0, message.totalCount.high >>> 0).toNumber() : message.totalCount;
                        if (message.successCount != null && message.hasOwnProperty("successCount"))
                            if (typeof message.successCount === "number")
                                object.successCount = options.longs === String ? String(message.successCount) : message.successCount;
                            else
                                object.successCount = options.longs === String ? $util.Long.prototype.toString.call(message.successCount) : options.longs === Number ? new $util.LongBits(message.successCount.low >>> 0, message.successCount.high >>> 0).toNumber() : message.successCount;
                        if (message.errorCount != null && message.hasOwnProperty("errorCount"))
                            if (typeof message.errorCount === "number")
                                object.errorCount = options.longs === String ? String(message.errorCount) : message.errorCount;
                            else
                                object.errorCount = options.longs === String ? $util.Long.prototype.toString.call(message.errorCount) : options.longs === Number ? new $util.LongBits(message.errorCount.low >>> 0, message.errorCount.high >>> 0).toNumber() : message.errorCount;
                        return object;
                    };

                    /**
                     * Converts this Process to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.Process
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Process.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Process;
                })();

                AsyncProcessEmailTemplate.ErrorItem = (function() {

                    /**
                     * Properties of an ErrorItem.
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                     * @interface IErrorItem
                     * @property {string|null} [name] ErrorItem name
                     */

                    /**
                     * Constructs a new ErrorItem.
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate
                     * @classdesc Represents an ErrorItem.
                     * @implements IErrorItem
                     * @constructor
                     * @param {mtechnavi.api.notification.AsyncProcessEmailTemplate.IErrorItem=} [properties] Properties to set
                     */
                    function ErrorItem(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ErrorItem name.
                     * @member {string} name
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem
                     * @instance
                     */
                    ErrorItem.prototype.name = "";

                    /**
                     * Verifies an ErrorItem message.
                     * @function verify
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ErrorItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates an ErrorItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem} ErrorItem
                     */
                    ErrorItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem)
                            return object;
                        let message = new $root.mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ErrorItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem
                     * @static
                     * @param {mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem} message ErrorItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ErrorItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this ErrorItem to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.notification.AsyncProcessEmailTemplate.ErrorItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ErrorItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ErrorItem;
                })();

                return AsyncProcessEmailTemplate;
            })();

            notification.NotifyChannelRequest = (function() {

                /**
                 * Properties of a NotifyChannelRequest.
                 * @memberof mtechnavi.api.notification
                 * @interface INotifyChannelRequest
                 * @property {Array.<string>|null} [channelNames] NotifyChannelRequest channelNames
                 * @property {Uint8Array|null} [data] NotifyChannelRequest data
                 */

                /**
                 * Constructs a new NotifyChannelRequest.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a NotifyChannelRequest.
                 * @implements INotifyChannelRequest
                 * @constructor
                 * @param {mtechnavi.api.notification.INotifyChannelRequest=} [properties] Properties to set
                 */
                function NotifyChannelRequest(properties) {
                    this.channelNames = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NotifyChannelRequest channelNames.
                 * @member {Array.<string>} channelNames
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @instance
                 */
                NotifyChannelRequest.prototype.channelNames = $util.emptyArray;

                /**
                 * NotifyChannelRequest data.
                 * @member {Uint8Array} data
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @instance
                 */
                NotifyChannelRequest.prototype.data = $util.newBuffer([]);

                /**
                 * Verifies a NotifyChannelRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotifyChannelRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.channelNames != null && message.hasOwnProperty("channelNames")) {
                        if (!Array.isArray(message.channelNames))
                            return "channelNames: array expected";
                        for (let i = 0; i < message.channelNames.length; ++i)
                            if (!$util.isString(message.channelNames[i]))
                                return "channelNames: string[] expected";
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                            return "data: buffer expected";
                    return null;
                };

                /**
                 * Creates a NotifyChannelRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.NotifyChannelRequest} NotifyChannelRequest
                 */
                NotifyChannelRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.NotifyChannelRequest)
                        return object;
                    let message = new $root.mtechnavi.api.notification.NotifyChannelRequest();
                    if (object.channelNames) {
                        if (!Array.isArray(object.channelNames))
                            throw TypeError(".mtechnavi.api.notification.NotifyChannelRequest.channelNames: array expected");
                        message.channelNames = [];
                        for (let i = 0; i < object.channelNames.length; ++i)
                            message.channelNames[i] = String(object.channelNames[i]);
                    }
                    if (object.data != null)
                        if (typeof object.data === "string")
                            $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                        else if (object.data.length)
                            message.data = object.data;
                    return message;
                };

                /**
                 * Creates a plain object from a NotifyChannelRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @static
                 * @param {mtechnavi.api.notification.NotifyChannelRequest} message NotifyChannelRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotifyChannelRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.channelNames = [];
                    if (options.defaults)
                        if (options.bytes === String)
                            object.data = "";
                        else {
                            object.data = [];
                            if (options.bytes !== Array)
                                object.data = $util.newBuffer(object.data);
                        }
                    if (message.channelNames && message.channelNames.length) {
                        object.channelNames = [];
                        for (let j = 0; j < message.channelNames.length; ++j)
                            object.channelNames[j] = message.channelNames[j];
                    }
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                    return object;
                };

                /**
                 * Converts this NotifyChannelRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.NotifyChannelRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotifyChannelRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NotifyChannelRequest;
            })();

            notification.NotifyResponse = (function() {

                /**
                 * Properties of a NotifyResponse.
                 * @memberof mtechnavi.api.notification
                 * @interface INotifyResponse
                 */

                /**
                 * Constructs a new NotifyResponse.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a NotifyResponse.
                 * @implements INotifyResponse
                 * @constructor
                 * @param {mtechnavi.api.notification.INotifyResponse=} [properties] Properties to set
                 */
                function NotifyResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a NotifyResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.NotifyResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotifyResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a NotifyResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.NotifyResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.NotifyResponse} NotifyResponse
                 */
                NotifyResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.NotifyResponse)
                        return object;
                    return new $root.mtechnavi.api.notification.NotifyResponse();
                };

                /**
                 * Creates a plain object from a NotifyResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.NotifyResponse
                 * @static
                 * @param {mtechnavi.api.notification.NotifyResponse} message NotifyResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotifyResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this NotifyResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.NotifyResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotifyResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NotifyResponse;
            })();

            notification.ListenChannelRequest = (function() {

                /**
                 * Properties of a ListenChannelRequest.
                 * @memberof mtechnavi.api.notification
                 * @interface IListenChannelRequest
                 * @property {string|null} [channelName] ListenChannelRequest channelName
                 */

                /**
                 * Constructs a new ListenChannelRequest.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a ListenChannelRequest.
                 * @implements IListenChannelRequest
                 * @constructor
                 * @param {mtechnavi.api.notification.IListenChannelRequest=} [properties] Properties to set
                 */
                function ListenChannelRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListenChannelRequest channelName.
                 * @member {string} channelName
                 * @memberof mtechnavi.api.notification.ListenChannelRequest
                 * @instance
                 */
                ListenChannelRequest.prototype.channelName = "";

                /**
                 * Verifies a ListenChannelRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.ListenChannelRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListenChannelRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.channelName != null && message.hasOwnProperty("channelName"))
                        if (!$util.isString(message.channelName))
                            return "channelName: string expected";
                    return null;
                };

                /**
                 * Creates a ListenChannelRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.ListenChannelRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.ListenChannelRequest} ListenChannelRequest
                 */
                ListenChannelRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.ListenChannelRequest)
                        return object;
                    let message = new $root.mtechnavi.api.notification.ListenChannelRequest();
                    if (object.channelName != null)
                        message.channelName = String(object.channelName);
                    return message;
                };

                /**
                 * Creates a plain object from a ListenChannelRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.ListenChannelRequest
                 * @static
                 * @param {mtechnavi.api.notification.ListenChannelRequest} message ListenChannelRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListenChannelRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.channelName = "";
                    if (message.channelName != null && message.hasOwnProperty("channelName"))
                        object.channelName = message.channelName;
                    return object;
                };

                /**
                 * Converts this ListenChannelRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.ListenChannelRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListenChannelRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListenChannelRequest;
            })();

            notification.ListenChannelResponse = (function() {

                /**
                 * Properties of a ListenChannelResponse.
                 * @memberof mtechnavi.api.notification
                 * @interface IListenChannelResponse
                 * @property {string|null} [firestoreProjectId] ListenChannelResponse firestoreProjectId
                 * @property {string|null} [firestoreCollectionPath] ListenChannelResponse firestoreCollectionPath
                 */

                /**
                 * Constructs a new ListenChannelResponse.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents a ListenChannelResponse.
                 * @implements IListenChannelResponse
                 * @constructor
                 * @param {mtechnavi.api.notification.IListenChannelResponse=} [properties] Properties to set
                 */
                function ListenChannelResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListenChannelResponse firestoreProjectId.
                 * @member {string} firestoreProjectId
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @instance
                 */
                ListenChannelResponse.prototype.firestoreProjectId = "";

                /**
                 * ListenChannelResponse firestoreCollectionPath.
                 * @member {string} firestoreCollectionPath
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @instance
                 */
                ListenChannelResponse.prototype.firestoreCollectionPath = "";

                /**
                 * Verifies a ListenChannelResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListenChannelResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firestoreProjectId != null && message.hasOwnProperty("firestoreProjectId"))
                        if (!$util.isString(message.firestoreProjectId))
                            return "firestoreProjectId: string expected";
                    if (message.firestoreCollectionPath != null && message.hasOwnProperty("firestoreCollectionPath"))
                        if (!$util.isString(message.firestoreCollectionPath))
                            return "firestoreCollectionPath: string expected";
                    return null;
                };

                /**
                 * Creates a ListenChannelResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.ListenChannelResponse} ListenChannelResponse
                 */
                ListenChannelResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.ListenChannelResponse)
                        return object;
                    let message = new $root.mtechnavi.api.notification.ListenChannelResponse();
                    if (object.firestoreProjectId != null)
                        message.firestoreProjectId = String(object.firestoreProjectId);
                    if (object.firestoreCollectionPath != null)
                        message.firestoreCollectionPath = String(object.firestoreCollectionPath);
                    return message;
                };

                /**
                 * Creates a plain object from a ListenChannelResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @static
                 * @param {mtechnavi.api.notification.ListenChannelResponse} message ListenChannelResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListenChannelResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.firestoreProjectId = "";
                        object.firestoreCollectionPath = "";
                    }
                    if (message.firestoreProjectId != null && message.hasOwnProperty("firestoreProjectId"))
                        object.firestoreProjectId = message.firestoreProjectId;
                    if (message.firestoreCollectionPath != null && message.hasOwnProperty("firestoreCollectionPath"))
                        object.firestoreCollectionPath = message.firestoreCollectionPath;
                    return object;
                };

                /**
                 * Converts this ListenChannelResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.ListenChannelResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListenChannelResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListenChannelResponse;
            })();

            notification.Event = (function() {

                /**
                 * Properties of an Event.
                 * @memberof mtechnavi.api.notification
                 * @interface IEvent
                 * @property {string|null} [eventId] Event eventId
                 * @property {string|null} [eventName] Event eventName
                 * @property {string|null} [tenantId] Event tenantId
                 * @property {string|null} [userId] Event userId
                 * @property {google.protobuf.IAny|null} [parameters] Event parameters
                 */

                /**
                 * Constructs a new Event.
                 * @memberof mtechnavi.api.notification
                 * @classdesc Represents an Event.
                 * @implements IEvent
                 * @constructor
                 * @param {mtechnavi.api.notification.IEvent=} [properties] Properties to set
                 */
                function Event(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Event eventId.
                 * @member {string} eventId
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 */
                Event.prototype.eventId = "";

                /**
                 * Event eventName.
                 * @member {string} eventName
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 */
                Event.prototype.eventName = "";

                /**
                 * Event tenantId.
                 * @member {string} tenantId
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 */
                Event.prototype.tenantId = "";

                /**
                 * Event userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 */
                Event.prototype.userId = "";

                /**
                 * Event parameters.
                 * @member {google.protobuf.IAny|null|undefined} parameters
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 */
                Event.prototype.parameters = null;

                /**
                 * Verifies an Event message.
                 * @function verify
                 * @memberof mtechnavi.api.notification.Event
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Event.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        if (!$util.isString(message.eventId))
                            return "eventId: string expected";
                    if (message.eventName != null && message.hasOwnProperty("eventName"))
                        if (!$util.isString(message.eventName))
                            return "eventName: string expected";
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        if (!$util.isString(message.tenantId))
                            return "tenantId: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.parameters != null && message.hasOwnProperty("parameters")) {
                        let error = $root.google.protobuf.Any.verify(message.parameters);
                        if (error)
                            return "parameters." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Event message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.notification.Event
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.notification.Event} Event
                 */
                Event.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.notification.Event)
                        return object;
                    let message = new $root.mtechnavi.api.notification.Event();
                    if (object.eventId != null)
                        message.eventId = String(object.eventId);
                    if (object.eventName != null)
                        message.eventName = String(object.eventName);
                    if (object.tenantId != null)
                        message.tenantId = String(object.tenantId);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.parameters != null) {
                        if (typeof object.parameters !== "object")
                            throw TypeError(".mtechnavi.api.notification.Event.parameters: object expected");
                        message.parameters = $root.google.protobuf.Any.fromObject(object.parameters);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Event message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.notification.Event
                 * @static
                 * @param {mtechnavi.api.notification.Event} message Event
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Event.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.eventId = "";
                        object.eventName = "";
                        object.tenantId = "";
                        object.userId = "";
                        object.parameters = null;
                    }
                    if (message.eventId != null && message.hasOwnProperty("eventId"))
                        object.eventId = message.eventId;
                    if (message.eventName != null && message.hasOwnProperty("eventName"))
                        object.eventName = message.eventName;
                    if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                        object.tenantId = message.tenantId;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.parameters != null && message.hasOwnProperty("parameters"))
                        object.parameters = $root.google.protobuf.Any.toObject(message.parameters, options);
                    return object;
                };

                /**
                 * Converts this Event to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.notification.Event
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Event.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Event;
            })();

            return notification;
        })();

        api.programoption = (function() {

            /**
             * Namespace programoption.
             * @memberof mtechnavi.api
             * @namespace
             */
            const programoption = {};

            programoption.ProgramOptionService = (function() {

                /**
                 * Constructs a new ProgramOptionService service.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ProgramOptionService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ProgramOptionService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ProgramOptionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProgramOptionService;

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#createProgramOption}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef CreateProgramOptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ProgramOption} [response] ProgramOption
                 */

                /**
                 * Calls CreateProgramOption.
                 * @function createProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.ICreateProgramOptionRequest} request CreateProgramOptionRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.CreateProgramOptionCallback} callback Node-style callback called with the error, if any, and ProgramOption
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.createProgramOption = function createProgramOption(request, callback) {
                    return this.rpcCall(createProgramOption, $root.mtechnavi.api.programoption.CreateProgramOptionRequest, $root.mtechnavi.api.programoption.ProgramOption, request, callback);
                }, "name", { value: "CreateProgramOption" });

                /**
                 * Calls CreateProgramOption.
                 * @function createProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.ICreateProgramOptionRequest} request CreateProgramOptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ProgramOption>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#listProgramOptions}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef ListProgramOptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ListProgramOptionsResponse} [response] ListProgramOptionsResponse
                 */

                /**
                 * Calls ListProgramOptions.
                 * @function listProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListProgramOptionsRequest} request ListProgramOptionsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.ListProgramOptionsCallback} callback Node-style callback called with the error, if any, and ListProgramOptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.listProgramOptions = function listProgramOptions(request, callback) {
                    return this.rpcCall(listProgramOptions, $root.mtechnavi.api.programoption.ListProgramOptionsRequest, $root.mtechnavi.api.programoption.ListProgramOptionsResponse, request, callback);
                }, "name", { value: "ListProgramOptions" });

                /**
                 * Calls ListProgramOptions.
                 * @function listProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListProgramOptionsRequest} request ListProgramOptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ListProgramOptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#getProgramOption}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef GetProgramOptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ProgramOption} [response] ProgramOption
                 */

                /**
                 * Calls GetProgramOption.
                 * @function getProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IGetProgramOptionRequest} request GetProgramOptionRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.GetProgramOptionCallback} callback Node-style callback called with the error, if any, and ProgramOption
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.getProgramOption = function getProgramOption(request, callback) {
                    return this.rpcCall(getProgramOption, $root.mtechnavi.api.programoption.GetProgramOptionRequest, $root.mtechnavi.api.programoption.ProgramOption, request, callback);
                }, "name", { value: "GetProgramOption" });

                /**
                 * Calls GetProgramOption.
                 * @function getProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IGetProgramOptionRequest} request GetProgramOptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ProgramOption>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#updateProgramOption}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef UpdateProgramOptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ProgramOption} [response] ProgramOption
                 */

                /**
                 * Calls UpdateProgramOption.
                 * @function updateProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IUpdateProgramOptionRequest} request UpdateProgramOptionRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.UpdateProgramOptionCallback} callback Node-style callback called with the error, if any, and ProgramOption
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.updateProgramOption = function updateProgramOption(request, callback) {
                    return this.rpcCall(updateProgramOption, $root.mtechnavi.api.programoption.UpdateProgramOptionRequest, $root.mtechnavi.api.programoption.ProgramOption, request, callback);
                }, "name", { value: "UpdateProgramOption" });

                /**
                 * Calls UpdateProgramOption.
                 * @function updateProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IUpdateProgramOptionRequest} request UpdateProgramOptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ProgramOption>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#deleteProgramOption}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef DeleteProgramOptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteProgramOption.
                 * @function deleteProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IDeleteProgramOptionRequest} request DeleteProgramOptionRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.DeleteProgramOptionCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.deleteProgramOption = function deleteProgramOption(request, callback) {
                    return this.rpcCall(deleteProgramOption, $root.mtechnavi.api.programoption.DeleteProgramOptionRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteProgramOption" });

                /**
                 * Calls DeleteProgramOption.
                 * @function deleteProgramOption
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IDeleteProgramOptionRequest} request DeleteProgramOptionRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#importProgramOptions}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef ImportProgramOptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ImportProgramOptionsResponse} [response] ImportProgramOptionsResponse
                 */

                /**
                 * Calls ImportProgramOptions.
                 * @function importProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IImportProgramOptionsRequest} request ImportProgramOptionsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.ImportProgramOptionsCallback} callback Node-style callback called with the error, if any, and ImportProgramOptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.importProgramOptions = function importProgramOptions(request, callback) {
                    return this.rpcCall(importProgramOptions, $root.mtechnavi.api.programoption.ImportProgramOptionsRequest, $root.mtechnavi.api.programoption.ImportProgramOptionsResponse, request, callback);
                }, "name", { value: "ImportProgramOptions" });

                /**
                 * Calls ImportProgramOptions.
                 * @function importProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IImportProgramOptionsRequest} request ImportProgramOptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ImportProgramOptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#exportProgramOptions}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef ExportProgramOptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ExportProgramOptionsResponse} [response] ExportProgramOptionsResponse
                 */

                /**
                 * Calls ExportProgramOptions.
                 * @function exportProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IExportProgramOptionsRequest} request ExportProgramOptionsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.ExportProgramOptionsCallback} callback Node-style callback called with the error, if any, and ExportProgramOptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.exportProgramOptions = function exportProgramOptions(request, callback) {
                    return this.rpcCall(exportProgramOptions, $root.mtechnavi.api.programoption.ExportProgramOptionsRequest, $root.mtechnavi.api.programoption.ExportProgramOptionsResponse, request, callback);
                }, "name", { value: "ExportProgramOptions" });

                /**
                 * Calls ExportProgramOptions.
                 * @function exportProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IExportProgramOptionsRequest} request ExportProgramOptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ExportProgramOptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#listProgramOptionFormats}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef ListProgramOptionFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ListProgramOptionFormatsResponse} [response] ListProgramOptionFormatsResponse
                 */

                /**
                 * Calls ListProgramOptionFormats.
                 * @function listProgramOptionFormats
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListProgramOptionFormatsRequest} request ListProgramOptionFormatsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.ListProgramOptionFormatsCallback} callback Node-style callback called with the error, if any, and ListProgramOptionFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.listProgramOptionFormats = function listProgramOptionFormats(request, callback) {
                    return this.rpcCall(listProgramOptionFormats, $root.mtechnavi.api.programoption.ListProgramOptionFormatsRequest, $root.mtechnavi.api.programoption.ListProgramOptionFormatsResponse, request, callback);
                }, "name", { value: "ListProgramOptionFormats" });

                /**
                 * Calls ListProgramOptionFormats.
                 * @function listProgramOptionFormats
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListProgramOptionFormatsRequest} request ListProgramOptionFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ListProgramOptionFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#maintenanceProgramOptionFormats}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef MaintenanceProgramOptionFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.MaintenanceProgramOptionsResponse} [response] MaintenanceProgramOptionsResponse
                 */

                /**
                 * Calls MaintenanceProgramOptionFormats.
                 * @function maintenanceProgramOptionFormats
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IMaintenanceProgramOptionsRequest} request MaintenanceProgramOptionsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.MaintenanceProgramOptionFormatsCallback} callback Node-style callback called with the error, if any, and MaintenanceProgramOptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.maintenanceProgramOptionFormats = function maintenanceProgramOptionFormats(request, callback) {
                    return this.rpcCall(maintenanceProgramOptionFormats, $root.mtechnavi.api.programoption.MaintenanceProgramOptionsRequest, $root.mtechnavi.api.programoption.MaintenanceProgramOptionsResponse, request, callback);
                }, "name", { value: "MaintenanceProgramOptionFormats" });

                /**
                 * Calls MaintenanceProgramOptionFormats.
                 * @function maintenanceProgramOptionFormats
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IMaintenanceProgramOptionsRequest} request MaintenanceProgramOptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.MaintenanceProgramOptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.ProgramOptionService#provisionProgramOptions}.
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @typedef ProvisionProgramOptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ProvisionProgramOptionsResponse} [response] ProvisionProgramOptionsResponse
                 */

                /**
                 * Calls ProvisionProgramOptions.
                 * @function provisionProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IProvisionProgramOptionsRequest} request ProvisionProgramOptionsRequest message or plain object
                 * @param {mtechnavi.api.programoption.ProgramOptionService.ProvisionProgramOptionsCallback} callback Node-style callback called with the error, if any, and ProvisionProgramOptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ProgramOptionService.prototype.provisionProgramOptions = function provisionProgramOptions(request, callback) {
                    return this.rpcCall(provisionProgramOptions, $root.mtechnavi.api.programoption.ProvisionProgramOptionsRequest, $root.mtechnavi.api.programoption.ProvisionProgramOptionsResponse, request, callback);
                }, "name", { value: "ProvisionProgramOptions" });

                /**
                 * Calls ProvisionProgramOptions.
                 * @function provisionProgramOptions
                 * @memberof mtechnavi.api.programoption.ProgramOptionService
                 * @instance
                 * @param {mtechnavi.api.programoption.IProvisionProgramOptionsRequest} request ProvisionProgramOptionsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ProvisionProgramOptionsResponse>} Promise
                 * @variation 2
                 */

                return ProgramOptionService;
            })();

            programoption.AttributeService = (function() {

                /**
                 * Constructs a new AttributeService service.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an AttributeService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function AttributeService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (AttributeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AttributeService;

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.AttributeService#saveAttribute}.
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @typedef SaveAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.Attribute} [response] Attribute
                 */

                /**
                 * Calls SaveAttribute.
                 * @function saveAttribute
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.ISaveAttributeRequest} request SaveAttributeRequest message or plain object
                 * @param {mtechnavi.api.programoption.AttributeService.SaveAttributeCallback} callback Node-style callback called with the error, if any, and Attribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AttributeService.prototype.saveAttribute = function saveAttribute(request, callback) {
                    return this.rpcCall(saveAttribute, $root.mtechnavi.api.programoption.SaveAttributeRequest, $root.mtechnavi.api.programoption.Attribute, request, callback);
                }, "name", { value: "SaveAttribute" });

                /**
                 * Calls SaveAttribute.
                 * @function saveAttribute
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.ISaveAttributeRequest} request SaveAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.Attribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.AttributeService#listAttributes}.
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @typedef ListAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.programoption.ListAttributesResponse} [response] ListAttributesResponse
                 */

                /**
                 * Calls ListAttributes.
                 * @function listAttributes
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListAttributesRequest} request ListAttributesRequest message or plain object
                 * @param {mtechnavi.api.programoption.AttributeService.ListAttributesCallback} callback Node-style callback called with the error, if any, and ListAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AttributeService.prototype.listAttributes = function listAttributes(request, callback) {
                    return this.rpcCall(listAttributes, $root.mtechnavi.api.programoption.ListAttributesRequest, $root.mtechnavi.api.programoption.ListAttributesResponse, request, callback);
                }, "name", { value: "ListAttributes" });

                /**
                 * Calls ListAttributes.
                 * @function listAttributes
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.IListAttributesRequest} request ListAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.programoption.ListAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.programoption.AttributeService#deleteAttribute}.
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @typedef DeleteAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteAttribute.
                 * @function deleteAttribute
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.IDeleteAttributeRequest} request DeleteAttributeRequest message or plain object
                 * @param {mtechnavi.api.programoption.AttributeService.DeleteAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(AttributeService.prototype.deleteAttribute = function deleteAttribute(request, callback) {
                    return this.rpcCall(deleteAttribute, $root.mtechnavi.api.programoption.DeleteAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteAttribute" });

                /**
                 * Calls DeleteAttribute.
                 * @function deleteAttribute
                 * @memberof mtechnavi.api.programoption.AttributeService
                 * @instance
                 * @param {mtechnavi.api.programoption.IDeleteAttributeRequest} request DeleteAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return AttributeService;
            })();

            programoption.CreateProgramOptionRequest = (function() {

                /**
                 * Properties of a CreateProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface ICreateProgramOptionRequest
                 * @property {mtechnavi.api.programoption.IProgramOption|null} [programOption] CreateProgramOptionRequest programOption
                 */

                /**
                 * Constructs a new CreateProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a CreateProgramOptionRequest.
                 * @implements ICreateProgramOptionRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.ICreateProgramOptionRequest=} [properties] Properties to set
                 */
                function CreateProgramOptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateProgramOptionRequest programOption.
                 * @member {mtechnavi.api.programoption.IProgramOption|null|undefined} programOption
                 * @memberof mtechnavi.api.programoption.CreateProgramOptionRequest
                 * @instance
                 */
                CreateProgramOptionRequest.prototype.programOption = null;

                /**
                 * Verifies a CreateProgramOptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.CreateProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateProgramOptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOption != null && message.hasOwnProperty("programOption")) {
                        let error = $root.mtechnavi.api.programoption.ProgramOption.verify(message.programOption);
                        if (error)
                            return "programOption." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateProgramOptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.CreateProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.CreateProgramOptionRequest} CreateProgramOptionRequest
                 */
                CreateProgramOptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.CreateProgramOptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.CreateProgramOptionRequest();
                    if (object.programOption != null) {
                        if (typeof object.programOption !== "object")
                            throw TypeError(".mtechnavi.api.programoption.CreateProgramOptionRequest.programOption: object expected");
                        message.programOption = $root.mtechnavi.api.programoption.ProgramOption.fromObject(object.programOption);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateProgramOptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.CreateProgramOptionRequest
                 * @static
                 * @param {mtechnavi.api.programoption.CreateProgramOptionRequest} message CreateProgramOptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateProgramOptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.programOption = null;
                    if (message.programOption != null && message.hasOwnProperty("programOption"))
                        object.programOption = $root.mtechnavi.api.programoption.ProgramOption.toObject(message.programOption, options);
                    return object;
                };

                /**
                 * Converts this CreateProgramOptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.CreateProgramOptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateProgramOptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateProgramOptionRequest;
            })();

            programoption.ListProgramOptionsRequest = (function() {

                /**
                 * Properties of a ListProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListProgramOptionsRequest
                 * @property {string|null} [pageToken] ListProgramOptionsRequest pageToken
                 * @property {mtechnavi.api.programoption.ListProgramOptionsRequest.IDataFilter|null} [dataFilter] ListProgramOptionsRequest dataFilter
                 */

                /**
                 * Constructs a new ListProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListProgramOptionsRequest.
                 * @implements IListProgramOptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListProgramOptionsRequest=} [properties] Properties to set
                 */
                function ListProgramOptionsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListProgramOptionsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @instance
                 */
                ListProgramOptionsRequest.prototype.pageToken = "";

                /**
                 * ListProgramOptionsRequest dataFilter.
                 * @member {mtechnavi.api.programoption.ListProgramOptionsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @instance
                 */
                ListProgramOptionsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListProgramOptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListProgramOptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListProgramOptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListProgramOptionsRequest} ListProgramOptionsRequest
                 */
                ListProgramOptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListProgramOptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ListProgramOptionsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ListProgramOptionsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListProgramOptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ListProgramOptionsRequest} message ListProgramOptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListProgramOptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListProgramOptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListProgramOptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListProgramOptionsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [categoryNames] DataFilter categoryNames
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.programoption.ListProgramOptionsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.categoryNames = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter categoryNames.
                     * @member {Array.<string>} categoryNames
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.categoryNames = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.categoryNames != null && message.hasOwnProperty("categoryNames")) {
                            if (!Array.isArray(message.categoryNames))
                                return "categoryNames: array expected";
                            for (let i = 0; i < message.categoryNames.length; ++i)
                                if (!$util.isString(message.categoryNames[i]))
                                    return "categoryNames: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter();
                        if (object.categoryNames) {
                            if (!Array.isArray(object.categoryNames))
                                throw TypeError(".mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter.categoryNames: array expected");
                            message.categoryNames = [];
                            for (let i = 0; i < object.categoryNames.length; ++i)
                                message.categoryNames[i] = String(object.categoryNames[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.categoryNames = [];
                        if (message.categoryNames && message.categoryNames.length) {
                            object.categoryNames = [];
                            for (let j = 0; j < message.categoryNames.length; ++j)
                                object.categoryNames[j] = message.categoryNames[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.programoption.ListProgramOptionsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListProgramOptionsRequest;
            })();

            programoption.ListProgramOptionsResponse = (function() {

                /**
                 * Properties of a ListProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListProgramOptionsResponse
                 * @property {Long|null} [total] ListProgramOptionsResponse total
                 * @property {Array.<mtechnavi.api.programoption.IProgramOption>|null} [items] ListProgramOptionsResponse items
                 * @property {string|null} [pageToken] ListProgramOptionsResponse pageToken
                 */

                /**
                 * Constructs a new ListProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListProgramOptionsResponse.
                 * @implements IListProgramOptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListProgramOptionsResponse=} [properties] Properties to set
                 */
                function ListProgramOptionsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListProgramOptionsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @instance
                 */
                ListProgramOptionsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListProgramOptionsResponse items.
                 * @member {Array.<mtechnavi.api.programoption.IProgramOption>} items
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @instance
                 */
                ListProgramOptionsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListProgramOptionsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @instance
                 */
                ListProgramOptionsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListProgramOptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListProgramOptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.programoption.ProgramOption.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListProgramOptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListProgramOptionsResponse} ListProgramOptionsResponse
                 */
                ListProgramOptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListProgramOptionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ListProgramOptionsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.programoption.ListProgramOptionsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.programoption.ListProgramOptionsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.programoption.ProgramOption.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListProgramOptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ListProgramOptionsResponse} message ListProgramOptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListProgramOptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.programoption.ProgramOption.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListProgramOptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListProgramOptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListProgramOptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListProgramOptionsResponse;
            })();

            programoption.GetProgramOptionRequest = (function() {

                /**
                 * Properties of a GetProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IGetProgramOptionRequest
                 * @property {string|null} [programOptionId] GetProgramOptionRequest programOptionId
                 */

                /**
                 * Constructs a new GetProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a GetProgramOptionRequest.
                 * @implements IGetProgramOptionRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IGetProgramOptionRequest=} [properties] Properties to set
                 */
                function GetProgramOptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetProgramOptionRequest programOptionId.
                 * @member {string} programOptionId
                 * @memberof mtechnavi.api.programoption.GetProgramOptionRequest
                 * @instance
                 */
                GetProgramOptionRequest.prototype.programOptionId = "";

                /**
                 * Verifies a GetProgramOptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.GetProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetProgramOptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOptionId != null && message.hasOwnProperty("programOptionId"))
                        if (!$util.isString(message.programOptionId))
                            return "programOptionId: string expected";
                    return null;
                };

                /**
                 * Creates a GetProgramOptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.GetProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.GetProgramOptionRequest} GetProgramOptionRequest
                 */
                GetProgramOptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.GetProgramOptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.GetProgramOptionRequest();
                    if (object.programOptionId != null)
                        message.programOptionId = String(object.programOptionId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetProgramOptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.GetProgramOptionRequest
                 * @static
                 * @param {mtechnavi.api.programoption.GetProgramOptionRequest} message GetProgramOptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetProgramOptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.programOptionId = "";
                    if (message.programOptionId != null && message.hasOwnProperty("programOptionId"))
                        object.programOptionId = message.programOptionId;
                    return object;
                };

                /**
                 * Converts this GetProgramOptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.GetProgramOptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetProgramOptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetProgramOptionRequest;
            })();

            programoption.UpdateProgramOptionRequest = (function() {

                /**
                 * Properties of an UpdateProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IUpdateProgramOptionRequest
                 * @property {mtechnavi.api.programoption.IProgramOption|null} [programOption] UpdateProgramOptionRequest programOption
                 */

                /**
                 * Constructs a new UpdateProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an UpdateProgramOptionRequest.
                 * @implements IUpdateProgramOptionRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IUpdateProgramOptionRequest=} [properties] Properties to set
                 */
                function UpdateProgramOptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProgramOptionRequest programOption.
                 * @member {mtechnavi.api.programoption.IProgramOption|null|undefined} programOption
                 * @memberof mtechnavi.api.programoption.UpdateProgramOptionRequest
                 * @instance
                 */
                UpdateProgramOptionRequest.prototype.programOption = null;

                /**
                 * Verifies an UpdateProgramOptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.UpdateProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProgramOptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOption != null && message.hasOwnProperty("programOption")) {
                        let error = $root.mtechnavi.api.programoption.ProgramOption.verify(message.programOption);
                        if (error)
                            return "programOption." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateProgramOptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.UpdateProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.UpdateProgramOptionRequest} UpdateProgramOptionRequest
                 */
                UpdateProgramOptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.UpdateProgramOptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.UpdateProgramOptionRequest();
                    if (object.programOption != null) {
                        if (typeof object.programOption !== "object")
                            throw TypeError(".mtechnavi.api.programoption.UpdateProgramOptionRequest.programOption: object expected");
                        message.programOption = $root.mtechnavi.api.programoption.ProgramOption.fromObject(object.programOption);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProgramOptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.UpdateProgramOptionRequest
                 * @static
                 * @param {mtechnavi.api.programoption.UpdateProgramOptionRequest} message UpdateProgramOptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProgramOptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.programOption = null;
                    if (message.programOption != null && message.hasOwnProperty("programOption"))
                        object.programOption = $root.mtechnavi.api.programoption.ProgramOption.toObject(message.programOption, options);
                    return object;
                };

                /**
                 * Converts this UpdateProgramOptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.UpdateProgramOptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProgramOptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProgramOptionRequest;
            })();

            programoption.DeleteProgramOptionRequest = (function() {

                /**
                 * Properties of a DeleteProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IDeleteProgramOptionRequest
                 * @property {mtechnavi.api.programoption.IProgramOption|null} [programOption] DeleteProgramOptionRequest programOption
                 */

                /**
                 * Constructs a new DeleteProgramOptionRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a DeleteProgramOptionRequest.
                 * @implements IDeleteProgramOptionRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IDeleteProgramOptionRequest=} [properties] Properties to set
                 */
                function DeleteProgramOptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteProgramOptionRequest programOption.
                 * @member {mtechnavi.api.programoption.IProgramOption|null|undefined} programOption
                 * @memberof mtechnavi.api.programoption.DeleteProgramOptionRequest
                 * @instance
                 */
                DeleteProgramOptionRequest.prototype.programOption = null;

                /**
                 * Verifies a DeleteProgramOptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.DeleteProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteProgramOptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOption != null && message.hasOwnProperty("programOption")) {
                        let error = $root.mtechnavi.api.programoption.ProgramOption.verify(message.programOption);
                        if (error)
                            return "programOption." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteProgramOptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.DeleteProgramOptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.DeleteProgramOptionRequest} DeleteProgramOptionRequest
                 */
                DeleteProgramOptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.DeleteProgramOptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.DeleteProgramOptionRequest();
                    if (object.programOption != null) {
                        if (typeof object.programOption !== "object")
                            throw TypeError(".mtechnavi.api.programoption.DeleteProgramOptionRequest.programOption: object expected");
                        message.programOption = $root.mtechnavi.api.programoption.ProgramOption.fromObject(object.programOption);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteProgramOptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.DeleteProgramOptionRequest
                 * @static
                 * @param {mtechnavi.api.programoption.DeleteProgramOptionRequest} message DeleteProgramOptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteProgramOptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.programOption = null;
                    if (message.programOption != null && message.hasOwnProperty("programOption"))
                        object.programOption = $root.mtechnavi.api.programoption.ProgramOption.toObject(message.programOption, options);
                    return object;
                };

                /**
                 * Converts this DeleteProgramOptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.DeleteProgramOptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteProgramOptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteProgramOptionRequest;
            })();

            programoption.ImportProgramOptionsRequest = (function() {

                /**
                 * Properties of an ImportProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IImportProgramOptionsRequest
                 * @property {string|null} [url] ImportProgramOptionsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportProgramOptionsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportProgramOptionsRequest dryRun
                 * @property {boolean|null} [exportError] ImportProgramOptionsRequest exportError
                 */

                /**
                 * Constructs a new ImportProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an ImportProgramOptionsRequest.
                 * @implements IImportProgramOptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IImportProgramOptionsRequest=} [properties] Properties to set
                 */
                function ImportProgramOptionsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportProgramOptionsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @instance
                 */
                ImportProgramOptionsRequest.prototype.url = "";

                /**
                 * ImportProgramOptionsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @instance
                 */
                ImportProgramOptionsRequest.prototype.fileFormat = null;

                /**
                 * ImportProgramOptionsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @instance
                 */
                ImportProgramOptionsRequest.prototype.dryRun = false;

                /**
                 * ImportProgramOptionsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @instance
                 */
                ImportProgramOptionsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportProgramOptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportProgramOptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportProgramOptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ImportProgramOptionsRequest} ImportProgramOptionsRequest
                 */
                ImportProgramOptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ImportProgramOptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ImportProgramOptionsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ImportProgramOptionsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportProgramOptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ImportProgramOptionsRequest} message ImportProgramOptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportProgramOptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportProgramOptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportProgramOptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportProgramOptionsRequest;
            })();

            programoption.ImportProgramOptionsResponse = (function() {

                /**
                 * Properties of an ImportProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IImportProgramOptionsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportProgramOptionsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportProgramOptionsResponse errors
                 * @property {string|null} [assetId] ImportProgramOptionsResponse assetId
                 */

                /**
                 * Constructs a new ImportProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an ImportProgramOptionsResponse.
                 * @implements IImportProgramOptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IImportProgramOptionsResponse=} [properties] Properties to set
                 */
                function ImportProgramOptionsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportProgramOptionsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @instance
                 */
                ImportProgramOptionsResponse.prototype.summary = null;

                /**
                 * ImportProgramOptionsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @instance
                 */
                ImportProgramOptionsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportProgramOptionsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @instance
                 */
                ImportProgramOptionsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportProgramOptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportProgramOptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportProgramOptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ImportProgramOptionsResponse} ImportProgramOptionsResponse
                 */
                ImportProgramOptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ImportProgramOptionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ImportProgramOptionsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ImportProgramOptionsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.programoption.ImportProgramOptionsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.programoption.ImportProgramOptionsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportProgramOptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ImportProgramOptionsResponse} message ImportProgramOptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportProgramOptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportProgramOptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ImportProgramOptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportProgramOptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportProgramOptionsResponse;
            })();

            programoption.ExportProgramOptionsRequest = (function() {

                /**
                 * Properties of an ExportProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IExportProgramOptionsRequest
                 * @property {Array.<string>|null} [ids] ExportProgramOptionsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportProgramOptionsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an ExportProgramOptionsRequest.
                 * @implements IExportProgramOptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IExportProgramOptionsRequest=} [properties] Properties to set
                 */
                function ExportProgramOptionsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportProgramOptionsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @instance
                 */
                ExportProgramOptionsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportProgramOptionsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @instance
                 */
                ExportProgramOptionsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportProgramOptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportProgramOptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportProgramOptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ExportProgramOptionsRequest} ExportProgramOptionsRequest
                 */
                ExportProgramOptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ExportProgramOptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ExportProgramOptionsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.programoption.ExportProgramOptionsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ExportProgramOptionsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportProgramOptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ExportProgramOptionsRequest} message ExportProgramOptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportProgramOptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportProgramOptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportProgramOptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportProgramOptionsRequest;
            })();

            programoption.ExportProgramOptionsResponse = (function() {

                /**
                 * Properties of an ExportProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IExportProgramOptionsResponse
                 * @property {string|null} [assetId] ExportProgramOptionsResponse assetId
                 */

                /**
                 * Constructs a new ExportProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an ExportProgramOptionsResponse.
                 * @implements IExportProgramOptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IExportProgramOptionsResponse=} [properties] Properties to set
                 */
                function ExportProgramOptionsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportProgramOptionsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsResponse
                 * @instance
                 */
                ExportProgramOptionsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportProgramOptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportProgramOptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportProgramOptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ExportProgramOptionsResponse} ExportProgramOptionsResponse
                 */
                ExportProgramOptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ExportProgramOptionsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ExportProgramOptionsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportProgramOptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ExportProgramOptionsResponse} message ExportProgramOptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportProgramOptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportProgramOptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ExportProgramOptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportProgramOptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportProgramOptionsResponse;
            })();

            programoption.ListProgramOptionFormatsRequest = (function() {

                /**
                 * Properties of a ListProgramOptionFormatsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListProgramOptionFormatsRequest
                 */

                /**
                 * Constructs a new ListProgramOptionFormatsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListProgramOptionFormatsRequest.
                 * @implements IListProgramOptionFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListProgramOptionFormatsRequest=} [properties] Properties to set
                 */
                function ListProgramOptionFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListProgramOptionFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListProgramOptionFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListProgramOptionFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListProgramOptionFormatsRequest} ListProgramOptionFormatsRequest
                 */
                ListProgramOptionFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListProgramOptionFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.programoption.ListProgramOptionFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListProgramOptionFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ListProgramOptionFormatsRequest} message ListProgramOptionFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListProgramOptionFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListProgramOptionFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListProgramOptionFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListProgramOptionFormatsRequest;
            })();

            programoption.ListProgramOptionFormatsResponse = (function() {

                /**
                 * Properties of a ListProgramOptionFormatsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListProgramOptionFormatsResponse
                 * @property {Long|null} [total] ListProgramOptionFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListProgramOptionFormatsResponse items
                 */

                /**
                 * Constructs a new ListProgramOptionFormatsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListProgramOptionFormatsResponse.
                 * @implements IListProgramOptionFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListProgramOptionFormatsResponse=} [properties] Properties to set
                 */
                function ListProgramOptionFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListProgramOptionFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @instance
                 */
                ListProgramOptionFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListProgramOptionFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @instance
                 */
                ListProgramOptionFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListProgramOptionFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListProgramOptionFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListProgramOptionFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListProgramOptionFormatsResponse} ListProgramOptionFormatsResponse
                 */
                ListProgramOptionFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListProgramOptionFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ListProgramOptionFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.programoption.ListProgramOptionFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.programoption.ListProgramOptionFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListProgramOptionFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ListProgramOptionFormatsResponse} message ListProgramOptionFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListProgramOptionFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListProgramOptionFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListProgramOptionFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListProgramOptionFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListProgramOptionFormatsResponse;
            })();

            programoption.MaintenanceProgramOptionsRequest = (function() {

                /**
                 * Properties of a MaintenanceProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IMaintenanceProgramOptionsRequest
                 * @property {Array.<mtechnavi.api.programoption.IProgramOption>|null} [programOptions] MaintenanceProgramOptionsRequest programOptions
                 * @property {mtechnavi.api.programoption.MaintenanceMode|null} [mode] MaintenanceProgramOptionsRequest mode
                 */

                /**
                 * Constructs a new MaintenanceProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a MaintenanceProgramOptionsRequest.
                 * @implements IMaintenanceProgramOptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IMaintenanceProgramOptionsRequest=} [properties] Properties to set
                 */
                function MaintenanceProgramOptionsRequest(properties) {
                    this.programOptions = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MaintenanceProgramOptionsRequest programOptions.
                 * @member {Array.<mtechnavi.api.programoption.IProgramOption>} programOptions
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @instance
                 */
                MaintenanceProgramOptionsRequest.prototype.programOptions = $util.emptyArray;

                /**
                 * MaintenanceProgramOptionsRequest mode.
                 * @member {mtechnavi.api.programoption.MaintenanceMode} mode
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @instance
                 */
                MaintenanceProgramOptionsRequest.prototype.mode = 0;

                /**
                 * Verifies a MaintenanceProgramOptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceProgramOptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOptions != null && message.hasOwnProperty("programOptions")) {
                        if (!Array.isArray(message.programOptions))
                            return "programOptions: array expected";
                        for (let i = 0; i < message.programOptions.length; ++i) {
                            let error = $root.mtechnavi.api.programoption.ProgramOption.verify(message.programOptions[i]);
                            if (error)
                                return "programOptions." + error;
                        }
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        switch (message.mode) {
                        default:
                            return "mode: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a MaintenanceProgramOptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.MaintenanceProgramOptionsRequest} MaintenanceProgramOptionsRequest
                 */
                MaintenanceProgramOptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.MaintenanceProgramOptionsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.MaintenanceProgramOptionsRequest();
                    if (object.programOptions) {
                        if (!Array.isArray(object.programOptions))
                            throw TypeError(".mtechnavi.api.programoption.MaintenanceProgramOptionsRequest.programOptions: array expected");
                        message.programOptions = [];
                        for (let i = 0; i < object.programOptions.length; ++i) {
                            if (typeof object.programOptions[i] !== "object")
                                throw TypeError(".mtechnavi.api.programoption.MaintenanceProgramOptionsRequest.programOptions: object expected");
                            message.programOptions[i] = $root.mtechnavi.api.programoption.ProgramOption.fromObject(object.programOptions[i]);
                        }
                    }
                    switch (object.mode) {
                    case "Complete":
                    case 0:
                        message.mode = 0;
                        break;
                    case "Incremental":
                    case 1:
                        message.mode = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MaintenanceProgramOptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.MaintenanceProgramOptionsRequest} message MaintenanceProgramOptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceProgramOptionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.programOptions = [];
                    if (options.defaults)
                        object.mode = options.enums === String ? "Complete" : 0;
                    if (message.programOptions && message.programOptions.length) {
                        object.programOptions = [];
                        for (let j = 0; j < message.programOptions.length; ++j)
                            object.programOptions[j] = $root.mtechnavi.api.programoption.ProgramOption.toObject(message.programOptions[j], options);
                    }
                    if (message.mode != null && message.hasOwnProperty("mode"))
                        object.mode = options.enums === String ? $root.mtechnavi.api.programoption.MaintenanceMode[message.mode] : message.mode;
                    return object;
                };

                /**
                 * Converts this MaintenanceProgramOptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceProgramOptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return MaintenanceProgramOptionsRequest;
            })();

            programoption.MaintenanceProgramOptionsResponse = (function() {

                /**
                 * Properties of a MaintenanceProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IMaintenanceProgramOptionsResponse
                 */

                /**
                 * Constructs a new MaintenanceProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a MaintenanceProgramOptionsResponse.
                 * @implements IMaintenanceProgramOptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IMaintenanceProgramOptionsResponse=} [properties] Properties to set
                 */
                function MaintenanceProgramOptionsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a MaintenanceProgramOptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MaintenanceProgramOptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a MaintenanceProgramOptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.MaintenanceProgramOptionsResponse} MaintenanceProgramOptionsResponse
                 */
                MaintenanceProgramOptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.MaintenanceProgramOptionsResponse)
                        return object;
                    return new $root.mtechnavi.api.programoption.MaintenanceProgramOptionsResponse();
                };

                /**
                 * Creates a plain object from a MaintenanceProgramOptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.MaintenanceProgramOptionsResponse} message MaintenanceProgramOptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MaintenanceProgramOptionsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this MaintenanceProgramOptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.MaintenanceProgramOptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MaintenanceProgramOptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return MaintenanceProgramOptionsResponse;
            })();

            /**
             * MaintenanceMode enum.
             * @name mtechnavi.api.programoption.MaintenanceMode
             * @enum {number}
             * @property {number} Complete=0 Complete value
             * @property {number} Incremental=1 Incremental value
             */
            programoption.MaintenanceMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "Complete"] = 0;
                values[valuesById[1] = "Incremental"] = 1;
                return values;
            })();

            programoption.ProvisionProgramOptionsRequest = (function() {

                /**
                 * Properties of a ProvisionProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IProvisionProgramOptionsRequest
                 */

                /**
                 * Constructs a new ProvisionProgramOptionsRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ProvisionProgramOptionsRequest.
                 * @implements IProvisionProgramOptionsRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IProvisionProgramOptionsRequest=} [properties] Properties to set
                 */
                function ProvisionProgramOptionsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ProvisionProgramOptionsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProvisionProgramOptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ProvisionProgramOptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ProvisionProgramOptionsRequest} ProvisionProgramOptionsRequest
                 */
                ProvisionProgramOptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ProvisionProgramOptionsRequest)
                        return object;
                    return new $root.mtechnavi.api.programoption.ProvisionProgramOptionsRequest();
                };

                /**
                 * Creates a plain object from a ProvisionProgramOptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ProvisionProgramOptionsRequest} message ProvisionProgramOptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProvisionProgramOptionsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ProvisionProgramOptionsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProvisionProgramOptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ProvisionProgramOptionsRequest;
            })();

            programoption.ProvisionProgramOptionsResponse = (function() {

                /**
                 * Properties of a ProvisionProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IProvisionProgramOptionsResponse
                 */

                /**
                 * Constructs a new ProvisionProgramOptionsResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ProvisionProgramOptionsResponse.
                 * @implements IProvisionProgramOptionsResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IProvisionProgramOptionsResponse=} [properties] Properties to set
                 */
                function ProvisionProgramOptionsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ProvisionProgramOptionsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProvisionProgramOptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ProvisionProgramOptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ProvisionProgramOptionsResponse} ProvisionProgramOptionsResponse
                 */
                ProvisionProgramOptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ProvisionProgramOptionsResponse)
                        return object;
                    return new $root.mtechnavi.api.programoption.ProvisionProgramOptionsResponse();
                };

                /**
                 * Creates a plain object from a ProvisionProgramOptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ProvisionProgramOptionsResponse} message ProvisionProgramOptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProvisionProgramOptionsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ProvisionProgramOptionsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ProvisionProgramOptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProvisionProgramOptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ProvisionProgramOptionsResponse;
            })();

            programoption.SaveAttributeRequest = (function() {

                /**
                 * Properties of a SaveAttributeRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface ISaveAttributeRequest
                 * @property {mtechnavi.api.programoption.IAttribute|null} [attribute] SaveAttributeRequest attribute
                 */

                /**
                 * Constructs a new SaveAttributeRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a SaveAttributeRequest.
                 * @implements ISaveAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.ISaveAttributeRequest=} [properties] Properties to set
                 */
                function SaveAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveAttributeRequest attribute.
                 * @member {mtechnavi.api.programoption.IAttribute|null|undefined} attribute
                 * @memberof mtechnavi.api.programoption.SaveAttributeRequest
                 * @instance
                 */
                SaveAttributeRequest.prototype.attribute = null;

                /**
                 * Verifies a SaveAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.SaveAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attribute != null && message.hasOwnProperty("attribute")) {
                        let error = $root.mtechnavi.api.programoption.Attribute.verify(message.attribute);
                        if (error)
                            return "attribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.SaveAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.SaveAttributeRequest} SaveAttributeRequest
                 */
                SaveAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.SaveAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.SaveAttributeRequest();
                    if (object.attribute != null) {
                        if (typeof object.attribute !== "object")
                            throw TypeError(".mtechnavi.api.programoption.SaveAttributeRequest.attribute: object expected");
                        message.attribute = $root.mtechnavi.api.programoption.Attribute.fromObject(object.attribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.SaveAttributeRequest
                 * @static
                 * @param {mtechnavi.api.programoption.SaveAttributeRequest} message SaveAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.attribute = null;
                    if (message.attribute != null && message.hasOwnProperty("attribute"))
                        object.attribute = $root.mtechnavi.api.programoption.Attribute.toObject(message.attribute, options);
                    return object;
                };

                /**
                 * Converts this SaveAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.SaveAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveAttributeRequest;
            })();

            programoption.ListAttributesRequest = (function() {

                /**
                 * Properties of a ListAttributesRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListAttributesRequest
                 * @property {string|null} [pageToken] ListAttributesRequest pageToken
                 * @property {Array.<string>|null} [attributeIds] ListAttributesRequest attributeIds
                 */

                /**
                 * Constructs a new ListAttributesRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListAttributesRequest.
                 * @implements IListAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListAttributesRequest=} [properties] Properties to set
                 */
                function ListAttributesRequest(properties) {
                    this.attributeIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @instance
                 */
                ListAttributesRequest.prototype.pageToken = "";

                /**
                 * ListAttributesRequest attributeIds.
                 * @member {Array.<string>} attributeIds
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @instance
                 */
                ListAttributesRequest.prototype.attributeIds = $util.emptyArray;

                /**
                 * Verifies a ListAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.attributeIds != null && message.hasOwnProperty("attributeIds")) {
                        if (!Array.isArray(message.attributeIds))
                            return "attributeIds: array expected";
                        for (let i = 0; i < message.attributeIds.length; ++i)
                            if (!$util.isString(message.attributeIds[i]))
                                return "attributeIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListAttributesRequest} ListAttributesRequest
                 */
                ListAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ListAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.attributeIds) {
                        if (!Array.isArray(object.attributeIds))
                            throw TypeError(".mtechnavi.api.programoption.ListAttributesRequest.attributeIds: array expected");
                        message.attributeIds = [];
                        for (let i = 0; i < object.attributeIds.length; ++i)
                            message.attributeIds[i] = String(object.attributeIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @static
                 * @param {mtechnavi.api.programoption.ListAttributesRequest} message ListAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attributeIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.attributeIds && message.attributeIds.length) {
                        object.attributeIds = [];
                        for (let j = 0; j < message.attributeIds.length; ++j)
                            object.attributeIds[j] = message.attributeIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAttributesRequest;
            })();

            programoption.ListAttributesResponse = (function() {

                /**
                 * Properties of a ListAttributesResponse.
                 * @memberof mtechnavi.api.programoption
                 * @interface IListAttributesResponse
                 * @property {Long|null} [total] ListAttributesResponse total
                 * @property {Array.<mtechnavi.api.programoption.IAttribute>|null} [items] ListAttributesResponse items
                 * @property {string|null} [pageToken] ListAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListAttributesResponse.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ListAttributesResponse.
                 * @implements IListAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.programoption.IListAttributesResponse=} [properties] Properties to set
                 */
                function ListAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @instance
                 */
                ListAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListAttributesResponse items.
                 * @member {Array.<mtechnavi.api.programoption.IAttribute>} items
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @instance
                 */
                ListAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @instance
                 */
                ListAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.programoption.Attribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ListAttributesResponse} ListAttributesResponse
                 */
                ListAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ListAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ListAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.programoption.ListAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.programoption.ListAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.programoption.Attribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @static
                 * @param {mtechnavi.api.programoption.ListAttributesResponse} message ListAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.programoption.Attribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ListAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListAttributesResponse;
            })();

            programoption.DeleteAttributeRequest = (function() {

                /**
                 * Properties of a DeleteAttributeRequest.
                 * @memberof mtechnavi.api.programoption
                 * @interface IDeleteAttributeRequest
                 * @property {mtechnavi.api.programoption.IAttribute|null} [attribute] DeleteAttributeRequest attribute
                 */

                /**
                 * Constructs a new DeleteAttributeRequest.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a DeleteAttributeRequest.
                 * @implements IDeleteAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.programoption.IDeleteAttributeRequest=} [properties] Properties to set
                 */
                function DeleteAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteAttributeRequest attribute.
                 * @member {mtechnavi.api.programoption.IAttribute|null|undefined} attribute
                 * @memberof mtechnavi.api.programoption.DeleteAttributeRequest
                 * @instance
                 */
                DeleteAttributeRequest.prototype.attribute = null;

                /**
                 * Verifies a DeleteAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.DeleteAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attribute != null && message.hasOwnProperty("attribute")) {
                        let error = $root.mtechnavi.api.programoption.Attribute.verify(message.attribute);
                        if (error)
                            return "attribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.DeleteAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.DeleteAttributeRequest} DeleteAttributeRequest
                 */
                DeleteAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.DeleteAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.DeleteAttributeRequest();
                    if (object.attribute != null) {
                        if (typeof object.attribute !== "object")
                            throw TypeError(".mtechnavi.api.programoption.DeleteAttributeRequest.attribute: object expected");
                        message.attribute = $root.mtechnavi.api.programoption.Attribute.fromObject(object.attribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.DeleteAttributeRequest
                 * @static
                 * @param {mtechnavi.api.programoption.DeleteAttributeRequest} message DeleteAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.attribute = null;
                    if (message.attribute != null && message.hasOwnProperty("attribute"))
                        object.attribute = $root.mtechnavi.api.programoption.Attribute.toObject(message.attribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.DeleteAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteAttributeRequest;
            })();

            programoption.ProgramOption = (function() {

                /**
                 * Properties of a ProgramOption.
                 * @memberof mtechnavi.api.programoption
                 * @interface IProgramOption
                 * @property {string|null} [programOptionId] ProgramOption programOptionId
                 * @property {string|null} [categoryName] ProgramOption categoryName
                 * @property {mtechnavi.api.programoption.ProgramOption.IDisplayNameLang|null} [category] ProgramOption category
                 * @property {string|null} [code] ProgramOption code
                 * @property {Object.<string,string>|null} [displayNameLang] ProgramOption displayNameLang
                 * @property {string|null} [systemName] ProgramOption systemName
                 * @property {mtechnavi.api.programoption.ProgramOption.IDisplayNameLang|null} [system] ProgramOption system
                 * @property {number|null} [order] ProgramOption order
                 * @property {boolean|null} [userEditable] ProgramOption userEditable
                 * @property {boolean|null} [userEdited] ProgramOption userEdited
                 * @property {Long|null} [deletedAt] ProgramOption deletedAt
                 * @property {Long|null} [createdAt] ProgramOption createdAt
                 * @property {Long|null} [updatedAt] ProgramOption updatedAt
                 */

                /**
                 * Constructs a new ProgramOption.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents a ProgramOption.
                 * @implements IProgramOption
                 * @constructor
                 * @param {mtechnavi.api.programoption.IProgramOption=} [properties] Properties to set
                 */
                function ProgramOption(properties) {
                    this.displayNameLang = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProgramOption programOptionId.
                 * @member {string} programOptionId
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.programOptionId = "";

                /**
                 * ProgramOption categoryName.
                 * @member {string} categoryName
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.categoryName = "";

                /**
                 * ProgramOption category.
                 * @member {mtechnavi.api.programoption.ProgramOption.IDisplayNameLang|null|undefined} category
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.category = null;

                /**
                 * ProgramOption code.
                 * @member {string} code
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.code = "";

                /**
                 * ProgramOption displayNameLang.
                 * @member {Object.<string,string>} displayNameLang
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.displayNameLang = $util.emptyObject;

                /**
                 * ProgramOption systemName.
                 * @member {string} systemName
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.systemName = "";

                /**
                 * ProgramOption system.
                 * @member {mtechnavi.api.programoption.ProgramOption.IDisplayNameLang|null|undefined} system
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.system = null;

                /**
                 * ProgramOption order.
                 * @member {number} order
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.order = 0;

                /**
                 * ProgramOption userEditable.
                 * @member {boolean} userEditable
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.userEditable = false;

                /**
                 * ProgramOption userEdited.
                 * @member {boolean} userEdited
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.userEdited = false;

                /**
                 * ProgramOption deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProgramOption createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ProgramOption updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 */
                ProgramOption.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a ProgramOption message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProgramOption.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.programOptionId != null && message.hasOwnProperty("programOptionId"))
                        if (!$util.isString(message.programOptionId))
                            return "programOptionId: string expected";
                    if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                        if (!$util.isString(message.categoryName))
                            return "categoryName: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                        if (!$util.isObject(message.displayNameLang))
                            return "displayNameLang: object expected";
                        let key = Object.keys(message.displayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.displayNameLang[key[i]]))
                                return "displayNameLang: string{k:string} expected";
                    }
                    if (message.systemName != null && message.hasOwnProperty("systemName"))
                        if (!$util.isString(message.systemName))
                            return "systemName: string expected";
                    if (message.system != null && message.hasOwnProperty("system")) {
                        let error = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.verify(message.system);
                        if (error)
                            return "system." + error;
                    }
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order))
                            return "order: integer expected";
                    if (message.userEditable != null && message.hasOwnProperty("userEditable"))
                        if (typeof message.userEditable !== "boolean")
                            return "userEditable: boolean expected";
                    if (message.userEdited != null && message.hasOwnProperty("userEdited"))
                        if (typeof message.userEdited !== "boolean")
                            return "userEdited: boolean expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ProgramOption message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.ProgramOption} ProgramOption
                 */
                ProgramOption.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.ProgramOption)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.ProgramOption();
                    if (object.programOptionId != null)
                        message.programOptionId = String(object.programOptionId);
                    if (object.categoryName != null)
                        message.categoryName = String(object.categoryName);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ProgramOption.category: object expected");
                        message.category = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.fromObject(object.category);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayNameLang) {
                        if (typeof object.displayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ProgramOption.displayNameLang: object expected");
                        message.displayNameLang = {};
                        for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                            message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                    }
                    if (object.systemName != null)
                        message.systemName = String(object.systemName);
                    if (object.system != null) {
                        if (typeof object.system !== "object")
                            throw TypeError(".mtechnavi.api.programoption.ProgramOption.system: object expected");
                        message.system = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.fromObject(object.system);
                    }
                    if (object.order != null)
                        message.order = object.order | 0;
                    if (object.userEditable != null)
                        message.userEditable = Boolean(object.userEditable);
                    if (object.userEdited != null)
                        message.userEdited = Boolean(object.userEdited);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ProgramOption message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @static
                 * @param {mtechnavi.api.programoption.ProgramOption} message ProgramOption
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProgramOption.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.objects || options.defaults)
                        object.displayNameLang = {};
                    if (options.defaults) {
                        object.programOptionId = "";
                        object.categoryName = "";
                        object.category = null;
                        object.code = "";
                        object.systemName = "";
                        object.system = null;
                        object.order = 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.userEditable = false;
                        object.userEdited = false;
                    }
                    if (message.programOptionId != null && message.hasOwnProperty("programOptionId"))
                        object.programOptionId = message.programOptionId;
                    if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                        object.categoryName = message.categoryName;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.toObject(message.category, options);
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    let keys2;
                    if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                        object.displayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                    }
                    if (message.systemName != null && message.hasOwnProperty("systemName"))
                        object.systemName = message.systemName;
                    if (message.system != null && message.hasOwnProperty("system"))
                        object.system = $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang.toObject(message.system, options);
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = message.order;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.userEditable != null && message.hasOwnProperty("userEditable"))
                        object.userEditable = message.userEditable;
                    if (message.userEdited != null && message.hasOwnProperty("userEdited"))
                        object.userEdited = message.userEdited;
                    return object;
                };

                /**
                 * Converts this ProgramOption to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.ProgramOption
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProgramOption.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ProgramOption.DisplayNameLang = (function() {

                    /**
                     * Properties of a DisplayNameLang.
                     * @memberof mtechnavi.api.programoption.ProgramOption
                     * @interface IDisplayNameLang
                     * @property {Object.<string,string>|null} [displayNameLang] DisplayNameLang displayNameLang
                     */

                    /**
                     * Constructs a new DisplayNameLang.
                     * @memberof mtechnavi.api.programoption.ProgramOption
                     * @classdesc Represents a DisplayNameLang.
                     * @implements IDisplayNameLang
                     * @constructor
                     * @param {mtechnavi.api.programoption.ProgramOption.IDisplayNameLang=} [properties] Properties to set
                     */
                    function DisplayNameLang(properties) {
                        this.displayNameLang = {};
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DisplayNameLang displayNameLang.
                     * @member {Object.<string,string>} displayNameLang
                     * @memberof mtechnavi.api.programoption.ProgramOption.DisplayNameLang
                     * @instance
                     */
                    DisplayNameLang.prototype.displayNameLang = $util.emptyObject;

                    /**
                     * Verifies a DisplayNameLang message.
                     * @function verify
                     * @memberof mtechnavi.api.programoption.ProgramOption.DisplayNameLang
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DisplayNameLang.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                            if (!$util.isObject(message.displayNameLang))
                                return "displayNameLang: object expected";
                            let key = Object.keys(message.displayNameLang);
                            for (let i = 0; i < key.length; ++i)
                                if (!$util.isString(message.displayNameLang[key[i]]))
                                    return "displayNameLang: string{k:string} expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a DisplayNameLang message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.programoption.ProgramOption.DisplayNameLang
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.programoption.ProgramOption.DisplayNameLang} DisplayNameLang
                     */
                    DisplayNameLang.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang)
                            return object;
                        let message = new $root.mtechnavi.api.programoption.ProgramOption.DisplayNameLang();
                        if (object.displayNameLang) {
                            if (typeof object.displayNameLang !== "object")
                                throw TypeError(".mtechnavi.api.programoption.ProgramOption.DisplayNameLang.displayNameLang: object expected");
                            message.displayNameLang = {};
                            for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                                message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DisplayNameLang message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.programoption.ProgramOption.DisplayNameLang
                     * @static
                     * @param {mtechnavi.api.programoption.ProgramOption.DisplayNameLang} message DisplayNameLang
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DisplayNameLang.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.objects || options.defaults)
                            object.displayNameLang = {};
                        let keys2;
                        if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                            object.displayNameLang = {};
                            for (let j = 0; j < keys2.length; ++j)
                                object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
                        }
                        return object;
                    };

                    /**
                     * Converts this DisplayNameLang to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.programoption.ProgramOption.DisplayNameLang
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DisplayNameLang.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DisplayNameLang;
                })();

                return ProgramOption;
            })();

            programoption.Attribute = (function() {

                /**
                 * Properties of an Attribute.
                 * @memberof mtechnavi.api.programoption
                 * @interface IAttribute
                 * @property {string|null} [attributeId] Attribute attributeId
                 * @property {string|null} [categoryCode] Attribute categoryCode
                 * @property {string|null} [category] Attribute category
                 * @property {boolean|null} [systemAttribute] Attribute systemAttribute
                 * @property {boolean|null} [usable] Attribute usable
                 * @property {string|null} [key] Attribute key
                 * @property {string|null} [displayName] Attribute displayName
                 * @property {string|null} [formId] Attribute formId
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] Attribute createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] Attribute updatedProperties
                 * @property {Long|null} [createdAt] Attribute createdAt
                 * @property {Long|null} [updatedAt] Attribute updatedAt
                 * @property {Long|null} [deletedAt] Attribute deletedAt
                 */

                /**
                 * Constructs a new Attribute.
                 * @memberof mtechnavi.api.programoption
                 * @classdesc Represents an Attribute.
                 * @implements IAttribute
                 * @constructor
                 * @param {mtechnavi.api.programoption.IAttribute=} [properties] Properties to set
                 */
                function Attribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Attribute attributeId.
                 * @member {string} attributeId
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.attributeId = "";

                /**
                 * Attribute categoryCode.
                 * @member {string} categoryCode
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.categoryCode = "";

                /**
                 * Attribute category.
                 * @member {string} category
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.category = "";

                /**
                 * Attribute systemAttribute.
                 * @member {boolean} systemAttribute
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.systemAttribute = false;

                /**
                 * Attribute usable.
                 * @member {boolean} usable
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.usable = false;

                /**
                 * Attribute key.
                 * @member {string} key
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.key = "";

                /**
                 * Attribute displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.displayName = "";

                /**
                 * Attribute formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.formId = "";

                /**
                 * Attribute createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.createdProperties = null;

                /**
                 * Attribute updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.updatedProperties = null;

                /**
                 * Attribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Attribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Attribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 */
                Attribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Attribute message.
                 * @function verify
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Attribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attributeId != null && message.hasOwnProperty("attributeId"))
                        if (!$util.isString(message.attributeId))
                            return "attributeId: string expected";
                    if (message.categoryCode != null && message.hasOwnProperty("categoryCode"))
                        if (!$util.isString(message.categoryCode))
                            return "categoryCode: string expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.systemAttribute != null && message.hasOwnProperty("systemAttribute"))
                        if (typeof message.systemAttribute !== "boolean")
                            return "systemAttribute: boolean expected";
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        if (typeof message.usable !== "boolean")
                            return "usable: boolean expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Attribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.programoption.Attribute} Attribute
                 */
                Attribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.programoption.Attribute)
                        return object;
                    let message = new $root.mtechnavi.api.programoption.Attribute();
                    if (object.attributeId != null)
                        message.attributeId = String(object.attributeId);
                    if (object.categoryCode != null)
                        message.categoryCode = String(object.categoryCode);
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.systemAttribute != null)
                        message.systemAttribute = Boolean(object.systemAttribute);
                    if (object.usable != null)
                        message.usable = Boolean(object.usable);
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.programoption.Attribute.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.programoption.Attribute.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Attribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @static
                 * @param {mtechnavi.api.programoption.Attribute} message Attribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Attribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.attributeId = "";
                        object.categoryCode = "";
                        object.category = "";
                        object.systemAttribute = false;
                        object.usable = false;
                        object.key = "";
                        object.displayName = "";
                        object.formId = "";
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.attributeId != null && message.hasOwnProperty("attributeId"))
                        object.attributeId = message.attributeId;
                    if (message.categoryCode != null && message.hasOwnProperty("categoryCode"))
                        object.categoryCode = message.categoryCode;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.systemAttribute != null && message.hasOwnProperty("systemAttribute"))
                        object.systemAttribute = message.systemAttribute;
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = message.usable;
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Attribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.programoption.Attribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Attribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Attribute;
            })();

            return programoption;
        })();

        api.survey = (function() {

            /**
             * Namespace survey.
             * @memberof mtechnavi.api
             * @namespace
             */
            const survey = {};

            survey.SurveyService = (function() {

                /**
                 * Constructs a new SurveyService service.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SurveyService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SurveyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SurveyService;

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyService#listHistorys}.
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @typedef ListHistorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListHistorysResponse} [response] ListHistorysResponse
                 */

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyService.ListHistorysCallback} callback Node-style callback called with the error, if any, and ListHistorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyService.prototype.listHistorys = function listHistorys(request, callback) {
                    return this.rpcCall(listHistorys, $root.mtechnavi.api.survey.ListHistorysRequest, $root.mtechnavi.api.survey.ListHistorysResponse, request, callback);
                }, "name", { value: "ListHistorys" });

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListHistorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyService#sharetoDiscard}.
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @typedef SharetoDiscardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoDiscardResponse} [response] SharetoDiscardResponse
                 */

                /**
                 * Calls SharetoDiscard.
                 * @function sharetoDiscard
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoDiscardRequest} request SharetoDiscardRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyService.SharetoDiscardCallback} callback Node-style callback called with the error, if any, and SharetoDiscardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyService.prototype.sharetoDiscard = function sharetoDiscard(request, callback) {
                    return this.rpcCall(sharetoDiscard, $root.mtechnavi.api.survey.SharetoDiscardRequest, $root.mtechnavi.api.survey.SharetoDiscardResponse, request, callback);
                }, "name", { value: "SharetoDiscard" });

                /**
                 * Calls SharetoDiscard.
                 * @function sharetoDiscard
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoDiscardRequest} request SharetoDiscardRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoDiscardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyService#sharetoComplete}.
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @typedef SharetoCompleteCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoCompleteResponse} [response] SharetoCompleteResponse
                 */

                /**
                 * Calls SharetoComplete.
                 * @function sharetoComplete
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoCompleteRequest} request SharetoCompleteRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyService.SharetoCompleteCallback} callback Node-style callback called with the error, if any, and SharetoCompleteResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyService.prototype.sharetoComplete = function sharetoComplete(request, callback) {
                    return this.rpcCall(sharetoComplete, $root.mtechnavi.api.survey.SharetoCompleteRequest, $root.mtechnavi.api.survey.SharetoCompleteResponse, request, callback);
                }, "name", { value: "SharetoComplete" });

                /**
                 * Calls SharetoComplete.
                 * @function sharetoComplete
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoCompleteRequest} request SharetoCompleteRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoCompleteResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyService#sharetoOpened}.
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @typedef SharetoOpenedCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoOpenedResponse} [response] SharetoOpenedResponse
                 */

                /**
                 * Calls SharetoOpened.
                 * @function sharetoOpened
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoOpenedRequest} request SharetoOpenedRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyService.SharetoOpenedCallback} callback Node-style callback called with the error, if any, and SharetoOpenedResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyService.prototype.sharetoOpened = function sharetoOpened(request, callback) {
                    return this.rpcCall(sharetoOpened, $root.mtechnavi.api.survey.SharetoOpenedRequest, $root.mtechnavi.api.survey.SharetoOpenedResponse, request, callback);
                }, "name", { value: "SharetoOpened" });

                /**
                 * Calls SharetoOpened.
                 * @function sharetoOpened
                 * @memberof mtechnavi.api.survey.SurveyService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoOpenedRequest} request SharetoOpenedRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoOpenedResponse>} Promise
                 * @variation 2
                 */

                return SurveyService;
            })();

            survey.SurveySenderService = (function() {

                /**
                 * Constructs a new SurveySenderService service.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveySenderService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SurveySenderService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SurveySenderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SurveySenderService;

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyRequestCatalog}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyRequestCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestCatalog} [response] SurveyRequestCatalog
                 */

                /**
                 * Calls CreateSurveyRequestCatalog.
                 * @function createSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestCatalogRequest} request CreateSurveyRequestCatalogRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyRequestCatalogCallback} callback Node-style callback called with the error, if any, and SurveyRequestCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyRequestCatalog = function createSurveyRequestCatalog(request, callback) {
                    return this.rpcCall(createSurveyRequestCatalog, $root.mtechnavi.api.survey.CreateSurveyRequestCatalogRequest, $root.mtechnavi.api.survey.SurveyRequestCatalog, request, callback);
                }, "name", { value: "CreateSurveyRequestCatalog" });

                /**
                 * Calls CreateSurveyRequestCatalog.
                 * @function createSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestCatalogRequest} request CreateSurveyRequestCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyRequestCatalogs}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyRequestCatalogsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyRequestCatalogsResponse} [response] ListSurveyRequestCatalogsResponse
                 */

                /**
                 * Calls ListSurveyRequestCatalogs.
                 * @function listSurveyRequestCatalogs
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestCatalogsRequest} request ListSurveyRequestCatalogsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyRequestCatalogsCallback} callback Node-style callback called with the error, if any, and ListSurveyRequestCatalogsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyRequestCatalogs = function listSurveyRequestCatalogs(request, callback) {
                    return this.rpcCall(listSurveyRequestCatalogs, $root.mtechnavi.api.survey.ListSurveyRequestCatalogsRequest, $root.mtechnavi.api.survey.ListSurveyRequestCatalogsResponse, request, callback);
                }, "name", { value: "ListSurveyRequestCatalogs" });

                /**
                 * Calls ListSurveyRequestCatalogs.
                 * @function listSurveyRequestCatalogs
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestCatalogsRequest} request ListSurveyRequestCatalogsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyRequestCatalogsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyRequestCatalog}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyRequestCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestCatalog} [response] SurveyRequestCatalog
                 */

                /**
                 * Calls GetSurveyRequestCatalog.
                 * @function getSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestCatalogRequest} request GetSurveyRequestCatalogRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyRequestCatalogCallback} callback Node-style callback called with the error, if any, and SurveyRequestCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyRequestCatalog = function getSurveyRequestCatalog(request, callback) {
                    return this.rpcCall(getSurveyRequestCatalog, $root.mtechnavi.api.survey.GetSurveyRequestCatalogRequest, $root.mtechnavi.api.survey.SurveyRequestCatalog, request, callback);
                }, "name", { value: "GetSurveyRequestCatalog" });

                /**
                 * Calls GetSurveyRequestCatalog.
                 * @function getSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestCatalogRequest} request GetSurveyRequestCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSurveyRequestCatalog}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSurveyRequestCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestCatalog} [response] SurveyRequestCatalog
                 */

                /**
                 * Calls UpdateSurveyRequestCatalog.
                 * @function updateSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestCatalogRequest} request UpdateSurveyRequestCatalogRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSurveyRequestCatalogCallback} callback Node-style callback called with the error, if any, and SurveyRequestCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSurveyRequestCatalog = function updateSurveyRequestCatalog(request, callback) {
                    return this.rpcCall(updateSurveyRequestCatalog, $root.mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest, $root.mtechnavi.api.survey.SurveyRequestCatalog, request, callback);
                }, "name", { value: "UpdateSurveyRequestCatalog" });

                /**
                 * Calls UpdateSurveyRequestCatalog.
                 * @function updateSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestCatalogRequest} request UpdateSurveyRequestCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#deleteSurveyRequestCatalog}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef DeleteSurveyRequestCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteSurveyRequestCatalog.
                 * @function deleteSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestCatalogRequest} request DeleteSurveyRequestCatalogRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.DeleteSurveyRequestCatalogCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.deleteSurveyRequestCatalog = function deleteSurveyRequestCatalog(request, callback) {
                    return this.rpcCall(deleteSurveyRequestCatalog, $root.mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteSurveyRequestCatalog" });

                /**
                 * Calls DeleteSurveyRequestCatalog.
                 * @function deleteSurveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestCatalogRequest} request DeleteSurveyRequestCatalogRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyBaseRequest} [response] SurveyBaseRequest
                 */

                /**
                 * Calls CreateSurveyBaseRequest.
                 * @function createSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyBaseRequestRequest} request CreateSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and SurveyBaseRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyBaseRequest = function createSurveyBaseRequest(request, callback) {
                    return this.rpcCall(createSurveyBaseRequest, $root.mtechnavi.api.survey.CreateSurveyBaseRequestRequest, $root.mtechnavi.api.survey.SurveyBaseRequest, request, callback);
                }, "name", { value: "CreateSurveyBaseRequest" });

                /**
                 * Calls CreateSurveyBaseRequest.
                 * @function createSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyBaseRequestRequest} request CreateSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyBaseRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyBaseRequest} [response] SurveyBaseRequest
                 */

                /**
                 * Calls UpdateSurveyBaseRequest.
                 * @function updateSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyBaseRequestRequest} request UpdateSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and SurveyBaseRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSurveyBaseRequest = function updateSurveyBaseRequest(request, callback) {
                    return this.rpcCall(updateSurveyBaseRequest, $root.mtechnavi.api.survey.UpdateSurveyBaseRequestRequest, $root.mtechnavi.api.survey.SurveyBaseRequest, request, callback);
                }, "name", { value: "UpdateSurveyBaseRequest" });

                /**
                 * Calls UpdateSurveyBaseRequest.
                 * @function updateSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyBaseRequestRequest} request UpdateSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyBaseRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#deleteSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef DeleteSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteSurveyBaseRequest.
                 * @function deleteSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyBaseRequestRequest} request DeleteSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.DeleteSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.deleteSurveyBaseRequest = function deleteSurveyBaseRequest(request, callback) {
                    return this.rpcCall(deleteSurveyBaseRequest, $root.mtechnavi.api.survey.DeleteSurveyBaseRequestRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteSurveyBaseRequest" });

                /**
                 * Calls DeleteSurveyBaseRequest.
                 * @function deleteSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyBaseRequestRequest} request DeleteSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#approvalRequestSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ApprovalRequestSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse} [response] ApprovalRequestSurveyBaseRequestResponse
                 */

                /**
                 * Calls ApprovalRequestSurveyBaseRequest.
                 * @function approvalRequestSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestRequest} request ApprovalRequestSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ApprovalRequestSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and ApprovalRequestSurveyBaseRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.approvalRequestSurveyBaseRequest = function approvalRequestSurveyBaseRequest(request, callback) {
                    return this.rpcCall(approvalRequestSurveyBaseRequest, $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest, $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse, request, callback);
                }, "name", { value: "ApprovalRequestSurveyBaseRequest" });

                /**
                 * Calls ApprovalRequestSurveyBaseRequest.
                 * @function approvalRequestSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestRequest} request ApprovalRequestSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#rejectSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef RejectSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.RejectSurveyBaseRequestResponse} [response] RejectSurveyBaseRequestResponse
                 */

                /**
                 * Calls RejectSurveyBaseRequest.
                 * @function rejectSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IRejectSurveyBaseRequestRequest} request RejectSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.RejectSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and RejectSurveyBaseRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.rejectSurveyBaseRequest = function rejectSurveyBaseRequest(request, callback) {
                    return this.rpcCall(rejectSurveyBaseRequest, $root.mtechnavi.api.survey.RejectSurveyBaseRequestRequest, $root.mtechnavi.api.survey.RejectSurveyBaseRequestResponse, request, callback);
                }, "name", { value: "RejectSurveyBaseRequest" });

                /**
                 * Calls RejectSurveyBaseRequest.
                 * @function rejectSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IRejectSurveyBaseRequestRequest} request RejectSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.RejectSurveyBaseRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#approveSurveyBaseRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ApproveSurveyBaseRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ApproveSurveyBaseRequestResponse} [response] ApproveSurveyBaseRequestResponse
                 */

                /**
                 * Calls ApproveSurveyBaseRequest.
                 * @function approveSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IApproveSurveyBaseRequestRequest} request ApproveSurveyBaseRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ApproveSurveyBaseRequestCallback} callback Node-style callback called with the error, if any, and ApproveSurveyBaseRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.approveSurveyBaseRequest = function approveSurveyBaseRequest(request, callback) {
                    return this.rpcCall(approveSurveyBaseRequest, $root.mtechnavi.api.survey.ApproveSurveyBaseRequestRequest, $root.mtechnavi.api.survey.ApproveSurveyBaseRequestResponse, request, callback);
                }, "name", { value: "ApproveSurveyBaseRequest" });

                /**
                 * Calls ApproveSurveyBaseRequest.
                 * @function approveSurveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IApproveSurveyBaseRequestRequest} request ApproveSurveyBaseRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ApproveSurveyBaseRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequest} [response] SurveyRequest
                 */

                /**
                 * Calls GetSurveyRequest.
                 * @function getSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestRequest} request GetSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyRequestCallback} callback Node-style callback called with the error, if any, and SurveyRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyRequest = function getSurveyRequest(request, callback) {
                    return this.rpcCall(getSurveyRequest, $root.mtechnavi.api.survey.GetSurveyRequestRequest, $root.mtechnavi.api.survey.SurveyRequest, request, callback);
                }, "name", { value: "GetSurveyRequest" });

                /**
                 * Calls GetSurveyRequest.
                 * @function getSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestRequest} request GetSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequest} [response] SurveyRequest
                 */

                /**
                 * Calls UpdateSurveyRequest.
                 * @function updateSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestRequest} request UpdateSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSurveyRequestCallback} callback Node-style callback called with the error, if any, and SurveyRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSurveyRequest = function updateSurveyRequest(request, callback) {
                    return this.rpcCall(updateSurveyRequest, $root.mtechnavi.api.survey.UpdateSurveyRequestRequest, $root.mtechnavi.api.survey.SurveyRequest, request, callback);
                }, "name", { value: "UpdateSurveyRequest" });

                /**
                 * Calls UpdateSurveyRequest.
                 * @function updateSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestRequest} request UpdateSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSystemNotificationUsersInSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSystemNotificationUsersInSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequest} [response] SurveyRequest
                 */

                /**
                 * Calls UpdateSystemNotificationUsersInSurveyRequest.
                 * @function updateSystemNotificationUsersInSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyRequestRequest} request UpdateSystemNotificationUsersInSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSystemNotificationUsersInSurveyRequestCallback} callback Node-style callback called with the error, if any, and SurveyRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSystemNotificationUsersInSurveyRequest = function updateSystemNotificationUsersInSurveyRequest(request, callback) {
                    return this.rpcCall(updateSystemNotificationUsersInSurveyRequest, $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest, $root.mtechnavi.api.survey.SurveyRequest, request, callback);
                }, "name", { value: "UpdateSystemNotificationUsersInSurveyRequest" });

                /**
                 * Calls UpdateSystemNotificationUsersInSurveyRequest.
                 * @function updateSystemNotificationUsersInSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyRequestRequest} request UpdateSystemNotificationUsersInSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateFlagInSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateFlagInSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequest} [response] SurveyRequest
                 */

                /**
                 * Calls UpdateFlagInSurveyRequest.
                 * @function updateFlagInSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest} request UpdateFlagInSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateFlagInSurveyRequestCallback} callback Node-style callback called with the error, if any, and SurveyRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateFlagInSurveyRequest = function updateFlagInSurveyRequest(request, callback) {
                    return this.rpcCall(updateFlagInSurveyRequest, $root.mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest, $root.mtechnavi.api.survey.SurveyRequest, request, callback);
                }, "name", { value: "UpdateFlagInSurveyRequest" });

                /**
                 * Calls UpdateFlagInSurveyRequest.
                 * @function updateFlagInSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest} request UpdateFlagInSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharetoSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharetoSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyRequestResponse} [response] SharetoSurveyRequestResponse
                 */

                /**
                 * Calls SharetoSurveyRequest.
                 * @function sharetoSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyRequestRequest} request SharetoSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharetoSurveyRequestCallback} callback Node-style callback called with the error, if any, and SharetoSurveyRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharetoSurveyRequest = function sharetoSurveyRequest(request, callback) {
                    return this.rpcCall(sharetoSurveyRequest, $root.mtechnavi.api.survey.SharetoSurveyRequestRequest, $root.mtechnavi.api.survey.SharetoSurveyRequestResponse, request, callback);
                }, "name", { value: "SharetoSurveyRequest" });

                /**
                 * Calls SharetoSurveyRequest.
                 * @function sharetoSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyRequestRequest} request SharetoSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#discardSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef DiscardSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.DiscardSurveyRequestResponse} [response] DiscardSurveyRequestResponse
                 */

                /**
                 * Calls DiscardSurveyRequest.
                 * @function discardSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDiscardSurveyRequestRequest} request DiscardSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.DiscardSurveyRequestCallback} callback Node-style callback called with the error, if any, and DiscardSurveyRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.discardSurveyRequest = function discardSurveyRequest(request, callback) {
                    return this.rpcCall(discardSurveyRequest, $root.mtechnavi.api.survey.DiscardSurveyRequestRequest, $root.mtechnavi.api.survey.DiscardSurveyRequestResponse, request, callback);
                }, "name", { value: "DiscardSurveyRequest" });

                /**
                 * Calls DiscardSurveyRequest.
                 * @function discardSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDiscardSurveyRequestRequest} request DiscardSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.DiscardSurveyRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#completeSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CompleteSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.CompleteSurveyRequestResponse} [response] CompleteSurveyRequestResponse
                 */

                /**
                 * Calls CompleteSurveyRequest.
                 * @function completeSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICompleteSurveyRequestRequest} request CompleteSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CompleteSurveyRequestCallback} callback Node-style callback called with the error, if any, and CompleteSurveyRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.completeSurveyRequest = function completeSurveyRequest(request, callback) {
                    return this.rpcCall(completeSurveyRequest, $root.mtechnavi.api.survey.CompleteSurveyRequestRequest, $root.mtechnavi.api.survey.CompleteSurveyRequestResponse, request, callback);
                }, "name", { value: "CompleteSurveyRequest" });

                /**
                 * Calls CompleteSurveyRequest.
                 * @function completeSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICompleteSurveyRequestRequest} request CompleteSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.CompleteSurveyRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyReplyDueDateChangeResult}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyReplyDueDateChangeResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeResult} [response] SurveyReplyDueDateChangeResult
                 */

                /**
                 * Calls CreateSurveyReplyDueDateChangeResult.
                 * @function createSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeResultRequest} request CreateSurveyReplyDueDateChangeResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyReplyDueDateChangeResultCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyReplyDueDateChangeResult = function createSurveyReplyDueDateChangeResult(request, callback) {
                    return this.rpcCall(createSurveyReplyDueDateChangeResult, $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult, request, callback);
                }, "name", { value: "CreateSurveyReplyDueDateChangeResult" });

                /**
                 * Calls CreateSurveyReplyDueDateChangeResult.
                 * @function createSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeResultRequest} request CreateSurveyReplyDueDateChangeResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyReplyDueDateChangeResults}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyReplyDueDateChangeResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse} [response] ListSurveyReplyDueDateChangeResultsResponse
                 */

                /**
                 * Calls ListSurveyReplyDueDateChangeResults.
                 * @function listSurveyReplyDueDateChangeResults
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsRequest} request ListSurveyReplyDueDateChangeResultsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyReplyDueDateChangeResultsCallback} callback Node-style callback called with the error, if any, and ListSurveyReplyDueDateChangeResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyReplyDueDateChangeResults = function listSurveyReplyDueDateChangeResults(request, callback) {
                    return this.rpcCall(listSurveyReplyDueDateChangeResults, $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest, $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse, request, callback);
                }, "name", { value: "ListSurveyReplyDueDateChangeResults" });

                /**
                 * Calls ListSurveyReplyDueDateChangeResults.
                 * @function listSurveyReplyDueDateChangeResults
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsRequest} request ListSurveyReplyDueDateChangeResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyReplyDueDateChangeResult}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyReplyDueDateChangeResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeResult} [response] SurveyReplyDueDateChangeResult
                 */

                /**
                 * Calls GetSurveyReplyDueDateChangeResult.
                 * @function getSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeResultRequest} request GetSurveyReplyDueDateChangeResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyReplyDueDateChangeResultCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyReplyDueDateChangeResult = function getSurveyReplyDueDateChangeResult(request, callback) {
                    return this.rpcCall(getSurveyReplyDueDateChangeResult, $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult, request, callback);
                }, "name", { value: "GetSurveyReplyDueDateChangeResult" });

                /**
                 * Calls GetSurveyReplyDueDateChangeResult.
                 * @function getSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeResultRequest} request GetSurveyReplyDueDateChangeResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharetoSurveyReplyDueDateChangeResult}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharetoSurveyReplyDueDateChangeResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse} [response] SharetoSurveyReplyDueDateChangeResultResponse
                 */

                /**
                 * Calls SharetoSurveyReplyDueDateChangeResult.
                 * @function sharetoSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultRequest} request SharetoSurveyReplyDueDateChangeResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharetoSurveyReplyDueDateChangeResultCallback} callback Node-style callback called with the error, if any, and SharetoSurveyReplyDueDateChangeResultResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharetoSurveyReplyDueDateChangeResult = function sharetoSurveyReplyDueDateChangeResult(request, callback) {
                    return this.rpcCall(sharetoSurveyReplyDueDateChangeResult, $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest, $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse, request, callback);
                }, "name", { value: "SharetoSurveyReplyDueDateChangeResult" });

                /**
                 * Calls SharetoSurveyReplyDueDateChangeResult.
                 * @function sharetoSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultRequest} request SharetoSurveyReplyDueDateChangeResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyResultChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequest} [response] SurveyResultChangeRequest
                 */

                /**
                 * Calls CreateSurveyResultChangeRequest.
                 * @function createSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultChangeRequestRequest} request CreateSurveyResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyResultChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyResultChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyResultChangeRequest = function createSurveyResultChangeRequest(request, callback) {
                    return this.rpcCall(createSurveyResultChangeRequest, $root.mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest, $root.mtechnavi.api.survey.SurveyResultChangeRequest, request, callback);
                }, "name", { value: "CreateSurveyResultChangeRequest" });

                /**
                 * Calls CreateSurveyResultChangeRequest.
                 * @function createSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultChangeRequestRequest} request CreateSurveyResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyResultChangeRequests}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyResultChangeRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse} [response] ListSurveyResultChangeRequestsResponse
                 */

                /**
                 * Calls ListSurveyResultChangeRequests.
                 * @function listSurveyResultChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyResultChangeRequestsRequest} request ListSurveyResultChangeRequestsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyResultChangeRequestsCallback} callback Node-style callback called with the error, if any, and ListSurveyResultChangeRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyResultChangeRequests = function listSurveyResultChangeRequests(request, callback) {
                    return this.rpcCall(listSurveyResultChangeRequests, $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest, $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse, request, callback);
                }, "name", { value: "ListSurveyResultChangeRequests" });

                /**
                 * Calls ListSurveyResultChangeRequests.
                 * @function listSurveyResultChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyResultChangeRequestsRequest} request ListSurveyResultChangeRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyResultChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequest} [response] SurveyResultChangeRequest
                 */

                /**
                 * Calls GetSurveyResultChangeRequest.
                 * @function getSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyResultChangeRequestRequest} request GetSurveyResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyResultChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyResultChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyResultChangeRequest = function getSurveyResultChangeRequest(request, callback) {
                    return this.rpcCall(getSurveyResultChangeRequest, $root.mtechnavi.api.survey.GetSurveyResultChangeRequestRequest, $root.mtechnavi.api.survey.SurveyResultChangeRequest, request, callback);
                }, "name", { value: "GetSurveyResultChangeRequest" });

                /**
                 * Calls GetSurveyResultChangeRequest.
                 * @function getSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyResultChangeRequestRequest} request GetSurveyResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharetoSurveyResultChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharetoSurveyResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse} [response] SharetoSurveyResultChangeRequestResponse
                 */

                /**
                 * Calls SharetoSurveyResultChangeRequest.
                 * @function sharetoSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultChangeRequestRequest} request SharetoSurveyResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharetoSurveyResultChangeRequestCallback} callback Node-style callback called with the error, if any, and SharetoSurveyResultChangeRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharetoSurveyResultChangeRequest = function sharetoSurveyResultChangeRequest(request, callback) {
                    return this.rpcCall(sharetoSurveyResultChangeRequest, $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest, $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse, request, callback);
                }, "name", { value: "SharetoSurveyResultChangeRequest" });

                /**
                 * Calls SharetoSurveyResultChangeRequest.
                 * @function sharetoSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultChangeRequestRequest} request SharetoSurveyResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyReminder}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyReminderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReminder} [response] SurveyReminder
                 */

                /**
                 * Calls CreateSurveyReminder.
                 * @function createSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReminderRequest} request CreateSurveyReminderRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyReminderCallback} callback Node-style callback called with the error, if any, and SurveyReminder
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyReminder = function createSurveyReminder(request, callback) {
                    return this.rpcCall(createSurveyReminder, $root.mtechnavi.api.survey.CreateSurveyReminderRequest, $root.mtechnavi.api.survey.SurveyReminder, request, callback);
                }, "name", { value: "CreateSurveyReminder" });

                /**
                 * Calls CreateSurveyReminder.
                 * @function createSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReminderRequest} request CreateSurveyReminderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReminder>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyReminders}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyRemindersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyRemindersResponse} [response] ListSurveyRemindersResponse
                 */

                /**
                 * Calls ListSurveyReminders.
                 * @function listSurveyReminders
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRemindersRequest} request ListSurveyRemindersRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyRemindersCallback} callback Node-style callback called with the error, if any, and ListSurveyRemindersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyReminders = function listSurveyReminders(request, callback) {
                    return this.rpcCall(listSurveyReminders, $root.mtechnavi.api.survey.ListSurveyRemindersRequest, $root.mtechnavi.api.survey.ListSurveyRemindersResponse, request, callback);
                }, "name", { value: "ListSurveyReminders" });

                /**
                 * Calls ListSurveyReminders.
                 * @function listSurveyReminders
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRemindersRequest} request ListSurveyRemindersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyRemindersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyReminder}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyReminderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReminder} [response] SurveyReminder
                 */

                /**
                 * Calls GetSurveyReminder.
                 * @function getSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReminderRequest} request GetSurveyReminderRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyReminderCallback} callback Node-style callback called with the error, if any, and SurveyReminder
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyReminder = function getSurveyReminder(request, callback) {
                    return this.rpcCall(getSurveyReminder, $root.mtechnavi.api.survey.GetSurveyReminderRequest, $root.mtechnavi.api.survey.SurveyReminder, request, callback);
                }, "name", { value: "GetSurveyReminder" });

                /**
                 * Calls GetSurveyReminder.
                 * @function getSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReminderRequest} request GetSurveyReminderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReminder>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharetoSurveyReminder}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharetoSurveyReminderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyReminderResponse} [response] SharetoSurveyReminderResponse
                 */

                /**
                 * Calls SharetoSurveyReminder.
                 * @function sharetoSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReminderRequest} request SharetoSurveyReminderRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharetoSurveyReminderCallback} callback Node-style callback called with the error, if any, and SharetoSurveyReminderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharetoSurveyReminder = function sharetoSurveyReminder(request, callback) {
                    return this.rpcCall(sharetoSurveyReminder, $root.mtechnavi.api.survey.SharetoSurveyReminderRequest, $root.mtechnavi.api.survey.SharetoSurveyReminderResponse, request, callback);
                }, "name", { value: "SharetoSurveyReminder" });

                /**
                 * Calls SharetoSurveyReminder.
                 * @function sharetoSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReminderRequest} request SharetoSurveyReminderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyReminderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#exportSurveyRequests}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ExportSurveyRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExportSurveyRequestsResponse} [response] ExportSurveyRequestsResponse
                 */

                /**
                 * Calls ExportSurveyRequests.
                 * @function exportSurveyRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyRequestsRequest} request ExportSurveyRequestsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ExportSurveyRequestsCallback} callback Node-style callback called with the error, if any, and ExportSurveyRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.exportSurveyRequests = function exportSurveyRequests(request, callback) {
                    return this.rpcCall(exportSurveyRequests, $root.mtechnavi.api.survey.ExportSurveyRequestsRequest, $root.mtechnavi.api.survey.ExportSurveyRequestsResponse, request, callback);
                }, "name", { value: "ExportSurveyRequests" });

                /**
                 * Calls ExportSurveyRequests.
                 * @function exportSurveyRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyRequestsRequest} request ExportSurveyRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExportSurveyRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyRequestFormats}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyRequestFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyRequestFormatsResponse} [response] ListSurveyRequestFormatsResponse
                 */

                /**
                 * Calls ListSurveyRequestFormats.
                 * @function listSurveyRequestFormats
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestFormatsRequest} request ListSurveyRequestFormatsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyRequestFormatsCallback} callback Node-style callback called with the error, if any, and ListSurveyRequestFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyRequestFormats = function listSurveyRequestFormats(request, callback) {
                    return this.rpcCall(listSurveyRequestFormats, $root.mtechnavi.api.survey.ListSurveyRequestFormatsRequest, $root.mtechnavi.api.survey.ListSurveyRequestFormatsResponse, request, callback);
                }, "name", { value: "ListSurveyRequestFormats" });

                /**
                 * Calls ListSurveyRequestFormats.
                 * @function listSurveyRequestFormats
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestFormatsRequest} request ListSurveyRequestFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyRequestFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedListSurveyReplyDueDateChangeRequests}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedListSurveyReplyDueDateChangeRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse} [response] SharedListSurveyReplyDueDateChangeRequestsResponse
                 */

                /**
                 * Calls SharedListSurveyReplyDueDateChangeRequests.
                 * @function sharedListSurveyReplyDueDateChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeRequestsRequest} request SharedListSurveyReplyDueDateChangeRequestsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedListSurveyReplyDueDateChangeRequestsCallback} callback Node-style callback called with the error, if any, and SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedListSurveyReplyDueDateChangeRequests = function sharedListSurveyReplyDueDateChangeRequests(request, callback) {
                    return this.rpcCall(sharedListSurveyReplyDueDateChangeRequests, $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest, $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse, request, callback);
                }, "name", { value: "SharedListSurveyReplyDueDateChangeRequests" });

                /**
                 * Calls SharedListSurveyReplyDueDateChangeRequests.
                 * @function sharedListSurveyReplyDueDateChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeRequestsRequest} request SharedListSurveyReplyDueDateChangeRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedGetSurveyReplyDueDateChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedGetSurveyReplyDueDateChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeRequest} [response] SurveyReplyDueDateChangeRequest
                 */

                /**
                 * Calls SharedGetSurveyReplyDueDateChangeRequest.
                 * @function sharedGetSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeRequestRequest} request SharedGetSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedGetSurveyReplyDueDateChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedGetSurveyReplyDueDateChangeRequest = function sharedGetSurveyReplyDueDateChangeRequest(request, callback) {
                    return this.rpcCall(sharedGetSurveyReplyDueDateChangeRequest, $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest, request, callback);
                }, "name", { value: "SharedGetSurveyReplyDueDateChangeRequest" });

                /**
                 * Calls SharedGetSurveyReplyDueDateChangeRequest.
                 * @function sharedGetSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeRequestRequest} request SharedGetSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedListSurveyResults}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedListSurveyResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyResultsResponse} [response] SharedListSurveyResultsResponse
                 */

                /**
                 * Calls SharedListSurveyResults.
                 * @function sharedListSurveyResults
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultsRequest} request SharedListSurveyResultsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedListSurveyResultsCallback} callback Node-style callback called with the error, if any, and SharedListSurveyResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedListSurveyResults = function sharedListSurveyResults(request, callback) {
                    return this.rpcCall(sharedListSurveyResults, $root.mtechnavi.api.survey.SharedListSurveyResultsRequest, $root.mtechnavi.api.survey.SharedListSurveyResultsResponse, request, callback);
                }, "name", { value: "SharedListSurveyResults" });

                /**
                 * Calls SharedListSurveyResults.
                 * @function sharedListSurveyResults
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultsRequest} request SharedListSurveyResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedGetSurveyResult}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedGetSurveyResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResult} [response] SurveyResult
                 */

                /**
                 * Calls SharedGetSurveyResult.
                 * @function sharedGetSurveyResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultRequest} request SharedGetSurveyResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedGetSurveyResultCallback} callback Node-style callback called with the error, if any, and SurveyResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedGetSurveyResult = function sharedGetSurveyResult(request, callback) {
                    return this.rpcCall(sharedGetSurveyResult, $root.mtechnavi.api.survey.SharedGetSurveyResultRequest, $root.mtechnavi.api.survey.SurveyResult, request, callback);
                }, "name", { value: "SharedGetSurveyResult" });

                /**
                 * Calls SharedGetSurveyResult.
                 * @function sharedGetSurveyResult
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultRequest} request SharedGetSurveyResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedListSurveyResultChangeRequests}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedListSurveyResultChangeRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequestsResponse} [response] SurveyResultChangeRequestsResponse
                 */

                /**
                 * Calls SharedListSurveyResultChangeRequests.
                 * @function sharedListSurveyResultChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultChangeRequestsRequest} request SharedListSurveyResultChangeRequestsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedListSurveyResultChangeRequestsCallback} callback Node-style callback called with the error, if any, and SurveyResultChangeRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedListSurveyResultChangeRequests = function sharedListSurveyResultChangeRequests(request, callback) {
                    return this.rpcCall(sharedListSurveyResultChangeRequests, $root.mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest, $root.mtechnavi.api.survey.SurveyResultChangeRequestsResponse, request, callback);
                }, "name", { value: "SharedListSurveyResultChangeRequests" });

                /**
                 * Calls SharedListSurveyResultChangeRequests.
                 * @function sharedListSurveyResultChangeRequests
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultChangeRequestsRequest} request SharedListSurveyResultChangeRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultChangeRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#sharedGetSurveyResultChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef SharedGetSurveyResultChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequest} [response] SurveyResultChangeRequest
                 */

                /**
                 * Calls SharedGetSurveyResultChangeRequest.
                 * @function sharedGetSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultChangeRequestRequest} request SharedGetSurveyResultChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.SharedGetSurveyResultChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyResultChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.sharedGetSurveyResultChangeRequest = function sharedGetSurveyResultChangeRequest(request, callback) {
                    return this.rpcCall(sharedGetSurveyResultChangeRequest, $root.mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest, $root.mtechnavi.api.survey.SurveyResultChangeRequest, request, callback);
                }, "name", { value: "SharedGetSurveyResultChangeRequest" });

                /**
                 * Calls SharedGetSurveyResultChangeRequest.
                 * @function sharedGetSurveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultChangeRequestRequest} request SharedGetSurveyResultChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#expandedListSurveyRequestContents}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ExpandedListSurveyRequestContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse} [response] ExpandedListSurveyRequestContentsResponse
                 */

                /**
                 * Calls ExpandedListSurveyRequestContents.
                 * @function expandedListSurveyRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExpandedListSurveyRequestContentsRequest} request ExpandedListSurveyRequestContentsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ExpandedListSurveyRequestContentsCallback} callback Node-style callback called with the error, if any, and ExpandedListSurveyRequestContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.expandedListSurveyRequestContents = function expandedListSurveyRequestContents(request, callback) {
                    return this.rpcCall(expandedListSurveyRequestContents, $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest, $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse, request, callback);
                }, "name", { value: "ExpandedListSurveyRequestContents" });

                /**
                 * Calls ExpandedListSurveyRequestContents.
                 * @function expandedListSurveyRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExpandedListSurveyRequestContentsRequest} request ExpandedListSurveyRequestContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#expandedGetSurveyRequestContent}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ExpandedGetSurveyRequestContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestContent} [response] SurveyRequestContent
                 */

                /**
                 * Calls ExpandedGetSurveyRequestContent.
                 * @function expandedGetSurveyRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExpandedGetSurveyRequestContentRequest} request ExpandedGetSurveyRequestContentRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ExpandedGetSurveyRequestContentCallback} callback Node-style callback called with the error, if any, and SurveyRequestContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.expandedGetSurveyRequestContent = function expandedGetSurveyRequestContent(request, callback) {
                    return this.rpcCall(expandedGetSurveyRequestContent, $root.mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest, $root.mtechnavi.api.survey.SurveyRequestContent, request, callback);
                }, "name", { value: "ExpandedGetSurveyRequestContent" });

                /**
                 * Calls ExpandedGetSurveyRequestContent.
                 * @function expandedGetSurveyRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExpandedGetSurveyRequestContentRequest} request ExpandedGetSurveyRequestContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyBaseRequestContents}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyBaseRequestContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse} [response] ListSurveyBaseRequestContentsResponse
                 */

                /**
                 * Calls ListSurveyBaseRequestContents.
                 * @function listSurveyBaseRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyBaseRequestContentsRequest} request ListSurveyBaseRequestContentsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyBaseRequestContentsCallback} callback Node-style callback called with the error, if any, and ListSurveyBaseRequestContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyBaseRequestContents = function listSurveyBaseRequestContents(request, callback) {
                    return this.rpcCall(listSurveyBaseRequestContents, $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest, $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse, request, callback);
                }, "name", { value: "ListSurveyBaseRequestContents" });

                /**
                 * Calls ListSurveyBaseRequestContents.
                 * @function listSurveyBaseRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyBaseRequestContentsRequest} request ListSurveyBaseRequestContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyBaseRequestContent}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyBaseRequestContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyBaseRequestContent} [response] SurveyBaseRequestContent
                 */

                /**
                 * Calls GetSurveyBaseRequestContent.
                 * @function getSurveyBaseRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyBaseRequestContentRequest} request GetSurveyBaseRequestContentRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyBaseRequestContentCallback} callback Node-style callback called with the error, if any, and SurveyBaseRequestContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyBaseRequestContent = function getSurveyBaseRequestContent(request, callback) {
                    return this.rpcCall(getSurveyBaseRequestContent, $root.mtechnavi.api.survey.GetSurveyBaseRequestContentRequest, $root.mtechnavi.api.survey.SurveyBaseRequestContent, request, callback);
                }, "name", { value: "GetSurveyBaseRequestContent" });

                /**
                 * Calls GetSurveyBaseRequestContent.
                 * @function getSurveyBaseRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyBaseRequestContentRequest} request GetSurveyBaseRequestContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyBaseRequestContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyRequestContents}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyRequestContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyRequestContentsResponse} [response] ListSurveyRequestContentsResponse
                 */

                /**
                 * Calls ListSurveyRequestContents.
                 * @function listSurveyRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestContentsRequest} request ListSurveyRequestContentsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyRequestContentsCallback} callback Node-style callback called with the error, if any, and ListSurveyRequestContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyRequestContents = function listSurveyRequestContents(request, callback) {
                    return this.rpcCall(listSurveyRequestContents, $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest, $root.mtechnavi.api.survey.ListSurveyRequestContentsResponse, request, callback);
                }, "name", { value: "ListSurveyRequestContents" });

                /**
                 * Calls ListSurveyRequestContents.
                 * @function listSurveyRequestContents
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestContentsRequest} request ListSurveyRequestContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyRequestContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#getSurveyRequestContent}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef GetSurveyRequestContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestContent} [response] SurveyRequestContent
                 */

                /**
                 * Calls GetSurveyRequestContent.
                 * @function getSurveyRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestContentRequest} request GetSurveyRequestContentRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.GetSurveyRequestContentCallback} callback Node-style callback called with the error, if any, and SurveyRequestContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.getSurveyRequestContent = function getSurveyRequestContent(request, callback) {
                    return this.rpcCall(getSurveyRequestContent, $root.mtechnavi.api.survey.GetSurveyRequestContentRequest, $root.mtechnavi.api.survey.SurveyRequestContent, request, callback);
                }, "name", { value: "GetSurveyRequestContent" });

                /**
                 * Calls GetSurveyRequestContent.
                 * @function getSurveyRequestContent
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyRequestContentRequest} request GetSurveyRequestContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#exportedSurveyResultFile}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ExportedSurveyResultFileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExportedSurveyResultFileResponse} [response] ExportedSurveyResultFileResponse
                 */

                /**
                 * Calls ExportedSurveyResultFile.
                 * @function exportedSurveyResultFile
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportedSurveyResultFileRequest} request ExportedSurveyResultFileRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ExportedSurveyResultFileCallback} callback Node-style callback called with the error, if any, and ExportedSurveyResultFileResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.exportedSurveyResultFile = function exportedSurveyResultFile(request, callback) {
                    return this.rpcCall(exportedSurveyResultFile, $root.mtechnavi.api.survey.ExportedSurveyResultFileRequest, $root.mtechnavi.api.survey.ExportedSurveyResultFileResponse, request, callback);
                }, "name", { value: "ExportedSurveyResultFile" });

                /**
                 * Calls ExportedSurveyResultFile.
                 * @function exportedSurveyResultFile
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportedSurveyResultFileRequest} request ExportedSurveyResultFileRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExportedSurveyResultFileResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyRequestWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyRequestWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestWorkForm} [response] SurveyRequestWorkForm
                 */

                /**
                 * Calls CreateSurveyRequestWorkForm.
                 * @function createSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest} request CreateSurveyRequestWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyRequestWorkFormCallback} callback Node-style callback called with the error, if any, and SurveyRequestWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyRequestWorkForm = function createSurveyRequestWorkForm(request, callback) {
                    return this.rpcCall(createSurveyRequestWorkForm, $root.mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest, $root.mtechnavi.api.survey.SurveyRequestWorkForm, request, callback);
                }, "name", { value: "CreateSurveyRequestWorkForm" });

                /**
                 * Calls CreateSurveyRequestWorkForm.
                 * @function createSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest} request CreateSurveyRequestWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyRequestWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyRequestWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse} [response] ListSurveyRequestWorkFormsResponse
                 */

                /**
                 * Calls ListSurveyRequestWorkForms.
                 * @function listSurveyRequestWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestWorkFormsRequest} request ListSurveyRequestWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyRequestWorkFormsCallback} callback Node-style callback called with the error, if any, and ListSurveyRequestWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyRequestWorkForms = function listSurveyRequestWorkForms(request, callback) {
                    return this.rpcCall(listSurveyRequestWorkForms, $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest, $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse, request, callback);
                }, "name", { value: "ListSurveyRequestWorkForms" });

                /**
                 * Calls ListSurveyRequestWorkForms.
                 * @function listSurveyRequestWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyRequestWorkFormsRequest} request ListSurveyRequestWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSurveyRequestWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSurveyRequestWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequestWorkForm} [response] SurveyRequestWorkForm
                 */

                /**
                 * Calls UpdateSurveyRequestWorkForm.
                 * @function updateSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest} request UpdateSurveyRequestWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSurveyRequestWorkFormCallback} callback Node-style callback called with the error, if any, and SurveyRequestWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSurveyRequestWorkForm = function updateSurveyRequestWorkForm(request, callback) {
                    return this.rpcCall(updateSurveyRequestWorkForm, $root.mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest, $root.mtechnavi.api.survey.SurveyRequestWorkForm, request, callback);
                }, "name", { value: "UpdateSurveyRequestWorkForm" });

                /**
                 * Calls UpdateSurveyRequestWorkForm.
                 * @function updateSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest} request UpdateSurveyRequestWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequestWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#deleteSurveyRequestWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef DeleteSurveyRequestWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteSurveyRequestWorkForm.
                 * @function deleteSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest} request DeleteSurveyRequestWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.DeleteSurveyRequestWorkFormCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.deleteSurveyRequestWorkForm = function deleteSurveyRequestWorkForm(request, callback) {
                    return this.rpcCall(deleteSurveyRequestWorkForm, $root.mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteSurveyRequestWorkForm" });

                /**
                 * Calls DeleteSurveyRequestWorkForm.
                 * @function deleteSurveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest} request DeleteSurveyRequestWorkFormRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#createSurveyResultWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef CreateSurveyResultWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultWorkForm} [response] SurveyResultWorkForm
                 */

                /**
                 * Calls CreateSurveyResultWorkForm.
                 * @function createSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest} request CreateSurveyResultWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.CreateSurveyResultWorkFormCallback} callback Node-style callback called with the error, if any, and SurveyResultWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.createSurveyResultWorkForm = function createSurveyResultWorkForm(request, callback) {
                    return this.rpcCall(createSurveyResultWorkForm, $root.mtechnavi.api.survey.CreateSurveyResultWorkFormRequest, $root.mtechnavi.api.survey.SurveyResultWorkForm, request, callback);
                }, "name", { value: "CreateSurveyResultWorkForm" });

                /**
                 * Calls CreateSurveyResultWorkForm.
                 * @function createSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest} request CreateSurveyResultWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#listSurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ListSurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyResultWorkFormsResponse} [response] ListSurveyResultWorkFormsResponse
                 */

                /**
                 * Calls ListSurveyResultWorkForms.
                 * @function listSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyResultWorkFormsRequest} request ListSurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ListSurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and ListSurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.listSurveyResultWorkForms = function listSurveyResultWorkForms(request, callback) {
                    return this.rpcCall(listSurveyResultWorkForms, $root.mtechnavi.api.survey.ListSurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.ListSurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "ListSurveyResultWorkForms" });

                /**
                 * Calls ListSurveyResultWorkForms.
                 * @function listSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyResultWorkFormsRequest} request ListSurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#updateSurveyResultWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef UpdateSurveyResultWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResultWorkForm} [response] SurveyResultWorkForm
                 */

                /**
                 * Calls UpdateSurveyResultWorkForm.
                 * @function updateSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest} request UpdateSurveyResultWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.UpdateSurveyResultWorkFormCallback} callback Node-style callback called with the error, if any, and SurveyResultWorkForm
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.updateSurveyResultWorkForm = function updateSurveyResultWorkForm(request, callback) {
                    return this.rpcCall(updateSurveyResultWorkForm, $root.mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest, $root.mtechnavi.api.survey.SurveyResultWorkForm, request, callback);
                }, "name", { value: "UpdateSurveyResultWorkForm" });

                /**
                 * Calls UpdateSurveyResultWorkForm.
                 * @function updateSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest} request UpdateSurveyResultWorkFormRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResultWorkForm>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#deleteSurveyResultWorkForm}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef DeleteSurveyResultWorkFormCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteSurveyResultWorkForm.
                 * @function deleteSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest} request DeleteSurveyResultWorkFormRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.DeleteSurveyResultWorkFormCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.deleteSurveyResultWorkForm = function deleteSurveyResultWorkForm(request, callback) {
                    return this.rpcCall(deleteSurveyResultWorkForm, $root.mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteSurveyResultWorkForm" });

                /**
                 * Calls DeleteSurveyResultWorkForm.
                 * @function deleteSurveyResultWorkForm
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest} request DeleteSurveyResultWorkFormRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#importProxySurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ImportProxySurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse} [response] ImportProxySurveyResultWorkFormsResponse
                 */

                /**
                 * Calls ImportProxySurveyResultWorkForms.
                 * @function importProxySurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest} request ImportProxySurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ImportProxySurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and ImportProxySurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.importProxySurveyResultWorkForms = function importProxySurveyResultWorkForms(request, callback) {
                    return this.rpcCall(importProxySurveyResultWorkForms, $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "ImportProxySurveyResultWorkForms" });

                /**
                 * Calls ImportProxySurveyResultWorkForms.
                 * @function importProxySurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest} request ImportProxySurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveySenderService#exportProxySurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @typedef ExportProxySurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse} [response] ExportProxySurveyResultWorkFormsResponse
                 */

                /**
                 * Calls ExportProxySurveyResultWorkForms.
                 * @function exportProxySurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportProxySurveyResultWorkFormsRequest} request ExportProxySurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveySenderService.ExportProxySurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and ExportProxySurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveySenderService.prototype.exportProxySurveyResultWorkForms = function exportProxySurveyResultWorkForms(request, callback) {
                    return this.rpcCall(exportProxySurveyResultWorkForms, $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "ExportProxySurveyResultWorkForms" });

                /**
                 * Calls ExportProxySurveyResultWorkForms.
                 * @function exportProxySurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveySenderService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportProxySurveyResultWorkFormsRequest} request ExportProxySurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                return SurveySenderService;
            })();

            survey.SurveyReceiverService = (function() {

                /**
                 * Constructs a new SurveyReceiverService service.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReceiverService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SurveyReceiverService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SurveyReceiverService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SurveyReceiverService;

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#createSurveyReplyDueDateChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef CreateSurveyReplyDueDateChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeRequest} [response] SurveyReplyDueDateChangeRequest
                 */

                /**
                 * Calls CreateSurveyReplyDueDateChangeRequest.
                 * @function createSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeRequestRequest} request CreateSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.CreateSurveyReplyDueDateChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.createSurveyReplyDueDateChangeRequest = function createSurveyReplyDueDateChangeRequest(request, callback) {
                    return this.rpcCall(createSurveyReplyDueDateChangeRequest, $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest, request, callback);
                }, "name", { value: "CreateSurveyReplyDueDateChangeRequest" });

                /**
                 * Calls CreateSurveyReplyDueDateChangeRequest.
                 * @function createSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeRequestRequest} request CreateSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#listSurveyReplyDueDateChangeRequests}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef ListSurveyReplyDueDateChangeRequestsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse} [response] ListSurveyReplyDueDateChangeRequestsResponse
                 */

                /**
                 * Calls ListSurveyReplyDueDateChangeRequests.
                 * @function listSurveyReplyDueDateChangeRequests
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsRequest} request ListSurveyReplyDueDateChangeRequestsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.ListSurveyReplyDueDateChangeRequestsCallback} callback Node-style callback called with the error, if any, and ListSurveyReplyDueDateChangeRequestsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.listSurveyReplyDueDateChangeRequests = function listSurveyReplyDueDateChangeRequests(request, callback) {
                    return this.rpcCall(listSurveyReplyDueDateChangeRequests, $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest, $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse, request, callback);
                }, "name", { value: "ListSurveyReplyDueDateChangeRequests" });

                /**
                 * Calls ListSurveyReplyDueDateChangeRequests.
                 * @function listSurveyReplyDueDateChangeRequests
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsRequest} request ListSurveyReplyDueDateChangeRequestsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#getSurveyReplyDueDateChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef GetSurveyReplyDueDateChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeRequest} [response] SurveyReplyDueDateChangeRequest
                 */

                /**
                 * Calls GetSurveyReplyDueDateChangeRequest.
                 * @function getSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeRequestRequest} request GetSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.GetSurveyReplyDueDateChangeRequestCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.getSurveyReplyDueDateChangeRequest = function getSurveyReplyDueDateChangeRequest(request, callback) {
                    return this.rpcCall(getSurveyReplyDueDateChangeRequest, $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest, request, callback);
                }, "name", { value: "GetSurveyReplyDueDateChangeRequest" });

                /**
                 * Calls GetSurveyReplyDueDateChangeRequest.
                 * @function getSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeRequestRequest} request GetSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharetoSurveyReplyDueDateChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharetoSurveyReplyDueDateChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse} [response] SharetoSurveyReplyDueDateChangeRequestResponse
                 */

                /**
                 * Calls SharetoSurveyReplyDueDateChangeRequest.
                 * @function sharetoSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeRequestRequest} request SharetoSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharetoSurveyReplyDueDateChangeRequestCallback} callback Node-style callback called with the error, if any, and SharetoSurveyReplyDueDateChangeRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharetoSurveyReplyDueDateChangeRequest = function sharetoSurveyReplyDueDateChangeRequest(request, callback) {
                    return this.rpcCall(sharetoSurveyReplyDueDateChangeRequest, $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest, $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse, request, callback);
                }, "name", { value: "SharetoSurveyReplyDueDateChangeRequest" });

                /**
                 * Calls SharetoSurveyReplyDueDateChangeRequest.
                 * @function sharetoSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeRequestRequest} request SharetoSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#getSurveyReception}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef GetSurveyReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReception} [response] SurveyReception
                 */

                /**
                 * Calls GetSurveyReception.
                 * @function getSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionRequest} request GetSurveyReceptionRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.GetSurveyReceptionCallback} callback Node-style callback called with the error, if any, and SurveyReception
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.getSurveyReception = function getSurveyReception(request, callback) {
                    return this.rpcCall(getSurveyReception, $root.mtechnavi.api.survey.GetSurveyReceptionRequest, $root.mtechnavi.api.survey.SurveyReception, request, callback);
                }, "name", { value: "GetSurveyReception" });

                /**
                 * Calls GetSurveyReception.
                 * @function getSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionRequest} request GetSurveyReceptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReception>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#updateSurveyReception}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef UpdateSurveyReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReception} [response] SurveyReception
                 */

                /**
                 * Calls UpdateSurveyReception.
                 * @function updateSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyReceptionRequest} request UpdateSurveyReceptionRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.UpdateSurveyReceptionCallback} callback Node-style callback called with the error, if any, and SurveyReception
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.updateSurveyReception = function updateSurveyReception(request, callback) {
                    return this.rpcCall(updateSurveyReception, $root.mtechnavi.api.survey.UpdateSurveyReceptionRequest, $root.mtechnavi.api.survey.SurveyReception, request, callback);
                }, "name", { value: "UpdateSurveyReception" });

                /**
                 * Calls UpdateSurveyReception.
                 * @function updateSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSurveyReceptionRequest} request UpdateSurveyReceptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReception>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#updateSystemNotificationUsersInSurveyReception}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef UpdateSystemNotificationUsersInSurveyReceptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReception} [response] SurveyReception
                 */

                /**
                 * Calls UpdateSystemNotificationUsersInSurveyReception.
                 * @function updateSystemNotificationUsersInSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyReceptionRequest} request UpdateSystemNotificationUsersInSurveyReceptionRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.UpdateSystemNotificationUsersInSurveyReceptionCallback} callback Node-style callback called with the error, if any, and SurveyReception
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.updateSystemNotificationUsersInSurveyReception = function updateSystemNotificationUsersInSurveyReception(request, callback) {
                    return this.rpcCall(updateSystemNotificationUsersInSurveyReception, $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest, $root.mtechnavi.api.survey.SurveyReception, request, callback);
                }, "name", { value: "UpdateSystemNotificationUsersInSurveyReception" });

                /**
                 * Calls UpdateSystemNotificationUsersInSurveyReception.
                 * @function updateSystemNotificationUsersInSurveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyReceptionRequest} request UpdateSystemNotificationUsersInSurveyReceptionRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReception>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#createSurveyResult}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef CreateSurveyResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyResult} [response] SurveyResult
                 */

                /**
                 * Calls CreateSurveyResult.
                 * @function createSurveyResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultRequest} request CreateSurveyResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.CreateSurveyResultCallback} callback Node-style callback called with the error, if any, and SurveyResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.createSurveyResult = function createSurveyResult(request, callback) {
                    return this.rpcCall(createSurveyResult, $root.mtechnavi.api.survey.CreateSurveyResultRequest, $root.mtechnavi.api.survey.SurveyResult, request, callback);
                }, "name", { value: "CreateSurveyResult" });

                /**
                 * Calls CreateSurveyResult.
                 * @function createSurveyResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateSurveyResultRequest} request CreateSurveyResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharetoSurveyResult}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharetoSurveyResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharetoSurveyResultResponse} [response] SharetoSurveyResultResponse
                 */

                /**
                 * Calls SharetoSurveyResult.
                 * @function sharetoSurveyResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultRequest} request SharetoSurveyResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharetoSurveyResultCallback} callback Node-style callback called with the error, if any, and SharetoSurveyResultResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharetoSurveyResult = function sharetoSurveyResult(request, callback) {
                    return this.rpcCall(sharetoSurveyResult, $root.mtechnavi.api.survey.SharetoSurveyResultRequest, $root.mtechnavi.api.survey.SharetoSurveyResultResponse, request, callback);
                }, "name", { value: "SharetoSurveyResult" });

                /**
                 * Calls SharetoSurveyResult.
                 * @function sharetoSurveyResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultRequest} request SharetoSurveyResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharetoSurveyResultResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedGetSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedGetSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyRequest} [response] SurveyRequest
                 */

                /**
                 * Calls SharedGetSurveyRequest.
                 * @function sharedGetSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyRequestRequest} request SharedGetSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedGetSurveyRequestCallback} callback Node-style callback called with the error, if any, and SurveyRequest
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedGetSurveyRequest = function sharedGetSurveyRequest(request, callback) {
                    return this.rpcCall(sharedGetSurveyRequest, $root.mtechnavi.api.survey.SharedGetSurveyRequestRequest, $root.mtechnavi.api.survey.SurveyRequest, request, callback);
                }, "name", { value: "SharedGetSurveyRequest" });

                /**
                 * Calls SharedGetSurveyRequest.
                 * @function sharedGetSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyRequestRequest} request SharedGetSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyRequest>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedListSurveyReplyDueDateChangeResults}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedListSurveyReplyDueDateChangeResultsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse} [response] SharedListSurveyReplyDueDateChangeResultsResponse
                 */

                /**
                 * Calls SharedListSurveyReplyDueDateChangeResults.
                 * @function sharedListSurveyReplyDueDateChangeResults
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeResultsRequest} request SharedListSurveyReplyDueDateChangeResultsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedListSurveyReplyDueDateChangeResultsCallback} callback Node-style callback called with the error, if any, and SharedListSurveyReplyDueDateChangeResultsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedListSurveyReplyDueDateChangeResults = function sharedListSurveyReplyDueDateChangeResults(request, callback) {
                    return this.rpcCall(sharedListSurveyReplyDueDateChangeResults, $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest, $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse, request, callback);
                }, "name", { value: "SharedListSurveyReplyDueDateChangeResults" });

                /**
                 * Calls SharedListSurveyReplyDueDateChangeResults.
                 * @function sharedListSurveyReplyDueDateChangeResults
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeResultsRequest} request SharedListSurveyReplyDueDateChangeResultsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedGetSurveyReplyDueDateChangeResult}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedGetSurveyReplyDueDateChangeResultCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeResult} [response] SurveyReplyDueDateChangeResult
                 */

                /**
                 * Calls SharedGetSurveyReplyDueDateChangeResult.
                 * @function sharedGetSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeResultRequest} request SharedGetSurveyReplyDueDateChangeResultRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedGetSurveyReplyDueDateChangeResultCallback} callback Node-style callback called with the error, if any, and SurveyReplyDueDateChangeResult
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedGetSurveyReplyDueDateChangeResult = function sharedGetSurveyReplyDueDateChangeResult(request, callback) {
                    return this.rpcCall(sharedGetSurveyReplyDueDateChangeResult, $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest, $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult, request, callback);
                }, "name", { value: "SharedGetSurveyReplyDueDateChangeResult" });

                /**
                 * Calls SharedGetSurveyReplyDueDateChangeResult.
                 * @function sharedGetSurveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeResultRequest} request SharedGetSurveyReplyDueDateChangeResultRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReplyDueDateChangeResult>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedListSurveyReminders}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedListSurveyRemindersCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyRemindersResponse} [response] SharedListSurveyRemindersResponse
                 */

                /**
                 * Calls SharedListSurveyReminders.
                 * @function sharedListSurveyReminders
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyRemindersRequest} request SharedListSurveyRemindersRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedListSurveyRemindersCallback} callback Node-style callback called with the error, if any, and SharedListSurveyRemindersResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedListSurveyReminders = function sharedListSurveyReminders(request, callback) {
                    return this.rpcCall(sharedListSurveyReminders, $root.mtechnavi.api.survey.SharedListSurveyRemindersRequest, $root.mtechnavi.api.survey.SharedListSurveyRemindersResponse, request, callback);
                }, "name", { value: "SharedListSurveyReminders" });

                /**
                 * Calls SharedListSurveyReminders.
                 * @function sharedListSurveyReminders
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyRemindersRequest} request SharedListSurveyRemindersRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyRemindersResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedGetSurveyReminder}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedGetSurveyReminderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReminder} [response] SurveyReminder
                 */

                /**
                 * Calls SharedGetSurveyReminder.
                 * @function sharedGetSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReminderRequest} request SharedGetSurveyReminderRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedGetSurveyReminderCallback} callback Node-style callback called with the error, if any, and SurveyReminder
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedGetSurveyReminder = function sharedGetSurveyReminder(request, callback) {
                    return this.rpcCall(sharedGetSurveyReminder, $root.mtechnavi.api.survey.SharedGetSurveyReminderRequest, $root.mtechnavi.api.survey.SurveyReminder, request, callback);
                }, "name", { value: "SharedGetSurveyReminder" });

                /**
                 * Calls SharedGetSurveyReminder.
                 * @function sharedGetSurveyReminder
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReminderRequest} request SharedGetSurveyReminderRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReminder>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#listSurveyReceptionContents}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef ListSurveyReceptionContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListSurveyReceptionContentsResponse} [response] ListSurveyReceptionContentsResponse
                 */

                /**
                 * Calls ListSurveyReceptionContents.
                 * @function listSurveyReceptionContents
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReceptionContentsRequest} request ListSurveyReceptionContentsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.ListSurveyReceptionContentsCallback} callback Node-style callback called with the error, if any, and ListSurveyReceptionContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.listSurveyReceptionContents = function listSurveyReceptionContents(request, callback) {
                    return this.rpcCall(listSurveyReceptionContents, $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest, $root.mtechnavi.api.survey.ListSurveyReceptionContentsResponse, request, callback);
                }, "name", { value: "ListSurveyReceptionContents" });

                /**
                 * Calls ListSurveyReceptionContents.
                 * @function listSurveyReceptionContents
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListSurveyReceptionContentsRequest} request ListSurveyReceptionContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListSurveyReceptionContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#getSurveyReceptionContent}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef GetSurveyReceptionContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SurveyReceptionContent} [response] SurveyReceptionContent
                 */

                /**
                 * Calls GetSurveyReceptionContent.
                 * @function getSurveyReceptionContent
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionContentRequest} request GetSurveyReceptionContentRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.GetSurveyReceptionContentCallback} callback Node-style callback called with the error, if any, and SurveyReceptionContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.getSurveyReceptionContent = function getSurveyReceptionContent(request, callback) {
                    return this.rpcCall(getSurveyReceptionContent, $root.mtechnavi.api.survey.GetSurveyReceptionContentRequest, $root.mtechnavi.api.survey.SurveyReceptionContent, request, callback);
                }, "name", { value: "GetSurveyReceptionContent" });

                /**
                 * Calls GetSurveyReceptionContent.
                 * @function getSurveyReceptionContent
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionContentRequest} request GetSurveyReceptionContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SurveyReceptionContent>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#openSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef OpenSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.OpenSurveyRequestResponse} [response] OpenSurveyRequestResponse
                 */

                /**
                 * Calls OpenSurveyRequest.
                 * @function openSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IOpenSurveyRequestRequest} request OpenSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.OpenSurveyRequestCallback} callback Node-style callback called with the error, if any, and OpenSurveyRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.openSurveyRequest = function openSurveyRequest(request, callback) {
                    return this.rpcCall(openSurveyRequest, $root.mtechnavi.api.survey.OpenSurveyRequestRequest, $root.mtechnavi.api.survey.OpenSurveyRequestResponse, request, callback);
                }, "name", { value: "OpenSurveyRequest" });

                /**
                 * Calls OpenSurveyRequest.
                 * @function openSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IOpenSurveyRequestRequest} request OpenSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.OpenSurveyRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedListSurveyRequestWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedListSurveyRequestWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse} [response] SharedListSurveyRequestWorkFormsResponse
                 */

                /**
                 * Calls SharedListSurveyRequestWorkForms.
                 * @function sharedListSurveyRequestWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyRequestWorkFormsRequest} request SharedListSurveyRequestWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedListSurveyRequestWorkFormsCallback} callback Node-style callback called with the error, if any, and SharedListSurveyRequestWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedListSurveyRequestWorkForms = function sharedListSurveyRequestWorkForms(request, callback) {
                    return this.rpcCall(sharedListSurveyRequestWorkForms, $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest, $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse, request, callback);
                }, "name", { value: "SharedListSurveyRequestWorkForms" });

                /**
                 * Calls SharedListSurveyRequestWorkForms.
                 * @function sharedListSurveyRequestWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyRequestWorkFormsRequest} request SharedListSurveyRequestWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#sharedListSurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef SharedListSurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse} [response] SharedListSurveyResultWorkFormsResponse
                 */

                /**
                 * Calls SharedListSurveyResultWorkForms.
                 * @function sharedListSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultWorkFormsRequest} request SharedListSurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.SharedListSurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and SharedListSurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.sharedListSurveyResultWorkForms = function sharedListSurveyResultWorkForms(request, callback) {
                    return this.rpcCall(sharedListSurveyResultWorkForms, $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "SharedListSurveyResultWorkForms" });

                /**
                 * Calls SharedListSurveyResultWorkForms.
                 * @function sharedListSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultWorkFormsRequest} request SharedListSurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#importSurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef ImportSurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse} [response] ImportSurveyResultWorkFormsResponse
                 */

                /**
                 * Calls ImportSurveyResultWorkForms.
                 * @function importSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest} request ImportSurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.ImportSurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and ImportSurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.importSurveyResultWorkForms = function importSurveyResultWorkForms(request, callback) {
                    return this.rpcCall(importSurveyResultWorkForms, $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "ImportSurveyResultWorkForms" });

                /**
                 * Calls ImportSurveyResultWorkForms.
                 * @function importSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest} request ImportSurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#exportSurveyResultWorkForms}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef ExportSurveyResultWorkFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse} [response] ExportSurveyResultWorkFormsResponse
                 */

                /**
                 * Calls ExportSurveyResultWorkForms.
                 * @function exportSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyResultWorkFormsRequest} request ExportSurveyResultWorkFormsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.ExportSurveyResultWorkFormsCallback} callback Node-style callback called with the error, if any, and ExportSurveyResultWorkFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.exportSurveyResultWorkForms = function exportSurveyResultWorkForms(request, callback) {
                    return this.rpcCall(exportSurveyResultWorkForms, $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest, $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse, request, callback);
                }, "name", { value: "ExportSurveyResultWorkForms" });

                /**
                 * Calls ExportSurveyResultWorkForms.
                 * @function exportSurveyResultWorkForms
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyResultWorkFormsRequest} request ExportSurveyResultWorkFormsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#createResultFormShareManagement}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef CreateResultFormShareManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ResultFormShareManagement} [response] ResultFormShareManagement
                 */

                /**
                 * Calls CreateResultFormShareManagement.
                 * @function createResultFormShareManagement
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateResultFormShareManagementRequest} request CreateResultFormShareManagementRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.CreateResultFormShareManagementCallback} callback Node-style callback called with the error, if any, and ResultFormShareManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.createResultFormShareManagement = function createResultFormShareManagement(request, callback) {
                    return this.rpcCall(createResultFormShareManagement, $root.mtechnavi.api.survey.CreateResultFormShareManagementRequest, $root.mtechnavi.api.survey.ResultFormShareManagement, request, callback);
                }, "name", { value: "CreateResultFormShareManagement" });

                /**
                 * Calls CreateResultFormShareManagement.
                 * @function createResultFormShareManagement
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.ICreateResultFormShareManagementRequest} request CreateResultFormShareManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ResultFormShareManagement>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#listResultFormShareManagements}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef ListResultFormShareManagementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ListResultFormShareManagementsResponse} [response] ListResultFormShareManagementsResponse
                 */

                /**
                 * Calls ListResultFormShareManagements.
                 * @function listResultFormShareManagements
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListResultFormShareManagementsRequest} request ListResultFormShareManagementsRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.ListResultFormShareManagementsCallback} callback Node-style callback called with the error, if any, and ListResultFormShareManagementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.listResultFormShareManagements = function listResultFormShareManagements(request, callback) {
                    return this.rpcCall(listResultFormShareManagements, $root.mtechnavi.api.survey.ListResultFormShareManagementsRequest, $root.mtechnavi.api.survey.ListResultFormShareManagementsResponse, request, callback);
                }, "name", { value: "ListResultFormShareManagements" });

                /**
                 * Calls ListResultFormShareManagements.
                 * @function listResultFormShareManagements
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IListResultFormShareManagementsRequest} request ListResultFormShareManagementsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ListResultFormShareManagementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyReceiverService#updateResultFormShareManagement}.
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @typedef UpdateResultFormShareManagementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ResultFormShareManagement} [response] ResultFormShareManagement
                 */

                /**
                 * Calls UpdateResultFormShareManagement.
                 * @function updateResultFormShareManagement
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateResultFormShareManagementRequest} request UpdateResultFormShareManagementRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyReceiverService.UpdateResultFormShareManagementCallback} callback Node-style callback called with the error, if any, and ResultFormShareManagement
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyReceiverService.prototype.updateResultFormShareManagement = function updateResultFormShareManagement(request, callback) {
                    return this.rpcCall(updateResultFormShareManagement, $root.mtechnavi.api.survey.UpdateResultFormShareManagementRequest, $root.mtechnavi.api.survey.ResultFormShareManagement, request, callback);
                }, "name", { value: "UpdateResultFormShareManagement" });

                /**
                 * Calls UpdateResultFormShareManagement.
                 * @function updateResultFormShareManagement
                 * @memberof mtechnavi.api.survey.SurveyReceiverService
                 * @instance
                 * @param {mtechnavi.api.survey.IUpdateResultFormShareManagementRequest} request UpdateResultFormShareManagementRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ResultFormShareManagement>} Promise
                 * @variation 2
                 */

                return SurveyReceiverService;
            })();

            survey.SurveyTaskListService = (function() {

                /**
                 * Constructs a new SurveyTaskListService service.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyTaskListService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SurveyTaskListService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SurveyTaskListService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SurveyTaskListService;

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyTaskListService#uncompletedListSurveyRequest}.
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @typedef UncompletedListSurveyRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.UncompletedListSurveyRequestResponse} [response] UncompletedListSurveyRequestResponse
                 */

                /**
                 * Calls UncompletedListSurveyRequest.
                 * @function uncompletedListSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @instance
                 * @param {mtechnavi.api.survey.IUncompletedListSurveyRequestRequest} request UncompletedListSurveyRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyTaskListService.UncompletedListSurveyRequestCallback} callback Node-style callback called with the error, if any, and UncompletedListSurveyRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyTaskListService.prototype.uncompletedListSurveyRequest = function uncompletedListSurveyRequest(request, callback) {
                    return this.rpcCall(uncompletedListSurveyRequest, $root.mtechnavi.api.survey.UncompletedListSurveyRequestRequest, $root.mtechnavi.api.survey.UncompletedListSurveyRequestResponse, request, callback);
                }, "name", { value: "UncompletedListSurveyRequest" });

                /**
                 * Calls UncompletedListSurveyRequest.
                 * @function uncompletedListSurveyRequest
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @instance
                 * @param {mtechnavi.api.survey.IUncompletedListSurveyRequestRequest} request UncompletedListSurveyRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.UncompletedListSurveyRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyTaskListService#unchangedListSurveyReplyDueDateChangeRequest}.
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @typedef UnchangedListSurveyReplyDueDateChangeRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse} [response] UnchangedListSurveyReplyDueDateChangeRequestResponse
                 */

                /**
                 * Calls UnchangedListSurveyReplyDueDateChangeRequest.
                 * @function unchangedListSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @instance
                 * @param {mtechnavi.api.survey.IUnchangedListSurveyReplyDueDateChangeRequestRequest} request UnchangedListSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyTaskListService.UnchangedListSurveyReplyDueDateChangeRequestCallback} callback Node-style callback called with the error, if any, and UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyTaskListService.prototype.unchangedListSurveyReplyDueDateChangeRequest = function unchangedListSurveyReplyDueDateChangeRequest(request, callback) {
                    return this.rpcCall(unchangedListSurveyReplyDueDateChangeRequest, $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest, $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse, request, callback);
                }, "name", { value: "UnchangedListSurveyReplyDueDateChangeRequest" });

                /**
                 * Calls UnchangedListSurveyReplyDueDateChangeRequest.
                 * @function unchangedListSurveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SurveyTaskListService
                 * @instance
                 * @param {mtechnavi.api.survey.IUnchangedListSurveyReplyDueDateChangeRequestRequest} request UnchangedListSurveyReplyDueDateChangeRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse>} Promise
                 * @variation 2
                 */

                return SurveyTaskListService;
            })();

            survey.CreateSurveyRequestCatalogRequest = (function() {

                /**
                 * Properties of a CreateSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyRequestCatalogRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] CreateSurveyRequestCatalogRequest surveyRequestCatalog
                 */

                /**
                 * Constructs a new CreateSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyRequestCatalogRequest.
                 * @implements ICreateSurveyRequestCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestCatalogRequest=} [properties] Properties to set
                 */
                function CreateSurveyRequestCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyRequestCatalogRequest surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestCatalogRequest
                 * @instance
                 */
                CreateSurveyRequestCatalogRequest.prototype.surveyRequestCatalog = null;

                /**
                 * Verifies a CreateSurveyRequestCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyRequestCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyRequestCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyRequestCatalogRequest} CreateSurveyRequestCatalogRequest
                 */
                CreateSurveyRequestCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyRequestCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyRequestCatalogRequest();
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyRequestCatalogRequest.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyRequestCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestCatalogRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyRequestCatalogRequest} message CreateSurveyRequestCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyRequestCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestCatalog = null;
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyRequestCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyRequestCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyRequestCatalogRequest;
            })();

            survey.ListSurveyRequestCatalogsRequest = (function() {

                /**
                 * Properties of a ListSurveyRequestCatalogsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestCatalogsRequest
                 * @property {string|null} [pageToken] ListSurveyRequestCatalogsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestCatalogIds] ListSurveyRequestCatalogsRequest surveyRequestCatalogIds
                 */

                /**
                 * Constructs a new ListSurveyRequestCatalogsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestCatalogsRequest.
                 * @implements IListSurveyRequestCatalogsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestCatalogsRequest=} [properties] Properties to set
                 */
                function ListSurveyRequestCatalogsRequest(properties) {
                    this.surveyRequestCatalogIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestCatalogsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @instance
                 */
                ListSurveyRequestCatalogsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyRequestCatalogsRequest surveyRequestCatalogIds.
                 * @member {Array.<string>} surveyRequestCatalogIds
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @instance
                 */
                ListSurveyRequestCatalogsRequest.prototype.surveyRequestCatalogIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyRequestCatalogsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestCatalogsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestCatalogIds != null && message.hasOwnProperty("surveyRequestCatalogIds")) {
                        if (!Array.isArray(message.surveyRequestCatalogIds))
                            return "surveyRequestCatalogIds: array expected";
                        for (let i = 0; i < message.surveyRequestCatalogIds.length; ++i)
                            if (!$util.isString(message.surveyRequestCatalogIds[i]))
                                return "surveyRequestCatalogIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestCatalogsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestCatalogsRequest} ListSurveyRequestCatalogsRequest
                 */
                ListSurveyRequestCatalogsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestCatalogsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestCatalogsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestCatalogIds) {
                        if (!Array.isArray(object.surveyRequestCatalogIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestCatalogsRequest.surveyRequestCatalogIds: array expected");
                        message.surveyRequestCatalogIds = [];
                        for (let i = 0; i < object.surveyRequestCatalogIds.length; ++i)
                            message.surveyRequestCatalogIds[i] = String(object.surveyRequestCatalogIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestCatalogsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestCatalogsRequest} message ListSurveyRequestCatalogsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestCatalogsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestCatalogIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestCatalogIds && message.surveyRequestCatalogIds.length) {
                        object.surveyRequestCatalogIds = [];
                        for (let j = 0; j < message.surveyRequestCatalogIds.length; ++j)
                            object.surveyRequestCatalogIds[j] = message.surveyRequestCatalogIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestCatalogsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestCatalogsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestCatalogsRequest;
            })();

            survey.ListSurveyRequestCatalogsResponse = (function() {

                /**
                 * Properties of a ListSurveyRequestCatalogsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestCatalogsResponse
                 * @property {Long|null} [total] ListSurveyRequestCatalogsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestCatalog>|null} [items] ListSurveyRequestCatalogsResponse items
                 * @property {string|null} [pageToken] ListSurveyRequestCatalogsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyRequestCatalogsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestCatalogsResponse.
                 * @implements IListSurveyRequestCatalogsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestCatalogsResponse=} [properties] Properties to set
                 */
                function ListSurveyRequestCatalogsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestCatalogsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @instance
                 */
                ListSurveyRequestCatalogsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyRequestCatalogsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestCatalog>} items
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @instance
                 */
                ListSurveyRequestCatalogsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyRequestCatalogsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @instance
                 */
                ListSurveyRequestCatalogsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRequestCatalogsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestCatalogsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestCatalogsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestCatalogsResponse} ListSurveyRequestCatalogsResponse
                 */
                ListSurveyRequestCatalogsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestCatalogsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestCatalogsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestCatalogsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestCatalogsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestCatalogsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestCatalogsResponse} message ListSurveyRequestCatalogsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestCatalogsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestCatalogsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestCatalogsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestCatalogsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestCatalogsResponse;
            })();

            survey.GetSurveyRequestCatalogRequest = (function() {

                /**
                 * Properties of a GetSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyRequestCatalogRequest
                 * @property {string|null} [surveyRequestCatalogId] GetSurveyRequestCatalogRequest surveyRequestCatalogId
                 */

                /**
                 * Constructs a new GetSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyRequestCatalogRequest.
                 * @implements IGetSurveyRequestCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyRequestCatalogRequest=} [properties] Properties to set
                 */
                function GetSurveyRequestCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyRequestCatalogRequest surveyRequestCatalogId.
                 * @member {string} surveyRequestCatalogId
                 * @memberof mtechnavi.api.survey.GetSurveyRequestCatalogRequest
                 * @instance
                 */
                GetSurveyRequestCatalogRequest.prototype.surveyRequestCatalogId = "";

                /**
                 * Verifies a GetSurveyRequestCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyRequestCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        if (!$util.isString(message.surveyRequestCatalogId))
                            return "surveyRequestCatalogId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyRequestCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyRequestCatalogRequest} GetSurveyRequestCatalogRequest
                 */
                GetSurveyRequestCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyRequestCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyRequestCatalogRequest();
                    if (object.surveyRequestCatalogId != null)
                        message.surveyRequestCatalogId = String(object.surveyRequestCatalogId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyRequestCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestCatalogRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyRequestCatalogRequest} message GetSurveyRequestCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyRequestCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestCatalogId = "";
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        object.surveyRequestCatalogId = message.surveyRequestCatalogId;
                    return object;
                };

                /**
                 * Converts this GetSurveyRequestCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyRequestCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyRequestCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyRequestCatalogRequest;
            })();

            survey.UpdateSurveyRequestCatalogRequest = (function() {

                /**
                 * Properties of an UpdateSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyRequestCatalogRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] UpdateSurveyRequestCatalogRequest surveyRequestCatalog
                 */

                /**
                 * Constructs a new UpdateSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyRequestCatalogRequest.
                 * @implements IUpdateSurveyRequestCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestCatalogRequest=} [properties] Properties to set
                 */
                function UpdateSurveyRequestCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyRequestCatalogRequest surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest
                 * @instance
                 */
                UpdateSurveyRequestCatalogRequest.prototype.surveyRequestCatalog = null;

                /**
                 * Verifies an UpdateSurveyRequestCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyRequestCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyRequestCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest} UpdateSurveyRequestCatalogRequest
                 */
                UpdateSurveyRequestCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest();
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyRequestCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest} message UpdateSurveyRequestCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyRequestCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestCatalog = null;
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyRequestCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyRequestCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyRequestCatalogRequest;
            })();

            survey.DeleteSurveyRequestCatalogRequest = (function() {

                /**
                 * Properties of a DeleteSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IDeleteSurveyRequestCatalogRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] DeleteSurveyRequestCatalogRequest surveyRequestCatalog
                 */

                /**
                 * Constructs a new DeleteSurveyRequestCatalogRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DeleteSurveyRequestCatalogRequest.
                 * @implements IDeleteSurveyRequestCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestCatalogRequest=} [properties] Properties to set
                 */
                function DeleteSurveyRequestCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteSurveyRequestCatalogRequest surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest
                 * @instance
                 */
                DeleteSurveyRequestCatalogRequest.prototype.surveyRequestCatalog = null;

                /**
                 * Verifies a DeleteSurveyRequestCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSurveyRequestCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteSurveyRequestCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest} DeleteSurveyRequestCatalogRequest
                 */
                DeleteSurveyRequestCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest();
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteSurveyRequestCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest
                 * @static
                 * @param {mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest} message DeleteSurveyRequestCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSurveyRequestCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestCatalog = null;
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    return object;
                };

                /**
                 * Converts this DeleteSurveyRequestCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSurveyRequestCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteSurveyRequestCatalogRequest;
            })();

            survey.CreateSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of a CreateSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyBaseRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyBaseRequest|null} [surveyBaseRequest] CreateSurveyBaseRequestRequest surveyBaseRequest
                 */

                /**
                 * Constructs a new CreateSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyBaseRequestRequest.
                 * @implements ICreateSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function CreateSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyBaseRequestRequest surveyBaseRequest.
                 * @member {mtechnavi.api.survey.ISurveyBaseRequest|null|undefined} surveyBaseRequest
                 * @memberof mtechnavi.api.survey.CreateSurveyBaseRequestRequest
                 * @instance
                 */
                CreateSurveyBaseRequestRequest.prototype.surveyBaseRequest = null;

                /**
                 * Verifies a CreateSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyBaseRequest.verify(message.surveyBaseRequest);
                        if (error)
                            return "surveyBaseRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyBaseRequestRequest} CreateSurveyBaseRequestRequest
                 */
                CreateSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyBaseRequestRequest();
                    if (object.surveyBaseRequest != null) {
                        if (typeof object.surveyBaseRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyBaseRequestRequest.surveyBaseRequest: object expected");
                        message.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.fromObject(object.surveyBaseRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyBaseRequestRequest} message CreateSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyBaseRequest = null;
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest"))
                        object.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.toObject(message.surveyBaseRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyBaseRequestRequest;
            })();

            survey.UpdateSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of an UpdateSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyBaseRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyBaseRequest|null} [surveyBaseRequest] UpdateSurveyBaseRequestRequest surveyBaseRequest
                 */

                /**
                 * Constructs a new UpdateSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyBaseRequestRequest.
                 * @implements IUpdateSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function UpdateSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyBaseRequestRequest surveyBaseRequest.
                 * @member {mtechnavi.api.survey.ISurveyBaseRequest|null|undefined} surveyBaseRequest
                 * @memberof mtechnavi.api.survey.UpdateSurveyBaseRequestRequest
                 * @instance
                 */
                UpdateSurveyBaseRequestRequest.prototype.surveyBaseRequest = null;

                /**
                 * Verifies an UpdateSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyBaseRequest.verify(message.surveyBaseRequest);
                        if (error)
                            return "surveyBaseRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyBaseRequestRequest} UpdateSurveyBaseRequestRequest
                 */
                UpdateSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyBaseRequestRequest();
                    if (object.surveyBaseRequest != null) {
                        if (typeof object.surveyBaseRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyBaseRequestRequest.surveyBaseRequest: object expected");
                        message.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.fromObject(object.surveyBaseRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyBaseRequestRequest} message UpdateSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyBaseRequest = null;
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest"))
                        object.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.toObject(message.surveyBaseRequest, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyBaseRequestRequest;
            })();

            survey.DeleteSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of a DeleteSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IDeleteSurveyBaseRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyBaseRequest|null} [surveyBaseRequest] DeleteSurveyBaseRequestRequest surveyBaseRequest
                 */

                /**
                 * Constructs a new DeleteSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DeleteSurveyBaseRequestRequest.
                 * @implements IDeleteSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IDeleteSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function DeleteSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteSurveyBaseRequestRequest surveyBaseRequest.
                 * @member {mtechnavi.api.survey.ISurveyBaseRequest|null|undefined} surveyBaseRequest
                 * @memberof mtechnavi.api.survey.DeleteSurveyBaseRequestRequest
                 * @instance
                 */
                DeleteSurveyBaseRequestRequest.prototype.surveyBaseRequest = null;

                /**
                 * Verifies a DeleteSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DeleteSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyBaseRequest.verify(message.surveyBaseRequest);
                        if (error)
                            return "surveyBaseRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DeleteSurveyBaseRequestRequest} DeleteSurveyBaseRequestRequest
                 */
                DeleteSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DeleteSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DeleteSurveyBaseRequestRequest();
                    if (object.surveyBaseRequest != null) {
                        if (typeof object.surveyBaseRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.DeleteSurveyBaseRequestRequest.surveyBaseRequest: object expected");
                        message.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.fromObject(object.surveyBaseRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.DeleteSurveyBaseRequestRequest} message DeleteSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyBaseRequest = null;
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest"))
                        object.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.toObject(message.surveyBaseRequest, options);
                    return object;
                };

                /**
                 * Converts this DeleteSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DeleteSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteSurveyBaseRequestRequest;
            })();

            survey.ApprovalRequestSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of an ApprovalRequestSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IApprovalRequestSurveyBaseRequestRequest
                 * @property {string|null} [surveyBaseRequestId] ApprovalRequestSurveyBaseRequestRequest surveyBaseRequestId
                 * @property {sharelib.IUserReference|null} [approvalPlanStaff] ApprovalRequestSurveyBaseRequestRequest approvalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] ApprovalRequestSurveyBaseRequestRequest approvalPlanOrganization
                 * @property {string|null} [comment] ApprovalRequestSurveyBaseRequestRequest comment
                 */

                /**
                 * Constructs a new ApprovalRequestSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ApprovalRequestSurveyBaseRequestRequest.
                 * @implements IApprovalRequestSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function ApprovalRequestSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApprovalRequestSurveyBaseRequestRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @instance
                 */
                ApprovalRequestSurveyBaseRequestRequest.prototype.surveyBaseRequestId = "";

                /**
                 * ApprovalRequestSurveyBaseRequestRequest approvalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @instance
                 */
                ApprovalRequestSurveyBaseRequestRequest.prototype.approvalPlanStaff = null;

                /**
                 * ApprovalRequestSurveyBaseRequestRequest approvalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @instance
                 */
                ApprovalRequestSurveyBaseRequestRequest.prototype.approvalPlanOrganization = null;

                /**
                 * ApprovalRequestSurveyBaseRequestRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @instance
                 */
                ApprovalRequestSurveyBaseRequestRequest.prototype.comment = "";

                /**
                 * Verifies an ApprovalRequestSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                        if (error)
                            return "approvalPlanStaff." + error;
                    }
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                        if (error)
                            return "approvalPlanOrganization." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest} ApprovalRequestSurveyBaseRequestRequest
                 */
                ApprovalRequestSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.approvalPlanStaff != null) {
                        if (typeof object.approvalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest.approvalPlanStaff: object expected");
                        message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
                    }
                    if (object.approvalPlanOrganization != null) {
                        if (typeof object.approvalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest.approvalPlanOrganization: object expected");
                        message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApprovalRequestSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest} message ApprovalRequestSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyBaseRequestId = "";
                        object.comment = "";
                        object.approvalPlanStaff = null;
                        object.approvalPlanOrganization = null;
                    }
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                        object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                        object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this ApprovalRequestSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestSurveyBaseRequestRequest;
            })();

            survey.ApprovalRequestSurveyBaseRequestResponse = (function() {

                /**
                 * Properties of an ApprovalRequestSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IApprovalRequestSurveyBaseRequestResponse
                 */

                /**
                 * Constructs a new ApprovalRequestSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ApprovalRequestSurveyBaseRequestResponse.
                 * @implements IApprovalRequestSurveyBaseRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IApprovalRequestSurveyBaseRequestResponse=} [properties] Properties to set
                 */
                function ApprovalRequestSurveyBaseRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApprovalRequestSurveyBaseRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestSurveyBaseRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestSurveyBaseRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse} ApprovalRequestSurveyBaseRequestResponse
                 */
                ApprovalRequestSurveyBaseRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse();
                };

                /**
                 * Creates a plain object from an ApprovalRequestSurveyBaseRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse} message ApprovalRequestSurveyBaseRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestSurveyBaseRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApprovalRequestSurveyBaseRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ApprovalRequestSurveyBaseRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestSurveyBaseRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestSurveyBaseRequestResponse;
            })();

            survey.RejectSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of a RejectSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IRejectSurveyBaseRequestRequest
                 * @property {string|null} [surveyBaseRequestId] RejectSurveyBaseRequestRequest surveyBaseRequestId
                 * @property {string|null} [comment] RejectSurveyBaseRequestRequest comment
                 */

                /**
                 * Constructs a new RejectSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a RejectSurveyBaseRequestRequest.
                 * @implements IRejectSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IRejectSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function RejectSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RejectSurveyBaseRequestRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @instance
                 */
                RejectSurveyBaseRequestRequest.prototype.surveyBaseRequestId = "";

                /**
                 * RejectSurveyBaseRequestRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @instance
                 */
                RejectSurveyBaseRequestRequest.prototype.comment = "";

                /**
                 * Verifies a RejectSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a RejectSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.RejectSurveyBaseRequestRequest} RejectSurveyBaseRequestRequest
                 */
                RejectSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.RejectSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.RejectSurveyBaseRequestRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a RejectSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.RejectSurveyBaseRequestRequest} message RejectSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyBaseRequestId = "";
                        object.comment = "";
                    }
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this RejectSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectSurveyBaseRequestRequest;
            })();

            survey.RejectSurveyBaseRequestResponse = (function() {

                /**
                 * Properties of a RejectSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IRejectSurveyBaseRequestResponse
                 */

                /**
                 * Constructs a new RejectSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a RejectSurveyBaseRequestResponse.
                 * @implements IRejectSurveyBaseRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IRejectSurveyBaseRequestResponse=} [properties] Properties to set
                 */
                function RejectSurveyBaseRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a RejectSurveyBaseRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectSurveyBaseRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a RejectSurveyBaseRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.RejectSurveyBaseRequestResponse} RejectSurveyBaseRequestResponse
                 */
                RejectSurveyBaseRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.RejectSurveyBaseRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.RejectSurveyBaseRequestResponse();
                };

                /**
                 * Creates a plain object from a RejectSurveyBaseRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.RejectSurveyBaseRequestResponse} message RejectSurveyBaseRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectSurveyBaseRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this RejectSurveyBaseRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.RejectSurveyBaseRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectSurveyBaseRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectSurveyBaseRequestResponse;
            })();

            survey.ApproveSurveyBaseRequestRequest = (function() {

                /**
                 * Properties of an ApproveSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IApproveSurveyBaseRequestRequest
                 * @property {string|null} [surveyBaseRequestId] ApproveSurveyBaseRequestRequest surveyBaseRequestId
                 * @property {string|null} [comment] ApproveSurveyBaseRequestRequest comment
                 */

                /**
                 * Constructs a new ApproveSurveyBaseRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ApproveSurveyBaseRequestRequest.
                 * @implements IApproveSurveyBaseRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IApproveSurveyBaseRequestRequest=} [properties] Properties to set
                 */
                function ApproveSurveyBaseRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApproveSurveyBaseRequestRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @instance
                 */
                ApproveSurveyBaseRequestRequest.prototype.surveyBaseRequestId = "";

                /**
                 * ApproveSurveyBaseRequestRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @instance
                 */
                ApproveSurveyBaseRequestRequest.prototype.comment = "";

                /**
                 * Verifies an ApproveSurveyBaseRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveSurveyBaseRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApproveSurveyBaseRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ApproveSurveyBaseRequestRequest} ApproveSurveyBaseRequestRequest
                 */
                ApproveSurveyBaseRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ApproveSurveyBaseRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ApproveSurveyBaseRequestRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApproveSurveyBaseRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.ApproveSurveyBaseRequestRequest} message ApproveSurveyBaseRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveSurveyBaseRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyBaseRequestId = "";
                        object.comment = "";
                    }
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this ApproveSurveyBaseRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveSurveyBaseRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveSurveyBaseRequestRequest;
            })();

            survey.ApproveSurveyBaseRequestResponse = (function() {

                /**
                 * Properties of an ApproveSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IApproveSurveyBaseRequestResponse
                 */

                /**
                 * Constructs a new ApproveSurveyBaseRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ApproveSurveyBaseRequestResponse.
                 * @implements IApproveSurveyBaseRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IApproveSurveyBaseRequestResponse=} [properties] Properties to set
                 */
                function ApproveSurveyBaseRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApproveSurveyBaseRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveSurveyBaseRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApproveSurveyBaseRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ApproveSurveyBaseRequestResponse} ApproveSurveyBaseRequestResponse
                 */
                ApproveSurveyBaseRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ApproveSurveyBaseRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.ApproveSurveyBaseRequestResponse();
                };

                /**
                 * Creates a plain object from an ApproveSurveyBaseRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.ApproveSurveyBaseRequestResponse} message ApproveSurveyBaseRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveSurveyBaseRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApproveSurveyBaseRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ApproveSurveyBaseRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveSurveyBaseRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveSurveyBaseRequestResponse;
            })();

            survey.GetSurveyRequestRequest = (function() {

                /**
                 * Properties of a GetSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] GetSurveyRequestRequest surveyRequestId
                 */

                /**
                 * Constructs a new GetSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyRequestRequest.
                 * @implements IGetSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyRequestRequest=} [properties] Properties to set
                 */
                function GetSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.GetSurveyRequestRequest
                 * @instance
                 */
                GetSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies a GetSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyRequestRequest} GetSurveyRequestRequest
                 */
                GetSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyRequestRequest} message GetSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this GetSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyRequestRequest;
            })();

            survey.UpdateSurveyRequestRequest = (function() {

                /**
                 * Properties of an UpdateSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyRequest|null} [surveyRequest] UpdateSurveyRequestRequest surveyRequest
                 */

                /**
                 * Constructs a new UpdateSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyRequestRequest.
                 * @implements IUpdateSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestRequest=} [properties] Properties to set
                 */
                function UpdateSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyRequestRequest surveyRequest.
                 * @member {mtechnavi.api.survey.ISurveyRequest|null|undefined} surveyRequest
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestRequest
                 * @instance
                 */
                UpdateSurveyRequestRequest.prototype.surveyRequest = null;

                /**
                 * Verifies an UpdateSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequest.verify(message.surveyRequest);
                        if (error)
                            return "surveyRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyRequestRequest} UpdateSurveyRequestRequest
                 */
                UpdateSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyRequestRequest();
                    if (object.surveyRequest != null) {
                        if (typeof object.surveyRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyRequestRequest.surveyRequest: object expected");
                        message.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.fromObject(object.surveyRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyRequestRequest} message UpdateSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequest = null;
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest"))
                        object.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.toObject(message.surveyRequest, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyRequestRequest;
            })();

            survey.UpdateSystemNotificationUsersInSurveyRequestRequest = (function() {

                /**
                 * Properties of an UpdateSystemNotificationUsersInSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSystemNotificationUsersInSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] UpdateSystemNotificationUsersInSurveyRequestRequest surveyRequestId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] UpdateSystemNotificationUsersInSurveyRequestRequest systemNotificationUsers
                 * @property {Long|null} [updatedAt] UpdateSystemNotificationUsersInSurveyRequestRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateSystemNotificationUsersInSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSystemNotificationUsersInSurveyRequestRequest.
                 * @implements IUpdateSystemNotificationUsersInSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyRequestRequest=} [properties] Properties to set
                 */
                function UpdateSystemNotificationUsersInSurveyRequestRequest(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSystemNotificationUsersInSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * UpdateSystemNotificationUsersInSurveyRequestRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * UpdateSystemNotificationUsersInSurveyRequestRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateSystemNotificationUsersInSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateSystemNotificationUsersInSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest} UpdateSystemNotificationUsersInSurveyRequestRequest
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSystemNotificationUsersInSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest} message UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.surveyRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateSystemNotificationUsersInSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSystemNotificationUsersInSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSystemNotificationUsersInSurveyRequestRequest;
            })();

            survey.UpdateFlagInSurveyRequestRequest = (function() {

                /**
                 * Properties of an UpdateFlagInSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateFlagInSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] UpdateFlagInSurveyRequestRequest surveyRequestId
                 * @property {boolean|null} [active] UpdateFlagInSurveyRequestRequest active
                 * @property {string|null} [comment] UpdateFlagInSurveyRequestRequest comment
                 * @property {Long|null} [updatedAt] UpdateFlagInSurveyRequestRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateFlagInSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateFlagInSurveyRequestRequest.
                 * @implements IUpdateFlagInSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateFlagInSurveyRequestRequest=} [properties] Properties to set
                 */
                function UpdateFlagInSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateFlagInSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @instance
                 */
                UpdateFlagInSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * UpdateFlagInSurveyRequestRequest active.
                 * @member {boolean} active
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @instance
                 */
                UpdateFlagInSurveyRequestRequest.prototype.active = false;

                /**
                 * UpdateFlagInSurveyRequestRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @instance
                 */
                UpdateFlagInSurveyRequestRequest.prototype.comment = "";

                /**
                 * UpdateFlagInSurveyRequestRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @instance
                 */
                UpdateFlagInSurveyRequestRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateFlagInSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateFlagInSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.active != null && message.hasOwnProperty("active"))
                        if (typeof message.active !== "boolean")
                            return "active: boolean expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateFlagInSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest} UpdateFlagInSurveyRequestRequest
                 */
                UpdateFlagInSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.active != null)
                        message.active = Boolean(object.active);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateFlagInSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest} message UpdateFlagInSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateFlagInSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyRequestId = "";
                        object.active = false;
                        object.comment = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.active != null && message.hasOwnProperty("active"))
                        object.active = message.active;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateFlagInSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateFlagInSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateFlagInSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateFlagInSurveyRequestRequest;
            })();

            survey.SharetoSurveyRequestRequest = (function() {

                /**
                 * Properties of a SharetoSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyRequestRequest
                 * @property {string|null} [surveyBaseRequestId] SharetoSurveyRequestRequest surveyBaseRequestId
                 */

                /**
                 * Constructs a new SharetoSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyRequestRequest.
                 * @implements ISharetoSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyRequestRequest=} [properties] Properties to set
                 */
                function SharetoSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyRequestRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestRequest
                 * @instance
                 */
                SharetoSurveyRequestRequest.prototype.surveyBaseRequestId = "";

                /**
                 * Verifies a SharetoSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyRequestRequest} SharetoSurveyRequestRequest
                 */
                SharetoSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyRequestRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyRequestRequest} message SharetoSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyBaseRequestId = "";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    return object;
                };

                /**
                 * Converts this SharetoSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyRequestRequest;
            })();

            survey.SharetoSurveyRequestResponse = (function() {

                /**
                 * Properties of a SharetoSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyRequestResponse
                 */

                /**
                 * Constructs a new SharetoSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyRequestResponse.
                 * @implements ISharetoSurveyRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyRequestResponse=} [properties] Properties to set
                 */
                function SharetoSurveyRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyRequestResponse} SharetoSurveyRequestResponse
                 */
                SharetoSurveyRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyRequestResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyRequestResponse} message SharetoSurveyRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyRequestResponse;
            })();

            survey.DiscardSurveyRequestRequest = (function() {

                /**
                 * Properties of a DiscardSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IDiscardSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] DiscardSurveyRequestRequest surveyRequestId
                 * @property {string|null} [comment] DiscardSurveyRequestRequest comment
                 */

                /**
                 * Constructs a new DiscardSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DiscardSurveyRequestRequest.
                 * @implements IDiscardSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IDiscardSurveyRequestRequest=} [properties] Properties to set
                 */
                function DiscardSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscardSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @instance
                 */
                DiscardSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * DiscardSurveyRequestRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @instance
                 */
                DiscardSurveyRequestRequest.prototype.comment = "";

                /**
                 * Verifies a DiscardSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a DiscardSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DiscardSurveyRequestRequest} DiscardSurveyRequestRequest
                 */
                DiscardSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DiscardSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DiscardSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a DiscardSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.DiscardSurveyRequestRequest} message DiscardSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyRequestId = "";
                        object.comment = "";
                    }
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this DiscardSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardSurveyRequestRequest;
            })();

            survey.DiscardSurveyRequestResponse = (function() {

                /**
                 * Properties of a DiscardSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IDiscardSurveyRequestResponse
                 * @property {mtechnavi.api.survey.IDiscard|null} [discard] DiscardSurveyRequestResponse discard
                 */

                /**
                 * Constructs a new DiscardSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DiscardSurveyRequestResponse.
                 * @implements IDiscardSurveyRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IDiscardSurveyRequestResponse=} [properties] Properties to set
                 */
                function DiscardSurveyRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscardSurveyRequestResponse discard.
                 * @member {mtechnavi.api.survey.IDiscard|null|undefined} discard
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestResponse
                 * @instance
                 */
                DiscardSurveyRequestResponse.prototype.discard = null;

                /**
                 * Verifies a DiscardSurveyRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardSurveyRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discard != null && message.hasOwnProperty("discard")) {
                        let error = $root.mtechnavi.api.survey.Discard.verify(message.discard);
                        if (error)
                            return "discard." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DiscardSurveyRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DiscardSurveyRequestResponse} DiscardSurveyRequestResponse
                 */
                DiscardSurveyRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DiscardSurveyRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DiscardSurveyRequestResponse();
                    if (object.discard != null) {
                        if (typeof object.discard !== "object")
                            throw TypeError(".mtechnavi.api.survey.DiscardSurveyRequestResponse.discard: object expected");
                        message.discard = $root.mtechnavi.api.survey.Discard.fromObject(object.discard);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DiscardSurveyRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.DiscardSurveyRequestResponse} message DiscardSurveyRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardSurveyRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.discard = null;
                    if (message.discard != null && message.hasOwnProperty("discard"))
                        object.discard = $root.mtechnavi.api.survey.Discard.toObject(message.discard, options);
                    return object;
                };

                /**
                 * Converts this DiscardSurveyRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DiscardSurveyRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardSurveyRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardSurveyRequestResponse;
            })();

            survey.CompleteSurveyRequestRequest = (function() {

                /**
                 * Properties of a CompleteSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICompleteSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] CompleteSurveyRequestRequest surveyRequestId
                 */

                /**
                 * Constructs a new CompleteSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CompleteSurveyRequestRequest.
                 * @implements ICompleteSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICompleteSurveyRequestRequest=} [properties] Properties to set
                 */
                function CompleteSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestRequest
                 * @instance
                 */
                CompleteSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies a CompleteSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CompleteSurveyRequestRequest} CompleteSurveyRequestRequest
                 */
                CompleteSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CompleteSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CompleteSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.CompleteSurveyRequestRequest} message CompleteSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this CompleteSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSurveyRequestRequest;
            })();

            survey.CompleteSurveyRequestResponse = (function() {

                /**
                 * Properties of a CompleteSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ICompleteSurveyRequestResponse
                 * @property {mtechnavi.api.survey.IComplete|null} [complete] CompleteSurveyRequestResponse complete
                 */

                /**
                 * Constructs a new CompleteSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CompleteSurveyRequestResponse.
                 * @implements ICompleteSurveyRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ICompleteSurveyRequestResponse=} [properties] Properties to set
                 */
                function CompleteSurveyRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSurveyRequestResponse complete.
                 * @member {mtechnavi.api.survey.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestResponse
                 * @instance
                 */
                CompleteSurveyRequestResponse.prototype.complete = null;

                /**
                 * Verifies a CompleteSurveyRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSurveyRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.survey.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteSurveyRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CompleteSurveyRequestResponse} CompleteSurveyRequestResponse
                 */
                CompleteSurveyRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CompleteSurveyRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CompleteSurveyRequestResponse();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.survey.CompleteSurveyRequestResponse.complete: object expected");
                        message.complete = $root.mtechnavi.api.survey.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSurveyRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.CompleteSurveyRequestResponse} message CompleteSurveyRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSurveyRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.survey.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this CompleteSurveyRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CompleteSurveyRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSurveyRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSurveyRequestResponse;
            })();

            survey.CreateSurveyReplyDueDateChangeResultRequest = (function() {

                /**
                 * Properties of a CreateSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyReplyDueDateChangeResultRequest
                 * @property {mtechnavi.api.survey.ISurveyReplyDueDateChangeResult|null} [surveyReplyDueDateChangeResult] CreateSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResult
                 */

                /**
                 * Constructs a new CreateSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyReplyDueDateChangeResultRequest.
                 * @implements ICreateSurveyReplyDueDateChangeResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeResultRequest=} [properties] Properties to set
                 */
                function CreateSurveyReplyDueDateChangeResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResult.
                 * @member {mtechnavi.api.survey.ISurveyReplyDueDateChangeResult|null|undefined} surveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest
                 * @instance
                 */
                CreateSurveyReplyDueDateChangeResultRequest.prototype.surveyReplyDueDateChangeResult = null;

                /**
                 * Verifies a CreateSurveyReplyDueDateChangeResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyReplyDueDateChangeResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeResult != null && message.hasOwnProperty("surveyReplyDueDateChangeResult")) {
                        let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.verify(message.surveyReplyDueDateChangeResult);
                        if (error)
                            return "surveyReplyDueDateChangeResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyReplyDueDateChangeResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest} CreateSurveyReplyDueDateChangeResultRequest
                 */
                CreateSurveyReplyDueDateChangeResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest();
                    if (object.surveyReplyDueDateChangeResult != null) {
                        if (typeof object.surveyReplyDueDateChangeResult !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest.surveyReplyDueDateChangeResult: object expected");
                        message.surveyReplyDueDateChangeResult = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.fromObject(object.surveyReplyDueDateChangeResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyReplyDueDateChangeResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest} message CreateSurveyReplyDueDateChangeResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyReplyDueDateChangeResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeResult = null;
                    if (message.surveyReplyDueDateChangeResult != null && message.hasOwnProperty("surveyReplyDueDateChangeResult"))
                        object.surveyReplyDueDateChangeResult = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.toObject(message.surveyReplyDueDateChangeResult, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyReplyDueDateChangeResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyReplyDueDateChangeResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyReplyDueDateChangeResultRequest;
            })();

            survey.ListSurveyReplyDueDateChangeResultsRequest = (function() {

                /**
                 * Properties of a ListSurveyReplyDueDateChangeResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReplyDueDateChangeResultsRequest
                 * @property {string|null} [pageToken] ListSurveyReplyDueDateChangeResultsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] ListSurveyReplyDueDateChangeResultsRequest surveyRequestIds
                 */

                /**
                 * Constructs a new ListSurveyReplyDueDateChangeResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReplyDueDateChangeResultsRequest.
                 * @implements IListSurveyReplyDueDateChangeResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsRequest=} [properties] Properties to set
                 */
                function ListSurveyReplyDueDateChangeResultsRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReplyDueDateChangeResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 */
                ListSurveyReplyDueDateChangeResultsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyReplyDueDateChangeResultsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 */
                ListSurveyReplyDueDateChangeResultsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyReplyDueDateChangeResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReplyDueDateChangeResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyReplyDueDateChangeResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest} ListSurveyReplyDueDateChangeResultsRequest
                 */
                ListSurveyReplyDueDateChangeResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReplyDueDateChangeResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest} message ListSurveyReplyDueDateChangeResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReplyDueDateChangeResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyReplyDueDateChangeResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReplyDueDateChangeResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyReplyDueDateChangeResultsRequest;
            })();

            survey.ListSurveyReplyDueDateChangeResultsResponse = (function() {

                /**
                 * Properties of a ListSurveyReplyDueDateChangeResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReplyDueDateChangeResultsResponse
                 * @property {Long|null} [total] ListSurveyReplyDueDateChangeResultsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult>|null} [items] ListSurveyReplyDueDateChangeResultsResponse items
                 * @property {string|null} [pageToken] ListSurveyReplyDueDateChangeResultsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyReplyDueDateChangeResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReplyDueDateChangeResultsResponse.
                 * @implements IListSurveyReplyDueDateChangeResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeResultsResponse=} [properties] Properties to set
                 */
                function ListSurveyReplyDueDateChangeResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReplyDueDateChangeResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyReplyDueDateChangeResultsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult>} items
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyReplyDueDateChangeResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyReplyDueDateChangeResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReplyDueDateChangeResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyReplyDueDateChangeResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse} ListSurveyReplyDueDateChangeResultsResponse
                 */
                ListSurveyReplyDueDateChangeResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReplyDueDateChangeResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse} message ListSurveyReplyDueDateChangeResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReplyDueDateChangeResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyReplyDueDateChangeResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReplyDueDateChangeResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyReplyDueDateChangeResultsResponse;
            })();

            survey.GetSurveyReplyDueDateChangeResultRequest = (function() {

                /**
                 * Properties of a GetSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyReplyDueDateChangeResultRequest
                 * @property {string|null} [surveyReplyDueDateChangeResultId] GetSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResultId
                 */

                /**
                 * Constructs a new GetSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyReplyDueDateChangeResultRequest.
                 * @implements IGetSurveyReplyDueDateChangeResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeResultRequest=} [properties] Properties to set
                 */
                function GetSurveyReplyDueDateChangeResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResultId.
                 * @member {string} surveyReplyDueDateChangeResultId
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest
                 * @instance
                 */
                GetSurveyReplyDueDateChangeResultRequest.prototype.surveyReplyDueDateChangeResultId = "";

                /**
                 * Verifies a GetSurveyReplyDueDateChangeResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyReplyDueDateChangeResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeResultId))
                            return "surveyReplyDueDateChangeResultId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyReplyDueDateChangeResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest} GetSurveyReplyDueDateChangeResultRequest
                 */
                GetSurveyReplyDueDateChangeResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest();
                    if (object.surveyReplyDueDateChangeResultId != null)
                        message.surveyReplyDueDateChangeResultId = String(object.surveyReplyDueDateChangeResultId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyReplyDueDateChangeResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest} message GetSurveyReplyDueDateChangeResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyReplyDueDateChangeResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeResultId = "";
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        object.surveyReplyDueDateChangeResultId = message.surveyReplyDueDateChangeResultId;
                    return object;
                };

                /**
                 * Converts this GetSurveyReplyDueDateChangeResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyReplyDueDateChangeResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyReplyDueDateChangeResultRequest;
            })();

            survey.SharetoSurveyReplyDueDateChangeResultRequest = (function() {

                /**
                 * Properties of a SharetoSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReplyDueDateChangeResultRequest
                 * @property {mtechnavi.api.survey.ISurveyReplyDueDateChangeResult|null} [surveyReplyDueDateChangeResult] SharetoSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResult
                 */

                /**
                 * Constructs a new SharetoSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReplyDueDateChangeResultRequest.
                 * @implements ISharetoSurveyReplyDueDateChangeResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultRequest=} [properties] Properties to set
                 */
                function SharetoSurveyReplyDueDateChangeResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResult.
                 * @member {mtechnavi.api.survey.ISurveyReplyDueDateChangeResult|null|undefined} surveyReplyDueDateChangeResult
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest
                 * @instance
                 */
                SharetoSurveyReplyDueDateChangeResultRequest.prototype.surveyReplyDueDateChangeResult = null;

                /**
                 * Verifies a SharetoSurveyReplyDueDateChangeResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReplyDueDateChangeResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeResult != null && message.hasOwnProperty("surveyReplyDueDateChangeResult")) {
                        let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.verify(message.surveyReplyDueDateChangeResult);
                        if (error)
                            return "surveyReplyDueDateChangeResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReplyDueDateChangeResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest} SharetoSurveyReplyDueDateChangeResultRequest
                 */
                SharetoSurveyReplyDueDateChangeResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest();
                    if (object.surveyReplyDueDateChangeResult != null) {
                        if (typeof object.surveyReplyDueDateChangeResult !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest.surveyReplyDueDateChangeResult: object expected");
                        message.surveyReplyDueDateChangeResult = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.fromObject(object.surveyReplyDueDateChangeResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyReplyDueDateChangeResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest} message SharetoSurveyReplyDueDateChangeResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReplyDueDateChangeResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeResult = null;
                    if (message.surveyReplyDueDateChangeResult != null && message.hasOwnProperty("surveyReplyDueDateChangeResult"))
                        object.surveyReplyDueDateChangeResult = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.toObject(message.surveyReplyDueDateChangeResult, options);
                    return object;
                };

                /**
                 * Converts this SharetoSurveyReplyDueDateChangeResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReplyDueDateChangeResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReplyDueDateChangeResultRequest;
            })();

            survey.SharetoSurveyReplyDueDateChangeResultResponse = (function() {

                /**
                 * Properties of a SharetoSurveyReplyDueDateChangeResultResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReplyDueDateChangeResultResponse
                 */

                /**
                 * Constructs a new SharetoSurveyReplyDueDateChangeResultResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReplyDueDateChangeResultResponse.
                 * @implements ISharetoSurveyReplyDueDateChangeResultResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeResultResponse=} [properties] Properties to set
                 */
                function SharetoSurveyReplyDueDateChangeResultResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyReplyDueDateChangeResultResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReplyDueDateChangeResultResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReplyDueDateChangeResultResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse} SharetoSurveyReplyDueDateChangeResultResponse
                 */
                SharetoSurveyReplyDueDateChangeResultResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyReplyDueDateChangeResultResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse} message SharetoSurveyReplyDueDateChangeResultResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReplyDueDateChangeResultResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyReplyDueDateChangeResultResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeResultResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReplyDueDateChangeResultResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReplyDueDateChangeResultResponse;
            })();

            survey.CreateSurveyReplyDueDateChangeRequestRequest = (function() {

                /**
                 * Properties of a CreateSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyReplyDueDateChangeRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest|null} [surveyReplyDueDateChangeRequest] CreateSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequest
                 */

                /**
                 * Constructs a new CreateSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyReplyDueDateChangeRequestRequest.
                 * @implements ICreateSurveyReplyDueDateChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyReplyDueDateChangeRequestRequest=} [properties] Properties to set
                 */
                function CreateSurveyReplyDueDateChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequest.
                 * @member {mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest|null|undefined} surveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 */
                CreateSurveyReplyDueDateChangeRequestRequest.prototype.surveyReplyDueDateChangeRequest = null;

                /**
                 * Verifies a CreateSurveyReplyDueDateChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyReplyDueDateChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeRequest != null && message.hasOwnProperty("surveyReplyDueDateChangeRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.verify(message.surveyReplyDueDateChangeRequest);
                        if (error)
                            return "surveyReplyDueDateChangeRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyReplyDueDateChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest} CreateSurveyReplyDueDateChangeRequestRequest
                 */
                CreateSurveyReplyDueDateChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest();
                    if (object.surveyReplyDueDateChangeRequest != null) {
                        if (typeof object.surveyReplyDueDateChangeRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest.surveyReplyDueDateChangeRequest: object expected");
                        message.surveyReplyDueDateChangeRequest = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.fromObject(object.surveyReplyDueDateChangeRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyReplyDueDateChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest} message CreateSurveyReplyDueDateChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyReplyDueDateChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeRequest = null;
                    if (message.surveyReplyDueDateChangeRequest != null && message.hasOwnProperty("surveyReplyDueDateChangeRequest"))
                        object.surveyReplyDueDateChangeRequest = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.toObject(message.surveyReplyDueDateChangeRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyReplyDueDateChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyReplyDueDateChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyReplyDueDateChangeRequestRequest;
            })();

            survey.ListSurveyReplyDueDateChangeRequestsRequest = (function() {

                /**
                 * Properties of a ListSurveyReplyDueDateChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReplyDueDateChangeRequestsRequest
                 * @property {string|null} [pageToken] ListSurveyReplyDueDateChangeRequestsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] ListSurveyReplyDueDateChangeRequestsRequest surveyRequestIds
                 */

                /**
                 * Constructs a new ListSurveyReplyDueDateChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReplyDueDateChangeRequestsRequest.
                 * @implements IListSurveyReplyDueDateChangeRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsRequest=} [properties] Properties to set
                 */
                function ListSurveyReplyDueDateChangeRequestsRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReplyDueDateChangeRequestsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyReplyDueDateChangeRequestsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyReplyDueDateChangeRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyReplyDueDateChangeRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest} ListSurveyReplyDueDateChangeRequestsRequest
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReplyDueDateChangeRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest} message ListSurveyReplyDueDateChangeRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyReplyDueDateChangeRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReplyDueDateChangeRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyReplyDueDateChangeRequestsRequest;
            })();

            survey.ListSurveyReplyDueDateChangeRequestsResponse = (function() {

                /**
                 * Properties of a ListSurveyReplyDueDateChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReplyDueDateChangeRequestsResponse
                 * @property {Long|null} [total] ListSurveyReplyDueDateChangeRequestsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest>|null} [items] ListSurveyReplyDueDateChangeRequestsResponse items
                 * @property {string|null} [pageToken] ListSurveyReplyDueDateChangeRequestsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyReplyDueDateChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReplyDueDateChangeRequestsResponse.
                 * @implements IListSurveyReplyDueDateChangeRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReplyDueDateChangeRequestsResponse=} [properties] Properties to set
                 */
                function ListSurveyReplyDueDateChangeRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReplyDueDateChangeRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyReplyDueDateChangeRequestsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest>} items
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyReplyDueDateChangeRequestsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyReplyDueDateChangeRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyReplyDueDateChangeRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse} ListSurveyReplyDueDateChangeRequestsResponse
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReplyDueDateChangeRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse} message ListSurveyReplyDueDateChangeRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyReplyDueDateChangeRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReplyDueDateChangeRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyReplyDueDateChangeRequestsResponse;
            })();

            survey.GetSurveyReplyDueDateChangeRequestRequest = (function() {

                /**
                 * Properties of a GetSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyReplyDueDateChangeRequestRequest
                 * @property {string|null} [surveyReplyDueDateChangeRequestId] GetSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequestId
                 */

                /**
                 * Constructs a new GetSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyReplyDueDateChangeRequestRequest.
                 * @implements IGetSurveyReplyDueDateChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyReplyDueDateChangeRequestRequest=} [properties] Properties to set
                 */
                function GetSurveyReplyDueDateChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequestId.
                 * @member {string} surveyReplyDueDateChangeRequestId
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 */
                GetSurveyReplyDueDateChangeRequestRequest.prototype.surveyReplyDueDateChangeRequestId = "";

                /**
                 * Verifies a GetSurveyReplyDueDateChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyReplyDueDateChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeRequestId))
                            return "surveyReplyDueDateChangeRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyReplyDueDateChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest} GetSurveyReplyDueDateChangeRequestRequest
                 */
                GetSurveyReplyDueDateChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest();
                    if (object.surveyReplyDueDateChangeRequestId != null)
                        message.surveyReplyDueDateChangeRequestId = String(object.surveyReplyDueDateChangeRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyReplyDueDateChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest} message GetSurveyReplyDueDateChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyReplyDueDateChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeRequestId = "";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        object.surveyReplyDueDateChangeRequestId = message.surveyReplyDueDateChangeRequestId;
                    return object;
                };

                /**
                 * Converts this GetSurveyReplyDueDateChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyReplyDueDateChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyReplyDueDateChangeRequestRequest;
            })();

            survey.SharetoSurveyReplyDueDateChangeRequestRequest = (function() {

                /**
                 * Properties of a SharetoSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReplyDueDateChangeRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest|null} [surveyReplyDueDateChangeRequest] SharetoSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequest
                 */

                /**
                 * Constructs a new SharetoSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReplyDueDateChangeRequestRequest.
                 * @implements ISharetoSurveyReplyDueDateChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeRequestRequest=} [properties] Properties to set
                 */
                function SharetoSurveyReplyDueDateChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequest.
                 * @member {mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest|null|undefined} surveyReplyDueDateChangeRequest
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 */
                SharetoSurveyReplyDueDateChangeRequestRequest.prototype.surveyReplyDueDateChangeRequest = null;

                /**
                 * Verifies a SharetoSurveyReplyDueDateChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReplyDueDateChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeRequest != null && message.hasOwnProperty("surveyReplyDueDateChangeRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.verify(message.surveyReplyDueDateChangeRequest);
                        if (error)
                            return "surveyReplyDueDateChangeRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReplyDueDateChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest} SharetoSurveyReplyDueDateChangeRequestRequest
                 */
                SharetoSurveyReplyDueDateChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest();
                    if (object.surveyReplyDueDateChangeRequest != null) {
                        if (typeof object.surveyReplyDueDateChangeRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest.surveyReplyDueDateChangeRequest: object expected");
                        message.surveyReplyDueDateChangeRequest = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.fromObject(object.surveyReplyDueDateChangeRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyReplyDueDateChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest} message SharetoSurveyReplyDueDateChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReplyDueDateChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeRequest = null;
                    if (message.surveyReplyDueDateChangeRequest != null && message.hasOwnProperty("surveyReplyDueDateChangeRequest"))
                        object.surveyReplyDueDateChangeRequest = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.toObject(message.surveyReplyDueDateChangeRequest, options);
                    return object;
                };

                /**
                 * Converts this SharetoSurveyReplyDueDateChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReplyDueDateChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReplyDueDateChangeRequestRequest;
            })();

            survey.SharetoSurveyReplyDueDateChangeRequestResponse = (function() {

                /**
                 * Properties of a SharetoSurveyReplyDueDateChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReplyDueDateChangeRequestResponse
                 */

                /**
                 * Constructs a new SharetoSurveyReplyDueDateChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReplyDueDateChangeRequestResponse.
                 * @implements ISharetoSurveyReplyDueDateChangeRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReplyDueDateChangeRequestResponse=} [properties] Properties to set
                 */
                function SharetoSurveyReplyDueDateChangeRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyReplyDueDateChangeRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReplyDueDateChangeRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReplyDueDateChangeRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse} SharetoSurveyReplyDueDateChangeRequestResponse
                 */
                SharetoSurveyReplyDueDateChangeRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyReplyDueDateChangeRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse} message SharetoSurveyReplyDueDateChangeRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReplyDueDateChangeRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyReplyDueDateChangeRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReplyDueDateChangeRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReplyDueDateChangeRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReplyDueDateChangeRequestResponse;
            })();

            survey.GetSurveyReceptionRequest = (function() {

                /**
                 * Properties of a GetSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyReceptionRequest
                 * @property {string|null} [surveyReceptionId] GetSurveyReceptionRequest surveyReceptionId
                 */

                /**
                 * Constructs a new GetSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyReceptionRequest.
                 * @implements IGetSurveyReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionRequest=} [properties] Properties to set
                 */
                function GetSurveyReceptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyReceptionRequest surveyReceptionId.
                 * @member {string} surveyReceptionId
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionRequest
                 * @instance
                 */
                GetSurveyReceptionRequest.prototype.surveyReceptionId = "";

                /**
                 * Verifies a GetSurveyReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        if (!$util.isString(message.surveyReceptionId))
                            return "surveyReceptionId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyReceptionRequest} GetSurveyReceptionRequest
                 */
                GetSurveyReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyReceptionRequest();
                    if (object.surveyReceptionId != null)
                        message.surveyReceptionId = String(object.surveyReceptionId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyReceptionRequest} message GetSurveyReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReceptionId = "";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        object.surveyReceptionId = message.surveyReceptionId;
                    return object;
                };

                /**
                 * Converts this GetSurveyReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyReceptionRequest;
            })();

            survey.UpdateSurveyReceptionRequest = (function() {

                /**
                 * Properties of an UpdateSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyReceptionRequest
                 * @property {mtechnavi.api.survey.ISurveyReception|null} [surveyReception] UpdateSurveyReceptionRequest surveyReception
                 */

                /**
                 * Constructs a new UpdateSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyReceptionRequest.
                 * @implements IUpdateSurveyReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyReceptionRequest=} [properties] Properties to set
                 */
                function UpdateSurveyReceptionRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyReceptionRequest surveyReception.
                 * @member {mtechnavi.api.survey.ISurveyReception|null|undefined} surveyReception
                 * @memberof mtechnavi.api.survey.UpdateSurveyReceptionRequest
                 * @instance
                 */
                UpdateSurveyReceptionRequest.prototype.surveyReception = null;

                /**
                 * Verifies an UpdateSurveyReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReception != null && message.hasOwnProperty("surveyReception")) {
                        let error = $root.mtechnavi.api.survey.SurveyReception.verify(message.surveyReception);
                        if (error)
                            return "surveyReception." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyReceptionRequest} UpdateSurveyReceptionRequest
                 */
                UpdateSurveyReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyReceptionRequest();
                    if (object.surveyReception != null) {
                        if (typeof object.surveyReception !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyReceptionRequest.surveyReception: object expected");
                        message.surveyReception = $root.mtechnavi.api.survey.SurveyReception.fromObject(object.surveyReception);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyReceptionRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyReceptionRequest} message UpdateSurveyReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReception = null;
                    if (message.surveyReception != null && message.hasOwnProperty("surveyReception"))
                        object.surveyReception = $root.mtechnavi.api.survey.SurveyReception.toObject(message.surveyReception, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyReceptionRequest;
            })();

            survey.UpdateSystemNotificationUsersInSurveyReceptionRequest = (function() {

                /**
                 * Properties of an UpdateSystemNotificationUsersInSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @property {string|null} [surveyReceptionId] UpdateSystemNotificationUsersInSurveyReceptionRequest surveyReceptionId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] UpdateSystemNotificationUsersInSurveyReceptionRequest systemNotificationUsers
                 * @property {Long|null} [updatedAt] UpdateSystemNotificationUsersInSurveyReceptionRequest updatedAt
                 */

                /**
                 * Constructs a new UpdateSystemNotificationUsersInSurveyReceptionRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSystemNotificationUsersInSurveyReceptionRequest.
                 * @implements IUpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSystemNotificationUsersInSurveyReceptionRequest=} [properties] Properties to set
                 */
                function UpdateSystemNotificationUsersInSurveyReceptionRequest(properties) {
                    this.systemNotificationUsers = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSystemNotificationUsersInSurveyReceptionRequest surveyReceptionId.
                 * @member {string} surveyReceptionId
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.prototype.surveyReceptionId = "";

                /**
                 * UpdateSystemNotificationUsersInSurveyReceptionRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * UpdateSystemNotificationUsersInSurveyReceptionRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @instance
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an UpdateSystemNotificationUsersInSurveyReceptionRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        if (!$util.isString(message.surveyReceptionId))
                            return "surveyReceptionId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an UpdateSystemNotificationUsersInSurveyReceptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest} UpdateSystemNotificationUsersInSurveyReceptionRequest
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest();
                    if (object.surveyReceptionId != null)
                        message.surveyReceptionId = String(object.surveyReceptionId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSystemNotificationUsersInSurveyReceptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest} message UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.systemNotificationUsers = [];
                    if (options.defaults) {
                        object.surveyReceptionId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        object.surveyReceptionId = message.surveyReceptionId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UpdateSystemNotificationUsersInSurveyReceptionRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSystemNotificationUsersInSurveyReceptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSystemNotificationUsersInSurveyReceptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSystemNotificationUsersInSurveyReceptionRequest;
            })();

            survey.CreateSurveyResultRequest = (function() {

                /**
                 * Properties of a CreateSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyResultRequest
                 * @property {mtechnavi.api.survey.ISurveyResult|null} [surveyResult] CreateSurveyResultRequest surveyResult
                 */

                /**
                 * Constructs a new CreateSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyResultRequest.
                 * @implements ICreateSurveyResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyResultRequest=} [properties] Properties to set
                 */
                function CreateSurveyResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyResultRequest surveyResult.
                 * @member {mtechnavi.api.survey.ISurveyResult|null|undefined} surveyResult
                 * @memberof mtechnavi.api.survey.CreateSurveyResultRequest
                 * @instance
                 */
                CreateSurveyResultRequest.prototype.surveyResult = null;

                /**
                 * Verifies a CreateSurveyResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResult != null && message.hasOwnProperty("surveyResult")) {
                        let error = $root.mtechnavi.api.survey.SurveyResult.verify(message.surveyResult);
                        if (error)
                            return "surveyResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyResultRequest} CreateSurveyResultRequest
                 */
                CreateSurveyResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyResultRequest();
                    if (object.surveyResult != null) {
                        if (typeof object.surveyResult !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyResultRequest.surveyResult: object expected");
                        message.surveyResult = $root.mtechnavi.api.survey.SurveyResult.fromObject(object.surveyResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyResultRequest} message CreateSurveyResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResult = null;
                    if (message.surveyResult != null && message.hasOwnProperty("surveyResult"))
                        object.surveyResult = $root.mtechnavi.api.survey.SurveyResult.toObject(message.surveyResult, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyResultRequest;
            })();

            survey.SharetoSurveyResultRequest = (function() {

                /**
                 * Properties of a SharetoSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyResultRequest
                 * @property {mtechnavi.api.survey.ISurveyResult|null} [surveyResult] SharetoSurveyResultRequest surveyResult
                 */

                /**
                 * Constructs a new SharetoSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyResultRequest.
                 * @implements ISharetoSurveyResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultRequest=} [properties] Properties to set
                 */
                function SharetoSurveyResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyResultRequest surveyResult.
                 * @member {mtechnavi.api.survey.ISurveyResult|null|undefined} surveyResult
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultRequest
                 * @instance
                 */
                SharetoSurveyResultRequest.prototype.surveyResult = null;

                /**
                 * Verifies a SharetoSurveyResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResult != null && message.hasOwnProperty("surveyResult")) {
                        let error = $root.mtechnavi.api.survey.SurveyResult.verify(message.surveyResult);
                        if (error)
                            return "surveyResult." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoSurveyResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyResultRequest} SharetoSurveyResultRequest
                 */
                SharetoSurveyResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyResultRequest();
                    if (object.surveyResult != null) {
                        if (typeof object.surveyResult !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoSurveyResultRequest.surveyResult: object expected");
                        message.surveyResult = $root.mtechnavi.api.survey.SurveyResult.fromObject(object.surveyResult);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyResultRequest} message SharetoSurveyResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResult = null;
                    if (message.surveyResult != null && message.hasOwnProperty("surveyResult"))
                        object.surveyResult = $root.mtechnavi.api.survey.SurveyResult.toObject(message.surveyResult, options);
                    return object;
                };

                /**
                 * Converts this SharetoSurveyResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyResultRequest;
            })();

            survey.SharetoSurveyResultResponse = (function() {

                /**
                 * Properties of a SharetoSurveyResultResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyResultResponse
                 */

                /**
                 * Constructs a new SharetoSurveyResultResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyResultResponse.
                 * @implements ISharetoSurveyResultResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultResponse=} [properties] Properties to set
                 */
                function SharetoSurveyResultResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyResultResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyResultResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyResultResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyResultResponse} SharetoSurveyResultResponse
                 */
                SharetoSurveyResultResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyResultResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyResultResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyResultResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyResultResponse} message SharetoSurveyResultResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyResultResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyResultResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyResultResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyResultResponse;
            })();

            survey.CreateSurveyResultChangeRequestRequest = (function() {

                /**
                 * Properties of a CreateSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyResultChangeRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyResultChangeRequest|null} [surveyResultChangeRequest] CreateSurveyResultChangeRequestRequest surveyResultChangeRequest
                 */

                /**
                 * Constructs a new CreateSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyResultChangeRequestRequest.
                 * @implements ICreateSurveyResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyResultChangeRequestRequest=} [properties] Properties to set
                 */
                function CreateSurveyResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyResultChangeRequestRequest surveyResultChangeRequest.
                 * @member {mtechnavi.api.survey.ISurveyResultChangeRequest|null|undefined} surveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest
                 * @instance
                 */
                CreateSurveyResultChangeRequestRequest.prototype.surveyResultChangeRequest = null;

                /**
                 * Verifies a CreateSurveyResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultChangeRequest != null && message.hasOwnProperty("surveyResultChangeRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyResultChangeRequest.verify(message.surveyResultChangeRequest);
                        if (error)
                            return "surveyResultChangeRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest} CreateSurveyResultChangeRequestRequest
                 */
                CreateSurveyResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest();
                    if (object.surveyResultChangeRequest != null) {
                        if (typeof object.surveyResultChangeRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest.surveyResultChangeRequest: object expected");
                        message.surveyResultChangeRequest = $root.mtechnavi.api.survey.SurveyResultChangeRequest.fromObject(object.surveyResultChangeRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest} message CreateSurveyResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultChangeRequest = null;
                    if (message.surveyResultChangeRequest != null && message.hasOwnProperty("surveyResultChangeRequest"))
                        object.surveyResultChangeRequest = $root.mtechnavi.api.survey.SurveyResultChangeRequest.toObject(message.surveyResultChangeRequest, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyResultChangeRequestRequest;
            })();

            survey.ListSurveyResultChangeRequestsRequest = (function() {

                /**
                 * Properties of a ListSurveyResultChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyResultChangeRequestsRequest
                 * @property {string|null} [pageToken] ListSurveyResultChangeRequestsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] ListSurveyResultChangeRequestsRequest surveyRequestIds
                 * @property {Array.<string>|null} [surveyResultIds] ListSurveyResultChangeRequestsRequest surveyResultIds
                 */

                /**
                 * Constructs a new ListSurveyResultChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyResultChangeRequestsRequest.
                 * @implements IListSurveyResultChangeRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyResultChangeRequestsRequest=} [properties] Properties to set
                 */
                function ListSurveyResultChangeRequestsRequest(properties) {
                    this.surveyRequestIds = [];
                    this.surveyResultIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyResultChangeRequestsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                ListSurveyResultChangeRequestsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyResultChangeRequestsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                ListSurveyResultChangeRequestsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * ListSurveyResultChangeRequestsRequest surveyResultIds.
                 * @member {Array.<string>} surveyResultIds
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                ListSurveyResultChangeRequestsRequest.prototype.surveyResultIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyResultChangeRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyResultChangeRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    if (message.surveyResultIds != null && message.hasOwnProperty("surveyResultIds")) {
                        if (!Array.isArray(message.surveyResultIds))
                            return "surveyResultIds: array expected";
                        for (let i = 0; i < message.surveyResultIds.length; ++i)
                            if (!$util.isString(message.surveyResultIds[i]))
                                return "surveyResultIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyResultChangeRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest} ListSurveyResultChangeRequestsRequest
                 */
                ListSurveyResultChangeRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    if (object.surveyResultIds) {
                        if (!Array.isArray(object.surveyResultIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest.surveyResultIds: array expected");
                        message.surveyResultIds = [];
                        for (let i = 0; i < object.surveyResultIds.length; ++i)
                            message.surveyResultIds[i] = String(object.surveyResultIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyResultChangeRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest} message ListSurveyResultChangeRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyResultChangeRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.surveyRequestIds = [];
                        object.surveyResultIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    if (message.surveyResultIds && message.surveyResultIds.length) {
                        object.surveyResultIds = [];
                        for (let j = 0; j < message.surveyResultIds.length; ++j)
                            object.surveyResultIds[j] = message.surveyResultIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyResultChangeRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyResultChangeRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyResultChangeRequestsRequest;
            })();

            survey.ListSurveyResultChangeRequestsResponse = (function() {

                /**
                 * Properties of a ListSurveyResultChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyResultChangeRequestsResponse
                 * @property {Long|null} [total] ListSurveyResultChangeRequestsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyResultChangeRequest>|null} [items] ListSurveyResultChangeRequestsResponse items
                 * @property {string|null} [pageToken] ListSurveyResultChangeRequestsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyResultChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyResultChangeRequestsResponse.
                 * @implements IListSurveyResultChangeRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyResultChangeRequestsResponse=} [properties] Properties to set
                 */
                function ListSurveyResultChangeRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyResultChangeRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @instance
                 */
                ListSurveyResultChangeRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyResultChangeRequestsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyResultChangeRequest>} items
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @instance
                 */
                ListSurveyResultChangeRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyResultChangeRequestsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @instance
                 */
                ListSurveyResultChangeRequestsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyResultChangeRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyResultChangeRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyResultChangeRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyResultChangeRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse} ListSurveyResultChangeRequestsResponse
                 */
                ListSurveyResultChangeRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyResultChangeRequest.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyResultChangeRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse} message ListSurveyResultChangeRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyResultChangeRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyResultChangeRequest.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyResultChangeRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyResultChangeRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyResultChangeRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyResultChangeRequestsResponse;
            })();

            survey.GetSurveyResultChangeRequestRequest = (function() {

                /**
                 * Properties of a GetSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyResultChangeRequestRequest
                 * @property {string|null} [surveyResultChangeRequestId] GetSurveyResultChangeRequestRequest surveyResultChangeRequestId
                 */

                /**
                 * Constructs a new GetSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyResultChangeRequestRequest.
                 * @implements IGetSurveyResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyResultChangeRequestRequest=} [properties] Properties to set
                 */
                function GetSurveyResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyResultChangeRequestRequest surveyResultChangeRequestId.
                 * @member {string} surveyResultChangeRequestId
                 * @memberof mtechnavi.api.survey.GetSurveyResultChangeRequestRequest
                 * @instance
                 */
                GetSurveyResultChangeRequestRequest.prototype.surveyResultChangeRequestId = "";

                /**
                 * Verifies a GetSurveyResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        if (!$util.isString(message.surveyResultChangeRequestId))
                            return "surveyResultChangeRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyResultChangeRequestRequest} GetSurveyResultChangeRequestRequest
                 */
                GetSurveyResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyResultChangeRequestRequest();
                    if (object.surveyResultChangeRequestId != null)
                        message.surveyResultChangeRequestId = String(object.surveyResultChangeRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyResultChangeRequestRequest} message GetSurveyResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultChangeRequestId = "";
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        object.surveyResultChangeRequestId = message.surveyResultChangeRequestId;
                    return object;
                };

                /**
                 * Converts this GetSurveyResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyResultChangeRequestRequest;
            })();

            survey.SharetoSurveyResultChangeRequestRequest = (function() {

                /**
                 * Properties of a SharetoSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyResultChangeRequestRequest
                 * @property {mtechnavi.api.survey.ISurveyResultChangeRequest|null} [surveyResultChangeRequest] SharetoSurveyResultChangeRequestRequest surveyResultChangeRequest
                 */

                /**
                 * Constructs a new SharetoSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyResultChangeRequestRequest.
                 * @implements ISharetoSurveyResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultChangeRequestRequest=} [properties] Properties to set
                 */
                function SharetoSurveyResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyResultChangeRequestRequest surveyResultChangeRequest.
                 * @member {mtechnavi.api.survey.ISurveyResultChangeRequest|null|undefined} surveyResultChangeRequest
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest
                 * @instance
                 */
                SharetoSurveyResultChangeRequestRequest.prototype.surveyResultChangeRequest = null;

                /**
                 * Verifies a SharetoSurveyResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultChangeRequest != null && message.hasOwnProperty("surveyResultChangeRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyResultChangeRequest.verify(message.surveyResultChangeRequest);
                        if (error)
                            return "surveyResultChangeRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoSurveyResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest} SharetoSurveyResultChangeRequestRequest
                 */
                SharetoSurveyResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest();
                    if (object.surveyResultChangeRequest != null) {
                        if (typeof object.surveyResultChangeRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest.surveyResultChangeRequest: object expected");
                        message.surveyResultChangeRequest = $root.mtechnavi.api.survey.SurveyResultChangeRequest.fromObject(object.surveyResultChangeRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest} message SharetoSurveyResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultChangeRequest = null;
                    if (message.surveyResultChangeRequest != null && message.hasOwnProperty("surveyResultChangeRequest"))
                        object.surveyResultChangeRequest = $root.mtechnavi.api.survey.SurveyResultChangeRequest.toObject(message.surveyResultChangeRequest, options);
                    return object;
                };

                /**
                 * Converts this SharetoSurveyResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyResultChangeRequestRequest;
            })();

            survey.SharetoSurveyResultChangeRequestResponse = (function() {

                /**
                 * Properties of a SharetoSurveyResultChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyResultChangeRequestResponse
                 */

                /**
                 * Constructs a new SharetoSurveyResultChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyResultChangeRequestResponse.
                 * @implements ISharetoSurveyResultChangeRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyResultChangeRequestResponse=} [properties] Properties to set
                 */
                function SharetoSurveyResultChangeRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyResultChangeRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyResultChangeRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyResultChangeRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse} SharetoSurveyResultChangeRequestResponse
                 */
                SharetoSurveyResultChangeRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyResultChangeRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse} message SharetoSurveyResultChangeRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyResultChangeRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyResultChangeRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyResultChangeRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyResultChangeRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyResultChangeRequestResponse;
            })();

            survey.CreateSurveyReminderRequest = (function() {

                /**
                 * Properties of a CreateSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyReminderRequest
                 * @property {mtechnavi.api.survey.ISurveyReminder|null} [surveyReminder] CreateSurveyReminderRequest surveyReminder
                 */

                /**
                 * Constructs a new CreateSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyReminderRequest.
                 * @implements ICreateSurveyReminderRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyReminderRequest=} [properties] Properties to set
                 */
                function CreateSurveyReminderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyReminderRequest surveyReminder.
                 * @member {mtechnavi.api.survey.ISurveyReminder|null|undefined} surveyReminder
                 * @memberof mtechnavi.api.survey.CreateSurveyReminderRequest
                 * @instance
                 */
                CreateSurveyReminderRequest.prototype.surveyReminder = null;

                /**
                 * Verifies a CreateSurveyReminderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyReminderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReminder != null && message.hasOwnProperty("surveyReminder")) {
                        let error = $root.mtechnavi.api.survey.SurveyReminder.verify(message.surveyReminder);
                        if (error)
                            return "surveyReminder." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyReminderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyReminderRequest} CreateSurveyReminderRequest
                 */
                CreateSurveyReminderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyReminderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyReminderRequest();
                    if (object.surveyReminder != null) {
                        if (typeof object.surveyReminder !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyReminderRequest.surveyReminder: object expected");
                        message.surveyReminder = $root.mtechnavi.api.survey.SurveyReminder.fromObject(object.surveyReminder);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyReminderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyReminderRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyReminderRequest} message CreateSurveyReminderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyReminderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReminder = null;
                    if (message.surveyReminder != null && message.hasOwnProperty("surveyReminder"))
                        object.surveyReminder = $root.mtechnavi.api.survey.SurveyReminder.toObject(message.surveyReminder, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyReminderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyReminderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyReminderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyReminderRequest;
            })();

            survey.ListSurveyRemindersRequest = (function() {

                /**
                 * Properties of a ListSurveyRemindersRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRemindersRequest
                 * @property {string|null} [pageToken] ListSurveyRemindersRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] ListSurveyRemindersRequest surveyRequestIds
                 */

                /**
                 * Constructs a new ListSurveyRemindersRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRemindersRequest.
                 * @implements IListSurveyRemindersRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRemindersRequest=} [properties] Properties to set
                 */
                function ListSurveyRemindersRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRemindersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @instance
                 */
                ListSurveyRemindersRequest.prototype.pageToken = "";

                /**
                 * ListSurveyRemindersRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @instance
                 */
                ListSurveyRemindersRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyRemindersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRemindersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyRemindersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRemindersRequest} ListSurveyRemindersRequest
                 */
                ListSurveyRemindersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRemindersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRemindersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRemindersRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRemindersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRemindersRequest} message ListSurveyRemindersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRemindersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyRemindersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRemindersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRemindersRequest;
            })();

            survey.ListSurveyRemindersResponse = (function() {

                /**
                 * Properties of a ListSurveyRemindersResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRemindersResponse
                 * @property {Long|null} [total] ListSurveyRemindersResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReminder>|null} [items] ListSurveyRemindersResponse items
                 * @property {string|null} [pageToken] ListSurveyRemindersResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyRemindersResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRemindersResponse.
                 * @implements IListSurveyRemindersResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRemindersResponse=} [properties] Properties to set
                 */
                function ListSurveyRemindersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRemindersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @instance
                 */
                ListSurveyRemindersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyRemindersResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReminder>} items
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @instance
                 */
                ListSurveyRemindersResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyRemindersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @instance
                 */
                ListSurveyRemindersResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRemindersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRemindersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReminder.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRemindersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRemindersResponse} ListSurveyRemindersResponse
                 */
                ListSurveyRemindersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRemindersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRemindersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRemindersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRemindersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReminder.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRemindersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRemindersResponse} message ListSurveyRemindersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRemindersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReminder.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRemindersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRemindersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRemindersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRemindersResponse;
            })();

            survey.GetSurveyReminderRequest = (function() {

                /**
                 * Properties of a GetSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyReminderRequest
                 * @property {string|null} [surveyReminderId] GetSurveyReminderRequest surveyReminderId
                 */

                /**
                 * Constructs a new GetSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyReminderRequest.
                 * @implements IGetSurveyReminderRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyReminderRequest=} [properties] Properties to set
                 */
                function GetSurveyReminderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyReminderRequest surveyReminderId.
                 * @member {string} surveyReminderId
                 * @memberof mtechnavi.api.survey.GetSurveyReminderRequest
                 * @instance
                 */
                GetSurveyReminderRequest.prototype.surveyReminderId = "";

                /**
                 * Verifies a GetSurveyReminderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyReminderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        if (!$util.isString(message.surveyReminderId))
                            return "surveyReminderId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyReminderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyReminderRequest} GetSurveyReminderRequest
                 */
                GetSurveyReminderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyReminderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyReminderRequest();
                    if (object.surveyReminderId != null)
                        message.surveyReminderId = String(object.surveyReminderId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyReminderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyReminderRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyReminderRequest} message GetSurveyReminderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyReminderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReminderId = "";
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        object.surveyReminderId = message.surveyReminderId;
                    return object;
                };

                /**
                 * Converts this GetSurveyReminderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyReminderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyReminderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyReminderRequest;
            })();

            survey.SharetoSurveyReminderRequest = (function() {

                /**
                 * Properties of a SharetoSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReminderRequest
                 * @property {mtechnavi.api.survey.ISurveyReminder|null} [surveyReminder] SharetoSurveyReminderRequest surveyReminder
                 */

                /**
                 * Constructs a new SharetoSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReminderRequest.
                 * @implements ISharetoSurveyReminderRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReminderRequest=} [properties] Properties to set
                 */
                function SharetoSurveyReminderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoSurveyReminderRequest surveyReminder.
                 * @member {mtechnavi.api.survey.ISurveyReminder|null|undefined} surveyReminder
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderRequest
                 * @instance
                 */
                SharetoSurveyReminderRequest.prototype.surveyReminder = null;

                /**
                 * Verifies a SharetoSurveyReminderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReminderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReminder != null && message.hasOwnProperty("surveyReminder")) {
                        let error = $root.mtechnavi.api.survey.SurveyReminder.verify(message.surveyReminder);
                        if (error)
                            return "surveyReminder." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReminderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReminderRequest} SharetoSurveyReminderRequest
                 */
                SharetoSurveyReminderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReminderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoSurveyReminderRequest();
                    if (object.surveyReminder != null) {
                        if (typeof object.surveyReminder !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoSurveyReminderRequest.surveyReminder: object expected");
                        message.surveyReminder = $root.mtechnavi.api.survey.SurveyReminder.fromObject(object.surveyReminder);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoSurveyReminderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReminderRequest} message SharetoSurveyReminderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReminderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReminder = null;
                    if (message.surveyReminder != null && message.hasOwnProperty("surveyReminder"))
                        object.surveyReminder = $root.mtechnavi.api.survey.SurveyReminder.toObject(message.surveyReminder, options);
                    return object;
                };

                /**
                 * Converts this SharetoSurveyReminderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReminderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReminderRequest;
            })();

            survey.SharetoSurveyReminderResponse = (function() {

                /**
                 * Properties of a SharetoSurveyReminderResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoSurveyReminderResponse
                 */

                /**
                 * Constructs a new SharetoSurveyReminderResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoSurveyReminderResponse.
                 * @implements ISharetoSurveyReminderResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoSurveyReminderResponse=} [properties] Properties to set
                 */
                function SharetoSurveyReminderResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoSurveyReminderResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoSurveyReminderResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoSurveyReminderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoSurveyReminderResponse} SharetoSurveyReminderResponse
                 */
                SharetoSurveyReminderResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoSurveyReminderResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoSurveyReminderResponse();
                };

                /**
                 * Creates a plain object from a SharetoSurveyReminderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoSurveyReminderResponse} message SharetoSurveyReminderResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoSurveyReminderResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoSurveyReminderResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoSurveyReminderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoSurveyReminderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoSurveyReminderResponse;
            })();

            survey.ListHistorysRequest = (function() {

                /**
                 * Properties of a ListHistorysRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListHistorysRequest
                 * @property {string|null} [pageToken] ListHistorysRequest pageToken
                 * @property {string|null} [typeName] ListHistorysRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListHistorysRequest recordIds
                 */

                /**
                 * Constructs a new ListHistorysRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListHistorysRequest.
                 * @implements IListHistorysRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListHistorysRequest=} [properties] Properties to set
                 */
                function ListHistorysRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.pageToken = "";

                /**
                 * ListHistorysRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.typeName = "";

                /**
                 * ListHistorysRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListHistorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListHistorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListHistorysRequest} ListHistorysRequest
                 */
                ListHistorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListHistorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListHistorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.survey.ListHistorysRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListHistorysRequest} message ListHistorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListHistorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListHistorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysRequest;
            })();

            survey.ListHistorysResponse = (function() {

                /**
                 * Properties of a ListHistorysResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListHistorysResponse
                 * @property {Long|null} [total] ListHistorysResponse total
                 * @property {Array.<mtechnavi.api.survey.IHistory>|null} [items] ListHistorysResponse items
                 * @property {string|null} [pageToken] ListHistorysResponse pageToken
                 */

                /**
                 * Constructs a new ListHistorysResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListHistorysResponse.
                 * @implements IListHistorysResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListHistorysResponse=} [properties] Properties to set
                 */
                function ListHistorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListHistorysResponse items.
                 * @member {Array.<mtechnavi.api.survey.IHistory>} items
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListHistorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListHistorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.History.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListHistorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListHistorysResponse} ListHistorysResponse
                 */
                ListHistorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListHistorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListHistorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListHistorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListHistorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.History.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListHistorysResponse} message ListHistorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.History.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListHistorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListHistorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysResponse;
            })();

            survey.SharetoDiscardRequest = (function() {

                /**
                 * Properties of a SharetoDiscardRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoDiscardRequest
                 * @property {mtechnavi.api.survey.IDiscard|null} [discard] SharetoDiscardRequest discard
                 */

                /**
                 * Constructs a new SharetoDiscardRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoDiscardRequest.
                 * @implements ISharetoDiscardRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoDiscardRequest=} [properties] Properties to set
                 */
                function SharetoDiscardRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoDiscardRequest discard.
                 * @member {mtechnavi.api.survey.IDiscard|null|undefined} discard
                 * @memberof mtechnavi.api.survey.SharetoDiscardRequest
                 * @instance
                 */
                SharetoDiscardRequest.prototype.discard = null;

                /**
                 * Verifies a SharetoDiscardRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoDiscardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoDiscardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discard != null && message.hasOwnProperty("discard")) {
                        let error = $root.mtechnavi.api.survey.Discard.verify(message.discard);
                        if (error)
                            return "discard." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoDiscardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoDiscardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoDiscardRequest} SharetoDiscardRequest
                 */
                SharetoDiscardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoDiscardRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoDiscardRequest();
                    if (object.discard != null) {
                        if (typeof object.discard !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoDiscardRequest.discard: object expected");
                        message.discard = $root.mtechnavi.api.survey.Discard.fromObject(object.discard);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoDiscardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoDiscardRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoDiscardRequest} message SharetoDiscardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoDiscardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.discard = null;
                    if (message.discard != null && message.hasOwnProperty("discard"))
                        object.discard = $root.mtechnavi.api.survey.Discard.toObject(message.discard, options);
                    return object;
                };

                /**
                 * Converts this SharetoDiscardRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoDiscardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoDiscardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoDiscardRequest;
            })();

            survey.SharetoDiscardResponse = (function() {

                /**
                 * Properties of a SharetoDiscardResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoDiscardResponse
                 */

                /**
                 * Constructs a new SharetoDiscardResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoDiscardResponse.
                 * @implements ISharetoDiscardResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoDiscardResponse=} [properties] Properties to set
                 */
                function SharetoDiscardResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoDiscardResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoDiscardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoDiscardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoDiscardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoDiscardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoDiscardResponse} SharetoDiscardResponse
                 */
                SharetoDiscardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoDiscardResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoDiscardResponse();
                };

                /**
                 * Creates a plain object from a SharetoDiscardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoDiscardResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoDiscardResponse} message SharetoDiscardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoDiscardResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoDiscardResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoDiscardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoDiscardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoDiscardResponse;
            })();

            survey.SharetoCompleteRequest = (function() {

                /**
                 * Properties of a SharetoCompleteRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoCompleteRequest
                 * @property {mtechnavi.api.survey.IComplete|null} [complete] SharetoCompleteRequest complete
                 */

                /**
                 * Constructs a new SharetoCompleteRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoCompleteRequest.
                 * @implements ISharetoCompleteRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoCompleteRequest=} [properties] Properties to set
                 */
                function SharetoCompleteRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoCompleteRequest complete.
                 * @member {mtechnavi.api.survey.IComplete|null|undefined} complete
                 * @memberof mtechnavi.api.survey.SharetoCompleteRequest
                 * @instance
                 */
                SharetoCompleteRequest.prototype.complete = null;

                /**
                 * Verifies a SharetoCompleteRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoCompleteRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoCompleteRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.complete != null && message.hasOwnProperty("complete")) {
                        let error = $root.mtechnavi.api.survey.Complete.verify(message.complete);
                        if (error)
                            return "complete." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoCompleteRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoCompleteRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoCompleteRequest} SharetoCompleteRequest
                 */
                SharetoCompleteRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoCompleteRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoCompleteRequest();
                    if (object.complete != null) {
                        if (typeof object.complete !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoCompleteRequest.complete: object expected");
                        message.complete = $root.mtechnavi.api.survey.Complete.fromObject(object.complete);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoCompleteRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoCompleteRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoCompleteRequest} message SharetoCompleteRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoCompleteRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.complete = null;
                    if (message.complete != null && message.hasOwnProperty("complete"))
                        object.complete = $root.mtechnavi.api.survey.Complete.toObject(message.complete, options);
                    return object;
                };

                /**
                 * Converts this SharetoCompleteRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoCompleteRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoCompleteRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoCompleteRequest;
            })();

            survey.SharetoCompleteResponse = (function() {

                /**
                 * Properties of a SharetoCompleteResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoCompleteResponse
                 */

                /**
                 * Constructs a new SharetoCompleteResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoCompleteResponse.
                 * @implements ISharetoCompleteResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoCompleteResponse=} [properties] Properties to set
                 */
                function SharetoCompleteResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoCompleteResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoCompleteResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoCompleteResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoCompleteResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoCompleteResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoCompleteResponse} SharetoCompleteResponse
                 */
                SharetoCompleteResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoCompleteResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoCompleteResponse();
                };

                /**
                 * Creates a plain object from a SharetoCompleteResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoCompleteResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoCompleteResponse} message SharetoCompleteResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoCompleteResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoCompleteResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoCompleteResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoCompleteResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoCompleteResponse;
            })();

            survey.SharetoOpenedRequest = (function() {

                /**
                 * Properties of a SharetoOpenedRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoOpenedRequest
                 * @property {mtechnavi.api.survey.IOpened|null} [opened] SharetoOpenedRequest opened
                 */

                /**
                 * Constructs a new SharetoOpenedRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoOpenedRequest.
                 * @implements ISharetoOpenedRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoOpenedRequest=} [properties] Properties to set
                 */
                function SharetoOpenedRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharetoOpenedRequest opened.
                 * @member {mtechnavi.api.survey.IOpened|null|undefined} opened
                 * @memberof mtechnavi.api.survey.SharetoOpenedRequest
                 * @instance
                 */
                SharetoOpenedRequest.prototype.opened = null;

                /**
                 * Verifies a SharetoOpenedRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoOpenedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoOpenedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.opened != null && message.hasOwnProperty("opened")) {
                        let error = $root.mtechnavi.api.survey.Opened.verify(message.opened);
                        if (error)
                            return "opened." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SharetoOpenedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoOpenedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoOpenedRequest} SharetoOpenedRequest
                 */
                SharetoOpenedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoOpenedRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharetoOpenedRequest();
                    if (object.opened != null) {
                        if (typeof object.opened !== "object")
                            throw TypeError(".mtechnavi.api.survey.SharetoOpenedRequest.opened: object expected");
                        message.opened = $root.mtechnavi.api.survey.Opened.fromObject(object.opened);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharetoOpenedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoOpenedRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharetoOpenedRequest} message SharetoOpenedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoOpenedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.opened = null;
                    if (message.opened != null && message.hasOwnProperty("opened"))
                        object.opened = $root.mtechnavi.api.survey.Opened.toObject(message.opened, options);
                    return object;
                };

                /**
                 * Converts this SharetoOpenedRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoOpenedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoOpenedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoOpenedRequest;
            })();

            survey.SharetoOpenedResponse = (function() {

                /**
                 * Properties of a SharetoOpenedResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharetoOpenedResponse
                 */

                /**
                 * Constructs a new SharetoOpenedResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharetoOpenedResponse.
                 * @implements ISharetoOpenedResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharetoOpenedResponse=} [properties] Properties to set
                 */
                function SharetoOpenedResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SharetoOpenedResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharetoOpenedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharetoOpenedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SharetoOpenedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharetoOpenedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharetoOpenedResponse} SharetoOpenedResponse
                 */
                SharetoOpenedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharetoOpenedResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.SharetoOpenedResponse();
                };

                /**
                 * Creates a plain object from a SharetoOpenedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharetoOpenedResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharetoOpenedResponse} message SharetoOpenedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharetoOpenedResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SharetoOpenedResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharetoOpenedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharetoOpenedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharetoOpenedResponse;
            })();

            survey.ExportSurveyRequestsRequest = (function() {

                /**
                 * Properties of an ExportSurveyRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyRequestsRequest
                 * @property {Array.<string>|null} [surveyBaseRequestIds] ExportSurveyRequestsRequest surveyBaseRequestIds
                 * @property {Array.<string>|null} [surveyRequestIds] ExportSurveyRequestsRequest surveyRequestIds
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportSurveyRequestsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportSurveyRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyRequestsRequest.
                 * @implements IExportSurveyRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyRequestsRequest=} [properties] Properties to set
                 */
                function ExportSurveyRequestsRequest(properties) {
                    this.surveyBaseRequestIds = [];
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyRequestsRequest surveyBaseRequestIds.
                 * @member {Array.<string>} surveyBaseRequestIds
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @instance
                 */
                ExportSurveyRequestsRequest.prototype.surveyBaseRequestIds = $util.emptyArray;

                /**
                 * ExportSurveyRequestsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @instance
                 */
                ExportSurveyRequestsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * ExportSurveyRequestsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @instance
                 */
                ExportSurveyRequestsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportSurveyRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestIds != null && message.hasOwnProperty("surveyBaseRequestIds")) {
                        if (!Array.isArray(message.surveyBaseRequestIds))
                            return "surveyBaseRequestIds: array expected";
                        for (let i = 0; i < message.surveyBaseRequestIds.length; ++i)
                            if (!$util.isString(message.surveyBaseRequestIds[i]))
                                return "surveyBaseRequestIds: string[] expected";
                    }
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportSurveyRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyRequestsRequest} ExportSurveyRequestsRequest
                 */
                ExportSurveyRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyRequestsRequest();
                    if (object.surveyBaseRequestIds) {
                        if (!Array.isArray(object.surveyBaseRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ExportSurveyRequestsRequest.surveyBaseRequestIds: array expected");
                        message.surveyBaseRequestIds = [];
                        for (let i = 0; i < object.surveyBaseRequestIds.length; ++i)
                            message.surveyBaseRequestIds[i] = String(object.surveyBaseRequestIds[i]);
                    }
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ExportSurveyRequestsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.survey.ExportSurveyRequestsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyRequestsRequest} message ExportSurveyRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.surveyBaseRequestIds = [];
                        object.surveyRequestIds = [];
                    }
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.surveyBaseRequestIds && message.surveyBaseRequestIds.length) {
                        object.surveyBaseRequestIds = [];
                        for (let j = 0; j < message.surveyBaseRequestIds.length; ++j)
                            object.surveyBaseRequestIds[j] = message.surveyBaseRequestIds[j];
                    }
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ExportSurveyRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSurveyRequestsRequest;
            })();

            survey.ExportSurveyRequestsResponse = (function() {

                /**
                 * Properties of an ExportSurveyRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyRequestsResponse
                 * @property {string|null} [assetId] ExportSurveyRequestsResponse assetId
                 */

                /**
                 * Constructs a new ExportSurveyRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyRequestsResponse.
                 * @implements IExportSurveyRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyRequestsResponse=} [properties] Properties to set
                 */
                function ExportSurveyRequestsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyRequestsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsResponse
                 * @instance
                 */
                ExportSurveyRequestsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportSurveyRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSurveyRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyRequestsResponse} ExportSurveyRequestsResponse
                 */
                ExportSurveyRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyRequestsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyRequestsResponse} message ExportSurveyRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportSurveyRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSurveyRequestsResponse;
            })();

            survey.ListSurveyRequestFormatsRequest = (function() {

                /**
                 * Properties of a ListSurveyRequestFormatsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestFormatsRequest
                 * @property {string|null} [pageToken] ListSurveyRequestFormatsRequest pageToken
                 */

                /**
                 * Constructs a new ListSurveyRequestFormatsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestFormatsRequest.
                 * @implements IListSurveyRequestFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestFormatsRequest=} [properties] Properties to set
                 */
                function ListSurveyRequestFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestFormatsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsRequest
                 * @instance
                 */
                ListSurveyRequestFormatsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRequestFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestFormatsRequest} ListSurveyRequestFormatsRequest
                 */
                ListSurveyRequestFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestFormatsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestFormatsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestFormatsRequest} message ListSurveyRequestFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestFormatsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestFormatsRequest;
            })();

            survey.ListSurveyRequestFormatsResponse = (function() {

                /**
                 * Properties of a ListSurveyRequestFormatsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestFormatsResponse
                 * @property {Long|null} [total] ListSurveyRequestFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListSurveyRequestFormatsResponse items
                 * @property {string|null} [pageToken] ListSurveyRequestFormatsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyRequestFormatsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestFormatsResponse.
                 * @implements IListSurveyRequestFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestFormatsResponse=} [properties] Properties to set
                 */
                function ListSurveyRequestFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @instance
                 */
                ListSurveyRequestFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyRequestFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @instance
                 */
                ListSurveyRequestFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyRequestFormatsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @instance
                 */
                ListSurveyRequestFormatsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRequestFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestFormatsResponse} ListSurveyRequestFormatsResponse
                 */
                ListSurveyRequestFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestFormatsResponse} message ListSurveyRequestFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestFormatsResponse;
            })();

            survey.SharedGetSurveyRequestRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] SharedGetSurveyRequestRequest surveyRequestId
                 */

                /**
                 * Constructs a new SharedGetSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyRequestRequest.
                 * @implements ISharedGetSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyRequestRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyRequestRequest
                 * @instance
                 */
                SharedGetSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies a SharedGetSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyRequestRequest} SharedGetSurveyRequestRequest
                 */
                SharedGetSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyRequestRequest} message SharedGetSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyRequestRequest;
            })();

            survey.SharedListSurveyReplyDueDateChangeResultsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyReplyDueDateChangeResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyReplyDueDateChangeResultsRequest
                 * @property {string|null} [pageToken] SharedListSurveyReplyDueDateChangeResultsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] SharedListSurveyReplyDueDateChangeResultsRequest surveyRequestIds
                 */

                /**
                 * Constructs a new SharedListSurveyReplyDueDateChangeResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyReplyDueDateChangeResultsRequest.
                 * @implements ISharedListSurveyReplyDueDateChangeResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeResultsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyReplyDueDateChangeResultsRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyReplyDueDateChangeResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyReplyDueDateChangeResultsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyReplyDueDateChangeResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyReplyDueDateChangeResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest} SharedListSurveyReplyDueDateChangeResultsRequest
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyReplyDueDateChangeResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest} message SharedListSurveyReplyDueDateChangeResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyReplyDueDateChangeResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyReplyDueDateChangeResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyReplyDueDateChangeResultsRequest;
            })();

            survey.SharedListSurveyReplyDueDateChangeResultsResponse = (function() {

                /**
                 * Properties of a SharedListSurveyReplyDueDateChangeResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyReplyDueDateChangeResultsResponse
                 * @property {Long|null} [total] SharedListSurveyReplyDueDateChangeResultsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult>|null} [items] SharedListSurveyReplyDueDateChangeResultsResponse items
                 * @property {string|null} [pageToken] SharedListSurveyReplyDueDateChangeResultsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyReplyDueDateChangeResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyReplyDueDateChangeResultsResponse.
                 * @implements ISharedListSurveyReplyDueDateChangeResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeResultsResponse=} [properties] Properties to set
                 */
                function SharedListSurveyReplyDueDateChangeResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyReplyDueDateChangeResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyReplyDueDateChangeResultsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeResult>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyReplyDueDateChangeResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyReplyDueDateChangeResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyReplyDueDateChangeResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse} SharedListSurveyReplyDueDateChangeResultsResponse
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyReplyDueDateChangeResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse} message SharedListSurveyReplyDueDateChangeResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyReplyDueDateChangeResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyReplyDueDateChangeResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyReplyDueDateChangeResultsResponse;
            })();

            survey.SharedGetSurveyReplyDueDateChangeResultRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyReplyDueDateChangeResultRequest
                 * @property {string|null} [surveyReplyDueDateChangeResultId] SharedGetSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResultId
                 */

                /**
                 * Constructs a new SharedGetSurveyReplyDueDateChangeResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyReplyDueDateChangeResultRequest.
                 * @implements ISharedGetSurveyReplyDueDateChangeResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeResultRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyReplyDueDateChangeResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyReplyDueDateChangeResultRequest surveyReplyDueDateChangeResultId.
                 * @member {string} surveyReplyDueDateChangeResultId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest
                 * @instance
                 */
                SharedGetSurveyReplyDueDateChangeResultRequest.prototype.surveyReplyDueDateChangeResultId = "";

                /**
                 * Verifies a SharedGetSurveyReplyDueDateChangeResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyReplyDueDateChangeResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeResultId))
                            return "surveyReplyDueDateChangeResultId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyReplyDueDateChangeResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest} SharedGetSurveyReplyDueDateChangeResultRequest
                 */
                SharedGetSurveyReplyDueDateChangeResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest();
                    if (object.surveyReplyDueDateChangeResultId != null)
                        message.surveyReplyDueDateChangeResultId = String(object.surveyReplyDueDateChangeResultId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyReplyDueDateChangeResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest} message SharedGetSurveyReplyDueDateChangeResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyReplyDueDateChangeResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeResultId = "";
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        object.surveyReplyDueDateChangeResultId = message.surveyReplyDueDateChangeResultId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyReplyDueDateChangeResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyReplyDueDateChangeResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyReplyDueDateChangeResultRequest;
            })();

            survey.SharedListSurveyRemindersRequest = (function() {

                /**
                 * Properties of a SharedListSurveyRemindersRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyRemindersRequest
                 * @property {string|null} [pageToken] SharedListSurveyRemindersRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] SharedListSurveyRemindersRequest surveyRequestIds
                 */

                /**
                 * Constructs a new SharedListSurveyRemindersRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyRemindersRequest.
                 * @implements ISharedListSurveyRemindersRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyRemindersRequest=} [properties] Properties to set
                 */
                function SharedListSurveyRemindersRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyRemindersRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @instance
                 */
                SharedListSurveyRemindersRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyRemindersRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @instance
                 */
                SharedListSurveyRemindersRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyRemindersRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyRemindersRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyRemindersRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyRemindersRequest} SharedListSurveyRemindersRequest
                 */
                SharedListSurveyRemindersRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyRemindersRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyRemindersRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyRemindersRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyRemindersRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyRemindersRequest} message SharedListSurveyRemindersRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyRemindersRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyRemindersRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyRemindersRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyRemindersRequest;
            })();

            survey.SharedListSurveyRemindersResponse = (function() {

                /**
                 * Properties of a SharedListSurveyRemindersResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyRemindersResponse
                 * @property {Long|null} [total] SharedListSurveyRemindersResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReminder>|null} [items] SharedListSurveyRemindersResponse items
                 * @property {string|null} [pageToken] SharedListSurveyRemindersResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyRemindersResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyRemindersResponse.
                 * @implements ISharedListSurveyRemindersResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyRemindersResponse=} [properties] Properties to set
                 */
                function SharedListSurveyRemindersResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyRemindersResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @instance
                 */
                SharedListSurveyRemindersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyRemindersResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReminder>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @instance
                 */
                SharedListSurveyRemindersResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyRemindersResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @instance
                 */
                SharedListSurveyRemindersResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyRemindersResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyRemindersResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReminder.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyRemindersResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyRemindersResponse} SharedListSurveyRemindersResponse
                 */
                SharedListSurveyRemindersResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyRemindersResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyRemindersResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyRemindersResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyRemindersResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReminder.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyRemindersResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyRemindersResponse} message SharedListSurveyRemindersResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyRemindersResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReminder.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyRemindersResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyRemindersResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyRemindersResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyRemindersResponse;
            })();

            survey.SharedGetSurveyReminderRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyReminderRequest
                 * @property {string|null} [surveyReminderId] SharedGetSurveyReminderRequest surveyReminderId
                 */

                /**
                 * Constructs a new SharedGetSurveyReminderRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyReminderRequest.
                 * @implements ISharedGetSurveyReminderRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReminderRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyReminderRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyReminderRequest surveyReminderId.
                 * @member {string} surveyReminderId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReminderRequest
                 * @instance
                 */
                SharedGetSurveyReminderRequest.prototype.surveyReminderId = "";

                /**
                 * Verifies a SharedGetSurveyReminderRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyReminderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        if (!$util.isString(message.surveyReminderId))
                            return "surveyReminderId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyReminderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReminderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyReminderRequest} SharedGetSurveyReminderRequest
                 */
                SharedGetSurveyReminderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyReminderRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyReminderRequest();
                    if (object.surveyReminderId != null)
                        message.surveyReminderId = String(object.surveyReminderId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyReminderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReminderRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyReminderRequest} message SharedGetSurveyReminderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyReminderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReminderId = "";
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        object.surveyReminderId = message.surveyReminderId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyReminderRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReminderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyReminderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyReminderRequest;
            })();

            survey.SharedListSurveyReplyDueDateChangeRequestsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyReplyDueDateChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyReplyDueDateChangeRequestsRequest
                 * @property {string|null} [pageToken] SharedListSurveyReplyDueDateChangeRequestsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] SharedListSurveyReplyDueDateChangeRequestsRequest surveyRequestIds
                 */

                /**
                 * Constructs a new SharedListSurveyReplyDueDateChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyReplyDueDateChangeRequestsRequest.
                 * @implements ISharedListSurveyReplyDueDateChangeRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeRequestsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyReplyDueDateChangeRequestsRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyReplyDueDateChangeRequestsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyReplyDueDateChangeRequestsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyReplyDueDateChangeRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyReplyDueDateChangeRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest} SharedListSurveyReplyDueDateChangeRequestsRequest
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyReplyDueDateChangeRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest} message SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyReplyDueDateChangeRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyReplyDueDateChangeRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyReplyDueDateChangeRequestsRequest;
            })();

            survey.SharedListSurveyReplyDueDateChangeRequestsResponse = (function() {

                /**
                 * Properties of a SharedListSurveyReplyDueDateChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyReplyDueDateChangeRequestsResponse
                 * @property {Long|null} [total] SharedListSurveyReplyDueDateChangeRequestsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest>|null} [items] SharedListSurveyReplyDueDateChangeRequestsResponse items
                 * @property {string|null} [pageToken] SharedListSurveyReplyDueDateChangeRequestsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyReplyDueDateChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyReplyDueDateChangeRequestsResponse.
                 * @implements ISharedListSurveyReplyDueDateChangeRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyReplyDueDateChangeRequestsResponse=} [properties] Properties to set
                 */
                function SharedListSurveyReplyDueDateChangeRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyReplyDueDateChangeRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyReplyDueDateChangeRequestsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyReplyDueDateChangeRequestsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyReplyDueDateChangeRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyReplyDueDateChangeRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse} SharedListSurveyReplyDueDateChangeRequestsResponse
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyReplyDueDateChangeRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse} message SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyReplyDueDateChangeRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyReplyDueDateChangeRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyReplyDueDateChangeRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyReplyDueDateChangeRequestsResponse;
            })();

            survey.SharedGetSurveyReplyDueDateChangeRequestRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyReplyDueDateChangeRequestRequest
                 * @property {string|null} [surveyReplyDueDateChangeRequestId] SharedGetSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequestId
                 */

                /**
                 * Constructs a new SharedGetSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyReplyDueDateChangeRequestRequest.
                 * @implements ISharedGetSurveyReplyDueDateChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyReplyDueDateChangeRequestRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyReplyDueDateChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyReplyDueDateChangeRequestRequest surveyReplyDueDateChangeRequestId.
                 * @member {string} surveyReplyDueDateChangeRequestId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 */
                SharedGetSurveyReplyDueDateChangeRequestRequest.prototype.surveyReplyDueDateChangeRequestId = "";

                /**
                 * Verifies a SharedGetSurveyReplyDueDateChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyReplyDueDateChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeRequestId))
                            return "surveyReplyDueDateChangeRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyReplyDueDateChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest} SharedGetSurveyReplyDueDateChangeRequestRequest
                 */
                SharedGetSurveyReplyDueDateChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest();
                    if (object.surveyReplyDueDateChangeRequestId != null)
                        message.surveyReplyDueDateChangeRequestId = String(object.surveyReplyDueDateChangeRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyReplyDueDateChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest} message SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyReplyDueDateChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReplyDueDateChangeRequestId = "";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        object.surveyReplyDueDateChangeRequestId = message.surveyReplyDueDateChangeRequestId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyReplyDueDateChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyReplyDueDateChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyReplyDueDateChangeRequestRequest;
            })();

            survey.SharedListSurveyResultsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyResultsRequest
                 * @property {string|null} [pageToken] SharedListSurveyResultsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] SharedListSurveyResultsRequest surveyRequestIds
                 */

                /**
                 * Constructs a new SharedListSurveyResultsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyResultsRequest.
                 * @implements ISharedListSurveyResultsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyResultsRequest(properties) {
                    this.surveyRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyResultsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @instance
                 */
                SharedListSurveyResultsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyResultsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @instance
                 */
                SharedListSurveyResultsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyResultsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyResultsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyResultsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyResultsRequest} SharedListSurveyResultsRequest
                 */
                SharedListSurveyResultsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyResultsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyResultsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyResultsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyResultsRequest} message SharedListSurveyResultsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyResultsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyRequestIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyResultsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyResultsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyResultsRequest;
            })();

            survey.SharedListSurveyResultsResponse = (function() {

                /**
                 * Properties of a SharedListSurveyResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyResultsResponse
                 * @property {Long|null} [total] SharedListSurveyResultsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyResult>|null} [items] SharedListSurveyResultsResponse items
                 * @property {string|null} [pageToken] SharedListSurveyResultsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyResultsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyResultsResponse.
                 * @implements ISharedListSurveyResultsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultsResponse=} [properties] Properties to set
                 */
                function SharedListSurveyResultsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyResultsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @instance
                 */
                SharedListSurveyResultsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyResultsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyResult>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @instance
                 */
                SharedListSurveyResultsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyResultsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @instance
                 */
                SharedListSurveyResultsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyResultsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyResultsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyResult.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyResultsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyResultsResponse} SharedListSurveyResultsResponse
                 */
                SharedListSurveyResultsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyResultsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyResultsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyResult.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyResultsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyResultsResponse} message SharedListSurveyResultsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyResultsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyResult.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyResultsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyResultsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyResultsResponse;
            })();

            survey.SharedGetSurveyResultRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyResultRequest
                 * @property {string|null} [surveyResultId] SharedGetSurveyResultRequest surveyResultId
                 */

                /**
                 * Constructs a new SharedGetSurveyResultRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyResultRequest.
                 * @implements ISharedGetSurveyResultRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyResultRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyResultRequest surveyResultId.
                 * @member {string} surveyResultId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultRequest
                 * @instance
                 */
                SharedGetSurveyResultRequest.prototype.surveyResultId = "";

                /**
                 * Verifies a SharedGetSurveyResultRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyResultRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        if (!$util.isString(message.surveyResultId))
                            return "surveyResultId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyResultRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyResultRequest} SharedGetSurveyResultRequest
                 */
                SharedGetSurveyResultRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyResultRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyResultRequest();
                    if (object.surveyResultId != null)
                        message.surveyResultId = String(object.surveyResultId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyResultRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyResultRequest} message SharedGetSurveyResultRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyResultRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultId = "";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        object.surveyResultId = message.surveyResultId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyResultRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyResultRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyResultRequest;
            })();

            survey.SharedListSurveyResultChangeRequestsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyResultChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyResultChangeRequestsRequest
                 * @property {string|null} [pageToken] SharedListSurveyResultChangeRequestsRequest pageToken
                 * @property {Array.<string>|null} [surveyRequestIds] SharedListSurveyResultChangeRequestsRequest surveyRequestIds
                 * @property {Array.<string>|null} [surveyResultIds] SharedListSurveyResultChangeRequestsRequest surveyResultIds
                 */

                /**
                 * Constructs a new SharedListSurveyResultChangeRequestsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyResultChangeRequestsRequest.
                 * @implements ISharedListSurveyResultChangeRequestsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultChangeRequestsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyResultChangeRequestsRequest(properties) {
                    this.surveyRequestIds = [];
                    this.surveyResultIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyResultChangeRequestsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                SharedListSurveyResultChangeRequestsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyResultChangeRequestsRequest surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                SharedListSurveyResultChangeRequestsRequest.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * SharedListSurveyResultChangeRequestsRequest surveyResultIds.
                 * @member {Array.<string>} surveyResultIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @instance
                 */
                SharedListSurveyResultChangeRequestsRequest.prototype.surveyResultIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyResultChangeRequestsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyResultChangeRequestsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    if (message.surveyResultIds != null && message.hasOwnProperty("surveyResultIds")) {
                        if (!Array.isArray(message.surveyResultIds))
                            return "surveyResultIds: array expected";
                        for (let i = 0; i < message.surveyResultIds.length; ++i)
                            if (!$util.isString(message.surveyResultIds[i]))
                                return "surveyResultIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyResultChangeRequestsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest} SharedListSurveyResultChangeRequestsRequest
                 */
                SharedListSurveyResultChangeRequestsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    if (object.surveyResultIds) {
                        if (!Array.isArray(object.surveyResultIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest.surveyResultIds: array expected");
                        message.surveyResultIds = [];
                        for (let i = 0; i < object.surveyResultIds.length; ++i)
                            message.surveyResultIds[i] = String(object.surveyResultIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyResultChangeRequestsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest} message SharedListSurveyResultChangeRequestsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyResultChangeRequestsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.surveyRequestIds = [];
                        object.surveyResultIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    if (message.surveyResultIds && message.surveyResultIds.length) {
                        object.surveyResultIds = [];
                        for (let j = 0; j < message.surveyResultIds.length; ++j)
                            object.surveyResultIds[j] = message.surveyResultIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyResultChangeRequestsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultChangeRequestsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyResultChangeRequestsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyResultChangeRequestsRequest;
            })();

            survey.SurveyResultChangeRequestsResponse = (function() {

                /**
                 * Properties of a SurveyResultChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyResultChangeRequestsResponse
                 * @property {Long|null} [total] SurveyResultChangeRequestsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyResultChangeRequest>|null} [items] SurveyResultChangeRequestsResponse items
                 * @property {string|null} [pageToken] SurveyResultChangeRequestsResponse pageToken
                 */

                /**
                 * Constructs a new SurveyResultChangeRequestsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyResultChangeRequestsResponse.
                 * @implements ISurveyResultChangeRequestsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyResultChangeRequestsResponse=} [properties] Properties to set
                 */
                function SurveyResultChangeRequestsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyResultChangeRequestsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @instance
                 */
                SurveyResultChangeRequestsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultChangeRequestsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyResultChangeRequest>} items
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @instance
                 */
                SurveyResultChangeRequestsResponse.prototype.items = $util.emptyArray;

                /**
                 * SurveyResultChangeRequestsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @instance
                 */
                SurveyResultChangeRequestsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SurveyResultChangeRequestsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyResultChangeRequestsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyResultChangeRequest.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SurveyResultChangeRequestsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyResultChangeRequestsResponse} SurveyResultChangeRequestsResponse
                 */
                SurveyResultChangeRequestsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyResultChangeRequestsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyResultChangeRequestsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SurveyResultChangeRequestsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyResultChangeRequestsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyResultChangeRequest.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyResultChangeRequestsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequestsResponse} message SurveyResultChangeRequestsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyResultChangeRequestsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyResultChangeRequest.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SurveyResultChangeRequestsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequestsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyResultChangeRequestsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyResultChangeRequestsResponse;
            })();

            survey.SharedGetSurveyResultChangeRequestRequest = (function() {

                /**
                 * Properties of a SharedGetSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedGetSurveyResultChangeRequestRequest
                 * @property {string|null} [surveyResultChangeRequestId] SharedGetSurveyResultChangeRequestRequest surveyResultChangeRequestId
                 */

                /**
                 * Constructs a new SharedGetSurveyResultChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedGetSurveyResultChangeRequestRequest.
                 * @implements ISharedGetSurveyResultChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedGetSurveyResultChangeRequestRequest=} [properties] Properties to set
                 */
                function SharedGetSurveyResultChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedGetSurveyResultChangeRequestRequest surveyResultChangeRequestId.
                 * @member {string} surveyResultChangeRequestId
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest
                 * @instance
                 */
                SharedGetSurveyResultChangeRequestRequest.prototype.surveyResultChangeRequestId = "";

                /**
                 * Verifies a SharedGetSurveyResultChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedGetSurveyResultChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        if (!$util.isString(message.surveyResultChangeRequestId))
                            return "surveyResultChangeRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a SharedGetSurveyResultChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest} SharedGetSurveyResultChangeRequestRequest
                 */
                SharedGetSurveyResultChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest();
                    if (object.surveyResultChangeRequestId != null)
                        message.surveyResultChangeRequestId = String(object.surveyResultChangeRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedGetSurveyResultChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest} message SharedGetSurveyResultChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedGetSurveyResultChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultChangeRequestId = "";
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        object.surveyResultChangeRequestId = message.surveyResultChangeRequestId;
                    return object;
                };

                /**
                 * Converts this SharedGetSurveyResultChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedGetSurveyResultChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedGetSurveyResultChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedGetSurveyResultChangeRequestRequest;
            })();

            survey.ExpandedListSurveyRequestContentsRequest = (function() {

                /**
                 * Properties of an ExpandedListSurveyRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExpandedListSurveyRequestContentsRequest
                 * @property {string|null} [pageToken] ExpandedListSurveyRequestContentsRequest pageToken
                 * @property {string|null} [surveyBaseRequestId] ExpandedListSurveyRequestContentsRequest surveyBaseRequestId
                 * @property {Array.<string>|null} [surveyBaseRequestIds] ExpandedListSurveyRequestContentsRequest surveyBaseRequestIds
                 * @property {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.IDataFilter|null} [dataFilter] ExpandedListSurveyRequestContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ExpandedListSurveyRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExpandedListSurveyRequestContentsRequest.
                 * @implements IExpandedListSurveyRequestContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExpandedListSurveyRequestContentsRequest=} [properties] Properties to set
                 */
                function ExpandedListSurveyRequestContentsRequest(properties) {
                    this.surveyBaseRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExpandedListSurveyRequestContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @instance
                 */
                ExpandedListSurveyRequestContentsRequest.prototype.pageToken = "";

                /**
                 * ExpandedListSurveyRequestContentsRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @instance
                 */
                ExpandedListSurveyRequestContentsRequest.prototype.surveyBaseRequestId = "";

                /**
                 * ExpandedListSurveyRequestContentsRequest surveyBaseRequestIds.
                 * @member {Array.<string>} surveyBaseRequestIds
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @instance
                 */
                ExpandedListSurveyRequestContentsRequest.prototype.surveyBaseRequestIds = $util.emptyArray;

                /**
                 * ExpandedListSurveyRequestContentsRequest dataFilter.
                 * @member {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @instance
                 */
                ExpandedListSurveyRequestContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies an ExpandedListSurveyRequestContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedListSurveyRequestContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.surveyBaseRequestIds != null && message.hasOwnProperty("surveyBaseRequestIds")) {
                        if (!Array.isArray(message.surveyBaseRequestIds))
                            return "surveyBaseRequestIds: array expected";
                        for (let i = 0; i < message.surveyBaseRequestIds.length; ++i)
                            if (!$util.isString(message.surveyBaseRequestIds[i]))
                                return "surveyBaseRequestIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExpandedListSurveyRequestContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest} ExpandedListSurveyRequestContentsRequest
                 */
                ExpandedListSurveyRequestContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.surveyBaseRequestIds) {
                        if (!Array.isArray(object.surveyBaseRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.surveyBaseRequestIds: array expected");
                        message.surveyBaseRequestIds = [];
                        for (let i = 0; i < object.surveyBaseRequestIds.length; ++i)
                            message.surveyBaseRequestIds[i] = String(object.surveyBaseRequestIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExpandedListSurveyRequestContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest} message ExpandedListSurveyRequestContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedListSurveyRequestContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyBaseRequestIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.surveyBaseRequestId = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    if (message.surveyBaseRequestIds && message.surveyBaseRequestIds.length) {
                        object.surveyBaseRequestIds = [];
                        for (let j = 0; j < message.surveyBaseRequestIds.length; ++j)
                            object.surveyBaseRequestIds[j] = message.surveyBaseRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ExpandedListSurveyRequestContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedListSurveyRequestContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ExpandedListSurveyRequestContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [statusCodes] DataFilter statusCodes
                     * @property {Array.<string>|null} [surveyRequestCatalogIds] DataFilter surveyRequestCatalogIds
                     * @property {Long|null} [sendedAtFrom] DataFilter sendedAtFrom
                     * @property {Long|null} [sendedAtTo] DataFilter sendedAtTo
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statusCodes = [];
                        this.surveyRequestCatalogIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statusCodes.
                     * @member {Array.<string>} statusCodes
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statusCodes = $util.emptyArray;

                    /**
                     * DataFilter surveyRequestCatalogIds.
                     * @member {Array.<string>} surveyRequestCatalogIds
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.surveyRequestCatalogIds = $util.emptyArray;

                    /**
                     * DataFilter sendedAtFrom.
                     * @member {Long} sendedAtFrom
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter sendedAtTo.
                     * @member {Long} sendedAtTo
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statusCodes != null && message.hasOwnProperty("statusCodes")) {
                            if (!Array.isArray(message.statusCodes))
                                return "statusCodes: array expected";
                            for (let i = 0; i < message.statusCodes.length; ++i)
                                if (!$util.isString(message.statusCodes[i]))
                                    return "statusCodes: string[] expected";
                        }
                        if (message.surveyRequestCatalogIds != null && message.hasOwnProperty("surveyRequestCatalogIds")) {
                            if (!Array.isArray(message.surveyRequestCatalogIds))
                                return "surveyRequestCatalogIds: array expected";
                            for (let i = 0; i < message.surveyRequestCatalogIds.length; ++i)
                                if (!$util.isString(message.surveyRequestCatalogIds[i]))
                                    return "surveyRequestCatalogIds: string[] expected";
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (!$util.isInteger(message.sendedAtFrom) && !(message.sendedAtFrom && $util.isInteger(message.sendedAtFrom.low) && $util.isInteger(message.sendedAtFrom.high)))
                                return "sendedAtFrom: integer|Long expected";
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (!$util.isInteger(message.sendedAtTo) && !(message.sendedAtTo && $util.isInteger(message.sendedAtTo.low) && $util.isInteger(message.sendedAtTo.high)))
                                return "sendedAtTo: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter();
                        if (object.statusCodes) {
                            if (!Array.isArray(object.statusCodes))
                                throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter.statusCodes: array expected");
                            message.statusCodes = [];
                            for (let i = 0; i < object.statusCodes.length; ++i)
                                message.statusCodes[i] = String(object.statusCodes[i]);
                        }
                        if (object.surveyRequestCatalogIds) {
                            if (!Array.isArray(object.surveyRequestCatalogIds))
                                throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter.surveyRequestCatalogIds: array expected");
                            message.surveyRequestCatalogIds = [];
                            for (let i = 0; i < object.surveyRequestCatalogIds.length; ++i)
                                message.surveyRequestCatalogIds[i] = String(object.surveyRequestCatalogIds[i]);
                        }
                        if (object.sendedAtFrom != null)
                            if ($util.Long)
                                (message.sendedAtFrom = $util.Long.fromValue(object.sendedAtFrom)).unsigned = false;
                            else if (typeof object.sendedAtFrom === "string")
                                message.sendedAtFrom = parseInt(object.sendedAtFrom, 10);
                            else if (typeof object.sendedAtFrom === "number")
                                message.sendedAtFrom = object.sendedAtFrom;
                            else if (typeof object.sendedAtFrom === "object")
                                message.sendedAtFrom = new $util.LongBits(object.sendedAtFrom.low >>> 0, object.sendedAtFrom.high >>> 0).toNumber();
                        if (object.sendedAtTo != null)
                            if ($util.Long)
                                (message.sendedAtTo = $util.Long.fromValue(object.sendedAtTo)).unsigned = false;
                            else if (typeof object.sendedAtTo === "string")
                                message.sendedAtTo = parseInt(object.sendedAtTo, 10);
                            else if (typeof object.sendedAtTo === "number")
                                message.sendedAtTo = object.sendedAtTo;
                            else if (typeof object.sendedAtTo === "object")
                                message.sendedAtTo = new $util.LongBits(object.sendedAtTo.low >>> 0, object.sendedAtTo.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.surveyRequestCatalogIds = [];
                            object.statusCodes = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtFrom = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtTo = options.longs === String ? "0" : 0;
                        }
                        if (message.surveyRequestCatalogIds && message.surveyRequestCatalogIds.length) {
                            object.surveyRequestCatalogIds = [];
                            for (let j = 0; j < message.surveyRequestCatalogIds.length; ++j)
                                object.surveyRequestCatalogIds[j] = message.surveyRequestCatalogIds[j];
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (typeof message.sendedAtFrom === "number")
                                object.sendedAtFrom = options.longs === String ? String(message.sendedAtFrom) : message.sendedAtFrom;
                            else
                                object.sendedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtFrom) : options.longs === Number ? new $util.LongBits(message.sendedAtFrom.low >>> 0, message.sendedAtFrom.high >>> 0).toNumber() : message.sendedAtFrom;
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (typeof message.sendedAtTo === "number")
                                object.sendedAtTo = options.longs === String ? String(message.sendedAtTo) : message.sendedAtTo;
                            else
                                object.sendedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtTo) : options.longs === Number ? new $util.LongBits(message.sendedAtTo.low >>> 0, message.sendedAtTo.high >>> 0).toNumber() : message.sendedAtTo;
                        if (message.statusCodes && message.statusCodes.length) {
                            object.statusCodes = [];
                            for (let j = 0; j < message.statusCodes.length; ++j)
                                object.statusCodes[j] = message.statusCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ExpandedListSurveyRequestContentsRequest;
            })();

            survey.ExpandedListSurveyRequestContentsResponse = (function() {

                /**
                 * Properties of an ExpandedListSurveyRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExpandedListSurveyRequestContentsResponse
                 * @property {Long|null} [total] ExpandedListSurveyRequestContentsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestContent>|null} [items] ExpandedListSurveyRequestContentsResponse items
                 * @property {string|null} [pageToken] ExpandedListSurveyRequestContentsResponse pageToken
                 */

                /**
                 * Constructs a new ExpandedListSurveyRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExpandedListSurveyRequestContentsResponse.
                 * @implements IExpandedListSurveyRequestContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExpandedListSurveyRequestContentsResponse=} [properties] Properties to set
                 */
                function ExpandedListSurveyRequestContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExpandedListSurveyRequestContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @instance
                 */
                ExpandedListSurveyRequestContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ExpandedListSurveyRequestContentsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestContent>} items
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @instance
                 */
                ExpandedListSurveyRequestContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ExpandedListSurveyRequestContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @instance
                 */
                ExpandedListSurveyRequestContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies an ExpandedListSurveyRequestContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedListSurveyRequestContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an ExpandedListSurveyRequestContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse} ExpandedListSurveyRequestContentsResponse
                 */
                ExpandedListSurveyRequestContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an ExpandedListSurveyRequestContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse} message ExpandedListSurveyRequestContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedListSurveyRequestContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ExpandedListSurveyRequestContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExpandedListSurveyRequestContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedListSurveyRequestContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExpandedListSurveyRequestContentsResponse;
            })();

            survey.ExpandedGetSurveyRequestContentRequest = (function() {

                /**
                 * Properties of an ExpandedGetSurveyRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExpandedGetSurveyRequestContentRequest
                 * @property {string|null} [surveyBaseRequestId] ExpandedGetSurveyRequestContentRequest surveyBaseRequestId
                 * @property {string|null} [businessUnitManagementId] ExpandedGetSurveyRequestContentRequest businessUnitManagementId
                 */

                /**
                 * Constructs a new ExpandedGetSurveyRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExpandedGetSurveyRequestContentRequest.
                 * @implements IExpandedGetSurveyRequestContentRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExpandedGetSurveyRequestContentRequest=} [properties] Properties to set
                 */
                function ExpandedGetSurveyRequestContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExpandedGetSurveyRequestContentRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @instance
                 */
                ExpandedGetSurveyRequestContentRequest.prototype.surveyBaseRequestId = "";

                /**
                 * ExpandedGetSurveyRequestContentRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @instance
                 */
                ExpandedGetSurveyRequestContentRequest.prototype.businessUnitManagementId = "";

                /**
                 * Verifies an ExpandedGetSurveyRequestContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExpandedGetSurveyRequestContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    return null;
                };

                /**
                 * Creates an ExpandedGetSurveyRequestContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest} ExpandedGetSurveyRequestContentRequest
                 */
                ExpandedGetSurveyRequestContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExpandedGetSurveyRequestContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest} message ExpandedGetSurveyRequestContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExpandedGetSurveyRequestContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyBaseRequestId = "";
                        object.businessUnitManagementId = "";
                    }
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    return object;
                };

                /**
                 * Converts this ExpandedGetSurveyRequestContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExpandedGetSurveyRequestContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExpandedGetSurveyRequestContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExpandedGetSurveyRequestContentRequest;
            })();

            survey.ListSurveyBaseRequestContentsRequest = (function() {

                /**
                 * Properties of a ListSurveyBaseRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyBaseRequestContentsRequest
                 * @property {string|null} [pageToken] ListSurveyBaseRequestContentsRequest pageToken
                 * @property {Array.<string>|null} [surveyBaseRequestIds] ListSurveyBaseRequestContentsRequest surveyBaseRequestIds
                 * @property {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.IDataFilter|null} [dataFilter] ListSurveyBaseRequestContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ListSurveyBaseRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyBaseRequestContentsRequest.
                 * @implements IListSurveyBaseRequestContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyBaseRequestContentsRequest=} [properties] Properties to set
                 */
                function ListSurveyBaseRequestContentsRequest(properties) {
                    this.surveyBaseRequestIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyBaseRequestContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @instance
                 */
                ListSurveyBaseRequestContentsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyBaseRequestContentsRequest surveyBaseRequestIds.
                 * @member {Array.<string>} surveyBaseRequestIds
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @instance
                 */
                ListSurveyBaseRequestContentsRequest.prototype.surveyBaseRequestIds = $util.emptyArray;

                /**
                 * ListSurveyBaseRequestContentsRequest dataFilter.
                 * @member {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @instance
                 */
                ListSurveyBaseRequestContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListSurveyBaseRequestContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyBaseRequestContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyBaseRequestIds != null && message.hasOwnProperty("surveyBaseRequestIds")) {
                        if (!Array.isArray(message.surveyBaseRequestIds))
                            return "surveyBaseRequestIds: array expected";
                        for (let i = 0; i < message.surveyBaseRequestIds.length; ++i)
                            if (!$util.isString(message.surveyBaseRequestIds[i]))
                                return "surveyBaseRequestIds: string[] expected";
                    }
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyBaseRequestContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest} ListSurveyBaseRequestContentsRequest
                 */
                ListSurveyBaseRequestContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyBaseRequestIds) {
                        if (!Array.isArray(object.surveyBaseRequestIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.surveyBaseRequestIds: array expected");
                        message.surveyBaseRequestIds = [];
                        for (let i = 0; i < object.surveyBaseRequestIds.length; ++i)
                            message.surveyBaseRequestIds[i] = String(object.surveyBaseRequestIds[i]);
                    }
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyBaseRequestContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest} message ListSurveyBaseRequestContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyBaseRequestContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyBaseRequestIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    if (message.surveyBaseRequestIds && message.surveyBaseRequestIds.length) {
                        object.surveyBaseRequestIds = [];
                        for (let j = 0; j < message.surveyBaseRequestIds.length; ++j)
                            object.surveyBaseRequestIds[j] = message.surveyBaseRequestIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyBaseRequestContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyBaseRequestContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListSurveyBaseRequestContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [statusCodes] DataFilter statusCodes
                     * @property {Array.<string>|null} [surveyRequestCatalogIds] DataFilter surveyRequestCatalogIds
                     * @property {Long|null} [sendedAtFrom] DataFilter sendedAtFrom
                     * @property {Long|null} [sendedAtTo] DataFilter sendedAtTo
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statusCodes = [];
                        this.surveyRequestCatalogIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statusCodes.
                     * @member {Array.<string>} statusCodes
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statusCodes = $util.emptyArray;

                    /**
                     * DataFilter surveyRequestCatalogIds.
                     * @member {Array.<string>} surveyRequestCatalogIds
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.surveyRequestCatalogIds = $util.emptyArray;

                    /**
                     * DataFilter sendedAtFrom.
                     * @member {Long} sendedAtFrom
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter sendedAtTo.
                     * @member {Long} sendedAtTo
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statusCodes != null && message.hasOwnProperty("statusCodes")) {
                            if (!Array.isArray(message.statusCodes))
                                return "statusCodes: array expected";
                            for (let i = 0; i < message.statusCodes.length; ++i)
                                if (!$util.isString(message.statusCodes[i]))
                                    return "statusCodes: string[] expected";
                        }
                        if (message.surveyRequestCatalogIds != null && message.hasOwnProperty("surveyRequestCatalogIds")) {
                            if (!Array.isArray(message.surveyRequestCatalogIds))
                                return "surveyRequestCatalogIds: array expected";
                            for (let i = 0; i < message.surveyRequestCatalogIds.length; ++i)
                                if (!$util.isString(message.surveyRequestCatalogIds[i]))
                                    return "surveyRequestCatalogIds: string[] expected";
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (!$util.isInteger(message.sendedAtFrom) && !(message.sendedAtFrom && $util.isInteger(message.sendedAtFrom.low) && $util.isInteger(message.sendedAtFrom.high)))
                                return "sendedAtFrom: integer|Long expected";
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (!$util.isInteger(message.sendedAtTo) && !(message.sendedAtTo && $util.isInteger(message.sendedAtTo.low) && $util.isInteger(message.sendedAtTo.high)))
                                return "sendedAtTo: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter();
                        if (object.statusCodes) {
                            if (!Array.isArray(object.statusCodes))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter.statusCodes: array expected");
                            message.statusCodes = [];
                            for (let i = 0; i < object.statusCodes.length; ++i)
                                message.statusCodes[i] = String(object.statusCodes[i]);
                        }
                        if (object.surveyRequestCatalogIds) {
                            if (!Array.isArray(object.surveyRequestCatalogIds))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter.surveyRequestCatalogIds: array expected");
                            message.surveyRequestCatalogIds = [];
                            for (let i = 0; i < object.surveyRequestCatalogIds.length; ++i)
                                message.surveyRequestCatalogIds[i] = String(object.surveyRequestCatalogIds[i]);
                        }
                        if (object.sendedAtFrom != null)
                            if ($util.Long)
                                (message.sendedAtFrom = $util.Long.fromValue(object.sendedAtFrom)).unsigned = false;
                            else if (typeof object.sendedAtFrom === "string")
                                message.sendedAtFrom = parseInt(object.sendedAtFrom, 10);
                            else if (typeof object.sendedAtFrom === "number")
                                message.sendedAtFrom = object.sendedAtFrom;
                            else if (typeof object.sendedAtFrom === "object")
                                message.sendedAtFrom = new $util.LongBits(object.sendedAtFrom.low >>> 0, object.sendedAtFrom.high >>> 0).toNumber();
                        if (object.sendedAtTo != null)
                            if ($util.Long)
                                (message.sendedAtTo = $util.Long.fromValue(object.sendedAtTo)).unsigned = false;
                            else if (typeof object.sendedAtTo === "string")
                                message.sendedAtTo = parseInt(object.sendedAtTo, 10);
                            else if (typeof object.sendedAtTo === "number")
                                message.sendedAtTo = object.sendedAtTo;
                            else if (typeof object.sendedAtTo === "object")
                                message.sendedAtTo = new $util.LongBits(object.sendedAtTo.low >>> 0, object.sendedAtTo.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.surveyRequestCatalogIds = [];
                            object.statusCodes = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtFrom = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtTo = options.longs === String ? "0" : 0;
                        }
                        if (message.surveyRequestCatalogIds && message.surveyRequestCatalogIds.length) {
                            object.surveyRequestCatalogIds = [];
                            for (let j = 0; j < message.surveyRequestCatalogIds.length; ++j)
                                object.surveyRequestCatalogIds[j] = message.surveyRequestCatalogIds[j];
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (typeof message.sendedAtFrom === "number")
                                object.sendedAtFrom = options.longs === String ? String(message.sendedAtFrom) : message.sendedAtFrom;
                            else
                                object.sendedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtFrom) : options.longs === Number ? new $util.LongBits(message.sendedAtFrom.low >>> 0, message.sendedAtFrom.high >>> 0).toNumber() : message.sendedAtFrom;
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (typeof message.sendedAtTo === "number")
                                object.sendedAtTo = options.longs === String ? String(message.sendedAtTo) : message.sendedAtTo;
                            else
                                object.sendedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtTo) : options.longs === Number ? new $util.LongBits(message.sendedAtTo.low >>> 0, message.sendedAtTo.high >>> 0).toNumber() : message.sendedAtTo;
                        if (message.statusCodes && message.statusCodes.length) {
                            object.statusCodes = [];
                            for (let j = 0; j < message.statusCodes.length; ++j)
                                object.statusCodes[j] = message.statusCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListSurveyBaseRequestContentsRequest;
            })();

            survey.ListSurveyBaseRequestContentsResponse = (function() {

                /**
                 * Properties of a ListSurveyBaseRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyBaseRequestContentsResponse
                 * @property {Long|null} [total] ListSurveyBaseRequestContentsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyBaseRequestContent>|null} [items] ListSurveyBaseRequestContentsResponse items
                 * @property {string|null} [pageToken] ListSurveyBaseRequestContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyBaseRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyBaseRequestContentsResponse.
                 * @implements IListSurveyBaseRequestContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyBaseRequestContentsResponse=} [properties] Properties to set
                 */
                function ListSurveyBaseRequestContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyBaseRequestContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @instance
                 */
                ListSurveyBaseRequestContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyBaseRequestContentsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyBaseRequestContent>} items
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @instance
                 */
                ListSurveyBaseRequestContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyBaseRequestContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @instance
                 */
                ListSurveyBaseRequestContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyBaseRequestContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyBaseRequestContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyBaseRequestContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyBaseRequestContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse} ListSurveyBaseRequestContentsResponse
                 */
                ListSurveyBaseRequestContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyBaseRequestContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyBaseRequestContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse} message ListSurveyBaseRequestContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyBaseRequestContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyBaseRequestContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyBaseRequestContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyBaseRequestContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyBaseRequestContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyBaseRequestContentsResponse;
            })();

            survey.GetSurveyBaseRequestContentRequest = (function() {

                /**
                 * Properties of a GetSurveyBaseRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyBaseRequestContentRequest
                 * @property {string|null} [surveyBaseRequestId] GetSurveyBaseRequestContentRequest surveyBaseRequestId
                 */

                /**
                 * Constructs a new GetSurveyBaseRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyBaseRequestContentRequest.
                 * @implements IGetSurveyBaseRequestContentRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyBaseRequestContentRequest=} [properties] Properties to set
                 */
                function GetSurveyBaseRequestContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyBaseRequestContentRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.GetSurveyBaseRequestContentRequest
                 * @instance
                 */
                GetSurveyBaseRequestContentRequest.prototype.surveyBaseRequestId = "";

                /**
                 * Verifies a GetSurveyBaseRequestContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyBaseRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyBaseRequestContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyBaseRequestContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyBaseRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyBaseRequestContentRequest} GetSurveyBaseRequestContentRequest
                 */
                GetSurveyBaseRequestContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyBaseRequestContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyBaseRequestContentRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyBaseRequestContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyBaseRequestContentRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyBaseRequestContentRequest} message GetSurveyBaseRequestContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyBaseRequestContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyBaseRequestId = "";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    return object;
                };

                /**
                 * Converts this GetSurveyBaseRequestContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyBaseRequestContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyBaseRequestContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyBaseRequestContentRequest;
            })();

            survey.ListSurveyRequestContentsRequest = (function() {

                /**
                 * Properties of a ListSurveyRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestContentsRequest
                 * @property {string|null} [pageToken] ListSurveyRequestContentsRequest pageToken
                 * @property {mtechnavi.api.survey.ListSurveyRequestContentsRequest.IDataFilter|null} [dataFilter] ListSurveyRequestContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ListSurveyRequestContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestContentsRequest.
                 * @implements IListSurveyRequestContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestContentsRequest=} [properties] Properties to set
                 */
                function ListSurveyRequestContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @instance
                 */
                ListSurveyRequestContentsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyRequestContentsRequest dataFilter.
                 * @member {mtechnavi.api.survey.ListSurveyRequestContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @instance
                 */
                ListSurveyRequestContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListSurveyRequestContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestContentsRequest} ListSurveyRequestContentsRequest
                 */
                ListSurveyRequestContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestContentsRequest} message ListSurveyRequestContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListSurveyRequestContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [surveyRequestIds] DataFilter surveyRequestIds
                     * @property {Array.<string>|null} [surveyBaseRequestIds] DataFilter surveyBaseRequestIds
                     * @property {Array.<string>|null} [statusCodes] DataFilter statusCodes
                     * @property {Array.<string>|null} [surveyRequestCatalogIds] DataFilter surveyRequestCatalogIds
                     * @property {Long|null} [sendedAtFrom] DataFilter sendedAtFrom
                     * @property {Long|null} [sendedAtTo] DataFilter sendedAtTo
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.survey.ListSurveyRequestContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.surveyRequestIds = [];
                        this.surveyBaseRequestIds = [];
                        this.statusCodes = [];
                        this.surveyRequestCatalogIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter surveyRequestIds.
                     * @member {Array.<string>} surveyRequestIds
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.surveyRequestIds = $util.emptyArray;

                    /**
                     * DataFilter surveyBaseRequestIds.
                     * @member {Array.<string>} surveyBaseRequestIds
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.surveyBaseRequestIds = $util.emptyArray;

                    /**
                     * DataFilter statusCodes.
                     * @member {Array.<string>} statusCodes
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statusCodes = $util.emptyArray;

                    /**
                     * DataFilter surveyRequestCatalogIds.
                     * @member {Array.<string>} surveyRequestCatalogIds
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.surveyRequestCatalogIds = $util.emptyArray;

                    /**
                     * DataFilter sendedAtFrom.
                     * @member {Long} sendedAtFrom
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter sendedAtTo.
                     * @member {Long} sendedAtTo
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.sendedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                            if (!Array.isArray(message.surveyRequestIds))
                                return "surveyRequestIds: array expected";
                            for (let i = 0; i < message.surveyRequestIds.length; ++i)
                                if (!$util.isString(message.surveyRequestIds[i]))
                                    return "surveyRequestIds: string[] expected";
                        }
                        if (message.surveyBaseRequestIds != null && message.hasOwnProperty("surveyBaseRequestIds")) {
                            if (!Array.isArray(message.surveyBaseRequestIds))
                                return "surveyBaseRequestIds: array expected";
                            for (let i = 0; i < message.surveyBaseRequestIds.length; ++i)
                                if (!$util.isString(message.surveyBaseRequestIds[i]))
                                    return "surveyBaseRequestIds: string[] expected";
                        }
                        if (message.statusCodes != null && message.hasOwnProperty("statusCodes")) {
                            if (!Array.isArray(message.statusCodes))
                                return "statusCodes: array expected";
                            for (let i = 0; i < message.statusCodes.length; ++i)
                                if (!$util.isString(message.statusCodes[i]))
                                    return "statusCodes: string[] expected";
                        }
                        if (message.surveyRequestCatalogIds != null && message.hasOwnProperty("surveyRequestCatalogIds")) {
                            if (!Array.isArray(message.surveyRequestCatalogIds))
                                return "surveyRequestCatalogIds: array expected";
                            for (let i = 0; i < message.surveyRequestCatalogIds.length; ++i)
                                if (!$util.isString(message.surveyRequestCatalogIds[i]))
                                    return "surveyRequestCatalogIds: string[] expected";
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (!$util.isInteger(message.sendedAtFrom) && !(message.sendedAtFrom && $util.isInteger(message.sendedAtFrom.low) && $util.isInteger(message.sendedAtFrom.high)))
                                return "sendedAtFrom: integer|Long expected";
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (!$util.isInteger(message.sendedAtTo) && !(message.sendedAtTo && $util.isInteger(message.sendedAtTo.low) && $util.isInteger(message.sendedAtTo.high)))
                                return "sendedAtTo: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter();
                        if (object.surveyRequestIds) {
                            if (!Array.isArray(object.surveyRequestIds))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.surveyRequestIds: array expected");
                            message.surveyRequestIds = [];
                            for (let i = 0; i < object.surveyRequestIds.length; ++i)
                                message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                        }
                        if (object.surveyBaseRequestIds) {
                            if (!Array.isArray(object.surveyBaseRequestIds))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.surveyBaseRequestIds: array expected");
                            message.surveyBaseRequestIds = [];
                            for (let i = 0; i < object.surveyBaseRequestIds.length; ++i)
                                message.surveyBaseRequestIds[i] = String(object.surveyBaseRequestIds[i]);
                        }
                        if (object.statusCodes) {
                            if (!Array.isArray(object.statusCodes))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.statusCodes: array expected");
                            message.statusCodes = [];
                            for (let i = 0; i < object.statusCodes.length; ++i)
                                message.statusCodes[i] = String(object.statusCodes[i]);
                        }
                        if (object.surveyRequestCatalogIds) {
                            if (!Array.isArray(object.surveyRequestCatalogIds))
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter.surveyRequestCatalogIds: array expected");
                            message.surveyRequestCatalogIds = [];
                            for (let i = 0; i < object.surveyRequestCatalogIds.length; ++i)
                                message.surveyRequestCatalogIds[i] = String(object.surveyRequestCatalogIds[i]);
                        }
                        if (object.sendedAtFrom != null)
                            if ($util.Long)
                                (message.sendedAtFrom = $util.Long.fromValue(object.sendedAtFrom)).unsigned = false;
                            else if (typeof object.sendedAtFrom === "string")
                                message.sendedAtFrom = parseInt(object.sendedAtFrom, 10);
                            else if (typeof object.sendedAtFrom === "number")
                                message.sendedAtFrom = object.sendedAtFrom;
                            else if (typeof object.sendedAtFrom === "object")
                                message.sendedAtFrom = new $util.LongBits(object.sendedAtFrom.low >>> 0, object.sendedAtFrom.high >>> 0).toNumber();
                        if (object.sendedAtTo != null)
                            if ($util.Long)
                                (message.sendedAtTo = $util.Long.fromValue(object.sendedAtTo)).unsigned = false;
                            else if (typeof object.sendedAtTo === "string")
                                message.sendedAtTo = parseInt(object.sendedAtTo, 10);
                            else if (typeof object.sendedAtTo === "number")
                                message.sendedAtTo = object.sendedAtTo;
                            else if (typeof object.sendedAtTo === "object")
                                message.sendedAtTo = new $util.LongBits(object.sendedAtTo.low >>> 0, object.sendedAtTo.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.surveyRequestCatalogIds = [];
                            object.statusCodes = [];
                            object.surveyRequestIds = [];
                            object.surveyBaseRequestIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtFrom = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.sendedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendedAtTo = options.longs === String ? "0" : 0;
                        }
                        if (message.surveyRequestCatalogIds && message.surveyRequestCatalogIds.length) {
                            object.surveyRequestCatalogIds = [];
                            for (let j = 0; j < message.surveyRequestCatalogIds.length; ++j)
                                object.surveyRequestCatalogIds[j] = message.surveyRequestCatalogIds[j];
                        }
                        if (message.sendedAtFrom != null && message.hasOwnProperty("sendedAtFrom"))
                            if (typeof message.sendedAtFrom === "number")
                                object.sendedAtFrom = options.longs === String ? String(message.sendedAtFrom) : message.sendedAtFrom;
                            else
                                object.sendedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtFrom) : options.longs === Number ? new $util.LongBits(message.sendedAtFrom.low >>> 0, message.sendedAtFrom.high >>> 0).toNumber() : message.sendedAtFrom;
                        if (message.sendedAtTo != null && message.hasOwnProperty("sendedAtTo"))
                            if (typeof message.sendedAtTo === "number")
                                object.sendedAtTo = options.longs === String ? String(message.sendedAtTo) : message.sendedAtTo;
                            else
                                object.sendedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAtTo) : options.longs === Number ? new $util.LongBits(message.sendedAtTo.low >>> 0, message.sendedAtTo.high >>> 0).toNumber() : message.sendedAtTo;
                        if (message.statusCodes && message.statusCodes.length) {
                            object.statusCodes = [];
                            for (let j = 0; j < message.statusCodes.length; ++j)
                                object.statusCodes[j] = message.statusCodes[j];
                        }
                        if (message.surveyRequestIds && message.surveyRequestIds.length) {
                            object.surveyRequestIds = [];
                            for (let j = 0; j < message.surveyRequestIds.length; ++j)
                                object.surveyRequestIds[j] = message.surveyRequestIds[j];
                        }
                        if (message.surveyBaseRequestIds && message.surveyBaseRequestIds.length) {
                            object.surveyBaseRequestIds = [];
                            for (let j = 0; j < message.surveyBaseRequestIds.length; ++j)
                                object.surveyBaseRequestIds[j] = message.surveyBaseRequestIds[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.survey.ListSurveyRequestContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListSurveyRequestContentsRequest;
            })();

            survey.ListSurveyRequestContentsResponse = (function() {

                /**
                 * Properties of a ListSurveyRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestContentsResponse
                 * @property {Long|null} [total] ListSurveyRequestContentsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestContent>|null} [items] ListSurveyRequestContentsResponse items
                 * @property {string|null} [pageToken] ListSurveyRequestContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyRequestContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestContentsResponse.
                 * @implements IListSurveyRequestContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestContentsResponse=} [properties] Properties to set
                 */
                function ListSurveyRequestContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @instance
                 */
                ListSurveyRequestContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyRequestContentsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestContent>} items
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @instance
                 */
                ListSurveyRequestContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyRequestContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @instance
                 */
                ListSurveyRequestContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRequestContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestContentsResponse} ListSurveyRequestContentsResponse
                 */
                ListSurveyRequestContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestContentsResponse} message ListSurveyRequestContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestContentsResponse;
            })();

            survey.GetSurveyRequestContentRequest = (function() {

                /**
                 * Properties of a GetSurveyRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyRequestContentRequest
                 * @property {string|null} [surveyRequestId] GetSurveyRequestContentRequest surveyRequestId
                 */

                /**
                 * Constructs a new GetSurveyRequestContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyRequestContentRequest.
                 * @implements IGetSurveyRequestContentRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyRequestContentRequest=} [properties] Properties to set
                 */
                function GetSurveyRequestContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyRequestContentRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.GetSurveyRequestContentRequest
                 * @instance
                 */
                GetSurveyRequestContentRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies a GetSurveyRequestContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyRequestContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyRequestContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyRequestContentRequest} GetSurveyRequestContentRequest
                 */
                GetSurveyRequestContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyRequestContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyRequestContentRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyRequestContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyRequestContentRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyRequestContentRequest} message GetSurveyRequestContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyRequestContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this GetSurveyRequestContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyRequestContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyRequestContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyRequestContentRequest;
            })();

            survey.ListSurveyReceptionContentsRequest = (function() {

                /**
                 * Properties of a ListSurveyReceptionContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReceptionContentsRequest
                 * @property {string|null} [pageToken] ListSurveyReceptionContentsRequest pageToken
                 * @property {mtechnavi.api.survey.ListSurveyReceptionContentsRequest.IDataFilter|null} [dataFilter] ListSurveyReceptionContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ListSurveyReceptionContentsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReceptionContentsRequest.
                 * @implements IListSurveyReceptionContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReceptionContentsRequest=} [properties] Properties to set
                 */
                function ListSurveyReceptionContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReceptionContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @instance
                 */
                ListSurveyReceptionContentsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyReceptionContentsRequest dataFilter.
                 * @member {mtechnavi.api.survey.ListSurveyReceptionContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @instance
                 */
                ListSurveyReceptionContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListSurveyReceptionContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReceptionContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyReceptionContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReceptionContentsRequest} ListSurveyReceptionContentsRequest
                 */
                ListSurveyReceptionContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReceptionContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReceptionContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReceptionContentsRequest} message ListSurveyReceptionContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReceptionContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListSurveyReceptionContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReceptionContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListSurveyReceptionContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                     * @interface IDataFilter
                     * @property {Long|null} [receiptedAtFrom] DataFilter receiptedAtFrom
                     * @property {Long|null} [receiptedAtTo] DataFilter receiptedAtTo
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.survey.ListSurveyReceptionContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter receiptedAtFrom.
                     * @member {Long} receiptedAtFrom
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.receiptedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter receiptedAtTo.
                     * @member {Long} receiptedAtTo
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.receiptedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.receiptedAtFrom != null && message.hasOwnProperty("receiptedAtFrom"))
                            if (!$util.isInteger(message.receiptedAtFrom) && !(message.receiptedAtFrom && $util.isInteger(message.receiptedAtFrom.low) && $util.isInteger(message.receiptedAtFrom.high)))
                                return "receiptedAtFrom: integer|Long expected";
                        if (message.receiptedAtTo != null && message.hasOwnProperty("receiptedAtTo"))
                            if (!$util.isInteger(message.receiptedAtTo) && !(message.receiptedAtTo && $util.isInteger(message.receiptedAtTo.low) && $util.isInteger(message.receiptedAtTo.high)))
                                return "receiptedAtTo: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter();
                        if (object.receiptedAtFrom != null)
                            if ($util.Long)
                                (message.receiptedAtFrom = $util.Long.fromValue(object.receiptedAtFrom)).unsigned = false;
                            else if (typeof object.receiptedAtFrom === "string")
                                message.receiptedAtFrom = parseInt(object.receiptedAtFrom, 10);
                            else if (typeof object.receiptedAtFrom === "number")
                                message.receiptedAtFrom = object.receiptedAtFrom;
                            else if (typeof object.receiptedAtFrom === "object")
                                message.receiptedAtFrom = new $util.LongBits(object.receiptedAtFrom.low >>> 0, object.receiptedAtFrom.high >>> 0).toNumber();
                        if (object.receiptedAtTo != null)
                            if ($util.Long)
                                (message.receiptedAtTo = $util.Long.fromValue(object.receiptedAtTo)).unsigned = false;
                            else if (typeof object.receiptedAtTo === "string")
                                message.receiptedAtTo = parseInt(object.receiptedAtTo, 10);
                            else if (typeof object.receiptedAtTo === "number")
                                message.receiptedAtTo = object.receiptedAtTo;
                            else if (typeof object.receiptedAtTo === "object")
                                message.receiptedAtTo = new $util.LongBits(object.receiptedAtTo.low >>> 0, object.receiptedAtTo.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.receiptedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.receiptedAtFrom = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.receiptedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.receiptedAtTo = options.longs === String ? "0" : 0;
                        }
                        if (message.receiptedAtFrom != null && message.hasOwnProperty("receiptedAtFrom"))
                            if (typeof message.receiptedAtFrom === "number")
                                object.receiptedAtFrom = options.longs === String ? String(message.receiptedAtFrom) : message.receiptedAtFrom;
                            else
                                object.receiptedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.receiptedAtFrom) : options.longs === Number ? new $util.LongBits(message.receiptedAtFrom.low >>> 0, message.receiptedAtFrom.high >>> 0).toNumber() : message.receiptedAtFrom;
                        if (message.receiptedAtTo != null && message.hasOwnProperty("receiptedAtTo"))
                            if (typeof message.receiptedAtTo === "number")
                                object.receiptedAtTo = options.longs === String ? String(message.receiptedAtTo) : message.receiptedAtTo;
                            else
                                object.receiptedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.receiptedAtTo) : options.longs === Number ? new $util.LongBits(message.receiptedAtTo.low >>> 0, message.receiptedAtTo.high >>> 0).toNumber() : message.receiptedAtTo;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListSurveyReceptionContentsRequest;
            })();

            survey.ListSurveyReceptionContentsResponse = (function() {

                /**
                 * Properties of a ListSurveyReceptionContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyReceptionContentsResponse
                 * @property {Long|null} [total] ListSurveyReceptionContentsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReceptionContent>|null} [items] ListSurveyReceptionContentsResponse items
                 * @property {string|null} [pageToken] ListSurveyReceptionContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyReceptionContentsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyReceptionContentsResponse.
                 * @implements IListSurveyReceptionContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyReceptionContentsResponse=} [properties] Properties to set
                 */
                function ListSurveyReceptionContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyReceptionContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @instance
                 */
                ListSurveyReceptionContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyReceptionContentsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReceptionContent>} items
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @instance
                 */
                ListSurveyReceptionContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyReceptionContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @instance
                 */
                ListSurveyReceptionContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyReceptionContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyReceptionContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReceptionContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyReceptionContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyReceptionContentsResponse} ListSurveyReceptionContentsResponse
                 */
                ListSurveyReceptionContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyReceptionContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyReceptionContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyReceptionContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyReceptionContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReceptionContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyReceptionContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyReceptionContentsResponse} message ListSurveyReceptionContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyReceptionContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReceptionContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyReceptionContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyReceptionContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyReceptionContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyReceptionContentsResponse;
            })();

            survey.GetSurveyReceptionContentRequest = (function() {

                /**
                 * Properties of a GetSurveyReceptionContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IGetSurveyReceptionContentRequest
                 * @property {string|null} [surveyReceptionId] GetSurveyReceptionContentRequest surveyReceptionId
                 */

                /**
                 * Constructs a new GetSurveyReceptionContentRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a GetSurveyReceptionContentRequest.
                 * @implements IGetSurveyReceptionContentRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IGetSurveyReceptionContentRequest=} [properties] Properties to set
                 */
                function GetSurveyReceptionContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSurveyReceptionContentRequest surveyReceptionId.
                 * @member {string} surveyReceptionId
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionContentRequest
                 * @instance
                 */
                GetSurveyReceptionContentRequest.prototype.surveyReceptionId = "";

                /**
                 * Verifies a GetSurveyReceptionContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSurveyReceptionContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        if (!$util.isString(message.surveyReceptionId))
                            return "surveyReceptionId: string expected";
                    return null;
                };

                /**
                 * Creates a GetSurveyReceptionContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.GetSurveyReceptionContentRequest} GetSurveyReceptionContentRequest
                 */
                GetSurveyReceptionContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.GetSurveyReceptionContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.GetSurveyReceptionContentRequest();
                    if (object.surveyReceptionId != null)
                        message.surveyReceptionId = String(object.surveyReceptionId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSurveyReceptionContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionContentRequest
                 * @static
                 * @param {mtechnavi.api.survey.GetSurveyReceptionContentRequest} message GetSurveyReceptionContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSurveyReceptionContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyReceptionId = "";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        object.surveyReceptionId = message.surveyReceptionId;
                    return object;
                };

                /**
                 * Converts this GetSurveyReceptionContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.GetSurveyReceptionContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSurveyReceptionContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSurveyReceptionContentRequest;
            })();

            survey.UncompletedListSurveyRequestRequest = (function() {

                /**
                 * Properties of an UncompletedListSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUncompletedListSurveyRequestRequest
                 * @property {string|null} [pageToken] UncompletedListSurveyRequestRequest pageToken
                 */

                /**
                 * Constructs a new UncompletedListSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UncompletedListSurveyRequestRequest.
                 * @implements IUncompletedListSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUncompletedListSurveyRequestRequest=} [properties] Properties to set
                 */
                function UncompletedListSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListSurveyRequestRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestRequest
                 * @instance
                 */
                UncompletedListSurveyRequestRequest.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UncompletedListSurveyRequestRequest} UncompletedListSurveyRequestRequest
                 */
                UncompletedListSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UncompletedListSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UncompletedListSurveyRequestRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UncompletedListSurveyRequestRequest} message UncompletedListSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListSurveyRequestRequest;
            })();

            survey.UncompletedListSurveyRequestResponse = (function() {

                /**
                 * Properties of an UncompletedListSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IUncompletedListSurveyRequestResponse
                 * @property {Long|null} [total] UncompletedListSurveyRequestResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyReceptionContent>|null} [items] UncompletedListSurveyRequestResponse items
                 * @property {string|null} [pageToken] UncompletedListSurveyRequestResponse pageToken
                 */

                /**
                 * Constructs a new UncompletedListSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UncompletedListSurveyRequestResponse.
                 * @implements IUncompletedListSurveyRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IUncompletedListSurveyRequestResponse=} [properties] Properties to set
                 */
                function UncompletedListSurveyRequestResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListSurveyRequestResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @instance
                 */
                UncompletedListSurveyRequestResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UncompletedListSurveyRequestResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyReceptionContent>} items
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @instance
                 */
                UncompletedListSurveyRequestResponse.prototype.items = $util.emptyArray;

                /**
                 * UncompletedListSurveyRequestResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @instance
                 */
                UncompletedListSurveyRequestResponse.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListSurveyRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListSurveyRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyReceptionContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListSurveyRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UncompletedListSurveyRequestResponse} UncompletedListSurveyRequestResponse
                 */
                UncompletedListSurveyRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UncompletedListSurveyRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UncompletedListSurveyRequestResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.UncompletedListSurveyRequestResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.UncompletedListSurveyRequestResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyReceptionContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListSurveyRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.UncompletedListSurveyRequestResponse} message UncompletedListSurveyRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListSurveyRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyReceptionContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListSurveyRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UncompletedListSurveyRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListSurveyRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListSurveyRequestResponse;
            })();

            survey.UnchangedListSurveyReplyDueDateChangeRequestRequest = (function() {

                /**
                 * Properties of an UnchangedListSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @property {string|null} [pageToken] UnchangedListSurveyReplyDueDateChangeRequestRequest pageToken
                 */

                /**
                 * Constructs a new UnchangedListSurveyReplyDueDateChangeRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UnchangedListSurveyReplyDueDateChangeRequestRequest.
                 * @implements IUnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUnchangedListSurveyReplyDueDateChangeRequestRequest=} [properties] Properties to set
                 */
                function UnchangedListSurveyReplyDueDateChangeRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnchangedListSurveyReplyDueDateChangeRequestRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 */
                UnchangedListSurveyReplyDueDateChangeRequestRequest.prototype.pageToken = "";

                /**
                 * Verifies an UnchangedListSurveyReplyDueDateChangeRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnchangedListSurveyReplyDueDateChangeRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UnchangedListSurveyReplyDueDateChangeRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest} UnchangedListSurveyReplyDueDateChangeRequestRequest
                 */
                UnchangedListSurveyReplyDueDateChangeRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UnchangedListSurveyReplyDueDateChangeRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest} message UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnchangedListSurveyReplyDueDateChangeRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UnchangedListSurveyReplyDueDateChangeRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnchangedListSurveyReplyDueDateChangeRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnchangedListSurveyReplyDueDateChangeRequestRequest;
            })();

            survey.UnchangedListSurveyReplyDueDateChangeRequestResponse = (function() {

                /**
                 * Properties of an UnchangedListSurveyReplyDueDateChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IUnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @property {Long|null} [total] UnchangedListSurveyReplyDueDateChangeRequestResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestContent>|null} [items] UnchangedListSurveyReplyDueDateChangeRequestResponse items
                 * @property {string|null} [pageToken] UnchangedListSurveyReplyDueDateChangeRequestResponse pageToken
                 */

                /**
                 * Constructs a new UnchangedListSurveyReplyDueDateChangeRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UnchangedListSurveyReplyDueDateChangeRequestResponse.
                 * @implements IUnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IUnchangedListSurveyReplyDueDateChangeRequestResponse=} [properties] Properties to set
                 */
                function UnchangedListSurveyReplyDueDateChangeRequestResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UnchangedListSurveyReplyDueDateChangeRequestResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @instance
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UnchangedListSurveyReplyDueDateChangeRequestResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestContent>} items
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @instance
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.prototype.items = $util.emptyArray;

                /**
                 * UnchangedListSurveyReplyDueDateChangeRequestResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @instance
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.prototype.pageToken = "";

                /**
                 * Verifies an UnchangedListSurveyReplyDueDateChangeRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UnchangedListSurveyReplyDueDateChangeRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse} UnchangedListSurveyReplyDueDateChangeRequestResponse
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UnchangedListSurveyReplyDueDateChangeRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse} message UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UnchangedListSurveyReplyDueDateChangeRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UnchangedListSurveyReplyDueDateChangeRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UnchangedListSurveyReplyDueDateChangeRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UnchangedListSurveyReplyDueDateChangeRequestResponse;
            })();

            survey.ExportedSurveyResultFileRequest = (function() {

                /**
                 * Properties of an ExportedSurveyResultFileRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportedSurveyResultFileRequest
                 * @property {string|null} [surveyResultId] ExportedSurveyResultFileRequest surveyResultId
                 */

                /**
                 * Constructs a new ExportedSurveyResultFileRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportedSurveyResultFileRequest.
                 * @implements IExportedSurveyResultFileRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportedSurveyResultFileRequest=} [properties] Properties to set
                 */
                function ExportedSurveyResultFileRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportedSurveyResultFileRequest surveyResultId.
                 * @member {string} surveyResultId
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileRequest
                 * @instance
                 */
                ExportedSurveyResultFileRequest.prototype.surveyResultId = "";

                /**
                 * Verifies an ExportedSurveyResultFileRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportedSurveyResultFileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        if (!$util.isString(message.surveyResultId))
                            return "surveyResultId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportedSurveyResultFileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportedSurveyResultFileRequest} ExportedSurveyResultFileRequest
                 */
                ExportedSurveyResultFileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportedSurveyResultFileRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportedSurveyResultFileRequest();
                    if (object.surveyResultId != null)
                        message.surveyResultId = String(object.surveyResultId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportedSurveyResultFileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExportedSurveyResultFileRequest} message ExportedSurveyResultFileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportedSurveyResultFileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultId = "";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        object.surveyResultId = message.surveyResultId;
                    return object;
                };

                /**
                 * Converts this ExportedSurveyResultFileRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportedSurveyResultFileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportedSurveyResultFileRequest;
            })();

            survey.ExportedSurveyResultFileResponse = (function() {

                /**
                 * Properties of an ExportedSurveyResultFileResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportedSurveyResultFileResponse
                 */

                /**
                 * Constructs a new ExportedSurveyResultFileResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportedSurveyResultFileResponse.
                 * @implements IExportedSurveyResultFileResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportedSurveyResultFileResponse=} [properties] Properties to set
                 */
                function ExportedSurveyResultFileResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ExportedSurveyResultFileResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportedSurveyResultFileResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ExportedSurveyResultFileResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportedSurveyResultFileResponse} ExportedSurveyResultFileResponse
                 */
                ExportedSurveyResultFileResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportedSurveyResultFileResponse)
                        return object;
                    return new $root.mtechnavi.api.survey.ExportedSurveyResultFileResponse();
                };

                /**
                 * Creates a plain object from an ExportedSurveyResultFileResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExportedSurveyResultFileResponse} message ExportedSurveyResultFileResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportedSurveyResultFileResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ExportedSurveyResultFileResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultFileResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportedSurveyResultFileResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportedSurveyResultFileResponse;
            })();

            survey.OpenSurveyRequestRequest = (function() {

                /**
                 * Properties of an OpenSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IOpenSurveyRequestRequest
                 * @property {string|null} [surveyRequestId] OpenSurveyRequestRequest surveyRequestId
                 */

                /**
                 * Constructs a new OpenSurveyRequestRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an OpenSurveyRequestRequest.
                 * @implements IOpenSurveyRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IOpenSurveyRequestRequest=} [properties] Properties to set
                 */
                function OpenSurveyRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OpenSurveyRequestRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestRequest
                 * @instance
                 */
                OpenSurveyRequestRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies an OpenSurveyRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OpenSurveyRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates an OpenSurveyRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.OpenSurveyRequestRequest} OpenSurveyRequestRequest
                 */
                OpenSurveyRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.OpenSurveyRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.OpenSurveyRequestRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from an OpenSurveyRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestRequest
                 * @static
                 * @param {mtechnavi.api.survey.OpenSurveyRequestRequest} message OpenSurveyRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenSurveyRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this OpenSurveyRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenSurveyRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenSurveyRequestRequest;
            })();

            survey.OpenSurveyRequestResponse = (function() {

                /**
                 * Properties of an OpenSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IOpenSurveyRequestResponse
                 * @property {mtechnavi.api.survey.IOpened|null} [opened] OpenSurveyRequestResponse opened
                 */

                /**
                 * Constructs a new OpenSurveyRequestResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an OpenSurveyRequestResponse.
                 * @implements IOpenSurveyRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IOpenSurveyRequestResponse=} [properties] Properties to set
                 */
                function OpenSurveyRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OpenSurveyRequestResponse opened.
                 * @member {mtechnavi.api.survey.IOpened|null|undefined} opened
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestResponse
                 * @instance
                 */
                OpenSurveyRequestResponse.prototype.opened = null;

                /**
                 * Verifies an OpenSurveyRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OpenSurveyRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.opened != null && message.hasOwnProperty("opened")) {
                        let error = $root.mtechnavi.api.survey.Opened.verify(message.opened);
                        if (error)
                            return "opened." + error;
                    }
                    return null;
                };

                /**
                 * Creates an OpenSurveyRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.OpenSurveyRequestResponse} OpenSurveyRequestResponse
                 */
                OpenSurveyRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.OpenSurveyRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.OpenSurveyRequestResponse();
                    if (object.opened != null) {
                        if (typeof object.opened !== "object")
                            throw TypeError(".mtechnavi.api.survey.OpenSurveyRequestResponse.opened: object expected");
                        message.opened = $root.mtechnavi.api.survey.Opened.fromObject(object.opened);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an OpenSurveyRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestResponse
                 * @static
                 * @param {mtechnavi.api.survey.OpenSurveyRequestResponse} message OpenSurveyRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OpenSurveyRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.opened = null;
                    if (message.opened != null && message.hasOwnProperty("opened"))
                        object.opened = $root.mtechnavi.api.survey.Opened.toObject(message.opened, options);
                    return object;
                };

                /**
                 * Converts this OpenSurveyRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.OpenSurveyRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OpenSurveyRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OpenSurveyRequestResponse;
            })();

            survey.CreateSurveyRequestWorkFormRequest = (function() {

                /**
                 * Properties of a CreateSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyRequestWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestWorkForm|null} [surveyRequestWorkForm] CreateSurveyRequestWorkFormRequest surveyRequestWorkForm
                 */

                /**
                 * Constructs a new CreateSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyRequestWorkFormRequest.
                 * @implements ICreateSurveyRequestWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyRequestWorkFormRequest=} [properties] Properties to set
                 */
                function CreateSurveyRequestWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyRequestWorkFormRequest surveyRequestWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyRequestWorkForm|null|undefined} surveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest
                 * @instance
                 */
                CreateSurveyRequestWorkFormRequest.prototype.surveyRequestWorkForm = null;

                /**
                 * Verifies a CreateSurveyRequestWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyRequestWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestWorkForm.verify(message.surveyRequestWorkForm);
                        if (error)
                            return "surveyRequestWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyRequestWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest} CreateSurveyRequestWorkFormRequest
                 */
                CreateSurveyRequestWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest();
                    if (object.surveyRequestWorkForm != null) {
                        if (typeof object.surveyRequestWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest.surveyRequestWorkForm: object expected");
                        message.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.fromObject(object.surveyRequestWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyRequestWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest} message CreateSurveyRequestWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyRequestWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestWorkForm = null;
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm"))
                        object.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.toObject(message.surveyRequestWorkForm, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyRequestWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyRequestWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyRequestWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyRequestWorkFormRequest;
            })();

            survey.ListSurveyRequestWorkFormsRequest = (function() {

                /**
                 * Properties of a ListSurveyRequestWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestWorkFormsRequest
                 * @property {string|null} [pageToken] ListSurveyRequestWorkFormsRequest pageToken
                 * @property {Array.<string>|null} [formIds] ListSurveyRequestWorkFormsRequest formIds
                 */

                /**
                 * Constructs a new ListSurveyRequestWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestWorkFormsRequest.
                 * @implements IListSurveyRequestWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestWorkFormsRequest=} [properties] Properties to set
                 */
                function ListSurveyRequestWorkFormsRequest(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestWorkFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @instance
                 */
                ListSurveyRequestWorkFormsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyRequestWorkFormsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @instance
                 */
                ListSurveyRequestWorkFormsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyRequestWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest} ListSurveyRequestWorkFormsRequest
                 */
                ListSurveyRequestWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest} message ListSurveyRequestWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestWorkFormsRequest;
            })();

            survey.ListSurveyRequestWorkFormsResponse = (function() {

                /**
                 * Properties of a ListSurveyRequestWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyRequestWorkFormsResponse
                 * @property {Long|null} [total] ListSurveyRequestWorkFormsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestWorkForm>|null} [items] ListSurveyRequestWorkFormsResponse items
                 * @property {string|null} [pageToken] ListSurveyRequestWorkFormsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyRequestWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyRequestWorkFormsResponse.
                 * @implements IListSurveyRequestWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyRequestWorkFormsResponse=} [properties] Properties to set
                 */
                function ListSurveyRequestWorkFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyRequestWorkFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                ListSurveyRequestWorkFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyRequestWorkFormsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestWorkForm>} items
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                ListSurveyRequestWorkFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyRequestWorkFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                ListSurveyRequestWorkFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyRequestWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyRequestWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestWorkForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyRequestWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse} ListSurveyRequestWorkFormsResponse
                 */
                ListSurveyRequestWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestWorkForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyRequestWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse} message ListSurveyRequestWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyRequestWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestWorkForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyRequestWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyRequestWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyRequestWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyRequestWorkFormsResponse;
            })();

            survey.UpdateSurveyRequestWorkFormRequest = (function() {

                /**
                 * Properties of an UpdateSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyRequestWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestWorkForm|null} [surveyRequestWorkForm] UpdateSurveyRequestWorkFormRequest surveyRequestWorkForm
                 */

                /**
                 * Constructs a new UpdateSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyRequestWorkFormRequest.
                 * @implements IUpdateSurveyRequestWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyRequestWorkFormRequest=} [properties] Properties to set
                 */
                function UpdateSurveyRequestWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyRequestWorkFormRequest surveyRequestWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyRequestWorkForm|null|undefined} surveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest
                 * @instance
                 */
                UpdateSurveyRequestWorkFormRequest.prototype.surveyRequestWorkForm = null;

                /**
                 * Verifies an UpdateSurveyRequestWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyRequestWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestWorkForm.verify(message.surveyRequestWorkForm);
                        if (error)
                            return "surveyRequestWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyRequestWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest} UpdateSurveyRequestWorkFormRequest
                 */
                UpdateSurveyRequestWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest();
                    if (object.surveyRequestWorkForm != null) {
                        if (typeof object.surveyRequestWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest.surveyRequestWorkForm: object expected");
                        message.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.fromObject(object.surveyRequestWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyRequestWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest} message UpdateSurveyRequestWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyRequestWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestWorkForm = null;
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm"))
                        object.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.toObject(message.surveyRequestWorkForm, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyRequestWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyRequestWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyRequestWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyRequestWorkFormRequest;
            })();

            survey.DeleteSurveyRequestWorkFormRequest = (function() {

                /**
                 * Properties of a DeleteSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IDeleteSurveyRequestWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyRequestWorkForm|null} [surveyRequestWorkForm] DeleteSurveyRequestWorkFormRequest surveyRequestWorkForm
                 */

                /**
                 * Constructs a new DeleteSurveyRequestWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DeleteSurveyRequestWorkFormRequest.
                 * @implements IDeleteSurveyRequestWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IDeleteSurveyRequestWorkFormRequest=} [properties] Properties to set
                 */
                function DeleteSurveyRequestWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteSurveyRequestWorkFormRequest surveyRequestWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyRequestWorkForm|null|undefined} surveyRequestWorkForm
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest
                 * @instance
                 */
                DeleteSurveyRequestWorkFormRequest.prototype.surveyRequestWorkForm = null;

                /**
                 * Verifies a DeleteSurveyRequestWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSurveyRequestWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestWorkForm.verify(message.surveyRequestWorkForm);
                        if (error)
                            return "surveyRequestWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteSurveyRequestWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest} DeleteSurveyRequestWorkFormRequest
                 */
                DeleteSurveyRequestWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest();
                    if (object.surveyRequestWorkForm != null) {
                        if (typeof object.surveyRequestWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest.surveyRequestWorkForm: object expected");
                        message.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.fromObject(object.surveyRequestWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteSurveyRequestWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest} message DeleteSurveyRequestWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSurveyRequestWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestWorkForm = null;
                    if (message.surveyRequestWorkForm != null && message.hasOwnProperty("surveyRequestWorkForm"))
                        object.surveyRequestWorkForm = $root.mtechnavi.api.survey.SurveyRequestWorkForm.toObject(message.surveyRequestWorkForm, options);
                    return object;
                };

                /**
                 * Converts this DeleteSurveyRequestWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DeleteSurveyRequestWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSurveyRequestWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteSurveyRequestWorkFormRequest;
            })();

            survey.CreateSurveyResultWorkFormRequest = (function() {

                /**
                 * Properties of a CreateSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateSurveyResultWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyResultWorkForm|null} [surveyResultWorkForm] CreateSurveyResultWorkFormRequest surveyResultWorkForm
                 */

                /**
                 * Constructs a new CreateSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateSurveyResultWorkFormRequest.
                 * @implements ICreateSurveyResultWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateSurveyResultWorkFormRequest=} [properties] Properties to set
                 */
                function CreateSurveyResultWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateSurveyResultWorkFormRequest surveyResultWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyResultWorkForm|null|undefined} surveyResultWorkForm
                 * @memberof mtechnavi.api.survey.CreateSurveyResultWorkFormRequest
                 * @instance
                 */
                CreateSurveyResultWorkFormRequest.prototype.surveyResultWorkForm = null;

                /**
                 * Verifies a CreateSurveyResultWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateSurveyResultWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyResultWorkForm.verify(message.surveyResultWorkForm);
                        if (error)
                            return "surveyResultWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateSurveyResultWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateSurveyResultWorkFormRequest} CreateSurveyResultWorkFormRequest
                 */
                CreateSurveyResultWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateSurveyResultWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateSurveyResultWorkFormRequest();
                    if (object.surveyResultWorkForm != null) {
                        if (typeof object.surveyResultWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateSurveyResultWorkFormRequest.surveyResultWorkForm: object expected");
                        message.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.fromObject(object.surveyResultWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateSurveyResultWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateSurveyResultWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateSurveyResultWorkFormRequest} message CreateSurveyResultWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateSurveyResultWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultWorkForm = null;
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm"))
                        object.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.toObject(message.surveyResultWorkForm, options);
                    return object;
                };

                /**
                 * Converts this CreateSurveyResultWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateSurveyResultWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateSurveyResultWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateSurveyResultWorkFormRequest;
            })();

            survey.ListSurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of a ListSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyResultWorkFormsRequest
                 * @property {string|null} [pageToken] ListSurveyResultWorkFormsRequest pageToken
                 * @property {Array.<string>|null} [formIds] ListSurveyResultWorkFormsRequest formIds
                 * @property {Array.<string>|null} [surveyResultWorkFormIds] ListSurveyResultWorkFormsRequest surveyResultWorkFormIds
                 */

                /**
                 * Constructs a new ListSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyResultWorkFormsRequest.
                 * @implements IListSurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function ListSurveyResultWorkFormsRequest(properties) {
                    this.formIds = [];
                    this.surveyResultWorkFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyResultWorkFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @instance
                 */
                ListSurveyResultWorkFormsRequest.prototype.pageToken = "";

                /**
                 * ListSurveyResultWorkFormsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @instance
                 */
                ListSurveyResultWorkFormsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * ListSurveyResultWorkFormsRequest surveyResultWorkFormIds.
                 * @member {Array.<string>} surveyResultWorkFormIds
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @instance
                 */
                ListSurveyResultWorkFormsRequest.prototype.surveyResultWorkFormIds = $util.emptyArray;

                /**
                 * Verifies a ListSurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    if (message.surveyResultWorkFormIds != null && message.hasOwnProperty("surveyResultWorkFormIds")) {
                        if (!Array.isArray(message.surveyResultWorkFormIds))
                            return "surveyResultWorkFormIds: array expected";
                        for (let i = 0; i < message.surveyResultWorkFormIds.length; ++i)
                            if (!$util.isString(message.surveyResultWorkFormIds[i]))
                                return "surveyResultWorkFormIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListSurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyResultWorkFormsRequest} ListSurveyResultWorkFormsRequest
                 */
                ListSurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyResultWorkFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultWorkFormsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    if (object.surveyResultWorkFormIds) {
                        if (!Array.isArray(object.surveyResultWorkFormIds))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultWorkFormsRequest.surveyResultWorkFormIds: array expected");
                        message.surveyResultWorkFormIds = [];
                        for (let i = 0; i < object.surveyResultWorkFormIds.length; ++i)
                            message.surveyResultWorkFormIds[i] = String(object.surveyResultWorkFormIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyResultWorkFormsRequest} message ListSurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.formIds = [];
                        object.surveyResultWorkFormIds = [];
                    }
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    if (message.surveyResultWorkFormIds && message.surveyResultWorkFormIds.length) {
                        object.surveyResultWorkFormIds = [];
                        for (let j = 0; j < message.surveyResultWorkFormIds.length; ++j)
                            object.surveyResultWorkFormIds[j] = message.surveyResultWorkFormIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListSurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyResultWorkFormsRequest;
            })();

            survey.ListSurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of a ListSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListSurveyResultWorkFormsResponse
                 * @property {Long|null} [total] ListSurveyResultWorkFormsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyResultWorkForm>|null} [items] ListSurveyResultWorkFormsResponse items
                 * @property {string|null} [pageToken] ListSurveyResultWorkFormsResponse pageToken
                 */

                /**
                 * Constructs a new ListSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListSurveyResultWorkFormsResponse.
                 * @implements IListSurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListSurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function ListSurveyResultWorkFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListSurveyResultWorkFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @instance
                 */
                ListSurveyResultWorkFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListSurveyResultWorkFormsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyResultWorkForm>} items
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @instance
                 */
                ListSurveyResultWorkFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListSurveyResultWorkFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @instance
                 */
                ListSurveyResultWorkFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListSurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListSurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyResultWorkForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListSurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListSurveyResultWorkFormsResponse} ListSurveyResultWorkFormsResponse
                 */
                ListSurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListSurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListSurveyResultWorkFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListSurveyResultWorkFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListSurveyResultWorkFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyResultWorkForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListSurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListSurveyResultWorkFormsResponse} message ListSurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListSurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyResultWorkForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListSurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListSurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListSurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListSurveyResultWorkFormsResponse;
            })();

            survey.UpdateSurveyResultWorkFormRequest = (function() {

                /**
                 * Properties of an UpdateSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateSurveyResultWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyResultWorkForm|null} [surveyResultWorkForm] UpdateSurveyResultWorkFormRequest surveyResultWorkForm
                 */

                /**
                 * Constructs a new UpdateSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateSurveyResultWorkFormRequest.
                 * @implements IUpdateSurveyResultWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateSurveyResultWorkFormRequest=} [properties] Properties to set
                 */
                function UpdateSurveyResultWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateSurveyResultWorkFormRequest surveyResultWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyResultWorkForm|null|undefined} surveyResultWorkForm
                 * @memberof mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest
                 * @instance
                 */
                UpdateSurveyResultWorkFormRequest.prototype.surveyResultWorkForm = null;

                /**
                 * Verifies an UpdateSurveyResultWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateSurveyResultWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyResultWorkForm.verify(message.surveyResultWorkForm);
                        if (error)
                            return "surveyResultWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateSurveyResultWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest} UpdateSurveyResultWorkFormRequest
                 */
                UpdateSurveyResultWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest();
                    if (object.surveyResultWorkForm != null) {
                        if (typeof object.surveyResultWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest.surveyResultWorkForm: object expected");
                        message.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.fromObject(object.surveyResultWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateSurveyResultWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest} message UpdateSurveyResultWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateSurveyResultWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultWorkForm = null;
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm"))
                        object.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.toObject(message.surveyResultWorkForm, options);
                    return object;
                };

                /**
                 * Converts this UpdateSurveyResultWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateSurveyResultWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateSurveyResultWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateSurveyResultWorkFormRequest;
            })();

            survey.DeleteSurveyResultWorkFormRequest = (function() {

                /**
                 * Properties of a DeleteSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IDeleteSurveyResultWorkFormRequest
                 * @property {mtechnavi.api.survey.ISurveyResultWorkForm|null} [surveyResultWorkForm] DeleteSurveyResultWorkFormRequest surveyResultWorkForm
                 */

                /**
                 * Constructs a new DeleteSurveyResultWorkFormRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a DeleteSurveyResultWorkFormRequest.
                 * @implements IDeleteSurveyResultWorkFormRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IDeleteSurveyResultWorkFormRequest=} [properties] Properties to set
                 */
                function DeleteSurveyResultWorkFormRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteSurveyResultWorkFormRequest surveyResultWorkForm.
                 * @member {mtechnavi.api.survey.ISurveyResultWorkForm|null|undefined} surveyResultWorkForm
                 * @memberof mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest
                 * @instance
                 */
                DeleteSurveyResultWorkFormRequest.prototype.surveyResultWorkForm = null;

                /**
                 * Verifies a DeleteSurveyResultWorkFormRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteSurveyResultWorkFormRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm")) {
                        let error = $root.mtechnavi.api.survey.SurveyResultWorkForm.verify(message.surveyResultWorkForm);
                        if (error)
                            return "surveyResultWorkForm." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteSurveyResultWorkFormRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest} DeleteSurveyResultWorkFormRequest
                 */
                DeleteSurveyResultWorkFormRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest();
                    if (object.surveyResultWorkForm != null) {
                        if (typeof object.surveyResultWorkForm !== "object")
                            throw TypeError(".mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest.surveyResultWorkForm: object expected");
                        message.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.fromObject(object.surveyResultWorkForm);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteSurveyResultWorkFormRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest
                 * @static
                 * @param {mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest} message DeleteSurveyResultWorkFormRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteSurveyResultWorkFormRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyResultWorkForm = null;
                    if (message.surveyResultWorkForm != null && message.hasOwnProperty("surveyResultWorkForm"))
                        object.surveyResultWorkForm = $root.mtechnavi.api.survey.SurveyResultWorkForm.toObject(message.surveyResultWorkForm, options);
                    return object;
                };

                /**
                 * Converts this DeleteSurveyResultWorkFormRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.DeleteSurveyResultWorkFormRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteSurveyResultWorkFormRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteSurveyResultWorkFormRequest;
            })();

            survey.ImportProxySurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of an ImportProxySurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IImportProxySurveyResultWorkFormsRequest
                 * @property {string|null} [url] ImportProxySurveyResultWorkFormsRequest url
                 * @property {boolean|null} [exportError] ImportProxySurveyResultWorkFormsRequest exportError
                 */

                /**
                 * Constructs a new ImportProxySurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ImportProxySurveyResultWorkFormsRequest.
                 * @implements IImportProxySurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IImportProxySurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function ImportProxySurveyResultWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportProxySurveyResultWorkFormsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @instance
                 */
                ImportProxySurveyResultWorkFormsRequest.prototype.url = "";

                /**
                 * ImportProxySurveyResultWorkFormsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @instance
                 */
                ImportProxySurveyResultWorkFormsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportProxySurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportProxySurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportProxySurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest} ImportProxySurveyResultWorkFormsRequest
                 */
                ImportProxySurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportProxySurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest} message ImportProxySurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportProxySurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportProxySurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportProxySurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportProxySurveyResultWorkFormsRequest;
            })();

            survey.ImportProxySurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of an ImportProxySurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IImportProxySurveyResultWorkFormsResponse
                 * @property {string|null} [assetId] ImportProxySurveyResultWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ImportProxySurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ImportProxySurveyResultWorkFormsResponse.
                 * @implements IImportProxySurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IImportProxySurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function ImportProxySurveyResultWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportProxySurveyResultWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse
                 * @instance
                 */
                ImportProxySurveyResultWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportProxySurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportProxySurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportProxySurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse} ImportProxySurveyResultWorkFormsResponse
                 */
                ImportProxySurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportProxySurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse} message ImportProxySurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportProxySurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportProxySurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ImportProxySurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportProxySurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportProxySurveyResultWorkFormsResponse;
            })();

            survey.ExportProxySurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of an ExportProxySurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportProxySurveyResultWorkFormsRequest
                 * @property {string|null} [surveyRequestId] ExportProxySurveyResultWorkFormsRequest surveyRequestId
                 */

                /**
                 * Constructs a new ExportProxySurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportProxySurveyResultWorkFormsRequest.
                 * @implements IExportProxySurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportProxySurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function ExportProxySurveyResultWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportProxySurveyResultWorkFormsRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest
                 * @instance
                 */
                ExportProxySurveyResultWorkFormsRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies an ExportProxySurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportProxySurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportProxySurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest} ExportProxySurveyResultWorkFormsRequest
                 */
                ExportProxySurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportProxySurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest} message ExportProxySurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportProxySurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this ExportProxySurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportProxySurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportProxySurveyResultWorkFormsRequest;
            })();

            survey.ExportProxySurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of an ExportProxySurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportProxySurveyResultWorkFormsResponse
                 * @property {string|null} [assetId] ExportProxySurveyResultWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ExportProxySurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportProxySurveyResultWorkFormsResponse.
                 * @implements IExportProxySurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportProxySurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function ExportProxySurveyResultWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportProxySurveyResultWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse
                 * @instance
                 */
                ExportProxySurveyResultWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportProxySurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportProxySurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportProxySurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse} ExportProxySurveyResultWorkFormsResponse
                 */
                ExportProxySurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportProxySurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse} message ExportProxySurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportProxySurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportProxySurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportProxySurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportProxySurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportProxySurveyResultWorkFormsResponse;
            })();

            survey.SharedListSurveyRequestWorkFormsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyRequestWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyRequestWorkFormsRequest
                 * @property {string|null} [pageToken] SharedListSurveyRequestWorkFormsRequest pageToken
                 * @property {Array.<string>|null} [formIds] SharedListSurveyRequestWorkFormsRequest formIds
                 */

                /**
                 * Constructs a new SharedListSurveyRequestWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyRequestWorkFormsRequest.
                 * @implements ISharedListSurveyRequestWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyRequestWorkFormsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyRequestWorkFormsRequest(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyRequestWorkFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @instance
                 */
                SharedListSurveyRequestWorkFormsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyRequestWorkFormsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @instance
                 */
                SharedListSurveyRequestWorkFormsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyRequestWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyRequestWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyRequestWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest} SharedListSurveyRequestWorkFormsRequest
                 */
                SharedListSurveyRequestWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyRequestWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest} message SharedListSurveyRequestWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyRequestWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyRequestWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyRequestWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyRequestWorkFormsRequest;
            })();

            survey.SharedListSurveyRequestWorkFormsResponse = (function() {

                /**
                 * Properties of a SharedListSurveyRequestWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyRequestWorkFormsResponse
                 * @property {Long|null} [total] SharedListSurveyRequestWorkFormsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyRequestWorkForm>|null} [items] SharedListSurveyRequestWorkFormsResponse items
                 * @property {string|null} [pageToken] SharedListSurveyRequestWorkFormsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyRequestWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyRequestWorkFormsResponse.
                 * @implements ISharedListSurveyRequestWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyRequestWorkFormsResponse=} [properties] Properties to set
                 */
                function SharedListSurveyRequestWorkFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyRequestWorkFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyRequestWorkFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyRequestWorkFormsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyRequestWorkForm>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyRequestWorkFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyRequestWorkFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyRequestWorkFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyRequestWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyRequestWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyRequestWorkForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyRequestWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse} SharedListSurveyRequestWorkFormsResponse
                 */
                SharedListSurveyRequestWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyRequestWorkForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyRequestWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse} message SharedListSurveyRequestWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyRequestWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyRequestWorkForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyRequestWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyRequestWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyRequestWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyRequestWorkFormsResponse;
            })();

            survey.SharedListSurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of a SharedListSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyResultWorkFormsRequest
                 * @property {string|null} [pageToken] SharedListSurveyResultWorkFormsRequest pageToken
                 * @property {Array.<string>|null} [formIds] SharedListSurveyResultWorkFormsRequest formIds
                 */

                /**
                 * Constructs a new SharedListSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyResultWorkFormsRequest.
                 * @implements ISharedListSurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function SharedListSurveyResultWorkFormsRequest(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyResultWorkFormsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @instance
                 */
                SharedListSurveyResultWorkFormsRequest.prototype.pageToken = "";

                /**
                 * SharedListSurveyResultWorkFormsRequest formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @instance
                 */
                SharedListSurveyResultWorkFormsRequest.prototype.formIds = $util.emptyArray;

                /**
                 * Verifies a SharedListSurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a SharedListSurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest} SharedListSurveyResultWorkFormsRequest
                 */
                SharedListSurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest} message SharedListSurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SharedListSurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyResultWorkFormsRequest;
            })();

            survey.SharedListSurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of a SharedListSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface ISharedListSurveyResultWorkFormsResponse
                 * @property {Long|null} [total] SharedListSurveyResultWorkFormsResponse total
                 * @property {Array.<mtechnavi.api.survey.ISurveyResultWorkForm>|null} [items] SharedListSurveyResultWorkFormsResponse items
                 * @property {string|null} [pageToken] SharedListSurveyResultWorkFormsResponse pageToken
                 */

                /**
                 * Constructs a new SharedListSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SharedListSurveyResultWorkFormsResponse.
                 * @implements ISharedListSurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.ISharedListSurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function SharedListSurveyResultWorkFormsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SharedListSurveyResultWorkFormsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyResultWorkFormsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SharedListSurveyResultWorkFormsResponse items.
                 * @member {Array.<mtechnavi.api.survey.ISurveyResultWorkForm>} items
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyResultWorkFormsResponse.prototype.items = $util.emptyArray;

                /**
                 * SharedListSurveyResultWorkFormsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @instance
                 */
                SharedListSurveyResultWorkFormsResponse.prototype.pageToken = "";

                /**
                 * Verifies a SharedListSurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SharedListSurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.SurveyResultWorkForm.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a SharedListSurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse} SharedListSurveyResultWorkFormsResponse
                 */
                SharedListSurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.SurveyResultWorkForm.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SharedListSurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse} message SharedListSurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SharedListSurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.SurveyResultWorkForm.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this SharedListSurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SharedListSurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SharedListSurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SharedListSurveyResultWorkFormsResponse;
            })();

            survey.ImportSurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of an ImportSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IImportSurveyResultWorkFormsRequest
                 * @property {string|null} [url] ImportSurveyResultWorkFormsRequest url
                 * @property {boolean|null} [exportError] ImportSurveyResultWorkFormsRequest exportError
                 */

                /**
                 * Constructs a new ImportSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ImportSurveyResultWorkFormsRequest.
                 * @implements IImportSurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IImportSurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function ImportSurveyResultWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportSurveyResultWorkFormsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @instance
                 */
                ImportSurveyResultWorkFormsRequest.prototype.url = "";

                /**
                 * ImportSurveyResultWorkFormsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @instance
                 */
                ImportSurveyResultWorkFormsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportSurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportSurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportSurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest} ImportSurveyResultWorkFormsRequest
                 */
                ImportSurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportSurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest} message ImportSurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportSurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportSurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportSurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportSurveyResultWorkFormsRequest;
            })();

            survey.ImportSurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of an ImportSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IImportSurveyResultWorkFormsResponse
                 * @property {string|null} [assetId] ImportSurveyResultWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ImportSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ImportSurveyResultWorkFormsResponse.
                 * @implements IImportSurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IImportSurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function ImportSurveyResultWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportSurveyResultWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse
                 * @instance
                 */
                ImportSurveyResultWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportSurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportSurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportSurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse} ImportSurveyResultWorkFormsResponse
                 */
                ImportSurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportSurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse} message ImportSurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportSurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportSurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ImportSurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportSurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportSurveyResultWorkFormsResponse;
            })();

            survey.ExportSurveyResultWorkFormsRequest = (function() {

                /**
                 * Properties of an ExportSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyResultWorkFormsRequest
                 * @property {string|null} [surveyRequestId] ExportSurveyResultWorkFormsRequest surveyRequestId
                 */

                /**
                 * Constructs a new ExportSurveyResultWorkFormsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyResultWorkFormsRequest.
                 * @implements IExportSurveyResultWorkFormsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyResultWorkFormsRequest=} [properties] Properties to set
                 */
                function ExportSurveyResultWorkFormsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyResultWorkFormsRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest
                 * @instance
                 */
                ExportSurveyResultWorkFormsRequest.prototype.surveyRequestId = "";

                /**
                 * Verifies an ExportSurveyResultWorkFormsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyResultWorkFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSurveyResultWorkFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest} ExportSurveyResultWorkFormsRequest
                 */
                ExportSurveyResultWorkFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyResultWorkFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest} message ExportSurveyResultWorkFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyResultWorkFormsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.surveyRequestId = "";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    return object;
                };

                /**
                 * Converts this ExportSurveyResultWorkFormsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyResultWorkFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSurveyResultWorkFormsRequest;
            })();

            survey.ExportSurveyResultWorkFormsResponse = (function() {

                /**
                 * Properties of an ExportSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyResultWorkFormsResponse
                 * @property {string|null} [assetId] ExportSurveyResultWorkFormsResponse assetId
                 */

                /**
                 * Constructs a new ExportSurveyResultWorkFormsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyResultWorkFormsResponse.
                 * @implements IExportSurveyResultWorkFormsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyResultWorkFormsResponse=} [properties] Properties to set
                 */
                function ExportSurveyResultWorkFormsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyResultWorkFormsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse
                 * @instance
                 */
                ExportSurveyResultWorkFormsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportSurveyResultWorkFormsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyResultWorkFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSurveyResultWorkFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse} ExportSurveyResultWorkFormsResponse
                 */
                ExportSurveyResultWorkFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyResultWorkFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse} message ExportSurveyResultWorkFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyResultWorkFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportSurveyResultWorkFormsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyResultWorkFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyResultWorkFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSurveyResultWorkFormsResponse;
            })();

            survey.CreateResultFormShareManagementRequest = (function() {

                /**
                 * Properties of a CreateResultFormShareManagementRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ICreateResultFormShareManagementRequest
                 * @property {mtechnavi.api.survey.IResultFormShareManagement|null} [resultFormShareManagement] CreateResultFormShareManagementRequest resultFormShareManagement
                 * @property {string|null} [surveyReceptionId] CreateResultFormShareManagementRequest surveyReceptionId
                 */

                /**
                 * Constructs a new CreateResultFormShareManagementRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a CreateResultFormShareManagementRequest.
                 * @implements ICreateResultFormShareManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ICreateResultFormShareManagementRequest=} [properties] Properties to set
                 */
                function CreateResultFormShareManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateResultFormShareManagementRequest resultFormShareManagement.
                 * @member {mtechnavi.api.survey.IResultFormShareManagement|null|undefined} resultFormShareManagement
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @instance
                 */
                CreateResultFormShareManagementRequest.prototype.resultFormShareManagement = null;

                /**
                 * CreateResultFormShareManagementRequest surveyReceptionId.
                 * @member {string} surveyReceptionId
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @instance
                 */
                CreateResultFormShareManagementRequest.prototype.surveyReceptionId = "";

                /**
                 * Verifies a CreateResultFormShareManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateResultFormShareManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.resultFormShareManagement != null && message.hasOwnProperty("resultFormShareManagement")) {
                        let error = $root.mtechnavi.api.survey.ResultFormShareManagement.verify(message.resultFormShareManagement);
                        if (error)
                            return "resultFormShareManagement." + error;
                    }
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        if (!$util.isString(message.surveyReceptionId))
                            return "surveyReceptionId: string expected";
                    return null;
                };

                /**
                 * Creates a CreateResultFormShareManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.CreateResultFormShareManagementRequest} CreateResultFormShareManagementRequest
                 */
                CreateResultFormShareManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.CreateResultFormShareManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.CreateResultFormShareManagementRequest();
                    if (object.resultFormShareManagement != null) {
                        if (typeof object.resultFormShareManagement !== "object")
                            throw TypeError(".mtechnavi.api.survey.CreateResultFormShareManagementRequest.resultFormShareManagement: object expected");
                        message.resultFormShareManagement = $root.mtechnavi.api.survey.ResultFormShareManagement.fromObject(object.resultFormShareManagement);
                    }
                    if (object.surveyReceptionId != null)
                        message.surveyReceptionId = String(object.surveyReceptionId);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateResultFormShareManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @static
                 * @param {mtechnavi.api.survey.CreateResultFormShareManagementRequest} message CreateResultFormShareManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateResultFormShareManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.resultFormShareManagement = null;
                        object.surveyReceptionId = "";
                    }
                    if (message.resultFormShareManagement != null && message.hasOwnProperty("resultFormShareManagement"))
                        object.resultFormShareManagement = $root.mtechnavi.api.survey.ResultFormShareManagement.toObject(message.resultFormShareManagement, options);
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        object.surveyReceptionId = message.surveyReceptionId;
                    return object;
                };

                /**
                 * Converts this CreateResultFormShareManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.CreateResultFormShareManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateResultFormShareManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateResultFormShareManagementRequest;
            })();

            survey.ListResultFormShareManagementsRequest = (function() {

                /**
                 * Properties of a ListResultFormShareManagementsRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IListResultFormShareManagementsRequest
                 * @property {string|null} [pageToken] ListResultFormShareManagementsRequest pageToken
                 * @property {Array.<string>|null} [surveyReceptionIds] ListResultFormShareManagementsRequest surveyReceptionIds
                 */

                /**
                 * Constructs a new ListResultFormShareManagementsRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListResultFormShareManagementsRequest.
                 * @implements IListResultFormShareManagementsRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IListResultFormShareManagementsRequest=} [properties] Properties to set
                 */
                function ListResultFormShareManagementsRequest(properties) {
                    this.surveyReceptionIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListResultFormShareManagementsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @instance
                 */
                ListResultFormShareManagementsRequest.prototype.pageToken = "";

                /**
                 * ListResultFormShareManagementsRequest surveyReceptionIds.
                 * @member {Array.<string>} surveyReceptionIds
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @instance
                 */
                ListResultFormShareManagementsRequest.prototype.surveyReceptionIds = $util.emptyArray;

                /**
                 * Verifies a ListResultFormShareManagementsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListResultFormShareManagementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.surveyReceptionIds != null && message.hasOwnProperty("surveyReceptionIds")) {
                        if (!Array.isArray(message.surveyReceptionIds))
                            return "surveyReceptionIds: array expected";
                        for (let i = 0; i < message.surveyReceptionIds.length; ++i)
                            if (!$util.isString(message.surveyReceptionIds[i]))
                                return "surveyReceptionIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListResultFormShareManagementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListResultFormShareManagementsRequest} ListResultFormShareManagementsRequest
                 */
                ListResultFormShareManagementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListResultFormShareManagementsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListResultFormShareManagementsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.surveyReceptionIds) {
                        if (!Array.isArray(object.surveyReceptionIds))
                            throw TypeError(".mtechnavi.api.survey.ListResultFormShareManagementsRequest.surveyReceptionIds: array expected");
                        message.surveyReceptionIds = [];
                        for (let i = 0; i < object.surveyReceptionIds.length; ++i)
                            message.surveyReceptionIds[i] = String(object.surveyReceptionIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListResultFormShareManagementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @static
                 * @param {mtechnavi.api.survey.ListResultFormShareManagementsRequest} message ListResultFormShareManagementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListResultFormShareManagementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.surveyReceptionIds = [];
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.surveyReceptionIds && message.surveyReceptionIds.length) {
                        object.surveyReceptionIds = [];
                        for (let j = 0; j < message.surveyReceptionIds.length; ++j)
                            object.surveyReceptionIds[j] = message.surveyReceptionIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListResultFormShareManagementsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListResultFormShareManagementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListResultFormShareManagementsRequest;
            })();

            survey.ListResultFormShareManagementsResponse = (function() {

                /**
                 * Properties of a ListResultFormShareManagementsResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IListResultFormShareManagementsResponse
                 * @property {Long|null} [total] ListResultFormShareManagementsResponse total
                 * @property {Array.<mtechnavi.api.survey.IResultFormShareManagement>|null} [items] ListResultFormShareManagementsResponse items
                 * @property {string|null} [pageToken] ListResultFormShareManagementsResponse pageToken
                 */

                /**
                 * Constructs a new ListResultFormShareManagementsResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ListResultFormShareManagementsResponse.
                 * @implements IListResultFormShareManagementsResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IListResultFormShareManagementsResponse=} [properties] Properties to set
                 */
                function ListResultFormShareManagementsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListResultFormShareManagementsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @instance
                 */
                ListResultFormShareManagementsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListResultFormShareManagementsResponse items.
                 * @member {Array.<mtechnavi.api.survey.IResultFormShareManagement>} items
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @instance
                 */
                ListResultFormShareManagementsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListResultFormShareManagementsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @instance
                 */
                ListResultFormShareManagementsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListResultFormShareManagementsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListResultFormShareManagementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.survey.ResultFormShareManagement.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListResultFormShareManagementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ListResultFormShareManagementsResponse} ListResultFormShareManagementsResponse
                 */
                ListResultFormShareManagementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ListResultFormShareManagementsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ListResultFormShareManagementsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.survey.ListResultFormShareManagementsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ListResultFormShareManagementsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.survey.ResultFormShareManagement.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListResultFormShareManagementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @static
                 * @param {mtechnavi.api.survey.ListResultFormShareManagementsResponse} message ListResultFormShareManagementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListResultFormShareManagementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.survey.ResultFormShareManagement.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListResultFormShareManagementsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ListResultFormShareManagementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListResultFormShareManagementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListResultFormShareManagementsResponse;
            })();

            survey.UpdateResultFormShareManagementRequest = (function() {

                /**
                 * Properties of an UpdateResultFormShareManagementRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IUpdateResultFormShareManagementRequest
                 * @property {mtechnavi.api.survey.IResultFormShareManagement|null} [resultFormShareManagement] UpdateResultFormShareManagementRequest resultFormShareManagement
                 */

                /**
                 * Constructs a new UpdateResultFormShareManagementRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an UpdateResultFormShareManagementRequest.
                 * @implements IUpdateResultFormShareManagementRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IUpdateResultFormShareManagementRequest=} [properties] Properties to set
                 */
                function UpdateResultFormShareManagementRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateResultFormShareManagementRequest resultFormShareManagement.
                 * @member {mtechnavi.api.survey.IResultFormShareManagement|null|undefined} resultFormShareManagement
                 * @memberof mtechnavi.api.survey.UpdateResultFormShareManagementRequest
                 * @instance
                 */
                UpdateResultFormShareManagementRequest.prototype.resultFormShareManagement = null;

                /**
                 * Verifies an UpdateResultFormShareManagementRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.UpdateResultFormShareManagementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateResultFormShareManagementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.resultFormShareManagement != null && message.hasOwnProperty("resultFormShareManagement")) {
                        let error = $root.mtechnavi.api.survey.ResultFormShareManagement.verify(message.resultFormShareManagement);
                        if (error)
                            return "resultFormShareManagement." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateResultFormShareManagementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.UpdateResultFormShareManagementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.UpdateResultFormShareManagementRequest} UpdateResultFormShareManagementRequest
                 */
                UpdateResultFormShareManagementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.UpdateResultFormShareManagementRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.UpdateResultFormShareManagementRequest();
                    if (object.resultFormShareManagement != null) {
                        if (typeof object.resultFormShareManagement !== "object")
                            throw TypeError(".mtechnavi.api.survey.UpdateResultFormShareManagementRequest.resultFormShareManagement: object expected");
                        message.resultFormShareManagement = $root.mtechnavi.api.survey.ResultFormShareManagement.fromObject(object.resultFormShareManagement);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateResultFormShareManagementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.UpdateResultFormShareManagementRequest
                 * @static
                 * @param {mtechnavi.api.survey.UpdateResultFormShareManagementRequest} message UpdateResultFormShareManagementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateResultFormShareManagementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.resultFormShareManagement = null;
                    if (message.resultFormShareManagement != null && message.hasOwnProperty("resultFormShareManagement"))
                        object.resultFormShareManagement = $root.mtechnavi.api.survey.ResultFormShareManagement.toObject(message.resultFormShareManagement, options);
                    return object;
                };

                /**
                 * Converts this UpdateResultFormShareManagementRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.UpdateResultFormShareManagementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateResultFormShareManagementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateResultFormShareManagementRequest;
            })();

            survey.SurveyRequestCatalog = (function() {

                /**
                 * Properties of a SurveyRequestCatalog.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequestCatalog
                 * @property {string|null} [surveyRequestCatalogId] SurveyRequestCatalog surveyRequestCatalogId
                 * @property {string|null} [code] SurveyRequestCatalog code
                 * @property {string|null} [displayName] SurveyRequestCatalog displayName
                 * @property {sharelib.INameOption|null} [category] SurveyRequestCatalog category
                 * @property {string|null} [guidance] SurveyRequestCatalog guidance
                 * @property {boolean|null} [approval] SurveyRequestCatalog approval
                 * @property {sharelib.INameOption|null} [completeCondition] SurveyRequestCatalog completeCondition
                 * @property {boolean|null} [partialReply] SurveyRequestCatalog partialReply
                 * @property {boolean|null} [decline] SurveyRequestCatalog decline
                 * @property {Array.<mtechnavi.api.survey.IAutoReminderSetting>|null} [autoReminderSettings] SurveyRequestCatalog autoReminderSettings
                 * @property {Array.<string>|null} [requesters] SurveyRequestCatalog requesters
                 * @property {Array.<sharelib.IAttachment>|null} [commonAttachments] SurveyRequestCatalog commonAttachments
                 * @property {boolean|null} [requiredAttachment] SurveyRequestCatalog requiredAttachment
                 * @property {boolean|null} [requestFormUsable] SurveyRequestCatalog requestFormUsable
                 * @property {boolean|null} [resultFormUsable] SurveyRequestCatalog resultFormUsable
                 * @property {Long|null} [surveyRequestCatalogUpdatedAt] SurveyRequestCatalog surveyRequestCatalogUpdatedAt
                 * @property {sharelib.IEmbeddedUser|null} [surveyRequestCatalogUpdatedBy] SurveyRequestCatalog surveyRequestCatalogUpdatedBy
                 * @property {Long|null} [createdAt] SurveyRequestCatalog createdAt
                 * @property {Long|null} [updatedAt] SurveyRequestCatalog updatedAt
                 * @property {Long|null} [deletedAt] SurveyRequestCatalog deletedAt
                 */

                /**
                 * Constructs a new SurveyRequestCatalog.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequestCatalog.
                 * @implements ISurveyRequestCatalog
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequestCatalog=} [properties] Properties to set
                 */
                function SurveyRequestCatalog(properties) {
                    this.autoReminderSettings = [];
                    this.requesters = [];
                    this.commonAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestCatalog surveyRequestCatalogId.
                 * @member {string} surveyRequestCatalogId
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.surveyRequestCatalogId = "";

                /**
                 * SurveyRequestCatalog code.
                 * @member {string} code
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.code = "";

                /**
                 * SurveyRequestCatalog displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.displayName = "";

                /**
                 * SurveyRequestCatalog category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.category = null;

                /**
                 * SurveyRequestCatalog guidance.
                 * @member {string} guidance
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.guidance = "";

                /**
                 * SurveyRequestCatalog approval.
                 * @member {boolean} approval
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.approval = false;

                /**
                 * SurveyRequestCatalog completeCondition.
                 * @member {sharelib.INameOption|null|undefined} completeCondition
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.completeCondition = null;

                /**
                 * SurveyRequestCatalog partialReply.
                 * @member {boolean} partialReply
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.partialReply = false;

                /**
                 * SurveyRequestCatalog decline.
                 * @member {boolean} decline
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.decline = false;

                /**
                 * SurveyRequestCatalog autoReminderSettings.
                 * @member {Array.<mtechnavi.api.survey.IAutoReminderSetting>} autoReminderSettings
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.autoReminderSettings = $util.emptyArray;

                /**
                 * SurveyRequestCatalog requesters.
                 * @member {Array.<string>} requesters
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.requesters = $util.emptyArray;

                /**
                 * SurveyRequestCatalog commonAttachments.
                 * @member {Array.<sharelib.IAttachment>} commonAttachments
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.commonAttachments = $util.emptyArray;

                /**
                 * SurveyRequestCatalog requiredAttachment.
                 * @member {boolean} requiredAttachment
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.requiredAttachment = false;

                /**
                 * SurveyRequestCatalog requestFormUsable.
                 * @member {boolean} requestFormUsable
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.requestFormUsable = false;

                /**
                 * SurveyRequestCatalog resultFormUsable.
                 * @member {boolean} resultFormUsable
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.resultFormUsable = false;

                /**
                 * SurveyRequestCatalog surveyRequestCatalogUpdatedAt.
                 * @member {Long} surveyRequestCatalogUpdatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.surveyRequestCatalogUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestCatalog surveyRequestCatalogUpdatedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} surveyRequestCatalogUpdatedBy
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.surveyRequestCatalogUpdatedBy = null;

                /**
                 * SurveyRequestCatalog createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestCatalog updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestCatalog deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 */
                SurveyRequestCatalog.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequestCatalog message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestCatalog.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        if (!$util.isString(message.surveyRequestCatalogId))
                            return "surveyRequestCatalogId: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        if (!$util.isString(message.guidance))
                            return "guidance: string expected";
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        if (typeof message.approval !== "boolean")
                            return "approval: boolean expected";
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition")) {
                        let error = $root.sharelib.NameOption.verify(message.completeCondition);
                        if (error)
                            return "completeCondition." + error;
                    }
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        if (typeof message.partialReply !== "boolean")
                            return "partialReply: boolean expected";
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        if (typeof message.decline !== "boolean")
                            return "decline: boolean expected";
                    if (message.autoReminderSettings != null && message.hasOwnProperty("autoReminderSettings")) {
                        if (!Array.isArray(message.autoReminderSettings))
                            return "autoReminderSettings: array expected";
                        for (let i = 0; i < message.autoReminderSettings.length; ++i) {
                            let error = $root.mtechnavi.api.survey.AutoReminderSetting.verify(message.autoReminderSettings[i]);
                            if (error)
                                return "autoReminderSettings." + error;
                        }
                    }
                    if (message.requesters != null && message.hasOwnProperty("requesters")) {
                        if (!Array.isArray(message.requesters))
                            return "requesters: array expected";
                        for (let i = 0; i < message.requesters.length; ++i)
                            if (!$util.isString(message.requesters[i]))
                                return "requesters: string[] expected";
                    }
                    if (message.commonAttachments != null && message.hasOwnProperty("commonAttachments")) {
                        if (!Array.isArray(message.commonAttachments))
                            return "commonAttachments: array expected";
                        for (let i = 0; i < message.commonAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.commonAttachments[i]);
                            if (error)
                                return "commonAttachments." + error;
                        }
                    }
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        if (typeof message.requiredAttachment !== "boolean")
                            return "requiredAttachment: boolean expected";
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        if (typeof message.requestFormUsable !== "boolean")
                            return "requestFormUsable: boolean expected";
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        if (typeof message.resultFormUsable !== "boolean")
                            return "resultFormUsable: boolean expected";
                    if (message.surveyRequestCatalogUpdatedAt != null && message.hasOwnProperty("surveyRequestCatalogUpdatedAt"))
                        if (!$util.isInteger(message.surveyRequestCatalogUpdatedAt) && !(message.surveyRequestCatalogUpdatedAt && $util.isInteger(message.surveyRequestCatalogUpdatedAt.low) && $util.isInteger(message.surveyRequestCatalogUpdatedAt.high)))
                            return "surveyRequestCatalogUpdatedAt: integer|Long expected";
                    if (message.surveyRequestCatalogUpdatedBy != null && message.hasOwnProperty("surveyRequestCatalogUpdatedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.surveyRequestCatalogUpdatedBy);
                        if (error)
                            return "surveyRequestCatalogUpdatedBy." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestCatalog message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequestCatalog} SurveyRequestCatalog
                 */
                SurveyRequestCatalog.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequestCatalog)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequestCatalog();
                    if (object.surveyRequestCatalogId != null)
                        message.surveyRequestCatalogId = String(object.surveyRequestCatalogId);
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.guidance != null)
                        message.guidance = String(object.guidance);
                    if (object.approval != null)
                        message.approval = Boolean(object.approval);
                    if (object.completeCondition != null) {
                        if (typeof object.completeCondition !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.completeCondition: object expected");
                        message.completeCondition = $root.sharelib.NameOption.fromObject(object.completeCondition);
                    }
                    if (object.partialReply != null)
                        message.partialReply = Boolean(object.partialReply);
                    if (object.decline != null)
                        message.decline = Boolean(object.decline);
                    if (object.autoReminderSettings) {
                        if (!Array.isArray(object.autoReminderSettings))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.autoReminderSettings: array expected");
                        message.autoReminderSettings = [];
                        for (let i = 0; i < object.autoReminderSettings.length; ++i) {
                            if (typeof object.autoReminderSettings[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.autoReminderSettings: object expected");
                            message.autoReminderSettings[i] = $root.mtechnavi.api.survey.AutoReminderSetting.fromObject(object.autoReminderSettings[i]);
                        }
                    }
                    if (object.requesters) {
                        if (!Array.isArray(object.requesters))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.requesters: array expected");
                        message.requesters = [];
                        for (let i = 0; i < object.requesters.length; ++i)
                            message.requesters[i] = String(object.requesters[i]);
                    }
                    if (object.commonAttachments) {
                        if (!Array.isArray(object.commonAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.commonAttachments: array expected");
                        message.commonAttachments = [];
                        for (let i = 0; i < object.commonAttachments.length; ++i) {
                            if (typeof object.commonAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.commonAttachments: object expected");
                            message.commonAttachments[i] = $root.sharelib.Attachment.fromObject(object.commonAttachments[i]);
                        }
                    }
                    if (object.requiredAttachment != null)
                        message.requiredAttachment = Boolean(object.requiredAttachment);
                    if (object.requestFormUsable != null)
                        message.requestFormUsable = Boolean(object.requestFormUsable);
                    if (object.resultFormUsable != null)
                        message.resultFormUsable = Boolean(object.resultFormUsable);
                    if (object.surveyRequestCatalogUpdatedAt != null)
                        if ($util.Long)
                            (message.surveyRequestCatalogUpdatedAt = $util.Long.fromValue(object.surveyRequestCatalogUpdatedAt)).unsigned = false;
                        else if (typeof object.surveyRequestCatalogUpdatedAt === "string")
                            message.surveyRequestCatalogUpdatedAt = parseInt(object.surveyRequestCatalogUpdatedAt, 10);
                        else if (typeof object.surveyRequestCatalogUpdatedAt === "number")
                            message.surveyRequestCatalogUpdatedAt = object.surveyRequestCatalogUpdatedAt;
                        else if (typeof object.surveyRequestCatalogUpdatedAt === "object")
                            message.surveyRequestCatalogUpdatedAt = new $util.LongBits(object.surveyRequestCatalogUpdatedAt.low >>> 0, object.surveyRequestCatalogUpdatedAt.high >>> 0).toNumber();
                    if (object.surveyRequestCatalogUpdatedBy != null) {
                        if (typeof object.surveyRequestCatalogUpdatedBy !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestCatalog.surveyRequestCatalogUpdatedBy: object expected");
                        message.surveyRequestCatalogUpdatedBy = $root.sharelib.EmbeddedUser.fromObject(object.surveyRequestCatalogUpdatedBy);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestCatalog message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequestCatalog} message SurveyRequestCatalog
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestCatalog.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.autoReminderSettings = [];
                        object.requesters = [];
                        object.commonAttachments = [];
                    }
                    if (options.defaults) {
                        object.surveyRequestCatalogId = "";
                        object.code = "";
                        object.displayName = "";
                        object.category = null;
                        object.guidance = "";
                        object.approval = false;
                        object.completeCondition = null;
                        object.partialReply = false;
                        object.decline = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestCatalogUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestCatalogUpdatedAt = options.longs === String ? "0" : 0;
                        object.surveyRequestCatalogUpdatedBy = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.requestFormUsable = false;
                        object.resultFormUsable = false;
                        object.requiredAttachment = false;
                    }
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        object.surveyRequestCatalogId = message.surveyRequestCatalogId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        object.guidance = message.guidance;
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        object.approval = message.approval;
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition"))
                        object.completeCondition = $root.sharelib.NameOption.toObject(message.completeCondition, options);
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        object.partialReply = message.partialReply;
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        object.decline = message.decline;
                    if (message.autoReminderSettings && message.autoReminderSettings.length) {
                        object.autoReminderSettings = [];
                        for (let j = 0; j < message.autoReminderSettings.length; ++j)
                            object.autoReminderSettings[j] = $root.mtechnavi.api.survey.AutoReminderSetting.toObject(message.autoReminderSettings[j], options);
                    }
                    if (message.requesters && message.requesters.length) {
                        object.requesters = [];
                        for (let j = 0; j < message.requesters.length; ++j)
                            object.requesters[j] = message.requesters[j];
                    }
                    if (message.commonAttachments && message.commonAttachments.length) {
                        object.commonAttachments = [];
                        for (let j = 0; j < message.commonAttachments.length; ++j)
                            object.commonAttachments[j] = $root.sharelib.Attachment.toObject(message.commonAttachments[j], options);
                    }
                    if (message.surveyRequestCatalogUpdatedAt != null && message.hasOwnProperty("surveyRequestCatalogUpdatedAt"))
                        if (typeof message.surveyRequestCatalogUpdatedAt === "number")
                            object.surveyRequestCatalogUpdatedAt = options.longs === String ? String(message.surveyRequestCatalogUpdatedAt) : message.surveyRequestCatalogUpdatedAt;
                        else
                            object.surveyRequestCatalogUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestCatalogUpdatedAt) : options.longs === Number ? new $util.LongBits(message.surveyRequestCatalogUpdatedAt.low >>> 0, message.surveyRequestCatalogUpdatedAt.high >>> 0).toNumber() : message.surveyRequestCatalogUpdatedAt;
                    if (message.surveyRequestCatalogUpdatedBy != null && message.hasOwnProperty("surveyRequestCatalogUpdatedBy"))
                        object.surveyRequestCatalogUpdatedBy = $root.sharelib.EmbeddedUser.toObject(message.surveyRequestCatalogUpdatedBy, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        object.requestFormUsable = message.requestFormUsable;
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        object.resultFormUsable = message.resultFormUsable;
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        object.requiredAttachment = message.requiredAttachment;
                    return object;
                };

                /**
                 * Converts this SurveyRequestCatalog to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequestCatalog
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestCatalog.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestCatalog;
            })();

            survey.SurveyBaseRequest = (function() {

                /**
                 * Properties of a SurveyBaseRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyBaseRequest
                 * @property {string|null} [surveyBaseRequestId] SurveyBaseRequest surveyBaseRequestId
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] SurveyBaseRequest managementOrganization
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] SurveyBaseRequest systemNotificationUsers
                 * @property {boolean|null} [systemNotificationIncludeMainContact] SurveyBaseRequest systemNotificationIncludeMainContact
                 * @property {Long|null} [surveyRequestAutoName] SurveyBaseRequest surveyRequestAutoName
                 * @property {string|null} [displayName] SurveyBaseRequest displayName
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyBaseRequest replyDueDateDt
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] SurveyBaseRequest surveyRequestCatalog
                 * @property {string|null} [surveyRequestCatalogId] SurveyBaseRequest surveyRequestCatalogId
                 * @property {string|null} [guidance] SurveyBaseRequest guidance
                 * @property {boolean|null} [approval] SurveyBaseRequest approval
                 * @property {sharelib.INameOption|null} [completeCondition] SurveyBaseRequest completeCondition
                 * @property {boolean|null} [partialReply] SurveyBaseRequest partialReply
                 * @property {boolean|null} [decline] SurveyBaseRequest decline
                 * @property {Array.<mtechnavi.api.survey.IAutoReminderSetting>|null} [autoReminderSettings] SurveyBaseRequest autoReminderSettings
                 * @property {Array.<string>|null} [requesters] SurveyBaseRequest requesters
                 * @property {Array.<sharelib.IAttachment>|null} [commonRequestAttachments] SurveyBaseRequest commonRequestAttachments
                 * @property {Array.<mtechnavi.api.survey.IAttachmentAttribute>|null} [businessUnitRequestAttachments] SurveyBaseRequest businessUnitRequestAttachments
                 * @property {boolean|null} [requiredAttachment] SurveyBaseRequest requiredAttachment
                 * @property {boolean|null} [requestFormUsable] SurveyBaseRequest requestFormUsable
                 * @property {boolean|null} [resultFormUsable] SurveyBaseRequest resultFormUsable
                 * @property {Array.<string>|null} [requestFormIds] SurveyBaseRequest requestFormIds
                 * @property {Array.<string>|null} [surveyResultWorkFormIds] SurveyBaseRequest surveyResultWorkFormIds
                 * @property {Array.<mtechnavi.api.survey.IBusinessUnitProperties>|null} [requestUnits] SurveyBaseRequest requestUnits
                 * @property {Long|null} [countRequestUnits] SurveyBaseRequest countRequestUnits
                 * @property {Long|null} [sendedAt] SurveyBaseRequest sendedAt
                 * @property {sharelib.INameOption|null} [approvalStatus] SurveyBaseRequest approvalStatus
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [issuerProperties] SurveyBaseRequest issuerProperties
                 * @property {sharelib.IApprovalRequest|null} [approvalRequest] SurveyBaseRequest approvalRequest
                 * @property {sharelib.IApprovalResult|null} [approvalResult] SurveyBaseRequest approvalResult
                 * @property {boolean|null} [completed] SurveyBaseRequest completed
                 * @property {Long|null} [surveyBaseRequestUpdatedAt] SurveyBaseRequest surveyBaseRequestUpdatedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyBaseRequest createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyBaseRequest updatedProperties
                 * @property {Long|null} [createdAt] SurveyBaseRequest createdAt
                 * @property {Long|null} [updatedAt] SurveyBaseRequest updatedAt
                 * @property {Long|null} [deletedAt] SurveyBaseRequest deletedAt
                 */

                /**
                 * Constructs a new SurveyBaseRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyBaseRequest.
                 * @implements ISurveyBaseRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyBaseRequest=} [properties] Properties to set
                 */
                function SurveyBaseRequest(properties) {
                    this.systemNotificationUsers = [];
                    this.autoReminderSettings = [];
                    this.requesters = [];
                    this.commonRequestAttachments = [];
                    this.businessUnitRequestAttachments = [];
                    this.requestFormIds = [];
                    this.surveyResultWorkFormIds = [];
                    this.requestUnits = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyBaseRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyBaseRequestId = "";

                /**
                 * SurveyBaseRequest managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.managementOrganization = null;

                /**
                 * SurveyBaseRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * SurveyBaseRequest systemNotificationIncludeMainContact.
                 * @member {boolean} systemNotificationIncludeMainContact
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.systemNotificationIncludeMainContact = false;

                /**
                 * SurveyBaseRequest surveyRequestAutoName.
                 * @member {Long} surveyRequestAutoName
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.displayName = "";

                /**
                 * SurveyBaseRequest replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.replyDueDateDt = null;

                /**
                 * SurveyBaseRequest surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyRequestCatalog = null;

                /**
                 * SurveyBaseRequest surveyRequestCatalogId.
                 * @member {string} surveyRequestCatalogId
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyRequestCatalogId = "";

                /**
                 * SurveyBaseRequest guidance.
                 * @member {string} guidance
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.guidance = "";

                /**
                 * SurveyBaseRequest approval.
                 * @member {boolean} approval
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.approval = false;

                /**
                 * SurveyBaseRequest completeCondition.
                 * @member {sharelib.INameOption|null|undefined} completeCondition
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.completeCondition = null;

                /**
                 * SurveyBaseRequest partialReply.
                 * @member {boolean} partialReply
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.partialReply = false;

                /**
                 * SurveyBaseRequest decline.
                 * @member {boolean} decline
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.decline = false;

                /**
                 * SurveyBaseRequest autoReminderSettings.
                 * @member {Array.<mtechnavi.api.survey.IAutoReminderSetting>} autoReminderSettings
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.autoReminderSettings = $util.emptyArray;

                /**
                 * SurveyBaseRequest requesters.
                 * @member {Array.<string>} requesters
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.requesters = $util.emptyArray;

                /**
                 * SurveyBaseRequest commonRequestAttachments.
                 * @member {Array.<sharelib.IAttachment>} commonRequestAttachments
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.commonRequestAttachments = $util.emptyArray;

                /**
                 * SurveyBaseRequest businessUnitRequestAttachments.
                 * @member {Array.<mtechnavi.api.survey.IAttachmentAttribute>} businessUnitRequestAttachments
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.businessUnitRequestAttachments = $util.emptyArray;

                /**
                 * SurveyBaseRequest requiredAttachment.
                 * @member {boolean} requiredAttachment
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.requiredAttachment = false;

                /**
                 * SurveyBaseRequest requestFormUsable.
                 * @member {boolean} requestFormUsable
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.requestFormUsable = false;

                /**
                 * SurveyBaseRequest resultFormUsable.
                 * @member {boolean} resultFormUsable
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.resultFormUsable = false;

                /**
                 * SurveyBaseRequest requestFormIds.
                 * @member {Array.<string>} requestFormIds
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.requestFormIds = $util.emptyArray;

                /**
                 * SurveyBaseRequest surveyResultWorkFormIds.
                 * @member {Array.<string>} surveyResultWorkFormIds
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyResultWorkFormIds = $util.emptyArray;

                /**
                 * SurveyBaseRequest requestUnits.
                 * @member {Array.<mtechnavi.api.survey.IBusinessUnitProperties>} requestUnits
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.requestUnits = $util.emptyArray;

                /**
                 * SurveyBaseRequest countRequestUnits.
                 * @member {Long} countRequestUnits
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.countRequestUnits = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest approvalStatus.
                 * @member {sharelib.INameOption|null|undefined} approvalStatus
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.approvalStatus = null;

                /**
                 * SurveyBaseRequest issuerProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} issuerProperties
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.issuerProperties = null;

                /**
                 * SurveyBaseRequest approvalRequest.
                 * @member {sharelib.IApprovalRequest|null|undefined} approvalRequest
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.approvalRequest = null;

                /**
                 * SurveyBaseRequest approvalResult.
                 * @member {sharelib.IApprovalResult|null|undefined} approvalResult
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.approvalResult = null;

                /**
                 * SurveyBaseRequest completed.
                 * @member {boolean} completed
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.completed = false;

                /**
                 * SurveyBaseRequest surveyBaseRequestUpdatedAt.
                 * @member {Long} surveyBaseRequestUpdatedAt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.surveyBaseRequestUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.createdProperties = null;

                /**
                 * SurveyBaseRequest updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.updatedProperties = null;

                /**
                 * SurveyBaseRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyBaseRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 */
                SurveyBaseRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyBaseRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyBaseRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.systemNotificationIncludeMainContact != null && message.hasOwnProperty("systemNotificationIncludeMainContact"))
                        if (typeof message.systemNotificationIncludeMainContact !== "boolean")
                            return "systemNotificationIncludeMainContact: boolean expected";
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (!$util.isInteger(message.surveyRequestAutoName) && !(message.surveyRequestAutoName && $util.isInteger(message.surveyRequestAutoName.low) && $util.isInteger(message.surveyRequestAutoName.high)))
                            return "surveyRequestAutoName: integer|Long expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        if (!$util.isString(message.surveyRequestCatalogId))
                            return "surveyRequestCatalogId: string expected";
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        if (!$util.isString(message.guidance))
                            return "guidance: string expected";
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        if (typeof message.approval !== "boolean")
                            return "approval: boolean expected";
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition")) {
                        let error = $root.sharelib.NameOption.verify(message.completeCondition);
                        if (error)
                            return "completeCondition." + error;
                    }
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        if (typeof message.partialReply !== "boolean")
                            return "partialReply: boolean expected";
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        if (typeof message.decline !== "boolean")
                            return "decline: boolean expected";
                    if (message.autoReminderSettings != null && message.hasOwnProperty("autoReminderSettings")) {
                        if (!Array.isArray(message.autoReminderSettings))
                            return "autoReminderSettings: array expected";
                        for (let i = 0; i < message.autoReminderSettings.length; ++i) {
                            let error = $root.mtechnavi.api.survey.AutoReminderSetting.verify(message.autoReminderSettings[i]);
                            if (error)
                                return "autoReminderSettings." + error;
                        }
                    }
                    if (message.requesters != null && message.hasOwnProperty("requesters")) {
                        if (!Array.isArray(message.requesters))
                            return "requesters: array expected";
                        for (let i = 0; i < message.requesters.length; ++i)
                            if (!$util.isString(message.requesters[i]))
                                return "requesters: string[] expected";
                    }
                    if (message.commonRequestAttachments != null && message.hasOwnProperty("commonRequestAttachments")) {
                        if (!Array.isArray(message.commonRequestAttachments))
                            return "commonRequestAttachments: array expected";
                        for (let i = 0; i < message.commonRequestAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.commonRequestAttachments[i]);
                            if (error)
                                return "commonRequestAttachments." + error;
                        }
                    }
                    if (message.businessUnitRequestAttachments != null && message.hasOwnProperty("businessUnitRequestAttachments")) {
                        if (!Array.isArray(message.businessUnitRequestAttachments))
                            return "businessUnitRequestAttachments: array expected";
                        for (let i = 0; i < message.businessUnitRequestAttachments.length; ++i) {
                            let error = $root.mtechnavi.api.survey.AttachmentAttribute.verify(message.businessUnitRequestAttachments[i]);
                            if (error)
                                return "businessUnitRequestAttachments." + error;
                        }
                    }
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        if (typeof message.requiredAttachment !== "boolean")
                            return "requiredAttachment: boolean expected";
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        if (typeof message.requestFormUsable !== "boolean")
                            return "requestFormUsable: boolean expected";
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        if (typeof message.resultFormUsable !== "boolean")
                            return "resultFormUsable: boolean expected";
                    if (message.requestFormIds != null && message.hasOwnProperty("requestFormIds")) {
                        if (!Array.isArray(message.requestFormIds))
                            return "requestFormIds: array expected";
                        for (let i = 0; i < message.requestFormIds.length; ++i)
                            if (!$util.isString(message.requestFormIds[i]))
                                return "requestFormIds: string[] expected";
                    }
                    if (message.surveyResultWorkFormIds != null && message.hasOwnProperty("surveyResultWorkFormIds")) {
                        if (!Array.isArray(message.surveyResultWorkFormIds))
                            return "surveyResultWorkFormIds: array expected";
                        for (let i = 0; i < message.surveyResultWorkFormIds.length; ++i)
                            if (!$util.isString(message.surveyResultWorkFormIds[i]))
                                return "surveyResultWorkFormIds: string[] expected";
                    }
                    if (message.requestUnits != null && message.hasOwnProperty("requestUnits")) {
                        if (!Array.isArray(message.requestUnits))
                            return "requestUnits: array expected";
                        for (let i = 0; i < message.requestUnits.length; ++i) {
                            let error = $root.mtechnavi.api.survey.BusinessUnitProperties.verify(message.requestUnits[i]);
                            if (error)
                                return "requestUnits." + error;
                        }
                    }
                    if (message.countRequestUnits != null && message.hasOwnProperty("countRequestUnits"))
                        if (!$util.isInteger(message.countRequestUnits) && !(message.countRequestUnits && $util.isInteger(message.countRequestUnits.low) && $util.isInteger(message.countRequestUnits.high)))
                            return "countRequestUnits: integer|Long expected";
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.approvalStatus);
                        if (error)
                            return "approvalStatus." + error;
                    }
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.issuerProperties);
                        if (error)
                            return "issuerProperties." + error;
                    }
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest")) {
                        let error = $root.sharelib.ApprovalRequest.verify(message.approvalRequest);
                        if (error)
                            return "approvalRequest." + error;
                    }
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult")) {
                        let error = $root.sharelib.ApprovalResult.verify(message.approvalResult);
                        if (error)
                            return "approvalResult." + error;
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        if (typeof message.completed !== "boolean")
                            return "completed: boolean expected";
                    if (message.surveyBaseRequestUpdatedAt != null && message.hasOwnProperty("surveyBaseRequestUpdatedAt"))
                        if (!$util.isInteger(message.surveyBaseRequestUpdatedAt) && !(message.surveyBaseRequestUpdatedAt && $util.isInteger(message.surveyBaseRequestUpdatedAt.low) && $util.isInteger(message.surveyBaseRequestUpdatedAt.high)))
                            return "surveyBaseRequestUpdatedAt: integer|Long expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyBaseRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyBaseRequest} SurveyBaseRequest
                 */
                SurveyBaseRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyBaseRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyBaseRequest();
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.systemNotificationIncludeMainContact != null)
                        message.systemNotificationIncludeMainContact = Boolean(object.systemNotificationIncludeMainContact);
                    if (object.surveyRequestAutoName != null)
                        if ($util.Long)
                            (message.surveyRequestAutoName = $util.Long.fromValue(object.surveyRequestAutoName)).unsigned = false;
                        else if (typeof object.surveyRequestAutoName === "string")
                            message.surveyRequestAutoName = parseInt(object.surveyRequestAutoName, 10);
                        else if (typeof object.surveyRequestAutoName === "number")
                            message.surveyRequestAutoName = object.surveyRequestAutoName;
                        else if (typeof object.surveyRequestAutoName === "object")
                            message.surveyRequestAutoName = new $util.LongBits(object.surveyRequestAutoName.low >>> 0, object.surveyRequestAutoName.high >>> 0).toNumber();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    if (object.surveyRequestCatalogId != null)
                        message.surveyRequestCatalogId = String(object.surveyRequestCatalogId);
                    if (object.guidance != null)
                        message.guidance = String(object.guidance);
                    if (object.approval != null)
                        message.approval = Boolean(object.approval);
                    if (object.completeCondition != null) {
                        if (typeof object.completeCondition !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.completeCondition: object expected");
                        message.completeCondition = $root.sharelib.NameOption.fromObject(object.completeCondition);
                    }
                    if (object.partialReply != null)
                        message.partialReply = Boolean(object.partialReply);
                    if (object.decline != null)
                        message.decline = Boolean(object.decline);
                    if (object.autoReminderSettings) {
                        if (!Array.isArray(object.autoReminderSettings))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.autoReminderSettings: array expected");
                        message.autoReminderSettings = [];
                        for (let i = 0; i < object.autoReminderSettings.length; ++i) {
                            if (typeof object.autoReminderSettings[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.autoReminderSettings: object expected");
                            message.autoReminderSettings[i] = $root.mtechnavi.api.survey.AutoReminderSetting.fromObject(object.autoReminderSettings[i]);
                        }
                    }
                    if (object.requesters) {
                        if (!Array.isArray(object.requesters))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.requesters: array expected");
                        message.requesters = [];
                        for (let i = 0; i < object.requesters.length; ++i)
                            message.requesters[i] = String(object.requesters[i]);
                    }
                    if (object.commonRequestAttachments) {
                        if (!Array.isArray(object.commonRequestAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.commonRequestAttachments: array expected");
                        message.commonRequestAttachments = [];
                        for (let i = 0; i < object.commonRequestAttachments.length; ++i) {
                            if (typeof object.commonRequestAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.commonRequestAttachments: object expected");
                            message.commonRequestAttachments[i] = $root.sharelib.Attachment.fromObject(object.commonRequestAttachments[i]);
                        }
                    }
                    if (object.businessUnitRequestAttachments) {
                        if (!Array.isArray(object.businessUnitRequestAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.businessUnitRequestAttachments: array expected");
                        message.businessUnitRequestAttachments = [];
                        for (let i = 0; i < object.businessUnitRequestAttachments.length; ++i) {
                            if (typeof object.businessUnitRequestAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.businessUnitRequestAttachments: object expected");
                            message.businessUnitRequestAttachments[i] = $root.mtechnavi.api.survey.AttachmentAttribute.fromObject(object.businessUnitRequestAttachments[i]);
                        }
                    }
                    if (object.requiredAttachment != null)
                        message.requiredAttachment = Boolean(object.requiredAttachment);
                    if (object.requestFormUsable != null)
                        message.requestFormUsable = Boolean(object.requestFormUsable);
                    if (object.resultFormUsable != null)
                        message.resultFormUsable = Boolean(object.resultFormUsable);
                    if (object.requestFormIds) {
                        if (!Array.isArray(object.requestFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.requestFormIds: array expected");
                        message.requestFormIds = [];
                        for (let i = 0; i < object.requestFormIds.length; ++i)
                            message.requestFormIds[i] = String(object.requestFormIds[i]);
                    }
                    if (object.surveyResultWorkFormIds) {
                        if (!Array.isArray(object.surveyResultWorkFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.surveyResultWorkFormIds: array expected");
                        message.surveyResultWorkFormIds = [];
                        for (let i = 0; i < object.surveyResultWorkFormIds.length; ++i)
                            message.surveyResultWorkFormIds[i] = String(object.surveyResultWorkFormIds[i]);
                    }
                    if (object.requestUnits) {
                        if (!Array.isArray(object.requestUnits))
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.requestUnits: array expected");
                        message.requestUnits = [];
                        for (let i = 0; i < object.requestUnits.length; ++i) {
                            if (typeof object.requestUnits[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.requestUnits: object expected");
                            message.requestUnits[i] = $root.mtechnavi.api.survey.BusinessUnitProperties.fromObject(object.requestUnits[i]);
                        }
                    }
                    if (object.countRequestUnits != null)
                        if ($util.Long)
                            (message.countRequestUnits = $util.Long.fromValue(object.countRequestUnits)).unsigned = false;
                        else if (typeof object.countRequestUnits === "string")
                            message.countRequestUnits = parseInt(object.countRequestUnits, 10);
                        else if (typeof object.countRequestUnits === "number")
                            message.countRequestUnits = object.countRequestUnits;
                        else if (typeof object.countRequestUnits === "object")
                            message.countRequestUnits = new $util.LongBits(object.countRequestUnits.low >>> 0, object.countRequestUnits.high >>> 0).toNumber();
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.approvalStatus != null) {
                        if (typeof object.approvalStatus !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.approvalStatus: object expected");
                        message.approvalStatus = $root.sharelib.NameOption.fromObject(object.approvalStatus);
                    }
                    if (object.issuerProperties != null) {
                        if (typeof object.issuerProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.issuerProperties: object expected");
                        message.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.issuerProperties);
                    }
                    if (object.approvalRequest != null) {
                        if (typeof object.approvalRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.approvalRequest: object expected");
                        message.approvalRequest = $root.sharelib.ApprovalRequest.fromObject(object.approvalRequest);
                    }
                    if (object.approvalResult != null) {
                        if (typeof object.approvalResult !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.approvalResult: object expected");
                        message.approvalResult = $root.sharelib.ApprovalResult.fromObject(object.approvalResult);
                    }
                    if (object.completed != null)
                        message.completed = Boolean(object.completed);
                    if (object.surveyBaseRequestUpdatedAt != null)
                        if ($util.Long)
                            (message.surveyBaseRequestUpdatedAt = $util.Long.fromValue(object.surveyBaseRequestUpdatedAt)).unsigned = false;
                        else if (typeof object.surveyBaseRequestUpdatedAt === "string")
                            message.surveyBaseRequestUpdatedAt = parseInt(object.surveyBaseRequestUpdatedAt, 10);
                        else if (typeof object.surveyBaseRequestUpdatedAt === "number")
                            message.surveyBaseRequestUpdatedAt = object.surveyBaseRequestUpdatedAt;
                        else if (typeof object.surveyBaseRequestUpdatedAt === "object")
                            message.surveyBaseRequestUpdatedAt = new $util.LongBits(object.surveyBaseRequestUpdatedAt.low >>> 0, object.surveyBaseRequestUpdatedAt.high >>> 0).toNumber();
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequest.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyBaseRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @static
                 * @param {mtechnavi.api.survey.SurveyBaseRequest} message SurveyBaseRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyBaseRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.autoReminderSettings = [];
                        object.requesters = [];
                        object.commonRequestAttachments = [];
                        object.requestUnits = [];
                        object.systemNotificationUsers = [];
                        object.businessUnitRequestAttachments = [];
                        object.requestFormIds = [];
                        object.surveyResultWorkFormIds = [];
                    }
                    if (options.defaults) {
                        object.surveyBaseRequestId = "";
                        object.displayName = "";
                        object.replyDueDateDt = null;
                        object.surveyRequestCatalog = null;
                        object.surveyRequestCatalogId = "";
                        object.guidance = "";
                        object.approval = false;
                        object.completeCondition = null;
                        object.partialReply = false;
                        object.decline = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.completed = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyBaseRequestUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyBaseRequestUpdatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestAutoName = options.longs === String ? "0" : 0;
                        object.approvalStatus = null;
                        object.systemNotificationIncludeMainContact = false;
                        object.requiredAttachment = false;
                        object.requestFormUsable = false;
                        object.resultFormUsable = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.countRequestUnits = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.countRequestUnits = options.longs === String ? "0" : 0;
                        object.issuerProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        object.managementOrganization = null;
                        object.approvalRequest = null;
                        object.approvalResult = null;
                    }
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        object.surveyRequestCatalogId = message.surveyRequestCatalogId;
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        object.guidance = message.guidance;
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        object.approval = message.approval;
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition"))
                        object.completeCondition = $root.sharelib.NameOption.toObject(message.completeCondition, options);
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        object.partialReply = message.partialReply;
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        object.decline = message.decline;
                    if (message.autoReminderSettings && message.autoReminderSettings.length) {
                        object.autoReminderSettings = [];
                        for (let j = 0; j < message.autoReminderSettings.length; ++j)
                            object.autoReminderSettings[j] = $root.mtechnavi.api.survey.AutoReminderSetting.toObject(message.autoReminderSettings[j], options);
                    }
                    if (message.requesters && message.requesters.length) {
                        object.requesters = [];
                        for (let j = 0; j < message.requesters.length; ++j)
                            object.requesters[j] = message.requesters[j];
                    }
                    if (message.commonRequestAttachments && message.commonRequestAttachments.length) {
                        object.commonRequestAttachments = [];
                        for (let j = 0; j < message.commonRequestAttachments.length; ++j)
                            object.commonRequestAttachments[j] = $root.sharelib.Attachment.toObject(message.commonRequestAttachments[j], options);
                    }
                    if (message.requestUnits && message.requestUnits.length) {
                        object.requestUnits = [];
                        for (let j = 0; j < message.requestUnits.length; ++j)
                            object.requestUnits[j] = $root.mtechnavi.api.survey.BusinessUnitProperties.toObject(message.requestUnits[j], options);
                    }
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = message.completed;
                    if (message.surveyBaseRequestUpdatedAt != null && message.hasOwnProperty("surveyBaseRequestUpdatedAt"))
                        if (typeof message.surveyBaseRequestUpdatedAt === "number")
                            object.surveyBaseRequestUpdatedAt = options.longs === String ? String(message.surveyBaseRequestUpdatedAt) : message.surveyBaseRequestUpdatedAt;
                        else
                            object.surveyBaseRequestUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.surveyBaseRequestUpdatedAt) : options.longs === Number ? new $util.LongBits(message.surveyBaseRequestUpdatedAt.low >>> 0, message.surveyBaseRequestUpdatedAt.high >>> 0).toNumber() : message.surveyBaseRequestUpdatedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (typeof message.surveyRequestAutoName === "number")
                            object.surveyRequestAutoName = options.longs === String ? String(message.surveyRequestAutoName) : message.surveyRequestAutoName;
                        else
                            object.surveyRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestAutoName) : options.longs === Number ? new $util.LongBits(message.surveyRequestAutoName.low >>> 0, message.surveyRequestAutoName.high >>> 0).toNumber() : message.surveyRequestAutoName;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        object.approvalStatus = $root.sharelib.NameOption.toObject(message.approvalStatus, options);
                    if (message.businessUnitRequestAttachments && message.businessUnitRequestAttachments.length) {
                        object.businessUnitRequestAttachments = [];
                        for (let j = 0; j < message.businessUnitRequestAttachments.length; ++j)
                            object.businessUnitRequestAttachments[j] = $root.mtechnavi.api.survey.AttachmentAttribute.toObject(message.businessUnitRequestAttachments[j], options);
                    }
                    if (message.systemNotificationIncludeMainContact != null && message.hasOwnProperty("systemNotificationIncludeMainContact"))
                        object.systemNotificationIncludeMainContact = message.systemNotificationIncludeMainContact;
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        object.requiredAttachment = message.requiredAttachment;
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        object.requestFormUsable = message.requestFormUsable;
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        object.resultFormUsable = message.resultFormUsable;
                    if (message.requestFormIds && message.requestFormIds.length) {
                        object.requestFormIds = [];
                        for (let j = 0; j < message.requestFormIds.length; ++j)
                            object.requestFormIds[j] = message.requestFormIds[j];
                    }
                    if (message.countRequestUnits != null && message.hasOwnProperty("countRequestUnits"))
                        if (typeof message.countRequestUnits === "number")
                            object.countRequestUnits = options.longs === String ? String(message.countRequestUnits) : message.countRequestUnits;
                        else
                            object.countRequestUnits = options.longs === String ? $util.Long.prototype.toString.call(message.countRequestUnits) : options.longs === Number ? new $util.LongBits(message.countRequestUnits.low >>> 0, message.countRequestUnits.high >>> 0).toNumber() : message.countRequestUnits;
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties"))
                        object.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.issuerProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.surveyResultWorkFormIds && message.surveyResultWorkFormIds.length) {
                        object.surveyResultWorkFormIds = [];
                        for (let j = 0; j < message.surveyResultWorkFormIds.length; ++j)
                            object.surveyResultWorkFormIds[j] = message.surveyResultWorkFormIds[j];
                    }
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest"))
                        object.approvalRequest = $root.sharelib.ApprovalRequest.toObject(message.approvalRequest, options);
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult"))
                        object.approvalResult = $root.sharelib.ApprovalResult.toObject(message.approvalResult, options);
                    return object;
                };

                /**
                 * Converts this SurveyBaseRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyBaseRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyBaseRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyBaseRequest;
            })();

            survey.SurveyRequest = (function() {

                /**
                 * Properties of a SurveyRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequest
                 * @property {string|null} [surveyRequestId] SurveyRequest surveyRequestId
                 * @property {string|null} [surveyBaseRequestId] SurveyRequest surveyBaseRequestId
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] SurveyRequest managementOrganization
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] SurveyRequest systemNotificationUsers
                 * @property {Array.<sharelib.IUserReference>|null} [responsibleUsers] SurveyRequest responsibleUsers
                 * @property {Long|null} [surveyRequestAutoName] SurveyRequest surveyRequestAutoName
                 * @property {Long|null} [surveyRequestDetailAutoName] SurveyRequest surveyRequestDetailAutoName
                 * @property {boolean|null} [completed] SurveyRequest completed
                 * @property {string|null} [displayName] SurveyRequest displayName
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyRequest replyDueDateDt
                 * @property {sharelib.IDatetime|null} [latestReplyDueDateDt] SurveyRequest latestReplyDueDateDt
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] SurveyRequest surveyRequestCatalog
                 * @property {string|null} [surveyRequestCatalogId] SurveyRequest surveyRequestCatalogId
                 * @property {string|null} [guidance] SurveyRequest guidance
                 * @property {boolean|null} [approval] SurveyRequest approval
                 * @property {sharelib.INameOption|null} [completeCondition] SurveyRequest completeCondition
                 * @property {boolean|null} [partialReply] SurveyRequest partialReply
                 * @property {boolean|null} [decline] SurveyRequest decline
                 * @property {Array.<mtechnavi.api.survey.IAutoReminderSetting>|null} [autoReminderSettings] SurveyRequest autoReminderSettings
                 * @property {Array.<string>|null} [requesters] SurveyRequest requesters
                 * @property {Array.<sharelib.IAttachment>|null} [commonRequestAttachments] SurveyRequest commonRequestAttachments
                 * @property {Array.<sharelib.IAttachment>|null} [businessUnitRequestAttachments] SurveyRequest businessUnitRequestAttachments
                 * @property {boolean|null} [requiredAttachment] SurveyRequest requiredAttachment
                 * @property {boolean|null} [requestFormUsable] SurveyRequest requestFormUsable
                 * @property {boolean|null} [resultFormUsable] SurveyRequest resultFormUsable
                 * @property {Array.<string>|null} [requestFormIds] SurveyRequest requestFormIds
                 * @property {Array.<string>|null} [resultFormIds] SurveyRequest resultFormIds
                 * @property {mtechnavi.api.survey.IBusinessUnitProperties|null} [requestUnit] SurveyRequest requestUnit
                 * @property {Array.<sharelib.IAttachment>|null} [proxyAttachments] SurveyRequest proxyAttachments
                 * @property {string|null} [remarks] SurveyRequest remarks
                 * @property {Long|null} [sendedAt] SurveyRequest sendedAt
                 * @property {Long|null} [receiptedAt] SurveyRequest receiptedAt
                 * @property {Long|null} [surveyResultExportedAt] SurveyRequest surveyResultExportedAt
                 * @property {mtechnavi.api.survey.IFlagProperties|null} [flagProperties] SurveyRequest flagProperties
                 * @property {sharelib.INameOption|null} [surveyRequestStatus] SurveyRequest surveyRequestStatus
                 * @property {boolean|null} [sended] SurveyRequest sended
                 * @property {sharelib.IEmbeddedCompany|null} [requestingCompany] SurveyRequest requestingCompany
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [issuerProperties] SurveyRequest issuerProperties
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyRequest sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyRequest createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyRequest updatedProperties
                 * @property {Long|null} [createdAt] SurveyRequest createdAt
                 * @property {Long|null} [updatedAt] SurveyRequest updatedAt
                 * @property {Long|null} [deletedAt] SurveyRequest deletedAt
                 */

                /**
                 * Constructs a new SurveyRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequest.
                 * @implements ISurveyRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequest=} [properties] Properties to set
                 */
                function SurveyRequest(properties) {
                    this.systemNotificationUsers = [];
                    this.responsibleUsers = [];
                    this.autoReminderSettings = [];
                    this.requesters = [];
                    this.commonRequestAttachments = [];
                    this.businessUnitRequestAttachments = [];
                    this.requestFormIds = [];
                    this.resultFormIds = [];
                    this.proxyAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestId = "";

                /**
                 * SurveyRequest surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyBaseRequestId = "";

                /**
                 * SurveyRequest managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.managementOrganization = null;

                /**
                 * SurveyRequest systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * SurveyRequest responsibleUsers.
                 * @member {Array.<sharelib.IUserReference>} responsibleUsers
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.responsibleUsers = $util.emptyArray;

                /**
                 * SurveyRequest surveyRequestAutoName.
                 * @member {Long} surveyRequestAutoName
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest surveyRequestDetailAutoName.
                 * @member {Long} surveyRequestDetailAutoName
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestDetailAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest completed.
                 * @member {boolean} completed
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.completed = false;

                /**
                 * SurveyRequest displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.displayName = "";

                /**
                 * SurveyRequest replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.replyDueDateDt = null;

                /**
                 * SurveyRequest latestReplyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} latestReplyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.latestReplyDueDateDt = null;

                /**
                 * SurveyRequest surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestCatalog = null;

                /**
                 * SurveyRequest surveyRequestCatalogId.
                 * @member {string} surveyRequestCatalogId
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestCatalogId = "";

                /**
                 * SurveyRequest guidance.
                 * @member {string} guidance
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.guidance = "";

                /**
                 * SurveyRequest approval.
                 * @member {boolean} approval
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.approval = false;

                /**
                 * SurveyRequest completeCondition.
                 * @member {sharelib.INameOption|null|undefined} completeCondition
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.completeCondition = null;

                /**
                 * SurveyRequest partialReply.
                 * @member {boolean} partialReply
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.partialReply = false;

                /**
                 * SurveyRequest decline.
                 * @member {boolean} decline
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.decline = false;

                /**
                 * SurveyRequest autoReminderSettings.
                 * @member {Array.<mtechnavi.api.survey.IAutoReminderSetting>} autoReminderSettings
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.autoReminderSettings = $util.emptyArray;

                /**
                 * SurveyRequest requesters.
                 * @member {Array.<string>} requesters
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requesters = $util.emptyArray;

                /**
                 * SurveyRequest commonRequestAttachments.
                 * @member {Array.<sharelib.IAttachment>} commonRequestAttachments
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.commonRequestAttachments = $util.emptyArray;

                /**
                 * SurveyRequest businessUnitRequestAttachments.
                 * @member {Array.<sharelib.IAttachment>} businessUnitRequestAttachments
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.businessUnitRequestAttachments = $util.emptyArray;

                /**
                 * SurveyRequest requiredAttachment.
                 * @member {boolean} requiredAttachment
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requiredAttachment = false;

                /**
                 * SurveyRequest requestFormUsable.
                 * @member {boolean} requestFormUsable
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requestFormUsable = false;

                /**
                 * SurveyRequest resultFormUsable.
                 * @member {boolean} resultFormUsable
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.resultFormUsable = false;

                /**
                 * SurveyRequest requestFormIds.
                 * @member {Array.<string>} requestFormIds
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requestFormIds = $util.emptyArray;

                /**
                 * SurveyRequest resultFormIds.
                 * @member {Array.<string>} resultFormIds
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.resultFormIds = $util.emptyArray;

                /**
                 * SurveyRequest requestUnit.
                 * @member {mtechnavi.api.survey.IBusinessUnitProperties|null|undefined} requestUnit
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requestUnit = null;

                /**
                 * SurveyRequest proxyAttachments.
                 * @member {Array.<sharelib.IAttachment>} proxyAttachments
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.proxyAttachments = $util.emptyArray;

                /**
                 * SurveyRequest remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.remarks = "";

                /**
                 * SurveyRequest sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest receiptedAt.
                 * @member {Long} receiptedAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.receiptedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest surveyResultExportedAt.
                 * @member {Long} surveyResultExportedAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyResultExportedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest flagProperties.
                 * @member {mtechnavi.api.survey.IFlagProperties|null|undefined} flagProperties
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.flagProperties = null;

                /**
                 * SurveyRequest surveyRequestStatus.
                 * @member {sharelib.INameOption|null|undefined} surveyRequestStatus
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.surveyRequestStatus = null;

                /**
                 * SurveyRequest sended.
                 * @member {boolean} sended
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.sended = false;

                /**
                 * SurveyRequest requestingCompany.
                 * @member {sharelib.IEmbeddedCompany|null|undefined} requestingCompany
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.requestingCompany = null;

                /**
                 * SurveyRequest issuerProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} issuerProperties
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.issuerProperties = null;

                /**
                 * SurveyRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.sharedProperties = null;

                /**
                 * SurveyRequest createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.createdProperties = null;

                /**
                 * SurveyRequest updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.updatedProperties = null;

                /**
                 * SurveyRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 */
                SurveyRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.responsibleUsers != null && message.hasOwnProperty("responsibleUsers")) {
                        if (!Array.isArray(message.responsibleUsers))
                            return "responsibleUsers: array expected";
                        for (let i = 0; i < message.responsibleUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.responsibleUsers[i]);
                            if (error)
                                return "responsibleUsers." + error;
                        }
                    }
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (!$util.isInteger(message.surveyRequestAutoName) && !(message.surveyRequestAutoName && $util.isInteger(message.surveyRequestAutoName.low) && $util.isInteger(message.surveyRequestAutoName.high)))
                            return "surveyRequestAutoName: integer|Long expected";
                    if (message.surveyRequestDetailAutoName != null && message.hasOwnProperty("surveyRequestDetailAutoName"))
                        if (!$util.isInteger(message.surveyRequestDetailAutoName) && !(message.surveyRequestDetailAutoName && $util.isInteger(message.surveyRequestDetailAutoName.low) && $util.isInteger(message.surveyRequestDetailAutoName.high)))
                            return "surveyRequestDetailAutoName: integer|Long expected";
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        if (typeof message.completed !== "boolean")
                            return "completed: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.latestReplyDueDateDt != null && message.hasOwnProperty("latestReplyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.latestReplyDueDateDt);
                        if (error)
                            return "latestReplyDueDateDt." + error;
                    }
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        if (!$util.isString(message.surveyRequestCatalogId))
                            return "surveyRequestCatalogId: string expected";
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        if (!$util.isString(message.guidance))
                            return "guidance: string expected";
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        if (typeof message.approval !== "boolean")
                            return "approval: boolean expected";
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition")) {
                        let error = $root.sharelib.NameOption.verify(message.completeCondition);
                        if (error)
                            return "completeCondition." + error;
                    }
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        if (typeof message.partialReply !== "boolean")
                            return "partialReply: boolean expected";
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        if (typeof message.decline !== "boolean")
                            return "decline: boolean expected";
                    if (message.autoReminderSettings != null && message.hasOwnProperty("autoReminderSettings")) {
                        if (!Array.isArray(message.autoReminderSettings))
                            return "autoReminderSettings: array expected";
                        for (let i = 0; i < message.autoReminderSettings.length; ++i) {
                            let error = $root.mtechnavi.api.survey.AutoReminderSetting.verify(message.autoReminderSettings[i]);
                            if (error)
                                return "autoReminderSettings." + error;
                        }
                    }
                    if (message.requesters != null && message.hasOwnProperty("requesters")) {
                        if (!Array.isArray(message.requesters))
                            return "requesters: array expected";
                        for (let i = 0; i < message.requesters.length; ++i)
                            if (!$util.isString(message.requesters[i]))
                                return "requesters: string[] expected";
                    }
                    if (message.commonRequestAttachments != null && message.hasOwnProperty("commonRequestAttachments")) {
                        if (!Array.isArray(message.commonRequestAttachments))
                            return "commonRequestAttachments: array expected";
                        for (let i = 0; i < message.commonRequestAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.commonRequestAttachments[i]);
                            if (error)
                                return "commonRequestAttachments." + error;
                        }
                    }
                    if (message.businessUnitRequestAttachments != null && message.hasOwnProperty("businessUnitRequestAttachments")) {
                        if (!Array.isArray(message.businessUnitRequestAttachments))
                            return "businessUnitRequestAttachments: array expected";
                        for (let i = 0; i < message.businessUnitRequestAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.businessUnitRequestAttachments[i]);
                            if (error)
                                return "businessUnitRequestAttachments." + error;
                        }
                    }
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        if (typeof message.requiredAttachment !== "boolean")
                            return "requiredAttachment: boolean expected";
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        if (typeof message.requestFormUsable !== "boolean")
                            return "requestFormUsable: boolean expected";
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        if (typeof message.resultFormUsable !== "boolean")
                            return "resultFormUsable: boolean expected";
                    if (message.requestFormIds != null && message.hasOwnProperty("requestFormIds")) {
                        if (!Array.isArray(message.requestFormIds))
                            return "requestFormIds: array expected";
                        for (let i = 0; i < message.requestFormIds.length; ++i)
                            if (!$util.isString(message.requestFormIds[i]))
                                return "requestFormIds: string[] expected";
                    }
                    if (message.resultFormIds != null && message.hasOwnProperty("resultFormIds")) {
                        if (!Array.isArray(message.resultFormIds))
                            return "resultFormIds: array expected";
                        for (let i = 0; i < message.resultFormIds.length; ++i)
                            if (!$util.isString(message.resultFormIds[i]))
                                return "resultFormIds: string[] expected";
                    }
                    if (message.requestUnit != null && message.hasOwnProperty("requestUnit")) {
                        let error = $root.mtechnavi.api.survey.BusinessUnitProperties.verify(message.requestUnit);
                        if (error)
                            return "requestUnit." + error;
                    }
                    if (message.proxyAttachments != null && message.hasOwnProperty("proxyAttachments")) {
                        if (!Array.isArray(message.proxyAttachments))
                            return "proxyAttachments: array expected";
                        for (let i = 0; i < message.proxyAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.proxyAttachments[i]);
                            if (error)
                                return "proxyAttachments." + error;
                        }
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.receiptedAt != null && message.hasOwnProperty("receiptedAt"))
                        if (!$util.isInteger(message.receiptedAt) && !(message.receiptedAt && $util.isInteger(message.receiptedAt.low) && $util.isInteger(message.receiptedAt.high)))
                            return "receiptedAt: integer|Long expected";
                    if (message.surveyResultExportedAt != null && message.hasOwnProperty("surveyResultExportedAt"))
                        if (!$util.isInteger(message.surveyResultExportedAt) && !(message.surveyResultExportedAt && $util.isInteger(message.surveyResultExportedAt.low) && $util.isInteger(message.surveyResultExportedAt.high)))
                            return "surveyResultExportedAt: integer|Long expected";
                    if (message.flagProperties != null && message.hasOwnProperty("flagProperties")) {
                        let error = $root.mtechnavi.api.survey.FlagProperties.verify(message.flagProperties);
                        if (error)
                            return "flagProperties." + error;
                    }
                    if (message.surveyRequestStatus != null && message.hasOwnProperty("surveyRequestStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.surveyRequestStatus);
                        if (error)
                            return "surveyRequestStatus." + error;
                    }
                    if (message.sended != null && message.hasOwnProperty("sended"))
                        if (typeof message.sended !== "boolean")
                            return "sended: boolean expected";
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany")) {
                        let error = $root.sharelib.EmbeddedCompany.verify(message.requestingCompany);
                        if (error)
                            return "requestingCompany." + error;
                    }
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.issuerProperties);
                        if (error)
                            return "issuerProperties." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequest} SurveyRequest
                 */
                SurveyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequest();
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.responsibleUsers) {
                        if (!Array.isArray(object.responsibleUsers))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.responsibleUsers: array expected");
                        message.responsibleUsers = [];
                        for (let i = 0; i < object.responsibleUsers.length; ++i) {
                            if (typeof object.responsibleUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.responsibleUsers: object expected");
                            message.responsibleUsers[i] = $root.sharelib.UserReference.fromObject(object.responsibleUsers[i]);
                        }
                    }
                    if (object.surveyRequestAutoName != null)
                        if ($util.Long)
                            (message.surveyRequestAutoName = $util.Long.fromValue(object.surveyRequestAutoName)).unsigned = false;
                        else if (typeof object.surveyRequestAutoName === "string")
                            message.surveyRequestAutoName = parseInt(object.surveyRequestAutoName, 10);
                        else if (typeof object.surveyRequestAutoName === "number")
                            message.surveyRequestAutoName = object.surveyRequestAutoName;
                        else if (typeof object.surveyRequestAutoName === "object")
                            message.surveyRequestAutoName = new $util.LongBits(object.surveyRequestAutoName.low >>> 0, object.surveyRequestAutoName.high >>> 0).toNumber();
                    if (object.surveyRequestDetailAutoName != null)
                        if ($util.Long)
                            (message.surveyRequestDetailAutoName = $util.Long.fromValue(object.surveyRequestDetailAutoName)).unsigned = false;
                        else if (typeof object.surveyRequestDetailAutoName === "string")
                            message.surveyRequestDetailAutoName = parseInt(object.surveyRequestDetailAutoName, 10);
                        else if (typeof object.surveyRequestDetailAutoName === "number")
                            message.surveyRequestDetailAutoName = object.surveyRequestDetailAutoName;
                        else if (typeof object.surveyRequestDetailAutoName === "object")
                            message.surveyRequestDetailAutoName = new $util.LongBits(object.surveyRequestDetailAutoName.low >>> 0, object.surveyRequestDetailAutoName.high >>> 0).toNumber();
                    if (object.completed != null)
                        message.completed = Boolean(object.completed);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.latestReplyDueDateDt != null) {
                        if (typeof object.latestReplyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.latestReplyDueDateDt: object expected");
                        message.latestReplyDueDateDt = $root.sharelib.Datetime.fromObject(object.latestReplyDueDateDt);
                    }
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    if (object.surveyRequestCatalogId != null)
                        message.surveyRequestCatalogId = String(object.surveyRequestCatalogId);
                    if (object.guidance != null)
                        message.guidance = String(object.guidance);
                    if (object.approval != null)
                        message.approval = Boolean(object.approval);
                    if (object.completeCondition != null) {
                        if (typeof object.completeCondition !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.completeCondition: object expected");
                        message.completeCondition = $root.sharelib.NameOption.fromObject(object.completeCondition);
                    }
                    if (object.partialReply != null)
                        message.partialReply = Boolean(object.partialReply);
                    if (object.decline != null)
                        message.decline = Boolean(object.decline);
                    if (object.autoReminderSettings) {
                        if (!Array.isArray(object.autoReminderSettings))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.autoReminderSettings: array expected");
                        message.autoReminderSettings = [];
                        for (let i = 0; i < object.autoReminderSettings.length; ++i) {
                            if (typeof object.autoReminderSettings[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.autoReminderSettings: object expected");
                            message.autoReminderSettings[i] = $root.mtechnavi.api.survey.AutoReminderSetting.fromObject(object.autoReminderSettings[i]);
                        }
                    }
                    if (object.requesters) {
                        if (!Array.isArray(object.requesters))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.requesters: array expected");
                        message.requesters = [];
                        for (let i = 0; i < object.requesters.length; ++i)
                            message.requesters[i] = String(object.requesters[i]);
                    }
                    if (object.commonRequestAttachments) {
                        if (!Array.isArray(object.commonRequestAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.commonRequestAttachments: array expected");
                        message.commonRequestAttachments = [];
                        for (let i = 0; i < object.commonRequestAttachments.length; ++i) {
                            if (typeof object.commonRequestAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.commonRequestAttachments: object expected");
                            message.commonRequestAttachments[i] = $root.sharelib.Attachment.fromObject(object.commonRequestAttachments[i]);
                        }
                    }
                    if (object.businessUnitRequestAttachments) {
                        if (!Array.isArray(object.businessUnitRequestAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.businessUnitRequestAttachments: array expected");
                        message.businessUnitRequestAttachments = [];
                        for (let i = 0; i < object.businessUnitRequestAttachments.length; ++i) {
                            if (typeof object.businessUnitRequestAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.businessUnitRequestAttachments: object expected");
                            message.businessUnitRequestAttachments[i] = $root.sharelib.Attachment.fromObject(object.businessUnitRequestAttachments[i]);
                        }
                    }
                    if (object.requiredAttachment != null)
                        message.requiredAttachment = Boolean(object.requiredAttachment);
                    if (object.requestFormUsable != null)
                        message.requestFormUsable = Boolean(object.requestFormUsable);
                    if (object.resultFormUsable != null)
                        message.resultFormUsable = Boolean(object.resultFormUsable);
                    if (object.requestFormIds) {
                        if (!Array.isArray(object.requestFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.requestFormIds: array expected");
                        message.requestFormIds = [];
                        for (let i = 0; i < object.requestFormIds.length; ++i)
                            message.requestFormIds[i] = String(object.requestFormIds[i]);
                    }
                    if (object.resultFormIds) {
                        if (!Array.isArray(object.resultFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.resultFormIds: array expected");
                        message.resultFormIds = [];
                        for (let i = 0; i < object.resultFormIds.length; ++i)
                            message.resultFormIds[i] = String(object.resultFormIds[i]);
                    }
                    if (object.requestUnit != null) {
                        if (typeof object.requestUnit !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.requestUnit: object expected");
                        message.requestUnit = $root.mtechnavi.api.survey.BusinessUnitProperties.fromObject(object.requestUnit);
                    }
                    if (object.proxyAttachments) {
                        if (!Array.isArray(object.proxyAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.proxyAttachments: array expected");
                        message.proxyAttachments = [];
                        for (let i = 0; i < object.proxyAttachments.length; ++i) {
                            if (typeof object.proxyAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequest.proxyAttachments: object expected");
                            message.proxyAttachments[i] = $root.sharelib.Attachment.fromObject(object.proxyAttachments[i]);
                        }
                    }
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.receiptedAt != null)
                        if ($util.Long)
                            (message.receiptedAt = $util.Long.fromValue(object.receiptedAt)).unsigned = false;
                        else if (typeof object.receiptedAt === "string")
                            message.receiptedAt = parseInt(object.receiptedAt, 10);
                        else if (typeof object.receiptedAt === "number")
                            message.receiptedAt = object.receiptedAt;
                        else if (typeof object.receiptedAt === "object")
                            message.receiptedAt = new $util.LongBits(object.receiptedAt.low >>> 0, object.receiptedAt.high >>> 0).toNumber();
                    if (object.surveyResultExportedAt != null)
                        if ($util.Long)
                            (message.surveyResultExportedAt = $util.Long.fromValue(object.surveyResultExportedAt)).unsigned = false;
                        else if (typeof object.surveyResultExportedAt === "string")
                            message.surveyResultExportedAt = parseInt(object.surveyResultExportedAt, 10);
                        else if (typeof object.surveyResultExportedAt === "number")
                            message.surveyResultExportedAt = object.surveyResultExportedAt;
                        else if (typeof object.surveyResultExportedAt === "object")
                            message.surveyResultExportedAt = new $util.LongBits(object.surveyResultExportedAt.low >>> 0, object.surveyResultExportedAt.high >>> 0).toNumber();
                    if (object.flagProperties != null) {
                        if (typeof object.flagProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.flagProperties: object expected");
                        message.flagProperties = $root.mtechnavi.api.survey.FlagProperties.fromObject(object.flagProperties);
                    }
                    if (object.surveyRequestStatus != null) {
                        if (typeof object.surveyRequestStatus !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.surveyRequestStatus: object expected");
                        message.surveyRequestStatus = $root.sharelib.NameOption.fromObject(object.surveyRequestStatus);
                    }
                    if (object.sended != null)
                        message.sended = Boolean(object.sended);
                    if (object.requestingCompany != null) {
                        if (typeof object.requestingCompany !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.requestingCompany: object expected");
                        message.requestingCompany = $root.sharelib.EmbeddedCompany.fromObject(object.requestingCompany);
                    }
                    if (object.issuerProperties != null) {
                        if (typeof object.issuerProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.issuerProperties: object expected");
                        message.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.issuerProperties);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequest.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequest} message SurveyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.autoReminderSettings = [];
                        object.requesters = [];
                        object.commonRequestAttachments = [];
                        object.businessUnitRequestAttachments = [];
                        object.proxyAttachments = [];
                        object.systemNotificationUsers = [];
                        object.requestFormIds = [];
                        object.resultFormIds = [];
                        object.responsibleUsers = [];
                    }
                    if (options.defaults) {
                        object.surveyRequestId = "";
                        object.surveyBaseRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestAutoName = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestDetailAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestDetailAutoName = options.longs === String ? "0" : 0;
                        object.completed = false;
                        object.displayName = "";
                        object.replyDueDateDt = null;
                        object.surveyRequestCatalog = null;
                        object.guidance = "";
                        object.approval = false;
                        object.completeCondition = null;
                        object.partialReply = false;
                        object.decline = false;
                        object.requestUnit = null;
                        object.remarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyResultExportedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyResultExportedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.surveyRequestCatalogId = "";
                        object.latestReplyDueDateDt = null;
                        object.requiredAttachment = false;
                        object.requestFormUsable = false;
                        object.resultFormUsable = false;
                        object.surveyRequestStatus = null;
                        object.requestingCompany = null;
                        object.issuerProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.receiptedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.receiptedAt = options.longs === String ? "0" : 0;
                        object.flagProperties = null;
                        object.sended = false;
                        object.managementOrganization = null;
                    }
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (typeof message.surveyRequestAutoName === "number")
                            object.surveyRequestAutoName = options.longs === String ? String(message.surveyRequestAutoName) : message.surveyRequestAutoName;
                        else
                            object.surveyRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestAutoName) : options.longs === Number ? new $util.LongBits(message.surveyRequestAutoName.low >>> 0, message.surveyRequestAutoName.high >>> 0).toNumber() : message.surveyRequestAutoName;
                    if (message.surveyRequestDetailAutoName != null && message.hasOwnProperty("surveyRequestDetailAutoName"))
                        if (typeof message.surveyRequestDetailAutoName === "number")
                            object.surveyRequestDetailAutoName = options.longs === String ? String(message.surveyRequestDetailAutoName) : message.surveyRequestDetailAutoName;
                        else
                            object.surveyRequestDetailAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestDetailAutoName) : options.longs === Number ? new $util.LongBits(message.surveyRequestDetailAutoName.low >>> 0, message.surveyRequestDetailAutoName.high >>> 0).toNumber() : message.surveyRequestDetailAutoName;
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = message.completed;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        object.guidance = message.guidance;
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        object.approval = message.approval;
                    if (message.completeCondition != null && message.hasOwnProperty("completeCondition"))
                        object.completeCondition = $root.sharelib.NameOption.toObject(message.completeCondition, options);
                    if (message.partialReply != null && message.hasOwnProperty("partialReply"))
                        object.partialReply = message.partialReply;
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        object.decline = message.decline;
                    if (message.autoReminderSettings && message.autoReminderSettings.length) {
                        object.autoReminderSettings = [];
                        for (let j = 0; j < message.autoReminderSettings.length; ++j)
                            object.autoReminderSettings[j] = $root.mtechnavi.api.survey.AutoReminderSetting.toObject(message.autoReminderSettings[j], options);
                    }
                    if (message.requesters && message.requesters.length) {
                        object.requesters = [];
                        for (let j = 0; j < message.requesters.length; ++j)
                            object.requesters[j] = message.requesters[j];
                    }
                    if (message.commonRequestAttachments && message.commonRequestAttachments.length) {
                        object.commonRequestAttachments = [];
                        for (let j = 0; j < message.commonRequestAttachments.length; ++j)
                            object.commonRequestAttachments[j] = $root.sharelib.Attachment.toObject(message.commonRequestAttachments[j], options);
                    }
                    if (message.businessUnitRequestAttachments && message.businessUnitRequestAttachments.length) {
                        object.businessUnitRequestAttachments = [];
                        for (let j = 0; j < message.businessUnitRequestAttachments.length; ++j)
                            object.businessUnitRequestAttachments[j] = $root.sharelib.Attachment.toObject(message.businessUnitRequestAttachments[j], options);
                    }
                    if (message.requestUnit != null && message.hasOwnProperty("requestUnit"))
                        object.requestUnit = $root.mtechnavi.api.survey.BusinessUnitProperties.toObject(message.requestUnit, options);
                    if (message.proxyAttachments && message.proxyAttachments.length) {
                        object.proxyAttachments = [];
                        for (let j = 0; j < message.proxyAttachments.length; ++j)
                            object.proxyAttachments[j] = $root.sharelib.Attachment.toObject(message.proxyAttachments[j], options);
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.surveyResultExportedAt != null && message.hasOwnProperty("surveyResultExportedAt"))
                        if (typeof message.surveyResultExportedAt === "number")
                            object.surveyResultExportedAt = options.longs === String ? String(message.surveyResultExportedAt) : message.surveyResultExportedAt;
                        else
                            object.surveyResultExportedAt = options.longs === String ? $util.Long.prototype.toString.call(message.surveyResultExportedAt) : options.longs === Number ? new $util.LongBits(message.surveyResultExportedAt.low >>> 0, message.surveyResultExportedAt.high >>> 0).toNumber() : message.surveyResultExportedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.surveyRequestCatalogId != null && message.hasOwnProperty("surveyRequestCatalogId"))
                        object.surveyRequestCatalogId = message.surveyRequestCatalogId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.latestReplyDueDateDt != null && message.hasOwnProperty("latestReplyDueDateDt"))
                        object.latestReplyDueDateDt = $root.sharelib.Datetime.toObject(message.latestReplyDueDateDt, options);
                    if (message.requiredAttachment != null && message.hasOwnProperty("requiredAttachment"))
                        object.requiredAttachment = message.requiredAttachment;
                    if (message.requestFormUsable != null && message.hasOwnProperty("requestFormUsable"))
                        object.requestFormUsable = message.requestFormUsable;
                    if (message.resultFormUsable != null && message.hasOwnProperty("resultFormUsable"))
                        object.resultFormUsable = message.resultFormUsable;
                    if (message.requestFormIds && message.requestFormIds.length) {
                        object.requestFormIds = [];
                        for (let j = 0; j < message.requestFormIds.length; ++j)
                            object.requestFormIds[j] = message.requestFormIds[j];
                    }
                    if (message.resultFormIds && message.resultFormIds.length) {
                        object.resultFormIds = [];
                        for (let j = 0; j < message.resultFormIds.length; ++j)
                            object.resultFormIds[j] = message.resultFormIds[j];
                    }
                    if (message.surveyRequestStatus != null && message.hasOwnProperty("surveyRequestStatus"))
                        object.surveyRequestStatus = $root.sharelib.NameOption.toObject(message.surveyRequestStatus, options);
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany"))
                        object.requestingCompany = $root.sharelib.EmbeddedCompany.toObject(message.requestingCompany, options);
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties"))
                        object.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.issuerProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.receiptedAt != null && message.hasOwnProperty("receiptedAt"))
                        if (typeof message.receiptedAt === "number")
                            object.receiptedAt = options.longs === String ? String(message.receiptedAt) : message.receiptedAt;
                        else
                            object.receiptedAt = options.longs === String ? $util.Long.prototype.toString.call(message.receiptedAt) : options.longs === Number ? new $util.LongBits(message.receiptedAt.low >>> 0, message.receiptedAt.high >>> 0).toNumber() : message.receiptedAt;
                    if (message.flagProperties != null && message.hasOwnProperty("flagProperties"))
                        object.flagProperties = $root.mtechnavi.api.survey.FlagProperties.toObject(message.flagProperties, options);
                    if (message.responsibleUsers && message.responsibleUsers.length) {
                        object.responsibleUsers = [];
                        for (let j = 0; j < message.responsibleUsers.length; ++j)
                            object.responsibleUsers[j] = $root.sharelib.UserReference.toObject(message.responsibleUsers[j], options);
                    }
                    if (message.sended != null && message.hasOwnProperty("sended"))
                        object.sended = message.sended;
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    return object;
                };

                /**
                 * Converts this SurveyRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequest;
            })();

            survey.SurveyReminder = (function() {

                /**
                 * Properties of a SurveyReminder.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyReminder
                 * @property {string|null} [surveyReminderId] SurveyReminder surveyReminderId
                 * @property {string|null} [surveyRequestId] SurveyReminder surveyRequestId
                 * @property {Long|null} [remindedAt] SurveyReminder remindedAt
                 * @property {sharelib.INameOption|null} [contentType] SurveyReminder contentType
                 * @property {string|null} [content] SurveyReminder content
                 * @property {mtechnavi.api.survey.ISendTargetSetting|null} [sendTarget] SurveyReminder sendTarget
                 * @property {boolean|null} [manual] SurveyReminder manual
                 * @property {string|null} [surveyReplyDueDateChangeId] SurveyReminder surveyReplyDueDateChangeId
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyReminder sharedProperties
                 * @property {Long|null} [createdAt] SurveyReminder createdAt
                 * @property {Long|null} [updatedAt] SurveyReminder updatedAt
                 * @property {Long|null} [deletedAt] SurveyReminder deletedAt
                 */

                /**
                 * Constructs a new SurveyReminder.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReminder.
                 * @implements ISurveyReminder
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyReminder=} [properties] Properties to set
                 */
                function SurveyReminder(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyReminder surveyReminderId.
                 * @member {string} surveyReminderId
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.surveyReminderId = "";

                /**
                 * SurveyReminder surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.surveyRequestId = "";

                /**
                 * SurveyReminder remindedAt.
                 * @member {Long} remindedAt
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.remindedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReminder contentType.
                 * @member {sharelib.INameOption|null|undefined} contentType
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.contentType = null;

                /**
                 * SurveyReminder content.
                 * @member {string} content
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.content = "";

                /**
                 * SurveyReminder sendTarget.
                 * @member {mtechnavi.api.survey.ISendTargetSetting|null|undefined} sendTarget
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.sendTarget = null;

                /**
                 * SurveyReminder manual.
                 * @member {boolean} manual
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.manual = false;

                /**
                 * SurveyReminder surveyReplyDueDateChangeId.
                 * @member {string} surveyReplyDueDateChangeId
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.surveyReplyDueDateChangeId = "";

                /**
                 * SurveyReminder sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.sharedProperties = null;

                /**
                 * SurveyReminder createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReminder updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReminder deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 */
                SurveyReminder.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyReminder message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyReminder.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        if (!$util.isString(message.surveyReminderId))
                            return "surveyReminderId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.remindedAt != null && message.hasOwnProperty("remindedAt"))
                        if (!$util.isInteger(message.remindedAt) && !(message.remindedAt && $util.isInteger(message.remindedAt.low) && $util.isInteger(message.remindedAt.high)))
                            return "remindedAt: integer|Long expected";
                    if (message.contentType != null && message.hasOwnProperty("contentType")) {
                        let error = $root.sharelib.NameOption.verify(message.contentType);
                        if (error)
                            return "contentType." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.sendTarget != null && message.hasOwnProperty("sendTarget")) {
                        let error = $root.mtechnavi.api.survey.SendTargetSetting.verify(message.sendTarget);
                        if (error)
                            return "sendTarget." + error;
                    }
                    if (message.manual != null && message.hasOwnProperty("manual"))
                        if (typeof message.manual !== "boolean")
                            return "manual: boolean expected";
                    if (message.surveyReplyDueDateChangeId != null && message.hasOwnProperty("surveyReplyDueDateChangeId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeId))
                            return "surveyReplyDueDateChangeId: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyReminder message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyReminder} SurveyReminder
                 */
                SurveyReminder.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyReminder)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyReminder();
                    if (object.surveyReminderId != null)
                        message.surveyReminderId = String(object.surveyReminderId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.remindedAt != null)
                        if ($util.Long)
                            (message.remindedAt = $util.Long.fromValue(object.remindedAt)).unsigned = false;
                        else if (typeof object.remindedAt === "string")
                            message.remindedAt = parseInt(object.remindedAt, 10);
                        else if (typeof object.remindedAt === "number")
                            message.remindedAt = object.remindedAt;
                        else if (typeof object.remindedAt === "object")
                            message.remindedAt = new $util.LongBits(object.remindedAt.low >>> 0, object.remindedAt.high >>> 0).toNumber();
                    if (object.contentType != null) {
                        if (typeof object.contentType !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReminder.contentType: object expected");
                        message.contentType = $root.sharelib.NameOption.fromObject(object.contentType);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.sendTarget != null) {
                        if (typeof object.sendTarget !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReminder.sendTarget: object expected");
                        message.sendTarget = $root.mtechnavi.api.survey.SendTargetSetting.fromObject(object.sendTarget);
                    }
                    if (object.manual != null)
                        message.manual = Boolean(object.manual);
                    if (object.surveyReplyDueDateChangeId != null)
                        message.surveyReplyDueDateChangeId = String(object.surveyReplyDueDateChangeId);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReminder.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyReminder message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @static
                 * @param {mtechnavi.api.survey.SurveyReminder} message SurveyReminder
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyReminder.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyReminderId = "";
                        object.surveyRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.remindedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.remindedAt = options.longs === String ? "0" : 0;
                        object.contentType = null;
                        object.content = "";
                        object.manual = false;
                        object.surveyReplyDueDateChangeId = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.sendTarget = null;
                    }
                    if (message.surveyReminderId != null && message.hasOwnProperty("surveyReminderId"))
                        object.surveyReminderId = message.surveyReminderId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.remindedAt != null && message.hasOwnProperty("remindedAt"))
                        if (typeof message.remindedAt === "number")
                            object.remindedAt = options.longs === String ? String(message.remindedAt) : message.remindedAt;
                        else
                            object.remindedAt = options.longs === String ? $util.Long.prototype.toString.call(message.remindedAt) : options.longs === Number ? new $util.LongBits(message.remindedAt.low >>> 0, message.remindedAt.high >>> 0).toNumber() : message.remindedAt;
                    if (message.contentType != null && message.hasOwnProperty("contentType"))
                        object.contentType = $root.sharelib.NameOption.toObject(message.contentType, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.manual != null && message.hasOwnProperty("manual"))
                        object.manual = message.manual;
                    if (message.surveyReplyDueDateChangeId != null && message.hasOwnProperty("surveyReplyDueDateChangeId"))
                        object.surveyReplyDueDateChangeId = message.surveyReplyDueDateChangeId;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.sendTarget != null && message.hasOwnProperty("sendTarget"))
                        object.sendTarget = $root.mtechnavi.api.survey.SendTargetSetting.toObject(message.sendTarget, options);
                    return object;
                };

                /**
                 * Converts this SurveyReminder to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyReminder
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyReminder.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyReminder;
            })();

            survey.SurveyReplyDueDateChangeResult = (function() {

                /**
                 * Properties of a SurveyReplyDueDateChangeResult.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyReplyDueDateChangeResult
                 * @property {string|null} [surveyReplyDueDateChangeResultId] SurveyReplyDueDateChangeResult surveyReplyDueDateChangeResultId
                 * @property {string|null} [surveyRequestId] SurveyReplyDueDateChangeResult surveyRequestId
                 * @property {Long|null} [changedAt] SurveyReplyDueDateChangeResult changedAt
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyReplyDueDateChangeResult replyDueDateDt
                 * @property {string|null} [comment] SurveyReplyDueDateChangeResult comment
                 * @property {string|null} [surveyReplyDueDateChangeRequestId] SurveyReplyDueDateChangeResult surveyReplyDueDateChangeRequestId
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyReplyDueDateChangeResult sharedProperties
                 * @property {Long|null} [createdAt] SurveyReplyDueDateChangeResult createdAt
                 * @property {Long|null} [updatedAt] SurveyReplyDueDateChangeResult updatedAt
                 * @property {Long|null} [deletedAt] SurveyReplyDueDateChangeResult deletedAt
                 */

                /**
                 * Constructs a new SurveyReplyDueDateChangeResult.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReplyDueDateChangeResult.
                 * @implements ISurveyReplyDueDateChangeResult
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyReplyDueDateChangeResult=} [properties] Properties to set
                 */
                function SurveyReplyDueDateChangeResult(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyReplyDueDateChangeResult surveyReplyDueDateChangeResultId.
                 * @member {string} surveyReplyDueDateChangeResultId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.surveyReplyDueDateChangeResultId = "";

                /**
                 * SurveyReplyDueDateChangeResult surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.surveyRequestId = "";

                /**
                 * SurveyReplyDueDateChangeResult changedAt.
                 * @member {Long} changedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.changedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeResult replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.replyDueDateDt = null;

                /**
                 * SurveyReplyDueDateChangeResult comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.comment = "";

                /**
                 * SurveyReplyDueDateChangeResult surveyReplyDueDateChangeRequestId.
                 * @member {string} surveyReplyDueDateChangeRequestId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.surveyReplyDueDateChangeRequestId = "";

                /**
                 * SurveyReplyDueDateChangeResult sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.sharedProperties = null;

                /**
                 * SurveyReplyDueDateChangeResult createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeResult updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeResult deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 */
                SurveyReplyDueDateChangeResult.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyReplyDueDateChangeResult message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyReplyDueDateChangeResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeResultId))
                            return "surveyReplyDueDateChangeResultId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.changedAt != null && message.hasOwnProperty("changedAt"))
                        if (!$util.isInteger(message.changedAt) && !(message.changedAt && $util.isInteger(message.changedAt.low) && $util.isInteger(message.changedAt.high)))
                            return "changedAt: integer|Long expected";
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeRequestId))
                            return "surveyReplyDueDateChangeRequestId: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyReplyDueDateChangeResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyReplyDueDateChangeResult} SurveyReplyDueDateChangeResult
                 */
                SurveyReplyDueDateChangeResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyReplyDueDateChangeResult();
                    if (object.surveyReplyDueDateChangeResultId != null)
                        message.surveyReplyDueDateChangeResultId = String(object.surveyReplyDueDateChangeResultId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.changedAt != null)
                        if ($util.Long)
                            (message.changedAt = $util.Long.fromValue(object.changedAt)).unsigned = false;
                        else if (typeof object.changedAt === "string")
                            message.changedAt = parseInt(object.changedAt, 10);
                        else if (typeof object.changedAt === "number")
                            message.changedAt = object.changedAt;
                        else if (typeof object.changedAt === "object")
                            message.changedAt = new $util.LongBits(object.changedAt.low >>> 0, object.changedAt.high >>> 0).toNumber();
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReplyDueDateChangeResult.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.surveyReplyDueDateChangeRequestId != null)
                        message.surveyReplyDueDateChangeRequestId = String(object.surveyReplyDueDateChangeRequestId);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReplyDueDateChangeResult.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyReplyDueDateChangeResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @static
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeResult} message SurveyReplyDueDateChangeResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyReplyDueDateChangeResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyReplyDueDateChangeResultId = "";
                        object.surveyRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.changedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.changedAt = options.longs === String ? "0" : 0;
                        object.replyDueDateDt = null;
                        object.comment = "";
                        object.surveyReplyDueDateChangeRequestId = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyReplyDueDateChangeResultId != null && message.hasOwnProperty("surveyReplyDueDateChangeResultId"))
                        object.surveyReplyDueDateChangeResultId = message.surveyReplyDueDateChangeResultId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.changedAt != null && message.hasOwnProperty("changedAt"))
                        if (typeof message.changedAt === "number")
                            object.changedAt = options.longs === String ? String(message.changedAt) : message.changedAt;
                        else
                            object.changedAt = options.longs === String ? $util.Long.prototype.toString.call(message.changedAt) : options.longs === Number ? new $util.LongBits(message.changedAt.low >>> 0, message.changedAt.high >>> 0).toNumber() : message.changedAt;
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        object.surveyReplyDueDateChangeRequestId = message.surveyReplyDueDateChangeRequestId;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this SurveyReplyDueDateChangeResult to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyReplyDueDateChangeResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyReplyDueDateChangeResult;
            })();

            survey.SurveyReplyDueDateChangeRequest = (function() {

                /**
                 * Properties of a SurveyReplyDueDateChangeRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyReplyDueDateChangeRequest
                 * @property {string|null} [surveyReplyDueDateChangeRequestId] SurveyReplyDueDateChangeRequest surveyReplyDueDateChangeRequestId
                 * @property {string|null} [surveyRequestId] SurveyReplyDueDateChangeRequest surveyRequestId
                 * @property {Long|null} [requestedAt] SurveyReplyDueDateChangeRequest requestedAt
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyReplyDueDateChangeRequest replyDueDateDt
                 * @property {string|null} [comment] SurveyReplyDueDateChangeRequest comment
                 * @property {string|null} [surveyReplyDueDateChangeId] SurveyReplyDueDateChangeRequest surveyReplyDueDateChangeId
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyReplyDueDateChangeRequest sharedProperties
                 * @property {Long|null} [createdAt] SurveyReplyDueDateChangeRequest createdAt
                 * @property {Long|null} [updatedAt] SurveyReplyDueDateChangeRequest updatedAt
                 * @property {Long|null} [deletedAt] SurveyReplyDueDateChangeRequest deletedAt
                 */

                /**
                 * Constructs a new SurveyReplyDueDateChangeRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReplyDueDateChangeRequest.
                 * @implements ISurveyReplyDueDateChangeRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyReplyDueDateChangeRequest=} [properties] Properties to set
                 */
                function SurveyReplyDueDateChangeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyReplyDueDateChangeRequest surveyReplyDueDateChangeRequestId.
                 * @member {string} surveyReplyDueDateChangeRequestId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.surveyReplyDueDateChangeRequestId = "";

                /**
                 * SurveyReplyDueDateChangeRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.surveyRequestId = "";

                /**
                 * SurveyReplyDueDateChangeRequest requestedAt.
                 * @member {Long} requestedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.requestedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeRequest replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.replyDueDateDt = null;

                /**
                 * SurveyReplyDueDateChangeRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.comment = "";

                /**
                 * SurveyReplyDueDateChangeRequest surveyReplyDueDateChangeId.
                 * @member {string} surveyReplyDueDateChangeId
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.surveyReplyDueDateChangeId = "";

                /**
                 * SurveyReplyDueDateChangeRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.sharedProperties = null;

                /**
                 * SurveyReplyDueDateChangeRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReplyDueDateChangeRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 */
                SurveyReplyDueDateChangeRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyReplyDueDateChangeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyReplyDueDateChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeRequestId))
                            return "surveyReplyDueDateChangeRequestId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (!$util.isInteger(message.requestedAt) && !(message.requestedAt && $util.isInteger(message.requestedAt.low) && $util.isInteger(message.requestedAt.high)))
                            return "requestedAt: integer|Long expected";
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.surveyReplyDueDateChangeId != null && message.hasOwnProperty("surveyReplyDueDateChangeId"))
                        if (!$util.isString(message.surveyReplyDueDateChangeId))
                            return "surveyReplyDueDateChangeId: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyReplyDueDateChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyReplyDueDateChangeRequest} SurveyReplyDueDateChangeRequest
                 */
                SurveyReplyDueDateChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyReplyDueDateChangeRequest();
                    if (object.surveyReplyDueDateChangeRequestId != null)
                        message.surveyReplyDueDateChangeRequestId = String(object.surveyReplyDueDateChangeRequestId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.requestedAt != null)
                        if ($util.Long)
                            (message.requestedAt = $util.Long.fromValue(object.requestedAt)).unsigned = false;
                        else if (typeof object.requestedAt === "string")
                            message.requestedAt = parseInt(object.requestedAt, 10);
                        else if (typeof object.requestedAt === "number")
                            message.requestedAt = object.requestedAt;
                        else if (typeof object.requestedAt === "object")
                            message.requestedAt = new $util.LongBits(object.requestedAt.low >>> 0, object.requestedAt.high >>> 0).toNumber();
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.surveyReplyDueDateChangeId != null)
                        message.surveyReplyDueDateChangeId = String(object.surveyReplyDueDateChangeId);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReplyDueDateChangeRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyReplyDueDateChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @static
                 * @param {mtechnavi.api.survey.SurveyReplyDueDateChangeRequest} message SurveyReplyDueDateChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyReplyDueDateChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyReplyDueDateChangeRequestId = "";
                        object.surveyRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.requestedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestedAt = options.longs === String ? "0" : 0;
                        object.replyDueDateDt = null;
                        object.comment = "";
                        object.surveyReplyDueDateChangeId = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyReplyDueDateChangeRequestId != null && message.hasOwnProperty("surveyReplyDueDateChangeRequestId"))
                        object.surveyReplyDueDateChangeRequestId = message.surveyReplyDueDateChangeRequestId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (typeof message.requestedAt === "number")
                            object.requestedAt = options.longs === String ? String(message.requestedAt) : message.requestedAt;
                        else
                            object.requestedAt = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAt) : options.longs === Number ? new $util.LongBits(message.requestedAt.low >>> 0, message.requestedAt.high >>> 0).toNumber() : message.requestedAt;
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.surveyReplyDueDateChangeId != null && message.hasOwnProperty("surveyReplyDueDateChangeId"))
                        object.surveyReplyDueDateChangeId = message.surveyReplyDueDateChangeId;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this SurveyReplyDueDateChangeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyReplyDueDateChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyReplyDueDateChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyReplyDueDateChangeRequest;
            })();

            survey.SurveyReception = (function() {

                /**
                 * Properties of a SurveyReception.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyReception
                 * @property {string|null} [surveyReceptionId] SurveyReception surveyReceptionId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] SurveyReception systemNotificationUsers
                 * @property {Long|null} [receiptedAt] SurveyReception receiptedAt
                 * @property {Array.<sharelib.IAttachment>|null} [replyAttachments] SurveyReception replyAttachments
                 * @property {Array.<string>|null} [lockedFormIds] SurveyReception lockedFormIds
                 * @property {sharelib.INameOption|null} [surveyResultStatus] SurveyReception surveyResultStatus
                 * @property {sharelib.IDatetime|null} [latestReplyDueDateDt] SurveyReception latestReplyDueDateDt
                 * @property {Long|null} [latestSendedAt] SurveyReception latestSendedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyReception createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyReception updatedProperties
                 * @property {Long|null} [createdAt] SurveyReception createdAt
                 * @property {Long|null} [updatedAt] SurveyReception updatedAt
                 * @property {Long|null} [deletedAt] SurveyReception deletedAt
                 */

                /**
                 * Constructs a new SurveyReception.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReception.
                 * @implements ISurveyReception
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyReception=} [properties] Properties to set
                 */
                function SurveyReception(properties) {
                    this.systemNotificationUsers = [];
                    this.replyAttachments = [];
                    this.lockedFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyReception surveyReceptionId.
                 * @member {string} surveyReceptionId
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.surveyReceptionId = "";

                /**
                 * SurveyReception systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * SurveyReception receiptedAt.
                 * @member {Long} receiptedAt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.receiptedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReception replyAttachments.
                 * @member {Array.<sharelib.IAttachment>} replyAttachments
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.replyAttachments = $util.emptyArray;

                /**
                 * SurveyReception lockedFormIds.
                 * @member {Array.<string>} lockedFormIds
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.lockedFormIds = $util.emptyArray;

                /**
                 * SurveyReception surveyResultStatus.
                 * @member {sharelib.INameOption|null|undefined} surveyResultStatus
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.surveyResultStatus = null;

                /**
                 * SurveyReception latestReplyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} latestReplyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.latestReplyDueDateDt = null;

                /**
                 * SurveyReception latestSendedAt.
                 * @member {Long} latestSendedAt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.latestSendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReception createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.createdProperties = null;

                /**
                 * SurveyReception updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.updatedProperties = null;

                /**
                 * SurveyReception createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReception updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyReception deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 */
                SurveyReception.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyReception message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyReception.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        if (!$util.isString(message.surveyReceptionId))
                            return "surveyReceptionId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.receiptedAt != null && message.hasOwnProperty("receiptedAt"))
                        if (!$util.isInteger(message.receiptedAt) && !(message.receiptedAt && $util.isInteger(message.receiptedAt.low) && $util.isInteger(message.receiptedAt.high)))
                            return "receiptedAt: integer|Long expected";
                    if (message.replyAttachments != null && message.hasOwnProperty("replyAttachments")) {
                        if (!Array.isArray(message.replyAttachments))
                            return "replyAttachments: array expected";
                        for (let i = 0; i < message.replyAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.replyAttachments[i]);
                            if (error)
                                return "replyAttachments." + error;
                        }
                    }
                    if (message.lockedFormIds != null && message.hasOwnProperty("lockedFormIds")) {
                        if (!Array.isArray(message.lockedFormIds))
                            return "lockedFormIds: array expected";
                        for (let i = 0; i < message.lockedFormIds.length; ++i)
                            if (!$util.isString(message.lockedFormIds[i]))
                                return "lockedFormIds: string[] expected";
                    }
                    if (message.surveyResultStatus != null && message.hasOwnProperty("surveyResultStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.surveyResultStatus);
                        if (error)
                            return "surveyResultStatus." + error;
                    }
                    if (message.latestReplyDueDateDt != null && message.hasOwnProperty("latestReplyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.latestReplyDueDateDt);
                        if (error)
                            return "latestReplyDueDateDt." + error;
                    }
                    if (message.latestSendedAt != null && message.hasOwnProperty("latestSendedAt"))
                        if (!$util.isInteger(message.latestSendedAt) && !(message.latestSendedAt && $util.isInteger(message.latestSendedAt.low) && $util.isInteger(message.latestSendedAt.high)))
                            return "latestSendedAt: integer|Long expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyReception message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyReception} SurveyReception
                 */
                SurveyReception.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyReception)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyReception();
                    if (object.surveyReceptionId != null)
                        message.surveyReceptionId = String(object.surveyReceptionId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyReception.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.receiptedAt != null)
                        if ($util.Long)
                            (message.receiptedAt = $util.Long.fromValue(object.receiptedAt)).unsigned = false;
                        else if (typeof object.receiptedAt === "string")
                            message.receiptedAt = parseInt(object.receiptedAt, 10);
                        else if (typeof object.receiptedAt === "number")
                            message.receiptedAt = object.receiptedAt;
                        else if (typeof object.receiptedAt === "object")
                            message.receiptedAt = new $util.LongBits(object.receiptedAt.low >>> 0, object.receiptedAt.high >>> 0).toNumber();
                    if (object.replyAttachments) {
                        if (!Array.isArray(object.replyAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.replyAttachments: array expected");
                        message.replyAttachments = [];
                        for (let i = 0; i < object.replyAttachments.length; ++i) {
                            if (typeof object.replyAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyReception.replyAttachments: object expected");
                            message.replyAttachments[i] = $root.sharelib.Attachment.fromObject(object.replyAttachments[i]);
                        }
                    }
                    if (object.lockedFormIds) {
                        if (!Array.isArray(object.lockedFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.lockedFormIds: array expected");
                        message.lockedFormIds = [];
                        for (let i = 0; i < object.lockedFormIds.length; ++i)
                            message.lockedFormIds[i] = String(object.lockedFormIds[i]);
                    }
                    if (object.surveyResultStatus != null) {
                        if (typeof object.surveyResultStatus !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.surveyResultStatus: object expected");
                        message.surveyResultStatus = $root.sharelib.NameOption.fromObject(object.surveyResultStatus);
                    }
                    if (object.latestReplyDueDateDt != null) {
                        if (typeof object.latestReplyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.latestReplyDueDateDt: object expected");
                        message.latestReplyDueDateDt = $root.sharelib.Datetime.fromObject(object.latestReplyDueDateDt);
                    }
                    if (object.latestSendedAt != null)
                        if ($util.Long)
                            (message.latestSendedAt = $util.Long.fromValue(object.latestSendedAt)).unsigned = false;
                        else if (typeof object.latestSendedAt === "string")
                            message.latestSendedAt = parseInt(object.latestSendedAt, 10);
                        else if (typeof object.latestSendedAt === "number")
                            message.latestSendedAt = object.latestSendedAt;
                        else if (typeof object.latestSendedAt === "object")
                            message.latestSendedAt = new $util.LongBits(object.latestSendedAt.low >>> 0, object.latestSendedAt.high >>> 0).toNumber();
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReception.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyReception message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @static
                 * @param {mtechnavi.api.survey.SurveyReception} message SurveyReception
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyReception.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.systemNotificationUsers = [];
                        object.replyAttachments = [];
                        object.lockedFormIds = [];
                    }
                    if (options.defaults) {
                        object.surveyReceptionId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.receiptedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.receiptedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.surveyResultStatus = null;
                        object.latestReplyDueDateDt = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.latestSendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.latestSendedAt = options.longs === String ? "0" : 0;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                    }
                    if (message.surveyReceptionId != null && message.hasOwnProperty("surveyReceptionId"))
                        object.surveyReceptionId = message.surveyReceptionId;
                    if (message.receiptedAt != null && message.hasOwnProperty("receiptedAt"))
                        if (typeof message.receiptedAt === "number")
                            object.receiptedAt = options.longs === String ? String(message.receiptedAt) : message.receiptedAt;
                        else
                            object.receiptedAt = options.longs === String ? $util.Long.prototype.toString.call(message.receiptedAt) : options.longs === Number ? new $util.LongBits(message.receiptedAt.low >>> 0, message.receiptedAt.high >>> 0).toNumber() : message.receiptedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.replyAttachments && message.replyAttachments.length) {
                        object.replyAttachments = [];
                        for (let j = 0; j < message.replyAttachments.length; ++j)
                            object.replyAttachments[j] = $root.sharelib.Attachment.toObject(message.replyAttachments[j], options);
                    }
                    if (message.surveyResultStatus != null && message.hasOwnProperty("surveyResultStatus"))
                        object.surveyResultStatus = $root.sharelib.NameOption.toObject(message.surveyResultStatus, options);
                    if (message.latestReplyDueDateDt != null && message.hasOwnProperty("latestReplyDueDateDt"))
                        object.latestReplyDueDateDt = $root.sharelib.Datetime.toObject(message.latestReplyDueDateDt, options);
                    if (message.latestSendedAt != null && message.hasOwnProperty("latestSendedAt"))
                        if (typeof message.latestSendedAt === "number")
                            object.latestSendedAt = options.longs === String ? String(message.latestSendedAt) : message.latestSendedAt;
                        else
                            object.latestSendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.latestSendedAt) : options.longs === Number ? new $util.LongBits(message.latestSendedAt.low >>> 0, message.latestSendedAt.high >>> 0).toNumber() : message.latestSendedAt;
                    if (message.lockedFormIds && message.lockedFormIds.length) {
                        object.lockedFormIds = [];
                        for (let j = 0; j < message.lockedFormIds.length; ++j)
                            object.lockedFormIds[j] = message.lockedFormIds[j];
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    return object;
                };

                /**
                 * Converts this SurveyReception to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyReception
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyReception.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyReception;
            })();

            survey.SurveyResult = (function() {

                /**
                 * Properties of a SurveyResult.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyResult
                 * @property {string|null} [surveyResultId] SurveyResult surveyResultId
                 * @property {string|null} [surveyRequestId] SurveyResult surveyRequestId
                 * @property {Long|null} [sendedAt] SurveyResult sendedAt
                 * @property {boolean|null} [partialReplyed] SurveyResult partialReplyed
                 * @property {boolean|null} [declined] SurveyResult declined
                 * @property {string|null} [comment] SurveyResult comment
                 * @property {Array.<sharelib.IAttachment>|null} [replyAttachments] SurveyResult replyAttachments
                 * @property {Array.<string>|null} [lockedFormIds] SurveyResult lockedFormIds
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyResult sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyResult createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyResult updatedProperties
                 * @property {Long|null} [createdAt] SurveyResult createdAt
                 * @property {Long|null} [updatedAt] SurveyResult updatedAt
                 * @property {Long|null} [deletedAt] SurveyResult deletedAt
                 */

                /**
                 * Constructs a new SurveyResult.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyResult.
                 * @implements ISurveyResult
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyResult=} [properties] Properties to set
                 */
                function SurveyResult(properties) {
                    this.replyAttachments = [];
                    this.lockedFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyResult surveyResultId.
                 * @member {string} surveyResultId
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.surveyResultId = "";

                /**
                 * SurveyResult surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.surveyRequestId = "";

                /**
                 * SurveyResult sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResult partialReplyed.
                 * @member {boolean} partialReplyed
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.partialReplyed = false;

                /**
                 * SurveyResult declined.
                 * @member {boolean} declined
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.declined = false;

                /**
                 * SurveyResult comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.comment = "";

                /**
                 * SurveyResult replyAttachments.
                 * @member {Array.<sharelib.IAttachment>} replyAttachments
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.replyAttachments = $util.emptyArray;

                /**
                 * SurveyResult lockedFormIds.
                 * @member {Array.<string>} lockedFormIds
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.lockedFormIds = $util.emptyArray;

                /**
                 * SurveyResult sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.sharedProperties = null;

                /**
                 * SurveyResult createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.createdProperties = null;

                /**
                 * SurveyResult updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.updatedProperties = null;

                /**
                 * SurveyResult createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResult updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResult deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 */
                SurveyResult.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyResult message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        if (!$util.isString(message.surveyResultId))
                            return "surveyResultId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.partialReplyed != null && message.hasOwnProperty("partialReplyed"))
                        if (typeof message.partialReplyed !== "boolean")
                            return "partialReplyed: boolean expected";
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        if (typeof message.declined !== "boolean")
                            return "declined: boolean expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.replyAttachments != null && message.hasOwnProperty("replyAttachments")) {
                        if (!Array.isArray(message.replyAttachments))
                            return "replyAttachments: array expected";
                        for (let i = 0; i < message.replyAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.replyAttachments[i]);
                            if (error)
                                return "replyAttachments." + error;
                        }
                    }
                    if (message.lockedFormIds != null && message.hasOwnProperty("lockedFormIds")) {
                        if (!Array.isArray(message.lockedFormIds))
                            return "lockedFormIds: array expected";
                        for (let i = 0; i < message.lockedFormIds.length; ++i)
                            if (!$util.isString(message.lockedFormIds[i]))
                                return "lockedFormIds: string[] expected";
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyResult} SurveyResult
                 */
                SurveyResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyResult)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyResult();
                    if (object.surveyResultId != null)
                        message.surveyResultId = String(object.surveyResultId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.partialReplyed != null)
                        message.partialReplyed = Boolean(object.partialReplyed);
                    if (object.declined != null)
                        message.declined = Boolean(object.declined);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.replyAttachments) {
                        if (!Array.isArray(object.replyAttachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyResult.replyAttachments: array expected");
                        message.replyAttachments = [];
                        for (let i = 0; i < object.replyAttachments.length; ++i) {
                            if (typeof object.replyAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyResult.replyAttachments: object expected");
                            message.replyAttachments[i] = $root.sharelib.Attachment.fromObject(object.replyAttachments[i]);
                        }
                    }
                    if (object.lockedFormIds) {
                        if (!Array.isArray(object.lockedFormIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyResult.lockedFormIds: array expected");
                        message.lockedFormIds = [];
                        for (let i = 0; i < object.lockedFormIds.length; ++i)
                            message.lockedFormIds[i] = String(object.lockedFormIds[i]);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResult.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResult.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResult.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @static
                 * @param {mtechnavi.api.survey.SurveyResult} message SurveyResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.replyAttachments = [];
                        object.lockedFormIds = [];
                    }
                    if (options.defaults) {
                        object.surveyResultId = "";
                        object.surveyRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.partialReplyed = false;
                        object.declined = false;
                        object.comment = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                    }
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        object.surveyResultId = message.surveyResultId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.partialReplyed != null && message.hasOwnProperty("partialReplyed"))
                        object.partialReplyed = message.partialReplyed;
                    if (message.declined != null && message.hasOwnProperty("declined"))
                        object.declined = message.declined;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.replyAttachments && message.replyAttachments.length) {
                        object.replyAttachments = [];
                        for (let j = 0; j < message.replyAttachments.length; ++j)
                            object.replyAttachments[j] = $root.sharelib.Attachment.toObject(message.replyAttachments[j], options);
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.lockedFormIds && message.lockedFormIds.length) {
                        object.lockedFormIds = [];
                        for (let j = 0; j < message.lockedFormIds.length; ++j)
                            object.lockedFormIds[j] = message.lockedFormIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SurveyResult to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyResult;
            })();

            survey.SurveyResultChangeRequest = (function() {

                /**
                 * Properties of a SurveyResultChangeRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyResultChangeRequest
                 * @property {string|null} [surveyResultChangeRequestId] SurveyResultChangeRequest surveyResultChangeRequestId
                 * @property {string|null} [surveyRequestId] SurveyResultChangeRequest surveyRequestId
                 * @property {string|null} [surveyResultId] SurveyResultChangeRequest surveyResultId
                 * @property {Long|null} [requestedAt] SurveyResultChangeRequest requestedAt
                 * @property {string|null} [comment] SurveyResultChangeRequest comment
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyResultChangeRequest sharedProperties
                 * @property {Long|null} [createdAt] SurveyResultChangeRequest createdAt
                 * @property {Long|null} [updatedAt] SurveyResultChangeRequest updatedAt
                 * @property {Long|null} [deletedAt] SurveyResultChangeRequest deletedAt
                 */

                /**
                 * Constructs a new SurveyResultChangeRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyResultChangeRequest.
                 * @implements ISurveyResultChangeRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyResultChangeRequest=} [properties] Properties to set
                 */
                function SurveyResultChangeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyResultChangeRequest surveyResultChangeRequestId.
                 * @member {string} surveyResultChangeRequestId
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.surveyResultChangeRequestId = "";

                /**
                 * SurveyResultChangeRequest surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.surveyRequestId = "";

                /**
                 * SurveyResultChangeRequest surveyResultId.
                 * @member {string} surveyResultId
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.surveyResultId = "";

                /**
                 * SurveyResultChangeRequest requestedAt.
                 * @member {Long} requestedAt
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.requestedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultChangeRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.comment = "";

                /**
                 * SurveyResultChangeRequest sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.sharedProperties = null;

                /**
                 * SurveyResultChangeRequest createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultChangeRequest updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultChangeRequest deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 */
                SurveyResultChangeRequest.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyResultChangeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyResultChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        if (!$util.isString(message.surveyResultChangeRequestId))
                            return "surveyResultChangeRequestId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        if (!$util.isString(message.surveyResultId))
                            return "surveyResultId: string expected";
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (!$util.isInteger(message.requestedAt) && !(message.requestedAt && $util.isInteger(message.requestedAt.low) && $util.isInteger(message.requestedAt.high)))
                            return "requestedAt: integer|Long expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyResultChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyResultChangeRequest} SurveyResultChangeRequest
                 */
                SurveyResultChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyResultChangeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyResultChangeRequest();
                    if (object.surveyResultChangeRequestId != null)
                        message.surveyResultChangeRequestId = String(object.surveyResultChangeRequestId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.surveyResultId != null)
                        message.surveyResultId = String(object.surveyResultId);
                    if (object.requestedAt != null)
                        if ($util.Long)
                            (message.requestedAt = $util.Long.fromValue(object.requestedAt)).unsigned = false;
                        else if (typeof object.requestedAt === "string")
                            message.requestedAt = parseInt(object.requestedAt, 10);
                        else if (typeof object.requestedAt === "number")
                            message.requestedAt = object.requestedAt;
                        else if (typeof object.requestedAt === "object")
                            message.requestedAt = new $util.LongBits(object.requestedAt.low >>> 0, object.requestedAt.high >>> 0).toNumber();
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResultChangeRequest.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyResultChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @static
                 * @param {mtechnavi.api.survey.SurveyResultChangeRequest} message SurveyResultChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyResultChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyResultChangeRequestId = "";
                        object.surveyRequestId = "";
                        object.surveyResultId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.requestedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestedAt = options.longs === String ? "0" : 0;
                        object.comment = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyResultChangeRequestId != null && message.hasOwnProperty("surveyResultChangeRequestId"))
                        object.surveyResultChangeRequestId = message.surveyResultChangeRequestId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        object.surveyResultId = message.surveyResultId;
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (typeof message.requestedAt === "number")
                            object.requestedAt = options.longs === String ? String(message.requestedAt) : message.requestedAt;
                        else
                            object.requestedAt = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAt) : options.longs === Number ? new $util.LongBits(message.requestedAt.low >>> 0, message.requestedAt.high >>> 0).toNumber() : message.requestedAt;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this SurveyResultChangeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyResultChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyResultChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyResultChangeRequest;
            })();

            survey.Discard = (function() {

                /**
                 * Properties of a Discard.
                 * @memberof mtechnavi.api.survey
                 * @interface IDiscard
                 * @property {string|null} [discardId] Discard discardId
                 * @property {string|null} [urn] Discard urn
                 * @property {string|null} [typeName] Discard typeName
                 * @property {string|null} [recordId] Discard recordId
                 * @property {string|null} [comment] Discard comment
                 * @property {Long|null} [discardedAt] Discard discardedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Discard sharedProperties
                 * @property {Long|null} [createdAt] Discard createdAt
                 * @property {Long|null} [updatedAt] Discard updatedAt
                 * @property {Long|null} [deletedAt] Discard deletedAt
                 */

                /**
                 * Constructs a new Discard.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a Discard.
                 * @implements IDiscard
                 * @constructor
                 * @param {mtechnavi.api.survey.IDiscard=} [properties] Properties to set
                 */
                function Discard(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Discard discardId.
                 * @member {string} discardId
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.discardId = "";

                /**
                 * Discard urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.urn = "";

                /**
                 * Discard typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.typeName = "";

                /**
                 * Discard recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.recordId = "";

                /**
                 * Discard comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.comment = "";

                /**
                 * Discard discardedAt.
                 * @member {Long} discardedAt
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.discardedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.sharedProperties = null;

                /**
                 * Discard createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Discard deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 */
                Discard.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Discard message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.Discard
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Discard.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.discardId != null && message.hasOwnProperty("discardId"))
                        if (!$util.isString(message.discardId))
                            return "discardId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.discardedAt != null && message.hasOwnProperty("discardedAt"))
                        if (!$util.isInteger(message.discardedAt) && !(message.discardedAt && $util.isInteger(message.discardedAt.low) && $util.isInteger(message.discardedAt.high)))
                            return "discardedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Discard message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.Discard
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.Discard} Discard
                 */
                Discard.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.Discard)
                        return object;
                    let message = new $root.mtechnavi.api.survey.Discard();
                    if (object.discardId != null)
                        message.discardId = String(object.discardId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.discardedAt != null)
                        if ($util.Long)
                            (message.discardedAt = $util.Long.fromValue(object.discardedAt)).unsigned = false;
                        else if (typeof object.discardedAt === "string")
                            message.discardedAt = parseInt(object.discardedAt, 10);
                        else if (typeof object.discardedAt === "number")
                            message.discardedAt = object.discardedAt;
                        else if (typeof object.discardedAt === "object")
                            message.discardedAt = new $util.LongBits(object.discardedAt.low >>> 0, object.discardedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.Discard.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Discard message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.Discard
                 * @static
                 * @param {mtechnavi.api.survey.Discard} message Discard
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Discard.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.discardId = "";
                        object.urn = "";
                        object.comment = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.discardedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.discardedAt = options.longs === String ? "0" : 0;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.discardId != null && message.hasOwnProperty("discardId"))
                        object.discardId = message.discardId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.discardedAt != null && message.hasOwnProperty("discardedAt"))
                        if (typeof message.discardedAt === "number")
                            object.discardedAt = options.longs === String ? String(message.discardedAt) : message.discardedAt;
                        else
                            object.discardedAt = options.longs === String ? $util.Long.prototype.toString.call(message.discardedAt) : options.longs === Number ? new $util.LongBits(message.discardedAt.low >>> 0, message.discardedAt.high >>> 0).toNumber() : message.discardedAt;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    return object;
                };

                /**
                 * Converts this Discard to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.Discard
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Discard.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Discard;
            })();

            survey.Complete = (function() {

                /**
                 * Properties of a Complete.
                 * @memberof mtechnavi.api.survey
                 * @interface IComplete
                 * @property {string|null} [completeId] Complete completeId
                 * @property {string|null} [urn] Complete urn
                 * @property {string|null} [typeName] Complete typeName
                 * @property {string|null} [recordId] Complete recordId
                 * @property {Long|null} [completedAt] Complete completedAt
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Complete sharedProperties
                 * @property {Long|null} [createdAt] Complete createdAt
                 * @property {Long|null} [updatedAt] Complete updatedAt
                 * @property {Long|null} [deletedAt] Complete deletedAt
                 */

                /**
                 * Constructs a new Complete.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a Complete.
                 * @implements IComplete
                 * @constructor
                 * @param {mtechnavi.api.survey.IComplete=} [properties] Properties to set
                 */
                function Complete(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Complete completeId.
                 * @member {string} completeId
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.completeId = "";

                /**
                 * Complete urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.urn = "";

                /**
                 * Complete typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.typeName = "";

                /**
                 * Complete recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.recordId = "";

                /**
                 * Complete completedAt.
                 * @member {Long} completedAt
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.completedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.sharedProperties = null;

                /**
                 * Complete createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Complete deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 */
                Complete.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Complete message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.Complete
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Complete.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.completeId != null && message.hasOwnProperty("completeId"))
                        if (!$util.isString(message.completeId))
                            return "completeId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (!$util.isInteger(message.completedAt) && !(message.completedAt && $util.isInteger(message.completedAt.low) && $util.isInteger(message.completedAt.high)))
                            return "completedAt: integer|Long expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Complete message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.Complete
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.Complete} Complete
                 */
                Complete.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.Complete)
                        return object;
                    let message = new $root.mtechnavi.api.survey.Complete();
                    if (object.completeId != null)
                        message.completeId = String(object.completeId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.completedAt != null)
                        if ($util.Long)
                            (message.completedAt = $util.Long.fromValue(object.completedAt)).unsigned = false;
                        else if (typeof object.completedAt === "string")
                            message.completedAt = parseInt(object.completedAt, 10);
                        else if (typeof object.completedAt === "number")
                            message.completedAt = object.completedAt;
                        else if (typeof object.completedAt === "object")
                            message.completedAt = new $util.LongBits(object.completedAt.low >>> 0, object.completedAt.high >>> 0).toNumber();
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.Complete.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Complete message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.Complete
                 * @static
                 * @param {mtechnavi.api.survey.Complete} message Complete
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Complete.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.completeId = "";
                        object.urn = "";
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.completedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.completedAt = options.longs === String ? "0" : 0;
                        object.typeName = "";
                        object.recordId = "";
                    }
                    if (message.completeId != null && message.hasOwnProperty("completeId"))
                        object.completeId = message.completeId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (typeof message.completedAt === "number")
                            object.completedAt = options.longs === String ? String(message.completedAt) : message.completedAt;
                        else
                            object.completedAt = options.longs === String ? $util.Long.prototype.toString.call(message.completedAt) : options.longs === Number ? new $util.LongBits(message.completedAt.low >>> 0, message.completedAt.high >>> 0).toNumber() : message.completedAt;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    return object;
                };

                /**
                 * Converts this Complete to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.Complete
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Complete.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Complete;
            })();

            survey.History = (function() {

                /**
                 * Properties of a History.
                 * @memberof mtechnavi.api.survey
                 * @interface IHistory
                 * @property {string|null} [historyId] History historyId
                 * @property {string|null} [urn] History urn
                 * @property {string|null} [typeName] History typeName
                 * @property {string|null} [recordId] History recordId
                 * @property {string|null} [historyCategoryName] History historyCategoryName
                 * @property {string|null} [contentName] History contentName
                 * @property {Array.<string>|null} [contentParamaters] History contentParamaters
                 * @property {Long|null} [occurredAt] History occurredAt
                 * @property {Long|null} [level] History level
                 * @property {Long|null} [createdAt] History createdAt
                 * @property {Long|null} [updatedAt] History updatedAt
                 * @property {Long|null} [deletedAt] History deletedAt
                 */

                /**
                 * Constructs a new History.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a History.
                 * @implements IHistory
                 * @constructor
                 * @param {mtechnavi.api.survey.IHistory=} [properties] Properties to set
                 */
                function History(properties) {
                    this.contentParamaters = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * History historyId.
                 * @member {string} historyId
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.historyId = "";

                /**
                 * History urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.urn = "";

                /**
                 * History typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.typeName = "";

                /**
                 * History recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.recordId = "";

                /**
                 * History historyCategoryName.
                 * @member {string} historyCategoryName
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.historyCategoryName = "";

                /**
                 * History contentName.
                 * @member {string} contentName
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.contentName = "";

                /**
                 * History contentParamaters.
                 * @member {Array.<string>} contentParamaters
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.contentParamaters = $util.emptyArray;

                /**
                 * History occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 */
                History.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a History message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.History
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                History.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        if (!$util.isString(message.historyId))
                            return "historyId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        if (!$util.isString(message.historyCategoryName))
                            return "historyCategoryName: string expected";
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        if (!$util.isString(message.contentName))
                            return "contentName: string expected";
                    if (message.contentParamaters != null && message.hasOwnProperty("contentParamaters")) {
                        if (!Array.isArray(message.contentParamaters))
                            return "contentParamaters: array expected";
                        for (let i = 0; i < message.contentParamaters.length; ++i)
                            if (!$util.isString(message.contentParamaters[i]))
                                return "contentParamaters: string[] expected";
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a History message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.History
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.History} History
                 */
                History.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.History)
                        return object;
                    let message = new $root.mtechnavi.api.survey.History();
                    if (object.historyId != null)
                        message.historyId = String(object.historyId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.historyCategoryName != null)
                        message.historyCategoryName = String(object.historyCategoryName);
                    if (object.contentName != null)
                        message.contentName = String(object.contentName);
                    if (object.contentParamaters) {
                        if (!Array.isArray(object.contentParamaters))
                            throw TypeError(".mtechnavi.api.survey.History.contentParamaters: array expected");
                        message.contentParamaters = [];
                        for (let i = 0; i < object.contentParamaters.length; ++i)
                            message.contentParamaters[i] = String(object.contentParamaters[i]);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a History message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.History
                 * @static
                 * @param {mtechnavi.api.survey.History} message History
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                History.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.contentParamaters = [];
                    if (options.defaults) {
                        object.historyId = "";
                        object.urn = "";
                        object.historyCategoryName = "";
                        object.contentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.typeName = "";
                        object.recordId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                    }
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        object.historyId = message.historyId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        object.historyCategoryName = message.historyCategoryName;
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        object.contentName = message.contentName;
                    if (message.contentParamaters && message.contentParamaters.length) {
                        object.contentParamaters = [];
                        for (let j = 0; j < message.contentParamaters.length; ++j)
                            object.contentParamaters[j] = message.contentParamaters[j];
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    return object;
                };

                /**
                 * Converts this History to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.History
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                History.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return History;
            })();

            survey.ExportedSurveyResultReplyAttachment = (function() {

                /**
                 * Properties of an ExportedSurveyResultReplyAttachment.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportedSurveyResultReplyAttachment
                 * @property {string|null} [exportedSurveyResultReplyAttachmentId] ExportedSurveyResultReplyAttachment exportedSurveyResultReplyAttachmentId
                 * @property {string|null} [surveyResultId] ExportedSurveyResultReplyAttachment surveyResultId
                 * @property {string|null} [surveyRequestId] ExportedSurveyResultReplyAttachment surveyRequestId
                 * @property {sharelib.IEmbeddedUser|null} [createdBy] ExportedSurveyResultReplyAttachment createdBy
                 * @property {Long|null} [createdAt] ExportedSurveyResultReplyAttachment createdAt
                 * @property {Long|null} [updatedAt] ExportedSurveyResultReplyAttachment updatedAt
                 * @property {Long|null} [deletedAt] ExportedSurveyResultReplyAttachment deletedAt
                 */

                /**
                 * Constructs a new ExportedSurveyResultReplyAttachment.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportedSurveyResultReplyAttachment.
                 * @implements IExportedSurveyResultReplyAttachment
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportedSurveyResultReplyAttachment=} [properties] Properties to set
                 */
                function ExportedSurveyResultReplyAttachment(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportedSurveyResultReplyAttachment exportedSurveyResultReplyAttachmentId.
                 * @member {string} exportedSurveyResultReplyAttachmentId
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.exportedSurveyResultReplyAttachmentId = "";

                /**
                 * ExportedSurveyResultReplyAttachment surveyResultId.
                 * @member {string} surveyResultId
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.surveyResultId = "";

                /**
                 * ExportedSurveyResultReplyAttachment surveyRequestId.
                 * @member {string} surveyRequestId
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.surveyRequestId = "";

                /**
                 * ExportedSurveyResultReplyAttachment createdBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} createdBy
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.createdBy = null;

                /**
                 * ExportedSurveyResultReplyAttachment createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ExportedSurveyResultReplyAttachment updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ExportedSurveyResultReplyAttachment deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 */
                ExportedSurveyResultReplyAttachment.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an ExportedSurveyResultReplyAttachment message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportedSurveyResultReplyAttachment.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.exportedSurveyResultReplyAttachmentId != null && message.hasOwnProperty("exportedSurveyResultReplyAttachmentId"))
                        if (!$util.isString(message.exportedSurveyResultReplyAttachmentId))
                            return "exportedSurveyResultReplyAttachmentId: string expected";
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        if (!$util.isString(message.surveyResultId))
                            return "surveyResultId: string expected";
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        if (!$util.isString(message.surveyRequestId))
                            return "surveyRequestId: string expected";
                    if (message.createdBy != null && message.hasOwnProperty("createdBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.createdBy);
                        if (error)
                            return "createdBy." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an ExportedSurveyResultReplyAttachment message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportedSurveyResultReplyAttachment} ExportedSurveyResultReplyAttachment
                 */
                ExportedSurveyResultReplyAttachment.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportedSurveyResultReplyAttachment)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportedSurveyResultReplyAttachment();
                    if (object.exportedSurveyResultReplyAttachmentId != null)
                        message.exportedSurveyResultReplyAttachmentId = String(object.exportedSurveyResultReplyAttachmentId);
                    if (object.surveyResultId != null)
                        message.surveyResultId = String(object.surveyResultId);
                    if (object.surveyRequestId != null)
                        message.surveyRequestId = String(object.surveyRequestId);
                    if (object.createdBy != null) {
                        if (typeof object.createdBy !== "object")
                            throw TypeError(".mtechnavi.api.survey.ExportedSurveyResultReplyAttachment.createdBy: object expected");
                        message.createdBy = $root.sharelib.EmbeddedUser.fromObject(object.createdBy);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an ExportedSurveyResultReplyAttachment message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @static
                 * @param {mtechnavi.api.survey.ExportedSurveyResultReplyAttachment} message ExportedSurveyResultReplyAttachment
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportedSurveyResultReplyAttachment.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.exportedSurveyResultReplyAttachmentId = "";
                        object.surveyResultId = "";
                        object.surveyRequestId = "";
                        object.createdBy = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.exportedSurveyResultReplyAttachmentId != null && message.hasOwnProperty("exportedSurveyResultReplyAttachmentId"))
                        object.exportedSurveyResultReplyAttachmentId = message.exportedSurveyResultReplyAttachmentId;
                    if (message.surveyResultId != null && message.hasOwnProperty("surveyResultId"))
                        object.surveyResultId = message.surveyResultId;
                    if (message.surveyRequestId != null && message.hasOwnProperty("surveyRequestId"))
                        object.surveyRequestId = message.surveyRequestId;
                    if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                        object.createdBy = $root.sharelib.EmbeddedUser.toObject(message.createdBy, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this ExportedSurveyResultReplyAttachment to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportedSurveyResultReplyAttachment
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportedSurveyResultReplyAttachment.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportedSurveyResultReplyAttachment;
            })();

            survey.Opened = (function() {

                /**
                 * Properties of an Opened.
                 * @memberof mtechnavi.api.survey
                 * @interface IOpened
                 * @property {string|null} [openedId] Opened openedId
                 * @property {string|null} [urn] Opened urn
                 * @property {string|null} [typeName] Opened typeName
                 * @property {string|null} [recordId] Opened recordId
                 * @property {mtechnavi.api.survey.IProcess|null} [openUserProcess] Opened openUserProcess
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Opened sharedProperties
                 * @property {Long|null} [createdAt] Opened createdAt
                 * @property {Long|null} [updatedAt] Opened updatedAt
                 * @property {Long|null} [deletedAt] Opened deletedAt
                 */

                /**
                 * Constructs a new Opened.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an Opened.
                 * @implements IOpened
                 * @constructor
                 * @param {mtechnavi.api.survey.IOpened=} [properties] Properties to set
                 */
                function Opened(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Opened openedId.
                 * @member {string} openedId
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.openedId = "";

                /**
                 * Opened urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.urn = "";

                /**
                 * Opened typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.typeName = "";

                /**
                 * Opened recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.recordId = "";

                /**
                 * Opened openUserProcess.
                 * @member {mtechnavi.api.survey.IProcess|null|undefined} openUserProcess
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.openUserProcess = null;

                /**
                 * Opened sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.sharedProperties = null;

                /**
                 * Opened createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Opened updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Opened deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 */
                Opened.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies an Opened message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.Opened
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Opened.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.openedId != null && message.hasOwnProperty("openedId"))
                        if (!$util.isString(message.openedId))
                            return "openedId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.openUserProcess != null && message.hasOwnProperty("openUserProcess")) {
                        let error = $root.mtechnavi.api.survey.Process.verify(message.openUserProcess);
                        if (error)
                            return "openUserProcess." + error;
                    }
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Opened message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.Opened
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.Opened} Opened
                 */
                Opened.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.Opened)
                        return object;
                    let message = new $root.mtechnavi.api.survey.Opened();
                    if (object.openedId != null)
                        message.openedId = String(object.openedId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.openUserProcess != null) {
                        if (typeof object.openUserProcess !== "object")
                            throw TypeError(".mtechnavi.api.survey.Opened.openUserProcess: object expected");
                        message.openUserProcess = $root.mtechnavi.api.survey.Process.fromObject(object.openUserProcess);
                    }
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.Opened.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Opened message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.Opened
                 * @static
                 * @param {mtechnavi.api.survey.Opened} message Opened
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Opened.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.openedId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.openUserProcess = null;
                        object.sharedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.openedId != null && message.hasOwnProperty("openedId"))
                        object.openedId = message.openedId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.openUserProcess != null && message.hasOwnProperty("openUserProcess"))
                        object.openUserProcess = $root.mtechnavi.api.survey.Process.toObject(message.openUserProcess, options);
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this Opened to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.Opened
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Opened.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Opened;
            })();

            survey.SurveyRequestUnitAttachment = (function() {

                /**
                 * Properties of a SurveyRequestUnitAttachment.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequestUnitAttachment
                 * @property {string|null} [surveyRequestUnitAttachmentId] SurveyRequestUnitAttachment surveyRequestUnitAttachmentId
                 * @property {string|null} [surveyBaseRequestId] SurveyRequestUnitAttachment surveyBaseRequestId
                 * @property {string|null} [businessUnitManagementId] SurveyRequestUnitAttachment businessUnitManagementId
                 * @property {string|null} [displayName] SurveyRequestUnitAttachment displayName
                 * @property {Array.<sharelib.IAttachment>|null} [attachments] SurveyRequestUnitAttachment attachments
                 * @property {Long|null} [createdAt] SurveyRequestUnitAttachment createdAt
                 * @property {Long|null} [updatedAt] SurveyRequestUnitAttachment updatedAt
                 * @property {Long|null} [deletedAt] SurveyRequestUnitAttachment deletedAt
                 */

                /**
                 * Constructs a new SurveyRequestUnitAttachment.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequestUnitAttachment.
                 * @implements ISurveyRequestUnitAttachment
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequestUnitAttachment=} [properties] Properties to set
                 */
                function SurveyRequestUnitAttachment(properties) {
                    this.attachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestUnitAttachment surveyRequestUnitAttachmentId.
                 * @member {string} surveyRequestUnitAttachmentId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.surveyRequestUnitAttachmentId = "";

                /**
                 * SurveyRequestUnitAttachment surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.surveyBaseRequestId = "";

                /**
                 * SurveyRequestUnitAttachment businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.businessUnitManagementId = "";

                /**
                 * SurveyRequestUnitAttachment displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.displayName = "";

                /**
                 * SurveyRequestUnitAttachment attachments.
                 * @member {Array.<sharelib.IAttachment>} attachments
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.attachments = $util.emptyArray;

                /**
                 * SurveyRequestUnitAttachment createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestUnitAttachment updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestUnitAttachment deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 */
                SurveyRequestUnitAttachment.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequestUnitAttachment message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestUnitAttachment.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestUnitAttachmentId != null && message.hasOwnProperty("surveyRequestUnitAttachmentId"))
                        if (!$util.isString(message.surveyRequestUnitAttachmentId))
                            return "surveyRequestUnitAttachmentId: string expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.attachments != null && message.hasOwnProperty("attachments")) {
                        if (!Array.isArray(message.attachments))
                            return "attachments: array expected";
                        for (let i = 0; i < message.attachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.attachments[i]);
                            if (error)
                                return "attachments." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestUnitAttachment message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequestUnitAttachment} SurveyRequestUnitAttachment
                 */
                SurveyRequestUnitAttachment.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequestUnitAttachment)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequestUnitAttachment();
                    if (object.surveyRequestUnitAttachmentId != null)
                        message.surveyRequestUnitAttachmentId = String(object.surveyRequestUnitAttachmentId);
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.attachments) {
                        if (!Array.isArray(object.attachments))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnitAttachment.attachments: array expected");
                        message.attachments = [];
                        for (let i = 0; i < object.attachments.length; ++i) {
                            if (typeof object.attachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestUnitAttachment.attachments: object expected");
                            message.attachments[i] = $root.sharelib.Attachment.fromObject(object.attachments[i]);
                        }
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestUnitAttachment message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequestUnitAttachment} message SurveyRequestUnitAttachment
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestUnitAttachment.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.attachments = [];
                    if (options.defaults) {
                        object.surveyRequestUnitAttachmentId = "";
                        object.surveyBaseRequestId = "";
                        object.businessUnitManagementId = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequestUnitAttachmentId != null && message.hasOwnProperty("surveyRequestUnitAttachmentId"))
                        object.surveyRequestUnitAttachmentId = message.surveyRequestUnitAttachmentId;
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.attachments && message.attachments.length) {
                        object.attachments = [];
                        for (let j = 0; j < message.attachments.length; ++j)
                            object.attachments[j] = $root.sharelib.Attachment.toObject(message.attachments[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this SurveyRequestUnitAttachment to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequestUnitAttachment
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestUnitAttachment.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestUnitAttachment;
            })();

            survey.SurveyRequestUnit = (function() {

                /**
                 * Properties of a SurveyRequestUnit.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequestUnit
                 * @property {string|null} [surveyRequestUnitId] SurveyRequestUnit surveyRequestUnitId
                 * @property {string|null} [surveyBaseRequestId] SurveyRequestUnit surveyBaseRequestId
                 * @property {string|null} [businessUnitManagementId] SurveyRequestUnit businessUnitManagementId
                 * @property {Array.<string>|null} [businessUnitContactIds] SurveyRequestUnit businessUnitContactIds
                 * @property {string|null} [companyId] SurveyRequestUnit companyId
                 * @property {string|null} [code] SurveyRequestUnit code
                 * @property {string|null} [displayName] SurveyRequestUnit displayName
                 * @property {Array.<sharelib.IUserReference>|null} [mainContact] SurveyRequestUnit mainContact
                 * @property {Array.<sharelib.IUserReference>|null} [mainContactAssistant] SurveyRequestUnit mainContactAssistant
                 * @property {Array.<sharelib.IUserReference>|null} [staff] SurveyRequestUnit staff
                 * @property {Array.<sharelib.IUserReference>|null} [staffAssistant] SurveyRequestUnit staffAssistant
                 * @property {Long|null} [createdAt] SurveyRequestUnit createdAt
                 * @property {Long|null} [updatedAt] SurveyRequestUnit updatedAt
                 * @property {Long|null} [deletedAt] SurveyRequestUnit deletedAt
                 */

                /**
                 * Constructs a new SurveyRequestUnit.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequestUnit.
                 * @implements ISurveyRequestUnit
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequestUnit=} [properties] Properties to set
                 */
                function SurveyRequestUnit(properties) {
                    this.businessUnitContactIds = [];
                    this.mainContact = [];
                    this.mainContactAssistant = [];
                    this.staff = [];
                    this.staffAssistant = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestUnit surveyRequestUnitId.
                 * @member {string} surveyRequestUnitId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.surveyRequestUnitId = "";

                /**
                 * SurveyRequestUnit surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.surveyBaseRequestId = "";

                /**
                 * SurveyRequestUnit businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.businessUnitManagementId = "";

                /**
                 * SurveyRequestUnit businessUnitContactIds.
                 * @member {Array.<string>} businessUnitContactIds
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.businessUnitContactIds = $util.emptyArray;

                /**
                 * SurveyRequestUnit companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.companyId = "";

                /**
                 * SurveyRequestUnit code.
                 * @member {string} code
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.code = "";

                /**
                 * SurveyRequestUnit displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.displayName = "";

                /**
                 * SurveyRequestUnit mainContact.
                 * @member {Array.<sharelib.IUserReference>} mainContact
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.mainContact = $util.emptyArray;

                /**
                 * SurveyRequestUnit mainContactAssistant.
                 * @member {Array.<sharelib.IUserReference>} mainContactAssistant
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.mainContactAssistant = $util.emptyArray;

                /**
                 * SurveyRequestUnit staff.
                 * @member {Array.<sharelib.IUserReference>} staff
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.staff = $util.emptyArray;

                /**
                 * SurveyRequestUnit staffAssistant.
                 * @member {Array.<sharelib.IUserReference>} staffAssistant
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.staffAssistant = $util.emptyArray;

                /**
                 * SurveyRequestUnit createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestUnit updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestUnit deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 */
                SurveyRequestUnit.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequestUnit message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestUnit.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestUnitId != null && message.hasOwnProperty("surveyRequestUnitId"))
                        if (!$util.isString(message.surveyRequestUnitId))
                            return "surveyRequestUnitId: string expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.businessUnitContactIds != null && message.hasOwnProperty("businessUnitContactIds")) {
                        if (!Array.isArray(message.businessUnitContactIds))
                            return "businessUnitContactIds: array expected";
                        for (let i = 0; i < message.businessUnitContactIds.length; ++i)
                            if (!$util.isString(message.businessUnitContactIds[i]))
                                return "businessUnitContactIds: string[] expected";
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.mainContact != null && message.hasOwnProperty("mainContact")) {
                        if (!Array.isArray(message.mainContact))
                            return "mainContact: array expected";
                        for (let i = 0; i < message.mainContact.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContact[i]);
                            if (error)
                                return "mainContact." + error;
                        }
                    }
                    if (message.mainContactAssistant != null && message.hasOwnProperty("mainContactAssistant")) {
                        if (!Array.isArray(message.mainContactAssistant))
                            return "mainContactAssistant: array expected";
                        for (let i = 0; i < message.mainContactAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContactAssistant[i]);
                            if (error)
                                return "mainContactAssistant." + error;
                        }
                    }
                    if (message.staff != null && message.hasOwnProperty("staff")) {
                        if (!Array.isArray(message.staff))
                            return "staff: array expected";
                        for (let i = 0; i < message.staff.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staff[i]);
                            if (error)
                                return "staff." + error;
                        }
                    }
                    if (message.staffAssistant != null && message.hasOwnProperty("staffAssistant")) {
                        if (!Array.isArray(message.staffAssistant))
                            return "staffAssistant: array expected";
                        for (let i = 0; i < message.staffAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staffAssistant[i]);
                            if (error)
                                return "staffAssistant." + error;
                        }
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestUnit message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequestUnit} SurveyRequestUnit
                 */
                SurveyRequestUnit.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequestUnit)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequestUnit();
                    if (object.surveyRequestUnitId != null)
                        message.surveyRequestUnitId = String(object.surveyRequestUnitId);
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.businessUnitContactIds) {
                        if (!Array.isArray(object.businessUnitContactIds))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.businessUnitContactIds: array expected");
                        message.businessUnitContactIds = [];
                        for (let i = 0; i < object.businessUnitContactIds.length; ++i)
                            message.businessUnitContactIds[i] = String(object.businessUnitContactIds[i]);
                    }
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.mainContact) {
                        if (!Array.isArray(object.mainContact))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.mainContact: array expected");
                        message.mainContact = [];
                        for (let i = 0; i < object.mainContact.length; ++i) {
                            if (typeof object.mainContact[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.mainContact: object expected");
                            message.mainContact[i] = $root.sharelib.UserReference.fromObject(object.mainContact[i]);
                        }
                    }
                    if (object.mainContactAssistant) {
                        if (!Array.isArray(object.mainContactAssistant))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.mainContactAssistant: array expected");
                        message.mainContactAssistant = [];
                        for (let i = 0; i < object.mainContactAssistant.length; ++i) {
                            if (typeof object.mainContactAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.mainContactAssistant: object expected");
                            message.mainContactAssistant[i] = $root.sharelib.UserReference.fromObject(object.mainContactAssistant[i]);
                        }
                    }
                    if (object.staff) {
                        if (!Array.isArray(object.staff))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.staff: array expected");
                        message.staff = [];
                        for (let i = 0; i < object.staff.length; ++i) {
                            if (typeof object.staff[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.staff: object expected");
                            message.staff[i] = $root.sharelib.UserReference.fromObject(object.staff[i]);
                        }
                    }
                    if (object.staffAssistant) {
                        if (!Array.isArray(object.staffAssistant))
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.staffAssistant: array expected");
                        message.staffAssistant = [];
                        for (let i = 0; i < object.staffAssistant.length; ++i) {
                            if (typeof object.staffAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.SurveyRequestUnit.staffAssistant: object expected");
                            message.staffAssistant[i] = $root.sharelib.UserReference.fromObject(object.staffAssistant[i]);
                        }
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestUnit message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequestUnit} message SurveyRequestUnit
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestUnit.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.mainContact = [];
                        object.mainContactAssistant = [];
                        object.staff = [];
                        object.staffAssistant = [];
                        object.businessUnitContactIds = [];
                    }
                    if (options.defaults) {
                        object.surveyRequestUnitId = "";
                        object.surveyBaseRequestId = "";
                        object.businessUnitManagementId = "";
                        object.companyId = "";
                        object.code = "";
                        object.displayName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequestUnitId != null && message.hasOwnProperty("surveyRequestUnitId"))
                        object.surveyRequestUnitId = message.surveyRequestUnitId;
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.mainContact && message.mainContact.length) {
                        object.mainContact = [];
                        for (let j = 0; j < message.mainContact.length; ++j)
                            object.mainContact[j] = $root.sharelib.UserReference.toObject(message.mainContact[j], options);
                    }
                    if (message.mainContactAssistant && message.mainContactAssistant.length) {
                        object.mainContactAssistant = [];
                        for (let j = 0; j < message.mainContactAssistant.length; ++j)
                            object.mainContactAssistant[j] = $root.sharelib.UserReference.toObject(message.mainContactAssistant[j], options);
                    }
                    if (message.staff && message.staff.length) {
                        object.staff = [];
                        for (let j = 0; j < message.staff.length; ++j)
                            object.staff[j] = $root.sharelib.UserReference.toObject(message.staff[j], options);
                    }
                    if (message.staffAssistant && message.staffAssistant.length) {
                        object.staffAssistant = [];
                        for (let j = 0; j < message.staffAssistant.length; ++j)
                            object.staffAssistant[j] = $root.sharelib.UserReference.toObject(message.staffAssistant[j], options);
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.businessUnitContactIds && message.businessUnitContactIds.length) {
                        object.businessUnitContactIds = [];
                        for (let j = 0; j < message.businessUnitContactIds.length; ++j)
                            object.businessUnitContactIds[j] = message.businessUnitContactIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this SurveyRequestUnit to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequestUnit
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestUnit.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestUnit;
            })();

            survey.SurveyRequestWorkForm = (function() {

                /**
                 * Properties of a SurveyRequestWorkForm.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequestWorkForm
                 * @property {string|null} [surveyRequestWorkFormId] SurveyRequestWorkForm surveyRequestWorkFormId
                 * @property {string|null} [formId] SurveyRequestWorkForm formId
                 * @property {string|null} [displayName] SurveyRequestWorkForm displayName
                 * @property {string|null} [attribute1] SurveyRequestWorkForm attribute1
                 * @property {string|null} [attribute2] SurveyRequestWorkForm attribute2
                 * @property {string|null} [attribute3] SurveyRequestWorkForm attribute3
                 * @property {string|null} [notice] SurveyRequestWorkForm notice
                 * @property {boolean|null} [required] SurveyRequestWorkForm required
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyRequestWorkForm sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyRequestWorkForm createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyRequestWorkForm updatedProperties
                 * @property {Long|null} [createdAt] SurveyRequestWorkForm createdAt
                 * @property {Long|null} [updatedAt] SurveyRequestWorkForm updatedAt
                 * @property {Long|null} [deletedAt] SurveyRequestWorkForm deletedAt
                 * @property {Long|null} [order] SurveyRequestWorkForm order
                 */

                /**
                 * Constructs a new SurveyRequestWorkForm.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequestWorkForm.
                 * @implements ISurveyRequestWorkForm
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequestWorkForm=} [properties] Properties to set
                 */
                function SurveyRequestWorkForm(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestWorkForm surveyRequestWorkFormId.
                 * @member {string} surveyRequestWorkFormId
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.surveyRequestWorkFormId = "";

                /**
                 * SurveyRequestWorkForm formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.formId = "";

                /**
                 * SurveyRequestWorkForm displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.displayName = "";

                /**
                 * SurveyRequestWorkForm attribute1.
                 * @member {string} attribute1
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.attribute1 = "";

                /**
                 * SurveyRequestWorkForm attribute2.
                 * @member {string} attribute2
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.attribute2 = "";

                /**
                 * SurveyRequestWorkForm attribute3.
                 * @member {string} attribute3
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.attribute3 = "";

                /**
                 * SurveyRequestWorkForm notice.
                 * @member {string} notice
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.notice = "";

                /**
                 * SurveyRequestWorkForm required.
                 * @member {boolean} required
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.required = false;

                /**
                 * SurveyRequestWorkForm sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.sharedProperties = null;

                /**
                 * SurveyRequestWorkForm createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.createdProperties = null;

                /**
                 * SurveyRequestWorkForm updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.updatedProperties = null;

                /**
                 * SurveyRequestWorkForm createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestWorkForm updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestWorkForm deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestWorkForm order.
                 * @member {Long} order
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 */
                SurveyRequestWorkForm.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequestWorkForm message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestWorkForm.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequestWorkFormId != null && message.hasOwnProperty("surveyRequestWorkFormId"))
                        if (!$util.isString(message.surveyRequestWorkFormId))
                            return "surveyRequestWorkFormId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        if (!$util.isString(message.attribute1))
                            return "attribute1: string expected";
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        if (!$util.isString(message.attribute2))
                            return "attribute2: string expected";
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        if (!$util.isString(message.attribute3))
                            return "attribute3: string expected";
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        if (!$util.isString(message.notice))
                            return "notice: string expected";
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                            return "order: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestWorkForm message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequestWorkForm} SurveyRequestWorkForm
                 */
                SurveyRequestWorkForm.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequestWorkForm)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequestWorkForm();
                    if (object.surveyRequestWorkFormId != null)
                        message.surveyRequestWorkFormId = String(object.surveyRequestWorkFormId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.attribute1 != null)
                        message.attribute1 = String(object.attribute1);
                    if (object.attribute2 != null)
                        message.attribute2 = String(object.attribute2);
                    if (object.attribute3 != null)
                        message.attribute3 = String(object.attribute3);
                    if (object.notice != null)
                        message.notice = String(object.notice);
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestWorkForm.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestWorkForm.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestWorkForm.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.order != null)
                        if ($util.Long)
                            (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                        else if (typeof object.order === "string")
                            message.order = parseInt(object.order, 10);
                        else if (typeof object.order === "number")
                            message.order = object.order;
                        else if (typeof object.order === "object")
                            message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestWorkForm message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequestWorkForm} message SurveyRequestWorkForm
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestWorkForm.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyRequestWorkFormId = "";
                        object.displayName = "";
                        object.attribute1 = "";
                        object.attribute2 = "";
                        object.attribute3 = "";
                        object.notice = "";
                        object.required = false;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.formId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.order = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequestWorkFormId != null && message.hasOwnProperty("surveyRequestWorkFormId"))
                        object.surveyRequestWorkFormId = message.surveyRequestWorkFormId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        object.attribute1 = message.attribute1;
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        object.attribute2 = message.attribute2;
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        object.attribute3 = message.attribute3;
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        object.notice = message.notice;
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (typeof message.order === "number")
                            object.order = options.longs === String ? String(message.order) : message.order;
                        else
                            object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
                    return object;
                };

                /**
                 * Converts this SurveyRequestWorkForm to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequestWorkForm
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestWorkForm.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestWorkForm;
            })();

            survey.SurveyResultWorkForm = (function() {

                /**
                 * Properties of a SurveyResultWorkForm.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyResultWorkForm
                 * @property {string|null} [surveyResultWorkFormId] SurveyResultWorkForm surveyResultWorkFormId
                 * @property {string|null} [baseFormId] SurveyResultWorkForm baseFormId
                 * @property {string|null} [formId] SurveyResultWorkForm formId
                 * @property {string|null} [displayName] SurveyResultWorkForm displayName
                 * @property {string|null} [attribute1] SurveyResultWorkForm attribute1
                 * @property {string|null} [attribute2] SurveyResultWorkForm attribute2
                 * @property {string|null} [attribute3] SurveyResultWorkForm attribute3
                 * @property {string|null} [notice] SurveyResultWorkForm notice
                 * @property {boolean|null} [required] SurveyResultWorkForm required
                 * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] SurveyResultWorkForm sharedProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyResultWorkForm createdProperties
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] SurveyResultWorkForm updatedProperties
                 * @property {Long|null} [createdAt] SurveyResultWorkForm createdAt
                 * @property {Long|null} [updatedAt] SurveyResultWorkForm updatedAt
                 * @property {Long|null} [deletedAt] SurveyResultWorkForm deletedAt
                 * @property {Long|null} [order] SurveyResultWorkForm order
                 */

                /**
                 * Constructs a new SurveyResultWorkForm.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyResultWorkForm.
                 * @implements ISurveyResultWorkForm
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyResultWorkForm=} [properties] Properties to set
                 */
                function SurveyResultWorkForm(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyResultWorkForm surveyResultWorkFormId.
                 * @member {string} surveyResultWorkFormId
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.surveyResultWorkFormId = "";

                /**
                 * SurveyResultWorkForm baseFormId.
                 * @member {string} baseFormId
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.baseFormId = "";

                /**
                 * SurveyResultWorkForm formId.
                 * @member {string} formId
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.formId = "";

                /**
                 * SurveyResultWorkForm displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.displayName = "";

                /**
                 * SurveyResultWorkForm attribute1.
                 * @member {string} attribute1
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.attribute1 = "";

                /**
                 * SurveyResultWorkForm attribute2.
                 * @member {string} attribute2
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.attribute2 = "";

                /**
                 * SurveyResultWorkForm attribute3.
                 * @member {string} attribute3
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.attribute3 = "";

                /**
                 * SurveyResultWorkForm notice.
                 * @member {string} notice
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.notice = "";

                /**
                 * SurveyResultWorkForm required.
                 * @member {boolean} required
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.required = false;

                /**
                 * SurveyResultWorkForm sharedProperties.
                 * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.sharedProperties = null;

                /**
                 * SurveyResultWorkForm createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.createdProperties = null;

                /**
                 * SurveyResultWorkForm updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.updatedProperties = null;

                /**
                 * SurveyResultWorkForm createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultWorkForm updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultWorkForm deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyResultWorkForm order.
                 * @member {Long} order
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 */
                SurveyResultWorkForm.prototype.order = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyResultWorkForm message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyResultWorkForm.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyResultWorkFormId != null && message.hasOwnProperty("surveyResultWorkFormId"))
                        if (!$util.isString(message.surveyResultWorkFormId))
                            return "surveyResultWorkFormId: string expected";
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        if (!$util.isString(message.baseFormId))
                            return "baseFormId: string expected";
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        if (!$util.isString(message.formId))
                            return "formId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        if (!$util.isString(message.attribute1))
                            return "attribute1: string expected";
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        if (!$util.isString(message.attribute2))
                            return "attribute2: string expected";
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        if (!$util.isString(message.attribute3))
                            return "attribute3: string expected";
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        if (!$util.isString(message.notice))
                            return "notice: string expected";
                    if (message.required != null && message.hasOwnProperty("required"))
                        if (typeof message.required !== "boolean")
                            return "required: boolean expected";
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                        let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                        if (error)
                            return "sharedProperties." + error;
                    }
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (!$util.isInteger(message.order) && !(message.order && $util.isInteger(message.order.low) && $util.isInteger(message.order.high)))
                            return "order: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyResultWorkForm message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyResultWorkForm} SurveyResultWorkForm
                 */
                SurveyResultWorkForm.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyResultWorkForm)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyResultWorkForm();
                    if (object.surveyResultWorkFormId != null)
                        message.surveyResultWorkFormId = String(object.surveyResultWorkFormId);
                    if (object.baseFormId != null)
                        message.baseFormId = String(object.baseFormId);
                    if (object.formId != null)
                        message.formId = String(object.formId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.attribute1 != null)
                        message.attribute1 = String(object.attribute1);
                    if (object.attribute2 != null)
                        message.attribute2 = String(object.attribute2);
                    if (object.attribute3 != null)
                        message.attribute3 = String(object.attribute3);
                    if (object.notice != null)
                        message.notice = String(object.notice);
                    if (object.required != null)
                        message.required = Boolean(object.required);
                    if (object.sharedProperties != null) {
                        if (typeof object.sharedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResultWorkForm.sharedProperties: object expected");
                        message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
                    }
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResultWorkForm.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyResultWorkForm.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.order != null)
                        if ($util.Long)
                            (message.order = $util.Long.fromValue(object.order)).unsigned = false;
                        else if (typeof object.order === "string")
                            message.order = parseInt(object.order, 10);
                        else if (typeof object.order === "number")
                            message.order = object.order;
                        else if (typeof object.order === "object")
                            message.order = new $util.LongBits(object.order.low >>> 0, object.order.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyResultWorkForm message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @static
                 * @param {mtechnavi.api.survey.SurveyResultWorkForm} message SurveyResultWorkForm
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyResultWorkForm.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyResultWorkFormId = "";
                        object.displayName = "";
                        object.attribute1 = "";
                        object.attribute2 = "";
                        object.attribute3 = "";
                        object.notice = "";
                        object.required = false;
                        object.sharedProperties = null;
                        object.createdProperties = null;
                        object.updatedProperties = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.baseFormId = "";
                        object.formId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.order = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.order = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyResultWorkFormId != null && message.hasOwnProperty("surveyResultWorkFormId"))
                        object.surveyResultWorkFormId = message.surveyResultWorkFormId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.attribute1 != null && message.hasOwnProperty("attribute1"))
                        object.attribute1 = message.attribute1;
                    if (message.attribute2 != null && message.hasOwnProperty("attribute2"))
                        object.attribute2 = message.attribute2;
                    if (message.attribute3 != null && message.hasOwnProperty("attribute3"))
                        object.attribute3 = message.attribute3;
                    if (message.notice != null && message.hasOwnProperty("notice"))
                        object.notice = message.notice;
                    if (message.required != null && message.hasOwnProperty("required"))
                        object.required = message.required;
                    if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                        object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.baseFormId != null && message.hasOwnProperty("baseFormId"))
                        object.baseFormId = message.baseFormId;
                    if (message.formId != null && message.hasOwnProperty("formId"))
                        object.formId = message.formId;
                    if (message.order != null && message.hasOwnProperty("order"))
                        if (typeof message.order === "number")
                            object.order = options.longs === String ? String(message.order) : message.order;
                        else
                            object.order = options.longs === String ? $util.Long.prototype.toString.call(message.order) : options.longs === Number ? new $util.LongBits(message.order.low >>> 0, message.order.high >>> 0).toNumber() : message.order;
                    return object;
                };

                /**
                 * Converts this SurveyResultWorkForm to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyResultWorkForm
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyResultWorkForm.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyResultWorkForm;
            })();

            survey.ResultFormShareManagement = (function() {

                /**
                 * Properties of a ResultFormShareManagement.
                 * @memberof mtechnavi.api.survey
                 * @interface IResultFormShareManagement
                 * @property {string|null} [resultFormShareManagementId] ResultFormShareManagement resultFormShareManagementId
                 * @property {Array.<string>|null} [formIds] ResultFormShareManagement formIds
                 * @property {Long|null} [createdAt] ResultFormShareManagement createdAt
                 * @property {Long|null} [updatedAt] ResultFormShareManagement updatedAt
                 * @property {Long|null} [deletedAt] ResultFormShareManagement deletedAt
                 */

                /**
                 * Constructs a new ResultFormShareManagement.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a ResultFormShareManagement.
                 * @implements IResultFormShareManagement
                 * @constructor
                 * @param {mtechnavi.api.survey.IResultFormShareManagement=} [properties] Properties to set
                 */
                function ResultFormShareManagement(properties) {
                    this.formIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResultFormShareManagement resultFormShareManagementId.
                 * @member {string} resultFormShareManagementId
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 */
                ResultFormShareManagement.prototype.resultFormShareManagementId = "";

                /**
                 * ResultFormShareManagement formIds.
                 * @member {Array.<string>} formIds
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 */
                ResultFormShareManagement.prototype.formIds = $util.emptyArray;

                /**
                 * ResultFormShareManagement createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 */
                ResultFormShareManagement.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ResultFormShareManagement updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 */
                ResultFormShareManagement.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ResultFormShareManagement deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 */
                ResultFormShareManagement.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a ResultFormShareManagement message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResultFormShareManagement.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.resultFormShareManagementId != null && message.hasOwnProperty("resultFormShareManagementId"))
                        if (!$util.isString(message.resultFormShareManagementId))
                            return "resultFormShareManagementId: string expected";
                    if (message.formIds != null && message.hasOwnProperty("formIds")) {
                        if (!Array.isArray(message.formIds))
                            return "formIds: array expected";
                        for (let i = 0; i < message.formIds.length; ++i)
                            if (!$util.isString(message.formIds[i]))
                                return "formIds: string[] expected";
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ResultFormShareManagement message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ResultFormShareManagement} ResultFormShareManagement
                 */
                ResultFormShareManagement.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ResultFormShareManagement)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ResultFormShareManagement();
                    if (object.resultFormShareManagementId != null)
                        message.resultFormShareManagementId = String(object.resultFormShareManagementId);
                    if (object.formIds) {
                        if (!Array.isArray(object.formIds))
                            throw TypeError(".mtechnavi.api.survey.ResultFormShareManagement.formIds: array expected");
                        message.formIds = [];
                        for (let i = 0; i < object.formIds.length; ++i)
                            message.formIds[i] = String(object.formIds[i]);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ResultFormShareManagement message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @static
                 * @param {mtechnavi.api.survey.ResultFormShareManagement} message ResultFormShareManagement
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResultFormShareManagement.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.formIds = [];
                    if (options.defaults) {
                        object.resultFormShareManagementId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.resultFormShareManagementId != null && message.hasOwnProperty("resultFormShareManagementId"))
                        object.resultFormShareManagementId = message.resultFormShareManagementId;
                    if (message.formIds && message.formIds.length) {
                        object.formIds = [];
                        for (let j = 0; j < message.formIds.length; ++j)
                            object.formIds[j] = message.formIds[j];
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this ResultFormShareManagement to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ResultFormShareManagement
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResultFormShareManagement.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResultFormShareManagement;
            })();

            survey.AutoReminderSetting = (function() {

                /**
                 * Properties of an AutoReminderSetting.
                 * @memberof mtechnavi.api.survey
                 * @interface IAutoReminderSetting
                 * @property {boolean|null} [autoReminder] AutoReminderSetting autoReminder
                 * @property {Long|null} [offset] AutoReminderSetting offset
                 * @property {sharelib.INameOption|null} [offsetType] AutoReminderSetting offsetType
                 * @property {boolean|null} [repeat] AutoReminderSetting repeat
                 * @property {Long|null} [repeatInterval] AutoReminderSetting repeatInterval
                 * @property {sharelib.INameOption|null} [contentType] AutoReminderSetting contentType
                 * @property {string|null} [content] AutoReminderSetting content
                 * @property {mtechnavi.api.survey.ISendTargetSetting|null} [sendTarget] AutoReminderSetting sendTarget
                 */

                /**
                 * Constructs a new AutoReminderSetting.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an AutoReminderSetting.
                 * @implements IAutoReminderSetting
                 * @constructor
                 * @param {mtechnavi.api.survey.IAutoReminderSetting=} [properties] Properties to set
                 */
                function AutoReminderSetting(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AutoReminderSetting autoReminder.
                 * @member {boolean} autoReminder
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.autoReminder = false;

                /**
                 * AutoReminderSetting offset.
                 * @member {Long} offset
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * AutoReminderSetting offsetType.
                 * @member {sharelib.INameOption|null|undefined} offsetType
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.offsetType = null;

                /**
                 * AutoReminderSetting repeat.
                 * @member {boolean} repeat
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.repeat = false;

                /**
                 * AutoReminderSetting repeatInterval.
                 * @member {Long} repeatInterval
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.repeatInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * AutoReminderSetting contentType.
                 * @member {sharelib.INameOption|null|undefined} contentType
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.contentType = null;

                /**
                 * AutoReminderSetting content.
                 * @member {string} content
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.content = "";

                /**
                 * AutoReminderSetting sendTarget.
                 * @member {mtechnavi.api.survey.ISendTargetSetting|null|undefined} sendTarget
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 */
                AutoReminderSetting.prototype.sendTarget = null;

                /**
                 * Verifies an AutoReminderSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AutoReminderSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.autoReminder != null && message.hasOwnProperty("autoReminder"))
                        if (typeof message.autoReminder !== "boolean")
                            return "autoReminder: boolean expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                            return "offset: integer|Long expected";
                    if (message.offsetType != null && message.hasOwnProperty("offsetType")) {
                        let error = $root.sharelib.NameOption.verify(message.offsetType);
                        if (error)
                            return "offsetType." + error;
                    }
                    if (message.repeat != null && message.hasOwnProperty("repeat"))
                        if (typeof message.repeat !== "boolean")
                            return "repeat: boolean expected";
                    if (message.repeatInterval != null && message.hasOwnProperty("repeatInterval"))
                        if (!$util.isInteger(message.repeatInterval) && !(message.repeatInterval && $util.isInteger(message.repeatInterval.low) && $util.isInteger(message.repeatInterval.high)))
                            return "repeatInterval: integer|Long expected";
                    if (message.contentType != null && message.hasOwnProperty("contentType")) {
                        let error = $root.sharelib.NameOption.verify(message.contentType);
                        if (error)
                            return "contentType." + error;
                    }
                    if (message.content != null && message.hasOwnProperty("content"))
                        if (!$util.isString(message.content))
                            return "content: string expected";
                    if (message.sendTarget != null && message.hasOwnProperty("sendTarget")) {
                        let error = $root.mtechnavi.api.survey.SendTargetSetting.verify(message.sendTarget);
                        if (error)
                            return "sendTarget." + error;
                    }
                    return null;
                };

                /**
                 * Creates an AutoReminderSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.AutoReminderSetting} AutoReminderSetting
                 */
                AutoReminderSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.AutoReminderSetting)
                        return object;
                    let message = new $root.mtechnavi.api.survey.AutoReminderSetting();
                    if (object.autoReminder != null)
                        message.autoReminder = Boolean(object.autoReminder);
                    if (object.offset != null)
                        if ($util.Long)
                            (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                        else if (typeof object.offset === "string")
                            message.offset = parseInt(object.offset, 10);
                        else if (typeof object.offset === "number")
                            message.offset = object.offset;
                        else if (typeof object.offset === "object")
                            message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
                    if (object.offsetType != null) {
                        if (typeof object.offsetType !== "object")
                            throw TypeError(".mtechnavi.api.survey.AutoReminderSetting.offsetType: object expected");
                        message.offsetType = $root.sharelib.NameOption.fromObject(object.offsetType);
                    }
                    if (object.repeat != null)
                        message.repeat = Boolean(object.repeat);
                    if (object.repeatInterval != null)
                        if ($util.Long)
                            (message.repeatInterval = $util.Long.fromValue(object.repeatInterval)).unsigned = false;
                        else if (typeof object.repeatInterval === "string")
                            message.repeatInterval = parseInt(object.repeatInterval, 10);
                        else if (typeof object.repeatInterval === "number")
                            message.repeatInterval = object.repeatInterval;
                        else if (typeof object.repeatInterval === "object")
                            message.repeatInterval = new $util.LongBits(object.repeatInterval.low >>> 0, object.repeatInterval.high >>> 0).toNumber();
                    if (object.contentType != null) {
                        if (typeof object.contentType !== "object")
                            throw TypeError(".mtechnavi.api.survey.AutoReminderSetting.contentType: object expected");
                        message.contentType = $root.sharelib.NameOption.fromObject(object.contentType);
                    }
                    if (object.content != null)
                        message.content = String(object.content);
                    if (object.sendTarget != null) {
                        if (typeof object.sendTarget !== "object")
                            throw TypeError(".mtechnavi.api.survey.AutoReminderSetting.sendTarget: object expected");
                        message.sendTarget = $root.mtechnavi.api.survey.SendTargetSetting.fromObject(object.sendTarget);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AutoReminderSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @static
                 * @param {mtechnavi.api.survey.AutoReminderSetting} message AutoReminderSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AutoReminderSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.autoReminder = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.offset = options.longs === String ? "0" : 0;
                        object.offsetType = null;
                        object.repeat = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.repeatInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.repeatInterval = options.longs === String ? "0" : 0;
                        object.contentType = null;
                        object.content = "";
                        object.sendTarget = null;
                    }
                    if (message.autoReminder != null && message.hasOwnProperty("autoReminder"))
                        object.autoReminder = message.autoReminder;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (typeof message.offset === "number")
                            object.offset = options.longs === String ? String(message.offset) : message.offset;
                        else
                            object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
                    if (message.offsetType != null && message.hasOwnProperty("offsetType"))
                        object.offsetType = $root.sharelib.NameOption.toObject(message.offsetType, options);
                    if (message.repeat != null && message.hasOwnProperty("repeat"))
                        object.repeat = message.repeat;
                    if (message.repeatInterval != null && message.hasOwnProperty("repeatInterval"))
                        if (typeof message.repeatInterval === "number")
                            object.repeatInterval = options.longs === String ? String(message.repeatInterval) : message.repeatInterval;
                        else
                            object.repeatInterval = options.longs === String ? $util.Long.prototype.toString.call(message.repeatInterval) : options.longs === Number ? new $util.LongBits(message.repeatInterval.low >>> 0, message.repeatInterval.high >>> 0).toNumber() : message.repeatInterval;
                    if (message.contentType != null && message.hasOwnProperty("contentType"))
                        object.contentType = $root.sharelib.NameOption.toObject(message.contentType, options);
                    if (message.content != null && message.hasOwnProperty("content"))
                        object.content = message.content;
                    if (message.sendTarget != null && message.hasOwnProperty("sendTarget"))
                        object.sendTarget = $root.mtechnavi.api.survey.SendTargetSetting.toObject(message.sendTarget, options);
                    return object;
                };

                /**
                 * Converts this AutoReminderSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.AutoReminderSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AutoReminderSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AutoReminderSetting;
            })();

            survey.BusinessUnitProperties = (function() {

                /**
                 * Properties of a BusinessUnitProperties.
                 * @memberof mtechnavi.api.survey
                 * @interface IBusinessUnitProperties
                 * @property {string|null} [businessUnitManagementId] BusinessUnitProperties businessUnitManagementId
                 * @property {Array.<string>|null} [businessUnitContactIds] BusinessUnitProperties businessUnitContactIds
                 * @property {string|null} [code] BusinessUnitProperties code
                 * @property {string|null} [displayName] BusinessUnitProperties displayName
                 * @property {Array.<sharelib.IUserReference>|null} [mainContact] BusinessUnitProperties mainContact
                 * @property {Array.<sharelib.IUserReference>|null} [mainContactAssistant] BusinessUnitProperties mainContactAssistant
                 * @property {Array.<sharelib.IUserReference>|null} [staff] BusinessUnitProperties staff
                 * @property {Array.<sharelib.IUserReference>|null} [staffAssistant] BusinessUnitProperties staffAssistant
                 * @property {string|null} [companyId] BusinessUnitProperties companyId
                 */

                /**
                 * Constructs a new BusinessUnitProperties.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a BusinessUnitProperties.
                 * @implements IBusinessUnitProperties
                 * @constructor
                 * @param {mtechnavi.api.survey.IBusinessUnitProperties=} [properties] Properties to set
                 */
                function BusinessUnitProperties(properties) {
                    this.businessUnitContactIds = [];
                    this.mainContact = [];
                    this.mainContactAssistant = [];
                    this.staff = [];
                    this.staffAssistant = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessUnitProperties businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.businessUnitManagementId = "";

                /**
                 * BusinessUnitProperties businessUnitContactIds.
                 * @member {Array.<string>} businessUnitContactIds
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.businessUnitContactIds = $util.emptyArray;

                /**
                 * BusinessUnitProperties code.
                 * @member {string} code
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.code = "";

                /**
                 * BusinessUnitProperties displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.displayName = "";

                /**
                 * BusinessUnitProperties mainContact.
                 * @member {Array.<sharelib.IUserReference>} mainContact
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.mainContact = $util.emptyArray;

                /**
                 * BusinessUnitProperties mainContactAssistant.
                 * @member {Array.<sharelib.IUserReference>} mainContactAssistant
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.mainContactAssistant = $util.emptyArray;

                /**
                 * BusinessUnitProperties staff.
                 * @member {Array.<sharelib.IUserReference>} staff
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.staff = $util.emptyArray;

                /**
                 * BusinessUnitProperties staffAssistant.
                 * @member {Array.<sharelib.IUserReference>} staffAssistant
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.staffAssistant = $util.emptyArray;

                /**
                 * BusinessUnitProperties companyId.
                 * @member {string} companyId
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 */
                BusinessUnitProperties.prototype.companyId = "";

                /**
                 * Verifies a BusinessUnitProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessUnitProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.businessUnitContactIds != null && message.hasOwnProperty("businessUnitContactIds")) {
                        if (!Array.isArray(message.businessUnitContactIds))
                            return "businessUnitContactIds: array expected";
                        for (let i = 0; i < message.businessUnitContactIds.length; ++i)
                            if (!$util.isString(message.businessUnitContactIds[i]))
                                return "businessUnitContactIds: string[] expected";
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.mainContact != null && message.hasOwnProperty("mainContact")) {
                        if (!Array.isArray(message.mainContact))
                            return "mainContact: array expected";
                        for (let i = 0; i < message.mainContact.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContact[i]);
                            if (error)
                                return "mainContact." + error;
                        }
                    }
                    if (message.mainContactAssistant != null && message.hasOwnProperty("mainContactAssistant")) {
                        if (!Array.isArray(message.mainContactAssistant))
                            return "mainContactAssistant: array expected";
                        for (let i = 0; i < message.mainContactAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.mainContactAssistant[i]);
                            if (error)
                                return "mainContactAssistant." + error;
                        }
                    }
                    if (message.staff != null && message.hasOwnProperty("staff")) {
                        if (!Array.isArray(message.staff))
                            return "staff: array expected";
                        for (let i = 0; i < message.staff.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staff[i]);
                            if (error)
                                return "staff." + error;
                        }
                    }
                    if (message.staffAssistant != null && message.hasOwnProperty("staffAssistant")) {
                        if (!Array.isArray(message.staffAssistant))
                            return "staffAssistant: array expected";
                        for (let i = 0; i < message.staffAssistant.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.staffAssistant[i]);
                            if (error)
                                return "staffAssistant." + error;
                        }
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isString(message.companyId))
                            return "companyId: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessUnitProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.BusinessUnitProperties} BusinessUnitProperties
                 */
                BusinessUnitProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.BusinessUnitProperties)
                        return object;
                    let message = new $root.mtechnavi.api.survey.BusinessUnitProperties();
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.businessUnitContactIds) {
                        if (!Array.isArray(object.businessUnitContactIds))
                            throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.businessUnitContactIds: array expected");
                        message.businessUnitContactIds = [];
                        for (let i = 0; i < object.businessUnitContactIds.length; ++i)
                            message.businessUnitContactIds[i] = String(object.businessUnitContactIds[i]);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.mainContact) {
                        if (!Array.isArray(object.mainContact))
                            throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.mainContact: array expected");
                        message.mainContact = [];
                        for (let i = 0; i < object.mainContact.length; ++i) {
                            if (typeof object.mainContact[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.mainContact: object expected");
                            message.mainContact[i] = $root.sharelib.UserReference.fromObject(object.mainContact[i]);
                        }
                    }
                    if (object.mainContactAssistant) {
                        if (!Array.isArray(object.mainContactAssistant))
                            throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.mainContactAssistant: array expected");
                        message.mainContactAssistant = [];
                        for (let i = 0; i < object.mainContactAssistant.length; ++i) {
                            if (typeof object.mainContactAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.mainContactAssistant: object expected");
                            message.mainContactAssistant[i] = $root.sharelib.UserReference.fromObject(object.mainContactAssistant[i]);
                        }
                    }
                    if (object.staff) {
                        if (!Array.isArray(object.staff))
                            throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.staff: array expected");
                        message.staff = [];
                        for (let i = 0; i < object.staff.length; ++i) {
                            if (typeof object.staff[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.staff: object expected");
                            message.staff[i] = $root.sharelib.UserReference.fromObject(object.staff[i]);
                        }
                    }
                    if (object.staffAssistant) {
                        if (!Array.isArray(object.staffAssistant))
                            throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.staffAssistant: array expected");
                        message.staffAssistant = [];
                        for (let i = 0; i < object.staffAssistant.length; ++i) {
                            if (typeof object.staffAssistant[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.BusinessUnitProperties.staffAssistant: object expected");
                            message.staffAssistant[i] = $root.sharelib.UserReference.fromObject(object.staffAssistant[i]);
                        }
                    }
                    if (object.companyId != null)
                        message.companyId = String(object.companyId);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessUnitProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @static
                 * @param {mtechnavi.api.survey.BusinessUnitProperties} message BusinessUnitProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessUnitProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.mainContact = [];
                        object.mainContactAssistant = [];
                        object.staff = [];
                        object.staffAssistant = [];
                        object.businessUnitContactIds = [];
                    }
                    if (options.defaults) {
                        object.businessUnitManagementId = "";
                        object.code = "";
                        object.displayName = "";
                        object.companyId = "";
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.mainContact && message.mainContact.length) {
                        object.mainContact = [];
                        for (let j = 0; j < message.mainContact.length; ++j)
                            object.mainContact[j] = $root.sharelib.UserReference.toObject(message.mainContact[j], options);
                    }
                    if (message.mainContactAssistant && message.mainContactAssistant.length) {
                        object.mainContactAssistant = [];
                        for (let j = 0; j < message.mainContactAssistant.length; ++j)
                            object.mainContactAssistant[j] = $root.sharelib.UserReference.toObject(message.mainContactAssistant[j], options);
                    }
                    if (message.staff && message.staff.length) {
                        object.staff = [];
                        for (let j = 0; j < message.staff.length; ++j)
                            object.staff[j] = $root.sharelib.UserReference.toObject(message.staff[j], options);
                    }
                    if (message.staffAssistant && message.staffAssistant.length) {
                        object.staffAssistant = [];
                        for (let j = 0; j < message.staffAssistant.length; ++j)
                            object.staffAssistant[j] = $root.sharelib.UserReference.toObject(message.staffAssistant[j], options);
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        object.companyId = message.companyId;
                    if (message.businessUnitContactIds && message.businessUnitContactIds.length) {
                        object.businessUnitContactIds = [];
                        for (let j = 0; j < message.businessUnitContactIds.length; ++j)
                            object.businessUnitContactIds[j] = message.businessUnitContactIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessUnitProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.BusinessUnitProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessUnitProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BusinessUnitProperties;
            })();

            survey.AttachmentAttribute = (function() {

                /**
                 * Properties of an AttachmentAttribute.
                 * @memberof mtechnavi.api.survey
                 * @interface IAttachmentAttribute
                 * @property {sharelib.IAttachment|null} [attachment] AttachmentAttribute attachment
                 * @property {string|null} [businessUnitManagementId] AttachmentAttribute businessUnitManagementId
                 * @property {string|null} [displayName] AttachmentAttribute displayName
                 */

                /**
                 * Constructs a new AttachmentAttribute.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an AttachmentAttribute.
                 * @implements IAttachmentAttribute
                 * @constructor
                 * @param {mtechnavi.api.survey.IAttachmentAttribute=} [properties] Properties to set
                 */
                function AttachmentAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AttachmentAttribute attachment.
                 * @member {sharelib.IAttachment|null|undefined} attachment
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @instance
                 */
                AttachmentAttribute.prototype.attachment = null;

                /**
                 * AttachmentAttribute businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @instance
                 */
                AttachmentAttribute.prototype.businessUnitManagementId = "";

                /**
                 * AttachmentAttribute displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @instance
                 */
                AttachmentAttribute.prototype.displayName = "";

                /**
                 * Verifies an AttachmentAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AttachmentAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.attachment != null && message.hasOwnProperty("attachment")) {
                        let error = $root.sharelib.Attachment.verify(message.attachment);
                        if (error)
                            return "attachment." + error;
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    return null;
                };

                /**
                 * Creates an AttachmentAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.AttachmentAttribute} AttachmentAttribute
                 */
                AttachmentAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.AttachmentAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.survey.AttachmentAttribute();
                    if (object.attachment != null) {
                        if (typeof object.attachment !== "object")
                            throw TypeError(".mtechnavi.api.survey.AttachmentAttribute.attachment: object expected");
                        message.attachment = $root.sharelib.Attachment.fromObject(object.attachment);
                    }
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    return message;
                };

                /**
                 * Creates a plain object from an AttachmentAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @static
                 * @param {mtechnavi.api.survey.AttachmentAttribute} message AttachmentAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AttachmentAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.attachment = null;
                        object.businessUnitManagementId = "";
                        object.displayName = "";
                    }
                    if (message.attachment != null && message.hasOwnProperty("attachment"))
                        object.attachment = $root.sharelib.Attachment.toObject(message.attachment, options);
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    return object;
                };

                /**
                 * Converts this AttachmentAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.AttachmentAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AttachmentAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AttachmentAttribute;
            })();

            survey.Process = (function() {

                /**
                 * Properties of a Process.
                 * @memberof mtechnavi.api.survey
                 * @interface IProcess
                 * @property {Long|null} [processedAt] Process processedAt
                 * @property {sharelib.IEmbeddedUser|null} [processedBy] Process processedBy
                 */

                /**
                 * Constructs a new Process.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a Process.
                 * @implements IProcess
                 * @constructor
                 * @param {mtechnavi.api.survey.IProcess=} [properties] Properties to set
                 */
                function Process(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Process processedAt.
                 * @member {Long} processedAt
                 * @memberof mtechnavi.api.survey.Process
                 * @instance
                 */
                Process.prototype.processedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Process processedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} processedBy
                 * @memberof mtechnavi.api.survey.Process
                 * @instance
                 */
                Process.prototype.processedBy = null;

                /**
                 * Verifies a Process message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.Process
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Process.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (!$util.isInteger(message.processedAt) && !(message.processedAt && $util.isInteger(message.processedAt.low) && $util.isInteger(message.processedAt.high)))
                            return "processedAt: integer|Long expected";
                    if (message.processedBy != null && message.hasOwnProperty("processedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.processedBy);
                        if (error)
                            return "processedBy." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Process message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.Process
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.Process} Process
                 */
                Process.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.Process)
                        return object;
                    let message = new $root.mtechnavi.api.survey.Process();
                    if (object.processedAt != null)
                        if ($util.Long)
                            (message.processedAt = $util.Long.fromValue(object.processedAt)).unsigned = false;
                        else if (typeof object.processedAt === "string")
                            message.processedAt = parseInt(object.processedAt, 10);
                        else if (typeof object.processedAt === "number")
                            message.processedAt = object.processedAt;
                        else if (typeof object.processedAt === "object")
                            message.processedAt = new $util.LongBits(object.processedAt.low >>> 0, object.processedAt.high >>> 0).toNumber();
                    if (object.processedBy != null) {
                        if (typeof object.processedBy !== "object")
                            throw TypeError(".mtechnavi.api.survey.Process.processedBy: object expected");
                        message.processedBy = $root.sharelib.EmbeddedUser.fromObject(object.processedBy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Process message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.Process
                 * @static
                 * @param {mtechnavi.api.survey.Process} message Process
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Process.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.processedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processedAt = options.longs === String ? "0" : 0;
                        object.processedBy = null;
                    }
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (typeof message.processedAt === "number")
                            object.processedAt = options.longs === String ? String(message.processedAt) : message.processedAt;
                        else
                            object.processedAt = options.longs === String ? $util.Long.prototype.toString.call(message.processedAt) : options.longs === Number ? new $util.LongBits(message.processedAt.low >>> 0, message.processedAt.high >>> 0).toNumber() : message.processedAt;
                    if (message.processedBy != null && message.hasOwnProperty("processedBy"))
                        object.processedBy = $root.sharelib.EmbeddedUser.toObject(message.processedBy, options);
                    return object;
                };

                /**
                 * Converts this Process to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.Process
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Process.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Process;
            })();

            survey.FlagProperties = (function() {

                /**
                 * Properties of a FlagProperties.
                 * @memberof mtechnavi.api.survey
                 * @interface IFlagProperties
                 * @property {boolean|null} [active] FlagProperties active
                 * @property {string|null} [comment] FlagProperties comment
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [updatedProperties] FlagProperties updatedProperties
                 */

                /**
                 * Constructs a new FlagProperties.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a FlagProperties.
                 * @implements IFlagProperties
                 * @constructor
                 * @param {mtechnavi.api.survey.IFlagProperties=} [properties] Properties to set
                 */
                function FlagProperties(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FlagProperties active.
                 * @member {boolean} active
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @instance
                 */
                FlagProperties.prototype.active = false;

                /**
                 * FlagProperties comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @instance
                 */
                FlagProperties.prototype.comment = "";

                /**
                 * FlagProperties updatedProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} updatedProperties
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @instance
                 */
                FlagProperties.prototype.updatedProperties = null;

                /**
                 * Verifies a FlagProperties message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FlagProperties.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.active != null && message.hasOwnProperty("active"))
                        if (typeof message.active !== "boolean")
                            return "active: boolean expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.updatedProperties);
                        if (error)
                            return "updatedProperties." + error;
                    }
                    return null;
                };

                /**
                 * Creates a FlagProperties message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.FlagProperties} FlagProperties
                 */
                FlagProperties.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.FlagProperties)
                        return object;
                    let message = new $root.mtechnavi.api.survey.FlagProperties();
                    if (object.active != null)
                        message.active = Boolean(object.active);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    if (object.updatedProperties != null) {
                        if (typeof object.updatedProperties !== "object")
                            throw TypeError(".mtechnavi.api.survey.FlagProperties.updatedProperties: object expected");
                        message.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.updatedProperties);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FlagProperties message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @static
                 * @param {mtechnavi.api.survey.FlagProperties} message FlagProperties
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FlagProperties.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.active = false;
                        object.comment = "";
                        object.updatedProperties = null;
                    }
                    if (message.active != null && message.hasOwnProperty("active"))
                        object.active = message.active;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.updatedProperties != null && message.hasOwnProperty("updatedProperties"))
                        object.updatedProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.updatedProperties, options);
                    return object;
                };

                /**
                 * Converts this FlagProperties to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.FlagProperties
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FlagProperties.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FlagProperties;
            })();

            survey.SendTargetSetting = (function() {

                /**
                 * Properties of a SendTargetSetting.
                 * @memberof mtechnavi.api.survey
                 * @interface ISendTargetSetting
                 * @property {boolean|null} [activeBusinessUnit] SendTargetSetting activeBusinessUnit
                 * @property {boolean|null} [activeResponsibleUser] SendTargetSetting activeResponsibleUser
                 * @property {boolean|null} [activeMainContact] SendTargetSetting activeMainContact
                 */

                /**
                 * Constructs a new SendTargetSetting.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SendTargetSetting.
                 * @implements ISendTargetSetting
                 * @constructor
                 * @param {mtechnavi.api.survey.ISendTargetSetting=} [properties] Properties to set
                 */
                function SendTargetSetting(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendTargetSetting activeBusinessUnit.
                 * @member {boolean} activeBusinessUnit
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @instance
                 */
                SendTargetSetting.prototype.activeBusinessUnit = false;

                /**
                 * SendTargetSetting activeResponsibleUser.
                 * @member {boolean} activeResponsibleUser
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @instance
                 */
                SendTargetSetting.prototype.activeResponsibleUser = false;

                /**
                 * SendTargetSetting activeMainContact.
                 * @member {boolean} activeMainContact
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @instance
                 */
                SendTargetSetting.prototype.activeMainContact = false;

                /**
                 * Verifies a SendTargetSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendTargetSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.activeBusinessUnit != null && message.hasOwnProperty("activeBusinessUnit"))
                        if (typeof message.activeBusinessUnit !== "boolean")
                            return "activeBusinessUnit: boolean expected";
                    if (message.activeResponsibleUser != null && message.hasOwnProperty("activeResponsibleUser"))
                        if (typeof message.activeResponsibleUser !== "boolean")
                            return "activeResponsibleUser: boolean expected";
                    if (message.activeMainContact != null && message.hasOwnProperty("activeMainContact"))
                        if (typeof message.activeMainContact !== "boolean")
                            return "activeMainContact: boolean expected";
                    return null;
                };

                /**
                 * Creates a SendTargetSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SendTargetSetting} SendTargetSetting
                 */
                SendTargetSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SendTargetSetting)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SendTargetSetting();
                    if (object.activeBusinessUnit != null)
                        message.activeBusinessUnit = Boolean(object.activeBusinessUnit);
                    if (object.activeResponsibleUser != null)
                        message.activeResponsibleUser = Boolean(object.activeResponsibleUser);
                    if (object.activeMainContact != null)
                        message.activeMainContact = Boolean(object.activeMainContact);
                    return message;
                };

                /**
                 * Creates a plain object from a SendTargetSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @static
                 * @param {mtechnavi.api.survey.SendTargetSetting} message SendTargetSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendTargetSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.activeBusinessUnit = false;
                        object.activeResponsibleUser = false;
                        object.activeMainContact = false;
                    }
                    if (message.activeBusinessUnit != null && message.hasOwnProperty("activeBusinessUnit"))
                        object.activeBusinessUnit = message.activeBusinessUnit;
                    if (message.activeResponsibleUser != null && message.hasOwnProperty("activeResponsibleUser"))
                        object.activeResponsibleUser = message.activeResponsibleUser;
                    if (message.activeMainContact != null && message.hasOwnProperty("activeMainContact"))
                        object.activeMainContact = message.activeMainContact;
                    return object;
                };

                /**
                 * Converts this SendTargetSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SendTargetSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendTargetSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendTargetSetting;
            })();

            survey.SurveyBaseRequestContent = (function() {

                /**
                 * Properties of a SurveyBaseRequestContent.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyBaseRequestContent
                 * @property {mtechnavi.api.survey.ISurveyBaseRequest|null} [surveyBaseRequest] SurveyBaseRequestContent surveyBaseRequest
                 * @property {sharelib.INameOption|null} [status] SurveyBaseRequestContent status
                 * @property {Long|null} [totalRequestUnit] SurveyBaseRequestContent totalRequestUnit
                 */

                /**
                 * Constructs a new SurveyBaseRequestContent.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyBaseRequestContent.
                 * @implements ISurveyBaseRequestContent
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyBaseRequestContent=} [properties] Properties to set
                 */
                function SurveyBaseRequestContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyBaseRequestContent surveyBaseRequest.
                 * @member {mtechnavi.api.survey.ISurveyBaseRequest|null|undefined} surveyBaseRequest
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @instance
                 */
                SurveyBaseRequestContent.prototype.surveyBaseRequest = null;

                /**
                 * SurveyBaseRequestContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @instance
                 */
                SurveyBaseRequestContent.prototype.status = null;

                /**
                 * SurveyBaseRequestContent totalRequestUnit.
                 * @member {Long} totalRequestUnit
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @instance
                 */
                SurveyBaseRequestContent.prototype.totalRequestUnit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyBaseRequestContent message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyBaseRequestContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyBaseRequest.verify(message.surveyBaseRequest);
                        if (error)
                            return "surveyBaseRequest." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.totalRequestUnit != null && message.hasOwnProperty("totalRequestUnit"))
                        if (!$util.isInteger(message.totalRequestUnit) && !(message.totalRequestUnit && $util.isInteger(message.totalRequestUnit.low) && $util.isInteger(message.totalRequestUnit.high)))
                            return "totalRequestUnit: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyBaseRequestContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyBaseRequestContent} SurveyBaseRequestContent
                 */
                SurveyBaseRequestContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyBaseRequestContent)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyBaseRequestContent();
                    if (object.surveyBaseRequest != null) {
                        if (typeof object.surveyBaseRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequestContent.surveyBaseRequest: object expected");
                        message.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.fromObject(object.surveyBaseRequest);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyBaseRequestContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.totalRequestUnit != null)
                        if ($util.Long)
                            (message.totalRequestUnit = $util.Long.fromValue(object.totalRequestUnit)).unsigned = false;
                        else if (typeof object.totalRequestUnit === "string")
                            message.totalRequestUnit = parseInt(object.totalRequestUnit, 10);
                        else if (typeof object.totalRequestUnit === "number")
                            message.totalRequestUnit = object.totalRequestUnit;
                        else if (typeof object.totalRequestUnit === "object")
                            message.totalRequestUnit = new $util.LongBits(object.totalRequestUnit.low >>> 0, object.totalRequestUnit.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyBaseRequestContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @static
                 * @param {mtechnavi.api.survey.SurveyBaseRequestContent} message SurveyBaseRequestContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyBaseRequestContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyBaseRequest = null;
                        object.status = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.totalRequestUnit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalRequestUnit = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyBaseRequest != null && message.hasOwnProperty("surveyBaseRequest"))
                        object.surveyBaseRequest = $root.mtechnavi.api.survey.SurveyBaseRequest.toObject(message.surveyBaseRequest, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.totalRequestUnit != null && message.hasOwnProperty("totalRequestUnit"))
                        if (typeof message.totalRequestUnit === "number")
                            object.totalRequestUnit = options.longs === String ? String(message.totalRequestUnit) : message.totalRequestUnit;
                        else
                            object.totalRequestUnit = options.longs === String ? $util.Long.prototype.toString.call(message.totalRequestUnit) : options.longs === Number ? new $util.LongBits(message.totalRequestUnit.low >>> 0, message.totalRequestUnit.high >>> 0).toNumber() : message.totalRequestUnit;
                    return object;
                };

                /**
                 * Converts this SurveyBaseRequestContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyBaseRequestContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyBaseRequestContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyBaseRequestContent;
            })();

            survey.SurveyRequestContent = (function() {

                /**
                 * Properties of a SurveyRequestContent.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyRequestContent
                 * @property {mtechnavi.api.survey.ISurveyRequest|null} [surveyRequest] SurveyRequestContent surveyRequest
                 * @property {sharelib.INameOption|null} [status] SurveyRequestContent status
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyRequestContent replyDueDateDt
                 * @property {Long|null} [surveyRequestNotificationAutoName] SurveyRequestContent surveyRequestNotificationAutoName
                 */

                /**
                 * Constructs a new SurveyRequestContent.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyRequestContent.
                 * @implements ISurveyRequestContent
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyRequestContent=} [properties] Properties to set
                 */
                function SurveyRequestContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestContent surveyRequest.
                 * @member {mtechnavi.api.survey.ISurveyRequest|null|undefined} surveyRequest
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @instance
                 */
                SurveyRequestContent.prototype.surveyRequest = null;

                /**
                 * SurveyRequestContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @instance
                 */
                SurveyRequestContent.prototype.status = null;

                /**
                 * SurveyRequestContent replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @instance
                 */
                SurveyRequestContent.prototype.replyDueDateDt = null;

                /**
                 * SurveyRequestContent surveyRequestNotificationAutoName.
                 * @member {Long} surveyRequestNotificationAutoName
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @instance
                 */
                SurveyRequestContent.prototype.surveyRequestNotificationAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a SurveyRequestContent message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequest.verify(message.surveyRequest);
                        if (error)
                            return "surveyRequest." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.surveyRequestNotificationAutoName != null && message.hasOwnProperty("surveyRequestNotificationAutoName"))
                        if (!$util.isInteger(message.surveyRequestNotificationAutoName) && !(message.surveyRequestNotificationAutoName && $util.isInteger(message.surveyRequestNotificationAutoName.low) && $util.isInteger(message.surveyRequestNotificationAutoName.high)))
                            return "surveyRequestNotificationAutoName: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyRequestContent} SurveyRequestContent
                 */
                SurveyRequestContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyRequestContent)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyRequestContent();
                    if (object.surveyRequest != null) {
                        if (typeof object.surveyRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestContent.surveyRequest: object expected");
                        message.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.fromObject(object.surveyRequest);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyRequestContent.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.surveyRequestNotificationAutoName != null)
                        if ($util.Long)
                            (message.surveyRequestNotificationAutoName = $util.Long.fromValue(object.surveyRequestNotificationAutoName)).unsigned = false;
                        else if (typeof object.surveyRequestNotificationAutoName === "string")
                            message.surveyRequestNotificationAutoName = parseInt(object.surveyRequestNotificationAutoName, 10);
                        else if (typeof object.surveyRequestNotificationAutoName === "number")
                            message.surveyRequestNotificationAutoName = object.surveyRequestNotificationAutoName;
                        else if (typeof object.surveyRequestNotificationAutoName === "object")
                            message.surveyRequestNotificationAutoName = new $util.LongBits(object.surveyRequestNotificationAutoName.low >>> 0, object.surveyRequestNotificationAutoName.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @static
                 * @param {mtechnavi.api.survey.SurveyRequestContent} message SurveyRequestContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyRequest = null;
                        object.status = null;
                        object.replyDueDateDt = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestNotificationAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestNotificationAutoName = options.longs === String ? "0" : 0;
                    }
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest"))
                        object.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.toObject(message.surveyRequest, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.surveyRequestNotificationAutoName != null && message.hasOwnProperty("surveyRequestNotificationAutoName"))
                        if (typeof message.surveyRequestNotificationAutoName === "number")
                            object.surveyRequestNotificationAutoName = options.longs === String ? String(message.surveyRequestNotificationAutoName) : message.surveyRequestNotificationAutoName;
                        else
                            object.surveyRequestNotificationAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestNotificationAutoName) : options.longs === Number ? new $util.LongBits(message.surveyRequestNotificationAutoName.low >>> 0, message.surveyRequestNotificationAutoName.high >>> 0).toNumber() : message.surveyRequestNotificationAutoName;
                    return object;
                };

                /**
                 * Converts this SurveyRequestContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyRequestContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestContent;
            })();

            survey.SurveyReceptionContent = (function() {

                /**
                 * Properties of a SurveyReceptionContent.
                 * @memberof mtechnavi.api.survey
                 * @interface ISurveyReceptionContent
                 * @property {sharelib.IEmbeddedCompany|null} [requestingCompany] SurveyReceptionContent requestingCompany
                 * @property {mtechnavi.api.survey.ISurveyReception|null} [surveyReception] SurveyReceptionContent surveyReception
                 * @property {mtechnavi.api.survey.ISurveyRequest|null} [surveyRequest] SurveyReceptionContent surveyRequest
                 * @property {sharelib.INameOption|null} [status] SurveyReceptionContent status
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyReceptionContent replyDueDateDt
                 */

                /**
                 * Constructs a new SurveyReceptionContent.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyReceptionContent.
                 * @implements ISurveyReceptionContent
                 * @constructor
                 * @param {mtechnavi.api.survey.ISurveyReceptionContent=} [properties] Properties to set
                 */
                function SurveyReceptionContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyReceptionContent requestingCompany.
                 * @member {sharelib.IEmbeddedCompany|null|undefined} requestingCompany
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 */
                SurveyReceptionContent.prototype.requestingCompany = null;

                /**
                 * SurveyReceptionContent surveyReception.
                 * @member {mtechnavi.api.survey.ISurveyReception|null|undefined} surveyReception
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 */
                SurveyReceptionContent.prototype.surveyReception = null;

                /**
                 * SurveyReceptionContent surveyRequest.
                 * @member {mtechnavi.api.survey.ISurveyRequest|null|undefined} surveyRequest
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 */
                SurveyReceptionContent.prototype.surveyRequest = null;

                /**
                 * SurveyReceptionContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 */
                SurveyReceptionContent.prototype.status = null;

                /**
                 * SurveyReceptionContent replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 */
                SurveyReceptionContent.prototype.replyDueDateDt = null;

                /**
                 * Verifies a SurveyReceptionContent message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyReceptionContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany")) {
                        let error = $root.sharelib.EmbeddedCompany.verify(message.requestingCompany);
                        if (error)
                            return "requestingCompany." + error;
                    }
                    if (message.surveyReception != null && message.hasOwnProperty("surveyReception")) {
                        let error = $root.mtechnavi.api.survey.SurveyReception.verify(message.surveyReception);
                        if (error)
                            return "surveyReception." + error;
                    }
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequest.verify(message.surveyRequest);
                        if (error)
                            return "surveyRequest." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SurveyReceptionContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.SurveyReceptionContent} SurveyReceptionContent
                 */
                SurveyReceptionContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.SurveyReceptionContent)
                        return object;
                    let message = new $root.mtechnavi.api.survey.SurveyReceptionContent();
                    if (object.requestingCompany != null) {
                        if (typeof object.requestingCompany !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReceptionContent.requestingCompany: object expected");
                        message.requestingCompany = $root.sharelib.EmbeddedCompany.fromObject(object.requestingCompany);
                    }
                    if (object.surveyReception != null) {
                        if (typeof object.surveyReception !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReceptionContent.surveyReception: object expected");
                        message.surveyReception = $root.mtechnavi.api.survey.SurveyReception.fromObject(object.surveyReception);
                    }
                    if (object.surveyRequest != null) {
                        if (typeof object.surveyRequest !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReceptionContent.surveyRequest: object expected");
                        message.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.fromObject(object.surveyRequest);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReceptionContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.survey.SurveyReceptionContent.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyReceptionContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @static
                 * @param {mtechnavi.api.survey.SurveyReceptionContent} message SurveyReceptionContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyReceptionContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.surveyReception = null;
                        object.surveyRequest = null;
                        object.status = null;
                        object.replyDueDateDt = null;
                        object.requestingCompany = null;
                    }
                    if (message.surveyReception != null && message.hasOwnProperty("surveyReception"))
                        object.surveyReception = $root.mtechnavi.api.survey.SurveyReception.toObject(message.surveyReception, options);
                    if (message.surveyRequest != null && message.hasOwnProperty("surveyRequest"))
                        object.surveyRequest = $root.mtechnavi.api.survey.SurveyRequest.toObject(message.surveyRequest, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.requestingCompany != null && message.hasOwnProperty("requestingCompany"))
                        object.requestingCompany = $root.sharelib.EmbeddedCompany.toObject(message.requestingCompany, options);
                    return object;
                };

                /**
                 * Converts this SurveyReceptionContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.SurveyReceptionContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyReceptionContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyReceptionContent;
            })();

            survey.SurveyIOService = (function() {

                /**
                 * Constructs a new SurveyIOService service.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents a SurveyIOService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function SurveyIOService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (SurveyIOService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SurveyIOService;

                /**
                 * Callback as used by {@link mtechnavi.api.survey.SurveyIOService#exportSurveyResultFiles}.
                 * @memberof mtechnavi.api.survey.SurveyIOService
                 * @typedef ExportSurveyResultFilesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.survey.ExportSurveyResultFilesResponse} [response] ExportSurveyResultFilesResponse
                 */

                /**
                 * Calls ExportSurveyResultFiles.
                 * @function exportSurveyResultFiles
                 * @memberof mtechnavi.api.survey.SurveyIOService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyResultFilesRequest} request ExportSurveyResultFilesRequest message or plain object
                 * @param {mtechnavi.api.survey.SurveyIOService.ExportSurveyResultFilesCallback} callback Node-style callback called with the error, if any, and ExportSurveyResultFilesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(SurveyIOService.prototype.exportSurveyResultFiles = function exportSurveyResultFiles(request, callback) {
                    return this.rpcCall(exportSurveyResultFiles, $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest, $root.mtechnavi.api.survey.ExportSurveyResultFilesResponse, request, callback);
                }, "name", { value: "ExportSurveyResultFiles" });

                /**
                 * Calls ExportSurveyResultFiles.
                 * @function exportSurveyResultFiles
                 * @memberof mtechnavi.api.survey.SurveyIOService
                 * @instance
                 * @param {mtechnavi.api.survey.IExportSurveyResultFilesRequest} request ExportSurveyResultFilesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.survey.ExportSurveyResultFilesResponse>} Promise
                 * @variation 2
                 */

                return SurveyIOService;
            })();

            survey.ExportSurveyResultFilesRequest = (function() {

                /**
                 * Properties of an ExportSurveyResultFilesRequest.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyResultFilesRequest
                 * @property {Array.<mtechnavi.api.survey.ExportSurveyResultFilesRequest.ITarget>|null} [targets] ExportSurveyResultFilesRequest targets
                 * @property {boolean|null} [includeExported] ExportSurveyResultFilesRequest includeExported
                 */

                /**
                 * Constructs a new ExportSurveyResultFilesRequest.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyResultFilesRequest.
                 * @implements IExportSurveyResultFilesRequest
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyResultFilesRequest=} [properties] Properties to set
                 */
                function ExportSurveyResultFilesRequest(properties) {
                    this.targets = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyResultFilesRequest targets.
                 * @member {Array.<mtechnavi.api.survey.ExportSurveyResultFilesRequest.ITarget>} targets
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @instance
                 */
                ExportSurveyResultFilesRequest.prototype.targets = $util.emptyArray;

                /**
                 * ExportSurveyResultFilesRequest includeExported.
                 * @member {boolean} includeExported
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @instance
                 */
                ExportSurveyResultFilesRequest.prototype.includeExported = false;

                /**
                 * Verifies an ExportSurveyResultFilesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyResultFilesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.targets != null && message.hasOwnProperty("targets")) {
                        if (!Array.isArray(message.targets))
                            return "targets: array expected";
                        for (let i = 0; i < message.targets.length; ++i) {
                            let error = $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target.verify(message.targets[i]);
                            if (error)
                                return "targets." + error;
                        }
                    }
                    if (message.includeExported != null && message.hasOwnProperty("includeExported"))
                        if (typeof message.includeExported !== "boolean")
                            return "includeExported: boolean expected";
                    return null;
                };

                /**
                 * Creates an ExportSurveyResultFilesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyResultFilesRequest} ExportSurveyResultFilesRequest
                 */
                ExportSurveyResultFilesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest();
                    if (object.targets) {
                        if (!Array.isArray(object.targets))
                            throw TypeError(".mtechnavi.api.survey.ExportSurveyResultFilesRequest.targets: array expected");
                        message.targets = [];
                        for (let i = 0; i < object.targets.length; ++i) {
                            if (typeof object.targets[i] !== "object")
                                throw TypeError(".mtechnavi.api.survey.ExportSurveyResultFilesRequest.targets: object expected");
                            message.targets[i] = $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target.fromObject(object.targets[i]);
                        }
                    }
                    if (object.includeExported != null)
                        message.includeExported = Boolean(object.includeExported);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyResultFilesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyResultFilesRequest} message ExportSurveyResultFilesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyResultFilesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.targets = [];
                    if (options.defaults)
                        object.includeExported = false;
                    if (message.targets && message.targets.length) {
                        object.targets = [];
                        for (let j = 0; j < message.targets.length; ++j)
                            object.targets[j] = $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target.toObject(message.targets[j], options);
                    }
                    if (message.includeExported != null && message.hasOwnProperty("includeExported"))
                        object.includeExported = message.includeExported;
                    return object;
                };

                /**
                 * Converts this ExportSurveyResultFilesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyResultFilesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ExportSurveyResultFilesRequest.Target = (function() {

                    /**
                     * Properties of a Target.
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                     * @interface ITarget
                     * @property {string|null} [surveyBaseRequestId] Target surveyBaseRequestId
                     * @property {string|null} [statusCode] Target statusCode
                     */

                    /**
                     * Constructs a new Target.
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest
                     * @classdesc Represents a Target.
                     * @implements ITarget
                     * @constructor
                     * @param {mtechnavi.api.survey.ExportSurveyResultFilesRequest.ITarget=} [properties] Properties to set
                     */
                    function Target(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Target surveyBaseRequestId.
                     * @member {string} surveyBaseRequestId
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @instance
                     */
                    Target.prototype.surveyBaseRequestId = "";

                    /**
                     * Target statusCode.
                     * @member {string} statusCode
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @instance
                     */
                    Target.prototype.statusCode = "";

                    /**
                     * Verifies a Target message.
                     * @function verify
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Target.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                            if (!$util.isString(message.surveyBaseRequestId))
                                return "surveyBaseRequestId: string expected";
                        if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                            if (!$util.isString(message.statusCode))
                                return "statusCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a Target message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target} Target
                     */
                    Target.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target)
                            return object;
                        let message = new $root.mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target();
                        if (object.surveyBaseRequestId != null)
                            message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                        if (object.statusCode != null)
                            message.statusCode = String(object.statusCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Target message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @static
                     * @param {mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target} message Target
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Target.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.surveyBaseRequestId = "";
                            object.statusCode = "";
                        }
                        if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                            object.surveyBaseRequestId = message.surveyBaseRequestId;
                        if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                            object.statusCode = message.statusCode;
                        return object;
                    };

                    /**
                     * Converts this Target to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.survey.ExportSurveyResultFilesRequest.Target
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Target.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Target;
                })();

                return ExportSurveyResultFilesRequest;
            })();

            survey.ExportSurveyResultFilesResponse = (function() {

                /**
                 * Properties of an ExportSurveyResultFilesResponse.
                 * @memberof mtechnavi.api.survey
                 * @interface IExportSurveyResultFilesResponse
                 * @property {string|null} [assetId] ExportSurveyResultFilesResponse assetId
                 */

                /**
                 * Constructs a new ExportSurveyResultFilesResponse.
                 * @memberof mtechnavi.api.survey
                 * @classdesc Represents an ExportSurveyResultFilesResponse.
                 * @implements IExportSurveyResultFilesResponse
                 * @constructor
                 * @param {mtechnavi.api.survey.IExportSurveyResultFilesResponse=} [properties] Properties to set
                 */
                function ExportSurveyResultFilesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportSurveyResultFilesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesResponse
                 * @instance
                 */
                ExportSurveyResultFilesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportSurveyResultFilesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportSurveyResultFilesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportSurveyResultFilesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.survey.ExportSurveyResultFilesResponse} ExportSurveyResultFilesResponse
                 */
                ExportSurveyResultFilesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.survey.ExportSurveyResultFilesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.survey.ExportSurveyResultFilesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportSurveyResultFilesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesResponse
                 * @static
                 * @param {mtechnavi.api.survey.ExportSurveyResultFilesResponse} message ExportSurveyResultFilesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportSurveyResultFilesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportSurveyResultFilesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.survey.ExportSurveyResultFilesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportSurveyResultFilesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportSurveyResultFilesResponse;
            })();

            return survey;
        })();

        api.tenantadmin = (function() {

            /**
             * Namespace tenantadmin.
             * @memberof mtechnavi.api
             * @namespace
             */
            const tenantadmin = {};

            tenantadmin.TenantAdminService = (function() {

                /**
                 * Constructs a new TenantAdminService service.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a TenantAdminService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function TenantAdminService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (TenantAdminService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TenantAdminService;

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#createUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef CreateUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} [response] UserAttribute
                 */

                /**
                 * Calls CreateUserAttribute.
                 * @function createUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAttributeRequest} request CreateUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.CreateUserAttributeCallback} callback Node-style callback called with the error, if any, and UserAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.createUserAttribute = function createUserAttribute(request, callback) {
                    return this.rpcCall(createUserAttribute, $root.mtechnavi.api.tenantadmin.CreateUserAttributeRequest, $root.mtechnavi.api.tenantadmin.UserAttribute, request, callback);
                }, "name", { value: "CreateUserAttribute" });

                /**
                 * Calls CreateUserAttribute.
                 * @function createUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAttributeRequest} request CreateUserAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#createUserAndUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef CreateUserAndUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} [response] UserAttribute
                 */

                /**
                 * Calls CreateUserAndUserAttribute.
                 * @function createUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAndUserAttributeRequest} request CreateUserAndUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.CreateUserAndUserAttributeCallback} callback Node-style callback called with the error, if any, and UserAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.createUserAndUserAttribute = function createUserAndUserAttribute(request, callback) {
                    return this.rpcCall(createUserAndUserAttribute, $root.mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest, $root.mtechnavi.api.tenantadmin.UserAttribute, request, callback);
                }, "name", { value: "CreateUserAndUserAttribute" });

                /**
                 * Calls CreateUserAndUserAttribute.
                 * @function createUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAndUserAttributeRequest} request CreateUserAndUserAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserAttributes}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributesResponse} [response] ListUserAttributesResponse
                 */

                /**
                 * Calls ListUserAttributes.
                 * @function listUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributesRequest} request ListUserAttributesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserAttributesCallback} callback Node-style callback called with the error, if any, and ListUserAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserAttributes = function listUserAttributes(request, callback) {
                    return this.rpcCall(listUserAttributes, $root.mtechnavi.api.tenantadmin.ListUserAttributesRequest, $root.mtechnavi.api.tenantadmin.ListUserAttributesResponse, request, callback);
                }, "name", { value: "ListUserAttributes" });

                /**
                 * Calls ListUserAttributes.
                 * @function listUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributesRequest} request ListUserAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#getUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef GetUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} [response] UserAttribute
                 */

                /**
                 * Calls GetUserAttribute.
                 * @function getUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetUserAttributeRequest} request GetUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.GetUserAttributeCallback} callback Node-style callback called with the error, if any, and UserAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.getUserAttribute = function getUserAttribute(request, callback) {
                    return this.rpcCall(getUserAttribute, $root.mtechnavi.api.tenantadmin.GetUserAttributeRequest, $root.mtechnavi.api.tenantadmin.UserAttribute, request, callback);
                }, "name", { value: "GetUserAttribute" });

                /**
                 * Calls GetUserAttribute.
                 * @function getUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetUserAttributeRequest} request GetUserAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#updateUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef UpdateUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} [response] UserAttribute
                 */

                /**
                 * Calls UpdateUserAttribute.
                 * @function updateUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAttributeRequest} request UpdateUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.UpdateUserAttributeCallback} callback Node-style callback called with the error, if any, and UserAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.updateUserAttribute = function updateUserAttribute(request, callback) {
                    return this.rpcCall(updateUserAttribute, $root.mtechnavi.api.tenantadmin.UpdateUserAttributeRequest, $root.mtechnavi.api.tenantadmin.UserAttribute, request, callback);
                }, "name", { value: "UpdateUserAttribute" });

                /**
                 * Calls UpdateUserAttribute.
                 * @function updateUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAttributeRequest} request UpdateUserAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#updateUserAndUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef UpdateUserAndUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} [response] UserAttribute
                 */

                /**
                 * Calls UpdateUserAndUserAttribute.
                 * @function updateUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAndUserAttributeRequest} request UpdateUserAndUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.UpdateUserAndUserAttributeCallback} callback Node-style callback called with the error, if any, and UserAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.updateUserAndUserAttribute = function updateUserAndUserAttribute(request, callback) {
                    return this.rpcCall(updateUserAndUserAttribute, $root.mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest, $root.mtechnavi.api.tenantadmin.UserAttribute, request, callback);
                }, "name", { value: "UpdateUserAndUserAttribute" });

                /**
                 * Calls UpdateUserAndUserAttribute.
                 * @function updateUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAndUserAttributeRequest} request UpdateUserAndUserAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#deleteUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DeleteUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserAttribute.
                 * @function deleteUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAttributeRequest} request DeleteUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DeleteUserAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.deleteUserAttribute = function deleteUserAttribute(request, callback) {
                    return this.rpcCall(deleteUserAttribute, $root.mtechnavi.api.tenantadmin.DeleteUserAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserAttribute" });

                /**
                 * Calls DeleteUserAttribute.
                 * @function deleteUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAttributeRequest} request DeleteUserAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#deleteUserAndUserAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DeleteUserAndUserAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserAndUserAttribute.
                 * @function deleteUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAndUserAttributeRequest} request DeleteUserAndUserAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DeleteUserAndUserAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.deleteUserAndUserAttribute = function deleteUserAndUserAttribute(request, callback) {
                    return this.rpcCall(deleteUserAndUserAttribute, $root.mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserAndUserAttribute" });

                /**
                 * Calls DeleteUserAndUserAttribute.
                 * @function deleteUserAndUserAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAndUserAttributeRequest} request DeleteUserAndUserAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#disableUser}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DisableUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.DisableUserResponse} [response] DisableUserResponse
                 */

                /**
                 * Calls DisableUser.
                 * @function disableUser
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDisableUserRequest} request DisableUserRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DisableUserCallback} callback Node-style callback called with the error, if any, and DisableUserResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.disableUser = function disableUser(request, callback) {
                    return this.rpcCall(disableUser, $root.mtechnavi.api.tenantadmin.DisableUserRequest, $root.mtechnavi.api.tenantadmin.DisableUserResponse, request, callback);
                }, "name", { value: "DisableUser" });

                /**
                 * Calls DisableUser.
                 * @function disableUser
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDisableUserRequest} request DisableUserRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.DisableUserResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#enableUser}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef EnableUserCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.EnableUserResponse} [response] EnableUserResponse
                 */

                /**
                 * Calls EnableUser.
                 * @function enableUser
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IEnableUserRequest} request EnableUserRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.EnableUserCallback} callback Node-style callback called with the error, if any, and EnableUserResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.enableUser = function enableUser(request, callback) {
                    return this.rpcCall(enableUser, $root.mtechnavi.api.tenantadmin.EnableUserRequest, $root.mtechnavi.api.tenantadmin.EnableUserResponse, request, callback);
                }, "name", { value: "EnableUser" });

                /**
                 * Calls EnableUser.
                 * @function enableUser
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IEnableUserRequest} request EnableUserRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.EnableUserResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#importUserAttributes}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ImportUserAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ImportUserAttributesResponse} [response] ImportUserAttributesResponse
                 */

                /**
                 * Calls ImportUserAttributes.
                 * @function importUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IImportUserAttributesRequest} request ImportUserAttributesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ImportUserAttributesCallback} callback Node-style callback called with the error, if any, and ImportUserAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.importUserAttributes = function importUserAttributes(request, callback) {
                    return this.rpcCall(importUserAttributes, $root.mtechnavi.api.tenantadmin.ImportUserAttributesRequest, $root.mtechnavi.api.tenantadmin.ImportUserAttributesResponse, request, callback);
                }, "name", { value: "ImportUserAttributes" });

                /**
                 * Calls ImportUserAttributes.
                 * @function importUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IImportUserAttributesRequest} request ImportUserAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ImportUserAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#exportUserAttributes}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ExportUserAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ExportUserAttributesResponse} [response] ExportUserAttributesResponse
                 */

                /**
                 * Calls ExportUserAttributes.
                 * @function exportUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportUserAttributesRequest} request ExportUserAttributesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ExportUserAttributesCallback} callback Node-style callback called with the error, if any, and ExportUserAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.exportUserAttributes = function exportUserAttributes(request, callback) {
                    return this.rpcCall(exportUserAttributes, $root.mtechnavi.api.tenantadmin.ExportUserAttributesRequest, $root.mtechnavi.api.tenantadmin.ExportUserAttributesResponse, request, callback);
                }, "name", { value: "ExportUserAttributes" });

                /**
                 * Calls ExportUserAttributes.
                 * @function exportUserAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportUserAttributesRequest} request ExportUserAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ExportUserAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserAttributeFormats}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserAttributeFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse} [response] ListUserAttributeFormatsResponse
                 */

                /**
                 * Calls ListUserAttributeFormats.
                 * @function listUserAttributeFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributeFormatsRequest} request ListUserAttributeFormatsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserAttributeFormatsCallback} callback Node-style callback called with the error, if any, and ListUserAttributeFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserAttributeFormats = function listUserAttributeFormats(request, callback) {
                    return this.rpcCall(listUserAttributeFormats, $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest, $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse, request, callback);
                }, "name", { value: "ListUserAttributeFormats" });

                /**
                 * Calls ListUserAttributeFormats.
                 * @function listUserAttributeFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributeFormatsRequest} request ListUserAttributeFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#sendEmailUserCreated}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef SendEmailUserCreatedCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse} [response] SendEmailUserCreatedResponse
                 */

                /**
                 * Calls SendEmailUserCreated.
                 * @function sendEmailUserCreated
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ISendEmailUserCreatedRequest} request SendEmailUserCreatedRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.SendEmailUserCreatedCallback} callback Node-style callback called with the error, if any, and SendEmailUserCreatedResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.sendEmailUserCreated = function sendEmailUserCreated(request, callback) {
                    return this.rpcCall(sendEmailUserCreated, $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest, $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse, request, callback);
                }, "name", { value: "SendEmailUserCreated" });

                /**
                 * Calls SendEmailUserCreated.
                 * @function sendEmailUserCreated
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ISendEmailUserCreatedRequest} request SendEmailUserCreatedRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#createUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef CreateUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} [response] UserGroupAttribute
                 */

                /**
                 * Calls CreateUserGroupAttribute.
                 * @function createUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAttributeRequest} request CreateUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.CreateUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and UserGroupAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.createUserGroupAttribute = function createUserGroupAttribute(request, callback) {
                    return this.rpcCall(createUserGroupAttribute, $root.mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest, $root.mtechnavi.api.tenantadmin.UserGroupAttribute, request, callback);
                }, "name", { value: "CreateUserGroupAttribute" });

                /**
                 * Calls CreateUserGroupAttribute.
                 * @function createUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAttributeRequest} request CreateUserGroupAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserGroupAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#createUserGroupAndUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef CreateUserGroupAndUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} [response] UserGroupAttribute
                 */

                /**
                 * Calls CreateUserGroupAndUserGroupAttribute.
                 * @function createUserGroupAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAndUserGroupAttributeRequest} request CreateUserGroupAndUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.CreateUserGroupAndUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and UserGroupAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.createUserGroupAndUserGroupAttribute = function createUserGroupAndUserGroupAttribute(request, callback) {
                    return this.rpcCall(createUserGroupAndUserGroupAttribute, $root.mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest, $root.mtechnavi.api.tenantadmin.UserGroupAttribute, request, callback);
                }, "name", { value: "CreateUserGroupAndUserGroupAttribute" });

                /**
                 * Calls CreateUserGroupAndUserGroupAttribute.
                 * @function createUserGroupAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAndUserGroupAttributeRequest} request CreateUserGroupAndUserGroupAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserGroupAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserGroupAttributes}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserGroupAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse} [response] ListUserGroupAttributesResponse
                 */

                /**
                 * Calls ListUserGroupAttributes.
                 * @function listUserGroupAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributesRequest} request ListUserGroupAttributesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserGroupAttributesCallback} callback Node-style callback called with the error, if any, and ListUserGroupAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserGroupAttributes = function listUserGroupAttributes(request, callback) {
                    return this.rpcCall(listUserGroupAttributes, $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest, $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse, request, callback);
                }, "name", { value: "ListUserGroupAttributes" });

                /**
                 * Calls ListUserGroupAttributes.
                 * @function listUserGroupAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributesRequest} request ListUserGroupAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#getUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef GetUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} [response] UserGroupAttribute
                 */

                /**
                 * Calls GetUserGroupAttribute.
                 * @function getUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetUserGroupAttributeRequest} request GetUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.GetUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and UserGroupAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.getUserGroupAttribute = function getUserGroupAttribute(request, callback) {
                    return this.rpcCall(getUserGroupAttribute, $root.mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest, $root.mtechnavi.api.tenantadmin.UserGroupAttribute, request, callback);
                }, "name", { value: "GetUserGroupAttribute" });

                /**
                 * Calls GetUserGroupAttribute.
                 * @function getUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetUserGroupAttributeRequest} request GetUserGroupAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserGroupAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#updateUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef UpdateUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} [response] UserGroupAttribute
                 */

                /**
                 * Calls UpdateUserGroupAttribute.
                 * @function updateUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAttributeRequest} request UpdateUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.UpdateUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and UserGroupAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.updateUserGroupAttribute = function updateUserGroupAttribute(request, callback) {
                    return this.rpcCall(updateUserGroupAttribute, $root.mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest, $root.mtechnavi.api.tenantadmin.UserGroupAttribute, request, callback);
                }, "name", { value: "UpdateUserGroupAttribute" });

                /**
                 * Calls UpdateUserGroupAttribute.
                 * @function updateUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAttributeRequest} request UpdateUserGroupAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserGroupAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#updateUserGroupAndUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef UpdateUserGroupAndUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} [response] UserGroupAttribute
                 */

                /**
                 * Calls UpdateUserGroupAndUserGroupAttribute.
                 * @function updateUserGroupAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAndUserGroupAttributeRequest} request UpdateUserGroupAndUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.UpdateUserGroupAndUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and UserGroupAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.updateUserGroupAndUserGroupAttribute = function updateUserGroupAndUserGroupAttribute(request, callback) {
                    return this.rpcCall(updateUserGroupAndUserGroupAttribute, $root.mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest, $root.mtechnavi.api.tenantadmin.UserGroupAttribute, request, callback);
                }, "name", { value: "UpdateUserGroupAndUserGroupAttribute" });

                /**
                 * Calls UpdateUserGroupAndUserGroupAttribute.
                 * @function updateUserGroupAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAndUserGroupAttributeRequest} request UpdateUserGroupAndUserGroupAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.UserGroupAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#deleteUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DeleteUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserGroupAttribute.
                 * @function deleteUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAttributeRequest} request DeleteUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DeleteUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.deleteUserGroupAttribute = function deleteUserGroupAttribute(request, callback) {
                    return this.rpcCall(deleteUserGroupAttribute, $root.mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserGroupAttribute" });

                /**
                 * Calls DeleteUserGroupAttribute.
                 * @function deleteUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAttributeRequest} request DeleteUserGroupAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#deleteUserAndUserGroupAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DeleteUserAndUserGroupAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserAndUserGroupAttribute.
                 * @function deleteUserAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAndUserGroupAttributeRequest} request DeleteUserGroupAndUserGroupAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DeleteUserAndUserGroupAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.deleteUserAndUserGroupAttribute = function deleteUserAndUserGroupAttribute(request, callback) {
                    return this.rpcCall(deleteUserAndUserGroupAttribute, $root.mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserAndUserGroupAttribute" });

                /**
                 * Calls DeleteUserAndUserGroupAttribute.
                 * @function deleteUserAndUserGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAndUserGroupAttributeRequest} request DeleteUserGroupAndUserGroupAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserGroupAttributeContents}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserGroupAttributeContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse} [response] ListUserGroupAttributeContentsResponse
                 */

                /**
                 * Calls ListUserGroupAttributeContents.
                 * @function listUserGroupAttributeContents
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributeContentsRequest} request ListUserGroupAttributeContentsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserGroupAttributeContentsCallback} callback Node-style callback called with the error, if any, and ListUserGroupAttributeContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserGroupAttributeContents = function listUserGroupAttributeContents(request, callback) {
                    return this.rpcCall(listUserGroupAttributeContents, $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest, $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse, request, callback);
                }, "name", { value: "ListUserGroupAttributeContents" });

                /**
                 * Calls ListUserGroupAttributeContents.
                 * @function listUserGroupAttributeContents
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributeContentsRequest} request ListUserGroupAttributeContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#importUserBelongsUserGroups}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ImportUserBelongsUserGroupsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse} [response] ImportUserBelongsUserGroupsResponse
                 */

                /**
                 * Calls ImportUserBelongsUserGroups.
                 * @function importUserBelongsUserGroups
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IImportUserBelongsUserGroupsRequest} request ImportUserBelongsUserGroupsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ImportUserBelongsUserGroupsCallback} callback Node-style callback called with the error, if any, and ImportUserBelongsUserGroupsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.importUserBelongsUserGroups = function importUserBelongsUserGroups(request, callback) {
                    return this.rpcCall(importUserBelongsUserGroups, $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest, $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse, request, callback);
                }, "name", { value: "ImportUserBelongsUserGroups" });

                /**
                 * Calls ImportUserBelongsUserGroups.
                 * @function importUserBelongsUserGroups
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IImportUserBelongsUserGroupsRequest} request ImportUserBelongsUserGroupsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#exportUserBelongsUserGroups}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ExportUserBelongsUserGroupsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse} [response] ExportUserBelongsUserGroupsResponse
                 */

                /**
                 * Calls ExportUserBelongsUserGroups.
                 * @function exportUserBelongsUserGroups
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportUserBelongsUserGroupsRequest} request ExportUserBelongsUserGroupsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ExportUserBelongsUserGroupsCallback} callback Node-style callback called with the error, if any, and ExportUserBelongsUserGroupsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.exportUserBelongsUserGroups = function exportUserBelongsUserGroups(request, callback) {
                    return this.rpcCall(exportUserBelongsUserGroups, $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest, $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse, request, callback);
                }, "name", { value: "ExportUserBelongsUserGroups" });

                /**
                 * Calls ExportUserBelongsUserGroups.
                 * @function exportUserBelongsUserGroups
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportUserBelongsUserGroupsRequest} request ExportUserBelongsUserGroupsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserBelongsUserGroupFormats}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserBelongsUserGroupFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse} [response] ListUserBelongsUserGroupFormatsResponse
                 */

                /**
                 * Calls ListUserBelongsUserGroupFormats.
                 * @function listUserBelongsUserGroupFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserBelongsUserGroupFormatsRequest} request ListUserBelongsUserGroupFormatsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserBelongsUserGroupFormatsCallback} callback Node-style callback called with the error, if any, and ListUserBelongsUserGroupFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserBelongsUserGroupFormats = function listUserBelongsUserGroupFormats(request, callback) {
                    return this.rpcCall(listUserBelongsUserGroupFormats, $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest, $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse, request, callback);
                }, "name", { value: "ListUserBelongsUserGroupFormats" });

                /**
                 * Calls ListUserBelongsUserGroupFormats.
                 * @function listUserBelongsUserGroupFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserBelongsUserGroupFormatsRequest} request ListUserBelongsUserGroupFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#exportAttachedRoles}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ExportAttachedRolesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ExportAttachedRolesResponse} [response] ExportAttachedRolesResponse
                 */

                /**
                 * Calls ExportAttachedRoles.
                 * @function exportAttachedRoles
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportAttachedRolesRequest} request ExportAttachedRolesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ExportAttachedRolesCallback} callback Node-style callback called with the error, if any, and ExportAttachedRolesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.exportAttachedRoles = function exportAttachedRoles(request, callback) {
                    return this.rpcCall(exportAttachedRoles, $root.mtechnavi.api.tenantadmin.ExportAttachedRolesRequest, $root.mtechnavi.api.tenantadmin.ExportAttachedRolesResponse, request, callback);
                }, "name", { value: "ExportAttachedRoles" });

                /**
                 * Calls ExportAttachedRoles.
                 * @function exportAttachedRoles
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportAttachedRolesRequest} request ExportAttachedRolesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ExportAttachedRolesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserGroupAttachedRolesFormats}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserGroupAttachedRolesFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse} [response] ListUserGroupAttachedRolesFormatsResponse
                 */

                /**
                 * Calls ListUserGroupAttachedRolesFormats.
                 * @function listUserGroupAttachedRolesFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttachedRolesFormatsRequest} request ListUserGroupAttachedRolesFormatsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserGroupAttachedRolesFormatsCallback} callback Node-style callback called with the error, if any, and ListUserGroupAttachedRolesFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserGroupAttachedRolesFormats = function listUserGroupAttachedRolesFormats(request, callback) {
                    return this.rpcCall(listUserGroupAttachedRolesFormats, $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest, $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse, request, callback);
                }, "name", { value: "ListUserGroupAttachedRolesFormats" });

                /**
                 * Calls ListUserGroupAttachedRolesFormats.
                 * @function listUserGroupAttachedRolesFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttachedRolesFormatsRequest} request ListUserGroupAttachedRolesFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#exportAllowedMenuItems}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ExportAllowedMenuItemsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse} [response] ExportAllowedMenuItemsResponse
                 */

                /**
                 * Calls ExportAllowedMenuItems.
                 * @function exportAllowedMenuItems
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportAllowedMenuItemsRequest} request ExportAllowedMenuItemsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ExportAllowedMenuItemsCallback} callback Node-style callback called with the error, if any, and ExportAllowedMenuItemsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.exportAllowedMenuItems = function exportAllowedMenuItems(request, callback) {
                    return this.rpcCall(exportAllowedMenuItems, $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest, $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse, request, callback);
                }, "name", { value: "ExportAllowedMenuItems" });

                /**
                 * Calls ExportAllowedMenuItems.
                 * @function exportAllowedMenuItems
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IExportAllowedMenuItemsRequest} request ExportAllowedMenuItemsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listUserGroupAllowedMenuItemFormats}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListUserGroupAllowedMenuItemFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse} [response] ListUserGroupAllowedMenuItemFormatsResponse
                 */

                /**
                 * Calls ListUserGroupAllowedMenuItemFormats.
                 * @function listUserGroupAllowedMenuItemFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAllowedMenuItemFormatsRequest} request ListUserGroupAllowedMenuItemFormatsRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListUserGroupAllowedMenuItemFormatsCallback} callback Node-style callback called with the error, if any, and ListUserGroupAllowedMenuItemFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listUserGroupAllowedMenuItemFormats = function listUserGroupAllowedMenuItemFormats(request, callback) {
                    return this.rpcCall(listUserGroupAllowedMenuItemFormats, $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest, $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse, request, callback);
                }, "name", { value: "ListUserGroupAllowedMenuItemFormats" });

                /**
                 * Calls ListUserGroupAllowedMenuItemFormats.
                 * @function listUserGroupAllowedMenuItemFormats
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAllowedMenuItemFormatsRequest} request ListUserGroupAllowedMenuItemFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#createRoleAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef CreateRoleAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.RoleAttribute} [response] RoleAttribute
                 */

                /**
                 * Calls CreateRoleAttribute.
                 * @function createRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateRoleAttributeRequest} request CreateRoleAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.CreateRoleAttributeCallback} callback Node-style callback called with the error, if any, and RoleAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.createRoleAttribute = function createRoleAttribute(request, callback) {
                    return this.rpcCall(createRoleAttribute, $root.mtechnavi.api.tenantadmin.CreateRoleAttributeRequest, $root.mtechnavi.api.tenantadmin.RoleAttribute, request, callback);
                }, "name", { value: "CreateRoleAttribute" });

                /**
                 * Calls CreateRoleAttribute.
                 * @function createRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.ICreateRoleAttributeRequest} request CreateRoleAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.RoleAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#listRoleAttributes}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef ListRoleAttributesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.ListRoleAttributesResponse} [response] ListRoleAttributesResponse
                 */

                /**
                 * Calls ListRoleAttributes.
                 * @function listRoleAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListRoleAttributesRequest} request ListRoleAttributesRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.ListRoleAttributesCallback} callback Node-style callback called with the error, if any, and ListRoleAttributesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.listRoleAttributes = function listRoleAttributes(request, callback) {
                    return this.rpcCall(listRoleAttributes, $root.mtechnavi.api.tenantadmin.ListRoleAttributesRequest, $root.mtechnavi.api.tenantadmin.ListRoleAttributesResponse, request, callback);
                }, "name", { value: "ListRoleAttributes" });

                /**
                 * Calls ListRoleAttributes.
                 * @function listRoleAttributes
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IListRoleAttributesRequest} request ListRoleAttributesRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.ListRoleAttributesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#getRoleAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef GetRoleAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.RoleAttribute} [response] RoleAttribute
                 */

                /**
                 * Calls GetRoleAttribute.
                 * @function getRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetRoleAttributeRequest} request GetRoleAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.GetRoleAttributeCallback} callback Node-style callback called with the error, if any, and RoleAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.getRoleAttribute = function getRoleAttribute(request, callback) {
                    return this.rpcCall(getRoleAttribute, $root.mtechnavi.api.tenantadmin.GetRoleAttributeRequest, $root.mtechnavi.api.tenantadmin.RoleAttribute, request, callback);
                }, "name", { value: "GetRoleAttribute" });

                /**
                 * Calls GetRoleAttribute.
                 * @function getRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IGetRoleAttributeRequest} request GetRoleAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.RoleAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#updateRoleAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef UpdateRoleAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantadmin.RoleAttribute} [response] RoleAttribute
                 */

                /**
                 * Calls UpdateRoleAttribute.
                 * @function updateRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateRoleAttributeRequest} request UpdateRoleAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.UpdateRoleAttributeCallback} callback Node-style callback called with the error, if any, and RoleAttribute
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.updateRoleAttribute = function updateRoleAttribute(request, callback) {
                    return this.rpcCall(updateRoleAttribute, $root.mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest, $root.mtechnavi.api.tenantadmin.RoleAttribute, request, callback);
                }, "name", { value: "UpdateRoleAttribute" });

                /**
                 * Calls UpdateRoleAttribute.
                 * @function updateRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IUpdateRoleAttributeRequest} request UpdateRoleAttributeRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantadmin.RoleAttribute>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantadmin.TenantAdminService#deleteRoleAttribute}.
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @typedef DeleteRoleAttributeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteRoleAttribute.
                 * @function deleteRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteRoleAttributeRequest} request DeleteRoleAttributeRequest message or plain object
                 * @param {mtechnavi.api.tenantadmin.TenantAdminService.DeleteRoleAttributeCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantAdminService.prototype.deleteRoleAttribute = function deleteRoleAttribute(request, callback) {
                    return this.rpcCall(deleteRoleAttribute, $root.mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteRoleAttribute" });

                /**
                 * Calls DeleteRoleAttribute.
                 * @function deleteRoleAttribute
                 * @memberof mtechnavi.api.tenantadmin.TenantAdminService
                 * @instance
                 * @param {mtechnavi.api.tenantadmin.IDeleteRoleAttributeRequest} request DeleteRoleAttributeRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                return TenantAdminService;
            })();

            tenantadmin.CreateUserAttributeRequest = (function() {

                /**
                 * Properties of a CreateUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ICreateUserAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] CreateUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new CreateUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a CreateUserAttributeRequest.
                 * @implements ICreateUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAttributeRequest=} [properties] Properties to set
                 */
                function CreateUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAttributeRequest
                 * @instance
                 */
                CreateUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies a CreateUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.CreateUserAttributeRequest} CreateUserAttributeRequest
                 */
                CreateUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.CreateUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.CreateUserAttributeRequest();
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.CreateUserAttributeRequest} message CreateUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userAttribute = null;
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserAttributeRequest;
            })();

            tenantadmin.CreateUserAndUserAttributeRequest = (function() {

                /**
                 * Properties of a CreateUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ICreateUserAndUserAttributeRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] CreateUserAndUserAttributeRequest user
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] CreateUserAndUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new CreateUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a CreateUserAndUserAttributeRequest.
                 * @implements ICreateUserAndUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ICreateUserAndUserAttributeRequest=} [properties] Properties to set
                 */
                function CreateUserAndUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserAndUserAttributeRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @instance
                 */
                CreateUserAndUserAttributeRequest.prototype.user = null;

                /**
                 * CreateUserAndUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @instance
                 */
                CreateUserAndUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies a CreateUserAndUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserAndUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserAndUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest} CreateUserAndUserAttributeRequest
                 */
                CreateUserAndUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserAndUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest} message CreateUserAndUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserAndUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.user = null;
                        object.userAttribute = null;
                    }
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateUserAndUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.CreateUserAndUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserAndUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserAndUserAttributeRequest;
            })();

            tenantadmin.ListUserAttributesRequest = (function() {

                /**
                 * Properties of a ListUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserAttributesRequest
                 * @property {string|null} [pageToken] ListUserAttributesRequest pageToken
                 */

                /**
                 * Constructs a new ListUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserAttributesRequest.
                 * @implements IListUserAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributesRequest=} [properties] Properties to set
                 */
                function ListUserAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesRequest
                 * @instance
                 */
                ListUserAttributesRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListUserAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserAttributesRequest} ListUserAttributesRequest
                 */
                ListUserAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributesRequest} message ListUserAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserAttributesRequest;
            })();

            tenantadmin.ListUserAttributesResponse = (function() {

                /**
                 * Properties of a ListUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserAttributesResponse
                 * @property {Long|null} [total] ListUserAttributesResponse total
                 * @property {Array.<mtechnavi.api.tenantadmin.IUserAttribute>|null} [items] ListUserAttributesResponse items
                 * @property {string|null} [pageToken] ListUserAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserAttributesResponse.
                 * @implements IListUserAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributesResponse=} [properties] Properties to set
                 */
                function ListUserAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @instance
                 */
                ListUserAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserAttributesResponse items.
                 * @member {Array.<mtechnavi.api.tenantadmin.IUserAttribute>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @instance
                 */
                ListUserAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListUserAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @instance
                 */
                ListUserAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListUserAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserAttributesResponse} ListUserAttributesResponse
                 */
                ListUserAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributesResponse} message ListUserAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserAttributesResponse;
            })();

            tenantadmin.GetUserAttributeRequest = (function() {

                /**
                 * Properties of a GetUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IGetUserAttributeRequest
                 * @property {string|null} [userAttributeId] GetUserAttributeRequest userAttributeId
                 */

                /**
                 * Constructs a new GetUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a GetUserAttributeRequest.
                 * @implements IGetUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IGetUserAttributeRequest=} [properties] Properties to set
                 */
                function GetUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetUserAttributeRequest userAttributeId.
                 * @member {string} userAttributeId
                 * @memberof mtechnavi.api.tenantadmin.GetUserAttributeRequest
                 * @instance
                 */
                GetUserAttributeRequest.prototype.userAttributeId = "";

                /**
                 * Verifies a GetUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.GetUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAttributeId != null && message.hasOwnProperty("userAttributeId"))
                        if (!$util.isString(message.userAttributeId))
                            return "userAttributeId: string expected";
                    return null;
                };

                /**
                 * Creates a GetUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.GetUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.GetUserAttributeRequest} GetUserAttributeRequest
                 */
                GetUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.GetUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.GetUserAttributeRequest();
                    if (object.userAttributeId != null)
                        message.userAttributeId = String(object.userAttributeId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.GetUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.GetUserAttributeRequest} message GetUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userAttributeId = "";
                    if (message.userAttributeId != null && message.hasOwnProperty("userAttributeId"))
                        object.userAttributeId = message.userAttributeId;
                    return object;
                };

                /**
                 * Converts this GetUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.GetUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetUserAttributeRequest;
            })();

            tenantadmin.UpdateUserAttributeRequest = (function() {

                /**
                 * Properties of an UpdateUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUpdateUserAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] UpdateUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new UpdateUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an UpdateUserAttributeRequest.
                 * @implements IUpdateUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAttributeRequest=} [properties] Properties to set
                 */
                function UpdateUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAttributeRequest
                 * @instance
                 */
                UpdateUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies an UpdateUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UpdateUserAttributeRequest} UpdateUserAttributeRequest
                 */
                UpdateUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UpdateUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UpdateUserAttributeRequest();
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UpdateUserAttributeRequest} message UpdateUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userAttribute = null;
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserAttributeRequest;
            })();

            tenantadmin.UpdateUserAndUserAttributeRequest = (function() {

                /**
                 * Properties of an UpdateUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUpdateUserAndUserAttributeRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] UpdateUserAndUserAttributeRequest user
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] UpdateUserAndUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new UpdateUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an UpdateUserAndUserAttributeRequest.
                 * @implements IUpdateUserAndUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserAndUserAttributeRequest=} [properties] Properties to set
                 */
                function UpdateUserAndUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserAndUserAttributeRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @instance
                 */
                UpdateUserAndUserAttributeRequest.prototype.user = null;

                /**
                 * UpdateUserAndUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @instance
                 */
                UpdateUserAndUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies an UpdateUserAndUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserAndUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserAndUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest} UpdateUserAndUserAttributeRequest
                 */
                UpdateUserAndUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserAndUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest} message UpdateUserAndUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserAndUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.user = null;
                        object.userAttribute = null;
                    }
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserAndUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserAndUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserAndUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserAndUserAttributeRequest;
            })();

            tenantadmin.DeleteUserAttributeRequest = (function() {

                /**
                 * Properties of a DeleteUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDeleteUserAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] DeleteUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new DeleteUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DeleteUserAttributeRequest.
                 * @implements IDeleteUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAttributeRequest=} [properties] Properties to set
                 */
                function DeleteUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAttributeRequest
                 * @instance
                 */
                DeleteUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies a DeleteUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DeleteUserAttributeRequest} DeleteUserAttributeRequest
                 */
                DeleteUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DeleteUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DeleteUserAttributeRequest();
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DeleteUserAttributeRequest} message DeleteUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userAttribute = null;
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserAttributeRequest;
            })();

            tenantadmin.DeleteUserAndUserAttributeRequest = (function() {

                /**
                 * Properties of a DeleteUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDeleteUserAndUserAttributeRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] DeleteUserAndUserAttributeRequest user
                 * @property {mtechnavi.api.tenantadmin.IUserAttribute|null} [userAttribute] DeleteUserAndUserAttributeRequest userAttribute
                 */

                /**
                 * Constructs a new DeleteUserAndUserAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DeleteUserAndUserAttributeRequest.
                 * @implements IDeleteUserAndUserAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserAndUserAttributeRequest=} [properties] Properties to set
                 */
                function DeleteUserAndUserAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserAndUserAttributeRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @instance
                 */
                DeleteUserAndUserAttributeRequest.prototype.user = null;

                /**
                 * DeleteUserAndUserAttributeRequest userAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserAttribute|null|undefined} userAttribute
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @instance
                 */
                DeleteUserAndUserAttributeRequest.prototype.userAttribute = null;

                /**
                 * Verifies a DeleteUserAndUserAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserAndUserAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.userAttribute);
                        if (error)
                            return "userAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserAndUserAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest} DeleteUserAndUserAttributeRequest
                 */
                DeleteUserAndUserAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    if (object.userAttribute != null) {
                        if (typeof object.userAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest.userAttribute: object expected");
                        message.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.userAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserAndUserAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest} message DeleteUserAndUserAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserAndUserAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.user = null;
                        object.userAttribute = null;
                    }
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    if (message.userAttribute != null && message.hasOwnProperty("userAttribute"))
                        object.userAttribute = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.userAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserAndUserAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserAndUserAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserAndUserAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserAndUserAttributeRequest;
            })();

            tenantadmin.DisableUserRequest = (function() {

                /**
                 * Properties of a DisableUserRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDisableUserRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] DisableUserRequest user
                 */

                /**
                 * Constructs a new DisableUserRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DisableUserRequest.
                 * @implements IDisableUserRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDisableUserRequest=} [properties] Properties to set
                 */
                function DisableUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisableUserRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.DisableUserRequest
                 * @instance
                 */
                DisableUserRequest.prototype.user = null;

                /**
                 * Verifies a DisableUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DisableUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DisableUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DisableUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DisableUserRequest} DisableUserRequest
                 */
                DisableUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DisableUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DisableUserRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DisableUserRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DisableUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DisableUserRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DisableUserRequest} message DisableUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this DisableUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DisableUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisableUserRequest;
            })();

            tenantadmin.DisableUserResponse = (function() {

                /**
                 * Properties of a DisableUserResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDisableUserResponse
                 * @property {mtechnavi.api.idp.IUser|null} [user] DisableUserResponse user
                 */

                /**
                 * Constructs a new DisableUserResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DisableUserResponse.
                 * @implements IDisableUserResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDisableUserResponse=} [properties] Properties to set
                 */
                function DisableUserResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisableUserResponse user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.DisableUserResponse
                 * @instance
                 */
                DisableUserResponse.prototype.user = null;

                /**
                 * Verifies a DisableUserResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DisableUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisableUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DisableUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DisableUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DisableUserResponse} DisableUserResponse
                 */
                DisableUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DisableUserResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DisableUserResponse();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DisableUserResponse.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DisableUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DisableUserResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DisableUserResponse} message DisableUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisableUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this DisableUserResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DisableUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisableUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DisableUserResponse;
            })();

            tenantadmin.EnableUserRequest = (function() {

                /**
                 * Properties of an EnableUserRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IEnableUserRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] EnableUserRequest user
                 */

                /**
                 * Constructs a new EnableUserRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an EnableUserRequest.
                 * @implements IEnableUserRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IEnableUserRequest=} [properties] Properties to set
                 */
                function EnableUserRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnableUserRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.EnableUserRequest
                 * @instance
                 */
                EnableUserRequest.prototype.user = null;

                /**
                 * Verifies an EnableUserRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.EnableUserRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableUserRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EnableUserRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.EnableUserRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.EnableUserRequest} EnableUserRequest
                 */
                EnableUserRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.EnableUserRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.EnableUserRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.EnableUserRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EnableUserRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.EnableUserRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.EnableUserRequest} message EnableUserRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableUserRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this EnableUserRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.EnableUserRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableUserRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnableUserRequest;
            })();

            tenantadmin.EnableUserResponse = (function() {

                /**
                 * Properties of an EnableUserResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IEnableUserResponse
                 * @property {mtechnavi.api.idp.IUser|null} [user] EnableUserResponse user
                 */

                /**
                 * Constructs a new EnableUserResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an EnableUserResponse.
                 * @implements IEnableUserResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IEnableUserResponse=} [properties] Properties to set
                 */
                function EnableUserResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnableUserResponse user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.EnableUserResponse
                 * @instance
                 */
                EnableUserResponse.prototype.user = null;

                /**
                 * Verifies an EnableUserResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.EnableUserResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnableUserResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates an EnableUserResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.EnableUserResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.EnableUserResponse} EnableUserResponse
                 */
                EnableUserResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.EnableUserResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.EnableUserResponse();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.EnableUserResponse.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an EnableUserResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.EnableUserResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.EnableUserResponse} message EnableUserResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnableUserResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this EnableUserResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.EnableUserResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnableUserResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnableUserResponse;
            })();

            tenantadmin.ImportUserAttributesRequest = (function() {

                /**
                 * Properties of an ImportUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IImportUserAttributesRequest
                 * @property {string|null} [url] ImportUserAttributesRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportUserAttributesRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportUserAttributesRequest dryRun
                 * @property {boolean|null} [exportError] ImportUserAttributesRequest exportError
                 */

                /**
                 * Constructs a new ImportUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ImportUserAttributesRequest.
                 * @implements IImportUserAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IImportUserAttributesRequest=} [properties] Properties to set
                 */
                function ImportUserAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportUserAttributesRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @instance
                 */
                ImportUserAttributesRequest.prototype.url = "";

                /**
                 * ImportUserAttributesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @instance
                 */
                ImportUserAttributesRequest.prototype.fileFormat = null;

                /**
                 * ImportUserAttributesRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @instance
                 */
                ImportUserAttributesRequest.prototype.dryRun = false;

                /**
                 * ImportUserAttributesRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @instance
                 */
                ImportUserAttributesRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportUserAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportUserAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportUserAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ImportUserAttributesRequest} ImportUserAttributesRequest
                 */
                ImportUserAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ImportUserAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ImportUserAttributesRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserAttributesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportUserAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ImportUserAttributesRequest} message ImportUserAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportUserAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportUserAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportUserAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportUserAttributesRequest;
            })();

            tenantadmin.ImportUserAttributesResponse = (function() {

                /**
                 * Properties of an ImportUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IImportUserAttributesResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportUserAttributesResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportUserAttributesResponse errors
                 * @property {string|null} [assetId] ImportUserAttributesResponse assetId
                 */

                /**
                 * Constructs a new ImportUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ImportUserAttributesResponse.
                 * @implements IImportUserAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IImportUserAttributesResponse=} [properties] Properties to set
                 */
                function ImportUserAttributesResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportUserAttributesResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @instance
                 */
                ImportUserAttributesResponse.prototype.summary = null;

                /**
                 * ImportUserAttributesResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @instance
                 */
                ImportUserAttributesResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportUserAttributesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @instance
                 */
                ImportUserAttributesResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportUserAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportUserAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportUserAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ImportUserAttributesResponse} ImportUserAttributesResponse
                 */
                ImportUserAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ImportUserAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ImportUserAttributesResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserAttributesResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserAttributesResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ImportUserAttributesResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportUserAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ImportUserAttributesResponse} message ImportUserAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportUserAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportUserAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ImportUserAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportUserAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportUserAttributesResponse;
            })();

            tenantadmin.ExportUserAttributesRequest = (function() {

                /**
                 * Properties of an ExportUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportUserAttributesRequest
                 * @property {Array.<string>|null} [ids] ExportUserAttributesRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportUserAttributesRequest fileFormat
                 */

                /**
                 * Constructs a new ExportUserAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportUserAttributesRequest.
                 * @implements IExportUserAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportUserAttributesRequest=} [properties] Properties to set
                 */
                function ExportUserAttributesRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportUserAttributesRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @instance
                 */
                ExportUserAttributesRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportUserAttributesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @instance
                 */
                ExportUserAttributesRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportUserAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportUserAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportUserAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportUserAttributesRequest} ExportUserAttributesRequest
                 */
                ExportUserAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportUserAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportUserAttributesRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportUserAttributesRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportUserAttributesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportUserAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportUserAttributesRequest} message ExportUserAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportUserAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportUserAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportUserAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportUserAttributesRequest;
            })();

            tenantadmin.ExportUserAttributesResponse = (function() {

                /**
                 * Properties of an ExportUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportUserAttributesResponse
                 * @property {string|null} [assetId] ExportUserAttributesResponse assetId
                 */

                /**
                 * Constructs a new ExportUserAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportUserAttributesResponse.
                 * @implements IExportUserAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportUserAttributesResponse=} [properties] Properties to set
                 */
                function ExportUserAttributesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportUserAttributesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesResponse
                 * @instance
                 */
                ExportUserAttributesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportUserAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportUserAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportUserAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportUserAttributesResponse} ExportUserAttributesResponse
                 */
                ExportUserAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportUserAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportUserAttributesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportUserAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportUserAttributesResponse} message ExportUserAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportUserAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportUserAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportUserAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportUserAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportUserAttributesResponse;
            })();

            tenantadmin.ListUserAttributeFormatsRequest = (function() {

                /**
                 * Properties of a ListUserAttributeFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserAttributeFormatsRequest
                 */

                /**
                 * Constructs a new ListUserAttributeFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserAttributeFormatsRequest.
                 * @implements IListUserAttributeFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributeFormatsRequest=} [properties] Properties to set
                 */
                function ListUserAttributeFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserAttributeFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserAttributeFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserAttributeFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest} ListUserAttributeFormatsRequest
                 */
                ListUserAttributeFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListUserAttributeFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest} message ListUserAttributeFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserAttributeFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserAttributeFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserAttributeFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserAttributeFormatsRequest;
            })();

            tenantadmin.ListUserAttributeFormatsResponse = (function() {

                /**
                 * Properties of a ListUserAttributeFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserAttributeFormatsResponse
                 * @property {Long|null} [total] ListUserAttributeFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListUserAttributeFormatsResponse items
                 */

                /**
                 * Constructs a new ListUserAttributeFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserAttributeFormatsResponse.
                 * @implements IListUserAttributeFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserAttributeFormatsResponse=} [properties] Properties to set
                 */
                function ListUserAttributeFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserAttributeFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @instance
                 */
                ListUserAttributeFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserAttributeFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @instance
                 */
                ListUserAttributeFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUserAttributeFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserAttributeFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserAttributeFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse} ListUserAttributeFormatsResponse
                 */
                ListUserAttributeFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserAttributeFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse} message ListUserAttributeFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserAttributeFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserAttributeFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserAttributeFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserAttributeFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserAttributeFormatsResponse;
            })();

            tenantadmin.SendEmailUserCreatedRequest = (function() {

                /**
                 * Properties of a SendEmailUserCreatedRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ISendEmailUserCreatedRequest
                 * @property {mtechnavi.api.idp.IUser|null} [user] SendEmailUserCreatedRequest user
                 */

                /**
                 * Constructs a new SendEmailUserCreatedRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a SendEmailUserCreatedRequest.
                 * @implements ISendEmailUserCreatedRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ISendEmailUserCreatedRequest=} [properties] Properties to set
                 */
                function SendEmailUserCreatedRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendEmailUserCreatedRequest user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest
                 * @instance
                 */
                SendEmailUserCreatedRequest.prototype.user = null;

                /**
                 * Verifies a SendEmailUserCreatedRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailUserCreatedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SendEmailUserCreatedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest} SendEmailUserCreatedRequest
                 */
                SendEmailUserCreatedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SendEmailUserCreatedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest} message SendEmailUserCreatedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailUserCreatedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this SendEmailUserCreatedRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailUserCreatedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailUserCreatedRequest;
            })();

            tenantadmin.SendEmailUserCreatedResponse = (function() {

                /**
                 * Properties of a SendEmailUserCreatedResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ISendEmailUserCreatedResponse
                 */

                /**
                 * Constructs a new SendEmailUserCreatedResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a SendEmailUserCreatedResponse.
                 * @implements ISendEmailUserCreatedResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ISendEmailUserCreatedResponse=} [properties] Properties to set
                 */
                function SendEmailUserCreatedResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a SendEmailUserCreatedResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendEmailUserCreatedResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SendEmailUserCreatedResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse} SendEmailUserCreatedResponse
                 */
                SendEmailUserCreatedResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse)
                        return object;
                    return new $root.mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse();
                };

                /**
                 * Creates a plain object from a SendEmailUserCreatedResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse} message SendEmailUserCreatedResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendEmailUserCreatedResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SendEmailUserCreatedResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.SendEmailUserCreatedResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendEmailUserCreatedResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendEmailUserCreatedResponse;
            })();

            tenantadmin.CreateUserGroupAttributeRequest = (function() {

                /**
                 * Properties of a CreateUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ICreateUserGroupAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] CreateUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new CreateUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a CreateUserGroupAttributeRequest.
                 * @implements ICreateUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function CreateUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest
                 * @instance
                 */
                CreateUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies a CreateUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest} CreateUserGroupAttributeRequest
                 */
                CreateUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest();
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest} message CreateUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroupAttribute = null;
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserGroupAttributeRequest;
            })();

            tenantadmin.CreateUserGroupAndUserGroupAttributeRequest = (function() {

                /**
                 * Properties of a CreateUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ICreateUserGroupAndUserGroupAttributeRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] CreateUserGroupAndUserGroupAttributeRequest userGroup
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] CreateUserGroupAndUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new CreateUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a CreateUserGroupAndUserGroupAttributeRequest.
                 * @implements ICreateUserGroupAndUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ICreateUserGroupAndUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function CreateUserGroupAndUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateUserGroupAndUserGroupAttributeRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                CreateUserGroupAndUserGroupAttributeRequest.prototype.userGroup = null;

                /**
                 * CreateUserGroupAndUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                CreateUserGroupAndUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies a CreateUserGroupAndUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateUserGroupAndUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateUserGroupAndUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest} CreateUserGroupAndUserGroupAttributeRequest
                 */
                CreateUserGroupAndUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateUserGroupAndUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest} message CreateUserGroupAndUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateUserGroupAndUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userGroup = null;
                        object.userGroupAttribute = null;
                    }
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateUserGroupAndUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.CreateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateUserGroupAndUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateUserGroupAndUserGroupAttributeRequest;
            })();

            tenantadmin.ListUserGroupAttributesRequest = (function() {

                /**
                 * Properties of a ListUserGroupAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttributesRequest
                 * @property {string|null} [pageToken] ListUserGroupAttributesRequest pageToken
                 */

                /**
                 * Constructs a new ListUserGroupAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttributesRequest.
                 * @implements IListUserGroupAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributesRequest=} [properties] Properties to set
                 */
                function ListUserGroupAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest
                 * @instance
                 */
                ListUserGroupAttributesRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListUserGroupAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest} ListUserGroupAttributesRequest
                 */
                ListUserGroupAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest} message ListUserGroupAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserGroupAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttributesRequest;
            })();

            tenantadmin.ListUserGroupAttributesResponse = (function() {

                /**
                 * Properties of a ListUserGroupAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttributesResponse
                 * @property {Long|null} [total] ListUserGroupAttributesResponse total
                 * @property {Array.<mtechnavi.api.tenantadmin.IUserGroupAttribute>|null} [items] ListUserGroupAttributesResponse items
                 * @property {string|null} [pageToken] ListUserGroupAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListUserGroupAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttributesResponse.
                 * @implements IListUserGroupAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributesResponse=} [properties] Properties to set
                 */
                function ListUserGroupAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @instance
                 */
                ListUserGroupAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserGroupAttributesResponse items.
                 * @member {Array.<mtechnavi.api.tenantadmin.IUserGroupAttribute>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @instance
                 */
                ListUserGroupAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListUserGroupAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @instance
                 */
                ListUserGroupAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListUserGroupAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse} ListUserGroupAttributesResponse
                 */
                ListUserGroupAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse} message ListUserGroupAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserGroupAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttributesResponse;
            })();

            tenantadmin.GetUserGroupAttributeRequest = (function() {

                /**
                 * Properties of a GetUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IGetUserGroupAttributeRequest
                 * @property {string|null} [userGroupAttributeId] GetUserGroupAttributeRequest userGroupAttributeId
                 */

                /**
                 * Constructs a new GetUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a GetUserGroupAttributeRequest.
                 * @implements IGetUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IGetUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function GetUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetUserGroupAttributeRequest userGroupAttributeId.
                 * @member {string} userGroupAttributeId
                 * @memberof mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest
                 * @instance
                 */
                GetUserGroupAttributeRequest.prototype.userGroupAttributeId = "";

                /**
                 * Verifies a GetUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttributeId != null && message.hasOwnProperty("userGroupAttributeId"))
                        if (!$util.isString(message.userGroupAttributeId))
                            return "userGroupAttributeId: string expected";
                    return null;
                };

                /**
                 * Creates a GetUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest} GetUserGroupAttributeRequest
                 */
                GetUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest();
                    if (object.userGroupAttributeId != null)
                        message.userGroupAttributeId = String(object.userGroupAttributeId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest} message GetUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroupAttributeId = "";
                    if (message.userGroupAttributeId != null && message.hasOwnProperty("userGroupAttributeId"))
                        object.userGroupAttributeId = message.userGroupAttributeId;
                    return object;
                };

                /**
                 * Converts this GetUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.GetUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetUserGroupAttributeRequest;
            })();

            tenantadmin.UpdateUserGroupAttributeRequest = (function() {

                /**
                 * Properties of an UpdateUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUpdateUserGroupAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] UpdateUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new UpdateUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an UpdateUserGroupAttributeRequest.
                 * @implements IUpdateUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function UpdateUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest
                 * @instance
                 */
                UpdateUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies an UpdateUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest} UpdateUserGroupAttributeRequest
                 */
                UpdateUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest();
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest} message UpdateUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroupAttribute = null;
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserGroupAttributeRequest;
            })();

            tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest = (function() {

                /**
                 * Properties of an UpdateUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUpdateUserGroupAndUserGroupAttributeRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] UpdateUserGroupAndUserGroupAttributeRequest userGroup
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] UpdateUserGroupAndUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new UpdateUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an UpdateUserGroupAndUserGroupAttributeRequest.
                 * @implements IUpdateUserGroupAndUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUpdateUserGroupAndUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function UpdateUserGroupAndUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateUserGroupAndUserGroupAttributeRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.prototype.userGroup = null;

                /**
                 * UpdateUserGroupAndUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies an UpdateUserGroupAndUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateUserGroupAndUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest} UpdateUserGroupAndUserGroupAttributeRequest
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateUserGroupAndUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest} message UpdateUserGroupAndUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userGroup = null;
                        object.userGroupAttribute = null;
                    }
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateUserGroupAndUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UpdateUserGroupAndUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateUserGroupAndUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateUserGroupAndUserGroupAttributeRequest;
            })();

            tenantadmin.DeleteUserGroupAttributeRequest = (function() {

                /**
                 * Properties of a DeleteUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDeleteUserGroupAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] DeleteUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new DeleteUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DeleteUserGroupAttributeRequest.
                 * @implements IDeleteUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function DeleteUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest
                 * @instance
                 */
                DeleteUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies a DeleteUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest} DeleteUserGroupAttributeRequest
                 */
                DeleteUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest();
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest} message DeleteUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userGroupAttribute = null;
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserGroupAttributeRequest;
            })();

            tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest = (function() {

                /**
                 * Properties of a DeleteUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDeleteUserGroupAndUserGroupAttributeRequest
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] DeleteUserGroupAndUserGroupAttributeRequest userGroup
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] DeleteUserGroupAndUserGroupAttributeRequest userGroupAttribute
                 */

                /**
                 * Constructs a new DeleteUserGroupAndUserGroupAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DeleteUserGroupAndUserGroupAttributeRequest.
                 * @implements IDeleteUserGroupAndUserGroupAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDeleteUserGroupAndUserGroupAttributeRequest=} [properties] Properties to set
                 */
                function DeleteUserGroupAndUserGroupAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserGroupAndUserGroupAttributeRequest userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.prototype.userGroup = null;

                /**
                 * DeleteUserGroupAndUserGroupAttributeRequest userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @instance
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.prototype.userGroupAttribute = null;

                /**
                 * Verifies a DeleteUserGroupAndUserGroupAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserGroupAndUserGroupAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest} DeleteUserGroupAndUserGroupAttributeRequest
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest();
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserGroupAndUserGroupAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest} message DeleteUserGroupAndUserGroupAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userGroup = null;
                        object.userGroupAttribute = null;
                    }
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserGroupAndUserGroupAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DeleteUserGroupAndUserGroupAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserGroupAndUserGroupAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserGroupAndUserGroupAttributeRequest;
            })();

            tenantadmin.ListUserGroupAttributeContentsRequest = (function() {

                /**
                 * Properties of a ListUserGroupAttributeContentsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttributeContentsRequest
                 * @property {string|null} [pageToken] ListUserGroupAttributeContentsRequest pageToken
                 */

                /**
                 * Constructs a new ListUserGroupAttributeContentsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttributeContentsRequest.
                 * @implements IListUserGroupAttributeContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributeContentsRequest=} [properties] Properties to set
                 */
                function ListUserGroupAttributeContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAttributeContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest
                 * @instance
                 */
                ListUserGroupAttributeContentsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListUserGroupAttributeContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttributeContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttributeContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest} ListUserGroupAttributeContentsRequest
                 */
                ListUserGroupAttributeContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAttributeContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest} message ListUserGroupAttributeContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttributeContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserGroupAttributeContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttributeContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttributeContentsRequest;
            })();

            tenantadmin.ListUserGroupAttributeContentsResponse = (function() {

                /**
                 * Properties of a ListUserGroupAttributeContentsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttributeContentsResponse
                 * @property {Long|null} [total] ListUserGroupAttributeContentsResponse total
                 * @property {Array.<mtechnavi.api.tenantadmin.IUserGroupAttributeContent>|null} [items] ListUserGroupAttributeContentsResponse items
                 * @property {string|null} [pageToken] ListUserGroupAttributeContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListUserGroupAttributeContentsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttributeContentsResponse.
                 * @implements IListUserGroupAttributeContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttributeContentsResponse=} [properties] Properties to set
                 */
                function ListUserGroupAttributeContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAttributeContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @instance
                 */
                ListUserGroupAttributeContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserGroupAttributeContentsResponse items.
                 * @member {Array.<mtechnavi.api.tenantadmin.IUserGroupAttributeContent>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @instance
                 */
                ListUserGroupAttributeContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListUserGroupAttributeContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @instance
                 */
                ListUserGroupAttributeContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListUserGroupAttributeContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttributeContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.tenantadmin.UserGroupAttributeContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttributeContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse} ListUserGroupAttributeContentsResponse
                 */
                ListUserGroupAttributeContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.tenantadmin.UserGroupAttributeContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAttributeContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse} message ListUserGroupAttributeContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttributeContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.tenantadmin.UserGroupAttributeContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserGroupAttributeContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttributeContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttributeContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttributeContentsResponse;
            })();

            tenantadmin.ImportUserBelongsUserGroupsRequest = (function() {

                /**
                 * Properties of an ImportUserBelongsUserGroupsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IImportUserBelongsUserGroupsRequest
                 * @property {string|null} [url] ImportUserBelongsUserGroupsRequest url
                 * @property {sharelib.IFileFormat|null} [fileFormat] ImportUserBelongsUserGroupsRequest fileFormat
                 * @property {boolean|null} [dryRun] ImportUserBelongsUserGroupsRequest dryRun
                 * @property {boolean|null} [exportError] ImportUserBelongsUserGroupsRequest exportError
                 */

                /**
                 * Constructs a new ImportUserBelongsUserGroupsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ImportUserBelongsUserGroupsRequest.
                 * @implements IImportUserBelongsUserGroupsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IImportUserBelongsUserGroupsRequest=} [properties] Properties to set
                 */
                function ImportUserBelongsUserGroupsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportUserBelongsUserGroupsRequest url.
                 * @member {string} url
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ImportUserBelongsUserGroupsRequest.prototype.url = "";

                /**
                 * ImportUserBelongsUserGroupsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ImportUserBelongsUserGroupsRequest.prototype.fileFormat = null;

                /**
                 * ImportUserBelongsUserGroupsRequest dryRun.
                 * @member {boolean} dryRun
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ImportUserBelongsUserGroupsRequest.prototype.dryRun = false;

                /**
                 * ImportUserBelongsUserGroupsRequest exportError.
                 * @member {boolean} exportError
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ImportUserBelongsUserGroupsRequest.prototype.exportError = false;

                /**
                 * Verifies an ImportUserBelongsUserGroupsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportUserBelongsUserGroupsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.url != null && message.hasOwnProperty("url"))
                        if (!$util.isString(message.url))
                            return "url: string expected";
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        if (typeof message.dryRun !== "boolean")
                            return "dryRun: boolean expected";
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        if (typeof message.exportError !== "boolean")
                            return "exportError: boolean expected";
                    return null;
                };

                /**
                 * Creates an ImportUserBelongsUserGroupsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest} ImportUserBelongsUserGroupsRequest
                 */
                ImportUserBelongsUserGroupsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest();
                    if (object.url != null)
                        message.url = String(object.url);
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    if (object.dryRun != null)
                        message.dryRun = Boolean(object.dryRun);
                    if (object.exportError != null)
                        message.exportError = Boolean(object.exportError);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportUserBelongsUserGroupsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest} message ImportUserBelongsUserGroupsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportUserBelongsUserGroupsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.url = "";
                        object.fileFormat = null;
                        object.dryRun = false;
                        object.exportError = false;
                    }
                    if (message.url != null && message.hasOwnProperty("url"))
                        object.url = message.url;
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                        object.dryRun = message.dryRun;
                    if (message.exportError != null && message.hasOwnProperty("exportError"))
                        object.exportError = message.exportError;
                    return object;
                };

                /**
                 * Converts this ImportUserBelongsUserGroupsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportUserBelongsUserGroupsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportUserBelongsUserGroupsRequest;
            })();

            tenantadmin.ImportUserBelongsUserGroupsResponse = (function() {

                /**
                 * Properties of an ImportUserBelongsUserGroupsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IImportUserBelongsUserGroupsResponse
                 * @property {sharelib.IImportSummary|null} [summary] ImportUserBelongsUserGroupsResponse summary
                 * @property {Array.<sharelib.IImportError>|null} [errors] ImportUserBelongsUserGroupsResponse errors
                 * @property {string|null} [assetId] ImportUserBelongsUserGroupsResponse assetId
                 */

                /**
                 * Constructs a new ImportUserBelongsUserGroupsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ImportUserBelongsUserGroupsResponse.
                 * @implements IImportUserBelongsUserGroupsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IImportUserBelongsUserGroupsResponse=} [properties] Properties to set
                 */
                function ImportUserBelongsUserGroupsResponse(properties) {
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ImportUserBelongsUserGroupsResponse summary.
                 * @member {sharelib.IImportSummary|null|undefined} summary
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @instance
                 */
                ImportUserBelongsUserGroupsResponse.prototype.summary = null;

                /**
                 * ImportUserBelongsUserGroupsResponse errors.
                 * @member {Array.<sharelib.IImportError>} errors
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @instance
                 */
                ImportUserBelongsUserGroupsResponse.prototype.errors = $util.emptyArray;

                /**
                 * ImportUserBelongsUserGroupsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @instance
                 */
                ImportUserBelongsUserGroupsResponse.prototype.assetId = "";

                /**
                 * Verifies an ImportUserBelongsUserGroupsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImportUserBelongsUserGroupsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.summary != null && message.hasOwnProperty("summary")) {
                        let error = $root.sharelib.ImportSummary.verify(message.summary);
                        if (error)
                            return "summary." + error;
                    }
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.sharelib.ImportError.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ImportUserBelongsUserGroupsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse} ImportUserBelongsUserGroupsResponse
                 */
                ImportUserBelongsUserGroupsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse();
                    if (object.summary != null) {
                        if (typeof object.summary !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse.summary: object expected");
                        message.summary = $root.sharelib.ImportSummary.fromObject(object.summary);
                    }
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse.errors: object expected");
                            message.errors[i] = $root.sharelib.ImportError.fromObject(object.errors[i]);
                        }
                    }
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ImportUserBelongsUserGroupsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse} message ImportUserBelongsUserGroupsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImportUserBelongsUserGroupsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.errors = [];
                    if (options.defaults) {
                        object.summary = null;
                        object.assetId = "";
                    }
                    if (message.summary != null && message.hasOwnProperty("summary"))
                        object.summary = $root.sharelib.ImportSummary.toObject(message.summary, options);
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.sharelib.ImportError.toObject(message.errors[j], options);
                    }
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ImportUserBelongsUserGroupsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ImportUserBelongsUserGroupsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImportUserBelongsUserGroupsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ImportUserBelongsUserGroupsResponse;
            })();

            tenantadmin.ExportUserBelongsUserGroupsRequest = (function() {

                /**
                 * Properties of an ExportUserBelongsUserGroupsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportUserBelongsUserGroupsRequest
                 * @property {Array.<string>|null} [ids] ExportUserBelongsUserGroupsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportUserBelongsUserGroupsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportUserBelongsUserGroupsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportUserBelongsUserGroupsRequest.
                 * @implements IExportUserBelongsUserGroupsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportUserBelongsUserGroupsRequest=} [properties] Properties to set
                 */
                function ExportUserBelongsUserGroupsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportUserBelongsUserGroupsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ExportUserBelongsUserGroupsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportUserBelongsUserGroupsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @instance
                 */
                ExportUserBelongsUserGroupsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportUserBelongsUserGroupsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportUserBelongsUserGroupsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportUserBelongsUserGroupsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest} ExportUserBelongsUserGroupsRequest
                 */
                ExportUserBelongsUserGroupsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportUserBelongsUserGroupsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest} message ExportUserBelongsUserGroupsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportUserBelongsUserGroupsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportUserBelongsUserGroupsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportUserBelongsUserGroupsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportUserBelongsUserGroupsRequest;
            })();

            tenantadmin.ExportUserBelongsUserGroupsResponse = (function() {

                /**
                 * Properties of an ExportUserBelongsUserGroupsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportUserBelongsUserGroupsResponse
                 * @property {string|null} [assetId] ExportUserBelongsUserGroupsResponse assetId
                 */

                /**
                 * Constructs a new ExportUserBelongsUserGroupsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportUserBelongsUserGroupsResponse.
                 * @implements IExportUserBelongsUserGroupsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportUserBelongsUserGroupsResponse=} [properties] Properties to set
                 */
                function ExportUserBelongsUserGroupsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportUserBelongsUserGroupsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse
                 * @instance
                 */
                ExportUserBelongsUserGroupsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportUserBelongsUserGroupsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportUserBelongsUserGroupsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportUserBelongsUserGroupsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse} ExportUserBelongsUserGroupsResponse
                 */
                ExportUserBelongsUserGroupsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportUserBelongsUserGroupsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse} message ExportUserBelongsUserGroupsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportUserBelongsUserGroupsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportUserBelongsUserGroupsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportUserBelongsUserGroupsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportUserBelongsUserGroupsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportUserBelongsUserGroupsResponse;
            })();

            tenantadmin.ListUserBelongsUserGroupFormatsRequest = (function() {

                /**
                 * Properties of a ListUserBelongsUserGroupFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserBelongsUserGroupFormatsRequest
                 */

                /**
                 * Constructs a new ListUserBelongsUserGroupFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserBelongsUserGroupFormatsRequest.
                 * @implements IListUserBelongsUserGroupFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserBelongsUserGroupFormatsRequest=} [properties] Properties to set
                 */
                function ListUserBelongsUserGroupFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserBelongsUserGroupFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserBelongsUserGroupFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserBelongsUserGroupFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest} ListUserBelongsUserGroupFormatsRequest
                 */
                ListUserBelongsUserGroupFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListUserBelongsUserGroupFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest} message ListUserBelongsUserGroupFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserBelongsUserGroupFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserBelongsUserGroupFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserBelongsUserGroupFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserBelongsUserGroupFormatsRequest;
            })();

            tenantadmin.ListUserBelongsUserGroupFormatsResponse = (function() {

                /**
                 * Properties of a ListUserBelongsUserGroupFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserBelongsUserGroupFormatsResponse
                 * @property {Long|null} [total] ListUserBelongsUserGroupFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListUserBelongsUserGroupFormatsResponse items
                 */

                /**
                 * Constructs a new ListUserBelongsUserGroupFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserBelongsUserGroupFormatsResponse.
                 * @implements IListUserBelongsUserGroupFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserBelongsUserGroupFormatsResponse=} [properties] Properties to set
                 */
                function ListUserBelongsUserGroupFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserBelongsUserGroupFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @instance
                 */
                ListUserBelongsUserGroupFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserBelongsUserGroupFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @instance
                 */
                ListUserBelongsUserGroupFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUserBelongsUserGroupFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserBelongsUserGroupFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserBelongsUserGroupFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse} ListUserBelongsUserGroupFormatsResponse
                 */
                ListUserBelongsUserGroupFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserBelongsUserGroupFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse} message ListUserBelongsUserGroupFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserBelongsUserGroupFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserBelongsUserGroupFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserBelongsUserGroupFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserBelongsUserGroupFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserBelongsUserGroupFormatsResponse;
            })();

            tenantadmin.ExportAttachedRolesRequest = (function() {

                /**
                 * Properties of an ExportAttachedRolesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportAttachedRolesRequest
                 * @property {Array.<string>|null} [ids] ExportAttachedRolesRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportAttachedRolesRequest fileFormat
                 */

                /**
                 * Constructs a new ExportAttachedRolesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportAttachedRolesRequest.
                 * @implements IExportAttachedRolesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportAttachedRolesRequest=} [properties] Properties to set
                 */
                function ExportAttachedRolesRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAttachedRolesRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @instance
                 */
                ExportAttachedRolesRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportAttachedRolesRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @instance
                 */
                ExportAttachedRolesRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportAttachedRolesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAttachedRolesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportAttachedRolesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportAttachedRolesRequest} ExportAttachedRolesRequest
                 */
                ExportAttachedRolesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportAttachedRolesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportAttachedRolesRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportAttachedRolesRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportAttachedRolesRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAttachedRolesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportAttachedRolesRequest} message ExportAttachedRolesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAttachedRolesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportAttachedRolesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAttachedRolesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAttachedRolesRequest;
            })();

            tenantadmin.ExportAttachedRolesResponse = (function() {

                /**
                 * Properties of an ExportAttachedRolesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportAttachedRolesResponse
                 * @property {string|null} [assetId] ExportAttachedRolesResponse assetId
                 */

                /**
                 * Constructs a new ExportAttachedRolesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportAttachedRolesResponse.
                 * @implements IExportAttachedRolesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportAttachedRolesResponse=} [properties] Properties to set
                 */
                function ExportAttachedRolesResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAttachedRolesResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesResponse
                 * @instance
                 */
                ExportAttachedRolesResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportAttachedRolesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAttachedRolesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportAttachedRolesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportAttachedRolesResponse} ExportAttachedRolesResponse
                 */
                ExportAttachedRolesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportAttachedRolesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportAttachedRolesResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAttachedRolesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportAttachedRolesResponse} message ExportAttachedRolesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAttachedRolesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportAttachedRolesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportAttachedRolesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAttachedRolesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAttachedRolesResponse;
            })();

            tenantadmin.ListUserGroupAttachedRolesFormatsRequest = (function() {

                /**
                 * Properties of a ListUserGroupAttachedRolesFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttachedRolesFormatsRequest
                 */

                /**
                 * Constructs a new ListUserGroupAttachedRolesFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttachedRolesFormatsRequest.
                 * @implements IListUserGroupAttachedRolesFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttachedRolesFormatsRequest=} [properties] Properties to set
                 */
                function ListUserGroupAttachedRolesFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserGroupAttachedRolesFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttachedRolesFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttachedRolesFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest} ListUserGroupAttachedRolesFormatsRequest
                 */
                ListUserGroupAttachedRolesFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListUserGroupAttachedRolesFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest} message ListUserGroupAttachedRolesFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttachedRolesFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserGroupAttachedRolesFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttachedRolesFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttachedRolesFormatsRequest;
            })();

            tenantadmin.ListUserGroupAttachedRolesFormatsResponse = (function() {

                /**
                 * Properties of a ListUserGroupAttachedRolesFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAttachedRolesFormatsResponse
                 * @property {Long|null} [total] ListUserGroupAttachedRolesFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListUserGroupAttachedRolesFormatsResponse items
                 */

                /**
                 * Constructs a new ListUserGroupAttachedRolesFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAttachedRolesFormatsResponse.
                 * @implements IListUserGroupAttachedRolesFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAttachedRolesFormatsResponse=} [properties] Properties to set
                 */
                function ListUserGroupAttachedRolesFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAttachedRolesFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @instance
                 */
                ListUserGroupAttachedRolesFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserGroupAttachedRolesFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @instance
                 */
                ListUserGroupAttachedRolesFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUserGroupAttachedRolesFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAttachedRolesFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserGroupAttachedRolesFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse} ListUserGroupAttachedRolesFormatsResponse
                 */
                ListUserGroupAttachedRolesFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAttachedRolesFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse} message ListUserGroupAttachedRolesFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAttachedRolesFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserGroupAttachedRolesFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAttachedRolesFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAttachedRolesFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAttachedRolesFormatsResponse;
            })();

            tenantadmin.ExportAllowedMenuItemsRequest = (function() {

                /**
                 * Properties of an ExportAllowedMenuItemsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportAllowedMenuItemsRequest
                 * @property {Array.<string>|null} [ids] ExportAllowedMenuItemsRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportAllowedMenuItemsRequest fileFormat
                 */

                /**
                 * Constructs a new ExportAllowedMenuItemsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportAllowedMenuItemsRequest.
                 * @implements IExportAllowedMenuItemsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportAllowedMenuItemsRequest=} [properties] Properties to set
                 */
                function ExportAllowedMenuItemsRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAllowedMenuItemsRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @instance
                 */
                ExportAllowedMenuItemsRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportAllowedMenuItemsRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @instance
                 */
                ExportAllowedMenuItemsRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportAllowedMenuItemsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAllowedMenuItemsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportAllowedMenuItemsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest} ExportAllowedMenuItemsRequest
                 */
                ExportAllowedMenuItemsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAllowedMenuItemsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest} message ExportAllowedMenuItemsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAllowedMenuItemsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportAllowedMenuItemsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAllowedMenuItemsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAllowedMenuItemsRequest;
            })();

            tenantadmin.ExportAllowedMenuItemsResponse = (function() {

                /**
                 * Properties of an ExportAllowedMenuItemsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IExportAllowedMenuItemsResponse
                 * @property {string|null} [assetId] ExportAllowedMenuItemsResponse assetId
                 */

                /**
                 * Constructs a new ExportAllowedMenuItemsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an ExportAllowedMenuItemsResponse.
                 * @implements IExportAllowedMenuItemsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IExportAllowedMenuItemsResponse=} [properties] Properties to set
                 */
                function ExportAllowedMenuItemsResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportAllowedMenuItemsResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse
                 * @instance
                 */
                ExportAllowedMenuItemsResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportAllowedMenuItemsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportAllowedMenuItemsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportAllowedMenuItemsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse} ExportAllowedMenuItemsResponse
                 */
                ExportAllowedMenuItemsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportAllowedMenuItemsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse} message ExportAllowedMenuItemsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportAllowedMenuItemsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportAllowedMenuItemsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ExportAllowedMenuItemsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportAllowedMenuItemsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportAllowedMenuItemsResponse;
            })();

            tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest = (function() {

                /**
                 * Properties of a ListUserGroupAllowedMenuItemFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAllowedMenuItemFormatsRequest
                 */

                /**
                 * Constructs a new ListUserGroupAllowedMenuItemFormatsRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAllowedMenuItemFormatsRequest.
                 * @implements IListUserGroupAllowedMenuItemFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAllowedMenuItemFormatsRequest=} [properties] Properties to set
                 */
                function ListUserGroupAllowedMenuItemFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListUserGroupAllowedMenuItemFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAllowedMenuItemFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListUserGroupAllowedMenuItemFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest} ListUserGroupAllowedMenuItemFormatsRequest
                 */
                ListUserGroupAllowedMenuItemFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest)
                        return object;
                    return new $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest();
                };

                /**
                 * Creates a plain object from a ListUserGroupAllowedMenuItemFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest} message ListUserGroupAllowedMenuItemFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAllowedMenuItemFormatsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListUserGroupAllowedMenuItemFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAllowedMenuItemFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAllowedMenuItemFormatsRequest;
            })();

            tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse = (function() {

                /**
                 * Properties of a ListUserGroupAllowedMenuItemFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListUserGroupAllowedMenuItemFormatsResponse
                 * @property {Long|null} [total] ListUserGroupAllowedMenuItemFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListUserGroupAllowedMenuItemFormatsResponse items
                 */

                /**
                 * Constructs a new ListUserGroupAllowedMenuItemFormatsResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListUserGroupAllowedMenuItemFormatsResponse.
                 * @implements IListUserGroupAllowedMenuItemFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListUserGroupAllowedMenuItemFormatsResponse=} [properties] Properties to set
                 */
                function ListUserGroupAllowedMenuItemFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserGroupAllowedMenuItemFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @instance
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserGroupAllowedMenuItemFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @instance
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListUserGroupAllowedMenuItemFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListUserGroupAllowedMenuItemFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse} ListUserGroupAllowedMenuItemFormatsResponse
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserGroupAllowedMenuItemFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse} message ListUserGroupAllowedMenuItemFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListUserGroupAllowedMenuItemFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListUserGroupAllowedMenuItemFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserGroupAllowedMenuItemFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserGroupAllowedMenuItemFormatsResponse;
            })();

            tenantadmin.CreateRoleAttributeRequest = (function() {

                /**
                 * Properties of a CreateRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface ICreateRoleAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IRoleAttribute|null} [roleAttribute] CreateRoleAttributeRequest roleAttribute
                 */

                /**
                 * Constructs a new CreateRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a CreateRoleAttributeRequest.
                 * @implements ICreateRoleAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.ICreateRoleAttributeRequest=} [properties] Properties to set
                 */
                function CreateRoleAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateRoleAttributeRequest roleAttribute.
                 * @member {mtechnavi.api.tenantadmin.IRoleAttribute|null|undefined} roleAttribute
                 * @memberof mtechnavi.api.tenantadmin.CreateRoleAttributeRequest
                 * @instance
                 */
                CreateRoleAttributeRequest.prototype.roleAttribute = null;

                /**
                 * Verifies a CreateRoleAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.CreateRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateRoleAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.RoleAttribute.verify(message.roleAttribute);
                        if (error)
                            return "roleAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateRoleAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.CreateRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.CreateRoleAttributeRequest} CreateRoleAttributeRequest
                 */
                CreateRoleAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.CreateRoleAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.CreateRoleAttributeRequest();
                    if (object.roleAttribute != null) {
                        if (typeof object.roleAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.CreateRoleAttributeRequest.roleAttribute: object expected");
                        message.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.fromObject(object.roleAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateRoleAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.CreateRoleAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.CreateRoleAttributeRequest} message CreateRoleAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateRoleAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.roleAttribute = null;
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute"))
                        object.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.toObject(message.roleAttribute, options);
                    return object;
                };

                /**
                 * Converts this CreateRoleAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.CreateRoleAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateRoleAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateRoleAttributeRequest;
            })();

            tenantadmin.ListRoleAttributesRequest = (function() {

                /**
                 * Properties of a ListRoleAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListRoleAttributesRequest
                 * @property {string|null} [pageToken] ListRoleAttributesRequest pageToken
                 */

                /**
                 * Constructs a new ListRoleAttributesRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListRoleAttributesRequest.
                 * @implements IListRoleAttributesRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListRoleAttributesRequest=} [properties] Properties to set
                 */
                function ListRoleAttributesRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListRoleAttributesRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesRequest
                 * @instance
                 */
                ListRoleAttributesRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListRoleAttributesRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListRoleAttributesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListRoleAttributesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListRoleAttributesRequest} ListRoleAttributesRequest
                 */
                ListRoleAttributesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListRoleAttributesRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListRoleAttributesRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListRoleAttributesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListRoleAttributesRequest} message ListRoleAttributesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListRoleAttributesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListRoleAttributesRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListRoleAttributesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListRoleAttributesRequest;
            })();

            tenantadmin.ListRoleAttributesResponse = (function() {

                /**
                 * Properties of a ListRoleAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IListRoleAttributesResponse
                 * @property {Long|null} [total] ListRoleAttributesResponse total
                 * @property {Array.<mtechnavi.api.tenantadmin.IRoleAttribute>|null} [items] ListRoleAttributesResponse items
                 * @property {string|null} [pageToken] ListRoleAttributesResponse pageToken
                 */

                /**
                 * Constructs a new ListRoleAttributesResponse.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a ListRoleAttributesResponse.
                 * @implements IListRoleAttributesResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IListRoleAttributesResponse=} [properties] Properties to set
                 */
                function ListRoleAttributesResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListRoleAttributesResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @instance
                 */
                ListRoleAttributesResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListRoleAttributesResponse items.
                 * @member {Array.<mtechnavi.api.tenantadmin.IRoleAttribute>} items
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @instance
                 */
                ListRoleAttributesResponse.prototype.items = $util.emptyArray;

                /**
                 * ListRoleAttributesResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @instance
                 */
                ListRoleAttributesResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListRoleAttributesResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListRoleAttributesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.tenantadmin.RoleAttribute.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListRoleAttributesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.ListRoleAttributesResponse} ListRoleAttributesResponse
                 */
                ListRoleAttributesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.ListRoleAttributesResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.ListRoleAttributesResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.tenantadmin.ListRoleAttributesResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.ListRoleAttributesResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.tenantadmin.RoleAttribute.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListRoleAttributesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @static
                 * @param {mtechnavi.api.tenantadmin.ListRoleAttributesResponse} message ListRoleAttributesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListRoleAttributesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.tenantadmin.RoleAttribute.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListRoleAttributesResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.ListRoleAttributesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListRoleAttributesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListRoleAttributesResponse;
            })();

            tenantadmin.GetRoleAttributeRequest = (function() {

                /**
                 * Properties of a GetRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IGetRoleAttributeRequest
                 * @property {string|null} [roleAttributeId] GetRoleAttributeRequest roleAttributeId
                 */

                /**
                 * Constructs a new GetRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a GetRoleAttributeRequest.
                 * @implements IGetRoleAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IGetRoleAttributeRequest=} [properties] Properties to set
                 */
                function GetRoleAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetRoleAttributeRequest roleAttributeId.
                 * @member {string} roleAttributeId
                 * @memberof mtechnavi.api.tenantadmin.GetRoleAttributeRequest
                 * @instance
                 */
                GetRoleAttributeRequest.prototype.roleAttributeId = "";

                /**
                 * Verifies a GetRoleAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.GetRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetRoleAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleAttributeId != null && message.hasOwnProperty("roleAttributeId"))
                        if (!$util.isString(message.roleAttributeId))
                            return "roleAttributeId: string expected";
                    return null;
                };

                /**
                 * Creates a GetRoleAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.GetRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.GetRoleAttributeRequest} GetRoleAttributeRequest
                 */
                GetRoleAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.GetRoleAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.GetRoleAttributeRequest();
                    if (object.roleAttributeId != null)
                        message.roleAttributeId = String(object.roleAttributeId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetRoleAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.GetRoleAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.GetRoleAttributeRequest} message GetRoleAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetRoleAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.roleAttributeId = "";
                    if (message.roleAttributeId != null && message.hasOwnProperty("roleAttributeId"))
                        object.roleAttributeId = message.roleAttributeId;
                    return object;
                };

                /**
                 * Converts this GetRoleAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.GetRoleAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetRoleAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetRoleAttributeRequest;
            })();

            tenantadmin.UpdateRoleAttributeRequest = (function() {

                /**
                 * Properties of an UpdateRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUpdateRoleAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IRoleAttribute|null} [roleAttribute] UpdateRoleAttributeRequest roleAttribute
                 */

                /**
                 * Constructs a new UpdateRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents an UpdateRoleAttributeRequest.
                 * @implements IUpdateRoleAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUpdateRoleAttributeRequest=} [properties] Properties to set
                 */
                function UpdateRoleAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateRoleAttributeRequest roleAttribute.
                 * @member {mtechnavi.api.tenantadmin.IRoleAttribute|null|undefined} roleAttribute
                 * @memberof mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest
                 * @instance
                 */
                UpdateRoleAttributeRequest.prototype.roleAttribute = null;

                /**
                 * Verifies an UpdateRoleAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateRoleAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.RoleAttribute.verify(message.roleAttribute);
                        if (error)
                            return "roleAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateRoleAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest} UpdateRoleAttributeRequest
                 */
                UpdateRoleAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest();
                    if (object.roleAttribute != null) {
                        if (typeof object.roleAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest.roleAttribute: object expected");
                        message.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.fromObject(object.roleAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateRoleAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest} message UpdateRoleAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateRoleAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.roleAttribute = null;
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute"))
                        object.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.toObject(message.roleAttribute, options);
                    return object;
                };

                /**
                 * Converts this UpdateRoleAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UpdateRoleAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateRoleAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateRoleAttributeRequest;
            })();

            tenantadmin.DeleteRoleAttributeRequest = (function() {

                /**
                 * Properties of a DeleteRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IDeleteRoleAttributeRequest
                 * @property {mtechnavi.api.tenantadmin.IRoleAttribute|null} [roleAttribute] DeleteRoleAttributeRequest roleAttribute
                 */

                /**
                 * Constructs a new DeleteRoleAttributeRequest.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a DeleteRoleAttributeRequest.
                 * @implements IDeleteRoleAttributeRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IDeleteRoleAttributeRequest=} [properties] Properties to set
                 */
                function DeleteRoleAttributeRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteRoleAttributeRequest roleAttribute.
                 * @member {mtechnavi.api.tenantadmin.IRoleAttribute|null|undefined} roleAttribute
                 * @memberof mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest
                 * @instance
                 */
                DeleteRoleAttributeRequest.prototype.roleAttribute = null;

                /**
                 * Verifies a DeleteRoleAttributeRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteRoleAttributeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.RoleAttribute.verify(message.roleAttribute);
                        if (error)
                            return "roleAttribute." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteRoleAttributeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest} DeleteRoleAttributeRequest
                 */
                DeleteRoleAttributeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest();
                    if (object.roleAttribute != null) {
                        if (typeof object.roleAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest.roleAttribute: object expected");
                        message.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.fromObject(object.roleAttribute);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteRoleAttributeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest
                 * @static
                 * @param {mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest} message DeleteRoleAttributeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteRoleAttributeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.roleAttribute = null;
                    if (message.roleAttribute != null && message.hasOwnProperty("roleAttribute"))
                        object.roleAttribute = $root.mtechnavi.api.tenantadmin.RoleAttribute.toObject(message.roleAttribute, options);
                    return object;
                };

                /**
                 * Converts this DeleteRoleAttributeRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.DeleteRoleAttributeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteRoleAttributeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteRoleAttributeRequest;
            })();

            tenantadmin.UserGroupAttributeContent = (function() {

                /**
                 * Properties of a UserGroupAttributeContent.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUserGroupAttributeContent
                 * @property {mtechnavi.api.tenantadmin.IUserGroupAttribute|null} [userGroupAttribute] UserGroupAttributeContent userGroupAttribute
                 * @property {boolean|null} [license] UserGroupAttributeContent license
                 */

                /**
                 * Constructs a new UserGroupAttributeContent.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a UserGroupAttributeContent.
                 * @implements IUserGroupAttributeContent
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUserGroupAttributeContent=} [properties] Properties to set
                 */
                function UserGroupAttributeContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserGroupAttributeContent userGroupAttribute.
                 * @member {mtechnavi.api.tenantadmin.IUserGroupAttribute|null|undefined} userGroupAttribute
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @instance
                 */
                UserGroupAttributeContent.prototype.userGroupAttribute = null;

                /**
                 * UserGroupAttributeContent license.
                 * @member {boolean} license
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @instance
                 */
                UserGroupAttributeContent.prototype.license = false;

                /**
                 * Verifies a UserGroupAttributeContent message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserGroupAttributeContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.verify(message.userGroupAttribute);
                        if (error)
                            return "userGroupAttribute." + error;
                    }
                    if (message.license != null && message.hasOwnProperty("license"))
                        if (typeof message.license !== "boolean")
                            return "license: boolean expected";
                    return null;
                };

                /**
                 * Creates a UserGroupAttributeContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UserGroupAttributeContent} UserGroupAttributeContent
                 */
                UserGroupAttributeContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UserGroupAttributeContent)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UserGroupAttributeContent();
                    if (object.userGroupAttribute != null) {
                        if (typeof object.userGroupAttribute !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserGroupAttributeContent.userGroupAttribute: object expected");
                        message.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.fromObject(object.userGroupAttribute);
                    }
                    if (object.license != null)
                        message.license = Boolean(object.license);
                    return message;
                };

                /**
                 * Creates a plain object from a UserGroupAttributeContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttributeContent} message UserGroupAttributeContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserGroupAttributeContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userGroupAttribute = null;
                        object.license = false;
                    }
                    if (message.userGroupAttribute != null && message.hasOwnProperty("userGroupAttribute"))
                        object.userGroupAttribute = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.toObject(message.userGroupAttribute, options);
                    if (message.license != null && message.hasOwnProperty("license"))
                        object.license = message.license;
                    return object;
                };

                /**
                 * Converts this UserGroupAttributeContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttributeContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserGroupAttributeContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserGroupAttributeContent;
            })();

            tenantadmin.UserAttribute = (function() {

                /**
                 * Properties of a UserAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUserAttribute
                 * @property {string|null} [userAttributeId] UserAttribute userAttributeId
                 * @property {mtechnavi.api.idp.IUser|null} [user] UserAttribute user
                 * @property {string|null} [staffCode] UserAttribute staffCode
                 * @property {boolean|null} [approver] UserAttribute approver
                 * @property {string|null} [remarks] UserAttribute remarks
                 * @property {sharelib.IOrganizationStructureReference|null} [representativeOrganization] UserAttribute representativeOrganization
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalOrganization] UserAttribute approvalOrganization
                 * @property {mtechnavi.api.tenantadmin.UserAttribute.IUserStatus|null} [status] UserAttribute status
                 * @property {Long|null} [userAttributeUpdatedAt] UserAttribute userAttributeUpdatedAt
                 * @property {sharelib.IEmbeddedUser|null} [userAttributeUpdatedBy] UserAttribute userAttributeUpdatedBy
                 * @property {Long|null} [deletedAt] UserAttribute deletedAt
                 * @property {Long|null} [createdAt] UserAttribute createdAt
                 * @property {Long|null} [updatedAt] UserAttribute updatedAt
                 */

                /**
                 * Constructs a new UserAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a UserAttribute.
                 * @implements IUserAttribute
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUserAttribute=} [properties] Properties to set
                 */
                function UserAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserAttribute userAttributeId.
                 * @member {string} userAttributeId
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.userAttributeId = "";

                /**
                 * UserAttribute user.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} user
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.user = null;

                /**
                 * UserAttribute staffCode.
                 * @member {string} staffCode
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.staffCode = "";

                /**
                 * UserAttribute approver.
                 * @member {boolean} approver
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.approver = false;

                /**
                 * UserAttribute remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.remarks = "";

                /**
                 * UserAttribute representativeOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} representativeOrganization
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.representativeOrganization = null;

                /**
                 * UserAttribute approvalOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalOrganization
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.approvalOrganization = null;

                /**
                 * UserAttribute status.
                 * @member {mtechnavi.api.tenantadmin.UserAttribute.IUserStatus|null|undefined} status
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.status = null;

                /**
                 * UserAttribute userAttributeUpdatedAt.
                 * @member {Long} userAttributeUpdatedAt
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.userAttributeUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserAttribute userAttributeUpdatedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} userAttributeUpdatedBy
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.userAttributeUpdatedBy = null;

                /**
                 * UserAttribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserAttribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserAttribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 */
                UserAttribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a UserAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAttributeId != null && message.hasOwnProperty("userAttributeId"))
                        if (!$util.isString(message.userAttributeId))
                            return "userAttributeId: string expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                        if (!$util.isString(message.staffCode))
                            return "staffCode: string expected";
                    if (message.approver != null && message.hasOwnProperty("approver"))
                        if (typeof message.approver !== "boolean")
                            return "approver: boolean expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.representativeOrganization != null && message.hasOwnProperty("representativeOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.representativeOrganization);
                        if (error)
                            return "representativeOrganization." + error;
                    }
                    if (message.approvalOrganization != null && message.hasOwnProperty("approvalOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalOrganization);
                        if (error)
                            return "approvalOrganization." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserAttribute.UserStatus.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.userAttributeUpdatedAt != null && message.hasOwnProperty("userAttributeUpdatedAt"))
                        if (!$util.isInteger(message.userAttributeUpdatedAt) && !(message.userAttributeUpdatedAt && $util.isInteger(message.userAttributeUpdatedAt.low) && $util.isInteger(message.userAttributeUpdatedAt.high)))
                            return "userAttributeUpdatedAt: integer|Long expected";
                    if (message.userAttributeUpdatedBy != null && message.hasOwnProperty("userAttributeUpdatedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.userAttributeUpdatedBy);
                        if (error)
                            return "userAttributeUpdatedBy." + error;
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a UserAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UserAttribute} UserAttribute
                 */
                UserAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UserAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UserAttribute();
                    if (object.userAttributeId != null)
                        message.userAttributeId = String(object.userAttributeId);
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.user: object expected");
                        message.user = $root.mtechnavi.api.idp.User.fromObject(object.user);
                    }
                    if (object.staffCode != null)
                        message.staffCode = String(object.staffCode);
                    if (object.approver != null)
                        message.approver = Boolean(object.approver);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.representativeOrganization != null) {
                        if (typeof object.representativeOrganization !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.representativeOrganization: object expected");
                        message.representativeOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.representativeOrganization);
                    }
                    if (object.approvalOrganization != null) {
                        if (typeof object.approvalOrganization !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.approvalOrganization: object expected");
                        message.approvalOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalOrganization);
                    }
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.status: object expected");
                        message.status = $root.mtechnavi.api.tenantadmin.UserAttribute.UserStatus.fromObject(object.status);
                    }
                    if (object.userAttributeUpdatedAt != null)
                        if ($util.Long)
                            (message.userAttributeUpdatedAt = $util.Long.fromValue(object.userAttributeUpdatedAt)).unsigned = false;
                        else if (typeof object.userAttributeUpdatedAt === "string")
                            message.userAttributeUpdatedAt = parseInt(object.userAttributeUpdatedAt, 10);
                        else if (typeof object.userAttributeUpdatedAt === "number")
                            message.userAttributeUpdatedAt = object.userAttributeUpdatedAt;
                        else if (typeof object.userAttributeUpdatedAt === "object")
                            message.userAttributeUpdatedAt = new $util.LongBits(object.userAttributeUpdatedAt.low >>> 0, object.userAttributeUpdatedAt.high >>> 0).toNumber();
                    if (object.userAttributeUpdatedBy != null) {
                        if (typeof object.userAttributeUpdatedBy !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.userAttributeUpdatedBy: object expected");
                        message.userAttributeUpdatedBy = $root.sharelib.EmbeddedUser.fromObject(object.userAttributeUpdatedBy);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a UserAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UserAttribute} message UserAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userAttributeId = "";
                        object.user = null;
                        object.remarks = "";
                        object.status = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.userAttributeUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.userAttributeUpdatedAt = options.longs === String ? "0" : 0;
                        object.userAttributeUpdatedBy = null;
                        object.staffCode = "";
                        object.approver = false;
                        object.representativeOrganization = null;
                        object.approvalOrganization = null;
                    }
                    if (message.userAttributeId != null && message.hasOwnProperty("userAttributeId"))
                        object.userAttributeId = message.userAttributeId;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.mtechnavi.api.idp.User.toObject(message.user, options);
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.mtechnavi.api.tenantadmin.UserAttribute.UserStatus.toObject(message.status, options);
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.userAttributeUpdatedAt != null && message.hasOwnProperty("userAttributeUpdatedAt"))
                        if (typeof message.userAttributeUpdatedAt === "number")
                            object.userAttributeUpdatedAt = options.longs === String ? String(message.userAttributeUpdatedAt) : message.userAttributeUpdatedAt;
                        else
                            object.userAttributeUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.userAttributeUpdatedAt) : options.longs === Number ? new $util.LongBits(message.userAttributeUpdatedAt.low >>> 0, message.userAttributeUpdatedAt.high >>> 0).toNumber() : message.userAttributeUpdatedAt;
                    if (message.userAttributeUpdatedBy != null && message.hasOwnProperty("userAttributeUpdatedBy"))
                        object.userAttributeUpdatedBy = $root.sharelib.EmbeddedUser.toObject(message.userAttributeUpdatedBy, options);
                    if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                        object.staffCode = message.staffCode;
                    if (message.approver != null && message.hasOwnProperty("approver"))
                        object.approver = message.approver;
                    if (message.representativeOrganization != null && message.hasOwnProperty("representativeOrganization"))
                        object.representativeOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.representativeOrganization, options);
                    if (message.approvalOrganization != null && message.hasOwnProperty("approvalOrganization"))
                        object.approvalOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalOrganization, options);
                    return object;
                };

                /**
                 * Converts this UserAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UserAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UserAttribute.UserStatus = (function() {

                    /**
                     * Properties of a UserStatus.
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute
                     * @interface IUserStatus
                     * @property {sharelib.INameOption|null} [status] UserStatus status
                     * @property {sharelib.INameOption|null} [category] UserStatus category
                     * @property {sharelib.INameOption|null} [detail] UserStatus detail
                     */

                    /**
                     * Constructs a new UserStatus.
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute
                     * @classdesc Represents a UserStatus.
                     * @implements IUserStatus
                     * @constructor
                     * @param {mtechnavi.api.tenantadmin.UserAttribute.IUserStatus=} [properties] Properties to set
                     */
                    function UserStatus(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserStatus status.
                     * @member {sharelib.INameOption|null|undefined} status
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @instance
                     */
                    UserStatus.prototype.status = null;

                    /**
                     * UserStatus category.
                     * @member {sharelib.INameOption|null|undefined} category
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @instance
                     */
                    UserStatus.prototype.category = null;

                    /**
                     * UserStatus detail.
                     * @member {sharelib.INameOption|null|undefined} detail
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @instance
                     */
                    UserStatus.prototype.detail = null;

                    /**
                     * Verifies a UserStatus message.
                     * @function verify
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.status != null && message.hasOwnProperty("status")) {
                            let error = $root.sharelib.NameOption.verify(message.status);
                            if (error)
                                return "status." + error;
                        }
                        if (message.category != null && message.hasOwnProperty("category")) {
                            let error = $root.sharelib.NameOption.verify(message.category);
                            if (error)
                                return "category." + error;
                        }
                        if (message.detail != null && message.hasOwnProperty("detail")) {
                            let error = $root.sharelib.NameOption.verify(message.detail);
                            if (error)
                                return "detail." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a UserStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.tenantadmin.UserAttribute.UserStatus} UserStatus
                     */
                    UserStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.tenantadmin.UserAttribute.UserStatus)
                            return object;
                        let message = new $root.mtechnavi.api.tenantadmin.UserAttribute.UserStatus();
                        if (object.status != null) {
                            if (typeof object.status !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.UserStatus.status: object expected");
                            message.status = $root.sharelib.NameOption.fromObject(object.status);
                        }
                        if (object.category != null) {
                            if (typeof object.category !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.UserStatus.category: object expected");
                            message.category = $root.sharelib.NameOption.fromObject(object.category);
                        }
                        if (object.detail != null) {
                            if (typeof object.detail !== "object")
                                throw TypeError(".mtechnavi.api.tenantadmin.UserAttribute.UserStatus.detail: object expected");
                            message.detail = $root.sharelib.NameOption.fromObject(object.detail);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @static
                     * @param {mtechnavi.api.tenantadmin.UserAttribute.UserStatus} message UserStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.status = null;
                            object.category = null;
                            object.detail = null;
                        }
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = $root.sharelib.NameOption.toObject(message.status, options);
                        if (message.category != null && message.hasOwnProperty("category"))
                            object.category = $root.sharelib.NameOption.toObject(message.category, options);
                        if (message.detail != null && message.hasOwnProperty("detail"))
                            object.detail = $root.sharelib.NameOption.toObject(message.detail, options);
                        return object;
                    };

                    /**
                     * Converts this UserStatus to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.tenantadmin.UserAttribute.UserStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserStatus;
                })();

                return UserAttribute;
            })();

            tenantadmin.UserGroupAttribute = (function() {

                /**
                 * Properties of a UserGroupAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IUserGroupAttribute
                 * @property {string|null} [userGroupAttributeId] UserGroupAttribute userGroupAttributeId
                 * @property {mtechnavi.api.idp.IUserGroup|null} [userGroup] UserGroupAttribute userGroup
                 * @property {string|null} [remarks] UserGroupAttribute remarks
                 * @property {boolean|null} [systemGroup] UserGroupAttribute systemGroup
                 * @property {mtechnavi.api.tenantadmin.UserGroupAttribute.IUserSummary|null} [userSummary] UserGroupAttribute userSummary
                 * @property {Array.<string>|null} [viewIds] UserGroupAttribute viewIds
                 * @property {Array.<string>|null} [presetIds] UserGroupAttribute presetIds
                 * @property {Long|null} [deletedAt] UserGroupAttribute deletedAt
                 * @property {Long|null} [createdAt] UserGroupAttribute createdAt
                 * @property {Long|null} [updatedAt] UserGroupAttribute updatedAt
                 */

                /**
                 * Constructs a new UserGroupAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a UserGroupAttribute.
                 * @implements IUserGroupAttribute
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IUserGroupAttribute=} [properties] Properties to set
                 */
                function UserGroupAttribute(properties) {
                    this.viewIds = [];
                    this.presetIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserGroupAttribute userGroupAttributeId.
                 * @member {string} userGroupAttributeId
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.userGroupAttributeId = "";

                /**
                 * UserGroupAttribute userGroup.
                 * @member {mtechnavi.api.idp.IUserGroup|null|undefined} userGroup
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.userGroup = null;

                /**
                 * UserGroupAttribute remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.remarks = "";

                /**
                 * UserGroupAttribute systemGroup.
                 * @member {boolean} systemGroup
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.systemGroup = false;

                /**
                 * UserGroupAttribute userSummary.
                 * @member {mtechnavi.api.tenantadmin.UserGroupAttribute.IUserSummary|null|undefined} userSummary
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.userSummary = null;

                /**
                 * UserGroupAttribute viewIds.
                 * @member {Array.<string>} viewIds
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.viewIds = $util.emptyArray;

                /**
                 * UserGroupAttribute presetIds.
                 * @member {Array.<string>} presetIds
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.presetIds = $util.emptyArray;

                /**
                 * UserGroupAttribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserGroupAttribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserGroupAttribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 */
                UserGroupAttribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a UserGroupAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserGroupAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userGroupAttributeId != null && message.hasOwnProperty("userGroupAttributeId"))
                        if (!$util.isString(message.userGroupAttributeId))
                            return "userGroupAttributeId: string expected";
                    if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                        let error = $root.mtechnavi.api.idp.UserGroup.verify(message.userGroup);
                        if (error)
                            return "userGroup." + error;
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.systemGroup != null && message.hasOwnProperty("systemGroup"))
                        if (typeof message.systemGroup !== "boolean")
                            return "systemGroup: boolean expected";
                    if (message.userSummary != null && message.hasOwnProperty("userSummary")) {
                        let error = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary.verify(message.userSummary);
                        if (error)
                            return "userSummary." + error;
                    }
                    if (message.viewIds != null && message.hasOwnProperty("viewIds")) {
                        if (!Array.isArray(message.viewIds))
                            return "viewIds: array expected";
                        for (let i = 0; i < message.viewIds.length; ++i)
                            if (!$util.isString(message.viewIds[i]))
                                return "viewIds: string[] expected";
                    }
                    if (message.presetIds != null && message.hasOwnProperty("presetIds")) {
                        if (!Array.isArray(message.presetIds))
                            return "presetIds: array expected";
                        for (let i = 0; i < message.presetIds.length; ++i)
                            if (!$util.isString(message.presetIds[i]))
                                return "presetIds: string[] expected";
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a UserGroupAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.UserGroupAttribute} UserGroupAttribute
                 */
                UserGroupAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.UserGroupAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.UserGroupAttribute();
                    if (object.userGroupAttributeId != null)
                        message.userGroupAttributeId = String(object.userGroupAttributeId);
                    if (object.userGroup != null) {
                        if (typeof object.userGroup !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserGroupAttribute.userGroup: object expected");
                        message.userGroup = $root.mtechnavi.api.idp.UserGroup.fromObject(object.userGroup);
                    }
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.systemGroup != null)
                        message.systemGroup = Boolean(object.systemGroup);
                    if (object.userSummary != null) {
                        if (typeof object.userSummary !== "object")
                            throw TypeError(".mtechnavi.api.tenantadmin.UserGroupAttribute.userSummary: object expected");
                        message.userSummary = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary.fromObject(object.userSummary);
                    }
                    if (object.viewIds) {
                        if (!Array.isArray(object.viewIds))
                            throw TypeError(".mtechnavi.api.tenantadmin.UserGroupAttribute.viewIds: array expected");
                        message.viewIds = [];
                        for (let i = 0; i < object.viewIds.length; ++i)
                            message.viewIds[i] = String(object.viewIds[i]);
                    }
                    if (object.presetIds) {
                        if (!Array.isArray(object.presetIds))
                            throw TypeError(".mtechnavi.api.tenantadmin.UserGroupAttribute.presetIds: array expected");
                        message.presetIds = [];
                        for (let i = 0; i < object.presetIds.length; ++i)
                            message.presetIds[i] = String(object.presetIds[i]);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a UserGroupAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @static
                 * @param {mtechnavi.api.tenantadmin.UserGroupAttribute} message UserGroupAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserGroupAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.viewIds = [];
                        object.presetIds = [];
                    }
                    if (options.defaults) {
                        object.userGroupAttributeId = "";
                        object.userGroup = null;
                        object.remarks = "";
                        object.systemGroup = false;
                        object.userSummary = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.userGroupAttributeId != null && message.hasOwnProperty("userGroupAttributeId"))
                        object.userGroupAttributeId = message.userGroupAttributeId;
                    if (message.userGroup != null && message.hasOwnProperty("userGroup"))
                        object.userGroup = $root.mtechnavi.api.idp.UserGroup.toObject(message.userGroup, options);
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.systemGroup != null && message.hasOwnProperty("systemGroup"))
                        object.systemGroup = message.systemGroup;
                    if (message.userSummary != null && message.hasOwnProperty("userSummary"))
                        object.userSummary = $root.mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary.toObject(message.userSummary, options);
                    if (message.viewIds && message.viewIds.length) {
                        object.viewIds = [];
                        for (let j = 0; j < message.viewIds.length; ++j)
                            object.viewIds[j] = message.viewIds[j];
                    }
                    if (message.presetIds && message.presetIds.length) {
                        object.presetIds = [];
                        for (let j = 0; j < message.presetIds.length; ++j)
                            object.presetIds[j] = message.presetIds[j];
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this UserGroupAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserGroupAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UserGroupAttribute.UserSummary = (function() {

                    /**
                     * Properties of a UserSummary.
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                     * @interface IUserSummary
                     * @property {Long|null} [total] UserSummary total
                     */

                    /**
                     * Constructs a new UserSummary.
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute
                     * @classdesc Represents a UserSummary.
                     * @implements IUserSummary
                     * @constructor
                     * @param {mtechnavi.api.tenantadmin.UserGroupAttribute.IUserSummary=} [properties] Properties to set
                     */
                    function UserSummary(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserSummary total.
                     * @member {Long} total
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary
                     * @instance
                     */
                    UserSummary.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a UserSummary message.
                     * @function verify
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserSummary.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                                return "total: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a UserSummary message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary} UserSummary
                     */
                    UserSummary.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary)
                            return object;
                        let message = new $root.mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary();
                        if (object.total != null)
                            if ($util.Long)
                                (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                            else if (typeof object.total === "string")
                                message.total = parseInt(object.total, 10);
                            else if (typeof object.total === "number")
                                message.total = object.total;
                            else if (typeof object.total === "object")
                                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserSummary message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary
                     * @static
                     * @param {mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary} message UserSummary
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserSummary.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.total = options.longs === String ? "0" : 0;
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (typeof message.total === "number")
                                object.total = options.longs === String ? String(message.total) : message.total;
                            else
                                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                        return object;
                    };

                    /**
                     * Converts this UserSummary to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.tenantadmin.UserGroupAttribute.UserSummary
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserSummary.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserSummary;
                })();

                return UserGroupAttribute;
            })();

            tenantadmin.RoleAttribute = (function() {

                /**
                 * Properties of a RoleAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @interface IRoleAttribute
                 * @property {string|null} [roleAttributeId] RoleAttribute roleAttributeId
                 * @property {string|null} [roleName] RoleAttribute roleName
                 * @property {string|null} [remarks] RoleAttribute remarks
                 * @property {Long|null} [deletedAt] RoleAttribute deletedAt
                 * @property {Long|null} [createdAt] RoleAttribute createdAt
                 * @property {Long|null} [updatedAt] RoleAttribute updatedAt
                 */

                /**
                 * Constructs a new RoleAttribute.
                 * @memberof mtechnavi.api.tenantadmin
                 * @classdesc Represents a RoleAttribute.
                 * @implements IRoleAttribute
                 * @constructor
                 * @param {mtechnavi.api.tenantadmin.IRoleAttribute=} [properties] Properties to set
                 */
                function RoleAttribute(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RoleAttribute roleAttributeId.
                 * @member {string} roleAttributeId
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.roleAttributeId = "";

                /**
                 * RoleAttribute roleName.
                 * @member {string} roleName
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.roleName = "";

                /**
                 * RoleAttribute remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.remarks = "";

                /**
                 * RoleAttribute deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RoleAttribute createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RoleAttribute updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 */
                RoleAttribute.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a RoleAttribute message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RoleAttribute.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roleAttributeId != null && message.hasOwnProperty("roleAttributeId"))
                        if (!$util.isString(message.roleAttributeId))
                            return "roleAttributeId: string expected";
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        if (!$util.isString(message.roleName))
                            return "roleName: string expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a RoleAttribute message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantadmin.RoleAttribute} RoleAttribute
                 */
                RoleAttribute.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantadmin.RoleAttribute)
                        return object;
                    let message = new $root.mtechnavi.api.tenantadmin.RoleAttribute();
                    if (object.roleAttributeId != null)
                        message.roleAttributeId = String(object.roleAttributeId);
                    if (object.roleName != null)
                        message.roleName = String(object.roleName);
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a RoleAttribute message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @static
                 * @param {mtechnavi.api.tenantadmin.RoleAttribute} message RoleAttribute
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RoleAttribute.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.roleAttributeId = "";
                        object.roleName = "";
                        object.remarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.roleAttributeId != null && message.hasOwnProperty("roleAttributeId"))
                        object.roleAttributeId = message.roleAttributeId;
                    if (message.roleName != null && message.hasOwnProperty("roleName"))
                        object.roleName = message.roleName;
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this RoleAttribute to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantadmin.RoleAttribute
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RoleAttribute.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RoleAttribute;
            })();

            return tenantadmin;
        })();

        api.tenantprovision = (function() {

            /**
             * Namespace tenantprovision.
             * @memberof mtechnavi.api
             * @namespace
             */
            const tenantprovision = {};

            tenantprovision.TenantProvisionService = (function() {

                /**
                 * Constructs a new TenantProvisionService service.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a TenantProvisionService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function TenantProvisionService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (TenantProvisionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TenantProvisionService;

                /**
                 * Callback as used by {@link mtechnavi.api.tenantprovision.TenantProvisionService#getTenantRequest}.
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @typedef GetTenantRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantprovision.GetTenantRequestResponse} [response] GetTenantRequestResponse
                 */

                /**
                 * Calls GetTenantRequest.
                 * @function getTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IGetTenantRequestRequest} request GetTenantRequestRequest message or plain object
                 * @param {mtechnavi.api.tenantprovision.TenantProvisionService.GetTenantRequestCallback} callback Node-style callback called with the error, if any, and GetTenantRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantProvisionService.prototype.getTenantRequest = function getTenantRequest(request, callback) {
                    return this.rpcCall(getTenantRequest, $root.mtechnavi.api.tenantprovision.GetTenantRequestRequest, $root.mtechnavi.api.tenantprovision.GetTenantRequestResponse, request, callback);
                }, "name", { value: "GetTenantRequest" });

                /**
                 * Calls GetTenantRequest.
                 * @function getTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IGetTenantRequestRequest} request GetTenantRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantprovision.GetTenantRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantprovision.TenantProvisionService#verifyTenantRequest}.
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @typedef VerifyTenantRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantprovision.VerifyTenantRequestResponse} [response] VerifyTenantRequestResponse
                 */

                /**
                 * Calls VerifyTenantRequest.
                 * @function verifyTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IVerifyTenantRequestRequest} request VerifyTenantRequestRequest message or plain object
                 * @param {mtechnavi.api.tenantprovision.TenantProvisionService.VerifyTenantRequestCallback} callback Node-style callback called with the error, if any, and VerifyTenantRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantProvisionService.prototype.verifyTenantRequest = function verifyTenantRequest(request, callback) {
                    return this.rpcCall(verifyTenantRequest, $root.mtechnavi.api.tenantprovision.VerifyTenantRequestRequest, $root.mtechnavi.api.tenantprovision.VerifyTenantRequestResponse, request, callback);
                }, "name", { value: "VerifyTenantRequest" });

                /**
                 * Calls VerifyTenantRequest.
                 * @function verifyTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IVerifyTenantRequestRequest} request VerifyTenantRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantprovision.VerifyTenantRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.tenantprovision.TenantProvisionService#applyTenantRequest}.
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @typedef ApplyTenantRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.tenantprovision.ApplyTenantRequestResponse} [response] ApplyTenantRequestResponse
                 */

                /**
                 * Calls ApplyTenantRequest.
                 * @function applyTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IApplyTenantRequestRequest} request ApplyTenantRequestRequest message or plain object
                 * @param {mtechnavi.api.tenantprovision.TenantProvisionService.ApplyTenantRequestCallback} callback Node-style callback called with the error, if any, and ApplyTenantRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(TenantProvisionService.prototype.applyTenantRequest = function applyTenantRequest(request, callback) {
                    return this.rpcCall(applyTenantRequest, $root.mtechnavi.api.tenantprovision.ApplyTenantRequestRequest, $root.mtechnavi.api.tenantprovision.ApplyTenantRequestResponse, request, callback);
                }, "name", { value: "ApplyTenantRequest" });

                /**
                 * Calls ApplyTenantRequest.
                 * @function applyTenantRequest
                 * @memberof mtechnavi.api.tenantprovision.TenantProvisionService
                 * @instance
                 * @param {mtechnavi.api.tenantprovision.IApplyTenantRequestRequest} request ApplyTenantRequestRequest message or plain object
                 * @returns {Promise<mtechnavi.api.tenantprovision.ApplyTenantRequestResponse>} Promise
                 * @variation 2
                 */

                return TenantProvisionService;
            })();

            tenantprovision.GetTenantRequestRequest = (function() {

                /**
                 * Properties of a GetTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IGetTenantRequestRequest
                 * @property {string|null} [tenantRequestId] GetTenantRequestRequest tenantRequestId
                 */

                /**
                 * Constructs a new GetTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a GetTenantRequestRequest.
                 * @implements IGetTenantRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IGetTenantRequestRequest=} [properties] Properties to set
                 */
                function GetTenantRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTenantRequestRequest tenantRequestId.
                 * @member {string} tenantRequestId
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestRequest
                 * @instance
                 */
                GetTenantRequestRequest.prototype.tenantRequestId = "";

                /**
                 * Verifies a GetTenantRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTenantRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        if (!$util.isString(message.tenantRequestId))
                            return "tenantRequestId: string expected";
                    return null;
                };

                /**
                 * Creates a GetTenantRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.GetTenantRequestRequest} GetTenantRequestRequest
                 */
                GetTenantRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.GetTenantRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.GetTenantRequestRequest();
                    if (object.tenantRequestId != null)
                        message.tenantRequestId = String(object.tenantRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTenantRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestRequest
                 * @static
                 * @param {mtechnavi.api.tenantprovision.GetTenantRequestRequest} message GetTenantRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTenantRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantRequestId = "";
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        object.tenantRequestId = message.tenantRequestId;
                    return object;
                };

                /**
                 * Converts this GetTenantRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTenantRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTenantRequestRequest;
            })();

            tenantprovision.GetTenantRequestResponse = (function() {

                /**
                 * Properties of a GetTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IGetTenantRequestResponse
                 * @property {mtechnavi.api.tenantprovision.ITenantRequest|null} [tenantRequest] GetTenantRequestResponse tenantRequest
                 */

                /**
                 * Constructs a new GetTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a GetTenantRequestResponse.
                 * @implements IGetTenantRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IGetTenantRequestResponse=} [properties] Properties to set
                 */
                function GetTenantRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTenantRequestResponse tenantRequest.
                 * @member {mtechnavi.api.tenantprovision.ITenantRequest|null|undefined} tenantRequest
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestResponse
                 * @instance
                 */
                GetTenantRequestResponse.prototype.tenantRequest = null;

                /**
                 * Verifies a GetTenantRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTenantRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantRequest != null && message.hasOwnProperty("tenantRequest")) {
                        let error = $root.mtechnavi.api.tenantprovision.TenantRequest.verify(message.tenantRequest);
                        if (error)
                            return "tenantRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetTenantRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.GetTenantRequestResponse} GetTenantRequestResponse
                 */
                GetTenantRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.GetTenantRequestResponse)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.GetTenantRequestResponse();
                    if (object.tenantRequest != null) {
                        if (typeof object.tenantRequest !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.GetTenantRequestResponse.tenantRequest: object expected");
                        message.tenantRequest = $root.mtechnavi.api.tenantprovision.TenantRequest.fromObject(object.tenantRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetTenantRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestResponse
                 * @static
                 * @param {mtechnavi.api.tenantprovision.GetTenantRequestResponse} message GetTenantRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTenantRequestResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantRequest = null;
                    if (message.tenantRequest != null && message.hasOwnProperty("tenantRequest"))
                        object.tenantRequest = $root.mtechnavi.api.tenantprovision.TenantRequest.toObject(message.tenantRequest, options);
                    return object;
                };

                /**
                 * Converts this GetTenantRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.GetTenantRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTenantRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTenantRequestResponse;
            })();

            tenantprovision.VerifyTenantRequestRequest = (function() {

                /**
                 * Properties of a VerifyTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IVerifyTenantRequestRequest
                 * @property {mtechnavi.api.tenantprovision.ITenantRequest|null} [tenantRequest] VerifyTenantRequestRequest tenantRequest
                 */

                /**
                 * Constructs a new VerifyTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a VerifyTenantRequestRequest.
                 * @implements IVerifyTenantRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IVerifyTenantRequestRequest=} [properties] Properties to set
                 */
                function VerifyTenantRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyTenantRequestRequest tenantRequest.
                 * @member {mtechnavi.api.tenantprovision.ITenantRequest|null|undefined} tenantRequest
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestRequest
                 * @instance
                 */
                VerifyTenantRequestRequest.prototype.tenantRequest = null;

                /**
                 * Verifies a VerifyTenantRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyTenantRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantRequest != null && message.hasOwnProperty("tenantRequest")) {
                        let error = $root.mtechnavi.api.tenantprovision.TenantRequest.verify(message.tenantRequest);
                        if (error)
                            return "tenantRequest." + error;
                    }
                    return null;
                };

                /**
                 * Creates a VerifyTenantRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.VerifyTenantRequestRequest} VerifyTenantRequestRequest
                 */
                VerifyTenantRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.VerifyTenantRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.VerifyTenantRequestRequest();
                    if (object.tenantRequest != null) {
                        if (typeof object.tenantRequest !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.VerifyTenantRequestRequest.tenantRequest: object expected");
                        message.tenantRequest = $root.mtechnavi.api.tenantprovision.TenantRequest.fromObject(object.tenantRequest);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyTenantRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestRequest
                 * @static
                 * @param {mtechnavi.api.tenantprovision.VerifyTenantRequestRequest} message VerifyTenantRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyTenantRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.tenantRequest = null;
                    if (message.tenantRequest != null && message.hasOwnProperty("tenantRequest"))
                        object.tenantRequest = $root.mtechnavi.api.tenantprovision.TenantRequest.toObject(message.tenantRequest, options);
                    return object;
                };

                /**
                 * Converts this VerifyTenantRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyTenantRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyTenantRequestRequest;
            })();

            tenantprovision.OnTenantCreatedRequest = (function() {

                /**
                 * Properties of an OnTenantCreatedRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IOnTenantCreatedRequest
                 * @property {string|null} [createdTenantId] OnTenantCreatedRequest createdTenantId
                 * @property {Array.<string>|null} [licenseCodes] OnTenantCreatedRequest licenseCodes
                 * @property {string|null} [businessUnitManagementId] OnTenantCreatedRequest businessUnitManagementId
                 * @property {string|null} [inviteBy] OnTenantCreatedRequest inviteBy
                 * @property {Object.<string,string>|null} [companyDisplayNameLang] OnTenantCreatedRequest companyDisplayNameLang
                 * @property {sharelib.IUserReference|null} [adminUser] OnTenantCreatedRequest adminUser
                 * @property {Long|null} [linkedAt] OnTenantCreatedRequest linkedAt
                 * @property {string|null} [tenantManagementId] OnTenantCreatedRequest tenantManagementId
                 */

                /**
                 * Constructs a new OnTenantCreatedRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents an OnTenantCreatedRequest.
                 * @implements IOnTenantCreatedRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IOnTenantCreatedRequest=} [properties] Properties to set
                 */
                function OnTenantCreatedRequest(properties) {
                    this.licenseCodes = [];
                    this.companyDisplayNameLang = {};
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OnTenantCreatedRequest createdTenantId.
                 * @member {string} createdTenantId
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.createdTenantId = "";

                /**
                 * OnTenantCreatedRequest licenseCodes.
                 * @member {Array.<string>} licenseCodes
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.licenseCodes = $util.emptyArray;

                /**
                 * OnTenantCreatedRequest businessUnitManagementId.
                 * @member {string} businessUnitManagementId
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.businessUnitManagementId = "";

                /**
                 * OnTenantCreatedRequest inviteBy.
                 * @member {string} inviteBy
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.inviteBy = "";

                /**
                 * OnTenantCreatedRequest companyDisplayNameLang.
                 * @member {Object.<string,string>} companyDisplayNameLang
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.companyDisplayNameLang = $util.emptyObject;

                /**
                 * OnTenantCreatedRequest adminUser.
                 * @member {sharelib.IUserReference|null|undefined} adminUser
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.adminUser = null;

                /**
                 * OnTenantCreatedRequest linkedAt.
                 * @member {Long} linkedAt
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.linkedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * OnTenantCreatedRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 */
                OnTenantCreatedRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies an OnTenantCreatedRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OnTenantCreatedRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.createdTenantId != null && message.hasOwnProperty("createdTenantId"))
                        if (!$util.isString(message.createdTenantId))
                            return "createdTenantId: string expected";
                    if (message.licenseCodes != null && message.hasOwnProperty("licenseCodes")) {
                        if (!Array.isArray(message.licenseCodes))
                            return "licenseCodes: array expected";
                        for (let i = 0; i < message.licenseCodes.length; ++i)
                            if (!$util.isString(message.licenseCodes[i]))
                                return "licenseCodes: string[] expected";
                    }
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        if (!$util.isString(message.businessUnitManagementId))
                            return "businessUnitManagementId: string expected";
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        if (!$util.isString(message.inviteBy))
                            return "inviteBy: string expected";
                    if (message.companyDisplayNameLang != null && message.hasOwnProperty("companyDisplayNameLang")) {
                        if (!$util.isObject(message.companyDisplayNameLang))
                            return "companyDisplayNameLang: object expected";
                        let key = Object.keys(message.companyDisplayNameLang);
                        for (let i = 0; i < key.length; ++i)
                            if (!$util.isString(message.companyDisplayNameLang[key[i]]))
                                return "companyDisplayNameLang: string{k:string} expected";
                    }
                    if (message.adminUser != null && message.hasOwnProperty("adminUser")) {
                        let error = $root.sharelib.UserReference.verify(message.adminUser);
                        if (error)
                            return "adminUser." + error;
                    }
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (!$util.isInteger(message.linkedAt) && !(message.linkedAt && $util.isInteger(message.linkedAt.low) && $util.isInteger(message.linkedAt.high)))
                            return "linkedAt: integer|Long expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates an OnTenantCreatedRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.OnTenantCreatedRequest} OnTenantCreatedRequest
                 */
                OnTenantCreatedRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.OnTenantCreatedRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.OnTenantCreatedRequest();
                    if (object.createdTenantId != null)
                        message.createdTenantId = String(object.createdTenantId);
                    if (object.licenseCodes) {
                        if (!Array.isArray(object.licenseCodes))
                            throw TypeError(".mtechnavi.api.tenantprovision.OnTenantCreatedRequest.licenseCodes: array expected");
                        message.licenseCodes = [];
                        for (let i = 0; i < object.licenseCodes.length; ++i)
                            message.licenseCodes[i] = String(object.licenseCodes[i]);
                    }
                    if (object.businessUnitManagementId != null)
                        message.businessUnitManagementId = String(object.businessUnitManagementId);
                    if (object.inviteBy != null)
                        message.inviteBy = String(object.inviteBy);
                    if (object.companyDisplayNameLang) {
                        if (typeof object.companyDisplayNameLang !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.OnTenantCreatedRequest.companyDisplayNameLang: object expected");
                        message.companyDisplayNameLang = {};
                        for (let keys = Object.keys(object.companyDisplayNameLang), i = 0; i < keys.length; ++i)
                            message.companyDisplayNameLang[keys[i]] = String(object.companyDisplayNameLang[keys[i]]);
                    }
                    if (object.adminUser != null) {
                        if (typeof object.adminUser !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.OnTenantCreatedRequest.adminUser: object expected");
                        message.adminUser = $root.sharelib.UserReference.fromObject(object.adminUser);
                    }
                    if (object.linkedAt != null)
                        if ($util.Long)
                            (message.linkedAt = $util.Long.fromValue(object.linkedAt)).unsigned = false;
                        else if (typeof object.linkedAt === "string")
                            message.linkedAt = parseInt(object.linkedAt, 10);
                        else if (typeof object.linkedAt === "number")
                            message.linkedAt = object.linkedAt;
                        else if (typeof object.linkedAt === "object")
                            message.linkedAt = new $util.LongBits(object.linkedAt.low >>> 0, object.linkedAt.high >>> 0).toNumber();
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from an OnTenantCreatedRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @static
                 * @param {mtechnavi.api.tenantprovision.OnTenantCreatedRequest} message OnTenantCreatedRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OnTenantCreatedRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseCodes = [];
                    if (options.objects || options.defaults)
                        object.companyDisplayNameLang = {};
                    if (options.defaults) {
                        object.createdTenantId = "";
                        object.businessUnitManagementId = "";
                        object.inviteBy = "";
                        object.adminUser = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.linkedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.linkedAt = options.longs === String ? "0" : 0;
                        object.tenantManagementId = "";
                    }
                    if (message.createdTenantId != null && message.hasOwnProperty("createdTenantId"))
                        object.createdTenantId = message.createdTenantId;
                    if (message.businessUnitManagementId != null && message.hasOwnProperty("businessUnitManagementId"))
                        object.businessUnitManagementId = message.businessUnitManagementId;
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        object.inviteBy = message.inviteBy;
                    let keys2;
                    if (message.companyDisplayNameLang && (keys2 = Object.keys(message.companyDisplayNameLang)).length) {
                        object.companyDisplayNameLang = {};
                        for (let j = 0; j < keys2.length; ++j)
                            object.companyDisplayNameLang[keys2[j]] = message.companyDisplayNameLang[keys2[j]];
                    }
                    if (message.adminUser != null && message.hasOwnProperty("adminUser"))
                        object.adminUser = $root.sharelib.UserReference.toObject(message.adminUser, options);
                    if (message.linkedAt != null && message.hasOwnProperty("linkedAt"))
                        if (typeof message.linkedAt === "number")
                            object.linkedAt = options.longs === String ? String(message.linkedAt) : message.linkedAt;
                        else
                            object.linkedAt = options.longs === String ? $util.Long.prototype.toString.call(message.linkedAt) : options.longs === Number ? new $util.LongBits(message.linkedAt.low >>> 0, message.linkedAt.high >>> 0).toNumber() : message.linkedAt;
                    if (message.licenseCodes && message.licenseCodes.length) {
                        object.licenseCodes = [];
                        for (let j = 0; j < message.licenseCodes.length; ++j)
                            object.licenseCodes[j] = message.licenseCodes[j];
                    }
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this OnTenantCreatedRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.OnTenantCreatedRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OnTenantCreatedRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return OnTenantCreatedRequest;
            })();

            tenantprovision.VerifyTenantRequestResponse = (function() {

                /**
                 * Properties of a VerifyTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IVerifyTenantRequestResponse
                 */

                /**
                 * Constructs a new VerifyTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a VerifyTenantRequestResponse.
                 * @implements IVerifyTenantRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IVerifyTenantRequestResponse=} [properties] Properties to set
                 */
                function VerifyTenantRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a VerifyTenantRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyTenantRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a VerifyTenantRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.VerifyTenantRequestResponse} VerifyTenantRequestResponse
                 */
                VerifyTenantRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.VerifyTenantRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.tenantprovision.VerifyTenantRequestResponse();
                };

                /**
                 * Creates a plain object from a VerifyTenantRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestResponse
                 * @static
                 * @param {mtechnavi.api.tenantprovision.VerifyTenantRequestResponse} message VerifyTenantRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyTenantRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this VerifyTenantRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.VerifyTenantRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyTenantRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyTenantRequestResponse;
            })();

            tenantprovision.ApplyTenantRequestRequest = (function() {

                /**
                 * Properties of an ApplyTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IApplyTenantRequestRequest
                 * @property {string|null} [tenantRequestId] ApplyTenantRequestRequest tenantRequestId
                 * @property {string|null} [verifyTenantRequestId] ApplyTenantRequestRequest verifyTenantRequestId
                 */

                /**
                 * Constructs a new ApplyTenantRequestRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents an ApplyTenantRequestRequest.
                 * @implements IApplyTenantRequestRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IApplyTenantRequestRequest=} [properties] Properties to set
                 */
                function ApplyTenantRequestRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApplyTenantRequestRequest tenantRequestId.
                 * @member {string} tenantRequestId
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @instance
                 */
                ApplyTenantRequestRequest.prototype.tenantRequestId = "";

                /**
                 * ApplyTenantRequestRequest verifyTenantRequestId.
                 * @member {string} verifyTenantRequestId
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @instance
                 */
                ApplyTenantRequestRequest.prototype.verifyTenantRequestId = "";

                /**
                 * Verifies an ApplyTenantRequestRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApplyTenantRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        if (!$util.isString(message.tenantRequestId))
                            return "tenantRequestId: string expected";
                    if (message.verifyTenantRequestId != null && message.hasOwnProperty("verifyTenantRequestId"))
                        if (!$util.isString(message.verifyTenantRequestId))
                            return "verifyTenantRequestId: string expected";
                    return null;
                };

                /**
                 * Creates an ApplyTenantRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.ApplyTenantRequestRequest} ApplyTenantRequestRequest
                 */
                ApplyTenantRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.ApplyTenantRequestRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.ApplyTenantRequestRequest();
                    if (object.tenantRequestId != null)
                        message.tenantRequestId = String(object.tenantRequestId);
                    if (object.verifyTenantRequestId != null)
                        message.verifyTenantRequestId = String(object.verifyTenantRequestId);
                    return message;
                };

                /**
                 * Creates a plain object from an ApplyTenantRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @static
                 * @param {mtechnavi.api.tenantprovision.ApplyTenantRequestRequest} message ApplyTenantRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApplyTenantRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.tenantRequestId = "";
                        object.verifyTenantRequestId = "";
                    }
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        object.tenantRequestId = message.tenantRequestId;
                    if (message.verifyTenantRequestId != null && message.hasOwnProperty("verifyTenantRequestId"))
                        object.verifyTenantRequestId = message.verifyTenantRequestId;
                    return object;
                };

                /**
                 * Converts this ApplyTenantRequestRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApplyTenantRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApplyTenantRequestRequest;
            })();

            tenantprovision.ApplyTenantRequestResponse = (function() {

                /**
                 * Properties of an ApplyTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface IApplyTenantRequestResponse
                 */

                /**
                 * Constructs a new ApplyTenantRequestResponse.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents an ApplyTenantRequestResponse.
                 * @implements IApplyTenantRequestResponse
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.IApplyTenantRequestResponse=} [properties] Properties to set
                 */
                function ApplyTenantRequestResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApplyTenantRequestResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApplyTenantRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApplyTenantRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.ApplyTenantRequestResponse} ApplyTenantRequestResponse
                 */
                ApplyTenantRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.ApplyTenantRequestResponse)
                        return object;
                    return new $root.mtechnavi.api.tenantprovision.ApplyTenantRequestResponse();
                };

                /**
                 * Creates a plain object from an ApplyTenantRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestResponse
                 * @static
                 * @param {mtechnavi.api.tenantprovision.ApplyTenantRequestResponse} message ApplyTenantRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApplyTenantRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApplyTenantRequestResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.ApplyTenantRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApplyTenantRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApplyTenantRequestResponse;
            })();

            tenantprovision.TenantRequest = (function() {

                /**
                 * Properties of a TenantRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @interface ITenantRequest
                 * @property {string|null} [tenantRequestId] TenantRequest tenantRequestId
                 * @property {mtechnavi.api.idp.ITenant|null} [tenant] TenantRequest tenant
                 * @property {mtechnavi.api.idp.IUser|null} [tenantAdmin] TenantRequest tenantAdmin
                 * @property {string|null} [invitedAt] TenantRequest invitedAt
                 * @property {string|null} [requestVerifiedAt] TenantRequest requestVerifiedAt
                 * @property {Array.<string>|null} [licenseCodes] TenantRequest licenseCodes
                 * @property {string|null} [inviteBy] TenantRequest inviteBy
                 * @property {string|null} [releateBusinessUnitManagementId] TenantRequest releateBusinessUnitManagementId
                 * @property {string|null} [tenantManagementId] TenantRequest tenantManagementId
                 */

                /**
                 * Constructs a new TenantRequest.
                 * @memberof mtechnavi.api.tenantprovision
                 * @classdesc Represents a TenantRequest.
                 * @implements ITenantRequest
                 * @constructor
                 * @param {mtechnavi.api.tenantprovision.ITenantRequest=} [properties] Properties to set
                 */
                function TenantRequest(properties) {
                    this.licenseCodes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TenantRequest tenantRequestId.
                 * @member {string} tenantRequestId
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.tenantRequestId = "";

                /**
                 * TenantRequest tenant.
                 * @member {mtechnavi.api.idp.ITenant|null|undefined} tenant
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.tenant = null;

                /**
                 * TenantRequest tenantAdmin.
                 * @member {mtechnavi.api.idp.IUser|null|undefined} tenantAdmin
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.tenantAdmin = null;

                /**
                 * TenantRequest invitedAt.
                 * @member {string} invitedAt
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.invitedAt = "";

                /**
                 * TenantRequest requestVerifiedAt.
                 * @member {string} requestVerifiedAt
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.requestVerifiedAt = "";

                /**
                 * TenantRequest licenseCodes.
                 * @member {Array.<string>} licenseCodes
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.licenseCodes = $util.emptyArray;

                /**
                 * TenantRequest inviteBy.
                 * @member {string} inviteBy
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.inviteBy = "";

                /**
                 * TenantRequest releateBusinessUnitManagementId.
                 * @member {string} releateBusinessUnitManagementId
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.releateBusinessUnitManagementId = "";

                /**
                 * TenantRequest tenantManagementId.
                 * @member {string} tenantManagementId
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 */
                TenantRequest.prototype.tenantManagementId = "";

                /**
                 * Verifies a TenantRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TenantRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        if (!$util.isString(message.tenantRequestId))
                            return "tenantRequestId: string expected";
                    if (message.tenant != null && message.hasOwnProperty("tenant")) {
                        let error = $root.mtechnavi.api.idp.Tenant.verify(message.tenant);
                        if (error)
                            return "tenant." + error;
                    }
                    if (message.tenantAdmin != null && message.hasOwnProperty("tenantAdmin")) {
                        let error = $root.mtechnavi.api.idp.User.verify(message.tenantAdmin);
                        if (error)
                            return "tenantAdmin." + error;
                    }
                    if (message.invitedAt != null && message.hasOwnProperty("invitedAt"))
                        if (!$util.isString(message.invitedAt))
                            return "invitedAt: string expected";
                    if (message.requestVerifiedAt != null && message.hasOwnProperty("requestVerifiedAt"))
                        if (!$util.isString(message.requestVerifiedAt))
                            return "requestVerifiedAt: string expected";
                    if (message.licenseCodes != null && message.hasOwnProperty("licenseCodes")) {
                        if (!Array.isArray(message.licenseCodes))
                            return "licenseCodes: array expected";
                        for (let i = 0; i < message.licenseCodes.length; ++i)
                            if (!$util.isString(message.licenseCodes[i]))
                                return "licenseCodes: string[] expected";
                    }
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        if (!$util.isString(message.inviteBy))
                            return "inviteBy: string expected";
                    if (message.releateBusinessUnitManagementId != null && message.hasOwnProperty("releateBusinessUnitManagementId"))
                        if (!$util.isString(message.releateBusinessUnitManagementId))
                            return "releateBusinessUnitManagementId: string expected";
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        if (!$util.isString(message.tenantManagementId))
                            return "tenantManagementId: string expected";
                    return null;
                };

                /**
                 * Creates a TenantRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.tenantprovision.TenantRequest} TenantRequest
                 */
                TenantRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.tenantprovision.TenantRequest)
                        return object;
                    let message = new $root.mtechnavi.api.tenantprovision.TenantRequest();
                    if (object.tenantRequestId != null)
                        message.tenantRequestId = String(object.tenantRequestId);
                    if (object.tenant != null) {
                        if (typeof object.tenant !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.TenantRequest.tenant: object expected");
                        message.tenant = $root.mtechnavi.api.idp.Tenant.fromObject(object.tenant);
                    }
                    if (object.tenantAdmin != null) {
                        if (typeof object.tenantAdmin !== "object")
                            throw TypeError(".mtechnavi.api.tenantprovision.TenantRequest.tenantAdmin: object expected");
                        message.tenantAdmin = $root.mtechnavi.api.idp.User.fromObject(object.tenantAdmin);
                    }
                    if (object.invitedAt != null)
                        message.invitedAt = String(object.invitedAt);
                    if (object.requestVerifiedAt != null)
                        message.requestVerifiedAt = String(object.requestVerifiedAt);
                    if (object.licenseCodes) {
                        if (!Array.isArray(object.licenseCodes))
                            throw TypeError(".mtechnavi.api.tenantprovision.TenantRequest.licenseCodes: array expected");
                        message.licenseCodes = [];
                        for (let i = 0; i < object.licenseCodes.length; ++i)
                            message.licenseCodes[i] = String(object.licenseCodes[i]);
                    }
                    if (object.inviteBy != null)
                        message.inviteBy = String(object.inviteBy);
                    if (object.releateBusinessUnitManagementId != null)
                        message.releateBusinessUnitManagementId = String(object.releateBusinessUnitManagementId);
                    if (object.tenantManagementId != null)
                        message.tenantManagementId = String(object.tenantManagementId);
                    return message;
                };

                /**
                 * Creates a plain object from a TenantRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @static
                 * @param {mtechnavi.api.tenantprovision.TenantRequest} message TenantRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TenantRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.licenseCodes = [];
                    if (options.defaults) {
                        object.tenantRequestId = "";
                        object.tenant = null;
                        object.tenantAdmin = null;
                        object.invitedAt = "";
                        object.requestVerifiedAt = "";
                        object.inviteBy = "";
                        object.releateBusinessUnitManagementId = "";
                        object.tenantManagementId = "";
                    }
                    if (message.tenantRequestId != null && message.hasOwnProperty("tenantRequestId"))
                        object.tenantRequestId = message.tenantRequestId;
                    if (message.tenant != null && message.hasOwnProperty("tenant"))
                        object.tenant = $root.mtechnavi.api.idp.Tenant.toObject(message.tenant, options);
                    if (message.tenantAdmin != null && message.hasOwnProperty("tenantAdmin"))
                        object.tenantAdmin = $root.mtechnavi.api.idp.User.toObject(message.tenantAdmin, options);
                    if (message.invitedAt != null && message.hasOwnProperty("invitedAt"))
                        object.invitedAt = message.invitedAt;
                    if (message.requestVerifiedAt != null && message.hasOwnProperty("requestVerifiedAt"))
                        object.requestVerifiedAt = message.requestVerifiedAt;
                    if (message.licenseCodes && message.licenseCodes.length) {
                        object.licenseCodes = [];
                        for (let j = 0; j < message.licenseCodes.length; ++j)
                            object.licenseCodes[j] = message.licenseCodes[j];
                    }
                    if (message.inviteBy != null && message.hasOwnProperty("inviteBy"))
                        object.inviteBy = message.inviteBy;
                    if (message.releateBusinessUnitManagementId != null && message.hasOwnProperty("releateBusinessUnitManagementId"))
                        object.releateBusinessUnitManagementId = message.releateBusinessUnitManagementId;
                    if (message.tenantManagementId != null && message.hasOwnProperty("tenantManagementId"))
                        object.tenantManagementId = message.tenantManagementId;
                    return object;
                };

                /**
                 * Converts this TenantRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.tenantprovision.TenantRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TenantRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TenantRequest;
            })();

            return tenantprovision;
        })();

        api.uicontroller = (function() {

            /**
             * Namespace uicontroller.
             * @memberof mtechnavi.api
             * @namespace
             */
            const uicontroller = {};

            uicontroller.UiController = (function() {

                /**
                 * Constructs a new UiController service.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents an UiController
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function UiController(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (UiController.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UiController;

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#createPresetMenu}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef CreatePresetMenuCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.PresetMenu} [response] PresetMenu
                 */

                /**
                 * Calls CreatePresetMenu.
                 * @function createPresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ICreatePresetMenuRequest} request CreatePresetMenuRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.CreatePresetMenuCallback} callback Node-style callback called with the error, if any, and PresetMenu
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.createPresetMenu = function createPresetMenu(request, callback) {
                    return this.rpcCall(createPresetMenu, $root.mtechnavi.api.uicontroller.CreatePresetMenuRequest, $root.mtechnavi.api.uicontroller.PresetMenu, request, callback);
                }, "name", { value: "CreatePresetMenu" });

                /**
                 * Calls CreatePresetMenu.
                 * @function createPresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ICreatePresetMenuRequest} request CreatePresetMenuRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.PresetMenu>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#listPresetMenus}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef ListPresetMenusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.ListPresetMenusResponse} [response] ListPresetMenusResponse
                 */

                /**
                 * Calls ListPresetMenus.
                 * @function listPresetMenus
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListPresetMenusRequest} request ListPresetMenusRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.ListPresetMenusCallback} callback Node-style callback called with the error, if any, and ListPresetMenusResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.listPresetMenus = function listPresetMenus(request, callback) {
                    return this.rpcCall(listPresetMenus, $root.mtechnavi.api.uicontroller.ListPresetMenusRequest, $root.mtechnavi.api.uicontroller.ListPresetMenusResponse, request, callback);
                }, "name", { value: "ListPresetMenus" });

                /**
                 * Calls ListPresetMenus.
                 * @function listPresetMenus
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListPresetMenusRequest} request ListPresetMenusRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.ListPresetMenusResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#getPresetMenu}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef GetPresetMenuCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.PresetMenu} [response] PresetMenu
                 */

                /**
                 * Calls GetPresetMenu.
                 * @function getPresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IGetPresetMenuRequest} request GetPresetMenuRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.GetPresetMenuCallback} callback Node-style callback called with the error, if any, and PresetMenu
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.getPresetMenu = function getPresetMenu(request, callback) {
                    return this.rpcCall(getPresetMenu, $root.mtechnavi.api.uicontroller.GetPresetMenuRequest, $root.mtechnavi.api.uicontroller.PresetMenu, request, callback);
                }, "name", { value: "GetPresetMenu" });

                /**
                 * Calls GetPresetMenu.
                 * @function getPresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IGetPresetMenuRequest} request GetPresetMenuRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.PresetMenu>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#updatePresetMenu}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef UpdatePresetMenuCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.PresetMenu} [response] PresetMenu
                 */

                /**
                 * Calls UpdatePresetMenu.
                 * @function updatePresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetMenuRequest} request UpdatePresetMenuRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.UpdatePresetMenuCallback} callback Node-style callback called with the error, if any, and PresetMenu
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.updatePresetMenu = function updatePresetMenu(request, callback) {
                    return this.rpcCall(updatePresetMenu, $root.mtechnavi.api.uicontroller.UpdatePresetMenuRequest, $root.mtechnavi.api.uicontroller.PresetMenu, request, callback);
                }, "name", { value: "UpdatePresetMenu" });

                /**
                 * Calls UpdatePresetMenu.
                 * @function updatePresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetMenuRequest} request UpdatePresetMenuRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.PresetMenu>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#deletePresetMenu}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef DeletePresetMenuCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeletePresetMenu.
                 * @function deletePresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeletePresetMenuRequest} request DeletePresetMenuRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.DeletePresetMenuCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.deletePresetMenu = function deletePresetMenu(request, callback) {
                    return this.rpcCall(deletePresetMenu, $root.mtechnavi.api.uicontroller.DeletePresetMenuRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeletePresetMenu" });

                /**
                 * Calls DeletePresetMenu.
                 * @function deletePresetMenu
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeletePresetMenuRequest} request DeletePresetMenuRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#createPreset}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef CreatePresetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.Preset} [response] Preset
                 */

                /**
                 * Calls CreatePreset.
                 * @function createPreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ICreatePresetRequest} request CreatePresetRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.CreatePresetCallback} callback Node-style callback called with the error, if any, and Preset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.createPreset = function createPreset(request, callback) {
                    return this.rpcCall(createPreset, $root.mtechnavi.api.uicontroller.CreatePresetRequest, $root.mtechnavi.api.uicontroller.Preset, request, callback);
                }, "name", { value: "CreatePreset" });

                /**
                 * Calls CreatePreset.
                 * @function createPreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ICreatePresetRequest} request CreatePresetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.Preset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#listPresets}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef ListPresetsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.ListPresetsResponse} [response] ListPresetsResponse
                 */

                /**
                 * Calls ListPresets.
                 * @function listPresets
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListPresetsRequest} request ListPresetsRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.ListPresetsCallback} callback Node-style callback called with the error, if any, and ListPresetsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.listPresets = function listPresets(request, callback) {
                    return this.rpcCall(listPresets, $root.mtechnavi.api.uicontroller.ListPresetsRequest, $root.mtechnavi.api.uicontroller.ListPresetsResponse, request, callback);
                }, "name", { value: "ListPresets" });

                /**
                 * Calls ListPresets.
                 * @function listPresets
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListPresetsRequest} request ListPresetsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.ListPresetsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#getPreset}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef GetPresetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.Preset} [response] Preset
                 */

                /**
                 * Calls GetPreset.
                 * @function getPreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IGetPresetRequest} request GetPresetRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.GetPresetCallback} callback Node-style callback called with the error, if any, and Preset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.getPreset = function getPreset(request, callback) {
                    return this.rpcCall(getPreset, $root.mtechnavi.api.uicontroller.GetPresetRequest, $root.mtechnavi.api.uicontroller.Preset, request, callback);
                }, "name", { value: "GetPreset" });

                /**
                 * Calls GetPreset.
                 * @function getPreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IGetPresetRequest} request GetPresetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.Preset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#updatePreset}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef UpdatePresetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.Preset} [response] Preset
                 */

                /**
                 * Calls UpdatePreset.
                 * @function updatePreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetRequest} request UpdatePresetRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.UpdatePresetCallback} callback Node-style callback called with the error, if any, and Preset
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.updatePreset = function updatePreset(request, callback) {
                    return this.rpcCall(updatePreset, $root.mtechnavi.api.uicontroller.UpdatePresetRequest, $root.mtechnavi.api.uicontroller.Preset, request, callback);
                }, "name", { value: "UpdatePreset" });

                /**
                 * Calls UpdatePreset.
                 * @function updatePreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetRequest} request UpdatePresetRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.Preset>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#deletePreset}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef DeletePresetCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeletePreset.
                 * @function deletePreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeletePresetRequest} request DeletePresetRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.DeletePresetCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.deletePreset = function deletePreset(request, callback) {
                    return this.rpcCall(deletePreset, $root.mtechnavi.api.uicontroller.DeletePresetRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeletePreset" });

                /**
                 * Calls DeletePreset.
                 * @function deletePreset
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeletePresetRequest} request DeletePresetRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#saveUserSetting}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef SaveUserSettingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.UserSetting} [response] UserSetting
                 */

                /**
                 * Calls SaveUserSetting.
                 * @function saveUserSetting
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ISaveUserSettingRequest} request SaveUserSettingRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.SaveUserSettingCallback} callback Node-style callback called with the error, if any, and UserSetting
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.saveUserSetting = function saveUserSetting(request, callback) {
                    return this.rpcCall(saveUserSetting, $root.mtechnavi.api.uicontroller.SaveUserSettingRequest, $root.mtechnavi.api.uicontroller.UserSetting, request, callback);
                }, "name", { value: "SaveUserSetting" });

                /**
                 * Calls SaveUserSetting.
                 * @function saveUserSetting
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.ISaveUserSettingRequest} request SaveUserSettingRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.UserSetting>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#listUserSettings}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef ListUserSettingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.ListUserSettingsResponse} [response] ListUserSettingsResponse
                 */

                /**
                 * Calls ListUserSettings.
                 * @function listUserSettings
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListUserSettingsRequest} request ListUserSettingsRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.ListUserSettingsCallback} callback Node-style callback called with the error, if any, and ListUserSettingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.listUserSettings = function listUserSettings(request, callback) {
                    return this.rpcCall(listUserSettings, $root.mtechnavi.api.uicontroller.ListUserSettingsRequest, $root.mtechnavi.api.uicontroller.ListUserSettingsResponse, request, callback);
                }, "name", { value: "ListUserSettings" });

                /**
                 * Calls ListUserSettings.
                 * @function listUserSettings
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IListUserSettingsRequest} request ListUserSettingsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.ListUserSettingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#deleteUserSetting}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef DeleteUserSettingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteUserSetting.
                 * @function deleteUserSetting
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeleteUserSettingRequest} request DeleteUserSettingRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.DeleteUserSettingCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.deleteUserSetting = function deleteUserSetting(request, callback) {
                    return this.rpcCall(deleteUserSetting, $root.mtechnavi.api.uicontroller.DeleteUserSettingRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteUserSetting" });

                /**
                 * Calls DeleteUserSetting.
                 * @function deleteUserSetting
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IDeleteUserSettingRequest} request DeleteUserSettingRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.UiController#getTaskListSummary}.
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @typedef GetTaskListSummaryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.GetTaskListSummaryResponse} [response] GetTaskListSummaryResponse
                 */

                /**
                 * Calls GetTaskListSummary.
                 * @function getTaskListSummary
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @param {mtechnavi.api.uicontroller.UiController.GetTaskListSummaryCallback} callback Node-style callback called with the error, if any, and GetTaskListSummaryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(UiController.prototype.getTaskListSummary = function getTaskListSummary(request, callback) {
                    return this.rpcCall(getTaskListSummary, $root.google.protobuf.Empty, $root.mtechnavi.api.uicontroller.GetTaskListSummaryResponse, request, callback);
                }, "name", { value: "GetTaskListSummary" });

                /**
                 * Calls GetTaskListSummary.
                 * @function getTaskListSummary
                 * @memberof mtechnavi.api.uicontroller.UiController
                 * @instance
                 * @param {google.protobuf.IEmpty} request Empty message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.GetTaskListSummaryResponse>} Promise
                 * @variation 2
                 */

                return UiController;
            })();

            uicontroller.PublicInformationService = (function() {

                /**
                 * Constructs a new PublicInformationService service.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PublicInformationService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function PublicInformationService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (PublicInformationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PublicInformationService;

                /**
                 * Callback as used by {@link mtechnavi.api.uicontroller.PublicInformationService#publicListInformations}.
                 * @memberof mtechnavi.api.uicontroller.PublicInformationService
                 * @typedef PublicListInformationsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.uicontroller.PublicListInformationsResponse} [response] PublicListInformationsResponse
                 */

                /**
                 * Calls PublicListInformations.
                 * @function publicListInformations
                 * @memberof mtechnavi.api.uicontroller.PublicInformationService
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IPublicListInformationsRequest} request PublicListInformationsRequest message or plain object
                 * @param {mtechnavi.api.uicontroller.PublicInformationService.PublicListInformationsCallback} callback Node-style callback called with the error, if any, and PublicListInformationsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(PublicInformationService.prototype.publicListInformations = function publicListInformations(request, callback) {
                    return this.rpcCall(publicListInformations, $root.mtechnavi.api.uicontroller.PublicListInformationsRequest, $root.mtechnavi.api.uicontroller.PublicListInformationsResponse, request, callback);
                }, "name", { value: "PublicListInformations" });

                /**
                 * Calls PublicListInformations.
                 * @function publicListInformations
                 * @memberof mtechnavi.api.uicontroller.PublicInformationService
                 * @instance
                 * @param {mtechnavi.api.uicontroller.IPublicListInformationsRequest} request PublicListInformationsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.uicontroller.PublicListInformationsResponse>} Promise
                 * @variation 2
                 */

                return PublicInformationService;
            })();

            uicontroller.CreatePresetMenuRequest = (function() {

                /**
                 * Properties of a CreatePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface ICreatePresetMenuRequest
                 * @property {mtechnavi.api.uicontroller.IPresetMenu|null} [presetMenu] CreatePresetMenuRequest presetMenu
                 */

                /**
                 * Constructs a new CreatePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a CreatePresetMenuRequest.
                 * @implements ICreatePresetMenuRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.ICreatePresetMenuRequest=} [properties] Properties to set
                 */
                function CreatePresetMenuRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreatePresetMenuRequest presetMenu.
                 * @member {mtechnavi.api.uicontroller.IPresetMenu|null|undefined} presetMenu
                 * @memberof mtechnavi.api.uicontroller.CreatePresetMenuRequest
                 * @instance
                 */
                CreatePresetMenuRequest.prototype.presetMenu = null;

                /**
                 * Verifies a CreatePresetMenuRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.CreatePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreatePresetMenuRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu")) {
                        let error = $root.mtechnavi.api.uicontroller.PresetMenu.verify(message.presetMenu);
                        if (error)
                            return "presetMenu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreatePresetMenuRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.CreatePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.CreatePresetMenuRequest} CreatePresetMenuRequest
                 */
                CreatePresetMenuRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.CreatePresetMenuRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.CreatePresetMenuRequest();
                    if (object.presetMenu != null) {
                        if (typeof object.presetMenu !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.CreatePresetMenuRequest.presetMenu: object expected");
                        message.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.fromObject(object.presetMenu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreatePresetMenuRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.CreatePresetMenuRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.CreatePresetMenuRequest} message CreatePresetMenuRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreatePresetMenuRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetMenu = null;
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu"))
                        object.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.toObject(message.presetMenu, options);
                    return object;
                };

                /**
                 * Converts this CreatePresetMenuRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.CreatePresetMenuRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreatePresetMenuRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreatePresetMenuRequest;
            })();

            uicontroller.ListPresetMenusRequest = (function() {

                /**
                 * Properties of a ListPresetMenusRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListPresetMenusRequest
                 */

                /**
                 * Constructs a new ListPresetMenusRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListPresetMenusRequest.
                 * @implements IListPresetMenusRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListPresetMenusRequest=} [properties] Properties to set
                 */
                function ListPresetMenusRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListPresetMenusRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPresetMenusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListPresetMenusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListPresetMenusRequest} ListPresetMenusRequest
                 */
                ListPresetMenusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListPresetMenusRequest)
                        return object;
                    return new $root.mtechnavi.api.uicontroller.ListPresetMenusRequest();
                };

                /**
                 * Creates a plain object from a ListPresetMenusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListPresetMenusRequest} message ListPresetMenusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPresetMenusRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListPresetMenusRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPresetMenusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPresetMenusRequest;
            })();

            uicontroller.ListPresetMenusResponse = (function() {

                /**
                 * Properties of a ListPresetMenusResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListPresetMenusResponse
                 * @property {Long|null} [total] ListPresetMenusResponse total
                 * @property {Array.<mtechnavi.api.uicontroller.IPresetMenu>|null} [items] ListPresetMenusResponse items
                 */

                /**
                 * Constructs a new ListPresetMenusResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListPresetMenusResponse.
                 * @implements IListPresetMenusResponse
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListPresetMenusResponse=} [properties] Properties to set
                 */
                function ListPresetMenusResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListPresetMenusResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @instance
                 */
                ListPresetMenusResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListPresetMenusResponse items.
                 * @member {Array.<mtechnavi.api.uicontroller.IPresetMenu>} items
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @instance
                 */
                ListPresetMenusResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListPresetMenusResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPresetMenusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.uicontroller.PresetMenu.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListPresetMenusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListPresetMenusResponse} ListPresetMenusResponse
                 */
                ListPresetMenusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListPresetMenusResponse)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.ListPresetMenusResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.uicontroller.ListPresetMenusResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.uicontroller.ListPresetMenusResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.uicontroller.PresetMenu.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListPresetMenusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListPresetMenusResponse} message ListPresetMenusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPresetMenusResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.uicontroller.PresetMenu.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListPresetMenusResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListPresetMenusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPresetMenusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPresetMenusResponse;
            })();

            uicontroller.GetPresetMenuRequest = (function() {

                /**
                 * Properties of a GetPresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IGetPresetMenuRequest
                 * @property {string|null} [presetMenuId] GetPresetMenuRequest presetMenuId
                 */

                /**
                 * Constructs a new GetPresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a GetPresetMenuRequest.
                 * @implements IGetPresetMenuRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IGetPresetMenuRequest=} [properties] Properties to set
                 */
                function GetPresetMenuRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPresetMenuRequest presetMenuId.
                 * @member {string} presetMenuId
                 * @memberof mtechnavi.api.uicontroller.GetPresetMenuRequest
                 * @instance
                 */
                GetPresetMenuRequest.prototype.presetMenuId = "";

                /**
                 * Verifies a GetPresetMenuRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.GetPresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPresetMenuRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetMenuId != null && message.hasOwnProperty("presetMenuId"))
                        if (!$util.isString(message.presetMenuId))
                            return "presetMenuId: string expected";
                    return null;
                };

                /**
                 * Creates a GetPresetMenuRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.GetPresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.GetPresetMenuRequest} GetPresetMenuRequest
                 */
                GetPresetMenuRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.GetPresetMenuRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.GetPresetMenuRequest();
                    if (object.presetMenuId != null)
                        message.presetMenuId = String(object.presetMenuId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetPresetMenuRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.GetPresetMenuRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.GetPresetMenuRequest} message GetPresetMenuRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPresetMenuRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetMenuId = "";
                    if (message.presetMenuId != null && message.hasOwnProperty("presetMenuId"))
                        object.presetMenuId = message.presetMenuId;
                    return object;
                };

                /**
                 * Converts this GetPresetMenuRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.GetPresetMenuRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPresetMenuRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPresetMenuRequest;
            })();

            uicontroller.UpdatePresetMenuRequest = (function() {

                /**
                 * Properties of an UpdatePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IUpdatePresetMenuRequest
                 * @property {mtechnavi.api.uicontroller.IPresetMenu|null} [presetMenu] UpdatePresetMenuRequest presetMenu
                 */

                /**
                 * Constructs a new UpdatePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents an UpdatePresetMenuRequest.
                 * @implements IUpdatePresetMenuRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetMenuRequest=} [properties] Properties to set
                 */
                function UpdatePresetMenuRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdatePresetMenuRequest presetMenu.
                 * @member {mtechnavi.api.uicontroller.IPresetMenu|null|undefined} presetMenu
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetMenuRequest
                 * @instance
                 */
                UpdatePresetMenuRequest.prototype.presetMenu = null;

                /**
                 * Verifies an UpdatePresetMenuRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdatePresetMenuRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu")) {
                        let error = $root.mtechnavi.api.uicontroller.PresetMenu.verify(message.presetMenu);
                        if (error)
                            return "presetMenu." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdatePresetMenuRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.UpdatePresetMenuRequest} UpdatePresetMenuRequest
                 */
                UpdatePresetMenuRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.UpdatePresetMenuRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.UpdatePresetMenuRequest();
                    if (object.presetMenu != null) {
                        if (typeof object.presetMenu !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.UpdatePresetMenuRequest.presetMenu: object expected");
                        message.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.fromObject(object.presetMenu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdatePresetMenuRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetMenuRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.UpdatePresetMenuRequest} message UpdatePresetMenuRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdatePresetMenuRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetMenu = null;
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu"))
                        object.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.toObject(message.presetMenu, options);
                    return object;
                };

                /**
                 * Converts this UpdatePresetMenuRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetMenuRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdatePresetMenuRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdatePresetMenuRequest;
            })();

            uicontroller.DeletePresetMenuRequest = (function() {

                /**
                 * Properties of a DeletePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IDeletePresetMenuRequest
                 * @property {mtechnavi.api.uicontroller.IPresetMenu|null} [presetMenu] DeletePresetMenuRequest presetMenu
                 */

                /**
                 * Constructs a new DeletePresetMenuRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a DeletePresetMenuRequest.
                 * @implements IDeletePresetMenuRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IDeletePresetMenuRequest=} [properties] Properties to set
                 */
                function DeletePresetMenuRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeletePresetMenuRequest presetMenu.
                 * @member {mtechnavi.api.uicontroller.IPresetMenu|null|undefined} presetMenu
                 * @memberof mtechnavi.api.uicontroller.DeletePresetMenuRequest
                 * @instance
                 */
                DeletePresetMenuRequest.prototype.presetMenu = null;

                /**
                 * Verifies a DeletePresetMenuRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.DeletePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeletePresetMenuRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu")) {
                        let error = $root.mtechnavi.api.uicontroller.PresetMenu.verify(message.presetMenu);
                        if (error)
                            return "presetMenu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeletePresetMenuRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.DeletePresetMenuRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.DeletePresetMenuRequest} DeletePresetMenuRequest
                 */
                DeletePresetMenuRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.DeletePresetMenuRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.DeletePresetMenuRequest();
                    if (object.presetMenu != null) {
                        if (typeof object.presetMenu !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.DeletePresetMenuRequest.presetMenu: object expected");
                        message.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.fromObject(object.presetMenu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeletePresetMenuRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.DeletePresetMenuRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.DeletePresetMenuRequest} message DeletePresetMenuRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeletePresetMenuRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetMenu = null;
                    if (message.presetMenu != null && message.hasOwnProperty("presetMenu"))
                        object.presetMenu = $root.mtechnavi.api.uicontroller.PresetMenu.toObject(message.presetMenu, options);
                    return object;
                };

                /**
                 * Converts this DeletePresetMenuRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.DeletePresetMenuRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeletePresetMenuRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeletePresetMenuRequest;
            })();

            uicontroller.CreatePresetRequest = (function() {

                /**
                 * Properties of a CreatePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface ICreatePresetRequest
                 * @property {mtechnavi.api.uicontroller.IPreset|null} [preset] CreatePresetRequest preset
                 */

                /**
                 * Constructs a new CreatePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a CreatePresetRequest.
                 * @implements ICreatePresetRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.ICreatePresetRequest=} [properties] Properties to set
                 */
                function CreatePresetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreatePresetRequest preset.
                 * @member {mtechnavi.api.uicontroller.IPreset|null|undefined} preset
                 * @memberof mtechnavi.api.uicontroller.CreatePresetRequest
                 * @instance
                 */
                CreatePresetRequest.prototype.preset = null;

                /**
                 * Verifies a CreatePresetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.CreatePresetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreatePresetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.preset != null && message.hasOwnProperty("preset")) {
                        let error = $root.mtechnavi.api.uicontroller.Preset.verify(message.preset);
                        if (error)
                            return "preset." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreatePresetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.CreatePresetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.CreatePresetRequest} CreatePresetRequest
                 */
                CreatePresetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.CreatePresetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.CreatePresetRequest();
                    if (object.preset != null) {
                        if (typeof object.preset !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.CreatePresetRequest.preset: object expected");
                        message.preset = $root.mtechnavi.api.uicontroller.Preset.fromObject(object.preset);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreatePresetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.CreatePresetRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.CreatePresetRequest} message CreatePresetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreatePresetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.preset = null;
                    if (message.preset != null && message.hasOwnProperty("preset"))
                        object.preset = $root.mtechnavi.api.uicontroller.Preset.toObject(message.preset, options);
                    return object;
                };

                /**
                 * Converts this CreatePresetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.CreatePresetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreatePresetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreatePresetRequest;
            })();

            uicontroller.ListPresetsRequest = (function() {

                /**
                 * Properties of a ListPresetsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListPresetsRequest
                 */

                /**
                 * Constructs a new ListPresetsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListPresetsRequest.
                 * @implements IListPresetsRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListPresetsRequest=} [properties] Properties to set
                 */
                function ListPresetsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a ListPresetsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListPresetsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPresetsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListPresetsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListPresetsRequest} ListPresetsRequest
                 */
                ListPresetsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListPresetsRequest)
                        return object;
                    return new $root.mtechnavi.api.uicontroller.ListPresetsRequest();
                };

                /**
                 * Creates a plain object from a ListPresetsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetsRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListPresetsRequest} message ListPresetsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPresetsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListPresetsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListPresetsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPresetsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPresetsRequest;
            })();

            uicontroller.ListPresetsResponse = (function() {

                /**
                 * Properties of a ListPresetsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListPresetsResponse
                 * @property {Long|null} [total] ListPresetsResponse total
                 * @property {Array.<mtechnavi.api.uicontroller.IPreset>|null} [items] ListPresetsResponse items
                 */

                /**
                 * Constructs a new ListPresetsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListPresetsResponse.
                 * @implements IListPresetsResponse
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListPresetsResponse=} [properties] Properties to set
                 */
                function ListPresetsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListPresetsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @instance
                 */
                ListPresetsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListPresetsResponse items.
                 * @member {Array.<mtechnavi.api.uicontroller.IPreset>} items
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @instance
                 */
                ListPresetsResponse.prototype.items = $util.emptyArray;

                /**
                 * Verifies a ListPresetsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPresetsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.uicontroller.Preset.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListPresetsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListPresetsResponse} ListPresetsResponse
                 */
                ListPresetsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListPresetsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.ListPresetsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.uicontroller.ListPresetsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.uicontroller.ListPresetsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.uicontroller.Preset.fromObject(object.items[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListPresetsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListPresetsResponse} message ListPresetsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPresetsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults)
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.uicontroller.Preset.toObject(message.items[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListPresetsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListPresetsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPresetsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPresetsResponse;
            })();

            uicontroller.GetPresetRequest = (function() {

                /**
                 * Properties of a GetPresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IGetPresetRequest
                 * @property {string|null} [presetId] GetPresetRequest presetId
                 */

                /**
                 * Constructs a new GetPresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a GetPresetRequest.
                 * @implements IGetPresetRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IGetPresetRequest=} [properties] Properties to set
                 */
                function GetPresetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPresetRequest presetId.
                 * @member {string} presetId
                 * @memberof mtechnavi.api.uicontroller.GetPresetRequest
                 * @instance
                 */
                GetPresetRequest.prototype.presetId = "";

                /**
                 * Verifies a GetPresetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.GetPresetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPresetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        if (!$util.isString(message.presetId))
                            return "presetId: string expected";
                    return null;
                };

                /**
                 * Creates a GetPresetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.GetPresetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.GetPresetRequest} GetPresetRequest
                 */
                GetPresetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.GetPresetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.GetPresetRequest();
                    if (object.presetId != null)
                        message.presetId = String(object.presetId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetPresetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.GetPresetRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.GetPresetRequest} message GetPresetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPresetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetId = "";
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        object.presetId = message.presetId;
                    return object;
                };

                /**
                 * Converts this GetPresetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.GetPresetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPresetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPresetRequest;
            })();

            uicontroller.UpdatePresetRequest = (function() {

                /**
                 * Properties of an UpdatePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IUpdatePresetRequest
                 * @property {mtechnavi.api.uicontroller.IPreset|null} [preset] UpdatePresetRequest preset
                 */

                /**
                 * Constructs a new UpdatePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents an UpdatePresetRequest.
                 * @implements IUpdatePresetRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IUpdatePresetRequest=} [properties] Properties to set
                 */
                function UpdatePresetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdatePresetRequest preset.
                 * @member {mtechnavi.api.uicontroller.IPreset|null|undefined} preset
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetRequest
                 * @instance
                 */
                UpdatePresetRequest.prototype.preset = null;

                /**
                 * Verifies an UpdatePresetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdatePresetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.preset != null && message.hasOwnProperty("preset")) {
                        let error = $root.mtechnavi.api.uicontroller.Preset.verify(message.preset);
                        if (error)
                            return "preset." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdatePresetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.UpdatePresetRequest} UpdatePresetRequest
                 */
                UpdatePresetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.UpdatePresetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.UpdatePresetRequest();
                    if (object.preset != null) {
                        if (typeof object.preset !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.UpdatePresetRequest.preset: object expected");
                        message.preset = $root.mtechnavi.api.uicontroller.Preset.fromObject(object.preset);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdatePresetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.UpdatePresetRequest} message UpdatePresetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdatePresetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.preset = null;
                    if (message.preset != null && message.hasOwnProperty("preset"))
                        object.preset = $root.mtechnavi.api.uicontroller.Preset.toObject(message.preset, options);
                    return object;
                };

                /**
                 * Converts this UpdatePresetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.UpdatePresetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdatePresetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdatePresetRequest;
            })();

            uicontroller.DeletePresetRequest = (function() {

                /**
                 * Properties of a DeletePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IDeletePresetRequest
                 * @property {mtechnavi.api.uicontroller.IPreset|null} [preset] DeletePresetRequest preset
                 */

                /**
                 * Constructs a new DeletePresetRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a DeletePresetRequest.
                 * @implements IDeletePresetRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IDeletePresetRequest=} [properties] Properties to set
                 */
                function DeletePresetRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeletePresetRequest preset.
                 * @member {mtechnavi.api.uicontroller.IPreset|null|undefined} preset
                 * @memberof mtechnavi.api.uicontroller.DeletePresetRequest
                 * @instance
                 */
                DeletePresetRequest.prototype.preset = null;

                /**
                 * Verifies a DeletePresetRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.DeletePresetRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeletePresetRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.preset != null && message.hasOwnProperty("preset")) {
                        let error = $root.mtechnavi.api.uicontroller.Preset.verify(message.preset);
                        if (error)
                            return "preset." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeletePresetRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.DeletePresetRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.DeletePresetRequest} DeletePresetRequest
                 */
                DeletePresetRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.DeletePresetRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.DeletePresetRequest();
                    if (object.preset != null) {
                        if (typeof object.preset !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.DeletePresetRequest.preset: object expected");
                        message.preset = $root.mtechnavi.api.uicontroller.Preset.fromObject(object.preset);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeletePresetRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.DeletePresetRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.DeletePresetRequest} message DeletePresetRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeletePresetRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.preset = null;
                    if (message.preset != null && message.hasOwnProperty("preset"))
                        object.preset = $root.mtechnavi.api.uicontroller.Preset.toObject(message.preset, options);
                    return object;
                };

                /**
                 * Converts this DeletePresetRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.DeletePresetRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeletePresetRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeletePresetRequest;
            })();

            uicontroller.SaveUserSettingRequest = (function() {

                /**
                 * Properties of a SaveUserSettingRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface ISaveUserSettingRequest
                 * @property {mtechnavi.api.uicontroller.IUserSetting|null} [userSetting] SaveUserSettingRequest userSetting
                 */

                /**
                 * Constructs a new SaveUserSettingRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a SaveUserSettingRequest.
                 * @implements ISaveUserSettingRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.ISaveUserSettingRequest=} [properties] Properties to set
                 */
                function SaveUserSettingRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SaveUserSettingRequest userSetting.
                 * @member {mtechnavi.api.uicontroller.IUserSetting|null|undefined} userSetting
                 * @memberof mtechnavi.api.uicontroller.SaveUserSettingRequest
                 * @instance
                 */
                SaveUserSettingRequest.prototype.userSetting = null;

                /**
                 * Verifies a SaveUserSettingRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.SaveUserSettingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SaveUserSettingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userSetting != null && message.hasOwnProperty("userSetting")) {
                        let error = $root.mtechnavi.api.uicontroller.UserSetting.verify(message.userSetting);
                        if (error)
                            return "userSetting." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SaveUserSettingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.SaveUserSettingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.SaveUserSettingRequest} SaveUserSettingRequest
                 */
                SaveUserSettingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.SaveUserSettingRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.SaveUserSettingRequest();
                    if (object.userSetting != null) {
                        if (typeof object.userSetting !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.SaveUserSettingRequest.userSetting: object expected");
                        message.userSetting = $root.mtechnavi.api.uicontroller.UserSetting.fromObject(object.userSetting);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SaveUserSettingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.SaveUserSettingRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.SaveUserSettingRequest} message SaveUserSettingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SaveUserSettingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userSetting = null;
                    if (message.userSetting != null && message.hasOwnProperty("userSetting"))
                        object.userSetting = $root.mtechnavi.api.uicontroller.UserSetting.toObject(message.userSetting, options);
                    return object;
                };

                /**
                 * Converts this SaveUserSettingRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.SaveUserSettingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SaveUserSettingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SaveUserSettingRequest;
            })();

            uicontroller.ListUserSettingsRequest = (function() {

                /**
                 * Properties of a ListUserSettingsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListUserSettingsRequest
                 * @property {string|null} [pageToken] ListUserSettingsRequest pageToken
                 * @property {string|null} [tag] ListUserSettingsRequest tag
                 */

                /**
                 * Constructs a new ListUserSettingsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListUserSettingsRequest.
                 * @implements IListUserSettingsRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListUserSettingsRequest=} [properties] Properties to set
                 */
                function ListUserSettingsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserSettingsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @instance
                 */
                ListUserSettingsRequest.prototype.pageToken = "";

                /**
                 * ListUserSettingsRequest tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @instance
                 */
                ListUserSettingsRequest.prototype.tag = "";

                /**
                 * Verifies a ListUserSettingsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserSettingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserSettingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListUserSettingsRequest} ListUserSettingsRequest
                 */
                ListUserSettingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListUserSettingsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.ListUserSettingsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserSettingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListUserSettingsRequest} message ListUserSettingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserSettingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.tag = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    return object;
                };

                /**
                 * Converts this ListUserSettingsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserSettingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserSettingsRequest;
            })();

            uicontroller.ListUserSettingsResponse = (function() {

                /**
                 * Properties of a ListUserSettingsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IListUserSettingsResponse
                 * @property {Long|null} [total] ListUserSettingsResponse total
                 * @property {Array.<mtechnavi.api.uicontroller.IUserSetting>|null} [items] ListUserSettingsResponse items
                 * @property {string|null} [pageToken] ListUserSettingsResponse pageToken
                 */

                /**
                 * Constructs a new ListUserSettingsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a ListUserSettingsResponse.
                 * @implements IListUserSettingsResponse
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IListUserSettingsResponse=} [properties] Properties to set
                 */
                function ListUserSettingsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListUserSettingsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @instance
                 */
                ListUserSettingsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListUserSettingsResponse items.
                 * @member {Array.<mtechnavi.api.uicontroller.IUserSetting>} items
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @instance
                 */
                ListUserSettingsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListUserSettingsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @instance
                 */
                ListUserSettingsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListUserSettingsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListUserSettingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.uicontroller.UserSetting.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListUserSettingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.ListUserSettingsResponse} ListUserSettingsResponse
                 */
                ListUserSettingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.ListUserSettingsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.ListUserSettingsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.uicontroller.ListUserSettingsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.uicontroller.ListUserSettingsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.uicontroller.UserSetting.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListUserSettingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @static
                 * @param {mtechnavi.api.uicontroller.ListUserSettingsResponse} message ListUserSettingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListUserSettingsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.uicontroller.UserSetting.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListUserSettingsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.ListUserSettingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListUserSettingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListUserSettingsResponse;
            })();

            uicontroller.DeleteUserSettingRequest = (function() {

                /**
                 * Properties of a DeleteUserSettingRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IDeleteUserSettingRequest
                 * @property {mtechnavi.api.uicontroller.IUserSetting|null} [userSetting] DeleteUserSettingRequest userSetting
                 */

                /**
                 * Constructs a new DeleteUserSettingRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a DeleteUserSettingRequest.
                 * @implements IDeleteUserSettingRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IDeleteUserSettingRequest=} [properties] Properties to set
                 */
                function DeleteUserSettingRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteUserSettingRequest userSetting.
                 * @member {mtechnavi.api.uicontroller.IUserSetting|null|undefined} userSetting
                 * @memberof mtechnavi.api.uicontroller.DeleteUserSettingRequest
                 * @instance
                 */
                DeleteUserSettingRequest.prototype.userSetting = null;

                /**
                 * Verifies a DeleteUserSettingRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.DeleteUserSettingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteUserSettingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userSetting != null && message.hasOwnProperty("userSetting")) {
                        let error = $root.mtechnavi.api.uicontroller.UserSetting.verify(message.userSetting);
                        if (error)
                            return "userSetting." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteUserSettingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.DeleteUserSettingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.DeleteUserSettingRequest} DeleteUserSettingRequest
                 */
                DeleteUserSettingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.DeleteUserSettingRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.DeleteUserSettingRequest();
                    if (object.userSetting != null) {
                        if (typeof object.userSetting !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.DeleteUserSettingRequest.userSetting: object expected");
                        message.userSetting = $root.mtechnavi.api.uicontroller.UserSetting.fromObject(object.userSetting);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteUserSettingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.DeleteUserSettingRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.DeleteUserSettingRequest} message DeleteUserSettingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteUserSettingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.userSetting = null;
                    if (message.userSetting != null && message.hasOwnProperty("userSetting"))
                        object.userSetting = $root.mtechnavi.api.uicontroller.UserSetting.toObject(message.userSetting, options);
                    return object;
                };

                /**
                 * Converts this DeleteUserSettingRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.DeleteUserSettingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteUserSettingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteUserSettingRequest;
            })();

            uicontroller.PublicListInformationsRequest = (function() {

                /**
                 * Properties of a PublicListInformationsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPublicListInformationsRequest
                 * @property {string|null} [pageToken] PublicListInformationsRequest pageToken
                 */

                /**
                 * Constructs a new PublicListInformationsRequest.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PublicListInformationsRequest.
                 * @implements IPublicListInformationsRequest
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPublicListInformationsRequest=} [properties] Properties to set
                 */
                function PublicListInformationsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListInformationsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsRequest
                 * @instance
                 */
                PublicListInformationsRequest.prototype.pageToken = "";

                /**
                 * Verifies a PublicListInformationsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListInformationsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a PublicListInformationsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.PublicListInformationsRequest} PublicListInformationsRequest
                 */
                PublicListInformationsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.PublicListInformationsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.PublicListInformationsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListInformationsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsRequest
                 * @static
                 * @param {mtechnavi.api.uicontroller.PublicListInformationsRequest} message PublicListInformationsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListInformationsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this PublicListInformationsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListInformationsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListInformationsRequest;
            })();

            uicontroller.PublicListInformationsResponse = (function() {

                /**
                 * Properties of a PublicListInformationsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPublicListInformationsResponse
                 * @property {Long|null} [total] PublicListInformationsResponse total
                 * @property {Array.<mtechnavi.api.admin.IInformation>|null} [items] PublicListInformationsResponse items
                 * @property {string|null} [pageToken] PublicListInformationsResponse pageToken
                 */

                /**
                 * Constructs a new PublicListInformationsResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PublicListInformationsResponse.
                 * @implements IPublicListInformationsResponse
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPublicListInformationsResponse=} [properties] Properties to set
                 */
                function PublicListInformationsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PublicListInformationsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @instance
                 */
                PublicListInformationsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PublicListInformationsResponse items.
                 * @member {Array.<mtechnavi.api.admin.IInformation>} items
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @instance
                 */
                PublicListInformationsResponse.prototype.items = $util.emptyArray;

                /**
                 * PublicListInformationsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @instance
                 */
                PublicListInformationsResponse.prototype.pageToken = "";

                /**
                 * Verifies a PublicListInformationsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PublicListInformationsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.admin.Information.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a PublicListInformationsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.PublicListInformationsResponse} PublicListInformationsResponse
                 */
                PublicListInformationsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.PublicListInformationsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.PublicListInformationsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.uicontroller.PublicListInformationsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.uicontroller.PublicListInformationsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.admin.Information.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a PublicListInformationsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @static
                 * @param {mtechnavi.api.uicontroller.PublicListInformationsResponse} message PublicListInformationsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PublicListInformationsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.admin.Information.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this PublicListInformationsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.PublicListInformationsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PublicListInformationsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PublicListInformationsResponse;
            })();

            uicontroller.GetTaskListSummaryResponse = (function() {

                /**
                 * Properties of a GetTaskListSummaryResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IGetTaskListSummaryResponse
                 * @property {boolean|null} [taskListExists] GetTaskListSummaryResponse taskListExists
                 */

                /**
                 * Constructs a new GetTaskListSummaryResponse.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a GetTaskListSummaryResponse.
                 * @implements IGetTaskListSummaryResponse
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IGetTaskListSummaryResponse=} [properties] Properties to set
                 */
                function GetTaskListSummaryResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetTaskListSummaryResponse taskListExists.
                 * @member {boolean} taskListExists
                 * @memberof mtechnavi.api.uicontroller.GetTaskListSummaryResponse
                 * @instance
                 */
                GetTaskListSummaryResponse.prototype.taskListExists = false;

                /**
                 * Verifies a GetTaskListSummaryResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.GetTaskListSummaryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetTaskListSummaryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.taskListExists != null && message.hasOwnProperty("taskListExists"))
                        if (typeof message.taskListExists !== "boolean")
                            return "taskListExists: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetTaskListSummaryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.GetTaskListSummaryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.GetTaskListSummaryResponse} GetTaskListSummaryResponse
                 */
                GetTaskListSummaryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.GetTaskListSummaryResponse)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.GetTaskListSummaryResponse();
                    if (object.taskListExists != null)
                        message.taskListExists = Boolean(object.taskListExists);
                    return message;
                };

                /**
                 * Creates a plain object from a GetTaskListSummaryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.GetTaskListSummaryResponse
                 * @static
                 * @param {mtechnavi.api.uicontroller.GetTaskListSummaryResponse} message GetTaskListSummaryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetTaskListSummaryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.taskListExists = false;
                    if (message.taskListExists != null && message.hasOwnProperty("taskListExists"))
                        object.taskListExists = message.taskListExists;
                    return object;
                };

                /**
                 * Converts this GetTaskListSummaryResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.GetTaskListSummaryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetTaskListSummaryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetTaskListSummaryResponse;
            })();

            uicontroller.UserSetting = (function() {

                /**
                 * Properties of a UserSetting.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IUserSetting
                 * @property {string|null} [userSettingId] UserSetting userSettingId
                 * @property {string|null} [userId] UserSetting userId
                 * @property {string|null} [tag] UserSetting tag
                 * @property {string|null} [type] UserSetting type
                 * @property {string|null} [displayName] UserSetting displayName
                 * @property {string|null} [recordData] UserSetting recordData
                 * @property {Long|null} [createdAt] UserSetting createdAt
                 * @property {Long|null} [updatedAt] UserSetting updatedAt
                 * @property {Long|null} [deletedAt] UserSetting deletedAt
                 */

                /**
                 * Constructs a new UserSetting.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a UserSetting.
                 * @implements IUserSetting
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IUserSetting=} [properties] Properties to set
                 */
                function UserSetting(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserSetting userSettingId.
                 * @member {string} userSettingId
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.userSettingId = "";

                /**
                 * UserSetting userId.
                 * @member {string} userId
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.userId = "";

                /**
                 * UserSetting tag.
                 * @member {string} tag
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.tag = "";

                /**
                 * UserSetting type.
                 * @member {string} type
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.type = "";

                /**
                 * UserSetting displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.displayName = "";

                /**
                 * UserSetting recordData.
                 * @member {string} recordData
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.recordData = "";

                /**
                 * UserSetting createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserSetting updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UserSetting deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 */
                UserSetting.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a UserSetting message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserSetting.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userSettingId != null && message.hasOwnProperty("userSettingId"))
                        if (!$util.isString(message.userSettingId))
                            return "userSettingId: string expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        if (!$util.isString(message.tag))
                            return "tag: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.recordData != null && message.hasOwnProperty("recordData"))
                        if (!$util.isString(message.recordData))
                            return "recordData: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a UserSetting message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.UserSetting} UserSetting
                 */
                UserSetting.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.UserSetting)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.UserSetting();
                    if (object.userSettingId != null)
                        message.userSettingId = String(object.userSettingId);
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.tag != null)
                        message.tag = String(object.tag);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.recordData != null)
                        message.recordData = String(object.recordData);
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a UserSetting message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @static
                 * @param {mtechnavi.api.uicontroller.UserSetting} message UserSetting
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserSetting.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.userSettingId = "";
                        object.userId = "";
                        object.tag = "";
                        object.type = "";
                        object.displayName = "";
                        object.recordData = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.userSettingId != null && message.hasOwnProperty("userSettingId"))
                        object.userSettingId = message.userSettingId;
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.tag != null && message.hasOwnProperty("tag"))
                        object.tag = message.tag;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.recordData != null && message.hasOwnProperty("recordData"))
                        object.recordData = message.recordData;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this UserSetting to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.UserSetting
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserSetting.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserSetting;
            })();

            uicontroller.PresetMenu = (function() {

                /**
                 * Properties of a PresetMenu.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPresetMenu
                 * @property {string|null} [presetMenuId] PresetMenu presetMenuId
                 * @property {string|null} [parentPresetMenuId] PresetMenu parentPresetMenuId
                 * @property {mtechnavi.api.uicontroller.IPresetMenuContainer|null} [container] PresetMenu container
                 * @property {mtechnavi.api.uicontroller.IPresetMenuItem|null} [item] PresetMenu item
                 * @property {Long|null} [deletedAt] PresetMenu deletedAt
                 * @property {Long|null} [createdAt] PresetMenu createdAt
                 * @property {Long|null} [updatedAt] PresetMenu updatedAt
                 */

                /**
                 * Constructs a new PresetMenu.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PresetMenu.
                 * @implements IPresetMenu
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPresetMenu=} [properties] Properties to set
                 */
                function PresetMenu(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PresetMenu presetMenuId.
                 * @member {string} presetMenuId
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.presetMenuId = "";

                /**
                 * PresetMenu parentPresetMenuId.
                 * @member {string} parentPresetMenuId
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.parentPresetMenuId = "";

                /**
                 * PresetMenu container.
                 * @member {mtechnavi.api.uicontroller.IPresetMenuContainer|null|undefined} container
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.container = null;

                /**
                 * PresetMenu item.
                 * @member {mtechnavi.api.uicontroller.IPresetMenuItem|null|undefined} item
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.item = null;

                /**
                 * PresetMenu deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PresetMenu createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * PresetMenu updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                PresetMenu.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                // OneOf field names bound to virtual getters and setters
                let $oneOfFields;

                /**
                 * PresetMenu value.
                 * @member {"container"|"item"|undefined} value
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 */
                Object.defineProperty(PresetMenu.prototype, "value", {
                    get: $util.oneOfGetter($oneOfFields = ["container", "item"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Verifies a PresetMenu message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PresetMenu.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    let properties = {};
                    if (message.presetMenuId != null && message.hasOwnProperty("presetMenuId"))
                        if (!$util.isString(message.presetMenuId))
                            return "presetMenuId: string expected";
                    if (message.parentPresetMenuId != null && message.hasOwnProperty("parentPresetMenuId"))
                        if (!$util.isString(message.parentPresetMenuId))
                            return "parentPresetMenuId: string expected";
                    if (message.container != null && message.hasOwnProperty("container")) {
                        properties.value = 1;
                        {
                            let error = $root.mtechnavi.api.uicontroller.PresetMenuContainer.verify(message.container);
                            if (error)
                                return "container." + error;
                        }
                    }
                    if (message.item != null && message.hasOwnProperty("item")) {
                        if (properties.value === 1)
                            return "value: multiple values";
                        properties.value = 1;
                        {
                            let error = $root.mtechnavi.api.uicontroller.PresetMenuItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a PresetMenu message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.PresetMenu} PresetMenu
                 */
                PresetMenu.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.PresetMenu)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.PresetMenu();
                    if (object.presetMenuId != null)
                        message.presetMenuId = String(object.presetMenuId);
                    if (object.parentPresetMenuId != null)
                        message.parentPresetMenuId = String(object.parentPresetMenuId);
                    if (object.container != null) {
                        if (typeof object.container !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.PresetMenu.container: object expected");
                        message.container = $root.mtechnavi.api.uicontroller.PresetMenuContainer.fromObject(object.container);
                    }
                    if (object.item != null) {
                        if (typeof object.item !== "object")
                            throw TypeError(".mtechnavi.api.uicontroller.PresetMenu.item: object expected");
                        message.item = $root.mtechnavi.api.uicontroller.PresetMenuItem.fromObject(object.item);
                    }
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a PresetMenu message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @static
                 * @param {mtechnavi.api.uicontroller.PresetMenu} message PresetMenu
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PresetMenu.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.presetMenuId = "";
                        object.parentPresetMenuId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.presetMenuId != null && message.hasOwnProperty("presetMenuId"))
                        object.presetMenuId = message.presetMenuId;
                    if (message.parentPresetMenuId != null && message.hasOwnProperty("parentPresetMenuId"))
                        object.parentPresetMenuId = message.parentPresetMenuId;
                    if (message.container != null && message.hasOwnProperty("container")) {
                        object.container = $root.mtechnavi.api.uicontroller.PresetMenuContainer.toObject(message.container, options);
                        if (options.oneofs)
                            object.value = "container";
                    }
                    if (message.item != null && message.hasOwnProperty("item")) {
                        object.item = $root.mtechnavi.api.uicontroller.PresetMenuItem.toObject(message.item, options);
                        if (options.oneofs)
                            object.value = "item";
                    }
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this PresetMenu to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.PresetMenu
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PresetMenu.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PresetMenu;
            })();

            uicontroller.PresetMenuContainer = (function() {

                /**
                 * Properties of a PresetMenuContainer.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPresetMenuContainer
                 * @property {string|null} [displayName] PresetMenuContainer displayName
                 * @property {Array.<string>|null} [childrenPresetMenuIds] PresetMenuContainer childrenPresetMenuIds
                 */

                /**
                 * Constructs a new PresetMenuContainer.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PresetMenuContainer.
                 * @implements IPresetMenuContainer
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPresetMenuContainer=} [properties] Properties to set
                 */
                function PresetMenuContainer(properties) {
                    this.childrenPresetMenuIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PresetMenuContainer displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @instance
                 */
                PresetMenuContainer.prototype.displayName = "";

                /**
                 * PresetMenuContainer childrenPresetMenuIds.
                 * @member {Array.<string>} childrenPresetMenuIds
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @instance
                 */
                PresetMenuContainer.prototype.childrenPresetMenuIds = $util.emptyArray;

                /**
                 * Verifies a PresetMenuContainer message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PresetMenuContainer.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.childrenPresetMenuIds != null && message.hasOwnProperty("childrenPresetMenuIds")) {
                        if (!Array.isArray(message.childrenPresetMenuIds))
                            return "childrenPresetMenuIds: array expected";
                        for (let i = 0; i < message.childrenPresetMenuIds.length; ++i)
                            if (!$util.isString(message.childrenPresetMenuIds[i]))
                                return "childrenPresetMenuIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a PresetMenuContainer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.PresetMenuContainer} PresetMenuContainer
                 */
                PresetMenuContainer.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.PresetMenuContainer)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.PresetMenuContainer();
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.childrenPresetMenuIds) {
                        if (!Array.isArray(object.childrenPresetMenuIds))
                            throw TypeError(".mtechnavi.api.uicontroller.PresetMenuContainer.childrenPresetMenuIds: array expected");
                        message.childrenPresetMenuIds = [];
                        for (let i = 0; i < object.childrenPresetMenuIds.length; ++i)
                            message.childrenPresetMenuIds[i] = String(object.childrenPresetMenuIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PresetMenuContainer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @static
                 * @param {mtechnavi.api.uicontroller.PresetMenuContainer} message PresetMenuContainer
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PresetMenuContainer.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.childrenPresetMenuIds = [];
                    if (options.defaults)
                        object.displayName = "";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.childrenPresetMenuIds && message.childrenPresetMenuIds.length) {
                        object.childrenPresetMenuIds = [];
                        for (let j = 0; j < message.childrenPresetMenuIds.length; ++j)
                            object.childrenPresetMenuIds[j] = message.childrenPresetMenuIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this PresetMenuContainer to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.PresetMenuContainer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PresetMenuContainer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PresetMenuContainer;
            })();

            uicontroller.PresetMenuItem = (function() {

                /**
                 * Properties of a PresetMenuItem.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPresetMenuItem
                 * @property {string|null} [presetId] PresetMenuItem presetId
                 */

                /**
                 * Constructs a new PresetMenuItem.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a PresetMenuItem.
                 * @implements IPresetMenuItem
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPresetMenuItem=} [properties] Properties to set
                 */
                function PresetMenuItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PresetMenuItem presetId.
                 * @member {string} presetId
                 * @memberof mtechnavi.api.uicontroller.PresetMenuItem
                 * @instance
                 */
                PresetMenuItem.prototype.presetId = "";

                /**
                 * Verifies a PresetMenuItem message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.PresetMenuItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PresetMenuItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        if (!$util.isString(message.presetId))
                            return "presetId: string expected";
                    return null;
                };

                /**
                 * Creates a PresetMenuItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenuItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.PresetMenuItem} PresetMenuItem
                 */
                PresetMenuItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.PresetMenuItem)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.PresetMenuItem();
                    if (object.presetId != null)
                        message.presetId = String(object.presetId);
                    return message;
                };

                /**
                 * Creates a plain object from a PresetMenuItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.PresetMenuItem
                 * @static
                 * @param {mtechnavi.api.uicontroller.PresetMenuItem} message PresetMenuItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PresetMenuItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.presetId = "";
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        object.presetId = message.presetId;
                    return object;
                };

                /**
                 * Converts this PresetMenuItem to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.PresetMenuItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PresetMenuItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PresetMenuItem;
            })();

            uicontroller.Preset = (function() {

                /**
                 * Properties of a Preset.
                 * @memberof mtechnavi.api.uicontroller
                 * @interface IPreset
                 * @property {string|null} [presetId] Preset presetId
                 * @property {string|null} [displayName] Preset displayName
                 * @property {string|null} [viewId] Preset viewId
                 * @property {Array.<string>|null} [userGroupCodes] Preset userGroupCodes
                 * @property {Uint8Array|null} [internalData] Preset internalData
                 * @property {Long|null} [deletedAt] Preset deletedAt
                 * @property {Long|null} [createdAt] Preset createdAt
                 * @property {Long|null} [updatedAt] Preset updatedAt
                 */

                /**
                 * Constructs a new Preset.
                 * @memberof mtechnavi.api.uicontroller
                 * @classdesc Represents a Preset.
                 * @implements IPreset
                 * @constructor
                 * @param {mtechnavi.api.uicontroller.IPreset=} [properties] Properties to set
                 */
                function Preset(properties) {
                    this.userGroupCodes = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Preset presetId.
                 * @member {string} presetId
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.presetId = "";

                /**
                 * Preset displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.displayName = "";

                /**
                 * Preset viewId.
                 * @member {string} viewId
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.viewId = "";

                /**
                 * Preset userGroupCodes.
                 * @member {Array.<string>} userGroupCodes
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.userGroupCodes = $util.emptyArray;

                /**
                 * Preset internalData.
                 * @member {Uint8Array} internalData
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.internalData = $util.newBuffer([]);

                /**
                 * Preset deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Preset createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Preset updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 */
                Preset.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a Preset message.
                 * @function verify
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Preset.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        if (!$util.isString(message.presetId))
                            return "presetId: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.viewId != null && message.hasOwnProperty("viewId"))
                        if (!$util.isString(message.viewId))
                            return "viewId: string expected";
                    if (message.userGroupCodes != null && message.hasOwnProperty("userGroupCodes")) {
                        if (!Array.isArray(message.userGroupCodes))
                            return "userGroupCodes: array expected";
                        for (let i = 0; i < message.userGroupCodes.length; ++i)
                            if (!$util.isString(message.userGroupCodes[i]))
                                return "userGroupCodes: string[] expected";
                    }
                    if (message.internalData != null && message.hasOwnProperty("internalData"))
                        if (!(message.internalData && typeof message.internalData.length === "number" || $util.isString(message.internalData)))
                            return "internalData: buffer expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Preset message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.uicontroller.Preset} Preset
                 */
                Preset.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.uicontroller.Preset)
                        return object;
                    let message = new $root.mtechnavi.api.uicontroller.Preset();
                    if (object.presetId != null)
                        message.presetId = String(object.presetId);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.viewId != null)
                        message.viewId = String(object.viewId);
                    if (object.userGroupCodes) {
                        if (!Array.isArray(object.userGroupCodes))
                            throw TypeError(".mtechnavi.api.uicontroller.Preset.userGroupCodes: array expected");
                        message.userGroupCodes = [];
                        for (let i = 0; i < object.userGroupCodes.length; ++i)
                            message.userGroupCodes[i] = String(object.userGroupCodes[i]);
                    }
                    if (object.internalData != null)
                        if (typeof object.internalData === "string")
                            $util.base64.decode(object.internalData, message.internalData = $util.newBuffer($util.base64.length(object.internalData)), 0);
                        else if (object.internalData.length)
                            message.internalData = object.internalData;
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Preset message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @static
                 * @param {mtechnavi.api.uicontroller.Preset} message Preset
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Preset.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.userGroupCodes = [];
                    if (options.defaults) {
                        object.presetId = "";
                        object.displayName = "";
                        object.viewId = "";
                        if (options.bytes === String)
                            object.internalData = "";
                        else {
                            object.internalData = [];
                            if (options.bytes !== Array)
                                object.internalData = $util.newBuffer(object.internalData);
                        }
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.presetId != null && message.hasOwnProperty("presetId"))
                        object.presetId = message.presetId;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.viewId != null && message.hasOwnProperty("viewId"))
                        object.viewId = message.viewId;
                    if (message.userGroupCodes && message.userGroupCodes.length) {
                        object.userGroupCodes = [];
                        for (let j = 0; j < message.userGroupCodes.length; ++j)
                            object.userGroupCodes[j] = message.userGroupCodes[j];
                    }
                    if (message.internalData != null && message.hasOwnProperty("internalData"))
                        object.internalData = options.bytes === String ? $util.base64.encode(message.internalData, 0, message.internalData.length) : options.bytes === Array ? Array.prototype.slice.call(message.internalData) : message.internalData;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    return object;
                };

                /**
                 * Converts this Preset to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.uicontroller.Preset
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Preset.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Preset;
            })();

            return uicontroller;
        })();

        api.worktask = (function() {

            /**
             * Namespace worktask.
             * @memberof mtechnavi.api
             * @namespace
             */
            const worktask = {};

            worktask.WorkTaskService = (function() {

                /**
                 * Constructs a new WorkTaskService service.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTaskService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function WorkTaskService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (WorkTaskService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WorkTaskService;

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#listHistorys}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ListHistorysCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ListHistorysResponse} [response] ListHistorysResponse
                 */

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ListHistorysCallback} callback Node-style callback called with the error, if any, and ListHistorysResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.listHistorys = function listHistorys(request, callback) {
                    return this.rpcCall(listHistorys, $root.mtechnavi.api.worktask.ListHistorysRequest, $root.mtechnavi.api.worktask.ListHistorysResponse, request, callback);
                }, "name", { value: "ListHistorys" });

                /**
                 * Calls ListHistorys.
                 * @function listHistorys
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListHistorysRequest} request ListHistorysRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ListHistorysResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#createWorkTaskCatalog}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef CreateWorkTaskCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTaskCatalog} [response] WorkTaskCatalog
                 */

                /**
                 * Calls CreateWorkTaskCatalog.
                 * @function createWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskCatalogRequest} request CreateWorkTaskCatalogRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.CreateWorkTaskCatalogCallback} callback Node-style callback called with the error, if any, and WorkTaskCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.createWorkTaskCatalog = function createWorkTaskCatalog(request, callback) {
                    return this.rpcCall(createWorkTaskCatalog, $root.mtechnavi.api.worktask.CreateWorkTaskCatalogRequest, $root.mtechnavi.api.worktask.WorkTaskCatalog, request, callback);
                }, "name", { value: "CreateWorkTaskCatalog" });

                /**
                 * Calls CreateWorkTaskCatalog.
                 * @function createWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskCatalogRequest} request CreateWorkTaskCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTaskCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#listWorkTaskCatalogs}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ListWorkTaskCatalogsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ListWorkTaskCatalogResponse} [response] ListWorkTaskCatalogResponse
                 */

                /**
                 * Calls ListWorkTaskCatalogs.
                 * @function listWorkTaskCatalogs
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskCatalogsRequest} request ListWorkTaskCatalogsRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ListWorkTaskCatalogsCallback} callback Node-style callback called with the error, if any, and ListWorkTaskCatalogResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.listWorkTaskCatalogs = function listWorkTaskCatalogs(request, callback) {
                    return this.rpcCall(listWorkTaskCatalogs, $root.mtechnavi.api.worktask.ListWorkTaskCatalogsRequest, $root.mtechnavi.api.worktask.ListWorkTaskCatalogResponse, request, callback);
                }, "name", { value: "ListWorkTaskCatalogs" });

                /**
                 * Calls ListWorkTaskCatalogs.
                 * @function listWorkTaskCatalogs
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskCatalogsRequest} request ListWorkTaskCatalogsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ListWorkTaskCatalogResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#getWorkTaskCatalog}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef GetWorkTaskCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTaskCatalog} [response] WorkTaskCatalog
                 */

                /**
                 * Calls GetWorkTaskCatalog.
                 * @function getWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskCatalogRequest} request GetWorkTaskCatalogRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.GetWorkTaskCatalogCallback} callback Node-style callback called with the error, if any, and WorkTaskCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.getWorkTaskCatalog = function getWorkTaskCatalog(request, callback) {
                    return this.rpcCall(getWorkTaskCatalog, $root.mtechnavi.api.worktask.GetWorkTaskCatalogRequest, $root.mtechnavi.api.worktask.WorkTaskCatalog, request, callback);
                }, "name", { value: "GetWorkTaskCatalog" });

                /**
                 * Calls GetWorkTaskCatalog.
                 * @function getWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskCatalogRequest} request GetWorkTaskCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTaskCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#updateWorkTaskCatalog}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef UpdateWorkTaskCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTaskCatalog} [response] WorkTaskCatalog
                 */

                /**
                 * Calls UpdateWorkTaskCatalog.
                 * @function updateWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskCatalogRequest} request UpdateWorkTaskCatalogRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.UpdateWorkTaskCatalogCallback} callback Node-style callback called with the error, if any, and WorkTaskCatalog
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.updateWorkTaskCatalog = function updateWorkTaskCatalog(request, callback) {
                    return this.rpcCall(updateWorkTaskCatalog, $root.mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest, $root.mtechnavi.api.worktask.WorkTaskCatalog, request, callback);
                }, "name", { value: "UpdateWorkTaskCatalog" });

                /**
                 * Calls UpdateWorkTaskCatalog.
                 * @function updateWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskCatalogRequest} request UpdateWorkTaskCatalogRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTaskCatalog>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#deleteWorkTaskCatalog}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef DeleteWorkTaskCatalogCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteWorkTaskCatalog.
                 * @function deleteWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskCatalogRequest} request DeleteWorkTaskCatalogRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.DeleteWorkTaskCatalogCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.deleteWorkTaskCatalog = function deleteWorkTaskCatalog(request, callback) {
                    return this.rpcCall(deleteWorkTaskCatalog, $root.mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteWorkTaskCatalog" });

                /**
                 * Calls DeleteWorkTaskCatalog.
                 * @function deleteWorkTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskCatalogRequest} request DeleteWorkTaskCatalogRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#createWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef CreateWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTask} [response] WorkTask
                 */

                /**
                 * Calls CreateWorkTask.
                 * @function createWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRequest} request CreateWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.CreateWorkTaskCallback} callback Node-style callback called with the error, if any, and WorkTask
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.createWorkTask = function createWorkTask(request, callback) {
                    return this.rpcCall(createWorkTask, $root.mtechnavi.api.worktask.CreateWorkTaskRequest, $root.mtechnavi.api.worktask.WorkTask, request, callback);
                }, "name", { value: "CreateWorkTask" });

                /**
                 * Calls CreateWorkTask.
                 * @function createWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRequest} request CreateWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTask>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#listWorkTasks}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ListWorkTasksCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ListWorkTasksResponse} [response] ListWorkTasksResponse
                 */

                /**
                 * Calls ListWorkTasks.
                 * @function listWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTasksRequest} request ListWorkTasksRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ListWorkTasksCallback} callback Node-style callback called with the error, if any, and ListWorkTasksResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.listWorkTasks = function listWorkTasks(request, callback) {
                    return this.rpcCall(listWorkTasks, $root.mtechnavi.api.worktask.ListWorkTasksRequest, $root.mtechnavi.api.worktask.ListWorkTasksResponse, request, callback);
                }, "name", { value: "ListWorkTasks" });

                /**
                 * Calls ListWorkTasks.
                 * @function listWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTasksRequest} request ListWorkTasksRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ListWorkTasksResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#getWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef GetWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTask} [response] WorkTask
                 */

                /**
                 * Calls GetWorkTask.
                 * @function getWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRequest} request GetWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.GetWorkTaskCallback} callback Node-style callback called with the error, if any, and WorkTask
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.getWorkTask = function getWorkTask(request, callback) {
                    return this.rpcCall(getWorkTask, $root.mtechnavi.api.worktask.GetWorkTaskRequest, $root.mtechnavi.api.worktask.WorkTask, request, callback);
                }, "name", { value: "GetWorkTask" });

                /**
                 * Calls GetWorkTask.
                 * @function getWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRequest} request GetWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTask>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#updateWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef UpdateWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTask} [response] WorkTask
                 */

                /**
                 * Calls UpdateWorkTask.
                 * @function updateWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskRequest} request UpdateWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.UpdateWorkTaskCallback} callback Node-style callback called with the error, if any, and WorkTask
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.updateWorkTask = function updateWorkTask(request, callback) {
                    return this.rpcCall(updateWorkTask, $root.mtechnavi.api.worktask.UpdateWorkTaskRequest, $root.mtechnavi.api.worktask.WorkTask, request, callback);
                }, "name", { value: "UpdateWorkTask" });

                /**
                 * Calls UpdateWorkTask.
                 * @function updateWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskRequest} request UpdateWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTask>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#deleteWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef DeleteWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteWorkTask.
                 * @function deleteWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRequest} request DeleteWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.DeleteWorkTaskCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.deleteWorkTask = function deleteWorkTask(request, callback) {
                    return this.rpcCall(deleteWorkTask, $root.mtechnavi.api.worktask.DeleteWorkTaskRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteWorkTask" });

                /**
                 * Calls DeleteWorkTask.
                 * @function deleteWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRequest} request DeleteWorkTaskRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#exportWorkTasks}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ExportWorkTasksCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ExportWorkTasksResponse} [response] ExportWorkTasksResponse
                 */

                /**
                 * Calls ExportWorkTasks.
                 * @function exportWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IExportWorkTasksRequest} request ExportWorkTasksRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ExportWorkTasksCallback} callback Node-style callback called with the error, if any, and ExportWorkTasksResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.exportWorkTasks = function exportWorkTasks(request, callback) {
                    return this.rpcCall(exportWorkTasks, $root.mtechnavi.api.worktask.ExportWorkTasksRequest, $root.mtechnavi.api.worktask.ExportWorkTasksResponse, request, callback);
                }, "name", { value: "ExportWorkTasks" });

                /**
                 * Calls ExportWorkTasks.
                 * @function exportWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IExportWorkTasksRequest} request ExportWorkTasksRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ExportWorkTasksResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#approvalRequestWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ApprovalRequestWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse} [response] ApprovalRequestWorkTaskResponse
                 */

                /**
                 * Calls ApprovalRequestWorkTask.
                 * @function approvalRequestWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest} request ApprovalRequestWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ApprovalRequestWorkTaskCallback} callback Node-style callback called with the error, if any, and ApprovalRequestWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.approvalRequestWorkTask = function approvalRequestWorkTask(request, callback) {
                    return this.rpcCall(approvalRequestWorkTask, $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest, $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse, request, callback);
                }, "name", { value: "ApprovalRequestWorkTask" });

                /**
                 * Calls ApprovalRequestWorkTask.
                 * @function approvalRequestWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest} request ApprovalRequestWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#approveWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ApproveWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ApproveWorkTaskResponse} [response] ApproveWorkTaskResponse
                 */

                /**
                 * Calls ApproveWorkTask.
                 * @function approveWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IApproveWorkTaskRequest} request ApproveWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ApproveWorkTaskCallback} callback Node-style callback called with the error, if any, and ApproveWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.approveWorkTask = function approveWorkTask(request, callback) {
                    return this.rpcCall(approveWorkTask, $root.mtechnavi.api.worktask.ApproveWorkTaskRequest, $root.mtechnavi.api.worktask.ApproveWorkTaskResponse, request, callback);
                }, "name", { value: "ApproveWorkTask" });

                /**
                 * Calls ApproveWorkTask.
                 * @function approveWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IApproveWorkTaskRequest} request ApproveWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ApproveWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#rejectWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef RejectWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.RejectWorkTaskResponse} [response] RejectWorkTaskResponse
                 */

                /**
                 * Calls RejectWorkTask.
                 * @function rejectWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IRejectWorkTaskRequest} request RejectWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.RejectWorkTaskCallback} callback Node-style callback called with the error, if any, and RejectWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.rejectWorkTask = function rejectWorkTask(request, callback) {
                    return this.rpcCall(rejectWorkTask, $root.mtechnavi.api.worktask.RejectWorkTaskRequest, $root.mtechnavi.api.worktask.RejectWorkTaskResponse, request, callback);
                }, "name", { value: "RejectWorkTask" });

                /**
                 * Calls RejectWorkTask.
                 * @function rejectWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IRejectWorkTaskRequest} request RejectWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.RejectWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#activateWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ActivateWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ActivateWorkTaskResponse} [response] ActivateWorkTaskResponse
                 */

                /**
                 * Calls ActivateWorkTask.
                 * @function activateWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IActivateWorkTaskRequest} request ActivateWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ActivateWorkTaskCallback} callback Node-style callback called with the error, if any, and ActivateWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.activateWorkTask = function activateWorkTask(request, callback) {
                    return this.rpcCall(activateWorkTask, $root.mtechnavi.api.worktask.ActivateWorkTaskRequest, $root.mtechnavi.api.worktask.ActivateWorkTaskResponse, request, callback);
                }, "name", { value: "ActivateWorkTask" });

                /**
                 * Calls ActivateWorkTask.
                 * @function activateWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IActivateWorkTaskRequest} request ActivateWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ActivateWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#discardWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef DiscardWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.DiscardWorkTaskResponse} [response] DiscardWorkTaskResponse
                 */

                /**
                 * Calls DiscardWorkTask.
                 * @function discardWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDiscardWorkTaskRequest} request DiscardWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.DiscardWorkTaskCallback} callback Node-style callback called with the error, if any, and DiscardWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.discardWorkTask = function discardWorkTask(request, callback) {
                    return this.rpcCall(discardWorkTask, $root.mtechnavi.api.worktask.DiscardWorkTaskRequest, $root.mtechnavi.api.worktask.DiscardWorkTaskResponse, request, callback);
                }, "name", { value: "DiscardWorkTask" });

                /**
                 * Calls DiscardWorkTask.
                 * @function discardWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDiscardWorkTaskRequest} request DiscardWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.DiscardWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#completeWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef CompleteWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskResponse} [response] CompleteWorkTaskResponse
                 */

                /**
                 * Calls CompleteWorkTask.
                 * @function completeWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskRequest} request CompleteWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.CompleteWorkTaskCallback} callback Node-style callback called with the error, if any, and CompleteWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.completeWorkTask = function completeWorkTask(request, callback) {
                    return this.rpcCall(completeWorkTask, $root.mtechnavi.api.worktask.CompleteWorkTaskRequest, $root.mtechnavi.api.worktask.CompleteWorkTaskResponse, request, callback);
                }, "name", { value: "CompleteWorkTask" });

                /**
                 * Calls CompleteWorkTask.
                 * @function completeWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskRequest} request CompleteWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.CompleteWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#uncompleteWorkTask}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef UncompleteWorkTaskCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskResponse} [response] UncompleteWorkTaskResponse
                 */

                /**
                 * Calls UncompleteWorkTask.
                 * @function uncompleteWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskRequest} request UncompleteWorkTaskRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.UncompleteWorkTaskCallback} callback Node-style callback called with the error, if any, and UncompleteWorkTaskResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.uncompleteWorkTask = function uncompleteWorkTask(request, callback) {
                    return this.rpcCall(uncompleteWorkTask, $root.mtechnavi.api.worktask.UncompleteWorkTaskRequest, $root.mtechnavi.api.worktask.UncompleteWorkTaskResponse, request, callback);
                }, "name", { value: "UncompleteWorkTask" });

                /**
                 * Calls UncompleteWorkTask.
                 * @function uncompleteWorkTask
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskRequest} request UncompleteWorkTaskRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.UncompleteWorkTaskResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#completeWorkTaskTicket}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef CompleteWorkTaskTicketCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskTicketResponse} [response] CompleteWorkTaskTicketResponse
                 */

                /**
                 * Calls CompleteWorkTaskTicket.
                 * @function completeWorkTaskTicket
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest} request CompleteWorkTaskTicketRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.CompleteWorkTaskTicketCallback} callback Node-style callback called with the error, if any, and CompleteWorkTaskTicketResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.completeWorkTaskTicket = function completeWorkTaskTicket(request, callback) {
                    return this.rpcCall(completeWorkTaskTicket, $root.mtechnavi.api.worktask.CompleteWorkTaskTicketRequest, $root.mtechnavi.api.worktask.CompleteWorkTaskTicketResponse, request, callback);
                }, "name", { value: "CompleteWorkTaskTicket" });

                /**
                 * Calls CompleteWorkTaskTicket.
                 * @function completeWorkTaskTicket
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest} request CompleteWorkTaskTicketRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.CompleteWorkTaskTicketResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#uncompleteWorkTaskTicket}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef UncompleteWorkTaskTicketCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse} [response] UncompleteWorkTaskTicketResponse
                 */

                /**
                 * Calls UncompleteWorkTaskTicket.
                 * @function uncompleteWorkTaskTicket
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest} request UncompleteWorkTaskTicketRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.UncompleteWorkTaskTicketCallback} callback Node-style callback called with the error, if any, and UncompleteWorkTaskTicketResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.uncompleteWorkTaskTicket = function uncompleteWorkTaskTicket(request, callback) {
                    return this.rpcCall(uncompleteWorkTaskTicket, $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest, $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse, request, callback);
                }, "name", { value: "UncompleteWorkTaskTicket" });

                /**
                 * Calls UncompleteWorkTaskTicket.
                 * @function uncompleteWorkTaskTicket
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest} request UncompleteWorkTaskTicketRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#listWorkTaskFormats}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ListWorkTaskFormatsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ListWorkTaskFormatsResponse} [response] ListWorkTaskFormatsResponse
                 */

                /**
                 * Calls ListWorkTaskFormats.
                 * @function listWorkTaskFormats
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskFormatsRequest} request ListWorkTaskFormatsRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ListWorkTaskFormatsCallback} callback Node-style callback called with the error, if any, and ListWorkTaskFormatsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.listWorkTaskFormats = function listWorkTaskFormats(request, callback) {
                    return this.rpcCall(listWorkTaskFormats, $root.mtechnavi.api.worktask.ListWorkTaskFormatsRequest, $root.mtechnavi.api.worktask.ListWorkTaskFormatsResponse, request, callback);
                }, "name", { value: "ListWorkTaskFormats" });

                /**
                 * Calls ListWorkTaskFormats.
                 * @function listWorkTaskFormats
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskFormatsRequest} request ListWorkTaskFormatsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ListWorkTaskFormatsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#createWorkTaskRelation}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef CreateWorkTaskRelationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTaskRelation} [response] WorkTaskRelation
                 */

                /**
                 * Calls CreateWorkTaskRelation.
                 * @function createWorkTaskRelation
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRelationRequest} request CreateWorkTaskRelationRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.CreateWorkTaskRelationCallback} callback Node-style callback called with the error, if any, and WorkTaskRelation
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.createWorkTaskRelation = function createWorkTaskRelation(request, callback) {
                    return this.rpcCall(createWorkTaskRelation, $root.mtechnavi.api.worktask.CreateWorkTaskRelationRequest, $root.mtechnavi.api.worktask.WorkTaskRelation, request, callback);
                }, "name", { value: "CreateWorkTaskRelation" });

                /**
                 * Calls CreateWorkTaskRelation.
                 * @function createWorkTaskRelation
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRelationRequest} request CreateWorkTaskRelationRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTaskRelation>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#deleteWorkTaskRelation}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef DeleteWorkTaskRelationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {google.protobuf.Empty} [response] Empty
                 */

                /**
                 * Calls DeleteWorkTaskRelation.
                 * @function deleteWorkTaskRelation
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRelationRequest} request DeleteWorkTaskRelationRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.DeleteWorkTaskRelationCallback} callback Node-style callback called with the error, if any, and Empty
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.deleteWorkTaskRelation = function deleteWorkTaskRelation(request, callback) {
                    return this.rpcCall(deleteWorkTaskRelation, $root.mtechnavi.api.worktask.DeleteWorkTaskRelationRequest, $root.google.protobuf.Empty, request, callback);
                }, "name", { value: "DeleteWorkTaskRelation" });

                /**
                 * Calls DeleteWorkTaskRelation.
                 * @function deleteWorkTaskRelation
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRelationRequest} request DeleteWorkTaskRelationRequest message or plain object
                 * @returns {Promise<google.protobuf.Empty>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#listWorkTaskRelationContents}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef ListWorkTaskRelationContentsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse} [response] ListWorkTaskRelationContentsResponse
                 */

                /**
                 * Calls ListWorkTaskRelationContents.
                 * @function listWorkTaskRelationContents
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskRelationContentsRequest} request ListWorkTaskRelationContentsRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.ListWorkTaskRelationContentsCallback} callback Node-style callback called with the error, if any, and ListWorkTaskRelationContentsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.listWorkTaskRelationContents = function listWorkTaskRelationContents(request, callback) {
                    return this.rpcCall(listWorkTaskRelationContents, $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest, $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse, request, callback);
                }, "name", { value: "ListWorkTaskRelationContents" });

                /**
                 * Calls ListWorkTaskRelationContents.
                 * @function listWorkTaskRelationContents
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IListWorkTaskRelationContentsRequest} request ListWorkTaskRelationContentsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskService#getWorkTaskRelationContent}.
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @typedef GetWorkTaskRelationContentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.WorkTaskRelationContent} [response] WorkTaskRelationContent
                 */

                /**
                 * Calls GetWorkTaskRelationContent.
                 * @function getWorkTaskRelationContent
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRelationContentRequest} request GetWorkTaskRelationContentRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskService.GetWorkTaskRelationContentCallback} callback Node-style callback called with the error, if any, and WorkTaskRelationContent
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskService.prototype.getWorkTaskRelationContent = function getWorkTaskRelationContent(request, callback) {
                    return this.rpcCall(getWorkTaskRelationContent, $root.mtechnavi.api.worktask.GetWorkTaskRelationContentRequest, $root.mtechnavi.api.worktask.WorkTaskRelationContent, request, callback);
                }, "name", { value: "GetWorkTaskRelationContent" });

                /**
                 * Calls GetWorkTaskRelationContent.
                 * @function getWorkTaskRelationContent
                 * @memberof mtechnavi.api.worktask.WorkTaskService
                 * @instance
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRelationContentRequest} request GetWorkTaskRelationContentRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.WorkTaskRelationContent>} Promise
                 * @variation 2
                 */

                return WorkTaskService;
            })();

            worktask.WorkTaskTaskListService = (function() {

                /**
                 * Constructs a new WorkTaskTaskListService service.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTaskTaskListService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function WorkTaskTaskListService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (WorkTaskTaskListService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WorkTaskTaskListService;

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskTaskListService#uncompletedListWorkTaskTickets}.
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @typedef UncompletedListWorkTaskTicketsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse} [response] UncompletedListWorkTaskTicketsResponse
                 */

                /**
                 * Calls UncompletedListWorkTaskTickets.
                 * @function uncompletedListWorkTaskTickets
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTaskTicketsRequest} request UncompletedListWorkTaskTicketsRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskTaskListService.UncompletedListWorkTaskTicketsCallback} callback Node-style callback called with the error, if any, and UncompletedListWorkTaskTicketsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskTaskListService.prototype.uncompletedListWorkTaskTickets = function uncompletedListWorkTaskTickets(request, callback) {
                    return this.rpcCall(uncompletedListWorkTaskTickets, $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest, $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse, request, callback);
                }, "name", { value: "UncompletedListWorkTaskTickets" });

                /**
                 * Calls UncompletedListWorkTaskTickets.
                 * @function uncompletedListWorkTaskTickets
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTaskTicketsRequest} request UncompletedListWorkTaskTicketsRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link mtechnavi.api.worktask.WorkTaskTaskListService#uncompletedListWorkTasks}.
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @typedef UncompletedListWorkTasksCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTasksResponse} [response] UncompletedListWorkTasksResponse
                 */

                /**
                 * Calls UncompletedListWorkTasks.
                 * @function uncompletedListWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTasksRequest} request UncompletedListWorkTasksRequest message or plain object
                 * @param {mtechnavi.api.worktask.WorkTaskTaskListService.UncompletedListWorkTasksCallback} callback Node-style callback called with the error, if any, and UncompletedListWorkTasksResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(WorkTaskTaskListService.prototype.uncompletedListWorkTasks = function uncompletedListWorkTasks(request, callback) {
                    return this.rpcCall(uncompletedListWorkTasks, $root.mtechnavi.api.worktask.UncompletedListWorkTasksRequest, $root.mtechnavi.api.worktask.UncompletedListWorkTasksResponse, request, callback);
                }, "name", { value: "UncompletedListWorkTasks" });

                /**
                 * Calls UncompletedListWorkTasks.
                 * @function uncompletedListWorkTasks
                 * @memberof mtechnavi.api.worktask.WorkTaskTaskListService
                 * @instance
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTasksRequest} request UncompletedListWorkTasksRequest message or plain object
                 * @returns {Promise<mtechnavi.api.worktask.UncompletedListWorkTasksResponse>} Promise
                 * @variation 2
                 */

                return WorkTaskTaskListService;
            })();

            worktask.ListHistorysRequest = (function() {

                /**
                 * Properties of a ListHistorysRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListHistorysRequest
                 * @property {string|null} [pageToken] ListHistorysRequest pageToken
                 * @property {string|null} [typeName] ListHistorysRequest typeName
                 * @property {Array.<string>|null} [recordIds] ListHistorysRequest recordIds
                 */

                /**
                 * Constructs a new ListHistorysRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListHistorysRequest.
                 * @implements IListHistorysRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListHistorysRequest=} [properties] Properties to set
                 */
                function ListHistorysRequest(properties) {
                    this.recordIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.pageToken = "";

                /**
                 * ListHistorysRequest typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.typeName = "";

                /**
                 * ListHistorysRequest recordIds.
                 * @member {Array.<string>} recordIds
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @instance
                 */
                ListHistorysRequest.prototype.recordIds = $util.emptyArray;

                /**
                 * Verifies a ListHistorysRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                        if (!Array.isArray(message.recordIds))
                            return "recordIds: array expected";
                        for (let i = 0; i < message.recordIds.length; ++i)
                            if (!$util.isString(message.recordIds[i]))
                                return "recordIds: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ListHistorysRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListHistorysRequest} ListHistorysRequest
                 */
                ListHistorysRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListHistorysRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListHistorysRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordIds) {
                        if (!Array.isArray(object.recordIds))
                            throw TypeError(".mtechnavi.api.worktask.ListHistorysRequest.recordIds: array expected");
                        message.recordIds = [];
                        for (let i = 0; i < object.recordIds.length; ++i)
                            message.recordIds[i] = String(object.recordIds[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ListHistorysRequest} message ListHistorysRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.recordIds = [];
                    if (options.defaults) {
                        object.pageToken = "";
                        object.typeName = "";
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordIds && message.recordIds.length) {
                        object.recordIds = [];
                        for (let j = 0; j < message.recordIds.length; ++j)
                            object.recordIds[j] = message.recordIds[j];
                    }
                    return object;
                };

                /**
                 * Converts this ListHistorysRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListHistorysRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysRequest;
            })();

            worktask.ListHistorysResponse = (function() {

                /**
                 * Properties of a ListHistorysResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListHistorysResponse
                 * @property {Long|null} [total] ListHistorysResponse total
                 * @property {Array.<mtechnavi.api.worktask.IHistory>|null} [items] ListHistorysResponse items
                 * @property {string|null} [pageToken] ListHistorysResponse pageToken
                 */

                /**
                 * Constructs a new ListHistorysResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListHistorysResponse.
                 * @implements IListHistorysResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListHistorysResponse=} [properties] Properties to set
                 */
                function ListHistorysResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListHistorysResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListHistorysResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IHistory>} items
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.items = $util.emptyArray;

                /**
                 * ListHistorysResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @instance
                 */
                ListHistorysResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListHistorysResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListHistorysResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.History.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListHistorysResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListHistorysResponse} ListHistorysResponse
                 */
                ListHistorysResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListHistorysResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListHistorysResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.ListHistorysResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.ListHistorysResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.History.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListHistorysResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ListHistorysResponse} message ListHistorysResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListHistorysResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.History.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListHistorysResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListHistorysResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListHistorysResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListHistorysResponse;
            })();

            worktask.CreateWorkTaskCatalogRequest = (function() {

                /**
                 * Properties of a CreateWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICreateWorkTaskCatalogRequest
                 * @property {mtechnavi.api.worktask.IWorkTaskCatalog|null} [workTaskCatalog] CreateWorkTaskCatalogRequest workTaskCatalog
                 */

                /**
                 * Constructs a new CreateWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CreateWorkTaskCatalogRequest.
                 * @implements ICreateWorkTaskCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskCatalogRequest=} [properties] Properties to set
                 */
                function CreateWorkTaskCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateWorkTaskCatalogRequest workTaskCatalog.
                 * @member {mtechnavi.api.worktask.IWorkTaskCatalog|null|undefined} workTaskCatalog
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskCatalogRequest
                 * @instance
                 */
                CreateWorkTaskCatalogRequest.prototype.workTaskCatalog = null;

                /**
                 * Verifies a CreateWorkTaskCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateWorkTaskCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskCatalog.verify(message.workTaskCatalog);
                        if (error)
                            return "workTaskCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateWorkTaskCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CreateWorkTaskCatalogRequest} CreateWorkTaskCatalogRequest
                 */
                CreateWorkTaskCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CreateWorkTaskCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.CreateWorkTaskCatalogRequest();
                    if (object.workTaskCatalog != null) {
                        if (typeof object.workTaskCatalog !== "object")
                            throw TypeError(".mtechnavi.api.worktask.CreateWorkTaskCatalogRequest.workTaskCatalog: object expected");
                        message.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.fromObject(object.workTaskCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateWorkTaskCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskCatalogRequest
                 * @static
                 * @param {mtechnavi.api.worktask.CreateWorkTaskCatalogRequest} message CreateWorkTaskCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWorkTaskCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskCatalog = null;
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog"))
                        object.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.toObject(message.workTaskCatalog, options);
                    return object;
                };

                /**
                 * Converts this CreateWorkTaskCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWorkTaskCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateWorkTaskCatalogRequest;
            })();

            worktask.ListWorkTaskCatalogsRequest = (function() {

                /**
                 * Properties of a ListWorkTaskCatalogsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskCatalogsRequest
                 * @property {string|null} [pageToken] ListWorkTaskCatalogsRequest pageToken
                 */

                /**
                 * Constructs a new ListWorkTaskCatalogsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskCatalogsRequest.
                 * @implements IListWorkTaskCatalogsRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskCatalogsRequest=} [properties] Properties to set
                 */
                function ListWorkTaskCatalogsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskCatalogsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogsRequest
                 * @instance
                 */
                ListWorkTaskCatalogsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTaskCatalogsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskCatalogsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTaskCatalogsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskCatalogsRequest} ListWorkTaskCatalogsRequest
                 */
                ListWorkTaskCatalogsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskCatalogsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskCatalogsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskCatalogsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogsRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskCatalogsRequest} message ListWorkTaskCatalogsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskCatalogsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTaskCatalogsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskCatalogsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTaskCatalogsRequest;
            })();

            worktask.ListWorkTaskCatalogResponse = (function() {

                /**
                 * Properties of a ListWorkTaskCatalogResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskCatalogResponse
                 * @property {Long|null} [total] ListWorkTaskCatalogResponse total
                 * @property {Array.<mtechnavi.api.worktask.IWorkTaskCatalog>|null} [items] ListWorkTaskCatalogResponse items
                 * @property {string|null} [pageToken] ListWorkTaskCatalogResponse pageToken
                 */

                /**
                 * Constructs a new ListWorkTaskCatalogResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskCatalogResponse.
                 * @implements IListWorkTaskCatalogResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskCatalogResponse=} [properties] Properties to set
                 */
                function ListWorkTaskCatalogResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskCatalogResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @instance
                 */
                ListWorkTaskCatalogResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListWorkTaskCatalogResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IWorkTaskCatalog>} items
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @instance
                 */
                ListWorkTaskCatalogResponse.prototype.items = $util.emptyArray;

                /**
                 * ListWorkTaskCatalogResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @instance
                 */
                ListWorkTaskCatalogResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTaskCatalogResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskCatalogResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.WorkTaskCatalog.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTaskCatalogResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskCatalogResponse} ListWorkTaskCatalogResponse
                 */
                ListWorkTaskCatalogResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskCatalogResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskCatalogResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTaskCatalogResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTaskCatalogResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.WorkTaskCatalog.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskCatalogResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskCatalogResponse} message ListWorkTaskCatalogResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskCatalogResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.WorkTaskCatalog.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTaskCatalogResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskCatalogResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskCatalogResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTaskCatalogResponse;
            })();

            worktask.GetWorkTaskCatalogRequest = (function() {

                /**
                 * Properties of a GetWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IGetWorkTaskCatalogRequest
                 * @property {string|null} [workTaskCatalogId] GetWorkTaskCatalogRequest workTaskCatalogId
                 */

                /**
                 * Constructs a new GetWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a GetWorkTaskCatalogRequest.
                 * @implements IGetWorkTaskCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IGetWorkTaskCatalogRequest=} [properties] Properties to set
                 */
                function GetWorkTaskCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetWorkTaskCatalogRequest workTaskCatalogId.
                 * @member {string} workTaskCatalogId
                 * @memberof mtechnavi.api.worktask.GetWorkTaskCatalogRequest
                 * @instance
                 */
                GetWorkTaskCatalogRequest.prototype.workTaskCatalogId = "";

                /**
                 * Verifies a GetWorkTaskCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.GetWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWorkTaskCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        if (!$util.isString(message.workTaskCatalogId))
                            return "workTaskCatalogId: string expected";
                    return null;
                };

                /**
                 * Creates a GetWorkTaskCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.GetWorkTaskCatalogRequest} GetWorkTaskCatalogRequest
                 */
                GetWorkTaskCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.GetWorkTaskCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.GetWorkTaskCatalogRequest();
                    if (object.workTaskCatalogId != null)
                        message.workTaskCatalogId = String(object.workTaskCatalogId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetWorkTaskCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskCatalogRequest
                 * @static
                 * @param {mtechnavi.api.worktask.GetWorkTaskCatalogRequest} message GetWorkTaskCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWorkTaskCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskCatalogId = "";
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        object.workTaskCatalogId = message.workTaskCatalogId;
                    return object;
                };

                /**
                 * Converts this GetWorkTaskCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.GetWorkTaskCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWorkTaskCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetWorkTaskCatalogRequest;
            })();

            worktask.UpdateWorkTaskCatalogRequest = (function() {

                /**
                 * Properties of an UpdateWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUpdateWorkTaskCatalogRequest
                 * @property {mtechnavi.api.worktask.IWorkTaskCatalog|null} [workTaskCatalog] UpdateWorkTaskCatalogRequest workTaskCatalog
                 */

                /**
                 * Constructs a new UpdateWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UpdateWorkTaskCatalogRequest.
                 * @implements IUpdateWorkTaskCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskCatalogRequest=} [properties] Properties to set
                 */
                function UpdateWorkTaskCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateWorkTaskCatalogRequest workTaskCatalog.
                 * @member {mtechnavi.api.worktask.IWorkTaskCatalog|null|undefined} workTaskCatalog
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest
                 * @instance
                 */
                UpdateWorkTaskCatalogRequest.prototype.workTaskCatalog = null;

                /**
                 * Verifies an UpdateWorkTaskCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateWorkTaskCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskCatalog.verify(message.workTaskCatalog);
                        if (error)
                            return "workTaskCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateWorkTaskCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest} UpdateWorkTaskCatalogRequest
                 */
                UpdateWorkTaskCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest();
                    if (object.workTaskCatalog != null) {
                        if (typeof object.workTaskCatalog !== "object")
                            throw TypeError(".mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest.workTaskCatalog: object expected");
                        message.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.fromObject(object.workTaskCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateWorkTaskCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest} message UpdateWorkTaskCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateWorkTaskCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskCatalog = null;
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog"))
                        object.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.toObject(message.workTaskCatalog, options);
                    return object;
                };

                /**
                 * Converts this UpdateWorkTaskCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateWorkTaskCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateWorkTaskCatalogRequest;
            })();

            worktask.DeleteWorkTaskCatalogRequest = (function() {

                /**
                 * Properties of a DeleteWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IDeleteWorkTaskCatalogRequest
                 * @property {mtechnavi.api.worktask.IWorkTaskCatalog|null} [workTaskCatalog] DeleteWorkTaskCatalogRequest workTaskCatalog
                 */

                /**
                 * Constructs a new DeleteWorkTaskCatalogRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a DeleteWorkTaskCatalogRequest.
                 * @implements IDeleteWorkTaskCatalogRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskCatalogRequest=} [properties] Properties to set
                 */
                function DeleteWorkTaskCatalogRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteWorkTaskCatalogRequest workTaskCatalog.
                 * @member {mtechnavi.api.worktask.IWorkTaskCatalog|null|undefined} workTaskCatalog
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest
                 * @instance
                 */
                DeleteWorkTaskCatalogRequest.prototype.workTaskCatalog = null;

                /**
                 * Verifies a DeleteWorkTaskCatalogRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteWorkTaskCatalogRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskCatalog.verify(message.workTaskCatalog);
                        if (error)
                            return "workTaskCatalog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteWorkTaskCatalogRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest} DeleteWorkTaskCatalogRequest
                 */
                DeleteWorkTaskCatalogRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest();
                    if (object.workTaskCatalog != null) {
                        if (typeof object.workTaskCatalog !== "object")
                            throw TypeError(".mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest.workTaskCatalog: object expected");
                        message.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.fromObject(object.workTaskCatalog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteWorkTaskCatalogRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest
                 * @static
                 * @param {mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest} message DeleteWorkTaskCatalogRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWorkTaskCatalogRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskCatalog = null;
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog"))
                        object.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.toObject(message.workTaskCatalog, options);
                    return object;
                };

                /**
                 * Converts this DeleteWorkTaskCatalogRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskCatalogRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWorkTaskCatalogRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteWorkTaskCatalogRequest;
            })();

            worktask.CreateWorkTaskRequest = (function() {

                /**
                 * Properties of a CreateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICreateWorkTaskRequest
                 * @property {mtechnavi.api.worktask.IWorkTask|null} [workTask] CreateWorkTaskRequest workTask
                 */

                /**
                 * Constructs a new CreateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CreateWorkTaskRequest.
                 * @implements ICreateWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRequest=} [properties] Properties to set
                 */
                function CreateWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateWorkTaskRequest workTask.
                 * @member {mtechnavi.api.worktask.IWorkTask|null|undefined} workTask
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRequest
                 * @instance
                 */
                CreateWorkTaskRequest.prototype.workTask = null;

                /**
                 * Verifies a CreateWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTask != null && message.hasOwnProperty("workTask")) {
                        let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.workTask);
                        if (error)
                            return "workTask." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CreateWorkTaskRequest} CreateWorkTaskRequest
                 */
                CreateWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CreateWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.CreateWorkTaskRequest();
                    if (object.workTask != null) {
                        if (typeof object.workTask !== "object")
                            throw TypeError(".mtechnavi.api.worktask.CreateWorkTaskRequest.workTask: object expected");
                        message.workTask = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.workTask);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.CreateWorkTaskRequest} message CreateWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTask = null;
                    if (message.workTask != null && message.hasOwnProperty("workTask"))
                        object.workTask = $root.mtechnavi.api.worktask.WorkTask.toObject(message.workTask, options);
                    return object;
                };

                /**
                 * Converts this CreateWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateWorkTaskRequest;
            })();

            worktask.ListWorkTasksRequest = (function() {

                /**
                 * Properties of a ListWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTasksRequest
                 * @property {string|null} [pageToken] ListWorkTasksRequest pageToken
                 * @property {mtechnavi.api.worktask.ListWorkTasksRequest.IDataFilter|null} [dataFilter] ListWorkTasksRequest dataFilter
                 */

                /**
                 * Constructs a new ListWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTasksRequest.
                 * @implements IListWorkTasksRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTasksRequest=} [properties] Properties to set
                 */
                function ListWorkTasksRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTasksRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @instance
                 */
                ListWorkTasksRequest.prototype.pageToken = "";

                /**
                 * ListWorkTasksRequest dataFilter.
                 * @member {mtechnavi.api.worktask.ListWorkTasksRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @instance
                 */
                ListWorkTasksRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListWorkTasksRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListWorkTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTasksRequest} ListWorkTasksRequest
                 */
                ListWorkTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTasksRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTasksRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTasksRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTasksRequest} message ListWorkTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListWorkTasksRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListWorkTasksRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [statusCodes] DataFilter statusCodes
                     * @property {Array.<string>|null} [workTaskCatalogIds] DataFilter workTaskCatalogIds
                     * @property {Long|null} [requestedAtFrom] DataFilter requestedAtFrom
                     * @property {Long|null} [requestedAtTo] DataFilter requestedAtTo
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.worktask.ListWorkTasksRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.statusCodes = [];
                        this.workTaskCatalogIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter statusCodes.
                     * @member {Array.<string>} statusCodes
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.statusCodes = $util.emptyArray;

                    /**
                     * DataFilter workTaskCatalogIds.
                     * @member {Array.<string>} workTaskCatalogIds
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.workTaskCatalogIds = $util.emptyArray;

                    /**
                     * DataFilter requestedAtFrom.
                     * @member {Long} requestedAtFrom
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.requestedAtFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DataFilter requestedAtTo.
                     * @member {Long} requestedAtTo
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.requestedAtTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.statusCodes != null && message.hasOwnProperty("statusCodes")) {
                            if (!Array.isArray(message.statusCodes))
                                return "statusCodes: array expected";
                            for (let i = 0; i < message.statusCodes.length; ++i)
                                if (!$util.isString(message.statusCodes[i]))
                                    return "statusCodes: string[] expected";
                        }
                        if (message.workTaskCatalogIds != null && message.hasOwnProperty("workTaskCatalogIds")) {
                            if (!Array.isArray(message.workTaskCatalogIds))
                                return "workTaskCatalogIds: array expected";
                            for (let i = 0; i < message.workTaskCatalogIds.length; ++i)
                                if (!$util.isString(message.workTaskCatalogIds[i]))
                                    return "workTaskCatalogIds: string[] expected";
                        }
                        if (message.requestedAtFrom != null && message.hasOwnProperty("requestedAtFrom"))
                            if (!$util.isInteger(message.requestedAtFrom) && !(message.requestedAtFrom && $util.isInteger(message.requestedAtFrom.low) && $util.isInteger(message.requestedAtFrom.high)))
                                return "requestedAtFrom: integer|Long expected";
                        if (message.requestedAtTo != null && message.hasOwnProperty("requestedAtTo"))
                            if (!$util.isInteger(message.requestedAtTo) && !(message.requestedAtTo && $util.isInteger(message.requestedAtTo.low) && $util.isInteger(message.requestedAtTo.high)))
                                return "requestedAtTo: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter();
                        if (object.statusCodes) {
                            if (!Array.isArray(object.statusCodes))
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter.statusCodes: array expected");
                            message.statusCodes = [];
                            for (let i = 0; i < object.statusCodes.length; ++i)
                                message.statusCodes[i] = String(object.statusCodes[i]);
                        }
                        if (object.workTaskCatalogIds) {
                            if (!Array.isArray(object.workTaskCatalogIds))
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter.workTaskCatalogIds: array expected");
                            message.workTaskCatalogIds = [];
                            for (let i = 0; i < object.workTaskCatalogIds.length; ++i)
                                message.workTaskCatalogIds[i] = String(object.workTaskCatalogIds[i]);
                        }
                        if (object.requestedAtFrom != null)
                            if ($util.Long)
                                (message.requestedAtFrom = $util.Long.fromValue(object.requestedAtFrom)).unsigned = false;
                            else if (typeof object.requestedAtFrom === "string")
                                message.requestedAtFrom = parseInt(object.requestedAtFrom, 10);
                            else if (typeof object.requestedAtFrom === "number")
                                message.requestedAtFrom = object.requestedAtFrom;
                            else if (typeof object.requestedAtFrom === "object")
                                message.requestedAtFrom = new $util.LongBits(object.requestedAtFrom.low >>> 0, object.requestedAtFrom.high >>> 0).toNumber();
                        if (object.requestedAtTo != null)
                            if ($util.Long)
                                (message.requestedAtTo = $util.Long.fromValue(object.requestedAtTo)).unsigned = false;
                            else if (typeof object.requestedAtTo === "string")
                                message.requestedAtTo = parseInt(object.requestedAtTo, 10);
                            else if (typeof object.requestedAtTo === "number")
                                message.requestedAtTo = object.requestedAtTo;
                            else if (typeof object.requestedAtTo === "object")
                                message.requestedAtTo = new $util.LongBits(object.requestedAtTo.low >>> 0, object.requestedAtTo.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.statusCodes = [];
                            object.workTaskCatalogIds = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.requestedAtFrom = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.requestedAtFrom = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                let long = new $util.Long(0, 0, false);
                                object.requestedAtTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.requestedAtTo = options.longs === String ? "0" : 0;
                        }
                        if (message.statusCodes && message.statusCodes.length) {
                            object.statusCodes = [];
                            for (let j = 0; j < message.statusCodes.length; ++j)
                                object.statusCodes[j] = message.statusCodes[j];
                        }
                        if (message.workTaskCatalogIds && message.workTaskCatalogIds.length) {
                            object.workTaskCatalogIds = [];
                            for (let j = 0; j < message.workTaskCatalogIds.length; ++j)
                                object.workTaskCatalogIds[j] = message.workTaskCatalogIds[j];
                        }
                        if (message.requestedAtFrom != null && message.hasOwnProperty("requestedAtFrom"))
                            if (typeof message.requestedAtFrom === "number")
                                object.requestedAtFrom = options.longs === String ? String(message.requestedAtFrom) : message.requestedAtFrom;
                            else
                                object.requestedAtFrom = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAtFrom) : options.longs === Number ? new $util.LongBits(message.requestedAtFrom.low >>> 0, message.requestedAtFrom.high >>> 0).toNumber() : message.requestedAtFrom;
                        if (message.requestedAtTo != null && message.hasOwnProperty("requestedAtTo"))
                            if (typeof message.requestedAtTo === "number")
                                object.requestedAtTo = options.longs === String ? String(message.requestedAtTo) : message.requestedAtTo;
                            else
                                object.requestedAtTo = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAtTo) : options.longs === Number ? new $util.LongBits(message.requestedAtTo.low >>> 0, message.requestedAtTo.high >>> 0).toNumber() : message.requestedAtTo;
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.worktask.ListWorkTasksRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListWorkTasksRequest;
            })();

            worktask.ListWorkTasksResponse = (function() {

                /**
                 * Properties of a ListWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTasksResponse
                 * @property {Long|null} [total] ListWorkTasksResponse total
                 * @property {Array.<mtechnavi.api.worktask.IWorkTask>|null} [items] ListWorkTasksResponse items
                 * @property {string|null} [pageToken] ListWorkTasksResponse pageToken
                 */

                /**
                 * Constructs a new ListWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTasksResponse.
                 * @implements IListWorkTasksResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTasksResponse=} [properties] Properties to set
                 */
                function ListWorkTasksResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTasksResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @instance
                 */
                ListWorkTasksResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListWorkTasksResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IWorkTask>} items
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @instance
                 */
                ListWorkTasksResponse.prototype.items = $util.emptyArray;

                /**
                 * ListWorkTasksResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @instance
                 */
                ListWorkTasksResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTasksResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTasksResponse} ListWorkTasksResponse
                 */
                ListWorkTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTasksResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTasksResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTasksResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTasksResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTasksResponse} message ListWorkTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.WorkTask.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTasksResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTasksResponse;
            })();

            worktask.GetWorkTaskRequest = (function() {

                /**
                 * Properties of a GetWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IGetWorkTaskRequest
                 * @property {string|null} [workTaskId] GetWorkTaskRequest workTaskId
                 */

                /**
                 * Constructs a new GetWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a GetWorkTaskRequest.
                 * @implements IGetWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRequest=} [properties] Properties to set
                 */
                function GetWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRequest
                 * @instance
                 */
                GetWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * Verifies a GetWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    return null;
                };

                /**
                 * Creates a GetWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.GetWorkTaskRequest} GetWorkTaskRequest
                 */
                GetWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.GetWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.GetWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.GetWorkTaskRequest} message GetWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskId = "";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    return object;
                };

                /**
                 * Converts this GetWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetWorkTaskRequest;
            })();

            worktask.UpdateWorkTaskRequest = (function() {

                /**
                 * Properties of an UpdateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUpdateWorkTaskRequest
                 * @property {mtechnavi.api.worktask.IWorkTask|null} [workTask] UpdateWorkTaskRequest workTask
                 */

                /**
                 * Constructs a new UpdateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UpdateWorkTaskRequest.
                 * @implements IUpdateWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUpdateWorkTaskRequest=} [properties] Properties to set
                 */
                function UpdateWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateWorkTaskRequest workTask.
                 * @member {mtechnavi.api.worktask.IWorkTask|null|undefined} workTask
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskRequest
                 * @instance
                 */
                UpdateWorkTaskRequest.prototype.workTask = null;

                /**
                 * Verifies an UpdateWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTask != null && message.hasOwnProperty("workTask")) {
                        let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.workTask);
                        if (error)
                            return "workTask." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UpdateWorkTaskRequest} UpdateWorkTaskRequest
                 */
                UpdateWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UpdateWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UpdateWorkTaskRequest();
                    if (object.workTask != null) {
                        if (typeof object.workTask !== "object")
                            throw TypeError(".mtechnavi.api.worktask.UpdateWorkTaskRequest.workTask: object expected");
                        message.workTask = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.workTask);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UpdateWorkTaskRequest} message UpdateWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTask = null;
                    if (message.workTask != null && message.hasOwnProperty("workTask"))
                        object.workTask = $root.mtechnavi.api.worktask.WorkTask.toObject(message.workTask, options);
                    return object;
                };

                /**
                 * Converts this UpdateWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UpdateWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateWorkTaskRequest;
            })();

            worktask.DeleteWorkTaskRequest = (function() {

                /**
                 * Properties of a DeleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IDeleteWorkTaskRequest
                 * @property {mtechnavi.api.worktask.IWorkTask|null} [workTask] DeleteWorkTaskRequest workTask
                 */

                /**
                 * Constructs a new DeleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a DeleteWorkTaskRequest.
                 * @implements IDeleteWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRequest=} [properties] Properties to set
                 */
                function DeleteWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteWorkTaskRequest workTask.
                 * @member {mtechnavi.api.worktask.IWorkTask|null|undefined} workTask
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRequest
                 * @instance
                 */
                DeleteWorkTaskRequest.prototype.workTask = null;

                /**
                 * Verifies a DeleteWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTask != null && message.hasOwnProperty("workTask")) {
                        let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.workTask);
                        if (error)
                            return "workTask." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.DeleteWorkTaskRequest} DeleteWorkTaskRequest
                 */
                DeleteWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.DeleteWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.DeleteWorkTaskRequest();
                    if (object.workTask != null) {
                        if (typeof object.workTask !== "object")
                            throw TypeError(".mtechnavi.api.worktask.DeleteWorkTaskRequest.workTask: object expected");
                        message.workTask = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.workTask);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.DeleteWorkTaskRequest} message DeleteWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTask = null;
                    if (message.workTask != null && message.hasOwnProperty("workTask"))
                        object.workTask = $root.mtechnavi.api.worktask.WorkTask.toObject(message.workTask, options);
                    return object;
                };

                /**
                 * Converts this DeleteWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteWorkTaskRequest;
            })();

            worktask.ExportWorkTasksRequest = (function() {

                /**
                 * Properties of an ExportWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IExportWorkTasksRequest
                 * @property {Array.<string>|null} [ids] ExportWorkTasksRequest ids
                 * @property {sharelib.IFileFormat|null} [fileFormat] ExportWorkTasksRequest fileFormat
                 */

                /**
                 * Constructs a new ExportWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ExportWorkTasksRequest.
                 * @implements IExportWorkTasksRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IExportWorkTasksRequest=} [properties] Properties to set
                 */
                function ExportWorkTasksRequest(properties) {
                    this.ids = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportWorkTasksRequest ids.
                 * @member {Array.<string>} ids
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @instance
                 */
                ExportWorkTasksRequest.prototype.ids = $util.emptyArray;

                /**
                 * ExportWorkTasksRequest fileFormat.
                 * @member {sharelib.IFileFormat|null|undefined} fileFormat
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @instance
                 */
                ExportWorkTasksRequest.prototype.fileFormat = null;

                /**
                 * Verifies an ExportWorkTasksRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportWorkTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ids != null && message.hasOwnProperty("ids")) {
                        if (!Array.isArray(message.ids))
                            return "ids: array expected";
                        for (let i = 0; i < message.ids.length; ++i)
                            if (!$util.isString(message.ids[i]))
                                return "ids: string[] expected";
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat")) {
                        let error = $root.sharelib.FileFormat.verify(message.fileFormat);
                        if (error)
                            return "fileFormat." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExportWorkTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ExportWorkTasksRequest} ExportWorkTasksRequest
                 */
                ExportWorkTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ExportWorkTasksRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ExportWorkTasksRequest();
                    if (object.ids) {
                        if (!Array.isArray(object.ids))
                            throw TypeError(".mtechnavi.api.worktask.ExportWorkTasksRequest.ids: array expected");
                        message.ids = [];
                        for (let i = 0; i < object.ids.length; ++i)
                            message.ids[i] = String(object.ids[i]);
                    }
                    if (object.fileFormat != null) {
                        if (typeof object.fileFormat !== "object")
                            throw TypeError(".mtechnavi.api.worktask.ExportWorkTasksRequest.fileFormat: object expected");
                        message.fileFormat = $root.sharelib.FileFormat.fromObject(object.fileFormat);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExportWorkTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ExportWorkTasksRequest} message ExportWorkTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportWorkTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.ids = [];
                    if (options.defaults)
                        object.fileFormat = null;
                    if (message.ids && message.ids.length) {
                        object.ids = [];
                        for (let j = 0; j < message.ids.length; ++j)
                            object.ids[j] = message.ids[j];
                    }
                    if (message.fileFormat != null && message.hasOwnProperty("fileFormat"))
                        object.fileFormat = $root.sharelib.FileFormat.toObject(message.fileFormat, options);
                    return object;
                };

                /**
                 * Converts this ExportWorkTasksRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportWorkTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportWorkTasksRequest;
            })();

            worktask.ExportWorkTasksResponse = (function() {

                /**
                 * Properties of an ExportWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IExportWorkTasksResponse
                 * @property {string|null} [assetId] ExportWorkTasksResponse assetId
                 */

                /**
                 * Constructs a new ExportWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ExportWorkTasksResponse.
                 * @implements IExportWorkTasksResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IExportWorkTasksResponse=} [properties] Properties to set
                 */
                function ExportWorkTasksResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExportWorkTasksResponse assetId.
                 * @member {string} assetId
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksResponse
                 * @instance
                 */
                ExportWorkTasksResponse.prototype.assetId = "";

                /**
                 * Verifies an ExportWorkTasksResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExportWorkTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        if (!$util.isString(message.assetId))
                            return "assetId: string expected";
                    return null;
                };

                /**
                 * Creates an ExportWorkTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ExportWorkTasksResponse} ExportWorkTasksResponse
                 */
                ExportWorkTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ExportWorkTasksResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ExportWorkTasksResponse();
                    if (object.assetId != null)
                        message.assetId = String(object.assetId);
                    return message;
                };

                /**
                 * Creates a plain object from an ExportWorkTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ExportWorkTasksResponse} message ExportWorkTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExportWorkTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.assetId = "";
                    if (message.assetId != null && message.hasOwnProperty("assetId"))
                        object.assetId = message.assetId;
                    return object;
                };

                /**
                 * Converts this ExportWorkTasksResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ExportWorkTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExportWorkTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExportWorkTasksResponse;
            })();

            worktask.ApprovalRequestWorkTaskRequest = (function() {

                /**
                 * Properties of an ApprovalRequestWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IApprovalRequestWorkTaskRequest
                 * @property {string|null} [workTaskId] ApprovalRequestWorkTaskRequest workTaskId
                 * @property {sharelib.IUserReference|null} [approvalPlanStaff] ApprovalRequestWorkTaskRequest approvalPlanStaff
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] ApprovalRequestWorkTaskRequest approvalPlanOrganization
                 * @property {string|null} [comment] ApprovalRequestWorkTaskRequest comment
                 */

                /**
                 * Constructs a new ApprovalRequestWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ApprovalRequestWorkTaskRequest.
                 * @implements IApprovalRequestWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IApprovalRequestWorkTaskRequest=} [properties] Properties to set
                 */
                function ApprovalRequestWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApprovalRequestWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @instance
                 */
                ApprovalRequestWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * ApprovalRequestWorkTaskRequest approvalPlanStaff.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @instance
                 */
                ApprovalRequestWorkTaskRequest.prototype.approvalPlanStaff = null;

                /**
                 * ApprovalRequestWorkTaskRequest approvalPlanOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @instance
                 */
                ApprovalRequestWorkTaskRequest.prototype.approvalPlanOrganization = null;

                /**
                 * ApprovalRequestWorkTaskRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @instance
                 */
                ApprovalRequestWorkTaskRequest.prototype.comment = "";

                /**
                 * Verifies an ApprovalRequestWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                        if (error)
                            return "approvalPlanStaff." + error;
                    }
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                        if (error)
                            return "approvalPlanOrganization." + error;
                    }
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest} ApprovalRequestWorkTaskRequest
                 */
                ApprovalRequestWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.approvalPlanStaff != null) {
                        if (typeof object.approvalPlanStaff !== "object")
                            throw TypeError(".mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest.approvalPlanStaff: object expected");
                        message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
                    }
                    if (object.approvalPlanOrganization != null) {
                        if (typeof object.approvalPlanOrganization !== "object")
                            throw TypeError(".mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest.approvalPlanOrganization: object expected");
                        message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
                    }
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApprovalRequestWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest} message ApprovalRequestWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.comment = "";
                        object.approvalPlanStaff = null;
                        object.approvalPlanOrganization = null;
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                        object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
                    if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                        object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
                    return object;
                };

                /**
                 * Converts this ApprovalRequestWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestWorkTaskRequest;
            })();

            worktask.ApprovalRequestWorkTaskResponse = (function() {

                /**
                 * Properties of an ApprovalRequestWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IApprovalRequestWorkTaskResponse
                 */

                /**
                 * Constructs a new ApprovalRequestWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ApprovalRequestWorkTaskResponse.
                 * @implements IApprovalRequestWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IApprovalRequestWorkTaskResponse=} [properties] Properties to set
                 */
                function ApprovalRequestWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApprovalRequestWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApprovalRequestWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApprovalRequestWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse} ApprovalRequestWorkTaskResponse
                 */
                ApprovalRequestWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse();
                };

                /**
                 * Creates a plain object from an ApprovalRequestWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse} message ApprovalRequestWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApprovalRequestWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApprovalRequestWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ApprovalRequestWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApprovalRequestWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApprovalRequestWorkTaskResponse;
            })();

            worktask.ApproveWorkTaskRequest = (function() {

                /**
                 * Properties of an ApproveWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IApproveWorkTaskRequest
                 * @property {string|null} [workTaskId] ApproveWorkTaskRequest workTaskId
                 * @property {string|null} [comment] ApproveWorkTaskRequest comment
                 */

                /**
                 * Constructs a new ApproveWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ApproveWorkTaskRequest.
                 * @implements IApproveWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IApproveWorkTaskRequest=} [properties] Properties to set
                 */
                function ApproveWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ApproveWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @instance
                 */
                ApproveWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * ApproveWorkTaskRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @instance
                 */
                ApproveWorkTaskRequest.prototype.comment = "";

                /**
                 * Verifies an ApproveWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an ApproveWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ApproveWorkTaskRequest} ApproveWorkTaskRequest
                 */
                ApproveWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ApproveWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ApproveWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an ApproveWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ApproveWorkTaskRequest} message ApproveWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.comment = "";
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this ApproveWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveWorkTaskRequest;
            })();

            worktask.ApproveWorkTaskResponse = (function() {

                /**
                 * Properties of an ApproveWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IApproveWorkTaskResponse
                 */

                /**
                 * Constructs a new ApproveWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ApproveWorkTaskResponse.
                 * @implements IApproveWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IApproveWorkTaskResponse=} [properties] Properties to set
                 */
                function ApproveWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ApproveWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ApproveWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ApproveWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ApproveWorkTaskResponse} ApproveWorkTaskResponse
                 */
                ApproveWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ApproveWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.ApproveWorkTaskResponse();
                };

                /**
                 * Creates a plain object from an ApproveWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ApproveWorkTaskResponse} message ApproveWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ApproveWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ApproveWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ApproveWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ApproveWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ApproveWorkTaskResponse;
            })();

            worktask.RejectWorkTaskRequest = (function() {

                /**
                 * Properties of a RejectWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IRejectWorkTaskRequest
                 * @property {string|null} [workTaskId] RejectWorkTaskRequest workTaskId
                 * @property {string|null} [comment] RejectWorkTaskRequest comment
                 */

                /**
                 * Constructs a new RejectWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a RejectWorkTaskRequest.
                 * @implements IRejectWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IRejectWorkTaskRequest=} [properties] Properties to set
                 */
                function RejectWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RejectWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @instance
                 */
                RejectWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * RejectWorkTaskRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @instance
                 */
                RejectWorkTaskRequest.prototype.comment = "";

                /**
                 * Verifies a RejectWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a RejectWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.RejectWorkTaskRequest} RejectWorkTaskRequest
                 */
                RejectWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.RejectWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.RejectWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a RejectWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.RejectWorkTaskRequest} message RejectWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.comment = "";
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this RejectWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectWorkTaskRequest;
            })();

            worktask.RejectWorkTaskResponse = (function() {

                /**
                 * Properties of a RejectWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IRejectWorkTaskResponse
                 */

                /**
                 * Constructs a new RejectWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a RejectWorkTaskResponse.
                 * @implements IRejectWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IRejectWorkTaskResponse=} [properties] Properties to set
                 */
                function RejectWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a RejectWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RejectWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a RejectWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.RejectWorkTaskResponse} RejectWorkTaskResponse
                 */
                RejectWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.RejectWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.RejectWorkTaskResponse();
                };

                /**
                 * Creates a plain object from a RejectWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.RejectWorkTaskResponse} message RejectWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RejectWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this RejectWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.RejectWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RejectWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RejectWorkTaskResponse;
            })();

            worktask.ListWorkTaskFormatsRequest = (function() {

                /**
                 * Properties of a ListWorkTaskFormatsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskFormatsRequest
                 * @property {string|null} [pageToken] ListWorkTaskFormatsRequest pageToken
                 */

                /**
                 * Constructs a new ListWorkTaskFormatsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskFormatsRequest.
                 * @implements IListWorkTaskFormatsRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskFormatsRequest=} [properties] Properties to set
                 */
                function ListWorkTaskFormatsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskFormatsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsRequest
                 * @instance
                 */
                ListWorkTaskFormatsRequest.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTaskFormatsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskFormatsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTaskFormatsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskFormatsRequest} ListWorkTaskFormatsRequest
                 */
                ListWorkTaskFormatsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskFormatsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskFormatsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskFormatsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskFormatsRequest} message ListWorkTaskFormatsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskFormatsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTaskFormatsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskFormatsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTaskFormatsRequest;
            })();

            worktask.ListWorkTaskFormatsResponse = (function() {

                /**
                 * Properties of a ListWorkTaskFormatsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskFormatsResponse
                 * @property {Long|null} [total] ListWorkTaskFormatsResponse total
                 * @property {Array.<sharelib.IFileFormat>|null} [items] ListWorkTaskFormatsResponse items
                 * @property {string|null} [pageToken] ListWorkTaskFormatsResponse pageToken
                 */

                /**
                 * Constructs a new ListWorkTaskFormatsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskFormatsResponse.
                 * @implements IListWorkTaskFormatsResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskFormatsResponse=} [properties] Properties to set
                 */
                function ListWorkTaskFormatsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskFormatsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @instance
                 */
                ListWorkTaskFormatsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListWorkTaskFormatsResponse items.
                 * @member {Array.<sharelib.IFileFormat>} items
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @instance
                 */
                ListWorkTaskFormatsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListWorkTaskFormatsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @instance
                 */
                ListWorkTaskFormatsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTaskFormatsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskFormatsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.sharelib.FileFormat.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTaskFormatsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskFormatsResponse} ListWorkTaskFormatsResponse
                 */
                ListWorkTaskFormatsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskFormatsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskFormatsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTaskFormatsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTaskFormatsResponse.items: object expected");
                            message.items[i] = $root.sharelib.FileFormat.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskFormatsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskFormatsResponse} message ListWorkTaskFormatsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskFormatsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.sharelib.FileFormat.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTaskFormatsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskFormatsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskFormatsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTaskFormatsResponse;
            })();

            worktask.CreateWorkTaskRelationRequest = (function() {

                /**
                 * Properties of a CreateWorkTaskRelationRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICreateWorkTaskRelationRequest
                 * @property {mtechnavi.api.worktask.IWorkTaskRelation|null} [workTaskRelation] CreateWorkTaskRelationRequest workTaskRelation
                 */

                /**
                 * Constructs a new CreateWorkTaskRelationRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CreateWorkTaskRelationRequest.
                 * @implements ICreateWorkTaskRelationRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICreateWorkTaskRelationRequest=} [properties] Properties to set
                 */
                function CreateWorkTaskRelationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateWorkTaskRelationRequest workTaskRelation.
                 * @member {mtechnavi.api.worktask.IWorkTaskRelation|null|undefined} workTaskRelation
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRelationRequest
                 * @instance
                 */
                CreateWorkTaskRelationRequest.prototype.workTaskRelation = null;

                /**
                 * Verifies a CreateWorkTaskRelationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRelationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateWorkTaskRelationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskRelation.verify(message.workTaskRelation);
                        if (error)
                            return "workTaskRelation." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateWorkTaskRelationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRelationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CreateWorkTaskRelationRequest} CreateWorkTaskRelationRequest
                 */
                CreateWorkTaskRelationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CreateWorkTaskRelationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.CreateWorkTaskRelationRequest();
                    if (object.workTaskRelation != null) {
                        if (typeof object.workTaskRelation !== "object")
                            throw TypeError(".mtechnavi.api.worktask.CreateWorkTaskRelationRequest.workTaskRelation: object expected");
                        message.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.fromObject(object.workTaskRelation);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateWorkTaskRelationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRelationRequest
                 * @static
                 * @param {mtechnavi.api.worktask.CreateWorkTaskRelationRequest} message CreateWorkTaskRelationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateWorkTaskRelationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskRelation = null;
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation"))
                        object.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.toObject(message.workTaskRelation, options);
                    return object;
                };

                /**
                 * Converts this CreateWorkTaskRelationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CreateWorkTaskRelationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateWorkTaskRelationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateWorkTaskRelationRequest;
            })();

            worktask.DeleteWorkTaskRelationRequest = (function() {

                /**
                 * Properties of a DeleteWorkTaskRelationRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IDeleteWorkTaskRelationRequest
                 * @property {mtechnavi.api.worktask.IWorkTaskRelation|null} [workTaskRelation] DeleteWorkTaskRelationRequest workTaskRelation
                 */

                /**
                 * Constructs a new DeleteWorkTaskRelationRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a DeleteWorkTaskRelationRequest.
                 * @implements IDeleteWorkTaskRelationRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IDeleteWorkTaskRelationRequest=} [properties] Properties to set
                 */
                function DeleteWorkTaskRelationRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeleteWorkTaskRelationRequest workTaskRelation.
                 * @member {mtechnavi.api.worktask.IWorkTaskRelation|null|undefined} workTaskRelation
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRelationRequest
                 * @instance
                 */
                DeleteWorkTaskRelationRequest.prototype.workTaskRelation = null;

                /**
                 * Verifies a DeleteWorkTaskRelationRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRelationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeleteWorkTaskRelationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskRelation.verify(message.workTaskRelation);
                        if (error)
                            return "workTaskRelation." + error;
                    }
                    return null;
                };

                /**
                 * Creates a DeleteWorkTaskRelationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRelationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.DeleteWorkTaskRelationRequest} DeleteWorkTaskRelationRequest
                 */
                DeleteWorkTaskRelationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.DeleteWorkTaskRelationRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.DeleteWorkTaskRelationRequest();
                    if (object.workTaskRelation != null) {
                        if (typeof object.workTaskRelation !== "object")
                            throw TypeError(".mtechnavi.api.worktask.DeleteWorkTaskRelationRequest.workTaskRelation: object expected");
                        message.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.fromObject(object.workTaskRelation);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeleteWorkTaskRelationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRelationRequest
                 * @static
                 * @param {mtechnavi.api.worktask.DeleteWorkTaskRelationRequest} message DeleteWorkTaskRelationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeleteWorkTaskRelationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskRelation = null;
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation"))
                        object.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.toObject(message.workTaskRelation, options);
                    return object;
                };

                /**
                 * Converts this DeleteWorkTaskRelationRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.DeleteWorkTaskRelationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeleteWorkTaskRelationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeleteWorkTaskRelationRequest;
            })();

            worktask.ListWorkTaskRelationContentsRequest = (function() {

                /**
                 * Properties of a ListWorkTaskRelationContentsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskRelationContentsRequest
                 * @property {string|null} [pageToken] ListWorkTaskRelationContentsRequest pageToken
                 * @property {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.IDataFilter|null} [dataFilter] ListWorkTaskRelationContentsRequest dataFilter
                 */

                /**
                 * Constructs a new ListWorkTaskRelationContentsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskRelationContentsRequest.
                 * @implements IListWorkTaskRelationContentsRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskRelationContentsRequest=} [properties] Properties to set
                 */
                function ListWorkTaskRelationContentsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskRelationContentsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @instance
                 */
                ListWorkTaskRelationContentsRequest.prototype.pageToken = "";

                /**
                 * ListWorkTaskRelationContentsRequest dataFilter.
                 * @member {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.IDataFilter|null|undefined} dataFilter
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @instance
                 */
                ListWorkTaskRelationContentsRequest.prototype.dataFilter = null;

                /**
                 * Verifies a ListWorkTaskRelationContentsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskRelationContentsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter")) {
                        let error = $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter.verify(message.dataFilter);
                        if (error)
                            return "dataFilter." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListWorkTaskRelationContentsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest} ListWorkTaskRelationContentsRequest
                 */
                ListWorkTaskRelationContentsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    if (object.dataFilter != null) {
                        if (typeof object.dataFilter !== "object")
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.dataFilter: object expected");
                        message.dataFilter = $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter.fromObject(object.dataFilter);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskRelationContentsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest} message ListWorkTaskRelationContentsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskRelationContentsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.pageToken = "";
                        object.dataFilter = null;
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    if (message.dataFilter != null && message.hasOwnProperty("dataFilter"))
                        object.dataFilter = $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter.toObject(message.dataFilter, options);
                    return object;
                };

                /**
                 * Converts this ListWorkTaskRelationContentsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskRelationContentsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListWorkTaskRelationContentsRequest.DataFilter = (function() {

                    /**
                     * Properties of a DataFilter.
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                     * @interface IDataFilter
                     * @property {Array.<string>|null} [linkedWorkTaskIds] DataFilter linkedWorkTaskIds
                     * @property {string|null} [typeName] DataFilter typeName
                     * @property {Array.<string>|null} [recordIds] DataFilter recordIds
                     */

                    /**
                     * Constructs a new DataFilter.
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest
                     * @classdesc Represents a DataFilter.
                     * @implements IDataFilter
                     * @constructor
                     * @param {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.IDataFilter=} [properties] Properties to set
                     */
                    function DataFilter(properties) {
                        this.linkedWorkTaskIds = [];
                        this.recordIds = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DataFilter linkedWorkTaskIds.
                     * @member {Array.<string>} linkedWorkTaskIds
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.linkedWorkTaskIds = $util.emptyArray;

                    /**
                     * DataFilter typeName.
                     * @member {string} typeName
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.typeName = "";

                    /**
                     * DataFilter recordIds.
                     * @member {Array.<string>} recordIds
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @instance
                     */
                    DataFilter.prototype.recordIds = $util.emptyArray;

                    /**
                     * Verifies a DataFilter message.
                     * @function verify
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DataFilter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.linkedWorkTaskIds != null && message.hasOwnProperty("linkedWorkTaskIds")) {
                            if (!Array.isArray(message.linkedWorkTaskIds))
                                return "linkedWorkTaskIds: array expected";
                            for (let i = 0; i < message.linkedWorkTaskIds.length; ++i)
                                if (!$util.isString(message.linkedWorkTaskIds[i]))
                                    return "linkedWorkTaskIds: string[] expected";
                        }
                        if (message.typeName != null && message.hasOwnProperty("typeName"))
                            if (!$util.isString(message.typeName))
                                return "typeName: string expected";
                        if (message.recordIds != null && message.hasOwnProperty("recordIds")) {
                            if (!Array.isArray(message.recordIds))
                                return "recordIds: array expected";
                            for (let i = 0; i < message.recordIds.length; ++i)
                                if (!$util.isString(message.recordIds[i]))
                                    return "recordIds: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a DataFilter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter} DataFilter
                     */
                    DataFilter.fromObject = function fromObject(object) {
                        if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter)
                            return object;
                        let message = new $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter();
                        if (object.linkedWorkTaskIds) {
                            if (!Array.isArray(object.linkedWorkTaskIds))
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter.linkedWorkTaskIds: array expected");
                            message.linkedWorkTaskIds = [];
                            for (let i = 0; i < object.linkedWorkTaskIds.length; ++i)
                                message.linkedWorkTaskIds[i] = String(object.linkedWorkTaskIds[i]);
                        }
                        if (object.typeName != null)
                            message.typeName = String(object.typeName);
                        if (object.recordIds) {
                            if (!Array.isArray(object.recordIds))
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter.recordIds: array expected");
                            message.recordIds = [];
                            for (let i = 0; i < object.recordIds.length; ++i)
                                message.recordIds[i] = String(object.recordIds[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DataFilter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @static
                     * @param {mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter} message DataFilter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DataFilter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults) {
                            object.linkedWorkTaskIds = [];
                            object.recordIds = [];
                        }
                        if (options.defaults)
                            object.typeName = "";
                        if (message.linkedWorkTaskIds && message.linkedWorkTaskIds.length) {
                            object.linkedWorkTaskIds = [];
                            for (let j = 0; j < message.linkedWorkTaskIds.length; ++j)
                                object.linkedWorkTaskIds[j] = message.linkedWorkTaskIds[j];
                        }
                        if (message.typeName != null && message.hasOwnProperty("typeName"))
                            object.typeName = message.typeName;
                        if (message.recordIds && message.recordIds.length) {
                            object.recordIds = [];
                            for (let j = 0; j < message.recordIds.length; ++j)
                                object.recordIds[j] = message.recordIds[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DataFilter to JSON.
                     * @function toJSON
                     * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsRequest.DataFilter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DataFilter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DataFilter;
                })();

                return ListWorkTaskRelationContentsRequest;
            })();

            worktask.ListWorkTaskRelationContentsResponse = (function() {

                /**
                 * Properties of a ListWorkTaskRelationContentsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IListWorkTaskRelationContentsResponse
                 * @property {Long|null} [total] ListWorkTaskRelationContentsResponse total
                 * @property {Array.<mtechnavi.api.worktask.IWorkTaskRelationContent>|null} [items] ListWorkTaskRelationContentsResponse items
                 * @property {string|null} [pageToken] ListWorkTaskRelationContentsResponse pageToken
                 */

                /**
                 * Constructs a new ListWorkTaskRelationContentsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a ListWorkTaskRelationContentsResponse.
                 * @implements IListWorkTaskRelationContentsResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IListWorkTaskRelationContentsResponse=} [properties] Properties to set
                 */
                function ListWorkTaskRelationContentsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListWorkTaskRelationContentsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @instance
                 */
                ListWorkTaskRelationContentsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ListWorkTaskRelationContentsResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IWorkTaskRelationContent>} items
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @instance
                 */
                ListWorkTaskRelationContentsResponse.prototype.items = $util.emptyArray;

                /**
                 * ListWorkTaskRelationContentsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @instance
                 */
                ListWorkTaskRelationContentsResponse.prototype.pageToken = "";

                /**
                 * Verifies a ListWorkTaskRelationContentsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListWorkTaskRelationContentsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.WorkTaskRelationContent.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates a ListWorkTaskRelationContentsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse} ListWorkTaskRelationContentsResponse
                 */
                ListWorkTaskRelationContentsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.WorkTaskRelationContent.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ListWorkTaskRelationContentsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse} message ListWorkTaskRelationContentsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListWorkTaskRelationContentsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.WorkTaskRelationContent.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this ListWorkTaskRelationContentsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ListWorkTaskRelationContentsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListWorkTaskRelationContentsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListWorkTaskRelationContentsResponse;
            })();

            worktask.GetWorkTaskRelationContentRequest = (function() {

                /**
                 * Properties of a GetWorkTaskRelationContentRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IGetWorkTaskRelationContentRequest
                 * @property {string|null} [workTaskRelationId] GetWorkTaskRelationContentRequest workTaskRelationId
                 */

                /**
                 * Constructs a new GetWorkTaskRelationContentRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a GetWorkTaskRelationContentRequest.
                 * @implements IGetWorkTaskRelationContentRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IGetWorkTaskRelationContentRequest=} [properties] Properties to set
                 */
                function GetWorkTaskRelationContentRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetWorkTaskRelationContentRequest workTaskRelationId.
                 * @member {string} workTaskRelationId
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRelationContentRequest
                 * @instance
                 */
                GetWorkTaskRelationContentRequest.prototype.workTaskRelationId = "";

                /**
                 * Verifies a GetWorkTaskRelationContentRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRelationContentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetWorkTaskRelationContentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskRelationId != null && message.hasOwnProperty("workTaskRelationId"))
                        if (!$util.isString(message.workTaskRelationId))
                            return "workTaskRelationId: string expected";
                    return null;
                };

                /**
                 * Creates a GetWorkTaskRelationContentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRelationContentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.GetWorkTaskRelationContentRequest} GetWorkTaskRelationContentRequest
                 */
                GetWorkTaskRelationContentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.GetWorkTaskRelationContentRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.GetWorkTaskRelationContentRequest();
                    if (object.workTaskRelationId != null)
                        message.workTaskRelationId = String(object.workTaskRelationId);
                    return message;
                };

                /**
                 * Creates a plain object from a GetWorkTaskRelationContentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRelationContentRequest
                 * @static
                 * @param {mtechnavi.api.worktask.GetWorkTaskRelationContentRequest} message GetWorkTaskRelationContentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetWorkTaskRelationContentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskRelationId = "";
                    if (message.workTaskRelationId != null && message.hasOwnProperty("workTaskRelationId"))
                        object.workTaskRelationId = message.workTaskRelationId;
                    return object;
                };

                /**
                 * Converts this GetWorkTaskRelationContentRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.GetWorkTaskRelationContentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetWorkTaskRelationContentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetWorkTaskRelationContentRequest;
            })();

            worktask.ActivateWorkTaskRequest = (function() {

                /**
                 * Properties of an ActivateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IActivateWorkTaskRequest
                 * @property {string|null} [workTaskId] ActivateWorkTaskRequest workTaskId
                 */

                /**
                 * Constructs a new ActivateWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ActivateWorkTaskRequest.
                 * @implements IActivateWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IActivateWorkTaskRequest=} [properties] Properties to set
                 */
                function ActivateWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ActivateWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskRequest
                 * @instance
                 */
                ActivateWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * Verifies an ActivateWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActivateWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    return null;
                };

                /**
                 * Creates an ActivateWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ActivateWorkTaskRequest} ActivateWorkTaskRequest
                 */
                ActivateWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ActivateWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.ActivateWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    return message;
                };

                /**
                 * Creates a plain object from an ActivateWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.ActivateWorkTaskRequest} message ActivateWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActivateWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskId = "";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    return object;
                };

                /**
                 * Converts this ActivateWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActivateWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ActivateWorkTaskRequest;
            })();

            worktask.ActivateWorkTaskResponse = (function() {

                /**
                 * Properties of an ActivateWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IActivateWorkTaskResponse
                 */

                /**
                 * Constructs a new ActivateWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an ActivateWorkTaskResponse.
                 * @implements IActivateWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IActivateWorkTaskResponse=} [properties] Properties to set
                 */
                function ActivateWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an ActivateWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ActivateWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an ActivateWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.ActivateWorkTaskResponse} ActivateWorkTaskResponse
                 */
                ActivateWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.ActivateWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.ActivateWorkTaskResponse();
                };

                /**
                 * Creates a plain object from an ActivateWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.ActivateWorkTaskResponse} message ActivateWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ActivateWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ActivateWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.ActivateWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ActivateWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ActivateWorkTaskResponse;
            })();

            worktask.DiscardWorkTaskRequest = (function() {

                /**
                 * Properties of a DiscardWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IDiscardWorkTaskRequest
                 * @property {string|null} [workTaskId] DiscardWorkTaskRequest workTaskId
                 * @property {string|null} [comment] DiscardWorkTaskRequest comment
                 */

                /**
                 * Constructs a new DiscardWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a DiscardWorkTaskRequest.
                 * @implements IDiscardWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IDiscardWorkTaskRequest=} [properties] Properties to set
                 */
                function DiscardWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscardWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @instance
                 */
                DiscardWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * DiscardWorkTaskRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @instance
                 */
                DiscardWorkTaskRequest.prototype.comment = "";

                /**
                 * Verifies a DiscardWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates a DiscardWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.DiscardWorkTaskRequest} DiscardWorkTaskRequest
                 */
                DiscardWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.DiscardWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.DiscardWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from a DiscardWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.DiscardWorkTaskRequest} message DiscardWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.comment = "";
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this DiscardWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardWorkTaskRequest;
            })();

            worktask.DiscardWorkTaskResponse = (function() {

                /**
                 * Properties of a DiscardWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IDiscardWorkTaskResponse
                 */

                /**
                 * Constructs a new DiscardWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a DiscardWorkTaskResponse.
                 * @implements IDiscardWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IDiscardWorkTaskResponse=} [properties] Properties to set
                 */
                function DiscardWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a DiscardWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscardWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a DiscardWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.DiscardWorkTaskResponse} DiscardWorkTaskResponse
                 */
                DiscardWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.DiscardWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.DiscardWorkTaskResponse();
                };

                /**
                 * Creates a plain object from a DiscardWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.DiscardWorkTaskResponse} message DiscardWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscardWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this DiscardWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.DiscardWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscardWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DiscardWorkTaskResponse;
            })();

            worktask.CompleteWorkTaskRequest = (function() {

                /**
                 * Properties of a CompleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICompleteWorkTaskRequest
                 * @property {string|null} [workTaskId] CompleteWorkTaskRequest workTaskId
                 */

                /**
                 * Constructs a new CompleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CompleteWorkTaskRequest.
                 * @implements ICompleteWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskRequest=} [properties] Properties to set
                 */
                function CompleteWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskRequest
                 * @instance
                 */
                CompleteWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * Verifies a CompleteWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CompleteWorkTaskRequest} CompleteWorkTaskRequest
                 */
                CompleteWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CompleteWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.CompleteWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskRequest} message CompleteWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskId = "";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    return object;
                };

                /**
                 * Converts this CompleteWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWorkTaskRequest;
            })();

            worktask.CompleteWorkTaskResponse = (function() {

                /**
                 * Properties of a CompleteWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICompleteWorkTaskResponse
                 */

                /**
                 * Constructs a new CompleteWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CompleteWorkTaskResponse.
                 * @implements ICompleteWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskResponse=} [properties] Properties to set
                 */
                function CompleteWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a CompleteWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CompleteWorkTaskResponse} CompleteWorkTaskResponse
                 */
                CompleteWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CompleteWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.CompleteWorkTaskResponse();
                };

                /**
                 * Creates a plain object from a CompleteWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskResponse} message CompleteWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWorkTaskResponse;
            })();

            worktask.UncompleteWorkTaskRequest = (function() {

                /**
                 * Properties of an UncompleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompleteWorkTaskRequest
                 * @property {string|null} [workTaskId] UncompleteWorkTaskRequest workTaskId
                 */

                /**
                 * Constructs a new UncompleteWorkTaskRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompleteWorkTaskRequest.
                 * @implements IUncompleteWorkTaskRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskRequest=} [properties] Properties to set
                 */
                function UncompleteWorkTaskRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompleteWorkTaskRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskRequest
                 * @instance
                 */
                UncompleteWorkTaskRequest.prototype.workTaskId = "";

                /**
                 * Verifies an UncompleteWorkTaskRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteWorkTaskRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    return null;
                };

                /**
                 * Creates an UncompleteWorkTaskRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompleteWorkTaskRequest} UncompleteWorkTaskRequest
                 */
                UncompleteWorkTaskRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompleteWorkTaskRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompleteWorkTaskRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompleteWorkTaskRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskRequest} message UncompleteWorkTaskRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteWorkTaskRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.workTaskId = "";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    return object;
                };

                /**
                 * Converts this UncompleteWorkTaskRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteWorkTaskRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteWorkTaskRequest;
            })();

            worktask.UncompleteWorkTaskResponse = (function() {

                /**
                 * Properties of an UncompleteWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompleteWorkTaskResponse
                 */

                /**
                 * Constructs a new UncompleteWorkTaskResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompleteWorkTaskResponse.
                 * @implements IUncompleteWorkTaskResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskResponse=} [properties] Properties to set
                 */
                function UncompleteWorkTaskResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an UncompleteWorkTaskResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteWorkTaskResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UncompleteWorkTaskResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompleteWorkTaskResponse} UncompleteWorkTaskResponse
                 */
                UncompleteWorkTaskResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompleteWorkTaskResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.UncompleteWorkTaskResponse();
                };

                /**
                 * Creates a plain object from an UncompleteWorkTaskResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskResponse
                 * @static
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskResponse} message UncompleteWorkTaskResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteWorkTaskResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UncompleteWorkTaskResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteWorkTaskResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteWorkTaskResponse;
            })();

            worktask.CompleteWorkTaskTicketRequest = (function() {

                /**
                 * Properties of a CompleteWorkTaskTicketRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICompleteWorkTaskTicketRequest
                 * @property {string|null} [workTaskId] CompleteWorkTaskTicketRequest workTaskId
                 * @property {string|null} [resultRemarks] CompleteWorkTaskTicketRequest resultRemarks
                 * @property {Array.<sharelib.IAttachment>|null} [resultAttachments] CompleteWorkTaskTicketRequest resultAttachments
                 */

                /**
                 * Constructs a new CompleteWorkTaskTicketRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CompleteWorkTaskTicketRequest.
                 * @implements ICompleteWorkTaskTicketRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskTicketRequest=} [properties] Properties to set
                 */
                function CompleteWorkTaskTicketRequest(properties) {
                    this.resultAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteWorkTaskTicketRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @instance
                 */
                CompleteWorkTaskTicketRequest.prototype.workTaskId = "";

                /**
                 * CompleteWorkTaskTicketRequest resultRemarks.
                 * @member {string} resultRemarks
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @instance
                 */
                CompleteWorkTaskTicketRequest.prototype.resultRemarks = "";

                /**
                 * CompleteWorkTaskTicketRequest resultAttachments.
                 * @member {Array.<sharelib.IAttachment>} resultAttachments
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @instance
                 */
                CompleteWorkTaskTicketRequest.prototype.resultAttachments = $util.emptyArray;

                /**
                 * Verifies a CompleteWorkTaskTicketRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWorkTaskTicketRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.resultRemarks != null && message.hasOwnProperty("resultRemarks"))
                        if (!$util.isString(message.resultRemarks))
                            return "resultRemarks: string expected";
                    if (message.resultAttachments != null && message.hasOwnProperty("resultAttachments")) {
                        if (!Array.isArray(message.resultAttachments))
                            return "resultAttachments: array expected";
                        for (let i = 0; i < message.resultAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.resultAttachments[i]);
                            if (error)
                                return "resultAttachments." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CompleteWorkTaskTicketRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CompleteWorkTaskTicketRequest} CompleteWorkTaskTicketRequest
                 */
                CompleteWorkTaskTicketRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CompleteWorkTaskTicketRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.CompleteWorkTaskTicketRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.resultRemarks != null)
                        message.resultRemarks = String(object.resultRemarks);
                    if (object.resultAttachments) {
                        if (!Array.isArray(object.resultAttachments))
                            throw TypeError(".mtechnavi.api.worktask.CompleteWorkTaskTicketRequest.resultAttachments: array expected");
                        message.resultAttachments = [];
                        for (let i = 0; i < object.resultAttachments.length; ++i) {
                            if (typeof object.resultAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.CompleteWorkTaskTicketRequest.resultAttachments: object expected");
                            message.resultAttachments[i] = $root.sharelib.Attachment.fromObject(object.resultAttachments[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteWorkTaskTicketRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @static
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskTicketRequest} message CompleteWorkTaskTicketRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWorkTaskTicketRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.resultAttachments = [];
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.resultRemarks = "";
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.resultRemarks != null && message.hasOwnProperty("resultRemarks"))
                        object.resultRemarks = message.resultRemarks;
                    if (message.resultAttachments && message.resultAttachments.length) {
                        object.resultAttachments = [];
                        for (let j = 0; j < message.resultAttachments.length; ++j)
                            object.resultAttachments[j] = $root.sharelib.Attachment.toObject(message.resultAttachments[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CompleteWorkTaskTicketRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWorkTaskTicketRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWorkTaskTicketRequest;
            })();

            worktask.CompleteWorkTaskTicketResponse = (function() {

                /**
                 * Properties of a CompleteWorkTaskTicketResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface ICompleteWorkTaskTicketResponse
                 */

                /**
                 * Constructs a new CompleteWorkTaskTicketResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a CompleteWorkTaskTicketResponse.
                 * @implements ICompleteWorkTaskTicketResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.ICompleteWorkTaskTicketResponse=} [properties] Properties to set
                 */
                function CompleteWorkTaskTicketResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies a CompleteWorkTaskTicketResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWorkTaskTicketResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteWorkTaskTicketResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.CompleteWorkTaskTicketResponse} CompleteWorkTaskTicketResponse
                 */
                CompleteWorkTaskTicketResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.CompleteWorkTaskTicketResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.CompleteWorkTaskTicketResponse();
                };

                /**
                 * Creates a plain object from a CompleteWorkTaskTicketResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketResponse
                 * @static
                 * @param {mtechnavi.api.worktask.CompleteWorkTaskTicketResponse} message CompleteWorkTaskTicketResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWorkTaskTicketResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteWorkTaskTicketResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.CompleteWorkTaskTicketResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWorkTaskTicketResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWorkTaskTicketResponse;
            })();

            worktask.UncompleteWorkTaskTicketRequest = (function() {

                /**
                 * Properties of an UncompleteWorkTaskTicketRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompleteWorkTaskTicketRequest
                 * @property {string|null} [workTaskId] UncompleteWorkTaskTicketRequest workTaskId
                 * @property {string|null} [comment] UncompleteWorkTaskTicketRequest comment
                 */

                /**
                 * Constructs a new UncompleteWorkTaskTicketRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompleteWorkTaskTicketRequest.
                 * @implements IUncompleteWorkTaskTicketRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskTicketRequest=} [properties] Properties to set
                 */
                function UncompleteWorkTaskTicketRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompleteWorkTaskTicketRequest workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @instance
                 */
                UncompleteWorkTaskTicketRequest.prototype.workTaskId = "";

                /**
                 * UncompleteWorkTaskTicketRequest comment.
                 * @member {string} comment
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @instance
                 */
                UncompleteWorkTaskTicketRequest.prototype.comment = "";

                /**
                 * Verifies an UncompleteWorkTaskTicketRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteWorkTaskTicketRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        if (!$util.isString(message.comment))
                            return "comment: string expected";
                    return null;
                };

                /**
                 * Creates an UncompleteWorkTaskTicketRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest} UncompleteWorkTaskTicketRequest
                 */
                UncompleteWorkTaskTicketRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.comment != null)
                        message.comment = String(object.comment);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompleteWorkTaskTicketRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest} message UncompleteWorkTaskTicketRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteWorkTaskTicketRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskId = "";
                        object.comment = "";
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.comment != null && message.hasOwnProperty("comment"))
                        object.comment = message.comment;
                    return object;
                };

                /**
                 * Converts this UncompleteWorkTaskTicketRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteWorkTaskTicketRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteWorkTaskTicketRequest;
            })();

            worktask.UncompleteWorkTaskTicketResponse = (function() {

                /**
                 * Properties of an UncompleteWorkTaskTicketResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompleteWorkTaskTicketResponse
                 */

                /**
                 * Constructs a new UncompleteWorkTaskTicketResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompleteWorkTaskTicketResponse.
                 * @implements IUncompleteWorkTaskTicketResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompleteWorkTaskTicketResponse=} [properties] Properties to set
                 */
                function UncompleteWorkTaskTicketResponse(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Verifies an UncompleteWorkTaskTicketResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompleteWorkTaskTicketResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UncompleteWorkTaskTicketResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse} UncompleteWorkTaskTicketResponse
                 */
                UncompleteWorkTaskTicketResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse)
                        return object;
                    return new $root.mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse();
                };

                /**
                 * Creates a plain object from an UncompleteWorkTaskTicketResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse
                 * @static
                 * @param {mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse} message UncompleteWorkTaskTicketResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompleteWorkTaskTicketResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UncompleteWorkTaskTicketResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompleteWorkTaskTicketResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompleteWorkTaskTicketResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompleteWorkTaskTicketResponse;
            })();

            worktask.UncompletedListWorkTaskTicketsRequest = (function() {

                /**
                 * Properties of an UncompletedListWorkTaskTicketsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompletedListWorkTaskTicketsRequest
                 * @property {string|null} [pageToken] UncompletedListWorkTaskTicketsRequest pageToken
                 */

                /**
                 * Constructs a new UncompletedListWorkTaskTicketsRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompletedListWorkTaskTicketsRequest.
                 * @implements IUncompletedListWorkTaskTicketsRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTaskTicketsRequest=} [properties] Properties to set
                 */
                function UncompletedListWorkTaskTicketsRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListWorkTaskTicketsRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest
                 * @instance
                 */
                UncompletedListWorkTaskTicketsRequest.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListWorkTaskTicketsRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListWorkTaskTicketsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListWorkTaskTicketsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest} UncompletedListWorkTaskTicketsRequest
                 */
                UncompletedListWorkTaskTicketsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListWorkTaskTicketsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest} message UncompletedListWorkTaskTicketsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListWorkTaskTicketsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListWorkTaskTicketsRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListWorkTaskTicketsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListWorkTaskTicketsRequest;
            })();

            worktask.UncompletedListWorkTaskTicketsResponse = (function() {

                /**
                 * Properties of an UncompletedListWorkTaskTicketsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompletedListWorkTaskTicketsResponse
                 * @property {Long|null} [total] UncompletedListWorkTaskTicketsResponse total
                 * @property {Array.<mtechnavi.api.worktask.IWorkTask>|null} [items] UncompletedListWorkTaskTicketsResponse items
                 * @property {string|null} [pageToken] UncompletedListWorkTaskTicketsResponse pageToken
                 */

                /**
                 * Constructs a new UncompletedListWorkTaskTicketsResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompletedListWorkTaskTicketsResponse.
                 * @implements IUncompletedListWorkTaskTicketsResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTaskTicketsResponse=} [properties] Properties to set
                 */
                function UncompletedListWorkTaskTicketsResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListWorkTaskTicketsResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @instance
                 */
                UncompletedListWorkTaskTicketsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UncompletedListWorkTaskTicketsResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IWorkTask>} items
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @instance
                 */
                UncompletedListWorkTaskTicketsResponse.prototype.items = $util.emptyArray;

                /**
                 * UncompletedListWorkTaskTicketsResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @instance
                 */
                UncompletedListWorkTaskTicketsResponse.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListWorkTaskTicketsResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListWorkTaskTicketsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListWorkTaskTicketsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse} UncompletedListWorkTaskTicketsResponse
                 */
                UncompletedListWorkTaskTicketsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListWorkTaskTicketsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @static
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse} message UncompletedListWorkTaskTicketsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListWorkTaskTicketsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.WorkTask.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListWorkTaskTicketsResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTaskTicketsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListWorkTaskTicketsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListWorkTaskTicketsResponse;
            })();

            worktask.UncompletedListWorkTasksRequest = (function() {

                /**
                 * Properties of an UncompletedListWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompletedListWorkTasksRequest
                 * @property {string|null} [pageToken] UncompletedListWorkTasksRequest pageToken
                 */

                /**
                 * Constructs a new UncompletedListWorkTasksRequest.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompletedListWorkTasksRequest.
                 * @implements IUncompletedListWorkTasksRequest
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTasksRequest=} [properties] Properties to set
                 */
                function UncompletedListWorkTasksRequest(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListWorkTasksRequest pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksRequest
                 * @instance
                 */
                UncompletedListWorkTasksRequest.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListWorkTasksRequest message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListWorkTasksRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListWorkTasksRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompletedListWorkTasksRequest} UncompletedListWorkTasksRequest
                 */
                UncompletedListWorkTasksRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompletedListWorkTasksRequest)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompletedListWorkTasksRequest();
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListWorkTasksRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksRequest
                 * @static
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTasksRequest} message UncompletedListWorkTasksRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListWorkTasksRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.pageToken = "";
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListWorkTasksRequest to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListWorkTasksRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListWorkTasksRequest;
            })();

            worktask.UncompletedListWorkTasksResponse = (function() {

                /**
                 * Properties of an UncompletedListWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @interface IUncompletedListWorkTasksResponse
                 * @property {Long|null} [total] UncompletedListWorkTasksResponse total
                 * @property {Array.<mtechnavi.api.worktask.IWorkTask>|null} [items] UncompletedListWorkTasksResponse items
                 * @property {string|null} [pageToken] UncompletedListWorkTasksResponse pageToken
                 */

                /**
                 * Constructs a new UncompletedListWorkTasksResponse.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents an UncompletedListWorkTasksResponse.
                 * @implements IUncompletedListWorkTasksResponse
                 * @constructor
                 * @param {mtechnavi.api.worktask.IUncompletedListWorkTasksResponse=} [properties] Properties to set
                 */
                function UncompletedListWorkTasksResponse(properties) {
                    this.items = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UncompletedListWorkTasksResponse total.
                 * @member {Long} total
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @instance
                 */
                UncompletedListWorkTasksResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * UncompletedListWorkTasksResponse items.
                 * @member {Array.<mtechnavi.api.worktask.IWorkTask>} items
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @instance
                 */
                UncompletedListWorkTasksResponse.prototype.items = $util.emptyArray;

                /**
                 * UncompletedListWorkTasksResponse pageToken.
                 * @member {string} pageToken
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @instance
                 */
                UncompletedListWorkTasksResponse.prototype.pageToken = "";

                /**
                 * Verifies an UncompletedListWorkTasksResponse message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UncompletedListWorkTasksResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                            return "total: integer|Long expected";
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (let i = 0; i < message.items.length; ++i) {
                            let error = $root.mtechnavi.api.worktask.WorkTask.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        if (!$util.isString(message.pageToken))
                            return "pageToken: string expected";
                    return null;
                };

                /**
                 * Creates an UncompletedListWorkTasksResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.UncompletedListWorkTasksResponse} UncompletedListWorkTasksResponse
                 */
                UncompletedListWorkTasksResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.UncompletedListWorkTasksResponse)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.UncompletedListWorkTasksResponse();
                    if (object.total != null)
                        if ($util.Long)
                            (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                        else if (typeof object.total === "string")
                            message.total = parseInt(object.total, 10);
                        else if (typeof object.total === "number")
                            message.total = object.total;
                        else if (typeof object.total === "object")
                            message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".mtechnavi.api.worktask.UncompletedListWorkTasksResponse.items: array expected");
                        message.items = [];
                        for (let i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.UncompletedListWorkTasksResponse.items: object expected");
                            message.items[i] = $root.mtechnavi.api.worktask.WorkTask.fromObject(object.items[i]);
                        }
                    }
                    if (object.pageToken != null)
                        message.pageToken = String(object.pageToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UncompletedListWorkTasksResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @static
                 * @param {mtechnavi.api.worktask.UncompletedListWorkTasksResponse} message UncompletedListWorkTasksResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UncompletedListWorkTasksResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.total = options.longs === String ? "0" : 0;
                        object.pageToken = "";
                    }
                    if (message.total != null && message.hasOwnProperty("total"))
                        if (typeof message.total === "number")
                            object.total = options.longs === String ? String(message.total) : message.total;
                        else
                            object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (let j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.mtechnavi.api.worktask.WorkTask.toObject(message.items[j], options);
                    }
                    if (message.pageToken != null && message.hasOwnProperty("pageToken"))
                        object.pageToken = message.pageToken;
                    return object;
                };

                /**
                 * Converts this UncompletedListWorkTasksResponse to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.UncompletedListWorkTasksResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UncompletedListWorkTasksResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UncompletedListWorkTasksResponse;
            })();

            worktask.WorkTaskCatalog = (function() {

                /**
                 * Properties of a WorkTaskCatalog.
                 * @memberof mtechnavi.api.worktask
                 * @interface IWorkTaskCatalog
                 * @property {string|null} [workTaskCatalogId] WorkTaskCatalog workTaskCatalogId
                 * @property {string|null} [code] WorkTaskCatalog code
                 * @property {string|null} [displayName] WorkTaskCatalog displayName
                 * @property {sharelib.INameOption|null} [category] WorkTaskCatalog category
                 * @property {string|null} [contents] WorkTaskCatalog contents
                 * @property {boolean|null} [approval] WorkTaskCatalog approval
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] WorkTaskCatalog managementOrganization
                 * @property {sharelib.IOrganizationStructureReference|null} [workOrganization] WorkTaskCatalog workOrganization
                 * @property {Array.<sharelib.IAttachment>|null} [commonAttachments] WorkTaskCatalog commonAttachments
                 * @property {Long|null} [workTaskCatalogCatalogUpdatedAt] WorkTaskCatalog workTaskCatalogCatalogUpdatedAt
                 * @property {sharelib.IEmbeddedUser|null} [workTaskCatalogCatalogUpdatedBy] WorkTaskCatalog workTaskCatalogCatalogUpdatedBy
                 * @property {Long|null} [createdAt] WorkTaskCatalog createdAt
                 * @property {Long|null} [updatedAt] WorkTaskCatalog updatedAt
                 * @property {Long|null} [deletedAt] WorkTaskCatalog deletedAt
                 */

                /**
                 * Constructs a new WorkTaskCatalog.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTaskCatalog.
                 * @implements IWorkTaskCatalog
                 * @constructor
                 * @param {mtechnavi.api.worktask.IWorkTaskCatalog=} [properties] Properties to set
                 */
                function WorkTaskCatalog(properties) {
                    this.commonAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WorkTaskCatalog workTaskCatalogId.
                 * @member {string} workTaskCatalogId
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.workTaskCatalogId = "";

                /**
                 * WorkTaskCatalog code.
                 * @member {string} code
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.code = "";

                /**
                 * WorkTaskCatalog displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.displayName = "";

                /**
                 * WorkTaskCatalog category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.category = null;

                /**
                 * WorkTaskCatalog contents.
                 * @member {string} contents
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.contents = "";

                /**
                 * WorkTaskCatalog approval.
                 * @member {boolean} approval
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.approval = false;

                /**
                 * WorkTaskCatalog managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.managementOrganization = null;

                /**
                 * WorkTaskCatalog workOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} workOrganization
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.workOrganization = null;

                /**
                 * WorkTaskCatalog commonAttachments.
                 * @member {Array.<sharelib.IAttachment>} commonAttachments
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.commonAttachments = $util.emptyArray;

                /**
                 * WorkTaskCatalog workTaskCatalogCatalogUpdatedAt.
                 * @member {Long} workTaskCatalogCatalogUpdatedAt
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.workTaskCatalogCatalogUpdatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTaskCatalog workTaskCatalogCatalogUpdatedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} workTaskCatalogCatalogUpdatedBy
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.workTaskCatalogCatalogUpdatedBy = null;

                /**
                 * WorkTaskCatalog createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTaskCatalog updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTaskCatalog deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 */
                WorkTaskCatalog.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a WorkTaskCatalog message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WorkTaskCatalog.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        if (!$util.isString(message.workTaskCatalogId))
                            return "workTaskCatalogId: string expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        if (!$util.isString(message.contents))
                            return "contents: string expected";
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        if (typeof message.approval !== "boolean")
                            return "approval: boolean expected";
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.workOrganization != null && message.hasOwnProperty("workOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.workOrganization);
                        if (error)
                            return "workOrganization." + error;
                    }
                    if (message.commonAttachments != null && message.hasOwnProperty("commonAttachments")) {
                        if (!Array.isArray(message.commonAttachments))
                            return "commonAttachments: array expected";
                        for (let i = 0; i < message.commonAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.commonAttachments[i]);
                            if (error)
                                return "commonAttachments." + error;
                        }
                    }
                    if (message.workTaskCatalogCatalogUpdatedAt != null && message.hasOwnProperty("workTaskCatalogCatalogUpdatedAt"))
                        if (!$util.isInteger(message.workTaskCatalogCatalogUpdatedAt) && !(message.workTaskCatalogCatalogUpdatedAt && $util.isInteger(message.workTaskCatalogCatalogUpdatedAt.low) && $util.isInteger(message.workTaskCatalogCatalogUpdatedAt.high)))
                            return "workTaskCatalogCatalogUpdatedAt: integer|Long expected";
                    if (message.workTaskCatalogCatalogUpdatedBy != null && message.hasOwnProperty("workTaskCatalogCatalogUpdatedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.workTaskCatalogCatalogUpdatedBy);
                        if (error)
                            return "workTaskCatalogCatalogUpdatedBy." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a WorkTaskCatalog message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.WorkTaskCatalog} WorkTaskCatalog
                 */
                WorkTaskCatalog.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.WorkTaskCatalog)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.WorkTaskCatalog();
                    if (object.workTaskCatalogId != null)
                        message.workTaskCatalogId = String(object.workTaskCatalogId);
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.contents != null)
                        message.contents = String(object.contents);
                    if (object.approval != null)
                        message.approval = Boolean(object.approval);
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.workOrganization != null) {
                        if (typeof object.workOrganization !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.workOrganization: object expected");
                        message.workOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.workOrganization);
                    }
                    if (object.commonAttachments) {
                        if (!Array.isArray(object.commonAttachments))
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.commonAttachments: array expected");
                        message.commonAttachments = [];
                        for (let i = 0; i < object.commonAttachments.length; ++i) {
                            if (typeof object.commonAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.commonAttachments: object expected");
                            message.commonAttachments[i] = $root.sharelib.Attachment.fromObject(object.commonAttachments[i]);
                        }
                    }
                    if (object.workTaskCatalogCatalogUpdatedAt != null)
                        if ($util.Long)
                            (message.workTaskCatalogCatalogUpdatedAt = $util.Long.fromValue(object.workTaskCatalogCatalogUpdatedAt)).unsigned = false;
                        else if (typeof object.workTaskCatalogCatalogUpdatedAt === "string")
                            message.workTaskCatalogCatalogUpdatedAt = parseInt(object.workTaskCatalogCatalogUpdatedAt, 10);
                        else if (typeof object.workTaskCatalogCatalogUpdatedAt === "number")
                            message.workTaskCatalogCatalogUpdatedAt = object.workTaskCatalogCatalogUpdatedAt;
                        else if (typeof object.workTaskCatalogCatalogUpdatedAt === "object")
                            message.workTaskCatalogCatalogUpdatedAt = new $util.LongBits(object.workTaskCatalogCatalogUpdatedAt.low >>> 0, object.workTaskCatalogCatalogUpdatedAt.high >>> 0).toNumber();
                    if (object.workTaskCatalogCatalogUpdatedBy != null) {
                        if (typeof object.workTaskCatalogCatalogUpdatedBy !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskCatalog.workTaskCatalogCatalogUpdatedBy: object expected");
                        message.workTaskCatalogCatalogUpdatedBy = $root.sharelib.EmbeddedUser.fromObject(object.workTaskCatalogCatalogUpdatedBy);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a WorkTaskCatalog message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @static
                 * @param {mtechnavi.api.worktask.WorkTaskCatalog} message WorkTaskCatalog
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkTaskCatalog.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.commonAttachments = [];
                    if (options.defaults) {
                        object.workTaskCatalogId = "";
                        object.code = "";
                        object.displayName = "";
                        object.category = null;
                        object.contents = "";
                        object.approval = false;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.workTaskCatalogCatalogUpdatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.workTaskCatalogCatalogUpdatedAt = options.longs === String ? "0" : 0;
                        object.workTaskCatalogCatalogUpdatedBy = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.managementOrganization = null;
                        object.workOrganization = null;
                    }
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        object.workTaskCatalogId = message.workTaskCatalogId;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        object.contents = message.contents;
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        object.approval = message.approval;
                    if (message.commonAttachments && message.commonAttachments.length) {
                        object.commonAttachments = [];
                        for (let j = 0; j < message.commonAttachments.length; ++j)
                            object.commonAttachments[j] = $root.sharelib.Attachment.toObject(message.commonAttachments[j], options);
                    }
                    if (message.workTaskCatalogCatalogUpdatedAt != null && message.hasOwnProperty("workTaskCatalogCatalogUpdatedAt"))
                        if (typeof message.workTaskCatalogCatalogUpdatedAt === "number")
                            object.workTaskCatalogCatalogUpdatedAt = options.longs === String ? String(message.workTaskCatalogCatalogUpdatedAt) : message.workTaskCatalogCatalogUpdatedAt;
                        else
                            object.workTaskCatalogCatalogUpdatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.workTaskCatalogCatalogUpdatedAt) : options.longs === Number ? new $util.LongBits(message.workTaskCatalogCatalogUpdatedAt.low >>> 0, message.workTaskCatalogCatalogUpdatedAt.high >>> 0).toNumber() : message.workTaskCatalogCatalogUpdatedAt;
                    if (message.workTaskCatalogCatalogUpdatedBy != null && message.hasOwnProperty("workTaskCatalogCatalogUpdatedBy"))
                        object.workTaskCatalogCatalogUpdatedBy = $root.sharelib.EmbeddedUser.toObject(message.workTaskCatalogCatalogUpdatedBy, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    if (message.workOrganization != null && message.hasOwnProperty("workOrganization"))
                        object.workOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.workOrganization, options);
                    return object;
                };

                /**
                 * Converts this WorkTaskCatalog to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.WorkTaskCatalog
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkTaskCatalog.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return WorkTaskCatalog;
            })();

            worktask.WorkTask = (function() {

                /**
                 * Properties of a WorkTask.
                 * @memberof mtechnavi.api.worktask
                 * @interface IWorkTask
                 * @property {string|null} [workTaskId] WorkTask workTaskId
                 * @property {Array.<sharelib.IUserReference>|null} [systemNotificationUsers] WorkTask systemNotificationUsers
                 * @property {Long|null} [workTaskAutoName] WorkTask workTaskAutoName
                 * @property {sharelib.INameOption|null} [status] WorkTask status
                 * @property {boolean|null} [completed] WorkTask completed
                 * @property {string|null} [displayName] WorkTask displayName
                 * @property {boolean|null} [asap] WorkTask asap
                 * @property {string|null} [asapComment] WorkTask asapComment
                 * @property {mtechnavi.api.worktask.IWorkTaskCatalog|null} [workTaskCatalog] WorkTask workTaskCatalog
                 * @property {string|null} [workTaskCatalogId] WorkTask workTaskCatalogId
                 * @property {string|null} [contents] WorkTask contents
                 * @property {boolean|null} [approval] WorkTask approval
                 * @property {sharelib.IOrganizationStructureReference|null} [managementOrganization] WorkTask managementOrganization
                 * @property {sharelib.IUserReference|null} [managementStaff] WorkTask managementStaff
                 * @property {mtechnavi.api.worktask.IProcess|null} [managementStaffChangeProcess] WorkTask managementStaffChangeProcess
                 * @property {sharelib.IOrganizationStructureReference|null} [workOrganization] WorkTask workOrganization
                 * @property {sharelib.IUserReference|null} [workStaff] WorkTask workStaff
                 * @property {mtechnavi.api.worktask.IProcess|null} [workStaffChangeProcess] WorkTask workStaffChangeProcess
                 * @property {string|null} [requestRemarks] WorkTask requestRemarks
                 * @property {Array.<sharelib.IAttachment>|null} [requestAttachments] WorkTask requestAttachments
                 * @property {Long|null} [requestedAt] WorkTask requestedAt
                 * @property {sharelib.IEmbeddedUser|null} [requestedBy] WorkTask requestedBy
                 * @property {mtechnavi.api.worktask.ITicket|null} [ticket] WorkTask ticket
                 * @property {mtechnavi.api.worktask.IProcess|null} [ticketRestartProcess] WorkTask ticketRestartProcess
                 * @property {mtechnavi.api.worktask.IProcess|null} [discardProcess] WorkTask discardProcess
                 * @property {mtechnavi.api.worktask.IProcess|null} [completeProcess] WorkTask completeProcess
                 * @property {sharelib.IDatetime|null} [worktaskDueDateDt] WorkTask worktaskDueDateDt
                 * @property {mtechnavi.api.worktask.IProcess|null} [worktaskDueDateChangeProcess] WorkTask worktaskDueDateChangeProcess
                 * @property {sharelib.IDatetime|null} [worktaskTicketDueDateDt] WorkTask worktaskTicketDueDateDt
                 * @property {mtechnavi.api.worktask.IProcess|null} [worktaskTicketDueDateChangeProcess] WorkTask worktaskTicketDueDateChangeProcess
                 * @property {sharelib.IDatetime|null} [worktaskTicketScheduledDateDt] WorkTask worktaskTicketScheduledDateDt
                 * @property {mtechnavi.api.worktask.IProcess|null} [worktaskTicketScheduledDateChangeProcess] WorkTask worktaskTicketScheduledDateChangeProcess
                 * @property {sharelib.INameOption|null} [approvalStatus] WorkTask approvalStatus
                 * @property {sharelib.IApprovalRequest|null} [approvalRequest] WorkTask approvalRequest
                 * @property {sharelib.IApprovalResult|null} [approvalResult] WorkTask approvalResult
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [issuerProperties] WorkTask issuerProperties
                 * @property {Long|null} [createdAt] WorkTask createdAt
                 * @property {Long|null} [updatedAt] WorkTask updatedAt
                 * @property {Long|null} [deletedAt] WorkTask deletedAt
                 */

                /**
                 * Constructs a new WorkTask.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTask.
                 * @implements IWorkTask
                 * @constructor
                 * @param {mtechnavi.api.worktask.IWorkTask=} [properties] Properties to set
                 */
                function WorkTask(properties) {
                    this.systemNotificationUsers = [];
                    this.requestAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WorkTask workTaskId.
                 * @member {string} workTaskId
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workTaskId = "";

                /**
                 * WorkTask systemNotificationUsers.
                 * @member {Array.<sharelib.IUserReference>} systemNotificationUsers
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.systemNotificationUsers = $util.emptyArray;

                /**
                 * WorkTask workTaskAutoName.
                 * @member {Long} workTaskAutoName
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workTaskAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTask status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.status = null;

                /**
                 * WorkTask completed.
                 * @member {boolean} completed
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.completed = false;

                /**
                 * WorkTask displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.displayName = "";

                /**
                 * WorkTask asap.
                 * @member {boolean} asap
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.asap = false;

                /**
                 * WorkTask asapComment.
                 * @member {string} asapComment
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.asapComment = "";

                /**
                 * WorkTask workTaskCatalog.
                 * @member {mtechnavi.api.worktask.IWorkTaskCatalog|null|undefined} workTaskCatalog
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workTaskCatalog = null;

                /**
                 * WorkTask workTaskCatalogId.
                 * @member {string} workTaskCatalogId
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workTaskCatalogId = "";

                /**
                 * WorkTask contents.
                 * @member {string} contents
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.contents = "";

                /**
                 * WorkTask approval.
                 * @member {boolean} approval
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.approval = false;

                /**
                 * WorkTask managementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} managementOrganization
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.managementOrganization = null;

                /**
                 * WorkTask managementStaff.
                 * @member {sharelib.IUserReference|null|undefined} managementStaff
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.managementStaff = null;

                /**
                 * WorkTask managementStaffChangeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} managementStaffChangeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.managementStaffChangeProcess = null;

                /**
                 * WorkTask workOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} workOrganization
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workOrganization = null;

                /**
                 * WorkTask workStaff.
                 * @member {sharelib.IUserReference|null|undefined} workStaff
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workStaff = null;

                /**
                 * WorkTask workStaffChangeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} workStaffChangeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.workStaffChangeProcess = null;

                /**
                 * WorkTask requestRemarks.
                 * @member {string} requestRemarks
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.requestRemarks = "";

                /**
                 * WorkTask requestAttachments.
                 * @member {Array.<sharelib.IAttachment>} requestAttachments
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.requestAttachments = $util.emptyArray;

                /**
                 * WorkTask requestedAt.
                 * @member {Long} requestedAt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.requestedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTask requestedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} requestedBy
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.requestedBy = null;

                /**
                 * WorkTask ticket.
                 * @member {mtechnavi.api.worktask.ITicket|null|undefined} ticket
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.ticket = null;

                /**
                 * WorkTask ticketRestartProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} ticketRestartProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.ticketRestartProcess = null;

                /**
                 * WorkTask discardProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} discardProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.discardProcess = null;

                /**
                 * WorkTask completeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} completeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.completeProcess = null;

                /**
                 * WorkTask worktaskDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} worktaskDueDateDt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskDueDateDt = null;

                /**
                 * WorkTask worktaskDueDateChangeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} worktaskDueDateChangeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskDueDateChangeProcess = null;

                /**
                 * WorkTask worktaskTicketDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} worktaskTicketDueDateDt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskTicketDueDateDt = null;

                /**
                 * WorkTask worktaskTicketDueDateChangeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} worktaskTicketDueDateChangeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskTicketDueDateChangeProcess = null;

                /**
                 * WorkTask worktaskTicketScheduledDateDt.
                 * @member {sharelib.IDatetime|null|undefined} worktaskTicketScheduledDateDt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskTicketScheduledDateDt = null;

                /**
                 * WorkTask worktaskTicketScheduledDateChangeProcess.
                 * @member {mtechnavi.api.worktask.IProcess|null|undefined} worktaskTicketScheduledDateChangeProcess
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.worktaskTicketScheduledDateChangeProcess = null;

                /**
                 * WorkTask approvalStatus.
                 * @member {sharelib.INameOption|null|undefined} approvalStatus
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.approvalStatus = null;

                /**
                 * WorkTask approvalRequest.
                 * @member {sharelib.IApprovalRequest|null|undefined} approvalRequest
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.approvalRequest = null;

                /**
                 * WorkTask approvalResult.
                 * @member {sharelib.IApprovalResult|null|undefined} approvalResult
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.approvalResult = null;

                /**
                 * WorkTask issuerProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} issuerProperties
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.issuerProperties = null;

                /**
                 * WorkTask createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTask updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTask deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 */
                WorkTask.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a WorkTask message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WorkTask.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        if (!$util.isString(message.workTaskId))
                            return "workTaskId: string expected";
                    if (message.systemNotificationUsers != null && message.hasOwnProperty("systemNotificationUsers")) {
                        if (!Array.isArray(message.systemNotificationUsers))
                            return "systemNotificationUsers: array expected";
                        for (let i = 0; i < message.systemNotificationUsers.length; ++i) {
                            let error = $root.sharelib.UserReference.verify(message.systemNotificationUsers[i]);
                            if (error)
                                return "systemNotificationUsers." + error;
                        }
                    }
                    if (message.workTaskAutoName != null && message.hasOwnProperty("workTaskAutoName"))
                        if (!$util.isInteger(message.workTaskAutoName) && !(message.workTaskAutoName && $util.isInteger(message.workTaskAutoName.low) && $util.isInteger(message.workTaskAutoName.high)))
                            return "workTaskAutoName: integer|Long expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        if (typeof message.completed !== "boolean")
                            return "completed: boolean expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.asap != null && message.hasOwnProperty("asap"))
                        if (typeof message.asap !== "boolean")
                            return "asap: boolean expected";
                    if (message.asapComment != null && message.hasOwnProperty("asapComment"))
                        if (!$util.isString(message.asapComment))
                            return "asapComment: string expected";
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskCatalog.verify(message.workTaskCatalog);
                        if (error)
                            return "workTaskCatalog." + error;
                    }
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        if (!$util.isString(message.workTaskCatalogId))
                            return "workTaskCatalogId: string expected";
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        if (!$util.isString(message.contents))
                            return "contents: string expected";
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        if (typeof message.approval !== "boolean")
                            return "approval: boolean expected";
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.managementOrganization);
                        if (error)
                            return "managementOrganization." + error;
                    }
                    if (message.managementStaff != null && message.hasOwnProperty("managementStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.managementStaff);
                        if (error)
                            return "managementStaff." + error;
                    }
                    if (message.managementStaffChangeProcess != null && message.hasOwnProperty("managementStaffChangeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.managementStaffChangeProcess);
                        if (error)
                            return "managementStaffChangeProcess." + error;
                    }
                    if (message.workOrganization != null && message.hasOwnProperty("workOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.workOrganization);
                        if (error)
                            return "workOrganization." + error;
                    }
                    if (message.workStaff != null && message.hasOwnProperty("workStaff")) {
                        let error = $root.sharelib.UserReference.verify(message.workStaff);
                        if (error)
                            return "workStaff." + error;
                    }
                    if (message.workStaffChangeProcess != null && message.hasOwnProperty("workStaffChangeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.workStaffChangeProcess);
                        if (error)
                            return "workStaffChangeProcess." + error;
                    }
                    if (message.requestRemarks != null && message.hasOwnProperty("requestRemarks"))
                        if (!$util.isString(message.requestRemarks))
                            return "requestRemarks: string expected";
                    if (message.requestAttachments != null && message.hasOwnProperty("requestAttachments")) {
                        if (!Array.isArray(message.requestAttachments))
                            return "requestAttachments: array expected";
                        for (let i = 0; i < message.requestAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.requestAttachments[i]);
                            if (error)
                                return "requestAttachments." + error;
                        }
                    }
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (!$util.isInteger(message.requestedAt) && !(message.requestedAt && $util.isInteger(message.requestedAt.low) && $util.isInteger(message.requestedAt.high)))
                            return "requestedAt: integer|Long expected";
                    if (message.requestedBy != null && message.hasOwnProperty("requestedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.requestedBy);
                        if (error)
                            return "requestedBy." + error;
                    }
                    if (message.ticket != null && message.hasOwnProperty("ticket")) {
                        let error = $root.mtechnavi.api.worktask.Ticket.verify(message.ticket);
                        if (error)
                            return "ticket." + error;
                    }
                    if (message.ticketRestartProcess != null && message.hasOwnProperty("ticketRestartProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.ticketRestartProcess);
                        if (error)
                            return "ticketRestartProcess." + error;
                    }
                    if (message.discardProcess != null && message.hasOwnProperty("discardProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.discardProcess);
                        if (error)
                            return "discardProcess." + error;
                    }
                    if (message.completeProcess != null && message.hasOwnProperty("completeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.completeProcess);
                        if (error)
                            return "completeProcess." + error;
                    }
                    if (message.worktaskDueDateDt != null && message.hasOwnProperty("worktaskDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.worktaskDueDateDt);
                        if (error)
                            return "worktaskDueDateDt." + error;
                    }
                    if (message.worktaskDueDateChangeProcess != null && message.hasOwnProperty("worktaskDueDateChangeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.worktaskDueDateChangeProcess);
                        if (error)
                            return "worktaskDueDateChangeProcess." + error;
                    }
                    if (message.worktaskTicketDueDateDt != null && message.hasOwnProperty("worktaskTicketDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.worktaskTicketDueDateDt);
                        if (error)
                            return "worktaskTicketDueDateDt." + error;
                    }
                    if (message.worktaskTicketDueDateChangeProcess != null && message.hasOwnProperty("worktaskTicketDueDateChangeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.worktaskTicketDueDateChangeProcess);
                        if (error)
                            return "worktaskTicketDueDateChangeProcess." + error;
                    }
                    if (message.worktaskTicketScheduledDateDt != null && message.hasOwnProperty("worktaskTicketScheduledDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.worktaskTicketScheduledDateDt);
                        if (error)
                            return "worktaskTicketScheduledDateDt." + error;
                    }
                    if (message.worktaskTicketScheduledDateChangeProcess != null && message.hasOwnProperty("worktaskTicketScheduledDateChangeProcess")) {
                        let error = $root.mtechnavi.api.worktask.Process.verify(message.worktaskTicketScheduledDateChangeProcess);
                        if (error)
                            return "worktaskTicketScheduledDateChangeProcess." + error;
                    }
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus")) {
                        let error = $root.sharelib.NameOption.verify(message.approvalStatus);
                        if (error)
                            return "approvalStatus." + error;
                    }
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest")) {
                        let error = $root.sharelib.ApprovalRequest.verify(message.approvalRequest);
                        if (error)
                            return "approvalRequest." + error;
                    }
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult")) {
                        let error = $root.sharelib.ApprovalResult.verify(message.approvalResult);
                        if (error)
                            return "approvalResult." + error;
                    }
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.issuerProperties);
                        if (error)
                            return "issuerProperties." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a WorkTask message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.WorkTask} WorkTask
                 */
                WorkTask.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.WorkTask)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.WorkTask();
                    if (object.workTaskId != null)
                        message.workTaskId = String(object.workTaskId);
                    if (object.systemNotificationUsers) {
                        if (!Array.isArray(object.systemNotificationUsers))
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.systemNotificationUsers: array expected");
                        message.systemNotificationUsers = [];
                        for (let i = 0; i < object.systemNotificationUsers.length; ++i) {
                            if (typeof object.systemNotificationUsers[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.WorkTask.systemNotificationUsers: object expected");
                            message.systemNotificationUsers[i] = $root.sharelib.UserReference.fromObject(object.systemNotificationUsers[i]);
                        }
                    }
                    if (object.workTaskAutoName != null)
                        if ($util.Long)
                            (message.workTaskAutoName = $util.Long.fromValue(object.workTaskAutoName)).unsigned = false;
                        else if (typeof object.workTaskAutoName === "string")
                            message.workTaskAutoName = parseInt(object.workTaskAutoName, 10);
                        else if (typeof object.workTaskAutoName === "number")
                            message.workTaskAutoName = object.workTaskAutoName;
                        else if (typeof object.workTaskAutoName === "object")
                            message.workTaskAutoName = new $util.LongBits(object.workTaskAutoName.low >>> 0, object.workTaskAutoName.high >>> 0).toNumber();
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.completed != null)
                        message.completed = Boolean(object.completed);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.asap != null)
                        message.asap = Boolean(object.asap);
                    if (object.asapComment != null)
                        message.asapComment = String(object.asapComment);
                    if (object.workTaskCatalog != null) {
                        if (typeof object.workTaskCatalog !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.workTaskCatalog: object expected");
                        message.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.fromObject(object.workTaskCatalog);
                    }
                    if (object.workTaskCatalogId != null)
                        message.workTaskCatalogId = String(object.workTaskCatalogId);
                    if (object.contents != null)
                        message.contents = String(object.contents);
                    if (object.approval != null)
                        message.approval = Boolean(object.approval);
                    if (object.managementOrganization != null) {
                        if (typeof object.managementOrganization !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.managementOrganization: object expected");
                        message.managementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.managementOrganization);
                    }
                    if (object.managementStaff != null) {
                        if (typeof object.managementStaff !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.managementStaff: object expected");
                        message.managementStaff = $root.sharelib.UserReference.fromObject(object.managementStaff);
                    }
                    if (object.managementStaffChangeProcess != null) {
                        if (typeof object.managementStaffChangeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.managementStaffChangeProcess: object expected");
                        message.managementStaffChangeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.managementStaffChangeProcess);
                    }
                    if (object.workOrganization != null) {
                        if (typeof object.workOrganization !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.workOrganization: object expected");
                        message.workOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.workOrganization);
                    }
                    if (object.workStaff != null) {
                        if (typeof object.workStaff !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.workStaff: object expected");
                        message.workStaff = $root.sharelib.UserReference.fromObject(object.workStaff);
                    }
                    if (object.workStaffChangeProcess != null) {
                        if (typeof object.workStaffChangeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.workStaffChangeProcess: object expected");
                        message.workStaffChangeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.workStaffChangeProcess);
                    }
                    if (object.requestRemarks != null)
                        message.requestRemarks = String(object.requestRemarks);
                    if (object.requestAttachments) {
                        if (!Array.isArray(object.requestAttachments))
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.requestAttachments: array expected");
                        message.requestAttachments = [];
                        for (let i = 0; i < object.requestAttachments.length; ++i) {
                            if (typeof object.requestAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.WorkTask.requestAttachments: object expected");
                            message.requestAttachments[i] = $root.sharelib.Attachment.fromObject(object.requestAttachments[i]);
                        }
                    }
                    if (object.requestedAt != null)
                        if ($util.Long)
                            (message.requestedAt = $util.Long.fromValue(object.requestedAt)).unsigned = false;
                        else if (typeof object.requestedAt === "string")
                            message.requestedAt = parseInt(object.requestedAt, 10);
                        else if (typeof object.requestedAt === "number")
                            message.requestedAt = object.requestedAt;
                        else if (typeof object.requestedAt === "object")
                            message.requestedAt = new $util.LongBits(object.requestedAt.low >>> 0, object.requestedAt.high >>> 0).toNumber();
                    if (object.requestedBy != null) {
                        if (typeof object.requestedBy !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.requestedBy: object expected");
                        message.requestedBy = $root.sharelib.EmbeddedUser.fromObject(object.requestedBy);
                    }
                    if (object.ticket != null) {
                        if (typeof object.ticket !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.ticket: object expected");
                        message.ticket = $root.mtechnavi.api.worktask.Ticket.fromObject(object.ticket);
                    }
                    if (object.ticketRestartProcess != null) {
                        if (typeof object.ticketRestartProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.ticketRestartProcess: object expected");
                        message.ticketRestartProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.ticketRestartProcess);
                    }
                    if (object.discardProcess != null) {
                        if (typeof object.discardProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.discardProcess: object expected");
                        message.discardProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.discardProcess);
                    }
                    if (object.completeProcess != null) {
                        if (typeof object.completeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.completeProcess: object expected");
                        message.completeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.completeProcess);
                    }
                    if (object.worktaskDueDateDt != null) {
                        if (typeof object.worktaskDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskDueDateDt: object expected");
                        message.worktaskDueDateDt = $root.sharelib.Datetime.fromObject(object.worktaskDueDateDt);
                    }
                    if (object.worktaskDueDateChangeProcess != null) {
                        if (typeof object.worktaskDueDateChangeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskDueDateChangeProcess: object expected");
                        message.worktaskDueDateChangeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.worktaskDueDateChangeProcess);
                    }
                    if (object.worktaskTicketDueDateDt != null) {
                        if (typeof object.worktaskTicketDueDateDt !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskTicketDueDateDt: object expected");
                        message.worktaskTicketDueDateDt = $root.sharelib.Datetime.fromObject(object.worktaskTicketDueDateDt);
                    }
                    if (object.worktaskTicketDueDateChangeProcess != null) {
                        if (typeof object.worktaskTicketDueDateChangeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskTicketDueDateChangeProcess: object expected");
                        message.worktaskTicketDueDateChangeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.worktaskTicketDueDateChangeProcess);
                    }
                    if (object.worktaskTicketScheduledDateDt != null) {
                        if (typeof object.worktaskTicketScheduledDateDt !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskTicketScheduledDateDt: object expected");
                        message.worktaskTicketScheduledDateDt = $root.sharelib.Datetime.fromObject(object.worktaskTicketScheduledDateDt);
                    }
                    if (object.worktaskTicketScheduledDateChangeProcess != null) {
                        if (typeof object.worktaskTicketScheduledDateChangeProcess !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.worktaskTicketScheduledDateChangeProcess: object expected");
                        message.worktaskTicketScheduledDateChangeProcess = $root.mtechnavi.api.worktask.Process.fromObject(object.worktaskTicketScheduledDateChangeProcess);
                    }
                    if (object.approvalStatus != null) {
                        if (typeof object.approvalStatus !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.approvalStatus: object expected");
                        message.approvalStatus = $root.sharelib.NameOption.fromObject(object.approvalStatus);
                    }
                    if (object.approvalRequest != null) {
                        if (typeof object.approvalRequest !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.approvalRequest: object expected");
                        message.approvalRequest = $root.sharelib.ApprovalRequest.fromObject(object.approvalRequest);
                    }
                    if (object.approvalResult != null) {
                        if (typeof object.approvalResult !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.approvalResult: object expected");
                        message.approvalResult = $root.sharelib.ApprovalResult.fromObject(object.approvalResult);
                    }
                    if (object.issuerProperties != null) {
                        if (typeof object.issuerProperties !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTask.issuerProperties: object expected");
                        message.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.issuerProperties);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a WorkTask message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @static
                 * @param {mtechnavi.api.worktask.WorkTask} message WorkTask
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkTask.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.systemNotificationUsers = [];
                        object.requestAttachments = [];
                    }
                    if (options.defaults) {
                        object.workTaskId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.workTaskAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.workTaskAutoName = options.longs === String ? "0" : 0;
                        object.status = null;
                        object.completed = false;
                        object.displayName = "";
                        object.asap = false;
                        object.asapComment = "";
                        object.workTaskCatalog = null;
                        object.workTaskCatalogId = "";
                        object.contents = "";
                        object.approval = false;
                        object.managementStaffChangeProcess = null;
                        object.workStaffChangeProcess = null;
                        object.requestRemarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.requestedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.requestedAt = options.longs === String ? "0" : 0;
                        object.requestedBy = null;
                        object.ticket = null;
                        object.ticketRestartProcess = null;
                        object.discardProcess = null;
                        object.completeProcess = null;
                        object.worktaskDueDateDt = null;
                        object.worktaskDueDateChangeProcess = null;
                        object.worktaskTicketDueDateDt = null;
                        object.worktaskTicketDueDateChangeProcess = null;
                        object.worktaskTicketScheduledDateDt = null;
                        object.worktaskTicketScheduledDateChangeProcess = null;
                        object.approvalStatus = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        object.managementOrganization = null;
                        object.managementStaff = null;
                        object.workOrganization = null;
                        object.workStaff = null;
                        object.approvalRequest = null;
                        object.approvalResult = null;
                        object.issuerProperties = null;
                    }
                    if (message.workTaskId != null && message.hasOwnProperty("workTaskId"))
                        object.workTaskId = message.workTaskId;
                    if (message.systemNotificationUsers && message.systemNotificationUsers.length) {
                        object.systemNotificationUsers = [];
                        for (let j = 0; j < message.systemNotificationUsers.length; ++j)
                            object.systemNotificationUsers[j] = $root.sharelib.UserReference.toObject(message.systemNotificationUsers[j], options);
                    }
                    if (message.workTaskAutoName != null && message.hasOwnProperty("workTaskAutoName"))
                        if (typeof message.workTaskAutoName === "number")
                            object.workTaskAutoName = options.longs === String ? String(message.workTaskAutoName) : message.workTaskAutoName;
                        else
                            object.workTaskAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.workTaskAutoName) : options.longs === Number ? new $util.LongBits(message.workTaskAutoName.low >>> 0, message.workTaskAutoName.high >>> 0).toNumber() : message.workTaskAutoName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = message.completed;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.asap != null && message.hasOwnProperty("asap"))
                        object.asap = message.asap;
                    if (message.asapComment != null && message.hasOwnProperty("asapComment"))
                        object.asapComment = message.asapComment;
                    if (message.workTaskCatalog != null && message.hasOwnProperty("workTaskCatalog"))
                        object.workTaskCatalog = $root.mtechnavi.api.worktask.WorkTaskCatalog.toObject(message.workTaskCatalog, options);
                    if (message.workTaskCatalogId != null && message.hasOwnProperty("workTaskCatalogId"))
                        object.workTaskCatalogId = message.workTaskCatalogId;
                    if (message.contents != null && message.hasOwnProperty("contents"))
                        object.contents = message.contents;
                    if (message.approval != null && message.hasOwnProperty("approval"))
                        object.approval = message.approval;
                    if (message.managementStaffChangeProcess != null && message.hasOwnProperty("managementStaffChangeProcess"))
                        object.managementStaffChangeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.managementStaffChangeProcess, options);
                    if (message.workStaffChangeProcess != null && message.hasOwnProperty("workStaffChangeProcess"))
                        object.workStaffChangeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.workStaffChangeProcess, options);
                    if (message.requestRemarks != null && message.hasOwnProperty("requestRemarks"))
                        object.requestRemarks = message.requestRemarks;
                    if (message.requestAttachments && message.requestAttachments.length) {
                        object.requestAttachments = [];
                        for (let j = 0; j < message.requestAttachments.length; ++j)
                            object.requestAttachments[j] = $root.sharelib.Attachment.toObject(message.requestAttachments[j], options);
                    }
                    if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                        if (typeof message.requestedAt === "number")
                            object.requestedAt = options.longs === String ? String(message.requestedAt) : message.requestedAt;
                        else
                            object.requestedAt = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAt) : options.longs === Number ? new $util.LongBits(message.requestedAt.low >>> 0, message.requestedAt.high >>> 0).toNumber() : message.requestedAt;
                    if (message.requestedBy != null && message.hasOwnProperty("requestedBy"))
                        object.requestedBy = $root.sharelib.EmbeddedUser.toObject(message.requestedBy, options);
                    if (message.ticket != null && message.hasOwnProperty("ticket"))
                        object.ticket = $root.mtechnavi.api.worktask.Ticket.toObject(message.ticket, options);
                    if (message.ticketRestartProcess != null && message.hasOwnProperty("ticketRestartProcess"))
                        object.ticketRestartProcess = $root.mtechnavi.api.worktask.Process.toObject(message.ticketRestartProcess, options);
                    if (message.discardProcess != null && message.hasOwnProperty("discardProcess"))
                        object.discardProcess = $root.mtechnavi.api.worktask.Process.toObject(message.discardProcess, options);
                    if (message.completeProcess != null && message.hasOwnProperty("completeProcess"))
                        object.completeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.completeProcess, options);
                    if (message.worktaskDueDateDt != null && message.hasOwnProperty("worktaskDueDateDt"))
                        object.worktaskDueDateDt = $root.sharelib.Datetime.toObject(message.worktaskDueDateDt, options);
                    if (message.worktaskDueDateChangeProcess != null && message.hasOwnProperty("worktaskDueDateChangeProcess"))
                        object.worktaskDueDateChangeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.worktaskDueDateChangeProcess, options);
                    if (message.worktaskTicketDueDateDt != null && message.hasOwnProperty("worktaskTicketDueDateDt"))
                        object.worktaskTicketDueDateDt = $root.sharelib.Datetime.toObject(message.worktaskTicketDueDateDt, options);
                    if (message.worktaskTicketDueDateChangeProcess != null && message.hasOwnProperty("worktaskTicketDueDateChangeProcess"))
                        object.worktaskTicketDueDateChangeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.worktaskTicketDueDateChangeProcess, options);
                    if (message.worktaskTicketScheduledDateDt != null && message.hasOwnProperty("worktaskTicketScheduledDateDt"))
                        object.worktaskTicketScheduledDateDt = $root.sharelib.Datetime.toObject(message.worktaskTicketScheduledDateDt, options);
                    if (message.worktaskTicketScheduledDateChangeProcess != null && message.hasOwnProperty("worktaskTicketScheduledDateChangeProcess"))
                        object.worktaskTicketScheduledDateChangeProcess = $root.mtechnavi.api.worktask.Process.toObject(message.worktaskTicketScheduledDateChangeProcess, options);
                    if (message.approvalStatus != null && message.hasOwnProperty("approvalStatus"))
                        object.approvalStatus = $root.sharelib.NameOption.toObject(message.approvalStatus, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.managementOrganization != null && message.hasOwnProperty("managementOrganization"))
                        object.managementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.managementOrganization, options);
                    if (message.managementStaff != null && message.hasOwnProperty("managementStaff"))
                        object.managementStaff = $root.sharelib.UserReference.toObject(message.managementStaff, options);
                    if (message.workOrganization != null && message.hasOwnProperty("workOrganization"))
                        object.workOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.workOrganization, options);
                    if (message.workStaff != null && message.hasOwnProperty("workStaff"))
                        object.workStaff = $root.sharelib.UserReference.toObject(message.workStaff, options);
                    if (message.approvalRequest != null && message.hasOwnProperty("approvalRequest"))
                        object.approvalRequest = $root.sharelib.ApprovalRequest.toObject(message.approvalRequest, options);
                    if (message.approvalResult != null && message.hasOwnProperty("approvalResult"))
                        object.approvalResult = $root.sharelib.ApprovalResult.toObject(message.approvalResult, options);
                    if (message.issuerProperties != null && message.hasOwnProperty("issuerProperties"))
                        object.issuerProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.issuerProperties, options);
                    return object;
                };

                /**
                 * Converts this WorkTask to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.WorkTask
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkTask.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return WorkTask;
            })();

            worktask.WorkTaskRelation = (function() {

                /**
                 * Properties of a WorkTaskRelation.
                 * @memberof mtechnavi.api.worktask
                 * @interface IWorkTaskRelation
                 * @property {string|null} [workTaskRelationId] WorkTaskRelation workTaskRelationId
                 * @property {string|null} [linkedWorkTaskId] WorkTaskRelation linkedWorkTaskId
                 * @property {string|null} [urn] WorkTaskRelation urn
                 * @property {string|null} [typeName] WorkTaskRelation typeName
                 * @property {string|null} [recordId] WorkTaskRelation recordId
                 * @property {sharelib.INameOption|null} [systemCategory] WorkTaskRelation systemCategory
                 * @property {Long|null} [createdAt] WorkTaskRelation createdAt
                 * @property {Long|null} [updatedAt] WorkTaskRelation updatedAt
                 * @property {Long|null} [deletedAt] WorkTaskRelation deletedAt
                 */

                /**
                 * Constructs a new WorkTaskRelation.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTaskRelation.
                 * @implements IWorkTaskRelation
                 * @constructor
                 * @param {mtechnavi.api.worktask.IWorkTaskRelation=} [properties] Properties to set
                 */
                function WorkTaskRelation(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WorkTaskRelation workTaskRelationId.
                 * @member {string} workTaskRelationId
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.workTaskRelationId = "";

                /**
                 * WorkTaskRelation linkedWorkTaskId.
                 * @member {string} linkedWorkTaskId
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.linkedWorkTaskId = "";

                /**
                 * WorkTaskRelation urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.urn = "";

                /**
                 * WorkTaskRelation typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.typeName = "";

                /**
                 * WorkTaskRelation recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.recordId = "";

                /**
                 * WorkTaskRelation systemCategory.
                 * @member {sharelib.INameOption|null|undefined} systemCategory
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.systemCategory = null;

                /**
                 * WorkTaskRelation createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTaskRelation updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * WorkTaskRelation deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 */
                WorkTaskRelation.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a WorkTaskRelation message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WorkTaskRelation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskRelationId != null && message.hasOwnProperty("workTaskRelationId"))
                        if (!$util.isString(message.workTaskRelationId))
                            return "workTaskRelationId: string expected";
                    if (message.linkedWorkTaskId != null && message.hasOwnProperty("linkedWorkTaskId"))
                        if (!$util.isString(message.linkedWorkTaskId))
                            return "linkedWorkTaskId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.systemCategory != null && message.hasOwnProperty("systemCategory")) {
                        let error = $root.sharelib.NameOption.verify(message.systemCategory);
                        if (error)
                            return "systemCategory." + error;
                    }
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a WorkTaskRelation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.WorkTaskRelation} WorkTaskRelation
                 */
                WorkTaskRelation.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.WorkTaskRelation)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.WorkTaskRelation();
                    if (object.workTaskRelationId != null)
                        message.workTaskRelationId = String(object.workTaskRelationId);
                    if (object.linkedWorkTaskId != null)
                        message.linkedWorkTaskId = String(object.linkedWorkTaskId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.systemCategory != null) {
                        if (typeof object.systemCategory !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskRelation.systemCategory: object expected");
                        message.systemCategory = $root.sharelib.NameOption.fromObject(object.systemCategory);
                    }
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a WorkTaskRelation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @static
                 * @param {mtechnavi.api.worktask.WorkTaskRelation} message WorkTaskRelation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkTaskRelation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskRelationId = "";
                        object.linkedWorkTaskId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.systemCategory = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                    }
                    if (message.workTaskRelationId != null && message.hasOwnProperty("workTaskRelationId"))
                        object.workTaskRelationId = message.workTaskRelationId;
                    if (message.linkedWorkTaskId != null && message.hasOwnProperty("linkedWorkTaskId"))
                        object.linkedWorkTaskId = message.linkedWorkTaskId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.systemCategory != null && message.hasOwnProperty("systemCategory"))
                        object.systemCategory = $root.sharelib.NameOption.toObject(message.systemCategory, options);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    return object;
                };

                /**
                 * Converts this WorkTaskRelation to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.WorkTaskRelation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkTaskRelation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return WorkTaskRelation;
            })();

            worktask.WorkTaskRelationContent = (function() {

                /**
                 * Properties of a WorkTaskRelationContent.
                 * @memberof mtechnavi.api.worktask
                 * @interface IWorkTaskRelationContent
                 * @property {mtechnavi.api.worktask.IWorkTaskRelation|null} [workTaskRelation] WorkTaskRelationContent workTaskRelation
                 * @property {string|null} [code] WorkTaskRelationContent code
                 * @property {string|null} [displayName] WorkTaskRelationContent displayName
                 * @property {sharelib.INameOption|null} [status] WorkTaskRelationContent status
                 */

                /**
                 * Constructs a new WorkTaskRelationContent.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a WorkTaskRelationContent.
                 * @implements IWorkTaskRelationContent
                 * @constructor
                 * @param {mtechnavi.api.worktask.IWorkTaskRelationContent=} [properties] Properties to set
                 */
                function WorkTaskRelationContent(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WorkTaskRelationContent workTaskRelation.
                 * @member {mtechnavi.api.worktask.IWorkTaskRelation|null|undefined} workTaskRelation
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @instance
                 */
                WorkTaskRelationContent.prototype.workTaskRelation = null;

                /**
                 * WorkTaskRelationContent code.
                 * @member {string} code
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @instance
                 */
                WorkTaskRelationContent.prototype.code = "";

                /**
                 * WorkTaskRelationContent displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @instance
                 */
                WorkTaskRelationContent.prototype.displayName = "";

                /**
                 * WorkTaskRelationContent status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @instance
                 */
                WorkTaskRelationContent.prototype.status = null;

                /**
                 * Verifies a WorkTaskRelationContent message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WorkTaskRelationContent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation")) {
                        let error = $root.mtechnavi.api.worktask.WorkTaskRelation.verify(message.workTaskRelation);
                        if (error)
                            return "workTaskRelation." + error;
                    }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    return null;
                };

                /**
                 * Creates a WorkTaskRelationContent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.WorkTaskRelationContent} WorkTaskRelationContent
                 */
                WorkTaskRelationContent.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.WorkTaskRelationContent)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.WorkTaskRelationContent();
                    if (object.workTaskRelation != null) {
                        if (typeof object.workTaskRelation !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskRelationContent.workTaskRelation: object expected");
                        message.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.fromObject(object.workTaskRelation);
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.api.worktask.WorkTaskRelationContent.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WorkTaskRelationContent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @static
                 * @param {mtechnavi.api.worktask.WorkTaskRelationContent} message WorkTaskRelationContent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WorkTaskRelationContent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.workTaskRelation = null;
                        object.code = "";
                        object.displayName = "";
                        object.status = null;
                    }
                    if (message.workTaskRelation != null && message.hasOwnProperty("workTaskRelation"))
                        object.workTaskRelation = $root.mtechnavi.api.worktask.WorkTaskRelation.toObject(message.workTaskRelation, options);
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    return object;
                };

                /**
                 * Converts this WorkTaskRelationContent to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.WorkTaskRelationContent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WorkTaskRelationContent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return WorkTaskRelationContent;
            })();

            worktask.History = (function() {

                /**
                 * Properties of a History.
                 * @memberof mtechnavi.api.worktask
                 * @interface IHistory
                 * @property {string|null} [historyId] History historyId
                 * @property {string|null} [urn] History urn
                 * @property {string|null} [typeName] History typeName
                 * @property {string|null} [recordId] History recordId
                 * @property {string|null} [historyCategoryName] History historyCategoryName
                 * @property {string|null} [contentName] History contentName
                 * @property {Array.<string>|null} [contentParamaters] History contentParamaters
                 * @property {Long|null} [occurredAt] History occurredAt
                 * @property {Long|null} [level] History level
                 * @property {Long|null} [createdAt] History createdAt
                 * @property {Long|null} [updatedAt] History updatedAt
                 * @property {Long|null} [deletedAt] History deletedAt
                 */

                /**
                 * Constructs a new History.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a History.
                 * @implements IHistory
                 * @constructor
                 * @param {mtechnavi.api.worktask.IHistory=} [properties] Properties to set
                 */
                function History(properties) {
                    this.contentParamaters = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * History historyId.
                 * @member {string} historyId
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.historyId = "";

                /**
                 * History urn.
                 * @member {string} urn
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.urn = "";

                /**
                 * History typeName.
                 * @member {string} typeName
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.typeName = "";

                /**
                 * History recordId.
                 * @member {string} recordId
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.recordId = "";

                /**
                 * History historyCategoryName.
                 * @member {string} historyCategoryName
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.historyCategoryName = "";

                /**
                 * History contentName.
                 * @member {string} contentName
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.contentName = "";

                /**
                 * History contentParamaters.
                 * @member {Array.<string>} contentParamaters
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.contentParamaters = $util.emptyArray;

                /**
                 * History occurredAt.
                 * @member {Long} occurredAt
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.occurredAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History level.
                 * @member {Long} level
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.level = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History createdAt.
                 * @member {Long} createdAt
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * History deletedAt.
                 * @member {Long} deletedAt
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 */
                History.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Verifies a History message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.History
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                History.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        if (!$util.isString(message.historyId))
                            return "historyId: string expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        if (!$util.isString(message.typeName))
                            return "typeName: string expected";
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        if (!$util.isString(message.recordId))
                            return "recordId: string expected";
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        if (!$util.isString(message.historyCategoryName))
                            return "historyCategoryName: string expected";
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        if (!$util.isString(message.contentName))
                            return "contentName: string expected";
                    if (message.contentParamaters != null && message.hasOwnProperty("contentParamaters")) {
                        if (!Array.isArray(message.contentParamaters))
                            return "contentParamaters: array expected";
                        for (let i = 0; i < message.contentParamaters.length; ++i)
                            if (!$util.isString(message.contentParamaters[i]))
                                return "contentParamaters: string[] expected";
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (!$util.isInteger(message.occurredAt) && !(message.occurredAt && $util.isInteger(message.occurredAt.low) && $util.isInteger(message.occurredAt.high)))
                            return "occurredAt: integer|Long expected";
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (!$util.isInteger(message.level) && !(message.level && $util.isInteger(message.level.low) && $util.isInteger(message.level.high)))
                            return "level: integer|Long expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                            return "createdAt: integer|Long expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                            return "deletedAt: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a History message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.History
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.History} History
                 */
                History.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.History)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.History();
                    if (object.historyId != null)
                        message.historyId = String(object.historyId);
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.typeName != null)
                        message.typeName = String(object.typeName);
                    if (object.recordId != null)
                        message.recordId = String(object.recordId);
                    if (object.historyCategoryName != null)
                        message.historyCategoryName = String(object.historyCategoryName);
                    if (object.contentName != null)
                        message.contentName = String(object.contentName);
                    if (object.contentParamaters) {
                        if (!Array.isArray(object.contentParamaters))
                            throw TypeError(".mtechnavi.api.worktask.History.contentParamaters: array expected");
                        message.contentParamaters = [];
                        for (let i = 0; i < object.contentParamaters.length; ++i)
                            message.contentParamaters[i] = String(object.contentParamaters[i]);
                    }
                    if (object.occurredAt != null)
                        if ($util.Long)
                            (message.occurredAt = $util.Long.fromValue(object.occurredAt)).unsigned = false;
                        else if (typeof object.occurredAt === "string")
                            message.occurredAt = parseInt(object.occurredAt, 10);
                        else if (typeof object.occurredAt === "number")
                            message.occurredAt = object.occurredAt;
                        else if (typeof object.occurredAt === "object")
                            message.occurredAt = new $util.LongBits(object.occurredAt.low >>> 0, object.occurredAt.high >>> 0).toNumber();
                    if (object.level != null)
                        if ($util.Long)
                            (message.level = $util.Long.fromValue(object.level)).unsigned = false;
                        else if (typeof object.level === "string")
                            message.level = parseInt(object.level, 10);
                        else if (typeof object.level === "number")
                            message.level = object.level;
                        else if (typeof object.level === "object")
                            message.level = new $util.LongBits(object.level.low >>> 0, object.level.high >>> 0).toNumber();
                    if (object.createdAt != null)
                        if ($util.Long)
                            (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                        else if (typeof object.createdAt === "string")
                            message.createdAt = parseInt(object.createdAt, 10);
                        else if (typeof object.createdAt === "number")
                            message.createdAt = object.createdAt;
                        else if (typeof object.createdAt === "object")
                            message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.deletedAt != null)
                        if ($util.Long)
                            (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                        else if (typeof object.deletedAt === "string")
                            message.deletedAt = parseInt(object.deletedAt, 10);
                        else if (typeof object.deletedAt === "number")
                            message.deletedAt = object.deletedAt;
                        else if (typeof object.deletedAt === "object")
                            message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a History message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.History
                 * @static
                 * @param {mtechnavi.api.worktask.History} message History
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                History.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.contentParamaters = [];
                    if (options.defaults) {
                        object.historyId = "";
                        object.urn = "";
                        object.typeName = "";
                        object.recordId = "";
                        object.historyCategoryName = "";
                        object.contentName = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.occurredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.occurredAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.createdAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deletedAt = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.level = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.level = options.longs === String ? "0" : 0;
                    }
                    if (message.historyId != null && message.hasOwnProperty("historyId"))
                        object.historyId = message.historyId;
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.typeName != null && message.hasOwnProperty("typeName"))
                        object.typeName = message.typeName;
                    if (message.recordId != null && message.hasOwnProperty("recordId"))
                        object.recordId = message.recordId;
                    if (message.historyCategoryName != null && message.hasOwnProperty("historyCategoryName"))
                        object.historyCategoryName = message.historyCategoryName;
                    if (message.contentName != null && message.hasOwnProperty("contentName"))
                        object.contentName = message.contentName;
                    if (message.contentParamaters && message.contentParamaters.length) {
                        object.contentParamaters = [];
                        for (let j = 0; j < message.contentParamaters.length; ++j)
                            object.contentParamaters[j] = message.contentParamaters[j];
                    }
                    if (message.occurredAt != null && message.hasOwnProperty("occurredAt"))
                        if (typeof message.occurredAt === "number")
                            object.occurredAt = options.longs === String ? String(message.occurredAt) : message.occurredAt;
                        else
                            object.occurredAt = options.longs === String ? $util.Long.prototype.toString.call(message.occurredAt) : options.longs === Number ? new $util.LongBits(message.occurredAt.low >>> 0, message.occurredAt.high >>> 0).toNumber() : message.occurredAt;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        if (typeof message.createdAt === "number")
                            object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                        else
                            object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                        if (typeof message.deletedAt === "number")
                            object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                        else
                            object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
                    if (message.level != null && message.hasOwnProperty("level"))
                        if (typeof message.level === "number")
                            object.level = options.longs === String ? String(message.level) : message.level;
                        else
                            object.level = options.longs === String ? $util.Long.prototype.toString.call(message.level) : options.longs === Number ? new $util.LongBits(message.level.low >>> 0, message.level.high >>> 0).toNumber() : message.level;
                    return object;
                };

                /**
                 * Converts this History to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.History
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                History.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return History;
            })();

            worktask.Ticket = (function() {

                /**
                 * Properties of a Ticket.
                 * @memberof mtechnavi.api.worktask
                 * @interface ITicket
                 * @property {string|null} [remarks] Ticket remarks
                 * @property {string|null} [resultRemarks] Ticket resultRemarks
                 * @property {Array.<sharelib.IAttachment>|null} [resultAttachments] Ticket resultAttachments
                 * @property {Long|null} [completedAt] Ticket completedAt
                 * @property {sharelib.IEmbeddedUser|null} [completedBy] Ticket completedBy
                 */

                /**
                 * Constructs a new Ticket.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a Ticket.
                 * @implements ITicket
                 * @constructor
                 * @param {mtechnavi.api.worktask.ITicket=} [properties] Properties to set
                 */
                function Ticket(properties) {
                    this.resultAttachments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Ticket remarks.
                 * @member {string} remarks
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 */
                Ticket.prototype.remarks = "";

                /**
                 * Ticket resultRemarks.
                 * @member {string} resultRemarks
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 */
                Ticket.prototype.resultRemarks = "";

                /**
                 * Ticket resultAttachments.
                 * @member {Array.<sharelib.IAttachment>} resultAttachments
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 */
                Ticket.prototype.resultAttachments = $util.emptyArray;

                /**
                 * Ticket completedAt.
                 * @member {Long} completedAt
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 */
                Ticket.prototype.completedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Ticket completedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} completedBy
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 */
                Ticket.prototype.completedBy = null;

                /**
                 * Verifies a Ticket message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Ticket.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        if (!$util.isString(message.remarks))
                            return "remarks: string expected";
                    if (message.resultRemarks != null && message.hasOwnProperty("resultRemarks"))
                        if (!$util.isString(message.resultRemarks))
                            return "resultRemarks: string expected";
                    if (message.resultAttachments != null && message.hasOwnProperty("resultAttachments")) {
                        if (!Array.isArray(message.resultAttachments))
                            return "resultAttachments: array expected";
                        for (let i = 0; i < message.resultAttachments.length; ++i) {
                            let error = $root.sharelib.Attachment.verify(message.resultAttachments[i]);
                            if (error)
                                return "resultAttachments." + error;
                        }
                    }
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (!$util.isInteger(message.completedAt) && !(message.completedAt && $util.isInteger(message.completedAt.low) && $util.isInteger(message.completedAt.high)))
                            return "completedAt: integer|Long expected";
                    if (message.completedBy != null && message.hasOwnProperty("completedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.completedBy);
                        if (error)
                            return "completedBy." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Ticket message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.Ticket} Ticket
                 */
                Ticket.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.Ticket)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.Ticket();
                    if (object.remarks != null)
                        message.remarks = String(object.remarks);
                    if (object.resultRemarks != null)
                        message.resultRemarks = String(object.resultRemarks);
                    if (object.resultAttachments) {
                        if (!Array.isArray(object.resultAttachments))
                            throw TypeError(".mtechnavi.api.worktask.Ticket.resultAttachments: array expected");
                        message.resultAttachments = [];
                        for (let i = 0; i < object.resultAttachments.length; ++i) {
                            if (typeof object.resultAttachments[i] !== "object")
                                throw TypeError(".mtechnavi.api.worktask.Ticket.resultAttachments: object expected");
                            message.resultAttachments[i] = $root.sharelib.Attachment.fromObject(object.resultAttachments[i]);
                        }
                    }
                    if (object.completedAt != null)
                        if ($util.Long)
                            (message.completedAt = $util.Long.fromValue(object.completedAt)).unsigned = false;
                        else if (typeof object.completedAt === "string")
                            message.completedAt = parseInt(object.completedAt, 10);
                        else if (typeof object.completedAt === "number")
                            message.completedAt = object.completedAt;
                        else if (typeof object.completedAt === "object")
                            message.completedAt = new $util.LongBits(object.completedAt.low >>> 0, object.completedAt.high >>> 0).toNumber();
                    if (object.completedBy != null) {
                        if (typeof object.completedBy !== "object")
                            throw TypeError(".mtechnavi.api.worktask.Ticket.completedBy: object expected");
                        message.completedBy = $root.sharelib.EmbeddedUser.fromObject(object.completedBy);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Ticket message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @static
                 * @param {mtechnavi.api.worktask.Ticket} message Ticket
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Ticket.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.resultAttachments = [];
                    if (options.defaults) {
                        object.remarks = "";
                        object.resultRemarks = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.completedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.completedAt = options.longs === String ? "0" : 0;
                        object.completedBy = null;
                    }
                    if (message.remarks != null && message.hasOwnProperty("remarks"))
                        object.remarks = message.remarks;
                    if (message.resultRemarks != null && message.hasOwnProperty("resultRemarks"))
                        object.resultRemarks = message.resultRemarks;
                    if (message.resultAttachments && message.resultAttachments.length) {
                        object.resultAttachments = [];
                        for (let j = 0; j < message.resultAttachments.length; ++j)
                            object.resultAttachments[j] = $root.sharelib.Attachment.toObject(message.resultAttachments[j], options);
                    }
                    if (message.completedAt != null && message.hasOwnProperty("completedAt"))
                        if (typeof message.completedAt === "number")
                            object.completedAt = options.longs === String ? String(message.completedAt) : message.completedAt;
                        else
                            object.completedAt = options.longs === String ? $util.Long.prototype.toString.call(message.completedAt) : options.longs === Number ? new $util.LongBits(message.completedAt.low >>> 0, message.completedAt.high >>> 0).toNumber() : message.completedAt;
                    if (message.completedBy != null && message.hasOwnProperty("completedBy"))
                        object.completedBy = $root.sharelib.EmbeddedUser.toObject(message.completedBy, options);
                    return object;
                };

                /**
                 * Converts this Ticket to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.Ticket
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Ticket.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Ticket;
            })();

            worktask.Process = (function() {

                /**
                 * Properties of a Process.
                 * @memberof mtechnavi.api.worktask
                 * @interface IProcess
                 * @property {Long|null} [processedAt] Process processedAt
                 * @property {sharelib.IEmbeddedUser|null} [processedBy] Process processedBy
                 * @property {string|null} [processedComment] Process processedComment
                 */

                /**
                 * Constructs a new Process.
                 * @memberof mtechnavi.api.worktask
                 * @classdesc Represents a Process.
                 * @implements IProcess
                 * @constructor
                 * @param {mtechnavi.api.worktask.IProcess=} [properties] Properties to set
                 */
                function Process(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Process processedAt.
                 * @member {Long} processedAt
                 * @memberof mtechnavi.api.worktask.Process
                 * @instance
                 */
                Process.prototype.processedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Process processedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} processedBy
                 * @memberof mtechnavi.api.worktask.Process
                 * @instance
                 */
                Process.prototype.processedBy = null;

                /**
                 * Process processedComment.
                 * @member {string} processedComment
                 * @memberof mtechnavi.api.worktask.Process
                 * @instance
                 */
                Process.prototype.processedComment = "";

                /**
                 * Verifies a Process message.
                 * @function verify
                 * @memberof mtechnavi.api.worktask.Process
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Process.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (!$util.isInteger(message.processedAt) && !(message.processedAt && $util.isInteger(message.processedAt.low) && $util.isInteger(message.processedAt.high)))
                            return "processedAt: integer|Long expected";
                    if (message.processedBy != null && message.hasOwnProperty("processedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.processedBy);
                        if (error)
                            return "processedBy." + error;
                    }
                    if (message.processedComment != null && message.hasOwnProperty("processedComment"))
                        if (!$util.isString(message.processedComment))
                            return "processedComment: string expected";
                    return null;
                };

                /**
                 * Creates a Process message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.api.worktask.Process
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.api.worktask.Process} Process
                 */
                Process.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.api.worktask.Process)
                        return object;
                    let message = new $root.mtechnavi.api.worktask.Process();
                    if (object.processedAt != null)
                        if ($util.Long)
                            (message.processedAt = $util.Long.fromValue(object.processedAt)).unsigned = false;
                        else if (typeof object.processedAt === "string")
                            message.processedAt = parseInt(object.processedAt, 10);
                        else if (typeof object.processedAt === "number")
                            message.processedAt = object.processedAt;
                        else if (typeof object.processedAt === "object")
                            message.processedAt = new $util.LongBits(object.processedAt.low >>> 0, object.processedAt.high >>> 0).toNumber();
                    if (object.processedBy != null) {
                        if (typeof object.processedBy !== "object")
                            throw TypeError(".mtechnavi.api.worktask.Process.processedBy: object expected");
                        message.processedBy = $root.sharelib.EmbeddedUser.fromObject(object.processedBy);
                    }
                    if (object.processedComment != null)
                        message.processedComment = String(object.processedComment);
                    return message;
                };

                /**
                 * Creates a plain object from a Process message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.api.worktask.Process
                 * @static
                 * @param {mtechnavi.api.worktask.Process} message Process
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Process.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.processedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.processedAt = options.longs === String ? "0" : 0;
                        object.processedBy = null;
                        object.processedComment = "";
                    }
                    if (message.processedAt != null && message.hasOwnProperty("processedAt"))
                        if (typeof message.processedAt === "number")
                            object.processedAt = options.longs === String ? String(message.processedAt) : message.processedAt;
                        else
                            object.processedAt = options.longs === String ? $util.Long.prototype.toString.call(message.processedAt) : options.longs === Number ? new $util.LongBits(message.processedAt.low >>> 0, message.processedAt.high >>> 0).toNumber() : message.processedAt;
                    if (message.processedBy != null && message.hasOwnProperty("processedBy"))
                        object.processedBy = $root.sharelib.EmbeddedUser.toObject(message.processedBy, options);
                    if (message.processedComment != null && message.hasOwnProperty("processedComment"))
                        object.processedComment = message.processedComment;
                    return object;
                };

                /**
                 * Converts this Process to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.api.worktask.Process
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Process.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Process;
            })();

            return worktask;
        })();

        return api;
    })();

    mtechnavi.ui = (function() {

        /**
         * Namespace ui.
         * @memberof mtechnavi
         * @namespace
         */
        const ui = {};

        ui.blueprint = (function() {

            /**
             * Namespace blueprint.
             * @memberof mtechnavi.ui
             * @namespace
             */
            const blueprint = {};

            blueprint.BlueprintSercListViewAggregated = (function() {

                /**
                 * Properties of a BlueprintSercListViewAggregated.
                 * @memberof mtechnavi.ui.blueprint
                 * @interface IBlueprintSercListViewAggregated
                 * @property {string|null} [_id] BlueprintSercListViewAggregated _id
                 * @property {string|null} [blueprintId] BlueprintSercListViewAggregated blueprintId
                 * @property {string|null} [blueprintNumber] BlueprintSercListViewAggregated blueprintNumber
                 * @property {number|null} [revision] BlueprintSercListViewAggregated revision
                 * @property {string|null} [displayName] BlueprintSercListViewAggregated displayName
                 * @property {Array.<string>|null} [analysisSettingItems] BlueprintSercListViewAggregated analysisSettingItems
                 * @property {sharelib.INameOption|null} [blueprintContentType] BlueprintSercListViewAggregated blueprintContentType
                 * @property {sharelib.INameOption|null} [category] BlueprintSercListViewAggregated category
                 * @property {Array.<string>|null} [usedSystems] BlueprintSercListViewAggregated usedSystems
                 * @property {boolean|null} [usable] BlueprintSercListViewAggregated usable
                 * @property {sharelib.IDatetime|null} [revisedDt] BlueprintSercListViewAggregated revisedDt
                 */

                /**
                 * Constructs a new BlueprintSercListViewAggregated.
                 * @memberof mtechnavi.ui.blueprint
                 * @classdesc Represents a BlueprintSercListViewAggregated.
                 * @implements IBlueprintSercListViewAggregated
                 * @constructor
                 * @param {mtechnavi.ui.blueprint.IBlueprintSercListViewAggregated=} [properties] Properties to set
                 */
                function BlueprintSercListViewAggregated(properties) {
                    this.analysisSettingItems = [];
                    this.usedSystems = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BlueprintSercListViewAggregated _id.
                 * @member {string} _id
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype._id = "";

                /**
                 * BlueprintSercListViewAggregated blueprintId.
                 * @member {string} blueprintId
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.blueprintId = "";

                /**
                 * BlueprintSercListViewAggregated blueprintNumber.
                 * @member {string} blueprintNumber
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.blueprintNumber = "";

                /**
                 * BlueprintSercListViewAggregated revision.
                 * @member {number} revision
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.revision = 0;

                /**
                 * BlueprintSercListViewAggregated displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.displayName = "";

                /**
                 * BlueprintSercListViewAggregated analysisSettingItems.
                 * @member {Array.<string>} analysisSettingItems
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.analysisSettingItems = $util.emptyArray;

                /**
                 * BlueprintSercListViewAggregated blueprintContentType.
                 * @member {sharelib.INameOption|null|undefined} blueprintContentType
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.blueprintContentType = null;

                /**
                 * BlueprintSercListViewAggregated category.
                 * @member {sharelib.INameOption|null|undefined} category
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.category = null;

                /**
                 * BlueprintSercListViewAggregated usedSystems.
                 * @member {Array.<string>} usedSystems
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.usedSystems = $util.emptyArray;

                /**
                 * BlueprintSercListViewAggregated usable.
                 * @member {boolean} usable
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.usable = false;

                /**
                 * BlueprintSercListViewAggregated revisedDt.
                 * @member {sharelib.IDatetime|null|undefined} revisedDt
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 */
                BlueprintSercListViewAggregated.prototype.revisedDt = null;

                /**
                 * Verifies a BlueprintSercListViewAggregated message.
                 * @function verify
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BlueprintSercListViewAggregated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message._id != null && message.hasOwnProperty("_id"))
                        if (!$util.isString(message._id))
                            return "_id: string expected";
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        if (!$util.isString(message.blueprintId))
                            return "blueprintId: string expected";
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        if (!$util.isString(message.blueprintNumber))
                            return "blueprintNumber: string expected";
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        if (!$util.isInteger(message.revision))
                            return "revision: integer expected";
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.analysisSettingItems != null && message.hasOwnProperty("analysisSettingItems")) {
                        if (!Array.isArray(message.analysisSettingItems))
                            return "analysisSettingItems: array expected";
                        for (let i = 0; i < message.analysisSettingItems.length; ++i)
                            if (!$util.isString(message.analysisSettingItems[i]))
                                return "analysisSettingItems: string[] expected";
                    }
                    if (message.blueprintContentType != null && message.hasOwnProperty("blueprintContentType")) {
                        let error = $root.sharelib.NameOption.verify(message.blueprintContentType);
                        if (error)
                            return "blueprintContentType." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category")) {
                        let error = $root.sharelib.NameOption.verify(message.category);
                        if (error)
                            return "category." + error;
                    }
                    if (message.usedSystems != null && message.hasOwnProperty("usedSystems")) {
                        if (!Array.isArray(message.usedSystems))
                            return "usedSystems: array expected";
                        for (let i = 0; i < message.usedSystems.length; ++i)
                            if (!$util.isString(message.usedSystems[i]))
                                return "usedSystems: string[] expected";
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        if (typeof message.usable !== "boolean")
                            return "usable: boolean expected";
                    if (message.revisedDt != null && message.hasOwnProperty("revisedDt")) {
                        let error = $root.sharelib.Datetime.verify(message.revisedDt);
                        if (error)
                            return "revisedDt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BlueprintSercListViewAggregated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.ui.blueprint.BlueprintSercListViewAggregated} BlueprintSercListViewAggregated
                 */
                BlueprintSercListViewAggregated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.ui.blueprint.BlueprintSercListViewAggregated)
                        return object;
                    let message = new $root.mtechnavi.ui.blueprint.BlueprintSercListViewAggregated();
                    if (object._id != null)
                        message._id = String(object._id);
                    if (object.blueprintId != null)
                        message.blueprintId = String(object.blueprintId);
                    if (object.blueprintNumber != null)
                        message.blueprintNumber = String(object.blueprintNumber);
                    if (object.revision != null)
                        message.revision = object.revision | 0;
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.analysisSettingItems) {
                        if (!Array.isArray(object.analysisSettingItems))
                            throw TypeError(".mtechnavi.ui.blueprint.BlueprintSercListViewAggregated.analysisSettingItems: array expected");
                        message.analysisSettingItems = [];
                        for (let i = 0; i < object.analysisSettingItems.length; ++i)
                            message.analysisSettingItems[i] = String(object.analysisSettingItems[i]);
                    }
                    if (object.blueprintContentType != null) {
                        if (typeof object.blueprintContentType !== "object")
                            throw TypeError(".mtechnavi.ui.blueprint.BlueprintSercListViewAggregated.blueprintContentType: object expected");
                        message.blueprintContentType = $root.sharelib.NameOption.fromObject(object.blueprintContentType);
                    }
                    if (object.category != null) {
                        if (typeof object.category !== "object")
                            throw TypeError(".mtechnavi.ui.blueprint.BlueprintSercListViewAggregated.category: object expected");
                        message.category = $root.sharelib.NameOption.fromObject(object.category);
                    }
                    if (object.usedSystems) {
                        if (!Array.isArray(object.usedSystems))
                            throw TypeError(".mtechnavi.ui.blueprint.BlueprintSercListViewAggregated.usedSystems: array expected");
                        message.usedSystems = [];
                        for (let i = 0; i < object.usedSystems.length; ++i)
                            message.usedSystems[i] = String(object.usedSystems[i]);
                    }
                    if (object.usable != null)
                        message.usable = Boolean(object.usable);
                    if (object.revisedDt != null) {
                        if (typeof object.revisedDt !== "object")
                            throw TypeError(".mtechnavi.ui.blueprint.BlueprintSercListViewAggregated.revisedDt: object expected");
                        message.revisedDt = $root.sharelib.Datetime.fromObject(object.revisedDt);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BlueprintSercListViewAggregated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @static
                 * @param {mtechnavi.ui.blueprint.BlueprintSercListViewAggregated} message BlueprintSercListViewAggregated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BlueprintSercListViewAggregated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.analysisSettingItems = [];
                        object.usedSystems = [];
                    }
                    if (options.defaults) {
                        object._id = "";
                        object.blueprintId = "";
                        object.blueprintNumber = "";
                        object.revision = 0;
                        object.displayName = "";
                        object.blueprintContentType = null;
                        object.category = null;
                        object.usable = false;
                        object.revisedDt = null;
                    }
                    if (message._id != null && message.hasOwnProperty("_id"))
                        object._id = message._id;
                    if (message.blueprintId != null && message.hasOwnProperty("blueprintId"))
                        object.blueprintId = message.blueprintId;
                    if (message.blueprintNumber != null && message.hasOwnProperty("blueprintNumber"))
                        object.blueprintNumber = message.blueprintNumber;
                    if (message.revision != null && message.hasOwnProperty("revision"))
                        object.revision = message.revision;
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.analysisSettingItems && message.analysisSettingItems.length) {
                        object.analysisSettingItems = [];
                        for (let j = 0; j < message.analysisSettingItems.length; ++j)
                            object.analysisSettingItems[j] = message.analysisSettingItems[j];
                    }
                    if (message.blueprintContentType != null && message.hasOwnProperty("blueprintContentType"))
                        object.blueprintContentType = $root.sharelib.NameOption.toObject(message.blueprintContentType, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = $root.sharelib.NameOption.toObject(message.category, options);
                    if (message.usedSystems && message.usedSystems.length) {
                        object.usedSystems = [];
                        for (let j = 0; j < message.usedSystems.length; ++j)
                            object.usedSystems[j] = message.usedSystems[j];
                    }
                    if (message.usable != null && message.hasOwnProperty("usable"))
                        object.usable = message.usable;
                    if (message.revisedDt != null && message.hasOwnProperty("revisedDt"))
                        object.revisedDt = $root.sharelib.Datetime.toObject(message.revisedDt, options);
                    return object;
                };

                /**
                 * Converts this BlueprintSercListViewAggregated to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.ui.blueprint.BlueprintSercListViewAggregated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BlueprintSercListViewAggregated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BlueprintSercListViewAggregated;
            })();

            return blueprint;
        })();

        ui.survey = (function() {

            /**
             * Namespace survey.
             * @memberof mtechnavi.ui
             * @namespace
             */
            const survey = {};

            survey.SurveyRequestContentAggregated = (function() {

                /**
                 * Properties of a SurveyRequestContentAggregated.
                 * @memberof mtechnavi.ui.survey
                 * @interface ISurveyRequestContentAggregated
                 * @property {string|null} [_id] SurveyRequestContentAggregated _id
                 * @property {string|null} [surveyBaseRequestId] SurveyRequestContentAggregated surveyBaseRequestId
                 * @property {Long|null} [surveyRequestAutoName] SurveyRequestContentAggregated surveyRequestAutoName
                 * @property {Array.<string>|null} [surveyRequestIds] SurveyRequestContentAggregated surveyRequestIds
                 * @property {Array.<string>|null} [businessUnitIds] SurveyRequestContentAggregated businessUnitIds
                 * @property {string|null} [displayName] SurveyRequestContentAggregated displayName
                 * @property {sharelib.INameOption|null} [status] SurveyRequestContentAggregated status
                 * @property {Long|null} [totalRequestUnit] SurveyRequestContentAggregated totalRequestUnit
                 * @property {Array.<string>|null} [requesters] SurveyRequestContentAggregated requesters
                 * @property {sharelib.IDatetime|null} [maxReplyDueDateDt] SurveyRequestContentAggregated maxReplyDueDateDt
                 * @property {sharelib.IDatetime|null} [minReplyDueDateDt] SurveyRequestContentAggregated minReplyDueDateDt
                 * @property {sharelib.IDatetime|null} [replyDueDateDt] SurveyRequestContentAggregated replyDueDateDt
                 * @property {Long|null} [sendedAt] SurveyRequestContentAggregated sendedAt
                 * @property {sharelib.IOrganizationStructureReference|null} [requesterManagementOrganization] SurveyRequestContentAggregated requesterManagementOrganization
                 * @property {string|null} [staffId] SurveyRequestContentAggregated staffId
                 * @property {sharelib.IUserReference|null} [approvalPlanStaffRef] SurveyRequestContentAggregated approvalPlanStaffRef
                 * @property {Array.<mtechnavi.api.tenantadmin.IUserAttribute>|null} [approvalPlanStaff] SurveyRequestContentAggregated approvalPlanStaff
                 * @property {string|null} [approvalPlanOrganizationUnitId] SurveyRequestContentAggregated approvalPlanOrganizationUnitId
                 * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganizationUnitRef] SurveyRequestContentAggregated approvalPlanOrganizationUnitRef
                 * @property {Array.<mtechnavi.api.company.IOrganization>|null} [approvalPlanOrganizationUnit] SurveyRequestContentAggregated approvalPlanOrganizationUnit
                 * @property {string|null} [approvalRequestComment] SurveyRequestContentAggregated approvalRequestComment
                 * @property {sharelib.IEmbeddedUser|null} [approvalRequestUpdatedBy] SurveyRequestContentAggregated approvalRequestUpdatedBy
                 * @property {mtechnavi.api.survey.ISurveyRequestCatalog|null} [surveyRequestCatalog] SurveyRequestContentAggregated surveyRequestCatalog
                 * @property {string|null} [guidance] SurveyRequestContentAggregated guidance
                 * @property {Long|null} [updatedAt] SurveyRequestContentAggregated updatedAt
                 * @property {sharelib.IEmbeddedUpdatedProperties|null} [createdProperties] SurveyRequestContentAggregated createdProperties
                 * @property {string|null} [issuerEmail] SurveyRequestContentAggregated issuerEmail
                 * @property {Array.<string>|null} [requestFormIds] SurveyRequestContentAggregated requestFormIds
                 * @property {Array.<string>|null} [surveyResultWorkFormIds] SurveyRequestContentAggregated surveyResultWorkFormIds
                 * @property {boolean|null} [maxFlagActive] SurveyRequestContentAggregated maxFlagActive
                 */

                /**
                 * Constructs a new SurveyRequestContentAggregated.
                 * @memberof mtechnavi.ui.survey
                 * @classdesc Represents a SurveyRequestContentAggregated.
                 * @implements ISurveyRequestContentAggregated
                 * @constructor
                 * @param {mtechnavi.ui.survey.ISurveyRequestContentAggregated=} [properties] Properties to set
                 */
                function SurveyRequestContentAggregated(properties) {
                    this.surveyRequestIds = [];
                    this.businessUnitIds = [];
                    this.requesters = [];
                    this.approvalPlanStaff = [];
                    this.approvalPlanOrganizationUnit = [];
                    this.requestFormIds = [];
                    this.surveyResultWorkFormIds = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SurveyRequestContentAggregated _id.
                 * @member {string} _id
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype._id = "";

                /**
                 * SurveyRequestContentAggregated surveyBaseRequestId.
                 * @member {string} surveyBaseRequestId
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.surveyBaseRequestId = "";

                /**
                 * SurveyRequestContentAggregated surveyRequestAutoName.
                 * @member {Long} surveyRequestAutoName
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.surveyRequestAutoName = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestContentAggregated surveyRequestIds.
                 * @member {Array.<string>} surveyRequestIds
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.surveyRequestIds = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated businessUnitIds.
                 * @member {Array.<string>} businessUnitIds
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.businessUnitIds = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated displayName.
                 * @member {string} displayName
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.displayName = "";

                /**
                 * SurveyRequestContentAggregated status.
                 * @member {sharelib.INameOption|null|undefined} status
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.status = null;

                /**
                 * SurveyRequestContentAggregated totalRequestUnit.
                 * @member {Long} totalRequestUnit
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.totalRequestUnit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestContentAggregated requesters.
                 * @member {Array.<string>} requesters
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.requesters = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated maxReplyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} maxReplyDueDateDt
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.maxReplyDueDateDt = null;

                /**
                 * SurveyRequestContentAggregated minReplyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} minReplyDueDateDt
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.minReplyDueDateDt = null;

                /**
                 * SurveyRequestContentAggregated replyDueDateDt.
                 * @member {sharelib.IDatetime|null|undefined} replyDueDateDt
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.replyDueDateDt = null;

                /**
                 * SurveyRequestContentAggregated sendedAt.
                 * @member {Long} sendedAt
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.sendedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestContentAggregated requesterManagementOrganization.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} requesterManagementOrganization
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.requesterManagementOrganization = null;

                /**
                 * SurveyRequestContentAggregated staffId.
                 * @member {string} staffId
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.staffId = "";

                /**
                 * SurveyRequestContentAggregated approvalPlanStaffRef.
                 * @member {sharelib.IUserReference|null|undefined} approvalPlanStaffRef
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalPlanStaffRef = null;

                /**
                 * SurveyRequestContentAggregated approvalPlanStaff.
                 * @member {Array.<mtechnavi.api.tenantadmin.IUserAttribute>} approvalPlanStaff
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalPlanStaff = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated approvalPlanOrganizationUnitId.
                 * @member {string} approvalPlanOrganizationUnitId
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalPlanOrganizationUnitId = "";

                /**
                 * SurveyRequestContentAggregated approvalPlanOrganizationUnitRef.
                 * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganizationUnitRef
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalPlanOrganizationUnitRef = null;

                /**
                 * SurveyRequestContentAggregated approvalPlanOrganizationUnit.
                 * @member {Array.<mtechnavi.api.company.IOrganization>} approvalPlanOrganizationUnit
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalPlanOrganizationUnit = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated approvalRequestComment.
                 * @member {string} approvalRequestComment
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalRequestComment = "";

                /**
                 * SurveyRequestContentAggregated approvalRequestUpdatedBy.
                 * @member {sharelib.IEmbeddedUser|null|undefined} approvalRequestUpdatedBy
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.approvalRequestUpdatedBy = null;

                /**
                 * SurveyRequestContentAggregated surveyRequestCatalog.
                 * @member {mtechnavi.api.survey.ISurveyRequestCatalog|null|undefined} surveyRequestCatalog
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.surveyRequestCatalog = null;

                /**
                 * SurveyRequestContentAggregated guidance.
                 * @member {string} guidance
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.guidance = "";

                /**
                 * SurveyRequestContentAggregated updatedAt.
                 * @member {Long} updatedAt
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * SurveyRequestContentAggregated createdProperties.
                 * @member {sharelib.IEmbeddedUpdatedProperties|null|undefined} createdProperties
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.createdProperties = null;

                /**
                 * SurveyRequestContentAggregated issuerEmail.
                 * @member {string} issuerEmail
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.issuerEmail = "";

                /**
                 * SurveyRequestContentAggregated requestFormIds.
                 * @member {Array.<string>} requestFormIds
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.requestFormIds = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated surveyResultWorkFormIds.
                 * @member {Array.<string>} surveyResultWorkFormIds
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.surveyResultWorkFormIds = $util.emptyArray;

                /**
                 * SurveyRequestContentAggregated maxFlagActive.
                 * @member {boolean} maxFlagActive
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 */
                SurveyRequestContentAggregated.prototype.maxFlagActive = false;

                /**
                 * Verifies a SurveyRequestContentAggregated message.
                 * @function verify
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SurveyRequestContentAggregated.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message._id != null && message.hasOwnProperty("_id"))
                        if (!$util.isString(message._id))
                            return "_id: string expected";
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        if (!$util.isString(message.surveyBaseRequestId))
                            return "surveyBaseRequestId: string expected";
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (!$util.isInteger(message.surveyRequestAutoName) && !(message.surveyRequestAutoName && $util.isInteger(message.surveyRequestAutoName.low) && $util.isInteger(message.surveyRequestAutoName.high)))
                            return "surveyRequestAutoName: integer|Long expected";
                    if (message.surveyRequestIds != null && message.hasOwnProperty("surveyRequestIds")) {
                        if (!Array.isArray(message.surveyRequestIds))
                            return "surveyRequestIds: array expected";
                        for (let i = 0; i < message.surveyRequestIds.length; ++i)
                            if (!$util.isString(message.surveyRequestIds[i]))
                                return "surveyRequestIds: string[] expected";
                    }
                    if (message.businessUnitIds != null && message.hasOwnProperty("businessUnitIds")) {
                        if (!Array.isArray(message.businessUnitIds))
                            return "businessUnitIds: array expected";
                        for (let i = 0; i < message.businessUnitIds.length; ++i)
                            if (!$util.isString(message.businessUnitIds[i]))
                                return "businessUnitIds: string[] expected";
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        if (!$util.isString(message.displayName))
                            return "displayName: string expected";
                    if (message.status != null && message.hasOwnProperty("status")) {
                        let error = $root.sharelib.NameOption.verify(message.status);
                        if (error)
                            return "status." + error;
                    }
                    if (message.totalRequestUnit != null && message.hasOwnProperty("totalRequestUnit"))
                        if (!$util.isInteger(message.totalRequestUnit) && !(message.totalRequestUnit && $util.isInteger(message.totalRequestUnit.low) && $util.isInteger(message.totalRequestUnit.high)))
                            return "totalRequestUnit: integer|Long expected";
                    if (message.requesters != null && message.hasOwnProperty("requesters")) {
                        if (!Array.isArray(message.requesters))
                            return "requesters: array expected";
                        for (let i = 0; i < message.requesters.length; ++i)
                            if (!$util.isString(message.requesters[i]))
                                return "requesters: string[] expected";
                    }
                    if (message.maxReplyDueDateDt != null && message.hasOwnProperty("maxReplyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.maxReplyDueDateDt);
                        if (error)
                            return "maxReplyDueDateDt." + error;
                    }
                    if (message.minReplyDueDateDt != null && message.hasOwnProperty("minReplyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.minReplyDueDateDt);
                        if (error)
                            return "minReplyDueDateDt." + error;
                    }
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt")) {
                        let error = $root.sharelib.Datetime.verify(message.replyDueDateDt);
                        if (error)
                            return "replyDueDateDt." + error;
                    }
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (!$util.isInteger(message.sendedAt) && !(message.sendedAt && $util.isInteger(message.sendedAt.low) && $util.isInteger(message.sendedAt.high)))
                            return "sendedAt: integer|Long expected";
                    if (message.requesterManagementOrganization != null && message.hasOwnProperty("requesterManagementOrganization")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.requesterManagementOrganization);
                        if (error)
                            return "requesterManagementOrganization." + error;
                    }
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        if (!$util.isString(message.staffId))
                            return "staffId: string expected";
                    if (message.approvalPlanStaffRef != null && message.hasOwnProperty("approvalPlanStaffRef")) {
                        let error = $root.sharelib.UserReference.verify(message.approvalPlanStaffRef);
                        if (error)
                            return "approvalPlanStaffRef." + error;
                    }
                    if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                        if (!Array.isArray(message.approvalPlanStaff))
                            return "approvalPlanStaff: array expected";
                        for (let i = 0; i < message.approvalPlanStaff.length; ++i) {
                            let error = $root.mtechnavi.api.tenantadmin.UserAttribute.verify(message.approvalPlanStaff[i]);
                            if (error)
                                return "approvalPlanStaff." + error;
                        }
                    }
                    if (message.approvalPlanOrganizationUnitId != null && message.hasOwnProperty("approvalPlanOrganizationUnitId"))
                        if (!$util.isString(message.approvalPlanOrganizationUnitId))
                            return "approvalPlanOrganizationUnitId: string expected";
                    if (message.approvalPlanOrganizationUnitRef != null && message.hasOwnProperty("approvalPlanOrganizationUnitRef")) {
                        let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganizationUnitRef);
                        if (error)
                            return "approvalPlanOrganizationUnitRef." + error;
                    }
                    if (message.approvalPlanOrganizationUnit != null && message.hasOwnProperty("approvalPlanOrganizationUnit")) {
                        if (!Array.isArray(message.approvalPlanOrganizationUnit))
                            return "approvalPlanOrganizationUnit: array expected";
                        for (let i = 0; i < message.approvalPlanOrganizationUnit.length; ++i) {
                            let error = $root.mtechnavi.api.company.Organization.verify(message.approvalPlanOrganizationUnit[i]);
                            if (error)
                                return "approvalPlanOrganizationUnit." + error;
                        }
                    }
                    if (message.approvalRequestComment != null && message.hasOwnProperty("approvalRequestComment"))
                        if (!$util.isString(message.approvalRequestComment))
                            return "approvalRequestComment: string expected";
                    if (message.approvalRequestUpdatedBy != null && message.hasOwnProperty("approvalRequestUpdatedBy")) {
                        let error = $root.sharelib.EmbeddedUser.verify(message.approvalRequestUpdatedBy);
                        if (error)
                            return "approvalRequestUpdatedBy." + error;
                    }
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog")) {
                        let error = $root.mtechnavi.api.survey.SurveyRequestCatalog.verify(message.surveyRequestCatalog);
                        if (error)
                            return "surveyRequestCatalog." + error;
                    }
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        if (!$util.isString(message.guidance))
                            return "guidance: string expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                            return "updatedAt: integer|Long expected";
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties")) {
                        let error = $root.sharelib.EmbeddedUpdatedProperties.verify(message.createdProperties);
                        if (error)
                            return "createdProperties." + error;
                    }
                    if (message.issuerEmail != null && message.hasOwnProperty("issuerEmail"))
                        if (!$util.isString(message.issuerEmail))
                            return "issuerEmail: string expected";
                    if (message.requestFormIds != null && message.hasOwnProperty("requestFormIds")) {
                        if (!Array.isArray(message.requestFormIds))
                            return "requestFormIds: array expected";
                        for (let i = 0; i < message.requestFormIds.length; ++i)
                            if (!$util.isString(message.requestFormIds[i]))
                                return "requestFormIds: string[] expected";
                    }
                    if (message.surveyResultWorkFormIds != null && message.hasOwnProperty("surveyResultWorkFormIds")) {
                        if (!Array.isArray(message.surveyResultWorkFormIds))
                            return "surveyResultWorkFormIds: array expected";
                        for (let i = 0; i < message.surveyResultWorkFormIds.length; ++i)
                            if (!$util.isString(message.surveyResultWorkFormIds[i]))
                                return "surveyResultWorkFormIds: string[] expected";
                    }
                    if (message.maxFlagActive != null && message.hasOwnProperty("maxFlagActive"))
                        if (typeof message.maxFlagActive !== "boolean")
                            return "maxFlagActive: boolean expected";
                    return null;
                };

                /**
                 * Creates a SurveyRequestContentAggregated message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {mtechnavi.ui.survey.SurveyRequestContentAggregated} SurveyRequestContentAggregated
                 */
                SurveyRequestContentAggregated.fromObject = function fromObject(object) {
                    if (object instanceof $root.mtechnavi.ui.survey.SurveyRequestContentAggregated)
                        return object;
                    let message = new $root.mtechnavi.ui.survey.SurveyRequestContentAggregated();
                    if (object._id != null)
                        message._id = String(object._id);
                    if (object.surveyBaseRequestId != null)
                        message.surveyBaseRequestId = String(object.surveyBaseRequestId);
                    if (object.surveyRequestAutoName != null)
                        if ($util.Long)
                            (message.surveyRequestAutoName = $util.Long.fromValue(object.surveyRequestAutoName)).unsigned = false;
                        else if (typeof object.surveyRequestAutoName === "string")
                            message.surveyRequestAutoName = parseInt(object.surveyRequestAutoName, 10);
                        else if (typeof object.surveyRequestAutoName === "number")
                            message.surveyRequestAutoName = object.surveyRequestAutoName;
                        else if (typeof object.surveyRequestAutoName === "object")
                            message.surveyRequestAutoName = new $util.LongBits(object.surveyRequestAutoName.low >>> 0, object.surveyRequestAutoName.high >>> 0).toNumber();
                    if (object.surveyRequestIds) {
                        if (!Array.isArray(object.surveyRequestIds))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.surveyRequestIds: array expected");
                        message.surveyRequestIds = [];
                        for (let i = 0; i < object.surveyRequestIds.length; ++i)
                            message.surveyRequestIds[i] = String(object.surveyRequestIds[i]);
                    }
                    if (object.businessUnitIds) {
                        if (!Array.isArray(object.businessUnitIds))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.businessUnitIds: array expected");
                        message.businessUnitIds = [];
                        for (let i = 0; i < object.businessUnitIds.length; ++i)
                            message.businessUnitIds[i] = String(object.businessUnitIds[i]);
                    }
                    if (object.displayName != null)
                        message.displayName = String(object.displayName);
                    if (object.status != null) {
                        if (typeof object.status !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.status: object expected");
                        message.status = $root.sharelib.NameOption.fromObject(object.status);
                    }
                    if (object.totalRequestUnit != null)
                        if ($util.Long)
                            (message.totalRequestUnit = $util.Long.fromValue(object.totalRequestUnit)).unsigned = false;
                        else if (typeof object.totalRequestUnit === "string")
                            message.totalRequestUnit = parseInt(object.totalRequestUnit, 10);
                        else if (typeof object.totalRequestUnit === "number")
                            message.totalRequestUnit = object.totalRequestUnit;
                        else if (typeof object.totalRequestUnit === "object")
                            message.totalRequestUnit = new $util.LongBits(object.totalRequestUnit.low >>> 0, object.totalRequestUnit.high >>> 0).toNumber();
                    if (object.requesters) {
                        if (!Array.isArray(object.requesters))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.requesters: array expected");
                        message.requesters = [];
                        for (let i = 0; i < object.requesters.length; ++i)
                            message.requesters[i] = String(object.requesters[i]);
                    }
                    if (object.maxReplyDueDateDt != null) {
                        if (typeof object.maxReplyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.maxReplyDueDateDt: object expected");
                        message.maxReplyDueDateDt = $root.sharelib.Datetime.fromObject(object.maxReplyDueDateDt);
                    }
                    if (object.minReplyDueDateDt != null) {
                        if (typeof object.minReplyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.minReplyDueDateDt: object expected");
                        message.minReplyDueDateDt = $root.sharelib.Datetime.fromObject(object.minReplyDueDateDt);
                    }
                    if (object.replyDueDateDt != null) {
                        if (typeof object.replyDueDateDt !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.replyDueDateDt: object expected");
                        message.replyDueDateDt = $root.sharelib.Datetime.fromObject(object.replyDueDateDt);
                    }
                    if (object.sendedAt != null)
                        if ($util.Long)
                            (message.sendedAt = $util.Long.fromValue(object.sendedAt)).unsigned = false;
                        else if (typeof object.sendedAt === "string")
                            message.sendedAt = parseInt(object.sendedAt, 10);
                        else if (typeof object.sendedAt === "number")
                            message.sendedAt = object.sendedAt;
                        else if (typeof object.sendedAt === "object")
                            message.sendedAt = new $util.LongBits(object.sendedAt.low >>> 0, object.sendedAt.high >>> 0).toNumber();
                    if (object.requesterManagementOrganization != null) {
                        if (typeof object.requesterManagementOrganization !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.requesterManagementOrganization: object expected");
                        message.requesterManagementOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.requesterManagementOrganization);
                    }
                    if (object.staffId != null)
                        message.staffId = String(object.staffId);
                    if (object.approvalPlanStaffRef != null) {
                        if (typeof object.approvalPlanStaffRef !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanStaffRef: object expected");
                        message.approvalPlanStaffRef = $root.sharelib.UserReference.fromObject(object.approvalPlanStaffRef);
                    }
                    if (object.approvalPlanStaff) {
                        if (!Array.isArray(object.approvalPlanStaff))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanStaff: array expected");
                        message.approvalPlanStaff = [];
                        for (let i = 0; i < object.approvalPlanStaff.length; ++i) {
                            if (typeof object.approvalPlanStaff[i] !== "object")
                                throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanStaff: object expected");
                            message.approvalPlanStaff[i] = $root.mtechnavi.api.tenantadmin.UserAttribute.fromObject(object.approvalPlanStaff[i]);
                        }
                    }
                    if (object.approvalPlanOrganizationUnitId != null)
                        message.approvalPlanOrganizationUnitId = String(object.approvalPlanOrganizationUnitId);
                    if (object.approvalPlanOrganizationUnitRef != null) {
                        if (typeof object.approvalPlanOrganizationUnitRef !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanOrganizationUnitRef: object expected");
                        message.approvalPlanOrganizationUnitRef = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganizationUnitRef);
                    }
                    if (object.approvalPlanOrganizationUnit) {
                        if (!Array.isArray(object.approvalPlanOrganizationUnit))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanOrganizationUnit: array expected");
                        message.approvalPlanOrganizationUnit = [];
                        for (let i = 0; i < object.approvalPlanOrganizationUnit.length; ++i) {
                            if (typeof object.approvalPlanOrganizationUnit[i] !== "object")
                                throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalPlanOrganizationUnit: object expected");
                            message.approvalPlanOrganizationUnit[i] = $root.mtechnavi.api.company.Organization.fromObject(object.approvalPlanOrganizationUnit[i]);
                        }
                    }
                    if (object.approvalRequestComment != null)
                        message.approvalRequestComment = String(object.approvalRequestComment);
                    if (object.approvalRequestUpdatedBy != null) {
                        if (typeof object.approvalRequestUpdatedBy !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.approvalRequestUpdatedBy: object expected");
                        message.approvalRequestUpdatedBy = $root.sharelib.EmbeddedUser.fromObject(object.approvalRequestUpdatedBy);
                    }
                    if (object.surveyRequestCatalog != null) {
                        if (typeof object.surveyRequestCatalog !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.surveyRequestCatalog: object expected");
                        message.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.fromObject(object.surveyRequestCatalog);
                    }
                    if (object.guidance != null)
                        message.guidance = String(object.guidance);
                    if (object.updatedAt != null)
                        if ($util.Long)
                            (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                        else if (typeof object.updatedAt === "string")
                            message.updatedAt = parseInt(object.updatedAt, 10);
                        else if (typeof object.updatedAt === "number")
                            message.updatedAt = object.updatedAt;
                        else if (typeof object.updatedAt === "object")
                            message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
                    if (object.createdProperties != null) {
                        if (typeof object.createdProperties !== "object")
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.createdProperties: object expected");
                        message.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.fromObject(object.createdProperties);
                    }
                    if (object.issuerEmail != null)
                        message.issuerEmail = String(object.issuerEmail);
                    if (object.requestFormIds) {
                        if (!Array.isArray(object.requestFormIds))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.requestFormIds: array expected");
                        message.requestFormIds = [];
                        for (let i = 0; i < object.requestFormIds.length; ++i)
                            message.requestFormIds[i] = String(object.requestFormIds[i]);
                    }
                    if (object.surveyResultWorkFormIds) {
                        if (!Array.isArray(object.surveyResultWorkFormIds))
                            throw TypeError(".mtechnavi.ui.survey.SurveyRequestContentAggregated.surveyResultWorkFormIds: array expected");
                        message.surveyResultWorkFormIds = [];
                        for (let i = 0; i < object.surveyResultWorkFormIds.length; ++i)
                            message.surveyResultWorkFormIds[i] = String(object.surveyResultWorkFormIds[i]);
                    }
                    if (object.maxFlagActive != null)
                        message.maxFlagActive = Boolean(object.maxFlagActive);
                    return message;
                };

                /**
                 * Creates a plain object from a SurveyRequestContentAggregated message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @static
                 * @param {mtechnavi.ui.survey.SurveyRequestContentAggregated} message SurveyRequestContentAggregated
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SurveyRequestContentAggregated.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.surveyRequestIds = [];
                        object.businessUnitIds = [];
                        object.requesters = [];
                        object.approvalPlanStaff = [];
                        object.approvalPlanOrganizationUnit = [];
                        object.requestFormIds = [];
                        object.surveyResultWorkFormIds = [];
                    }
                    if (options.defaults) {
                        object._id = "";
                        object.surveyBaseRequestId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.surveyRequestAutoName = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.surveyRequestAutoName = options.longs === String ? "0" : 0;
                        object.displayName = "";
                        object.status = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.totalRequestUnit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalRequestUnit = options.longs === String ? "0" : 0;
                        object.maxReplyDueDateDt = null;
                        object.minReplyDueDateDt = null;
                        object.replyDueDateDt = null;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.sendedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.sendedAt = options.longs === String ? "0" : 0;
                        object.staffId = "";
                        object.approvalPlanOrganizationUnitId = "";
                        object.approvalRequestComment = "";
                        object.approvalRequestUpdatedBy = null;
                        object.surveyRequestCatalog = null;
                        object.guidance = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.updatedAt = options.longs === String ? "0" : 0;
                        object.approvalPlanStaffRef = null;
                        object.approvalPlanOrganizationUnitRef = null;
                        object.createdProperties = null;
                        object.issuerEmail = "";
                        object.maxFlagActive = false;
                        object.requesterManagementOrganization = null;
                    }
                    if (message._id != null && message.hasOwnProperty("_id"))
                        object._id = message._id;
                    if (message.surveyBaseRequestId != null && message.hasOwnProperty("surveyBaseRequestId"))
                        object.surveyBaseRequestId = message.surveyBaseRequestId;
                    if (message.surveyRequestAutoName != null && message.hasOwnProperty("surveyRequestAutoName"))
                        if (typeof message.surveyRequestAutoName === "number")
                            object.surveyRequestAutoName = options.longs === String ? String(message.surveyRequestAutoName) : message.surveyRequestAutoName;
                        else
                            object.surveyRequestAutoName = options.longs === String ? $util.Long.prototype.toString.call(message.surveyRequestAutoName) : options.longs === Number ? new $util.LongBits(message.surveyRequestAutoName.low >>> 0, message.surveyRequestAutoName.high >>> 0).toNumber() : message.surveyRequestAutoName;
                    if (message.surveyRequestIds && message.surveyRequestIds.length) {
                        object.surveyRequestIds = [];
                        for (let j = 0; j < message.surveyRequestIds.length; ++j)
                            object.surveyRequestIds[j] = message.surveyRequestIds[j];
                    }
                    if (message.businessUnitIds && message.businessUnitIds.length) {
                        object.businessUnitIds = [];
                        for (let j = 0; j < message.businessUnitIds.length; ++j)
                            object.businessUnitIds[j] = message.businessUnitIds[j];
                    }
                    if (message.displayName != null && message.hasOwnProperty("displayName"))
                        object.displayName = message.displayName;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = $root.sharelib.NameOption.toObject(message.status, options);
                    if (message.totalRequestUnit != null && message.hasOwnProperty("totalRequestUnit"))
                        if (typeof message.totalRequestUnit === "number")
                            object.totalRequestUnit = options.longs === String ? String(message.totalRequestUnit) : message.totalRequestUnit;
                        else
                            object.totalRequestUnit = options.longs === String ? $util.Long.prototype.toString.call(message.totalRequestUnit) : options.longs === Number ? new $util.LongBits(message.totalRequestUnit.low >>> 0, message.totalRequestUnit.high >>> 0).toNumber() : message.totalRequestUnit;
                    if (message.requesters && message.requesters.length) {
                        object.requesters = [];
                        for (let j = 0; j < message.requesters.length; ++j)
                            object.requesters[j] = message.requesters[j];
                    }
                    if (message.maxReplyDueDateDt != null && message.hasOwnProperty("maxReplyDueDateDt"))
                        object.maxReplyDueDateDt = $root.sharelib.Datetime.toObject(message.maxReplyDueDateDt, options);
                    if (message.minReplyDueDateDt != null && message.hasOwnProperty("minReplyDueDateDt"))
                        object.minReplyDueDateDt = $root.sharelib.Datetime.toObject(message.minReplyDueDateDt, options);
                    if (message.replyDueDateDt != null && message.hasOwnProperty("replyDueDateDt"))
                        object.replyDueDateDt = $root.sharelib.Datetime.toObject(message.replyDueDateDt, options);
                    if (message.sendedAt != null && message.hasOwnProperty("sendedAt"))
                        if (typeof message.sendedAt === "number")
                            object.sendedAt = options.longs === String ? String(message.sendedAt) : message.sendedAt;
                        else
                            object.sendedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sendedAt) : options.longs === Number ? new $util.LongBits(message.sendedAt.low >>> 0, message.sendedAt.high >>> 0).toNumber() : message.sendedAt;
                    if (message.staffId != null && message.hasOwnProperty("staffId"))
                        object.staffId = message.staffId;
                    if (message.approvalPlanStaff && message.approvalPlanStaff.length) {
                        object.approvalPlanStaff = [];
                        for (let j = 0; j < message.approvalPlanStaff.length; ++j)
                            object.approvalPlanStaff[j] = $root.mtechnavi.api.tenantadmin.UserAttribute.toObject(message.approvalPlanStaff[j], options);
                    }
                    if (message.approvalPlanOrganizationUnitId != null && message.hasOwnProperty("approvalPlanOrganizationUnitId"))
                        object.approvalPlanOrganizationUnitId = message.approvalPlanOrganizationUnitId;
                    if (message.approvalPlanOrganizationUnit && message.approvalPlanOrganizationUnit.length) {
                        object.approvalPlanOrganizationUnit = [];
                        for (let j = 0; j < message.approvalPlanOrganizationUnit.length; ++j)
                            object.approvalPlanOrganizationUnit[j] = $root.mtechnavi.api.company.Organization.toObject(message.approvalPlanOrganizationUnit[j], options);
                    }
                    if (message.approvalRequestComment != null && message.hasOwnProperty("approvalRequestComment"))
                        object.approvalRequestComment = message.approvalRequestComment;
                    if (message.approvalRequestUpdatedBy != null && message.hasOwnProperty("approvalRequestUpdatedBy"))
                        object.approvalRequestUpdatedBy = $root.sharelib.EmbeddedUser.toObject(message.approvalRequestUpdatedBy, options);
                    if (message.surveyRequestCatalog != null && message.hasOwnProperty("surveyRequestCatalog"))
                        object.surveyRequestCatalog = $root.mtechnavi.api.survey.SurveyRequestCatalog.toObject(message.surveyRequestCatalog, options);
                    if (message.guidance != null && message.hasOwnProperty("guidance"))
                        object.guidance = message.guidance;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        if (typeof message.updatedAt === "number")
                            object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                        else
                            object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
                    if (message.approvalPlanStaffRef != null && message.hasOwnProperty("approvalPlanStaffRef"))
                        object.approvalPlanStaffRef = $root.sharelib.UserReference.toObject(message.approvalPlanStaffRef, options);
                    if (message.approvalPlanOrganizationUnitRef != null && message.hasOwnProperty("approvalPlanOrganizationUnitRef"))
                        object.approvalPlanOrganizationUnitRef = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganizationUnitRef, options);
                    if (message.createdProperties != null && message.hasOwnProperty("createdProperties"))
                        object.createdProperties = $root.sharelib.EmbeddedUpdatedProperties.toObject(message.createdProperties, options);
                    if (message.issuerEmail != null && message.hasOwnProperty("issuerEmail"))
                        object.issuerEmail = message.issuerEmail;
                    if (message.requestFormIds && message.requestFormIds.length) {
                        object.requestFormIds = [];
                        for (let j = 0; j < message.requestFormIds.length; ++j)
                            object.requestFormIds[j] = message.requestFormIds[j];
                    }
                    if (message.surveyResultWorkFormIds && message.surveyResultWorkFormIds.length) {
                        object.surveyResultWorkFormIds = [];
                        for (let j = 0; j < message.surveyResultWorkFormIds.length; ++j)
                            object.surveyResultWorkFormIds[j] = message.surveyResultWorkFormIds[j];
                    }
                    if (message.maxFlagActive != null && message.hasOwnProperty("maxFlagActive"))
                        object.maxFlagActive = message.maxFlagActive;
                    if (message.requesterManagementOrganization != null && message.hasOwnProperty("requesterManagementOrganization"))
                        object.requesterManagementOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.requesterManagementOrganization, options);
                    return object;
                };

                /**
                 * Converts this SurveyRequestContentAggregated to JSON.
                 * @function toJSON
                 * @memberof mtechnavi.ui.survey.SurveyRequestContentAggregated
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SurveyRequestContentAggregated.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SurveyRequestContentAggregated;
            })();

            return survey;
        })();

        return ui;
    })();

    return mtechnavi;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Verifies an Empty message.
             * @function verify
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Empty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Empty)
                    return object;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} message Empty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Empty;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                let message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Any;
        })();

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (let i = 0; i < message.file.length; ++i) {
                        let error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (let i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (let j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (let i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (let i = 0; i < message.messageType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (let i = 0; i < message.service.length; ++i) {
                        let error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    let error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (let i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (let i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (let i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (let i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (let i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (let j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (let j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (let j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (let j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (let j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (let i = 0; i < message.field.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (let i = 0; i < message.nestedType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (let i = 0; i < message.extensionRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (let i = 0; i < message.oneofDecl.length; ++i) {
                        let error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (let i = 0; i < message.reservedRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (let i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                let message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (let i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (let i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (let i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (let i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (let i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (let i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (let j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (let j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (let j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (let j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (let j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (let j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (let i = 0; i < message.value.length; ++i) {
                        let error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (let i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (let j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (let i = 0; i < message.method.length; ++i) {
                        let error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (let i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (let j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                let message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             * @property {mtn.ICsvFileOptions|null} [".mtn.csvDefaultFormat"] MessageOptions .mtn.csvDefaultFormat
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MessageOptions .mtn.csvDefaultFormat.
             * @member {mtn.ICsvFileOptions|null|undefined} .mtn.csvDefaultFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".mtn.csvDefaultFormat"] = null;

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".mtn.csvDefaultFormat"] != null && message.hasOwnProperty(".mtn.csvDefaultFormat")) {
                    let error = $root.mtn.CsvFileOptions.verify(message[".mtn.csvDefaultFormat"]);
                    if (error)
                        return ".mtn.csvDefaultFormat." + error;
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                let message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".mtn.csvDefaultFormat"] != null) {
                    if (typeof object[".mtn.csvDefaultFormat"] !== "object")
                        throw TypeError(".google.protobuf.MessageOptions..mtn.csvDefaultFormat: object expected");
                    message[".mtn.csvDefaultFormat"] = $root.mtn.CsvFileOptions.fromObject(object[".mtn.csvDefaultFormat"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                    object[".mtn.csvDefaultFormat"] = null;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".mtn.csvDefaultFormat"] != null && message.hasOwnProperty(".mtn.csvDefaultFormat"))
                    object[".mtn.csvDefaultFormat"] = $root.mtn.CsvFileOptions.toObject(message[".mtn.csvDefaultFormat"], options);
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {mtn.IVisibilityOptions|null} [".mtn.visibility"] FieldOptions .mtn.visibility
             * @property {mtn.ICsvColumnOptions|null} [".mtn.csv"] FieldOptions .mtn.csv
             * @property {mtn.IDataproxyOptions|null} [".mtn.dataproxy"] FieldOptions .mtn.dataproxy
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .mtn.visibility.
             * @member {mtn.IVisibilityOptions|null|undefined} .mtn.visibility
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".mtn.visibility"] = null;

            /**
             * FieldOptions .mtn.csv.
             * @member {mtn.ICsvColumnOptions|null|undefined} .mtn.csv
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".mtn.csv"] = null;

            /**
             * FieldOptions .mtn.dataproxy.
             * @member {mtn.IDataproxyOptions|null|undefined} .mtn.dataproxy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".mtn.dataproxy"] = null;

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".mtn.visibility"] != null && message.hasOwnProperty(".mtn.visibility")) {
                    let error = $root.mtn.VisibilityOptions.verify(message[".mtn.visibility"]);
                    if (error)
                        return ".mtn.visibility." + error;
                }
                if (message[".mtn.csv"] != null && message.hasOwnProperty(".mtn.csv")) {
                    let error = $root.mtn.CsvColumnOptions.verify(message[".mtn.csv"]);
                    if (error)
                        return ".mtn.csv." + error;
                }
                if (message[".mtn.dataproxy"] != null && message.hasOwnProperty(".mtn.dataproxy")) {
                    let error = $root.mtn.DataproxyOptions.verify(message[".mtn.dataproxy"]);
                    if (error)
                        return ".mtn.dataproxy." + error;
                }
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                let message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".mtn.visibility"] != null) {
                    if (typeof object[".mtn.visibility"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..mtn.visibility: object expected");
                    message[".mtn.visibility"] = $root.mtn.VisibilityOptions.fromObject(object[".mtn.visibility"]);
                }
                if (object[".mtn.csv"] != null) {
                    if (typeof object[".mtn.csv"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..mtn.csv: object expected");
                    message[".mtn.csv"] = $root.mtn.CsvColumnOptions.fromObject(object[".mtn.csv"]);
                }
                if (object[".mtn.dataproxy"] != null) {
                    if (typeof object[".mtn.dataproxy"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..mtn.dataproxy: object expected");
                    message[".mtn.dataproxy"] = $root.mtn.DataproxyOptions.fromObject(object[".mtn.dataproxy"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".mtn.visibility"] = null;
                    object[".mtn.csv"] = null;
                    object[".mtn.dataproxy"] = null;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".mtn.visibility"] != null && message.hasOwnProperty(".mtn.visibility"))
                    object[".mtn.visibility"] = $root.mtn.VisibilityOptions.toObject(message[".mtn.visibility"], options);
                if (message[".mtn.csv"] != null && message.hasOwnProperty(".mtn.csv"))
                    object[".mtn.csv"] = $root.mtn.CsvColumnOptions.toObject(message[".mtn.csv"], options);
                if (message[".mtn.dataproxy"] != null && message.hasOwnProperty(".mtn.dataproxy"))
                    object[".mtn.dataproxy"] = $root.mtn.DataproxyOptions.toObject(message[".mtn.dataproxy"], options);
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                let message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                let message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                let message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                let message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                let message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (let i = 0; i < message.name.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                let message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (let i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (let j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    let message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (let i = 0; i < message.location.length; ++i) {
                        let error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                let message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (let i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (let j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (let i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    let message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (let i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (let i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (let j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (let j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (let i = 0; i < message.annotation.length; ++i) {
                        let error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                let message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (let i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (let j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    let message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

export const sharelib = $root.sharelib = (() => {

    /**
     * Namespace sharelib.
     * @exports sharelib
     * @namespace
     */
    const sharelib = {};

    sharelib.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof sharelib
         * @interface IAddress
         * @property {string|null} [countryCode] Address countryCode
         * @property {string|null} [postalCode] Address postalCode
         * @property {string|null} [addressLine] Address addressLine
         */

        /**
         * Constructs a new Address.
         * @memberof sharelib
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {sharelib.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address countryCode.
         * @member {string} countryCode
         * @memberof sharelib.Address
         * @instance
         */
        Address.prototype.countryCode = "";

        /**
         * Address postalCode.
         * @member {string} postalCode
         * @memberof sharelib.Address
         * @instance
         */
        Address.prototype.postalCode = "";

        /**
         * Address addressLine.
         * @member {string} addressLine
         * @memberof sharelib.Address
         * @instance
         */
        Address.prototype.addressLine = "";

        /**
         * Verifies an Address message.
         * @function verify
         * @memberof sharelib.Address
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Address.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                if (!$util.isString(message.countryCode))
                    return "countryCode: string expected";
            if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                if (!$util.isString(message.postalCode))
                    return "postalCode: string expected";
            if (message.addressLine != null && message.hasOwnProperty("addressLine"))
                if (!$util.isString(message.addressLine))
                    return "addressLine: string expected";
            return null;
        };

        /**
         * Creates an Address message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.Address
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.Address} Address
         */
        Address.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.Address)
                return object;
            let message = new $root.sharelib.Address();
            if (object.countryCode != null)
                message.countryCode = String(object.countryCode);
            if (object.postalCode != null)
                message.postalCode = String(object.postalCode);
            if (object.addressLine != null)
                message.addressLine = String(object.addressLine);
            return message;
        };

        /**
         * Creates a plain object from an Address message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.Address
         * @static
         * @param {sharelib.Address} message Address
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Address.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.countryCode = "";
                object.postalCode = "";
                object.addressLine = "";
            }
            if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                object.countryCode = message.countryCode;
            if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                object.postalCode = message.postalCode;
            if (message.addressLine != null && message.hasOwnProperty("addressLine"))
                object.addressLine = message.addressLine;
            return object;
        };

        /**
         * Converts this Address to JSON.
         * @function toJSON
         * @memberof sharelib.Address
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Address.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Address;
    })();

    sharelib.MonetaryAmount = (function() {

        /**
         * Properties of a MonetaryAmount.
         * @memberof sharelib
         * @interface IMonetaryAmount
         * @property {string|null} [currencyCode] MonetaryAmount currencyCode
         * @property {Long|null} [integralAmount] MonetaryAmount integralAmount
         * @property {number|null} [fractionalAmount] MonetaryAmount fractionalAmount
         */

        /**
         * Constructs a new MonetaryAmount.
         * @memberof sharelib
         * @classdesc Represents a MonetaryAmount.
         * @implements IMonetaryAmount
         * @constructor
         * @param {sharelib.IMonetaryAmount=} [properties] Properties to set
         */
        function MonetaryAmount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonetaryAmount currencyCode.
         * @member {string} currencyCode
         * @memberof sharelib.MonetaryAmount
         * @instance
         */
        MonetaryAmount.prototype.currencyCode = "";

        /**
         * MonetaryAmount integralAmount.
         * @member {Long} integralAmount
         * @memberof sharelib.MonetaryAmount
         * @instance
         */
        MonetaryAmount.prototype.integralAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MonetaryAmount fractionalAmount.
         * @member {number} fractionalAmount
         * @memberof sharelib.MonetaryAmount
         * @instance
         */
        MonetaryAmount.prototype.fractionalAmount = 0;

        /**
         * Verifies a MonetaryAmount message.
         * @function verify
         * @memberof sharelib.MonetaryAmount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonetaryAmount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                if (!$util.isString(message.currencyCode))
                    return "currencyCode: string expected";
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (!$util.isInteger(message.integralAmount) && !(message.integralAmount && $util.isInteger(message.integralAmount.low) && $util.isInteger(message.integralAmount.high)))
                    return "integralAmount: integer|Long expected";
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                if (!$util.isInteger(message.fractionalAmount))
                    return "fractionalAmount: integer expected";
            return null;
        };

        /**
         * Creates a MonetaryAmount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.MonetaryAmount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.MonetaryAmount} MonetaryAmount
         */
        MonetaryAmount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.MonetaryAmount)
                return object;
            let message = new $root.sharelib.MonetaryAmount();
            if (object.currencyCode != null)
                message.currencyCode = String(object.currencyCode);
            if (object.integralAmount != null)
                if ($util.Long)
                    (message.integralAmount = $util.Long.fromValue(object.integralAmount)).unsigned = false;
                else if (typeof object.integralAmount === "string")
                    message.integralAmount = parseInt(object.integralAmount, 10);
                else if (typeof object.integralAmount === "number")
                    message.integralAmount = object.integralAmount;
                else if (typeof object.integralAmount === "object")
                    message.integralAmount = new $util.LongBits(object.integralAmount.low >>> 0, object.integralAmount.high >>> 0).toNumber();
            if (object.fractionalAmount != null)
                message.fractionalAmount = object.fractionalAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from a MonetaryAmount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.MonetaryAmount
         * @static
         * @param {sharelib.MonetaryAmount} message MonetaryAmount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonetaryAmount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currencyCode = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.integralAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.integralAmount = options.longs === String ? "0" : 0;
                object.fractionalAmount = 0;
            }
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                object.currencyCode = message.currencyCode;
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (typeof message.integralAmount === "number")
                    object.integralAmount = options.longs === String ? String(message.integralAmount) : message.integralAmount;
                else
                    object.integralAmount = options.longs === String ? $util.Long.prototype.toString.call(message.integralAmount) : options.longs === Number ? new $util.LongBits(message.integralAmount.low >>> 0, message.integralAmount.high >>> 0).toNumber() : message.integralAmount;
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                object.fractionalAmount = message.fractionalAmount;
            return object;
        };

        /**
         * Converts this MonetaryAmount to JSON.
         * @function toJSON
         * @memberof sharelib.MonetaryAmount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonetaryAmount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MonetaryAmount;
    })();

    sharelib.DistantAmount = (function() {

        /**
         * Properties of a DistantAmount.
         * @memberof sharelib
         * @interface IDistantAmount
         * @property {sharelib.DistantAmount.Unit|null} [unit] DistantAmount unit
         * @property {Long|null} [integralAmount] DistantAmount integralAmount
         * @property {number|null} [fractionalAmount] DistantAmount fractionalAmount
         */

        /**
         * Constructs a new DistantAmount.
         * @memberof sharelib
         * @classdesc Represents a DistantAmount.
         * @implements IDistantAmount
         * @constructor
         * @param {sharelib.IDistantAmount=} [properties] Properties to set
         */
        function DistantAmount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DistantAmount unit.
         * @member {sharelib.DistantAmount.Unit} unit
         * @memberof sharelib.DistantAmount
         * @instance
         */
        DistantAmount.prototype.unit = 0;

        /**
         * DistantAmount integralAmount.
         * @member {Long} integralAmount
         * @memberof sharelib.DistantAmount
         * @instance
         */
        DistantAmount.prototype.integralAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DistantAmount fractionalAmount.
         * @member {number} fractionalAmount
         * @memberof sharelib.DistantAmount
         * @instance
         */
        DistantAmount.prototype.fractionalAmount = 0;

        /**
         * Verifies a DistantAmount message.
         * @function verify
         * @memberof sharelib.DistantAmount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DistantAmount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.unit != null && message.hasOwnProperty("unit"))
                switch (message.unit) {
                default:
                    return "unit: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (!$util.isInteger(message.integralAmount) && !(message.integralAmount && $util.isInteger(message.integralAmount.low) && $util.isInteger(message.integralAmount.high)))
                    return "integralAmount: integer|Long expected";
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                if (!$util.isInteger(message.fractionalAmount))
                    return "fractionalAmount: integer expected";
            return null;
        };

        /**
         * Creates a DistantAmount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.DistantAmount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.DistantAmount} DistantAmount
         */
        DistantAmount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.DistantAmount)
                return object;
            let message = new $root.sharelib.DistantAmount();
            switch (object.unit) {
            case "NONE":
            case 0:
                message.unit = 0;
                break;
            case "METER":
            case 1:
                message.unit = 1;
                break;
            case "CENTI_METER":
            case 2:
                message.unit = 2;
                break;
            case "MILLI_METER":
            case 3:
                message.unit = 3;
                break;
            case "MICRO_METER":
            case 4:
                message.unit = 4;
                break;
            case "NANO_METER":
            case 5:
                message.unit = 5;
                break;
            }
            if (object.integralAmount != null)
                if ($util.Long)
                    (message.integralAmount = $util.Long.fromValue(object.integralAmount)).unsigned = false;
                else if (typeof object.integralAmount === "string")
                    message.integralAmount = parseInt(object.integralAmount, 10);
                else if (typeof object.integralAmount === "number")
                    message.integralAmount = object.integralAmount;
                else if (typeof object.integralAmount === "object")
                    message.integralAmount = new $util.LongBits(object.integralAmount.low >>> 0, object.integralAmount.high >>> 0).toNumber();
            if (object.fractionalAmount != null)
                message.fractionalAmount = object.fractionalAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from a DistantAmount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.DistantAmount
         * @static
         * @param {sharelib.DistantAmount} message DistantAmount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DistantAmount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.unit = options.enums === String ? "NONE" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.integralAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.integralAmount = options.longs === String ? "0" : 0;
                object.fractionalAmount = 0;
            }
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = options.enums === String ? $root.sharelib.DistantAmount.Unit[message.unit] : message.unit;
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (typeof message.integralAmount === "number")
                    object.integralAmount = options.longs === String ? String(message.integralAmount) : message.integralAmount;
                else
                    object.integralAmount = options.longs === String ? $util.Long.prototype.toString.call(message.integralAmount) : options.longs === Number ? new $util.LongBits(message.integralAmount.low >>> 0, message.integralAmount.high >>> 0).toNumber() : message.integralAmount;
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                object.fractionalAmount = message.fractionalAmount;
            return object;
        };

        /**
         * Converts this DistantAmount to JSON.
         * @function toJSON
         * @memberof sharelib.DistantAmount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DistantAmount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Unit enum.
         * @name sharelib.DistantAmount.Unit
         * @enum {number}
         * @property {number} NONE=0 NONE value
         * @property {number} METER=1 METER value
         * @property {number} CENTI_METER=2 CENTI_METER value
         * @property {number} MILLI_METER=3 MILLI_METER value
         * @property {number} MICRO_METER=4 MICRO_METER value
         * @property {number} NANO_METER=5 NANO_METER value
         */
        DistantAmount.Unit = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NONE"] = 0;
            values[valuesById[1] = "METER"] = 1;
            values[valuesById[2] = "CENTI_METER"] = 2;
            values[valuesById[3] = "MILLI_METER"] = 3;
            values[valuesById[4] = "MICRO_METER"] = 4;
            values[valuesById[5] = "NANO_METER"] = 5;
            return values;
        })();

        return DistantAmount;
    })();

    sharelib.QuantityAmount = (function() {

        /**
         * Properties of a QuantityAmount.
         * @memberof sharelib
         * @interface IQuantityAmount
         * @property {sharelib.INameOption|null} [unit] QuantityAmount unit
         * @property {Long|null} [integralAmount] QuantityAmount integralAmount
         * @property {number|null} [fractionalAmount] QuantityAmount fractionalAmount
         */

        /**
         * Constructs a new QuantityAmount.
         * @memberof sharelib
         * @classdesc Represents a QuantityAmount.
         * @implements IQuantityAmount
         * @constructor
         * @param {sharelib.IQuantityAmount=} [properties] Properties to set
         */
        function QuantityAmount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QuantityAmount unit.
         * @member {sharelib.INameOption|null|undefined} unit
         * @memberof sharelib.QuantityAmount
         * @instance
         */
        QuantityAmount.prototype.unit = null;

        /**
         * QuantityAmount integralAmount.
         * @member {Long} integralAmount
         * @memberof sharelib.QuantityAmount
         * @instance
         */
        QuantityAmount.prototype.integralAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QuantityAmount fractionalAmount.
         * @member {number} fractionalAmount
         * @memberof sharelib.QuantityAmount
         * @instance
         */
        QuantityAmount.prototype.fractionalAmount = 0;

        /**
         * Verifies a QuantityAmount message.
         * @function verify
         * @memberof sharelib.QuantityAmount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QuantityAmount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.unit != null && message.hasOwnProperty("unit")) {
                let error = $root.sharelib.NameOption.verify(message.unit);
                if (error)
                    return "unit." + error;
            }
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (!$util.isInteger(message.integralAmount) && !(message.integralAmount && $util.isInteger(message.integralAmount.low) && $util.isInteger(message.integralAmount.high)))
                    return "integralAmount: integer|Long expected";
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                if (!$util.isInteger(message.fractionalAmount))
                    return "fractionalAmount: integer expected";
            return null;
        };

        /**
         * Creates a QuantityAmount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.QuantityAmount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.QuantityAmount} QuantityAmount
         */
        QuantityAmount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.QuantityAmount)
                return object;
            let message = new $root.sharelib.QuantityAmount();
            if (object.unit != null) {
                if (typeof object.unit !== "object")
                    throw TypeError(".sharelib.QuantityAmount.unit: object expected");
                message.unit = $root.sharelib.NameOption.fromObject(object.unit);
            }
            if (object.integralAmount != null)
                if ($util.Long)
                    (message.integralAmount = $util.Long.fromValue(object.integralAmount)).unsigned = false;
                else if (typeof object.integralAmount === "string")
                    message.integralAmount = parseInt(object.integralAmount, 10);
                else if (typeof object.integralAmount === "number")
                    message.integralAmount = object.integralAmount;
                else if (typeof object.integralAmount === "object")
                    message.integralAmount = new $util.LongBits(object.integralAmount.low >>> 0, object.integralAmount.high >>> 0).toNumber();
            if (object.fractionalAmount != null)
                message.fractionalAmount = object.fractionalAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from a QuantityAmount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.QuantityAmount
         * @static
         * @param {sharelib.QuantityAmount} message QuantityAmount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QuantityAmount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.unit = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.integralAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.integralAmount = options.longs === String ? "0" : 0;
                object.fractionalAmount = 0;
            }
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = $root.sharelib.NameOption.toObject(message.unit, options);
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (typeof message.integralAmount === "number")
                    object.integralAmount = options.longs === String ? String(message.integralAmount) : message.integralAmount;
                else
                    object.integralAmount = options.longs === String ? $util.Long.prototype.toString.call(message.integralAmount) : options.longs === Number ? new $util.LongBits(message.integralAmount.low >>> 0, message.integralAmount.high >>> 0).toNumber() : message.integralAmount;
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                object.fractionalAmount = message.fractionalAmount;
            return object;
        };

        /**
         * Converts this QuantityAmount to JSON.
         * @function toJSON
         * @memberof sharelib.QuantityAmount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QuantityAmount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QuantityAmount;
    })();

    sharelib.Amount = (function() {

        /**
         * Properties of an Amount.
         * @memberof sharelib
         * @interface IAmount
         * @property {Long|null} [integralAmount] Amount integralAmount
         * @property {number|null} [fractionalAmount] Amount fractionalAmount
         */

        /**
         * Constructs a new Amount.
         * @memberof sharelib
         * @classdesc Represents an Amount.
         * @implements IAmount
         * @constructor
         * @param {sharelib.IAmount=} [properties] Properties to set
         */
        function Amount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Amount integralAmount.
         * @member {Long} integralAmount
         * @memberof sharelib.Amount
         * @instance
         */
        Amount.prototype.integralAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Amount fractionalAmount.
         * @member {number} fractionalAmount
         * @memberof sharelib.Amount
         * @instance
         */
        Amount.prototype.fractionalAmount = 0;

        /**
         * Verifies an Amount message.
         * @function verify
         * @memberof sharelib.Amount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Amount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (!$util.isInteger(message.integralAmount) && !(message.integralAmount && $util.isInteger(message.integralAmount.low) && $util.isInteger(message.integralAmount.high)))
                    return "integralAmount: integer|Long expected";
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                if (!$util.isInteger(message.fractionalAmount))
                    return "fractionalAmount: integer expected";
            return null;
        };

        /**
         * Creates an Amount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.Amount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.Amount} Amount
         */
        Amount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.Amount)
                return object;
            let message = new $root.sharelib.Amount();
            if (object.integralAmount != null)
                if ($util.Long)
                    (message.integralAmount = $util.Long.fromValue(object.integralAmount)).unsigned = false;
                else if (typeof object.integralAmount === "string")
                    message.integralAmount = parseInt(object.integralAmount, 10);
                else if (typeof object.integralAmount === "number")
                    message.integralAmount = object.integralAmount;
                else if (typeof object.integralAmount === "object")
                    message.integralAmount = new $util.LongBits(object.integralAmount.low >>> 0, object.integralAmount.high >>> 0).toNumber();
            if (object.fractionalAmount != null)
                message.fractionalAmount = object.fractionalAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from an Amount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.Amount
         * @static
         * @param {sharelib.Amount} message Amount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Amount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.integralAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.integralAmount = options.longs === String ? "0" : 0;
                object.fractionalAmount = 0;
            }
            if (message.integralAmount != null && message.hasOwnProperty("integralAmount"))
                if (typeof message.integralAmount === "number")
                    object.integralAmount = options.longs === String ? String(message.integralAmount) : message.integralAmount;
                else
                    object.integralAmount = options.longs === String ? $util.Long.prototype.toString.call(message.integralAmount) : options.longs === Number ? new $util.LongBits(message.integralAmount.low >>> 0, message.integralAmount.high >>> 0).toNumber() : message.integralAmount;
            if (message.fractionalAmount != null && message.hasOwnProperty("fractionalAmount"))
                object.fractionalAmount = message.fractionalAmount;
            return object;
        };

        /**
         * Converts this Amount to JSON.
         * @function toJSON
         * @memberof sharelib.Amount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Amount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Amount;
    })();

    sharelib.NullableQuantityAmount = (function() {

        /**
         * Properties of a NullableQuantityAmount.
         * @memberof sharelib
         * @interface INullableQuantityAmount
         * @property {sharelib.INameOption|null} [unit] NullableQuantityAmount unit
         * @property {string|null} [amountNum] NullableQuantityAmount amountNum
         */

        /**
         * Constructs a new NullableQuantityAmount.
         * @memberof sharelib
         * @classdesc Represents a NullableQuantityAmount.
         * @implements INullableQuantityAmount
         * @constructor
         * @param {sharelib.INullableQuantityAmount=} [properties] Properties to set
         */
        function NullableQuantityAmount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NullableQuantityAmount unit.
         * @member {sharelib.INameOption|null|undefined} unit
         * @memberof sharelib.NullableQuantityAmount
         * @instance
         */
        NullableQuantityAmount.prototype.unit = null;

        /**
         * NullableQuantityAmount amountNum.
         * @member {string} amountNum
         * @memberof sharelib.NullableQuantityAmount
         * @instance
         */
        NullableQuantityAmount.prototype.amountNum = "";

        /**
         * Verifies a NullableQuantityAmount message.
         * @function verify
         * @memberof sharelib.NullableQuantityAmount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NullableQuantityAmount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.unit != null && message.hasOwnProperty("unit")) {
                let error = $root.sharelib.NameOption.verify(message.unit);
                if (error)
                    return "unit." + error;
            }
            if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                if (!$util.isString(message.amountNum))
                    return "amountNum: string expected";
            return null;
        };

        /**
         * Creates a NullableQuantityAmount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.NullableQuantityAmount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.NullableQuantityAmount} NullableQuantityAmount
         */
        NullableQuantityAmount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.NullableQuantityAmount)
                return object;
            let message = new $root.sharelib.NullableQuantityAmount();
            if (object.unit != null) {
                if (typeof object.unit !== "object")
                    throw TypeError(".sharelib.NullableQuantityAmount.unit: object expected");
                message.unit = $root.sharelib.NameOption.fromObject(object.unit);
            }
            if (object.amountNum != null)
                message.amountNum = String(object.amountNum);
            return message;
        };

        /**
         * Creates a plain object from a NullableQuantityAmount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.NullableQuantityAmount
         * @static
         * @param {sharelib.NullableQuantityAmount} message NullableQuantityAmount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullableQuantityAmount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.unit = null;
                object.amountNum = "";
            }
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = $root.sharelib.NameOption.toObject(message.unit, options);
            if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                object.amountNum = message.amountNum;
            return object;
        };

        /**
         * Converts this NullableQuantityAmount to JSON.
         * @function toJSON
         * @memberof sharelib.NullableQuantityAmount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullableQuantityAmount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullableQuantityAmount;
    })();

    sharelib.NullableMonetaryAmount = (function() {

        /**
         * Properties of a NullableMonetaryAmount.
         * @memberof sharelib
         * @interface INullableMonetaryAmount
         * @property {string|null} [currencyCode] NullableMonetaryAmount currencyCode
         * @property {string|null} [amountNum] NullableMonetaryAmount amountNum
         */

        /**
         * Constructs a new NullableMonetaryAmount.
         * @memberof sharelib
         * @classdesc Represents a NullableMonetaryAmount.
         * @implements INullableMonetaryAmount
         * @constructor
         * @param {sharelib.INullableMonetaryAmount=} [properties] Properties to set
         */
        function NullableMonetaryAmount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NullableMonetaryAmount currencyCode.
         * @member {string} currencyCode
         * @memberof sharelib.NullableMonetaryAmount
         * @instance
         */
        NullableMonetaryAmount.prototype.currencyCode = "";

        /**
         * NullableMonetaryAmount amountNum.
         * @member {string} amountNum
         * @memberof sharelib.NullableMonetaryAmount
         * @instance
         */
        NullableMonetaryAmount.prototype.amountNum = "";

        /**
         * Verifies a NullableMonetaryAmount message.
         * @function verify
         * @memberof sharelib.NullableMonetaryAmount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NullableMonetaryAmount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                if (!$util.isString(message.currencyCode))
                    return "currencyCode: string expected";
            if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                if (!$util.isString(message.amountNum))
                    return "amountNum: string expected";
            return null;
        };

        /**
         * Creates a NullableMonetaryAmount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.NullableMonetaryAmount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.NullableMonetaryAmount} NullableMonetaryAmount
         */
        NullableMonetaryAmount.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.NullableMonetaryAmount)
                return object;
            let message = new $root.sharelib.NullableMonetaryAmount();
            if (object.currencyCode != null)
                message.currencyCode = String(object.currencyCode);
            if (object.amountNum != null)
                message.amountNum = String(object.amountNum);
            return message;
        };

        /**
         * Creates a plain object from a NullableMonetaryAmount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.NullableMonetaryAmount
         * @static
         * @param {sharelib.NullableMonetaryAmount} message NullableMonetaryAmount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullableMonetaryAmount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currencyCode = "";
                object.amountNum = "";
            }
            if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                object.currencyCode = message.currencyCode;
            if (message.amountNum != null && message.hasOwnProperty("amountNum"))
                object.amountNum = message.amountNum;
            return object;
        };

        /**
         * Converts this NullableMonetaryAmount to JSON.
         * @function toJSON
         * @memberof sharelib.NullableMonetaryAmount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullableMonetaryAmount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullableMonetaryAmount;
    })();

    sharelib.NameOption = (function() {

        /**
         * Properties of a NameOption.
         * @memberof sharelib
         * @interface INameOption
         * @property {string|null} [categoryName] NameOption categoryName
         * @property {string|null} [code] NameOption code
         * @property {Object.<string,string>|null} [displayNameLang] NameOption displayNameLang
         * @property {string|null} [systemName] NameOption systemName
         */

        /**
         * Constructs a new NameOption.
         * @memberof sharelib
         * @classdesc Represents a NameOption.
         * @implements INameOption
         * @constructor
         * @param {sharelib.INameOption=} [properties] Properties to set
         */
        function NameOption(properties) {
            this.displayNameLang = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NameOption categoryName.
         * @member {string} categoryName
         * @memberof sharelib.NameOption
         * @instance
         */
        NameOption.prototype.categoryName = "";

        /**
         * NameOption code.
         * @member {string} code
         * @memberof sharelib.NameOption
         * @instance
         */
        NameOption.prototype.code = "";

        /**
         * NameOption displayNameLang.
         * @member {Object.<string,string>} displayNameLang
         * @memberof sharelib.NameOption
         * @instance
         */
        NameOption.prototype.displayNameLang = $util.emptyObject;

        /**
         * NameOption systemName.
         * @member {string} systemName
         * @memberof sharelib.NameOption
         * @instance
         */
        NameOption.prototype.systemName = "";

        /**
         * Verifies a NameOption message.
         * @function verify
         * @memberof sharelib.NameOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NameOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                if (!$util.isString(message.categoryName))
                    return "categoryName: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                if (!$util.isObject(message.displayNameLang))
                    return "displayNameLang: object expected";
                let key = Object.keys(message.displayNameLang);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.displayNameLang[key[i]]))
                        return "displayNameLang: string{k:string} expected";
            }
            if (message.systemName != null && message.hasOwnProperty("systemName"))
                if (!$util.isString(message.systemName))
                    return "systemName: string expected";
            return null;
        };

        /**
         * Creates a NameOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.NameOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.NameOption} NameOption
         */
        NameOption.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.NameOption)
                return object;
            let message = new $root.sharelib.NameOption();
            if (object.categoryName != null)
                message.categoryName = String(object.categoryName);
            if (object.code != null)
                message.code = String(object.code);
            if (object.displayNameLang) {
                if (typeof object.displayNameLang !== "object")
                    throw TypeError(".sharelib.NameOption.displayNameLang: object expected");
                message.displayNameLang = {};
                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
            }
            if (object.systemName != null)
                message.systemName = String(object.systemName);
            return message;
        };

        /**
         * Creates a plain object from a NameOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.NameOption
         * @static
         * @param {sharelib.NameOption} message NameOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NameOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.displayNameLang = {};
            if (options.defaults) {
                object.categoryName = "";
                object.code = "";
                object.systemName = "";
            }
            if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                object.categoryName = message.categoryName;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            let keys2;
            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                object.displayNameLang = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
            }
            if (message.systemName != null && message.hasOwnProperty("systemName"))
                object.systemName = message.systemName;
            return object;
        };

        /**
         * Converts this NameOption to JSON.
         * @function toJSON
         * @memberof sharelib.NameOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NameOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NameOption;
    })();

    sharelib.ApprovalRequest = (function() {

        /**
         * Properties of an ApprovalRequest.
         * @memberof sharelib
         * @interface IApprovalRequest
         * @property {Long|null} [requestedAt] ApprovalRequest requestedAt
         * @property {sharelib.IEmbeddedUser|null} [requestedStaff] ApprovalRequest requestedStaff
         * @property {sharelib.IUserReference|null} [approvalPlanStaff] ApprovalRequest approvalPlanStaff
         * @property {sharelib.IOrganizationStructureReference|null} [approvalPlanOrganization] ApprovalRequest approvalPlanOrganization
         * @property {string|null} [requestedComment] ApprovalRequest requestedComment
         */

        /**
         * Constructs a new ApprovalRequest.
         * @memberof sharelib
         * @classdesc Represents an ApprovalRequest.
         * @implements IApprovalRequest
         * @constructor
         * @param {sharelib.IApprovalRequest=} [properties] Properties to set
         */
        function ApprovalRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApprovalRequest requestedAt.
         * @member {Long} requestedAt
         * @memberof sharelib.ApprovalRequest
         * @instance
         */
        ApprovalRequest.prototype.requestedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ApprovalRequest requestedStaff.
         * @member {sharelib.IEmbeddedUser|null|undefined} requestedStaff
         * @memberof sharelib.ApprovalRequest
         * @instance
         */
        ApprovalRequest.prototype.requestedStaff = null;

        /**
         * ApprovalRequest approvalPlanStaff.
         * @member {sharelib.IUserReference|null|undefined} approvalPlanStaff
         * @memberof sharelib.ApprovalRequest
         * @instance
         */
        ApprovalRequest.prototype.approvalPlanStaff = null;

        /**
         * ApprovalRequest approvalPlanOrganization.
         * @member {sharelib.IOrganizationStructureReference|null|undefined} approvalPlanOrganization
         * @memberof sharelib.ApprovalRequest
         * @instance
         */
        ApprovalRequest.prototype.approvalPlanOrganization = null;

        /**
         * ApprovalRequest requestedComment.
         * @member {string} requestedComment
         * @memberof sharelib.ApprovalRequest
         * @instance
         */
        ApprovalRequest.prototype.requestedComment = "";

        /**
         * Verifies an ApprovalRequest message.
         * @function verify
         * @memberof sharelib.ApprovalRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApprovalRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                if (!$util.isInteger(message.requestedAt) && !(message.requestedAt && $util.isInteger(message.requestedAt.low) && $util.isInteger(message.requestedAt.high)))
                    return "requestedAt: integer|Long expected";
            if (message.requestedStaff != null && message.hasOwnProperty("requestedStaff")) {
                let error = $root.sharelib.EmbeddedUser.verify(message.requestedStaff);
                if (error)
                    return "requestedStaff." + error;
            }
            if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff")) {
                let error = $root.sharelib.UserReference.verify(message.approvalPlanStaff);
                if (error)
                    return "approvalPlanStaff." + error;
            }
            if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization")) {
                let error = $root.sharelib.OrganizationStructureReference.verify(message.approvalPlanOrganization);
                if (error)
                    return "approvalPlanOrganization." + error;
            }
            if (message.requestedComment != null && message.hasOwnProperty("requestedComment"))
                if (!$util.isString(message.requestedComment))
                    return "requestedComment: string expected";
            return null;
        };

        /**
         * Creates an ApprovalRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.ApprovalRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.ApprovalRequest} ApprovalRequest
         */
        ApprovalRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.ApprovalRequest)
                return object;
            let message = new $root.sharelib.ApprovalRequest();
            if (object.requestedAt != null)
                if ($util.Long)
                    (message.requestedAt = $util.Long.fromValue(object.requestedAt)).unsigned = false;
                else if (typeof object.requestedAt === "string")
                    message.requestedAt = parseInt(object.requestedAt, 10);
                else if (typeof object.requestedAt === "number")
                    message.requestedAt = object.requestedAt;
                else if (typeof object.requestedAt === "object")
                    message.requestedAt = new $util.LongBits(object.requestedAt.low >>> 0, object.requestedAt.high >>> 0).toNumber();
            if (object.requestedStaff != null) {
                if (typeof object.requestedStaff !== "object")
                    throw TypeError(".sharelib.ApprovalRequest.requestedStaff: object expected");
                message.requestedStaff = $root.sharelib.EmbeddedUser.fromObject(object.requestedStaff);
            }
            if (object.approvalPlanStaff != null) {
                if (typeof object.approvalPlanStaff !== "object")
                    throw TypeError(".sharelib.ApprovalRequest.approvalPlanStaff: object expected");
                message.approvalPlanStaff = $root.sharelib.UserReference.fromObject(object.approvalPlanStaff);
            }
            if (object.approvalPlanOrganization != null) {
                if (typeof object.approvalPlanOrganization !== "object")
                    throw TypeError(".sharelib.ApprovalRequest.approvalPlanOrganization: object expected");
                message.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.fromObject(object.approvalPlanOrganization);
            }
            if (object.requestedComment != null)
                message.requestedComment = String(object.requestedComment);
            return message;
        };

        /**
         * Creates a plain object from an ApprovalRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.ApprovalRequest
         * @static
         * @param {sharelib.ApprovalRequest} message ApprovalRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApprovalRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.requestedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestedAt = options.longs === String ? "0" : 0;
                object.requestedComment = "";
                object.requestedStaff = null;
                object.approvalPlanStaff = null;
                object.approvalPlanOrganization = null;
            }
            if (message.requestedAt != null && message.hasOwnProperty("requestedAt"))
                if (typeof message.requestedAt === "number")
                    object.requestedAt = options.longs === String ? String(message.requestedAt) : message.requestedAt;
                else
                    object.requestedAt = options.longs === String ? $util.Long.prototype.toString.call(message.requestedAt) : options.longs === Number ? new $util.LongBits(message.requestedAt.low >>> 0, message.requestedAt.high >>> 0).toNumber() : message.requestedAt;
            if (message.requestedComment != null && message.hasOwnProperty("requestedComment"))
                object.requestedComment = message.requestedComment;
            if (message.requestedStaff != null && message.hasOwnProperty("requestedStaff"))
                object.requestedStaff = $root.sharelib.EmbeddedUser.toObject(message.requestedStaff, options);
            if (message.approvalPlanStaff != null && message.hasOwnProperty("approvalPlanStaff"))
                object.approvalPlanStaff = $root.sharelib.UserReference.toObject(message.approvalPlanStaff, options);
            if (message.approvalPlanOrganization != null && message.hasOwnProperty("approvalPlanOrganization"))
                object.approvalPlanOrganization = $root.sharelib.OrganizationStructureReference.toObject(message.approvalPlanOrganization, options);
            return object;
        };

        /**
         * Converts this ApprovalRequest to JSON.
         * @function toJSON
         * @memberof sharelib.ApprovalRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApprovalRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApprovalRequest;
    })();

    sharelib.ApprovalResult = (function() {

        /**
         * Properties of an ApprovalResult.
         * @memberof sharelib
         * @interface IApprovalResult
         * @property {Long|null} [decidedAt] ApprovalResult decidedAt
         * @property {sharelib.IEmbeddedUser|null} [decidedStaff] ApprovalResult decidedStaff
         * @property {string|null} [decidedComment] ApprovalResult decidedComment
         */

        /**
         * Constructs a new ApprovalResult.
         * @memberof sharelib
         * @classdesc Represents an ApprovalResult.
         * @implements IApprovalResult
         * @constructor
         * @param {sharelib.IApprovalResult=} [properties] Properties to set
         */
        function ApprovalResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApprovalResult decidedAt.
         * @member {Long} decidedAt
         * @memberof sharelib.ApprovalResult
         * @instance
         */
        ApprovalResult.prototype.decidedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ApprovalResult decidedStaff.
         * @member {sharelib.IEmbeddedUser|null|undefined} decidedStaff
         * @memberof sharelib.ApprovalResult
         * @instance
         */
        ApprovalResult.prototype.decidedStaff = null;

        /**
         * ApprovalResult decidedComment.
         * @member {string} decidedComment
         * @memberof sharelib.ApprovalResult
         * @instance
         */
        ApprovalResult.prototype.decidedComment = "";

        /**
         * Verifies an ApprovalResult message.
         * @function verify
         * @memberof sharelib.ApprovalResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApprovalResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.decidedAt != null && message.hasOwnProperty("decidedAt"))
                if (!$util.isInteger(message.decidedAt) && !(message.decidedAt && $util.isInteger(message.decidedAt.low) && $util.isInteger(message.decidedAt.high)))
                    return "decidedAt: integer|Long expected";
            if (message.decidedStaff != null && message.hasOwnProperty("decidedStaff")) {
                let error = $root.sharelib.EmbeddedUser.verify(message.decidedStaff);
                if (error)
                    return "decidedStaff." + error;
            }
            if (message.decidedComment != null && message.hasOwnProperty("decidedComment"))
                if (!$util.isString(message.decidedComment))
                    return "decidedComment: string expected";
            return null;
        };

        /**
         * Creates an ApprovalResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.ApprovalResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.ApprovalResult} ApprovalResult
         */
        ApprovalResult.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.ApprovalResult)
                return object;
            let message = new $root.sharelib.ApprovalResult();
            if (object.decidedAt != null)
                if ($util.Long)
                    (message.decidedAt = $util.Long.fromValue(object.decidedAt)).unsigned = false;
                else if (typeof object.decidedAt === "string")
                    message.decidedAt = parseInt(object.decidedAt, 10);
                else if (typeof object.decidedAt === "number")
                    message.decidedAt = object.decidedAt;
                else if (typeof object.decidedAt === "object")
                    message.decidedAt = new $util.LongBits(object.decidedAt.low >>> 0, object.decidedAt.high >>> 0).toNumber();
            if (object.decidedStaff != null) {
                if (typeof object.decidedStaff !== "object")
                    throw TypeError(".sharelib.ApprovalResult.decidedStaff: object expected");
                message.decidedStaff = $root.sharelib.EmbeddedUser.fromObject(object.decidedStaff);
            }
            if (object.decidedComment != null)
                message.decidedComment = String(object.decidedComment);
            return message;
        };

        /**
         * Creates a plain object from an ApprovalResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.ApprovalResult
         * @static
         * @param {sharelib.ApprovalResult} message ApprovalResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApprovalResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.decidedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.decidedAt = options.longs === String ? "0" : 0;
                object.decidedComment = "";
                object.decidedStaff = null;
            }
            if (message.decidedAt != null && message.hasOwnProperty("decidedAt"))
                if (typeof message.decidedAt === "number")
                    object.decidedAt = options.longs === String ? String(message.decidedAt) : message.decidedAt;
                else
                    object.decidedAt = options.longs === String ? $util.Long.prototype.toString.call(message.decidedAt) : options.longs === Number ? new $util.LongBits(message.decidedAt.low >>> 0, message.decidedAt.high >>> 0).toNumber() : message.decidedAt;
            if (message.decidedComment != null && message.hasOwnProperty("decidedComment"))
                object.decidedComment = message.decidedComment;
            if (message.decidedStaff != null && message.hasOwnProperty("decidedStaff"))
                object.decidedStaff = $root.sharelib.EmbeddedUser.toObject(message.decidedStaff, options);
            return object;
        };

        /**
         * Converts this ApprovalResult to JSON.
         * @function toJSON
         * @memberof sharelib.ApprovalResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApprovalResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApprovalResult;
    })();

    sharelib.UserReference = (function() {

        /**
         * Properties of a UserReference.
         * @memberof sharelib
         * @interface IUserReference
         * @property {string|null} [userId] UserReference userId
         * @property {string|null} [email] UserReference email
         * @property {string|null} [displayName] UserReference displayName
         */

        /**
         * Constructs a new UserReference.
         * @memberof sharelib
         * @classdesc Represents a UserReference.
         * @implements IUserReference
         * @constructor
         * @param {sharelib.IUserReference=} [properties] Properties to set
         */
        function UserReference(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserReference userId.
         * @member {string} userId
         * @memberof sharelib.UserReference
         * @instance
         */
        UserReference.prototype.userId = "";

        /**
         * UserReference email.
         * @member {string} email
         * @memberof sharelib.UserReference
         * @instance
         */
        UserReference.prototype.email = "";

        /**
         * UserReference displayName.
         * @member {string} displayName
         * @memberof sharelib.UserReference
         * @instance
         */
        UserReference.prototype.displayName = "";

        /**
         * Verifies a UserReference message.
         * @function verify
         * @memberof sharelib.UserReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            return null;
        };

        /**
         * Creates a UserReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.UserReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.UserReference} UserReference
         */
        UserReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.UserReference)
                return object;
            let message = new $root.sharelib.UserReference();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.email != null)
                message.email = String(object.email);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            return message;
        };

        /**
         * Creates a plain object from a UserReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.UserReference
         * @static
         * @param {sharelib.UserReference} message UserReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.email = "";
                object.displayName = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            return object;
        };

        /**
         * Converts this UserReference to JSON.
         * @function toJSON
         * @memberof sharelib.UserReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserReference;
    })();

    sharelib.OrganizationReference = (function() {

        /**
         * Properties of an OrganizationReference.
         * @memberof sharelib
         * @interface IOrganizationReference
         * @property {string|null} [organizationId] OrganizationReference organizationId
         * @property {string|null} [code] OrganizationReference code
         * @property {string|null} [displayName] OrganizationReference displayName
         * @property {string|null} [displayNameShort] OrganizationReference displayNameShort
         */

        /**
         * Constructs a new OrganizationReference.
         * @memberof sharelib
         * @classdesc Represents an OrganizationReference.
         * @implements IOrganizationReference
         * @constructor
         * @param {sharelib.IOrganizationReference=} [properties] Properties to set
         */
        function OrganizationReference(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrganizationReference organizationId.
         * @member {string} organizationId
         * @memberof sharelib.OrganizationReference
         * @instance
         */
        OrganizationReference.prototype.organizationId = "";

        /**
         * OrganizationReference code.
         * @member {string} code
         * @memberof sharelib.OrganizationReference
         * @instance
         */
        OrganizationReference.prototype.code = "";

        /**
         * OrganizationReference displayName.
         * @member {string} displayName
         * @memberof sharelib.OrganizationReference
         * @instance
         */
        OrganizationReference.prototype.displayName = "";

        /**
         * OrganizationReference displayNameShort.
         * @member {string} displayNameShort
         * @memberof sharelib.OrganizationReference
         * @instance
         */
        OrganizationReference.prototype.displayNameShort = "";

        /**
         * Verifies an OrganizationReference message.
         * @function verify
         * @memberof sharelib.OrganizationReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrganizationReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                if (!$util.isString(message.organizationId))
                    return "organizationId: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                if (!$util.isString(message.displayNameShort))
                    return "displayNameShort: string expected";
            return null;
        };

        /**
         * Creates an OrganizationReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.OrganizationReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.OrganizationReference} OrganizationReference
         */
        OrganizationReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.OrganizationReference)
                return object;
            let message = new $root.sharelib.OrganizationReference();
            if (object.organizationId != null)
                message.organizationId = String(object.organizationId);
            if (object.code != null)
                message.code = String(object.code);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.displayNameShort != null)
                message.displayNameShort = String(object.displayNameShort);
            return message;
        };

        /**
         * Creates a plain object from an OrganizationReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.OrganizationReference
         * @static
         * @param {sharelib.OrganizationReference} message OrganizationReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrganizationReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.organizationId = "";
                object.code = "";
                object.displayName = "";
                object.displayNameShort = "";
            }
            if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                object.organizationId = message.organizationId;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                object.displayNameShort = message.displayNameShort;
            return object;
        };

        /**
         * Converts this OrganizationReference to JSON.
         * @function toJSON
         * @memberof sharelib.OrganizationReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrganizationReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrganizationReference;
    })();

    sharelib.OrganizationStructureReference = (function() {

        /**
         * Properties of an OrganizationStructureReference.
         * @memberof sharelib
         * @interface IOrganizationStructureReference
         * @property {string|null} [organizationId] OrganizationStructureReference organizationId
         * @property {string|null} [code] OrganizationStructureReference code
         * @property {string|null} [displayName] OrganizationStructureReference displayName
         * @property {string|null} [displayNameShort] OrganizationStructureReference displayNameShort
         * @property {Array.<sharelib.IOrganizationReference>|null} [parentOrganization] OrganizationStructureReference parentOrganization
         */

        /**
         * Constructs a new OrganizationStructureReference.
         * @memberof sharelib
         * @classdesc Represents an OrganizationStructureReference.
         * @implements IOrganizationStructureReference
         * @constructor
         * @param {sharelib.IOrganizationStructureReference=} [properties] Properties to set
         */
        function OrganizationStructureReference(properties) {
            this.parentOrganization = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrganizationStructureReference organizationId.
         * @member {string} organizationId
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         */
        OrganizationStructureReference.prototype.organizationId = "";

        /**
         * OrganizationStructureReference code.
         * @member {string} code
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         */
        OrganizationStructureReference.prototype.code = "";

        /**
         * OrganizationStructureReference displayName.
         * @member {string} displayName
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         */
        OrganizationStructureReference.prototype.displayName = "";

        /**
         * OrganizationStructureReference displayNameShort.
         * @member {string} displayNameShort
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         */
        OrganizationStructureReference.prototype.displayNameShort = "";

        /**
         * OrganizationStructureReference parentOrganization.
         * @member {Array.<sharelib.IOrganizationReference>} parentOrganization
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         */
        OrganizationStructureReference.prototype.parentOrganization = $util.emptyArray;

        /**
         * Verifies an OrganizationStructureReference message.
         * @function verify
         * @memberof sharelib.OrganizationStructureReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrganizationStructureReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                if (!$util.isString(message.organizationId))
                    return "organizationId: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                if (!$util.isString(message.displayNameShort))
                    return "displayNameShort: string expected";
            if (message.parentOrganization != null && message.hasOwnProperty("parentOrganization")) {
                if (!Array.isArray(message.parentOrganization))
                    return "parentOrganization: array expected";
                for (let i = 0; i < message.parentOrganization.length; ++i) {
                    let error = $root.sharelib.OrganizationReference.verify(message.parentOrganization[i]);
                    if (error)
                        return "parentOrganization." + error;
                }
            }
            return null;
        };

        /**
         * Creates an OrganizationStructureReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.OrganizationStructureReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.OrganizationStructureReference} OrganizationStructureReference
         */
        OrganizationStructureReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.OrganizationStructureReference)
                return object;
            let message = new $root.sharelib.OrganizationStructureReference();
            if (object.organizationId != null)
                message.organizationId = String(object.organizationId);
            if (object.code != null)
                message.code = String(object.code);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.displayNameShort != null)
                message.displayNameShort = String(object.displayNameShort);
            if (object.parentOrganization) {
                if (!Array.isArray(object.parentOrganization))
                    throw TypeError(".sharelib.OrganizationStructureReference.parentOrganization: array expected");
                message.parentOrganization = [];
                for (let i = 0; i < object.parentOrganization.length; ++i) {
                    if (typeof object.parentOrganization[i] !== "object")
                        throw TypeError(".sharelib.OrganizationStructureReference.parentOrganization: object expected");
                    message.parentOrganization[i] = $root.sharelib.OrganizationReference.fromObject(object.parentOrganization[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an OrganizationStructureReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.OrganizationStructureReference
         * @static
         * @param {sharelib.OrganizationStructureReference} message OrganizationStructureReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrganizationStructureReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.parentOrganization = [];
            if (options.defaults) {
                object.organizationId = "";
                object.code = "";
                object.displayName = "";
                object.displayNameShort = "";
            }
            if (message.organizationId != null && message.hasOwnProperty("organizationId"))
                object.organizationId = message.organizationId;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.displayNameShort != null && message.hasOwnProperty("displayNameShort"))
                object.displayNameShort = message.displayNameShort;
            if (message.parentOrganization && message.parentOrganization.length) {
                object.parentOrganization = [];
                for (let j = 0; j < message.parentOrganization.length; ++j)
                    object.parentOrganization[j] = $root.sharelib.OrganizationReference.toObject(message.parentOrganization[j], options);
            }
            return object;
        };

        /**
         * Converts this OrganizationStructureReference to JSON.
         * @function toJSON
         * @memberof sharelib.OrganizationStructureReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrganizationStructureReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrganizationStructureReference;
    })();

    sharelib.CompanyReference = (function() {

        /**
         * Properties of a CompanyReference.
         * @memberof sharelib
         * @interface ICompanyReference
         * @property {string|null} [companyId] CompanyReference companyId
         * @property {string|null} [displayName] CompanyReference displayName
         */

        /**
         * Constructs a new CompanyReference.
         * @memberof sharelib
         * @classdesc Represents a CompanyReference.
         * @implements ICompanyReference
         * @constructor
         * @param {sharelib.ICompanyReference=} [properties] Properties to set
         */
        function CompanyReference(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyReference companyId.
         * @member {string} companyId
         * @memberof sharelib.CompanyReference
         * @instance
         */
        CompanyReference.prototype.companyId = "";

        /**
         * CompanyReference displayName.
         * @member {string} displayName
         * @memberof sharelib.CompanyReference
         * @instance
         */
        CompanyReference.prototype.displayName = "";

        /**
         * Verifies a CompanyReference message.
         * @function verify
         * @memberof sharelib.CompanyReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CompanyReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.companyId != null && message.hasOwnProperty("companyId"))
                if (!$util.isString(message.companyId))
                    return "companyId: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            return null;
        };

        /**
         * Creates a CompanyReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.CompanyReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.CompanyReference} CompanyReference
         */
        CompanyReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.CompanyReference)
                return object;
            let message = new $root.sharelib.CompanyReference();
            if (object.companyId != null)
                message.companyId = String(object.companyId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            return message;
        };

        /**
         * Creates a plain object from a CompanyReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.CompanyReference
         * @static
         * @param {sharelib.CompanyReference} message CompanyReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.companyId = "";
                object.displayName = "";
            }
            if (message.companyId != null && message.hasOwnProperty("companyId"))
                object.companyId = message.companyId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            return object;
        };

        /**
         * Converts this CompanyReference to JSON.
         * @function toJSON
         * @memberof sharelib.CompanyReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyReference;
    })();

    sharelib.ComponentUnitReference = (function() {

        /**
         * Properties of a ComponentUnitReference.
         * @memberof sharelib
         * @interface IComponentUnitReference
         * @property {string|null} [componentUnitId] ComponentUnitReference componentUnitId
         * @property {string|null} [componentUnitCode] ComponentUnitReference componentUnitCode
         * @property {Object.<string,string>|null} [displayNameLang] ComponentUnitReference displayNameLang
         */

        /**
         * Constructs a new ComponentUnitReference.
         * @memberof sharelib
         * @classdesc Represents a ComponentUnitReference.
         * @implements IComponentUnitReference
         * @constructor
         * @param {sharelib.IComponentUnitReference=} [properties] Properties to set
         */
        function ComponentUnitReference(properties) {
            this.displayNameLang = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComponentUnitReference componentUnitId.
         * @member {string} componentUnitId
         * @memberof sharelib.ComponentUnitReference
         * @instance
         */
        ComponentUnitReference.prototype.componentUnitId = "";

        /**
         * ComponentUnitReference componentUnitCode.
         * @member {string} componentUnitCode
         * @memberof sharelib.ComponentUnitReference
         * @instance
         */
        ComponentUnitReference.prototype.componentUnitCode = "";

        /**
         * ComponentUnitReference displayNameLang.
         * @member {Object.<string,string>} displayNameLang
         * @memberof sharelib.ComponentUnitReference
         * @instance
         */
        ComponentUnitReference.prototype.displayNameLang = $util.emptyObject;

        /**
         * Verifies a ComponentUnitReference message.
         * @function verify
         * @memberof sharelib.ComponentUnitReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComponentUnitReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                if (!$util.isString(message.componentUnitId))
                    return "componentUnitId: string expected";
            if (message.componentUnitCode != null && message.hasOwnProperty("componentUnitCode"))
                if (!$util.isString(message.componentUnitCode))
                    return "componentUnitCode: string expected";
            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                if (!$util.isObject(message.displayNameLang))
                    return "displayNameLang: object expected";
                let key = Object.keys(message.displayNameLang);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.displayNameLang[key[i]]))
                        return "displayNameLang: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a ComponentUnitReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.ComponentUnitReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.ComponentUnitReference} ComponentUnitReference
         */
        ComponentUnitReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.ComponentUnitReference)
                return object;
            let message = new $root.sharelib.ComponentUnitReference();
            if (object.componentUnitId != null)
                message.componentUnitId = String(object.componentUnitId);
            if (object.componentUnitCode != null)
                message.componentUnitCode = String(object.componentUnitCode);
            if (object.displayNameLang) {
                if (typeof object.displayNameLang !== "object")
                    throw TypeError(".sharelib.ComponentUnitReference.displayNameLang: object expected");
                message.displayNameLang = {};
                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ComponentUnitReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.ComponentUnitReference
         * @static
         * @param {sharelib.ComponentUnitReference} message ComponentUnitReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComponentUnitReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.displayNameLang = {};
            if (options.defaults) {
                object.componentUnitId = "";
                object.componentUnitCode = "";
            }
            if (message.componentUnitId != null && message.hasOwnProperty("componentUnitId"))
                object.componentUnitId = message.componentUnitId;
            if (message.componentUnitCode != null && message.hasOwnProperty("componentUnitCode"))
                object.componentUnitCode = message.componentUnitCode;
            let keys2;
            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                object.displayNameLang = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this ComponentUnitReference to JSON.
         * @function toJSON
         * @memberof sharelib.ComponentUnitReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComponentUnitReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ComponentUnitReference;
    })();

    sharelib.StaffReference = (function() {

        /**
         * Properties of a StaffReference.
         * @memberof sharelib
         * @interface IStaffReference
         * @property {string|null} [staffId] StaffReference staffId
         * @property {string|null} [staffCode] StaffReference staffCode
         * @property {Object.<string,string>|null} [displayNameLang] StaffReference displayNameLang
         */

        /**
         * Constructs a new StaffReference.
         * @memberof sharelib
         * @classdesc Represents a StaffReference.
         * @implements IStaffReference
         * @constructor
         * @param {sharelib.IStaffReference=} [properties] Properties to set
         */
        function StaffReference(properties) {
            this.displayNameLang = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StaffReference staffId.
         * @member {string} staffId
         * @memberof sharelib.StaffReference
         * @instance
         */
        StaffReference.prototype.staffId = "";

        /**
         * StaffReference staffCode.
         * @member {string} staffCode
         * @memberof sharelib.StaffReference
         * @instance
         */
        StaffReference.prototype.staffCode = "";

        /**
         * StaffReference displayNameLang.
         * @member {Object.<string,string>} displayNameLang
         * @memberof sharelib.StaffReference
         * @instance
         */
        StaffReference.prototype.displayNameLang = $util.emptyObject;

        /**
         * Verifies a StaffReference message.
         * @function verify
         * @memberof sharelib.StaffReference
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StaffReference.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.staffId != null && message.hasOwnProperty("staffId"))
                if (!$util.isString(message.staffId))
                    return "staffId: string expected";
            if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                if (!$util.isString(message.staffCode))
                    return "staffCode: string expected";
            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                if (!$util.isObject(message.displayNameLang))
                    return "displayNameLang: object expected";
                let key = Object.keys(message.displayNameLang);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.displayNameLang[key[i]]))
                        return "displayNameLang: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a StaffReference message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.StaffReference
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.StaffReference} StaffReference
         */
        StaffReference.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.StaffReference)
                return object;
            let message = new $root.sharelib.StaffReference();
            if (object.staffId != null)
                message.staffId = String(object.staffId);
            if (object.staffCode != null)
                message.staffCode = String(object.staffCode);
            if (object.displayNameLang) {
                if (typeof object.displayNameLang !== "object")
                    throw TypeError(".sharelib.StaffReference.displayNameLang: object expected");
                message.displayNameLang = {};
                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a StaffReference message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.StaffReference
         * @static
         * @param {sharelib.StaffReference} message StaffReference
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StaffReference.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.displayNameLang = {};
            if (options.defaults) {
                object.staffId = "";
                object.staffCode = "";
            }
            if (message.staffId != null && message.hasOwnProperty("staffId"))
                object.staffId = message.staffId;
            if (message.staffCode != null && message.hasOwnProperty("staffCode"))
                object.staffCode = message.staffCode;
            let keys2;
            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                object.displayNameLang = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this StaffReference to JSON.
         * @function toJSON
         * @memberof sharelib.StaffReference
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StaffReference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StaffReference;
    })();

    sharelib.EmbeddedUser = (function() {

        /**
         * Properties of an EmbeddedUser.
         * @memberof sharelib
         * @interface IEmbeddedUser
         * @property {string|null} [displayName] EmbeddedUser displayName
         * @property {string|null} [email] EmbeddedUser email
         */

        /**
         * Constructs a new EmbeddedUser.
         * @memberof sharelib
         * @classdesc Represents an EmbeddedUser.
         * @implements IEmbeddedUser
         * @constructor
         * @param {sharelib.IEmbeddedUser=} [properties] Properties to set
         */
        function EmbeddedUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmbeddedUser displayName.
         * @member {string} displayName
         * @memberof sharelib.EmbeddedUser
         * @instance
         */
        EmbeddedUser.prototype.displayName = "";

        /**
         * EmbeddedUser email.
         * @member {string} email
         * @memberof sharelib.EmbeddedUser
         * @instance
         */
        EmbeddedUser.prototype.email = "";

        /**
         * Verifies an EmbeddedUser message.
         * @function verify
         * @memberof sharelib.EmbeddedUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmbeddedUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            return null;
        };

        /**
         * Creates an EmbeddedUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.EmbeddedUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.EmbeddedUser} EmbeddedUser
         */
        EmbeddedUser.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.EmbeddedUser)
                return object;
            let message = new $root.sharelib.EmbeddedUser();
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        /**
         * Creates a plain object from an EmbeddedUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.EmbeddedUser
         * @static
         * @param {sharelib.EmbeddedUser} message EmbeddedUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmbeddedUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.displayName = "";
                object.email = "";
            }
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            return object;
        };

        /**
         * Converts this EmbeddedUser to JSON.
         * @function toJSON
         * @memberof sharelib.EmbeddedUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmbeddedUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmbeddedUser;
    })();

    sharelib.EmbeddedSharedProperties = (function() {

        /**
         * Properties of an EmbeddedSharedProperties.
         * @memberof sharelib
         * @interface IEmbeddedSharedProperties
         * @property {string|null} [sharedBy] EmbeddedSharedProperties sharedBy
         * @property {Long|null} [sharedAt] EmbeddedSharedProperties sharedAt
         */

        /**
         * Constructs a new EmbeddedSharedProperties.
         * @memberof sharelib
         * @classdesc Represents an EmbeddedSharedProperties.
         * @implements IEmbeddedSharedProperties
         * @constructor
         * @param {sharelib.IEmbeddedSharedProperties=} [properties] Properties to set
         */
        function EmbeddedSharedProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmbeddedSharedProperties sharedBy.
         * @member {string} sharedBy
         * @memberof sharelib.EmbeddedSharedProperties
         * @instance
         */
        EmbeddedSharedProperties.prototype.sharedBy = "";

        /**
         * EmbeddedSharedProperties sharedAt.
         * @member {Long} sharedAt
         * @memberof sharelib.EmbeddedSharedProperties
         * @instance
         */
        EmbeddedSharedProperties.prototype.sharedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Verifies an EmbeddedSharedProperties message.
         * @function verify
         * @memberof sharelib.EmbeddedSharedProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmbeddedSharedProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sharedBy != null && message.hasOwnProperty("sharedBy"))
                if (!$util.isString(message.sharedBy))
                    return "sharedBy: string expected";
            if (message.sharedAt != null && message.hasOwnProperty("sharedAt"))
                if (!$util.isInteger(message.sharedAt) && !(message.sharedAt && $util.isInteger(message.sharedAt.low) && $util.isInteger(message.sharedAt.high)))
                    return "sharedAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an EmbeddedSharedProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.EmbeddedSharedProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.EmbeddedSharedProperties} EmbeddedSharedProperties
         */
        EmbeddedSharedProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.EmbeddedSharedProperties)
                return object;
            let message = new $root.sharelib.EmbeddedSharedProperties();
            if (object.sharedBy != null)
                message.sharedBy = String(object.sharedBy);
            if (object.sharedAt != null)
                if ($util.Long)
                    (message.sharedAt = $util.Long.fromValue(object.sharedAt)).unsigned = false;
                else if (typeof object.sharedAt === "string")
                    message.sharedAt = parseInt(object.sharedAt, 10);
                else if (typeof object.sharedAt === "number")
                    message.sharedAt = object.sharedAt;
                else if (typeof object.sharedAt === "object")
                    message.sharedAt = new $util.LongBits(object.sharedAt.low >>> 0, object.sharedAt.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an EmbeddedSharedProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.EmbeddedSharedProperties
         * @static
         * @param {sharelib.EmbeddedSharedProperties} message EmbeddedSharedProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmbeddedSharedProperties.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sharedBy = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.sharedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sharedAt = options.longs === String ? "0" : 0;
            }
            if (message.sharedBy != null && message.hasOwnProperty("sharedBy"))
                object.sharedBy = message.sharedBy;
            if (message.sharedAt != null && message.hasOwnProperty("sharedAt"))
                if (typeof message.sharedAt === "number")
                    object.sharedAt = options.longs === String ? String(message.sharedAt) : message.sharedAt;
                else
                    object.sharedAt = options.longs === String ? $util.Long.prototype.toString.call(message.sharedAt) : options.longs === Number ? new $util.LongBits(message.sharedAt.low >>> 0, message.sharedAt.high >>> 0).toNumber() : message.sharedAt;
            return object;
        };

        /**
         * Converts this EmbeddedSharedProperties to JSON.
         * @function toJSON
         * @memberof sharelib.EmbeddedSharedProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmbeddedSharedProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmbeddedSharedProperties;
    })();

    sharelib.EmbeddedUpdatedProperties = (function() {

        /**
         * Properties of an EmbeddedUpdatedProperties.
         * @memberof sharelib
         * @interface IEmbeddedUpdatedProperties
         * @property {Long|null} [updatedAt] EmbeddedUpdatedProperties updatedAt
         * @property {sharelib.IEmbeddedUser|null} [updatedBy] EmbeddedUpdatedProperties updatedBy
         */

        /**
         * Constructs a new EmbeddedUpdatedProperties.
         * @memberof sharelib
         * @classdesc Represents an EmbeddedUpdatedProperties.
         * @implements IEmbeddedUpdatedProperties
         * @constructor
         * @param {sharelib.IEmbeddedUpdatedProperties=} [properties] Properties to set
         */
        function EmbeddedUpdatedProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmbeddedUpdatedProperties updatedAt.
         * @member {Long} updatedAt
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @instance
         */
        EmbeddedUpdatedProperties.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * EmbeddedUpdatedProperties updatedBy.
         * @member {sharelib.IEmbeddedUser|null|undefined} updatedBy
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @instance
         */
        EmbeddedUpdatedProperties.prototype.updatedBy = null;

        /**
         * Verifies an EmbeddedUpdatedProperties message.
         * @function verify
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmbeddedUpdatedProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                    return "updatedAt: integer|Long expected";
            if (message.updatedBy != null && message.hasOwnProperty("updatedBy")) {
                let error = $root.sharelib.EmbeddedUser.verify(message.updatedBy);
                if (error)
                    return "updatedBy." + error;
            }
            return null;
        };

        /**
         * Creates an EmbeddedUpdatedProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.EmbeddedUpdatedProperties} EmbeddedUpdatedProperties
         */
        EmbeddedUpdatedProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.EmbeddedUpdatedProperties)
                return object;
            let message = new $root.sharelib.EmbeddedUpdatedProperties();
            if (object.updatedAt != null)
                if ($util.Long)
                    (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                else if (typeof object.updatedAt === "string")
                    message.updatedAt = parseInt(object.updatedAt, 10);
                else if (typeof object.updatedAt === "number")
                    message.updatedAt = object.updatedAt;
                else if (typeof object.updatedAt === "object")
                    message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
            if (object.updatedBy != null) {
                if (typeof object.updatedBy !== "object")
                    throw TypeError(".sharelib.EmbeddedUpdatedProperties.updatedBy: object expected");
                message.updatedBy = $root.sharelib.EmbeddedUser.fromObject(object.updatedBy);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmbeddedUpdatedProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @static
         * @param {sharelib.EmbeddedUpdatedProperties} message EmbeddedUpdatedProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmbeddedUpdatedProperties.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updatedAt = options.longs === String ? "0" : 0;
                object.updatedBy = null;
            }
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                if (typeof message.updatedAt === "number")
                    object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                else
                    object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
            if (message.updatedBy != null && message.hasOwnProperty("updatedBy"))
                object.updatedBy = $root.sharelib.EmbeddedUser.toObject(message.updatedBy, options);
            return object;
        };

        /**
         * Converts this EmbeddedUpdatedProperties to JSON.
         * @function toJSON
         * @memberof sharelib.EmbeddedUpdatedProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmbeddedUpdatedProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmbeddedUpdatedProperties;
    })();

    sharelib.EmbeddedCompany = (function() {

        /**
         * Properties of an EmbeddedCompany.
         * @memberof sharelib
         * @interface IEmbeddedCompany
         * @property {string|null} [companyId] EmbeddedCompany companyId
         * @property {Object.<string,string>|null} [displayNameLang] EmbeddedCompany displayNameLang
         */

        /**
         * Constructs a new EmbeddedCompany.
         * @memberof sharelib
         * @classdesc Represents an EmbeddedCompany.
         * @implements IEmbeddedCompany
         * @constructor
         * @param {sharelib.IEmbeddedCompany=} [properties] Properties to set
         */
        function EmbeddedCompany(properties) {
            this.displayNameLang = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmbeddedCompany companyId.
         * @member {string} companyId
         * @memberof sharelib.EmbeddedCompany
         * @instance
         */
        EmbeddedCompany.prototype.companyId = "";

        /**
         * EmbeddedCompany displayNameLang.
         * @member {Object.<string,string>} displayNameLang
         * @memberof sharelib.EmbeddedCompany
         * @instance
         */
        EmbeddedCompany.prototype.displayNameLang = $util.emptyObject;

        /**
         * Verifies an EmbeddedCompany message.
         * @function verify
         * @memberof sharelib.EmbeddedCompany
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmbeddedCompany.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.companyId != null && message.hasOwnProperty("companyId"))
                if (!$util.isString(message.companyId))
                    return "companyId: string expected";
            if (message.displayNameLang != null && message.hasOwnProperty("displayNameLang")) {
                if (!$util.isObject(message.displayNameLang))
                    return "displayNameLang: object expected";
                let key = Object.keys(message.displayNameLang);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.displayNameLang[key[i]]))
                        return "displayNameLang: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an EmbeddedCompany message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.EmbeddedCompany
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.EmbeddedCompany} EmbeddedCompany
         */
        EmbeddedCompany.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.EmbeddedCompany)
                return object;
            let message = new $root.sharelib.EmbeddedCompany();
            if (object.companyId != null)
                message.companyId = String(object.companyId);
            if (object.displayNameLang) {
                if (typeof object.displayNameLang !== "object")
                    throw TypeError(".sharelib.EmbeddedCompany.displayNameLang: object expected");
                message.displayNameLang = {};
                for (let keys = Object.keys(object.displayNameLang), i = 0; i < keys.length; ++i)
                    message.displayNameLang[keys[i]] = String(object.displayNameLang[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an EmbeddedCompany message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.EmbeddedCompany
         * @static
         * @param {sharelib.EmbeddedCompany} message EmbeddedCompany
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmbeddedCompany.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.displayNameLang = {};
            if (options.defaults)
                object.companyId = "";
            if (message.companyId != null && message.hasOwnProperty("companyId"))
                object.companyId = message.companyId;
            let keys2;
            if (message.displayNameLang && (keys2 = Object.keys(message.displayNameLang)).length) {
                object.displayNameLang = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.displayNameLang[keys2[j]] = message.displayNameLang[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this EmbeddedCompany to JSON.
         * @function toJSON
         * @memberof sharelib.EmbeddedCompany
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmbeddedCompany.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmbeddedCompany;
    })();

    sharelib.Attachment = (function() {

        /**
         * Properties of an Attachment.
         * @memberof sharelib
         * @interface IAttachment
         * @property {string|null} [assetId] Attachment assetId
         * @property {sharelib.INameOption|null} [category] Attachment category
         * @property {string|null} [filename] Attachment filename
         * @property {string|null} [remarks] Attachment remarks
         * @property {string|null} [mimeType] Attachment mimeType
         * @property {sharelib.INameOption|null} [linkType] Attachment linkType
         * @property {Long|null} [attachedAt] Attachment attachedAt
         */

        /**
         * Constructs a new Attachment.
         * @memberof sharelib
         * @classdesc Represents an Attachment.
         * @implements IAttachment
         * @constructor
         * @param {sharelib.IAttachment=} [properties] Properties to set
         */
        function Attachment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Attachment assetId.
         * @member {string} assetId
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.assetId = "";

        /**
         * Attachment category.
         * @member {sharelib.INameOption|null|undefined} category
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.category = null;

        /**
         * Attachment filename.
         * @member {string} filename
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.filename = "";

        /**
         * Attachment remarks.
         * @member {string} remarks
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.remarks = "";

        /**
         * Attachment mimeType.
         * @member {string} mimeType
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.mimeType = "";

        /**
         * Attachment linkType.
         * @member {sharelib.INameOption|null|undefined} linkType
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.linkType = null;

        /**
         * Attachment attachedAt.
         * @member {Long} attachedAt
         * @memberof sharelib.Attachment
         * @instance
         */
        Attachment.prototype.attachedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Verifies an Attachment message.
         * @function verify
         * @memberof sharelib.Attachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Attachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.assetId != null && message.hasOwnProperty("assetId"))
                if (!$util.isString(message.assetId))
                    return "assetId: string expected";
            if (message.category != null && message.hasOwnProperty("category")) {
                let error = $root.sharelib.NameOption.verify(message.category);
                if (error)
                    return "category." + error;
            }
            if (message.filename != null && message.hasOwnProperty("filename"))
                if (!$util.isString(message.filename))
                    return "filename: string expected";
            if (message.remarks != null && message.hasOwnProperty("remarks"))
                if (!$util.isString(message.remarks))
                    return "remarks: string expected";
            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                if (!$util.isString(message.mimeType))
                    return "mimeType: string expected";
            if (message.linkType != null && message.hasOwnProperty("linkType")) {
                let error = $root.sharelib.NameOption.verify(message.linkType);
                if (error)
                    return "linkType." + error;
            }
            if (message.attachedAt != null && message.hasOwnProperty("attachedAt"))
                if (!$util.isInteger(message.attachedAt) && !(message.attachedAt && $util.isInteger(message.attachedAt.low) && $util.isInteger(message.attachedAt.high)))
                    return "attachedAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an Attachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.Attachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.Attachment} Attachment
         */
        Attachment.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.Attachment)
                return object;
            let message = new $root.sharelib.Attachment();
            if (object.assetId != null)
                message.assetId = String(object.assetId);
            if (object.category != null) {
                if (typeof object.category !== "object")
                    throw TypeError(".sharelib.Attachment.category: object expected");
                message.category = $root.sharelib.NameOption.fromObject(object.category);
            }
            if (object.filename != null)
                message.filename = String(object.filename);
            if (object.remarks != null)
                message.remarks = String(object.remarks);
            if (object.mimeType != null)
                message.mimeType = String(object.mimeType);
            if (object.linkType != null) {
                if (typeof object.linkType !== "object")
                    throw TypeError(".sharelib.Attachment.linkType: object expected");
                message.linkType = $root.sharelib.NameOption.fromObject(object.linkType);
            }
            if (object.attachedAt != null)
                if ($util.Long)
                    (message.attachedAt = $util.Long.fromValue(object.attachedAt)).unsigned = false;
                else if (typeof object.attachedAt === "string")
                    message.attachedAt = parseInt(object.attachedAt, 10);
                else if (typeof object.attachedAt === "number")
                    message.attachedAt = object.attachedAt;
                else if (typeof object.attachedAt === "object")
                    message.attachedAt = new $util.LongBits(object.attachedAt.low >>> 0, object.attachedAt.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an Attachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.Attachment
         * @static
         * @param {sharelib.Attachment} message Attachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Attachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.assetId = "";
                object.category = null;
                object.filename = "";
                object.remarks = "";
                object.mimeType = "";
                object.linkType = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.attachedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.attachedAt = options.longs === String ? "0" : 0;
            }
            if (message.assetId != null && message.hasOwnProperty("assetId"))
                object.assetId = message.assetId;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = $root.sharelib.NameOption.toObject(message.category, options);
            if (message.filename != null && message.hasOwnProperty("filename"))
                object.filename = message.filename;
            if (message.remarks != null && message.hasOwnProperty("remarks"))
                object.remarks = message.remarks;
            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                object.mimeType = message.mimeType;
            if (message.linkType != null && message.hasOwnProperty("linkType"))
                object.linkType = $root.sharelib.NameOption.toObject(message.linkType, options);
            if (message.attachedAt != null && message.hasOwnProperty("attachedAt"))
                if (typeof message.attachedAt === "number")
                    object.attachedAt = options.longs === String ? String(message.attachedAt) : message.attachedAt;
                else
                    object.attachedAt = options.longs === String ? $util.Long.prototype.toString.call(message.attachedAt) : options.longs === Number ? new $util.LongBits(message.attachedAt.low >>> 0, message.attachedAt.high >>> 0).toNumber() : message.attachedAt;
            return object;
        };

        /**
         * Converts this Attachment to JSON.
         * @function toJSON
         * @memberof sharelib.Attachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Attachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Attachment;
    })();

    sharelib.Datetime = (function() {

        /**
         * Properties of a Datetime.
         * @memberof sharelib
         * @interface IDatetime
         * @property {string|null} [timezoneName] Datetime timezoneName
         * @property {Long|null} [timestamp] Datetime timestamp
         * @property {sharelib.Datetime.Accuracy|null} [accuracy] Datetime accuracy
         */

        /**
         * Constructs a new Datetime.
         * @memberof sharelib
         * @classdesc Represents a Datetime.
         * @implements IDatetime
         * @constructor
         * @param {sharelib.IDatetime=} [properties] Properties to set
         */
        function Datetime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Datetime timezoneName.
         * @member {string} timezoneName
         * @memberof sharelib.Datetime
         * @instance
         */
        Datetime.prototype.timezoneName = "";

        /**
         * Datetime timestamp.
         * @member {Long} timestamp
         * @memberof sharelib.Datetime
         * @instance
         */
        Datetime.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Datetime accuracy.
         * @member {sharelib.Datetime.Accuracy} accuracy
         * @memberof sharelib.Datetime
         * @instance
         */
        Datetime.prototype.accuracy = 0;

        /**
         * Verifies a Datetime message.
         * @function verify
         * @memberof sharelib.Datetime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Datetime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                if (!$util.isString(message.timezoneName))
                    return "timezoneName: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                switch (message.accuracy) {
                default:
                    return "accuracy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a Datetime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.Datetime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.Datetime} Datetime
         */
        Datetime.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.Datetime)
                return object;
            let message = new $root.sharelib.Datetime();
            if (object.timezoneName != null)
                message.timezoneName = String(object.timezoneName);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            switch (object.accuracy) {
            case "MICROSECOND":
            case 0:
                message.accuracy = 0;
                break;
            case "MILLISECOND":
            case 1:
                message.accuracy = 1;
                break;
            case "SECOND":
            case 2:
                message.accuracy = 2;
                break;
            case "MINUTE":
            case 3:
                message.accuracy = 3;
                break;
            case "HOUR":
            case 4:
                message.accuracy = 4;
                break;
            case "DAY":
            case 5:
                message.accuracy = 5;
                break;
            case "MONTH":
            case 6:
                message.accuracy = 6;
                break;
            case "YEAR":
            case 7:
                message.accuracy = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Datetime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.Datetime
         * @static
         * @param {sharelib.Datetime} message Datetime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Datetime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timezoneName = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.accuracy = options.enums === String ? "MICROSECOND" : 0;
            }
            if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                object.timezoneName = message.timezoneName;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.accuracy != null && message.hasOwnProperty("accuracy"))
                object.accuracy = options.enums === String ? $root.sharelib.Datetime.Accuracy[message.accuracy] : message.accuracy;
            return object;
        };

        /**
         * Converts this Datetime to JSON.
         * @function toJSON
         * @memberof sharelib.Datetime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Datetime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Accuracy enum.
         * @name sharelib.Datetime.Accuracy
         * @enum {number}
         * @property {number} MICROSECOND=0 MICROSECOND value
         * @property {number} MILLISECOND=1 MILLISECOND value
         * @property {number} SECOND=2 SECOND value
         * @property {number} MINUTE=3 MINUTE value
         * @property {number} HOUR=4 HOUR value
         * @property {number} DAY=5 DAY value
         * @property {number} MONTH=6 MONTH value
         * @property {number} YEAR=7 YEAR value
         */
        Datetime.Accuracy = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MICROSECOND"] = 0;
            values[valuesById[1] = "MILLISECOND"] = 1;
            values[valuesById[2] = "SECOND"] = 2;
            values[valuesById[3] = "MINUTE"] = 3;
            values[valuesById[4] = "HOUR"] = 4;
            values[valuesById[5] = "DAY"] = 5;
            values[valuesById[6] = "MONTH"] = 6;
            values[valuesById[7] = "YEAR"] = 7;
            return values;
        })();

        return Datetime;
    })();

    sharelib.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof sharelib
         * @interface IDate
         * @property {number|null} [year] Date year
         * @property {number|null} [month] Date month
         * @property {number|null} [day] Date day
         */

        /**
         * Constructs a new Date.
         * @memberof sharelib
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {sharelib.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date year.
         * @member {number} year
         * @memberof sharelib.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof sharelib.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof sharelib.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof sharelib.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.year != null && message.hasOwnProperty("year"))
                if (!$util.isInteger(message.year))
                    return "year: integer expected";
            if (message.month != null && message.hasOwnProperty("month"))
                if (!$util.isInteger(message.month))
                    return "month: integer expected";
            if (message.day != null && message.hasOwnProperty("day"))
                if (!$util.isInteger(message.day))
                    return "day: integer expected";
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.Date)
                return object;
            let message = new $root.sharelib.Date();
            if (object.year != null)
                message.year = object.year | 0;
            if (object.month != null)
                message.month = object.month | 0;
            if (object.day != null)
                message.day = object.day | 0;
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.Date
         * @static
         * @param {sharelib.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.year = 0;
                object.month = 0;
                object.day = 0;
            }
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = message.year;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = message.month;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = message.day;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof sharelib.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Date;
    })();

    /**
     * ErrorLevel enum.
     * @name sharelib.ErrorLevel
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ERROR=1 ERROR value
     * @property {number} WARNING=2 WARNING value
     */
    sharelib.ErrorLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "ERROR"] = 1;
        values[valuesById[2] = "WARNING"] = 2;
        return values;
    })();

    /**
     * Error enum.
     * @name sharelib.Error
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} BAD_REQUEST=1 BAD_REQUEST value
     * @property {number} INVALID_ARGUMENT=2 INVALID_ARGUMENT value
     * @property {number} INTERNAL_ERROR=3 INTERNAL_ERROR value
     * @property {number} UNAUTHORIZED=4 UNAUTHORIZED value
     * @property {number} FORBIDDEN=5 FORBIDDEN value
     * @property {number} NOT_FOUND=6 NOT_FOUND value
     * @property {number} RESOURCE_ALREADY_UPDATED_BY_OTHER=7 RESOURCE_ALREADY_UPDATED_BY_OTHER value
     */
    sharelib.Error = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "BAD_REQUEST"] = 1;
        values[valuesById[2] = "INVALID_ARGUMENT"] = 2;
        values[valuesById[3] = "INTERNAL_ERROR"] = 3;
        values[valuesById[4] = "UNAUTHORIZED"] = 4;
        values[valuesById[5] = "FORBIDDEN"] = 5;
        values[valuesById[6] = "NOT_FOUND"] = 6;
        values[valuesById[7] = "RESOURCE_ALREADY_UPDATED_BY_OTHER"] = 7;
        return values;
    })();

    sharelib.FileFormat = (function() {

        /**
         * Properties of a FileFormat.
         * @memberof sharelib
         * @interface IFileFormat
         * @property {sharelib.INameOption|null} [format] FileFormat format
         * @property {boolean|null} [header] FileFormat header
         * @property {Array.<sharelib.IHeaderColumn>|null} [headerColumns] FileFormat headerColumns
         * @property {string|null} [commaName] FileFormat commaName
         * @property {string|null} [encodingName] FileFormat encodingName
         * @property {boolean|null} [quoteMinimally] FileFormat quoteMinimally
         * @property {string|null} [typeName] FileFormat typeName
         */

        /**
         * Constructs a new FileFormat.
         * @memberof sharelib
         * @classdesc Represents a FileFormat.
         * @implements IFileFormat
         * @constructor
         * @param {sharelib.IFileFormat=} [properties] Properties to set
         */
        function FileFormat(properties) {
            this.headerColumns = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileFormat format.
         * @member {sharelib.INameOption|null|undefined} format
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.format = null;

        /**
         * FileFormat header.
         * @member {boolean} header
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.header = false;

        /**
         * FileFormat headerColumns.
         * @member {Array.<sharelib.IHeaderColumn>} headerColumns
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.headerColumns = $util.emptyArray;

        /**
         * FileFormat commaName.
         * @member {string} commaName
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.commaName = "";

        /**
         * FileFormat encodingName.
         * @member {string} encodingName
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.encodingName = "";

        /**
         * FileFormat quoteMinimally.
         * @member {boolean} quoteMinimally
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.quoteMinimally = false;

        /**
         * FileFormat typeName.
         * @member {string} typeName
         * @memberof sharelib.FileFormat
         * @instance
         */
        FileFormat.prototype.typeName = "";

        /**
         * Verifies a FileFormat message.
         * @function verify
         * @memberof sharelib.FileFormat
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileFormat.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.format != null && message.hasOwnProperty("format")) {
                let error = $root.sharelib.NameOption.verify(message.format);
                if (error)
                    return "format." + error;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                if (typeof message.header !== "boolean")
                    return "header: boolean expected";
            if (message.headerColumns != null && message.hasOwnProperty("headerColumns")) {
                if (!Array.isArray(message.headerColumns))
                    return "headerColumns: array expected";
                for (let i = 0; i < message.headerColumns.length; ++i) {
                    let error = $root.sharelib.HeaderColumn.verify(message.headerColumns[i]);
                    if (error)
                        return "headerColumns." + error;
                }
            }
            if (message.commaName != null && message.hasOwnProperty("commaName"))
                if (!$util.isString(message.commaName))
                    return "commaName: string expected";
            if (message.encodingName != null && message.hasOwnProperty("encodingName"))
                if (!$util.isString(message.encodingName))
                    return "encodingName: string expected";
            if (message.quoteMinimally != null && message.hasOwnProperty("quoteMinimally"))
                if (typeof message.quoteMinimally !== "boolean")
                    return "quoteMinimally: boolean expected";
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                if (!$util.isString(message.typeName))
                    return "typeName: string expected";
            return null;
        };

        /**
         * Creates a FileFormat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.FileFormat
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.FileFormat} FileFormat
         */
        FileFormat.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.FileFormat)
                return object;
            let message = new $root.sharelib.FileFormat();
            if (object.format != null) {
                if (typeof object.format !== "object")
                    throw TypeError(".sharelib.FileFormat.format: object expected");
                message.format = $root.sharelib.NameOption.fromObject(object.format);
            }
            if (object.header != null)
                message.header = Boolean(object.header);
            if (object.headerColumns) {
                if (!Array.isArray(object.headerColumns))
                    throw TypeError(".sharelib.FileFormat.headerColumns: array expected");
                message.headerColumns = [];
                for (let i = 0; i < object.headerColumns.length; ++i) {
                    if (typeof object.headerColumns[i] !== "object")
                        throw TypeError(".sharelib.FileFormat.headerColumns: object expected");
                    message.headerColumns[i] = $root.sharelib.HeaderColumn.fromObject(object.headerColumns[i]);
                }
            }
            if (object.commaName != null)
                message.commaName = String(object.commaName);
            if (object.encodingName != null)
                message.encodingName = String(object.encodingName);
            if (object.quoteMinimally != null)
                message.quoteMinimally = Boolean(object.quoteMinimally);
            if (object.typeName != null)
                message.typeName = String(object.typeName);
            return message;
        };

        /**
         * Creates a plain object from a FileFormat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.FileFormat
         * @static
         * @param {sharelib.FileFormat} message FileFormat
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileFormat.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.headerColumns = [];
            if (options.defaults) {
                object.format = null;
                object.header = false;
                object.commaName = "";
                object.encodingName = "";
                object.typeName = "";
                object.quoteMinimally = false;
            }
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = $root.sharelib.NameOption.toObject(message.format, options);
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = message.header;
            if (message.commaName != null && message.hasOwnProperty("commaName"))
                object.commaName = message.commaName;
            if (message.encodingName != null && message.hasOwnProperty("encodingName"))
                object.encodingName = message.encodingName;
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                object.typeName = message.typeName;
            if (message.headerColumns && message.headerColumns.length) {
                object.headerColumns = [];
                for (let j = 0; j < message.headerColumns.length; ++j)
                    object.headerColumns[j] = $root.sharelib.HeaderColumn.toObject(message.headerColumns[j], options);
            }
            if (message.quoteMinimally != null && message.hasOwnProperty("quoteMinimally"))
                object.quoteMinimally = message.quoteMinimally;
            return object;
        };

        /**
         * Converts this FileFormat to JSON.
         * @function toJSON
         * @memberof sharelib.FileFormat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileFormat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileFormat;
    })();

    sharelib.HeaderColumn = (function() {

        /**
         * Properties of a HeaderColumn.
         * @memberof sharelib
         * @interface IHeaderColumn
         * @property {string|null} [messageName] HeaderColumn messageName
         * @property {string|null} [displayName] HeaderColumn displayName
         */

        /**
         * Constructs a new HeaderColumn.
         * @memberof sharelib
         * @classdesc Represents a HeaderColumn.
         * @implements IHeaderColumn
         * @constructor
         * @param {sharelib.IHeaderColumn=} [properties] Properties to set
         */
        function HeaderColumn(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeaderColumn messageName.
         * @member {string} messageName
         * @memberof sharelib.HeaderColumn
         * @instance
         */
        HeaderColumn.prototype.messageName = "";

        /**
         * HeaderColumn displayName.
         * @member {string} displayName
         * @memberof sharelib.HeaderColumn
         * @instance
         */
        HeaderColumn.prototype.displayName = "";

        /**
         * Verifies a HeaderColumn message.
         * @function verify
         * @memberof sharelib.HeaderColumn
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HeaderColumn.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.messageName != null && message.hasOwnProperty("messageName"))
                if (!$util.isString(message.messageName))
                    return "messageName: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            return null;
        };

        /**
         * Creates a HeaderColumn message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.HeaderColumn
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.HeaderColumn} HeaderColumn
         */
        HeaderColumn.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.HeaderColumn)
                return object;
            let message = new $root.sharelib.HeaderColumn();
            if (object.messageName != null)
                message.messageName = String(object.messageName);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            return message;
        };

        /**
         * Creates a plain object from a HeaderColumn message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.HeaderColumn
         * @static
         * @param {sharelib.HeaderColumn} message HeaderColumn
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HeaderColumn.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.messageName = "";
                object.displayName = "";
            }
            if (message.messageName != null && message.hasOwnProperty("messageName"))
                object.messageName = message.messageName;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            return object;
        };

        /**
         * Converts this HeaderColumn to JSON.
         * @function toJSON
         * @memberof sharelib.HeaderColumn
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HeaderColumn.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HeaderColumn;
    })();

    sharelib.ImportSummary = (function() {

        /**
         * Properties of an ImportSummary.
         * @memberof sharelib
         * @interface IImportSummary
         * @property {Long|null} [total] ImportSummary total
         * @property {Long|null} [success] ImportSummary success
         * @property {Long|null} [error] ImportSummary error
         */

        /**
         * Constructs a new ImportSummary.
         * @memberof sharelib
         * @classdesc Represents an ImportSummary.
         * @implements IImportSummary
         * @constructor
         * @param {sharelib.IImportSummary=} [properties] Properties to set
         */
        function ImportSummary(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportSummary total.
         * @member {Long} total
         * @memberof sharelib.ImportSummary
         * @instance
         */
        ImportSummary.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ImportSummary success.
         * @member {Long} success
         * @memberof sharelib.ImportSummary
         * @instance
         */
        ImportSummary.prototype.success = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ImportSummary error.
         * @member {Long} error
         * @memberof sharelib.ImportSummary
         * @instance
         */
        ImportSummary.prototype.error = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Verifies an ImportSummary message.
         * @function verify
         * @memberof sharelib.ImportSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.total != null && message.hasOwnProperty("total"))
                if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                    return "total: integer|Long expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (!$util.isInteger(message.success) && !(message.success && $util.isInteger(message.success.low) && $util.isInteger(message.success.high)))
                    return "success: integer|Long expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isInteger(message.error) && !(message.error && $util.isInteger(message.error.low) && $util.isInteger(message.error.high)))
                    return "error: integer|Long expected";
            return null;
        };

        /**
         * Creates an ImportSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.ImportSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.ImportSummary} ImportSummary
         */
        ImportSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.ImportSummary)
                return object;
            let message = new $root.sharelib.ImportSummary();
            if (object.total != null)
                if ($util.Long)
                    (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                else if (typeof object.total === "string")
                    message.total = parseInt(object.total, 10);
                else if (typeof object.total === "number")
                    message.total = object.total;
                else if (typeof object.total === "object")
                    message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
            if (object.success != null)
                if ($util.Long)
                    (message.success = $util.Long.fromValue(object.success)).unsigned = false;
                else if (typeof object.success === "string")
                    message.success = parseInt(object.success, 10);
                else if (typeof object.success === "number")
                    message.success = object.success;
                else if (typeof object.success === "object")
                    message.success = new $util.LongBits(object.success.low >>> 0, object.success.high >>> 0).toNumber();
            if (object.error != null)
                if ($util.Long)
                    (message.error = $util.Long.fromValue(object.error)).unsigned = false;
                else if (typeof object.error === "string")
                    message.error = parseInt(object.error, 10);
                else if (typeof object.error === "number")
                    message.error = object.error;
                else if (typeof object.error === "object")
                    message.error = new $util.LongBits(object.error.low >>> 0, object.error.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an ImportSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.ImportSummary
         * @static
         * @param {sharelib.ImportSummary} message ImportSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.total = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.success = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.success = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.error = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.error = options.longs === String ? "0" : 0;
            }
            if (message.total != null && message.hasOwnProperty("total"))
                if (typeof message.total === "number")
                    object.total = options.longs === String ? String(message.total) : message.total;
                else
                    object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success === "number")
                    object.success = options.longs === String ? String(message.success) : message.success;
                else
                    object.success = options.longs === String ? $util.Long.prototype.toString.call(message.success) : options.longs === Number ? new $util.LongBits(message.success.low >>> 0, message.success.high >>> 0).toNumber() : message.success;
            if (message.error != null && message.hasOwnProperty("error"))
                if (typeof message.error === "number")
                    object.error = options.longs === String ? String(message.error) : message.error;
                else
                    object.error = options.longs === String ? $util.Long.prototype.toString.call(message.error) : options.longs === Number ? new $util.LongBits(message.error.low >>> 0, message.error.high >>> 0).toNumber() : message.error;
            return object;
        };

        /**
         * Converts this ImportSummary to JSON.
         * @function toJSON
         * @memberof sharelib.ImportSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportSummary;
    })();

    sharelib.ImportError = (function() {

        /**
         * Properties of an ImportError.
         * @memberof sharelib
         * @interface IImportError
         * @property {number|null} [rowNumber] ImportError rowNumber
         * @property {number|null} [columnNumber] ImportError columnNumber
         * @property {sharelib.ErrorLevel|null} [errorLevel] ImportError errorLevel
         * @property {string|null} [messageName] ImportError messageName
         * @property {Array.<string>|null} [messageArguments] ImportError messageArguments
         */

        /**
         * Constructs a new ImportError.
         * @memberof sharelib
         * @classdesc Represents an ImportError.
         * @implements IImportError
         * @constructor
         * @param {sharelib.IImportError=} [properties] Properties to set
         */
        function ImportError(properties) {
            this.messageArguments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportError rowNumber.
         * @member {number} rowNumber
         * @memberof sharelib.ImportError
         * @instance
         */
        ImportError.prototype.rowNumber = 0;

        /**
         * ImportError columnNumber.
         * @member {number} columnNumber
         * @memberof sharelib.ImportError
         * @instance
         */
        ImportError.prototype.columnNumber = 0;

        /**
         * ImportError errorLevel.
         * @member {sharelib.ErrorLevel} errorLevel
         * @memberof sharelib.ImportError
         * @instance
         */
        ImportError.prototype.errorLevel = 0;

        /**
         * ImportError messageName.
         * @member {string} messageName
         * @memberof sharelib.ImportError
         * @instance
         */
        ImportError.prototype.messageName = "";

        /**
         * ImportError messageArguments.
         * @member {Array.<string>} messageArguments
         * @memberof sharelib.ImportError
         * @instance
         */
        ImportError.prototype.messageArguments = $util.emptyArray;

        /**
         * Verifies an ImportError message.
         * @function verify
         * @memberof sharelib.ImportError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowNumber != null && message.hasOwnProperty("rowNumber"))
                if (!$util.isInteger(message.rowNumber))
                    return "rowNumber: integer expected";
            if (message.columnNumber != null && message.hasOwnProperty("columnNumber"))
                if (!$util.isInteger(message.columnNumber))
                    return "columnNumber: integer expected";
            if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                switch (message.errorLevel) {
                default:
                    return "errorLevel: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.messageName != null && message.hasOwnProperty("messageName"))
                if (!$util.isString(message.messageName))
                    return "messageName: string expected";
            if (message.messageArguments != null && message.hasOwnProperty("messageArguments")) {
                if (!Array.isArray(message.messageArguments))
                    return "messageArguments: array expected";
                for (let i = 0; i < message.messageArguments.length; ++i)
                    if (!$util.isString(message.messageArguments[i]))
                        return "messageArguments: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ImportError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.ImportError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.ImportError} ImportError
         */
        ImportError.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.ImportError)
                return object;
            let message = new $root.sharelib.ImportError();
            if (object.rowNumber != null)
                message.rowNumber = object.rowNumber | 0;
            if (object.columnNumber != null)
                message.columnNumber = object.columnNumber | 0;
            switch (object.errorLevel) {
            case "NONE":
            case 0:
                message.errorLevel = 0;
                break;
            case "ERROR":
            case 1:
                message.errorLevel = 1;
                break;
            case "WARNING":
            case 2:
                message.errorLevel = 2;
                break;
            }
            if (object.messageName != null)
                message.messageName = String(object.messageName);
            if (object.messageArguments) {
                if (!Array.isArray(object.messageArguments))
                    throw TypeError(".sharelib.ImportError.messageArguments: array expected");
                message.messageArguments = [];
                for (let i = 0; i < object.messageArguments.length; ++i)
                    message.messageArguments[i] = String(object.messageArguments[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImportError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.ImportError
         * @static
         * @param {sharelib.ImportError} message ImportError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.messageArguments = [];
            if (options.defaults) {
                object.rowNumber = 0;
                object.columnNumber = 0;
                object.errorLevel = options.enums === String ? "NONE" : 0;
                object.messageName = "";
            }
            if (message.rowNumber != null && message.hasOwnProperty("rowNumber"))
                object.rowNumber = message.rowNumber;
            if (message.columnNumber != null && message.hasOwnProperty("columnNumber"))
                object.columnNumber = message.columnNumber;
            if (message.errorLevel != null && message.hasOwnProperty("errorLevel"))
                object.errorLevel = options.enums === String ? $root.sharelib.ErrorLevel[message.errorLevel] : message.errorLevel;
            if (message.messageName != null && message.hasOwnProperty("messageName"))
                object.messageName = message.messageName;
            if (message.messageArguments && message.messageArguments.length) {
                object.messageArguments = [];
                for (let j = 0; j < message.messageArguments.length; ++j)
                    object.messageArguments[j] = message.messageArguments[j];
            }
            return object;
        };

        /**
         * Converts this ImportError to JSON.
         * @function toJSON
         * @memberof sharelib.ImportError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportError;
    })();

    sharelib.NotificationSetting = (function() {

        /**
         * Properties of a NotificationSetting.
         * @memberof sharelib
         * @interface INotificationSetting
         * @property {string|null} [systemCategory] NotificationSetting systemCategory
         * @property {string|null} [title] NotificationSetting title
         * @property {string|null} [content] NotificationSetting content
         * @property {string|null} [link] NotificationSetting link
         * @property {Array.<string>|null} [tos] NotificationSetting tos
         * @property {Array.<string>|null} [ccs] NotificationSetting ccs
         * @property {string|null} [senderTenant] NotificationSetting senderTenant
         */

        /**
         * Constructs a new NotificationSetting.
         * @memberof sharelib
         * @classdesc Represents a NotificationSetting.
         * @implements INotificationSetting
         * @constructor
         * @param {sharelib.INotificationSetting=} [properties] Properties to set
         */
        function NotificationSetting(properties) {
            this.tos = [];
            this.ccs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotificationSetting systemCategory.
         * @member {string} systemCategory
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.systemCategory = "";

        /**
         * NotificationSetting title.
         * @member {string} title
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.title = "";

        /**
         * NotificationSetting content.
         * @member {string} content
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.content = "";

        /**
         * NotificationSetting link.
         * @member {string} link
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.link = "";

        /**
         * NotificationSetting tos.
         * @member {Array.<string>} tos
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.tos = $util.emptyArray;

        /**
         * NotificationSetting ccs.
         * @member {Array.<string>} ccs
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.ccs = $util.emptyArray;

        /**
         * NotificationSetting senderTenant.
         * @member {string} senderTenant
         * @memberof sharelib.NotificationSetting
         * @instance
         */
        NotificationSetting.prototype.senderTenant = "";

        /**
         * Verifies a NotificationSetting message.
         * @function verify
         * @memberof sharelib.NotificationSetting
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotificationSetting.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.systemCategory != null && message.hasOwnProperty("systemCategory"))
                if (!$util.isString(message.systemCategory))
                    return "systemCategory: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.link != null && message.hasOwnProperty("link"))
                if (!$util.isString(message.link))
                    return "link: string expected";
            if (message.tos != null && message.hasOwnProperty("tos")) {
                if (!Array.isArray(message.tos))
                    return "tos: array expected";
                for (let i = 0; i < message.tos.length; ++i)
                    if (!$util.isString(message.tos[i]))
                        return "tos: string[] expected";
            }
            if (message.ccs != null && message.hasOwnProperty("ccs")) {
                if (!Array.isArray(message.ccs))
                    return "ccs: array expected";
                for (let i = 0; i < message.ccs.length; ++i)
                    if (!$util.isString(message.ccs[i]))
                        return "ccs: string[] expected";
            }
            if (message.senderTenant != null && message.hasOwnProperty("senderTenant"))
                if (!$util.isString(message.senderTenant))
                    return "senderTenant: string expected";
            return null;
        };

        /**
         * Creates a NotificationSetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.NotificationSetting
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.NotificationSetting} NotificationSetting
         */
        NotificationSetting.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.NotificationSetting)
                return object;
            let message = new $root.sharelib.NotificationSetting();
            if (object.systemCategory != null)
                message.systemCategory = String(object.systemCategory);
            if (object.title != null)
                message.title = String(object.title);
            if (object.content != null)
                message.content = String(object.content);
            if (object.link != null)
                message.link = String(object.link);
            if (object.tos) {
                if (!Array.isArray(object.tos))
                    throw TypeError(".sharelib.NotificationSetting.tos: array expected");
                message.tos = [];
                for (let i = 0; i < object.tos.length; ++i)
                    message.tos[i] = String(object.tos[i]);
            }
            if (object.ccs) {
                if (!Array.isArray(object.ccs))
                    throw TypeError(".sharelib.NotificationSetting.ccs: array expected");
                message.ccs = [];
                for (let i = 0; i < object.ccs.length; ++i)
                    message.ccs[i] = String(object.ccs[i]);
            }
            if (object.senderTenant != null)
                message.senderTenant = String(object.senderTenant);
            return message;
        };

        /**
         * Creates a plain object from a NotificationSetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.NotificationSetting
         * @static
         * @param {sharelib.NotificationSetting} message NotificationSetting
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationSetting.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.tos = [];
                object.ccs = [];
            }
            if (options.defaults) {
                object.systemCategory = "";
                object.title = "";
                object.content = "";
                object.link = "";
                object.senderTenant = "";
            }
            if (message.systemCategory != null && message.hasOwnProperty("systemCategory"))
                object.systemCategory = message.systemCategory;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.link != null && message.hasOwnProperty("link"))
                object.link = message.link;
            if (message.tos && message.tos.length) {
                object.tos = [];
                for (let j = 0; j < message.tos.length; ++j)
                    object.tos[j] = message.tos[j];
            }
            if (message.ccs && message.ccs.length) {
                object.ccs = [];
                for (let j = 0; j < message.ccs.length; ++j)
                    object.ccs[j] = message.ccs[j];
            }
            if (message.senderTenant != null && message.hasOwnProperty("senderTenant"))
                object.senderTenant = message.senderTenant;
            return object;
        };

        /**
         * Converts this NotificationSetting to JSON.
         * @function toJSON
         * @memberof sharelib.NotificationSetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationSetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NotificationSetting;
    })();

    sharelib.LicenseLabelPermission = (function() {

        /**
         * Properties of a LicenseLabelPermission.
         * @memberof sharelib
         * @interface ILicenseLabelPermission
         * @property {Array.<string>|null} [endpoints] LicenseLabelPermission endpoints
         * @property {Array.<string>|null} [userGroupCodes] LicenseLabelPermission userGroupCodes
         * @property {Array.<string>|null} [presetIds] LicenseLabelPermission presetIds
         */

        /**
         * Constructs a new LicenseLabelPermission.
         * @memberof sharelib
         * @classdesc Represents a LicenseLabelPermission.
         * @implements ILicenseLabelPermission
         * @constructor
         * @param {sharelib.ILicenseLabelPermission=} [properties] Properties to set
         */
        function LicenseLabelPermission(properties) {
            this.endpoints = [];
            this.userGroupCodes = [];
            this.presetIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LicenseLabelPermission endpoints.
         * @member {Array.<string>} endpoints
         * @memberof sharelib.LicenseLabelPermission
         * @instance
         */
        LicenseLabelPermission.prototype.endpoints = $util.emptyArray;

        /**
         * LicenseLabelPermission userGroupCodes.
         * @member {Array.<string>} userGroupCodes
         * @memberof sharelib.LicenseLabelPermission
         * @instance
         */
        LicenseLabelPermission.prototype.userGroupCodes = $util.emptyArray;

        /**
         * LicenseLabelPermission presetIds.
         * @member {Array.<string>} presetIds
         * @memberof sharelib.LicenseLabelPermission
         * @instance
         */
        LicenseLabelPermission.prototype.presetIds = $util.emptyArray;

        /**
         * Verifies a LicenseLabelPermission message.
         * @function verify
         * @memberof sharelib.LicenseLabelPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LicenseLabelPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.endpoints != null && message.hasOwnProperty("endpoints")) {
                if (!Array.isArray(message.endpoints))
                    return "endpoints: array expected";
                for (let i = 0; i < message.endpoints.length; ++i)
                    if (!$util.isString(message.endpoints[i]))
                        return "endpoints: string[] expected";
            }
            if (message.userGroupCodes != null && message.hasOwnProperty("userGroupCodes")) {
                if (!Array.isArray(message.userGroupCodes))
                    return "userGroupCodes: array expected";
                for (let i = 0; i < message.userGroupCodes.length; ++i)
                    if (!$util.isString(message.userGroupCodes[i]))
                        return "userGroupCodes: string[] expected";
            }
            if (message.presetIds != null && message.hasOwnProperty("presetIds")) {
                if (!Array.isArray(message.presetIds))
                    return "presetIds: array expected";
                for (let i = 0; i < message.presetIds.length; ++i)
                    if (!$util.isString(message.presetIds[i]))
                        return "presetIds: string[] expected";
            }
            return null;
        };

        /**
         * Creates a LicenseLabelPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sharelib.LicenseLabelPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sharelib.LicenseLabelPermission} LicenseLabelPermission
         */
        LicenseLabelPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.sharelib.LicenseLabelPermission)
                return object;
            let message = new $root.sharelib.LicenseLabelPermission();
            if (object.endpoints) {
                if (!Array.isArray(object.endpoints))
                    throw TypeError(".sharelib.LicenseLabelPermission.endpoints: array expected");
                message.endpoints = [];
                for (let i = 0; i < object.endpoints.length; ++i)
                    message.endpoints[i] = String(object.endpoints[i]);
            }
            if (object.userGroupCodes) {
                if (!Array.isArray(object.userGroupCodes))
                    throw TypeError(".sharelib.LicenseLabelPermission.userGroupCodes: array expected");
                message.userGroupCodes = [];
                for (let i = 0; i < object.userGroupCodes.length; ++i)
                    message.userGroupCodes[i] = String(object.userGroupCodes[i]);
            }
            if (object.presetIds) {
                if (!Array.isArray(object.presetIds))
                    throw TypeError(".sharelib.LicenseLabelPermission.presetIds: array expected");
                message.presetIds = [];
                for (let i = 0; i < object.presetIds.length; ++i)
                    message.presetIds[i] = String(object.presetIds[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a LicenseLabelPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sharelib.LicenseLabelPermission
         * @static
         * @param {sharelib.LicenseLabelPermission} message LicenseLabelPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LicenseLabelPermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.endpoints = [];
                object.userGroupCodes = [];
                object.presetIds = [];
            }
            if (message.endpoints && message.endpoints.length) {
                object.endpoints = [];
                for (let j = 0; j < message.endpoints.length; ++j)
                    object.endpoints[j] = message.endpoints[j];
            }
            if (message.userGroupCodes && message.userGroupCodes.length) {
                object.userGroupCodes = [];
                for (let j = 0; j < message.userGroupCodes.length; ++j)
                    object.userGroupCodes[j] = message.userGroupCodes[j];
            }
            if (message.presetIds && message.presetIds.length) {
                object.presetIds = [];
                for (let j = 0; j < message.presetIds.length; ++j)
                    object.presetIds[j] = message.presetIds[j];
            }
            return object;
        };

        /**
         * Converts this LicenseLabelPermission to JSON.
         * @function toJSON
         * @memberof sharelib.LicenseLabelPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LicenseLabelPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LicenseLabelPermission;
    })();

    return sharelib;
})();

export const mtn = $root.mtn = (() => {

    /**
     * Namespace mtn.
     * @exports mtn
     * @namespace
     */
    const mtn = {};

    /**
     * Scope enum.
     * @name mtn.Scope
     * @enum {number}
     * @property {number} PRIVATE=0 PRIVATE value
     * @property {number} GRANTED=1 GRANTED value
     * @property {number} PUBLIC=2 PUBLIC value
     * @property {number} INHERIT=3 INHERIT value
     */
    mtn.Scope = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PRIVATE"] = 0;
        values[valuesById[1] = "GRANTED"] = 1;
        values[valuesById[2] = "PUBLIC"] = 2;
        values[valuesById[3] = "INHERIT"] = 3;
        return values;
    })();

    mtn.VisibilityOptions = (function() {

        /**
         * Properties of a VisibilityOptions.
         * @memberof mtn
         * @interface IVisibilityOptions
         * @property {mtn.Scope|null} [scope] VisibilityOptions scope
         */

        /**
         * Constructs a new VisibilityOptions.
         * @memberof mtn
         * @classdesc Represents a VisibilityOptions.
         * @implements IVisibilityOptions
         * @constructor
         * @param {mtn.IVisibilityOptions=} [properties] Properties to set
         */
        function VisibilityOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VisibilityOptions scope.
         * @member {mtn.Scope} scope
         * @memberof mtn.VisibilityOptions
         * @instance
         */
        VisibilityOptions.prototype.scope = 0;

        /**
         * Verifies a VisibilityOptions message.
         * @function verify
         * @memberof mtn.VisibilityOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VisibilityOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scope != null && message.hasOwnProperty("scope"))
                switch (message.scope) {
                default:
                    return "scope: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a VisibilityOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mtn.VisibilityOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mtn.VisibilityOptions} VisibilityOptions
         */
        VisibilityOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.mtn.VisibilityOptions)
                return object;
            let message = new $root.mtn.VisibilityOptions();
            switch (object.scope) {
            case "PRIVATE":
            case 0:
                message.scope = 0;
                break;
            case "GRANTED":
            case 1:
                message.scope = 1;
                break;
            case "PUBLIC":
            case 2:
                message.scope = 2;
                break;
            case "INHERIT":
            case 3:
                message.scope = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a VisibilityOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mtn.VisibilityOptions
         * @static
         * @param {mtn.VisibilityOptions} message VisibilityOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VisibilityOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.scope = options.enums === String ? "PRIVATE" : 0;
            if (message.scope != null && message.hasOwnProperty("scope"))
                object.scope = options.enums === String ? $root.mtn.Scope[message.scope] : message.scope;
            return object;
        };

        /**
         * Converts this VisibilityOptions to JSON.
         * @function toJSON
         * @memberof mtn.VisibilityOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VisibilityOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VisibilityOptions;
    })();

    /**
     * FileEncoding enum.
     * @name mtn.FileEncoding
     * @enum {number}
     * @property {number} UTF_8=0 UTF_8 value
     * @property {number} SHIFT_JIS=1 SHIFT_JIS value
     */
    mtn.FileEncoding = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UTF_8"] = 0;
        values[valuesById[1] = "SHIFT_JIS"] = 1;
        return values;
    })();

    mtn.CsvFileOptions = (function() {

        /**
         * Properties of a CsvFileOptions.
         * @memberof mtn
         * @interface ICsvFileOptions
         * @property {boolean|null} [header] CsvFileOptions header
         * @property {mtn.CsvFileOptions.CommaChar|null} [comma] CsvFileOptions comma
         * @property {mtn.FileEncoding|null} [encoding] CsvFileOptions encoding
         * @property {boolean|null} [quoteMinimally] CsvFileOptions quoteMinimally
         */

        /**
         * Constructs a new CsvFileOptions.
         * @memberof mtn
         * @classdesc Represents a CsvFileOptions.
         * @implements ICsvFileOptions
         * @constructor
         * @param {mtn.ICsvFileOptions=} [properties] Properties to set
         */
        function CsvFileOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CsvFileOptions header.
         * @member {boolean} header
         * @memberof mtn.CsvFileOptions
         * @instance
         */
        CsvFileOptions.prototype.header = false;

        /**
         * CsvFileOptions comma.
         * @member {mtn.CsvFileOptions.CommaChar} comma
         * @memberof mtn.CsvFileOptions
         * @instance
         */
        CsvFileOptions.prototype.comma = 0;

        /**
         * CsvFileOptions encoding.
         * @member {mtn.FileEncoding} encoding
         * @memberof mtn.CsvFileOptions
         * @instance
         */
        CsvFileOptions.prototype.encoding = 0;

        /**
         * CsvFileOptions quoteMinimally.
         * @member {boolean} quoteMinimally
         * @memberof mtn.CsvFileOptions
         * @instance
         */
        CsvFileOptions.prototype.quoteMinimally = false;

        /**
         * Verifies a CsvFileOptions message.
         * @function verify
         * @memberof mtn.CsvFileOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CsvFileOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.header != null && message.hasOwnProperty("header"))
                if (typeof message.header !== "boolean")
                    return "header: boolean expected";
            if (message.comma != null && message.hasOwnProperty("comma"))
                switch (message.comma) {
                default:
                    return "comma: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.encoding != null && message.hasOwnProperty("encoding"))
                switch (message.encoding) {
                default:
                    return "encoding: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.quoteMinimally != null && message.hasOwnProperty("quoteMinimally"))
                if (typeof message.quoteMinimally !== "boolean")
                    return "quoteMinimally: boolean expected";
            return null;
        };

        /**
         * Creates a CsvFileOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mtn.CsvFileOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mtn.CsvFileOptions} CsvFileOptions
         */
        CsvFileOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.mtn.CsvFileOptions)
                return object;
            let message = new $root.mtn.CsvFileOptions();
            if (object.header != null)
                message.header = Boolean(object.header);
            switch (object.comma) {
            case "COMMA":
            case 0:
                message.comma = 0;
                break;
            case "TAB":
            case 1:
                message.comma = 1;
                break;
            }
            switch (object.encoding) {
            case "UTF_8":
            case 0:
                message.encoding = 0;
                break;
            case "SHIFT_JIS":
            case 1:
                message.encoding = 1;
                break;
            }
            if (object.quoteMinimally != null)
                message.quoteMinimally = Boolean(object.quoteMinimally);
            return message;
        };

        /**
         * Creates a plain object from a CsvFileOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mtn.CsvFileOptions
         * @static
         * @param {mtn.CsvFileOptions} message CsvFileOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CsvFileOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.header = false;
                object.comma = options.enums === String ? "COMMA" : 0;
                object.encoding = options.enums === String ? "UTF_8" : 0;
                object.quoteMinimally = false;
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = message.header;
            if (message.comma != null && message.hasOwnProperty("comma"))
                object.comma = options.enums === String ? $root.mtn.CsvFileOptions.CommaChar[message.comma] : message.comma;
            if (message.encoding != null && message.hasOwnProperty("encoding"))
                object.encoding = options.enums === String ? $root.mtn.FileEncoding[message.encoding] : message.encoding;
            if (message.quoteMinimally != null && message.hasOwnProperty("quoteMinimally"))
                object.quoteMinimally = message.quoteMinimally;
            return object;
        };

        /**
         * Converts this CsvFileOptions to JSON.
         * @function toJSON
         * @memberof mtn.CsvFileOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CsvFileOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * CommaChar enum.
         * @name mtn.CsvFileOptions.CommaChar
         * @enum {number}
         * @property {number} COMMA=0 COMMA value
         * @property {number} TAB=1 TAB value
         */
        CsvFileOptions.CommaChar = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COMMA"] = 0;
            values[valuesById[1] = "TAB"] = 1;
            return values;
        })();

        return CsvFileOptions;
    })();

    mtn.CsvColumnOptions = (function() {

        /**
         * Properties of a CsvColumnOptions.
         * @memberof mtn
         * @interface ICsvColumnOptions
         * @property {number|null} [column] CsvColumnOptions column
         * @property {string|null} [header] CsvColumnOptions header
         * @property {string|null} ["default"] CsvColumnOptions default
         */

        /**
         * Constructs a new CsvColumnOptions.
         * @memberof mtn
         * @classdesc Represents a CsvColumnOptions.
         * @implements ICsvColumnOptions
         * @constructor
         * @param {mtn.ICsvColumnOptions=} [properties] Properties to set
         */
        function CsvColumnOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CsvColumnOptions column.
         * @member {number} column
         * @memberof mtn.CsvColumnOptions
         * @instance
         */
        CsvColumnOptions.prototype.column = 0;

        /**
         * CsvColumnOptions header.
         * @member {string} header
         * @memberof mtn.CsvColumnOptions
         * @instance
         */
        CsvColumnOptions.prototype.header = "";

        /**
         * CsvColumnOptions default.
         * @member {string} default
         * @memberof mtn.CsvColumnOptions
         * @instance
         */
        CsvColumnOptions.prototype["default"] = "";

        /**
         * Verifies a CsvColumnOptions message.
         * @function verify
         * @memberof mtn.CsvColumnOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CsvColumnOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.column != null && message.hasOwnProperty("column"))
                if (!$util.isInteger(message.column))
                    return "column: integer expected";
            if (message.header != null && message.hasOwnProperty("header"))
                if (!$util.isString(message.header))
                    return "header: string expected";
            if (message["default"] != null && message.hasOwnProperty("default"))
                if (!$util.isString(message["default"]))
                    return "default: string expected";
            return null;
        };

        /**
         * Creates a CsvColumnOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mtn.CsvColumnOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mtn.CsvColumnOptions} CsvColumnOptions
         */
        CsvColumnOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.mtn.CsvColumnOptions)
                return object;
            let message = new $root.mtn.CsvColumnOptions();
            if (object.column != null)
                message.column = object.column | 0;
            if (object.header != null)
                message.header = String(object.header);
            if (object["default"] != null)
                message["default"] = String(object["default"]);
            return message;
        };

        /**
         * Creates a plain object from a CsvColumnOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mtn.CsvColumnOptions
         * @static
         * @param {mtn.CsvColumnOptions} message CsvColumnOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CsvColumnOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.column = 0;
                object.header = "";
                object["default"] = "";
            }
            if (message.column != null && message.hasOwnProperty("column"))
                object.column = message.column;
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = message.header;
            if (message["default"] != null && message.hasOwnProperty("default"))
                object["default"] = message["default"];
            return object;
        };

        /**
         * Converts this CsvColumnOptions to JSON.
         * @function toJSON
         * @memberof mtn.CsvColumnOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CsvColumnOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CsvColumnOptions;
    })();

    mtn.DataproxyIndex = (function() {

        /**
         * Properties of a DataproxyIndex.
         * @memberof mtn
         * @interface IDataproxyIndex
         * @property {string|null} [name] DataproxyIndex name
         */

        /**
         * Constructs a new DataproxyIndex.
         * @memberof mtn
         * @classdesc Represents a DataproxyIndex.
         * @implements IDataproxyIndex
         * @constructor
         * @param {mtn.IDataproxyIndex=} [properties] Properties to set
         */
        function DataproxyIndex(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataproxyIndex name.
         * @member {string} name
         * @memberof mtn.DataproxyIndex
         * @instance
         */
        DataproxyIndex.prototype.name = "";

        /**
         * Verifies a DataproxyIndex message.
         * @function verify
         * @memberof mtn.DataproxyIndex
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataproxyIndex.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a DataproxyIndex message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mtn.DataproxyIndex
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mtn.DataproxyIndex} DataproxyIndex
         */
        DataproxyIndex.fromObject = function fromObject(object) {
            if (object instanceof $root.mtn.DataproxyIndex)
                return object;
            let message = new $root.mtn.DataproxyIndex();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a DataproxyIndex message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mtn.DataproxyIndex
         * @static
         * @param {mtn.DataproxyIndex} message DataproxyIndex
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataproxyIndex.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this DataproxyIndex to JSON.
         * @function toJSON
         * @memberof mtn.DataproxyIndex
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataproxyIndex.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DataproxyIndex;
    })();

    mtn.DataproxyOptions = (function() {

        /**
         * Properties of a DataproxyOptions.
         * @memberof mtn
         * @interface IDataproxyOptions
         * @property {mtn.IDataproxyIndex|null} [index] DataproxyOptions index
         */

        /**
         * Constructs a new DataproxyOptions.
         * @memberof mtn
         * @classdesc Represents a DataproxyOptions.
         * @implements IDataproxyOptions
         * @constructor
         * @param {mtn.IDataproxyOptions=} [properties] Properties to set
         */
        function DataproxyOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DataproxyOptions index.
         * @member {mtn.IDataproxyIndex|null|undefined} index
         * @memberof mtn.DataproxyOptions
         * @instance
         */
        DataproxyOptions.prototype.index = null;

        /**
         * Verifies a DataproxyOptions message.
         * @function verify
         * @memberof mtn.DataproxyOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DataproxyOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index != null && message.hasOwnProperty("index")) {
                let error = $root.mtn.DataproxyIndex.verify(message.index);
                if (error)
                    return "index." + error;
            }
            return null;
        };

        /**
         * Creates a DataproxyOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mtn.DataproxyOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mtn.DataproxyOptions} DataproxyOptions
         */
        DataproxyOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.mtn.DataproxyOptions)
                return object;
            let message = new $root.mtn.DataproxyOptions();
            if (object.index != null) {
                if (typeof object.index !== "object")
                    throw TypeError(".mtn.DataproxyOptions.index: object expected");
                message.index = $root.mtn.DataproxyIndex.fromObject(object.index);
            }
            return message;
        };

        /**
         * Creates a plain object from a DataproxyOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mtn.DataproxyOptions
         * @static
         * @param {mtn.DataproxyOptions} message DataproxyOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DataproxyOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.index = null;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = $root.mtn.DataproxyIndex.toObject(message.index, options);
            return object;
        };

        /**
         * Converts this DataproxyOptions to JSON.
         * @function toJSON
         * @memberof mtn.DataproxyOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DataproxyOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DataproxyOptions;
    })();

    return mtn;
})();

export const record = $root.record = (() => {

    /**
     * Namespace record.
     * @exports record
     * @namespace
     */
    const record = {};

    record.Record = (function() {

        /**
         * Properties of a Record.
         * @memberof record
         * @interface IRecord
         * @property {string|null} [recordId] Record recordId
         * @property {string|null} [typeName] Record typeName
         * @property {Object.<string,record.IField>|null} [fields] Record fields
         * @property {Array.<record.IIndex>|null} [indexs] Record indexs
         * @property {sharelib.IEmbeddedSharedProperties|null} [sharedProperties] Record sharedProperties
         * @property {Long|null} [createdAt] Record createdAt
         * @property {Long|null} [updatedAt] Record updatedAt
         * @property {Long|null} [deletedAt] Record deletedAt
         */

        /**
         * Constructs a new Record.
         * @memberof record
         * @classdesc Represents a Record.
         * @implements IRecord
         * @constructor
         * @param {record.IRecord=} [properties] Properties to set
         */
        function Record(properties) {
            this.fields = {};
            this.indexs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Record recordId.
         * @member {string} recordId
         * @memberof record.Record
         * @instance
         */
        Record.prototype.recordId = "";

        /**
         * Record typeName.
         * @member {string} typeName
         * @memberof record.Record
         * @instance
         */
        Record.prototype.typeName = "";

        /**
         * Record fields.
         * @member {Object.<string,record.IField>} fields
         * @memberof record.Record
         * @instance
         */
        Record.prototype.fields = $util.emptyObject;

        /**
         * Record indexs.
         * @member {Array.<record.IIndex>} indexs
         * @memberof record.Record
         * @instance
         */
        Record.prototype.indexs = $util.emptyArray;

        /**
         * Record sharedProperties.
         * @member {sharelib.IEmbeddedSharedProperties|null|undefined} sharedProperties
         * @memberof record.Record
         * @instance
         */
        Record.prototype.sharedProperties = null;

        /**
         * Record createdAt.
         * @member {Long} createdAt
         * @memberof record.Record
         * @instance
         */
        Record.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Record updatedAt.
         * @member {Long} updatedAt
         * @memberof record.Record
         * @instance
         */
        Record.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Record deletedAt.
         * @member {Long} deletedAt
         * @memberof record.Record
         * @instance
         */
        Record.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Verifies a Record message.
         * @function verify
         * @memberof record.Record
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Record.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                if (!$util.isString(message.recordId))
                    return "recordId: string expected";
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                if (!$util.isString(message.typeName))
                    return "typeName: string expected";
            if (message.fields != null && message.hasOwnProperty("fields")) {
                if (!$util.isObject(message.fields))
                    return "fields: object expected";
                let key = Object.keys(message.fields);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "fields: integer key{k:int32} expected";
                    {
                        let error = $root.record.Field.verify(message.fields[key[i]]);
                        if (error)
                            return "fields." + error;
                    }
                }
            }
            if (message.indexs != null && message.hasOwnProperty("indexs")) {
                if (!Array.isArray(message.indexs))
                    return "indexs: array expected";
                for (let i = 0; i < message.indexs.length; ++i) {
                    let error = $root.record.Index.verify(message.indexs[i]);
                    if (error)
                        return "indexs." + error;
                }
            }
            if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties")) {
                let error = $root.sharelib.EmbeddedSharedProperties.verify(message.sharedProperties);
                if (error)
                    return "sharedProperties." + error;
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                    return "updatedAt: integer|Long expected";
            if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                    return "deletedAt: integer|Long expected";
            return null;
        };

        /**
         * Creates a Record message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof record.Record
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {record.Record} Record
         */
        Record.fromObject = function fromObject(object) {
            if (object instanceof $root.record.Record)
                return object;
            let message = new $root.record.Record();
            if (object.recordId != null)
                message.recordId = String(object.recordId);
            if (object.typeName != null)
                message.typeName = String(object.typeName);
            if (object.fields) {
                if (typeof object.fields !== "object")
                    throw TypeError(".record.Record.fields: object expected");
                message.fields = {};
                for (let keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
                    if (typeof object.fields[keys[i]] !== "object")
                        throw TypeError(".record.Record.fields: object expected");
                    message.fields[keys[i]] = $root.record.Field.fromObject(object.fields[keys[i]]);
                }
            }
            if (object.indexs) {
                if (!Array.isArray(object.indexs))
                    throw TypeError(".record.Record.indexs: array expected");
                message.indexs = [];
                for (let i = 0; i < object.indexs.length; ++i) {
                    if (typeof object.indexs[i] !== "object")
                        throw TypeError(".record.Record.indexs: object expected");
                    message.indexs[i] = $root.record.Index.fromObject(object.indexs[i]);
                }
            }
            if (object.sharedProperties != null) {
                if (typeof object.sharedProperties !== "object")
                    throw TypeError(".record.Record.sharedProperties: object expected");
                message.sharedProperties = $root.sharelib.EmbeddedSharedProperties.fromObject(object.sharedProperties);
            }
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
            if (object.updatedAt != null)
                if ($util.Long)
                    (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
                else if (typeof object.updatedAt === "string")
                    message.updatedAt = parseInt(object.updatedAt, 10);
                else if (typeof object.updatedAt === "number")
                    message.updatedAt = object.updatedAt;
                else if (typeof object.updatedAt === "object")
                    message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
            if (object.deletedAt != null)
                if ($util.Long)
                    (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false;
                else if (typeof object.deletedAt === "string")
                    message.deletedAt = parseInt(object.deletedAt, 10);
                else if (typeof object.deletedAt === "number")
                    message.deletedAt = object.deletedAt;
                else if (typeof object.deletedAt === "object")
                    message.deletedAt = new $util.LongBits(object.deletedAt.low >>> 0, object.deletedAt.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Record message. Also converts values to other types if specified.
         * @function toObject
         * @memberof record.Record
         * @static
         * @param {record.Record} message Record
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Record.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.indexs = [];
            if (options.objects || options.defaults)
                object.fields = {};
            if (options.defaults) {
                object.typeName = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updatedAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.deletedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.deletedAt = options.longs === String ? "0" : 0;
                object.recordId = "";
                object.sharedProperties = null;
            }
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                object.typeName = message.typeName;
            let keys2;
            if (message.fields && (keys2 = Object.keys(message.fields)).length) {
                object.fields = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.fields[keys2[j]] = $root.record.Field.toObject(message.fields[keys2[j]], options);
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
            if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                if (typeof message.updatedAt === "number")
                    object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
                else
                    object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
            if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                if (typeof message.deletedAt === "number")
                    object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt;
                else
                    object.deletedAt = options.longs === String ? $util.Long.prototype.toString.call(message.deletedAt) : options.longs === Number ? new $util.LongBits(message.deletedAt.low >>> 0, message.deletedAt.high >>> 0).toNumber() : message.deletedAt;
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                object.recordId = message.recordId;
            if (message.indexs && message.indexs.length) {
                object.indexs = [];
                for (let j = 0; j < message.indexs.length; ++j)
                    object.indexs[j] = $root.record.Index.toObject(message.indexs[j], options);
            }
            if (message.sharedProperties != null && message.hasOwnProperty("sharedProperties"))
                object.sharedProperties = $root.sharelib.EmbeddedSharedProperties.toObject(message.sharedProperties, options);
            return object;
        };

        /**
         * Converts this Record to JSON.
         * @function toJSON
         * @memberof record.Record
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Record.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Record;
    })();

    record.Field = (function() {

        /**
         * Properties of a Field.
         * @memberof record
         * @interface IField
         * @property {string|null} [name] Field name
         * @property {Uint8Array|null} [value] Field value
         * @property {mtn.IVisibilityOptions|null} [visibility] Field visibility
         */

        /**
         * Constructs a new Field.
         * @memberof record
         * @classdesc Represents a Field.
         * @implements IField
         * @constructor
         * @param {record.IField=} [properties] Properties to set
         */
        function Field(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Field name.
         * @member {string} name
         * @memberof record.Field
         * @instance
         */
        Field.prototype.name = "";

        /**
         * Field value.
         * @member {Uint8Array} value
         * @memberof record.Field
         * @instance
         */
        Field.prototype.value = $util.newBuffer([]);

        /**
         * Field visibility.
         * @member {mtn.IVisibilityOptions|null|undefined} visibility
         * @memberof record.Field
         * @instance
         */
        Field.prototype.visibility = null;

        /**
         * Verifies a Field message.
         * @function verify
         * @memberof record.Field
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Field.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                    return "value: buffer expected";
            if (message.visibility != null && message.hasOwnProperty("visibility")) {
                let error = $root.mtn.VisibilityOptions.verify(message.visibility);
                if (error)
                    return "visibility." + error;
            }
            return null;
        };

        /**
         * Creates a Field message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof record.Field
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {record.Field} Field
         */
        Field.fromObject = function fromObject(object) {
            if (object instanceof $root.record.Field)
                return object;
            let message = new $root.record.Field();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                if (typeof object.value === "string")
                    $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                else if (object.value.length)
                    message.value = object.value;
            if (object.visibility != null) {
                if (typeof object.visibility !== "object")
                    throw TypeError(".record.Field.visibility: object expected");
                message.visibility = $root.mtn.VisibilityOptions.fromObject(object.visibility);
            }
            return message;
        };

        /**
         * Creates a plain object from a Field message. Also converts values to other types if specified.
         * @function toObject
         * @memberof record.Field
         * @static
         * @param {record.Field} message Field
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Field.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                if (options.bytes === String)
                    object.value = "";
                else {
                    object.value = [];
                    if (options.bytes !== Array)
                        object.value = $util.newBuffer(object.value);
                }
                object.visibility = null;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
            if (message.visibility != null && message.hasOwnProperty("visibility"))
                object.visibility = $root.mtn.VisibilityOptions.toObject(message.visibility, options);
            return object;
        };

        /**
         * Converts this Field to JSON.
         * @function toJSON
         * @memberof record.Field
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Field.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Field;
    })();

    record.Index = (function() {

        /**
         * Properties of an Index.
         * @memberof record
         * @interface IIndex
         * @property {string|null} [name] Index name
         * @property {Uint8Array|null} [value] Index value
         */

        /**
         * Constructs a new Index.
         * @memberof record
         * @classdesc Represents an Index.
         * @implements IIndex
         * @constructor
         * @param {record.IIndex=} [properties] Properties to set
         */
        function Index(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Index name.
         * @member {string} name
         * @memberof record.Index
         * @instance
         */
        Index.prototype.name = "";

        /**
         * Index value.
         * @member {Uint8Array} value
         * @memberof record.Index
         * @instance
         */
        Index.prototype.value = $util.newBuffer([]);

        /**
         * Verifies an Index message.
         * @function verify
         * @memberof record.Index
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Index.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                    return "value: buffer expected";
            return null;
        };

        /**
         * Creates an Index message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof record.Index
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {record.Index} Index
         */
        Index.fromObject = function fromObject(object) {
            if (object instanceof $root.record.Index)
                return object;
            let message = new $root.record.Index();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                if (typeof object.value === "string")
                    $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                else if (object.value.length)
                    message.value = object.value;
            return message;
        };

        /**
         * Creates a plain object from an Index message. Also converts values to other types if specified.
         * @function toObject
         * @memberof record.Index
         * @static
         * @param {record.Index} message Index
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Index.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                if (options.bytes === String)
                    object.value = "";
                else {
                    object.value = [];
                    if (options.bytes !== Array)
                        object.value = $util.newBuffer(object.value);
                }
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
            return object;
        };

        /**
         * Converts this Index to JSON.
         * @function toJSON
         * @memberof record.Index
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Index.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Index;
    })();

    return record;
})();

export { $root as default };
